<ng-container [ngSwitch]="field.model.type">
    <span *ngSwitchCase="'button'" class="form-label-static" [title]="field.model.tooltip"
          [attr.lang]="showAltLang ? objectDefLang : null">{{field.model.title}}</span>
    <eob-label
        *ngSwitchDefault
        [field]="field"
        [ismockform]="ismockform"
        [layoutField]="layoutField"
        [formid]="formid"
        [value]="field.model.title"
        [tooltip]="field.model.tooltip"
        [isRequired]="field.model.isRequired"
        [isUnique]="field.model.isUnique"
        [lang]="showAltLang ? objectDefLang : null"
    ></eob-label>
    <ng-template></ng-template>
</ng-container>
