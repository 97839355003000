<ul id = "PdfjsKebabMenu" [ngClass]="{'open':isMenuOpen, 'close': !isMenuOpen}" [ngStyle]="{'height': menuHeight}">
    <li *ngIf="!toolbarState.showSearchBar" (click)="controls.search()">
        <button class = "button-transparent">
            <span>{{'eob.pdfjs.toggle.search' | ngTranslate}}</span>
        </button>
    </li>
    <li *ngIf = "!toolbarState.showRefresh">
        <button class = "button-transparent"
                title = "{{'eob.pdfjs.reload' | ngTranslate}}" (click)="controls.refresh()">
            <span>{{'eob.pdfjs.reload' | ngTranslate}}</span>
        </button>
    </li>
    <li *ngIf = "!toolbarState.showSideBar">
        <button class = "button-transparent" (click)="controls.sidebar()">
            <span>{{'eob.pdfjs.toggle.sidebar' | ngTranslate}}</span>
        </button>
    </li>
    <li *ngIf="!toolbarState.showZoomOut">
        <button class = "button-transparent"
                title = "{{'eob.pdfjs.zoom.out' | ngTranslate}}"  (click)="controls.zoomOut()">
            <span>{{'eob.pdfjs.zoom.out' | ngTranslate}}</span>
        </button>
    </li>
    <li *ngIf="!toolbarState.showZoomIn">
        <button class = "button-transparent"
                title = "{{'eob.pdfjs.zoom.in' | ngTranslate}}"  (click)="controls.zoomIn()">
            <span>{{'eob.pdfjs.zoom.in' | ngTranslate}}</span>
        </button>
    </li>
    <li *ngIf="!toolbarState.showRotateRight">
        <button class = "button-transparent"  (click)="controls.rotateRight()">
            <span>{{"eob.pdfjs.turn.right" | ngTranslate }}</span>
        </button>
    </li>
    <li *ngIf="!toolbarState.showRotateLeft">
        <button class = "button-transparent"  (click)="controls.rotateLeft()">
            <span>{{"eob.pdfjs.turn.left" | ngTranslate }}</span>
        </button>
    </li>
    <li *ngIf="!toolbarState.showAttachments && toolbarState.attachmentsAvailable && !toolbarState.attachmentsOpen">
        <button class = "button-transparent"  (click)="controls.attachments()">
            <span>{{'eob.pdfjs.show.attachments' | ngTranslate}}</span>
        </button>
    </li>
    <li *ngIf="!toolbarState.showGoToParent && toolbarState.attachmentsAvailable && toolbarState.attachmentsOpen">
        <button class = "button-transparent"  (click)="controls.goToParent()">
            <span>{{'eob.pdfjs.go.to.parent' | ngTranslate}}</span>
        </button>
    </li>
</ul>
