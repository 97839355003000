import Collator = Intl.Collator;
import * as dayjs from "dayjs";
import { Inject, Injectable } from "@angular/core";
import { FormField } from "MODULES_PATH/form/interfaces/form.interface";
import { FieldDataType } from "ENUMS_PATH/field.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

/**
 * Service offering various sorting functions
 */
@Injectable({ providedIn: "root" })
export class SortService {

    reA = /[^a-zA-Z]/g;
    reN = /[^0-9]/g;

    private objectKey = "";
    private pathToValue: any[] = [];
    private collator: Collator = new Intl.Collator();

    constructor(@Inject("environmentService") private environmentService: TodoEnvironmentService, private messageService: MessageService) {
        this.messageService.subscribeFirst(Broadcasts.ENVIRONMENT_INITIALIZED, () => {
            const currentLang: string = environmentService.getLanguage();
            this.collator = new Intl.Collator(currentLang, {
                sensitivity: "variant",
                caseFirst: "upper"
            });
        });
    }

    /**
     * Sort an array by a field type.
     *
     * @param field - A form field.
     * @param list - An array of any kind of type.
     */
    sortListField = (field: FormField, list: any[]): void => {
        switch (field.model.type) {
            case FieldDataType.NUMBER:
                list.sort(this.sortByNumber);
                break;
            case FieldDataType.DATE:
            case FieldDataType.DATETIME:
                list.sort(this.sortByDatetime);
                break;
            default :
                list.sort(this.sortFieldValueByText);
                break;
        }
    };

    /**
     * Sort by number path
     *
     * @param array
     * @param path
     */
    sortByNumberPath = (array: any[], path: string): void => {
        this.pathToValue = path ? path.split(".") : [];
        array.sort((valA: any, valB: any) => {
            const a: any = this.pathToValue.length ? this.getValue(valA) : valA;
            const b: any = this.pathToValue.length ? this.getValue(valB) : valB;
            return this.getNumberSortResult(a, b);
        });
    };

    /**
     * Sort by number
     *
     * @param valA
     * @param valB
     * @returns result of sorting by number
     */
    sortByNumber = (valA: string, valB: string): number => {
        const a: string = this.extractValue(valA);
        const b: string = this.extractValue(valB);
        return this.getNumberSortResult(a, b);
    };

    /**
     * Sort by datetime path
     *
     * @param array
     * @param path
     */
    sortByDatetimePath = (array: any[], path: string): void => {
        this.pathToValue = path ? path.split(".") : [];
        array.sort((valA: any, valB: any) => {
            const a: any = this.pathToValue.length ? this.getValue(valA) : valA;
            const b: any = this.pathToValue.length ? this.getValue(valB) : valB;
            return this.getDateTimeSortResult(a, b);
        });
    };

    /**
     * Sort by datetime
     *
     * @param valA
     * @param valB
     * @returns result of sorting by datetime
     */
    sortByDatetime = (valA: string, valB: string): number => {
        const a: string = this.extractValue(valA);
        const b: string = this.extractValue(valB);
        return this.getDateTimeSortResult(a, b);
    };

    /**
     * Sort by text path
     *
     * @param array
     * @param path
     */
    sortByTextPath = (array: any[], path: string): void => {
        this.pathToValue = path ? path.split(".") : [];
        array.sort((valA: string, valB: string) => {
            const a: string = this.pathToValue.length ? this.getValue(valA) : valA;
            const b: string = this.pathToValue.length ? this.getValue(valB) : valB;
            return this.textComparator(a, b);
        });
    };

    /**
     * Sort field value by text
     *
     * @param valA
     * @param valB
     * @returns result of sorting fields value by text
     */
    sortFieldValueByText = (valA: string, valB: string): number => {
        const a: string = this.extractValue(valA);
        const b: string = this.extractValue(valB);

        return this.textComparator(a, b);
    };

    textComparator = (a: any, b: any): number => {
        a = a.toString().trim();
        b = b.toString().trim();

        return this.collator.compare(a, b);
    };

    /**
     * Extract value from a field
     *
     * @param payload
     * @returns string of extracted value.
     */
    extractValue = (payload: string | any = ""): string => {
        let value: string = typeof (payload) == "string" ? payload : payload.field ? payload.field.value : payload.value;

        if (value == null) {
            value = payload;
        }

        if (typeof (value) == "string") {
            value = this.fromHtmlEntities(value.trim());
        }

        return value;
    };

    /**
     * Sorting of alphanumeric characters by key
     *
     * @param array
     * @param key
     */
    sortAlphanumericByKey = (array: any[], key: string): void => {
        this.objectKey = key;
        array.sort((first: any, second: any) => {
            let a: any = first[this.objectKey];
            let b: any = second[this.objectKey];

            if (!isNaN(a)) {
                a = a.toString().toLowerCase();
            }
            if (!isNaN(b)) {
                b = b.toString().toLowerCase();
            }

            const aA: string = a.replace(this.reA, "");
            const bA: string = b.replace(this.reA, "");
            if (aA === bA) {
                const aN: number = parseInt(a.replace(this.reN, ""), 10);
                const bN: number = parseInt(b.replace(this.reN, ""), 10);
                return aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                return aA > bA ? 1 : -1;
            }
        });
    };

    /**
     * Date comparator used to compare dates
     *
     * @param a
     * @param b
     * @returns number of datetime sort result
     */
    private getDateTimeSortResult(a: string, b: string): number {
        if (b === "" && a === "") {
            return 0;
        }
        if (b === "" && a !== "") {
            return 1;
        }
        if (a === "" && b !== "") {
            return -1;
        }
        const {date, datetime, time} = this.environmentService.env.dateFormat;

        // get milliseconds from string and compare
        const aDate: number = dayjs(a, [datetime, date, time], true).valueOf();
        const bDate: number = dayjs(b, [datetime, date, time], true).valueOf();

        if (aDate < bDate) {
            return -1;
        }
        if (aDate > bDate) {
            return 1;
        }
        return 0;
    }

    /**
     * Number comparator used for number sorting
     *
     * @param a
     * @param b
     * @returns number
     */
    private getNumberSortResult(a: string | number, b: string | number): number {
        // convert to number
        // we use 0,0000001 to sort empty field values before 0
        a = a === "" ? -0.0000001 : a;
        b = b === "" ? -0.0000001 : b;

        const numA: string | number = typeof(a) === "string" ? parseFloat(a) : a;
        const numB: string | number = typeof(b) === "string" ? parseFloat(b) : b;

        if (numA < numB) {
            return -1;
        }
        if (numA > numB) {
            return 1;
        }
        return 0;
    }

    /**
     * Get a value through some magic voodoo
     *
     * @param object
     * @returns any
     */
    private getValue(object: any): any {
        let hasNext = true;
        let index = 0;

        while (hasNext) {
            if (object[this.pathToValue[index]]) {
                return object[this.pathToValue[index]];
            }

            if (this.pathToValue[index + 1]) {
                index++;
            } else {
                hasNext = false;
            }
        }
    }

    /**
     * Extract a character from HTML entities
     *
     * @param str
     * @returns string
     */
    private fromHtmlEntities(str: string): string {
        return str.toString().replace(/&#x.+?;/g, (s: string) => {
            const match: RegExpMatchArray | null = /&#x(.+?);/.exec(s);
            if (match != null) {
                return String.fromCharCode(parseInt(match[1], 16));
            } else {
                return "";
            }
        });
    }
}
