import * as angular from "angular";

/**
 * the instance of the row controller
 * everything row related goes into this class
 */
export class VirtualGridRowController {

    private gPadding: number = 16;
    private gCellNodeClasses: string = "tree-node-cell";

    private onNodeExpandAsync: any;
    private isCollapsible: boolean;
    private expandNodesByDefault: boolean;
    private readonly: boolean;
    private deselectWhenCollapse: boolean;
    private useIntermediateNodes: boolean;
    private useCheckboxSelection: boolean;

    private valueGetter: any;
    private selectedRows: any[];

    constructor(protected grid: any, config: any) {
        this.onNodeExpandAsync = config.onNodeExpandAsync;
        this.expandNodesByDefault = config.expandNodesByDefault == void 0 ? true : config.expandNodesByDefault;
        this.isCollapsible = config.isCollapsible == void 0 ? true : config.isCollapsible;
        this.useCheckboxSelection = config.useCheckboxSelection;
        this.useIntermediateNodes = config.useIntermediateNodes;
        this.deselectWhenCollapse = config.deselectWhenCollapse;
        this.readonly = config.readonly;
        this.valueGetter = config.valueGetter;
        this.selectedRows = [];
    }

    /**
     * updates the config properties
     *
     * @param config
     */
    updateConfigProperties = (config: any): void => {
        this.onNodeExpandAsync = config.onNodeExpandAsync;
        this.expandNodesByDefault = config.expandNodesByDefault == void 0 ? true : config.expandNodesByDefault;
        this.isCollapsible = config.isCollapsible == void 0 ? true : config.isCollapsible;
        this.useCheckboxSelection = config.useCheckboxSelection;
        this.useIntermediateNodes = config.useIntermediateNodes;
        this.deselectWhenCollapse = config.deselectWhenCollapse;
        this.readonly = config.readonly;
        this.valueGetter = config.valueGetter;
    };

    /**
     * reset each rendered row to the initial position and index
     */
    resetRenderedRows = (): void => {
        for (let i: number = 0; i < this.grid.renderedRowCount; i++) {
            const row: any = this.grid.renderedRows[i];

            row.index = i;
            row.top = i * this.grid.config.rowHeight;
            row.listNode.css({top: row.top});
        }
    };

    /**
     * processes the grid data and creates the grid rows from scratch
     *
     * @param {Array<any>} rows
     */
    buildRows = (rows: any[]): void => {

        this.selectedRows = [];
        // preprocess data
        this.grid.columnController.getMaxRecursionDepth(rows);
        this.setRowProperties(rows, 0, null);

        // flatten the recursive structure
        this.grid.rows = this.grid.flatten(rows);

        // setting childcount for later usage
        this.setRowIndexes();
        this.setTotalChildCounts(this.grid.rows);
    };

    /**
     * reset the content of each row and set the template of each cell according to the column definition
     */
    buildCellContent = (): void => {
        const columns: any[] = this.grid.columnController.columns;

        for (const row of this.grid.renderedRows) {
            row.cells = [];
            row.listNode.empty();

            for (const col of columns) {

                const cellNode: any = angular.element(`<div class='${this.gCellNodeClasses}'></div>`);

                cellNode.css({"width": col.width, "min-width": col.width, "max-width": col.width});

                const cell: any = {
                    textNode: null,
                    treeNode: null,
                    facetNode: null,
                    iconNode: null,
                    checkboxNode: null,
                    cellNode,
                    field: col.field,
                    cellRenderer: col.cellRenderer
                };

                if (col.isIconColumn) {
                    cell.iconNode = angular.element("<img class='tree-node-icon' src=''>");
                } else if (this.useCheckboxSelection && col.isCheckboxColumn) {
                    cell.checkboxNode = angular.element("<i class='tree-checkbox-icon'>");
                } else {

                    if (col.showAsTree && this.isCollapsible) {
                        cell.treeNode = angular.element("<i class='tree-node-icon tree-expanded'></i>");
                        cell.treeNode.bind("click", this.toggleNodeListener);
                        cell.facetNode = angular.element("<i class=''></i>");
                    }

                    cell.textNode = angular.element("<span class='tree-text-node'></span>");
                }

                cellNode.append(cell.checkboxNode);
                cellNode.append(cell.treeNode);
                cellNode.append(cell.facetNode);
                cellNode.append(cell.iconNode);
                cellNode.append(cell.textNode);

                row.cells.push(cell);
                row.listNode.append(cellNode);
            }
        }
    };

    /**
     * Node click listener
     *
     * @param {JQueryEventObject} event
     */
    private toggleNodeListener = (event: JQueryEventObject): void => {

        const rowElement: any = angular.element(event.target).closest(".tree-list-node");
        const index: string = rowElement.attr("number") as string;

        const row: any = this.grid.rows[index];

        if (row[this.grid.childNodesKey] != void 0) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        this.grid.rowController.toggleRow(row);
    };

    /**
     * set row properties according to the config and add level and parent properties
     *
     * @param {Array<any>} nodes - the items of this node
     * @param {number} nodeLevel - the current level
     * @param parent             - the parent of the current nodes
     */
    setRowProperties = (nodes: any[], nodeLevel: number = 0, parent: any): void => {

        for (const node of nodes) {

            node.level = nodeLevel;

            if (parent != void 0) {
                node.parent = parent;
            }

            node.isSelectable = !this.readonly && (node.selectable != void 0 ? node.selectable : true);
            node.isSelected = !!node.isSelected;
            node.isVisible = parent == void 0 || parent != void 0 && parent.expanded;

            if (this.isCollapsible) {
                node.isCollapsible = node.isCollapsible == void 0 ? true : node.isCollapsible;
            }

            if (node.isSelected) {
                this.selectedRows.push(node);
            }

            if (node[this.grid.childNodesKey]) {
                node.expanded = this.expandNodesByDefault || node.expanded;
                node.isSelectable = this.readonly ? !this.readonly : this.useIntermediateNodes;
                node.isSelectable = node.isSelectable && (node.selectable != void 0 ? node.selectable : true);

                this.setRowProperties(node[this.grid.childNodesKey], nodeLevel + 1, node);
            }
        }
    };

    /**
     * set the row indexes for each row
     */
    setRowIndexes = (): void => {
        for (const i in this.grid.rows) {
            this.grid.rows[i].index = Number(i);
        }
    };

    /**
     * sets the cummulative count of children per node
     *
     * @param {Array<any>} rows
     */
    setTotalChildCounts = (rows: any[]): void => {
        for (const row of rows) {
            row.childCountTotal = this.getCompleteChildCount(row.index);
        }
    };

    /**
     * Return the complete child count, not just one node level but the whole recursive structurefolder.dir
     *
     * @param rowIndex - the row to get the child count from
     * @returns {number}
     */
    getCompleteChildCount = (rowIndex: number): number => {
        const childCount: number = this.grid.rows.length - 1 - rowIndex;
        const row: any = this.grid.api.getRowByKey("index", rowIndex);
        for (let i: number = Number(row.index) + 1; i < this.grid.rows.length; i++) {
            if (this.grid.rows[i].level <= row.level) {
                return this.grid.rows[i - 1].index - row.index;
            }
        }

        return childCount;
    };

    /**
     * creates an array with all rowindexes that are currently displayable
     * this does not mean that every row is visible on the screen but these rows can be reached by scrolling
     */
    rebuildVisibleRowMap = (): void => {
        this.grid.visibleRowIndices = [];

        for (const i in this.grid.rows) {
            if (this.grid.rows[i].isVisible) {
                this.grid.visibleRowIndices.push(Number(i));
            }
        }
    };

    /**
     * toggle the visibility of the rendered rows when there are more rendered rows than content rows
     */
    toggleRenderedRowVisibility = (): void => {
        for (const row of this.grid.renderedRows) {
            if (this.grid.visibleRowIndices.indexOf(row.index) == -1) {
                row.listNode.hide();
            } else {
                row.listNode.show();
            }
        }
    };

    /**
     * Fill the grid with with rows for every visible entry.
     *
     * @param {boolean} forceRefresh - refreshes every row even if it is not necessary
     */
    renderRows = (forceRefresh: boolean = false): void => {
        for (const row of this.grid.renderedRows) {
            if (row.index > this.grid.rows.length - 1 || row.index < 0) {
                break;
            }

            if (row.listNode.attr("number") == row.index && !forceRefresh) {
                continue; // no changes continue with the next row
            }

            this.renderRow(row);
        }
    };

    /**
     * render a single row and update classes
     *
     * @param row
     */
    private renderRow(row: any): void {
        row.listNode.attr("number", row.index);
        row.listNode.css("top", row.top);

        this.toggleSelectionClasses(row);

        for (const j in row.cells) {
            const cell: any = row.cells[j];

            cell.rowIndex = row.index;
            cell.colIndex = j;
            cell.rowData = this.grid.rows[row.index];

            if (cell.textNode != void 0) {
                if (cell.cellRenderer != void 0 && typeof(cell.cellRenderer) == "function") {
                    const content: JQuery = cell.cellRenderer(cell);

                    if (content != void 0) {
                        cell.textNode[0].innerHTML = content[0].outerHTML;
                    }

                } else if (typeof(this.valueGetter) == "function") {
                    cell.textNode[0].innerHTML = this.valueGetter(cell);
                } else if (cell.isIconCell) {
                    cell.textNode[0].innerHTML = this.grid.rows[row.index][cell.field];
                } else {
                    cell.textNode.text(this.grid.rows[row.index][cell.field]);
                    cell.textNode[0].title = this.grid.rows[row.index][cell.field];
                }
            }

            if (this.grid.columnController.columns[cell.colIndex].showAsTree) {
                cell.cellNode.css({"padding-left": `${this.gPadding * this.grid.rows[row.index].level}px`});

                if (cell.treeNode != void 0) {
                    const children: any[] = this.grid.rows[row.index][this.grid.childNodesKey];

                    if (children != void 0 && children.length > 0 && this.grid.rows[row.index].isCollapsible) {

                        cell.treeNode.removeClass("tree-empty");

                        if (this.grid.columnController.columns[cell.colIndex].isFacetIcon) {
                            cell.facetNode.addClass("facet-node-icon");
                            cell.facetNode.addClass(cell.rowData.iconClass);
                        }

                        if (this.grid.rows[row.index].expanded && !this.grid.rows[row.index].loading) {
                            cell.treeNode.addClass("tree-expanded");
                            cell.treeNode.removeClass("tree-collapsed");
                            cell.treeNode.removeClass("loading-content");
                        } else {
                            cell.treeNode.addClass("tree-collapsed");
                            cell.treeNode.removeClass("tree-expanded");
                            if (this.grid.rows[row.index].loading) {
                                cell.treeNode.addClass("loading-content");
                            }
                        }
                    } else {
                        if (this.grid.columnController.columns[cell.colIndex].isFacetIcon) {
                            cell.treeNode.addClass(cell.rowData.iconClass);
                            cell.facetNode.removeClass();
                        } else {
                            cell.treeNode.addClass("tree-empty");
                        }

                        cell.treeNode.removeClass("tree-expanded");
                        cell.treeNode.removeClass("tree-collapsed");
                    }
                }
            }

            if (cell.iconNode != void 0) {
                if (cell.rowData.iconClass) {
                    cell.iconNode.parent().addClass(cell.rowData.iconClass);
                    cell.iconNode.parent().addClass("eob-icon-16");
                    cell.iconNode.remove();
                } else if (cell.rowData.icon && !isNaN(cell.rowData.icon)) {
                    const iconSource: string = `${this.grid.injections.ProfileService.getCurrentBaseUrl()}/osrest/api/icon/${cell.rowData.icon}`;
                    cell.iconNode.attr("src", iconSource);
                } else if (cell.iconNode.attr("src") != void 0 && cell.iconNode.attr("src") != "") {
                    cell.iconNode[0].removeAttribute("src");
                } else if (cell.cellNode.hasClass("eob-icon-16")) {
                    cell.cellNode[0].className = this.gCellNodeClasses;
                    cell.cellNode.append(cell.iconNode);
                } else {
                    cell.iconNode.remove();
                }
            }
        }
    }

    /**
     * Align the indexes of the rows and the indexes of the array
     * e.g. after scrolling the rows will get shifted and might look like this
     * [5 , 6 , 7 , 8 , 9 , 1 , 2 , 3 , 4] and realign them so it will look like this
     * [1 , 2 , 3 , 4 , 5 , 6 , 7 , 8 , 9]
     */
    reorderRenderedRows = (): void => {

        let emptyRows: any[] = [];

        for (let i: number = 0; i < this.grid.renderedRowCount; i++) {

            if (i >= this.grid.renderedRows.length) {
                break;
            }

            const row: any = this.grid.renderedRows[i];
            if (row.index == -1) {
                emptyRows = emptyRows.concat(this.grid.renderedRows.splice(i, 1));
                i--;
            }
        }

        for (let i: number = 0; i < this.grid.renderedRowCount; i++) {

            const row: any = this.grid.renderedRows[i];
            const next: any = this.grid.renderedRows[i + 1];

            if (row == void 0 || next == void 0) {
                break;
            }

            if (next.index < row.index) {
                const first: any = this.grid.renderedRows.slice(0, i + 1);
                const last: any = this.grid.renderedRows.slice(-(this.grid.renderedRowCount - emptyRows.length - i - 1));

                this.grid.renderedRows = last.concat(first);
                break;
            }
        }

        this.grid.renderedRows = this.grid.renderedRows.concat(emptyRows);
    };

    /**
     * Alter the selection class of the row
     *
     * @param row
     */
    private toggleSelectionClasses(row: any): void {
        if (this.grid.rows[row.index].isSelected) {
            row.listNode.addClass("selected");
        } else {
            row.listNode.removeClass("selected");
        }

        if (!this.grid.rows[row.index].isSelectable) {
            row.listNode.addClass("not-selectable");
            row.listNode.removeClass("selectable");
        } else {
            row.listNode.addClass("selectable");
            row.listNode.removeClass("not-selectable");
        }
    }

    /**
     * Recalculate the position of the rendered rows when they are removed or the visibility changes
     *
     * @param startTop - optional - the position where to start calculating
     * @param startIndex - optional - the start index
     */
    calculateRowPosition = (startTop: number, startIndex: number): void => {
        let currentIndex: number = startIndex != void 0 ? startIndex : this.grid.renderedRows[0].index;
        const firstRowTop: number = startTop != void 0 ? startTop : this.grid.renderedRows[0].top;

        let count: number = 0;

        while (count < this.grid.renderedRowCount) {

            if (this.grid.rows[currentIndex] == void 0) {
                break;
            }

            if (this.grid.rows[currentIndex].isVisible) {
                this.grid.renderedRows[count].index = currentIndex;
                this.grid.renderedRows[count].top = (count * this.grid.config.rowHeight) + firstRowTop;

                count++;
            }

            currentIndex++;
        }

        // we did not reach all rendered rows at this point
        // so we are going to reset the index and the top position of the remaining rows
        while (count < this.grid.renderedRowCount) {

            const visibleRowIndex: number = this.grid.visibleRowIndices.indexOf(this.grid.renderedRows[0].index) - 1;
            let shiftedRows: number = 0;
            while (visibleRowIndex - shiftedRows >= 0 && count < this.grid.renderedRowCount) {

                this.grid.renderedRows[count].index = this.grid.visibleRowIndices[visibleRowIndex - shiftedRows];
                this.grid.renderedRows[count].top = this.grid.renderedRows[0].top - (this.grid.config.rowHeight * (shiftedRows + 1));

                count++;
                shiftedRows++;
            }

            if (count >= this.grid.renderedRowCount) {
                break;
            }

            this.grid.renderedRows[count].index = -1;
            this.grid.renderedRows[count].top = (count * this.grid.config.rowHeight) + firstRowTop;

            count++;
        }
    };

    /**
     * detaches the row and all it's children with the given index and removes it from the grid
     *
     * @param {number} rowIndex - the row to detach
     * @returns {Array<any>} - the row and it's children
     */
    detachRowByIndex = (rowIndex: number): any[] => {

        let rowsToRemove: number = rowIndex == this.grid.rows.length - 1 ? 1 : this.grid.rows.length - rowIndex;
        const rowToStart: any = this.grid.rows[rowIndex];
        for (let i: number = Number(rowToStart.index) + 1; i < this.grid.rows.length; i++) {

            if (this.grid.rows[i].level <= rowToStart.level) {
                rowsToRemove = this.grid.rows[i - 1].index - rowIndex + 1;
                break;
            }
        }

        if (rowToStart.parent != void 0) {
            for (const i in rowToStart.parent[this.grid.childNodesKey]) {
                const childNode: any = rowToStart.parent[this.grid.childNodesKey][i];

                if (childNode.index == rowToStart.index) {
                    rowToStart.parent[this.grid.childNodesKey].splice(i, 1);
                    break;
                }
            }
        }

        return this.grid.rows.splice(rowIndex, rowsToRemove);
    };

    /**
     * Move a row with it's children (if available) to the given destination index
     *
     * @param rowIndexToMove - the row to move
     * @param rowIndexToAppend - the index where to move the row (and it's children)
     * @param appendAsChild - whether to append the moving row to the destination or insert it before
     */
    moveRow = (rowIndexToMove: number, rowIndexToAppend: number, appendAsChild: boolean): void => {
        const targetRow: any = this.grid.api.getRowByKey("index", rowIndexToAppend);

        // if we attach the node as a child, we have to attach it as the last child of the target row
        let targetRowIndex: number = appendAsChild ? +targetRow.index + this.getCompleteChildCount(rowIndexToAppend) + 1 : rowIndexToAppend;

        const targetRowChildCountBefore: number = targetRow.childCountTotal;

        const rowsToMove: any[] = this.detachRowByIndex(rowIndexToMove);
        const rowToMove: any = rowsToMove[0];

        this.setRowIndexes();

        if (appendAsChild) {
            // in case the row to attach was already at the right index, but on the wrong level, we just add another 1
            // to add it as the child of the target row
            targetRowIndex = rowToMove.index == +targetRow.index + 1 ? rowToMove.index : targetRowIndex;
        } else {
            targetRowIndex = rowIndexToAppend;
        }

        const targetRowChildCountAfter: number = this.getCompleteChildCount(targetRow.index);
        if (rowIndexToMove < rowIndexToAppend || targetRowChildCountAfter < targetRowChildCountBefore) {
            // at this point we removed n rows from the array and the target row moved up by the number of rows removed
            targetRowIndex -= rowsToMove.length;
        }

        const args: any = [targetRowIndex, 0].concat(rowsToMove);
        Array.prototype.splice.apply(this.grid.rows, args);

        const sourceRow: any = rowToMove.parent;
        rowToMove.parent = targetRow;
        rowToMove.isVisible = true;
        targetRow[this.grid.childNodesKey].push(rowToMove);

        const baseLevel: number = appendAsChild ? +targetRow.level + 1 : targetRow.parent != void 0 ? targetRow.parent.level : 0;
        this.rebaseRowLevel(rowsToMove[0], baseLevel);

        targetRow.childCountTotal = this.getCompleteChildCount(targetRow.index);
        sourceRow.childCountTotal = this.getCompleteChildCount(sourceRow.index);

        this.toggleRow(targetRow, true);

        this.grid.api.refreshGrid();
    };

    /**
     * rebases the level of a row and all its children in the case they were moved to another level
     *
     * @param row - the row to start rebasing
     * @param {number} baseLevel - the new base level
     */
    private rebaseRowLevel(row: any, baseLevel: number): void {
        row.level = baseLevel;

        if (row[this.grid.childNodesKey] != void 0) {
            for (const i in row[this.grid.childNodesKey]) {
                this.rebaseRowLevel(row[this.grid.childNodesKey][i], baseLevel + 1);
            }
        }
    }

    /**
     * inserts rows at the given index
     *
     * @param {number} rowIndexToInsert
     * @param {Array<any>} rowsToInsert
     * @param {boolean} insertAsChilds - whether to append the rows to the given rowindex as children or insert it before
     */
    insertRows = (rowIndexToInsert: number, rowsToInsert: any[], insertAsChilds: boolean): void => {
        let args: any;
        const rows: any[] = this.grid.flatten(rowsToInsert);
        const startRow: any = this.grid.api.getRowByKey("index", rowIndexToInsert);
        if (insertAsChilds) {
            this.setRowProperties(rowsToInsert, +startRow.level + 1, startRow);
            args = [+startRow.index + 1, 0].concat(rows);
            Array.prototype.splice.apply(this.grid.rows, args);

            startRow[this.grid.childNodesKey] = startRow[this.grid.childNodesKey].concat(rowsToInsert);
        } else {
            this.setRowProperties(rowsToInsert, 0, null);
            this.grid.rows = this.grid.rows.concat(rows);
        }

        this.setRowIndexes();
        this.setTotalChildCounts(rows);

        this.grid.api.refreshGrid();
    };

    /**
     * expand or collapse a row
     *
     * @param row - the row to open or close
     * @param bool - whether the row shall be opened or closed
     */
    toggleRow = (row: any, bool: boolean): void => {

        if (row.expanded == bool && bool != void 0) {
            return; // already expanded or collapsed ... nothing to do
        }

        this.reorderRenderedRows();

        row.expanded = bool != void 0 ? bool : !row.expanded;

        if (row.expanded && typeof (this.onNodeExpandAsync) == "function") {
            row.loading = true;

            for (const renderedRow of this.grid.renderedRows) {
                if (renderedRow.index == row.index) {
                    this.renderRow(renderedRow);
                    break;
                }
            }

            this.onNodeExpandAsync({rowData: row, api: this.grid.api}, () => {
                row.loading = false;
                this.expandCollapse(row);
            });

        } else {
            this.expandCollapse(row);
        }
    };

    /**
     * toggle visibility according to the parent visible state
     *
     * @param row - the row to start from
     */
    private expandCollapse(row: any): void {

        const currentLevel: number = row.level;
        for (let i: number = Number(row.index) + 1; i < this.grid.rows.length; i++) {
            if (this.grid.rows[i].level > currentLevel) {
                const parent: any = this.grid.rows[i].parent;
                this.grid.rows[i].isVisible = row.expanded ? (parent.expanded && parent.isVisible && !this.grid.rows[i].isFiltered) : row.expanded;
            } else {
                break;
            }
        }

        // config property ... only deselect when this property is set to true, default is true
        if (this.deselectWhenCollapse) {
            this.deselectInvisible();
        }

        this.grid.api.refreshGrid();
    }

    /**
     * deselect all invisible rows
     */
    private deselectInvisible(): void {
        for (const row of this.grid.rows) {

            if (!row.isVisible && row.isSelected) {
                this.grid.api.deselectRow(row);
            }
        }
    }
}
