import {Directive, HostListener, Input, OnDestroy, OnInit} from "@angular/core";
import {StandaloneValidationBubbleComponent} from "MODULES_PATH/form/components/standalone-validation-bubble/standalone-validation-bubble.component";
import {FormControl} from "@angular/forms";
import {first} from "rxjs/operators";
import {Subscription} from "rxjs";
import {BubbleBoxStyle} from "INTERFACES_PATH/validation.interface";

@Directive({
    selector: "[eobDisplayBubble]"
})
export class DisplayBubbleDirective implements OnInit, OnDestroy {
    @Input() bubble: StandaloneValidationBubbleComponent;
    @Input() control: FormControl;
    @Input() host: HTMLElement;

    private subs: Subscription = new Subscription();
    private active: boolean;
    private title: string;
    private message: string;

    ngOnInit(): void {
        if (!this.control) {
            console.error("FormControl missing. Directive won't function as expected.");
            console.info(this);
            this.control = new FormControl();
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    @HostListener("focus")
    focus(): void {
        if (this.control.status == "INVALID" && this.control.dirty) {
            this.show();
            this.subs.add(this.control.valueChanges.pipe(first()).subscribe(() => {
                this.hide();
            }));
        }
    }

    @HostListener("blur")
    blur(): void {
        this.hide();
    }

    @HostListener("mouseover")
    mouseover(): void {
        if (this.control.status == "INVALID" && this.control.dirty) {
            this.show();
        }
    }

    @HostListener("mouseout")
    mouseout(): void {
        if (this.active && document.activeElement != this.host) {
            this.hide();
        }
    }

    setValidationMessage(title: string, message: string): void {
        this.title = title;
        this.message = message;
        this.bubble?.setValidationMessage(title, message);
    }

    private show(): void {
        if (this.bubble.enablePositioning) {
            this.bubble.setValidationMessage(this.title, this.message);
            this.bubble.setPosition(this.getPosition());
        }

        this.bubble.showBubble();
        this.active = true;
    }

    hide(): void {
        this.bubble?.hideBubble();
        this.active = false;
    }

    private getPosition(): BubbleBoxStyle {
        const css: BubbleBoxStyle = {
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto"
        };

        const screenPos: DOMRect = this.host.getBoundingClientRect();
        css.left = screenPos.left;

        if (typeof screenPos.bottom === "number") {
            css.top = screenPos.bottom + window.scrollY + 4;
        }

        return css;
    }
}
