import {HitlistEvent} from "MODULES_PATH/hitlist/enums/hitlist-event.enum";
import {ColumnState} from "ag-grid-community/dist/lib/columnController/columnController";
import {Inject, Injectable} from "@angular/core";
import {SortService} from "CORE_PATH/services/utils/sort.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ColDef, ColGroupDef, GridOptions, RowNode} from "ag-grid-community";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {GridData} from "MODULES_PATH/grid/interfaces/grid-data.interface";
import {ColumnDefinition} from "MODULES_PATH/grid/interfaces/column.interface";
import {AsIniHitlistConfiguration} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {HitlistConfig, SortModel, StoredParams} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {TodoCacheManagerService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";

@Injectable({
    providedIn: "root"
})
export class HitListApiService {
    private readonly translateFn: TranslateFnType;
    private readonly isPhone: boolean;

    constructor(@Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("cacheManagerService") protected cacheManagerService: TodoCacheManagerService,
                @Inject("stateHistoryManager") protected stateHistoryManager: TodoStateHistoryManager,
                protected actionService: ActionService,
                protected viewerService: ViewerService,
                protected gridContentService: GridContentService,
                protected clientService: ClientService,
                protected messageService: MessageService,
                protected sortService: SortService,
                protected asIniService: AsIniService
    ) {
        this.translateFn = this.$filter("translate") ;
        this.isPhone = this.clientService.isPhone();
    }

    setHitListSettings = (hitListConfig: HitlistConfig): void => {
        try {
            const hitListSettings: AsIniHitlistConfiguration = this.asIniService.getHitlistConfiguration(this.stateHistoryManager.getCurrentStateData().data);
            const gridOptions: any = hitListConfig.api.getGridOptions();

            if (gridOptions.api == void 0 || hitListSettings == void 0) {
                return;
            }

            if (gridOptions.api.rowModel?.rowsToDisplay?.length > 0) {
                const postfix: string = this.getPostfix(gridOptions.columnApi.getColumnState());
                const sortModel: SortModel[] = this.getSortModel(postfix, hitListConfig, hitListSettings.type);

                if (hitListSettings.type == "history" || hitListSettings.type == "hitlist.history" || hitListSettings.type == "fulltextResult" || hitListSettings.type == "hitlist.fulltextResult") {
                    const colName: string = hitListSettings.type == "history" || hitListSettings.type == "hitlist.history" ? this.translateFn("eob.grid.systemcolumn.modified.date") : this.translateFn("eob.grid.systemcolumn.ranking");
                    const col: ColumnDefinition = gridOptions.api.columnController.getAllDisplayedColumns().find(x => x.colDef.headerName == colName);

                    if (col && sortModel.length == 0) {
                        sortModel.push({colId: col.colId, sort: "desc"});
                    }
                }

                this.setSortModel(gridOptions, sortModel);

                if (!this.isPhone) {
                    if (hitListSettings.width != void 0) {
                        this.setColumnWidths(hitListConfig);
                    }

                    if (hitListSettings.groups != void 0) {
                        this.setGroupingConfig(hitListConfig, hitListSettings.expandedGroups);
                    }
                }
            }
        } catch (error) {
            console.error("hit list settings could not be set", error);
        }
    };

    /**
     * Remove an entry from the grid.
     *
     * @param {DmsDocument} hitlistConfig
     * @param {DmsDocument} docModel - Delete the entry that belongs to this dmsDocument.
     * @param {boolean} [keepViewer=false] - Don't clear the viewer, if the new grid is empty.
     */
    deleteItem = (hitlistConfig: HitlistConfig, docModel: DmsDocumentModel, keepViewer: boolean): void => {
        const gridItems: DmsDocument[] = [];
        const rows: any[] = hitlistConfig.rows;

        for (const i in hitlistConfig.rows) {
            if (docModel.id != rows[i].id) {
                const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(rows[i].osid);
                gridItems.push(dmsDocument);
            }
        }

        const config: GridData = this.gridContentService.getListEntries(gridItems, hitlistConfig.context);

        this.messageService.broadcast(HitlistEvent.UPDATE_ROWS, {
            config,
            isGridContentChange: false,
            keepViewer,
            executeInstantly: true,
            context: hitlistConfig.context
        });
    };

    addItem = (hitListConfig: HitlistConfig, item: DmsDocument, keepViewer: boolean): void => {
        const gridItems: DmsDocument[] = [];

        for (const row of hitListConfig.rows) {
            const doc: DmsDocument = this.cacheManagerService.dmsDocuments.getById(row.osid);
            gridItems.push(doc);
        }

        const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(item.model.id);
        gridItems.push(dmsDocument);

        const config: GridData = this.gridContentService.getListEntries(gridItems, hitListConfig.context);

        this.messageService.broadcast(HitlistEvent.ITEM_ADDED, {
            context: hitListConfig.context,
            columns: config.columns,
            rows: config.rows
        });

        const gridOptions: GridOptions = hitListConfig.api.getGridOptions();

        gridOptions.rowData = config.rows;
        gridOptions.api.forEachNode((node) => {
            if (item.model.id == node.data.id) {
                node.setSelected(true, true);
                gridOptions.api.ensureNodeVisible(node);
                gridOptions.api.refreshHeader();

                if (keepViewer !== true) {
                    this.messageService.broadcast(HitlistEvent.UPDATE_VIEWER, item.model);
                }

                if (typeof (hitListConfig.onSelectionChange) == "function") {
                    hitListConfig.onSelectionChange(node.data);
                }
            }
        });
    };

    selectFirstItem = (hitListConfig: HitlistConfig): void => {
        // Ensure getting sorted entries after the actual sort options have been set
        setTimeout(() => {
            if (hitListConfig.api.getGridOptions().api != void 0 && hitListConfig.rows.length > 0) {
                let selected = false;

                hitListConfig.api.getGridOptions().api.forEachNodeAfterFilterAndSort(node => {
                    if (selected) {
                        return;
                    }
                    if (node != void 0 && !node.group && !selected) {
                        this.selectNode(node, false);
                        selected = true;
                    }
                });
            }
        }, 100);
    };

    selectNode = (node: RowNode, itemSelected: boolean): boolean => {
        node.setSelected(true, false);

        if (!itemSelected) {
            this.messageService.broadcast(HitlistEvent.REFRESH_GROUPING_TAGS, {
                node,
                data: node.data
            });

            this.messageService.broadcast(HitlistEvent.UPDATE_VIEWER, node.data);

            itemSelected = true;
        }

        return itemSelected;
    };

    selectItems = (gridOptions: GridOptions, arrayOfIds: string[], selectionKey: string): void => {
        if (arrayOfIds === void 0 || arrayOfIds.length === 0) {
            return;
        }

        if (!selectionKey.includes(".")) {
            gridOptions.api.forEachNode((node) => {
                if (node.data != void 0 && arrayOfIds.includes(node.data[selectionKey])) {
                    node.setSelected(true);
                    gridOptions.api.refreshHeader();
                }
            });
        } else {
            const selectionKeys: string[] = selectionKey.split(".");

            gridOptions.api.forEachNode((node) => {
                if (arrayOfIds.includes(node.data[selectionKeys[0]][selectionKeys[1]])) {
                    node.setSelected(true);
                    gridOptions.api.refreshHeader();
                }
            });
        }
    };

    /**
     * Apply column width settings only for non-icon cell.
     */
    setColumnWidths = (hitListConfig: HitlistConfig): void => {
        const widths: string | number[] = Object.values(this.getWidths(hitListConfig));
        const columnState: ColumnState[] = hitListConfig.api.getGridOptions().columnApi.getColumnState();
        let counter: number = 0;

        for (let i: number = 0; i < columnState.length; i++) {
            if (!hitListConfig?.columns[i]?.isIconCell) {
                columnState[i].width = widths[counter++];
            }
        }

        hitListConfig.api.getGridOptions().columnApi.setColumnState(columnState);
    };

    /**
     * Get all column widths.
     *
     * @returns {object} A column id to width map.
     */
    getWidths = (hitListConfig: HitlistConfig): AsIniHitlistConfiguration => this.getPropertySettings("width", hitListConfig.columns);

    /**
     * @see https://www.ag-grid.com/javascript-grid-column-api/
     * @param columnState of the current grid
     * @return postfix, e.g. _1 or an empty string like so ""
     */
    getPostfix(columnState: ColumnState[]): string {
        let colId: string;

        if (columnState.length > 0) {
            colId = columnState[0].colId;
            if (colId.indexOf("_") > 0) {
                return colId.substring(colId.lastIndexOf("_"), colId.length);
            }
        }

        return "";
    }

    /**
     * Get all column sort settings in form of an agGrid sort model.
     *
     * @returns {{ colId: string, sort: string|number}[]} An agGrid sort model.
     */
    getSortModel(postfix: string, hitListConfig: HitlistConfig, type: string): SortModel[] {
        const sortModel: SortModel[] = [];
        const settings: AsIniHitlistConfiguration = this.getPropertySettings("sort", hitListConfig.columns);
        const isLocation: boolean = this.isLocation() && type != "folder";
        let colId: string;

        for (const key in settings) {
            if (settings[key]) {

                if (isLocation) {
                    const iconColumnsCount: number = hitListConfig.columns.filter(column => column.isIconCell).length - 1;
                    colId = `f${parseInt(key) + iconColumnsCount}`;
                    colId += postfix;
                } else {
                    colId = key;
                }

                if (settings[key].includes(":")) {
                    sortModel[parseInt(settings[key].split(":")[1])] = {
                        colId, sort: settings[key].split(":")[0],
                    };
                } else {
                    sortModel.push({
                        colId, sort: settings[key]
                    });
                }
            }
        }

        return sortModel;
    }

    /**
     * @return {boolean} whether the configuration is for a location or a flat location, false otherwise
     */
    isLocation(): boolean {
        const hitListSettings: AsIniHitlistConfiguration = this.asIniService.getHitlistConfiguration(this.stateHistoryManager.getCurrentStateData().data);

        return hitListSettings.type == "folder" || hitListSettings.type == "folderFlat";
    }

    /**
     * Apply grouping settings.
     */
    setGroupingConfig = (hitListConfig: HitlistConfig, expandedGroups: any): void => {
        const expandedGroupKeys: string[] = Object.keys(expandedGroups);
        const gridOptions: GridOptions = hitListConfig.api.getGridOptions();

        // Another layer of timeouting is required for the grid to do its job properly
        setTimeout(() => {
            gridOptions.api.forEachNode(node => {
                if (expandedGroupKeys.includes(node.key)) {
                    node.setExpanded(true);
                }
            });
        }, 500);

        const groups: AsIniHitlistConfiguration = this.getPropertySettings("groups", hitListConfig.columns);
        const postfix: string = this.getPostfix(gridOptions.columnApi.getColumnState());

        setTimeout(() => {
            const colDefs: ColDef[] | ColGroupDef[] = gridOptions.columnDefs;

            for (const colId in groups) {
                for (const colDef of colDefs) {
                    if (colDef?.field == colId) {
                        colDef.rowGroup = true;
                    }
                }

                gridOptions.columnApi.addRowGroupColumn(colId + postfix);
                gridOptions.columnApi.setColumnVisible(colId + postfix, true);
            }
        }, 0);

        gridOptions.api.refreshClientSideRowModel("aggregate");
    };

    /**
     * Get the settings of the given property from the hitlist configuration.
     *
     * @param {string} property - The property that shall be extracted from the hitlist.
     * @param {ColumnDefinition[]} columns - Hitlist columns.
     * @returns {object} A column id to property value map.
     */
    getPropertySettings(property: string, columns: ColumnDefinition[]): AsIniHitlistConfiguration {
        const settings: AsIniHitlistConfiguration = {};
        const hitListSettings: AsIniHitlistConfiguration = this.asIniService.getHitlistConfiguration(this.stateHistoryManager.getCurrentStateData().data);
        let isNotLocation: boolean = !this.isLocation();
        if (hitListSettings.type === "folder") {
            isNotLocation = true;
        }

        for (let columnIdentifier in hitListSettings[property]) {
            const settingValue: any = hitListSettings[property][columnIdentifier];
            columnIdentifier = this.getColId(columnIdentifier, isNotLocation, columns); // CHANGE THE LOCATION HERE TO REMOVE @@@ FROM FILED NAME
            if (columnIdentifier == void 0) {
                continue;
            }
            settings[columnIdentifier] = settingValue;
        }
        return settings;
    }

    /**
     * Get the agGrid column id for the given column identifier.
     *
     * @param {string} columnIdentifier - The column identifier used in the asini.
     * @param {boolean} getUnique - Whether a unique or anonymous identifier is given.
     * @param {ColumnDefinition[]} columns - hitlist columns.
     * @returns {string} The agGrid id of the column (f0, f1, ...).
     */
    getColId(columnIdentifier: string, getUnique: boolean, columns: ColumnDefinition[]): string {
        if (getUnique) {
            if (columnIdentifier.includes("@@@")) {
                const parts: string[] = columnIdentifier.split("@@@");
                columnIdentifier = parts[0];
            }

            const columnDef: ColumnDefinition = columns.find(column => column?.internalName === columnIdentifier || column?.headerName === columnIdentifier);

            return columnDef != void 0 ? columnDef.field : undefined;
        }

        return columnIdentifier;
    }

    /**
     * Rows in a certain context can have an event date which is provided here.
     *
     * @param {object} row to extract the event date from
     * @returns {number} the event date time stamp
     */
    getEventDate = (row: any): string => {
        let notifications: any[] = [];

        switch (row.context) {
            case "revisit":
                notifications = this.cacheManagerService.dmsDocuments.getById(row.osid).model.revisits;
                break;
            case "abo":
                notifications = this.cacheManagerService.dmsDocuments.getById(row.osid).model.subscriptions;
                break;
            case "subscriptionObjects":
                notifications = this.cacheManagerService.dmsDocuments.getById(row.osid).model.subscriptionObjects;
            default:
                return "";
        }

        notifications = notifications != void 0 ? notifications : [];

        const notification: any = notifications.find(n => n.model.guid == row.guid);

        return notification != void 0 ? notification.model.eventDate : "";
    };

    rememberGroupExpansion = (gridOptions: GridOptions): StoredParams => {
        // remember the expanded nodes if grid was grouped cause grouping info will get lost upon setColumnState()
        const expandedGroups: string[] = [];

        gridOptions.api.forEachNode((node) => {
            if (node.expanded) {
                expandedGroups.push(node.key);
            }
        });

        // remember selected rows
        let selectedIds: string[] = [];

        const selectedRows: any[] = gridOptions.api == void 0 ? [] : gridOptions.api.getSelectedRows();

        if (selectedRows?.length) {
            for (const i of selectedRows) {
                selectedIds.push(selectedRows[i].id);
            }
        } else {
            const items: any[] = this.stateHistoryManager.getCurrentConfig().selectedItems;
            const hasNoItems: boolean = items == void 0 || (Object.keys(items).length === 0 && items.constructor === Object);

            if (!hasNoItems) {
                selectedIds = Object.keys(items);
            }
        }

        const storedParams: StoredParams = {
            selectedIds,
            expandedGroups
        };

        // returns some params cause they are needed as parameter in the function for restoring the group expansion
        return storedParams;
    };

    restoreGroupExpansion = (storedParams: StoredParams, hitListConfig: HitlistConfig): void => {
        const gridOptions: GridOptions = hitListConfig.api.getGridOptions();
        const expandedGroups: string[] = storedParams.expandedGroups;

        let actualRowData: any[] = [];

        if (hitListConfig.rows != void 0 && hitListConfig.rows.length != gridOptions.rowData.length) {
            for (const i of gridOptions.rowData) {
                for (const j of hitListConfig.rows) {
                    if (gridOptions.rowData[i] && gridOptions.rowData[i].id == hitListConfig.rows[j].id) {
                        actualRowData.push(gridOptions.rowData[i]);
                    }
                }
            }

            gridOptions.rowData = actualRowData;
            hitListConfig.rows = actualRowData;
        } else {
            actualRowData = gridOptions.rowData;
        }

        setTimeout(() => {
            if (expandedGroups.length > 0) {
                gridOptions.api.forEachNode((node) => {
                    for (const i of expandedGroups) {
                        if (node.key == expandedGroups[i]) {
                            node.expanded = true;
                        }
                    }
                });
                // apply expansion state of groups to ag-grid again
                gridOptions.api.setRowData(actualRowData);
                // select previously selected item again because setRowData() removes the selection in groups
                this.messageService.broadcast("RESTORE_SELECTION", this.stateHistoryManager.getCurrentConfig());
                this.selectItems(hitListConfig.api.getGridOptions(), storedParams.selectedIds, "id");
            }
        }, 0);
    };

    setSortModel = (gridOptions: GridOptions, sortModel: ColumnState[]): void => {
        const columnState: ColumnState[] = [];

        if (sortModel) {
            sortModel.forEach((item: ColumnState, index: number) => {
                columnState.push({
                    colId: item.colId,
                    sort: item.sort,
                    sortIndex: index
                });
            });
        }

        gridOptions.columnApi.applyColumnState({ state: columnState, defaultState: { sort: null } });
    };
}
