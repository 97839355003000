import {Component, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {Progressbar, ProgressbarService} from "SERVICES_PATH/eob.progressbar.srv";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {
    TodoCacheManagerService,
    TodoFormHelper,
    TodoFormService,
    TodoSearchService,
    TodoFormData,
    TodoFormDef,
    TodoListEntry,
    TodoEnvironmentService
} from "INTERFACES_PATH/any.types";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {ChangeDetectorRef, ElementRef, Renderer2} from "@angular/core";
import {HitlistConfig} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {Field} from "INTERFACES_PATH/field.interface";
import {HelperConfig, QuickfinderGridData} from "MODULES_PATH/modal-dialog/interfaces/modal-quickfinder.interface";
import {OsrestSearchResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-search-result.interface";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {RowNode} from "ag-grid-community/dist/lib/entities/rowNode";
import {QuickfinderData, Config} from "MODULES_PATH/form/interfaces/quickfinder.addon.interface";
import {ObjectType} from "MODULES_PATH/form/interfaces/form-addon-config.interface";
import {FormField} from "MODULES_PATH/form/interfaces/form.interface";

@Component({
    selector: "eob-modal-quickfinder",
    templateUrl: "./eob-modal-quickfinder.component.html",
    styleUrls: ["./eob-modal-quickfinder.component.scss"]
})
export class EobModalQuickfinderComponent implements OnInit, OnDestroy {
    @Input() quickfinderdata: QuickfinderData;
    private readonly translateFn: TranslateFnType;
    showAltLang: boolean;
    objectDefLang: string;
    hitlistConfig: HitlistConfig;
    formFields: Field[];
    isQuickSearch = false;
    isQuickResult = false;
    isLoading: boolean;
    noHits: boolean;
    isSubmitting = false;
    progressBar: Progressbar;
    helperConfig: HelperConfig;
    formData: TodoFormData;
    formHelper: TodoFormHelper;
    formDef: TodoFormDef;

    constructor(@Inject("formService") private formService: TodoFormService,
                @Inject("formHelper") private injectFormHelper: TodoFormHelper,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("searchService") private searchService: TodoSearchService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                private asIniService: AsIniService,
                private progressbarService: ProgressbarService,
                private messageService: MessageService,
                private httpService: HttpService,
                private clientService: ClientService,
                private notificationsService: NotificationsService,
                private gridContentService: GridContentService,
                private renderer: Renderer2,
                private el: ElementRef,
                private cdRef: ChangeDetectorRef) {
        this.translateFn = $filter("translate");
    }

    ngOnInit(): void {
        this.formFields = this.quickfinderdata.typeDef.api.getFields(true);

        this.progressBar = this.progressbarService.getProgressbarInstance("loadAnimation", null, true);
        this.formData = this.formService.createFormData(this.formFields, "min");

        this.helperConfig = {
            formData: this.formData,
            modelDef: this.quickfinderdata.typeDef.model,
            isSearch: true,
            search: this.getSearchResultAsync
        };

        this.formHelper = this.injectFormHelper.getFormHelper(this.helperConfig);

        this.formDef = {
            isMainForm: true,
            validationMode: "min",
            formFields: this.formFields,
            formHelper: this.formHelper,
            formLayout: this.quickfinderdata.typeDef.api.getLayout(),
        };

        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang();
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();

        const foreignHelper: TodoFormHelper = this.quickfinderdata.formHelper,
            quickfinderField = this.quickfinderdata.field.model;

        let isEmpty = true;
        const objectTypes: ObjectType[] = quickfinderField.config.objectTypes;
        let currentType: ObjectType;

        for (const type of objectTypes) {
            const config: Config = this.quickfinderdata.typeDef.model.config;
            if (type.id == config.objectTypeId || type.id == config.internal) {
                currentType = type;
                break;
            }
        }

        if (currentType == void 0) {
            return;
        }

        isEmpty = this.isQuickfinderConfig(currentType, foreignHelper, quickfinderField, isEmpty);

        this.showQuickSearch(true);

        setTimeout(() => {
            if (!isEmpty) {
                void this.getSearchResultAsync();
            }
        }, 0);
    }

    ngOnDestroy(): void {
        if (this.formHelper != void 0) {
            this.formHelper.destroy();
        }
    }

    private isQuickfinderConfig(currentType, foreignHelper, quickfinderField, isEmpty: boolean) {
        for (let i = 0; i < currentType.fields.length; i++) {
            const field: string = currentType.fields[i];
            let fieldKey = "",
                fieldName: string = field;

            if (!field) {
                continue;
            } else if (field.indexOf("@@") == 0 && field.lastIndexOf("@@") == field.length - 2) {
                continue;
            } else if (field.charAt(0) == "%" && field.charAt(field.length - 1) == "%") {
                fieldKey = "internal";
                fieldName = fieldName.replace(/%/g, "");
            } else {
                fieldKey = "name";
            }

            const foreignFields = foreignHelper.getFields();
            if (i == 0 && foreignFields[quickfinderField.internal].value != "") {
                const mainField: FormField = this.formHelper.getFieldByAnyKey(fieldKey, fieldName);
                mainField.value = foreignFields[quickfinderField.internal].value;
                isEmpty = false;
                continue;
            }

            for (const key in foreignFields) {
                if (foreignFields[key].model.crossCheck == i && foreignFields[key].model.relatedControl == quickfinderField.tabIndex && foreignFields[key].model.pageIndex == quickfinderField.pageIndex) {

                    if (foreignFields[key].value != "") {
                        const currentField: FormField = this.formHelper.getFieldByAnyKey(fieldKey, fieldName);
                        currentField.value = foreignFields[key].value;
                        isEmpty = false;
                    }

                    break;
                }
            }
        }
        return isEmpty;
    }

    showQuickSearch = (isShowSearch: boolean): void => {
        this.isQuickSearch = !!isShowSearch;
        this.isQuickResult = !isShowSearch;
        setTimeout(() => {
            if (this.clientService.isLocalClient()) {
                const modalDialogContainerHeight: number = document.querySelector("#modalDialogContainer").clientHeight;
                const stateHeaderHeight: number = document.querySelector("eob-state-header").clientHeight;
                const hitList: HTMLElement = this.el.nativeElement.querySelector("eob-hit-list");
                if (hitList) {
                    this.renderer.setStyle(hitList, "height", `${modalDialogContainerHeight - stateHeaderHeight - 130}px`);
                }
            }
        }, 0);
    };

    applyEntryAsync = async (): Promise<void> => {
        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            return;
        }
        if (this.isSubmitting || this.hitlistConfig.api == void 0) {
            return;
        }

        this.isSubmitting = true;

        const selectedItem: RowNode = this.hitlistConfig.api.getSelectedRows()[0];

        try {
            const response = await this.httpService.legacyGet(`/documents/search/${selectedItem.id}?refresh=true`);
            this.quickfinderdata.applyData(response.data, this.quickfinderdata.typeDef.model.config.objectTypeId);
            this.close();
        } catch (error) {
            this.notificationsService.warning(this.translateFn("eob.object.not.found.error"));
        }

        this.isSubmitting = false;
    };

    getSearchResultAsync = async (): Promise<void> => {
        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            return;
        }
        this.isQuickSearch = false;
        this.isLoading = true;
        this.progressBar.show();

        const queryData = {
            formDataTypes: {},
            objectTypeIds: [this.quickfinderdata.typeDef.model.config.objectTypeId],
            description: this.quickfinderdata.typeDef.model.config.title
        };

        queryData.formDataTypes[this.quickfinderdata.typeDef.model.config.objectTypeId] = this.formHelper.getFields();

        try {
            const scriptResult = await this.searchService.executeQueryWithScriptsAsync(queryData, {registerContext: `${this.asIniService.isIncludeObjectsWithoutRegisterContext() ? 1 : 0}`});

            if (scriptResult.resultCode == -1) {
                this.showQuickSearch(true);
            } else if (scriptResult.resultCode == -2) {
                this.close();
                return;
            } else {
                this.fillGrid(scriptResult.searchResult);
                this.showQuickSearch(false);
            }

            this.isLoading = false;
        } catch (error) {
            this.fillGrid([]);
            this.showQuickSearch(false);
            this.notificationsService.error(this.translateFn("eob.result.error"));
        } finally {
            for (const field in this.formHelper.getFields()) {
                if (this.formHelper.getFields()[field].model.type == "pagecontrol") {
                    this.formHelper.getFields()[field].model.activePageIndex = 0;
                }
            }
            this.progressBar.hide();
            this.cdRef.detectChanges();
        }
    };

    private fillGrid = (searchResult: OsrestSearchResult[]): void => {
        this.noHits = false;
        const documentIds: TodoListEntry[] = this.cacheManagerService.dmsDocuments.add(searchResult);
        const dmsDocuments: TodoListEntry[] = this.cacheManagerService.dmsDocuments.get(documentIds);
        const listEntries: QuickfinderGridData = this.gridContentService.getListEntries(dmsDocuments, "quickfinder");

        listEntries.suppressStateUpdate = true;
        listEntries.suppressGrouping = true;
        listEntries.suppressMultiselect = true;
        listEntries.suppressViewerUpdate = true;
        listEntries.onDoubleClick = this.applyEntryAsync;

        listEntries.hideTouchKebab = true;

        this.hitlistConfig = listEntries;

        if (searchResult.length <= 0) {
            this.noHits = true;
        }
    };

    close = (): void =>
        this.messageService.broadcast(ModalEvents.DESTROY);
}
