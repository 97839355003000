import {Injectable} from "@angular/core";
import {ScriptingModule} from "MODULES_PATH/scripting/scripting.module";
import {DmsActionService} from "MODULES_PATH/dms/dms-action.service";
import {ScriptingSearchService} from "MODULES_PATH/scripting/scripting-search.service";
import {ScriptingDmsApi} from "./interfaces/scripting-dms-api.interface";

@Injectable({providedIn: ScriptingModule})
export class ScriptingDmsService {
    constructor(private dmsActionService: DmsActionService, private scriptingSearchService: ScriptingSearchService) {
    }

    /** Returns dms action functions used for scripting */
    getDmsActions(): ScriptingDmsApi {
        return {
            insert: this.dmsActionService.insertObject,
            update: this.dmsActionService.updateObject,
            delete: this.dmsActionService.deleteObject,
            moveItem: this.dmsActionService.moveItem.bind(this.dmsActionService),
            openIndexData: this.dmsActionService.openIndexData,
            openResultList: this.dmsActionService.openResultList,
            openResultListByIds: this.dmsActionService.openResultListByIds,
            openVariants: this.dmsActionService.openVariants,
            openLocation: this.dmsActionService.openLocation,
            getLocations: this.dmsActionService.getLocations,
            addLocation: this.dmsActionService.addLocation,
            search: this.scriptingSearchService.search.bind(this.scriptingSearchService),
            searchById: this.scriptingSearchService.searchById.bind(this.scriptingSearchService),
            searchDistinctValues: this.scriptingSearchService.searchDistinctValues.bind(this.scriptingSearchService)
        };
    }
}