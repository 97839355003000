import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {Observable, Subject} from "rxjs";
import {EobModalDeduplicationComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-deduplication/eob-modal-deduplication.component";
import {EobModalOrgMultiselectComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-org-multiselect/eob-modal-org-multiselect.component";
import {EobModalHitlistComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-hitlist/eob-modal-hitlist.component";
import {EobModalPasswordComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-password/eob-modal-password.component";

require("COMPONENTS_PATH/eob-modal-container/eob-modal-confirm/eob.modal.confirm.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-offline-info/eob.modal.offline.info.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-email/eob.modal.email.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-checkin/eob.modal.checkin.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-export/eob.modal.export.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-edit-content/eob.modal.edit.content.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-revisit/eob.modal.revisit.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-abo/eob.modal.abo.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-list/eob.modal.list.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-progress-status/eob.modal.progress.status.dir.ts");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-select-printer/eob.modal.select.printer.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-link-object/eob.modal.link-object.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-external-tray-object-types/eob.modal.external.tray.object.types.dir.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-upload/eob.modal.upload.dir.js");


angular.module("eob.core").factory("modalDialogService", ModalDialogService);

ModalDialogService.$inject = ["$rootScope", "$compile", "$q", "$filter", "$state", "notificationsService", "wfOrgAddonService",
    "backendService", "inboxActionService", "$injector", "clientService", "dmsDocumentService", "objectTypeService",
    "cacheManagerService", "errorModelService", "modalDialogInjectorService", "progressbarService"];

/**
 * A service that provides functions to show specific modal dialogs.
 */
export default function ModalDialogService($rootScope, $compile, $q, $filter, $state, NotificationsService, WfOrgAddonService,
                                           BackendService, InboxActionService, $injector, ClientService, DmsDocumentService, ObjectTypeService,
                                           CacheManagerService, ErrorModelService, ModalDialogInjectorService, ProgressbarService) {
    let gProgressStatusScope;

    let service = {
        errorInfoDialog,
        deleteVariantDialog,
        infoDialog,
        closeOpenDialogs,
        areModalDialogsOpen,
        openUserConfig,
        editDocumentContentDialog,
        dropzoneDialog,
        sendLinkDialog,
        sendContentDialog,
        exportIndexDataDialog,
        showPreviewUrlDialog,
        showOfflineInfoDialog,
        showDashboardSearchConfig,
        addRevisitDialog,
        addAboDialog,
        editAboDialog,
        openAssignPerformersDialogFromHitlist,
        openAssignPerformersDialog,
        openMultipleLocation,
        selectPrinterDialog,
        showProgressDialog,
        showProgressDialogWithCustomText,
        showCountingProgressDialog,
        showDeduplicationDialog,
        hideProgressDialog,
        showPasswordDialog,
        showOrgMultiselectDialog,
        showObjectTypeDialog,
        createLinkObjectDialog,
        createModalContainer,
        isEnabledInputField,
        openDepersonalizeDialog,
        assignPerformers
    };
    return service;

    /**
     * Show a simple dialog with a title, message and an optional area toggle to expand an error message. Optionally a cancel- and or submit button can be added.
     * @param {string} title - Title text.
     * @param {string} msg - Message text.
     * @param {string} cancel - Cancel button title.
     * @param {Error|string} error - Error to be shown inside area toggle.
     * @returns {Promise} A promise that will be resolved, once the dialog is submitted.
     */
    function errorInfoDialog(title, msg, cancel, error) {
        let dialogModel = "<eob-modal-confirm></eob-modal-confirm>",
            dialogAttributes = {
                buttons: {
                    cancel
                },
                error
            };

        if ((msg.indexOf("<") != -1) && (msg.indexOf(">") != -1)) {
            dialogAttributes.htmlMsg = msg;
        } else {
            dialogAttributes.msg = msg;
        }

        return createModalContainer(dialogModel, title, "", dialogAttributes, true);
    }

    /**
     * Show a confirm dialog, if the variants shall be deleted.
     *
     * @param {string} msg - The dialog message.
     * @returns {Promise} - The confirm dialog promise.
     */
    function deleteVariantDialog(msg) {
        let title = $filter("translate")("eob.action.modal.delete.variant.title"),
            cancelButton = $filter("translate")("modal.button.cancel"),
            deleteButton = $filter("translate")("modal.button.delete");
        return infoDialog(title, msg, cancelButton, deleteButton);
    }

    /**
     * Show a simple dialog with a title and message. Optionally a cancel- and or submit button can be added.
     * @param {string=} title - Title text.
     * @param {string=} msg - Message text.
     * @param {string=} cancel - Cancel button title.
     * @param {string=} submit - Submit button title.
     * @param {string=} ok - Ok button title.
     * @param {boolean=} noDismissing - Whether the user should not be able to close the dialog by himself
     * @param {object=} scopeAdditions Any attributes that should be added to the modal scope
     * @returns {Promise} A promise that will be resolved, once the dialog is submitted.
     */
    function infoDialog(title, msg, cancel, submit, ok, noDismissing = false, scopeAdditions) {
        let dialogModel = "<eob-modal-confirm></eob-modal-confirm>",
            dialogAttributes = {
                buttons: {
                    cancel,
                    submit,
                    ok
                }
            };
        if ((msg.indexOf("<") != -1) && (msg.indexOf(">") != -1)) {
            dialogAttributes.htmlMsg = msg;
        } else {
            dialogAttributes.msg = msg;
        }

        dialogAttributes.noDismissing = noDismissing;
        dialogAttributes.hideCloseHeaderButton = noDismissing;

        dialogAttributes.icon = (scopeAdditions || {}).icon;
        dialogAttributes.subMsg = (scopeAdditions || {}).subMsg;

        Object.assign(dialogAttributes, scopeAdditions)

        return createModalContainer(dialogModel, title, "", dialogAttributes, true);
    }

    /**
     * Closes all open modal dialogs and rejects their promises
     */
    function closeOpenDialogs() {
        // THIS SEEMS TO BE BROKEN, USE BROADCAST INSTEAD (s. app.component.ts -> closeDialogs() )
        for (let elem of document.querySelectorAll("div#modalDialog")) {
            angular.element(elem).scope().close();
        }
    }

    function areModalDialogsOpen() {
        return document.querySelectorAll("div#modalDialog").length != 0;
    }

    function arrayfy(arrayOrObject) {
        return !Array.isArray(arrayOrObject) ? [arrayOrObject] : arrayOrObject;
    }

    function dropzoneDialog(dmsDocument, additionalAttributes = {}) {
        let dialogModel = "<eob-modal-checkin></eob-modal-checkin>";
        return createSingleModalContainer(dialogModel, $filter("translate")("eob.action.modal.checkin.title"), dmsDocument, additionalAttributes);
    }

    function sendLinkDialog(dmsDocuments) {
        let dialogModel = "<eob-modal-email></eob-modal-email>";
        createMultiModalContainer(dialogModel, $filter("translate")("eob.action.modal.email.title"), $filter("translate")("eob.contextmenu.action.email.link.title"), arrayfy(dmsDocuments), { "sendcontent": false, "noDismissing": ClientService.isTouchDevice() });
    }

    function sendContentDialog(dmsDocuments) {
        let dialogModel = "<eob-modal-email></eob-modal-email>";
        createMultiModalContainer(dialogModel, $filter("translate")("eob.action.modal.email.title"), $filter("translate")("eob.contextmenu.action.email.content.title"), arrayfy(dmsDocuments), { "sendcontent": true, "noDismissing": ClientService.isTouchDevice() });
    }

    function exportIndexDataDialog(dmsDocuments) {
        let dialogModel = "<eob-modal-export></eob-modal-export>";
        createMultiModalContainer(dialogModel, $filter("translate")("eob.action.modal.export.indexdata.title"), "", arrayfy(dmsDocuments));
    }

    function editDocumentContentDialog(item, additionalAttributes = {}) {
        let dialogModel = "<eob-modal-edit-content></eob-modal-edit-content>";
        return createSingleModalContainer(dialogModel, $filter("translate")("eob.action.modal.edit.title"), item, additionalAttributes);
    }

    function addRevisitDialog(item) {
        let dialogModel = "<eob-modal-revisit></eob-modal-revisit>";
        createSingleModalContainer(dialogModel, $filter("translate")("eob.action.modal.revisit.title"), item);
    }

    function openMultipleLocation(objectTypeIdArray) {
        let modal$ = ModalDialogInjectorService.createDialog({ title: $filter("translate")("eob.action.modal.choose.location.title"),
            noDismissing: true
        }, { component: EobModalHitlistComponent, input: {items: objectTypeIdArray}});
        return modal$.toPromise();
    }

    async function selectPrinterDialog() {
        if (!ClientService.isDesktop()) {
            return Promise.reject("Not a desktop client");
        }
        let _res, _rej, promise = new Promise((resolve, reject) => {
            _res = resolve;
            _rej = reject;
        });

        let modalScope = $rootScope.$new();
        modalScope.resolve = _res;
        modalScope.reject = _rej;
        modalScope.printers = await window.electron.getPrinters();
        modalScope.destroy = new Subject();
        const title = $filter("translate")("eob.action.print.title");

        ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
            input: {
                title
            },
            childElement: angular.element("<eob-modal-select-printer printers=\"printers\" resolve=\"resolve\" reject=\"reject\" destroy=\"destroy\"></eob-modal-select-printer>"),
            scope: modalScope
        });

        $rootScope.$apply();
        return await promise;
    }

    function editAboDialog(item) {
        let dialogModel = "<eob-modal-abo></eob-modal-abo>";
        createMultiModalContainer(dialogModel, $filter("translate")("eob.action.modal.abo.edit.title"), "", item, { "isedit": true });
    }

    function addAboDialog(items) {
        let dialogModel = "<eob-modal-abo></eob-modal-abo>";
        createMultiModalContainer(dialogModel, $filter("translate")("eob.action.modal.abo.add.title"), "", items, { "isedit": false });
    }

    function showPreviewUrlDialog(item) {
        let dialogModel = "<eob-modal-url></eob-modal-url>";
        createSingleModalContainer(dialogModel, $filter("translate")("eob.action.modal.previewlink.title"), item);
    }

    function showOfflineInfoDialog() {
        let dialogModel = "<eob-modal-offline-info></eob-modal-offline-info>"
        return createModalContainer(dialogModel, $filter("translate")("eob.sync.info.dialog.title"), "");
    }

    function openUserConfig() {
        let dialogModel = "<eob-modal-user-config></eob-modal-user-config>";
        return createModalContainer(dialogModel, $filter("translate")("eob.action.modal.user.config.title"), "");
    }

    function createSingleModalContainer(dialogModel, title, item, additionalAttributes) {
        additionalAttributes = additionalAttributes || {};
        additionalAttributes.item = item;

        return createModalContainer(dialogModel, title, "", additionalAttributes);
    }

    function createMultiModalContainer(dialogModel, title, description, items, additionalAttributes) {
        if (!Array.isArray(items)) {
            items = [items];
        }

        additionalAttributes = additionalAttributes || {};
        additionalAttributes.items = items;

        return createModalContainer(dialogModel, title, description, additionalAttributes);
    }

    /**
     * Build and show a modal dialog.
     * @param {string} dialogModel - Element html of the dialog content directive.
     * @param {string} title - Title text of the dialog.
     * @param {string} description - Subtitle text of the dialog.
     * @param {Object=} attributes - A map of additional objects, that are needed by the dialog content directive.
     * @param {boolean=} rejection - Property rejection allows throwing an error to the promise if we handle the promise with a certain modal dialogue. The default value is false.
     * @returns {Promise}
     */

    function createModalContainer(dialogModel, title, description, attributes, rejection = false) {
        let modalScope = $rootScope.$new(),
            dialogElement = angular.element(dialogModel);

        for (let attrKey in attributes) {
            modalScope[attrKey] = attributes[attrKey];
            dialogElement.attr(attrKey, attrKey);
        }

        // destroy Subject to be used for tracking modal destroying inside of eob-modal-container
        modalScope.destroy = new Subject();
        dialogElement.attr("destroy", "destroy");

        let modalContainer$ = ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
            input: {
                title,
                description,
                rejection,
                noDismissing: modalScope.noDismissing
            },
            childElement: dialogElement,
            scope: modalScope
        });

        // If the dialogue is triggered from outside of the webclient (e.g. window close button), the root scope has to be applied
        // To avoid $apply() during digest, it is triggered with a delay
        setTimeout(() => {
            $rootScope.$apply();
        }, 0);

        if (attributes && attributes.buttons && Object.values(attributes.buttons).filter(x => x != undefined).length == 0) {
            const result = document.getElementsByClassName("button-container");
            result[0].setAttribute("style", "margin-bottom: 60px");
        }

        return modalContainer$.toPromise();
    }

    /**
     * Assign new performers to a specific workItem.
     * @param {string} workItemId - The id of a workItem.
     * @param {Object[]} performers - An array with workflow organisation objects.
     * @returns {Promise}
     */
    function assignPerformers(workItemId, performers) {
        let data = {
            "id": workItemId,
            "performers": []
        };

        for (let i = 0; i < performers.length; i++) {
            data.performers.push(performers[i].id);
        }

        return BackendService.post("/workflows/assignperformers", [data]).then(() => {
            NotificationsService.success($filter("translate")("eob.action.workflow.assign.performers.success"));
            return;
        }, (error) => {
            NotificationsService.backendError(error, "eob.action.workflow.assign.performers.error");
            return;
        });
    }

    /**
     * Open a confirm dialog and depersonalize the workItem, if the dialog is confirmed.
     *
     * @param {string} workItemId - The id of a workItem.
     * @param {string} processId - The id of a workflow process the workItem belongs to.
     * @returns {Promise} A promise that will be resolved once the workItem is personalized. Otherwise it will be rejected.
     */
    function openDepersonalizeDialog(workItemId, processId) {
        let deferred = $q.defer();

        let title = $filter("translate")("modal.confirm.workflow.depersonalize.title"),
            msg = $filter("translate")("modal.confirm.workflow.depersonalize.message"),
            cancel = $filter("translate")("modal.button.cancel", true),
            submit = $filter("translate")("modal.button.yes");

        infoDialog(title, msg, cancel, submit).then(() => {
            InboxActionService.depersonalizeWorkflows({ id: workItemId, processID: processId }).toPromise().then(() => {
                deferred.resolve();
                return;
            }, (error) => {
                deferred.reject(error);
                return;
            })
            .catch((error) => {console.error(error)})
            return;
        }, () => {
            // Cancel clicked. No error object returned.
            deferred.reject();
            return;
        })
        .catch(error => {console.error(error)})

        return deferred.promise;
    }

    /**
     * Depersonalize a workItem, if necessary,
     * and open a modal dialog to assign new performers to the workItem.
     * @param {Object} item - A process inbox item.
     */
    async function openAssignPerformersDialogFromHitlist(item) {
        let itemToUse = item.origin != void 0 ? item.origin : item;

        try {
            const changedData = await service.openAssignPerformersDialog(itemToUse.id, itemToUse.processId, itemToUse.personalized != void 0);

            if (changedData.personalizedBy != void 0) {
                delete itemToUse.personalized;
                $state.reload();
            }
        } catch (error) {
            /* dialog was cancelled */
        }
    }

    /**
     * Depersonalize a workItem, if necessary,
     * and open a modal dialog to assign new performers to the workItem.
     * @param {string} workItemId - The id of a workItem.
     * @param {string} processId - The id of the process the workItem belongs to.
     * @param {boolean} isPersonalized - Indicates if the workItem is personalized.
     * @returns {Promise} A promise that is resolved with an object that contains the edited data.
     */
    async function openAssignPerformersDialog(workItemId, processId, isPersonalized) {
        const changedData = {};

        // The workflow is already personalized. Open a confirm dialog and give the user the choice to clear the current personalization.
        if (isPersonalized) {
            await service.openDepersonalizeDialog(workItemId, processId);
            changedData.personalizedBy = "";
        }

        let res;
        try {
            res = await BackendService.get(`/workflows/running/performers?workitemId=${workItemId}`);
        } catch (error) {
            NotificationsService.backendError(error);
            return changedData;
        }

        const assignPerformer$ = new Subject();
        const config = {
            showAll: true
        };
        config.orgMember = WfOrgAddonService.getOrgList(config);

        const field = {
            value: res.data.filter(orgObj => orgObj.isDirectlyAssigned || orgObj.isDirectlyAssigned == undefined)
            .map(orgObj => orgObj.id).join(";"),
            model: {
                config
            },
            applySelection: async (selectedEntries) => {
                let performers = selectedEntries.map(entry => entry.value);

                // ask the user in another modal dialog, whether he really wants to submit the dialog without choosing any performers
                if (performers.length == 0) {
                    // wait for the former dialog to close or the new one will be closed immediatly instead
                    await new Observable(observer => { setTimeout(() => observer.complete(), 0); }).toPromise();

                    const title = $filter("translate")("eob.action.workflow.assign.no.performers.title"),
                        msg = $filter("translate")("eob.action.workflow.assign.no.performers.message"),
                        submit = $filter("translate")("modal.button.yes"),
                        cancel = $filter("translate")("modal.button.cancel");

                    try {
                        await service.infoDialog(title, msg, cancel, submit);
                    } catch (error) {
                        // Cancel button clicked. Assignment is cancelled.
                        assignPerformer$.next(changedData);
                        assignPerformer$.complete();
                        return;
                    }
                }

                try {
                    await service.assignPerformers(workItemId, performers);
                    changedData.performers = performers;
                } catch (error) {
                    console.error(error);
                }

                assignPerformer$.next(changedData);
                assignPerformer$.complete();
            }
        };
        const onCancel = () => {
            assignPerformer$.next(changedData);
            assignPerformer$.complete();
        }

        WfOrgAddonService.showOrganisationAddonByField(undefined, field, undefined, onCancel);

        return assignPerformer$.toPromise();
    }

    function showDashboardSearchConfig() {
        const DesktopService = $injector.get("desktopService");
        DesktopService.getQuickSearches();

        let modalScope = $rootScope.$new();

        ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
            input: {
                title: $filter("translate")("modal.dashboard.searches.config.title")
            },
            childElement: angular.element("<eob-modal-dashboard-searches-config></eob-modal-dashboard-searches-config>"),
            scope: modalScope
        });
    }

    /**
     * Shows a progress inidicator with a loading spinner and a message of current work.
     *
     * @access public
     * @param {string} messageKey - A language key which is translated into a language string and displayed.
     * @param {Subject} cancelSubject
     */
    function showProgressDialog(messageKey, cancelSubject) {
        showProgressDialogWithCustomText($filter("translate")(messageKey), cancelSubject)
    }

    /**
     * Shows a progress inidicator with a loading spinner and a message of current work.
     *
     * @access public
     * @param {string} message - Message to show inside the dialog
     * @param {Subject} cancelSubject
     */
    function showProgressDialogWithCustomText(message, cancelSubject) {
        let shouldApply = true;
        if (!gProgressStatusScope) {
            shouldApply = false;
            gProgressStatusScope = $rootScope.$new();
            gProgressStatusScope.binding = {};
            gProgressStatusScope.hideHeader = true;
            gProgressStatusScope.noDismissing = true;
            gProgressStatusScope.hideCloseHeaderButton = true;

            ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                input: {
                    noDismissing: true,
                    hideHeader: true
                },
                childElement: angular.element("<eob-modal-progress-status binding='binding' hide-close-header-button='hideCloseHeaderButton' no-dismissing='noDismissing' hide-header='hideHeader'></eob-modal-progress-status>"),
                scope: gProgressStatusScope
            });
        }

        if (cancelSubject) {
            if (gProgressStatusScope.binding.cancelSubject && !gProgressStatusScope.binding.cancelSubject.closed) {
                gProgressStatusScope.binding.cancelSubject.complete()
            }

            gProgressStatusScope.binding.cancelSubject = cancelSubject;
        }

        gProgressStatusScope.binding.statusText = message;
        if (shouldApply) {
            try {
                gProgressStatusScope.$apply();
            } catch (ignored) {
                console.error(ignored);
            }
        }
    }

    function showCountingProgressDialog(header, message, current, max) {
        if (!gProgressStatusScope) {
            gProgressStatusScope = $rootScope.$new();
            gProgressStatusScope.binding = {};
            gProgressStatusScope.binding.statusText = message;

            const childElement = angular.element("<eob-modal-progress-counter [binding]='binding'></eob-modal-progress-counter>");
            ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                input: {
                    title: header,
                    noDismissing: true,
                    hideHeader: false
                },
                childElement,
                scope: gProgressStatusScope
            });

            let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", childElement[0]);
            gProgressStatusScope.binding.progressBar = progressBar;

            progressBar.show();
        }

        gProgressStatusScope.binding.progressBar.updateValueXofMax(current, max);

        try {
            gProgressStatusScope.$apply();
        } catch (ignored) {
            console.error(ignored);
        }
    }

    function showDeduplicationDialog(emsDeduplicationOption) {
        let modal$ = ModalDialogInjectorService.createDialog({
                title: $filter("translate")("eob.ems.import.modal.progress.title"),
                noDismissing: true
            }, { component: EobModalDeduplicationComponent, input: { emsDeduplicationOption }});

        return modal$.toPromise();
    }

    /**
     * Hides the progess indicator. Please call this method without any parameters. The given parameter is
     * an internal one and used to avoid ring calls by clicking in the modal dialog on close/abort.
     *
     * @access public
     * @param {boolean} calledFromCloseButton - This method was called by clicking the close button on the modal dialog.
     */
    function hideProgressDialog(calledFromCloseButton = false) {
        if (gProgressStatusScope) {
            gProgressStatusScope.noDismissing = false;

            if (!calledFromCloseButton) {
                gProgressStatusScope.binding.closeProgressDialog();
            }

            if (gProgressStatusScope && gProgressStatusScope.binding.cancelPromise && gProgressStatusScope.binding.cancelPromise.promise.$$state.status == 0) {
                gProgressStatusScope.binding.cancelPromise.resolve();
            }

            gProgressStatusScope = null;
        }
    }

    function showPasswordDialog(confirmWithPassword = true, confirmCallback) {
        let modal$ = ModalDialogInjectorService.createDialog({
            title: $filter("translate")("eob.action.modal.confirm.title"),
            noDismissing: true,
            rejection: true
        }, {
            component: EobModalPasswordComponent,
            input: {
                confirmobject: {
                    confirmCallback,
                    confirmWithPassword,
                    msg: $filter("translate")("eob.action.notification.confirmation.message")
                }
            }
        });
        return modal$.toPromise();
    }

    function showOrgMultiselectDialog(title, config, content, apply, cancel) {
        $rootScope.$broadcast("close.inline.dialogs");

        ModalDialogInjectorService.createDialog({ title },
            { component: EobModalOrgMultiselectComponent, input: { content, config }, output: { applyEvent: apply, cancelEvent: cancel }});
    }

    /**
     * Displays modal dialog for edit/creation of object link comment.
     * @param {object} dmsDocument - copied dms document.
     */
    function createLinkObjectDialog(dmsDocument, isEdit = false) {
        let dialogModel = "<eob-modal-link-object></eob-modal-link-object>";
        const messageKey = isEdit ? "eob.action.modal.link.object.edit.title" : "eob.action.modal.link.object.title";
        createSingleModalContainer(dialogModel, $filter("translate")(messageKey), dmsDocument, { "isedit": isEdit });
    }

    function isEnabledInputField(checkboxName, inputName, formHelper) {
        let field = formHelper.getFieldByInternal(inputName);
        let focussable = field.api.getElement().find("input");
        let input = focussable.first()[0];
        if (formHelper.getFieldByInternal(checkboxName).api.getValue() == 0) {
            formHelper.getFieldByInternal(inputName).api.disable();
            input.parentNode.classList.remove("focus");
        } else {
            formHelper.getFieldByInternal(inputName).api.enable();
            formHelper.getFieldByInternal(inputName).api.focus();
            input.parentNode.classList.add("focus");
            input.focus();
        }
    }

    /**
     * Displays a modal dialog with appropriate object types to choose from.
     *
     * @param {string} title - The title of the modal dialog.
     * @param {object} metadata - The metadata to identify the tray item.
     * @param {{osid, objectTypeId}} parentLocation - The location for insertion.
     * @param {Array} parentInsertableTypes - The object types that can be inserted in the location.
     * @returns {Promise<Object>} The selected ObjectType.
     * @access private
     */
    async function showObjectTypeDialog(title, metadata, parentLocation, parentInsertableTypes) {
        let mainTypes = Array.isArray(metadata.mainTypes) ? metadata.mainTypes : [metadata.mainTypes]; //still needed or is it always an array?
        let insertableIdOptions = parentInsertableTypes;
        const insertableTypes = [];

        // only check the given objectType, if one is given
        if (metadata.objectTypeId && parentInsertableTypes.indexOf(metadata.objectTypeId) != -1) {
            insertableIdOptions = [metadata.objectTypeId];
        }

        // filter possible objectTypes for availability
        let count = metadata.filePaths ? metadata.filePaths.length : metadata.fileNames.length;
        let insertError;

        // Todo: DmsDocumentService.canObjectInsert in a loop is a bad idea. Let's optimize on js->ts
        for (let typeId of insertableIdOptions) {
            let typeConfig = CacheManagerService.objectTypes.getById(typeId).model.config;

            if (typeConfig.multiType || (!typeConfig.withoutPages && mainTypes.indexOf(typeConfig.mainType) > -1)) {
                try {
                    DmsDocumentService.canObjectInsert(parentLocation.osid, parentLocation.objectTypeId, typeId, undefined, count);
                    typeConfig.icon = ObjectTypeService.getIconClass(typeId, null, true);
                    insertableTypes.push(typeConfig);
                } catch (error) { insertError = error; }
            }
        }

        // cancel without an available objectType
        if (insertableTypes.length < 1) {
            if (insertableIdOptions.length > 1 || insertError == void 0) {
                insertError = ErrorModelService.createCustomError("WEB_EX_SOURCE_NO_MATCHING_OBJECT_TYPE");
            }

            NotificationsService.customError(insertError, "WEB_EX_SOURCE_NO_MATCHING_OBJECT_TYPE");
            throw insertError;
        }

        // resolve with the requested objecttype
        if (metadata.objectTypeId && insertableTypes[0].objectTypeId == metadata.objectTypeId) {
            return insertableTypes[0];
        }

        // offer an alternative objecttype choice to the user
        let dialogModel = "<eob-modal-external-tray-object-types></eob-modal-external-tray-object-types>";

        return await createModalContainer(dialogModel, title, undefined, { types: insertableTypes });
    }
}
