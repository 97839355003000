/**
 * This Service updates datastructures from one webclient version to another newer one.
 */
export class UpdateService {
    static $inject: string[] = ["$injector"];

    constructor(protected $injector: ng.auto.IInjectorService) {
    }

    /**
     * Update Async method
     *
     * @return {Promise<void>}
     */
    async updateAsync(): Promise<void> {
        await this._900FinalTo900SP1Async();
    }

    /**
     * Updater from 9.00 Final to 9.00 SP1
     * We must transfer the prepared files into the indexedDB.
     *
     * @return {Promise<void>} - Void
     * @private
     */
    private async _900FinalTo900SP1Async(): Promise<void> {
        if (window.electron && window.electron.getPreparedMetadataFiles) {
            const externalTrayService: any = this.$injector.get("externalTrayService") ;
            const preparedMetadataFiles: any = window.electron.getPreparedMetadataFiles();
            let i: number = 0;

            for (const metadataInOldStructure of preparedMetadataFiles) {
                try {
                    const metadataInNewStructure: any = externalTrayService.createMetadata({
                        mainType: metadataInOldStructure.metadata.mainType,
                        displayTitle: metadataInOldStructure.metadata.displayTitle,
                        filePaths: metadataInOldStructure.metadata.fileNames.map((x: any) => `${metadataInOldStructure.rootPath}\\${x}`)
                    });

                    metadataInNewStructure.fromUpdate = true; // To avoid rename dialog displayed
                    metadataInNewStructure.groupKey += i++; // we won't wait one second per iteration

                    await externalTrayService.addTrayItemAsync(metadataInNewStructure);
                    window.electron.removePreparedDirectoryAfterUpdate(metadataInOldStructure.rootPath);
                } catch (error) {
                    console.warn(`The old metadata structure could not be parsed: ${error.message}`);
                }
            }
        }
    }
}
