import { Injectable } from "@angular/core";
import { FieldModel } from "SHARED_PATH/models/field.model";
import { FieldControlType, FieldAddon } from "ENUMS_PATH/field.enum";
import { ElementPosition } from "INTERFACES_PATH/position.interface";
import { RawField, Field } from "INTERFACES_PATH/field.interface";
import { Layout } from "INTERFACES_PATH/layout.interface";
import { Block } from "INTERFACES_PATH/block.interface";
import { MainForm } from "MODULES_PATH/form/interfaces/form.interface";
import { Row } from "INTERFACES_PATH/row.interface";
import * as angular from "angular";
import {TodoCharWidthMap, TodoLayoutRows} from "INTERFACES_PATH/any.types";

@Injectable({
    providedIn: "root"
})
export class FormLayoutService {
    private readonly deafultColCount: number = 100;
    private readonly colWidthPercent: number = 100 / this.deafultColCount;
    private readonly scaleFactor: number = 1.2;
    private readonly scaleFactorWithEpsilon: number = 2;
    private readonly rowHeight: number = 8;

    // the epsilon ()
    private readonly epsilon: number = 6;

    // the column count we set when we are using the layout service recursively
    // this ensures that we only use the column count a pagecontrol has at its max and dont start over with
    // the default column count
    private colCount: number;
    private fieldList: FieldModel[];
    // An array of common letters we use to determine the real length of a label in pixels
    // We will use the "TEXT_WIDTH_INSPECTOR" to calculate the width of each char in pixel and cache them
    // later we count the occurences of each char inside the string and add this to the length of each string
    // unknown chars will be calculated with the default cahr 'M'
    private charWidthMap: TodoCharWidthMap = {};
    private readonly defaultChar: string = "M";
    private readonly printChars: string[] = [
        "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "ä", "ö", "ü",
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "Ä", "Ö", "Ü", "ß",
        "!", "\"", "§", "$", "%", "&", "/", "\\", "(", ")", "[", "]", "{", "}", "=", "?",
        ".", ":", "-", "_", ",", ";", "#", "'", "+", "*", "~", "<", ">", "|", "@", "€",
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    constructor() {
        this.generateCssForLayoutGrid();
    }

    /**
     * @param frame - the dimensions of the current frame
     * @param fields - raw field data as an array
     * @param colCount - optional parameter to set a fixed column count (only used by page-controls)
     * @param fieldModelList
     * @param parentField
     * @param mainForm
     * @returns {{form: {}, fieldList: Array}}
     */
    buildLayout(frame: ElementPosition, fields: RawField[], colCount: number, fieldModelList: FieldModel[], parentField: Field, mainForm: MainForm): Layout {
        this.colCount = colCount == void 0 ? this.deafultColCount : colCount;
        this.fieldList = fieldModelList;

        const absLeft: number = parentField == void 0 ? 0 : parentField.absLeftPixel;
        const layout: Layout = {
            form: {} as MainForm,
            colCount,
            fieldList: [],
            zeroSizedFields: [],
            parent: parentField,
            rows: []
        };

        layout.form.height = Number(frame.bottom);
        layout.form.width = Number(frame.right);
        layout.mainForm = mainForm == void 0 ? layout.form : mainForm;
        layout.form.addonWidth = this.calculateAddonPosition(layout, absLeft);

        if (colCount == void 0) {
            this.trimForm(layout, fields);
        }

        this.buildFields(layout, fields, absLeft);
        this.alignFieldsHorizontally(layout);
        this.prepareFields(layout, parentField);
        this.addVerticalSpaces(layout);

        // rebuild after the first optimization steps
        layout.rows = this.rebuildRows(layout.rows);

        this.removeSpaces(layout);
        this.blockDetection(layout);

        // rebuild after removing empty spaces and optimizing blocks
        layout.rows = this.rebuildRows(layout.rows);

        this.fillEmptyRows(layout);
        this.correctHorizontalCollision(layout);
        this.getRealLefts(layout);
        this.maximizeLabelWidth(layout);
        this.sortFieldListByTabindex(layout);
        return layout;
    }

    calculateCharsWidth(): void {
        // get the span element we use to determine the width of the labels in webclient pixels
        const textWidthInspector: JQuery = angular.element(document.body).find("#text-width-inspector");

        for (const char of this.printChars) {
            let charWidth: number = textWidthInspector.text(char).width();

            if (charWidth < 6) {
                charWidth = 6;
            }

            this.charWidthMap[char] = charWidth;
        }
    }

    private generateCssForLayoutGrid(): void {
        let css = "";
        const head: HTMLHeadElement = document.head || document.getElementsByTagName("head")[0];
        const style = document.createElement("style");

        for (let i = 0; i < this.deafultColCount; i++) {
            css += `.left-${i}{left:${i * this.colWidthPercent}%;}`;
            css += `.width-${i}{width:${(i + 1) * this.colWidthPercent}%;max-width:${(i + 1) * this.colWidthPercent}%}`;
        }

        style.type = "text/css";

        style.appendChild(document.createTextNode(css));

        head.appendChild(style);
    }

    /**
     * Checks if the field we got is a real label in case we get "special" information about the fields position
     * This means pagecontrols, groups, checkboxes, buttons (and maybe other fields also) only have a label position
     *
     * @param model - the field model
     * @returns {boolean}
     */
    private isRealLabel(model: FieldModel): boolean {
        return model != void 0 && model.type != FieldControlType.CHECKBOX && model.type != FieldControlType.GROUP && model.type != FieldControlType.PAGE_CONTROL && model.type != FieldControlType.BUTTON;
    }

    /**
     * Check if the field has a size
     * Fields may not have a size in case they do not really belong to the field
     * e.g. the input position of a group container is zero in size because it does not exists
     *
     * @param elementPos - The original position information
     * @returns {boolean}
     */
    private isZeroSized(elementPos: ElementPosition): boolean {
        // right and bottom should be numbers??
        return elementPos.right == 0 && elementPos.bottom == 0;
    }

    /**
     * Align labels and inputs inside the same row if the difference between their top position (in pixel)
     * is smaller than our epsilon
     *
     * @param exactTextPos - The original position information of the field's label
     * @param exactInputPos - The original position information of the field's input
     * @param labelPos - The calculated position of the label inside our grid
     * @param inputPos - The calculated position of the input inside our grid
     */
    private alignInRow(exactTextPos: ElementPosition, exactInputPos: ElementPosition, labelPos: Field, inputPos: Field): void {
        if (labelPos == void 0 || inputPos == void 0) {
            return;
        }

        // already in the same row
        if (labelPos.row - inputPos.row == 0) {
            inputPos.isLabelLeft = true;
            labelPos.isLabelLeft = true;
            return;
        }

        // we will align them if the difference between their computed rows is 1 ..
        if (Math.abs(labelPos.row - inputPos.row) != 1) {
            return;
        }

        // .. and if the difference between their top positions is lesser than the epsilon
        if (Math.abs(exactTextPos.top - exactInputPos.top) > this.epsilon) {
            return;
        }

        if (labelPos.row > inputPos.row) {
            labelPos.row = inputPos.row;
        } else {
            inputPos.row = labelPos.row;
        }

        inputPos.isLabelLeft = true;
        labelPos.isLabelLeft = true;
    }

    /**
     * parse the strings into numbers for comparison
     *
     * @param element - The original element (its called element because it is either a label or an input)
     * @returns {*}
     */
    private parseNumbers(element: ElementPosition): ElementPosition {
        element.bottom = Number(element.bottom);
        element.top = Number(element.top);
        element.right = Number(element.right);
        element.left = Number(element.left);

        // in case the field starts with a left smaller than 0 we set it to zero
        if (element.left < 0 && element.left + element.right > 0) {
            element.left = 0;
        }

        // in case the field starts with a top smaller than 0 we set it to zero
        if (element.top < 0 && element.top + element.bottom > 0) {
            element.top = 0;
        }

        return element;
    }

    /**
     * Check if label and input are in the same row ... duh ..
     *
     * @param labelPos - the calculated position of the label inside the web grid
     * @param inputPos - the calculated position of the input inside the web grid
     * @returns {boolean}
     */
    private isInSameRow(labelPos: ElementPosition, inputPos: ElementPosition): boolean {
        const overlapping: number = labelPos.top + labelPos.bottom - inputPos.top;

        if (overlapping > labelPos.bottom / 2) {
            return true;
        }
    }

    private snapLabelToInput(field: Field): void {
        if (field.isLabel && field.isLabelLeft) {
            field.absWidthInCols += field.sibling.absLeftInCols - (field.absLeftInCols + field.absWidthInCols);
        }
    }

    private increaseTopPos(startRow: number, rowCountToAdd: number, fieldList: Field[]): void {
        for (const field of fieldList) {
            if (field.row >= startRow) {
                field.row += rowCountToAdd;
            }
        }
    }

    private optimizeGroupLayout(groupLayout: Layout): void {
        // placeholder row
        const rows: TodoLayoutRows = groupLayout.rows;

        let emptyRowsTop = 0;

        for (const row of rows) {
            if (row) {
                break;
            }

            emptyRowsTop++;
        }

        if (emptyRowsTop > 2) {
            rows.splice(0, emptyRowsTop - 2);
            this.increaseTopPos(0, (emptyRowsTop - 2) * -1, groupLayout.fieldList);

        } else if (emptyRowsTop < 2) {
            rows.splice(0, 0, 0);
        }

        if (rows[rows.length - 1]) {
            rows.push(0);
        }
    }

    private setRowIndexes(fields: Field[], index: number): void {
        for (const field of fields) {
            field.row = index;
        }
    }

    private prepareFields(layout: Layout, parentField: Field): void {
        for (const j in layout.lines) {
            const fields: Field[] = layout.lines[j];

            if (fields == void 0) {
                continue;
            }

            for (const field of fields) {
                const internal: string = field.internal;
                const fieldModel: FieldModel = this.getFieldModel(internal);

                if (fieldModel == void 0 || field.isOffCanvas || (field.isLabel && fieldModel.isInvisibleText)) {
                    continue;
                }

                if (!field.isLabel && field.isLabelAbove && field.sibling != void 0) {
                    const input: Field = field;
                    const label: Field = field.sibling;

                    this.getRealTextWidth(fieldModel, label, layout.form.width);
                    // if the label and the input lay above each other we add a row to the input
                    if (input.row - label.row == 1) {
                        this.setRowIndexes(layout.lines[j], input.row + 1);
                    }
                }
            }

            for (const field of fields) {
                const internal: string = field.internal;
                const fieldModel: FieldModel = this.getFieldModel(internal);

                // there are some field types we do not support like webcontrols, imagecontrols and multifields
                // these fields do not have a model, so continue;
                if (fieldModel == void 0 || field.isOffCanvas) {// || (field.isLabel && fieldModel.isInvisibleText)) {
                    continue;
                }

                field.model = fieldModel;
                field.parent = parentField;

                if (fieldModel.type == FieldControlType.GRID && field.isLabel && field.sibling != void 0) {
                    field.height = 2;
                    field.sibling.absLeftInCols = field.absLeftInCols;
                    field.sibling.absWidthInCols = field.absWidthInCols;
                }

                // we need to add a row to the total height because the tabs need one space for themselves
                if (fieldModel.type == FieldControlType.PAGE_CONTROL) {
                    field.height++;
                }

                // HERE COMES ADD TO ROWS !!! \(ö)/
                if (fieldModel.type != FieldControlType.GROUP && fieldModel.type != FieldControlType.PAGE_CONTROL) {
                    this.addToRows(layout, field, fieldModel);
                }

                let contentHeight = 0;

                if (fieldModel.type == FieldControlType.PAGE_CONTROL) {
                    field.pages = [];

                    for (const page of field.rawField.page) {
                        const pageLayout: Layout = this.buildLayout(field.exactPos, page.fields.field, field.width + 1, this.fieldList, field, layout.mainForm);

                        if (pageLayout.rows.length > contentHeight) {
                            contentHeight = pageLayout.rows.length;
                        }

                        field.pages.push(pageLayout);
                    }

                    field.height = contentHeight + 4;

                    this.addToRows(layout, field, fieldModel);

                } else if (fieldModel.type == FieldControlType.GROUP) {
                    field.groupLayout = this.buildLayout(field.exactPos, field.rawField.fields, field.width + 1, this.fieldList, field, layout.mainForm);

                    this.optimizeGroupLayout(field.groupLayout);
                    this.increaseTopPos(field.height + field.row, field.groupLayout.rows.length - field.height, layout.fieldList);
                    this.rebuildRows(field.groupLayout.rows);

                    field.height = field.groupLayout.rows.length;

                    this.addToRows(layout, field, fieldModel);
                }
            }
        }
    }

    private buildFieldPositioning(layout: Layout, elementPos: ElementPosition, isLabelPos: boolean, model: FieldModel, absLeft: number, absWidth: number): Field {
        const formWidth: number = layout.form.width;
        const elementPosition: ElementPosition = this.parseNumbers(elementPos);

        let isOffCanvas = false;

        // if the field is outside the visible area, we do not want to see it later, so
        // we set it to "offCanvas"
        if (elementPosition.left > layout.form.width) {
            isOffCanvas = true;
        } else if (elementPosition.top > layout.form.height) {
            isOffCanvas = true;
        } else if (elementPosition.top + elementPosition.bottom < 0) {
            isOffCanvas = true;
        } else if (elementPosition.left + elementPosition.right < 0) {
            isOffCanvas = true;
        } else if (model.addon == FieldAddon.ADDRESS) {
            // special case --> we found a field with an address addon
            // we dont support it, but we need to add it to the form because we have to transport its indexdata
            // when the user wants to overwrite current indexdata
            isOffCanvas = true;
        }

        // this will do the trick --> checkboxes are smaller than other fields by default
        // we would get rounding errors .. :(
        if (model.type == FieldControlType.CHECKBOX) {
            if (elementPosition.bottom == 8) {
                elementPosition.top += -2;
            }
        }

        const eobElementPos: Field = {
            internal: model.internal,
            title: model.title,
            left: this.getLeft(formWidth, elementPosition.left),
            row: this.getRow(elementPosition.top),
            width: this.getWidth(formWidth, elementPosition.right),
            height: this.getHeight(elementPosition.bottom),
            absWidthInCols: Math.round((elementPosition.right / layout.mainForm.width) * 100) + 1,
            absLeftInCols: Math.floor(100 * ((absLeft + Number(elementPosition.left)) / absWidth)),
            exactPos: elementPosition,
            isOffCanvas
        };

        if (!isLabelPos && model.addon != void 0) {
            eobElementPos.width += Math.floor(eobElementPos.height * this.rowHeight / formWidth * 100);
            eobElementPos.addon = model.addon;

            if (elementPosition.right <= 2) {
                eobElementPos.isStandaloneAddon = true;
            }
        }

        // check if we got a label position and that its not a special "Label"
        // like pagecontrols, checkboxes, buttons or something else
        if (isLabelPos && this.isRealLabel(model)) {
            eobElementPos.isLabel = true;
        }

        // if the field would grow outside the form we set the width to the available maximum
        if (elementPosition.left + elementPosition.width > layout.form.width) {
            elementPosition.width -= (elementPosition.left + elementPosition.width - layout.form.width);
        }

        // TODO - i have no idea what to do at this point, check later
        if (!this.isZeroSized(elementPosition)) {
            return eobElementPos;
        } else {
            console.log("TODO --> fields without dimensions !?");
        }
    }

    getTextWidthInPixel(str: string): number {
        let lengthInPixel = 0;

        for (const char of str) {
            let charWidth: number = this.charWidthMap[char];

            if (charWidth == void 0) {
                charWidth = this.charWidthMap[this.defaultChar];
            }

            lengthInPixel += charWidth;
        }

        return lengthInPixel;
    }

    private getLeft(formWidth: number, elementLeft: number): number {
        if (elementLeft <= 0) {
            return 0;
        } else {
            const leftPercent: number = (elementLeft / formWidth) * 100;
            return Math.floor(leftPercent / this.colWidthPercent);
        }
    }

    private getWidth(formWidth: number, elementWidth: number, scaleFactor?: number): number {
        const width: number = scaleFactor != void 0 ? formWidth * scaleFactor : formWidth;
        const widthPercent: number = (elementWidth / width) * 100;

        if (widthPercent < this.colWidthPercent) {
            return 1;
        } else {
            return Math.round(widthPercent / this.colWidthPercent);
        }
    }

    private getRow(elementTop: number): number {
        const row: number = Math.round(elementTop * this.scaleFactor / this.rowHeight);

        if (elementTop <= 0) {
            return 1;
        } else {
            return row;
        }
    }

    private getHeight(elementHeight: number): number {
        const height: number = Math.round((elementHeight * this.scaleFactor) / this.rowHeight);

        if (height < 4) {
            return 2;
        } else {
            return height;
        }
    }

    private isVerticallyColliding(collider: Field, field: Field): boolean {
        const element: Field = collider.width < field.width ? collider : field;
        const mostrightLeft: number = collider.left > field.left ? collider.left : field.left;
        const mostleftRight: number = collider.left + collider.width < field.left + field.width ? collider.left + collider.width : field.left + field.width;
        const diff: number = mostleftRight - mostrightLeft;

        if (diff > element.width / 2) {
            return true;
        }
    }

    private addToRows(layout: Layout, field: Field, model: FieldModel): void {
        let row: number;
        let isColliding = false;
        let collidingRows = 0;

        // we need to return in this case because the field has no layout !? every position attribute is 0
        // e.g. buttons, static texts, groups, and maybe more --> TODO: investigate
        if (field == void 0 || field.isOffCanvas) {
            return;
        }

        row = field.row;
        const height = field.height;

        for (let i = 0; i < height; i++) {
            const currentRow: number = row + i;

            let isResolved = false;

            if (layout.rows[currentRow] != void 0 && layout.rows[currentRow].length > 0) {

                const elementsToCheck: Field[] = layout.rows[currentRow];
                for (const element of elementsToCheck) {
                    const bottommostField: Field = element.row > field.row ? element : field;
                    const topmostField: Field = element.row < field.row ? element : field;

                    if (field.left >= element.left && field.left <= element.left + element.width && bottommostField.row < topmostField.row + topmostField.height) {

                        if (element.internal == field.internal) {
                            const label: Field = element.isLabel ? element : field;
                            const input: Field = label.sibling;

                            if (this.isInSameRow(label.exactPos, input.exactPos) && model.type != FieldControlType.GRID) {
                                const difference: number = input.left - (label.left + label.width);

                                label.width = label.width + difference - 1;
                                isResolved = true;
                                break;
                            }
                        } else if (this.isVerticallyColliding(element, field)) {
                            let insertBefore = false;
                            let start: number;

                            const difference: number = topmostField.row + topmostField.height - bottommostField.row;

                            if (field.row < element.row) {
                                start = field.row + field.height;
                            } else {
                                start = field.row + 1;
                                insertBefore = true;
                            }

                            for (let k = 0; k < difference; k++) {
                                layout.rows.splice(start, 0, 0);
                            }

                            this.increaseTopPos(start, difference, layout.fieldList);

                            if (insertBefore) {
                                field.row += difference;
                                row += difference;
                            } else {
                                element.row += difference;

                                if (element.isLabelAbove || element.isLabelLeft) {
                                    element.sibling.row += difference;
                                }
                            }

                            isResolved = true;
                            break;
                        }

                        isColliding = true;
                        collidingRows++;
                    }
                }
            }
            if (isResolved) {
                break;
            }
        }

        if (isColliding) {
            // TODO - PANIC!
            // console.log("PANIC", collidingRows, model.title)
        } else {
            for (let i = 0; i < height; i++) {
                const currentRow: number = row + i;

                if (layout.rows[currentRow] == void 0 || !layout.rows[currentRow]) {
                    layout.rows[currentRow] = [];
                }

                layout.rows[currentRow].push(field);
            }
        }
    }

    private trimForm(layout: Layout, fields: RawField[]): void {
        let rightmost = 0;
        let leftmost: number = layout.form.width;

        for (const field of fields) {
            const labelZeroSized: boolean = this.isZeroSized(field.field_pos);
            const inputZeroSized: boolean = this.isZeroSized(field.input_pos);
            const inputRight: number = Number(field.input_pos.right) + Number(field.input_pos.left);
            const labelRight: number = Number(field.field_pos.right) + Number(field.field_pos.left);
            const inputLeft = Number(field.input_pos.left);
            const labelLeft = Number(field.field_pos.left);

            if (inputLeft < leftmost && !inputZeroSized) {
                leftmost = inputLeft;
            }
            if (labelLeft < leftmost && !labelZeroSized) {
                leftmost = labelLeft;
            }
            if (labelRight > rightmost && labelLeft < layout.form.width) {
                rightmost = labelRight;
            }
            if (inputRight > rightmost && inputLeft < layout.form.width) {
                rightmost = inputRight;
            }
        }

        layout.form.width = rightmost + 20;
        layout.maxWidth = layout.form.width * 2.5;
    }

    /**
     * Get the enriched field model
     *
     * @param internal - The internal name of the current field
     * @returns {fieldModel}
     */
    private getFieldModel(internal: string): FieldModel {
        for (const field of this.fieldList) {
            if (field.internal == internal) {
                return field;
            }
        }
    }

    private isLabelAbove(label: Field, input: Field): void {
        if (input.isLabelLeft) {
            return;
        }

        if (input.row - label.row <= 2 && input.row - label.row >= 0) {
            const leftmost: Field = label.left < input.left ? label : input;

            if (leftmost.sibling.left <= leftmost.left + leftmost.width) {
                input.isLabelAbove = true;
                label.isLabelAbove = true;
            }
        }
    }

    private getRealTextWidth(model: FieldModel, label: Field, formWidth: number): void {
        const realWidth: number = this.getTextWidthInPixel(model.title);
        const newTextWidth: number = this.getWidth(formWidth, realWidth, this.scaleFactorWithEpsilon);

        if (newTextWidth < label.width) {
            label.width = newTextWidth;
        }
    }

    private addVerticalSpaces(layout: Layout): void {
        let spaceNeededLeft = 0;
        let spaceNeededRight = 0;
        let spaceRight = 1000;
        let spaceLeft = 1000;

        const rows: Field[][] = layout.rows;

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (const row of rows) {
            // get available space on both sides in form/group/pagecontrol
            for (const j in row) {
                const tempSpaceLeft: number = row[j].left;
                const tempSpaceRight: number = 99 - row[j].left - row[j].width;

                if (tempSpaceLeft < spaceLeft) {
                    spaceLeft = tempSpaceLeft;
                    // remember when space is to little on left side
                    if (tempSpaceLeft == 0) {
                        spaceNeededLeft = 2;
                    } else if (tempSpaceLeft == 1 && spaceNeededLeft != 2) {
                        spaceNeededLeft = 1;
                    }
                }

                if (tempSpaceRight < spaceRight) {
                    spaceRight = tempSpaceRight;

                    // remember when space is to little on right side
                    if (tempSpaceRight == 99) {
                        spaceNeededRight = 2;
                    } else if (tempSpaceRight == 1 && spaceNeededRight != 2) {
                        spaceNeededRight = 1;
                    }
                }
            }
        }

        // move all fields inside a form/group/pagecontrol to create space left or right if able
        if (spaceNeededLeft > 0 && spaceRight > spaceNeededLeft + 1) {
            for (const field of layout.fieldList) {
                field.left += spaceNeededLeft;
            }
        }
        if (spaceNeededRight > 0 && spaceLeft > spaceNeededRight + 1) {
            for (const field of layout.fieldList) {
                field.left -= spaceNeededRight;
            }
        }
    }

    private removeSpaces(layout: Layout): void {
        let emptyRowsTop = 0;
        let firstFilledRowFound = false;

        const rows: Field[][] = layout.rows;
        // we only need to remove spaces at the top, because the height of the element is

        for (let i = 0; i < rows.length; i++) {
            if (!firstFilledRowFound && (!rows[i] || rows[i] == void 0)) {
                emptyRowsTop++;
                continue;
            } else {
                firstFilledRowFound = true;
            }

            if (firstFilledRowFound && (!rows[i] || rows[i] == void 0)) {
                let bottommostTop = 0;
                let exactPos: ElementPosition;
                let topmostBottom: number = layout.form.height;

                const previousRow: Field[] = rows[i - 1];
                const nextRow: Field[] = rows[i + 1];

                // last row is empty, as expected ^^
                if (nextRow == void 0) {
                    return;
                }

                for (const prow of previousRow) {
                    exactPos = prow.exactPos;
                    if (exactPos.top + exactPos.bottom > bottommostTop) {
                        bottommostTop = exactPos.top + exactPos.bottom;
                    }
                }

                for (const nrow of nextRow) {
                    exactPos = nrow.exactPos;
                    if (exactPos.top < topmostBottom) {
                        topmostBottom = exactPos.top;
                    }
                }

                if (topmostBottom - bottommostTop > 0 && topmostBottom - bottommostTop <= 8) {
                    rows.splice(i, 1);
                    i--;
                    this.increaseTopPos(i, -1, layout.fieldList);
                }
            }

        }

        if (emptyRowsTop > 1) {
            rows.splice(0, emptyRowsTop - 1);
            this.increaseTopPos(0, -1 * (emptyRowsTop - 1), layout.fieldList);
        }
    }

    private inspectBlock(block: Block, layout: Layout, splitBlocks: Block[], blockFieldInternals?: string[]): void {
        let firstRowFound = false;
        let stopAtThisPoint = false;

        for (const i in block.rows) {
            const row: Field[] = block.rows[i];

            if (!firstRowFound && !row) {
                continue;
            } else {
                firstRowFound = true;
            }

            if (!row) {
                let rowIndex = Number(i);
                let lastEmptyRowIndex = Number(i);
                let nextRowEmpty = true;

                while (nextRowEmpty) {
                    rowIndex++;
                    if (block.rows[rowIndex] != 0) {
                        lastEmptyRowIndex = rowIndex;
                        nextRowEmpty = false;
                    }
                }

                for (let j = Number(i); j <= lastEmptyRowIndex; j++) {
                    const layoutRow: Field[] = layout.rows[j];

                    if (!layoutRow || layoutRow == void 0) {
                        continue;
                    } else {
                        for (const row of layoutRow) {
                            const fieldToCheck: Field = row;

                            if (blockFieldInternals.includes(fieldToCheck.internal)) {
                                continue;
                            }
                            if (fieldToCheck.left <= block.left && fieldToCheck.left + fieldToCheck.width > block.left || fieldToCheck.left >= block.left && fieldToCheck.left <= block.left + block.width) {
                                const firstBlock: Block = {
                                    left: block.left,
                                    width: block.width,
                                    top: block.top,
                                    fields: [],
                                    rows: block.rows
                                };

                                const remainingBlock: Block = {
                                    left: block.left,
                                    width: block.width,
                                    top: block.top,
                                    fields: [],
                                    rows: block.rows
                                };

                                for (let d = Number(i); d < block.rows.length; d++) {
                                    firstBlock.rows[d] = 0;
                                }

                                for (let d = 0; d < Number(i); d++) {
                                    remainingBlock.rows[d] = 0;
                                }

                                for (const field of block.fields) {
                                    if (field.row < Number(i)) {
                                        firstBlock.fields.push(field);
                                    } else {
                                        remainingBlock.fields.push(field);
                                    }
                                }

                                splitBlocks.push(firstBlock);
                                stopAtThisPoint = true;
                                this.inspectBlock(remainingBlock, layout, splitBlocks);
                                break;
                            }
                        }
                        if (stopAtThisPoint) {
                            break;
                        }
                    }
                }
                if (stopAtThisPoint) {
                    break;
                }
            }
        }
    }

    private blockDetection(layout: Layout): void {
        const fields: Field[] = layout.fieldList;
        const lefts: {[k: number]: Field[]} = {};
        const rights: {[k: number]: Field[]} = {};

        for (const field of fields) {

            if (!field.isLabel) {
                const left: number = field.left;
                const right: number = field.left + field.width;

                if (lefts[left] == void 0) {
                    lefts[left] = [];
                }

                if (rights[right] == void 0) {
                    rights[right] = [];
                }

                lefts[left].push(field);
                rights[right].push(field);
            }
        }

        let blocks: Block[] = [];

        for (const i in lefts) {
            let leftmost = 100;
            let topmost: number = layout.rows.length;
            let bottommost = 0;

            const inputs: Field[] = lefts[i];

            for (const input of inputs) {
                if (!input.sibling) {
                    if (input.left < leftmost) {
                        leftmost = input.left;
                    }
                }

                if (input.row < topmost) {
                    topmost = input.row;
                }

                if (input.row + input.height > bottommost) {
                    bottommost = input.row + input.height;
                }

                if (input.sibling != void 0 && input.sibling.left < leftmost) {
                    leftmost = input.sibling.left;
                }
            }

            const block: Block = {
                left: leftmost,
                width: 0,
                top: topmost,
                height: bottommost - topmost,
                fields: inputs,
                rows: []
            };

            for (const input of inputs) {
                const row: number = input.row;
                const height: number = input.height;

                for (let g: number = row; g < row + height; g++) {
                    if (block.rows[g] == void 0) {
                        block.rows[g] = [];
                    }

                    block.rows[g].push(input);
                }

            }

            this.fillEmptyRows(block);

            blocks.push(block);

            // align the labels in the same column
            for (const input of inputs) {
                if (!input.sibling || input.isLabelAbove) {
                    continue;
                }

                input.sibling.width += input.sibling.left - leftmost;
                input.sibling.left = leftmost;
            }
        }

        let allSplitBlocks: Block[] = [];

        const blocksToSplit: number[] = [];

        for (const i in blocks) {
            let rightmost = 0;

            const block: Block = blocks[i];
            const splitBlocks: Block[] = [];
            const inputs: Field[] = block.fields;
            const blockFieldInternals: string[] = [];

            for (const input of inputs) {
                blockFieldInternals.push(input.internal);

                if (input.left + input.width > rightmost) {
                    rightmost = input.left + input.width;
                }
            }

            block.width = rightmost - block.left;

            for (let input of inputs) {
                if (!input.sibling) {
                    continue;
                }

                const row: Field[] = layout.rows[input.row];

                if (row && row.length) {
                    for (const item of row) {
                        if (item.left < rightmost && item.left > input.left) {
                            input = item;
                        }
                    }
                }

                input.width = rightmost - input.left;
            }

            this.inspectBlock(block, layout, splitBlocks, blockFieldInternals);
            if (splitBlocks.length > 0) {
                blocksToSplit.push(block.left);
            }

            allSplitBlocks = allSplitBlocks.concat(splitBlocks);
        }

        for (const bnum of blocksToSplit) {
            blocks = blocks.filter((obj: Block) => obj.left !== bnum);
        }

        blocks = blocks.concat(allSplitBlocks);

        for (const block of blocks) {
            if (block.fields.length > 2) {
                this.correctHorizontalSpaces(block, layout);
            }
        }

        layout.blocks = blocks;
    }

    private rebuildRows(rows: Field[][]): Field[][] {
        const optimized: number[] = [];
        const newRows: Field[][] = [];
        for (const row of rows) {
            if (row && row.length) {
                for (const field of row) {
                    if (optimized.includes(field.index)) {
                        continue;
                    }

                    for (let k = 0; k < field.height; k++) {
                        const currentRow: number = field.row + k;

                        if (newRows[currentRow] == void 0 || !newRows[currentRow]) {
                            newRows[currentRow] = [];
                        }

                        newRows[currentRow].push(field);
                    }
                    optimized.push(field.index);
                }
            }
        }

        return newRows;
    }

    private fillEmptyRows(block: Block): void {
        for (let i = 0; i < block.rows.length; i++) {
            if (block.rows[i] == void 0) {
                block.rows[i] = [];
            }
        }
    }

    private correctHorizontalCollision(layout: Layout): void {
        for (let i = 0; i < layout.fieldList.length; i++) {
            const field: Field = layout.fieldList[i];

            if (field.isLabel && field.sibling != void 0 && field.sibling.model != void 0 && field.sibling.model.isInvisibleText) {
                continue;
            }

            if (field.isOffCanvas) {
                continue;
            }

            for (let j: number = i + 1; j < layout.fieldList.length; j++) {
                const nextField: Field = layout.fieldList[j];

                if (nextField.isOffCanvas) {
                    continue;
                }

                const fieldRight: number = field.absLeftInCols + field.absWidthInCols;
                const fieldLeft: number = field.absLeftInCols;
                const fieldBottom: number = field.row + field.height;
                const nextFieldLeft: number = nextField.absLeftInCols;
                const nextFieldBottom: number = nextField.row + nextField.height;

                if (fieldRight > nextFieldLeft && fieldLeft < nextFieldLeft &&
                    ((nextField.row <= field.row && nextFieldBottom > field.row) || (nextField.row > field.row && nextField.row < fieldBottom))) {
                    const difference: number = nextFieldLeft - fieldRight;
                    field.absWidthInCols += difference;
                }
            }
        }
    }

    private getRealLefts(layout: Layout): void {
        for (const field of layout.fieldList) {
            this.snapLabelToInput(field);

            if (field.parent == void 0) {
                field.realLeft = field.absLeftInCols;
                field.realWidth = field.absWidthInCols;

                continue;
            }

            const relCols: number = field.absLeftInCols - field.parent.absLeftInCols;
            field.realLeft = (relCols * this.deafultColCount) / field.parent.absWidthInCols;
            field.realWidth = field.absWidthInCols * this.deafultColCount / field.parent.absWidthInCols;
        }
    }

    private createRowObjects(rows: Field[][]): Row[] {
        const rowObjects: Row[] = [];

        // remove empty rows from array and create RowObjects
        for (const b in rows) {
            const rowObject: Row = {
                fields: rows[b],
                nextRowEmpty: false,
                row: Number(b)
            };

            if (rowObject.fields) {
                rowObjects.push(rowObject);
            } else if (rowObjects.length > 0) {
                rowObjects[rowObjects.length - 1].nextRowEmpty = true;
            }
        }

        return rowObjects;
    }

    private getExactHorizontalSpaces(rowObjects: Row[]): Row[] {
        for (const b in rowObjects) {
            if (Number(b) > 0) {
                const prevRow: Row = rowObjects[Number(b) - 1];
                const row: Row = rowObjects[Number(b)];

                let bottomMost = 0;
                let topMost = -1;

                // we're looking for the bottommost field in the previous row
                for (let f = 0; f < prevRow.fields.length; f++) {
                    const field: Field = prevRow.fields[f];

                    if (f > 0) {
                        const prevField: Field = prevRow.fields[f - 1];
                        if (field.exactPos.top + field.exactPos.bottom > prevField.exactPos.top + prevField.exactPos.bottom) {
                            bottomMost = field.exactPos.top + field.exactPos.bottom;
                            row.row = field.row + field.height;
                        }
                    } else {
                        bottomMost = field.exactPos.top + field.exactPos.bottom;
                        row.row = field.row + field.height;
                    }
                }

                // now we're looking for the topmost field of the row
                for (const f in row.fields) {
                    const field: Field = row.fields[f];
                    if (Number(f) > 0) {
                        const prevField: Field = row.fields[Number(f) - 1];
                        if (field.exactPos.top < prevField.exactPos.top) {
                            topMost = field.exactPos.top;
                        }

                        if (field.row + field.height > prevField.row + prevField.height) {
                            row.nextRow = field.row;
                        } else {
                            row.nextRow = prevField.row;
                        }
                    } else {
                        topMost = field.exactPos.top;
                        prevRow.nextRow = field.row;
                    }
                }

                if (topMost - bottomMost >= 0) {
                    prevRow.spaceAfter = topMost - bottomMost;
                }
            }
        }

        return rowObjects;
    }

    private correctHorizontalSpaces(block: Block, layout: Layout): number {
        const rowObjects: Row[] = this.createRowObjects(block.rows);
        const filledRows: Row[] = this.getExactHorizontalSpaces(rowObjects);
        const filteredArray: Row[] = filledRows.filter((obj: Row) => obj.spaceAfter != void 0);
        const sortArray: Row[] = filteredArray.slice(0);

        if (sortArray.length > 1) {
            sortArray.sort((a, b) => b.spaceAfter - a.spaceAfter);

            const tmpArray: Row[] = sortArray.slice(0);
            const rightSide: Row[] = tmpArray.splice(0, Math.floor(sortArray.length / 2));

            let leftSide: Row[];

            if (sortArray.length % 2 != 0) {
                leftSide = tmpArray.slice(0, -1);
            } else {
                leftSide = tmpArray;
            }

            const q3: number = this.getMeridian(rightSide);
            const q2: number = this.getMeridian(sortArray);
            const q1: number = this.getMeridian(leftSide);

            let interQuartil: number = (q3 - q1) * 6; // 6 was chosen deliberately
            const borderLow: number = q1 - interQuartil;

            if (interQuartil < 2) {
                interQuartil = 2;
            }

            // otherwise s*$# would go down
            if (borderLow < -50) {
                return;
            }

            const borderHigh: number = q3 + interQuartil;
            const standardSpace: number = (q2 / this.rowHeight) % 10 <= 0.8 ? Math.floor(q2 / this.rowHeight) : Math.round(q2 / this.rowHeight);
            const rowToCheck: Row = sortArray[sortArray.length - 1];

            let bottomOfBlock = 0;

            for (const field of rowToCheck.fields) {
                if (field.row + field.height > bottomOfBlock) {
                    bottomOfBlock = field.row + field.height;
                }
            }

            for (const currentRow of filteredArray) {
                let rowsToDelete = 0;

                if (currentRow.spaceAfter <= borderHigh && currentRow.spaceAfter >= borderLow) {
                    rowsToDelete = currentRow.nextRow - currentRow.row - standardSpace;
                    if (rowsToDelete == 0) {
                        continue;
                    }

                    for (const field of layout.fieldList) {
                        if (field.row == currentRow.nextRow && field.isLabel && field.isLabelAbove /*&& filteredArray[d].spaceAfter > rowsToDelete*/) {
                            break;
                        }
                        if (field.row >= currentRow.nextRow) {
                            let doDelete = true;
                            if (rowsToDelete > 0 && field.row > bottomOfBlock && bottomOfBlock != 0 && field.row > block.top + block.height) {
                                for (let m: number = field.row - rowsToDelete; m < field.row; m++) {
                                    if (layout.rows[m] != 0 && layout.rows[m] != void 0) {
                                        for (const t in layout.rows[m]) {
                                            if (layout.rows[m][t].internal != field.internal ||
                                                (layout.rows[m][t].internal == field.internal &&
                                                    (layout.rows[m][t].isLabel || field.isLabel) &&
                                                    (!layout.rows[m][t].isLabel || !field.isLabel))) {
                                                doDelete = false;
                                            }
                                        }
                                    }
                                }
                            }

                            if (doDelete) {

                                let startIndex: number;
                                let endIndex: number;

                                startIndex = rowsToDelete > 0 ? field.row + field.height - rowsToDelete : field.row;
                                endIndex = rowsToDelete > 0 ? field.row + field.height : field.row - rowsToDelete;

                                for (let s: number = startIndex; s < endIndex; s++) {
                                    for (const y in layout.rows[s]) {
                                        const element: Field = layout.rows[s][y];

                                        if (element.index == field.index) {
                                            layout.rows[s].splice(y, 1);
                                            break;
                                        }
                                    }
                                }

                                startIndex = rowsToDelete > 0 ? field.row - rowsToDelete : field.row + field.height;
                                endIndex = rowsToDelete > 0 ? field.row : field.row + field.height - rowsToDelete;

                                for (let s: number = startIndex; s < endIndex; s++) {
                                    if (layout.rows[s] == void 0) {
                                        layout.rows[s] = [];
                                    }

                                    layout.rows[s].push(field);
                                }

                                field.row -= rowsToDelete;
                            }
                        }
                    }
                }

                for (const item of filteredArray) {
                    item.row -= rowsToDelete;
                    item.nextRow -= rowsToDelete;
                }
            }
        }
    }

    private getMeridian(arr: Row[]): number {
        if (arr.length % 2 == 0) {
            return (arr[arr.length / 2].spaceAfter + arr[arr.length / 2 - 1].spaceAfter) / 2;
        } else if (arr.length == 1) {
            return arr[0].spaceAfter;
        } else {
            return arr[Math.floor(arr.length / 2)].spaceAfter;
        }
    }

    private alignFieldsHorizontally(layout: Layout): void {
        const lines: { [k: number]: Field[] } = {};

        for (const field of layout.fieldList) {
            const exactTop: number = field.exactPos.top;

            if (lines[exactTop] == void 0) {
                lines[exactTop] = [];
            }

            lines[exactTop].push(field);
        }

        for (const i in lines) {
            if (lines[i] == void 0) {
                continue;
            }

            let lineNumber = Number(i);
            // check if there is a better rangecenter
            let currentLine: Field[] = lines[i];
            let addCount = 0;

            for (let k = 1; k < 3; k++) {
                const nextLine: Field[] = lines[lineNumber + k];

                if (nextLine != void 0) {
                    currentLine = nextLine;
                    addCount = k;
                }
            }

            lineNumber += addCount;

            const rangeStart: number = lineNumber - 2;
            const rangeEnd: number = lineNumber + 2;

            for (let j: number = rangeStart; j <= rangeEnd; j++) {
                if (lines[j] != void 0 && j != lineNumber) {
                    currentLine = currentLine.concat(lines[j]);
                    lines[j] = null;
                }
            }

            lines[lineNumber] = currentLine;
        }

        for (const i in lines) {
            const line: Field[] = lines[i];

            let topmostRow = 1000;// random number
            if (line) {
                for (const field of line) {
                    if (field.row < topmostRow) {
                        topmostRow = field.row;
                    }
                }
            }

            if (line) {
                for (const field of line) {
                    field.row = topmostRow;
                }
            }
        }

        layout.lines = lines;
    }

    private buildFields(layout: Layout, fields: RawField[], absLeft: number): void {
        for (const field of fields) {
            let label: Field = null;
            let input: Field = null;

            const internalName: string = field.internal;
            const model: FieldModel = this.getFieldModel(internalName);

            // there are some field types we do not support like webcontrols, imagecontrols ans multifields
            // these fields do not have a model, so continue;
            if (model == void 0) {
                continue;
            }

            if (model.type == FieldControlType.GRID) {
                field.input_pos = field.field_pos;
            }

            const absWidth: number = layout.mainForm.width;

            if (!this.isZeroSized(field.field_pos)) {
                label = this.buildFieldPositioning(layout, field.field_pos, true, model, absLeft, absWidth);
                label.rawField = field;

                if (model.type != FieldControlType.GRID) {
                    layout.fieldList.push(label);
                    label.index = layout.fieldList.length;
                } else if (this.isZeroSized(field.input_pos)) {
                    field.input_pos = field.field_pos;
                }

                label.absLeftPixel = absLeft + Number(field.field_pos.left);
                label.parentLeftPixel = absLeft;
            }

            if (!this.isZeroSized(field.input_pos) && model.type != FieldControlType.PAGE_CONTROL) {
                input = this.buildFieldPositioning(layout, field.input_pos, false, model, absLeft, absWidth);
                input.rawField = field;

                layout.fieldList.push(input);

                input.index = layout.fieldList.length;
                input.absLeftPixel = absLeft + Number(field.input_pos.left);
            }

            if (this.isZeroSized(field.field_pos) && this.isZeroSized(field.input_pos)) {
                layout.zeroSizedFields.push(field);
            }

            if (label != void 0 && input != void 0 && typeof (label) == "object" && typeof (input) == "object") {
                label.sibling = input;
                input.sibling = label;

                // check if the label is in the same row as its input
                // sometimes the algorithm fails to round to the same positions
                this.alignInRow(field.field_pos, field.input_pos, label, input);

                this.isLabelAbove(label, input);

                if (model.type == FieldControlType.GRID) {
                    input.absLeftInCols = label.absLeftInCols;
                    input.row = label.row;
                    input.height = label.height;
                }

                if (input.isLabelAbove) {
                    if (input.left - label.left > 0) {
                        label.absWidthInCols += input.absLeftInCols - label.absLeftInCols;
                    }
                    // snap label to left side of field
                    label.absLeftInCols = input.absLeftInCols;
                }
            }
        }
    }

    private maximizeLabelWidth(layout: Layout): void {
        for (const field of layout.fieldList) {
            let newLeft = 0;
            if (field.isOffCanvas) {
                continue;
            }

            if (field.isLabel) {
                let isSomeFieldInbetween = false;
                const height: number = field.height;
                const row: number = field.row;

                for (let j: number = row; j < row + height; j++) {
                    if(j >= layout.rows.length) {
                        console.debug(`maximizeLabelWidth(): Desired row index ${j} is out of bounds (length ${layout.rows.length})`);
                        continue;
                    }
                    if(!layout.rows[j]) {
                        console.debug(`maximizeLabelWidth(): Desired row index ${j} is missing`);
                        continue;
                    }

                    const elements: Field[] = layout.rows[j];
                    const elementRights: number[] = [];

                    for (const element of elements) {
                        if (element.internal == field.internal) {
                            continue;
                        }

                        let elementRight: number = element.realLeft + element.realWidth;

                        if (element.model && element.model.addon != void 0) {
                            elementRight += layout.form.addonWidth;
                        }

                        elementRights.push(elementRight);

                        if (Math.floor(elementRight) <= Math.floor(field.realLeft) && elementRight > newLeft) {
                            newLeft = elementRight;
                        }
                    }

                    // we need to make sure no field is inbetween
                    for (const r of elementRights) {
                        if (r > newLeft && r <= field.realLeft + field.realWidth) {
                            isSomeFieldInbetween = true;
                        }
                    }
                }

                const isLabelAbove: boolean = field.sibling != void 0 && (field.row == field.sibling.row - 1 || field.row == field.sibling.row - 2);

                if (!isLabelAbove && !isSomeFieldInbetween && field.model.type !== FieldControlType.STATIC) {
                    field.realWidth += field.realLeft - newLeft;
                    field.realLeft = newLeft;
                } else if (isLabelAbove) {
                    field.realWidth = field.sibling.realWidth;
                }
            }
        }
    }

    private calculateAddonPosition(layout: Layout, absLeft: number): number {
        const addonTemplate: ElementPosition = {
            top: 0,
            left: 0,
            bottom: 12,
            right: 12
        };

        const model: FieldModel = new FieldModel({});
        const pos: Field = this.buildFieldPositioning(layout, addonTemplate, false, model, absLeft, layout.form.width);

        if (layout.parent == void 0) {
            return pos.absWidthInCols;
        } else {
            return pos.absWidthInCols * this.deafultColCount / layout.parent.absWidthInCols;
        }
    }

    private sortFieldListByTabindex(layout: Layout): void {
        layout.fieldList.sort((a: Field, b: Field) => a.index < b.index ? -1 : a.index > b.index ? 1 : 0);
    }
}
