import {Directive, ElementRef} from "@angular/core";
import {OnInit} from "@angular/core";

@Directive({
    selector: "[eobDefaultInput]"
})
export class DefaultInputDirective implements OnInit {

    constructor(private el : ElementRef) {
    }

    ngOnInit(): void {
        this.el.nativeElement.setAttribute("autocomplete", "off");
        this.el.nativeElement.setAttribute("autocapitalize", "off");
        this.el.nativeElement.setAttribute("autocorrect", "off");
        this.el.nativeElement.setAttribute("spellcheck", "false");
    }
}