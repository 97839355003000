export class Semaphore {
    private readonly limit: number = 0;

    private queue: any[] = [];

    constructor(private open: number) {
        if (open <= 0) {
            throw new Error("semaphore must be intitialized to a positive value");
        }
        this.limit = open;
    }

    get(portion: number): Promise<any> {
        return this.getByFn(() => portion);
    }

    getByFn(portionFn: () => number): Promise<any> {
        const ticket: Promise<any> = new Promise<any>((resolve, reject) => this.queue.push({portionFn, resolve, reject}));

        this.check();

        return ticket;
    }

    releaseAll(): void {
        this.queue.forEach(consumer => consumer.reject());
    }

    private check(): void {
        if (this.queue.length <= 0) {
            return;
        }

        const consumer: any = this.queue[0];
        const portion: number = consumer.portionFn();

        if (this.open >= portion || this.open == this.limit) {
            consumer.portion = portion;
            this.dispatch();
        }
    }

    private dispatch(): void {
        const consumer: any = this.queue.shift();

        this.open-=consumer.portion;

        consumer.resolve(() => this.release(consumer.portion));
    }

    private release(portion: number): void {
        this.open+=portion;
        this.check();
    }
}