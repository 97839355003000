require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/form/field-builder/eob.form.grid.builder.srv.js");
const {datetimepickerFactory} = require("jquery-datetimepicker")
// eslint-disable-next-line no-undef
datetimepickerFactory(jQuery)

angular.module("eob.core").factory("formFieldBuilder", FormFieldBuilder);

FormFieldBuilder.$inject = ["environmentService", "formGridBuilder"];

export default function FormFieldBuilder(EnvironmentService, FormGridBuilder) {
    // eslint-disable-next-line no-undef
  jQuery.datetimepicker.setLocale(EnvironmentService.getLanguage());

  return {
    createGridField: FormGridBuilder.createGrid,
  };
}
