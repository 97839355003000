<div *ngIf="!hidden" class="timer" [ngClass]="{'timer__hidden': !hiddenInfo}">
    <div class="timer__info" [ngClass]="{'timer__hidden--info': hiddenInfo}">
        <div class="timer__info--close"><div (click)="toggle()">—</div></div>
        <div class="timer__info--message">
            {{"timer.component.user.hint" | ngTranslate}}
        </div>
    </div>
    <div class="timer__countdown" (click)="toggle()">
        <div *ngIf="currentTime?.days">
            <div class="timer__countdown--label" *ngIf="!hiddenInfo">{{"timer.component.days" | ngTranslate}}</div>
            <div class="timer__countdown--value">{{ currentTime?.days }}</div>
        </div>
        <div>
            <div class="timer__countdown--label" *ngIf="!hiddenInfo">{{"timer.component.hours" | ngTranslate}}</div>
            <div class="timer__countdown--value">{{ currentTime?.hours }}</div>
        </div>
        <div>
            <div class="timer__countdown--label" *ngIf="!hiddenInfo">{{"timer.component.minutes" | ngTranslate}}</div>
            <div class="timer__countdown--value">{{ currentTime?.minutes }}</div>
        </div>
        <div>
            <div class="timer__countdown--label" *ngIf="!hiddenInfo">{{"timer.component.seconds" | ngTranslate}}</div>
            <div class="timer__countdown--value">{{ currentTime?.seconds }}</div>
        </div>
    </div>
</div>