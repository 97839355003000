import {Injectable, Injector} from "@angular/core";
import {
    AuthenticationData,
    AuthenticationProvider,
    AuthenticationStatusEvent,
    AuthenticationType
} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {NoneAuthService} from "CORE_PATH/authentication/protocols/none-auth.service";
import {BasicAuthService} from "CORE_PATH/authentication/protocols/basic-auth.service";
import {Observable, ReplaySubject, Subscription} from "rxjs";
import {tap} from "rxjs/operators";
import {NtlmAuthService} from "./protocols/ntlm-auth.service";
import {AuthenticationModule} from "CORE_PATH/authentication/authentication.module";
import {HttpHandler, HttpRequest} from "@angular/common/http";
import {OidcElectronAuthDelegateService} from "CORE_PATH/authentication/protocols/oidc-electron-auth-delegate.service";

@Injectable({
    providedIn: AuthenticationModule
})
export class AuthenticationService implements AuthenticationProvider {

    authenticationProvider: AuthenticationProvider;
    statusEvent$: ReplaySubject<AuthenticationStatusEvent> = new ReplaySubject<AuthenticationStatusEvent>(1);
    providerStatusSub: Subscription;
    authenticated: boolean = false;

    constructor(private injector: Injector) {
        this.authenticationProvider = this.injector.get<NoneAuthService>(NoneAuthService);

        // if in electron, subscribe to status event to propagate auth headers in main side
        if (window.electron) {
            this.statusEvent$.subscribe(event => {
                if (Array.isArray(event.requestHeaders)) {
                    window.electron.propagateAuthHeaders(event.requestHeaders);
                }
            });
        }
    }

    getStatusEvent(): Observable<AuthenticationStatusEvent> {
        return this.statusEvent$.asObservable();
    }

    selectAuthenticationProvider(type: AuthenticationType): void {
        switch (type) {
            case AuthenticationType.BASIC_AUTH:
                this.authenticationProvider = this.injector.get<BasicAuthService>(BasicAuthService);
                break;
            case AuthenticationType.OPENRESTY_KEYCLOAK:
                if (window.electron) {
                    this.authenticationProvider = this.injector.get<OidcElectronAuthDelegateService>(OidcElectronAuthDelegateService);
                } else {
                    // TODO add non-electron oidc auth service
                    this.authenticationProvider = this.injector.get<NoneAuthService>(NoneAuthService);
                }
                break;
            case AuthenticationType.NTLM_SYSTEM:
            case AuthenticationType.NTLM_USERNAME:
                this.authenticationProvider = this.injector.get<NtlmAuthService>(NtlmAuthService);
                break;
            case AuthenticationType.NONE:
            default:
                this.authenticationProvider = this.injector.get<NoneAuthService>(NoneAuthService);
        }
        this.providerStatusSub?.unsubscribe();
        this.providerStatusSub = this.authenticationProvider.getStatusEvent().subscribe(x => this.statusEvent$.next(x));
    }

    isAuthenticated(): Observable<boolean> {
        return this.authenticationProvider.isAuthenticated();
    }

    authenticate(backendOrigin: string, credentials?: AuthenticationData): Observable<AuthenticationStatusEvent> {
        return this.authenticationProvider.authenticate(backendOrigin, credentials).pipe(tap(x => this.statusEvent$.next(x)));
    }

    invalidateSession(): Observable<AuthenticationStatusEvent> {
        return this.authenticationProvider.invalidateSession().pipe(tap(x => this.statusEvent$.next(x)));
    }

    handleReauthenticationRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.authenticationProvider.handleReauthenticationRequest(req, next);
    }
}
