(function () {


    require("SERVICES_PATH/eob.state.history.manager.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");

    require("COMPONENTS_PATH/eob-state-wrapper/eob-dashboard/eob.dashboard.dir.js");

    angular.module("eob.client").controller("dashboardController", DashboardController);

    DashboardController.$inject = ["$stateParams", "$state", "$filter", "stateHistoryManager", "environmentService", "clientService"];

    //the linked controller function
    function DashboardController($stateParams, $state, $filter, StateHistoryManager, EnvironmentService, ClientService) {
        if ($stateParams.state == void 0) {
            let stateId = $.now(),
                nextStateContent;
            //if it is cordova or electron and the client is offline start in favorites state
            if (ClientService.isLocalClient() && ClientService.isOffline()) {

                nextStateContent = {
                    config: {
                        executeSingleHitAction: false
                    },
                    type: "hitlist.offlineObjects",
                    description: $filter("translate")("eob.app.bar.favorites.title")
                };

                StateHistoryManager.setStateData(nextStateContent, stateId);
                $state.go("hitlist.offlineObjects", {state: stateId});

            } else if (EnvironmentService.env.autoOpenWorkflow && EnvironmentService.isWorkflowUser()) {
                nextStateContent = {
                    config: {},
                    type: "workflow"
                };

                // this function generates a new state with given data
                StateHistoryManager.setStateData(nextStateContent, stateId);
                $state.go("hitlist.inbox", {type: "workflow", state: stateId});
            } else {
                nextStateContent = {
                    config: {}
                };

                // this function generates a new state with given data
                StateHistoryManager.setStateData(nextStateContent, stateId);
                $state.go("dashboard", {state: stateId}, {location: "replace"});
            }
        }
    }
})();
