import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {BackendObjectService} from "../../../app/core/backend/services/object/backend-object.service";

require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

angular.module("eob.framework").directive("eobWorkflowFiles", EobWorkflowFiles);

EobWorkflowFiles.$inject = ["$stateParams", "gridContentService", "$q", "$rootScope", "notificationsService", "$filter",
    "$timeout", "stateHistoryManager", "viewerService", "toolService", "cacheManagerService", "dmsDocumentService",
    "clientService", "messageService", "backendObjectService"];

/**
 * A directive for displaying workflow files.
 */
// eslint-disable-next-line max-params, require-jsdoc
function EobWorkflowFiles($stateParams, GridContentService, $q, $rootScope, NotificationsService, $filter,
                          $timeout, StateHistoryManager, ViewerService, ToolService, CacheManagerService, DmsDocumentService,
                          ClientService, MessageService, BackendObjectService) {
    return {
        restrict: "E",
        scope: {
            formHelper: "=formHelper",
            modelDef: "="
        },
        async link(scope) {
            scope.wfFileAreaWorkConfig = null;
            scope.wfFileAreaInfoConfig = null;
            scope.showInfoArea = null;

            scope.wfFileAreaWorkFiles = {};
            scope.wfFileAreaInfoFiles = {};
            scope.shouldUseTabs = ClientService.isPhoneOrTablet();
            scope.isForcedPhoneLayout = ClientService.isForcedPhoneLayout();

            let workDocuments = [];
            let infoDocuments = [];
            let stateId = $stateParams.state;
            let gContextId;

            let closeInlineDialogsSubscription = MessageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, (event) => {
                if (!event) {
                    return;
                }

                if ((ClientService.isPhone() && event.target && angular.element(event.target).hasClass("overlayContextMenu") || event.target.closest(".close-modal")) ||
                    !(event.target.closest(".context-menu-item") && event.target.closest(".context-menu-item").getAttribute("hassubmenu") == "true")) {
                    scope.wfFileAreaWorkFiles.dropdownOpen = false;
                    scope.wfFileAreaInfoFiles.dropdownOpen = false;

                    scope.$apply()
                }
            });

            scope.showInsertMenu = showInsertMenu;

            scope.$on("workflow.add.typeless.to.file.area", (ev, item) => scope.formHelper.addFile(item.id, item.typeId, item.fileArea, "2"));
            scope.$on("workflow.add.to.file.area", addWfFileToArea);
            scope.$on("remove.from.workflow", (ev, item) => scope.formHelper.removeFile(item.id));
            scope.$on("workflow.switch.file.area", (ev, item) => {
                let wfFile = scope.formHelper.getFile(item.id).wfFile;
                return wfFile.model.workspace ? wfFile.api.moveToInfo() : wfFile.api.moveToWork();
            });
            scope.$on("$destroy", () => {
                closeInlineDialogsSubscription.unsubscribe();
                CacheManagerService.dmsDocuments.detachListeners(gContextId);

                if (scope.shouldUseTabs) {
                    StateHistoryManager.updateConfig({ fileActiveTab: scope.openTab }, stateId);
                }
            });

            scope.switchSettingsTab = (tab) => {
                scope.openTab = `${["workspace", "infoArea"].indexOf(tab) >= 0 ? tab : "workspace"}`;
            };

            await initAsync();

            /**
             * Initialize the workflow file directive.
             *
             * @access private
             * @returns {Promise} Resolved once the directive is initialized.
             */
            async function initAsync() {
                let files = scope.formHelper.getFiles();
                scope.showInfoArea = scope.modelDef.config.showInfoArea;
                for (let osid in files) {
                    let { wfFile, dmsDocument } = files[osid];

                    if (!dmsDocument.model.isForbidden) {
                        if (scope.showInfoArea) {
                            if (wfFile.model.workspace) {
                                workDocuments.push(dmsDocument);
                            } else {
                                infoDocuments.push(dmsDocument);
                            }
                        } else {
                            workDocuments.push(dmsDocument);
                        }
                    }
                }

                let workConfig = GridContentService.getListEntries(workDocuments, "wfFileAreaWorkFiles");
                workConfig.suppressSelectionOnInit = true;
                scope.wfFileAreaWorkConfig = workConfig;
                scope.wfFileAreaWorkConfig.context = "wfFileAreaWorkFiles";

                scope.wfFileAreaWorkConfig.contextData = {
                    workflowId: scope.modelDef.config.workflowId,
                    showInfoArea: scope.showInfoArea,
                    context: "wfFileAreaWorkFiles",
                    permittedObjectTypes: scope.modelDef.getPermittedObjectTypes()
                };

                if (scope.showInfoArea) {
                    workConfig.onSelectionChange = () => scope.wfFileAreaInfoConfig.api.deselectAll();
                    let infoConfig = GridContentService.getListEntries(infoDocuments, "wfFileAreaInfoFiles");
                    infoConfig.onSelectionChange = () => scope.wfFileAreaWorkConfig.api.deselectAll();
                    infoConfig.suppressSelectionOnInit = true;
                    scope.wfFileAreaInfoConfig = infoConfig;
                    scope.wfFileAreaInfoConfig.context = "wfFileAreaInfoFiles";
                    scope.wfFileAreaInfoConfig.contextData = {
                        workflowId: scope.modelDef.config.workflowId,
                        showInfoArea: scope.showInfoArea,
                        context: "wfFileAreaInfoFiles",
                        permittedObjectTypes: scope.modelDef.getPermittedObjectTypes()
                    };
                }

                $timeout(() => {
                    // wait until grid is initialized, then config.api methods are set
                    scope.formHelper.setFileGrids({
                        work: scope.wfFileAreaWorkConfig.api,
                        info: scope.showInfoArea ? scope.wfFileAreaInfoConfig.api : {}.api
                    });

                    let data = StateHistoryManager.getCurrentConfig();
                    let osIds = Object.keys(files);

                    if (scope.shouldUseTabs) {
                        scope.openTab = (data.fileActiveTab) ? data.fileActiveTab : scope.showInfoArea && (scope.wfFileAreaInfoConfig.rows.length > 0 && !workConfig.rows.length > 0) ? "infoArea" : "workspace";
                    }

                    // DODO-10568. Could be solved better with refactoring attach-/detachListeners then now in the bugfix.
                    if (osIds.length > 0 && CacheManagerService.dmsDocuments.getById(osIds[0]) == void 0) {
                        let dmsDocuments = [];

                        for (let osid in files) {
                            dmsDocuments.push(files[osid].dmsDocument);
                        }

                        CacheManagerService.dmsDocuments.add(dmsDocuments);
                    }

                    gContextId = CacheManagerService.dmsDocuments.attachListener(Object.keys(files), onDocumentChanged);

                    if (data == void 0 || data.selectedItems == void 0) {
                        selectMainFileDocument(files);
                    }
                }, 0);
            }

            /**
             * Callback for the dmsCache.
             *
             * @param {string[]} updatedIds - The osids of the changed dmsDocuments.
             */
            function onDocumentChanged(updatedIds) {
                let wfFileAreas = ["wfFileAreaWork"];
                if (scope.showInfoArea) {
                    wfFileAreas.push("wfFileAreaInfo");
                }

                wfFileAreas.forEach((wfFileArea) => {
                    // ["wfFileAreaWork", "wfFileAreaInfo"].forEach((wfFileArea) => {
                    let fileConfigApi = scope[`${wfFileArea}Config`].api,
                        ids = fileConfigApi.getRows().map((row) => row.osid);

                    let isUpdateNecessary = ids.find(id => updatedIds.indexOf(id) !== -1) != void 0;

                    isUpdateNecessary && fileConfigApi.updateRows(GridContentService.getListEntries(CacheManagerService.dmsDocuments.get(ids), `${wfFileArea}Files`));
                });
            }

            /**
             * Select the main workflow file document or the next available file.
             *
             * @param {object} files - A map of all available workflow file items.
             */
            function selectMainFileDocument(files) {
                let displayFileFound = false;
                let objectId = scope.modelDef.config.objectId;

                for (let i in files) {
                    if (files[i].wfFile.model.id == objectId &&
                        !files[i].dmsDocument.model.isForbidden) {
                        setTimeout(() => {
                            let area = files[i].wfFile.model.workspace == true || !scope.showInfoArea ? scope.wfFileAreaWorkConfig : scope.wfFileAreaInfoConfig;
                            area.api.selectItems([i], "id");
                            ViewerService.setFileMimeTypeGroup(files[i].dmsDocument.model.fileProperties.mimeTypeGroup, files[i].dmsDocument.model.mainType, files[i].dmsDocument.model.isTypeless);
                            ViewerService.updateViewer(objectId);
                        }, 0);

                        displayFileFound = true;
                        break;
                    }
                }

                if (!displayFileFound) {
                    if (scope.wfFileAreaWorkConfig.rows.length > 0) {
                        scope.wfFileAreaWorkConfig.api.selectItems(scope.wfFileAreaWorkConfig.rows[0].osid, "id");
                    } else if (scope.showInfoArea && scope.wfFileAreaInfoConfig.rows.length > 0) {
                        scope.wfFileAreaInfoConfig.api.selectItems(scope.wfFileAreaInfoConfig.rows[0].osid, "id");
                    }
                }
            }

            /**
             * Create a new wfFile and add it to a file area.
             *
             * @param {object} ev - A js event.
             * @param {object} item - The data the wfFile is created from.
             * @returns {Promise} Resolved once the wfFile is added.
             */
            async function addWfFileToArea(ev, item) {
                try {
                    const locations = await BackendObjectService.getDmsObjectLocations(item.model.osid, item.model.objectTypeId).toPromise();

                    if (locations == undefined) {
                        NotificationsService.info($filter("translate")("eob.workflow.files.file.add.no.location.error"));
                        return;
                    }

                } catch (error) {
                    NotificationsService.warning($filter("translate")("eob.workflow.files.file.add.error"));
                    return;
                }

                let files = scope.formHelper.getFiles();

                if (files[item.model.id]) {
                    NotificationsService.warning($filter("translate")("eob.workflow.files.file.already.exists"));
                    return;
                }

                let targetConfig = item.model.wfItem.workspace ? scope.wfFileAreaWorkConfig : scope.wfFileAreaInfoConfig;
                let rows = targetConfig.api.getRows();

                for (let i in rows) {
                    let row = rows[i];

                    if (row.id == item.model.id) {
                        return;
                    }
                }

                // add file to form helper
                let targetLocation = (item.model.wfItem.workspace) ? "work" : "info";

                CacheManagerService.dmsDocuments.attachListener(item.model.osid, onDocumentChanged, gContextId);

                try {
                    await scope.formHelper.addFile(item.model.id, item.model.objectTypeId, targetLocation, item.model.wfItem.location);
                } catch (error) {
                    console.error(error);
                    NotificationsService.warning($filter("translate")("eob.workflow.files.file.add.error"));
                }
            }

            /**
             * Open the kebab menu of a wfFileArea.
             *
             * @param {string} context - The wfFileArea key.
             * @param {object} event - A js event.
             */
            function showInsertMenu(context, event) {
                let contextData = {
                    workflowId: scope.modelDef.config.workflowId,
                    showInfoArea: scope.showInfoArea,
                    context,
                    title: context == "wfFileAreaWorkFiles" ? $filter("translate")("eob.workflow.files.work.area.title") : $filter("translate")("eob.workflow.files.info.area.title"),
                    permittedObjectTypes: scope.modelDef.getPermittedObjectTypes()
                };

                if (scope.wfFileAreaWorkFiles.dropdownOpen || scope.wfFileAreaInfoFiles.dropdownOpen) {
                    $rootScope.$broadcast("close.inline.dialogs");
                    return;
                }

                scope[context].dropdownOpen = true;

                event.preventDefault();
                event.stopImmediatePropagation();

                // adjust event, to make the cm appear under the button
                let kebabButton = angular.element(event.target).closest(".add-to-workflow-files")[0];
                event.clientX = kebabButton.getBoundingClientRect().left;
                event.clientY = kebabButton.getBoundingClientRect().top + kebabButton.offsetHeight;

                MessageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                    items: [],
                    contextData,
                    event: event.originalEvent
                });
            };
        },
        template: require("!raw-loader!./eob.workflow.files.html")
    };
}
