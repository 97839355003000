import { ReplaySubject } from "rxjs";
import {BeforeOpenResultCode} from "./eob.client.script.codes";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {ProfileCacheKey} from "ENUMS_PATH/database/profile-cache-key.enum";

require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/mobile-desktop/eob.file.cache.srv");
require("SERVICES_PATH/scripting/form/eob.form.tools.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/scripting/eob.script.helper.model.srv.js");

angular.module("eob.core").factory("clientScriptService", ClientScriptService);

ClientScriptService.$inject = ["$injector", "$filter", "backendService", "fileCacheService", "clientService",
    "formToolsService", "organisationService", "environmentService", "errorModelService", "cacheManagerService", "modalDialogService"];

/**
 * Provides accessibility to global and user specific client scripts.
 * @returns public method interface
 * @constructor
 */
// eslint-disable-next-line max-params, require-jsdoc
export default function ClientScriptService($injector, $filter, BackendService, FileCacheService, ClientService,
                             FormToolsService, OrganisationService, EnvironmentService, ErrorModelService, CacheManagerService, ModalDialogService) {
    /**
     * The object used by the scripter. Do not touch it!
     * @type {object}
     */
    const gScriptingStorage = {};
    /**
     * The backend url to request scripts.
     * @type {string}
     */
    const gScriptsURL = "/documents/scripts?clienttype=web",
        gListenerGuid = CacheManagerService.scripts.attachListener([]);

    let gScriptKeys = [];

    let service = {
        getGlobalScriptingStorage,
        getGlobalScriptAsync,
        getByObjectTypeIdAsync,
        executeGlobalScriptsAsync,
        executeAfterLoginScriptAsync,
        executeBeforeStartQueryScriptAsync,
        executeOnMoveScriptAsync,
        executeOnCreateCopyScriptAsync,
        executeOnAddLocationScriptAsync,
        executeBeforeOpenObjectScript
    };

    return service;

    /**
     * Get the global scripting object for the scripter that uses our scripts.
     * @access public
     * @returns {object} The global scripting object.
     */
    function getGlobalScriptingStorage() {
        return gScriptingStorage;
    }

    /**
     * Get the global script from the cache or backend, depending on whether the client is on- or offline.
     * @access public
     * @returns {object} The global script.
     */
    async function getGlobalScriptAsync() {
        let result = await getScriptAsync("global_webclient", GlobalCacheKey.DMS_GLOBAL_SCRIPT_CACHE);

        if (result == void 0) {
            return {};
        } else {
            return result.data ? result.data : result;
        }
    }

    /**
     * Get a specific script from the backend or cache.
     * @access private
     * @param {string} eventType - The eventtype the script is identified with.
     * @param {string=} cacheKey - The either global or persistent cache key. May be null, if the script shall not be cached.
     * @param {number|string} objectTypeId - The objectType id the script is for. For the global script, it may be undefined.
     * @returns {Promise<object|undefined>} - The script object. May be undefined, if it cannot be found.
     */
    async function getScriptAsync(eventType, cacheKey, objectTypeId) {
        let script,
            cachableEventTypes = ["before_start_query", "on_add_location", "on_create_copy", "on_move"],
            shouldBeCached = eventType.indexOf("global") >= 0 || cachableEventTypes.includes(eventType),

            scriptKey = `${eventType}${objectTypeId ? objectTypeId : ""}`;

        try {
            if (shouldBeCached) {
                script = CacheManagerService.scripts.getById(scriptKey);
                if (script != void 0) {
                    // the script property is missing, if we already assured that there is no script for this eventtype
                    return script.model.script ? script.model : undefined;
                }
            }

            let scripts = [];
            if (ClientService.isOnline() || cacheKey != void 0) {
                let urlAddition = objectTypeId ? `&objecttypeid=${objectTypeId}` : "";
                let result = await BackendService.cachedGetAsync(`${gScriptsURL}&eventtype=${eventType}${urlAddition}`, cacheKey);

                if (Array.isArray(result)) {
                    scripts = result;
                } else if (result && result.data && Array.isArray(result.data)) {
                    scripts = result.data;
                }
            } else if (objectTypeId !== void 0){
                const allScripts = await FileCacheService.getContentAsync(DatabaseEntryType.GLOBAL,
                    ProfileCacheKey.DMS_OBJECT_TYPE_SCRIPT_CACHE, {
                        group: ProfileCacheKey.OFFLINE_CONTENT_CACHE_GROUP,
                        first: true
                    });

                if (allScripts != void 0) {
                    scripts = (allScripts[objectTypeId] || {}).data || [];
                }
            }

            // Make sure the event type of the response matches the requested event type
            // If the AppConnector is not aware of the queried type, it will simply return every script
            script = scripts.find(x => (x.eventType && x.eventType == eventType.toUpperCase()) || (!x.eventType && objectTypeId == void 0));

            if (shouldBeCached) {
                addScriptToCache(script, eventType, objectTypeId);
            }
        } catch (error) {
            console.error(`loading the ${eventType} script failed: ${error}`);
        }

        return script;
    }

    /**
     * Get the global and user specific object type scripts from the cache or backend, depending on whether
     * the client is online or offline.
     * @access public
     * @param {string|number} objectTypeId - The object type id of the item that shall be gotten from the cache.
     * @returns {{ data: [] }} A response object with an array of scripts.
     */
    async function getByObjectTypeIdAsync(objectTypeId) {
        const DmsDocumentService = $injector.get("dmsDocumentService");

        if (!DmsDocumentService.isValidOsid(objectTypeId)) {
            return { data: [] };
        }

        let result = [];

        try {
            if (ClientService.isOnline()) {
                result = await BackendService.get(`${gScriptsURL}&objecttypeid=${objectTypeId}`);
            } else {
                let dmsScripts = await FileCacheService.getContentAsync(DatabaseEntryType.GLOBAL,
                    ProfileCacheKey.DMS_OBJECT_TYPE_SCRIPT_CACHE,
                    { group: ProfileCacheKey.OFFLINE_SCRIPT_CACHE_GROUP, first: true });

                result = (dmsScripts || {})[objectTypeId];
            }

            result = result == void 0 ? { data: [] } : result;

            let globalTypeScript = result.data.find(script => script.eventType == "global_webclient_object_type");
            addScriptToCache(globalTypeScript, "global_webclient_object_type", objectTypeId);
        } catch (error) {
            console.error(`loading DMS object type scripts failed: ${error}`);
        }

        return result == void 0 ? { data: [] } : result;
    }

    /**
     * Persist the given script. If no script is given, an empty script is persisted.
     * @param {object=} script - A backend script object. May be undefined.
     * @param {string} eventType - The eventtype of the script.
     * @param {number|string=} objectTypeId - The objectType the script is for.
     */
    function addScriptToCache(script, eventType, objectTypeId) {
        let scriptKey = CacheManagerService.scripts.add(script || {eventType, objectTypeId})[0];

        if (gScriptKeys.indexOf(scriptKey) < 0) {
            gScriptKeys.push(scriptKey);
            CacheManagerService.scripts.updateListener(gListenerGuid, gScriptKeys);
        }
    }

    /**
     * Get the afterLogin script and execute it.
     * @access public
     * @returns {string[]} - An array with internal names of objectTypes, that shall be disabled for the client session.
     */
    async function executeAfterLoginScriptAsync() {
        const done$ = new ReplaySubject(1);
        const ScriptHelperModelService = $injector.get("scriptHelperModelService");
        let scriptObj = await getScriptAsync("webclient_after_login", GlobalCacheKey.DMS_AFTER_LOGIN_SCRIPT_CACHE);

        if (scriptObj == void 0 || !scriptObj.script) {
            return [];
        }
        if (!/done\(.*\)/gi.test(scriptObj.script)) {
            throw "After login script lacks done() call.";
        }

        let scriptFunc = FormToolsService.defineFunction(["formHelper", "data", "scriptingStorage", "done"], scriptObj.script, "Webclient_After_Login");

        const scriptData = {
            objectTypesToIgnore: [],
            users: OrganisationService.getUserList(),
            groups: OrganisationService.getGroupList(),
            roles: EnvironmentService.getUserRoles(),
            session: EnvironmentService.getSessionInfo()
        };

        let done = (returnCode, errorMessage) => {
            if (returnCode === -1) {
                done$.error(errorMessage);
                throw new Error(errorMessage);
            }
            done$.next(scriptData.objectTypesToIgnore);
            done$.complete();
        };

        let scriptHelper = ScriptHelperModelService.getScriptHelper();

        try {
            await scriptHelper.bindScripts();
            scriptFunc(scriptHelper, scriptData, gScriptingStorage, done);
        } catch(err) {
            // Make sure the error message is also printed to the console when logging to idb
            _console.warn(err);
            done$.error(err);
            done();
            throw ErrorModelService.createCustomError("WEB_FORM_SCRIPT_RETURNED_ERROR")
        }
        return done$.toPromise()
    }

    /**
     * Get the global scripts and execute them.
     * @param {object} scriptHelper - A scriptHelper object.
     * @param {{objectTypeId: number, name: string}=} scriptData - Script data, to create the script names. The objectTypeId may be undefined.
     * @returns {Promise<void>} A promise resolved once the global functions have been executed.
     */
    async function executeGlobalScriptsAsync(scriptHelper, scriptData = {}) {
        let globalDMSScript = await service.getGlobalScriptAsync(),
            globalObjectTypeScript;

        if (scriptData.objectTypeId != void 0) {
            globalObjectTypeScript = await getScriptAsync("global_webclient_object_type", undefined, scriptData.objectTypeId);
        }

        try {
            for (let scriptObj of [globalDMSScript, globalObjectTypeScript]) {
                if (scriptObj) {
                    let scriptName = FormToolsService.getScriptName(false, scriptData, undefined, scriptObj);
                    FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage"], scriptObj.script, scriptName)(scriptHelper, scriptHelper.globals, getGlobalScriptingStorage());
                }
            }
        } catch (error) {
            console.error(error);
            throw ErrorModelService.createCustomError("WEB_FORM_SCRIPT_RETURNED_ERROR");
        }
    }

    /**
     * Get and execute the beforeStartQuery script for the given objectTypeId, if one exists.
     * The given query will be altered depending on the script and returned.
     *
     * @access public
     * @param {number} objectTypeId - The objectType id, the script shall be executed for.
     * @param {string} objectType - The name of the objectType.
     * @param {object} query - A osrest backend query.
     * @throws {CustomError} If the script fails a custom error will be thrown.
     * @returns {Promise<{query: object, resultCode: number}>} Is resolved with the script resultcode and the altered query.
     */
    async function executeBeforeStartQueryScriptAsync(objectTypeId, objectType, query) {
        const ScriptHelperModelService = $injector.get("scriptHelperModelService");
        let resultCode = 0;

        let scriptObj = await getScriptAsync("before_start_query", undefined, objectTypeId);

        if (scriptObj != void 0) {
            let scriptQuery = angular.copy(query);

            let scriptData = {objectTypeId, name: objectType},
                scriptName = FormToolsService.getScriptName(false, scriptData, undefined, scriptObj),
                scriptFn = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "query", "done"], scriptObj.script, scriptName);

            let scriptHelper = ScriptHelperModelService.getScriptHelper();
            await scriptHelper.bindScripts(scriptData);

            try {
                resultCode = await new Promise(resolve => scriptFn(scriptHelper, scriptHelper.globals, gScriptingStorage, scriptQuery, resolve));
            } catch (error) {
                console.error(error);
                throw ErrorModelService.createCustomError("WEB_FORM_SCRIPT_RETURNED_ERROR");
            }

            // use the changed query as default
            resultCode = resultCode == void 0 ? 1 : resultCode;

            // 0: use the query without script changes, 1: use the changed query
            query = resultCode == 0 ? query : scriptQuery;
        }

        return {resultCode, query};
    }


    /**
     * Execute the on_create_copy Event and act accordingly to the resultcode
     *
     * @access public
     * @param {object} clipboard - The clipboard content.
     * @param {DmsDocument} targetParent - The dmsDocument where the clipboard content has to be inserted
     * @throws {CustomError} If the script fails a custom error will be thrown.
     * @returns {Promise<number>} Is resolved with the script resultcode.
     */
    async function executeOnCreateCopyScriptAsync(clipboard, targetParent) {
        return await executeClipboardScriptEvent("on_create_copy", clipboard, targetParent)
    }

    /**
     * Execute the on_move Event and act accordingly to the resultcode
     *
     * @access public
     * @param {object} clipboard - The clipboard content.
     * @param {DmsDocument} targetParent - The dmsDocument where the clipboard content has to be inserted
     * @throws {CustomError} If the script fails a custom error will be thrown.
     * @returns {Promise<number>} Is resolved with the script resultcode.
     */
    async function executeOnMoveScriptAsync(clipboard, targetParent) {
        return await executeClipboardScriptEvent("on_move", clipboard, targetParent)
    }

    /**
     * Execute the on_add_location Event and act accordingly to the resultcode
     *
     * @access public
     * @param {object} clipboard - The clipboard content.
     * @param {DmsDocument} targetParent - The dmsDocument where the clipboard content has to be inserted
     * @throws {CustomError} If the script fails a custom error will be thrown.
     * @returns {Promise<number>} Is resolved with the script resultcode.
     */
    async function executeOnAddLocationScriptAsync(clipboard, targetParent) {
        return await executeClipboardScriptEvent("on_add_location", clipboard, targetParent)
    }

    /**
     * executes the given script
     * @param {string} eventType - the script event type
     * @param {object} clipboard - the clipboard content
     * @param {DmsDocument} targetParent - the tareget folder/register where the action takes place
     * @throws {CustomError} If the script fails a custom error will be thrown.
     * @returns {Promise<number>} - resolve a promise with the return value of the script
     */
    async function executeClipboardScriptEvent(eventType, clipboard, targetParent) {
        const ScriptHelperModelService = $injector.get("scriptHelperModelService");
        let resultCode = 0;
        let currentParent;

        if (clipboard.parentId != void 0) {
            currentParent = await CacheManagerService.dmsDocuments.getOrFetchById(clipboard.parentId);
        }

        let dmsDocument = clipboard.item;
        if(dmsDocument.model.osid) {
            dmsDocument = await CacheManagerService.dmsDocuments.getOrFetchById(dmsDocument.model.osid);
        }

        let scriptObj = await getScriptAsync(eventType, undefined, dmsDocument.model.objectTypeId);

        if (scriptObj != void 0) {
            // TODO DODO-15303 cleanup for 10.10 release
            let scriptData = {
                    dmsDocument: Object.assign(Object.assign({}, dmsDocument), dmsDocument.model),
                    currentParent: currentParent != void 0 ? currentParent.model : null,
                    targetParent: targetParent.model
                },
                scriptName = FormToolsService.getScriptName(false, dmsDocument.model, undefined, scriptObj),
                scriptFn;


            try {
                scriptFn = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "scriptData", "done"], scriptObj.script, scriptName);
            } catch (err) {
                let errorMessage = "";
                errorMessage += `${$filter("translate")("eob.form.script.returned.error.verbose").replace("%s1", scriptName)}\n\n`;
                console.error(errorMessage, err);
                errorMessage += err.stack;

                ModalDialogService.errorInfoDialog($filter("translate")("eob.notification.error.title"), $filter("translate")("modal.confirm.dms.get.script.error.message"), $filter("translate")("modal.button.close"), errorMessage)
                throw ErrorModelService.createCustomError("WEB_FORM_SCRIPT_RETURNED_ERROR");
            }

            let scriptHelper = ScriptHelperModelService.getScriptHelper();
            await scriptHelper.bindScripts(scriptData);

            try {
                resultCode = await new Promise(resolve => scriptFn(scriptHelper, scriptHelper.globals, gScriptingStorage, scriptData, resolve));
            } catch (error) {
                console.error(error);
                throw ErrorModelService.createCustomError("WEB_FORM_SCRIPT_RETURNED_ERROR");
            }

            // use the changed query as default
            resultCode = resultCode == void 0 ? 1 : resultCode;
        }

        return resultCode;
    }

    /**
     * Get and execute the BEFORE_OPEN script for the given objectTypeId, if one exists.
     *
     * @param {DmsDocument|any} dmsDocument - DMS document
     * @throws {CustomError} If the script fails a custom error will be thrown.
     * @returns {Promise<number>} Is resolved with the script resultcode.
     */
    async function executeBeforeOpenObjectScript(dmsDocument) {
        let resultCode = BeforeOpenResultCode.OPEN;

        if (dmsDocument == void 0 || dmsDocument.context == "workflow") {
            return resultCode;
        }

        const ScriptHelperModelService = $injector.get("scriptHelperModelService");
        let scriptObj = await getScriptAsync("BEFORE_OPEN", void 0, dmsDocument.model.objectTypeId);

        if (scriptObj != void 0) {
            let scriptData = { objectTypeId: dmsDocument.model.objectTypeId, name: dmsDocument.model.name },
                scriptName = FormToolsService.getScriptName(false, scriptData, void 0, scriptObj);
            try {
                let scriptFn = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "objectId", "objectTypeId", "done"], scriptObj.script, scriptName);
                let scriptHelper = ScriptHelperModelService.getScriptHelper();
                await scriptHelper.bindScripts(scriptData);

                resultCode = await new Promise(resolve => scriptFn(scriptHelper, scriptHelper.globals, gScriptingStorage, dmsDocument.model.osid, dmsDocument.model.objectTypeId, resolve));
            } catch (error) {
                let errorMessage = "";
                errorMessage += `${$filter("translate")("eob.form.script.returned.error.verbose").replace("%s1", scriptName)}\n\n`;
                console.error(errorMessage, error);
                errorMessage += error.stack;

                ModalDialogService.errorInfoDialog($filter("translate")("eob.notification.error.title"), $filter("translate")("modal.confirm.dms.get.script.error.message"), $filter("translate")("modal.button.close"), errorMessage);
                throw ErrorModelService.createCustomError("WEB_FORM_SCRIPT_RETURNED_ERROR");
            }
        }

        resultCode = resultCode === void 0 ? BeforeOpenResultCode.OPEN : resultCode;

        return resultCode;
    }
}
