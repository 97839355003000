import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";

require("SERVICES_PATH/eob.backend.srv.js");

angular.module("eob.core").factory("cacheModelService", CacheModelService);

CacheModelService.$inject = ["$rootScope", "toolService", "backendService", "offlineCacheService"];

/* eslint-disable */
/**
 * A service, that creates an object that caches items and manages listeners. The listeners will be executed when an item is changed.
 */
export default function CacheModelService($rootScope, ToolService, BackendService, OfflineCacheService) { /* eslint-enable */
    const gCONTEXT_PROPERTIES = ["revisits", "subscriptions", "subscriptionObjects", "variantData", "wfItem", "syncState", "vtx"];

        return {
            createCache(parseFn, identifierName) {
                return new Cache(parseFn, identifierName);
            }
        };

        /**
         * Creates a cache object.
         *
         * @param {function} parseFn - A function that parses backend data to the item structure that shall be cached.
         * @param {string} [identifierName] - The property name of the item identifier, that shall be used for caching.
         */
        function Cache(parseFn, identifierName) {
            let self = this,
                gCache = {},
                gListeners = {};

        const gPARSE_FN = parseFn,
            gIDENTIFIER_NAME = identifierName;

        self.add = add;
        self.remove = remove;
        self.contains = contains;

        self.getById = getById;
        self.getBy = getBy;
        self.getOrFetchById = getOrFetchById;
        self.getOrFetchByIds = getOrFetchByIds;
        self.get = get;
        self.getAsMap = getAsMap;
        self.getAll = getAll;

        self.attachListener = attachListener;
        self.updateListener = updateListener;
        self.detachListeners = detachListeners;
        self.executeListeners = executeListeners;
        self.executeGC = executeGC;

        window.addEventListener("hashchange", executeGC);

        /**
         * Adds one or multiple items to the cache.
         * Evaluates if the given items still need to be parsed and parses them accordingly before adding them.
         *
         * @param {object[]|object} items - An array or one object, that may either be a parsed object or backend data (for one or more items).
         * @param {boolean=} suppressListener - Add items to the cache without executing the listener functions.
         * @returns {Array} An array of ids of the cached items.
         */
        function add(items, suppressListener) {
            let updatedCacheItemIds = [],
                addedIds = [];

            if (items === void 0) {
                return [];
            }

            items = getArray(items);
            if (items.length < 1) {
                return [];
            }

                for (let item of items) {
                    let parsedItems = (item.model == void 0) ? gPARSE_FN(item) : item;
                    parsedItems = getArray(parsedItems);

                for (let parsedItem of parsedItems) {
                    let itemId = parsedItem.model[gIDENTIFIER_NAME].toString(),
                        cacheItem = gCache[itemId];

                    if (cacheItem != void 0) {
                        updatedCacheItemIds.push(itemId);
                        updateCacheItem(cacheItem, parsedItem);
                    } else {
                        gCache[itemId] = parsedItem;
                    }

                    addedIds.push(itemId);
                }
            }
            if (!suppressListener && updatedCacheItemIds.length > 0) {
                executeListeners(updatedCacheItemIds);
            }

            return addedIds;
        }

        /**
         * Updates the cached items.
         *
         * @param {object} cacheItem - An item from the cache.
         * @param {object} newItem - An item with updated properties.
         */
        function updateCacheItem(cacheItem, newItem) {
            if (cacheItem == newItem) {
                return;
            }

            let newModel = newItem.model;

            if (newItem instanceof DmsDocument) {
                newModel.fields = Object.assign({}, cacheItem.model.fields, newModel.fields);

                for (let contextProperty of gCONTEXT_PROPERTIES) {
                    if (newModel[contextProperty] == void 0) {
                        newModel[contextProperty] = cacheItem.model[contextProperty];
                    }
                }

                if (newModel.parentFields == void 0 || Object.keys(newModel.parentFields).length == 0) {
                    newModel.parentFields = cacheItem.model.parentFields;
                }
            }

            cacheItem.model = newModel;

            if (newItem.api !== void 0 && Object.keys(newItem.api).length > 0) {
                cacheItem.api = newItem.api;
            } else if ((cacheItem.api || {}).setDmsDocumentModel) {
                cacheItem.api.setDmsDocumentModel(cacheItem.model);
            }
        }

        /**
         * Removes deleted items from the cache.
         *
         * @param {string[]|number[]} deletedIds - One or many ids of the items that shall be deleted from the cache.
         */
        function remove(deletedIds) {
            let deletedCacheItemIds = [];

            deletedIds = stringifyArray(getArray(deletedIds));
            for (let deletedId of deletedIds) {
                let cacheItem = gCache[deletedId];

                if (cacheItem != void 0) {
                    deletedCacheItemIds.push(deletedId);
                    delete gCache[deletedId];
                }
            }

            executeListeners(deletedCacheItemIds);
        }

        /**
         * Does the cache contain the cache item with the given identifier.
         * @param {*} itemId - The main cache identifier.
         * @returns {boolean} - Whether the cache item exists.
         */
        function contains(itemId) {
            let item = getById(itemId);
            return item != void 0;
        }

        /**
         * Get an item from the cache.
         *
         * @param {string|number} itemId - The id of the item that shall be gotten from the cache.
         * @returns {null|object} The cached item or null.
         */
        function getById(itemId) {
            // filter out undefined AND null item ids
            // noinspection EqualityComparisonWithCoercionJS - please keep == instead of three or we will loose the null check
            if (itemId == void 0) {
                return null;
            }
            let cacheItems = get([itemId.toString()]);
            return cacheItems.length > 0 ? cacheItems[0] : null;
        }

        /**
         * Get an item from the cache by a property value that is not the main identifier.
         * @param {string} property - The name of the property. It will be searched under model. Make a deep search by using dots.
         * @param {*} value - The value of the property of the item that shall be gotten from the cache.
         * @returns {null|object} The cached item or null.
         */
        function getBy(property, value) {
            if (property.indexOf(".") < 0) {
                return Object.values(gCache).find(item => item.model[property] == value);
            }

            let propertyPath = property.split(".");
            return Object.values(gCache).find(item => {
                let tmp = item.model;
                for (let prop of propertyPath) {
                    tmp = tmp[prop];
                    if (tmp == void 0) {
                        return false;
                    }
                }
                return tmp == value;
            });
        }

        /**
         * Bulk version of {@link getOrFetchById}
         * @param {string[]} itemIds
         * @param {boolean=} forceFetch - Force fetching documents
         * @returns {Promise<Array>} DmsDocuments resulting from the queried ids
         */
        async function getOrFetchByIds(itemIds, forceFetch) {
            let result = []
            for (let id of itemIds) {
                try {
                    result.push(await getOrFetchById(id, undefined, forceFetch))
                } catch (error) {
                    console.warn(`Failed to fetch information for object id ${ id }`)
                }
            }
            return result;
        }

        /**
         * Get an item from the cache or the backend, if it's not cached locally
         * @param {string|number} itemId - The id of the item that shall be gotten from the cache.
         * @param {string|number=} objectTypeId - optional object type id
         * @param {boolean=} forceFetch - Force fetch document information
         * @returns {Promise<*>} The cached item or null.
         */
        async function getOrFetchById(itemId, objectTypeId, forceFetch) {
            if (!itemId) {
                return null;
            }

            itemId = itemId.toString();
            let localResult = self.getById(itemId);
            if (localResult && !forceFetch) {
                return localResult;
            }

            let response;
            if (navigator.onLine) {
                const url = `/documents/search/${itemId}?baseparams=false&fieldsschema=AUTO&refresh=true${objectTypeId ? `&objecttypeid=${objectTypeId}` : ""}`;
                try {
                    response = (await BackendService.get(url)).data;
                } catch (e) { /* not found */ }
            } else {
                response = OfflineCacheService.get(itemId);
            }

            if (!response) {
                console.warn(`could not fetchById for Id ${itemId}. Is the item gone?`);
                return null;
            }

            return self.getById(self.add(response));
        }

        /**
         * Get one or many items from the cache.
         *
         * @param {string[]|string|number[]|number} itemIds - One or an array of ids of the items that shall be gotten from the cache.
         * @returns {Array} An array of cache items. May be empty if none is found.
         */
        function get(itemIds) {
            return Object.values(getAsMap(itemIds));
        }

        /**
         * Get one or many items from the cache as a map with their main identifier.
         *
         * @param {string[]|string|number[]|number} itemIds - One or an array of ids of the items that shall be gotten from the cache.
         * @returns {object} An object of cache items in map form. May be empty if none is found.
         */
        function getAsMap(itemIds) {
            let cacheItems = {};
            itemIds = stringifyArray(getArray(itemIds));

            for (let itemId of itemIds) {
                let cacheItem = gCache[itemId];

                if (cacheItem != void 0) {
                    cacheItems[itemId] = cacheItem;
                }
            }

            return cacheItems;
        }

        /**
         * Get all cache entries.
         * @returns {{model}[]} An array of cache items.
         */
        function getAll() {
            return Object.values(gCache);
        }

        /**
         * Attach a listener that shall be executed, when one or many cached items from the given ids are changed.
         *
         * @param {string[]|string|number[]|number} itemIds - One or many ids that shall be watched.
         * @param {function=} cb - The callback function that shall be executed on change.
         * @param {string=} guid - The guid to an existing context
         * @returns {string} The listener guid.
         */
        function attachListener(itemIds, cb, guid) {
            let listenerGuid = guid == void 0 ? ToolService.createGUID() : guid;

            itemIds = stringifyArray(getArray(itemIds));

            if (guid == void 0) {
                gListeners[listenerGuid] = { itemIds, cb };
            } else {
                for (let id of itemIds) {
                    if (gListeners[listenerGuid].itemIds.indexOf(id) == -1) {
                        gListeners[listenerGuid].itemIds.push(id)
                    }
                }
            }

            for (let id of itemIds) {
                if (!gCache[id]) {
                    // I suggest to throw a exception here because the calling code want to
                    // attach a listener on a none existing cache item. We have a undefined
                    // state. The caller knows a items we don't know. Was it removed due
                    // bad usage of the cache? With a continue we find the bug through a support call.
                    // let's decide this while migrating to typescript. Found while DODO-10568.
                    continue;
                }
                if (gCache[id].listenerGuids == void 0) {
                    gCache[id].listenerGuids = []
                }

                if (gCache[id].listenerGuids.indexOf(listenerGuid) == -1) {
                    gCache[id].listenerGuids.push(listenerGuid)
                }
            }

            return listenerGuid;
        }

        /**
         * Reset the watched item ids of an existing listener.
         *
         * @param {string} guid - The listener guid.
         * @param {string[]|string|number[]|number} itemIds - One or many ids that shall be watched.
         * @Todo: Why are the old items not checked for removal?
         */
        function updateListener(guid, itemIds) {
            if (gListeners[guid] != void 0) {
                gListeners[guid].itemIds = [];
            }

            attachListener(itemIds, null, guid);
        }

        /**
         * Detach one or many listeners.
         *
         * @param {string[]|string} listenerGuids - One or many listener guids.
         */
        function detachListeners(listenerGuids) {
            listenerGuids = getArray(listenerGuids);

            for (let listenerGuid of listenerGuids) {
                for (let i in gCache) {
                    let item = gCache[i];

                    if (item.listenerGuids != void 0) {
                        let index = item.listenerGuids.indexOf(listenerGuid);

                        // this item has an active listener
                        if (index != -1) {
                            item.listenerGuids.splice(item.listenerGuids.indexOf(listenerGuid), 1);

                            // no more listeners
                            if (item.listenerGuids.length === 0) {
                                delete gCache[i]
                            }
                        }
                    }
                }

                delete gListeners[listenerGuid];
            }
        }

        /**
         * Compare the changed ids with the ids from the listeners and execute the listeners callbacks accordingly.
         *
         * @param {string[]|string} itemIds - One or many ids of changed cache items.
         */
        function executeListeners(itemIds) {
            itemIds = getArray(itemIds);

            for (let listenerGuid in gListeners) {
                let listener = gListeners[listenerGuid];

                if (listener.cb == void 0) {
                    continue;
                }

                let updatedIds = listener.itemIds.filter(itemId => itemIds.indexOf(itemId) >= 0);
                if (updatedIds.length > 0) {
                    listener.cb(updatedIds);
                }
            }
        }

        /**
         * Check if an object is an array and parse it to an array eventually.
         *
         * @param {object|object[]|string|number[]|number} item - An array of object or an object.
         * @returns {object[]|string[]|number[]} An array of objects.
         */
        function getArray(item) {
            return Array.isArray(item) ? item : [item];
        }

        /**
         * Convenience function to convert array of numbers into array of strings
         *
         * @param {string[]|number[]} array - The array to be stringifyed.
         * @returns {string[]} Array with string values
         */
        function stringifyArray(array) {
            return array.filter(e => {
                return e != void 0;
            }).map(e => {
                return e.toString();
            })
        }

        /**
         * remove items without listeners
         */
        function executeGC() {
            for (let i in gCache) {
                let item = gCache[i];

                // no more listeners
                if (item.listenerGuids == void 0 || item.listenerGuids.length === 0) {
                    delete gCache[i]
                }
            }
        }
    }
}
