import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {take} from "rxjs/operators";
import {from} from "rxjs";

angular.module("eob.framework").directive("eobModalLinkObject", EobModalLinkObject);

EobModalLinkObject.$inject = ["$filter", "$state", "environmentService", "backendService", "notificationsService", "clientService", "formFieldModelService", "formService", "formHelper", "messageService", "cacheManagerService"];

// eslint-disable-next-line max-params, require-jsdoc
export default function EobModalLinkObject($filter, $state, EnvironmentService, BackendService, NotificationsService, ClientService, FormFieldModelService, FormService, FormHelper, MessageService, CacheManagerService) {
    return {
        scope: {
            dmsDocument: "=item",
            isEdit: "=isedit"
        },
        async link(scope) {
            const formData = await buildFormData();

            if (scope.isEdit) {
                loadLinkText();
            }

            scope.formHelper = FormHelper.getFormHelper({ formData, submit: scope.save });
            scope.formDef = {
                isMainForm: true,
                validationMode: "max",
                formFields: scope.fields,
                formHelper: scope.formHelper,
                isMockForm: true
            };

            scope.save = save;
            scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

            /**
             * Build form data create mocked field for modal dialog
             * @returns {object} of created fields.
             */
            async function buildFormData() {
                let formFields = {
                    comment: FormFieldModelService.getMockedField("text", "comment", $filter("translate")("modal.link.object.action.link.text")),
                };
                formFields.comment.maxLength = 4000
                formFields.comment.isTextArea = true
                scope.fields = Object.values(formFields);

                return FormService.createFormData(scope.fields, "max");
            }
            /**
             * Processing input data and save changes.
             */
            function save() {
                const field = scope.formHelper.getFieldByInternal("comment")
                if(!field.api.isValid()) {
                    field.api.focus();
                    return;
                }
                if (ClientService.isOffline()) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                    return;
                }

                let url;
                let sourceObjectId;
                let sourceObjectTypeId;

                if (scope.isEdit) {
                    url = `/documents/links/update/${$state.params.osid}`;
                } else {
                    const clipboard = EnvironmentService.getClipboard();

                    sourceObjectId = clipboard.item.model.osid;
                    sourceObjectTypeId = clipboard.item.model.objectTypeId;
                    url = `/documents/links/${sourceObjectId}`;
                }

                const linkObject = {
                    relatedObjectId: scope.dmsDocument.model.osid,
                    relatedObjectTypeId: scope.dmsDocument.model.objectTypeId,
                    text: field.api.getValue()
                };

                submit(url, linkObject, sourceObjectId, sourceObjectTypeId);
            }

            /**
             * create/update link of the given dms object.
             * @param {string} url - The backend url for link object creation.
             * @param {object} linkObject - uncludes relatedObjectId, relatedObjectTypeId and free text of the given object.
             * @param {sourceObjectId} sourceObjectId - The source object Id which is linked.
             * @param {sourceObjectTypeId} sourceObjectTypeId - The source object type Id which is linked.
             **/
            async function submit(url, linkObject, sourceObjectId, sourceObjectTypeId) {
                try {
                    await BackendService.post(url, linkObject);

                    const messageKey = scope.isEdit ? "modal.link.object.update.success" : "modal.link.object.create.success";
                    let successMsg = $filter("translate")(messageKey);
                    NotificationsService.success(successMsg);
                    if (sourceObjectId != void 0) {
                        await incLinkReferenceCountForObject(sourceObjectId, sourceObjectTypeId);
                        await incLinkReferenceCountForObject(linkObject.relatedObjectId, linkObject.relatedObjectTypeId);
                    } else {
                        from(CacheManagerService.dmsDocuments.getOrFetchById(linkObject.relatedObjectId, linkObject.relatedObjectTypeId, true))
                        .pipe(take(1))
                        .subscribe(dmsDocument => {
                            if (dmsDocument.model.links != void 0) {
                                const link = dmsDocument.model.links.find(i => i.idPair.objectId === linkObject.relatedObjectId);

                                if (link != void 0) {
                                    link.comment = linkObject.text;
                                }
                            }

                            const documentIds = CacheManagerService.dmsDocuments.add(dmsDocument);
                            const listenerGuid = CacheManagerService.dmsDocuments.attachListener(documentIds);
                            CacheManagerService.dmsDocuments.updateListener(listenerGuid, documentIds);
                            CacheManagerService.dmsDocuments.detachListeners(listenerGuid);
                            MessageService.broadcast(Broadcasts.OBJECT_LINK_UPDATED, dmsDocument);
                        });
                    }
                    if(!scope.isEdit) {
                        EnvironmentService.clearClipboard();
                    }
                } catch (error) {
                    let errorMsg = $filter("translate")("modal.link.object.add.error");
                    if (error && error.data.root == "OBJECT_NOTICE_LINK_ALREADY_EXISTS") {
                        errorMsg = $filter("translate")("modal.link.object.already.exist.error");
                    }

                    NotificationsService.backendError(error, errorMsg);
                } finally {
                    scope.close()
                }
            }

            /**
             * Load link text into the dialog.
             */
            async function loadLinkText() {
                const url = `/documents/links/${$state.params.osid}`;
                BackendService.get(url)
                    .then((response) => {
                        const linkObject = response.data.find(linkObject => linkObject.relatedObjectId == scope.dmsDocument.model.osid)
                        scope.formHelper.getFieldByInternal("comment").api.setValue(linkObject ? linkObject.text : "");
                }).catch((error) => {
                    let errorMsg = $filter("translate")("modal.link.object.load.link.text.error");

                    NotificationsService.backendError(error, errorMsg);
                })
            }

            /**
             *
             * Increment the link count and inform the cache which informs all listener.
             * This update the hitlist row.
             *
             * @param objectId - The object id which had one additional link now.
             * @param objectTypeId - The object type id for the object id
             * @return {Promise<void>} This function is async but is not returning anythink.
             */
            async function incLinkReferenceCountForObject(objectId, objectTypeId) {
                const dmsDocument = await CacheManagerService.dmsDocuments.getOrFetchById(objectId, objectTypeId);
                dmsDocument.model.baseParameters.links++;

                CacheManagerService.dmsDocuments.add(dmsDocument);
                MessageService.broadcast(Broadcasts.OBJECT_LINK_UPDATED, dmsDocument);
            }
        },
        template: require("!raw-loader!./eob.modal.link-object.html")
    };
}
