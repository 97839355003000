<div id = "default-page">
    <eob-dashlet-default-page></eob-dashlet-default-page>
</div>

<ul class = "tools pdfjs-toolbar">

    <li [hidden]="!pdfjsToolbar.showSearchBar">
        <button class = "viewer-button" alt = "search in document icon" id = "viewFind"
                title = "{{'eob.pdfjs.toggle.search' | ngTranslate}}" #search>
            <eob-icon title = "{{'eob.pdfjs.toggle.search' | ngTranslate}}" name = "search" size = "24"></eob-icon>
        </button>
    </li>
    <li [hidden]="!pdfjsToolbar.showKebab">
        <button class="viewer-button"
                title="{{'nav.kebab.panel.title' | ngTranslate}}"
                id="showToolbarKebab"
                (click)="showPdfjsKebab($event)">
            <eob-icon title="{{'nav.kebab.panel.title' | ngTranslate}}" name="kebab-menu" size="24"></eob-icon>
        </button>
        <eob-pdfjs-kebab [toolbarState]="pdfjsToolbar" [controls]="controls" [showMenu]="isPdfjsKebabOpen"></eob-pdfjs-kebab>
    </li>
    <li [hidden]="!pdfjsToolbar.showRefresh">
        <button class = "viewer-button" alt = "refresh document icon" id = "refreshView"
                title = "{{'eob.pdfjs.reload' | ngTranslate}}" #refresh>
            <eob-icon title = "{{'eob.pdfjs.reload' | ngTranslate}}" name = "refresh" size = "24"></eob-icon>
        </button>
    </li>
    <li [hidden]="!pdfjsToolbar.showSideBar">
        <button class = "viewer-button" alt = "toggle sidebar icon" id = "sidebarToggle"
                title = "{{'eob.pdfjs.toggle.sidebar' | ngTranslate}}" #sidebar>
            <eob-icon title = "{{'eob.pdfjs.toggle.sidebar' | ngTranslate}}" name = "toolbar-toggle"
                      size = "24"></eob-icon>
        </button>
    </li>
    <li [hidden]="!pdfjsToolbar.showZoomOut">
        <button class = "viewer-button" alt = "zoom out icon" id = "zoomOut"
                title = "{{'eob.pdfjs.zoom.out' | ngTranslate}}" #zoomOut>
            <eob-icon title = "{{'eob.pdfjs.zoom.out' | ngTranslate}}" name = "minus" size = "24"></eob-icon>
        </button>
    </li>
    <li [hidden]="!pdfjsToolbar.showZoomIn">
        <button class = "viewer-button pdfjs-button" alt = "zoom in icon" id = "zoomIn"
                title = "{{'eob.pdfjs.zoom.in' | ngTranslate}}" #zoomIn>
            <eob-icon title = "{{'eob.pdfjs.zoom.in' | ngTranslate}}" name = "plus" size = "24"></eob-icon>
        </button>
    </li>
    <li [hidden]="!pdfjsToolbar.showRotateRight">
        <button class = "viewer-button pdfjs-button" alt = "rotate document right icon" id = "osTurnRight"
                title = "{{'eob.pdfjs.turn.right' | ngTranslate}}" #rotateRight>
            <eob-icon title = "{{'eob.pdfjs.turn.right' | ngTranslate}}" name = "rotate-document-right-24"
                      size = "24"></eob-icon>
        </button>
    </li>
    <li [hidden]="!pdfjsToolbar.showRotateLeft">
        <button class = "viewer-button pdfjs-button" alt = "rotate document left icon" id = "osTurnLeft"
                title = "{{'eob.pdfjs.turn.left' | ngTranslate}}" #rotateLeft>
            <eob-icon title = "{{'eob.pdfjs.turn.left' | ngTranslate}}" name = "rotate-document-left-24"
                      size = "24"></eob-icon>
        </button>
    </li>
    <li [hidden]="!pdfjsToolbar.showAttachments">
        <button class = "viewer-button invisible" alt = "show attachments icon" id = "show-attachments"
                title = "{{'eob.pdfjs.show.attachments' | ngTranslate}}" #attachments>
            <eob-icon title = "{{'eob.pdfjs.show.attachments' | ngTranslate}}" name = "attachment"
                      size = "24"></eob-icon>
            <span id = "attachments-badge"></span>
        </button>
    </li>
    <li [hidden]="!pdfjsToolbar.showGoToParent">
        <button class = "viewer-button invisible" alt = "go to parent icon" id = "go-to-parent"
                title = "{{'eob.pdfjs.go.to.parent' | ngTranslate}}" #goToParent>
            <eob-icon title = "{{'eob.pdfjs.go.to.parent' | ngTranslate}}" name = "parent" size = "24"></eob-icon>
        </button>
    </li>
</ul>

<div id = "dialogContainer" class="eob-custom">
    <dialog id = "passwordDialog">
        <div class = "dialog dialogContent">
            <div class = "row">
                <label id="passwordText" hidden></label>
                <span>{{'eob.pdfjs.password.description' | ngTranslate}}</span>
            </div>
            <div class = "row">
                <form autocomplete = "off" class = "password-container">
                    <div class = "form-element form-input form-label form-row required" internal = "password">
                        <span class = "form-row-label">{{'eob.pdfjs.password.label' | ngTranslate}}</span>
                        <input id = "password" class = "form-row-input" spellcheck = "false" type = "password">
                    </div>
                </form>
            </div>
            <div class = "buttonRow">
                <button id = "passwordCancel" class = "secondary-button">
                    <span>{{'modal.button.cancel' | ngTranslate}}</span></button>
                <button id = "passwordSubmit" class = ""><span>{{'modal.button.submit' | ngTranslate}}</span></button>
            </div>
        </div>
    </dialog>
</div>

<div id = "pdfjsViewerContainer">
    <div id = "findbar" [ngClass] = "{open: isSearchOpen, close: !isSearchOpen}">
        <div id = "findbarInputContainer">
            <input id = "findInput"
                   placeholder = "{{'eob.pdfjs.search.placeholder' | ngTranslate}}" tabindex = "91">
            <div class = "splitToolbarButton">
                <button id = "findPrevious"
                        title = "{{'eob.pdfjs.search.previous' | ngTranslate}}" tabindex = "92">
                    <eob-icon name = "arrow-up" size = "16"
                              title = "{{'eob.pdfjs.search.previous' | ngTranslate}}"></eob-icon>
                </button>
                <button id = "findNext" title = "{{'eob.pdfjs.search.next' | ngTranslate}}"
                        tabindex = "93">
                    <eob-icon name = "arrow-up" size = "16"
                              title = "{{'eob.pdfjs.search.next' | ngTranslate}}"></eob-icon>
                </button>
            </div>
            <span id = "findResultsCount" class = "toolbarLabel"></span>
        </div>
        <div id = "findbarOptionsOneContainer" hidden>
            <input type = "checkbox" id = "findHighlightAll" class = "toolbarField" tabindex = "94" checked>
            <!-- WEBCLIENT_CUSTOM checked -->
            <label for = "findHighlightAll" class = "toolbarLabel" data-l10n-id = "find_highlight">Highlight all</label>
            <input type = "checkbox" id = "findMatchCase" class = "toolbarField" tabindex = "95">
            <label for = "findMatchCase" class = "toolbarLabel" data-l10n-id = "find_match_case_label">Match
                                                                                                       case</label>
        </div>
        <div id = "findbarOptionsTwoContainer" hidden>
            <input type="checkbox" id="findMatchDiacritics" class="toolbarField" tabindex="96">
            <label for="findMatchDiacritics" class="toolbarLabel" data-l10n-id="find_match_diacritics_label">Match Diacritics</label>
            <input type = "checkbox" id = "findEntireWord" class = "toolbarField" tabindex = "96">
            <label for = "findEntireWord" class = "toolbarLabel" data-l10n-id = "find_entire_word_label">Whole
                                                                                                         words</label>
        </div>
        <div id = "findbarMessageContainer" hidden>
            <span id = "findMsg" class = "toolbarLabel"></span>
        </div>
    </div>

    <eob-pdfjs-container></eob-pdfjs-container>
</div>
