import {Inject, Injectable} from "@angular/core";
import {
    TodoAsIniService,
    TodoCacheManagerService,
    TodoConfiguredField,
    TodoEnvironmentService, TodoFormData, TodoQuickSearchFormData, TodoResultConfigParent, TodoStaticColumnData
} from "INTERFACES_PATH/any.types";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {FieldDataType} from "ENUMS_PATH/field.enum";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {OfflineDataStore} from "MODELS_PATH/eob.offline.data.model";
import {WfOrgPerformer} from "MODULES_PATH/modal-dialog/interfaces/substitutes-config.interface";
import {ObjectType} from "INTERFACES_PATH/object-type.interface";
import {Query, QueryContainer, QueryParam} from "INTERFACES_PATH/query.interface";
import {OsrestQuery} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-query.interface";
import {Fieldschema, ResultConfig} from "INTERFACES_PATH/validation.interface";
import {ColumnModel} from "MODULES_PATH/grid/interfaces/grid-cell-component.interface";
import {BackendSearchIdRequest} from "CORE_PATH/backend/interfaces/search-requests/backend-search-id-request";
import {CustomQuery, CustomQueryField} from "CORE_PATH/interfaces/custom-query.interface";

@Injectable({
    providedIn: "root"
})
export class QueryBuilderService {
    // eslint-disable-next-line max-params
    constructor(
        @Inject("cacheManagerService") protected cacheManagerService: TodoCacheManagerService,
        @Inject("environmentService") private environmentService: TodoEnvironmentService,
        @Inject("asIniService") private asIniService: TodoAsIniService,
        private valueUtilsService: ValueUtilsService,
        private toolService: ToolService,
        private organisationService: OrganisationService,
        private objectTypeService: ObjectTypeService) {
    }

    /** Create the base structure of a query with default properties. */
    createQuery(isResultConfig?: boolean, objectTypeId?: string): Query {
        const body: Query = {
            fields: {},
            baseparams: {}
        };

        if (isResultConfig !== false) {
            const typeDef: ObjectType = this.cacheManagerService.objectTypes.getById(objectTypeId);
            body.result_config = {
                pagesize: parseInt(this.environmentService.env.hitlist.maxsize),
                maxhits: parseInt(this.environmentService.env.hitlist.maxsize),
                deny_empty: false,
                normalize_values: false,
                fieldsschema: [{
                    dbName: "id",
                    sort_order: "DESC",
                    sort_pos: 1
                }],
                registerContext: this.asIniService.isIncludeObjectsWithoutRegisterContext() ? 1 : 0,
                rights: 1
            };
            if (typeDef?.model && typeDef.model.type === "document") {
                body.result_config.fieldsschema.push({dbName: "signstate"});
            }
        }

        return body;
    }

    /**
     * Create the backend payload for querying multiple osids per objectTypeId.
     * Create multiple queries, which only contain the given split size of ids max.
     *
     * @param ids - An array of dms objects.
     * @param limit - The id query size.
     * @returns queryList An array of maps (objectTypeId to osid).
     */
    createSplitQueryIdMap(ids: OfflineDataStore[], limit: number): BackendSearchIdRequest[] {
        let idsToLoad: BackendSearchIdRequest = this.createQueryIdMap(ids);

        // sorted from less ids to more ids
        idsToLoad = Object.fromEntries(Object.entries(idsToLoad).sort((a, b) => a[1].ids.length - b[1].ids.length));

        let lastSplitQuery: BackendSearchIdRequest = {};
        const splitQueries: BackendSearchIdRequest[] = [lastSplitQuery];

        let idsCount: number = 0;
        for (const [typeId, objectType] of Object.entries(idsToLoad)) {
            const objectTypeIds: string[] = objectType.ids;
            const overLimit: number = objectTypeIds.length % limit;

            for (let idPackAmount: number, i: number = 0; i < objectTypeIds.length; i += idPackAmount) {
                idPackAmount = Math.min(objectTypeIds.length - i, limit);

                // If possible keep the ids of an objecttype in one query.
                // If they don't fit into one query try to add the remaining ids to the former query.
                // Otherwise create a new query.
                if (i == 0 && overLimit != 0 && idsCount + overLimit < limit) {
                    idPackAmount = overLimit;
                } else if (idsCount + idPackAmount > limit) {
                    splitQueries.push(lastSplitQuery = {});
                    idsCount = 0;
                }

                lastSplitQuery[typeId] = {ids: objectTypeIds.slice(i, i + idPackAmount)};
                idsCount += idPackAmount;
            }
        }

        return splitQueries;
    }

    /**
     * Create the backend payload for querying multiple osids per objectTypeId.
     *
     * @param ids - An array of dms objects.
     * @returns idsQuery An object that maps the given ids into a objectTypeId to osid representation.
     */
    private createQueryIdMap(ids: OfflineDataStore[]): BackendSearchIdRequest {
        const idMap: BackendSearchIdRequest = {};

        for (const idPair of ids) {
            if (!idMap[idPair.objectTypeId]) {
                idMap[idPair.objectTypeId] = {ids: []};
            }
            idMap[idPair.objectTypeId].ids.push(idPair.osid);
        }

        return idMap;
    }

    /** Transform formData to an osrest backend query. */
    createFormDataQuery(data: TodoFormData): OsrestQuery {
        const queryData: OsrestQuery = {
            query: null,
            additionalQueries: []
        };

        let activePageId: string = data.activePage;

        if (activePageId == void 0) {
            activePageId = data.objectTypeIds[0];
        }

        const typeDef: ObjectType = this.cacheManagerService.objectTypes.getById(activePageId);
        const query: Query = this.createQuery(true, activePageId);

        // adding the user configured fields to the query
        // this allows us to request only the fields we can see
        let configuredFields: { [key: string]: TodoConfiguredField } = typeDef.api.getConfiguredFields();

        for (const i in configuredFields) {
            const field: TodoConfiguredField = configuredFields[i];
            query.result_config.fieldsschema.push({dbName: undefined, internalName: field.internal});
        }

        this.addCabinetFields(query, typeDef);

        query.objectTypeId = activePageId.toString();

        if (data.fulltext != void 0) {
            query.vtx_query = data.fulltext;
        }

        if (data.formDataTypes == void 0) {
            data.formDataTypes = {};
        }

        // this is a special condition because we need to support more than one fulltext field at once
        // currently used in stored queries with multiple types, but later we might need this too when we are
        // combining queries in the gui
        if (data.formDataTypes.fulltext != void 0 && data.formDataTypes.fulltext[query.objectTypeId] != void 0) {
            query.vtx_query = data.formDataTypes.fulltext[query.objectTypeId];
        }

        if (data.id != void 0) {
            query.osid = data.id;
        }

        const activePageFormData: { [objectTypeId: string]: { [internal: string]: TodoQuickSearchFormData } } = data.formDataTypes[activePageId];

        this.addFormDataToQuery(activePageFormData, query, typeDef, data);

        queryData.query = query;

        for (const typeId in data.formDataTypes) {
            if (Number(typeId) < 0 || isNaN(Number(typeId))) {
                continue;
            }

            const additionalFormData: { [objectTypeId: string]: { [internal: string]: TodoQuickSearchFormData } } = data.formDataTypes[typeId];

            if (typeId != activePageId) {
                const additionalQuery: Query = this.createQuery(true, typeId);
                const additionalTypeDef: ObjectType = this.cacheManagerService.objectTypes.getById(typeId);

                additionalQuery.objectTypeId = typeId;

                if (data.formDataTypes.fulltext && data.formDataTypes.fulltext[typeId] != void 0) {
                    additionalQuery.vtx_query = data.formDataTypes.fulltext[typeId];
                }

                configuredFields = additionalTypeDef.api.getConfiguredFields();

                for (const i in configuredFields) {
                    const f: TodoConfiguredField = configuredFields[i];
                    additionalQuery.result_config.fieldsschema.push({dbName: undefined, internalName: f.internal});
                }

                this.addCabinetFields(additionalQuery, additionalTypeDef);
                this.addFormDataToQuery(additionalFormData, additionalQuery, additionalTypeDef, data);
                queryData.additionalQueries.push(additionalQuery);
            }
        }

        return queryData;
    }

    createScriptQuery(queryData: OsrestQuery, useAsIni: boolean): OsrestQuery {
        // if script query with use of AS.INI, extend query config with additional information
        const mainQuery: Query = queryData.query;
        this.setObjectTypeId(mainQuery);

        if (mainQuery.objectTypeId == void 0) {
            console.warn("Insufficient type information in script query to determine object type id.");
            return;
        }

        // extend main query config
        this.extendResultListQuery(mainQuery, useAsIni);

        if (queryData.additionalQueries != void 0) {
            const mainObjectType: string = this.objectTypeService.getObjectType(mainQuery.objectTypeId);

            for (const additionalQuery of queryData.additionalQueries) {
                this.setObjectTypeId(additionalQuery);

                // extend additional query config, if type matches
                if (additionalQuery.objectTypeId != void 0) {
                    const additionalObjectType: string = this.objectTypeService.getObjectType(additionalQuery.objectTypeId);
                    if (additionalObjectType == mainObjectType) {
                        this.extendResultListQuery(additionalQuery, useAsIni);
                    }
                }
            }
        }

        return queryData;
    }

    // region deprecated since 8.50 - custom frontend query
    /** @deprecated Create query from custom frontend query structure. */
    createQueryWithData(data: CustomQuery): QueryContainer {
        const query: QueryContainer = {
            query: this.createQuery(true)
        };
        query.query.objectTypeId = data.objectTypeId.toString();

        this.addRequestFields(query, data.requestFields);
        this.setSearchProperties(query, data);

        for (const responseField of data.responseFields) {
            query.query.result_config.fieldsschema.push({dbName: undefined, internalName: responseField});
        }

        return query;
    }

    private addRequestFields(query: QueryContainer, fields: CustomQueryField[] = []): void {
        for (const field of fields) {
            if (field.type == "grid") {
                query.query.fields[field.internal] = {
                    type: "GRID",
                    internalName: field.internal,
                    columns: field.columns,
                    rows: field.rows
                };
            } else {
                query.query.fields[field.internal] = {
                    internalName: field.internal,
                    value: field.value
                };
            }
        }
    }

    private setSearchProperties(query: QueryContainer, data: CustomQuery): void {
        const maxHits: number = data.maxHits == void 0 || isNaN(data.maxHits) ? query.query.result_config.maxhits : Number(data.maxHits);
        const resultConfig: ResultConfig = query.query.result_config;

        resultConfig.pagesize = maxHits;
        resultConfig.maxhits = maxHits;
        resultConfig.registerContext = this.asIniService.isIncludeObjectsWithoutRegisterContext() ? 1 : 0;
        resultConfig.rights = 1;

        if (data.distinct == true) {
            resultConfig.deny_empty = false;
            resultConfig.distinct = true;
            resultConfig.fieldsschema = [];
        }
    }

    // endregion

    private addCabinetFields(query: Query, typeDef: ObjectType): void {
        // only applicable for documents and registers (not folders)
        if (typeDef.model.config == void 0 || typeDef.model.config.mainType == 0) {
            return;
        }

        const cabinetTypeDef: ObjectType = this.cacheManagerService.objectTypes.getById(typeDef.model.config.cabinetId);
        const resultConfigParent: TodoResultConfigParent = this.createResultConfigParent(typeDef, cabinetTypeDef);
        if (resultConfigParent.fieldsschema.length > 0) {
            query.result_config.parents = [];
            query.result_config.parents.push(resultConfigParent);
        }
    }

    private createResultConfigParent(objectTypeDef: ObjectType, cabinetTypeDef: ObjectType): TodoResultConfigParent {
        const fieldsSchema: Fieldschema[] = [];
        let cabinetId: string;
        if (objectTypeDef != void 0 && cabinetTypeDef != void 0) {
            cabinetId = cabinetTypeDef.model.config.cabinetId;
            const configuredBaseParams: { [key: string]: TodoConfiguredField } = objectTypeDef.api.getConfiguredBaseParams(cabinetTypeDef, objectTypeDef.api.getType());

            if (configuredBaseParams != void 0) {
                // TODO so far, only configured folder fields are considered, no "normal" base params
                for (const key in configuredBaseParams) {
                    const baseParam: TodoConfiguredField = configuredBaseParams[key];
                    // look for folder fields (no base param name)
                    if (baseParam.baseParamName == void 0) {
                        fieldsSchema.push({dbName: undefined, internalName: baseParam.internal});
                    }
                }
            }
        }

        return {
            objectTypeId: cabinetId,
            fieldsschema: fieldsSchema
        };
    }

    private addFormDataToQuery(formData: { [objectTypeId: string]: { [internal: string]: TodoQuickSearchFormData } }, query: Query, typeDef: ObjectType, data: TodoFormData): void {
        const NULL_VALUE_SEARCH_REGEX: RegExp = /(?:<>|!=|[]{0})#NULL#/i;

        for (const i in formData) {
            const fieldModel: FieldModel = new FieldModel(formData[i].model, this.environmentService.featureSet);
            let value: string = formData[i].value;
            const rangeEnd: string = formData[i].to;

            if (fieldModel.isNull) {
                if (fieldModel.isBaseParam) {
                    query.baseparams.internal = fieldModel.getQueryValue(value);
                } else {
                    query.fields[fieldModel.internal] = fieldModel.getQueryValue(value);
                }

                continue;
            }

            if (fieldModel.type === FormFieldType.RADIO && isNaN(Number(value))) {
                continue;
            } else if (fieldModel.type === FormFieldType.CHECKBOX && (isNaN(Number(value)) || Number(value) > 1)) {
                continue;
            }

            const hasValue: boolean = (value !== "" && value !== void 0) || (rangeEnd !== "" && rangeEnd !== void 0);

            // check if the current field is part of the objecttype definition and not a custom field
            // we or someone else added to the form
            if (!typeDef.api.hasField(fieldModel.internal) && !fieldModel.isBaseParam) {
                continue;
            }

            // special case ..
            // in this case the user wants to search for a wf user, but the server only accepts
            // wf user guids, so we have to replace the name by the guid
            if (fieldModel.addon === "organisation" && value !== "") {
                const wfOrgPerformer: WfOrgPerformer[] = this.organisationService.getWfOrgPerformer();

                for (const member of wfOrgPerformer) {
                    if (member.name === value) {
                        value = member.id;
                    }
                }
            }

            if (hasValue) {
                switch (fieldModel.type) {
                    case FieldDataType.LETTER:
                    case FieldDataType.TEXT:
                    case FieldDataType.ALPHANUM:
                    case FieldDataType.CAPITAL:
                        if (data?.context === "search" &&
                            fieldModel.name !== "baseparamOwner" &&
                            !["list", "tree", "rightGroups", "organisation"].includes(fieldModel.addon) &&
                            NULL_VALUE_SEARCH_REGEX.test(value) == false) {
                            value = this.setAutoStar(value, this.environmentService.getSessionInfo().autostar);
                        }
                        break;
                    case FieldDataType.DATE:
                    case FieldDataType.DATETIME:
                    case FieldDataType.TIME:
                        value = this.valueUtilsService.dateToRangeString(formData[i], true);

                        // if the datetime value is a baseparam we need to check if we need to add missing parts
                        // because the rich client has a "from" and a "to" field, and adds the "to" part if its empty ...
                        if (fieldModel.isBaseParam) {
                            value = this.toolService.getRangeByExpression(value, fieldModel.type);
                        }
                        break;
                    case FieldDataType.DECIMAL:
                    case FieldDataType.NUMBER:
                        value = this.valueUtilsService.numberToRangeString(formData[i]);
                        break;
                    case FormFieldType.RADIO:
                    case FormFieldType.CHECKBOX:
                        value = value.toString();
                        break;
                }

                if (fieldModel.isBaseParam) {
                    this.addBaseParamToQuery(query, fieldModel, value);
                } else {
                    query.fields[fieldModel.internal] = fieldModel.getQueryValue(value);
                }
            } else if (fieldModel.type === FormFieldType.GRID) {
                const field: FieldModel = typeDef.api.getFieldByName(fieldModel.name);
                const columns: ColumnModel[] = field.columns;
                let gridData: string[][] = formData[i].gridData;

                if (gridData === void 0) {
                    gridData = [];
                }

                const rows: string[] = gridData.length == 0 ? [] : gridData[0];
                const colData: TodoStaticColumnData = [];
                const rowData: string[] = [];

                for (const [j, column] of columns.entries()) {
                    const val: string = rows[j];

                    if (val !== "" && val !== void 0) {
                        colData.push({
                            displayName: column.idTitle,
                            type: column.type
                        });

                        if (data.context && data.context === "search" && column.type === FieldDataType.TEXT) {
                            rowData.push(this.setAutoStar(val, this.environmentService.getSessionInfo().autostar));
                        } else if (column.type === FieldDataType.DATE) {
                            rowData.push(this.valueUtilsService.convertToTimestamp(val, false, false));
                        } else {
                            rowData.push(val);
                        }
                    }
                }

                if (rowData.length) {
                    query.fields[fieldModel.name] = fieldModel.getGridQueryValue({
                        columns: colData,
                        rows: [rowData],
                        type: undefined,
                        internalName: undefined
                    });
                }
            }
        }
    }

    private addBaseParamToQuery(query: Query, fieldModel: FieldModel, value: string): void {
        const internal: string = fieldModel.internal.substring("baseParam".length, fieldModel.internal.length);
        if (internal.includes("ArchiveState")) {
            this.updateArchiveStateQueryField(query.baseparams, internal, value);
        } else if (internal.includes("Locked")) {
            this.updateLockedQueryField(query.baseparams, internal, value);
        } else if (internal.includes("HasVariants")) {
            // only add this flag, when its really true. The AppConnector searches for variants when the
            // key is defined, not when its true ... the value could be "Space-Cat" and the AppConnector
            // would still think its true
            if (value == "1") {
                query.baseparams[internal] = {value: "true", internalName: ""};
            }
        } else if (internal.includes("MultiLocation")) {
            if (value == "1") {
                query.baseparams[internal] = {value: "true", internalName: ""};
            }
        } else if (internal.includes("Owner")) {
            query.baseparams[internal] = {value: [], internalName: ""};
            query.baseparams[internal].value = value.split(",");
        } else if (internal.includes("InRegister")) {
            this.updateInRegisterQueryField(query.baseparams, internal, value);
        } else if (internal.includes("Signed")) {
            this.updateIsSignedQueryField(query.baseparams, internal, value);
        } else {
            query.baseparams[internal] = fieldModel.getQueryValue(value);
        }
    }

    private updateArchiveStateQueryField(baseparams: { [key: string]: QueryParam }, fieldName: string, value: string): void {
        if (value == "1") {
            const archiveStates: string[] = this.getArchiveStateValues(baseparams);
            const archiveStateValue: string = this.getArchiveStateValue(fieldName);

            if (!archiveStates.includes(archiveStateValue)) {
                archiveStates.push(archiveStateValue);
            }
        }
    }

    private updateInRegisterQueryField(baseparams: { [key: string]: QueryParam }, fieldName: string, value: string) {
        if (value != "1") {
            return;
        }

        if (baseparams.Register != void 0) {
            delete baseparams.Register;
            return;
        }

        const registerParam: QueryParam = baseparams.Register = {value: null, internalName: null};
        registerParam.value = !fieldName.includes("NotInRegister") ? "true" : "false";
    }

    private getArchiveStateValues(baseparams: { [key: string]: QueryParam }): string[] {
        let result: string[];
        let archiveState: QueryParam = baseparams.ArchiveState;

        if (!archiveState) {
            archiveState = baseparams.ArchiveState = {value: null, internalName: null};
            result = archiveState.value = [];
        } else {
            result = archiveState.value as string[];
        }

        return result;
    }

    private getArchiveStateValue(fieldName: string): string {
        if (fieldName.includes("Archived")) {
            return "ARCHIVED";
        } else if (fieldName.includes("NotArchivable")) {
            return "NOT_ARCHIVABLE";
        } else if (fieldName.includes("Archivable")) {
            return "ARCHIVABLE";
        } else if (fieldName.includes("NoPages")) {
            return "NO_PAGES";
        } else if (fieldName.includes("Reference")) {
            return "REFERENCE";
        }
    }

    private updateLockedQueryField(baseparams: { [key: string]: QueryParam }, fieldName: string, value: string): void {
        if (value == "1") {

            if (baseparams.Locked != void 0) {
                baseparams.Locked.value = ["*"];
                return;
            }

            const locked: QueryParam = baseparams.Locked = { value: [] };
            const lockedBy: string | string[] = locked.value = [];

            lockedBy.push(this.environmentService.getSessionInfo().username);

            if (fieldName.includes("ByOther")) {
                locked.negate = true;
            }
        }
    }

    /**
     * sets the autostar and checks if the user already set it (avoid doubled stars ... *sigh*),
     * Autostar configuration: 0 - None 1 - Left 2 - Right 3 - Both
     */
    private setAutoStar(val: string, config: string): string {
        if (val === "") {
            return "";
        }

        let value: string = val;

        switch (config) {
            case "0":
                break;
            case "1":
                if (value.charAt(0) != "*") {
                    value = `*${value}`;
                }
                break;
            case "2":
                if (value.charAt(value.length - 1) != "*") {
                    value = `${value}*`;
                }
                break;
            case "3":
                if (value.charAt(0) != "*") {
                    value = `*${value}`;
                }
                if (value.charAt(value.length - 1) != "*") {
                    value = `${value}*`;
                }
                break;
        }

        return value;
    }

    // region enrich script query
    private setObjectTypeId(query: Query): void {
        if (query == void 0) {
            return;
        }

        // try to determine object type id from query config (the same way osrest and enaio-beans do)
        let objectTypeId: string;
        if (query.objectTypeId != void 0) {
            objectTypeId = query.objectTypeId;
        } else if (query.dbName != void 0) {
            objectTypeId = this.cacheManagerService.objectTypes.getBy("config.tableName", query.dbName).model.osid;
        } else if (query.cabinet != void 0 && query.name != void 0) {
            objectTypeId = this.objectTypeService.guessObjectTypeIdByCabinetAndName(query.cabinet, query.name);
        } else if (query.name != void 0) {
            objectTypeId = this.cacheManagerService.objectTypes.getBy("config.internal", query.name)?.model.osid;
            if (objectTypeId == void 0) {
                objectTypeId = this.cacheManagerService.objectTypes.getBy("config.name", query.name)?.model.osid;
            }
        }

        if (query.objectTypeId == void 0 && objectTypeId != void 0) {
            query.objectTypeId = objectTypeId.toString();
        }
    }

    extendResultListQuery(query: Query, useAsIni: boolean): void {
        if (query.objectTypeId == void 0) {
            return;
        }

        const typeDef: ObjectType = this.cacheManagerService.objectTypes.getById(query.objectTypeId);
        if (typeDef == void 0) {
            console.warn(`No type definition found for object type id ${query.objectTypeId}`);
            return;
        }

        // extend configured fields and base params in query config
        this.addConfiguredFields(query, typeDef, useAsIni);
        this.addConfiguredCabinetFields(query, typeDef, useAsIni);
    }

    private setFieldInternalName(field: Fieldschema, typeDef: ObjectType): void {
        // check field for internal name
        if (field.internalName != void 0) {
            return;
        }

        // look for type field using db and display name
        let typeField: FieldModel;
        if (field.dbName != void 0) {
            typeField = typeDef.api.getFieldByDbName(field.dbName);
        } else if (field.displayName != void 0) {
            typeField = typeDef.api.getFieldByName(field.displayName);
        }

        if (typeField != void 0) {
            field.internalName = typeField.internal;
        }
    }

    private addConfiguredFields(query: Query, typeDef: ObjectType, useAsIni: boolean): void {
        // combine hit list fields from AS.INI with query fields
        const fieldsschema: Fieldschema[] = [];
        const internalNames: { [key: string]: boolean } = {};

        if (useAsIni) {
            // get internal names of configured fields from AS.INI
            const configuredFields: TodoConfiguredField = typeDef.api.getConfiguredFields();
            if (configuredFields != void 0) {
                for (const key in configuredFields) {
                    const field: FieldModel = configuredFields[key];
                    fieldsschema.push({internalName: field.internal, dbName: undefined});
                    internalNames[field.internal] = true;
                }
            }
        }

        // check fields in query fieldsschema
        if (query.result_config && query.result_config.fieldsschema != void 0) {
            const resultFields: Fieldschema[] = query.result_config.fieldsschema;
            for (const resultField of resultFields) {
                // check field for internal name
                this.setFieldInternalName(resultField, typeDef);

                // if internal name is set and field is not already in configured fields, add it
                if (resultField.internalName != void 0) {
                    if (!internalNames[resultField.internalName]) {
                        fieldsschema.push(resultField);
                        internalNames[resultField.internalName] = true;
                    }
                } else {
                    // no further information, add it anyway
                    fieldsschema.push(resultField);
                }
            }
        }

        if (fieldsschema.length > 0) {
            if (query.result_config == void 0) {
                query.result_config = {};
            }

            query.result_config.fieldsschema = fieldsschema;
        }
    }

    private addConfiguredCabinetFields(query: Query, typeDef: ObjectType, useAsIni: boolean): void {
        // only applicable for documents and registers (not folders)
        if (typeDef.model.config == void 0 || typeDef.model.config.mainType == 0) {
            return;
        }

        const cabinetId: string = typeDef.model.config.cabinetId;
        const cabinetTypeDef: ObjectType = this.cacheManagerService.objectTypes.getById(cabinetId);

        // get configured folder fields as configured parent object
        let configuredParent: TodoResultConfigParent;
        if (useAsIni) {
            // only used, when AS.INI is used
            configuredParent = this.createResultConfigParent(typeDef, cabinetTypeDef);
        }

        // look for parents in given query
        const additionalParents: TodoResultConfigParent[] = [];
        if (query.result_config && query.result_config.parents != void 0) {
            const parents: TodoResultConfigParent[] = query.result_config.parents;
            for (const parent of parents) {
                // check objectTypeId in query parent
                if (parent.objectTypeId == void 0) {
                    parent.objectTypeId = this.cacheManagerService.objectTypes.getBy("config.internal", parent.internalName).model.osid;
                }

                // check db names in parent fieldsschema
                this.checkParentFieldsschema(parent.fieldsschema, cabinetTypeDef);

                // if AS.INI is used and cabinet id found in query parent, merge query fields into configured parent
                if (useAsIni && parent.objectTypeId == cabinetId) {
                    this.mergeFieldsschemasByInternalName(parent.fieldsschema, configuredParent.fieldsschema);
                } else {
                    // other id or no id found, just add query parent
                    additionalParents.push(parent);
                }
            }

            delete query.result_config.parents;
        }

        const resultConfigParents: TodoResultConfigParent[] = [];

        // if AS.INI is used, first add configured parent, if present and not empty ...
        if (useAsIni && configuredParent.fieldsschema.length > 0) {
            resultConfigParents.push(configuredParent);
        }

        // ... then use additional parents, if present
        for (const additionalParent of additionalParents) {
            resultConfigParents.push(additionalParent);
        }

        if (resultConfigParents.length > 0) {
            if (query.result_config == void 0) {
                query.result_config = {};
            }

            query.result_config.parents = resultConfigParents;
        }
    }

    private checkParentFieldsschema(fieldsschema: Fieldschema[], typeDef: ObjectType): void {
        if (fieldsschema == void 0 || fieldsschema.length < 1) {
            return;
        }

        // check internal names in all fields
        for (const field of fieldsschema) {
            this.setFieldInternalName(field, typeDef);
        }
    }

    private mergeFieldsschemasByInternalName(sourceSchema: Fieldschema[], destinationSchema: Fieldschema[]): void {
        // merge source fields into destination schema by internal field names

        if (sourceSchema == void 0 || sourceSchema.length < 1) {
            return;
        }

        // collect internal names from destination schema
        const internalNames: { [key: string]: boolean } = {};
        for (const destinationField of destinationSchema) {
            if (destinationField.internalName != void 0) {
                internalNames[destinationField.internalName] = true;
            }
        }

        // merge source fields into destination fields by internal name
        for (const sourceField of sourceSchema) {
            // if internal name is set and field is not already in destination fields, add it
            if (sourceField.internalName != void 0 && !internalNames[sourceField.internalName]) {
                destinationSchema.push(sourceField);
            }
        }
    }

    private updateIsSignedQueryField(baseparams: { [key: string]: QueryParam }, fieldName: string, value: string): void {
        if (value != "1") {
            return;
        }

        if (baseparams.Signed == void 0) {
            baseparams.Signed = {value: [], internalName: ""};
        }

        if (fieldName.includes("SignedCurrent")) {
            (baseparams.Signed.value as string[]).push("CURRENT");
        } else {
            (baseparams.Signed.value as string[]).push("PREVIOUS");
        }
    }
}