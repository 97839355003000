import {NgModule, Type} from "@angular/core";
import {NavigationComponent} from "MODULES_PATH/navigation/navigation.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "SHARED_PATH/shared.module";

import {StoreModule} from "@ngrx/store";
import * as fromNavigation from "./+state/navigation.reducer";

import {
    EobNavDesktopDirective,
    EobNavExternalTrayDirective,
    EobNavQuicksearchDirective
} from "MODULES_PATH/navigation/directives";
import {EobNavKebabComponent} from "./components/eob-nav-kebab/eob-nav-kebab.component";
import {EobNavBarComponent} from "./components/eob-nav-bar/eob-nav-bar.component";
import {EobNavContentComponent} from "./components/eob-nav-content/eob-nav-content.component";
import {EobNavContentHeaderComponent} from "./components/eob-nav-content-header/eob-nav-content-header.component";
import {EobPanelComponent} from "./components/eob-panel/eob-panel.component";
import {EobQuicksearchDirective} from "MODULES_PATH/navigation/directives/eob-quicksearch.directive";
import {EobNavUserAvatarComponent} from "./components/eob-nav-user-avatar/eob-nav-user-avatar.component";
import {EobNavObjectSearchComponent} from "./components/eob-nav-object-search/eob-nav-object-search.component";
import {EobNavInboxComponent} from "./components/eob-nav-inbox/eob-nav-inbox.component";
import { EobNavExternalTrayComponent } from './components/eob-nav-external-tray/eob-nav-external-tray.component';
import {A11yModule} from "MODULES_PATH/a11y/a11y.module";

/**
 * These directives should be removed after we've migrated them in the AngularX, as well as 'directives' directory
 * unless we have a new directive that is not part of the migration process
 */
const directives: Array<Type<unknown>> = [
    EobNavQuicksearchDirective,
    EobNavDesktopDirective,
    EobNavExternalTrayDirective,
    EobQuicksearchDirective
];

const components: Array<Type<unknown>> = [
    NavigationComponent,
    EobNavBarComponent,
    EobNavContentComponent,
    EobNavContentHeaderComponent,
    EobNavKebabComponent,
    EobNavUserAvatarComponent,
    EobPanelComponent,
    EobNavObjectSearchComponent,
    EobNavInboxComponent
];

const modules: Array<Type<unknown>> = [
    CommonModule,
    SharedModule
];

@NgModule({
    declarations: [...components, ...directives, EobNavExternalTrayComponent],
    imports: [...modules, StoreModule.forFeature(fromNavigation.navigationFeatureKey, fromNavigation.navigationReducer), A11yModule],
    exports: [...components],
    entryComponents: [NavigationComponent, EobPanelComponent]
})
export class NavigationModule {
}
