import {Component} from "@angular/core";
import {ITooltipAngularComp} from "ag-grid-angular";
import {ITooltipParams} from "ag-grid-community";

@Component({
    selector: "eob-grid-header-tooltip",
    templateUrl: "./grid-header-tooltip.component.html",
    styleUrls: ["./grid-header-tooltip.component.scss"],
})
export class GridHeaderTooltipComponent implements ITooltipAngularComp {
    params: ITooltipParams;
    isHeader: boolean;

    agInit(params: ITooltipParams): void {
        this.params = params;
        this.isHeader = params.rowIndex === undefined;
    }
}