import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";

(function () {


    require("SERVICES_PATH/eob.desktop.srv.js");
    require("COMPONENTS_PATH/eob-touch-event-binder/eob.touch.event.binder.dir.js");

    angular.module("eob.framework").directive("eobDesktopFolder", EobDesktopFolder);

    EobDesktopFolder.$inject = ["$compile", "$rootScope", "desktopService", "clientService", "messageService", "environmentService"];

    function EobDesktopFolder($compile, $rootScope, DesktopService, ClientService, MessageService, EnvironmentService) {
        return {
            restrict: "E",
            scope: {
                item: "="
            },
            link(scope, element, attrs) {
            },
            compile(element) {
                return recursionHelper().compile(element, (scope) => {

                    let touchHappened = false;

                    scope.expandSubDesktop = function (item, event) {
                        if (!touchHappened) {
                            item.expanded = !item.expanded;
                            item.icon = DesktopService.getDesktopFolderIcon(item);
                            DesktopService.updateFolderState(item);
                        } else {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    };

                    scope.executeAction = function (item, event) {
                        if (!touchHappened) {
                            DesktopService.executeAction(item);
                        } else {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }

                    function preventGhostClick(event) {
                        angular.element(event.target).on("touchend click", (event) => {
                            if (!touchHappened) {
                                touchHappened = true;
                                setTimeout(() => {
                                    touchHappened = false
                                }, 300);
                            }
                        })
                    }

                    scope.showMenu = function (item, event) {

                        // preventing ghost click events on ios
                        if (ClientService.isiOs()) {
                            preventGhostClick(event);
                        }

                        MessageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                            items: [item],
                            event: event.originalEvent,
                            contextData: {
                                context: "desktop",
                                title: item.name
                            }
                        });

                        element.find(".selected").removeClass("selected")
                        DesktopService.markSelected(event);
                    };
                });

                function recursionHelper() {
                    return {
                        /**
                         * Manually compiles the element, fixing the recursion loop.
                         * @param element
                         * @param [link] A post-link function, or an object with function(s) registered via pre and post properties.
                         * @returns An object containing the linking functions.
                         */
                        compile(element, link) {
                            // Normalize the link parameter
                            if (angular.isFunction(link)) {
                                link = {post: link};
                            }

                            // Break the recursion loop by removing the contents
                            let contents = element.contents().remove();
                            let compiledContents;
                            return {
                                pre: (link && link.pre) ? link.pre : null,
                                /**
                                 * Compiles and re-adds the contents
                                 */
                                post(scope, element) {
                                    // Compile the contents
                                    if (!compiledContents) {
                                        compiledContents = $compile(contents);
                                    }
                                    // Re-add the compiled contents to the element
                                    compiledContents(scope, (clone) => {
                                        element.append(clone);
                                    });

                                    // Call the post-linking function, if any
                                    if (link && link.post) {
                                        link.post.apply(null, arguments);
                                    }
                                }
                            };
                        }
                    }
                }
            },

            template: require("!raw-loader!./eob.desktop.folder.html")
        };
    }
})();
