import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Type} from "@angular/core";
import {EobStateFilterComponent} from "MODULES_PATH/state-basics/components/eob-state-filter/eob-state-filter.component";
import {EobStateFulltextFilterComponent} from "MODULES_PATH/state-basics/components/eob-state-fulltext-filter/eob-state-fulltext-filter.component";
import {EobStateHeaderComponent} from "MODULES_PATH/state-basics/components/eob-state-header/eob-state-header.component";
import {EobStateRefresherComponent} from "MODULES_PATH/state-basics/components/eob-state-refresher/eob-state-refresher.component";
import {EobStateSyncButtonComponent} from "MODULES_PATH/state-basics/components/eob-state-sync-button/eob-state-sync-button.component";
import {StateTouchRefreshComponent} from "MODULES_PATH/state-basics/components/state-touch-refresh/state-touch-refresh.component";
import {EobKebabMenuComponent} from "MODULES_PATH/state-basics/components/state-kebab-menu/state-kebab-menu.component";
import {SharedModule} from "SHARED_PATH/shared.module";
import {FormsModule} from "@angular/forms";
import {AutocompleteModule} from "MODULES_PATH/autocomplete/autocomplete.module";

const components: Array<Type<unknown>> = [
    EobStateFilterComponent,
    EobStateFulltextFilterComponent,
    EobStateHeaderComponent,
    EobStateRefresherComponent,
    EobStateSyncButtonComponent,
    StateTouchRefreshComponent,
    EobKebabMenuComponent
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule, SharedModule, FormsModule, AutocompleteModule
    ],
    exports: [...components],
    entryComponents: [...components]
})
export class StateBasicsModule {
}
