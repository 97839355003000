import {Injectable, Inject} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {NotificationsService} from "../notification/notifications.service";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import { ViewerEvent } from "MODULES_PATH/dashlet/enums/viewer-event.enum";

const {saveAs}: any = require("file-saver");

/**
 * Client service implementation to be used inside a browser context
 */
@Injectable({
    providedIn: "root"
})
export class ClientBrowserService extends ClientService {
    gStorage: CustomStorage;

    constructor(@Inject("$filter") protected $filter: ng.IFilterService, @Inject("$injector") protected $injector: ng.auto.IInjectorService, @Inject("$eobConfig") protected $eobConfig: any,
                @Inject("$rootScope") protected $rootScope: RootScope, messageService: MessageService, protected errorModelService: ErrorModelService, @Inject("mimeTypes") protected mimeTypes: any,
                protected authenticationService: AuthenticationService, protected customStorageService: CustomStorageService) {
        super($filter, $injector, $eobConfig, $rootScope, errorModelService, messageService, authenticationService, customStorageService);
        customStorageService.getStorage()?.subscribe(storage => {
            this.gStorage = storage;
        });
    }

    /** @inheritdoc */
    // eslint-disable-next-line @typescript-eslint/require-await
    async checkForCheckedOutFilesAsync(): Promise<boolean> {
        return false;
    }

    /** @inheritdoc */
    // eslint-disable-next-line @typescript-eslint/require-await
    async platformLogoutAsync(): Promise<void> {
        this.messageService.broadcast(ViewerEvent.DESTROY_DETACHED_VIEWER);

        sessionStorage.clear();

        const notificationsService: NotificationsService = this.$injector.get("notificationsService");
        notificationsService.goodbye(true);

        (location as any).hash = "";
        setTimeout(() => {
            const url: URL = new URL(location.href);
            // Hopefully forces all browsers to ignore the cache
            window.location.href = `${url.origin}/_secure/logout/`;
        }, 1500);
    }

    /** @inheritdoc */
    // eslint-disable-next-line @typescript-eslint/require-await
    async getStorageAsync(): Promise<CustomStorage> {
        return this.gStorage;
    }

    /** @inheritdoc */
    // eslint-disable-next-line @typescript-eslint/require-await
    async platformOpenFileDataInDefaultAppAsync(dmsDocument: DmsDocument, fileName: string,
                                                fileContent: ArrayBuffer, save?: boolean): Promise<string | Error | void> {
        const file: Blob = new Blob([fileContent]);
        saveAs(file, fileName);

        return fileName;
    }

    /** @inheritdoc */
    broadcastTrayItemsChanged(): void {
    }

    /** @inheritdoc */
    setIsSynchronizing(isSynchronizing: boolean): void {
    }

    /** @inheritDoc */
    getIsSynchronizing(): Promise<boolean> {
        return new Promise(resolve => {
            resolve(false);
        });
    }

    /** @inheritdoc */
    setIsCacheLocked(isCacheLocked: boolean): void {
    }

    /** @inheritDoc */
    getIsCacheLocked(): Promise<boolean> {
        return Promise.resolve(false);
    }

    /** @inheritDoc */
    setColorTheme(theme: string): void {
    }

    /** @inheritdoc */
    // eslint-disable-next-line @typescript-eslint/require-await
    async platformSaveAsAsync(fileName: string, fileContent: ArrayBuffer): Promise<string> {
        const fileExt: string = fileName.split(".").pop();
        const mimeType: string = this.mimeTypes[fileExt];
        let file: Blob;

        if (mimeType) {
            file = new Blob([fileContent], {type: mimeType});
        } else {
            file = new Blob([fileContent]);
        }

        saveAs(file, fileName);
        return fileName;
    }

    /** @inheritdoc */
    // eslint-disable-next-line @typescript-eslint/require-await
    async readDataFromFileAsync(filePath: string, isText: boolean): Promise<string | ArrayBuffer> {
        return "";
    }

    /** @inheritDoc */
    // eslint-disable-next-line @typescript-eslint/require-await
    async correctFilePathAsync(filePath: string): Promise<string> {
        return filePath;
    }

    /** @inheritdoc */
    refreshTabTitle(tabTitle: string): void {
    }

    /** @inheritdoc */
    async writeDataIntoTempFileAsync(filename: string, fileContent: ArrayBuffer): Promise<any> {
    }

    /** @inheritdoc */
    clearCookies(): void {
    }

    /** @inheritdoc */
    // eslint-disable-next-line @typescript-eslint/require-await
    async execProgramAsync(pathToProgram: string, programArguments: string, returnResult: boolean, workingDirectory?: string): Promise<string> {
        return "";
    }

    /** @inheritdoc */
    getMDMConfig(): undefined {
        return undefined;
    }
}
