import {FeatureSet} from "MODELS_PATH/eob.feature.set.model";
import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";
import {CorrectionFieldMask, FieldMaskValue} from "MODULES_PATH/form/models/field-mask.model";

export class MedicChoiceMask extends CorrectionFieldMask {
    constructor(private subType: string, private featureSet: FeatureSet) {
        super();
    }

    formatBlur(maskValue: FieldMaskValue): FieldMaskValue {
        const rendererValue: FormInputValue = maskValue.value ? this.parseValue(maskValue.value) : "";

        return (rendererValue === "" && maskValue.value) ? maskValue : {
            value: rendererValue.toString().charAt(0),
            rendererValue
        };
    }

    protected parseValue(value: FormInputValue): FormInputValue {
        value = value.toString().trim().toLowerCase();
        if (value === "") {
            return value;
        }

        let replacement: string;
        // Note that the strings in the arrays are NOT localized.
        // That is: no matter what language has been chosen, valid values are German, only.
        // Same goes for Rich-Client.
        switch (this.subType) {
            case "P":
                replacement = this.expandFromArray(value, ["Ambulant", "Fremdpatient", "Station"]);
                break;
            case "Q":
                replacement = this.expandFromArray(value, ["ja", "nein"]);
                break;
            case "S":
                const validValues: string[] = ["m\u00E4nnlich", "weiblich"];
                if (this.featureSet.contains("objectdefinition.form.field.type.divers")) {
                    validValues.push("divers");
                }

                replacement = this.expandFromArray(value, validValues);
                break;
            case "T":
                replacement = this.expandFromArray(value, ["links", "rechts"]);
                break;
            default:
                replacement = "";
        }

        return replacement;
    }

    /**
     * If the specified str matches the beginning of any element in the array validValues,
     * the matching array element is returned.
     *
     * The matching operation is case insensitive.
     *
     * @param str a trimmed lower case string
     * @param validValues array with valid values
     *
     * @return string element of validValues or empty string
     */
    private expandFromArray(str: string, validValues: string[]): string {
        return validValues.find(validValue => validValue.toLowerCase().indexOf(str) === 0) || "";
    }
}