import * as angular from "angular";
import * as JSZip from "jszip";
import {Subject, of, Observable, from} from "rxjs";
import {filter, map, switchMap, mergeAll, reduce, takeUntil} from "rxjs/operators";
import {Inject, Injectable} from "@angular/core";
import {StateService} from "@uirouter/core/lib/state/stateService";
import {UIRouter} from "@uirouter/core";
import {FileUpdate} from "MODULES_PATH/dms/interfaces/file-update.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ContentObject} from "SHARED_PATH/models/eob.content.object";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {DmsModule} from "MODULES_PATH/dms/dms.module";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {FileObject} from "INTERFACES_PATH/file-object.interface";
import {RenditionInfoPageDetailsData, ProcessedRenditionInfoData} from "INTERFACES_PATH/rendition-info-data.interfaces";
import {AttachmentObject} from "INTERFACES_PATH/attachement-object.interface";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {HttpEventType, HttpParams, HttpResponse} from "@angular/common/http";
import {OsrestDocumentfilesResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-documentfiles-result.interface";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {TodoAsIniService, TodoEnvironmentService} from "INTERFACES_PATH/any.types";

/**
 * Service offering functions to export and add content to existing DMS documents
 */
@Injectable({ providedIn: DmsModule })
export class DmsContentService {

    private readonly translateFn: TranslateFnType;
    private readonly $state: StateService;
    private readonly allowedTemplateFillingFileExtensions: string[] = ["doc", "dot", "docx", "docm", "xla" , "xlw", "xlc", "xlm", "xls" , "xlt" , "xlsx", "xlsm" , "ppt", "pps", "pot", "pptx", "pptm"];
    private availableRenditionExtensions: string[] = ["bmp","csv","dib","doc","docm","docx","dot","dotx","dwg","dxf","emf","eml","epdf","epi","eps","epsf","tif","tiff",
    "epsi","exr","gif","ico","jpg","jpeg","mim","mpp","mpt","msg","odg","odp","ods","odt","pbm","pcx","pdf","png","pnm","pot","potm","potx","pps","ppsm","ppsx",
    "ppt","pptm","pptx","ps","ps2","ps3","psd","ptif","rtf","svg","svgz","sxc","sxi","sxw","wbmp","wmf","wps","xbm","xcf","xls","xlsb","xlsm","xlsx","xltm","xltx","xmind","xml"];


    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
    constructor(uiRouter: UIRouter, @Inject("$filter") $filter: angular.IFilterService, @Inject("$eobConfig") private $eobConfig: any,
                private notificationsService: NotificationsService, @Inject("modalDialogService") private modalDialogService: any,
                private httpService: HttpService, @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("asIniService") private asIniService: TodoAsIniService, @Inject("cacheManagerService") private cacheManagerService: any,
                private clientService: ClientService, @Inject("fileCacheService") private fileCacheService: FileCacheService,
                @Inject("$injector") private $injector: any, private viewerService: ViewerService,
                @Inject("offlineCacheService") private offlineCacheService: OfflineCacheService, private messageService: MessageService) { /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
        this.translateFn = $filter("translate");
        this.$state = uiRouter.stateService;

        this.messageService.subscribeFirst(Broadcasts.ENVIRONMENT_INITIALIZED, (env: any) =>
            this.availableRenditionExtensions = [...this.availableRenditionExtensions, ...(env.offline?.rendition?.split(",").map(extension => extension.toLowerCase()) || [])]);
    }

    /**
     * Print the passed document(s). Uses the PDF rendition from the backend and allows the user to pick a printer.
     *
     * @param {Array<DmsDocument> | DmsDocument} dmsDocuments one or multiple dms document items.
     * @returns {Promise<void>}
     */
    async printContentAsync(dmsDocuments: DmsDocument[] | DmsDocument): Promise<void> {
        interface DmsDocumentContent {
            filename: string;
            buffer: ArrayBuffer;
        }

        type FetchPdfFunc = (dmsDocument: DmsDocument) => Promise<DmsDocumentContent>;
        const canceler$$: Subject<void> = new Subject<void>();
        const fetchPdf: FetchPdfFunc = async (dmsDocument: DmsDocument): Promise<DmsDocumentContent> => {
            if (this.clientService.isOnline()) {
                const filename = `${dmsDocument.api.buildNameFromIndexData(5, true, true)}.pdf`;
                const response: HttpResponse<ArrayBuffer> = await this.httpService.retrieveDocumentFiles([{id: dmsDocument.model.osid}], "pdf", filename).toPromise();
                const buffer: ArrayBuffer = response.body; // Here we fetch pdf. The file extension is known.
                return {buffer, filename};

            } else {
                const container: ContentObject | void = await this.offlineCacheService.getPreviewContentById(dmsDocument.model.osid);

                if (!container || container.files.length != 1) {
                    throw new Error(`Rendition for id ${dmsDocument.model.osid} could not be retrieved.`);
                }

                return {
                    buffer: new Uint8Array(container.files[0].content),
                    filename: container.files[0].name
                };
            }
        };

        this.modalDialogService.showProgressDialog("eob.status.loading.content", canceler$$);
        const results: DmsDocumentContent[] = [];
        dmsDocuments = Array.isArray(dmsDocuments) ? dmsDocuments : [dmsDocuments];
        for (const doc of dmsDocuments) {
            try {
                results.push(await fetchPdf(doc));
            } catch (error) {
                console.warn(error);
                if (error.type && error.type !== "WEB_HTTP_REQUEST_CANCELED") {
                    this.notificationsService.backendError(error, "eob.object.load.error");
                } else {
                    this.notificationsService.info(this.translateFn("eob.sync.rendition.for.id.not.available").replace(/%s/i, doc.model.osid));
                }
            }
        }

        if (this.clientService.isDesktop()) {
            if (results.length == 0) {
                this.notificationsService.info(this.translateFn("eob.action.print.no.documents"));
                this.modalDialogService.hideProgressDialog(false);
                return;
            }
            try {
                if(results.length > 1) {
                    const printer: string = await this.modalDialogService.selectPrinterDialog();
                    if (printer) {
                        window.electron.printPdf(printer, results.map((x: DmsDocumentContent) => x.buffer));
                    } else {
                        this.notificationsService.info(this.translateFn("eob.action.print.no.printers"));
                    }
                } else {
                    window.electron.printPdf("", results.map((x: DmsDocumentContent) => x.buffer));
                }
            } catch (ignored) {
                // No printer selected
            }
        } else if (results.length > 1) {
                const zip: JSZip = new JSZip();
                for (const file of results) {
                    zip.file(ToolService.nameToFilename(file.filename), file.buffer);
                }
                this.customSaveAsAsync(await zip.generateAsync({type: "blob"}), "export.zip");
            } else if (results.length === 1) {
                this.customSaveAsAsync(results[0].buffer, results[0].filename);
            }

        this.modalDialogService.hideProgressDialog(false);
    }

    /**
     * Exports the original content of the passed document(s) and offers it as a download to the user
     *
     * @param {Array<DmsDocument> | DmsDocument} dmsDocuments One or more documents
     * @param suppressDownload
     * @returns {Promise<void>}
     */
    async exportContentAsync(dmsDocuments: DmsDocument[] | DmsDocument, suppressDownload?: boolean): Promise<FileObject> {
        const postData: any = {
            archivename: "archiv.zip",
            ids: [] as any[],
            type: ""
        };

        if (!Array.isArray(dmsDocuments)) {
            dmsDocuments = [dmsDocuments];
        }

        this.buildExportFiles(dmsDocuments, postData);

        if (this.clientService.isOffline()) {
            for (const dmsDocument of dmsDocuments) {
                const container: ContentObject = await this.offlineCacheService.getOriginalContentById(dmsDocument.model.osid);

                if (!container) {
                    this.notificationsService.info(this.translateFn("eob.sync.content.not.available"));
                    continue;
                }
                if(!suppressDownload) {
                    this.customSaveAsAsync(container.files[0].content, container.files[0].name);
                }
            }
            return;
        } else {
            let fileName: string = postData.ids[0].name;
            if (dmsDocuments.length === 1 && dmsDocuments[0].model.baseParameters.objectCount == 1 && dmsDocuments[0].model.hasContent) {
                const dmsDocument: DmsDocument = dmsDocuments[0];
                const docModel: DmsDocumentModel = dmsDocument.model;
                try {
                    const response: HttpResponse<ArrayBuffer> = await this.httpService.retrieveDocumentFiles([{id: docModel.osid}], "1", null).toPromise();
                    const extension: string = this.extractExtensionFromContentDisposition(response);
                    if (!suppressDownload) {
                        await this.customSaveAsAsync(response.body, `${fileName}.${extension}`);
                    }
                    return {
                        content: response.body,
                        name: `${fileName}.${extension}`
                    };

                } catch (error) {
                    this.notificationsService.backendError(error, "eob.object.load.error");
                }
            } else {
                this.modalDialogService.showProgressDialog("eob.status.loading.content");

                try {
                    const response: ArrayBuffer = (await this.httpService.retrieveDocumentFiles(postData.ids, "zip", fileName).toPromise()).body;
                    fileName += ".zip";
                    if (!suppressDownload) {
                        await this.customSaveAsAsync(response, fileName);
                    }
                    return {
                        content: response,
                        name: fileName
                    };

                } catch (error) {
                    this.notificationsService.backendError(error, "eob.action.export.failed");
                } finally {
                    this.modalDialogService.hideProgressDialog();
                }
            }
        }
    }

    private extractExtensionFromContentDisposition(response: HttpResponse<ArrayBuffer>): string {
        const contentDispositionParts: string[] = response.headers.get("Content-Disposition").split(/\./);
        const fileExtension: string = contentDispositionParts[contentDispositionParts.length - 1];

        // osrest/api/documentfiles/osid/1 is called whenever we access i.e. export, show etc. email with content
        if (response.url) {
            const osid: string = response.url.split(/\//)[6];
            const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(osid);
            const emailMainType: boolean = dmsDocument.model.mainType == "6" || dmsDocument.model.subType == "6";
            return emailMainType && fileExtension === "ima" ? "eml" : fileExtension;
        }

        return fileExtension;
    }

    /**
     * Exports the PDF rendition of the passed document(s)  and offers it as a download to the user.
     *
     * @param {Array<DmsDocument> | DmsDocument} dmsDocuments One or more documents
     * @returns {Promise<void>}
     */
    async exportPdfAsync(dmsDocuments: DmsDocument[] | DmsDocument): Promise<void> {
        if (!Array.isArray(dmsDocuments)) {
            dmsDocuments = [dmsDocuments];
        }

        if (this.clientService.isOffline()) {
            for (const dmsDocument of dmsDocuments) {
                const container: ContentObject | void = await this.offlineCacheService.getPreviewContentById(dmsDocument.model.osid);

                if (!container) {
                    this.notificationsService.info(this.translateFn("eob.sync.rendition.not.available"));
                    continue;
                }

                if (container.files.length === 1) {
                    const file: FileObject = container.files[0];
                    this.customSaveAsAsync(file.content, ToolService.nameToFilename(file.name));
                }
            }

            return;
        } else {
            let filename: string;
            if(dmsDocuments.length == 1) {
                filename = `${dmsDocuments[0].api.buildNameFromIndexData(5, true, true)}.pdf`;
            } else {
                filename = "export.pdf";
            }
            this.modalDialogService.showProgressDialog("eob.status.loading.content");

            try {
                const response: ArrayBuffer = (await this.httpService.retrieveDocumentFiles(dmsDocuments.map(x => ({id: x.model.osid})), "pdf", filename).toPromise()).body;
                await this.customSaveAsAsync(response, filename);
            } catch (error) {
                this.notificationsService.backendError(error, "eob.action.export.failed");
            } finally {
                this.modalDialogService.hideProgressDialog();
            }
        }
    }

    /**
     * Add content to a dms document.
     *
     * @param {object} dropzoneContent - Content data.
     * @param {string|number} osid - An object id.
     * @throws {error} - A backend error.
     */
    addContentToDocumentAsync = async (dropzoneContent: any, osid: string | number): Promise<void> => {
        if (dropzoneContent.mode == "template" && dropzoneContent.template.id != -1) {
            if (dropzoneContent.templateConfiguration != void 0) {
                this.asIniService.updateTemplateConfiguration(dropzoneContent.item.objectTypeId, dropzoneContent.templateConfiguration);
            }

            if (!dropzoneContent.template) {
                return;
            }

            this.assignTemplate(dropzoneContent, osid);
        } else {
            let metadata: any = {};
            if (dropzoneContent.mode == "trayItem") {
                metadata = dropzoneContent.files;
                const files: string[] = [];

                for (const fileName of metadata.fileNames) {
                    const file: any = await this.fileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, fileName, {
                        group: metadata.groupKey,
                        first: true
                    });

                    if (file == void 0) {
                        continue;
                    }
                    const res: string = await this.httpService.uploadFile(fileName, new Blob([file], {type: "application/octet-stream"}))
                        .pipe(filter(x => x.type == HttpEventType.Response), map((x: HttpResponse<string>) => x.body)).toPromise();
                    files.push(res);
                }

                dropzoneContent.files = files;
            }

            if (dropzoneContent.files.length == "0" || dropzoneContent.files.id == "-1") {
                return;
            }

            const typeConfig: any = this.cacheManagerService.objectTypes.getById(dropzoneContent.item.objectTypeId).model.config;

            if (this.environmentService.isMicroserviceBackend()) {
                const params: FileUpdate = {
                    archiveID: typeConfig.cabinetId,
                    objectTypeID: dropzoneContent.item.objectTypeId,
                    objectID: osid.toString(),
                    files: dropzoneContent.files,
                    isTemplate: dropzoneContent.isTemplate
                };

                await this.httpService.legacyPost("/fileUpdate.do", params, {}, this.$eobConfig.getOswebBase());
            } else {
                const params: HttpParams = new HttpParams({fromObject: {
                    archiveID: typeConfig.cabinetId,
                    objectTypeID: dropzoneContent.item.objectTypeId,
                    objectID: osid.toString(),
                    files: dropzoneContent.files,
                    isTemplate: dropzoneContent.isTemplate
                }});

                await this.httpService.legacyPost("/fileUpdate.do", params, {}, this.$eobConfig.getOswebBase());
            }

            const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(osid);

            if (dmsDocument != void 0) {
                dmsDocument.model.baseParameters.objectCount = dropzoneContent.files.length;

                // the document suddenly has content ... seems to be "not archivable" now :D
                if (dmsDocument.model.hasContent == false) {
                    dmsDocument.model.hasContent = true;
                    dmsDocument.model.baseParameters.archiveState = "NOT_ARCHIVABLE";
                }

                this.cacheManagerService.dmsDocuments.executeListeners(dmsDocument.model.osid);

                await this.offlineCacheService.resetSynchronizationState(dmsDocument.model.osid);
            }

            if (/hitlist\.(favorites|offlineObjects)/i.test(this.$state.current.name || "")) {
                this.$state.reload();
            }
        }
    };

    /**
     * Request the template content and add it to the dmsDocument with the given id.
     *
     * @param {object} dropzoneContent - Content data.
     * @param {string | number} osid  An object id.
     * @returns {Promise<any>}
     */
    private async addTemplateToDocumentAsync(dropzoneContent: any, osid: string | number, isTemplateFilling: boolean): Promise<any> {

        try {
            if (isTemplateFilling) {
                this.notificationsService.info(this.translateFn("eob.form.create.document.with.template"));
            }

            await this.httpService.assignTemplateToObject(osid.toString(), dropzoneContent.item.objectTypeId, dropzoneContent.template.id, isTemplateFilling).toPromise();

            // the content has been added and can't be removed now
            this.environmentService.clearDropzoneContent();

            if (isTemplateFilling) {
                this.notificationsService.success(this.translateFn("eob.action.create.template.success"));

                this.viewerService.refreshContent(parseInt(osid as string));
            }

            const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(osid);
            if (dmsDocument) {
                // set the objectcount to 1 because documents with templates can only have one document
                dmsDocument.model.baseParameters.objectCount = 1;

                // the document suddenly has content ... seems to be "not archivable" now :D
                if (dmsDocument.model.hasContent == false) {
                    dmsDocument.model.hasContent = true;
                    dmsDocument.model.baseParameters.archiveState = "NOT_ARCHIVABLE";
                }

                this.cacheManagerService.dmsDocuments.executeListeners(dmsDocument.model.osid);
            }
        } catch (error) {
            this.notificationsService.backendError(error, "eob.action.create.template.error");
        }
    }

    /**
     * Shows a saveAs Dialog or delegates it to the mobile os to hand over the content to another app.
     *
     * @param {ArrayBuffer} buffer - Downloaded binary data from osrest.
     * @param {string} filename - the filename with extension in which the content should be saved.
     */
    customSaveAsAsync = async (buffer: ArrayBuffer|Blob, filename: string): Promise<any> => {
        if (!filename || filename.length <= 4) {
            filename = `${filename ? filename.replace(/^/, "export") : "export"}`;
        }

        try {
            await this.clientService.saveAsAsync(filename, buffer);
        } catch (error) {
            this.notificationsService.customError(error);
        }
    };

    /**
     * Generates appropriate export file names for each passed document
     *
     * @param {Array<DmsDocument>} dmsDocuments Documents to process
     * @param files Reference to be enriched with document metadata
     */
    private buildExportFiles(dmsDocuments: DmsDocument[], files: any): void {
        for (const dmsDocument of dmsDocuments) {
            files.ids.push({
                id: dmsDocument.model.id,
                name: dmsDocument.api.buildNameFromIndexData(5, true, true)
            });
        }
    }

    /**
     * Get the original content for the given osid.
     *
     * @param osid - The osid of the dms object with the desired content.
     * @param filename - This filename will be given to the created file.
     * @param retrieveZipped - whether documents containing multiple files should be fetched as a zip archive, rather than individual files
     * @returns The requested file wrapped in a ContentObject..
     */
    getOriginalContent(osid: string, filename: string, retrieveZipped: boolean = true): Observable<ContentObject> {
        const contentObject: ContentObject = new ContentObject();

        return this.httpService.queryDocumentFiles(osid).pipe(switchMap(x => {
            if(x.files) {
                if(x.files.length > 1 && retrieveZipped) {
                    return this.httpService.retrieveDocumentFiles([{id: osid}], "zip", filename).pipe(
                        map(y => {
                            contentObject.files.push({
                                name: `${filename}.zip`,
                                content: y.body,
                                extension: "zip"
                            });
                            return contentObject;
                        })
                    );
                }
                const obs: Array<Observable<HttpResponse<ArrayBuffer>>> = [];
                for(const [i, file] of x.files.entries()) {
                    obs.push(this.httpService.retrieveDocumentFiles([{id: osid}], (i + 1).toString(10), filename));
                }

                return from(obs).pipe(
                    mergeAll(2),
                    reduce((acc, value) => {
                        acc.push(value);
                        return acc;
                    }, []),
                    map(y => {
                        for (const [i, response] of y.entries()) {
                            const extension: string = this.extractExtensionFromContentDisposition(response);
                            contentObject.files.push({
                                name: `${filename}${i}.${x.files[i].split(".").pop()}`,
                                content: response.body,
                                extension
                            });
                        }
                        return contentObject;
                    })
                );
            }
            return of(contentObject);
        }));
    }

    /**
     * Get the pdf rendition for the given osid.
     *
     * @param osid - The osid of the dms object with the desired content.
     * @param filename - This filename will be given to the created file.
     * @param requestConfig - Additional properties for backend requests.
     * @returns The requested file wrapped in a ContentObject.
     */
    async getPDFRendition(osid: string, filename: string, requestConfig?: any): Promise<ContentObject> {
        // rendition information is not reliable for PDF renditions, so check the status
        // with a HEAD call
        let status = -1;
        try {
            status = await this.getPdfRenditionStatus(osid);
        } catch (err) {
            status = err;
        }

        if (status === 422) {
            throw new Error(`PDF rendition corrupt for ${osid}.`);
        }

        if (status === 404) {
            // not in cache; to avoid old content hashes, send delete call prior to fetching new one
            try {
                await this.httpService.deleteOsRenditionCache(+osid).toPromise();
            } catch (_) {
            }
        }

        let fetchCall: Observable<ArrayBuffer> = this.httpService.retrieveRendition(osid, "pdf");
        if (requestConfig.timeout) {
            fetchCall = fetchCall.pipe(takeUntil(from(requestConfig.timeout)));
        }
        const content: ArrayBuffer = await fetchCall.toPromise();

        // only for additional information in content object
        let info: ProcessedRenditionInfoData;
        try {
            info = await this.getRenditionInfoAsync(osid, requestConfig);
        } catch (error) {
            // info call will also throw an error, if info.status is "failed" or "processing"
            // in case of real call failure, a subject is returned as error
            info = typeof error.status == "string" ? error : {} as ProcessedRenditionInfoData;
        }

        // PDF rendition call was successful, ignore rendition info status
        info = info || {} as ProcessedRenditionInfoData;
        info.status = "successful";

        const contentObject: ContentObject = new ContentObject();
        contentObject.files.push({
            name: `${filename}.pdf`,
            content
        });
        contentObject.info = info;

        return contentObject;
    }

    /**
     * Retrieves attachments along with their preview thumbnails.
     *
     * @param renditionInfo - The info of a pdf rendition.
     * @param osid - The osid of the dms object with the desired content.
     * @param requestConfig - Additional properties for backend requests.
     */
    async getAttachmentsAsync(renditionInfo: ProcessedRenditionInfoData, osid: string, requestConfig?: any): Promise<AttachmentObject[]> {
        const attachments: AttachmentObject[] = [];

        if (!renditionInfo || !renditionInfo.page_details) {
            return attachments;
        }

        for (const attachmentKey in renditionInfo.page_details) {
            const attachmentInfo: RenditionInfoPageDetailsData = renditionInfo.page_details[attachmentKey];

            if (!attachmentInfo.foreign_id) {
                continue; // This is no attachment. Maybe an image inside the main mail.
            }

            let attachment: AttachmentObject;
            try {
                const renditionAttachmentInfo: ProcessedRenditionInfoData = await this.getRenditionInfoAsync(attachmentInfo.foreign_id, requestConfig);

                if (renditionAttachmentInfo === undefined) {
                    throw new Error(`Missing rendition info for the attachment ${attachmentInfo.foreign_id} of the object ${osid}.`);
                }

                renditionAttachmentInfo.id = attachmentInfo.foreign_id;

                // PDF of the attachment
                let fetchCallPdf: Observable<ArrayBuffer> = this.httpService.retrieveRendition(attachmentInfo.foreign_id, "pdf");
                if(requestConfig.timeout) {
                    fetchCallPdf = fetchCallPdf.pipe(takeUntil(from(requestConfig.timeout)));
                }
                const attachementPdf: ArrayBuffer = await fetchCallPdf.toPromise();

                // Thumbnail of the attachment
                let fetchCallThumbnail: Observable<ArrayBuffer> = this.httpService.retrieveRendition(attachmentInfo.foreign_id, "thumbnail");
                if(requestConfig.timeout) {
                    fetchCallThumbnail = fetchCallThumbnail.pipe(takeUntil(from(requestConfig.timeout)));
                }
                const thumbnail: ArrayBuffer = await fetchCallThumbnail.toPromise();

                attachment = {
                    info: renditionAttachmentInfo,
                    previewImage: thumbnail,
                    content: attachementPdf
                };
            } catch (error) {
                console.warn(`Synchronization of attachment ${attachmentInfo.foreign_id} of object ${osid} failed: `, error);
            }

            // add an empty dummy, if the attachement couldn't be loaded
            attachment = attachment || { info: null, previewImage: null, content: null };
            attachments.push(attachment);
        }

        return attachments;
    }

    /**
     * Get status of PDF rendition:
     * 200: ok
     * 404: not in cache
     * 422: corrupt
     *
     * @param osid - The osid of the dms object with the desired content
     * @private
     */
    private getPdfRenditionStatus(osid: string): Promise<number> {
        return this.httpService.retrievePdfRenditionStatus(osid).toPromise();
    }

    /**
     * Give the rendition up to one minute to create the preview.
     *
     * @param osid - The osid of the dms object with the desired content.
     * @param requestConfig - Additional properties for backend requests.
     */
    private async getRenditionInfoAsync(osid: string, requestConfig?: any): Promise<ProcessedRenditionInfoData> {
        let call: Observable<ProcessedRenditionInfoData> = this.httpService.retrieveRenditionInformation(osid);
        if(requestConfig.timeout) {
            call = call.pipe(takeUntil(from(requestConfig.timeout)));
        }
        return call.toPromise();
    }

    /** Check whether a rendition can be produced for the given extension due to the rendition configuration of the webclient backend. */
    checkAvailableRenditionExtension(extension: string): boolean {
        return (this.availableRenditionExtensions == undefined
            || this.availableRenditionExtensions.includes("*")
            || this.availableRenditionExtensions.includes(extension.toLowerCase()));
    }

    /**
     * Show email content in the respective platform using the native email client.
     */
    showEmailContent = async (dmsDocument: DmsDocument): Promise<void> => {
        if(this.clientService.isOffline()) {
            const container: ContentObject | void = await this.offlineCacheService.getOriginalContentById(dmsDocument.model.osid);
            if (container?.files?.length != 1) {
                this.notificationsService.info(this.translateFn("eob.sync.content.not.available"));
            } else {
                this.clientService.openFileDataInDefaultAppAsync(dmsDocument, container.files[0].name, container.files[0].content, false);
            }
            return;
        }

        this.modalDialogService.showProgressDialog("eob.status.loading.content");
        try {
            const documentFilesInfo: OsrestDocumentfilesResult = await this.httpService.queryDocumentFiles(dmsDocument.model.osid).toPromise();
            if(documentFilesInfo?.files?.length == 1) {
                const response: HttpResponse<ArrayBuffer> = await this.httpService.retrieveDocumentFiles([{id: dmsDocument.model.osid}], "1", "filename").toPromise();
                const filename = `${dmsDocument.api.buildNameFromIndexData(5, true, true)}.${this.extractExtensionFromContentDisposition(response)}`;
                this.clientService.openFileDataInDefaultAppAsync(dmsDocument, filename, response.body, false);
            }
        } catch(e) {
            console.warn(e);
            this.notificationsService.error(this.translateFn("eob.result.document.load.error"));
        } finally {
            this.modalDialogService.hideProgressDialog();

        }
    };

    // since template filling can take some time, we execute it asynchronously
    async assignTemplate(dropzoneContent, osid) {
        const fileExtenstion: string = (dropzoneContent.template.extension || "").toLowerCase();
        const isTemplateFilling: boolean = this.environmentService.env.import.useTemplateFilling && this.allowedTemplateFillingFileExtensions.includes(fileExtenstion);

        if (isTemplateFilling) {
            this.addTemplateToDocumentAsync(dropzoneContent, osid, isTemplateFilling);
        } else {
            await this.addTemplateToDocumentAsync(dropzoneContent, osid, isTemplateFilling);
        }
    }
}
