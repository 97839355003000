/**
 * Profile sensitiv data for each user.
 *
 * @readonly
 * @enum {string}
 */
export enum ProfileCacheKey {
    /**
     * The AS.ini from the user
     */
    AS_INI = "AsIni",

    /**
     * The Roles the user has
     */
    USER_ROLES = "UserRoles",

    /**
     * Session Information
     */
    SESSION_INFO = "SessionInfo",

    /**
     * Workflows the user can start
     */
    STARTABLE_WORKFLOWS = "StartableWorkflows",

    /**
     * Templates the user can choose for content
     */
    TEMPLATES = "Templates",

    /**
     * Store the users unsubmitted chenges with this key
     * Editing Indexdata or creating content should be autosaved in case
     * the user experiences a power outtage or closes the browser on accident
     */
    AUTOSAVED_DATA = "autosavedData",

    /**
     * Group keys for offline cache data.
     */
    OFFLINE_CONTENT_CACHE_GROUP = "content",

    /**
     * Specifies all the DMS scripts in a map with the
     * object type ID as key and all the scripts as
     * values.
     * Only scripts for client type "web" and only
     * object types found in the favorites cache along
     * with the global object type scripts are
     * considered.
     */
    DMS_OBJECT_TYPE_SCRIPT_CACHE = "dmsObjectTypeScriptCache",

    OFFLINE_SCRIPT_CACHE_GROUP = "script",

    /**
     * The detailsviewer configuration.
     */
    DV_CONFIGURATION = "dvConfiguration"
}