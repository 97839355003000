<div class="container">
    <div class="flex">
        <eob-hit-list class="modal-grid" *ngIf="ready" [hitlistconfig]="hitlistConfig" [contextdata]="contextData"></eob-hit-list>
        <div id="loadAnimation" class="osProgressbar invisible">
            <div class="osloader"></div>
        </div>

        <div class="button-container">
            <button (click)="close()" class="secondary-button">{{'modal.button.back' | ngTranslate}}</button>
            <button [ngClass]="{loading : isLoading}" (click)="applyEntry()">{{'modal.button.submit' | ngTranslate}}</button>
        </div>
    </div>
</div>
