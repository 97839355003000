import {Injectable, Inject} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import * as loglevel from "loglevel";
import Dexie from "dexie";
import {Logger, LogLevelDesc} from "loglevel";
import * as JSZip from "jszip";
import {SessionInfo} from "INTERFACES_PATH/session-info.interface";
import {ServiceInfo} from "INTERFACES_PATH/service-info.interfaces";

interface CustomWindow extends Window {
    console: Console;
    _console: Console;
}

interface LogEntry {
    id?: number;
    timestamp: Date;
    tab: string;
    session: string;
    logger: string;
    locationHash: string;
    level: string;
    message: unknown;
}

class LogDb extends Dexie {
    log: Dexie.Table<LogEntry, number>;

    constructor() {
        super("LogDb");
        this.version(1).stores({log: "++id,timestamp,tab,session,logger,locationHash,level,message"});
    }
}

declare let window: CustomWindow;

@Injectable({
    providedIn: "root"
})
export class LoggingService {

    randomSessionId: string;
    randomTabId: string;
    // How many log entries should be kept in the database
    private readonly LOG_ENTRIES_TO_KEEP = 20000;

    // How many log entries to delete if LOG_ENTRIES_TO_KEEP is exceeded
    private readonly LOG_ENTRIES_TO_DELETE = 2000;

    private readonly translateFn: TranslateFnType;
    private db: LogDb = new LogDb();
    private consoleLogger: Logger;
    private dbLogger: Logger;
    private activeLogger: Logger;

    constructor(private clientService: ClientService,
                @Inject("$injector") private $injector: ng.auto.IInjectorService,
                @Inject("$filter") $filter: ng.IFilterService) {
        this.consoleLogger = loglevel.getLogger("console");

        this.translateFn = $filter("translate");
        const temp = clientService.getRandomIds();
        this.randomSessionId = temp.randomSessionId;
        this.randomTabId = temp.randomTabId;

        // Expose some console logging functions to be used in special cases
        // @ts-ignore
        window._console = {
            info: window.console.info.bind(window),
            warn: window.console.warn.bind(window)
        };

        this.consoleLogger.setLevel(0);
        this.activeLogger = this.consoleLogger;
        this._bindLoggerFunctions();
        void this.initDbLogger();
    }

  private _bindLoggerFunctions(): void {
    this.info = this.activeLogger.info.bind(this);
    this.debug = this.activeLogger.debug.bind(this);
    this.warn = this.activeLogger.warn.bind(this);
    this.error = this.activeLogger.error.bind(this);
    this.trace = this.activeLogger.trace.bind(this);
  }

  wipeDb(): Promise<void> {
        if(!this.db.isOpen()) {
            return Promise.reject("db isn't opened");
        }
        return this.db.log.clear();
  }

  async initDbLogger(forceDbLogger?: boolean): Promise<void> {
        // process is not a window object, it is injected by webpack
         if (!forceDbLogger && (/development|test/gi.test(process.env.NODE_ENV) || localStorage.getItem("forceConsoleLogging") || location.href.includes("debug"))) {
             return;
         }
        try {
            this.dbLogger = loglevel.getLogger("db");
            this.dbLogger.setLevel(0);
            if (!this.db.isOpen()) {
                await this.db.open();
            }
            const shrinkDb = async () => {
                const ids = await this.db.log.orderBy(":id").keys();

                if (ids.length < this.LOG_ENTRIES_TO_DELETE) {
                    window._console.info(`Fewer than ${this.LOG_ENTRIES_TO_DELETE} entries, clearing log table. Quota exceeded.`);
                    await this.db.log.clear();
                } else {
                    const firstIdToPreserve = ids[ids.length - (this.LOG_ENTRIES_TO_KEEP - this.LOG_ENTRIES_TO_DELETE)];
                    await this.db.log.where("id").below(firstIdToPreserve).delete();
                    window._console.info("Cleaned up log table. Max log entry count reached.");
                }
            };

            const rejectionListener = error => {
                // Handle errors with no reason (heh)
                switch ((error.reason || {}).name || error) {
                    // errnames.QuotaExceeded === "QuotaExceededError"
                    case Dexie.errnames.QuotaExceeded:
                        void shrinkDb();
                        break;
                    case Dexie.errnames.DataClone:
                        // Ignore cloning errors, as they are handled individually
                        break;
                    default:
                        window._console.warn(`error: ${error.reason}`);
                        break;
                }
            };
            window.addEventListener("unhandledrejection", rejectionListener);

            window.addEventListener("unload", () => {
                this.db.close();
            });

            this.dbLogger.methodFactory = (methodName, logLevel, loggerName) => async (...msg: any[]) => {
                if (!this.db.isOpen()) {
                    await this.db.open();
                }
                const logMessageObj = {
                    logger: String(loggerName),
                    timestamp: new Date(),
                    level: methodName,
                    tab: this.randomTabId,
                    session: this.randomSessionId,
                    locationHash: location.hash,
                    message: msg as unknown as string
                };
                try {
                    this.consoleLogger[methodName](msg);
                    await this.db.log.add(logMessageObj);
                } catch (error) {
                    if (error.name == "DataCloneError") {
                        try {
                            logMessageObj.message = JSON.stringify(msg);
                            await this.db.log.add(logMessageObj);
                        } catch (_) {
                            window._console.warn("Unable to serialize the given log message");
                        }
                    } else {
                        window._console.warn("Unable to store the log message: ", error);
                    }
                }
            };
            this.dbLogger.setLevel(this.dbLogger.getLevel());

            for (const k of ["debug", "log", "info", "warn", "error"]) {
                window.console[k] = this.dbLogger[k];
            }
            this.activeLogger = this.dbLogger;
            this._bindLoggerFunctions();
        } catch (error) {
            this.consoleLogger.info(`Failed to init indexedDB logging: ${error}`);
            this.activeLogger = this.consoleLogger;
        }
    }

    debug(...msg: any[]): void {
        this.activeLogger.debug(...msg);
    }

    info(...msg: any[]): void {
        this.activeLogger.info(...msg);
    }

    warn(...msg: any[]): void {
        this.activeLogger.warn(...msg);
    }

    error(...msg: any[]): void {
        this.activeLogger.error(...msg);
    }

    trace(...msg: any[]): void {
        this.activeLogger.trace(...msg);
    }

    setLogLevel(level: LogLevelDesc): void {
        this.activeLogger.setLevel(level);
    }

    async getEntriesAsText(sessionId?: string, tabId?: string): Promise<string> {
        try {
            if (!this.db.isOpen()) {
                await this.db.open();
            }

            let table = this.db.log.toCollection();

            if (sessionId) {
                table = table.filter(i => i.session == sessionId);
            }

            if (tabId) {
                table = table.filter(i => i.tab == tabId);
            }

            const entries = await table.toArray();
            let result = "";

            if (entries.length < 1) {
                return result;
            }

            for (const entry of entries) {
                result += `${entry.timestamp.toJSON()} [${entry.logger}] *${entry.session}-${entry.tab}* $${entry.locationHash}$ ${entry.level.toUpperCase()} ${entry.message}\r\n`;
            }

            return result;
        } catch (e) {
            return "";
        }
    }

    async getLogEntriesAsZipFile(sessionId?: string, tabId?: string): Promise<void> {
        const environmentService: TodoEnvironmentService = this.$injector.get("environmentService");
        const sessionInfo: SessionInfo = environmentService.getSessionInfo();
        const serviceInfo: ServiceInfo = environmentService.getServiceInfo();
        const userName = sessionInfo.fullname ? `${sessionInfo.fullname} (${sessionInfo.username})` : sessionInfo.username;

        let loggedData = `${window.navigator.userAgent}\r\n\n`;
        loggedData += `${this.translateFn("eob.app.bar.usermenu.info.dialog.webclient.version.client")} ${environmentService.getClientVersion()}\r\n`;
        loggedData += `${this.translateFn("eob.app.bar.usermenu.info.dialog.webclient.version.service")} ${environmentService.getProductVersion()}\r\n`;
        loggedData += `${this.translateFn("eob.app.bar.usermenu.info.dialog.rest.version")} ${serviceInfo.apiVersion} (Build ${serviceInfo.buildRevision})\r\n`;
        loggedData += `${this.translateFn("eob.app.bar.usermenu.info.dialog.rest.url")} ${serviceInfo.services.appconnector}\r\n`;
        loggedData += `${this.translateFn("eob.app.bar.usermenu.info.dialog.renditioncache.url")} ${serviceInfo.services.renditioncache}\r\n`;
        loggedData += `${this.translateFn("eob.app.bar.usermenu.info.dialog.server.version")} ${serviceInfo.serverVersion}\r\n`;
        loggedData += `${this.translateFn("eob.app.bar.usermenu.info.dialog.server.address")} ${serviceInfo.serverAddress}\r\n`;
        loggedData += `${this.translateFn("eob.app.bar.usermenu.info.dialog.user")} ${userName}\r\n\n`;

        const logRecords = await this.getEntriesAsText(sessionId, tabId);
        loggedData += `${logRecords}`;

        // get electron native log data
        if (this.clientService.isLocalClient() && window.electron) {
            const electronLogFileContent = await this.clientService.readDataFromFileAsync(window.electron.getLogFilePath(), false) as ArrayBuffer;
            const electronLogToString = new TextDecoder("utf-8").decode(electronLogFileContent);
            loggedData += "\nELECTRON LOG DATA\n\n";
            loggedData += `${electronLogToString}`;
        }

        const zip = new JSZip();
        zip.file("log.txt", loggedData);
        await this.clientService.saveAsAsync("log.zip", await zip.generateAsync({compression: "DEFLATE", type: "blob"}));
    }
}
