import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Type} from "@angular/core";
import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {EobModalDateTimePickerComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-date-time-picker/eob-modal-date-time-picker.component";
import {EobModalDeduplicationComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-deduplication/eob-modal-deduplication.component";
import {EobModalOrgMultiselectComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-org-multiselect/eob-modal-org-multiselect.component";
import {EobModalProgressCounterComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-progress-counter/eob-modal-progress-counter.component";
import {EobModalQuickfinderComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-quickfinder/eob-modal-quickfinder.component";
import {EobModalUserConfigComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-user-config/eob-modal-user-config.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "SHARED_PATH/shared.module";
import {HitlistModule} from "MODULES_PATH/hitlist/hitlist.module";
import {EobMultiselectBoxesComponent} from "MODULES_PATH/modal-dialog/components/eob-multiselect-boxes/eob-multiselect-boxes.component";
import {EobSubstitutesConfigComponent} from "MODULES_PATH/modal-dialog/components/eob-substitutes-config/eob-substitutes-config.component";
import {ColorCustomizationComponent} from "MODULES_PATH/modal-dialog/components/color-customization/color-customization.component";
import {FormModule} from "MODULES_PATH/form/form.module";
import {StateBasicsModule} from "MODULES_PATH/state-basics/state-basics.module";
import {GridModule} from "MODULES_PATH/grid/grid.module";
import {EobModalHitlistComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-hitlist/eob-modal-hitlist.component";
import {EobModalPasswordComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-password/eob-modal-password.component";

const components: Array<Type<unknown>> = [
    EobModalContainerComponent,
    EobModalDateTimePickerComponent,
    EobModalDeduplicationComponent,
    EobModalOrgMultiselectComponent,
    EobModalProgressCounterComponent,
    EobModalQuickfinderComponent,
    EobModalUserConfigComponent,
    EobMultiselectBoxesComponent,
    EobSubstitutesConfigComponent,
    EobModalHitlistComponent,
    ColorCustomizationComponent,
    EobModalPasswordComponent
];

@NgModule({
    imports: [
        CommonModule, FormsModule, SharedModule, HitlistModule, FormModule, StateBasicsModule, GridModule, ReactiveFormsModule
    ],
    exports: [...components],
    declarations: [...components],
    entryComponents: [...components]
})
export class ModalDialogModule {
}
