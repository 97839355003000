<div class="ag-cell-label-container" role="presentation" (click)="onClick($event)">
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" [hidden]="!sortCount">{{sortCount}}</span>
        <ng-container [ngSwitch]="sort">
            <span *ngSwitchCase="'asc'" ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon">
                <span class="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span>
            </span>
            <span *ngSwitchCase="'desc'" ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon">
                <span class="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span>
            </span>
        </ng-container>
        <ng-template eob-cell-host></ng-template>
    </div>
</div>