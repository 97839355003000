import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";import {takeWhile} from "rxjs/operators";
import {CustomDashletEvent} from "../../../../app/modules/dashlet/enums/custom-dashlet-event.enum";

require("COMPONENTS_PATH/eob-dropzone/eob.dropzone.dir.js");

require("SERVICES_PATH/eob.environment.srv.js");
require("MODULES_PATH/dms/dms-content.service");
require("SERVICES_PATH/mobile-desktop/eob.external.tray.srv.js");

angular.module("eob.framework").directive("eobModalCheckin", EobModalCheckin);

EobModalCheckin.$inject = ["$filter", "$timeout", "$rootScope", "$compile", "environmentService", "notificationsService",
    "dmsActionService", "viewerService", "dmsContentService", "externalTrayService", "progressbarService", "clientService", "messageService"];

/**
 * A directive for uploading and saving content.
 * @scopeparam {Object} item - The dms item, the content will be added to.
 */
// eslint-disable-next-line max-params, require-jsdoc
function EobModalCheckin($filter, $timeout, $rootScope, $compile, EnvironmentService, NotificationsService,
                         DmsActionService, ViewerService, DmsContentService, ExternalTrayService, ProgressbarService, ClientService, MessageService) {
    return {
        scope: {
            dmsDocument: "=item",
            destroy$: "=destroy",
            attributes: "="
        },
        link(scope, element) {

            let destroyed = false;
            let isAdding = false;
            let docModel = scope.dmsDocument.model;
            scope.loadDropzoneFile = false;
            scope.isIntegratedList = true;

            // this is the data that we need to initialize the dropzone
            scope.dropzoneConfig = {
                id: docModel.osid,
                objectTypeId: docModel.objectTypeId,
                mainType: docModel.mainType,
                hideTemplates: true,
                isModal: true,
                destroy$: scope.destroy$,
            };

            // if the modal dialog was opened from script
            if (scope.attributes !== void 0) {
                scope.dropzoneConfig.isFileInfoNeeded = scope.attributes.isFileInfoNeeded;
                scope.dropzoneConfig.showExternalTrayTab = scope.attributes.showExternalTrayItems;
            }

            scope.ready = true;
            scope.showButtons = true;

            scope.$on("file.added.externally", () => {
                scope.addFile();
            });

            scope.addFileAsync = async function() {
                if (ClientService.isOffline()) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                    return;
                }
                let progressBarContainer = element.find(".dropzone-content-box")[0];
                let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", progressBarContainer, true);
                progressBar.show();

                let dropzoneContent = EnvironmentService.getDropzoneContent();

                if (isAdding) {
                    return;
                }
                isAdding = true;

                if (dropzoneContent.mode == "trayItem") {
                    let chosenItem = { dmsDocument: scope.dmsDocument };
                    await ExternalTrayService.checkInTrayItemAsync(dropzoneContent.files, chosenItem, true);
                    scope.close(dropzoneContent.scriptObject);
                } else {
                    if (dropzoneContent.files.length < 1) {
                        NotificationsService.error($filter("translate")("modal.edit.content.missing.files"));
                        isAdding = false;
                        progressBar.hide();
                        return;
                    }

                    try {
                        if (ClientService.isOffline()) {
                            NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                            return;
                        }
                        await DmsContentService.addContentToDocumentAsync(dropzoneContent, docModel.osid);

                        // when inserting new dmsDocs from outside into dropzone, they might not have api
                        if (scope.dmsDocument.api) {
                            scope.dmsDocument.api.updateBaseParameters({ "objectCount": dropzoneContent.files.length });
                        }

                        ViewerService.refreshContent(docModel.osid);

                        DmsActionService.undoCheckOut(scope.dmsDocument);
                        progressBar.hide();
                        scope.close(dropzoneContent.scriptObject);
                        await ViewerService.updateO365Viewer(docModel.osid, scope.dmsDocument);
                        MessageService.broadcast(CustomDashletEvent.UPDATE_CUSTOM_DASHLET_PROPERTIES, {"regenerate": "1"});
                    } catch (error) {
                        NotificationsService.backendError(error, "modal.edit.content.save.error");
                    }
                }
            };

            scope.close = function(data) {
                if (destroyed) {
                    return;
                }

                MessageService.broadcast(ModalEvents.DESTROY, data);

                // set this value in case the scope got destroyed
                // this may prevent an error when the backend blocks, the user opens another document
                // and then the callback kicks in --> the new modal dialog will get destroyed
                destroyed = true;
            };

            scope.destroy$.pipe(takeWhile(destroy => destroy)).subscribe(_ => {
                // the user downloaded the document or swapped its content
                // --> no checkout at this point
                // either the file must be locked or will be unlocked by the "addFile" function
                if (isAdding) {
                    angular.element(document.body).find(".dz-hidden-input, .fileInput").remove();
                    MessageService.broadcast(ModalEvents.DESTROY);

                    return;
                }

                angular.element(document.body).find(".dz-hidden-input, .fileInput").remove();
            });
        },
        template:
            require("!raw-loader!./eob.modal.checkin.html")
    };
}
