import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EobFulltextSearchComponent} from "MODULES_PATH/dashboard/components/eob-fulltext-search/eob-fulltext-search.component";
import {SharedModule} from "SHARED_PATH/shared.module";
import {FormsModule} from "@angular/forms";
import {AutocompleteModule} from "MODULES_PATH/autocomplete/autocomplete.module";

@NgModule({
    declarations: [EobFulltextSearchComponent],
    imports: [
        CommonModule, SharedModule, FormsModule, AutocompleteModule
    ],
    exports: [EobFulltextSearchComponent],
    entryComponents: [EobFulltextSearchComponent]
})
export class DashboardModule {
}
