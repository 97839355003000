import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {Inject, Injectable} from "@angular/core";
import {
    ContextMenuAction,
    ContextMenuActionsProvider,
    ContextType,
    InboxContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {InboxActionService} from "CORE_PATH/services/actions/inbox-action.service";
import {ContextMenuActionsService} from "MODULES_PATH/context-menu/services/context-menu-actions.service";
import {TodoCacheManagerService, TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {MultiMenuService} from "MODULES_PATH/context-menu/services/multi-menu.service";
import {defer, Observable} from "rxjs";

@Injectable({
    providedIn: ContextMenuModule
})
export class InboxContextMenuService implements ContextMenuActionsProvider {
    constructor(private contextMenuUtilsService: ContextMenuUtilsService,
                private clientService: ClientService,
                private contextMenuActionsService: ContextMenuActionsService,
                private actionService: ActionService, private multiMenuService: MultiMenuService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("inboxActionService") private inboxActionService: InboxActionService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
    ) {}

    getSingleSelectionContextMenuActions(contextMenuItem: InboxContextMenuItem, contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            const contextMenuActions: ContextMenuAction[] = [];
            const contextItem: InboxContextMenuItem = this.cacheManagerService.dmsDocuments.getById(contextMenuItem.osid).api.getContextItem(contextMenuItem.guid);

            if (this.clientService.isOnline()) {
                if (contextItem.model.read !== void 0) {
                    if (contextItem.model.read) {
                        contextMenuActions.push(this.addMarkUnread([contextItem], contextData));
                    } else {
                        contextMenuActions.push(this.addMarkRead([contextItem], contextData));
                    }
                }

                if (contextData.context == ContextType.SUBSCRIPTIONOBJECTS && this.environmentService.userHasRole("R_CLNT_ABO_ADMIN")) {
                    contextMenuActions.push(this.addEditAbo(contextItem));
                    contextMenuActions.push(this.addClearAbo([contextItem]));
                }

                if (contextData.context == ContextType.ABO) {
                    if (!contextItem.model.confirmed && contextItem.model.confirmationType != "NO_CONFIRMATION") {
                        contextMenuActions.push(this.addMarkEditedUnedited([contextItem], contextData));
                    }

                    if (contextItem.model.confirmed || contextItem.model.confirmationType == "NO_CONFIRMATION") {
                        contextMenuActions.push(this.addRemoveNotifications([contextItem]));
                    }
                } else if (contextData.context == ContextType.REVISIT && !contextItem.model.confirmed) {
                    contextMenuActions.push(this.addMarkEditedUnedited([contextItem], contextData));
                }
            }

            if (contextMenuActions.length > 0){
                contextMenuActions.push(this.contextMenuUtilsService.addDivider());
            }

            await this.contextMenuActionsService.getSingleselectItems(contextMenuItem, contextMenuActions, contextData.context, contextData);

            return contextMenuActions;
        });
    }

    getMultiselectionContextMenuActions(contextMenuItems: InboxContextMenuItem[], contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            const contextMenuActions: ContextMenuAction[] = [];

            if (this.clientService.isOnline()) {
                if (contextData.context == ContextType.ABO) {
                    this.getAboSpecificMultiActions(contextMenuItems, contextMenuActions);
                } else if (contextData.context == ContextType.REVISIT) {
                    this.getRevisitSpecificMultiActions(contextMenuItems, contextMenuActions, contextData);
                } else {
                    this.getSubscriptionObjectSpecificMultiActions(contextMenuItems, contextMenuActions);
                }
            }

            if (contextMenuActions.length > 0) {
                contextMenuActions.push(this.contextMenuUtilsService.addDivider());
            }

            await this.multiMenuService.getMultiselectActions(contextMenuItems, contextMenuActions, contextData.context, contextData);

            return contextMenuActions;
        });
    }

    private getAboSpecificMultiActions(contextMenuItems: InboxContextMenuItem[], contextMenuActions: ContextMenuAction[]): void {
        let canConfirm = true;
        let canRemove = true;
        let markUnread = false;
        let markRead = false;
        let withoutConfirmation = true;

        const contextItems: InboxContextMenuItem[] = [];

        for (const item of contextMenuItems) {
            const contextItem: InboxContextMenuItem = this.cacheManagerService.dmsDocuments.getById(item.osid).api.getContextItem(item.guid);

            if (contextItem.model.confirmationType != "NO_CONFIRMATION") {
                withoutConfirmation = false;
                canConfirm = false;
            }

            if (!contextItem.model.confirmed && contextItem.model.confirmationType != "NO_CONFIRMATION") {
                canRemove = false;
            }

            if (contextItem.model.read) {
                markUnread = true;
            } else {
                markRead = true;
            }

            contextItems.push(contextItem);
        }

        if (markUnread) {
            contextMenuActions.push(this.addMarkUnread(contextItems));
        }

        if (markRead) {
            contextMenuActions.push(this.addMarkRead(contextItems));
        }

        // only set the action if every selected document does not require a confirmation
        if (canConfirm && !withoutConfirmation) {
            contextMenuActions.push(this.addMarkEditedUnedited(contextItems));
        }

        if (canRemove) {
            contextMenuActions.push(this.addRemoveNotifications(contextItems));
        }
    }

    private getRevisitSpecificMultiActions(contextMenuItems: InboxContextMenuItem[], contextMenuActions: ContextMenuAction[], contextData: ContextData): void {
        let canConfirm = true;
        let markUnread = false;
        let markRead = false;

        const contextItems: InboxContextMenuItem[] = [];

        for (const item of contextMenuItems) {
            const contextItem: InboxContextMenuItem = this.cacheManagerService.dmsDocuments.getById(item.osid).api.getContextItem(item.guid);

            if (contextItem.model.confirmationType != "NO_CONFIRMATION") {
                canConfirm = false;
            }

            if (contextItem.model.read) {
                markUnread = true;
            } else {
                markRead = true;
            }

            contextItems.push(contextItem);
        }

        if (markUnread) {
            contextMenuActions.push(this.addMarkUnread(contextItems, contextData));
        }

        if (markRead) {
            contextMenuActions.push(this.addMarkRead(contextItems, contextData));
        }

        // only set the action if every selected document does not require a confirmation
        if (canConfirm) {
            contextMenuActions.push(this.addMarkEditedUnedited(contextItems));
        }
    }

    private getSubscriptionObjectSpecificMultiActions(items: InboxContextMenuItem[], contextMenuActions: ContextMenuAction[]): void {
        const contextItems: InboxContextMenuItem[] = [];

        for (const item of items) {
            const contextItem: InboxContextMenuItem = this.cacheManagerService.dmsDocuments.getById(item.osid).api.getContextItem(item.guid);
            contextItems.push(contextItem);
        }

        if (this.environmentService.userHasRole("R_CLNT_ABO_ADMIN")) {
            contextMenuActions.push(this.addClearAbo(contextItems));
        }
    }

    private addMarkRead(contextMenuItems: InboxContextMenuItem[], contextData?: ContextData): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.inboxitem.markread.title",
            "mark-read", this.inboxActionService.markRead, contextMenuItems, contextData?.context ?? ContextType.ABO);
    }

    private addMarkUnread(contextMenuItems: InboxContextMenuItem[], contextData?: ContextData): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.inboxitem.markunread.title",
            "mark-unread", this.inboxActionService.markUnread, contextMenuItems, contextData?.context ?? ContextType.ABO);
    }

    private addMarkEditedUnedited(contextMenuItems: InboxContextMenuItem[], contextType?: ContextData): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.notification.confirm.title",
            "edited", this.inboxActionService.markAsEditedUnedited, contextMenuItems, contextType?.context ?? ContextType.ABO);
    }

    private addRemoveNotifications(contextMenuItems: InboxContextMenuItem[]): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.notification.remove.title",
            "delete-dark", this.inboxActionService.removeNotifications, contextMenuItems);
    }

    private addEditAbo(contextMenuItem: InboxContextMenuItem): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.abo.edit", "edit",
            this.actionService.editAbo, contextMenuItem);
    }

    private addClearAbo(contextMenuItems: InboxContextMenuItem[]): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.abo.clear", "delete-dark",
            this.inboxActionService.clearAbo, contextMenuItems);
    }
}
