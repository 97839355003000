(function() {
    require("SERVICES_PATH/viewer/detailsviewer/dv.date.srv.js");
    require("SERVICES_PATH/viewer/detailsviewer/dv.username.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");

    angular.module("eob.framework").directive("dvNav", DvNav);

    DvNav.$inject = ["$filter", "dvDateService", "environmentService", "dvUsernameService", "dvDocumentService", "messageService"];

    // eslint-disable-next-line max-params, require-jsdoc
    function DvNav($filter, DvDateService, EnvironmentService, DvUserNameService, DvDocumentService, MessageService) {
        return {
            restrict: "E",
            template: require ("!raw-loader!./dv.nav.html"),
            link(scope, element) {
                scope.notesLinkEnabled = EnvironmentService.userHasRole("R_CLNT_VIEWNOTES");
                scope.historyLinkEnabled = EnvironmentService.userHasRole("R_CLNT_OBJHIST");
                scope.workflowLinkEnabled = EnvironmentService.userHasRole("R_WFCLNT_USE");

                MessageService.subscribe("clear.viewer", clear);
                scope.$on("dv.no.dms.object", noObject);

                DvDocumentService.attachListener(setLockInformation);

                /**
                 * Don't display the navigation, when the default page is shown.
                 *
                 * @param {Event} event - Placeholder for the js event.
                 * @param {{ isDmsObject: boolean }} data - Whether default page is shown or not.
                 */
                function noObject(event, data) {
                    if (data.isDmsObject) {
                        element.removeClass("invisible");
                    } else {
                        element.addClass("invisible");
                    }
                }

                /**
                 * Clear scope data.
                 */
                function clear() {
                    scope.isLocked = false;
                    scope.imgLockMsg = undefined;
                }

                /**
                 * Add an icon with lockout information to the navigation, if the document is locked out.
                 *
                 * @param {object} dvDmsObject - Contains information on who locked this document and when.
                 */
                function setLockInformation(dvDmsObject) {
                    let lockData = dvDmsObject.lockdata;

                    scope.isLocked = lockData.isLocked;
                    scope.isLockedSelf = lockData.isLockedSelf;

                    if (scope.isLocked) {
                        let lockDate = lockData.lockedTime != void 0 ? DvDateService.convertTimestamp(parseInt(lockData.lockedTime)) : undefined;

                        if (scope.isLockedSelf) {
                            scope.imgLockMsg = lockDate == void 0 ? $filter("translate")("app.nav.lock.by.current.user.without.time")
                                : $filter("translate")("app.nav.lock.by.current.user").replace("{date}", lockDate);
                        } else {
                            let lockUser = DvUserNameService.getUsername({ name: lockData.lockedUser, fullname: lockData.lockedUserFullName});

                            scope.imgLockMsg = lockDate == void 0 ? $filter("translate")("app.nav.lock.by.other.user.without.time").replace("{user}", lockUser)
                                : $filter("translate")("app.nav.lock.by.other.user").replace("{user}", lockUser).replace("{date}", lockDate);
                        }
                    }
                }

                scope.$on("$destroy", () => {
                    DvDocumentService.detachListener(setLockInformation);
                });
            }
        };
    }
})();
