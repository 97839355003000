import {Inject, Injectable} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";
import {Subject} from "rxjs";
import {buffer, debounceTime} from "rxjs/operators";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {OsrestIcons} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-icons.interface";

@Injectable({
    providedIn: "root"
})
export class IconService {
    private iconMap: Map<string, string> = new Map<string, string>();
    private initPromise: Promise<void> | void = undefined;
    private addedIcons: string[] = [];
    processor$: Subject<string[]> = new Subject<string[]>();
    emitter$: Subject<Map<string, string>> = new Subject<Map<string, string>>();

    constructor(protected httpService: HttpService,
                protected clientService: ClientService,
                @Inject("fileCacheService") protected fileCacheService: FileCacheService) {
        this.processor$.pipe(buffer(this.processor$.pipe(debounceTime(1000)))).subscribe(x => {
            this._processIcons(x.reduce((acc, val) => acc.concat(val), []).filter((e, i, a) => a.indexOf(e) === i));
        });
    }

    processIcons(iconIds: string[]): Promise<any> {
        return new Promise<any>((resolve) => {
            this.processor$.next(iconIds);
            this.emitter$.subscribe(x => resolve(this.iconMap));
        });
    }

    /**
     * loads icons from the backend and sotres them in the indexed db
     *
     * @param {Array<string | number>} iconIds
     * @returns {Promise<object>}
     */
    private async _processIcons(iconIds: string[]): Promise<any> {
        await this.init();
        const tmpIcons: string[] = [];
        for (const id of iconIds) {
            if (!/^\d+$/.test(id.toString())) {
                continue;
            }

            if (!this.iconMap.has(id)) {
                tmpIcons.push(id);
            }
        }

        iconIds = tmpIcons;

        if (this.clientService.isOnline() && iconIds.length > 0) {
            let icons: { [k: string]: string };

            try {
                const response: OsrestIcons = await this.httpService.getIcons(iconIds).toPromise();
                icons = response.icons;
            } catch (e) {
                icons = {};
            }

            for(const k of Object.keys(icons)) {
                this.iconMap.set(k, icons[k]);
            }

            this.fileCacheService.storeContentAsync(DatabaseEntryType.GLOBAL, this.iconMap, GlobalCacheKey.OBJ_DEF_ICONS);
        }

        this.appendIconStyles(this.iconMap);
        this.emitter$.next(this.iconMap);
        return this.iconMap;
    }

    /**
     * Returns the current icon map with Pairs <key,value> as <iconId, base64>
     *
     * @returns {Promise<object>}
     */
    async getIcons(): Promise<any> {
        await this.init();
        return this.iconMap;
    }

    hasIcon(iconId: string): boolean {
        return this.iconMap.has(iconId);
    }

    /**
     * inits icons as styles
     *
     * @returns {Promise<void>}
     */
    private async init(): Promise<void> {
        if (this.initPromise == void 0) {
            this.initPromise = this.initIcons();
        }
        return this.initPromise;
    }

    /**
     * loads icons from cache
     *
     * @returns {Promise<void>}
     */
    private async initIcons(): Promise<void> {
        let cachedResult: any;

        try {
            cachedResult = await this.fileCacheService.getContentAsync(DatabaseEntryType.GLOBAL, GlobalCacheKey.OBJ_DEF_ICONS, {first: true});
        } catch (error) { /* Can be ignored. */ }

        for(const k of Object.keys(cachedResult || {})) {
            this.iconMap.set(k, cachedResult[k]);
        }
    }

    /**
     * creates a generic stylesheet with every icon as a class with base64 content
     *
     * @param iconMap
     */
    private appendIconStyles(iconMap: Map<string, string>): void {
        if (!iconMap.size) {
            return;
        }
        const oldAddedIconsLength: number = this.addedIcons.length;
        let css = "";

        iconMap.forEach((value, key) => {
            if (this.addedIcons.includes(key)) {
                return;
            }
            this.addedIcons.push(key);
            css += `.custom-icon-${key}{background-image:url(data:image/gif;base64,${value});}\n`;
        });
        if (oldAddedIconsLength < this.addedIcons.length) {
            const style: HTMLStyleElement = document.createElement("style");
            const head: HTMLElement = document.head || document.getElementsByTagName("head")[0];
            style.setAttribute("type", "text/css");
            style.appendChild(document.createTextNode(css));
            head.appendChild(style);
        }
    }
}
