import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
angular.module("eob.framework").directive("eobModalConfirm", EobModalConfirm);

EobModalConfirm.$inject = ["$injector", "$filter", "clientService", "notificationsService", "messageService"];

/**
 * A directive for a simple confirm dialog.
 * @scopeparam {string} msg - The message text.
 * @scopeparam {string} htmlmsg - The message text in html form.
 * @scopeparam {Object} deferred - The deferred object to resolve or reject the dialog.
 * @scopeparam {Object} buttons - May contain cancel, submit, ok button texts.
 */
export default function EobModalConfirm($injector, $filter, ClientService, NotificationsService, MessageService) {
    return {
        restrict: "E",
        scope: {
            msg: "=",
            htmlmsg: "=",
            resolve: "=",
            reject: "=",
            deferred: "=",
            buttons: "=",
            error: "=",
            icon: "=",
            submsg: "=",
            allowOfflineSubmission: "=allowofflinesubmission",
            downloadLogData: "=downloadlogdata"
        },
        link (scope, element) {
            scope.errorAreaToggleState = false;
            if (scope.error && scope.error instanceof Error) {
                scope.errorMessage = scope.error.stack;
            }
            else if (scope.error && (scope.error instanceof String || typeof scope.error === "string")) {
                scope.errorMessage = scope.error;
            }

            scope.hideCloseButton = !((scope.buttons.cancel && scope.buttons.cancel != ""));
            scope.hideConfirmButton = !scope.buttons.submit;
            scope.hideOkButton = !scope.buttons.ok;
            scope.hideDownloadLogFileButton = !scope.downloadLogData;

            scope.isCloseOnly = scope.hideConfirmButton && scope.hideOkButton;
            /**
             * Close the dialog once it is confirmed
             * @param button Which button has been pressed to confirm
             */
            scope.confirm = function (button) {
                if (ClientService.isOffline() && !scope.allowOfflineSubmission) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                    return;
                }
                if(scope.resolve != void 0){
                    scope.resolve(button);
                }else{
                    MessageService.broadcast(ModalEvents.DESTROY, button === "cancel" ? null : button);
                }
                scope.close(button);
            };

            /**
             * Close the dialog once it is canceled.
             * @param button Which button has been pressed to cancel
             */
            scope.close = function (button) {
                scope.reject != void 0 ? scope.reject(button) : MessageService.broadcast(ModalEvents.DESTROY, button === "cancel" ? null : button);
            };

            /**
             * Download zipped log file
             */
            scope.downloadLogFile = async(sessionId, tabId) => {
                const LoggingService = $injector.get("loggingService");
                await LoggingService.getLogEntriesAsZipFile(sessionId, tabId);
            }
        },
        template: require("!raw-loader!./eob.modal.confirm.html")
    };
}
