import {Component, Input, Output, EventEmitter} from "@angular/core";
import {Renderer2} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {ContextMenuAction} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";

@Component({
    selector: "eob-context-menu-template",
    templateUrl: "./eob-context-menu-template.component.html",
    styleUrls: ["./eob-context-menu-template.component.scss"]
})
export class EobContextMenuTemplateComponent {
    @Input() type: string;
    @Input() contextMenuActions: ContextMenuAction[];
    @Input() submenuItem: ContextMenuAction;
    @Input() headerContent: string;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onBack: EventEmitter<Event> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClose: EventEmitter<Event> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onOpenSubmenu: EventEmitter<any> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onOpen2ndSubmenu: EventEmitter<any> = new EventEmitter();

    isPhone:boolean = false;
    isForcedPhoneLayout:boolean = false;

    constructor(protected clientService: ClientService,
                protected messageService: MessageService, protected renderer: Renderer2) {
        this.isPhone = this.clientService.isPhone();
        this.isForcedPhoneLayout = this.clientService.isForcedPhoneLayout();
    }

    executeCallback(event: Event, contextMenuAction: ContextMenuAction): void {
        if (contextMenuAction.type == "sub" && contextMenuAction.params.length > 0) {
            this.emitSubmenuEvent(event, contextMenuAction);
        } else {
            contextMenuAction.callback(...contextMenuAction.params);
            this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS, event);
        }
    }

    onMouseEnter(event: Event, contextMenuAction: ContextMenuAction): void {
        if ((event.type == "mouseenter" && this.isForcedPhoneLayout) || (event.type == "mouseup" && !this.isForcedPhoneLayout)) {
            return;
        }

        if (!this.clientService.isTouchDevice()) {
            if (contextMenuAction.type == "sub" && contextMenuAction.params.length > 0) {
                this.emitSubmenuEvent(event, contextMenuAction);
            } else if (this.type == "main") {
                this.renderer.setStyle(document.querySelector("div.submenu-container"), "display", "none");
            }
        }
    }

    emitSubmenuEvent(event: Event, contextMenuAction: ContextMenuAction): void {
        if (this.type == "sub") {
            this.onOpen2ndSubmenu.emit({event, contextMenuAction});
        } else {
            this.onOpenSubmenu.emit({event, contextMenuAction});
        }
    }

    hideSubmenuContainers(): void {
        document.querySelectorAll("div.submenu-container.context-menu").forEach(element => {
            this.renderer.setStyle(element, "display", "none");
        });

        document.querySelectorAll("li.context-menu-item").forEach(element => {
            this.renderer.removeClass(element, "hovered");
        });
    }

    // region phone
    closeMenu(event: Event): void {
        this.onClose.emit(event);
    }

    goBack(event: Event): void {
        this.onBack.emit(event);
    }

    addClosedStyles(menuElement?: HTMLElement): void {
        this.renderer.setStyle(menuElement, "top", `${document.body.offsetHeight}px`);
        this.renderer.setStyle(menuElement, "display", "none");
    }
}
