import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Renderer2
} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {
    Column,
    ColumnResizedEvent,
    ColumnState,
    GridOptions,
    RowClickedEvent,
    RowNode
} from "ag-grid-community";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {HitListService} from "MODULES_PATH/hitlist/services/hit-list.service";
import {HitListApiService} from "MODULES_PATH/hitlist/services/hit-list-api.service";
import {StateParams, StateService} from "@uirouter/core";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {DmsMessageType} from "MODULES_PATH/dms/enums/dms-message-type.enum";
import {HitlistEvent} from "MODULES_PATH/hitlist/enums/hitlist-event.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Subscription} from "rxjs";
import {OfflineSynchronizationMessage} from "ENUMS_PATH/offline/offline-synchronization-message.enum";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {ColumnDefinition} from "MODULES_PATH/grid/interfaces/column.interface";
import {AsIniHitlistConfiguration} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ContextData, HitlistConfig, StoredParams} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {ColumnController} from "ag-grid-community/dist/lib/columnController/columnController";
import {
    CellContextMenuEvent,
    ColumnRowGroupChangedEvent,
    RowSelectedEvent
} from "ag-grid-community/dist/lib/events";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import * as angular from "angular";
import {ContextMenuService} from "MODULES_PATH/context-menu/services/context-menu.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {
    TodoCacheManagerService, TodoEnvironmentService,
    TodoGridNodeData,
    TodoHitlistConfigService,
    TodoHitlistStateConfig,
    TodoItemAddedData,
    TodoListEntry,
    TodoLocationTypeConfig,
    TodoStateHistoryManager,
    TodoStaticColumnData,
    TodoTouchHandlerService,
    TodoUpdateRowsData
} from "INTERFACES_PATH/any.types";
import {OfflineObjectSyncStatus} from "SHARED_PATH/models/offline-sync-status.model";
import {GridCellContent} from "MODULES_PATH/grid/interfaces/grid-cell-content.interface";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {AgGridService} from "MODULES_PATH/grid/services/ag-grid.service";
import {SortService} from "CORE_PATH/services/utils/sort.service";

@Component({
    selector: "eob-hit-list",
    templateUrl: "./eob-hit-list.component.html",
    styleUrls: ["./eob-hit-list.component.scss"]
})
export class EobHitListComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() hitlistconfig: HitlistConfig;
    @Input() contextdata: ContextData;

    gridOptions: GridOptions = {};
    isInitializationDone: boolean = false;
    isEmptyGrid: boolean = false;
    showAddButton: boolean = false;
    emptyPlaceholderString: string = "";

    readonly isPhone: boolean;
    readonly isTouchDevice: boolean;
    readonly translateFn: TranslateFnType;
    readonly stateId;

    columnDefs: ColumnDefinition[];
    rows: RowNode[];
    columnsResized: boolean = true;
    touchKebabColumn: TodoStaticColumnData;
    lastNodeOsid: string | number;
    selectedRowOsid: string | number;
    lastNodeSelectionStatus: boolean;
    resizeDebounce: ReturnType<typeof setTimeout>;
    ensureIndexVisibleTimeout: ReturnType<typeof setTimeout>;
    hitListSettings: AsIniHitlistConfiguration;
    subscription: Subscription = new Subscription();
    refreshGridContent: (rows: RowNode[], colDefs: ColumnDefinition[]) => void;

    private headerDataId: string;
    private headerIdKey: string;
    private updateRowsDebounce;
    private isForcedPhoneLayout: boolean;

    showAltLang: boolean;
    objectDefLang: string;

    // eslint-disable-next-line max-params
    constructor(@Inject(forwardRef(() => ClientService)) private clientService: ClientService,
                @Inject(forwardRef(() => "$filter")) private $filter: ng.IFilterService,
                @Inject(forwardRef(() => "$location")) private $location: ng.ILocationService,
                @Inject(forwardRef(() => "$stateParams")) private $stateParams: StateParams,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                protected viewerService: ViewerService,
                @Inject("cacheManagerService") protected cacheManagerService: TodoCacheManagerService,
                @Inject("stateHistoryManager") protected stateHistoryManager: TodoStateHistoryManager,
                @Inject("hitlistConfigService") protected hitlistConfigService: TodoHitlistConfigService,
                @Inject("touchHandlerService") private touchHandlerService: TodoTouchHandlerService,
                @Inject("offlineCacheService") private offlineCacheService: OfflineCacheService,
                private $state: StateService,
                private agGridService: AgGridService,
                private messageService: MessageService,
                private toolService: ToolService,
                private asIniService: AsIniService,
                private hitListService: HitListService,
                private hitListApiService: HitListApiService,
                private contextMenuService: ContextMenuService,
                private sortService: SortService,
                private el: ElementRef, private renderer: Renderer2, private changeDetection: ChangeDetectorRef) {
        this.translateFn = this.$filter("translate");
        this.isPhone = this.clientService.isPhone();
        this.isTouchDevice = this.clientService.isTouchDevice();
        this.stateId = $stateParams.state;
        this.isForcedPhoneLayout = this.clientService.isForcedPhoneLayout();
    }

    ngOnInit(): void {
        this.hitListSettings = this.asIniService.getHitlistConfiguration(this.stateHistoryManager.getCurrentStateData().data);
        this.columnDefs = this.hitlistconfig.columns;
        this.rows = this.hitlistconfig.rows;
        this.initHitList.call(this);
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang();
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();

        this.contextMenuService.registerContextMenuActionsProvider(this.hitlistconfig.context ?? this.$stateParams.type ?? this.contextdata?.context ?? this.$state.current.name);
    }

    ngAfterViewInit(): void {
        this.extendLastCol(true);
        this.initEmptyPlaceholderString();
    }

    ngOnDestroy(): void {
        this.contextMenuService.unregisterContextMenuActionsProvider(this.hitlistconfig.context ?? this.$stateParams.type ?? this.contextdata?.context ?? this.$state.current.name);
        this.subscription.unsubscribe();
    }


    extendLastCol = (suppressAutoSave: boolean): void => {
        const gridViewport: HTMLElement = this.el.nativeElement.querySelector(".ag-body-viewport");
        const bodyContainer: HTMLElement = this.el.nativeElement.querySelector(".ag-center-cols-container");
        const columns: ColumnState[] = this.gridOptions.columnApi.getColumnState();

        if (!bodyContainer || !gridViewport) {
            return;
        }

        let gridWidth: number = gridViewport.offsetWidth;

        if (gridWidth === 0) {
            setTimeout(() => {
                this.extendLastCol(true);
            }, 0);

            return;
        }

        if (!this.isPhone && gridViewport.offsetHeight < bodyContainer.offsetHeight) {
            gridWidth -= 17;
        } else if (this.isPhone) {
            // min width of a col is 200, we have to set it here to default 200 to calculate the width when orientation changes
            columns[1].width = 200;
        }

        if (!this.gridOptions.columnApi) {
            console.warn("extendLastCol() without columnApi");
            return;
        }

        const groups: Column[] = this.gridOptions.columnApi.getRowGroupColumns();
        const groupCols: string[] = [];

        if (groups.length) {
            for (const group of groups) {
                groupCols.push(group.getColId());
            }
        }

        let totalColWidth: number = 0;
        let lastCol: string;

        for (let i = 0, j: number = columns.length - 1; i < j; i++) {
            lastCol = `f${i}`;

            if (groupCols.includes(lastCol)) {
                continue;
            }

            const width: number = columns[i].width;
            if (!isNaN(width)) {
                totalColWidth += width;
            }
        }

        if (totalColWidth < gridWidth) {
            if (this.isPhone) {
                columns[1].width += gridWidth - totalColWidth - columns[columns.length - 1].width;
                this.gridOptions.columnApi.setColumnWidth(columns[1].colId, columns[1].width);
            } else if (columns.length > 0) {
                columns[columns.length - 1].width = gridWidth - totalColWidth;
                this.gridOptions.columnApi.setColumnWidth(columns[columns.length - 1].colId, columns[columns.length - 1].width);
            }
        }

        this.onColumnResized(null, true, suppressAutoSave);
    };

    createNewObject = (): void => {
        void this.hitListService.createNewObject();
    };

    refreshGroupingTags = (params: TodoGridNodeData): void => {
        if (!params.data) {
            return;
        }

        if (this.gridOptions.columnApi.getRowGroupColumns().length) {
            const colsController: ColumnController = params.node.columnController;
            const tags: HTMLElement[] = this.el.nativeElement.querySelectorAll(".ag-column-drop-cell-text");

            let colId: string;

            if (!tags.length) {
                return;
            }

            for (const [i, column] of colsController.getRowGroupColumns().entries()) {
                colId = column.getColId();
                colId = colId.indexOf("_") > 0 ? /(f.*)_.*/.exec(colId)[1] : colId;

                const tagText: string = params?.data[colId].headerName || "";

                tags[i].innerHTML = tagText;
            }
        }
    };

    restoreSelection = (config: HitlistConfig, isInitial: boolean = false): void => {
        let itemSelected: boolean = false;

        const selectedItemKeys: string[] = Object.keys(config.selectedItems || {});
        const suppressSelectionOnInit: boolean = isInitial && this.hitlistconfig.suppressSelectionOnInit;

        if (selectedItemKeys.length > 0) {
            // select all items, that were selected before
            this.gridOptions.api.forEachNodeAfterFilterAndSort((node) => {
                // in case the grid is grouped, each group gets its own node and has to be skipped
                if (node == void 0 || node.group) {
                    return;
                }

                const selected: number = config.selectedItems[node.data.guid] === void 0 ? config.selectedItems[node.data.id] : config.selectedItems[node.data.guid];
                if (selected != void 0) {
                    itemSelected = this.hitListApiService.selectNode(node, false);

                    clearTimeout(this.ensureIndexVisibleTimeout);

                    this.ensureIndexVisibleTimeout = setTimeout(() => {
                        if (this.gridOptions.api) {
                            this.gridOptions.api.ensureIndexVisible(node.rowIndex);
                        }
                    }, 500);
                }
            });

            // if no selected item was found, select the item after the formerly selected item
            if (!itemSelected && !suppressSelectionOnInit) {
                let index: number = 0;
                let lastNode: RowNode = null;

                const selectedIndex: number = config.selectedItems[selectedItemKeys[0]];

                this.gridOptions.api.forEachNodeAfterFilterAndSort((node) => {
                    lastNode = node;

                    if (!itemSelected && index >= selectedIndex) {
                        itemSelected = this.hitListApiService.selectNode(node, itemSelected);
                    } else {
                        index++;
                    }
                });

                if (!itemSelected && lastNode != null) {
                    itemSelected = this.hitListApiService.selectNode(lastNode, false);
                }
            }
        }

        // select the first item
        if (!itemSelected && !suppressSelectionOnInit) {
            this.selectFirstItem();
        }
    };

    onColumnRowGroupChanged = (params: ColumnRowGroupChangedEvent): void => {
        if (params.source == "toolPanelUi" && this.canSaveHitlistSettings()) {
            this.hitlistConfigService.saveGrouping(this.hitlistconfig.api, this.$stateParams.state);
            this.hitListSettings = this.asIniService.getHitlistConfiguration(this.stateHistoryManager.getCurrentStateData().data);
        }

        this.refreshGroupingTags(params);
    };

    onRowGroupOpened = (): void => {
        if (this.canSaveHitlistSettings()) {
            this.hitlistConfigService.saveGrouping(this.hitlistconfig.api, this.$stateParams.state);
            this.hitListSettings = this.asIniService.getHitlistConfiguration(this.stateHistoryManager.getCurrentStateData().data);
        }
    };

    updateRowsCount = (rowNode?: RowNode): void => {
        if (!rowNode?.group) {
            if (isNaN(this.hitlistconfig.selectedRowsCount)) {
                this.hitlistconfig.selectedRowsCount = 0;
            }

            this.hitlistconfig.totalRowsCount = this.rows.length;
            this.hitlistconfig.selectedRowsCount = this.hitlistconfig.api.getSelectedRows()?.length;
        }
    };

    // region api functions

    deleteItem = ({model: docModel}: DmsDocument, keepViewer: boolean): void => {
        this.hitListApiService.deleteItem(this.hitlistconfig, docModel, keepViewer);
    };

    addItem = (item: DmsDocument, keepViewer: boolean): void => {
        this.hitListApiService.addItem(this.hitlistconfig, item, keepViewer);
    };

    selectFirstItem = (): void => {
        this.hitListApiService.selectFirstItem(this.hitlistconfig);
    };

    deselectAll = (): void => {
        this.gridOptions?.api.deselectAll();
    };

    selectItems = (arrayOfIds: string[], selectionKey: string): void => {
        this.hitListApiService.selectItems(this.gridOptions, arrayOfIds, selectionKey);
    };

    resetGrid = (): void => {
        this.hitListApiService.setSortModel(this.gridOptions, []);
        this.gridOptions.columnApi.resetColumnState();
        this.columnsResized = false;
    };

    /**
     * Update the grid with new data.
     *
     * @param {object} newHitListConfig - The altered hitlist configuration.
     * @param {boolean=} isGridContentChange - Reset the complete hitlist configuration.
     * @param {boolean=} keepViewer - Don't clear the viewer, if the new grid is empty.
     * @param {boolean=} executeInstantly - Whether the function should be executed instantly or be debounced instead
     */
    updateRows(newHitListConfig: HitlistConfig, isGridContentChange: boolean, keepViewer: boolean, executeInstantly: boolean): void {
        this.isInitializationDone = false;

        if (isGridContentChange) {
            this.isInitializationDone = false;
            this.hitListSettings = this.asIniService.getHitlistConfiguration(this.stateHistoryManager.getCurrentStateData().data);
        }

        if (this.gridOptions.api == void 0) {
            return;
        }

        this.hitListService.saveSelection(this.gridOptions, this.$stateParams.state, this.hitlistconfig.filter);

        this.columnDefs = newHitListConfig.columns;

        if (this.isPhone && this.$state.current.name == "hitlist.offlineObjects" && (this.contextdata || {}).context != "modalHitlist") {
            // we can't set new rows here because all offline sync data will be removed from the phone cell
            // instead we make sure osids are the same and show "old" rows on phone
            this.rows = this.rows.filter(row => newHitListConfig.rows.find(x => (row as any).osid === (x).osid));
        } else {
            this.rows = newHitListConfig.rows || [];
        }
        this.gridOptions.rowData = this.rows;

        if (this.isPhone && this.columnDefs.length < 3) {
            this.columnDefs.push(this.touchKebabColumn);
        }

        this.hitListService.attachComparators(this.columnDefs);

        setTimeout(() => {
            this.refreshGridContent(this.rows, this.columnDefs);
        }, 0);

        clearTimeout(this.updateRowsDebounce);

        this.updateRowsDebounce = setTimeout(() => {
            if (this.gridOptions.api.getDisplayedRowCount() > 0) {
                this.restoreSelection(this.stateHistoryManager.getCurrentConfig(), keepViewer);
            } else if (!keepViewer) {
                void this.viewerService.clearViewer();
            }

            this.isInitializationDone = true;

            if (this.showAddButton) {
                this.updateGridContainer();
            }

            this.updateRowsCount();

            this.messageService.broadcast(HitlistEvent.UPDATE_ROWS_DONE);
        }, 250);
    }

    // endregion

    private async initHitList(): Promise<void> {
        const rowGroupColumnsEmptyMessage: string = this.translateFn("eob.hitlist.grouping.placeholder");

        if (this.isPhone) {
            this.touchKebabColumn = {
                field: `f${this.columnDefs.length}`,
                suppressMovable: true,
                resizable: false,
                sortable: false,
                suppressSizeToFit: true,
                width: 40,
                isIconCell: true,
                cellRenderer: "iconCellRenderer",
                cellRendererParams: {value: {icon: "kebab-dark", ariaLabel: "kebab-dark"}},
                onCellClicked: (a) => {
                    this.onRightClick(a);
                }
            };

            this.clientService.registerOrientationChangeHandler(() => {
                clearTimeout(this.ensureIndexVisibleTimeout);
                this.ensureIndexVisibleTimeout = setTimeout(() => {
                    if (this.gridOptions.api) {
                        const nodes: RowNode[] = this.gridOptions.api.getSelectedNodes();
                        if (nodes[0]) {
                            this.gridOptions.api.ensureIndexVisible(nodes[0].rowIndex);
                        }
                        this.extendLastCol(true);
                    }
                }, 500);
            });

            const context: string = this.$stateParams.type;

            if (!this.hitlistconfig.hideTouchKebab) {
                if (context == "startable" || context == "processes") {
                    this.touchKebabColumn.cellRendererParams.value.icon = this.hitListSettings.subType == "startable" ? "start-arrow-blue" : "assign-workflow";
                    this.touchKebabColumn.onCellClicked = (params) => {
                        this.hitListService.callWorkflowAction(params, context);
                    };
                }

                this.columnDefs.push(this.touchKebabColumn);
            }
        }

        this.refreshGridContent = this._refreshGridContent;
        this.isEmptyGrid = this.rows.length === 0;

        if (this.isEmptyGrid && !this.hitlistconfig.suppressSelectionOnInit) {
            void this.viewerService.clearViewer();
        }

        setTimeout(() => {
            this.isInitializationDone = true;
        }, 1000);

        this.gridOptions = {
            defaultColDef: {
                resizable: !this.isPhone,
                sortable: true,
                filter: true,
                suppressMenu: true
            },
            frameworkComponents: this.hitlistconfig.frameworkComponents,
            autoGroupColumnDef: this.isPhone ? {} : {minWidth: 200},
            groupUseEntireRow: true,
            rowGroupPanelShow: (this.hitlistconfig.suppressGrouping || this.isPhone) ? "" : "always",
            suppressMovableColumns: (!!this.hitlistconfig.suppressGrouping || this.isPhone),
            suppressColumnMoveAnimation: true,
            suppressScrollOnNewData: true,
            columnDefs: this.columnDefs,
            rowData: this.rows,
            rowHeight: this.agGridService.getRowHeight(),
            headerHeight: this.isPhone ? 0 : 40,
            rowSelection: this.hitlistconfig.suppressMultiselect ? "single" : "multiple",
            suppressContextMenu: true,
            suppressAsyncEvents: true,
            suppressNoRowsOverlay: true,
            suppressHorizontalScroll: false,
            suppressDragLeaveHidesColumns: true,
            suppressPropertyNamesCheck: true,
            rowBuffer: 40,
            onRowClicked: this.onRowClicked,
            onRowDoubleClicked: this.hitlistconfig.onDoubleClick ? this.hitlistconfig.onDoubleClick : this.hitListService.onRowDoubleClicked,
            onCellContextMenu: this.onRightClick,
            onRowSelected: this.onRowSelected,
            onGridReady: this.onGridReady,
            onColumnResized: this.onColumnResized,
            onColumnRowGroupChanged: this.onColumnRowGroupChanged,
            onRowGroupOpened: this.onRowGroupOpened,
            onGridSizeChanged: this.sizeToFit,
            onSortChanged: this.onSortChanged,
            icons: {
                // TODO use <eob-icon>
                groupExpanded: "<i class=\"icon-16-einklappen\" style=\"width: 16px;margin-bottom: 4px;\"></i>",
                groupContracted: "<i class=\"icon-16-ausklappen\" style=\"width: 16px;margin-bottom: 4px;\"></i>"
            },
            localeText: {
                rowGroupColumnsEmptyMessage
            },
            defaultGroupSortComparator: (nodeA, nodeB) => {
                const sortDir: string = nodeA.rowGroupColumn.getSort();
                const column: ColumnDefinition = this.columnDefs.find(col => (nodeA.field === col.field));
                const multiplyBy: number = (sortDir == void 0 || sortDir == "asc") ? 1 : -1;

                switch (column.colType) {
                    case "DECIMAL":
                        return (this.sortService.sortByNumber(nodeA.key.toLowerCase(), nodeB.key.toLowerCase()) * multiplyBy);
                    case "DATETIME":
                        return (this.sortService.sortByDatetime(nodeA.key.toLowerCase(), nodeB.key.toLowerCase()) * multiplyBy);
                    default :
                        return (this.sortService.sortFieldValueByText(nodeA.key.toLowerCase(), nodeB.key.toLowerCase()) * multiplyBy);
                }
            }
        };
        this.headerIdKey = this.hitlistconfig.headerIdKey;

        this.subscription.add(this.messageService.subscribe(HitlistEvent.UPDATE_VIEWER, (item: DmsDocumentModel) => {
            this.hitListService.updateViewer(item, this.hitlistconfig);
        }));

        this.hitListService.attachComparators(this.columnDefs);

        if (this.$state.current.name == "folder") {
            const locationTypeConfig: TodoLocationTypeConfig = this.cacheManagerService.objectTypes.getById(this.$location.search().currentTypeId).model.config;
            const locationConfig = this.cacheManagerService.dmsDocuments.getById(this.$location.search().currentId).model;
            this.showAddButton = this.clientService.isOnline() && locationConfig.rights.objExport && locationTypeConfig.insertableTypes.length;

            setTimeout(() => {
                this.updateGridContainer();
            }, 500);
        }

        if (this.$state.current.name == "hitlist.offlineObjects" && (this.contextdata || {}).context != "modalHitlist") {
            let syncColumnField: string;

            if (this.hitlistconfig.columns.find(col => (col.internalName === "staticColSyncStatus" || this.isPhone && col.field === "f1"))) {
                // add the current sync status initially
                const initSyncColumn = async (newRows, colDefs) => {
                    if (colDefs) {
                        syncColumnField = colDefs.find(col => (col.internalName === "staticColSyncStatus" || this.isPhone && col.field === "f1")).field;
                    }

                    if (newRows) {
                        const syncStatus: Map<string, OfflineObjectSyncStatus> = await this.offlineCacheService.getCacheProgresses(newRows.map(row => row.osid));
                        newRows.forEach(row => row[syncColumnField].value = syncStatus.get(row.osid));
                    }
                };

                this.refreshGridContent = async (newRows, colDefs) => {
                    await initSyncColumn(newRows, colDefs);
                    this._refreshGridContent(newRows, colDefs);
                };

                await initSyncColumn(this.hitlistconfig.rows, this.hitlistconfig.columns);

                this.gridOptions.api.refreshCells();
                this.hitlistconfig.api.resort();
                this.changeDetection.detectChanges();
            }

            // update the sync status on change
            this.subscription.add(this.messageService.subscribe(OfflineSynchronizationMessage.OBJECT_SYNC_STATUS_CHANGED, ({osid, percentage, failedCount}) => {
                const row: TodoListEntry = this.hitlistconfig.rows.filter(entry => entry.osid == osid)[0];

                if (row === undefined) {
                    return;
                }

                const cell: GridCellContent = row[syncColumnField];
                cell.value = cell.value || {};

                // refresh the affected cell with the new value
                if (cell.value.percentage !== percentage || cell.value.failedCount !== failedCount) {
                    cell.value = {percentage, failedCount};
                    this.gridOptions.api.refreshCells();
                    this.changeDetection.detectChanges();

                    if (this.gridOptions.columnApi.getColumnState().find(sort => sort.colId == syncColumnField)) {
                        this.hitlistconfig.api.resort();
                    }
                }
            }));
        }

        this.subscription.add(this.messageService.subscribe(HitlistEvent.RESTORE_SELECTION, (payload: HitlistConfig) => {
            this.restoreSelection(payload);
        }));

        this.subscription.add(this.messageService.subscribe(HitlistEvent.UPDATE_GRID_CONTAINER, () => {
            this.updateGridContainer();
        }));

        this.subscription.add(this.messageService.subscribe("set.result.list.selection", () => {
            this.hitListService.saveSelection(this.gridOptions, this.$stateParams.state, this.hitlistconfig.filter);
        }));

        this.subscription.add(this.messageService.subscribe(HitlistEvent.REFRESH_GROUPING_TAGS, (payload: TodoGridNodeData) => {
            if (!this.headerDataId || this.headerDataId != payload.data[this.headerIdKey]) {
                this.headerDataId = payload.data[this.headerIdKey];

                this.gridOptions?.api.refreshHeader();
                this.refreshGroupingTags(payload);
            }
        }));

        this.subscription.add(this.messageService.subscribe(HitlistEvent.ITEM_ADDED, (payload: TodoItemAddedData) => {
            if (this.hitlistconfig.context == payload.context) {
                this.hitListService.attachComparators(payload.columns);
                this.refreshGridContent(payload.rows, payload.columns);
            }
        }));

        this.subscription.add(this.messageService.subscribe(HitlistEvent.UPDATE_ROWS, (payload: TodoUpdateRowsData) => {
            if (payload.context == this.hitlistconfig.context) {
                this.updateRows(payload.config, payload.isGridContentChange, payload.keepViewer, payload.executeInstantly);
            }
        }));

        this.subscription.add(this.messageService.subscribe(HitlistEvent.UPDATE_FILTER_QUERY, (filter: string) => {
            if (this.gridOptions.api) {
                this.hitlistconfig.filter = filter;
                this.gridOptions.api.setQuickFilter(filter);
            }
        }));

        this.subscription.add(this.messageService.subscribe(DmsMessageType.DMSOBJECT_DELETED, (removedIds: string[]) => {
            this.hitListService.removeDeletedItems(removedIds, this.gridOptions);
        }));

        this.messageService.broadcast("hitlist.ready");
    }

    private _refreshGridContent = (rows: RowNode[], colDefs: ColumnDefinition[]): void => {
        if (this.gridOptions.api == void 0) {
            return;
        }

        this.gridOptions.api.setRowData(rows);

        this.hitlistconfig.columns = this.columnDefs = colDefs;
        this.hitlistconfig.rows = this.rows = rows;

        this.gridOptions.rowData = this.rows;

        if (!this.isPhone) {
            this.gridOptions.api.setColumnDefs(colDefs);
            this.sizeToFit();
            this.hitListApiService.setHitListSettings(this.hitlistconfig);

            const storedParams: StoredParams = this.hitListApiService.rememberGroupExpansion(this.gridOptions);
            this.hitListApiService.restoreGroupExpansion(storedParams, this.hitlistconfig);
        }

        this.isEmptyGrid = this.rows.length == 0;
        this.changeDetection.detectChanges();
        this.initEmptyPlaceholderString();
    };

    private onRowSelected = (rowNode: RowSelectedEvent): void => {
        if (!this.hitlistconfig.suppressStateUpdate) {
            this.hitListService.saveSelection(this.gridOptions, this.stateId, this.hitlistconfig.filter);
        }

        if (rowNode.node.data && this.lastNodeOsid && rowNode.node.data.osid == this.lastNodeOsid && this.lastNodeSelectionStatus == rowNode.node.isSelected()) {
            return;
        }

        this.lastNodeOsid = rowNode.node.data ? rowNode.node.data.osid : null;
        this.lastNodeSelectionStatus = rowNode.node.isSelected();
        this.updateRowsCount(rowNode.node);
    };

    private onRowClicked = (params: RowClickedEvent): void => {
        this.selectedRowOsid = params.data ? params.data.osid : null;
        this.hitListService.onRowClicked(params, this.hitlistconfig);
    };

    private onRightClick = (params: CellContextMenuEvent): void => {
        this.hitListService.onRightClick(params, this.hitlistconfig);
    };

    private onGridReady = (): void => {
        this.columnsResized = false;

        const config: TodoHitlistStateConfig = this.stateHistoryManager.getCurrentConfig();

        this.hitlistconfig.filter = config.filter == void 0 ? "" : config.filter;
        this.hitlistconfig.api = {
            addItem: this.addItem,
            deleteItem: this.deleteItem,
            updateRows: (newHitListConfig, isGridContentChange, keepViewer, executeInstantly) => this.updateRows(newHitListConfig, isGridContentChange, keepViewer, executeInstantly),
            resetGrid: this.resetGrid,
            selectItems: this.selectItems,
            deselectAll: this.deselectAll,
            selectFirstItem: this.selectFirstItem,
            getEventDate: this.hitListApiService.getEventDate,
            getRows: () => this.hitlistconfig.rows,
            getColumns: () => this.hitlistconfig.columns,
            getSelectedRows: () => this.gridOptions.api == void 0 ? [] : this.gridOptions.api.getSelectedRows(),
            getGridOptions: () => this.gridOptions,
            resort: () => {
                this.hitListApiService.setSortModel(this.gridOptions, this.gridOptions.columnApi.getColumnState());
            }
        };

        this.hitListApiService.setHitListSettings(this.hitlistconfig);

        // initial visualization
        // check if there is an existing config if not, default
        if (!this.hitlistconfig.suppressStateUpdate && config != void 0) {
            const emptySettingWidth: boolean = this.hitListSettings.width == void 0 || Object.keys(this.hitListSettings.width).length == 0;

            if ((!config.columnWidth &&
                (emptySettingWidth || this.hitlistconfig.columns[this.hitlistconfig.columns.length - 1]?.width !== void 0))
                || this.isPhone) {
                this.sizeToFit();
            } else {
                this.columnsResized = true;
            }
            this.restoreSelection(this.hitlistconfig.selectedItems ? this.hitlistconfig : config, true);
        } else if (!this.hitlistconfig.suppressSelectionOnInit) {
            this.selectFirstItem();
        }

        if (this.$state.current.name == "folder" || this.$state.current.name == "workflow") {
            const gridViewport: HTMLElement = this.el.nativeElement.querySelector(".ag-body-viewport");

            if (this.clientService.isiOs()) {
                this.touchHandlerService.bindTouchEvents(angular.element(gridViewport));
            }

            gridViewport.addEventListener("contextmenu", (event) => this.gridViewportContextmenu(event));

            this.el.nativeElement.querySelector(".empty-hitlist-placeholder").addEventListener("contextmenu", (event) => this.gridViewportContextmenu(event));
        }
    };

    private sizeToFit = (): void => {
        if (!this.columnsResized) {
            if (this.gridOptions.api != void 0) {
                this.gridOptions.api.sizeColumnsToFit();
            }
            this.columnsResized = false;
        } else {
            this.extendLastCol(true);
        }
    };

    private onSortChanged = (): void => {
        if (this.canSaveHitlistSettings()) {
            this.hitlistConfigService.saveSorting(this.hitlistconfig.api, this.$stateParams.state);
        }
    };

    private canSaveHitlistSettings = (): boolean => this.isInitializationDone && !this.toolService.startsWith(this.hitlistconfig.context, "wfFileArea");

    private onColumnResized = (event: ColumnResizedEvent, calculationDone?: boolean, suppressAutoSave?: boolean): void => {
        if (this.isInitializationDone) {
            this.columnsResized = true;
        }

        clearTimeout(this.resizeDebounce);

        this.resizeDebounce = setTimeout(() => {
            try {
                if (this.columnsResized && this.isInitializationDone) {

                    if (!suppressAutoSave && this.canSaveHitlistSettings()) {
                        this.hitlistConfigService.saveColWidth(this.hitlistconfig.api, this.$stateParams.state);
                    }

                    if (!calculationDone) {
                        this.extendLastCol(true);
                    }
                }
            } catch (err) {
                console.warn("Error in resizeDebounce: ", err);
            }
        }, 250);
    };

    private updateGridContainer(): void {
        const gridViewport: HTMLElement = this.el.nativeElement.querySelector(".ag-center-cols-container");
        const bodyViewport: HTMLElement = this.el.nativeElement.querySelector(".ag-body-viewport");

        if (gridViewport.scrollHeight > bodyViewport.offsetHeight) {
            this.renderer.addClass(this.el.nativeElement.querySelector(".ag-body-viewport"), "scrollbar");
        } else {
            this.renderer.removeClass(this.el.nativeElement.querySelector(".ag-body-viewport"), "scrollbar");
        }
    }

    private initEmptyPlaceholderString(): void {
        this.emptyPlaceholderString = this.translateFn(this.hitListService.getPlaceHolderString());
    }

    private gridViewportContextmenu(event: Event): void {
        event.preventDefault();

        if (this.$state.current.name == "folder") {
            this.messageService.broadcast(HitlistEvent.SHOW_EMPTY_SPACE_CONTEXTMENU, event);
        } else {
            this.hitlistconfig.contextData.title = this.hitlistconfig.contextData.context == "wfFileAreaWorkFiles" ?
                this.translateFn("eob.workflow.files.work.area.title") : this.translateFn("eob.workflow.files.info.area.title");

            this.messageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                items: [],
                event,
                contextData: this.hitlistconfig.contextData
            });
        }
    }
}
