import {NgModule, Optional, SkipSelf} from "@angular/core";
import {ajsServiceProviders} from "ROOT_PATH/eob-core/ajs.upgraded.providers";
import {ProgressbarService} from "SERVICES_PATH/eob.progressbar.srv";
import {BackendModule} from "CORE_PATH/backend/backend.module";

/**
 * The module for core services, that are needed by several other modules.
 */
@NgModule({
    declarations: [],
    imports: [BackendModule],
    providers: [...ajsServiceProviders, ProgressbarService]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() serviceModule: CoreModule) {
        if(serviceModule) {
            throw new Error("Module is not to be used standalone");
        }
    }
}
