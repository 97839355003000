import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output} from "@angular/core";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {TodoCacheManagerService, TodoEnvironmentService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {Subscription} from "rxjs";
import {ContentDashletService} from "../../dashlet-services/content-dashlet.service";
import {DetailsDashletService} from "MODULES_PATH/dashlet/dashlet-services/details-dashlet.service";
import {O365DashletService} from "MODULES_PATH/dashlet/dashlet-services/o365-dashlet.service.service";
import {CustomDashletService} from "MODULES_PATH/dashlet/dashlet-services/custom-dashlet.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {DashletKebabMenuItem} from "../eob-dashlet-kebab-menu/eob-dashlet-kebab-menu.component";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import {Connection} from "ENUMS_PATH/connection.enum";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {CustomDashletInitEnvironmentProperties} from "MODULES_PATH/dashlet/interfaces/custom-dashlet-init-properties.interface";
import {DashletHandler} from "MODULES_PATH/dashlet/interfaces/dashlet-handler.interface";
import {CustomDashletConfig} from "MODULES_PATH/dashlet/interfaces/custom-dashlet-config.interface";
import {CustomDashletEvent} from "MODULES_PATH/dashlet/enums/custom-dashlet-event.enum";
import {TranslateFnType} from "CLIENT_PATH/custom.types";

@Component({
    selector: "eob-dashlet-container",
    templateUrl: "./eob-dashlet-container.component.html",
    styleUrls: ["./eob-dashlet-container.component.scss"]
})
export class EobDashletContainerComponent implements OnInit, OnDestroy {
    private readonly DEFAULT_DASHLET: string = "content";
    private readonly translateFn: TranslateFnType;

    @Output() activeDashletChanged: EventEmitter<string> = new EventEmitter<string>();

    isChanging: boolean = false;
    activeDashletId: string = this.DEFAULT_DASHLET;
    lastDashletId: string = null;
    currentId: number = null;
    selectedOsids: number[] = [];
    hasContent: boolean = true;

    customDashlets: CustomDashletConfig[] = [];
    showCustomDashlets: boolean = this.customDashlets.length > 0;
    dashletKebabMenuItems: DashletKebabMenuItem[];

    activeCustomDashletTitle: string = "";
    dashletInitProperties: CustomDashletInitEnvironmentProperties;

    useDocumentViewer: boolean = this.environmentService.useDocumentViewer();
    isO365Enabled = this.environmentService.isO365Enabled();
    showO365ViewerEnabled: boolean;
    language: string = null;
    isMobile: boolean = this.clientService.isPhoneOrTablet();
    isOnline: boolean = this.clientService.isOnline();

    dashlets: Record<string, DashletHandler> = {
        content: {currentOsid: undefined, api: this.contentDashletService},
        details: {currentOsid: undefined, api: this.detailsDashletService},
        o365: {currentOsid: undefined, api: this.o365DashletService}
    };

    private subscription: Subscription = new Subscription();
    private stateContext: string = "";

    // eslint-disable-next-line max-params
    constructor(@Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("$filter") $filter: ng.IFilterService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                public viewerService: ViewerService,
                private messageService: MessageService,
                private clientService: ClientService,
                private iconService: IconService,
                private el: ElementRef,
                private contentDashletService: ContentDashletService,
                private detailsDashletService: DetailsDashletService,
                private o365DashletService: O365DashletService,
                private customDashletService: CustomDashletService,
                private cdRef: ChangeDetectorRef,
                private toolService: ToolService) {
        this.translateFn = $filter("translate");
        this.clientService.registerConnectivityChangeHandler(this.onConnectivityChanged);

        window.addEventListener("goto-custom-dashlet", e => {
            this.goTo((e as CustomEvent).detail);
        });
    }

    ngOnInit(): void {
        this.initCustomDashlets();

        this.initLastActiveDashlet();
    }

    initCustomDashlets(): void {
        if (this.clientService.isPhoneOrTablet()) {
            return;
        }

        if (!this.environmentService.env.dashlets?.length) {
            console.info("dashlets empty");
            return;
        }

        this.customDashlets = this.filterDashletsByObjecttype(false);

        this.language = this.environmentService.getLanguage();
        this.dashletInitProperties = {
            ...this.customDashletService.getDashletInitProperties()
        };
        this.dashletKebabMenuItems = this.getDashletKebabMenuItems();
        this.stateContext = this.customDashletService.getStateContext();
    }

    updateViewer(osid?: number, dmsDocument?: DmsDocument, dashletId?: string, selectedOsids?: number[]): void {
        this.selectedOsids = selectedOsids || this.selectedOsids;
        let infoDmsDocument: DmsDocument = dmsDocument;
        if(!infoDmsDocument) {
            infoDmsDocument = this.cacheManagerService.dmsDocuments.getById(osid);
        }
        this.showO365ViewerEnabled = this.isO365Enabled && infoDmsDocument?.model.subType == 4;
        this.filterDashletsByObjecttype(true);

        this.currentId = osid || this.currentId;
        dashletId = dashletId || this.activeDashletId;

        this.checkCustomDashlets();

        const activeDashlet: DashletHandler = this.dashlets[dashletId];

        if (activeDashlet && osid) {
            if ((activeDashlet.currentOsid != osid
                || (activeDashlet.currentSelectedOsids ? !this.toolService.compareArrays(activeDashlet.currentSelectedOsids, this.selectedOsids) : false))) {
                activeDashlet.currentOsid = osid;
                activeDashlet.currentSelectedOsids = activeDashlet.currentSelectedOsids ? this.selectedOsids : undefined;

                activeDashlet.api.update(osid, dmsDocument);
            } else if (dashletId.includes("customDashlet-") && this.stateContext !== this.customDashletService.getStateContext()) {
                this.stateContext = this.customDashletService.getStateContext();
                this.messageService.broadcast(CustomDashletEvent.UPDATE_CUSTOM_DASHLET_PROPERTIES, {});
            }
        } else {
            this.openDashlet(dashletId);
        }
    }

    refreshViewer(osid: number = this.currentId, dashletIds?: string[], keepCache: boolean = true): void {
        dashletIds = dashletIds || Object.keys(this.dashlets);

        for (const dashletId of dashletIds) {
            const dashlet: DashletHandler = this.dashlets[dashletId];

            if (dashlet?.currentOsid == osid) {
                dashlet.api.refresh(osid, keepCache);
            }
        }
    }

    clearViewer(): void {
        this.currentId = undefined;
        this.selectedOsids = undefined;
        this.checkCustomDashlets();

        for (const dashlet of Object.values(this.dashlets)) {
            dashlet.currentOsid = undefined;
            dashlet.currentSelectedOsids = dashlet.currentSelectedOsids ? [] : undefined;
            dashlet.api.clear();
        }
    }

    sendDashletEvent(dashletId: string, eventKey: string, data: unknown): void {
        const dashlet: DashletHandler = this.dashlets[dashletId];
        dashlet?.api.sendEvent(eventKey, data);
    }

    goTo(dashletId: string, osid: number = this.currentId): void {
        if (this.activeDashletId !== dashletId) {
            this.isChanging = true;

            this.updateViewer(osid, undefined, dashletId);
            this.openDashlet(dashletId);

            // if user opens indexdata in a new tab, the last active dashlet must be shown,
            // so we save dashletId or custom dashlet title in the local storage to restore the last active dashlet
            if (dashletId.includes("customDashlet-")) {
                this.viewerService.setLastActiveDashlet(this.customDashlets.find(cd => cd.id == dashletId).title_EN);
            } else {
                this.viewerService.setLastActiveDashlet(dashletId);
            }

            this.setDashletContentVisible();
        }
    }

    openDashlet(dashletId?: string): void {
        if (dashletId && (dashletId == this.activeDashletId || !this.dashlets[dashletId])) {
            return;
        }

        this.isChanging = true;

        this.activeDashletId = this.lastDashletId = dashletId || this.activeDashletId || this.DEFAULT_DASHLET;

        if (this.activeDashletId.includes("customDashlet-")) {
            this.setActiveCustomDashletTitle();
        }

        this.activeDashletChanged.emit(this.activeDashletId);

        this.setDashletContentVisible();
    }

    getDashletKebabMenuItems(): DashletKebabMenuItem[] {
        const items: DashletKebabMenuItem[] = [];
        this.customDashlets.forEach(dashlet => {
            items.push({
                name: dashlet.id,
                icon: this.iconService.hasIcon(`${dashlet.iconId}`) ? `custom-icon-${dashlet.iconId}` : "dashlet-default",
                title: this.getCustomDashletTitle(dashlet.id)
            });
        });

        return items;
    }

    setActiveCustomDashletTitle(): void {
        this.activeCustomDashletTitle = this.getCustomDashletTitle(this.activeDashletId);
    }

    getCustomDashletTitle(dashletId: string): string {
        const dashlet: CustomDashletConfig = this.customDashlets.find(cd => cd.id == dashletId);
        if (!dashlet) {
            return null;
        }

        let title: string;
        switch (this.language) {
            case "en":
                title = dashlet.title_EN;
                break;
            case "fr":
                title = dashlet.title_FR;
                break;
            default:
                title = dashlet.title_DE;
        }

        return title || this.translateFn("eob.user-content.header.title");
    }

    checkCustomDashlets(): void {
        this.showCustomDashlets = !!this.currentId;
    }

    /** Hide custom dashlets, if offline */
    onConnectivityChanged = (currentConnectivity: Connection): void => {
        this.showCustomDashlets = this.customDashlets.length > 0 && currentConnectivity !== Connection.NONE;
    };

    filterDashletsByObjecttype(update: boolean): CustomDashletConfig[] {
        const filteredDashlets: CustomDashletConfig[] = this.environmentService.getObjecttypeFilteredDashlets(this.selectedOsids);

        this.customDashlets = filteredDashlets;

        this.customDashlets.forEach((dashlet: CustomDashletConfig) => {
            if (!this.dashlets[dashlet.id]) {
                this.dashlets[dashlet.id] = {
                    currentOsid: undefined,
                    currentSelectedOsids: [],
                    api: this.customDashletService
                };
            }
        });

        if (this.activeDashletId.includes("customDashlet-") && !this.customDashlets.find(dashlet => dashlet.id == this.activeDashletId)) {
            this.openDashlet(this.DEFAULT_DASHLET);
        }

        if (update) {
            this.dashletKebabMenuItems = this.getDashletKebabMenuItems();
        }

        return filteredDashlets;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private initLastActiveDashlet(): void {
        const savedActiveDashlet: string = this.stateHistoryManager.getCurrentConfig()?.activeDashlet;

        if (savedActiveDashlet) {
            if (this.dashlets[savedActiveDashlet]) {
                this.goTo(savedActiveDashlet);
            } else if (this.customDashlets.find(cd => cd.title_EN == savedActiveDashlet)) {
                this.goTo(this.customDashlets.find(cd => cd.title_EN == savedActiveDashlet).id);
            } else {
                this.goTo(this.DEFAULT_DASHLET);
            }
        }
    }

    private setDashletContentVisible(): void {
        // display the dashlet content again once the dashlet was able to start updating visually -  e.g. the pdfjs displays a loading circle
        setTimeout(() => {
            this.isChanging = false;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!(this.cdRef as any).destroyed) {
                this.cdRef.detectChanges(); // for the image-based documentviewer
            }
        }, 0);
    }
}
