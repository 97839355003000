import {Inject, Injectable} from "@angular/core";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {OfficeBackendService} from "MODULES_PATH/wopi/services/eob.office.backend.service";
import {BehaviorSubject} from "rxjs";
import {StateParams, StateService} from "@uirouter/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

@Injectable({providedIn: "root"})
export class WopiHostService {
    refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isO365File = false;
    converting = false;
    constructor(@Inject("$state") private $state: StateService, @Inject("$stateParams") private $stateParams: StateParams, @Inject("officeBackendService") public officeBackendService: OfficeBackendService, @Inject("$injector") private $injector: angular.auto.IInjectorService,
                private clientService: ClientService) {
    }

    async initDMSObjectAsync(osid: string, fileId: string, dmsDocument: DmsDocument, language: string, popupViewer?: any): Promise<any> {
        if (!osid) {
            return;
        }

        this.officeBackendService.osId = osid;
        this.officeBackendService.dmsDocumet = dmsDocument;
        this.officeBackendService.language = language;
        const dvDocumentService: any = this.$injector.get("dvDocumentService");
        const dvDmsObject: DmsDocumentModel = await dvDocumentService.loadAsync(osid, true, null);
        const cacheManagerService: any = this.$injector.get("cacheManagerService");
        const environmentService: TodoEnvironmentService = this.$injector.get("environmentService");
        const mainType: any = cacheManagerService.objectTypes.getById(dvDmsObject.objectTypeId).model.config.mainType;
        const allowedExtensions: any = environmentService.getAllowedFileExtByMainType(mainType).toLowerCase().split(",").map(String);
        environmentService.isO365Enabled(); // TODO ???
        this.prepare(osid, fileId, dvDmsObject, allowedExtensions, popupViewer);
    }

    async refreshObjectInHitlist(osid: string): Promise<any> {
        const cacheManagerService: any = this.$injector.get("cacheManagerService");
        const dvDocumentService: any = this.$injector.get("dvDocumentService");
        await dvDocumentService.loadAsync(osid, true, null);
        cacheManagerService.dmsDocuments.executeListeners(osid);

    }

    prepare(osid: string, fileId: string, dvDmsObject: DmsDocumentModel, allowedExtensions: any, popupViewer: any): void {
        this.officeBackendService.currentObjectId = osid;
        if (dvDmsObject == void 0 || (dvDmsObject.fileProperties.mimeTypeGroup !== "POWERPOINT" && dvDmsObject.fileProperties.mimeTypeGroup !== "WORD" && dvDmsObject.fileProperties.mimeTypeGroup !== "EXCEL")) {
            if(!allowedExtensions.includes("pptx") && !allowedExtensions.includes("docx") && !allowedExtensions.includes("xlsx")) {
                this.isO365File = false;
                return;
            }
        }
        this.isO365File = true;
        const prepareId: string = osid;
        let closeUrl: string;
        if (popupViewer && popupViewer !== null) {
            closeUrl = popupViewer.location.href;
        } else {
            closeUrl = window.location.href;
        }

        if (this.clientService.isDesktop()) {
            closeUrl = "https://help.optimal-systems.com/enaio/";
        }
        const baseUrl = this.officeBackendService.profileService.getCurrentBaseUrl();
        const electron = !!window.electron;
        this.officeBackendService.urlSrc = `${baseUrl}/dashlet365/?id=${prepareId}&allowedExtensions=${allowedExtensions}&locale=${this.officeBackendService.generateLanguage()}&closeUrl=${encodeURIComponent(closeUrl)}&electron=${electron}`;
        this.refresh.next(true);

    }
}