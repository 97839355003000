import { Inject, Injectable } from "@angular/core";
import { TranslateFnType } from "CLIENT_PATH/custom.types";
import { EobTimer } from "MODULES_PATH/timer/interfaces/timer.interface";
import { BehaviorSubject, interval, Observable, Subject, Subscription } from "rxjs";
import { finalize, take, map, takeUntil } from "rxjs/operators";
import { ValueUtilsService } from "CORE_PATH/services/utils/value-utils.service";
import { TimerDoneType } from "MODULES_PATH/timer/enums/timer-done-type";
import { ClientService } from "CORE_PATH/services/client/client.service";

@Injectable({
    providedIn: "root"
})
export class TimerService {
    countDown$: BehaviorSubject<EobTimer> = new BehaviorSubject(null);
    done$: Subject<string> = new Subject();
    subs: Subscription = new Subscription();
    private readonly translate: TranslateFnType;
    private running = false;

    constructor(private valueUtilsService: ValueUtilsService,
                private clientService: ClientService,
                @Inject("modalDialogService") private modalDialogService: any,
                @Inject("stateHistoryManager") private stateHistoryManager: any,
                @Inject("$filter") $filter: ng.IFilterService
    ) {
        this.translate = $filter("translate") ;
    }

    start(seconds: number): void {
        if (!seconds) {
            return;
        }

        if (!this.subs || this.subs.closed) {
            this.subs = new Subscription();
        }

        const countDown$: Observable<EobTimer> = interval(1000).pipe(
            take(seconds),
            // tap(val => console.log("current timer value", val)),
            map(() => {
                sessionStorage.setItem("remainingTimerTime", `${seconds}`);
                if (this.clientService.isDesktop()) {
                    sessionStorage.setItem("reusedLocationTab", `${window.electron.getIsReusedLocationTab()}`);
                    window.electron.storeSessionStorage();
                }
                return this.valueUtilsService.convertMiliseconds((--seconds) * 1000);
            }),
            finalize(() => {
                if (seconds === 0) {
                    this.unsubscribe();
                }
            })
        );

        this.subs.add(countDown$.pipe(takeUntil(this.done$)).subscribe(
            (time: EobTimer) => this.countDown$.next(time),
            (_) => { },
            () => {
                this.running = false;
                sessionStorage.removeItem("remainingTimerTime");
                sessionStorage.removeItem("reusedLocationTab");

                if (this.clientService.isDesktop()) {
                    window.electron.storeSessionStorage();
                }
            })
        );

        this.running = true;
    }

    stop(): void {
        this.subs.unsubscribe();
        this.countDown$.next(null);
    }

    isRunning(): boolean {
        return this.running;
    }

    unsubscribe(): void {
        this.done$.next(TimerDoneType.TIMEOUT);
        this.stop();
    }

    done(): Observable<string> {
        return this.done$;
    }

    // eslint-disable-next-line max-params
    showDoneInfoDialog = async (success: number = 0, max: number = 0, handleRedirection: boolean = true, doneBy: string = TimerDoneType.TIMEOUT, numberOfSkipped?: number) => {
        this.modalDialogService.hideProgressDialog();

        let submessage: string;
        if (success > 0 && max > 0) {
            submessage = this.translate("eob.ems.import.modal.success")
                .replace("%d1", success.toString())
                .replace("%d2", max.toString());
        }

        let message: string, icon: string;
        if (doneBy == TimerDoneType.TIMEOUT) {
            message = this.translate("eob.timer.finished.timeout.modal.message");
            icon = "timeout";
        } else if (doneBy == TimerDoneType.SKIPPED_BY_USER) {
            message = this.translate("eob.modal.deduplication.skip.by.user")
                .replace("%d1", numberOfSkipped.toString())
                .replace("%d2", max.toString());
            icon = "warning";
        } else {
            message = this.translate("eob.timer.finished.user.modal.message");
            icon = "warning";
        }

        const dialogPromise: any = this.modalDialogService.infoDialog(
            this.translate("eob.ems.import.modal.progress.title"),
            message, undefined, undefined, "Ok",
            true, { icon, subMsg: submessage });

        try {
            await dialogPromise;

            if (handleRedirection) {
                if (window.electron.getIsReusedLocationTab()) {
                    history.back();
                } else {
                    this.stateHistoryManager.goToDashboardState();
                    window.electron.closeActiveTab();
                }
            }
        } catch (err) {
            console.error(err);
        }
    };
}
