import {createReducer, on} from "@ngrx/store";
import * as PdfjsToolbarActions from "./pdfjs-toolbar.actions";
import {PdfjsToolbarAction } from "./pdfjs-toolbar.actions";
import {PdfjsToolbarState} from "MODULES_PATH/dashlet/interfaces/pdfjs.interface";

export const pdfjsToolbarFeatureKey: string = "pdfjsToolbar";

export const initialState: PdfjsToolbarState = {
    showSearchBar: true,
    showKebab: false,
    showRefresh: true,
    showSideBar: true,
    showZoomOut: true,
    showZoomIn: true,
    showRotateRight: true,
    showRotateLeft: true,
    showAttachments: true,
    showGoToParent: false,
    attachmentsAvailable: false,
    attachmentsOpen: false
};

// eslint-disable-next-line @typescript-eslint/typedef
export const pdfjsToolbarReducer = createReducer(
    initialState,

    on(PdfjsToolbarActions.updatePdfjsToolbar, (state: PdfjsToolbarState, action) =>
         ({...state,
        [action.pdfjsToolbarItemType]: action.payload})
    ),
);

