import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Renderer2
} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import * as dayjs from "dayjs";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {FieldAddon, FieldDataType} from "ENUMS_PATH/field.enum";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import * as customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

@Component({
    selector: "eob-modal-date-time-picker",
    templateUrl: "./eob-modal-date-time-picker.component.html",
    styleUrls: ["./eob-modal-date-time-picker.component.scss"]
})

export class EobModalDateTimePickerComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() field: any;
    @Input() formhelper: any;

    readonly isPhone: boolean;
    readonly isPhoneOrTablet: boolean;

    fieldType: string;
    isSearch: boolean;
    isTime: boolean;
    isRangeInputActive: boolean;
    isRangeActive: boolean;
    isDateActive: boolean;

    private modalDialog: HTMLElement;
    private container: HTMLElement;
    private pickerContainer: HTMLElement;
    private dateTimeValueInput: HTMLInputElement;
    private dateTimeInput: HTMLInputElement;
    private rangeInput: HTMLInputElement;
    private rangeStartTodayButton: HTMLElement;
    private rangeEndTodayButton: HTMLElement;
    private dateRangeButton: HTMLElement;
    private timeRangeButton: HTMLElement;
    private datePicker: HTMLElement;
    private timePicker: HTMLElement;
    private activeInput: HTMLInputElement;
    private toolbar: HTMLElement;
    private confirmButton: HTMLElement;

    private hoursListHtml: HTMLElement;
    private hoursListJquery: JQuery;
    private minutesListHtml: HTMLElement;
    private minutesListJQuery: JQuery;

    private xdsoftDateTimePicker: HTMLElement;
    private xdsoftDateTimePickerInterface: any;

    private dateFormat: string;
    private dateTimeValue: string;
    private rangeValue: string;

    private readonly translateFn: TranslateFnType;
    private readonly yearStart: number = 1900;
    private readonly yearEnd: number = 2100;
    private readonly timeRegEx: string = "^(?:[0-1][0-9]|2[0-4]|[0-9])\\:[0-5][0-9](?:\\:[0-5][0-9])?$";
    private readonly allowedKeys: number[] = [8, 16, 32, 35, 36, 37, 39, 46, 48, 49, 50, 51, 52,
        53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 119, 190, 226];

    constructor(@Inject("$filter") private $filter: ng.IFilterService,
                private clientService: ClientService,
                private notificationsService: NotificationsService,
                private valueUtilsService: ValueUtilsService,
                @Inject(forwardRef(() => "environmentService")) private environmentService: TodoEnvironmentService,
                @Inject(forwardRef(() => "layoutManagerService")) private layoutManagerService: any,
                private renderer: Renderer2,
                private el: ElementRef<HTMLElement>,
                private messageService: MessageService) {
        this.translateFn = this.$filter("translate");
        this.isPhone = this.clientService.isPhone();
        this.isPhoneOrTablet = this.clientService.isPhoneOrTablet();
        this.isTime = this.isPhone; // same layout as the phone version
        this.isRangeInputActive = false;
        this.isRangeActive = false;
        this.isDateActive = true;
    }

    ngOnInit(): void {
        this.fieldType = this.field.model.type;
        if (this.fieldType != FieldDataType.DATE && this.fieldType != FieldDataType.DATETIME && this.fieldType != FieldDataType.TIME) {
            this.fieldType = this.field.model.addon;
        }

        this.isSearch = this.formhelper.isSearch;
    }

    ngAfterViewInit(): void {
        this.container = this.el.nativeElement.querySelector(".container");
        this.pickerContainer = this.el.nativeElement.querySelector(".date-time-picker-container");
        this.dateTimeValueInput = this.container.querySelector(".date-time-value-input");
        this.dateTimeInput = this.container.querySelector(".date-time-input-modal");
        this.activeInput = this.dateTimeInput;
        this.rangeInput = this.container.querySelector(".range-input-modal");
        this.rangeStartTodayButton = this.el.nativeElement.querySelector(".today-button.range-start");
        this.rangeEndTodayButton = this.el.nativeElement.querySelector(".today-button.range-end");
        this.dateRangeButton = this.el.nativeElement.querySelector(".date-range-button");
        this.timeRangeButton = this.el.nativeElement.querySelector(".time-range-button");
        this.datePicker = this.el.nativeElement.querySelector(".date-picker");
        this.timePicker = this.el.nativeElement.querySelector(".time-picker");
        this.confirmButton = this.el.nativeElement.querySelector(".confirm-button");

        const currentDay: number = new Date().getDate();
        const rangeStartCurrentDay: HTMLElement = this.el.nativeElement.querySelector(".range-start-current-day");
        rangeStartCurrentDay.innerText = `${currentDay}`;
        const rangeEndCurrentDay: HTMLElement = this.el.nativeElement.querySelector(".range-end-current-day");
        rangeEndCurrentDay.innerText = `${currentDay}`;

        if (this.fieldType == "time") {
            this.isTime = true;
        } else if (this.isPhone && !(this.fieldType == "date" || this.fieldType == "time")) {
            this.renderer.setStyle(this.timePicker, "display", "none");
        }

        let theField: any = this.field;

        if (theField.value == void 0) {
            theField = this.formhelper.getFieldByInternal(this.field.model?.internal);
        }

        const valueParts: string[] = theField.value.split("-");
        let dateTimeVal: string = valueParts[0].trim();
        let rangeVal: string = theField.to != void 0 ? theField.to : valueParts[1];

        dateTimeVal = this.removeSeconds(dateTimeVal);
        rangeVal = this.removeSeconds(rangeVal);

        this.dateTimeValue = `${dateTimeVal != void 0 && this.isValid(dateTimeVal) ? dateTimeVal : ""}`;
        this.rangeValue = `${rangeVal != void 0 && this.isValid(rangeVal) ? rangeVal : ""}`;

        this.addDatePicker();
        this.addTimePicker();
        this.addPlaceholders();

        if (!this.layoutManagerService.isTouchLayoutActive()) {
            this.dateTimeInput.focus();
        }

        if (this.isPhone) {
            // disable keyboard sliding into view on small phone displays
            this.renderer.setAttribute(this.dateTimeInput, "readonly", "true");
            this.renderer.setAttribute(this.rangeInput, "readonly", "true");
        }

        this.attachListeners();

        setTimeout(() => {
            this.renderer.addClass(this.container, "visible");
            //initial focus on Dialog
            this.modalDialog.focus();
        }, 150);

        // If no date is pre-filled/selected, the current date should be entered directly in the input field.
        if (this.fieldType !== "time" && this.dateTimeInput.value == "") {
            this.xdsoftDateTimePickerInterface.setOptions({value: "now"});
            this.dateTimeInput.value = this.dateTimeValueInput.value;
            this.updateDropDowns();
            this.updateTimePicker();
        }

        if (this.dateTimeInput.value !== "" && (this.fieldType == FieldDataType.TIME || this.fieldType == FieldDataType.DATETIME || this.fieldType == FieldAddon.DATETIME)) {
            setTimeout(() => {
                this.scrollTime();
            }, 150);
        }

        //set initial focus on Confirm Button to receive keyboard and default events at the beginning
        setTimeout(() => this.confirmButton.focus(), 0);
    }

    ngOnDestroy(): void {
        this.close();
    }

    clear(): void {
        this.field.api.setValue("", true);
        this.close();
    }

    close(): void {
        if (this.xdsoftDateTimePicker != void 0) {
            this.renderer.removeClass(this.xdsoftDateTimePicker, "visible");
        }

        const dateTimeValueInputEl: JQuery = $(this.dateTimeValueInput);
        (dateTimeValueInputEl as any).datetimepicker("destroy");

        this.messageService.broadcast(ModalEvents.DESTROY);
    }

    confirm(): void {
        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            return;
        }

        let value: string;
        const rangeStart: string = this.getCompleteValue(this.dateTimeInput.value);
        const rangeEnd: string = this.getCompleteValue(this.rangeInput.value);

        if (this.isRangeActive) {
            if (rangeStart != "" && rangeEnd != "") {
                // written this way, IntelliJ IDEA 2020.1 complains about "identical branches" ?!
                // -> value = rangeStart > rangeEnd ? `${rangeEnd} - ${rangeStart}` : `${rangeStart} - ${rangeEnd}`;
                const v1 = `${rangeEnd} - ${rangeStart}`;
                const v2 = `${rangeStart} - ${rangeEnd}`;
                value = rangeStart > rangeEnd ? v1 : v2;
            } else if (rangeStart == "") {
                value = `<=${rangeEnd}`;
            } else if (rangeEnd == "") {
                value = `>=${rangeStart}`;
            }
        } else {
            value = rangeStart;
        }

        this.field.api.getElement().value = value;
        this.field.api.setValue(value, true);
        this.close();
    }

    toggleDateTime(isDate: boolean): void {
        if (isDate) {
            this.isDateActive = true;

            this.renderer.setStyle(this.datePicker, "display", "flex");
            this.renderer.setStyle(this.timePicker, "display", "none");
        } else {
            this.isDateActive = false;

            this.renderer.setStyle(this.datePicker, "display", "none");
            this.renderer.setStyle(this.timePicker, "display", "flex");

            this.scrollTime();
        }
    }

    private addDatePicker(): void {
        const language: string = this.environmentService.getLanguage();
        if (this.fieldType == "time") {
            return;
        } else if (this.fieldType == "date") {
            this.dateFormat = language == "de" ? "d.m.Y" : "d/m/Y";
        } else {
            this.dateFormat = language == "de" ? "d.m.Y H:i" : "d/m/Y H:i";
        }

        const config: any = {
            lang: language,
            validateOnBlur: false,
            format: this.dateFormat,
            scrollInput: false,
            timepicker: false,
            value: "",
            opened: true,
            yearStart: this.yearStart,
            yearEnd: this.yearEnd,
            onChangeDateTime: (currentTime: any, input: HTMLInputElement) => {
                this.activeInput.value = input[0].value;
                this.updateTimePicker();
            }
        };

        const dateTimeValueInputEl: JQuery = $(this.dateTimeValueInput);
        const data: any = dateTimeValueInputEl.data();
        if (!data || !data.xdsoft_datetimepicker) {
            (dateTimeValueInputEl as any).datetimepicker(config);
        }

        this.xdsoftDateTimePicker = document.querySelector(".xdsoft_datetimepicker");
        this.renderer.appendChild(this.pickerContainer.querySelector(".date-picker"), this.xdsoftDateTimePicker);
        this.xdsoftDateTimePickerInterface = dateTimeValueInputEl.data().xdsoft_datetimepicker;

        if (this.dateTimeValue && this.dateTimeValue.indexOf("<=") == 0) {
            this.isRangeActive = true;
            this.rangeInput.value = this.dateTimeValue.replace("<=", "").trim();
        } else if (this.dateTimeValue && this.dateTimeValue.indexOf(">=") == 0) {
            this.xdsoftDateTimePickerInterface.setOptions({
                value: this.dateTimeValue.replace(">=", "").trim(),
                format: this.dateFormat
            });
            this.isRangeActive = true;
        } else {
            if (this.dateTimeValue == void 0) {
                this.dateTimeValue = "";
            }

            this.xdsoftDateTimePickerInterface.setOptions({value: this.dateTimeValue.trim(), format: this.dateFormat});

            if (this.rangeValue != void 0 && this.rangeValue != "") {
                this.rangeInput.value = this.rangeValue.trim();
                this.isRangeActive = true;
            } else if (this.dateTimeValue.indexOf("<=") == 0 || this.dateTimeValue.indexOf(">=") == 0) {
                this.isRangeActive = true;
            }
        }

        this.activeInput.value = this.dateTimeValueInput.value;

        if (this.activeInput.classList.contains(".range-input-modal")) {
            this.renderer.addClass(this.xdsoftDateTimePicker, "eob-range-picker");
        } else {
            this.renderer.addClass(this.xdsoftDateTimePicker, "eob-date-picker");
        }

        $(this.xdsoftDateTimePicker).find(".xdsoft_select").hide();
        this.customizeToolbar();
    }

    private addTimePicker(): void {
        this.hoursListHtml = this.pickerContainer.querySelector(".hours-list");
        this.hoursListJquery = $(this.pickerContainer).find(".hours-list");
        this.minutesListHtml = this.pickerContainer.querySelector(".minutes-list");
        this.minutesListJQuery = $(this.pickerContainer).find(".minutes-list");

        if (this.fieldType == "date" || this.hoursListHtml.hasChildNodes()) {
            return;
        }

        for (let i = 0; i <= 23; i++) {
            const value: string = i < 10 ? `0${i}` : i.toString();
            this.appendTimePickerElement(value, this.hoursListHtml, true);
        }

        for (let i = 0; i <= 59; i++) {
            const value: string = i < 10 ? `0${i}` : i.toString();
            this.appendTimePickerElement(value, this.minutesListHtml, false);
        }

        if (this.fieldType == "time") {
            if (this.rangeValue != void 0 && this.rangeValue != "") {
                this.rangeInput.value = this.rangeValue.trim();
                this.dateTimeInput.value = this.dateTimeValue.trim();
                this.isRangeActive = true;
            } else if (this.dateTimeValue.indexOf("<=") == 0) {
                this.rangeInput.value = this.dateTimeValue.replace("<=", "");
                this.isRangeActive = true;
            } else if (this.dateTimeValue.indexOf(">=") == 0) {
                this.rangeInput.value = this.dateTimeValue.replace(">=", "");
                this.isRangeActive = true;
            } else {
                this.dateTimeInput.value = this.dateTimeValue.trim();
            }
        }

        this.updateTimePicker();
    }

    private appendTimePickerElement(value: string, parentElementList: HTMLElement, isHour: boolean = true): void {
        const listEntry: HTMLElement = this.renderer.createElement("li");
        listEntry.innerText = value;
        this.renderer.appendChild(parentElementList, listEntry);
        this.renderer.addClass(listEntry, value);

        listEntry.addEventListener("click", () => {
            this.changeTime(listEntry.innerText, isHour);
            this.updateTimePicker();
        });
    }

    private updateTimePicker(): void {
        let initialHour: string;
        let initialMinute: string;

        const value: string = this.activeInput.value;
        if (value != "") {
            let initialTime: string;

            if (this.fieldType == "time") {
                initialTime = value;
            } else {
                initialTime = value.split(" ")[1];
            }

            if (initialTime == void 0) {
                return;
            }

            if (!new RegExp(this.timeRegEx).test(initialTime)) {
                initialTime = "";
            }

            initialHour = initialTime.split(":")[0];
            initialMinute = initialTime.split(":")[1];

            if (initialTime != "") {
                if (initialHour.length == 1) {
                    initialHour = `0${initialHour}`;
                    this.changeTime(initialHour, true);
                }

                if (initialMinute.length == 1) {
                    initialMinute = `0${initialMinute}`;
                    this.changeTime(initialMinute, false);
                }
            }
        }

        const currentDate: Date = new Date();

        if (value != "" && (initialHour == void 0 || initialHour == "")) {
            initialHour = currentDate.getHours().toString();
            this.changeTime(initialHour, true);
        }

        if (value != "" && (initialMinute == void 0 || initialMinute == "")) {
            initialMinute = "00";
            this.changeTime(initialMinute, false);
        }

        this.hoursListJquery.find(".active").removeClass("active");
        this.minutesListJQuery.find(".active").removeClass("active");

        if (value != "") {
            this.hoursListJquery.find(`.${initialHour}`).addClass("active");
            this.minutesListJQuery.find(`.${initialMinute}`).addClass("active");

            this.scrollTime();
        }
    }

    private scrollTime(): void {
        this.scrollIntoView(this.hoursListJquery.find(".active"), $(this.container).find(".hours .scroll-container"));
        this.scrollIntoView(this.minutesListJQuery.find(".active"), $(this.container).find(".minutes .scroll-container"));
    }

    private removeSeconds(value: string): string {
        if (value == void 0 || value == "") {
            return;
        }

        const parts: string[] = value.split(":");
        if (parts.length > 2) {
            parts.pop();
        }

        return parts.join(":");
    }

    private isValid(value: string): boolean {
        const datePart: string = value.trim().split(" ")[0];
        const timePart: string = value.trim().split(" ")[1];

        if ((this.fieldType == FieldDataType.DATETIME || this.fieldType == FieldAddon.DATETIME) && (!this.isValidDate(datePart) || !this.isValidTime(timePart))) {
            return false;
        } else if (this.fieldType == "date" && !this.isValidDate(datePart)) {
            return false;
        } else if (this.fieldType == "time" && !this.isValidTime(datePart)) {
            return false;
        }

        return true;
    }

    private isValidDate(date: string): boolean {
        const newDate: string = date.replace(/\//g, ".");
        return dayjs(newDate, "DD.MM.YYYY").isValid();
    }

    private isValidTime(time: string): boolean {
        return dayjs(time, ["HH:mm:ss", "HH:mm"]).isValid();
    }

    private addPlaceholders(): void {
        let ft: FieldDataType;
        if (this.fieldType == "date") {
            ft = FieldDataType.DATE;
        } else if (this.fieldType == "time") {
            ft = FieldDataType.TIME;
        } else {
            ft = FieldDataType.DATETIME;
        }

        const placeholder: string = this.valueUtilsService.getDatePlaceholder(ft);
        this.renderer.setAttribute(this.rangeInput, "placeholder", placeholder);
        this.renderer.setAttribute(this.dateTimeInput, "placeholder", placeholder);
    }

    private attachListeners(): void {
        this.rangeInput.addEventListener("focus", () => {
            if (this.activeInput.classList.contains("range-input-modal")) {
                return;
            }

            this.activeInput = this.rangeInput;
            this.isRangeInputActive = true;

            this.setTimePickerInput(this.rangeInput);
        });

        this.dateTimeInput.addEventListener("focus", () => {
            if (this.activeInput.classList.contains("date-time-input-modal")) {
                return;
            }

            this.activeInput = this.dateTimeInput;
            this.isRangeInputActive = false;

            this.setTimePickerInput(this.dateTimeInput);
        });

        [this.dateTimeInput, this.rangeInput].forEach(input => {
            input.addEventListener("keydown", (event: KeyboardEvent) => {
                this.preventIllegalInputs(event);
            });
        });

        this.rangeStartTodayButton.addEventListener("click", () => {
            this.setDateTimeInputValue(this.dateTimeInput);
        });

        this.rangeEndTodayButton.addEventListener("click", () => {
            this.setDateTimeInputValue(this.rangeInput);
        });

        [this.dateRangeButton, this.timeRangeButton].forEach(button => {
            button.addEventListener("click", () => {
                this.toggleRangeButton();
            });
        });

        this.rangeInput.addEventListener("change", () => {
            this.setTimePickerInput(this.rangeInput);
        });

        this.dateTimeInput.addEventListener("change", () => {
            this.setTimePickerInput(this.dateTimeInput);
        });

        // this is needed because we attach some very important classes to the original dropdowns of the addon
        // in the manipulateDropdown function but because the dropdowns are regenerated after most click actions
        // those classes get lost again
        this.container.addEventListener("click", () => {
            setTimeout(() => {
                if (this.fieldType != "time") {
                    this.manipulateDropdown("year", false, true);
                }

                if (this.xdsoftDateTimePicker != void 0) {
                    $(this.xdsoftDateTimePicker).find(".xdsoft_date").bind("click", () => {
                        // This is another ugly hack to make the next/prev buttons for year selection available
                        // on first click after a date was clicked. Seems like the addon is throwing away the
                        // event binding after selecting a date. This double Click trigger triggers the
                        // manipulateDropdown function more securely than just a timeout or direct call of the
                        // manipulateDropdown function here.
                        this.container.click();
                    });
                }
            }, 100);
        });
    }

    private setTimePickerInput(input: HTMLInputElement): void {
        if (this.xdsoftDateTimePickerInterface != void 0) {
            this.xdsoftDateTimePickerInterface.setOptions({
                value: input.value,
                format: this.dateFormat
            });
        }

        this.updateDropDowns();
        this.updateTimePicker();
    }

    private toggleRangeButton(): void {
        this.isRangeActive = !this.isRangeActive;
        this.isRangeInputActive = this.isRangeActive;
        this.activeInput = this.isRangeInputActive ? this.rangeInput : this.dateTimeInput;

        if (this.isPhone || !this.layoutManagerService.isTouchLayoutActive()) {
            setTimeout(() => {
                if (this.isRangeActive) {
                    this.rangeInput.focus();
                } else {
                    this.dateTimeInput.focus();
                }
            }, 100);
        }
    }

    private setDateTimeInputValue(targetInput: HTMLInputElement): void {
        this.xdsoftDateTimePickerInterface.setOptions({value: "now"});

        setTimeout(() => {
            targetInput.value = this.dateTimeValueInput.value;

            this.updateDropDowns();
            this.updateTimePicker();

            if (!this.layoutManagerService.isTouchLayoutActive() || this.isPhone) {
                setTimeout(() => {
                    targetInput.focus();
                }, 100);
            }
        }, 100);
    }

    private preventIllegalInputs(event: KeyboardEvent): void {
        let isAllowed = false;

        for (const keyValue of this.allowedKeys) {
            if (event.keyCode == keyValue) {
                isAllowed = true;
                break;
            }
        }

        if (!isAllowed) {
            event.preventDefault();
        }
    }

    private getCompleteValue(value: string): string {
        if (value == void 0 || value == "") {
            return "";
        }

        const newValue: string = value.replace("/\//", ".");
        const date = this.fieldType == "time" ? dayjs(newValue, ["HH:mm:ss", "HH:mm"]) : dayjs(newValue, ["DD.MM.YYYY HH:mm:ss", "DD.MM.YYYY HH:mm", "DD.MM.YYYY"]);
        if (!date.isValid()) {
            return "";
        }

        switch (this.fieldType) {
            case FieldDataType.DATE:
                return date.format(this.environmentService.env.dateFormat.date);
            case FieldDataType.DATETIME:
            case FieldAddon.DATETIME:
                return date.format(this.environmentService.env.dateFormat.datetime.replace(":ss", ""));
            case FieldDataType.TIME:
                return date.format(this.environmentService.env.dateFormat.time.replace(":ss", ""));
        }
    }

    private customizeToolbar(): void {
        this.toolbar = this.xdsoftDateTimePicker.querySelector(".xdsoft_monthpicker");
        const selectedMonth: HTMLElement = this.container.querySelector(".xdsoft_month > span");
        const selectedYear: HTMLElement = this.container.querySelector(".xdsoft_year > span");

        const nextButton: HTMLElement = this.toolbar.querySelector(".xdsoft_next");
        this.renderer.setAttribute(nextButton, "title", this.translateFn("eob.datetimepicker.forward"));

        const prevButton: HTMLElement = this.toolbar.querySelector(".xdsoft_prev");
        this.renderer.setAttribute(prevButton, "title", this.translateFn("eob.datetimepicker.back"));

        this.renderer.appendChild(this.container.querySelector(".date-picker-toolbar"), this.toolbar);

        [nextButton, prevButton].forEach(button => {
            // prevent the default for touchend event because the datetimepicker sends multiple events if we dont do this
            button.addEventListener("touchend", (event: KeyboardEvent) => {
                event.preventDefault();
            });

            button.addEventListener("mouseup", () => {
                setTimeout(() => {
                    const nextEntry: Element = this.getNewMonth();
                    this.changeSelection("month", nextEntry);
                    this.updateDropDowns();
                }, 100);
            });
        });

        this.renderer.addClass(selectedMonth, "selected-option");
        ["click", "touchend"].forEach(event => {
            selectedMonth.addEventListener(event, () => {
                this.manipulateDropdown("month");
                const monthList: JQuery = $(this.container).find(".month-list");
                this.scrollIntoView(monthList.find(".xdsoft_current"), monthList);
            });
        });

        this.renderer.addClass(selectedYear, "selected-option");
        ["click", "touchend"].forEach(event => {
            selectedYear.addEventListener(event, () => {
                this.manipulateDropdown("year");
                const yearList: JQuery = $(this.container).find(".year-list");
                this.scrollIntoView(yearList.find(".xdsoft_current"), yearList);
            });
        });

        this.el.nativeElement.addEventListener("click", (event: MouseEvent) => {
            const target: JQuery<any> = $(event.target);
            if (!target.hasClass("xdsoft_option") && !target.hasClass("selected-option")) {
                this.renderer.addClass(this.toolbar.querySelector(".date-dropdown"), "hidden");
            }
        });

        setTimeout(() => {
            this.manipulateDropdown("month", true);
            this.manipulateDropdown("year", true);
            this.createAdditionalButtons(this.toolbar);
            $(this.xdsoftDateTimePicker).find(".xdsoft_date").bind("click", () => {
                // needs to trigger manipulation of the dropdown after first date selection
                // because addon does weird shit after clicking a date
                $(this.container).trigger("click");
            });
        }, 100);
    }

    private getNewMonth(): Element {
        const monthList: HTMLElement = this.container.querySelector(".month-list");
        const originalList: HTMLElement = this.container.querySelector(".xdsoft_monthselect");
        const newIndex: number = +$(originalList).find(".xdsoft_current").attr("data-value");
        return monthList.querySelectorAll(".xdsoft_option").item(newIndex);
    }

    private scrollIntoView(listElement: JQuery, scrollContainer: JQuery): void {
        const index: number = listElement.index();
        const itemHeight: number = listElement.outerHeight();
        const scrollTop: number = ((index + 1) * itemHeight) - (scrollContainer.height() / 2) - (itemHeight / 2);
        scrollContainer.scrollTop(scrollTop);
    }

    private manipulateDropdown(type: string, isInitialRun: boolean = false, isSelectionDone: boolean = false): void {
        const listDiv: HTMLElement = this.renderer.createElement("div");
        this.renderer.addClass(listDiv, `${type}-list`);
        this.renderer.addClass(listDiv, "date-dropdown");
        this.renderer.addClass(listDiv, "hidden");
        this.renderer.setAttribute(listDiv, "role", "list");

        const lastIndex: number = type == "year" ? this.yearEnd - this.yearStart + 1 : 12;
        let isScrolling = false;

        const currentSelectionDiv: HTMLElement = this.container.querySelector(`.xdsoft_${type}`);
        const options: NodeListOf<HTMLElement> = currentSelectionDiv.querySelectorAll(".xdsoft_option");
        for (let i = 0; i < lastIndex; i++) {
            const originalEntry: HTMLElement = options[i];
            const newEntry: Node = originalEntry.cloneNode(true);
            const entryClassIndex: number = type == "year" ? i + this.yearStart : i;
            this.renderer.addClass(originalEntry, `option-${entryClassIndex}`);
            this.renderer.setAttribute(newEntry, "role", "listitem");
            this.renderer.appendChild(listDiv, newEntry);

            ["click", "touchend"].forEach(event => {
                newEntry.addEventListener(event, () => {
                    if (!isScrolling) {
                        this.updateDropDowns();
                        this.changeSelection(type, newEntry);
                    }
                });
            });
        }

        if (isSelectionDone) {
            return;
        }

        if (this.layoutManagerService.isTouchLayoutActive()) {
            let lastEvent: TouchEvent = null;
            listDiv.addEventListener("touchstart", () => {
                let scrollDistance = 0;

                const onTouchMove: any = (event: TouchEvent): void => {
                    isScrolling = true;

                    const onTouchEnd: any = (): void => {
                        isScrolling = false;
                        lastEvent = null;
                        listDiv.removeEventListener("touchmove", onTouchMove);
                        listDiv.removeEventListener("touchend", onTouchEnd);
                    };

                    listDiv.addEventListener("touchend", onTouchEnd);

                    if (lastEvent == null) {
                        lastEvent = event;
                        return;
                    }

                    const diff: number = lastEvent.touches.item(0).clientY - event.touches.item(0).clientY;
                    if (Math.abs(scrollDistance) < 8) {
                        scrollDistance += diff;
                        isScrolling = false;
                    } else {
                        listDiv.scrollTop += diff;
                    }
                    lastEvent = event;
                };

                listDiv.addEventListener("touchmove", onTouchMove);
            });
        }

        if (isInitialRun) {
            this.renderer.appendChild(currentSelectionDiv, listDiv);
        } else {
            const domList: HTMLElement = this.container.querySelector(`.${type}-list`);
            if (domList.classList.contains("hidden")) {
                this.renderer.removeClass(domList, "hidden");
            } else {
                this.renderer.addClass(domList, "hidden");
            }
        }
    }

    private updateDropDowns(): void {
        this.addActiveClassDropdown($(this.container).find(".month-list"), $(this.container).find(".xdsoft_monthselect"));
        this.addActiveClassDropdown($(this.container).find(".year-list"), $(this.container).find(".xdsoft_yearselect"));
    }

    private addActiveClassDropdown(newList: JQuery, originalList: JQuery): void {
        setTimeout(() => {
            const newValue: string = originalList.find(".xdsoft_current").attr("data-value");
            newList.find(".xdsoft_current").removeClass("xdsoft_current");
            newList.find(`.xdsoft_option[data-value='${newValue}']`).addClass("xdsoft_current");
        }, 100);
    }

    private createAdditionalButtons(toolbar: HTMLElement): void {
        const prevYearButton: HTMLElement = this.renderer.createElement("button");
        this.renderer.addClass(prevYearButton, "prev-year");
        this.renderer.setAttribute(prevYearButton, "title", this.translateFn("eob.datetimepicker.back"));

        const nextYearButton: HTMLElement = this.renderer.createElement("button");
        this.renderer.addClass(nextYearButton, "next-year");
        this.renderer.setAttribute(nextYearButton, "title", this.translateFn("eob.datetimepicker.forward"));

        prevYearButton.addEventListener("click", () => {
            this.changeYear(false);
        });

        nextYearButton.addEventListener("click", () => {
            this.changeYear(true);
        });

        this.renderer.appendChild(toolbar, prevYearButton);
        this.renderer.appendChild(toolbar, nextYearButton);

        const dropdownWidth: number = $(toolbar).find(".selected-option").width();
        $(toolbar).find(".date-dropdown").css("width", `${dropdownWidth}px`);
    }

    private changeYear(isNext: boolean): void {
        const currentTime: any = this.xdsoftDateTimePickerInterface.data().xdsoft_datetime.currentTime;
        currentTime.setFullYear(currentTime.getFullYear() as number + (isNext ? 1 : -1));
        this.xdsoftDateTimePickerInterface.setOptions({value: currentTime, format: this.dateFormat});
        this.activeInput.value = this.dateTimeValueInput.value;
        this.updateDropDowns();
    }

    private changeSelection(type: string, entry: Element | Node): void {
        const currentTime: any = this.xdsoftDateTimePickerInterface.data().xdsoft_datetime.currentTime;
        if (type == "month") {
            currentTime.setMonth($(entry).attr("data-value"));
        } else {
            currentTime.setFullYear($(entry).attr("data-value"));
        }

        this.xdsoftDateTimePickerInterface.setOptions({value: currentTime, format: this.dateFormat});

        const selectedOptionDiv: HTMLElement = this.container.querySelector(`.xdsoft_${type}`);
        this.renderer.addClass(selectedOptionDiv.querySelector(`.${type}-list`), "hidden");

        setTimeout(() => {
            this.updateDateInput();
        }, 50);
    }

    private updateDateInput(): void {
        $(this.pickerContainer).find(".xdsoft_date.xdsoft_current").trigger("click");
    }

    private changeTime(value: string, isNewHour: boolean): void {
        if ((this.fieldType == FieldDataType.DATETIME || this.fieldType == FieldAddon.DATETIME) && this.activeInput.value == "") {
            this.updateDateInput();
        }

        let newValue: string;
        const currentValue: string = this.activeInput.value;
        const newDigits: string = value;
        const dateString: string = (this.fieldType == FieldDataType.DATETIME || this.fieldType == FieldAddon.DATETIME) ? currentValue.split(" ")[0] : "";
        const timeString: string = (this.fieldType == FieldDataType.DATETIME || this.fieldType == FieldAddon.DATETIME) ? currentValue.split(" ")[1] : currentValue;
        const splittedValue: string[] = timeString == void 0 ? ["00", "00"] : timeString.split(":");

        if (isNewHour) {
            // written this way, IntelliJ IDEA 2020.1 complains about "identical branches" ?!
            // -> newValue = splittedValue[1] == void 0 ? `${newDigits}:00` : `${newDigits}:${splittedValue[1]}`;
            const v1 = `${newDigits}:00`;
            const v2 = `${newDigits}:${splittedValue[1]}`;
            newValue = splittedValue[1] == void 0 ? v1 : v2;
        } else {
            // written this way, IntelliJ IDEA 2020.1 complains about "identical branches" ?!
            // -> newValue = splittedValue[0] == void 0 ? `00:${newDigits}` : `${splittedValue[0]}:${newDigits}`;
            const v1 = `00:${newDigits}`;
            const v2 = `${splittedValue[0]}:${newDigits}`;
            newValue = splittedValue[0] == void 0 ? v1 : v2;
        }

        this.activeInput.value = dateString == "" ? newValue : `${dateString} ${newValue}`;

        if (this.xdsoftDateTimePickerInterface != void 0) {
            this.xdsoftDateTimePickerInterface.setOptions({value: this.activeInput.value, format: this.dateFormat});
        }
    }
}