import {Inject, Injectable} from "@angular/core";
import {KebabMenuAction} from "MODULES_PATH/state-basics/components/state-kebab-menu/state-kebab-menu.component";
import {
    TodoDesktopService,
    TodoEnvironmentService,
    TodoHitlistConfigService, TodoLayoutRows, TodoModalDialogService,
    TodoStateHistoryManager, TodoStateService
} from "INTERFACES_PATH/any.types";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {OfflineService} from "SERVICES_PATH/offline/eob.offline.srv";
import {StateObject, StateService} from "@uirouter/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {ContextMenuService} from "MODULES_PATH/context-menu/services/context-menu.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {HitlistConfig} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {
    StateContent,
    StateFolderData,
    StateWorkflowInfoData
} from "INTERFACES_PATH/state.interface";

export interface WorkflowGroup {
    expandable: boolean;
    id: string;
    rows: { [key: string]: TodoLayoutRows };
    title: string;
}

@Injectable({
    providedIn: "root"
})
export class KebabMenuService {
    private readonly translate: TranslateFnType;
    private hitlistConfig: HitlistConfig;

    constructor(
        @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
        @Inject("environmentService") private environmentService: TodoEnvironmentService,
        @Inject("offlineService") private offlineService: OfflineService,
        @Inject("hitlistConfigService") protected hitlistConfigService: TodoHitlistConfigService,
        @Inject("$state") private $state: StateService,
        @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
        @Inject("stateService") private stateService: TodoStateService,
        @Inject("offlineCacheService") private offlineCacheService: OfflineCacheService,
        @Inject("desktopService") private desktopService: TodoDesktopService,
        @Inject("$filter") private $filter: ng.IFilterService,
        private contextMenuService: ContextMenuService,
        private notificationsService: NotificationsService,
        private messageService: MessageService,
        private clientService: ClientService) {
        this.translate = this.$filter("translate");
    }

    /**
     * Return kebab menu actions for the result state.
     *
     * @param hitlistConfig - hitlist configuration
     * @returns {Object[]} Menu actions.
     */
    async getHitlistMenuItemsAsync(hitlistConfig?: HitlistConfig): Promise<KebabMenuAction[]> {
        const isAllowedToSave: boolean = this.environmentService.userHasRole("R_CLNT_STORE_SETTINGS");
        const stateMenuItems: KebabMenuAction[] = [];
        const state: StateObject = this.stateHistoryManager.getCurrentStateData();
        const isPhone: boolean = this.clientService.isPhone();

        if (state == void 0 || state.data == void 0) {
            return stateMenuItems;
        }

        if (!isPhone) {
            stateMenuItems.push(this.createActionEntry("reset-configs", this.translate("eob.reset.config.title"), this.hitlistConfigService.resetConfiguration));
        }

        if (this.clientService.isOnline()) {
            if (isPhone && state.data.type !== "startable") {
                stateMenuItems.push(this.createActionEntry("kebab-refresh", this.translate("eob.state.refresher.title"), this.refresh.bind(this)));
                if (this.$state.current.name == "hitlist.failedSyncObjects") {
                    if (!this.offlineService.isSyncRunning()) {
                        this.hitlistConfig = hitlistConfig;
                        stateMenuItems.push(this.createActionEntry("synchronize", this.translate("eob.nav.kebab.synchronize.title"), this.startSync.bind(this)));
                    } else {
                        stateMenuItems.push(this.createActionEntry("stop-sync", this.translate("eob.stop.synchronization.title"), this.abortSync.bind(this)));
                    }
                }
            }

            if (state.data.type == "search" && state.data.flat != true && isAllowedToSave) {
                stateMenuItems.push(this.createActionEntry("query-saver", this.translate("eob.query.saver.save.title"), this.desktopService.saveQuery));
                stateMenuItems.push(this.createActionEntry("configure-dark", this.translate("nav.objectsearch.configuration.title"), this.stateService.showHitlistConfig));
            }
        }

        if (["hitlist.offlineObjects", "hitlist.failedSyncObjects"].some(state => this.$state.current.name == state)) {
            stateMenuItems.push(this.createActionEntry("info", this.translate("eob.sync.info.dialog.title"), this.modalDialogService.showOfflineInfoDialog));
        }

        if (this.$state.current.name == "hitlist.offlineObjects" && (await this.offlineCacheService.getFailedSyncObjectsCount()) > 0) {
            stateMenuItems.push(this.createActionEntry("sync-warning", this.translate("eob.sync.failed.objects.review"), this.openFailedSyncObjectsState.bind(this)));
        }

        if (state.data.type == "search" || (state.data.type == "script-query" && state.data.useAsIni == true)) {
            if (state.data.flat == true) {
                stateMenuItems.push(this.createActionEntry("treeview", this.translate("eob.kebap.menu.folder.tree"), this.hitlistConfigService.openFolderTree));
            }
        } else if (this.isStateTypeFolderTree(state.data)) {
            stateMenuItems.push(this.createActionEntry("treeview", this.translate("eob.kebap.menu.folder.flat"), this.hitlistConfigService.openFolderFlat));
        }

        return stateMenuItems;
    }

    getRoutingListMenuItems(groups: WorkflowGroup[]): KebabMenuAction[] {
        const menuItems: KebabMenuAction[] = [];

        menuItems.push(this.createActionEntry("template", this.translate("eob.workflow.circulation.template.load"), this.showTemplates.bind(this)));

        if (this.clientService.isOnline() && groups.length > 0 && (this.environmentService.userHasRole("R_CLNT_WFPRIVATEROUTELIST") || this.environmentService.userHasRole("R_CLNT_WFPUBLICROUTELIST"))) {
            menuItems.push(this.createActionEntry("template-save", this.translate("eob.workflow.circulation.template.save"), this.saveTemplate.bind(this)));
        }

        return menuItems;
    }

    showTemplates(): void {
        this.messageService.broadcast("showTemplates");
    }

    saveTemplate(): void {
        this.messageService.broadcast("showTemplateSaveModalDialog");
    }

    /**
     * Return kebab menu actions for the workflow info state.
     *
     * @param data - WorkItem data.
     * @returns KebabMenuAction actions.
     */
    getWorkflowInfoMenuItems(data: StateWorkflowInfoData): KebabMenuAction[] {
        const menuItems: KebabMenuAction[] = [];

        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translate("eob.message.offline.function.disabled"));
            return menuItems;
        }

        if (this.clientService.isOnline()) {
            menuItems.push(this.createActionEntry("workflow-is-personalized", this.translate("eob.contextmenu.action.workflow.details.assign.performers"), async () => {
                try {
                    const changedData: StateWorkflowInfoData = await this.modalDialogService.openAssignPerformersDialog(data.activityId, data.processId, data.personalizedBy != void 0 && data.personalizedBy != "");
                    if (changedData.personalizedBy != void 0) {
                        this.messageService.broadcast("workflow.info.update", changedData);
                    }

                    if (changedData.performers != void 0) {
                        this.messageService.broadcast("workflow.info.refresh");
                    }
                } catch (error) { /* action cancelled*/
                }
            }));
        }

        return menuItems;
    }

    async getFolderStateMenuItemsAsync(data: StateFolderData): Promise<KebabMenuAction[]> {
        const stateMenuItems: KebabMenuAction[] = await this.getHitlistMenuItemsAsync();

        if (this.clientService.isOnline()) {
            data.items = [{
                osid: data.osid,
                objectTypeId: data.objectTypeId,
                mainType: data.objectType == "FOLDER" ? 0 : 99
            }];

            data.contextData = {
                title: undefined,
                context: "emptySpaceInHitlist"
            };

            this.contextMenuService.getContextMenuActions(data.items, data.contextData)
                .subscribe(contextMenuItems => {
                        for (const item of contextMenuItems) {
                            if (item.callback) {
                                stateMenuItems.push(this.createActionEntry(item.icon, item.title, () => item.callback(...item.params)));
                            } else {
                                stateMenuItems.push(this.createActionEntry(item.icon, item.title, (data: { event: Event }): void => {
                                    this.messageService.broadcast(InlineDialogEvent.DISPLAY_CUSTOM_ACTIONS, {
                                        items: item.params,
                                        event: data.event,
                                        contextData: {
                                            context: "emptySpaceInHitlist",
                                            title: item.title
                                        },
                                    });
                                }, "emptySpaceInHitlist"));
                            }
                        }
                    }
                );
        }

        return stateMenuItems;
    }

    private createActionEntry(icon: string, title: string, callback: (data: { event: Event }) => Promise<void> | void, context = ""): KebabMenuAction {
        return {
            icon: `${icon}`,
            alt: title,
            title,
            callback,
            context
        };
    }

    /**
     * Opens the view showing all the offline objects which have failed to be synchronized.
     */
    private openFailedSyncObjectsState(): void {
        const nextStateType: string = "hitlist.failedSyncObjects";
        const nextStateId: number = Date.now();

        const nextStateContent: StateContent = {
            config: {
                suppressSingleHitAction: true,
                userAction: ""
            },
            type: nextStateType,
            id: undefined,
            objectTypeId: undefined
        };

        this.stateHistoryManager.setStateData(nextStateContent, nextStateId);

        this.$state.go(nextStateType, {state: nextStateId});
    }

    /**
     * Starts synchronisation of offline objects on smartphone.
     */
    private async startSync(): Promise<void> {
        if (this.hitlistConfig.api) {
            this.hitlistConfig.api.getGridOptions().api.setRowData([]);
            await this.offlineService.synchronizeAsync();
        }
    }

    /**
     * Aborts the current synchronisation of offline objects on smartphone.
     */
    private abortSync(): void {
        this.offlineService.stopSynchronization();
    }

    private async refresh(): Promise<void> {
        await this.$state.reload();
    }


    // folder state with register tree
    private isStateTypeFolderTree(data: { type: string; flat: boolean }): boolean {
        if (data.type != void 0 && data.type == "folder" && !data.flat) {
            return true;
        }

        return false;
    }

}