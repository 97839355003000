import * as angular from "angular";
import {Inject, Injectable} from "@angular/core";
import {StateService} from "@uirouter/core";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {GridContentUtilsService} from "MODULES_PATH/grid/services/grid-content-utils.service";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {GridData} from "MODULES_PATH/grid/interfaces/grid-data.interface";
import {AsIniWfInboxStaticColumns} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {SortService} from "CORE_PATH/services/utils/sort.service";
import {IconCellComponent} from "../components/grid-cells/icon-cell/icon-cell.component";
import {
    TodoCacheManagerService,
    TodoConfiguredField,
    TodoContextItem,
    TodoEnvironmentService,
    TodoFieldCache,
    TodoFieldsSchemaField,
    TodoListEntry,
    TodoOptionalGridData,
    TodoParameterField,
    TodoQuery,
    TodoRawParentField,
    TodoResultConfigParent,
    TodoRowTemplate,
    TodoStaticColumnConfig,
    TodoStaticColumnData,
    TodoWorkflowParameter
} from "INTERFACES_PATH/any.types";
import {IconHeaderCellComponent} from "../components/grid-header-cells/icon-header-cell/icon-header-cell-content.component";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ColumnDefinition} from "MODULES_PATH/grid/interfaces/column.interface";
import {ObjectType} from "INTERFACES_PATH/object-type.interface";
import {Field} from "INTERFACES_PATH/field.interface";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {FieldType} from "MODULES_PATH/dms/interfaces/field-type.interface";
import {BaseParameters} from "MODULES_PATH/dms/interfaces/base-parameters.interface";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {PhoneCellComponent} from "MODULES_PATH/grid/components/grid-cells/phone-cell/phone-cell.component";

/**
 * converts raw backend data to griddata used for the ag grid inside the hitlist dir
 */
@Injectable({
    providedIn: "root"
})
export class GridContentService {
    // eslint-disable-next-line max-params
    constructor(@Inject("environmentService") private environmentService: TodoEnvironmentService, @Inject("staticColumnConfig") private staticColumnConfig: TodoStaticColumnConfig,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                private readonly $state: StateService,
                private objectTypeService: ObjectTypeService,
                private asIniService: AsIniService,
                protected valueUtilsService: ValueUtilsService,
                protected toolService: ToolService,
                private layoutManagerService: LayoutManagerService,
                private clientService: ClientService,
                protected gridContentUtilsService: GridContentUtilsService,
                private sortService: SortService) {

    }

    /**
     * Creates the config for the hitlist component and the ag-grid
     * Converts the given listentries (most of the time dmsDocuments) to a format readable for the ag-grid and the virtual-grid
     *
     * @param listEntries - dmsDocuments or workflow inbox entries
     * @param optionalContext - the context we want to convert for. used for scripting purposes and secondary lists like quickfinder or workflow files
     * @param optionalData
     */
    getListEntries = (listEntries: TodoListEntry[], optionalContext?: string, optionalData?: TodoOptionalGridData): GridData => {
        if (!Array.isArray(listEntries)) {
            listEntries = [listEntries];
        }

        const hitlistConfig: GridData = {
            columns: [],
            rows: [],
            filter: "",
            staticColCount: 0,
            frameworkComponents: {
                iconCellRenderer: IconCellComponent,
                iconCellHeaderRenderer: IconHeaderCellComponent,
                phoneCellRenderer: PhoneCellComponent
            }
        };

        const context: string = typeof (optionalContext) == "undefined" ? this.getCurrentContext() : optionalContext;
        hitlistConfig.headerIdKey = this.getContextHeaderIdKey(context);

        const staticColumns: TodoStaticColumnData[] = this.getStaticColumns(context, optionalData);

        // remove favs for processes, workflow and favorites
        const favIndex: number = staticColumns.findIndex(col => col.columnType == "favorite");
        if (favIndex != -1 && !this.clientService.isPhone() && ["processes", "workflow", "favorites", "hitlist.favorites", "offlineObjects", "hitlist.offlineObjects"].includes(context)) {
            staticColumns.splice(favIndex, 1);
        }

        // this adds the static columns that are configured in the
        // constant hitlist-specific columns inside the inbox controller
        hitlistConfig.columns = this.addStaticColumns(staticColumns, context);

        if (!this.clientService.isPhone() && (context == "processes" || context == "workflow")) {
            this.gridContentUtilsService.addParameterColumns(hitlistConfig.columns, listEntries);
        } else if (context == "favorites" || context == "hitlist.favorites") {
            // the list entries in "favorites"-hitlist should be sorted in the same way as in "offlineObjects",
            // so that both states have the same column names initially.
            listEntries.sort((objA: DmsDocument, objB: DmsDocument) => objA.model.osid === objB.model.osid ? 0 : (objA.model.osid < objB.model.osid ? -1 : 1));
        } else if (this.clientService.isPhone() && (context == "history" || context == "hitlist.history")) {
            listEntries.sort((objA: DmsDocument, objB: DmsDocument) =>
                // DODO-10695 sort history hitlist so the last entry is at the top
                this.sortService.sortByDatetime(objA.model.baseParameters.modifyDate, objB.model.baseParameters.modifyDate)).reverse();
        }

        // this is an enpty grid resolve and return
        if (typeof (listEntries) == "undefined" || (Array.isArray(listEntries) && listEntries.length == 0)) {
            return hitlistConfig;
        }

        // add the rows and complete the columns with the object specific columns
        // this has to be async because we might not know the objecttype definition by now (this happens on demand to reduce traffic)
        return this.addObjects(hitlistConfig, listEntries, staticColumns, context, optionalData);
    };

    /**
     * returns the current context as a string
     */
    private getCurrentContext(): string {
        if (this.$state.current.name == "hitlist.inbox") {
            return this.$state.params.type as string;
        }

        return this.$state.current.name;
    }

    private getContextHeaderIdKey(context) {
        if (["workflow", "processes"].includes(context)) {
            return "activityId";
        } else if (context == "startable") {
            return "context";
        } else {
            return "objectTypeId";
        }
    }

    /**
     * Returns the static columns for the current context.
     * static columns can be icon columns and text columns from the given context
     *
     * @param context - the current state context .. either name or type
     * @param optionalData - Additional string data. Can be getAll|isFolderOrRegisterList.
     */
    getStaticColumns(context: string, optionalData: TodoOptionalGridData): TodoStaticColumnData[] {
        let columns: TodoStaticColumnData[];
        const wfInboxStaticColumns: AsIniWfInboxStaticColumns = this.asIniService.getWfInboxStaticColumns();

        if (this.clientService.isPhone()) {
            if (context == "startable" || context == "workflow" || context == "processes") {
                columns = this.staticColumnConfig.smartphoneNonDms;
            } else if (/(hitlist\.)?offlineobjects/gi.test(context)) {
                columns = this.staticColumnConfig.smartphoneOfflineObjects;
            } else {
                columns = this.staticColumnConfig.smartphoneDms;
            }
        } else {
            context = context == "objectReferences" && !this.environmentService.isObjectLinkNoteAllowed() ? "objectReferencesWithoutLinkNote" : context;

            columns = typeof (this.staticColumnConfig[context]) == "undefined" ? this.staticColumnConfig.default : this.staticColumnConfig[context];
            columns = context == "hitlist.fulltextResult" ? this.staticColumnConfig.default.concat(columns) : columns;
            columns = this.toolService.startsWith(context, "wfFileArea") ? this.staticColumnConfig.wfFileArea : columns;
            columns = context == "inbox" ? this.staticColumnConfig.workflow.filter(x => x.type === "text" || x.type === "datetime") : columns;
        }

        const iconColConfig: TodoStaticColumnData = this.asIniService.getStaticColumns();
        const isFolderOrRegisterList: boolean = context == "hitlist.result" && optionalData == "isFolderOrRegisterList";

        for (const col of columns) {
            col.enabled = false;
            if (col.columnType == "dmsIcon" && iconColConfig.dmsIcon == false) {
                continue;
            } else if (col.columnType == "archiveState" && (iconColConfig.archiveState == false || isFolderOrRegisterList)) {
                continue;
            } else if (col.columnType == "lockState" && (iconColConfig.lockState == false || isFolderOrRegisterList)) {
                continue;
            } else if (col.columnType == "notes" && iconColConfig.notes == false) {
                continue;
            } else if (col.columnType == "links" && iconColConfig.links == false) {
                continue;
            } else if (col.columnType == "annotations" && (iconColConfig.annotations == false || isFolderOrRegisterList)) {
                continue;
            } else if (col.columnType == "objectType" && iconColConfig.objectType == false) {
                continue;
            } else if (col.columnType == "mimeType" && (iconColConfig.mimeType == false || isFolderOrRegisterList)) {
                continue;
            } else if (col.columnType == "favorite" && (iconColConfig.favorite == false)) {
                continue;
            } else if (col.columnType == "signature" && (iconColConfig.signature == false || isFolderOrRegisterList)) {
                continue;
            } else if (col.columnType == "workflowActivityName" && !wfInboxStaticColumns.WFITEMNAME) {
                continue;
            } else if (col.columnType == "workflowSubject" && !wfInboxStaticColumns.WFSUBJECT) {
                continue;
            } else if (col.columnType == "workflowWarningTime" && !wfInboxStaticColumns.WFCLOSURE) {
                continue;
            } else if (col.columnType == "workflowCreationTime" && !wfInboxStaticColumns.WFCREATION) {
                continue;
            } else if (col.columnType == "workflowProcessName" && !wfInboxStaticColumns.WFNAME) {
                continue;
            } else if (col.columnType == "workflowPersonalizedBy" && !wfInboxStaticColumns.WFPERSONALIZED) {
                continue;
            }

            col.enabled = true;
        }

        // in the user settings and on phone we want to get all static columns despite the configuration
        if (optionalData == "getAll" || this.clientService.isPhone()) {
            return columns;
        }

        return columns.filter(x => x.enabled);
    }

    /**
     * adds the static columns to the column definition for the grid component (ag-grid)
     *
     * @param staticColumns - configured columns from the main.config file
     * @param context - the current context
     */
    addStaticColumns(staticColumns: TodoStaticColumnData[], context: string): ColumnDefinition[] {
        const cols: ColumnDefinition[] = [];
        if (this.showCheckboxColumn(context) && (staticColumns.length == 0 || staticColumns[0].columnType !== "checkbox")) {
            staticColumns.unshift({columnType: "checkbox", type: "icon"});
        }

        for (const [i, col] of staticColumns.entries()) {
            if (col.type == "phoneCellContent") {
                cols.push(this.gridContentUtilsService.getStaticPhoneContentColumn(col, i));
            } else if (col.type == "icon") {
                cols.push(this.gridContentUtilsService.getStaticIconCol(col, i));
            } else {
                cols.push(this.gridContentUtilsService.getStaticTextCol(col, i));
            }
        }

        return cols;
    }

    /**
     * determines wheter we show a checkbox column or not
     *
     * @param context - the current context
     */
    private showCheckboxColumn(context: string): boolean {
        if (context == "quickfinder" || context == "startable" || context == "variants") {
            return false;
        }

        return !(!this.layoutManagerService.isTouchLayoutActive() || this.clientService.isPhone());
    }

    /**
     * Creates rows from the given Data and adds the rows to the GridData
     */
    private addObjects(hitlistData: GridData, listEntries: TodoListEntry[], staticColumns: TodoStaticColumnData[], context: string, optionalData: TodoOptionalGridData): GridData {
        switch (context) {
            case "abo":
            case "subscriptionObjects":
            case "revisit":
            case "result":
            case "hitlist.result":
            case "history":
            case "hitlist.history":
            case "favorites":
            case "hitlist.favorites":
            case "fulltextResult":
            case "hitlist.fulltextResult":
            case "folder":
            case "desktop":
            case "download":
            case "quickfinder":
            case "failedSyncObjects":
            case "hitlist.failedSyncObjects":
            case "offlineObjects":
            case "hitlist.offlineObjects":
            case "scriptQuery":
            case "variants":
            case "wfFileAreaWorkFiles":
            case "wfFileAreaInfoFiles":
            case "objectReferences":
                return this.addDmsObject(hitlistData, listEntries, staticColumns, context, optionalData);
            case "workflow":
            case "startable":
            case "wfAdHocEntries":
            case "processes":
                return this.addNonDmsObjects(hitlistData, listEntries, staticColumns, context);
            default:
                console.warn("unexpected addObjects() call", context);
                return hitlistData;
        }
    }

    /**
     * Adds a row to the gridData rows for every given dmsDocument
     */
    private addDmsObject(gridData: GridData, dmsDocuments: DmsDocument[], staticColumns: TodoStaticColumnData[], context: string, optionalData: TodoOptionalGridData): GridData {
        // gather the required types we need to get from the objecttype definition
        const requiredTypes: string[] = [];

        for (const dmsDocument of dmsDocuments) {
            const docModel: DmsDocumentModel = dmsDocument.model;

            if (!requiredTypes.includes(docModel.objectTypeId)) {
                requiredTypes.push(docModel.objectTypeId);
            }
        }

        const types: Map<string, ObjectType> = this.cacheManagerService.objectTypes.getAsMap(requiredTypes);
        const fieldCache: TodoFieldCache = {};
        const forbiddenTypes: string[] = [];

        for (const req of requiredTypes) {
            if (types[req] == void 0) {
                forbiddenTypes.push(req);
            }
        }

        if (forbiddenTypes.length > 0) {
            console.warn("The user has no rights to see the following types --> ", forbiddenTypes.toString());
            console.warn("Documents of those types will be filtered and therefore not visible.");
        }

        for (const t in types) {
            fieldCache[t] = this.getFieldCache(types[t], context, optionalData);

            const state: string = this.$state.current.name;
            const isOfflineFavorites: boolean = ["hitlist.offlineObjects", "hitlist.failedSyncObjects"].includes(state) && this.asIniService.isSynchronizeFavoritesOffline();
            if (["folder", "variants"].includes(state) || isOfflineFavorites || this.isFolderFlat(optionalData)) {
                GridContentService.getRidOfCabinetFields(fieldCache[t]);
            }

            if (state == "hitlist.history") {
                GridContentService.getRidOfLastModifiedField(fieldCache[t]);
            }
        }

        for (let i = 0; i < dmsDocuments.length; i++) {
            const docModel: DmsDocumentModel = dmsDocuments[i].model;
            if (!types[docModel.objectTypeId]) {
                dmsDocuments.splice(i, 1);
                i--;
                continue;
            }

            gridData.staticColCount = staticColumns.length;

            const rowTemplate: TodoRowTemplate = {};
            rowTemplate.context = context;
            rowTemplate.objectTypeId = docModel.objectTypeId;
            rowTemplate.osid = docModel.osid;
            rowTemplate.id = docModel.osid;
            rowTemplate.fileMimeTypeGroup = docModel.fileProperties.mimeTypeGroup;
            rowTemplate.mainType = docModel.mainType;
            rowTemplate.isTypeless = docModel.isTypeless;

            const contextItems: TodoContextItem[] | null = GridContentService.getContextItems(docModel, context);
            // add the dms object related cells and columns
            if (this.clientService.isPhone()) {
                this.addDmsSmartphoneObjectData(dmsDocuments[i], rowTemplate, context);
            } else {
                this.addDmsObjectData(docModel, rowTemplate, gridData.columns, staticColumns, fieldCache[docModel.objectTypeId], types[docModel.objectTypeId]);
            }

            if (contextItems != null) {
                for (const contextItem of contextItems) {
                    const rowEntry: TodoRowTemplate = angular.copy(rowTemplate);

                    if (context == "objectReferences") {
                        rowEntry.referenceType = contextItem.model.kind;
                        rowEntry.referenceComment = contextItem.model.comment;
                    }

                    // add the static cells to the row
                    this.gridContentUtilsService.addContextObjectData(contextItem, rowEntry, staticColumns, docModel);
                    GridContentService.addEmptyPlaceholderCells(gridData, staticColumns.length);

                    rowEntry.guid = contextItem.model.guid;
                    rowEntry.eventDate = contextItem.model.eventDate;

                    gridData.rows.push(rowEntry);
                }
            } else if (context != "result" && context != "hitlist.favorites") {
                this.gridContentUtilsService.addContextObjectData(dmsDocuments[i], rowTemplate, staticColumns, docModel);
                GridContentService.addEmptyPlaceholderCells(gridData, staticColumns.length);

                gridData.rows.push(rowTemplate);
            } else {
                gridData.rows.push(rowTemplate);
            }
        }

        return gridData;
    }

    /**
     * Creates rows for the list component from the given listentries and adds the rows to the GridData
     *
     * @param gridData - the grid data for the hitlist component
     * @param listEntries - workflow inbox items or running process items
     * @param staticColumns - the static columns defined for the given context
     * @param context - either  "workflow", "startable", "wfAdHocEntries", "processes"
     */
    private addNonDmsObjects(gridData: GridData, listEntries: TodoListEntry[], staticColumns: TodoStaticColumnData[], context: string): GridData {
        for (const listEntry of listEntries) {
            const rowTemplate: TodoRowTemplate = {};

            // add the static cells to the row
            this.gridContentUtilsService.addContextObjectData(listEntry, rowTemplate, staticColumns);

            rowTemplate.context = context;

            if (["workflow", "startable", "processes"].includes(context)) {
                rowTemplate.workflowId = listEntry.id;
                rowTemplate.id = listEntry.id;
                rowTemplate.processId = listEntry.processID;
                rowTemplate.activityId = listEntry.id;
                rowTemplate.processName = listEntry.processName;
                rowTemplate.activityName = listEntry.activityName;
                rowTemplate.subject = listEntry.processSubject;
                rowTemplate.creationTime = listEntry.creationTime;
                rowTemplate.overTime = listEntry.overTime;
                rowTemplate.personalized = listEntry.personalized;
                rowTemplate.warningTime = listEntry.warningTime;
                rowTemplate.read = listEntry.read;
                rowTemplate.substitute = listEntry.substitute;

                if (listEntry.objectId != 0) {
                    rowTemplate.osid = listEntry.objectId;
                }
            }

            if (!this.clientService.isPhone() && ["workflow", "processes"].includes(context)) {
                GridContentService.addParameterFields(rowTemplate, this.getParameterFields(listEntry), staticColumns);
                this.gridContentUtilsService.updateParameterColumns(listEntry, gridData.columns, staticColumns.length);
            }

            if (this.clientService.isPhone()) {
                if (context == "startable") {
                    rowTemplate.displayTitle = listEntry.title;
                }

                rowTemplate.f1 = this.gridContentUtilsService.getSmartphoneWorkflowInfo(listEntry, context);
            }

            gridData.rows.push(rowTemplate);
        }

        return gridData;
    }

    /**
     * Extracts an Object with key value pairs from the listentry and returns an object with (key/value) for (workflowParameter/value)
     *
     * @param listEntry - a listentry from the workflow inboxes
     */
    private getParameterFields(listEntry: TodoListEntry): { [key: string]: string | number } {
        const result: { [key: string]: string | number } = {};

        listEntry.workflowParameters.forEach((workflowParameter: TodoWorkflowParameter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let value: any = workflowParameter.value;

            if (workflowParameter.type == "DATE" && value) {
                value = parseInt(value) * 1000;
                value = this.valueUtilsService.formatDate(value, false);
            } else if (workflowParameter.type == "DATETIME" && value) {
                value = parseInt(value) * 1000;
                value = this.valueUtilsService.formatDate(value, true);
            } else if (workflowParameter.type == "TIME" && value) {
                value = parseInt(value) * 1000;
                value = this.valueUtilsService.formatTime(value);
            }

            result[workflowParameter.name] = value;
        });

        return result;
    }

    /**
     * adds dynamic parameterfields to the GridData
     *
     * @param rowTemplate - the rowtemplate for the GridData
     * @param parameterFields - extracted parameter fields
     * @param staticColumns
     */
    private static addParameterFields(rowTemplate: TodoRowTemplate, parameterFields: { [key: string]: TodoParameterField }, staticColumns: TodoStaticColumnData[]): void {
        let columnCounter: number = staticColumns.length;

        for (const parameterField in parameterFields) {
            const field: TodoParameterField = {};

            if (parameterFields.hasOwnProperty(parameterField)) {
                field.value = parameterFields[parameterField];
                field.headerName = parameterField;
                const fieldName: string = `f${columnCounter++}`;
                rowTemplate[fieldName] = field;
            }
        }
    }

    /**
     * Creates an object consisting of all fields that are configured for the objecttype and adds baseparam fields and configured fields
     * This ensures that we have all the relevant information for later caching and reusing the field information
     */
    private getFieldCache(typeDef: ObjectType, context: string, optionalData: TodoOptionalGridData): TodoFieldCache {
        const fieldList: Field[] = typeDef.api.getFlatFieldList();

        const fieldCache: TodoFieldCache = {
            fields: {},
            configured: {},
            baseParams: {}
        };

        for (const field of fieldList) {
            fieldCache.fields[field.internal] = field;
        }

        GridContentService.addConfiguredFields(fieldCache, typeDef, context, optionalData);
        this.addBaseParams(fieldCache, typeDef, context, optionalData);

        return fieldCache;
    }

    /**
     * adds the configured fields to the field cache object
     */
    private static addConfiguredFields(fieldCache: TodoFieldCache, typeDef: ObjectType, context: string, optionalData: TodoOptionalGridData): void {
        const objectTypeId: string = typeDef.model.config.objectTypeId;
        let configuredFields: { [key: string]: TodoConfiguredField };

        if (context == "scriptQuery" && optionalData != void 0) {
            if (optionalData.useAsIni) {
                // if script query and use AS.INI, start with configured AS.INI fields...
                configuredFields = angular.copy(typeDef.api.getConfiguredFields());
            } else {
                // ... or with empty fields
                configuredFields = {};
            }

            // add query fields to configured fields, if not already configured
            const internalNames: string[] = GridContentService.getInternalNames(optionalData.searchQuery, objectTypeId);

            for (const internal of internalNames) {
                const field: FieldModel = fieldCache.fields[internal];

                if (configuredFields[internal] == void 0 && field != void 0) {
                    // if internal name is not already in configured fields, add new field
                    configuredFields[internal] = {
                        position: Object.keys(configuredFields).length,
                        internal,
                        headerName: field.title,
                        dbname: field.dbname,
                        isUnicode: field.isUnicode,
                        type: field.type
                    };
                }
            }
        } else {
            // no script query, just use configured AS.INI fields
            configuredFields = typeDef.api.getConfiguredFields();
        }

        fieldCache.configured = configuredFields;
    }

    /**
     * returns an array with all the internal names of the required fields for a search query
     */
    private static getInternalNames(query: TodoQuery, objectTypeId: string): string[] {
        let internalNames: string[] = GridContentService.getInternalNamesByObjectTypeId(query.query, objectTypeId);

        if (internalNames.length < 1 && query.additionalQueries != void 0) {
            // if not found, look into additional query result configs
            for (const additionalQuery of query.additionalQueries) {
                internalNames = GridContentService.getInternalNamesByObjectTypeId(additionalQuery, objectTypeId);

                if (internalNames.length > 0) {
                    break;
                }
            }
        }

        return internalNames;
    }

    /**
     * returns the internal names of all fields from a searchquery
     */
    private static getInternalNamesByObjectTypeId(query: TodoQuery, objectTypeId: string): string[] {
        const names: string[] = [];

        // get internal field names for objectTypeId from query result config
        if (query != void 0 && query.objectTypeId == objectTypeId &&
            query.result_config != void 0 && query.result_config.fieldsschema != void 0) {

            const fields: TodoFieldsSchemaField[] = query.result_config.fieldsschema;

            for (const field of fields) {
                if (field.internalName != void 0) {
                    names.push(field.internalName);
                }
            }
        }

        return names;
    }

    /**
     * Returns true if we are in a flat folder view
     *
     * @param optionalData - given data from the result dir to determine if this is a flat folder view
     */
    private isFolderFlat(optionalData: TodoOptionalGridData): boolean {
        return !!(this.$state.current.name == "hitlist.result" && optionalData && optionalData.flat);
    }

    /**
     * adds the configured baseparameter fields to the fieldcache object
     */
    private addBaseParams(fieldCache: TodoFieldCache, typeDef: ObjectType, context: string, optionalData: TodoOptionalGridData): void {
        const cabinetId: string = typeDef.model.config.cabinetId;
        const cabinetTypeDef: ObjectType = this.cacheManagerService.objectTypes.getById(cabinetId);
        const type: string = typeDef.api.getType();
        let configuredBaseParams: TodoConfiguredField;

        if (context == "scriptQuery" && optionalData != void 0) {
            if (optionalData.useAsIni) {
                // if script query and use AS.INI, start with configured AS.INI baseparams...
                configuredBaseParams = angular.copy(typeDef.api.getConfiguredBaseParams(cabinetTypeDef, type));
            } else {
                // ... or with empty baseparams
                configuredBaseParams = {};
            }

            const searchQuery: TodoQuery = optionalData.searchQuery;

            if (searchQuery != void 0) {
                // extend configured base params with main query base params
                GridContentService.extendConfiguredBaseParams(configuredBaseParams, searchQuery.query, cabinetId.toString(), cabinetTypeDef);

                // extend configured base params with additional queries base params
                if (searchQuery.additionalQueries != void 0) {
                    for (const additionalQuery of searchQuery.additionalQueries) {
                        GridContentService.extendConfiguredBaseParams(configuredBaseParams, additionalQuery, cabinetId.toString(), cabinetTypeDef);
                    }
                }
            }
        } else {
            // no script query, just use configured AS.INI baseparams
            configuredBaseParams = typeDef.api.getConfiguredBaseParams(cabinetTypeDef, type);
        }

        fieldCache.baseParams = configuredBaseParams;
    }

    /**
     * adds configured baseparameters from additional queries to the fieldcache object
     *
     * @param configuredBaseParams - the configured baseparameters
     * @param query - the given query - either additional query of main query
     * @param cabinetId - The objectTypeId of the parent cabinet
     * @param cabinetTypeDef - the typedefinition of the parent cabinet
     */
    private static extendConfiguredBaseParams(configuredBaseParams: TodoConfiguredField, query: TodoQuery, cabinetId: string, cabinetTypeDef: ObjectType): void {
        // extend configured base params with query base params
        if (query == void 0 || query.result_config == void 0 || query.result_config.parents == void 0) {
            return;
        }

        // look for parents in main query config
        const parents: TodoResultConfigParent[] = query.result_config.parents;

        for (const parent of parents) {
            // check for matching cabinet id and fields in fields schema
            if (parent.objectTypeId == cabinetId && parent.fieldsschema != void 0) {
                for (const parentField of parent.fieldsschema) {

                    const typeField: FieldModel = GridContentService.getTypeField(parentField, cabinetTypeDef); // get type field based on internal/db/display name

                    if (typeField != void 0 && configuredBaseParams[typeField.internal] == void 0 && configuredBaseParams[typeField.dbname] == void 0) {
                        // if type field was found and field is not already in configured base params, add new base param
                        configuredBaseParams[typeField.internal] = {
                            position: Object.keys(configuredBaseParams).length,
                            internal: typeField.internal,
                            headerName: typeField.title,
                            dbname: typeField.dbname,
                            isUnicode: typeField.isUnicode,
                            type: typeField.type,
                            baseParamName: typeField.baseParamName
                        };
                    }
                }
            }
        }
    }

    /**
     * returns the field from the typeDefinition by given information
     *
     * @param parentField - the raw parent field object
     * @param parentTypeDef - the typedefinition of the parent cabinet
     */
    private static getTypeField(parentField: TodoRawParentField, parentTypeDef: ObjectType): FieldModel {
        let typeField: FieldModel;

        // get type field based on internal/db/display name
        if (parentField.internalName != void 0) {
            typeField = parentTypeDef.api.getField(parentField.internalName);
        }

        if (typeField == void 0 && parentField.dbName != void 0) {
            typeField = parentTypeDef.api.getFieldByDbName(parentField.dbName);
        }

        if (typeField == void 0 && parentField.displayName != void 0) {
            typeField = parentTypeDef.api.getFieldByName(parentField.displayName);
        }

        return typeField;
    }

    /**
     * in the variant or folder state we dont need cabinetfields, so we get rid of them and adjust the positioning for the missing params
     */
    private static getRidOfCabinetFields(fieldCache: TodoFieldCache): void {
        let deletes: number = 0;

        for (const baseParamInternal in fieldCache.baseParams) {
            if (deletes > 0 && (fieldCache.baseParams[baseParamInternal].position - deletes >= 0)) {
                fieldCache.baseParams[baseParamInternal].position -= deletes;
            }

            if (fieldCache.baseParams[baseParamInternal].baseParamName == void 0) {
                delete fieldCache.baseParams[baseParamInternal];
                deletes++;
            }
        }
    }

    /**
     * Removes the modified on column in case it has been configured for a object type because the history state
     * contains that as a static column already.
     *
     * @param {TodoFieldCache} fieldCache - All the fields that need to be checked.
     * @private
     */
    private static getRidOfLastModifiedField(fieldCache: TodoFieldCache): void {
        for (const baseParamInternal in fieldCache.baseParams) {
            if (fieldCache.baseParams[baseParamInternal].internal == "baseparamModified") {
                delete fieldCache.baseParams[baseParamInternal];
            }
        }
    }

    /**
     * returns the contextspecific items for a given dmsDocument and the current context or null if no context is given
     */
    private static getContextItems(docModel: DmsDocumentModel, context: string): TodoContextItem[] | null {
        switch (context) {
            case "revisit":
                return docModel.revisits;
            case "abo":
                return docModel.subscriptions;
            case "subscriptionObjects":
                return docModel.subscriptionObjects;
            case "fulltextResult":
            case "hitlist.fulltextResult":
                return docModel.vtx;
            case "variants":
                return docModel.variantData;
            case "wfFileAreaWorkFiles":
            case "wfFileAreaInfoFiles":
                return docModel.wfItem;
            case "offlineObjects":
            case "hitlist.offlineObjects":
                return docModel.offlineObjects;
            case "objectReferences":
                const result: TodoContextItem[] = [];
                docModel.links.forEach(x => result.push({model: x}));
                return result;
            default:
                return null;
        }
    }

    /**
     * adds the data needed for smartphone lists to the rowTemplate
     */
    private addDmsSmartphoneObjectData(dmsDocument: DmsDocument, rowTemplate: TodoRowTemplate, context: string): void {
        rowTemplate.f0 = this.gridContentUtilsService.getObjectTypeIcon(dmsDocument.model);
        rowTemplate.f1 = this.gridContentUtilsService.getTitleAndDescriptionForPhone(dmsDocument, context);
    }

    /**
     * Adds data to the cells for the generic columns
     *
     * @param dmsDocumentModel
     * @param rowTemplate
     * @param gridDataColumns - the columns for the ag-grid or virtual-grid
     * @param staticColumns - the configured static columns
     * @param fieldCache - the cached field objects of the objecttype
     * @param typeDef - the typedefinition of the objecttype
     */
    private addDmsObjectData(dmsDocumentModel: DmsDocumentModel, rowTemplate: TodoRowTemplate, gridDataColumns: ColumnDefinition[],
                             staticColumns: ColumnDefinition[], fieldCache: TodoFieldCache, typeDef: ObjectType): void {
        let colIndex: number = staticColumns.length;
        const configuredBaseParamFields: Field[] = fieldCache.baseParams;

        if (configuredBaseParamFields != void 0) {
            this.addBaseParameterFields(dmsDocumentModel, rowTemplate, gridDataColumns, colIndex, fieldCache);
            colIndex += Object.keys(configuredBaseParamFields).length;
        }

        this.addStaticDmsContent(dmsDocumentModel, rowTemplate, staticColumns, typeDef);
        this.addDmsFields(dmsDocumentModel, rowTemplate, gridDataColumns, colIndex, fieldCache);
    }

    /**
     * adds cellcontent for the configured baseparameters
     */
    private addBaseParameterFields(dmsDocumentModel: DmsDocumentModel, rowTemplate: TodoRowTemplate, gridDataColumns: ColumnDefinition[], firstNonStaticColumnIndex: number, fieldCache: TodoFieldCache): void {
        const configuredBaseParams: Field = fieldCache.baseParams;

        if (Object.keys(configuredBaseParams).length == 0) {
            return;
        }

        const cabFields: FieldType = dmsDocumentModel.parentFields;

        for (const baseParamInternal in configuredBaseParams) {
            const configuredParam: FieldModel = configuredBaseParams[baseParamInternal];
            const isCabinetField: boolean = configuredParam.baseParamName == void 0;
            const fieldData: TodoStaticColumnData = {
                internal: configuredParam.internal || baseParamInternal,
                type: "",
                value: ""
            };

            if (isCabinetField) {
                fieldData.value = cabFields[configuredParam.internal];
                fieldData.type = configuredParam.type;
            } else {
                const parsedInternal: string = configuredParam.internal.substring("baseparam".length, configuredParam.internal.length).toLowerCase();

                fieldData.type = GridContentService.getBaseparamType(parsedInternal);
                fieldData.value = GridContentService.getBaseParamValue(parsedInternal, dmsDocumentModel.baseParameters);
            }

            // fallback in case we have inconsistent data
            if (fieldData.value == void 0) {
                fieldData.value = "";

                console.warn("the given field value was not defined, fallback!");
                console.warn("check if there are inconsistent configurations!");
            }

            this.updateColumnDefinition(fieldData, firstNonStaticColumnIndex, gridDataColumns, configuredBaseParams);
            this.addCellContent(fieldData.value, rowTemplate, firstNonStaticColumnIndex, configuredBaseParams, configuredParam);
        }
    }

    /**
     * Maps the baseparameter internal to the dmsDocument baseparameter and returns the value
     *
     * @param baseparamInternal - the name of the configured baseparameter
     * @param baseparameters - the baseparameter map from the dmsDocument
     */
    private static getBaseParamValue(baseparamInternal: string, baseparameters: BaseParameters): string {
        const paramMap: { [key: string]: string } = {
            documentcount: "objectCount",
            timestamp: "creationDate",
            retentionplanneddate: "retentionPlannedDate",
            creator: "creator",
            created: "creationDate",
            modified: "modifyDate",
            modifier: "modifier",
            retentiondate: "retentionDate",
            archivedate: "archiveDate",
            archivist: "archivist"
        };

        const value: string | undefined = baseparameters[paramMap[baseparamInternal]];
        return value == void 0 ? "" : value.toString();
    }

    /**
     * returns the datatype of the baseparameter
     *
     * @param baseparamInternal - the internal name of the configured baseparameter
     */
    private static getBaseparamType(baseparamInternal: string): string {
        let type: string = "TEXT";

        switch (baseparamInternal) {
            case "timestamp":
            case "created":
            case "archivedate":
            case "modified":
            case "retentiondate":
            case "retentionplanneddate":
                type = "DATETIME";
                break;
            case "documentcount":
                type = "DECIMAL";
                break;
        }

        return type;
    }

    /**
     * updates the column definition so the columns can be sorted equal
     * configured fields with the type date and datetime can be combined to datetime
     * the same goes for integer and decimal types
     *
     * @param fieldData
     * @param firstNonStaticColumnIndex - the first index where generic columns can be inserted
     * @param gridDataColumns
     * @param configuredFields
     */
    private updateColumnDefinition(fieldData: TodoStaticColumnData, firstNonStaticColumnIndex: number, gridDataColumns: ColumnDefinition[],
                                   configuredFields: { [key: string]: TodoConfiguredField }): void {
        let fieldType: string = fieldData.type;
        const fieldInternal: string = fieldData.internal;
        const fieldDbName: string = fieldData.dbName;
        let fieldConfig: TodoConfiguredField = configuredFields[fieldInternal];

        if (fieldConfig == void 0) {
            fieldConfig = configuredFields[fieldDbName];
        }

        if (fieldConfig != void 0) {
            if (fieldConfig.type == "date" || fieldConfig.type == "datetime") {
                fieldType = "DATETIME";
            } else if (fieldConfig.type == "decimal") {
                fieldType = "DECIMAL";
            }
        }

        if (fieldType == "date") {
            fieldType = "DATETIME";
        } else if (fieldType == "radio" || fieldType == "checkbox") {
            fieldType = "TEXT";
        }
        /**
         * pos is the position of the dms related columns.
         * these columns are numbered from 0 to n.
         * therefore we can add columns in any order
         */
        const pos: number = fieldConfig.position as number + firstNonStaticColumnIndex;

        if (!gridDataColumns[pos]) {
            gridDataColumns[pos] = {
                type: fieldType,
                // headerName: fieldInternal,
                headerName: fieldConfig.headerName,
                field: `f${pos}`,
                internalName: fieldInternal,
                editable: false,
                cellStyle: this.gridContentUtilsService.getColumnCellStyle
            };
        } else if (gridDataColumns[pos] && gridDataColumns[pos].type != fieldType) {
            gridDataColumns[pos].type = "TEXT";
        }
    }

    /**
     * Adds the cell to the rowTemplate
     * Dates will be converted to the configured dateformat and leading zeroes will be added if the field is configured as such a field
     *
     * @param value - the current value the cell will become
     * @param rowTemplate
     * @param firstNonStaticColumnIndex
     * @param configuredFields - the list of configured fields
     * @param configuredField - the configured field for we are adding the value
     */
    private addCellContent(value: string | number, rowTemplate: TodoRowTemplate, firstNonStaticColumnIndex: number,
                           configuredFields: { [key: string]: TodoConfiguredField }, configuredField: TodoConfiguredField): void {
        const type: string = configuredField.type;
        const internal: string = configuredField.internal;
        const dbName: string = configuredField.dbName;
        let fieldConfig: TodoConfiguredField = configuredFields[internal];

        if (fieldConfig == void 0) {
            fieldConfig = configuredFields[dbName];
        }

        let currentValue: string = value.toString();

        // check the position where the current column has to be
        const index: number = fieldConfig.position as number + firstNonStaticColumnIndex;
        const headerName: string = fieldConfig.headerName;
        const valueAsNumber: number = Number(currentValue);

        if (type == "date" || type == "datetime") {
            if (!isNaN(valueAsNumber) && currentValue != "") {
                const isDateTime: boolean = (type == "datetime");
                const sourceFormat: string = isDateTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY";
                currentValue = this.valueUtilsService.formatDate(currentValue, isDateTime, sourceFormat);
            } else if (isNaN(valueAsNumber)) {
                currentValue = this.valueUtilsService.dateToLocaleDate(currentValue);
            }
        } else if (type == "RADIO") {
            if (currentValue < "0") {
                currentValue = "";
            }
        } else if (configuredField != void 0 && type == "checkbox" && configuredField.trueVal != void 0 && configuredField.falseVal != void 0) {
            currentValue = currentValue == "1" ? configuredField.trueVal : configuredField.falseVal;
        } else if (configuredField != void 0 && configuredField.hasLeadingZeros) {
            currentValue = this.valueUtilsService.formatLeadingZeros(currentValue, configuredField.maxLength);
        }

        rowTemplate[`f${index}`] = {
            value: currentValue,
            colIndex: index,
            headerName
        };
    }

    /**
     * adds empty cells to each row for sorting purposes
     * in mixed lists we need a column index at each cell to get the defined column and its datatype
     */
    private static addEmptyPlaceholderCells(gridData: GridData, staticColumnCount: number): void {
        const maxColumns: number = gridData.columns.length;

        for (const row of gridData.rows) {
            for (let i: number = staticColumnCount; i < maxColumns; i++) {
                if (!row[`f${i}`]) {
                    row[`f${i}`] = {
                        value: "",
                        colIndex: i
                    };
                }
            }
        }
    }

    /**
     * adds static dms content to the cells like icons and the objecttype name
     */
    private addStaticDmsContent(dmsDocumentModel: DmsDocumentModel, rowTemplate: TodoRowTemplate, staticColumns: TodoStaticColumnData[], typeDef: ObjectType): void {
        for (const [index, col] of staticColumns.entries()) {
            const cell: string = `f${index}`;

            switch (col.columnType) {
                case "dmsIcon":
                    rowTemplate[cell] = this.gridContentUtilsService.getObjectTypeIcon(dmsDocumentModel);
                    break;
                case "signature" :
                    rowTemplate[cell] = this.gridContentUtilsService.getSignaturStateIcon(dmsDocumentModel);
                    break;
                case "mimeType" :
                    rowTemplate[cell] = this.gridContentUtilsService.getMimeTypeIcon(dmsDocumentModel);
                    break;
                case "favorite" :
                    rowTemplate[cell] = this.gridContentUtilsService.getFavoriteIcon(dmsDocumentModel);
                    break;
                case "archiveState" :
                    rowTemplate[cell] = this.gridContentUtilsService.getArchiveStateIcon(dmsDocumentModel);
                    break;
                case "lockState" :
                    rowTemplate[cell] = this.gridContentUtilsService.getLockStateIcon(dmsDocumentModel);
                    break;
                case "notes" :
                    rowTemplate[cell] = this.gridContentUtilsService.getNotesIcon(dmsDocumentModel);
                    break;
                case "links" :
                    rowTemplate[cell] = this.gridContentUtilsService.getLinksIcon(dmsDocumentModel);
                    break;
                case "annotations" :
                    rowTemplate[cell] = this.gridContentUtilsService.getAnnotationsIcon(dmsDocumentModel);
                    break;
                case "objectType" :
                    rowTemplate[cell] = this.gridContentUtilsService.getObjectTypeName(dmsDocumentModel, typeDef);
                    break;
            }
        }
    }

    /**
     * adds generic content to the rowTemplate for the configured fields
     */
    private addDmsFields(dmsDocumentModel: DmsDocumentModel, rowTemplate: TodoRowTemplate, gridDataColumns: ColumnDefinition[], currentColIndex: number, fieldCache: TodoFieldCache): void {
        const configuredFields: { [key: string]: TodoConfiguredField } = fieldCache.configured;

        if (Object.keys(configuredFields).length > 0) {
            for (const internal in dmsDocumentModel.fields) {
                const value: string = dmsDocumentModel.fields[internal];
                const fieldModel: FieldModel = fieldCache.fields[internal];

                if (configuredFields[internal] != void 0) {
                    this.updateColumnDefinition(fieldModel, currentColIndex, gridDataColumns, configuredFields);
                    this.addCellContent(value, rowTemplate, currentColIndex, configuredFields, fieldModel);
                }
            }
        }
    }
}
