(function () {
    require("COMPONENTS_PATH/eob-state-wrapper/eob-workflow/eob.workflow.dir.js");
    require("COMPONENTS_PATH/eob-workflow-files/eob.workflow.files.dir.js");
    require("COMPONENTS_PATH/eob-workflow-protocol/eob.workflow.protocol.dir.js");
    require("COMPONENTS_PATH/eob-workflow-circulation/eob.workflow.circulation.dir.js");

    angular.module("eob.client").controller("workflowController", WorkflowController);

    WorkflowController.$inject = ["$stateParams", "$state"];

    function WorkflowController($stateParams, $state) {
        if ($stateParams.state == void 0) {
            let stateId = $.now();
            let workflowID = $stateParams.id;

            $state.go("workflow", {id: workflowID, state: stateId})
        }
    }
})();