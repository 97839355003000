(function () {

    require("ag-grid-enterprise/dist/ag-grid-enterprise.min.js");

    angular.module("eob.framework").directive("eobWorkflowProtocol", EobWorkflowProtocol);

    EobWorkflowProtocol.$inject = ["$filter"];

    function EobWorkflowProtocol($filter) {
        return {
            restrict: "E",
            scope: {
                formHelper: "=formHelper",
                protocol: "="
            },
            link(scope) {
                if (scope.protocol == void 0) {
                    return null;
                }

                let translate = $filter("translate");
                let dictionary = {
                    "Datum": translate("eob.workflow.protocol.header.date"),
                    "Uhrzeit": translate("eob.workflow.protocol.header.time"),
                    "Aktivität": translate("eob.workflow.protocol.header.activity"),
                    "Benutzer": translate("eob.workflow.protocol.header.user"),
                    "Protokoll": translate("eob.workflow.protocol.header.protocol")
                };

                let protocol = scope.protocol.value,
                    columns = protocol.columns,
                    rows = protocol.rows;

                let columnDefs = [];
                for (let i = 0; i < columns.length; i++) {
                    let name = columns[i].name,
                        headerName = dictionary[name] || name;

                    let columnDef = {
                        headerName,
                        field: name
                    };

                    columnDefs.push(columnDef);
                }

                let rowData = [];
                for (let k = 0; k < rows.length; k++) {
                    let row = rows[k];
                    let data = {
                        selected: true
                    };

                    for (let j = 0; j < columnDefs.length; j++) {
                        data[columnDefs[j].field] = row.content[j];
                    }

                    rowData.push(data);
                }

                for (let col of columnDefs) {
                    col.cellRenderer = (params) => {
                        let value = params.data[params.colDef.field];
                        let container = angular.element("<span class=cell-value></span>");
                        container.append(value);
                        return container[0];
                    }
                }

                scope.gridOptions = {
                    defaultColDef: {
                        resizable: true,
                        sortable: true,
                        filter: true,
                        suppressMenu: true
                    },
                    columnDefs,
                    rowData,
                    groupUseEntireRow: true,
                    rowHeight: 32,
                    headerHeight: 32,
                    suppressContextMenu: true,
                    suppressNoRowsOverlay: true,
                    suppressDragLeaveHidesColumns: true,
                    rowBuffer: 20,
                    onGridSizeChanged: sizeToFit,
                    onGridReady: (params) =>{
                        scope.gridOptions.api = params.api;
                        scope.formHelper.setProtocolGrid(scope.gridOptions);
                    }
                };

                function sizeToFit() {
                    if (scope.gridOptions.api != void 0) {
                        scope.gridOptions.api.sizeColumnsToFit();
                    }
                }
            },
            template: require("!raw-loader!./eob.workflow.protocol.html")
        }
    }
})();
