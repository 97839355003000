import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

(function () {

    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.state.history.manager.srv.js");

    angular.module("eob.framework").directive("eobModalAddFavPrompt", EobModalAddFavPrompt);

    EobModalAddFavPrompt.$inject = ["environmentService", "notificationsService", "$filter", "$http", "$timeout", "$q", "profileService", "clientService", "messageService"];

    function EobModalAddFavPrompt(EnvironmentService, NotificationsService, $filter, $http, $timeout, $q, ProfileService, ClientService, MessageService) {
        return {
            restrict: "E",
            scope: {
                objId: "="
            },
            link (scope, element, attrs) {
                if (!ClientService.isLocalClient()) {
                    return;
                }
                scope.mobileSettings = EnvironmentService.getMobileSettings();
                scope.rememberChoice = false;

                scope.confirm = function (toast) {
                    if (scope.rememberChoice) {
                        scope.mobileSettings.autofav = "yes";
                        EnvironmentService.setMobileSettings(scope.mobileSettings);
                    }

                    let cancel = $q.defer();

                    $timeout(() => {
                        cancel.resolve();
                    }, 5000);

                    // eslint-disable-next-line promise/catch-or-return
                    $http.post(`${ProfileService.getCurrentBaseUrl()}/osrest/api/documents/favourites/add/${scope.objId}`, "", {
                        noretry: true,
                        timeout: cancel.promise,
                        headers: {"Content-Type": undefined}
                    }).then((res) => {
                        if (toast) {
                            if (res.status !== 200) {
                                NotificationsService.error($filter("translate")("eob.action.notification.fav.add.error"));
                            } else {
                                NotificationsService.success($filter("translate")("eob.action.notification.fav.prompt.add.success"));
                            }
                        }
                    }, (error) => {
                        NotificationsService.backendError(error, "eob.action.notification.fav.add.error");
                    });

                    scope.$destroy();
                };

                scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

                scope.reject = function () {
                    if (scope.rememberChoice) {
                        if (scope.rememberChoice) {
                            scope.mobileSettings.autofav = "no";
                            EnvironmentService.setMobileSettings(scope.mobileSettings);
                        }
                    }
                    scope.close();
                };

                scope.work = function () {
                    if (scope.mobileSettings.autofav === "yes") {
                        scope.confirm(false);
                    } else if (scope.mobileSettings.autofav === "no") {
                        scope.$destroy();
                    }
                };
                scope.work();
            },
            template: require("!raw-loader!./eob.modal.add.fav.prompt.html")
        };
    }
})();
