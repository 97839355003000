/**
 * Systemfields supported bei DMS2
 */
export enum SystemFields {

    // system fields supported by osrest queries along with the DMS2 equivalent

    CREATOR = "system:createdBy",
    CREATIONDATE = "system:creationDate",
    MODIFIER = "system:lastModifiedBy",
    MODIFIED = "system:lastModificationDate",
    ARCHIVESTATE = "system:OBJECT_FLAGS",
    ARCHIVIST = "system:OBJECT_AVID",
    ARCHIVEDATE = "system:OBJECT_AVDATE",
    LINKS = "system:OBJECT_LINKS",
    SYSTEMID = "system:OBJECT_SYSTEMID",
    OWNER = "system:OBJECT_USERGUID",

    RETENTIONPLANNEDDATE = "system:OBJECT_RETENTION_PLANNED",
    RETENTIONDATE = "system:OBJECT_RETENTION",
    PDFANNOTATIONCOUNT = "system:OBJECT_PDFANNOTATIONCOUNT",
    TXTNOTICECOUNT = "system:OBJECT_TXTNOTICECOUNT",
    OBJECT_COUNT = "system:OBJECT_COUNT",
    OBJECT_MAIN = "system:OBJECT_MAIN",
    OBJECT_CO = "system:OBJECT_CO",
    OBJECT_MEDDOCID = "system:OBJECT_MEDDOCID",
    OBJECT_MEDDIAID = "system:OBJECT_MEDDIAID",
    OBJECT_MEDDOCNA = "system:OBJECT_MEDDOCNA",
    OBJECT_MEDDIANA = "system:OBJECT_MEDDIANA",
    versionLabel = "system:versionLabel",
    versionSeriesCheckedOutBy = "system:versionSeriesCheckedOutBy",
    OBJECT_FOREIGNID = "system:OBJECT_FOREIGNID",
    OBJECT_DELETED = "system:OBJECT_DELETED",
    OBJECT_INDEXHISTFLAGS = "system:OBJECT_INDEXHISTFLAGS",
    OBJECT_DOCHISTFLAGS = "system:OBJECT_DOCHISTFLAGS",
    OBJECT_OSSD = "system:OBJECT_OSSD",
    OBJECT_MIMETYPEID = "system:OBJECT_MIMETYPEID",
    OBJECT_FILESIZE = "system:OBJECT_FILESIZE",
    OBJECT_SIGNSTATE = "system:OBJECT_SIGNSTATE",
    STAMM_ID = "system:STAMM_ID",
    STAMM_TIME = "system:STAMM_TIME",
    STAMM_LINKS = "system:STAMM_LINKS",
    REG_ID = "system:REG_ID",
    REG_STAID = "system:REG_STAID",
    REG_PARID = "system:REG_PARID",
    SDSTA_ID = "system:SDSTA_ID",
    SDOBJ_ID = "system:SDOBJ_ID",
    SDOBJTYPE = "system:SDOBJTYPE",
    SDREG_ID = "system:SDREG_ID",
    SDDEL = "system:SDDEL",
    SDTIME = "system:SDTIME",
    SDREG_TYPE = "system:SDREG_TYPE",
    FOLDERID = "system:FOLDERID",
    FOLDERTYPE = "system:FOLDERTYPE",
    REGISTERID = "system:REGISTERID",
    REGISTERTYPE = "system:REGISTERTYPE",
    PARENTREGID	 = "system:PARENTREGID",
    PARENTREGTYPE = "system:PARENTREGTYPE",
    ICON_ID = "system:ICON_ID"
}