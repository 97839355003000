import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {Inject, Injectable} from "@angular/core";
import {
    ContextMenuAction,
    ContextMenuActionsProvider,
    ObjectReferenceContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {defer, from, Observable} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {ContextMenuActionsService} from "MODULES_PATH/context-menu/services/context-menu-actions.service";
import {TodoCacheManagerService, TodoEnvironmentService, TodoModalDialogService} from "INTERFACES_PATH/any.types";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {DmsActionService} from "MODULES_PATH/dms/dms-action.service";
import {MultiMenuService} from "MODULES_PATH/context-menu/services/multi-menu.service";

@Injectable({
    providedIn: ContextMenuModule
})
export class ObjectReferencesContextMenuService implements ContextMenuActionsProvider {
    private contextMenuActions: ContextMenuAction[] = [];

    constructor(private contextMenuUtilsService: ContextMenuUtilsService,
                private contextMenuActionsService: ContextMenuActionsService,
                private clientService: ClientService, private messageService: MessageService,
                private dmsActionService: DmsActionService, private multiMenuService: MultiMenuService,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
    ) {
    }

    getSingleSelectionContextMenuActions(contextMenuItem: ObjectReferenceContextMenuItem, contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            this.contextMenuActions = [];

            this.addObjectReferenceSpecificActions([contextMenuItem], contextData);

            if (this.contextMenuActions.length > 0) {
                this.contextMenuActions.push(this.contextMenuUtilsService.addDivider());
            }

            await this.contextMenuActionsService.getSingleselectItems(contextMenuItem, this.contextMenuActions, "objectReferences", contextData);

            return this.contextMenuActions;
        });
    }

    getMultiselectionContextMenuActions(contextMenuItems: ObjectReferenceContextMenuItem[], contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            this.contextMenuActions = [];

            this.addObjectReferenceSpecificActions(contextMenuItems, contextData);

            if (this.contextMenuActions.length > 0) {
                this.contextMenuActions.push(this.contextMenuUtilsService.addDivider());
            }

            await this.multiMenuService.getMultiselectActions(contextMenuItems, this.contextMenuActions, contextData.context, contextData);

            return this.contextMenuActions;
        });
    }

    private addObjectReferenceSpecificActions(contextMenuItems: ObjectReferenceContextMenuItem[], contextData: ContextData): void {
        if (!this.clientService.isOnline()) {
            return;
        }

        if ( this.environmentService.isObjectLinkNoteAllowed() && contextMenuItems.length == 1 && contextMenuItems[0].referenceType == "note") {
            this.addEditLink(contextMenuItems);
        }

        if (this.clientService.isOnline() && this.environmentService.featureSet.contains("dms.delete.links") && !contextMenuItems.filter(x => x.referenceType != "note").length) {
            this.addRemoveLink(contextMenuItems, contextData.parentId);
        }
    }

    private addEditLink(contextMenuItems: ObjectReferenceContextMenuItem[]): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.edit.link.text", "edit-link", async () => {
            const dmsDocument: DmsDocument = await this.cacheManagerService.dmsDocuments.getOrFetchById(contextMenuItems[0].osid);
            this.modalDialogService.createLinkObjectDialog(dmsDocument, true);
        }, true));
    }

    private addRemoveLink(contextMenuItems: ObjectReferenceContextMenuItem[], parentId: string): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction(`eob.contextmenu.action.link${contextMenuItems.length > 1 ? "s" : ""}.remove.title`, "delete-dark",
            () => {
                // Todo: See comment in dmsActionService.removeNoteLinks and refactor it.
                from(this.cacheManagerService.dmsDocuments.getOrFetchById(parentId)).pipe(
                    switchMap((doc: DmsDocument) => this.dmsActionService.removeNoteLinks(doc, contextMenuItems.map(x => ({
                            comment: x.referenceComment,
                            type: x.referenceType,
                            kind: "note",
                            idPair: {objectId: x.osid, objectTypeId: x.objectTypeId}
                        }))))
                ).subscribe(objectLinkResponse => {
                    this.messageService.broadcast(Broadcasts.OBJECT_LINKS_REMOVED, objectLinkResponse);
                }, _ => {/* dialog dismissed */
                });
            })
        );
    }
}