(function() {
    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.search.srv.js");

    require("SERVICES_PATH/eob.state.history.manager.srv.js");
    require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

    angular.module("eob.core").factory("locationService", LocationService);

    LocationService.$inject = ["objectTypeService", "backendService", "environmentService", "toolService", "clientService",
        "offlineCacheService", "offlineLocationCacheService", "searchService", "dmsDocumentService", "stateHistoryManager", "cacheManagerService",
        "backendObjectService"];

    // eslint-disable-next-line max-params
    function LocationService(ObjectTypeService, BackendService, EnvironmentService, ToolService, ClientService,
                             OfflineCacheService, OfflineLocationCacheService, SearchService, DmsDocumentService, StateHistoryManager,
                             CacheManagerService, BackendObjectService) {
        return {
            getLocationPathAsync,
            exploreFolderAsync,
            buildTree,
            findRootNode,
            loadRegisterDetailsAsync,
            addSelectedItemsAsync
        };

        /**
         * Get the representativ path to a specific location of an ecmObject.
         *
         * @param {{objectId, objectTypeId}} dmsItem - A wrapper object for ecmObject properties.
         * @param {int} parentId - The os ID of the requested parent register or folder.
         * @param {object[]=} parents - The parents of the ecmObject. If not given, they will be requested with a backend call.
         * @returns {Promise<{ objectId, objectTypeId }[]>} abc.
         */
        async function getLocationPathAsync({ objectId, objectTypeId }, parentId, parents) {
            if (parents == void 0) {
                parents = await BackendObjectService.getDmsObjectLocations(objectId, objectTypeId).toPromise();
            }

            for (const pathItem of parents) {
                for (const pathEntry of pathItem) {
                    if (pathEntry.objectId == parentId) {
                        return pathItem;
                    }
                }
            }
        }

        function getExplorePayload(folderId, cabinetId) {
            let explorePayload = {
                osid: folderId.toString(),
                fieldsschema_mode: "ALL",
                childschema_mode: "DEF",
                childfieldsschema_mode: "DEF",
                children: []
            };

            cabinetId = CacheManagerService.objectTypes.getById(cabinetId).model.config.cabinetId;

            let insertable = CacheManagerService.objectTypes.getById(cabinetId).model.config.childTypes;

            for (let i in insertable) {
                let objectTypeId = insertable[i];
                let conf = CacheManagerService.objectTypes.getById(objectTypeId).api.getConfiguredFields();

                let child = {
                    objectTypeId: insertable[i],
                    fieldsschema: []
                };

                for (let j in conf) {
                    child.fieldsschema.push({
                        internalName: conf[j].internal
                    });
                }

                if (ObjectTypeService.isDocumentType(objectTypeId)) {
                    child.fieldsschema.push({
                        internalName: "OBJECT_SIGNSTATE",
                        system: true
                    });
                }

                explorePayload.children.push(child);
            }

            return explorePayload;
        }

        async function exploreFolderAsync(osid, objectTypeId, getRegisterTree) {
            if (ClientService.isOffline() && ClientService.isLocalClient()) {
                return OfflineLocationCacheService.exploreFolderOrRegister(osid, getRegisterTree);
            }

            let cabinetId = CacheManagerService.objectTypes.getById(objectTypeId).model.config.cabinetId;
            let payload = getExplorePayload(osid, cabinetId);

            let url = `/documents/explore?pagesize=${EnvironmentService.env.hitlist.maxsize}&rights=true&status=true`;

            if (getRegisterTree) {
                url += "&registertreeids=true";
            }

            return (await BackendService.post(url, payload)).data;
        }

        /** Reduce the tree data by the objecttypes the user is not allowed to see
         * @param {Array} nodes - The nodes of each level of the tree
         */
        function reduceTree(nodes) {
            for (let i = nodes.length - 1; i >= 0; i--) {
                let node = nodes[i];

                if (!CacheManagerService.objectTypes.contains(node.objectTypeId) || (node.rights && node.rights.objExport == false)) {
                    nodes.splice(i, 1);
                    continue;
                }

                if (node.childRegister != void 0 && node.childRegister.length > 0) {
                    reduceTree(node.childRegister);
                }
            }
        }

        /**
         * Build the tree from the backend data and expand the nodes we need
         * @param   {Array | dmsDocument} dmsDocuments - An Array of dmsDocuments
         * @param   {Array} folderRegisterTree - The register tree structure
         * @param   {Object} state - The current state data
         * @returns {Array | Object} - Returns the root note inside an array to iterate it later inside the virtual tree instance
         */
        function buildTree(dmsDocuments, folderRegisterTree, state) {
            let rootNode = null;
            let path = state.data.config.path == void 0 || state.data.config.path == "" ? [] : state.data.config.path;
            let dmsDocumentModel = dmsDocuments[0].model;

            reduceTree(folderRegisterTree);

            path = angular.copy(path);

            if (dmsDocumentModel.isFolder) {
                rootNode = folderRegisterTree[0];
            } else if (path.length > 0) {
                rootNode = findRootNode(path[0], folderRegisterTree);
            } else {
                rootNode = findRootNode(dmsDocumentModel, folderRegisterTree);
            }

            let rootChildren = rootNode.childRegister;
            let root = {
                childRegister: rootChildren,
                name: dmsDocuments[0].api.buildNameFromIndexData(10, false, false),
                osid: rootNode.osid,
                objectTypeId: rootNode.objectTypeId,
                expanded: true,
                isCollapsible: false
            };

            let treeData = [root];

            if (path.length > 0) {
                root.osid = path[0].objectId;
                root.objectTypeId = path[0].objectTypeId;
            } else {
                root.isSelected = true;
            }

            path.shift(); // remove the root node for we already know it

            let index = 0,
                currentNodes = root.childRegister,
                hasNext = path.length > 0 && currentNodes != void 0;

            while (hasNext) {
                let foundMatch = false;
                for (let i in currentNodes) {
                    let node = currentNodes[i];

                    if (node.osid == path[index].objectId) {
                        node.expanded = true;
                        foundMatch = true;
                        currentNodes = node.childRegister;
                        index++;
                        if (path[index] == void 0) {
                            hasNext = false;
                            node.isSelected = true;
                        }

                        break;
                    }
                }

                if (!foundMatch) {
                    hasNext = false;
                }
            }

            return treeData;
        }

        /**
         * Reduce the tree by the nodes we do not see and return the part of the tree the user wants /has to see
         * @param {{ objectId, objectTypeId } path - the complete tree information
         * @param {Array} nodes - the partial tree
         * @returns {Object} - returns the root node object
         */
        function findRootNode(path, nodes) {
            let tree = nodes;

            // The user starts at the cabinet which is the root of this evil
            // Todo: path could here by IdPair or a complete DmsModel. This is not nice. One function should
            //       accept one type of argument per parameter and not a branch of. Refactor it once this is
            //       moved to type script.
            if (path.objectType == "FOLDER") {
                return path;
            }

            for (let i in nodes) {
                if (nodes[i].osid == path.objectId) {
                    return nodes[i];
                }

                if (nodes[i].childRegister && nodes[i].childRegister.length) {
                    tree = findRootNode(path, nodes[i].childRegister);

                    if (tree != void 0) {
                        return tree;
                    }
                }
            }

            // the requested node doesn't exist anymore, return the top node
            if (tree == void 0) {
                return nodes[0];
            }
        }

        async function loadRegisterDetailsAsync(registerList) {
            if (ClientService.isOnline()) {
                let {result, warning, error} = await SearchService.searchByIdsAsync(registerList);

                if (warning) {
                    this.notificationsService.warning(warning);
                }

                if (error) {
                    this.notificationsService.error(error);
                }

                return result;
            }

            const registerIds = registerList.map(register => register.osid);
            const registers = await OfflineCacheService.getByIds(registerIds);

            const osids = CacheManagerService.dmsDocuments.add(registers);
            return CacheManagerService.dmsDocuments.get(osids);
        }

        /**
         * Add dmsDocuments that may be missing due to a hitlist size limit.
         *
         * @param {Object} documentIds - An object with the osids of the shown dms objects in the hitlist as properties.
         * @param {string=} exploreId - An dms object with this osid may not be added. For example the folder osid of the folder, which content shall be displayed.
         * @returns {Promise<void>} Resolved once all dms objects are added.
         */
        async function addSelectedItemsAsync(documentIds, exploreId) {
            if (ClientService.isOffline()) {
                return;
            }

            let state = StateHistoryManager.getCurrentStateData(),
                selectedItems = state.data.config.selectedItems || {};

            // DODO-7589: If the maximum hit list items are reached we must ensure, that the selected object is in it.
            // We select a object and say "open location" and if the object isn't inside because max hitlist amount
            // the user ask where is my selected item...
            // Todo: We should discuss this in a architectural meeting because I think this is expensive and slow down
            //       our open location action and this state is already very slow!
            for (let selectedItemId in selectedItems) {
                if (exploreId && exploreId == selectedItemId || !(await doesBelongToLocation(exploreId, selectedItemId))) {
                    continue;
                }

                if (!documentIds.includes(selectedItemId)) {
                    try {
                        let preRequestedDoc = await SearchService.searchById(selectedItemId);

                        if (preRequestedDoc != void 0) {
                            documentIds.push(selectedItemId);
                            CacheManagerService.dmsDocuments.add(preRequestedDoc);
                        }
                    } catch(error) { /* empty */ }
                }
            }
        }

        /**
         * Checks if the document belongs to this location.
         *
         * @param  {string | number} locationId - Specifies a folder or register.
         * @param  {string | number} documentId
         * @return {boolean}
         */
        async function doesBelongToLocation(locationId, documentId) {
            let dmsDocument = await CacheManagerService.dmsDocuments.getOrFetchById(documentId);

            if (dmsDocument != void 0) {
                let locations = await BackendObjectService.getDmsObjectLocations(dmsDocument.model.osid, dmsDocument.model.objectTypeId).toPromise();

                if (Array.isArray(locations)) {
                    for (const locationPath of locations) {
                        if (locationPath.find(location => location.objectId == locationId)) {
                            return true;
                        }
                    }
                }
            }

            return false;
        }
    }
})();
