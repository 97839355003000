import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";

angular.module("eob.core").factory("pageControlApiService", PageControlApiService);

PageControlApiService.$inject = ["clientService", "clientScriptService", "messageService"];

/**
 * A service that creates a form pagecontrol element
 */
export default function PageControlApiService(ClientService, ClientScriptService, MessageService) {

    return {
        addPageControlApi
    };

    function addPageControlApi(api, field, formHelper) {

        let isScrollButtonVisible = false;

        /**
         * Sets the title for each page control tab.
         *
         * @param {string[]} titles is a list of for each tab title.
         */
        api.setTitle = (titles) => {
            if (!Array.isArray(titles)) {
                console.warn("array must be passed to set page control tab titles");
                return;
            }

            let element = api.getElement();
            let pages = element.find("ul > li");
            for (let i = 0; i < pages.length && i < titles.length; i++) {
                let pageTitle = pages[i].querySelector("button > span");
                pageTitle.innerText = titles[i];
            }

            MessageService.broadcast(FormEvent.HANDLE_PAGECONTROL_BUTTONS);
        }

        /**
         * Returns all fields of all page ctrl pages.
         *
         * @returns {FormField[]} A list of FormFields.
         */
        api.getFields = () => {
            let content = [];

            for (let page of field.model.pages) {
                content = content.concat(page.fields);
            }

            return content;
        };

        /**
         * opens the desired page of the pagecontrol
         * @param page {object|index|name} the field model or the pageindex
         * @param focusFirst {boolean} set this to focus the first element inside the pagecontrol
         * @isInitialPage {boolean} true if the mask was just entered and the default/first page is being shown
         */
        api.goToPage = function(page, focusFirst, isInitialPage) {
            // don't do the autofocus on mobile device for pagecontrol

            if (ClientService.isTouchDevice()) {
                focusFirst = false;
            }
            let nextPage = api.getPage(page);

            if (nextPage == void 0) {
                return;
            }

            if (isInitialPage && (nextPage.isDisabled || nextPage.isInvisible)) {
                nextPage = api.getPage(getNextPage());
            } else if (nextPage.isDisabled || nextPage.isInvisible) {
                return;
            }

            if (nextPage == void 0) {
                return;
            }

            if (nextPage.isInvisible) {
                console.warn("You cannot go to a page that is not visible");
                return;
            }

            let activePage = field.api.getActivePage();
            let leaveEvent, enterEvent;

            if (nextPage.pageId == activePage.pageId) {
                return;
            }

            if (field.eventScripts != void 0) {
                leaveEvent = field.eventScripts["onLeavePage"];
                enterEvent = field.eventScripts["onEnterPage"];
            }

            if (leaveEvent != void 0 && typeof (leaveEvent) == "function") {
                leaveEvent(formHelper, formHelper.globals, ClientScriptService.getGlobalScriptingStorage(), field, activePage);
            }

            activePage.active = false;
            nextPage.active = true;

            // this function callback is inside the page control field
            // for digest reasons .. otherwise the page would not change
            field.api.setPageActive(Number(nextPage.index));

            if (focusFirst) {
                field.api.focusFirst();
            }

            if (enterEvent != void 0 && typeof (enterEvent) == "function") {
                enterEvent(formHelper, formHelper.globals, ClientScriptService.getGlobalScriptingStorage(), field, nextPage);
            }
        };

        /**
         * Returns the pageObject from a name or index
         * @param page
         * @returns {Object}
         */
        api.getPage = function(page) {
            let index;
            let pages = field.model.pages;

            if (!isNaN(Number(page))) {
                index = page;
            } else if (typeof (page) == "object" && page.index != void 0) {
                index = page.index;
            } else if (typeof (page) == "string") {
                for (let j in pages) {
                    if (pages[j].internal == page || pages[j].title == page) {
                        index = pages[j].index;
                    }
                }
            }

            if (pages[index] == void 0) {
                if (index == void 0) {
                    index = page;
                }

                console.warn("The required page does not exist --> ", index);
                return null;
            }

            return pages[index];
        };

        /**
         * returns the active page from the pagecontrol
         * @returns {Object}
         */
        api.getActivePage = function() {
            return field.model.pages[field.model.activePageIndex];
        };

        /**
         * focusses the first element of the pagecontrol
         */
        api.focusFirst = function() {
            let fields = formHelper.getFieldsFromPageControl(field.model.internal, field.model.activePageIndex);

            let firstInvalidField = null;
            let firstFocusableField = null;

            for (let i in fields) {
                if (firstInvalidField == void 0 && fields[i].isValid == false) {
                    firstInvalidField = fields[i];
                    break;
                }

                if (firstFocusableField == void 0 && fields[i].api != void 0 && fields[i].api.focus != void 0) {
                    firstFocusableField = fields[i];
                }
            }

            let firstField = firstInvalidField || firstFocusableField;

            if (firstField != void 0 && typeof (firstField) == "object" && firstField.api && firstField.api.focus) {
                firstField.api.focus();
            }
        };

        /**
         * hides the whole pagecontrol
         */
        api.hide = function() {
            let element = api.getElement();

            element.hide();

            field.isInvisible = true;
        };

        /**
         * shows the pagecontrol
         */
        api.show = function() {
            let element = api.getElement();

            if (getNextPage() != null) {
                element.show();
                field.isInvisible = false;
            } else {
                console.warn("All pages are hidden --> cannot show the page control");
            }
        };

        /**
         * Is the whole pagecontrol visible?
         *
         * @returns {boolean} true if it is visible, else false
         */
        api.isVisible = function() {
            if (!isParentVisible(field, formHelper)) {
                return false;
            }

            return (getNextPage() != null && (field.isInvisible == void 0 || field.isInvisible == false));
        };

        /**
         * Hides the given page
         * @param page - The desired Page to hide
         * The page can be the Index, the pageObject or the display name
         */
        api.hidePage = function(page) {
            let element = api.getElement();
            let pageElements = element.find("ul>li");
            let pageObj = api.getPage(page);

            if (pageObj == null) {
                return;
            }

            pageObj.isInvisible = true;
            angular.element(pageElements[pageObj.index]).hide();

            // we are hiding the current selected page .. :(
            if (pageObj.active) {
                let nextPage = getNextPage();

                if (nextPage != null) {
                    api.goToPage(nextPage, true);
                } else {
                    console.warn("All pages are hidden --> hide the whole page control");

                    api.hide();
                }
            }
        };

        /**
         * Shows the given page
         * @param page - The desired Page to show
         * The page can be the Index, the pageObject or the display name
         */
        api.showPage = function(page) {
            let element = api.getElement();
            let pages = element.find("ul>li");
            let pageObj = api.getPage(page);

            if (pageObj == null) {
                return;
            }

            pageObj.isInvisible = false;
            angular.element(pages[pageObj.index]).show();

            if (field.isInvisible) {
                console.warn("PageControl is not visible --> show it");
                api.show();
                api.goToPage(pageObj, true);
            }
        };

        /**
         * Is a given page of the pagecontrol visible?
         *
         * @returns {boolean} true if it is visible, else false
         */
        api.isPageVisible = function(page) {
            if (!api.isVisible()) {
                return false;
            }

            let pageObj = api.getPage(page);

            if (pageObj == null) {
                return false;
            }

            return (pageObj.isInvisible == void 0 || pageObj.isInvisible == false);
        };

        /**
         * enables the whole pagecontrol
         */
        api.enable = function() {
            let pages = field.model.pages;

            for (let i in pages) {
                api.enablePage(i);
            }

            field.isDisabled = false;
        };

        /**
         * disables the whole pagecontrol
         */
        api.disable = function() {
            let pages = field.model.pages;

            for (let i = pages.length - 1; i >= 0; i--) {
                api.disablePage(i);
            }

            field.isDisabled = true;
        };

        /**
         * Is the whole pagecontrol enabled
         *
         * @returns {boolean} true if it is enabled, else false
         */
        api.isEnabled = function() {
            return (field.isDisabled == void 0 || field.isDisabled == false);
        };

        /**
         * Enables the given page
         * @param page - The desired Page to enable
         * The page can be the Index, the pageObject or the display name
         */
        api.enablePage = function(page) {
            let element = api.getElement();
            let pageTabs = element.find("ul>li");
            let pageObj = api.getPage(page);

            if (pageObj == null) {
                return;
            }

            // enable page-control tab
            pageObj.isDisabled = false;
            angular.element(pageTabs[pageObj.index]).removeClass("disabled");

            // enable all fields
            let pageContent = element.find(".page-control-page-content");
            let pageFormFields = angular.element(pageContent[pageObj.index]).find(".form-element");

            pageFormFields.removeClass("disabled");
            pageFormFields.find("input, textarea, button").removeAttr("readonly").removeAttr("disabled");
        };

        /**
         * Disables the given page
         * @param page - The desired Page to disable
         * The page can be the Index, the pageObject or the display name
         */
        api.disablePage = function(page) {
            let element = api.getElement();
            let pageTabs = element.find("ul>li");
            let pageObj = api.getPage(page);

            if (pageObj == null) {
                return;
            }

            pageObj.isDisabled = true;

            // disable page-control tab
            angular.element(pageTabs[pageObj.index]).addClass("disabled");

            // disable all fields
            let pageContent = element.find(".page-control-page-content");
            let pageFormFields = angular.element(pageContent[pageObj.index]).find(".form-element");

            pageFormFields.addClass("disabled");
            pageFormFields.find("input, textarea, button").attr("readonly", true).attr("disabled", true);

            if (pageObj.active) {
                let nextPage = getNextPage();

                if (nextPage != null) {
                    api.goToPage(nextPage, true);
                }
            }
        };

        /**
         * Is a given page of the pagecontrol enabled?
         *
         * @param page The desired Page to check
         * @returns {boolean} true if it is enabled, else false
         */
        api.isPageEnabled = function(page) {
            let pageObj = api.getPage(page);

            if (pageObj == null) {
                return false;
            }

            return (pageObj.isDisabled == void 0 || pageObj.isDisabled == false);
        };

        function getNextPage() {
            let pages = field.model.pages;
            let nextPage = null;

            for (let i in pages) {
                if (!pages[i].isInvisible && !pages[i].isDisabled) {
                    nextPage = pages[i];
                    break;
                }
            }

            return nextPage;
        }
    }

    function isParentVisible(field, formHelper) {
        if (field.model.parentPage == void 0) {
            return true;
        }

        let parentField = formHelper.getFieldByInternal(field.model.parentPage);

        if (field.model.pageIndex == void 0) {
            return parentField.api.isVisible();
        }

        // Pagecontrol
        return parentField.api.isPageVisible(field.model.pageIndex);
    }
}
