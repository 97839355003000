<div role="contentinfo">
    <div *ngFor="let footerconfig of footerConfigs">
        <button type="button" (click)="onClick(footerconfig.action)" class={{footerconfig.class}}
                [ngClass]="{'disabled' : saveDisabled}" [disabled]="saveDisabled">
            <eob-icon name={{footerconfig.icon.name}} title={{footerconfig.icon.title}} size="32"></eob-icon>
        </button>
    </div>
    <div *ngFor="let footerconfig of footerSearchSettingsConfigs">
        <button type="button" (click)="onClick(footerconfig.action)" class={{footerconfig.class}}>
            <eob-icon name={{footerconfig.icon.name}} title={{footerconfig.icon.title}} size="32"></eob-icon>
        </button>
    </div>
</div>