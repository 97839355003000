import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {takeWhile} from "rxjs/operators";

angular.module("eob.framework").directive("eobModalSelectPrinter", EobModalSelectPrinter);

EobModalSelectPrinter.$inject = ["messageService"];

export default function EobModalSelectPrinter(MessageService) {
    return {
        restrict: "E",
        scope: {
            printers: "=",
            resolve: "=",
            reject: "=",
            destroy$: "=destroy"
        },
        link(scope, element) {
            const subscription = MessageService.subscribeFirst(ModalEvents.DESTROY, () => {
                scope.close();
            });

            if(scope.printers.length == 1){
                scope.choosePrinter(scope.printers[0]);
            }
            if(scope.printers.length == 0){
                scope.choosePrinter("");
            }

            scope.choosePrinter = printer => {
                scope.resolve(printer.name);
                localStorage.setItem("defaultPrinter", printer.name);
                scope.close();
            }

            // close the dialog once it is canceled
            scope.close = function() {
                scope.reject();
                MessageService.broadcast(ModalEvents.DESTROY);
            };

            scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => {
                scope.reject();
                subscription.unsubscribe();
            });
        },
        template: require("!raw-loader!./eob.modal.select.printer.html")
    };
}
