import * as angular from "angular";

/**
 * instance of column controller
 * everything related to columns goes into this class
 */
export class VirtualGridColumnController {

    private gPadding = 16;
    private gTouchIcon = 40;

    private minimumColumnWidth = 32;
    private animationFrameRef: any;

    private headerValueGetter: any;
    private columns: any[] = [];
    private sortedColumns: any[];

    private autoSizeColumns: boolean;
    private recursionDepth: number;
    private treeColIndex: number;

    private isVerticalScrolling = false;

    private sortable = true;
    private suppressResizeDetection = false;

    private scrollbarWidth = 17;
    private minWidth: any = 0;
    private width: any = 0;

    private truncateCellContent = true;

    private rafId = 0;

    private rAF(cb): number {
        window.cancelAnimationFrame(this.rafId);
        this.rafId = window.requestAnimationFrame(cb);
        return this.rafId;
    }

    init = false;
    isResizing = false;
    isWindowResizing = false;
    resizeObserver = new MutationObserver((mutationsList, observer) => {
        if(mutationsList.find(x => x.type == "attributes" && x.attributeName == "style")) {
            if (this.isWindowResizing) {
                return;
            }

            if (!this.init) {
                this.init = true;
                this.updateCellWidth();
                cancelAnimationFrame(this.animationFrameRef);
                return;
            }

            if (!this.isResizing) {
                this.animationFrameRef = this.rAF(() => {
                    this.updateCellWidth();
                });

                window.addEventListener("mouseup", () => {
                    this.isResizing = false;
                    cancelAnimationFrame(this.animationFrameRef);
                }, {once: true});

            }

            this.isResizing = true;
        }
    });


    constructor(protected grid: any, config: any) {
        this.columns = config.columns.slice();
        this.headerValueGetter = config.headerValueGetter;
        this.autoSizeColumns = config.autoSizeColumns;
        this.recursionDepth = 0;
        this.treeColIndex = 0;
        this.minWidth = config.minWidth;
        this.width = config.width;
        this.sortable = config.sortable;
        this.suppressResizeDetection = config.suppressResizeDetection;
        this.sortedColumns = [];

        this.truncateCellContent = config.truncateCellContent != void 0 ? config.truncateCellContent : true;
    }

    /**
     * updates the config properties
     *
     * @param config
     */
    updateConfigProperties = (config: any): void => {
        this.columns = config.columns.slice();
        this.headerValueGetter = config.headerValueGetter;
        this.autoSizeColumns = config.autoSizeColumns;
        this.minWidth = config.minWidth;
        this.width = config.width;
        this.sortable = config.sortable;
        this.suppressResizeDetection = config.suppressResizeDetection;
        this.truncateCellContent = config.truncateCellContent != void 0 ? config.truncateCellContent : true;
    };

    /**
     * recreates the whole header
     *
     * calculates width
     * creates dom nodes, sets values and appends the nodes to the header element
     *
     */
    buildColumns = (): void => {

        // calculating styles (not setting them)
        this.calculateColumnWidth();

        this.calculateGridWidth();

        this.buildHeader();

        if (!this.suppressResizeDetection) {
            this.bindResizeSensor();
        }
    };

    /**
     * refreshes the header and each headercell
     * replaces the headervalue or calls the headerValueGetter
     */
    refreshColumns = (): void => {

        this.calculateMinWidth();
        this.setCellWidth();

        for (const index in this.columns) {
            const col: any = this.columns[index];

            col.colIndex = index;

            const headerCell: JQuery = col.headerCell;

            let headerValue = "";

            if (this.grid.showHeader && this.headerValueGetter && typeof (this.headerValueGetter) == "function") {
                headerValue = this.headerValueGetter({col, api: this.grid.api});
            } else {
                headerValue = col.headerValue;
            }

            headerCell.find(".header-cell-text")[0].innerHTML = headerValue;
        }
    };

    /**
     * finds the maximum depth level and adds it to the grid for later indentation purposes
     *
     * @param {Array<any>} rows
     * @param {number} nodeLevel
     */
    getMaxRecursionDepth = (rows: any[], nodeLevel: number = 0): void => {
        for (let i = 0; i < this.grid.columnController.columns.length; i++) {
            const col: any = this.grid.columnController.columns[i];

            if (col.showAsTree) {
                this.treeColIndex = i;
            }
        }

        for (const row of rows) {

            const node: any = row;
            node.level = nodeLevel;

            this.recursionDepth = node.level > this.recursionDepth ? node.level : this.recursionDepth;

            if (node[this.grid.childNodesKey]) {
                this.getMaxRecursionDepth(node[this.grid.childNodesKey], nodeLevel + 1);
            }
        }
    };

    private getCellContentWidth(col: any, row: any): number {
        let cellWidth = 0;

        cellWidth += row[col.field] != void 0 ? this.grid.injections.FormLayoutService.getTextWidthInPixel(row[col.field]) : 0;

        if (col.showAsTree) {
            cellWidth += this.recursionDepth * this.gPadding;
            if (this.grid.isTouch) {
                cellWidth += this.gPadding + this.gTouchIcon;
            } else {
                cellWidth += 2 * this.gPadding;
            }
        }

        if (col.isIconColumn) {
            cellWidth += this.gPadding;
        }

        if (!col.isIconColumn && !col.showAsTree) {
            cellWidth += this.gPadding;
        }

        return cellWidth;
    }

    private calculateMinWidth(): void {
        for (const row of this.grid.rows) {

            for (const col of this.columns) {

                if (this.truncateCellContent) {
                    col.minWidth = this.minimumColumnWidth;
                    continue;
                }

                // calculate the width of the cell to apply the minimum column width
                let cellWidth = 0;

                if (col.minWidth == void 0) {
                    col.minWidth = 0;
                }

                cellWidth = this.getCellContentWidth(col, row);

                if (cellWidth > col.minWidth) {
                    col.minWidth = cellWidth;
                }
            }
        }
    }

    /**
     * this only calculates the width of each column but does not alter the css value of the headercell
     *
     * if the columns shall be autosized we calculate the approx. width by the width of the content using the FormlayoutService
     * otherwise we divide the available width by the columns given
     */
    private calculateColumnWidth(): void {

        console.log("calculate columns");
        this.calculateMinWidth();

        // the grid now has a scrollbar, so we have to create some space
        this.setVerticalScrolling();

        if (this.autoSizeColumns) {

            // now we calculate the approx. width of the columns by their contents
            for (const row of this.grid.rows) {

                for (const col of this.columns) {

                    if (col.suppressAutoSize) {
                        continue;
                    }

                    if (col.width == void 0) {
                        col.width = 0;
                    }

                    let cellWidth = 0;

                    cellWidth = this.getCellContentWidth(col, row);

                    if (cellWidth > col.maxWidth) {
                        col.width = col.maxWidth;
                    } else if (col.width < cellWidth) {
                        col.width = cellWidth;
                    }
                }
            }
            return;
        }

        let availableWidth: number = this.grid.config.scrollContainer.width();
        let unsizedColumns = 0; // we count the columns without a predefined width

        if (this.isVerticalScrolling) {
            availableWidth -= this.scrollbarWidth;
        }

        // first we need to find the maximum depth if the grid has tree columns
        // this is needed because the tree column will grow with each increase in depth by 16px
        // this has to be added onto the column size
        for (const col of this.columns) {
            if (col.width !== void 0) {
                availableWidth -= col.width;
            } else {
                unsizedColumns++;
            }
        }

        if (unsizedColumns > 0) {
            // there are columns without a defined width so we even the available space out down to the minimum of 40px
            for (const col of this.columns) {
                if (col.width === void 0) {
                    if (col.isPhoneCell) {
                        // no recursionDepth on phone -> use all available width for "f1" column
                        col.width = availableWidth;
                    } else {
                        const width: number = (availableWidth - this.recursionDepth * this.gPadding) / unsizedColumns;
                        col.width = width < this.minimumColumnWidth ? this.minimumColumnWidth : width;
                    }
                }
            }
        }

        // calculate the left of each column
        for (const col of this.columns) {
            // adding 12 pixel as place between cells, like a this.gPadding
            // col.width += 12;
            if (col.showAsTree) {
                col.width += this.recursionDepth * this.gPadding;
            }
        }
    }

    /**
     * calculate the tree width according to the cell values
     * the text length in pixel will be approximated by the layout service
     *
     * special condition is a width with a % in it --> this will overwrite the default behaviour
     */
    private calculateGridWidth(): void {
        console.log("calculate grid");
        if (this.width != void 0 && this.width != "" && this.width.indexOf("%") != -1) {
            this.grid.listElement.width(this.width);
            this.grid.headerWrapper.width(this.width);
            this.grid.listHeader.width(this.width);
            return;
        }

        let gridWidth = 0;

        for (const col of this.columns) {
            gridWidth += col.width;
        }

        if (gridWidth < this.minWidth) {
            gridWidth = this.minWidth;
        }

        this.grid.headerWrapper.css({width: gridWidth});
        this.grid.listHeader.css({"width": gridWidth, "min-width": gridWidth});
        this.grid.listElement.css({"width": gridWidth, "min-width": gridWidth});
        this.grid.gridWrapper.css({"width": gridWidth, "min-width": gridWidth});

        if (this.isVerticalScrolling) {
            this.grid.gridWrapper.css({
                "width": gridWidth + this.scrollbarWidth,
                "min-width": gridWidth + this.scrollbarWidth
            });
        }
    }

    /**
     * builds the header for the configured columns
     * sets values, binds events
     * appends nodes to the header element
     */
    private buildHeader(): void {

        console.log("build header");
        this.grid.listHeader.empty();

        for (const index in this.columns) {
            const col: any = this.columns[index];

            const headerCell: JQuery = angular.element(`<div class='header-cell' col-index='${index}'></div>`);
            const headerCellText: JQuery = angular.element(`<span class="header-cell-text">${col.headerValue}</span>`);
            const headerSortArrow: JQuery = angular.element("<i class='header-sort-arrow'></i>");

            headerCell.append(headerCellText);
            headerCell.append(headerSortArrow);

            if (!col.sortable && !this.sortable) {
                headerCell.bind("click", (event: JQueryEventObject) => {
                    this.sortColumn(col, headerCell, event);
                });
            }

            if (!col.suppressResize && Number(index) < this.columns.length) {
                const headerCellResizer: JQuery = angular.element("<div class='header-cell-resizer'></div>");
                headerCell.append(headerCellResizer);

                headerCellResizer.bind("mousedown", this.onResize);
            }

            headerCell.css({"width": col.width, "min-width": col.width});

            col.headerCell = headerCell;

            col.isAutoResize = !col.suppressResize;

            if (col.width > this.minimumColumnWidth) {
                col.canShrink = true;
            }

            this.grid.listHeader.append(headerCell);
        }
    }

    /**
     * returns the complete column width of all columns
     *
     * @returns {number}
     */
    private getCompleteColumnWidth(): number {
        let completeColumnWidth = 0;

        for (const col of this.columns) {
            completeColumnWidth += col.width;
        }

        return completeColumnWidth;
    }

    /**
     * returns an array of columns that are able to autosize their width
     * when the grid shrinks the columns can only shrink to the minimum columnwidth
     *
     * @param {number} startIndex - the starting index from where we have to collect the autosizable columns
     * @param {boolean} isGrowing - the indicator whether the grid is growing or not
     * @returns {any[]} - an array
     */
    private getAutoSizableColumns(startIndex: number, isGrowing: boolean): any[] {
        const cols: any[] = [];
        const modifier: number = startIndex == 0 ? 0 : 1;
        for (let i: number = startIndex + modifier; i < this.columns.length; i++) {
            const column: any = this.columns[i];

            if (!isGrowing && column.isAutoResize && column.canShrink) {
                cols.push(column);
            } else if (isGrowing && column.isAutoResize) {
                cols.push(column);
            }
        }
        return cols;
    }

    /**
     * calculates and updates the columnwidths of all columns starting from the startindex
     * at this point we calculate the relative width of a column once something changed according to the configuration
     * off all columns whether they can grow or shrink
     * the calculation starts periodically every 16ms caused by the use of a requestAnimationFrame
     *
     * @param {number} startIndex - the index to start calculating from
     */
    private updateCellWidth: (startIndex?: number) => void = (startIndex?: number): void => {

        startIndex = startIndex || 0;

        let gridWidth: number = this.grid.config.scrollContainer.width();
        let completeColumnWidth: number = this.getCompleteColumnWidth();

        if (this.isVerticalScrolling) {
            gridWidth -= this.scrollbarWidth;
        }

        const currentDiff: number = gridWidth - completeColumnWidth;
        // let autosizableCols: any[] = this.getAutoSizableColumns(startIndex)
        const autosizableCols: any[] = currentDiff > 0 ? this.getAutoSizableColumns(startIndex, true) : this.getAutoSizableColumns(startIndex, false);
        let diffPerColumn = 0;

        if (currentDiff < 0) {
            // some column changed in size, so the content of the grid does not fit into the viewport
            // at this point we try to shrink columns if there are columns that are able to shrink

            if (autosizableCols.length > 0) {
                // there are columns to shrink
                diffPerColumn = currentDiff / autosizableCols.length;
                // the content of the grid tries to grow even more and there is no column to shrink
                // therefore we must let the grid content and the header grow in size
            } else if (this.isVerticalScrolling) {
                // in this case the grid has two scrollbars and we need to add a space for the scrollbar
                // to not obscure the scroll behaviour
                this.grid.headerWrapper.css({width: completeColumnWidth + this.scrollbarWidth});
                this.grid.listHeader.css({width: completeColumnWidth + this.scrollbarWidth});
                this.grid.listElement.css({width: completeColumnWidth, minWidth: completeColumnWidth});
            } else {
                this.grid.headerWrapper.css({width: completeColumnWidth});
                this.grid.listHeader.css({width: completeColumnWidth, minWidth: completeColumnWidth});
                this.grid.listElement.css({width: completeColumnWidth, minWidth: completeColumnWidth});
            }

            this.adjustCellWidth(startIndex, diffPerColumn);
            this.setCellWidth();
            completeColumnWidth = this.getCompleteColumnWidth();
            if (autosizableCols.length > 0) {
                this.grid.headerWrapper.css({width: completeColumnWidth});
                this.grid.listHeader.css({width: completeColumnWidth});
                this.grid.listElement.css({width: completeColumnWidth});
            }
        } else if (currentDiff > 0) {
            if (autosizableCols.length > 0) {
                // there are columns to shrink
                diffPerColumn = currentDiff / autosizableCols.length;
                this.grid.headerWrapper.css({width: completeColumnWidth});
                this.grid.listHeader.css({width: completeColumnWidth});
                this.grid.listElement.css({width: completeColumnWidth});
            }

            this.adjustCellWidth(startIndex, diffPerColumn);
            this.setCellWidth();
        }

        if (diffPerColumn != 0) {
            const gridWrapperWidth: number = this.isVerticalScrolling ? this.grid.gridWrapper.width() - this.scrollbarWidth : this.grid.gridWrapper.width();
            const diff: number = gridWrapperWidth - this.getCompleteColumnWidth();

            if (Math.abs(diff) < 1e-10) {
                this.grid.headerWrapper.css({width: gridWrapperWidth});
                this.grid.listHeader.css({width: gridWrapperWidth});
                this.grid.listElement.css({width: gridWrapperWidth});
            }
        }

        let width: number = this.getCompleteColumnWidth();
        if (this.isVerticalScrolling) {
            width += this.scrollbarWidth;
        }

        // this.grid.gridWrapper.css({width, minWidth: width});

        if (this.grid.listElement.width() < this.grid.gridWrapper.width()) {
            this.grid.listElement.width(this.grid.gridWrapper.width());
        }

        this.animationFrameRef = this.rAF(() => {
            this.updateCellWidth(startIndex);
        });
    };

    /**
     * adjusts the cellwidth of each cell (including header cells) to the width of the header cell + the calculated difference per column
     *
     * @param {number} startIndex
     * @param {number} diffPerColumn
     */
    private adjustCellWidth(startIndex: number, diffPerColumn: number): void {
        for (let i: number = startIndex; i < this.columns.length; i++) {

            const currentColumn: any = this.columns[i];
            let width: number;
            if (currentColumn.suppressResize) {
                continue;
            }

            if (currentColumn.isAutoResize) {
                width = currentColumn.width as number + diffPerColumn;

                if (width <= currentColumn.minWidth) {
                    width = currentColumn.minWidth;
                    currentColumn.canShrink = false;
                } else {
                    currentColumn.canShrink = true;
                }

                currentColumn.width = width;
            }
        }
    }

    /**
     * set the column width to each cell
     */
    private setCellWidth(): void {
        for (let i = 0; i < this.columns.length; i++) {

            const currentColumn: any = this.columns[i];

            if (currentColumn.suppressResize) {
                continue;
            }

            currentColumn.headerCell.css({
                "width": currentColumn.width,
                "min-width": currentColumn.width,
                "max-width": currentColumn.width
            });

            for (const row of this.grid.renderedRows) {

                if (row.index < 0) {
                    break;
                }

                let width: number = currentColumn.width;

                // in case the previous column is shown as a tree we have to remove the level spacing
                // because only the following column gets special treatment to fit to the tree column
                if (i > this.treeColIndex && this.columns[i].showAsTree) {
                    width -= (this.gPadding * this.grid.rows[row.index].level);
                }

                if (width <= currentColumn.minWidth) {
                    width = currentColumn.minWidth;
                }

                row.cells[i].cellNode.css({width, "min-width": width, "max-width": width});
            }
        }
    }

    /**
     * the resize event of the header resize handle
     *
     * @param {JQueryEventObject} event
     */
    private onResize = (event: JQueryEventObject): void => {

        let currentX: number = event.screenX;
        const headerCell: any = angular.element(event.target).closest(".header-cell");
        const index = Number(headerCell.attr("col-index"));
        const col: any = this.columns[index];

        this.animationFrameRef = this.rAF(() => {
            this.updateCellWidth(index);
        });

        angular.element(window).bind("mousemove", (event: JQueryEventObject) => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            col.isAutoResize = false;
            col.canShrink = false;

            let diff: number = -(currentX - event.screenX);

            if (diff == 0) {
                return;
            }

            if (col.width <= this.minimumColumnWidth && diff < 0) {
                diff = -(col.width - this.minimumColumnWidth);
            }

            col.width += diff;

            // at this point the column is no longer part of the autosizing columns and we lock its width
            // to ensure the browser does not start automatic calculating some widths according to its rendering algorithm
            // chrome does this when we only set the width due to miscalculations while rounding the width property
            headerCell.css({"width": col.width, "min-width": col.width, "max-width": col.width});

            currentX += diff;
        });

        angular.element(window).bind("mouseup", () => {
            angular.element(window).unbind("mousemove mouseup");
            cancelAnimationFrame(this.animationFrameRef);
        });

        angular.element(event.target).bind("click", (event: JQueryEventObject) => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            angular.element(event.target).unbind("click");
        });
    };

    /**
     * binds a resize sensor to the headerWrapper to handle global resize events
     * global resize events are window resize or the user resizes another component resulting in empty space
     */
    private bindResizeSensor(): void {
        this.resizeObserver.disconnect();

        try {
            this.resizeObserver.disconnect();
            this.resizeObserver.observe(this.grid.config.scrollContainer[0], {subtree: true, attributes: true});
        } catch (ex) {
            console.log(ex);
        }

        window.addEventListener("resize", () => {
            this.isWindowResizing = true;
            this.animationFrameRef = this.rAF(() => {
                this.setVerticalScrolling();
                this.updateCellWidth();
                cancelAnimationFrame(this.animationFrameRef);
                this.isWindowResizing = false;
            });
        });
    }

    private setVerticalScrolling(): void {
        this.isVerticalScrolling = ((this.grid.rows.length) * this.grid.rowHeight > this.grid.gridWrapper.height());
    }

    /**
     * edits the sort property of the given column definition and changes the html class of the arrow
     *
     * @param col
     * @param {JQuery} headerSortArrow
     * @param {JQueryEventObject} event
     */
    private editSorting(col: any, headerSortArrow: JQuery, event: JQueryEventObject): void {

        if (col.sort == void 0) {
            col.sort = "asc";
            headerSortArrow.addClass("ag-icon-asc");

            if (event.shiftKey) {
                this.sortedColumns.push(col);
            } else {
                this.sortedColumns = [col];
            }

        } else if (col.sort == "asc") {
            col.sort = "desc";
            headerSortArrow.removeClass("ag-icon-asc");
            headerSortArrow.addClass("ag-icon-desc");

            for (let sortCol of this.sortedColumns) {
                if (col.field == sortCol.field) {
                    sortCol = col;
                }
            }
        } else if (col.sort == "desc") {
            col.sort = null;
            headerSortArrow.removeClass("ag-icon-desc");

            for (const i in this.sortedColumns) {
                const sortCol: any = this.sortedColumns[i];
                if (col.field == sortCol.field) {
                    this.sortedColumns.splice(Number(i), 1);
                }
            }
        }
    }

    /**
     * sorts the column ascending or descending
     * if the user holds the shift key the grid starts to sort multidimensional
     *
     * @param col - the column definiton of the column to sort
     * @param {JQuery} headerCell
     * @param {JQueryEventObject} event
     */
    private sortColumn(col: any, headerCell: JQuery, event: JQueryEventObject): void {
        const headerSortArrow: JQuery = headerCell.find(".header-sort-arrow");

        console.log("sort column");
        // copying the rows to not edit them by mistake
        const rowsCopy: any[] = this.grid.config.rows.slice();

        // resetting other sortings in case the user sorts another column without pressed shiftKey
        for (const i in this.columns) {
            const c: any = this.columns[i];
            if (i != col.colIndex && c.sort != void 0 && !event.shiftKey) {
                this.grid.rows = rowsCopy;
                this.sortedColumns = [];
                c.sort = null;
                c.headerCell.find(".header-sort-arrow").removeClass("ag-icon-asc");
                c.headerCell.find(".header-sort-arrow").removeClass("ag-icon-desc");
            }
        }

        this.editSorting(col, headerSortArrow, event);

        let sortedRows: any[] = [];
        const sortObject: any = {};

        if (this.sortedColumns.length == 0) {
            sortedRows = rowsCopy;
            this.grid.api.updateGridRows(sortedRows, false);
            return;
        }

        for (const sortedColumn of this.sortedColumns) {
            sortObject[sortedColumn.field] = sortedColumn.sort;
        }

        sortedRows = this.sortHelper.multiSort(rowsCopy, sortObject);

        this.grid.api.updateGridRows(sortedRows, false);
    }

    destroy(): void {
        this.resizeObserver.disconnect();
    }

    /**
     * A sorting helper with functions to achieve multidimensional sorting over grid columns
     */
    private sortHelper: any = {
        isNum(v: string | number): boolean {
            return (!isNaN(parseFloat(v as string)) && isFinite(v as number));
        },

        // from James Coglan/Jeff Atwood's answers at
        // http://stackoverflow.com/questions/5223/length-of-javascript-object-ie-associative-array
        obLen(obj: any): number {
            let size = 0;
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    size++;
                }
            }

            return size;
        },

        // avoiding using Object.keys
        obIx(obj: any, ix: string | number): any {
            let size = 0;
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (size == ix) {
                        return key;
                    }

                    size++;
                }
            }
            return false;
        },
        keySort(a: any, b: any, d?: any): number {
            d = d !== null ? d : 1;
            a = this.isNum(a) ? a * 1 : a.value.toLowerCase();
            b = this.isNum(b) ? b * 1 : b.value.toLowerCase();
            if (a == b) {
                return 0;
            }

            return a > b ? 1 * d : -1 * d;
        },

        /**
         * Sorts array of objects on keys as provided
         *
         * @param objarr array of objects
         * @param keys object specifying keys, {KEY1:"asc", "KEY2:"desc", KEY3:"desc"}, also {KEYX:"skip"} for fun
         * @returns array of objects, sorted
         */
        multiSort(objarr: any[], keys: any): any[] {

            // not sure what we want to do if no keys provided.
            // use obIx0 on a member?
            keys = keys || {};

            const KL: any = this.obLen(keys);

            // as yet poorly defined -- maybe sort on
            if (!KL) {
                return objarr.sort(this.keySort);
            }

            for (const k in keys) {
                // asc unless desc or skip
                keys[k] = keys[k] == "desc" || keys[k] == -1 ? -1 : (keys[k] == "skip" || keys[k] === 0 ? 0 : 1);
            }

            objarr.sort((a: any, b: any) => {
                let sorted = 0, ix = 0;

                while (sorted === 0 && ix < KL) {
                    const k: any = this.obIx(keys, ix);
                    if (k) {
                        const dir: any = keys[k];
                        sorted = this.keySort(a[k], b[k], dir);
                        ix++;
                    }
                }
                return sorted;
            });
            return objarr;
        }
    };
}
