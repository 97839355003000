import {Injectable} from "@angular/core";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";
import {EMPTY, Observable, of} from "rxjs";
import {BackendHistoryObject} from "CORE_PATH/backend/interfaces/backend-history-object.interface";
import {OsrestObjectHistory} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-object-history.interface";
import {OsRestMoveObject} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-move-object.interface";
import {map, switchMap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {BackendInsertUpdateObject} from "CORE_PATH/backend/interfaces/insert-update/backend-insert-update-object.interface";
import {BackendObjectInserts} from "CORE_PATH/backend/interfaces/backend-object-insert.interface";
import {OsRestObjectInserts} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-object-inserts.interface";
import {IdPair} from "INTERFACES_PATH/id-pair.interface";
import {OsRestParentsDocuments, OsRestParentsResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-parents.result.interface";
import {ObjectLink} from "CORE_PATH/backend/interfaces/object-link.interface";
import {ObjectType} from "INTERFACES_PATH/object-type.interface";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {OsrestNoteLink} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-note-link.interface";
import * as angular from "angular";
import {OsrestGreenArrowResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-green-arrow-result.interface";

@Injectable({providedIn: BackendOsRestModule})
export class OsRestObjectService {

    // TODO: We should inject the ObjectTypeService. This is the most basic service
    //       which holds the object definition. It only consume the BackendObjectDefinitionService
    //       and this should no other dependencies have to avoid ring dependencies
    //       Then no ugly broadcast is needed and object definition information isn't stored here!
    private objectTypes: ObjectType[];

    constructor(private httpClient: HttpClient, private messageService: MessageService) {

        messageService.subscribeFirst(Broadcasts.OBJECT_TYPES_INITIALIZED, (objTypes: ObjectType[]) => this.objectTypes = objTypes);
    }

    getObjectHistory(objectId: string): Observable<BackendHistoryObject[]> {
        return this.httpClient.get<OsrestObjectHistory[]>(`/osrest/api/documents/history/${objectId}`).pipe(
            map(x => x.map(y => new BackendHistoryObject(y.user?.name ?? "", y.user?.fullname ?? "", y.info, y.action, y.actionname, new Date(y.time))))
        );
    }

    // eslint-disable-next-line max-params
    moveDmsObject(objectId: string, targetId: string, sourceId: string, objectTypeId?: string, targetTypeId?: string, cabinetId?: string): Observable<void> {
        const payload: OsRestMoveObject = {
            item: { id: objectId },
            target: { id: targetId }
        };

        if (sourceId != undefined) {
            payload.source = { id: sourceId };
        }

        if (targetTypeId != undefined && targetTypeId != "-1") {
            payload.target.objectTypeId = targetTypeId;
        }

        if (objectTypeId != undefined) {
            payload.item.objectTypeId = objectTypeId;

            if(cabinetId != undefined) {
                payload.archive = {
                    id: cabinetId
                };
            }
        }

        return this.httpClient.post<void>("/osrest/api/documentfiles/move?recursive=true", payload);
    }

    deleteDmsObject(objectId: string, parentId: string, recursive: boolean): Observable<void> {
        let uri: string = `/osrest/api/documentfiles/delete/${objectId}?recursive=${recursive ? "true" : "false"}`;

        if (parentId != undefined) {
            uri += `&parent=${parentId}`;
        }

        return this.httpClient.delete<void>(uri);
    }

    switchArchiveFlagDmsObject(objectId: string, switchToArchivable: boolean): Observable<void> {
        return this.httpClient.get<void>(`/osrest/api/documents/archive/${objectId}?archive=${switchToArchivable}`);
    }

    setObjectTypeForTypelessDmsObject(payload: BackendInsertUpdateObject): Observable<void> {
        const blob: Blob = new Blob([JSON.stringify(payload)], {
            type: "application/json;charset=UTF-8"
        });

        const formData: FormData = new FormData();
        formData.append("data", blob);

        return this.httpClient.post<void>(`/osrest/api/documents/settype/${payload.osid}?inwftray=true&inusertray=false`, formData);
    }

    getDmsObjectInserts(objectId: string, objectTypeId: string): Observable<BackendObjectInserts> {
        return this.httpClient.get<OsRestObjectInserts>(`/osrest/api/documents/insertables/${objectId}?objecttypeid=${objectTypeId}`)
            .pipe(map((x: OsRestObjectInserts) => {
                const retVal: BackendObjectInserts = {};

                for (const insertableObjectTypeId in x.objectInserts) {
                    retVal[insertableObjectTypeId] = {
                        maxCount: x.objectInserts[insertableObjectTypeId]
                    };
                }

                return retVal;
            }));
    }

    insertUpdateDmsObject(req: "insert" | "update" | "wfTray" | "greenArrow", payload: BackendInsertUpdateObject, locationId: string, locationTypeId: string): Observable<string> {
        let url: string = "/osrest/api/documents/";

        switch (req) {
            case "update": url += "update"; break;
            case "insert": url += "insert"; break;
            case "greenArrow": url += "insert"; break;
            case "wfTray": url += "insert?inwftray=true&inusertray=false"; break;
        }

        if (locationId != undefined) {
            url += `/${locationId}`;
        }

        if (locationTypeId != undefined) {
            url += `?objecttypeid=${locationTypeId}`;
        }

        if (req == "greenArrow") {
            url += (url.includes("?")) ? "&isgreenarrowreference=true" : "?isgreenarrowreference=true";
        } else if (req == "update") {
            url += `/${payload.osid}`;
            url += (url.includes("?")) ? "&noexistencecheck=true" : "?noexistencecheck=true";
        }

        return this.httpClient.post<string>(url, this.stringifyObjectToFormdata(payload));
    }

    getDmsObjectLocations(objectId: string, objectTypeId: string) : Observable<IdPair[][]> {
        return this.httpClient.get<OsRestParentsResult>(`/osrest/api/documents/parents/${objectId}?tree=true${objectTypeId == void 0 ? "" : `&objecttypeid=${objectTypeId}`}`).pipe(
            map(x => {
                if (x.documents.length === 0) {
                    return;
                }

                const locationList: IdPair[][] = [];

                function addFn(collection: IdPair[], backendItem: OsRestParentsDocuments): void {
                    collection.push({
                        objectId: backendItem.id,
                        objectTypeId: backendItem.objectTypeId
                    });
                }

                function buildLocationList(startNode: OsRestParentsDocuments, path: IdPair[]): void {
                    if (startNode.id != objectId) {
                        addFn(path, startNode);
                    } else {
                        if (path.length == 0) {
                            addFn(path, startNode);
                        }

                        locationList.push(path);
                        return;
                    }

                    const childrenDocuments: OsRestParentsDocuments[] = startNode.children?.documents;

                    if (childrenDocuments != undefined) {
                        for (const childDocument of childrenDocuments) {
                            buildLocationList(childDocument, angular.copy(path));
                        }
                    } else {
                        locationList.push(path);
                    }
                }

                for (const folder of x.documents) {
                    buildLocationList(folder, []);
                }

                return locationList;
            })
        );
    }

    addDmsObjectLocation(objectId: string, targetObjectId: string, objectTypeId: string, targetObjectTypeId: string): Observable<void> {
        let url: string = `/osrest/api/documents/insert/${targetObjectId}?islink=true`;

        if(targetObjectTypeId != undefined) {
            url += `&objecttypeid=${targetObjectTypeId}`;
        }

        const data: any = {
            osid: objectId,
            fields: {}
        };

        if (objectTypeId != undefined) {
            data.objectTypeId = objectTypeId;
        }

        // We discard the result because the objectId is the same and the caller isn't interested in it.
        // The mapping is necessary because otherwise the raw value is return also if we specify void.
        return this.httpClient.post<void>(url, this.stringifyObjectToFormdata(data)).pipe(map(x => undefined));
    }

    getReferenceDocuments(objectId: string): Observable<IdPair[]> {
        const objectTypeIds: string = this.objectTypes ? `?objecttypeids=${this.objectTypes.filter(x => /^[1-7]$/.test(x.model.config.mainType)).map(x => x.model.osid).join(";")}` : "";

        return this.httpClient.get<OsrestGreenArrowResult>(`/osrest/api/documents/greenarrowlinks/${objectId}${objectTypeIds}`).pipe(
            map((x) => {
                const result: IdPair[] = [];

                for (const [greenArrowObjectTypeId, v] of Object.entries(x)) {
                    v.forEach(greenArrowObjectId => result.push({objectId: greenArrowObjectId.toString(), objectTypeId: greenArrowObjectTypeId}));
                }

                return result;
            })
        );
    }

    getNoteLinks(objectId: string): Observable<ObjectLink[]> {
        return this.httpClient.get<OsrestNoteLink[]>(`/osrest/api/documents/links/${objectId}`).pipe(
            map(x => x.map(y => ({
                kind: "note",
                idPair: {
                    objectId: y.relatedObjectId.toString(),
                    objectTypeId: y.relatedObjectTypeId.toString()
                },
                comment: y.text
            })))
        );
    }

    addNoteLink(sourceObjectId: string, targetObjectId: string, targetObjectTypeId: string, note: string): Observable<ObjectLink[]> {
        try {
            return this.httpClient.post<OsrestNoteLink[]>(`/osrest/api/documents/links/${sourceObjectId}`, {
                relatedObjectId: targetObjectId,
                relatedObjectTypeId: targetObjectTypeId,
                text: note
            }).pipe(
                map(x => x.map(y => ({
                    kind: "note",
                    idPair: {
                        objectId: y.relatedObjectId.toString(),
                        objectTypeId: y.relatedObjectTypeId.toString()
                    },
                    comment: y.text
                })))
            );
        } catch (error) {
            console.error(error);
        }
    }

    removeNoteLink(originalObjectId: string, referenceObjectId: string): Observable<ObjectLink[]> {
        return this.httpClient.delete<OsrestNoteLink[]>(`/osrest/api/documents/links/remove/${originalObjectId}/${referenceObjectId}`).pipe(
            map(x => x.map(y => ({kind: "note", idPair: {objectId: y.relatedObjectId.toString(), objectTypeId: y.relatedObjectTypeId.toString()}, comment: y.text})))
        );
    }

    private stringifyObjectToFormdata(data: BackendInsertUpdateObject): FormData {
        const headers: BlobPropertyBag = {
            type: "application/json;charset=UTF-8"
        };
        const blob: Blob = new Blob([JSON.stringify(data)], headers);

        const formData: FormData = new FormData();
        formData.append("data", blob);
        return formData;
    }
}
