import {
    Component, ElementRef, Inject,
    Input,
    OnInit
} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {
    TodoClientScriptService,
    TodoFormGridBuilder,
    TodoFormHelper
} from "INTERFACES_PATH/any.types";
import {
    GridOptions,
    ColDef,
    ColumnResizedEvent,
    CellClickedEvent,
    ColumnState,
    Column, GridSizeChangedEvent, GridColumnsChangedEvent
} from "ag-grid-community";
import {IconCellComponent} from "MODULES_PATH/grid/components/grid-cells/icon-cell/icon-cell.component";
import {Subject, Subscription} from "rxjs";
import {GridCellComponent} from "MODULES_PATH/grid/components/grid-cells/grid-cell/grid-cell.component";
import {debounceTime, take} from "rxjs/operators";
import {SortService} from "CORE_PATH/services/utils/sort.service";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ColumnDefinition} from "MODULES_PATH/grid/interfaces/column.interface";
import {FormLayoutService} from "MODULES_PATH/form/services/form-layout.service";
import {Renderer2} from "@angular/core";
import {
    GridHeaderTooltipComponent
} from "MODULES_PATH/grid/components/grid-header-cells/grid-header-tooltip/grid-header-tooltip.component";
import {ClientService} from "CORE_PATH/services/client/client.service";

interface CellsInitializeContext {
    cellsInitializeCounter: Subject<unknown>;
}

@Component({
    selector: "eob-form-grid",
    templateUrl: "./eob-form-grid.component.html",
    styleUrls: ["./eob-form-grid.component.scss"]
})
export class EobFormGridComponent implements OnInit {
    @Input() field: Field;
    @Input() formhelper: TodoFormHelper;

    gridOptions: GridOptions;
    showAddAndRemoveButtons: boolean;

    private readonly translate: TranslateFnType;
    private readonly staticColumnWidth: number = this.layoutManagerService.isTouchLayoutActive() ? 40 : 32;
    private readonly scrollbarWidth: number = this.clientService.isTouchDevice() ? 4 : 8;

    private columnRatios: { [key: string]: number } = {};
    private currentGridWidth: number = 0;
    private isCheckBoxColEnabled: boolean = false;
    private hasVerticalScrollbars: boolean = false;

    private expandLastColumnDebounce: Subject<void> = new Subject();
    private subs: Subscription = new Subscription();

    // eslint-disable-next-line max-params
    constructor(@Inject("clientScriptService") private clientScriptService: TodoClientScriptService,
                @Inject("$filter") private $filter: ng.IFilterService,
                @Inject("formGridBuilder") private formGridBuilder: TodoFormGridBuilder,
                private clientService: ClientService,
                private sortService: SortService,
                private layoutManagerService: LayoutManagerService,
                private formLayoutService: FormLayoutService,
                private elRef: ElementRef,
                private renderer: Renderer2) {
        this.translate = this.$filter("translate");
        this.subs.add(this.expandLastColumnDebounce.pipe(debounceTime(50)).subscribe(this.adjustLastColumn.bind(this)));
    }

    ngOnInit(): void {
        this.showAddAndRemoveButtons = !(this.formhelper.isSearch || this.field.isDisabled);
        this.fillEmptyData();

        // make sure that the cells for at least 100 rows are rendered, the cells need to be rendered for the cell script functions to work
        const rowBuffer: number = 100;
        const context: CellsInitializeContext = {cellsInitializeCounter: new Subject()};
        const cellCount: number = Math.min(this.field.gridData.length, rowBuffer) * this.field.model.columns.length;
        const cellsInitialized: Promise<unknown> = context.cellsInitializeCounter.pipe(take(cellCount)).toPromise();

        this.gridOptions = {
            defaultColDef: {
                sortable: true,
                resizable: true,
                suppressMenu: true
            },
            frameworkComponents: {
                gridCellRenderer: GridCellComponent,
                tooltipRenderer: GridHeaderTooltipComponent
            },
            tooltipShowDelay: 0,
            columnDefs: this.getColumnDefinitions(this.field.model.columns),
            rowData: this.field.gridData,
            rowHeight: this.staticColumnWidth,
            headerHeight: 32,
            suppressColumnVirtualisation: true,
            rowBuffer,
            suppressContextMenu: true,
            suppressPropertyNamesCheck: true,
            singleClickEdit: true,
            onGridColumnsChanged: (param) => this.onColumnsChanged(param),
            onColumnResized: params => this.onColumnResized(params),
            onGridSizeChanged: (param) => this.onGridResized(param),
            context,
            onGridReady: async () => {
                this.field.gridApi = this.gridOptions.api;
                try {
                    await cellsInitialized;
                    this.field.isReady = true;
                    this.field.api.validate();
                    this.formhelper.gridReady$.next(true);
                } catch (_) { /* cancelled */
                }
            }
        };

        this.setColumnRatios();
        this.gridOptions.context.validationBubble = this.formhelper.validationBubble;
        this.field.api.computeCellProperties(this.field.gridData);
    }

    addRow(): void {
        if (this.field.isDisabled) {
            console.warn("The grid is disabled");
            return;
        }

        if (this.field.eventScripts != void 0 && typeof (this.field.eventScripts.onBeforeAddRow) == "function") {
            const eventFn: (formHelper, globals, scriptingStorage, field, cb) => void = this.field.eventScripts.onBeforeAddRow;
            const callback = (userCanAdd) => {
                if (userCanAdd == void 0 || userCanAdd == 1) {
                    this.field.api.addRow();
                }
            };

            eventFn(this.formhelper, this.formhelper.globals, this.clientScriptService.getGlobalScriptingStorage(), this.field, callback);
        } else {
            this.field.api.addRow();
        }

        this.onRowsChanged();
        this.field.api.getElement().find(".ag-cell-focus").removeClass("ag-cell-focus");
    }

    private removeRow(param: CellClickedEvent): void {
        this.formGridBuilder.removeRow(param, this.field, this.formhelper);
        this.onRowsChanged();
    }

    private onRowsChanged(): void {
        const newHasVerticalScrollbars: boolean = this.formGridBuilder.checkForTableScrolling(this.field, this.gridOptions);

        // adjust column widths after a grid width change through new/removed scrollbars
        if (this.hasVerticalScrollbars != newHasVerticalScrollbars) {
            this.adjustColumnWidths();
        }
    }

    private fillEmptyData(): void {
        if (this.field.gridData == void 0 || this.field.gridData.length === 0) {
            this.field.gridData = [];
            const row: string[] = [];
            for (const column of this.field.model.columns) {
                row.push("");
            }
            this.field.gridData.push(row);
        }
    }

    private getColumnDefinitions(columns: ColumnDefinition[]): ColDef[] {
        const columnDefs: ColumnDefinition[] = [];

        // adding the column definition
        for (let i: number = 0; i < columns.length; i++) {
            const col: ColumnDefinition = columns[i];

            let comparator: (valA: string, valB: string) => number;
            switch (col.type) {
                case "decimal":
                case "number":
                    comparator = this.sortService.sortByNumber;
                    break;
                case "datetime":
                case "date":
                    comparator = this.sortService.sortByDatetime;
                    break;
                default :
                    comparator = this.sortService.sortFieldValueByText;
                    break;
            }

            const colDef: ColumnDefinition = {
                field: i.toString(),
                index: i,
                addon: col.addon,
                isDisabled: !!col.isDisabled,
                suppressMovable: true,
                minWidth: 40,
                headerName: col.title,
                headerTooltip: col.title,
                tooltipComponent: "tooltipRenderer",
                width: col.width,
                comparator,
                cellRendererFramwork: IconCellComponent,
                cellRenderer: "gridCellRenderer",
                cellRendererParams: {field: this.field, formHelper: this.formhelper},
                pinned: undefined
            };

            columnDefs.push(colDef);
        }

        // the delete button --> only show if the user is nowhere near the search form
        if (this.showAddAndRemoveButtons) {
            columnDefs.push(this.getRemoveButtonColDef(columns));
        }

        return columnDefs;
    }

    private getRemoveButtonColDef(columns: ColumnDefinition[]): ColumnDefinition {
        const headerTooltip: string = this.translate("form.delete.table.row");

        return {
            field: `f${columns.length}`,
            suppressMovable: true,
            resizable: false,
            sortable: false,
            suppressSizeToFit: true,
            headerTooltip,
            width: this.staticColumnWidth,
            headerName: "",
            cellClass: "cell-delete-row",
            template: `<button class="button-transparent"><i role='img' [attr.aria-label]='${headerTooltip}' title='${headerTooltip}' class='icon-16-delete-row'></i></button>`,
            onCellClicked: params => this.removeRow(params),
            pinned: "right",
            isDeleteRowCell: true
        };
    }

    /**
     * Event fired by agGrid if column status change.
     * E.g. if the checkbox selection column is added or a column is hidden.
     * Update properties and adjust column widths.
     */
    private onColumnsChanged(param: GridColumnsChangedEvent): void {
        this.isCheckBoxColEnabled = !!(param.api.getColumnDefs()[0] as ColDef).checkboxSelection;

        if (this.currentGridWidth > 0) {
            this.adjustColumnWidths();
        }
    }

    /**
     * Event fired by agGrid if a column is resized by dragging or api.
     * Set the new column width ratio dragged by a user and adjust the last column width accordingly.
     */
    private onColumnResized(param: ColumnResizedEvent): void {
        // wait until the resizing is finished and ignore events, which were fired by ourselves via the grid api
        if (!param.finished || param.source == "api") {
            return;
        }

        if (this.currentGridWidth > 0) {
            const column: Column = param.column;
            // adjust ratio to the new ratio dragged by the user
            this.columnRatios[column.getColDef().field] = Math.round((column.getActualWidth() / this.getAdjustedGridWidth()) * 1000) / 1000;

            this.expandLastColumnDebounce.next();
        }
    }

    private onGridResized(param: GridSizeChangedEvent): void {
        const newGridWidth: number = param.clientWidth;
        // ignore pageCtrl tab changes, which update the grid width to zero and back to its original width
        if (newGridWidth == 0 || this.currentGridWidth == newGridWidth) {
            return;
        }

        this.currentGridWidth = newGridWidth;
        this.adjustColumnWidths();
    }

    private adjustColumnWidths(): void {
        this.formGridBuilder.saveFittingRowsFromScrolling(this.field);

        const gridColumns: Column[] = this.gridOptions.columnApi.getAllGridColumns();

        // Only calculate the relation of the column widths by the grid size without static columns.
        const adjustedGridWidth: number = this.getAdjustedGridWidth();

        for (const [i, colDef] of this.field.gridApi.getColumnDefs().entries()) {
            let newColWidth: number;
            if (!this.isStaticColumn(colDef)) {
                const ratio: number = this.columnRatios[colDef.colId];

                if (ratio == undefined || ratio == 0) {
                    newColWidth = this.getMinWidth(colDef as ColumnDefinition);
                } else {
                    newColWidth = ratio * adjustedGridWidth;
                }
            }

            if (newColWidth && gridColumns[i].getActualWidth() != newColWidth) {
                this.gridOptions.columnApi.setColumnWidth(gridColumns[i], newColWidth);
            }
        }

        this.expandLastColumnDebounce.next();
    }

    private adjustLastColumn(): void {
        const columnStates: ColumnState[] = this.gridOptions.columnApi.getColumnState();
        const allColumns: Column[] = this.gridOptions.columnApi.getAllGridColumns();

        let widthOfAllCols: number = 0;
        allColumns.forEach((col, i) => widthOfAllCols += columnStates[i].hide ? 0 : col.getActualWidth());

        // get last column that is visible and not the (maybe existing) pinned remove-button column
        const lastCol: Column = allColumns.slice().reverse().find((col: Column) => !col.isPinned() && col.isVisible());
        if (!lastCol) {
            return;
        }

        let lastColWidth: number = lastCol.getActualWidth();
        // The column width before a possible expansion.
        const minLastColWidth: number = this.getAdjustedGridWidth() * this.columnRatios[lastCol.getColDef().field];
        this.hasVerticalScrollbars = this.formGridBuilder.checkForTableScrolling(this.field, this.gridOptions);
        const gridWidthWithoutScrollbars: number = this.currentGridWidth - (this.hasVerticalScrollbars ? this.scrollbarWidth : 0);
        let remainingSpace: number = gridWidthWithoutScrollbars - widthOfAllCols;

        // Return to a smaller version of the last column, min. the last set size, if there is less space available now.
        if (remainingSpace < 0 && minLastColWidth < lastColWidth) {
            remainingSpace += lastColWidth - minLastColWidth;
            lastColWidth = minLastColWidth;
        }

        // expand the last column to the remaining space
        let newLastColWidth: number = minLastColWidth;
        if (remainingSpace > 0) {
            newLastColWidth = remainingSpace + lastColWidth;

            if (this.layoutManagerService.isTouchLayoutActive()) {
                const pinnedRightHeader: HTMLElement = this.elRef.nativeElement.querySelector(".ag-pinned-right-header");

                if (this.hasVerticalScrollbars) {
                    this.renderer.addClass(pinnedRightHeader, "including-scrolls");
                } else {
                    this.renderer.removeClass(pinnedRightHeader, "including-scrolls");
                }
            }
        }

        if (lastCol.getActualWidth() != newLastColWidth) {
            this.gridOptions.columnApi.setColumnWidth(lastCol, newLastColWidth);
            this.formGridBuilder.saveFittingRowsFromScrolling(this.field);
        }
    }

    /**
     * Initialize the ratios of the widths of the columns as defined in the object definition.
     */
    private setColumnRatios(): void {
        const schemaGridWidth: number = Number(this.field.model.width);

        for (const colDef of this.gridOptions.columnDefs as ColDef[]) {
            if (this.isStaticColumn(colDef)) {
                continue;
            }

            // for some reason the width in the initial column definition is set to twice the size defined in the schema
            const schemaColumnWidth: number = colDef.width / 2;
            this.columnRatios[colDef.field] = Math.round(schemaColumnWidth / schemaGridWidth * 1000) / 1000;
        }
    }

    private getMinWidth(colDef: ColumnDefinition) {
        return this.formLayoutService.getTextWidthInPixel(colDef.headerName) + 24;
    }

    /**
     * Get grid width with only the object definition columns, so without static columns and scrollbars.
     */
    private getAdjustedGridWidth(): number {
        this.hasVerticalScrollbars = this.formGridBuilder.checkForTableScrolling(this.field, this.gridOptions);
        let adjustedGridWidth: number = this.currentGridWidth;
        adjustedGridWidth -= this.hasVerticalScrollbars ? this.scrollbarWidth : 0;

        const amountStaticColumns: number = 0 + (this.isCheckBoxColEnabled ? 1 : 0) + (this.showAddAndRemoveButtons ? 1 : 0);
        return adjustedGridWidth - amountStaticColumns * this.staticColumnWidth;
    }

    private isStaticColumn(colDef: ColDef): boolean {
        return !!colDef.checkboxSelection || colDef.cellClass == "cell-delete-row";
    }

    onDestroy(): void {
        this.subs.unsubscribe();
        this.gridOptions.context.cellsInitializeCounter.error("cancelled");
    }
}
