import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {StateObject, StateParams, StateService, UIRouterGlobals} from "@uirouter/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {Subscription} from "rxjs";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {Progressbar, ProgressbarService} from "SERVICES_PATH/eob.progressbar.srv";
import {
    TodoCacheManagerService,
    TodoDesktopService,
    TodoEnvironmentService,
    TodoLocationService,
    TodoModalDialogService,
    TodoOfflineCacheService,
    TodoSearchService,
    TodoStateHistoryManager,
    TodoStateService
} from "INTERFACES_PATH/any.types";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import * as angular from "angular";
import {DmsMessageType} from "MODULES_PATH/dms/enums/dms-message-type.enum";
import {GridData} from "MODULES_PATH/grid/interfaces/grid-data.interface";
import {HitlistLoadingState} from "MODULES_PATH/hitlist/model/loading-state.model";
import {Subject} from "rxjs";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import {HitlistConfig} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {KebabMenuAction} from "MODULES_PATH/state-basics/components/state-kebab-menu/state-kebab-menu.component";
import {KebabMenuService} from "CORE_PATH/services/kebab-menu/kebab-menu.service";
import {QueryBuilderService} from "CORE_PATH/services/search/query-builder.service";

@Component({
  selector: "eob-result",
  templateUrl: "./result.component.html",
  styleUrls: ["./result.component.scss"]
})
export class ResultComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() stateHeaderTitle?: string;
    @Input() stateHeaderDescription?: string;
    @Input() hitlistConfig?: HitlistConfig;
    @Input() showHitlistMenu?: boolean = true;
    @Input() showStateSyncButton?: boolean = false;
    @Input() showRefreshButton?: boolean = true;
    @Input() menuItems?: any = [];
    // dms objects data retrieved by different context specific backend calls in the search service
    @Input() hitlistItems?: Subject<any>;

    /**
     * Currently those two @Inputs are used only for object-references.component,
     * but it should be implemented for all states after they are migrated and if they needed
     *
     * @private
     */
    @Input() updatedHitlistConfig?: Subject<any>;
    @Input() loadingState?: HitlistLoadingState;

    private readonly translate: TranslateFnType;
    private readonly stateParams: StateParams;
    private state: StateObject;
    private defaultTitle: string;
    private documentIds: string[] = [];
    private contextId: string;
    private addToFavoritesSubscription: Subscription;
    private progressBar: Progressbar;
    private annotationsListener: () => void;
    private notesListener: () => void;

    isMockContext: boolean;
    isPhone: boolean;
    isForcedPhoneLayout: boolean;
    errorDetail: string;

    constructor(
        @Inject("$location") private $location: ng.ILocationService,
        @Inject("$state") private $state: StateService,
        @Inject("$filter") private $filter: ng.IFilterService,
        @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
        @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
        @Inject("desktopService") private desktopService: TodoDesktopService,
        @Inject("$rootScope") private $rootScope: ng.IRootScopeService,
        @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
        @Inject("offlineCacheService") private offlineCacheService: TodoOfflineCacheService,
        @Inject("stateService") private stateService: TodoStateService,
        @Inject("searchService") private searchService: TodoSearchService,
        @Inject("locationService") private locationService: TodoLocationService,
        @Inject("environmentService") private environmentService: TodoEnvironmentService,
        private kebabMenuService: KebabMenuService,
        private actionService: ActionService,
        private messageService: MessageService,
        private viewerService: ViewerService,
        private clientService: ClientService,
        private progressbarService: ProgressbarService,
        private gridContentService: GridContentService,
        private iconService: IconService,
        private notificationsService: NotificationsService,
        private el: ElementRef,
        private cdRef: ChangeDetectorRef,
        private queryBuilderService: QueryBuilderService,
        globals: UIRouterGlobals
    ) {
        this.translate = this.$filter("translate");
        this.isPhone = this.clientService.isPhone();
        this.isForcedPhoneLayout = this.clientService.isForcedPhoneLayout();
        this.stateParams = globals.params;
    }

    async ngOnInit(): Promise<void> {
        this.loadingState = this.loadingState ?? new HitlistLoadingState();
        this.state = this.stateHistoryManager.getStateData(this.$location.search().state);

        this.updatedHitlistConfig?.subscribe(x => {
            this.hitlistConfig.api.updateRows(x);
        });

        this.stateHistoryManager.updateStateData({
            name: this.$state.current.name,
            params: this.$state.params
        });

        this.defaultTitle = this.state.data.flat ? this.translate("eob.location.state.title") : this.translate("eob.result.state.title");

        if (!this.stateHeaderTitle) {
            this.stateHeaderTitle = this.state.data.title || this.defaultTitle;
        }

        if (!this.stateHeaderDescription) {
            this.stateHeaderDescription = this.state.data.description || "";
        }

        this.isMockContext = !/(search|folder|scriptQuery)/gi.test(this.state.data.type);
        this.progressBar = this.progressbarService.getProgressbarInstance("loadAnimation", this.el.nativeElement, true);
        this.progressBar.show();

        this.menuItems = await this.kebabMenuService.getHitlistMenuItemsAsync();
        this.showHitlistMenu = this.menuItems.length > 0;

        // TODO: refactoring | use messageService
        this.$rootScope.$on("hitlist.config.changed", (ev, cabinetId) => {
            // Fix for DODO-8966 Part-3 see comment
            const rows: any = this.hitlistConfig.api.getRows();

            for (const row of rows) {
                const dmsDocument = this.cacheManagerService.dmsDocuments.get(row.osid)[0];
                if (dmsDocument != void 0 && dmsDocument.model.cabinetId == cabinetId) {
                    this.$state.reload();
                    break;
                }
            }
        });

        // TODO: refactoring | use messageService
        this.$rootScope.$on("set.result.list.selection", (ev, objectIdsToSelect) => {
            if (!Array.isArray(objectIdsToSelect)) {
                objectIdsToSelect = [objectIdsToSelect];
            }

            if (objectIdsToSelect.length == 0) {
                return;
            }

            this.hitlistConfig.api.deselectAll();
            this.hitlistConfig.api.selectItems(objectIdsToSelect, "id");
            this.messageService.broadcast("set.result.list.selection");

            if (this.hitlistConfig.suppressViewerUpdate != true && objectIdsToSelect.length === 1) {
                this.viewerService.updateViewer(objectIdsToSelect[0], undefined, objectIdsToSelect);
            } else {
                this.viewerService.clearViewer();
            }
        });

        /**
         * Receive annotations change events and update the model.
         */
        this.annotationsListener = this.$rootScope.$on("annotationsChanged", (event, args) => {
            const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(args.id);
            dmsDocument.model.baseParameters.annotations = args.annotationsCount;
            this.cacheManagerService.dmsDocuments.add(dmsDocument);
        });

        /**
         * Receive note change events and update the model.
         */
        this.notesListener = this.$rootScope.$on("dv.notesChanged", (event, args) => {
            const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(args.id);
            dmsDocument.model.baseParameters.notes = args.notesCount;
            dmsDocument.model.hasNotes = args.notesCount > 0;

            this.cacheManagerService.dmsDocuments.add(dmsDocument);
        });

        if (!this.hitlistItems) {
            await this.init();
        } else {
            // init only after hitlist items were delivered by the parent component
            this.hitlistItems.subscribe((hitlistItems) => void this.init(hitlistItems));
        }
    }

    ngAfterViewInit(): void {
        // Show a prompt to allow a user to save a public query, which has been opened from a os link file
        setTimeout(() => {
            // The dialogue has to be delayed, as it is otherwise destroyed by the initialization of the state
            if (this.state.data.isPublic && this.state.data.fromFile) {
                (async () => {
                    await this.modalDialogService.infoDialog(this.translate("eob.action.modal.desktop.add.title"),
                        this.translate("modal.confirm.should.save.public.query.to.desktop").replace(/(["«])(%s1)(["»])/, `${this.state.data.description ? `$1${this.state.data.description}$3` : ""}`),
                        this.translate("modal.button.no"), this.translate("modal.button.yes"));
                    this.desktopService.saveQuery();
                })();
            }
        }, 0);
    }

    private async init(hitlistItems?: DmsDocument[]): Promise<void> {
        if (this.state.data.name == "hitlist.inbox" || this.state.data.type === "hitlist.object-references") {
            return;
        }

        this.loadingState.isLoading = true;

        // in case of script query, extend query configs
        if (this.state.data != void 0 && this.state.data.type == "scriptQuery"
            && this.state.data.scriptQuery != void 0 && this.state.data.scriptQuery.query != void 0) {
            // do not change original script query to reflect hitlist config changes later
            const searchQueryData: any = angular.copy(this.state.data.scriptQuery);

            this.state.data.searchQuery = this.queryBuilderService.createScriptQuery(searchQueryData, this.state.data.useAsIni);

            if (this.state.data.searchQuery != void 0) {
                // set objectTypeIds array for hitlist config dialog
                this.state.data.objectTypeIds = [searchQueryData.query.objectTypeId];
            }
        }

        if (this.state.data && (this.state.data.type == "hitlist.favorites" || this.state.data.type == "hitlist.offlineObjects") && !this.environmentService.userHasRole("R_CLNT_SHOW_MOBFAV")) {
            this.loadingState.hasErrors = true;
            this.showHitlistMenu = false;
            const emptyPlaceholder: HTMLElement = this.el.nativeElement.querySelector("div.empty-hitlist-placeholder > div > span");
            emptyPlaceholder.innerText = this.translate("eob.result.state.favorites.no.rights");
            this.loadingState.isLoading = false;
            this.progressBar.hide();
            return;
        }

        try {
            let searchResult;

            if (this.state.data.searchType == "form") {
                const scriptResult = await this.searchService.executeQueryWithScriptsAsync(this.state.data);
                searchResult = scriptResult.searchResult;

                // query was cancelled by script
                if (scriptResult.resultCode < 0) {
                    // for resultCode -2 go back twice to also close the form, otherwise return to the form
                    const backSteps: number = scriptResult.resultCode == -2 ? -2 : -1;
                    history.go(backSteps);
                    return;
                }
            } else if (this.state.data.type == "hitlist.failedSyncObjects" && (await this.clientService.getIsSynchronizing())) {
                // state will be refreshed once the sync is done
                return;
            } else if (hitlistItems) {
                searchResult = hitlistItems;
            } else {
                // all search backend calls that couldn't be moved to components stay here for the time being :(
                switch (this.state.data.type) {
                    case "search":
                        searchResult = await this.searchService.executeSearch(this.state.data);
                        break;
                    case "entry":
                        searchResult = await this.searchService.searchById(this.state.data.id, this.state.data.objectTypeId);                        break;
                    case "scriptQuery":
                        searchResult = await this.searchService.executeScriptQuerySearch(this.state.data);
                        break;
                    case "expert":
                        searchResult = await this.searchService.executeExpertSearch(this.state.data);
                        break;
                    case "searchByIds":
                        const {result, warning, error} = await this.searchService.searchByIdsAsync(this.state.data.items, false);
                        searchResult = result;

                        if (warning) {
                            this.notificationsService.warning(warning);
                        }

                        if (error) {
                            this.notificationsService.error(error);
                        }

                        break;
                }
            }

            if (!searchResult) {
                // Handle an invalid state silently
                console.warn("searchResult is undefined");
                this.$state.go("dashboard");
                return;
            }

            this.documentIds = this.cacheManagerService.dmsDocuments.add(searchResult);

            try {
                // add selected items in case the result list is a flat location
                if (this.state.data.type == "search" && this.state.data && this.state.data.flat && this.state.data.config) {
                    await this.locationService.addSelectedItemsAsync(this.documentIds);
                }
            } catch (error) {
                console.error(error);
            }

            this.contextId = this.cacheManagerService.dmsDocuments.attachListener(this.documentIds, this.changeCallback);

            if (["hitlist.favorites", "hitlist.offlineObjects"].includes(this.state.data.type)) {
                this.addToFavoritesSubscription = this.messageService.subscribe(DmsMessageType.ADD_TO_FAVORITE, (osids: string[]) => {
                    this.documentIds = this.documentIds.concat(osids.filter(id => !this.documentIds.includes(id)));
                    this.cacheManagerService.dmsDocuments.updateListener(this.contextId, this.documentIds);
                    this.changeCallback();
                });
            }

            const listEntries: GridData = await this.createListEntries();

            if (listEntries && listEntries.rows.length == 1 && this.isSingleHitActionApplicable()) {
                const osid: string = listEntries.rows[0].osid;
                const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(osid);

                const keepState: boolean = await this.actionService.executeSingleHitAction(dmsDocument);

                if (!keepState) {
                    // set a parameter for the moment, the user tries to navigate back
                    // in that case we cannot execute the single hit action, because the user would not go anywhere from that time
                    // so we have to suppress the automatic action in the case the user wants to go back
                    this.stateHistoryManager.updateConfig({
                        executeSingleHitAction: false
                    });
                    return;
                }
            }

            this.messageService.subscribeFirst("hitlist.ready", () => {
                this.loadingState.isLoading = false;
                this.progressBar.hide();

                this.addHitlistApiToMenuItems();
                if (!/(hitlist.favorites|favorites|offlineobjects)/gi.test(this.state.data.type) && listEntries?.rows.length >= this.environmentService.env.hitlist.maxsize &&
                    this.environmentService.env.hitlist.maxsize != -1 && this.clientService.isOnline()) {
                    this.notificationsService.warning(this.translate("eob.hitlist.maxhits.exceeded"));
                }
            });
            this.hitlistConfig = listEntries;

            if (listEntries && listEntries.rows.length == 0) {
                this.viewerService.clearViewer();
            }
        } catch (error) {
            console.error(error);
            if (this.clientService.isOffline()) {
                this.notificationsService.info(this.translate("eob.message.offline.function.disabled"));
                return this.clientService.executeStateErrorFallback();
            } else if (error && error.error && error.error.root && error.error.root == "PARAMETER_WRONG_FORMAT_SPECIFIC") {
                this.errorDetail = `${this.translate("eob.invalid.input.parameter.error").split(":")[0]}.`;
            } else {
                error = (error || {}).data ? error.data : error;
                this.notificationsService.customError(error);
            }

            this.loadingState.isLoading = false;
            this.progressBar.hide();
            angular.element(document.body).find("eob-kebab-menu").hide();
            this.loadingState.hasErrors = true;
        } finally {
            this.cdRef.detectChanges();
        }
    }

    /**
     *  This is the callback for each document when it changes --> refresh grid
     *  this will trigger when we get a message from the outside or when a document
     *  is modified from the inside
     */
    private changeCallback = async (osids?: string[]): Promise<void> => {
        if (osids && ["hitlist.favorites", "hitlist.offlineObjects", "hitlist.failedSyncObjects"].includes(this.state.data.type)) {
            const dmsDocuments: DmsDocument[] = this.cacheManagerService.dmsDocuments.get(osids);
            const removedOsids: string[] = dmsDocuments.filter(doc => doc.model.isFavorite === false).map(doc => doc.model.osid);
            this.documentIds = this.documentIds.filter(id => !removedOsids.includes(id));
            this.cacheManagerService.dmsDocuments.updateListener(this.contextId, this.documentIds);
        }

        const listEntries = await this.createListEntries();

        // Only update rows if a hitlistconfig exists. If the document is checked out asynchronously during a state change, the hitlist config does not exist
        (((this.hitlistConfig || {}).api || {}).updateRows || (() => {
        }))(listEntries, undefined, true);
    };

    /**
     * Returns a list of grid entries from document ids
     *
     * @returns {Array}
     */
    private async createListEntries(): Promise<GridData> {
        const dmsDocuments: DmsDocument[] = this.cacheManagerService.dmsDocuments.get(this.documentIds);
        this.getMimeTypeIcons(dmsDocuments);
        return this.getGridListEntries(dmsDocuments);
    }

    private getMimeTypeIcons(dmsDocuments): void {
        const iconIds = [];
        for (const dmsDocument of dmsDocuments) {
            const iconId = dmsDocument.model.fileProperties.mimeTypeIconId;
            if (iconId != void 0 && !iconIds.includes(iconId)) {
                iconIds.push(iconId);
            }
        }
        this.iconService.processIcons(iconIds);
    }

    private async getGridListEntries(dmsDocuments: DmsDocument[]): Promise<GridData> {
        const isFolderOrRegisterList: string = dmsDocuments[0] != undefined && (dmsDocuments[0].model.mainType == "0" || dmsDocuments[0].model.mainType == "99") ? "isFolderOrRegisterList" : null;
        if (this.state.data.type && this.state.data.type == "scriptQuery") {
            return this.gridContentService.getListEntries(dmsDocuments, "scriptQuery", {
                searchQuery: this.state.data.searchQuery,
                useAsIni: this.state.data.useAsIni
            });
        }

        if (this.state.data.flat && this.state.data.flat == true) {

            if (this.clientService.isOffline()) {
                const dmsDocument: DmsDocument = await this.offlineCacheService.getById(this.state.data.objectId);
                if (dmsDocument != void 0 && dmsDocument.model.objectTypeId != this.state.data.objectTypeIds[0]) {
                    this.clientService.registerConnectivityChangeHandler(this.folderConnectivityHandler);
                }
            }

            return this.gridContentService.getListEntries(dmsDocuments, "hitlist.result", {
                flat: true
            });
        }

        return this.gridContentService.getListEntries(dmsDocuments, undefined, isFolderOrRegisterList);
    }

    private addHitlistApiToMenuItems() {
        for (const item of this.menuItems) {
            item.hitlistConfig = this.hitlistConfig;
        }
    }

    private folderConnectivityHandler = async (): Promise<void> => {
        this.reloadFolderWithCorrectedData((await this.offlineCacheService.getById(this.state.data.objectId)).model);
    };

    private reloadFolderWithCorrectedData({osid, objectTypeId}): void {
        this.stateService.openFolderOrRegisterAsync({
            osid,
            objectTypeId,
        }, null, null, null);
    }

    /**
     * Determine whether single hit action is applicable
     *
     * @returns {boolean} Should the default action be executed.
     */
    private isSingleHitActionApplicable(): boolean {
        if (this.state != void 0 && this.state.data != void 0) {
            if (this.state.data.flat == true) {
                // ... in flat location view (especially with only one item in result list)
                return false;
            } else if (this.state.data.config != void 0 && !this.state.data.config.executeSingleHitAction) {
                // ... if suppress flag is set
                return false;
            }
        }

        return true;
    }

    /**
     * This is moved here from eob.offline.objects.dir.js because of the migration process
     *
     * @constructor
     * Create the kebab menu actions upon opening the menu.
     * @returns {Promise<object>} Resolved with the kebab menu actions.
     */
     menuItemsCallback = async (): Promise<KebabMenuAction[]> => {
         const menuItems = this.isPhone ? await this.kebabMenuService.getHitlistMenuItemsAsync(this.hitlistConfig) : await this.kebabMenuService.getHitlistMenuItemsAsync();

        for (const item of menuItems) {
            item.hitlistConfig = this.hitlistConfig;
        }

        return menuItems;
    };

    ngOnDestroy(): void {
        if (this.state?.data.type === "hitlist.object-references") {
            return;
        }

        this.cacheManagerService.dmsDocuments.detachListeners(this.contextId);

        const documents: DmsDocument[] = this.cacheManagerService.dmsDocuments.get(this.documentIds);

        for (const doc of documents) {
            delete doc.model.vtx;
        }

        this.cacheManagerService.dmsDocuments.add(documents);

        if (this.notesListener) {
            this.notesListener();
        }

        if (this.annotationsListener) {
            this.annotationsListener();
        }

        this.clientService.unregisterConnectivityChangeHandler(this.folderConnectivityHandler);

        if (this.addToFavoritesSubscription) {
            this.addToFavoritesSubscription.unsubscribe();
        }
    }

}
