import { Injectable } from "@angular/core";
import {IdPair} from "INTERFACES_PATH/id-pair.interface";
import {OfflineDataStore} from "MODELS_PATH/eob.offline.data.model";
import {
    BackendContainerObject,
    BackendObject, BackendRegisterTree
} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {DmsDocumentStore} from "INTERFACES_PATH/dms-document-store.interface";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import Dexie from "dexie";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";

// @Injectable() is required by spectator, else spec fails with unresolved parameters.
// @Injectable() decorator specifies that Angular can use this class in the DI system.
// Omitting the metadata i.e. providedIn: 'root', means that the OfflineLocationCacheService is NOT visible throughout the application for Angular DI purposes.
@Injectable()
export class OfflineLocationCacheService {
    private dmsDocumentsCache: Dexie.Table<DmsDocumentStore, string>;
    private offlineDataCache: Dexie.Table<OfflineDataStore, string>;

    static $inject: string[] = ["fileCacheService", "errorModelService", "objectTypeService", "offlineCacheService"];

    constructor(private fileCacheService: FileCacheService, private errorModelService: ErrorModelService,
                private objectTypeService: ObjectTypeService, private offlineCacheService: OfflineCacheService) {
    }

    init(): void {
        this.dmsDocumentsCache = this.fileCacheService.getDmsDocumentsTable();
        this.offlineDataCache = this.fileCacheService.getOfflineDataTable();
    }

    /** Provide one location path of a dms object as an array. Return undefined if no location can be found. */
    async getCachedLocationPath(osid: string): Promise<IdPair[]> {
        const locationPath: IdPair[] = [];

        const traverseTree: (id: string) => Promise<void> = async (id) => {
            const parent: OfflineDataStore = await this.offlineDataCache.where("children").equals(id).first();
            if (parent !== undefined) {
                locationPath.unshift({objectId: parent.osid, objectTypeId: parent.objectTypeId.toString()});
                await traverseTree(parent.osid);
            }
        };
        await traverseTree(osid);

        return locationPath.length > 0 ? locationPath : undefined;
    }

    /** Simulate explore backend response. */
    async exploreFolderOrRegister(osid: string, getRegisterTree: boolean = false): Promise<BackendContainerObject> {
        const offlineData: OfflineDataStore = await this.offlineDataCache.where("osid").equals(osid).first();
        if (offlineData == null) {
            throw this.errorModelService.createCustomError("WEB_OBJECT_NOT_FOUND");
        }

        const indexdata: DmsDocumentStore = await this.dmsDocumentsCache.where("osid").equals(osid).first();
        const exploreResult: BackendContainerObject = this.offlineCacheService.createDmsDocument(offlineData, indexdata) as unknown as BackendObject;
        exploreResult.osid = osid;
        exploreResult.children = await this.offlineCacheService.getByIds(offlineData.children) as unknown as BackendObject[];

        if (getRegisterTree) {
            exploreResult.folderRegisterTree = await this.getRegisterTree(osid);
        }
        return exploreResult;
    }

    /** Return a hierarchical tree of child registers of the topmost parent of the given osid. */
    private async getRegisterTree(osid: string): Promise<BackendRegisterTree[]> {
        const locationPath: IdPair[] = await this.getCachedLocationPath(osid);
        if (locationPath !== undefined && locationPath.length > 0) {
            osid = locationPath[0].objectId;
        }

        const transformFolderRegisterTree: (offlineData: OfflineDataStore) => Promise<BackendRegisterTree[]> = async (offlineData) => {
            const retVal: BackendRegisterTree[] = [];

            const children: OfflineDataStore[] = await this.offlineDataCache.where("osid").anyOf(offlineData.children).toArray();
            for (const child of children) {
                // Only folder and register
                if (!this.objectTypeService.isDocumentType(child.objectTypeId.toString())) {
                    const childRegister: BackendRegisterTree[] = await transformFolderRegisterTree(child);
                    retVal.push({
                        childRegister,
                        osid: child.osid,
                        objectTypeId: child.objectTypeId
                    });
                }
            }

            return retVal;
        };

        const containerOfflineData: OfflineDataStore = await this.offlineDataCache.where("osid").equals(osid).first();
        return [{
            childRegister: await transformFolderRegisterTree(containerOfflineData),
            osid: containerOfflineData.osid,
            objectTypeId: containerOfflineData.objectTypeId
        }];
    }

    /** Return a flat list of all child documents (deep) of a folder or register. */
    async getFlatFolderOrRegisterContent(osid: string): Promise<DmsDocument[]> {
        const containerOfflineData: OfflineDataStore = await this.offlineDataCache.where("osid").equals(osid).first();
        if (containerOfflineData == null) {
            throw this.errorModelService.createCustomError("WEB_OBJECT_NOT_FOUND");
        }

        // unique list of deep child documents offline data
        const offlineData: OfflineDataStore[] = (await this.getDeepContainerDocumentOfflineData(containerOfflineData))
            .filter((value: OfflineDataStore, index: number, self: OfflineDataStore[]) => self.indexOf(value) === index);

        const cachedDocs: DmsDocumentStore[] = await this.dmsDocumentsCache.where("osid").anyOf(offlineData.map(d => d.osid)).toArray();
        return cachedDocs.map(cachedDoc => this.offlineCacheService.createDmsDocument(offlineData.find(od => od.osid == cachedDoc.osid), cachedDoc));
    }

    /** Return a list of all offline data objects for documents (deep) of a folder or register. */
    private async getDeepContainerDocumentOfflineData(offlineDataParent: OfflineDataStore): Promise<OfflineDataStore[]> {
        let documentOfflineData: OfflineDataStore[] = [];

        const offlineData: OfflineDataStore[] = await this.offlineDataCache.where("osid").anyOf(offlineDataParent.children).toArray();
        for (const data of offlineData) {
            if (this.objectTypeService.isDocumentType(data.objectTypeId.toString())) {
                documentOfflineData.push(data);
            } else {
                documentOfflineData = [].concat(documentOfflineData, (await this.getDeepContainerDocumentOfflineData(data)));
            }
        }

        return documentOfflineData;
    }
}