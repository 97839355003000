import {Observable} from "rxjs";
import {HttpHandler, HttpRequest} from "@angular/common/http";

export enum AuthenticationState {
    AUTHENTICATING_SESSION = "AUTHENTICATING_SESSION",
    GENERAL_ERROR = "GENERAL_ERROR",
    INVALID_AUTHENTICATION_TYPE_FOR_PLATFORM = "INVALID_AUTHENTICATION_TYPE_FOR_PLATFORM",
    INVALID_AUTHENTICATION_TYPE_FOR_PROVIDER = "INVALID_AUTHENTICATION_TYPE_FOR_PROVIDER",
    INVALID_LOGIN_DATA = "INVALID_LOGIN_DATA",
    LOGGED_IN = "LOGGED_IN",
    LOGGED_OUT = "LOGGED_OUT",
    LOGIN_ABORTED = "LOGIN_ABORTED",
    LOGIN_FAILED = "LOGIN_FAILED",
    LOGIN_CREDENTIALS_INCOMPLETE = "LOGIN_CREDENTIALS_INCOMPLETE",
    LOGIN_URL_INVALID = "LOGIN_URL_INVALID",
    LOGIN_URL_NO_PROTOCOL = "LOGIN_URL_NO_PROTOCOL",
    SESSION_REFRESHED = "SESSION_REFRESHED",
    SESSION_REFRESH_FAILED = "SESSION_REFRESH_FAILED",
    PASSWORD_EXPIRED = "PASSWORD_EXPIRED"
}

export enum AuthenticationType {
    NONE = "NONE",
    BASIC_AUTH = "BASIC_AUTH",
    NTLM_USERNAME = "NTLM_USERNAME",
    NTLM_SYSTEM = "NTLM_SYSTEM",
    OPENRESTY_KEYCLOAK = "OPENRESTY_KEYCLOAK"
}

export interface AuthenticationData {
    authType: AuthenticationType;
    username?: string;
    password?: string;
}

export interface AuthRequestHeader {
    key: string;
    value: string;
}

export interface AuthenticationStatusEvent {
    state: AuthenticationState;
    error?: any;
    requestHeaders?: AuthRequestHeader[];
    username?: string;
    userId?: number;
    origin?: string;
}

export interface AuthenticationProvider {
    isAuthenticated(): Observable<boolean>;

    authenticate(backendOrigin: string, credentials?: AuthenticationData): Observable<AuthenticationStatusEvent>;

    invalidateSession(): Observable<AuthenticationStatusEvent>;

    getStatusEvent(): Observable<AuthenticationStatusEvent>;

    handleReauthenticationRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any>;
}
