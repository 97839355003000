import {Subject} from "rxjs";
angular.module("eob.core").factory("formGridBuilder", FormGridBuilder);

FormGridBuilder.$inject = ["$rootScope", "$timeout", "$compile", "formLayoutService", "layoutManagerService", "clientScriptService"];

let agGridElement = null;

// eslint-disable-next-line max-params
function FormGridBuilder($rootScope, $timeout, $compile, FormLayoutService, LayoutManagerService, ClientScriptService) {
    return {
        createGrid,
        saveFittingRowsFromScrolling,
        checkForTableScrolling,
        removeRow
    };

    function createGrid(field, container, formHelper) {
        formHelper.gridReady$ = new Subject();
        container.addClass("form-table");

        let domElement = angular.element("<eob-form-grid class='form-grid-wrapper' [field]='field' [formhelper]='formhelper'></eob-form-grid>");
        const scope = $rootScope.$new();

        scope.field = field;
        scope.formhelper = formHelper;
        domElement = $compile(domElement)(scope);

        agGridElement = angular.element(domElement[0].getElementsByTagName("eob-grid-wrapper")[0]);

        return domElement;
    }

    function removeRow(params, field, formHelper) {
        if (field.isDisabled) {
            console.warn("The grid is disabled");
            return;
        }

        if (field.eventScripts != void 0 && typeof (field.eventScripts["onBeforeDeleteRow"]) == "function") {
            let event = field.eventScripts["onBeforeDeleteRow"];
            let row = {
                data: params.node.data,
                rowIndex: params.rowIndex
            };
            const callBack = (userCanRemove) => {
                if (userCanRemove == void 0 || userCanRemove == 1) {
                    field.api.removeRow(params.rowIndex);
                }
            };

            event(formHelper, formHelper.globals, ClientScriptService.getGlobalScriptingStorage(), field, row, callBack);
        } else {
            field.api.removeRow(params.rowIndex);
        }
    }

    function checkForTableScrolling(field) {
        let scrollElement;
        if (LayoutManagerService.isTouchLayoutActive()) {
            scrollElement = angular.element(field.api.getElement()).find(".ag-body");
        } else {
            scrollElement = angular.element(field.api.getElement()).find(".ag-body-viewport");
        }
        return hasScrollBarVertical(scrollElement);
    }

    function saveFittingRowsFromScrolling(field) {
        let viewport = angular.element(field.api.getElement().find(".ag-body"));
        let body = angular.element(field.api.getElement().find(".ag-body-container"));
        let isFitting = viewport.height() > body.height() && viewport.width() >= body.width();

        if (isFitting && !field.isDisabled) {
            field.api.getElement().addClass("no-scrolling");
        } else {
            field.api.getElement().removeClass("no-scrolling");
        }
    }

    function hasScrollBarVertical(field) {
        if (field == void 0 || field[0] == void 0) {
            return false;
        }

        return field[0].scrollHeight > field.height();
    }
}
