import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

(function() {
    require("SERVICES_PATH/addons/eob.db.catalog.srv.js");
    require("SERVICES_PATH/addons/eob.tree.addon.srv.js");

    angular.module("eob.framework").directive("eobInlineTree", EobInlineTree);

    EobInlineTree.$inject = ["$rootScope", "$compile", "$timeout", "virtualGridService", "toolService", "viewService", "dbCatalogService",
        "treeAddonService", "clientService", "progressbarService", "layoutManagerService",
        "messageService"];

    function EobInlineTree($rootScope, $compile, $timeout, VirtualGridService, ToolService, ViewService, DbCatalogService,
                           TreeAddonService, ClientService, ProgressbarService, LayoutManagerService,
                           MessageService) {
        return {
            restrict: "E",
            link(scope, element) {
                let gShowTreeSubject;
                let isTouchDevice = LayoutManagerService.isTouchLayoutActive();
                let treeConfig = {
                    rowHeight: isTouchDevice ? 40 : 28,
                    maxWidth: 620,
                    minWidth: 230,
                    rows: [],
                    columns: [],
                    scrollContainer: element.find("#tree-content"),
                    useMultiSelect: false,
                    useCheckboxSelection: false,
                    useIntermediateNodes: false,
                    deselectWhenCollapse: true,
                    autoSizeColumns: true,
                    suppressResizeDetection: true,
                    readonly: false,
                    onRowClick,
                    onRowDoubleClick
                };

                let virtualGridInstance = null;
                let hasShortValues = false;
                let addonButton;

                scope.overlayCount = 0; // counts open modals/overlays in front of this view

                let globalClickListener,
                    config,
                    guid,
                    field,
                    value,
                    domElement,
                    isGridCell,
                    catalogType,
                    isSearch = false,
                    shortListWidth = 0,
                    valueListWidth = 0,
                    progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true),
                    treeContainer = element.find(".tree-addon"),
                    filterField = element.find(".addon-filter");

                scope.isPhone = ClientService.isPhone();

                if (scope.isPhone) {
                    addClosedStyles(treeContainer);
                }

                scope.filter = {
                    value: ""
                };

                scope.switchJoinMode = function(newMode) {
                    scope.joinMode = newMode;
                };

                scope.$watch("filter.value", (newValue) => {
                    if (virtualGridInstance) {
                        virtualGridInstance.api.setFilter(newValue, true);
                        recalculateTreeStyles(newValue);
                    }
                });

                function recalculateTreeStyles(newValue) {
                    let visibleRowCount = virtualGridInstance.api.getVisibleRows();

                    // needed for Edge if Tree initially had bigger height than available space to bottom of window
                    // top has to be calculated again after filtering else tree would be stuck to bottom of window
                    if ((!ClientService.isMobile() || scope.isPhone) && (newValue || visibleRowCount.length > 0)) {
                        let treeHeight = visibleRowCount.length > 12 ? 340 : visibleRowCount.length * 24;
                        if (scope.isPhone) {
                            if (scope.showJoinButtons && filterField.is(":focus")) {
                                treeHeight = visibleRowCount.length > 3 ? 150 : visibleRowCount.length * 40;
                                element.find("#tree-content").css("max-height", 160);
                            } else {
                                treeHeight = visibleRowCount.length > 5 ? 200 : visibleRowCount.length * 40;
                                element.find("#tree-content").css("max-height", 200);
                            }
                        }

                        if (newValue == "") {
                            scope.treeHeight = scope.startHeight;
                        } else {
                            scope.treeHeight = treeHeight;
                        }

                        const bounds = addonButton.getBoundingClientRect();
                        if (bounds.top || bounds.left || bounds.height || bounds.width) { // check if addonButton does still exist
                            let newCss = getTreeStyle(addonButton, treeHeight);
                            delete newCss.left;
                            treeContainer.css(newCss);
                        }
                    }
                }

                filterField.on("focus", () => {
                    recalculateTreeStyles(filterField.val());
                });

                filterField.on("blur", () => {
                    recalculateTreeStyles(filterField.val());
                });

                scope.applySelection = function() {
                    applySelection();
                };

                treeContainer.bind("touchstart", (event) => {
                    if (!angular.element(event.target).hasClass("addon-filter")) {
                        filterField.blur();
                    }
                });

                function onRowClick(row, event) {
                    filterField.blur();
                    if (!scope.isPhone) {
                        if (field.applySelection) {
                            field.applySelection(row);
                            scope.hideTree();
                        } else if (((catalogType == "list" || catalogType == "db") || isTouchDevice) && !config.useMultiSelect) {
                            // list without multiselection have to close on every click
                            applySelection();
                        }
                    }
                }

                function onRowDoubleClick() {
                    applySelection();
                }

                function applySelection() {

                    selectRow();

                    scope.hideTree();
                }

                function selectRow() {
                    let value;
                    let selectedItems = distinct(virtualGridInstance.api.getSelectedRows());
                    field.selectedItems = selectedItems;

                    if (selectedItems.length === 0) {
                        scope.hideTree();
                    }

                    if (catalogType == "hierarchy") {
                        let paths = [];
                        for (let i in selectedItems) {
                            let path = getHierarchyValue(selectedItems[i]);
                            paths.push(path);
                        }
                        value = paths.join(scope.joinMode);
                    } else if (config.useMultiSelect) {
                        value = joinSelection(selectedItems);
                    } else if (selectedItems.length > 0) {
                        value = hasShortValues ? selectedItems[0].short : selectedItems[0].value;
                    }

                    if (isGridCell) {
                        field.grid.api.setCellValue(field.cell.rowIndex, field.cell.colIndex, value, true);
                        field.grid.api.validateCell(field.cell.rowIndex, field.cell.colIndex);
                        field.grid.api.focusCell(field.cell.rowIndex, field.cell.colIndex);
                    } else if (config.scriptDeferred != void 0) {
                        let defValue = {
                            selection: value,
                            raw: selectedItems
                        };

                        if (config.useMultiSelect) {
                            if (scope.joinMode == ";") {
                                defValue.joinMode = "OR";
                            } else if (scope.joinMode == "+") {
                                defValue.joinMode = "AND";
                            }
                        }

                        defValue.raw = deleteUnneededProperties(defValue.raw);

                        config.scriptDeferred.resolve(defValue);
                    } else if (field.applySelection) {
                        field.applySelection(selectedItems[0])
                    } else {
                        field.api.setValue(value, true);
                        if (!isTouchDevice) {
                            domElement.focus();
                        }
                    }
                }

                function distinct(users) {
                    const result = [];
                    users.map(user => {
                        if (!result.find(unique => unique.short ? unique.short == user.short : unique.value == user.value && user.type == unique.type)) {
                            result.push(user);
                        }
                    })
                    return result;
                }

                function getHierarchy(node) {
                    let values = [];

                    while (node) {
                        let value = node.short || node.value;

                        //  insert the current value at the first index, because we start here from the last leave
                        //  in the tree
                        values.splice(0, 0, value);

                        node = node.parent;
                    }

                    return values;
                }

                function getHierarchyValue(node) {
                    let values = getHierarchy(node);
                    let hierarchySeparator = config.separator || "|";
                    return values.join(hierarchySeparator);
                }

                function deleteUnneededProperties(value) {
                    for (let j in value) {
                        let entry = value[j];
                        delete entry.visible;
                        delete entry.parent;
                        delete entry.selected;
                        delete entry.isSelectable;
                        delete entry.top;
                        delete entry.level;
                        delete entry.index;
                        delete entry.expanded;

                        if (entry.nodes) {
                            for (let i in entry.nodes) {
                                delete entry.nodes[i].parent;
                                deleteUnneededProperties(entry.nodes);
                            }
                            delete entry.nodes;
                        }
                    }

                    return value;
                }

                scope.hideTree = function() {
                    scope.isVisible = false;

                    filterField.blur();

                    virtualGridInstance.api.clear();

                    treeContainer.css({ "display": "none" });

                    scope.showActionButtons = false;

                    MessageService.broadcast(ModalEvents.CLOSE_OVERLAY);
                    scope.overlayClosed.unsubscribe();
                    scope.overlayOpenend.unsubscribe();
                    element.off("keydown");

                    unbindGlobalClickListener();
                    if (config.scriptDeferred != void 0) {
                        config.scriptDeferred.reject();
                    }

                    guid = null;
                    progressBar.hide();
                    if (scope.isPhone) {
                        addClosedStyles(treeContainer);
                        close();
                    }
                };

                function joinSelection(selectedItems) {

                    let separator = scope.joinMode == void 0 ? config.separator == void 0 ? ";" : config.separator : scope.joinMode;
                    let joinedValueArray = [];
                    for (let i in selectedItems) {
                        let entry = selectedItems[i];
                        let value = (entry.short == void 0) ? entry.value : entry.short;
                        joinedValueArray.push(value);
                    }

                    return joinedValueArray.join(separator);
                }

                treeContainer.bind("click", (e) => {
                    e.stopImmediatePropagation();
                });

                function resetProperties() {

                    scope.filter = {
                        value: ""
                    };
                    scope.joinMode = ";";
                    scope.isShortValue = false;

                    hasShortValues = false;
                    config = {};
                    domElement = null;
                    field = {};
                }

                let oldValue = 0;

                function getTreeStyle(target, contentHeight, isRecalc) {
                    let treeWidth = shortListWidth + valueListWidth > 500 ? 564 : shortListWidth + valueListWidth;
                    let window = $(document.body).find("main");
                    let windowWidth = $(window).width();
                    let windowHeight = $(window).height();
                    let emptyTreeHeight = 130; // this is the height of the tree without content inside the tree
                    let treeHeight = emptyTreeHeight + contentHeight;
                    let css;

                    if (scope.showJoinButtons && !scope.isPhone) {
                        treeHeight += 40;
                    }

                    css = {
                        top: "auto",
                        left: "auto",
                        right: "auto",
                        bottom: "auto",
                        display: "block"
                    };

                    let zIndex = ViewService.getZIndex(target);
                    zIndex = Number(zIndex) + 5;

                    css["z-index"] = zIndex;

                    let screenPos = target.getBoundingClientRect();
                    css.left = screenPos.right;

                    if (css.left + treeWidth + 40 > windowWidth) {
                        if (treeWidth > windowWidth) {
                            css.width = "100%";
                        }

                        let left = css.left - treeWidth - 40;
                        css.left = left > 0 ? left : 0;
                    }

                    css.top = screenPos.top;
                    if (css.top + treeHeight > windowHeight) {
                        css.top = "auto";
                        css.bottom = 6;
                    }

                    if (treeHeight > windowHeight) {
                        css.top = 6;
                        css["overflow"] = "auto";
                        if (ClientService.isMobile() && !isRecalc) {
                            setTimeout(() => {
                                if ($(window).height() != windowHeight && treeHeight < $(window).height()) {
                                    treeContainer.css(getTreeStyle(target, contentHeight, true));
                                }
                            }, 400)
                        }
                    }

                    if (scope.isPhone) {
                        treeHeight += 50; //header-content  + action buttons

                        if (scope.showJoinButtons) {
                            treeHeight += 50; //buttons height + margin
                        }

                        css = {
                            top: "auto",
                            "max-height": treeHeight,
                            "height": "100%",
                            left: 56, //nav-bar
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            "flex-direction": "column",
                            "overflow": "hidden",
                            "overflow-y": "auto",
                            "-webkit-transition": "height 0.35s", /* Safari */
                            "transition": "height 0.35s",
                            "z-index": 9999999
                        };
                    }

                    return css;
                }

                // binding a scope listener --> the binding returns a deregister function
                function bindGlobalClickListener() {
                    globalClickListener = scope.$on("close.inline.dialogs", (e, target) => {
                        if ($.contains(element[0], target)) {
                            return;
                        }

                        scope.hideTree();
                    });
                }

                // call the deregister function --> we dont need this anymore
                function unbindGlobalClickListener() {
                    if (globalClickListener != void 0) {
                        globalClickListener();
                        globalClickListener = null;
                    }
                }

                function showTree(eventData) {
                    scope.fieldModel = eventData.data.model;

                    scope.isVisible = true;

                    if (Array.isArray(eventData.list)) {
                        scope.tree = $.extend(true, [], eventData.list);
                    } else if (Array.isArray(eventData.data.model.tree.nodes)) {
                        scope.tree = $.extend(true, [], eventData.data.model.tree.nodes);
                    } else {
                        throw new Error("No list found.");
                    }

                    if (virtualGridInstance != void 0) {
                        virtualGridInstance.destroy();
                        virtualGridInstance = null;
                    }

                    virtualGridInstance = VirtualGridService.getVirtualGridInstance(treeConfig);

                    TreeAddonService.createParentNodes(scope.tree);

                    MessageService.broadcast(ModalEvents.OPEN_OVERLAY);
                    scope.overlayOpenend = MessageService.subscribe(ModalEvents.OPEN_OVERLAY, () => {scope.overlayCount += 1;});
                    scope.overlayClosed = MessageService.subscribe(ModalEvents.CLOSE_OVERLAY, () => {scope.overlayCount -= 1;});
                    element.on("keydown", (event) => {
                        if (scope.overlayCount > 0 || !scope.isVisible) {
                            return
                        }

                        if (event.key == "Esc" || event.key == "Escape") {
                            scope.hideTree();
                            event.stopPropagation();
                        }
                    });

                    field = eventData.data;
                    config = eventData.config != void 0 ? eventData.config : field.model.tree.config;
                    catalogType = eventData.catalogType != void 0 ? eventData.catalogType : scope.fieldModel.addon;
                    domElement = eventData.element;
                    isSearch = eventData.isSearch;
                    isGridCell = field.isGridCell;

                    hasShortValues = false;
                    scope.maxhitsReached = scope.fieldModel.addon == "db" && DbCatalogService.getMaxhitsReached();

                    if (catalogType == "user") {
                        catalogType = "list";
                    }

                    if (isSearch) {
                        treeConfig.useMultiSelect = /list|organisation/gi.test(catalogType) ? (config.useMultiSelect || config.multiSelect) : false;
                    } else {
                        treeConfig.useMultiSelect = (config.useMultiSelect || config.multiSelect);
                    }

                    scope.readonly = config.readonly;
                    scope.showJoinButtons = isSearch && treeConfig.useMultiSelect && catalogType == "list" && config.readonly != true;
                    if (scope.isPhone) {
                        scope.showActionButtons = true;
                    } else if (isTouchDevice) {
                        scope.showActionButtons = treeConfig.useMultiSelect;
                    } else {
                        scope.showActionButtons = catalogType != "list" && catalogType != "db" || treeConfig.useMultiSelect || config.readonly;
                    }

                    scope.joinMode = config.multiselectionSeparator;

                    if (scope.tree != void 0) {
                        hasShortValues = config.hideShortValues ? false : TreeAddonService.hasShortValues(scope.tree);

                        if (field.model.tree != void 0) {
                            field.model.tree.config.shortValue = hasShortValues;
                        }
                    }

                    let sortKey = hasShortValues ? "short" : "value";
                    let isIconCatalog = config.iconCatalog;

                    let useIntermediateNodes;
                    if (catalogType === "hierarchy") {
                        if (isSearch) {
                            useIntermediateNodes = true;
                        } else {
                            useIntermediateNodes = !!config.intermediate; // (not not) to eval undefined and null to false
                        }
                    } else {
                        useIntermediateNodes = !!config.intermediate; // (not not) to eval undefined and null to false
                    }

                    TreeAddonService.removeDeprecatedStarEntries(scope.tree, isSearch, useIntermediateNodes);

                    if (config.sorted) {
                        TreeAddonService.sortTreeNodes(field.model, scope.tree, sortKey);
                    }

                    if (treeConfig.useMultiSelect) {
                        treeConfig.useCheckboxSelection = isTouchDevice ? true : config.useCheckboxSelection;
                    } else {
                        treeConfig.useCheckboxSelection = false;
                    }

                    treeConfig.readonly = config.readonly;
                    treeConfig.deselectWhenCollapse = config.deselectWhenCollapse;
                    treeConfig.useIntermediateNodes = useIntermediateNodes;
                    treeConfig.columns = [];

                    //cheboxes
                    if (treeConfig.useCheckboxSelection) {
                        treeConfig.columns.push({
                            field: "checkbox",
                            isIconColumn: false,
                            isCheckboxColumn: true,
                            showAsTree: false,
                            maxWidth: 40,
                            width: 20
                        });
                    }

                    // icons
                    if (isIconCatalog) {
                        treeConfig.columns.push({
                            field: "iconClass",
                            isIconColumn: true,
                            suppressAutoSize: true,
                            width: 40
                        });
                    }

                    // short values
                    if (hasShortValues) {
                        treeConfig.columns.push({
                            field: "short",
                            isIconColumn: false,
                            isCheckboxColumn: false,
                            showAsTree: true,
                            maxWidth: 400
                        });
                    }

                    // the text column
                    if (!config.hideValues) {
                        treeConfig.columns.push({
                            field: "value",
                            isIconColumn: false,
                            isCheckboxColumn: false,
                            showAsTree: !hasShortValues,
                            maxWidth: !hasShortValues ? 600 : 240,
                            isFacetIcon: config.facetIcon
                        });
                    }

                    virtualGridInstance.api.updateGridContent({ rows: scope.tree });

                    let elementCount = virtualGridInstance.api.getRowCount();
                    let treeHeight = elementCount > 12 ? 340 : elementCount * 24;
                    if (ClientService.isTouchDevice()) {
                        treeHeight = elementCount > 8 ? 340 : elementCount * 40;

                        if (scope.isPhone) {
                            treeContainer.css("display", "flex");
                            treeHeight = elementCount > 5 ? 200 : elementCount * 40;
                            if (eventData.data.isGridCell) {
                                treeHeight += 16; // + one row for header-description
                            }
                        }
                    }

                    scope.startHeight = treeHeight;
                    scope.treeHeight = scope.startHeight;
                    addonButton = eventData.target;

                    let style = getTreeStyle(addonButton, treeHeight);

                    let treeWidth = treeContainer.width();
                    let windowWidth = angular.element(document.body).find("main").width();

                    treeWidth = treeWidth > 500 ? 564 : treeWidth;

                    if ((style.left + treeWidth > windowWidth - 50) && !scope.isPhone) {
                        if (treeWidth >= windowWidth - 50) {
                            if ((windowWidth - 50) !== treeWidth) {
                                oldValue = treeWidth;
                            }
                            style.width = `${windowWidth - 50}px`;
                        }
                        let left = style.left - treeWidth - 40;
                        style.left = left > 0 ? left : 48; // 48 px for nav-bar
                    }

                    if (scope.isPhone && eventData.data.model.internal == "substitutes") {
                        style.left = 0;
                    }

                    treeContainer.css(style);

                    setTimeout(() => {
                        scope.$apply();
                        if (catalogType != "hierarchy") {
                            let valuesToSelect = [];

                            if (field.value != void 0 && field.model.tree != void 0) {
                                if (config.useMultiSelect) {
                                    if (field.value.indexOf("+") != -1) {
                                        scope.joinMode = "+";
                                        valuesToSelect = field.value.split("+");
                                    } else if (field.value.indexOf(scope.joinMode) != -1) {
                                        valuesToSelect = field.value.split(scope.joinMode);
                                    } else if (config.separator) {
                                        valuesToSelect = field.value.split(config.separator);
                                    } else {
                                        valuesToSelect.push(field.value);
                                    }
                                } else {
                                    valuesToSelect.push(field.value);
                                }

                                virtualGridInstance.api.selectValues(valuesToSelect, field.model.tree.config.selectCaseInsensitive);
                            }
                        } else if (field.value != void 0 && field.value !== "") {
                            let paths = [];
                            let valueParts = config.useMultiSelect ? field.value.split(config.multiselectionSeparator) : [field.value];

                            for (let i in valueParts) {
                                paths.push(valueParts[i].split(config.separator));
                            }
                            virtualGridInstance.api.selectValuesByPaths(paths);
                        }
                    }, 0);

                    setTimeout(() => {
                        scope.$apply();
                    }, 0);

                    progressBar.hide();

                    element.find(".max-hits-message").width(element.find(".tree-addon-list").width());
                    if (!isTouchDevice) {
                        $timeout(() => {
                            filterField.focus();
                        }, 0);
                    } else {
                        recalculateTreeStyles(filterField.val());
                    }
                }

                function handlePromise(eventData, promiseGuid) {
                    return eventData.promise.then((data) => {

                        if (promiseGuid != guid) {
                            return;
                        }

                        eventData.list = data;

                        TreeAddonService.setDefaultShortValues(data);

                        showTree(eventData);
                        // we are using a javascript promise, so we need to $apply
                        scope.$apply();

                        return true;
                    }, () => {
                        scope.hideTree();
                        scope.$apply();
                    }).catch((error) => {
                        console.error(error);
                    });
                }

                gShowTreeSubject = MessageService.subscribe("showTree", (eventData) => {

                    if (eventData.data.isGridCell) {
                        scope.title = eventData.data.grid.model.name;
                        scope.description = eventData.data.model.title;
                    } else {
                        scope.title = eventData.data.row ? eventData.target.offsetParent.title : eventData.data.model.title;
                    }

                    $rootScope.$broadcast("close.inline.dialogs", eventData.target);

                    resetProperties();
                    bindGlobalClickListener();

                    guid = ToolService.createGUID();

                    if (eventData.promise == void 0) {
                        if (oldValue !== 0) {
                            treeContainer.css({ "width": `${oldValue}px` });
                        }
                        showTree(eventData);
                    } else {
                        progressBar.show();

                        treeContainer.css(getTreeStyle(eventData.target, 200));
                        treeContainer.css("min-height", "200px");

                        handlePromise(eventData, guid);
                    }

                    if (scope.isPhone) {
                        treeConfig.scrollContainer.width;
                        element.find(".grid-wrapper").width(treeConfig.scrollContainer.width());
                        element.find(".tree-addon-list").width(treeConfig.scrollContainer.width());
                        recalculateTreeStyles(filterField.val());
                        let overlay = angular.element("<div class='overlayContextMenu'></div>");
                        overlay.attr("ng-click", "hideTree()");
                        angular.element(document.body).append($compile(overlay)(scope));
                        angular.element(document.body).append(overlay);
                    }
                });

                function close(event) {
                    angular.element(document.body).find(".overlayContextMenu").remove();
                    scope.title = "";
                    scope.description = "";
                    scope.treeHeight = 0;
                    scope.overlayCount = 0;
                }

                function addClosedStyles(menu) {
                    $(menu).css({
                        "display": "none",
                        "height": 0,
                        "-webkit-transition": "height 0.35s", /* Safari */
                        "transition": "height 0.35s",
                        "z-index": 0
                    });
                }

                scope.$on("$destroy", () => {
                    if (gShowTreeSubject) {
                        gShowTreeSubject.unsubscribe();
                    }
                });
            },
            template: require("!raw-loader!./eob.inline.tree.html")
        };
    }
})();
