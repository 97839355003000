<ul>
    <li>
        <eob-panel *ngIf = "showAbo || showRevisits"
                   [eobPanelTitle] = "'nav.inbox.aborevisits.title' | ngTranslate"
                   [initExpanded] = "true"
                   [isNavPanel] = "true"
                   [panelHeadingLang] = "showAltLang ? objectDefLang : null"
                   (focusLeftEvent) = "passFocus($event, 0)">
            <div panelContent>
                <ul class = "nav-inbox-elements nav-panel-elements">
                    <li *ngIf = "showRevisits" (click) = "openInbox('revisit')">
                        <button (click) = "openInbox('revisit')">
                            <eob-icon title = "{{'nav.inbox.aborevisits.revisits' | ngTranslate}}"
                                      name = "nav-inbox-clock" size = "16"></eob-icon>
                            <span
                                [attr.lang] = "showAltLang ? objectDefLang : null">{{'nav.inbox.aborevisits.revisits' | ngTranslate}}</span>
                        </button>
                    </li>
                    <li *ngIf = "showAbo" (click) = "openInbox('abo')">
                        <button (click) = "openInbox('abo')">
                            <eob-icon title = "{{'nav.inbox.aborevisits.abo' | ngTranslate}}" name = "nav-inbox-bell"
                                      size = "16"></eob-icon>
                            <span
                                [attr.lang] = "showAltLang ? objectDefLang : null">{{'nav.inbox.aborevisits.abo' | ngTranslate}}</span>
                        </button>
                    </li>
                    <li *ngIf = "showAbo" (click) = "openInbox('subscriptionObjects')">
                        <button (click) = "openInbox('subscriptionObjects')">
                            <eob-icon title = "{{'nav.inbox.aborevisits.subscriptionobjects' | ngTranslate}}"
                                      name = "nav-inbox-subscriptionobjects" size = "16"></eob-icon>
                            <span
                                [attr.lang] = "showAltLang ? objectDefLang : null">{{'nav.inbox.aborevisits.subscriptionobjects' | ngTranslate}}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </eob-panel>
    </li>
    <li>
        <eob-panel *ngIf = "showWorkflow"
                   [eobPanelTitle] = "'nav.inbox.workflow.title' | ngTranslate"
                   [initExpanded] = "true" [isNavPanel] = "true"
                   [panelHeadingLang] = "showAltLang ? objectDefLang : null"
                   (focusLeftEvent) = "passFocus($event, 1)">
            <div panelContent>
                <ul class = "nav-inbox-elements nav-panel-elements">
                    <li (click) = "openInbox('workflow')">
                        <button (click) = "openInbox('workflow')">
                            <eob-icon title = "{{'nav.inbox.workflow.inbox' | ngTranslate}}" name = "nav-workflow-inbox"
                                      size = "16"></eob-icon>
                            <span
                                [attr.lang] = "showAltLang ? objectDefLang : null">{{'nav.inbox.workflow.inbox' | ngTranslate}}</span>
                        </button>
                    </li>
                    <li (click) = "openInbox('startable')">
                        <button (click) = "openInbox('startable')">
                            <eob-icon title = "{{'nav.inbox.workflow.startable' | ngTranslate}}"
                                      name = "nav-workflow-startable" size = "16"></eob-icon>
                            <span
                                [attr.lang] = "showAltLang ? objectDefLang : null">{{'nav.inbox.workflow.startable' | ngTranslate}}</span>
                        </button>
                    </li>
                    <li (click) = "openInbox('processes')">
                        <button (click) = "openInbox('processes')">
                            <eob-icon title = "{{'nav.inbox.workflow.running' | ngTranslate}}"
                                      name = "nav-workflow-running" size = "16"></eob-icon>
                            <span
                                [attr.lang] = "showAltLang ? objectDefLang : null">{{'nav.inbox.workflow.running' | ngTranslate}}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </eob-panel>
    </li>
</ul>
