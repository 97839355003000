import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
require("SERVICES_PATH/eob.desktop.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");

angular.module("eob.framework").directive("eobModalDashboardSearchesConfig", EobModalDashboardSearchesConfig);

EobModalDashboardSearchesConfig.$inject = ["$filter", "desktopService", "asIniService", "$rootScope", "environmentService",
    "notificationsService", "clientService", "messageService"];

function EobModalDashboardSearchesConfig($filter, DesktopService, AsIniService, $rootScope, EnvironmentService,
                                         NotificationsService, ClientService, MessageService) {

    return {
        scope: {},
        link(scope, element) {
            scope.ready = false;
            scope.availableItems = [];
            scope.gridConfig;
            scope.multiselectOptions = {
                keepSequence: true
            };

            let quickSearches;
            let desktopQueries;
            let conf;

            setGridConfig();

            DesktopService.initAsync().then(() => {
                let selected = AsIniService.getDashboardSearches().selected;
                if (selected != void 0) {
                    conf = selected.split(",");
                } else {
                    conf = [];
                }
                quickSearches = DesktopService.getQuickSearches();
                desktopQueries = DesktopService.getAllDesktopQueries();

                buildAvailableAndSelectedItems();
                scope.ready = true;
            }).catch((error) => {
                if (error.type != "WEB_OFFLINE_ERROR") {
                    NotificationsService.customError(error);
                }
            });

            function setGridConfig() {
                const getDefaultGridConfig = () => ({
                    columnDefs: [{
                        headerName: $filter("translate")("modal.dashboard.available.searches.title"),
                        field: "name",
                        cellRenderer(params) {
                            if (!params.data.profile) {
                                return params.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
                            } else {
                                return params.value;
                            }
                        }
                    }]
                });

                const leftConfig = getDefaultGridConfig();
                Object.assign(leftConfig.columnDefs[0], {
                    sort: "asc",
                    comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase())
                });

                const rightConfig = getDefaultGridConfig();
                Object.assign(rightConfig.columnDefs[0], {
                    headerName: $filter("translate")("modal.dashboard.selected.searches.title")
                });

                scope.gridConfig = { leftConfig, rightConfig };
            }

            function buildAvailableAndSelectedItems() {
                if (!EnvironmentService.env.desktop.showQueries) {
                    return;
                }

                scope.tooltip = $filter("translate")("nav.desktop.profile.distributed");

                [quickSearches, Object.values(desktopQueries)].forEach(array => {
                    for (let item of array) {
                        const name = item.profile !== "" ? `"<i class="profile-dist-icon icon-16-profile-distributed-small" title=""></i>"${item.name}` : item.name;
                        const index = conf.indexOf(item.id.toString());

                        scope.availableItems.push({
                            name,
                            profile: item.profile,
                            id: item.id,
                            selected: index >= 0,
                            position: index >= 0 ? index : undefined
                        });
                    }
                });
            }

            scope.applyData = function() {
                if (ClientService.isOffline()) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                    return;
                }
                const selectedItems = scope.availableItems.filter(item => item.selected);
                AsIniService.setDashboardSearches(selectedItems);
                $rootScope.$broadcast("dashboard.config.changed");
                scope.close();
            };

            scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);
        },
        template: require("!raw-loader!./eob.modal.dashboard.searches.config.html")
    }
}

