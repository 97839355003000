import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromPdfjsToolbar from "./pdfjs-toolbar.reducer";
import {PdfjsToolbarState} from "MODULES_PATH/dashlet/interfaces/pdfjs.interface";

// eslint-disable-next-line @typescript-eslint/typedef
export const selectPdfjsToolbarState = createFeatureSelector<PdfjsToolbarState>(
    fromPdfjsToolbar.pdfjsToolbarFeatureKey
);

// eslint-disable-next-line @typescript-eslint/typedef
export const selectAvailableStates = createSelector(
    selectPdfjsToolbarState,
    (state: PdfjsToolbarState) => state
);
