import {take} from "rxjs/operators";
import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {Broadcasts} from "../../../app/shared/enums/broadcasts.enum";
import {NavigationEvents} from "../../../app/modules/navigation/enums/navigation-events.enum";
//import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";

(function() {
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.modal.dialog.srv.js");
    require("MODULES_PATH/navigation/services/navigation.service");

    angular.module("eob.framework").directive("eobUserMenu", EobUserMenu);

    EobUserMenu.$inject = ["environmentService", "$filter", "$state", "modalDialogService", "asIniService",
        "$eobConfig", "$q", "$compile", "clientService", "offlineService", "$rootScope", "messageService", "modalDialogInjectorService", "navigationService"];

    function EobUserMenu(EnvironmentService, $filter, $state, ModalDialogService, AsIniService,
                         $eobConfig, $q, $compile, ClientService, OfflineService, $rootScope, MessageService, ModalDialogInjectorService, NavigationService) {
        return {
            restrict: "E",
            scope: {},
            link(scope, element, attrs) {

                let globalClickListener = null;
                let connectivityState;
                let sessionInfo = EnvironmentService.getSessionInfo();
                let serviceInfo = EnvironmentService.getServiceInfo();
                let lastFocusedElement = document.getElementById("user-menu-first-button");
                let isUsingKeyboardNav = false;

                scope.user = sessionInfo.fullname || sessionInfo.username;
                scope.username = sessionInfo.username;
                scope.isPhone = ClientService.isPhone();

                if (sessionInfo.fullname.trim().length > 0) {
                    scope.username = `${scope.username} (${sessionInfo.fullname})`;
                }

                scope.isUsermanagementAllowed = EnvironmentService.userHasRole("R_DMS_SUPERVISOR") && EnvironmentService.env.useUsermanagement && !scope.isPhone;
                scope.isDownloadDesktopClientAllowed = !EnvironmentService.isDesktopAppDownloadDisabled() && !ClientService.isLocalClient() && /Windows/.test(navigator.userAgent);
                MessageService.subscribe(NavigationEvents.SHOW_USER_MENU, () => {
                    scope.isUpdateAvailable = $rootScope.updateAvailable;
                    showUserMenu();
                    ClientService.registerConnectivityChangeHandler(showUserMenu);
                });
                MessageService.subscribe(NavigationEvents.PREPARE_USER_MENU_WITH_KEY_NAV, () => {
                    focusFirstButton();
                    isUsingKeyboardNav = true;
                });

                element.bind("keydown keypress", (event) => {
                    let keyCode = event.keyCode;
                    lastFocusedElement = lastFocusedElement || document.getElementById("user-menu-first-button");

                    if (keyCode == 38 || keyCode == 40) { // Arrow Up: 38, Arrow Down: 40
                        let pressedArrowUp = keyCode == 38;
                        let nextElement = pressedArrowUp ? lastFocusedElement.previousElementSibling : lastFocusedElement.nextElementSibling;
                        if (nextElement && nextElement.firstElementChild && nextElement.firstElementChild.localName == "button") {
                            lastFocusedElement = nextElement;
                        } else {
                            lastFocusedElement = pressedArrowUp ? document.getElementById("user-menu-last-button") : getFirstButton();
                        }

                        if (!isUsingKeyboardNav) {
                            lastFocusedElement = pressedArrowUp ? document.getElementById("user-menu-last-button") : getFirstButton();
                            isUsingKeyboardNav = true;
                        }
                    }

                    if (keyCode == 27) { // ESC: 27
                        if (NavigationService.activeTab == "kebab") { // if nav kebab is open, focus user menu item in nav kebab, else focus root nav bar
                            scope.toggleUserMenu(event);
                            MessageService.broadcast(NavigationEvents.FOCUS_NAV_BAR_BODY);
                            MessageService.broadcast(NavigationEvents.CLOSE_ONLY_USER_MENU);
                        } else {
                            MessageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
                            MessageService.broadcast(NavigationEvents.FOCUS_NAV_BAR);
                        }
                        return;
                    }

                    if (keyCode == 9) { // tab: 9
                        if (isUsingKeyboardNav) {
                            event.stopPropagation();
                            event.preventDefault();
                        } else {
                            isUsingKeyboardNav = true;
                            return;
                        }
                    }

                    lastFocusedElement.firstElementChild.focus();
                });

                function focusFirstButton() {
                    lastFocusedElement = getFirstButton();
                    lastFocusedElement.firstElementChild.focus();
                }

                function getFirstButton() {
                    let el = document.getElementById("user-menu-first-button") || document.getElementById("user-menu-offline-first-button");
                    return el;
                }

                function showUserMenu() {
                    scope.connectionState = ClientService.isOnline();
                    connectivityState = ClientService.isOnline() ? "online" : "offline";

                    if (!isUsingKeyboardNav) {
                        document.getElementById("user-menu-username").focus();
                    } else {
                        focusFirstButton();
                    }
                    MessageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
                    scope.dropdownOpen = true;
                    attrs.$set("inert", undefined);
                    bindGlobalClickListener();
                }

                // binding a scope listener --> the binding returns a deregister function
                function bindGlobalClickListener() {
                    globalClickListener = MessageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, () => {
                        if (lastFocusedElement) {
                            lastFocusedElement.blur();
                        }
                        scope.dropdownOpen = false;
                        attrs.$set("inert", "");
                        ClientService.unregisterConnectivityChangeHandler(showUserMenu);
                        isUsingKeyboardNav = false;
                        if (globalClickListener != void 0) {
                            globalClickListener.unsubscribe();
                            globalClickListener = null;
                        }
                    });
                }

                scope.openUsermanagement = () => {
                    $state.go("usermanagement", { state: $.now() });
                };

                function confirmLogOut() {
                    let modalScope = $rootScope.$new();

                    let title = $filter("translate")("eob.app.bar.usermenu.log.out.while.sync.title");
                    modalScope.msg = $filter("translate")("eob.app.bar.usermenu.log.out.while.sync.msg");
                    modalScope.buttons = {
                        cancel: $filter("translate")("modal.button.cancel"),
                        ok: $filter("translate")("modal.button.yes")
                    };

                    const modalContainer$ = ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                        input: {
                            title,
                            rejection: true
                        },
                        childElement: angular.element("<eob-modal-confirm msg='msg' buttons='buttons'></eob-modal-confirm>"),
                        scope: modalScope
                    });

                    modalContainer$.pipe(take(1)).subscribe(_ => {
                        OfflineService.stopSynchronization();
                        ClientService.logoutAsync();
                    });

                    scope.$apply();
                }

                scope.logout = async () => {
                    if (await ClientService.getIsSynchronizing()) {
                        confirmLogOut();
                    } else {
                        ClientService.logoutAsync();
                    }
                };

                scope.toggleUserMenu = function($event) {
                    if($event) {
                        $event.stopImmediatePropagation();
                    }
                    scope.dropdownOpen = !scope.dropdownOpen;
                    attrs.$set("inert", scope.dropdownOpen ? undefined : "");

                };

                scope.openUserConfig = function() {
                    ModalDialogService.openUserConfig();
                };

                scope.showHelp = function() {
                    switch (AsIniService.getGuiLanguage()) {
                        case "en":
                            window.open("https://help.optimal-systems.com/enaio/v100/user/webclient/en/index.html", "_blank");
                            break;
                        case "fr":
                            window.open("https://help.optimal-systems.com/enaio/v100/user/webclient/fr/index.html", "_blank");
                            break;
                        default:
                            window.open("https://help.optimal-systems.com/enaio/v100/user/webclient/de/index.html", "_blank");
                            break;
                    }
                };

                scope.showInfo = async () => {
                    let clickListener = null;
                    let userName = sessionInfo.fullname ? `${sessionInfo.fullname} (${sessionInfo.username})` : sessionInfo.username;
                    let message = `<li><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.webclient.version.client")}</span><span>${EnvironmentService.getClientVersion()}</span></li>`;
                    message += `<li><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.webclient.version.service")}</span><span>${EnvironmentService.getProductVersion()}</span></li>`;
                    message += `<li><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.rest.version")}</span><span>${serviceInfo.apiVersion} (Build ${serviceInfo.buildRevision})</span></li>`;
                    message += `<li><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.rest.url")}</span><span>${serviceInfo.services.appconnector}</span></li>`;
                    message += `<li><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.renditioncache.url")}</span><span>${serviceInfo.services.renditioncache}</span></li>`;
                    message += `<li><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.server.version")}</span><span>${serviceInfo.serverVersion}</span></li>`;
                    message += `<li><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.server.address")}</span><span>${serviceInfo.serverAddress}</span></li>`;

                    if (ClientService.isLocalClient()) {
                        ClientService.registerConnectivityChangeHandler(onConnectivityChange);
                        let connectionStateTitle = ClientService.isOnline() ? $filter("translate")("eob.app.bar.usermenu.info.dialog.connection.online") : $filter("translate")("eob.app.bar.usermenu.info.dialog.connection.offline");
                        message += `<li class="connection-container"><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.connection")}</span><span>${connectionStateTitle} <i class="connectionStatus ${connectivityState}"></i></span></li>`;
                    }

                    message += `<li><span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.user")}</span><span>${userName}</span></li>`;

                    const downloadLogData = { button: $filter("translate")("modal.button.downloadLogFile") };

                    try {
                        await ModalDialogService.infoDialog($filter("translate")("eob.app.bar.usermenu.info.dialog.title"), message, $filter("translate")("modal.close.title"), null, null, false, { downloadLogData });
                    } catch (_) {
                        // dialog dismissed
                    }

                    clickListener = scope.$on("close.inline.dialogs", () => {
                        if (document.querySelectorAll("ul.bluebird-info-list").length == 0) {
                            ClientService.unregisterConnectivityChangeHandler(onConnectivityChange);
                            clickListener();
                        }
                    });
                };

                // updates the online status in info dialog on connectivity change
                function onConnectivityChange() {
                    connectivityState = ClientService.isOnline() ? "online" : "offline";
                    let connectionStateTitle = ClientService.isOnline() ? $filter("translate")("eob.app.bar.usermenu.info.dialog.connection.online") : $filter("translate")("eob.app.bar.usermenu.info.dialog.connection.offline");
                    let connectionContainer = angular.element(document.body).find(".connection-container");
                    connectionContainer[0].innerHTML = `<span>${$filter("translate")("eob.app.bar.usermenu.info.dialog.connection")}</span><span>${connectionStateTitle}</span><i class="connectionStatus ${connectivityState}"></i>`;
                }

                scope.downloadDesktopClient = function() {
                    window.open(`${$eobConfig.getOswebBase()}/standalone/enaio-webclient-app.exe`, "_blank");
                };

                scope.applyUpdate = async () => {
                    console.info("applyUpdate")
                    try {
                        await ModalDialogService.infoDialog($filter("translate")("eob.action.modal.update.title"), $filter("translate")("eob.action.modal.update.message"), $filter("translate")("modal.button.no"), $filter("translate")("modal.button.yes"));
                        window.electron.startUpdateInstallation();
                    } catch (_) {
                        // no op
                    }
                }
                // Please don't judge me for this ridiculous fix for DODO-14612
                setTimeout(() => {
                    attrs.$set("inert", "");
                }, 0)
            },
            template: require("!raw-loader!./eob.user.menu.html")
        };
    }
})();
