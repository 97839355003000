(function() {
    "use strict";

    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/viewer/detailsviewer/dv.tool.srv.js");

    angular.module("eob.core").factory("dvNotesService", DvNotesService);

    DvNotesService.$inject = ["$location", "$q", "$rootScope", "backendService", "dvToolService"];

    function DvNotesService($location, $q, $rootScope, BackendService, DvToolService) {
        let registeredNotes = [];
        let newNoteContainerCallback;

        let service = {
            data: {},
            addNote,
            editNote,
            deleteNote,
            registerNote,
            registerNewNoteContainer,
            clearRegisteredNotes,
            switchEditModeOnRegisteredNotes,
            load
        };
        return service;

        function registerNewNoteContainer(callbackFunction) {
            newNoteContainerCallback = callbackFunction;
        }

        function registerNote(object) {
            registeredNotes.push(object);
        }

        function switchEditModeOnRegisteredNotes(id, enable) {
            if (id != 0) {
                newNoteContainerCallback(enable);
            }

            let index = registeredNotes.length;
            while (index--) {
                if (registeredNotes[index].id != id) {
                    registeredNotes[index]["cb"](enable);
                }
            }
        }

        function clearRegisteredNotes() {
            registeredNotes = [];
        }

        function load(osid) {
            let data = this.data;
            let deferred = $q.defer();
            let promise = deferred.promise;

            let sessionGuid = $location.search().sessionguid;

            let dataUrl = `/documents/notes/${osid}`;
            if (sessionGuid) {
                dataUrl += `?sessionguid=${sessionGuid}`;
            }

            if (osid == void 0) {
                deferred.reject("error");
                return promise;
            }

            BackendService.get(dataUrl).then((response) => {
                data.docid = osid;
                refreshDataAsync(osid, response.data);

                deferred.resolve(data);
            }, (data, status) => {
                if (data.status == -1) {
                    return;
                }
                deferred.reject("error");
            });

            return promise;
        }

        function addNote(note) {
            let data = this.data;
            let deferred = $q.defer();

            let url = `/documents/notes/${data.docid}`;
            let sessionGuid = $location.search().sessionguid;
            if (sessionGuid) {
                url += `?sessionguid=${sessionGuid}`;
            }

            BackendService.post(url, note).then((response) => {
                refreshDataAsync(data.docid, response.data, true);
                deferred.resolve(response.data);
            }, (data) => {
                deferred.reject("error");
            });

            return deferred.promise;
        }

        function editNote(osid, changedJson) {
            let url = `/documents/notes/update/${osid}`;
            let deferred = $q.defer();

            BackendService.post(url, changedJson).then((response) => {
                refreshDataAsync(osid, response.data);
                deferred.resolve(response.data);
            }, (data) => {
                deferred.reject("error");
            });
            return deferred.promise;
        }

        function deleteNote(osid, notesId) {
            let deferred = $q.defer();
            let url = `/documents/notes/remove/${osid}/${notesId}`;

            BackendService.post(url).then((response) => {
                refreshDataAsync(osid, response.data, true);
                deferred.resolve(response.data);
            }, () => {
                deferred.reject("error");
            });
            return deferred.promise;
        }

        async function refreshDataAsync(osid, newData, broadcastUpdate) {
            let data = service.data;

            data.notesData = newData;
            data.notesData.counter = DvToolService.itemCounter(newData);

            if (broadcastUpdate) {
                $rootScope.$broadcast("dv.notes.updated");
            }
        }
    }
})();