import {ViewerEvent} from "../../../app/modules/dashlet/enums/viewer-event.enum";

require("SERVICES_PATH/eob.backend.srv.js");

angular.module("eob.core").factory("pdfjsViewerService", PdfjsViewerService);

PdfjsViewerService.$inject = ["$filter", "$timeout", "$injector", "httpService", "dmsDocumentService", "profileService",
    "notificationsService", "progressbarService", "clientService", "offlineCacheService", "cacheManagerService", "messageService"];

function PdfjsViewerService($filter, $timeout, $injector, httpService, DmsDocumentService, ProfileService,
                            NotificationsService, ProgressbarService, ClientService, OfflineCacheService, CacheManagerService, MessageService) {
    let ViewerService;

    let viewer = null;
    let thumbnailDefaultImg = "icon-16-document";
    let thumbnailNotPresentable = "icon-16-no_preview";
    let timeoutValue = 30000;
    let globalTimeout = `timeout=${timeoutValue}`;
    let urlBase = "/osrenditioncache/";
    let renditionBase = "app/api/document/";
    let getInfo = "/rendition/web/info?";
    let getPdf = "/rendition/pdf?";
    let getThumbnail = "/rendition/thumbnail?";
    let addTimeStamp = "t=TIMESTAMP";
    let renditionDelete = "?_method=delete";

    let base = urlBase + renditionBase;
    let baseAndID = `${base}IDIDID`;
    let baseID = `${renditionBase}IDIDID`;

    let getRenditionInfoTemplate = baseID + getInfo + addTimeStamp;
    let urlTemplate = baseAndID + getPdf + globalTimeout;
    let thumbnailUrlTemplateComplete = `${baseID + getThumbnail + globalTimeout}&${addTimeStamp}`;
    let renditionDeleteTemplate = `${baseID + renditionDelete}&${addTimeStamp}`;
    let lastId, timeoutId;

    return {
        init,
        getViewer
    };

    function init(element) {
        PDFViewerApplication.isViewerEmbedded = true;
        PDFViewerApplicationOptions.set("verbosity", 0);
        PDFViewerApplicationOptions.set("externalLinkTarget", 2);
        PDFViewerApplicationOptions.set("disableOutline", true);

        setTimeout(() => {
            window.webViewerLoad();

            initViewer();

            if (viewer == void 0) {
                console.log("Viewer could not be initialized");
                return;
            }

            viewer.init(element);
        }, 0);
    }

    function getViewer() {
        initViewer();
        return viewer;
    }

    function initViewer() {
        if (ViewerService == void 0) {
            ViewerService = $injector.get("viewerService");
        }

        if (viewer == void 0) {
            viewer = new Viewer();
        }
    }

    function Viewer() {
        let self = this;
        let lastPreviewOpenState = undefined;

        let attachmentsPreviewIsVisible = false;
        let parentID = null;
        let currentAttachmentID = undefined;
        let currentVisibleID = undefined;
        let noFilenameExist = $filter("translate")("eob.pdfjs.no.filename");

        let invisible = "invisible";
        let attachmentObjects = [];
        let attachmentContainer = [];
        let progressBars = [];
        let excludedContentTypes = ["application/zip", "application/x-dosexec"];
        //at the moment not needed. --> SonarQube Error
        //var knownContentTypes = ['text/plain', 'image/png', 'image/jpeg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        self.layout = {};

        self.init = _init;
        self.clearViewer = _clearViewer;
        self.refresh = _refresh;
        self.showSearch = _showSearch;
        self.updateId = _updateId;
        self.setPDFjsNoDocumentError = _setPDFjsNoDocumentError;
        self.controlSidebarPreview = _controlSidebarPreview;
        self.resizeCallback = _resizeCallback;

        function _addBaseUrl(url) {
            if (!ClientService.isLocalClient()) {
                return url;
            }

            let profile = ProfileService.prepareCurrentProfile();
            return `${profile.url}${url}`;
        }

        function _init(element) {
            self.element = element;
            self.layout = {
                searchButton: element.find("#viewFind"),
                refreshViewButton: element.find("#refreshView"),
                showAttachmentsButton: element.find("#show-attachments"),
                attachmentsBadge: element.find("#attachments-badge"),
                goToParentButton: element.find("#go-to-parent"),
                showThumbnailsButton: element.find("#sidebarToggle"),
                annotationsButton: element.find(".eoBluebirdToolbarButton.annotations"),
                thumbnailContainer: element.find("#sidebarContent"),
                attachmentContainerElement: element.find("#attachment-container"),
                attachmentInnerContainer: element.find(".attachment-wrapper"),
                noPreviewContainer: element.find(".no-preview-possible"),
                rotatePageRight: element.find("#osTurnRight"),
                rotatePageLeft: element.find("#osTurnLeft"),
                zoomOut: element.find("#zoomOut"),
                zoomIn: element.find("#zoomIn")
            };
            bindClickHandler();

            if (ClientService.isLocalClient()) {
                ClientService.registerConnectivityChangeHandler(showConnectivityRelatedToolbuttons);
            }
        }

        function _clearViewer() {
            if ("PDFViewerApplication" in window && typeof (PDFViewerApplication.changeUrl) == "function"
                && Object.keys(self.layout).length > 0) {
                PDFViewerApplication.changeUrl(require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/nopreview.pdf"));
                PDFViewerApplication.hideTools();
                resetViewerStates();
                controlPreviewContainer(true);
                lastPreviewOpenState = undefined;
            }
        }

        let tmpSearchToken;

        function _refresh(id) {
            if (id != void 0 || (parentID && currentVisibleID == parentID)) {
                resetViewerStates();
                if (PDFViewerApplication != void 0 && PDFViewerApplication.findBar.findField.value && PDFViewerApplication.findBar.findField.value.length > 0) {
                    tmpSearchToken = PDFViewerApplication.findBar.findField.value;
                }
                closeSidebar();
                let regenerateId = parentID || id;

                regenerateParentDocument(regenerateId);
            } else if (currentVisibleID && currentVisibleID == currentAttachmentID && currentVisibleID != -1) {
                regenerateAttachment(currentAttachmentID);
            }
        }

        function regenerateAttachment(id) {
            let tmpUrl = renditionDeleteTemplate.replace(/IDIDID/g, id);
            tmpUrl = tmpUrl.replace(/TIMESTAMP/g, addTimestamp());

            (async () => {
                await httpService.legacyGet(tmpUrl, urlBase);
                let newUrl = urlTemplate.replace(/IDIDID/g, id);
                loadAttachmentAsync(_addBaseUrl(newUrl), false);
            })
        }

        function regenerateParentDocument(_parenId) {
            PDFViewerApplication.showTools();

            // Shows loading. We need it earlier for the following rest calls.
            if (PDFViewerApplication.loadingBar) {
                PDFViewerApplication.loadingBar.show();
                PDFViewerApplication.close();
            }

            let tmpUrl = renditionDeleteTemplate.replace(/IDIDID/g, _parenId);
            tmpUrl = tmpUrl.replace(/TIMESTAMP/g, addTimestamp());

            (async () => {
                await httpService.legacyGet(tmpUrl, urlBase)
                currentVisibleID = _parenId;

                tmpUrl = _addBaseUrl(urlTemplate.replace(/IDIDID/g, _parenId));

                await getAttachmentsAsync(_parenId, 0);
                await PDFViewerApplication.changeUrl(addTimestamp(tmpUrl));
                ViewerService.refreshAnnotations();
            })();
        }

        function _controlSidebarPreview() {
            if (parentID && currentVisibleID == parentID) {
                if (lastPreviewOpenState == "SITEPREVIEW") {
                    controlPreviewContainer();
                }
                if (tmpSearchToken) {
                    PDFViewerApplication.showSearch(tmpSearchToken);
                }
            }
        }

        function _showSearch(searchKey) {
            // Maybe we need some time to load the document content. Let's wait on currentVisibleID set.
            $timeout(() => {
                if (currentVisibleID == void 0) {
                    _showSearch(searchKey);
                } else {
                    PDFViewerApplication.showSearch(searchKey);
                }
            }, 500);
        }

        function goToParent() {
            self.layout.goToParentButton.removeClass("top-button");
            toggleAttachmentButtonState(true);
            reloadParent();
            showAllToolbuttons();
        }

        let debounce = null;

        async function _updateId(id) {
            clearTimeout(debounce);

            debounce = setTimeout(async () => {
                closeSidebar();
                parentID = undefined;
                resetViewerStates();
                clearSearchField();
                closePasswordPrompt();

                try {
                    document.querySelector(".pdfViewer").style.display = "none";

                    if (lastId != id) {
                        clearTimeout(timeoutId);
                    }

                    if (PDFViewerApplication.loadingBar) {
                        PDFViewerApplication.loadingBar.show();
                    }

                    let doc = await getDmsDocument(id);
                    lastId = id;

                    let updateFunc = () => {
                        setTimeout(() => {
                            _updateId(doc.model.id);
                        }, 0);

                    };

                    if (doc && doc.model && !doc.model.hasContent && doc.model.baseParameters && doc.model.baseParameters.pageCount > 0) {
                        timeoutId = setTimeout(updateFunc, 1000);
                        return;
                    }

                    let newUrl = "";
                    let newPreviewContent = "";
                    let isOffline = ClientService.isOffline();

                    if (doc && doc.model.mainType == "99") {
                        newUrl = require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/register.pdf");
                        newPreviewContent = "register";
                    } else if (doc && doc.model.mainType == "0") {
                        newUrl = require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/cabinet.pdf");
                        newPreviewContent = "cabinet";
                    } else if (doc && !doc.model.hasContent) {
                        newUrl = require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/error.pdf");
                        newPreviewContent = "error";
                    } else if (doc && doc.model.rights && !doc.model.rights.objExport && !doc.model.isTypeless) {
                        newUrl = require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/forbidden.pdf");
                        newPreviewContent = "forbidden";
                    } else if (isOffline) {
                        const contentObject = (doc) ? await OfflineCacheService.getPreviewContentById(doc.model.osid) : null;

                        if (contentObject && contentObject.files.length > 0) {
                            const pdfBlob = new Blob([contentObject.files[0].content], { type: "application/pdf" });
                            newUrl = window.URL.createObjectURL(pdfBlob);
                            newPreviewContent = "document";
                            parentID = (doc) ? doc.model.osid : 0;
                        } else {
                            newUrl = require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/error.pdf");
                            newPreviewContent = "error";
                        }
                    } else {
                        newUrl = urlTemplate.replace(/IDIDID/g, doc.model.osid);
                        newUrl = _addBaseUrl(addTimestamp(newUrl));

                        parentID = doc.model.osid;
                        newPreviewContent = "document";
                    }

                    if (newPreviewContent == "document") {
                        if (lastPreviewOpenState == "SITEPREVIEW") {
                            controlPreviewContainer();
                        }

                        await getAttachmentsAsync(parentID, 0);
                    }

                    if (newPreviewContent == "document") {
                        PDFViewerApplication.showTools();
                        notifyAnnotations(id);
                    } else {
                        notifyAnnotations();
                        PDFViewerApplication.hideTools();
                        controlPreviewContainer(true);
                    }

                    document.querySelector(".pdfViewer").style.display = "block";

                    await PDFViewerApplication.changeUrl(newUrl);

                    // Now setting the currentVisibleID and not earlier. If there is a vtx search term we
                    // wait until currentVisibleID is no longer undefined. Then the viewer has finally loaded.
                    currentVisibleID = parentID;

                    // when offline on mobile, sometimes the inner view is not fully rendered
                    if (isOffline && ClientService.isMobile()) {
                        setTimeout(() => {
                            _resizeCallback();
                        }, 50);
                    }
                } catch (error) {
                    document.querySelector(".pdfViewer").style.display = "block";

                    if (error) {
                        console.error(error);
                    }

                    if (ClientService.isOffline()) {
                        NotificationsService.warning($filter("translate")("eob.main.offline.document.not.available"));
                    } else if (error && error.root == "USER_NOT_AUTHORIZED") {
                        _setPDFjsNoDocumentError();
                        NotificationsService.error($filter("translate")("eob.read.access.denied.error"));
                    } else if (error && error.status == 403) {
                        // might be caused by clauses
                        _setPDFjsNoDocumentError();
                    } else {
                        _setPDFjsNoDocumentError();
                        NotificationsService.error($filter("translate")("eob.result.document.load.error"));
                    }
                }
            });
        }

        async function getDmsDocument(osid) {
            let result = CacheManagerService.dmsDocuments.getById(osid);

            if (result == void 0) {
                let response;

                if (ClientService.isLocalClient() && ClientService.isOffline()) {
                    response = await OfflineCacheService.getById(osid);
                } else {
                    response = (await httpService.legacyGet(`/documents/search/${osid}`)).data;
                }

                if (response) {
                    CacheManagerService.dmsDocuments.add(response);
                    result = CacheManagerService.dmsDocuments.getById(osid);
                }
            }

            return result;
        }

        function _resizeCallback() {
            if (PDFViewerApplication.initialized) {
                let currentScaleValue = PDFViewerApplication.pdfViewer.currentScaleValue;
                if (currentScaleValue === "auto" ||
                    currentScaleValue === "page-fit" ||
                    currentScaleValue === "page-width") {
                    // Note: the scale is constant for 'page-actual'.
                    PDFViewerApplication.pdfViewer.currentScaleValue = currentScaleValue;
                } else if (!currentScaleValue) {
                    // Normally this shouldn't happen, but if the scale wasn't initialized
                    // we set it to the default value in order to prevent any issues.
                    // (E.g. the document being rendered with the wrong scale on load.)
                    PDFViewerApplication.pdfViewer.currentScaleValue = "auto";
                }
                PDFViewerApplication.pdfViewer.update();
            }
        }

        function _setPDFjsNoDocumentError() {
            if (parentID != null && currentAttachmentID) {
                let newUrl = require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/error.pdf");
                closeSidebar();
                hideToolbuttonsforBrokenAttachment();
                PDFViewerApplication.changeUrl(newUrl, true);
            } else {
                resetViewerStates();
                let newUrl = require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/error.pdf");
                parentID = undefined;
                controlPreviewContainer(true);
                PDFViewerApplication.hideTools();
                PDFViewerApplication.changeUrl(newUrl, true);
            }
        }

        function clearSearchField() {
            if (PDFViewerApplication != void 0 && PDFViewerApplication.appConfig != void 0) {
                angular.element(PDFViewerApplication.appConfig.findBar.findField).val("");
            }

        }

        function toggleAttachmentsPreview() {
            if (PDFViewerApplication != void 0 && PDFViewerApplication.pdfSidebar.isOpen) {
                if (attachmentsPreviewIsVisible) { //close sidebar
                    closeSidebar();
                    lastPreviewOpenState = undefined;
                } else { //switch site- to attachment-preview
                    lastPreviewOpenState = "THUMBNAILPREVIEW";
                    togglePreviewContainer(false, true);
                }
                attachmentsPreviewIsVisible = !attachmentsPreviewIsVisible;
            } else {
                attachmentsPreviewIsVisible = true;
                openSidebar();
                lastPreviewOpenState = "THUMBNAILPREVIEW";
                togglePreviewContainer(false, true);
            }
        }

        function extractAttachments(objectId, pages, contentOfflineObject) {
            if (objectId != parentID) {
                return;
            }

            progressBars = [];
            attachmentContainer = [];
            attachmentObjects = [];

            if (!pages) {
                return;
            }

            for (let p in pages) {
                if (pages.hasOwnProperty(p) && pages[p].foreign_id) {
                    let filename = pages[p].name && pages[p].name.length > 0 ? pages[p].name : noFilenameExist;

                    let newAttachment = {
                        id: pages[p].foreign_id,
                        filename
                    };

                    attachmentObjects.push(newAttachment);
                }
            }

            setBadgeInformation(attachmentObjects.length);

            if (attachmentObjects.length == 0) {
                return;
            }

            if (lastPreviewOpenState == "THUMBNAILPREVIEW") {
                controlPreviewContainer();
            }

            toggleAttachmentButtonState(true, "init");
            addThumbnailContainer();

            for (let i = 0; i < attachmentObjects.length; i++) {
                getAttachmentInformationAsync(objectId, i, attachmentObjects[i].id, 0, contentOfflineObject);
            }
        }

        function addThumbnailContainer() {
            let attachments = attachmentObjects.length;
            if (attachments == void 0 || attachments == 0) {
                return;
            }
            for (let i = 0; i < attachments; i++) {
                let progressbarElement = document.createElement("div");
                progressbarElement.classList.add("osProgressbar");
                progressbarElement.classList.add("invisible");
                let id = `thumbnail_${i}`;
                progressbarElement.id = id;
                let innerElement = document.createElement("div");
                innerElement.classList.add("osloader");
                progressbarElement.appendChild(innerElement);

                let divElement = document.createElement("div");
                divElement.classList.add("attachment-pages");
                divElement.title = attachmentObjects[i].filename;
                divElement.style.height = "100px";
                divElement.id = i;
                divElement.appendChild(progressbarElement);
                self.layout.attachmentInnerContainer.append(divElement);

                let bar = ProgressbarService.getProgressbarInstance(id, self.layout.attachmentInnerContainer[0], true);
                bar.show();
                progressBars.push(bar);
                attachmentContainer.push(divElement);
            }
        }

        function rotatePage(val) {
            if (PDFViewerApplication && (val == 90 || val == -90)) {
                PDFViewerApplication.rotatePages(val);
            }
        }

        function bindClickHandler() {

            unbindClickHandler();

            angular.element(self.layout.searchButton).click(() => {
                PDFViewerApplication.toggleSearch();
            });
            angular.element(self.layout.refreshViewButton).click(() => {
                _refresh();
            });
            angular.element(self.layout.rotatePageRight).click(() => {
                rotatePage(90);
            });
            angular.element(self.layout.rotatePageLeft).click(() => {
                rotatePage(-90);
            });
            angular.element(self.layout.showThumbnailsButton).click((event) => {
                event.stopImmediatePropagation();
                toggleSitePreview();
            });

            angular.element(self.layout.showAttachmentsButton).click((event) => {
                event.stopImmediatePropagation();
                toggleAttachmentsPreview();
            });

            angular.element(self.layout.goToParentButton).click((event) => {
                event.stopImmediatePropagation();
                goToParent();
            });
        }

        function unbindClickHandler() {
            angular.element(self.layout.searchButton).unbind();
            angular.element(self.layout.refreshViewButton).unbind();
            angular.element(self.layout.rotatePageRight).unbind();
            angular.element(self.layout.rotatePageLeft).unbind();
            angular.element(self.layout.showThumbnailsButton).unbind();
            angular.element(self.layout.showAttachmentsButton).unbind();
            angular.element(self.layout.goToParentButton).unbind();
        }

        function hideToolbuttonsforBrokenAttachment() {
            self.layout.goToParentButton.addClass("top-button");
            self.layout.searchButton.hide();
            self.layout.refreshViewButton.hide();
            self.layout.showThumbnailsButton.hide();
            self.layout.annotationsButton.hide();
            self.layout.rotatePageRight.hide();
            self.layout.rotatePageLeft.hide();
            self.layout.zoomOut.hide();
            self.layout.zoomIn.hide();
        }

        function showAllToolbuttons() {
            self.layout.searchButton.show();
            self.layout.showThumbnailsButton.show();
            self.layout.rotatePageRight.show();
            self.layout.rotatePageLeft.show();
            self.layout.zoomOut.show();
            self.layout.zoomIn.show();

            showConnectivityRelatedToolbuttons();
        }

        function showConnectivityRelatedToolbuttons() {
            if (ClientService.isOnline()) {
                self.layout.refreshViewButton.show();
                self.layout.annotationsButton.show();
            } else {
                self.layout.refreshViewButton.hide();
                self.layout.annotationsButton.hide();
            }
        }

        async function getAttachmentsAsync(id, retryRound) {
            if (id == void 0) {
                return;
            }

            return new Promise((resolve, reject) => {
                (async function _getAttachmentsAsync() {
                    let data = null;
                    let contentOfflineObject = null;

                    if (ClientService.isOffline()) {
                        contentOfflineObject = await OfflineCacheService.getPreviewContentById(id);

                        if (contentOfflineObject.info) {
                            data = contentOfflineObject.info;
                        } else {
                            resolve();
                            return;
                        }
                    } else {
                        let response;
                        let tmpUrl = getRenditionInfoTemplate.replace(/IDIDID/g, id);
                        tmpUrl = tmpUrl.replace(/TIMESTAMP/g, addTimestamp());

                        try {
                            response = await httpService.legacyGet(tmpUrl, urlBase);
                            data = response.data;
                        } catch (error) {
                            reject(error);
                            return
                        }
                    }

                    if (data && (data.page_count >= 0 || data.status != "processing")) {
                        extractAttachments(id, data.page_details, contentOfflineObject);
                        resolve();
                    } else {
                        retryRound++;

                        if (retryRound < 50) {
                            setTimeout(() => {
                                _getAttachmentsAsync();
                            }, 500);
                        } else {
                            reject();
                        }
                    }
                })();
            });
        }

        async function getAttachmentInformationAsync(objectId, index, id, retryRound, contentOfflineObject) {
            let data = null;
            let offlineAttachment = null;
            let renditionError = false;
            const offline = ClientService.isOffline();

            if (offline) {
                if (contentOfflineObject && contentOfflineObject.attachments) {
                    for (offlineAttachment of contentOfflineObject.attachments) {
                        if (offlineAttachment.info != null && offlineAttachment.info.id == id) {
                            data = offlineAttachment.info;
                            break;
                        }
                    }
                }
            } else {
                try {
                    let tmpUrl = getRenditionInfoTemplate.replace(/IDIDID/g, id);
                    tmpUrl = tmpUrl.replace(/TIMESTAMP/g, addTimestamp());

                    let response = await httpService.legacyGet(tmpUrl, urlBase);
                    data = response.data;
                } catch (error) {
                    console.error(error);
                    renditionError = true;
                }
            }

            if (data && (data.page_count >= 0 || data.status != "processing")) {
                let contentType = data.contenttype;
                analyseAttachmentInformationAsync(index, id, contentType, offlineAttachment);
            } else if (!renditionError && !offline) {
                retryRound = retryRound ? retryRound + 1 : 1;

                if (!offline && retryRound < 50) {
                    setTimeout(() => {
                        getAttachmentInformationAsync(objectId, index, id, retryRound, contentOfflineObject);
                    }, 500);
                }
            } else {
                analyseAttachmentInformationAsync(index, id, null, offlineAttachment);
            }
        }

        async function analyseAttachmentInformationAsync(index, id, contentType, offlineAttachment) {
            if (!contentType || excludedContentTypes.indexOf(contentType) != -1) {
                addImageElement(index, thumbnailNotPresentable);
            } else {
                let content = null;

                try {
                    if (ClientService.isOffline()) {
                        if (offlineAttachment.previewImage) {
                            content = new Blob([offlineAttachment.previewImage], { type: "image/png" });
                        }
                    } else {
                        let thumbnailAddress = thumbnailUrlTemplateComplete.replace(/IDIDID/g, id);
                        thumbnailAddress = thumbnailAddress.replace(/TIMESTAMP/g, addTimestamp());

                        let response = await httpService.legacyGet(thumbnailAddress, urlBase, { responseType: "arraybuffer" });
                        content = new Blob([response.data], { type: "image/png" });
                    }

                    if (!content || content.size == 2740) {
                        addImageElement(index, thumbnailDefaultImg);
                    } else {
                        addImageElement(index, content);
                    }
                } catch (error) {
                    console.log("ERROR: no previewError");
                    addImageElement(index, thumbnailDefaultImg);
                }
            }
        }

        function addImageElement(index, imgSource) {
            let noPreviewAvailable = (imgSource.indexOf && imgSource.indexOf(thumbnailNotPresentable) != -1);
            let noThumbnailAvailable = (imgSource.indexOf && imgSource.indexOf(thumbnailDefaultImg) != -1);
            let imgElement = noPreviewAvailable || noThumbnailAvailable ? document.createElement("i") : document.createElement("img");
            imgElement.classList.add("thumbnailImage");

            if (noPreviewAvailable || noThumbnailAvailable) {
                imgElement.classList.add("no-thumbnail");
                imgElement.classList.add(imgSource);
            } else {
                imgElement.src = window.URL.createObjectURL(imgSource);
            }

            if (attachmentContainer[index] != void 0) {
                let divElement = attachmentContainer[index];
                divElement.addEventListener("click", noPreviewAvailable ? switchToNoPreview : switchToAttachment);
                divElement.appendChild(imgElement);
                progressBars[index].hide();
                divElement.style.height = "";
            }
        }

        function reloadParent() {
            if (!parentID) {
                console.log("Error - no ParentID exist");
                return;
            }
            currentAttachmentID = undefined;
            currentVisibleID = parentID;
            notifyAnnotations(parentID);
            toggleNoPreviewContainer();
            _updateId(parentID);
            toggleAttachmentsPreview();
        }

        function switchToAttachment(event) {
            let index = event.currentTarget.id;

            if (index && attachmentObjects[index].id) {
                currentAttachmentID = attachmentObjects[index].id;
                currentVisibleID = currentAttachmentID;
                loadAttachmentAsync(currentAttachmentID, false);
            }
        }

        function switchToNoPreview() {
            currentVisibleID = -1;
            loadAttachmentAsync(undefined, true);
        }

        async function loadAttachmentAsync(attachmentId, noPreviewExist) {
            notifyAnnotations();
            toggleAttachmentButtonState(true);
            toggleNoPreviewContainer(noPreviewExist);

            let newUrl = undefined;

            if (attachmentId != undefined) {
                if (ClientService.isOffline()) {
                    const contentObject = await OfflineCacheService.getPreviewContentById(parentID);

                    if (contentObject) {
                        for (const i in contentObject.attachments) {
                            const attachment = contentObject.attachments[i];

                            if (attachment.info.id == attachmentId) {
                                const pdfBlob = new Blob([attachment.content], { type: "application/pdf" });
                                newUrl = window.URL.createObjectURL(pdfBlob);
                                break;
                            }
                        }
                    }
                } else {
                    newUrl = urlTemplate.replace(/IDIDID/g, attachmentId);
                    newUrl = _addBaseUrl(addTimestamp(newUrl));
                }
            } else {
                newUrl = require("!file-loader?outputPath=pdfs/!MODULES_PATH/dashlet/components/eob-pdfjs/pdfs/error.pdf");
            }

            await PDFViewerApplication.close();

            if (PDFViewerApplication != void 0 && PDFViewerApplication.pdfSidebar.isOpen) {
                closeSidebar();
            }

            await PDFViewerApplication.changeUrl(newUrl);
        }

        function resetViewerStates() {
            setBadgeInformation();
            attachmentObjects = [];
            attachmentContainer = [];
            progressBars = [];
            tmpSearchToken = undefined;
            currentAttachmentID = undefined;
            currentVisibleID = undefined;
            showAllToolbuttons();
            removeAttachmentThumbnails();
            toggleNoPreviewContainer();
            toggleAttachmentButtonState(false, "init");
        }

        function closePasswordPrompt() {
            try {
                let overlayManager = PDFViewerApplication.overlayManager,
                    passwordPrompt = PDFViewerApplication.passwordPrompt;

                let isPasswordPromptVisible = overlayManager.active == passwordPrompt.overlayName;
                if (isPasswordPromptVisible) {
                    PDFViewerApplication.passwordPrompt.close();
                }
            } catch (_) {
                // ignored
            }
        }

        function controlPreviewContainer(noDocument) {
            if (noDocument) {
                toggleAttachmentButtonState(false, "init");
                closeSidebar();
                return;
            }
            if (lastPreviewOpenState) { //SITEPREVIEW/THUMBNAILPREVIEW
                if (lastPreviewOpenState == "THUMBNAILPREVIEW") {
                    toggleAttachmentButtonState(true, "init");
                    openSidebar("attachments");
                } else {
                    toggleAttachmentButtonState((attachmentContainer.length > 0), "init");
                    openSidebar();
                }
            } else {
                attachmentsPreviewIsVisible = false;
                toggleAttachmentButtonState(false, "init");
                closeSidebar();
            }
        }

        function toggleAttachmentButtonState(newState, init) {
            MessageService.broadcast(ViewerEvent.ATTACHMENTS_AVAILABLE, { newState, init });

            if (init) {
                changeInvisibleClass(self.layout.goToParentButton, true);
                if (newState) {
                    changeInvisibleClass(self.layout.showAttachmentsButton);
                } else {
                    changeInvisibleClass(self.layout.showAttachmentsButton, true);
                }
                return;
            }
            if (newState) {
                changeInvisibleClass(self.layout.goToParentButton);
                changeInvisibleClass(self.layout.showAttachmentsButton, true);
            } else {
                changeInvisibleClass(self.layout.goToParentButton, true);
                changeInvisibleClass(self.layout.showAttachmentsButton);
            }
        }

        function setBadgeInformation(val) {
            if (self.layout.attachmentsBadge && self.layout.attachmentsBadge[0]) {
                self.layout.attachmentsBadge[0].innerHTML = val == void 0 ? "" : val;
            }
        }

        function removeAttachmentThumbnails() {
            let element = self.layout.attachmentInnerContainer[0];
            let firstChild = element.firstChild;
            while (firstChild) {
                element.removeChild(firstChild);
                firstChild = element.firstChild;
            }
        }

        function notifyAnnotations(id) {
            // As long as we have no offline annotations
            if (ClientService.isOffline() || isNaN(id)) {
                id = 0;
            }

            toggleAnnotationsUsableState(id);
            ViewerService.notifyAnnotations(id);
        }

        function toggleAnnotationsUsableState(id) {
            if (self.layout.annotationsButton.length == 0) {
                self.layout.annotationsButton = self.element.find(".eoBluebirdToolbarButton.annotations");
            }
            if (id) {
                changeInvisibleClass(self.layout.annotationsButton);
            } else {
                self.layout.annotationsButton.removeAttr("style"); // inline-block prevents hiding
                changeInvisibleClass(self.layout.annotationsButton, true);
            }
        }

        function addTimestamp(url) {
            if (url == void 0) {
                return $.now().toString();
            }

            if (url.indexOf("?") == -1) {
                url += `?t=${$.now()}`;
            } else {
                url += `&t=${$.now()}`;
            }
            return url;
        }

        function toggleSitePreview() {
            if (PDFViewerApplication.pdfSidebar.isOpen && !attachmentsPreviewIsVisible) {
                closeSidebar();
                lastPreviewOpenState = undefined;
                return;
            } else if (!PDFViewerApplication.pdfSidebar.isOpen) { //sidebar currently closed.
                openSidebar();
            }
            togglePreviewContainer(true);
            attachmentsPreviewIsVisible = false;
        }

        function togglePreviewContainer(pagePreviewIsVisible) {
            if (pagePreviewIsVisible) {
                lastPreviewOpenState = "SITEPREVIEW";
                changeInvisibleClass(self.layout.attachmentContainerElement, true);
                self.layout.showAttachmentsButton.removeClass("toggled");
                self.layout.showThumbnailsButton.addClass("toggled");

                changeInvisibleClass(self.layout.thumbnailContainer);
            } else {
                lastPreviewOpenState = "THUMBNAILPREVIEW";
                changeInvisibleClass(self.layout.attachmentContainerElement);
                changeInvisibleClass(self.layout.thumbnailContainer, true);
                self.layout.showAttachmentsButton.addClass("toggled");
                self.layout.showThumbnailsButton.removeClass("toggled");
            }
        }

        function closeSidebar() {
            if ("PDFViewerApplication" in window && PDFViewerApplication != void 0 && PDFViewerApplication.pdfSidebar != void 0) {
                $timeout(() => {
                    PDFViewerApplication.pdfSidebar.close();
                    self.layout.showThumbnailsButton.removeClass("toggled");
                    self.layout.showAttachmentsButton.removeClass("toggled");
                }, 50);
            }
        }

        function openSidebar() {
            if (PDFViewerApplication != void 0) {
                PDFViewerApplication.pdfSidebar.open();
            }
        }

        function toggleNoPreviewContainer(toIsVisibleState) {
            if (toIsVisibleState) {
                changeInvisibleClass(self.layout.noPreviewContainer);
            } else {
                changeInvisibleClass(self.layout.noPreviewContainer, true);
            }

        }

        function changeInvisibleClass(element, add) {
            if (add) {
                angular.element(element).addClass(invisible);
            } else {
                angular.element(element).removeClass(invisible);
            }
        }
    }
}

