// ****************************************************************
// USER NAME
// displays a users name depending on his properties
// ****************************************************************
// COMMENTS
// a single comment item
(function() {
    
    angular.module("eob.framework").directive("dvUsername", DvUsername);

    DvUsername.$inject = ["$log", "dvUsernameService", "dvToolService"];

    function DvUsername($log, DvUsernameService, DvToolService) {

        return {
            restrict: "AE",
            template: require("!raw-loader!./dv.username.html"),
            link(scope, element, attributes) {
                let user;
                let attrUser = attributes.user != void 0 ? attributes.user : undefined;
                let css = attributes.css != void 0 ? attributes.css : undefined;

                init();

                function init() {
                    if (!attrUser) {
                        $log.error("Error: dvUsername: no attribute 'user'\n attributes:", attrUser, attributes);
                        return;
                    }

                    extractUserObject();
                    setUsernameSnippet();
                }

                function extractUserObject() {
                    user = DvToolService.getDeepObjectByString(scope, attrUser);

                    if (user == void 0) {
                        $log.error("Error: dvUsername: no user object\n current scope:", attrUser, scope);
                    }
                }

                function setUsernameSnippet() {
                    if (!user) {
                        return;
                    }
                    scope.usersnippet = DvUsernameService.getHtmlUsernameSnippet(user, css);
                }
            }
        };

    }
})();
