/* eslint-disable @typescript-eslint/unbound-method */
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnInit,
    Renderer2
} from "@angular/core";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {Field} from "INTERFACES_PATH/field.interface";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {FieldType} from "MODULES_PATH/dms/interfaces/field-type.interface";
import {FieldControlType, FieldDataType} from "ENUMS_PATH/field.enum";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {Layout} from "INTERFACES_PATH/layout.interface";
import {
    TodoFieldApiService,
    TodoFormBuilderService,
    TodoFormData,
    TodoFormFieldBuilder,
    TodoFormHelper
} from "INTERFACES_PATH/any.types";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {FormControl} from "@angular/forms";
import {FormControlService} from "MODULES_PATH/form/services/form-control.service";
import {StateParams} from "@uirouter/core";

@Component({
    selector: "eob-form-element",
    templateUrl: "./eob-form-element.component.html",
    styleUrls: ["./eob-form-element.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class EobFormElementComponent implements OnInit, AfterContentInit, AfterViewInit {
    @Input("layoutfield") layoutField: Field;
    @Input() formhelper: TodoFormHelper;
    @Input() formdata: TodoFormData;
    @Input() layout: Layout;
    @Input() ismockform: boolean;
    @Input() formid: string;

    field: Field;
    fieldType: FieldType | FormFieldType | FieldControlType | FieldDataType;
    hasAddon: boolean;
    readonly state: string;

    model: FieldModel;
    layoutFactor: number;
    isInvisibleField: boolean;

    private ngOnInitResolve: () => void;
    private ngOnInitPromise: Promise<void> = new Promise((resolve) => this.ngOnInitResolve = resolve);

    // eslint-disable-next-line max-params
    constructor(@Inject(forwardRef(() => "fieldApiService")) public fieldApiService: TodoFieldApiService,
                @Inject(forwardRef(() => "formBuilderService")) public formBuilderService: TodoFormBuilderService,
                @Inject(forwardRef(() => "formFieldBuilder")) public formFieldBuilder: TodoFormFieldBuilder,
                @Inject(forwardRef(() => ClientService)) public clientService: ClientService,
                @Inject("$stateParams") public $stateParams: StateParams,
                private formControlService: FormControlService,
                public layoutManagerService: LayoutManagerService,
                public el: ElementRef,
                public renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.layoutFactor = this.layoutManagerService.isTouchLayoutActive() ? 20 : 16;
        this.field = this.formhelper.getFieldByInternal(this.layoutField.internal);
        // const fieldName: string = `${this.layoutField.internal}-${this.layoutField.isLabel ? "text" : "input"}`;
        this.field.api = this.fieldApiService.getFieldApi(this.formhelper, this.field, this.el.nativeElement);
        this.model = this.field.model;

        // for the styling/layout/etc all of these field types are being treated equally
        const textFieldTypes: string[] = [
            FormFieldType.ALPHANUMERIC,
            FormFieldType.CAPITAL,
            FormFieldType.CHOICE,
            FormFieldType.DATE,
            FormFieldType.DATETIME,
            FormFieldType.DECIMAL,
            FormFieldType.LETTER,
            FormFieldType.NUMBER,
            FormFieldType.TEXT,
            FormFieldType.TIME,
        ];

        this.fieldType = this.layoutField.isLabel ? FormFieldType.STATIC : this.field.model.type;
        if(textFieldTypes.includes(this.fieldType)) {
            this.fieldType = FormFieldType.TEXT;
        }

        if (this.fieldType == FormFieldType.TEXT) {
            this.field.model.control = this.formControlService.getInputControlByField(this.field, this.layoutField, this.formhelper);
        }

        if(this.fieldType == FormFieldType.CHECKBOX) {
            // A plain FormControl is enough here
            this.field.model.control = new FormControl();
        }

        this.hasAddon = !!this.layoutField.addon;
        this.ngOnInitResolve();
    }

    ngAfterContentInit(): void {
        let relativeAddonWidth: number;
        // the complete width in % of the container relative to the form
        this.model.fieldContainerWidth = this.layoutField.realWidth;
        // the width of the addon button in % relative to the container
        if (this.layout?.form) {
            this.model.addonButtonWidth = this.layout.form.addonWidth;
        }

        // DODO-16720: We start to allow form groups "being" fully invisible. This will result in showing different
        // search and edit forms in enaio client and enaio webclient.
        // In enaio client, all fields set to 'visible' under a group are shown, even if the "surrounding" group is set
        // to 'invisible' in the enaio editor. For enaio client, the group is just a nice border, not a real container.
        // In enaio webclient, a group is a real container. Thus, no containing fields will be shown, if the group
        // is not visible.
        this.isInvisibleField = (this.model.isInvisibleField || (this.model.isInvisibleText && this.layoutField.isLabel)); // && this.model.type !== FormFieldType.GROUP

        let elementHeight: number = this.layoutField.height * this.layoutFactor;
        if (this.model.type == FormFieldType.GROUP && this.clientService.isTouchDevice() && !this.clientService.isPhone()) {
            elementHeight -= 20;
        }

        this.renderer.setStyle(this.el.nativeElement, "height", `${elementHeight}px`);
        this.renderer.setStyle(this.el.nativeElement, "top", `${this.layoutField.row * this.layoutFactor}px`);
        this.renderer.setStyle(this.el.nativeElement, "left", `${this.layoutField.realLeft}%`);
        this.renderer.setStyle(this.el.nativeElement, "width", `${this.model.fieldContainerWidth}%`);
        this.renderer.setStyle(this.el.nativeElement, "max-width", `${this.model.fieldContainerWidth}%`);

        if (this.layoutField.isLabel || this.model.addon == void 0) {
            this.renderer.setStyle(this.el.nativeElement, "width", `${this.model.fieldContainerWidth}%`);
            this.renderer.setStyle(this.el.nativeElement, "max-width", `${this.model.fieldContainerWidth}%`);
        }

        if (!this.layoutField.isLabel && this.model.addon != void 0) {
            const completeWidth: number = this.model.fieldContainerWidth + this.model.addonButtonWidth;
            relativeAddonWidth = (this.model.addonButtonWidth / completeWidth) * 100;
            this.renderer.setStyle(this.el.nativeElement, "width", `${completeWidth}%`);
            this.renderer.setStyle(this.el.nativeElement, "max-width", `${completeWidth}%`);
        }

        this.renderer.setAttribute(this.el.nativeElement, "internal", this.layoutField.internal);

        // only get the dom element for field types that are not converted to angular components yet
        if (this.model.type == FormFieldType.GRID) {
            const domElement: JQLite = this.formBuilderService.getDomElementByType(this.field, this.el.nativeElement, this.formdata, this.layoutField, this.formhelper);
            if (domElement) {
                this.renderer.appendChild(this.el.nativeElement, domElement[0]);
            }
        }

        if (this.isInvisibleField) {
            this.renderer.setStyle(this.el.nativeElement, "display", "none");
        }

        if (this.model.isRequired) {
            this.renderer.addClass(this.el.nativeElement, "required");
        }

        if (this.model.isUnique) {
            this.renderer.addClass(this.el.nativeElement, "unique");
        }

        if (this.layoutField.isOffCanvas) {
            this.renderer.addClass(this.el.nativeElement, "invisible-field");
            this.renderer.setAttribute(this.el.nativeElement, "hidden", "true");
        }

        if (this.layoutField.width && this.layoutField.width < 1) {
            // console.warn("to small", this.layoutField.width);
            this.layoutField.width = 1;
        }
    }

    async ngAfterViewInit(): Promise<void> {
        await this.ngOnInitPromise;

        if (this.field.api != void 0 && this.field.model.type != FormFieldType.GROUP && this.field.model.type != FormFieldType.STATIC) {
            if (this.field.api.bindDefaultFocusEvents) {
                this.field.api.bindDefaultFocusEvents();
            }
            if (this.field.api.bindDefaultOnChange) {
                this.field.api.bindDefaultOnChange();
            }
        }

        // the grid needs another cycle to get all its data set up
        if (this.field.model.type == FormFieldType.GRID) {
            await new Promise(resolve => setTimeout(resolve, 0));
        }

        if (!new RegExp(`${FormFieldType.PAGECONTROL}|${FormFieldType.GROUP}`).test(this.field.model.type)) {
            if (this.field.api != void 0) {
                if (this.field.isDisabled && typeof (this.field.api.disable) == "function") {
                    this.field.api.disable();
                }

                if (this.field.model.isInvisibleField) {
                    this.field.api.hide();
                }
            }
        }
    }
}
