import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from "@angular/core";
import {TodoEnvironmentService, TodoStateHistoryManager, TodoStateService} from "INTERFACES_PATH/any.types";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {Cabinet} from "INTERFACES_PATH/object-type.interface";
import {LayoutConfiguration} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {EobPanelComponent} from "MODULES_PATH/navigation/components/eob-panel/eob-panel.component";
import {PanelKeyboardNavigation} from "MODULES_PATH/navigation/interfaces/panelKeyboardNavigation.interface";
import {NavigationDirection} from "MODULES_PATH/navigation/enums/navigationDirection.enum";
import {Subscription} from "rxjs";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";

@Component({
    selector: "eob-nav-objectsearch",
    templateUrl: "./eob-nav-object-search.component.html",
    styleUrls: ["./eob-nav-object-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EobNavObjectSearchComponent implements OnInit, AfterViewInit, PanelKeyboardNavigation, OnDestroy {
    @ViewChildren(EobPanelComponent) panels!: QueryList<EobPanelComponent>;
    hitlistConfigAllowed: boolean;
    cabinets: Cabinet[];
    isIosMobile: boolean;
    showAltLang: boolean;
    objectDefLang: string;
    private subs: Subscription = new Subscription();

    constructor(
        @Inject("environmentService") private environmentService: TodoEnvironmentService,
        @Inject("stateService") private stateService: TodoStateService,
        @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
        private asIniService: AsIniService,
        private objectTypeService: ObjectTypeService,
        private actionService: ActionService,
        private clientService: ClientService,
        private messageService: MessageService,
        private navigationService: NavigationService,
    ) {
    }

    ngOnInit(): void {
        const layoutConfig: LayoutConfiguration = this.asIniService.getLayoutConfiguration();

        this.hitlistConfigAllowed = this.environmentService.userHasRole("R_CLNT_STORE_SETTINGS");
        this.cabinets = this.objectTypeService.getNavigation();
        this.isIosMobile = this.clientService.isiOsMobile();
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang();
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();
        // Todo: It's not nice that activeCabinet could be not there or -1.
        //       On TS refactoring we should guaretee that it has a value like at least -1.
        if (layoutConfig.activeCabinet != void 0 && layoutConfig.activeCabinet != "-1") {
            const activeCab: LayoutConfiguration = this.cabinets.find(x => x.cabinetId == layoutConfig.activeCabinet);
            if (activeCab) {
                activeCab.expanded = true;
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.navigationService.isFixed) {
            this.panels.first?.setTabReachable(true);
        }

        this.subs.add(this.navigationService.focusFirstPanel(this.panels, "objectsearch"));
    }

    passFocus(senderSide: NavigationDirection, senderID: number): void {
        this.navigationService.passFocus(this.panels, senderSide, senderID);
    }

    resetTabindexes(exceptForID: number): void {
        this.navigationService.resetTabindex(this.panels, exceptForID);
    }

    closeOtherCabinetPanels(cabinet: Cabinet): void {
        this.cabinets.filter(x => x.cabinetId != cabinet.cabinetId).map(y => y.expanded = false);
        cabinet.expanded = !cabinet.expanded;

        if (cabinet.expanded) {
            this.asIniService.updateLayoutConfiguration({activeCabinet: cabinet.cabinetId});
        } else {
            this.asIniService.updateLayoutConfiguration({activeCabinet: -1});
        }
    }

    showHitlistConfig(cabinetId: number, event: Event): void {
        // prevent the events --> if not, the search mask would open first
        event.stopPropagation();
        event.stopImmediatePropagation();

        this.stateService.showHitlistConfig(cabinetId);
    }

    createFolder(objectTypeId: string, event: Event): void {
        // prevent the events --> if not, the search mask would open first
        event.stopPropagation();
        event.stopImmediatePropagation();

        void this.actionService.createObject(undefined, objectTypeId);
    }

    openSearchView(objectTypeId: number, event: Event): void {
        event.stopImmediatePropagation();
        const nextStateId: number = Date.now();
        this.stateHistoryManager.setStateData({config: {userAction: "search"}, type: "search"}, nextStateId);
        this.stateHistoryManager.goToState("search", {objectTypeId, state: nextStateId});
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
