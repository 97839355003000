import {Script} from "../../../eob-model/eob.script.model";

require("MODELS_PATH/eob.cache.model.srv.js");
require("MODELS_PATH/eob.variant.model.srv.js");
require("MODELS_PATH/eob.desktop.model.srv.js");
require("MODELS_PATH/eob.objecttype.model.srv.js");

angular.module("eob.core").factory("cacheManagerService", CacheManagerService);

CacheManagerService.$inject = ["cacheModelService", "dmsDocumentService", "variantModelService", "desktopModelService", "objectTypeModelService"];

/**
 * A service that wraps all caches that are used in the webclient.
 */
// eslint-disable-next-line max-params, require-jsdoc
export default function CacheManagerService(CacheModelService, DmsDocumentService, VariantModelService, DesktopModelService, ObjectTypeModelService) {
    let Cache = CacheModelService.createCache;

    let service = {};

    service.dmsDocuments = Cache(DmsDocumentService.createDmsDocument, "osid");
    service.variantTreeData = Cache(VariantModelService.createVariantTreeData, "originalVariantId");
    service.desktopData = Cache(DesktopModelService.createDesktopEntry, "guid");
    service.scripts = Cache(wrapConstructor(Script), "nameKey");
    service.objectTypes = Cache(ObjectTypeModelService.createObjectType, "osid");

    return service;

    /**
     * Wrap a constructor in a callable function.
     * @param {function} constructor - A constructable but not callable function.
     * @returns {function} A callable function.
     */
    function wrapConstructor(constructor) {
        return (...args) => { return new constructor(...args); };
    }
}
