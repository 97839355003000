import {Directive} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {ElementRef, Injector, Input} from "@angular/core";

@Directive({
    selector: "eob-nav-external-tray"
})
export class EobNavExternalTrayDirective extends UpgradeComponent {

    constructor(elementRef: ElementRef, injector: Injector) {
        super("eobNavExternalTray", elementRef, injector);
    }

}
