import {Inject, Injectable} from "@angular/core";
import {DmsActionService} from "MODULES_PATH/dms/dms-action.service";
import {TodoCacheManagerService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";

export interface SelectedItem {
    objectId: string;
    objectTypeId: string;
}

@Injectable({
    providedIn: "root"
})
export class OsClientService {
    constructor(private dmsActionService: DmsActionService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("stateHistoryManager") protected stateHistoryManager: TodoStateHistoryManager) {
    }

    osjxOpenLocation(...params): Promise<void> {
        const [inNewTab, objectId, objectTypeId, parentId, parentTypeId] = params;
        return this.dmsActionService.openLocation(inNewTab, objectId, objectTypeId, parentId, parentTypeId);
    }

    osjxOpenDataSheet(...params): boolean {
        const [inNewTab, mode, objectId, objectTypeId] = params;
        return this.dmsActionService.openIndexData(inNewTab, mode, objectId, objectTypeId);
    }

    osjxGetSelectedObjects(): string {
        const selectItems: SelectedItem[] = this.stateHistoryManager.getSelectedItems() as SelectedItem[];
        return selectItems.map(x => `${x.objectId},${x.objectTypeId}`).join(";");
    }

    osjxRefreshObjectInLists(id: string): Promise<void> {
       return this.cacheManagerService.dmsDocuments.getOrFetchById(id, undefined, true);
    }

    osjxOpenResultList(payloadJson: string): Promise<void> {
        const payload = JSON.parse(payloadJson);
        if(!Array.isArray(payload.hits)) {
            throw new Error("Invalid payload supplied");
        }
        return this.dmsActionService.openResultListByIds(false, payload.hits.map(x => ({osid: x.id, objectTypeId: x.type})), payload.title ?? "", "", false);
    }

    // osjxGetEnvironment(id: string): OsDashletInit {
    //     return this.customDashletService.getOsDashletInit(id);
    // }
}
