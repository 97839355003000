
/**
 * Enum describing the possible EMS related status.
 */
export enum EmsStatusCodes {
    SUCCESSFUL_STORED = 0,
    GENERAL_EMS_ERROR = -1,
    FILENAME_PARSE_ERROR = -2,
    STORING_FAILED = -3,
    STORING_CANCELED_BY_USER = -4,
    STORING_CANCELED_BY_TIMEOUT = -5,
    DEDUPLICATION_FAILED = -6
}

export enum EmsDeduplicationOption {
    CREATE_COPY = "INSERT", // EMS "INSERT" is to create a DMS copy
    ADD_LOCATION = "COPY", // EMS "COPY" is to add a location
    CREATE_REFERENCE_DOCUMENT = "LINK"
}
