import {Inject, Injectable, OnDestroy} from "@angular/core";
import {
    AuthenticationData,
    AuthenticationProvider,
    AuthenticationState,
    AuthenticationStatusEvent,
    AuthenticationType,
    AuthRequestHeader
} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {defer, interval, Observable, of, Subscription} from "rxjs";
import {HttpBackend, HttpClient, HttpHandler, HttpRequest} from "@angular/common/http";
import {catchError, map, retry, switchMap, throttle} from "rxjs/operators";
import {AuthenticationModule} from "../authentication.module";
import {from, Subject, throwError} from "rxjs";
import {Profile, ProfileService} from "../util/profile.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

@Injectable({
    providedIn: AuthenticationModule
})
export class NtlmAuthService implements AuthenticationProvider, OnDestroy {
    // TODO Remove
    /* eslint-disable no-console */
    private readonly translateFn: TranslateFnType;
    httpClient: HttpClient;
    subscriptions: Subscription = new Subscription();
    profile: Profile;
    customStorage: CustomStorage;
    statusEvent$: Subject<AuthenticationStatusEvent> = new Subject<AuthenticationStatusEvent>();

    // eslint-disable-next-line max-params
    constructor(customStorageService: CustomStorageService,
                handler: HttpBackend,
                private profileService: ProfileService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("$filter") $filter: ng.IFilterService,
                @Inject("fileCacheService") protected fileCacheService: FileCacheService
    ) {
        this.httpClient = new HttpClient(handler);
        this.translateFn = $filter("translate");
        this.subscriptions.add(
            customStorageService.getStorage().subscribe(storage => {
                this.customStorage = storage;
            }));
    }

    isAuthenticated(): Observable<boolean> {
        if (!window.electron || !this.profileService.hasProfiles()) {
            return of(false);
        }

        const lastActiveProfile: Profile = this.profileService.getLastActiveProfile();
        if (!lastActiveProfile) {
            return of(false);
        }

        // check autentication with osrest call
        return this.httpClient.get(`${lastActiveProfile.url}/osrest/api/session`, {
            responseType: "text"
        }).pipe(
            switchMap(p =>
                defer(async () => {
                    // gateway session is valid, prepare secure items in last active profile and propagate
                    // NTLM auth headers from profile, which might be lost after page reload in electron
                    await this.prepareLastActiveProfile(lastActiveProfile);
                    if (lastActiveProfile.authHeaders?.length > 0) {
                        window.electron.propagateAuthHeaders(lastActiveProfile.authHeaders);
                    }
                }).pipe(
                    map(p2 => {
                        // gateway session is valid and NTLM auth headers are propagated
                        sessionStorage.setItem("forceAutoLogin", "true");
                        return true;
                    }), catchError(error => {
                        // error while propagating NTLM auth headers
                        console.error(error);
                        return of(false);
                    })
                )
            ), catchError(e1 =>
                // osrest test call failed
                defer(async () => {
                    // prepare secure items in last active profile and reset session cookie on electron side for all
                    // web sessions (NTLM auth headers from profile will be propagated in setPartitionSessionCookie)
                    await this.prepareLastActiveProfile(lastActiveProfile);
                    if (lastActiveProfile.authHeaders?.length > 0) {
                        await window.electron.setPartitionSessionCookie(lastActiveProfile.url, lastActiveProfile.authHeaders, true);
                    }
                }).pipe(
                    // reauthenticate for NTLM with last active profile
                    switchMap(p3 => this._authenticateWithNtlm(lastActiveProfile)
                        .pipe(
                            map(p4 => {
                                // NTLM authentication successful
                                sessionStorage.setItem("forceAutoLogin", "true");
                                return true;
                            }), catchError(error => {
                                // NTLM authentication failed
                                console.error(error);
                                return of(false);
                            })
                        )
                    ), catchError(error => {
                        // NTLM authentication failed
                        console.error(error);
                        return of(false);
                    })
                ) // reauthenticate
            ) // osrest test call failed
        ); // return this.httpClient.get
    }

    // set secure items in profile according to authType
    private async prepareLastActiveProfile(lastActiveProfile: Profile): Promise<void> {
        const lastActiveProfileKey: string = this.profileService.getLastActiveProfileKey();

        switch (lastActiveProfile.authType) {
            case AuthenticationType.BASIC_AUTH:
            case AuthenticationType.NTLM_USERNAME:
                const password: string = await this.customStorage.getSecureItem(`pw@@@${lastActiveProfileKey}`);
                if (password) {
                    lastActiveProfile.password = password;
                }
                break;

            case AuthenticationType.NTLM_SYSTEM:
                const authCookie: string = await this.customStorage.getSecureItem(`authcookie@@@${lastActiveProfileKey}`);
                if (authCookie) {
                    const authHeaders: AuthRequestHeader[] = [{key: "Cookie", value: authCookie}];
                    lastActiveProfile.authHeaders = authHeaders;
                }
                break;

            default:
                break;
        }
    }

    authenticate(backendOrigin: string, credentials?: AuthenticationData): Observable<AuthenticationStatusEvent> {
        if (!window.electron) {
            return of({state: AuthenticationState.INVALID_AUTHENTICATION_TYPE_FOR_PLATFORM});
        }

        if (credentials.authType !== AuthenticationType.NTLM_USERNAME && credentials.authType !== AuthenticationType.NTLM_SYSTEM) {
            return of({state: AuthenticationState.INVALID_AUTHENTICATION_TYPE_FOR_PROVIDER});
        }

        const profile: Profile = {
            url: backendOrigin,
            username: credentials.username,
            password: credentials.password,
            authType: credentials.authType
        };

        return this._authenticateWithNtlm(profile).pipe(
            map(_ => {
                // persist session
                sessionStorage.setItem("forceAutoLogin", "true");
                return {state: AuthenticationState.LOGGED_IN};
            }),
            catchError(error => of({state: AuthenticationState.LOGIN_FAILED, error})));
    }

    private _authenticateWithNtlm(profile: Profile): Observable<void> {
        const profileKey: string = this.profileService.getProfileKeyFromProfile(profile);

        return defer(async () => {
            // try to restore password from secure storage, if NTLM with username, and password not set
            if (profile.authType === AuthenticationType.NTLM_USERNAME && !profile.password) {
                profile.password = await this.customStorage.getSecureItem(`pw@@@${profileKey}`);
            }
        }).pipe(
            switchMap(_ => window.electron.getNtlmAuthenticationCookie(profile)
                .pipe(
                    switchMap((authCookie: string) =>
                        defer(async () => {
                                if (!authCookie || authCookie.length < 1) {
                                    window.electron.cancelNtlmAuthenticationCookieGet();
                                    // eslint-disable-next-line no-throw-literal
                                    throw {message: "login really failed", status: -2};
                                }

                                // save and activate profile
                                // TODO check if necessary
                                this.profileService.saveProfile(profile);

                                // store password, if NTLM and username
                                if (profile.authType === AuthenticationType.NTLM_USERNAME) {
                                    await this.customStorage.setSecureItem(`pw@@@${profileKey}`, profile.password);
                                }

                                // store authentication cookie for profile
                                const authHeaders: AuthRequestHeader[] = [{key: "Cookie", value: authCookie}];
                                profile.authHeaders = authHeaders;
                                await this.customStorage.setSecureItem(`authcookie@@@${profileKey}`, authCookie);
                                window.electron.storeSessionStorage();

                                // set authentication cookie in webclient root partition session
                                await window.electron.setPartitionSessionCookie(profile.url, authHeaders, true);
                                this.statusEvent$.next(
                                    {state: AuthenticationState.LOGGED_IN, requestHeaders: authHeaders}
                                );
                            }
                        ) // use authCookie for profile and session
                    ) // handle getNtlmAuthenticationCookie result
                ) // pipe getNtlmAuthenticationCookie result
            ) // call getNtlmAuthenticationCookie
        ) as Observable<void>;
    }

    invalidateSession(): Observable<AuthenticationStatusEvent> {
        console.info("ntlm invalidateSession()");
        if (!window.electron) {
            return of({
                state: AuthenticationState.INVALID_AUTHENTICATION_TYPE_FOR_PLATFORM
            });
        }

        return defer(async () => {
            sessionStorage.removeItem("forceAutoLogin");
            sessionStorage.removeItem("afterFirstLogin");
            sessionStorage.removeItem("randomSessionId");

            await this.fileCacheService.removeTemporaryEntriesAsync();
            await this.fileCacheService.removeSessionDataAsync();

            const currentProfile: Profile = this.profileService.prepareCurrentProfile();
            if (currentProfile.url && currentProfile.authType) {
                // autologin is not allowed in saved profiles after we logged out
                if (currentProfile.autologin) {
                    currentProfile.autologin = false;
                    this.profileService.saveProfile(currentProfile);
                }

                // remove auth cookie from secure storage
                try {
                    // if no secure storage element for the given id exists, the promise is rejected (at least on iOS)
                    await this.customStorage.removeSecureItem(`authcookie@@@${this.profileService.getProfileKeyFromProfile(currentProfile)}`);
                } catch (_) {
                    // ignored
                }
            }
        }).pipe(
            switchMap(_ => {
                // TODO add /_secure/logout call
                this.statusEvent$.next({state: AuthenticationState.LOGGED_OUT});
                return of({state: AuthenticationState.LOGGED_OUT});
            }),
            catchError(e => throwError(e)));
    }

    getStatusEvent(): Observable<AuthenticationStatusEvent> {
        return this.statusEvent$.asObservable();
    }

    handleReauthenticationRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!window.electron) {
            return next.handle(req);
        }
        return defer(async () => {
            const currentProfile: Profile = this.profileService.prepareCurrentProfile();
            if (currentProfile.authType == AuthenticationType.NTLM_USERNAME) {
                currentProfile.password = await this.customStorage.getSecureItem(`pw@@@${this.profileService.getProfileKeyFromProfile(currentProfile)}`);
            }
            return currentProfile;
        }).pipe(
            switchMap(profile => window.electron.getNtlmAuthenticationCookie(profile).pipe(
                throttle(val => interval(200)),
                retry(3),
                switchMap((authCookie: string) => {
                    this.statusEvent$.next({
                        state: AuthenticationState.SESSION_REFRESHED,
                        requestHeaders: [{key: "Cookie", value: authCookie}]
                    });
                    return from(window.electron.setPartitionSessionCookie(profile.url, [{
                        key: "Cookie",
                        value: authCookie
                    }], true));
                }),
                switchMap(() => next.handle(req)),
                catchError((refreshTokenError) => {
                    console.warn(refreshTokenError);
                    return throwError(refreshTokenError);
                })))
        );
    }

    ngOnDestroy(): void {
        console.info("ntlm ngOnDestroy()");
        this.subscriptions.unsubscribe();
    }
}
