import {Component, Inject, OnInit} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-baseparam-switch",
    templateUrl: "./eob-baseparam-switch.component.html",
    styleUrls: ["./eob-baseparam-switch.component.scss"]
})

export class EobBaseparamSwitchComponent implements OnInit {
    constructor(private messageService: MessageService,
                protected clientService: ClientService,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                @Inject("stateHistoryManager") protected stateHistoryManager: any) {
    }

    active = false;
    initialValue = false;

    ngOnInit(): void {
        const canUseBaseParams: boolean = this.environmentService.env.form.useBaseParams;
        if (!canUseBaseParams) {
            return;
        }

        const stateConfig: any = this.stateHistoryManager.getCurrentConfig();
        if (stateConfig?.showBaseParams) {
            this.initialValue = true;
            this.toggleBaseparams();
        }
    }

    toggleBaseparams(): void {
        if(!this.clientService.isPhoneOrTablet()) {
            this.active = !this.active;
        }
        this.messageService.broadcast(FormEvent.TOGGLE_BASEPARAMS);
    }
}