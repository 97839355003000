export enum NavigationAction {
    SHOW_INBOX = "showInbox",
    SHOW_WORKFLOW = "showWorkflow",
    SHOW_FAVORITES = "showFavorites",
    SHOW_OFFLINE_OBJECTS = "showOfflineObjects",
    SHOW_NAV_AVATAR = "showNavAvatar",
    SHOW_HISTORY = "showHistory",
    SHOW_INBOX_AREA = "showInboxArea",
    SHOW_DESKTOP = "showDesktop",
    SHOW_QUICKSEARCHES = "showQuickSearches",
    SHOW_NAV_KEBAB = "showNavKebab",
    SHOW_OBJECTSEARCH = "showObjectsearch",
    SHOW_EXTERNALTRAY = "showExternalTray",
    EXTERNALTRAY_ELEMENTS_AVAILABLE = "externalTrayElementsAvailable"
}
