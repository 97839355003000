/**
 * Global broadcast names to be used with the message service
 */
export enum Broadcasts {
    /**
     * To be broadcasted after receiving the `FeatureSet` from the AppConnector. The `FeatureSet` must be added as a payload.
     */
    FEATURESET_RECEIVED = "FEATURESET_RECEIVED",

    /**
     * We have loaded the server version. This is as a example used to detect which backend we could use.
     */
    PRODUCT_VERSION_RECEIVED = "PRODUCT_VERSION_RECEIVED",

    /**
     * The environment service has successfully finished initializing the webclient.
     */
    ENVIRONMENT_INITIALIZED = "ENVIRONMENT_INITIALIZED",

    /**
     * Payload: all available object types as ObjectType[]
     */
    OBJECT_TYPES_INITIALIZED = "OBJECT_TYPES_INITIALIZED",

    /**
     * Payload: All existing object links as ObjectLinkResponse
     */
    OBJECT_LINK_UPDATED = "OBJECT_LINK_UPDATED",
    OBJECT_LINKS_REMOVED = "OBJECT_LINKS_REMOVED",

    /**
     * Payload: {@link Connection}
     */
    CONNECTIVITY_CHANGED = "CONNECTIVITY_CHANGED",

    /**
     * Payload: boolean
     */
    SUPPRESS_CONNECTIVITY_CHANGE_HANDLER = "SUPPRESS_CONNECTIVITY_CHANGE_HANDLER",

    /**
     * Todo
     */
    INIT_MAIN_VIEW = "INIT_MAIN_VIEW",

    /**
     * Signal that it is ready to receive messages it has subscribed to.
     */
    LOCATION_VIEW_READY = "LOCATION_VIEW_READY",
    CREATION_VIEW_READY = "CREATION_VIEW_READY",

    /**
     * Todo
     */
    VIEWER_READY = "VIEWER_READY",

    /**
     * Todo
     */
    INSERT_EMAILS_CREATE_FORM = "INSERT_EMAILS_CREATE_FORM",

    /**
     * This messages tells the user management that a user shall be saved to the backend.
     */
    USERMANAGEMENT_SAVE_USER = "USERMANAGEMENT_SAVE_USER",

    /**
     * Payload: `Array<OsrestWorkflowInbox & (insert grid model type here)>`
     */
    INBOX_INDEXDATA_CHANGED = "INBOX_INDEXDATA_CHANGED",

    /**
     * For when you feel the need to trigger a global change detection from somewhere where you usually shouldn't trigger it
     */
    DETECT_CHANGES = "DETECT_CHANGES",
}
