<input
    [id]="fieldid"
    [title]="control.eobOptions?.type == 'password' ? '' : control.value"
    [fieldMask]="control.eobOptions?.masks"
    [formControl]="control"
    [attr.has-addon]="hasAddon"
    [attr.placeholder]="showPlaceholder ? control.eobOptions?.placeholder : ''"
    [attr.readonly]="disabled == true || control.disabled ? '' : null"
    [ngClass]="{ 'mock-field': ismockform }"
    [eobAutocomplete]="autocomplete"
    (click)="clickHandler($event)"
    (keydown)="keyDownHandler($event)"
    (input)="inputHandler()"
    [type]="control.eobOptions?.type || 'text'"
    class="form-row-input"
    eobDefaultInput
    #input
    eobDisplayBubble
    [bubble]="customBubble || bubble"
    [control]="control"
    [host]="input"
    [attr.disabled]="(control.disabled || disabled) ? '' : null"
    (focus)="onFocus()"
    (blur)="onBlur()"
>
<eob-addon *ngIf="control.eobOptions?.addonConfig" [control]="control"></eob-addon>
<eob-standalone-validation-bubble #bubble></eob-standalone-validation-bubble>
