(function() {
    angular.module("eob.core").factory("treeAddonService", TreeAddonService);

    TreeAddonService.$inject = ["sortService", "messageService"];

    /**
     * A service to display the tree addon.
     */
    function TreeAddonService(SortService, MessageService) {

        return {
            showTree,
            hasShortValues,
            createParentNodes,
            sortTreeNodes,
            setDefaultShortValues,
            removeDeprecatedStarEntries
        };

        /**
         * Show the tree addon and give it all the necessary information it needs to display itself and its content.
         * @param {Object} event - The click event to determine where the catalog shall be opened.
         * @param {Object} field - The whole field with model and api.
         * @param {Object} formHelper - The formHelper of the form the field belongs to.
         */
        function showTree(event, field, formHelper) {
            let config = {
                event,
                target: event.target,
                data: field,
                element: field.api.getElement(),
                list: [],
                isSearch: formHelper.isSearch
            };

            if (field.model.hasPseudoCatalog) {
                config.config = field.model.pseudoCatalogDefinition.config;

                config.promise = new Promise(((resolve) => {
                    // pseudoCatalogDataCallback parameters are different in normal text fields and grid cells
                    const fieldOrCell = field.model.isGridCell ? { colIndex: field.model.cell.colIndex, rowIndex: field.model.cell.rowIndex } : field;
                    const fieldOrRowValue = field.model.isGridCell ? field.model.grid.api.getRowByIndex(field.model.cell.rowIndex) : field.value;

                    field.model.pseudoCatalogDataCallback(fieldOrCell, fieldOrRowValue, (response) => {
                        resolve(response)
                    });
                }));
            } else {
                config.list = field.api.getListEntries();
            }

            if (formHelper.isView && field.model.tree && field.model.tree.config) {
                field.model.tree.config.readonly = true
            }

            event.stopImmediatePropagation();
            MessageService.broadcast("showTree", config);
        }

        /**
         * Check the given nodes if short values are present
         * @param items - recursive tree nodes
         * @returns {boolean}
         */
        function hasShortValues(items) {
            for (let i in items) {
                let item = items[i];

                if (item.short != void 0 && item.short != item.value) {
                    return true;
                } else if (item.nodes != void 0) {
                    let hasShorts = hasShortValues(item.nodes);
                    if (hasShorts) {
                        return true;
                    }
                }
            }

            return false;
        }

        /**
         * Create parent nodes for each child node to ensure we can traverse from every node to the root node and back
         * this is used to create values for hierarchy catalogs
         * @param items - recursive tree nodes
         * @param parent - the current parent node of each item
         */
        function createParentNodes(items, parent) {
            for (let i in items) {

                if (parent != void 0) {
                    items[i].parent = parent;
                }

                if (items[i].nodes != void 0) {
                    createParentNodes(items[i].nodes, items[i])
                }
            }
        }

        /**
         * Sort the tree / list recursively by the given sortKey
         * @param fieldModel
         * @param items - recursive tree nodes
         * @param sortKey - the key to sort by. "short" or "value"
         */
        function sortTreeNodes(fieldModel, items, sortKey) {
            if (fieldModel.subType == "date" || fieldModel.subType == "datetime") {
                SortService.sortByDatetimePath(items, sortKey);
            } else if (fieldModel.subType == "number" || fieldModel.subType == "decimal") {
                SortService.sortByNumberPath(items, sortKey);
            } else {
                SortService.sortByTextPath(items, sortKey);
            }

            if (fieldModel.addon != "list") {
                for (let i in items) {
                    let node = items[i];
                    if (Array.isArray(node.items) || Array.isArray(node.nodes)) {
                        sortTreeNodes(fieldModel, node.items || node.nodes, sortKey)
                    }
                }
            }
        }

        /**
         * set the short values if they are missing
         * @param nodes - the tree nodes
         */
        function setDefaultShortValues(nodes) {
            for (let i in nodes) {
                if (nodes[i].short == void 0) {
                    nodes[i].short = nodes[i].value;
                }

                if (nodes[i].nodes != void 0) {
                    setDefaultShortValues(nodes[i].nodes);
                }
            }
        }

        function removeDeprecatedStarEntries(nodes, allowEntries, allowIntermediate) {
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                const value = node.value.toString();
                if (value.charAt(value.length - 1) === "*") {
                    if (!allowEntries) {
                        nodes.splice(i, 1);
                        i--;
                        continue;
                    } else {
                        const sameShort = node.value === node.short;
                        node.value = value.substr(0, value.lastIndexOf("*"));
                        node.short = sameShort ? node.value : node.short;
                    }
                }

                if (node.nodes) {
                    removeDeprecatedStarEntries(node.nodes, allowEntries, allowIntermediate);

                    if (node.nodes.length === 0) {
                        if (!allowIntermediate) {
                            nodes.splice(i, 1);
                            i--;
                        }
                        delete node.nodes;
                    }
                }
            }
        }
    }
})();
