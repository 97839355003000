import {Directive, HostListener, OnInit} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {Inject} from "@angular/core";
import {GlobalEventEnum} from "ENUMS_PATH/global-event.enum";
import {TodoTouchHandlerService} from "INTERFACES_PATH/any.types";

@Directive({
    selector: "[eob-global-events]"
})
export class EobGlobalEventsDirective implements OnInit {

    constructor(@Inject("$rootScope") private $rootScope: ng.IRootScopeService,
                @Inject("touchHandlerService") protected touchHandlerService: TodoTouchHandlerService,
                private clientService: ClientService,
                private messageService: MessageService,
    ) {
    }

    useGlobalEvent = true;

    ngOnInit(): void {
        if (this.clientService.isTouchDevice()) {
            // TODO check if is mobile browser cause iOS has already native back swipe in browser
            this.touchHandlerService.initSwipeNavigation();
            this.messageService.subscribe(GlobalEventEnum.USE_GLOBAL_EVENT, (data: boolean) => {
                this.useGlobalEvent = data;
            });
        }
        window.addEventListener("keydown", event => {
            if ((event.key == "Esc" || event.key == "Escape") &&this.useGlobalEvent) {
                this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS, event);
                this.$rootScope.$broadcast("close.inline.dialogs", event.target);
            }
        });
    }

    @HostListener("click", ["$event"]) onclick(event: Event): void {
        if (this.useGlobalEvent) {
            this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS, event);
            this.$rootScope.$broadcast("close.inline.dialogs", event.target);
        }
    }

    // @HostListener("window:keydown", ["$event"])
    // handleKeyDown(event: KeyboardEvent) {
    //     if ((event.key == "Esc" || event.key == "Escape") &&this.useGlobalEvent) {
    //         this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS, event);
    //         this.$rootScope.$broadcast("close.inline.dialogs", event.target);
    //     }
    // }
}
