import {Inject, Injectable, PipeTransform} from "@angular/core";
import {ValidatorFn, Validators} from "@angular/forms";
import {FormValidationService} from "MODULES_PATH/form/services/form-validation.service";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {UpperCasePipe} from "@angular/common";
import {FieldDataType} from "ENUMS_PATH/field.enum";
import {EobInputFormControl} from "MODULES_PATH/form/components/inputs/eob-input-form-control.model";
import {TodoEnvironmentService, TodoFormHelper} from "INTERFACES_PATH/any.types";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {Field} from "INTERFACES_PATH/field.interface";
import {MedicChoiceMask} from "../models/field-masks/medic-choice-mask.model";
import {MultiEntryMask} from "../models/field-masks/multi-entry-mask.model";
import {DecimalFieldMask} from "../models/field-masks/decimal-field-mask.model";
import {LeadingZerosMask} from "../models/field-masks/leading-zeros-mask.model";
import {FieldExpander} from "INTERFACES_PATH/validation.interface";
import {RightGroupsAddonService} from "MODULES_PATH/form/services/form-builder/right-groups-addon.service";
import {UserAddonService} from "MODULES_PATH/form/services/form-builder/user-addon.service";
import {OrgMask} from "MODULES_PATH/form/models/field-masks/org-mask.model";
import {RightGroupMask} from "../models/field-masks/right-group-mask.model";
import {UserMask} from "MODULES_PATH/form/models/field-masks/user-mask.model";
import {DateMask} from "MODULES_PATH/form/models/field-masks/date-mask.model";
import {FieldMask} from "MODULES_PATH/form/models/field-mask.model";
import {FormAddonService} from "MODULES_PATH/form/services/form-addon.service";
import {customDecimalLength} from "MODULES_PATH/form/validators/custom-validators";

@Injectable({providedIn: "root"})
export class FormControlService {

    // eslint-disable-next-line max-params
    constructor(@Inject("environmentService") private environmentService: TodoEnvironmentService,
                private valueUtilsService: ValueUtilsService, private rightGroupsAddonService: RightGroupsAddonService,
                private userAddonService: UserAddonService, private formAddonService: FormAddonService) {
    }

    getInputControlByField(field: Field, layoutField: Field, formHelper: TodoFormHelper): EobInputFormControl {
        return new EobInputFormControl(field.value, {
            label: field.model.name,
            placeholder: field.model.placeholder,
            pipes: this.getPipesByField(field),
            masks: this.getMasksByField(field, formHelper),
            validators: this.getValidatorsByField(field, layoutField),
            addonConfig: this.formAddonService.getInputAddonConfig(field, formHelper)
        });
    }

    getValidatorsByField(field: Field, layoutField: Field): ValidatorFn[] {
        const controlValidators: ValidatorFn[] = [];
        // Don't attach validators to inputs with addons for now
        if (field.validationMode == "max" && !layoutField.model?.addon && !layoutField.addon) {
            const fieldValidator: ValidatorFn = FormValidationService.fieldValidator(field.model.type as FormFieldType);
            if (fieldValidator) {
                controlValidators.push(fieldValidator);
            }
            if (field.model.isRequired) {
                // eslint-disable-next-line @typescript-eslint/unbound-method
                controlValidators.push(Validators.required);
            }
            if (field.model.maxLength) {
                if(field.model.type == FieldDataType.DECIMAL) {
                    // Input has to be cleared of anything but numbers and the decimal delimiter
                    controlValidators.push(customDecimalLength(field.model.maxLength, this.valueUtilsService.parseDecimal.bind(this.valueUtilsService)));
                } else {
                    controlValidators.push(Validators.maxLength(field.model.maxLength));
                }
            }
        }
        return controlValidators;
    }

    getPipesByField(field: Field): PipeTransform[] {
        const controlPipes: PipeTransform[] = [];
        if (field.model.type == FormFieldType.CAPITAL) {
            controlPipes.push(new UpperCasePipe());
        }
        return controlPipes;
    }

    getMasksByField(field: Field, formHelper: TodoFormHelper): FieldMask[] {
        const masks: FieldMask[] = [];
        if (field.model.type == "choice") {
            masks.push(new MedicChoiceMask(field.model.subType, this.environmentService.featureSet));
        } else if (field.model.type === FieldDataType.DECIMAL && !field.model.addon) {
            masks.push(new DecimalFieldMask(this.environmentService, this.valueUtilsService));
        } else if (!formHelper.isSearch && [FormFieldType.DATE, FormFieldType.DATETIME, FormFieldType.TIME].includes(field.model.type as FormFieldType)) {
            masks.push(new DateMask(field.model.type as FormFieldType, this.environmentService.env.dateFormat));
        }

        if (field.model.addon == "organisation") {
            masks.push(new OrgMask(field as FieldExpander));
        } else if (field.model.addon?.includes("rightGroups")) { // for "rightGroups" and "rightGroupsOld"
            masks.push(new RightGroupMask(field as FieldExpander, this.rightGroupsAddonService));
        } else if (field.model.addon == "user") { // for "rightGroups" and "rightGroupsOld"
            masks.push(new UserMask(field as FieldExpander, this.userAddonService));
        }

        if (field.model.tree?.config?.useMultiSelect) {
            masks.push(new MultiEntryMask([";", "+"]));
        }

        if (field.model.hasLeadingZeros) {
            masks.push(new LeadingZerosMask(this.valueUtilsService, field.model.maxLength));
        }

        return masks;
    }
}
