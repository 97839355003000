import {ProfileCacheKey} from "ENUMS_PATH/database/profile-cache-key.enum";
(function() {
    angular.module("eob.core").factory("dvEnvironmentService", DvEnvironmentService);

    DvEnvironmentService.$inject = ["backendService", "fileCacheService"];

    /**
     * The environment service for the detailsviewer.
     */
    // eslint-disable-next-line max-params, require-jsdoc
    function DvEnvironmentService(BackendService, FileCacheService) {
        let webStorageAvailable = !!window.sessionStorage;
        let scriptStorage = {};

        let service = {
            initAsync,
            storeContainerVisibility,
            retrieveContainerVisibility,
            storeTableWidthSettings,
            retrieveTableWidthSettings,
            getSessionSetting,
            env: {}
        };
        return service;

        /**
         * Initializes the detailsviewer environment.
         *
         * @returns {Promise<void>} Resolved once the detailsviewer is initialized.
         */
        async function initAsync() {
            await fetchConfigurationAsync();
        }

        function storeContainerVisibility(containerName, isVisible) {
            storeSessionSetting(containerName, { isVisible });
        }

        function retrieveContainerVisibility(containerName) {
            let containerSetting = getSessionSetting(containerName);
            return containerSetting == void 0 ? false : containerSetting.isVisible;

        }

        function storeTableWidthSettings(objectTypeId, tableName, settings) {
            let objectTypeConfig = getSessionSetting(objectTypeId);

            if (objectTypeConfig == void 0) {
                objectTypeConfig = {};
            }

            objectTypeConfig[tableName] = settings;

            storeSessionSetting(objectTypeId, objectTypeConfig);
        }

        function retrieveTableWidthSettings(objectTypeId, tableName) {
            let objectTypeSettings = getSessionSetting(objectTypeId);
            let tableSettings;

            if (objectTypeSettings != void 0) {
                tableSettings = objectTypeSettings[tableName];
            }

            return tableSettings || { columns: {} };
        }

        function storeSessionSetting(key, value) {
            let stringValue = JSON.stringify(value);

            if (webStorageAvailable) {
                window.sessionStorage.setItem(`dv_${key}`, stringValue);
            } else {
                scriptStorage[key] = stringValue;
            }
        }

        function getSessionSetting(key) {
            let val;
            if (webStorageAvailable) {
                val = window.sessionStorage.getItem(`dv_${key}`);
            } else {
                val = scriptStorage[key];
            }

            return val == void 0 ? null : JSON.parse(val);
        }

        /**
         * Request the detailsviewer configuration from the appconnector and set the neccessary environment properties.
         * The configuration file is at [...appconnector]/configuration/client-config/detailsviewer.properties.
         *
         * @returns {Promise<void>} Resolved once the configuration is set.
         */
        async function fetchConfigurationAsync() {
            let response = await BackendService.cachedGetAsync("/serviceinfo/clientconfiguration/detailsviewer", ProfileCacheKey.DV_CONFIGURATION);
            service.env = response.data;
        }

    }
})();