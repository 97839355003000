import {Component, EventEmitter, Output, ViewEncapsulation} from "@angular/core";
import {ProfileManagerService} from "MODULES_PATH/profile-manager/profile-manager.service";

@Component({
    selector: "eob-welcome-page",
    templateUrl: "./eob-welcome-page.component.html",
    styleUrls: ["./eob-welcome-page.component.scss"],
    encapsulation: ViewEncapsulation.Emulated
})
export class EobWelcomePageComponent {
    @Output() triggerAbortLogin = new EventEmitter();
    @Output() triggerLoginToDemo = new EventEmitter();

    constructor(public profileManagerService: ProfileManagerService) { }

    /**
     * triggers abortLogin method from profile-manager.component
     */
    abortLogin(): void {
        this.triggerAbortLogin.emit();
    }

    /**
     * triggers loginToDemo method from profile-manager.component
     */
    loginToDemo(): void {
        this.triggerLoginToDemo.emit();
    }

}
