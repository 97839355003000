import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {NavigationState} from "MODULES_PATH/navigation/interfaces/navigation.interface";
import {StateEnum} from "ENUMS_PATH/state.enum";
import {selectAvailableStates} from "MODULES_PATH/navigation/+state/navigation.selectors";
import {Store} from "@ngrx/store";
import {AfterViewInit} from "@angular/core";
import {NavigationEvents} from "MODULES_PATH/navigation/enums/navigation-events.enum";

@Component({
    selector: "eob-nav-kebab",
    templateUrl: "./eob-nav-kebab.component.html",
    styleUrls: ["./eob-nav-kebab.component.scss"]
})
export class EobNavKebabComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("navKebabElements", {static: false}) navKebabElements: ElementRef<HTMLElement>;

    avatarUrl: string;
    nav$: Observable<NavigationState>;
    initialNavState$: Observable<NavigationState>;
    lastFocusedElement: HTMLElement;

    private subs: Subscription = new Subscription();

    constructor(private messageService: MessageService,
                private navigationService: NavigationService,
                private navStore: Store<NavigationState>,
                @Inject("fileCacheService") private fileCacheService: FileCacheService) {
        this.nav$ = this.navStore.select(selectAvailableStates);
        this.initialNavState$ = this.navigationService.initialNavState$;
    }

    async ngOnInit(): Promise<void> {
        const avatarString: string = await this.fileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, "avatar", {first: true});
        this.avatarUrl = avatarString;

    }

    ngAfterViewInit(): void {
        this.lastFocusedElement = null;
        this.messageService.subscribe(NavigationEvents.FOCUS_NAV_BAR_BODY, () => this.setFocusToFirstElement());
    }

    @HostListener("keydown", ["$event"])
    handleKeyDown(event: KeyboardEvent): void {
        if (event.code == "Escape" && !this.navigationService.isFixed) {
            this.messageService.broadcast(NavigationEvents.FOCUS_NAV_BAR);
            this.navigationService.closeNavigation(0);
            return;
        }
        if (event.code != "ArrowUp" && event.code != "ArrowDown") {
            return;
        }

        if (this.lastFocusedElement == null) {
            this.setFocusToFirstElement();
            return;
        }

        let nextElement: HTMLElement = (event.code == "ArrowDown" ? this.lastFocusedElement.parentElement.nextElementSibling : this.lastFocusedElement.parentElement.previousElementSibling) as HTMLElement;
        if (nextElement) {
            nextElement = nextElement.firstElementChild as HTMLElement;
        } else if (event.code == "ArrowUp") {
            nextElement = this.navKebabElements.nativeElement.lastElementChild.firstElementChild as HTMLElement;
        } else {
            nextElement = this.navKebabElements.nativeElement.firstElementChild.firstElementChild as HTMLElement;
        }

        if (nextElement) {
            this.lastFocusedElement.setAttribute("tabindex", "-1");
            this.lastFocusedElement = nextElement;
            nextElement.setAttribute("tabindex", "0");
            nextElement.focus();
        }
    }

    @HostListener("focusout", ["$event"])
    handleFocusout(event: FocusEvent): void { // When losing focus, check if there is one item with tabindex 0 left, otherwise set the first items tabindex to 0
        if (this.navKebabElements.nativeElement.contains(document.activeElement)) {
            return;
        }

        const kebabItems: HTMLButtonElement[] = Array.from(this.navKebabElements.nativeElement.querySelectorAll("button"));
        const oneItemIsFocusable: boolean = kebabItems.some((button) => button.getAttribute("tabindex") == "0");
        if (oneItemIsFocusable || kebabItems.length == 0) {
            return;
        }

        kebabItems[0].setAttribute("tabindex", "0");
        console.warn("Kebabfocus restored!");
    }


    showUserMenu(event: Event): void {
        this.navigationService.showUserMenu(event);
        (this.lastFocusedElement?.firstElementChild as HTMLElement)?.blur();
    }

    goToSecondaryState(event: Event, state: StateEnum): void {
        this.navigationService.goToSecondaryState(event, state);
    }

    switchNavContent(event: Event, clickedTab: string): void {
        this.navigationService.switchNavContent(clickedTab, false, event);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    setFocusToFirstElement(): void {
        const firstItem: HTMLElement = this.navKebabElements?.nativeElement?.firstElementChild?.firstElementChild as HTMLElement;
        if (!firstItem) { // In this case the view doesn't exist yet
            return;
        }
        this.lastFocusedElement?.setAttribute("tabindex", "-1");
        this.lastFocusedElement = firstItem;
        firstItem.setAttribute("tabindex", "0");

        setTimeout(() => {
            // wait 50ms before calling focus, because when element is new in DOM browser might miss the event
            firstItem.focus();
        }, 50);
    }
}
