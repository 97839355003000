import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {take} from "rxjs/operators";

require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

angular.module("eob.framework").directive("eobModalHitlistConfig", EobModalHitlistConfig);

EobModalHitlistConfig.$inject = ["$filter", "objectTypeService", "asIniService", "notificationsService", "$rootScope", "fieldsetBuilderService", "cacheManagerService", "messageService", "environmentService"];

export default function EobModalHitlistConfig($filter, ObjectTypeService, AsIniService, NotificationsService, $rootScope, FieldsetBuilderService, CacheManagerService, MessageService, EnvironmentService) {
    return {
        replace: true,
        scope: {
            objectTypeId: "=objecttypeid"
        },
        link(scope, element) {

            scope.ready = false;
            scope.isObjectTypes = true;
            scope.availableItems = [];
            scope.gridConfig;
            scope.multiselectOptions = {
                keepSequence: true
            };
            scope.dropDownConfig = {
                items: [],
                useFullHeight: false
            };

            let changedConfigs = {};
            let skipFields = ["static", "pagecontrol", "grid", "group", "button"];
            let allBaseParams;
            let objTypeDef;
            let cabTypeDef;
            let objectTypeModel = CacheManagerService.objectTypes.getById(scope.objectTypeId).model;
            let activeDropdownItem = scope.objectTypeId;
            let allItems = {};
            let cabinetIconTitle = $filter("translate")("modal.export.icon.folder");
            let documentIconTitle = $filter("translate")("modal.export.icon.baseparam");
            let cabinetFieldIcon = `<div class="container-basesparam-icon" title="${cabinetIconTitle}"><i class="icon-16-OT-Archive-dark"></i></div>`;
            let baseparamFieldIcon = `<div class="container-basesparam-icon" title="${documentIconTitle}"><i class="icon-16-baseparams"></i></div>`;
            let objectTypeId = objectTypeModel.config.objectTypeId;
            scope.cabinetId = objectTypeModel.config.cabinetId;
            const navigation = ObjectTypeService.getNavigation()
            const objectTypes = (navigation == void 0) ? null : navigation.find(x => x.cabinetId == scope.cabinetId).objectTypes;
            scope.dropDownConfig.items = buildObjectTypeDropDownList(objectTypes);

            scope.altLang = !EnvironmentService.uiLangIsObjectDefLang() ? EnvironmentService.getObjectDefinitionLanguage() : null;

            objTypeDef = CacheManagerService.objectTypes.getById(objectTypeId);
            addFieldsToPool(objTypeDef);
            setMultiListItems();

            if (objectTypeModel.mainType != 0) {
                cabTypeDef = CacheManagerService.objectTypes.getById(scope.cabinetId);
                allItems[scope.cabinetId] = addFieldsToPool(cabTypeDef);
            }

            function buildObjectTypeDropDownList(objectTypes) {
                let list = [];
                for (let i in objectTypes) {
                    let item = {
                        name: objectTypes[i].name,
                        icon: objectTypes[i].icon.replace(/-white$/gi, ""),
                        typeId: objectTypes[i].objectTypeId
                    };

                    if (item.icon === "OT-Archive") {
                        item.icon = "OT-Archive-dark"
                    }
                    if (item.icon === "OT-Register") {
                        item.icon = "OT-Register-dark"
                    }

                    if (item.typeId == objectTypeId) {
                        item.isActive = true;
                    }
                    list.push(item);
                }
                return list;
            }

            function buildGeneralFieldsDropDownList() {
                let options = [
                    {
                        option: "folder",
                        name: $filter("translate")("modal.hitlist.general.dropdown.folder.baseparams"),
                        icon: "OT-Archive-dark"
                    },
                    {
                        option: "register",
                        name: $filter("translate")("modal.hitlist.general.dropdown.register.baseparams"),
                        icon: "OT-Register-dark"
                    },
                    {
                        option: "document",
                        name: $filter("translate")("modal.hitlist.general.dropdown.document.baseparams"),
                        isActive: true,
                        icon: "document"
                    }
                ];
                return options;
            }

            scope.toggleConfigContainer = function () {
                addSelectedItems();
                scope.isObjectTypes = !scope.isObjectTypes;
                if (scope.isObjectTypes) {
                    scope.dropDownConfig.items = buildObjectTypeDropDownList(objectTypes);
                    activeDropdownItem = objTypeDef.model.config.objectTypeId;
                    addFieldsToPool(objTypeDef);
                    setMultiListItems();
                } else {
                    scope.dropDownConfig.items = buildGeneralFieldsDropDownList();
                    activeDropdownItem = "document";
                    allBaseParams = FieldsetBuilderService.getAllBaseparamsForHitlistConfig(activeDropdownItem);
                    addFieldsToPool(cabTypeDef);
                    addBaseparamsToPool();
                    setMultiListItems();
                }
            };

            scope.dropDownConfig.callback = function (selection) {
                addSelectedItems();
                scope.ready = false;
                if (scope.isObjectTypes) {
                    objectTypeId = selection.typeId;
                    activeDropdownItem = objectTypeId;
                    objTypeDef = CacheManagerService.objectTypes.getById(objectTypeId);
                    addFieldsToPool(objTypeDef);
                    setMultiListItems();
                } else {
                    activeDropdownItem = selection.option;
                    allBaseParams = FieldsetBuilderService.getAllBaseparamsForHitlistConfig(activeDropdownItem);
                    addBaseparamsToPool();
                    setMultiListItems();
                }
            };

            function addFieldsToPool(typeDef) {
                let allFields = [];
                const flatFieldList = typeDef.api.getFlatFieldList();
                flatFieldList.forEach((field) => {
                    if(field.type == "radio" && !field.isMasterRadio) {
                        // Omit non-master radio buttons
                        return;
                    }
                    if (isValidField(field) && !field.isInvisibleField) {
                        let fieldData = {
                            internal: field.internal,
                            name: field.title,
                            dbname: field.dbname,
                            selected: false,
                            icon: cabinetFieldIcon,
                        };
                        if (typeDef == scope.cabinetId || activeDropdownItem == scope.cabinetId) {
                            fieldData.icon = cabinetFieldIcon
                        }

                        if (field.type == "radio" && field.groupInternal != void 0) {
                            try {
                                fieldData.name = flatFieldList.find(x => x.internal == field.groupInternal).title;
                            } catch(error) {
                                // Failsafe in case we don't find the referenced group. One never knows...
                                console.warn(`Unable to find group ${field.groupInternal} for field ${field.internal}`);
                            }
                        }
                        allFields.push(fieldData);
                        allItems[activeDropdownItem] = allFields;
                    }
                });
                return allFields;
            }

            function addBaseparamsToPool() {
                let allFields = [];
                allBaseParams.forEach((field) => {
                    let fieldData = {
                        internal: field.internal,
                        name: field.title,
                        selected: false,
                        icon: baseparamFieldIcon
                    };
                    fieldData.dbname = field.baseParamName == void 0 ? field.dbname : field.baseParamName;
                    allFields.push(fieldData);
                });
                allItems[activeDropdownItem] = allFields;

                if (activeDropdownItem == "register" || activeDropdownItem == "document") {
                    allItems[activeDropdownItem] = allItems[activeDropdownItem].concat(allItems[scope.cabinetId]);
                }
            }

            function setMultiListItems() {
                const selectedItemNames = getConfiguredFields();
                let allFields = allItems[activeDropdownItem] || [];

                const availableItems = [];
                for (const field of allFields) {
                    const index = selectedItemNames.indexOf(field.dbname);
                    field.selected = index >= 0;
                    field.position = index;
                    availableItems.push(field);
                }
                scope.availableItems = availableItems;

                setListConfig();
            }

            function getConfiguredFields(opt) {
                let option = opt != void 0 ? opt : activeDropdownItem;
                if (changedConfigs != void 0 && changedConfigs[option] != void 0) { // user changed something and navigated away and back
                    return changedConfigs[option].map(field => field.dbname);
                }

                let selectedItems = scope.isObjectTypes ? objTypeDef.api.getConfiguredFields() : objTypeDef.api.getConfiguredBaseParams(cabTypeDef, option);
                if (selectedItems == undefined) {
                    return [];
                }

                return Object.values(selectedItems).sort((a, b) => a.position - b.position).map(field => field.dbname || field.baseParamName);
            }

            function addSelectedItems() {
                changedConfigs[activeDropdownItem] = scope.availableItems.filter(entry => entry.selected);
            }

            function setListConfig() {
                const getDefaultGridConfig = () => ({
                    columnDefs: [{
                        headerName: "",
                        field: "icon",
                        width: 20,
                        sortable: false,
                        cellRenderer(params) {
                            return params.value;
                        },
                    }, {
                        headerName: $filter("translate")("modal.hitlist.config.available.fields"),
                        field: "name",
                    }]
                });

                const rightConfig = getDefaultGridConfig();
                Object.assign(rightConfig.columnDefs[1], {
                    headerName: $filter("translate")("modal.hitlist.config.selected.fields")
                });

                const configs = {
                    leftConfig: getDefaultGridConfig(),
                    rightConfig
                };

                if (scope.isObjectTypes) {
                    configs.leftConfig.columnDefs.shift();
                    configs.rightConfig.columnDefs.shift();
                }

                scope.gridConfig = configs;
                scope.ready = true;
            }

            function isValidField(field) {
                return skipFields.indexOf(field.type) == -1;
            }

            scope.applyData = function () {
                addSelectedItems();

                let newConfig = {};

                for (var i in changedConfigs) {
                    newConfig[i] = [];
                    for (let j in changedConfigs[i]) {
                        newConfig[i].push(changedConfigs[i][j].dbname);
                    }
                }
                for (var i in newConfig) {
                    if (newConfig[i].length === 0 && !/document|register|folder/.test(i)) {
                        NotificationsService.info($filter("translate")("modal.hitlist.notification.reset.to.defaults.for.object.type.x").replace("[%s1]", CacheManagerService.objectTypes.getById(i).model.config.title), $filter("translate")("eob.notification.hint.title"));
                    }
                }
                AsIniService.updateHitlistFieldsConfiguration(newConfig, scope.cabinetId).pipe(take(1)).subscribe(saveSub => {
                    saveSub.add(() => {
                        for (let i in changedConfigs) {
                            if (!isNaN(i)) {
                                CacheManagerService.objectTypes.getById(i).api.resetConfiguredFields();
                            }
                        }

                        $rootScope.$broadcast("hitlist.config.changed", scope.cabinetId);
                        scope.close();
                    })
                });
            };

            scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY, scope.listConfig);
        },
        template: require("!raw-loader!./eob.modal.hitlist.config.html")
    };
}
