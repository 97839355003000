/**
 * Enum defining static cache keys. They are all server specific and
 * are stored with the Gateway URL as combined db key.
 */
export enum GlobalCacheKey {
    /**
     * Specifies all the global DMS scripts
     */
    DMS_GLOBAL_SCRIPT_CACHE = "dmsGlobalScriptCache",

    /**
     * Specifies all the global DMS scripts
     */
    DMS_AFTER_LOGIN_SCRIPT_CACHE = "dmsAfterLoginScriptCache",

    /**
     * Object definition
     */
    OBJ_DEF_CACHE = "objectDefinition",

    /**
     * Objecttypes the technical user can see for merged rights.
     */
    OBJ_DEF_CABINETS = "objdefCabinets",

    /**
     * A List of all groups
     */
    GROUP_LIST = "GroupList",

    /**
     * A List of all users
     */
    USER_LIST = "UserList",

    /**
     * Webclient properties
     */
    OS_WEB_PROPERTIES = "OsWebProperties",

    /**
     * AppConnector ServiceInfo
     */
    SERVICE_INFO = "ServiceInfo",

    /**
     * AppConnector FeatureSet
     */
    FEATURE_SET = "FeatureSet",

    /**
     * EMS configuration
     */
    EMS_CONFIGURATION = "EMSConfiguration",

    /**
     * EMS mapped types
     */
    EMS_TYPES = "EMSTypes",

    /**
     * AppConnector Mimetypes
     */
    MIMETYPES = "Mimetypes",

    /**
     * Workflow Organisations
     */
    WORKFLOW_ORGANISATION = "WfOrganisation",

    /**
     * Product Version
     */
    PRODUCT_VERSION = "ProductVersion",

    /**
     *
     */
    OBJ_DEF_ICONS = "ObjectTypeIcons",

    /**
     * This random session id is used to speed up the loading process of newly opened tabs or during a refresh.
     * On electron, this id is inherited from the first tab successfully establishing a session. Its main
     * purpose is to determine, whether the persisted requests are from the current session and can be
     * considered up to date.
     */
    RANDOM_SESSION_ID = "RandomSessionId",
}