import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Type} from "@angular/core";
import {EobDetailsViewerWrapperComponent} from "MODULES_PATH/details-viewer/eob-details-viewer.component";
import {SharedModule} from "SHARED_PATH/shared.module";

const components: Array<Type<unknown>> = [
    EobDetailsViewerWrapperComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule, SharedModule
    ],
    exports: [...components],
    entryComponents: [...components]
})
export class DetailsViewerModule {
}
