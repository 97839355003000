<div class="hit-list-wrapper">
    <eob-grid-wrapper [gridoptions] = "gridOptions" [attr.lang]="showAltLang ? objectDefLang : null"></eob-grid-wrapper>

    <button *ngIf="showAddButton" type="button" (click)="createNewObject()">
        <eob-icon title="{{'eob.create.state.new.title' | ngTranslate}}" name="footer-add" size="32"></eob-icon>
    </button>

    <div [hidden]="!isEmptyGrid" class="empty-hitlist-placeholder">
        <div class="placeholder-content">
            <eob-icon size="32" name="no-elements-to-show" title="{{emptyPlaceholderString}}"></eob-icon>
            <span>{{emptyPlaceholderString}}</span>
        </div>
    </div>
</div>
