import {Inject, Injectable} from "@angular/core";
import {ContextMenuAction, ContextMenuItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {BackendExternalTool} from "CORE_PATH/backend/interfaces/resource/backend-external-tool.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ClipboardDesktopItem, ClipboardDmsDocument} from "INTERFACES_PATH/custom-storage.interface";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {
    TodoCacheManagerService,
    TodoEnvironmentService,
    TodoEnvObjectInEnvironmentService,
    TodoModalDialogService,
    TodoSearchService,
    TodoStateHistoryManager
} from "INTERFACES_PATH/any.types";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {Platform} from "SHARED_PATH/types/platform.type";

/**
 * A service that provides helper functions for context-menu related services and components.
 */
@Injectable({providedIn: "root"})
export class ContextMenuUtilsService {
    private readonly translateFn: TranslateFnType;

    gContextmenuStyles: Map<string, JQLiteCssProperties> = new Map<string, JQLiteCssProperties>();

    constructor(private notificationsService: NotificationsService,
                private actionService: ActionService,
                private iconService: IconService,
                private clientService: ClientService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("searchService") private searchService: TodoSearchService,
                @Inject("$filter") private $filter: ng.IFilterService,
    ) {
        this.translateFn = this.$filter("translate");
    }

    addDivider(): ContextMenuAction {
        return {
            type: "divider",
            title: "",
            icon: "",
            params: []
        };
    }

    addSubmenu(title: string, iconURL: string, params: ContextMenuAction[]): ContextMenuAction {
        title = this.translateFn(title);

        return {
            title,
            icon: iconURL,
            type: "sub",
            params
        };
    }

    addAction(title: string, iconURL: string, callback: any, ...params: unknown[]): ContextMenuAction {
        title = this.translateFn(title);

        return {
            title,
            icon: iconURL,
            type: "main",
            callback,
            params
        };
    }

    /**
     * @param rights to check for
     * @param typeConfig can be a static source of rights like the object definition
     * @param dmsDocument can be a dynamic source of rights set up at runtime along with resolved clauses
     */
    hasRights(rights: string[], typeConfig: ObjectTypeConfig, dmsDocument: DmsDocument): boolean {
        for (const r of rights) {
            if (typeConfig.rights && typeConfig.rights[r] === false) {
                return false;
            }

            if (Object.keys(dmsDocument.model.rights).length == 0 || (Object.keys(dmsDocument.model.rights).length > 0 && dmsDocument.model.rights[r] === false)) {
                return false;
            }
        }

        return true;
    }

    allowAddAbo(item: DmsDocument, context: string, env: TodoEnvObjectInEnvironmentService): boolean {
        return (!item.model.isTypeless
            || item.model.isinWfTray
            || context == "abo"
            || context == "subscriptionObjects")
            && env.actions.useAbo
            && (item.model.variantData == void 0 || item.model.variantData[0].model.isActive);
    }

    canInsert(typeConfig: ObjectTypeConfig, clipboardDmsDocument: DmsDocument): boolean {
        return typeConfig.insertableTypes.includes(clipboardDmsDocument.model.objectTypeId);
    }

    isInsertableTypeless(mainType: string, multiType: boolean, withoutPages: boolean): boolean {
        const clipboard: ClipboardDmsDocument = this.environmentService.getClipboard();
        return (clipboard.item.model.mainType == mainType && (withoutPages == false || multiType == true));
    }

    resolveObjectTypes(config: ObjectTypeConfig): ObjectTypeConfig[] {
        const objectTypeConfigs: ObjectTypeConfig[] = [];

        for (const type of config.insertableTypes) {
            if (!this.cacheManagerService.objectTypes.contains(type)) {
                continue;
            }

            const typeConfig: ObjectTypeConfig = this.cacheManagerService.objectTypes.getById(type).model.config;

            if (typeConfig == void 0) {
                console.warn("could not find objecttype --> ", type);
                continue;
            }

            objectTypeConfigs.push(typeConfig);
        }

        return objectTypeConfigs;
    }

    isValidClipboardContentForDesktop(contextItem: ContextMenuItem, clipboard: ClipboardDmsDocument | ClipboardDesktopItem): boolean {
        if (clipboard == void 0) {
            return false;
        }

        if (contextItem.id == (clipboard as ClipboardDesktopItem).item.id) {
            return false;
        }

        const clipboardDmsDocumentModel: DmsDocumentModel = (clipboard as ClipboardDmsDocument).item.model;

        if (clipboardDmsDocumentModel?.isinWfTray || clipboardDmsDocumentModel?.isTypeless) {
            return false;
        }

        return true;
    }

    async addExternalTools(contextMenuItems: DmsDocument[], contextMenuActions: ContextMenuAction[]): Promise<void> {
        if (!this.environmentService.env.externalTools?.length) {
            return;
        }

        const currentLang: string = this.environmentService.getLanguage();
        const username: string = this.environmentService.getSessionInfo().username;
        const platform: Platform = this.clientService.getPlatformNameAsString();
        let actionAdded: boolean = false;

        const tools: BackendExternalTool[] = this.environmentService.env.externalTools.filter(x => {
            if (!Array.isArray(x.platforms) || (!Array.isArray(x.objectTypes) && x.objectTypes != "*") || !x.platforms.includes(platform)) {
                return false;
            }

            if (Array.isArray(x.objectTypes)) {
                return contextMenuItems.every(dmsDoc => x.objectTypes.includes(dmsDoc.model.internal));
            }

            return true;
        });

        for (const tool of tools) {
            let title: string;
            let icon: string;

            if (tool.iconId) {
                try {
                    const icons: Map<string, string> = await this.iconService.getIcons();
                    icon = icons.has(tool.iconId) ? `custom-icon-${tool.iconId}` : "";
                } catch (e) {
                    console.error(e);
                }
            } else {
                icon = "open-app";
            }

            if (currentLang === "fr") {
                title = tool.name_FR;
            } else if (currentLang === "en") {
                title = tool.name_EN;
            } else {
                title = tool.name_DE;
            }

            switch (tool.type) {
                case "application":
                    if (this.clientService.isDesktop()) {
                        if (/%[fgpoi]/gi.test(tool.argument)) {
                            actionAdded = true;
                            contextMenuActions.push(this.addAction(title, icon, this.actionService.createAndSaveFilesForProgramArguments, contextMenuItems, tool));
                        } else {
                            actionAdded = true;
                            contextMenuActions.push(this.addAction(title, icon, window.electron.execProgramAsync, tool.path, undefined, undefined, tool.workingDirectory));
                        }
                    }
                    break;
                case "url":
                    contextMenuActions.push(this.addAction(title, icon, () => {
                        let url: string = tool.path;
                        const osidRegex = /\$osid\$/gi;
                        const userRegex = /\$username\$/gi;
                        if (osidRegex.test(tool.path)) {
                            const dmsDocuments: DmsDocument[] = contextMenuItems;
                            url = url.replace(osidRegex, dmsDocuments.map(x => x.model.osid).join(","));
                        }
                        if (userRegex.test(tool.path)) {
                            url = url.replace(userRegex, username).toLowerCase();
                        }
                        window.open(url, "_blank");
                    }));
                    actionAdded = true;

                    break;
            }
        }

        if (actionAdded) {
            contextMenuActions.push(this.addDivider());
        }
    }

    checkVariantRights = async (dmsDocument: DmsDocument): Promise<void> => {
        dmsDocument = await this.searchService.searchById(dmsDocument.model.osid);

        if (dmsDocument.model.rights.indexModify || this.environmentService.userHasRole("R_CLNT_VAR_SHOWALWAYS")) {
            this.stateHistoryManager.goToManageVariants(dmsDocument);
        } else {
            this.notificationsService.info(this.translateFn("eob.action.edit.info.no.variant.manager.notification"), this.translateFn("eob.notification.hint.title"));
        }
    };
}
