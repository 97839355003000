(function() {
    require("SERVICES_PATH/viewer/detailsviewer/dv.environment.srv.js");

    angular.module("eob.core").factory("dvUsernameService", DvUsernameService);

    DvUsernameService.$inject = ["$filter", "dvEnvironmentService"];

    /**
     * A service for preparing display user names.
     */
    // eslint-disable-next-line max-params, require-jsdoc
    function DvUsernameService($filter, DvEnvironmentService) {
        let service = {
            getUsername,
            getHtmlUsernameSnippet
        };
        return service;

        /**
         * Takes the given user information and returns a display name based on the dv configuration.
         * If the user is unknown a default text will be returned.
         *
         * @param {{ name, fullname }} userObject - An user object with name and login data. The name properties may be empty.
         * @returns {string} A display name for the given data.
         */
        function getUsername(userObject) {
            let showLoginName = DvEnvironmentService.env["display.username"] == "login";
            let username;

            if (userObject != void 0) {
                username = userObject.name;

                if (userObject.fullname && !showLoginName) {
                    username = userObject.fullname;
                }
            }

            return username || $filter("translate")("app.nav.lock.unknown");
        }

        /** optional: 'css' aatribute for a custom class' **/
        function getHtmlUsernameSnippet(userObject, css) {
            if(userObject == void 0 || Object.keys(userObject).length === 0){
                return;
            }
            let _css = css ? ` class="${css}"` : "";
            let username = getUsername(userObject);
            let returnSnippet = css ? "" : "<div class=\"user\">";
            returnSnippet = returnSnippet.concat(`<span${_css}>${username}</span>`);
            return css ? returnSnippet : returnSnippet.concat("</div>");
        }
    }
})();
