import {Component, ElementRef, Inject, OnDestroy, OnInit} from "@angular/core";
import {StateParams, StateService} from "@uirouter/core";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {AutoCompleteService} from "MODULES_PATH/autocomplete/services/autocomplete.service";
import {FormCatalogEntry} from "MODULES_PATH/form/interfaces/form.interface";
import {DropDownConfig} from "INTERFACES_PATH/dropdown.interface";
import {AutoCompleteConfig} from "MODULES_PATH/autocomplete/interfaces/autocomplete.interface";
import {Cabinet, ObjectType} from "INTERFACES_PATH/object-type.interface";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {
    TodoBackendService,
    TodoCacheManagerService,
    TodoEnvironmentService,
    TodoStateHistoryManager
} from "INTERFACES_PATH/any.types";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";

@Component({
    selector: "eob-fulltext-search",
    templateUrl: "./eob-fulltext-search.component.html",
    styleUrls: ["./eob-fulltext-search.component.scss"]
})
export class EobFulltextSearchComponent implements OnInit, OnDestroy {
    isPhone: boolean;
    showFulltext: boolean;
    dropDownConfig: DropDownConfig;
    eobAutocomplete: AutoCompleteConfig;
    searchGlobally: any;

    private readonly translateFn: TranslateFnType;

    private initId: string;
    private stateId: string;
    private config: any;
    private search: any;
    private navigation: Cabinet[];
    private activeCab: Cabinet;
    private objectTypesVtx: ObjectType[];
    private tryAutoComplete = true;
    private showAltLang: boolean;
    private objectDefLang: string;

    constructor(@Inject("$filter") private $filter: ng.IFilterService,
                @Inject("$state") private $state: StateService,
                @Inject("$stateParams") private $stateParams: StateParams,
                protected clientService: ClientService,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                protected viewerService: ViewerService,
                @Inject("stateHistoryManager") protected stateHistoryManager: TodoStateHistoryManager,
                @Inject("cacheManagerService") protected cacheManagerService: TodoCacheManagerService,
                @Inject("backendService") protected backendService: TodoBackendService,
                protected layoutManagerService: LayoutManagerService,
                protected objectTypeService: ObjectTypeService,
                protected autoCompleteService: AutoCompleteService,
                private messageService: MessageService,
                private el: ElementRef) {
        this.translateFn = $filter("translate");
    }

    ngOnInit(): void {
        this.isPhone = this.clientService.isPhone();
        this.showFulltext = this.environmentService.isFulltextAvailable();
        this.stateId = this.$stateParams.state;

        this.eobAutocomplete = {
            debounce: 500,
            minCharacters: 3,
            useMultiSelect: false,
            isField: false,
            hasAutoComplete: false,
            isGridCell: false,
            isDisabled: false,
            addon: undefined,
            autoCompleteOpen: false,
            getItems: this.autoCompleteCallback.bind(this),
            isSelectingValue: false
        };

        if (this.clientService.isDesktop()) {
            this.clientService.refreshTabTitle("enaio webclient");
        }

        this.viewerService.clearViewer();

        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang();
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();

        this.config = this.stateHistoryManager.getCurrentConfig();
        this.initId = this.config.cabId != void 0 ? this.config.cabId : null;

        this.searchGlobally = {
            name: this.translateFn("eob.fulltext.search.global.title"),
            cabinetId: null
        };

        this.search = {
            key: this.config.searchKey != void 0 ? this.config.searchKey : ""
        };

        this.dropDownConfig = {
            items: [this.searchGlobally],
            useFullHeight: true,
            callback: this.dropdownCallback.bind(this),
        };

        this.objectTypesVtx = this.cacheManagerService.objectTypes.getAll().filter(o => o.model.config.useFullText).map(o => o.model.osid);
        this.navigation = this.objectTypeService.getNavigation();

        for (const tmp of this.navigation.filter(x => x.vtx)) {
            this.dropDownConfig.items.push({
                name: tmp.name,
                cabinetId: tmp.cabinetId,
                objectTypes: tmp.objectTypes.map(x => x.objectTypeId), // objectTypes are needed for autocomplete
                icon: this.objectTypeService.getIconClass(tmp.cabinetId, null, true)
            });
        }

        for (const item of this.dropDownConfig.items) {
            if (item.cabinetId == this.initId) {
                item.isActive = true;
                this.activeCab = item;
                break;
            }
        }

        if (!this.layoutManagerService.isTouchLayoutActive() && this.showFulltext) {
            setTimeout(() => {
                this.el.nativeElement.querySelector("#fulltext-search-field").focus();
            }, 0);
        }
    }

    dropdownCallback(cab: Cabinet): void {
        this.activeCab = cab;
    }

    autoCompleteCallback(searchKey: string): Promise<FormCatalogEntry[]> {
        return new Promise(((resolve) => {
            const url = "/documents/vtx/autocomplete/";
            const data: any = {
                query: searchKey,
                facets: {
                    objtype: this.objectTypesVtx
                }
            };

            let list: FormCatalogEntry[] = [];

            if (!this.tryAutoComplete) {
                resolve(list);
                return;
            }

            // if a cabinet was selected for fulltext search find object types of cabinet with fulltext allowance
            if (this.activeCab.cabinetId != void 0) {
                data.facets.objtype = this.objectTypesVtx.filter(x => this.activeCab.objectTypes.includes(x));
            }

            this.backendService.post(url, data).then((response) => {
                for (const i in Object.keys(response.data)) {
                    const listEntry: FormCatalogEntry = {
                        value: "",
                        short: ""
                    };

                    listEntry.value = Object.keys(response.data)[i];
                    list.push(listEntry);
                }

                list = this.autoCompleteService.sortResult(list, false);
                resolve(list);
            }).catch( _ => this.tryAutoComplete = false);
        }));
    }

    executeSearch(): void {
        const localSearchKey: string = this.search.key.trim();

        if (localSearchKey.length < 1) {
            return;
        }

        const nextStateId: number = +new Date();

        const fulltextSearchConfig: any = {
            type: "fulltextResult",
            searchKey: localSearchKey,
            cabinetId: this.activeCab.cabinetId,
            description: `${this.translateFn("eob.result.state.fulltext.description")} - ${localSearchKey}`,
            config: {}
        };

        this.stateHistoryManager.setStateData(fulltextSearchConfig, nextStateId);
        this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
        this.$state.go("hitlist.fulltextResult", {state: nextStateId});
    }

    ngOnDestroy(): void {
        this.saveConfiguration();
    }

    private saveConfiguration(): void {
        const newConfig: any = {
            cabId: this.activeCab == void 0 ? null : this.activeCab.cabinetId,
            searchKey: this.search.key
        };

        this.stateHistoryManager.updateConfig(newConfig, this.stateId);
    }
}
