<div #dropdownContainer class="eob-dropdown-container" [ngClass] = "{'open': dropDownOpen,'closed': !dropDownOpen, 'scroll': dropdownScroll}">
    <div #dropdownActiveElement class="dropdown-active-element" (click) = "toggleDropdown()" title="{{selection.name}}">
        <i  class="{{selection.icon}}"></i>
        <span>{{selection.name}}</span>
    </div>
    <ul #dropdown class="eob-dropdown" (click) = "toggleDropdown()" [ngClass] = "{'scroll': dropdownScroll}">
        <li #dropdownItem class="eob-dropdown-item" *ngFor="let item of dropdownconfig.items" (click) = "setItemActive(item)"
             [ngClass] = "{active: item.isActive}" title="{{item.name}}">
            <eob-icon [name]=item.icon [size]="16"></eob-icon>
            <span>{{item.name}}</span>
        </li>
    </ul>
</div>