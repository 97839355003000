import {Inject, Injectable} from "@angular/core";
import {ScriptingModule} from "MODULES_PATH/scripting/scripting.module";
import {TodoEnvironmentService, TodoQuery, TodoQueryConfig, TodoSearchService, TodoQueryData} from "INTERFACES_PATH/any.types";
import {CustomError} from "CORE_PATH/models/custom-error/custom-error.model";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {DmsDocumentService} from "MODULES_PATH/dms/dms-document.service";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";

@Injectable({providedIn: ScriptingModule})
export class ScriptingSearchService {
    constructor(@Inject("searchService") private searchService: TodoSearchService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                private dmsDocumentService: DmsDocumentService) {
    }

    /**
     * Proxy a search to the backend.
     *
     * @param queryData - Backend query or custom query data. Custom query data is deprecated since 8.50 SP4.
     * @param queryConfigData - Backend url query parameters.
     */
    async search(queryData: TodoQuery|TodoQueryData, queryConfigData: TodoQueryConfig = {}): Promise<DmsDocument[]> {
        let query: TodoQuery, queryConfig: TodoQueryConfig;

        const isAppConnectorQuery: boolean = (queryData.query != void 0);
        if (isAppConnectorQuery) {
            query = queryData;
            queryConfig = queryConfigData;

            /** @deprecated since 8.50 SP4 -  search function should only proxy the osrest query and not support a custom webclient search syntax */
        } else {
            const error: CustomError = this.searchService.validateQueryData(queryData);
            if (error != void 0) {
                throw error;
            }

            query = this.searchService.createQueryWithData(queryData);

            queryConfig = queryConfigData;
            queryConfig.fieldsschema = this.searchService.getValidFieldsschema(queryData);
            queryConfig.pagesize = query.query.result_config.maxhits;
        }

        queryConfig.pagesize = queryConfig.pagesize || parseInt(this.environmentService.env.hitlist.maxsize);

        const result: BackendObject[] = await this.searchService.executeQuery(query, queryConfig);
        return result.map(ecmobject => this.dmsDocumentService.createDmsDocumentHierarchy(ecmobject));
    }

    /** Proxy an id search to the backend. */
    searchById(id: number, objectTypeId?: number): DmsDocument {
        return this.searchService.searchById(id, objectTypeId);
    }

    /** Proxy a distinct values search to the backend. */
    searchDistinctValues(data: TodoQueryData): string[] {
        return this.searchService.searchDistinctValues(data);
    }
}