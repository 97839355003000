import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {takeWhile} from "rxjs/operators";

/**
 * Created by bohnhardt on 14.06.2016.
 */
(function () {


    angular.module("eob.framework").directive("eobModalMaintype", EobModalMaintype);

    EobModalMaintype.$inject = ["$filter", "messageService"];

    function EobModalMaintype($filter, MessageService) {
        return {
            restrict: "E",
            scope: {
                resolve: "=",
                reject: "=",
                types: "=",
                destroy$: "=destroy"
            },
            link (scope, element) {
                let allTypes = [{
                    id: "1",
                    name: $filter("translate")("document.type.greyscale"),
                    icon: "OT-Gray-Image"
                }, {
                    id: "2",
                    name: $filter("translate")("document.type.blackwhite"),
                    icon: "OT-SW-Image"
                }, {
                    id: "3",
                    name: $filter("translate")("document.type.color"),
                    icon: "OT-Color-Image"
                }, {
                    id: "4",
                    name: $filter("translate")("document.type.windows"),
                    icon: "OT-W-Doc"
                }, {
                    id: "5",
                    name: $filter("translate")("document.type.multimedia"),
                    icon: "OT-Multi-Media"
                }, {
                    id: "6",
                    name: $filter("translate")("document.type.email"),
                    icon: "OT-Mail"
                }, {
                    id: "7",
                    name: $filter("translate")("document.type.xml"),
                    icon: "OT-XML"
                }];

                scope.mainTypes = [];

                //if no types were handed to the dialog we assume that all of them are allowed.
                if(scope.types){
                    for(let type of scope.types){
                        scope.mainTypes.push(allTypes[type - 1]);
                    }
                }else{
                    scope.mainTypes = allTypes;
                }

                scope.choseType = function (mainType) {
                    scope.resolve(mainType);
                    scope.close();
                };

                scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

                scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => scope.reject());
            },
            template: require("!raw-loader!./eob.modal.maintype.html")
        };
    }
})();
