import {Injectable} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {Layout} from "INTERFACES_PATH/layout.interface";

@Injectable({
    providedIn: "root"
})
export class FormPhoneService {
    buildFlexForm(layout: Layout): Field[] {
        if (layout != void 0) {
            const fieldList: Field[] = layout.fieldList;
            const layoutFields: Field[] = [];

            for (const field of fieldList) {
                if (field.model == void 0) {
                    console.warn("field is missing its model, please find out what's wrong here!!!");
                    continue;
                }

                // DODO-9887
                // Check radio groups for possibly missing radio buttons, that are visually outside the group
                if (field.model?.type == "group") {
                    if (Array.isArray(field.model.fields) && field.model.fields[0]?.isMasterRadio && Array.isArray(field.model.fields[0]?.fields)) {
                        // field.groupLayout.fieldList = field.groupLayout.fieldList.filter(x => x !== void 0);
                        field.model.fields[0].fields.forEach(x => {
                            // radio button contained inside master radio is missing in fieldlist of the group
                            if (!field.groupLayout.fieldList.map(y => y.internal).includes(x)) {
                                field.groupLayout.fieldList.push(fieldList.find(z => z.internal == x));
                            }
                        });
                    }
                }

                field.left = 0;
                field.realLeft = 0;
                field.absLeftInCols = 0;
                field.width = 100;
                field.realWidth = 100;
                field.absWidthInCols = 100;

                layoutFields.push(field);
            }

            layoutFields.sort((a, b) => a.model.tabIndex - b.model.tabIndex);

            return layoutFields;
        }
    }
}
