require("SERVICES_PATH/workflow/eob.routing.list.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");

angular.module("eob.core").factory("workflowModelService", WorkflowModelService);

WorkflowModelService.$inject = ["formFieldModelService", "routingListService", "valueUtilsService", "objectTypeService", "environmentService", "formLayoutService", "fieldsetBuilderService"];

export default function WorkflowModelService(FormFieldModelService, RoutingListService, ValueUtilsService, ObjectTypeService, EnvironmentService, FormLayoutService, FieldsetBuilderService) {
    // this will give numbers and chars, that represent something important, a name
    // instead of a non sense number or whatever
    let dictionary = {
        isSpecialField: "16",
        isRadioOrCheckBox: "32",
        required: "1",
        readonly: "268435456",
        invisibleField: "8388608",
        invisibleTextForRadio: "16777216",
        leadingZero: "64",
        isTextArea: "524288",
        datatype: {
            group: "G",
            text: "X",
            addon: "2097152",
            list: "128",
            tree: "256",
            hierarchy: "512",
            grid: "W",
            static: "",
            pagecontrol: "C",
            checkbox: "0",
            radio: "1"
        },
        unSupported: {
            multiField: "1048576",
            image: "B",
            webControl: "E"
        },
        parameterTypeMap: {
            String: "text",
            DateSafe: "date",
            IntegerSafe: "number",
            FloatSafe: "decimal",
            TimeSafe: "time",
            DateTimeSafe: "datetime",
            List: "grid",
            Record: "record"
        }
    };

    return {
        getWorkflowModel,
        getParameterApi
    };

    function getWorkflowModel(data) {
        return new WorkflowModel(data);
    }

    function WorkflowModel(data) {
        let self = this;
        let model = data.WorkItem;

        // fields contains the original data set as an object per field
        // (recursive structure)
        // let fields = [];
        let responsiveFields = [];
        // fieldList contains a flat list of every field for searching purposes
        // using this we can find each field without the need to search deep inside recursive structures
        // (not recursive structure)
        // let fieldList = [];

        // is the profile password required for forwarding the workflow
        let isPasswordRequired = isPasswordRequiredForForwarding(model.ExtendedAttributes.ExtendedAttribute);

        // a list of the parameters and their configuration
        let parameters = parseParameters(model.Parameters.Parameter);

        let permittedObjectTypes = parsePermittedObjectTypes(model.ObjectTypes);

        // the commonFields and requiredFields are used to determine the fallback fields as mentioned above (configuredFields)
        // we store the needed fields by tab index

        // get the files from the current workflow task
        let files = getFiles(model.File);

        // combine fields which we get as single fields
        // we combine fields into a new one with childfields
        // e.g radiobuttons
        // e.g groups (TODO)
        // let combinedFields = ObjectTypeService.combineFields(angular.copy(model.Masks.Mask.MaskFields.MaskField), true);
        let combinedResponsiveFields = FieldsetBuilderService.combineResponsiveFields(angular.copy(model.Masks.Mask.MaskFields.MaskField), true);

        let routingList = model.RoutingList != void 0 ? RoutingListService.parseRoutingList(model.RoutingList) : null;

        let layout;

        self.getParameters = function() {
            return parameters;
        };

        self.getPermittedObjectTypes = function() {
            return permittedObjectTypes;
        }

        self.getFiles = function() {
            return files;
        };

        self.getRoutingList = function() {
            return routingList;
        };

        self.isPasswordRequired = function() {
            return isPasswordRequired;
        };

        self.isProcessResponsible = function() {
            return model.ProcessResponsible == "1";
        };

        self.getLayout = function() {
            return layout;
        };

        // returns the original field set to render search masks etc..
        self.getFields = function() {
            return responsiveFields;
        };

        self.getField = function(internal) {
            for (let i in responsiveFields) {
                if (responsiveFields[i].internal == internal) {
                    return responsiveFields[i];
                }
            }
        };

        self.getFlatFieldList = function() {
            return responsiveFields;
        };

        self.getFieldByName = function(name) {
            for (let i in responsiveFields) {
                if (responsiveFields[i].name == name) {
                    return responsiveFields[i];
                }
            }
        };

        setConfig(model);

        let parameterFieldIdMap = getParameterFieldMap(parameters);

        // fields = buildFieldSet(combinedFields, fieldList, parameterFieldIdMap);

        parseCrosscheckValues(combinedResponsiveFields);

        buildResponsiveFieldSet(combinedResponsiveFields, responsiveFields, parameterFieldIdMap);

        let frame = {
            bottom: model.Masks.Mask.FrameHeight,
            right: model.Masks.Mask.FrameWidth
        };

        layout = FormLayoutService.buildLayout(frame, combinedResponsiveFields, null, responsiveFields);

        // set the config properties to give a simple overview about type specific attributes
        function setConfig(model) {
            self.config = {};
            self.config.name = model.WorkItemName;
            self.config.activityId = model.ActivityId;
            self.config.workflowId = model.WorkflowId;
            self.config.processId = model.ProcessId;
            self.config.workflowType = model.WorkflowType;
            self.config.version = model.Version;
            self.config.processName = model.ProcessName;
            self.config.objectId = model.ObjectId;
            self.config.showInfoArea = model.File.Mode != "2";
        }
    }

    function getParameterFieldMap(parameters) {
        let parameterFieldIdMap = {};
        for (let i in parameters) {
            let param = parameters[i];
            parameterFieldIdMap[param.model.fieldId] = param;
        }
        return parameterFieldIdMap;
    }

    function getFiles(file) {
        let files = {};

        let wfDocs = file.Docs.Doc;

        if (!wfDocs) {
            return files;
        }

        if (!Array.isArray(wfDocs)) {
            wfDocs = [wfDocs];
        }

        for (let i in wfDocs) {
            let doc = wfDocs[i];

            let item = {
                deletable: doc.Deleteable == "1", // if the item can be removed from the wf
                display: doc.Display == "1", // if this item shall be the one shown in the viewer
                location: doc.Location, // "1" == real location "2" means only tray as location
                movable: doc.Moveable == "1", // if the item can be moved between the two areas
                originalId: doc.OriginalId, // in case the doc has variants this will be the id of the original item the wf was started with
                activeVariantId: doc.Id,
                rights: doc.Rights, // not needed --> this is here because we could need this later
                sig: doc.Sig, // not needed --> this is here because we could need this later
                objectTypeId: doc.Type, // objecttype id
                useActiveVariant: doc.UseActiveVariant == "1", // if the active variant shall be inside the files
                workspace: doc.Workspace == "1", // if the item is shown inside the work area or info area
                isNew: false
            };

            item.id = item.useActiveVariant ? doc.Id : doc.OriginalId;

            files[item.id] = item;
        }

        return files;
    }

    function buildResponsiveFieldSet(rawFields, responsiveFields, parameters, pageIndex, parentPage, pageControl) {
        // @ pageIndex
        // we need the pageindex to determine the right amount of radiobuttons per group
        // this is because in the taborder can be 6 radiobuttons in a row, but on different pages
        // we have to know which radiobuttons have to be grouped together for special purposes...
        // the temporary list of fields from one pagecontrol or a objecttype
        let fieldCollection = [];
        // we need this to ensure we can iterate those fields
        // the object definition returns an object literal instead an array, if
        // the object definition contains only 1 field (e.g. a pagecontrol)
        if (!Array.isArray(rawFields)) {
            rawFields = [rawFields];
        }

        for (let index in rawFields) {
            if (isSupported(rawFields[index])) {
                //var field = new FieldModel(rawFields[index], fieldList, pageIndex, parameters);
                let conf = {
                    field: rawFields[index],
                    pageIndex,
                    parentPage,
                    pageControl,
                    parameter: parameters[rawFields[index].Id],
                    isWorkflow: true,
                    objectTypeConfig: null
                };

                let field = FormFieldModelService.getFieldModel(conf);

                if (field.type == "pagecontrol") {
                    for (let i in field.pages) {
                        field.pages[i].index = i;
                        field.pages[i].fields = buildResponsiveFieldSet(field.pages[i].fields, responsiveFields, parameters, i, field.internal, field);
                    }
                }

                if (field.type == "group") {
                    if (field.fields == void 0) {
                        field.fields = [];
                    }

                    // the property parentPage currently has a misleading name, since it may stand for the internal name of the parent pageCtrl or group
                    field.fields = buildResponsiveFieldSet(field.fields, responsiveFields, parameters, pageIndex, field.internal, pageControl);
                }

                fieldCollection.push(field);
                responsiveFields.push(field);
            }
        }

        return fieldCollection;
    }

    /**
     * Parse the crosscheck value(position of the target field) and the tabIndex of quickfinder field
     * @param {object} responsiveFields - workflow mask fields
     */
    function parseCrosscheckValues(responsiveFields) {
        // parse recursively if pagecontrol or group
        for (let field of responsiveFields) {
            if (field.DataType === "C") {
                let pages = Array.isArray(field.Page) ? field.Page : [field.Page];
                for (let page of pages) {
                    parseCrosscheckValues(page.MaskFields.MaskField);
                }
            }
        }

        let crosscheckFields = getCrosscheckFields(responsiveFields);
        if (crosscheckFields.length) {
            let crosscheckValues = [];

            for (let field of crosscheckFields) {
                let relatedControl = ValueUtilsService.getCrosscheckRelatedControl(field.Flags1);
                field.RelatedControl = relatedControl;

                if (!crosscheckValues[relatedControl]) {
                    crosscheckValues[relatedControl] = [];
                }

                if (crosscheckValues[relatedControl].indexOf(parseInt(field.Flags1)) === -1) {
                    crosscheckValues[relatedControl].push(parseInt(field.Flags1));
                }
            }

            crosscheckValues.forEach((relatedControl) => {
                relatedControl.sort((valueA, valueB) => (valueA > valueB ? 1 : -1));
            });

            for (let field of crosscheckFields) {
                field.Crosscheck = crosscheckValues[field.RelatedControl].indexOf(parseInt(field.Flags1)) + 1;
            }
        }
    }

    function getCrosscheckFields(fields) {
        const groupedFields = [];
        loadGroupFields(fields, groupedFields);
        const requiredFields = fields.concat(groupedFields);

        const excludedFields1Values = ["0", "131072", "4194304", "65536", "8388608"]; // datetime, pre-filled or invisible field flags
        return requiredFields.filter(field => excludedFields1Values.indexOf(field.Flags1) === -1);
    }

    /**
     * Load all fields inside a group box tree recursively into a flat list.
     * @param fields
     * @param groupedFields
     */
    function loadGroupFields(fields, groupedFields) {
        for (let field of fields) {
            if (field.DataType === "G" && field.fields) {
                field.fields.forEach(x => groupedFields.push(x));
                loadGroupFields(field.fields, groupedFields);
            }
        }
    }

    function isSupported(rawField) {

        let invalidFieldTypes = [dictionary.unSupported.webControl, dictionary.unSupported.image];

        if (invalidFieldTypes.indexOf(rawField.DataType) != -1) {
            return false;
        }

        if (rawField.flags & dictionary.unSupported.multiField) {
            return false;
        }

        return true;
    }

    function isPasswordRequiredForForwarding(extendedAttributes) {
        for (let i in extendedAttributes) {
            if ((extendedAttributes[i].Name == "CHECK_PASSWORD") && (extendedAttributes[i].Value == "1")) {
                return true;
            }
        }

        return false;
    }

    // endregion

    // region parse parameters
    /**
     * Parse the backend data to a wf parameter object.
     * @param {object} paramMods - The backend parameter data.
     * @returns {object} - A wf parameter object.
     */
    function parseParameters(paramMods) {
        let parameters = {};

        if (typeof (paramMods) == "undefined" || Object.keys(paramMods).length == 0) {
            return parameters;
        }

        if (!Array.isArray(paramMods)) {
            paramMods = [paramMods];
        }

        for (let i in paramMods) {
            let paramMod = paramMods[i],
                paramType,
                paramValue;

            let xmlType = getParameterXMLType(paramMod.WFVar);
            paramType = dictionary.parameterTypeMap[xmlType];

            if (paramType == "grid") {
                paramValue = parseGridParameterValue(paramMod.WFVar.List, paramMod.WFVar.Types);
            } else if (paramType == "record") {
                paramValue = parseRecordParameterValue(paramMod.WFVar);
            } else {
                paramValue = paramMod.WFVar[xmlType];
            }

            if (typeof (paramValue) == "object" && Object.keys(paramValue).length == 0) {
                paramValue = "";
            }

            let parameter = {
                model: {
                    type: paramType,
                    name: paramMod.Name,
                    id: paramMod.DataField,
                    mode: paramMod.Mode,
                    fieldId: paramMod.FormField
                },
                value: paramValue,
                initialValue: angular.copy(paramValue)
            };

            parameters[parameter.model.id] = parameter;
        }

        return parameters;
    }

    function parsePermittedObjectTypes(objectTypes) {
        let result = {
            objectTypes: [],
            objectTypesAllowed: true
        };

        if (objectTypes == void 0) {
            return result;
        }

        result.objectTypesAllowed = !!(objectTypes.ObjectTypesAllowed != void 0 ? parseInt(objectTypes.ObjectTypesAllowed) : -1);

        objectTypes = Array.isArray(objectTypes.ObjectType) ? objectTypes.ObjectType : [objectTypes.ObjectType]

        for (const objectType of objectTypes) {
            if (objectType != void 0) {
                result.objectTypes.push(objectType.InternalName);
            }
        }

        return result;
    }

    // region record
    function parseRecordParameterValue(wfVar, types) {
        types = types || wfVar.Types;
        let members = getMembers(wfVar);

        if (members == void 0) {
            members = [];
        }

        if (!Array.isArray(members)) {
            members = [members];
        }

        let data = parseRecordMembers(members, types);
        let record = extractRecordMemberValues(data);

        return {
            data,
            record
        };
    }

    function parseRecordMembers(members, types) {
        let parsedMembers = [];

        for (let i in members) {
            let member = members[i],
                xmlType = getParameterXMLType(member, false, false),
                type = dictionary.parameterTypeMap[xmlType];

            let parsedMember = {
                type,
                name: member.Name
            };

            var parsedValue;
            if (type == "record") {
                parsedValue = parseRecordParameterValue(member, types);
            } else if (type == "grid") {
                parsedValue = parseGridParameterValue(member.List, types);
            } else {
                parsedValue = { value: extractSimpleValue(member, xmlType) };
            }

            // extend parsedMember by parsedValue
            for (let key in parsedValue) {
                parsedMember[key] = parsedValue[key];
            }

            parsedMembers.push(parsedMember);
        }

        return parsedMembers;
    }

    function extractRecordMemberValues(parsedMembers) {
        let record = {};

        for (let i = 0; i < parsedMembers.length; i++) {
            let parsedMember = parsedMembers[i];

            if (parsedMember.type == "record") {
                parsedMember.value = parsedMember.record;
                delete parsedMember.record;
            } else if (parsedMember.type == "grid") {
                addAndExtractGridRows(parsedMember);
            }

            record[parsedMember.name] = parsedMember.value;
            delete parsedMember.value;
        }

        return record;
    }

    function addAndExtractGridRows(parsedMember) {
        let columns = parsedMember.columns,
            isFlat = parsedMember.isFlat,
            rows = parsedMember.rows;

        let values = [],
            rowIds = [];

        for (let i = 0; i < rows.length; i++) {
            let row = rows[i];

            var rowValue;
            if (isFlat) {
                rowValue = row.content[0];
            } else {
                rowValue = {};
                for (let j = 0; j < row.content.length; j++) {
                    rowValue[columns[j].name] = row.content[j];
                }
            }

            values.push(rowValue);
            rowIds[i] = row.id;
        }

        parsedMember.rowIds = rowIds;
        parsedMember.value = values;
        delete parsedMember.rows;
    }

    // endregion

    // region grid
    function parseGridParameterValue(listEl, typesEl) {
        let typeId = listEl.TypeId,
            columns = getMembers(typesEl, typeId),
            isFlat = isListFlat(typesEl, typeId);

        if (columns == void 0) {
            columns = [];
        }

        if (!Array.isArray(columns)) {
            columns = [columns];
        }

        columns = parseGridMembers(columns);

        let rows = getGridRows(listEl);

        return {
            typeId,
            isFlat,
            columns,
            rows
        };
    }

    function isListFlat(typesEl, typeId) {
        if (typeId == void 0) {
            return true;
        }

        let types = typesEl.Type;
        if (!Array.isArray(types)) {
            types = [types];
        }

        for (let i = 0; i < types.length; i++) {
            let type = types[i];

            if (typeId == type.Id) {
                return !type.Record;
            }
        }
    }

    function parseGridMembers(members) {
        let parsedMembers = [];

        for (let i = 0; i < members.length; i++) {
            let member = members[i];

            let parsedMember = {
                type: dictionary.parameterTypeMap[getParameterXMLType(member, true, false)]
            };

            if (member.Name != void 0) {
                parsedMember.name = member.Name;
            }

            parsedMembers.push(parsedMember);
        }

        return parsedMembers;
    }

    function getGridRows(list) {
        let rows = [];

        if (typeof (list.ListItem) == "undefined") {
            return rows;
        }

        if (!Array.isArray(list.ListItem)) {
            list.ListItem = [list.ListItem];
        }

        for (let i in list.ListItem) {
            let entry = list.ListItem[i];

            let row = {
                id: entry.Id
            };

            let rowModel = getMembers(entry);
            let rowContent = [];

            for (let cellIndex in rowModel) {
                let cellModel = rowModel[cellIndex];
                let cell = extractSimpleValue(cellModel, getParameterXMLType(cellModel));
                rowContent.push(cell);
            }

            row.content = rowContent;

            rows.push(row);
        }
        return rows;
    }

    // endregion

    function extractSimpleValue(member, xmlType) {
        let value = "";
        if (xmlType != void 0 && typeof (member[xmlType]) != "object") {
            value = member[xmlType];
        }
        return value;
    }

    function getMembers(object, typeId) {
        for (let key in object) {
            let value = object[key];

            key = key.toLowerCase();

            if (key == "member") {
                if (!Array.isArray(value)) {
                    value = [value];
                }
                return value;
            }

            if (key == "type") {
                if (!Array.isArray(value)) {
                    value = [value];
                }

                for (let j = 0; j < value.length; j++) {
                    var type = value[j];

                    if (typeId != void 0 && type.Id != typeId) {
                        continue;
                    }

                    return getMembers(type);
                }
            } else if (key == "declaredtype" || key == "record") {
                return getMembers(value);
            }

            // parameter could be a flat list without a record
            // unfortunately we cannot determine a list parameter differently
            for (var type in dictionary.parameterTypeMap) {
                if (key == type.toLowerCase()) {
                    return [object];
                }
            }
        }
    }

    function getParameterXMLType(param, uppercase, transform) {
        for (let type in dictionary.parameterTypeMap) {

            let transformedType = uppercase == true ? type.toUpperCase() : type;

            if (typeof (param[transformedType]) != "undefined") {
                return transform == false ? type : transformedType;
            }
        }

        return null;
    }

    // endregion

    // region parameter API
    function getParameterApi(helper, paramData, field) {
        return new ParameterApi(helper, paramData, field);
    }

    function ParameterApi(helper, paramData, field) {
        let self = this;

        // exclude the grid because the field api has no setValue in case its a grid
        if (paramData.model.type == "grid") {
            // add special functions for grids
            addGridApi(self, paramData);
        } else if (paramData.model.type == "record") {
            self.setValue = function(value) {
                paramData.value.record = value;
            };
        } else {
            self.setValue = function(value, setFieldValue) {
                paramData.value = value;

                setFieldValue = setFieldValue == void 0 ? true : setFieldValue;

                if (setFieldValue && field != void 0 && field.api != void 0) {
                    field.api.setValue(value);
                }
            };
        }

        /**
         * returns the field assigned to the parameter
         * @returns {*|Object}
         */
        self.getField = function() {
            return field;
        };

        /**
         * Get the value of the parameter.
         * @param {boolean} transform - Determines whether to convert dates to timestamps or not.
         * @returns {*} The value of the parameter.
         */
        self.getValue = (transform) => transformValue(paramData.value, transform);

        /**
         * Get the initial value of the parameter.
         * @param {boolean} transform - Determines whether to convert dates to timestamps or not.
         * @returns {*} The initial value of the parameter.
         */
        self.getInitialValue = (transform) => transformValue(paramData.initialValue, transform);

        /**
         * Get a value of the parameter.
         * @param {*} value - A value.
         * @param {boolean} transform - Determines whether to convert dates to timestamps or not.
         * @returns {*} The transformed value.
         */
        function transformValue(value, transform) {
            let type = paramData.model.type;

            if (value == void 0 || value === "") {
                return "";
            }

            if (transform) {
                if (type == "date") {
                    return ValueUtilsService.convertToTimestamp(value, false, true);
                }

                if (type == "datetime") {
                    return ValueUtilsService.convertToTimestamp(value, true, true);
                }
            }

            if (type == "record") {
                return value.record;
            }

            return type == "grid" ? value : value.toString();
        }

        function addGridApi(self, paramData) {
            /**
             * Get the rows of the grid.
             * @returns {object[]} - The rows.
             */
            self.getRows = () => extractRows(paramData.value);

            /**
             * Get the initial rows of the grid.
             * @returns {object[]} - The initial rows.
             */
            self.getInitialRows = () => extractRows(paramData.initialValue);

            /**
             * Transform the record value to a list representation.
             * @param {object} value - A grid value.
             * @returns {object[]} - The extracted rows.
             */
            function extractRows(value) {
                let rows = [];

                let paramRows = value.rows;
                for (let row of paramRows) {
                    rows.push(row.content);
                }

                return rows;
            }

            self.addRow = function(values) {
                // adding an empty row
                if (values === void 0) {
                    values = [];
                }

                if (arguments.length > 1 || !Array.isArray(values)) {
                    values = [].slice.apply(arguments);
                }

                self.addRows([values]);
            };

            self.addRows = function(rowValues) {
                rowValues.forEach(values => {
                    let row = transformValuesToRow(values);
                    paramData.value.rows.push(row);
                });

                if (field != void 0 && field.api != void 0 && field.api.addRows != void 0) {
                    field.api.addRows(rowValues);
                }
            }

            self.editRow = function(index, values) {
                if (!ValueUtilsService.isValidRowIndex(index, paramData.value.rows.length)) {
                    return;
                }

                if (!Array.isArray(values)) {
                    values = Array.prototype.slice.call(arguments, 1);
                }

                let row = transformValuesToRow(values);

                paramData.value.rows[index].content = row.content;

                if (field != void 0 && field.api != void 0 && field.api.editRow != void 0) {
                    field.api.editRow(index, values);
                }
            };

            self.removeRow = function(index) {
                if (!ValueUtilsService.isValidRowIndex(index, paramData.value.rows.length)) {
                    return;
                }

                paramData.value.rows.splice(index, 1);

                if (field != void 0 && field.api != void 0 && field.api.removeRow != void 0) {
                    field.api.removeRow(index);
                }
            };

            self.setRows = function(values) {
                paramData.value.rows = [];
                self.addRows(values);
            }

            function transformValuesToRow(values) {
                values = ValueUtilsService.normalizeRowValues(values, paramData.value.columns.length);

                let row = {
                    content: values
                };

                return row;
            }
        }
    }

    // endregion
}
