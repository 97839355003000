<button *ngIf="menuItems.length == 1" class="dashlet-kebab-menu-single-icon button-transparent" id="dashlet-kebab-menu-single-item">
    <span [ngClass] = "{active: activeContainerDashlet && activeContainerDashlet.includes('customDashlet')}"
       (click)="goTo(menuItems[0])">
        <eob-icon [name]="menuItems[0].icon" [title]="menuItems[0].title" size="24"></eob-icon>
    </span>
</button>

<button *ngIf="menuItems.length != 1" class="dashlet-kebab-menu button-transparent" (click)="toggleMenu($event)"
      title="{{'eob.dashlet.menu.title' | ngTranslate}}" [attr.arial-label]="'eob.dashlet.menu.title' | ngTranslate">
    <div class="dashlet-kebab-menu-icon" [ngClass]="{'open': dropdownOpen}">
        <!-- one dot per span for the kebab menu icon -->
        <span></span>
        <span></span>
        <span></span>
    </div>
</button>

<ul *ngIf="menuItems.length != 1" id="dashlet-kebab-menu-dropdown"
    [ngClass]="{'open': dropdownOpen == true, 'close': dropdownOpen == false, 'hidden': dropdownOpen == null}">
    <li (click)="goTo(item)" *ngFor="let item of menuItems" [ngClass] = "{'selected': activeCustomDashletName === item.name}">
        <button class="button-transparent">
            <eob-icon [name]="item.icon" [title]="item.title" size="16"></eob-icon>
            <span>{{item.title}}</span>
        </button>
    </li>
</ul>
