(function() {
    require("SERVICES_PATH/viewer/documentviewer.view.srv.js");
    require("SERVICES_PATH/viewer/documentviewer.page.srv.js");
    require("SERVICES_PATH/eob.backend.srv.js");

    angular.module("eob.core").factory("documentViewer", DocumentViewer);

    DocumentViewer.$inject = ["documentView", "documentPage", "backendService", "progressbarService", "$rootScope", "$compile", "$q", "$injector"];

    function DocumentViewer(DocumentView, DocumentPage, BackendService, ProgressbarService, $rootScope, $compile, $q, $injector) {
        let ViewerService;
        let viewer = null;

        let ProfileService = null;
        let preloadScope = {};
        let urlBase = "/app";
        let globalTimeout = 30000;

        let IMAGE_SIZE_THUMBNAIL = "96";
        let IMAGE_SIZE_MIDDLE = "1200";

        let infoURLLayout, imageURLLayout, searchURLLayout, textURLLayout;

        return {
            getViewer,
            init
        };

        function init(element, GlobalViewerService) {
            try {
                ProfileService = $injector.get("profileService");
            } catch (error) {console.info(error)}
            initViewer();

            if (viewer == void 0) {
                console.error("Viewer could not be initialized");
                return;
            }

            infoURLLayout = `${urlBase}/api/document/IDIDID/rendition/web/info?`;
            imageURLLayout = `${urlBase}/api/document/IDIDID/rendition/web/page/PAGEPAGEPAGE/image/SIZESIZESIZE?timestamp=TIMESTAMPTIMESTAMP&timeout=${globalTimeout}`;
            searchURLLayout = `${urlBase}/api/document/IDIDID/rendition/web/search?q=QUERYQUERY&timeout=${globalTimeout}`;
            textURLLayout = `${urlBase}/api/document/IDIDID/rendition/web/page/PAGEPAGEPAGE/text.json?timeout=${globalTimeout}&timestamp=TIMESTAMPTIMESTAMP`;

            viewer.init(element, GlobalViewerService);
        }

        function initViewer() {
            if (ViewerService == void 0) {
                ViewerService = $injector.get("viewerService");
            }

            if (viewer == void 0) {
                viewer = new Viewer();

                // set the viewer inside the view service to avoid circular references
                DocumentView.initView();
                DocumentView.setViewer(viewer);
            }
        }

        function getViewer() {
            initViewer();
            return viewer;
        }

        function Viewer() {
            let self = this;

            self.layout = {};

            self.init = function(element, GlobalViewerService) {

                // init object with most of the dom elements for easier access
                self.layout = {
                    view: element.find("#view"),
                    viewerContainer: element.find("#viewer-container"),
                    footerContainer: element.find("#footer-container"),
                    searchContainer: element.find("#findbar"),
                    searchInput: element.find("#findInput"),
                    searchResultCount: element.find("#findResultsCount"),
                    content: element.find("#content"),
                    contentContainer: element.find("#content-container"),
                    pageContainer: element.find("#page-container"),
                    textContainer: element.find("#text-container"),
                    contentPadding: 16,
                    thumbnailContainer: element.find("#thumbnail-container"),
                    thumbnailWrapper: element.find("#thumbnail-wrapper"),
                    toolbar: element.find("#viewer-toolbar"),
                    currentPageNumber: element.find("#current-page-number"),
                    totalPageCount: element.find("#total-page-count"),
                    contentErrorContainer: element.find("#content-error-container"),
                    contentErrorImage: element.find("#content-error-image"),
                    errorContainer: element.find("#error-container"),
                    defaultPage: element.find("#default-page"),
                    errorImage: element.find("#error-image"),
                    prevPageButton: element.find(".prev-page"),
                    nextPageButton: element.find(".next-page"),
                    goToParentButton: element.find(".go-to-parent"),
                    showThumbnailsButton: element.find(".show-thumbnails"),
                    showAttachmentsButton: element.find(".show-attachments"),
                    attachmentsBadge: element.find("#attachments-badge"),
                    showSearchButton: element.find(".show-search"),
                    recreateDocumentButton: element.find(".recreate-document"),
                    getViewportWidth() {
                        return element.innerWidth() - self.layout.toolbar.width();
                    },
                    getViewportHeight() {
                        return element.height();
                    }
                };

                self.view.bindThumbnailScrollEvent();
                self.view.bindMouseScrollEvent();
                self.view.bindSearchKeypressEvent();
                self.view.bindPageNumberKeypress();

                self.clearViewer();

                self.globalLoadingAnimation = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);

                self.globalViewerService = GlobalViewerService;

                bindClickHandler();
                bindKeyboardHandler();

                self.layout.defaultPage.show();
                self.layout.viewerContainer.hide();
                self.layout.errorContainer.hide();
            };

            self.view = DocumentView.getView();

            self.queryInterval = 750; // interval in milliseconds for the server meta data requests
            self.delaySearchExecutionBy = 250; // delay in milliseconds for "live" search activity

            self.parents = [];
            self.attachments = [];

            self.zoomedWidth = 0;
            self.zoomedHeight = 0;

            self.isSelectingText = false;
            self.isPreviewReady = false;

            self.pages = false; // initially nothing known about number of pages or pages themselves
            self.pageTextCache = {};
            self.pageTextPromises = {};

            self.initialSearchResultPage = -1;
            self.searchSequenceNumber = 0;
            self.onlyPreloadThumbnailsAfterPage = 0; // unless the server tells us to not preload pages we preload all thumbnail images

            self.searchEnabled = false; // remember if the search functionality has been activated

            // status for the system: are we showing a page view already, or is the system still initializing?
            self.isShowingPage = false;

            self.globalTimestamp = 0;

            self.lastSearchTerm = "";

            self.isLoadingAnimationVisible = false;

            self._markPagesWithHash = true;
            self.currentPage = 1;
            self.totalPageCount = 0;
            self.isAttachment = false;
            self.previousAttachment = null;
            self.nextAttachment = null;
            self.nextAttachmentParentPathIndex = 0;

            self.waitForSearch = false;
            self.cachedSearchResults = {};

            self.isForeign = false;

            function bindClickHandler() {
                self.layout.content.bind("click", (e) => {

                    // the user selected text and we need to let this be
                    // we are toggling the var at this point because the click fires once the user
                    // releases the mouse button, so this might be the only place where we can do this
                    if (self.isSelectingText) {
                        self.isSelectingText = false;
                        return;
                    }

                    let target = angular.element(e.target);

                    if (target.is("input")) {
                        target.focus();
                    } else {
                        self.view.removeTextSelection();
                        self.layout.viewerContainer.focus();
                    }
                });
            }

            let keys = {
                left: 37,
                up: 38,
                right: 39,
                down: 40,
                pageUp: 33,
                pageDown: 34,
                end: 35,
                home: 36,
                esc: 27,
                numPlus: 107,
                numMinus: 109
            };

            function bindKeyboardHandler() {
                self.layout.viewerContainer.bind("keydown", (ev) => {

                    if (ev.which == 70 && ev.ctrlKey) {
                        self.view.switchSearch();
                        ev.preventDefault();
                    }

                    if (ev.which == keys.pageDown) {
                        self.nextPage();
                        ev.preventDefault();
                    }
                    if (ev.which == keys.pageUp) {
                        self.prevPage();
                        ev.preventDefault();
                    }

                    if (ev.which == keys.numMinus) {
                        self.view.setCustomZoom(-0.1);
                        self.view.adjustDimensions();
                        ev.preventDefault();
                    }
                    if (ev.which == keys.numPlus) {
                        self.view.setCustomZoom(0.1);
                        self.view.adjustDimensions();
                        ev.preventDefault();
                    }

                    // Catch Ctrl+A and select everything (if possible)
                    if (ev.which == 65 && ev.ctrlKey) {
                        self.view.selectAll();
                        ev.preventDefault();
                    }

                    if (ev.ctrlKey && (ev.which == 48 || ev.which == 96)) {
                        self.view.sizeViewToFit();
                        ev.preventDefault();
                    }

                    if (ev.ctrlKey && ev.which == 67) {
                        self.view.copySelectionToClipboard();
                    }
                });
            }

            self.clearViewer = function() {
                if (self.layout.viewerContainer) {
                    self.layout.viewerContainer.hide();
                }

                if (self.layout.viewerContainer) {
                    self.layout.errorContainer.hide();
                }
                if (self.layout.thumbnailContainer) {
                    self.layout.thumbnailContainer.hide();
                }

                if (self.layout.defaultPage) {
                    self.layout.defaultPage.show();
                }

                self.documentId = null;
            };

            self.refresh = function(id) {
                self.documentId = null;
                self.updateId(id, self.isForeign, null, true);
            };

            self.resizeCallback = function() {
                self.view.adjustDimensions();
            }

            self.showSearch = function(searchKey) {
                if (self.isPreviewReady && !self.isErrorShowing) {
                    showSearch(searchKey);
                } else {
                    var interval = setInterval(() => {
                        if (self.isPreviewReady && !self.isErrorShowing) {
                            showSearch(searchKey);
                            clearInterval(interval);
                        }
                    }, 100);
                }
            };

            function showSearch(key) {
                self.layout.searchInput.val(key);
                self.view.showSearch();
                self.layout.thumbnailContainer.hide();
                self.waitForSearch = true;
                self.search(key);
            }

            // a pagenumber -1 means we go to the last page of the document
            self.updateId = function(id, isForeign, pagenumber, refresh) {
                if (id == self.documentId) {
                    return;
                }

                self.goToLastPage = pagenumber != void 0 && pagenumber == -1;

                self.waitForSearch = false;

                self.documentId = id;
                self.pages = false;
                self.view.resetView();
                self.view.setDocumentId(id);
                self.currentPage = 1;

                self.lastSearchTerm = "";

                self.isPreviewReady = false;
                self.isErrorShowing = false;

                self.zoomedWidth = 0;
                self.zoomedHeight = 0;

                self.pageTextCache = {};
                self.attachments = [];
                self.isForeign = !!isForeign;
                self.isAttachment = self.isForeign;

                if (self.isAttachment) {
                    ViewerService.notifyAnnotations();
                    self.layout.toolbar.find(".annotations").hide();
                    self.layout.recreateDocumentButton.hide();
                } else {
                    self.layout.toolbar.find(".annotations").show();
                    self.layout.recreateDocumentButton.show();
                }

                self.nextAttachment = null;
                self.previousAttachment = null;

                if (!self.isForeign) {
                    self.parents = [];
                    self.layout.searchInput.val("");
                    self.layout.searchInput.removeClass("notFound");
                    self.layout.searchResultCount.text("0");
                } else {
                    getNextAndPrevAttachment();
                }

                // eslint-disable-next-line promise/catch-or-return
                self.askForData().then((response) => {
                    let data = response.data;

                    if (self.goToLastPage) {
                        self.currentPage = Number(data.page_count);
                    }

                    self.update(data, true);

                    if (refresh) {
                        ViewerService.refreshAnnotations(self.totalPageCount);
                    }

                    self.view.initThumbnails();

                    showAttachmentsButtons(self.isForeign);

                    // this means we are waiting for the viewer to search the backend for fulltext hits (maybe)
                    // so the search is open and we do not want to show attachments
                    if (!self.waitForSearch) {
                        showAttachmentThumbnails();
                    }

                    toggleNextPageButton();
                    togglePrevPageButton();
                }, () => {
                    self.view.showErrorPage("404");

                    if (!self.isAttachment) {
                        self.view.hideControls();
                    } else {
                        toggleNextPageButton();
                        togglePrevPageButton();
                    }
                });
            };

            function getNextAndPrevAttachment() {
                let lastIndex = 1;
                let currentParent = self.parents[self.parents.length - 1];

                if (self.parents.length === 0) {
                    return;
                }

                for (let i in currentParent.pages) {
                    let p = currentParent.pages[i];
                    if (self.documentId == p.foreign_id) {
                        lastIndex = Number(i);
                        break;
                    }
                }

                // setting previous and next attachment
                self.previousAttachment = currentParent.pages[lastIndex - 1] != void 0 && currentParent.pages[lastIndex - 1].foreign_id ? currentParent.pages[lastIndex - 1] : null;
                // this is the easy case --> there is an attachment following after the current one
                // just use it's foreign_id as the next viewer id
                if (currentParent.pages[lastIndex + 1] != void 0 && currentParent.pages[lastIndex + 1].foreign_id) {
                    self.nextAttachment = currentParent.pages[lastIndex + 1];
                    return;
                }

                // this is a rather rare case we need to check
                // there is no attachment after the current one, but ..... the current parent itself is an attachment that might have
                // more attachments following our current parent,
                // we need to think of mail attachments like branches we jump into and out of it
                // in this case the next attachment would mean we jump to our parents node and use the attachment thats following
                // as the next attachment to show
                let currentIndex = lastIndex;

                let parentIndex = self.parents.length - 1;

                while (currentParent != void 0) {

                    if (currentParent.pages[currentIndex + 1] != void 0 && currentParent.pages[currentIndex + 1].foreign_id) {
                        self.nextAttachment = currentParent.pages[currentIndex + 1];
                        self.nextAttachmentParentPathIndex = parentIndex + 1;
                        break;
                    }

                    parentIndex--;

                    currentParent = self.parents[parentIndex];

                    if (currentParent != void 0) {
                        currentIndex = currentParent.number + 1;
                    }
                }
            }

            self.showViewer = function() {
                if (self.isPreviewReady && !self.isErrorShowing) {
                    showViewer();
                } else {
                    let interval = setInterval(() => {
                        if (self.isPreviewReady && !self.isErrorShowing) {
                            showViewer();
                            clearInterval(interval);
                        }
                    }, 100);
                }
            };

            function showViewer() {
                self.layout.viewerContainer.show();

                if (self.view.thumbnailsVisible) {
                    self.layout.thumbnailContainer.show();
                }
            }

            self.goToParent = function() {
                let parent = self.parents[self.parents.length - 1];

                self.parents.pop();
                self.isAttachment = false;

                if (isNumber(parent.id)) {
                    ViewerService.notifyAnnotations(parent.id);
                }

                self.updateId(parent.id, parent.isForeign);
            };

            function showAttachmentsButtons(isForeign) {
                if (isForeign) {
                    self.layout.goToParentButton.show();
                } else {
                    self.layout.goToParentButton.hide();
                }

                if (self.attachments.length > 0) {
                    self.layout.showAttachmentsButton.show();
                    self.setAttachmentBadgeInformation(self.attachments.length);
                } else {
                    self.layout.showAttachmentsButton.hide();
                }
            }

            function showAttachmentThumbnails() {
                if (self.view.attachmentsVisible) {
                    if (self.attachments.length == 0) {
                        self.layout.thumbnailContainer.hide();
                    } else {
                        self.layout.thumbnailContainer.show();
                    }
                }
            }

            self.setAttachmentBadgeInformation = function(val) {
                if (self.layout.attachmentsBadge[0]) {
                    self.layout.attachmentsBadge[0].innerHTML = val == void 0 ? "" : val;
                }
            };

            function toggleNextPageButton() {
                if (self.isAttachment && self.nextAttachment == void 0 && (self.currentPage == self.pages.length - 1 || !self.pages)) {
                    self.layout.nextPageButton.css({ visibility: "hidden" });
                    return;
                }

                if (self.pages.length == 1 && self.nextAttachment == void 0) {
                    self.layout.nextPageButton.css({ visibility: "hidden" });
                    return;
                }

                if (self.currentPage == self.pages.length - 1 && self.nextAttachment == void 0) {
                    self.layout.nextPageButton.css({ visibility: "hidden" });
                    return;
                }

                self.layout.nextPageButton.css({ visibility: "visible" });
            }

            function togglePrevPageButton() {
                // just one page and not an attachment
                if (self.pages.length == 1 && !self.isAttachment) {
                    self.layout.prevPageButton.css({ visibility: "hidden" });
                    return;
                }

                let page = self.getPage(self.currentPage);

                if (page != void 0 && page.number == 1 && !self.isAttachment) {
                    self.layout.prevPageButton.css({ visibility: "hidden" });
                    return;
                }

                self.layout.prevPageButton.css({ visibility: "visible" });
            }

            /**
             * self starts a search for the given term, and asks the server for search results if there aren't any cached results.
             **/
            self.search = function(term) {
                if (term.length < 3) {
                    // don't search for very short terms
                    return;
                }

                if (self.lastSearchTerm == term) {
                    // we already had self search before, reuse cached search results
                    self.gotSearchResults(self.cachedSearchResults);
                } else {
                    // request search results from server...
                    self.lastSearchTerm = term;
                    self.searchSequenceNumber++;

                    let url = searchURLLayout.replace(/IDIDID/g, self.documentId);
                    url = `${url.replace(/QUERYQUERY/g, encodeURIComponent(term))}&cacheBuster=${Math.random()}`;

                    let currentSearchSequenceNumber = self.searchSequenceNumber;
                    // async data loading

                    // eslint-disable-next-line promise/catch-or-return
                    BackendService.get(url, "/osrenditioncache").then((response) => {
                        if (self.searchSequenceNumber == currentSearchSequenceNumber) {
                            // Only process the search results if these belong to the last executed search query as
                            // we do not actually cancel searches, just ignore their results if the user executes multiple searches quickly
                            self.view.removeSearchSelection();
                            self.gotSearchResults(response.data);
                        }
                    });
                }
            };

            /**
             * self is called after the start of a search - changing to the page with the first search result
             **/
            self.gotSearchResults = function(results) {
                if (!results) {
                    return;
                }
                if (results.info) {
                    // update cached results: we got an ajax server response
                    self.cachedSearchResults = results.info;
                }
                // else: we use cached search results

                self.initialSearchResultPage = self.currentPage;

                // if we are loaded with a search term in the url its possible we got a page number too as a parameter..
                // in that case we check if that page has search results on it and show that page otherwise we show the
                // first page with search results on it.
                var firstPageIndex;
                if (self.initialSearchResultPage != -1 && self.cachedSearchResults.documents[1].pages[self.initialSearchResultPage]) {
                    firstPageIndex = self.initialSearchResultPage;
                } else {
                    var firstPageIndex = -1;
                    for (let pageindex in self.cachedSearchResults.documents[1].pages) {
                        firstPageIndex = pageindex;
                        break;
                    }
                }

                // Test if have no hits in the currently open document. If yes, check if we got hits in attachments and jump to first attachment (if we may jump)
                if (self.cachedSearchResults.documents[1].count == "0") {
                    // Do we have hits in other documents?
                    for (let docindex in self.cachedSearchResults.documents) {
                        // Not looking at curren document
                        if (docindex == 1) {
                            continue;
                        }

                        let searchResultsInDocument = self.cachedSearchResults.documents[docindex];

                        if (parseInt(searchResultsInDocument.count) > 0) {
                            // Look for the page that has the foreign_id set to be equal to the digest from the search result indicator and jump to that "page"
                            for (let i = 1; i < self.pages.length; i++) {
                                let page = self.pages[i];
                                if (page.isForeign() && page.foreign_id == `*${searchResultsInDocument.digest}`) {
                                    self.goToPage(i, true);
                                    return;
                                }
                            }
                            return;
                        }
                    }
                }

                // no hit on any page found --> add invalid class to highlight the search field
                if (firstPageIndex < 0) {
                    self.goToPage(self.currentPage);
                    self.layout.searchInput.addClass("notFound");
                    self.layout.searchResultCount.text("0");
                } else {
                    self.layout.searchInput.removeClass("notFound");
                }

                // go to first page with search results on it
                self.goToPage(firstPageIndex, true);
            };

            self.nextPage = function() {
                // reset zoom
                self.view.zoomedWidth = 0;
                self.view.zoomedHeight = 0;

                if (!self.pages || self.pages.length == 0 || self.currentPage == self.pages.length - 1) {
                    if (self.isAttachment && self.nextAttachment != void 0) {

                        if (self.nextAttachmentParentPathIndex != 0) {
                            self.parents = self.parents.slice(0, self.nextAttachmentParentPathIndex);
                            self.nextAttachmentParentPathIndex = 0;
                        }

                        self.updateId(self.nextAttachment.foreign_id, true);
                    }
                } else {
                    self.goToPage(parseInt(self.currentPage) + 1, true);
                }
            };

            self.prevPage = function() {
                // reset zoom
                self.view.zoomedWidth = 0;
                self.view.zoomedHeight = 0;

                if (self.currentPage == 1) {
                    // we are on first page. if possible open previous document or parent  if we are inside an attachment

                    // Added additional logic for Change Request: 476999341
                    // If we are an attachment, we have to figure out if there is a previous attachment we can go to and open it instead of navigating to parent immediately.
                    if (self.isAttachment) {
                        // Go to the previous attachment now
                        if (self.previousAttachment == null) {
                            self.goToParent();
                        } else {
                            self.updateId(self.previousAttachment.foreign_id, true, -1);
                        }

                    }
                } else {
                    self.goToPage(parseInt(self.currentPage) - 1, true);
                }
            };

            function isNumber(n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            }

            // navigate to the page with given number if possible.
            // ask the server for that page, if we don't got info on it yet...
            self.goToPage = function(number, highlightSearchResults, selectLastHit) {
                if (!isNumber(number) || parseInt(number) < parseInt(1) || (self.pages && parseInt(number) > parseInt(self.totalPageCount))) {
                    self.view.updatePageNumber(self.currentPage);
                    return;
                }

                let page = self.getPage(number);
                if (!page) {
                    return;
                }

                // we try to navigate to a page with foreign  id which means we need to switch to a different document.
                if (page.isForeign()) {
                    self.parents.push({
                        id: self.documentId,
                        isForeign: self.isAttachment,
                        pages: self.pages,
                        number: page.number
                    });

                    self.updateId(page.foreign_id, true);
                    return;
                }

                self.view.updatePageNumber(number);

                self.currentPage = number;
                self.view.showPage(self.currentPage);

                // eslint-disable-next-line promise/catch-or-return
                self.getPageText(self.currentPage).then((text) => {
                    self.view.overlayPageText(text);

                    if (highlightSearchResults) {
                        self.view.highlightSearchResults(self.currentPage);
                        let pageHitCount = self.view.currentSearchHits.length - 1;
                        if (selectLastHit) {
                            self.view.markSearchHit(pageHitCount);
                        } else {
                            self.view.markSearchHit(0);
                        }

                    }
                });

                DocumentView.setThumbActive(number);
                toggleNextPageButton();
                togglePrevPageButton();
            };

            self.rememberRequested = function(what, page) {
                self.singleRequestsHistory[`${page}/${what}`] = true;
            };

            /** askForPageText
             *  Load text for the current page...
             **/
            self.getPageText = function(page) {
                let currentPage = self.getPage(page);
                let deferred = $q.defer();
                let promise = deferred.promise;

                // do not ask for text on pages which do not have text available
                if (!self.pages[self.currentPage] || currentPage.hasNoText) {
                    // No error object because this is no error.
                    deferred.reject();
                    return promise;
                }

                self.pageTextPromises[self.currentPage] = promise;

                if (self.pageTextCache[page]) {
                    // already got the page text... dont need to ask the server...
                    // we are gonna overlay it...
                    deferred.resolve(self.pageTextCache[page]);
                } else if (!currentPage.wasAskedForText) {
                    currentPage.wasAskedForText = true;

                    let url = textURLLayout.replace(/PAGEPAGEPAGE/g, page);
                    url = url.replace(/TIMESTAMPTIMESTAMP/g, self.globalTimestamp);
                    url = url.replace(/IDIDID/g, self.documentId);

                    // eslint-disable-next-line promise/catch-or-return
                    BackendService.get(url, "/osrenditioncache").then((response) => {
                        let pageNumber = Object.keys(response.data.pages)[0];

                        self.pageTextCache[pageNumber] = response.data.pages[pageNumber];

                        deferred.resolve(response.data.pages[pageNumber]);
                    }, (error) => {
                        currentPage.hasNoText = true;
                        deferred.reject(error);
                    });
                }

                return promise;
            };

            /**
             * Ask for document meta information in JSON format and update the model
             **/
            self.askForData = function() {
                let url = infoURLLayout.replace(/IDIDID/g, self.documentId);

                // when trying to access a page that we don't know anything about yet, we tell the server
                // that we primarily want data about that specific page. If the page has not been processed yet
                // the server should do it now.
                url += `&page=${self.currentPage}&cacheBuster=${Math.random()}`;
                // asyncronous requesting of meta data

                return BackendService.get(url, "/osrenditioncache");
            };

            self.setProgress = function(value) {
                if (!self.last_progress) {
                    self.last_progress = 1;
                }

                self.last_progress = Math.max(parseInt(self.last_progress), parseInt(value));

                // update progress ui
                if (self.last_progress >= 1) {
                    $("#progress1").addClass("active");
                }
                if (self.last_progress >= 2) {
                    $("#progress2").addClass("active");
                }
                if (self.last_progress >= 3) {
                    $("#progress3").addClass("active");
                }
                if (self.last_progress >= 4) {
                    $("#progress4").addClass("active");
                }
            };

            /**
             * Callback for the "data loaded" event - checks if any (new) information is given and updates the model accordingly
             * and triggers actions in the view
             **/
            self.update = function(data, forceUpdate) {
                //self.openQuery = false; // got a response, so the active query is done now.
                let serverFinished = false;

                switch (data.status) {
                    case "failed":
                        self.view.showErrorPage();
                        self.isPreviewReady = true;
                        self.isErrorShowing = true;
                        return;
                    case "processing":
                        // we get data, but server is still working so need to ask again...
                        if (data.progress) {
                            self.setProgress(data.progress);
                        }
                        break;
                    case "successful":
                        // server is done, no more data coming even if we ask for the same data again...
                        serverFinished = true;
                        self.setProgress(4);

                        var icon = "document";
                        if (data.objecttype == "folder") {
                            icon = "OT-Archive-dark";
                        }
                        if (data.objecttype == "register") {
                            icon = "OT-Register-dark";
                        }
                        if (data.objecttype == "map") {
                            icon = "ico-mappe";
                        }
                        if (data.objecttype == "memo") {
                            icon = "ico-notiz";
                        }
                        if (data.objecttype == "permissiondenied") {
                            icon = "403";
                        }
                        if (data.objecttype == "nodocument") {
                            icon = "404";
                        }

                        if (icon != "document") {
                            self.view.showErrorPage(icon);
                            self.isPreviewReady = true;
                            self.isErrorShowing = true;
                            return;
                        }
                        break;
                    default:
                        self.view.showErrorPage();
                        self.isPreviewReady = true;
                        self.isErrorShowing = true;
                        return;
                }

                let needToShowNavigationBar = false;

                // if we didn't know the page count before, create the pages models now.
                if (!self.pages && data.page_count) {
                    self.pages = [];
                    self.pages[0] = DocumentPage.getPage(0, self.documentId, viewer, self.view); // dummy entry to ensure that our pages start at index "1" for page "1".

                    self.totalPageCount = data.page_count;
                    self.layout.totalPageCount.text(` / ${self.totalPageCount}`);

                    // didn't know anything about pages before, create page models now
                    for (let i = 1; i <= data.page_count; i++) {
                        self.pages[self.pages.length] = DocumentPage.getPage(i, self.documentId, viewer, self.view);
                    }

                    // we know how many pages so we can show the navigation and the thumbnail placeholders
                    needToShowNavigationBar = true;

                    // if we are trying to show a page that doesnt exist...
                    if (self.currentPage > data.page_count) {
                        self.currentPage = 1;
                    }
                }

                self.layout.errorContainer.hide();
                self.layout.contentErrorContainer.hide();
                self.layout.defaultPage.hide();

                // Previously was using last Updated timestamp, now using "created" timestamp of the info.xml on serverside which allows more effective caching
                //self.globalTimestamp = data.timestamp;
                self.globalTimestamp = data.created;

                /*
                 * The server automatically creates a few thumbnails and full sized pages as images:
                 * We should not send preload requests for the pages up to the page numbers mentioned in the info json response
                 *
                 * Note: The preloading is memory-cached by the browser anyway and there is only one single request for the
                 * fullsized images so there is no need to explicitly stop the fullpage preloading. self could be fixed
                 * later for consistency
                 */
                if (data.precreate) {
                    self.onlyPreloadThumbnailsAfterPage = data.precreate[IMAGE_SIZE_THUMBNAIL];
                }

                let needToUpdateThumbnailView = false;

                // check if we got new data on the pages...
                for (let i = 1; i < self.pages.length; i++) {
                    if (data.page_details) {
                        let pageData = data.page_details[i];
                        // got some details on self page?
                        if (pageData) {
                            let page = self.pages[i];

                            if (pageData.text) {
                                page.text = true; // we can get text overlays for self page
                            }

                            if (pageData.foreign_id != void 0) {
                                page.foreign_id = pageData.foreign_id;
                                page.setThumbnail(5); // new thumbnail state to indicate that we need to link to a different document

                                self.attachments.push(page);
                            }

                            // if page didn't have information about it's thumbnail yet, and server says he got data - let's check it!
                            if (page.thumbnail < 1 && pageData.dimensions) {
                                if (pageData.dimensions[IMAGE_SIZE_THUMBNAIL]) {
                                    // got info on thumbnail for self page
                                    if (pageData.dimensions[IMAGE_SIZE_THUMBNAIL].status == "successful") {
                                        // server got a thumbnail picture
                                        page.setThumbnailDimensions(pageData.dimensions[IMAGE_SIZE_THUMBNAIL].width, pageData.dimensions[IMAGE_SIZE_THUMBNAIL].height);
                                        page.setThumbnail(1);
                                        page.thumbnail_timestamp = self.globalTimestamp; // pageData.dimensions[IMAGE_SIZE_THUMBNAIL].timestamp;
                                        //needToUpdateThumbnailView = true; --> SonarQube Error
                                    } else {
                                        // server failed to make a thumbnail picture
                                        page.setThumbnail(-1);
                                    }
                                }
                            }

                            // don't got a full page view yet? check if server says we can get one
                            //if( ! page.hasFullView){
                            if (page.fullView == false || page.fullView == -1) {
                                if (serverFinished && (!pageData.dimensions || !pageData.dimensions[IMAGE_SIZE_MIDDLE])) {
                                    // server couldn't make a full view (but we might have a thumbnail...
                                    page.setFullView(-1);
                                }

                                if (pageData.dimensions) {
                                    if (pageData.dimensions[IMAGE_SIZE_MIDDLE]) {
                                        // got info on full page view for self page
                                        if (pageData.dimensions[IMAGE_SIZE_MIDDLE].status == "successful") {
                                            // server got a full page view picture
                                            page.setFullViewDimensions(pageData.dimensions[IMAGE_SIZE_MIDDLE].width, pageData.dimensions[IMAGE_SIZE_MIDDLE].height);
                                            page.setFullView(true);
                                            page.image_timestamp = self.globalTimestamp; // =pageData.dimensions[IMAGE_SIZE_MIDDLE].timestamp;
                                        } else {
                                            // server failed to make a big picture
                                            page.setFullView(false);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                if (needToShowNavigationBar) {
                    self.view.showNavigationBar();
                }

                //  ====================================================
                // we are done processing the data, now we try to figure out if we need to do / show something
                // if we know the page count we can do render something so the user doesn't get bored
                if (self.pages.length > 1) {
                    // there are pages.. so we need to show the "page loading" view and optionally try to display the currently selected page

                    // note: we should only try to display the currently selected page if it's different from the page we were trying to access last time
                    // or if it's the same page and we got an update for it

                    let page = self.getPage(self.currentPage);
                    // todo: check if we already tried to display the current page...

                    if (forceUpdate || self.view.lastPage != self.currentPage || (self.view.lastPage == self.currentPage && page.fullView && !self.view.lastPageRendered)) {
                        // only try to show the page again, if we got an update for it...

                        if (!self.waitForSearch) {
                            // if we do not show a full page view yet, display a page (usually page 1 if the user didn't already select another page)
                            self.goToPage(self.currentPage);
                            self.isErrorShowing = false;
                            self.isPreviewReady = true;
                        }
                    }
                }

                let serverIsStillWorking = data.status == "processing";

                if (serverIsStillWorking) {
                    let currentPage = self.getPage(self.currentPage);
                    if (currentPage != void 0 && currentPage.fullView == true) {
                        self.hideLoadingAnimation();
                    } else {
                        self.showLoadingAnimation();
                        // ask again later
                        //////##########################################
                        setTimeout(() => {
                            self.refresh(self.documentId, self.isForeign);
                        }, self.queryInterval);
                    }
                } else {
                    self.hideLoadingAnimation();
                }
            };

            self.hideLoadingAnimation = function() {
                self.layout.view.fadeIn("slow");
                self.layout.toolbar.fadeIn("slow");
                self.layout.footerContainer.fadeIn("slow");
                self.isLoadingAnimationVisible = false;
                self.globalLoadingAnimation.hide();
            };

            self.showLoadingAnimation = function() {
                self.layout.view.hide();
                self.layout.toolbar.hide();
                self.layout.footerContainer.hide();
                self.isLoadingAnimationVisible = true;
                self.globalLoadingAnimation.show();
            };

            self.getPage = function(number) {
                if (number < 0 || number > self.pages.length) {
                    return false;
                }
                return self.pages[number];
            };

            self.preloadPage = function(number, isSilent) {
                let page = self.getPage(Number(number));
                //var nextPage = self.getPage(Number(number) + 1);

                if (page == void 0) {
                    return;
                }

                if (page.fullView == true || preloadScope[number] != void 0) {
                    return; // already created or requested
                }

                let fullURL = `${ProfileService ? ProfileService.getCurrentBaseUrl() : ""}/osrenditioncache${imageURLLayout.replace(/IDIDID/g, self.documentId)}`;
                fullURL = fullURL.replace(/PAGEPAGEPAGE/g, number);
                fullURL = fullURL.replace(/SIZESIZESIZE/g, IMAGE_SIZE_MIDDLE);
                if (self.image_timestamp) {
                    fullURL = fullURL.replace(/TIMESTAMPTIMESTAMP/g, self.image_timestamp);
                } else {
                    fullURL = fullURL.replace(/TIMESTAMPTIMESTAMP/g, self.globalTimestamp);
                }

                preloadScope[number] = $rootScope.$new();
                preloadScope[number].success = handlePreloadSuccess;
                preloadScope[number].error = handlePreloadFailure;

                let img = angular.element(`<img silent='${isSilent}' page='${number}' src='${fullURL}'/>`);

                img.bind("load", function(e) {
                    let image = angular.element(this);
                    let pageNumber = image.attr("page");

                    let page = self.getPage(Number(number));
                    let isSilent = image.attr("silent");

                    page.setFullViewDimensions(image.width(), image.height());
                    page.fullView = true;

                    preloadScope[pageNumber].$destroy();

                    delete preloadScope[pageNumber];

                    image.remove();

                    if (isSilent == "false" && number == self.currentPage) {
                        self.view.showPage(pageNumber);
                    }
                });

                angular.element(document.body).append($compile(img)(preloadScope[number]));
            };

            function handlePreloadSuccess(type, o) {
                if (type == "full") {
                    //self.setFullViewDimensions($(o).width(), $(o).height());
                    self.fullView = true;

                    self.viewer.askForData();
                } else if (type == "thumbnail") {
                    // refresh the thumbnail view
                    self.thumbnail = 1;
                    self.view.showThumbnails();
                }
            }

            function handlePreloadFailure(type) {
                if (type == "full") {
                    self.fullView = -2;
                    self.view.showPage(self.number);
                } else if (type == "thumbnail") {
                    self.thumbnail = -1;
                    self.view.showThumbnails();
                }
            }
        }

    }
})();
