import {Component, Input} from "@angular/core";
import {GridHeaderCellComponent} from "MODULES_PATH/grid/components/grid-header-cells/grid-header-cell/grid-header-cell.component";
import {OnInit, Type} from "@angular/core";
import {GridHeaderCellInterface} from "../../../interfaces/grid-header-cell.interface";
import {GridIconCellParamsInterface} from "MODULES_PATH/grid/interfaces/grid-icon-cell-params.interface";
import {IHeaderParams} from "ag-grid-community";

@Component({
    selector: "eob-icon-header-cell-content",
    templateUrl: "./icon-header-cell-content.component.html",
    styleUrls: ["./icon-header-cell-content.component.scss"]
})
export class IconHeaderCellContentComponent implements OnInit, GridHeaderCellInterface {
    @Input() params: IHeaderParams;

    icon: string;
    title: string;

    ngOnInit(): void {
        const value: GridIconCellParamsInterface = this.params.column.getColDef().headerComponentParams;

        if (value) {
            this.icon = value.icon;
        }
        this.title = value?.title || "";
    }
}

@Component({
    templateUrl: "../grid-header-cell/grid-header-cell.component.html",
    styleUrls: ["../grid-header-cell/grid-header-cell.component.scss"]
})
export class IconHeaderCellComponent extends GridHeaderCellComponent {
    protected readonly cellContent: Type<GridHeaderCellInterface> = IconHeaderCellContentComponent;
}
