import {Subscription} from "rxjs";
import {throttleTime, takeWhile} from "rxjs/operators";
import {ObjectSyncStatus} from "ENUMS_PATH/offline/offline-object-sync-status.enum";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

angular.module("eob.framework").directive("eobModalOfflineInfo", EobModalOfflineInfo);

EobModalOfflineInfo.$inject = ["fileCacheService", "offlineService", "backendService", "$filter", "clientService", "objectTypeService", "messageService"];

/**
 * A directive for a simple confirm dialog.
 * @scopeparam {string} msg - The message text.
 * @scopeparam {string} htmlmsg - The message text in html form.
 * @scopeparam {Object} deferred - The deferred object to resolve or reject the dialog.
 * @scopeparam {Object} buttons - May contain cancel, submit, ok button texts.
 */
function EobModalOfflineInfo(FileCacheService, OfflineService, BackendService, $filter, ClientService, ObjectTypeService, MessageService) {
    return {
        restrict: "E",
        scope: {
            destroy$: "=destroy"
        },
        link(scope, element) {
            let spaceSubscription = new Subscription();
            let timeoutConfigHolder;

            setTimeout(async() => {
                const osids = await FileCacheService.getOfflineDataTable().toCollection().primaryKeys();

                document.querySelector("span#cache-size").innerHTML = await FileCacheService.getIndexdataSpaceUsage(osids);

                const renditionSizeSpan = document.querySelector("span#overall-rendition-size");
                let overallRenditionSize = 0;
                const overallSpace$ = FileCacheService.getOverallContentSpaceUsage(osids);
                spaceSubscription.add(overallSpace$.subscribe({
                    next: value => {
                        overallRenditionSize += value;
                    }, complete: () => {
                        renditionSizeSpan.innerHTML = FileCacheService.niceBytes(overallRenditionSize);
                    }
                }));
                spaceSubscription.add(overallSpace$.pipe(throttleTime(100)).subscribe(value => {
                    renditionSizeSpan.innerHTML = `${FileCacheService.niceBytes(overallRenditionSize)}...`;
                }))

                const docCountSpan = document.querySelector("span#document-count")
                const failedCountSpan = document.querySelector("span#failed-count")
                const freeSpaceSpan = document.querySelector("span#free-space")
                freeSpaceSpan.innerHTML = FileCacheService.niceBytes(await FileCacheService.getFreeSpace())

                let documentCount = 0, doneDocumentCount = 0, failedDocumentCount = 0;
                await FileCacheService.getOfflineDataTable().toCollection().each(offlineData => {
                    if (ObjectTypeService.isDocumentType(offlineData.objectTypeId)) {
                        documentCount++;
                        if (offlineData.sync === ObjectSyncStatus.DONE) {
                            doneDocumentCount++;
                        } else if (offlineData.isSyncFailed()) {
                            failedDocumentCount++;
                        }
                    }
                });

                if(ClientService.isOnline()) {
                    let docIds = new Set();
                    timeoutConfigHolder = BackendService.getCancelableHttpConfig();
                    const result = await OfflineService.getBackendOfflineObjects(timeoutConfigHolder);
                    const countDocuments = (offlineObjects = []) => offlineObjects.forEach(offlineObject => {
                        if (ObjectTypeService.isDocumentType(offlineObject.objectTypeId)) {
                            docIds.add(offlineObject.id);
                        } else if ((offlineObject.children || []).length > 0) {
                            countDocuments(offlineObject.children);
                        }
                    });
                    countDocuments(result);
                    documentCount = docIds.size;
                }

                docCountSpan.innerHTML = $filter("translate")("eob.sync.info.dialog.document.count.text")
                .replace("[%s1]", documentCount)
                .replace("[%s2]", doneDocumentCount);

                failedCountSpan.innerHTML = failedDocumentCount;
            });

            /**
             * Close the dialog once it is canceled.
             * @param button Which button has been pressed to cancel
             */
            scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

            /**
             * Remove the dialog content from the main dialog container once the dialog is destroyed.
             */
            scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => {
                spaceSubscription.unsubscribe();

                if (timeoutConfigHolder) {
                    timeoutConfigHolder.subject.complete();
                }
           });
        },
        template: require("!raw-loader!./eob.modal.offline.info.html")
    };
}
