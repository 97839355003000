<!--
                           (afterInit) = "setHeight($event)" [ngClass] = "{'mock-form': !layout || isPhone}" [formid]="formId">
-> doesn't compile:
 error TS2339: Property 'setHeight' does not exist on type 'EobFormComponent'.

4                            (afterInit) = "setHeight($event)" [ngClass] = "{'mock-form': !layout || isPhone}" [formid]="formId">
                                            ~~~~~~~~~

-> no method setHeight?!

-->

<eob-responsive-form-component
    [layout]="layout"
    [formdata]="formData"
    [formhelper]="formHelper"
    (onformready)="throwFormReady()"
    [ismockform]="formdef.isMockForm || isPhone"
    [redrawsubject]="redrawBaseParamsSubject"
    [ngClass]="{ 'mock-form': !layout || isPhone }"
    [formid]="formId"
    [validationBubble]="bubble"
>
</eob-responsive-form-component>
<eob-standalone-validation-bubble
    #bubble
    [enablePositioning]="true"
></eob-standalone-validation-bubble>
