import {Component, ElementRef, forwardRef, Inject, Input, NgZone, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {Field} from "INTERFACES_PATH/field.interface";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Layout} from "INTERFACES_PATH/layout.interface";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {TodoEnvironmentService, TodoFormHelper} from "INTERFACES_PATH/any.types";
import {take, skip} from "rxjs/operators";

@Component({
    selector: "eob-form",
    templateUrl: "./eob-form.component.html",
    styleUrls: ["./eob-form.component.scss"]
})
export class EobFormComponent implements OnInit, OnDestroy {
    @Input() formdef: any;
    @Input() dataready: boolean;

    redrawBaseParamsSubject: Subject<any> = new Subject();
    layout: Layout;
    formData: Field[] = [];

    formFields: Field[] = [];
    isMainForm: boolean = false;
    formHelper: TodoFormHelper;
    isPhone: boolean;
    resizeObserver: ResizeObserver;
    formId: string = Math.random().toString(16).substr(2, 8);
    lastWidth = 0;
    resizeSub = new Subject<number>();

    private subscriptions: Subscription = new Subscription();

    // eslint-disable-next-line max-params
    constructor(@Inject(forwardRef(() => ClientService)) public clientService: ClientService,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                protected layoutManagerService: LayoutManagerService,
                public messageService: MessageService,
                public renderer: Renderer2,
                public el: ElementRef,
                public ngZone: NgZone) {
        this.subscriptions.add(this.messageService.subscribe(FormEvent.REDRAW_BASEPARAM_FORM, () => {
            if (this.formdef.isBaseParamDef) {
                this.detachResizeSensor();
                this.buildBaseParamForm();
            }
        }));
    }

    ngOnInit(): void {
        let formWidth: number;
        this.formHelper = this.formdef.formHelper;
        this.isPhone = this.clientService.isPhone();
        this.resizeSub.subscribe((widthDiff) => {
            this.formHelper.executeFormResize(widthDiff);
        });

        this.resizeObserver = new ResizeObserver(mutationList => {
            if(!this.lastWidth) {
                this.lastWidth = mutationList[0].contentRect.width;
            }
            this.resizeSub.next(Math.abs(this.lastWidth - mutationList[0].contentRect.width));
            this.lastWidth = mutationList[0].contentRect.width;
        });
        this.resizeObserver.observe(this.el.nativeElement);

        for (const formField of this.formdef.formFields) {
            this.formData[formField.internal] = this.formHelper.getFieldByInternal(formField.internal);
        }

        // performance log
        const start: number = +new Date();

        this.buildBaseParamForm();

        this.layout = this.formdef.formLayout;

        if (this.layout && this.layout.maxWidth != void 0 && !this.isPhone) {
            const minHeight: string = this.layoutManagerService.isTouchLayoutActive() ? `${this.layout.rows.length * 20 + 16}px` : `${this.layout.rows.length * 16 + 16}px`;
            this.renderer.setStyle(this.el.nativeElement, "max-width", `${this.layout.maxWidth}px`);
            this.renderer.setStyle(this.el.nativeElement, "margin", "auto");
            if (!this.clientService.isPhone()) {
                this.renderer.setStyle(this.el.nativeElement, "min-height", minHeight);
            }
        }

        // performance log to find performance related issues faster in forms
        const end: number = +new Date();
        if (end - start > 150) {
            console.warn("building form took more than 150 ms ", `${end - start} ms`, " this might be a performance issue!");
        }
    }

    detachResizeSensor(): void {
        this.resizeObserver.disconnect();
    }

    throwFormReady(): void {
        const formDataGrids: Field[] = Object.values(this.formData).filter(element => element.model.type==="grid");

        if (formDataGrids.length > 0) {
            this.subscriptions.add(this.formHelper.gridReady$.pipe(skip(formDataGrids.length - 1), take(formDataGrids.length)).subscribe(()=>{
                this.messageService.broadcast(FormEvent.FORM_READY);
            }));
        } else {
            this.messageService.broadcast(FormEvent.FORM_READY);
        }
    }

    buildBaseParamForm(): void {
        if (this.redrawBaseParamsSubject) {
            this.redrawBaseParamsSubject.next(this.formdef);
        }
    }

    ngOnDestroy(): void {
        $(".xdsoft_datetimepicker").remove();
        this.detachResizeSensor();
        this.subscriptions.unsubscribe();
        this.resizeSub.complete();
    }
}
