import {Observable} from "rxjs";
import {BackendVariantObject} from "CORE_PATH/backend/interfaces/backend-variant-object.interface";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {Dms2VariantsService} from "CORE_PATH/backend/modules/dms2/services/variants/dms2-variants.service";
import {OsRestVariantsService} from "CORE_PATH/backend/modules/osrest/services/variants/osrest-variants.service";
import {BackendBaseService} from "CORE_PATH/backend/services/base/backend-base.service";

@Injectable({providedIn: BackendModule})
export class BackendVariantsService extends BackendBaseService<OsRestVariantsService | Dms2VariantsService> {

    constructor(messageService: MessageService, dms2VariantsService: Dms2VariantsService, osRestVariantsService: OsRestVariantsService) {
        super(messageService, dms2VariantsService, osRestVariantsService);
    }

    loadVariants(osId: string, objectTypeId: string) : Observable<BackendVariantObject[]> {
        return this.delegate.loadVariants(osId, objectTypeId);
    }

    activateVariant(currentActiveOsId: string, newActivOsId: string): Observable<void> {
        return this.delegate.activateVariant(currentActiveOsId, newActivOsId);
    }

    createVariant(osId: string, objectTypeId: string, type: string): Observable<string> {
        return this.delegate.createVariant(osId, objectTypeId, type);
    }
}
