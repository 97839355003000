(function () {
    "use strict";

    angular.module("eob.core").factory("desktopModelService", DesktopModelService);

    DesktopModelService.$inject = [];

    function DesktopModelService() {

        return {
            createDesktopEntry
        };

        function createDesktopEntry(desktopItemData) {

            switch (desktopItemData.type) {
                case "FOLDER":
                    return new DesktopFolder(desktopItemData);
                case "OBJECT":
                    return new DesktopDocument(desktopItemData);
                case "QUERY":
                    return new DesktopQuery(desktopItemData);
                case "APP" :
                    return new DesktopAppReference(desktopItemData);
                case "PORTFOLIO":
                    return new DesktopPortfolio(desktopItemData);
            }

        }

        function DesktopFolder() {

        }

    }
})();