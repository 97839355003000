import {Subject} from "rxjs"
import {takeUntil} from "rxjs/operators"
import {DropzoneMessage} from "MODULES_PATH/dropzone/components/ems-dropzone/ems-dropzone.component";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {TimerDoneType} from "../../../../app/modules/timer/enums/timer-done-type";
import {ElectronIpcEvent} from "ENUMS_PATH/electron-ipc-renderer-event-type.enums";
import {ViewerEvent} from "../../../../app/modules/dashlet/enums/viewer-event.enum";
import * as dayjs from "dayjs"
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import {ProfileCacheKey} from "../../../../app/shared/enums/database/profile-cache-key.enum";
dayjs.extend(customParseFormat);
require("COMPONENTS_PATH/eob-modal-container/eob-modal-maintype/eob.modal.maintype.dir.js");

require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/form/eob.form.srv.js");
require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");
require("SERVICES_PATH/eob.modal.dialog.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

angular.module("eob.framework").directive("eobCreate", EobCreate);

EobCreate.$inject = ["$rootScope", "$compile", "$stateParams", "$q", "$timeout", "$filter", "$location", "fileCacheService", "toolService",
    "objectTypeService", "backendService", "formService", "formHelper", "dmsDocumentService", "modalDialogService", "externalTrayService",
    "viewerService", "environmentService", "stateHistoryManager", "progressbarService", "clientService", "cacheManagerService", "notificationsService",
    "messageService", "modalDialogInjectorService", "timerService", "progressService", "dmsActionService"];

/* eslint-disable */
/**
 * This directive is the wrapping directive for the indexdata modify
 * Its purpose is to get the Objecttype definition and further details
 * needed for this state (see /eob-core/state.history.manager.srv.js)
 */
export default function EobCreate($rootScope, $compile, $stateParams, $q, $timeout, $filter, $location, FileCacheService, ToolService,
                                  ObjectTypeService, BackendService, FormService, FormHelper, DmsDocumentService, ModalDialogService, ExternalTrayService,
                                  ViewerService, EnvironmentService, StateHistoryManager, ProgressbarService, ClientService, CacheManagerService, NotificationsService,
                                  MessageService, ModalDialogInjectorService, TimerService, ProgressService, DmsActionService) { /* eslint-enable */
    return {
        restrict: "E",
        async link(scope, element) {
            let objectType = CacheManagerService.objectTypes.getById($stateParams.objectTypeId);
            const unsubscriber = new Subject();
            let progressbar;
            let metadata;
            let preventSubmissionWithHint = false;
            let stateParamsMode = $stateParams.mode;
            let saveDisabled = false;

            // Todo DODO-13393
            // FormService.setBeforeCancelExecuted({isExecuted: false, formHelper: FormService.getBeforeCancelExecuted().formHelper});

            scope.createResult$ = new Subject();
            scope.isTimerRunning = TimerService.isRunning();
            scope.isEmsType = evaluateEmsType(objectType.model.config.isEmsType, objectType.model.config.mainType, $stateParams.mode);
            scope.isPhone = ClientService.isPhone();

            scope.stateTitle = "";
            scope.stateDescription = "";

            scope.scriptsReady = false;

            scope.showDropzone = false;
            scope.readonlyDropzone = false;
            EnvironmentService.clearDropzoneContent();

            scope.formHelper = null;
            scope.formLayout = null;

            scope.isPhone = ClientService.isPhone();
            scope.isDropzoneOpen = false;

            ViewerService.clearViewer();

            let autosavedData = null;
            if ($location.search().restoreAutosave == "true") {
                autosavedData = await FileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, ProfileCacheKey.AUTOSAVED_DATA, { first: true })
            }

            initFooterConfig();
            initProgressbar();

            let state = StateHistoryManager.getStateData($stateParams.state);

            let dmsLocationTypeId = $stateParams.parentTypeId != void 0 ?
                $stateParams.parentTypeId :
                ((state || {}).data || {}).targetTypeId;

            let helperConfig = {
                modelDef: null,
                formData: null,
                isCreate: true,
                isVariant: $stateParams.mode === "variants",
                isReference: $stateParams.mode == "reference",
                dmsLocationId: $stateParams.targetId,
                dmsLocationTypeId,
                save: FormService.insertDocument,
                cancel: FormService.cancel,
                progressbar,
                createResult$: scope.createResult$,
                saveDisabled
            };

            if ($stateParams.groupKey) {
                metadata = await ExternalTrayService.getTrayElementByTrayIdAsync($stateParams.groupKey);
            }

            startTimerAfterPageReloading();

            if (TimerService.isRunning()) {
                ProgressService.finished.next(false);
                TimerService.done().subscribe(async x => {
                    if (x === TimerDoneType.CREATE_VIEW_CANCEL) {
                        await FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, "", metadata.groupKey);
                        ClientService.broadcastTrayItemsChanged();
                        ProgressService.finished.next(true);
                    }
                });
            }

            let dmsDocument = null;

            switch ($stateParams.mode) {
                case "copy" :
                    showViewer(false);
                    scope.stateTitle = $filter("translate")("eob.create.state.copy.title");
                    break;
                case "new" :
                case "inwftray":
                    showViewer(false);
                    scope.stateTitle = $filter("translate")("eob.create.state.new.title");
                    break;
                case "typeless" :
                    scope.stateTitle = $filter("translate")("eob.create.state.typeless.title");
                    break;
                case "reference" :
                    showViewer(false);
                    scope.stateTitle = $filter("translate")("eob.create.state.reference.title");
                    break;
                case "variants" :
                    showViewer(false);
                    scope.stateTitle = $filter("translate")("eob.create.state.variant.title");
                    break;
            }

            const formReadySubscription = MessageService.subscribeFirst(FormEvent.FORM_READY, async () => {
                try {
                    scope.formHelper.focusFirstField();
                    await FormService.executeFormScript("beforeOpen", scope.formHelper);
                    if (progressbar) {
                        progressbar.hide();
                        progressbar = null;
                    }

                    scope.scriptsReady = true;
                } catch (e) {
                    console.error(e);
                    goBack()
                }
            });

            scope.$on("dropzone.content.changed", enableSaveButton)
            scope.$on("dropzone.content.queued", disableSaveButton)

            scope.formFields = objectType.api.getFields(true);
            scope.stateDescription = objectType.model.config.title;
            helperConfig.modelDef = objectType.model;

            function initFooterConfig() {
                scope.footerConfigs = [{
                    icon: { name: "footer-back-dark", title: $filter("translate")("form.footer.back") },
                    action: "back",
                    class: "footer-button secondary-button"
                }, {
                    icon: { name: "footer-save", title: $filter("translate")("form.footer.save") },
                    action: "submit",
                    class: "footer-button save-button"
                }]
            }

            function initProgressbar() {
                progressbar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);

                setTimeout(() => {
                    if (progressbar != void 0) {
                        progressbar.show();
                    }
                }, 50);
            }

            async function startTimerAfterPageReloading() {
                if (ClientService.isDesktop() && !TimerService.isRunning()) {
                    const remainingTime = sessionStorage.getItem("remainingTimerTime");
                    const isReusedLocationTab = sessionStorage.getItem("reusedLocationTab");
                    if (remainingTime) {
                        const seconds = Number(remainingTime) - 10;
                        state.data.config.userAction = "wcf";

                        await FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, "", metadata.groupKey);
                        ClientService.broadcastTrayItemsChanged();

                        if (Math.sign(seconds) > 0) {
                            if (isReusedLocationTab === "true") {
                                window.electron.setIsReusedLocationTab(true);
                            }

                            TimerService.start(seconds);

                            const detail = { detail: { metadata, data: { objectTypeId: metadata.parentObjectTypeId, osid: metadata.parentObjectId }}};
                            window.dispatchEvent(new CustomEvent(ElectronIpcEvent.ADD_EXTERNAL_TRAY_ITEM, detail));
                        } else {
                            if (isReusedLocationTab === "true") {
                                sessionStorage.removeItem("remainingTimerTime");
                                sessionStorage.removeItem("reusedLocationTab");
                                localStorage.removeItem("metadataGroupKey");
                                window.electron.setIsReusedLocationTab(true);
                                window.electron.storeSessionStorage();
                                window.dispatchEvent(new CustomEvent(ElectronIpcEvent.AFTER_TAB_RELOAD, { detail: { timerDoneType: TimerDoneType.TIMEOUT } }));
                                showViewer(true);
                                await DmsActionService.openLocation(false, undefined, undefined, metadata.parentObjectId, metadata.parentObjectTypeId);
                                return;
                            }

                            sessionStorage.removeItem("remainingTimerTime");
                            sessionStorage.removeItem("reusedLocationTab");
                            localStorage.removeItem("metadataGroupKey");
                            window.electron.storeSessionStorage();
                            window.dispatchEvent(new CustomEvent(ElectronIpcEvent.AFTER_TAB_RELOAD, { detail: { timerDoneType: TimerDoneType.TIMEOUT } }));
                            setTimeout(() => window.electron.closeActiveTab(), 0);
                        }
                    }
                }
            }

            // check if there are cached scripts
            if (objectType.api.getTypeScripts() == null) {
                // if not, we need to get them and store them
                try {
                    let response = await BackendService.get(`/documents/scripts?objecttypeid=${$stateParams.objectTypeId}&clienttype=web`)
                    scope.eventScripts = response.data;
                    objectType.api.setTypeScripts(response.data);
                } catch (e) {
                    showScriptsErrorDialog();
                    return;
                }
            } else {
                // if so, we use them
                scope.eventScripts = objectType.api.getTypeScripts();
            }

            if ($stateParams.mode == "copy" || $stateParams.mode == "variants" || $stateParams.mode == "typeless" || $stateParams.mode == "reference") {
                let response;
                try {
                    response = await BackendService.get(`/documents/search/${$stateParams.id}?refresh=true`)
                } catch (error) {
                    console.warn(error);
                    NotificationsService.error($filter("translate")("eob.object.load.error"))
                    $timeout(() => {
                        ClientService.executeStateErrorFallback();
                    }, 100);
                    return;
                }

                dmsDocument = CacheManagerService.dmsDocuments.get(CacheManagerService.dmsDocuments.add(response.data))[0];

                helperConfig.dmsData = dmsDocument;

                let item = response.data;

                if (item != void 0) {
                    setTimeout(() => {
                        ViewerService.updateViewer(item.osid, dmsDocument);
                    }, 0);
                }
            }

            let formData = FormService.createFormData(scope.formFields);

            helperConfig.formData = formData;

            let metadataFields = {};
            if (metadata && metadata.indexData) {
                metadataFields = metadata.indexData.fields;
            }

            async function getEmailExtractionDataViaEms(fileData, objectTypeId) {
                let emsExtractionData = {};

                let response = await FormService.getMailExtraction(fileData, objectTypeId)
                if (Array.isArray(response.data.objects) && response.data.objects[0] && response.data.objects[0].options) {
                    const fieldValues = response.data.objects[0].options["ems:target:enaio:fields"].value;
                    for (let key in fieldValues) {
                        const field = formData[key];
                        let fieldValue = fieldValues[key];
                        if (fieldValue == null || fieldValue == void 0) {
                            continue;
                        }

                        switch(field.model.type) {
                            case "datetime":
                                // EMS input format for datetime values: DD.MM.YYYY HH:mm:ss
                                fieldValue = dayjs(fieldValue, "DD.MM.YYYY HH:mm:ss").format(EnvironmentService.env.dateFormat.datetime);
                                break;
                            case "date":
                                // EMS input format for date values: DDMMYYYY
                                fieldValue = dayjs(fieldValue, "DDMMYYYY").format(EnvironmentService.env.dateFormat.date);
                                break;
                            default:
                                break;
                        }

                        emsExtractionData[key] = fieldValue;
                    }
                }

                return emsExtractionData;
            }

            scope.dropIt = async function(fileList) {
                try {
                    disableSaveButton()

                    const emsExtractionData = await getEmailExtractionDataViaEms(fileList[0], $stateParams.objectTypeId);
                    for (let key in emsExtractionData) {
                        const field = scope.formHelper.getFieldByInternal(key);

                        if(field && field.api) {
                            field.api.setValue(emsExtractionData[key]);
                        } else {
                            console.warn("Field API missing for field", field)
                        }
                    }

                    MessageService.broadcast(DropzoneMessage.DROPZONE_EMS_EXTRACTION_FINISHED, { file: fileList[0], successful: true })
                    enableSaveButton()
                } catch (error) {
                    console.warn("Error during extraction", error)
                    MessageService.broadcast(DropzoneMessage.DROPZONE_EMS_EXTRACTION_FINISHED, { file: fileList[0], successful: false })
                }
            }

            if ($stateParams.groupKey) {
                // handle tray item with group key
                if (metadata && metadata.trayItemType == "insertEmails") {
                    if (metadata.objects.length == 1) {
                        const filePath = metadata.objects[0].properties.filePath.value;
                        const fileContent = await ClientService.readDataFromFileAsync(filePath, false);
                        const fileData = new Blob([fileContent]);
                        fileData.name = filePath.split(/[/\\]/).pop();
                        let emsExtractionData = {};
                        try {
                            emsExtractionData = await getEmailExtractionDataViaEms(fileData, metadata.objectTypeId);
                        } catch (e) {
                            NotificationsService.error($filter("translate")("eob.ems.not.available"));
                            helperConfig.saveDisabled = true;
                        }

                        let additionalFormData = {
                            fields: {}
                        }

                        for (let key in emsExtractionData) {
                            additionalFormData.fields[key] = emsExtractionData[key];
                        }

                        FormService.addIndexData(formData, additionalFormData, true);
                    } else {
                        scope.stateDescription = `${scope.stateDescription} - ${$filter("translate")("eob.create.multiple.mail.description")}`;
                    }
                } else if (metadataFields && Object.keys(metadataFields).length > 0) {
                    // handle field values given by metadata.indexData.fields
                    FormService.addIndexData(formData, metadata.indexData, true);
                } else if ($stateParams.mode == "new") {
                    FormService.prefillFormData(formData);
                } else if ($stateParams.mode == "variants") {
                    FormService.addIndexData(formData, dmsDocument.model);
                }
            } else if ($stateParams.mode == "new" || $stateParams.mode == "typeless" || $stateParams.mode == "inwftray") {
                FormService.prefillFormData(formData);
            } else if (dmsDocument) {
                FormService.addIndexData(formData, dmsDocument.model);
            }

            await FormService.restoreAutosavedIndexdata(formData)
            await FormService.disableFieldsAsync(formData);
            disableSaveButton();

            try {
                let promise = getMainTypeAsync();

                if ($stateParams.mode == "inwftray") {
                    helperConfig.submit = FormService.insertToWfTray;
                } else if ($stateParams.mode == "typeless") {
                    helperConfig.save = FormService.typeDocument;
                } else if ($stateParams.mode == "reference") {
                    helperConfig.save = FormService.insertDocument;
                } else if (metadata && metadata.trayItemType == "insertEmails" && state.data.config.userAction === "wcf") {
                    helperConfig.save = FormService.insertEmails;
                } else {
                    helperConfig.save = FormService.insertDocument;
                }

                scope.formHelper = FormHelper.getFormHelper(helperConfig);

                let mainType = await promise;

                scope.isEmsType = evaluateEmsType(objectType.model.config.isEmsType, mainType, $stateParams.mode);
                if (scope.isEmsType) {
                    MessageService.subscribe(DropzoneMessage.DROPZONE_CONTENT_UPDATED, value => {
                        if (value.length == 0) {
                            preventSubmissionWithHint = true;
                        } else {
                            preventSubmissionWithHint = false;
                        }
                    }, takeUntil(unsubscriber));

                    if (!$stateParams.groupKey) {
                        preventSubmissionWithHint = true;
                    }

                    const originalSubmit = scope.formHelper.submit;
                    const interceptSubmit = async () => {
                        if (preventSubmissionWithHint) {
                            NotificationsService.info($filter("translate")("dropzone.file.required.for.creation"))
                        } else {
                            await originalSubmit();
                        }
                    }

                    scope.formHelper.submit = interceptSubmit;
                } else if(objectType.model.config.mainType == 6 && $stateParams.mode == "copy") {
                    NotificationsService.info($filter("translate")("eob.action.create.copy.mail.mapping.missing"))
                }

                await scope.formHelper.bindScripts(scope.eventScripts);

                scope.formDef = {
                    isMainForm: true,
                    validationMode: "max",
                    formFields: scope.formFields,
                    formHelper: scope.formHelper,
                    formLayout: objectType.api.getLayout()
                };

                FormService.initFormAutoSave(scope.formHelper);
                if (!$stateParams.groupKey || !scope.isEmsType) {
                    initDropzone(mainType);
                }
                scope.dataReady = true;
                scope.$apply()
            } catch (e) {
                console.error(e);
                goBack()
            }

            /**
             * Get the mainType of the to be created dms object.
             * @returns {Promise<string>} Is resolved with the mainType.
             */
            async function getMainTypeAsync() {
                try {
                    let mainType = helperConfig.modelDef.config.mainType;
                    // if the new document is a multitype we need to ask the user
                    // as which type the document shall be inserted
                    // overwrite the default with the users decision
                    if ($stateParams.groupKey) {
                        if (helperConfig.modelDef.config.multiType) {
                            mainType = await _getSubTypeAsync(metadata.mainTypes);
                        }
                    } else if ($stateParams.mode == "new" || $stateParams.mode == "inwftray") {
                        if (helperConfig.modelDef.config.multiType) {
                            mainType = await _getSubTypeAsync();
                        }
                    } else if ($stateParams.mode == "typeless" || $stateParams.mode == "reference") {
                        mainType = dmsDocument.model.mainType;
                    } else {
                        mainType = dmsDocument.model.subType || dmsDocument.model.mainType;
                    }
                    return mainType;
                } catch (e) {
                    console.error(e);
                    goBack()
                }
            }

            function initDropzone(mainType) {
                scope.formHelper.updateConfig({ mainType });

                scope.dropzoneConfig = {
                    objectTypeId: objectType.model.osid,
                    mainType,
                    maxFileCount: 1,
                    allowedFileExtensions: ["eml"]
                };

                let typeConfig = CacheManagerService.objectTypes.getById(scope.dropzoneConfig.objectTypeId).model.config;
                let isDropZoneNeeded = true;

                if (scope.dropzoneConfig.mainType == "0" || scope.dropzoneConfig.mainType == "99") {
                    // this is a register or a folder --> no dropzone needed
                    isDropZoneNeeded = false;
                } else if (!typeConfig.rights.objModify || typeConfig.reference || typeConfig.withoutPages) {
                    // no rights to insert a file
                    if ($stateParams.mode == "variants") {
                        NotificationsService.info($filter("translate")("eob.action.process.missing.write.rights.info"))
                        scope.readonlyDropzone = true
                    } else {
                        isDropZoneNeeded = false;
                    }
                } else if (scope.dropzoneConfig.mainType == "8") {
                    // container documents are not allowed yet
                    isDropZoneNeeded = false;
                }

                if (!isDropZoneNeeded) {
                    enableSaveButton();
                    return;
                }

                if (autosavedData != void 0) {
                    if (autosavedData.files) {
                        $timeout(() => {
                            for (let file of autosavedData.files) {
                                dropzone.addFile(file)
                            }
                        }, 0)
                    }

                    scope.dropzoneConfig.mode = autosavedData.dropzoneContent.mode
                    scope.dropzoneConfig.files = autosavedData.dropzoneContent.files
                    scope.dropzoneConfig.template = autosavedData.dropzoneContent.template
                    scope.dropzoneConfig.templateConfiguration = autosavedData.dropzoneContent.templateConfiguration
                } else if ($stateParams.groupKey != void 0) {
                    MessageService.subscribe(DropzoneMessage.DROPZONE_INITIALISED, async () => {
                        dropzone.on("removedfile", () => {
                            $stateParams.originalDropzoneContentRemoved = true;
                        })
                        for (let fileName of metadata.fileNames) {
                            let file = await FileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, fileName, {
                                group: metadata.groupKey,
                                first: true
                            });

                            if (file == void 0) {
                                continue;
                            }

                            // creating a file is the better solution since the dropzone does not really work with blobs so far
                            let fileOfBlob = ToolService.blob2file(new Blob([file], { type: "application/octet-stream" }), fileName)
                            dropzone.addFile(fileOfBlob);
                        }
                    }, takeUntil(unsubscriber));
                } else if ($stateParams.mode == "typeless" || $stateParams.mode == "reference") {
                    let clipboard = EnvironmentService.getClipboard();
                    scope.dropzoneConfig.id = clipboard.item.model.osid;
                    scope.loadExternal = true;
                    scope.readonlyDropzone = true;
                } else if ($stateParams.mode == "copy" || $stateParams.mode == "variants") {
                    scope.dropzoneConfig.id = $stateParams.id;

                    if (dmsDocument.model.hasContent) {
                        scope.loadExternal = true;
                    }
                }

                if (!scope.isEmsType && mainType == 6 && $stateParams.mode != "reference") {
                    // Non-mapped email types so great, dropzone not required
                    return;
                }

                scope.showDropzone = true;

                if (scope.isPhone) {
                    scope.$on("dropzone.content.changed", countAttachments);
                }
            }

            async function _getSubTypeAsync(possibleTypes) {
                let subTypeResolve = null, subTypeReject = null,
                    subTypePromise = new Promise((resolve, reject) => {
                        subTypeResolve = resolve;
                        subTypeReject = reject;
                    });

                if (autosavedData != void 0) {
                    subTypeResolve(autosavedData.chosenMaintype)
                    return subTypePromise
                }

                $timeout(() => {
                    let modalScope = $rootScope.$new();
                    modalScope.resolve = subTypeResolve;
                    modalScope.reject = subTypeReject;
                    modalScope.types = possibleTypes;
                    modalScope.destroy = new Subject();

                    ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                        input: {
                            title: $filter("translate")("eob.action.modal.maintype.title")
                        },
                        childElement: angular.element("<eob-modal-maintype types=\"types\" resolve=\"resolve\" reject=\"reject\" destroy=\"destroy\"></eob-modal-maintype>"),
                        scope: modalScope
                    });
                }, 0);

                return subTypePromise;
            }

            async function showScriptsErrorDialog() {
                let title = $filter("translate")("modal.confirm.dms.get.script.error.title");
                let msg = $filter("translate")("modal.confirm.dms.get.script.error.message");
                let cancel = $filter("translate")("modal.button.close");

                try {
                    await ModalDialogService.infoDialog(title, msg, cancel)
                } catch (e) {
                    console.error(e);
                    goBack()
                }
            }

            scope.$on("$destroy", () => {

                // Todo DODO-13393
                // if (stateParamsMode == "inwftray" && !FormService.getBeforeCancelExecuted().isExecuted) {
                //     FormService.executeFormScript("beforeCancel", FormService.getBeforeCancelExecuted().formHelper, false);
                // }

                stateParamsMode = "";
                angular.element(document.body).find(".dz-hidden-input, .fileInput").remove();
                MessageService.broadcast(ViewerEvent.SHOW_VIEWER, true);
                unsubscriber.next(true);
                scope.formHelper && scope.formHelper.destroy();

                formReadySubscription.unsubscribe();
            });

            function goBack() {
                setTimeout(() => {
                    ClientService.executeStateErrorFallback();
                }, 0)
            }

            function showViewer(showViewer = true) {
                $timeout(() => {
                    MessageService.broadcast("show.viewer", showViewer);
                }, 0);
            }

            function disableSaveButton() {
                MessageService.broadcast(FormEvent.DISABLE_SAVE_BUTTON);
            }

            function enableSaveButton() {
                let saveBtn = angular.element(".footer-button.save-button");
                if (saveBtn != void 0) {
                    saveBtn.removeClass("disabled");
                    saveBtn.removeAttr("disabled");
                }
            }

            scope.openDropzone = function() {
                let lastScrollTop;
                let dropzone = element.find(".dropzone-phone");
                let creationForm = element.find(".creation-form");
                let scrollContainer = element.find(".state-content");

                if (scope.isDropzoneOpen) {
                    scope.isDropzoneOpen = false;
                    countAttachments();
                    dropzone.removeClass("visible");
                    creationForm.removeClass("hidden");
                    scrollContainer.scrollTop(lastScrollTop)
                } else {
                    scope.isDropzoneOpen = true;
                    lastScrollTop = scrollContainer.scrollTop();
                    creationForm.addClass("hidden");
                    dropzone.addClass("visible");
                    element.find("#attachments-badge").hide();
                }
            }

            function countAttachments() {
                if (!scope.isDropzoneOpen) {
                    let dropzoneContent = EnvironmentService.getDropzoneContent();
                    let attachmentsBadge = element.find("#attachments-badge");
                    let numberOfFiles;

                    if (dropzoneContent.files && dropzoneContent.files.length) {
                        numberOfFiles = dropzoneContent.files.length;
                    } else if (autosavedData) {
                        numberOfFiles = autosavedData.dropzoneContent.files.length;
                    } else if (dropzoneContent.files && dropzoneContent.files.trayItemType == "external") {
                        numberOfFiles = dropzoneContent.files.fileNames.length;
                    } else if (dropzoneContent.scriptObject.names) {
                        numberOfFiles = dropzoneContent.scriptObject.names.length;
                    } else {
                        numberOfFiles = 0;
                    }

                    if (attachmentsBadge.length) {
                        if (numberOfFiles > 0) {
                            attachmentsBadge[0].innerHTML = numberOfFiles;
                            attachmentsBadge.addClass("visible");
                        } else {
                            attachmentsBadge[0].innerHTML = ""
                            attachmentsBadge.removeClass("visible");
                        }
                        attachmentsBadge.show();
                    }
                }
            }

            function evaluateEmsType(isEmsType, mainType, mode) {
                return isEmsType && mainType == "6" && mode != "reference";
            }

            MessageService.broadcast(Broadcasts.CREATION_VIEW_READY, {createResult$: scope.createResult$});
        }
    };
}
