import {ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import { MessageService } from "CORE_PATH/services/message/message.service";
import {first} from "rxjs/operators";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import { GlobalEventEnum } from "ENUMS_PATH/global-event.enum";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {Subscription} from "rxjs";

export enum DropzoneMessage {
    DROPZONE_CONTENT_UPDATED = "DROPZONE_CONTENT_UPDATED",
    DROPZONE_EMS_EXTRACTION_FINISHED = "DROPZONE_EMS_EXTRACTION_FINISHED",
    DROPZONE_INITIALISED = "DROPZONE_INITIALISED"
}

export interface DropzoneConfig {
    /**
     * Currently NOOP
     */
    mainType: string;
    /**
     * Currently NOOP
     */
    hideTemplates?: boolean;
    objectTypeId: string;
    /**
     * File extensions (without leading .) to be accepted by the dropzone.
     */
    allowedFileExtensions?: string[];
    maxFileCount: number;
    id?: string;
}

export interface UploadResult {
    file: File;
    successful: boolean;
}

interface FileInformation {
    file: File;
    processing: boolean;
    uploadProgress: number;
    failed: boolean;
}

interface ScriptObject {
    names?: string[];
    type?: string;
}

interface DropzoneContent {
    scriptObject: ScriptObject;
    files: any;
    mode: string;
    item: any;
    template: string;
    templateConfiguration: any;
}

@Component({
    selector: "eob-ems-dropzone",
    templateUrl: "./ems-dropzone.component.html",
    styleUrls: ["./ems-dropzone.component.scss"]
})
export class EmsDropzoneComponent implements OnDestroy, OnInit {

    private readonly translateFn: TranslateFnType;

    constructor(private changeDetection: ChangeDetectorRef, private notificationsService: NotificationsService,
                @Inject(forwardRef(() => "fileCacheService")) private fileCacheService: FileCacheService,
                @Inject(forwardRef(() => "$filter")) private $filter: ng.IFilterService,
                @Inject(forwardRef(() => ClientService)) private clientService: ClientService,
                private messageService: MessageService, @Inject("environmentService") private environmentService: TodoEnvironmentService,
                private httpService: HttpService) {
        this.translateFn = $filter("translate");
        this.messageService.broadcast(GlobalEventEnum.USE_GLOBAL_EVENT, false);
    }

    @Output() onfiledropped: EventEmitter<any> = new EventEmitter<any>();
    @Input() config: DropzoneConfig;
    files: FileInformation[] = [];
    initialRetrieval = new Subscription();

    @ViewChild("fileInput", {static: false}) private fileInput: ElementRef<HTMLInputElement>;

    getFileExtensions(): string {
        // Android can't handle the accept attribute if it contains file extensions, hence they are dropped
        return this.clientService.isAndroid() ? "" : this.config.allowedFileExtensions?.map(x => `.${x}`).join(",");
    }


    ngOnInit() {
        // set id implies there's a source id to copy from
        if(this.config.id) {
            this.files.push({file: new File([], "src.eml"), failed: false, processing: false, uploadProgress: 0});
            this.initialRetrieval.add(this.httpService.retrieveDocumentFiles([{id: this.config.id}], "1", "src.eml").subscribe(x => {
                const contentDispositionParts: string[] = x.headers.get("Content-Disposition").split(/\./);
                const fileExtension: string = contentDispositionParts[contentDispositionParts.length - 1];
                this.files.splice(0, 1);
                const blob: Blob = new Blob([new Uint8Array(x.body)]);
                const file: File = new File([blob], `src.${fileExtension}`);
                this.files.push({file, failed: false, processing: false, uploadProgress: 100});
                this.messageService.broadcast<File[]>(DropzoneMessage.DROPZONE_CONTENT_UPDATED, this.files.map(y => y.file));
            }));
        }
    }

    uploadFile(event: FileList): void {
        const eventFiles: File[] = [];
        if(event.length > (this.config.maxFileCount ?? 1) || event.length + this.files.length > (this.config.maxFileCount ?? 1)) {
            this.notificationsService.info(this.translateFn("dropzone.maxfilesexceeded"));
            return;
        }

        if(this.config.allowedFileExtensions?.length > 0) {
            const allowedFiles: File[] = Array.from(event).filter(x => this.config.allowedFileExtensions.includes(x.name.split(".").pop().toLowerCase()));
            if(allowedFiles.length != event.length) {
                this.notificationsService.info(this.translateFn("dropzone.invalidfiletype"));
                return;
            }
        }

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i: number = 0; i < event.length; i++) {
            const fi: FileInformation = {file: event[i], processing: true, uploadProgress: 0, failed: false};
            this.files.push(fi);
            eventFiles.push(event[i]);
            this.messageService.subscribe<UploadResult>(DropzoneMessage.DROPZONE_EMS_EXTRACTION_FINISHED, (result => {
                fi.processing = false;
                const scriptObject: ScriptObject = {};
                if (result.successful) {
                    fi.uploadProgress = 1;
                    if (this.config.mainType === "6") {
                        scriptObject.type = "upload";
                    } else if (this.environmentService.getCreationMode() === "copy") {
                        scriptObject.type = "edit";
                    } else {
                        scriptObject.type = "upload";
                        scriptObject.names.push(fi.file.name ? fi.file.name : "");
                    }
                } else {
                    fi.uploadProgress = 0;
                    fi.failed = true;
                }
                const content: DropzoneContent = this.environmentService.getDropzoneContent();
                this.environmentService.setDropzoneContent(scriptObject, content.files, content.mode, content.item, content.template, content.templateConfiguration);
                this.changeDetection.detectChanges();
            }), first());
        }

        this.onfiledropped.emit(eventFiles);
        this.messageService.broadcast<File[]>(DropzoneMessage.DROPZONE_CONTENT_UPDATED, this.files.map(x => x.file));
        // This empties the FileList. We only use the input to be able to retrieve files.
        this.fileInput.nativeElement.value = "";
        this.changeDetection.detectChanges();
    }

    removeFile(index: number): void {
        this.initialRetrieval.unsubscribe();
        this.files.splice(index, 1);
        this.messageService.broadcast<File[]>(DropzoneMessage.DROPZONE_CONTENT_UPDATED, this.files.map(x => x.file));
        this.changeDetection.detectChanges();
    }

    ngOnDestroy(): void {
        const content: DropzoneContent = this.environmentService.getDropzoneContent();
        this.messageService.broadcast(GlobalEventEnum.USE_GLOBAL_EVENT, true);
        this.environmentService.setDropzoneContent({}, content.files, content.mode, content.item, content.template, content.templateConfiguration);
        this.messageService.broadcast<File[]>(DropzoneMessage.DROPZONE_CONTENT_UPDATED, []);
    }
}
