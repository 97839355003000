import { HttpErrorResponse } from "@angular/common/http";

require("SERVICES_PATH/viewer/detailsviewer/dv.username.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");

angular.module("eob.framework").directive("dvHistory", DvHistory);

DvHistory.$inject = ["environmentService", "$filter", "dvUsernameService", "$timeout", "clientService",
    "backendObjectService", "messageService"];


// eslint-disable-next-line max-params
function DvHistory(EnvironmentService, $filter, DvUsernameService, $timeout, ClientService,
                   BackendObjectService, MessageService) {

    return {
        restrict: "E",
        template: require("!raw-loader!./dv.history.html"),
        link(scope) {
            let buttonTextChanges = $filter("translate")("history.show.changes");
            let buttonTextAll = $filter("translate")("history.show.all");
            let columnDefs = [{
                headerName: "",
                field: "timestamp",
                cellRenderer: timeRender,
                width: 68,
                suppressSizeToFit: true
            }, {
                headerName: "",
                field: "type",
                cellRenderer: typeRenderer,
                width: ClientService.isPhone() ? 30 : 56,
                suppressSizeToFit: true
            },{
                headerName: "",
                field: "info",
                cellRenderer: descriptionRender
            }];
            scope.showAll = true;
            scope.viewEnabled = false;
            scope.historydata = undefined;
            scope.histerror = false;
            scope.btnText = scope.showAll ? buttonTextChanges : buttonTextAll;
            scope.toggleShow = toggleShow;

            scope.$on("changeObject", load);
            MessageService.subscribe("clear.viewer", clear);

            function timeRender(params) {
                let timestamp = params.value;
                let dateFormat = $filter("translate")("app.date.format");
                let date = $filter("date")(timestamp, dateFormat);
                let time = $filter("date")(timestamp, "H:mm");
                return `<div class="datetime"><div class="date">${date}</div>` +
                    `<div class="time">${time}</div></div>`;
            }

            function typeRenderer(params) {
                let htmlSnippet = "<div class=\"timeline\">";
                if (params.value) {
                    htmlSnippet = htmlSnippet.concat("<span></span>");
                } else {
                    htmlSnippet = htmlSnippet.concat("<span class=\"info\"></span>");
                }
                return htmlSnippet.concat("</div>");
            }

            function descriptionRender(params) {
                let infoObject = params.value;
                if (infoObject == void 0) {
                    return "";
                }

                return "<div class=\"info\">" +
                    `<div class="title">${infoObject.actiontitle}</div>` +
                    `<div class="desc" style="-webkit-box-orient: vertical">${infoObject.info}</div>` +
                    `<div class="userblock">${DvUsernameService.getHtmlUsernameSnippet({
                        name: infoObject.user,
                        fullname: infoObject.fullname
                    })}</div>` +
                    "</div>";
            }

            init();

            function init() {
                scope.loading = true;
                scope.viewEnabled = EnvironmentService.userHasRole("R_CLNT_OBJHIST");
                if (scope.viewEnabled) {
                    initGrid();
                    load();
                } else {
                    scope.loading = false;
                }
            }

            function initGrid() {
                if (scope.gridOptions != void 0) {
                    return;
                }

                scope.gridOptions = {
                    defaultColDef: {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        suppressMenu: true
                    },
                    rowHeight: 94,
                    headerHeight: 0,
                    columnDefs,
                    rowData: [],
                    suppressContextMenu: true,
                    suppressHorizontalScroll: true,
                    suppressNoRowsOverlay: true,
                    rowBuffer: 60,
                    suppressCellSelection: true,
                    onGridSizeChanged: fitColumns
                };
            }

            function fitColumns() {
                if (scope.loading || !scope.gridOptions || !scope.gridOptions.api) {
                    return;
                }

                $timeout(() => {
                    scope.gridOptions.api.sizeColumnsToFit();
                }, 100);
            }

            function prepareAndSetHistoryData() {
                let historyData = scope.historydata;

                let rowData = [];
                for (let historyItem of historyData) {
                    // 7 and 31 are the action ids, which are not displayed, if user chooses to show only changes to the object
                    let isInfoItem = historyItem.action == 7 || historyItem.action == 31;

                    if (!scope.showAll && isInfoItem) {
                        continue;
                    }

                    let newRow = {
                        timestamp: +historyItem.creationDate,
                        type: isInfoItem,
                        info: {
                            actiontitle: historyItem.actiontitle,
                            info: historyItem.actioninfo,
                            user: historyItem.user,
                            fullname: historyItem.fullname
                        }
                    };

                    rowData.push(newRow);
                }

                if (scope.gridOptions && scope.gridOptions.api) {
                    scope.gridOptions.api.setRowData(rowData);
                }
            }

            async function load() {
                let requestedOsId = scope.objectId;
                try {
                    const historyData = await BackendObjectService.getObjectHistory(requestedOsId).toPromise();

                    if (requestedOsId == scope.objectId) {
                        scope.historydata = historyData;
                        scope.historydata.forEach(translate);
                        $timeout(() => {
                            prepareAndSetHistoryData();
                        }, 0);
                    }

                    scope.histerror = false;
                    scope.loading = false;
                    fitColumns();
                } catch (e) {
                    console.warn(e);
                    if (e instanceof HttpErrorResponse && e.error.errorCode == 31) {
                        scope.viewEnabled = false;
                        scope.errmsg = $filter("translate")("app.rights");
                    } else {
                        scope.errmsg = $filter("translate")("error.history.get");
                    }
                    scope.histerror = true;
                } finally {
                    scope.loading = false;
                }
            }

            function translate(element) {
                element.actiontitle = $filter("translate")(`dv.history.action.title.${element.action}`);

                if (element.action == 31 || element.action == 40) {
                    // Some very common entries we have written in the past with our services.
                    // They should be translated too. If there is action 31 or 40 with other
                    // strings we display them not translated. Decision from DODO-11995, 17th December 2020.
                    switch (element.description) {
                        case "Thumbnail Darstellung der Dokumentendatei wurde übertragen":
                            element.actioninfo = $filter("translate")("dv.history.action.info.31a");
                            break;
                        case "Dokumentendatei wurde als PDF übertragen":
                            element.actioninfo = $filter("translate")("dv.history.action.info.31b");
                            break;
                        case "Dokumentendatei wurde als ZIP übertragen":
                            element.actioninfo = $filter("translate")("dv.history.action.info.31c");
                            break;
                        case "Dokument wurde zur Vorschau angezeigt.":
                        case "Das Dokument wurde zur Ansicht geöffnet.":
                        case "Seite 1 der Dokumentendatei wurde übertragen":
                            element.actioninfo = $filter("translate")("dv.history.action.info.31d");
                            break;
                        case "Das Dokument wurde zur Bearbeitung geöffnet.":
                            element.actioninfo = $filter("translate")("dv.history.action.info.31e");
                            break;
                        default:
                            element.actioninfo = element.description;
                    }
                } else {
                    element.actioninfo = $filter("translate")(`dv.history.action.info.${element.action}`);
                }
            }

            function toggleShow() {
                scope.showAll = !scope.showAll;
                scope.btnText = scope.showAll ? buttonTextChanges : buttonTextAll;
                prepareAndSetHistoryData();
            }

            function clear() {
                scope.showAll = false;
                scope.viewEnabled = false;

                scope.historydata = undefined;
                scope.histerror = false;
            }

        }
    };
};
