import { Component, ElementRef, Injector } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

/**
 * The component wrapper for the eobDocumentViewer AngularJS directive.
 */
@Component({
  selector: "document-viewer",
  template: ""
})
export class DocumentViewerComponent extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
      super("eobDocumentViewer", elementRef, injector);
  }
}
