require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/workflow/eob.routing.list.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/eob.search.srv.js");
require("SERVICES_PATH/scripting/form/eob.form.tools.srv.js");
require("SERVICES_PATH/scripting/form/eob.wf.scripting.srv.js");
require("SERVICES_PATH/scripting/eob.client.script.srv.js");
require("SERVICES_PATH/scripting/eob.script.helper.model.srv.js");

angular.module("eob.core").factory("formHelper", FormHelper);

FormHelper.$inject = ["$rootScope", "$timeout", "$log", "environmentService", "backendService", "$q", "dmsDocumentService", "dmsActionService",
    "routingListService", "inboxActionService", "valueUtilsService", "stateHistoryManager", "asIniService", "searchService", "errorModelService",
    "$filter", "$compile", "cacheManagerService", "objectTypeService", "formToolsService", "wfScriptingService", "organisationService",
    "clientScriptService", "clientService", "modalDialogService", "scriptHelperModelService"];

// eslint-disable-next-line max-params, require-jsdoc
export default function FormHelper($rootScope, $timeout, $log, EnvironmentService, BackendService, $q, DmsDocumentService, DmsActionService,
                                   RoutingListService, InboxActionService, ValueUtilsService, StateHistoryManager, AsIniService, SearchService, ErrorModelService,
                                   $filter, $compile, CacheManagerService, ObjectTypeService, FormToolsService, WfScriptingService, OrganisationService,
                                   ClientScriptService, ClientService, ModalDialogService, ScriptHelperModelService) {
    function getFormHelper(helperConfig) {
        return new FormHelper(helperConfig);
    }

    // inheritance js-style
    const ScriptHelper = ScriptHelperModelService.ScriptHelper;
    FormHelper.prototype = Object.create(ScriptHelper.prototype);

    function FormHelper(helperConfig) {
        ScriptHelper.call(this);

        let self = this,
            fields = angular.copy(helperConfig.formData),
            isSearch = helperConfig.isSearch,
            isWorkflow = helperConfig.isWorkflow,
            isCreate = helperConfig.isCreate,
            isReference = helperConfig.isReference,
            isEdit = helperConfig.isEdit,
            isView = helperConfig.isView,
            isVariant = helperConfig.isVariant,
            modelDef = helperConfig.modelDef,
            dmsDocument = null;

        self.globals = {};
        self.formScripts = {};
        self.resizeListener = [];

        self.scriptingStorage = ClientScriptService.getGlobalScriptingStorage();
        self.passwordRequired = ValueUtilsService.parseBoolean(helperConfig.isPasswordRequired);
        self.progressbar = helperConfig.progressbar;
        self.createResult$ = helperConfig.createResult$;
        self.saveDisabled = helperConfig.saveDisabled;

        // callbacks
        let cancelCallback = FormToolsService.progressWrapper(helperConfig.cancel, helperConfig);
        let saveCallback = FormToolsService.progressWrapper(helperConfig.save, helperConfig);
        let forwardCallback = FormToolsService.progressWrapper(helperConfig.forward, helperConfig);
        let searchCallback = FormToolsService.progressWrapper(helperConfig.search, helperConfig);

        init();

        function init() {
            if (helperConfig.dmsData != void 0) {
                dmsDocument = CacheManagerService.dmsDocuments.get(CacheManagerService.dmsDocuments.add(helperConfig.dmsData))[0];
            }

            if (isSearch) {
                self.isSearch = true;
            } else if (isWorkflow) {
                self.isWorkflow = true;
            } else if (isCreate) {
                self.isCreate = true;
                self.isReference = isReference;
            } else if (isEdit) {
                self.isEdit = true;
            } else if (isView) {
                self.isView = true;
            }

            // only set this api in case we are used by a workflow
            if (isWorkflow) {
                WfScriptingService.addWorkflowApi(self, helperConfig);
            }

            self.getCurrentDmsDocument = function() {
                return dmsDocument;
            };
        }

        self.setCurrentDmsDocument = function(document) {
            if (document != void 0) {
                dmsDocument = document;
            }
        };

        self.submit = function() {
            if (self.isEdit && saveCallback != void 0) {
                return saveCallback(self);
            }

            if (self.isWorkflow && forwardCallback != void 0) {
                return forwardCallback(self);
            }

            if (self.isSearch && searchCallback != void 0) {
                for (let key of Object.keys(fields)) {
                    let value = fields[key].value;
                    if (value != void 0 && typeof value == "string") {
                        fields[key].value = value.trim();
                    }
                }
                return searchCallback(self);
            }

            if (self.isCreate && saveCallback != void 0) {
                return saveCallback(self);
            }
        };

        /**
         * @deprecated since SP5 8.50
         * submits the form with the submit action
         */
        self.submitForm = function() {
            $log.warn("The function 'formHelper.submitForm()' is deprecated and will be removed in enaio version 9.0");
            $log.warn("Use the functions 'save', 'forward' and 'search'");
            $log.warn("For further information see the api scripting documentation");

            return self.submit();
        };

        /**
         * returns current information
         * @returns {Object}
         */
        self.getInfo = function() {
            // Call parent function first.
            let info = ScriptHelper.prototype.getInfo();

            if (self.isWorkflow) {
                info.workflow = {
                    name: modelDef.config.name,
                    activityId: modelDef.config.activityId,
                    workflowId: modelDef.config.workflowId,
                    processId: modelDef.config.processId,
                    workflowType: modelDef.config.workflowType,
                    rActivityId: helperConfig.workitemId,
                    version: modelDef.config.version
                };
            } else {
                info.dms = {
                    mainType: modelDef.config.mainType,
                    objectTypeId: modelDef.config.objectTypeId
                };

                if (isCreate && helperConfig.dmsLocationId != void 0 && helperConfig.dmsLocationTypeId != void 0) {
                    info.dms.dmsLocationId = helperConfig.dmsLocationId;
                    info.dms.dmsLocationTypeId = helperConfig.dmsLocationTypeId;
                }

                if (dmsDocument != void 0) {
                    info.dms.osid = dmsDocument.model.osid;
                }

                if (isVariant) {
                    info.dms.isVariant = true;
                } else {
                    info.dms.isVariant = false;
                }
            }

            return info;
        };

        // region form
        if (searchCallback != void 0) {
            self.search = function() {
                return searchCallback(self);
            };
        }

        if (cancelCallback != void 0) {
            self.cancel = function() {
                return cancelCallback(self);
            };
        }

        if (forwardCallback != void 0) {
            self.forward = function() {
                return forwardCallback(self);
            };
        }

        if (saveCallback != void 0) {
            self.save = function(closeWorkflowMask = true) {
                return saveCallback(self, closeWorkflowMask);
            };
        }

        /**
         * returns all fields
         * @returns {FieldModel[]} - returns all fields of the form
         */
        self.getFields = function() {
            return fields;
        };

        self.getModel = function() {
            return modelDef;
        };

        /**
         * returns a single field by its name or null
         * @param {string} name - name of the field
         * @returns {FieldModel} - The model of the field
         */
        self.getFieldByName = function(name) {
            name = name.replace(/&&?/g, match => match.length == 2 ? "&" : "");

            for (let i in fields) {
                if (fields[i].model.name == name) {
                    return fields[i];
                }

                if (fields[i].model.type == "radio" && fields[i].model.groupName == name) {
                    return fields[i];
                }
            }

            console.warn("Field does not exist --> ", name);
            return null;
        };

        /**
         * returns a single field by its internal name or null
         * @param internal {string} internal name of the field
         * @returns {Object}
         */
        self.getFieldByInternal = function(internal) {
            let field = fields[internal];

            if (field == void 0) {
                console.warn("Field does not exist --> ", internal);
                return null;
            }

            return field;
        };

        /**
         * returns a single field by anykey value pair or null
         * @param key {string} The key to use. e.g "guid"
         * @param value {string} The value that shall match to the given key
         * @returns {Object}
         */
        self.getFieldByAnyKey = function(key, value) {
            if (key == void 0) {
                return null;
            }

            if (key.toLowerCase() == "internal") {
                return self.getFieldByInternal(value);
            }

            if (key.toLowerCase() == "name") {
                return self.getFieldByName(value);
            }

            for (let i in fields) {
                if (fields[i].model[key] == value) {
                    return fields[i];
                }
            }

            console.warn("Field does not exist --> ", value);
            return null;
        };

        /**
         * returns the content of the pagecontrol with the given page index
         * if no pageindex is set this function return every field from all pages
         *
         * @deprecated
         * @param {string}internal - the internal name of the page control
         * @param {*=} pageIdentifier - An optional page identifier. May be the page index, name or the page object itself.
         * @throws {CustomError} If the queried pagecontrol does not exist
         * @returns {Array} form field models for given pagecontrol
         */
        self.getFieldsFromPageControl = function(internal, pageIdentifier) {
            let pageCtrl = self.getFieldByInternal(internal);

            if (!pageCtrl) {
                throw ErrorModelService.createCustomError("OBJECT_GET_DETAILS_FAILED");
            }

            if (pageIdentifier == void 0) {
                return pageCtrl.api.getFields();
            }

            let page = pageCtrl.api.getPage(pageIdentifier);

            if (page == null) {
                throw ErrorModelService.createCustomError("WEB_PARAMETER_INVALID", pageIdentifier);
            }

            return page.fields;
        };

        /**
         * returns the content of the pagecontrol with the given page index as fields with model and api
         * if no pageindex is set this function return every field from all pages
         *
         * @param {string}internal - the internal name of the page control
         * @param {*=} pageIdentifier - An optional page identifier. May be the page index, name or the page object itself.
         * @throws {CustomError} If the queried pagecontrol does not exist
         * @returns {Array} form fields for given pagecontrol
         */
        self.getCompleteFieldsFromPageControl = function(internal, pageIdentifier) {
            return self.getFieldsFromPageControl(internal, pageIdentifier).map(x => self.getFieldByInternal(x.internal))
        }

        /**
         * Focus the first field of the form.
         *
         */
        self.focusFirstField = function() {
            for (let i in fields) {
                let field = fields[i];

                let exclude = ["pagecontrol", "group", "static", "radio"];
                if (field.api != void 0 && !field.model.isInvisibleField && exclude.indexOf(field.model.type) == -1) {
                    field.api.focus(true);
                    break;
                }
            }
        };
        //endregion

        self.setPasswordRequired = function(passwordRequired) {
            self.passwordRequired = ValueUtilsService.parseBoolean(passwordRequired);
        };

        self.isPasswordRequired = function() {
            return self.passwordRequired;
        };

        self.getFileInfo = function() {
            let content = EnvironmentService.getDropzoneContent();
            return content.scriptObject;
        };

        /**
         * Destroys the formhelper and removes all EventListeners
         */
        self.destroy = function() {
            ScriptHelper.prototype.destroy();

            fields = []
            self = undefined;
        };
    }

    // these are private members, that are not part of the official api documentation
    // anything that will be called by the webclient itself, will be part of the prototype
    FormHelper.prototype.config = {};

    FormHelper.prototype.setFormScript = function(eventName, script) {
        let self = this;

        self.formScripts[eventName] = script;
    };

    FormHelper.prototype.getFormScript = function(eventName) {
        return this.formScripts[eventName];
    };

    FormHelper.prototype.bindScripts = async function(scripts) {
        let self = this;

        if (!self.isWorkflow) {
            let globalDmsScript = await ClientScriptService.getGlobalScriptAsync();
            if (globalDmsScript !== void 0 && Object.keys(globalDmsScript).length > 0) {
                if (!Array.isArray(scripts)) {
                    scripts = [];
                } else {
                    scripts = angular.copy(scripts);
                }

                scripts.unshift(globalDmsScript);
            }
        }

        for (let i in scripts) {
            let scriptObj = scripts[i];
            var field;

            if (scriptObj.script == void 0 || scriptObj.script === "" || scriptObj.scriptLanguage != "JavaScript") {
                continue;
            }

            if (scriptObj.fieldName != void 0) {
                field = self.getFieldByName(scriptObj.fieldName);

                if (field == void 0) {
                    console.warn("could not find field for --> ", scriptObj);
                    continue;
                }
            } else if (scriptObj.fieldGUID != void 0) {
                field = self.getFieldByAnyKey("guid", scriptObj.fieldGUID);

                if (field == void 0) {
                    console.warn("could not find field for GUID --> ", scriptObj);
                    continue;
                }
            }

            let scriptName = FormToolsService.getScriptName(self.isWorkflow, self.getModel().config, field, scriptObj);

            try {
                var script;

                switch (scriptObj.eventType) {
                    case "GLOBAL_CLIENT":
                    case "GLOBAL_WEBCLIENT":
                    case "GLOBAL_WEBCLIENT_OBJECT_TYPE":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage"], scriptObj.script, scriptName)(self, self.globals, ClientScriptService.getGlobalScriptingStorage());
                        break;
                    case "BEFORE_CANCEL":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "isSave", "done"], scriptObj.script, scriptName);
                        self.setFormScript("beforeCancel", script);
                        break;
                    case "BEFORE_FORWARD":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "done"], scriptObj.script, scriptName);
                        self.setFormScript("beforeForward", script);
                        break;
                    case "BEFORE_OPEN":
                        if (!self.isWorkflow) {
                            break;
                        }
                    case "SHOW":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "userAction", "done"], scriptObj.script, scriptName);
                        self.setFormScript("beforeOpen", script);
                        break;
                    case "BEFORE_VALIDATE":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "done"], scriptObj.script, scriptName);
                        self.setFormScript("beforeValidate", script);
                        break;
                    case "AFTER_VALIDATE":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "done"], scriptObj.script, scriptName);
                        self.setFormScript("beforeSave", script);
                        break;
                    case "AFTER_SAVE":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "done"], scriptObj.script, scriptName);
                        self.setFormScript("afterSave", script);
                        break;
                }

                if (field == void 0) {
                    continue;
                }

                if (field.eventScripts == void 0 && typeof (field) == "object") {
                    field.eventScripts = {};
                }

                switch (scriptObj.eventType) {
                    case "CELL_FOCUS_GAINED":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field", "cell"], scriptObj.script, scriptName);
                        field.eventScripts.onCellFocus = script;
                        break;
                    case "CELL_VALUE_CHANGED":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field", "cell"], scriptObj.script, scriptName);
                        field.eventScripts.onCellChange = script;
                        break;
                    case "BEFORE_ADD_ROW":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field", "done"], scriptObj.script, scriptName);
                        field.eventScripts.onBeforeAddRow = script;
                        break;
                    case "BEFORE_DELETE_ROW":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field", "row", "done"], scriptObj.script, scriptName);
                        field.eventScripts.onBeforeDeleteRow = script;
                        break;
                    case "BUTTON_CLICK":
                    case "CLICK_ITEM":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field"], scriptObj.script, scriptName);
                        field.eventScripts.onClick = script;
                        break;
                    case "FOCUS_GAINED":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field"], scriptObj.script, scriptName);
                        field.eventScripts.onFocus = script;
                        break;
                    case "VALUE_CHANGED":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field"], scriptObj.script, scriptName);
                        field.eventScripts.onChange = script;
                        break;
                    case "LEAVE_PAGE":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field", "page"], scriptObj.script, scriptName);
                        field.eventScripts.onLeavePage = script;
                        break;
                    case "ENTER_PAGE":
                        script = FormToolsService.defineFunction(["formHelper", "globals", "scriptingStorage", "field", "page"], scriptObj.script, scriptName);
                        field.eventScripts.onEnterPage = script;
                        break;
                }
            } catch (err) {
                let errorMessage = "";
                errorMessage += `${$filter("translate")("eob.form.script.returned.error.verbose").replace("%s1", scriptName)}\n\n`;
                console.error(errorMessage, err);
                errorMessage += err.stack;

                await ModalDialogService.errorInfoDialog($filter("translate")("eob.notification.error.title"), $filter("translate")("modal.confirm.dms.get.script.error.message"), $filter("translate")("modal.button.close"), errorMessage);
                history.back();
            }
        }
    };

    FormHelper.prototype.updateConfig = function(conf) {
        let self = this;

        for (let i in conf) {
            self.config[i] = conf[i];
        }
    };

    FormHelper.prototype.getConfig = function() {
        let self = this;
        return self.config;
    };

    FormHelper.prototype.executeFormResize = function(widthDif) {
        for (let i in this.resizeListener) {
            this.resizeListener[i](widthDif);
        }
    };

    FormHelper.prototype.onFormResize = function(fn) {
        if (typeof (fn) == "function") {
            this.resizeListener.push(fn);
        }
    };

    return {
        getFormHelper
    };
};
