import {Inject, Injectable} from "@angular/core";
import {TodoExternalTrayService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {StateEnum} from "ENUMS_PATH/state.enum";
import {StateService} from "@uirouter/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NavigationAction} from "MODULES_PATH/navigation/enums/navigation.enum";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ElementRef, QueryList} from "@angular/core";
import {StateConfig, StateContent} from "INTERFACES_PATH/state.interface";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Store} from "@ngrx/store";
import {NavigationState} from "MODULES_PATH/navigation/interfaces/navigation.interface";
import {updateNavigation} from "MODULES_PATH/navigation/+state/navigation.actions";
import {Observable} from "rxjs";
import {EobPanelComponent} from "MODULES_PATH/navigation/components/eob-panel/eob-panel.component";
import {Subscription} from "rxjs";
import {NavigationDirection} from "MODULES_PATH/navigation/enums/navigationDirection.enum";
import {NavigationEvents} from "MODULES_PATH/navigation/enums/navigation-events.enum";

@Injectable({
    providedIn: "root"
})
export class NavigationService {
    private readonly translate: TranslateFnType;
    readonly DEFAULT_ANIMATION_DURATION: number = 300; /* transition time in ms for nav-bar opening and closing */
    /**
     * using a timeout that can be terminated to ensure the navigation does not
     * get stuck in an indeterminate state due to trigger finger syndrome
     */
    closeNavigationTimeout: NodeJS.Timeout;
    navTitle: string;
    connectionState: string;
    isNavVisible: boolean = true;
    isFixed: boolean = true;
    isUserMenuOpen: boolean = false;
    navEl: HTMLElement;
    externalTrayItemCount: number;
    navigationRef: ElementRef<HTMLElement>;
    activeTab: string;
    initialNavState$: Observable<NavigationState>;
    navBarTransitionDelay = 300;

    constructor(
        @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
        @Inject("externalTrayService") private externalTrayService: TodoExternalTrayService,
        @Inject("$filter") private $filter: ng.IFilterService,
        @Inject("$state") private $state: StateService,
        private messageService: MessageService,
        private asIniService: AsIniService,
        private clientService: ClientService,
        private navStore: Store<NavigationState>,
    ) {
        this.translate = this.$filter("translate");
        this.connectionState = this.clientService.isOnline() ? "online" : "offline";
        this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, () => {
            this.isUserMenuOpen = false;
        });
        this.messageService.subscribe(NavigationEvents.CLOSE_ONLY_USER_MENU, () => {
            this.isUserMenuOpen = false;
        });
    }

    goToSecondaryState(event: Event, stateType: StateEnum): void {
        let nextStateType: string = "";
        let nextStateDesciption: string = "";
        const nextStateConfig: StateConfig = {
            userAction: undefined
        };

        switch (stateType) {
            case StateEnum.FULLTEXTSEARCH:
                nextStateType = "hitlist.fulltextResult";
                nextStateDesciption = this.translate("eob.app.bar.history.title");
                break;
            case StateEnum.HITLIST_FAVORITES:
                nextStateType = "favorites";
                nextStateDesciption = this.translate("eob.app.bar.favorites.title");
                nextStateConfig.executeSingleHitAction = false;
                break;
            case StateEnum.HITLIST_HISTORY:
                nextStateType = "history";
                nextStateDesciption = this.translate("eob.app.bar.history.title");
                nextStateConfig.executeSingleHitAction = false;
                break;
            case StateEnum.HITLIST_OFFLINE_OBJECTS:
                nextStateType = "hitlist.offlineObjects";
                nextStateDesciption = this.translate("eob.app.bar.favorites.title");
                nextStateConfig.executeSingleHitAction = false;
                break;
            default:
                return;
        }

        event.stopPropagation();
        event.stopImmediatePropagation();

        const nextStateId: number = +new Date();
        const nextStateContent: StateContent = {
            id: undefined,
            objectTypeId: undefined,
            config: nextStateConfig,
            type: nextStateType,
            description: nextStateDesciption
        };

        // this function generates a new state with given data
        this.stateHistoryManager.setStateData(nextStateContent, nextStateId);

        // jump into the new state
        void this.$state.go(stateType, {state: nextStateId});
    }

    openDashboard(): void {
        if (!this.clientService.isOnline()) {
            return;
        }

        this.stateHistoryManager.goToDashboardState();
    }

    closeNavigation(timeout: number, target?: HTMLElement): void {
        if (!this.isNavVisible || this.isFixed) {
            return;
        }

        if (this.navigationRef?.nativeElement.contains(target)) {
            return;
        }

        if (target != void 0 && target.closest("ul")?.classList.contains("context-menu")) {
            return;
        }

        const modal = document.body.getElementsByTagName("eob-modal-container")[0];

        if (modal != undefined && !modal.className.includes("hidden")) {
            return;
        } else {
            const closeTimeoutInMillisec: number = timeout ?? this.DEFAULT_ANIMATION_DURATION;
            const hideTimeoutInMillisec: number = closeTimeoutInMillisec + this.navBarTransitionDelay;

            this.closeNavigationTimeout = setTimeout(() => {
                this.navEl.classList.add("closed");
                this.isNavVisible = false;
            }, closeTimeoutInMillisec);
            setTimeout(() => {
                document.querySelector("eob-nav nav .nav-body-container")?.setAttribute("hidden", "");
            }, hideTimeoutInMillisec);
        }
    }

    switchNavContent(clickedTab: string, preventShowingNavigation?: boolean, event?: Event): void {
        const clickWasTriggertByKeyboard: boolean = event && (event as PointerEvent).detail == 0;
        const focusShouldMoveToNavContent: boolean = this.isFixed || !this.isNavVisible || this.activeTab != clickedTab;

        if (!focusShouldMoveToNavContent) {
            this.closeNavigation(0);
            return;
        }

        if (clickWasTriggertByKeyboard && focusShouldMoveToNavContent) {
            setTimeout(() => this.messageService.broadcast(NavigationEvents.FOCUS_NAV_BAR_BODY), 0);
        }

        if (!preventShowingNavigation && !this.isFixed) {
            document.querySelector("eob-nav nav .nav-body-container")?.removeAttribute("hidden");
            this.navEl.classList.remove("closed");
            this.isNavVisible = true;

        }

        this.activeTab = clickedTab;

        switch (clickedTab) {
            case "objectsearch":
                this.navTitle = this.translate("nav.pane.title.objectsearch");
                break;
            case "desktop":
                this.navTitle = this.translate("nav.pane.title.desktop");
                break;
            case "quicksearch":
                this.navTitle = this.translate("nav.pane.title.quicksearch");
                break;
            case "inbox":
                this.navTitle = this.translate("nav.pane.title.inbox");
                break;
            case "externaltray":
                this.navTitle = this.translate("nav.pane.title.external.tray");
                break;
            case "kebab":
                this.navTitle = this.translate("nav.kebab.panel.title");
                break;
        }

        this.asIniService.updateLayoutConfiguration({activeNavPane: clickedTab});
    }

    async setExternalTrayStateAsync(): Promise<void> {
        this.externalTrayItemCount = (await this.externalTrayService.getExternalTrayItemsAsync()).length;
        this.navStore.dispatch(updateNavigation({
            navType: NavigationAction.SHOW_EXTERNALTRAY,
            payload: (this.externalTrayItemCount > 0)
        }));
        this.navStore.dispatch(updateNavigation({
            navType: NavigationAction.EXTERNALTRAY_ELEMENTS_AVAILABLE,
            payload: (this.externalTrayItemCount > 0)
        }));
    }

    showUserMenu(event: Event): void {
        event.stopImmediatePropagation();

        if (this.isUserMenuOpen) {
            this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
            this.isUserMenuOpen = false;
            return;
        }

        event.stopPropagation();
        setTimeout(() => this.messageService.broadcast(NavigationEvents.PREPARE_USER_MENU_WITH_KEY_NAV));
        this.messageService.broadcast(NavigationEvents.SHOW_USER_MENU, event);
        this.isUserMenuOpen = true;
    }

    /**
     * Used by eob-panel host component to add a subscription and listen when the first panel should be focused
     *
     * @param panels - a list of all the eob-panels controlled by this component
     * @param hostID - id string of the panel host component
     * @returns Subscription object that listens to FOCUS_NAR_BAR_BODY messages
     */
    focusFirstPanel(panels: QueryList<EobPanelComponent>, hostID: string): Subscription {
        return this.messageService.subscribe(NavigationEvents.FOCUS_NAV_BAR_BODY, () => {
            if (hostID !== this.activeTab) {
                return;
            }
            const animationDuration = this.isFixed ? 0 : this.DEFAULT_ANIMATION_DURATION;
            setTimeout(() => {
                panels.forEach((panel) => panel.setTabReachable(false));
                panels.first.setTabReachable(true);
            panels.first.setFocus(NavigationDirection.top);
            }, animationDuration);

        });
    }


    /**
     * Used by eob-panel host component.
     * Handles focusLeftEvent emmited by an eob-panel when the focus reached its end,
     * this method sets the focus and tabindex to the next panel
     *
     * @param panels - a list of all the eob-panels controlled by this component
     * @param senderSide - the side which the focus left the last panel (Top or Bottom)
     * @param senderID - the ID of the last panel
     */
    passFocus(panels: QueryList<EobPanelComponent>, senderSide: NavigationDirection, senderID: number): void {
        const senderPanel: EobPanelComponent = panels.toArray()[senderID]; // This is where the focus came from
        const topReceiverID: number = senderID - 1 >= 0 ? senderID - 1 : panels.length - 1; // Index of the panel above
        const bottomReceiverID: number = panels.length > senderID + 1 ? senderID + 1 : 0; // Index of the panel below
        const receiverPanel: EobPanelComponent = panels.toArray()[senderSide == NavigationDirection.top ? topReceiverID : bottomReceiverID]; // Choose the next panel depending on where the focus left the last panel
        senderPanel.setTabReachable(false); // Set correct tabindex and give focus to new panel
        receiverPanel.setTabReachable(true);
        receiverPanel.setFocus(1-senderSide);
    }

    /**
     * Used by eob-panel host component.
     * Handels focusEnteredByMouseEvent emmited by an eob-panel when user clicked on a panel heading by mouse
     * Sets all tabindexes to -1 except for the panel that was clicked
     *
     *  @param panels: a list of all the eob-panels controlled by this component
     *  @param exceptForID: ID of the clicked panel
     */
    resetTabindex(panels: QueryList<EobPanelComponent>, exceptForID: number) {
        panels.forEach((panel: EobPanelComponent, index: number) => {
            panel.setTabReachable(exceptForID === index);
        });
    }
}
