import {Component, EventEmitter, Inject, Output} from "@angular/core";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {HostListener, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {NavigationEvents} from "MODULES_PATH/navigation/enums/navigation-events.enum";

@Component({
    selector: "eob-nav-content-header",
    templateUrl: "./eob-nav-content-header.component.html",
    styleUrls: ["./eob-nav-content-header.component.scss"]
})
export class EobNavContentHeaderComponent {
    @Output() changePinState: EventEmitter<boolean> = new EventEmitter();
    private readonly translate: TranslateFnType;
    isPhone: boolean;
    isTouchDevice: boolean;
    pinIsFocusable: boolean;

    constructor(@Inject("$filter") private $filter: ng.IFilterService,
                public navigationService: NavigationService,
                private clientService: ClientService,
                private messageService: MessageService) {
        this.translate = this.$filter("translate");
        this.isPhone = this.clientService.isPhone();
        this.isTouchDevice = this.clientService.isPhoneOrTablet();
        this.pinIsFocusable = this.navigationService.isNavVisible;
    }

    /**
     * Close Nav bar on esc when nav pin is focused
     *
     * @param event
     */
    @HostListener("keydown", ["$event"])
    handleKeydown(event: KeyboardEvent): void {
        if (event.code == "Escape" && !this.navigationService.isFixed) {
            this.close();
        }
    }

    getNavPinTooltip(): string {
        return this.navigationService.isFixed ? this.translate("nav.pane.pin.title.unpin") : this.translate("nav.pane.pin.title.pin");
    }

    triggerChangePinState(): void {
        if (this.navigationService.isFixed) {
            this.messageService.broadcast(NavigationEvents.FOCUS_NAV_BAR);
        }

        this.changePinState.emit(false);
    }

    close(timeout: number = 0): void {
        this.navigationService.closeNavigation(timeout);
        setTimeout(() => this.messageService.broadcast(NavigationEvents.FOCUS_NAV_BAR), timeout);
    }
}
