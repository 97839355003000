import {Component, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, AfterViewInit, EventEmitter, ViewChild} from "@angular/core";
import {NavigationDirection} from "MODULES_PATH/navigation/enums/navigationDirection.enum";

@Component({
    selector: "eob-panel",
    templateUrl: "./eob-panel.component.html",
    styleUrls: ["./eob-panel.component.scss"],
})
export class EobPanelComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() eobPanelTitle: string;
    @Input() initExpanded: boolean | string;
    @Input() isNavPanel: boolean | string;
    @Input() isActive: boolean | string;
    @Input() panelHeadingLang?: string;
    // Informs listener that the focus reached the end of this list. Emits which end was reached (Top or Bottom).
    @Output() focusLeftEvent: EventEmitter<NavigationDirection> = new EventEmitter<NavigationDirection>();
    // Informs listener when panel receives focus by mouse click
    @Output() focusEnteredByMouseEvent: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("panelHeading", {static: false}) panelHeading;
    @ViewChild("panelContent", {static: false}) panelContent;
    expanded: boolean;
    private lastFocused: HTMLElement;
    private focusableElements: HTMLElement[];
    private lastFocusedIndex: number;


    ngOnInit(): void {
        // AJS templates are unable to pass boolean values and use strings instead.
        // As long as we don't get rid of that cancer, we need to accomodate it.
        this.expanded = this.initExpanded === true || this.initExpanded == "true";
    }

    ngAfterViewInit(): void {
        this.indexAllFocusableElements();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isActive && changes.isActive.currentValue !== changes.isActive.previousValue) {
            this.expanded = changes.isActive.currentValue === true;
            // if panel was toggled, scan panel content for new focusable elements.
            // Ensures new items can be reached by keyboard navigation and saves performance
            if (!this.panelContent || !this.panelHeading) {
                return;
            }
            this.indexAllFocusableElements();
        }
    }

    /**
     * React to Arrow key presses by moving the focus in arrow direction or emmiting focusLeftEvent when reaching the end
     *
     * @param $event from users keypress
     */
    @HostListener("keydown", ["$event"])
    handleKeydown($event: KeyboardEvent): void {
        const arrowKeyIndex = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf($event.key);
        if (arrowKeyIndex <= -1) {
            return;
        }

        let nextElement: HTMLElement;
        if (arrowKeyIndex < 2) { // Arrow up or down
            const wasArrowUp: boolean = $event.key == "ArrowUp";
            nextElement = this.getNextFocusableElement(wasArrowUp ? NavigationDirection.top : NavigationDirection.bottom);
            if (!nextElement || !this.expanded) {
                this.focusLeftEvent.emit(wasArrowUp ? NavigationDirection.top : NavigationDirection.bottom);
                return;
            }
        }

        // Ensure lastFocused is defined, default is panel heading
        this.lastFocused = this.lastFocused ? this.lastFocused : this.panelHeading.nativeElement;
        // Arrow right
        if (arrowKeyIndex == 3) {
            nextElement = this.lastFocused.parentElement.querySelector("button.secondary-panel-button");
        }

        // Arrow left
        if (arrowKeyIndex == 2 && this.lastFocused.classList.contains("secondary-panel-button")) {
            nextElement = this.lastFocused.parentElement.querySelector("button:not(.secondary-panel-button)");
            nextElement = nextElement ? nextElement : this.panelHeading.nativeElement;
        }

        if (!nextElement) {
            return;
        }

        this.lastFocused = nextElement;
        nextElement.focus();
    }

    /**
     * Sets the focus on either end (Top or Bottom) of the panel for keyboard navigation
     * If panel is collapsed it always focuses the Top end
     *
     * @param at
     */
    setFocus = (at: NavigationDirection = NavigationDirection.top): void => {
        if (![NavigationDirection.top, NavigationDirection.bottom].includes(at)) {
            return;
        }
        this.lastFocusedIndex = (at == NavigationDirection.top || !this.expanded) ? 0 : this.focusableElements.length - 1;
        this.lastFocused = this.focusableElements[this.lastFocusedIndex];
        this.lastFocused.focus();
    };

    /**
     * Sets the tabindex of the panel heading to -1 or 0
     * 0: User can select panel with tab
     * -1: panel will be ignored when tab-ing
     *
     * @param toBe boolean value, true: 0, false: -1
     */
    setTabReachable = (toBe: boolean): void => {
        if (!this.focusableElements || this.focusableElements.length == 0) {
            console.error("Error in panel component: Tried setting tabindex before component was initialized");
            return;
        }
        this.focusableElements[0].setAttribute("tabindex", toBe ? "0" : "-1");
    };

    togglePanel(event?: Event): void {
        this.expanded = !this.expanded;

        // @ts-ignore
        if (event?.detail === 1) { // toggled mouse click
            this.focusEnteredByMouseEvent.emit();
        }

        // timeout because indexAllFocusableElements inside ngOnChanges() will set it to -1 again
        setTimeout(() => {
            this.setTabReachable(true);
        });
    }

    triggerClickOnPanelBody(event: Event): void {
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    /**
     * Create an array of focusable elements from panel heading + panel content for faster keyboard navigation
     * then sets all elements tabindex to "-1"
     *
     * @private
     */
    private indexAllFocusableElements(): void {
        const primaryElements = Array.from(this.panelContent.nativeElement.querySelectorAll("button:not(.secondary-panel-button)"));
        this.focusableElements = [this.panelHeading.nativeElement].concat(primaryElements);
        this.focusableElements.forEach((element) => {
            element.setAttribute("tabindex", "-1");
        });
    }

    /**
     * Find the next element in direction up- or downwards (.top or .bottom)
     * Returns null if the end was reached
     *
     * @param direction
     * @private
     */
    private getNextFocusableElement(direction: NavigationDirection): HTMLElement {
        const nextIndex: number = direction == NavigationDirection.top ? this.lastFocusedIndex - 1 : this.lastFocusedIndex + 1;
        if (nextIndex < 0 || nextIndex >= this.focusableElements.length) {
            return null;
        }
        this.lastFocusedIndex = nextIndex;
        return this.focusableElements[nextIndex];
    }
}
