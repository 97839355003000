import {Inject, Injectable} from "@angular/core";
import * as angular from "angular";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {OfflineService} from "SERVICES_PATH/offline/eob.offline.srv";
import {FieldsetBuilderService} from "CORE_PATH/services/field/fieldset-builder.service";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ColumnDefinition} from "MODULES_PATH/grid/interfaces/column.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ObjectSyncFailedStatus} from "ENUMS_PATH/offline/offline-object-sync-failed-status.enum";
import {
    TodoCacheManagerService,
    TodoContextItem,
    TodoEnvironmentService,
    TodoHtmlStyle,
    TodoListEntry,
    TodoRevisit,
    TodoRowTemplate,
    TodoStaticColumnData,
    TodoSubscription,
    TodoSubscriptionModel,
    TodoSyncStatus,
    TodoVariantData,
    TodoWorkflowContext,
    TodoWorkflowContextModel, TodoWorkflowParameter
} from "INTERFACES_PATH/any.types";
import * as dayjs from "dayjs";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {GridCellContent} from "MODULES_PATH/grid/interfaces/grid-cell-content.interface";
import {ICellRendererParams} from "ag-grid-community";
import {ObjectType} from "INTERFACES_PATH/object-type.interface";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import {
    AdditionalPhoneCellContent,
    GridPhoneCellContent,
    GridPhoneCellContentValue,
    GridPhoneSyncStatus
} from "MODULES_PATH/grid/interfaces/grid-phone-cell-content.interface";
import {TitleAndDescription} from "MODULES_PATH/dms/interfaces/title-and-description.interface";
import {Icon} from "MODULES_PATH/form/interfaces/icon.interface";

/**
 * this service returns the contents of cells and provides the whole translation using a dictionary
 */
@Injectable({
    providedIn: "root"
})
export class GridContentUtilsService {
    private readonly translateFn: TranslateFnType;
    private dict: { [key: string]: { [key: string]: string } } = {};

    // eslint-disable-next-line max-params
    constructor(@Inject("$filter") private $filter: ng.IFilterService, private objectTypeService: ObjectTypeService,
                @Inject("fieldsetBuilderService") protected fieldsetBuilderService: FieldsetBuilderService, private valueUtilsService: ValueUtilsService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService, private clientService: ClientService,
                @Inject("offlineService") private offlineService: OfflineService,
                private iconService: IconService) {
        this.translateFn = $filter("translate");
        this.translateStrings();
    }

    /**
     * A dictionary that holds the translations.
     * We need this to only use the translate provider once for each string
     */
    private translateStrings(): void {
        this.dict = {
            tooltip: {
                objectType: this.translateFn("eob.grid.columnheader.tooltip.objecttype"),
                unknownMimeType: this.translateFn("eob.grid.systemcolumn.tooltip.unknown.mimeType"),
                notificationTypeIndexData: this.translateFn("eob.grid.systemcolumn.tooltip.notificationtype.edit.indexdata"),
                notificationTypeContent: this.translateFn("eob.grid.systemcolumn.tooltip.notificationtype.edit.content"),
                notificationTypeLocationAdded: this.translateFn("eob.grid.systemcolumn.tooltip.notificationtype.link"),
                notificationTypeDeleted: this.translateFn("eob.grid.systemcolumn.tooltip.notificationtype.remove"),
                notificationTypeDocumentMoved: this.translateFn("eob.grid.systemcolumn.tooltip.notificationtype.document.moved"),
                notificationTypeFolderMoved: this.translateFn("eob.grid.systemcolumn.tooltip.notificationtype.folder.moved"),
                notificationTypeObjectCreated: this.translateFn("eob.grid.systemcolumn.tooltip.recentobjectaction.new"),
                notifyTypeInternal: this.translateFn("eob.grid.systemcolumn.tooltip.abo.notifyType.internal"),
                notifyTypeEmail: this.translateFn("eob.grid.systemcolumn.tooltip.abo.notifyType.mail"),
                activeVariant: this.translateFn("eob.grid.systemcolumn.tooltip.variants.active"),
                modifiedDate: this.translateFn("eob.grid.systemcolumn.modified.date"),
                archiveStateArchivable: this.translateFn("eob.grid.systemcolumn.tooltip.arch.archivable"),
                archiveStateNoPages: this.translateFn("eob.grid.systemcolumn.tooltip.arch.nopages"),
                archiveStateNotArchivable: this.translateFn("eob.grid.systemcolumn.tooltip.arch.notarchivable"),
                archiveStateReference: this.translateFn("eob.grid.systemcolumn.tooltip.arch.reference"),
                lockStateVariantCheckedOutByMe: this.translateFn("eob.grid.systemcolumn.tooltip.variant.checkout.me"),
                lockStateVariantCheckedOutByOthers: this.translateFn("eob.grid.systemcolumn.tooltip.variant.checkout.others"),
                lockStateVariantUnlocked: this.translateFn("eob.grid.systemcolumn.tooltip.variant"),
                lockStateCheckedOutByOthers: this.translateFn("eob.grid.systemcolumn.tooltip.checkout.others"),
                lockStateCheckedOutByMe: this.translateFn("eob.grid.systemcolumn.tooltip.checkout.me"),
                notes: this.translateFn("eob.grid.systemcolumn.tooltip.notes"),
                links: this.translateFn("eob.grid.systemcolumn.tooltip.links"),
                annotations: this.translateFn("eob.grid.systemcolumn.tooltip.annotations"),
                typeless: this.translateFn("eob.grid.systemcolumn.object.typeless"),
                substitute: this.translateFn("eob.grid.inbox.systemcolumn.tooltip.substitute"),
                closureTime: this.translateFn("eob.grid.inbox.systemcolumn.tooltip.closuretime"),
                personalized: this.translateFn("eob.grid.inbox.systemcolumn.tooltip.personalized"),
                overtime: this.translateFn("eob.grid.inbox.systemcolumn.tooltip.overtime"),
                confirmationPending: this.translateFn("eob.grid.systemcolumn.tooltip.confirmed.pending"),
                confirmationApproved: this.translateFn("eob.grid.systemcolumn.tooltip.confirmed.yes"),
                confirmationDeclined: this.translateFn("eob.grid.systemcolumn.tooltip.confirmed.no"),
                isFavorite: this.translateFn("eob.grid.columnheader.tooltip.is.favorite"),
                notSynchronisedPhone: this.translateFn("eob.sync.last.time.no.phone"),
                syncStatus: this.translateFn("eob.grid.columnheader.tooltip.sync.status"),
                syncRunning: this.translateFn("eob.grid.systemcolumn.tooltip.syncStatus.running"),
                syncFailed: this.translateFn("eob.grid.systemcolumn.tooltip.syncStatus.failed"),
                signedbefore: this.translateFn("eob.grid.systemcolumn.tooltip.signature.before.state"),
                signedcurrent: this.translateFn("eob.grid.systemcolumn.tooltip.signature.current.state"),
                location: this.translateFn("eob.grid.columnheader.tooltip.location"),
                link: this.translateFn("eob.baseparam.reference"),
                note: this.translateFn("eob.baseparam.link")
            },
            headerTooltip: {
                objectType: this.translateFn("eob.grid.columnheader.tooltip.objecttype"),
                mimeType: this.translateFn("eob.grid.columnheader.tooltip.mimeType"),
                favorite: this.translateFn("eob.grid.columnheader.tooltip.favorite"),
                notificationTypeIndexData: this.translateFn("eob.grid.columnheader.tooltip.indexdata.edited"),
                notificationTypeContent: this.translateFn("eob.grid.columnheader.tooltip.content.edited"),
                notificationTypeLocationAdded: this.translateFn("eob.grid.columnheader.tooltip.location"),
                notificationTypeDeleted: this.translateFn("eob.grid.columnheader.tooltip.delete"),
                notificationTypeGeneral: this.translateFn("eob.grid.columnheader.tooltip.change"),
                notificationTypeObjectMoved: this.translateFn("eob.grid.columnheader.tooltip.object.moved"),
                subscription: this.translateFn("eob.grid.columnheader.tooltip.subscription"),
                email: this.translateFn("eob.grid.columnheader.tooltip.mail"),
                variant: this.translateFn("eob.grid.columnheader.tooltip.variant"),
                lockState: this.translateFn("eob.grid.columnheader.tooltip.locking"),
                archived: this.translateFn("eob.grid.columnheader.tooltip.archived"),
                notes: this.translateFn("eob.grid.columnheader.tooltip.notes"),
                links: this.translateFn("eob.grid.columnheader.tooltip.links"),
                annotations: this.translateFn("eob.grid.columnheader.tooltip.annotations"),
                object: this.translateFn("eob.grid.systemcolumn.object"),
                recentObjectAction: this.translateFn("eob.grid.columnheader.tooltip.recentobjectaction"),
                substitution: this.translateFn("eob.grid.columnheader.tooltip.substitution"),
                closureTime: this.translateFn("eob.grid.columnheader.tooltip.closuretime"),
                personalization: this.translateFn("eob.grid.columnheader.tooltip.personalization"),
                workflow: this.translateFn("eob.grid.columnheader.tooltip.workflow"),
                syncStatus: this.translateFn("eob.grid.columnheader.tooltip.sync.status"),
                workflowTray: this.translateFn("eob.grid.columnheader.tooltip.wftray"),
                edited: this.translateFn("eob.grid.columnheader.tooltip.edited"),
                signature: this.translateFn("eob.grid.columnheader.tooltip.signature")
            },
            cellContent: {
                variantBaseUnknown: this.translateFn("eob.variants.result.unkown")
            },
            headerCellContent: {
                offlineSyncStatus: this.translateFn("offline.object.sync.status"),
                workitemActivityName: this.translateFn("inbox.workflow.workitem"),
                workitemProcessName: this.translateFn("inbox.workflow.workitem.workflow"),
                workitemSubject: this.translateFn("inbox.workflow.workitem.subject"),
                workitemPersonalizedBy: this.translateFn("inbox.workflow.workitem.persoanlized.by"),
                workitemWarningTime: this.translateFn("inbox.workflow.workitem.warning.time"),
                workitemClosureTime: this.translateFn("inbox.workflow.workitem.closure.until"),
                workitemCreationTime: this.translateFn("inbox.workflow.workitem.created"),
                workflowTitle: this.translateFn("eob.grid.systemcolumn.workflow.title"),
                workflowInfo: this.translateFn("eob.grid.systemcolumn.workflow.info"),
                variantBasedOn: this.translateFn("eob.contextmenu.variants.based.on"),
                variantCreationDate: this.translateFn("eob.contextmenu.variants.at"),
                variantCreator: this.translateFn("eob.contextmenu.variants.from"),
                variantVersion: this.translateFn("eob.contextmenu.variants.variant"),
                subscriptionModifyDate: this.translateFn("eob.grid.systemcolumn.modification.datetime"),
                subscriptionModifiedBy: this.translateFn("eob.grid.systemcolumn.modification.by"),
                revisitPresentationDate: this.translateFn("eob.grid.systemcolumn.revised.date"),
                revisitCreator: this.translateFn("eob.grid.systemcolumn.revised.by"),
                failedSyncStatusInfo: this.translateFn("eob.grid.systemcolumn.failed.sync.status"),
                vtxRanking: this.translateFn("eob.grid.systemcolumn.ranking"),
                vtxPreview: this.translateFn("eob.grid.systemcolumn.preview"),
                info: this.translateFn("eob.grid.systemcolumn.info"),
                linkText: this.translateFn("eob.baseparam.link.text"),
                title: this.translateFn("eob.grid.systemcolumn.title"),
            }
        };
    }

    getIconCellContent(icon?: string, title?: string): GridCellContent {
        return {value: {icon, title}};
    }

    /**
     * returns the objecttype icon for the given dmsDocument
     */
    getObjectTypeIcon(docModel: DmsDocumentModel): GridCellContent {
        const title = docModel.name ? docModel.name : "";
        return this.getIconCellContent(docModel.icon, title);
    }

    /**
     * returns the signature state icon for the given dmsDocument
     */
    getSignaturStateIcon(docModel: DmsDocumentModel): GridCellContent {
        let icon: string, title: string;
        switch (docModel.baseParameters.signedState) {
            case "1":
                icon = "signed-version-before";
                title = this.dict.tooltip.signedbefore;
                break;
            case "2":
                icon = "signed-current-version";
                title = this.dict.tooltip.signedcurrent;
                break;
        }

        return this.getIconCellContent(icon, title);
    }

    /**
     * returns the favorites state icon for the given dmsDocument
     */
    getFavoriteIcon = (docModel: DmsDocumentModel): GridCellContent => {
        let icon: string, title: string;
        if (docModel.baseParameters.favorite) {
            icon = "favorite";
            title = this.dict.tooltip.isFavorite;
        }

        return this.getIconCellContent(icon, title);
    };

    /**
     * returns the objecttype icon for the given dmsDocument
     */
    getMimeTypeIcon = (docModel: DmsDocumentModel): GridCellContent => {
        let title: string, icon: string;

        if (docModel.fileProperties.mimeTypeGroup && (docModel.fileProperties.fileCount || 0) > 0) {
            icon = docModel.fileProperties.mimeTypeIconId;
            let fileExtension: string = docModel.fileProperties.fileExtension;
            let mimeTypeGroup: string = docModel.fileProperties.mimeTypeGroup || "";

            fileExtension = fileExtension == "" || fileExtension == void 0 ? "" : ` (${fileExtension})`;
            mimeTypeGroup = mimeTypeGroup.charAt(0) + mimeTypeGroup.slice(1).toLowerCase();

            if (docModel.baseParameters.archiveState == "REFERENCE") {
                icon = undefined;
            } else if (icon == void 0 && docModel.baseParameters.objectCount > 0) {
                icon = "16-unknown-file-format";
                title = `${this.dict.tooltip.unknownMimeType}${fileExtension}`;
            } else {
                title = `${mimeTypeGroup}${fileExtension}`;
            }
        }

        return this.getIconCellContent(icon ? `custom-icon-${icon}` : undefined, title);
    };

    /**
     * returns the cellcontent for each notification action column
     *
     * @param action - the action that caused the notification
     * @param item
     */
    private getSpecificubScriptionModifierType(action: string, item: TodoSubscriptionModel): GridCellContent {
        if (item.actions.indexOf(action) >= 0) {
            return this.getNotificationValueIcon(action);
        }

        return this.getIconCellContent();
    }

    /**
     * Returns the icon for the cause of the notification
     *
     * @param action - the action that caused the notification
     */
    private getNotificationValueIcon(action: string): GridCellContent {
        let icon: string, title: string;

        switch (action) {
            case "INDEXDATA_MODIFIED":
                icon = "indexdata-edited";
                title = this.dict.tooltip.notificationTypeIndexData;
                break;
            case "DOCUMENT_MODIFIED":
                icon = "content-edited";
                title = this.dict.tooltip.notificationTypeContent;
                break;
            case "LOCATION_ADDED":
                icon = "location";
                title = this.dict.tooltip.notificationTypeLocationAdded;
                break;
            case "OBJECT_DELETED":
                icon = "delete-row";
                title = this.dict.tooltip.notificationTypeDeleted;
                break;
            case "DOCUMENT_MOVED":
                icon = "object-moved";
                title = this.dict.tooltip.notificationTypeDocumentMoved;
                break;
            case "REGISTER_MOVED":
                icon = "object-moved";
                title = this.dict.tooltip.notificationTypeFolderMoved;
                break;
        }

        return this.getIconCellContent(icon, title);
    }

    /**
     * Returns the icon for the cause of the history entry
     *
     * @param action - the action that caused the history entry
     * @returns - returns the icon as a string
     */
    private getRecentObjectActionIcon(action: string): GridCellContent {
        let icon: string, title: string;
        switch (action) {
            case "INDEXDATA_CHANGED":
                icon = "indexdata-edited";
                title = this.dict.tooltip.notificationTypeIndexData;
                break;
            case "CONTENT_CHANGED":
                icon = "content-edited";
                title = this.dict.tooltip.notificationTypeContent;
                break;
            case "OBJECT_CREATED":
                icon = "dokument-neu";
                title = this.dict.tooltip.notificationTypeObjectCreated;
                break;
            case "DOCUMENT_MOVED":
                icon = "object-moved";
                title = this.dict.tooltip.notificationTypeDocumentMoved;
                break;
            case "REGISTER_MOVED":
                icon = "object-moved";
                title = this.dict.tooltip.notificationTypeFolderMoved;
                break;
        }

        return this.getIconCellContent(icon, title);
    }

    /**
     * Return the cellcontent for the recent action for the history state
     */
    private getRecentObjectAction(contextItem: DmsDocument): GridCellContent {
        let recentAction = "";

        // Get the Information for the recent object action hidden in the ecmSimpleFields
        for (const i in contextItem.model.fields) {
            if (i === "enaio:RECENT_OBJECT_ACTION") {
                recentAction = contextItem.model.fields[i];
            }
        }

        return this.getRecentObjectActionIcon(recentAction);
    }

    /**
     * Returns the cellcontent of each column that indicates why notifications are created
     */
    private getModifierType(contextItem: TodoSubscription): GridCellContent {
        return this.getNotificationValueIcon(contextItem.model.action);
    }

    /**
     * returns the syncstatus for the failed offline object
     */
    private getFailedSyncStatus(): GridCellContent {
        return this.getIconCellContent("sync-warning", this.dict.tooltip.syncFailed);
    }

    /**
     * returns the syncstatus for the progress of the offline object
     */
    private getSyncStatus(): GridCellContent {
        return {value: {percentage: undefined, failedCount: undefined}};
    }

    private getSyncStatusTitle({percentage, failedCount}: TodoSyncStatus): string {
        let title: string = "";

        if (failedCount > 0 && percentage == 1) {
            if (failedCount > 1) {
                title = `${failedCount as number}${this.translateFn("hitlist.syncStatus.failed.folder.register.multi")}`;
            } else if (failedCount === 1) {
                title = `${failedCount as number}${this.translateFn("hitlist.syncStatus.failed.folder.register.single")}`;
            } else {
                title = this.translateFn("hitlist.syncStatus.failed");
            }
        } else if (percentage == 1) {
            title = this.translateFn("hitlist.syncStatus.success");
        }

        return title;
    }

    private getSyncSvgElement(percentage: number, osid?: string): JQLite {
        const cssClass = `svg-animated-progress-ring ${osid}`;
        const svgElement: JQLite = angular.element(`<svg class="${cssClass}" height="32" width="36">
                        <circle class="progress-ring-circle" stroke-width="8" fill="transparent" r="4" cx="12" cy="18">
                            <title>${this.dict.tooltip.syncRunning}</title></circle></svg>`);

        const svgCircle: JQLite = svgElement.find(".progress-ring-circle");
        const strokeOffset: number = (26 - (percentage || 0) * 26);
        svgCircle.css("stroke-dashoffset", strokeOffset);

        return svgElement;
    }

    getSyncStatusTemplate(osid: string, {
        percentage,
        failedCount
    }: TodoSyncStatus = {}): string {
        const container: JQLite = angular.element("<span></span>");
        const title: string = this.getSyncStatusTitle({percentage, failedCount});
        const svgElement: JQLite = this.getSyncSvgElement(percentage, osid);

        container.append(svgElement);

        const hasFailedIcon: boolean = container.find(".icon-16-sync-warning").length > 0;
        const hasCompleteIcon: boolean = container.find(".icon-16-sync-complete").length > 0;

        if (percentage < 1) {
            container.removeClass("done");
            if (hasFailedIcon) {
                container.find(".icon-16-sync-warning").remove();
            }

            if (hasCompleteIcon) {
                container.find(".icon-16-sync-complete").remove();
            }
        }

        if (failedCount > 0 && percentage == 1 && !hasFailedIcon) {
            if (hasCompleteIcon) {
                container.find(".icon-16-sync-complete").remove();
            }

            container.append(`<i class='icon-16-sync-warning' title='${title}'></i>`);
            container.addClass("done");
        } else if (percentage == 1 && !hasCompleteIcon && !hasFailedIcon) {
            container.append(`<i class='icon-16-sync-complete'  title='${title}'></i>`);
            container.addClass("done");
        }

        return container[0].outerHTML;
    }

    getPhoneSyncStatus({percentage, failedCount}: TodoSyncStatus): GridPhoneSyncStatus {
        const title: string = this.getSyncStatusTitle({percentage, failedCount});
        const additionalContent: AdditionalPhoneCellContent = {content: "", class: "smartphone-sync-status"};
        const isSyncRunning: boolean = this.offlineService.isSyncRunning();

        let icon: Icon;
        let svgElement: JQLite;

        if (this.clientService.isLocalClient() && isSyncRunning) {
            additionalContent.content = this.translateFn("eob.grid.systemcolumn.tooltip.syncStatus.running");
        }

        if (percentage == 0 && isSyncRunning) {
            additionalContent.content = this.translateFn("eob.grid.systemcolumn.tooltip.syncStatus.running");
        } else if (percentage == 1) {
            if (failedCount > 0) {
                icon = {name: "sync-warning", title: ""};
            } else {
                icon = {name: "sync-complete", title: ""};
            }

            if (title) {
                additionalContent.content = title;
            }
        } else if (percentage > 0 && isSyncRunning) {
            svgElement = this.getSyncSvgElement(percentage);
        }

        return {additionalContent, icon, svgElement};
    }

    getVTXTemplate(params: ICellRendererParams): string {
        const value: string = params.data[params.colDef.field].value;

        // escape all < and > except for <em> elements, that the vtx engine added to highlight the matched search term
        const regex = /<em>|<\/em>/g;

        let m: RegExpExecArray, preI = 0, escapedText = "";
        while ((m = regex.exec(value)) !== null) {
            escapedText += ValueUtilsService.escapeHTML(value.substring(preI, m.index)); // escaped text
            escapedText += value.substring(m.index, m.index + m[0].length); // em element
            preI = m.index + m[0].length;
        }
        escapedText += ValueUtilsService.escapeHTML(value.substring(preI, value.length));

        return escapedText;
    }

    /**
     * returns the content for the favorite column
     */
    private getIsFavorite(contextItem: DmsDocument): GridCellContent {
        let icon: string, title: string;
        if (contextItem.model.isFavorite) {
            icon = "favorite";
            title = this.dict.tooltip.isFavorite;
        }

        return this.getIconCellContent(icon, title);
    }

    /**
     * returns the specific subscription notify type like EMAIL or INTERNAL
     * this notifies the user via EMAIL or by the internal system with a subscription in the users inbox
     *
     * @param type - The type how the user has to be notified
     * @param contextItem - The context specific item
     */
    private getSpecificSubscriptionNotificationType(type: string, contextItem: TodoSubscriptionModel): GridCellContent {
        let icon: string, title: string;
        if (contextItem.notifyType == type || contextItem.notifyType == "INTERNAL_AND_EMAIL") {
            switch (type) {
                case "INTERNAL":
                    icon = "abonnieren";
                    title = this.dict.tooltip.notifyTypeInternal;
                    break;
                case "EMAIL":
                    icon = "OT-Mail";
                    title = this.dict.tooltip.notifyTypeEmail;
                    break;
            }
        }

        return this.getIconCellContent(icon, title);
    }

    /**
     * Returns the icon for the active variant
     *
     * @param contextItem - The context specific item
     */
    getActiveVariantIcon = (contextItem: TodoVariantData): GridCellContent => {
        const cellContent: GridCellContent = {
            headerName: `<i class="icon-16-header-cell-variant-active" title="${this.dict.headerTooltip.variant}"></i>`,
            value: ""
        };

        if (contextItem.model.isActive) {
            cellContent.value = `<i class="icon-16-variant-active" title="${this.dict.tooltip.activeVariant}"></i>`;
        }

        return cellContent;
    };

    /**
     * Returns the title and description pattern for a dmsDocument for phone only.
     *
     * @param dmsDocument - The dms document.
     * @param context - The current state context.
     */
    getTitleAndDescriptionForPhone = (dmsDocument: DmsDocument, context?: string): GridPhoneCellContentValue => {
        const titleData: TitleAndDescription = dmsDocument.api.getTitleAndDescription();
        let icon: Icon;
        let additionalContent: AdditionalPhoneCellContent;
        let cellContent: any;
        let cellContentValue: any;

        if (dmsDocument.model.isTypeless) {
            titleData.title = this.dict.tooltip.typeless;
        }

        switch (context) {
            case "history":
            case "hitlist.history":
                const recentAction: string = dmsDocument.model.fields["enaio:RECENT_OBJECT_ACTION"];

                if (recentAction) {
                    const cellData = this.getRecentObjectActionIcon(recentAction).value;

                    icon = {name: cellData.icon, title: cellData.title};
                    additionalContent = {
                        content: cellData.title,
                        class: "history-last-action"
                    };
                }
                break;
            case "failedSyncObjects":
            case "hitlist.failedSyncObjects":
                icon = {name: "sync-warning", title: ""};
                additionalContent = {
                    content: this.getFailedSyncStatusInfo(dmsDocument.model.syncState).value,
                    class: "smartphone-sync-status"
                };
                break;
            case "offlineObjects":
            case "hitlist.offlineObjects":
                cellContent = {
                    title: titleData.title,
                    description: titleData.description,
                    filterValue: `${titleData.title} ${titleData.description}`,
                    isTitleBold: true
                };
                cellContentValue = {...cellContent};

                return Object.assign(cellContent, {value: cellContentValue});
            case "objectReferences":
                const referenceTypeKey: string = dmsDocument.model.links[0].kind;

                if (referenceTypeKey === "original") {
                    break;
                }

                additionalContent = {
                    content: this.dict.tooltip[referenceTypeKey],
                    class: ""
                };
                break;
        }

        if (context !== "objectReferences" && dmsDocument.model.baseParameters.archiveState == "REFERENCE") {
            additionalContent = {
                content: this.dict.tooltip.link,
                class: "reference-document"
            };
        }

        cellContent = {
            title: titleData.title,
            description: titleData.description,
            filterValue: `${titleData.title} ${titleData.description}`,
            isTitleBold: (context !== "abo" && context !== "revisit"),
            additionalContent,
            icon
        };
        cellContentValue = {...cellContent};

        return Object.assign(cellContent, {value: cellContentValue});
    };

    /**
     * Returns hitlist layout and info pattern for a workflow according to the context
     */
    getSmartphoneWorkflowInfo = (contextItem: TodoWorkflowContextModel, context: string): GridPhoneCellContentValue => {
        const titleData: TitleAndDescription = {title: "", description: ""};
        let additionalContent: AdditionalPhoneCellContent;

        switch (context) {
            case "startable":
                titleData.title = contextItem.title;
                titleData.description = contextItem.info;
                break;
            case "processes":
            case "workflow":
                const warningTime: number | string = contextItem.warningTime > 0 ? this.valueUtilsService.formatDate(contextItem.warningTime, true) : "";
                const creationTime: number | string = contextItem.creationTime > 0 ? this.valueUtilsService.formatDate(contextItem.creationTime, true) : "";

                titleData.title = `${contextItem.activityName as string} - ${contextItem.processName as string}`;
                titleData.description = contextItem.processSubject;

                if (warningTime) {
                    additionalContent = {
                        content: `${this.dict.headerCellContent.workitemWarningTime}: ${warningTime}`,
                        class: ""
                    };
                    if (Date.now() > contextItem.warningTime) {
                        additionalContent.class = "expired";
                    }
                } else {
                    additionalContent = {
                        content: `${this.dict.headerCellContent.workitemCreationTime}: ${creationTime}`,
                        class: ""
                    };
                }
                break;
        }

        const cellContent: GridPhoneCellContent = {
            title: titleData.title,
            description: titleData.description,
            filterValue: `${titleData.title} ${titleData.description}`,
            isTitleBold: context !== "workflow",
            additionalContent
        };
        const cellContentValue: any = {...cellContent};

        return Object.assign(cellContent, {value: cellContentValue});
    };

    /**
     * returns the cellcontent for the modification date of the entry
     */
    private getLastModifiedHistory(contextItem: DmsDocument): GridCellContent {
        return {
            headerName: this.dict.tooltip.modifiedDate,
            value: contextItem.model.baseParameters.modifyDate
        };
    }

    /**
     * returns the cellcontent for the archive state
     */
    getArchiveStateIcon = (docModel: DmsDocumentModel): GridCellContent => {
        let icon: string, title: string;
        if (docModel.mainType == "0" || docModel.mainType == "99" || this.objectTypeService.isTypeless(docModel.objectTypeId)) {
            return this.getIconCellContent();
        }

        let archiveState: string = docModel.baseParameters.archiveState;
        if (archiveState == void 0 && docModel.isTypeless) {
            archiveState = "NOT_ARCHIVABLE";
        }

        switch (archiveState) {
            case "ARCHIVED" :
                break;
            case "ARCHIVABLE" :
                icon = "archivable";
                title = this.dict.tooltip.archiveStateArchivable;
                break;
            case "NO_PAGES" :
                icon = "NO-PAGES";
                title = this.dict.tooltip.archiveStateNoPages;
                break;
            case "NOT_ARCHIVABLE" :
                icon = "NOT-ARCHIVABLE";
                title = this.dict.tooltip.archiveStateNotArchivable;
                break;
            case "REFERENCE" :
                icon = "REFERENCE";
                title = this.dict.tooltip.archiveStateReference;
                break;
        }

        return this.getIconCellContent(icon, title);
    };

    /**
     * returns the cellcontent for the lock- and variantstate
     */
    getLockStateIcon = (docModel: DmsDocumentModel): GridCellContent => {
        if (this.objectTypeService.isTypeless(docModel.objectTypeId)) {
            return this.getIconCellContent();
        }

        const isVariant: boolean = docModel.hasVariants,
            lockState: string | void = docModel.baseParameters.locked;

        if (docModel.mainType == "0" || docModel.mainType == "99" || lockState == void 0) {
            return this.getIconCellContent();
        }

        let icon: string, title: string;
        if (isVariant) {
            if (lockState == "SELF") {
                icon = "variant-yellow";
                title = this.dict.tooltip.lockStateVariantCheckedOutByMe;
            } else if (lockState == "OTHERS") {
                icon = "variant-red";
                title = this.dict.tooltip.lockStateVariantCheckedOutByOthers;
            } else {
                icon = "variant";
                title = this.dict.tooltip.lockStateVariantUnlocked;
            }
        } else if (lockState == "OTHERS") {
            icon = "lock-error";
            title = this.dict.tooltip.lockStateCheckedOutByOthers;
        } else if (lockState == "SELF") {
            icon = "lock";
            title = this.dict.tooltip.lockStateCheckedOutByMe;
        }

        return this.getIconCellContent(icon, title);
    };

    /**
     * returns the notes icon
     */
    getNotesIcon = (docModel: DmsDocumentModel): GridCellContent => {
        const isNotesColumnSplit: boolean = this.environmentService.featureSet.contains("user.setting.hitlist.iconcolumn");
        const notesCount: string | number = isNotesColumnSplit ? docModel.baseParameters.notes : docModel.baseParameters.links;
        const title: string = notesCount == void 0 ? "" : `${this.dict.tooltip.notes}${notesCount.toString()} `;

        let icon: string;
        if (notesCount > 0) {
            icon = "header-cell-notes";
        }

        return this.getIconCellContent(icon, title);
    };

    /**
     * returns the links icon
     */
    getLinksIcon = (docModel: DmsDocumentModel): GridCellContent => {
        const linksCount: number = docModel.baseParameters.links;
        const title: string = linksCount == void 0 ? "" : `${this.dict.tooltip.links}${linksCount.toString()} `;

        let icon: string;
        if (linksCount > 0) {
            icon = "links";
        }

        return this.getIconCellContent(icon, title);
    };

    /**
     * returns the annotations icon
     */
    getAnnotationsIcon = (docModel: DmsDocumentModel): GridCellContent => {
        const annotationCount: string | number = docModel.baseParameters.annotations;
        const title: string = annotationCount == void 0 ? "" : `${this.dict.tooltip.annotations}${annotationCount.toString()} `;

        let icon: string;
        if (annotationCount > 0) {
            icon = "annotations";
        }

        return this.getIconCellContent(icon, title);
    };

    /**
     * returns the name of the objecttype column
     */
    getObjectTypeName = (docModel: DmsDocumentModel, typeDef: ObjectType): GridCellContent => {
        const cellContent: GridCellContent = {
            headerName: this.dict.headerTooltip.object,
            value: ""
        };

        if (docModel.isTypeless) {
            cellContent.value = this.dict.tooltip.typeless;
        } else if (typeDef != void 0) {
            cellContent.value = typeDef.model.config.title;
        }

        return cellContent;
    };

    /**
     * Translates the status code into a human readable info.
     *
     * @param failedSyncStatus - status code
     */
    private getFailedSyncStatusInfo(failedSyncStatus: ObjectSyncFailedStatus): GridCellContent {
        let info: string;

        switch (failedSyncStatus) {
            case ObjectSyncFailedStatus.FAILED_ORIGINAL_CONTENT:
                info = this.translateFn("eob.sync.status.failed.original");
                break;
            case ObjectSyncFailedStatus.FAILED_PREVIEW_CONTENT:
                info = this.translateFn("eob.sync.status.failed.preview");
                break;
            case ObjectSyncFailedStatus.FAILED_EMAIL_ATTACHMENT:
                info = this.translateFn("eob.sync.status.failed.email.attachment");
                break;
            case ObjectSyncFailedStatus.FAILED_CONTENT_LIMIT:
                info = this.translateFn("eob.sync.offline.filesize.limit");
            default:
                info = this.translateFn("eob.sync.status.failed.unknown");
        }

        return {
            headerName: this.dict.headerCellContent.failedSyncStatusInfo,
            value: info
        };
    }

    /**
     * returns the cellcontent for the confirmstate column
     */
    private getConfirmState(contextItem: TodoSubscription | TodoRevisit): GridCellContent {
        let icon: string, title: string;
        if (contextItem.model.confirmed) {
            icon = "edited";
            title = this.dict.tooltip.confirmationApproved;
        } else if (contextItem.constructor.name == "Revisit" && contextItem.model.eventDate > new Date().getTime()) {
            icon = "revisited-unfired";
            title = this.dict.tooltip.confirmationPending;
        } else if (contextItem.constructor.name != "Subscription" || contextItem.model.confirmationType != "NO_CONFIRMATION") {
            icon = "unedited";
            title = this.dict.tooltip.confirmationDeclined;
        }
        return this.getIconCellContent(icon, title);
    }

    /**
     * returns the cellcontent for the substitute icon column
     */
    private getWorkflowSubstituteIcon(contextItem: TodoWorkflowContextModel): GridCellContent {
        const icon: string = contextItem.substitute == true ? "nav-workflow-substitution" : undefined;
        return this.getIconCellContent(icon, this.dict.headerTooltip.substitution);
    }

    /**
     * returns the cellcontent for the closure time icon column
     */
    private getWorkflowClosureTimeIcon(contextItem: TodoWorkflowContextModel): GridCellContent {
        const icon: string = contextItem.closureTime ? "workflow-closure-time" : undefined;
        return this.getIconCellContent(icon, this.dict.tooltip.closureTime);
    }

    /**
     * returns the cellcontent for the personalized icon column
     */
    private getWorkflowPersonalizedIcon(contextItem: TodoWorkflowContextModel): GridCellContent {
        const icon: string = contextItem.personalized ? "workflow-is-personalized" : undefined;
        return this.getIconCellContent(icon, this.dict.tooltip.personalized);
    }

    /**
     * returns the cellcontent for the overtime icon column
     */
    private getWorkflowOverTimeIcon(contextItem: TodoWorkflowContextModel): GridCellContent {
        const icon: string = contextItem.overTime == true ? "unedited" : undefined;
        return this.getIconCellContent(icon, this.dict.tooltip.overtime);
    }

    /**
     * returns the workflow specific icon or the default workflow icon
     */
    private getWorkflowIcon(contextItem: TodoWorkflowContextModel): GridCellContent {
        let iconClass = "workflow-startable-blue";
        if (contextItem.iconId && this.iconService.hasIcon(contextItem.iconId)) {
            iconClass = `custom-icon-${contextItem.iconId}`;
        }
        return this.getIconCellContent(iconClass);
    }

    /**
     * returns the location icon for the work and info area of a workflow file to edetermine if it has a location or not
     */
    private getLocationIcon(docModel: DmsDocumentModel): GridCellContent {
        let icon: string, title: string;
        if (docModel.isDocument && (docModel.isinWfTray || docModel.isTypeless)) {
            icon = "dokument-standortlos";
            title = this.dict.headerTooltip.workflowTray;
        }

        return this.getIconCellContent(icon, title);
    }

    /**
     * returns a css object indicating to draw the text content bold or normal
     *
     * @param params - ag-grid params including grid api
     */
    getColumnCellStyle = (params: ICellRendererParams): TodoHtmlStyle => {
        if (params.data.read != void 0) {
            return params.data.read == false ? {"font-weight": "bold"} : {"font-weight": "normal"};
        }

        const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(params.data.osid);
        if (dmsDocument == void 0) {
            return;
        }

        const contextItem: TodoSubscription | TodoRevisit | TodoWorkflowContext = dmsDocument.api.getContextItem(params.data.guid);
        if (contextItem == void 0) {
            return;
        }

        return contextItem.model.read == false ? {"font-weight": "bold"} : {"font-weight": "normal"};
    };

    /**
     * Returns the column definiton for a static text column
     *
     * @param col - the configured column
     * @param index
     */
    getStaticTextCol = (col: TodoStaticColumnData, index: number | string): ColumnDefinition => {
        const column: ColumnDefinition = {
            headerName: "",
            type: "TEXT",
            field: `f${index}`,
            editable: false,
            cellStyle: this.getColumnCellStyle,
            enabled: col.enabled
        };

        switch (col.columnType) {
            case "objectType":
                column.internalName = "staticColObjectType";
                column.headerName = this.dict.headerTooltip.object;
                break;
            case "aboModifyDate" :
                column.headerName = this.dict.headerCellContent.subscriptionModifyDate;
                column.type = "DATETIME";
                break;
            case "aboModifier" :
                column.headerName = this.dict.headerCellContent.subscriptionModifiedBy;
                break;
            case "aboInfo" :
                column.headerName = this.dict.headerCellContent.info;
                break;
            case "presentationDate":
                column.headerName = this.dict.headerCellContent.revisitPresentationDate;
                column.type = "DATETIME";
                break;
            case "revisionCreator":
                column.headerName = this.dict.headerCellContent.revisitCreator;
                break;
            case "comment":
                column.headerName = this.dict.headerCellContent.linkText;
                break;
            case "title":
                column.headerName = this.dict.headerCellContent.title;
                break;
            case "info":
                column.headerName = this.dict.headerCellContent.info;
                break;
            case "workflowTitle":
                column.headerName = this.dict.headerCellContent.workflowTitle;
                break;
            case "workflowInfo":
                column.headerName = this.dict.headerCellContent.workflowInfo;
                break;
            case "workflowActivityName":
                column.headerName = this.dict.headerCellContent.workitemActivityName;
                column.internalName = "WFITEMNAME";
                break;
            case "workflowProcessName":
                column.headerName = this.dict.headerCellContent.workitemProcessName;
                column.internalName = "WFNAME";
                break;
            case "workflowSubject":
                column.headerName = this.dict.headerCellContent.workitemSubject;
                column.internalName = "WFSUBJECT";
                break;
            case "workflowPersonalizedBy":
                column.headerName = this.dict.headerCellContent.workitemPersonalizedBy;
                column.internalName = "WFPERSONALIZED";
                break;
            case "workflowCreationTime":
                column.headerName = this.dict.headerCellContent.workitemCreationTime;
                column.internalName = "WFCREATION";
                column.type = "DATETIME";
                break;
            case "workflowWarningTime":
                column.headerName = this.dict.headerCellContent.workitemWarningTime;
                column.internalName = "WFCLOSURE";
                column.type = "DATETIME";
                break;
            case "workflowClosureTime":
                column.headerName = this.dict.headerCellContent.workitemClosureTime;
                column.type = "DATETIME";
                break;
            case "ranking":
                column.headerName = this.dict.headerCellContent.vtxRanking;
                column.type = "DECIMAL";
                column.cellRenderer = params => `<span class="cell-value">${Number(params.value).toFixed()}</span>`;
                break;
            case "preview":
                column.headerName = this.dict.headerCellContent.vtxPreview;
                column.cellRenderer = this.getVTXTemplate.bind(this);
                break;
            case "failedSyncStatusInfo" :
                column.headerName = this.dict.headerCellContent.failedSyncStatusInfo;
                column.internalName = "staticTextColFailedSyncStatusInfo";
                column.cellRenderer = (params) => {
                    const value: string = params.data[params.colDef.field].value;
                    const container: JQLite = angular.element("<span class=cell-value-bold></span>");
                    container.append(value);
                    return container[0];
                };
                break;
            case "historyLastModified" :
                column.headerName = this.dict.tooltip.modifiedDate;
                column.type = "DATETIME";
                break;
            case "activityModelName" :
                column.headerName = this.dict.tooltip.modifiedDate;
                column.type = "DATETIME";
                break;
            case "activityDisplayName" :
                column.headerName = this.dict.tooltip.modifiedDate;
                column.type = "DATETIME";
                break;
            case "taskPerformers" :
                column.headerName = this.dict.tooltip.modifiedDate;
                column.type = "DATETIME";
                break;
            case "taskPeriod" :
                column.headerName = this.dict.tooltip.modifiedDate;
                column.type = "DATETIME";
                break;
            case "taskDueTo" :
                column.headerName = this.dict.tooltip.modifiedDate;
                column.type = "DATETIME";
                break;
            case "taskRemark" :
                column.headerName = this.dict.tooltip.modifiedDate;
                column.type = "DATETIME";
                break;
        }

        return column;
    };

    /**
     * adds the parameter columns to the column definiton in case we are insidew the workflow inbox or the running workflows
     */
    addParameterColumns = (columns: ColumnDefinition[], listEntries: TodoListEntry[]): void => {
        let maxNumberOfColumns = 0;
        listEntries.forEach((entry: TodoListEntry) => {
            if (entry.workflowParameters.length > maxNumberOfColumns) {
                maxNumberOfColumns = entry.workflowParameters.length;
            }
        });

        const completeColumnCount: number = maxNumberOfColumns + columns.length;

        for (let i: number = columns.length; i < completeColumnCount; i++) {
            const colField = `f${i}`;
            columns.push({
                headerName: "",
                field: colField,
                internalName: colField,
                editable: false,
                cellStyle: this.getColumnCellStyle
            });
        }
    };

    /**
     * updates generic parameter columns in the GridData
     *
     * @param listEntry - a list entry from the workflow inboxes
     * @param gridDataColumns
     * @param firstNonStaticColumnIndex - the first index where generic columns can be inserted
     */
    updateParameterColumns(listEntry: TodoListEntry, gridDataColumns: ColumnDefinition[], firstNonStaticColumnIndex: number): void {
        listEntry.workflowParameters.forEach((workflowParameter: TodoWorkflowParameter) => {
            let type: string = workflowParameter.type;

            if (type == "DATE" || type == "TIME") {
                type = "DATETIME";
            } else if (type == "INTEGER") {
                type = "number";
            }

            const pos: number = workflowParameter.position as number + firstNonStaticColumnIndex;
            gridDataColumns[pos].type = type;
        });
    }

    /**
     * adds contextspecific data to the rows
     */
    addContextObjectData = (contextItem: TodoContextItem, rowTemplate: TodoRowTemplate, staticColumns: TodoStaticColumnData, docModel?: DmsDocumentModel): void => {
        for (const index in staticColumns) {
            const col: TodoStaticColumnData = staticColumns[index];
            const cell = `f${index}`;

            switch (col.columnType) {
                case "location":
                    rowTemplate[cell] = this.getLocationIcon(docModel);
                    break;
                case "workflowIcon":
                    rowTemplate[cell] = this.getWorkflowIcon(contextItem);
                    break;
                case "workflowOvertime":
                    rowTemplate[cell] = this.getWorkflowOverTimeIcon(contextItem);
                    break;
                case "workflowPersonalized":
                    rowTemplate[cell] = this.getWorkflowPersonalizedIcon(contextItem);
                    break;
                case "workflowClosuretime":
                    rowTemplate[cell] = this.getWorkflowClosureTimeIcon(contextItem);
                    break;
                case "workflowSubstitute":
                    rowTemplate[cell] = this.getWorkflowSubstituteIcon(contextItem);
                    break;
                case "workflowActivityName":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workitemActivityName,
                        value: contextItem.activityName
                    };
                    break;
                case "workflowProcessName":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workitemProcessName,
                        value: contextItem.processName
                    };
                    break;
                case "workflowSubject":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workitemSubject,
                        value: contextItem.processSubject
                    };
                    break;
                case "workflowPersonalizedBy":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workitemPersonalizedBy,
                        value: contextItem.personalized || ""
                    };
                    break;
                case "workflowCreationTime":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workitemCreationTime,
                        value: this.valueUtilsService.formatDate(contextItem.creationTime, true)
                    };
                    break;
                case "workflowWarningTime":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workitemWarningTime,
                        value: contextItem.warningTime > 0 ? this.valueUtilsService.formatDate(contextItem.warningTime, true) : ""
                    };
                    break;
                case "workflowClosureTime":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workitemClosureTime,
                        value: contextItem.closureTime > 0 ? this.valueUtilsService.formatDate(contextItem.closureTime, true) : ""
                    };
                    break;
                case "confirmed" :
                    rowTemplate[cell] = this.getConfirmState(contextItem);
                    break;
                case "aboNotificationType" :
                    rowTemplate[cell] = this.getModifierType(contextItem);
                    break;
                case "recentObjectAction" :
                    rowTemplate[cell] = this.getRecentObjectAction(contextItem);
                    break;
                case "isFavorite" :
                    rowTemplate[cell] = this.getIsFavorite(contextItem);
                    break;
                case "syncStatus":
                    rowTemplate[cell] = this.getSyncStatus();
                    break;
                case "failedSyncStatus":
                    rowTemplate[cell] = this.getFailedSyncStatus();
                    break;
                case "activeVariantIcon" :
                    rowTemplate[cell] = this.getActiveVariantIcon(contextItem);
                    break;
                case "variantOf" :
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.variantBasedOn,
                        value: contextItem.model.originVersion == void 0 ? this.dict.cellContent.variantBaseUnknown : contextItem.model.originVersion
                    };
                    break;
                case "variantCreated" :
                    // Todo: Not nice but DMS2 don't return the date only. We should move to date+time which is in
                    //       baseParamters.created but currently the column has to little width. I could not find
                    //       the virtual grid position were to setup the width for this column. Finally I killed
                    //       the time part for now to have it as it was before.
                    const v = this.valueUtilsService.dateToLocaleDate(docModel.baseParameters.created);

                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.variantCreationDate,
                        value: v.substring(0, v.indexOf(" ")),
                    };
                    break;
                case "variantCreator" :
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.variantCreator,
                        value: docModel.baseParameters.creator
                    };
                    break;
                case "variantVersion" :
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.variantVersion,
                        value: contextItem.model.version
                    };
                    break;
                case "aboModifyDate" :
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.subscriptionModifyDate,
                        value: dayjs(contextItem.model.eventDate).format(this.environmentService.env.dateFormat.datetime)
                    };
                    break;
                case "aboModifier" :
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.subscriptionModifiedBy,
                        value: contextItem.model.sender.name
                    };
                    break;
                case "aboInfo" :
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.info,
                        value: contextItem.model.infoText
                    };
                    break;
                case "presentationDate":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.revisitPresentationDate,
                        value: dayjs(contextItem.model.eventDate).format(this.environmentService.env.dateFormat.datetime)
                    };
                    break;
                case "revisionCreator":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.revisitCreator,
                        value: contextItem.model.sender.fullname || contextItem.model.sender.name
                    };
                    break;
                case "comment":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.linkText,
                        value: contextItem.model.infoText
                    };
                    break;
                case "title":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.title,
                        value: contextItem.title
                    };
                    break;
                case "workflowTitle":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workflowTitle,
                        value: contextItem.title
                    };
                    break;
                case "info":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.info,
                        value: contextItem.info
                    };
                    break;
                case "workflowInfo":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.workflowInfo,
                        value: contextItem.info
                    };
                    break;
                case "ranking":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.vtxRanking,
                        value: (contextItem.model.vtxscore || 0).toFixed().toString()
                    };
                    break;
                case "preview":
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.vtxPreview,
                        value: contextItem.model.preview
                    };
                    break;
                case "aboIndexdataModifier":
                    rowTemplate[cell] = this.getSpecificubScriptionModifierType("INDEXDATA_MODIFIED", contextItem.model);
                    break;
                case "aboContentModifier":
                    rowTemplate[cell] = this.getSpecificubScriptionModifierType("DOCUMENT_MODIFIED", contextItem.model);
                    break;
                case "aboDeletionModifier":
                    rowTemplate[cell] = this.getSpecificubScriptionModifierType("OBJECT_DELETED", contextItem.model);
                    break;
                case "aboLocationAddedModifier":
                    rowTemplate[cell] = this.getSpecificubScriptionModifierType("LOCATION_ADDED", contextItem.model);
                    break;
                case "aboObjectMovedNotification":
                    if (contextItem.model.actions.indexOf("REGISTER_MOVED") != -1) {
                        rowTemplate[cell] = this.getSpecificubScriptionModifierType("REGISTER_MOVED", contextItem.model);
                    } else if (contextItem.model.actions.indexOf("DOCUMENT_MOVED") != -1) {
                        rowTemplate[cell] = this.getSpecificubScriptionModifierType("DOCUMENT_MOVED", contextItem.model);
                    }

                    break;
                case "aboServerNotification":
                    rowTemplate[cell] = this.getSpecificSubscriptionNotificationType("INTERNAL", contextItem.model);
                    break;
                case "aboMailNotification":
                    rowTemplate[cell] = this.getSpecificSubscriptionNotificationType("EMAIL", contextItem.model);
                    break;
                case "historyLastModified":
                    rowTemplate[cell] = this.getLastModifiedHistory(contextItem);
                    break;
                case "failedSyncStatusInfo":
                    rowTemplate[cell] = this.getFailedSyncStatusInfo(contextItem.model.syncState);
                    break;
                case "referenceComment":
                    const comment: string = rowTemplate.referenceComment ?? "";
                    rowTemplate[cell] = {
                        headerName: this.dict.headerCellContent.linkText,
                        value: comment ? comment : "" /* DODO-12086 we have no tooltipsd currently in the grid */
                    };
                    break;
            }
        }
    };

    /**
     * Returns a `HeaderComponentParams` skeleton with raw HTML inserted in place of the indended value.
     * This approach is required, as ag-grid otherwise escapes HTML inside column header values.
     *
     * @param value
     * @param sortArrowStyle if desired, additional styling can be added to the `span` elements' inside a style for the sorting arrows
     */
    static getHeaderComponentParams(value: string, sortArrowStyle?: string): { template: string } {
        return {
            template:
                `${"<div class=\"ag-cell-label-container\" role=\"presentation\">" +
                "  <span ref=\"eMenu\" class=\"ag-header-icon ag-header-cell-menu-button\"></span>" +
                "  <div ref=\"eLabel\" class=\"ag-header-cell-label\" role=\"presentation\">" +
                `    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ${sortArrowStyle ? ` style="${sortArrowStyle}"` : ""}></span>` +
                `    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ${sortArrowStyle ? ` style="${sortArrowStyle}"` : ""}></span>` +
                `    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ${sortArrowStyle ? ` style="${sortArrowStyle}"` : ""}></span>` +
                `    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ${sortArrowStyle ? ` style="${sortArrowStyle}"` : ""}></span>`}${
                    value
                }    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>` +
                "  </div>" +
                "</div>"
        };
    }

    getStaticPhoneContentColumn = (col: TodoStaticColumnData, index: string | number): ColumnDefinition => {
        const column: ColumnDefinition = {
            internalName: "",
            enabled: col.enabled,
            field: `f${index}`,
            isIconCell: false,
            checkboxSelection: false,
            resizable: false,
            sortable: false,
            suppressSizeToFit: true,
            title: "",
            cellRenderer: "phoneCellRenderer",
            cellStyle: this.getColumnCellStyle
        };

        return column;
    };

    /**
     * Returns the column definiton for a static icon column.
     *
     * @param col - the configured column
     * @param index
     */
    getStaticIconCol = (col: TodoStaticColumnData, index: string | number): ColumnDefinition => {
        const column: ColumnDefinition = {
            headerName: "",
            headerComponentParams: {},
            internalName: "",
            enabled: col.enabled,
            field: `f${index}`,
            width: 36,
            isIconCell: true,
            checkboxSelection: false,
            resizable: false,
            suppressMovable: true,
            suppressSizeToFit: true,
            title: "",
            cellRenderer: "iconCellRenderer",
            headerComponent: "iconCellHeaderRenderer"
        };

        switch (col.columnType) {
            case "checkbox":
                column.checkboxSelection = true;
                column.internalName = "staticColCheckbox";
                column.headerComponentParams = {
                    icon: "checkbox-ok"
                };
                column.comparator = (valueA, valueB, nodeA, nodeB) => nodeA.selected - nodeB.selected;
                break;
            case "workflowIcon":
                column.internalName = "staticColWorkflow";
                column.headerComponentParams = {
                    icon: "header-cell-workflow",
                    title: this.dict.headerTooltip.workflow
                };
                break;
            case "workflowOvertime":
                column.internalName = "staticColOvertime";
                column.headerComponentParams = {
                    icon: "header-cell-edited",
                    title: this.dict.headerTooltip.edited
                };
                break;
            case "workflowPersonalized":
                column.internalName = "staticColPersonalized";
                column.headerComponentParams = {
                    icon: "header-cell-personalization",
                    title: this.dict.headerTooltip.personalization
                };
                break;
            case "workflowClosuretime":
                column.internalName = "staticColClosuretime";
                column.headerComponentParams = {
                    icon: "header-cell-closure-time",
                    title: this.dict.headerTooltip.closureTime
                };
                break;
            case "workflowSubstitute":
                column.internalName = "staticColSubstitute";
                column.headerComponentParams = {
                    icon: "header-cell-substitution",
                    title: this.dict.headerTooltip.substitution
                };
                break;
            case "dmsIcon":
                column.internalName = "staticColDmsIcon";
                column.headerComponentParams = {
                    icon: "header-cell-objecttype",
                    title: this.dict.headerTooltip.objectType
                };
                break;
            case "signature":
                column.internalName = "staticColSignature";
                column.headerComponentParams = {
                    icon: "header-cell-signature",
                    title: this.dict.headerTooltip.signature
                };
                break;
            case "mimeType":
                column.internalName = "staticColMimeType";
                column.headerComponentParams = {
                    icon: "header-cell-mimetype",
                    title: this.dict.headerTooltip.mimeType
                };
                break;
            case "favorite":
                column.internalName = "staticColFavorite";
                column.headerComponentParams = {
                    icon: "header-cell-favorite",
                    title: this.dict.headerTooltip.favorite
                };
                break;
            case "archiveState" :
                column.internalName = "staticColArchiveState";
                column.headerComponentParams = {
                    icon: "header-cell-archived",
                    title: this.dict.headerTooltip.archived
                };
                break;
            case "lockState" :
                column.internalName = "staticColLockState";
                column.headerComponentParams = {
                    icon: "header-cell-locking",
                    title: this.dict.headerTooltip.lockState
                };
                break;
            case "notes" :
                column.internalName = "staticColNotes";
                column.headerComponentParams = {
                    icon: "header-cell-notes",
                    title: this.dict.headerTooltip.notes
                };
                break;
            case "links" :
                column.internalName = "staticColLinks";
                column.headerComponentParams = {
                    icon: "header-cell-links",
                    title: this.dict.headerTooltip.links
                };
                break;
            case "annotations" :
                column.internalName = "staticColAnnotations";
                column.headerComponentParams = {
                    icon: "header-cell-annotations",
                    title: this.dict.headerTooltip.annotations
                };
                break;
            case "confirmed" :
                column.internalName = "staticColConfirmed";
                column.headerComponentParams = {
                    icon: "header-cell-edited",
                    title: this.dict.headerTooltip.edited
                };
                break;
            case "activeVariantIcon" :
                column.internalName = "staticColVariant";
                column.title = this.dict.headerTooltip.variant;
                column.headerName = `<i class="icon-16-header-cell-variant-active" title="${column.title}"></i>`;
                column.headerComponentParams = GridContentUtilsService.getHeaderComponentParams(column.headerName);
                break;
            case "aboNotificationType" :
                column.internalName = "staticColNotificationType";
                column.headerComponentParams = {
                    icon: "header-cell-change",
                    title: this.dict.headerTooltip.notificationTypeGeneral
                };
                break;
            case "aboIndexdataModifier" :
                column.internalName = "staticColIndexdataEdited";
                column.headerComponentParams = {
                    icon: "header-cell-indexdata-edited",
                    title: this.dict.headerTooltip.notificationTypeIndexData
                };
                break;
            case "aboContentModifier" :
                column.internalName = "staticColContentEdited";
                column.headerComponentParams = {
                    icon: "header-cell-content-edited",
                    title: this.dict.headerTooltip.notificationTypeContent
                };
                break;
            case "aboDeletionModifier" :
                column.internalName = "staticColObjectDeleted";
                column.headerComponentParams = {
                    icon: "delete-dark",
                    title: this.dict.headerTooltip.notificationTypeDeleted
                };
                break;
            case "aboLocationAddedModifier" :
                column.internalName = "staticColLocationAdded";
                column.headerComponentParams = {
                    icon: "location-dark",
                    title: this.dict.headerTooltip.notificationTypeLocationAdded
                };
                break;
            case "aboObjectMovedNotification" :
                column.internalName = "staticColObjectMoved";
                column.headerComponentParams = {
                    icon: "object-moved-header",
                    title: this.dict.headerTooltip.notificationTypeObjectMoved
                };
                break;
            case "aboServerNotification" :
                column.internalName = "staticColAboServerNotification";
                column.headerComponentParams = {
                    icon: "header-cell-subscription",
                    title: this.dict.headerTooltip.subscription
                };
                break;
            case "aboMailNotification" :
                column.internalName = "staticColAboMailNotification";
                column.headerComponentParams = {
                    icon: "header-cell-mail",
                    title: this.dict.headerTooltip.email
                };
                break;
            case "location" :
                column.internalName = "staticColWithoutLocation";
                column.headerComponentParams = {
                    icon: "header-cell-standortlos",
                    title: this.dict.headerTooltip.workflowTray
                };
                break;
            case "recentObjectAction" :
                column.internalName = "staticColRecentObjectAction";
                column.headerComponentParams = {
                    icon: "header-cell-change",
                    title: this.translateFn("eob.grid.columnheader.tooltip.recentobjectaction")
                };
                break;
            case "isFavorite" :
                column.internalName = "staticColIsFavotite";
                column.headerComponentParams = {
                    icon: "header-cell-favorite",
                    title: this.dict.headerTooltip.isFavorite
                };
                break;
            case "failedSyncStatus" :
                column.internalName = "staticColFailedSyncStatus";
                column.headerComponentParams = {
                    icon: "header-cell-sync-status",
                    title: this.dict.headerTooltip.syncStatus
                };
                break;
            case "syncStatus" :
                column.internalName = "staticColSyncStatus";
                column.cellRenderer = params => this.getSyncStatusTemplate(params.data.osid, params.value);
                column.headerComponentParams = {
                    icon: "header-cell-sync-status",
                    title: this.dict.headerTooltip.syncStatus
                };
                column.comparator = (a: TodoSyncStatus, b: TodoSyncStatus): number => {
                    if (a.percentage == b.percentage) {
                        // noinspection NestedConditionalExpressionJS
                        return !!a.failedCount == !!b.failedCount ? 0 : a.failedCount < b.failedCount ? 1 : -1;
                    }
                    return a.percentage > b.percentage ? 1 : -1;
                };
                break;
        }

        return column;
    };

    static getColumnDefinition(field: string, headerName: string, config: TodoStaticColumnData = {}): ColumnDefinition {
        return {
            headerName,
            field,
            cellRenderer: config.withTooltip ? (params => `<span title="${params.value as string}">${params.value as string}</span>`) : undefined
        };
    }
}
