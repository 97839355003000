import {Injectable} from "@angular/core";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {InboxContextMenuItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ConfirmationObject, InboxActionContextMenuItem} from "CORE_PATH/interfaces/inbox-action.service.interface";

@Injectable({providedIn: BackendOsRestModule})
export class OsrestRevisitService {

    constructor(private httpClient: HttpClient) {
    }

    markReadRevisits(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>("/osrest/api/notifications/revisits/markread/", data);
    }

    markUnreadRevisits(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>("/osrest/api/notifications/revisits/markread/?reset=true", data);
    }

    markAsEditedUneditedRevisits(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>("/osrest/api/notifications/revisits/process/", data);
    }

    markSingleAsEditedUneditedRevisits(contextItem: InboxContextMenuItem, confirmationObject: ConfirmationObject): Observable<void> {
        const confirmWithPassword: boolean = contextItem.model.confirmationType == "CONFIRMATION_PASSWORD";
        let url: string = `/osrest/api/notifications/revisits/process/${contextItem.model.osid}/${contextItem.model.eventDate}`;

        if (confirmWithPassword) {
            url += `?confirmed=true&password=${btoa(confirmationObject.password)}`;
        } else {
            url += "?confirmed=true";
        }

        return this.httpClient.get<void>(url);
    }
}
