<div>
    <div class="container">
        <div *ngIf="fieldType != 'time'"
             class="toolbar-container">
            <div class="date-picker-toolbar"></div>
        </div>

        <div class="date-time-container">
            <div [hidden]="!isSearch" class="custom-date-picker">
                <button [hidden]="isPhone || isTime" [ngClass]="{active: isRangeActive}"
                        [attr.aria-label]="'modal.datepicker.interval' | ngTranslate"
                        class='date-range-button'>{{'modal.datepicker.interval' | ngTranslate}}</button>
                <button [hidden]="!isPhone && !isTime" [ngClass]="{open: isRangeActive, close: !isRangeActive}"
                        class='time-range-button button-transparent'>
                    <eob-icon *ngIf="!isRangeActive" name="date-time-range" size="24"
                              title="{{'modal.datepicker.interval' | ngTranslate}}"></eob-icon>
                    <eob-icon *ngIf="isRangeActive" name="date-time-point" size="24"
                              title="{{'modal.datepicker.interval' | ngTranslate}}"></eob-icon>
                </button>
                <span *ngIf="isRangeActive && (isPhone || isTime)"
                      class="range-input-label">{{'modal.datepicker.range.label' | ngTranslate}}</span>
            </div>

            <div class="date-containers">
                <div class="date-time-input-container" [ngClass]="{active: !isRangeInputActive}">
                    <input class="date-time-input-modal" (keydown.enter)="confirm()" type="text"/>
                    <button [hidden]="fieldType == 'time'"
                            class="today-button empty-calendar-icon range-start">
                        <eob-icon title="{{'modal.datepicker.today' | ngTranslate}}" name="empty-calendar-icon"
                                  size="32"></eob-icon>
                        <span class="range-start-current-day"></span>
                    </button>
                </div>

                <span *ngIf="isRangeActive && !isPhone && !isTime"
                      class="range-input-label">{{'modal.datepicker.range.label' | ngTranslate}}</span>
                <div [hidden]="!isRangeActive"
                     class="date-time-input-container"
                     [ngClass]="{active: isRangeInputActive}">
                    <input class="range-input-modal" type="text" (keydown.enter)="confirm()"/>
                    <div [hidden]="fieldType == 'time'"
                         class="today-button empty-calendar-icon range-end">
                        <eob-icon name="empty-calendar-icon" size="32"
                                  title="{{'modal.datepicker.today' | ngTranslate}}"></eob-icon>
                        <span class="range-end-current-day"></span>
                    </div>
                </div>
                <input class="date-time-value-input" hidden/>
            </div>
        </div>

        <div class="toolbar-container-phone"
             *ngIf="isPhone && fieldType == 'datetime'"
             [ngClass]="{'range-active': isPhone && isRangeActive}">
            <div class="toolbar-container-phone-date" (click)="toggleDateTime(true)"
                 [ngClass]="{'active': isDateActive}"></div>
            <div class="toolbar-container-phone-time" (click)="toggleDateTime(false)"
                 [ngClass]="{'active': !isDateActive}"></div>
        </div>

        <div class="date-time-picker-container">
            <div [hidden]="fieldType == 'time'" class="date-picker"></div>
            <div [hidden]="fieldType == 'date'" class="time-picker">
                <div class=hours>
                    <span>{{"modal.datepicker.timepicker.hours.label" | ngTranslate}}</span>
                    <div class="scroll-container">
                        <ul class="hours-list"></ul>
                    </div>
                </div>
                <div class="minutes">
                    <span>{{"modal.datepicker.timepicker.minutes.label" | ngTranslate}}</span>
                    <div class="scroll-container">
                        <ul class="minutes-list"></ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="button-container">
        <button class="secondary-button" (click)="close()">{{'modal.button.cancel' | ngTranslate}}</button>
        <button [hidden]="!isPhoneOrTablet" class="secondary-button"
                (click)="clear()">{{'modal.button.clear' | ngTranslate}}</button>
        <button class="confirm-button" (click)="confirm()">{{'modal.button.apply' | ngTranslate}}</button>
    </div>
</div>
