(function () {

    require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
    require("SERVICES_PATH/workflow/eob.workflow.model.srv.js");
    require("SERVICES_PATH/eob.state.history.manager.srv.js");
    require("SERVICES_PATH/workflow/eob.routing.list.srv.js");
    require("SERVICES_PATH/eob.modal.dialog.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/form/eob.form.srv.js");
    require("SERVICES_PATH/eob.search.srv.js");
    require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");

    const {DatabaseEntryType} = require("ENUMS_PATH/database/database-entry-type.enum");
    const {ProfileCacheKey} = require("ENUMS_PATH/database/profile-cache-key.enum");
    const {FormEvent} = require("MODULES_PATH/form/enums/form-event.enum");

    angular.module("eob.framework").directive("eobWorkflow", EobWorkflow);

    EobWorkflow.$inject = ["cacheManagerService", "workflowModelService", "notificationsService", "stateHistoryManager", "fileCacheService",
        "routingListService", "modalDialogService", "environmentService", "objectTypeService", "backendService",
        "$stateParams", "formService", "valueUtilsService", "searchService", "formHelper", "progressbarService", "clientService",
        "$filter", "$state", "$timeout", "$q", "$location", "viewerService", "messageService"];

    function EobWorkflow(CacheManagerService, WorkflowModelService, NotificationsService, StateHistoryManager, FileCacheService,
                         RoutingListService, ModalDialogService, EnvironmentService, ObjectTypeService, BackendService,
                         $stateParams, FormService, ValueUtilsService, SearchService, FormHelper, ProgressbarService, ClientService,
                         $filter, $state, $timeout, $q, $location, ViewerService, MessageService) {
        return {
            restrict: "E",
            async link(scope, element) {
                let globalListenerUnbindFunction = null;
                let progressbar;

                scope.ready = false;
                scope.dataReady = false;
                scope.hiddenTabs = [];
                scope.dropDownOpen = false;
                scope.switchTo = switchTo;

                scope.toggleDropdown = function () {
                    scope.dropDownOpen = !scope.dropDownOpen;
                    if (scope.dropDownOpen) {
                        $timeout(() => {
                            bindGlobalListener();
                        }, 0);
                    }
                };

                initFooterConfig();
                initProgressbar();
                let autosavedData = await FileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, ProfileCacheKey.AUTOSAVED_DATA, {first: true})
                let isRestore = $location.search().restoreAutosave == "true" && autosavedData != void 0;
                // get the current state infos --> these are optional in case the user navigates back
                let stateId = $stateParams.state;
                let stateConfig = StateHistoryManager.getCurrentConfig($stateParams.state);

                if (stateConfig.canceled == true) {
                    NotificationsService.error($filter("translate")("eob.workflow.invalid.state.error"));
                    $state.go("hitlist.inbox", {type: "workflow"});
                    return;
                }

                let helperConfig = {
                    modelDef: null,
                    formData: null,
                    isWorkflow: true,
                    workitemId: $stateParams.id,
                    save: FormService.saveWorkflow,
                    forward: FormService.forwardWorkflow,
                    cancel: FormService.cancel,
                    switchWorkflowTabs: scope.switchTo,
                    hideWorkflowTabs,
                    progressbar
                };

                let dropdownTitles = {
                    mask: $filter("translate")("eob.workflow.state.dropdown.mask.title"),
                    files: $filter("translate")("eob.workflow.state.dropdown.files.title"),
                    protocol: $filter("translate")("eob.workflow.state.dropdown.protocol.title"),
                    routingList: $filter("translate")("eob.workflow.state.dropdown.circulation.title")
                };

                scope.activeTab = "mask";
                scope.activeTabName = dropdownTitles[scope.activeTab];
                scope.stateTitle = "Workflow";

                let formReadySubscription;

                await initAsync();

                /**
                 * Initialize the workflow directive.
                 *
                 * @access private
                 * @returns {Promise} Resolved once the directive is completely initialized.
                 */
                async function initAsync() {
                    let workflowData;


                    // Todo DODO-13393
                    // FormService.setBeforeCancelExecuted({isExecuted: false});

                    try {
                        workflowData = await BackendService.get(`/workflows/running/full/${$stateParams.id}?refresh=true&personalize=true&clienttype=${EnvironmentService.wfClientType}`);
                        workflowData = workflowData.data;
                    } catch (error) {
                        NotificationsService.backendError(error, "eob.workflow.error");
                        if(ClientService.isOnline()) {
                            let nextStateId = Date.now();
                            StateHistoryManager.setStateData({ config: { userAction: undefined }, type: "workflow" }, nextStateId);
                            $state.go("hitlist.inbox", { type: "workflow", state: nextStateId });
                        } else {
                            ClientService.executeStateErrorFallback();
                        }
                        return;
                    }

                    let workflowDef = WorkflowModelService.getWorkflowModel(workflowData);

                    scope.stateDescription = `${workflowDef.config.processName} - ${workflowDef.config.name}`;
                    scope.isProcessResponsible = workflowDef.isProcessResponsible();
                    scope.formFields = workflowDef.getFields(true);

                    if (isRestore) {
                        scope.routingList = autosavedData.routingList;
                    } else if (stateConfig != void 0 && stateConfig.routingList != void 0) {
                        scope.routingList = stateConfig.routingList
                    } else {
                        scope.routingList = workflowDef.getRoutingList();
                    }

                    let parameters = workflowDef.getParameters();
                    let formData = FormService.createFormData(scope.formFields, "max");

                    FormService.addWorkflowData(formData, parameters);
                    await FormService.disableFieldsAsync(formData);

                    helperConfig.modelDef = workflowDef;
                    helperConfig.formData = formData;
                    helperConfig.parameters = parameters;
                    helperConfig.routingList = scope.routingList;

                    // set the initial parameters and form fields
                    // we set this function inside the formHelper to access this from anywhere outside this form
                    // but still connected to the current workflow or form
                    // we do this to access the tab from custom scripts or functions that do not know the current condition
                    scope.formHelper = FormHelper.getFormHelper(helperConfig);
                    scope.formDef = {
                        isMainForm: true,
                        validationMode: "max",
                        formFields: scope.formFields,
                        formHelper: scope.formHelper,
                        formLayout: helperConfig.modelDef.getLayout(),
                    };
                    scope.modelDef = workflowDef;

                    FormService.initFormAutoSave(scope.formHelper, scope.routingList);

                    addParametersApi(parameters);

                    addWorkflowProtocol(parameters);
                    addRoutingList();

                    let fileDataPromise = prepareFilesAsync(workflowDef);
                    let scriptDataPromise = fetchScriptsAsync(workflowData);

                    await Promise.all([fileDataPromise, scriptDataPromise]);

                    // optional restoring the last state config in case the user left the workflow with unsaved changes
                    // and comes back after whatever he was doing
                    await restoreStateConfig();

                    let promises = [];

                    let maskFormResolve, maskFormPromise = new Promise((resolve) => {
                        maskFormResolve = resolve;
                    });
                    promises.push(maskFormPromise);

                    formReadySubscription = MessageService.subscribeFirst(FormEvent.FORM_READY, () => {
                        maskFormResolve();
                    });

                    if (scope.formHelper.hasRoutingList) {
                        let routingListResolve, routingListPromise = new Promise((resolve) => {
                            routingListResolve = resolve;
                        });
                        promises.push(routingListPromise);

                        scope.$on("circulation.grid.ready", (event) => {
                            event.stopPropagation();
                            routingListResolve();
                        });
                    }

                    scope.dataReady = true;
                    // apply the dataReady change
                    scope.$apply();

                    await Promise.all(promises);
                    scope.formHelper.focusFirstField();
                    await executeBeforeOpenScript();

                    scope.ready = true;

                    // Todo DODO-13393
                    // FormService.setBeforeCancelExecuted({isExecuted: FormService.getBeforeCancelExecuted().isExecuted, formHelper: scope.formHelper});

                    progressbar.hide();
                    progressbar = null;
                }

                function initFooterConfig() {
                    scope.footerConfigs = [{
                        icon: { name: "footer-back-dark", title: $filter("translate")("form.footer.back") },
                        action: "back",
                        class: "footer-button secondary-button"
                    }, {
                        icon: { name: "footer-save-dark", title: $filter("translate")("form.footer.save") },
                        action: "saveWorkflow",
                        class: "secondary-button footer-save-button footer-button"
                    }, {
                        icon: { name: "footer-forward", title: $filter("translate")("form.footer.forward") },
                        action: "submit",
                        class: "footer-button"
                    }]
                }

                /**
                 * Initialize the progressbar.
                 *
                 * @access private
                 */
                function initProgressbar() {
                    progressbar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);
                    setTimeout(() => {
                        if (progressbar != void 0) {
                            progressbar.show();
                        }
                    }, 50);
                }

                function bindGlobalListener() {
                    globalListenerUnbindFunction = scope.$on("close.inline.dialogs", () => {
                        scope.dropDownOpen = false;
                        unbindGlobalListener();
                    });
                }

                function unbindGlobalListener() {
                    globalListenerUnbindFunction();
                }

                function hideWorkflowTabs(tabs) {
                    if (!Array.isArray(tabs)) {
                        tabs = [tabs];
                    }

                    for (let i = 0; i < tabs.length; i++) {
                        let tab = tabs[i];

                        if (dropdownTitles[tab] == void 0) {
                            console.warn("Workflow tab does not exist --> ", tab);
                            continue;
                        }

                        scope.hiddenTabs.push(tab);
                    }
                }

                function switchTo(destination) {
                    if (dropdownTitles[destination] == void 0) {
                        console.warn("Workflow tab does not exist --> ", destination);
                        return;
                    }

                    scope.activeTab = destination;
                    scope.activeTabName = dropdownTitles[destination];
                    scope.dropdownVisible = false;
                }

                function addParametersApi(parameters) {
                    let fields = scope.formHelper.getFields();

                    for (let i in parameters) {
                        let parameter = parameters[i];
                        let field = fields[parameter.model.fieldId];
                        parameter.api = WorkflowModelService.getParameterApi(scope.formHelper, parameter, field);
                    }
                }

                function addRoutingList() {
                    scope.hasRoutingList = scope.routingList != void 0;

                    if (scope.hasRoutingList) {
                        scope.canEditRoutingList = (scope.isProcessResponsible || EnvironmentService.userHasRole("R_CLNT_WFROUTELIST"));
                        RoutingListService.addRoutingListApi(scope.routingList, scope.formHelper);
                    } else {
                        hideWorkflowTabs("routingList");
                    }
                }

                function addWorkflowProtocol(parameters) {
                    for (let i in parameters) {
                        if (parameters[i].model["name"] == "wfProtocol") {
                            scope.protocol = parameters[i];
                            scope.hasProtocol = true;
                            break;
                        }
                    }

                    if (scope.protocol == void 0) {
                        hideWorkflowTabs("protocol");
                        scope.hasProtocol = false;
                    }
                }

                async function restoreStateConfig() {
                    // nothing to restore
                    if (!isRestore && (!stateConfig || (typeof(stateConfig) == "object" && Object.keys(stateConfig).length == 0))) {
                        return;
                    }

                    let restoreData = {
                        activeTab: stateConfig.activeTab,
                        params: isRestore ? autosavedData.parameters : stateConfig.params,
                        formData: isRestore ? autosavedData.fields : stateConfig.formData,
                        routingList: isRestore ? autosavedData.routingList : stateConfig.routingList
                    }

                    if (restoreData.activeTab) {
                        scope.activeTab = restoreData.activeTab;
                        scope.activeTabName = dropdownTitles[scope.activeTab];
                    }

                    if (restoreData.routingList) {
                        let routingList = restoreData.routingList;

                        for (let i in routingList.model.groups) {
                            let group = routingList.model.groups[i];
                            group.routingList = routingList;

                            for (let j in group.model.items) {
                                let item = group.model.items[j];
                                item.group = group;
                            }
                        }
                    }

                    let params = scope.formHelper.getParameters();
                    let formData = scope.formHelper.getFields();

                    for (let key in restoreData.params) {
                        let param = restoreData.params[key];

                        if (params[key]) {
                            params[key].value = param.value;
                        }
                    }

                    for (let key in restoreData.formData) {
                        let field = restoreData.formData[key];

                        if (formData[key]) {
                            formData[key].value = field.value;

                            if (formData[key].model.type == "grid") {
                                formData[key].gridData = field.gridData;
                            }
                        }
                    }
                }

                /**
                 * Get the missing workflow scripts and bind them to the FormHelper.
                 *
                 * @access private
                 * @param {object} data - The backend data of the workflow.
                 * @returns {Promise} Resolved once all scripts are bound.
                 */
                async function fetchScriptsAsync(data) {
                    let wfId = data.WorkItem.WorkflowId,
                        activityId = data.WorkItem.ActivityId;

                    try {
                        let response = await BackendService.get(`/workflows/clientScripts/${wfId}/${activityId}?clienttype=${EnvironmentService.wfClientType}`);
                        await scope.formHelper.bindScripts(response.data);
                    } catch (error) {
                        let title = $filter("translate")("modal.confirm.workflow.get.script.error.title");
                        let msg = $filter("translate")("modal.confirm.workflow.get.script.error.message");
                        let cancel = $filter("translate")("modal.button.close");

                        ModalDialogService.infoDialog(title, msg, cancel).then(undefined, () => {
                            $state.go("hitlist.inbox", {type: "workflow"});
                        }).catch(err => console.error(err));

                        throw error;
                    }
                }

                /**
                 * Execute the BeforeOpenScript of the workflow.
                 *
                 * @returns {Promise}
                 */
                async function executeBeforeOpenScript() {
                    try {
                        await FormService.executeFormScript("beforeOpen", scope.formHelper);
                    } catch (error) {
                        // Firefox so slow, is funny to me
                        setTimeout(() => {
                            ClientService.executeStateErrorFallback();
                        }, 0);

                        throw error;
                    }
                }

                /**
                 * Fetch all necessary workflow files and add them sorted to the formHelper.
                 *
                 * @access private
                 * @param {WorkflowModel} workflowDef - The workflow model.
                 * @returns {Promise} Resolved once the files are added.
                 */
                async function prepareFilesAsync(workflowDef) {
                    let fileIds = await fetchFilesAsync(workflowDef);

                    // in case previous actions stored a wfTrayFile into state data, add it to scope and formHelper
                    if (stateConfig.wfTrayFile != void 0) {
                        let wfTrayId = stateConfig.wfTrayFile.id.toString();

                        if (fileIds.indexOf(wfTrayId) < 0) {
                            try {
                                // adds the wfItem to dmsDocument and the dmsDocument to the cache
                                await scope.formHelper.addFile(stateConfig.wfTrayFile.id, stateConfig.wfTrayFile.typeId, stateConfig.wfTrayFile.fileArea, "2");
                                fileIds.push(wfTrayId);
                            } catch (error) {
                                if (error.status != 403) {
                                    console.error(error);
                                }
                            }
                        }
                    }

                    // documents from server are in random order.
                    // sort them inital by their osid. The grid can afterwards resort them.
                    fileIds.sort((a, b) => {
                        return parseInt(a) - parseInt(b);
                    });

                    scope.formHelper.setFiles(CacheManagerService.dmsDocuments.get(fileIds));
                }

                /**
                 * Fetch all workflow files.
                 *
                 * @access private
                 * @param {WorkflowModel} workflowDef - The workflow model.
                 * @returns {Promise<Array>} Resolved with the ids of all files once all files are recieved.
                 */
                async function fetchFilesAsync(workflowDef) {
                    let docIds = [];
                    let promises = [];
                    let wfItems = workflowDef.getFiles();
                    let stateCfgFiles = isRestore ? autosavedData.files : stateConfig.files;

                    let viewerId = null;

                    for (let id in wfItems) {
                        let wfItem = wfItems[id];

                        if (wfItem.display || viewerId == void 0) {
                            viewerId = id
                        }

                        let isDeleted = true;
                        if (stateCfgFiles != void 0) {
                            for (let cfgFileId in stateCfgFiles) {
                                let stateCfgFile = stateCfgFiles[cfgFileId];

                                if (stateCfgFile.model.originalId == wfItem.originalId) {
                                    isDeleted = false;
                                    wfItem = stateCfgFile.model;
                                    break;
                                }
                            }
                        } else {
                            isDeleted = false;
                        }

                        if (isDeleted) {
                            continue;
                        }

                        // test for permission to see the objectType of wfItem or if wfItem is typeless
                        if (CacheManagerService.objectTypes.contains(wfItem.objectTypeId) || ObjectTypeService.isTypeless(wfItem.objectTypeId)) {
                            let promise = getFile(id, wfItem.objectTypeId, wfItem.location, docIds, wfItem);
                            promises.push(promise);
                        } else {
                            addForbiddenFile(id, wfItem, docIds);
                        }
                    }

                    if (viewerId != void 0) {
                        ViewerService.updateViewer(viewerId);
                    }

                    if (stateCfgFiles != void 0 && Object.keys(stateCfgFiles).length > 0) {
                        for (let key in stateCfgFiles) {
                            let file = stateCfgFiles[key],
                                fileData = file.model;

                            if (fileData.isNew) {
                                let promise = getFile(key, fileData.objectTypeId, fileData.location, docIds, fileData);
                                promises.push(promise);
                            }
                        }
                    }

                    await Promise.all(promises);
                    return docIds;
                }

                /**
                 * Add a file dummy to the workflow files,
                 * for files that the user is not allowed to see.
                 *
                 * @param {string} id - Osid of the forbidden file.
                 * @param {WfFile} wfFile - The wfFile to the forbidden File.
                 * @param {string[]} docIds - An array of osids, which the osid of the forbidden file has to be added to.
                 */
                function addForbiddenFile(id, wfFile, docIds) {
                    let forbiddenFile = {
                        model: {
                            isForbidden: true,
                            osid: id,
                            objectTypeId: wfFile.objectTypeId,
                            wfItem: [{
                                model: {wfFile}
                            }]
                        }
                    };

                    docIds.push(id);
                    CacheManagerService.dmsDocuments.add(forbiddenFile);
                }

                function getFile(id, objectTypeId, location, docIds, wfItem) {
                    let deferred = $q.defer();

                    SearchService.searchWorkflowFileDocument(id, objectTypeId, location, wfItem.useActiveVariant).then((response) => {
                        if (response != "" && response != void 0) {
                            response = response.length > 0 ? response[0] : response;
                            response.wfItem = wfItem;
                            let docId = CacheManagerService.dmsDocuments.add(response);
                            docIds.push(docId[0]);
                        } else {
                            addForbiddenFile(id, wfItem, docIds);
                        }

                        deferred.resolve();
                        return;
                    }, (error) => {
                        addForbiddenFile(id, wfItem, docIds);
                        deferred.resolve();
                    }).catch(err => console.error(err));

                    return deferred.promise;
                }

                scope.$on("hitlist.config.changed", (ev, objectTypeId) => {
                    let files = scope.formHelper.getFiles();
                    for (let i in files) {
                        if (files[i].objectTypeId == objectTypeId) {
                            $state.reload();
                            break;
                        }
                    }
                });

                scope.$on("$destroy", () => {
                    if (!scope.formHelper) {
                        return;
                    }

                    let files = scope.formHelper.getWfFiles();
                    let formData = scope.formHelper.getFields();
                    let params = scope.formHelper.getParameters();
                    let routingList;

                    if (scope.formHelper.hasRoutingList) {
                        routingList = FormService.convertRoutingListForSerialization(scope.routingList);
                    }

                    let newData = FormService.reduceFormdata(formData);

                    StateHistoryManager.updateConfig({
                        activeTab: scope.activeTab,
                        files,
                        formData: newData,
                        params,
                        routingList
                    }, stateId);

                    // Todo DODO-13393
                    // if (!FormService.getBeforeCancelExecuted().isExecuted) {
                    //     FormService.executeFormScript("beforeCancel", scope.formHelper, false);
                    // }

                    scope.formHelper.destroy();

                    formReadySubscription.unsubscribe();
                });
            }
        };
    }

    module.exports = EobWorkflow;
})();
