import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {NavigationState} from "MODULES_PATH/navigation/interfaces/navigation.interface";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {OfflineSynchronizationMessage} from "ENUMS_PATH/offline/offline-synchronization-message.enum";
import {OverallSyncStatus} from "ENUMS_PATH/offline/offline-global-sync-status.enum";
import {Store} from "@ngrx/store";
import {selectAvailableStates} from "MODULES_PATH/navigation/+state/navigation.selectors";
import {NavigationEvents} from "MODULES_PATH/navigation/enums/navigation-events.enum";
import {ExternalTrayEvents} from "MODULES_PATH/external-tray/enums/external-tray-events.enum";

interface RootScope extends ng.IRootScopeService {
    updateAvailable: boolean;
}

@Component({
    selector: "eob-nav-bar",
    templateUrl: "./eob-nav-bar.component.html",
    styleUrls: ["./eob-nav-bar.component.scss"],
})
export class EobNavBarComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() navBarTitle: string;
    @Output() changePinState: EventEmitter<boolean> = new EventEmitter();

    private readonly translate: TranslateFnType;
    private readonly TRAY_UPDATING_PULSE_INTERVAL: number = 15000;
    private fillHeight: number = 0;
    private subs: Subscription = new Subscription();
    private nav: NavigationState;
    private gradientStops: Node;
    private lastFocusedElement: HTMLElement;

    @ViewChild("enaioHomeContainer", {static: false}) enaioHomeContainer: ElementRef<HTMLElement>;
    @ViewChild("navBarBottom", {static: false}) navBarBottom: ElementRef<HTMLElement>;
    @ViewChild("navBarTop", {static: false}) navBarTop: ElementRef<HTMLElement>;

    showUpdateBadge: boolean = false;
    showUpdatePulse: boolean = false;
    externalTrayRefreshed: boolean = false;
    absUrl: string;
    nav$: Observable<NavigationState>;

    // eslint-disable-next-line max-params
    constructor(@Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("$rootScope") private $rootScope: RootScope,
                @Inject("$filter") private $filter: ng.IFilterService,
                private navStore: Store<NavigationState>,
                private messageService: MessageService,
                private notificationsService: NotificationsService,
                private asIniService: AsIniService,
                private renderer: Renderer2,
                private clientService: ClientService,
                public navigationService: NavigationService,
                private cdRef: ChangeDetectorRef) {
        this.translate = this.$filter("translate");
        this.absUrl = `url(${window.location.href.replace(window.location.hash, "")}#bottom-to-top)`;

        this.nav$ = this.navStore.select(selectAvailableStates);
    }

    ngOnInit(): void {
        this.subs.add(this.navStore.select(selectAvailableStates).subscribe(state => {
            this.nav = state;
        }));

        this.subs.add(this.messageService.subscribe(NavigationEvents.FOCUS_NAV_BAR, () => {
            this.setFocusOnNavBar();
        }));

        this.subs.add(this.messageService.subscribe(OfflineSynchronizationMessage.GLOBAL_SYNC_STATUS_CHANGED, ({status, percentage}) => {
            if ([OverallSyncStatus.ABORTED, OverallSyncStatus.ABORTING_QUOTA, OverallSyncStatus.ABORTING_QUOTA, OverallSyncStatus.ABORTING_OFFLINE, OverallSyncStatus.FAILED].includes(status)) {
                this.fillHeight = 0;
            } else {
                /**
                 * The fillHeight calculates inner background height of the star icon during the offline synchronization
                 */
                this.fillHeight = 0.1 * Math.tan(2.8 * (percentage - 0.5)) + 0.5;

                let favSVG: HTMLElement;
                if (percentage == 1) {
                    try {
                        favSVG = this.renderer.selectRootElement("#animated-fav-svg", true);
                    } catch (_) {
                        // button is currently hidden
                        return;
                    }

                    favSVG.classList.add("finished");

                    /**
                     * 6 seconds to remove finished class from the filled out star icon
                     * and to show the default one, after the offline synchronization is finished
                     */
                    setTimeout(() => {
                        favSVG.classList.remove("finished");
                        this.fillHeight = 0;
                        this.renderer.setAttribute(this.gradientStops, "offset", `${this.fillHeight}`);
                    }, 6000);
                }
            }

            this.renderer.setAttribute(this.gradientStops, "offset", `${this.fillHeight}`);
        }));
    }

    ngAfterViewInit(): void {
        this.lastFocusedElement = this.enaioHomeContainer.nativeElement;

        let animatedFavSvg: HTMLElement;

        try {
            animatedFavSvg = this.renderer.selectRootElement("#animated-fav-svg", true);
            this.gradientStops = animatedFavSvg.querySelector("stop");
        } catch (_) {
            // button is currently hidden
        }

        if (this.$rootScope.updateAvailable) {
            this.showUpdateBadge = true;
        }

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.subs.add(this.messageService.subscribe(ExternalTrayEvents.TRAY_ELEMENTS_CHANGED, async () => {
            const previousExternalTrayCount: number = this.navigationService.externalTrayItemCount;

            await this.navigationService.setExternalTrayStateAsync();

            if (this.navigationService.activeTab === "externaltray" && !this.nav.showExternalTray) {
                if (this.clientService.isOnline()) {
                    this.navigationService.switchNavContent( "objectsearch", true);
                } else {
                    if (this.navigationService.isFixed) {
                        this.changePinState.emit(true);
                    }

                    this.navigationService.closeNavigation(500);
                }
            }

            if (previousExternalTrayCount < this.navigationService.externalTrayItemCount) {
                this.externalTrayRefreshed = true;

                setTimeout(() => {
                    this.externalTrayRefreshed = false;
                }, this.TRAY_UPDATING_PULSE_INTERVAL);
            }
            this.cdRef.detectChanges();
        }));

        this.$rootScope.$on("update.available", async () => {
            this.triggerUpdatePulse();
            const previousExternalTrayCount: number = this.navigationService.externalTrayItemCount || 0;
            await this.navigationService.setExternalTrayStateAsync();

            if (this.navigationService.activeTab === "externaltray" && !this.nav.showExternalTray) {
                this.navigationService.switchNavContent("objectsearch", false);
            }

            if (previousExternalTrayCount < this.navigationService.externalTrayItemCount) {
                this.externalTrayRefreshed = true;
                setTimeout(() => {
                    this.externalTrayRefreshed = false;
                }, this.TRAY_UPDATING_PULSE_INTERVAL);

                this.notificationsService.info(this.translate("external.tray.element.added.notification"));
            }
        });
    }

    @HostListener("window:resize", ["$event"])
    handleWindowResize(): void {
        this.lastFocusedElement.firstElementChild.setAttribute("tabindex", "-1");
        this.lastFocusedElement = this.enaioHomeContainer.nativeElement;
        this.lastFocusedElement.firstElementChild.setAttribute("tabindex", "0");
    }

    handleFocusEvent(event: FocusEvent): void {
        if (!event.target) {
            return;
        }
        const target: HTMLElement = event.target as HTMLElement;
        this.lastFocusedElement.firstElementChild.setAttribute("tabindex", "-1");
        this.lastFocusedElement = target.parentElement;
        target.setAttribute("tabindex", "0");
    }

    @HostListener("keydown", ["$event"])
    handleArrowKeyDown(event: KeyboardEvent): void {
        if (event.key != "ArrowUp" && event.key != "ArrowDown") {
            return;
        }

        const lastFocusWasInTopNavBar: boolean = this.lastFocusedElement.parentElement.parentElement.classList.contains("nav-bar-top");
        const arrowDownWasPressed: boolean = event.key == "ArrowDown";

        if ((arrowDownWasPressed && this.lastFocusedElement?.nextElementSibling?.classList.contains("nav-tab-container"))
            || (!arrowDownWasPressed && this.lastFocusedElement?.previousElementSibling?.classList.contains("nav-tab-container"))) {
            this.lastFocusedElement.firstElementChild.setAttribute("tabindex", "-1");
            this.lastFocusedElement = arrowDownWasPressed ? this.lastFocusedElement.nextElementSibling as HTMLElement : this.lastFocusedElement.previousElementSibling as HTMLElement;
        } else {
            const navBar: HTMLElement = lastFocusWasInTopNavBar ? this.navBarBottom.nativeElement : this.navBarTop.nativeElement;
            this.lastFocusedElement.firstElementChild.setAttribute("tabindex", "-1");
            this.lastFocusedElement = arrowDownWasPressed ? navBar.firstElementChild as HTMLElement : navBar.lastElementChild as HTMLElement;

            if (!arrowDownWasPressed && this.lastFocusedElement.classList.contains("i-got-your-back")) {
                this.lastFocusedElement = this.lastFocusedElement.previousElementSibling as HTMLElement;
            }
        }

        (this.lastFocusedElement.firstElementChild as HTMLElement).focus();
    }


    showUserMenu(event: Event): void {
        this.navigationService.showUserMenu(event);
        (this.lastFocusedElement.firstElementChild as HTMLElement).blur();
    }

    setFocusOnNavBar(): void {
        if (this.lastFocusedElement) {
            (this.lastFocusedElement.firstElementChild as HTMLElement).focus();
        }
    }

    private triggerUpdatePulse(): void {
        this.showUpdatePulse = true;
        this.showUpdateBadge = true;
        this.$rootScope.updateAvailable = true;

        setTimeout(() => {
            this.showUpdatePulse = false;
        }, this.TRAY_UPDATING_PULSE_INTERVAL);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
