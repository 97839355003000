(function() {
    

    angular.module("eob.framework").directive("eobDemoContact", EobDemoContact);

    EobDemoContact.$inject = ["$transitions", "$state", "$timeout", "environmentService"];

    function EobDemoContact($transitions, $state, $timeout, EnvironmentService) {
        return {
            restrict: "E",
            link(scope) {

                let interval;
                // 1/5/10 min
                let timeIntervals = [60000, 300000, 600000];
                let counter = 0;
                let containerRemoved = false;

                scope.dashboardState = $state.current.name == "dashboard";
                scope.containerOpened;

                init();

                function init() {
                    if ($state.current.name == "dashboard") {
                        scope.containerOpened = true;
                    } else {
                        scope.containerOpened = false;
                        showDemoContact(timeIntervals[counter]);
                    }
                };

                function showDemoContact(timeInterval) {
                    interval = setInterval(() => {
                        if (!containerRemoved) {
                            scope.containerOpened = true;
                            clearInterval(interval);

                            $timeout(() => {
                               interval = false;
                            }, 0);
                        }
                    }, timeInterval);
                };

                scope.close = function() {
                    scope.containerOpened = false;

                    if (counter < timeIntervals.length - 1) {
                        counter++;
                        showDemoContact(timeIntervals[counter]);
                    } else {
                        containerRemoved = true
                    }
                };

                scope.contact = function() {
                    if ($state.current.name !== "dashboard") {
                        scope.containerOpened = false;
                    }

                    clearInterval(interval);
                    containerRemoved = true;

                    // electron throws console error after opening the new window
                    try {
                        const currentLang = EnvironmentService.getLanguage();

                        if (currentLang === "fr" || currentLang === "en") {
                            window.open("https://www.optimal-systems.de/en/software/enaio-mobile-feedback", "_blank");
                        } else {
                            window.open("https://www.optimal-systems.de/software/mobile-feedback", "_blank");
                        }
                    } catch (_) {
                        // TODO known issue in electron 2.0.2
                    }
                };

                $transitions.onSuccess({}, () => {
                    if (scope.dashboardState) {
                        scope.containerOpened = false;
                    }

                    scope.dashboardState = $state.current.name == "dashboard";

                    if (scope.dashboardState) {
                        if(scope.containerOpened) {
                            counter++;
                        }
                        scope.containerOpened = true;
                    } else if (!interval && !scope.containerOpened && !scope.dashboardState && !containerRemoved) {
                        init();
                    }
                });
            },
            template: require("!raw-loader!./eob.demo.contact.html")
        };
    }
})();
