import {Injectable, Inject} from "@angular/core";
import {StateService} from "@uirouter/core/lib/state/stateService";
import {UIRouter} from "@uirouter/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {DmsModule} from "MODULES_PATH/dms/dms.module";
import {DmsDocumentService} from "MODULES_PATH/dms/dms-document.service";
import {DmsContentService} from "MODULES_PATH/dms/dms-content.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {DmsMessageType} from "MODULES_PATH/dms/enums/dms-message-type.enum";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {CustomError} from "CORE_PATH/models/custom-error/custom-error.model";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {filter, map, mergeMap, switchMap, toArray} from "rxjs/operators";
import {HttpEventType, HttpEvent, HttpResponse} from "@angular/common/http";
import {ERROR_CODES} from "SHARED_PATH/models/error-model.config";
import {ObjectLink, ObjectLinkResponse} from "CORE_PATH/backend/interfaces/object-link.interface";
import {Observable, from, EMPTY, forkJoin} from "rxjs";
import {CheckedOutFile, ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {BackendObjectService} from "CORE_PATH/backend/services/object/backend-object.service";
import {catchError, mergeAll, reduce} from "rxjs/operators";
import {BackendInsertUpdateObject} from "CORE_PATH/backend/interfaces/insert-update/backend-insert-update-object.interface";
import {TodoAsIniService, TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import { IdPair } from "INTERFACES_PATH/id-pair.interface";
import * as iconv from "iconv-lite";

const NOT_ARCHIVABLE: string = "NOT_ARCHIVABLE";
const ARCHIVABLE: string = "ARCHIVABLE";
let ctrlPressed: boolean = false;

window.addEventListener("keyup", (e: KeyboardEvent) => {
    ctrlPressed = e.ctrlKey;
});

window.addEventListener("keydown", (e: KeyboardEvent) => {
    ctrlPressed = e.ctrlKey;
});

/**
 * Internal interface for deleting a DmsObject
 */
interface DeleteObject {
    dmsObject: DmsDocument;
    hasMultipleLocations: boolean;
    error: string;
}

/**
 * DMS action service
 */
@Injectable({providedIn: DmsModule})
export class DmsActionService {

    private readonly translateFn: TranslateFnType;
    private readonly $state: StateService;

    // eslint-disable-next-line max-params
    constructor(private dmsDocumentService: DmsDocumentService, private messageService: MessageService,
                uiRouter: UIRouter, @Inject("$filter") $filter: any, @Inject("$eobConfig") private $eobConfig: any,
                private clientService: ClientService, private httpService: HttpService,
                private objectTypeService: ObjectTypeService, @Inject("searchService") private searchService: any,
                @Inject("stateHistoryManager") private stateHistoryManager: any, private valueUtilsService: ValueUtilsService,
                private notificationsService: NotificationsService, @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("modalDialogService") private modalDialogService: any, @Inject("asIniService") private asIniService: TodoAsIniService,
                @Inject("errorModelService") private errorModelService: ErrorModelService, @Inject("cacheManagerService") private cacheManagerService: any,
                private viewerService: ViewerService, @Inject("stateService") private stateService: any,
                @Inject("dmsContentService") private dmsContentService: DmsContentService, @Inject("profileService") private profileService: ProfileService,
                @Inject("variantService") private variantService: any, @Inject("viewService") private viewService: any,
                @Inject("clientScriptService") private clientScriptService: any, @Inject("offlineCacheService") private offlineCacheService: any,
                private backendObjectService: BackendObjectService) {

        this.translateFn = $filter("translate");
        this.$state = uiRouter.stateService;
    }

    /**
     * returns all locations of an object
     *
     * @param {String} objectId of an object that the location will be opened for
     * @param {String} objectTypeId is optional but can improve performance
     * @param {boolean} queryObjectData if set to true, returns `DmsDocument` instances, rathen than just ids
     * @return {Promise} - Returns a promise that resolves with all locations
     */
    getLocations = (objectId: string, objectTypeId?: string, queryObjectData?: boolean): Promise<IdPair[][] | DmsDocument[][]> => {
        if (!this.dmsDocumentService.isValidOsid(objectId)) {
            throw this.errorModelService.createParameterError("objectId", objectId);
        } else if (objectTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(objectTypeId)) {
            throw this.errorModelService.createParameterError("objectTypeId", objectTypeId);
        }

        const result = this.backendObjectService.getDmsObjectLocations(objectId, objectTypeId ?? "");
        if(queryObjectData) {
            return result.pipe(switchMap(x => from(x.map(y => y.map(z => from(this.cacheManagerService.dmsDocuments.getOrFetchById(z.objectId, z.objectTypeId)))))),
                mergeMap(x => forkJoin(...x), 2),
                toArray()).toPromise();
        } else {
            return result.toPromise();
        }
    };

    /**
     * Opens the location of an object or opens a register or folder.
     * Verifies the correctness of all given parameters.
     *
     * @param {boolean} inNewTab - whether it should be opened in a new tab. If the webclient is being run inside a mobile app, the location is displayed within the current tab instead
     * @param {string|number} objectId - An object id of the object that the location will be opened for.
     * @param {string|number} objectTypeId - The object type id to the object id. The parameter is optional but can improve performance.
     * @param {string|number} parentId - Either the parent id of the object, the location shall be opened for or a register/folder that shall be opened.
     * @param {string|number} parentTypeId - The object type id to the parent id. The parameter is optional but can improve performance.
     */
    openLocation = async (inNewTab: boolean | string | number, objectId: string | number, objectTypeId?: string | number, parentId?: string | number, parentTypeId?: string | number): Promise<void> => {
        let error: CustomError;

        if (objectId != null && !this.dmsDocumentService.isValidOsid(objectId)) {
            error = this.errorModelService.createParameterError("objectId", objectId);
        } else if (objectTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(objectTypeId)) {
            error = this.errorModelService.createParameterError("objectTypeId", objectTypeId);
        } else if (parentId != void 0 && !this.dmsDocumentService.isValidOsid(parentId)) {
            error = this.errorModelService.createParameterError("parentId", parentId);
        } else if (parentTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(parentTypeId)) {
            error = this.errorModelService.createParameterError("parentTypeId", parentTypeId);
        } else if (objectId == void 0 && parentId == void 0) {
            error = this.errorModelService.createCustomError("WEB_NO_OBJECT_OR_PARENT");
        }

        if (error != void 0) {
            throw error;
        }

        inNewTab = this.valueUtilsService.parseBoolean(inNewTab);

        if (objectId == null) {
            return this.stateService.openFolderOrRegisterAsync({osid: parentId, objectTypeId: parentTypeId}, inNewTab);
        } else {
            const doc: DmsDocument = await this.searchService.searchById(objectId, objectTypeId);
            const parent: any = {
                id: parentId,
                objectTypeId: parentTypeId
            };
            await this.stateService.goToLocationAsync(doc, parent, inNewTab);
        }
    };

    /**
     * Adds a new location to the given dms object.
     *
     * @param {string|number} objectId - The object id of the object that will be linked in the new location.
     * @param {string|number} targetId - The object id of the new location.
     * @param {string|number} objectTypeId - the object type id to the objectId.
     * @param {string|number} targetTypeId - the object type id to the targetId.
     */
    addLocation = (objectId: string | number, targetId: string | number, objectTypeId: string | number, targetTypeId: string | number): Promise<any> => {
        if (!this.dmsDocumentService.isValidOsid(targetId)) {
            throw this.errorModelService.createParameterError("targetId", targetId);
        } else if (!this.dmsDocumentService.isValidOsid(objectId)) {
            throw this.errorModelService.createParameterError("objectId", objectId);
        } else if (objectTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(objectTypeId)) {
            throw this.errorModelService.createParameterError("objectTypeId", objectTypeId);
        } else if (targetTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(targetTypeId)) {
            throw this.errorModelService.createParameterError("targetTypeId", targetTypeId);
        } else {
            // hard typing directly here to not push string | number through the complete webclient.
            return this.backendObjectService.addDmsObjectLocation(objectId.toString(), targetId.toString(), objectTypeId.toString(), targetTypeId.toString()).toPromise();
        }
    };

    /**
     * Creates a new dms object from the given data.
     *
     * @param {Object} data - An object with field information, osid, objectTypeId, etc.
     * @returns {promise}
     */
    insertObject = async (data: any): Promise<any> => {
        this.validateUpsertData(data, true);

        const typeConfig: any = this.cacheManagerService.objectTypes.getById(data.objectTypeId).model.config;

        if (!typeConfig.rights.indexModify) {
            throw this.errorModelService.createCustomError("WEB_USER_NOT_AUTHORIZED");
        }

        const payload: BackendInsertUpdateObject = await this.prepareUpsertPayload(data);
        const id: string = await this.backendObjectService.insertDmsObject(payload, data.targetId, data.targetTypeId).toPromise();

        return {id};
    };

    /**
     * updates a dms document with the given data
     *
     * @param data An object with field information, osid, objectTypeID
     * @returns {Promise<void>}
     */
    updateObject = async (data: any): Promise<void> => {
        this.validateUpsertData(data, false);

        const typeConfig: any = this.cacheManagerService.objectTypes.getById(data.objectTypeId).model.config;

        if (!typeConfig.rights.indexModify) {
            throw this.errorModelService.createCustomError("WEB_USER_NOT_AUTHORIZED");
        }

        const payload: any = await this.prepareUpsertPayload(data);
        delete payload.result_config;

        await this.backendObjectService.updateDmsObject(payload, null, null).toPromise();
    };

    /**
     * Validates the passed data for inserting or updating
     *
     * @param data
     * @param {boolean} isInsert
     * @returns {any}
     */
    private validateUpsertData = (data: any, isInsert: boolean): void => {
        if (data == void 0) {
            console.warn("The payload must be defined.");
            throw this.errorModelService.createParameterError("data", data);
        }

        if (!this.objectTypeService.isValidObjectTypeId(data.objectTypeId)) {
            throw this.errorModelService.createParameterError("data.objectTypeId", data.objectTypeId); // isValidObjectTypeId writes to console
        }

        if (data.fields == void 0 || typeof (data.fields) != "object") {
            console.warn("The fields must be an object.");
            throw this.errorModelService.createParameterError("data.fields", data.fields);
        }

        if (isInsert) {
            const typeConfig: any = this.cacheManagerService.objectTypes.getById(data.objectTypeId).model.config;

            if (typeConfig.mainType != "0" && !this.dmsDocumentService.isValidOsid(data.targetId)) {
                console.warn("The targetId must be defined if the object is not a cabinet.");
                throw this.errorModelService.createParameterError("data.targetId", data.targetId);
            }

            if (data.targetTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(data.targetTypeId)) {
                throw this.errorModelService.createParameterError("data.targetTypeId", data.targetTypeId); // isValidObjectTypeId writes to console
            }
        } else if (!this.dmsDocumentService.isValidOsid(data.osid)) {
            throw this.errorModelService.createParameterError("data.osid", data.osid); // isValidOsid writes to console
        }
    };

    /**
     * Processes the given data and converts it to the format the REST service is expecting
     *
     * @param data
     * @returns {Promise<{objectTypeId: any; result_config: {fieldsschema: any[]}; fields: any; mainTypeId: any}>}
     */
    // eslint-disable-next-line @typescript-eslint/require-await
    private prepareUpsertPayload = async (data: any): Promise<{ osid: string; objectTypeId: string; result_config: { fieldsschema: any[] }; fields: any; mainTypeId: string }> => {
        const reqFields: any = data.fields;
        const typeDef: any = this.cacheManagerService.objectTypes.getById(data.objectTypeId);
        const fields: any = typeDef.api.getFlatFieldList();
        const keys: string[] = Object.keys(reqFields);
        const resultingFields: any = {};

        for (const fieldKey of Object.keys(fields)) {
            const field: any = fields[fieldKey];

            if (keys.includes(field.internal)) {
                keys.splice(keys.indexOf(field.internal), 1);

                const value: any = reqFields[field.internal];

                if (field.type == "grid") {
                    const gridData: any = this.prepareUpsertGridPayload(field, value);

                    if (gridData != void 0) {
                        resultingFields[field.internal] = gridData;
                    }
                } else {
                    resultingFields[field.internal] = {
                        value: value.toString(),
                        internalName: field.internal
                    };
                }
            }
        }

        if (keys.length > 0) {
            console.warn("Could not find mapping fields for internals: ", keys);
        }

        const retVal: any = {
            fields: resultingFields,
            objectTypeId: typeDef.model.config.objectTypeId.toString(),
            mainTypeId: ((data.mainType == void 0) ? typeDef.model.config.mainType : data.mainType).toString(),
            result_config: {fieldsschema: []}
        };

        if (data.osid) {
            retVal.osid = data.osid.toString();
        }

        return retVal;
    };

    /**
     * Processes the given field and rows to create a upsert payload the REST server understands
     *
     * @param field
     * @param rows
     * @returns {{internalName: any; columns: any[]; type: string; rows: any[]}}
     */
    private prepareUpsertGridPayload = (field: any, rows: any): any => {
        if (!Array.isArray(rows)) {
            console.warn("The value shall be assigned to a grid field, but is not an array. Skipping this assignment");
            return;
        }

        const rowsData: string[][] = [];

        for (const row of rows) {
            if (!Array.isArray(row)) {
                console.warn("The value shall be assigned to a grid field, but at least one row is not an array. Skipping this assignment");
                return;
            }

            const rowData: string[] = [];

            for (const cell of row) {
                rowData.push(cell.toString());
            }

            rowsData.push(rowData);
        }

        const colData: any[] = [];

        for (const column of field.columns) {
            colData.push({
                internalName: column.internal,
                type: column.type
            });
        }

        return {
            type: "GRID",
            internalName: field.internal,
            columns: colData,
            rows: rowsData
        };
    };

    /**
     * Deletes the given dms object. It seems that this method is only used by our scripting engine.
     * We may consolidate it with "deleteDmsObjects".
     *
     * @param {object} data - An object with the osid and the objectTypeId of the item that shall be deleted.
     * @returns {promise}
     */
    deleteObject = async (data: any): Promise<any> => {
        if (data == void 0) {
            throw this.errorModelService.createParameterError("data", data);
        }

        if (!this.objectTypeService.isValidObjectTypeId(data.objectTypeId)) {
            throw this.errorModelService.createParameterError("data.objectTypeId", data.objectTypeId);
        }

        const typeConfig: any = this.cacheManagerService.objectTypes.getById(data.objectTypeId).model.config;

        if (!typeConfig.rights.objDelete) {
            throw this.errorModelService.createCustomError("WEB_USER_NOT_AUTHORIZED");
        }

        await this.backendObjectService.deleteDmsObject(data.osid, undefined, (typeConfig.mainType == "99" || typeConfig.mainType == "0")).toPromise();
    };

    /**
     * Deletes multiple objects from the ECM, potentially placing them in the user's recycle bin.
     * The function provides user feedback and allows to cancel the deletion.
     *
     * @param {DmsDocument[]} dmsObjects The dms objects which should be deleted.
     * @param {string} parentId The id of the register or folder where the objects are located in.
     * @returns {Promise<void>}
     */
    deleteDmsObjects = async (dmsObjects: DmsDocument[], parentId: string): Promise<any> => {
        let oneDeleteItemHasChildren: boolean = false;
        let oneDeleteItemHasWorkflow: boolean = false;
        let oneDeleteItemHasMultipleLocations: boolean = false;
        const deleteObjects: DeleteObject[] = [];

        try {
            for (const dmsObject of dmsObjects) {
                const deleteObject: DeleteObject = {
                    dmsObject,
                    hasMultipleLocations: false,
                    error: undefined
                };

                if (await this.dmsDocumentService.hasChildren(dmsObject)) {
                    oneDeleteItemHasChildren = true;
                }

                if (await this.dmsDocumentService.hasMultipleLocations(dmsObject)) {
                    oneDeleteItemHasMultipleLocations = true;
                    deleteObject.hasMultipleLocations = true;
                }

                if (await this.dmsDocumentService.hasWorkflowItem(dmsObject.model.osid)) {
                    oneDeleteItemHasWorkflow = true;
                }

                deleteObjects.push(deleteObject);
            }
        } catch (error) {
            console.warn(error);
            this.notificationsService.backendError(error, "modal.edit.content.initialize.error");
            return;
        }

        let errorMsgKey: string;
        let confirmMsgKey: string;
        let cancelButton: string | null = null;
        let deleteButtonTitle: string;

        if (oneDeleteItemHasChildren) {
            confirmMsgKey = (dmsObjects.length > 1) ? "eob.action.delete.folders.confirm.message" : "eob.action.delete.folder.confirm.message";
            errorMsgKey = "eob.action.delete.folder.error.message";
            cancelButton = this.translateFn("modal.button.cancel");
            deleteButtonTitle = this.translateFn("modal.button.delete");
        } else if (oneDeleteItemHasMultipleLocations && parentId == void 0) {
            confirmMsgKey = (dmsObjects.length > 1) ? "eob.action.delete.documents.error.multi.locations" : "eob.action.delete.document.error.multi.locations";
            errorMsgKey = "eob.action.delete.document.error.message";
            deleteButtonTitle = this.translateFn("modal.button.back");
        } else if (oneDeleteItemHasWorkflow) {
            errorMsgKey = "eob.action.delete.in.workflow.error.message";
            confirmMsgKey = (dmsObjects.length > 1) ? "eob.action.delete.documents.confirm.message" : "eob.action.delete.document.confirm.message";
            cancelButton = this.translateFn("modal.button.cancel");
            deleteButtonTitle = this.translateFn("modal.button.delete");
        } else {
            confirmMsgKey = (dmsObjects.length > 1) ? "eob.action.delete.documents.confirm.message" : "eob.action.delete.document.confirm.message";
            errorMsgKey = "eob.action.delete.document.error.message";
            cancelButton = this.translateFn("modal.button.cancel");
            deleteButtonTitle = this.translateFn("modal.button.delete");
        }

        try {
            await this.modalDialogService.infoDialog(this.translateFn("modal.button.delete"), this.translateFn(confirmMsgKey), cancelButton, deleteButtonTitle);
        } catch (ignored) {
            // dialog dismissed
            return false;
        }

        if (!oneDeleteItemHasMultipleLocations || parentId != void 0) {
            const successfulObjects: DeleteObject[] = [];
            const failedObjects: DeleteObject[] = [];

            await from(deleteObjects).pipe(
                mergeMap(deleteObject =>
                    this.backendObjectService.deleteDmsObject(deleteObject.dmsObject.model.osid.toString(), parentId, true).pipe(
                        map(_ => {
                            successfulObjects.push(deleteObject);
                        }),
                        catchError(error => {
                            deleteObject.error = error;
                            failedObjects.push(deleteObject);
                            return EMPTY;
                        })
                    ), 3)
            ).toPromise();

            if(!failedObjects.length) {
                const scsMsgKey: string = dmsObjects.length > 1 ? "eob.action.delete.documents.success.message" : "eob.action.delete.document.success.message";
                this.notificationsService.success(this.translateFn(scsMsgKey));
                this.deleteDmsObjectsById(parentId, deleteObjects);
            } else if(!successfulObjects.length) {
                    this.notificationsService.error(this.translateFn(errorMsgKey));
            } else {
                this.deleteDmsObjectsById(parentId, deleteObjects.filter(x => successfulObjects.includes(x)));

                this.modalDialogService.errorInfoDialog(this.translateFn("eob.action.delete.documents.success.message"),
                    this.translateFn("eob.action.delete.partly.successful"),
                    this.translateFn("modal.button.close"),
                    failedObjects.reduce((total, deleteObject) => `${total}${deleteObject.dmsObject.api.buildNameFromIndexData(5)} (${deleteObject.dmsObject.model.id}): ${this.translateFn(ERROR_CODES[deleteObject.error].messageKey)}\n`, ""));
            }

            return !failedObjects.length;
        }
    };

    /**
     * Deletes the passed items from the in-memory document cache and notifies all listeners of the removal.
     *
     * @param {string} parentId The id of the register or folder where the objects are located in.
     * @param {DeleteObject[]} deleteObjects The objects which got deleted.
     */
    private deleteDmsObjectsById = (parentId: string, deleteObjects: DeleteObject[]): void => {
        const ids: string[] = deleteObjects.map((deleteObject: DeleteObject) => deleteObject.dmsObject.model.osid);

        // first broadcast deleted item ids to modules not listening to cache updates (EnvironmentService, ...)
        this.messageService.broadcast(DmsMessageType.DMSOBJECT_DELETED, ids);

        if (parentId) {
            this.messageService.broadcast(DmsMessageType.LOCATION_REMOVED, {
                parentId,
                osids: ids
            });
        } else {
            this.messageService.broadcast(DmsMessageType.ROOT_REMOVED);
        }

        deleteObjects.forEach((deleteObject: DeleteObject) => {
            if (!deleteObject.hasMultipleLocations) {
                this.cacheManagerService.dmsDocuments.remove([deleteObject.dmsObject.model.osid]);
            }
        });
    };

    /**
     * Moves an item to a new location.
     *
     * @param {string|number} objectId - The osid of the object that will be moved.
     * @param {string|number} targetId - The osid of the cabinet or register the object will be moved to.
     * @param {string|number} sourceId - The osid of the source location.
     * @param {string|number=} objectTypeId - The object type id of the object that will be moved.
     * @param {string|number=} targetTypeId - The object type id of the cabinet or register the object will be moved to.
     */
    async moveItem(objectId: string, targetId: string, sourceId: string, objectTypeId?: string, targetTypeId?: string): Promise<void> {
        if (targetId === sourceId) {
            throw this.errorModelService.createCustomError("WEB_OBJECT_ALREADY_EXISTS");
        } else if (!this.dmsDocumentService.isValidOsid(objectId)) {
            throw this.errorModelService.createParameterError("objectId", objectId);
        } else if (!this.dmsDocumentService.isValidOsid(targetId)) {
            throw this.errorModelService.createParameterError("targetId", targetId);
        } else if (sourceId != void 0 && !this.dmsDocumentService.isValidOsid(sourceId)) {
            throw this.errorModelService.createParameterError("sourceId", sourceId);
        } else if (objectTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(objectTypeId)) {
            throw this.errorModelService.createParameterError("objectTypeId", objectTypeId);
        } else if (targetTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(targetTypeId)) {
            throw this.errorModelService.createParameterError("targetTypeId", targetTypeId);
        } else if (objectTypeId != void 0) {
            let cabinetId: string;

            try {
                cabinetId = this.cacheManagerService.objectTypes.getById(objectTypeId).model.config.cabinetId;
            } catch (_) {
                // ignored
            }
            await this.backendObjectService.moveDmsObject(objectId, targetId, sourceId, objectTypeId, targetTypeId, cabinetId).toPromise();
        } else {
            await this.backendObjectService.moveDmsObject(objectId, targetId, sourceId, objectTypeId, targetTypeId).toPromise();
        }
    }

    /**
     * Inserts an item held inside the clipboard into a new location, hence creates a link
     *
     * @param {DmsDocument} targetDmsDocument location, which the clipboard item should be linked to
     * @returns {Promise<void>}
     */
    linkItemAsync = async (targetDmsDocument: DmsDocument): Promise<void> => {
        const clipboard: any = this.environmentService.getClipboard();
        let clipboardDmsDocument: any = clipboard.item;

        const resultCode: number = await this.clientScriptService.executeOnAddLocationScriptAsync(clipboard, targetDmsDocument);

        if (resultCode == -1) {
            console.warn("Script Oo_Add_Location was cancelled");
            return;
        }

        try {
            await this.dmsDocumentService.canObjectInsert(targetDmsDocument.model.osid, targetDmsDocument.model.objectTypeId, clipboardDmsDocument.model.objectTypeId);

            if (targetDmsDocument.model.osid == clipboard.parentId) {
                this.notificationsService.warning(this.translateFn("eob.folder.tree.link.item.already.exists"));
                return;
            }

            let objectId: string = clipboardDmsDocument.model.osid.toString();

            if (clipboardDmsDocument.model.variantData != void 0 && !clipboardDmsDocument.model.variantData[0].model.isActive) {
                objectId = this.variantService.getActiveVariantId(clipboardDmsDocument.model.id);

                const activeVariant: DmsDocument | null = this.cacheManagerService.dmsDocuments.getById(objectId);

                if (activeVariant == null) {
                    clipboardDmsDocument = await this.searchService.searchById(objectId, clipboardDmsDocument.model.objectTypeId, false);
                } else {
                    clipboardDmsDocument = activeVariant;
                }
            }

            await this.backendObjectService.addDmsObjectLocation(objectId, targetDmsDocument.model.osid, clipboardDmsDocument.model.objectTypeId.toString(), targetDmsDocument.model.objectTypeId).toPromise();

            this.messageService.broadcast(DmsMessageType.LOCATION_CREATED, {
                item: clipboardDmsDocument.model,
                parentId: targetDmsDocument.model.osid
            });
        } catch (error) {
            this.messageService.broadcast(DmsMessageType.LOCATION_CREATED, {
                item: clipboardDmsDocument.model,
                parentId: targetDmsDocument.model.osid,
                error
            });
        }
    };

    /**
     * Creates a link between two objects along with an optional note.
     *
     * @param {DmsDocument} sourceDocument - one end of the link
     * @param {DmsDocument} targetDocument - the other end of the link
     * @param {string} note - optional text to describe the link
     * @return {ObjectLink[]} linkObjects - all the object links of the source document
     */
    addNoteLink = (sourceDocument: DmsDocument, targetDocument: DmsDocument, note?: string): ObjectLink[] => {
        try {
            const linkObjects: Observable<ObjectLink[]> = this.backendObjectService.addNoteLink(
                sourceDocument.model.osid,
                targetDocument.model.osid, targetDocument.model.objectTypeId,
                note != void 0 ? note : "");

            void this.incLinkReferenceCountForObject(sourceDocument.model.osid, sourceDocument.model.objectTypeId);
            void this.incLinkReferenceCountForObject(targetDocument.model.osid, targetDocument.model.objectTypeId);

            const successMsg: string = this.translateFn("modal.link.object.create.success");
            this.notificationsService.success(successMsg);

            let result: ObjectLink[];
             linkObjects.subscribe(x => (result = x));
            return result;
        } catch (error) {
            const errorMsg: string = this.translateFn("modal.link.object.add.error");
            this.notificationsService.backendError(error, errorMsg);
        }
    };

    incLinkReferenceCountForObject = async (objectId: string, objectTypeId: string): Promise<void> => {
        const dmsDocument = await this.cacheManagerService.dmsDocuments.getOrFetchById(objectId, objectTypeId);
        dmsDocument.model.baseParameters.links++;

        this.cacheManagerService.dmsDocuments.add(dmsDocument);
        this.messageService.broadcast(Broadcasts.OBJECT_LINK_UPDATED, dmsDocument);
    };

    removeNoteLinks = (originalDocument: DmsDocument, links: ObjectLink[]): Observable<ObjectLinkResponse> => from(this.modalDialogService.infoDialog(this.translateFn("modal.button.delete"),
            this.translateFn(`eob.action.delete.link${links.length > 1 ? "s" : ""}.confirm.message`),
            this.translateFn("modal.button.no"), this.translateFn("modal.button.yes"))).pipe(
            switchMap(_ => {
                // Todo: Moved from http.service to this location while refactoring the backend layer.
                //       This should be moved into a NoteLink module to be close to the displaying code.
                //       A central dms-action.service, which handle as a monolite all action of the
                //       webclient is a bad idea.
                const obs: Array<Observable<ObjectLink[]>> = [];

                for (const link of links) {
                    obs.push(this.backendObjectService.removeNoteLink(originalDocument.model.osid, link.idPair.objectId));
                }

                return from(obs).pipe(
                    mergeAll(3),
                    // Make sure notes are only queried once at the end
                    reduce(acc => acc, []),
                    // Retrieve links once again, because the link removal isn't atomic inside the backend.
                    // We should think on deleting it sequential (slow performance) or creature a multi delete
                    // rest endpoint. The multi delete endpoint is then atomic.
                    switchMap(_ => this.backendObjectService.getNoteLinks(originalDocument.model.osid).pipe(
                        map(links => ({errorOccured: false, links, missingLinkTypes: []} as ObjectLinkResponse))
                    )),
                    catchError(e => {
                        console.warn("Error during link deletion: ", e);
                        return this.backendObjectService.getNoteLinks(originalDocument.model.osid).pipe(
                            map(links => ({errorOccured: true, links, missingLinkTypes: []} as ObjectLinkResponse))
                        );
                    })
                );
            })
        );

    /**
     * Open an index data form of an object for viewing or editing.
     *
     * @param {boolean} inNewTab - Open a new tab?
     * @param {string} mode - 'view' or 'edit'.
     * @param {string|number} objectId - The id of an object to view or edit indexdata.
     * @param {string|number} objectTypeId - The object type id of the object to view or edit indexdata.
     * @returns {boolean} True, if the objectId and objectTypeId are valid, otherwise false.
     */
    openIndexData = (inNewTab: boolean | string | number, mode: "view" | "edit", objectId: string | number, objectTypeId: string | number | undefined): boolean => {
        if (!this.dmsDocumentService.isValidOsid(objectId)) {
            return false;
        }

        if (objectTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(objectTypeId)) {
            return false;
        }

        if (this.valueUtilsService.parseBoolean(inNewTab)) {
            const stateParams: any = {
                indexdata: objectId,
                mode,
                state: +new Date()
            } as any;
            if (objectTypeId) {
                stateParams.objecttypeid = objectTypeId;
            }
            const stateData: any = {
                config: {userAction: "script"},
                params: stateParams
            };

            this.stateHistoryManager.goToState("entry", stateParams, stateData, true);
        } else {
            const docData: any = {model: {osid: objectId, objectTypeId: objectTypeId || ""}};
            this.stateHistoryManager.goToIndexData(docData, mode, "script");
        }

        return true;
    };

    /**
     * Open the result list for a given query.
     *
     * @param {boolean} inNewTab - Open a new tab?
     * @param {Object} query - The query object (as used in backend calls).
     * @param {string=""} title - The title for the result list.
     * @param {string=""} description - The description for the result list.
     * @param {boolean=true} useAsIni - Use hit list config from AS.INI.
     * @param {boolean=true} executeSingleHitAction - Execute default action, if result list has only one item.
     * @returns {promise}
     */
        // eslint-disable-next-line @typescript-eslint/require-await
    openResultList = async (inNewTab: boolean | string | number, query: any, title: string = "", description: string = "", useAsIni: boolean = true, executeSingleHitAction: boolean = true): Promise<any> => {
        if (query == void 0 || query === "") {
            throw this.errorModelService.createParameterError("query", query);
        } else {
            const data: any = {
                config: {executeSingleHitAction: this.valueUtilsService.parseBoolean(executeSingleHitAction)},
                type: "scriptQuery",
                scriptQuery: query,
                title,
                description,
                useAsIni: this.valueUtilsService.parseBoolean(useAsIni)
            };
            this.stateHistoryManager.goToState("hitlist.result", {}, data, this.valueUtilsService.parseBoolean(inNewTab));
            return query;
        }
    };

    /**
     * Open a result list with the given list of {osid,objectTypeId}s.
     *
     * @param {boolean} inNewTab - whether to display the result list in a new tab (default: true)
     * @param {Array<{osid: number|string, objectTypeId: number|string}>} items - array of {osid,objectTypeId}
     * @param {string} title - the title above the result list (default: "")
     * @param {string} description - the description underneath the title (default: "")
     * @param {boolean=true} executeSingleHitAction - execute the single hit action, if there is only one item
     * @param {string} filter - A initial value for the hitlist filter field
     * @returns {Promise<any>} - promise for error handling
     */
    openResultListByIds = async (inNewTab: boolean | string = true, items: Array<{ osid: number | string; objectTypeId: number | string }>,
                                 // eslint-disable-next-line @typescript-eslint/require-await
                                 title: string = "", description: string = "", executeSingleHitAction: boolean | string = true, filter?: string): Promise<any> => {
        if (!Array.isArray(items)) {
            throw this.errorModelService.createParameterError("items", items);
        } else {
            const data: any = {
                config: {executeSingleHitAction: this.valueUtilsService.parseBoolean(executeSingleHitAction), filter},
                type: "searchByIds",
                items,
                title,
                description
            };
            this.stateHistoryManager.goToState("hitlist.result", {}, data, this.valueUtilsService.parseBoolean(inNewTab));
        }
    };

    /**
     * Open variants of the given object.
     *
     * @param {boolean} inNewTab - Open a new tab.
     * @param {string|number} objectId - The id of an object to open variants for.
     * @param {string|number=} objectTypeId - The object type id of the object to open variants for.
     * @returns {promise}
     */
    openVariants = async (inNewTab: boolean | string | number, objectId: string | number, objectTypeId?: string | number): Promise<void> => {
        if (!this.environmentService.env.actions.useVariants) {
            throw this.errorModelService.createCustomError("WEB_USER_NOT_AUTHORIZED");
        } else if (!this.dmsDocumentService.isValidOsid(objectId)) {
            throw this.errorModelService.createParameterError("objectId", objectId);
        } else if (objectTypeId != void 0 && !this.objectTypeService.isValidObjectTypeId(objectTypeId)) {
            throw this.errorModelService.createParameterError("objectTypeId", objectTypeId);
        }

        const response: any = await this.searchService.searchById(objectId, objectTypeId);
        const obj: any = response.model;

        if (obj.mainType != "4" && obj.subType != "4") {
            throw this.errorModelService.createCustomError("WEB_NO_VARIANTS_OBJECTTYPE", obj.mainType, obj.subType);
        }

        // noinspection NonShortCircuitBooleanExpressionJS
        if (obj.objectFlagsValue & 0x80) {
            throw this.errorModelService.createCustomError("WEB_NO_VARIANTS_WORKFLOW_TRAY");
        }

        if (!obj.hasContent) {
            throw this.errorModelService.createCustomError("WEB_OBJECT_HAS_NO_CONTENT");
        }

        if (!obj.rights.objModify || !obj.rights.indexModify) {
            throw this.errorModelService.createCustomError("WEB_USER_NOT_AUTHORIZED");
        }

        if (obj.isLocked && obj.baseParameters.locked == "OTHERS" && !obj.hasVariants) {
            throw this.errorModelService.createCustomError("WEB_OBJECT_LOCKED_OTHER");
        }

        const stateParams: any = {
            id: obj.osid,
            objectTypeId: obj.objectTypeId
        };

        const stateData: any = {
            config: {
                caller: +new Date(),
                initActiveVariantId: obj.osid
            },
            type: "variants"
        };
        this.stateHistoryManager.goToState("variants", stateParams, stateData, this.valueUtilsService.parseBoolean(inNewTab));
    };

    /**
     * Sets the given document(s) as archiveable
     *
     * @param {DmsDocument | DmsDocument[]} dmsDocuments
     */
    setArchivable = async (dmsDocuments: DmsDocument[]): Promise<void> => this.switchArchiveFlag(dmsDocuments, true);

    /**
     * Sets the given document(s) as non-archiveable
     *
     * @param {DmsDocument | DmsDocument[]} dmsDocuments
     */
    setNotArchivable = async (dmsDocuments: DmsDocument[]): Promise<void> => this.switchArchiveFlag(dmsDocuments, false);

    /**
     * change the current archive state for the selected items
     *
     * @param dmsDocuments - array of selected items with the same archive state
     * @param switchToArchivable boolean wheter to set archivalbe or not
     */
    private switchArchiveFlag = async (dmsDocuments: DmsDocument[], switchToArchivable: boolean): Promise<void> => {
        if (dmsDocuments === void 0 || (Array.isArray(dmsDocuments) && dmsDocuments.length == 0)) {
            return;
        }

        if (!Array.isArray(dmsDocuments)) {
            dmsDocuments = [dmsDocuments];
        }

        const successfulItems: DmsDocument[] = [];

        await from(dmsDocuments).pipe(
            mergeMap(dmsDocument =>
                this.backendObjectService.switchArchiveFlagDmsObject(dmsDocument.model.osid.toString(), switchToArchivable).pipe(
                    map(_ => {
                        successfulItems.push(dmsDocument);
                    })/*,
                    catchError(error => {
                        return EMPTY;
                    })*/
                ), 3)
        ).toPromise();

        if(successfulItems.length == dmsDocuments.length) {
            let msg: string;
            if (dmsDocuments.length > 1) {
                msg = switchToArchivable ?
                    this.translateFn("eob.action.archivable.multi.true") :
                    this.translateFn("eob.action.archivable.multi.false");
            } else {
                msg = switchToArchivable ?
                    this.translateFn("eob.action.archivable.single.true") :
                    this.translateFn("eob.action.archivable.single.false");
            }
            this.notificationsService.success(msg);
        } else {
            this.notificationsService.error("eob.action.archivable.error");
        }

        successfulItems.map(x => (x.model.baseParameters.archiveState as string) = (switchToArchivable) ? ARCHIVABLE : NOT_ARCHIVABLE);
        this.cacheManagerService.dmsDocuments.executeListeners(successfulItems.map(x => x.model.osid));
    };

    /**
     * Currently used for the check in in the electron client.
     * If only one document is checked out an auto checkin is attempted. Otherwise the user will be presented with a dropzone dialog.
     *
     * @param {{model}} documentData - A subset of a dmsDocument, of which the content shall be checked in.
     * @returns {Promise<boolean|undefined>} Is resolved with true/false, if the auto checkin was successfull/failed. Is resolved with undefined, if further user action is necessary.
     */
    checkIn = async (documentData: any): Promise<boolean | void> => {
        if (!window.electron || ctrlPressed) {
            this.modalDialogService.dropzoneDialog(documentData);
            return;
        }

        const filesToCheckIn: any[] = await window.electron.getCheckedOutFilesStatusAsync(documentData.model.id);

        if (filesToCheckIn.length !== 1) {
            this.modalDialogService.dropzoneDialog(documentData);
            return;
        }

        // one file is checked out and we try to check it in automatically
        return this.autoCheckInAsync(documentData, filesToCheckIn[0]);
    };

    /**
     * Currently only osed in the electron.
     * Attampts a checkin, if the given file is available.
     * If the file is currently locked, the user is presented with a info dialog.
     * And if the file is not available, the user is directed to a dropzone.
     *
     * @param {{model}} documentData - A subset of a dmsDocument, of which the content shall be checked in.
     * @param {object} file - The content file data.
     * @returns {Promise<boolean>} Is resolved with true/false, if the auto checkin was successfull/failed. Is resolved with undefined, if further user action is necessary.
     */
    autoCheckInAsync = async (documentData: any, file: any): Promise<boolean | void> => {
        const docId: string = documentData.model.id;
        documentData.model.mocked = true;

        switch (file.status) {
            case "OK":
                const contents: Buffer = await window.electron.getCheckedOutFileContentAsync(docId);
                const result: string = await this.httpService.uploadFile(file.fileName, new Blob([contents]))
                    .pipe(filter((x: HttpEvent<string>) => x.type == HttpEventType.Response), map((x: HttpResponse<string>) => x.body)).toPromise();
                const pseudoDropzone: any = {
                    files: [result],
                    item: documentData.model
                };

                try {
                    await this.dmsContentService.addContentToDocumentAsync(pseudoDropzone, docId);
                    await this.undoCheckOut(documentData.model);
                } catch (error) {
                    this.notificationsService.backendError(error, "modal.edit.content.save.error");
                }

                void this.viewerService.refreshContent(parseInt(docId));
                void this.viewerService.updateO365Viewer(docId, documentData.model);
                return true;
            case "EBUSY":
                try {
                    await this.modalDialogService.infoDialog(this.translateFn("eob.action.modal.checkin.title"),
                        this.translateFn("eob.action.checkin.error.currently.open"),
                        this.translateFn("modal.close.title"));
                } catch (_) {
                    // ignored
                }

                return false;
            case "ENOENT":
            default:
                this.notificationsService.error(this.translateFn("eob.action.checkin.error.file.not.found"));
                this.modalDialogService.dropzoneDialog(documentData);
                break;
        }
    };

    /**
     * Undoes a checkout operation by restoring the clearing the lock state of the given document
     *
     * @param {DmsDocument | any} dmsDocument document representation, which may lack API functions
     * @returns {Promise<boolean>}
     */
    async undoCheckOut(dmsDocument: DmsDocument | any): Promise<boolean> {
        let checkoutUndone: boolean = false;
        let wcfCall: boolean = false;

        if (dmsDocument.mocked) {
            wcfCall = true;
            dmsDocument = await this.cacheManagerService.dmsDocuments.getOrFetchById(dmsDocument.osid, dmsDocument.objectTypeId, true);
            this.cacheManagerService.dmsDocuments.executeListeners(dmsDocument.osid);
        }

        const docModel: DmsDocumentModel = dmsDocument.model;

        const undoCheckoutCall: () => Promise<void> = async () => {
            try {
                await this.httpService.checkoutDocument(docModel.osid, docModel.objectTypeId, true).toPromise();
                dmsDocument = await this.cacheManagerService.dmsDocuments.getOrFetchById(dmsDocument.model.osid, dmsDocument.model.objectTypeId, true);
                this.cacheManagerService.dmsDocuments.add(dmsDocument);
                checkoutUndone = true;
                this.cacheManagerService.dmsDocuments.executeListeners(dmsDocument.model.osid);
            } catch (error) {
                console.warn(error);
                if (!wcfCall) {
                    this.notificationsService.error(this.translateFn("eob.action.checkout.error"));
                }
            }
        };

        if (docModel.baseParameters.locked != void 0 && docModel.baseParameters.locked == "SELF") {
            const files: CheckedOutFile[] = this.profileService.removeCheckedOutObject(`${docModel.id}`);

            if (window.electron) {
                if (files.length === 0) {
                    await undoCheckoutCall();
                    return checkoutUndone;
                } else if (files.length >= 1) {
                    if (files[0].status === "EBUSY") {
                        this.modalDialogService.infoDialog(this.translateFn("eob.contextmenu.action.content.undocheckout.title"),
                            this.translateFn("eob.action.checkin.error.currently.open"),
                            this.translateFn("modal.close.title"));
                    } else { // "OK" || "ENOENT" || default
                        await window.electron.removeWorkFilesAsync(files);
                        await undoCheckoutCall();
                    }
                } else {
                    this.modalDialogService.infoDialog(this.translateFn("eob.contextmenu.action.content.undocheckout.title"),
                        this.translateFn("eob.action.checkin.error.currently.open"),
                        this.translateFn("modal.close.title"));
                }
            } else {
                await undoCheckoutCall();
            }
        }
        return checkoutUndone;
    }

    /**
     * Checks out the given document by setting the appropriate lock state. This is used to ensure that the content of the document can't be altered by a third party.
     * If an error occurs, the user gets a visual feedback.
     *
     * @param {DmsDocument} dmsDocument
     * @returns {Promise<void>}
     */
    async checkOut(dmsDocument: DmsDocument): Promise<void> {
        const docModel: DmsDocumentModel = dmsDocument.model;
        if (docModel.tray != void 0) {
            return;
        }

        if (docModel.baseParameters.objectCount == "0" || docModel.baseParameters.objectCount == void 0 || !["1", "2", "3", "4"].includes(docModel.mainType as string)) {
            return;
        } else {
            try {
                await this.httpService.checkoutDocument(docModel.osid, docModel.objectTypeId).toPromise();
                docModel.baseParameters.locked = "SELF";
                docModel.isLocked = true;

                this.cacheManagerService.dmsDocuments.executeListeners(dmsDocument.model.osid);
            } catch (response) {
                // Conflicted. Another user checked this item out in the meantime.
                if (response.status == 409) {
                    // woaaah ... it was me who checked this out :-O
                    if (this.environmentService.getSessionInfo().userid == response.data.id) {
                        docModel.baseParameters.locked = "SELF";
                    } else {
                        // someone else checked it out
                        const username: string = response.fullname || response.name;
                        const message: string = this.translateFn("eob.action.checkout.conflict").replace("{user}", username);
                        this.notificationsService.error(message);

                        docModel.baseParameters.locked = "OTHERS";

                        throw response;
                    }

                    docModel.isLocked = true;
                    this.cacheManagerService.dmsDocuments.executeListeners(dmsDocument.model.osid);
                } else {
                    this.notificationsService.backendError(response, this.translateFn("eob.action.checkout.error"));
                }
            }
        }
    }

    /**
     * Adds documents to the favourites portfolio.
     * The user receives a feedback, whether the action was successful or has failed.
     *
     * @param {DmsDocument[]} dmsDocuments
     */
    addFavorites = (dmsDocuments: DmsDocument[]): void => {
        const osids: string[] = dmsDocuments.map(s => s.model.osid);

        this.httpService.addFavorites(osids).subscribe(x => {
            if (x.filter(y => y.success).length == osids.length) {
                for (const dmsDocument of dmsDocuments) {
                    dmsDocument.model.isFavorite = true;
                    dmsDocument.model.baseParameters.favorite = true;
                }

                this.cacheManagerService.dmsDocuments.executeListeners(osids);
                void (async () => {
                    await this.offlineCacheService.updateCacheReferences(osids);
                    this.messageService.broadcast(DmsMessageType.ADD_TO_FAVORITE, osids);
                })();

                if (x.length > 1) {
                    this.notificationsService.success(this.translateFn("eob.action.notification.fav.multi.add.success"));
                } else {
                    this.notificationsService.success(this.translateFn("eob.action.notification.fav.single.add.success"));
                }
            } else {
                    this.notificationsService.error(this.translateFn("eob.action.notification.fav.add.error"));
            }
        });
    };

    /**
     * Removes documents to the favourites portfolio.
     * The user receives a feedback, whether the action was successful or has failed.
     *
     * @param {DmsDocument[]} dmsDocuments
     * @returns {Promise<void>}
     */
    removeFavorites = async (dmsDocuments: DmsDocument[]): Promise<void> => {
        try {
            const osids: string[] = dmsDocuments.map(s => s.model.osid);
            await this.httpService.removeFavorites(osids).toPromise();
            this.offlineCacheService.removeOfflineObjects(osids);

            for (const dmsDocument of dmsDocuments) {
                dmsDocument.model.isFavorite = false;
                dmsDocument.model.baseParameters.favorite = false;
            }
            this.cacheManagerService.dmsDocuments.executeListeners(osids);

            if (dmsDocuments.length > 1) {
                this.notificationsService.success(this.translateFn("eob.action.notification.fav.multi.remove.success"));
            } else {
                this.notificationsService.success(this.translateFn("eob.action.notification.fav.single.remove.success"));
            }
        } catch(e) {
            this.notificationsService.error(this.translateFn("eob.action.notification.fav.remove.error"));
        }
    };

    /**
     * Copies the passed value to the clipboard of the user's device
     * Usually used to copy a content preview link into the clipboard, hence the name
     *
     * @param {string} value
     */
    copyPreviewURLToClipboard = (value: string): void => {
        const successful: boolean = this.viewService.copyToClipboard(value);

        if (successful) {
            this.notificationsService.success(this.translateFn("eob.action.notification.preview.url.copy.success"));
        } else {
            this.notificationsService.error(this.translateFn("eob.action.notification.preview.url.copy.error"));
        }
    };

    /**
     * Copies the passed value to the clipboard of the user's device
     * Usually used to copy a content webclient link into the clipboard, hence the name
     *
     * @param {string} value
     */
    copyWebclientURLToClipboard = (value: string): void => {
        const successful: boolean = this.viewService.copyToClipboard(value);

        if (successful) {
            this.notificationsService.success(this.translateFn("eob.action.notification.webclient.url.copy.success"));
        } else {
            this.notificationsService.error(this.translateFn("eob.action.notification.webclient.url.copy.error"));
        }
    };

    async downloadOSFile(dmsObject: DmsDocument, fileName: string, suppressDownload?: boolean): Promise<Buffer> {
        let fileContent: Buffer;

        if (!dmsObject || !dmsObject.model) {
            throw this.errorModelService.createParameterError("dmsDocument", dmsObject);
        }

        if (fileName === "" || !fileName) {
            throw this.errorModelService.createParameterError("fileName", fileName);
        }

        try {
            const parents: IdPair[][] = await this.backendObjectService.getDmsObjectLocations(dmsObject.model.osid, dmsObject.model.objectTypeId).toPromise();
            const parentFolder = await this.cacheManagerService.dmsDocuments.getOrFetchById(parents[0][0].objectId);
            let parentRegister = null;

            if (parents[0].length > 1) {
                parentRegister = await this.cacheManagerService.dmsDocuments.getOrFetchById(parents[0][parents[0].length - 1].objectId);
            }

            fileContent = this.generateOSFile(dmsObject, parentRegister, parentFolder);
        } catch (error) {
            console.error(error);
            this.notificationsService.error(this.translateFn("eob.action.notification.osfile.download.error"));
        }

        if (fileContent) {
            if(!suppressDownload) {
                const file: Blob = new Blob([fileContent], {type: "text/plain"});
                void this.clientService.saveAsAsync(fileName, file);
            }

            return fileContent;
        }
    }

    /**
     * Creates OS file locally for three object types - FOLDER, REGISTER, DOCUMENT.
     * Attention: The generation code is also available in EmailService.java here. If you fix something here
     * fix it also there!
     */
    private generateOSFile(dmsObject: DmsDocument, parentRegister: DmsDocument, parentFolder: DmsDocument): Buffer {
        const modelName: string = dmsObject.model.name || "";
        const modelInternal: string = dmsObject.model.internal || "";
        const iniFileContent: string[] = ["[OSAS]"];

        switch (dmsObject.model.objectType) {
            case "FOLDER":
                iniFileContent.push(
                    `SCHRANK=${modelName}`,
                    `SCHRANKINTERN=${modelInternal}`
                );
                this.addSection(iniFileContent, dmsObject);
                break;
            case "REGISTER":
                iniFileContent.push(
                    `REGISTER=${modelName}`,
                    `REGISTERINTERN=${modelInternal}`,
                    `SCHRANK=${parentFolder.model.name}`,
                    `SCHRANKINTERN=${parentFolder.model.internal}`
                );
                this.addSection(iniFileContent, dmsObject);
                this.addSection(iniFileContent, parentFolder);
                break;
            case "DOCUMENT":
                iniFileContent.push(
                    `DOKUMENTTYP=${modelName}`,
                    `DOKUMENTINTERN=${modelInternal}`,
                    `SCHRANK=${parentFolder.model.name}`,
                    `SCHRANKINTERN=${parentFolder.model.internal}`
                );

                if (parentRegister != void 0) {
                    iniFileContent.push(
                        `REGISTER=${parentRegister.model.name}`,
                        `REGISTERINTERN=${parentRegister.model.internal}`
                    );
                }
                this.addSection(iniFileContent, dmsObject);
                this.addSection(iniFileContent, parentFolder);

                if (parentRegister != void 0) {
                    this.addSection(iniFileContent, parentRegister);
                }
                break;
        }

        // At the end a empty line to be equal to the rich client.
        const result = `${iniFileContent.join("\r\n")}\r\n`;
        if(this.environmentService.getServiceInfo().serverUnicode == "1") {
            return iconv.encode(result, "utf16");
        } else {
            return iconv.encode(result, "win1252");
        }
    }

    private addSection(iniFileContent: string[], dmsObject: DmsDocument) : void {
        iniFileContent.push(
            `[${dmsObject.model.name}]`,
            `#OSID#=${dmsObject.model.osid}`,
        );

        if (dmsObject.model.name.toLocaleLowerCase() != dmsObject.model.internal.toLocaleLowerCase()) {
            iniFileContent.push(
                `[${dmsObject.model.internal}]`,
                `#OSID#=${dmsObject.model.id}`
            );
        }
    }
}
