import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import { Subscription } from "rxjs/index";
import { finalize } from "rxjs/operators";

(() => {
    require("SERVICES_PATH/dms/eob.variant.srv.js");
    require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
    require("SERVICES_PATH/eob.state.history.manager.srv.js");

    angular.module("eob.framework").directive("eobModalExternalVariants", EobModalExternalVariants);

    EobModalExternalVariants.$inject = ["$filter", "virtualGridService", "progressbarService",
        "gridContentService", "layoutManagerService", "variantService", "cacheManagerService", "stateHistoryManager", "virtualGridConverter", "messageService"];

    function EobModalExternalVariants($filter, VirtualGridService, ProgressbarService,
                                      GridContentService, LayoutManagerService, VariantService, CacheManagerService, StateHistoryManager, VirtualGridConverter, MessageService) {
        return {
            restrict: "E",
            scope: {
                osid: "=",
                metadata: "=?",
                resolve: "=?",
                reject: "=?"
            },
            link(scope, element) {

                element.closest("#modalDialogContainer").css("max-width", 1000);
                const sub = new Subscription();
                let virtualGridInstance = null;
                const gHITLIST_CONFIG_DEFAULT = {
                    suppressMultiselect: true,
                    suppressGrouping: true
                };

                let gDocumentIds = [];

                let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);
                progressBar.show();

                let variantData, hitlistConfig;
                sub.add(VariantService.getVariantTreeData(scope.osid).pipe(
                    finalize(() => {
                        progressBar.hide();
                    })
                ).subscribe(fetchedVariantData => {
                    variantData = fetchedVariantData
                    extractIds(variantData.model.variantTree);

                    hitlistConfig = getHitlistConfig();
                    let convertedData = VirtualGridConverter.convert(hitlistConfig, variantData);

                    function getHitlistConfig() {
                        let dmsDocuments = CacheManagerService.dmsDocuments.get(gDocumentIds);
                        let config = GridContentService.getListEntries(dmsDocuments);

                        return Object.assign(config, gHITLIST_CONFIG_DEFAULT);
                    }

                    function extractIds(node) {
                        gDocumentIds.push(node.osid);

                        for (let n of node.children) {
                            extractIds(n)
                        }
                    }

                    let rowHeight = LayoutManagerService.isTouchLayoutActive() ? 40 : 32;
                    let treeConfig = {
                        rowHeight,
                        headerRowHeight: rowHeight,
                        rows: convertedData.rows,
                        childNodesKey: "children",
                        columns: convertedData.columns,
                        showHeader: true,
                        scrollContainer: element.find("#virtual-variant-tree"),
                        useMultiSelect: false,
                        useIntermediateNodes: true,
                        deselectWhenCollapse: false,
                        isCollapsible: false,
                        expandNodesByDefault: true,
                        onGridReady,
                        valueGetter,
                        headerValueGetter,
                        onRowClick: showVariantVersions,
                        onRowRightClick: showVariantVersions
                    };
                    VirtualGridService.getVirtualGridInstance(treeConfig);

                }));

                function onGridReady(params) {
                    virtualGridInstance = params;
                    for (let row of params.api.getRows()) {
                        let activeVariantId = variantData.model.activeVariantId;
                        if (row.osid == activeVariantId) {
                            params.api.selectIndex(row.index);
                            params.api.refreshHeader();
                            return;
                        }
                    }
                }

                function headerValueGetter(params) {
                    let rows = params.api.getRows();

                    if (rows.length > 0) {
                        return rows[0][params.col.field].headerName;
                    } else {
                        return ""
                    }
                }

                function valueGetter(params) {
                    return params.rowData[params.field].value
                }

                function choseVariant(variantObject) {
                    let dmsDocument = CacheManagerService.dmsDocuments.getById(variantObject.osid);
                    StateHistoryManager.goToCreateVariantState(dmsDocument, variantObject.version, scope.metadata.groupKey, "wcf");

                    scope.resolve && scope.resolve("resolve");
                }

                function showVariantVersions(node, event) {
                    let versions = VariantService.getNextVersions(node.osid);
                    let ctxItems = [];

                    for (let v in versions) {

                        let title = "";
                        let icon = "";
                        switch (v) {
                            case "main":
                                title = $filter("translate")("eob.contextmenu.action.variants.new.head") + versions[v];
                                icon = "main-variant";
                                break;
                            case "parallel":
                                title = $filter("translate")("eob.contextmenu.action.variants.new.para") + versions[v];
                                icon = "parallel-variant";
                                break;
                            case "sub":
                                title = $filter("translate")("eob.contextmenu.action.variants.new.sub") + versions[v];
                                icon = "sub-variant";
                                break;
                        }

                        ctxItems.push({
                            title,
                            callback: choseVariant,
                            icon,
                            params: [{osid: node.osid, version: v}]
                        })
                    }

                    MessageService.broadcast(InlineDialogEvent.DISPLAY_CUSTOM_ACTIONS, {
                        items: ctxItems,
                        event,
                        contextData: {
                            context: "",
                            title: ""
                        },
                    });
                }

                // close the dialog once it is canceled
                scope.close = function () {
                    scope.reject && scope.reject("rejected");
                    sub.unsubscribe();
                    MessageService.broadcast(ModalEvents.DESTROY);
                };
                scope.$on("$destroy", () => {
                    if(virtualGridInstance) {
                        virtualGridInstance.destroy();
                    }
                    sub.unsubscribe();
                })
            },
            template: require("!raw-loader!./eob.modal.external.variants.html")
        };
    }
})();
