import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";

@Injectable({providedIn: BackendOsRestModule})
export class OsRestStoredQueriesService {

    constructor(private httpClient: HttpClient) {
    }

    getHistory(): Observable<BackendObject> {
        return this.httpClient.get<BackendObject>("/osrest/api/documents/storedqueries/-2?verbose=true&fieldschema=AUTO");
    }

    execute(queryId: string, maxSize: number): Observable<BackendObject> {
        return this.httpClient.get<BackendObject>(`/osrest/api/documents/storedqueries/${queryId}?verbose=true&baseparams=true&pagesize=${maxSize}`);
    }
}