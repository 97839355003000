<div class="container">
    <p class="pwd-description">{{confirmobject.msg}}</p>
    <form class="password-container" [formGroup]="form" (ngSubmit)="onSubmit()">
        <eob-standalone-form-element [control]="pwdControl" [required]="true" [label]="'modal.confirm.password.label' | ngTranslate" [trackCapslock]="true"></eob-standalone-form-element>
        <div class="button-container">
            <button class="secondary-button" type="button" (click)="close(false)">{{'modal.button.cancel' | ngTranslate}}</button>
            <button type="submit">{{'modal.button.submit' | ngTranslate}}</button>
        </div>
    </form>
</div>
