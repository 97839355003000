<span class = "hitlist-footer-info">
    <span  [hidden] = "!hitlistconfig?.footerIcon"  class = "{{hitlistconfig ? hitlistconfig.footerIcon: ''}}">
        <eob-icon [hidden] = "hitlistconfig && hitlistconfig.footerIcon != 'icon-24-synchronize-footer'" name="synchronize-arrow-separate" size="16"></eob-icon>
    </span>
    <span [hidden] = "!hitlistconfig?.footerInformation" class = "text">{{hitlistconfig ? hitlistconfig.footerInformation : ''}}</span>
</span>

<span class = "hitlist-footer-row-count"  [hidden] = "!hitlistconfig?.totalRowsCount">
    <span class = "hitlist-footer-total">{{'eob.hitlist.footer.total' | ngTranslate}}{{hitlistconfig?.totalRowsCount}}</span>
        <span class = "hitlist-footer-selected">{{'eob.hitlist.footer.selected' | ngTranslate}}{{hitlistconfig?.selectedRowsCount}}</span>
</span>
