import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {filter} from "rxjs/operators";

require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/form/eob.form.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/eob.modal.dialog.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

// search.dir only, therefor also lazy loaded
angular.module("eob.framework").compileProvider.directive("eobSearch", EobSearch);

EobSearch.$inject = ["$timeout", "$filter", "$q", "$stateParams", "$location",
    "objectTypeService", "stateHistoryManager", "formService", "environmentService", "formHelper", "backendService", "cacheManagerService",
    "modalDialogService", "autoCompleteService", "viewerService", "progressbarService", "clientService", "clientScriptService", "messageService"];

/* eslint-disable */
/**
 * This directive is the wrapping directive for the object search
 * Its purpose is to get the Objecttype definition and further details
 * needed for this state (see /eob-core/state.history.manager.srv.js)
 */
export default function EobSearch($timeout, $filter, $q, $stateParams, $location,
                   ObjectTypeService, StateHistoryManager, FormService, EnvironmentService, FormHelper, BackendService, CacheManagerService,
                   ModalDialogService, AutoCompleteService, ViewerService, ProgressbarService, ClientService, ClientScriptService, MessageService) { /* eslint-enable */
    return {
        restrict: "E",
        async link(scope, element) {

            let stateId = $location.search().state;
            let useSearch = EnvironmentService.env.useObjectSearch &&
                EnvironmentService.userHasRole("R_CLNT_ARC_BAR") &&
                EnvironmentService.userHasRole("R_CLNT_EXECUTE_INQUIRY");

            let objectType = CacheManagerService.objectTypes.getById($stateParams.objectTypeId);
            let progressbar, beforeOpenExecuted = false;

            $timeout(() => {
                MessageService.broadcast("show.viewer", false);
            }, 0);

            scope.canUseBaseParams = EnvironmentService.isBaseParameterAvailable() && EnvironmentService.env.form.useBaseParams;
            scope.showBaseparams = false;

            scope.stateDescription = objectType.model.config.title;

            scope.formHelper = null;
            scope.formLayout = null;

            scope.scriptsReady = false;

            scope.fulltext = {
                useFullText: objectType.model.config.useFullText && EnvironmentService.isFulltextAvailable(),
                query: "",
                open: false
            };

            scope.autoCompleteConfig = {
                debounce: 0,
                minCharacters: 1,
                multiSelect: false,
                getItems: autoCompleteCallback
            };

            scope.isPhone = ClientService.isPhone();

            // get the current objecttype definition
            scope.formFields = objectType.api.getFields(true);

            scope.baseParamFormFields = objectType.api.getBaseParamFields();

            scope.formLayout = objectType.api.getLayout();

            let scriptDeferred = $q.defer();
            let scriptPromise = scriptDeferred.promise;
            ViewerService.clearViewer();

            initFooterConfig();
            initProgressbar();

            function initFooterConfig() {
                scope.footerConfigs = [{
                    icon: { name: "footer-search", title: $filter("translate")("form.footer.submit.search") },
                    action: "submit",
                    class: "footer-button"
                }]
            }

            function initProgressbar() {
                progressbar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);
                setTimeout(() => {
                    if (progressbar != void 0) {
                        progressbar.show();
                    }
                }, 100);
            }

            // check if there are cached scripts
            if (objectType.api.getTypeScripts() == null) {
                // if not, we need to get them and store them

                try {
                    let scriptResponse = await ClientScriptService.getByObjectTypeIdAsync(objectType.model.config.objectTypeId)
                    scope.eventScripts = scriptResponse.data;
                    objectType.api.setTypeScripts(scriptResponse.data);
                    scriptDeferred.resolve()
                } catch (error) {
                    showScriptsErrorDialog();
                    scriptDeferred.reject(error);
                }
            }
            else {
                // if so, we use them
                scope.eventScripts = objectType.api.getTypeScripts();
                scriptDeferred.resolve();
            }

            scriptPromise.then(async () => {
                let allFields = scope.formFields.concat(scope.baseParamFormFields);

                let formData = FormService.createFormData(allFields, "min");
                let lastState = StateHistoryManager.getStateData($location.search().state);
                let lastFields = lastState.data.fields;

                let vtxQuery = lastState.data.config == void 0 ? "" : lastState.data.config.vtxQuery;
                if (vtxQuery != "" && vtxQuery != void 0) {
                    scope.fulltext.query = vtxQuery
                }

                if (lastFields != void 0 && Object.keys(lastFields).length !== 0) {
                    for (let key in formData) {
                        let lastField = lastFields[key];
                        let restoreModel = true;

                        if (lastField) {
                            for (let i in lastField) {
                                if ((formData[key].model.addon == "date") && (typeof lastField[i] === "string") && (lastField[i].indexOf("-") != -1)) {
                                    let split = lastField[i].split("-");
                                    formData[key].value = split[0];
                                    formData[key].to = split[1];
                                    restoreModel = false;
                                }
                                else {
                                    formData[key].value = lastField.value;

                                    if (lastField.gridData) {
                                        formData[key].gridData = lastField.gridData;
                                    }
                                }
                            }

                            // Macht finde ich wenig sinn dies hier. Aber bevor ich was kaputt mache...
                            // Wieso �berschreiben wir das wiederhergestellte Model mit dem Defaultmodel?!?
                            if (restoreModel) {
                                if (lastField.model.isBaseParam) {
                                    formData[key].model = objectType.api.getBaseParam(key);
                                }
                            }
                        }
                    }
                }

                let helperConfig = {
                    modelDef: objectType.model,
                    formData,
                    isSearch: true,
                    search: FormService.submitSearchForm,
                    cancel() {
                        history.back()
                    },
                    progressbar
                };

                scope.formHelper = FormHelper.getFormHelper(helperConfig);

                loadFulltextFieldConfiguration();

                scope.formDef = {
                    isMainForm: true,
                    validationMode: "min",
                    formFields: scope.formFields,
                    formHelper: scope.formHelper,
                    formLayout: objectType.api.getLayout(),
                };

                scope.baseparamFormDef = {
                    isMainForm: true,
                    validationMode: "min",
                    formFields: scope.baseParamFormFields,
                    formHelper: scope.formHelper,
                    isBaseParamDef: true,
                    isMockForm: true
                };

                // to check if fields in basic parameters have any values
                toggleBaseParams();

                try {
                    await scope.formHelper.bindScripts(scope.eventScripts);
                } catch (ex) {
                    scope.dataReady = true;
                }

                //init children if data is ready
                scope.dataReady = true;
                scope.$apply()
            });

            function autoCompleteCallback(searchKey) {

                return new Promise(((resolve, reject) => {
                    let list = [];
                    let url = "/documents/vtx/autocomplete/";
                    let data = {
                        query: searchKey,
                        facets: {
                            objtype: [$stateParams.objectTypeId]
                        }
                    };
                    BackendService.post(url, data).then((response) => {
                        if (typeof(response.data) == "object") {
                            for (let i in Object.keys(response.data)) {
                                let listEntry = {
                                    value: "",
                                    short: ""
                                };
                                listEntry.value = Object.keys(response.data)[i];
                                list.push(listEntry);
                            }
                        }
                        list = AutoCompleteService.sortResult(list, false);
                        resolve(list);
                    });
                }));
            }

            scope.$watch("fulltext.query", () => {
                if (scope.formHelper != void 0) {
                    scope.formHelper.config.fulltext = scope.fulltext.query;
                }
            });

            if (!useSearch) {
                history.back()
            }

            const updateFulltextSubscription = MessageService.subscribe(FormEvent.UPDATE_FULLTEXT_QUERY, (config) => {
                scope.fulltext.query = config.fulltext;
            });

            const formReadySubscription = MessageService.subscribeFirst(FormEvent.FORM_READY, async () => {
                if (beforeOpenExecuted) {
                    return;
                }

                scope.formHelper.focusFirstField();

                beforeOpenExecuted = true;

                FormService.executeFormScript("beforeOpen", scope.formHelper).then(resultCode => {
                    progressbar = null;
                    if (!resultCode || resultCode >= 0) {
                        scope.scriptsReady = true;
                    }
                }, (error) => {
                    // result code -2 tell the webclient not to take any kind of action after promise rejection
                    if (error != -2) {
                        // Firefox so slow, is funny to me
                        setTimeout(() => {
                            ClientService.executeStateErrorFallback();
                        }, 0);
                    }
                });
            });

            const toggleBaseParamsSubscription = MessageService.subscribe(FormEvent.TOGGLE_BASEPARAMS, () => {
                scope.showBaseparams = !scope.showBaseparams;

                if (scope.isPhone) {
                    $timeout(() => {
                        if (scope.showBaseparams) {
                            $(".search-left-pane").attr("style", "width: 0px");
                            $(".search-right-pane").attr("style", "left: 0");
                        } else {
                            $(".search-left-pane").attr("style", "right: 0");
                            $(".search-right-pane").attr("style", "width: 0px");
                        }
                    }, 0);
                } else {
                    $timeout(() => {
                        if (scope.showBaseparams) {
                            $(".search-left-pane").attr("style", "right: 50%");
                            $(".search-divider").attr("style", "right: 50%; width: 4px");
                            $(".search-right-pane").attr("style", "width: 50%");
                        } else {
                            $(".search-left-pane").attr("style", "right: 0");
                            $(".search-right-pane").attr("style", "width: 0px");
                        }
                    }, 0)
                }
                toggleBaseParams();
            });

            // In production mode, the directive might be initialized twice, hence breaking the first execution due to missing field API
            // This is a quick fix for a more serious issue (to be fixed in DODO-12446)
            MessageService.getObservable().pipe(filter(x => x.type == FormEvent.SEARCH_DESTROYED)).subscribe(_ => {
                formReadySubscription.unsubscribe()
            });

            scope.$on("$destroy", () => {
                $timeout(() => {
                    MessageService.broadcast("show.viewer", true);
                }, 0);

                if (scope.formHelper == void 0) {
                    return;
                }

                let newData = FormService.reduceFormdata(scope.formHelper.getFields());
                let data = {
                    config: {
                        showBaseParams: scope.showBaseparams,
                        vtxQuery: scope.fulltext.query
                    },
                    type: "search",
                    fields: newData
                };
                scope.formHelper.destroy();
                StateHistoryManager.setStateData(data, stateId);
                formReadySubscription.unsubscribe();
                toggleBaseParamsSubscription.unsubscribe();
                updateFulltextSubscription.unsubscribe();
            });

            function showScriptsErrorDialog() {
                let title = $filter("translate")("modal.confirm.dms.get.script.error.title");
                let msg = $filter("translate")("modal.confirm.dms.get.script.error.message");
                let submit = $filter("translate")("modal.button.close");

                ModalDialogService.infoDialog(title, msg, null, submit).then(() => {
                    //no submit action atm
                }, (error) => {
                    ClientService.executeStateErrorFallback()
                });
            };

            function toggleBaseParams() {
                $timeout(() => {
                    let data = scope.formHelper.getFields();
                    let button = element.find(".baseparam-switch");
                    let i = button.find("i");

                    for (let key in data) {
                        let field = data[key];
                        if (field.model.isBaseParam) {
                            if (field.value !== "0" && field.value !== "") {
                                i.addClass("icon-24-baseparams-active")
                                break;
                            } else {
                                i.removeClass("icon-24-baseparams-active")
                            }
                        }
                    }
                }, 0);
            };

            function loadFulltextFieldConfiguration() {
                let fulltextValue = scope.formHelper.getConfig().fulltext;
                scope.fulltext.open = fulltextValue != void 0 && fulltextValue.length > 0;
                scope.fulltext.query = fulltextValue;
            }
        },
        template: require("!raw-loader!./eob.search.html")
    }
}
