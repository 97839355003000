import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";

@Injectable({providedIn: BackendDms2Module})
export class Dms2SessionService {

    constructor() {
    }

    checkPassword(): Observable<void> {
        throw new Error("not implemented yet");
    }
}