import {Injectable} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {StateService} from "@uirouter/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {DmsDocumentService} from "MODULES_PATH/dms/dms-document.service";
import {DmsContentService} from "MODULES_PATH/dms/dms-content.service";
import {ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {Inject} from "@angular/core";
import {DmsActionService} from "MODULES_PATH/dms/dms-action.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {BeforeOpenResultCode} from "SERVICES_PATH/scripting/eob.client.script.codes";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {Subject, EMPTY, Subscription} from "rxjs";
import {IdPair} from "INTERFACES_PATH/id-pair.interface";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {switchMap, map, finalize, delay} from "rxjs/operators";
import {OsrestSubscriptionObject} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-subscription-object.interface";
import {CustomError} from "CORE_PATH/models/custom-error/custom-error.model";
import * as JSZip from "jszip";
import {FileObject} from "INTERFACES_PATH/file-object.interface";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {LayoutManagerService} from "../layout-manager/layout-manager.service";
import {TodoEnvironmentService, TodoCacheManagerService, TodoClientScriptService} from "INTERFACES_PATH/any.types";
import {BackendExternalTool} from "CORE_PATH/backend/interfaces/resource/backend-external-tool.interface";
import {CustomDashletEvent} from "MODULES_PATH/dashlet/enums/custom-dashlet-event.enum";

type UserAction = "contextmenu" | "doubleclick" | "singlehit" | "entry" | "script" | "search";

@Injectable({
    providedIn: "root"
})
export class ActionService {
    readonly translateFn: TranslateFnType;

    // eslint-disable-next-line max-params
    constructor(@Inject("environmentService") protected environmentService: TodoEnvironmentService,
                @Inject("inboxActionService") protected inboxActionService: any,
                @Inject("stateHistoryManager") protected stateHistoryManager: any,
                @Inject("offlineCacheService") protected offlineCacheService: OfflineCacheService,
                @Inject("stateService") protected stateService: any,
                @Inject("$rootScope") protected $rootScope: RootScope,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("modalDialogService") protected modalDialogService: any,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("clientScriptService") protected clientScriptService: TodoClientScriptService,
                protected objectTypeService: ObjectTypeService,
                protected dmsDocumentService: DmsDocumentService,
                protected dmsContentService: DmsContentService,
                protected dmsActionService: DmsActionService,
                protected profileService: ProfileService,
                private errorModelService: ErrorModelService,
                protected $state: StateService,
                protected clientService: ClientService,
                protected httpService: HttpService,
                protected notificationsService: NotificationsService,
                private layoutManagerService: LayoutManagerService,
                private messageService: MessageService) {
        this.translateFn = $filter("translate");
    }

    /**
     * Execute the double click action, that is defined in osweb.properties with the key 'com.os.osdrt.hitlist.document.doubleclick.action' in the given context.
     *
     * @param dmsDocument document or workflow item
     * @param context
     * @param executeBeforeOpen - Whether the BEFORE_OPEN script should be executed
     * @param userAction - User action, where the call originates from
     * @returns Whether the action doesn't change the state
     */
    executeDoubleClickAction = async (dmsDocument: any, context: string, executeBeforeOpen?: boolean, userAction?: UserAction): Promise<boolean> => {
        if (dmsDocument?.model) {
            if (executeBeforeOpen == void 0) {
                executeBeforeOpen = this.environmentService.env.actions.doubleClickAction == "EDIT_CONTENT_DATA" || dmsDocument.model.isFolder || dmsDocument.model.isRegister || context == "desktop";
            }

            // We can't test for just the constructor name, as is fails for minimized builds
            if ((dmsDocument instanceof DmsDocument || dmsDocument?.model?.osid) && executeBeforeOpen) {
                const shouldOpen: BeforeOpenResultCode = await this.clientScriptService.executeBeforeOpenObjectScript(dmsDocument);
                if (shouldOpen != BeforeOpenResultCode.OPEN) {
                    return false;
                }
            }
        }
        return this._executeDefaultAction(dmsDocument, this.environmentService.env.actions.doubleClickAction, context, userAction);
    };

    /**
     * Execute the single hit action, that is defined in osweb.properties with the key 'com.os.osdrt.hitlist.document.singlehit.action'.
     *
     * @param dmsDocument
     * @returns Whether the action doesn't change the state
     */
    executeSingleHitAction = async (dmsDocument: DmsDocument): Promise<boolean> => {
        const shouldOpen: BeforeOpenResultCode = await this.clientScriptService.executeBeforeOpenObjectScript(dmsDocument);
        if (shouldOpen != BeforeOpenResultCode.OPEN) {
            return false;
        }
        return this._executeDefaultAction(dmsDocument, this.environmentService.env.actions.singleHitAction, "", "singlehit");
    };

    /**
     * Execute the given action on the given item within the context.
     *
     * @param actionItem - Can be a dmsDocument, a hitlistItem or any kind of inbox item.
     * @param defaultAction - The action to execute. Can be left out for register and folder.
     * @param context - The item's context.
     * @param userAction - User action where the call originates from
     * @returns `true` to display the hitlist, `false` we're displaying another state.
     */
    private _executeDefaultAction = async (actionItem: any, defaultAction: string, context?: string, userAction?: UserAction): Promise<boolean> => {
        let dmsDocument: DmsDocument = actionItem;

        if (this.$state.current.name == "hitlist.inbox") {
            if (!this.clientService.isOnline()) {
                this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
                return false;
            } else {
                switch (context) {
                    case "workflow":
                        this.inboxActionService.openRunningWorkflow(actionItem);

                        if (actionItem.read === false) {
                            this.inboxActionService.markWfItemRead(actionItem);
                        }

                        return false;
                    case "startable":
                        this.inboxActionService.startWorkflow([], actionItem.workflowId);
                        return false;
                    case "processes":
                        this.stateHistoryManager.goToWorkflowInfo(actionItem);
                        return false;
                    case "abo":
                    case "revisit":
                        // we need to mark the element as read if the user double clicked the item inside the inbox
                        if (actionItem.model.read === false) {
                            this.inboxActionService.markRead([actionItem], context);
                        }
                        dmsDocument = this.cacheManagerService.dmsDocuments.getById(actionItem.model.osid);
                        break;
                }
            }
        }

        if (context == "quickfinder") {
            return true;
        }

        const docModel: DmsDocumentModel = dmsDocument.model;
        const typeConfig: any = this.cacheManagerService.objectTypes.getById(docModel.objectTypeId).model.config;

        // Register and Folder
        if (docModel.isFolder || docModel.isRegister) {
            if (this.clientService.isOnline() || (await this.offlineCacheService.isObjectOfflineCached(docModel.osid))) {
                if (this.$state.current.name == "folder" && context != "desktop") {
                    this.$rootScope.$broadcast("eob.tree.open.item", {osid: docModel.osid});
                } else {
                    try {
                        return !(await this.stateService.openFolderOrRegisterAsync(docModel, null, null, null));
                    } catch (error) {
                        if (!error.type || error.type != "WEB_FORM_SCRIPT_RETURNED_ERROR") {
                            this.notificationsService.warning(this.translateFn("eob.object.not.found"));
                        }
                        return false;
                    }
                }
            } else {
                this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            }

            return false;
        }

        if (defaultAction === "NONE") {
            return true;
        }

        if (this.layoutManagerService.isTouchLayoutActive()) {
            this.messageService.broadcast(ModalEvents.OPEN_MODAL_DASHLETS, docModel.osid);
            return true;
        }

        if (!defaultAction) {
            defaultAction = "EDIT_INDEX_DATA";
        }

        if (!this.clientService.isOnline()) {
            defaultAction = "SHOW_INDEX_DATA";
        }

        let displayHitlist = true;
        if ((defaultAction == "EDIT_CONTENT_DATA") && (docModel.baseParameters.objectCount != "0") && docModel.baseParameters.locked === "UNLOCKED") {
            if (this.dmsDocumentService.isEditContentAllowed(dmsDocument, typeConfig)) {
                void this.editDocumentContent(dmsDocument, false);
            } else {
                this.notificationsService.error(this.translateFn("eob.action.edit.content.not.allowed"), this.translateFn("eob.notification.error.title"));
            }
        } else if (defaultAction == "PRINT_CONTENT_AS_PDF" && docModel.hasContent) {
            if (this.dmsDocumentService.isExportContentAllowed(dmsDocument, typeConfig) && this.environmentService.userHasRole("R_CLNT_PRN_DOCS")) {
                void this.dmsContentService.printContentAsync(dmsDocument);
            } else {
                this.notificationsService.error(this.translateFn("eob.action.export.content.not.allowed"), this.translateFn("eob.notification.error.title"));
            }
        } else if (defaultAction == "EXPORT_CONTENT_DATA" && docModel.hasContent) {
            if (this.dmsDocumentService.isExportContentAllowed(dmsDocument, typeConfig)) {
                void this.dmsContentService.exportContentAsync(dmsDocument);
            } else {
                this.notificationsService.error(this.translateFn("eob.action.export.content.not.allowed"), this.translateFn("eob.notification.error.title"));
            }
        } else if (defaultAction == "EXPORT_CONTENT_AS_PDF" && docModel.hasContent) {
            if (this.dmsDocumentService.isExportContentAllowed(dmsDocument, typeConfig)) {
                void this.dmsContentService.exportPdfAsync(dmsDocument);
            } else {
                this.notificationsService.error(this.translateFn("eob.action.export.content.not.allowed"), this.translateFn("eob.notification.error.title"));
            }
        } else if ((defaultAction == "EDIT_INDEX_DATA") && ((dmsDocument.model.rights != null) && (dmsDocument.model.rights.indexModify)) && !docModel.isTypeless) {
            this.stateHistoryManager.goToEditIndexData(dmsDocument, userAction);
            displayHitlist = false;
        } else if (defaultAction == "SHOW_INDEX_DATA" && !docModel.isTypeless) {
            if (this.clientService.isOnline() || (await this.offlineCacheService.isObjectOfflineCached(docModel.osid))) {
                this.stateHistoryManager.goToShowIndexData(dmsDocument, userAction);
            } else {
                this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            }
            displayHitlist = false;
        } else if (!docModel.isTypeless) {
            // fallback in case there was no default action fitting the given constraints and we could possibly still do things
            if (typeConfig?.rights != void 0) {
                if (dmsDocument.model.rights?.indexModify) {
                    this.stateHistoryManager.goToEditIndexData(dmsDocument, userAction);
                } else {
                    this.stateHistoryManager.goToShowIndexData(dmsDocument, userAction);
                }

                displayHitlist = false;
            } else {
                this.notificationsService.error(this.translateFn("eob.object.not.found"), this.translateFn("eob.notification.error.title"));
            }
        }

        return displayHitlist;
    };

    /**
     * Copy the given dmsDocument to the clipboard
     *
     * @param dmsDocument - The dmsDocument Object
     * @param parentId - The osid of the dmsDocument's parent
     */
    copyToClipboard = (dmsDocument: DmsDocument, parentId: string | number): void => {
        this.environmentService.addToClipboard(dmsDocument, "copy", parentId);
    };

    /**
     * Cut the given dmsDocument to the clipboard. This allowes the user to move the object from location to location
     *
     * @param dmsDocument - The dmsDocument Object
     * @param parentId - The osid of the dmsDocument's parent
     */
    cutToClipboard = (dmsDocument: DmsDocument, parentId: string | number): void => {
        this.environmentService.addToClipboard(dmsDocument, "cut", parentId);
    };

    /**
     * Edits content for the given document.
     * In case the document has main type 4, the content of the document is offered to the user via download or temporary local file.
     * After offering the content to the user, the document is checked out to ensure no concurrent edits may occur.
     * If a main type may have several files, a dialog is displayed allowing to add or remove content.
     *
     * @param dmsDocument
     * @param callBeforeOpen whether the BEFORE_OPEN script should be called
     */
    editDocumentContent = async (dmsDocument: DmsDocument, callBeforeOpen = true): Promise<void> => {
        let checkedOut: boolean;
        if (callBeforeOpen) {
            const shouldAllowEdit: BeforeOpenResultCode = await this.clientScriptService.executeBeforeOpenObjectScript(dmsDocument);
            if (shouldAllowEdit != BeforeOpenResultCode.OPEN) {
                return;
            }
        }
        if (dmsDocument.model.subType == 4 && !dmsDocument.model.tray && dmsDocument.model.hasContent && this.clientService.isLocalClient()) {
            const canceler$$: Subject<void> = new Subject<void>();
            try {
                let fileContent: ArrayBuffer | null;
                let fileName: string;

                if (this.clientService.isOnline()) {
                    this.modalDialogService.showProgressDialog("eob.status.loading.mimetype", canceler$$);
                    await this.httpService.queryDocumentFiles(`${dmsDocument.model.id}`).pipe(switchMap(documentDetails => {
                        // Max 20MB for mobile. Otherwise it can result in crashing the whole app due to low memory.
                        if (documentDetails.size > (20 * 1048576) && this.clientService.isMobile()) {
                            this.modalDialogService.hideProgressDialog();
                            this.notificationsService.info(this.translateFn("eob.mobile.open.file.too.big"));
                            return EMPTY;
                        }
                        this.modalDialogService.showProgressDialog("eob.status.loading.content");
                        fileName = `${dmsDocument.api.buildNameFromIndexData(5, true, true)}.${documentDetails.files[0].split(".").pop()}`;

                        return this.httpService.retrieveDocumentFiles([{id: dmsDocument.model.osid}], "1", "");
                    }), map(async contentResponse => {
                        fileContent = contentResponse.body;
                        if (fileName.endsWith(".")) {
                            const contentDispositionParts: string[] = contentResponse.headers.get("Content-Disposition").split(/\./);
                            fileName += contentDispositionParts[contentDispositionParts.length - 1];
                        }

                        this.modalDialogService.showProgressDialog("eob.status.checkout");
                        await this.dmsActionService.checkOut(dmsDocument);
                        checkedOut = true;
                        const filePath: string = await this.clientService.openFileDataInDefaultAppAsync(dmsDocument, fileName, fileContent, false) as string;

                        await this.profileService.addCheckedOutObject(dmsDocument, filePath, fileContent);

                        this.messageService.broadcast(CustomDashletEvent.UPDATE_CUSTOM_DASHLET_PROPERTIES, {regenerate: "1"});

                        this.modalDialogService.hideProgressDialog();
                    })).toPromise();
                } else {
                    // Editing content is not supported when offline
                    return;
                }

            } catch (error) {
                let customError: CustomError;
                console.warn(error);
                if (error.type !== "WEB_HTTP_REQUEST_CANCELED") {
                    this.modalDialogService.hideProgressDialog();

                    if (error.status == "9") {
                        customError = this.errorModelService.createCustomError("WEB_UNKNOWN_APP");
                    } else if (error.type == void 0) {
                        customError = this.errorModelService.createCustomError("WEB_CHECKOUT_FAILED");
                    }
                    this.notificationsService.customError(customError ?? error);
                }
                if (checkedOut) {
                    await this.dmsActionService.undoCheckOut(dmsDocument);
                }
            }
            canceler$$.complete();
        } else {
            if (!dmsDocument.model.tray) {
                const typeConfig: any = this.cacheManagerService.objectTypes.getById(dmsDocument.model.objectTypeId).model.config;

                if (!typeConfig.rights?.objModify) {
                    this.notificationsService.warning(this.translateFn("eob.action.edit.content.no.rights"));
                    return;
                }

                await this.dmsActionService.checkOut(dmsDocument);
            }
            this.modalDialogService.editDocumentContentDialog(dmsDocument);
        }
    };

    /**
     * Shows a dialog for editing a subscription
     *
     * @param item
     */
    editAbo = async (item: DmsDocument): Promise<void> => {
        try {
            const result: OsrestSubscriptionObject = await this.httpService.querySubscription(item.model.aboGroup).toPromise();
            item.model.mailAddresses = result.mailAddresses;
            item.model.groupsToBeNotified = result.groupsToBeNotified;
            item.model.usersToBeNotified = result.usersToBeNotified;

            this.modalDialogService.editAboDialog(item);
        } catch (error) {
            this.notificationsService.backendError(error, "eob.action.abo.load.error");
        }
    };

    /**
     * Open a create state for the given objectTypeId and optionally the parent for the given ids.
     *
     * @param parentObject - May be null, if no parent is given or a pair of ids.
     * @param insertObjectTypeId - The objectTypeId of the to be created dms object.
     * @param stateParams - Call the create state with these state parameters.
     * @param inNewTab - Open the create state in a new tab.
     * @param userAction - A custom user action, that led to the creation state.
     */
    createObject = async (parentObject: IdPair | null, insertObjectTypeId: string, stateParams?: any, inNewTab?: boolean, userAction?: string): Promise<void> => {
        try {
            if (this.objectTypeService.isFolderType(insertObjectTypeId) || parentObject == void 0) {
                this.stateHistoryManager.goToCreateNewState(undefined, insertObjectTypeId, stateParams, inNewTab, userAction);
                return;
            }

            // Due to normalization of osid vs. objectId vs. id I found no good way get compatible between IdPair and DmsDocumentModel.
            // This convertation code will be removed somewhere in the future.
            // @ts-ignore
            if (parentObject.osid !== undefined) {
                // @ts-ignore
                parentObject.objectId = parentObject.osid;
            }

            await this.dmsDocumentService.canObjectInsert(parentObject.objectId, parentObject.objectTypeId, insertObjectTypeId);
            const insertObjectInfo: any = {insertObjectTypeId, parentObject};
            this.stateHistoryManager.setStateData(insertObjectInfo, "openLocation");
            this.stateHistoryManager.goToCreateNewState(parentObject, insertObjectTypeId, stateParams, inNewTab, userAction);
        } catch (error) {
            console.warn(error);
            this.notificationsService.customError(error, "WEB_INSERT_FAILED");
        }
    };

    /**
     * Assigns an object type to a typeless document
     *
     * @param parentObject
     * @param objectTypeId
     */
    createObjectFromTypeless = async (parentObject: DmsDocument, objectTypeId: string): Promise<void> => {
        const dmsDocument: DmsDocument = this.environmentService.getClipboard().item;

        try {
            await this.dmsDocumentService.canObjectInsert(parentObject.model.osid, parentObject.model.objectTypeId, objectTypeId);
            this.stateHistoryManager.goToCreateFromTypeless(parentObject, dmsDocument, objectTypeId);
        } catch (error) {
            this.notificationsService.customError(error);
        }
    };

    /**
     * Assigns an object type to a green arrow reference document
     */
    createObjectReference = async (parentObject: DmsDocument, insertableTypes: number[]): Promise<void> => {
        const dmsDocument: DmsDocument = this.environmentService.getClipboard().item;

        const parentLocation: IdPair = {
            objectId: parentObject.model.osid,
            objectTypeId: parentObject.model.objectTypeId,
        };

        const title: string = this.translateFn("eob.action.modal.object.type.reference.document.title");
        const metaData: any = {filePaths: [], mainTypes: [dmsDocument.model.mainType]};
        const objectType: any = await this.modalDialogService.showObjectTypeDialog(title, metaData, parentLocation, insertableTypes);

        if (objectType == void 0) {
            return;
        }

        try {
            await this.dmsDocumentService.canObjectInsert(parentObject.model.osid, parentObject.model.objectTypeId, objectType.objectTypeId);
            this.stateHistoryManager.goToCreateReferenceState(parentObject, dmsDocument, objectType.objectTypeId);
        } catch (error) {
            this.notificationsService.customError(error);
        }
    };

    createAndSaveFilesForProgramArguments = async (dmsDocuments: DmsDocument[], tool: BackendExternalTool): Promise<void> => {
        const delayWaitDialog: Subject<string> = new Subject();
        const delayWaitDialogSub: Subscription = delayWaitDialog.pipe(delay(500), finalize(() => this.modalDialogService.hideProgressDialog())).subscribe(() => this.modalDialogService.showProgressDialog("modal.fav.please.wait"));
        delayWaitDialog.next("");

        try {
            let newArgs: string = tool.argument;

            if (/%[fg]/gi.test(tool.argument)) {
                const contentFiles: string[] = [];
                const zip: JSZip = new JSZip();

                if (dmsDocuments.length > 1 || (dmsDocuments.length == 1 && dmsDocuments[0].model.baseParameters.objectCount > 1)) {
                    const content: FileObject = await this.dmsContentService.exportContentAsync(dmsDocuments, true);
                    const contentZip: JSZip = await zip.loadAsync(content.content);

                    for (const filename of Object.keys(contentZip.files)) {
                        const fileContent: ArrayBuffer = await contentZip.file(filename).async("arraybuffer");
                        contentFiles.push(`${window.electron.saveToOsTempDir(`${Date.now()}-${filename}`, fileContent)}`);
                    }
                } else if (!dmsDocuments.find(x => x.model.hasContent)) {
                    newArgs = newArgs.replace(/%[fg]/gi, "");
                } else {
                    const content: FileObject = await this.dmsContentService.exportContentAsync(dmsDocuments, true);
                    contentFiles.push(`${window.electron.saveToOsTempDir(`${Date.now()}-${content.name}`, content.content)}`);
                }

                if (/%f/gi.test(tool.argument)) {
                    newArgs = newArgs.replace(/%f/gi, contentFiles.map(x => `"${x}"`).join(" "));
                }

                if (/%g/gi.test(tool.argument)) {
                    const txtFile: string = window.electron.saveToOsTempDir(`${Date.now()}.txt`, contentFiles.join("\r\n"));
                    newArgs = newArgs.replace(/%g/gi, `"${txtFile}"`);
                }
            }
            if (/%[po]/gi.test(tool.argument)) {
                const osFiles: string[] = [];

                for (const dmsDocument of dmsDocuments) {
                    const docOsFile: Buffer = await this.dmsActionService.downloadOSFile(dmsDocument, "a", true);
                    osFiles.push(`${window.electron.saveToOsTempDir(`${Date.now()}.os`, docOsFile)}`);
                }

                if (/%p/gi.test(tool.argument)) {
                    const txtFile: string = window.electron.saveToOsTempDir(`${Date.now()}.txt`, osFiles.join("\r\n"));
                    newArgs = newArgs.replace(/%p/gi, `"${txtFile}"`);
                } else {
                    newArgs = newArgs.replace(/%o/gi, osFiles.map(x => `"${x}"`).join(" "));
                }
            }

            if (/%i/gi.test(newArgs)) {
                const objectIds: string[] = [];

                for (const dmsDocument of dmsDocuments) {
                    objectIds.push(`${dmsDocument.model.osid},${dmsDocument.model.objectTypeId}`);
                }

                const txtFile: string = window.electron.saveToOsTempDir(`${Date.now()}.txt`, objectIds.join("\r\n"));
                newArgs = newArgs.replace(/%i/gi, `"${txtFile}"`);
            }

            delayWaitDialogSub.unsubscribe();
            delayWaitDialog.complete();

            try {
                await window.electron.execProgramAsync(tool.path, newArgs, false, tool.workingDirectory);
            } catch (e) {
                this.notificationsService.warning(this.translateFn("eob.action.notification.external.app.execution.error"));
            }
        } catch (e) {
            console.warn("Error while executing external application: ", e);
            delayWaitDialogSub.unsubscribe();
            delayWaitDialog.complete();
        }
    };

    /**
     * Copies the item held in the clipboard to the passed location
     *
     * @param targetDmsDocument location to copy to
     */
    copyItem = async (targetDmsDocument: DmsDocument): Promise<void> => {
        const clipboard: any = this.environmentService.getClipboard();
        const clipboardDmsDocument: DmsDocument = clipboard.item;

        try {
            await this.dmsDocumentService.canObjectInsert(targetDmsDocument.model.osid, targetDmsDocument.model.objectTypeId, clipboardDmsDocument.model.objectTypeId);
            const resultCode: number = await this.clientScriptService.executeOnCreateCopyScriptAsync(clipboard, targetDmsDocument);

            if (resultCode == -1) {
                console.warn("Script 'On_Create_Copy' was cancelled");
                return;
            }
            const insertObjectInfo: any = {insertObjectTypeId: clipboardDmsDocument.model.objectTypeId, parentObject: {objectId: targetDmsDocument.model.osid, objectTypeId: targetDmsDocument.model.objectTypeId}};
            this.stateHistoryManager.setStateData(insertObjectInfo, "openLocation");
            this.stateHistoryManager.goToCopyState(targetDmsDocument.model.osid, targetDmsDocument.model.objectTypeId, clipboardDmsDocument);
        } catch (error) {
            this.notificationsService.customError(error, "WEB_INSERT_FAILED");
        }
    };

    /**
     * Moves the item held in the clipboard to the passed location
     *
     * @param targetDmsDocument location to move to
     */
    async moveItem(targetDmsDocument: DmsDocument): Promise<void> {
        const clipboard: any = this.environmentService.getClipboard();
        const clipboardDmsDocument: DmsDocument = clipboard.item;

        try {
            await this.dmsDocumentService.canObjectInsert(targetDmsDocument.model.osid, targetDmsDocument.model.objectTypeId, clipboardDmsDocument.model.objectTypeId, clipboardDmsDocument.model.osid);
            const resultCode: number = await this.clientScriptService.executeOnMoveScriptAsync(clipboard, targetDmsDocument);

            if (resultCode == -1) {
                console.warn("Script 'On_Move' was cancelled");
                return;
            }

            await this.dmsActionService.moveItem(clipboardDmsDocument.model.osid, targetDmsDocument.model.osid, clipboard.parentId,
                clipboardDmsDocument.model.objectTypeId, targetDmsDocument.model.objectTypeId);
        } catch (error) {
            this.notificationsService.customError(error, "WEB_MOVE_FAILED");
            throw error;
        }

        this.environmentService.clearClipboard();

        this.$rootScope.$broadcast("move.item", {
            dmsDocument: clipboardDmsDocument,
            targetLocationId: targetDmsDocument.model.osid,
            sourceLocationId: clipboard.parentId
        });
    }

    /**
     * Executes a saved query based on the passed data
     *
     * @param item
     */
    executeSavedQuery = (item: any, filter?: string): void => {
        const nextStateId: number = +new Date();

        const nextStateContent: any = {
            config: {executeSingleHitAction: true},
            type: item.expert ? "expert" : "search",
            description: item.name,
            objectTypeIds: item.objectTypeIds,
            activePage: item.activePage,
            formDataTypes: item.formDataTypes,
            queryId: item.id,
            isPublic: item.isPublic,
            fromFile: item.fromFile
        };

        // this function generates a new state with given data
        this.stateHistoryManager.setStateData(nextStateContent, nextStateId);
        this.stateHistoryManager.updateConfig({filter}, nextStateId);
        // jump into the new state
        this.$state.go("hitlist.result", {state: nextStateId});
    };

    /**
     * Copies the item currently held in the clipboard into a workflow
     *
     * @param location the file area of the workflow
     */
    copyToWorkflow = (location: string): void => {
        // clipboard items are JSON parsed (no DMS documents!)
        const item: any = this.environmentService.getClipboard().item;

        // this is the original notification info from the follow-ups and/or subscriptions
        // we need to remove it to not have bold fonts of unread items inside the wf files area
        if (item.origin != void 0) {
            delete item.original;
            delete item.origin.original;
        }

        item.model.wfItem = {
            deletable: true,
            display: false,
            location: "1",
            id: item.model.id,
            movable: true,
            objectTypeId: item.model.objectTypeId,
            useActiveVariant: true,
            workspace: location == "wfFileAreaWorkFiles",
            isNew: true
        };

        this.$rootScope.$broadcast("workflow.add.to.file.area", item);
    };

    /**
     * Broadcasts an event to remove the given workflow item
     *
     * @param item
     */
    removeFromWorkflowFiles = (item: any): void => {
        this.$rootScope.$broadcast("remove.from.workflow", item);
    };

    /**
     * Broadcasts an event to move the given workflow item to the other file area
     *
     * @param item
     */
    switchWorkflowFileArea = (item: any): void => {
        this.$rootScope.$broadcast("workflow.switch.file.area", item);
    };

    /**
     * Broadcasts an event to move a circulation slip
     *
     * @param template
     */
    circulationSlipMove = (template: any): void => {
        this.$rootScope.$broadcast("circulation.slips.move", template);
    };

    /**
     * Broadcasts an event to append a circulation slip
     *
     * @param template
     */
    circulationSlipAppend = (template: any): void => {
        this.$rootScope.$broadcast("circulation.slips.append", template);
    };

    /**
     * Broadcasts an event to delete a circulation slip
     *
     * @param template
     */
    circulationSlipDelete = (template: any): void => {
        this.$rootScope.$broadcast("circulation.slips.delete", template);
    };
}
