import {Action, createAction, props} from "@ngrx/store";

export interface PdfjsToolbarAction extends Action{
    pdfjsToolbarItemType: string;
    payload: boolean;
}

// eslint-disable-next-line @typescript-eslint/typedef
export const updatePdfjsToolbar = createAction(
    "[PdfjsToolbar] Update PdfjsToolbar",
    props<{pdfjsToolbarItemType: string; payload: boolean}>()
);
