import {Component, OnInit, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {AfterViewInit, EventEmitter, HostListener, OnDestroy, Output, ViewChild} from "@angular/core";
import {InputComponent} from "MODULES_PATH/form/components/inputs/input/input.component";

@Component({
    selector: "eob-standalone-form-element",
    templateUrl: "./standalone-form-element.component.html",
    styleUrls: ["./standalone-form-element.component.scss"]
})
export class StandaloneFormElementComponent implements AfterViewInit, OnDestroy {

    inputId: string = Math.random().toString(16).substr(2, 8);

    private focusListener;

    @Input() control = new FormControl();
    @Input() label = "";
    @Input() required = false;
    @Input() trackCapslock = false;
    @Output() inputFocused = new EventEmitter<boolean>();
    @ViewChild(InputComponent, {static: false}) input: InputComponent;

    ngAfterViewInit() {
        this.input.input.nativeElement.setAttribute("id", this.inputId);
        this.input.isFocused.subscribe(event => this.inputFocused.emit(event));
    }

    ngOnDestroy(): void {
    }

}
