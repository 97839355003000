import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {Inject, Injectable} from "@angular/core";
import {
    ContextMenuAction,
    ContextMenuActionsProvider,
    ContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {InboxActionService} from "CORE_PATH/services/actions/inbox-action.service";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {Observable, of} from "rxjs";

@Injectable({
    providedIn: ContextMenuModule
})
export class StartableWorkflowsContextMenuService implements ContextMenuActionsProvider {
    private contextMenuActions: ContextMenuAction[] = [];

    constructor(private contextMenuUtilsService: ContextMenuUtilsService,
                private clientService: ClientService,
                @Inject("inboxActionService") private inboxActionService: InboxActionService,
    ) {
    }

    getSingleSelectionContextMenuActions(contextMenuItem: ContextMenuItem, contextData: ContextData): Observable<ContextMenuAction[]> {
        this.contextMenuActions = [];

        this.addStartWorkflow(contextMenuItem);

        return of(this.contextMenuActions);
    }

    getMultiselectionContextMenuActions(contextMenuItems: ContextMenuItem[], contextData: ContextData): Observable<ContextMenuAction[]> {
        return;
    }

    private addStartWorkflow(contextMenuItem: ContextMenuItem): void {
        if (this.clientService.isOnline()) {
            this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.workflow.title",
                "workflow-startable-blue", this.inboxActionService.startWorkflow, contextMenuItem));
        }
    }
}