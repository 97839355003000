import {Injectable, Inject} from "@angular/core";
import {TranslateFnType} from "ROOT_PATH/eob-client/custom.types";
import {DmsModule} from "./dms.module";
import {Cabinet, FlatNavigation, Language, ObjectType, ObjectTypeConfig, OsrestObjectDefinition} from "ROOT_PATH/app/shared/interfaces/object-type.interface";
import {ProgressStatusService} from "CORE_PATH/services/progress/progress-status.service";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {ObjectTypeRights} from "ENUMS_PATH/objecttype-rights.enum";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

@Injectable({
    providedIn: DmsModule
})
export class ObjectTypeService {
    private readonly translateFn: TranslateFnType;
    private readonly rawObjDefTypes: { [name: string]: any };
    private availableObjectTypeIcons: { [name: number]: string };
    private navigation: Cabinet[];
    private cacheManagerService: any; // Todo: CacheManagerService isn't converted yet.
    private contextId: string;

    // eslint-disable-next-line max-params
    constructor(@Inject("$filter") private $filter: ng.IFilterService,
                @Inject("$injector") private $injector: angular.auto.IInjectorService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                private httpService: HttpService,
                private iconService: IconService,
                private progressStatusService: ProgressStatusService,
                private messageService: MessageService) {

        this.translateFn = $filter("translate");
        this.rawObjDefTypes = {};
        this.availableObjectTypeIcons = {};
        this.navigation = [];
        this.cacheManagerService = null;
    }

    /**
     * Initializes the objecttype service
     * The objectdefinition, the cabinets(navigation) and the icons will be fetched and combined into the
     * objecttype definition objects and the navigation
     *
     * @param objectTypesToIgnore - an array of objecttype ids or internal names we have to ignore because the scripted said so in the "after login script"
     * @returns - returns a promise since this function is async
     */
    async initAsync(objectTypesToIgnore: string[]): Promise<void> {
        this.cacheManagerService = this.$injector.get("cacheManagerService");
        await this.buildObjectTypesAsync(objectTypesToIgnore);
        this.messageService.broadcast(Broadcasts.OBJECT_TYPES_INITIALIZED, this.cacheManagerService.objectTypes.getAll());
    }

    // region get and build objecttypes
    /**
     * The objectdefinition, the cabinets(navigation) and the icons will be fetched and combined into the
     * objecttype definition objects and the navigation
     *
     * @param objectTypesToIgnore - an array of objecttype ids we have to ignore because the scripted said so in the "after login script"
     */
    private async buildObjectTypesAsync(objectTypesToIgnore: string[]): Promise<void> {
        await this.fetchObjDefinitionAsync();

        // Fetches the cabinets (navigation) from the webclient backend with the merged right system.
        // Then set the expanded flag of each cabinet to false.
        this.navigation = await this.httpService.fetchCabinets().toPromise();

        this.progressStatusService.setLoadingMessage(this.translateFn("eob.loading.object.definition"));
        this.progressStatusService.setLoadingMessage(this.translateFn("eob.loading.parse.object.definition"));

        this.parseObjectTypes();

        await this.fetchIconsAsync();
        this.setNavigationTypeIcons();

        this.reduceAvailableTypes(objectTypesToIgnore);
        this.buildInsertableTypes();

        if (this.contextId == void 0) {
            const ids: number[] = this.cacheManagerService.objectTypes.getAll().map(objectType => objectType.model.osid);
            this.contextId = this.cacheManagerService.objectTypes.attachListener(ids);
        }
    }

    /**
     * First ask the backend with a cached timestamp of the last loaded objdef
     * If the server responds with 304 (not modified) we use the cached objddef otherwise we fetch
     * the object definition from the server
     */
    private async fetchObjDefinitionAsync(): Promise<void> {
        const objDef: OsrestObjectDefinition = await this.httpService.fetchObjectDefinition().toPromise();

        if (!objDef.asobjdef || !objDef.asobjdef.cabinet || !objDef.asobjdef.languages) {
            throw new Error(this.translateFn("eob.object.definition.invalid"));
        }

        // an array of all cabinets and languages of the object definition
        let cabinets: Cabinet[] = objDef.asobjdef.cabinet;
        let languages: Language[] = objDef.asobjdef.languages.language;

        if (!Array.isArray(cabinets)) {
            cabinets = [cabinets];
        }

        if (!Array.isArray(languages)) {
            languages = [languages];
        }

        this.environmentService.setLanguages(languages);
        this.addTypelessObjectType();

        for (const cabinet of cabinets) {
            // an array of each type inside this cabinet
            let types: any[] = cabinet.object;

            if (!Array.isArray(types)) {
                types = [types];
            }

            for (const type of types) {
                const typeId: string = type.ids.oid;
                this.rawObjDefTypes[typeId] = type;
            }
        }
    }

    /**
     * add typeless objecttype mocks to the available objecttypes map
     */
    private addTypelessObjectType(): void {
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: -1}, fields: {}}});
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: 19660800}, fields: {}}});
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: 19660801}, fields: {}}});
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: 19660802}, fields: {}}});
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: 19660803}, fields: {}}});
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: 19660804}, fields: {}}});

        // Basic register parameters
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: 6750208}, fields: {}}});
        // Basic document parameters
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: 6553600}, fields: {}}});
        // Basic folder parameters
        this.cacheManagerService.objectTypes.add({rawObjectType: {ids: {oid: 6684672}, fields: {}}});
    }

    /**
     * parses the backeend data to objecttype definitions the objecttypemodel needs the
     * cabinet data as well to add properties defined by the merged rightssystem
     */
    private parseObjectTypes(): void {
        const flatNavigation: { [name: string]: FlatNavigation} = {};

        for (const cabinet of this.navigation) {
            const parentId: string = cabinet.cabinetId.toString();
            const childTypes: string[] = [];
            let vtx = false;

            for (let j = 0; j < cabinet.objectTypes.length; j++) {
                const type: any = cabinet.objectTypes[j];

                // The webclient do not support container types which have the mainType 8.
                // We remove them from the objectTypes inside the current cabinet.
                if (type.mainType == 8) {
                    cabinet.objectTypes.splice(j, 1);
                    j--;
                    console.warn("Container document types are currently not supported");
                    continue;
                }

                const typeId: string = type.objectTypeId.toString();
                const rawObjectType: any = this.rawObjDefTypes[typeId];
                const fields: any = (Array.isArray(rawObjectType.fields.field)) ? rawObjectType.fields.field : [rawObjectType.fields.field];

                const rawNavCabinet: FlatNavigation = {} as FlatNavigation;
                rawNavCabinet.rights = type.rights;
                rawNavCabinet.name = type.name;
                rawNavCabinet.mainType = rawObjectType.maintype;
                rawNavCabinet.cabinetId = parentId;
                rawNavCabinet.vtx = (Number(fields[0].flags.flags) & 0x00010000) > 0;

                if (!rawNavCabinet.vtx) {
                    rawNavCabinet.vtx = (Number(fields[0].flags.flags2) & 0x20000000) > 0;
                }

                if (typeId != parentId) {
                    childTypes.push(typeId);
                }

                // One object type which is vtx enabled is enough that the cabinet get vtx true.
                if (rawNavCabinet.vtx) {
                    vtx = true;
                }

                flatNavigation[typeId] = rawNavCabinet;
            }

            flatNavigation[parentId].vtx = vtx;
            flatNavigation[parentId].childTypes = childTypes;
            cabinet.vtx = vtx;
            cabinet.expanded = false; // First of all it's closed. Later in the code we expand one from last session.
        }

        // Add all objects to the cache. This is odd. I find we don't need the cache in the future and use
        // this class as the owner and manager. Then we could optimize the code more and more instead delegating
        // it to a external class.
        for (const objectTypeId in flatNavigation) {
            const rawObjectType: any = this.rawObjDefTypes[objectTypeId];
            const rawNavCabinet: any = flatNavigation[objectTypeId];

            this.cacheManagerService.objectTypes.add({rawObjectType, rawNavCabinet});
        }
    }

    /**
     * reduce the navigation items and the available types by the types the user is not able to see
     * in this constellation we might be allowed to see types, the user is not allowed to see
     *
     * @param objectTypesToIgnore - an optional array of objecttype ids we have to ignore because the scripted said so in the "after login script"
     */
    private reduceAvailableTypes(objectTypesToIgnore: string[]): void {
        const types: ObjectType[] = this.cacheManagerService.objectTypes.getAll();
        const mappedTypesToIgnore: string[] = [];

        for (const typeToIgnore of objectTypesToIgnore) {
            for (const type of types) {
                if (typeToIgnore == type.model.osid || typeToIgnore == type.model.config.internal) {
                    mappedTypesToIgnore.push(type.model.osid as string);
                }
            }
        }

        for (let cabIndex = 0; cabIndex < this.navigation.length; cabIndex++) {
            const cabinet: Cabinet = this.navigation[cabIndex];

            for (let typeIndex = 0; typeIndex < cabinet.objectTypes.length; typeIndex++) {
                const type: ObjectTypeConfig = cabinet.objectTypes[typeIndex];
                const objectType: ObjectType = this.cacheManagerService.objectTypes.getById(type.objectTypeId);

                if (objectType == void 0 || mappedTypesToIgnore.includes(type.objectTypeId)) {
                    cabinet.objectTypes.splice(typeIndex, 1);
                    this.cacheManagerService.objectTypes.remove(type.objectTypeId);
                    typeIndex--;
                } else {
                    type.name = objectType.model.config.title;
                    type.internal = objectType.model.config.internal;
                }
            }

            if (cabinet.objectTypes.length == 0 || mappedTypesToIgnore.includes(cabinet.cabinetId)) {
                this.navigation.splice(cabIndex, 1);
                this.cacheManagerService.objectTypes.remove(cabinet.cabinetId);
                cabIndex--;
            } else {
                const cabinetObjectType: ObjectType = this.cacheManagerService.objectTypes.getById(cabinet.cabinetId);
                cabinet.name = cabinetObjectType?.model.config.title;
            }
        }

        // clean up for mapped types
        for (const mappedType of mappedTypesToIgnore) {
            for (const type of types) {
                const typeConfig: ObjectTypeConfig = type.model.config;
                const cabinetType: ObjectType = this.cacheManagerService.objectTypes.getById(typeConfig.cabinetId);
                const parentConfig: ObjectTypeConfig = cabinetType == void 0 ? null : cabinetType.model.config;

                if (parentConfig == void 0 || typeConfig.objectTypeId == mappedType || parentConfig.objectTypeId == mappedType) {
                    this.cacheManagerService.objectTypes.remove(type.model.osid);
                }
            }
        }
    }

    /**
     * Builds an array of objecttype ids containing each objecttype id that can be inserted into registers and folders
     * (for quick access and convenience)
     */
    private buildInsertableTypes(): void {
        const types: ObjectType[] = this.cacheManagerService.objectTypes.getAll();

        for (const objectType of types) {
            const config: ObjectTypeConfig = objectType.model.config;
            const cabinetObjectType: ObjectType = this.cacheManagerService.objectTypes.getById(config.cabinetId);

            if (cabinetObjectType == void 0) {
                continue;
            }

            if (config.mainType == "0" || config.mainType == "99") {
                const childTypes: any = cabinetObjectType.model.config.childTypes || [];
                const insertableTypes: any[] = [];

                for (let j: number = childTypes.length - 1; j >= 0; j--) {
                    const childTypeId: any = childTypes[j],
                        childObjectType: any = this.cacheManagerService.objectTypes.getById(childTypeId);
                    if (childObjectType == void 0) {
                        childTypes.splice(j, 1);
                        continue;
                    }

                    if (config.limitedObjects[childTypeId] != "0"
                        && childObjectType.model.config.rights[ObjectTypeRights.INDEXDATA_MODIFY]
                        && childObjectType.model.config.mainType != "8") {

                        insertableTypes.unshift(childTypeId);
                    }
                }

                config.insertableTypes = insertableTypes;
            }
        }
    }

    // endregion
    // region object type icons

    /**
     * Gathers all custom icons in an array and fetches them as a bulk from the appconnector
     * The icons are then turned into b64 png's and added to a generic stylesheet to later use the icons as classes
     *
     * @returns - returns nothing
     */
    private async fetchIconsAsync(): Promise<void> {
        const iconIds: Array<string | number> = []; // Todo: One data type should be the goal
        const currentIconMap: any = await this.iconService.getIcons();
        const types: ObjectType[] = this.cacheManagerService.objectTypes.getAll();

        for (const type of types) {
            if (!type.model) {
                // There is no runtime interface checking in TypeScript, so we rely on the presence of a model here
                // This shouldn't happen if we realy work with types. If it happens we have a bug in our code.
                // The scenario isn't explained in before why this could happen.
                console.warn("type has no model in the collection of ObjectType[].");
                continue;
            }

            const iconId: string | number = type.model.config.iconId;

            if (iconId != void 0 && !iconIds.includes(iconId) && currentIconMap[iconId] == void 0) {
                iconIds.push(Number(iconId));
            }

            for (const field of type.api.getFlatFieldList()) {
                if (field.tree != void 0 && field.tree.config.icons.length) {
                    for (const nodeIconId of field.tree.config.icons) {

                        if (isNaN(nodeIconId)) {
                            continue;
                        }

                        if (nodeIconId != void 0 && !iconIds.includes(nodeIconId) && currentIconMap[nodeIconId] == void 0) {
                            iconIds.push(Number(nodeIconId));
                        }
                    }
                }

                if (field.type == "pagecontrol") {
                    for (const page of field.pages) {
                        if (page.iconId != void 0 && page.iconId != "0" && !iconIds.includes(page.iconId) && currentIconMap[page.iconId] == void 0) {
                            iconIds.push(Number(page.iconId));
                        }
                    }
                }

                if (field.type == "button") {
                    if (field.iconId != void 0 && !["", "0", "no icon"].includes(field.iconId) && !iconIds.includes(field.iconId) && currentIconMap[field.iconId] == void 0) {
                        iconIds.push(Number(field.iconId));
                    }
                }
            }
        }

        // Todo: Really self explaining. I suggest to rename acc and value to y and z
        //       Why does our iconIds not fit for the icon service? Is it not our code? Are we not able to unify it?
        this.availableObjectTypeIcons = [...await this.iconService.processIcons(iconIds.map(x => `${x}`))].reduce((acc, val) => {
            acc[val[0]] = val[1];
            return acc;
        }, {});

        this.setFallbackObjectTypeIcons();
    }

    /**
     * Adds fallback icons to the objecttypes with unknown custom icons
     * The fallback is in general the maintype specific icon.
     * Todo: High performance. Let's do a second iteration because fetchIconsAsync wasn't enough.
     *       Also load all stuff from cacheManager where we inserted them before. We don't know our own stuff.
     */
    private setFallbackObjectTypeIcons(): void {
        const availableTypes: ObjectType[] = this.cacheManagerService.objectTypes.getAll();

        for (const type of availableTypes) {
            const iconId: string | number = type.model.config.iconId;

            if (iconId != void 0 && this.availableObjectTypeIcons[iconId] == void 0) {
                for (const cab of this.navigation) {
                    for (const cabType of cab.objectTypes) {
                        if (cabType.objectTypeId == type.model.config.objectTypeId) {
                            // some icons failed and at this point we apply a fallback
                            // the fallback only applies to objecttypes
                            cabType.iconId = undefined;
                            const availableType: ObjectType = availableTypes.find(x => x.model.osid == cabType.objectTypeId);

                            if (availableType) {
                                availableType.model.config.iconId = undefined;
                                availableType.model.config.icon = undefined;
                            }
                            cabType.icon = this.getIconClass(cabType.objectTypeId);
                        }
                    }
                }
            }
        }
    }

    /**
     * adds the user configured custom icons to the cabinets in the navigation
     */
    private setNavigationTypeIcons(): void {
        for (const navigationItem of this.navigation) {
            for (const cabType of navigationItem.objectTypes) {
                cabType.icon = this.getIconClass(cabType.objectTypeId, cabType.iconId);
            }
        }
    }

    /**
     * Creates a class for the icon element
     *
     * @param  objectTypeId - Objecttypeid
     * @param  iconId       - Optional iconid for when the type has a custom icon
     * @param  getDarkIcon - Optional boolean ; returns the dark icon if we need it. Only applies to register and folder icon
     * @param  subType     - Optional Number between 1 and 8 for multitype default icons
     * @returns - Return the classname of the current icon
     */
    getIconClass(objectTypeId: string | number, iconId?: string | number, getDarkIcon: boolean = false, subType?: string): string {
        const type: ObjectType = this.cacheManagerService.objectTypes.getById(objectTypeId);

        if (iconId != "0" && iconId != null && iconId != void 0) {
            // special icon extracted from indexdata (icon catalog)
            // the icon might not exist or whatever
            // we will either fall back to the cached objecttype icon or the default maintype icon
            if (this.availableObjectTypeIcons[iconId] != void 0) {
                return `custom-icon-${iconId}`;
            }
        }

        let mainType: string;

        // Fix in case a typeless document found its way inside the desktopgrids
        if (objectTypeId != void 0) {
            mainType = (Number(objectTypeId) >>> 16).toString();
        }

        if (type && type.model.config && type.model.config.iconId != void 0) {
            return `custom-icon-${type.model.config.iconId}`;
        }

        if (subType) {
            return this.getDefaultMainTypeIcon(subType, getDarkIcon);
        }

        return this.getDefaultMainTypeIcon(mainType, getDarkIcon);
    }

    /**
     * @param maintype - the maintype of the object
     * @param getDarkIcon - a boolean wheter to return the dark icon or not
     * @returns - returns the icon class string of the given maintype
     */
    getDefaultMainTypeIcon(maintype: string, getDarkIcon: boolean): string {
        switch (maintype) {
            case "2":
                return getDarkIcon ? "OT-SW-Image" : "OT-SW-Image-white";
            case "3":
                return getDarkIcon ? "OT-Color-Image" : "OT-Color-Image-white";
            case "4":
                return getDarkIcon ? "OT-W-Doc" : "OT-W-Doc-white";
            case "5":
                return getDarkIcon ? "OT-Multi-Media" : "OT-Multi-Media-white";
            case "6":
                return getDarkIcon ? "OT-Mail" : "OT-Mail-white";
            case "7":
                return getDarkIcon ? "OT-XML" : "OT-XML-white";
            case "0":
                return getDarkIcon ? "OT-Archive-dark" : "OT-Archive";
            case "99":
                return getDarkIcon ? "OT-Register-dark" : "OT-Register";
            case "1":
            default:
                return getDarkIcon ? "OT-Gray-Image" : "OT-Gray-Image-white";
        }
    }

    // endregion

    /**
     * @returns - returns the navigation
     */
    getNavigation(): Cabinet[] {
        return this.navigation;
    }

    /**
     * @param id - the objectTypeId
     * @returns - true or false
     */
    isValidObjectTypeId(id: number | string): boolean {
        if (id == void 0 || id === "" || isNaN(Number(id))) {
            console.warn("The objectTypeId must be a valid number:", id);
            return false;
        }

        if (!this.cacheManagerService.objectTypes.contains(id)) {
            console.warn("The user has no rights to see the objectType or the objectType does not exist:", id);
            return false;
        }
        return true;
    }

    /**
     * @param cabinetName - the name/internal of the cabinet
     * @param desiredObjectTypeName - the name/internal of the desired objectType
     * @returns - returns void or the found objectTypeId
     */
    guessObjectTypeIdByCabinetAndName(cabinetName: string, desiredObjectTypeName: string): any {
        if (cabinetName == void 0 || desiredObjectTypeName == void 0) {
            return;
        }

        const objectTypes: ObjectType[] = this.cacheManagerService.objectTypes.getAll();
        const cabinet: ObjectType = objectTypes.find(objectType => objectType.model.config.internal == cabinetName || objectType.model.config.name == cabinetName);

        if (cabinet != void 0) {
            const childTypes: any[] = this.cacheManagerService.objectTypes.get(cabinet.model.config.childTypes);
            const objectType: ObjectType = childTypes.find(childType => childType.model.config.internal == desiredObjectTypeName || childType.model.config.name == desiredObjectTypeName);

            if (objectType != void 0) {
                return objectType.model.osid;
            }
        }
    }

    /**
     * Convenience function to check for folder
     *
     * @param objectTypeId -The objectTypeId
     * @returns - returns true / false
     */
    isFolderType(objectTypeId: string): boolean {
        return this.getObjectType(objectTypeId) == "folder";
    }

    /**
     * Convenience function to check for register type
     *
     * @param objectTypeId - The objectTypeId
     * @returns - returns true / false
     */
    isRegisterType(objectTypeId: string): boolean {
        return this.getObjectType(objectTypeId) == "register";
    }

    /**
     * Convenience function to check for document type
     *
     * @param objectTypeId - The objectTypeId
     * @returns - returns true / false
     */
    isDocumentType(objectTypeId: string): boolean {
        return this.getObjectType(objectTypeId) == "document";
    }

    /**
     * Convenience function to check for a typeless document
     *
     * @param objectTypeId - The objectTypeId
     * @returns - returns true / false
     */
    isTypeless(objectTypeId: string): boolean {
        return this.getObjectType(objectTypeId) == "typeless";
    }

    /**
     * Returns the type of a dms document as document / register / folder / typeless
     * Todo: This is NOT the objectType!!! It's the BaseType.
     *
     * @param objectTypeId - The objectTypeId
     * @returns - returns the name of the type
     */
    getObjectType(objectTypeId: string): string {
        const mainType: number = Number(objectTypeId) >> 16;
        let objectType: string;

        if (mainType == 0) {
            objectType = "folder";
        } else if (mainType == 99) {
            objectType = "register";
        } else if (mainType == 200 || mainType == 300 || Number(objectTypeId) == -1) {
            objectType = "typeless";
        } else {
            objectType = "document";
        }

        return objectType;
    }

    /**
     * Todo: The complete class must be refactored. Not the CacheManagerService should hold
     *       The object types. This class here is the owner!
     *
     * @param objectTypeId The object type Id of the object type to load.
     */
    getRealObjectType(objectTypeId: string): ObjectType {
        return this.cacheManagerService.objectTypes.getById(objectTypeId);
    }
}
