<div class="substitute-wrapper">
    <div class="substitute-config-container">
        <div class="form-container">
            <div class="form-wrapper">
                <eob-form #formrow [formdef]="formDef" class="single-column"></eob-form>
            </div>
        </div>
        <div class="button-container" [ngClass] = "{'substitutes-phone':isPhone}">
            <button class="form-button" (click) = "addSubstitutes()">{{'modal.button.add' | ngTranslate}}</button>
        </div>
    </div>
    <div #configuredsubstitutes class="configured-substitutes">
        <div class="substitute-list-item" *ngFor="let substitute of substitutes">
            <i class="icon-16-user substitute-avatar"></i>
            <span class="substitute-user-name">{{substitute.name}}</span>
            <button class="substitute-delete-button" (click) = "removeSubstitute(substitute.id)">
                <i class="icon-16-delete-row"  role="img" [attr.aria-label]="'modal.button.delete' | ngTranslate"></i>
            </button>
        </div>
    </div>
</div>
