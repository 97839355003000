import {Injectable, Inject} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {RawField, FieldConfig} from "INTERFACES_PATH/field.interface";
import {FieldControlType} from "ENUMS_PATH/field.enum";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {FormFieldModelService} from "MODULES_PATH/form/services/form-field-model.service";

/**
 * Fieldset builder service: A service that creates a form pagecontrol element
 */
@Injectable({
    providedIn: "root"
})
export class FieldsetBuilderService {

    private readonly translateFn: TranslateFnType;
    baseParamFieldTitleMap: Map<string, string>;

    constructor(@Inject("$filter") private $filter: ng.IFilterService, private formFieldModelService: FormFieldModelService) {
        this.translateFn = $filter("translate");
        this.baseParamFieldTitleMap = new Map();
    }

    /**
     * Builds responsive field set creates a flat list of all fieldModels recursively
     *
     * @param rawFields - the fields from the object definition
     * @param responsiveFieldList - an array where all fields are pushed into
     * @param objectTypeConfig - the config of the objecttype
     * @param pageIndex - the index of the current pagecontrol page
     * @param parentPage - the parent page object
     * @param pageControl - the parent pagecontrol
     * @returns - returns an array of all fieldmodels from the current parent object (or form)
     */
    buildResponsiveFieldSet(rawFields: RawField[], responsiveFieldList: FieldModel[], objectTypeConfig: ObjectTypeConfig, pageIndex?: string, parentPage?: string, pageControl?: any): FieldModel[] {
        // @ pageIndex
        // we need the pageindex to determine the right amount of radiobuttons per group
        // this is because in the taborder can be 6 radiobuttons in a row, but on different pages
        // we have to know which radiobuttons have to be grouped together for special purposes...
        // the temporary list of fields from one pagecontrol or a objecttype
        const fieldCollection: FieldModel[] = [];
        // we need this to ensure we can iterate those fields
        // the object definition returns an object literal instead an array, if
        // the object definition contains only 1 field (e.g. a pagecontrol)
        if (!Array.isArray(rawFields)) {
            rawFields = [rawFields];
        }

        for (const rawField of rawFields) {
            if (this.isSupported(rawField)) {
                const conf: FieldConfig<FieldModel> = {
                    field: rawField,
                    pageIndex,
                    parentPage,
                    pageControl,
                    parameter: null,
                    isWorkflow: false,
                    objectTypeConfig
                };

                const field: FieldModel = this.formFieldModelService.getFieldModel(conf);

                if (field.type == "pagecontrol") {
                    for (const j in field.pages) {
                        field.pages[j].index = j;
                        field.pages[j].fields = this.buildResponsiveFieldSet(field.pages[j].fields, responsiveFieldList, objectTypeConfig, j, field.internal, field);
                    }
                }

                if (field.type == "group") {
                    if (field.fields == void 0) {
                        field.fields = [];
                    }

                    field.fields = this.buildResponsiveFieldSet(field.fields, responsiveFieldList, objectTypeConfig, pageIndex, field.internal, pageControl);
                }

                fieldCollection.push(field);
                responsiveFieldList.push(field);

                if (!field.internal) {
                    console.warn("internal name missing", field, rawField);
                }
            }
        }

        // Fields must be sorted by tabIndex to show the hitlist, if no customer configuration is present,
        // and to build the file name for e.g. .os references in the same order as the rich client.
        responsiveFieldList.sort((fieldA, fieldB) => Number(fieldA.tabIndex) - Number(fieldB.tabIndex));

        return fieldCollection;
    }

    /**
     * Is supported method check the supported flags for a specific field
     *
     * @param rawField - the unparsed field from the object definition
     * @return - returns true when the field is supported by the webclient
     */
    isSupported(rawField: RawField): boolean {
        if (rawField.flags.multifield == "1") {
            return false;
        }

        if (rawField.flags.datatype == "bitmap") {
            // imagecontrol
            return false;
        } else if (rawField.flags.datatype == "text" && rawField.flags.dt == "E") {
            // webcontrol
            return false;
        }

        return true;
    }

    /**
     * Is common field method
     *
     * @param field - the field model of the current field
     * @returns - returns true when the field can hold a value by itself
     */
    isCommonField(field: any): boolean {
        return !Object.values(FieldControlType).includes(field.type);
    }

    /**
     * Get base param field by internal
     *
     * @param internal - the internal of the baseparam
     * @returns - returns the baseparam field object with the given internal name
     */
    getBaseParamFieldByInternal(internal: string): any {
        const baseParamArray: FieldModel[] = this.buildBaseParamFields(2, true).concat(this.buildBaseParamFields(2, false));

        // remove double entries
        const seen: any = {};
        const baseParamFields: any = baseParamArray.filter((item) => {
            if (seen.hasOwnProperty(item.internal)) {
                return false;
            } else {
                seen[item.internal] = true;
                return true;
            }
        });

        // find the baseParam in array
        const baseParamField = baseParamFields.find(param => param.baseParamName === internal);

        return baseParamField;
    }

    /**
     * Get all base params for hitlist config
     *
     * @param option - Which baseparameters should be used depending on type document or folder/register
     * @returns - all baseparameters for the given type (document / register / folder)
     */
    getAllBaseparamsForHitlistConfig(option: string): FieldModel[] {
        return this.buildBaseParamFields(option, true);
    }

    /**
     * Build base param fields
     *
     * @param maintype - the maintype of the
     * @param isHitlistConfig - determines if the requested baseparameters are used by the hitlist config
     * @returns - returns an array of baseparameters for the given maintype
     */
    buildBaseParamFields(maintype: any, isHitlistConfig: boolean): FieldModel[] {
        const rawFields: RawField[] = [];
        rawFields.push(this.createBaseParamField("baseparamCreator", "eob.baseparam.creator", "", "X", "", "axaddusr.dll", "", "anleger", 0));
        rawFields.push(this.createBaseParamField("baseparamCreated", "eob.baseparam.created", "", "9", "4194304", "", "", "angelegt", 1));
        rawFields.push(this.createBaseParamField("baseparamModifier", "eob.baseparam.modifier", "", "X", "", "axaddusr.dll", "", "modifyuser", 2));
        rawFields.push(this.createBaseParamField("baseparamModified", "eob.baseparam.modified", "", "9", "4194304", "", "", "modifytime", 3));

        if (!isHitlistConfig && maintype != "register" && maintype != "folder") {
            rawFields.push(this.createBaseParamField("baseparamOwner", "eob.baseparam.owner", "", "X", "", "axaddusr.dll", "", "owner", 4));
        }

        if (this.isDocument(maintype)) {
            rawFields.push(this.createBaseParamField("baseparamRetentionPlannedDate", "eob.baseparam.retention.date.planned", "", "D", "4194304", "", "", "retention_planned", 5));
            rawFields.push(this.createBaseParamField("baseparamRetentionDate", "eob.baseparam.retention.date", "", "D", "4194304", "", "", "retention", 6));
            rawFields.push(this.createBaseParamField("baseparamArchiveDate", "eob.baseparam.archived.date", "", "D", "4194304", "", "", "archiviert", 7));

            if (!isHitlistConfig) {
                rawFields.push(this.createBaseParamField("baseparamLockedByMe", "eob.baseparam.locked.by.me", "checkbox", "0", "", "", "lock", "", 8));
                rawFields.push(this.createBaseParamField("baseparamLockedByOther", "eob.baseparam.locked.by.other", "checkbox", "0", "", "", "lock-error", "", 9));
                rawFields.push(this.createBaseParamField("baseparamArchiveStateReference", "eob.baseparam.reference", "checkbox", "0", "", "", "REFERENCE", "", 10));
                rawFields.push(this.createBaseParamField("baseparamArchiveStateArchived", "eob.baseparam.archived", "checkbox", "0", "", "", "archived", "", 11));
                rawFields.push(this.createBaseParamField("baseparamArchiveStateNotArchivable", "eob.baseparam.not.archivable", "checkbox", "0", "", "", "NOT-ARCHIVABLE", "", 12));
                rawFields.push(this.createBaseParamField("baseparamArchiveStateArchivable", "eob.baseparam.archivable", "checkbox", "0", "", "", "archivable", "", 13));
                rawFields.push(this.createBaseParamField("baseparamArchiveStateNoPages", "eob.baseparam.without.pages", "checkbox", "0", "", "", "NO-PAGES", "", 14));
                rawFields.push(this.createBaseParamField("baseparamHasVariants", "eob.baseparam.has.variants", "checkbox", "0", "", "", "variant", "", 15));
                rawFields.push(this.createBaseParamField("baseparamMultiLocation", "eob.baseparam.multiple.locations", "checkbox", "0", "", "", "baseparam-multiple-locations", "", 16));
                rawFields.push(this.createBaseParamField("baseparamInRegister", "eob.baseparam.in.register", "checkbox", "0", "", "", "baseparam-in-register", "", 17));
                rawFields.push(this.createBaseParamField("baseparamNotInRegister", "eob.baseparam.not.in.register", "checkbox", "0", "", "", "baseparam-in-no-register", "", 18));
                rawFields.push(this.createBaseParamField("baseparamSignedCurrentVersion", "eob.baseparam.singed.in.current.version", "checkbox", "0", "", "", "signed-current-version", "", 19));
                rawFields.push(this.createBaseParamField("baseparamSignedPreviousVersion", "eob.baseparam.singed.in.previous.version", "checkbox", "0", "", "", "signed-version-before", "", 20));
            } else {
                rawFields.push(this.createBaseParamField("baseparamDocumentCount", "eob.baseparam.document.count", "", "Z", "", "", "", "anzahl", 21));
                rawFields.push(this.createBaseParamField("baseparamTimeStamp", "eob.baseparam.time.stamp", "", "X", "", "", "", "zeitstempel", 22));
                rawFields.push(this.createBaseParamField("baseparamArchivist", "eob.baseparam.archivist", "", "X", "", "", "", "archivar", 23));
            }
        }

        const result: FieldModel[] = [];

        rawFields.forEach((rawField) => {
            const conf: FieldConfig<FieldModel> = {
                field: rawField,
                pageIndex: undefined,
                parentPage: undefined,
                parameter: null,
                isWorkflow: false,
                objectTypeConfig: null
            };

            const field: FieldModel = this.formFieldModelService.getFieldModel(conf);

            result.push(field);
        });

        return result;
    }

    /**
     * Create base param field
     *
     * @param name - the name of the field
     * @param resourceKey - text resource key to be translated
     * @param controlType - the field type as a string given by the object definition
     * @param dataType - the datatype of the field
     * @param flags1 - the bitmap with all the properties
     * @param addon - which addon
     * @param icon - the icon class
     * @param baseParamName - the baseparameter name
     * @param taborder - the tab index
     * @returns - returns a mocked field for base parameters
     */
    private createBaseParamField(name: string, resourceKey: string, controlType: string, dataType: string, flags1: string, addon: string, icon: string, baseParamName: string, taborder: number): any {
        let title: any = this.baseParamFieldTitleMap.get(resourceKey);

        if (!title) {
            title = this.translateFn(resourceKey);
            this.baseParamFieldTitleMap.set(resourceKey, title);
        }

        return {
            internal: name,
            name,
            taborder,
            names: {name: [{lang_id: 7, content: title}, {lang_id: 9, content: title}]},
            flags: {control_type: controlType, dt: dataType, flags1},
            isBaseParam: true,
            list: {ADDON32: addon, rawdata: ""},
            icon,
            baseParamName
        };
    }

    /**
     * Combines the fields of the objecttype
     * 1. Fields that are configured to be "inside" a group container have to be a child of the group to be rendered correctly
     *    Fields that appear to be inside the groups rectangle will be spliced from the current parent and added to tha group field
     * 2. Radiobuttons that are following each other by the tabindex will be added to the first radiobutton in the row and are
     *    removed from the current parent
     *
     * @param fields - the raw fields from the form / pagecontrol
     * @param isWorkflow - if its a workflow we have to map the property names in another way
     * @returns - returns the newly combined fields
     */
    combineResponsiveFields(fields: RawField | RawField[], isWorkflow: boolean): RawField[] {
        if (!Array.isArray(fields)) {
            fields = [fields];
        }

        const iterableFields: string[] = ["field_pos", "input_pos"];
        const resultFields: RawField[] = $.extend([], fields);

        for (let i = 0; i < resultFields.length; i++) {
            const field: RawField = resultFields[i];
            const dataType: string = isWorkflow ? field.DataType : field.flags.dt;
            let fieldPages: any = isWorkflow ? field.Page : field.page;

            if (isWorkflow) {
                field.internal = field.Id;
            }

            // in this case we got a pagecontrol without pages
            // this is not a valid szenario, but it might occure due to a lack of validation in an older
            // version of the editor, ignore the whole field .. out of sight out of mind :P
            if (dataType == "C" && fieldPages == void 0) {
                resultFields.splice(i, 1);
                i--;
                continue;
            }

            if (isWorkflow) {
                field.field_pos = {
                    bottom: +field.FieldBottom,
                    left: +field.FieldLeft,
                    right: +field.FieldRight,
                    top: +field.FieldTop
                };

                field.input_pos = {
                    bottom: +field.InpBottom,
                    left: +field.InpLeft,
                    right: +field.InpRight,
                    top: +field.InpTop
                };
            }

            if (dataType == "1" && field.masterRadioInternal == void 0) {
                // this is our new master
                // we need this field to search for values (each radiobutton value is assigned to this field)
                // in case these radiobuttons are found inside a group field
                // the name of the groupfield is our master

                field.isMasterRadio = true;
                field.masterRadioInternal = isWorkflow ? field.Id : field.internal;
                field.fields = isWorkflow ? [field.Id] : [field.internal];

                let n = 1;
                let nextField: RawField = resultFields[i + n],
                    nextFieldDataType: string = nextField == void 0 ? null : isWorkflow ? nextField.DataType : nextField.flags.dt;

                while (nextField && nextFieldDataType == "1") {
                    nextField.masterRadioInternal = isWorkflow ? field.Id : field.internal;
                    const radioIdentifier: string = isWorkflow ? nextField.Id : nextField.internal;

                    field.fields.push(radioIdentifier);
                    n++;

                    nextField = resultFields[i + n];
                    nextFieldDataType = nextField == void 0 ? null : isWorkflow ? nextField.DataType : nextField.flags.dt;
                }

                const prevField: RawField = resultFields[i - 1],
                    prevFieldDataType: string = prevField == void 0 ? null : isWorkflow ? prevField.DataType : prevField.flags.dt;

                if (prevField == void 0) {
                    continue;
                }

                if (prevFieldDataType == "G") {
                    field.groupInternal = isWorkflow ? prevField.Id : prevField.internal;
                }
            }

            if (dataType == "C") {
                if (!Array.isArray(fieldPages)) {
                    fieldPages = [fieldPages];
                }

                for (const page of fieldPages) {

                    if (isWorkflow) {
                        page.MaskFields.MaskField = this.combineResponsiveFields(page.MaskFields.MaskField, true);

                        field.page = fieldPages;

                        page.fields = {
                            field: page.MaskFields.MaskField
                        };

                    } else {
                        page.fields.field = this.combineResponsiveFields(page.fields.field, false);
                    }
                }
            }
        }

        // Find all groups sorted by the highest y-coordinate and combine groups that are meant to be a child of other groups
        // according to their coordinates
        const groups: RawField[] = this.findGroups(resultFields, isWorkflow);

        if (groups.length > 0) {
            for (const group of groups) {
                group.fields = [];

                for (let j = 0; j < resultFields.length; j++) {
                    if (isFieldInGroup(group, resultFields[j])) {
                        repositionInnerField(group, resultFields[j]);
                        group.fields.push(resultFields[j]);
                        resultFields.splice(j, 1);
                        j--;
                    }
                }
            }

            for (let g = 0; g < groups.length; g++) {
                const group: RawField = groups[g];
                for (let n: number = (g + 1); n < groups.length; n++) {
                    const nextGroup: RawField = groups[n];
                    const isInside: boolean = isFieldInGroup(nextGroup, group);

                    if (isInside) {
                        repositionInnerField(nextGroup, group);
                        nextGroup.fields.push(group);
                        groups.splice(g, 1);
                        g--;
                        break;
                    }
                }
            }
        }

        /**
         * @param group - the group field
         * @param field - the field to check if its part of the group
         * @returns - returns true when the whole field is inside the group field
         */
        function isFieldInGroup(group: RawField, field: RawField): boolean {
            const groupTop = Number(group.field_pos.top),
                groupLeft = Number(group.field_pos.left),
                groupBottom: number = Number(group.field_pos.top) + Number(group.field_pos.bottom),
                groupRight: number = Number(group.field_pos.left) + Number(group.field_pos.right);

            for (const fieldElement of iterableFields) {
                const fieldTop = Number(field[fieldElement].top),
                    fieldLeft = Number(field[fieldElement].left),
                    fieldBottom: number = Number(field[fieldElement].top) + Number(field[fieldElement].bottom),
                    fieldRight: number = Number(field[fieldElement].left) + Number(field[fieldElement].right);

                if (fieldTop > groupTop && fieldBottom < groupBottom && fieldLeft > groupLeft && fieldRight < groupRight) {
                    return true;
                }
            }
        }

        /**
         * Adjust the top and the left of the repositioned field because we need the positions relative to the parent
         * to act as if they are a real child of the group field
         *
         * @param group - the group field
         * @param innerField - the field that is now a child of the group
         */
        function repositionInnerField(group: RawField, innerField: RawField): void {
            for (const fieldElement of iterableFields) {
                innerField[fieldElement].top = Number(innerField[fieldElement].top) - Number(group.field_pos.top);
                innerField[fieldElement].left = Number(innerField[fieldElement].left) - Number(group.field_pos.left);
            }
        }

        return resultFields.concat(groups);
    }

    /**
     * Find groups
     *
     * @param fields - all raw fields of the objecttype / pagecontrol
     * @param isWorkflow - *self explanatory*
     * @returns - returns a sorted array of all groups sorted by the bottom position of the groups
     */
    findGroups(fields: RawField[], isWorkflow: boolean): RawField[] {
        const groups: RawField[] = [];

        for (let i = 0; i < fields.length; i++) {
            const field: RawField = fields[i];
            const dataType: string = isWorkflow ? field.DataType : field.flags.dt;
            if (dataType == "G") {
                groups.push(field);
                fields.splice(i, 1);
                i--;
            }
        }

        groups.sort((a, b) => {
            const x = Number(a.field_pos.bottom);
            const y = Number(b.field_pos.bottom);
            return x < y ? -1 : x > y ? 1 : 0;
        });

        return groups;
    }

    /**
     * isDocument
     * checks if the maintype is related to the "document" type
     *
     * @param maintype - maintype identifier
     * @returns - self explanatory
     */
    private isDocument(maintype: any): boolean {
        if (maintype == "document") {
            return true;
        } else if (isNaN(maintype)) {
            return false;
        } else {
            return !(maintype == 0 || maintype == 99);
        }
    }
}