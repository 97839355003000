import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {Inject, Injectable} from "@angular/core";
import {
    CirculationSlipTemplateContextMenuItem,
    ContextMenuAction,
    ContextMenuActionsProvider,
    ContextType,
    WorkflowContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {InboxActionService} from "CORE_PATH/services/actions/inbox-action.service";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {ContextMenuActionsService} from "MODULES_PATH/context-menu/services/context-menu-actions.service";
import {ClipboardDmsDocument} from "INTERFACES_PATH/custom-storage.interface";
import {Cabinet} from "INTERFACES_PATH/object-type.interface";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {defer, Observable, of} from "rxjs";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import { ActionService } from "CORE_PATH/services/actions/action.service";
import { TodoCacheManagerService, TodoEnvironmentService, TodoPermittedObjectTypes, TodoStateHistoryManager } from "INTERFACES_PATH/any.types";

@Injectable({
    providedIn: ContextMenuModule
})
export class WorkflowContextMenuService implements ContextMenuActionsProvider {
    constructor(private contextMenuUtilsService: ContextMenuUtilsService,
                private contextMenuActionsService: ContextMenuActionsService,
                private clientService: ClientService,
                private actionService: ActionService,
                private objectTypeService: ObjectTypeService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("inboxActionService") private inboxActionService: InboxActionService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                private iconService: IconService
    ) {
    }

    getSingleSelectionContextMenuActions(contextMenuItem: unknown, contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            const contextMenuActions: ContextMenuAction[] = [];

            if (contextData.context == ContextType.WORKFLOW && this.clientService.isOnline()) {
                this.getWorkflowStateContextMenuActions(contextMenuItem as WorkflowContextMenuItem, contextMenuActions);
            } else if (contextData.context == ContextType.WFFILEAREAWORKFILES || contextData.context == ContextType.WFFILEAREAINFOFILES) {
                if (contextMenuItem) {
                    await this.getWorkflowFileAreaContextMenuActions(contextMenuActions, contextMenuItem as WorkflowContextMenuItem, contextData);
                } else {
                    this.getEmptySpaceContextMenuActions(contextMenuActions, contextData);
                }
            } else if (contextData.context == ContextType.CIRCULATIONSLIP) {
                this.getCirculationSlipContextMenuActions(contextMenuItem as CirculationSlipTemplateContextMenuItem, contextMenuActions);
            }

            return contextMenuActions;
        });
    }

    getMultiselectionContextMenuActions(contextMenuItems: WorkflowContextMenuItem[], contextData: ContextData): Observable<ContextMenuAction[]> {
        const contextMenuActions: ContextMenuAction[] = [];

        let canDepersonalze = false,
            canPersonalize = false,
            canSetRead = false,
            canSetUnread = false;

        for (const item of contextMenuItems) {
            if (item.personalized == "") {
                canPersonalize = true;
            } else {
                canDepersonalze = true;
            }

            if (item.read) {
                canSetUnread = true;
            } else {
                canSetRead = true;
            }
        }

        if (this.clientService.isOnline()) {
            if (canPersonalize) {
                contextMenuActions.push(this.addPersonalizeWorkflows(contextMenuItems));
            }

            if (canDepersonalze) {
                contextMenuActions.push(this.addDepersonalizeWorkflows(contextMenuItems));
            }

            if (canSetRead) {
                contextMenuActions.push(this.addMarkWfItemRead(contextMenuItems));
            }

            if (canSetUnread) {
                contextMenuActions.push(this.addMarkWfItemUnread(contextMenuItems));
            }
        }

        return of(contextMenuActions);
    }

    private getWorkflowStateContextMenuActions(contextMenuItem: WorkflowContextMenuItem, contextMenuActions: ContextMenuAction[]): void {
        contextMenuActions.push(this.addOpenRunningWorkflow(contextMenuItem));

        if (contextMenuItem.personalized == "") {
            contextMenuActions.push(this.addPersonalizeWorkflows([contextMenuItem]));
        } else {
            contextMenuActions.push(this.addDepersonalizeWorkflows([contextMenuItem]));
        }

        if (contextMenuItem.read) {
            contextMenuActions.push(this.addMarkWfItemUnread([contextMenuItem]));
        } else {
            contextMenuActions.push(this.addMarkWfItemRead([contextMenuItem]));
        }
    }

    private async getWorkflowFileAreaContextMenuActions(contextMenuActions: ContextMenuAction[], contextMenuItem: WorkflowContextMenuItem, contextData: ContextData): Promise<void> {
        const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(contextMenuItem.osid);
        const docModel: DmsDocumentModel = dmsDocument.model;
        const contextItem: WorkflowContextMenuItem = dmsDocument.api.getContextItem(contextMenuItem.guid);

        if (contextItem.model.wfFile.deletable) {
            contextMenuActions.push(this.addRemoveFromWorkflowFiles(docModel));
        }

        if (contextItem.model.wfFile.movable && contextData.showInfoArea) {
            contextMenuActions.push(this.addMoveToWorkInfoArea(contextData.context, docModel));
        }

        if (contextMenuActions.length > 0) {
            contextMenuActions.push(this.contextMenuUtilsService.addDivider());
        }

        await this.contextMenuActionsService.getSingleselectItems(contextMenuItem, contextMenuActions, contextData.context, contextData);
    }

    private getEmptySpaceContextMenuActions(contextMenuActions: ContextMenuAction[], contextData: ContextData): void {
        const location: string = contextData.context == "wfFileAreaWorkFiles" ? "work" : "info";
        const clipboard: ClipboardDmsDocument = this.environmentService.getClipboard();

        if (this.clientService.isOnline()) {
            if (clipboard && clipboard.item && clipboard.clipboardAction == "copy") {
                if (this.isPermittedObjectType(contextData.permittedObjectTypes, clipboard.item.model.internal)) {
                    contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.insert.title",
                        "clipboard-get", this.actionService.copyToWorkflow, contextData.context));
                }
            }

            const data: Cabinet[] = this.objectTypeService.getNavigation();
            const entries: Cabinet[] = data;
            const cabinetSubmenuActions: ContextMenuAction[] = [];

            let oneRestriction = false;

            for (const entry of entries) {
                const objectTypeSubmenuActions: ContextMenuAction[] = [];
                const icon = entry.iconId && this.iconService.hasIcon(`${entry.iconId}`) ? `custom-icon-${entry.iconId}` : "OT-Archive-dark";

                for (const objectType of entry.objectTypes) {
                    const subIcon = `${this.objectTypeService.getIconClass(objectType.objectTypeId, objectType.iconId, true)}`;

                    if (objectType.mainType != "99") {
                        if (this.isPermittedObjectType(contextData.permittedObjectTypes, objectType.internal)) {
                            objectTypeSubmenuActions.push(this.contextMenuUtilsService.addAction(objectType.name, subIcon,
                                this.stateHistoryManager.goToCreateTypedInWfTray, {
                                    objectType,
                                    location
                                }));
                        } else {
                            oneRestriction = true;
                        }
                    }
                }

                if (objectTypeSubmenuActions.length > 0) {
                    cabinetSubmenuActions.push(this.contextMenuUtilsService.addSubmenu(entry.name, icon, objectTypeSubmenuActions));
                }
            }

            if (!oneRestriction) {
                this.addWithoutTypeSubmenus(cabinetSubmenuActions, location);
            }

            if (cabinetSubmenuActions.length > 0) {
                contextMenuActions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.new.title",
                    "dokument-neu", cabinetSubmenuActions));
            }
        }
    }

    private isPermittedObjectType(permittedObjectTypes: TodoPermittedObjectTypes, objectType: string) {
        if (!this.environmentService.featureSet.contains("workflow.process.objectType")) {
            return true;
        }

        if (permittedObjectTypes == void 0 || permittedObjectTypes.objectTypesAllowed === false) {
            return false;
        }

        if (permittedObjectTypes.objectTypesAllowed === undefined
            || (permittedObjectTypes.objectTypesAllowed === true && permittedObjectTypes.objectTypes.length === 0)) {
            return true;
        }

        return !!permittedObjectTypes.objectTypes.find(x => x === objectType);
    }

    private getCirculationSlipContextMenuActions(template: CirculationSlipTemplateContextMenuItem, contextMenuActions: ContextMenuAction[]): void {
        const canManagePrivateCirculation: boolean = this.environmentService.userHasRole("R_CLNT_WFPRIVATEROUTELIST");
        const canManagePublicCirculation: boolean = this.environmentService.userHasRole("R_CLNT_WFPUBLICROUTELIST");

        contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.workflow.circulation.context.append", "clipboard-get",
            this.actionService.circulationSlipAppend, template));

        if (this.clientService.isOffline()) {
            return; // further actions need connectivity
        }

        if (canManagePrivateCirculation && canManagePublicCirculation) {
            if (template.model.isPublic) {
                contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.workflow.circulation.context.personalize",
                    "wf-personalize", this.actionService.circulationSlipMove, template));
            } else {
                contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.workflow.circulation.context.publish",
                    "wf-depersonalize", this.actionService.circulationSlipMove, template));
            }
        }

        if ((template.model.isPublic && canManagePublicCirculation) || (!template.model.isPublic && canManagePrivateCirculation)) {
            contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.workflow.circulation.context.delete",
                "delete-dark", this.actionService.circulationSlipDelete, template));
        }
    }

    private addOpenRunningWorkflow(contextMenuItem: WorkflowContextMenuItem): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.inboxitem.open",
            "wf-edit", this.inboxActionService.openRunningWorkflow, contextMenuItem);
    }

    private addPersonalizeWorkflows(contextMenuItems: WorkflowContextMenuItem[]): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.inboxitem.personalize",
            "wf-personalize", this.inboxActionService.personalizeWorkflows, contextMenuItems);
    }

    private addDepersonalizeWorkflows(contextMenuItems: WorkflowContextMenuItem[]): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.inboxitem.depersonalize",
            "wf-depersonalize", this.inboxActionService.depersonalizeInboxWorkflows, contextMenuItems);
    }

    private addMarkWfItemRead(contextMenuItems: WorkflowContextMenuItem[]): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.inboxitem.markread.title", "mark-read",
            this.inboxActionService.markWfItemRead, contextMenuItems);
    }

    private addMarkWfItemUnread(contextMenuItems: WorkflowContextMenuItem[]): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.inboxitem.markunread.title",
            "mark-unread", this.inboxActionService.markWfItemUnread, contextMenuItems);
    }

    private addRemoveFromWorkflowFiles(docModel: DmsDocumentModel): ContextMenuAction {
        return this.contextMenuUtilsService.addAction("eob.contextmenu.action.workflow.remove.from.files.title",
            "delete-dark", this.actionService.removeFromWorkflowFiles, docModel);
    }

    private addMoveToWorkInfoArea(context: string, docModel: DmsDocumentModel): ContextMenuAction {
        const moveTitle: string = context == ContextType.WFFILEAREAWORKFILES ? "eob.contextmenu.action.workflow.move.info.title" : "eob.contextmenu.action.workflow.move.work.title";
        const moveIcon: string = context == ContextType.WFFILEAREAWORKFILES ? (this.clientService.isPhoneOrTablet() ? "arrow-right" : "arrow-down") : (this.clientService.isPhoneOrTablet() ? "arrow-left" : "arrow-up");

        return this.contextMenuUtilsService.addAction(moveTitle, moveIcon, this.actionService.switchWorkflowFileArea, docModel);
    }

    private addWithoutTypeSubmenus(cabinetSubmenuActions: ContextMenuAction[], location: string): void {
        const withoutTypeSubmenus: ContextMenuAction[] = [];

        withoutTypeSubmenus.push(this.contextMenuUtilsService.addAction("document.type.greyscale", "OT-Gray-Image", this.actionService.editDocumentContent, {
            model: {
                tray: "workflow",
                location,
                mainType: 1,
                subType: 1
            }
        }));

        withoutTypeSubmenus.push(this.contextMenuUtilsService.addAction("document.type.blackwhite", "OT-SW-Image", this.actionService.editDocumentContent, {
            model: {
                tray: "workflow",
                location,
                mainType: 2,
                subType: 2
            }
        }));

        withoutTypeSubmenus.push(this.contextMenuUtilsService.addAction("document.type.color", "OT-Color-Image", this.actionService.editDocumentContent, {
            model: {
                tray: "workflow",
                location,
                mainType: 3,
                subType: 3
            }
        }));

        withoutTypeSubmenus.push(this.contextMenuUtilsService.addAction("document.type.windows", "OT-W-Doc", this.actionService.editDocumentContent, {
            model: {
                tray: "workflow",
                location,
                mainType: 4,
                subType: 4
            }
        }));

        cabinetSubmenuActions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.new.without.type", "OT-Typeless", withoutTypeSubmenus));
    }
}
