import {Component, Input, Output, EventEmitter, AfterViewInit, AfterContentInit, ViewChild, ElementRef} from "@angular/core";
import {NgForm, NgModel} from "@angular/forms";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ProfileManagerService} from "MODULES_PATH/profile-manager/profile-manager.service";
import {CapslockTracerService} from "MODULES_PATH/form/services/capslock-tracer.service";

interface Passwords {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

@Component({
    selector: "eob-change-password",
    templateUrl: "./eob-change-password.component.html",
    styleUrls: ["./eob-change-password.component.scss"]
})
export class EobChangePasswordComponent implements AfterViewInit, AfterContentInit {
    passwords: Passwords;

    @Input() usedDuringLogin = false;
    @Input() displayBackButton = false;
    @Input() statusErrorMessage = "";
    @Output() back = new EventEmitter();
    @Output() passwordChanged = new EventEmitter<string>();

    @ViewChild("passwordForm", {static: false}) passwordForm: NgForm;
    @ViewChild("currentPasswordInput", {static: false}) currentPasswordInput: NgModel;
    @ViewChild("submitButton", {static: false}) submitButton: ElementRef<HTMLButtonElement>;

    constructor(
        public httpService: HttpService,
        public profileManagerService: ProfileManagerService,
        private capslockTracerService: CapslockTracerService) {
        this.passwords = {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: ""
        };
    }

    ngAfterContentInit(): void {
        this.passwords.currentPassword = this.profileManagerService.profile?.password;
    }

    ngAfterViewInit(): void {
        if(this.passwords.currentPassword) {
            // Call has to be wrapped inside setTimeout, as form control initialization is deferred internally
            setTimeout(() => this.passwordForm.control.get("current-password").markAsDirty());
        }
    }

    submitPasswordChangeForm(): void {
        this.submitButton.nativeElement.disabled = true;
        const authHeader: {Authorization?: string} = {};
        if(this.profileManagerService.profile) {
            authHeader.Authorization = `Basic ${btoa(`${this.profileManagerService.profile.username}:${this.passwords.currentPassword}`)}`;
        }
        this.httpService.changePassword(this.passwords.currentPassword, this.passwords.newPassword, this.profileManagerService.profile?.url, authHeader).subscribe(_ => {
            if(this.usedDuringLogin) {
                this.httpService.invalidateGatewaySession(this.profileManagerService.profile.url).subscribe(_ => {
                    this.passwordChanged.emit(this.passwords.newPassword);
                });
            } else {
                this.passwordChanged.emit(this.passwords.newPassword);
            }
        }, error => {
            console.info(error);

            if (error instanceof HttpErrorResponse && (error.status == 401 || error.status == 403)) {
                this.passwordForm.control.get("current-password").setErrors({incorrectPassword: true});
            }
            this.submitButton.nativeElement.disabled = false;
        }, () => {
            this.submitButton.nativeElement.disabled = false;
        });
    }

    onInputFocus(focussed: boolean): void {
        if(focussed) {
            this.capslockTracerService.showCapsLockWarning.next(true);
        } else {
            this.capslockTracerService.showCapsLockWarning.next(false);
            this.capslockTracerService.capsLockOn.next(false);
        }
    }
}
