import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {Inject, Injectable} from "@angular/core";
import {
    ContextMenuAction,
    ContextMenuActionsProvider,
    ContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {TodoModalDialogService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {MultiMenuService} from "MODULES_PATH/context-menu/services/multi-menu.service";
import {defer, Observable, of} from "rxjs";

@Injectable({
    providedIn: ContextMenuModule
})
export class ProcessesContextMenuService implements ContextMenuActionsProvider {
    private contextMenuActions: ContextMenuAction[] = [];

    constructor(private contextMenuUtilsService: ContextMenuUtilsService,
                private clientService: ClientService, private multiMenuService: MultiMenuService,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager
    ) {
    }

    getSingleSelectionContextMenuActions(contextMenuItem: ContextMenuItem, contextData: ContextData): Observable<ContextMenuAction[]> {
        this.contextMenuActions = [];

        if (this.clientService.isOnline()) {
            this.addGoToWorkflowInfo(contextMenuItem);
            this.addOpenAssignPerformersDialog(contextMenuItem);
        }

        return of(this.contextMenuActions);
    }

    getMultiselectionContextMenuActions(contextMenuItems: ContextMenuItem[], contextData: ContextData): Observable<ContextMenuAction[]> {
        return of([]);
    }

    private addGoToWorkflowInfo(contextMenuItem: ContextMenuItem): void {
        if (!this.clientService.isPhone()) {
            this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.workflow.details.open",
                "wf-edit", this.stateHistoryManager.goToWorkflowInfo, contextMenuItem));
        }
    }

    private addOpenAssignPerformersDialog(contextMenuItem: ContextMenuItem): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.workflow.details.assign.performers",
            "workflow-is-personalized", this.modalDialogService.openAssignPerformersDialogFromHitlist, contextMenuItem));
    }
}