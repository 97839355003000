import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {Inject, Injectable} from "@angular/core";
import {
    ContextMenuAction,
    ContextMenuActionsProvider
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ContextData, Location} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {
    TodoCacheManagerService,
    TodoEnvironmentService,
    TodoExternalTrayService,
    TodoStateHistoryManager
} from "INTERFACES_PATH/any.types";
import {Observable, of} from "rxjs";
import {ExternalTrayFileMetadata} from "MODULES_PATH/external-tray/interfaces/external-tray-file-metadata.interfaces";

@Injectable({
    providedIn: ContextMenuModule
})
export class ExternalTrayContextMenuService implements ContextMenuActionsProvider {
    private contextMenuActions: ContextMenuAction[] = [];

    constructor(private contextMenuUtilsService: ContextMenuUtilsService,
                private clientService: ClientService,
                @Inject("$location") private $location: ng.ILocationService,
                @Inject("externalTrayService") private externalTrayService: TodoExternalTrayService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
    ) {
    }

    getSingleSelectionContextMenuActions(externalTrayFileMetadata: ExternalTrayFileMetadata, contextData: ContextData): Observable<ContextMenuAction[]> {
        this.contextMenuActions = [];

        if (externalTrayFileMetadata.fileNames.length === 1) {
            this.addOpenTrayElement(externalTrayFileMetadata);
        }

        this.addRenameTrayElement(externalTrayFileMetadata);

        if (this.clientService.isOnline()) {
            if (this.stateHistoryManager.getCurrentStateData().data.type == "folder") {
                this.addInsertTrayItemAtLocation(externalTrayFileMetadata);
            }

            if (externalTrayFileMetadata.trayItemType == "insertVariant") {
                this.addInsertVariantFromTray(externalTrayFileMetadata);
            }

            if (!externalTrayFileMetadata.mainTypes.includes("6")) {
                this.addCheckInTrayItem(externalTrayFileMetadata);
            } else if (this.clientService.isDesktop() && this.environmentService.featureSet.contains("ems")) {
                this.addCheckInAsEmail(externalTrayFileMetadata);
            }
        }

        this.addRemoveTrayElement(externalTrayFileMetadata);

        return of(this.contextMenuActions);
    }

    getMultiselectionContextMenuActions(externalTrayFileMetadata: ExternalTrayFileMetadata[], contextData: ContextData): Observable<ContextMenuAction[]> {
        return;
    }

    private addOpenTrayElement(externalTrayFileMetadata: ExternalTrayFileMetadata): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.external.tray.open.title",
            "edit-content", this.externalTrayService.openTrayElementAsync, externalTrayFileMetadata));
    }

    private addRenameTrayElement(externalTrayFileMetadata: ExternalTrayFileMetadata): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.external.tray.rename.title",
            "datenblatt-bearbeiten", this.externalTrayService.renameTrayElementAsync, externalTrayFileMetadata));
    }

    private addInsertVariantFromTray(externalTrayFileMetadata: ExternalTrayFileMetadata): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.external.tray.insert.as.variant",
            "variant", this.externalTrayService.insertVariantFromTrayAsync, externalTrayFileMetadata, false));
    }

    private addRemoveTrayElement(externalTrayFileMetadata: ExternalTrayFileMetadata): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.external.tray.remove.title",
            "delete-dark", this.externalTrayService.removeTrayElementAsync, externalTrayFileMetadata));
    }

    private addInsertTrayItemAtLocation(externalTrayFileMetadata: ExternalTrayFileMetadata): void {
        const location: Location = {
            osid: this.$location.search().currentId,
            objectTypeId: this.$location.search().currentTypeId
        };

        if (!externalTrayFileMetadata.mainTypes.includes("6")) {
            this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.external.tray.insert.in.location",
                "insert-in-location", this.externalTrayService.insertTrayItemAtLocationAsync, externalTrayFileMetadata, location, false));
        } else if (this.clientService.isDesktop() && this.environmentService.featureSet.contains("ems")) {
            const parentTypeConfig: ObjectTypeConfig = this.cacheManagerService.objectTypes.getById(location.objectTypeId).model.config;
            const parentInsertableTypes: string[] = parentTypeConfig.insertableTypes;
            const matchedIds: string[] = this.environmentService.getMatchedEmsTypeObjectIds(parentInsertableTypes);

            if (matchedIds.length > 0) {
                location.matchedIds = matchedIds;
                this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.external.tray.insert.in.current.location",
                    "insert-in-location", this.externalTrayService.insertTrayItemAtLocationAsync, externalTrayFileMetadata, location, false));
            }
        }
    }

    private addCheckInTrayItem(externalTrayFileMetadata: ExternalTrayFileMetadata): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.external.tray.checkin.as.document", "lock",
            this.externalTrayService.checkInTrayItemAsync, externalTrayFileMetadata, null, true));
    }

    private addCheckInAsEmail(externalTrayFileMetadata: ExternalTrayFileMetadata): void {
        const location: Location = {
            osid: externalTrayFileMetadata.parentObjectId,
            objectTypeId: externalTrayFileMetadata.parentObjectTypeId.toString()
        };

        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.external.tray.checkin.as.email", "lock",
            this.externalTrayService.insertTrayItemAtLocationAsync, externalTrayFileMetadata, location, true));
    }
}