import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {takeWhile} from "rxjs/operators";

(function () {


    angular.module("eob.framework").directive("eobModalPromt", EobModalPromt);

    EobModalPromt.$inject = ["viewService", "notificationsService", "$filter", "clientService", "messageService"];

    function EobModalPromt(ViewService, NotificationsService, $filter, ClientService, MessageService) {
        return {
            restrict: "E",
            scope: {
                confirmObject: "=",
                buttons: "=",
                destroy$: "=destroy"
            },
            link (scope, element, attrs) {

                // notification callback function
                // a deferred is not enough at this point because once it is rejected if
                // the title is already taken
                let notifyCallback = function (done, message) {
                    if (done) {
                        scope.close();
                    } else {
                        NotificationsService.error(message)
                    }
                };

                scope.confirmation = {
                    notifyCallback,
                    finalize: scope.confirmObject.finalize,
                    currentItem: scope.confirmObject.currentItem,
                    message: ""
                };

                if (!scope.buttons) {
                    scope.buttons = {};
                }

                if (!scope.buttons.submit) {
                    scope.buttons.submit = $filter("translate")("modal.button.submit", true);
                }

                if (!scope.buttons.cancel) {
                    scope.buttons.cancel = $filter("translate")("modal.button.cancel", true);
                }

                ViewService.autoFocusElement(element, "input[type=text]");

                if (scope.confirmObject.suggestion) {
                    scope.confirmation.message = scope.confirmObject.suggestion
                }

                scope.confirm = function () {
                    if (ClientService.isOffline() && !scope.confirmObject.allowOfflineSubmission) {
                        NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                        return;
                    }
                    if (scope.confirmation.message) {
                        scope.confirmObject.callback(scope.confirmation);
                    } else {
                        NotificationsService.error($filter("translate")("modal.prompt.required.message"));
                    }
                };

                scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

                scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => {
                    if (scope.confirmObject.abort != void 0) {
                        scope.confirmObject.abort();
                    }
               });
            },
            template: require("!raw-loader!./eob.modal.promt.html")
        };
    }
})();
