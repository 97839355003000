<form #passwordForm="ngForm" eobNewPasswordValidator (ngSubmit)="submitPasswordChangeForm()">

    <h2 class="state-title">{{ "eob.app.bar.usermenu.change.password" | ngTranslate }}</h2>

    <div [hidden]="!usedDuringLogin" class="password-expired-message hintbox">
        <span [hidden]="passwordForm.touched && (passwordForm.submitted || passwordForm.errors)">{{'modal.password.expired' | ngTranslate}}</span>
        <span [hidden]="!passwordForm.errors?.passwordsDoNotMatch">{{"eob.add.user.password.missmatch.message" | ngTranslate}}</span>
        <span [hidden]="!passwordForm.errors?.passwordsIdentical">{{"eob.password.old.new.same" | ngTranslate}}</span>
        <span [hidden]="!currentPasswordInput.invalid">{{"eob.password.incorrect.error" | ngTranslate}}</span>
    </div>

    <div class="form-row-container">
        <div class="form-row">
            <legend>{{'modal.password.old.password' | ngTranslate}}</legend>
            <input #currentPasswordInput="ngModel" type="password" autocomplete="off" autocapitalize="none"
                   autocorrect="off" id="currentPassword" name="current-password" required
                   [(ngModel)]="passwords.currentPassword" [value]="passwords.currentPassword" (focus)="onInputFocus(true)" (blur)="onInputFocus(false)"/>
        </div>
        <div class="form-row">
            <legend>{{'modal.password.new.password' | ngTranslate}}</legend>
            <input #newPasswordInput="ngModel" type="password" autocomplete="off" autocapitalize="none"
                   autocorrect="off" id="newPassword" name="new-password" required
                   [(ngModel)]="passwords.newPassword" (focus)="onInputFocus(true)" (blur)="onInputFocus(false)"/>
        </div>
        <div class="form-row">
            <legend>{{'modal.password.repeat.password' | ngTranslate}}</legend>
            <input #newPasswordConfirmationInput="ngModel" type="password" autocomplete="off"
                   autocapitalize="none"
                   autocorrect="off" id="newPasswordConfirmation" name="new-password-confirmation" required
                   [(ngModel)]="passwords.newPasswordConfirmation" (focus)="onInputFocus(true)" (blur)="onInputFocus(false)"/>
        </div>
    </div>
    <eob-capslock-tracer></eob-capslock-tracer>
    <div class="login-button-container">
        <button class="secondary-button" type="button"
                (click)="back.emit()">{{'modal.button.back' | ngTranslate}}</button>
        <button class="primary" #submitButton type="submit"
                [disabled]="!passwordForm.form.valid">{{"eob.login.login.and.change.password" | ngTranslate}}</button>
        <!--                <span [hidden]="!passwordForm.submitted || passwordForm.valid" class="error-bubble">nope</span>-->
    </div>
</form>
