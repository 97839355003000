import * as angular from "angular";
import {Injectable, Inject} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {FormCatalogEntry} from "MODULES_PATH/form/interfaces/form.interface";
import {AutoComplete, AutoCompleteConfig} from "MODULES_PATH/autocomplete/interfaces/autocomplete.interface";
import {FieldAddon} from "ENUMS_PATH/field.enum";
import {Tree} from "INTERFACES_PATH/tree.interface";
import {UserAddonService} from "MODULES_PATH/form/services/form-builder/user-addon.service";
import {OrgAddonField, WfOrgAddonField} from "INTERFACES_PATH/addon.field.interface";
import {RightGroupsAddonService} from "MODULES_PATH/form/services/form-builder/right-groups-addon.service";
import {WfOrgAddonService} from "MODULES_PATH/form/services/form-builder/wf-org-addon.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Subscription} from "rxjs";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {TodoFormHelper} from "INTERFACES_PATH/any.types";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {Cell} from "MODULES_PATH/grid/interfaces/grid-cell-component.interface";
interface SearchKeyIndexes {
    start: number;
    next: number;
}

@Injectable({providedIn: "root"})
export class AutoCompleteService {
    listClickCallback: any;
    listElements: HTMLElement[] = [];

    private readonly subscription: Subscription = new Subscription();
    private keypressCounter = 0;
    private listInternal: FormCatalogEntry[] = [];

    constructor(protected userAddonService: UserAddonService, protected wfOrgAddonService: WfOrgAddonService,
                protected rightGroupsAddonService: RightGroupsAddonService, private messageService: MessageService,
                @Inject("dbCatalogService") protected dbCatalogService: any,
                @Inject("treeAddonService") protected treeAddonService: any) {
        this.subscription.add(this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, () => {
            this.keypressCounter++;
        }));
    }

    addAutoComplete(field: Field, formHelper: TodoFormHelper): void {
        const autoCompleteConfig: AutoCompleteConfig = {
            debounce: 0,
            minCharacters: 1,
            useMultiSelect: false,
            isField: field != void 0,
            hasAutoComplete: false,
            isGridCell: field.model.isGridCell,
            isDisabled: field.isDisabled,
            addon: field.model.addon,
            autoCompleteOpen: field.model.autoCompleteOpen,
            isSelectingValue: false,
            getItems: autoCompleteCallback.bind(this)
        };

        if ((field.model.tree != void 0 && field.model.tree.config.useMultiSelect == true) ||
            (field.model.addon == "user" && field.model.config.useMultiSelect == true) ||
            (field.model.addon == "organisation") || (field.model.addon == "rightGroups") ||
            (field.model.addon == "rightGroupsOld")) {
            autoCompleteConfig.useMultiSelect = true;
        }

        field.model.autoCompleteConfig = autoCompleteConfig;
        this.messageService.broadcast(FormEvent.UPDATE_AUTOCOMPLETE_CONFIG);

        function autoCompleteCallback(searchKey) {
            return new Promise(((resolve, reject) => {
                this.getAutoCompleteResultForAddon(searchKey, field, formHelper, (entries) => {
                    if (entries == void 0) {
                        resolve([]);
                    } else {
                        resolve(entries);
                    }
                });
            }));
        }
    }

    async getAutoCompleteResultForAddon(searchKey: string, field: Field, formHelper: TodoFormHelper, dataCallback: any): Promise<void> {
        searchKey = searchKey.toLowerCase();

        this.keypressCounter++;

        let hasShortValues: boolean = false;

        const isSearch: boolean = formHelper.isSearch;

        if (field.model.tree != void 0 && field.model.tree.config.shortValue == true || [FieldAddon.USER, FieldAddon.RIGHTGROUPS, FieldAddon.RIGHTGROUPSOLD].includes(field.model.addon)) {
            hasShortValues = true;
        }

        if (field.model.addon == FieldAddon.DB) {
            const result = await this.dbCatalogService.getDbEntries(field, formHelper, searchKey);
            dataCallback(result);
        } else {
            const currentCount: number = this.keypressCounter;

            this.getListEntries(field, searchKey, isSearch, (listEntries) => {
                if (currentCount < this.keypressCounter) {
                    dataCallback([]);

                    return;
                }
                // this checks if the async callback delivered data with short values
                // if (field.model.hasPseudoCatalog) {
                hasShortValues = this.treeAddonService.hasShortValues(listEntries);

                this.treeAddonService.setDefaultShortValues(listEntries);
                this.treeAddonService.createParentNodes(listEntries);
                // }

                let result: FormCatalogEntry[] = this.getFilteredResult(field, listEntries, searchKey, hasShortValues, isSearch);

                result = this.sortResult(result, hasShortValues);
                result = this.removeDoubles(result);

                dataCallback(result);
            });
        }
    }

    sortResult(result: FormCatalogEntry[], hasShort: boolean): FormCatalogEntry[] {
        return result.sort((a, b) => {
            const x: string = hasShort ? a.short.toLowerCase() : a.value.toLowerCase();
            const y: string = hasShort ? b.short.toLowerCase() : b.value.toLowerCase();

            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    getSearchKey(element: HTMLInputElement, useMultiSelect: boolean): string {
        let value: string = element.value;

        if (useMultiSelect) {
            const indexes: SearchKeyIndexes = this.getSearchkeyIndexes(value, element);

            if (indexes.start == -1 && indexes.next == -1) {
                return value;
            } else {
                indexes.next = indexes.next < 0 ? 10000 : indexes.next;
                value = value.substr(indexes.start + 1, indexes.next - indexes.start - 1);
            }
        }

        return value;
    }

    getNewInputValue(config: AutoComplete): string {
        let viewValueShort: string = this.listInternal[config.currentIndex].short;
        let viewValueDefault: string = this.listInternal[config.currentIndex].value;
        let value: string;

        if (config.autoCompleteConfig.useMultiSelect) {
            value = config.element.value.toString();

            const insertIndexes: SearchKeyIndexes = this.getSearchkeyIndexes(value, config.element);
            const firstStringPart: string = value.slice(0, insertIndexes.start + 1);
            const lastStringPart: string = insertIndexes.next < 0 ? "" : value.slice(insertIndexes.next);

            viewValueShort = [firstStringPart, this.listInternal[config.currentIndex].short, lastStringPart].join("");
            viewValueDefault = [firstStringPart, this.listInternal[config.currentIndex].value, lastStringPart].join("");
        }

        return this.listInternal[config.currentIndex].short != "" ? viewValueShort : viewValueDefault;
    }

    setClickCallback(clickCallback: any): void {
        this.listClickCallback = clickCallback;
    }

    setListInternal(listInternal: FormCatalogEntry[]): void {
        this.listInternal = listInternal;
    }

    setListElements(listElements: HTMLElement[]): void {
        this.listElements = listElements;
    }

    getSearchkeyIndexes(searchKey: string, element: HTMLInputElement): SearchKeyIndexes {
        const searchOperator: string = searchKey.lastIndexOf("+") != -1 ? "+" : ";";
        const selectionStart: number = element.selectionStart;
        const startIndex: number = searchKey.lastIndexOf(searchOperator, selectionStart - 1);
        const nextIndex: number = searchKey.indexOf(searchOperator, selectionStart - 1);

        return {
            start: startIndex,
            next: nextIndex
        };
    }

    private buildHierarchyChildValues(listEntry: FormCatalogEntry, value: string, hasShortValues: boolean, separator: string, isSearch: boolean): string {
        if (listEntry.parent != void 0) {
            const parent: FormCatalogEntry = listEntry.parent;

            value = hasShortValues ? parent.short + separator + value : parent.value + separator + value;
            value = this.buildHierarchyChildValues(parent, value, hasShortValues, separator, isSearch);
        }
        return value;
    }

    private buildHierarchyParentValues(listEntry: FormCatalogEntry, hierarchyChildValues: FormCatalogEntry[], value: string, hasShortValues: boolean, separator: string, isSearch: boolean): void {
        if (listEntry.nodes != void 0) {
            for (const node of listEntry.nodes) {
                let pathValue = "";

                if (listEntry.parent != void 0) {
                    pathValue = this.buildHierarchyChildValues(listEntry, value, hasShortValues, separator, isSearch);
                } else {
                    pathValue = hasShortValues ? listEntry.short : listEntry.value;
                }

                const newValue: string = hasShortValues ? pathValue + separator + node.short : pathValue + separator + node.value;
                const newEntry: FormCatalogEntry = this.createEntry(node, newValue, hasShortValues, true);

                if (isSearch || node.nodes == void 0) {
                    hierarchyChildValues.push(newEntry);
                }

                this.getNodePaths(node, newEntry, hierarchyChildValues, hasShortValues, separator, isSearch);
            }
        }
    }

    private getNodePaths(node: FormCatalogEntry, entry: FormCatalogEntry, hierarchyChildValues: FormCatalogEntry[], hasShortValues: boolean, separator: string, isSearch: boolean): void {
        if (node.nodes != void 0) {
            for (const subNode of node.nodes) {
                const newValue: string = hasShortValues ? entry.short + separator + subNode.short : entry.value + separator + subNode.value;
                const newEntry: FormCatalogEntry = this.createEntry(subNode, newValue, hasShortValues, true);

                if (isSearch || subNode.nodes == void 0) {
                    hierarchyChildValues.push(newEntry);
                }

                this.getNodePaths(subNode, newEntry, hierarchyChildValues, hasShortValues, separator, isSearch);
            }
        }
    }

    private addNodesToList(node: FormCatalogEntry, listEntries: FormCatalogEntry[]): void {
        if (node.nodes) {
            for (const newNode of node.nodes) {
                listEntries.push(newNode);
                this.addNodesToList(newNode, listEntries);
            }
        }
    }

    private createEntry(node: FormCatalogEntry, value: string, hasShortValues: boolean, isHierarchy: boolean): FormCatalogEntry {
        const entry: FormCatalogEntry = {
            value: "",
            short: ""
        };

        if (isHierarchy && hasShortValues) {
            entry.short = value;
            entry.value = node.value;
        } else if (hasShortValues) {
            entry.value = value;
            entry.short = node.short;
        } else {
            entry.value = value;
        }
        return entry;
    }

    private getListEntries(field: Field, searchKey: string, isSearch: boolean, callback: any): void {
        let listEntries: FormCatalogEntry[] = [];
        const addonType: FieldAddon = field.model.addon;

        switch (addonType) {
            case FieldAddon.USER:
                listEntries = this.userAddonService.buildInlineTreeUserList(field as OrgAddonField);
                callback(angular.copy(listEntries));
                break;
            case FieldAddon.ORGANIZATION:
                for (const orgMember of field.model.config.orgMember) {
                    listEntries.push({
                        value: orgMember.displayName,
                        short: orgMember.name
                    });
                }
                callback(angular.copy(listEntries));
                break;
            case FieldAddon.RIGHTGROUPS:
            case FieldAddon.RIGHTGROUPSOLD:
                const members: any = this.rightGroupsAddonService.getUserAndGroups(field.model.config);
                for (const member of members) {
                    const memberType: string = member.type == "group" ? "(G)" : "(U)";
                    const memberEntry: FormCatalogEntry = {
                        value: member.userGroupMixInfo,
                        short: member.name as string + memberType
                    };
                    listEntries.push(memberEntry);
                }
                callback(angular.copy(listEntries));
                break;
            default:
                const adjustListEntries = (nodes: FormCatalogEntry[]) => {
                    const unmutedNodes: FormCatalogEntry[] = JSON.parse(JSON.stringify(nodes));
                    this.treeAddonService.removeDeprecatedStarEntries(unmutedNodes, isSearch, field.model.tree?.config?.intermediate);
                    callback(unmutedNodes);
                };

                if (field.model.hasPseudoCatalog) {
                    // reorder the arguments for each case
                    // the callback for a gridcell gets [cell , row , callback] and the field callback [field , callback]
                    let args: any = [];

                    if (field.model.isGridCell) {
                        const row: string[] = [...field.model.cell.rowNode.data];
                        const cell: Cell = { colIndex: field.model.cell.colIndex, rowIndex: field.model.cell.rowIndex };

                        row[field.model.cell.colIndex] = searchKey;

                        args = [cell, row, adjustListEntries];
                    } else {
                        args = [field, searchKey, adjustListEntries];
                    }

                    field.model.pseudoCatalogDataCallback.apply(this, args);
                } else if (addonType != void 0 && addonType != FieldAddon.QUICKFINDER && addonType != FieldAddon.DATETIME) {
                    listEntries = field.api.getListEntries();
                    adjustListEntries(listEntries);
                }
                break;
        }
    }

    private getFilteredResult(field: Field, listEntries: FormCatalogEntry[], searchKey: string, hasShortValues: boolean, isSearch: boolean): FormCatalogEntry[] {
        const isTree: boolean = field.model.addon == FieldAddon.TREE;
        const isHierarchy: boolean = field.model.addon == FieldAddon.HIERARCHY;
        const tree: Tree = field.model.tree;
        const tmpList: FormCatalogEntry[] = [];
        const result: FormCatalogEntry[] = [];
        const hierarchyChildValues: FormCatalogEntry[] = [];

        let separator = "|";

        if (tree != void 0 && tree.config.separator != void 0) {
            separator = tree.config.separator;
        }

        // create a flat list to search in for tree and hierarchy addon
        if (isTree || isHierarchy) {
            for (const listEntry of listEntries) {
                this.addNodesToList(listEntry, listEntries);
            }
        }

        // create a temporary list with matching items
        for (const listEntry of listEntries) {
            if ((listEntry.value.toLowerCase().indexOf(searchKey) == 0) || (listEntry.short.toLowerCase().indexOf(searchKey) == 0)) {
                tmpList.push(listEntry);
            }
        }

        for (const tmpEntry of tmpList) {
            let value: string = tmpEntry.value;
            let short: string = tmpEntry.short;
            let entry: FormCatalogEntry;

            // if hierarchy build the path name for each hierarchy item
            if (isHierarchy) {
                if (hasShortValues) {
                    this.buildHierarchyParentValues(tmpEntry, hierarchyChildValues, short, hasShortValues, separator, isSearch);
                } else {
                    this.buildHierarchyParentValues(tmpEntry, hierarchyChildValues, value, hasShortValues, separator, isSearch);
                }

                if (!isSearch && tmpEntry.parent == void 0 && tmpEntry.nodes != void 0) {
                    continue;
                }

                if (hasShortValues) {
                    short = this.buildHierarchyChildValues(tmpEntry, short, hasShortValues, separator, isSearch);
                } else {
                    value = this.buildHierarchyChildValues(tmpEntry, value, hasShortValues, separator, isSearch);
                }
            }

            const isEntryAllowed = !(tmpEntry.nodes != void 0 && (isTree || (isHierarchy && !isSearch && !field.model.tree.config.intermediate)));

            if (hasShortValues && isHierarchy) {
                entry = this.createEntry(tmpEntry, short, hasShortValues, true);
            } else {
                entry = this.createEntry(tmpEntry, value, hasShortValues, false);
            }

            if (isEntryAllowed && (entry.short != "" || entry.value != "")) {
                result.push(entry);
            }
        }

        return result.concat(hierarchyChildValues);
    }

    private removeDoubles(result: FormCatalogEntry[]): FormCatalogEntry[] {
        const entriesToRemove: number[] = [];

        let counter = 0;

        for (let i = 1; i < result.length; i++) {
            if (result[i].value == result[i - 1].value && result[i].short == result[i - 1].short) {
                entriesToRemove.push(i);
            }
        }

        for (const index of entriesToRemove) {
            result.splice(index - counter, 1);
            counter++;
        }
        return result;
    }
}
