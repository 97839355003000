<button id="eob-kebab-menu" role="img" class="icon-container state-header-button button-transparent" (click)="toggleMenu()"
      title="{{'eob.state.menu.title' | ngTranslate}}" [attr.arial-label]="'eob.state.menu.title' | ngTranslate">
    <div class="kebab-icon" [ngClass]="{'open': dropdownOpen}">
        <!--  Each span is one dot in the kebab menu icon. Do not remove! -->
        <span></span>
        <span></span>
        <span></span>
    </div>
</button>

<ul id="eob-kebab-menu-dropdown"
    [ngClass]="{'open':dropdownOpen == true, 'close': dropdownOpen == false,'hidden':dropdownOpen == null}">
    <li *ngFor="let item of (stateMenuItems$ | async)" (click)="executeAction(item, $event)"
        [attr.hasSubmenu]="item.context == 'emptySpaceInHitlist'">
        <!--usual kebab menu items-->
        <button *ngIf="item.context !== 'emptySpaceInHitlist'" (mouseenter)="hideContextMenuOnMouseEnter()" class="button-transparent">
            <eob-icon [name]="item.icon" [title]="item.alt" size="16"></eob-icon>
            <span>{{item.title}}</span>
        </button>

        <!--kebab menu items in folder state to create new/insert documents-->
        <button *ngIf="item.context == 'emptySpaceInHitlist'" (mouseenter)="executeAction(item, $event)" class="button-transparent">
            <eob-icon [name]="item.icon" [title]="item.alt" size="16"></eob-icon>
            <span>{{item.title}}</span>
            <eob-icon name="caret" size="16" title=""></eob-icon>
        </button>
    </li>
</ul>
