import {enableProdMode, NgZone} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {setAngularJSGlobal} from "@angular/upgrade/static";
import * as angular from "angular";
import * as dayjs from "dayjs";
import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";
import {UrlService} from "@uirouter/core";

// import "babel-polyfill"
if (environment.production) {
    enableProdMode();
}

setAngularJSGlobal(angular);
// eslint-disable-next-line @typescript-eslint/dot-notation
window["dayjs"] = dayjs;

const onDeviceReady: () => void = () => {
    // Manually bootstrap the Angular app

    // eslint-disable-next-line promise/prefer-await-to-then
    platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
            // get() UrlService from DI (this call will create all the UIRouter services)
            const urlService: UrlService = platformRef.injector.get(UrlService);

            // Instruct UIRouter to listen to URL changes
            function startUIRouter(): void {
                urlService.listen();
                urlService.sync();
            }

            platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
        }
        // eslint-disable-next-line promise/prefer-await-to-then
    ).catch(
        // eslint-disable-next-line promise/prefer-await-to-callbacks
        err => console.error(err)
    );
};

if (environment.mobile) {
    require("./app/modules/cordova/cordova.launch.ts");
    document.addEventListener("deviceready", onDeviceReady, false);
} else {
    onDeviceReady();
}
