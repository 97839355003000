(function () {
    'use strict';

    /**
     * Applications main registry for external plugins and modules.
     *
     * Each plugin is stored under a unique identifier which will be used to address the plugin from the
     * PluginContainer component (eoPluginContainer).
     *
     * Required fields are:
     *  - name: a display name for the Plugin
     *  - directive: the directive tag to be rendered
     *  - files: the files to be lazy loaded by the PluginContainer (including the plugin directive file)
     *
     * Optional fields are:
     *  - description: a short description of the plugin
     *  - serie: set to true, if the order in which the provided files are loaded matters
     *
     */
    angular.module('eob.client').constant('$cfgPlugins',
        {}
    );
})();