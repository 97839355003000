<!--
                  *ngFor = "let layoutField of layoutFields; trackBy:identify"
-> doesn't compile:
error TS2339: Property 'identify' does not exist on type 'EobResponsiveFormComponent'.
2                   *ngFor = "let layoutField of layoutFields; trackBy:identify"

-> identify method missing?!
-->
<eob-form-element class = "form-element"
                  *ngFor = "let layoutField of layoutFields"
                  [layoutfield] = "layoutField"
                  [formdata] = "formdata"
                  [formhelper] = "formhelper"
                  [layout] = "layout"
                  [formid] = "formid"
                  [ngClass] = "layoutField.type"
                  [ismockform] = "ismockform">
</eob-form-element>