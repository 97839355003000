export type Stringbool = "false" | "true";
export type Numberbool = "0" | "1";
export interface StaticIconColumns {
    dmsIcon: boolean;
    archiveState: boolean;
    lockState: boolean;
    links: boolean;
    signature: boolean;
    objectType: boolean;
    mimeType: boolean;
    favorite: boolean;
    notes: boolean;
    annotations: boolean;
}

export type Language = "de" | "en" | "fr";

export interface LayoutConfiguration {
    isNavigationFixed?: Stringbool;
    colorTheme?: string;
    accentColor?: string;
    activeCabinet?: string | number;
    activeNavPane?: string;
    navigationWidth?: string;
    expanded?: boolean;
}

export interface AsIniFieldConfig {
    prefix: string;
    objTypeFields: {
        [k: string]: string[];
    };
}

export interface AsIniFieldConfigs {
    hitlist: AsIniFieldConfig;
    docBaseParams:AsIniFieldConfig;
    folderBaseParams:AsIniFieldConfig;
    registerBaseParams: AsIniFieldConfig;
    sendTitle:AsIniFieldConfig;
    windowTitle: AsIniFieldConfig;
}

export interface AsIniFieldConfig {
    prefix: string;
    objTypeFields: {
        [k: string]: string[];
    };
}

export interface AsIniHitlistConfiguration {
    id?: string;
    isEmpty?: boolean;
    sort?: {
        [k: string]: string;
    };
    type?: string;
    groups?: {
        [k: string]: string;
    };
    expandedGroups?: {
        [k: string]: string;
    };
    width?: {
        [k: string]: number;
    };
    subType?: string;
    locationTreeWidth?: number;
}

export type SortOrder = "ASC" | "DESC" | "UNDEFINED";

export enum IniSetSettings {
    SORT_ASC = "SET004",
    SORT_DESC = "SET011",
    SHOW_REGISTER_TREE = "SET002"
}

export interface AsIniDashboardSearchConfiguration {
    expanded?: string;
    selected?: string;
}

export interface KeyValueSetting<T> {
    [p: string]: T;
}

export interface AsIni {
    [k: string]: any;

    EBWCLAYOUTCONFIGURATION?: LayoutConfiguration;
    EBWCQUICKSEARCHSTATE?: { expanded?: string };
    EBWCDASHBOARDSEARCHCONFIGURATION?: AsIniDashboardSearchConfiguration;
    CLIENT?: {
        [k: string]: any;
        LANGUAGE?: Language;
        TREEDIVCHAR?: string;
    };
    EBWCOFFLINE?: {
        ALWAYSSYNCHFAVORITES?: Numberbool;
        SYNCHORIGINALCONTENT?: Numberbool;
        USEMOBILEDATAFORSYNCH?: Numberbool;
    };
    OPENEDWORKITEMS?: {
        [k: string]: string;
    };
    REQUESTMODE?: {
        [k: string]: Numberbool;
        USESAMEFOLDERWINDOW?: Numberbool;
        WITHNOREGISTER?: Numberbool;
    };
    STATICLISTCOLS?: {
        COL0?: Numberbool;
        COL1?: Numberbool;
        COL2?: Numberbool;
        COL3?: Numberbool;
        COL4?: Numberbool;
        COL5?: Numberbool;
        COL6?: Numberbool;
        COL7?: Numberbool;
        COL8?: Numberbool;
        COL9?: Numberbool;
    };
    EBWCDESKTOPFOLDERSTATE?: KeyValueSetting<Stringbool>;
    EBWCHITLISTCONFIG?: {
        [k: string]: string;
    };
    LASTSORTDIR?: {
        [k: string]: Numberbool;
    };
    LASTSORTCOL?: {
        [k: string]: string;
    };
    LASTWDOCSEL?: {
        [k: string]: string;
    };
    OPTIONEN?: {
        Autostern: string;
    };
    "WORKFLOW@INBOXCOLUMNS"?: {
        [k: string]: Numberbool;
    };
}

export enum StaticIconColumnMapping {
    COL0 = "dmsIcon",
    COL1 = "archiveState",
    COL2 = "lockState",
    COL3 = "links",
    COL4 = "signature",
    COL5 = "objectType",
    COL6 = "mimeType",
    COL7 = "favorite",
    COL8 = "notes",
    COL9 = "annotations",
}

export enum IniKeys {
    LAYOUT_CONFIGURATION = "EBWCLAYOUTCONFIGURATION",
    PREFIX_CABINET_SECTION = "SETS#",
    PREFIX_HITLIST_FIELDS_SECTION = "FIELDS#",
    PREFIX_DOCUMENT_BASEPARAMS_SECTION = "FIELDS#0064",
    PREFIX_FOLDER_BASEPARAMS_SECTION = "FIELDS#0066",
    PREFIX_REGISTER_BASEPARAMS_SECTION = "FIELDS#0067",
    PREFIX_SEND_TITLE_SECTION = "EMAILFIELDS#",
    PREFIX_WINDOW_TITEL_SECTION = "WINDOWTITELFIELDS#",
    HITLIST_CONFIG_SECTION = "EBWCHITLISTCONFIG",
    SETTINGS_TIMESTAMP = "BACKUPTIMESTAMP"

}

export interface AsIniTemplateConfiguration {
    sortDirection?: "asc" | "desc";
    sortColumn?: string;
    selectedEntry?: string;
}

export interface AsIniWfInboxStaticColumns {
    "WFITEMNAME": boolean;
    "WFSUBJECT": boolean;
    "WFCLOSURE": boolean;
    "WFCREATION": boolean;
    "WFNAME": boolean;
    "WFPERSONALIZED": boolean;
}
