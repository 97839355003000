export enum InlineDialogEvent {
    CLOSE_INLINE_DIALOGS = "CLOSE_INLINE_DIALOGS",

    /**
     * context menu
     */
    DISPLAY_CTX_ACTIONS = "DISPLAY_CTX_ACTIONS",
    DISPLAY_CUSTOM_ACTIONS = "DISPLAY_CUSTOM_ACTIONS",
    OPEN_SUBMENU = "OPEN_SUBMENU",
    OPEN_2ND_SUBMENU = "OPEN_2ND_SUBMENU",
}