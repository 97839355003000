import * as angular from "angular";
import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges
} from "@angular/core";
import {NgControl} from "@angular/forms";
import {KeyCode} from "ENUMS_PATH/key-code.enum";
import {FormCatalogEntry} from "MODULES_PATH/form/interfaces/form.interface";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {Observable, Subscription} from "rxjs";
import {BubbleBoxStyle} from "INTERFACES_PATH/validation.interface";
import {AutoCompleteService} from "../services/autocomplete.service";
import {AutoComplete, AutoCompleteConfig} from "../interfaces/autocomplete.interface";
import {ViewService} from "CORE_PATH/services/view/view.service";

@Directive({
    selector: "[eobAutocomplete]"
})
export class EobAutocompleteDirective implements OnInit, OnDestroy, OnChanges {
    @Input("eobAutocomplete") autoCompleteConfig: AutoCompleteConfig;

    private autoComplete: AutoComplete;
    private listInternal: FormCatalogEntry[] = [];
    private listeners: Array<() => void> = [];
    private closeDialogSubscription: Subscription = new Subscription();
    private scrollListener: () => void;
    private isHoveringResultContainer = false;

    // eslint-disable-next-line max-params
    constructor(private autoCompleteService: AutoCompleteService, private messageService: MessageService,
                private el: ElementRef, private renderer: Renderer2, private ngModel: NgControl, private viewService: ViewService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if addon is added dynamically through a script, autoCompleteConfig is updated and we need to init directive again
        if (changes.autoCompleteConfig?.previousValue === null) {
            this.ngOnInit();
        }
    }

    ngOnInit(): void {
        if (!this.autoCompleteConfig) {
            return
        }

        if (this.autoCompleteService.listElements.length == 0) {
            const autocompleteListElements: HTMLElement[] = this.renderer.selectRootElement(".autocomplete-list", true).children;
            this.autoCompleteService.setListElements(autocompleteListElements);
        }

        // in case the autocomplete gets appended multiple times through client scripts
        // we return at this point to ensure listeners are not bound multiple times
        // this does not work for grid cells because after each blur the input is rendered again
        if (this.autoCompleteConfig.hasAutoComplete && !this.autoCompleteConfig.isGridCell) {
            return;
        }

        this.autoCompleteConfig.hasAutoComplete = true;

        const resultContainer: HTMLElement = document.body.querySelector("#autocomplete-wrapper");

        let _currentIndex = -1;
        this.autoComplete = {
            debounce: null,
            autoCompleteConfig: this.autoCompleteConfig,
            resultContainer,
            ul: resultContainer.querySelector("ul"),
            listElements: this.autoCompleteService.listElements,
            currentSearchKey: "",
            viewValue: "",
            resultCount: 0,
            scrollPos: 0,
            liHeight: 0,
            visibleElementsCount: 0,
            firstVisible: 0,
            lastVisible: 0,
            containerLeft: 0,
            containerVisible: false,
            element: this.el.nativeElement,

            get currentIndex() {
                return _currentIndex;
            },
            set currentIndex(value: number) {
                _currentIndex = value;
                this.autoCompleteConfig.isSelectingValue = value >= 0
            }
        };

        this.listeners.push(this.renderer.listen(this.autoComplete.element, "keydown", event => this.onKeyDown(event)));
        this.listeners.push(this.renderer.listen(this.autoComplete.element, "keyup", event => this.onKeyUp(event)));
        this.listeners.push(this.renderer.listen(this.autoComplete.element, "blur", () => this.hideAutoCompleteContainer()));
        this.listeners.push(this.renderer.listen(this.autoComplete.resultContainer, "mouseenter", () => this.isHoveringResultContainer = true));
        this.listeners.push(this.renderer.listen(this.autoComplete.resultContainer, "mouseleave", () => this.isHoveringResultContainer = false));

    }

    ngOnDestroy(): void {
        this.closeDialogSubscription?.unsubscribe();
        this.listeners.forEach(fn => fn());

        if (this.autoComplete) {
            this.hideAutoCompleteContainer();
        }
    }

    /**
     * Copied from eob-validation-bubble to fix bug before release => put in a common service when refactoring
     */
    getScrollParent(element: HTMLElement, includeHidden?: boolean): HTMLElement | string {
        let style: CSSStyleDeclaration = getComputedStyle(element);
        const excludeStaticParent: boolean = style.position === "absolute";
        const overflowRegex: RegExp = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

        if (style.position === "fixed") {
            return document.body;
        }

        let parent: HTMLElement = element;

        while (parent.parentElement) {
            parent = parent.parentElement;
            style = getComputedStyle(parent);
            if (excludeStaticParent && style.position === "static") {
                continue;
            }
            if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
                return parent;
            }
        }

        return document.body;
    }

    /**
     * Position the target Element next to some other element,
     * or hide it if its off the screen
     *
     * @param relativeTo: Element which has desired postion
     * @param target: Element that gets updated
     */
    updatePosition(relativeTo: HTMLElement, target: HTMLElement) {
        const position: BubbleBoxStyle = this.viewService.getPosition(relativeTo);
        const distanceFromTop = relativeTo.getBoundingClientRect().top;

        if (distanceFromTop < 0) {
            this.hideAutoCompleteContainer();
        }
        Object.keys(position).forEach(key => {
            this.renderer.setStyle(target, key, `${position[key]}${typeof (position[key]) === "number" ? "px" : ""}`);
        });
    }

    onKeyDown(event: MouseEvent | KeyboardEvent | TouchEvent): void {
        if (this.autoCompleteConfig.isDisabled) {
            return;
        }

        const key: number = event.which;

        switch (key) {
            case KeyCode.ENTER:
                if (this.autoComplete.currentIndex != -1) {
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                    event.preventDefault();
                }

                this.autoComplete.element.focus();
                break;
            case KeyCode.UP:
            case KeyCode.DOWN:
                event.preventDefault();
        }

        if (this.autoComplete.currentIndex != -1 && ![KeyCode.UP, KeyCode.DOWN, KeyCode.ENTER, KeyCode.SHIFT].includes(key)) {
            this.renderer.removeClass(this.autoComplete.listElements[this.autoComplete.currentIndex], "active-result");
            this.autoComplete.currentIndex = -1;
        }
    }

    onKeyUp(event: MouseEvent | KeyboardEvent | TouchEvent): void {
        if (this.autoCompleteConfig.isDisabled) {
            return;
        }

        clearTimeout(this.autoComplete.debounce);

        const key: number = event.which;
        const invalidKeys: number[] = [37, 38, 39, 40, 13, 9, 27, 16, 17, 20];
        const searchKey: string = this.autoCompleteService.getSearchKey(this.autoComplete.element, this.autoComplete.autoCompleteConfig.useMultiSelect);

        this.autoComplete.currentSearchKey = searchKey;

        // trigger arrow navigation or escape
        if (this.autoComplete.resultCount) {
            switch (key) {
                case KeyCode.ENTER:
                    if (this.autoComplete.currentIndex != -1) {
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                        event.preventDefault();

                        this.applyResult(this.autoComplete.currentIndex);
                    }
                    this.autoComplete.element.focus();
                    this.hideAutoCompleteContainer();

                    break;
                case KeyCode.DOWN:
                    if (this.isContainerVisible(this.autoComplete.resultContainer)) {
                        this.arrowNavigation(1);
                    }
                    break;
                case KeyCode.UP:
                    if (this.isContainerVisible(this.autoComplete.resultContainer)) {
                        this.arrowNavigation(-1);
                    }

                    break;
                case KeyCode.ESCAPE:
                    this.hideAutoCompleteContainer();
                    this.autoComplete.element.focus();

            }
        }

        // if key is part of invalidKeys return => do not get new info of BackendService
        if (invalidKeys.includes(key)) {
            return;
        }

        this.getAutoCompleteResults(searchKey);
    }

    applyResult(index: number): void {
        this.autoComplete.currentIndex = index;

        this.ngModel.control.patchValue(this.autoCompleteService.getNewInputValue(this.autoComplete));

        this.autoComplete.element.dispatchEvent(new Event("change"));

        this.hideAutoCompleteContainer(true);
    }

    /**
     * Complete user input with the value at 'currentIndex'. The autocompleted text gets selected.
     */
    suggestResult() {
        if (!this.autoComplete.autoCompleteConfig.isField) {
            this.ngModel.control.patchValue(this.listInternal[this.autoComplete.currentIndex].value);
            return;
        }

        // fill in new value
        const value: string = this.autoCompleteService.getNewInputValue(this.autoComplete);
        const cursorPosition: number = this.autoComplete.element.selectionStart;
        this.ngModel.control.patchValue(value);
        this.autoComplete.element.dispatchEvent(new Event("change"));

        // select new text
        setTimeout(() => {
            this.autoComplete.element.selectionStart = cursorPosition;
            this.autoComplete.element.selectionEnd = cursorPosition;
            this.autoComplete.element.setSelectionRange(cursorPosition, value.length);
        }, 0);
    }

    private arrowNavigation(direction: number): void {
        const maxIndex: number = this.autoComplete.resultCount - 1;

        if (this.autoComplete.currentIndex != -1) {
            this.renderer.removeClass(this.autoComplete.listElements[this.autoComplete.currentIndex], "active-result");
        }

        this.autoComplete.currentIndex += direction;
        this.autoComplete.currentIndex = this.autoComplete.currentIndex > maxIndex ? 0 : this.autoComplete.currentIndex < 0 ? maxIndex : this.autoComplete.currentIndex;

        this.renderer.addClass(this.autoComplete.listElements[this.autoComplete.currentIndex], "active-result");

        if (direction < 0 && this.autoComplete.currentIndex == maxIndex) {
            this.autoComplete.firstVisible = maxIndex - this.autoComplete.visibleElementsCount + 1;
            this.autoComplete.lastVisible = maxIndex;
            this.autoComplete.scrollPos = this.autoComplete.firstVisible * this.autoComplete.liHeight;
        } else if (direction > 0 && this.autoComplete.currentIndex >= this.autoComplete.lastVisible) {
            this.autoComplete.firstVisible++;
            this.autoComplete.lastVisible++;
            this.autoComplete.scrollPos += this.autoComplete.liHeight;
        } else if (direction < 0 && this.autoComplete.currentIndex < this.autoComplete.firstVisible) {
            this.autoComplete.firstVisible--;
            this.autoComplete.lastVisible--;
            this.autoComplete.scrollPos -= this.autoComplete.liHeight;
        } else if (this.autoComplete.currentIndex == 0) {
            this.autoComplete.firstVisible = 0;
            this.autoComplete.lastVisible = this.autoComplete.visibleElementsCount;
            this.autoComplete.scrollPos = 0;
        }
        this.autoComplete.ul.scrollTop = this.autoComplete.scrollPos;

        this.suggestResult();
    }

    private fillResultList(listInt: FormCatalogEntry[], searchKey: string): void {
        this.listInternal = listInt.slice(0, 10);
        this.autoComplete.resultCount = this.listInternal.length;

        let maxShortValueWidth = 0;

        // if no result was found stop here and hide container
        if (this.autoComplete.resultCount == 0) {
            this.hideAutoCompleteContainer();
            return;
        }

        for (let i = 0; i < 10; i++) {
            const value: HTMLElement = this.autoComplete.listElements[i].querySelector(".value");
            const short: HTMLElement = this.autoComplete.listElements[i].querySelector(".short");

            // reset the list entry
            value.innerHTML = "";
            short.innerHTML = "";

            this.autoComplete.listElements[i].style.display = "flex";
            if (this.autoComplete.autoCompleteConfig.isField) {
                short.style.display = this.autoCompleteConfig.addon != "db" ? "flex" : "none";
            } else {
                short.style.display = "none";
            }

            // apply result texts to the list entries if existing
            if (this.listInternal[i] == void 0 || (this.listInternal[i].value == "" && this.listInternal[i].short == "")) {
                this.autoComplete.listElements[i].style.display = "none";
            } else {
                value.innerText = this.listInternal[i].value;
                short.innerText = this.listInternal[i].short;
            }

            // get the max width of the short value elements to adjust the width of the column later
            if (this.autoComplete.autoCompleteConfig.isField && this.listInternal[i] != void 0) {
                const shortValueWidth: number = $(document.body).find("#text-width-inspector").text(this.listInternal[i].short).width() + 16;
                if (shortValueWidth == 16) {
                    short.style.display = "none";
                } else if (shortValueWidth > maxShortValueWidth) {
                    maxShortValueWidth = shortValueWidth;
                }
            }
        }

        this.renderResultContainer(searchKey, maxShortValueWidth);
    }

    private getAutoCompleteResults(searchKey: string): void {
        if (searchKey.length >= this.autoComplete.autoCompleteConfig.minCharacters) {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            this.autoComplete.debounce = setTimeout(async () => {
                // set time to next call
                const result: any = await this.autoComplete.autoCompleteConfig.getItems(searchKey);
                if (this.isContainerVisible(this.autoComplete.element)) {
                    let listInternal: FormCatalogEntry[];
                    if (result.field != void 0 && this.autoCompleteConfig.addon == "db") {
                        listInternal = result.list;
                    } else {
                        listInternal = result;
                    }

                    if (listInternal != void 0) {
                        this.fillResultList(listInternal, searchKey);
                        this.autoCompleteService.setListInternal(listInternal);
                    }
                }
            }, this.autoComplete.autoCompleteConfig.debounce);
        } else {
            this.hideAutoCompleteContainer();
        }
    }

    private renderResultContainer(searchKey: string, maxShortValueWidth: number): void {
        if (this.autoComplete.resultCount > 0 && searchKey == this.autoComplete.currentSearchKey) {
            setTimeout(() => {

                const position = {
                    left: this.autoComplete.element.getBoundingClientRect().left,
                    top: this.autoComplete.element.getBoundingClientRect().top + this.autoComplete.element.offsetHeight,
                    height: "auto",
                    width: "auto"
                };

                this.updatePosition(this.autoComplete.element, this.autoComplete.resultContainer);

                $(this.autoComplete.resultContainer).find(".short").width(maxShortValueWidth);

                this.messageService.broadcast("unbind-validation-bubble", this.autoComplete.element);

                // reset the dimensions
                this.renderer.setStyle(this.autoComplete.ul, "height", position.height);
                this.renderer.setStyle(this.autoComplete.ul, "width", position.width);
                this.renderer.setStyle(this.autoComplete.resultContainer, "display", "flex");

                this.showAutoCompleteContainer();

                // set max height and max width in cases where the autocomplete container is overflowing the window
                if (document.body.offsetHeight < position.top + this.autoComplete.resultContainer.offsetHeight) {
                    position.height = `${document.body.offsetHeight - position.top}px`;
                }

                if (document.body.offsetWidth < position.left + this.autoComplete.resultContainer.offsetWidth) {
                    position.width = `${document.body.offsetWidth - position.left}px`;
                }

                this.renderer.setStyle(this.autoComplete.ul, "height", position.height);
                this.renderer.setStyle(this.autoComplete.ul, "width", position.width);

                this.autoComplete.liHeight = this.autoComplete.listElements[0].offsetHeight;
                this.autoComplete.visibleElementsCount = Math.floor(this.autoComplete.ul.offsetHeight / this.autoComplete.liHeight);
                this.autoComplete.firstVisible = 0;
                this.autoComplete.lastVisible = this.autoComplete.visibleElementsCount - 1;

                this.autoCompleteService.setClickCallback(this.applyResult.bind(this));
            }, 0);

        } else if (searchKey != this.autoComplete.currentSearchKey) {
            this.getAutoCompleteResults(this.autoComplete.currentSearchKey);
        }
    }

    private isContainerVisible(container: HTMLElement): boolean {
        return container.offsetWidth > 0 && container.offsetHeight > 0;
    }

    private showAutoCompleteContainer(): void {
        if (typeof (this.scrollListener) == "function") {
            this.scrollListener();
        }
        this.closeDialogSubscription?.unsubscribe();
        this.closeDialogSubscription = this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, () => this.hideAutoCompleteContainer());
        this.scrollListener = this.renderer.listen(this.getScrollParent(this.el.nativeElement), "scroll", () => this.updatePosition(this.el.nativeElement, this.autoComplete.resultContainer));

        this.setVisibilityState(true);

        this.autoCompleteConfig.autoCompleteOpen = true;
    }

    private hideAutoCompleteContainer(forceHiding: boolean = false): void {
        setTimeout(() => {
            if (!forceHiding && this.isHoveringResultContainer) { // Only close when mouse is not in result container
                console.log("canceled hiding!");
                return;
            }

            if (this.autoComplete.currentIndex > -1 && this.autoComplete.listElements[this.autoComplete.currentIndex]) {
                this.autoComplete.listElements[this.autoComplete.currentIndex].classList.remove("active-result");
            }

            this.autoComplete.currentIndex = -1;
            this.autoComplete.resultContainer.style.display = "none";
            if (typeof (this.scrollListener) == "function") {
                this.scrollListener();
            }

            this.autoCompleteConfig.autoCompleteOpen = false;

            this.setVisibilityState(false);

            this.closeDialogSubscription?.unsubscribe();
        }, 0);

    }

    private setVisibilityState(visible: boolean): void {
        this.autoComplete.containerVisible = visible;
        this.autoComplete.containerLeft = angular.element(document.body).find("#autocomplete-wrapper").offset().left;
    }
}
