import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";

(function() {
    require("COMPONENTS_PATH/eob-modal-container/eob-modal-dashboard-searches-config/eob.modal.dashboard.searches.config.dir.js");

    require("SERVICES_PATH/eob.state.history.manager.srv.js");
    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.desktop.srv.js");
    require("SERVICES_PATH/eob.modal.dialog.srv.js");

    angular.module("eob.framework").directive("eobDashboard", EobDashboard);

    EobDashboard.$inject = ["$timeout", "$state", "stateHistoryManager", "backendService", "asIniService",
        "environmentService", "actionService", "desktopService", "$rootScope", "$filter", "$compile", "viewerService",
        "modalDialogService", "notificationsService", "progressbarService", "clientService", "messageService"];

    // eslint-disable-next-line max-params, require-jsdoc
    function EobDashboard($timeout, $state, StateHistoryManager, BackendService, AsIniService,
                          EnvironmentService, ActionService, DesktopService, $rootScope, $filter, $compile, ViewerService,
                          ModalDialogService, NotificationsService, ProgressbarService, ClientService, MessageService) {

        return {
            restrict: "E",
            async link(scope, element) {

                $timeout(() => {
                    MessageService.broadcast(ViewerEvent.SHOW_VIEWER, false);
                }, 0);

                scope.ready = {
                    inbox: false,
                    quicksearches: false
                };
                scope.isLoggingOut = ClientService.isLoggingOut;

                let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);

                progressBar.show();

                scope.unreadSubscriptions = null;
                scope.unreadRevisits = null;
                scope.unreadWorkflows = null;
                scope.showConfigureButton = EnvironmentService.userHasRole("R_CLNT_STORE_SETTINGS");
                scope.showSearchArea = EnvironmentService.env.desktop.showQueries;
                scope.isPhone = ClientService.isPhone();
                scope.isForcedPhoneLayout = ClientService.isForcedPhoneLayout();
                scope.showAltLang = !EnvironmentService.uiLangIsObjectDefLang();
                scope.objectDefLang = EnvironmentService.getObjectDefinitionLanguage();

                scope.saveExpandedState = function(search) {
                    search.expanded = !search.expanded;
                    AsIniService.setDashboardQuicksearchState(search.id, search.expanded)
                };

                scope.$on("update.queries", () => {
                    fetchQueries();
                });

                scope.$on("dashboard.config.changed", () => {
                    buildDashboardSearches()
                });

                scope.$on("$destroy", () => {
                    setTimeout(() => {
                        // Prevents flickering of preview if dashboard button is mashed
                        MessageService.broadcast(ViewerEvent.SHOW_VIEWER, true);
                    }, 0);
                });

                // We initial land at the dashboard and navigate from here in case of offline to the
                // offline|favorites state. We shouldn't load anything here if we are offline.
                if (ClientService.isOnline() && !ClientService.isLoggingOut) {
                    await countUnreadInboxItems();
                }

                async function countUnreadInboxItems() {
                    scope.countSubscriptions = EnvironmentService.env.actions.useAbo;
                    scope.countRevisits = EnvironmentService.env.actions.useRevisits;
                    scope.countWorkflows = EnvironmentService.isWorkflowUser();

                    let url = `/notifications/count?showown=true&clienttype=${EnvironmentService.wfClientType}` +
                        `&countSubscriptions=${scope.countSubscriptions}&countRevisits=${scope.countRevisits}&countWorkflows=${scope.countWorkflows}`;

                    try {
                        let response = await BackendService.get(url);

                        if (scope.countSubscriptions) {
                            scope.subscriptions = {
                                unread: response.data.subscriptions.unread,
                                total: response.data.subscriptions.total
                            }
                        }

                        if (scope.countRevisits) {
                            scope.revisits = {
                                unread: response.data.revisits.unread,
                                total: response.data.revisits.total
                            }
                        }

                        if (scope.countWorkflows) {
                            scope.workflows = {
                                unread: response.data.workflows.unread,
                                total: response.data.workflows.total
                            }
                        }

                        scope.ready.inbox = true;
                    } catch (_) {
                        scope.ready.inbox = true;
                    }
                }

                scope.openInbox = function(context) {
                    let nextStateId = $.now();

                    // next we create a package with all the relevant data
                    // in this case the whole form, the objecttypeid and the type of task
                    // the config key is used by the state itself
                    // a state can update its state model with information like "sort,grouping, scroll position etc..."
                    let nextStateContent = {
                        config: {},
                        type: context
                    };

                    // this function generates a new state with given data
                    StateHistoryManager.setStateData(nextStateContent, nextStateId);
                    // jump into the new state
                    $state.go("hitlist.inbox", { type: context, state: nextStateId })
                };

                let allSearches = [],
                    desktopSearches;

                scope.quicksearches = [];
                scope.dashboardSearches = [];

                try {
                    await DesktopService.initAsync();
                    fetchQueries();
                } catch (error) {
                    if (error.type != "WEB_OFFLINE_ERROR") {
                        NotificationsService.customError(error);
                    }
                } finally {
                    $timeout(() => {
                        scope.ready.quicksearches = true;
                    }, 500);
                }

                function fetchQueries() {
                    if (scope.showSearchArea) {
                        scope.quicksearches = DesktopService.getQuickSearches(AsIniService.getDashboardQuicksearchState());

                        for (let i in scope.quicksearches) {
                            allSearches.push(scope.quicksearches[i])
                        }

                        desktopSearches = DesktopService.getAllDesktopQueries();

                        for (let i in desktopSearches) {
                            let item = angular.copy(desktopSearches[i]);
                            item.type = "query";
                            allSearches.push(item)
                        }
                    }

                    if (allSearches.length === 0) {
                        scope.showSearchArea = false;
                    }

                    buildDashboardSearches();
                }

                function buildDashboardSearches() {
                    scope.dashboardSearches = [];
                    let selected = AsIniService.getDashboardSearches().selected;
                    let keys = [];
                    if (selected != void 0) {
                        keys = selected.split(",");
                    }

                    for (let search of allSearches) {
                        let index = keys.indexOf(search.id.toString());

                        if (index != -1) {
                            scope.dashboardSearches[index] = search;
                        }
                    }

                    // it might happen, that some queries are configured but the the user loses the rights to see them
                    // in that case the configured entries would leave undefined entries inside the array, which is rather bad
                    // we splice them from the array
                    for (let i = 0; i < scope.dashboardSearches.length; i++) {
                        if (scope.dashboardSearches[i] == void 0 || scope.dashboardSearches[i] == "") {
                            scope.dashboardSearches.splice(i, 1);
                            i--
                        }
                    }
                }

                scope.executeSavedQuery = function(item) {
                    DesktopService.executeAction(item);
                };

                scope.showDashboardSearchConfig = () => {
                    ModalDialogService.showDashboardSearchConfig();
                };
            },
            template: require("!raw-loader!./eob.dashboard.html")
        };
    }
})();
