import {Directive} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: "[eobNewPasswordValidator]",
    // eslint-disable-next-line no-use-before-define
    providers: [{provide: NG_VALIDATORS, useExisting: NewPasswordValidatorDirective, multi: true}]
})
export class NewPasswordValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors {
        const currentPassword = control.get("current-password");
        const newPassword = control.get("new-password");
        const newPasswordConfirmation = control.get("new-password-confirmation");
        if (!currentPassword || !newPassword || !newPasswordConfirmation) {
            return null;
        }
        if(currentPassword.value == newPassword.value) {
            newPassword.setErrors({passwordsIdentical: true});
            return {passwordsIdentical: true};
        }
        if (!newPassword.value || !newPasswordConfirmation.value) {
            return null;
        }
        if (newPassword.value != newPasswordConfirmation.value) {
            if (!newPassword.errors?.passwordsDoNotMatch) {
                newPassword.setErrors({passwordsDoNotMatch: true});
            }
            if (!newPasswordConfirmation.errors?.passwordsDoNotMatch) {
                newPasswordConfirmation.setErrors({passwordsDoNotMatch: true});
            }
            return {passwordsDoNotMatch: true};
        }

        newPassword.setErrors(null);
        newPasswordConfirmation.setErrors(null);
        return null;
    }
}
