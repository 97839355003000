import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {DmsDocumentModel} from "./dms-document-model";
import {BaseParameters} from "../interfaces/base-parameters.interface";
import {TitleAndDescription} from "MODULES_PATH/dms/interfaces/title-and-description.interface";
import { TodoEnvironmentService, TodoCacheManagerService } from "INTERFACES_PATH/any.types";

/**
 * Service implementing the public DMS document API
 */
export class DmsDocumentApi {

    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
    private toolService: ToolService;
    private cacheManagerService: TodoCacheManagerService;
    private environmentService: TodoEnvironmentService;
    private dmsDocumentService: any;
    /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

    private TITLE_DELIMITER: string = " - ";

    constructor(private model: DmsDocumentModel, $injector: ng.auto.IInjectorService ) {
        this.toolService = $injector.get("toolService");
        this.cacheManagerService = $injector.get("cacheManagerService");
        this.environmentService = $injector.get("environmentService");
        this.dmsDocumentService = $injector.get("dmsDocumentService");
    }

    /**
     * set the dms document model anew
     *
     * @param model
     */
    setDmsDocumentModel = (model: DmsDocumentModel): void => {
        this.model = model;
    };

    /**
     * adds a revisit object
     *
     * @param revisit
     */
    addRevisit = (revisit: any): void => {
        revisit.guid = this.toolService.createGUID();
        this.model.revisits.push(revisit);
    };

    /**
     * adds a subscription object
     *
     * @param subscription
     */
    addSubscription = (subscription: any): void => {
        subscription.guid = this.toolService.createGUID();
        this.model.subscriptions.push(subscription);
    };

    /**
     * adds variantinfo
     *
     * @param variantInfo
     */
    addVariantInfo = (variantInfo: any): void => {
        this.model.variantInfo.push(variantInfo);
    };

    /**
     * adds a wf item
     *
     * @param wfItem
     */
    addWfItem = (wfItem: any): void => {
        this.model.wfItem.push(wfItem);
    };

    /**
     * returns the contextitem for the given guid or null
     *
     * @param guid
     * @returns {any}
     */
    getContextItem = (guid: any): any => {
        if (guid == void 0) {
            return null;
        }

        const revisits: any[] = this.model.revisits || [],
            subscriptions: any[] = this.model.subscriptions || [],
            subscriptionObjects: any[] = this.model.subscriptionObjects || [],
            wfItem: any[] = this.model.wfItem || [];

        const contextItems: any[] = ([] as any[]).concat(revisits, subscriptions, subscriptionObjects, wfItem);

        for (const contextItem of contextItems) {
            if (contextItem.model.guid == guid) {
                return contextItem;
            }
        }
        return null;
    };

    /**
     * removes a contextspecific model from the dmsDocument using the guid of the contextitem
     *
     * @param {string} guid
     * @param {string} context
     */
    removeContextItem = (guid: string, context: string): void => {
        const contextItems: any[] = this.model[context] || [];

        for (const i in contextItems) {
            if (contextItems[i].model.guid == guid) {
                contextItems.splice(+i, 1);
                return;
            }
        }
    };

    /**
     * updates baseparameters with an object of changes
     *
     * @param changes
     */
    updateBaseParameters = (changes: any): void => {
        for (const property in changes) {
            const value: any = changes[property];

            const baseParameters: BaseParameters = this.model.baseParameters;

            if (baseParameters[property] == void 0) {
                console.warn("unknown property ", property);
                continue;
            }

            // Todo check if the changes are legal?
            baseParameters[property] = value;

            switch (property) {
                case "objectCount":
                    if (value > 0) {
                        this.model.hasContent = true;
                    }
                    break;
            }
        }
    };

    /**
     * Returns the title and description pattern for a dmsDocument.
     *
     * @returns TitleAndDescription The title and description pattern for a dmsDocument.
     */
    getTitleAndDescription = (): TitleAndDescription => {
        const name: string = this.buildNameFromIndexData(-1, false, false);

        // split file name in title and description
        const fieldValues: string[] = name.split(this.TITLE_DELIMITER);
        const title: string = fieldValues.shift() || this.model.name,
            description: string = fieldValues.join(this.TITLE_DELIMITER);

        return { title, description };
    };

    /**
     * Builds a filename based on the configured fields.
     *
     * @param {Number}      length               - describes how many fields should be used to build the name
     * @param {boolean}     fileSystemCompatible - the filename must be filesystem compatible to save a file with this name
     * @param {boolean}     useObjectTypeName    - determines wheter to use the object type name or not
     * @returns {String} The generated name
     */
    buildNameFromIndexData = (length?: number, fileSystemCompatible?: boolean, useObjectTypeName?: boolean): string => {
        const typeDef: any = this.cacheManagerService.objectTypes.getById(this.model.objectTypeId),
            configuredFields: any = typeDef.api.getConfiguredFields(),
            langCode: string = this.environmentService.getLanguage();
        let prefix: string = "";

        // add the type name to the beginning of the array
        if (useObjectTypeName) {
            prefix = typeDef.model.config.title;
        }

        const fileName: string = this.dmsDocumentService.buildTitleFromConfiguredFields(this.model.fields, configuredFields, langCode, length, prefix);

        return (fileSystemCompatible) ? ToolService.nameToFilename(fileName) : fileName;
    };
}
