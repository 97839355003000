(function() {


    require("SERVICES_PATH/viewer/detailsviewer/dv.environment.srv.js");

    angular.module("eob.framework").directive("dvTable", DvTable);

    DvTable.$inject = ["$log", "dvEnvironmentService", "$filter", "clientService"];

    function DvTable($log, DvEnvironmentService, $filter, ClientService) {
        return {
            scope: {
                dvTitle: "@",
                tabledata: "=",
                type: "@",
                objecttypeid: "@"
            },
            restrict: "AE",
            template: "<div ag-grid=\"gridOptions\" class=\"dv-indexdata-grid\" style=\"height: 100%; width: 100%;\" ng-if=\"gridOptions && tabledata && !isPhone\"></div>",
            link(scope, element) {
                let isTable = scope.type === "table";

                let MAIL_LINK_REG_EX = new RegExp("<a.*class='(mail|link)'.*>(.+)</a>");
                let SPECIAL_VALUE_REG_EX = new RegExp("(<a.*class='(tel|mail|link)')(.*)(<\/a>)");

                let domElements = {
                    parentContainer: undefined,
                    visibleContainer: undefined,
                    columnHeightHelperElement: undefined
                };

                let simpleContainerTypID = ["simpleFields", "baseParamsFields"].indexOf(scope.type);
                let fieldName = ["displayName", "type"];

                let isUserResizing = false;
                let isTechnicallyResizing = false;

                let isColumnWidthInitialized = false;
                let isColumnWidthUserEdited = false;

                // initial or user defined width of all columns
                let preferredWidth = 0;
                // difference between all column widths together and actual grid width
                let userSpecificContainerWidthDif;
                // actual width of all columns
                let allColumnsWidth = -1;

                let headerHeight = isTable ? 32 : 0;
                let tableMinRowHeight = 31;
                let tableMaxRowHeight = 135;

                let currentTableHeight = 0;
                let currentTableData;

                scope.isPhone = ClientService.isPhone();
                scope.$on("dv.refresh.indexdata", (e, data) => {
                    setNewTableData(data);
                });
                scope.$watch("tabledata", updateTableData, true);

                if(!scope.isPhone) {
                    init();
                }

                // region init
                function init() {
                    initDomElements();
                    if (scope.gridOptions !== void 0) {
                        return;
                    }
                    setAllColumnsWidth();
                    initGridOptions();
                }

                function initDomElements() {
                    domElements.columnHeightHelperElement = document.getElementById("getColumnHeight");
                    domElements.parentContainer = element[0].parentElement;
                    domElements.visibleContainer = domElements.parentContainer.parentElement;
                }

                function initGridOptions() {
                    scope.gridOptions = {
                        defaultColDef: {
                            sortable: false,
                            resizable: true,
                            filter: false,
                            suppressMenu: true
                        },
                        headerHeight,
                        columnDefs: setColumnDef(),
                        rowData: prepareRowData(),
                        suppressContextMenu: true,
                        suppressNoRowsOverlay: true,
                        suppressMovableColumns: true,
                        suppressCellSelection: false,
                        suppressColumnVirtualisation: true,
                        enableCellTextSelection: true,
                        getRowHeight: function getRowHeight(params) {
                            return params.data.rowHeight || tableMinRowHeight;
                        },
                        onGridSizeChanged: gridWidthChanged
                    };

                    if (isTable) {
                        scope.gridOptions.onColumnResized = onColumnResized;

                        // we can only autosize the column widths, once the grid is visible
                        scope.$on("dv.table.visible", initTable);
                    }
                }

                function initTable() {
                    if (!isColumnWidthInitialized && isViewerVisible()) {
                        // And than we need to wait, until agGrid is ready displaying the now "visible" columns.
                        // Unfortunatly no event is thrown for the display to be ready, so we need to check that ourselves.
                        let visibleIntervalId = setInterval(() => {
                            if (isVisible()) {
                                clearInterval(visibleIntervalId);
                                prepareRowData(scope.tabledata.value);
                                scope.gridOptions.api.doLayout();
                            }
                        }, 10);
                    }
                }

                //endregion

                // region columns
                function setColumnDef() {
                    let colDefinition = [];
                    if (isTable) { //table contains a table
                        colDefinition = prepareTableColumns(scope.tabledata.columns);
                    } else if (simpleContainerTypID >= 0) { //indexdata, baseparams
                        colDefinition = prepareDataColumns(simpleContainerTypID);
                    }
                    return colDefinition;
                }

                function prepareDataColumns(partId) {
                    let tmpWidth = allColumnsWidth / 2;

                    let colDef = [];
                    colDef.push({ headerName: "", field: fieldName[partId], width: tmpWidth });
                    colDef.push({
                        headerName: "",
                        field: "value",
                        width: tmpWidth,
                        cellRenderer(params) {
                            let title = isSpecialValue(params.value) ? extractTitle(params.value) : params.value;
                            return `<span title="${title}">${params.value}</span>`;

                        }
                    });

                    return colDef;
                }

                function prepareTableColumns(columns) {
                    let colDef = [];
                    for (let index = 0; index < columns.length; index++) {
                        colDef.push({
                            headerName: columns[index].name,
                            field: index.toString(),
                            menuTabs: [],
                            minWidth: 50,
                            cellRenderer(params) {
                                let title = isSpecialValue(params.value) ? extractTitle(params.value) : params.value;
                                return `<span title="${title}">${params.value}</span>`;
                            }
                        });
                    }
                    return colDef;
                }

                // endregion

                // region rows
                function prepareRowData(newData) {
                    let data = newData || currentTableData;

                    if (data == void 0) {
                        data = scope.tabledata != void 0 ? (isTable ? scope.tabledata.value : scope.tabledata) : [];
                    }

                    let preparedData = getPreparedData(data);
                    currentTableData = preparedData;

                    if (scope.gridOptions != void 0 && scope.gridOptions.api != void 0) {
                        scope.gridOptions.api.setRowData(currentTableData);
                    }

                    // set container height
                    domElements.parentContainer.style.height = `${currentTableHeight}px`;

                    return currentTableData;
                }

                // endregion

                // region row height and column width
                function getPreparedData(data) {
                    if (isTable && !isVisible()) {
                        return data;
                    }

                    return prepareData(data);
                }

                function prepareData(data) {
                    if (isTable && !isColumnWidthInitialized) {
                        isColumnWidthInitialized = true;
                        setInitialColumnWidths();
                    }

                    let sizeData = isTable ? getSizeDataForTableData(data) : getSizeDataForSimpleData(data);
                    let rows = sizeData.rows;
                    let columnWidths = sizeData.columnWidths;

                    return setRowHeights(rows, columnWidths);
                }

                function getSizeDataForSimpleData(data) {
                    let width = Math.floor(allColumnsWidth / 2);
                    let columnWidths = [width, width];

                    let rows = [];
                    for (let j = 0; j < data.length; j++) {
                        rows.push({
                            item: data[j],
                            cells: [data[j].value, data[j][fieldName[simpleContainerTypID]]]
                        });
                    }

                    return { rows, columnWidths };
                }

                function getSizeDataForTableData(data) {
                    let columnWidths = [];
                    let columnState = scope.gridOptions.columnApi.getColumnState();
                    for (let i = 0; i < columnState.length; i++) {
                        columnWidths.push(columnState[i].width);
                    }

                    let rows = [];
                    for (let j = 0; j < data.length; j++) {
                        rows.push({
                            item: data[j],
                            cells: data[j]
                        });
                    }

                    return { rows, columnWidths };
                }

                function setRowHeights(rows, columnWidths) {
                    let gridData = [];
                    let newContainerHeight = 0;

                    if (domElements.columnHeightHelperElement) {
                        domElements.columnHeightHelperElement.style.display = "inline-block";

                        for (let j = 0; j < rows.length; j++) {
                            let maxHeight = tableMinRowHeight;
                            let row = rows[j];

                            for (let i in row.cells) {
                                let value = row.cells[i];
                                let width = columnWidths[i];

                                if (isLinkMail(value)) {
                                    value = getLinkMailCellSize(value);
                                }

                                domElements.columnHeightHelperElement.style.width = `${width}px`;
                                domElements.columnHeightHelperElement.innerHTML = value;
                                let cellHeight = domElements.columnHeightHelperElement.offsetHeight;

                                // prevent unnecessary scrollbar
                                if (isTable && cellHeight > tableMinRowHeight) {
                                    cellHeight += 5;
                                }

                                if (cellHeight > maxHeight) {
                                    maxHeight = cellHeight;
                                }
                            }

                            if (maxHeight > tableMaxRowHeight) {
                                maxHeight = tableMaxRowHeight;
                            }

                            newContainerHeight += maxHeight;

                            let item = angular.copy(row.item);
                            item.rowHeight = maxHeight;
                            gridData.push(item);
                        }

                        // add scrollbar space
                        if (isTable) {
                            newContainerHeight += 20;

                            if (newContainerHeight > 500) {
                                newContainerHeight = 500;
                            }
                        }

                        domElements.columnHeightHelperElement.innerHTML = "";
                        domElements.columnHeightHelperElement.style.display = "none";

                        currentTableHeight = newContainerHeight + headerHeight;

                        return gridData;
                    }
                }

                // endregion

                function isLinkMail(value) {
                    return !!value.toString().match(MAIL_LINK_REG_EX);
                }

                function isSpecialValue(value) {
                    //for more details see dv.documentService
                    return !!value.toString().match(SPECIAL_VALUE_REG_EX);
                }

                function getLinkMailCellSize(value) {
                    return `${value}--`; //~ padding from the a tag
                }

                function extractTitle(value) {
                    let matchMailLink = MAIL_LINK_REG_EX.exec(value.toString());

                    if (!value.includes("mailto") && matchMailLink && matchMailLink.length > 1) {
                        return matchMailLink[1].trim();
                    }

                    return "";
                }

                // region initial column resize
                function setInitialColumnWidths() {
                    isTechnicallyResizing = true;
                    let widthConfig = DvEnvironmentService.retrieveTableWidthSettings(scope.objecttypeid, scope.dvTitle);
                    if (Object.keys(widthConfig.columns).length > 0) {
                        isColumnWidthUserEdited = true;
                        setUserConfigWidths(widthConfig.columns);
                    } else {
                        dvAutoSize();
                    }
                    isTechnicallyResizing = false;
                }

                function setUserConfigWidths(columns) {
                    let allColumnWidths = 0;
                    for (let colId in columns) {
                        let width = columns[colId];
                        scope.gridOptions.columnDefs[colId].width = width;
                        allColumnWidths += width;
                    }
                    if(scope.gridOptions.api) {
                        scope.gridOptions.api.setColumnDefs(scope.gridOptions.columnDefs);
                    }

                    if (allColumnWidths < getGridWidth()) {
                        sizeColumnsToFit();

                        allColumnWidths = 0;
                        let columnState = scope.gridOptions.columnApi.getColumnState();
                        for (let colId in columnState) {
                            allColumnWidths += columnState[colId].width;
                        }
                    }

                    preferredWidth = allColumnWidths;
                    userSpecificContainerWidthDif = allColumnsWidth - preferredWidth;
                    allColumnsWidth = preferredWidth;
                }

                function dvAutoSize() {
                    scope.gridOptions.api.sizeColumnsToFit();
                    autoSize();

                    let minLargeColumnWidth = 300;
                    let allColumnWidths = 0,
                        allSmallColumnWidths = 0,
                        allLargeColumnWidths = 0;
                    let largerColumns = [];

                    let columnState = scope.gridOptions.columnApi.getColumnState();
                    for (let colId in columnState) {
                        var column = columnState[colId];
                        var width = column.width;

                        if (width > minLargeColumnWidth) {
                            largerColumns.push({ colId, width });
                            allLargeColumnWidths += width;
                        } // let columns, which are smaller than the min width, keep their perfect width
                        else {
                            width += 16; // plus padding
                            allSmallColumnWidths += width;
                            allColumnWidths += width;
                            scope.gridOptions.columnDefs[colId].width = width;
                        }
                    }

                    // sort larger columns by width (small -> big)
                    largerColumns.sort((a, b) => {
                        return a.width - b.width;
                    });

                    // split remaining space for the larger column widths by percentage
                    let remainingSpace = allColumnsWidth - allSmallColumnWidths;
                    for (let i = 0; i < largerColumns.length; i++) {
                        column = largerColumns[i];
                        width = minLargeColumnWidth;

                        if (remainingSpace > minLargeColumnWidth) {
                            // calc remaining column width by percentage
                            let widthPercentage = column.width / allLargeColumnWidths;
                            let widthByPercentage = Math.round(remainingSpace * widthPercentage);

                            // but don't allow it to get smaller than the min width
                            if (widthByPercentage < minLargeColumnWidth) {
                                // reduce the overall remaining space by the additional space, that the column now needs
                                let overlappingSpace = (minLargeColumnWidth - widthByPercentage);
                                remainingSpace -= overlappingSpace;

                                widthByPercentage = minLargeColumnWidth;
                            }

                            width = widthByPercentage;
                        }

                        allColumnWidths += width;
                        scope.gridOptions.columnDefs[column.colId].width = width;
                    }

                    scope.gridOptions.api.setColumnDefs(scope.gridOptions.columnDefs);
                    preferredWidth = allColumnWidths;

                    sizeColumnsToFit();
                }

                //endregion

                // region update
                function onColumnResized(e) {
                    isUserResizing = isColumnWidthInitialized && !isTechnicallyResizing;

                    if (isUserResizing) {
                        isColumnWidthUserEdited = true;

                        // if need be set new row heights and container height
                        prepareRowData();

                        // save new width user settings
                        if (e.finished) {
                            isUserResizing = false;
                            preferredWidth = 0;
                            let columnState = scope.gridOptions.columnApi.getColumnState();
                            for (let i = 0; i < columnState.length; i++) {
                                preferredWidth += columnState[i].width;
                            }

                            userSpecificContainerWidthDif = getGridWidth() - preferredWidth;

                            allColumnsWidth = preferredWidth;
                            saveColumnWidthConfig();
                        }
                    }
                }

                function updateTableData(newVal, oldVal) {
                    if (!isTable && newVal != void 0 && newVal != oldVal) {
                        setNewTableData(isTable ? newVal.value : newVal);
                    }
                }

                function setNewTableData(data) {
                    if (scope.gridOptions != void 0 && !scope.isPhone) {
                        setAllColumnsWidth();

                        prepareRowData(data);
                        sizeColumnsToFit();
                    }
                }

                function gridWidthChanged(params) {
                    // user is changing column width and a scrollbar appears oder disappears
                    if (isUserResizing) {
                        return;
                    }

                    let widthDif = params["clientWidth"] - allColumnsWidth;
                    if (widthDif === 0) {
                        return;
                    }

                    setAllColumnsWidth(params["clientWidth"]);

                    prepareRowData();

                    sizeColumnsToFit();
                }

                // endregion

                // region utility
                function sizeColumnsToFit() {
                    let newContainerWidth = allColumnsWidth;
                    if (isTable) {
                        // don't resize the table any further, if the preferred size is already undershot
                        if (newContainerWidth < preferredWidth) {
                            newContainerWidth = preferredWidth;
                        }
                    }

                    isTechnicallyResizing = true;
                    if( scope.gridOptions.columnApi) {
                        scope.gridOptions.columnApi.sizeColumnsToFit(newContainerWidth);
                    }
                    isTechnicallyResizing = false;
                }

                function autoSize() {
                    let columnIds = [];
                    let columnDefinitions = scope.gridOptions.columnDefs;

                    for (let i = 0; i < columnDefinitions.length; i++) {
                        let def = columnDefinitions[i];
                        columnIds.push(def.field);
                    }

                    scope.gridOptions.columnApi.autoSizeColumns(columnIds);
                }

                function setAllColumnsWidth(width) {
                    if (width != void 0) {
                        if (isColumnWidthUserEdited) {
                            allColumnsWidth -= (allColumnsWidth + userSpecificContainerWidthDif) - width;
                        } else {
                            allColumnsWidth = width;
                        }
                    }
                    else if (allColumnsWidth <= 0) {
                        allColumnsWidth = getGridWidth();
                    }
                }

                function getGridWidth() {
                    return angular.element(element[0].parentElement.parentElement)[0].clientWidth;
                }

                function saveColumnWidthConfig() {
                    let widthSettings = DvEnvironmentService.retrieveTableWidthSettings(scope.objecttypeid, scope.dvTitle);

                    let columnState = scope.gridOptions.columnApi.getColumnState();
                    for (let i = 0; i < columnState.length; i++) {
                        let column = columnState[i];
                        widthSettings.columns[column.colId] = column.width;
                    }

                    DvEnvironmentService.storeTableWidthSettings(scope.objecttypeid, scope.dvTitle, widthSettings);
                }

                function isViewerVisible() {
                    return getGridWidth() > 0;
                }

                function isVisible() {
                    return domElements.visibleContainer.classList.contains("open");
                }

                //endregion
            }
        };
    }
})();
