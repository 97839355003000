
export class BackendHistoryObject {
    readonly action: number;

    // eslint-disable-next-line max-params
    constructor(readonly user: string, readonly fullname: string, readonly description: string, action: number,
                readonly detail: string, readonly creationDate: Date) {

        // Core-API starts with 100 and enaio server history codes are currently less then 60. There is enough space
        // towards 100. This check will long time work.
        if (action < 100) {
            this.action = action;
        } else {
            // Core-API to enaio server history codes. Internal we work with the enaio server history code numbers
            // See: https://help.optimal-systems.com/enaio_develop/display/DA/1.4+History+Codes
            switch (action) {
                case 100: this.action = 2; break;
                case 101: this.action = 10; break;
                case 102: this.action = 51; break;

                // case 110: this.action = ??; break; // yuuvis only. enaio may be in later versions

                case 120: this.action = 17; break;
                case 121: this.action = 36; break;
                case 122: this.action = 54; break;

                // case 130: this.action = ??; break; // yuuvis only. enaio may be in later versions
                // case 131: this.action = ??; break; // yuuvis only. enaio may be in later versions

                case 140: this.action = 39; break;
                case 141: this.action = 11; break;
                // case 142: this.action = ??; break; // yuuvis only. enaio may be in later versions

                case 150: this.action = 44; break;

                case 160: this.action = 48; break;

                case 200: this.action = 29; break;
                // case 201: this.action = ??; break; // yuuvis only. enaio may be in later versions
                case 202: this.action = 27; break;

                // case 210: this.action = ??; break; // yuuvis only. enaio may be in later versions

                case 220: this.action = 18; break;
                case 221: this.action = 35; break;

                case 230: this.action = 6; break;
                case 231: this.action = 16; break;

                // case 240: this.action = ??; break; // yuuvis only. enaio may be in later versions
                case 241: this.action = 12; break;
                case 242: this.action = 52; break;

                case 250: this.action = 46; break;

                case 260: this.action = 50; break;

                case 300: this.action = 3; break;
                case 301: this.action = 4; break;
                // case 302: this.action = ??; break; // yuuvis only. enaio may be in later versions
                // case 303: this.action = ??; break; // yuuvis only. enaio may be in later versions
                case 304: this.action = 55; break;

                // case 310: this.action = ??; break; // yuuvis only. enaio may be in later versions

                case 320: this.action = 37; break;
                case 321: this.action = 43; break;
                case 322: this.action = 32; break;
                case 323: this.action = 14; break;
                case 324: this.action = 28; break;
                case 325: this.action = 19; break;
                case 326: this.action = 41; break;

                case 330: this.action = 33; break;
                case 331: this.action = 53; break;
                case 332: this.action = 34; break;

                case 340: this.action = 21; break;
                case 341: this.action = 22; break;
                case 342: this.action = 38; break;

                case 350: this.action = 8; break;
                case 351: this.action = 9; break;
                case 352: this.action = 5; break;
                case 353: this.action = 42; break;

                case 360: this.action = 1; break;
                case 361: this.action = 15; break;

                case 370: this.action = 45; break;

                case 380: this.action = 49; break;

                case 390: this.action = 24; break;
                case 391: this.action = 26; break;
                case 392: this.action = 23; break;
                case 393: this.action = 25; break;

                case 400: this.action = 7; break;
                // case 401: this.action = ??; break; // yuuvis only. enaio may be in later versions
                // case 402: this.action = ??; break; // yuuvis only. enaio may be in later versions
                // case 403: this.action = ??; break; // yuuvis only. enaio may be in later versions
                // case 404: this.action = ??; break; // yuuvis only. enaio may be in later versions

                case 410: this.action = 13; break;
                case 411: this.action = 20; break;

                case 470: this.action = 31; break;
                case 471: this.action = 40; break;

                case 600: this.action = 30; break;
                case 601: this.action = 47; break;
            }
        }
    }
}
