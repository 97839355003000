import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";
import {CorrectionFieldMask} from "MODULES_PATH/form/models/field-mask.model";

export class MultiEntryMask extends CorrectionFieldMask {
    constructor(private multiselectionSeparators: string[]) {
        super();
    }

    /** Parses a list removing the leading and trailing semicolon. */
    parseValue(value: FormInputValue): FormInputValue {
        let parsedValue: string = value.toString();
        this.multiselectionSeparators.forEach(separator => {
            parsedValue = parsedValue.split(separator).filter(v => v?.trim().length > 0).join(separator);
        });
        return parsedValue;
    }
}