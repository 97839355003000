import {ChangeDetectorRef, Component, ElementRef, forwardRef, Inject, Input, OnInit, Renderer2} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {TodoClientScriptService, TodoEnvironmentService, TodoFormHelper} from "INTERFACES_PATH/any.types";
import {FieldModel} from "SHARED_PATH/models/field.model";

@Component({
    selector: "eob-form-radio",
    templateUrl: "./eob-form-radio.component.html",
    styleUrls: ["./eob-form-radio.component.scss"]
})
export class EobFormRadioComponent implements OnInit {
    @Input() field: Field;
    @Input() formhelper: TodoFormHelper;
    @Input() formid: string;
    @Input() ismockform: boolean;

    fieldid: string;
    showAltLang: boolean;
    objectDefLang: string;

    // eslint-disable-next-line max-params
    constructor(
        public el: ElementRef,
        public renderer: Renderer2,
        private cdRef: ChangeDetectorRef,
        @Inject(forwardRef(() => "environmentService")) public environmentService: TodoEnvironmentService,
        @Inject("clientScriptService") private clientScriptService: TodoClientScriptService) {
    }

    ngOnInit(): void {
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang() && !this.ismockform;
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();

        const model: FieldModel = this.field.model;
        const fields: Field[] = this.formhelper.getFields();

        const masterRadio: Field = fields[model.masterRadioInternal];
        const groupInternal: string = masterRadio.model.groupInternal;

        this.fieldid = `${this.field.model.internal}-${this.formid}`;

        if (groupInternal != void 0 && fields[groupInternal] != void 0 && fields[groupInternal].value != "") {
            masterRadio.value = fields[masterRadio.model.groupInternal].value;
        }

        if ((this.formhelper.isCreate || this.formhelper.isWorkflow) && !masterRadio.value) {
            masterRadio.value = 0;
        }

        if (this.formhelper.isEdit && !masterRadio.model.fields.some(field => fields[field] != void 0 && fields[field].value != "")) {
            masterRadio.value = 0;
        }

        if (model.isMasterRadio) {
            this.initWorkflowEventScripts();
            model.masterRadioControl.registerOnChange(newValue => {
                this.field.value = newValue;
                this.field.api.syncParameterValue();
            });
            model.masterRadioControl.setValue(masterRadio.value);
        }

        this.createRadioButtonApi(this.field);
    }

    /**
     * Creates the radio button api.
     *
     * @param {object} field - the form field object.
     */
    createRadioButtonApi(field: Field): void {
        field.api.focus = () => {
            field.api.getElement().focus();
        };

        field.api.enable = (onlyEnableFirstRadio) => {
            field.model.isDisabled = false;
            if(!field.model.isMasterRadio || onlyEnableFirstRadio) {
                field.model.control.enable();
            } else {
                field.model.radioGroupControls.forEach(x => x.enable());
            }

            this.cdRef.detectChanges();
        };

        field.api.disable = (onlyDisableFirstRadio) => {
            field.model.isDisabled = true;
            if(!field.model.isMasterRadio || onlyDisableFirstRadio) {
                field.model.control.disable();
            } else {
                field.model.radioGroupControls.forEach(x => x.disable());
            }

            this.cdRef.detectChanges();
        };

        field.api.hide = (onlyHideFirstRadio) => {
            if(field.model.isMasterRadio) {
                if(onlyHideFirstRadio) {
                    field.model.radioGroupControls[0].setValue({hide: true});
                } else {
                    for (const control of field.model.radioGroupControls) {
                        control.setValue({hide: true});
                    }
                }
            } else {
                field.model.control.setValue({hide: true});
            }
            field.model.isInvisible = true;
            this.cdRef.detectChanges();
        };

        field.api.show = (onlyShowFirstRadio) => {
            if(field.model.isMasterRadio) {
                if(onlyShowFirstRadio) {
                    field.model.radioGroupControls[0].setValue({hide: false});
                } else {
                    for (const control of field.model.radioGroupControls) {
                        control.setValue({hide: false});
                    }
                }
            } else {
                field.model.control.setValue({hide: false});
            }
            field.model.isInvisible = false;
            this.cdRef.detectChanges();
        };

        field.api.setValue = (scriptValue, executeOnChange) => {
            const parsedValue: number = this.parseRadioButtonScriptValue(scriptValue);

            field.model.masterRadioControl.setValue(parsedValue);
            field.model.masterRadioControl.markAsDirty();
            field.model.masterRadioControl.updateValueAndValidity();

            if (executeOnChange) {
                this.executeOnChange();
            }

            this.cdRef.detectChanges();
        };
    }

    private parseRadioButtonScriptValue(value: string|number): number {
        if (value == void 0) {
            console.warn("Value should not be empty");
            return 0;
        }

        value = Number(value);
        if (isNaN(value) || this.field.model.fields.length <= value) {
            console.warn("Value is not allowed for a radiobutton group --> ", value);
            return 0;
        }

        return value;
    }

    private initWorkflowEventScripts() {
        // in workflows the radiobutton script is appended to the radiobutton group
        if (this.field.model.groupInternal) {
            const parentGroupField: Field = this.formhelper.getFieldByInternal(this.field.model.groupInternal);

            if (parentGroupField?.eventScripts?.onChange) {
                this.field.eventScripts = {...this.field.eventScripts, onChange: parentGroupField.eventScripts.onChange};
            }
        }
    }

    executeOnChange(): void {
        const masterRadioField: Field = this.formhelper.getFieldByInternal(this.field.model.masterRadioInternal);

        if (masterRadioField?.eventScripts?.onChange && typeof masterRadioField.eventScripts.onChange == "function") {
            masterRadioField.eventScripts.onChange(this.formhelper, this.formhelper.globals, this.clientScriptService.getGlobalScriptingStorage(), masterRadioField);
        }
    }
}
