import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

angular.module("eob.framework").directive("eobModalExternalTrayObjectTypes", EobModalExternalTrayObjectTypes);

EobModalExternalTrayObjectTypes.$inject = ["messageService"];

function EobModalExternalTrayObjectTypes(MessageService) {
    return {
        restrict: "E",
        scope: {
            deferred: "=",
            types: "="
        },
        link(scope, ev) {
            scope.chooseObjectType = function(objectType) {
                scope.close(objectType);
            };

            // close the dialog once it is canceled
            scope.close = (objectType) => MessageService.broadcast(ModalEvents.DESTROY, objectType);
        },
        template: require("!raw-loader!./eob.modal.external.tray.object.types.html")
    };
}
