<div class="progress-container">
    <div id="loadAnimation" class="osProgressbar invisible">
        <div class="osloader"></div>
        <div class="invisible percentLoader">
            <div id="mask">
                <span class="percentView"></span>
                <div id="fill"></div>
            </div>
        </div>
    </div>
    <div class="status-text" *ngIf="binding">{{ binding.statusText }}</div>
</div>
<div class="button-container">
    <button class="cancelButton" (click)="closeProgressDialog()">{{'modal.button.cancel' | ngTranslate}}</button>
</div>