/**
 * Contain and handle the featureset from the backend.
 * Used as singleton in the EnvironmentService.
 */
export class FeatureSet {
    private featureList: Set<string> = new Set();

    constructor(featureTree: any = {}) {
        this.flatten(featureTree);
    }

    /**
     * Whether the featureset contains the given key.
     *
     * @param key{string} - The key of the feature.
     */
    contains(key: string): boolean {
        return this.featureList.has(key.toLowerCase());
    }

    /**
     * Traverse the given feature tree and add the feature keys to the flat 'featureList' for faster accessability.
     *
     * @param featureTree {object} - An hierarchical structure of features.
     * @param keyPart {string|undefined} - The current property key part needed for recursion.
     */
    private flatten(featureTree: any, keyPart: string = ""): void {
        for (const [property, feature] of Object.entries(featureTree)) {
            const featureKey: string = `${keyPart}${keyPart === "" ? "" : "."}${property}`;

            this.featureList.add(featureKey.toLowerCase());

            if (Object.keys(feature).length > 0) {
                this.flatten(feature, featureKey);
            }
        }
    }
}
