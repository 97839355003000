import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {ObjectType, ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {OfflineAvailability} from "INTERFACES_PATH/offline-cache.interface";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {TodoEnvObjectInEnvironmentService} from "INTERFACES_PATH/any.types";
import {Observable} from "rxjs";


export enum ContextType {
    STARTABLE = "startable",
    PROCESSES = "processes",
    EXTERNALTRAY = "externaltray",
    WORKFLOW = "workflow",
    DESKTOP = "desktop",
    ABO = "abo",
    SUBSCRIPTIONOBJECTS = "subscriptionObjects",
    REVISIT = "revisit",
    OBJECTREFERENCES = "hitlist.object-references",
    VARIANTS = "variants",
    WFFILEAREAWORKFILES = "wfFileAreaWorkFiles",
    WFFILEAREAINFOFILES = "wfFileAreaInfoFiles",
    CIRCULATIONSLIP = "circulationSlip",
    FOLDER = "folder",
    FOLDERTREE = "folderTree",
    EMPTYSPACEINHITLIST = "emptySpaceInHitlist",
}

/**
 * A dms object in a hitlist on which the user can call the context-menu.
 *
 */
export interface ContextMenuItem {
    id: string;
    osid: string;
    objectTypeId: string;
}

/**
 * An item in the external tray on which the user can call the context-menu.
 *
 */
export interface ExternalTrayItem {
    displayTitle: string,
    fileNames: string[],
    filePaths: string[],
    groupKey: string,
    icon: string,
    indexData: {[key: string]: any}, //???
    mainTypes: string[],
    objectTypeId: string,
    parentObjectId: string,
    parentObjectTypeId: string,
    trayItemType: string
}

/**
 * A running or startable workflow on which the user can call the context-menu.
 *
 */
export interface WorkflowContextMenuItem extends ContextMenuItem {
    workflowId: string;
    title: string;
    personalized: string;
    processName: string;
    activityName: string;
    displayTitle: string;
    iconId: number;
    guid: number;
    read: boolean;
    model: {
        wfFile: {
            movable: boolean;
            deletable: boolean;
        };
        internal: string;
    };
}

/**
 * A circulation slip template on which the user can call the context-menu.
 *
 */
export interface CirculationSlipTemplateContextMenuItem extends ContextMenuItem {
    model: {
        isPublic: boolean;
    };
}

/**
 * An abo, revisit or subscription object on which the user can call the context-menu.
 *
 */
export interface InboxContextMenuItem extends ContextMenuItem {
    confirmationType: string;
    guid: string;
    workflowId: string;
    eventDate?: string;
    model: {
        confirmationType: string;
        confirmed: boolean;
        read: boolean;
        osid: string;
        eventDate: string;
        guid: string;
        aboGroup: string;
        id: string;
    };
}

/**
 * An object in the object references hitlist on which the user can call the context-menu.
 *
 */
export interface ObjectReferenceContextMenuItem extends ContextMenuItem {
    referenceType: string;
    referenceComment: string;
}

/**
 * Single context-menu action which can be clicked or touched to execute the callback.
 *
 */
export interface ContextMenuAction {
    /* Title of the context-menu action e.g. "Show indexdata" */
    title: string;
    /* Icon displayed to the left side of the title */
    icon: string;
    /* Parameters either for the callback or for corresponding submenu actions e.g. "Send e-mail" */
    params: unknown[];
    /* Type of the context-menu. Can be either "main" or "sub" */
    type: string;
    /* Callback which will be executed once the user clicks/touches a context-menu action.
    Optional because not available on context-menu actions which store corresponding submenu actions. */
    callback?: any;
}

/**
 * Data provided by broadcasting the DISPLAY_CTX_ACTIONS event.
 *
 */
export interface DisplayContextMenuActionsData<T> {
    /* Selected dms documents or context specific context-menu actions */
    items: T[];
    /* Mouse event from the right click or Touch event from the longpress */
    event: Event;
    /* Additional context data */
    contextData: ContextData;
}

/**
 * Single selection context menu.
 *
 */
export interface ContextMenu {
    /* Single selection context-menu actions or submenu actions */
    actions: ContextMenuAction[];
    /* Object type of the selected item */
    typeDef: ObjectType;
    /* Env object from the environment service */
    env: TodoEnvObjectInEnvironmentService;
    /* Current context */
    context: string;
    /* Osid of the parent register or folder (selected tree item in tree view) */
    parentId: string;
    /* Document model of the selected item */
    docModel: DmsDocumentModel;
    /* Selected dms document */
    dmsDocument: DmsDocument;
    /* Object type configuration of the selected dms document */
    typeConfig: ObjectTypeConfig;
    /* Offline availability of all parts of the selected dms object (indexdata, original content, preview content)  */
    offlineAvailability?: OfflineAvailability;
}

/**
 * Interface which is implemented by context specific services to provide context menu actions
 *
 */
export interface ContextMenuActionsProvider {
    /* Returns single selection context menu actions according to the current context */
    getSingleSelectionContextMenuActions(contextMenuItem: unknown, contextData: ContextData): Observable<ContextMenuAction[]>;
    /* Returns multiselection context menu actions according to the current context */
    getMultiselectionContextMenuActions(contextMenuItems: unknown[], contextData: ContextData): Observable<ContextMenuAction[]>;
}
