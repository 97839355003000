import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {GridCellComponent} from "MODULES_PATH/grid/components/grid-cells/grid-cell/grid-cell.component";
import {IconCellComponent} from "MODULES_PATH/grid/components/grid-cells/icon-cell/icon-cell.component";
import {GridHeaderCellComponent} from "MODULES_PATH/grid/components/grid-header-cells/grid-header-cell/grid-header-cell.component";
import {GridWrapperComponent} from "MODULES_PATH/grid/grid-wrapper.component";
import {AgGridDirective} from "MODULES_PATH/grid/directives/ag-grid.directive";
import {SharedModule} from "SHARED_PATH/shared.module";
import {AgGridModule} from "ag-grid-angular";
import {Type} from "@angular/core";
import {
    IconHeaderCellComponent,
    IconHeaderCellContentComponent
} from "MODULES_PATH/grid/components/grid-header-cells/icon-header-cell/icon-header-cell-content.component";
import "ag-grid-enterprise";
import {FormsModule} from "@angular/forms";
import {CellHostDirective} from "MODULES_PATH/grid/directives/cell-host.directive";
import {AutocompleteModule} from "MODULES_PATH/autocomplete/autocomplete.module";
import {PhoneCellComponent} from "MODULES_PATH/grid/components/grid-cells/phone-cell/phone-cell.component";
import {EobFormGridComponent} from "MODULES_PATH/grid/components/eob-form-grid/eob-form-grid.component";
import {FormModule} from "MODULES_PATH/form/form.module";
import {GridHeaderTooltipComponent} from "MODULES_PATH/grid/components/grid-header-cells/grid-header-tooltip/grid-header-tooltip.component";

const components: Array<Type<unknown>> = [
    GridHeaderTooltipComponent,
    GridCellComponent,
    GridHeaderCellComponent,
    GridWrapperComponent,
    EobFormGridComponent
];

const gridComponents: Array<Type<unknown>> = [
    IconCellComponent,
    IconHeaderCellContentComponent,
    IconHeaderCellComponent,
    PhoneCellComponent
];

const directives: any = [
    AgGridDirective,
    CellHostDirective
];

@NgModule({
    imports: [
        CommonModule, FormsModule, SharedModule, AutocompleteModule, AgGridModule.withComponents([...gridComponents]), FormModule
    ],
    exports: [...directives, ...components],
    declarations: [...components, ...directives, ...gridComponents],
    entryComponents: [GridWrapperComponent, GridCellComponent, EobFormGridComponent, GridHeaderTooltipComponent]
})
export class GridModule {
}
