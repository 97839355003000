import {Component, OnInit} from "@angular/core";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";

@Component({
    selector: "eob-dashlet-default-page",
    templateUrl: "./eob-dashlet-default-page.component.html",
    styleUrls: ["./eob-dashlet-default-page.component.scss"]
})
export class EobDashletDefaultPageComponent implements OnInit {
    language: string = "";

    constructor(private asIniService: AsIniService) {
    }

    ngOnInit(): void {
        this.language = this.asIniService.getGuiLanguage();

        switch (this.language) {
            case "de":
                this.language = "de";
                break;
            default:
                this.language = "en";
        }
    }
}