/* eslint-disable @typescript-eslint/unbound-method */
// eslint-disable-next-line no-undef
import Timeout = NodeJS.Timeout;
import {Component, Inject} from "@angular/core";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {
    TodoCacheManagerService,
    TodoDocumentViewer,
    TodoEnvironmentService,
    TodoStateHistoryManager
} from "INTERFACES_PATH/any.types";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {ChangeDetectorRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {EobDashletContainerComponent} from "../eob-dashlet-container/eob-dashlet-container.component";
import {PopupViewerWindow} from "MODULES_PATH/dashlet/interfaces/popup-viewer-window.interface";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";
import {ThemeService} from "MODULES_PATH/theme/theme.service";
import {Subscription} from "rxjs";

declare let window: PopupViewerWindow;

@Component({
    selector: "eob-attached-dashlet-container",
    templateUrl: "./eob-attached-dashlet-container.component.html",
    styleUrls: ["./eob-attached-dashlet-container.component.scss"]
})
export class EobAttachedDashletContainerComponent implements OnInit, OnDestroy {
    @ViewChild(EobDashletContainerComponent, {static: false}) eobDashletContainerComponent: EobDashletContainerComponent;

    private readonly gListenerGuid: string = this.cacheManagerService.dmsDocuments.attachListener([], this.onDmsDocumentsChanged.bind(this));

    private mutationObserver: MutationObserver;

    private popupViewer: PopupViewerWindow;
    private popupOpen: boolean;
    private isPopUpRefresh: boolean;
    private subscriptions: Subscription = new Subscription();

    // eslint-disable-next-line max-params
    constructor(private messageService: MessageService,
                private viewerService: ViewerService,
                private changeDetection: ChangeDetectorRef,
                private themeService: ThemeService,
                @Inject("$rootScope") private $rootScope: ng.IRootScopeService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("documentViewer") private documentViewer: TodoDocumentViewer,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
    ) {
        this.subscriptions.add(this.themeService.getThemeChanges().subscribe(name => {
            if (this.popupViewer != void 0) {
                this.popupViewer.onThemeChanged(name);
            }
        }));
    }

    ngOnInit(): void {
        this.subscriptions.add(this.messageService.subscribe(ViewerEvent.SHOW_VIEWER, (isShow: boolean) => this.showViewer(isShow)));
        this.subscriptions.add(this.messageService.subscribe(ViewerEvent.UPDATE_VIEWER, ({osid, dmsDocument, selectedOsids}) => this.updateViewer(osid, dmsDocument, selectedOsids)));
        this.subscriptions.add(this.messageService.subscribe(ViewerEvent.REFRESH_VIEWER, ({osid, keepCache, dashletIds}) => this.refreshViewer(osid, dashletIds, keepCache)));
        this.subscriptions.add(this.messageService.subscribe(ViewerEvent.CLEAR_VIEWER, () => this.clearViewer()));
        this.subscriptions.add(this.messageService.subscribe(ViewerEvent.SEND_DASHLET_EVENT, ({dashletId, eventKey, data}) => this.sendDashletEvent(dashletId, eventKey, data)));
        this.subscriptions.add(this.messageService.subscribe(ViewerEvent.DESTROY_DETACHED_VIEWER, () => this.popupViewer?.close()));

        this.bindResize();
    }

    private showViewer(isShow: boolean): void {
        // the popup viewer always stays "open" while detached
        if (this.popupOpen) {
            return;
        }

        this.messageService.broadcast("open.attached.viewer", isShow);
    }

    private updateViewer(osid?: number, dmsDocument?: DmsDocument, selectedOsids?: number[]): void {
        this.cacheManagerService.dmsDocuments.updateListener(this.gListenerGuid, selectedOsids || [osid]);

        if (this.popupOpen) {
            void this.popupViewer.updateViewer(osid, this.viewerService.getSearchKey(), undefined, selectedOsids);
            return;
        }

        this.eobDashletContainerComponent.updateViewer(osid, dmsDocument, undefined, selectedOsids);
    }

    private refreshViewer(osid?: number, dashletIds?: string[], keepCache?: boolean): void {
        if (this.popupOpen) {
            this.popupViewer.refreshViewer(osid, dashletIds, keepCache);
            return;
        }

        this.eobDashletContainerComponent.refreshViewer(osid, dashletIds, keepCache);
    }

    private clearViewer(): void {
        this.cacheManagerService.dmsDocuments.updateListener(this.gListenerGuid, []);

        if (this.popupOpen) {
            this.popupViewer.clearViewer();
            return;
        }

        this.eobDashletContainerComponent.clearViewer();
    }

    private sendDashletEvent(dashletId: string, eventKey: string, data: unknown): void {
        if (this.popupOpen) {
            void this.popupViewer.sendDashletEvent(dashletId, eventKey, data);
            return;
        }

        this.eobDashletContainerComponent.sendDashletEvent(dashletId, eventKey, data);
    }

    detachViewer(): void {
        let viewerHtml: string = window.location.href.includes("debug.html") ? "viewer.debug.html" : "index.html#/detachedViewer";
        const target: string = window.electron ? `popupWindow${window.webviewId}` : "popupWindow";

        if (window.electron) {
            viewerHtml += `?openerId=${window.electron.getCurrentWebContentsId() as string}`;
        }

        this.popupOpen = true;
        this.openPopupViewer(viewerHtml, target);

        this.unbindResize();
        this.onPopupReady(this.eobDashletContainerComponent.currentId, this.eobDashletContainerComponent.activeDashletId, this.eobDashletContainerComponent.selectedOsids);

        this.messageService.broadcast("open.attached.viewer", false);
        this.eobDashletContainerComponent.clearViewer();
    }

    private openPopupViewer(viewerHtml: string, target: string): void {
        this.popupViewer = window.open(viewerHtml, target, "scrollbars = 1, resizable=1, width=600, height=800, scrollbars=yes") as PopupViewerWindow;

        // overwritten in electron
        this.popupViewer.addCustomEventListener = (eventName: string, cbFn) => {
            this.popupViewer.addEventListener(eventName, cbFn);
        };

        if (window.electron) {
            window.electron.popupMiddleware.addElectronMiddleware(this.popupViewer, PopupViewerWindow.ViewerFns);
        }
    }

    private reattachViewer(activeOsid?: number, activeDashlet?: string, activeSelectedOsids?: number[]): void {
        if (!this.popupOpen) {
            return;
        }

        this.popupViewer?.close();
        this.popupOpen = false;
        this.popupViewer = null;

        if (!(this.stateHistoryManager.getCurrentStateData()?.data?.type == "dashboard")) {
            this.messageService.broadcast("open.attached.viewer", true);
        }

        this.bindResize();

        this.eobDashletContainerComponent.updateViewer(activeOsid, undefined, activeDashlet, activeSelectedOsids);
        this.eobDashletContainerComponent.openDashlet(activeDashlet);
        this.changeDetection.detectChanges();
    }

    private onPopupReady(osid: number, activeDashletId: string, selectedOsids: number[]): void {
        let timesTried: number = 0;
        const interval: Timeout = setInterval(() => {
            if (timesTried >= 4) {
                console.error("Could not create external viewer --> reattach it!");
                this.popupOpen = true;
                clearInterval(interval);
                this.reattachViewer();
                return;
            }

            if (this.popupViewer != void 0 && !this.popupViewer.closed) {
                this.addEventListeners(osid, activeDashletId, selectedOsids);
                clearInterval(interval);
            } else {
                timesTried++;
                console.log("Could not find popup, retry ", timesTried);
            }
        }, 250);
    }

    /** adds event listeners to the popup viewer */
    private addEventListeners(osid: number, activeDashletId: string, selectedOsids: number[]): void {
        if (!this.popupViewer.addCustomEventListener) {
            this.popupViewer.addCustomEventListener = (eventName: string, cbFn) => {
                this.popupViewer.addEventListener(eventName, cbFn);
            };
        }

        this.popupViewer.addCustomEventListener("onPopUpReady", () => {
            this.popupViewer.setDashlets(this.environmentService.env.dashlets);
            this.popupViewer.init(activeDashletId, osid, this.viewerService.getSearchKey(), selectedOsids);
        });

        this.popupViewer.addCustomEventListener("beforeunload", (e) => {
            if (this.isPopUpRefresh) {
                this.isPopUpRefresh = false;
                return;
            }

            if ((e.currentTarget as PopupViewerWindow).details) {
                const activeViewerDashlet: string = ((e.currentTarget as PopupViewerWindow).details?.activeViewerDashlet),
                    activeOsid: number = ((e.currentTarget as PopupViewerWindow).details?.activeOsid),
                    activeSelectedOsids: number[] = (e.currentTarget as PopupViewerWindow).details?.activeSelectedOsids;
                this.reattachViewer(activeOsid, activeViewerDashlet, activeSelectedOsids);
            } else {
                this.reattachViewer(osid, activeDashletId, selectedOsids);
            }
        });

        this.popupViewer.addCustomEventListener("keydown", (e) => {
            if (e.key === "F5") {
                this.isPopUpRefresh = true;
            } else {
                return;
            }

            const activeViewerDashlet: string = (e.currentTarget as PopupViewerWindow).details.activeViewerDashlet,
                activeOsid: number = (e.currentTarget as PopupViewerWindow).details.activeOsid,
                activeSelectedOsids: number[] = (e.currentTarget as PopupViewerWindow).details.activeSelectedOsids;
            this.onPopupReady(activeOsid, activeViewerDashlet, activeSelectedOsids);
        });

        this.popupViewer.addCustomEventListener(ViewerEvent.UPDATE_DMS_CACHE, (e) => {
            this.cacheManagerService.dmsDocuments.add(...e.detail);
        });

        PopupViewerWindow.RootscopeViewerEvents.forEach(viewerEvent => {
            this.popupViewer.addCustomEventListener(viewerEvent, (event: CustomEvent) => {
                this.$rootScope.$broadcast(viewerEvent, event.detail);
            });
        });

        PopupViewerWindow.ViewerEvents.forEach(viewerEvent => {
            this.popupViewer.addCustomEventListener(viewerEvent, (event: CustomEvent) => {
                this.messageService.broadcast(viewerEvent, event.detail);
            });
        });
    }

    private bindResize(): void {
        this.mutationObserver = new MutationObserver(mutationsList => {
            if (mutationsList.find(x => x.type == "attributes" && x.attributeName == "style")) {
                this.viewerService.getContentViewer().resizeCallback();
            }
        });
        const viewerComponent: Element = document.querySelector("div.viewer-component");
        this.mutationObserver.observe(viewerComponent, {attributes: true});
    }

    private unbindResize(): void {
        this.mutationObserver.disconnect();
    }

    /** Mirror changes to the active dmsDocument to the detached cache. */
    private onDmsDocumentsChanged(osids: string[]): void {
        if (this.popupViewer != void 0) {
            const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(osids[0]);
            this.popupViewer.updateDmsDocumentCache(osids[0], dmsDocument);
        }
    }

    ngOnDestroy(): void {
        if (this.popupViewer != void 0) {
            this.popupViewer.close();
        }

        this.cacheManagerService.dmsDocuments.detachListeners(this.gListenerGuid);
        this.subscriptions.unsubscribe();
    }
}
