(function() {
    
    angular.module("eob.framework").directive("daysFromNow", DaysFromNow);

    DaysFromNow.$inject = ["$filter", "dvToolService"];

    function DaysFromNow($filter, DvToolService) {
        return {
            restrict: "AE",
            template: require("!raw-loader!./dv.days.from.now.html"),
            link(scope, element, attributes) {

                setDaysFromNow();

                function setDaysFromNow() {
                    let daysLeft = DvToolService.getDeepObjectByString(scope, attributes.daysLeft) || 0;
                    let daysLeftClass, daysLeftStr;

                    if (daysLeft < 0) {
                        daysLeftClass = "pastdue";
                        daysLeftStr = "app.daysleft.pastdue";
                    } else if (daysLeft === 0) {
                        daysLeftClass = "today";
                        daysLeftStr = "app.daysleft.today";
                    } else if (daysLeft === 1) {
                        daysLeftClass = "tomorrow";
                        daysLeftStr = "app.daysleft.tomorrow";
                    } else if (daysLeft < 8 && daysLeft > 1) {
                        daysLeftClass = "soon";
                        daysLeftStr = "app.daysleft.soon";
                    } else {
                        return;
                    }
                    let elem = element.find("span");
                    if (elem.length > 0 && elem[0].classList.contains("dfn")) {
                        elem.addClass(daysLeftClass);
                    }
                    scope.daysLeftStr = $filter("translate")(daysLeftStr);
                }
            }
        };
    }
})();
