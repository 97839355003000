(function () {
    

    const Dropzone = require("dropzone");

    angular.module("dropzone", []).directive("dropzone", () => {
        return function (scope, element, attrs) {
            let config, dropzone;

            config = scope[attrs.dropzone];

            dropzone = new Dropzone.Dropzone(element[0], config.options);

            // bind the given event handlers
            angular.forEach(config.eventHandlers, (handler, event) => {
                dropzone.on(event, handler);
            });
        };
    });
})();