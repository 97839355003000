import * as angular from "angular";
import {VirtualGridApi} from "./eob.virtual.grid.api.srv";
import {VirtualGridColumnController} from "./eob.virtual.grid.column.srv";
import {VirtualGridRowController} from "./eob.virtual.grid.row.srv";
import {ProfileService} from "CORE_PATH/authentication/util/profile.service";

/**
 * wrapping class to create instances of the virtual grid
 */
export class VirtualGridService {

    static $inject: string[] = ["formLayoutService", "touchHandlerService", "layoutManagerService", "profileService"];

    private injections: any = {};

    constructor(protected formLayoutService: any, protected touchHandlerService: any, protected layoutManagerService: any, protected profileService: ProfileService) {
        this.injections.FormLayoutService = this.formLayoutService;
        this.injections.TouchHandlerService = this.touchHandlerService;
        this.injections.LayoutManagerService = this.layoutManagerService;
        this.injections.ProfileService = this.profileService; // used by VirtualGridRowController
    }

    /**
     * returns an instance of the virtual grid
     *
     * @param config
     * @returns {any}
     */
    getVirtualGridInstance(config: any): any {
        return new VirtualGridService.VirtualGrid(config, this.injections);
    }
}

export namespace VirtualGridService {
    /**
     * Returns an instance of a virtual grid
     */
    export class VirtualGrid {

        private api: VirtualGridApi;
        private columnController: VirtualGridColumnController;
        private rowController: VirtualGridRowController;

        private onRowRightClick: any;
        private onRowDoubleClick: any;
        private onRowClick: any;

        private isTouch: boolean;

        private initDone: boolean;
        private showHeader: boolean;

        private lastTop: number;
        private scrollPosition: number;

        private rows: any[];
        private renderedRows: any[];

        private sortComperator: any;
        private sortDirection: string;
        private useCheckboxSelection: boolean;

        private rowHeight = 24;
        private headerRowHeight = 24;

        private childNodesKey: string;

        private visibleRowIndices: number[];
        private renderedRowThreshold: number;
        private renderedRowCount: number;

        private listElement: JQuery;
        private listHeader: JQuery;
        private headerWrapper: JQuery;
        private gridWrapper: JQuery;

        constructor(protected config: any, protected injections: any) {

            this.showHeader = this.config.showHeader == void 0 ? false : this.config.showHeader;

            this.rowHeight = this.config.rowHeight == void 0 ? this.rowHeight : this.config.rowHeight;
            this.headerRowHeight = this.config.headerRowHeight == void 0 ? this.headerRowHeight : this.config.headerRowHeight;

            this.initDone = false;
            this.rows = this.config.rows.slice();

            this.visibleRowIndices = [];

            this.scrollPosition = 0;
            this.lastTop = 0;

            this.childNodesKey = this.config.childNodesKey != void 0 && this.config.childNodesKey !== "" ? this.config.childNodesKey : "nodes";

            this.sortComperator = typeof(this.config.sortComperator) == "function" ? this.config.sortComperator : VirtualGrid.defaultSortComperator.bind(this);
            this.sortDirection = "asc";
            this.useCheckboxSelection = this.config.useCheckboxSelection;

            this.renderedRows = [];

            // the complete amount of rendered rows
            this.renderedRowCount = 100;

            // the number of rows outside the visible area needed before redrawing the rows
            // once the user scrolls and we need to shift rows from top to bottom, we start shifting
            // when the difference in pixel between the last rendered invisible row and the last visible row
            // exceeds threshold x row height
            this.renderedRowThreshold = 10;

            // callbacks
            this.onRowRightClick = this.config.onRowRightClick;
            this.onRowDoubleClick = this.config.onRowDoubleClick;
            this.onRowClick = this.config.onRowClick;

            this.isTouch = this.injections.LayoutManagerService.isTouchLayoutActive();

            this.api = new VirtualGridApi(this);
            this.columnController = new VirtualGridColumnController(this, this.config);
            this.rowController = new VirtualGridRowController(this, this.config);

            this.headerWrapper = angular.element("<div class='header-wrapper'></div>");
            this.listHeader = angular.element("<div class='tree-addon-header'></div>");
            this.gridWrapper = angular.element("<div class='grid-wrapper'></div>");
            this.listElement = angular.element("<ul class='tree-addon-list default-tree'></ul>");

            if (this.initDone == false) {
                this.initGrid();

                this.resetGridProperties();
                this.updateConfigProperties();
                this.api.updateGridContent(this.config);

                if (this.config.onGridReady && typeof(this.config.onGridReady) == "function" && !this.initDone) {
                    this.config.onGridReady(this);
                }

                this.initDone = true;
            }
        }

        /**
         * update the configuration of the grid once a new gridconfig is set
         */
        updateConfigProperties = (): void => {

            this.rowController.updateConfigProperties(this.config);
            this.columnController.updateConfigProperties(this.config);
            this.api.updateConfigProperties(this.config);

            this.rows = this.config.rows.slice();

            this.childNodesKey = this.config.childNodesKey != void 0 && this.config.childNodesKey !== "" ? this.config.childNodesKey : "nodes";
        };

        /**
         * destroyes the grid and it's content
         * releases listeners and clears all array to prevent memory leaks
         */
        destroy = (): void => {
            // remove scroll handler
            this.config.scrollContainer.unbind();
            this.gridWrapper.unbind();

            this.gridWrapper[0].removeEventListener("scroll", this.onScroll);

            for (const renderedRow of this.renderedRows) {
                if (renderedRow.cells != void 0) {
                    for (const j in renderedRow.cells) {
                        let cell: any = renderedRow.cells[j];

                        /* eslint-disable */
                        cell.checkboxNode != void 0 && cell.checkboxNode.unbind() && cell.checkboxNode.remove();
                        cell.treeNode != void 0 && cell.treeNode.unbind() && cell.treeNode.remove();
                        cell.treeIconNode != void 0 && cell.treeIconNode.unbind() && cell.treeIconNode.remove();
                        cell.textNode != void 0 && cell.textNode.unbind() && cell.textNode.remove();
                        cell.iconNode != void 0 && cell.iconNode.unbind() && cell.iconNode.remove();
                        cell.cellNode != void 0 && cell.cellNode.unbind() && cell.cellNode.remove();
                        /* eslint-enable */

                        cell = undefined;
                    }
                }

                // remove click handler
                if (renderedRow.listNode != void 0) {
                    renderedRow.listNode.unbind();
                    renderedRow.listNode.remove();
                }
            }

            // release memory by setting arrays to undefined
            // these arrays might hold a lot of information about locations etc, so clear them
            this.columnController.destroy();
            this.config.scrollContainer.empty();
        };

        /**
         * calculates the height of the grid according to the amount of visible rows up to a maximum of aound 12 rows in height
         */
        calculateGridHeight = (): void => {
            this.scrollPosition = this.gridWrapper.scrollTop() ;

            const currentListHeight: number = this.listElement.height() ;
            const newListHeight: number = this.rowHeight * this.visibleRowIndices.length;

            const listHeight: number = this.listElement.height() ;

            if (this.config.scrollContainer.height() as number + this.scrollPosition == listHeight && newListHeight > currentListHeight) {
                let diff: number = newListHeight - currentListHeight;
                const maxDiff: number = this.config.scrollContainer.height() - this.rowHeight;
                if (diff > maxDiff) {
                    diff = maxDiff;
                }

                this.scrollPosition += diff;
            }

            this.listElement.height(newListHeight + 8);
            this.gridWrapper.scrollTop(this.scrollPosition);
        };

        /**
         * flatten the recursive structure of the tree and transform it to a list
         *
         * @param nodes - recursive tree structure or just a plain list
         */
        flatten = (nodes: any[]): any[] => {

            let listFragment: any[] = [];

            for (const node of nodes) {

                listFragment.push(node);

                if (node[this.childNodesKey]) {

                    const childListFragment: any[] = this.flatten(node[this.childNodesKey]);

                    listFragment = listFragment.concat(childListFragment);
                }
            }

            return listFragment;
        };

        /**
         * resets the grid to the default settings
         */
        private resetGridProperties(): void {
            this.rows = [];
            this.scrollPosition = 0;
            this.lastTop = 0;

            this.gridWrapper.scrollLeft(0);
            this.gridWrapper.scrollTop(0);
        }

        /**
         * the default sorting function
         * only alphabetic sorting by name
         */
        private static defaultSortComperator(rowA: any, rowB: any): number {
            return rowA.name > rowB.name ? 1 : rowA.name < rowB.name ? -1 : 0;
        }

        /**
         * initilize the grid instance
         * create the rows
         * bind click handler
         */
        private initGrid(): void {

            this.headerWrapper.append(this.listHeader);
            this.gridWrapper.append(this.listElement);

            // this.gridWrapper[0].addEventListener("scroll", onScroll, {passive: true});
            this.gridWrapper.bind("scroll", this.onScroll);
            if (this.showHeader) {
                this.config.scrollContainer.append(this.headerWrapper);
                this.headerWrapper.height(this.headerRowHeight);
                this.listHeader.height(this.headerRowHeight);

                this.gridWrapper.css({"margin-top": `${this.headerRowHeight}px`});
            }

            this.config.scrollContainer.append(this.gridWrapper);

            for (let i = 0; i < this.renderedRowCount; i++) {
                const listNode: JQuery = angular.element("<li class=\"tree-list-node\"></li>");

                listNode.height(this.rowHeight);

                if (typeof(this.onRowClick) == "function") {
                    listNode.bind("click", this.onClick);
                }

                if (typeof(this.onRowDoubleClick) == "function") {
                    listNode.bind("dblclick", this.onDoubleClick);
                }

                if (typeof(this.onRowRightClick) == "function") {
                    listNode.bind("contextmenu", this.onRightClick);
                }

                if (this.isTouch) {
                    this.injections.TouchHandlerService.bindTouchEvents(listNode);
                }

                const item: any = {
                    index: i,
                    cells: [],
                    listNode,
                    visible: true,
                    top: this.rowHeight * i
                };

                this.renderedRows.push(item);

                listNode.css({
                    height: this.rowHeight,
                    top: item.top,
                });

                this.listElement.append(listNode);
            }

            this.config.scrollContainer.addClass("virtual-grid");
        }

        /**
         * onClick callback
         *
         * @param event - the click event
         */
        private onClick = (event: JQueryEventObject): void => {

            const rowElement: any = angular.element(event.target).closest(".tree-list-node");
            const index: any = rowElement.attr("number");
            const row: any = this.rows[index as number];

            if (!row.isSelectable) {
                return;
            }

            if (this.isTouch) {
                const touchEvent: JQueryEventObject | void = this.injections.TouchHandlerService.getTouchEvent();

                if (touchEvent != void 0) {
                    event = touchEvent as JQueryEventObject;
                }
            }

            const isCheckboxSelection: boolean = angular.element(event.target).hasClass("tree-checkbox-icon");
            const useCtrl: boolean = event.ctrlKey,
                useShift: boolean = event.shiftKey;

            this.api.select(row, useCtrl, useShift, isCheckboxSelection);

            if (this.onRowClick != void 0) {
                this.onRowClick(row, event);
            }
        };

        /**
         * onDoubleClick callback
         *
         * @param event - the click event
         */
        private onDoubleClick = (event: JQueryEventObject): void => {

            const rowElement: any = angular.element(event.target).closest(".tree-list-node");
            const index: any = rowElement.attr("number");

            const row: any = this.rows[index as number];

            if (!row.isSelectable || angular.element(event.target).hasClass("tree-node-icon")) {
                return;
            }

            this.api.select(row);

            if (this.onRowDoubleClick != void 0) {
                this.onRowDoubleClick({rowData: row, api: this.api});
            }
        };

        /**
         * onRightClick callback
         *
         * @param event - the click event
         */
        private onRightClick = (event: JQueryEventObject): void => {
            const rowElement: any = angular.element(event.target).closest(".tree-list-node");
            const index: any = rowElement.attr("number");
            const row: any = this.rows[index as number];

            if (!row || !row.isSelectable) {
                return;
            }

            if (this.onRowRightClick != void 0) {
                this.onRowRightClick(row, event.originalEvent, rowElement);
            }

            event.preventDefault();
        };

        /**
         * on scroll callback
         */
        private onScroll = (): void => {
            if (this.showHeader) {
                this.headerWrapper.scrollLeft(this.gridWrapper.scrollLeft() );
            }

            this.rearrangeListnodes();
            this.rowController.renderRows();
        };

        /**
         * Alter the order of the rendered rows when the user scrolls
         * using only the visible area to avoid giant dom nodes
         * this would impact the responsiveness of the whole browser
         *
         * at this point once the user scrolls, some rows will be scrolled outside the visible area,
         * we use these containers and adjust their top position and shift them through the rendered row array
         */
        private rearrangeListnodes(): void {
            const firstRowTop: number = this.renderedRows[0].top;
            const lastRowTop: number = this.renderedRows[this.renderedRows.length - 1].top;
            const gridWrapper: JQuery = this.gridWrapper;

            const currentTop: number = gridWrapper.scrollTop() ;
            const currentBottom: number = currentTop + (gridWrapper.height() );
            const listHeight: number = this.listElement.height() ;

            // scrolling down
            if (this.lastTop < currentTop) {

                // return if the next row is the last row
                if (lastRowTop + this.rowHeight >= listHeight) {
                    return;
                }

                if (currentBottom + (this.renderedRowThreshold * this.rowHeight) > lastRowTop) {

                    this.shiftDown(currentBottom, lastRowTop, listHeight);
                    this.rowController.reorderRenderedRows();
                }
            } else if (currentTop - (this.renderedRowThreshold * this.rowHeight) < firstRowTop) {
                this.shiftUp(currentTop, firstRowTop);
                this.rowController.reorderRenderedRows();
            }

            this.lastTop = currentTop;
        }

        /**
         * shift elements from the top of the list to the bottom until threshold * row height
         * is bigger than the top position of the last row element
         *
         * @param currentBottom - the bottom position of the list in pixel (list height + scroll top)
         * @param lastRowTop - the top position of the last rendered row in pixel
         * @param listHeight - the height of the scrollable list / container
         */
        private shiftDown(currentBottom: number, lastRowTop: number, listHeight: number): void {
            let iterations = 0;
            let nextIndex: number = this.renderedRows[this.renderedRows.length - 1].index;

            const diff: number = lastRowTop - (currentBottom + (this.renderedRowThreshold * this.rowHeight));
            let shiftIterations: number = Math.ceil(Math.abs(diff) / this.rowHeight);

            shiftIterations = shiftIterations < 5 ? 5 : shiftIterations;

            for (let i = 0; i < shiftIterations; i++) {

                if (lastRowTop + this.rowHeight >= listHeight) {
                    break;
                }

                const nextVisibleIndex: any = this.visibleRowIndices[this.visibleRowIndices.indexOf(nextIndex) + 1];

                if (nextVisibleIndex == void 0) {
                    break;
                }

                const currentRenderedRowIndex: number = iterations % this.renderedRowCount;

                const shiftable: any = this.renderedRows[currentRenderedRowIndex];

                shiftable.top = lastRowTop + this.rowHeight;
                shiftable.index = nextVisibleIndex;

                shiftable.listNode.css({top: shiftable.top});

                lastRowTop = shiftable.top;
                nextIndex = nextVisibleIndex;
                iterations++;
            }
        }

        /**
         * shift elements from the bottom of the list to the top until threshold x row height
         * is bigger than the top position of the first row element
         *
         * @param currentTop - the top position of the list in pixel
         * @param firstRowTop - the top position of the first rendered row in pixel
         */
        private shiftUp(currentTop: number, firstRowTop: number): void {
            let iterations = 0;
            let nextIndex: number = this.renderedRows[0].index;

            const diff: number = firstRowTop - (currentTop - (this.renderedRowThreshold * this.rowHeight));
            let shiftIterations: number = Math.ceil(Math.abs(diff) / this.rowHeight);

            shiftIterations = shiftIterations < 5 ? 5 : shiftIterations;

            for (let i = 0; i < shiftIterations; i++) {

                if (firstRowTop - this.rowHeight < 0) {
                    break;
                }

                const nextVisibleIndex: any = this.visibleRowIndices[this.visibleRowIndices.indexOf(nextIndex) - 1];

                if (nextVisibleIndex == void 0) {
                    break;
                }

                const currentRenderedRowIndex: number = this.renderedRowCount - (iterations % this.renderedRowCount) - 1;

                const shiftable: any = this.renderedRows[currentRenderedRowIndex];

                shiftable.top = firstRowTop - this.rowHeight;
                shiftable.index = nextVisibleIndex;

                shiftable.listNode.css({top: shiftable.top});

                firstRowTop = shiftable.top;
                nextIndex = nextVisibleIndex;
                iterations++;
            }
        }

        /**
         * applies sorting to the tree
         * TODO replace this with generic sorting in the column controller once the multiarray sort is completed
         *
         * @param {any[]} rows
         * @param {boolean} isRecursive
         */
        private applySorting(rows: any[], isRecursive: boolean = false): void {

            if (rows == void 0 || rows.length == 0) {
                return;
            }

            // either sort ascending or descending according to the config
            if (rows.length > 1) {
                if (this.sortDirection == "asc") {
                    rows.sort(this.sortComperator);
                } else if (this.sortDirection == "desc") {
                    rows.sort(this.sortComperator).reverse();
                }
            }

            // apply the sorting recursively
            for (const row of rows) {
                if (row[this.childNodesKey] != void 0 && row[this.childNodesKey].length > 0) {
                    this.applySorting(row[this.childNodesKey], true);
                }
            }

            if (isRecursive == false) {
                const sortedRows: any[] = this.flatten(rows);
                let smallestIndex: number = this.rows.length;

                for (const sortedRow of sortedRows) {
                    if (sortedRow.index < smallestIndex) {
                        smallestIndex = sortedRow.index;
                    }
                }

                const args: any = [smallestIndex, sortedRows.length].concat(sortedRows);

                Array.prototype.splice.apply(this.rows, args);
            }
        }
    }
}
