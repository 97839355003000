import {Component, OnInit, ElementRef, forwardRef, Inject} from "@angular/core";

import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";

@Component({
    selector: "eob-search-settings-pin",
    templateUrl: "./eob-search-settings-pin.component.html",
    styleUrls: ["./eob-search-settings-pin.component.scss"]
})

export class EobSearchSettingsPinComponent implements OnInit {
    constructor(private clientService: ClientService,
                private messageService: MessageService,
                private el: ElementRef) {}

    ngOnInit(): void {
        if (!this.clientService.isPhone()) {
            this.el.nativeElement.style.display = "none";
        }
    }

    openSearchSettings(): void {
        if (!this.clientService.isPhone()) {
            this.el.nativeElement.style.display = "none";
        }
        this.messageService.broadcast(FormEvent.TOGGLE_SEARCH_SETTINGS);
    }
}
