import * as angular from "angular";
import {TranslateFnType} from "../../../eob-client/custom.types";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {GridData} from "MODULES_PATH/grid/interfaces/grid-data.interface";
import {GridContentUtilsService} from "MODULES_PATH/grid/services/grid-content-utils.service";

/**
 * interface for gridData object of the virtualGrid converter
 */
interface VirtualGridData {
    columns: any[];
    rows: any[];
}

/**
 * class to convert from normal grid content to virtual grid content
 */
export class VirtualGridConverter {
    static $inject: string[] = ["$filter", "stateHistoryManager", "layoutManagerService", "cacheManagerService", "gridContentUtilsService", "clientService"];

    private translateFn: TranslateFnType;

    constructor(protected $filter: ng.IFilterService, protected stateHistoryManager: any, protected layoutManagerService: any,
                protected cacheManagerService: any, protected gridContentUtilsService: GridContentUtilsService, protected clientService: ClientService) {

        this.translateFn = this.$filter("translate");
    }

    /**
     * converts the gridData from the old format to the new format for the virtual tree
     * this is needed in the variant state to create a recursive structure from the plain list in order to show the variants as a tree
     *
     * @param {GridData} hitlistConfig
     * @param data
     * @returns {VirtualGridData}
     */
    convert = (hitlistConfig: GridData, data: any): VirtualGridData => {
        const gridData: VirtualGridData = {
            columns: [],
            rows: []
        };

        const context: string = this.stateHistoryManager.getCurrentStateData().data.type;

        switch (context) {
            case "variants":
                gridData.columns = this.getVariantColumns(hitlistConfig);
                gridData.rows = this.getVariantRows(hitlistConfig.rows, data);
                break;
            case "search":
                gridData.columns = this.getColumns(hitlistConfig);
                gridData.rows = hitlistConfig.rows;
                break;
        }

        return gridData;
    };

    /**
     * converts the rows to a tree structure
     *
     * @param {Array<any>} hitlistRows
     * @param variantData
     * @returns {Array<any>}
     */
    private getVariantRows(hitlistRows: any[], variantData: any): any[] {
        return this.getVariantTree(variantData.model.variantTree, hitlistRows);
    }

    /**
     * converts the rows to a tree structure starting at the root node
     *
     * @param variantTreeData
     * @param {Array<any>} oldRows
     * @returns {Array<any>}
     */
    private getVariantTree(variantTreeData: any, oldRows: any[]): any[] {
        const treeData: any[] = [];

        const dmsDocument: any = this.cacheManagerService.dmsDocuments.getById(variantTreeData.osid);

        let root: any = {};
        for (const row of oldRows) {
            if (row.osid == dmsDocument.model.osid) {
                root = row;
                root.children = [];
            }
        }

        treeData.push(root);

        for (const i of variantTreeData.children) {
            this.addNodes(root.children, i, oldRows);
        }

        return treeData;
    }

    /**
     * adds nodes to the root node
     *
     * @param {Array<any>} childArray
     * @param node
     * @param {Array<any>} oldRows
     */
    private addNodes(childArray: any[], node: any, oldRows: any[]): void {
        const dmsDocument: any = this.cacheManagerService.dmsDocuments.getById(node.osid);

        if (dmsDocument == void 0) {
            return;
        }

        let variantNode: any = {};

        for (const row of oldRows) {

            if (row.osid == dmsDocument.model.osid) {
                variantNode = row;
                variantNode.children = [];
            }
        }

        childArray.push(variantNode);

        for (const i of node.children) {
            this.addNodes(variantNode.children, i, oldRows);
        }
    }

    /**
     * converts the columns to the virtual grid columns for resultlist columns
     *
     * @param {GridData} hitlistConfig
     * @returns {Array<any>}
     */
    private getColumns(hitlistConfig: GridData): any[] {
        const cols: any[] = [];

        for (const col of hitlistConfig.columns) {
            const c: any = {
                headerValue: col.headerName,
                field: col.field
            };

            if (col.width != void 0) {
                c.width = col.width;
            }

            if (col.isIconCell) {
                c.suppressResize = true;
                c.cellRenderer = (params: any): JQuery => {
                    const container: JQuery = angular.element("<div></div>");
                    return container.append(params.rowData[params.field].value);
                };
            }

            cols.push(c);
        }

        return cols;
    }

    /**
     * convertsd the given GridData columns to virtual grid columns for the variant state
     *
     * @param {GridData} hitlistConfig
     * @returns {Array<any>}
     */
    private getVariantColumns(hitlistConfig: GridData): any[] {
        let cols: any = [];
        if (this.clientService.isPhone()) {
            cols = [{
                headerValue: this.translateFn("eob.contextmenu.variants.variant"),
                field: "f0",
                width: 60,
                showAsTree: false,
                suppressResize: true,
                cellRenderer: (params: any): JQuery => {
                    const container: JQuery = angular.element("<div></div>");
                    const dmsDocument: any = this.cacheManagerService.dmsDocuments.getById(params.rowData.osid);
                    const content: JQuery = angular.element(`<span class="node-text">${dmsDocument.model.variantData[0].model.version}</span>`);
                    if (dmsDocument.model.variantData[0].model.isActive) {
                        content.addClass("active");
                    }
                    params.textNode.css("justify-content", "center");
                    return container.append(content);
                }
            }, {
                field: "f1",
                isPhoneCell: true,
                cellRenderer: (params: any): JQuery => {
                    const el: JQuery = angular.element(`<div><span style="font-weight: bold">${params.rowData.f1.value.title}</span>
                        <span>${params.rowData.f1.value.description}</span></div>`);
                    el.find("span").css({
                        "white-space": "pre-line",
                        "display": "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        "-webkit-line-clamp": "2",
                        "max-height": "35px",
                        "word-break": "break-all",
                        "overflow": "hidden"
                    });
                    return el;
                }
            }, {
                field: "f2",
                width: 40,
                suppressResize: true,
                cellRenderer: (params: any): JQuery => angular.element("<i class=\"icon-16-kebab-dark\" style=\"padding-left: 15px\"></i>")
            }];
        } else {
            cols = [{
                headerValue: `<i class="icon-16-header-cell-variant-active" title="${this.translateFn("eob.grid.columnheader.tooltip.variant")}"></i>`,
                field: "f0",
                width: this.layoutManagerService.isTouchLayoutActive() ? 40 : 32,
                suppressResize: true,
                cellRenderer: (params: any): JQuery => {
                    const container: JQuery = angular.element("<div></div>");
                    const activeVariantIcon: string = this.getActiveVariantIcon(params.rowData.osid);
                    return container.append(activeVariantIcon);
                }
            }, {
                headerValue: this.translateFn("eob.contextmenu.variants.variant"),
                field: "f1",
                showAsTree: true,
                cellRenderer: (params: any): JQuery => {
                    const container: JQuery = angular.element("<div class='variant-icon-container'></div>");
                    const dmsDocument: any = this.cacheManagerService.dmsDocuments.getById(params.rowData.osid);

                    container.append(this.getArchiveStateIcon(dmsDocument));
                    container.append(this.getVariantStateIcon(dmsDocument));
                    const text: JQuery = angular.element(`<span class="node-text">${dmsDocument.model.variantData[0].model.version}</span>`);
                    return container.append(text);
                }
            }, {
                headerValue: this.translateFn("eob.contextmenu.variants.based.on"),
                field: "f2"
            }, {
                headerValue: this.translateFn("eob.contextmenu.variants.from"),
                field: "f3"
            }, {
                headerValue: this.translateFn("eob.contextmenu.variants.at"),
                field: "f4"
            }];
        }

        for (let i: number = hitlistConfig.staticColCount; i < hitlistConfig.columns.length; i++) {
            cols.push({
                headerValue: hitlistConfig.columns[i].headerName,
                field: hitlistConfig.columns[i].field
            });
        }

        return cols;
    }

    /**
     * returns the active variant icon
     *
     * @param {string} osid
     * @returns {string}
     */
    private getActiveVariantIcon(osid: string): string {
        const dmsDocument: any = this.cacheManagerService.dmsDocuments.getById(osid);
        const isActive: boolean = dmsDocument.model.variantData[0].model.isActive;

        return isActive ? `<i class="icon-16-header-cell-variant-active" title="${this.translateFn("eob.grid.systemcolumn.tooltip.variants.active")}"></i>` : "";
    }

    /**
     * returns the lockstate icon
     */
    private getVariantStateIcon(dmsDocument: any): string {
        return this.getIconElement(this.gridContentUtilsService.getLockStateIcon(dmsDocument.model).value);
    }

    /**
     * returns the archive state icon
     */
    private getArchiveStateIcon(dmsDocument: any): string {
        return this.getIconElement(this.gridContentUtilsService.getArchiveStateIcon(dmsDocument.model).value);
    }

    private getIconElement(cellData: any) {
        return `<i class='icon-16-${cellData.icon}' title='${cellData.title}'></i>`;
    }
}
