import {Inject, Injectable} from "@angular/core";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {FeatureSet} from "MODELS_PATH/eob.feature.set.model";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {FieldControlType, FieldDataType} from "ENUMS_PATH/field.enum";
import {first} from "rxjs/operators";
import * as dayjs from "dayjs";
import {EobTimer} from "MODULES_PATH/timer/interfaces/timer.interface";
import {environment} from "ROOT_PATH/environments/environment";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Local date parsing on midnight time problem.
// Without this plugins e.g. 24.12.2021 is converted into 2021-12-23 with 23:00:00 time because of UTC
// We only want the date and not the time and then the date is -1 day.
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);


/**
 * Helper functions for parsing values
 */
@Injectable({providedIn: "root"})
export class ValueUtilsService {
    // private decimalRegEx: string = "^-?([0-9]{1,5}([.,TSEP][0-9]{3})*([,.][0-9]+)?|\\d*\\[,.]\\d+|\\d+)$";
    // do not include thousand separators in this regEx because it must consider decimal separators only
    static decimalRegEx: string = "^-?[0-9]*([\\.,\\-]?[0-9]{1,2})?$";

    private readonly translateFn: TranslateFnType;
    private readonly dateIsoRegEx: RegExp = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}");
    private isNotEqualActive: boolean;
    private readonly negateRegexp: RegExp = /^(\*?)(!=|<>)/;

    constructor(@Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("$filter") $filter: ng.IFilterService,
                private messageService: MessageService) {

        if (environment.test) {
            this.isNotEqualActive = true;
        } else {
            messageService.subscribe(Broadcasts.FEATURESET_RECEIVED, (featureSet: FeatureSet) => {
                this.isNotEqualActive = featureSet.contains("dms.search.operator.notEqual");
            }, first());
        }

        this.translateFn = $filter("translate");
    }

    /**
     * Parse a boolean value from a string or number argument
     *
     * @param value
     * @returns boolean
     */
    parseBoolean(value: string | number | boolean): boolean {
        if (typeof (value) == "string") {
            switch (value.toLowerCase()) {
                case "false":
                case "0":
                    return false;

                case "true":
                case "1":
                    return true;

                default:
                    return false;
            }
        } else if (typeof (value) == "number") {
            return value > 0;
        }

        return value;
    }

    /**
     * Parse a decimal or list value
     *
     * @param type
     * @param value
     * @returns string
     */
    parseValue(type: "decimal" | "list", value: string | number): string {
        if (type == "decimal") {
            value = this.parseDecimal(value);
        } else if (type == "list") {
            value = this.parseList(value as string);
        }

        return value as string;
    }

    /**
     * Parses a list removing the leading and trailing semicolon
     *
     * @param value
     * @returns string
     */
    parseList(value: string): string {
        // remove leading seperator
        if (value.charAt(0) == ";" || value.charAt(0) == "+") {
            value = value.substr(1);
        }
        // remove trailing seperator
        if (value.charAt(value.length - 1) == ";" || value.charAt(value.length - 1) == "+") {
            value = value.substr(0, value.length - 1);
        }

        return value;
    }

    /**
     * Checks whether the passed value is a valid decimal value
     *
     * @param value
     * @returns a boolean that checks if a decimal value has been sent
     */
    isDecimalValue(value: string | number): boolean {
        if (value === undefined || value === "") {
            return false;
        }

        if (typeof value == "number") {
            value = value.toString();
        }

        return new RegExp(ValueUtilsService.decimalRegEx).test(value);
    }

    /**
     * Formats a passed number or string into a known decimal format having two decimals divided by.
     *
     * @param value
     * @returns string
     */
    parseDecimal(value: string | number): string {
        if (value === undefined) {
            return undefined;
        }

        // in case of number, we want to have a string for replacing things
        value = value.toString();

        // DODO-15875: do not care for thousand separators, just replace German decimal separator (comma)
        // with dot to parse it later as decimal value; if there is more than one decimal separator,
        // the value will stay "invalid" with regards to decimal
        if (value.includes(",")) {
            value = value.replace(/,/g, ".");
        }

        // strange condition in grids: whenever there is a decimal number starting with "0.xyz"
        // the server returns ".xyz" without the leading 0; in this case we simply add it again
        if (value.charAt(0) == ".") {
            value = `0${value}`;
        }

        // fill decimal value with trailing zeroes, if no decimal separator is given
        if (!value.includes(".") && this.isDecimalValue(value)) {
            value += ".00";
        }

        // fill up trailing zeroes to two digits, if too short
        const floating: string = value.split(".")[1];
        if (floating != void 0 && floating.length < 2) {
            value += "0"; // Add a zero for the second floating digit.
        }

        return value;
    }

    /**
     * fill with leading zeros
     */
    formatLeadingZeros(value: string, length: number): string {
        let prefix: string = "";

        // keep minuses upfront
        if (value.charAt(0) == "-") {
            prefix = "-";
            value = value.substring(1);
        }

        return `${prefix}${value.padStart(length, "0")}`;
    }

    /**
     * Formats timestamp according to the current environment settings
     *
     * @param timestamp
     * @returns string of format timestamp according to the current environment settings
     */
    formatTime(timestamp: string): string {
        return dayjs(timestamp).format(this.environmentService.env.dateFormat.time);
    }

    /**
     * Formats date according to the current environment settings
     *
     * @param {string} date - Either a timestamp or a date string.
     * @param {boolean} isDateTime - True, if the date comes along with a time, otherwise false.
     * @param {string} sourceFormat - Optional format string to avoid "Invalid date".
     * @returns string of format date according to the current environment settings
     */
    formatDate(date: string, isDateTime: boolean, sourceFormat?: string): string {
        const format: string = isDateTime ? this.environmentService.env.dateFormat.datetime : this.environmentService.env.dateFormat.date;

        if (sourceFormat != void 0) {
            return dayjs.tz(date, sourceFormat, "Etc/UCT").format(format);
        }

        return dayjs(date).format(format);
    }

    /**
     * Converts a datetime timestamp to a date
     *
     * @param value
     * @param isDatetime
     * @param isUnix
     * @returns timestamp.
     */
    convertToTimestamp(value: string, isDatetime: boolean, isUnix: boolean): string {
        const format: string = isDatetime ? [this.environmentService.env.dateFormat.datetime, this.environmentService.env.dateFormat.datetime.slice(0, -3)] : this.environmentService.env.dateFormat.date;
        let timestamp: string = dayjs.tz(value, format, "Etc/UCT").valueOf().toString();

        if (timestamp === "NaN") {
            timestamp = value;
        }

        if (isUnix) {
            // trim potentially existing nanoseconds
            timestamp = timestamp.substr(0, 10);
        }

        return timestamp;
    }

    convertToIsoDateTime(value: string, isDatetime: boolean): string {
        if (value.includes("-") && (!isDatetime) || value.includes("T")) {
            // A date string with "-" or a date time string with additional "T" seems to be already an iso date time string.
            return value;
        }

        const format: string = isDatetime ? [this.environmentService.env.dateFormat.datetime, this.environmentService.env.dateFormat.datetime.slice(0, -3)] : this.environmentService.env.dateFormat.date;
        return dayjs.tz(value, format, "Etc/UCT").toISOString();
    }

    /**
     * Replaces the date separator according to current locale
     *
     * @param date The date to format int the current language.
     * @returns A string of converted date to current local.
     */
    dateToLocaleDate(date: string): string {
        let localeDate: string;

        if (date == void 0) {
            return "";
        }

        // Look if the value is iso8601 from the DMS Service.
        if (this.dateIsoRegEx.test(date)) {
            const format: string = (date.includes("T")) ? this.environmentService.env.dateFormat.datetime : this.environmentService.env.dateFormat.date;
            return dayjs(date).format(format);
        }

        // It's osrest format
        if (this.environmentService.getLanguage() == "fr" || this.environmentService.getLanguage() == "en") {
            localeDate = date.replace(/\./g, "/");
        } else {
            localeDate = date.replace(/\//g, ".");
        }

        return localeDate;
    }

    /**
     * Adds correction hours to the time stamp, because momentjs recognises summer time
     * in the past even if there was no summer time.
     * e.g.
     * 1. user gives the date 31.10.1954
     * 2. time part is assumed with 00:00:00
     * 3. momentjs recognise summer time when parsing, e.g. Berlin +2 h
     * 4. the resulting time stamp gets reduced by 2 hours, e.g. -478749600000 (30.10.1954 22:00:00)
     * 5. java datetime API only adds 1 hour because it knows there was no summer time at this year
     * => 30.10.1954 23:00:00 to look up which is wrong
     *
     * @param timestamp
     * @param type to choose date only fields
     * @returns fixed timestamp otherwise ""
     */
    fixDaylightSavingIncompatibility(timestamp: string, type: string): string {
        if (timestamp == void 0 || timestamp == "") {
            return "";
        }

        type = type == void 0 ? "date" : type;

        const result: string | number = type == "date" ? parseInt(timestamp, 10) + 43200000 : timestamp; // add 12 hours
        return result.toString();
    }

    /**
     * Parses a date field towards a date range string
     *
     * @param field
     * @param isSearch
     * @returns string
     */
    dateToRangeString(field: any, isSearch: boolean): string {
        let returnValue: string;
        let negate: boolean = false;
        const fieldType: string = field.model.type;
        let dateContent: any = {
            value: field.value,
            to: field.to
        };

        if (this.isNotEqualActive && this.negateRegexp.test(dateContent.value) && !dateContent.to) {
            negate = true;
            dateContent.value = dateContent.value.replace(this.negateRegexp, "$1");
        }

        // Create a year date range when only a year is given
        if (dateContent.value.length == 4) {
            dateContent.to = dayjs(parseInt(dateContent.value, 10) + 1, "YYYY").subtract(1, "days").format(this.environmentService.env.dateFormat.date);
            dateContent.value = dayjs(dateContent.value, "YYYY").format(this.environmentService.env.dateFormat.date);
        }

        if (!new RegExp("([<>=])", "g").test(dateContent.value) && dateContent.value.indexOf("-") == -1) {
            dateContent.value = `${dateContent.value}-${(dateContent.to ? dateContent.to : dateContent.value)}`;
        }

        dateContent = this.fillMissingTimeParts(dateContent, fieldType, false);
        delete dateContent.to;
        returnValue = dateContent.value;

        if (isSearch) {
            if (dateContent.value && !dateContent.to) {
                if (new RegExp("([<>=])", "g").test(dateContent.value) || dateContent.value.indexOf("-") != -1) {
                    returnValue = dateContent.value;
                } else {
                    returnValue = `>=${dateContent.value}`;
                }
            } else if (!dateContent.value && dateContent.to) {
                if (new RegExp("([<>=])", "g").test(dateContent.to)) {
                    returnValue = dateContent.to;
                } else {
                    returnValue = `<=${dateContent.to}`;
                }
            } else if (dateContent.value && dateContent.to) {
                const match: RegExpMatchArray = dateContent.to.match(new RegExp("([<>=])", "g"));

                if (match?.length && fieldType != "time") {
                    returnValue = `${dateContent.value}|${dateContent.to}`;
                } else if (!match || match.length === 0) {
                    returnValue = `${dateContent.value}-${dateContent.to}`;
                } else if (match?.length && fieldType == "time" && dateContent.value !== "") {
                    // It seems to be both strings but the concat makes no sence.
                    // e.g. value<=to or value>=to
                    // I want to concentrate here on the data layer so I can't investigate this at the moment.
                    returnValue = `${dateContent.value}${dateContent.to}`;
                }
            }
        }

        if (fieldType == "date" || fieldType == "datetime") {
            returnValue = this.convertRangeToTimestamp(returnValue, this.environmentService.env.dateFormat.datetime, false, "datetime");
        }

        return negate ? `!=${returnValue}` : returnValue;
    }

    /**
     * Retrieves a locale specific date placeholder
     *
     * @param type
     * @returns value of translation key
     */
    getDatePlaceholder(type: FieldDataType | FieldControlType | FormFieldType): string {
        switch (type) {
            case FieldDataType.DATETIME:
                return this.translateFn("modal.datepicker.datetime.placeholder");
            case FieldDataType.TIME:
                return this.translateFn("modal.datepicker.time.placeholder");
            default:
                return this.translateFn("modal.datepicker.date.placeholder");
        }
    }

    /**
     * Extracts valid email addresses from the given string containing one or multiple addresses divided by [;, ]
     *
     * @param mailString
     * @returns object which includes isValid and mailAddresses array
     */
    extractMailAddresses(mailString: string): { isValid: boolean; mailAddresses: string[] } {
        const mailArray: string[] = [];
        let currentExtractedMail: string = "";
        let insideQuotes: boolean = false;

        for (let i: number = 0; i < mailString.length; i++) {
            if (mailString.charAt(i) == "\"") {
                insideQuotes = !insideQuotes;
            }

            if (insideQuotes) {
                currentExtractedMail = currentExtractedMail.concat(mailString.charAt(i));
            } else if (mailString.charAt(i) != ";" && mailString.charAt(i) != "," && mailString.charAt(i) != " ") {
                currentExtractedMail = currentExtractedMail.concat(mailString.charAt(i));
            } else if (currentExtractedMail.length != 0) {
                mailArray.push(currentExtractedMail);
                currentExtractedMail = "";
            }
        }

        if (currentExtractedMail.length != 0) {
            mailArray.push(currentExtractedMail);
        }

        if (this.validateEmailArray(mailArray)) {
            return {
                isValid: true,
                mailAddresses: mailArray
            };
        }

        return {
            isValid: false,
            mailAddresses: []
        };
    }

    /**
     * Formats a field range information into a string
     *
     * @param field
     * @returns a string value that shows the range between two values
     */
    numberToRangeString(field: any): string {
        if (field.to == "" || field.to == void 0) {
            return field.value;
        }

        if (field.value == "" || field.value == void 0) {
            return field.to;
        }

        return `${field.value}-${field.to}`;
    }

    /**
     * Removes unknown or excess values from a passed values array.
     *
     * @param values
     * @param columnsLength
     * @returns an array of normalized row values
     */
    normalizeRowValues(values: string[], columnsLength: number): string[] {
        // In case we don't receive an array
        if (!Array.isArray(values)) {
            values = [values];
        }

        // remove the additional values because we cannot store more than the column definition allows
        if (columnsLength < values.length) {
            values.splice(columnsLength);
            console.warn(`The maximum numbers of values has been exceeded. You can only add ${columnsLength} values, due to the column count`);
        }

        // filling the missing entries with empty strings
        if (columnsLength > values.length) {
            console.warn("Filling missing values with empty strings");
            for (let index: number = values.length; index < columnsLength; index++) {
                values.push("");
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const i in values) {
            if (values[i] === null || values[i] === void 0 || (typeof (values[i]) != "string" && typeof (values[i]) != "number")) {
                console.warn("The type of the  given value is not supported --> ", values[i]);
                console.warn("The value will be turned into an empty string.");
                values[i] = "";
            } else {
                values[i] = values[i].toString();
            }
        }

        return values;
    }

    /**
     * Checks whether the passed index violates the row count
     *
     * @param index
     * @param rowsLength
     * @returns a boolean which checks that the row index is valid
     */
    isValidRowIndex(index: any, rowsLength: number): boolean {
        if (index == void 0 || index === "") {
            console.warn("The row index must be defined");
            return false;
        }

        if (isNaN(index)) {
            console.warn("The row index must be an integer value");
            return false;
        }

        if (index < 0 || index >= rowsLength) {
            console.warn("Row index out of bound for -->  ", index, " ,the highest index allowed is --> ", rowsLength - 1);
            return false;
        }

        return true;
    }

    /**
     * Checks whether the passed index violates the column count
     *
     * @param index
     * @param colsLength
     * @returns a boolean which checks that the column index is valid
     */
    isValidColIndex(index: any, colsLength: number): boolean {
        if (index == void 0 || index === "") {
            console.warn("The column index must be defined");
            return false;
        }

        if (isNaN(index)) {
            console.warn("The column index must be an integer value");
            return false;
        }

        if (index < 0 || index >= colsLength) {
            console.warn("Column index out of bound for -->  ", index, " ,the highest index allowed is --> ", colsLength - 1);
            return false;
        }

        return true;
    }

    /**
     * Checks whether the passed indices are valid for the given rows
     *
     * @param rowIndex
     * @param colIndex
     * @param rows
     * @returns a boolean which checks that the cell index is valid
     */
    isValidCellIndex(rowIndex: any, colIndex: any, rows: any[]): boolean {
        if (!this.isValidRowIndex(rowIndex, rows.length)) {
            return false;
        }

        if (colIndex == void 0 || colIndex === "") {
            console.warn("The column index must be defined");
            return false;
        }

        if (isNaN(colIndex)) {
            console.warn("The column index must be an integer value");
            return false;
        }

        if (colIndex < 0 || colIndex >= rows[rowIndex].length) {
            console.warn("Column index out of bound for -->  ", colIndex, " ,the highest index allowed is --> ", rows[rowIndex].length - 1);
            return false;
        }

        return true;
    }

    /**
     * Formats decimal value according to locale
     *
     * @param value
     * @param format - rounds off to the nearest value using Math.round() method | accepts string format like inside of AngularX built-in pipes (e.g. | number: '.2-2').
     * @returns a string of formated number
     */
    numberToLocaleNumber(value: number | string, format?: string): string {
        value = value?.toString();
        let minDec: number;
        let maxDec: number;

        // limited to 2 decimal
        if (!format) {
            format = ".0-2";
        }
        const matches = format.matchAll(/(\d+)-(\d+)/g).next();

        if (matches.value?.length == 3) {
            minDec = Number(matches.value[1]);
            maxDec = Number(matches.value[2]);
        }
        const thousandDelimiter: string = this.environmentService.getThousandSeparator() ?? ".";
        const currentLang: string = this.environmentService.getLanguage() ?? "de";

        if (!value || Number.isNaN(Number(value))) {
            return value;
        }

        let formatedValue: string = new Intl.NumberFormat(currentLang, {
            minimumFractionDigits: minDec,
            maximumFractionDigits: maxDec
        }).format(Number(value));//formatNumber(Number(value), currentLang, format);
        switch (currentLang) {
            case "en":
                formatedValue = formatedValue.replace(/\,/g, thousandDelimiter);
                break;
            case "de":
                formatedValue = formatedValue.replace(/\./g, thousandDelimiter);
                break;
            case "fr":
                formatedValue = formatedValue.replace(/\s/g, thousandDelimiter);
                break;
        }

        return formatedValue;
    }

    getCrosscheckRelatedControl(flags1: string): number {
        // this function does the magic ...
        // we need to get the tab index of the referred quickfinder field
        // to achieve this we need to do some "things"
        // ########
        // to get the tab index of the quickfinder, each field is connected to, we need to get the flags1 key of
        // the field and do the following steps

        // 1. convert the decimal to a DWORD (the DWORD is the binary representation of a decimal in 2 * 2 bytes)
        // e.g. DWORD of "1049602" --> [ {00000000} * {00010000} ] * [ {00000100} * {00000010}]" equals 32 bit
        // this is the code to get the DWORD --> (104960).toString(2)
        // explanation : the integer will be converted to a string with the base 2
        // HINT : the binary will be shown without the leading zeros
        // The Brackets around the integer are needed !! ... do not remove them

        // 2. We need the third byte and convert it into a number
        // to achieve this, we simply shift the whole integer by 8 bits to the right
        // and use the substring function to get the last 8 bits
        // e.g. (104960 >>> 0).toString(2) will be {00000000} * {00010000} * {00000100} (the first 3 bytes of the DWORD)

        // 3. We use the substr function to get the last 8 bits of the remaining binary
        // var a = (1049602 >>> 8).toString(2);
        // a.substr(a.length - 8) --> this will return 00000100

        // the final step will be to get the decimal of the binary from step 3
        // we use the parseInt function with the base 2 --> this converts a binary string to a decimal
        // var a = (1049602 >>> 8).toString(2);
        // a.length > 8 ? a.substr(a.length - 8) : a --> only cut the string if the length > 8 because otherwise we deal with negative numbers
        // which can lead to an unecpected result
        // parseInt(a,2) --> will return 4
        // but the 4 is not the tabindex of the quickfinder field we are looking for
        // we need to reduce this value by 1 because the documentation of this crosscheck field says,
        // "returns the tab index + 1" --> we take 1 away and get the right index... DONE

        // we wrap this into an anonymous iife function and get the final result below
        const a: string = (parseInt(flags1) >>> 8).toString(2);
        const b: string = a.length > 8 ? a.substr(a.length - 8) : a;
        return (parseInt(b, 2) - 1);
    }

    /**
     * Converting miliseconds to timer format
     *
     * @param miliseconds
     * @returns {Timer} (e.g. { seconds: "00", minutes: "00", hours: "00", days: "00" })
     */
    convertMiliseconds(miliseconds: number): EobTimer {
        if (miliseconds === undefined || miliseconds === null) {
            return;
        }

        const pad: (arg: number) => string = (n: number): string => n < 10 ? `0${n}` : String(n);
        const totalSeconds: number = Number(Math.floor(miliseconds / 1000));
        const totalMinutes: number = Number(Math.floor(totalSeconds / 60));
        const totalHours: number = Number(Math.floor(totalMinutes / 60));

        const seconds: string = pad(Number(totalSeconds % 60));
        const minutes: string = pad(Number(totalMinutes % 60));
        const hours: string = pad(Number(totalHours % 24));
        const days: number = Number(Math.floor(totalHours / 24));

        return Object.assign({seconds, minutes, hours}, (days && {days: pad(days)}));
    }

    /**
     * Validates the given email addresses according to a complex RegExp
     *
     * @param emailArray
     * @returns whether all addresses inside the array are valid
     */
    private validateEmailArray(emailArray: string[]): boolean {
        /*Match info:
         * https://en.wikipedia.org/wiki/Email_address
         * Parts:
         * N1: Hit Letters, Numbers,and '-'  : [\w\-\.]
         * N2 :mutated vowel :[\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]
         * SG1: Specialgroup 1: outside "" allowed : [/=?^!#$%&'_`{}|~\\\*\+\-]
         * SG2 : Specialgroup 2: only allowed inside "" : [(),:;<>@[\\\]]
         * SP1 : '\" empty or some text"@....
         * SPACE : rekursiv emailblock allowed spacer: , ; space  : mail (; mail)*
         *  - * : x-times
         *  regex : Structure:
         *  localpart@domainpart
         *  localpart: N1&SG1 or SPACE N1,N2,SP1,SG2 SPACE
         *  domainpart: @N1&N2.N1 (without: - & .) or @N1 (without: - & .) or @[IP-address]
         *
         *  Accepted:
         *  - multi emailaddresses with spacer: allowed: ; . <space>
         *  - Normalemailadresses : test@test.org
         *  - Addresses with specialcharacters at the localpart(SG1) : #!$%&'*+-/=?^_`{}|~@example.org
         *  - quoted localPart : "defghi"@example.com
         *  - Chains with '.' : example.it.is@test.org or example@test.real.com
         *  - mutated vowel: Äöäö@öäöäö.de
         *  - Domain names need more then 1 letter.
         *
         *  Not accepted:
         *  - double quote inside a double quote block
         */
        const mPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

        for (const address of emailArray) {
            if (!mPattern.test(address)) {
                return false;
            }
        }

        return true;
    }

    /**
     * extractDateRangeAndConvert
     *
     * @param portion
     * @param localeString
     * @param origin
     * @param fieldType
     * @returns a string of extracted and converted date range
     */
    private extractDateRangeAndConvert(portion: string, localeString: string, origin: string, fieldType: string): string {
        const extracted: string = portion.replace(/([=<>!])/g, "").trim();
        let timeStamp: string = dayjs(extracted, localeString).valueOf().toString();
        timeStamp = this.fixDaylightSavingIncompatibility(timeStamp, fieldType);
        const newValue: string = origin.substring(0, origin.indexOf(extracted)) + timeStamp + origin.substring(origin.indexOf(extracted) + extracted.length, origin.length);
        return newValue;
    }

    /**
     * convertRangeToTimestamp
     *
     * @param date
     * @param localeString
     * @param isUnix
     * @param fieldType
     * @returns a string of converted date range to timestamp
     */
    private convertRangeToTimestamp(date: string, localeString: string, isUnix: boolean, fieldType: string): string {
        /**
         * check for year = 0 this happens when the user enters crazy stuff
         * like letters and so on .. to keep moment.js from converting it
         * into a timestamp for the date 01.01.0000 simply return at this
         * point
         */
        if (!date || date === "") {
            return "";
        }

        const regEx: RegExp = new RegExp("([=<>-])", "g");

        if (regEx.test(date)) {
            /**
             * at this point we know that there are comperators inside this string
             * but the "-" is optional if the second date has comperators aswell
             * in this case we use a special char combination "|" and delete it afterwards
             */
            let dateArray: string[];

            if (date.includes("|")) {
                dateArray = date.split("|");
                date = date.replace("|", "");
            } else {
                dateArray = date.split("-");
            }

            let newDate: string = this.extractDateRangeAndConvert(dateArray[0], localeString, date, fieldType);

            if (dateArray[1]) {
                newDate = this.extractDateRangeAndConvert(dateArray[1], localeString, newDate, fieldType);

                const tmpArray: string[] = newDate.split("-");

                if (tmpArray[0].trim() > tmpArray[1].trim()) {
                    newDate = ([tmpArray[0], tmpArray[1]] = [tmpArray[1], tmpArray[0]]).join("-"); // destructor, switch values and join
                }
            }

            // remove whitespaces
            return newDate.replace(/ /g, "");
        }

        let momentString: string = dayjs(date, localeString).valueOf().toString();
        momentString = this.fixDaylightSavingIncompatibility(momentString, fieldType);

        /**
         * DODO-6479
         * If there are no comperators and we have a datetime value that ends with 0 seconds,
         * then we search for the second range 0-59.
         */
        /*if (makeRange) {
            let momentInt = parseInt(momentString);
            momentInt += 59000;
            momentString += "-" + momentInt;
        }*/

        /**
         * if the datetime has to be a unix timestamp we need to remove the potentially existing nanoseconds
         */
        if (isUnix) {
            momentString = momentString.substr(0, 10);
        }

        return momentString;
    }

    /**
     * fillMissingTimeParts
     *
     * @param dateContent
     * @param fieldType
     * @param isRangeEnd
     * @returns updated missing time parts
     */
    private fillMissingTimeParts(dateContent: any, fieldType: string, isRangeEnd: boolean): any {
        let value: string = isRangeEnd ? dateContent.to : dateContent.value;
        let timeParts: string[];

        if (value === "") {
            return dateContent;
        }

        const timePartsArray: string[][] = [];
        let comperators: string = "";
        const firstDigitIndex: number = value.search(/\d/);

        if (firstDigitIndex > 0) {
            comperators = value.substr(0, firstDigitIndex);
        }

        const tmp: string[] = value.replace(/([!=<>])/g, "").trim().replace(/\s+/g, " ").split("-");

        for (const i in tmp) {
            const dateTimeParts: string[] = tmp[i].trim().split(" ");

            if (fieldType == "time") {
                dateTimeParts.unshift("");
            }

            if (dateTimeParts[1] == void 0) {
                timePartsArray.push([]);
                continue;
            }

            timeParts = dateTimeParts[1].trim().split(":");
            timePartsArray.push(timeParts);

            tmp[i] = dateTimeParts[0].trim();
        }

        for (const i in timePartsArray) {
            const parts: string[] = timePartsArray[i];

            if (parseInt(i, 10) > 0) {
                isRangeEnd = true;
            }

            parts[0] = parts[0] == void 0 ? isRangeEnd ? "23" : "00" : parts[0];
            parts[1] = parts[1] == void 0 ? isRangeEnd ? "59" : "00" : parts[1];
            parts[2] = parts[2] == void 0 ? isRangeEnd ? "59" : "00" : parts[2];
        }

        if (tmp.length > 1) {
            value = "";

            for (const i in tmp) {
                const date: string = tmp[i];

                if (fieldType != "time") {
                    value += `${date} `;
                }

                value += timePartsArray[i].join(":");

                if (parseInt(i, 10) === 0) {
                    value += "-";
                }
            }
        } else {
            value = fieldType == "datetime" || fieldType == "date" ? `${tmp[0]} ${timePartsArray[0].join(":")}` : timePartsArray[0].join(":");
        }

        if (comperators !== "") {
            value = comperators + value;
        }

        dateContent.value = value;
        return dateContent;
    }

    static escapeHTML(html: string): string {
        return html.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    }
}
