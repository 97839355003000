(function () {
    "use strict";

    angular.module("eob.core").factory("revisitModelService", RevisitModelService);

    RevisitModelService.$inject = ["toolService"];

    function RevisitModelService(ToolService) {

        let service = {
            createRevisit,
            Revisit
        };
        return service;

        function createRevisit(backendNotification) {
            return new service.Revisit(backendNotification);
        }

        function Revisit(backendNotification) {
            let self = this;

            self.model = {
                confirmed: backendNotification.confirmed,
                confirmationType: backendNotification.confirmationType,
                eventDate: backendNotification.eventDate,
                infoText: backendNotification.infoText,
                read: backendNotification.read,
                osid: backendNotification.id,
                sender: {
                    fullname: backendNotification.sender.fullname,
                    name: backendNotification.sender.name,
                },
                guid: ToolService.createGUID()
            }
        }
    }
})();
