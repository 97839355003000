import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

(function() {


    require("COMPONENTS_PATH/eob-circular-tab-form/eob.circular.tab.form.dir.js");

    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.backend.srv.js");

    angular.module("eob.framework").directive("eobModalRevisit", EobModalRevisit);

    EobModalRevisit.$inject = ["$filter", "$compile", "$rootScope", "$timeout", "formFieldModelService", "environmentService",
        "notificationsService", "backendService", "valueUtilsService", "viewerService", "organisationService", "clientService", "formService",
        "formHelper", "orgAddonService", "messageService", "modalDialogService"];

    function EobModalRevisit($filter, $compile, $rootScope, $timeout, FormFieldModelService, EnvironmentService,
                             NotificationsService, BackendService, ValueUtilsService, ViewerService, OrganisationService, ClientService, FormService,
                             FormHelper, OrgAddonService, MessageService, ModalDialogService) {
        return {
            scope: {
                dmsDocument: "=item"
            },
            link(scope, element) {
                let g_OrgEntries; // keep all org addon entries, while some might be filtered from the multiselect boxes
                const revisitForOthers = EnvironmentService.userHasRole("R_CLNT_REVISIT_FOROTHERS");
                scope.orgEntries;
                scope.orgGridConfigs;

                scope.formHelper = undefined;
                scope.isFormVisible = false;

                scope.createRevisit = createRevisit;

                scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

                (async () => {
                    let formData = await buildFormData();
                    scope.formHelper = FormHelper.getFormHelper({formData, submit: scope.createRevisit});
                    scope.formDef = {
                        isMainForm: true,
                        validationMode: "max",
                        formFields: scope.fields,
                        formHelper: scope.formHelper,
                        isMockForm: true
                    };

                    let content = angular.element("<div ng-show='isFormVisible'></div>");
                    let form = angular.element("<eob-form [formdef]='formDef'></eob-form>");
                    let buttons = angular.element("<div class=\"button-container\"> " +
                        "<button type=\"button\" tabindex=\"0\" class=\"secondary-button\" ng-click=\"close()\">{{::'modal.button.cancel' | translate}}</button>" +
                        "<button type=\"button\" tabindex=\"0\" class=\"btn btn-primary\" ng-click=\"createRevisit()\">{{::'modal.button.save' | translate}}</button></div>");
                    let containerElement = element.find(".revisit-modal-container");

                    content.append(form);
                    content.append(buttons);
                    let compiled = $compile(content);
                    containerElement.append(content);
                    compiled(scope);
                    initFields();
                })();

                async function buildFormData() {
                    let formFields = {
                        revisitAt: FormFieldModelService.getMockedField("datetime", "revisitAt", $filter("translate")("modal.revisit.at")),
                        revisitNote: FormFieldModelService.getMockedField("text", "revisitNote", $filter("translate")("modal.revisit.note")),
                        revisitPerEmail: FormFieldModelService.getMockedField("checkbox", "revisitPerEmail"),
                        revisitMail: FormFieldModelService.getMockedField("text", "revisitMail", $filter("translate")("modal.revisit.mail")),
                        revisitConfirmPassword: FormFieldModelService.getMockedField("checkbox", "revisitConfirmPassword", (ClientService.isPhone() ? $filter("translate")("modal.revisit.confirm") : ""))
                    };

                    if (revisitForOthers) {
                        Object.assign(formFields, {
                            revisitSelfRadio: FormFieldModelService.getMockedField("radio", "revisitSelfRadio", $filter("translate")("modal.revisit.self")),
                            revisitOthersRadio: FormFieldModelService.getMockedField("radio", "revisitOthersRadio", $filter("translate")("modal.revisit.others")),
                            revisitForSelectionUser: FormFieldModelService.getMockedField("checkbox", "revisitForSelectionUser", $filter("translate")("modal.revisit.userlist.user")),
                            revisitForSelectionGroups: FormFieldModelService.getMockedField("checkbox", "revisitForSelectionGroups", $filter("translate")("modal.revisit.userlist.groups")),
                        });

                        formFields.revisitSelfRadio.isMasterRadio = true;
                        formFields.revisitSelfRadio.fields = ["revisitSelfRadio", "revisitOthersRadio"]
                        formFields.revisitSelfRadio.masterRadioInternal = "revisitSelfRadio";
                        formFields.revisitOthersRadio.masterRadioInternal = "revisitSelfRadio";
                    }

                    scope.fields = Object.values(formFields);
                    return FormService.createFormData(scope.fields, "max");
                }

                function modifyForSelectionElements() {
                    let revisitMailField = element.find(scope.formHelper.getFieldByInternal("revisitMail").api.getElement()[0]);

                    if (ClientService.isPhone()) {
                        scope.formHelper.getFieldByInternal("revisitPerEmail").api.getElement().addClass("form-custom-checkbox-phone");
                        scope.formHelper.getFieldByInternal("revisitMail").api.getElement().addClass("form-email-container-phone");
                    } else if (!ClientService.isPhone() && ClientService.isPhoneOrTablet()) {
                        scope.formHelper.getFieldByInternal("revisitPerEmail").api.getElement().addClass("form-custom-checkbox-tablet");
                        scope.formHelper.getFieldByInternal("revisitMail").api.getElement().addClass("form-email-container-tablet");
                    } else {
                        scope.formHelper.getFieldByInternal("revisitPerEmail").api.getElement().addClass("form-custom-checkbox");
                        scope.formHelper.getFieldByInternal("revisitMail").api.getElement().addClass("form-email-container");
                    }

                    revisitMailField.before(scope.formHelper.getFieldByInternal("revisitPerEmail").api.getElement()[0]);

                    if (revisitForOthers) {
                        let forSelectionContainer = angular.element("<div ng-class=\"{'open': formHelper.getFieldByInternal('revisitSelfRadio').value == 1, 'close': formHelper.getFieldByInternal('revisitSelfRadio').value != 1}\" class=\"form-element form-elements-container-wrap-inline for-selection-container\"></div>");
                        let compiledContainer = $compile(forSelectionContainer);
                        let forSelectionLabel = angular.element(`<div ng-class=\"{'open': formHelper.getFieldByInternal('revisitSelfRadio').value == 1, 'close': formHelper.getFieldByInternal('revisitSelfRadio').value != 1}\" class="form-element form-label-custom for-selection-label"><span>${$filter("translate")("modal.revisit.userlist.show")}</span></div>`);
                        let compiledLabel = $compile(forSelectionLabel);

                        forSelectionContainer.append(scope.formHelper.getFieldByInternal("revisitForSelectionUser").api.getElement()[0])
                        forSelectionContainer.append(scope.formHelper.getFieldByInternal("revisitForSelectionGroups").api.getElement()[0])
                        revisitMailField.after(forSelectionLabel);
                        forSelectionLabel.after(forSelectionContainer);

                        compiledContainer(scope);
                        compiledLabel(scope);
                    }
                }

                function modifyMultiSelectBoxElements() {
                    const content = [].concat(OrganisationService.getActiveUserList(), OrganisationService.getGroupList());
                    scope.orgEntries = OrgAddonService.parseToOrgAddonEntries(content);
                    g_OrgEntries = scope.orgEntries;

                    scope.orgGridConfigs = OrgAddonService.getDefaultGridConfigs();

                    let forSelectionContainer = element.find(".for-selection-container");
                    let multiselectBoxContainer = angular.element("<div class=\"toggle-hidden-area\" ng-class=\"{'open': formHelper.getFieldByInternal('revisitSelfRadio').value == 1, 'close': formHelper.getFieldByInternal('revisitSelfRadio').value != 1}\"></div>");
                    let multiSelectDir = angular.element("<eob-multiselect-boxes " +
                        "[content]='orgEntries' [leftconfig]='orgGridConfigs.leftConfig' [rightconfig]='orgGridConfigs.rightConfig'>" +
                        "</eob-multiselect-boxes>");

                    multiselectBoxContainer.append(multiSelectDir)
                    let compiled = $compile(multiselectBoxContainer);
                    forSelectionContainer.after(multiselectBoxContainer);
                    compiled(scope);
                    }

                function modifyCreateForElements() {
                    let forSelectionLabel = element.find(".for-selection-label")

                    let createForContainer = angular.element("<div class=\"form-row-input-container create-for-container\"></div>");
                    let radioButtonsContainer = angular.element("<div class=\"form-element form-elements-container-wrap-inline\"></div>");

                    let createForLabel = angular.element(`<eob-label class="form-element form-label-custom" [value]=\"'${$filter("translate")("modal.revisit.for")}'\"></eob-label>`);

                    let selfRadioContainer = angular.element("<div class=\"form-radio-group\"></div>")
                    selfRadioContainer.append(scope.formHelper.getFieldByInternal("revisitSelfRadio").api.getElement()[0]);
                    radioButtonsContainer.append(selfRadioContainer);

                    let othersRadioContainer = angular.element("<div class=\"form-radio-group\"></div>")
                    let othersRadioBtn = angular.element(scope.formHelper.getFieldByInternal("revisitOthersRadio").api.getElement()[0]);
                    othersRadioBtn.bind("click", resetValuesForSelectionFields);
                    othersRadioContainer.append(othersRadioBtn);
                    radioButtonsContainer.append(othersRadioContainer);

                    createForContainer.append(createForLabel);
                    let compiled = $compile(createForContainer);
                    createForContainer.append(radioButtonsContainer);
                    forSelectionLabel.before(createForContainer);
                    compiled(scope);
                }

                function modifyConfirmPasswordElements() {
                    let container;
                    if (revisitForOthers) {
                        container = element.find(".toggle-hidden-area")
                    } else {
                        container = element.find(scope.formHelper.getFieldByInternal("revisitMail").api.getElement()[0]);
                    }

                    let confirmPasswordContainer = angular.element("<div class=\"form-element form-elements-container-wrap-inline\"></div>");
                    let confirmPasswordLabel = angular.element(`<div class="form-element form-label-custom"><label for="revisitConfirmPassword">${$filter("translate")("modal.revisit.confirm")}</label></div>`);
                    confirmPasswordContainer.append(scope.formHelper.getFieldByInternal("revisitConfirmPassword").api.getElement()[0]);

                    if (ClientService.isPhone()) {
                        container.after(confirmPasswordContainer);
                    } else {
                        container.after(confirmPasswordLabel);
                        confirmPasswordLabel.after(confirmPasswordContainer);
                    }
                }

                function initFields() {
                    $timeout(() => {
                        modifyForSelectionElements();
                        if (revisitForOthers) {
                            modifyMultiSelectBoxElements();
                            modifyCreateForElements();
                            scope.formHelper.getFieldByInternal("revisitSelfRadio").api.setValue(0);

                            scope.formHelper.getFieldByInternal("revisitForSelectionUser").api.setValue(1);
                            scope.formHelper.getFieldByInternal("revisitForSelectionUser").api.validate = async() => refreshList();
                            scope.formHelper.getFieldByInternal("revisitForSelectionGroups").api.setValue(1);
                            scope.formHelper.getFieldByInternal("revisitForSelectionGroups").api.validate = async() => refreshList();
                        }
                        modifyConfirmPasswordElements();

                        scope.isFormVisible = true;
                        scope.formHelper.getFieldByInternal("revisitAt").api.setValue(getNowIn15Minutes());
                        scope.formHelper.getFieldByInternal("revisitAt").api.focus();
                        scope.formHelper.getFieldByInternal("revisitMail").api.setValue(EnvironmentService.getSessionInfo().email);
                        scope.formHelper.getFieldByInternal("revisitMail").api.disable();
                        scope.formHelper.getFieldByInternal("revisitMail").api.validate = () => validateEmail();

                        scope.formHelper.getFieldByInternal("revisitPerEmail").api.validate = () => isEnabledRevisitPerMail();
                    }, 0);
                }

                function resetValuesForSelectionFields() {
                    scope.formHelper.getFieldByInternal("revisitForSelectionUser").api.setValue(1);
                    scope.formHelper.getFieldByInternal("revisitForSelectionGroups").api.setValue(1);
                    refreshList();
                }

                async function isEnabledRevisitPerMail() {
                    ModalDialogService.isEnabledInputField("revisitPerEmail", "revisitMail", scope.formHelper);
                }

                function refreshList() {
                    if (scope.formHelper.getFieldByInternal("revisitSelfRadio").api.getValue() == 1) {
                        const showUser = scope.formHelper.getFieldByInternal("revisitForSelectionUser").api.getValue() == 1;
                        const showGroups = scope.formHelper.getFieldByInternal("revisitForSelectionGroups").api.getValue() == 1;

                        scope.orgEntries = g_OrgEntries.filter(entry =>
                            (entry.selected || entry.type == "user" && showUser || entry.type == "group" && showGroups)
                        );
                    } else {
                        scope.formHelper.getFieldByInternal("revisitForSelectionUser").api.setValue(1);
                        scope.formHelper.getFieldByInternal("revisitForSelectionGroups").api.setValue(1);
                    }
                }

                function validateEmail() {
                    let obj = ValueUtilsService.extractMailAddresses(scope.formHelper.getFieldByInternal("revisitMail").value);
                    const input = angular.element(scope.formHelper.getFieldByInternal("revisitMail").api.getElement()[0]);
                    if (obj.isValid) {
                        input.removeClass("invalid");
                        MessageService.broadcast("unbind-validation-bubble", input);
                    } else {
                        input.addClass("invalid");
                        MessageService.broadcast("bind-validation-bubble", {
                            input,
                            title: $filter("translate")("modal.email.validate.error.title"),
                            msg: $filter("translate")("modal.email.validate.error.message")
                        });
                    }
                }

                function getNowIn15Minutes() {
                    let nowIn15 = new Date();
                    nowIn15.setSeconds(0, 0);

                    let addMinutes = 15;
                    let currentMinutes = nowIn15.getMinutes();
                    //round up to next 5 or 0
                    if (currentMinutes % 5 !== 0) {
                        addMinutes += 5 - (currentMinutes % 5);
                    }
                    nowIn15.setTime(nowIn15.getTime() + (addMinutes * 60 * 1000));

                    return ValueUtilsService.formatDate(nowIn15, true);
                }

                function createRevisit() {
                    if (ClientService.isOffline()) {
                        NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                        return;
                    }

                    this.formHelper.getFieldByInternal("revisitAt").api.validate().then((res) => {
                        if (scope.formHelper.getFieldByInternal("revisitAt").api.getValue() == "") {
                            NotificationsService.error($filter("translate")("modal.revisit.error.wrongduedate"));
                            return;
                        }

                        let currentUser = {
                            id: EnvironmentService.getSessionInfo().userid,
                            name: EnvironmentService.getSessionInfo().username,
                            fullname: EnvironmentService.getSessionInfo().fullname
                        };

                        let recipientList = [currentUser];
                        if (revisitForOthers
                            && scope.formHelper.getFieldByInternal("revisitSelfRadio").api.getValue() == 1) {
                            recipientList = prepareRecipientList();
                        }

                        if (recipientList.length == 0) {
                            NotificationsService.error($filter("translate")("modal.revisit.error.norecipientlist"));
                            return;
                        }

                        let mailAddresses = [];
                        let emails = this.formHelper.getFieldByInternal("revisitMail").api.getValue();
                        let obj = ValueUtilsService.extractMailAddresses(emails);

                        if (!obj.isValid || (scope.formHelper.getFieldByInternal("revisitPerEmail").api.getValue() == "1" && obj.mailAddresses.length == 0)) {
                            NotificationsService.error($filter("translate")("modal.revisit.submission.missing.mail.error"));
                            return;
                        } else {
                            mailAddresses = obj.mailAddresses;
                        }

                        let revisitData = {
                            osid: parseInt(scope.dmsDocument.model.id),
                            duedate: ValueUtilsService.convertToTimestamp(this.formHelper.getFieldByInternal("revisitAt").api.getValue(), true, false),
                            subject: this.formHelper.getFieldByInternal("revisitNote").api.getValue(),
                            creationdate: new Date().getTime(),
                            notified: false,
                            confirm: this.formHelper.getFieldByInternal("revisitConfirmPassword").api.getValue() == 1,
                            notifiedbymail: this.formHelper.getFieldByInternal("revisitPerEmail").api.getValue() == 1,
                            mailAddresses,
                            sender: currentUser,
                            recipients: recipientList,
                            newRecipients: recipientList
                        };

                        let items = [revisitData];
                        let errorMsgKey = "modal.revisit.error";

                        BackendService.post("/notifications/revisitMultiObjects", items).then((res) => {
                                if (Array.isArray(res.data) && res.data.length > 0) {
                                    NotificationsService.backendMultiError(res, errorMsgKey);
                                } else {
                                    NotificationsService.success($filter("translate")("modal.revisit.success"));

                                    ViewerService.refreshDetails(scope.dmsDocument.model.id);
                                }
                            return;
                            }, (error) => {
                                NotificationsService.backendMultiError(error, errorMsgKey);
                            return;
                            }

                        ).catch(error => {
                            console.error(error);
                            scope.close();
                        });
                        scope.close();
                        return;
                    }, () => {
                        this.formHelper.getFieldByInternal("revisitAt").api.focus();
                    }).catch(error => {
                        console.error(error);
                        return;
                    });
                    return;

                }

                /* Erstellt die finale Empfängerliste für die Wiedervorlage.
                 * Gruppen werden aufgelöst und überprüft, dass es keine doppelten
                 * Empfänger gibt.
                 */
                function prepareRecipientList() {
                    const selectedContent = scope.orgEntries.filter(entry => entry.selected);
                    let recipientList = [];
                    let idList = [];
                    let groupIndexList = [];
                    let tmpUser = {};
                    let tmpGroup;

                    for (let i = 0; i < selectedContent.length; i++) {
                        const entry = selectedContent[i].value;
                        if (entry.type === "user") {
                            tmpUser = {
                                id: entry.id,
                                name: entry.name,
                                fullname: entry.fullname
                            };
                            recipientList.push(tmpUser);
                            idList.push(tmpUser.id);
                        } else {
                            groupIndexList.push(i);
                        }
                    }

                    for (let groupIndex = 0; groupIndex < groupIndexList.length; groupIndex++) {
                        tmpGroup = selectedContent[groupIndexList[groupIndex]].value;
                        for (let userIndex = 0; userIndex < tmpGroup.users.length; userIndex++) {
                            tmpUser = {
                                id: tmpGroup.users[userIndex].id,
                                name: tmpGroup.users[userIndex].name,
                                fullname: tmpGroup.users[userIndex].fullname
                            };
                            if (idList.indexOf(tmpUser.id) == -1) {
                                idList.push(tmpUser.id);
                                recipientList.push(tmpUser);
                            }
                        }
                    }
                    return recipientList;
                }
            },
            template: require("!raw-loader!./eob.modal.revisit.html")
        };
    }
})();
