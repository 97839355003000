import {AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild} from "@angular/core";
import {fromEvent, Subscription} from "rxjs";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ContextMenuAction} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {EobContextMenuTemplateComponent} from "MODULES_PATH/context-menu/components/eob-context-menu-template/eob-context-menu-template.component";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {ChangeDetectorRef, Input} from "@angular/core";
import {take} from "rxjs/operators";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";

export interface SubmenuDimensions {
    clientRect: DOMRect;
    submenuHeight: number;
    submenuWidth: number;
    parentHeight: number;
    parentWidth: number;
    parentLeft: number;
    parentTop: number;
    bodyHeight: number;
}

export interface SubmenuPosition {
    left: number;
    top: number;
    height: number;
}

@Component({
    selector: "eob-submenu",
    templateUrl: "./eob-submenu.component.html",
    styleUrls: ["./eob-submenu.component.scss"]
})
export class EobSubmenuComponent extends EobContextMenuTemplateComponent implements AfterViewInit, OnDestroy {
    @Input() is2ndsubmenu: boolean;
    @Input() hasSubmenu: boolean;
    @Input() headerContent: string;

    contextMenuAction: ContextMenuAction;
    submenuStyle: JQLiteCssProperties = {display: "none"};
    type: string;

    private mainMenu: HTMLElement;
    private contextMenuActionElement: HTMLElement;
    private subscription: Subscription = new Subscription();
    private mouseEnterSubscription: Subscription = new Subscription();
    private mouseLeaveSubscription: Subscription = new Subscription();

    @ViewChild("submenu", {static: false}) private submenu: ElementRef<HTMLElement>;

    constructor(private contextMenuUtilsService: ContextMenuUtilsService, protected clientService: ClientService,
                protected messageService: MessageService,
                protected renderer: Renderer2, protected el: ElementRef<HTMLElement>, protected changeDetection: ChangeDetectorRef) {
        super(clientService, messageService, renderer);
    }

    ngAfterViewInit(): void {
        this.type = this.is2ndsubmenu ? "2ndSub" : "sub";
        const eventName: string = this.is2ndsubmenu ? InlineDialogEvent.OPEN_2ND_SUBMENU : InlineDialogEvent.OPEN_SUBMENU;

        this.subscription.add(this.messageService.subscribe(eventName, (payload: any) => {
            this.submenuStyle = {display: "none"};
            this.mainMenu = payload.mainMenu;
            this.contextMenuAction = payload.contextMenuAction;
            this.contextMenuActionElement = payload.event.target.closest("li");

            if (!this.clientService.isTouchDevice()) {
                this.mouseEnterSubscription.unsubscribe();

                this.mouseEnterSubscription = fromEvent(this.submenu.nativeElement, "mouseenter").subscribe(() => {
                    this.renderer.addClass(this.contextMenuActionElement, "hovered");
                });

                this.mouseLeaveSubscription = fromEvent(this.submenu.nativeElement, "mouseleave").pipe(take(1)).subscribe(() => {
                    if (!this.hasSubmenu) {
                        this.renderer.removeClass(this.contextMenuActionElement, "hovered");

                        this.submenuStyle = {display: "none"};
                        this.changeDetection.detectChanges();
                    }
                });
            }

            this.openSubmenu(payload);
        }));

        this.subscription.add(this.mouseLeaveSubscription);
        this.subscription.add(this.mouseEnterSubscription);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    open2ndSubmenu(payload: any): void {
        this.messageService.broadcast(InlineDialogEvent.OPEN_2ND_SUBMENU, {
            contextMenuAction: payload.contextMenuAction,
            event: payload.event,
            mainMenu: this.submenu.nativeElement
        });
    }

    navigateBack(event: Event): void {
        event.stopPropagation();
        event.stopImmediatePropagation();

        this.submenuStyle = {display: "none"};
        this.changeDetection.detectChanges();

        if (this.is2ndsubmenu) {
            $(this.mainMenu).css(this.contextMenuUtilsService.gContextmenuStyles.get("sub"));
        } else {
            $(this.mainMenu).css(this.contextMenuUtilsService.gContextmenuStyles.get("main"));
        }
    }

    private openSubmenu(payload: any): void {
        if (this.isPhone) {
            this.addClosedStyles(this.mainMenu);
        }

        this.submenuStyle = this.styleSubmenu(this.contextMenuAction.params.length, this.mainMenu, this.submenu.nativeElement, this.contextMenuActionElement);

        if (this.isPhone && !this.is2ndsubmenu) {
            this.contextMenuUtilsService.gContextmenuStyles.set(this.type, this.submenuStyle);
        }

        this.changeDetection.detectChanges();
    }

    private styleSubmenu(itemCount: number, mainEl: HTMLElement, submenu: HTMLElement, nativeElement: HTMLElement): JQLiteCssProperties {
        this.changeDetection.detectChanges();
        this.renderer.setStyle(submenu, "display", "block");

        let position: SubmenuPosition = {
            left: 0,
            top: 0,
            height: 0
        };

        const dimensions: SubmenuDimensions = {} as SubmenuDimensions;
        const approximateHeight: number = this.clientService.isTouchDevice() ? itemCount * 40 : itemCount * 32;

        dimensions.clientRect = nativeElement.getBoundingClientRect();
        dimensions.submenuHeight = approximateHeight;
        dimensions.submenuWidth = submenu.offsetWidth;
        dimensions.parentHeight = mainEl.offsetHeight;
        dimensions.parentWidth = mainEl.offsetWidth;
        dimensions.parentLeft = mainEl.getBoundingClientRect().left;
        dimensions.parentTop = mainEl.getBoundingClientRect().top;
        dimensions.bodyHeight = document.body.offsetHeight;

        if (this.isPhone) {
            dimensions.submenuHeight += 52; // 52px for header and padding

            return this.styleSubmenuPhone(dimensions);
        } else {
            /**
             * to position the menu we check several possibilities
             * 1. is the submenu too big to fit into the screen
             *  --> align the submenu at the top of the main menu and reduce its size
             *  to the size of the main menu and make it scroll
             * 2. is there enough space to drop the menu down
             *  --> else, we drop the submenu upwards
             * 3. If we drop it upwards, is there enough space to the top?
             *  --> if not, we align the submenu at the top position of the main menu
             * 4. if there is still not enough space in between to drop either up or down
             *  --> we position it at the top with a space of 7px to the top
             */

            if (dimensions.submenuHeight > dimensions.bodyHeight) {
                position.height = document.body.offsetHeight - 10;
            } else {
                position.height = approximateHeight;
            }

            if (dimensions.clientRect.left + nativeElement.offsetWidth + dimensions.submenuWidth + 8 > document.body.offsetWidth) {
                position.left = -dimensions.submenuWidth;
            } else {
                position.left = dimensions.parentWidth - 2; // -2 to reduce the gap that exists due toe the border left and right
            }

            if (dimensions.parentWidth + dimensions.parentLeft + dimensions.submenuWidth > document.body.offsetWidth) {
                position.left = dimensions.parentLeft - dimensions.submenuWidth;
            } else {
                position.left = dimensions.parentLeft + dimensions.parentWidth;
            }

            if (dimensions.clientRect.top + dimensions.submenuHeight > dimensions.bodyHeight) {
                position = this.alignSubmenu(position, dimensions);
            } else {
                position.top = dimensions.clientRect.top - 6;
            }

            return {
                display: "block",
                overflow: "auto",
                top: `${position.top}px`,
                left: `${position.left}px`,
                height: `${position.height}px`,
            };
        }
    }

    private alignSubmenu(position: SubmenuPosition, dimensions: SubmenuDimensions): SubmenuPosition {
        // we cannot drop it up because it would float out at the top of the window
        // try something else
        if (dimensions.clientRect.top + dimensions.clientRect.height - dimensions.submenuHeight < 0) {
            if (dimensions.submenuHeight > dimensions.bodyHeight) {
                position.top = 0;
            } else if (dimensions.parentTop + dimensions.submenuHeight > dimensions.bodyHeight) {
                const possibleTop: number = dimensions.clientRect.top + dimensions.clientRect.height - dimensions.submenuHeight;

                if (possibleTop < 0) {
                    position.top = 0;
                } else {
                    position.top = possibleTop;
                }
            } else if (dimensions.parentHeight > dimensions.bodyHeight) {
                // position.top = dimensions.clientRect.top - 7;
                position.top = dimensions.bodyHeight - dimensions.submenuHeight;
            } else {
                // align the submenu at the top of the parent menu
                position.top = dimensions.parentTop;
            }
        } else {
            // at this point we cannot drop the menu down, because it would float out of the window
            // align the submenu at the bottom of the parent contextmenu
            position.top = dimensions.bodyHeight - dimensions.submenuHeight - 10;
        }

        return position;
    }

    private styleSubmenuPhone(dimensions: SubmenuDimensions): JQLiteCssProperties {
        const scrollbar: boolean = dimensions.submenuHeight > dimensions.bodyHeight;

        if (scrollbar) {
            dimensions.submenuHeight = dimensions.bodyHeight;
        }

        const maxHeight: number = scrollbar ? dimensions.submenuHeight - 10 : dimensions.submenuHeight;
        const top: number = scrollbar ? 10 : dimensions.bodyHeight - dimensions.submenuHeight;

        return {
            "top": `${top}px`,
            "left": `${56}px`,
            "right": 0,
            "height": "auto",
            "max-height": `${maxHeight}px`,
            "min-height": `${maxHeight}px`,
            "overflow": "auto",
            "display": "block",
        };
    }
}
