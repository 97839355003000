import {BaseParameters} from "../interfaces/base-parameters.interface";
import {FieldType} from "../interfaces/field-type.interface";
import {FileProperties} from "../interfaces/file-properties.interface";
import {Rights} from "INTERFACES_PATH/rights.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ObjectLink} from "CORE_PATH/backend/interfaces/object-link.interface";
import {Group, User} from "INTERFACES_PATH/user.interface";

const map: WeakMap<any, any> = new WeakMap();
const internal: (a: any) => any = (object: any): any => {
    if (!map.has(object)) {
        map.set(object, {});
    }
    return map.get(object);
};

/**
 * Class representation of a DMS document model including rights and aggregated metadata (index data, base parameters, etc)
 */
export class DmsDocumentModel {
    rights: Rights;
    baseParameters: BaseParameters;
    fileProperties: FileProperties;
    fields: FieldType;
    parentFields: FieldType;
    name: string;
    internal: string;
    id: string | number;
    isLocked?: boolean;
    osid: string;
    guid?: string;
    objectTypeId: string;
    objectFlagsValue: string | number;
    mainType?: string | number;
    subType?: string | number;
    cabinetId?: string | number;
    isRegister?: boolean;
    isFolder?: boolean;
    isDocument?: boolean;
    isTypeless?: boolean;
    isinWfTray?: boolean;
    isReference?: boolean;
    isFavorite?: boolean;
    hasContent?: boolean;
    icon?: string;
    objectType?: "DOCUMENT" | "REGISTER" | "FOLDER";
    syncState?: number;
    subscriptions?: any[];
    tray?: string;
    eventDate?: string;
    action?: string;
    read?: boolean;
    objectCount?: string;
    aboGroup?: string;
    mailAddresses?: string[];
    groupsToBeNotified?: Group[];
    revisits?: any[];
    vtx?: any[];
    subscriptionObjects?: any[];
    hasVariants?: boolean;
    isShared?: boolean;
    hasNotes?: boolean;
    usersToBeNotified?: User[];
    variantData?: any;
    wfItem?: any;
    workflowId?: string;
    offlineObjects?: any;
    variantInfo?: any;
    links?: ObjectLink[];

    constructor(data: any, $injector: ng.auto.IInjectorService) {
        internal(this).ObjectTypeService = $injector.get("objectTypeService");
        internal(this).EnvironmentService = $injector.get("environmentService");
        internal(this).CacheManagerService = $injector.get("cacheManagerService");
        internal(this).FieldsetBuilderService = $injector.get("fieldsetBuilderService");

        // context items
        internal(this).RevisitModelService = $injector.get("revisitModelService");
        internal(this).VtxModelService = $injector.get("vtxModelService");
        internal(this).SubscriptionModelService = $injector.get("subscriptionModelService");
        internal(this).SubscriptionObjectModelService = $injector.get("subscriptionObjectModelService");
        internal(this).VariantModelService = $injector.get("variantModelService");
        internal(this).WfFileModelService = $injector.get("wfFileModelService");
        internal(this).DmsDocumentService = $injector.get("dmsDocumentService");
        internal(this).ValueUtilsService = $injector.get("valueUtilsService");

        // using the model to later be able to add the api at this point without interfering
        // customer scripts --> this means we have a model but not necessarily an api

        if (data.internal === undefined) {
            this.initFromBackend(data);
        } else {
            this.initFromModel(data);
        }
    }

    private initFromModel(data: any): void {
        Object.assign(this, data);

        // the following properties might be numbers, to make them indexable for dexie, and have to be turned back into a boolean
        this.hasContent = !!this.hasContent;
    }

    private initFromBackend(data: any): void {
        this.rights = {};
        this.baseParameters = {} as BaseParameters;
        this.fileProperties = {} as FileProperties;
        this.fields = {};
        this.parentFields = {};
        this.name = data.displayName;
        this.internal = data.internalName;
        this.id = data.osid; // backward compatibility
        this.osid = data.osid;
        this.objectTypeId = data.objectTypeId;
        this.objectFlagsValue = data.objectFlagsValue;

        const typeDef: any = internal(this).CacheManagerService.objectTypes.getById(this.objectTypeId);

        if (typeDef == void 0) {
            return;
        }

        const typeDefConfig: any = typeDef.model.config;
        const objectTypeFields: any = typeDef.api.getFlatFieldList();
        let parentObjectTypeFields: any[] = [];

        if (data.ecmParentFields != void 0 && data.ecmParentFields[0] != void 0) {
            const parentTypeDef: any = internal(this).CacheManagerService.objectTypes.getById(data.ecmParentFields[0].objectTypeId);
            parentObjectTypeFields = parentTypeDef.api.getFlatFieldList();
        }

        if (typeDefConfig != void 0 && data != void 0) {
            this.cabinetId = typeDefConfig.cabinetId;
            this.mainType = typeDefConfig.mainType;
            this.subType = typeDefConfig.mainType;
        }

        switch (data.objectType) {
            case "REGISTER":
                this.isRegister = true;
                this.objectType = "REGISTER";
                break;
            case "FOLDER":
                this.isFolder = true;
                this.objectType = "FOLDER";
                break;
            case "DOCUMENT":
            default:
                this.isDocument = true;
                this.objectType = "DOCUMENT";
                break;
        }

        if (data.rights != void 0 && Object.keys(data.rights).length > 0 && typeDefConfig.rights != void 0 && Object.keys(typeDefConfig.rights).length > 0) {
            // at this point we need to merge the current user rights (including clauses) with the combined user rights
            this.rights = {
                indexModify: data.rights.indexModify && typeDefConfig.rights.indexModify,
                objDelete: data.rights.objDelete && typeDefConfig.rights.objDelete,
                objExport: data.rights.objExport && typeDefConfig.rights.objExport,
                objModify: data.rights.objModify && typeDefConfig.rights.objModify
            };
        }

        this.transformFields(data, objectTypeFields, "fields", data.ecmSimpleFields);
        this.transformFields(data, parentObjectTypeFields, "parentFields", data.ecmParentFields);
        this.transformFileProperties(data);
        // transformation of file properties needs to be done first
        this.transformBaseParams(data);

        this.isTypeless = this._isTypeless();
        this.isinWfTray = this._isInWfTray();
        this.isReference = this._isReference();
        this.isFavorite = data.fav;
        this.syncState = data.sync;

        this.icon = this.getIconClass(data.iconId);

        this.addContextItems(data);
    }

    /**
     * returns true when the document exists only inside the workflow tray
     *
     * @returns {boolean}
     * @private
     */
    private _isInWfTray(): boolean {
        if (this.mainType == "99" || this.mainType == "0") {
            return false;
        }

        const flagsValue: any = this.objectFlagsValue != void 0 ? this.objectFlagsValue : "";

        // noinspection NonShortCircuitBooleanExpressionJS
        return !!((flagsValue ) & 0x80);
    }

    /**
     * returns true when the document has a maintype of 200,300 or an objecttypeid of -1
     *
     * @returns {boolean}
     * @private
     */
    private _isTypeless(): boolean {
        return internal(this).ObjectTypeService.isTypeless(this.objectTypeId);
    }

    /**
     * returns true if the current document a reference to another document
     * it is a reference when it is a green arrow to another dms document or it isw a link to an external archive
     *
     * @returns {boolean}
     * @private
     */
    private _isReference(): boolean {
        if (this.objectType != "DOCUMENT") {
            return false;
        }

        const systemId: any = this.baseParameters.systemId,
            foreignId: any = this.baseParameters.foreignId;

        return ((systemId === void 0 || systemId === "0" || systemId === "") && (foreignId !== "0" && foreignId !== void 0 && foreignId !== ""));
    }

    /**
     * Returns the appropriate icon class name for the given item
     *
     * @param iconId - The icon id.
     * @returns {string} Icon class for the passed document
     */
    private getIconClass(iconId: string): string {
        return internal(this).ObjectTypeService.getIconClass(this.objectTypeId, iconId, true, this.subType) as string;
    }

    /**
     * Map the file properties
     *
     * @param data
     */
    private transformFileProperties(data: any): void {

        if (data.fileProperties == void 0) {
            return;
        }

        for (const fileProperty of data.fileProperties) {
            switch (fileProperty.type) {
                case "COUNT":
                    this.fileProperties.fileCount = fileProperty.value;
                    this.hasContent = this.hasContent || this.fileProperties.fileCount > 0;
                    break;
                case "EXTENSION":
                    this.fileProperties.fileExtension = fileProperty.value;
                    break;
                case "MIMETYPEGROUP":
                    this.fileProperties.mimeTypeGroup = fileProperty.value;
                    break;
                case "SIZE":
                    this.fileProperties.fileSize = fileProperty.value;
                    break;
                case "ICONID":
                    this.fileProperties.mimeTypeIconId = fileProperty.value;
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * maps values of a param array to a key-value-pair solution  (object)
     * and sets quickaccess properties like "hasContent" or "isLocked" to the document itself
     *
     * it also extracts the main and subtype of the document
     *
     * @param data - raw data
     */
    private transformBaseParams(data: any): void {

        const typeConfig: any = internal(this).CacheManagerService.objectTypes.getById(this.objectTypeId).model.config;

        for (const baseParameter of data.baseParameters) {
            switch (baseParameter.type) {
                case "CREATOR" :
                    this.baseParameters.creator = baseParameter.value;
                    break;
                case "CREATED" :
                    this.baseParameters.created = internal(this).ValueUtilsService.dateToLocaleDate(baseParameter.value);
                    break;
                case "CREATION_DATE" :
                    this.baseParameters.creationDate = internal(this).ValueUtilsService.dateToLocaleDate(baseParameter.value);
                    break;
                case "MODIFIER" :
                    this.baseParameters.modifier = baseParameter.value;
                    break;
                case "MODIFIED" :
                    this.baseParameters.modifyDate = internal(this).ValueUtilsService.dateToLocaleDate(baseParameter.value);
                    break;
                case "OWNER" :
                    this.baseParameters.owner = baseParameter.value;
                    break;
                case "LINKS" :
                    this.baseParameters.links = parseInt(baseParameter.value, 10);
                    this.hasNotes = this.baseParameters.links != 0;
                    break;
                case "SYSTEM_ID" :
                    this.baseParameters.systemId = baseParameter.value;
                    break;
                case "FOREIGN_ID" :
                    this.baseParameters.foreignId = baseParameter.value;
                    break;
                case "ARCHIVIST" :
                    // DODO-8033: Luckily ARCHIVE_STATE comes everytime in before and we can make this conditional value.
                    this.baseParameters.archivist = (this.baseParameters.archiveState == "ARCHIVED") ? baseParameter.value : "";
                    break;
                case "ARCHIVE_DATE" :
                    // DODO-8033: Luckily ARCHIVE_STATE comes everytime in before and we can make this conditional value.
                    this.baseParameters.archiveDate = (this.baseParameters.archiveState == "ARCHIVED") ? internal(this).ValueUtilsService.dateToLocaleDate(baseParameter.value) : "";
                    break;
                case "ARCHIVE_STATE" :
                    this.baseParameters.archiveState = baseParameter.value;
                    break;
                case "VERSION" :
                    this.baseParameters.version = baseParameter.value;
                    this.hasVariants = baseParameter.value != "0";
                    break;
                case "RETENTION_DATE" :
                    this.baseParameters.retentionDate = internal(this).ValueUtilsService.dateToLocaleDate(baseParameter.value);
                    break;
                case "RETENTION_PLANNED_DATE" :
                    this.baseParameters.retentionPlannedDate = internal(this).ValueUtilsService.dateToLocaleDate(baseParameter.value);
                    break;
                case "DOCUMENTPAGECOUNT" :
                    this.baseParameters.pageCount = parseInt(baseParameter.value, 10);
                    break;
                case "SHARED_DOCUMENT" :
                    this.baseParameters.sharedDocument = parseInt(baseParameter.value, 10);
                    this.isShared = this.baseParameters.sharedDocument != 0;
                    break;
                case "LOCKED_USER" :
                    this.baseParameters.lockedUser = baseParameter.value;
                    break;
                case "LOCKED_USER_FULL_NAME" :
                    this.baseParameters.lockedUserFullName = baseParameter.value;
                    break;
                case "LOCKED_TIME" :
                    this.baseParameters.lockedTime = baseParameter.value;
                    break;
                case "LOCKED" :
                    this.baseParameters.locked = baseParameter.value;
                    this.isLocked = baseParameter.value != "UNLOCKED";
                    break;
                case "PDF_ANNOTATION_COUNT" :
                    this.baseParameters.annotations = parseInt(baseParameter.value, 10);
                    break;
                case "TEXT_NOTICE_COUNT" :
                    this.baseParameters.notes = parseInt(baseParameter.value, 10);
                    break;
            }
        }

        for (const sysField of data.systemFields) {
            switch (sysField.type) {
                case "OBJECT_COUNT":
                    this.baseParameters.objectCount = parseInt(sysField.value, 10);
                    this.hasContent = this.baseParameters.objectCount != 0;
                    break;
                case "OBJECT_MAIN":
                    this.mainType = typeConfig.mainType == void 0 ? this.getTypeCodeByName(sysField.value) : typeConfig.mainType;
                    this.subType = typeConfig.multiType ? this.getTypeCodeByName(sysField.value) : this.mainType;
                    break;
                case "OBJECT_FLAGS":
                    this.baseParameters.archiveState = sysField.value;
                    break;
                case "OBJECT_LOCKUSER" :
                    this.baseParameters.locked = sysField.value;
                    this.isLocked = sysField.value != "UNLOCKED";
                    break;
                case "OBJECT_VERID" :
                    if (sysField.value != "" && parseInt(sysField.value, 10) > 0) {
                        this.hasVariants = true;
                        this.baseParameters.version = sysField.value;
                    }
                    break;
                case "OBJECT_MIMETYPEID":
                    this.addMissingFileProperties(sysField.value);
                    break;
                case "OBJECT_SIGNSTATE" :
                    if (sysField.value != "" && parseInt(sysField.value, 10) > 0) {
                        this.baseParameters.signedState = sysField.value;
                    }
                    break;
            }

            for (const base in data) {
                if(data[base] && data[base] !== null) {
                    if (base === "fav") {
                        this.baseParameters.favorite = data[base];
                    }
                }
            }

            this.detectGreenArrowDocument();
        }
    }

    /**
     * From enaio beans 2.8.0, which is the base library of the enaio appConnector,
     * the detection for green arrow documents is removed. Frontend clients must do it
     * on their own. Also in the DMS microservice we will not have this detection too.
     */
    private detectGreenArrowDocument(): void {
        if (!this.hasContent && this.baseParameters.archiveState == "REFERENCE") {
            const systemId: string | number = this.baseParameters.systemId;
            this.hasContent = (systemId == "0" || systemId == "");
        }
    }

    /**
     * Returns the subtype of a document
     * The subtypes go from 1 - 8 (8 is currently not supported)
     * The subtype is needed when the document is not bound to a certain maintype and can contain any type
     * (Modulübergreifende Dokumenttypen)
     *
     * @param {string} value - The value of the systemfield OBJECT_MAIN
     * @returns {string} - subtype number as string
     */
    private getTypeCodeByName(value: string): string {
        switch (value) {
            case "GRAYSCALE" :
                return "1";
            case "BLACKWHITE":
                return "2";
            case "COLOR":
                return "3";
            case "WINDOWS":
                return "4";
            case "MULTIMEDIA":
                return "5";
            case "EMAIL":
                return "6";
            case "XML":
                return "7";
            // case "CONTAINER":
            //     return "8";
        }
        return "";
    }

    /**
     * If the file properties are not complete (because we don't get all of them with lol)
     * the missing ones will be added here
     *
     * @param {string} mimeTypeId - The mimeTypeId of the object (systemFields.OBJECT_MIMETYPEID)
     * @returns {any} - a file properties object
     */
    private addMissingFileProperties(mimeTypeId: string): void {
        if (this.mainType == "99" || this.mainType == "0") {
           return;
        }

        if (this.fileProperties.fileExtension == void 0 || this.fileProperties.mimeTypeGroup == void 0 || this.fileProperties.mimeTypeIconId == void 0) {
            const fileProperties: any = internal(this).EnvironmentService.getMimetypeById(mimeTypeId);
            if(fileProperties == void 0) {
               return;
            }
            this.fileProperties.fileExtension = fileProperties.extension;
            this.fileProperties.mimeTypeGroup = fileProperties.mimetypegroup;
            this.fileProperties.mimeTypeIconId = fileProperties.osiconid;
        }
    }

    /**
     *  Maps the indexdata to a key-value structure for common fields with values
     *
     *  Grids will be simplified with columns with the following properties
     *      name - the displayname
     *      intenal - the internal name of the column
     *      type - the type of the column
     *
     * @param data - raw data
     * @param objectTypeFields - the objecttype field list
     * @param {string} propertyKey Which property of the current instance should be modified
     * @param rawFields
     */
    private transformFields(data: any, objectTypeFields: any, propertyKey: string, rawFields: any): any {

        if (this[propertyKey] == void 0) {
            this[propertyKey] = {};
        }

        for (const i in rawFields) {
            const field: any = rawFields[i];
            let fieldInternal: string = field.internalName;

            // a single & isn't displayed, as it is handled like a hotkey mapping in WinAPI. && shows an escaped &.
            // Does not apply to lists or catalogues, however. Top kek. DODO-10468, DODO-11963
            if (field.type == "RADIO") {
                fieldInternal = this.findRadioInternal(field.internalName, objectTypeFields);
                this[propertyKey][fieldInternal] = field.value.replace(/&&?/g, match => match.length % 2 == 0 ? match.substring(0, match.length / 2) : "");
            } else {
                this[propertyKey][fieldInternal] = field.value;
            }
        }

        for (const j in data.ecmTableFields) {
            const table: any = data.ecmTableFields[j];

            this[propertyKey][table.internalName] = {
                columns: [],
                rows: []
            };

            for (const c in table.columns) {
                const col: any = table.columns[c];

                this[propertyKey][table.internalName].columns.push({
                    name: col.displayName,
                    internal: col.internalName,
                    type: this.parseType(col.type)
                });
            }

            for (const r in table.value) {
                const rec: any = table.value[r].ecmSimpleFields;
                const row: any[] = [];

                for (const cell in rec) {
                    row.push(rec[cell].value);
                }

                this[propertyKey][table.internalName].rows.push(row);
            }
        }
    }

    /**
     * Searches the real internal name of the radio because once the radiobuttons are inside a group,
     * the appConnector returns the internal name of the radio group instead the name of the first radiobutton
     *
     * @param {string} internal - the internal name of the field we get from the appconnector
     * @param fields - the collection of all fields assigned to this objecttype
     * @returns {string} - the internal of the first radiobutton of the radio group
     */
    private findRadioInternal(radioInternal: string, fields: any): string {
        for (const i in fields) {
            if (fields[i].type != "radio") {
                continue;
            }

            if (fields[i].groupInternal == radioInternal) {
                return fields[i].internal;
            }
        }

        return radioInternal;
    }

    /**
     * Maps the AppConnector field types to bluebird field types
     *
     * @param {string} restType The type acquired from the AppConnector
     * @returns {string} The mapping to be used inside the bluebird client
     */
    private parseType(restType: string): string {
        const typeMap: any = {
            TEXT: "text",
            DATE: "date",
            INTEGER: "number",
            DECIMAL: "decimal",
            TIME: "time",
            DATETIME: "datetime",
            LIST: "grid",
            TABLE: "grid"
        };

        return typeMap[restType];
    }

    /**
     * creates and adds contextspecific models for inboxes or other relevant states
     * contextspecific models will be added to an array of objects with the name of the current context
     *
     * @param data - raw backend data
     * @returns {any}
     */
    private addContextItems(data: any): void {
        if (data.notifications != void 0) {
            // eslint-disable-next-line prefer-const
            for (let [inboxType, notifications] of Object.entries(data.notifications)) {
                // const notifications = data.notifications[inboxType];
                notifications = Array.from(notifications as any || []);
                for(const notification of notifications as any[]) {
                    switch (inboxType) {
                        case "revisit":
                            if (this.revisits == void 0) {
                                this.revisits = [];
                            }

                            this.revisits.push(internal(this).RevisitModelService.createRevisit(notification));
                            break;
                        case "abo":
                            if (this.subscriptions == void 0) {
                                this.subscriptions = [];
                            }

                            this.subscriptions.push(internal(this).SubscriptionModelService.createSubscription(notification, this));
                            break;
                        case "subscriptionObjects":
                            if (this.subscriptionObjects == void 0) {
                                this.subscriptionObjects = [];
                            }

                            this.subscriptionObjects.push(internal(this).SubscriptionObjectModelService.createSubscriptionObject(notification));
                            break;
                    }
                }
            }
        }

        // Volltextergebnis
        if (data.vtxscore != void 0) {
            this.vtx = [
                internal(this).VtxModelService.createVtx(data)
            ];
        }

        // Verlauf
        let found = false;

        for (const field of data.ecmSimpleFields) {
            if (field.internalName == "enaio:RECENT_OBJECT_ACTION") {
                found = true;
                this.fields["enaio:RECENT_OBJECT_ACTION"] = field.value;
                break;
            }
        }

        if (!found && this.fields["enaio:RECENT_OBJECT_ACTION"] != void 0) {
            delete this.fields["enaio:RECENT_OBJECT_ACTION"];
        }

        // Variantenmanagement
        if (data.variantTree || data.variantInfo) {
            const variantData: any = internal(this).VariantModelService.createVariantData(data, this.osid);
            if (variantData != void 0 && variantData.model != void 0) {
                this.variantData = [variantData];
            }
        }

        // Workflowakte
        if (data.wfItem != void 0) {
            this.wfItem = [
                internal(this).WfFileModelService.createWfFile(data)
            ];
        }
    }
}
