angular.module("eob.core").factory("wfFileApiService", WfFileApiService);

WfFileApiService.$inject = [];

export default function WfFileApiService() {

    return {
        newWfFile,
    };

    function newWfFile(file, formHelper, grids){
        return new WfFile(file, formHelper, grids);
    }

    /**
     * Create a WfFile object with access to general functions to modify the files.
     *
     * @param file - The workflow file object
     * @param formHelper - The formHelper api
     * @param grids - The file areas
     * @constructor
     */
    function WfFile(file, formHelper, grids) {
        let self = this;

        // DODO-10568:
        // Previous we did a deep copy of the model. I've removed this because the two places where
        // we create a WfFile don't need a deep copy rather it produce a unlink to the model in the
        // dmsDocument. If we set a model property of WfFile the dmsDocument model is not affected
        // and other parts read the dmsDocument.wfFile and get old informations.
        self.model = (file.model == void 0) ? file : file.model;

        self.api = {
            moveToWork,
            moveToInfo,
            remove,
            setDeletable,
            setMovable
        };

        /**
         * moves the file to the work area
         */
        function moveToWork() {
            if (!self.model.workspace) {
                self.model.workspace = true;

                setTimeout(() => {
                    if (grids.work != void 0) {
                        grids.info.deleteItem(formHelper.getFile(self.model.id).dmsDocument, true);
                        grids.work.addItem(formHelper.getFile(self.model.id).dmsDocument, true);
                    }
                })

                self.model.modified = true;
            }
        }

        /**
         * moves to file to the info area
         */
        function moveToInfo() {
            if (self.model.workspace) {
                self.model.workspace = false;

                setTimeout(() => {
                    if (grids.info != void 0) {
                        grids.work.deleteItem(formHelper.getFile(self.model.id).dmsDocument, true);
                        grids.info.addItem(formHelper.getFile(self.model.id).dmsDocument, true);
                    }
                })

                self.model.modified = true;
            }
        }

        /**
         * removes the file from the current workflow
         * the file will not be deleted
         */
        function remove() {
            formHelper.removeFile(self.model.id);
        }

        /**
         * Sets a file deletable or not deletable
         * The User may or may not be able to remove the file using the contextmenu
         * @param bool - The parameter if its deletable or not
         */
        function setDeletable(bool) {
            self.model.deletable = (typeof(bool) == "boolean" && bool) || bool == "true";
            self.model.modified = true;
        }

        /**
         * Sets a file movable or not movable
         * The User may or may not be able to move the file across the file areas
         * @param bool - The parameter if its deletable or not
         */
        function setMovable(bool) {
            self.model.movable = (typeof(bool) == "boolean" && bool) || bool == "true";
            self.model.modified = true;
        }
    }

}
