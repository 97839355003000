import {GridContentUtilsService} from "../../../app/modules/grid/services/grid-content-utils.service";
import {DropzoneMessage} from "../../../app/modules/dropzone/components/ems-dropzone/ems-dropzone.component";
import {from, Subscription} from "rxjs";
import {takeWhile, timeout} from "rxjs/operators";
import {ExternalTrayEvents} from "../../../app/modules/external-tray/enums/external-tray-events.enum";

require("ag-grid-enterprise/dist/ag-grid-enterprise.min.js");
require("COMPONENTS_PATH/eob-dropzone/eob.dropzone.mod.js");
require("SERVICES_PATH/eob.environment.srv.js");

require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

const he = require("he"),
    Dropzone = require("dropzone");

angular.module("eob.framework").directive("eobDropzone", EobDropzone);

EobDropzone.$inject = ["environmentService", "$filter", "notificationsService", "backendService", "asIniService", "$timeout",
    "$rootScope", "$eobConfig", "profileService", "clientService", "progressbarService", "layoutManagerService", "externalTrayService",
    "$stateParams", "cacheManagerService", "authenticationService", "httpService", "messageService"];

/**
 * A directive for dropping files into a panel.
 * @scopeparam {Object} data - The dropzone config.
 * @scopeparam {boolean} loadExternal - gets the content from the backend
 */
// eslint-disable-next-line max-params
export default function EobDropzone(EnvironmentService, $filter, NotificationsService, BackendService, AsIniService, $timeout,
                                    $rootScope, $eobConfig, ProfileService, ClientService, ProgressbarService, LayoutManagerService,
                                    ExternalTrayService, $stateParams, CacheManagerService, AuthenticationService, HttpService,
                                    MessageService) {
    return {
        scope: {
            dropzoneConfig: "=",
            loadExternal: "=",
            showButtons: "="
        },
        restrict: "E",
        replace: true,
        async link(scope, element) {
            const subs = new Subscription();
            let headers = {};

            subs.add(AuthenticationService.getStatusEvent().subscribe(event => {
                if (event.requestHeaders) {
                    headers = event.requestHeaders.reduce((acc, v) => {
                        acc[v.key] = v.value
                        return acc;
                    }, {})
                    if (scope.dropzoneConfiguration) {
                        scope.dropzoneConfiguration.options.headers = headers;
                    }
                }
            }));

            scope.dropZoneMsgDefault = false;
            scope.minimized = false;
            scope.isLoaded = false;
            scope.preselectedTrayElementKey = undefined;
            scope.trayElements = undefined;
            scope.selectionInfo = "";
            scope.showSelectionInfo = false;
            scope.showExternalTrayTab = false;
            scope.scriptObject = {};
            scope.isForcedPhoneLayout = ClientService.isForcedPhoneLayout();

            let fullUploadURL = `${ProfileService.getCurrentBaseUrl() + $eobConfig.getOswebBase()}/fileUpload.do`;
            let isLocalClient = ClientService.isLocalClient();
            let mainType = scope.dropzoneConfig.mainType;

            // need null as the default because of reasons ... (dropzone needs this)
            let maxFiles = ["4", "5", "6", "7", "8"].indexOf(mainType) != -1 ? 1 : null;
            let acceptedFiles = ClientService.isMobile() ? "" : getAcceptedFiles();
            let externalFilterString = "";
            let dropZoneMsg = "";
            let templateConfiguration = null;
            let previewTemplate = document.querySelector("#preview-template");
            let isInitContentLoaded = false;
            let isAddingFile = false;
            let cancelPromise, cancel;
            scope.activeTab = "files"

            const isCreateGreenArrowOnPhone = ($stateParams.mode == "reference") && ClientService.isPhone();

            let selectedItems = {
                template: {
                    content: null,
                    info: $filter("translate")("dropzone.template.selected"),
                    isActive: false
                },
                trayItem: {
                    content: null,
                    info: $filter("translate")("dropzone.tray.item.selected"),
                    isActive: false
                },
                files: {
                    content: [],
                    info: $filter("translate")("dropzone.files.selected"),
                    isActive: false
                }
            };

            scope.switchToTab = switchToTab;
            scope.onTraySelectionChange = onTraySelectionChange;
            // show dropzone and external tray
            scope.showCustomContent = scope.dropzoneConfig.showCustomContent !== void 0 ? scope.dropzoneConfig.showCustomContent : true;
            scope.showMobileSource = showMobileSource;
            scope.isMobileImageUpload = !isDropzoneClickable();

            let progressBarContainer = element.find(".dropzone-content-box")[0];
            let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", progressBarContainer, true);

            if (scope.dropzoneConfig.isModal) {
                scope.dropzoneConfig.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => {
                    destroy();
                });
            }

            initDropzone();

            if (mainType == "4" && !scope.dropzoneConfig.hideTemplates) {
                let templates = EnvironmentService.getTemplates(scope.dropzoneConfig.objectTypeId);
                if (templates.length > 0) {
                    scope.showTemplates = true;
                    initTemplateGrid(templates);
                }
            }

            if (isLocalClient && scope.showCustomContent) {
                await initTrayElementsAsync();
            }

            initGreenArrowMode();

            $timeout(() => {
                let initialTab = scope.dropzoneConfig.mode != void 0 && scope.dropzoneConfig.mode != "" ? scope.dropzoneConfig.mode : scope.activeTab

                if (!scope.showCustomContent) {
                    initialTab = "template";
                } else if (initialTab === "trayItem" && !scope.showExternalTrayTab) {
                    initialTab = "files";
                }

                scope.switchToTab(initialTab);
                scope.isLoaded = true;
            }, 0);

            async function initDropzone() {
                scope.dropzoneConfiguration = {
                    options: {
                        url: fullUploadURL,
                        maxFilesize: "4096",
                        maxFiles,
                        addRemoveLinks: true,
                        uploadMultiple: false,
                        previewTemplate: previewTemplate ? previewTemplate.innerHTML : "",
                        acceptedFiles,
                        clickable: isDropzoneClickable(),
                        timeout: 1800 * 1000,
                        async init() {
                            let dropzone = this;
                            $rootScope.dropzone = this;
                            window.dropzone = this;
                            MessageService.broadcast(DropzoneMessage.DROPZONE_INITIALISED, {});
                            let dzContent = angular.element(dropzone.element.children[0]);
                            if (scope.loadExternal) {
                                try {
                                    cancelPromise = new Promise(resolve => {
                                        cancel = resolve;
                                    });

                                    let httpConfig = {
                                        timeout: cancelPromise // it is created while init
                                    };

                                    let response = await BackendService.get(`/documentfiles/${scope.dropzoneConfig.id}`, "", httpConfig);

                                    scope.loadableFiles = response.data.files;
                                    appendDefaultMessage(dzContent)

                                    if (response.data.files && response.data.files.length) {
                                        progressBar.show();
                                        if (isCreateGreenArrowOnPhone) {
                                            await loadDocumentPreviewAsync(1);
                                            element.find(".dz-size").css("display", "none");
                                        } else {
                                            await loadDocumentAsync(dropzone, undefined);
                                        }
                                    } else {
                                        onInitContentDone(dzContent);
                                    }
                                } catch (error) {
                                    if (error.type !== "WEB_HTTP_REQUEST_CANCELED" && !cancelPromise.cancelled) {
                                        NotificationsService.backendError(error, "dropzone.responseerror");
                                    }
                                }
                            } else {
                                appendDefaultMessage(dzContent)
                                onInitContentDone(dzContent);
                            }
                        },
                        accept(file, done) {
                            if (ClientService.isMobile()) {
                                let acceptedFileExtensions = getAcceptedFiles();
                                let currentFileExtension = `.${file.name.split(".").pop()}`.toLowerCase()
                                if (acceptedFileExtensions.indexOf(currentFileExtension) != -1) {
                                    done()
                                } else {
                                    // do the notification
                                    done("Error") // The error callback will  handle the notification
                                }
                            } else {
                                done();
                            }
                        },
                        dictDefaultMessage: "",
                        dictRemoveFile: "x",
                        dictCancelUpload: $filter("translate")("dropzone.cancel.upload"),
                        dictCancelUploadConfirmation: $filter("translate")("dropzone.cancel.upload.confirmation"),
                        headers
                    },
                    "eventHandlers": {
                        "addedfile"() {
                            dropZoneMsg = "";
                            isAddingFile = true;
                            scope.$emit("dropzone.content.queued")
                        },
                        "thumbnail"(file, url) {
                            const placeholder = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTIwIDE1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIwIDE1MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNGRkZGRkY7fS5zdDF7ZmlsbDojRURFREVEO30uc3Qye2ZpbGw6I0RDRENEQzt9PC9zdHlsZT48cmVjdCBjbGFzcz0ic3QwIiB3aWR0aD0iMTIwIiBoZWlnaHQ9IjE0OS44Ii8+PHJlY3QgeD0iMjEiIHk9IjI1LjIiIGNsYXNzPSJzdDEiIHdpZHRoPSI3NyIgaGVpZ2h0PSIzLjciLz48cmVjdCB4PSIyMSIgeT0iMzIuMiIgY2xhc3M9InN0MSIgd2lkdGg9Ijc3IiBoZWlnaHQ9IjQ4LjciLz48cGF0aCBjbGFzcz0ic3QyIiBkPSJNODMuOCwwIi8+PHBhdGggY2xhc3M9InN0MSIgZD0iTTgxLDBIMHYxNDkuOGgxMjBWMzguMUw4MSwweiBNMTEzLDE0My41SDdWN2w2NiwwTDczLDQ2bDQwLDBWMTQzLjV6IE03OSw0MFY2LjdMMTEyLjIsNDBMNzksNDB6Ii8+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI3OSw0MCA3OSw2LjcgMTEyLjIsNDAgIi8+PHJlY3QgeD0iMjEiIHk9Ijg0LjEiIGNsYXNzPSJzdDEiIHdpZHRoPSI3NyIgaGVpZ2h0PSIzLjciLz48L3N2Zz4=";
                            const img = file.previewElement.querySelector(`img[alt="${file.name}"]`);

                            if (/\[object[\s\w\d%]+\]$/.test(img.src)) {
                                img.src = placeholder
                            }

                            img.onerror = () => {
                                img.src = placeholder
                            }
                        },
                        "error"(file) {
                            if (!file.accepted) {
                                dropZoneMsg = $filter("translate")("dropzone.invalidfiletype");
                                this.removeFile(file);
                            }
                        },
                        "removedfile"(file) {
                            selectedItems.files.content.splice(selectedItems.files.content.indexOf(file.guid), 1);
                            scope.dropzoneConfig.modified = true;
                            if (selectedItems.files.content.length == 0) {
                                scope.scriptObject = {};
                                setSelection("files");
                                scope.showSelectionInfo = false
                            }
                            if (selectedItems.files.content.length > 0) {
                                setSelection("files");
                            }
                            scope.$emit("dropzone.content.changed")
                            scope.$apply();
                        },
                        "success"(file, response) {
                            // The old backend tell the client it return json but return a string.
                            // With the microservice it is hard to do such a answer and we return a normal json object.
                            file.guid = (EnvironmentService.isMicroserviceBackend()) ? response.cid : response;
                            selectedItems.files.content.push(file.guid);
                            scope.dropzoneConfig.modified = true;
                            scope.switchToTab("files");
                            setSelection("files");
                            scope.$apply();
                        },
                        "maxfilesexceeded"() {
                            dropZoneMsg = $filter("translate")("dropzone.maxfilesexceeded");
                        },
                        "queuecomplete"() {
                            if (dropZoneMsg) {
                                NotificationsService.info(dropZoneMsg);
                            }
                            isAddingFile = false;

                            if (isInitContentLoaded) {
                                scope.$emit("dropzone.content.changed")
                            }

                        },
                        "reset"() {

                        },
                        "canceled"() {
                            isAddingFile = false;
                            scope.$emit("dropzone.content.changed")
                        }
                    }
                };

                if (!isDropzoneClickable()) {
                    element.find("#mobileSourceSelection").css("display", "flex");
                }
            }

            function initTemplateGrid(templates) {
                let templateIcon = "";
                let content = [];

                // only allow the user to select the "no template" option, if the user creates it from scratch
                // editing content does not allow to select the "no template" option
                if (!scope.dropzoneConfig.isEditContent) {
                    content.push({
                        alias: "",
                        namespace: $filter("translate")("dropzone.no.template.selected"),
                        id: "-1"
                    });
                }

                for (let i in templates) {
                    if (templates[i].nameSpace == "Word") {
                        templateIcon = "<i class=\"icon-24-namespace-word\"></i>";
                    } else if (templates[i].nameSpace == "PPT") {
                        templateIcon = "<i class=\"icon-24-namespace-ppt\"></i>";
                    } else if (templates[i].nameSpace == "PDF") {
                        templateIcon = "<i class=\"icon-24-namespace-pdf\"></i>";
                    } else if (templates[i].nameSpace == "Excel") {
                        templateIcon = "<i class=\"icon-24-namespace-excel\"></i>";
                    } else {
                        templateIcon = "<i class=\"icon-24-namespace-default\"></i>";
                    }

                    content.push({
                        alias: templates[i].alias,
                        namespace: templates[i].nameSpace,
                        id: templates[i].id,
                        icon: templateIcon,
                        extension: templates[i].extension
                    });
                }

                let colDefs = [{
                    suppressSizeToFit: true,
                    field: "icon",
                    width: 45,
                    isIconCell: true,
                    suppressMovable: true,
                    headerComponentParams: GridContentUtilsService.getHeaderComponentParams("<span><i class=\"icon-24-template-type\"></i></span>", "width: 10px; right: 1px"),
                    cellRenderer(params) {
                        return params.value == void 0 ? "" : params.value;
                    }
                }, {
                    headerName: $filter("translate")("dropzone.template.name"),
                    field: "namespace",
                    suppressMovable: true,
                    suppressSizeToFit: false,
                    sortingOrder: ["asc", "desc"]
                }, {
                    headerName: $filter("translate")("dropzone.template.alias"),
                    suppressSizeToFit: false,
                    field: "alias",
                    suppressMovable: true,
                    sortingOrder: ["asc", "desc"]
                }];

                templateConfiguration = AsIniService.getTemplateConfiguration(scope.dropzoneConfig.objectTypeId);

                if ((templateConfiguration !== void 0) && (templateConfiguration.sortColumn !== void 0) && (templateConfiguration.sortDirection !== void 0)) {
                    let colDef = colDefs[templateConfiguration.sortColumn];

                    if (colDef !== void 0) {
                        colDef.sort = templateConfiguration.sortDirection;
                    }
                }

                scope.options = {
                    defaultColDef: {
                        sortable: true,
                        resizable: true,
                        filter: true,
                        suppressMenu: true
                    },
                    appSpecific: {},
                    columnDefs: colDefs,
                    rowData: content,
                    rowHeight: LayoutManagerService.isTouchLayoutActive() ? 40 : 32,
                    headerHeight: LayoutManagerService.isTouchLayoutActive() ? 40 : 32,
                    rowSelection: "single",
                    suppressContextMenu: true,
                    suppressNoRowsOverlay: true,
                    suppressPropertyNamesCheck: true,
                    suppressMultiSort: true,
                    isExternalFilterPresent,
                    doesExternalFilterPass,
                    onGridSizeChanged() {
                        if (scope.options.api != void 0) {
                            scope.options.api.sizeColumnsToFit();
                        }
                    },
                    onRowClicked(row) {
                        if (row.data == void 0 || row.data.id != "-1") {
                            templateConfiguration.selectedEntry = row.data.alias;
                        }
                        selectedItems.template.content = row.data;
                        setSelection("template");

                        scope.$emit("enable.save.button", true);
                        scope.$apply();
                    },
                    onGridReady(event) {
                        event.api.forEachNode((node, idx) => {
                            if (scope.dropzoneConfig.template != void 0 && content[idx].id == scope.dropzoneConfig.template.id) {
                                node.setSelected(true);
                                selectedItems.template.content = node.data;
                                setDropzoneContent(scope.activeTab);
                            } else if ((templateConfiguration !== void 0) && (templateConfiguration.selectedEntry !== void 0)) {
                                if (content[idx].alias == templateConfiguration.selectedEntry) {
                                    node.setSelected(true);
                                    selectedItems.template.content = node.data;
                                    setDropzoneContent(scope.activeTab)
                                }
                            } else if (content[idx].id == "-1") {
                                node.setSelected(true);
                                selectedItems.template.content = node.data;
                                setDropzoneContent(scope.activeTab)
                            }
                        });
                    },
                    onAfterSortChanged() {
                        let allColumns = this.columnApi.getAllColumns();

                        for (let i = 0; i < allColumns.length; i++) {
                            if (allColumns[i].sort != null) {
                                templateConfiguration.sortColumn = i.toString();
                                templateConfiguration.sortDirection = allColumns[i].sort;
                                // EnvironmentService.setDropzoneContent(selectedItems.files.content, selectedItems.template.isActive, scope.dropzoneConfig, selectedItems.template.content, templateConfiguration);
                                break;
                            }
                        }
                    }
                };
            }

            async function initTrayElementsAsync() {
                if ($stateParams.mode === "reference") {
                    scope.showExternalTrayTab = false;
                    return;
                }

                let allowedTypes = EnvironmentService.getAllowedFileExtByMainType(mainType).split(",");
                let trayElements = await ExternalTrayService.getExternalTrayItemsByType(allowedTypes);

                scope.preselectedTrayElementKey = (scope.dropzoneConfig.mode === "trayItem" && scope.dropzoneConfig.files !== void 0) ? scope.dropzoneConfig.files.groupKey : undefined;
                scope.showExternalTrayTab = trayElements.length > 0;
                scope.trayElements = trayElements;

                subs.add(MessageService.subscribe(ExternalTrayEvents.TRAY_ELEMENTS_CHANGED, async() => {
                    let updatedTrayElements = await ExternalTrayService.getExternalTrayItemsByType(allowedTypes);
                    scope.showExternalTrayTab = updatedTrayElements.length > 0;
                    scope.trayElements = updatedTrayElements;
                    // due to lagged communication from ajs to ax
                    scope.$apply();

                    if (scope.activeTab === "trayItem" && !scope.showExternalTrayTab) {
                        switchToTab("files");
                    }
                }));
            }

            function onTraySelectionChange(trayItem) {
                selectedItems.trayItem.content = trayItem;

                if (scope.activeTab === "trayItem") {
                    setSelection("trayItem");
                    scope.$emit("enable.save.button", true);
                }
            }

            function switchToTab(tab) {
                element.find(".nav-tabs li.active").removeClass("active");

                let tabClass = `.${tab}-tab`;

                scope.activeTab = tab;

                element.find(tabClass).addClass("active");

                if (tab === "files") {
                    scope.$emit("enable.save.button", selectedItems.files.content.length > 0);

                    if (scope.isMobileImageUpload) {
                        element.find("#mobileSourceSelection").css("display", "flex");
                    } else {
                        element.find("#mobileSourceSelection").css("display", "none");
                    }
                } else {
                    element.find("#mobileSourceSelection").css("display", "none");
                    scope.$emit("enable.save.button", true);
                }

                setSelectedItemsInactive();
                setSelection(tab); // Diana 20.06.20: Removed surrounding if (!isInitial)
            }

            function isDropzoneClickable() {
                // For W-Doc and XML or if we are not on cordova the dropzone acts as usual. Otherwise we
                // handle camera and picture gallery manually in the mobile app.
                // noinspection OverlyComplexBooleanExpressionJS
                return (mainType == 4 || mainType == 7 || !ClientService.isAndroid() || !ClientService.isMobile() || $stateParams.mode == "reference" || !scope.showCustomContent);
            }

            function showMobileSource(source) {
                if (source == "camera") {
                    if (mainType == 5) {
                        navigator.device.capture.captureVideo(insertMobileMediaIntoDropzone, null, {
                            limit: 1
                        });
                    } else {
                        navigator.device.capture.captureImage(insertMobileMediaIntoDropzone, null, {
                            limit: 1
                        });
                    }
                } else {
                    let mediaType = (mainType == 5) ? navigator.camera.MediaType.VIDEO : navigator.camera.MediaType.PICTURE;

                    navigator.camera.getPicture(insertMobileMediaIntoDropzone, null, {
                        quality: 100,
                        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
                        destinationType: navigator.camera.DestinationType.FILE_URI,
                        mediaType
                    });
                }
            }

            /**
             * Cordova can only work with none async functions. Therefore this
             * middleware function on the way to async.
             */
            function insertMobileMediaIntoDropzone(mediaFiles) {
                insertMobileMediaIntoDropzoneAsync(mediaFiles);
            }

            async function insertMobileMediaIntoDropzoneAsync(mediaFiles) {
                if (!Array.isArray(mediaFiles)) {
                    mediaFiles = [mediaFiles];
                }

                for (let i in mediaFiles) {
                    // Capture-Plugin return a object while camera plugin return a path.
                    if (mediaFiles[i] instanceof Object) {
                        mediaFiles[i] = mediaFiles[i].fullPath;
                    }

                    mediaFiles[i] = await ClientService.correctFilePathAsync(mediaFiles[i]);
                }

                ExternalTrayService.insertTrayItemInDropzone({
                    filePaths: mediaFiles
                });
            }

            function setSelectedItemsInactive() {
                for (let tab in selectedItems) {
                    if (selectedItems[tab].isActive) {
                        selectedItems[tab].isActive = false;
                    }
                }
            }

            function setSelection(mode, name) {
                let showInfo = false;
                setSelectedItemsInactive();
                selectedItems[mode].isActive = true;

                // if no Item is active(selected) then we do not show the selection info
                for (let index in selectedItems) {
                    if (selectedItems[index].isActive) {
                        showInfo = true;
                        break;
                    }
                }

                scope.showSelectionInfo = showInfo;

                // The population of the dropzone has to be delayed a bit, because otherwise sometimes the filename isn't set inside the <span>
                setTimeout(() => {
                    let itemName;
                    let files;

                    switch (mode) {
                        case "trayItem":
                            files = (selectedItems[mode].content && selectedItems[mode].content.fileNames) ? selectedItems[mode].content.fileNames : [];
                            itemName = selectedItems[mode].content ? selectedItems[mode].content.displayTitle : "";
                            break;
                        case "template":
                            if (selectedItems[mode].content) {
                                itemName = selectedItems[mode].content.alias != "" ? selectedItems[mode].content.alias : selectedItems[mode].content.namespace;
                                files = [itemName];
                            } else {
                                itemName = name != void 0 ? name : element.find(".dz-filename span").text();
                                files = [itemName];
                            }
                            break;
                        default:
                            files = Array.from(document.querySelectorAll(".dz-filename span")).map(e => e.innerText).filter(e => e != "");
                            itemName = files.join(", ");
                    }

                    if (itemName != "") {
                        if (EnvironmentService.getCreationMode() === "copy" || scope.dropzoneConfig.isModal && !scope.dropzoneConfig.isFileInfoNeeded) {
                            scope.scriptObject.names = undefined;
                            scope.scriptObject.type = "edit";
                        } else {
                            scope.scriptObject.names = files;
                            scope.scriptObject.type = mode === "files" ? "upload" : mode === "trayItem" ? "externalTray" : mode;
                        }

                        scope.selectionInfo = selectedItems[mode].info + itemName;
                        scope.showSelectionInfo = true;
                    } else {
                        scope.scriptObject = {};
                        scope.showSelectionInfo = false;
                    }

                    setDropzoneContent(mode);
                    scope.$apply();
                }, 0);
            }

            function isExternalFilterPresent() {
                return true;
            }

            function doesExternalFilterPass(node) {
                if (!node.quickFilterAggregateText) {
                    scope.options.api.filterManager.aggregateRowForQuickFilter(node);
                    node.quickFilterAggregateText = he.decode(node.quickFilterAggregateText).toLowerCase();
                    node.quickFilterAggregateText = node.quickFilterAggregateText.replace(/<img[^>]+>(_)?/g, ""); // Icons entfernen
                }

                return (node.quickFilterAggregateText.indexOf(externalFilterString) >= 0);
            }

            function getAcceptedFiles() {
                let accepted = [];
                let fileExtensions = EnvironmentService.getAllowedFileExtByMainType(mainType).split(",");

                for (let i in fileExtensions) {
                    let ext = fileExtensions[i].toLowerCase();

                    if (ext.charAt(0) !== ".") {
                        ext = `.${ext}`;
                        accepted.push(ext);
                    }
                }

                return (accepted.join(","));
            }

            async function loadDocumentPreviewAsync(index) {
                let file = {
                    name: scope.loadableFiles[index - 1],
                    size: 0,
                    status: Dropzone.SUCCESS,
                    accepted: true
                };

                let dzContent = dropzone.element.children[0];

                if (!(dzContent.className != void 0 && dzContent.className.toLowerCase() === "dz-preview dz-complete dz-image-preview")) {
                    setSelection("files", file.name);
                    progressBar.hide();
                }

                dropzone.emit("addedfile", file);
                dropzone.emit("complete", file);

                await loadThumbnailContentWithTimeout(dropzone, file, index);

                if (scope.loadableFiles[index]) {
                    await loadDocumentPreviewAsync(++index);
                } else {
                    setDropzoneContent("files");
                    onInitContentDone();
                }
            }

            async function loadDocumentAsync(dropzone, index) {
                index = index || 1;

                let url = `/documentfiles/${scope.dropzoneConfig.id}/${index}`;
                let fd = new FormData();
                let httpConfig = {
                    responseType: "arraybuffer",
                    timeout: cancelPromise // it is created while init
                };
                let response = await BackendService.get(url, "", httpConfig);
                let data = response.data;
                let blob = new Blob([data], { type: "text/xml" });

                fd.append(scope.loadableFiles[index - 1], blob, scope.loadableFiles[index - 1]);

                let file = {
                    name: buildFileName(index),
                    size: blob.size,
                    status: Dropzone.SUCCESS,
                    accepted: true
                };

                let dzContent = dropzone.element.children[0];

                if (!(dzContent.className != void 0 && dzContent.className.toLowerCase() === "dz-preview dz-complete dz-image-preview")) {
                    // scope.showSelectionInfo = true;
                    // scope.selectionInfo = file.name;
                    setSelection("files", file.name);
                    progressBar.hide();
                }

                dropzone.emit("addedfile", file);
                dropzone.emit("complete", file);

                await loadThumbnailContentWithTimeout(dropzone, file, index);

                dropzone.files.push(file);

                let postConfig = {
                    headers: { "Content-Type": undefined },
                    responseType: (EnvironmentService.isMicroserviceBackend()) ? "json" : "text",
                    timeout: cancelPromise
                };

                response = await BackendService.post("/fileUpload.do", fd, postConfig, $eobConfig.getOswebBase());
                selectedItems.files.content.push(EnvironmentService.isMicroserviceBackend() ? response.data.cid : response.data);
                file.guid = response.data;

                if (scope.loadableFiles[index]) {
                    await loadDocumentAsync(dropzone, ++index);
                } else {
                    setDropzoneContent("files");
                    onInitContentDone();
                }
            }

            function buildFileName(index) {
                const dmsDocument = CacheManagerService.dmsDocuments.getById(scope.dropzoneConfig.id);
                let name = dmsDocument.api.buildNameFromIndexData(5, true, true);
                let ending = scope.loadableFiles.length == 1 ? `.${scope.loadableFiles[index - 1].split(".")[1]}` : ` - ${scope.loadableFiles[index - 1]}`;
                return name + ending;
            }

            function setDropzoneContent(mode) {
                let files;
                let dropzoneConfig = scope.dropzoneConfig;
                let template = selectedItems[mode].content;
                let templateConfig = templateConfiguration;

                files = selectedItems[mode].content;
                EnvironmentService.setDropzoneContent(scope.scriptObject, files, mode, dropzoneConfig, template, templateConfig);

                if (mode === "trayItem" && files) {
                    $stateParams.groupKey = files.groupKey;
                }
            }

            function appendDefaultMessage(dzContent) {
                let message;
                dzContent.contents().remove();

                if (scope.isMobileImageUpload) {
                    message = $filter("translate")("dropzone.mobile.image.upload.message");
                } else {
                    dzContent.append("<span class='dropzone-icon'></span>");
                    message = ClientService.isTouchDevice() ? $filter("translate")("dropzone.defaultmessage.mobile") : $filter("translate")("dropzone.defaultmessage");
                }

                dzContent.append(`<span>${message}</span>`);

                let allowedTypes = EnvironmentService.getAllowedFileExtByMainType(mainType);
                let allowedTypesMessage = angular.element(`<span class='allowed-types-message'>${$filter("translate")("dropzone.allowed.types.message")} ${allowedTypes}</span>`);
                dzContent.append(allowedTypesMessage);
            }

            function initGreenArrowMode() {
                if ($stateParams.mode == "reference") {
                    scope.showTemplates = false;
                    scope.contentTabTitle = $filter("translate")("dropzone.content");
                } else {
                    scope.contentTabTitle = $filter("translate")("dropzone.template.add.content");
                }
            }

            async function loadThumbnailContentWithTimeout(dropzone, file, index) {
                from(getThumbnailContent(index))
                .pipe(timeout(5000))
                .subscribe(
                    val => dropzone.emit("thumbnail", file, val),
                    _ => console.error("time for getting thumbnail content exceeded")
                );
            }

            async function getThumbnailContent(index) {
                try {
                    const date = await Date.now();

                    do {
                        const infoResponse = await BackendService.get(`/app/api/document/${scope.dropzoneConfig.id}/rendition/web/info?t=${date}`, "/osrenditioncache");

                        if (infoResponse.data.status == "processing") {
                            await new Promise(resolve => setTimeout(resolve, 500));
                            continue;
                        }

                        if (infoResponse.data.status != "successful") {
                            console.error("Thumbnail processing for modal dialog failed.", infoResponse.data);
                            return null;
                        }

                        const thumbResponse = await BackendService.get(`/app/api/document/${scope.dropzoneConfig.id}/rendition/web/page/${index}/image/96?t=${date}`, "/osrenditioncache", { responseType: "arraybuffer" });
                        return URL.createObjectURL(new Blob([thumbResponse.data], { type: "image/png" }));
                    } while (true);
                } catch (error) {
                    console.error(error);
                }
            }

            function onInitContentDone() {
                isInitContentLoaded = true;
                scope.$emit("dropzone.content.changed");
            }

            function destroy() {
                subs.unsubscribe();
                const content = EnvironmentService.getDropzoneContent();

                if (content != void 0) {
                    EnvironmentService.setDropzoneContent({}, content.files, content.mode, content.item, content.template, content.templateConfiguration);
                }

                $rootScope.dropzone = null;
                window.dropzone = null;

                if (cancel) {
                    cancelPromise.cancelled = true;
                    cancel(); // terminate all reminding http calls
                }
            }

            /**
             * watch the filter and replace : and ; with their html entities
             * we use this beacause the ng-grid reserved these chars to do regEx things
             * but we have to search for it, though
             */
            scope.$watch("templateFilter", (value) => {
                if (scope.options != void 0 && scope.options.api) {
                    externalFilterString = value != void 0 ? value.toLowerCase() : "";
                    scope.options.api.onFilterChanged();
                }
            });

            scope.$on("$destroy", () => {
                destroy();
            });
        },
        template: require("!raw-loader!./eob.dropzone.html")
    };
}
