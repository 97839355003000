/**
 * Form broadcast names to be used with the message service
 */
export enum FormEvent {
    /**
     * Search settings.
     */
    TOGGLE_SEARCH_SETTINGS = "TOGGLE_SEARCH_SETTINGS",
    DESELECT_OBJECT_TYPE = "DESELECT_OBJECT_TYPE",
    /**
     * Emitted when SearchController receives a $destroy event
     *
     * @type {FormEvent.SEARCH_DESTROYED}
     */
    SEARCH_DESTROYED = "SEARCH_DESTROYED",

    /**
     * To be broadcasted after form is ready and other form stuff.
     */
    FORM_READY = "FORM_READY",
    REDRAW_BASEPARAM_FORM = "REDRAW_BASEPARAM_FORM",

    UPDATE_FULLTEXT_QUERY = "UPDATE_FULLTEXT_QUERY",
    UPDATE_AUTOCOMPLETE_CONFIG = "UPDATE_AUTOCOMPLETE_CONFIG",

    DISABLE_SAVE_BUTTON = "DISABLE_SAVE_BUTTON",

    TOGGLE_BASEPARAMS = "TOGGLE_BASEPARAMS",

    HANDLE_PAGECONTROL_BUTTONS = "HANDLE_PAGECONTROL_BUTTONS"
}
