import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {Subscription, throwError, Observable} from "rxjs";
import {catchError, filter} from "rxjs/operators";
import {BackendFacade} from "CORE_PATH/backend/interfaces/backend-facade.interface";
import {OsrestDriver} from "CORE_PATH/backend/modules/osrest/osrest-driver";
import {Dms2Driver} from "CORE_PATH/backend/modules/dms2/dms2-driver";
import {OsrestObjectResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-object-result.interface";
import {OsrestSearchResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-search-result.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {Cabinet, OsrestObjectDefinition} from "INTERFACES_PATH/object-type.interface";
import {OsrestDocumentfilesIdNameMap} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-documentfiles-id-name-map.interface";
import {OsrestDocumentfilesResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-documentfiles-result.interface";
import {ProcessedRenditionInfoData} from "INTERFACES_PATH/rendition-info-data.interfaces";
import {OsrestChildrenHierarchyResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-children-hierarchy-result.interface";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {BulkRequestResult} from "CORE_PATH/backend/models/bulk-request-result.model";
import {switchMap, tap} from "rxjs/operators";
import {from, of} from "rxjs";
import {HttpHeaders} from "@angular/common/http";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {AsIni} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {ProfileCacheKey} from "ENUMS_PATH/database/profile-cache-key.enum";
import {environment} from "ROOT_PATH/environments/environment";
import {GlobalStore} from "INTERFACES_PATH/database/database-store.interface";
import {OsrestSubscriptionObject} from "../modules/osrest/interfaces/osrest-subscription-object.interface";
import {OsrestWorkflowResult} from "../modules/osrest/interfaces/osrest-workflow-result.interface";
import {OsrestIcons} from "../modules/osrest/interfaces/osrest-icons.interface";
import {OsrestWorkflowOrganisationResult} from "../modules/osrest/interfaces/osrest-workflow-organisation-result.interface";
import {OsrestOrganisationGroupsResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-organisation-groups-result.interface";
import {User} from "INTERFACES_PATH/user.interface";
import {OsrestRevisits, OsrestSubscriptionNotifications} from "../modules/osrest/interfaces/osrest-subscription-notifications.interface";
import {
    OsrestWorkflowInbox,
    OsrestWorkflowProcess,
    OsrestWorkItem
} from "../modules/osrest/interfaces/osrest-workflow-inbox.interface";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

@Injectable({
    providedIn: "root"
})
export class HttpService implements BackendFacade {

    private readonly serviceBase: string;
    private readonly oswebBase: string;
    private delegate: BackendFacade;
    private newBackendAvailable: boolean = false;
    private dms2available: boolean = false;

    constructor(private httpClient: HttpClient,
                private osrestDelegate: OsrestDriver,
                private dmsDelegate: Dms2Driver,
                @Inject("$eobConfig") $eobConfig: any,
                @Inject("fileCacheService") private fileCacheService: FileCacheService,
                private clientService: ClientService,
                messageService: MessageService) {

        this.serviceBase = $eobConfig.getServiceBase();
        this.oswebBase = $eobConfig.getOswebBase();

        this.delegate = environment.dms2 ? this.dmsDelegate : this.osrestDelegate;

        // If we use the dmsDelegate we must use for certain calls the old osrest because they are not yet available.
        this.dmsDelegate.setOsrestDelegate(this.osrestDelegate);

        // Using broadcast sfor now, as injecting the EnvironmentService would create a circular dependency
        messageService.subscribeFirst(Broadcasts.PRODUCT_VERSION_RECEIVED, (productVersion: string) => {
            if (!productVersion.startsWith("9.")) {
                this.dms2available = true;
                this.delegate = this.dmsDelegate;
            }
        });
        messageService.subscribeFirst(Broadcasts.ENVIRONMENT_INITIALIZED, (env: TodoEnvironmentService) => this.newBackendAvailable = env.microserviceBackend);
    }

    isDmsServiceAvailable(): boolean {
        return this.dms2available;
    }

    private _createLegacyRequest(method: string, url: string, base?: string, config?: ng.IHttpRequestConfigHeaders, data?: any): Promise<any> {
        return new Observable(subscriber => {
            let req: HttpRequest<any>;
            switch (method) {
                case "POST":
                case "DELETE":
                    if (typeof config?.transformRequest == "function") {
                        data = config.transformRequest(data);
                    }
                    req = new HttpRequest(method, (base ? base : this.serviceBase) + url, data, {
                        responseType: config?.responseType ? config.responseType : "json"
                    });
                    break;
                case "GET":
                default:
                    req = new HttpRequest(method ?? "GET", (base ? base : this.serviceBase) + url, {
                        responseType: config?.responseType ? config.responseType : "json"
                    });
                    break;
            }
            const sub: Subscription = this.httpClient.request<any>(req).pipe(filter(x => x.type == HttpEventType.Response)).subscribe({
                next: (value: HttpResponse<any>) => {
                    subscriber.next(Object.assign(value, {data: value.body}));
                    subscriber.complete();
                }, error: (err: HttpErrorResponse) => {
                    console.warn(err);
                    subscriber.error(Object.assign(err, {data: err.error}));
                }
            });
            if (config?.timeout instanceof Promise) {
                (async () => {
                    await config.timeout;
                    sub.unsubscribe();
                    subscriber.error({status: -1});
                })();
            }
        }).toPromise();
    }

    legacyGet(url: string, base?: string, config?: ng.IHttpRequestConfigHeaders): Promise<any> {
        return this._createLegacyRequest("GET", url, base, config);
    }

    legacyPost(url: string, data: any, config?: ng.IHttpRequestConfigHeaders, base?: string): Promise<any> {
        return this._createLegacyRequest("POST", url, base, config, data);
    }

    legacyDelete(url: string, base?: string, config?: ng.IHttpRequestConfigHeaders): Promise<any> {
        return this._createLegacyRequest("DELETE", url, base, config);
    }

    uploadFile(filename: string, blob: Blob): Observable<HttpEvent<string>> {
        return this.delegate.uploadFile(filename, blob);
    }

    search(request: any, params: string): Observable<OsrestSearchResult[]> {
        return this.delegate.search(request, params);
    }

    searchById(id: string|number, objectTypeId?: string|number, original?: boolean): Observable<OsrestSearchResult|DmsDocument> {
        return this.delegate.searchById(id, objectTypeId, original);
    }

    fetchCabinets(): Observable<Cabinet[]> {
        if(this.clientService.isOffline()) {
            return from(this.fileCacheService.getContentAsync(DatabaseEntryType.GLOBAL, GlobalCacheKey.OBJ_DEF_CABINETS, {first: true}));
        }
        return this.httpClient.get<Cabinet[]>(`${this.oswebBase}/rest/objdef/cabinets`)
            .pipe(tap(x => {
                void this.fileCacheService.storeContentAsync(DatabaseEntryType.GLOBAL, x, GlobalCacheKey.OBJ_DEF_CABINETS);
            }));
    }

    fetchObjectDefinition(): Observable<OsrestObjectDefinition> {
        const url: string = this.delegate instanceof Dms2Driver ? "/api/dms/schema/native" : `${this.serviceBase}/objdef/global`;
        let headers: HttpHeaders = new HttpHeaders();

        return from(this.fileCacheService.getContentAsync(DatabaseEntryType.GLOBAL, GlobalCacheKey.OBJ_DEF_CACHE, {onlyContent: false}))
            .pipe(switchMap((cached: GlobalStore<any>) => {
                if(!cached) {
                    if(this.clientService.isOffline()) {
                        return throwError("Object definition missing");
                    }
                } else {
                    if(this.clientService.isOffline()) {
                        return of(cached.content);
                    }
                    headers = headers.set("If-Modified-Since", cached.timestamp.toUTCString());
                }
                return this.httpClient.get<OsrestObjectDefinition>(url, {headers})
                    .pipe(tap(serverObjDef => {
                            void this.fileCacheService.storeContentAsync(DatabaseEntryType.GLOBAL, serverObjDef, GlobalCacheKey.OBJ_DEF_CACHE);
                        }),
                        catchError((response: HttpErrorResponse) => {
                            if(response.status == 304 && cached) {
                                return of(cached.content);
                            } else {
                                return throwError(response);
                            }
                        }));
            }));
    }

    addFavorites(ids: string[]): Observable<BulkRequestResult[]> {
        return this.delegate.addFavorites(ids);
    }

    removeFavorites(ids: string[]): Observable<void> {
        return this.delegate.removeFavorites(ids);
    }

    checkoutDocument(objectId: string, objectTypeId?: string, undo?: boolean): Observable<void> {
        return this.delegate.checkoutDocument(objectId, objectTypeId, undo);
    }

    retrieveDocumentFiles(objects: OsrestDocumentfilesIdNameMap[], typeOrFileNumber: string, filename: string, addWatermark?: boolean): Observable<HttpResponse<ArrayBuffer>> {
        return this.delegate.retrieveDocumentFiles(objects, typeOrFileNumber, filename, addWatermark);
    }

    queryDocumentFiles(objectId: string): Observable<OsrestDocumentfilesResult> {
        return this.delegate.queryDocumentFiles(objectId);
    }

    assignTemplateToObject(objectId: string, objectTypeId: string, templateId: string, fillTemplate?: boolean): Observable<never> {
        return this.delegate.assignTemplateToObject(objectId, objectTypeId, templateId, fillTemplate);
    }

    retrievePdfRenditionStatus(objectId: string): Observable<number> {
        return this.delegate.retrievePdfRenditionStatus(objectId);
    }

    retrieveRenditionInformation(objectId: string, maxRetries: number = 50, delayMs: number = 500): Observable<ProcessedRenditionInfoData> {
        return this.delegate.retrieveRenditionInformation(objectId, maxRetries, delayMs);
    }

    retrieveRendition(objectId: string, type: "pdf" | "thumbnail", timeoutMs: number = 30000): Observable<ArrayBuffer> {
        return this.delegate.retrieveRendition(objectId, type, timeoutMs);
    }

    getChildrenHierarchy(objectId: string): Observable<OsrestChildrenHierarchyResult[]> {
        return this.delegate.getChildrenHierarchy(objectId);
    }

    getObjectHierarchy(objectId: string): Observable<OsrestObjectResult> {
        return this.delegate.getObjectHierarchy(objectId);
    }

    getSettingsTimestamp(): Observable<number> {
        return this.delegate.getSettingsTimestamp();
    }

    loadSettings(): Observable<AsIni> {
        if (!this.clientService.isOnline()) {
            return from(this.fileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, ProfileCacheKey.AS_INI, {first: true})).pipe(switchMap(retrievedSettings => {
                if (!retrievedSettings) {
                        return throwError("No local settings available");
                    } else {
                        return of(retrievedSettings);
                    }
                }
            ));
        } else {
            return this.delegate.loadSettings().pipe(
                tap(settings => {
                    void this.fileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, settings, ProfileCacheKey.AS_INI);
                })
            );
        }
    }

    saveSettings(settings: AsIni): Observable<void> {
        const observer: any = {};
        observer.complete = () => {
            this.fileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, settings, ProfileCacheKey.AS_INI);
        };
        return this.delegate.saveSettings(settings).pipe(
            tap(observer)
        );
    }

    getDropzoneThumbnail(osid: string, index: string): Observable<Blob> {
        return this.delegate.getDropzoneThumbnail(osid, index);
    }

    querySubscription(aboGroup: string): Observable<OsrestSubscriptionObject> {
        return this.delegate.querySubscription(aboGroup);
    }

    getStartableWorkflowModels(clientType?: "web" | "mobile" | "desktop" | "web_de" | "web_en" | "web_fr"): Observable<OsrestWorkflowResult[]> {
        return this.delegate.getStartableWorkflowModels(clientType);
    }

    deleteOsRenditionCache(osid: number): Observable<void> {
        return this.delegate.deleteOsRenditionCache(osid);
    }

    changePassword(oldPassword: string, newPassword: string, backendOrigin?: string, authHeader?: { [k: string]: string }): Observable<void> {
        return this.delegate.changePassword(oldPassword, newPassword, backendOrigin, authHeader);
    }

    invalidateGatewaySession(backendOrigin?: string): Observable<HttpResponse<string>> {
        return this.httpClient.get(`${backendOrigin ? backendOrigin : ""}/_secure/logout/`, {observe: "response", responseType: "text"})
            .pipe(catchError((err: HttpErrorResponse) => {
                // Currently, the gateway returns a 401 after redirecting to the default endpoint, which has to be handled accordingly
                if(err.status == 401) {
                    return of(new HttpResponse({status: 200, body: ""}));
                } else {
                    return throwError(err);
                }
            }));
    }

    getIcons(iconIds: string[]): Observable<OsrestIcons> {
        return this.delegate.getIcons(iconIds);
    }

    getWorkflowOrganisation(): Observable<OsrestWorkflowOrganisationResult> {
        if (!this.clientService.isOnline()) {
            return from(this.fileCacheService.getContentAsync(DatabaseEntryType.GLOBAL, GlobalCacheKey.WORKFLOW_ORGANISATION, {first: true})).pipe(
                switchMap(cachedOrg => {
                    if(!cachedOrg) {
                        return throwError("Workflow organisation missing");
                    } else {
                        return of(cachedOrg);
                    }
                })
            );
        }
        return this.delegate.getWorkflowOrganisation().pipe(tap(x => {
            void this.fileCacheService.storeContentAsync(DatabaseEntryType.GLOBAL, x, GlobalCacheKey.WORKFLOW_ORGANISATION);
        }));
    }

    getOrganisationUsers(): Observable<User[]> {
        if (!this.clientService.isOnline()) {
            return from(this.fileCacheService.getContentAsync(DatabaseEntryType.GLOBAL, GlobalCacheKey.USER_LIST, {first: true})).pipe(
                switchMap(cachedOrgUsers => {
                    if (!cachedOrgUsers) {
                        return throwError("Organisation users missing");
                    } else {
                        return of(cachedOrgUsers);
                    }
                })
            );
        }
        return this.delegate.getOrganisationUsers().pipe(tap(x => {
            void this.fileCacheService.storeContentAsync(DatabaseEntryType.GLOBAL, x, GlobalCacheKey.USER_LIST);
        }));
    }

    getOrganisationGroups(): Observable<OsrestOrganisationGroupsResult[]> {
        if (!this.clientService.isOnline()) {
            return from(this.fileCacheService.getContentAsync(DatabaseEntryType.GLOBAL, GlobalCacheKey.GROUP_LIST, {first: true})).pipe(
                switchMap(cachedOrgGroups => {
                    if (!cachedOrgGroups) {
                        return throwError("Organisation groups missing");
                    } else {
                        return of(cachedOrgGroups);
                    }
                })
            );
        }
        return this.delegate.getOrganisationGroups().pipe(tap(x => {
            void this.fileCacheService.storeContentAsync(DatabaseEntryType.GLOBAL, x, GlobalCacheKey.GROUP_LIST);
        }));
    }

    getRevisits(): Observable<OsrestRevisits> {
        return this.delegate.getRevisits();
    }

    getRunningProcesses(): Observable<OsrestWorkflowProcess[]> {
        return this.delegate.getRunningProcesses();
    }

    getRunningWorkflows(): Observable<OsrestWorkflowInbox[]> {
        return this.delegate.getRunningWorkflows();
    }

    getRunningWorkflow(id: string): Observable<{ WorkItem: OsrestWorkItem }> {
        return this.delegate.getRunningWorkflow(id);
    }

    getSubscriptionNotifications(): Observable<OsrestSubscriptionNotifications> {
        return this.delegate.getSubscriptionNotifications();
    }

    getSubscriptionObjects(): Observable<OsrestSubscriptionObject[]> {
        return this.delegate.getSubscriptionObjects();
    }


}
