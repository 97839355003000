import {Inject, Injectable} from "@angular/core";
import {OperatonData} from "INTERFACES_PATH/validation.interface";
import {Field} from "INTERFACES_PATH/field.interface";
import {FormValidationService} from "MODULES_PATH/form/services/form-validation.service";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {FieldControlType, FieldDataType} from "ENUMS_PATH/field.enum";
import {Subject} from "rxjs";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";

@Injectable({providedIn: "root"})
export class ValidationApiService {

    constructor(@Inject("formValidationService") private formValidationService: FormValidationService,
                @Inject("errorModelService") private errorModelService: ErrorModelService,
                private valueUtilsService: ValueUtilsService) {
    }

    addValidationApi = (api: any, field: Field, formHelper: any): any => {
        const fieldType: FormFieldType | FieldControlType | FieldDataType = field.model.type;
        api.valueUtilsService = this.valueUtilsService;

        if (fieldType == "pagecontrol" || fieldType == "button" || fieldType == "radio" || fieldType == "group") {
            return;
        }

        /**
         * sets a field invalid and shows a popup box with the given title and message
         *
         * @param title - The title of the error box
         * @param msg - The Description of the error
         */
        api.setInvalid = (title: string, msg: string): void => {
            if (msg == void 0) {
                msg = "";
            }

            if (title == void 0) {
                title = "";
            }

            this.formValidationService.setInvalid(field, msg, title);
        };

        /**
         * sets the field valid and removes the red border / popup box
         */
        api.setValid = (): void => {
            this.formValidationService.setValid(field);
        };

        /**
         * Is the field valid?
         *
         * @returns {boolean} true if it is valid, else false
         */
        api.isValid = (): boolean => this.formValidationService.isValid(field);

        /**
         * adds a custom validation to a field
         *
         * @param fn - The validation function
         * @param title - The error title
         * @param msg - The error description
         * @param isAsync - Whether the validation is asynchronous or not, default is false
         */
        api.addCustomValidation = (fn: any, title: string, msg: string, isAsync: boolean): void => {
            if (typeof fn != "function") {
                console.error("this is no function --> ", fn);
                return;
            }

            if (field.customValidations == void 0) {
                field.customValidations = [];
            }

            field.customValidations.push({fn, isAsync, msg, title, isCustom: true});
        };

        /**
         * executes all synchronous validation tasks
         *
         * @returns {boolean}
         */
        api.syncValidate = (): boolean => {
            let isValid = true;

            if (field.model.isEmsField && formHelper.isCreate && !formHelper.isReference) {
                return true;
            }

            const operations: OperatonData[] = this.formValidationService.getSyncedOperations(field);

            for (const op of operations) {
                const valid: any = op.fn(field, formHelper);

                if (!valid) {
                    // get the validation bubble content
                    // exclude the grids because they do the invalidation
                    if (field.model.type != "grid") {
                        api.setInvalid(op.title, op.msg);
                    } else {
                        field.isValid = false;
                    }

                    isValid = false;
                    break;
                }
            }

            if (isValid) {
                api.setValid();
            }

            return isValid;
        };

        /**
         * Execute all asynchronous validation tasks and set the field invalid/valid accordingly.
         *
         * @param {Object[]=} crossPromises - Pairs of promises and validation functions, which affect more than one field and are already executing for another.
         * @returns {promise} The validation promise. It is rejected, if the field is invalid and resolved otherwise.
         */
        api.asyncValidate = async (crossPromises?: any): Promise<any> => {
            const asyncOperations: OperatonData[] = this.formValidationService.getAsyncedOperations(field);

            if (asyncOperations.length === 0 || (field.model.isEmsField && formHelper.isCreate)) {
                return Promise.resolve();
            }

            const promises: any[] = [];
            for (const asyncOperation of asyncOperations) {
                promises.push(getAsyncValidationPromise(asyncOperation, crossPromises));
            }

            const res: any[] = await Promise.all(promises);
            let isValid = true;

            for (const i in res) {
                if (!res[i]) {
                    isValid = false;

                    if (field.model.type != "grid") {
                        const op: OperatonData = asyncOperations[i];
                        api.setInvalid(op.title, op.msg);
                    } else {
                        field.isValid = false;
                    }

                    throw this.errorModelService.createCustomError("WEB_VALIDATION_DETECTED_ERRORS");
                }
            }

            if (isValid) {
                api.setValid();
            }
        };

        /**
         * Get an asynchronous validation function wrapped in a promise.
         *
         * @param {Object} op - An object that contains a validation function, the error title and error message.
         * @param {Object[]=} crossPromises - Pairs of promises and validation functions, which affect more than one field and are already executing for another.
         * @returns {promise} The wrapped validation promise. It is resolved with the validation state (true/false) and only rejected if an error occurred.
         */
        async function getAsyncValidationPromise(op: OperatonData, crossPromises: any[]): Promise<boolean> {
            let promise: Promise<boolean>;

            if (crossPromises != void 0) {
                for (const crossPromise of crossPromises) {
                    if (op.fn == crossPromise.fn) {
                        return crossPromise;
                    }
                }
            }

            if (op.isCustom) {
                promise = new Promise(((resolve) => {
                    op.fn(field, formHelper, (result) => {
                        if (result == void 0 || api.valueUtilsService.parseBoolean(result)) {
                            resolve(true);
                        } else {
                            resolve(false);

                            if (field.model.type != "grid") {
                                api.setInvalid(op.title, op.msg);
                            }
                        }
                    });
                }));
            } else {
                promise = new Promise(((resolve) => {
                    op.fn(field, formHelper).then((isValid) => {
                        if (!isValid) {
                            if (field.model.type != "grid") {
                                api.setInvalid(op.title, op.msg);
                            }
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    }).catch(err => console.warn(err));
                }));
            }

            return promise;
        }

        /**
         * Execute the validation functions of a field, first the synchronous and if necessary the asynchronous.
         * Additionally the field is set valid/invalid accordingly.
         *
         * @returns {promise} The validation promise. It is rejected, if the field is invalid and resolved otherwise.
         */
        api.validate = (): Promise<boolean> => {
            const subject: Subject<any> = new Subject();

            if (field.validationMode == "min") {
                subject.next();
                subject.complete();
                return subject.toPromise();
            }

            const isValid: boolean = api.syncValidate();

            if (!isValid) {
                subject.error(this.errorModelService.createCustomError("WEB_VALIDATION_DETECTED_ERRORS"));
                return subject.toPromise();
            }

            api.asyncValidate().then(() => {
                subject.next();
                subject.complete();
            }).catch(err => subject.error(err));

            return subject.toPromise();
        };
    };
}
