import {Injectable} from "@angular/core";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";
import {Dms2SearchService} from "CORE_PATH/backend/modules/dms2/services/search/dms2-search.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BackendVariantObject} from "CORE_PATH/backend/interfaces/backend-variant-object.interface";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {Dms2ObjectResult, Dms2Object} from "CORE_PATH/backend/modules/dms2/interfaces/dms2-object.interface";
import {of, Observable} from "rxjs";
import {map, mergeMap, toArray} from "rxjs/operators";

@Injectable({providedIn: BackendDms2Module})
export class Dms2VariantsService {

    constructor(private httpClient: HttpClient, private dms2SearchService: Dms2SearchService) {
    }

    loadVariants(osId: string, objectTypeId: string) : Observable<BackendVariantObject[]> {
        const typeQueryString: string = (objectTypeId == null) ? "" : `?objectTypeId=${objectTypeId}`;

        return this.httpClient.get<Dms2ObjectResult>(`/api/dms/objects/${osId}/native/variants${typeQueryString}`).pipe(
            mergeMap(response => {
                const allBackendObjects$: Array<Observable<BackendObject>> = response.objects.map(dms2Variant => this.loadMetaDataForDms2Variant(dms2Variant));
                return of(...allBackendObjects$).pipe(
                    mergeMap(req => req, 2),
                    toArray(),
                    map(allBackendObjects => this.convertDms2Variants(response.objects, allBackendObjects))
                );
            })
        );
    }

    activateVariant(currentActiveOsId: string, newActivOsId: string): Observable<void> {
        return this.httpClient.put<void>(`/api/dms/objects/${currentActiveOsId}/native/variants/${newActivOsId}`, null);
    }

    createVariant(osId: string, objectTypeId: string, type: string): Observable<string> {
        // Content-Type must be set for the enaio gateway if there is cors activated.
        // Todo: DODO-14668
        return this.httpClient.post<Dms2ObjectResult>(`/api/dms/objects/${osId}/native/variants?objectTypeId=${objectTypeId}&variantType=${type}&transferPlannedRetention=true`, null, {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        }).pipe(
            map(response => response.objects[0].properties["system:objectId"].value)
        );
    }

    private convertDms2Variants(dms2Variants: Dms2Object[], backendObjects: BackendObject[]): BackendVariantObject[] {
        const retVal: BackendVariantObject[] = [];
        let i: number = 0;

        for (const dms2Variant of dms2Variants) {
            retVal.push({
                ...backendObjects.find(x => x.osid == dms2Variant.properties["system:objectId"].value),
                variantInfo: {
                    id: dms2Variant.properties["system:objectId"].value,
                    originId: dms2Variant.properties["system:variantOriginId"].value,
                    version: dms2Variant.properties["system:variantVersion"].value,
                    active: dms2Variant.properties["system:variantActive"].value,
                }
            });

            i++;
        }

        return retVal;
    }

    private loadMetaDataForDms2Variant(dms2Object: Dms2Object): Observable<BackendObject> {
        return this.dms2SearchService.getObjectMetadata(dms2Object.properties["system:objectId"].value, null);
    }
}
