import {Component, Inject, OnInit, ElementRef, Output, EventEmitter, OnDestroy, NgZone} from "@angular/core";

import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {FieldsetBuilderService} from "CORE_PATH/services/field/fieldset-builder.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {StateParams} from "@uirouter/core";

import {FieldModel} from "SHARED_PATH/models/field.model";
import {ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

interface ObjectType {
    title: string;
    icon: string;
    isActive: boolean;
    isMainSearchType: boolean;
    baseParams: string[];
    objectTypeId: string;
    internal: string;
}

@Component({
    selector: "eob-search-settings",
    templateUrl: "./eob-search-settings.component.html",
    styleUrls: ["./eob-search-settings.component.scss"]
})

export class EobSearchSettingsComponent implements OnInit, OnDestroy {

    @Output() searchsettingschanged: EventEmitter<any> = new EventEmitter<any>();

    openTab: string;
    activeObjectType: ObjectType;
    selectedTypes: ObjectType[] = [];
    objectTypePerType: any = {
        folder: [],
        register: [],
        document: []
    };
    baseParamFields: FieldModel[] = [];
    isPhone: boolean;
    showBasicParameter: boolean;

    private deselectObjectTypeSubscription: any;

    private readonly translateFn: TranslateFnType;
    private readonly stateParams: StateParams;


    private currentObjectTypeId: string;
    private objectTypes: ObjectType[] = [];
    private currentTypeConfig: ObjectTypeConfig;
    private areaToggleStates: any;
    private stateId: string;
    private lastState: any;
    private canUseBaseParams: boolean;
    private baseParamFieldsMap: any;
    private resizeObserver: MutationObserver;

    showAltLang: boolean;
    objectDefLang: string;
    uiLang: string;

    constructor(@Inject("$stateParams") private $stateParams: StateParams,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("$location") protected $location: ng.ILocationService,
                @Inject("cacheManagerService") protected cacheManagerService: any,
                @Inject("stateHistoryManager") protected stateHistoryManager: any,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                protected clientService: ClientService,
                @Inject("objectTypeService") protected objectTypeService: ObjectTypeService,
                @Inject("fieldsetBuilderService") protected fieldsetBuilderService: FieldsetBuilderService,
                private messageService: MessageService,
                private el: ElementRef,
                private ngZone: NgZone) {
        this.translateFn = this.$filter("translate") ;
        this.stateParams = $stateParams;
    }

    ngOnInit(): void {
        this.stateId = this.$location.search().state;
        this.lastState = this.stateHistoryManager.getStateData(this.stateId);
        this.canUseBaseParams = this.environmentService.userHasRole("R_ADM_START") ? true : this.environmentService.isBaseParameterAvailable();
        this.isPhone = this.clientService.isPhone();
        this.showBasicParameter = this.canUseBaseParams && this.environmentService.env.form.useBaseParams;
        this.openTab = this.lastState.data.searchSettings ? this.lastState.data.searchSettings.activeTab : this.showBasicParameter ? "baseParams" : "objectTypes";
        this.currentObjectTypeId = this.stateParams.objectTypeId;
        this.currentTypeConfig = this.cacheManagerService.objectTypes.getById(this.currentObjectTypeId).model.config;
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang();
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();
        this.uiLang = this.environmentService.getLanguage();
        this.baseParamFieldsMap = {
            folder: this.fieldsetBuilderService.buildBaseParamFields("0", false),
            register: this.fieldsetBuilderService.buildBaseParamFields("99", false),
            document: this.fieldsetBuilderService.buildBaseParamFields("4", false)
        };

        this.areaToggleStates = {
            baseParams: {
                title: this.translateFn("eob.search.baseparam.mask.title")
            },
            objectTypes: {
                title: this.translateFn("eob.search.settings.objecttypes.title")
            }
        };
        this.resizeObserver = new MutationObserver(mutationList => {
            if (mutationList.find(x => x.type == "attributes" && x.attributeName == "style")) {
                this.calculateSectionHeights();
            }
        });

        if (this.lastState && this.lastState.data.searchSettings) {
            this.initObjectTypes(this.lastState.data.searchSettings.objectTypes);
            this.changeActiveObjectType(this.lastState.data.searchSettings.activeObjectType.objectTypeId);
            this.selectedTypes = this.lastState.data.searchSettings.selectedTypes;
        } else {
            this.initObjectTypes();
            this.toggleTypeIsActive(this.currentObjectTypeId);
        }

        this.searchsettingschanged.emit(this.areaToggleStates[this.openTab].title);

        this.deselectObjectTypeSubscription = this.messageService.subscribe<string>(FormEvent.DESELECT_OBJECT_TYPE, (objectTypeId: string) => {
            this.deselectType(objectTypeId);
        });

        setTimeout(() => {
            this.calculateSectionHeights();
            this.bindResizeSensor();
        }, 0);
    }

    switchSettingsTab(tab: string): void {
        this.openTab = tab;
        this.searchsettingschanged.emit(this.areaToggleStates[this.openTab].title);

        setTimeout(() => {
            this.calculateSectionHeights();
        }, 0);
    }

    initObjectTypes(lastObjectTypes?: ObjectType[]): void {
        for (const type of this.cacheManagerService.objectTypes.getAll()) {
            const typeConfig: any = type.model.config;

            if (typeConfig.cabinetId != this.currentTypeConfig.cabinetId) {
                continue;
            }

            const objectType: ObjectType = {
                title: typeConfig.title,
                icon: this.objectTypeService.getIconClass(typeConfig.objectTypeId, null, true),
                isActive: false,
                isMainSearchType: typeConfig.objectTypeId == this.currentObjectTypeId,
                baseParams: [],
                objectTypeId: typeConfig.objectTypeId,
                internal: typeConfig.internal,
            };

            if (lastObjectTypes) {
                const lastObjectType: ObjectType = lastObjectTypes.find((obj) => obj.internal == typeConfig.internal);

                if (lastObjectType && lastObjectType.isActive) {
                    objectType.isActive = lastObjectType.isActive;
                    objectType.baseParams = lastObjectType.baseParams;
                }
            }

            this.objectTypePerType[this.objectTypeService.getObjectType(typeConfig.objectTypeId)].push(objectType);
        }

        this.objectTypePerType.register.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);
        this.objectTypePerType.document.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);

        this.objectTypes = this.objectTypePerType.folder.concat(this.objectTypePerType.register).concat(this.objectTypePerType.document);
    }

    changeActiveObjectType(objectTypeId: string): void {
        this.baseParamFields = this.baseParamFieldsMap[this.objectTypeService.getObjectType(objectTypeId)];
        this.activeObjectType = this.getObjectType(objectTypeId);

        setTimeout(() => {
            this.calculateSectionHeights();
        }, 0);
    }

    toggleTypeIsActive(objectTypeId: string): void {
        const objectType: ObjectType = this.getObjectType(objectTypeId);

        if (objectType.isActive) {
            this.deselectType(objectType.objectTypeId);

            if (this.selectedTypes.length === 0) {
                this.selectType(objectType.objectTypeId);
                // set first tab active when the current object is deselected
                this.changeActiveObjectType(this.currentObjectTypeId);
            }
            return;
        }
        this.selectType(objectType.objectTypeId);
        this.changeActiveObjectType(objectTypeId);
    }

    selectType(objectTypeId: string): void {
        const type: ObjectType = this.getObjectType(objectTypeId);
        type.isActive = true;
        this.selectedTypes.push(type);
    }

    deselectType(objectTypeId: string): void {
        for (const objectType of this.objectTypes) {
            if (objectType.objectTypeId == objectTypeId) {
                objectType.isActive = false;

                for (let j = 0; j < this.selectedTypes.length; j++) {
                    if (this.selectedTypes[j].objectTypeId == objectTypeId) {
                        this.selectedTypes.splice(j, 1);
                        break;
                    }
                }
                this.changeActiveObjectType(this.selectedTypes[this.selectedTypes.length - 1].objectTypeId);
            }
        }
    }

    getObjectType(objectTypeId: string): ObjectType {
        return this.objectTypes.find(objectType => objectType.objectTypeId == objectTypeId);
    }

    // we need to set a min height for the area/section container to be able to animate and
    // fit everything underneath nicely
    setSectionHeight(container: JQuery): void {
        const itemCount: number = $(container).find(".settings-item").length;
        const itemWidth: number = $(container).find(".settings-item").outerWidth() + 16;
        const containerWidth: number = $(container).width();

        let columnsCount: number = itemWidth > containerWidth ? 1 : Math.floor(containerWidth / itemWidth);
        columnsCount = columnsCount > itemCount ? itemCount : columnsCount;

        const itemsPerCol: number = Math.ceil(itemCount / columnsCount);

        let sectionHeight: number = this.clientService.isTouchDevice() ? itemsPerCol * 40 : itemsPerCol * 32;
        sectionHeight = $(container).hasClass("base-param-container") ? sectionHeight + 56 : sectionHeight + 8;

        $(container).css("min-height", `${sectionHeight}px`);
    }

    calculateSectionHeights(): void {
        if (this.openTab == "baseParams") {
            this.setSectionHeight(this.el.nativeElement.querySelector(".base-param-container"));
        } else {
            // there are multiple objecttype container
            const objectTypeContainer: JQuery[] = this.el.nativeElement.querySelectorAll(".objecttype-container");
            for (const container of objectTypeContainer) {
                this.setSectionHeight(container);
            }
        }
    }

    bindResizeSensor(): void {
        this.resizeObserver.observe(this.el.nativeElement, {attributes: true, subtree: true});
    }

    changeSetting(settingType: string, identifier: string): void {
        const change: any = {};

        switch (settingType) {
            case "baseParams":
                if (!this.activeObjectType.baseParams.includes(identifier)) {
                    this.activeObjectType.baseParams.push(identifier);
                    change.isAdd = true;
                } else {
                    this.activeObjectType.baseParams.splice(this.activeObjectType.baseParams.indexOf(identifier), 1);
                    change.isAdd = false;
                }

                change.changedParam = identifier;
                change.type = "baseParams";
                change.baseParams = this.activeObjectType.baseParams;
                change.objectTypeId = this.activeObjectType.objectTypeId;
                break;
            case "objectType":
                const objectType: ObjectType = this.getObjectType(identifier);
                if (!objectType || (identifier == this.currentObjectTypeId && objectType.isActive)) {
                    return;
                }

                this.toggleTypeIsActive(identifier);

                change.type = "objectType";
                change.objectTypeId = identifier;
                break;
        }

        this.searchsettingschanged.emit(change);
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.messageService.broadcast("show.viewer", true);
        }, 0);

        const data: any = {
            searchSettings: {
                objectTypes: this.objectTypes,
                activeObjectType: this.activeObjectType,
                selectedTypes: this.selectedTypes,
                activeTab: this.openTab
            }
        };
        this.stateHistoryManager.updateStateData(data, this.stateId);
        this.deselectObjectTypeSubscription.unsubscribe();
        this.resizeObserver.disconnect();
    }
}
