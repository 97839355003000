import {Component, Inject} from "@angular/core";
import {ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {OrgAddonService} from "MODULES_PATH/form/services/form-builder/org-addon.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {SortService} from "CORE_PATH/services/utils/sort.service";

@Component({
    selector: "eob-modal-org-multiselect",
    templateUrl: "./eob-modal-org-multiselect.component.html",
    styleUrls: ["./eob-modal-org-multiselect.component.scss"]
})
export class EobModalOrgMultiselectComponent<T extends any> implements OnInit, OnDestroy {
    @Input() content: T[];
    @Input() config: any = {};

    @Output("applyevent") applyEvent: EventEmitter<T[]> = new EventEmitter<T[]>();
    @Output("cancelevent") cancelEvent: EventEmitter<T[]> = new EventEmitter<T[]>();

    leftConfig: any;
    rightConfig: any;

    private readonly translateFn: TranslateFnType;

    // eslint-disable-next-line max-params
    constructor(private el: ElementRef<HTMLElement>, @Inject("$filter") private $filter: ng.IFilterService, private clientService: ClientService,
                private notificationsService: NotificationsService,
                @Inject("sortService") private sortService: SortService, private orgAddonService: OrgAddonService, private messageService: MessageService) {
        this.translateFn = this.$filter("translate") ;
    }

    ngOnInit(): void {
        this.initGrids();
    }

    ngOnDestroy(): void {
        this.cancel();
    }

    private initGrids(): any {
        const defaultGridConfig: any = this.orgAddonService.getDefaultGridConfigs(this.config);

        this.leftConfig = defaultGridConfig.leftConfig;

        this.leftConfig.columnDefs.forEach(column => {
            if (column.field == this.config.initialSort) {
                column.sort = "asc";
            }

            if (column.field == "displayName") {
                column.comparator = this.getSortFn();
            } else if (column.field == "description") {
                column.comparator = this.sortService.sortFieldValueByText;
            }
        });

        this.rightConfig = defaultGridConfig.rightConfig;
    }

    apply(): void {
        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            return;
        }

        this.applyEvent.emit(this.content.filter(entry => (entry as any).selected));
        this.close();
    }

    cancel(): void {
        this.cancelEvent.emit();
        this.close();
    }

    private close(): void {
        this.messageService.broadcast(ModalEvents.DESTROY);
    }

    private getSortFn(): any {
        if (this.config.sortFn != undefined) {
            return this.config.sortFn;
        }

        switch (this.config.sortBy) {
            case "name":
                return (a, b, objA, objB) => this.sortUsers(a, b, objA, objB);
            case "description":
                return (a, b, objA, objB) => this.sortUsersByFullname(a, b, objA, objB);
            case "type":
                return (a, b, objA, objB) => this.sortByNameWithGroupsFirst(a, b, objA, objB);
        }
    }

    private sortByNameWithGroupsFirst(a: any, b: any, objA: any, objB: any): number {
        const nameA: string = objA.data.displayName;
        const nameB: string = objB.data.displayName;
        const typeA: string = objA.data.type;
        const typeB: string = objB.data.type;

        if (typeA != typeB) {
            if (typeA == "group") {
                return -1;
            }
            if (typeB == "group") {
                return 1;
            }
        }

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    private sortUsers(a: any, b: any, userObjA: any, userObjB: any): number {
        const shortA: string = userObjA.data.displayName,
            shortB: string = userObjB.data.displayName;

        if (shortA < shortB) {
            return -1;
        }
        if (shortA > shortB) {
            return 1;
        }
        return 0;
    }

    private sortUsersByFullname(a: any, b: any, userObjA: any, userObjB: any): number {
        const fullA: string = userObjA.data.description,
            fullB: string = userObjB.data.description;

        const shortA: string = userObjA.data.displayName,
            shortB: string = userObjB.data.displayName;

        if (fullA == "" && fullB == "") {
            if (shortA < shortB) {
                return -1;
            }
            if (shortA > shortB) {
                return 1;
            }
            return 0;
        } else {
            if (fullA < fullB) {
                return -1;
            }
            if (fullA > fullB) {
                return 1;
            }
            return 0;
        }
    }
}
