import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ProfileManagerComponent} from "./profile-manager.component";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {NgHybridStateDeclaration} from "@uirouter/angular-hybrid";
import {SharedModule} from "SHARED_PATH/shared.module";
import {FormsModule} from "@angular/forms";
import {AuthenticationModule} from "CORE_PATH/authentication/authentication.module";
import {EobChangePasswordComponent} from "./components/eob-change-password/eob-change-password.component";
import {NewPasswordValidatorDirective} from "MODULES_PATH/profile-manager/directives/new-password-validator.directive";
import {EobLoginComponent} from "./components/eob-login/eob-login.component";
import {EobProfileManagerBannerComponent} from "./components/eob-profile-manager-banner/eob-profile-manager-banner.component";
import {ProfileManagerService} from "MODULES_PATH/profile-manager/profile-manager.service";
import {EobWelcomePageComponent} from "./components/eob-welcome-page/eob-welcome-page.component";
import {FormModule} from "MODULES_PATH/form/form.module";

export const states: NgHybridStateDeclaration[] = [
    {
        name: "native",
        url: "/native"
    },
    {
        name: "native.login",
        url: "/login",
        component: ProfileManagerComponent
    }
];

const components: any[] = [
    ProfileManagerComponent,
    EobProfileManagerBannerComponent,
    EobWelcomePageComponent,
    EobChangePasswordComponent,
    EobLoginComponent,
];

const directives: any[] = [
    NewPasswordValidatorDirective
];

const modules: any[] = [
    CommonModule,
    SharedModule,
    FormsModule,
    FormModule,
    // HttpClientModule,
    // OAuthModule.forRoot(),
    AuthenticationModule,
    UIRouterUpgradeModule.forChild({states})
];

const services: any[] = [
    ProfileManagerService
];

@NgModule({
    declarations: [...components, ...directives],
    imports: [...modules],
    providers: [
        ...services,
        // { provide: HttpClient, useFactory: backend => new HttpClient(backend), deps: [HttpBackend] }
    ],
    exports: [...components]
})
export class ProfileManagerModule {
}
