<div class="nav-bar-container" *ngIf="nav$ | async as navigation">
    <div class="nav-bar-positioning-container" [ngClass]="{'isMinimal': navigation.showNavKebab}">
        <div class="nav-bar-top">
            <ul #navBarTop>
                <li class="nav-tab-container enaio-home-container"
                    style="height: 77px; min-height: 77px !important;"
                    #enaioHomeContainer>
                    <button class="nav-tab-icon enaio-home"
                            title="{{'nav.pane.title.dashboard' | ngTranslate}}"
                            (focus)="handleFocusEvent($event)"
                            (click)="navigationService.openDashboard()"
                            tabindex="0">
                        <div id="enaio-icon" title="{{navBarTitle}}">
                            <img src="assets/enaio_icon.svg" alt="go to dashboard icon">
                        </div>
                    </button>

                </li>
                <li class="nav-tab-container"
                    *ngIf="navigation.showObjectsearch && navigationService.connectionState === 'online'">
                    <button class="nav-tab-icon nav-tab-objectsearch"
                            title="{{'nav.pane.title.objectsearch' | ngTranslate}}"
                            (focus)="handleFocusEvent($event)"
                            (click)="navigationService.switchNavContent('objectsearch', false, $event)"
                            [ngClass]="{'active': navigationService.activeTab === 'objectsearch'}"
                            tabindex="-1">
                        <eob-icon title="{{'nav.pane.title.objectsearch' | ngTranslate}}" name="nav-objectsearch" size="24"></eob-icon>
                    </button>
                </li>
                <li class="nav-tab-container"
                    *ngIf="navigation.showDesktop && navigationService.connectionState === 'online'">
                    <button class="nav-tab-icon nav-tab-desktop"
                            title="{{'nav.pane.title.desktop' | ngTranslate}}"
                            (focus)="handleFocusEvent($event)"
                            (click)="navigationService.switchNavContent('desktop', false, $event)"
                            [ngClass]="{'active': navigationService.activeTab === 'desktop'}"
                            tabindex="-1">
                        <eob-icon title="{{'nav.pane.title.desktop' | ngTranslate}}" name="nav-desktop" size="24"></eob-icon>
                    </button>
                </li>

                <li class="nav-tab-container"
                    *ngIf="navigation.showQuickSearches && navigationService.connectionState === 'online'">
                    <button class="nav-tab-icon nav-tab-quicksearch"
                            title="{{'nav.pane.title.quicksearch' | ngTranslate}}"
                            (focus)="handleFocusEvent($event)"
                            (click)="navigationService.switchNavContent('quicksearch', false, $event)"
                            [ngClass]="{'active': navigationService.activeTab === 'quicksearch'}"
                            tabindex="-1">
                        <eob-icon title="{{'nav.pane.title.quicksearch' | ngTranslate}}" name="nav-quicksearch" size="24"></eob-icon>
                    </button>
                </li>
                <li class="nav-tab-container"
                    (focus)="handleFocusEvent($event)"
                    *ngIf="navigation.showInboxArea && navigationService.connectionState === 'online'">
                    <button class="nav-tab-icon nav-tab-inbox"
                            title="{{'nav.pane.title.inbox' | ngTranslate}}"
                            (focus)="handleFocusEvent($event)"
                            (click)="navigationService.switchNavContent('inbox', false, $event)"
                            [ngClass]="{'active': navigationService.activeTab === 'inbox'}"
                            tabindex="-1">
                        <eob-icon title="{{'nav.pane.title.inbox' | ngTranslate}}" name="nav-inbox" size="24"></eob-icon>
                    </button>
                </li>

                <li class="nav-tab-container"
                    (focus)="handleFocusEvent($event)"
                    [hidden]="!navigation.externalTrayElementsAvailable || !navigation.showExternalTray">
                    <button class="nav-tab-icon nav-tab-external-tray"
                            title="{{'nav.pane.title.external.tray' | ngTranslate}}"
                            (focus)="handleFocusEvent($event)"
                            (click)="navigationService.switchNavContent( 'externaltray', false, $event)"
                            [ngClass]="{'active': navigationService.activeTab === 'externaltray'}"
                            tabindex="-1">
                        <eob-icon title="{{'nav.pane.title.external.tray' | ngTranslate}}" name="nav-document-tray" size="24"></eob-icon>
                        <div class="tray-counter">
                            <span>{{navigationService.externalTrayItemCount}}</span>
                            <div class="tray-pulse" *ngIf="externalTrayRefreshed"></div>
                        </div>
                    </button>
                </li>

                <!--!!This is a work around for a chrome bug, do not remove!-->
                <li class="i-got-your-back"></li>
                <!---->
            </ul>
        </div>
        <div class="nav-bar-bottom">
            <ul #navBarBottom>
                <li class="nav-tab-container"
                    *ngIf="navigation.showNavKebab">
                    <button class="nav-tab-icon nav-tab-kebab"
                            title="{{'nav.kebab.panel.title' | ngTranslate}}"
                            (focus)="handleFocusEvent($event)"
                            (click)="navigationService.switchNavContent('kebab', false, $event)"
                            [ngClass]="{'active': navigationService.activeTab === 'kebab'}"
                            tabindex="-1">
                        <eob-icon title="{{'nav.kebab.panel.title' | ngTranslate}}" name="kebab-white" size="24"></eob-icon>
                    </button>
                </li>

                <li class="nav-tab-container"
                    *ngIf="navigation.showOfflineObjects && !navigation.showNavKebab">
                    <button class="nav-tab-icon"
                            title="{{'eob.app.bar.offline.objects.title' | ngTranslate}}"
                            [ngClass]="{'nav-tab-offline-objects': navigationService.connectionState === 'offline', 'nav-tab-favorites': navigationService.connectionState === 'online'}"
                            (click)="navigationService.goToSecondaryState($event, 'hitlist.offlineObjects')"
                            (focus)="handleFocusEvent($event)"
                            tabindex="-1">
                        <svg id="animated-fav-svg" alt="show favorites navigation tab" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" version="1.1" xml:space="preserve">
                                <title>{{'eob.app.bar.offline.objects.title' | ngTranslate}}</title>
                            <defs>
                                    <linearGradient id="bottom-to-top"  x1="0%" y1="100%" x2="0%" y2="0%">
                                        <stop class="gradient-stop-bottom" offset="0" style="stop-color: #fff; stop-opacity: 1"></stop>
                                        <stop class="gradient-stop-top" offset="0" style="stop-color: #fff; stop-opacity: 0"></stop>
                                        <!--<style type="text/css"> .st0 { fill: none; stroke: #FFFFFF; stroke-miterlimit: 10;} </style>-->
                                    </linearGradient>
                                </defs>
                            <path
                                stroke="#ffffff"
                                [attr.fill]="absUrl"
                                stroke-width="1" d="M17.7,21.1L12,17.2l-5.7,3.9L8,14.3L2.8,9.9l6.8-0.4L12,2.9l2.5,6.6l6.8,0.3L16,14.3
                                    C16,14.3,17.7,21.1,17.7,21.1z"/>
                            </svg>
                    </button>
                </li>

                <li class="nav-tab-container"
                    *ngIf="navigation.showFavorites && !navigation.showNavKebab">
                    <button class="nav-tab-icon" [ngClass]="{'nav-tab-offline-objects': navigationService.connectionState === 'offline', 'nav-tab-favorites': navigationService.connectionState === 'online'}"
                            title="{{'eob.app.bar.favorites.title' | ngTranslate}}"
                            (click)="navigationService.goToSecondaryState($event,'hitlist.favorites')"
                            (focus)="handleFocusEvent($event)"
                            tabindex="-1">
                        <svg id="animated-fav-svg" [attr.fill]="'transparent'" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" version="1.1" xml:space="preserve">
                                <title>{{'eob.app.bar.favorites.title' | ngTranslate}}</title>
                            <path stroke="#ffffff" stroke-width="1" d="M17.7,21.1L12,17.2l-5.7,3.9L8,14.3L2.8,9.9l6.8-0.4L12,2.9l2.5,6.6l6.8,0.3L16,14.3
                                    C16,14.3,17.7,21.1,17.7,21.1z"/>
                            </svg>
                    </button>
                </li>

                <li class="nav-tab-container"
                    *ngIf="navigation.showHistory && !navigation.showNavKebab && navigationService.connectionState === 'online'">
                    <button class="nav-tab-icon nav-tab-history"
                            title="{{'eob.app.bar.history.title' | ngTranslate}}"
                            (click)="navigationService.goToSecondaryState($event,'hitlist.history')"
                            (focus)="handleFocusEvent($event)"
                            tabindex="-1">
                        <eob-icon title="{{'eob.app.bar.history.title' | ngTranslate}}" name="nav-history" size="24"></eob-icon>
                    </button>
                </li>

                <li class="nav-tab-container"
                    *ngIf="navigation.showNavAvatar">
                    <button class="nav-tab-icon nav-tab-global-avatar"
                            (click)="showUserMenu($event)"
                            (focus)="handleFocusEvent($event)"
                            tabindex="-1">
                        <eob-nav-user-avatar></eob-nav-user-avatar>
                        <div class="tray-update-badge" *ngIf="showUpdateBadge">
                            <span>&#128257;</span>
                            <div class="tray-pulse" *ngIf="showUpdatePulse"></div>
                        </div>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
