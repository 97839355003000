import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {Observable} from "rxjs";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";

@Injectable({providedIn: BackendOsRestModule})
export class OsRestFavoritesService {

    constructor(private httpClient: HttpClient) {
    }

    getFavorites(): Observable<BackendObject> {
        return this.httpClient.get<BackendObject>("/osrest/api/documents/favourites?verbose=true&fieldschema=AUTO");
    }
}