import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Type} from "@angular/core";
import {EobWopiContainerComponent} from "MODULES_PATH/wopi/eob-wopi-container.component";
import {EobWopiHostComponent} from "MODULES_PATH/wopi/components/eob-wopi-host/eob-wopi-host.component";

const components: Array<Type<unknown>> = [
    EobWopiContainerComponent,
    EobWopiHostComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule
    ],
    entryComponents: [...components],
    exports: [...components]
})
export class WopiModule {
}
