(function () {
    require("ag-grid-enterprise/dist/ag-grid-enterprise.min.js");

    require("COMPONENTS_PATH/eob-state-wrapper/eob-workflow-info/eob.workflow.info.dir.js");

    angular.module("eob.client").controller("workflowInfoController", WorkflowInfoController);

    WorkflowInfoController.$inject = ["$stateParams", "$state"];

    function WorkflowInfoController($stateParams, $state) {
        if ($stateParams.state == void 0) {
            let stateId = $.now();
            let workflowID = $stateParams.id;

            $state.go("workflowInfo", {id: workflowID, state: stateId})
        }
    }
})();
