import * as angular from "angular";

/**
 * Returns an instance of a progressbar
 */
export class Progressbar {
    private _isIndeterminate: boolean;
    private _hideToolbar: boolean;

    private _currentPercent: number = 0;
    private _displayPercent: number = 0;

    private _osProgressbarElement: any;
    private _textElement: any;
    private _fillbar: any;

    constructor(protected id: string, protected element: any, protected isIndeterminate: boolean, protected hideToolbar: boolean) {
        this._isIndeterminate = !!isIndeterminate;
        this._hideToolbar = hideToolbar;

        this._currentPercent = 0;
        this._displayPercent = 0;

        const searchForm: any = element ? element : document;
        this._osProgressbarElement = undefined;
        this._textElement = undefined;
        this._fillbar = undefined;

        const tmpProgressbarList: HTMLElement[] = searchForm.getElementsByClassName("osProgressbar");
        for (const progressbarItem of tmpProgressbarList) {
            if (progressbarItem.id == id) {
                this._osProgressbarElement = progressbarItem;
            }
        }

        if (!this._osProgressbarElement) {
            console.warn("Error: osProgressbar: no Element found");
            return;
        }

        if (!this._isIndeterminate) {
            this.unsetIndeterminate();
        }
    }

    setIndeterminate = (): void => {
        if (this._osProgressbarElement.children === void 0 || this._osProgressbarElement.children.length < 2) {
            return;
        }

        this._osProgressbarElement.children[1].classList.add("invisible");
        this._osProgressbarElement.children[0].classList.remove("invisible");

        this._textElement = undefined;
        this._fillbar = undefined;
    };

    unsetIndeterminate = (): void => {
        if (this._osProgressbarElement.children === void 0 || this._osProgressbarElement.children.length < 2) {
            return;
        }

        this._osProgressbarElement.children[0].classList.add("invisible");
        this._osProgressbarElement.children[1].classList.remove("invisible");

        this._textElement = this._osProgressbarElement.getElementsByClassName("percentView");
        this._textElement = this._textElement.length > 0 ? this._textElement[0] : null;
        this._fillbar = angular.element(this._osProgressbarElement).find("#fill")[0];
    };

    getPercent = (): number => this._currentPercent;

    addPercent = (val: number): void => {
        this.setPercent(this._currentPercent + val);
    };

    setPercent = (val: number): void => {
        if (this._isIndeterminate || isNaN(val)) {
            return;
        }

        if (val > 100) {
            val = 100;
        } else if (val < 0) {
            val = 0;
        }

        this._currentPercent = val;

        // only update the visual progressbar with a min difference of one
        if (Math.abs(val - this._displayPercent) >= 1) {
            this.updateLoading(this, Math.floor(val));
        }
    };

    toggleIndeterminate = (): void => {
        if (this._osProgressbarElement === void 0 ||
            this._osProgressbarElement.children === void 0 ||
            this._osProgressbarElement.children.length < 2) {
            return;
        }

        if (this._isIndeterminate) {
            this.unsetIndeterminate();
            this._isIndeterminate = false;
        } else {
            this.setIndeterminate();
            this._isIndeterminate = true;
        }
    };

    updateLoading = (elem: any, newPercent: number): void => {
        if (elem._osProgressbarElement == void 0 || elem._fillbar == void 0 || elem._textElement == void 0) {
            return;
        }

        const zeroPercentMargin: number = 90;
        const percentageInMargin: number = zeroPercentMargin - Math.floor(newPercent * zeroPercentMargin / 100);

        elem._fillbar.style.marginTop = `${percentageInMargin}px`;
        elem._textElement.innerText = `${newPercent}%`;

        elem._displayPercent = newPercent;
    };

    setWidth = (size: any): void => {
        /* not needed */
    };

    /**
     * Only use with a not indeterminated progressbar to show a count up of numbers instead of the precent.
     * Use as an alternative to addPercent(...).
     *
     * @param {number} current - the new value for the count up
     * @param {number} max - the static end value
     */
    updateValueXofMax = (current: number, max: number): void => {
        if (this._osProgressbarElement == void 0 || this._fillbar == void 0 || this._textElement == void 0) {
            return;
        }

        const zeroPercentMargin: number = 90;
        const newPercent: number = Math.floor(current / max * 100);
        const percentageInMargin: number = zeroPercentMargin - Math.floor(newPercent * zeroPercentMargin / 100);

        this._fillbar.style.marginTop = `${percentageInMargin}px`;
        this._textElement.innerText = `${current}/${max}`;
    };

    show = (): void => {
        if (this._osProgressbarElement !== void 0) {
            this._osProgressbarElement.classList.remove("invisible");
        }

        if (this._hideToolbar) {
            const toolbar: HTMLCollection = document.getElementsByClassName("pdfjs-toolbar");
            if (toolbar.length > 0) {
                toolbar[0].classList.add("invisible");
            }
        }
    };

    hide = (): void => {
        if (this._osProgressbarElement !== void 0) {
            this._currentPercent = 0;
            this._displayPercent = 0;
            this._osProgressbarElement.classList.add("invisible");
        }

        if (this._hideToolbar) {
            const toolbar: HTMLCollection = document.getElementsByClassName("pdfjs-toolbar");
            if (toolbar.length > 0) {
                toolbar[0].classList.remove("invisible");
            }
        }
    };
}

/**
 * params:
 *
 *  @param {int} Id:  number
 *  @param {dom element} element:  (optional) the startelement to find the progressbar html element
 *  @param {bool} isIndeterminate: (optional) switch to the indeterminate type.
 * Types:
 *          - progressbar               : with 'set' (percent) to show progress steps.
 *          - indeterminate progressbar : only turning circle
 * functions:
 *      bar.show : show the progressbar
 *      bar.hide : show the progressbar
 *      bar.percent(val) (set) : add new loadstep (val : 0-100)
 *      bar.percent      (get) : get current percent.
 *      bar.setWidth : just needed for pdfjs - DONT USE IT! - no functionality.
 *
 * <div id="1" class="osProgressbar invisible">
 * <div class="osloader" ></div>                default.
 * <div class="invisible percentLoader">        info: block not needed for indeterminate progressbar
 * <div id="mask">
 * <span class="percentView">0%</span>
 * <div id="fill"></div>
 * </div>
 * </div>
 * </div>
 */
export class ProgressbarService {
    /**
     * returns an instance of Progressbar
     *
     * @param id
     * @param element
     * @param isIndeterminate
     * @param hideToolbar
     * @returns {any}
     */
    getProgressbarInstance(id: string, element: any, isIndeterminate?: boolean, hideToolbar?: boolean): Progressbar {
        if (!id) {
            console.warn("Error: osProgressbar: no ID.");
            return;
        }

        return new Progressbar(id, element, isIndeterminate, hideToolbar);
    }
}
