import * as angular from "angular";
import * as dayjs from "dayjs";
import {Injectable, Inject} from "@angular/core";

import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {DmsModule} from "MODULES_PATH/dms/dms.module";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {OsrestChildrenHierarchyResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-children-hierarchy-result.interface";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {FieldDataType} from "ENUMS_PATH/field.enum";
import {ObjectTypeRights} from "ENUMS_PATH/objecttype-rights.enum";
import {ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {TodoWorkflowProcess, TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {BackendObjectInsert, BackendObjectInserts} from "CORE_PATH/backend/interfaces/backend-object-insert.interface";
import {BackendObjectService} from "CORE_PATH/backend/services/object/backend-object.service";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import {IdPair} from "INTERFACES_PATH/id-pair.interface";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {DmsContainerModel} from "MODULES_PATH/dms/interfaces/dms-container.interface";
dayjs.extend(customParseFormat);
/**
 * Class used for creation and manipulation of {@link DmsDocument} objects
 */
@Injectable({ providedIn: DmsModule })
export class DmsDocumentService {

    private translateFn: TranslateFnType;

    // eslint-disable-next-line max-params
    constructor(@Inject("$filter") $filter: angular.IFilterService,
                @Inject("$injector") private $injector: ng.auto.IInjectorService,
                @Inject("errorModelService") private errorModelService: ErrorModelService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("offlineCacheService") private offlineCacheService: OfflineCacheService,
                private notificationsService: NotificationsService,
                private clientService: ClientService,
                private httpService: HttpService,
                private backendObjectService: BackendObjectService,
                private valueUtilsService: ValueUtilsService) {
        this.translateFn = $filter("translate");
    }

    /**
     * returns a new instance of a dms document.
     *
     * @param {Object} data - the raw data from the appconnector
     * @returns {DmsDocument} - return a new dms document
     */
    createDmsDocument = (data: any): DmsDocument => new DmsDocument(data, this.$injector);

    createDmsDocumentHierarchy(data: BackendObject): DmsDocument {
        const dmsDocument: DmsDocument = this.createDmsDocument(data);

        if (data.children) {
            (dmsDocument.model as DmsContainerModel).children = data.children.map(child => this.createDmsDocumentHierarchy(child));
        }

        return dmsDocument;
    }

    /**
     * Validates the given osid if it would be valid
     * Caution: This function returns true in case the osid is a valid number and does not validate if this document
     * exists
     *
     * @param osid - the object id
     * @param desc? - a optional custom description in case the validation fails
     * @returns {boolean}
     */
    isValidOsid = (osid: any, desc?: string): boolean => {
        if (osid == void 0 || osid === "" || isNaN(osid)) {
            console.warn(`The ${desc == void 0 ? "osid" : desc} must be a valid number.`);
            return false;
        }

        return true;
    };

    /**
     * Validates against the backend whether the container (register or folder) accepts another document of this type.
     *
     * @param {string} osId - the osid of the container.
     * @param {string} objectTypeId - the objectTypeId of the container.
     * @param {string} insertType - the objectType we want to insert into the container.
     * @param {string} sourceOsid - the osid  where the object should be inserted from.
     * @param {number} objectCount - The amount of objects, that shall be inserted.
     * @throws {CustomError} WEB_NO_COMPATIBLE_OBJECTTYPE, WEB_MAX_OBJECTTYPE, WEB_MOVE_PARENT_IN_CHILD.
     * @returns {Promise<void>} Resolves when the object can be inserted. Otherwise a specific error is thrown, why the object can't be inserted.
     */
    async canObjectInsert(osId: string, objectTypeId: string, insertType: string, sourceOsid?: string, objectCount: number = 1): Promise<void> {
        const objectInserts: BackendObjectInserts = await this.backendObjectService.getDmsObjectInserts(osId, objectTypeId).toPromise();
        const objectInsert: BackendObjectInsert = objectInserts[parseInt(insertType)];

        // object insert values: -1 unlimited, 0+ number of allowed objects, undefined means the object type could not be inserted
        if (objectInsert == void 0) {
            throw this.errorModelService.createCustomError("WEB_NO_COMPATIBLE_OBJECTTYPE");
        }

        // more objects should be inserted than allowed
        if (objectInsert.maxCount !== -1 && objectCount > objectInsert.maxCount) {
            throw this.errorModelService.createCustomError("WEB_MAX_OBJECTTYPE");
        }

        if (sourceOsid) {
            const childrenHierarchy: OsrestChildrenHierarchyResult[] = await this.httpService.getChildrenHierarchy(sourceOsid.toString()).toPromise();

            if (this.checkChildrenForMatchingId(childrenHierarchy, osId)) {
                throw this.errorModelService.createCustomError("WEB_MOVE_PARENT_IN_CHILD");
            }
        }
    }

    /**
     * Checks for an osid inside a child hierarchy
     *
     * @param childrenHierarchy XHR result data from backend call to /documents/childrenHierarchy/[id]
     * @param {string|number} targetOsid The osid to look for inside the hierarchy
     * @returns {boolean} Whether the osid is included in the child hierarchy
     */
    private checkChildrenForMatchingId(childrenHierarchy: any, targetOsid: string | number): boolean {
        for (const obj of childrenHierarchy) {
            if (obj.id == targetOsid || (obj.children.length > 0 && this.checkChildrenForMatchingId(obj.children, targetOsid))) {
                return true;
            }
        }

        return false;
    }

    /**
     * Validates if the user has the rights to export content of the given document and whether the backend allows it or not
     */
    isExportContentAllowed(dmsDocument?: DmsDocument, typeConfig?: ObjectTypeConfig): boolean {
        if (this.environmentService.env.actions.useFileExport && this.environmentService.userHasRole("R_CLNT_EXPORT")) {
            if (!dmsDocument || (dmsDocument.model.hasContent && (dmsDocument.model.objectTypeId == "-1" || this.hasRights([ObjectTypeRights.OBJECT_EXPORT], dmsDocument, typeConfig)))) {
                return true;
            }
        }

        return false;
    }

    isExportIndexdataAllowed(docModel?: DmsDocumentModel): boolean {
        const exportRolesAreSplit: boolean = this.environmentService.featureSet.contains("dms.export.rights_split");
        const exportIndexdataRole: boolean = this.environmentService.userHasRole(exportRolesAreSplit ? "R_CLNT_EXPORT_INDEX_DATA" : "R_CLNT_EXPORT");
        return exportIndexdataRole && this.environmentService.env.actions.useIndexdataExport && (!docModel || docModel.objectTypeId != "-1");
    }

    hasRights(rights: string[], dmsDocument: DmsDocument, typeConfig?: ObjectTypeConfig): boolean {
        for (const r of rights) {
            if (typeConfig?.rights && typeConfig.rights[r] === false) {
                return false;
            }

            if (Object.keys(dmsDocument.model.rights).length == 0 || (Object.keys(dmsDocument.model.rights).length > 0 && dmsDocument.model.rights[r] === false)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if a dms document has a workflow item.
     *
     * @param {string | number} osid
     * @returns {boolean} returns true if a dms document has a workflow item otherwise returns false.
     */
     hasWorkflowItem = async (osid: string | number): Promise<boolean> => {
        const dataUrl: string = `/workflows/processes/${osid}`;
        const workflowItems: TodoWorkflowProcess = await this.httpService.legacyGet(dataUrl);

        try {
            return !!workflowItems.data?.length;
        } catch (error) {
            console.log(`legacyGet for ${dataUrl} failed:, `, error);
        }
    };

    /**
     * Check whether we support content editing for the given dms item.
     *
     * @param {DmsDocument} dmsDocument - A dms item.
     * @param {Object} typeConfig - The type config of the dms item.
     * @returns {boolean} Is content editing supported.
     */
    isEditContentAllowed(dmsDocument: DmsDocument, typeConfig: any): boolean {
        const editableMainTypes: string[] = ["1", "2", "3", "4"];
        const docModel: DmsDocumentModel = dmsDocument.model;

        // no right to edit content
        if (!dmsDocument.model.rights.objModify) {
            return false;
        }

        // each document with one of the following maintypes can't be edited
        // register and folder do not have any content
        if (/(0|6|8|99)/gi.test(`${docModel.mainType}`) || /(0|6|8|99)/gi.test(`${docModel.subType}`)) {
            return false;
        }

        // this document has the maintype 5-8 where we do not support editing content
        // but adding content to a document, which has none, is still possible
        if (!editableMainTypes.includes(docModel.mainType as string) && docModel.hasContent) {
            return false;
        }

        // the document is already archived or it is only a reference to another document
        if (docModel.baseParameters.archiveState == "ARCHIVED" || docModel.baseParameters.archiveState == "REFERENCE") {
            return false;
        }

        // no content allowed
        return !typeConfig.withoutPages;
    }

    /**
     * Query whether the given document has children
     *
     * @param {DmsDocument} dmsDocument
     * @returns {Promise<boolean>} Whether the queried document has children
     */
    hasChildren = async (dmsDocument: DmsDocument): Promise<boolean> => {
        const docModel: DmsDocumentModel = dmsDocument.model;

        if (docModel.mainType == "99" || docModel.mainType == "0") {
            const response: any = await this.httpService.getObjectHierarchy(docModel.osid).toPromise();
            const documents: any = response.documents;
            let childDocuments: any = [];

            if (documents.length && documents[0].children && documents[0].children.documents) {
                childDocuments = documents[0].children.documents;
            }

            return childDocuments.length !== 0;
        }

        return false;
    };
    /**
     * Queries whether the passed document has multiple locations
     *
     * @param {DmsDocument} dmsDocument
     * @returns {Promise<boolean>} Whether the document has multiple locations
     */
    hasMultipleLocations = async (dmsDocument: DmsDocument): Promise<boolean> => {
        const locations: IdPair[][] = await this.backendObjectService.getDmsObjectLocations(dmsDocument.model.osid, dmsDocument.model.objectTypeId).toPromise();
        return locations.length > 1;
    };

    /**
     * Build a title for an dms object based on configured fields for a specific mode.
     *
     * @param {DmsDocument} dmsDocument - The dms object the title should be build for.
     * @param {"hitlist"|"send"|"window"} [mode="hitlist"] - A mode defining which configured fields should be used.
     * @param {boolean} allowEmptyFields - Include fields with empty value
     * @returns {string} the title for the dms object.
     */
    buildTitleByMode = (dmsDocument: DmsDocument, mode: string = "hitlist", allowEmptyFields: boolean = true): string => {
        const objectTypeId: string | number = dmsDocument.model.objectTypeId,
            cacheManagerService: any = this.$injector.get("cacheManagerService"),
            typeDef: any = cacheManagerService.objectTypes.getById(objectTypeId),
            langCode: string = this.environmentService.getLanguage();
        let configuredFields: any;

        mode = mode.toLowerCase();

        if (["send", "window"].includes(mode)) {
            configuredFields = typeDef.api.getConfiguredTitleFields(mode);
        }

        if (mode == "hitlist" || configuredFields == void 0 || Object.keys(configuredFields).length == 0) {
            configuredFields = typeDef.api.getConfiguredFields();
        }

        if (configuredFields == void 0 || Object.keys(configuredFields).length == 0) {
            console.warn(`Could not get configured fields for: ${mode}, object type id ${dmsDocument.model.objectTypeId}`);
            return "";
        }

        return this.buildTitleFromConfiguredFields(dmsDocument.model.fields, configuredFields, langCode, Object.keys(configuredFields).length, "", allowEmptyFields);
    };

    /**
     * Build a title from a list of indexdata fields and configured fields.
     *
     * @param {object} fields - A map of fields by internal name of an dms object.
     * @param {object} fieldConfiguration - A map of data for the configured fields by internal name of an object type.
     * @param {string} [langCode = de] - The language the fields should be formatted in.
     * @param {int} [length = 3] - The amount of fields, that should be used at max. Use -1 for no limit.
     * @param {string=} prefix - A prefix that is added bevor the list of field values.
     * @param {boolean} allowEmptyFields - Include fields with empty value
     * @returns {string} a title consisting of field values seperated by a hyphen.
     */
    private buildTitleFromConfiguredFields(fields: {[k: string]: any}, fieldConfiguration: any, langCode: string = "de", length: number = 3, prefix: string, allowEmptyFields: boolean = false): string {
        let namingParts: string[] = [];

        const configuredFields: any[] = Object.values(fieldConfiguration).sort((a: any, b: any) => a.position - b.position);
        for (const configuredField of configuredFields) {
            const type: any = configuredField.type;
            let value: string = fields[configuredField.internal] || "";

            if (type == "date" || type == "datetime") {
                if (!isNaN(Number(value)) && value != "") {
                    const format: string = type == "datetime" ? this.environmentService.env.dateFormat.datetime : this.environmentService.env.dateFormat.date;
                    value = dayjs(new Date(parseInt(value, 10))).format(format);
                } else if (value != "" && langCode != "de" && value.includes(".")) {
                    if (type == "datetime") {
                        value = dayjs(value, "DD.MM.YYYY HH:mm:ss").format(this.environmentService.env.dateFormat.datetime);
                    } else {
                        value = dayjs(value, "DD.MM.YYYY").format(this.environmentService.env.dateFormat.date);
                    }
                }
            } else if (type === FieldDataType.DECIMAL) {
                if (value) {
                    const convertValue: string | number = this.valueUtilsService.numberToLocaleNumber(value, ".2-2");
                    value = convertValue.toString();
                }
            }

            if (allowEmptyFields || value !== "") {
                namingParts.push(value);
            }
        }

        if (prefix != void 0 && prefix !== "") {
            namingParts.unshift(prefix);
        }

        if (length >= 0) {
            namingParts = namingParts.slice(0, length);
        }

        return namingParts.join(" - ");
    }
}
