import {Injectable} from "@angular/core";
import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {
    ContextMenu,
    ContextMenuAction,
    ContextMenuItem,
    WorkflowContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ObjectType, ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {Inject} from "@angular/core";
import {
    TodoCacheManagerService,
    TodoDesktopService,
    TodoEnvironmentService,
    TodoModalDialogService,
    TodoStateHistoryManager,
    TodoStateService,
    TodoVariantService
} from "INTERFACES_PATH/any.types";
import {ObjectTypeRights} from "ENUMS_PATH/objecttype-rights.enum";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {ClipboardDesktopItem, ClipboardDmsDocument} from "INTERFACES_PATH/custom-storage.interface";
import {DesktopItem} from "INTERFACES_PATH/desktop.interface";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {InboxActionService} from "CORE_PATH/services/actions/inbox-action.service";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {DmsActionService} from "MODULES_PATH/dms/dms-action.service";
import {DmsDocumentService} from "MODULES_PATH/dms/dms-document.service";
import {DmsContentService} from "MODULES_PATH/dms/dms-content.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {VariantDocumentModel, VariantVersion} from "INTERFACES_PATH/variant.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import { OfflineLocationCacheService } from "SERVICES_PATH/offline/eob.offline.location.cache.srv";

/**
 * A service that provides functions to build the contextmenu actions (single selection).
 */
@Injectable({providedIn: ContextMenuModule})
export class ContextMenuActionsService {

    private readonly translateFn: TranslateFnType;

    private contextMenu: ContextMenu = {} as ContextMenu;

    // eslint-disable-next-line max-params
    constructor(@Inject("$filter") private $filter: ng.IFilterService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("variantService") private variantService: TodoVariantService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("desktopService") private desktopService: TodoDesktopService,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("stateService") private stateService: TodoStateService,
                @Inject("inboxActionService") private inboxActionService: InboxActionService,
                @Inject("offlineCacheService") private offlineCacheService: OfflineCacheService,
                @Inject("offlineLocationCacheService") private offlineLocationCacheService: OfflineLocationCacheService,
                private layoutManagerService: LayoutManagerService,
                private contextMenuUtilsService: ContextMenuUtilsService, private actionService: ActionService,
                private clientService: ClientService, private objectTypeService: ObjectTypeService,
                private dmsActionService: DmsActionService, private dmsDocumentService: DmsDocumentService,
                private dmsContentService: DmsContentService, private messageService: MessageService,
                private iconService: IconService) {
        this.translateFn = $filter("translate");
    }

    /**
     /**
     * Add actions to the contextmenu
     *
     * @param {Object} items - The selected items
     * @param {String} context - The context.
     * @param {Object} additionalContextData - Additional data from the context
     * @returns {Array} - Array of possible context menu actions
     */
    async getSingleselectItems(contextMenuItem: ContextMenuItem, contextMenuActions: ContextMenuAction[], context: string, additionalContextData: ContextData): Promise<void> {
        let dmsDocument: DmsDocument;
        let typeDef: ObjectType;

        if (contextMenuItem.osid != void 0) {
            let objectTypeId: string;

            dmsDocument = this.cacheManagerService.dmsDocuments.getById(contextMenuItem.osid);

            if (contextMenuItem != void 0 && contextMenuItem.objectTypeId) {
                objectTypeId = contextMenuItem.objectTypeId;
            } else if (dmsDocument != void 0 && dmsDocument.model.objectTypeId) {
                objectTypeId = dmsDocument.model.objectTypeId;
            }

            typeDef = this.cacheManagerService.objectTypes.getById(objectTypeId);
        }

        if (typeDef || dmsDocument) {
            await this.addSingleSelectActions(
                dmsDocument,
                contextMenuActions,
                context,
                typeDef,
                additionalContextData);
        }
    }

    async addSingleSelectActions(dmsDocument: DmsDocument, actions: ContextMenuAction[], context: string, typeDef: ObjectType, additionalContextData: ContextData): Promise<void> {

        this.initContextMenu(dmsDocument, actions, typeDef, context, additionalContextData);

        if (this.clientService.isOnline()) {

            await this.contextMenuUtilsService.addExternalTools([this.contextMenu.dmsDocument], this.contextMenu.actions);

            this.addShowIndexdata();
            this.addShowObject();
            this.addEditIndexdata();
            this.addShowContainerChildren();
            this.addEditDocumentContent();
            this.addMailActions();
            this.addLinkActions();
            this.addDelete();

            await this.addOpenLocation();

            this.addObjectReferences();
            this.addCopyToClipboard(additionalContextData);
            this.addCutToClipboard();
            this.addWorkflows();
            this.addExportActions();
            this.addPrintContent();
            this.addRemoveFavorite();
            this.addRevisit();
            this.addAbo();
            this.addInsertFromClipboardActions();
            this.addInsertTypelessFromClipboardActions();
            this.addVariantManager();
            this.addArchiveAction();
            this.addInsertActions();
        } else {
            this.contextMenu.offlineAvailability = await this.offlineCacheService.getOfflineAvailability(dmsDocument.model.id.toString());

            this.addShowIndexdata();
            this.addShowObject();
            this.addShowContainerChildren();

            if (this.contextMenu.offlineAvailability.originalContent && dmsDocument.model.mainType == 6) {
                if (this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_EXPORT], dmsDocument, this.contextMenu.typeConfig)) {
                    this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.content.show.title",
                        "dokument-ansehen", this.dmsContentService.showEmailContent, dmsDocument));
                }
            }

            this.addLinkActions();

            await this.addOpenLocation();

            this.addPrintContent();
            this.addExportActions();

        }

        if (!this.environmentService.featureSet.contains("dms.osfile")) {
            this.addCopyPreviewURLToClipboard();
        }
    }

    async getVariantContextMenuActions(contextMenuItem: ContextMenuItem): Promise<ContextMenuAction[]> {
        const contextMenuActions: ContextMenuAction[] = [],
            dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(contextMenuItem.osid),
            docModel: DmsDocumentModel = dmsDocument.model,
            objectType: ObjectType = this.cacheManagerService.objectTypes.getById(docModel.objectTypeId),
            variantModel: VariantDocumentModel = docModel.variantData[0].model;

        this.initContextMenu(dmsDocument, contextMenuActions, objectType, "variants");

        this.contextMenu.offlineAvailability = await this.offlineCacheService.getOfflineAvailability(docModel.id.toString());

        if (this.clientService.isOnline() &&
            !docModel.isLocked &&
            this.dmsDocumentService.hasRights([ObjectTypeRights.INDEXDATA_MODIFY, ObjectTypeRights.OBJECT_MODIFY], dmsDocument, this.contextMenu.typeConfig)) {

            const versions: VariantVersion = this.variantService.getNextVersions(docModel.osid);

            if (!variantModel.isActive) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.variants.set.active", "variant-active",
                    this.variantService.changeActiveVariant, dmsDocument, this.stateHistoryManager.getCurrentConfig().caller));
            }

            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction(this.translateFn("eob.contextmenu.action.variants.new.head") + versions.main, "main-variant",
                this.stateHistoryManager.goToCreateVariantState, dmsDocument, "main"));

            if (versions.parallel != void 0) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction(this.translateFn("eob.contextmenu.action.variants.new.para") + versions.parallel,
                    "sub-variant", this.stateHistoryManager.goToCreateVariantState, dmsDocument, "parallel"));
            }

            if (versions.sub != void 0) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction(this.translateFn("eob.contextmenu.action.variants.new.sub") + versions.sub,
                    "parallel-variant", this.stateHistoryManager.goToCreateVariantState, dmsDocument, "sub"));
            }

            if (this.contextMenu.actions.length > 0) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addDivider());
            }
        }

        this.addShowIndexdata();
        this.addShowObject();
        this.addEditIndexdata();
        this.addEditDocumentContent();
        this.addPrintContent();

        if (variantModel.isActive) {
            this.addMailActions();
        }

        if (this.clientService.isOnline() && variantModel.version != "Original" && !docModel.isLocked &&
            this.dmsDocumentService.hasRights([ObjectTypeRights.INDEXDATA_MODIFY], dmsDocument, objectType.model.config)) {

            const text: string = variantModel.hasChildren ? "eob.contextmenu.action.variants.delete.multiple" : "eob.contextmenu.action.variants.delete.single";

            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction(text, "delete-dark", this.variantService.deleteVariant, dmsDocument));
        }

        if (variantModel.isActive) {
            await this.addOpenLocation();
        }

        this.addWorkflows();
        this.addExportActions();
        this.addObjectReferences();

        if (variantModel.isActive) {
            this.addRevisit();
            this.addAbo();
        }

        this.addArchiveAction();

        return this.contextMenu.actions;
    }

    async getSpecialFolderItems(item: ContextMenuItem, contextMenuActions: ContextMenuAction[]): Promise<void> {
        const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(item.osid);
        const typeDef: ObjectType = this.cacheManagerService.objectTypes.getById(dmsDocument.model.objectTypeId);

        this.initContextMenu(dmsDocument, contextMenuActions, typeDef, "");

        this.contextMenu.offlineAvailability = await this.offlineCacheService.getOfflineAvailability(dmsDocument.model.id.toString());

        this.addInsertFromClipboardActions();
        this.addInsertTypelessFromClipboardActions();
        this.addInsertActions();
    }

    private initContextMenu(item: DmsDocument, actions: ContextMenuAction[], typeDef?: ObjectType, context?: string, contextData?: ContextData): void {
        this.contextMenu.actions = actions;
        this.contextMenu.context = context;
        this.contextMenu.env = this.environmentService.env;

        if (item == void 0) {
            return;
        }

        const parentId: string = contextData == void 0 ? null : contextData.parentId;

        this.contextMenu.dmsDocument = item;
        this.contextMenu.docModel = item.model;
        this.contextMenu.typeDef = typeDef;
        this.contextMenu.typeConfig = null;
        this.contextMenu.parentId = parentId;
        this.contextMenu.offlineAvailability = null;

        if (this.contextMenu.docModel != void 0) {
            this.contextMenu.typeConfig = this.cacheManagerService.objectTypes.getById(this.contextMenu.docModel.objectTypeId).model.config;
        }
    }

    private addPrintContent = (): void => {
        if (this.contextMenu.docModel.hasContent && this.clientService.isDesktop() && (this.clientService.isOnline() || this.contextMenu.offlineAvailability.previewContent)) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.print.document.title",
                "print", this.dmsContentService.printContentAsync.bind(this.dmsContentService), this.contextMenu.dmsDocument));
        }
    };

    private addShowIndexdata = (): void => {
        if (!this.contextMenu.docModel.isTypeless && (this.clientService.isOnline() || this.contextMenu.offlineAvailability.indexdata) && !this.layoutManagerService.isTouchLayoutActive()) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.indexdata.show.title",
                "datenblatt-ansehen", this.stateHistoryManager.goToShowIndexData, this.contextMenu.dmsDocument, "contextmenu"));
        }
    };

    private addShowObject = (): void => {
        if (!this.contextMenu.docModel.isTypeless && (this.clientService.isOnline() || this.contextMenu.offlineAvailability.indexdata) && this.layoutManagerService.isTouchLayoutActive() && !(!this.clientService.isPhoneOrTablet() && this.clientService.isTouchDevice())) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.object.show.title", "datenblatt-ansehen", () => {
                this.messageService.broadcast(ModalEvents.OPEN_MODAL_DASHLETS, this.contextMenu.docModel.id);
            }, this.contextMenu.dmsDocument));
        }
    };

    private addEditIndexdata = (): void => {
        if (!this.contextMenu.docModel.isTypeless && this.clientService.isOnline() && this.dmsDocumentService.hasRights([ObjectTypeRights.INDEXDATA_MODIFY], this.contextMenu.dmsDocument, this.contextMenu.typeConfig)) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.indexdata.edit.title",
                "datenblatt-bearbeiten", this.stateHistoryManager.goToEditIndexData, this.contextMenu.dmsDocument, "contextmenu"));
        }
    };

    private addShowContainerChildren = (): void => {
        if (this.contextMenu.context != "folderTree" && (this.contextMenu.docModel.isRegister || this.contextMenu.docModel.isFolder) &&
            this.contextMenu.docModel.rights.objExport && (this.clientService.isOnline() || this.contextMenu.offlineAvailability.indexdata)) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.content.show.title",
                "dokument-ansehen", this.actionService.executeDoubleClickAction, this.contextMenu.dmsDocument, this.contextMenu.context, true));
        }
    };

    private addEditDocumentContent = (): void => {
        if (!this.clientService.isOnline()) {
            return;
        }

        // Variants can be opened readonly if systemrole is missing. Then we must check inside the variant
        // state regarding the indexModify object right. If it is not given content isn't also allowed to edit.
        const isVariantState: boolean = this.contextMenu.context == "variants";

        if (!this.dmsDocumentService.isEditContentAllowed(this.contextMenu.dmsDocument, this.contextMenu.typeConfig) || (isVariantState &&
            !this.dmsDocumentService.hasRights([ObjectTypeRights.INDEXDATA_MODIFY], this.contextMenu.dmsDocument, this.contextMenu.typeConfig))) {
            return;
        }

        // someone else locked this document, and we cannot alter its content
        if (this.contextMenu.docModel.baseParameters.locked == "OTHERS") {
            return;
        }

        if (!this.contextMenu.typeDef.model.config.withoutPages && this.contextMenu.docModel.mainType != 6) {
            if (this.contextMenu.docModel.baseParameters.locked == "UNLOCKED" || !this.contextMenu.docModel.hasContent) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.content.edit.title",
                    "edit-content", this.actionService.editDocumentContent, this.contextMenu.dmsDocument));
            } else if (this.contextMenu.docModel.baseParameters.locked == "SELF") {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.content.checkin.title",
                    "lock", this.dmsActionService.checkIn, this.contextMenu.dmsDocument));
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.content.undocheckout.title",
                    "undo-checkout", this.dmsActionService.undoCheckOut.bind(this.dmsActionService), this.contextMenu.dmsDocument));
            }
        }
    };

    private addMailActions = (): void => {
        if (!this.clientService.isOnline() || this.contextMenu.docModel.isTypeless) {
            return;
        }

        if (this.contextMenu.docModel.hasContent && this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_EXPORT], this.contextMenu.dmsDocument, this.contextMenu.typeConfig)) {
            if (Number(this.contextMenu.docModel.mainType) === 6 || Number(this.contextMenu.docModel.subType) === 6) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.content.show.title",
                    "dokument-ansehen", this.dmsContentService.showEmailContent, this.contextMenu.dmsDocument));
            }
        }

        const mailActions: ContextMenuAction[] = [];

        if (this.contextMenu.env.email.sendLink) {
            mailActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.email.link.title", "mail-house",
                this.modalDialogService.sendLinkDialog, this.contextMenu.dmsDocument));
        }

        if (this.contextMenu.env.email.smtpServer && this.environmentService.userHasRole("R_CLNT_SENDEXT")) {
            if (this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_EXPORT], this.contextMenu.dmsDocument, this.contextMenu.typeConfig) && this.contextMenu.docModel.hasContent) {
                mailActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.email.content.title",
                    "mail-earth", this.modalDialogService.sendContentDialog, this.contextMenu.dmsDocument));
            }
        }

        if (mailActions.length) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.email.title", "send-to", mailActions));
        }
    };

    private addLinkActions = (): void => {
        if (!this.environmentService.featureSet.contains("dms.osfile")) {
            return;
        }

        const linkActions: ContextMenuAction[] = [];

        this.addCopyPreviewURLToClipboard(linkActions);

        if (this.contextMenu.env.oswebURL && !this.contextMenu.docModel.isTypeless) {
            const oswebURL: string = decodeURIComponent(this.environmentService.env.oswebURL);
            const url = `${oswebURL}#/entry?location=${this.contextMenu.dmsDocument.model.osid}&objecttypeid=${this.contextMenu.dmsDocument.model.objectTypeId}`;

            linkActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.copylink.webclientlink.title", "copy-url", this.dmsActionService.copyWebclientURLToClipboard, url));
        }

        if (!this.contextMenu.docModel.isTypeless && this.clientService.isOnline()) {
            let title: string = this.dmsDocumentService.buildTitleByMode(this.contextMenu.dmsDocument, "send");

            if (!title) {
                const timestamp: string = (+new Date()).toString();

                title = `link-${timestamp}`;
            }

            const osFileName = `${title}.os`;

            linkActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.copylink.link.title",
                "download-os-file", this.dmsActionService.downloadOSFile.bind(this.dmsActionService), this.contextMenu.dmsDocument, osFileName));
        }

        if (linkActions.length) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.copylink.title", "copy-link", linkActions));
        }
    };

    private addDelete = (): void => {
        // the document is archived but we do not own the systemrole to delete it though :(
        if (this.clientService.isOnline() && this.contextMenu.docModel.baseParameters.archiveState == "ARCHIVED" && !this.environmentService.userHasRole("R_CLNT_ARC_DELDOCS")) {
            return;
        }

        if (this.contextMenu.context != "result" && this.contextMenu.context != "hitlist.result" && this.contextMenu.context != "folder" && this.contextMenu.context != "desktop" && this.contextMenu.context != "folderTree") {
            return;
        }

        // we can only delete if the document is not locked by others
        if (this.contextMenu.docModel.baseParameters.locked != "OTHERS" && this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_DELETE], this.contextMenu.dmsDocument, this.contextMenu.typeConfig)) {
            if (this.contextMenu.context === "desktop") {
                const contextItem: ContextMenuItem = this.contextMenu.actions[0].params[0] as ContextMenuItem;

                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.delete.title",
                    "delete-dark", this.desktopService.removeAndDeleteDesktopItem, contextItem));
            } else {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.delete.title",
                    "delete-dark", this.dmsActionService.deleteDmsObjects, [this.contextMenu.dmsDocument], this.contextMenu.parentId));
            }
        }
    };

    private addOpenLocation = async (): Promise<void> => {
        if (!this.clientService.isOnline() && !(await this.offlineLocationCacheService.getCachedLocationPath(this.contextMenu.docModel.id.toString()))) {
            return;
        }

        if (this.contextMenu.docModel.isTypeless
            || this.contextMenu.docModel.isinWfTray
            || this.contextMenu.context == "folder"
            || (this.contextMenu.context == "result" && this.stateHistoryManager.getCurrentStateData().data.flat)
            || (this.contextMenu.context == "hitlist.result" && this.stateHistoryManager.getCurrentStateData().data.flat)
            || !this.contextMenu.env.actions.useShowLocation
            || this.contextMenu.docModel.isFolder) {
            return;
        }

        let dmsDoc: DmsDocument = this.contextMenu.dmsDocument;

        if (this.contextMenu.docModel.variantData != void 0 && !this.contextMenu.docModel.variantData[0].model.isActive) {
            const activeDocId: string = this.variantService.getActiveVariantId(this.contextMenu.docModel.osid);
            dmsDoc = this.cacheManagerService.dmsDocuments.getById(activeDocId);

            // Fake object. We only need the id for opening.
            if (dmsDoc == null) {
                dmsDoc = {
                    model: {
                        osid: activeDocId
                    }
                } as DmsDocument;
            }
        }

        if (this.contextMenu.context != "folderTree") {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.show.location.title",
                "location", this.stateService.goToLocationAsync, dmsDoc));
        }
    };

    private addCopyToClipboard = (contextData: ContextData): void => {
        if (this.contextMenu.env.actions.useClipboard && (parseInt(this.contextMenu.docModel.objectTypeId) > -1) || this.contextMenu.docModel.isTypeless) {
            if (contextData && contextData.workflowId) {
                this.contextMenu.dmsDocument.model.workflowId = contextData.workflowId;
            }

            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.clipboard.copy.title",
                "clipboard-set", this.actionService.copyToClipboard, this.contextMenu.dmsDocument, this.contextMenu.parentId));
        }
    };

    private addCutToClipboard = (): void => {
        if ((this.contextMenu.docModel.mainType != "0") && (this.environmentService.userHasRole("R_CLNT_MOVE_OBJECTS")) &&
            this.contextMenu.env.actions.useClipboard && /(folder|folderTree)/gi.test(this.contextMenu.context)) {

            if (!this.dmsDocumentService.hasRights([ObjectTypeRights.INDEXDATA_MODIFY], this.contextMenu.dmsDocument, this.contextMenu.typeConfig)) {
                return;
            }

            if ((this.contextMenu.docModel.baseParameters.objectCount > 0) && !this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_MODIFY], this.contextMenu.dmsDocument, this.contextMenu.typeConfig)) {
                return;
            }

            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.clipboard.cut.title",
                "cut", this.actionService.cutToClipboard, this.contextMenu.dmsDocument, this.contextMenu.parentId));
        }
    };

    private addWorkflows = (): void => {
        if (this.clientService.isOnline() && this.environmentService.isWorkflowUser()) {
            const workflows: WorkflowContextMenuItem[] = this.environmentService.getStartableWorkflows();
            const workflowActions: ContextMenuAction[] = [];

            let icon = "";

            if (workflows.length) {
                for (const workflow of workflows) {
                    icon = workflow.iconId > 0 && this.iconService.hasIcon(`${workflow.iconId}`) ? `custom-icon-${workflow.iconId}` : "workflow-startable-blue";

                    if (this.environmentService.isAllowedObjectTypes([this.contextMenu.dmsDocument.model.internal], workflow.workflowId)) {
                        workflowActions.push(this.contextMenuUtilsService.addAction(workflow.title, icon, this.inboxActionService.startWorkflow, this.contextMenu.dmsDocument, workflow.id));
                    }
                }

                this.contextMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.workflow.title", "workflow-startable-blue", workflowActions));
            }
        }
    };

    private addExportActions = (): void => {
        if (this.contextMenu.docModel.isTypeless || (!this.clientService.isOnline() && !this.contextMenu.offlineAvailability.indexdata)) {
            return;
        }

        const exportActions: ContextMenuAction[] = [];

        if (this.dmsDocumentService.isExportIndexdataAllowed(this.contextMenu.docModel)) {
            exportActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.export.hitlist.title",
                "export-indexdata", this.modalDialogService.exportIndexDataDialog, this.contextMenu.dmsDocument));
        }

        if (this.dmsDocumentService.isExportContentAllowed(this.contextMenu.dmsDocument, this.contextMenu.typeConfig)) {
            if ((this.clientService.isOnline() || this.contextMenu.offlineAvailability.originalContent)) {
                exportActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.export.content.title",
                    "export-content", this.dmsContentService.exportContentAsync.bind(this.dmsContentService), this.contextMenu.dmsDocument));
            }

            // there can be an export violation under the conditions that the user is only allowed to export a defined set of objectTypes
            const exportViolation: boolean = this.contextMenu.env.export.allowedTypes.length && this.contextMenu.env.export.allowedTypes.indexOf(this.contextMenu.docModel.objectTypeId) == -1;
            if (this.contextMenu.env.actions.useMergePdf && !exportViolation && (this.clientService.isOnline() || this.contextMenu.offlineAvailability.previewContent)) {
                exportActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.export.pdf.title", "export-pdf", this.dmsContentService.exportPdfAsync.bind(this.dmsContentService), this.contextMenu.dmsDocument));
            }
        }

        if (exportActions.length) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.export.title", "export", exportActions));
        }
    };

    private addRemoveFavorite = (): void => {
        if (!this.clientService.isOnline() || this.contextMenu.docModel.isTypeless || this.contextMenu.docModel.isinWfTray
            || (this.contextMenu.docModel.variantData != void 0 && !this.contextMenu.docModel.variantData[0].model.isActive)) {
            return;
        }

        if (this.environmentService.userHasRole("R_CLNT_SHOW_MOBFAV")) {
            if (this.contextMenu.docModel.isFavorite == true) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.fav.remove.title",
                    "remove-from-favs", this.dmsActionService.removeFavorites, [this.contextMenu.dmsDocument]));
            } else {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.fav.add.title",
                    "add-to-favs", this.dmsActionService.addFavorites, [this.contextMenu.dmsDocument]));
            }
        }
    };

    private addRevisit = (): void => {
        if (!this.clientService.isOnline() || this.contextMenu.docModel.isTypeless || !this.contextMenu.env.actions.useRevisits
            || (this.contextMenu.docModel.variantData != void 0 && !this.contextMenu.docModel.variantData[0].model.isActive)) {
            return;
        }

        this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.notification.revisit.title",
            "clock-dark", this.modalDialogService.addRevisitDialog, this.contextMenu.dmsDocument));
    };

    private addAbo = (): void => {
        if (this.clientService.isOnline() && this.contextMenuUtilsService.allowAddAbo(this.contextMenu.dmsDocument, this.contextMenu.context, this.contextMenu.env)) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.notification.abo.title",
                "abonnieren", this.modalDialogService.addAboDialog, this.contextMenu.dmsDocument));
        }
    };

    private addVariantManager = (): void => {
        // either the user has no right to administrate variants or the document does not support this action
        // For variant manager the mainType is relevant. Also mainType 4 but subType 6 (E-Mail) has a
        // variant manager visible.
        if (!this.clientService.isOnline()
            || !this.contextMenu.env.actions.useVariants
            || (this.contextMenu.docModel.mainType != 4 && this.contextMenu.docModel.subType != 4)
            || (this.contextMenu.docModel.baseParameters.archiveState == "REFERENCE")) {
            return;
        }

        // missing rights?
        if ((!this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_MODIFY, ObjectTypeRights.INDEXDATA_MODIFY], this.contextMenu.dmsDocument, this.contextMenu.typeConfig) ||
            this.contextMenu.docModel.baseParameters.objectCount == "0") && !this.environmentService.userHasRole("R_CLNT_VAR_SHOWALWAYS")) {
            return;
        }

        if (!this.contextMenu.docModel.hasContent || this.contextMenu.docModel.isTypeless) {
            return;
        }

        // return if it is checked out by someone else
        if (this.contextMenu.docModel.baseParameters.locked == "OTHERS") {
            return;
        }

        if (this.contextMenu.docModel.isinWfTray || this.contextMenu.docModel.isReference) {
            return;
        }

        this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.variants.title", "variant",
            this.contextMenuUtilsService.checkVariantRights, this.contextMenu.dmsDocument));
    };

    private addInsertFromClipboardActions = (): void => {
        if (!this.clientService.isOnline() || this.contextMenu.docModel.rights.objExport === false) {
            return;
        }

        const clipboard: ClipboardDmsDocument = this.environmentService.getClipboard();
        const clipboardDmsDocument: DmsDocument = clipboard == void 0 ? null : clipboard.item;
        const insertActions: ContextMenuAction[] = [];

        if (
            !!clipboard &&
            clipboard.clipboardAction == "copy" &&
            this.contextMenu.docModel.osid !== clipboardDmsDocument.model.osid &&
            !clipboardDmsDocument.model.isinWfTray &&
            !clipboardDmsDocument.model.isTypeless &&
            this.environmentService.featureSet.contains("dms.create.links")
        ) {
            if (this.environmentService.isObjectLinkNoteAllowed()) {
                insertActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.insert.link.object",
                    "links", this.modalDialogService.createLinkObjectDialog, this.contextMenu.dmsDocument));
            } else {
                insertActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.insert.link.object",
                    "links", this.dmsActionService.addNoteLink, clipboardDmsDocument, this.contextMenu.dmsDocument, ""));
            }

            if (!clipboard || (this.contextMenu.docModel.mainType != "0" && this.contextMenu.docModel.mainType != "99") || !this.contextMenu.typeConfig.insertableTypes) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.insert.title", "clipboard-set", insertActions));
            }
        }

        if (!clipboard || (this.contextMenu.docModel.mainType != "0" && this.contextMenu.docModel.mainType != "99") || !this.contextMenu.typeConfig.insertableTypes) {
            return;
        }

        if (clipboardDmsDocument.model == void 0) {
            return;
        }

        if (clipboard.clipboardAction == "copy") {
            const clipboardTypeConfig: ObjectTypeConfig = this.cacheManagerService.objectTypes.getById(clipboardDmsDocument.model.objectTypeId).model.config;

            if (this.contextMenu.docModel.mainType.toString() != "8" && clipboardTypeConfig.rights &&
                clipboardTypeConfig.rights[ObjectTypeRights.INDEXDATA_MODIFY] && !clipboardDmsDocument.model.isinWfTray
                && this.contextMenuUtilsService.canInsert(this.contextMenu.typeConfig, clipboardDmsDocument)) {
                insertActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.insert.copy.title", "document-exchange", this.actionService.copyItem, this.contextMenu.dmsDocument));
            }

            if (clipboardTypeConfig.mainType != "0" && clipboardTypeConfig.mainType != "99") {
                if (this.environmentService.userHasRole("R_CLNT_CREATE_REFERENCE") && this.contextMenuUtilsService.canInsert(this.contextMenu.typeConfig, clipboardDmsDocument)) {
                    const title: string = "eob.contextmenu.action.insert.location.title";
                    const icon: string = "new-location";

                    if (clipboardDmsDocument.model.isinWfTray) {
                        insertActions.push(this.contextMenuUtilsService.addAction(title, icon, this.actionService.moveItem.bind(this.actionService), this.contextMenu.dmsDocument));
                    } else {
                        insertActions.push(this.contextMenuUtilsService.addAction(title, icon, this.dmsActionService.linkItemAsync, this.contextMenu.dmsDocument));
                    }
                }

                if (clipboardDmsDocument.model.hasContent
                    && !clipboardDmsDocument.model.isinWfTray
                    && !clipboardDmsDocument.model.isTypeless
                    && this.environmentService.userHasRole("R_CLNT_CREATE_REFERENCE_DOCS")
                    && clipboardDmsDocument.model.baseParameters.archiveState != "REFERENCE"
                    && this.environmentService.featureSet.contains("dms.create.greenArrowReference")) {

                    const insertableTypes: string[] = [];

                    this.contextMenuUtilsService.resolveObjectTypes(this.contextMenu.typeConfig).forEach((typeConfig) => {
                        if (typeConfig.mainType != "8" && typeConfig.rights[ObjectTypeRights.INDEXDATA_MODIFY]) {
                            insertableTypes.push(typeConfig.objectTypeId.toString());
                        }
                    });

                    insertActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.insert.reference.title",
                        "REFERENCE", this.actionService.createObjectReference, this.contextMenu.dmsDocument, insertableTypes));
                }
            }

            if (insertActions.length) {
                this.contextMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.insert.title", "clipboard-set", insertActions));
            }
        } else if (this.contextMenu.docModel.osid != clipboardDmsDocument.model.osid && clipboard.parentId != this.contextMenu.docModel.osid
            && this.contextMenuUtilsService.canInsert(this.contextMenu.typeConfig, clipboardDmsDocument)) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.insert.title", "clipboard-get", this.actionService.moveItem.bind(this.actionService), this.contextMenu.dmsDocument));
        }
    };

    private addInsertTypelessFromClipboardActions = (): void => {
        const clipboard: ClipboardDesktopItem | ClipboardDmsDocument = this.environmentService.getClipboard();
        const clipboardDmsDocument: DmsDocument | DesktopItem = clipboard == void 0 ? null : clipboard.item;

        if (!this.clientService.isOnline() || !clipboard) {
            return;
        }

        if ((this.contextMenu.docModel.mainType != "0" && this.contextMenu.docModel.mainType != "99") || !this.contextMenu.typeConfig.insertableTypes) {
            return;
        }

        if ((clipboardDmsDocument as DmsDocument).model && !(clipboardDmsDocument as DmsDocument).model.isTypeless) {
            return;
        }

        if (clipboard.clipboardAction != "copy") {
            return;
        }

        const insertableTyplessActions: ContextMenuAction[] = [];

        this.contextMenuUtilsService.resolveObjectTypes(this.contextMenu.typeConfig).filter((typeConfig) =>
            this.contextMenuUtilsService.isInsertableTypeless(typeConfig.mainType, typeConfig.multiType, typeConfig.withoutPages)).forEach((typeConfig) => {
            if (typeConfig.mainType != "8" && this.dmsDocumentService.hasRights([ObjectTypeRights.INDEXDATA_MODIFY], this.contextMenu.dmsDocument, typeConfig)) {

                // We need the "dark" icons, since the rectangle inside the icons isn't visible otherwise
                const icon: string = typeConfig.icon.replace(/-white$/gi, "");

                if (this.environmentService.isAllowedObjectTypes([typeConfig.internal], (clipboardDmsDocument as DmsDocument).model.workflowId)) {
                    insertableTyplessActions.push(this.contextMenuUtilsService.addAction(typeConfig.name, icon, this.actionService.createObjectFromTypeless, this.contextMenu.dmsDocument, typeConfig.objectTypeId));
                }
            }
        });

        if (insertableTyplessActions.length) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.insert.as.title", "clipboard-get", insertableTyplessActions));
        }
    };

    private addArchiveAction = (): void => {
        if (!this.clientService.isOnline() || !this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_MODIFY, ObjectTypeRights.INDEXDATA_MODIFY], this.contextMenu.dmsDocument, this.contextMenu.typeConfig) || this.contextMenu.docModel.baseParameters.objectCount == "0") {
            return;
        }

        if (this.contextMenu.docModel.isinWfTray) {
            return;
        }

        if (!this.environmentService.userHasRole("R_CLNT_ARC_CHANGESTATE")) {
            return;
        }

        if (this.contextMenu.docModel.baseParameters.archiveState == "NOT_ARCHIVABLE") {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.archive.set.title",
                "set-archivable", this.dmsActionService.setArchivable, [this.contextMenu.dmsDocument]));
        } else if (this.contextMenu.docModel.baseParameters.archiveState == "ARCHIVABLE") {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.archive.unset.title",
                "nicht-archivierbar-setzen", this.dmsActionService.setNotArchivable, [this.contextMenu.dmsDocument]));
        }
    };

    private addInsertActions = (): void => {
        if (!this.clientService.isOnline() || !this.contextMenu.typeConfig.insertableTypes || this.contextMenu.typeConfig.insertableTypes.length == 0) {
            return;
        }

        const insertableActions: ContextMenuAction[] = [];

        this.contextMenuUtilsService.resolveObjectTypes(this.contextMenu.typeConfig).forEach((typeConfig) => {
            const icon: string = this.objectTypeService.getIconClass(typeConfig.objectTypeId, null, true);
            insertableActions.push(this.contextMenuUtilsService.addAction(typeConfig.title, icon, this.actionService.createObject, this.contextMenu.dmsDocument.model, typeConfig.objectTypeId));
        });

        if (insertableActions.length && this.contextMenu.docModel.rights.objExport !== false) {
            this.contextMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.new.title", "dokument-neu", insertableActions));
        }
    };

    private addObjectReferences = (): void => {
        if (this.clientService.isOnline() && !this.contextMenu.docModel.isTypeless && !this.contextMenu.docModel.isinWfTray) {
            if ((/FOLDER|REGISTER/.test(this.contextMenu.dmsDocument.model.objectType) && !this.contextMenu.docModel.baseParameters.links) ||
                (Array.isArray(this.contextMenu.docModel.variantData) && this.contextMenu.docModel.variantData.length && !this.contextMenu.docModel.variantData[0].model.isActive)) {
                return;
            }
            this.contextMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.locations.links.references",
                "links", this.stateHistoryManager.goToObjectReferencesState, this.contextMenu.dmsDocument));
        }
    };

    private addCopyPreviewURLToClipboard(linkActions: ContextMenuAction[] = this.contextMenu.actions): void {
        if (this.contextMenu.env.contentViewerURL && !this.contextMenu.docModel.isTypeless) {
            const url: string = `${decodeURIComponent(this.environmentService.env.contentViewerURL) + this.contextMenu.dmsDocument.model.osid}?pagecount=1`;

            linkActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.copylink.previewlink.title", "popout", this.dmsActionService.copyPreviewURLToClipboard, url));
        }
    }
}
