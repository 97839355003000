import {Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {CapslockTracerService} from "MODULES_PATH/form/services/capslock-tracer.service";
import {Subscription} from "rxjs";

@Component({
    selector: "eob-capslock-tracer",
    templateUrl: "./capslock-tracer.component.html",
    styleUrls: ["./capslock-tracer.component.scss"]
})
export class CapslockTracerComponent implements OnInit, OnDestroy {
    capsLockOn: boolean;
    showCapsLockWarning: boolean;
    subs: Subscription = new Subscription();

    @HostListener("window:keydown", ["$event"]) onKeyDown(event: KeyboardEvent): void {
        const capsOn = event.getModifierState && event.getModifierState("CapsLock");
        this.capslockTracerService.capsLockOn.next(capsOn);
    }

    @HostListener("window:keyup", ["$event"]) onKeyUp(event: KeyboardEvent): void {
        const capsOn = event.getModifierState && event.getModifierState("CapsLock");
        this.capslockTracerService.capsLockOn.next(capsOn);
    }

    constructor(private capslockTracerService: CapslockTracerService) { }

    ngOnInit(): void {
        this.subs.add(this.capslockTracerService.capsLockOn.subscribe((x: boolean) => this.capsLockOn = x));
        this.subs.add(this.capslockTracerService.showCapsLockWarning.subscribe((x: boolean) => this.showCapsLockWarning = x));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
