import {Inject} from "@angular/core";
import BrowserDetect from "browser-detect";
import {BrowserDetectInfo} from "browser-detect/dist/types/browser-detect.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {fromEvent, Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {Connection} from "ENUMS_PATH/connection.enum";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {Orientation} from "ENUMS_PATH/orientation.enum";
import {ClientServiceMessage} from "ENUMS_PATH/client.enum";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {Platform} from "SHARED_PATH/types/platform.type";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

declare global {
    interface DocumentEventMap {
        "open.os.file": CustomEvent<any>;
    }

    interface RootScope extends ng.IRootScopeService {
        webclientReady: boolean;
        $$listenerCount?: Map<string, number>;
    }

    interface CordovaPlugins {
        SecureStorage: any;
        fileOpener2: any;
        workarounds: any;
        intent: any;
        EmmAppConfig: any;
    }

    interface Cordova {
        plugins: CordovaPlugins;
    }

    interface Window {
        webviewId: string;
        openedUrl: string | null;
        FilePath: any;
        plugins: CordovaPlugins;
        electron: any;
        cordova: Cordova;
        cookieMaster: any;

        /**
         * Event handler used in mobile apps to open passed files / intents
         */
        handleOpenURL(url: string | string[] | Map<string, string | ArrayBuffer>): void;
    }
}

/**
 * Base class, which is being extended by the platform specific client services
 */
export abstract class ClientService {
    private currentConnectivity: Connection = Connection.UNKNOWN;
    private currentOrientation: Orientation = this.getOrientationType();
    private connectivityChangeHandlers: any[] = [];
    private orientationChangeHandlers: any[] = [];
    private synchronizationChangeHandlers: any[] = [];
    cacheLockedSubject: Subject<any> = new Subject<any>();

    isLoggingOut = false;
    private gIsTouchDevice: boolean;
    private gIsPhone: boolean;
    private gIsAndroid: boolean;
    private gIsIOS: boolean;
    private gIsIOSMobile: boolean;
    private gIsForcedPhone: boolean;
    private gIsWindows: boolean;
    private randomSessionId: string | null = null;
    private randomTabId: string | null = null;

    private readonly stateHistoryManager: any;
    private readonly $state: any;
    private connectivityChangeTimeout: any;
    protected readonly translateFn: TranslateFnType;

    /**
     * Whether an offline synchronization is running.
     */
    protected isSynchronizing = false;
    protected isCacheLocked = false;

    constructor(@Inject("$filter") protected $filter: ng.IFilterService, @Inject("$injector") protected $injector: ng.auto.IInjectorService, @Inject("$eobConfig") protected $eobConfig: any,
                @Inject("$rootScope") protected $rootScope: RootScope, protected errorModelService: ErrorModelService, protected messageService: MessageService,
                protected authenticationService: AuthenticationService, protected customStorageService: CustomStorageService) {
        this.gIsTouchDevice = this.checkIsTouchDevice();
        this.gIsPhone = this.checkIsPhone();
        this.gIsIOS = (/iPad|iPhone|iPod/.test(window.navigator.userAgent) || navigator.platform.includes("Mac")) && !(window as any).MSStream;
        this.gIsIOSMobile = /iPad|iPhone|iPod/.test(window.navigator.userAgent) || (navigator.platform.includes("Mac") && navigator.maxTouchPoints == 5);
        this.gIsAndroid = window.navigator.userAgent.includes("Android");
        this.gIsForcedPhone = !this.isDetached() && window.innerWidth < 600;
        this.gIsWindows = navigator.platform.includes("Win");
        this.translateFn = this.$filter("translate");
        this.stateHistoryManager = this.$injector.get("stateHistoryManager");
        this.$state = this.$injector.get("$state");
        this.setRandomIds();
    }

    /**
     * Performs platform specific logout actions (e.g. closing tabs on electron)
     */
    abstract platformLogoutAsync(): Promise<void>;

    /**
     * Clear all cookies for the specific platform.
     * This will delete the session token for example, ensuring that a new user isn't logged in as the previous user.
     */
    abstract clearCookies(): void;

    /**
     * Opens a file with the default app (all platforms) or offer to save the given file to a location the user chooses (Android)
     *
     * @access public
     * @param dmsDocument - dmsDocumentCache of the dms object to open
     * @param fileName - name of the file incl. extension
     * @param fileContent - An arraybuffer with the bytes of the content
     * @param save - whether the file should be saved rather than opened
     */
    abstract platformOpenFileDataInDefaultAppAsync(dmsDocument: DmsDocument, fileName: string,
                                                   fileContent: ArrayBuffer, save?: boolean): Promise<string | Error | void>;

    /**
     * Performs platform specific logout actions (e.g. using file-saver in browser)
     *
     * @param filename
     * @param fileContent
     * @returns filename
     */
    abstract platformSaveAsAsync(filename: string, fileContent: ArrayBuffer | Blob): Promise<string>;

    /**
     * Refreshes the title of the active tab accoring to the active state
     *
     * @param tabTitle - The name that should be displayed
     */
    abstract refreshTabTitle(tabTitle: string): void;

    /**
     * Writes content to a temporary file and return it file handle.
     *
     * @param filename - The name of the file in the filesystem.
     * @param fileContent - The file content to write
     * @return - A handle to the written file
     */
    abstract writeDataIntoTempFileAsync(filename: string, fileContent: ArrayBuffer): Promise<string | ArrayBuffer>;

    /**
     * Reads data from a given file and return it as string or arraybuffer.
     *
     * @param filePath - The absolut path to the file in the filesystem
     * @param isText - True if it should be read as text, otherwise false
     * @return - The read string or arraybuffer
     */
    abstract readDataFromFileAsync(filePath: string, isText: boolean): Promise<string | ArrayBuffer>;

    /**
     * On mobile devices the photo library return a file path like contents:// instead of file:///
     * We must translate this kind of resource identifier to the real file path with file:///
     * In case we get already a absolute file path the return it instant. This method is currently
     * only implemented for cordova. All other return the input parameter.
     *
     * @param filePath - A absolut path to the file in the filesystem or a resource identifier
     * @return - A absolut path to the file in the filesystem
     */
    abstract correctFilePathAsync(filePath: string): Promise<string>;

    // TODO remove storage methods from client service and use custom storage service
    /**
     * Returns functions for secure- and unsecure string storage. The secure methods are only returned
     * if they are explicitly requested. This is for performance. Init the security is time intensive.
     *
     * @return - The Methods for storage.
     */
    abstract getStorageAsync(): Promise<CustomStorage>;

    /**
     * Broadcasts that the external tray items has been changed.
     */
    abstract broadcastTrayItemsChanged(): void;

    /**
     * Broadcasts when sync started or finished.
     */
    abstract setIsSynchronizing(isSynchronizing: boolean): void;

    /**
     * gets sync status (running or not running).
     */
    abstract getIsSynchronizing(): Promise<boolean>;

    /**
     * Broadcasts when cache lock started or finished.
     */
    abstract setIsCacheLocked(isCacheLocked: boolean): void;

    /**
     * Gets cache lock status.
     */
    abstract getIsCacheLocked(): Promise<boolean>;

    /** gets the color theme */
    abstract setColorTheme(theme: string): void;

    /** returns the MDM config as an object in cordova and undefined in browser/electron */
    abstract getMDMConfig(): any | undefined;

    /**
     * Checks if files are currently checked out. At the moment only electron is implemented.
     * That rejects closing the client and forces to checkin or discard the checkouts.
     *
     * @return - True if there are checked out files, otherwise false.
     */
    abstract checkForCheckedOutFilesAsync(): Promise<boolean>;

    /**
     * Executes on electron a program on the host machine.
     *
     * @param pathToProgram - The absolute or relative path to the program to execute.
     * @param programArguments - Arguments to send to the program
     * @param returnResult - true if the call should wait on called program exit, false not.
     * @return - Returns the stdout contents if we should wait until program exit or null if we are not waiting.
     */
    abstract execProgramAsync(pathToProgram: string, programArguments: string, returnResult: boolean, workingDirectory?: string): Promise<string>;

    /**
     * Init service
     */
    init(): void {
        this.initConnectivityChangeHandler();
        if (this.gIsPhone) {
            this.initOrientationChangeHandler();
        }
        this.messageService.broadcast<ClientService>(ClientServiceMessage.INITIALIZED, this);
    }

    /**
     * Init connectivity change handler to notify observer if the client go online or offline.
     */
    initConnectivityChangeHandler(): void {
        this.currentConnectivity = this.getConnectionType();

        document.addEventListener("resume", () => {
            setTimeout(() => {
                if (this.currentConnectivity == this.getConnectionType()) {
                    return;
                }
                if (this.getConnectionType() == Connection.NONE) {
                    clearTimeout(this.connectivityChangeTimeout);
                }
                this.currentConnectivity = this.getConnectionType();
                this.notifyConnectivityChange();
            }, 0);
        });

        // cordova-plugin-network-information. Catch the events when we go online or offline.
        fromEvent(window, "online").pipe(debounceTime(1000)).subscribe(event => {
            clearTimeout(this.connectivityChangeTimeout);
            if (this.currentConnectivity == this.getConnectionType()) {
                return;
            }

            this.currentConnectivity = this.getConnectionType();

            // The connection stack need some time to initialize it self. If we switch too early
            // then the requests will fail. Therefore we wait 5 seconds.
            this.connectivityChangeTimeout = setTimeout(() => {
                this.notifyConnectivityChange();
            }, 5000);
        });
        window.addEventListener("offline", () => {
            clearTimeout(this.connectivityChangeTimeout);
            if (this.currentConnectivity == this.getConnectionType()) {
                return;
            }
            this.currentConnectivity = Connection.NONE;
            this.notifyConnectivityChange();
        }, false);
    }

    /**
     * Init orientation change handler to notify observer.
     */
    initOrientationChangeHandler(): void {
        this.currentOrientation = this.getOrientationType();
        window.addEventListener("orientationchange", () => {
            this.currentOrientation = this.getOrientationType();
            this.notifyOrientationChange();
        }, false);
    }

    // region Client detection

    getClientType(): string {
        return this.isDesktop() ? "desktop_app" : this.isMobile() ? this.isMobileBrowser() ? "mobile_app" : "mobile" : "web";
    }

    /**
     * Are we running on a touch device
     *
     * @return - true if true we run on a touch device otherwise false
     */
    checkIsTouchDevice(): boolean {
        const browserMeta: BrowserDetectInfo = BrowserDetect(window.navigator.userAgent);
        const hasTouch: boolean = (("ontouchstart" in window) || // html5 browsers
            // (window.navigator.maxTouchPoints > 0) || // future IE --> commented out because it made chrome browser think that it's a touch device
            (window.navigator.msMaxTouchPoints > 0) ||
            window.matchMedia("(pointer: coarse)").matches); // https://developer.mozilla.org/de/docs/Web/CSS/@media/pointer

        // TODO isLocalClient() || (isTouchMonitor && touchActivatedInProfileSettings) Refactoring
        return this.isMobile() || hasTouch || browserMeta.mobile;
    }

    /**
     * Are we running on a phone device
     *
     * @return - true if true we run on a phone device otherwise false
     */
    checkIsPhone(): boolean {
        const isPhonewidth: boolean = (
            (window.matchMedia("(min-width:220px) and (max-width: 420px) and (orientation: portrait)")).matches ||
            (window.matchMedia("(min-height:220px) and (max-height: 420px) and (orientation: landscape)")).matches
        );
        return this.isTouchDevice() && isPhonewidth;
    }

    /**
     * Forces phone layout (when users zooms browser window)
     */
    forcePhoneLayout(state: boolean): void {
        this.gIsForcedPhone = state;
    }

    /**
     * Checks if isPhone property got activated from zooming in (on a desktop client not on a mobile device)
     *
     * @returns - True if we run on a desktop with zoomed in layout
     */
    isForcedPhoneLayout(): boolean {
        return this.gIsForcedPhone;
    }

    /**
     * Checks if the client is running in a phone App or in a brwoser on a phone
     *
     * @access public
     * @returns - True if we run on a phone, otherwise false
     */
    isPhone(): boolean {
        return this.gIsPhone || this.gIsForcedPhone;
    }

    /**
     * Determine if the current device is a touch device.
     *
     * @access public
     * @return - True if it is a touch device, otherwise false.
     */
    isTouchDevice(): boolean {
        return this.gIsTouchDevice;
    }

    /**
     * Checks if this device is a phone or tablet
     *
     * @access public
     * @return - True if it is, otherwise false
     */
    isPhoneOrTablet(): boolean {
        return this.isiOsMobile() || this.isAndroid() || this.isPhone() || this.isMobile();
    }

    /**
     * Check if we are running on an Apple mac book, iPad, iPhone or iPod
     *
     * @access public
     * @returns- True if we run on such a device, otherwise false
     */
    isiOs(): boolean {
        return this.gIsIOS;
    }

    /**
     * Check if we are running on an Apple iPad, iPhone or iPod
     *
     * @access public
     * @returns {boolean}- True if we run on such a device, otherwise false
     */
    isiOsMobile(): boolean {
        return this.gIsIOSMobile;
    }

    /**
     * Check if we are running on Safari
     *
     * @access public
     * @returns - True if we a running in a safari browser
     */
    isSafariBrowser(): boolean {
        const browserMeta: any = BrowserDetect();
        return browserMeta.name.toLowerCase().indexOf("safari") != -1;
        // Safari 3.0+ "[object HTMLElementConstructor]"
        // let isSafari = /constructor/i.test(window.HTMLElement) || (function(p) {
        //     return p.toString() === "[object SafariRemoteNotification]";
        // })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification));
    }

    /**
     * Checks if we are running on an android device
     *
     * @access public
     * @returns - True if we a running on an android device
     */
    isAndroid(): boolean {
        return this.gIsAndroid;
    }

    /**
     * Checks if we are running on an windows device
     *
     * @access public
     * @returns - True if we a running on an android device
     */
    isWindows(): boolean {
        // return /win32/.test(require("os").platform());
        return this.gIsWindows;
    }

    /**
     * Determine if the webclient runs on electron or cordova.
     *
     * @access public
     * @return - True if the webclient runs on electron or cordova, otherwise false.
     */
    isLocalClient(): boolean {
        return (window.electron != void 0 || window.cordova != void 0);
    }

    /**
     * Determine if the webclient runs on a mobile device.
     *
     * @access public
     * @return - True if the webclient runs on a mobile device, otherwise false.
     */
    isMobileBrowser(): boolean {
        const browserMeta: BrowserDetectInfo = BrowserDetect();
        return browserMeta.mobile;
    }

    /**
     * Determine if the webclient runs on cordova.
     *
     * @access public
     * @return - True if the webclient runs on cordova, otherwise false.
     */
    isMobile(): boolean {
        return !!window.cordova;
    }

    /**
     * Determine if the webclient runs on electron.
     *
     * @access public
     * @return - True if the webclient runs on electron, otherwise false.
     */
    isDesktop(): boolean {
        return (window.electron != void 0);
    }

    isDetached(): boolean {
        return /#\/detachedViewer/gi.test(location.href);
    }

    /**
     * Determine if the webclient is currently online.
     *
     * @access public
     * @return - True if the webclient is currently online, otherwise false.
     */
    isOnline(): boolean {
        return navigator.onLine;
    }

    /**
     * Determine if the webclient is currently offline.
     *
     * @access public
     * @return - True if the webclient is currently offline, otherwise false.
     */
    isOffline(): boolean {
        return !this.isOnline();
    }

    // endregion
    // region Orientation Change

    /**
     * With this method one can register as a observer to get notified when the orientation of mobile device changes.
     *
     * @access public
     * @param handler - Callbackfunction which should be called in case of an connectivity change.
     */
    registerOrientationChangeHandler(handler: any): void {
        this.unregisterOrientationChangeHandler(handler);
        this.orientationChangeHandlers.push(handler);
    }

    /**
     * With this method one can unregister as a observer to no longer get notified when the orientation of mobile device changes.
     *
     * @access public
     * @param handler - Callbackfunction which should be unregistered.
     */
    unregisterOrientationChangeHandler(handler: any): void {
        for (let i: number = this.orientationChangeHandlers.length - 1; i >= 0; i--) {
            if (this.orientationChangeHandlers[i] === handler) {
                this.orientationChangeHandlers.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Notifies all observers on orienatation change.
     *
     * @access private
     */
    notifyOrientationChange(): void {
        for (let i: number = this.orientationChangeHandlers.length - 1; i >= 0; i--) {
            this.orientationChangeHandlers[i](this.currentOrientation);
        }
    }

    /**
     * Determine the current orientation state.
     *
     * @access private
     * @return - One of the constants ClientService.(ORIENTATION.PORTRAIT|ORIENTATION.LANDSCAPE)
     */
    private getOrientationType(): Orientation {
        if (window.matchMedia("(orientation: portrait)").matches) {
            return Orientation.PORTRAIT;
        } else {
            return Orientation.LANDSCAPE;
        }
    }

    // endregion
    // region Connectivity Change

    /**
     * With this method one can register as a observer to get notified when the connectivity status changes.
     *
     * @access public
     * @param handler - Callbackfunction which should be called in case of an connectivity change.
     */
    registerConnectivityChangeHandler(handler: any): void {
        this.unregisterConnectivityChangeHandler(handler);
        this.connectivityChangeHandlers.push(handler);
    }

    /**
     * With this method one can unregister as a observer to no longer get notified when the connectivity status changes.
     *
     * @access public
     * @param handler - Callbackfunction which should be unregistered.
     */
    unregisterConnectivityChangeHandler(handler: any): void {
        for (let i: number = this.connectivityChangeHandlers.length - 1; i >= 0; i--) {
            if (this.connectivityChangeHandlers[i] === handler) {
                this.connectivityChangeHandlers.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Notifies all observers on connectivity change.
     *
     * @access private
     */
    notifyConnectivityChange(): void {
        this.messageService.broadcast(Broadcasts.CONNECTIVITY_CHANGED, this.currentConnectivity);
        for (let i: number = this.connectivityChangeHandlers.length - 1; i >= 0; i--) {
            this.connectivityChangeHandlers[i](this.currentConnectivity);
        }
    }

    /**
     * Determine the current connectivity state.
     *
     * @access private
     * @return - One of the constants ClientService.(CONNECTION_NONE|CONNECTION_MOBILE|CONNECTION_WIFI)
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
     * @Todo: For Electron may be use https://www.npmjs.com/package/network in the future?!? We currently have no type of connection
     * @Todo: and also we need to now if the connection is clocked like in the ice. This tells us there is only a limit bandwith available per day.
     */
    getConnectionType(): Connection {
        // Offline for electron
        if (window.navigator.onLine === false) {
            return Connection.NONE;
        }

        // Online for electron or a client which do not provide a connection.type
        if ((window.navigator as any).connection == void 0 || (window.navigator as any).connection.type == void 0) {
            return Connection.WIFI;
        }

        switch ((window.navigator as any).connection.type) {
            case "ethernet":
            case "wifi":
            case "wimax":
                return Connection.WIFI;
            case "none":
                return Connection.NONE;
            case "cellular":
            case "unknown":
            default:
                return Connection.MOBILE;
        }
    }

    /**
     * Returns platform name as a string.
     *
     * @returns {{platform: Platform}}
     */
    getPlatformNameAsString(): Platform {
        let platform: Platform;

        if (this.isMobile()) {
            if (this.isLocalClient()) {
                platform = "mobile_app";
            } else {
                platform = "mobile";
            }
        } else if (this.isDesktop()) {
            platform = "desktop_app";
        } else {
            platform = "web";
        }

        return platform;
    }

    // endregion
    // region Synchronization Change
    /**
     * With this method one can register as a observer to get notified when the synchronization status changes.
     *
     * @access public
     * @param handler - Callbackfunction which should be called in case of a synchronization change.
     */
    registerSynchronizationChangeHandler(handler: any): void {
        this.unregisterSynchronizationChangeHandler(handler);
        this.synchronizationChangeHandlers.push(handler);
    }

    /**
     * With this method one can unregister as a observer to no longer get notified when the synchronization status changes.
     *
     * @access public
     * @param handler - Callbackfunction which should be unregistered.
     */
    unregisterSynchronizationChangeHandler(handler: any): void {
        for (let i: number = this.synchronizationChangeHandlers.length - 1; i >= 0; i--) {
            if (this.synchronizationChangeHandlers[i] === handler) {
                this.synchronizationChangeHandlers.splice(i, 1);
                break;
            }
        }
    }

    /**
     * Notifies all observers on a synchronization change.
     *
     * @access public
     */
    protected notifySynchronizationChange(isSynchronizing: boolean): void {
        for (let i: number = this.synchronizationChangeHandlers.length - 1; i >= 0; i--) {
            this.synchronizationChangeHandlers[i](isSynchronizing);
        }
    }

    // endregion
    // region Open/Save Files

    /**
     * Opens the passed file content using the means the current OS provides
     *
     * @param dmsDocument
     * @param fileName
     * @param fileContent
     * @param save - whether the file should be saved rather than opened
     */
    async openFileDataInDefaultAppAsync(dmsDocument: DmsDocument, fileName: string,
                                        fileContent: any, save: boolean): Promise<string | Error | void> {
        const modalDialogService: any = this.$injector.get("modalDialogService");

        if (!this.isMobile() || !this.isPhoneOrTablet()) {
            modalDialogService.hideProgressDialog();
        }

        return this.platformOpenFileDataInDefaultAppAsync(dmsDocument, fileName, fileContent, save);
    }

    /**
     * Show a saveAs Dialog or delegate this to the mobile os.
     *
     * @access public
     * @param filename - The filename with extension in which the content should be saved.
     * @param fileContent - The data of the file
     * @throws - A backend error.
     */
    async saveAsAsync(filename: string, fileContent: ArrayBuffer | Blob): Promise<Error | void> {
        // Make sure we don't get an overly long filename
        filename = ToolService.nameToFilename(filename);

        try {
            await this.platformSaveAsAsync(filename, fileContent);
        } catch (error) {
            const errorModelService: ErrorModelService = this.$injector.get("errorModelService");

            if (!error) {
                throw errorModelService.createCustomError("WEB_UNKNOWN_APP");
            }
            if (error.status == "9") {
                throw errorModelService.createCustomError("WEB_UNKNOWN_APP");
            } else if (error.type == void 0) {
                throw errorModelService.createCustomError("WEB_EXPORT_FAILED");
            }

            throw error;
        } finally {
            const modalDialogService: any = this.$injector.get("modalDialogService");

            if (!this.isMobile() || !this.isPhoneOrTablet()) {
                modalDialogService.hideProgressDialog();
            }
        }
    }

    // endregion
    // region logout

    /**
     * Logout
     */
    async logoutAsync(force?: boolean): Promise<void> {
        if (!force) {
            const allowLogout: boolean = await this.checkForCheckedOutFilesAsync();
            if (!allowLogout && this.isDesktop()) {
                return;
            }
        }

        // Can't be injected in the constructor and we currently only need it here just before logout.
        const environmentService: TodoEnvironmentService = this.$injector.get("environmentService");
        this.isLoggingOut = true;

        if (this.isOnline() && !environmentService.isMicroserviceBackend()) {
            const backendService: any = this.$injector.get("backendService");

            try {
                // trigger webclient backend cleanup and invalidate techical backend session
                await backendService.get("/_secure/logout/");
            } catch (err) {
                // errors can be ignored
            }
            try {
                // trigger webclient backend cleanup and invalidate techical backend session
                await backendService.get("/logout.do", this.$eobConfig.getOswebBase());
            } catch (err) {
                // errors can be ignored
            }
        }

        localStorage.removeItem("clipboard");

        await this.authenticationService.invalidateSession().toPromise();
        await this.platformLogoutAsync();
    }

    // endregion

    /**
     * Sets a random session id to decide whether the cached session data is for the current session
     * Also, the session and tab id are used to define the webclient instance in log messages
     */
    setRandomIds(): void {
        this.randomSessionId = sessionStorage.getItem("randomSessionId");

        if (!this.randomSessionId) {
            sessionStorage.setItem("randomSessionId", Math.random().toString(16).substr(2, 8));
            this.randomSessionId = sessionStorage.getItem("randomSessionId");
        }

        if (!this.randomTabId) {
            this.randomTabId = Math.random().toString(16).substr(2, 8);
        }
    }

    /**
     * Gets the random session and tab id used to tell sessions and tabs apart
     *
     * @returns {{randomSessionId: string, randomTabId: string}}
     */
    getRandomIds(): { randomSessionId: string | null; randomTabId: string | null } {
        return {randomSessionId: this.randomSessionId, randomTabId: this.randomTabId};
    }

    /**
     * Convenience function to handle errors usually resulting in a back navigation.
     * If the client is offline, going back can trigger a page reload and result in a broken state, so the offlineObjects state is shown instead.
     */
    executeStateErrorFallback(): void {
        if (!this.isOnline() && this.isLocalClient()) {
            const stateId: number = +new Date();
            const nextStateContent: any = {
                config: {
                    executeSingleHitAction: false
                },
                type: "hitlist.offlineObjects",
                description: this.translateFn("eob.app.bar.favorites.title")
            };

            this.stateHistoryManager.setStateData(nextStateContent, stateId);
            this.$state.go("hitlist.offlineObjects", {state: stateId});
        } else {
            history.back();
        }
    }
}
