import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "SHARED_PATH/shared.module";
import {Type} from "@angular/core";
import {EobSearchSettingsComponent} from "MODULES_PATH/search/components/eob-search-settings/eob-search-settings.component";
import {EobSearchSettingsPinComponent} from "MODULES_PATH/search/components/eob-search-settings-pin/eob-search-settings-pin.component";
import {EobBaseparamSwitchComponent} from "MODULES_PATH/search/components/eob-baseparam-switch/eob-baseparam-switch.component";

const components: Array<Type<unknown>> = [
    EobSearchSettingsComponent,
    EobSearchSettingsPinComponent,
    EobBaseparamSwitchComponent
];

@NgModule({
    declarations: [...components],
    exports: [...components],
    imports: [
        CommonModule, SharedModule
    ],
    entryComponents: [...components]
})
export class SearchModule {
}
