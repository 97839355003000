<div>
    <div #focusBubble role="alert" class="popover focus-bubble">
        <span #focusBubbleTitle class="popover-title"></span>
        <div #focusBubbleMessage class="popover-content"></div>
    </div>
    <div #hoverBubble role="alert" class="popover hover-bubble">
        <span #hoverBubbleTitle class="popover-title"></span>
        <div #hoverBubbleMessage class="popover-content"></div>
    </div>
</div>

