import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {take} from "rxjs/operators";
import {IconCellComponent} from "../../../../app/modules/grid/components/grid-cells/icon-cell/icon-cell.component";

(function() {
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.backend.srv.js");

    angular.module("eob.framework").directive("eobUsermanagement", EobUsermanagement);

    EobUsermanagement.$inject = ["$timeout", "$rootScope", "$compile", "$q", "$filter", "backendService", "$state", "notificationsService", "organisationService", "modalDialogInjectorService"];

    // eslint-disable-next-line max-params
    function EobUsermanagement($timeout, $rootScope, $compile, $q, $filter, BackendService, $state, NotificationsService, OrganisationService, ModalDialogInjectorService) {
        return {
            restrict: "E",
            link(scope) {
                scope.ready = false;
                scope.showUserList = true;
                scope.hitlistConfig;

                init();

                scope.addUser = function() {
                    $state.go("userupsert", { mode: "create", state: Date.now() });
                };

                scope.back = function() {
                    setTimeout(() => {
                        history.back()
                    }, 0)
                };

                function init() {
                    let users = OrganisationService.getUserList();

                    users.sort(compareByName);

                    let colDefs = buildUserColDefs();
                    let rows = buildUserRows(users);

                    scope.hitlistConfig = {
                        columns: colDefs,
                        rows,
                        filter: "",
                        suppressMultiselect: true,
                        onDoubleClick: editUser,
                        frameworkComponents: {
                            iconCellRenderer: IconCellComponent
                        }
                    };

                    // the state would be initialized too fast, and the state components would not be ready at this point
                    // this might lead to unexpected behaviour of the state layout, therefore a timeout
                    $timeout(() => {
                        scope.ready = true;
                    }, 250)
                }

                function editUser(params) {
                    $state.go("userupsert", { mode: "edit", user: params.data.id, state: Date.now() });
                }

                function deleteUser(params) {
                    let modalScope = $rootScope.$new();

                    let userName = params.node.data.userName.value == "" ? params.node.data.loginName.value : params.node.data.userName.value;
                    let userId = params.node.data.id;

                    modalScope.msg = $filter("translate")("eob.modal.delete.user.message").replace("{user}", userName);
                    modalScope.buttons = {
                        cancel: $filter("translate")("modal.button.cancel"),
                        submit: $filter("translate")("modal.button.delete")
                    };

                    const modalContainer$ = ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                        input: {
                            title: $filter("translate")("eob.modal.delete.user.title"),
                            rejection: true
                        },
                        childElement: angular.element("<eob-modal-confirm msg='msg' buttons='buttons'></eob-modal-confirm>"),
                        scope: modalScope
                    })

                    modalContainer$.pipe(take(1)).subscribe(_ => {
                        BackendService.get(`/organization/user/delete/${userId}`).then(() => {
                            params.api.removeItems([params.node]);
                        }, (error) => {
                            NotificationsService.backendError(error, "eob.user.delete.error");
                        })
                    }, error => console.warn(error));

                    scope.$apply()
                }

                function buildUserColDefs() {
                    return [{
                        field: "loginName",
                        headerName: $filter("translate")("eob.user.loginname"),
                        isIconCell: false
                    }, {
                        field: "userName",
                        headerName: $filter("translate")("eob.user.username"),
                        isIconCell: false
                    }, {
                        field: "groups",
                        headerName: $filter("translate")("eob.user.groups"),
                        isIconCell: false
                    }, {
                        field: "edit",
                        headerName: $filter("translate")("eob.user.edit"),
                        headerTooltip: $filter("translate")("eob.user.edit"),
                        tooltipField: "edit.headerName",
                        isIconCell: true,
                        resizable: false,
                        sortable: false,
                        suppressMovable: true,
                        suppressSizeToFit: false,
                        width: 80,
                        cellStyle: { "cursor": "pointer" },
                        onCellClicked: editUser,
                        cellRenderer: "iconCellRenderer"
                    }, {
                        field: "delete",
                        headerName: $filter("translate")("eob.user.delete"),
                        headerTooltip: $filter("translate")("eob.user.delete"),
                        tooltipField: "delete.headerName",
                        isIconCell: true,
                        resizable: false,
                        sortable: false,
                        suppressMovable: true,
                        suppressSizeToFit: false,
                        width: 80,
                        cellStyle: { "cursor": "pointer" },
                        onCellClicked: deleteUser,
                        cellRenderer: "iconCellRenderer"
                    }
                    ];
                }

                function buildUserRows(users) {
                    let rows = [];

                    for (let i in users) {
                        let user = users[i];

                        rows.push({
                            "loginName": {
                                value: user.name,
                                headerName: $filter("translate")("eob.user.loginname")
                            },
                            "userName": {
                                value: user.fullname,
                                headerName: $filter("translate")("eob.user.username")
                            },
                            "groups": {
                                value: user.groups.join(", "),
                                headerName: $filter("translate")("eob.user.groups")
                            },
                            "edit": {
                                headerName: $filter("translate")("eob.user.edit"),
                                value: {
                                    icon: "user-edit"
                                }
                            },
                            "delete": {
                                headerName: $filter("translate")("eob.user.delete"),
                                value: {
                                    icon: "user-delete"
                                }
                            },
                            "id": user.id,
                            "context": "usermanagement"
                        })
                    }

                    return rows;
                }

                function compareByName(a, b) {
                    if (a.name < b.name) {
                        return -1;
                    } else if (a.name > b.name) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        }
    }
})();
