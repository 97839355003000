import {ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Inject, ComponentFactory, EventEmitter, Type} from "@angular/core";
import {Subject} from "rxjs";
import {ComponentInjectorDTO, AJSElementInjectorDTO, ModalContainerInput} from "MODULES_PATH/modal-dialog/interfaces/modal-container.interface";
import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {TodoScope} from "INTERFACES_PATH/any.types";

// until the js dialogs are refactored, we either need ComponentRef or js scopes
type DestroyReference = ComponentRef<unknown>|TodoScope;

@Injectable({
    providedIn: "root"
})
export class ModalDialogInjectorService {
    componentRefs: DestroyReference[][] = [];

    // eslint-disable-next-line max-params
    constructor(
        @Inject("$compile") private $compile: ng.ICompileService,
        private injector: Injector,
        private appRef: ApplicationRef,
        private resolver: ComponentFactoryResolver
    ) {}

    createDialog(containerInput: ModalContainerInput, childDto: ComponentInjectorDTO): Subject<unknown> {
        const componentRefs: DestroyReference[] = this.createComponent({ component: EobModalContainerComponent, input: containerInput}, childDto);
        return this.addModalDialog(componentRefs);
    }

    /**
     * deprecated - Only use for angular js dialogs.
     */
    createDialogAJS(component: Type<EobModalContainerComponent>, dto: AJSElementInjectorDTO): Subject<unknown> {
        const compiled: JQLite = this.$compile(dto.childElement)(dto.scope);

        const componentRef: ComponentRef<EobModalContainerComponent> = this.compileComponent(component, { input: dto.input, destroy: dto.scope?.destroy }, dto.output, compiled[0])[0];

        return this.addModalDialog([componentRef, dto.scope]);
    }

    private createComponent(containerDto: ComponentInjectorDTO, childDto: ComponentInjectorDTO): DestroyReference[] {
        const childRef: ComponentRef<unknown> = this.compileComponent<unknown>(childDto.component, childDto.input, childDto.output)[0];

        return this.compileComponent<EobModalContainerComponent>(
            containerDto.component as Type<EobModalContainerComponent>, { input: containerDto.input }, containerDto.output, childRef);
    }

    private addModalDialog(componentRefs: DestroyReference[]): Subject<unknown> {
        const componentRef: ComponentRef<EobModalContainerComponent> = componentRefs[0];
        const compInstance: EobModalContainerComponent = componentRef.instance;

        if (compInstance.destroy) {
            compInstance.childDestroy$ = compInstance.destroy;
        }

        if (!compInstance.isPermanent) {
            if (this.componentRefs.length) {
                this.componentRefs[this.componentRefs.length - 1][0].instance.destroy$.unsubscribe();
            }

            this.componentRefs.push(componentRefs);
        }

        const domElem: HTMLElement = (componentRef.hostView as EmbeddedViewRef<EobModalContainerComponent>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        return componentRef.instance.output;
    }

    /**
     * Resolve the ng-content transcludes from an element, unmatched nodes are added to the wildcard.
     * Unfortantely angular doesn't do that itself when using the ComponentFactory.
     */
    private getNgContentNodes(element: Element, ngSelectors: string[]): Node[][] {
        const wildcardElements: Node[] = [element];

        return ngSelectors.map(selector => {
            if (selector === "*") {
                return wildcardElements;
            }

            const els: NodeListOf<Element> = element.querySelectorAll(selector);
            els.forEach(e => e.remove());
            return Array.from(els);
        });
    }

    private compileComponent<T>(component: Type<T>, input?: unknown, output?: Record<string, unknown>, childRef?: ComponentRef<unknown>|Element): DestroyReference[] {
        const factory: ComponentFactory<T> = this.resolver.resolveComponentFactory(component);

        let childElement: Element;
        if (childRef instanceof ComponentRef) {
            childElement = (childRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0];
        } else {
            childElement = childRef;
        }

        const ngContentNodes: Node[][] = childElement ? this.getNgContentNodes(childElement, factory.ngContentSelectors) : [];
        const componentRef: ComponentRef<T> = factory.create(this.injector, ngContentNodes);
        this.appRef.attachView(componentRef.hostView);

        // add the input values to the component
        const compInstance: T = componentRef.instance;
        if (input) {
            for (const [key, value] of Object.entries(input)) {
                compInstance[key] = value;
            }
        }

        // register callbacks to event emitter of the component
        if (output) {
            for (const [key, cb] of Object.entries(output)) {
                if (cb && compInstance[key] && compInstance[key] instanceof EventEmitter) {
                    compInstance[key].subscribe(cb);
                }
            }
        }

        componentRef.changeDetectorRef.detectChanges();

        return [componentRef, childRef];
    }

    destroy(): void {
        if (!this.componentRefs.length) {
            return;
        }

        const components: DestroyReference[] = this.componentRefs[this.componentRefs.length - 1];
        const component: ComponentRef<EobModalContainerComponent> = components[0];

        this.appRef.detachView(component.hostView);
        component.instance.destroySubscriptions();

        components.forEach(comp => {
            if (comp instanceof ComponentRef) {
                comp.destroy();
            } else {
                comp.$destroy();
            }
        });

        this.componentRefs.pop();

        if (this.componentRefs.length) {
            this.componentRefs[this.componentRefs.length - 1][0].instance.initSubscriptionForDestroy();
        }
    }
}
