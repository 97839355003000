import {InjectionToken, StaticProvider} from "@angular/core";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NavigatorToken: InjectionToken<string> = new InjectionToken("navigator-token");
export const WindowToken: InjectionToken<string> = new InjectionToken("window-token");

export const windowProviders: StaticProvider[] = [
    {
        provide: NavigatorToken,
        useValue: navigator
    }, {
        provide: WindowToken,
        useValue: window
    }
];