import {ERROR_CODES} from "SHARED_PATH/models/error-model.config";

import * as dayjs from "dayjs";

const DEFAULT_ERROR: any = ERROR_CODES.WEB_ERROR_UNDEFINED;

/**
 * Builds a custom webclient error object from scratch
 * and replaces placeholder with given replacements.
 *
 * @param {string} type - A textual ID for the error.
 * @param {int} status - A representativ of html status codes.
 * @param {string} msgKey - A translation key for the error message.
 * @param {string[]} replacements - An array of placehodler replacements.
 */
export class CustomError {
    private type: string;
    private statusCode: number;
    private time: string;
    private readonly message: string[];

    constructor(type: string, status: number, message: string[]) {
        this.type = type || DEFAULT_ERROR.type;
        this.statusCode = status;
        this.time = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        this.message = message;
    }

    toString = (): string => this.message[0];
}
