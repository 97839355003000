/**
 * A script object.
 */
export class Script {
    model: ScriptModel;

    /**
     * Create a new Script instance.
     *
     * @param {object} backendData - The osrest backend representation of a script object.
     * @constructor
     */
    constructor(backendData: any) {
        this.model = new ScriptModel(backendData);
    }
}

/**
 * The model object of a script.
 */
class ScriptModel {
    id: number;
    eventType: string;
    script: string;
    scriptLanguage: string;
    objectTypeId: number;
    nameKey: string;

    /**
     * Create a new Script model instance.
     *
     * @param {object} backendData - The osrest backend representation of a script object.
     * @constructor
     */
    constructor(backendData: any) {
        this.id = backendData.id;
        this.eventType = backendData.eventType;
        this.script = backendData.script;
        this.objectTypeId = backendData.objectTypeId;
        this.scriptLanguage = backendData.scriptLanguage;

        this.nameKey = `${this.eventType.toLowerCase()}${this.objectTypeId?this.objectTypeId:""}`;
    }
}
