import {Inject, Injectable} from "@angular/core";
import * as angular from "angular";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {EmsStatusCodes} from "ENUMS_PATH/ems.enum";
import {LoggingService} from "CORE_PATH/services/logging/logging.service";

interface IdProperty {
    value: string;
}

interface Options {
    "ems:target:enaio:objectTypeId": IdProperty;
    "ems:parent:enaio:objectId": IdProperty;
    "ems:parent:enaio:objectTypeId": IdProperty;
}

interface ContentStream {
    mimetype: string;
    fileName: string;
    cid: string;
}

interface EmsObject {
    properties?: string;
    options: Options;
    contentStreams: ContentStream[];
}

interface EmsPayload {
    objects: EmsObject[];
}

interface NumberProperty {
    value: number;
}

interface Properties {
    "system:objectId": NumberProperty;
    "storeId": IdProperty;
    "entryId": IdProperty;
    "statusCode": NumberProperty;
}

interface EmsReponseObject {
    properties: Properties;
}

interface EmsResponseObjects {
    objects: EmsReponseObject[];
}

@Injectable({
    providedIn: "root"
})
/**
 * Provides functionality to talk to the EMS.
 */
export class EmsService {
    constructor(@Inject("fileCacheService") protected fileCacheService: FileCacheService,
                @Inject("backendService") protected backendService: any,
                protected loggingService: LoggingService) {
    }

    /**
     * Gets the email content from the IndexDB and sends it along with meta data to the EMS for storing.
     *
     * @param {any} metadata - Contains meta data for actual document.
     * @param {string} filename - The original file name of that email.
     * @param {any} object - The object containing the input data.
     * @param {any} form - The form containing fields, etc. (optional)
     * @param {string} deduplicationHandling - handling for deduplication responses ("LINK", "COPY" or "INSERT") (optional)
     * @return {number} objectId - The ID of the created email document.
     */
    async storeEmailAsync(metadata: any, filename: string, object: any, form?: any, deduplicationHandling?: string): Promise<EmsReponseObject> {
        const fd: FormData = new FormData();
        let mimeType = "message/rfc822";
        if (filename.toLowerCase().endsWith(".msg")) {
            mimeType = "application/vnd.ms-outlook";
        }

        const emsPayload: EmsPayload = {
            objects: [{
                properties: (form || {}).fields,
                options: {
                    "ems:target:enaio:objectTypeId": {
                        value: `${metadata.objectTypeId}`
                    },
                    "ems:parent:enaio:objectId": {
                        value: `${metadata.parentObjectId}`
                    },
                    "ems:parent:enaio:objectTypeId": {
                        value: `${metadata.parentObjectTypeId}`
                    }
                },
                contentStreams: [{
                    mimetype: mimeType,
                    fileName: filename,
                    cid: "cid_ems_store"
                }]
            }]
        };

        if (deduplicationHandling?.length > 0) {
            emsPayload.objects[0].options["ems:deduplication:handling"] = { value: deduplicationHandling };
            emsPayload.objects[0].options["ems:deduplication:resolving"] = { value: "LAST" };
        }

        const indexdataBlob: Blob = new Blob([JSON.stringify(emsPayload)], { type: "application/json" });
        fd.append("data", indexdataBlob, "data");

        const file: any = await this.fileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, filename, {
            group: metadata.groupKey,
            first: true
        });

        const contentBlob: any = new Blob([file], { type: mimeType });
        contentBlob.name = "cid_ems_store";

        fd.append("cid_ems_store", contentBlob, filename);

        const config: ng.IHttpRequestConfigHeaders = {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            transformRequest: angular.identity,
            headers: { "Content-Type": undefined }
        };

        const response: ng.IHttpResponse<EmsResponseObjects> = await this.backendService.post("/api/store", fd, config, "/ems");
        const objectId: number = response.data.objects[0].properties["enaio:objectId"].value;

        this.loggingService.info(`EMS stored email with object id: ${objectId}`);

        return this.buildEmsResponseObject(object, objectId, EmsStatusCodes.SUCCESSFUL_STORED);
    }

    buildEmsResponseObject(object: EmsReponseObject, objectId: number, statusCode: number): EmsReponseObject {
        return {
            properties: {
                "system:objectId": {
                    value: objectId
                },
                "storeId": {
                    value: object.properties.storeId.value
                },
                "entryId": {
                    value: object.properties.entryId.value
                },
                "statusCode": {
                    value: statusCode
                }
            }
        };
    }
}
