import {Injectable} from "@angular/core";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";
import {HttpClient} from "@angular/common/http";
import {BackendVariantObject} from "CORE_PATH/backend/interfaces/backend-variant-object.interface";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

/**
 * Internal interface. Do not use it. It's only exported for Unit-Tests.
 */
export interface PrivateOsRestVariantObject {
    id: string;
    originId: string;
    version: string;
    active: boolean;
    children: PrivateOsRestVariantObject[];
    ecmObject: BackendObject;
}

@Injectable({providedIn: BackendOsRestModule})
export class OsRestVariantsService {

    constructor(private httpClient: HttpClient) {
    }

    loadVariants(osId: string, objectTypeId: string) : Observable<BackendVariantObject[]> {
        let typeQueryString: string = (objectTypeId == null) ? "" : `?objectTypeId=${objectTypeId}`;
        typeQueryString += (typeQueryString == "") ? "?verbose=true&refresh=true" : "&verbose=true&refresh=true";

        return this.httpClient.get<PrivateOsRestVariantObject>(`/osrest/api/documents/variants/${osId}${typeQueryString}`).pipe(
            map(result => this.convertOsRestVariantTree([result]))
        );

    }

    activateVariant(currentActiveOsId: string, newActivOsId: string): Observable<void> {
        let uri: string = `/osrest/api/documents/variants/setactive/${newActivOsId}`;

        if (currentActiveOsId != undefined) {
            uri += `?activevariant=${currentActiveOsId}`;
        }

        return this.httpClient.get<void>(uri);
    }

    createVariant(osId: string, objectTypeId: string, type: string): Observable<string> {
        return this.httpClient.get<PrivateOsRestVariantObject>(`/osrest/api/documents/variants/create/${osId}?type=${type}&objecttypeid=${objectTypeId}`).pipe(
            map(response => response.id)
        );
    }

    private convertOsRestVariantTree(osRestResult: PrivateOsRestVariantObject[]) : BackendVariantObject[] {
        const result: BackendVariantObject[] = [];

        for (const variant of osRestResult) {
            result.push({
                ...variant.ecmObject,
                variantInfo: {
                    active: variant.active,
                    id: variant.id,
                    originId: variant.originId,
                    version: variant.version
                }
            });

            if (variant.children != undefined) {
                result.push(...this.convertOsRestVariantTree(variant.children));
            }
        }

        return result;
    }
}
