import {Inject, Injectable} from "@angular/core";
import {TreeConfig, TreeNode} from "INTERFACES_PATH/tree.interface";
import {FieldAddon} from "ENUMS_PATH/field.enum";
import {FormCatalogEntry} from "../interfaces/form.interface";

@Injectable({providedIn: "root"})
export class NewTreeAddonService {
    constructor(@Inject("treeAddonService") protected treeAddonService: any) {
    }

    /**
     * Searches for selected tree nodes inside all configurated tree addon nodes based on the addon field value
     *
     * @param value - value of the addon field
     * @param addonType - addon type e.g. "list" or "tree"
     * @param treeNodes - all configurated addon nodes
     * @param treeConfig - addon config
     * @param isSearch - true if search mask is displayed
     * @returns {TreeNode[]} Selected tree nodes
     */
    // eslint-disable-next-line max-params
    getTreeAddonSelectedItems(value: string, addonType: string, treeNodes: TreeNode[], treeConfig: TreeConfig, isSearch: boolean): TreeNode[] {
        const selectedItems: TreeNode[] = [];

        if (treeNodes.length > 0) {
            for (const node of treeNodes) {
                // if the catalog type is tree or hierarchy, check further nodes
                if (node.nodes?.length > 0) {
                    const nodesToCheck: TreeNode[] = node.nodes;

                    // in hierarchy catalohs the parent node can be selected too so we need to check it to
                    if (addonType === FieldAddon.HIERARCHY) {
                        const {nodes, ...parentNode} = node;
                        nodesToCheck.push(parentNode);
                    }

                    const items: TreeNode[] = this.getTreeAddonSelectedItems(value, addonType, nodesToCheck, treeConfig, isSearch);

                    items.forEach(n => {
                        if (n !== void 0 && !selectedItems.find(x => x.value === n.value)) {
                            selectedItems.push(n);
                        }
                    });

                    if (selectedItems.length > 0 && !treeConfig.useMultiSelect) {
                        break;
                    }
                } else if (treeConfig.useMultiSelect && addonType !== FieldAddon.HIERARCHY) {
                    const searchOperator: string = value.lastIndexOf(treeConfig.multiselectionSeparator) != -1 ? treeConfig.multiselectionSeparator : value.lastIndexOf("+") != -1 ? "+" : ";";
                    const searchStrings: string[] = value.split(searchOperator).filter(v => v && v.trim().length > 0);

                    searchStrings.forEach(searchString => {
                        this.findSelectedTreeNode(searchString, treeNodes, selectedItems, treeConfig, isSearch);
                    });
                } else {
                    if (addonType === FieldAddon.HIERARCHY) {
                        const hierarchicValues: string[] = value.split(treeConfig.separator);
                        value = hierarchicValues[hierarchicValues.length - 1];
                    }

                    this.findSelectedTreeNode(value, treeNodes, selectedItems, treeConfig, isSearch);
                }
            }
        }

        return selectedItems;
    }

    // eslint-disable-next-line max-params
    private findSelectedTreeNode(value: string, nodes: TreeNode[], selectedItems: TreeNode[], treeConfig: TreeConfig, isSearch: boolean): void {
        const unmutedNodes: FormCatalogEntry[] = JSON.parse(JSON.stringify(nodes));
        this.treeAddonService.removeDeprecatedStarEntries(unmutedNodes, isSearch, treeConfig.intermediate || treeConfig.useIntermediateNodes);

        const listElement: TreeNode = unmutedNodes.find(x => (x.value).toLowerCase() == value.toLowerCase() || (x.short).toLowerCase() == value.toLowerCase());

        if (listElement !== void 0 && !selectedItems.find(x => x.value === listElement.value)) {
            selectedItems.push(listElement);
        }
    }
}