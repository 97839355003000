import {Injectable, Inject} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ReplaySubject, Subject} from "rxjs";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {FileOpenService} from "SERVICES_PATH/mobile-desktop/eob.file.open.srv";
import {CordovaFilesystemUtil} from "SERVICES_PATH/mobile-desktop/cordova.filesystem.util";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import { first } from "rxjs/operators";
import {ExternalTrayEvents} from "MODULES_PATH/external-tray/enums/external-tray-events.enum";

/* eslint-disable @typescript-eslint/require-await */

// Commented out because of unused currently. Once this is true we remove it.
/*const map: WeakMap<any, any> = new WeakMap();

const internal: (a: any) => any = (object: any): any => {
    if (!map.has(object)) {
        map.set(object, {});
        setTimeout(() => {
            map.delete(object);
        }, 10000);
    }
    return map.get(object);
};*/

interface GetContentResult {
    filename: string;
    tempfilename: string;
}

/**
 * Client service implementation to be used inside a mobile app context
 */
@Injectable({
    providedIn: "root"
})
export class ClientCordovaService extends ClientService {
    private gStorage$: Subject<CustomStorage> = new ReplaySubject<CustomStorage>(1);

    constructor(@Inject("$injector") protected $injector: ng.auto.IInjectorService,
                @Inject("$eobConfig") protected $eobConfig: any,
                @Inject("$rootScope") protected $rootScope: RootScope,
                messageService: MessageService,
                protected errorModelService: ErrorModelService,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("fileOpenService") protected fileOpenService: FileOpenService,
                @Inject("mimeTypes") protected mimeTypes: any,
                @Inject("notificationsService") protected notificationsService: any,
                @Inject("$cordovaFile") protected $cordovaFile: any,
                protected authenticationService: AuthenticationService,
                protected customStorageService: CustomStorageService) {
        super($filter, $injector, $eobConfig, $rootScope, errorModelService, messageService, authenticationService, customStorageService);

        customStorageService.getStorage()?.subscribe(storage => {
            this.gStorage$.next(storage);
        });
    }

    /** @inheritDoc */
    init(): void {
        super.init();
        this.addFileOpenHandlers();
    }

    /**
     * Add support for open in enaio mobile to all file types.
     */
    addFileOpenHandlers(): void {
        if (window.plugins && window.plugins.intent) {
            const modalDialogService: any = this.$injector.get("modalDialogService");
            const handleIntent: any = async (intent: any): Promise<void> => {
                console.debug(intent);
                if (!this.$rootScope.webclientReady && sessionStorage.getItem("started")) {
                    console.debug("Trying to process intent before webclientReady, although the webclient" +
                        "was already running; intent will not be processed.");
                    return;
                }

                const fileMap: Map<string, ArrayBuffer> = new Map();

                const addFileToMap: (uri: string) => Promise<void> = async (uri: string): Promise<void> => {
                    const result: GetContentResult = await window.FilePath.getFileFromContentUri(uri);
                    if (!result.filename) {
                        result.filename = uri.split("/").pop() ;
                    }

                    try {
                        console.info(`Adding ${result.filename} to fileMap`);
                        let messageText: string = this.translateFn("modal.reading.file.x").replace("[%s1]", result.filename);
                        if (messageText == "modal.reading.file.x") {
                            messageText = result.filename;
                        }
                        modalDialogService.showProgressDialogWithCustomText(messageText);
                        fileMap.set(result.filename || `null ${+new Date()}`, await this.readDataFromFileAsync(`file://${result.tempfilename}`, false) as ArrayBuffer);
                    } catch (e) {
                        console.warn(e.message);
                        this.notificationsService.info(this.translateFn("modal.upload.file.too.big"));
                    }
                };

                if (intent.hasOwnProperty("data")) {
                    try {
                        await addFileToMap(intent.data);
                        window.handleOpenURL(fileMap);
                    } catch (error) {
                        console.warn(error);
                        this.notificationsService.error(this.translateFn("eob.open.file.failed"));
                    } finally {
                        modalDialogService.hideProgressDialog();
                    }
                } else if (/android.intent.action.SEND(_MULTIPLE)?/i.test(intent.action)) {
                    if (intent.hasOwnProperty("clipItems")) {
                        for (const clipItem of intent.clipItems) {
                            try {
                                await addFileToMap(clipItem.uri);
                            } catch (error) {
                                console.warn(error);
                                this.notificationsService.error(this.translateFn("eob.open.file.failed"));
                            } finally {
                                modalDialogService.hideProgressDialog();
                            }
                        }
                        console.info(fileMap);
                        window.handleOpenURL(fileMap);
                    } else {
                        const uris: string[] = intent.extras["android.intent.extra.STREAM"].match(/([\d\w/:]+)/gi);
                        for (const uri of uris) {
                            try {
                                await addFileToMap(uri);
                            } catch (error) {
                                console.warn(error);
                                this.notificationsService.error(this.translateFn("eob.open.file.failed"));
                            } finally {
                                modalDialogService.hideProgressDialog();
                            }

                        }
                        console.info(fileMap);
                        window.handleOpenURL(fileMap);
                    }
                }
            };

            window.plugins.intent.setDefaultIntentHandler = (): void => {
                window.plugins.intent.setNewIntentHandler(handleIntent);
            };

            // Handle the intent when the app is open
            // If the app is running in the background, this function
            // will handle the opened file
            window.plugins.intent.setDefaultIntentHandler();

            // Handle the intent when the app is not open
            // This will be executed only when the app starts or wasn't active
            // in the background
            window.plugins.intent.getCordovaIntent(handleIntent, () => {
                // TODO error handler
            });
        } else {
            console.warn("window.plugins.intent missing");
        }

        document.addEventListener("deviceready", (event: Event) => {
            if (!this.$rootScope.webclientReady) {
                const registerReadyEvent: any = this.$rootScope.$on("webclientReady", (_: ng.IAngularEvent) => {
                    window.handleOpenURL = async (fileMapOrUrl: Map<string, string | ArrayBuffer> | string): Promise<void> => {
                        if (typeof fileMapOrUrl == "string") {
                            const modalDialogService: any = this.$injector.get("modalDialogService");
                            const url: string = decodeURIComponent(fileMapOrUrl);
                            const map: Map<string, string | ArrayBuffer> = new Map<string, string | ArrayBuffer>();
                            try {
                                const filename: string = url.split("/").pop() ;
                                let messageText: string = this.translateFn("modal.reading.file.x").replace("[%s1]", filename);
                                if (messageText == "modal.reading.file.x") {
                                    messageText = filename;
                                }
                                modalDialogService.showProgressDialogWithCustomText(messageText);
                                map.set(url.split("/").pop() || "", await this.readDataFromFileAsync(url, false));
                            } catch (e) {
                                console.warn(e.message);
                                this.notificationsService.info(this.translateFn("modal.upload.file.too.big"));
                            } finally {
                                modalDialogService.hideProgressDialog();
                            }
                            this.fileOpenService.openFilesAsync(map);
                        } else if (fileMapOrUrl instanceof Map) {
                            console.info(fileMapOrUrl);
                            this.fileOpenService.openFilesAsync(fileMapOrUrl);
                        }
                    };

                    if (window.openedUrl) {
                        window.handleOpenURL(window.openedUrl);
                        delete window.openedUrl;
                    }

                    // deregister the event from above. We only need a one-time callback.
                    registerReadyEvent();
                });
            }
        }, false);
    }

    /** @inheritdoc */
    broadcastTrayItemsChanged(): void {
        this.messageService.broadcast(ExternalTrayEvents.TRAY_ELEMENTS_CHANGED);
    }

    /** @inheritdoc */
    setIsSynchronizing(isSynchronizing: boolean): void {
        if (isSynchronizing != this.isSynchronizing) {
            this.isSynchronizing = isSynchronizing;
            this.notifySynchronizationChange(isSynchronizing);
        }
    }

    /** @inheritDoc */
    getIsSynchronizing(): Promise<boolean> {
        return Promise.resolve(this.isSynchronizing);
    }

    /** @inheritDoc */
    setIsCacheLocked(isCacheLocked: boolean): void {
        if (this.isCacheLocked != isCacheLocked) {
            this.isCacheLocked = isCacheLocked;
            this.cacheLockedSubject.next(isCacheLocked);
        }
    }

    /** @inheritDoc */
    async getIsCacheLocked(): Promise<boolean> {
        return this.isCacheLocked;
    }

    /** @inheritDoc */
    setColorTheme(theme: string): void {
    }

    /** @inheritdoc */
    async getStorageAsync(): Promise<CustomStorage> {
        return this.gStorage$.pipe(first()).toPromise();
    }

    redirect(url: string): void {
        window.location.replace(url);
    }

    /** @inheritdoc */
    async platformLogoutAsync(): Promise<void> {
        this.notificationsService.goodbye(true);
        this.clearCookies();
        this.redirect("index.html");
    }

    /** @inheritdoc */
    clearCookies(): void {
        // Ensure alternating cookiemaster versions are handled gracefully
        Object.keys(window.cookieMaster).forEach((key: string): void => {
            if (key == "clear" || key == "clearCookies") {
                window.cookieMaster[key]();
            }
        });
    }

    /** @inheritDoc */
    async platformOpenFileDataInDefaultAppAsync(dmsDocument: any, fileName: string, fileContent: ArrayBuffer | Blob | Uint8Array, save?: boolean): Promise<string | Error | void> {
        console.info(`openFileDataInDefaultAppAsync with filename '${fileName}'`);
        const modalDialogService: any = this.$injector.get("modalDialogService");

        const step = 5242880;
        const filePrefix = `$$$${+new Date()}$$$`;
        let position = 0;
        let mimeType = "";

        if (fileContent instanceof Uint8Array || fileContent instanceof ArrayBuffer) {
            while (position < fileContent.byteLength) {
                modalDialogService.showProgressDialogWithCustomText(`${this.translateFn("eob.status.open.other.app")}\n${position ? `${(position / fileContent.byteLength * 100).toFixed(2)} %` : ""} `);
                const end: number | undefined = (position + step) < fileContent.byteLength ? (position + step) : void 0;
                await this.writeDataIntoTempFileAsync(`${filePrefix}#${position}#-${fileName}`, fileContent.slice(position, end));
                console.info((position / 1048576).toFixed(2));
                position += step;
            }
        } else if (fileContent instanceof Blob) {
            const fileReader: FileReader = new FileReader();

            if (fileContent.type !== "") {
                mimeType = fileContent.type;
            }

            while (position < fileContent.size) {
                modalDialogService.showProgressDialogWithCustomText(`${this.translateFn("eob.status.open.other.app")}\n${position ? `${(position / fileContent.size * 100).toFixed(2)} %` : ""} `);
                const read$$: Subject<void> = new Subject<void>();
                const end: number | undefined = (position + step) < fileContent.size ? (position + step) : void 0;

                fileReader.onloadend = async () => {
                    await this.writeDataIntoTempFileAsync(`${filePrefix}#${position}#-${fileName}`, fileReader.result as ArrayBuffer);

                    console.info((position / 1048576).toFixed(2));
                    position += step;

                    read$$.complete();
                };

                fileReader.readAsArrayBuffer(fileContent.slice(position, end));
                await read$$.toPromise();
            }
        }

        const completeFile: { path: string } = await window.cordova.plugins.workarounds.createFileFromChunks(filePrefix, fileName);
        modalDialogService.hideProgressDialog();
        const fileExt: string = fileName.split(".").pop() ;

        if (!mimeType) {
            mimeType = this.mimeTypes[fileExt];
        }

        if (mimeType == void 0) {
            throw this.errorModelService.createCustomError("WEB_UNKNOWN_MIMETYPE", fileExt);
        }

        // fileOpener2 does not manage to find the file via the cdv-path. Therefore we recreate the absolute link.
        const filePath = `${(window.cordova as any).file.cacheDirectory}${fileName}`;
        const splitFilename: string[] = fileName.split(".");
        splitFilename.pop();

        if (this.isAndroid() && save) {
            const filenameWithoutExt: string = splitFilename.join(".");
            const result: { message?: string; uri?: string } = await window.cordova.plugins.workarounds.createDocument(
                this.mimeTypes[fileExt] ? filenameWithoutExt : fileName,
                this.mimeTypes[fileExt] ? this.mimeTypes[fileExt] : "application/octet-stream",
                completeFile.path);

            if (result.uri) {
                return result.uri;
            } else {
                return new Error(result.message || JSON.stringify(result));
            }
        } else {
            return await new Promise((resolve: (a: string) => void, reject: (e: Error) => void): void => {
                window.cordova.plugins.fileOpener2.showOpenWithDialog(filePath, mimeType, {
                    success(): void {
                        resolve(completeFile.path);
                    },
                    error(error: Error): void {
                        reject(error);
                    }
                });
            }) as any;
        }
    }

    /** @inheritdoc */
    async platformSaveAsAsync(filename: string, fileContent: Uint8Array | ArrayBuffer | Blob): Promise<string> {
        await this.openFileDataInDefaultAppAsync(null, filename, fileContent, true);
        return filename;

    }

    /** @inheritdoc
     * @throws - error if file is larger than 20 MB
     */
    async readDataFromFileAsync(filePath: string, isText: boolean): Promise<string | ArrayBuffer> {
        const filename: string | undefined = filePath.split("/").pop();
        const path = `${filePath.split("/").slice(0, -1).join("/")}/`;
        const fileMetadata: Metadata = await CordovaFilesystemUtil.getMetadata(path, filename );
        if (fileMetadata.size > 1048576 * 20 /* 20 MiB */) {
            throw new Error(`File too big: ${fileMetadata.size / 1048576} MiB > 20 MiB`);
        }

        const content: string | ArrayBuffer = await CordovaFilesystemUtil.read(path, filename, isText);

        return content;

    }

    /** @inheritDoc */
    async correctFilePathAsync(filePath: string): Promise<string> {
        if (filePath.startsWith("content://")) {
            return new Promise((resolve: (a: string) => void, reject: (a: any) => void): void => {
                window.FilePath.resolveNativePath(filePath, result => {
                    resolve(result);
                }, error => {
                    reject(error);
                });
            });
        } else if (filePath.startsWith("/")) {
            return `file://${filePath}`;
        }

        return filePath;
    }

    /** @inheritdoc */
    refreshTabTitle(tabTitle: string): void {
    }

    /** @inheritdoc */
    async writeDataIntoTempFileAsync(filename: string, fileContent: ArrayBuffer): Promise<any> {
        const result: any = await this.$cordovaFile.writeFile((window.cordova as any).file.cacheDirectory, filename, fileContent, true);
        return result.target;
    }

    /** @inheritdoc */
    async checkForCheckedOutFilesAsync(): Promise<boolean> {
        return false;
    }

    /** @inheritdoc */
    async execProgramAsync(pathToProgram: string, programArguments: string, returnResult: boolean, workingDirectory?: string): Promise<string> {
        return "";
    }

    /** @inheritdoc */
    getMDMConfig(): any {
        return window.cordova.plugins.EmmAppConfig.getValue();
    }
}
