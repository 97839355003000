export enum StateEnum {
    INDEXDATA = "indexdata",
    LOCATION = "location",
    APP_ENTRY_STOREDQUERY_ID = "app.entry.storedquery.id",
    SEARCH = "search",
    APP_ENTRY_OSID = "app.entry.osid",
    APP_ENTRY_ASPATH = "app.entry.aspath",
    OSID = "osid",
    APP_ENTRY_WF_WORKITEM_ID = "app.entry.wf.workitem.id",
    WORKFLOW = "workflow",
    DOWNLOAD = "download",
    HITLIST = "hitlist",
    FULLTEXTSEARCH = "fulltextsearch",
    HITLIST_FAVORITES = "hitlist.favorites",
    HITLIST_HISTORY = "hitlist.history",
    HITLIST_OFFLINE_OBJECTS = "hitlist.offlineObjects"
}