angular.module("eob.core").factory("objectTypeModelService", ObjectTypeModelService);

ObjectTypeModelService.$inject = ["$injector", "formFieldModelService", "fieldsetBuilderService", "environmentService", "asIniService", "formLayoutService", "objectTypeService"];

/**
 * A service that creates a form pagecontrol element
 */
// eslint-disable-next-line max-params, require-jsdoc
export default function ObjectTypeModelService($injector, FormFieldModelService, FieldsetBuilderService, EnvironmentService, AsIniService, FormLayoutService, ObjectTypeService) {
    return {
        createObjectType
    };

    /**
     * Create a new ObjectType instance.
     * @param {*} rawObjectType - the objecttype info from the object definition
     * @param {*} rawNavCabinet - the cabinet info from the webclient backend
     * @returns {ObjectType} An ObjectType.
     */
    function createObjectType({rawObjectType, rawNavCabinet = {}}) {
        return new ObjectType(rawObjectType, rawNavCabinet);
    }

    /**
     * Create a new ObjectType instance.
     * @param {Object} rawObjectType - the objecttype info from the object definition
     * @param {Object} rawNavCabinet - the cabinet info from the webclient backend
     */
    function ObjectType(rawObjectType, rawNavCabinet = {}) {
        let self = this;

        self.model = new ObjectTypeModel(rawObjectType);
        // create the api with the objectType rather than just the model, because the cache might overwrite the model
        self.api = new ObjectTypeApi(self, rawObjectType, rawNavCabinet);
    }

    /**
     * Create a new ObjectTypeModel instance.
     * @param {Object} object - the objecttype info from the object definition
     */
    function ObjectTypeModel(object) {
        let self = this;

        // Todo: Should be stay a string because on other places this is a string.
        //       See objecttype.service.ts -> reduceAvailableTypes -> mappedTypesToIgnore.push
        self.osid = Number(object.ids.oid);

        // fieldList contains a flat list of every field for searching purposes
        // using this we can find each field without the need to search deep inside recursive structures
        // (not recursive structure)
        self.responsiveFieldList = [];
        // the configuredFields are the fields which the user desires to see as a column in the hitlist
        // if the user did not configure these fields, we need to have a fallback
        self.configuredFields = {};
        self.sendTitleConfiguredFields = {};
        self.windowTitleConfiguredFields = {};
        // the commonFields and requiredFields are used to determine the fallback fields as mentioned above (configuredFields)
        // we store the needed fields by tab index
        self.commonFields = [];
        self.requiredFields = [];
        // combine fields which we get as single fields
        // we combine fields into a new one with childfields
        // e.g radiobuttons
        // e.g groups
        self.combinedResponsiveFields = FieldsetBuilderService.combineResponsiveFields(angular.copy(object.fields.field), false);
        self.layout;

        self.baseParamFields = FieldsetBuilderService.buildBaseParamFields(object.maintype, false);

        self.type = ObjectTypeService.getObjectType(self.osid);

        // these are the scripts used for each objecttype
        self.scripts = null;

        self.config = {};
    }

    /**
     * Create a new ObjectType instance.
     * @param {Object} objectType - The corresponding ObjectType.
     * @param {Object} object - the objecttype info from the object definition
     * @param {Object} additionalProperties - the cabinet info from the webclient backend
     */
    function ObjectTypeApi(objectType, object, additionalProperties = {}) {
        let self = this;

        let lastCreated = null;

        setConfig(object, additionalProperties);
        FieldsetBuilderService.buildResponsiveFieldSet(objectType.model.combinedResponsiveFields, objectType.model.responsiveFieldList, objectType.model.config);
        getOtherFields();

        if (object.frame != void 0) {
            try {
                objectType.model.layout = FormLayoutService.buildLayout(object.frame, objectType.model.combinedResponsiveFields, null, objectType.model.responsiveFieldList);
            } catch (err) {
                console.warn(objectType.model.osid, err);
            }
        }

        /**
         * @returns {*} - returns the scripts associated to this objecttype
         */
        self.getTypeScripts = function () {
            return objectType.model.scripts;
        };
        /**
         * sets the scripts associated to this objecttype
         * @param {Object} data - the script information
         */
        self.setTypeScripts = function (data) {
            objectType.model.scripts = data;
        };

        /**
         * clears all generated field information
         * (to recreate them later) in case the scripter adds information we not want
         */
        function clearFields() {
            objectType.model.responsiveFieldList = [];
        }

        /**
         * @returns {String} returns the type of the objecttype as a string (document / register / folder)
         */
        self.getType = function () {
            return objectType.model.type;
        };

        /**
         * @returns {*} returns the layout information
         */
        self.getLayout = function () {
            return objectType.model.layout;
        };

        /**
         * @returns {Array<FieldModel>} - returns all baseparam fields
         */
        self.getBaseParamFields = function () {
            return objectType.model.baseParamFields;
        };

        /**
         * clears the configured fields in case the user modyfies the configuration
         */
        self.resetConfiguredFields = function () {
            objectType.model.configuredFields = {};
        };

        /**
         * @param {string} key - the key of a field
         * @param {string} value - the value that has to match the key
         * @returns {FieldModel} - returns a field from this objecttype that matches the key and value
         */
        function findField(key, value) {
            for (let field of objectType.model.responsiveFieldList) {
                if (field[key] == value) {
                    return field;
                }
            }
        }

        /**
         * @returns {Array<FieldModel>} returns the original field set to render search masks etc..
         */
        self.getFields = function () {
            clearFields();
            FieldsetBuilderService.buildResponsiveFieldSet(objectType.model.combinedResponsiveFields, objectType.model.responsiveFieldList, objectType.model.config);
            return objectType.model.responsiveFieldList;
        };

        /**
         * @param {string} internal - the internal name of the baseparam
         * @returns {*} - returns the field with the given internal name
         */
        self.getBaseParam = function (internal) {
            for (let baseParam of objectType.model.baseParamFields) {
                if (baseParam.internal == internal) {
                    return baseParam;
                }
            }
        };

        /**
         * @returns {Array<FieldModel>} - returns all fields from this objecttype as a flattened list
         */
        self.getFlatFieldList = function () {
            // to save time when creating multiple dms documents we dont refresh the field models
            // for about 5 seconds. Each dmsDocument created inside these 5 seconds will get a cached version of the
            // fieldmodels
            if (lastCreated != void 0 && +new Date() - lastCreated < 5000) {
                return objectType.model.responsiveFieldList;
            }

            clearFields();
            FieldsetBuilderService.buildResponsiveFieldSet(objectType.model.combinedResponsiveFields, objectType.model.responsiveFieldList, objectType.model.config);

            lastCreated = +new Date();

            return objectType.model.responsiveFieldList;
        };

        /**
         * @param {string} internal - the internal name of the field
         * @returns {boolean} - returns true if the field exists
         */
        self.hasField = function (internal) {
            return !!findField("internal", internal);
        };

        /**
         * @param {string} key - the key to find
         * @param {string} value - the value to match the key
         * @returns {FieldModel} - Returns the fieldmodel with the matchen key/value
         */
        function getFieldByKey(key, value) {
            clearFields();
            FieldsetBuilderService.buildResponsiveFieldSet(objectType.model.combinedResponsiveFields, objectType.model.responsiveFieldList, objectType.model.config);

            return findField(key, value);
        }

        /**
         * @param {string} internal - the internal name of the field
         * @returns {FieldModel} - returns to matching field
         */
        self.getField = function (internal) {
            return getFieldByKey("internal", internal);
        };

        /**
         * @param {string} name - the name of the field
         * @returns {FieldModel} - returns to matching field
         */
        self.getFieldByName = function (name) {
            return getFieldByKey("name", name);
        };

        /**
         * @param {string} dbname - the dbname name of the field
         * @returns {FieldModel} - returns to matching field
         */
        self.getFieldByDbName = function (dbname) {
            return getFieldByKey("dbname", dbname);
        };

        /**
         * configured fields are either:
         * 1. All required fields (mandatory to be filled)
         * 2. If there are less than 3 required fields, the configured fields are filled with up to
         *    3 common fields (all fields that can have a value except grids and invisible fields)
         * @returns {{}} -returns all configured fields of this objecttype
         */
        self.getConfiguredFields = function () {
            if (objectType.model.osid === -1) {
                if (Object.keys(objectType.model.configuredFields).length === 0) {
                    return getTypelessObjectFields();
                }

                return objectType.model.configuredFields;
            }

            if (Object.keys(objectType.model.configuredFields).length === 0) {
                let defaultFields = AsIniService.getHitlistFieldsConfiguration(objectType.model.osid);

                if (Array.isArray(defaultFields) && defaultFields.length) {
                    setConfiguredFields(defaultFields, objectType.model.configuredFields);
                } else {
                    // determine the fallback if no fields were configured
                    // the priority goes like
                    // --> required fields
                    // --> common fields (only fields that can be represented as a text field, checkboxes and radiobuttons)
                    // we will use all required fields if we have at least 3 required fields. If not we use all required fields
                    // and then as much common fields until we got 3 fields in total.
                    // (if there are less than 3 fields, we use all of them)
                    let columnIndex = 0;

                    // Keep information present about radio buttons. Only the first one of a group can be quered.
                    let lastFieldWasRadioButton = false;

                    for (let field of objectType.model.requiredFields) {
                        if (field.isInvisibleField) {
                            continue;
                        }

                        objectType.model.configuredFields[field.internal] = {
                            dbname: field.dbname,
                            headerName: field.title,
                            internal: field.internal,
                            isUnicode: field.isUnicode,
                            position: columnIndex++,
                            type: field.type
                        };
                    }

                    for (let field of objectType.model.commonFields) {
                        if (field.type !== "radio") {
                            lastFieldWasRadioButton = false;
                        }

                        if (field.isInvisibleField) {
                            continue;
                        }

                        if (Object.keys(objectType.model.configuredFields).length < 3) {
                            if (field.type === "radio") {
                                if (lastFieldWasRadioButton) {
                                    // The seconds and further radio buttons are quered with the first radio button
                                    continue;
                                }

                                lastFieldWasRadioButton = true;
                            }

                            objectType.model.configuredFields[field.internal] = {
                                dbname: field.dbname,
                                headerName: field.title,
                                internal: field.internal,
                                isUnicode: field.isUnicode,
                                position: columnIndex++,
                                type: field.type
                            };
                        } else {
                            break;
                        }
                    }
                }
            }

            return objectType.model.configuredFields;
        };

        /**
         * @param {ObjectTypeModel} cabinetTypeDef - the objectTypeDefinitions of the parent cabinet
         * @param {string} objectTypeAsString - the objecttype as a string (document / register / folder)
         * @returns {any} - returns all configured baseparams including cabinet parameters
         */
        self.getConfiguredBaseParams = function (cabinetTypeDef, objectTypeAsString) {
            let result = {};
            let baseParams;

            if (cabinetTypeDef == void 0) {
                return result;
            }

            switch (objectTypeAsString) {
                case "folder":
                    baseParams = AsIniService.getConfiguredFolderBaseParams(cabinetTypeDef.model.config.cabinetId);
                    break;
                case "register":
                    baseParams = AsIniService.getConfiguredRegisterBaseParams(cabinetTypeDef.model.config.cabinetId);
                    break;
                case "document":
                    baseParams = AsIniService.getConfiguredDocumentBaseParams(cabinetTypeDef.model.config.cabinetId);
            }

            if (baseParams == void 0) {
                return;
            }

            if (!Array.isArray(baseParams)) {
                baseParams = [baseParams];
            }

            if (baseParams != void 0) {
                baseParams.forEach((baseParam, index) => {
                    let field = cabinetTypeDef.api.getFieldByDbName(baseParam);

                    if (field == void 0) {
                        field = FieldsetBuilderService.getBaseParamFieldByInternal(baseParam);
                    }

                    if (field != void 0) {
                        result[field.internal] = {
                            position: index,
                            internal: field.internal,
                            headerName: field.title,
                            dbname: field.dbname,
                            isUnicode: field.isUnicode,
                            type: field.type,
                            baseParamName: field.baseParamName
                        };
                    }
                });
            }

            return result;
        };

        /**
         * Get the configured fields for building a title for a dms object.
         *
         * @param {"send"|"window"} mode - A mode defining which configured fields should be returned.
         * @returns {object} A map of fields by internal name.
         */
        self.getConfiguredTitleFields = function (mode) {
            let getConfigFn,
                configFieldList;

            if (mode == "send") {
                getConfigFn = AsIniService.getConfiguredSendTitleFields;
                configFieldList = objectType.model.sendTitleConfiguredFields;
            } else if (mode == "window") {
                getConfigFn = AsIniService.getConfiguredWindowTitleFields;
                configFieldList = objectType.model.windowTitleConfiguredFields;
            }

            if (configFieldList != void 0 && Object.keys(configFieldList).length === 0) {
                setConfiguredFields(getConfigFn(objectType.model.osid), configFieldList);
            }

            return configFieldList;
        };

        /**
         * Parse the asini field config to a map of fields by internal name.
         *
         * @param {string[]} asIniFieldConfig - An array of db names.
         * @param {object} configFieldList - A map of fields by internal name.
         */
        function setConfiguredFields(asIniFieldConfig, configFieldList) {
            for (let i in asIniFieldConfig) {
                let currentField = self.getFieldByDbName(asIniFieldConfig[i]);

                if (currentField && currentField.isInvisibleField) {
                    continue;
                }

                if (currentField == void 0) {
                    console.warn(`field with dbname ${asIniFieldConfig[i]} could not be found`);
                    continue;
                }

                let headerName = currentField.title;

                let internal = currentField.internal;
                if (Array.isArray(currentField.fields) && currentField.fields.length) {
                    internal = currentField.fields[0];
                    if(currentField.groupInternal) {
                        try {
                            headerName = getFieldByKey("internal", currentField.groupInternal).title;
                        } catch(error) {
                            // Failsafe in case we don't find the referenced group. One never knows...                        }
                            console.warn(`Unable to find group ${currentField.groupInternal} for field ${currentField.internal}`);
                        }
                    }
                }
                else if(currentField.type == "radio"){
                    continue;
                }

                configFieldList[internal] = {
                    internal,
                    position: parseInt(i),
                    headerName,
                    dbname: currentField.dbname,
                    isUnicode: currentField.isUnicode,
                    type: currentField.type
                };
            }
        }

        /**
         * @returns {{}} - returns the configured fields of a typeless object type
         */
        function getTypelessObjectFields() {
            let columnIndex = 0;

            let reqBaseParams = ["baseparamCreator", "baseparamCreated"];

            for (let i = 0; i < reqBaseParams.length; i++) {
                let internal = reqBaseParams[i];

                let param = self.getBaseParam(internal);

                let configuredKey = internal == "baseparamCreator" ? "CREATOR" : "CREATED";

                objectType.model.configuredFields[configuredKey] = {
                    internal: configuredKey.toLowerCase(),
                    position: columnIndex++,
                    headerName: param.title,
                    type: param.type.toUpperCase(),
                    isBaseParam: true
                };
            }
            return objectType.model.configuredFields;
        }

        /**
         * fill required fields, commonfields
         */
        function getOtherFields() {
            for (let field of objectType.model.responsiveFieldList) {
                if (field.isRequired) {
                    objectType.model.requiredFields.push(field);
                } else if (FieldsetBuilderService.isCommonField(field)) {
                    objectType.model.commonFields.push(field);
                }
            }
        }

        /**
         * set the config properties to give a simple overview about type specific attributes
         * @param {Object} currentObjectType - The current raw objecttype
         * @param {Object} additionalObjectTypeProperties - additional properties given by the old webclient backend
         */
        function setConfig(currentObjectType, additionalObjectTypeProperties) {
            objectType.model.config = {};

            objectType.model.config.isEmsType = false;
            objectType.model.config.reference = currentObjectType.reference;
            objectType.model.config.mainType = currentObjectType.maintype;
            objectType.model.config.objectTypeId = objectType.model.osid;
            objectType.model.config.name = currentObjectType.name;
            objectType.model.config.internal = currentObjectType.internal;
            objectType.model.config.tableName = currentObjectType.tablename;
            objectType.model.config.useFullText = currentObjectType.fulltext && (currentObjectType.fulltext == 1 || currentObjectType.fulltext == 2);
            objectType.model.config.multiType = false;
            objectType.model.config.withoutPages = false;
            objectType.model.config.icon = ObjectTypeService.getIconClass(objectType.model.osid, currentObjectType.IconID);
            objectType.model.config.cabinetId = additionalObjectTypeProperties.cabinetId;
            objectType.model.config.childTypes = additionalObjectTypeProperties.childTypes;
            objectType.model.config.rights = additionalObjectTypeProperties.rights;
            objectType.model.config.limitedObjects = {};

            if (currentObjectType.IconID != void 0 && currentObjectType.IconID != "" && currentObjectType.IconID != "0") {
                objectType.model.config.iconId = currentObjectType.IconID;
            }

            if (currentObjectType.limited_objects != void 0) {

                if (!Array.isArray(currentObjectType.limited_objects.limited_object)) {
                    currentObjectType.limited_objects.limited_object = [currentObjectType.limited_objects.limited_object];
                }

                for (let o of currentObjectType.limited_objects.limited_object) {
                    objectType.model.config.limitedObjects[o.type] = o.count;
                }
            }

            if (currentObjectType.fields.field != void 0) {
                let field = Array.isArray(currentObjectType.fields.field) ? currentObjectType.fields.field[0] : currentObjectType.fields.field;

                if (field.flags.flags & 8192) {
                    objectType.model.config.multiType = true;
                }
                if (field.flags.flags & 8388608) {
                    objectType.model.config.withoutPages = true;
                }
            }

            if (currentObjectType.ids.oid > -1 && currentObjectType.names != void 0 && currentObjectType.names.name != void 0) {
                objectType.model.config.title = EnvironmentService.getLocalizedInfo(currentObjectType.names.name);
            }

            let emsConfig = EnvironmentService.getEmsTypeByInternal(currentObjectType.internal)
            if (emsConfig != void 0) {
                objectType.model.config.isEmsType = true;
                objectType.model.config.emsMapping = {}

                for (let field of emsConfig.emsType.mappingFields) {
                    objectType.model.config.emsMapping[field.internalName] = field.extractionName;
                }

                objectType.model.config.emsDeduplicationContext = emsConfig.emsType.deduplicationContext;
            }
        }
    }
}
