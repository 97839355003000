import {Inject, Injectable} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {MessageService} from "../message/message.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: "root"
})
/**
 * Provides the functionality to visualise a progess state. Further this progress can be canceled
 * either by user input or an observable timer service.
 */
export class ProgressService {
    private readonly translateFn: TranslateFnType;

    private static max: number;
    private static success: number;
    finished: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(@Inject("$filter") $filter: ng.IFilterService, @Inject("modalDialogService") private modalDialogService: any,
                private messageService: MessageService
    ) {
        this.translateFn = $filter("translate");
    }

    finishSuccess = async (numberOfSuccess: number) => {
        this.modalDialogService.hideProgressDialog();

        let message: string, icon: string;
        if (numberOfSuccess < ProgressService.max) {
            message = this.translateFn("eob.ems.import.modal.warning")
                .replace("%d1", numberOfSuccess.toString())
                .replace("%d2", ProgressService.max.toString());
            icon = "warning";
        } else {
            message = this.translateFn("eob.ems.import.modal.success")
                .replace("%d1", ProgressService.success.toString())
                .replace("%d2", ProgressService.max.toString());
            icon = "success";
        }

        try {
            const config: any = { icon, triggerClose: (this.closeSuccessNotificationDialog)() };
            await this.modalDialogService.infoDialog(
                this.translateFn("eob.ems.import.modal.progress.title"),
                message, undefined, undefined, undefined,
                false, config);
        } catch (e) {
            // dialog dismissing
        }
    };

    updateProgress(index: number, max: number): void {
        ProgressService.max = max;
        ProgressService.success = index;

        this.modalDialogService.showCountingProgressDialog(
            this.translateFn("eob.ems.import.modal.progress.title"),
            this.translateFn("eob.ems.import.modal.progress.message"),
            index, max);
    }

    isFinished(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.finished.subscribe(x => {
                if (x) {
                    resolve(true);
                }
            }, () => reject());
        });
    }

    private closeSuccessNotificationDialog(): void {
        setTimeout(() => this.messageService.broadcast(ModalEvents.DESTROY), 3000);
    }
}