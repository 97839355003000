import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {ObjectTypeRights} from "ENUMS_PATH/objecttype-rights.enum";
import { Subscription } from "rxjs/index";
import { finalize } from "rxjs/operators";

require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/dms/eob.variant.srv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/mobile-desktop/eob.external.tray.srv.js");
require("COMPONENTS_PATH/eob-modal-container/eob-modal-external-variants/eob.modal.external.variants.dir.js");

angular.module("eob.framework").directive("eobVariant", EobVariant);

EobVariant.$inject = ["$stateParams", "$filter", "$timeout", "$compile", "$rootScope", "objectTypeService",
    "backendService", "viewerService", "cacheManagerService", "toolService", "gridContentService", "variantService",
    "progressbarService", "stateHistoryManager", "virtualGridService", "layoutManagerService", "virtualGridConverter",
    "clientService", "notificationsService", "messageService", "contextMenuService", "dmsDocumentService"];

/**
 * A state to manage variants.
 */
// eslint-disable-next-line max-params, require-jsdoc
export default function EobVariant($stateParams, $filter, $timeout, $compile, $rootScope, ObjectTypeService,
                                   BackendService, ViewerService, CacheManagerService, ToolService, GridContentService, VariantService,
                                   ProgressbarService, StateHistoryManager, VirtualGridService, LayoutManagerService, VirtualGridConverter,
                                   ClientService, NotificationsService, MessageService, ContextMenuService, DmsDocumentService) {
    return {
        restrict: "E",
        link(scope, element) {
            const sub = new Subscription();

            // Todo DODO-13393
            // FormService.setBeforeCancelExecuted({isExecuted: false, formHelper: FormService.getBeforeCancelExecuted().formHelper});

            const gHITLIST_CONFIG_DEFAULT = {
                suppressMultiselect: true,
                suppressGrouping: true
            };

            let currentStateConfig = StateHistoryManager.getCurrentStateData();
            let gContextId,
                gVariantContextId,
                gDocumentIds = [],
                gObjectType = CacheManagerService.objectTypes.getById($stateParams.objectTypeId);

            scope.stateTitle = $filter("translate")("eob.variants.state.title");
            scope.stateDescription = gObjectType.model.config.title;
            scope.isPhone = ClientService.isPhone();

            let variantData = null;
            let rowHeight = scope.isPhone ? 80 : LayoutManagerService.isTouchLayoutActive() ? 40 : 32;

            initFooterConfigs();

            let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);
            progressBar.show();
            let hitlistConfig, virtualGridInstance = null;

            sub.add(VariantService.getVariantTreeData($stateParams.id).pipe(
                finalize(() => {
                    progressBar.hide();
                })
            ).subscribe({next(fetchedVariantData) {
                    variantData = fetchedVariantData
                    extractIds(variantData.model.variantTree);
                    gContextId = CacheManagerService.dmsDocuments.attachListener(gDocumentIds, refreshHitlist);

                    // see DODO-12360 and DODO-10629. When we open preview/detailsviewer on mobile devices
                    // "hashchange" event is triggered, executeGC() in cache.model.srv.js is executed and all objects without
                    // listeners will be deleted. So we have to attach listeners to variantTreeData too
                    if (ClientService.isTouchDevice()) {
                        gVariantContextId = CacheManagerService.variantTreeData.attachListener(gDocumentIds, refreshHitlist);
                    }

                    hitlistConfig = getHitlistConfig();
                    let convertedData = VirtualGridConverter.convert(hitlistConfig, variantData);

                    let treeConfig = {
                        rowHeight,
                        headerRowHeight: rowHeight,
                        rows: convertedData.rows,
                        childNodesKey: "children",
                        columns: convertedData.columns,
                        showHeader: !scope.isPhone,
                        scrollContainer: element.find("#virtual-variant-tree"),
                        useMultiSelect: false,
                        isVariantManagement: true,
                        useIntermediateNodes: true,
                        deselectWhenCollapse: false,
                        isCollapsible: false,
                        expandNodesByDefault: true,
                        sortable: false,
                        onGridReady,
                        valueGetter,
                        headerValueGetter,
                        onRowClick: onClick,
                        onRowRightClick: showContextMenu,
                        onRowDoubleClick: onDoubleClick,
                    };

                    VirtualGridService.getVirtualGridInstance(treeConfig);
                    ContextMenuService.registerContextMenuActionsProvider("variants");
                },
            error(error) {
                if (ClientService.isOffline()) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                } else {
                    NotificationsService.error($filter("translate")("eob.action.edit.info.no.variant.manager.notification"));
                }
                goBack();
            }}));

            /**
             * Set the selection, scroll position and update the viewer initially.
             * @param {object} params - The virtual grid instance.
             */
            function onGridReady(params) {
                virtualGridInstance = params;
                selectDefaultVariantEntry();
            }

            /**
             * Enrich the grid configuration with the default configuration for the variant state.
             * @returns {object} A hitlist configuration.
             */
            function getHitlistConfig() {
                let dmsDocuments = CacheManagerService.dmsDocuments.get(gDocumentIds);
                let config = GridContentService.getListEntries(dmsDocuments);

                return Object.assign(config, gHITLIST_CONFIG_DEFAULT);
            }

            /**
             * Get the osids of all displayed variants.
             * @param {object} node - A grid node.
             */
            function extractIds(node) {
                gDocumentIds.push(node.osid);

                for (let n of node.children) {
                    extractIds(n);
                }
            }

            /**
             * Get the display name for the given data.
             * @param {{api: VirtualGridApi, col: object}} params - A data object.
             * @returns {string} The display name for the column or an empty string.
             */
            function headerValueGetter(params) {
                let rows = params.api.getSelectedRows();
                let selectedRow = rows[0];

                return `${selectedRow ? selectedRow[params.col.field].headerName : ""}`;
            }

            /**
             * Get the value for the given cell.
             * @param {object} params - The node data.
             * @returns {*} The cell value.
             */
            function valueGetter(params) {
                return params.rowData[params.field].value;
            }

            /**
             * Renders the grid again and updates each cell.
             */
            function refreshHitlist() {
                if (virtualGridInstance != void 0) {
                    let convertedRows = VirtualGridConverter.convert(hitlistConfig, variantData);

                    virtualGridInstance.api.updateGridRows(convertedRows.rows);

                    selectDefaultVariantEntry(virtualGridInstance);
                }
            }

            /**
             * Set selected dmsDocuments in state config to osid
             * @param osid - The os id
             */
            function setStateConfigSelection(osid) {
                currentStateConfig.data.config = currentStateConfig.data.config || {};
                currentStateConfig.data.config.selectedDmsOsids = [];
                currentStateConfig.data.config.selectedDmsOsids.push(osid.toString());
            }

            /**
             * Select the last selected or active variant entry.
             */
            function selectDefaultVariantEntry() {
                if (virtualGridInstance == void 0) {
                    return;
                }

                let selectedVariantId = 0;
                if (currentStateConfig.data.config != void 0 && currentStateConfig.data.config.selectedItems != void 0) {
                    let keys = Object.keys(currentStateConfig.data.config.selectedItems);

                    if (keys.length > 0) {
                        selectedVariantId = keys[0];
                    }
                }

                let defaultIndex = 0;
                let defaultOsid = -1;
                let selectedRows = virtualGridInstance.api.getSelectedRows();

                if (selectedRows.length == 0) {
                    for (let row of virtualGridInstance.api.getRows()) {
                        if (row.osid == selectedVariantId) {
                            defaultIndex = row.index;
                            defaultOsid = row.osid;
                            ViewerService.setFileMimeTypeGroup(row.fileMimeTypeGroup, row.mainType);
                            break;
                        }

                        if (row.osid == variantData.model.activeVariantId) {
                            defaultIndex = row.index;
                            defaultOsid = row.osid;
                            ViewerService.setFileMimeTypeGroup(row.fileMimeTypeGroup, row.mainType);
                        }
                    }

                    if (defaultOsid == -1) {
                        // In case the active variant was deleted we don't find it anylonger in the
                        // virtual grid. The server is activating in such a case the original variant
                        // and this is located in row 0 in the virtual grid. Therefore, we can use
                        // the osid from row 0.
                        defaultOsid = virtualGridInstance.api.getRows()[0].osid;
                    }

                    virtualGridInstance.api.selectIndex(defaultIndex);
                    virtualGridInstance.api.scrollToIndex(defaultIndex, false);
                    virtualGridInstance.api.refreshHeader();

                    setStateConfigSelection(defaultOsid);
                    ViewerService.updateViewer(defaultOsid, undefined, [defaultOsid]);
                }
            }

            /**
             * OnDoubleClick callback for the hitlist. It sets the clicked variant active.
             * @param {object} params - The callback parameter from the hitlist.
             */
            function onDoubleClick(params) {
                if (ClientService.isOffline()) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                } else {
                    let dmsDocument = CacheManagerService.dmsDocuments.getById(params.rowData.osid);
                    if (DmsDocumentService.hasRights([ObjectTypeRights.INDEXDATA_MODIFY, ObjectTypeRights.OBJECT_MODIFY], dmsDocument, gObjectType.model.config)) {
                        VariantService.changeActiveVariant(dmsDocument);
                    }
                }
            }

            /**
             * OnClick callback for the hitlist. It updates the viewer.
             * @param {object} params - The callback parameter from the hitlist.
             * @param {object} event - A js event.
             */
            function onClick(params, event) {
                let targetElement = angular.element(event.target);
                ViewerService.setFileMimeTypeGroup(params.fileMimeTypeGroup, params.mainType);
                let isTouchKebab = (targetElement.hasClass("icon-16-kebab-dark") || targetElement.find(".icon-16-kebab-dark").length > 0);
                let isTouch = LayoutManagerService.isTouchLayoutActive();

                if (scope.isPhone && isTouchKebab) {
                    showContextMenu(params, event);
                } else if (isTouch) {
                    MessageService.broadcast(ModalEvents.OPEN_MODAL_DASHLETS, params.osid);
                } else {
                    setStateConfigSelection(params.osid);
                    ViewerService.updateViewer(params.osid, undefined, [params.osid]);
                }
            }

            /**
             * Show the contextmenu on right click.
             * @param {object} params - The grid data.
             * @param {object} event - A js event.
             */
            async function showContextMenu(params, event) {
                virtualGridInstance.api.selectIndex(params.index);
                setStateConfigSelection(params.osid);
                ViewerService.updateViewer(params.osid, undefined, [params.osid]);

                MessageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                    items: [params],
                    event,
                    contextData: {
                        context: "variants",
                        title: scope.isPhone ? params.f1.title : ""
                    }
                });
            }

            scope.$on("$destroy", () => {
                // Todo DODO-13393
                // if (!FormService.getBeforeCancelExecuted().isExecuted) {
                //     FormService.executeFormScript("beforeCancel", FormService.getBeforeCancelExecuted().formHelper, false);
                // }
                ContextMenuService.unregisterContextMenuActionsProvider("variant");

                CacheManagerService.dmsDocuments.detachListeners(gContextId);

                if (ClientService.isTouchDevice()) {
                    CacheManagerService.variantTreeData.detachListeners(gVariantContextId);
                }
                if(virtualGridInstance){
                    virtualGridInstance.destroy();
                }
                sub.unsubscribe();
            });

            /**
             * Returns to last state.
             */
            function goBack() {
                $timeout(() => {
                    ClientService.executeStateErrorFallback();
                }, 100);
            }

            function initFooterConfigs() {
                scope.footerConfigs = [{
                    icon: { name: "footer-back", title: $filter("translate")("form.footer.back") },
                    action: "back",
                    class: "footer-button"
                }]
            }
        }
    };
}
