(function () {
    "use strict";

    angular.module("eob.core").factory("wfFileModelService", WfFileModelService);

    WfFileModelService.$inject = ["toolService"];

    function WfFileModelService(ToolService) {

        return {
            createWfFile
        };

        function createWfFile(backendSearchEntry) {
            return new WfFile(backendSearchEntry);
        }

        function WfFile(backendSearchEntry) {
            let self = this;

            self.model = {
                wfFile: backendSearchEntry.wfItem,
                guid: ToolService.createGUID()
            };
        }
    }
})();