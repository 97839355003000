<eob-panel [eobPanelTitle] = "panelHeading"
           [initExpanded] ="true"
           [isNavPanel] = "true"
           (focusLeftEvent) = "passFocus($event, 0)"
           *ngIf = "ready">
    <div panelContent>
        <ul class = "nav-external-tray-list nav-panel-elements"><!-- *ngIf = "ready">-->
            <li *ngFor = "let item of items; let i = index" class = "external-tray-element">
                <button (click) = "showMenu(item, $event)" (dblclick) = "openItem(item)" class = "button-transparent">
                    <eob-icon name = "{{item.icon}}" size = "16"></eob-icon>
                    <span title = "{{item.displayTitle}}">{{item.displayTitle}}</span>
                </button>
            </li>
        </ul>
    </div>
</eob-panel>
