import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ViewService} from "CORE_PATH/services/view/view.service";
import * as angular from "angular";
import {Subscription} from "rxjs";
import {BubbleBoxStyle, ValidationBubble} from "INTERFACES_PATH/validation.interface";

@Component({
    selector: "eob-validation-bubble",
    templateUrl: "./eob-validation-bubble.component.html",
    styleUrls: ["./eob-validation-bubble.component.scss"]
})

export class EobValidationBubbleComponent implements OnInit, OnDestroy {

    constructor(private viewService: ViewService,
                private messageService: MessageService,
                private renderer: Renderer2) {}

    private hFocus: any;
    private hBlur: any;
    private hMouseEnter: any;
    private hMouseLeave: any;
    private invalidInput: HTMLElement;
    private sub: Subscription = new Subscription();

    @ViewChild("focusBubble", { static: false }) focusBubbleElement: ElementRef;
    @ViewChild("focusBubbleTitle", { static: false }) focusBubbleText: ElementRef;
    @ViewChild("focusBubbleMessage", { static: false }) focusBubbleMessage: ElementRef;
    @ViewChild("hoverBubble", { static: false }) hoverBubbleElement: ElementRef;
    @ViewChild("hoverBubbleTitle", { static: false }) hoverBubbleText: ElementRef;
    @ViewChild("hoverBubbleMessage", { static: false }) hoverBubbleMessage: ElementRef;

    ngOnInit(): void {
        this.sub.add(this.messageService.subscribe("bind-validation-bubble", (data: ValidationBubble): void => {
            this.invalidInput = data.input;
            const bubbleTitle: string = data.title;
            const bubbleMessage: string = data.msg;

            this.unbindAll(this.invalidInput);

            this.invalidInput.addEventListener("remove", () => {
                this.unbindAll(this.invalidInput);
            });

            this.hFocus = (currentEvent): void => {
                this.focusBubbleText.nativeElement.textContent = bubbleTitle;
                this.focusBubbleMessage.nativeElement.textContent = bubbleMessage;
                this.focusBubbleElement.nativeElement.style.display = "block";
                let zIndex: number = this.viewService.getZIndex(this.invalidInput);
                zIndex = Number(zIndex) + 5;

                const css: BubbleBoxStyle = this.getPosition(currentEvent.target);
                const scrollParent: HTMLElement | string = this.getScrollParent(this.invalidInput);

                angular.element(scrollParent)[0].removeEventListener("scroll", () => this.scrollParentHandler(this.invalidInput));
                angular.element(scrollParent)[0].addEventListener("scroll", () => this.scrollParentHandler(this.invalidInput));

                for (const s in css) {
                    this.focusBubbleElement.nativeElement.style[s] = css[s];
                    if (this.focusBubbleElement.nativeElement.style[s] !== css[s]) {
                        this.focusBubbleElement.nativeElement.style[s] = `${css[s].toString()}px`;
                    }
                }
                this.focusBubbleElement.nativeElement.style.opacity = 1;
                this.focusBubbleElement.nativeElement.style.zIndex = zIndex;
                this.focusBubbleElement.nativeElement.style.disply = "block";
                setTimeout(() => {
                    this.hoverBubbleElement.nativeElement.style.opacity = 0;
                    this.hoverBubbleElement.nativeElement.style.zIndex = -5;
                    this.hoverBubbleElement.nativeElement.style.display = "none";
                }, 200);
            };

            this.invalidInput.addEventListener("focus", this.hFocus);

            this.invalidInput.addEventListener("blur", (): void => {
                this.focusBubbleElement.nativeElement.style.opacity = 0;
                this.focusBubbleElement.nativeElement.style.zIndex = -5;
                this.focusBubbleElement.nativeElement.style.display = "none";
            });

            this.hMouseEnter = (event): void => {
                this.renderer.addClass(this.invalidInput, "hover");

                if (!$(this).is(":focus")) {

                    let zIndex: number = this.viewService.getZIndex(this.invalidInput);
                    zIndex = Number(zIndex) + 5;

                    const css: BubbleBoxStyle = this.getPosition(event.target);
                    css.opacity = 1;
                    css["z-index"] = zIndex;
                    css.display = "block";
                    for (const s in css) {
                        this.hoverBubbleElement.nativeElement.style[s] = css[s];
                        if (this.hoverBubbleElement.nativeElement.style[s] !== css[s]) {
                            this.hoverBubbleElement.nativeElement.style[s] = `${css[s].toString()}px`;
                        }
                    }
                    this.hoverBubbleText.nativeElement.textContent = bubbleTitle;
                    this.hoverBubbleMessage.nativeElement.textContent = bubbleMessage;
                }
            };

            this.hMouseLeave = (): void => {
                this.renderer.removeClass(this.invalidInput, "hover");
                if (!$(this).is(":focus")) {
                    if (!$(this.invalidInput).hasClass("hover")) {
                        this.hoverBubbleElement.nativeElement.style.opacity = 0;
                        this.hoverBubbleElement.nativeElement.style.zIndex = -5;
                        this.hoverBubbleElement.nativeElement.style.display = "none";
                    }
                }
            };

            this.invalidInput.addEventListener("mouseenter", this.hMouseEnter);

            this.invalidInput.addEventListener("mouseleave", this.hMouseLeave);

        }));

        this.sub.add(this.messageService.subscribe("unbind-validation-bubble", (input: HTMLElement): void => {
            this.unbindAll(input);
        }));
    }

    scrollParentHandler(element: any): void {
        const newCss: BubbleBoxStyle = this.getPosition(element);

        const inputScreenPos: BubbleBoxStyle = element.getBoundingClientRect();

        if (inputScreenPos.top < 0) {
            this.focusBubbleElement.nativeElement.style.display = "none";
        } else {
            this.focusBubbleElement.nativeElement.style.display = "block";
        }

        this.focusBubbleElement.nativeElement.style = newCss;
    }

    unbindAll(element: HTMLElement): void {
        if (this.hFocus) {
            element.removeEventListener("focus", this.hFocus);
        }
        if (this.hBlur) {
            element.removeEventListener("blur", this.hBlur);
        }
        if (this.hMouseEnter) {
            element.removeEventListener("mouseenter", this.hMouseEnter);
        }
        if (this.hMouseLeave) {
            element.removeEventListener("mouseleave", this.hMouseLeave);
        }

        this.hoverBubbleElement.nativeElement.style.opacity = 0;
        this.hoverBubbleElement.nativeElement.style.zIndex = -5;
        this.focusBubbleElement.nativeElement.style.opacity = 0;
        this.focusBubbleElement.nativeElement.style.zIndex = -5;
    }

    getScrollParent(element: HTMLElement, includeHidden?: boolean): HTMLElement | string {
        let style: CSSStyleDeclaration = getComputedStyle(element);
        const excludeStaticParent: boolean = style.position === "absolute";
        const overflowRegex: RegExp = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

        if (style.position === "fixed") {
            return document.body;
        }

        let parent: HTMLElement = element;

        while (parent.parentElement) {
            parent = parent.parentElement;
            style = getComputedStyle(parent);
            if (excludeStaticParent && style.position === "static") {
                continue;
            }
            if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
                return parent;
            }
        }

        return document.body;
    }

    getPosition(element: HTMLElement, isReCalc?: boolean): BubbleBoxStyle {
        const css: BubbleBoxStyle = {
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto"
        };

        const screenPos: DOMRect = element.getBoundingClientRect();
        css.left = screenPos.left;
        if (typeof screenPos.bottom === "number") {
            css.top = screenPos.bottom + window.scrollY + 4;
        }

        return css;
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
