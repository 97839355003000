import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {Subject, Subscription} from "rxjs";

(function() {


    require("./eob-wf-circulation-grid/eob.wf.circulation.grid.dir.js");
    require("COMPONENTS_PATH/eob-modal-container/eob-modal-circulation-slip-save/eob.modal.circulation.slip.save.dir.js");

    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.modal.dialog.srv.js");
    require("SERVICES_PATH/form/eob.form.srv.js");
    require("COMPONENTS_PATH/eob-touch-event-binder/eob.touch.event.binder.dir.js");

    const dayjs = require("dayjs");

    angular.module("eob.framework").directive("eobWorkflowCirculation", EobWorkflowCirculation);

    EobWorkflowCirculation.$inject = ["$filter", "$q", "$rootScope", "$compile", "backendService", "notificationsService", "valueUtilsService", "environmentService",
        "modalDialogService", "formService", "clientService", "progressbarService", "messageService", "modalDialogInjectorService", "contextMenuService", "wfOrgAddonService"];

    function EobWorkflowCirculation($filter, $q, $rootScope, $compile, BackendService, NotificationsService, ValueUtilsService, EnvironmentService,
                                    ModalDialogService, FormService, ClientService, ProgressbarService, MessageService, ModalDialogInjectorService, ContextMenuService, WfOrgAddonService) {
        return {
            restrict: "E",
            scope: {
                formHelper: "=",
                routingList: "="
            },
            link(scope, element) {
                const subscriptions = new Subscription();

                let activityTypePropsEnum = {
                    "WORKITEM": { icon: "icon-16-wf-activity-workitem", transKey: "eob.workflow.circulation.tooltip.activity.workitem" },
                    "MULTIINSTANCE": { icon: "icon-16-wf-activity-multi", transKey: "eob.workflow.circulation.tooltip.activity.multiinstanz" },
                    "ROUTE": { icon: "icon-16-wf-activity-route", transKey: "eob.workflow.circulation.tooltip.activity.route" }
                };

                let defaultActivity, defaultPeriod;
                let activityMap = {};
                let periodMap = {};

                let formHelper = scope.formHelper;
                let routingList = scope.routingList;
                let isUserProcessResponsible = formHelper.isUserProcessResponsible();
                let columns = {};
                let groups = [];

                let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);

                ContextMenuService.registerContextMenuActionsProvider("circulationSlip");

                scope.isPhone = ClientService.isPhone();
                scope.ready = false;
                scope.showTemplateSection = false;
                scope.gridConfig = null;
                scope.newTemplate = {
                    name: "",
                    public: (!scope.canManagePrivateCirculation),
                    canManagePrivateCirculation: EnvironmentService.userHasRole("R_CLNT_WFPRIVATEROUTELIST"),
                    canManagePublicCirculation: EnvironmentService.userHasRole("R_CLNT_WFPUBLICROUTELIST")
                };
                scope.incompatible = {
                    show: false
                };

                scope.saveTemplate = saveTemplate;
                scope.closeTemplates = function() {
                    showTemplates(false);
                };
                scope.toggleIncompatible = toggleIncompatible;
                scope.appendSelectedTemplate = appendSelectedTemplate;
                scope.isIncompatibleTemplate = isIncompatibleTemplate;
                scope.isDummyTemplate = isDummyTemplate;
                scope.showTemplateMenu = showTemplateMenu;
                scope.addGroup = addGroup;

                $(".search-right-pane").attr("style", "width: 0px; overflow: hidden !important");

                subscriptions.add(MessageService.subscribe("showTemplates", () => {
                    showTemplates(true);
                }));

                subscriptions.add(MessageService.subscribe("showTemplateSaveModalDialog", () => {
                    showTemplateSaveModalDialog("", false);
                }));

                scope.$on("circulation.slips.save", () => {
                    saveTemplate();
                });

                scope.$on("circulation.slips.move", (event, template) => {
                    moveTemplate(template);
                });

                scope.$on("circulation.slips.append", (event, template) => {
                    appendSelectedTemplate(template);
                });

                scope.$on("circulation.slips.delete", (event, template) => {
                    deleteTemplate(template);
                });

                scope.$on("$destroy", () => {
                    ContextMenuService.unregisterContextMenuActionsProvider("circulationSlip");
                    subscriptions.unsubscribe();
                });

                init();

                // region Init

                function init() {
                    let processId = routingList.model.processId;
                    let adHocActivityId = routingList.model.activityId;
                    let promises = [];

                    let adHocInfoPromise = BackendService.get(`/workflows/adhoc/info?processId=${processId}&activityId=${adHocActivityId}`);
                    promises.push(adHocInfoPromise);

                    let templatePromise = initTemplateSection();
                    promises.push(templatePromise);

                    $q.all(promises).then((res) => {
                        prepareDefaultData(res[0].data);
                        initGrid();
                    }, (error) => {
                        NotificationsService.backendError(error, "eob.workflow.circulation.load.error");
                    });
                }

                function prepareDefaultData(data) {
                    for (let i in data.activities) {
                        let activity = data.activities[i];
                        activity.participants = activity.performers;
                        delete activity.performers;

                        activityMap[activity.name] = activity;

                        if (activity.id == data.defaultActivityId) {
                            defaultActivity = activity;
                        }
                    }

                    if (defaultActivity == void 0) {
                        defaultActivity = data.activities[0];
                    }

                    for (let j in data.periods) {
                        let period = data.periods[j];
                        periodMap[period.name] = period;

                        if (period.id == data.defaultPeriodId) {
                            defaultPeriod = period;
                        }
                    }

                    routingList.model.adHocData = {
                        defaultActivity,
                        defaultPeriod,
                        activities: activityMap,
                        periods: periodMap
                    };
                }

                function initGrid() {
                    prepareColumns();
                    prepareGroups();

                    scope.gridConfig = {
                        columns,
                        groups,
                        expandable: routingList.model.expandable,

                        createGroup: onCreateGroup,
                        onMoveGroup,
                        createRow: onCreateRow,
                        onRemoveRow,
                        onMoveRow
                    };

                    scope.ready = true;

                    scope.$on("circulation.grid.ready", (e, gridField) => {
                        formHelper.setRoutingListGrid(gridField);
                    })
                }

                function prepareColumns() {
                    addActivityTypeColumn();
                    addActivityNameColumn();
                    addColumn("taskName", "text");
                    addPerformersColumn();
                    addPeriodColumn();
                    addDueOnColumn();
                    addEditableColumn();
                    addDeletableColumn();
                    addColumn("remark", "text");
                }

                function prepareGroups() {
                    let groupsData = routingList.model.groups;

                    for (let i = 0; i < groupsData.length; i++) {
                        let groupData = groupsData[i].model;
                        let groupName = [];
                        let groupRows = {};
                        let rowsData = groupData.items;

                        for (let itemId in rowsData) {
                            let rowData = rowsData[itemId].model;
                            groupName.push(rowData.activityName.value);
                            groupRows[itemId] = createRowFromBackendData(rowData);
                        }

                        groups.push({
                            id: groupData.id,
                            rows: groupRows,
                            title: groupName.join(" "),
                            expandable: groupData.expandable
                        });
                    }
                }

                function getPeriodById(idOrName) {
                    for (let i in periodMap) {
                        let period = periodMap[i];

                        if (period.id == idOrName || period.name == idOrName) {
                            return period;
                        }
                    }

                    return null;
                }

                function setGroupTitle(changedRow) {
                    let group = changedRow.group;
                    let activityNames = [];

                    for (let rowId in group.rows) {
                        let row = group.rows[rowId];

                        // we need to do this in case the there is an activity added on beforeOpen
                        // at this point the api is not ready yet !? --> use the value
                        let value = row.cells["activityName"].api != void 0 ? row.cells["activityName"].api.getValue() : row.cells["activityName"].value;

                        activityNames.push(value);
                    }

                    group.title = activityNames.length == 0 ? $filter("translate")("eob.workflow.circulation.empty.group") : activityNames.join(" ");
                }

                // region create rows
                function onCreateRow(group, data) {
                    if (data == void 0) {
                        var group = routingList.api.getGroupById(group.id);
                        data = group.api.createItem(defaultActivity, defaultPeriod, false);
                    }

                    return createRow(data.model);
                }

                function createRowFromBackendData(model) {
                    parseBackendData(model);
                    let row = createRow(model);
                    let cells = row.cells;

                    if (!model.isNew.value && !isUserProcessResponsible) {
                        if (cells.editable.value) {
                            cells.editable.isDisabled = true;
                            cells.deletable.isDisabled = true;
                        } else {
                            for (let cellIndex in cells) {
                                cells[cellIndex].isDisabled = true;
                            }
                        }

                        if (!cells.deletable.value) {
                            row.disableRemoveRow = true;
                        }
                    }

                    return row;
                }

                function parseBackendData(model) {
                    let periodId = model.periodName.value;

                    if (periodId != void 0 && periodId != "") {
                        let period = getPeriodById(periodId);

                        model.periodName.value = (period != void 0) ? period.name : $filter("translate")("eob.workflow.circulation.unknown");

                        // Wenn es ein unix Zeitstempel ist (ohne Millisekunden)
                        if (model.dueOn.value.indexOf(" ") == -1 && model.dueOn.value < 10000000000) {
                            if (model.timerType.value == 1) {
                                let m1 = dayjs();
                                let m2 = model.dueOn.value * 1000;

                                // Wenn wir hinter dayjs() liegen, dann haben wir eine relative Zeit.
                                if (m2 < m1) {
                                    model.dueOn.value = ValueUtilsService.convertToTimestamp(m1.add(m2));
                                }
                            } else if (model.timerType.value == 2) {
                                model.dueOn.value = ValueUtilsService.convertToTimestamp(dayjs(model.dueOn.value * 1000));
                            }
                        }
                    }
                }

                function createRow(model) {
                    let row = {
                        id: model.id.value
                    };
                    let cells = row.cells = {};

                    addActivityTypeCell(cells, model);
                    addCell(cells, "activityName", model.activityName, true);
                    addCell(cells, "taskName", model.taskName, true);
                    addCell(cells, "participants", model.participants, true);
                    addCell(cells, "editable", model.editable, true);
                    addCell(cells, "deletable", model.deletable, true);
                    addCell(cells, "remark", model.remark, true);
                    addCell(cells, "periodName", model.periodName, true);
                    addCell(cells, "timerType", model.timerType, true);
                    addCell(cells, "dueOn", model.dueOn, true);

                    let periodName = model.periodName.value;

                    if (periodName != void 0 && periodName != "") {
                        let period = periodMap[periodName];
                        cells.periodName.value = period != void 0 ? period.name : $filter("translate")("eob.workflow.circulation.unknown");
                    } else {
                        model.dueOn.value = ""
                    }

                    return row;
                }

                /**
                 * Adds a icon referenz as the value for the activity type column along with a
                 * suitable tooltip.
                 *
                 * @param cells
                 * @param model
                 */
                function addActivityTypeCell(cells, model) {
                    let activity = activityMap[model.activityName.value];
                    let activityType = `${activity == void 0 ? "" : activity.type}`;
                    let property = activityTypePropsEnum[`${activityType}`];

                    addCell(cells, "activityType", `${property != void 0 ? property.icon : ""}`);
                    if (property != void 0) {
                        cells["activityType"].title = $filter("translate")(property.transKey);
                    }
                }

                function onCreateGroup(data) {
                    let createDefaultRow = false;

                    if (data == void 0) {
                        data = routingList.api.createGroup(null, false);
                        createDefaultRow = true;
                    }

                    data = data.model;

                    let group = {
                        id: data.id,
                        title: "",
                        expandable: data.expandable,
                        rows: {}
                    };

                    for (let i in data.items) {
                        let item = data.items[i];
                        let row = createRow(item.model);
                        group.rows[row.id] = row;
                    }

                    if (createDefaultRow) {
                        let defaultRow = onCreateRow(group);
                        group.title = defaultRow.cells["activityName"].value;
                        group.rows[defaultRow.id] = defaultRow;
                    }

                    return group;
                }

                function onMoveGroup(group, index) {
                    let modelGroup = routingList.api.getGroupById(group.id);

                    modelGroup.api.moveTo(modelGroup.routingList, index, false, false);
                }

                function addCell(cells, name, valueObject, attachField) {
                    cells[name] = attachField ? valueObject : { value: valueObject };
                }

                function onRemoveRow(row) {
                    setGroupTitle(row);

                    let group = routingList.api.getGroupById(row.group.id);
                    if (group != null) {
                        let item = group.api.getItemById(row.id);
                        if (item != void 0) {
                            group.api.removeItem(item);
                        }
                    }
                }

                function onMoveRow(row, oldGroup, newGroup) {
                    if (oldGroup.id != newGroup.id) {
                        let newGridGroup = routingList.api.getGroupById(newGroup.id);
                        let oldGridGroup = routingList.api.getGroupById(oldGroup.id);
                        let gridItem = oldGridGroup.api.getItemById(row.id);

                        gridItem.api.moveTo(newGridGroup);
                    }
                }

                // endregion
                // region create columns

                function addActivityTypeColumn() {
                    return addIconColumn("activityType", "static", "icon-16-wf-activity-type-header", null, {});
                }

                function addActivityNameColumn() {
                    let nodes = [];

                    for (let activityName in activityMap) {
                        let activity = activityMap[activityName];

                        nodes.push({
                            value: activity.name,
                            iconUrl: activityTypePropsEnum[activity.type]
                        });
                    }

                    let onChangeFn = function(cell, field) {
                        let newActivity = activityMap[field.model.control.value];

                        if (newActivity == void 0) {
                            return;
                        }

                        let taskName = cell.row.cells["taskName"].value;
                        let performerField = cell.row.cells["participants"];
                        let taskIsDefault = false;

                        for (let i in activityMap) {
                            if (taskName == activityMap[i].name) {
                                taskIsDefault = true;
                                break;
                            }
                        }

                        if (taskIsDefault) {
                            cell.row.cells["taskName"].api.setValue(newActivity.name);
                        }

                        cell.row.cells["editable"].api.setValue(newActivity.editable);
                        cell.row.cells["deletable"].api.setValue(newActivity.deletable);
                        cell.row.cells["activityType"].value = activityTypePropsEnum[`${newActivity.type}`].icon;
                        cell.row.cells["activityType"].title = $filter("translate")(activityTypePropsEnum[`${newActivity.type}`].transKey);

                        if (newActivity.type == "ROUTE") {
                            performerField.api.setValue("");
                            performerField.api.disable();
                            performerField.api.setValid();

                            let periodField = cell.row.cells["periodName"];
                            periodField.api.setValue("");
                            periodField.api.setValid();

                            let dueOnField = cell.row.cells["dueOn"];
                            dueOnField.api.setValue("");
                            dueOnField.api.setValid();
                        } else {
                            performerField.api.enable();
                            performerField.api.validate();
                        }

                        setGroupTitle(cell.row);
                    };

                    let onInitFn = function(cell) {
                        setGroupTitle(cell.row);
                    };

                    let column = addListColumn("activityName", "text", nodes);
                    column.config.onChange = onChangeFn;
                    column.config.onInit = onInitFn;
                    column.modelProps.isRequired = true;

                    return column;
                }

                function addPerformersColumn() {
                    let modelProps = {
                        isMockfield: true,
                        addon: "organisation",
                        config: {
                            showAll: true,
                            namingRule: "%$$Name$$%"
                        }
                    };
                    modelProps.config.orgMember = WfOrgAddonService.getOrgList(modelProps.config);

                    let onInitFn = function(cell, field) {
                        // disable if route
                        if (cell.row.cells["activityType"].value == activityTypePropsEnum.ROUTE.icon) {
                            field.api.disable();
                        }

                        field.api.addCustomValidation((field) => {
                            return (cell.row.cells["activityType"].value == activityTypePropsEnum.ROUTE.icon || (field.value != void 0 && field.value != ""));
                        }, $filter("translate")("eob.validation.required.title"), $filter("translate")("eob.validation.required.message"));
                    };

                    let config = {
                        onInit: onInitFn
                    };

                    return addColumn("participants", "text", modelProps, config);
                }

                function addPeriodColumn() {
                    let nodes = [];

                    for (let periodName in periodMap) {
                        nodes.push({
                            value: periodName
                        });
                    }

                    let onChangeFn = function(cell, field) {
                        let newPeriod = periodMap[field.model.control.value];
                        let dueOnField = cell.row.cells["dueOn"];
                        let timerTypeField = cell.row.cells["timerType"];

                        if (newPeriod == void 0) {
                            dueOnField.api.disable();
                            dueOnField.api.setValue("");
                            dueOnField.api.setValid();
                            timerTypeField.value = 0;
                        } else {
                            let dueOn = ValueUtilsService.formatDate(dayjs().add(newPeriod.duration * 1000).valueOf(), true);

                            timerTypeField.value = 1;
                            dueOnField.api.setValue(dueOn);
                            dueOnField.api.enable();
                            dueOnField.api.validate();
                        }
                    };

                    let column = addListColumn("periodName", "text", nodes);
                    column.config.onChange = onChangeFn;
                    return column;
                }

                function addDueOnColumn() {
                    let onInitFn = function(cell, field) {
                        let periodField = cell.row.cells["periodName"];

                        if (periodField.model.control.value == "" || periodField.model.control.value == void 0) {
                            field.api.disable();
                        }

                        field.api.addCustomValidation((field) => {
                            return !((periodField.value != void 0) && (periodField.value != "") && ((field.value == void 0) || (field.value == "")));
                        }, $filter("translate")("eob.workflow.circulation.column.header.dueOn"), $filter("translate")("eob.workflow.circulation.period.date.error.msg"));
                    };

                    let onChangeFn = function(cell) {
                        let timerTypeField = cell.row.cells["timerType"];
                        timerTypeField.value = 2;
                    };

                    return addColumn("dueOn", "datetime", { placeholder: "" }, { onInit: onInitFn, onChange: onChangeFn });
                }

                function addEditableColumn() {
                    let onChangeFn = function(cell, field) {
                        if (field.model.control.value != "1" && cell.row.cells["deletable"].model.control.value != 0) {
                            cell.row.cells["deletable"].api.setValue(0, false);
                        }
                    };
                    let config = {
                        onChange: onChangeFn
                    };

                    return addIconColumn("editable", "checkbox", "icon-16-edit", null, config);
                }

                function addDeletableColumn() {
                    let onChangeFn = function(cell, field) {
                        if (field.model.control.value == "1" && cell.row.cells["editable"].model.control.value != 1) {
                            cell.row.cells["editable"].api.setValue(1, false);
                        }
                    };
                    let config = {
                        onChange: onChangeFn
                    };

                    return addIconColumn("deletable", "checkbox", "icon-16-delete-dark", null, config);
                }

                function addIconColumn(title, type, icon, modelProps, columnConfig) {
                    let config = columnConfig || {};
                    config.icon = icon;
                    config.width = 60;

                    return addColumn(title, type, modelProps, config);
                }

                function addListColumn(title, type, nodes, isIcon) {
                    let modelProps = {
                        addon: "list",
                        tree: {
                            config: {
                                shortValue: false,
                                multiSelect: false,
                                intermediate: false,
                                sorted: false,
                                validate: true,
                                isIconCatalog: isIcon
                            },
                            nodes
                        }
                    };

                    return addColumn(title, type, modelProps);
                }

                function addColumn(title, type, modelProps, columnConfig, fieldProps) {
                    columnConfig = columnConfig || {};

                    columnConfig.index = title;
                    columnConfig.title = $filter("translate")(`eob.workflow.circulation.column.header.${title}`);
                    columnConfig.type = type;

                    let column = {
                        config: columnConfig,
                        fieldProps: fieldProps || {},
                        modelProps: modelProps || {}
                    };

                    columns[columnConfig.index] = column;

                    return column;
                }

                // endregion
                // region Templates

                function saveTemplate() {
                    formHelper.getRoutingListTemplates().then((templates) => {
                        let collisionPrivate = null;
                        let collisionPublic = null;

                        for (let i in templates) {
                            if (templates[i].model.name == scope.newTemplate.name) {
                                if (templates[i].model.isPublic) {
                                    collisionPublic = templates[i];
                                } else {
                                    collisionPrivate = templates[i];
                                }

                                break;
                            }
                        }

                        if (collisionPrivate != null) {
                            ModalDialogService.infoDialog($filter("translate")("eob.workflow.circulation.save"), $filter("translate")("eob.workflow.circulation.save.private.overwrite"), $filter("translate")("modal.button.yes"), $filter("translate")("modal.button.no")).then(() => {
                                saveTemplateOnServer(collisionPrivate);
                            }, () => {
                                showTemplateSaveModalDialog(scope.newTemplate.name, scope.newTemplate.public);
                            });
                        } else if (collisionPublic != null) {
                            ModalDialogService.infoDialog($filter("translate")("eob.workflow.circulation.save"), $filter("translate")("eob.workflow.circulation.save.public.overwrite"), $filter("translate")("modal.button.back")).then(() => {
                                showTemplateSaveModalDialog(scope.newTemplate.name, scope.newTemplate.public);
                            }, () => {
                                showTemplateSaveModalDialog(scope.newTemplate.name, scope.newTemplate.public);
                            });
                        } else {
                            saveTemplateOnServer(null);
                        }
                    }, (error) => {
                        // should not be happen because templates are already loaded.
                    });
                }

                function saveTemplateOnServer(templateToOverwrite) {
                    routingList.api.saveAsTemplate(scope.newTemplate.name, scope.newTemplate.public, templateToOverwrite).then((template) => {
                        NotificationsService.success($filter("translate")("eob.workflow.circulation.save.success"));
                        initTemplateSection();
                    }, () => {
                        NotificationsService.error($filter("translate")("eob.workflow.circulation.save.failed"));
                    });
                }

                function showTemplateSaveModalDialog(name, isPublic) {
                    FormService.validateForm(routingList.api.getFields(), false).then(() => {
                        scope.newTemplate.name = name;
                        scope.newTemplate.public = isPublic;

                        let modalScope = $rootScope.$new();
                        modalScope.newTemplate = scope.newTemplate;
                        modalScope.destroy = new Subject();

                        ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                            input: {
                                title: $filter("translate")("eob.workflow.circulation.template.save")
                            },
                            childElement: angular.element("<eob-modal-circulation-slip-save new-template='newTemplate' destroy='destroy'></eob-modal-circulation-slip-save>"),
                            scope: modalScope
                        });
                    }, () => {
                        NotificationsService.error($filter("translate")("eob.workflow.circulation.has.errors"));
                    });
                }

                function showTemplateMenu(template, event) {
                    if (template.model.id != void 0) {
                        MessageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                            contextData: {
                                context: "circulationSlip",
                                title: template.model.name
                            },
                            items: [template],
                            event: event.originalEvent
                        });
                    }
                }

                function moveTemplate(template) {
                    if (template.model.isPublic) {
                        template.api.personalize().then(() => {
                            initTemplateSection();
                            NotificationsService.success($filter("translate")("eob.workflow.circulation.personalize.success"));
                        }, () => {
                            NotificationsService.error($filter("translate")("eob.workflow.circulation.personalize.failed"));
                        });
                    } else {
                        template.api.publish().then(() => {
                            initTemplateSection();
                            NotificationsService.success($filter("translate")("eob.workflow.circulation.publish.success"));
                        }, () => {
                            NotificationsService.error($filter("translate")("eob.workflow.circulation.publish.failed"));
                        });
                    }
                }

                function deleteTemplate(template) {
                    template.api.delete().then(() => {
                        initTemplateSection();
                        NotificationsService.success($filter("translate")("eob.workflow.circulation.delete.success"));
                    }, () => {
                        NotificationsService.error($filter("translate")("eob.workflow.circulation.delete.failed"));
                    });
                }

                function toggleIncompatible() {
                    scope.incompatible.show = !scope.incompatible.show;
                    initTemplateSection();
                }

                function appendSelectedTemplate(template) {
                    if (template.model.id != void 0) {
                        if (routingList.model.expandable) {
                            if (scope.isPhone) {
                                showTemplates(false);
                                progressBar.show();
                                setTimeout(() => {
                                    progressBar.hide();
                                }, 100);
                            }
                            routingList.api.addList(template.model.routingList, true);
                        } else {
                            ModalDialogService.infoDialog($filter("translate")("eob.workflow.circulation.template.load"), $filter("translate")("eob.workflow.circulation.load.not.expandable"), null, $filter("translate")("modal.button.close"));
                        }
                    }
                }

                function isIncompatibleTemplate(template) {
                    return ((template.model.routingList != void 0) && (routingList.model.activityId != template.model.routingList.model.activityId));
                }

                function isDummyTemplate(template) {
                    return (template.model.id == void 0);
                }

                function showTemplates(show) {
                    if (scope.isPhone) {
                        let formFooter = angular.element(document.body).find("eob-form-footer");
                        if (show) {
                            scope.showTemplateSection = true;
                            $(".search-left-pane").attr("style", "display: none");
                            $(".search-divider").attr("style", "display: none");
                            $(".search-right-pane").attr("style", "width: 100%");

                            formFooter.hide();
                        } else {
                            scope.showTemplateSection = false;
                            $(".search-left-pane").attr("style", "display: block; right: 0");
                            $(".search-right-pane").attr("style", "display: none");

                            formFooter.show();
                        }
                    } else if (show) {
                            scope.showTemplateSection = true;
                            $(".search-left-pane").attr("style", "right: 25%");
                            $(".search-divider").attr("style", "right: 25%; width: 4px");
                            $(".search-right-pane").attr("style", "width: 25%");
                        } else {
                            scope.showTemplateSection = false;
                            $(".search-left-pane").attr("style", "right: 0");
                            $(".search-right-pane").attr("style", "width: 0px; overflow: hidden !important");
                        }
                }

                function initTemplateSection() {
                    let deferred = $q.defer();

                    formHelper.getRoutingListTemplates().then((templates) => {
                        scope.publicTemplates = [];
                        scope.privateTemplates = [];

                        templates.sort((a, b) => {
                            return a.model.name.localeCompare(b.model.name);
                        });

                        let noTemplateAvailable = {
                            model: {
                                name: $filter("translate")("eob.workflow.circulation.not.available"),
                            }
                        };

                        for (let i in templates) {
                            if ((scope.incompatible.show) || (routingList.model.activityId == templates[i].model.routingList.model.activityId)) {
                                if (templates[i].model.isPublic) {
                                    scope.publicTemplates.push(templates[i]);
                                } else {
                                    scope.privateTemplates.push(templates[i]);
                                }
                            }
                        }

                        if (scope.publicTemplates.length == 0) {
                            scope.publicTemplates.push(noTemplateAvailable);
                        }

                        if (scope.privateTemplates.length == 0) {
                            scope.privateTemplates.push(noTemplateAvailable);
                        }

                        deferred.resolve();
                    }, (error) => {
                        deferred.reject(error);
                    });

                    return deferred.promise;
                }

                function addGroup() {
                    showTemplates(false);
                    scope.$broadcast("add.new.group", true);
                }

                // endregion
            },
            template: require("!raw-loader!./eob.workflow.circulation.html")
        }
    }
})();
