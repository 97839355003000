import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {DmsMessageType} from "MODULES_PATH/dms/enums/dms-message-type.enum";
import {forkJoin, from, of} from "rxjs";
import {catchError, map, switchMap, take, toArray} from "rxjs/operators";
import {DmsDocument} from "../../app/modules/dms/models/dms-document"
import {Broadcasts} from "../../app/shared/enums/broadcasts.enum"
import {ProfileCacheKey} from "ENUMS_PATH/database/profile-cache-key.enum";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";
import {registerLocaleData} from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import localeFr from "@angular/common/locales/fr";
import {Languages} from "ENUMS_PATH/languages.enum";
import {environment} from "../../environments/environment";
import {FeatureSet} from "../../eob-model/eob.feature.set.model";
import {GlobalSystemSettings} from "ENUMS_PATH/global-system-settings.enum";
import {cloneDeep} from "lodash";

require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");

require("SERVICES_PATH/viewer/detailsviewer/dv.environment.srv.js");

angular.module("eob.core").factory("environmentService", EnvironmentService);

EnvironmentService.$inject = ["$injector", "$q", "$timeout", "$filter", "$eobConfig", "$rootScope", "$compile", "notificationsService",
    "backendService", "configMappingOldBackend", "configMappingMicroservice", "stateHistoryManager", "errorModelService", "clientService",
    "organisationService", "themeService", "iconService", "dvEnvironmentService", "messageService", "$stateParams",
    "modalDialogInjectorService", "httpService", "backendResourceService", "toolService"];

export default function EnvironmentService($injector, $q, $timeout, $filter, $eobConfig, $rootScope, $compile, NotificationsService,
                                           BackendService, configMappingOldBackend, configMappingMicroservice, StateHistoryManager, ErrorModelService, ClientService,
                                           OrganisationService, ThemeService, IconService, DvEnvironmentService, MessageService,
                                           $stateParams, ModalDialogInjectorService, httpService, BackendResourceService, ToolService) {

    let startableWorkflows = [],
        showBaseParameter,
        isObjectLinkNoteEnabled,
        dropzoneContent,
        sessionInfo,
        serviceInfo,
        mimetypes = [],
        emsConfiguration = null,
        emsTypes,
        productVersion,
        langIndex,
        fallbackLangIndex,
        languages,
        templates = [],
        roles,
        licenseRenewFailedCount = 0,
        clipboardCacheListenerGuid = null,
        canChangeObjDefLanguage = false,
        objDefLanguage,
        objDefLangIsUILang = true,
        env = {
            actions: {},
            form: {},
            desktop: {},
            export: {},
            import: {},
            location: {},
            email: {},
            hitlist: {},
            layout: {},
            externalTools: [],
            dashlets: []
        },
        AsIniService;

    let service = {
        getAllowedFileExtensions,
        getAllowedFileExtByMainType,
        getLocalizedFieldInfo,
        getLocalizedInfo,
        getStartableWorkflows,
        clearDropzoneContent,
        isFulltextAvailable,
        isDesktopAppDownloadDisabled,
        isDesktopAppDebuggingEnabled,
        isMicroserviceBackend,
        getDropzoneContent,
        setDropzoneContent,
        getProductVersion,
        getClientVersion,
        useDocumentViewer,
        getMobileSettings,
        setMobileSettings,
        isAllowedObjectTypes,
        setDateFormats,
        isBaseParameterAvailable,
        isObjectLinkNoteAllowed,
        isWorkflowUser,
        setDashlets,
        getObjecttypeFilteredDashlets,
        getSessionInfo,
        setSessionInfo,
        getServiceInfo,
        clearClipboard,
        addToClipboard,
        getClipboard,
        setLanguages,
        getLanguages,
        getObjectDefinitionLanguage,
        uiLangIsObjectDefLang,
        getUserRoles,
        getTemplates,
        getMimetypeById,
        getEmsConfiguration,
        getEmsTypeByInternal,
        getMatchedEmsTypeObjectIds,
        checkAbsenceAsync,
        getLanguage,
        getThousandSeparator,
        userHasRole,
        initAsync,
        fetchProductVersionAsync,
        getCreationMode,
        isO365Enabled,
        getClientType,
        getMatchedEmsTypeObject,
        env,
        canChangeObjDefLanguage: () => canChangeObjDefLanguage,
        featureSet: new FeatureSet()
    };

    MessageService.subscribe(DmsMessageType.DMSOBJECT_DELETED, (removedIds) => {
        let clipboard = service.getClipboard();

        if (clipboard == null) {
            return;
        }

        if (!Array.isArray(removedIds)) {
            removedIds = [removedIds];
        }

        for (let id of removedIds) {
            if (id == clipboard.item.model.id) {
                service.clearClipboard();
                return;
            }
        }
    });

    return service;

    async function initAsync() {
        AsIniService = $injector.get("asIniService");
        // first check the roles if we are allowed to start the webclient
        // and get the sessioninfo to initAsync the localstorage
        await fetchUserRolesAsync();
        await fetchSessionInfoAsync();
        objDefLanguage = sessionInfo.languageObjDef || getLanguage();
        if (sessionInfo.languageObjDef) {
            canChangeObjDefLanguage = true;
        }

        objDefLangIsUILang = (objDefLanguage == getLanguage());
        // (do not use Promise.all() for these two promises to avoid async problems while setting authentication cookies in Electron)

        if (userHasRole("R_WEBCLNT_START")) {
            // get the licence
            await fetchLicense(false);
            await fetchFeatureSet();

            // initialize the remaining ressources
            await getGlobalConfig();
            await fetchServiceInfoAsync();
            await fetchMimetypesAsync();
            await fetchEmsConfigurationAsync();
            await fetchEmsTypesAsync();

            showBaseParameter = await isGlobalSystemSetting(GlobalSystemSettings.SHOW_BASEPARAMETER);
            isObjectLinkNoteEnabled = await isGlobalSystemSetting(GlobalSystemSettings.NOTE_LINK_STORAGE_ENABLED);

            setTheme();
            setLocaleResources();

            // the client type must be set, before we request the startable workflows
            service.wfClientType = `${`web_${objDefLanguage}`}`;

            if (isWorkflowUser()) {
                fetchStartableWorkflowsAsync();
                OrganisationService.fetchWfOrganisation().subscribe();
            }

            await OrganisationService.fetchOrganisationUsers().toPromise();
            await OrganisationService.fetchOrganisationGroups().toPromise();

            fetchTemplatesAsync();
            DvEnvironmentService.initAsync();

            clearDropzoneContent();
            service.viewerPromise = new Promise(resolve => setTimeout(resolve, 1500));

            AsIniService.setSavingAllowed(roles.indexOf("R_CLNT_STORE_SETTINGS") != -1);

            if (ClientService.isDesktop() && window.electron) {
                isDesktopAppDebuggingEnabled() ? window.electron.enableDeveloperConsole() : window.electron.disableDeveloperConsole();
            }

            if (ClientService.isLocalClient() && window.webviewId == "webclient") {
                doDelayedFavoritesSynchronization();
            }

            const userGroupsFilter = (arrayToFilter) => {
                const filteredResult = [];

                arrayToFilter.forEach(val => {
                    if (Array.isArray(val.groups) && val.groups.map(x => x.toUpperCase()).some(x => sessionInfo.groups.includes(x))) {
                        filteredResult.push(val);
                    } else if (Array.isArray(val.users) && val.users.map(x => x.toUpperCase()).includes(sessionInfo.username)) {
                        filteredResult.push(val);
                    } else if (!Array.isArray(val.users) && !Array.isArray(val.groups)) {
                        filteredResult.push(val);
                    }
                });
                return filteredResult;
            };

            const platformsFilter = (arrayToFilter) => {
                const filteredResult = [];

                arrayToFilter.forEach(val => {
                    if (Array.isArray(val.platforms)) {
                        const platforms = val.platforms.map(x => x.toLowerCase());
                        if (ClientService.isMobile()) {
                            if (ClientService.isLocalClient()) {
                                if (platforms.includes("mobile_app")) {
                                    filteredResult.push(val);
                                }
                            } else if (platforms.includes("mobile")) {
                                filteredResult.push(val);
                            }
                        } else if (ClientService.isDesktop()) {
                            if (platforms.includes("desktop_app")) {
                                filteredResult.push(val);
                            }
                        } else if (platforms.includes("web")) {
                            filteredResult.push(val);
                        }
                    }
                });

                return filteredResult;
            }

            const externalResources$ = {};
            if (service.featureSet.contains("dashlets") && !ClientService.isDetached()) {
                externalResources$.dashlets = BackendResourceService.getDashlets().pipe(
                    map(result => userGroupsFilter(result)),
                    map(result => platformsFilter(result)),
                    map(result => {
                        result.forEach(val => val.id = `customDashlet-${ToolService.createGUID()}`)
                        return result;
                    }),
                    catchError(error => {
                        console.error(`Error while retrieving dashlets: ${error}`);
                        return of([]);
                    })
                );
            }

            externalResources$.externalTools = BackendResourceService.getExternalTools().pipe(
                map(result => userGroupsFilter(result)),
                catchError(error => {
                    console.error(`Error while retrieving external tools: ${error}`);
                    return of([]);
                })
            );

            void await forkJoin(externalResources$).pipe(
                switchMap(res => {
                    const obs = [];

                    if (res.dashlets) {
                        this.env.dashlets = res.dashlets;
                        obs.push(from(IconService.processIcons(res.dashlets.map(y => y.iconId).filter(y => y != void 0))));
                    }

                    if (res.externalTools) {
                        this.env.externalTools = res.externalTools;
                        obs.push(from(IconService.processIcons(res.externalTools.map(y => y.iconId).filter(y => y != void 0))));
                    }

                    return forkJoin(...obs).pipe(toArray(), map(x => res));
                })
            ).toPromise();

            // Not so great temporary workaround to provide the entire environment service to the OSRest driver, please don't judge
            MessageService.broadcast(Broadcasts.ENVIRONMENT_INITIALIZED, Object.assign(Object.assign({}, this.env), this));
        } else {
            // no role to see the client --> reject
            throw ErrorModelService.createCustomError("WEB_MISSING_ROLE_FOR_WEBCLIENT_USAGE");
        }
    }

    /**
     * Triggers the favorites synchronization process for offline usage. The trigger is
     * delayed for one minute.
     * @access private
     */
    function doDelayedFavoritesSynchronization() {
        $timeout(() => {
            let OfflineService = $injector.get("offlineService");
            OfflineService.synchronizeAsync(true);
        }, 30000);
    }

    function getAllowedFileExtensions() {
        return this.env.import;
    }

    function getAllowedFileExtByMainType(mainType) {
        return this.env.import[mainType] || "";
    }

    function setLanguages(langs) {
        languages = langs;
    }

    function getLanguages() {
        return languages;
    }

    function getObjectDefinitionLanguage() {
        return objDefLanguage;
    }

    /**
     * Returns true if object definition language and UI language are the same
     *
     * @returns {Boolean} Object def lang is the same as UI lang
     */
    function uiLangIsObjectDefLang() {
        return objDefLangIsUILang;
    }

    function useDocumentViewer() {
        if (ClientService.isPhoneOrTablet()) {
            return false;
        }

        return serviceInfo.services.documentpreview != void 0 && serviceInfo.services.documentpreview.indexOf("documentviewer") != -1;
    }

    function setLocaleResources() {
        let locale = localeDe;
        const currentLang = getLanguage();
        switch (currentLang) {
            case "en":
                locale = localeEn;
                break;
            case "de":
                locale = localeDe;
                break;
            case "fr":
                locale = localeFr;
                break;
            default:
                break;
        }

        registerLocaleData(locale);
    }

    function isWorkflowUser() {
        return env.actions.useWorkflow && userHasRole("R_WFCLNT_USE") && sessionInfo.wfGuid != "" && sessionInfo.wfGuid != void 0;
    }

    function isFulltextAvailable() {
        let fullTextInfo = serviceInfo.capabilities.capabilities.find(element => {return element.name === "Fulltext"});
        let isFullTextLoaded = fullTextInfo ? fullTextInfo.defaults[0].value === "true" : false;
        return env.useFulltext && userHasRole("R_CLNT_EXECUTE_INQUIRY") && serviceInfo.services.fulltext != void 0 && isFullTextLoaded;
    }

    function isDesktopAppDownloadDisabled() {
        return (isMicroserviceBackend()) ? !env.enableDesktopAppDownload : env.disableDesktopAppDownload;
    }

    function isDesktopAppDebuggingEnabled() {
        return env.enableDesktopAppDebugging;
    }

    function isMicroserviceBackend() {
        return env.microserviceBackend;
    }

    function setDateFormats() {
        getDateFormats();
    }

    function setDashlets(dashlets) {
        this.env.dashlets = dashlets;
    }

    function getObjecttypeFilteredDashlets(selectedOsids) {
        return objecttypeFilter(this.env.dashlets, selectedOsids);
    }

    function objecttypeFilter(arrayToFilter, selectedOsids) {
        const CacheManagerService = $injector.get("cacheManagerService");
        const platform = ClientService.getPlatformNameAsString();
        const selectedEntries = CacheManagerService.dmsDocuments.get(selectedOsids);

        const filteredDashlets = arrayToFilter.filter(dashlet => {
            if (!Array.isArray(dashlet.platforms) || (!Array.isArray(dashlet.objectTypes) && dashlet.objectTypes != "*") || !dashlet.platforms.includes(platform)) {
                return false;
            }

            if (Array.isArray(dashlet.objectTypes)) {
                const sanitizedObjTypes = dashlet.objectTypes.map(objtype => objtype.toLowerCase());

                return selectedEntries.every(dmsDoc => sanitizedObjTypes.includes(dmsDoc.model.internal.toLowerCase()));
            }

            return true;
        });

        return filteredDashlets;
    }

    function getStartableWorkflows() {
        return startableWorkflows;
    }

    function getUserRoles() {
        return roles;
    }

    function getSessionInfo() {
        return sessionInfo || {};
    }

    function setSessionInfo(info) {
        sessionInfo = info;
    }

    function getServiceInfo() {
        return serviceInfo || {};
    }

    function getProductVersion() {
        return productVersion;
    }

    function getClientVersion() {
        if (ClientService.isMobile() || ClientService.isLocalClient()) {
            // environment.clientVersion global variable is set in base-environment.js to present
            // client version from package.json as well as build timestamp and commit hash of that version
            // in the info modal dialog. For enaio mobile the cordova hook "set_build_number.js" writes
            // the version number from config.xml to package.json.
            // eslint-disable-next-line no-undef
            return `${environment.clientVersion}`;
        } else {
            return getProductVersion();
        }
    }

    function userHasRole(role) {
        return roles.indexOf(role) != -1;
    }

    function getLanguage() {
        // For unknown reasons, in some tests the initialization call  falls short of setting that service reference
        if (!AsIniService) {
            AsIniService = $injector.get("asIniService");
        }
        return AsIniService.getGuiLanguage();
    }

    function getTemplates(objectTypeId) {
        let res = [];

        for (let i in templates) {
            if (templates[i].objectType == objectTypeId) {
                res.push(templates[i]);
            }
        }

        return res;
    }

    /**
     * Looks up for a mime type in the cached mime type list from the server.
     * @param {string | number} mimetypeId - ID of the mimetype in question.
     * @return {Object} mimetype - The result or undefined otherwise.
     */
    function getMimetypeById(mimetypeId) {
        return (mimetypes || []).find(mimetype => mimetype.mimetypeid == mimetypeId);
    }

    /**
     * Get EMS configuration
     * @return {Object} configuration - EMS configuration or null, if feature is disabled
     */
    function getEmsConfiguration() {
        return emsConfiguration;
    }

    /**
     * Get EMS type by internal name
     * @param internal - the internal name
     * @returns {null} - type, if found, otherwise null
     */
    function getEmsTypeByInternal(internal) {
        if (emsTypes == void 0) {
            return null;
        }

        for (let type of emsTypes) {
            if (type.ecmType.internal_name === internal) {
                return type;
            }
        }

        return null;
    }

    /**
     * Get all matched EMS type ids
     * @returns {null} - types, if found, otherwise null or empty
     */
    function getMatchedEmsTypeObjectIds(ids) {
        if (emsTypes == void 0) {
            return null;
        }
        let objectTypeIds = [];
        for (let i = 0; i < ids.length; i++) {
            for (let type of emsTypes) {
                if (ids[i] === type.ecmType.oid.toString()) {
                    objectTypeIds.push(type.ecmType.oid);
                }
            }
        }
        return objectTypeIds;
    }

    function isBaseParameterAvailable() {
        return showBaseParameter;
    }

    async function isGlobalSystemSetting(settingName) {
        let json = {
            path: "%ETCPATH%\\as.cfg",
            section: "System",
            value: settingName
        };

        let result = false;
        try {
            let response = await BackendService.post("/serviceinfo/systemConfigValue", json);
            if (response && response.data && response.data[settingName] === 1) {
                result = true;
            }
        } catch (error) {
            // error ignored
        }

        return result;
    }

    function isObjectLinkNoteAllowed() {
        return isObjectLinkNoteEnabled;
    }

    async function fetchStartableWorkflowsAsync() {
        startableWorkflows = await httpService.getStartableWorkflowModels(service.wfClientType).toPromise();
        startableWorkflows.sort((a, b) => {
            let strA = a.title.toLowerCase(), strB = b.title.toLowerCase();
            return strA === strB ? 0 : (strA < strB ? -1 : 1);
        });

        let workflowIcons = [];
        for (let wf of startableWorkflows) {
            if (wf.iconId != "0" && wf.iconId != void 0) {
                workflowIcons.push(Number(wf.iconId))
            }
        }

        if (workflowIcons.length > 0) {
            IconService.processIcons(workflowIcons)
        }
    }

    async function fetchUserRolesAsync() {
        try {
            let response = await BackendService.cachedGetAsync("/session/systemroles", ProfileCacheKey.USER_ROLES);
            roles = response.data;
        } catch (error) {
            initErrorHandler(null, error);
        }
    }

    async function fetchTemplatesAsync() {
        let response = await BackendService.cachedGetAsync("/documents/templates", ProfileCacheKey.TEMPLATES);
        templates = (response.data || []).filter(template => template.fileName != undefined && template.fileName != "");
        return response;
    }

    async function fetchSessionInfoAsync() {
        try {
            let response = await BackendService.cachedGetAsync("/session?refresh=true", ProfileCacheKey.SESSION_INFO);
            sessionInfo = response.data;
            StateHistoryManager.init(sessionInfo.userid);
        } catch (error) {
            initErrorHandler(null, error);
        }
    }

    /**
     * Initially fetch the feature set for (mobile) feature versioning from the backend.
     * @returns {Promise<void>} Resolved once the feature set is fetched.
     */
    async function fetchFeatureSet() {
        let response = {};

        try {
            response = await BackendService.cachedGetAsync("/serviceinfo/featureset", GlobalCacheKey.FEATURE_SET);
        } catch (error) {
            if ((error || {}).status === 404) {
                console.warn("The feature set is not available! An AppConnector update might be necessary.")
            } else {
                throw error;
            }
        }

        service.featureSet = new FeatureSet(response.data || {});
        MessageService.broadcast(Broadcasts.FEATURESET_RECEIVED, service.featureSet)
    }

    async function fetchServiceInfoAsync() {
        try {
            let response = await BackendService.cachedGetAsync("/serviceinfo", GlobalCacheKey.SERVICE_INFO);
            serviceInfo = response.data;

            if (ClientService.isLocalClient()) {
                checkServerVersionForLocalClient(serviceInfo);
            }

            env.gatewayURL = serviceInfo.services.gateway;
            env.oswebURL = serviceInfo.services.osweb;
            env.contentViewerURL = serviceInfo.services.contentviewer;
            env.useDocumentViewer = serviceInfo.services.documentviewer != void 0 && serviceInfo.services.documentviewer.indexOf("osdocumentviewer");
            env.usePdfViewer = serviceInfo.services.documentviewer != void 0 && serviceInfo.services.documentviewer.indexOf("pdfjs");

            return serviceInfo;
        } catch (error) {
            initErrorHandler(null, error);
        }
    }

    /**
     * Checks the major server version against the value of 9.
     *
     * @access private
     * @param {object} serviceInfo - A service info object
     * @throws {Object} CustomError - In case the comparison fails.
     */
    function checkServerVersionForLocalClient(serviceInfo) {
        let serverVersion = parseInt(serviceInfo.serverVersion.split(".")[0]);
        if (serverVersion < 9) {
            throw ErrorModelService.createCustomError("LOCAL_CLIENT_SERVER_VERSION_MISMATCH");
        }
    }

    /**
     * Sets the color theme for client.
     *
     * @access public
     * @param {object} theme - the color theme that should be used
     */
    function setTheme(theme = "") {
        theme = theme != "" ? theme : AsIniService.getLayoutConfiguration().colorTheme || "standard";
        let accentColor = AsIniService.getLayoutConfiguration().accentColor || "#0092e1";

        //inform electron tabs which theme they should use
        ClientService.setColorTheme(theme);
        ThemeService.setTheme(theme, accentColor);

        require.ensure([], () => {
            require("style-loader!STYLING_PATH/sass/_app.scss");
        });

        //style status bar of mobile app
        if (ClientService.isMobile()) {
            StatusBar.overlaysWebView(false);
            if (theme == "dark") {
                StatusBar.backgroundColorByHexString("#171717");
                StatusBar.styleLightContent();
            } else {
                StatusBar.backgroundColorByHexString("#f1f1f1");
                StatusBar.styleDefault();
            }
            StatusBar.show();
        }
    }

    function getCreationMode() {
        return $stateParams.mode;
    }

    async function fetchProductVersionAsync() {
        try {
            let response = await BackendService.cachedGetAsync("/rest/util/version", GlobalCacheKey.PRODUCT_VERSION, $eobConfig.getOswebBase());

            if (typeof response.data === "string") {
                productVersion = response.data;
            } else {
                productVersion = `${response.data.buildVersion} (Build ${response.data.buildDate})`;
            }

            MessageService.broadcast(Broadcasts.PRODUCT_VERSION_RECEIVED, productVersion);
            return response;
        } catch (error) {
            // We do not wait for that information while starting the webclient. Therefore no reject.
            productVersion = $filter("translate")("eob.version.number.load.error");
        }
    }

    async function fetchEmsConfigurationAsync() {
        try {
            if (service.featureSet.contains("ems")) {
                let response = await BackendService.cachedGetAsync("/maintenance/report", GlobalCacheKey.EMS_CONFIGURATION, "/ems");
                emsConfiguration = response.data.application.configuration;
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchEmsTypesAsync() {
        try {
            if (service.featureSet.contains("ems")) {
                let response = await BackendService.cachedGetAsync("/api/types?includeObjectType=true", GlobalCacheKey.EMS_TYPES, "/ems");
                emsTypes = response.data;
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchMimetypesAsync() {
        try {
            if (service.featureSet.contains("objectDefinition.mimetype")) {
                let response = await BackendService.cachedGetAsync("/serviceinfo/mimetypes", GlobalCacheKey.MIMETYPES);
                mimetypes = response.data.OsMimetypes.OsMimetype;
            }
        } catch (error) {
            console.error(error);
        }
    }

    function fetchLicense(fromRenewTimer) {
        return new Promise((resolve, reject) => {
            let licenseKey = localStorage.getItem("licenseKey");

            if (ClientService.isOffline() && licenseKey == null && !fromRenewTimer) {
                reject(ErrorModelService.createCustomError("WEB_LICENSE_SERVICE_NOT_AVAILABLE"));
                return;
            }

            if (!fromRenewTimer) {
                setInterval(() => {
                    fetchLicense(true).catch((errorMessage) => {
                        NotificationsService.error(errorMessage, null, 15000);
                    });
                }, 30000);
            }

            if (licenseKey == null) {
                // Update, remove old storage. Remove this in enaio 10
                localStorage.removeItem("licenses");
                licenseKey = "489ad4ea-37bf-46a4-b3d2-ec1de41589e4"; // Dummy, will be changed by license service
            }

            if (ClientService.isOnline()) {
                // eslint-disable-next-line promise/catch-or-return,promise/prefer-await-to-then
                BackendService.get(`/api/simple/renew/WEB/${licenseKey}?forceAcquire=true`, "/license").then((response) => {
                    licenseRenewFailedCount = 0;

                    if (licenseKey != response.data.userKey) {
                        localStorage.setItem("licenseKey", response.data.userKey);
                    }

                    resolve();

                    return;
                }).catch((error) => {
                    licenseRenewFailedCount++;

                    // no more licenses
                    // TODO: Microserice... are the error objects identically to rest V2? If not talk to mr. dunkel himself :)
                    if (!fromRenewTimer || (licenseRenewFailedCount % 10) == 0) {
                        if (error.status == "409") {
                            reject(ErrorModelService.createCustomError("WEB_LICENSE_OUT_OF_LICENSES"));
                        } else {
                            reject(ErrorModelService.createCustomError("WEB_LICENSE_SERVICE_NOT_AVAILABLE"));
                        }
                    } else {
                        resolve(); // only every 10th time we throw a error.
                    }
                });
            } else {
                resolve(); // We have a license from earlier and we are offline. It's ok...
            }
        })
    }

    async function getGlobalConfig() {
        try {
            let response = await BackendService.cachedGetAsync("/rest/clientConfig", GlobalCacheKey.OS_WEB_PROPERTIES, $eobConfig.getOswebBase());
            let data = response.data;
            let configMapping = configMappingOldBackend;

            // The test could be done with all featureSwitches.*. Once one of them is present
            // the microservice backend is used and also the dms microservice.
            if (data["featureSwitches.search"] != void 0) {
                configMapping = configMappingMicroservice;
                env.microserviceBackend = true;
            }

            for (let i in data) {
                // iterate each property and check if we will use this
                // see the config inside the app.js
                // skip the items we will not map, these properties are mostly no longer supported
                if (!configMapping[i]) {
                    continue;
                }

                let path = configMapping[i].split(".");
                let hasNext = true;
                let currentBranch = env;

                // convert yes or no values to boolean or use the original value if yes or no are not the value
                let value = (data[i] == "yes" || data[i] == "no") ? data[i] == "yes" : data[i];

                // build a javascript object literal from a path like a.b.c --> {a : { b : c}}
                while (hasNext) {
                    if (!path[1]) {
                        hasNext = false;
                        currentBranch[path[0]] = value;
                    } else {
                        if (!currentBranch[path[0]]) {
                            currentBranch[path[0]] = {};
                        }

                        currentBranch = currentBranch[path[0]];
                        path.splice(0, 1);
                    }
                }
            }
            // legacy band-aid in case someone queries allowed file extensions for non-mapped main type 6
            env.import["6"] = "EML";
            return response;
        } catch (error) {
            initErrorHandler(null, error);
        }
    }

    function getDateFormats() {
        env.dateFormat = {
            date: $filter("translate")("eob.dateFormat.date"),
            datetime: $filter("translate")("eob.dateFormat.datetime"),
            time: $filter("translate")("eob.dateFormat.time")
        };
    }

    function getLocalizedInfo(data, infoKey) {
        let localDataObject = {};
        let currentLang = getObjectDefinitionLanguage();

        if (!Array.isArray(data)) {
            data = [data];
        }

        let fallbackLanguageId = null,
            languageId = null;

        for (let i in languages) {
            let language = languages[i];

            if (language.active == "1" || language.active == "2") {
                if (language.active == "2") {
                    if (languageId == void 0) {
                        languageId = language.lang_id;
                    }

                    fallbackLanguageId = language.lang_id;
                } else if (Languages[language.lang_id] === currentLang) {
                    languageId = language.lang_id;
                    break;
                }
            }
        }

        for (let i in data) {
            let localObject = data[i];

            if (localObject.lang_id == languageId) {
                localDataObject = localObject;
                break;
            }

            if (localObject.lang_id == fallbackLanguageId) {
                fallbackLangIndex = i;
                localDataObject = localObject;
            }
        }

        switch (infoKey) {
            case "icon":
                return localDataObject["imageid"];
            case "tooltip":
                return localDataObject["tooltip"];
            default:
                return localDataObject["content"];
        }
    }

    function getLocalizedFieldInfo(titleArray, field, fieldInfoName) {
        let langIndex = getCurrentLanguageId(field);
        let key = "";

        switch (fieldInfoName) {
            case "icon":
                key = "imageid";
                break;
            case "tooltip":
                key = "tooltip";
                break;
            default:
                key = "content";
        }

        if (!Array.isArray(titleArray)) {
            titleArray = [titleArray];
        }

        if (titleArray[langIndex] == void 0) {
            return titleArray[0][key];
        }

        return titleArray[langIndex][key];
    }

    function getCurrentLanguageId(field) {
        if (!Array.isArray(field.names.name)) {
            field.names.name = [field.names.name];
            return 0;
        }

        if (!langIndex) {
            let tmpId, tmpFallbackId;

            for (let i in languages) {
                if (languages[i].active == "1" || languages[i].active == "2") {
                    if (languages[i].active == "2") {
                        if (tmpId == void 0) {
                            tmpId = languages[i].lang_id;
                        }

                        tmpFallbackId = languages[i].lang_id;
                    } else if (getObjectDefinitionLanguage() === Languages[languages[i].lang_id]) {
                        tmpId = languages[i].lang_id;
                        break;
                    }
                }
            }

            for (let i in field.names.name) {
                if (field.names.name[i].lang_id == tmpId) {
                    langIndex = i;
                } else if (field.names.name[i].lang_id == tmpFallbackId) {
                    fallbackLangIndex = i;
                }
            }
        }

        return field.names.name[langIndex] == void 0 || field.names.name[langIndex].content == void 0 ? fallbackLangIndex : langIndex;
    }

    /**
     * Adds a item to the clipboard and replaces a old one if there is one in the clipboard.
     * There will also be added a listener because the item is in use and should not be removed
     * from the cache while it is in the clipboard.
     *
     * @param {object} item - The item we add to the clipboard. Can be dmsDocument or desktop item
     * @param {string} clipboardAction - The desired action the user wants to execute on this item
     * @param {int} parentId - An optional parentId
     */
    function addToClipboard(item, clipboardAction, parentId) {
        let CacheManagerService = $injector.get("cacheManagerService");
        if (item instanceof DmsDocument) {
            item = { model: item.model }
        }
        if (clipboardCacheListenerGuid != null) {
            CacheManagerService.dmsDocuments.detachListeners(clipboardCacheListenerGuid);
        }

        if (item.model != void 0 && item.model.objectTypeId != void 0) {
            clipboardCacheListenerGuid = CacheManagerService.dmsDocuments.attachListener(item.model.osid, (items) => {
                let { model } = CacheManagerService.dmsDocuments.getById(items[0]);
                let clipboardContent = getClipboard();
                if (!clipboardContent) {
                    CacheManagerService.dmsDocuments.detachListeners(clipboardCacheListenerGuid)
                    clipboardCacheListenerGuid = null;
                } else {
                    addToClipboardInternal({ model }, clipboardContent.clipboardAction, clipboardContent.parentId);
                }

            });
        } else {
            clipboardCacheListenerGuid = null;
        }
        addToClipboardInternal(item, clipboardAction, parentId);
    }

    /**
     *
     *
     * @param item
     * @param clipboardAction
     * @param parentId
     */
    function addToClipboardInternal(item, clipboardAction, parentId) {
        const clipboardItem = cloneDeep(item)
        if (Array.isArray(clipboardItem.links)) {
            clipboardItem.links.forEach(x => delete x.dmsDocument)
        }
        localStorage.setItem("clipboard", JSON.stringify({
            item: clipboardItem,
            parentId,
            clipboardAction
        }));
    }

    function clearClipboard() {
        if (clipboardCacheListenerGuid != null) {
            let CacheManagerService = $injector.get("cacheManagerService");
            CacheManagerService.dmsDocuments.detachListeners(clipboardCacheListenerGuid);
            clipboardCacheListenerGuid = null;
        }

        localStorage.removeItem("clipboard");
    }

    function getClipboard() {
        try {
            return JSON.parse(localStorage.getItem("clipboard"));
        } catch (error) {
            console.warn(`Parse error: ${error.message}\nInvalid clipboard contents in localStorage: ${localStorage.getItem("clipboard")}`);
            return null;
        }
    }

    function clearDropzoneContent() {
        dropzoneContent = {
            scriptObject: {},
            files: [],
            mode: "",
            item: {},
            template: "",
            templateConfiguration: {}
        };
    }

    function getDropzoneContent() {
        return dropzoneContent;
    }

    function setDropzoneContent(scriptObject, files, mode, item, template, templateConfiguration) {
        dropzoneContent = {
            scriptObject,
            files,
            mode,
            item,
            template,
            templateConfiguration
        };
    }

    function checkAbsenceAsync() {
        // TODO: Refactor modal dialogs to async-await
        let deferred = $q.defer();

        if (sessionInfo.isWorkflowAbsent && !window.isPopupWindow && ClientService.isOnline()) {
            let modalScope = $rootScope.$new();

            let title = $filter("translate")("modal.confirm.reposrt.presence.title");
            modalScope.msg = $filter("translate")("modal.confirm.reposrt.presence.message");
            modalScope.buttons = {
                cancel: $filter("translate")("modal.button.no"),
                submit: $filter("translate")("modal.button.yes")
            };

            const modalContainer$ = ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                input: {
                    title,
                    rejection: true
                },
                childElement: angular.element("<eob-modal-confirm msg=\"msg\" buttons=\"buttons\"></eob-modal-confirm>"),
                scope: modalScope
            });

            $rootScope.$apply();

            modalContainer$.pipe(take(1)).subscribe(_ => {
                // eslint-disable-next-line promise/catch-or-return,promise/prefer-await-to-then
                BackendService.get("/workflows/absence/false").then(() => {
                    sessionInfo.isWorkflowAbsent = false;
                    NotificationsService.success($filter("translate")("eob.app.bar.usermenu.workflow.report.present"));
                    deferred.resolve();
                    return;
                }).catch((error) => {
                    // TODO: Errorhandling
                    deferred.resolve();
                });
            }, error => {
                console.warn(error);
                deferred.resolve();
            });
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

    function initErrorHandler(deferred, error) {
        // TODO: Fehlerbehandlung sukzessive verbessern. Irgendwann sollte dies hier überflüssig sein
        // TODO: und nur noch deferred.reject(error); ausreichen. Dann steht der Fehler auch detaillierter
        // TODO: auf der GUI.
        let errorMsg = error.message || error.toString();
        console.error(errorMsg);

        let customError;
        if (error.statusCode === 400 && error.type != void 0) {
            customError = error;
        } else {
            let customErrorKey = (error.status == 403) ? "WEB_MISSING_ROLE_FOR_WEBCLIENT_USAGE" : "WEB_APPCONNECTOR_GENERAL_ERROR";
            customError = ErrorModelService.createCustomError(customErrorKey);
        }

        if (deferred != void 0) {
            deferred.reject(customError);
        } else {
            throw customError;
        }
    }

    /**
     * Returns mobile settings. If there are no saved settings inside the Local Storage, new settings are saved and returned.
     *
     * Parameters:
     * - autofav: yes, no, ask
     * - favCacheDepth: 0 = no synchronization, 1 = sync only favorites, 2 = favorites and one subfolder, etc.
     * - favCacheSyncOnMobile: Whether the favorites should be synchronized over a mobile network
     * - downloadOriginalDoc: Whether original documents should also be downloaded
     * @returns {{autofav: string, favCacheDepth: number, favCacheSyncOnMobile: boolean, downloadOriginalDoc: boolean}}
     */
    function getMobileSettings() {
        let mobileSettings = {
            autofav: "ask",
            favCacheDepth: 0,
            favCacheSyncOnMobile: false,
            downloadOriginalDoc: false
        };

        if (!localStorage.getItem("mobileSettings")) {
            localStorage.setItem("mobileSettings", JSON.stringify(mobileSettings));
            return mobileSettings;
        } else {
            try {
                return JSON.parse(localStorage.getItem("mobileSettings"));
            } catch (err) {
                console.info(`Corrupted settings: ${err}`);
                localStorage.setItem("mobileSettings", JSON.stringify(mobileSettings));
                return JSON.parse(localStorage.getItem("mobileSettings"));
            }
        }
    }

    function setMobileSettings(settings) {
        if (Object.keys(settings).length < 1) {
            console.info("Invalid settings, not saved.");
        } else {
            localStorage.setItem("mobileSettings", JSON.stringify(settings));
        }
    }

    /**
     * Check whether an array of object types can be started by a given workflow.
     * @param {Array} objectTypes - object types to be checked
     * @param {String} workflowId - GUID of the workflow model to check against
     * @return {boolean} isAllowed - whether the object types can be started by the worklow
     */
    function isAllowedObjectTypes(objectTypes, workflowId) {
        if (!service.featureSet.contains("workflow.process.objectType")) {
            return true;
        }

        let startable = startableWorkflows.find(startable => startable.workflowId === workflowId) || {};
        if (startable.objectTypesAllowed === false) {
            return false;
        }

        if (startable.objectTypesAllowed === undefined
            || (startable.objectTypesAllowed === true && startable.allowedObjectTypes.length === 0)) {
            return true;
        }

        return objectTypes.map(objectType => {
            return startable.allowedObjectTypes.find(allowedObjectType => allowedObjectType === objectType)
        }).every(objecttype => objecttype != undefined);
    }

    function getThousandSeparator() {
        const quoteWrap = /^"(.+)"$/;
        const lang = getLanguage()
        const separator = env[`thousandSeparator${lang[0].toUpperCase()}${lang.slice(1)}`] || ""
        return separator.replace(quoteWrap, (str, group) => group);
    }

    function isO365Enabled() {
        return env["useOffice365"] === "true";
    }

    function getClientType() {
        return service.wfClientType;
    }

    function getMatchedEmsTypeObject(id) {
        if (id == void 0) {
            return null;
        }

        for (let type of emsTypes) {
            if (id === type.ecmType.oid.toString()) {
                return type.ecmType.oid.toString();
            }
        }

        return null;
    }
}
