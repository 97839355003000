import {Component, ElementRef, OnInit, OnDestroy, Renderer2, Inject} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {StateService} from "@uirouter/core";

@Component({
    selector: "eob-state-refresher",
    templateUrl: "./eob-state-refresher.component.html",
    styleUrls: ["./eob-state-refresher.component.scss"]
})

export class EobStateRefresherComponent implements OnInit, OnDestroy {

    constructor(protected clientService: ClientService,
                @Inject("$state") private $state: StateService,
                private renderer: Renderer2,
                private el: ElementRef) {
    }

    ngOnInit(): void {
        this.renderer.addClass(this.el.nativeElement, "state-header-button");
        this.clientService.registerConnectivityChangeHandler(this.onConnectivityChange);
        this.onConnectivityChange();
    }

    ngOnDestroy(): void {
        this.clientService.unregisterConnectivityChangeHandler(this.onConnectivityChange);
    }

    onConnectivityChange = () => {
        const display: string = (this.clientService.isOnline()) ? "block" : "none";
        this.renderer.setStyle(this.el.nativeElement, "display", display);
    };

    refreshState = () => {
        this.$state.reload();
    };
}
