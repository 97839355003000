import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as fromNavigation from "./navigation.reducer";
import {NavigationState} from "MODULES_PATH/navigation/interfaces/navigation.interface";

// eslint-disable-next-line @typescript-eslint/typedef
export const selectNavigationState = createFeatureSelector<NavigationState>(
    fromNavigation.navigationFeatureKey
);

// eslint-disable-next-line @typescript-eslint/typedef
export const selectAvailableStates = createSelector(
    selectNavigationState,
    (state: NavigationState) => state
);
