import {Injectable, Inject} from "@angular/core";
import {OrgAddonConfig, OrgAddonEntry} from "INTERFACES_PATH/org-addon-entry.interface";
import {DmsGroupOrgObject, DmsOrganisationObject, DmsUserOrgObject} from "INTERFACES_PATH/dms-organisation-object.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";

@Injectable({providedIn: "root"})
export class OrgAddonService {
    private readonly translateFn: TranslateFnType;

    constructor(@Inject("$filter") private $filter: ng.IFilterService) {
        this.translateFn = this.$filter("translate") ;
    }

    parseToOrgAddonEntries(content: DmsOrganisationObject[], selectFn?: (entry: DmsOrganisationObject) => boolean): OrgAddonEntry[] {
        return content.map(entry => ({
            displayName: entry.name,
            description: (entry as DmsGroupOrgObject).description || (entry as DmsUserOrgObject).fullname || "",
            type: entry.type,
            icon: entry.icon,
            children: (entry as DmsGroupOrgObject).users,
            selected: selectFn ? selectFn(entry): false,
            value: entry
        }));
    }

    getDefaultGridConfigs(config: OrgAddonConfig = {}): { leftConfig: any; rightConfig: any } {
        const getDefaultGridConfig: any = () => ({ // use a function to get a new config instance
            columnDefs: [{
                field: "icon", headerName: "", width: config.showDescription != false ? 40 : 20,
                cellRenderer: params => params.value
            }, {
                field: "displayName", headerName: this.translateFn("modal.revisit.userlist.name"),
                cellRenderer: params => this.addTooltip(params)
            },
                ...config.showDescription != false ? [{
                    field: "description", headerName: this.translateFn("modal.revisit.userlist.description"),
                    cellRenderer: params => `<span title="${params.value}">${params.value}</span>`
                }] : []
            ]
        });

        return {
            rightConfig: getDefaultGridConfig(),
            leftConfig:  getDefaultGridConfig()
        };
    }

    /**
     * Add a tooltip, which users are part of a role, to a rendered ag-grid cell.
     *
     * @param params - AgGrid cell object.
     * @returns The rendered cell value.
     */
    private addTooltip(params: any): string {
        if (params.data.type === "Rolle" || params.data.type === "group") {
            const children: any = params.data.children || params.data.users;
            const childrenNames: string[] = [];

            if (children !== undefined) {
                for (const child of children) {
                    childrenNames.push(child.name);
                }

                return `<span title="${params.value}: ${childrenNames.join(", ")}">${params.value}</span>`;
            }
        }

        return `<span title="${params.value}">${params.value}</span>`;
    }
}
