import {Subject} from "rxjs";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";

require("SERVICES_PATH/eob.config.prv.js");

angular.module("eob.core").factory("backendService", BackendService);

BackendService.$inject = ["$rootScope", "$http", "$httpBackend", "$eobConfig", "profileService", "fileCacheService", "errorModelService", "$injector", "$filter", "httpService"];

export default function BackendService($rootScope, $http, $httpBackend, $eobConfig, ProfileService, FileCacheService, ErrorModelService, $injector, $filter, httpService) {

    let service = {
        get,
        post,
        delete: sendDelete,

        cachedGetAsync,
        cachedPostAsync,

        getCancelableHttpConfig,
        getTransformRequest
    };
    return service;

    // region Uncached Methods

    /**
     * Returns a JSON Object from OSRest if online or an error if offline
     * TODO: See param order in get and post. Base and config are not in the same order. Refactoring!
     *
     * @access public
     * @param {string} uri - uri call for BackendService,
     * @param {object} data - JSON-Object with post data
     * @param {object} config - a $http config object
     * @param {string} base - the base on where to start the request (like "/osweb")
     * @returns {Promise} - returns a Promise of the JSON Data from the backend
     */
    async function post(uri, data, config, base) {
        return await httpService.legacyPost(uri, data, config, base);
    }

    /**
     * Returns a JSON Object from OSRest if online or an error if offline
     *
     * @access public
     * @param {string} uri - uri call for BackendService
     * @param {string} base - the base on where to start the request (like "/osweb")
     * @param {object} config - a $http config object
     * @returns {Promise} - returns a Promise of the JSON Data from the backend
     */
    async function get(uri, base, config) {
        return httpService.legacyGet(uri, base, config);
    }

    /**
     * Returns a JSON Object from OSRest if online or an error if offline
     *
     * @access public
     * @param {string} uri - uri call for BackendService
     * @param {string} base - the base on where to start the request (like "/osweb")
     * @returns {Promise} - returns a Promise of the JSON Data from the backend
     */
    async function sendDelete(uri, base) {
        return httpService.legacyDelete(uri, base);
    }

    /**
     * Get http config with callback for transforming request payload to form data with blob
     * @return {object} - http config with request transforming callback
     */
    function getTransformRequest() {
        return {
            headers: {
                "Content-Type": undefined
            },
            transformRequest(data) {
                let headers = {
                    type: "application/json;charset=UTF-8"
                };
                let blob = new Blob([JSON.stringify(data)], headers);

                let formData = new FormData();
                formData.append("data", blob);
                return formData;
            }
        };
    }

    // endregion
    // region Cached Methods

    /**
     * Returns a JSON Object from OSRest if online or from FileCacheService if offline.
     * This Methods is slightly more complex then the post one because get request are
     * made while booting and there should be a quick boot when opening another tab in electron.
     *
     * @access public
     * @param {string} uri - uri call for BackendService,
     * @param {string} cacheKey - the name of the offlineFile that will be loaded if offline
     * @param {string=} base - the base on where to start the request (like "/osweb")
     * @param {object=} config - a $http config object
     * @returns {Promise} - returns a Promise of the JSON Data from the backend
     */
    async function cachedGetAsync(uri, cacheKey, base, config) {
        if (!cacheKey) {
            return await service.get(uri, base, config);
        }

        // We only use the randomSessionId for boot to speed this up. After that each tab is for it's own
        //const { randomSessionId } = ClientService.getRandomSessionIds();
        const cacheEntryType = (Object.values(GlobalCacheKey).indexOf(cacheKey) > -1) ? DatabaseEntryType.GLOBAL : DatabaseEntryType.PERSISTENT;

        try {
            // Disable session caching due to possible inconsistencies between currently opened tabs and server settings
            //const dbRandonSessionId = await FileCacheService.getContentAsync(FileCacheService.EntryTypes.GLOBAL, FileCacheService.GlobalCacheKeys.RANDOM_SESSION_ID, {first: true, onlyContent: true});

            if (/*randomSessionId == dbRandonSessionId || */!navigator.onLine) {
                let data = await FileCacheService.getContentAsync(cacheEntryType, cacheKey, { first: true });

                if (data != void 0) {
                    return {
                        data,
                        status: 200,
                        statusText: ""
                    };
                }
            }
        } catch (_) {
            // ignore. If something goes wrong we fetch the data from rest
        }

        let response = await service.get(uri, base, config);

        try {
            if (response.status == 304) {
                let data = await FileCacheService.getContentAsync(cacheEntryType, cacheKey, { first: true });

                return {
                    data,
                    status: 200,
                    statusText: ""
                };
            } else {
                // Async -> But don't wait
                FileCacheService.storeContentAsync(cacheEntryType, response.data, cacheKey);
            }
        } catch (_) {
            // ignore. If we can't store the data in the IndexedDB, then we fetch it next time again from rest.
        }

        return response;
    }

    /**
     * Returns a JSON Object from OSRest if online or from FileCacheService if offline
     *
     * @access public
     * @param {string} uri - uri call for BackendService,
     * @param {string} cacheKey - the name of the offlineFile that will be loaded if offline
     * @param {object} data - JSON-Object with post data
     * @param {string} base - the base on where to start the request (like "/osweb")
     * @param {object} config - a $http config object
     * @returns {Promise} - returns a Promise of the JSON Data from the backend
     */
    async function cachedPostAsync(uri, cacheKey, data, base, config) {
        const cacheEntryType = (Object.values(DatabaseEntryType.GLOBAL).indexOf(cacheKey) > -1) ? DatabaseEntryType.GLOBAL : DatabaseEntryType.PERSISTENT;

        if (navigator.onLine) {
            let response = await post(uri, base, config);

            try {
                await FileCacheService.storeContentAsync(cacheEntryType, response.data, cacheKey, "");
            } catch (_) {
                // ignore. If we can't store the data in the IndexedDB, then we fetch it next time again from rest.
            }

            return response.data;
        }

        try {
            let responseData = await FileCacheService.getContentAsync(cacheEntryType, cacheKey, { first: true });

            if (responseData != void 0) {
                return {
                    data: responseData,
                    status: 200,
                    statusText: ""
                };
            }
        } catch (_) {
            // ignore. We throw the error under here.
        }

        throw ErrorModelService.createCustomError("WEB_APPCONNECTOR_GENERAL_ERROR");
    }

    // endregion

    /**
     * Returns a {@link Subject} to be used to abort a running $http request
     * @returns {TimeoutConfigHolder}
     */
    function getCancelableHttpConfig() {
        const subject = new Subject();
        return {
            subject,
            config: {
                timeout: subject.toPromise()
            }
        };
    }
}
