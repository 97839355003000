import {Injectable} from "@angular/core";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";
import {Observable, of, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BackendExternalTool} from "CORE_PATH/backend/interfaces/resource/backend-external-tool.interface";
import {BackendDashlet} from "CORE_PATH/backend/interfaces/resource/backend-dashlet.interface";
import {catchError} from "rxjs/operators";

@Injectable({providedIn: BackendDms2Module})
export class Dms2ResourceService {

    constructor(private httpClient: HttpClient) {
    }

    getPublicResource(url: string, responseType: "json" | "arraybuffer" | "text" = "text"): Observable<unknown> {
        return this.httpClient.get(`/resources/apps/${url}`, {responseType: responseType as "text"});
    }

    getExternalTools(): Observable<BackendExternalTool[]> {
        return this.getPublicResource("osweb/public/external_tools/tools.json", "json").pipe(
            catchError(error => {
                // If the tools.json is not present we return a empty array.
                // Otherwise we throw up the error to the calling instance.
                if (error.status == 404) {
                    return of([]);
                }

                return throwError(error);
            })
        ) as Observable<BackendExternalTool[]>;
    }

    getDashlets(): Observable<BackendDashlet[]> {
        return this.getPublicResource("osweb/public/dashlets/dashlets.json", "json")
            .pipe(catchError(error => {
                // If the dashlets.json is not present we return a empty array.
                // Otherwise we throw up the error to the calling instance.
                if (error.status == 404) {
                    return of([]);
                }

                return throwError(error);
            })
        ) as Observable<BackendDashlet[]>;
    }

    getDashletUriContent(uri: string): Observable<string> {
        return this.httpClient.get(uri, {responseType: "text"});
    }
}
