import {Component} from "@angular/core";
import {EobInputFormControl} from "MODULES_PATH/form/components/inputs/eob-input-form-control.model";
import {AfterViewInit, ElementRef, Inject, Input, OnDestroy} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {Validators, FormGroup} from "@angular/forms";
import {TodoModalDialogService} from "INTERFACES_PATH/any.types";
import {timeoutWith} from "rxjs/operators";
import {ValidationErrors} from "@angular/forms";
import {of} from "rxjs";
import {from, Observable} from "rxjs";
import {BackendSessionService} from "CORE_PATH/backend/services/session/backend-session.service";
import {catchError, map} from "rxjs/operators";
import {ViewService} from "CORE_PATH/services/view/view.service";
import {CapslockTracerService} from "MODULES_PATH/form/services/capslock-tracer.service";

@Component({
    selector: "eob-modal-password",
    templateUrl: "./eob-modal-password.component.html",
    styleUrls: ["./eob-modal-password.component.scss"]
})
export class EobModalPasswordComponent implements AfterViewInit, OnDestroy {
    @Input() confirmobject: {
        confirmCallback: (confirmation: { password: string }) => void;
        msg: string;
    };
    readonly form: FormGroup;
    readonly pwdControl: EobInputFormControl;

    private readonly translate: TranslateFnType;

    // eslint-disable-next-line max-params
    constructor(@Inject("$filter") private $filter: ng.IFilterService,
                @Inject("modalDialogService") public modalDialogService: TodoModalDialogService,
                private backendService: BackendSessionService, private elementRef: ElementRef,
                private messageService: MessageService, private clientService: ClientService,
                private notificationsService: NotificationsService, private viewService: ViewService,
                private capslockTracerService: CapslockTracerService) {
        this.translate = this.$filter("translate");

        this.pwdControl = new EobInputFormControl("", {
            type: "password",
            // eslint-disable-next-line @typescript-eslint/unbound-method
            validators: [Validators.required]
        });
        this.form = new FormGroup({
            password: this.pwdControl
        });
    }

    onSubmit(): void {
        if (!this.pwdControl.dirty) { // show the required error, if the pwd field is still untouched
            this.pwdControl.markAsDirty();
            this.pwdControl.updateValueAndValidity();
        }

        if (!this.form.valid) {
            return;
        }

        this.form.disable();
        this.checkPassword().subscribe((error) => {
            if (error) {
                this.form.enable();
                if (error.pwdError) {
                    this.pwdControl.setErrors({customError: {message: error.pwdError}});
                }
                return;
            }

            if (this.confirmobject && typeof this.confirmobject.confirmCallback === "function") {
                this.confirmobject.confirmCallback({password: this.pwdControl.value});
            }

            this.close(true);
        });
    }

    close(success?: boolean): void {
        this.messageService.broadcast(ModalEvents.DESTROY, success);
    }

    private checkPassword(): Observable<ValidationErrors | void> {
        const value: string = this.pwdControl.value;

        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translate("eob.message.offline.function.disabled"));
            return of({error: true});
        }

        return this.backendService.checkPassword(value).pipe(catchError(({error}) => {
            if (error.root == "PASSWORD_INCORRECT") /* wrong password */ {
                this.pwdControl.setValue("");
                return of({pwdError: this.translate("modal.confirm.password.error")});
            } else if (error.root === "USER_IS_BLOCKED") /* locked */ {
                return this.logoutBlockedUser();
            } else {
                this.pwdControl.reset();
                this.notificationsService.error(this.translate("eob.add.user.general.error.title"));
            }

            this.viewService.autoFocusElement(this.elementRef.nativeElement, "input[type=password]");
            return of({error: true});
        }));
    }

    private logoutBlockedUser(): Observable<ValidationErrors> {
        const title: string = this.translate("modal.confirm.password.error"),
            msg: string = this.translate("modal.confirm.blocked.error");

        const modalContainer$: Observable<unknown> = from(this.modalDialogService.infoDialog(title, msg, undefined, this.translate("modal.button.submit"), undefined, true));
        const obs$: Observable<ValidationErrors> = modalContainer$.pipe(timeoutWith(30000, of({})), map(() => ({error: true})));
        obs$.subscribe(_ => {
            void this.clientService.logoutAsync(true);
        });
        return obs$;
    }

    ngAfterViewInit(): void {
        this.capslockTracerService.showCapsLockWarning.next(true);
    }

    ngOnDestroy(): void {
        this.capslockTracerService.showCapsLockWarning.next(false);
    }

}
