<form class="login-form" spellcheck="false">
    <!--<span>{{initErrorMessage | ngTranslate}}</span>-->

    <h2 *ngIf="!isPhone">{{'eob.login.heading' | ngTranslate}}</h2>

    <div class="form-row-container">
        <div id="loadAnimation" class="osProgressbar" [hidden]="!profileManagerService.checkingLoginData">
            <div class="osloader"></div>
        </div>
        <div class="login-form__row profile-selection-row" *ngIf="!isBrowserClient">
            <legend>{{'eob.login.profile' | ngTranslate}}</legend>
            <div id="profile-selection-row" [hidden]="profileManagerService.checkingLoginData">
                <div class="profile-dropdown" tabindex="0" [hidden]="!profilesReady" (focusout)="profileDropdown.dropDownOpen ? profileDropdown.toggleDropdown() : false">
                    <eob-dropdown [dropdownconfig]="profileDropDownConfig" #profileDropdown></eob-dropdown>
                </div>
                <button class="profile-delete-button primary" type="button" #deleteProfileButton (click)="deleteProfile()" title="{{'eob.login.profile.delete.title' | ngTranslate}}"></button>
            </div>
        </div>
        <div class="login-form__row type-selection-row" *ngIf="!hideTypeDropdown && !isBrowserClient">
            <legend>{{'eob.login.type' | ngTranslate}}</legend>
            <div id="type-selection-row">
                <div class="type-dropdown" tabindex="1" [hidden]="!profilesReady" (focusout)="typeDropdown.dropDownOpen ? typeDropdown.toggleDropdown() : false">
                    <eob-dropdown [dropdownconfig]="authTypeDropdownConfig" #typeDropdown></eob-dropdown>
                </div>
            </div>
        </div>
        <div class="profiles-form-inputs">
            <div class="login-form__row" [hidden]="isBrowserClient">
                <legend>{{'eob.login.url' | ngTranslate}}</legend>
                <input class="eob-simple-form-textfield" tabindex="2" type="text" autocomplete="off" autocapitalize="none"
                       autocorrect="off" id="url" placeholder="{{'eob.login.url' | ngTranslate}}" name="login-url"
                       [(ngModel)]="profileManagerService.profile.url"/>
            </div>
            <div class="login-form__row">
                <legend>{{'eob.login.username' | ngTranslate}}</legend>
                <input class="eob-simple-form-textfield" tabindex="3" type="text" autocomplete="off" autocapitalize="none"
                       autocorrect="off" id="userName" placeholder="{{'eob.login.username' | ngTranslate}}" name="login-username"
                       [ngClass]="{disabled: !profileManagerService.allowEnteringCredentials, null: profileManagerService.allowEnteringCredentials}"
                       [readOnly]="!profileManagerService.allowEnteringCredentials" [(ngModel)]="profileManagerService.profile.username" #loginUsername/>
            </div>
            <div class="login-form__row">
                <legend>{{'eob.login.password' | ngTranslate}}</legend>
                <input class="eob-simple-form-textfield" tabindex="4" type="password" autocomplete="off" autocapitalize="none"
                       autocorrect="off" id="userPassword" placeholder="{{'eob.login.password' | ngTranslate}}" name="login-password"
                       (focus)="onInputFocus(true)" (blur)="onInputFocus(false)"
                       [ngClass]="{disabled: !profileManagerService.allowEnteringCredentials, null: profileManagerService.allowEnteringCredentials}"
                       [readOnly]="!profileManagerService.allowEnteringCredentials" [(ngModel)]="profileManagerService.profile.password"/>
            </div>
            <div class="login-form__row checkbox-row" [hidden]="isBrowserClient">
                <div class="checkbox">
                    <div id="auto-login-checkbox-div" class="eob-simple-form-checkbox custom-checkbox"
                         (keydown)="autoLoginCheckboxKeydown($event)" (click)="toggleAutoLogin()"
                         [ngClass]="{ok: profileManagerService.profile.autologin === true, null: profileManagerService.profile.autologin !== true}"
                         tabindex="5" #autologinCheckbox></div>
                </div>
                <legend id="legend-auto-login">{{'eob.login.auto.login' | ngTranslate}}</legend>
            </div>
        </div>
    </div>
    <eob-capslock-tracer></eob-capslock-tracer>
    <div class="login-button-container">
        <button class="secondary-button" [hidden]="!profileManagerService.showBackButton" type="button" (click)="back()">{{'modal.button.back' | ngTranslate}}</button>
        <button class="primary" #submitButton [hidden]="profileManagerService.checkingLoginData" type="submit" (click)="login()" (keydown)="loginButtonKeydown($event)">{{'eob.login.login' | ngTranslate}}</button>
        <button class="primary" [hidden]="!profileManagerService.checkingLoginData || !profileManagerService.showLoginForm" type="button" (click)="abortLogin()">{{'modal.button.cancel' | ngTranslate}}</button>
    </div>
</form>
