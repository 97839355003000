import {Component, EventEmitter, Input, OnDestroy, Output} from "@angular/core";
import {GridApi, GridOptions} from "ag-grid-community";
import {RowNode} from "ag-grid-community/dist/lib/entities/rowNode";
import {
    CellContextMenuEvent,
    ColumnEvent,
    ColumnResizedEvent,
    GridReadyEvent,
    RowEvent,
    SortChangedEvent
} from "ag-grid-community/dist/lib/events";

@Component({
    selector: "eob-grid-wrapper",
    templateUrl: "./grid-wrapper.component.html",
    styleUrls: ["./grid-wrapper.component.scss"]
})

export class GridWrapperComponent implements OnDestroy {
    @Input() gridoptions: GridOptions;
    @Input() gridapi: GridApi;
    @Output() destroyed: EventEmitter<void> = new EventEmitter<void>();

    ngOnDestroy(): void {
        this.destroyed.emit();
    }

    onGridSizeChanged(params: any): void {
        this.gridoptions.onGridSizeChanged(params);
    }

    onGridReady = (params: GridReadyEvent): void => {
        this.gridoptions.api = params.api;
        this.gridoptions.columnApi = params.columnApi;
        this.gridoptions.onGridReady(params);
    };

    onRowClicked = (event: RowEvent): void => {
        if (typeof this.gridoptions.onRowClicked === "function") {
            this.gridoptions.onRowClicked(event);
        }
    };

    onRowSelected = (event: RowEvent): void => {
        if (typeof this.gridoptions.onRowSelected === "function") {
            this.gridoptions.onRowSelected(event);
        }
    };

    onRowDoubleClicked = (event: RowEvent): void => {
        if (typeof this.gridoptions.onRowDoubleClicked === "function") {
            this.gridoptions.onRowDoubleClicked(event);
        }
    };

    onColumnResized = (event: ColumnResizedEvent): void => {
        if (typeof this.gridoptions.onColumnResized === "function") {
            this.gridoptions.onColumnResized(event);
        }
    };

    onColumnRowGroupChanged = (event: ColumnEvent): void => {
        this.gridoptions.onColumnRowGroupChanged(event);
    };

    onCellContextMenu = (event: CellContextMenuEvent): void => {
        if (typeof this.gridoptions.onCellContextMenu === "function") {
            this.gridoptions.onCellContextMenu(event);
        }
    };

    onSortChanged = (event: SortChangedEvent): void => {
        if (typeof this.gridoptions.onSortChanged === "function") {
            this.gridoptions.onSortChanged(event);
        }
    };

    onGridColumnsChanged = (event: SortChangedEvent): void => {
        if (typeof this.gridoptions.onGridColumnsChanged === "function") {
            this.gridoptions.onGridColumnsChanged(event);
        }
    };

    defaultGroupSortComparator = (nodeA: RowNode, nodeB: RowNode): number =>
        this.gridoptions.defaultGroupSortComparator(nodeA, nodeB);
}