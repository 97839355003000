(function () {


    angular.module("eob.framework").directive("dvWorkflow", DvWorkflow);

    DvWorkflow.$inject = ["dvWorkflowService", "environmentService", "dvUsernameService", "$timeout", "messageService"];

    function DvWorkflow(DvWorkflowService, EnvironmentService, DvUsernameService, $timeout, MessageService) {
        return {
            restrict: "E",
            template: require("!raw-loader!./dv.workflow.html"),
            link(scope) {
                scope.wfdata = undefined;
                scope.wferror = false;
                scope.loading = true;

                scope.$on("changeObject", load);
                MessageService.subscribe("clear.viewer", clear);
                scope.$on("dv.workflows.update", refreshData);

                scope.altLang = !EnvironmentService.uiLangIsObjectDefLang() ? EnvironmentService.getObjectDefinitionLanguage() : null;

                init();

                function init() {
                    scope.viewEnabled = EnvironmentService.userHasRole("R_WFCLNT_USE");
                    if (scope.viewEnabled) {
                        load();
                        scope.loading = false;
                    } else {
                        scope.loading = false;
                    }
                }

                function refreshData() {
                    const dvWfData = DvWorkflowService.getData();
                    $timeout(() => {
                        if (Array.isArray(scope.wfdata) && scope.wfdata.length == 1 && scope.wfdata[0].id &&
                            dvWfData.id && dvWfData.id !== scope.wfdata[0].id) {
                            scope.wfdata = DvWorkflowService.getData();
                        }
                    }, 0);
                }

                function load() {
                    let requestedOsId = scope.objectId;
                    DvWorkflowService.load(requestedOsId).then((res) => {
                        if (requestedOsId == scope.objectId) {
                            scope.wfdata = prepareUserSnippet(res);
                        }
                        scope.wferror = false;
                        scope.loading = false;
                    }, (reason) => {
                        scope.wferror = true;
                        scope.loading = false;
                    });
                }

                function prepareUserSnippet(data) {
                    let index = data.length;
                    while (index--) {
                        let tmpWorkflow = data[index];

                        if (tmpWorkflow.creator != void 0) {
                            tmpWorkflow.creator.snippet = DvUsernameService.getHtmlUsernameSnippet(tmpWorkflow.creator);
                        }

                        let activityIndex = tmpWorkflow.activities.length;
                        while (activityIndex--) {
                            let activity = tmpWorkflow.activities[activityIndex];
                            if (activity.endTime > 0) {
                                activity.finished = activity.endTime;
                            } else {
                                activity.finishedState = true;
                            }

                            if (activity.owner == void 0) {
                                activity.owner = {};
                            }

                            activity.owner.snippet = DvUsernameService.getHtmlUsernameSnippet(activity.owner);
                        }
                    }
                    return data;
                }

                function clear() {
                    scope.wfdata = undefined;
                    scope.wferror = false;
                }
            }
        };
    }
})();
