import {createReducer, on} from "@ngrx/store";
import * as NavigationActions from "./navigation.actions";
import {NavAction} from "./navigation.actions";
import {NavigationState} from "MODULES_PATH/navigation/interfaces/navigation.interface";

export const navigationFeatureKey: string = "navigation";

export const initialState: NavigationState = {
    showInbox: false,
    showWorkflow: false,
    showFavorites: false,
    showOfflineObjects: false,
    showNavAvatar: true,
    showHistory: false,
    showInboxArea: false,
    showDesktop: false,
    showQuickSearches: false,
    showNavKebab: false,
    showObjectsearch: false,
    showExternalTray: false,
    externalTrayElementsAvailable: false
};

// eslint-disable-next-line @typescript-eslint/typedef
export const navigationReducer = createReducer(
    initialState,

    on(NavigationActions.updateNavigation, (state: NavigationState, action) => ({
        ...state,
        [action.navType]: action.payload
    })),
);
