<div class = "vertical-button-bar" [hidden] = "isPhone">
    <ul>
        <li [ngClass] = "{'selected' : openTab == 'baseParams'}"
            (click) = "switchSettingsTab('baseParams')" *ngIf = "showBasicParameter">
            <button class = "button-transparent">
                <eob-icon name = "baseparams-24" size = "24"
                          title = "{{'eob.search.baseparam.mask.title' | ngTranslate}}"></eob-icon>
            </button>
        </li>
        <li [ngClass] = "{'selected' : openTab == 'objectTypes'}"
            (click) = "switchSettingsTab('objectTypes')">
            <button class = "button-transparent">
                <eob-icon name = "add-objecttype" size = "24"
                          title = "{{'eob.search.settings.objecttypes.title' | ngTranslate}}"></eob-icon>
            </button>
        </li>
    </ul>
</div>
<div class = "search-options" [attr.lang]="showAltLang ? objectDefLang : null">
    <div class = "config-container base-param-container"
         *ngIf = "isPhone || (showBasicParameter && openTab == 'baseParams')">
        <ul class = "nav nav-tabs">
            <ng-container *ngFor = "let type of selectedTypes">
                <li *ngIf = "type.isActive"
                    [ngClass] = "{'active' : activeObjectType.objectTypeId == type.objectTypeId}"
                    (click) = "changeActiveObjectType(type.objectTypeId)">
                    <button class = "button-transparent">
                        <eob-icon *ngIf = "type.icon != ''" name = "{{type.icon}}" size = "16"></eob-icon>
                        <h3>{{type.title}}</h3>
                    </button>
                </li>
            </ng-container>
        </ul>
        <ul class = base-params-list-container [attr.lang]="uiLang">
            <li *ngFor = "let baseParam of baseParamFields" class = "settings-item {{baseParam.internal}}"
                [ngClass] = "{'active' : activeObjectType.baseParams.indexOf(baseParam.internal) != -1}"
                (click) = "changeSetting('baseParams',baseParam.internal)">
                <eob-icon *ngIf = "baseParam.icon" name = "{{baseParam.icon}}" size = "16"></eob-icon>
                <eob-icon *ngIf = "baseParam.iconClass" name = "{{baseParam.iconClass}}" size = "16"></eob-icon>
                <h3>{{baseParam.title}}</h3>
            </li>
        </ul>
    </div>
    <div class = "object-type-settings" *ngIf = "openTab == 'objectTypes'">
        <div class = "config-container toggle-area-hidden-content open objecttype-container">
            <div *ngFor = "let type of objectTypePerType.folder" class = "settings-item {{type.objectTypeId}}"
                 [ngClass] = "{'active' : type.isActive, mainsearch : type.isMainSearchType}"
                 (click) = "changeSetting('objectType',type.objectTypeId)">
                <eob-icon *ngIf = "type.icon != ''" name = "{{type.icon}}" size = "16"></eob-icon>
                <h3>{{type.title}}</h3>
            </div>
        </div>
        <div class = "config-container toggle-area-hidden-content objecttype-container"
             [ngClass] = "{'open': objectTypePerType.register.length, 'close': !objectTypePerType.register.length}">
            <div *ngFor = "let type of objectTypePerType.register" class = "settings-item {{type.objectTypeId}}"
                 [ngClass] = "{'active' : type.isActive,  mainsearch : type.isMainSearchType}"
                 (click) = "changeSetting('objectType',type.objectTypeId)">
                <eob-icon *ngIf = "type.icon != ''" name = "{{type.icon}}" size = "16"></eob-icon>
                <h3>{{type.title}}</h3>
            </div>
        </div>
        <div class = "config-container toggle-area-hidden-content objecttype-container"
             [ngClass] = "{'open': objectTypePerType.document.length, 'close': !objectTypePerType.document.length}">
            <div *ngFor = "let type of objectTypePerType.document" class = "settings-item {{type.objectTypeId}}"
                 [ngClass] = "{'active' : type.isActive,  'mainsearch' : type.isMainSearchType}"
                 (click) = "changeSetting('objectType',type.objectTypeId)">
                <eob-icon *ngIf = "type.icon != ''" name = "{{type.icon}}" size = "16"></eob-icon>
                <h3>{{type.title}}</h3>
            </div>
        </div>
    </div>
</div>
