import {NgModule, NO_ERRORS_SCHEMA} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgHybridStateDeclaration, UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {ObjectReferencesComponent} from "MODULES_PATH/hitlist/components/object-references/object-references.component";
import {SharedModule} from "SHARED_PATH/shared.module";
import {GridModule} from "MODULES_PATH/grid/grid.module";
import {EobHitListComponent} from "MODULES_PATH/hitlist/eob-hit-list.component";
import {EobHitlistFooterComponent} from "MODULES_PATH/hitlist/components/eob-hitlist-footer/eob-hitlist-footer.component";
import {StateBasicsModule} from "MODULES_PATH/state-basics/state-basics.module";
import {FavoritesComponent} from "./components/favorites/favorites.component";
import {ResultComponent} from "MODULES_PATH/hitlist/components/result/result.component";
import {HistoryComponent} from "./components/history/history.component";
import {SearchResultComponent} from "./components/search-result/search-result.component";
import {FulltextComponent} from "./components/fulltext/fulltext.component";
import {OfflineObjectsComponent} from "./components/offline-objects/offline-objects.component";
import {FailedSyncObjectsComponent} from "./components/failed-sync-objects/failed-sync-objects.component";
import {InboxComponent} from "./components/inbox/inbox.component";

export const states: NgHybridStateDeclaration[] = [
    {
        name: "hitlist",
        url: "/hitlist"
    },
    {
        name: "hitlist.object-references",
        url: "/object-references/:osid?state",
        component: ObjectReferencesComponent
    },
    {
        name: "hitlist.favorites",
        url: "/favorites?state",
        component: FavoritesComponent
    },
    {
        name: "hitlist.history",
        url: "/history?state",
        component: HistoryComponent
    },
    {
        name: "hitlist.result",
        url: "/result?state&flat&folderId",
        component: SearchResultComponent
    },
    {
        name: "hitlist.fulltextResult",
        url: "/fulltextResult?state",
        component: FulltextComponent
    },
    {
        name: "hitlist.offlineObjects",
        url: "/offlineObjects?state",
        component: OfflineObjectsComponent
    },
    {
        name: "hitlist.failedSyncObjects",
        url: "/failedSyncObjects?state",
        component: FailedSyncObjectsComponent
    },
    {
        name: "hitlist.inbox",
        url: "/inbox/:type?state",
        component: InboxComponent
    }
];

const components: any = [
    ResultComponent,
    EobHitListComponent,
    EobHitlistFooterComponent,

    /**
     * states component
     */
    ObjectReferencesComponent,
    FavoritesComponent,
    HistoryComponent,
    SearchResultComponent,
    FulltextComponent,
    OfflineObjectsComponent,
    FailedSyncObjectsComponent,
    InboxComponent
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        GridModule,
        StateBasicsModule,
        UIRouterUpgradeModule.forChild({states})
    ],
    declarations: [...components],
    exports: [...components],
    entryComponents: [EobHitListComponent, EobHitlistFooterComponent, ResultComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class HitlistModule {
}
