import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    OnDestroy,
    Renderer2,
    ViewChild
} from "@angular/core";
import {from, fromEvent, Subject} from "rxjs";
import {mergeMap, takeUntil} from "rxjs/operators";
import {TranslateFnType} from "CLIENT_PATH/custom.types";

import {ClientService} from "CORE_PATH/services/client/client.service";
import {StateService} from "@uirouter/core";

@Component({
    selector: "eob-state-touch-refresh",
    templateUrl: "./state-touch-refresh.component.html",
    styleUrls: ["./state-touch-refresh.component.scss"]
})
export class StateTouchRefreshComponent implements AfterViewInit, OnDestroy {
    readonly translateFn: TranslateFnType;

    isTouch = false;
    isRefreshed = false;
    minDistance = 120;

    touchStartUnsubscriber: Subject<boolean> = new Subject<boolean>();
    touchEndUnsubscriber: Subject<boolean> = new Subject<boolean>();

    @ViewChild("innerScroller", {static: false}) scrollBox: ElementRef<HTMLElement>;
    @ViewChild("refreshIconContainer", {static: false}) refreshIconContainer: ElementRef<HTMLElement>;
    @ViewChild("pullBox", {static: false}) pullBox: ElementRef<HTMLElement>;

    constructor(@Inject(forwardRef(() => "$filter")) private $filter: ng.IFilterService,
                @Inject("$state") private $state: StateService, private clientService: ClientService,
                private renderer: Renderer2, private el: ElementRef, private cdRef: ChangeDetectorRef) {
        this.translateFn = this.$filter("translate");
        this.isTouch = this.clientService.isTouchDevice();
    }

    ngAfterViewInit(): void {
        if (this.isTouch) {
            fromEvent(this.scrollBox.nativeElement, "touchstart").pipe(takeUntil(this.touchStartUnsubscriber)).subscribe(
                (event: TouchEvent) => {
                    this.animateRefresh(event);
                }
            );
        }
    }

    ngOnDestroy(): void {
        this.touchStartUnsubscriber.next(true);
    }

    /**
     * Binds the touchmove event and animates the refresh icon
     * Binds the touchend event and does the refresh
     */
    private animateRefresh = (touchStartEvent: TouchEvent): void => {
        let isFirstExecution = true;
        let loaderPosition: number;
        let topStart: number;
        let topPrevious: number;
        let isRefreshGesture = false;
        let xMove = 0, yMove = 0;

        const grid: HTMLElement = document.body.querySelector(".ag-root-wrapper-body");
        const xStart = touchStartEvent.touches[0].pageX, yStart = touchStartEvent.touches[0].pageY;

        fromEvent(grid, "touchmove").pipe(takeUntil(this.touchEndUnsubscriber)).subscribe((event: TouchEvent) => {
            if (!isRefreshGesture && !this.clientService.isiOs()) {
                xMove = Math.abs(event.touches[0].pageX - xStart);
                yMove = Math.abs(event.touches[0].pageY - yStart);

                if (yMove > 20 && xMove < yMove / 2) {
                    isRefreshGesture = true;
                }

                return;
            }

            const gridScrollTop = grid.querySelector(".ag-body-viewport").scrollTop;
            const isGridScrolled = gridScrollTop !== 0;
            const topCurrent = event.touches[0].pageY;

            //if grid is scrolled to top prevent scroll event otherwise just do the scrolling
            if (gridScrollTop < 0 || (loaderPosition >= 0 && !isGridScrolled && event.cancelable)) {
                event.preventDefault();
            } else if (isGridScrolled) {
                this.renderer.setStyle(this.scrollBox.nativeElement, "opacity", 1);
                return;
            }

            //set our starting drag position
            if (isFirstExecution) {
                topStart = topPrevious = topCurrent;
                isFirstExecution = false;

                this.renderer.setStyle(this.pullBox.nativeElement, "display", "flex");
                return;
            }

            loaderPosition = topCurrent - topStart;
            loaderPosition = loaderPosition <= this.minDistance ? loaderPosition : this.minDistance;

            let opacity = loaderPosition * 1.5 / this.minDistance;
            opacity = opacity > 1 ? 1 : opacity;

            //if user is dragging in wrong direction cancel pull to refresh
            if (loaderPosition < 0 || topPrevious > topCurrent) {
                this.renderer.setStyle(this.scrollBox.nativeElement, "opacity", 1);
                this.renderer.addClass(this.refreshIconContainer.nativeElement, "shrink");
                this.touchEndUnsubscriber.next(true);

                setTimeout(() => {
                    this.resetRefreshIcon();
                }, 150);

                return;
            }

            if (loaderPosition > 10) {
                this.renderer.setStyle(this.scrollBox.nativeElement, "opacity", 0.7);
            }

            this.isRefreshed = loaderPosition >= this.minDistance;
            this.cdRef.detectChanges();

            topPrevious = topCurrent;

            this.renderer.setStyle(this.pullBox.nativeElement, "top", `${loaderPosition + 40}px`);
            this.renderer.setStyle(this.pullBox.nativeElement, "opacity", opacity);
        });

        from(["touchend", "touchcancel"])
            .pipe(
                mergeMap(_ => fromEvent(this.el.nativeElement, _)),
                takeUntil(this.touchEndUnsubscriber)
            )
            .subscribe(
                () => {
                    if (loaderPosition >= this.minDistance) {
                        this.renderer.addClass(this.refreshIconContainer.nativeElement, "rotate");
                        this.cdRef.detectChanges();

                        setTimeout(() => {
                            void this.$state.reload();
                            this.isRefreshed = false;
                        }, 500);
                    } else {
                        this.resetRefreshIcon();
                    }

                    this.touchEndUnsubscriber.next(true);
                }
            );
    };

    private resetRefreshIcon(): void {
        this.renderer.setStyle(this.pullBox.nativeElement, "top", 0);
        this.renderer.setStyle(this.pullBox.nativeElement, "display", "none");
        this.renderer.setStyle(this.pullBox.nativeElement, "opacity", 0);

        this.renderer.setStyle(this.scrollBox.nativeElement, "opacity", 1);
        this.renderer.removeClass(this.refreshIconContainer.nativeElement, "shrink");

        this.isRefreshed = false;
    }
}
