import {Inject, Injectable} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ERROR_CODES} from "SHARED_PATH/models/error-model.config";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {NotifyType} from "ENUMS_PATH/notifications.enum";
import {ClientServiceMessage} from "ENUMS_PATH/client.enum";
import * as angular from "angular";
import {Notyf, NotyfNotification} from "notyf";
import {CustomError} from "CORE_PATH/models/custom-error/custom-error.model";
import {WfUser} from "MODULES_PATH/modal-dialog/interfaces/substitutes-config.interface";
import {OrganisationService} from "../organisation/organisation.service";

/**
 * Service used to show notifications using toastr
 */
@Injectable({ providedIn: "root" })
export class NotificationsService {
    private readonly translateFn: TranslateFnType;
    private clientService: ClientService;
    private logoutInProgress: boolean = false;
    private readonly notyf: Notyf;

    constructor(@Inject("$filter") $filter: ng.IFilterService, @Inject("$injector") private $injector: ng.auto.IInjectorService, private errorModelService: ErrorModelService, messageService: MessageService) {
        this.translateFn = $filter("translate");
        messageService.subscribeFirst<ClientService>(ClientServiceMessage.INITIALIZED, payload => this.clientService = payload);
        this.notyf = new Notyf({
            duration: 8000,
            ripple: true,
            position: {
                x: "right",
                y: "top",
            },
            types: [
                {
                    type: "warning",
                    icon: {
                        className: "toast-warning-icon",
                    }
                },
                {
                    type: "info",
                    icon: {
                        className: "toast-info-icon",
                    }
                },
                {
                    type: "success",
                    icon: {
                        className: "toast-success-icon",
                    }
                },
                {
                    type: "error",
                    icon: {
                        className: "toast-error-icon",
                    }
                },
            ]
        });
    }

    /**
     * screen overlay for refresh and logout.
     *
     * @param isLogout
     */
    goodbye(isLogout: boolean): void {
        if (this.logoutInProgress) {
            return;
        }

        const exitSplash: JQuery = angular.element("<div class=\"eob-splash\"><div class =\"exit-splash\"  align=\"center\"><span class=\"leaving-message\" role=\"status\"></span></div></div>");

        if (isLogout) {
            const leavingPageMessage: string = this.translateFn("eob.leaving.page.message");
            exitSplash.find(".leaving-message").append(leavingPageMessage);
            this.logoutInProgress = true;
        }

        angular.element("body").append(exitSplash);
    }

    /**
     * Convenience method for formHelper API to show a toaster message
     *
     * @param type - the toast type ("error", "warning", "info", "success")
     * @param message - the toast message
     * @param title - the toast title
     * @param timeout - timeout in milliseconds (default is 5000, 0 for unlimited)
     */
    showToast(type: NotifyType = NotifyType.INFO, message: string = "", title: string = "", timeout?: number): void {
        const className: string = title ? `toast-${type} title` : `toast-${type}`;
        this._attachDismissListener(this.notyf.open({className, type, duration: timeout ?? this.notyf.options.duration, message: `${title ? `${title}\n` : ""}${message}`}));
    }

    /**
     * Convenience function for access to several notification levels
     *
     * @param level Kind of notification to be shown
     * @param message
     * @param title
     */
    notify(level: NotifyType, message: string, title: string = ""): void {
        this[level](message, title);
    }

    info(message: string, title: string = ""): void {
        this.showToast(NotifyType.INFO, message, title);
    }

    /**
     * Send a warning.
     */
    warning(message: string, title: string = ""): void {
        this.showToast(NotifyType.WARNING, message, title);
    }

    /**
     *
     * @param message
     * @param title
     */
    success = (message: string, title: string = ""): void => {
        this.showToast(NotifyType.SUCCESS, message, title);
    };

    multiSuccess = (count: number, messageKey: string): void => {
        const successMsgAddition: string = `<br>${this.translateFn("eob.multi.action.success.addition")}`;
        const addition: string = (count > 1) ? `${successMsgAddition}${count}` : "";

        this.success(this.translateFn(messageKey) + addition);
    };

    error(message: string, title?: string, timeOut?: number): void {
        this.showToast(NotifyType.ERROR, message, title, timeOut);

    }

    /**
     * Take any kind of error, resolve it to a webclient custom error and show a toastr message with the configured type.
     *
     * @param errorObject - May be a CustomError or an error backend response.
     * @param fallbackErrorCode - An optional error code as fallback.
     */
    customError(errorObject: any, fallbackErrorCode: string = ""): void {
        let ctmError: any = errorObject;

        // We can't test for just the constructor name, as is fails for minimized builds
        if (errorObject.constructor.name != "CustomError" && !errorObject.type && !ERROR_CODES[errorObject.type]) {
            const fallbackError: any = ERROR_CODES[fallbackErrorCode] || {};

            if (errorObject.serviceErrorCode == undefined) {
                // Error Object Rest-V1 -> Rest-V2
                const data: any = {
                    error: {
                        root: errorObject.data?.root ?? fallbackErrorCode,
                        status: errorObject.status,
                        message: errorObject.data?.errorMessage
                    }
                };

                ctmError = this.createCustomErrorByData(data, fallbackError.messageKey, errorObject.data?.parameter);
            } else {
                // DMS2 Backend error
                // This is incomplete and will be done within DODO-14918
                const data: any = {
                    error: {
                        root: fallbackErrorCode,
                        status: errorObject.httpStatusCode,
                        message: errorObject.message
                    }
                };

                ctmError = this.createCustomErrorByData(data, fallbackError.messageKey, undefined);
            }
        }

        // Catch unknown errors
        const notifyType: NotifyType = (ERROR_CODES[ctmError.type || "WEB_ERROR_UNDEFINED"] || {}).type || "error";
        this.notify(notifyType, ctmError.toString(), "");
    }

    /**
     * Checks the backend call for failed und succeeded actions.
     * Toasts success and customizable error messages for those actions.
     * Returns an array with the items that were successfully processed.
     *
     * @param response - backend call response
     * @param items - an array of items, which should be compared with the returned backend items
     * @param equalsFn - compare which item belongs to which backend item
     * @param errorMsgKey - default error message key
     * @param successMsgKey - default success message key
     * @returns - returns an array of succeeded items
     */
    backendMulti = (response: any, items: any[], equalsFn: (a: any, b: any) => number, errorMsgKey: string, successMsgKey?: string): any[] => {
        const data: any = response.data || response.errors;
        let succeededItems: any[] = [];

        if (Array.isArray(data) && data.length > 0) {
            if ((successMsgKey != void 0) && (data.length < items.length)) {
                const successCount: number = (items.length - data.length);

                this.multiSuccess(successCount, successMsgKey);
            }

            this.backendMultiError(response, errorMsgKey);
            succeededItems = this.cutFromArray(items, data, equalsFn);
        } else {
            this.backendError(response, errorMsgKey);
        }

        return succeededItems;
    };

    /**
     * Throw an error toaster based on an AppConnector response.
     * Todo: Currently no idea how response can be ng.IHttpResponse<any> or HttpErrorResponse
     * Todo: Therefore changed to any but if someone knows it replace it please.
     *
     * @param response - An AppConnector response object to a http-call.
     * @param fallbackLangKey - An optional translation key for a fallback error msg.
     * @param timeout - An optional timeout in milliseconds if the error toaster should remain visible longer.
     */
    backendError(response: any, fallbackLangKey?: string, timeout?: number): void {
        if (this.clientService?.isOffline()) {
            return;
        }

        // Error Object Rest-V1 -> Rest-V2
        if (response.data || response.error) {
            if (response.error instanceof ArrayBuffer) {
                const decodedString: string = String.fromCharCode.apply(null, new Uint8Array(response.error));
                response.data = JSON.parse(decodedString);
            } else if (response.error) {
                response.data = response.error;
            }

            const data: any = {
                error: {
                    root: response.data.root,
                    status: response.status,
                    message: response.data.errorMessage
                }
            };

            const ctmError: CustomError = this.createCustomErrorByData(data, fallbackLangKey);
            this.error(ctmError.toString(), "", timeout);
        } else {
            // no http error ... at this point we might have run into a common js mistake
            const obj: any = response;

            if (obj.message) {
                this.error(obj.message, "", timeout);
            } else if (typeof obj == "string") {
                this.error(obj, "", timeout);
            } else {
                const msg: string = this.translateFn(fallbackLangKey || ERROR_CODES.WEB_ERROR_UNDEFINED.messageKey);
                this.error(msg, "", timeout);
            }
        }
    }

    /**
     * Toasts an error message per failed action plus an additional default text, how many times the action failed.
     *
     * @param response - A http-call response.
     * @param errorMsgKey - default error message
     */
    backendMultiError = (response: ng.IHttpResponse<any>, errorMsgKey: string): void => {
        const data: any = response.data || response;

        const PLACEHOLDER_START_TOKEN: string = "[";
        const PLACEHOLDER_END_TOKEN: string = "]";
        const ADDITION_SUFFIX: string = `<br>${this.translateFn("eob.multi.action.error.addition")}`;

        const errorObjects: any = this.createCustomErrors(data, response.status, errorMsgKey);

        for (const errorObject of errorObjects) {
            const messages: string[] = errorObject.message;
            const count: number = messages.length;

            if (count > 1) {
                let mainMessage: string = messages[0];

                if (mainMessage.includes(PLACEHOLDER_START_TOKEN) && mainMessage.includes(PLACEHOLDER_END_TOKEN)) {
                    const params: any[] = [];

                    for (const message of messages) {
                        params.push(this.errorModelService.extractMessageParameters(message)[0]);
                    }

                    mainMessage = this.errorModelService.replaceMessagePlaceholders(errorObject.toString(), [params.join(", ")]);
                }

                this.error(`${mainMessage}${ADDITION_SUFFIX}${count}`);
            } else {
                this.error(errorObject.toString());
            }
        }
    };

    /**
     * dismiss all visible notifications
     */
    dismissAll(): void {
        this.notyf.dismissAll();
    }

    /**
     * Takes a response from an AppConnector multi action http-call and extracts custom webclient error objects.
     *
     * @param data - An array of error objects from a backend response.
     * @param status - The http status code.
     * @param fallbackErrorMsgKey - An optional fallback translation key.
     * @returns A map of custom error objects.
     */
    private createCustomErrors(data: any, status: number, fallbackErrorMsgKey: string): any {
        const errorObjects: any = {};

        for (const backendData of data) {
            if (backendData.error == void 0) {
                console.warn("Couldn't find error data for ", backendData);
                continue;
            }

            backendData.error.status = status || 500;
            const ctmError: any = this.createCustomErrorByData(backendData, fallbackErrorMsgKey);

            const errorObject: any = errorObjects[ctmError.type];
            if (errorObject == void 0) {
                errorObjects[ctmError.type] = ctmError;
            } else {
                errorObject.message.push(ctmError.toString());
            }
        }

        return errorObjects;
    }

    /**
     * Builds a custom webclient error object based on http-call response data.
     *
     * @param data - Http-call response data.
     * @param fallbackMsgKey - An optional translation key for a fallback msg.
     * @param replacements - An array of placeholder replacements.
     * @returns A custom webclient error object.
     */
    private createCustomErrorByData(data: any, fallbackMsgKey: string, replacements: string[] = []): CustomError {
        data = data.error ?? {};

        const errorDetails: any = (data.root == void 0) ? null : ERROR_CODES[data.root];
        let messageKey: string = "";

        if (errorDetails != void 0) {
            messageKey = errorDetails.messageKey;

            replacements = replacements.length > 0 ? replacements : this.errorModelService.extractMessageParameters(data.message);

            if (errorDetails.useCustomReplacement) {
                replacements = this.getCustomReplacements(data.root, replacements);
            }
        } else if (fallbackMsgKey != void 0) {
            messageKey = fallbackMsgKey;
        } else {
            console.warn("Could not find error message for data ", data);
        }

        return this.errorModelService.createCustomWebclientError(data.root, data.status, messageKey, replacements);
    }

    /**
     * Switch the backend replacements for a specific error with custom replacements.
     *
     * @param errorCode - The textual ID of an error.
     * @param replacements - The extracted replacements of a backend error.
     * @returns The custom replacements.
     */
    private getCustomReplacements(errorCode: string, replacements: string[]): string[] {
        let cstReplacements: string[] = replacements;

        if (errorCode == "WORKFLOW_WORKITEM_PERSONALIZATION_UNAUTHORIZED_SPECIFIC") {
            const organisationService: OrganisationService = this.$injector.get("organisationService");
            cstReplacements = organisationService.getWfOrgPerformerByIds(replacements).map((wfUser: WfUser) => wfUser.name || wfUser.login);
        }

        return cstReplacements;
    }

    /**
     * Remove all elements from an array that are determined equal with elements in another array.
     *
     * @param array1 - An array of any kind.
     * @param array2 - An array of any kind.
     * @param equalsFn - A function that determines whether an element matches another.
     * @returns A subset of array1.
     */
    private cutFromArray(array1: unknown[], array2: unknown[], equalsFn: (a: unknown, b: unknown) => number): unknown[] {
        if (array1.length == array2.length) {
            return [];
        }

        array2 = angular.copy(array2);
        array1 = angular.copy(array1);

        for (let i: number = array1.length - 1; i >= 0; i--) {
            for (let j: number = array2.length - 1; j >= 0; j--) {
                if (equalsFn(array1[i], array2[j])) {
                    array1.splice(i, 1);
                    array2.splice(j, 1);
                    break;
                }
            }
        }

        return array1;
    }

    /**
     * Adds a click listener to the last notification element. This allows dismissing the notification without using the visible button offered by the library.
     *
     * @param {NotyfNotification} notification
     * @private
     */
    private _attachDismissListener(notification: NotyfNotification): void {
        window.document.querySelector(".notyf__toast:last-child")?.addEventListener("click", () => {
            this.notyf.dismiss(notification);
        }, {once: true});
    }
}
