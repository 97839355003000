import {MessageService} from "CORE_PATH/services/message/message.service";
import {Observable} from "rxjs";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {BackendBaseService} from "CORE_PATH/backend/services/base/backend-base.service";
import {Dms2StoredQueriesService} from "CORE_PATH/backend/modules/dms2/services/storedqueries/dms2-stored-queries.service";
import {OsRestStoredQueriesService} from "CORE_PATH/backend/modules/osrest/services/storedqueries/osrest-storedqueries.service";

@Injectable({providedIn: BackendModule})
export class BackendStoredQueriesService extends BackendBaseService<Dms2StoredQueriesService | OsRestStoredQueriesService>{

    constructor(messageService: MessageService, dms2StoredQueriesService: Dms2StoredQueriesService, osRestStoredQueriesService: OsRestStoredQueriesService) {
        super(messageService, dms2StoredQueriesService, osRestStoredQueriesService);
    }

    /**
     * @return {Observable<BackendObject>} - The history of changed objects for the current user.
     */
    getHistory(): Observable<BackendObject> {
        return this.osRestService.getHistory();
    }

    /**
     * Executes a stored query.
     *
     * @param {string} queryId - The ID of the stored query.
     * @param {number} maxSize - The maximum number of hits.
     * @return {Observable<BackendObject>} - An observable list.
     */
    execute(queryId: string, maxSize: number): Observable<BackendObject> {
        return this.osRestService.execute(queryId, maxSize);
    }
}