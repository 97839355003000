import {
    Component,
    OnDestroy,
    Input, OnChanges, SimpleChanges
} from "@angular/core";
import {Subscription} from "rxjs";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {PdfjsToolbarState} from "MODULES_PATH/dashlet/interfaces/pdfjs.interface";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {initialState} from "MODULES_PATH/dashlet/components/eob-pdfjs/+state/pdfjs-toolbar.reducer";

@Component({
    selector: "eob-pdfjs-kebab",
    templateUrl: "./pdfjs-kebab.component.html",
    styleUrls: ["./pdfjs-kebab.component.scss"]
})
export class PdfjsKebabComponent implements OnDestroy, OnChanges {
    isMenuOpen: boolean = false;
    globalClickListener: Subscription = null;
    menuHeight: string = "";

    @Input() toolbarState: PdfjsToolbarState = initialState;
    @Input() controls = {};
    @Input() showMenu = false;

    constructor(private messageService: MessageService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.showMenu?.currentValue) {
            this.showPdfjsKebabMenu();
        }
        let menuItemsCount: number = -1; // because we don't count the attachmentsAvailable property at all

        for (const button in this.toolbarState){
            if (!this.toolbarState[button]){
                menuItemsCount++;
            }
        }
        if(!this.toolbarState.attachmentsAvailable){
            if(!this.toolbarState.showAttachments){
                menuItemsCount --;
            }
            if(!this.toolbarState.showGoToParent){
                menuItemsCount --;
            }
        }

        if(!this.toolbarState.attachmentsOpen){
            menuItemsCount--;
        }

        this.menuHeight = `${menuItemsCount*40}px`;
    }

    showPdfjsKebabMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
        this.bindGlobalClickListener();
    }


    // binding a scope listener --> the binding returns a deregister function
    private bindGlobalClickListener(): void {
        this.globalClickListener = this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, () => {
            this.isMenuOpen = false;
            if (this.globalClickListener != void 0) {
                this.globalClickListener.unsubscribe();
                this.globalClickListener = null;
            }
        });
    }


    ngOnDestroy(): void {
        this.globalClickListener?.unsubscribe();
    }

}
