import {Connection} from "ENUMS_PATH/connection.enum";

angular.module("eob.core").factory("scriptHelperModelService", ScriptHelperModelService);

ScriptHelperModelService.$inject = ["$stateParams", "dmsActionService", "dmsDocumentService", "clientService", "organisationService",
    "wfScriptingService", "environmentService", "clientScriptService", "notificationsService", "viewerService", "modalDialogService",
    "httpService", "cacheManagerService", "errorModelService", "formToolsService", "backendResourceService", "scriptingDmsService"];

// eslint-disable-next-line max-params, require-jsdoc
export default function ScriptHelperModelService($stateParams, DmsActionService, DmsDocumentService, ClientService, OrganisationService, WfScriptingService,
                                                 EnvironmentService, ClientScriptService, NotificationsService, ViewerService, ModalDialogService,
                                                 httpService, CacheManagerService, ErrorModelService, FormToolsService, BackendResourceService, ScriptingDmsService) {

    return {
        getScriptHelper,
        ScriptHelper
    }

    /**
     * Get a new ScriptHelper instance.
     * @returns {ScriptHelper} A scriptHelper.
     */
    function getScriptHelper() {
        return new ScriptHelper();
    }

    /**
     * Create a new ScriptHelper.
     * @constructor
     */
    function ScriptHelper() {
        let self = this,
            connectivityListener = [];

        self.globals = {};
        self.scriptingStorage = ClientScriptService.getGlobalScriptingStorage();

        // dms functions not depending on self.isCreate, etc.
        self.dms = ScriptingDmsService.getDmsActions();

        // workflow functions not depending on self.isWorkflow
        self.wf = WfScriptingService.getWfActions();

        // The Change Events on which the function stored in connectivityEvent are invoked
        ClientService.registerConnectivityChangeHandler(connectivityEvent);

        /**
         * Returns a boolean for the current online state.
         * @returns {boolean} Whether the client is online.
         */
        self.isOnline = function() {
            return ClientService.isOnline();
        };

        /**
         * Pushes functions on the connectivityListener array to invoke these functions if the connectivity changes.
         * @param {function}fn - The function, that will be stored.
         */
        self.onConnectivityStateChanged = function(fn) {
            if (typeof fn == "function") {
                connectivityListener.push(fn);
            }
        };

        /**
         * changes the document of the viewers (detailsviewer and document preview)
         * @param {string|number} osid - the osid to show
         */
        self.updateViewer = function(osid) {
            ViewerService.updateViewer(osid)
        }

        /**
         * Invokes stored connectivityChanged callback functions.
         * @param {string} currentConnectivity - The current connectivity state.
         */
        function connectivityEvent(currentConnectivity) {
            let isOnline = currentConnectivity != Connection.NONE;

            for (let listener of connectivityListener) {
                listener(isOnline);
            }
        }

        /**
         * Get information about the current user session.
         * @returns {Object} An object with user and session data.
         */
        self.getInfo = function() {
            let info = {};
            let data = EnvironmentService.getSessionInfo();

            info.user = {
                name: data.username,
                fullname: data.fullname,
                email: data.email,
                userId: data.userid,
                groups: data.groups,
                osGuid: data.osGuid,
                wfGuid: data.wfGuid,
                wfOrgId: data.wfOrg
            };

            info.session = {
                sessionGuid: data.sessionGuid,
                language: EnvironmentService.getLanguage()
            };

            return info;
        };
        // Enable child classes to use this function while overwriting it.
        ScriptHelper.prototype.getInfo = self.getInfo;

        /**
         * Get a specific dms organisation group.
         * @param {string|name} identifier - Either the groups id or name.
         * @returns {object} A dms group.
         */
        self.getGroup = function(identifier) {
            let groups = OrganisationService.getGroupList();
            return getOrgMember(groups, identifier);
        };

        /**
         * Get a specific dms organisation user.
         * @param {string|name} identifier - Either the users id or name.
         * @returns {object} A dms user.
         */
        self.getUser = function(identifier) {
            let users = OrganisationService.getUserList();
            return getOrgMember(users, identifier);
        };

        /**
         * Get all dms organisation users.
         * @returns {object[]} An array of dms users.
         */
        self.getUserList = function() {
            return angular.copy(OrganisationService.getUserList());
        };

        /**
         * Get all dms organisation groups.
         * @returns {object[]} An array of dms groups.
         */
        self.getGroupList = function() {
            return angular.copy(OrganisationService.getGroupList());
        };

        /**
         * Get the whole dms organisation as a flat list.
         * @returns {object[]} An array of dms groups and dms users.
         */
        self.getOrganisation = function() {
            return angular.copy(OrganisationService.getDmsOrgMembers());
        };

        /**
         * Get the whole wf organisation as a flat list.
         * @param {boolean} complete - Whether to only get user and roles or more.
         * @returns {object[]} An array of wf organisation objects.
         */
        // Had to be expanded by a parameter, because an old version only returned user and roles,
        // while later on a user requested to get the complete organisation.
        self.getWfOrganisation = function(complete) {
            if (!complete) {
                return [].concat(
                    self.getWfOrganisationMembers("Person"),
                    self.getWfOrganisationMembers("Rolle")
                );
            }

            return OrganisationService.getWfOrganisation();
        };

        /**
         * Get a specific wf organisation object.
         * @param {string} type - The organisation object type, like Person|Rolle|...
         * @param {*} identifier - Either the id or name of the organisation object.
         * @param {boolean=} getChildren - Include the children of the organisation object in the response.
         * @returns {object} A organisation object.
         */
        self.getWfOrganisationMember = function(type, identifier, getChildren) {
            let orgMembers = self.getWfOrganisationMembers(type, getChildren);
            return getOrgMember(orgMembers, identifier);
        };

        /**
         * Get wf organisation objects of a specific type.
         * @param {string} type - The organisation object type, like Person|Rolle|...
         * @param {boolean=} getChildren - Include the children of the organisation object in the response.
         * @returns {object[]} An array of wf organisation objects.
         */
        self.getWfOrganisationMembers = function(type, getChildren) {
            let orgObjects = OrganisationService.getWfOrgObjectsByType(type);

            if (!getChildren) {
                for (let i = 0; i < orgObjects.length; i++) {
                    let orgObject = orgObjects[i];
                    delete orgObject.children;
                }
            }

            return orgObjects;
        };

        /**
         * Fetches an arbitrary file from inside the configuration microservice (/resources/apps/).
         * Currently, DMS2 is used to communicate with the configuration microservice.
         * Only files from inside the public directory of a service can be fetched.
         * @param {string} url URL to append to path (e.g. osweb/public/magic.txt)
         * @param {"json" | "arraybuffer" | "text" | "text/javascript"} responseType Defaults to text
         * @returns {Promise<any>} response
         */
        self.getPublicResource = (url, responseType = "text") => {
            return BackendResourceService.getPublicResource(url, responseType).toPromise();
        }

        /**
         * Fetches a javascript from inside the configuration microservice (/resources/apps/).
         * Currently, DMS2 is used to communicate with the configuration microservice.
         * Only files from inside the public directory of a service can be fetched.
         * After fetching it will execute it and the script is visible like all other event scripts
         * and is debugable. We inject the scriptingStorage that the scripter can bind the loaded
         * script to it and have the availability to access it in other events later.
         *
         * @param url {string} url URL to append to path (e.g. osweb/public/magic.txt)
         * @param additionParameter Further parameter which should be available for the loaded scripts
         * @return {Promise<void>} response
         */
        self.includePublicScript = async (url, ...additionParameter) => {
            const script = await BackendResourceService.getPublicResource(url, "text/javascript").toPromise();
            const start = url.lastIndexOf("/") + 1;
            const name = url.substr(start, url.lastIndexOf(".js") - start);
            const func = FormToolsService.defineFunction(["scriptingStorage", "additionParameter"], script, `external_${name}`);
            func(ClientScriptService.getGlobalScriptingStorage(), additionParameter);
        }

        // add wf organisation objects for custom types
        WfScriptingService.addGetWfOrgObjectFns(self);

        /**
         * Get a specific organisation object from a list of organisation objects.
         * @param {object[]} orgMembers - A list of organisation objects.
         * @param {string} identifier - Either the organisation objects id or name.
         * @returns {object|null} The organisation object or null, if the organisation object couldn't be found.
         */
        function getOrgMember(orgMembers, identifier) {
            if (identifier == void 0) {
                return null;
            }

            identifier = identifier.toString();

            for (let member of orgMembers) {
                if (member.id == identifier || member.name.toLowerCase() == identifier.toLowerCase()) {
                    return angular.copy(member);
                }
            }
            return null;
        }

        /**
         * Shows toaster message
         * @param {"error" | "warning" | "info" | "success"} type - toast type
         * @param {string} message - the toast message
         * @param {string?} title - the toast title
         * @param {number?} timeout - timeout in milliseconds (default is 5000, 0 for unlimited)
         */
        self.showToast = function(type, message, title, timeout = 5000) {
            if (!/warning|info|error|success/.test(type) || arguments.length < 2) {
                throw ErrorModelService.createCustomError("WEB_PARAMETER_INVALID", "Correct toast type and message have to be set");
            }
            NotificationsService.showToast(type, message, title, timeout);
        };

        /**
         * Shows password dialog
         * @returns {deferred.promise|{then, catch, finally}}
         * @rejects If dialog is closed or user is locked after authentication attempt
         */
        self.showPasswordDialog = function() {
            return ModalDialogService.showPasswordDialog();
        };

        /**
         * Executes on electron a program on the host machine.
         * @param {string} pathToProgram - The absolute or relative path to the program to execute.
         * @param {string} programArguments - Arguments to send to the program
         * @param {boolean} returnResult - true if the call should wait on called program exit, false not.
         */
        self.execProgramAsync = async function(pathToProgram, programArguments = null, returnResult = false) {
            return await ClientService.execProgramAsync(pathToProgram, programArguments, returnResult);
        };

        self.getPlatformInfo = function() {
            const localClient = ClientService.isLocalClient();
            const desktop = ClientService.isDesktop();
            const mobile = ClientService.isMobile();
            const mobileBrowser = ClientService.isMobileBrowser();
            const phone = ClientService.isPhone();
            const ios = ClientService.isiOs();
            const android = ClientService.isAndroid();
            const win = ClientService.isWindows();
            let object = {};
            object.web = !localClient;
            object.app = mobile;
            object.desktop = desktop;
            object.phone = phone && (mobile || mobileBrowser);
            object.tablet = !phone && (mobile || mobileBrowser);
            object.ios = ios;
            object.android = android;
            object.windows = win;

            return object;
        };

        /**
         * Shows edit content dialog for document with a given osid.
         * @param {string|number} osid - osid of the document which content should be edited
         * @param {boolean} showIfHasContent - whether dialog needs to be shown if the document already has content
         * @param {boolean} showTemplates - whether "templates" tab needs to be shown
         * @param {boolean} showCustomContent - whether "content" and "external tray" tabs need to be shown
         * @returns {Promise<object>} - returns uploaded file info similar to FormHelper#getFileInfo
         */
        self.showEditContentDialog = async function(osid, showIfHasContent = true, showTemplates = true, showCustomContent = true) {
            if (!DmsDocumentService.isValidOsid(osid) || $stateParams.osid === osid.toString()) {
                throw ErrorModelService.createParameterError("id", osid);
            }

            const dmsDocument = await CacheManagerService.dmsDocuments.getOrFetchById(osid);

            if (dmsDocument == void 0) {
                throw ErrorModelService.createCustomError("WEB_OBJECT_NOT_FOUND", osid);
            } else if (!DmsDocumentService.isEditContentAllowed(dmsDocument, CacheManagerService.objectTypes.getById(dmsDocument.model.objectTypeId).model.config)) {
                throw ErrorModelService.createCustomError("WEB_EDIT_CONTENT_NOT_ALLOWED");
            } else if (dmsDocument.model.baseParameters.locked !== "UNLOCKED") {
                throw ErrorModelService.createCustomError("WEB_OBJECT_LOCKED_OTHER");
            } else if (!showIfHasContent && dmsDocument.model.hasContent) {
                throw "The document already has content.";
            } else if (!showCustomContent && !showTemplates) {
                throw "No templates, dropzone or external tray elements are shown: user is not able to edit the content";
            } else if (!showCustomContent && (dmsDocument.model.mainType !== "4" ||
                (dmsDocument.model.mainType == "4" && EnvironmentService.getTemplates(dmsDocument.model.objectTypeId).length == 0))) {
                throw "There are no templates available for this object and no dropzone or external tray elements are shown: user is not able to edit the content";
            }

            DmsActionService.checkOut(dmsDocument);

            let dropzoneConfig = {
                showTemplates,
                showCustomContent,
                isFileInfoNeeded: true
            }

            try {
                return ModalDialogService.editDocumentContentDialog(dmsDocument, { "attributes": dropzoneConfig });
            } catch (error) {
                throw error;
            }
        }

        /**
         * Shows checkin content dialog for document.
         * @param {string|number} osid - osid of the document which content should be checked in
         * @param {boolean} showExternalTrayItems - whether "external tray" tab needs to be shown
         * @returns {Promise<object>} - returns checked in file info similar to FormHelper#getFileInfo
         */
        self.showCheckinContentDialog = async function(osid, showExternalTrayItems = true) {
            if (!DmsDocumentService.isValidOsid(osid)) {
                throw ErrorModelService.createParameterError("id", osid);
            }

            const dmsDocument = await CacheManagerService.dmsDocuments.getOrFetchById(osid, undefined, true);

            if (dmsDocument === null) {
                throw ErrorModelService.createCustomError("WEB_OBJECT_NOT_FOUND", osid);
            } else if (!DmsDocumentService.isEditContentAllowed(dmsDocument, CacheManagerService.objectTypes.getById(dmsDocument.model.objectTypeId).model.config)) {
                throw ErrorModelService.createCustomError("WEB_EDIT_CONTENT_NOT_ALLOWED");
            } else if (dmsDocument.model.baseParameters.locked === "UNLOCKED") {
                throw ErrorModelService.createCustomError("WEB_OBJECT_NOT_LOCKED");
            } else if (dmsDocument.model.baseParameters.locked !== "SELF") {
                throw ErrorModelService.createCustomError("WEB_OBJECT_LOCKED_OTHER");
            }

            let dropzoneConfig = { isFileInfoNeeded: true, showExternalTrayItems };

            try {
                return await ModalDialogService.dropzoneDialog(dmsDocument, { "attributes": dropzoneConfig });
            } catch (error) {
                throw error;
            }
        }

        self.destroy = function() {
            ClientService.unregisterConnectivityChangeHandler(connectivityEvent);

            connectivityListener = [];
            self = undefined;
        };

        ScriptHelper.prototype.destroy = self.destroy;

        /**
         * Bind all necessary scripts and execute the global ones.
         *
         * @param {{objectTypeId: number, name: string}=} scriptData - Script data, to create the script names.
         * @returns {Promise<void>} A promise resolved once the global functions have been executed.
         */
        ScriptHelper.prototype.bindScripts = async function(scriptData) {
            await ClientScriptService.executeGlobalScriptsAsync(self, scriptData);
        };
    }
}
