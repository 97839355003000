/**
 * Enum defining the available entry types
 */
export enum DatabaseEntryType {
    /**
     * persistent global storage
     */
    GLOBAL = "global",

    /**
     * persistent profile specific storage
     */
    PERSISTENT = "persistent",

    /**
     * temporary profile specific storage
     */
    TEMPORARY = "temporary"
}