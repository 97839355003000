import {Injectable, Inject} from "@angular/core";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";

@Injectable({
    providedIn: "root"
})
export class AgGridService {
    constructor(private toolService: ToolService,
                private clientService: ClientService,
                @Inject("$state") protected $state: any,
                private layoutManagerService: LayoutManagerService) {
    }

    private defaultOptions: any = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            filter: true,
            suppressMenu: true
        },
        suppressContextMenu: true,
        suppressNoRowsOverlay: true,
        rowSelection: "multiple",
        rowHeight: this.layoutManagerService.isTouchLayoutActive() ? 40 : 32,
        headerHeight: this.layoutManagerService.isTouchLayoutActive() ? 40 : 32,
        onGridSizeChanged: (gridEvent: any) => gridEvent.api.sizeColumnsToFit()
    };

    getDefaultOptions(...options: any): any {
        const gridOptions: any = this.toolService.mergeDeep(this.defaultOptions, ...options);

        return gridOptions;
    }

    getRowHeight(): number {
        if (this.clientService.isPhone()) {
            return this.$state.current.name == "hitlist.offlineObjects" || this.$state.current.name == "hitlist.failedSyncObjects" || this.$state.current.name == "hitlist.objectReferences" ? 100 : 80;
        }

        if (this.layoutManagerService.isTouchLayoutActive()) {
            return 40;
        }

        return 32;
    }

    // static enableExternalFilter(gridOptions: any, filterFn: () => string): void {
    //     gridOptions.isExternalFilterPresent = () => true;
    //     gridOptions.doesExternalFilterPass = (node: any) => {
    //         if (!node.quickFilterAggregateText) {
    //             node.gridApi.filterManager.aggregateRowForQuickFilter(node);
    //             node.quickFilterAggregateText = he.decode(node.quickFilterAggregateText).toLowerCase();
    //             node.quickFilterAggregateText = node.quickFilterAggregateText.replace(/<[^>]+>_?/g, ""); // HTML entfernen
    //         }
    //
    //         return node.quickFilterAggregateText.indexOf(filterFn()) >= 0;
    //     };
    //     gridOptions.onFilterChanged = (gridEvent: any) => {
    //         // deselect filtered rows
    //         const selectedNodes: any = gridEvent.api.getSelectedNodes();
    //         const displayedNodes: any = gridEvent.api.getModel().rowsToDisplay;
    //
    //         selectedNodes.forEach(row => {
    //             if (row.selected && !displayedNodes.includes(row => row.id === row.id)) {
    //                 row.selected = false;
    //             }
    //         });
    //     };
    // }

    static selectFirstRow(gridOptions: any): void {
        const node: any = gridOptions.api.getDisplayedRowAtIndex(0);
        AgGridService.selectRow(gridOptions, node);
    }

    static selectLastRow(gridOptions: any): void {
        // const nodes: any = gridOptions.api.getRenderedNodes();
        const nodes: any[] = gridOptions.api.rowModel.rowsToDisplay;

        if (nodes.length > 0) {
            AgGridService.selectRow(gridOptions, nodes[nodes.length - 1]);
        }
    }

    static selectNextRow(gridOptions: any, nodes: any[]): void {
        if (nodes.length === 0) {
            return;
        }

        const lastEntry: any = nodes[nodes.length - 1];
        const afterLastIndex: number = lastEntry.rowIndex - nodes.length + 1;

        const nextLastNode: any = gridOptions.api.getDisplayedRowAtIndex(afterLastIndex);
        if (nextLastNode !== undefined) {
            AgGridService.selectRow(gridOptions, nextLastNode);
            return;
        }

        const nextFirstNode: any = gridOptions.api.getDisplayedRowAtIndex(afterLastIndex);
        if (nextFirstNode !== undefined) {
            AgGridService.selectRow(gridOptions, nextFirstNode);
            return;
        }

        AgGridService.selectLastRow(gridOptions);
    }

    static selectRow(gridOptions: any, node: any): void {
        if (node !== undefined) {
            node.setSelected(true);
            gridOptions.api.ensureIndexVisible(node.rowIndex);
        }
    }

    static getAllRows(gridOptions: any): any[] {
        const data: any = [];
        gridOptions.api.forEachNode(node => data.push(node.data));
        return data;
    }

    static moveSelectedUp(gridOptions: any): void {
        return this.moveSelected(gridOptions, true);
    }

    static moveSelectedDown(gridOptions: any): void {
        return this.moveSelected(gridOptions, false);
    }

    private static moveSelected(gridOptions: any, moveUp: boolean): void {
        // let nodes: any[] = gridOptions.api.getRenderedNodes();
        let nodes: any[] = gridOptions.api.rowModel.rowsToDisplay;
        const data: any[] = nodes.map(node => node.data);

        const moveBy: number = moveUp ? -1 : 1;
        nodes = moveUp ? nodes : nodes.reverse();

        const selectedIndices: number[] = [];
        let canShift = false;
        for (const node of nodes) {
            // start shifting rows after we find the first row, that is not selected
            if (!node.selected) {
                if (!canShift) {
                    canShift = true;
                }
                continue;
            }

            // shift the row and/or remember the index of selected rows
            const index: number = node.rowIndex;
            if (canShift) {
                selectedIndices.push(index + moveBy);
                data.splice(index, 1);
                data.splice(index + moveBy, 0, node.data);
            } else {
                selectedIndices.push(index);
            }
        }

        gridOptions.api.setRowData(data);

        if (selectedIndices.length > 0) {
            // reset selection
            gridOptions.api.forEachNode((node, i) => {
                if (selectedIndices.includes(i)) {
                    node.setSelected(true);
                }
            });
            gridOptions.api.ensureNodeVisible(selectedIndices[0]);
        }
    }
}
