export class OfflineObjectSyncStatus {
    osid?: string;
    doneCount: number;
    failedCount: number;
    childCount?: number;

    constructor(doneCount: number, failedCount: number, childCount: number) {
        this.doneCount = doneCount;
        this.failedCount = failedCount;
        this.childCount = childCount;
    }

    get percentage(): number {
        if (this.childCount == undefined) {
            return 0;
        } else if (this.childCount == 0) {
            return this.failedCount + this.doneCount;
        } else {
            return (this.failedCount + this.doneCount) / this.childCount;
        }
    }
}