import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {FieldMask, FieldMaskValue} from "MODULES_PATH/form/models/field-mask.model";

export class LeadingZerosMask extends FieldMask {
    constructor(private valueUtilsService: ValueUtilsService, private maxLength: number) {
        super();
    }

    formatBlur(maskValue: FieldMaskValue): FieldMaskValue {
        return {
            value: maskValue.value,
            rendererValue: maskValue.rendererValue == "" ? maskValue.rendererValue : this.valueUtilsService.formatLeadingZeros(maskValue.rendererValue.toString(), this.maxLength)
        };
    }
}