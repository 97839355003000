<div [ngClass]="expanded ? 'expanded' : 'collapsed'">
    <button #panelHeading class="panel-heading button-transparent" (click)="togglePanel($event)" (focus)="setFocus()">
        <eob-icon *ngIf="!isNavPanel" name="toggle-arrow-gray" class="toggle-arrow" size="16"></eob-icon>
        <eob-icon *ngIf="isNavPanel" name="toggle-arrow-white" class="toggle-arrow" size="16"></eob-icon>
        <h5 class="panel-title" [attr.lang]="panelHeadingLang ? panelHeadingLang : null">{{eobPanelTitle}}</h5>
        <div class="plugin-container">
            <ng-content select="[panelPlugin]"></ng-content>
        </div>
    </button>
    <div #panelContent class="panel-body" (click)="triggerClickOnPanelBody($event)">
        <ng-content select="[panelContent]"></ng-content>
    </div>
</div>
