import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {OsrestSubscriptionService} from "CORE_PATH/backend/modules/osrest/services/inbox/osrest-subscription.service";
import {Observable} from "rxjs";
import {InboxContextMenuItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ConfirmationObject, InboxActionContextMenuItem, AboGroupPayload} from "CORE_PATH/interfaces/inbox-action.service.interface";

@Injectable({providedIn: BackendModule})
export class BackendSubscriptionService {

    constructor(private delegate: OsrestSubscriptionService) {
    }

    /**
     * remove subscriptions
     *
     * @param {ContextItem[]} data
     */
    removeSubscriptions(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.delegate.removeSubscriptions(data);
    }

    /**
     * mark read subscriptions
     *
     * @param {ContextItem[]} data
     */
    markReadSubscriptions(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.delegate.markReadSubscriptions(data);
    }

    /**
     * mark unread subscriptions
     *
     * @param {ContextItem[]} data
     */
    markUnreadSubscriptions(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.delegate.markUnreadSubscriptions(data);
    }

    /**
     * mark as edited unedited subscriptions
     *
     * @param {ContextItem[]} data
     */
    markAsEditedUneditedSubscriptions(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.delegate.markAsEditedUneditedSubscriptions(data);
    }

    /**
     * mark single as edited unedited subscriptions
     *
     * @param {InboxContextMenuItem} contextItem
     * @param {ConfirmationObject} confirmationObject
     */
    markSingleAsEditedUneditedSubscriptions(contextItem: InboxContextMenuItem, confirmationObject: ConfirmationObject): Observable<void> {
        return this.delegate.markSingleAsEditedUneditedSubscriptions(contextItem, confirmationObject);
    }

    /**
     * delete subscription objects
     *
     * @param {Payload} payload
     */
    deleteSubscriptionObjects(payload: AboGroupPayload[]): Observable<void> {
        return this.delegate.deleteSubscriptionObjects(payload);
    }
}
