import {Injectable} from "@angular/core";
import {DashletService} from "MODULES_PATH/dashlet/interfaces/dashlet-service.interface";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {DashletModule} from "MODULES_PATH/dashlet/dashlet.module";

@Injectable({
    providedIn: "root"
})
export class O365DashletService implements DashletService {
    constructor(private viewerService: ViewerService) {
    }

    update(osid: number, dmsDocument: DmsDocument): void {
        void this.viewerService.updateO365Viewer(osid, dmsDocument);
    }

    refresh(osid: number, keepCache: boolean): void {
    }

    clear(): void {
    }

    sendEvent(eventKey: string, data: unknown): void {
    }
}