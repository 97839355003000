import {Directive, ElementRef, Injector} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {ajsServiceProviders as providers} from "ROOT_PATH/eob-core/ajs.upgraded.providers";

/**
 * The directive wrapper for the eob-main.
 */
@Directive({
    selector: "eob-main",
    providers
})
export class EobMainDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super("eobMain", elementRef, injector);
    }
}