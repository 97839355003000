import {Component, ElementRef, Renderer2, OnInit, Inject, ViewChild, OnChanges, SimpleChanges, OnDestroy} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {StateService} from "@uirouter/core";
import {Input} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {DropDownConfig} from "INTERFACES_PATH/dropdown.interface";
import {Subscription} from "rxjs";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";

@Component({
    selector: "eob-dropdown",
    templateUrl: "./eob-dropdown.component.html",
    styleUrls: ["./eob-dropdown.component.scss"]
})

export class EobDropdownComponent implements OnInit, OnChanges, OnDestroy {

    constructor(protected clientService: ClientService,
                @Inject("$state") private $state: StateService,
                @Inject("layoutManagerService") private layoutManagerService: any,
                private messageService: MessageService,
                private el: ElementRef,
                private renderer: Renderer2) {
    }

    @ViewChild("dropdownActiveElement", {static: false}) dropdownActiveElement: ElementRef<HTMLElement>;
    @ViewChild("dropdown", {static: false}) dropdownElement: ElementRef<HTMLElement>;
    @ViewChild("dropdownContainer", {static: false}) dropdownContainer: ElementRef<HTMLElement>;
    @ViewChild("dropdownItem", {static: false}) dropdownItem: ElementRef<HTMLElement>;
    @Input() dropdownconfig: DropDownConfig;

    globalListenerUnbindFunction: Subscription = null;
    dropDownOpen = false;
    isInit = true;
    selection: any;
    dropdownScroll = false;
    dropDownCallback: any;

    ngOnDestroy(): void {
        this.globalListenerUnbindFunction?.unsubscribe();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.isInit = false;
        }, 150);

        const config: any = {
            emptyPlaceholder: { name: "< leer >", placeholder: true },
            showEmptyPlaceholder: false,
            callbackAfterInit: false
        };

        this.dropdownconfig = {...config, ...this.dropdownconfig};

        this.dropDownCallback = this.dropdownconfig.callback;

        this.populateDropdownSelection();

        if (this.dropdownconfig.callbackAfterInit && this.dropDownCallback) {
            this.dropDownCallback(this.selection);
        }

        this.messageService.subscribe("populate-dropdown-selection", () => {
            this.populateDropdownSelection();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.populateDropdownSelection();
    }

    setItemActive = (item: any): void => {
        this.selection.isActive = false;
        this.selection = item;
        if (!item.placeholder) {
            item.isActive = true;
        }
        this.dropdownconfig.callback?.(item);
    };

    populateDropdownSelection = (): void => {
        this.selection = null;
        if (this.dropdownconfig?.items) {
            if (this.dropdownconfig.items.length === 0 && this.dropdownconfig.showEmptyPlaceholder) {
                this.dropdownconfig.items.push(this.dropdownconfig.emptyPlaceholder);
            }

            if (this.dropdownconfig.items.length > 1 && this.dropdownconfig.items[0].placeholder) {
                this.dropdownconfig.items.shift();
            }

            for (const item of this.dropdownconfig.items) {
                if (item.isActive) {
                    this.selection = item;
                }
            }

            if (!this.selection && this.dropdownconfig.items && this.dropdownconfig.items.length > 0) {
                console.info("Selected first item due to lack of selected item");
                this.selection = this.dropdownconfig.items[0];

                if (!this.isInit || (this.isInit && this.dropdownconfig.callbackAfterInit)) {
                    this.dropDownCallback?.(this.selection);
                }
            }
        }
    };

    toggleDropdown = (): void => {
        this.dropdownScroll = false;
        this.dropDownOpen = !this.dropDownOpen;
        if (this.dropDownOpen) {
            this.bindGlobalListener();
            if (this.dropdownconfig.useFullHeight) {
                const {x, y}: any = this.el.nativeElement.getBoundingClientRect();
                const dropdownActiveElementHeight: number = this.dropdownActiveElement.nativeElement.clientHeight;
                const spaceAvailable: number = window.innerHeight - y - dropdownActiveElementHeight;
                const elementHeight: number = this.layoutManagerService.isTouchLayoutActive() ? 40 : 32;
                let dropdownHeight: number = this.dropdownconfig.items.length * elementHeight + 16;

                if (spaceAvailable < dropdownHeight) {
                    dropdownHeight = spaceAvailable - 8;
                    this.dropdownScroll = true;
                }

                this.renderer.setStyle(this.dropdownContainer.nativeElement, "bottom", "auto");
                this.renderer.setStyle(this.dropdownElement.nativeElement, "height", `${dropdownHeight}px`);

                this.renderer.setStyle(this.dropdownElement.nativeElement, "scrollTop", 2);

                const index: number = this.dropdownconfig.items.findIndex(element => element.isActive);
                if (index >= 0) {
                    const itemheight: number = this.dropdownItem.nativeElement.clientHeight;
                    this.dropdownElement.nativeElement.scrollTop = itemheight * index;
                }
            }
        }
    };

    bindGlobalListener = () => {
        this.globalListenerUnbindFunction = this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, (event: Event) => {
            if(!this.el.nativeElement.contains(event?.target)) {
                this.dropDownOpen = false;
                this.unbindGlobalListener();
            }
        });
    };

    unbindGlobalListener = () => {
        this.globalListenerUnbindFunction.unsubscribe();
    };
}
