import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";
import {FieldExpander} from "INTERFACES_PATH/validation.interface";
import {CorrectionFieldMask} from "MODULES_PATH/form/models/field-mask.model";
import { WfOrganisationObject, WfUserOrgObject } from "INTERFACES_PATH/wf-organisation-object.interface";
import { OrganisationConfig } from "MODULES_PATH/form/interfaces/form-addon-config.interface";

export class OrgMask extends CorrectionFieldMask {
    constructor(private field: FieldExpander) {
        super();
    }

    parseValue(inputValue: FormInputValue): FormInputValue {
        let values: string[] = inputValue?.toString().split(";") || [];

        // remove empty values
        values = values.filter(v => v?.trim().length > 0);

        // parse values
        const parsedMembers: WfOrganisationObject[] = [];
        const parsedValues: string[] = values.map(value => {
            const lowerCaseValue: string = value.toLowerCase();

            // check already made entries first for performance reasons
            let match = this.field.orgValues?.find(vMember => lowerCaseValue === vMember.name.toLowerCase());
            if (!match) {
                match = Object.values((this.field.model.config as OrganisationConfig).orgMember).find(cMember => {
                    const isId: boolean = cMember.id.toLowerCase() == lowerCaseValue;
                    const isName: boolean = cMember.name.toLowerCase() == lowerCaseValue;
                    const isLastName: boolean = (cMember as WfUserOrgObject).nachname?.toLowerCase() == lowerCaseValue;
                    const isFirstName: boolean = (cMember as WfUserOrgObject).vorname?.toLowerCase() == lowerCaseValue;
                    return (isId || isName || isLastName || isFirstName);
                });
            }

            if (match !== undefined) {
                parsedMembers.push(match);
            }
            return match?.name || value;
        });

        // at this point we write the corrected parts back into the field
        this.field.orgValues = parsedMembers;

        return parsedValues.join(";");
    }
}