import {Injectable} from "@angular/core";
import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {
    ContextMenuAction,
    ContextMenuActionsProvider,
    ContextMenuItem,
    ContextType
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {ContextMenuActionsService} from "MODULES_PATH/context-menu/services/context-menu-actions.service";
import {MultiMenuService} from "MODULES_PATH/context-menu/services/multi-menu.service";
import {defer, Observable} from "rxjs";

@Injectable({
    providedIn: ContextMenuModule
})
export class FolderContextMenuService implements ContextMenuActionsProvider {

    constructor(private contextMenuActionsService: ContextMenuActionsService,
                private multiMenuService: MultiMenuService) {
    }

    getSingleSelectionContextMenuActions(contextMenuItem: ContextMenuItem, contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            const contextMenuActions: ContextMenuAction[] = [];

            if (contextData.context == ContextType.EMPTYSPACEINHITLIST) {
                await this.contextMenuActionsService.getSpecialFolderItems(contextMenuItem, contextMenuActions);
            } else {
                await this.contextMenuActionsService.getSingleselectItems(contextMenuItem, contextMenuActions, contextData.context, contextData);
            }

            return contextMenuActions;
        });
    }

    getMultiselectionContextMenuActions(contextMenuItems: ContextMenuItem[], contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            const contextMenuActions: ContextMenuAction[] = [];

            await this.multiMenuService.getMultiselectActions(contextMenuItems, contextMenuActions, contextData.context, contextData);

            return contextMenuActions;
        });
    }
}
