import {Component, ElementRef, Inject, OnDestroy, OnInit} from "@angular/core";
import {HitlistLoadingState} from "MODULES_PATH/hitlist/model/loading-state.model";
import {Progressbar, ProgressbarService} from "SERVICES_PATH/eob.progressbar.srv";
import {StateService} from "@uirouter/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {TodoSearchService} from "INTERFACES_PATH/any.types";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {Subject} from "rxjs";

@Component({
    selector: "eob-failed-sync-objects",
    templateUrl: "./failed-sync-objects.component.html",
    styleUrls: ["./failed-sync-objects.component.scss"]
})
export class FailedSyncObjectsComponent implements OnInit, OnDestroy {
    private stateProgressBar: Progressbar;
    private readonly translateFn: TranslateFnType;

    stateTitle: string;
    loadingState: HitlistLoadingState = new HitlistLoadingState();
    hitlistItems: Subject<DmsDocument[]> = new Subject<DmsDocument[]>();

    constructor(
        @Inject("$filter") private $filter: ng.IFilterService,
        @Inject("$state") private $state: StateService,
        @Inject("searchService") private searchService: TodoSearchService,
        private progressbarService: ProgressbarService,
        private notificationsService: NotificationsService, private clientService: ClientService,
        private el: ElementRef
    ) {
        this.translateFn = this.$filter("translate");
        this.loadingState.hasloadSyncAnimation = true;
        this.stateTitle = this.translateFn("eob.sync.failed.view.description");
    }

    async ngOnInit(): Promise<void> {

        try {
            this.loadingState.isLoading = true;
            const searchResult = await this.searchService.executeFailedSyncObjectsSearch();

            this.hitlistItems.next(searchResult.result);

            if (searchResult.warning) {
                this.notificationsService.warning(searchResult.warning);
            }

            if (searchResult.error) {
                this.notificationsService.error(searchResult.error);
            }
        } catch {
            this.loadingState.hasErrors = true;
            this.loadingState.isLoading = false;
        } finally {
            this.loadingState.isLoading = false;
            this.loadingState.showHitlist = true;
        }

        this.stateProgressBar = this.progressbarService.getProgressbarInstance("loadSyncAnimation", this.el.nativeElement, true);
        this.clientService.registerSynchronizationChangeHandler(this.onSynchronizationStateChanged);

        if (await this.clientService.getIsSynchronizing()) {
            this.stateProgressBar.show();
        }
    }

    /**
     * Callback when a synchronization of offline favorites was triggered, aborted or finished.
     *
     *  @param {boolean} isSynchronizing - Whether the synchronisation of offline favorites is running now.
     */
    private onSynchronizationStateChanged = (isSynchronizing: boolean): void => {
        if (isSynchronizing) {
            this.stateProgressBar.show();
        } else {
            this.stateProgressBar.hide();
            this.updateFailedSyncObjects();
        }
    };

    /**
     * Get the failed offline objects and update the hitlist.
     */
    private async updateFailedSyncObjects(): Promise<void> {
        await this.$state.reload();
    }

    ngOnDestroy(): void {
        this.clientService.unregisterSynchronizationChangeHandler(this.onSynchronizationStateChanged);
    }

}
