import {FormControl} from "@angular/forms";
import {DropzoneMessage} from "MODULES_PATH/dropzone/components/ems-dropzone/ems-dropzone.component";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {ProfileCacheKey} from "ENUMS_PATH/database/profile-cache-key.enum";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {FormFieldType} from "../../../app/modules/form/enums/form-field-type.enum";

require("SERVICES_PATH/eob.modal.dialog.srv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/mobile-desktop/eob.external.tray.srv.js");
require("SERVICES_PATH/eob.state.location.srv.js");

const dayjs = require("dayjs");

angular.module("eob.core").factory("formService", FormService);

FormService.$inject = ["notificationsService", "modalDialogService", "stateHistoryManager", "environmentService", "viewerService",
    "backendService", "valueUtilsService", "actionService", "errorModelService", "formValidationService", "cacheManagerService",
    "dmsActionService", "$stateParams", "$location", "$timeout", "$rootScope", "$filter", "$state", "$q", "dmsContentService",
    "clientService", "externalTrayService", "fileCacheService", "locationService", "stateService", "objectTypeService",
    "clientScriptService", "messageService", "offlineCacheService", "messageService", "backendVariantsService", "backendObjectService"];

/**
 * A service that contains functions to submit or cancel forms.
 */
export default function FormService(NotificationsService, ModalDialogService, StateHistoryManager, EnvironmentService, ViewerService,
                                    BackendService, ValueUtilsService, ActionService, ErrorModelService, FormValidationService, CacheManagerService,
                                    DmsActionService, $stateParams, $location, $timeout, $rootScope, $filter, $state, $q, DmsContentService,
                                    ClientService, ExternalTrayService, FileCacheService, LocationService, StateService, ObjectTypeService, ClientScriptService,
                                    messageService, OfflineCacheService, MessageService, BackendVariantsService, BackendObjectService) {

    let autoSaveInterval = null;
    let emsDropzoneContents = [];

    // Todo DODO-13393
    // let beforeCancelExecuted = {isExecuted: false, formHelper: {}};

    // Due to inconsistent event script return values, we have to treat certain events differently
    const zeroRejectEvents = /(beforeOpen|beforeCancel|onShow|beforeValidate|beforeForward|beforeSave|afterSave|afterValidate)/i

    const PERFORMER_RECORD_ID = "E89E5D499A4D4D6F80BFCD18008A8709";
    let api = {
        init,
        transformRoutingList,
        executeFormScript,
        submitSearchForm,
        submitCombinedSearchForm,
        updateIndexData,
        prefillFormData,
        addWorkflowData,
        forwardWorkflow,
        insertDocument,
        insertEmails,
        reduceFormdata,
        createFormData,
        submitWorkflow,
        cancel,
        insertToWfTray,
        disableFieldsAsync,
        addIndexData,
        saveWorkflow,
        validateForm,
        getMailExtraction,
        typeDocument,
        initFormAutoSave,
        restoreAutosavedIndexdata,
        convertRoutingListForSerialization,
        getFieldDeepTabOrder,
        sortFieldsByOrder

        // Todo DODO-13393
        // setBeforeCancelExecuted,
        // getBeforeCancelExecuted
    };
    return api;

    function init() {
        // bootstrap either the combined or normal queries for the search state
        if (EnvironmentService.featureSet.contains("dms.search.combination")) {
            require("COMPONENTS_PATH/eob-state-wrapper/eob-combined-search/eob.combined.search.dir.js");
        } else {
            require("COMPONENTS_PATH/eob-state-wrapper/eob-search/eob.search.dir.js");
        }
        messageService.subscribe(DropzoneMessage.DROPZONE_CONTENT_UPDATED, value => {
            emsDropzoneContents = value
        })
    }

    // Todo DODO-13393
    // function setBeforeCancelExecuted(_beforeCancelExecuted) {
    //     beforeCancelExecuted = _beforeCancelExecuted;
    // }
    //
    // function getBeforeCancelExecuted() {
    //     return beforeCancelExecuted;
    // }

    function executeFormScript(scriptName, formHelper, isSave) {

        // Todo DODO-13393
        // if (scriptName === "beforeCancel") {
        //     beforeCancelExecuted = {isExecuted: true};
        // }

        let deferred = $q.defer();
        let formScript = formHelper.getFormScript(scriptName);

        try {
            if (formScript != void 0) {
                let scriptingStorage = ClientScriptService.getGlobalScriptingStorage();
                let done = (resultCode) => {
                    if (resultCode == void 0 || resultCode > 0 || (resultCode >= 0 && !zeroRejectEvents.test(scriptName))) {
                        deferred.resolve(resultCode);
                    } else {
                        // no error occured, but the scripter told us to stop
                        deferred.reject(resultCode);
                    }
                };

                let args = isSave != void 0 ? [formHelper, formHelper.globals, scriptingStorage, isSave, done] : [formHelper, formHelper.globals, scriptingStorage, done];

                if (scriptName == "beforeOpen") {
                    // Add origin argument for onShow script
                    let stateId = $location.search().state;
                    let stateConfig = StateHistoryManager.getStateData(stateId);
                    args.splice(3, 0, stateConfig.data.config.userAction);
                }

                formScript(...args);
            } else {
                deferred.resolve();
            }
        } catch (err) {
            let errorMessage = "";
            errorMessage += `${$filter("translate")("eob.form.script.returned.error.verbose").replace("%s1", scriptName)}\n\n`;
            console.error(errorMessage, err);
            errorMessage += err.stack;

            ModalDialogService.errorInfoDialog($filter("translate")("eob.notification.error.title"), $filter("translate")("modal.confirm.dms.get.script.error.message"), $filter("translate")("modal.button.close"), errorMessage).then(null, () => {
                deferred.reject(err);
            }).catch(err => console.error(err));
        }

        return deferred.promise;
    }

    function addFilesToPayload(payload, formHelper) {
        let files = formHelper.getWfFiles();

        for (let id in files) {
            let file = files[id];

            // we need to give the backend the active id, whatever the configuration
            let wfId = (!file.model.useActiveVariant && file.model.activeVariantId != void 0) ? file.model.activeVariantId : id;
            payload.files.push(wfId);

            if (file.model.isNew || file.model.modified) {
                let entry = {
                    id: wfId,
                    objectTypeId: file.model.objectTypeId,
                    workspace: file.model.workspace ? "1" : "0",
                    location: file.model.location,
                    movable: file.model.movable,
                    deletable: file.model.deletable,
                    useActiveVariant: file.model.useActiveVariant
                };

                payload.verboseFiles.push(entry);
            }
        }
    }

    function validateWorkflow(formHelper) {
        let formData = formHelper.getFields(),
            routingList = formHelper.getRoutingList();

        let deferred = $q.defer();

        let validateTabs = [{
            "tabName": "mask",
            "fields": formData
        }];

        if (routingList != void 0) {
            validateTabs.push({
                "tabName": "routingList",
                "fields": routingList.api.getFields()
            });
        }

        let promises = [];
        angular.forEach(validateTabs, (tabData) => {
            let validationPromise = $q.defer();
            promises.push(validationPromise.promise);

            validateForm(tabData.fields).then(() => {
                validationPromise.resolve();
                return;
            }, () => {
                // See $q.all. We need the tabName. The error object is created in $q.all
                validationPromise.reject(tabData.tabName);
                return;
            }).catch(err => console.error(err));
        });

        $q.all(promises).then(() => {
            deferred.resolve();
            return;
        }, (tabName) => {
            formHelper.setActiveWorkflowTab(tabName);
            deferred.reject(ErrorModelService.createCustomError("WEB_VALIDATION_DETECTED_ERRORS"));
            return;
        }).catch(err => console.error(err));

        return deferred.promise;
    }

    function saveWorkflow(formHelper, closeWorkflowMask = true) {
        let deferred = $q.defer();
        validateWorkflow(formHelper).then(() => {
            executeFormScript("beforeCancel", formHelper, true).then(() => {
                api.submitWorkflow(formHelper, `/workflows/cancel?save=true&clienttype=${EnvironmentService.wfClientType}`, false).then(() => {
                    deferred.resolve();
                    NotificationsService.success($filter("translate")("eob.form.workflow.save.success"));
                    if (closeWorkflowMask) {
                        goBack();
                    }
                    return;
                }, (error) => {
                    deferred.reject(error);

                    // the action might just be cancelled without an error happening
                    if (error) {
                        NotificationsService.backendError(error, "eob.form.workflow.save.error");
                    }
                }).catch(err => console.error(err));
                return;
            }, deferred.reject).catch(err => console.error(err));
            return;
        }, deferred.reject).catch(err => console.error(err));

        return deferred.promise;
    }

    function forwardWorkflow(formHelper) {
        let deferred = $q.defer();

        validateWorkflow(formHelper).then(() => {
            executeFormScript("beforeForward", formHelper).then(() => {
                api.submitWorkflow(formHelper, `/workflows/forward?clienttype=${EnvironmentService.wfClientType}`, false).then(() => {
                    deferred.resolve();
                    NotificationsService.success($filter("translate")("eob.form.workflow.forward.success"));
                    goBack();
                    return;
                }, (error) => {
                    deferred.reject(error);

                    // the action might just be cancelled without an error happening
                    if (error) {
                        NotificationsService.backendError(error, "eob.form.workflow.forward.error");
                    }
                }).catch(err => console.error(err));
                return;
            }, deferred.reject).catch(err => console.error(err));
            return;
        }, deferred.reject).catch(err => console.error(err));

        return deferred.promise;
    }

    async function submitWorkflow(formHelper, url, skipValidation) {
        let formData = formHelper.getFields(),
            parameters = formHelper.getParameters(),
            routingList = formHelper.getRoutingList(),
            wfParams = transformWorkflowData(formData, parameters),
            payload = {
                id: $state.params.id,
                workflowParameters: wfParams
            };

        if (routingList != void 0) {
            payload.routingList = transformRoutingList(routingList);
        }

        if (!skipValidation) {
            payload.files = [];
            payload.verboseFiles = [];
            addFilesToPayload(payload, formHelper);
        }

        if (((formHelper.getModel().isPasswordRequired()) || formHelper.isPasswordRequired()) && (url.includes("/workflows/forward"))) {
            await formHelper.showPasswordDialog();
        }
        await BackendService.post(`${url}?clienttype=${EnvironmentService.wfClientType}`, payload);
    }

    function cancel(formHelper) {
        return executeFormScript("beforeCancel", formHelper, false).then(() => {
            StateHistoryManager.updateConfig({ canceled: true }, $stateParams.state);
            goBack();
            return;
        });
    }

    function transformWorkflowData(formData, parameters) {
        let payload = [];

        for (let i in parameters) {
            let field = formData[parameters[i].model.fieldId],
                param = parameters[i],
                value = field ? field.value : param.value,
                type = param.paramType,
                item;

            if (param.model.type == "grid") {
                if (param.value.typeId == PERFORMER_RECORD_ID) {
                    continue;
                }

                item = transformGrid(param, field);
            } else {
                item = {
                    name: param.model.name,
                    id: param.model.id
                };

                if (param.model.type == "record") {
                    type = "RECORD";
                    item.members = transformRecord(value.data, value.record);
                } else if (field) {
                    type = field.model.addon == "list" ? "LIST" : type;
                    item.value = field.api.getValue(true);
                } else {
                    type = getParameterType(type);
                    item.value = value;
                }

                item.type = type;
            }

            payload.push(item);
        }

        return payload;
    }

    function transformRecord(data, record) {
        let members = [];

        for (let j = 0; j < data.length; j++) {
            let member = angular.copy(data[j]);
            member.type = getParameterType(member.type);

            if (member.type == "RECORD") {
                member.members = transformRecord(member.data, record[member.name]);
                delete member.data;
            } else if (member.type == "LIST") {
                member = transformRecordGrid(member, record);
            } else {
                member.value = record[member.name];
            }

            members.push(member);
        }

        return members;
    }

    function transformRecordGrid(member, record) {
        let transformedMember = {
            model: {
                name: member.name,
                typeId: member.typeId,
                isFlat: member.isFlat
            },
            value: {
                columns: member.columns,
                rows: []
            }
        };

        for (let i = 0; i < record[member.name].length; i++) {
            let parsedRowValue = [];

            let rowValue = record[member.name][i];
            if (!member.isFlat) {
                for (let key in rowValue) {
                    parsedRowValue.push(rowValue[key]);
                }
            } else {
                parsedRowValue.push(rowValue);
            }

            transformedMember.value.rows[i] = {
                content: parsedRowValue,
                id: member.rowIds[i]
            };
        }

        return transformGrid(transformedMember);
    }

    function transformGrid(param, field) {
        let item = {
            headers: [],
            records: [],
            id: param.model.id,
            name: param.model.name,
            type: "TABLE",
            typeId: param.model.typeId,
            isFlat: param.model.isFlat
        };

        // add columns
        for (let j in param.value.columns) {
            let col = {
                name: param.value.columns[j].name
            };

            let type = param.value.columns[j].type;
            col.type = getParameterType(type);

            item.headers.push(col);
        }
        let row,
            rec;

        if (field != void 0) {
            if (field.model.addon == "organisation") {
                for (let k in field.orgValues) {
                    let orgMember = field.orgValues[k];
                    item.records.push({ data: [orgMember.id, orgMember.name] });
                }
            } else {
                // iterate the rows
                let gridData = field.api.getRows();

                for (let j in gridData) {

                    row = gridData[j];
                    rec = {
                        data: []
                    };

                    if (row.rowParamId) {
                        rec.id = row.rowParamId;
                    }

                    // iterate the cells
                    for (let index in param.value.columns) {
                        let col = item.headers[index];
                        let value = row[index] == void 0 ? "" : row[index];
                        if (value != "") {
                            if (col.type == "DATE") {
                                value = ValueUtilsService.convertToTimestamp(value, false, true);
                            } else if (col.type == "DATETIME") {
                                value = ValueUtilsService.convertToTimestamp(value, true, true);
                            } else if (col.type == "DECIMAL") {
                                value = ValueUtilsService.parseDecimal(value);
                            }
                        }

                        rec.data.push(value);
                    }

                    item.records.push(rec);
                }
            }
        } else if (param.value.rows.length) {
            // this grid is not bound to the view
            // it is possibly filled by script --> use the content and submit
            // iterate the rows
            for (let paramRow in param.value.rows) {
                row = param.value.rows[paramRow];
                rec = {
                    data: row.content
                };

                if (row.id) {
                    rec.id = row.id;
                }

                item.records.push(rec);
            }
        }

        return item;
    }

    function getParameterType(fieldType) {
        let type;

        if (fieldType == "number") {
            type = "INTEGER";
        } else if (fieldType == "decimal") {
            type = "DECIMAL";
        } else if (fieldType == "date") {
            type = "DATE";
        } else if (fieldType == "datetime") {
            type = "DATETIME";
        } else if (fieldType == "time") {
            type = "TIME";
        } else if (fieldType == "record") {
            type = "RECORD";
        } else if (fieldType == "grid") {
            type = "LIST";
        } else {
            type = "TEXT";
        }

        return type;
    }

    function transformRoutingList(routingList, asTemplate) {
        let entryNr = 0;
        let defaultData = routingList.model.adHocData;
        let routingListData = routingList.model;
        let payload = {
            id: routingListData.id,
            activityId: routingListData.activityId,
            processId: routingListData.processId,
            expandable: routingListData.expandable,
            entries: []
        };

        for (let entryId in routingListData.groups) {
            let group = routingListData.groups[entryId];

            if (Object.keys(group.model.items).length == 0) {
                continue;
            }

            let payloadEntry = {
                nr: entryNr++,
                expandable: group.model.expandable,
                items: []
            };

            payload.entries.push(payloadEntry);

            for (let j in group.model.items) {
                let itemData = group.model.items[j].model;
                payloadEntry.items.push(transformRoutingListItem(itemData, defaultData, asTemplate));
            }
        }

        return payload;
    }

    function transformRoutingListItem(itemData, defaultData, asTemplate) {
        let payloadItem = {
            id: itemData.id.value,
            changeable: itemData.editable.value == "1",
            deleteable: itemData.deletable.value == "1",
            activityName: itemData.taskName.value,
            objectIds: itemData.participants.api.getValue(true),
            remark: itemData.remark.value
        };

        payloadItem.objectIds = payloadItem.objectIds.split(";").join(",");

        let activityName = itemData.activityName.value;
        let activity = defaultData.activities[activityName];
        let period = defaultData.periods[itemData.periodName.value];

        if (activity != void 0) {
            payloadItem.activityId = activity.id;
            payloadItem.modelActivityName = activityName;
        } else {
            payloadItem.activityId = "";
            payloadItem.modelActivityName = "";
        }

        if (period != void 0) {
            payloadItem.timerId = period.id;
            payloadItem.timerName = period.name;
            payloadItem.timerDurationType = itemData.timerType.value;
            payloadItem.timerDuration = (itemData.timerType.value == 1) ? period.duration * 1000 : itemData.dueOn.api.getValue(true);

            if (asTemplate) {
                payloadItem.timerDurationType = 1;

                // templates can be saved either as absolute or relative time
                // they're always supposed to be relative, though
                // therefor we have to force a relative time here
                if (itemData.timerType.value == 2) {
                    payloadItem.timerDuration -= dayjs();

                    if (payloadItem.timerDuration < 0) {
                        payloadItem.timerDuration = 0;
                    }
                }
            }
        } else {
            payloadItem.timerId = "";
            payloadItem.timerName = "";
            payloadItem.timerDurationType = 0;
            payloadItem.timerDuration = 0;
        }

        return payloadItem;
    }

    function addWorkflowData(formData, parameters) {
        if (!parameters) {
            return;
        }

        for (let i in parameters) {
            let parameter = parameters[i];
            let field = formData[parameter.model.fieldId],
                value;

            if (field == void 0) {
                continue;
            }

            if (field.model.addon == "organisation") {
                let rows = parameter.value.rows;
                let columns = parameter.value.columns;
                let index = 0;
                for (let c in columns) {
                    if (columns[c].name === "Name") {
                        break;
                    }
                    index++;
                }
                let values = [];
                for (let j in rows) {
                    let record = rows[j].content;
                    values.push(record[index]);
                }
                value = values.join(";");
            } else if (field.model.type == "date") {
                value = ValueUtilsService.dateToLocaleDate(parameter.value);
            } else if (field.model.type == "datetime") {
                if (parameter.value == "" || parameter.value == 86400) {
                    value = "";
                } else {
                    let val = parseInt(`${parameter.value}000`);
                    value = dayjs(new Date(val)).format(EnvironmentService.env.dateFormat.datetime);
                }
            } else if (parameter.value.typeId == PERFORMER_RECORD_ID) {
                let performers = parameter.value.rows.map(row => row.content[1]);
                value = performers.join(";");
            } else if (field.model.type == "grid") {
                let columns = field.model.columns,
                    rows = parameter.value.rows;

                field.gridData = [];

                //iterate each row
                for (let rowCount in rows) {
                    //iterate each cell
                    let record = rows[rowCount].content,
                        row = [];

                    for (let cellCount in columns) {
                        let cellValue;
                        let cell = record[cellCount];

                        if (typeof (cell) == "undefined") {
                            cellValue = "";
                        } else {
                            cellValue = cell;

                            if (columns[cellCount].type == "decimal") {
                                if (cellValue.charAt(0) == "-" && cellValue.charAt(1) == ".") {
                                    cellValue = `${cellValue.slice(0, 1)}0${cellValue.slice(1)}`;
                                } else if (cellValue.charAt(0) == ".") {
                                    cellValue = `0${cellValue}`;
                                }
                            } else if (columns[cellCount].type == "date") {
                                cellValue = ValueUtilsService.dateToLocaleDate(cellValue);
                            }
                        }

                        row.push(cellValue);
                    }

                    field.gridData.push(row);
                }

                // The 'gridData' is just the initial value of the grid and is not updated later, since the agGrid does all the updating on its own.
                // The 'value' property is basically not used for grids, but should not be null to avoid errors.
                field.initialValue = angular.copy(field.gridData);
                field.value = "";
                continue;
            } else {
                value = parameter.value;
            }

            if (typeof (value) == "undefined") {
                value = "";
            }

            field.value = value;
            field.initialValue = value;
        }
    }

    function addIndexData(formData, indexData, truncateValues = false) {
        for (let key in indexData.fields) {

            let formDataField = formData[key];

            if (formDataField == void 0) {
                console.warn("missing field !?", key);
                continue;
            }

            let value = indexData.fields[key];

            if (formDataField.model.type == "grid") {
                formDataField.gridData = indexData.fields[key].rows;

                // The 'gridData' is just the initial value of the grid and is not updated later, since the agGrid does all the updating on its own.
                // The 'value' property is basically not used for grids, but should not be null to avoid errors.
                formDataField.initialValue = angular.copy(formDataField.gridData);
                formDataField.value = "";
                continue;
            } else if (formDataField.model.type == "radio") {
                for (let i = 0; i < formDataField.model.fields.length; i++) {
                    if (formData[formDataField.model.fields[i]].model.name == value) {
                        value = i.toString();
                        break;
                    }
                }
            }

            if (truncateValues && formDataField.model.type === "text" && value && value.length > formDataField.model.maxLength) {
                value = value.substring(0, formDataField.model.maxLength);
            }

            formDataField.value = value;
            formDataField.initialValue = value;
        }
    }

    function prefillFormData(formData) {
        if (!formData) {
            formData = {};
        }

        for (let i in formData) {
            let field = formData[i],
                model = field.model,
                internal = model.internal,
                value;

            if (!model.init) {
                continue;
            }

            if (model.init.type == "constant") {
                if (model.type == "radio") {
                    if (model.masterRadioInternal) {
                        internal = model.masterRadioInternal;
                        let master = formData[internal];

                        for (let j in master.model.fields) {
                            let button = formData[master.model.fields[j]];

                            if (button.model.init != void 0 && button.model.init.value == "1") {
                                value = j;
                                break;
                            }
                        }
                    } else {
                        for (let j in model.fields) {
                            let button = model.fields[j];

                            if (button.init[0] == "1") {
                                value = j;
                                break;
                            }
                        }
                    }
                } else {
                    formData[model.internal].value = model.init.value;
                    value = model.init.value;
                }
            } else if (model.init.type == "function") {
                let sessionInfo = EnvironmentService.getSessionInfo(),
                    dateParseFormat = EnvironmentService.env.dateFormat;

                switch (model.init.function) {
                    case "currentDate":
                    case "currentDatetime":
                        let dateTimeFormat = model.init.function == "currentDate" ? dateParseFormat.date : dateParseFormat.datetime;
                        value = dayjs().format(dateTimeFormat);
                        break;
                    case "currentUser":
                    case "currentUserData":
                        value = sessionInfo.username.toUpperCase();

                        if (model.init.function == "currentUserData") {
                            value += "(u)";
                        }
                        break;
                    case "currentUserFullname":
                        value = sessionInfo.fullname;
                        break;
                    case "currentDatePlusTimespan":
                        let dateFormat = model.type == "datetime" ? dateParseFormat.datetime : dateParseFormat.date;
                        value = dayjs().add(model.init.value).format(dateFormat);
                        break;
                    case "currentTime":
                        value = dayjs().format("H:m:s");
                        break;
                    default:
                        console.warn(`Unknown initialize function '${model.init.function}'!`);
                }
            }

            formData[internal].value = value;
            formData[internal].initialValue = value;
        }
    }

    /**
     * Returns disabled fields
     * @param {object} formData - formData
     * @param {DmsDocumentModel} dmsDocumentModel - dmsDocument model
     * @param {boolean} isReadonly - optional parameter to disable fields
     */
    async function disableFieldsAsync(formData, dmsDocumentModel, isReadonly = false) {
        for (let i in formData) {
            let field = formData[i];
            let isSupervisor = EnvironmentService.userHasRole("R_DMS_SUPERVISOR");

            if (isReadonly) {
                field.isDisabled = true; //disable fields in dv-indexdata for phone
            }

            if ($stateParams.mode == "view") {
                field.isDisabled = true;
            }

            if ($stateParams.mode == "new" && field.model.isEmsField) {
                let metadata = await ExternalTrayService.getTrayElementByTrayIdAsync($stateParams.groupKey);
                if (field.model.isEmsDigestField || (metadata && metadata.objects.length > 1)) {
                    field.isDisabled = true;
                    field.value = $filter("translate")("eob.create.multiple.mail.field.automatic");
                }
            }

            if (field.model.readonly == "always") {
                field.isDisabled = true;
            }

            if ((field.model.readonly == "init" || field.model.readonly == "init & arch") && field.value !== "" && $stateParams.mode != "copy" && $stateParams.mode != "reference") {
                field.isDisabled = true;
            }

            if ((field.model.readonly == "arch" || field.model.readonly == "init & arch") && dmsDocumentModel && dmsDocumentModel.baseParameters.archiveState == "ARCHIVED") {
                field.isDisabled = true;
            }

            if (!isSupervisor && field.model.readonly == "supervisor") {
                field.isDisabled = true;
            }

            // If the field is disabled, the button can in Addons maybe enabled based on CANLOCK in Objectdefinition.
            // If canLock is present and zero the button should be enabled while the field itself is disabled.
            // canLock with value 2 is the same only for supervisors or edit and create.
            if (field.model.config != void 0) {
                if (field.model.config.canLock == 0 && ($stateParams.mode == "view" || $state.current.name == "workflow")) {
                    field.model.alwaysEnableAddon = true;
                } else if (field.model.config.canLock == 2 && field.model.readonly != void 0 && field.model.readonly.indexOf("arch") == -1 && ($stateParams.mode == "edit" || $state.current.name == "create" || $state.current.name == "workflow")) {
                    field.model.alwaysEnableAddon = true;
                }
            }

            if (field.model.type == "grid") {
                if (field.isDisabled) {
                    disableTableCells(field);
                } else {
                    let indexes = [];

                    for (let i in field.model.columns) {
                        if (field.model.columns[i].readonly) {
                            indexes.push(i);
                        }
                    }

                    if (indexes.length) {
                        disableTableCells(field, indexes);
                    }
                }
            }

            if (field.isDisabled) {
                field.suppressSubmission = true;
            }
        }
    }

    function disableTableCells(field, indexes) {
        for (let i in field.model.columns) {
            let col = field.model.columns[i];

            if (indexes != void 0) {
                if (indexes.indexOf(i.toString()) != -1) {
                    col.isDisabled = true;
                }
            } else {
                col.isDisabled = true;
            }
        }
    }

    function createFormData(fields, validationMode, isRecursive, formData) {
        if (!formData) {
            formData = {};
        }

        // For some reason, we sometimes get a FieldModel, rather than a field here. Just some high quality code right there
        const mappedRadioFields = fields.filter(x => x.type == FormFieldType.RADIO).reduce((acc, val) => {
            if (Array.isArray(acc[val.masterRadioInternal])) {
                acc[val.masterRadioInternal].push(val);
            } else {
                acc[val.masterRadioInternal] = [val];
            }
            return acc;
        }, {});

        for (const group of Object.keys(mappedRadioFields)) {
            const controls = []
            const masterRadioControl = new FormControl()

            mappedRadioFields[group].forEach(x => {
                if (x.masterRadioControl || x.control) {
                    // Function gets called for groups or page controls, the fields of which are already part of the entire form
                    return;
                }
                x.control = new FormControl()
                x.radioGroupControls = controls;
                x.masterRadioControl = masterRadioControl;
                controls.push(x.control)
            });
        }

        for (let i in fields) {
            let field = fields[i];

            formData[field.internal] = {
                model: field,
                value: "",
                initialValue: field.type == "grid" ? [] : "",
                validationMode
            };

            if (field.type == "pagecontrol") {
                for (let j in field.pages) {
                    let page = field.pages[j];
                    api.createFormData(page.fields, validationMode, true, formData);
                }
            } else if (field.type == "group") {
                api.createFormData(field.fields, validationMode, true, formData);
            }
        }

        if (!isRecursive) {
            // we check for readonly crosscheck fields only on non-search indexdata forms, where user can edit and save data
            // validationMode === "max" makes sure that this check is not applied to e.g. quicksearch forms in the navigation
            if (/create|workflow|indexdata/gi.test($state.current.name) && $stateParams.mode !== "view" && validationMode === "max") {
                checkReadonlyCrosscheck(formData);
            }

            return formData;
        }
    }

    /**
     * Validate the index data of a form.
     *
     * @param {FormData} formData - A map of all form fields.
     * @param {boolean=} skipValidation - Resolve the validation as successful.
     * @returns {Promise} The validation promise. It is rejected if any field is invalid and resolved otherwise.
     */
    function validateForm(formData, skipValidation) {
        let validationDeferred = $q.defer();

        if (skipValidation) {
            validationDeferred.resolve(true);
            return validationDeferred.promise;
        }

        let skipableTypes = ["pagecontrol", "group", "static", "radio", "button"];
        let isValid = true;
        let promises = [];
        let crossPromises = undefined;

        for (let i in formData) {
            let field = formData[i];

            if (skipableTypes.indexOf(field.model.type) != -1) {
                continue;
            }

            if (!field.api) {
                console.warn("Field without api: ", field);
                continue;
            }
            let ret = field.api.syncValidate();

            if (ret == false) {
                isValid = false;
                continue;
            }

            if (field.model.isUnique) {
                if (crossPromises == void 0) {
                    let uniquePromise = FormValidationService.validateUnique(field, null, true);

                    crossPromises = [{
                        fn: FormValidationService.validateUnique,
                        promise: uniquePromise
                    }];
                }

                promises.push(field.api.asyncValidate(crossPromises));
            } else if (field.model.hasPseudoCatalog || field.model.type == "grid") {
                promises.push(field.api.asyncValidate());
            }
        }

        if (promises.length === 0) {
            if (isValid) {
                validationDeferred.resolve();
            } else {
                let firstInvalidField = getFirstInvalidField(formData);
                // Circulation slip fields lack the API - since it's unlikely it'd ever be used in the first place, let's keep it this way
                if (firstInvalidField != void 0 && firstInvalidField.api) {
                    firstInvalidField.api.focus();
                }

                // Todo DODO-13393
                // setBeforeCancelExecuted({isExecuted: false, formHelper: getBeforeCancelExecuted().formHelper});

                validationDeferred.reject();
            }

            return validationDeferred.promise;
        }

        $q.all(promises).then(() => {
            if (isValid) {
                validationDeferred.resolve();
            } else {

                // Todo DODO-13393
                // setBeforeCancelExecuted({isExecuted: false, formHelper: getBeforeCancelExecuted().formHelper});

                validationDeferred.reject();
                const field = getFirstInvalidField(formData);
                if (field) {
                    field.api.focus();
                }
            }
            return;
        }, (error) => {

            // Todo DODO-13393
            // setBeforeCancelExecuted({isExecuted: false, formHelper: getBeforeCancelExecuted().formHelper});

            validationDeferred.reject(error);
            getFirstInvalidField(formData).api.focus();
        }).catch(err => console.error(err));

        return validationDeferred.promise;
    }

    function getFirstInvalidField(formData) {
        for (let i in formData) {
            let field = formData[i];

            if (field.isValid === false) {
                return field;
            }
        }
    }

    /**
     * Submits a create variant form.
     *
     * @param {{id, objectTypeId}} idPair - The object id and objectTypeId of the dms document the variant is created from.
     * @param {string} type - The variant type that shall be created (main|parallel|sub).
     * @param {object} payload - The update payload.
     * @param {object} dropzoneContent - The dropzone content.
     */
    async function addVariantAsync({ id, objectTypeId }, type, payload, dropzoneContent) {
        payload.osid = await BackendVariantsService.createVariant(id, objectTypeId, type).toPromise();

        // adding the indexdata first for later template filling
        await BackendObjectService.updateDmsObject(payload, undefined, undefined).toPromise();
        await DmsContentService.addContentToDocumentAsync(dropzoneContent, payload.osid);

        if (dropzoneContent.mode != "template" || !dropzoneContent.template || !EnvironmentService.env.import.useTemplateFilling || dropzoneContent.template.id == -1) {
            NotificationsService.success($filter("translate")("eob.form.create.variant.without.template"));
        }

        return payload.osid;
    }

    async function insertEmails(formHelper) {
        let formData = formHelper.getFields();
        let modelDef = formHelper.getModel();
        let form = prepareForm(formData, modelDef);

        await executeFormScript("beforeValidate", formHelper);
        await validateForm(formData);

        const resultCode = await executeFormScript("beforeSave", formHelper);

        if (resultCode == 2) {
            form = formHelper.getFields()
        }

        for (let key in form.fields) {
            let field = formHelper.getFieldByInternal(key);
            if (field !== null && (field.model.type === "date" || field.model.type === "datetime")) {
                form.fields[key].value = new Date(Number(form.fields[key].value)).toISOString();
            }
        }

        MessageService.broadcast(Broadcasts.INSERT_EMAILS_CREATE_FORM, { form });

        let metadata = await ExternalTrayService.getTrayElementByTrayIdAsync($stateParams.groupKey);
        await DmsActionService.openLocation(false, undefined, undefined, metadata.parentObjectId, metadata.parentObjectTypeId);
    }

    async function insertDocument(formHelper) {

        // Todo DODO-13393
        // setBeforeCancelExecuted({isExecuted: true, formHelper: getBeforeCancelExecuted().formHelper});

        let formData = formHelper.getFields(),
            modelDef = formHelper.getModel();
        try {
            await executeFormScript("beforeValidate", formHelper);
            await validateForm(formData);
            const resultCode = await executeFormScript("beforeSave", formHelper);
            if (resultCode == 2) {
                formData = formHelper.getFields()
            }
            let payload = prepareForm(formData, modelDef);
            payload.mainTypeId = formHelper.getConfig().mainType;
            await createDocument(payload, $location.search().targetId, formHelper);
        } catch (error) {

            // Todo DODO-13393
            // setBeforeCancelExecuted({isExecuted: false, formHelper: getBeforeCancelExecuted().formHelper});

            console.warn(`Error on insertDocument: ${(error || {}).stack || error}`)
            throw error
        }
    }

    /**
     * Creates a new dms document with index data and content.
     *
     * @param {object} payload - The payload.
     * @param {string|number} targetId - The object id of the target location.
     * @param {FormHelper} formHelper - A formHelper.
     * @return {Promise} A promise that is resolved once the document is created.
     */
    async function createDocument(payload, targetId, formHelper) {
        let callerStateId = StateHistoryManager.getCurrentConfig().caller;
        let callerState = StateHistoryManager.getStateData(callerStateId);
        let dropzoneContent = EnvironmentService.getDropzoneContent();
        let httpConfig = BackendService.getTransformRequest();
        let successMsg = $filter("translate")("form.create.success");

        if ($stateParams.mode == "variants") {
            // adding a variant without content is not allowed.
            if (dropzoneContent.files.length === 0 && dropzoneContent.mode != "template") {
                throw new Error(NotificationsService.error($filter("translate")("modal.edit.content.missing.files")))
            }
        }

        try {
            if ($stateParams.mode == "variants") {
                let trayItemKey;
                if (dropzoneContent.mode == "trayItem") {
                    trayItemKey = dropzoneContent.files.groupKey;
                }

                let variantId = await addVariantAsync($stateParams, $stateParams.type, payload, dropzoneContent);
                callerState.data.config.selectedItems = {};
                callerState.data.config.selectedItems[variantId] = 1;

                if ($stateParams.groupKey != void 0) {
                    trayItemKey = $stateParams.groupKey;

                    let metadata = await ExternalTrayService.getTrayElementByTrayIdAsync($stateParams.groupKey);
                    if (metadata.setNewVariantActive) {
                        await BackendService.get(`/documents/variants/setactive/${variantId}`);
                    }

                    try {
                        await DmsActionService.undoCheckOut({
                            mocked: true,
                            osid: metadata.objectId,
                            objectTypeId: metadata.objectTypeId
                        });
                    } catch (error) {
                        if (error.status != 403) {
                            console.error(error);
                            throw error;
                        }
                    }
                }

                if (trayItemKey) {
                    clearExternalTrayItem(trayItemKey);
                }

                goBack();
            } else if (payload.mainTypeId == "6" && $stateParams.mode != "reference" && !$stateParams.groupKey
                && CacheManagerService.objectTypes.getById($stateParams.objectTypeId).model.config.isEmsType) {
                try {
                    const response = await saveWithEms(formHelper, payload);
                    const responseObject = response.data.objects[0];
                    const responseObjectId = responseObject.properties["enaio:objectId"].value;

                    $timeout(async () => {
                        try {
                            if ($stateParams.mode != "inwftray") {
                                DmsActionService.openLocation(false, responseObjectId, $stateParams.objectTypeId, $stateParams.targetId, $stateParams.parentTypeId);
                            } else {
                                if ($stateParams.mode == "inwftray") {
                                    // store wfTrayFile in state data for returning to workflow file area and inserting it
                                    let wfTrayFile = {
                                        id: responseObjectId,
                                        typeId: parseInt(responseObject.properties["enaio:objectTypeId"].value),
                                        fileArea: $stateParams.location
                                    };

                                    let selectedItems = {};
                                    selectedItems[responseObjectId] = [];
                                    StateHistoryManager.updateConfig({ wfTrayFile, selectedItems }, callerStateId);
                                }
                                goBack();
                            }
                        } catch (error) {
                            console.warn(error);
                            NotificationsService.error($filter("translate")("eob.action.notification.email.insert.error"));
                            goBack();
                        }
                    }, 1000);
                } catch (error) {
                    console.warn(error);
                    NotificationsService.error($filter("translate")("eob.action.notification.email.insert.error"));
                    goBack();
                }
                return;
            } else {
                if ($stateParams.groupKey != void 0) {
                    let metadata = await ExternalTrayService.getTrayElementByTrayIdAsync($stateParams.groupKey);

                    if (metadata && metadata.trayItemType == "insertEmails") {
                        try {
                            const insertObjectInfo = StateHistoryManager.getStateData("openLocation");
                            const parentObject = insertObjectInfo.data.parentObject;
                            const response = await saveWithEms(formHelper, payload, metadata);
                            const responseObjectId = response.data.objects[0].properties["enaio:objectId"].value;
                            DmsActionService.openLocation(
                                false, responseObjectId, metadata.objectTypeId,
                                parentObject.osid ? parentObject.osid : metadata.parentObjectId,
                                parentObject.objectTypeId ? parentObject.objectTypeId : metadata.parentObjectTypeId
                            );
                        } catch (error) {
                            console.warn(error);
                            if (error.status === 300) {
                                NotificationsService.error($filter("translate")("eob.ems.deduplication.error.notification"));
                            } else {
                                NotificationsService.error($filter("translate")("eob.ems.not.available"));
                            }
                        }

                        return;
                    }
                }

                let osid;
                let objectTypeId = payload.objectTypeId;
                let typeModel = CacheManagerService.objectTypes.getById(objectTypeId).model;

                if ($stateParams.mode == "inwftray") {
                    osid = await BackendObjectService.insertWfTrayDmsObject(payload).toPromise();
                } else if ($stateParams.mode == "reference") {
                    payload.osid = EnvironmentService.getClipboard().item.model.osid;
                    osid = await BackendObjectService.insertGreenArrowDmsObject(payload, targetId, undefined).toPromise();
                } else if (payload.mainTypeId == "0") {
                    osid = await BackendObjectService.insertDmsObject(payload, undefined, undefined).toPromise();
                } else {
                    osid = await BackendObjectService.insertDmsObject(payload, targetId, undefined).toPromise();
                }

                if ($stateParams.mode == "inwftray") {
                    // store wfTrayFile in state data for returning to workflow file area and inserting it
                    let wfTrayFile = {
                        id: osid,
                        typeId: parseInt(objectTypeId),
                        fileArea: $stateParams.location
                    };

                    let selectedItems = {};
                    selectedItems[osid] = [];
                    StateHistoryManager.updateConfig({ wfTrayFile, selectedItems }, callerStateId);
                }

                if (payload.mainTypeId == "0") {
                    let dmsDocument = null;
                    let error403 = false;

                    try {
                        dmsDocument = await formHelper.dms.searchById(osid, objectTypeId);
                    } catch (error) {
                        if (error.status == 403) {
                            successMsg += ` ${$filter("translate")("form.create.success.no.view.right")}`;
                            error403 = true;
                        } else {
                            throw error;
                        }
                    }

                    formHelper.setCurrentDmsDocument(dmsDocument);

                    await executeFormScript("afterSave", formHelper);

                    NotificationsService.success(successMsg);

                    if ($stateParams.mode == "inwftray") {
                        goBack();
                    } else if (typeModel.config && typeModel.config.rights && typeModel.config.rights.objExport !== false) {
                        StateService.openFolderOrRegisterAsync({
                            osid,
                            objectTypeId: parseInt(objectTypeId)
                        }, false, undefined, true);
                    } else {
                        goBack();
                    }
                } else {
                    if (callerState && callerState.data.type == "folder") {
                        let selectedItems = {};
                        selectedItems[osid] = [];

                        let path = callerState.data.config.path;
                        if (path.length > 0 && path[path.length - 1].osid != $stateParams.targetId) {
                            path = (await LocationService.getLocationPathAsync({
                                objectId: osid,
                                objectTypeId
                            }, $stateParams.targetId)) || path;
                        }

                        StateHistoryManager.updateConfig({ selectedItems, path }, callerStateId);
                    }

                    let dmsDocument = null;
                    let forbidden = false;

                    try {
                        dmsDocument = await formHelper.dms.searchById(osid, objectTypeId);
                    } catch (error) {
                        if (error.status == 403) {
                            successMsg += ` ${$filter("translate")("form.create.success.no.view.right")}`;
                        } else {
                            throw error;
                        }
                    }

                    if (dmsDocument != null) {
                        // add it to later reference it in another service...
                        CacheManagerService.dmsDocuments.add(dmsDocument);

                        if (dmsDocument.model.rights.objModify && $stateParams.mode != "reference") {
                            let trayItemKey;
                            if (dropzoneContent.mode == "trayItem") {
                                trayItemKey = dropzoneContent.files.groupKey;
                            }
                            await DmsContentService.addContentToDocumentAsync(dropzoneContent, osid);

                            if (trayItemKey) {
                                clearExternalTrayItem(trayItemKey);
                            }
                        }
                    }

                    formHelper.setCurrentDmsDocument(dmsDocument);

                    await executeFormScript("afterSave", formHelper);

                    if (dmsDocument == null) {
                        NotificationsService.success(successMsg);
                    } else if (dmsDocument.model.rights.objModify || dropzoneContent.files.length == 0) {
                        if (dropzoneContent.mode != "template" || dropzoneContent.template.id == -1 || !EnvironmentService.env.import.useTemplateFilling) {
                            NotificationsService.success(successMsg);
                        }
                    } else {
                        NotificationsService.warning($filter("translate")("eob.form.create.missing.rights.insert.content"));
                    }

                    if ($stateParams.groupKey) {
                        if (dropzoneContent.files.length > 0 && !$stateParams.originalDropzoneContentRemoved) {
                            clearExternalTrayItem($stateParams.groupKey);
                        }
                    }

                    if (ValueUtilsService.parseBoolean($stateParams.openParentAfterCreate)) {
                        let parent = { id: $stateParams.targetId, objectTypeId: $stateParams.parentTypeId };
                        StateService.goToLocationAsync(dmsDocument, parent, false, true);
                    } else {
                        goBack();
                    }
                }
            }
            await OfflineCacheService.resetSynchronizationState($stateParams.targetId)

        } catch (error) {
            NotificationsService.backendError(error, "form.create.error");
            console.error(error);
            throw new Error(error);
        }
    }

    async function getMailExtraction(fileData, objectTypeId, cancelPromise) {
        let fd = new FormData();
        let mimeType = "message/rfc822";
        if (fileData.name.toLowerCase().endsWith(".msg")) {
            mimeType = "application/vnd.ms-outlook";
        }

        const blob = new Blob([fileData], { type: mimeType });
        blob.name = "cid_emsstore"

        fd.append("cid_emsstore", blob, "blobdata");

        let data = {
            "objects": [{
                "options": {
                    "ems:target:enaio:objectTypeId": {
                        "value": objectTypeId
                    }
                },
                "contentStreams": [{
                    "mimetype": mimeType,
                    "fileName": fileData.name,
                    "cid": "cid_emsstore"
                }]
            }]
        }

        if ($stateParams.mode == "inwftray") {
            data.objects[0].options["ems:parent:enaio:objectId"] = { "value": "ignored" }
            data.objects[0].options["ems:parent:enaio:objectTypeId"] = { "value": "ignored" }
            data.objects[0].options["enaio:options"] = { value: "INWFTRAY=1" }
        } else {
            data.objects[0].options["ems:parent:enaio:objectId"] = { "value": `${$location.search().targetId}` }
            data.objects[0].options["ems:parent:enaio:objectTypeId"] = { "value": `${$location.search().parentTypeId}` }
        }

        let blob2 = new Blob([JSON.stringify(data)], { type: "application/json" })
        fd.append("data", blob2, "data")

        let config = {
            transformRequest: angular.identity,
            headers: { "Content-Type": undefined },
        };

        if (cancelPromise) {
            config.timeout = cancelPromise
        }

        return BackendService.post("/api/extract", fd, config, "/ems");
    }

    async function saveWithEms(formHelper, payload, metadata) {

        const createEmsFormData = (file, filenameArg) => {
            const fd = new FormData();
            const filename = filenameArg ? filenameArg : file.name;
            let mimeType = "message/rfc822";
            if (filename.toLowerCase().endsWith(".msg")) {
                mimeType = "application/vnd.ms-outlook";
            }

            let emsPayload = {
                "objects": [{
                    "properties": payload.fields,
                    "options": {},
                    "contentStreams": [{
                        "mimetype": mimeType,
                        "fileName": filename,
                        "cid": "cid_emsstore"
                    }]
                }]
            };
            if ($stateParams.mode == "inwftray") {
                emsPayload.objects[0].options["ems:target:enaio:objectTypeId"] = { value: payload.objectTypeId }
                emsPayload.objects[0].options["ems:parent:enaio:objectId"] = { "value": "ignored" }
                emsPayload.objects[0].options["ems:parent:enaio:objectTypeId"] = { "value": "ignored" }
                emsPayload.objects[0].options["enaio:options"] = { value: "INWFTRAY=1" }
            } else {
                let insertObjectInfo = StateHistoryManager.getStateData("openLocation");
                if (insertObjectInfo.data.parentObject) {
                    if ($location.search().targetId) {
                        insertObjectInfo.data.parentObject.osid = $location.search().targetId
                    }
                    if ($location.search().parentTypeId) {
                        insertObjectInfo.data.parentObject.objectTypeId = $location.search().parentTypeId
                    }
                }
                emsPayload.objects[0].options["ems:target:enaio:objectTypeId"] = { value: insertObjectInfo.data.insertObjectTypeId ? insertObjectInfo.data.insertObjectTypeId.toString() : metadata.objectTypeId }
                emsPayload.objects[0].options["ems:parent:enaio:objectId"] = { value: insertObjectInfo.data.parentObject.osid ? insertObjectInfo.data.parentObject.osid : metadata.parentObjectId }
                emsPayload.objects[0].options["ems:parent:enaio:objectTypeId"] = { value: insertObjectInfo.data.parentObject.objectTypeId ? insertObjectInfo.data.parentObject.objectTypeId : metadata.parentObjectTypeId }
            }

            let indexdataBlob = new Blob([JSON.stringify(emsPayload)], { type: "application/json" });
            fd.append("data", indexdataBlob, "data");
            let contentBlob = new Blob([file], { type: mimeType });
            contentBlob.name = "cid_emsstore";

            fd.append("cid_emsstore", contentBlob, filename);
            return fd;
        }

        let response;
        for (let key in payload.fields) {
            let field = formHelper.getFieldByInternal(key);
            if (field !== null && (field.model.type === "date" || field.model.type === "datetime")) {
                payload.fields[key].value = new Date(Number(payload.fields[key].value)).toISOString();
            }
        }

        if (emsDropzoneContents.length == 1) {
            let config = {
                transformRequest: angular.identity,
                headers: { "Content-Type": undefined }
            };

            response = await BackendService.post("/api/store", createEmsFormData(emsDropzoneContents[0]), config, "/ems");

        } else {
            for (let object of metadata.objects) {
                let filename = object.properties.filePath.value.split(/[/\\]/).pop();
                let mail = await FileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, filename, {
                    group: metadata.groupKey,
                    first: true
                });

                let config = {
                    transformRequest: angular.identity,
                    headers: { "Content-Type": undefined }
                };

                response = await BackendService.post("/api/store", createEmsFormData(mail, filename), config, "/ems");
            }

            clearExternalTrayItem(metadata.groupKey);
        }

        return response
    }

    async function updateIndexData(formHelper) {
        // Todo DODO-13393
        // setBeforeCancelExecuted({isExecuted: true, formHelper: getBeforeCancelExecuted().formHelper});

        let formData = formHelper.getFields();
        let modelDef = formHelper.getModel();

        await executeFormScript("beforeValidate", formHelper);
        await validateForm(formData);

        const resultCode = await executeFormScript("beforeSave", formHelper);

        if (resultCode == 2) {
            formData = formHelper.getFields()
        }

        let payload = prepareForm(formData, modelDef);
        payload.osid = $stateParams.osid;

        for (let field of Object.keys(payload.fields)) {
            if (payload.fields[field].value) {
                payload.fields[field].value = payload.fields[field].value.replace(/\r/g, "");
            }
        }

        try {
            await BackendObjectService.updateDmsObject(payload, undefined, undefined).toPromise();
            await updateFromBackendAsync(formHelper, payload.osid);
            await executeFormScript("afterSave", formHelper);

            // DODO-11636: in semi-rare cases, e.g. coming from a favourites state, the DmsDocument may be propagaden with stale data after leaving
            // this state, as /document/favourites doesn't necessarily contain all fields. This call ensures that the local cache is up to date.
            await CacheManagerService.dmsDocuments.getOrFetchById(payload.osid, payload.objectTypeId, true);

            NotificationsService.success($filter("translate")("form.update.success"));

            ViewerService.refreshDetails(payload.osid);

            await OfflineCacheService.resetSynchronizationState(payload.osid)
            goBack();

        } catch (error) {

            // Todo DODO-13393
            // setBeforeCancelExecuted({isExecuted: false, formHelper: getBeforeCancelExecuted().formHelper});

            NotificationsService.backendError(error, "form.update.error");
            throw error;
        }
    }

    /**
     * There may be rare cases where getting a dms document after updateing fails. E.g.
     * changing index data make a clause take effect and makes the dms
     * document not accessible anymore for the current user. Then it is valid
     * to get a 403 (forbidden) here. In that scenario the user still has the
     * obsolete dms documente available in the script context.
     *
     * @param formHelper
     * @param osid
     * @return {Promise<void>}
     */
    async function updateFromBackendAsync(formHelper, osid) {
        try {
            let dmsDocument = await formHelper.dms.searchById(osid);
            formHelper.setCurrentDmsDocument(dmsDocument);
        } catch (_) {
            // its ok to fail here
        }
    }

    function typeDocument(formHelper) {
        let deferred = $q.defer();
        let formData = formHelper.getFields();
        let modelDef = formHelper.getModel();

        validateForm(formData).then(() => {
            let clipboardItem = EnvironmentService.getClipboard().item;
            let osid = clipboardItem.model.osid;
            let payload = prepareForm(formData, modelDef);

            payload.osid = osid;
            // preserve main type in case we type an object type modulübergreifend
            payload.mainTypeId = clipboardItem.model.mainType;

            // Todo: Once we have also updated insert/update to DMS2 we might skip result_config complete.
            //       Currently I do not trust to remove it in prepareForm. First all consumer of it must be checked.
            delete payload.result_config;

            // Todo: Refactor it to RxJS.
            BackendObjectService.setObjectTypeForTypelessDmsObject(payload).toPromise().then(async () => {
                let targetItem = await CacheManagerService.dmsDocuments.getOrFetchById($location.search().targetId);

                if (targetItem == void 0) {
                    targetItem = {
                        model: {
                            osid: $stateParams.targetId,
                            objectTypeId: modelDef.config.cabinetId
                        },
                        api: {}
                    }
                }

                clipboardItem.model.objectTypeId = payload.objectTypeId;
                EnvironmentService.addToClipboard(clipboardItem, "copy");

                ActionService.moveItem(targetItem).then(() => {
                    EnvironmentService.clearClipboard();
                    NotificationsService.success($filter("translate")("form.type.and.locate.success"));
                    deferred.resolve();
                    goBack();
                    return;
                }, deferred.reject).catch(err => console.error(err));
                return;
            }, (error) => {
                NotificationsService.backendError(error, "eob.object.set.type.failed.error");
                deferred.reject(error);
            }).catch(err => console.error(err));
            return;
        }, deferred.reject).catch(err => console.error(err));

        return deferred.promise;
    }

    function insertToWfTray(formHelper) {
        let deferred = $q.defer();

        let formData = formHelper.getFields(),
            modelDef = formHelper.getModel();

        executeFormScript("beforeValidate", formHelper).then(() => {
            validateForm(formData).then(() => {
                executeFormScript("beforeSave", formHelper).then(resultCode => {
                    if (resultCode == 2) {
                        formData = formHelper.getFields()
                    }
                    let payload = prepareForm(formData, modelDef);
                    payload.mainTypeId = formHelper.getConfig().mainType;
                    createDocument(payload, "", formHelper).then(deferred.resolve, deferred.reject).catch(err => console.error(err));
                    return;
                }, deferred.reject).catch(err => console.error(err));
                return;
            }, deferred.reject).catch(err => console.error(err));
            return;
        }, deferred.reject).catch(err => console.error(err));

        return deferred.promise;
    }

    /**
     * Todo: For Refactoring to type script please use the interfaces BackendInsertUpdateGridField and
     *       BackendInsertUpdateField for payload return.
     */
    function prepareForm(formData, modelDef) {
        let fields = {};
        let payload = {
            fields,
            objectTypeId: modelDef.config.objectTypeId.toString(),
            mainTypeId: modelDef.config.mainType,
            result_config: { fieldsschema: [] }
        };

        for (let i in formData) {
            let fieldModel = formData[i].model;

            if (formData[i].suppressSubmission && $stateParams.type != "copy") {
                if (formData[i].model.alwaysEnableAddon !== true) {
                    continue;
                }

                if (formData[i].value != "" && (formData[i].model.readonly == "init" || formData[i].model.readonly == "init & arch")) {
                    continue;
                }
            }

            if ((formData[i].model.readonly == "init" || formData[i].model.readonly == "init & arch") && formData[i].value == "") {
                continue;
            }

            if (fieldModel.type == "pagecontrol" || fieldModel.type == "static" || fieldModel.type == "group" || fieldModel.type == "button") {
                continue;
            }

            if (fieldModel.type == "radio" && !fieldModel.isMasterRadio) {
                continue;
            }

            if (fieldModel.isCorrupted) {
                console.error("The objecttype definition is corrupted for the field: ", fieldModel.name, " The data cannot be processed.");
                continue;
            }

            if (formData[i].api == void 0) {
                console.warn("Field without api found, better fix this");
                continue;
            }

            if (fieldModel.type == "grid") {
                let columns = fieldModel.columns;
                let rows = formData[i].api.getRows();
                let colData = [];
                let rowData = [];

                for (let col in columns) {
                    colData.push({
                        internalName: columns[col].internal,
                        type: columns[col].type
                    });
                }

                for (let cell in rows) {
                    let row = [];
                    let isEmpty = true;

                    for (let col in columns) {
                        let value = rows[cell][col];

                        if (columns[col].type == "date" && value != void 0 && value.length > 0) {
                            value = ValueUtilsService.convertToTimestamp(value, false, false);
                        }

                        if (columns[col].type == "decimal" && !columns[col].addon) {
                            value = ValueUtilsService.parseDecimal(value);
                        }

                        row.push(value);

                        if (value != "") {
                            isEmpty = false;
                        }
                    }

                    if (!isEmpty) {
                        rowData.push(row);
                    }
                }

                // the grid is empty and we need to add at least one line because
                // the user might have deleted everything inside the grid ...
                if (rowData.length === 0) {
                    let row = [];

                    for (let index in columns) {
                        row.push("");
                    }
                }

                fields[fieldModel.name] = {
                    type: "GRID",
                    internalName: fieldModel.internal,
                    columns: colData,
                    rows: rowData
                };
            } else {
                let value = formData[i].api.getValue(true);
                if (fieldModel.type == "date") {
                    value = ValueUtilsService.fixDaylightSavingIncompatibility(value);
                }

                fields[fieldModel.internal] = {
                    value,
                    internalName: fieldModel.internal
                };
            }
        }

        return payload;
    }

    // we need to prepare the current formdata for the localstorage to ensure
    // there are no circular dependencies like scopes, or lists inside the object
    // otherwise the localstorage is not able to save this..
    function reduceFormdata(formData) {
        let newData = {};

        for (let key in formData) {
            let field = formData[key];

            if ((field.value == void 0 || field.value === "") && !field.model.isNull && !field.to && field.model.type != "grid") {
                continue;
            }

            if (field.to == void 0) {
                newData[key] = {
                    value: field.value.toString(),
                    model: {}
                };
            } else {
                newData[key] = {
                    value: field.value.toString(),
                    to: field.to.toString(),
                    model: {}
                };
            }

            for (let i in field.model) {
                let val = field.model[i];

                if (typeof (val) != "string" && typeof (val) != "boolean" && typeof (val) != "number") {
                    continue;
                }

                newData[key].model[i] = val;
            }

            if (field.model.type == "grid") {
                if (field.gridData) {
                    newData[key].gridData = field.gridData;
                } else if (field.api == void 0) {
                    newData[key].gridData = [];
                } else {
                    newData[key].gridData = angular.copy(field.api.getRows());
                }
            }
        }

        return newData;
    }

    function submitSearchForm(formHelper) {
        if (formHelper == void 0) {
            return $q.when();
        }

        let deferred = $q.defer();

        let formData = formHelper.getFields(),
            modelDef = formHelper.getModel(),
            newData = reduceFormdata(formData);

        // set the data for the current state
        StateHistoryManager.setStateData(newData, $stateParams.state);

        // we are now generating a new state which we will call in a few steps
        // to initialize a new state we have to set a timestamp for it to get their data (the timestamp is the ID)
        let nextStateId = $.now();

        // next we create a package with all the relevant data
        // in this case the whole form, the objecttypeid and the type of task
        // the config key is used by the state itself
        // a state can update its state model with information like "sort,grouping, scroll position etc..."
        let nextStateContent = {
            config: Object.assign({ executeSingleHitAction: true }, StateHistoryManager.getStateData($stateParams.state).data.config || {}),
            formDataTypes: {}, // wrap this in an array for later when we can search for multiple things at once
            objectTypeIds: [$stateParams.objectTypeId],
            activePage: $stateParams.objectTypeId,
            type: "search",
            searchType: "form",
            description: modelDef.config.title
        };

        nextStateContent.formDataTypes[$stateParams.objectTypeId] = newData;

        let config = formHelper.getConfig();

        if (config.fulltext != "" && config.fulltext != void 0) {
            nextStateContent.fulltext = config.fulltext;
        }

        // this function generates a new state with given data
        StateHistoryManager.setStateData(nextStateContent, nextStateId);

        deferred.resolve();

        // jump into the new state
        $state.go("hitlist.result", { state: nextStateId });

        return deferred.promise;
    }

    function submitCombinedSearchForm(searchDefs) {
        if (searchDefs == void 0) {
            return $q.when();
        }

        let deferred = $q.defer();
        let combinedFormData = {};
        let fulltext = {}
        for (let searchDef of searchDefs) {
            let formData = searchDef.formDef.formHelper.getFields(),
                modelDef = searchDef.formDef.formHelper.getModel(),
                newData = reduceFormdata(formData);

            combinedFormData[modelDef.config.objectTypeId] = newData

            if (searchDef.fulltext != "") {
                fulltext[modelDef.config.objectTypeId] = searchDef.fulltext
            }
        }

        combinedFormData.fulltext = fulltext;

        let stateData = StateHistoryManager.getStateData($stateParams.state).data;
        stateData.combinedFormData = combinedFormData;

        // set the data for the current state
        StateHistoryManager.setStateData(stateData, $stateParams.state);

        // we are now generating a new state which we will call in a few steps
        // to initialize a new state we have to set a timestamp for it to get their data (the timestamp is the ID)
        let nextStateId = $.now();

        // next we create a package with all the relevant data
        // in this case the whole form, the objecttypeid and the type of task
        // the config key is used by the state itself
        // a state can update its state model with information like "sort,grouping, scroll position etc..."
        let nextStateContent = {
            config: Object.assign({ executeSingleHitAction: true }, StateHistoryManager.getStateData($stateParams.state).data.config || {}),
            formDataTypes: combinedFormData, // wrap this in an array for later when we can search for multiple things at once
            objectTypeIds: Object.keys(combinedFormData),
            activePage: $stateParams.objectTypeId,
            type: "search",
            searchType: "form",
            description: searchDefs[0].typeDef.model.config.title
        };

        // this function generates a new state with given data
        StateHistoryManager.setStateData(nextStateContent, nextStateId);

        deferred.resolve();

        // jump into the new state
        $state.go("hitlist.result", { state: nextStateId });

        return deferred.promise;
    }

    /**
     * initializes an interval that saved the current state of the form and the dropzone in case
     * the app or the browser gets killed or stops working by any other reason
     * @param formHelper - the formhelper to get the fields
     * @param routingList - the rountingList in case we save a workflow
     */
    function initFormAutoSave(formHelper, routingList) {
        if (ClientService.isPhoneOrTablet()) {
            autoSaveInterval = setInterval(() => {

                if (formHelper == void 0) {
                    return;
                }

                let fields = reduceFormdata(formHelper.getFields());

                let autosavePayload = {
                    fields,
                    files: [],
                    locationHash: location.hash,
                    dropzoneContent: EnvironmentService.getDropzoneContent(),
                    chosenMaintype: formHelper.config.mainType, // in case this is a multitype document
                }

                if ($state.current.name == "workflow") {
                    autosavePayload.files = JSON.parse(JSON.stringify(formHelper.getWfFiles()));
                    autosavePayload.parameters = JSON.parse(JSON.stringify(formHelper.getParameters()));

                    if (formHelper.hasRoutingList) {
                        autosavePayload.routingList = JSON.parse(JSON.stringify(convertRoutingListForSerialization(routingList)));
                    }
                }

                if ("dropzone" in window && dropzone != void 0) {
                    autosavePayload.files = dropzone.files
                }

                FileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, autosavePayload, ProfileCacheKey.AUTOSAVED_DATA)
            }, 5000)

            $timeout(() => {
                let onHashChange = () => {
                    // the autosave of indexdata and files might be running
                    // at this point the user changed the state manually so we can remove the saved data
                    FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, ProfileCacheKey.AUTOSAVED_DATA)
                    // localStorage.removeItem("unfinished-state");
                    clearInterval(autoSaveInterval)
                    window.removeEventListener("hashchange", onHashChange);
                }

                window.addEventListener("hashchange", onHashChange);
            }, 0)
        }
    }

    /**
     * restores the saved indexdata and adds it to the formdata object of the formHelper by object reference
     * @param formData
     * @returns {Promise<void>}
     */
    async function restoreAutosavedIndexdata(formData) {

        let stateId = $location.search().state;
        let autosavedFields;
        if ($location.search().restoreAutosave == "true") {
            let data = await FileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, ProfileCacheKey.AUTOSAVED_DATA, { first: true })
            autosavedFields = data == void 0 ? {} : data.fields
        } else {
            let lastState = StateHistoryManager.getStateData(stateId);
            autosavedFields = lastState.data.fields;
        }

        if (autosavedFields != void 0 && Object.keys(autosavedFields).length !== 0) {
            for (let key in formData) {
                let lastField = autosavedFields[key];
                if (lastField) {
                    for (let i in lastField) {
                        formData[key].value = lastField.value;

                        if (lastField.gridData) {
                            formData[key].gridData = lastField.gridData;
                        }
                    }
                }
            }
        }
    }

    function convertRoutingListForSerialization(routingList) {
        // Ringabhängigkeiten entfernen sonst scheitert die Serialisierung
        let routingListCopy = angular.copy(routingList);

        if (routingListCopy.model != void 0 && routingListCopy.model.groups != void 0) {
            for (let i in routingListCopy.model.groups) {
                let group = routingListCopy.model.groups[i];
                delete group.routingList;

                if (group.model.items != void 0) {
                    for (let j in group.model.items) {
                        let item = group.model.items[j];
                        delete item.group;

                        if (item.model != 0) {
                            for (let k in item.model) {
                                let property = item.model[k];

                                for (let l in property) {
                                    if (l != "value") {
                                        delete property[l];
                                    } else if (k == "dueOn" && property[l].indexOf(" ") > 0) {
                                        property[l] = ValueUtilsService.convertToTimestamp(property[l], true, false);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return routingListCopy;
    }

    // DODO-11010 inform user about read-only crosscheck fields in the index data form
    function checkReadonlyCrosscheck(formData) {
        for (let i in formData) {
            if (formData[i].model.readonly && formData[i].model.crossCheck) {
                NotificationsService.showToast("info", $filter("translate")("eob.form.contains.readonly.crosscheck.info"), "", 15000);

                setTimeout(() => {
                    messageService.broadcast(FormEvent.DISABLE_SAVE_BUTTON);
                }, 0);

                return;
            }
        }
    }

    /**
     * Returns to last state.
     */
    function goBack() {
        $timeout(() => {
            const currentStateData = StateHistoryManager.getCurrentStateData();

            if (currentStateData.data && currentStateData.data.config && currentStateData.data.config.userAction == "entry") {
                const previousStateData = StateHistoryManager.getStateData(currentStateData.data.config.caller);

                if (previousStateData.data && previousStateData.data.params && previousStateData.data.params.indexdata) {
                    const items = [{
                        osid: previousStateData.data.params.indexdata,
                        objectTypeId: "unknown"
                    }];

                    DmsActionService.openResultListByIds(false, items, undefined, undefined, false, undefined);
                } else {
                    StateHistoryManager.goToPreviousState();
                }
            } else {
                StateHistoryManager.goToPreviousState();
            }
        }, 500);
    }

    async function clearExternalTrayItem(itemKey) {
        try {
            await FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, "", itemKey);
            ClientService.broadcastTrayItemsChanged();
        } catch (error) {
            NotificationsService.error(this.translateFn("eob.external.tray.item.delete.failed"));
        }
    }

    /**
     * Get the deep, unique tabindex of a field, that determines the order of the fields on a form.
     * @returns number[] An array of tab indexes:
     * [position of the field on the top layer (tab index of the field or of the parent page ctrl),
     * position of the field on the middle layer (tab index of the page ctrl page or nothing),
     * tab index of the field]
     */
    function getFieldDeepTabOrder(fieldDefinition) {
        let topPositionI = fieldDefinition.tabIndex;
        let pageI = 0;
        let tabI = fieldDefinition.tabIndex;

        if (fieldDefinition.pageControl) {
            topPositionI = fieldDefinition.pageControl.tabIndex;
            pageI = parseInt(fieldDefinition.pageIndex);
        }

        return [topPositionI, pageI, tabI];
    }

    /**
     * Sort fields by their tab index.
     * Takes into account that fields can have the same tab index, if they are on page ctrl pages.
     * In that case the tab index of the page ctrl and the index of the page are also considered.
     */
    function sortFieldsByOrder(fields) {
        return fields.sort((fieldA, fieldB) => {
            let orderA = fieldA.order, orderB = fieldB.order;

            for (let i = 0; i < 3; i++) {
                let a = orderA[i], b = orderB[i];
                if (a !== b) {
                    return a - b;
                }
            }

            return 0;
        });
    }
}
