import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {Subject} from "rxjs";
import {ExternalTrayEvents} from "../../../app/modules/external-tray/enums/external-tray-events.enum";
import {TimerDoneType} from "../../../app/modules/timer/enums/timer-done-type";
import * as dayjs from "dayjs";
import {EobExternalTrayElementsComponent} from "../../../app/modules/external-tray/components/external-tray-elements/eob-external-tray-elements.component";

require("COMPONENTS_PATH/eob-modal-container/eob-modal-external-tray-locations/eob.modal.external.tray.locations.dir.js");

angular.module("eob.core").factory("externalTrayService", ExternalTrayService);

ExternalTrayService.$inject = ["$stateParams", "$rootScope", "$filter", "$compile", "$eobConfig", "$location", "$timeout", "environmentService", "backendService",
    "modalDialogService", "clientService", "objectTypeService", "actionService", "notificationsService", "errorModelService", "viewerService", "fieldsetBuilderService",
    "cacheManagerService", "searchService", "dmsDocumentService", "dmsContentService", "dmsActionService", "fileCacheService", "stateHistoryManager",
    "progressbarService", "modalDialogInjectorService", "messageService", "timerService"];

/**
 * This service handles files in the external tray when using Electron Webclient
 */
function ExternalTrayService($stateParams, $rootScope, $filter, $compile, $eobConfig, $location, $timeout, EnvironmentService, BackendService,
                             ModalDialogService, ClientService, ObjectTypeService, ActionService, NotificationsService, ErrorModelService, ViewerService, FieldsetBuilderService,
                             CacheManagerService, SearchService, DmsDocumentService, DmsContentService, DmsActionService, FileCacheService, StateHistoryManager,
                             ProgressbarService, ModalDialogInjectorService, MessageService, TimerService) {

    let gCurrentTrayItems = [];

    const service = {
        addTrayItemAsync,
        insertTrayItemAtLocationAsync,
        insertTrayItemInDropzone,
        insertVariantFromTrayAsync,
        storeDataInIndexedDBAsync,
        getExternalTrayItemsByType,
        getExternalTrayItemsAsync,
        getTrayElementByTrayIdAsync,
        openTrayElementAsync,
        renameTrayElementAsync,
        checkInTrayItemAsync,
        removeTrayElementAsync,
        removeTrayElementByGroupkeyAsync,
        getInsertableTrayItems,
        chooseLocationForInsertFromTrayAsync,
        createMetadata
    };

    return service;

    /**
     * returns the metadata object for a specific groupkey
     * @param {string} groupKey - the groupkey of the meatadata object
     * @returns {Promise<object>} - returns the promise from the async function and resolves to a metadata object
     */
    async function getTrayElementByTrayIdAsync(groupKey) {
        let trayItems = await getExternalTrayItemsAsync();

        for (let metadataObject of trayItems) {
            if (metadataObject.groupKey == groupKey) {
                return metadataObject;
            }
        }
    }

    async function getExternalTrayItemsByType(allowedTypes) {
        let trayElements = await this.getExternalTrayItemsAsync();

        //filter the trayElements to show only elements that have file extensions which match the allowed file extensions of the document
        return trayElements.filter(trayElement =>
            trayElement.fileNames.every(filename => {
                let fileExt = filename.split(".").pop().toUpperCase();
                return allowedTypes.indexOf(fileExt) > -1;
            })
        );
    }

    /**
     * Get list of external tray items with mainType icon
     * @returns {Array<object>} - Array with tray items which consists
     */
    async function getExternalTrayItemsAsync() {
        let items = await FileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, "metadata", { groupStartsWith: "external_" });
        items = items || [];

        for (let metadata of items) {
            let mainType = 1;

            //metadata.mainTypes should always be defined but we're checking just in case there are some items with the former metadata structure
            if (metadata.mainTypes != void 0) {
                // there could be multiple mainTypes. We use the first one.
                mainType = metadata.mainTypes[0];
            } else if (metadata.mainType != void 0) {
                mainType = metadata.mainType;
            }

            metadata.icon = ObjectTypeService.getDefaultMainTypeIcon(metadata.mainTypes[0]);
        }

        // Cache the items for sync access
        gCurrentTrayItems = items;

        return items;
    }

    /**
     * Get the cached tray items. The load function is async and in the context menu we are sync
     * Therefore we must cache these items to use it there.
     *
     * @returns {Array}
     */
    function getCurrentTrayItems() {
        return gCurrentTrayItems;
    }

    /**
     * Adds an external tray item with its metadata to an location
     *
     * @param {object} metadata - The metadata of the tray item
     * @param {object} location - Location data.
     * @param {object} additionalData - Additional data needed for further steps.
     * @param {Observable} result$ - Subscribe to, to get result informations.
     * @returns {Promise<void>} - resolves void
     */
    async function addTrayItemAsync(metadata, location, additionalData, result$) {
        console.info(`ExternalTrayAdding: ${metadata}`);

        let context = StateHistoryManager.getCurrentStateContext();

        if (context.type == "createWithDropzone") {
            insertTrayItemInDropzone(metadata);
        } else {
            if (ClientService.isMobile()) {
                let action = await _showInsertActionsDialogAsync(metadata);
                metadata.trayItemType = action == void 0 ? "" : action.id;
            }

            try {
                await storeDataInIndexedDBAsync(metadata);
            } catch (error) {
                console.error(error);
                await FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, "", metadata.groupKey);
                NotificationsService.error($filter("translate")("eob.open.file.failed"));
                return;
            }

            // TODO: The if can be delete with next major release I think. Then every one should have updated from 9.00 Final to an 9.00 SPx or later in between.
            if (!metadata.fromUpdate) {
                switch (metadata.trayItemType) {
                    case "insertVariant":
                        await insertVariantFromTrayAsync(metadata);
                        break;
                    case "insertDocument":
                        await insertTrayItemAtLocationAsync(metadata, location, true, additionalData);
                        break;
                    case "insertEmails":
                        await insertEmailsAsync(metadata, location, true, additionalData, result$);
                        break;
                    case "checkIn":
                        await checkInTrayItemAsync(metadata);
                        break;
                    default:
                        await renameTrayElementAsync(metadata, true, $filter("translate")("eob.action.modal.external.tray.rename.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
                        NotificationsService.info($filter("translate")("external.tray.element.added.notification"));
                        MessageService.broadcast(ExternalTrayEvents.TRAY_ELEMENTS_CHANGED)
                }
            }
        }
    }

    async function insertEmailsAsync(metadata, location, showRenameOnAbort, additionalData = {}, result$) {
        const internalName = CacheManagerService.objectTypes.getById(metadata.objectTypeId).model.config.internal;
        const emsConfig = EnvironmentService.getEmsTypeByInternal(internalName);

        if (ClientService.isDesktop()) {
            window.electron.bringAppWindowToFront();
        }

        if (emsConfig == void 0) {
            NotificationsService.error($filter("translate")("eob.ems.not.available"));
            result$.next(TimerDoneType.EMS_NOT_AVAILABLE);
            result$.complete();
            return;
        }

        localStorage.setItem("metadataGroupKey", metadata.groupKey);
        window.electron.storeSessionStorage();

        if (emsConfig.emsType.showIndexdata) {
            await insertTrayItemAtLocationAsync(metadata, location, true, additionalData);

            MessageService.subscribe(Broadcasts.CREATION_VIEW_READY, (data) => {
                data.createResult$.subscribe(x => {
                    result$.next(x);
                    result$.complete();
                });
            });

            let cancel = false;
            result$.subscribe(async x => {
                await FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, "", metadata.groupKey);
                ClientService.broadcastTrayItemsChanged();
                localStorage.removeItem("metadataGroupKey");

                if (TimerDoneType.CREATE_VIEW_CANCEL == x) {
                    cancel = true;
                    TimerService.stop();

                    if (!window.electron.getIsReusedLocationTab()) {
                        window.electron.closeActiveTab();
                    } else {
                        setTimeout(async() => {
                            await DmsActionService.openLocation(false, undefined, undefined, metadata.parentObjectId, metadata.parentObjectTypeId);
                        }, 800);
                    }
                }
            });

            let form;
            MessageService.subscribeFirst(Broadcasts.INSERT_EMAILS_CREATE_FORM, (data) => {
                form = data.form;
            });

            MessageService.subscribeFirst(Broadcasts.LOCATION_VIEW_READY, (data) => {
                data.emsResult$.subscribe(x => {
                    result$.next(x);
                    result$.complete();
                });
                if (!cancel) {
                    MessageService.broadcast(ModalEvents.INSERT_EMAILS_AT_LOCATION, { metadata, form });
                }
            });
        } else {
            await insertTrayItemWithoutShowingFormAsync(metadata, location, result$);
        }
    }

    async function insertTrayItemWithoutShowingFormAsync(metadata, location, result$) {
        try {
            await DmsActionService.openLocation(false, undefined, undefined, location.osid, location.objectTypeId);
        } catch (error) {
            console.warn("Missing location: ", error);
            NotificationsService.error($filter("translate")("eob.action.open.location.error"));
            return;
        }

        MessageService.subscribeFirst(Broadcasts.LOCATION_VIEW_READY, (data) => {
            data.emsResult$.subscribe(x => {
                result$.next(x);
                result$.complete();
            });
            MessageService.broadcast(ModalEvents.INSERT_EMAILS_AT_LOCATION, { metadata });
        });
    }

    async function storeDataInIndexedDBAsync(metadata) {
        // Store content from filesystem into indexedDB
        if (metadata.filePaths instanceof Array && metadata.filePaths.length > 0) {
            await storeDataByFilePathsArrayAsync(metadata, metadata.filePaths);
        } else if (metadata.filePaths instanceof Map) {
            for (let [filename, content] of metadata.filePaths.entries()) {
                console.info(`ExternalTrayAdding: ${filename}`);
                await FileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, content, filename, metadata.groupKey);
                console.info(`ExternalTrayAdding: ${filename} ... success`);
            }

            // Prevent persisting content twice
            delete metadata.filePaths;
        } else if (metadata.objects instanceof Array && metadata.objects.length > 0) {
            const filePaths = metadata.objects.map(x => x.properties.filePath.value);
            await storeDataByFilePathsArrayAsync(metadata, filePaths);
        }

        // All other entries should have a file extension so metadata as key should be save
        await FileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, metadata, "metadata", metadata.groupKey);

        console.info(`ExternalTrayAddingMetadata: ${metadata} ... success`);
        ClientService.broadcastTrayItemsChanged();
        console.info("ExternalTray: renamed finished");
    }

    async function storeDataByFilePathsArrayAsync(metadata, filePaths) {
        for (let filePath of filePaths) {
            console.info(`ExternalTrayAdding: ${filePath}`);

            let filename = filePath.split(/[/\\]/).pop();
            let fileContent = await ClientService.readDataFromFileAsync(filePath, false);

            await FileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, fileContent, filename, metadata.groupKey);

            console.info(`ExternalTrayAdding: ${filePath} ... success`);
        }
    }

    /**
     * Shows a list dialog that lets the user choose what thay want to do with the new tray item.
     * @param {object} metadata - metadata of the tray item.
     * @returns {Promise<string>} - the option that the user chose
     * @private
     */
    async function _showInsertActionsDialogAsync(metadata) {
        let insertResolve = null, insertReject = null, insertPromise = new Promise((resolve, reject) => {
            insertResolve = resolve;
            insertReject = reject;
        });

        try {

            let listItems = await _getInsertActionsAsync(metadata.mainTypes);
            if (listItems.length == 1) {
                return listItems[0];
            }

            let modalScope = $rootScope.$new();
            modalScope.resolve = insertResolve;
            modalScope.reject = insertReject;
            modalScope.buttons = { cancel: $filter("translate")("modal.button.cancel") };
            modalScope.destroy = new Subject();

            modalScope.config = {
                message: $filter("translate")("eob.action.external.tray.choose.action.message"),
                listItems
            };

            ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                input: {
                    title: $filter("translate")("eob.action.modal.new.external.tray.element.title"),
                    noDismissing: true
                },
                childElement: angular.element("<eob-modal-list config=\"config\" buttons=\"buttons\" resolve=\"resolve\" reject=\"resolve\" destroy=\"destroy\"></eob-modal-list>"),
                scope: modalScope
            });

            $rootScope.$apply();
        } catch (error) {
            renameTrayElementAsync(metadata, true, $filter("translate")("eob.action.modal.external.tray.rename.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
        }

        return await insertPromise;
    }

    /**
     * Builds an array with the possible actions for new tray items that will presented to the user depending on the situation.
     *
     * @param {array} maintypes - the maintypes the extenal tray item could possibly have
     * @returns {array} - the array with the list items for the modal dialog
     * @private
     */
    async function _getInsertActionsAsync(maintypes) {
        let listItems = [{
            id: "external",
            icon: "icon-16-external-tray",
            title: $filter("translate")("eob.action.external.tray.leave.in.tray.message")
        }];

        if (maintypes.length == 1 && maintypes[0] == 4) {
            let verifiedObjects = await SearchService.searchCheckedOutObjectsAsync();
            if (verifiedObjects.length) {
                listItems.push({
                    id: "checkIn",
                    icon: "lock",
                    title: $filter("translate")("eob.action.external.tray.check.in.message")
                });
            }
        }

        if (ClientService.isOnline() && StateHistoryManager.getCurrentStateContext().type == "location") {
            listItems.push({
                id: "insertDocument",
                icon: "icon-16-dokument-neu",
                title: $filter("translate")("eob.contextmenu.action.external.tray.insert.in.location")
            });
        }
        return listItems;
    }

    /**
     * Remove a single tray element by the given groupkey
     * @param {string} groupKey - The unique identifier of the metadata object
     * @returns {Promise<void>}
     */
    async function removeTrayElementByGroupkeyAsync(groupKey) {
        return await removeTrayElementAsync(await getTrayElementByTrayIdAsync(groupKey));
    }

    /**
     * Open tray item in shell with standard app.
     *
     * @param {object} metadata - The tray item to open
     * @returns {Promise<void>}
     */
    async function openTrayElementAsync(metadata) {
        let fileContent = await FileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, metadata.fileNames[0], {
            group: metadata.groupKey,
            first: true
        });

        if (fileContent != void 0) {
            await ClientService.openFileDataInDefaultAppAsync(null, metadata.fileNames[0], fileContent);
        }
    }

    /**
     * Remove element from external tray using information from tray item
     * @param {object} metadata - tray item.
     */
    async function removeTrayElementAsync(metadata) {
        try {
            let confirmMessage = $filter("translate")("eob.action.external.tray.remove.element.confirm.message");
            let cancelButtonTitle = $filter("translate")("modal.button.cancel");
            let deleteButtonTitle = $filter("translate")("modal.button.delete");

            await ModalDialogService.infoDialog(deleteButtonTitle, confirmMessage, cancelButtonTitle, deleteButtonTitle, null, false, { allowOfflineSubmission: true });

            try {
                await FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, "", metadata.groupKey);
                ClientService.broadcastTrayItemsChanged();
            } catch (error) {
                NotificationsService.error($filter("translate")("eob.external.tray.item.delete.failed"));
            }
        } catch (_) {
            // user does not want to remove the element
        }
    }

    /**
     * Change display title of element in external tray
     * @param {object} metadata - metadata of the tray item.
     * @param {object} isDismissable - indicates weather the user is allowed to cancel the dialog.
     * @param {string} dialogTitle - optional dialog title
     * @param {string} dialogMessage - optional dialog message
     */
    async function renameTrayElementAsync(metadata, isDismissable = true, dialogTitle = "", dialogMessage = "") {
        let renameResolve, renamePromise = new Promise((resolve) => {
            renameResolve = resolve;
        });

        let confirmObject = {
            message: dialogMessage !== "" ? dialogMessage : $filter("translate")("eob.action.modal.external.tray.rename.message"),
            suggestion: metadata.displayTitle,
            callback: (confirmation) => {
                confirmation.notifyCallback(true);
                confirmation.finalize(confirmation.message);
            },
            finalize: async(newDisplayTitle) => {
                metadata.displayTitle = newDisplayTitle;
                await FileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, metadata, "metadata", metadata.groupKey);
                renameResolve();
            },
            allowOfflineSubmission: true
        };

        let buttons = {
            hideCancelButton: isDismissable == false
        };

        try {
            let modalScope = $rootScope.$new();
            modalScope.confirmObject = confirmObject;
            modalScope.buttons = buttons;
            modalScope.destroy = new Subject();
            let noDismissing = isDismissable == false;

            ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                input: {
                    title: dialogTitle !== "" ? dialogTitle : $filter("translate")("eob.desktop.rename.item.title"),
                    noDismissing
                },
                childElement: angular.element("<eob-modal-promt confirm-object=\"confirmObject\" buttons=\"buttons\" destroy=\"destroy\"></eob-modal-promt>"),
                scope: modalScope
            });
        } catch (_) {
            // user does not want to rename the element
            renameResolve();
        }

        await renamePromise;

        ClientService.broadcastTrayItemsChanged();
    }

    /**
     * Asking user which Document should be checked in and doing the check in.
     *
     * @param {object} metadata - metadata of the tray item.
     * @param {object} chosenItem - item that should be checked in
     * @param {boolean} isFromTray - whether the document is an already existing tray item
     * @access public
     */
    async function checkInTrayItemAsync(metadata, chosenItem = undefined, isFromTray = false) {
        chosenItem = chosenItem == void 0 ? await getDocumentToCheckInDialogAsync(metadata, isFromTray) : chosenItem;

        if (chosenItem == void 0 || chosenItem.dmsDocument == void 0) {
            if (!isFromTray) {
                await renameTrayElementAsync(metadata, false, $filter("translate")("eob.action.modal.external.tray.rename.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
            }
            return;
        }

        ModalDialogService.showProgressDialog("eob.status.loading.checkin");

        let osid = chosenItem.dmsDocument.model.osid;

        //we're not getting the item from cache again because user could have done checkin on another device already
        let dmsDocument = await SearchService.searchById(osid, chosenItem.dmsDocument.model.objectTypeId);
        CacheManagerService.dmsDocuments.add(dmsDocument);

        if (dmsDocument.model.baseParameters.locked != "SELF") {
            ModalDialogService.hideProgressDialog();

            NotificationsService.error($filter("translate")("eob.action.modal.external.document.already.checked.in"));
            CacheManagerService.dmsDocuments.executeListeners(osid);
            await renameTrayElementAsync(metadata, false, $filter("translate")("eob.action.modal.external.tray.rename.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
            return;
        }

        try {
            let file = await FileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, metadata.fileNames[0], {
                group: metadata.groupKey,
                first: true
            });

            if (file == void 0) {
                throw "file is missing";
            }

            let data = new FormData();
            data.append("file", new Blob([file]), metadata.fileNames[0]);

            let result = await BackendService.post("/fileUpload.do", data, {
                responseType: "text",
                headers: { "Content-Type": void 0 }
            }, $eobConfig.getOswebBase());
            let pseudoDropZone = {
                files: [result.data],
                item: dmsDocument.model
            };

            await DmsContentService.addContentToDocumentAsync(pseudoDropZone, osid);
            await DmsActionService.undoCheckOut(dmsDocument);

            await ViewerService.refreshContent(osid);
            await ViewerService.updateO365Viewer(osid, dmsDocument);

            await FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, "", metadata.groupKey);
            ClientService.broadcastTrayItemsChanged();

            ModalDialogService.hideProgressDialog();
            NotificationsService.success($filter("translate")("eob.action.modal.external.document.checkin.success"));
        } catch (_) {
            ModalDialogService.hideProgressDialog();
            NotificationsService.error($filter("translate")("eob.action.modal.external.document.checkin.failed"));
        }
    }

    /**
     * Displays modal dialog for user to choose a document that they want to check in with content from external tray
     * @param {object} metadata - metadata of the tray item.
     * @access public
     * @returns {Promise<object>} - the chosen list item that contains a dmsDocument
     */
    async function getDocumentToCheckInDialogAsync(metadata, isFromTray) {
        let checkinObjectResolve = null, checkinObjectReject = null,
            checkinObjectPromise = new Promise((resolve, reject) => {
                checkinObjectResolve = resolve;
                checkinObjectReject = reject;
            });

        try {
            let modalScope = $rootScope.$new();
            modalScope.resolve = checkinObjectResolve;
            modalScope.reject = checkinObjectReject;
            modalScope.buttons = { cancel: $filter("translate")("modal.button.cancel") };
            modalScope.destroy = new Subject();
            modalScope.config = {
                message: $filter("translate")("eob.action.modal.external.choose.document.for.checkin.message")
            };

            let dom = angular.element("<eob-modal-list config=\"config\" buttons=\"buttons\" resolve=\"resolve\" reject=\"resolve\" destroy=\"destroy\"></eob-modal-list>");
            dom.append("<div id=\"loadAnimation\" class=\"osProgressbar\"><div class=\"osloader\"></div></div>");

            ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                input: {
                    title: $filter("translate")("eob.action.modal.external.checkin.tray.item.title")
                },
                childElement: dom,
                scope: modalScope
            });

            // $rootScope.$apply();

            let matchingDocuments = [];
            let listItems = [];
            let allCheckedOutObjects = await SearchService.searchCheckedOutObjectsAsync();

            if (!allCheckedOutObjects.length) {
                if (isFromTray) {
                    MessageService.broadcast(ModalEvents.DESTROY);
                    NotificationsService.info($filter("translate")("eob.action.modal.external.no.checked.out.documents"));
                } else {
                    NotificationsService.error($filter("translate")("eob.action.modal.external.document.already.checked.in"));
                    await renameTrayElementAsync(metadata, true, $filter("translate")("eob.action.modal.external.tray.rename.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
                }
                return;
            }

            let checkedOutIds = CacheManagerService.dmsDocuments.add(allCheckedOutObjects);
            let checkedOutDocuments = CacheManagerService.dmsDocuments.get(checkedOutIds);

            for (let dmsDocument of checkedOutDocuments) {
                let listItem = {
                    title: DmsDocumentService.buildTitleByMode(dmsDocument, "hitlist", false),
                    id: dmsDocument.model.osid,
                    icon: dmsDocument.model.icon,
                    dmsDocument
                };

                if (!dmsDocument.model.rights.objModify) {
                    continue;
                }

                if (metadata.mainTypes.indexOf(dmsDocument.model.subType) == -1) {
                    continue;
                }

                if (metadata.fileNames[0].indexOf(dmsDocument.model.fileProperties.fileExtension) > -1) {
                    matchingDocuments.push(listItem);
                } else {
                    listItems.push(listItem);
                }
            }

            listItems.sort((a, b) => {
                return b.dmsDocument.model.baseParameters.lockedTime - a.dmsDocument.model.baseParameters.lockedTime;
            });

            //documents with matching file names will be displayed first
            listItems = matchingDocuments.concat(listItems);
            modalScope.config.listItems = listItems;

            if (isFromTray && !listItems.length) {
                MessageService.broadcast(ModalEvents.DESTROY);
                NotificationsService.info($filter("translate")("eob.action.modal.external.no.checked.out.documents"));
            }

            $timeout(() => {
                let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", null, true);
                progressBar.hide();
                $rootScope.$apply();
            });
        } catch (_) {
            // user does not want to rename the element
            if (isFromTray) {
                //TODO
            } else {
                renameTrayElementAsync(metadata, true, $filter("translate")("eob.action.modal.external.tray.rename.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
                NotificationsService.info($filter("translate")("external.tray.element.added.notification"));
            }
        }
        return checkinObjectPromise;
    }

    /**
     * Add a variant from the external tray
     * @param {Object} metadata - the metadata object of the tray item
     * @param {Boolean=} showRenameOnAbort - An optional parameter if the user has to give the file a name on abort
     * @return {Promise<void>}
     */
    async function insertVariantFromTrayAsync(metadata, showRenameOnAbort = true) {
        let osid = metadata.objectId;

        if (osid == void 0) {
            return;
        }

        let context = StateHistoryManager.getCurrentStateContext().type;

        if (context != "variants" || $stateParams.id != osid) {
            StateHistoryManager.goToManageVariants({
                model: {
                    osid: metadata.objectId,
                    objectTypeId: metadata.objectTypeId
                }
            }, metadata.groupKey);
        }

        let modalScope = $rootScope.$new();
        let typedef = CacheManagerService.objectTypes.getById(metadata.objectTypeId);

        modalScope.osid = osid;
        modalScope.metadata = metadata;

        let variantResolve = null, variantReject = null, variantPromise = new Promise((resolve, reject) => {
            variantResolve = resolve;
            variantReject = reject;
        });

        modalScope.resolve = variantResolve;
        modalScope.reject = variantReject;

        $timeout(async() => {
            ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
                input: {
                    title: $filter("translate")("eob.action.modal.external.tray.variant.version.title"),
                    description: typedef.model.config.name
                },
                childElement: angular.element("<eob-modal-external-variants resolve='resolve' reject='reject' metadata='metadata' osid='osid'></eob-modal-external-variants>"),
                scope: modalScope
            });

            try {
                await variantPromise;
            } catch (_) {
                //show rename
                if (showRenameOnAbort) {
                    renameTrayElementAsync(metadata, false, $filter("translate")("eob.action.modal.external.tray.rename.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
                    NotificationsService.info($filter("translate")("external.tray.element.added.notification"));
                }
            }
        }, 0);
    }

    /**
     * Choose location for inserting a tray element
     * @param {Array<object>} locations - array of location information
     * @return {Promise<void>} - Object with the selected location
     */
    async function chooseLocationForInsertFromTrayAsync(locations) {
        if (!locations || locations.length < 1) {
            console.warn("Missing locations");
            return;
        }

        let locationList = [];

        for (let location of locations) {
            let dmsDocument;

            try {
                dmsDocument = CacheManagerService.dmsDocuments.getById(location.objectId);

                if (!dmsDocument) {
                    dmsDocument = await SearchService.searchById(location.objectId, location.objectTypeId);
                }

                if (dmsDocument) {
                    locationList.push({
                        dmsDocument,
                        icon: dmsDocument.model.icon,
                        name: dmsDocument.model.name,
                        title: DmsDocumentService.buildTitleByMode(dmsDocument, "window"),
                        webviewId: location.webviewId
                    });
                } else {
                    console.warn(`No dmsDocument found for objectId ${location.objectId} and objectTypeId ${location.objectTypeId}`);
                }
            } catch (_) {
                console.warn(`No dmsDocument found for objectId ${location.objectId} and objectTypeId ${location.objectTypeId}`);
            }
        }

        if (locationList.length < 1) {
            console.warn("No object titles");
            return;
        }

        let locationsResolve, locationsReject, locationsPromise = new Promise((resolve, reject) => {
            locationsResolve = resolve;
            locationsReject = reject;
        });

        let modalScope = $rootScope.$new();
        modalScope.resolve = locationsResolve;
        modalScope.reject = locationsReject;
        modalScope.locations = locationList;
        modalScope.message = $filter("translate")("eob.action.modal.external.tray.choose.location.message");
        modalScope.destroy = new Subject();

        ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
            input: {
                title: $filter("translate")("eob.action.modal.external.tray.choose.location.title")
            },
            childElement: angular.element("<eob-modal-external-tray-locations message=\"message\" locations=\"locations\" resolve=\"resolve\" reject=\"reject\" destroy=\"destroy\"></eob-modal-external-tray-locations>"),
            scope: modalScope
        });

        $rootScope.$apply();

        return await locationsPromise;
    }

    /**
     * Get all tray items that can be inserted in a certain location with the given insertable object types
     * @param {Array} insertableObjectTypes - object types that can be inserted in a certain location
     * @returns the tray items that can be inserted in a certain location as an Array
     */
    function getInsertableTrayItems(insertableObjectTypes) {
        let externalTrayItems = getExternalTrayItemsAsync();
        let insertableMainTypes = [];
        let insertableItems = [];

        insertableObjectTypes.forEach((typeId) => {
            let typeConfig = CacheManagerService.objectTypes.getById(typeId).model.config;

            if (insertableMainTypes.indexOf(typeConfig.mainType) == -1) {
                insertableMainTypes.push(typeConfig.mainType);
            }
        });

        //we only want to show items that really can be inserted in the location
        for (let metadata of externalTrayItems) {
            // some images for example can have multiple possible maintypes
            for (let mainType of metadata.mainTypes) {
                if (insertableMainTypes.indexOf(mainType) > -1) {
                    insertableItems.push(metadata);
                    break;
                }
            }
        }

        return insertableItems;
    }

    /**
     * Insert new document using given parent location and optional metadataFile.
     * If no metadataFile is given and the external tray has more than one element, a modal choice dialog will be shown.
     *
     * @param {object} metadata - metadata to identify tray item
     * @param {{ osid, objectTypeId }|{ osid, objectTypeId }[]} location - A pair of ids of the location for insert.
     * If multiple locations are given, the user will be prestened with a modal dialog to choose a location.
     * @param {boolean} showRenameOnAbort - if true a rename dialog will be shown on abort
     * @param {object} additionalData - Additional data for further steps.
     * @returns {Promise<void>} Resolved once the state change is triggered or the user aborted the action.
     */
    async function insertTrayItemAtLocationAsync(metadata, location, showRenameOnAbort, additionalData = {}) {
        try {
            let inNewTab = false;

            // Where multiple locations present? Then the caller has no fixed location defined and the
            // servicebridge has determined the open locations.
            if (ClientService.isOffline()) {
                location = void 0;
            } else if (location instanceof Array) {
                try {
                    let locationData = await chooseLocationForInsertFromTrayAsync(location);

                    if (locationData !== void 0) {
                        location = (locationData.dmsDocument || {}).model;
                        inNewTab = locationData.useNewTab;
                    }
                } catch (error) {
                    if (error && error.type === "WEB_USER_ABORTED") {
                        location = void 0;
                    } else {
                        throw error;
                    }
                }
            }

            console.info(`trayItem for creation: ${JSON.stringify(metadata)}`);

            let locationObjectType = location ? ObjectTypeService.getObjectType(location.objectTypeId) : void 0;

            if (!location || (locationObjectType !== "folder" && locationObjectType !== "register")) {
                await renameTrayElementAsync(metadata, true, $filter("translate")("eob.action.modal.external.tray.no.locations.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
                NotificationsService.info($filter("translate")("external.tray.element.added.notification"));
                return;
            }

            let parentTypeConfig = CacheManagerService.objectTypes.getById(location.objectTypeId).model.config;
            let parentInsertableTypes = parentTypeConfig.insertableTypes;
            let chosenObjectType;

            try {
                // wait for objectTypePromise to be resolved or rejected by upper if/else or modal dialog
                const title = $filter("translate")("eob.action.modal.external.tray.object.type.title");
                chosenObjectType = await ModalDialogService.showObjectTypeDialog(title, metadata, location, location.matchedIds || parentInsertableTypes);
            } catch (_) { /**/ }

            if (chosenObjectType === void 0) {
                // user does not want to choose an external tray item or object type
                if (showRenameOnAbort) {
                    await renameTrayElementAsync(metadata, true, $filter("translate")("eob.action.modal.external.tray.rename.title"), $filter("translate")("eob.action.modal.external.tray.rename.message"));
                    NotificationsService.info($filter("translate")("external.tray.element.added.notification"));
                }

                return;
            }

            let stateParams = { openParentAfterCreate: true, groupKey: metadata.groupKey };
            await ActionService.createObject(location, chosenObjectType.objectTypeId, stateParams, inNewTab, additionalData.userAction);

            // TODO: Return objectId to select the new object in hitlist!?!? Prüfenj ob nicht automatisch erfolgt...
        } catch (error) {
            console.error(error);
            NotificationsService.error($filter("translate")("eob.external.tray.insert.metadata.file.failed"));
        }
    }

    /**
     * Inserts the file directly into an open dropzone
     * @param metadata - The metadata object of the file
     */
    function insertTrayItemInDropzone(metadata) {
        // TODO: Show the dialog every time and not only with the dropzone
        // Show upload modal dialog and if it is ready, then we can push it into the dropzone.
        let modalScope = $rootScope.$new();
        modalScope.metadata = metadata;
        modalScope.destroy = new Subject();

        ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
            input: {
                title: $filter("translate")("modal.upload.title")
            },
            childElement: angular.element("<eob-modal-upload metadata='metadata' destroy='destroy'></eob-modal-upload>"),
            scope: modalScope
        });

        $rootScope.$apply();
    }

    /**
     * Convert call parameters to default metadata structure.
     *
     * @param {object} callParameters - call parameters for preparation with {displayTitle, mainType, objectTypeId, parentObjectId, parentObjectTypeId, filePaths, indexData}
     * @returns {object} metadata object
     * @private
     */
    function createMetadata(callParameters) {
        let timestamp = dayjs(new Date()).format("YYYYMMDDhhmmss");
        let mainTypes = [];
        let fileNames = [];

        if (callParameters.mainType) {
            mainTypes.push(`${callParameters.mainType}`);
        }

        if (callParameters.filePaths instanceof Array) {
            for (let filePath of callParameters.filePaths) {
                let filename = filePath.split(/[/\\]/).pop(); // slash and backslash
                let fileType = filename.split(".").pop();

                fileNames.push(filename);

                if (!callParameters.mainType) {
                    // Iterate all main types and check which main types are suitable to the file extension.
                    for (let i = 1; i < 8; i++) {
                        let allowedTypes = EnvironmentService.getAllowedFileExtByMainType(i).split(",");

                        if (allowedTypes.indexOf(fileType.toUpperCase()) > -1 && mainTypes.indexOf(`${i}`) == -1) {
                            mainTypes.push(`${i}`);
                        }
                    }
                }
            }
        } else if (callParameters.filePaths instanceof Map) {
            for (let filename of callParameters.filePaths.keys()) {
                let fileType = filename.split(".").pop();
                fileNames.push(filename);

                if (!callParameters.mainType) {
                    // Iterate all main types and check which main types are suitable to the file extension.
                    for (let i = 1; i < 8; i++) {
                        let allowedTypes = EnvironmentService.getAllowedFileExtByMainType(i).split(",");

                        if (allowedTypes.indexOf(fileType.toUpperCase()) > -1 && mainTypes.indexOf(`${i}`) == -1) {
                            mainTypes.push(`${i}`);
                        }
                    }
                }
            }
        }

        let metadata = {
            displayTitle: callParameters.displayTitle,
            mainTypes,

            objectId: callParameters.objectId,
            objectTypeId: callParameters.objectTypeId,
            parentObjectId: callParameters.parentObjectId,
            parentObjectTypeId: callParameters.parentObjectTypeId,

            groupKey: `external_${timestamp}`,
            filePaths: callParameters.filePaths || [],
            indexData: {
                fields: {}
            },
            fileNames,
            trayItemType: callParameters.callMethod,
            setNewVariantActive: callParameters.setNewVariantActive,
            callTimeout: callParameters.callTimeout
        };

        if (callParameters.indexData) {
            for (let fieldMetadata of callParameters.indexData) {
                metadata.indexData.fields[fieldMetadata.field] = fieldMetadata.value;
            }
        }

        if (!metadata.displayTitle) {
            if (metadata.filePaths instanceof Array && metadata.filePaths.length > 0) {
                metadata.displayTitle = metadata.filePaths[0].split(/[/\\]/).pop(); // slash and backslash
            } else if (metadata.filePaths instanceof Map && metadata.filePaths.size > 0) {
                metadata.displayTitle = "";
                for (let [filename, content] of metadata.filePaths.entries()) {
                    metadata.displayTitle += `${filename}, `;
                }
                metadata.displayTitle = metadata.displayTitle.slice(0, -2);
            } else {
                metadata.displayTitle = timestamp;
            }
        }

        if (callParameters.objects) {
            metadata.objects = callParameters.objects;
        }

        return metadata;
    }
}
