import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EobTimerComponent } from "./components/eob-timer/eob-timer.component";
import { SharedModule } from "SHARED_PATH/shared.module";

@NgModule({
    declarations: [EobTimerComponent],
    exports: [EobTimerComponent],
    imports: [
        CommonModule, SharedModule
    ],
    entryComponents: [EobTimerComponent]
})
export class TimerModule { }
