import {Directive, ElementRef, Injector} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {ajsServiceProviders as providers} from "ROOT_PATH/eob-core/ajs.upgraded.providers";
/**
 * The directive wrapper for the eob-demo-contact.
 */
@Directive({
    selector: "eob-demo-contact",
    providers
})
export class EobDemoContactDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super("eobDemoContact", elementRef, injector);
    }
}