import {Theme} from "MODULES_PATH/theme/theme.interface";
import {Injectable} from "@angular/core";
import {ThemingUtilsService} from "MODULES_PATH/theme/theming-utils.service";

@Injectable({
    providedIn: "root"
})

export class StandardThemeService {

    constructor(private themingUtilsService: ThemingUtilsService) {}

    getStandardTheme(accentColor): Theme{
        return {
            name: "standard",
            general: {
                baseColor: "#f2f2f2",
                urlBgNav: "url('assets/background-images/dashboard-tiles-nav.png')",
                urlBgDashboard: "url('assets/background-images/dashboard-tiles-light.png')",
            },
            color: {
                background: {
                    panel: "#fbfcfd",
                    pane: "#fbfcfd",
                    main: "#dedede", // "darken($panel-color, 8%)",
                    splitPane: "#ddd", // "darken($panel-color, 10%)",
                    inlineDialog: "#fbfcfd", // "lighten($panel-color, 5%)",
                    header: "#fbfcfd", // lighten(var(--color-background-panel), 8%),
                    grid: "#fff", // "darken($panel-color, 2.5%)",
                    overlay: "rgba(0,0,0,0.8)",
                    // navigation
                    //nav: "rgba(0,146,225, 0.78)",
                    nav: accentColor,
                    navOffline: "#8c8c8c", // "lighten($panel-color, 10%)",
                }, accent: {
                    primary: accentColor,
                    gray: "#f1f1f1", // "lighten($panel-color, 10%)",
                    light: this.themingUtilsService.shade(accentColor, 0.24), // "lighten($accent-color, 10%)",
                    extraLight: this.themingUtilsService.shade(accentColor, 0.88), // "rgba(21, 173, 255, 0.15)", "(lighten($accent-color, 10%), 0.15)",
                    desat1:  this.themingUtilsService.shade(accentColor, -0.12), // "desaturate($accent-color-light, 32%)",
                    invalid: "rgba(255,0,0,0.08)",
                    attention: "#ff0",
                    warning: "#ff9900",
                    error: "#bd362f",
                    info: "#0092e1",
                    ok: "#28c700",
                    gradient1: this.themingUtilsService.shade(accentColor, 0.1), //0092e0
                    gradient2: accentColor,
                    gradient3: this.themingUtilsService.shade(accentColor, -0.1),
                }, font: {
                    main: "#777",
                    inverted: "#fff",
                    medium: "rgba(80,80,80, 0.75)",
                    highlighted: "#777", // font color for items that have main font color but are being hovered, focused or selected
                    buttonHover: "#fff", // font color for items that have inverted font color but are being hovered, focused or selected
                    disabled: "rgba(80,80,80, 0.75)",
                }, element: {
                    button: accentColor,
                    navScrollbar: "rgba(255, 255, 255, 0.42)",
                    navScrollbarTrack: accentColor,
                    scrollbar: "#dddddd",
                    formButton: this.themingUtilsService.shade(accentColor, -0.12), // "rgba(desaturate($accent-color, 80%), 0.6)",
                    formInput: "#fbfcfd", // "lighten(#3a3a3a, 8%)",
                }, border: {
                    accessibility: "transparent", // border that is transparent in all themes other than high contrast theme
                    default: "#e5e5e5",
                    button: "transparent", // border that is transparent in all themes other than high contrast theme
                    inverted: "#ffffff",
                    disabled: "#dedede", // "lighten($panel-color, 8%)",
                    defaultNew: "#bbb" // "lighten($panel-color, 8%)",
                }
            }
        };
    }


}
