import {Injectable, Inject} from "@angular/core";
import {OrgAddonField} from "INTERFACES_PATH/addon.field.interface";
import {OrgAddonConfig, OrgAddonEntry} from "INTERFACES_PATH/org-addon-entry.interface";
import {DmsGroupOrgObject, DmsOrganisationObject, DmsUserOrgObject} from "INTERFACES_PATH/dms-organisation-object.interface";
import {RightGroupsConfig} from "MODULES_PATH/form/interfaces/form-addon-config.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {OrgAddonService} from "MODULES_PATH/form/services/form-builder/org-addon.service";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {TodoModalDialogService} from "INTERFACES_PATH/any.types";

@Injectable({ providedIn: "root" })
export class RightGroupsAddonService {
    private readonly translateFn: TranslateFnType;

    constructor(private organisationService: OrganisationService,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("$filter") private $filter: ng.IFilterService, private orgAddonService: OrgAddonService) {
        this.translateFn = this.$filter("translate") ;
    }

    showRightGroupsAddon(event: Event, field: OrgAddonField): void {
        event.stopImmediatePropagation();
        const fieldConfig: RightGroupsConfig = field.model.config;

        field.initialValue = field.value;

        const content: DmsOrganisationObject[] = this.getUserAndGroups(fieldConfig);
        const fieldContent: string[] = field.value != "" ? field.value.toLowerCase().split(";") : [],
            selectFn: (entry: DmsOrganisationObject) => boolean = entry => fieldContent.includes(`${entry.name.toLowerCase()}${(entry as DmsGroupOrgObject).osguid ? "(g)" : "(u)"}`);

        const entries: OrgAddonEntry[] = this.orgAddonService.parseToOrgAddonEntries(content, selectFn);

        const config: OrgAddonConfig = {
            initialSort: (fieldConfig.sortByNameWithGroupsFirst || fieldConfig.sortBothByName) ? "displayName" : "description",
            sortBy: fieldConfig.sortBothByName ? "displayName" : fieldConfig.sortByNameWithGroupsFirst ? "type" : fieldConfig.sortByDescription ? "description" : undefined
        };

        const applyFn: (items: OrgAddonEntry[]) => void = (items) => this.applyRightGroupsSelection(field, items);

        this.modalDialogService.showOrgMultiselectDialog(this.translateFn("modal.right.groups.addon.title"), config, entries, applyFn);
    }

    private applyRightGroupsSelection(field: OrgAddonField, items: OrgAddonEntry[]): void {
        // the "entry" key is for each entry that we need to add through the configuration
        // of the addon field (extra1 contains 'extra' user)
        const selection: string[] = items.map(entry => (entry.type == "user") ? `${entry.displayName}(U)` : `${entry.displayName}(G)`);
        field.api.setValue(selection.join(";"), true);
    }

    getUserAndGroups(fieldConfig: RightGroupsConfig): DmsOrganisationObject[] {
        let users: DmsUserOrgObject[] = (fieldConfig || {}).showUsers ? this.organisationService.getActiveUserList() as unknown as DmsUserOrgObject[] : [],
            groups: DmsGroupOrgObject[] = (fieldConfig || {}).showGroups ? this.organisationService.getGroupList() as unknown as DmsGroupOrgObject[] : [];

        if (fieldConfig === undefined) {
            return [].concat(users, groups);
        }

        // only show exclusive org objects
        users = this.resolveGroups(users, fieldConfig.exclusiveGroups); // only show users included in the exclusive groups
        users = this.applyExclusiveRules(users, fieldConfig.exclusiveUsers) as DmsUserOrgObject[];
        groups = this.applyExclusiveRules(groups, fieldConfig.exclusiveGroups) as DmsGroupOrgObject[];

        // remove excluded org objects
        users = this.applyExcludeRules(users, fieldConfig.excludeUsers) as DmsUserOrgObject[];
        groups = this.applyExcludeRules(groups, fieldConfig.excludeGroups) as DmsGroupOrgObject[];

        // filter
        users = this.applyFilter(users, fieldConfig.userFilter) as DmsUserOrgObject[];
        groups = this.applyFilter(groups, fieldConfig.groupsFilter) as DmsGroupOrgObject[];

        return [].concat(users, groups);
    }

    private resolveGroups(users: DmsUserOrgObject[], groupsToResolve: string[]): DmsUserOrgObject[] {
        if (!groupsToResolve || groupsToResolve.length == 0) {
            return users;
        }

        groupsToResolve = groupsToResolve.map(name => name.toLowerCase());

        return users.filter(user => {
            const userGroups: string[] = user.groups.map(name => name.toLowerCase());
            return userGroups.find(group => groupsToResolve.includes(group));
        });
    }

    private applyExcludeRules(list: Array<DmsUserOrgObject|DmsGroupOrgObject>, exclude: string[]): Array<DmsUserOrgObject|DmsGroupOrgObject> {
        if (!exclude || exclude.length == 0) {
            return list;
        }

        exclude = exclude.map(exclude => exclude.toLowerCase());
        return list.filter(entry => !exclude.includes(entry.name.toLowerCase()));
    }

    private applyExclusiveRules(list: Array<DmsUserOrgObject|DmsGroupOrgObject>, exclusive: string[]): Array<DmsUserOrgObject|DmsGroupOrgObject> {
        if (!exclusive || exclusive.length == 0) {
            return list;
        }

        exclusive = exclusive.map(exclusive => exclusive.toLowerCase());
        return list.filter(entry => exclusive.includes(entry.name.toLowerCase()));
    }

    private applyFilter(entries: DmsOrganisationObject[], filters: string[]): DmsOrganisationObject[] {
        if (filters === undefined || filters.length === 0) {
            return entries;
        }

        filters = filters.map(filter => filter.toLowerCase());
        return entries.filter(entry => filters.find(filter => entry.name.toLowerCase().indexOf(filter) === 0));
    }
}
