import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ViewerEvent} from "../enums/viewer-event.enum";

export abstract class PopupViewerWindow extends Window {
    static ViewerFns: string[] = ["refreshViewer", "updateViewer", "clearViewer", "init", "updateDmsDocumentCache", "setDashlets", "sendDashletEvent", "onThemeChanged"];
    static RootscopeViewerEvents: string[] = ["dv.notesChanged", "annotationsChanged"];
    static ViewerEvents: string[] = [ViewerEvent.EXEC_DASHLET_API];

    detachedViewer: boolean;
    details: {
        activeViewerDashlet: string;
        activeOsid: number;
        activeSelectedOsids: number[];
    };

    abstract addCustomEventListener(eventName: string, cbFn): void;

    /**
     * Definition for function in detached viewer
     */
    abstract updateViewer(id: number, searchKey?: string, dashletId?: string, selectedOsids?: number[]): Promise<void>;

    /**
     * Definition for function in detached viewer
     */
    abstract refreshViewer(osid: number, dashletIds: string[], keepCache?: boolean): void;

    abstract clearViewer(): void;

    abstract init(dashletId: string, osid: number, searchKey: string, selectedOsids?: number[]): void;

    /**
     * Definition for function in detached viewer
     */
    abstract updateDmsDocumentCache(id: string, dmsDocument: DmsDocument): void;

    abstract onThemeChanged(name: string): void;

    abstract setDashlets(dashlets: any[]): void;

    abstract sendDashletEvent(dashletId: string, eventKey: string, data: unknown): void;
}