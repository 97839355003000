import {Inject, Injectable} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ModalDialogInjectorService} from "MODULES_PATH/modal-dialog/services/modal-dialog-injector.service";
import {EobModalDateTimePickerComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-date-time-picker/eob-modal-date-time-picker.component";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {FieldAddon, FieldDataType} from "ENUMS_PATH/field.enum";

@Injectable({
    providedIn: "root"
})
export class DateTimePickerService {

    private readonly translateFn: TranslateFnType;

    constructor(@Inject("$filter") $filter: ng.IFilterService,
                private modalDialogInjectorService: ModalDialogInjectorService,
                private messageService: MessageService) {
        this.translateFn = $filter("translate");
    }

    showDateTimePicker(event: Event, field: any, formHelper: any): void {
        this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
        let title = "";

        let containerClass: string;
        let fieldType = field.model.type;

        if (fieldType != FieldDataType.DATE && fieldType != FieldDataType.DATETIME && fieldType != FieldDataType.TIME ) {
            fieldType = field.model.addon;
        }

        if (fieldType == FieldDataType.DATE || fieldType == FieldAddon.DATE) {
            title = this.translateFn("modal.datepicker.title.date");
            containerClass = "date-picker-dialog";
        } else if (fieldType == FieldDataType.DATETIME || fieldType == FieldAddon.DATETIME) {
            title = this.translateFn("modal.datepicker.title.datetime");
        } else {
            title = this.translateFn("modal.datepicker.title.time");
            containerClass = "time-picker-dialog";
        }

        this.modalDialogInjectorService.createDialog({ title, containerClass }, {
            component: EobModalDateTimePickerComponent, input: { formhelper: formHelper, field }
        });
    }
}
