import {Injectable} from "@angular/core";
import {DashletService} from "MODULES_PATH/dashlet/interfaces/dashlet-service.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import { MessageService } from "CORE_PATH/services/message/message.service";

@Injectable({
    providedIn: "root"
})
export class DetailsDashletService implements DashletService {
    constructor(private messageService: MessageService) {
    }

    update(osid: number, dmsDocument: DmsDocument): void {
        this.messageService.broadcast("update.viewer", {id: osid, dmsDocument});
    }

    refresh(osid: number, keepCache: boolean): void {
        this.messageService.broadcast("update.viewer", {id: osid});
    }

    clear(): void {
        this.messageService.broadcast("clear.viewer");
    }

    sendEvent(eventKey: string, data: unknown): void {
    }
}