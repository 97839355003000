import {Injectable, Injector} from "@angular/core";
import {
    ContextMenuAction,
    ContextMenuActionsProvider,
    ContextType
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {StartableWorkflowsContextMenuService} from "MODULES_PATH/context-menu/context-type/startable-workflows-context-menu.service";
import {ProcessesContextMenuService} from "MODULES_PATH/context-menu/context-type/processes-context-menu.service";
import {ExternalTrayContextMenuService} from "MODULES_PATH/context-menu/context-type/external-tray-context-menu.service";
import {WorkflowContextMenuService} from "MODULES_PATH/context-menu/context-type/workflow-context-menu.service";
import {DesktopContextMenuService} from "MODULES_PATH/context-menu/context-type/desktop-context-menu.service";
import {InboxContextMenuService} from "MODULES_PATH/context-menu/context-type/inbox-context-menu.service";
import {ObjectReferencesContextMenuService} from "MODULES_PATH/context-menu/context-type/object-references-context-menu.service";
import {DefaultHitlistContextMenuService} from "MODULES_PATH/context-menu/context-type/default-hitlist-context-menu.service";
import {VariantsContextMenuService} from "MODULES_PATH/context-menu/context-type/variants-context-menu.service";
import {FolderContextMenuService} from "MODULES_PATH/context-menu/context-type/folder-context-menu.service";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"})
export class ContextMenuService {
    private contextMenuProvidersMap: Map<string, ContextMenuActionsProvider> = new Map<string, ContextMenuActionsProvider>();

    constructor(private injector: Injector) {
    }

    registerContextMenuActionsProvider(context: string): void {
        let contextMenuActionsProvider: ContextMenuActionsProvider;

        switch (context) {
            case ContextType.ABO:
            case ContextType.REVISIT:
            case ContextType.SUBSCRIPTIONOBJECTS:
                contextMenuActionsProvider = this.injector.get<InboxContextMenuService>(InboxContextMenuService);
                break;
            case ContextType.DESKTOP:
                contextMenuActionsProvider = this.injector.get<DesktopContextMenuService>(DesktopContextMenuService);
                break;
            case ContextType.EXTERNALTRAY:
                contextMenuActionsProvider = this.injector.get<ExternalTrayContextMenuService>(ExternalTrayContextMenuService);
                break;
            case ContextType.FOLDER:
            case ContextType.FOLDERTREE:
            case ContextType.EMPTYSPACEINHITLIST:
                contextMenuActionsProvider = this.injector.get<FolderContextMenuService>(FolderContextMenuService);
                break;
            case ContextType.OBJECTREFERENCES:
                context = "objectReferences";
                contextMenuActionsProvider = this.injector.get<ObjectReferencesContextMenuService>(ObjectReferencesContextMenuService);
                break;
            case ContextType.PROCESSES:
                contextMenuActionsProvider = this.injector.get<ProcessesContextMenuService>(ProcessesContextMenuService);
                break;
            case ContextType.STARTABLE:
                contextMenuActionsProvider = this.injector.get<StartableWorkflowsContextMenuService>(StartableWorkflowsContextMenuService);
                break;
            case ContextType.VARIANTS:
                contextMenuActionsProvider = this.injector.get<VariantsContextMenuService>(VariantsContextMenuService);
                break;
            case ContextType.CIRCULATIONSLIP:
            case ContextType.WFFILEAREAINFOFILES:
            case ContextType.WFFILEAREAWORKFILES:
            case ContextType.WORKFLOW:
                contextMenuActionsProvider = this.injector.get<WorkflowContextMenuService>(WorkflowContextMenuService);
                break;
            default:
                contextMenuActionsProvider = this.injector.get<DefaultHitlistContextMenuService>(DefaultHitlistContextMenuService);
                break;
        }

        this.contextMenuProvidersMap.set(context, contextMenuActionsProvider);
    }

    unregisterContextMenuActionsProvider(context: string): void {
        this.contextMenuProvidersMap.delete(context);
    }

    getContextMenuActions(contextMenuItems: unknown[], contextData: ContextData): Observable<ContextMenuAction[]> {
        let contextMenuActionsProvider: ContextMenuActionsProvider = this.contextMenuProvidersMap.get(contextData.context);
        if(!contextMenuActionsProvider) {
            // So far, it doesn't look like any instances of scriptQuery would require a different menu
            // Since the context is mangled in the process of the hitlist component creation within getListEntries() (hitlist.result turns into scriptQuery), a duct tape solution is put in place here
            contextMenuActionsProvider = this.contextMenuProvidersMap.get("hitlist.result");
        }

        if (contextMenuActionsProvider) {
            if (contextMenuItems.length > 1) {
                return contextMenuActionsProvider.getMultiselectionContextMenuActions(contextMenuItems, contextData);
            } else {
                return contextMenuActionsProvider.getSingleSelectionContextMenuActions(contextMenuItems[0], contextData);
            }
        }
    }
}
