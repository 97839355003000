import {Directive, ElementRef, OnInit} from "@angular/core";
import {Input} from "@angular/core";
import agGrid from "ag-grid-enterprise/dist/ag-grid-enterprise.min.js";
import {GridOptions} from "ag-grid-community";

@Directive({
    selector: "[ag-grid]"
})
export class AgGridDirective implements OnInit {
    @Input("ag-grid") gridOptions: GridOptions;

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        new agGrid.Grid(this.el.nativeElement, this.gridOptions);
    }
}