export enum NavigationEvents {
    /**
     * Broadcast the signal that shows the user menu.
     */
    SHOW_USER_MENU = "SHOW_USER_MENU",

    /**
     * Brodcast that the user is navigating by keyboard so the user menu sets the focus
     */
    PREPARE_USER_MENU_WITH_KEY_NAV = "PREPARE_USER_MENU_WITH_KEY_NAV",

    /**
     * Set focus on the first element in the nav bar body
     * (no matter what category is currently displayed in nav bar body)
     */
    FOCUS_NAV_BAR_BODY = "FOCUS_NAV_BAR_BODY",

    /**
     * Set focus on the last focused nav bar element
     */
    FOCUS_NAV_BAR = "FOCUS_NAV_BAR",
    /**
     * Signal does it navigation bar pinned or not.
     */
    UNPIN_NAVIGATION = "UNPIN_NAVIGATION",

    /**
     * Signals closing of user menu but not of all modals
     */
    CLOSE_ONLY_USER_MENU = "CLOSE_ONLY_USER_MENU"
}