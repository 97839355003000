import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

require("SERVICES_PATH/viewer/detailsviewer/dv.tool.srv.js");
require("SERVICES_PATH/viewer/detailsviewer/dv.notes.srv.js");
require("SERVICES_PATH/viewer/detailsviewer/dv.workflow.srv.js");
require("SERVICES_PATH/viewer/detailsviewer/dv.revisits.srv.js");
require("SERVICES_PATH/viewer/detailsviewer/dv.document.srv.js");
require("SERVICES_PATH/viewer/detailsviewer/dv.environment.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("COMPONENTS_PATH/eob-detailsviewer/components/dv-content/dv.content.dir.js");
require("COMPONENTS_PATH/eob-detailsviewer/components/dv-nav/dv.nav.dir.js");

angular.module("eob.framework").directive("eobDetailsViewer", EobDetailsViewer);

EobDetailsViewer.$inject = ["$rootScope", "dvToolService", "dvNotesService", "dvWorkflowService", "dvRevisitsService",
    "dvDocumentService", "dvEnvironmentService", "backendService", "$timeout", "clientService", "ngZone", "messageService"];

function EobDetailsViewer($rootScope, DvToolService, DvNotesService, DvWorkflowService, DvRevisitsService,
                          DvDocumentService, DvEnvironmentService, BackendService, $timeout, ClientService, ngZone, MessageService) {
    return {
        restrict: "E",
        link(scope, element) {
            let views = ["indexdata", "workflow", "history", "notes", "revisits"];
            let lastTimestamp = 0;
            let sensor = null;
            let cleanUpSubscription;
            const unsubscriber = new Subject();
            const resizeObserver = new MutationObserver(mutationsList => {
                if(mutationsList.find(x => x.type == "attributes" && x.attributeName == "style")) {
                    let width = element[0].getBoundingClientRect().width;

                    if (width > 525) {
                        element.addClass("large");
                        element.removeClass("min");
                    } else {
                        element.addClass("min");
                        element.removeClass("large");
                    }
                }
            });

            scope.objectId = $rootScope.osid;
            scope.isOnline = ClientService.isOnline();
            scope.isTypeless = false;
            scope.name = undefined;
            scope.ctrl = undefined;
            scope.noteSize = undefined;
            scope.workflow = undefined;
            scope.revisitsSize = undefined;

            scope.changeView = changeView;

            bindViewerEvents();
            bindWatcher();
            initAsync();

            /**
             * Initialize the detailsviewer environment and directive.
             *
             * @returns {Promise<void>} Resolved once the detailsviewer is initialized.
             */
            async function initAsync() {
                ClientService.registerConnectivityChangeHandler(toggleOfflineMode);
                let initContent = $rootScope.view;

                if (scope.objectId != void 0) {
                    initDMSObjectAsync(scope.objectId);
                    changeView(initContent);
                }

                DvWorkflowService.workflow.subscribe(data => scope.workflow = data, takeUntil(unsubscriber));
            }

            function changeView(view) {
                let newView = "indexdata";

                let excludedViewIfTypeless = view != "notes" || view != "revisits" || !scope.isTypeless;

                if (views.indexOf(view) != -1 && excludedViewIfTypeless) {
                    newView = view;
                } else if (scope.ctrl != void 0) {
                    return;
                }

                scope.ctrl = newView;
            }

            /**
             * Update the detailsviewer completely.
             * Either show the default page, if no data/osid is given,
             * or load and show the data for the given osid/dmsDocument.
             *
             * @param {{ id, dmsDocument }} data - Change the shown dms object.
             */
            function update(data) {
                DvWorkflowService.workflow.next(false);
                if (data == void 0 || data.id == void 0) {
                    clearViewer();
                    return;
                }

                scope.objectId = data.id;
                initDMSObjectAsync(data.id, data.dmsDocument);
                changeView();
                scope.$broadcast("changeObject");
            }

            /**
             * Load all neccessary data to display the detailsviewer navigation and heading.
             *
             * @param {number} osid - An osid for the dms object that shall be shown.
             * @param {DmsDocument=} dmsDocument - A dmsDocument that shall be shown.
             * @returns {Promise} Resolved once the detailsviewer is initialized.
             */
            async function initDMSObjectAsync(osid, dmsDocument) {
                let timestamp = new Date().getTime();

                try {
                    let dvDmsObject = await DvDocumentService.loadAsync(osid, true, dmsDocument);

                    if (dvDmsObject == void 0) {
                        clearViewer(timestamp);
                        return;
                    }

                    loadTabNumbersAsync(osid);

                    scope.name = dvDmsObject.name;
                    scope.isTypeless = dvDmsObject.isTypeless;

                    // hide the default page again, if it was visible
                    updateDefaultPage(osid, timestamp);
                } catch (error) {
                    clearViewer(timestamp);
                }
            }

            /**
             * Load and set the tab numbers in the navigation.
             *
             * @param {number} osid - The osid of a dms object.
             */
            async function loadTabNumbersAsync(osid) {
                if (!ClientService.isOnline()) {
                    return;
                }

                scope.noteSize = (await DvNotesService.load(osid)).notesData.counter;
                scope.revisitsSize = (await DvRevisitsService.load(osid)).length;

                await DvWorkflowService.load(osid);
            }

            /**
             * Show or hide the default page.
             *
             * @param {number=} osid - An osid or null.
             * @param {number=} ts - A timestamp.
             */
            function updateDefaultPage(osid, ts) {
                let data = { isDmsObject: !!osid };
                ts = ts || new Date().getTime();
                if (ts > lastTimestamp) {
                    lastTimestamp = ts;
                    $rootScope.$broadcast("dv.no.dms.object", data);
                }
            }

            function bindWatcher() {
                // this watch gets triggered when the ng-show changes
                // first i thought this would be a bug because i watched an anonymous function
                // but the angular guys solved this as WAD (works as designed), lucky me :D
                // https://github.com/angular/angular.js/issues/12823#issuecomment-139499677
                /**
                 * When the element gets destroyed via ng-if, it's scope and therefore the watcher on it is also destroyed.
                 * When the element is rendered again, the watcher is initialized again - which means it's called with the same values,
                 * because it triggers the exception.
                 */
                scope.$watch(() => {
                    return window.getComputedStyle(element[0]).display !== "none";
                }, checkElementState);
            }

            function checkElementState(isVisible) {
                if (isVisible) {
                    $rootScope.$broadcast("dv.refresh.indexdata");
                    bindResizeSensor();
                } else if (sensor != void 0) {
                    sensor.detach();
                    sensor = null;
                }
            }

            function bindResizeSensor() {
                // save the width of the pane when the user resizes the nav pane
                // only save in case we stop resizing by mouseup and the navigation is fixed
                // otherwise we would save the width when the user resizes the window or toggles the unpinned navigation
                resizeObserver.observe(element[0], {subtree: true, attributes: true})
            }

            function bindViewerEvents() {
                cleanUpSubscription = MessageService.subscribe("update.viewer", (data) => {
                    $timeout(() => update(data), 0);
                });

                scope.$on("dv.notes.updated", () => {
                    $timeout(() => {
                        if ($rootScope.noteSize != void 0) {
                            scope.noteSize = $rootScope.noteSize;
                        }
                    }, 10);
                });

                MessageService.subscribe("clear.viewer", clear);
            }

            function toggleOfflineMode() {
                scope.isOnline = ClientService.isOnline();

                if (!scope.isOnline) {
                    changeView("indexdata");
                }
            }

            function clearViewer(ts) {
                ts = ts || new Date().getTime();
                if (ts > lastTimestamp) {
                    clear.viewer;
                    MessageService.broadcast("clear.viewer");
                }
            }

            function clear() {
                scope.objectId = undefined;
                scope.name = "";
                scope.ctrl = undefined;
                scope.noteSize = undefined;
                DvWorkflowService.workflow.next(false);
                scope.revisitsSize = undefined;

                updateDefaultPage(undefined, new Date().getTime());
            }

            scope.$on("destroy.viewer", () => {
                $timeout(() => {
                    scope.$destroy();
                    element.remove();
                }, 0);
            });

            scope.$on("$destroy", () => {
                unsubscriber.next(true);
                cleanUpSubscription.unsubscribe();
                resizeObserver.disconnect();
                ClientService.unregisterConnectivityChangeHandler(toggleOfflineMode);
            });
        },
        template: require("!raw-loader!./eob.details.viewer.html")
    };
}
