import {Inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {map} from "rxjs/operators";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";

@Injectable({providedIn: "root"})
export class OfficeBackendService {
    private officeServiceBase = "/office365";
    baseUrl: string;
    accessToken: string;
    currentObjectId: string|number;
    updating = false;
    osId: string;
    id: string;
    language: string;
    dmsDocumet: DmsDocument;
    translateFn: TranslateFnType;
    urlSrc: string;

    constructor(private http: HttpClient, @Inject("$injector") private $injector: angular.auto.IInjectorService,
                public notificationsService: NotificationsService, public profileService: ProfileService,
                @Inject("$filter") private $filter: ng.IFilterService) {
        this.translateFn = this.$filter("translate") ;
    }

    updateFile(accessToken: string): any {
        this.updating = true;
        this.baseUrl = this.profileService.getCurrentBaseUrl();
        return this.http.post<any>(`${this.baseUrl + this.officeServiceBase}/file/update`, {accessToken, conversion: false})
            .pipe(map(response => {
                this.updating = false;
                if (response.status === "ERROR") {
                    console.log(`updating file failed, id: ${this.id}`);
                    return response;
                }
                if (response.status === "UPDATED") {
                    console.log(`file successfully updated, id: ${this.id}`);
                }
                return response;
            }));
    }

    updateContentAndDetailsViewer(): void {
        const viewserService: ViewerService = this.$injector.get("viewerService");
        void viewserService.refreshContent(parseInt(this.osId));
    }

    generateLanguage(): string {
        let locale: string;
        if (this.language === "de") {
            locale = "de-DE";
        } else if (this.language === "fr") {
            locale = "fr-FR";
        } else {
            locale = "en-US";
        }
        return locale;
    }

    getEditNotification(accessToken: string): any {
        return this.http.post<any>(`${this.officeServiceBase}/session`, {accessToken})
            .pipe(map(response => response));
    }
}
