import {OverallSyncStatus} from "ENUMS_PATH/offline/offline-global-sync-status.enum";
import {OfflineSynchronizationMessage} from "ENUMS_PATH/offline/offline-synchronization-message.enum";
import {OfflineDataStore} from "MODELS_PATH/eob.offline.data.model";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {OfflineObjectSyncStatus} from "SHARED_PATH/models/offline-sync-status.model";
import {ObjectSyncStatus} from "ENUMS_PATH/offline/offline-object-sync-status.enum";
import Dexie from "dexie";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {MessageService} from "CORE_PATH/services/message/message.service";

export class OfflineSyncStatus {
    // Phases: Backend, OfflineData, Indexdata, Permissions, prepare Content, Content, ObjectType Scripts, Global Scripts
    static readonly PHASES: number[] = [0, 0.05, 0.1, 0.25, 0.3, 0.35, 0.9, 0.95, 1];
    static readonly maxProgressStep: number = 0.05;

    private _status: OverallSyncStatus = OverallSyncStatus.NONE;
    abortStatus: OverallSyncStatus;
    phase: number = 0;
    percentage: number = 0;
    displayPercentage: number = 0;

    intervalling: boolean = false;

    singlePercentages: Map<string, any> = new Map();

    constructor(private messageService: MessageService, private offlineCacheService: OfflineCacheService,
                private errorModelService: ErrorModelService, private fileCacheService: FileCacheService) {
    }

    /** Provides the status whether the sync is running in this tab. */
    isSyncRunning = () => this.status === OverallSyncStatus.RUNNING;

    start(): void {
        this.status = OverallSyncStatus.RUNNING;
        this.abortStatus = undefined;
        this.phase = 0;
        this.singlePercentages.clear();
        this.setProgressState(0);
    }

    reset(): void {
        this.status = OverallSyncStatus.NONE;
    }

    getProgressStepPart(partSize: number, divider: number): number {
        return this.getProgressStep(Math.max(divider / partSize, 1));
    }

    getProgressStep(divider: number): number {
        divider = divider == 0 ? 1 : divider;
        return (OfflineSyncStatus.PHASES[this.phase + 1] - this.percentage) / divider;
    }

    addProgress(progressStep: number): void {
        if (!isNaN(progressStep)) {
            this.setProgressState(this.percentage + progressStep);
        }
    }

    nextPhase(): void {
        this.setProgressState(OfflineSyncStatus.PHASES[++this.phase]);
    }

    finish(finishStatus: OverallSyncStatus): void {
        this.setProgressState(1, finishStatus);
    }

    async setProgressByCache(): Promise<void> {
        const offlineDataCache: Dexie.Table<OfflineDataStore, string> = this.fileCacheService.getOfflineDataTable();

        const allCount: number = await offlineDataCache.count(),
            doneCount: number = await offlineDataCache.where("sync").equals(ObjectSyncStatus.DONE).count();

        this.addProgress(this.getProgressStep(allCount / doneCount));
    }

    /** Set the current global sync state and inform registered listeners. */
    setProgressState(newPercentage?: number, overallSyncStatus?: OverallSyncStatus): void {
        this.checkForAbortSynchronization(overallSyncStatus);

        if (overallSyncStatus !== undefined) {
            this.status = overallSyncStatus;
            console.info(`synchronization changed to ${OverallSyncStatus[overallSyncStatus]} at ${(newPercentage || this.percentage)*100}%`);
        }

        this.setPercentage(newPercentage, overallSyncStatus != undefined);
    }

    /**
     * Set the new percentage and broadcast the change.
     *
     * @param newPercentage - The new percentage.
     * @param force - Force a broadcast, even though the percentage maybe didn't change.
     */
    private setPercentage(newPercentage: number = this.percentage, force: boolean = false): void {
        // math.min is just a safety measure in case the sum of the divided progress steps is larger then its initial sum due to "natural" unit roundoffs
        this.percentage = newPercentage >= 1 ? 1 : Math.min(newPercentage, OfflineSyncStatus.PHASES[this.phase + 1]);

        if (!this.intervalling && newPercentage < 1 && newPercentage - this.displayPercentage > OfflineSyncStatus.maxProgressStep) {
            const update = () => {
                this.setDisplayPercentage(force);

                if (this.percentage < 1 && this.displayPercentage < this.percentage) {
                    this.intervalling = true;
                    setTimeout(update, 1000);
                } else {
                    this.intervalling = false;
                }
            };
            update();
        } else {
            this.setDisplayPercentage(force);
        }
    }

    private setDisplayPercentage(force: boolean = false): void {
        if (!force && this.displayPercentage !== 0 && this.displayPercentage == this.percentage) {
            return;
        }

        this.displayPercentage = this.percentage >= 1 ? 1 : Math.min(this.displayPercentage + OfflineSyncStatus.maxProgressStep, this.percentage);

        this.messageService.broadcast(OfflineSynchronizationMessage.GLOBAL_SYNC_STATUS_CHANGED, { percentage: this.displayPercentage, status: this.status, abortStatus: this.abortStatus });
    }

    /**
     * Filter the given offline objects for affacted main offline objects and calculate their cache progresses.
     * Inform the registered listeners and update the overall cache progress.
     */
    async updateCacheProgresses(offlineData: OfflineDataStore[]): Promise<void> {
        const filteredIds: Set<string> = new Set<string>();

        offlineData.forEach(data => {
            data.favReferences.forEach(filteredIds.add, filteredIds);
        });

        return this.updateCacheProgressesByIds(filteredIds);
    }

    async addCacheProgress(offlineData: OfflineDataStore, addFailedCount: boolean): Promise<void> {
        return this.updateCacheProgressesByIds(offlineData.favReferences, addFailedCount);
    }

    /**
     * Calculate all cache progresses for the offline objects with the given osid.
     * Inform the registered listeners and update the overall cache progress.
     *
     * @param cacheIds - Update the cache progress for these ids.
     * @param addFailedCount - Increase the done/failed count for all given osids. Updates the progress without accessing the db if possible.
     */
    private async updateCacheProgressesByIds(cacheIds: (Set<string>|string[]), addFailedCount?: boolean): Promise<void> {
        for (const osid of cacheIds) {
            let calcedValues: OfflineObjectSyncStatus;

            if (addFailedCount != undefined && this.singlePercentages.has(osid)) {
                calcedValues = this.singlePercentages.get(osid);

                if (addFailedCount) {
                    calcedValues.failedCount++;
                } else {
                    calcedValues.doneCount++;
                }
            } else {
                calcedValues = await this.offlineCacheService.getCacheProgress(osid);
                calcedValues.osid = osid;

                if (calcedValues.childCount !== undefined) {
                    this.singlePercentages.set(osid, calcedValues);
                }
            }

            this.messageService.broadcast(OfflineSynchronizationMessage.OBJECT_SYNC_STATUS_CHANGED, calcedValues);
        }
    }

    /**
     * Check whether the abortion of the synchronization was triggered
     * and throw an error to cancel the sync function from within.
     */
    private checkForAbortSynchronization(overallSyncStatus?: OverallSyncStatus): void {
        if (overallSyncStatus != OverallSyncStatus.ABORTED && [OverallSyncStatus.ABORTING_USER, OverallSyncStatus.ABORTING_OFFLINE, OverallSyncStatus.ABORTING_QUOTA].includes(this.status)) {
            throw this.errorModelService.createCustomError("WEB_SYNCHRONIZATION_ABORTED");
        }
    }

    set status(syncStatus: OverallSyncStatus) {
        if ([OverallSyncStatus.ABORTING_USER, OverallSyncStatus.ABORTING_QUOTA, OverallSyncStatus.ABORTING_OFFLINE].includes(syncStatus)) {
            this.abortStatus = syncStatus;
        }
        this._status = syncStatus;
    }
    get status(): OverallSyncStatus {
        return this._status;
    }
}
