(function() {
    require("angular-dragula");

    angular.module("eob.framework").directive("eobWfCirculationGrid", EobWfCirculationGrid);

    EobWfCirculationGrid.$inject = ["$rootScope", "$q", "$compile", "formFieldModelService", "toolService", "$timeout", "dragulaService",
        "kebabMenuService", "clientService", "messageService", "formHelper", "formService"];

    function EobWfCirculationGrid($rootScope, $q, $compile, FormFieldModelService, ToolService, $timeout, DragulaService,
                                  KebabMenuService, ClientService, MessageService, FormHelper, FormService) {
        return {
            restrict: "E",
            scope: {
                config: "="
            },
            link(scope, element) {
                let config = scope.config;
                let columns = config.columns;
                let groups = config.groups;
                let fields;

                let selectedRow, selectedContainer, isOverContainerCalled = false,
                    selectedGroup, itemDrake, dragging;

                let scrollContainer = angular.element(document.body).find(".search-left-pane");

                let gridField = {
                    model: {
                        groups,
                        "expandable": config.expandable
                    }, api: {
                        getGroup,
                        addGroup,
                        removeGroup,
                        removeGroupById,
                        addRow,
                        addRowById,
                        removeRow,
                        setGridExpandable,
                        setGroupExpandable
                    }
                };
                let bindValidationBubbleSubsribtion;

                scope.model = gridField.model;
                scope.api = gridField.api;
                scope.toggleState = true;

                scope.isPhone = ClientService.isPhone();
                scope.changeView = changeView;
                scope.showActivities = false;
                scope.showEmptyMessage = groups.length == 0;

                scope.menuItemsCallbackAsync = async () => {
                    return new Promise(resolve => {
                        resolve(KebabMenuService.getRoutingListMenuItems(groups));
                    })
                };

                init();

                function init() {
                    initData();
                    generateHtml();
                }

                // region prepare data

                function initData() {
                    let promises = [];

                    scope.gridFormHelper = FormHelper.getFormHelper({ formData: {} });
                    fields = scope.gridFormHelper.getFields();

                    for (let i = 0; i < groups.length; i++) {
                        let groupPromise = prepareGroup(groups[i]);
                        promises.push(groupPromise);
                    }

                    $q.all(promises).then(() => {
                        setGridExpandable(gridField.model.expandable, true, true);
                        scope.$emit("circulation.grid.ready", gridField);
                    });
                }

                function prepareGroup(group) {
                    let promises = [];

                    for (let rowId in group.rows) {
                        let rowPromise = prepareRow(group, group.rows[rowId]);
                        promises.push(rowPromise);
                    }

                    return $q.all(promises);
                }

                function prepareRow(group, row) {
                    row.id = row.id || ToolService.createGUID();
                    row.group = group;

                    return prepareFields(row);
                }

                function prepareFields(row) {
                    let rowDeferred = $q.defer(),
                        initFns = [];

                    for (let columnIndex in row.cells) {
                        let column = columns[columnIndex];

                        if (column != void 0) {
                            let field = row.cells[columnIndex];

                            if (field.model == void 0) {
                                row.cells[columnIndex] = createField(column, row, ToolService.createGUID());
                            } else if (field.model.addon == "organisation") {
                                field.model.config.initializeValue = false;
                            }

                            initFns = initFns.concat(bindFieldEvents(column, field));
                        }
                    }

                    $timeout(() => {
                        initFields(initFns);
                        rowDeferred.resolve();
                    }, 0)

                    return rowDeferred.promise;
                }

                function createField(column, row, internalName) {
                    let cell = row.cells[column.config.index];
                    let type = column.config.type || "text";
                    let addon = column.modelProps != void 0 && column.modelProps.addon ? column.modelProps.addon : null;

                    const field = FormFieldModelService.getMockedField(type, internalName, undefined, addon);
                    cell.model = field;

                    fields[field.internal] = cell;
                    cell.row = row;

                    let fieldProps = column.fieldProps;
                    setFieldProperties(cell, fieldProps);

                    let modelProps = column.modelProps;
                    if (modelProps != void 0) {
                        for (let i in modelProps) {
                            field[i] = angular.copy(modelProps[i]);
                        }
                    }

                    return cell;
                }

                function setFieldProperties(field, fieldProps) {
                    if (fieldProps != void 0) {
                        Object.assign(field, fieldProps);
                    }
                }

                function bindFieldEvents(column, cell) {
                    let initFns = [];

                    if (column.config.onInit != void 0) {
                        initFns.push({
                            cell,
                            fns: [column.config.onInit]
                        });
                    }

                    if (column.config.onChange != void 0) {
                        initFns = initFns.concat(bindOnChangeFn(cell, column.config.onChange));
                    }

                    return initFns;
                }

                function bindOnChangeFn(cell, onChange) {
                    let initFns = [];

                    initFns.push({
                        cell,
                        fns: [function(cell, field) {
                            if (field.model.type === "checkbox") {
                                field.model.control.registerOnChange(() => {
                                    if (!field.isDisabled) {
                                        onChange(cell, field);
                                    }
                                });
                            } else {
                                if (field.api == void 0) {
                                    return;
                                }
                                let input = field.api.getElement().find("input,textarea");
                                input.bind("change", () => {
                                    onChange(cell, field);
                                })
                            }
                        }]
                    });

                    return initFns;
                }

                function initFields(initFns) {
                    for (let i = initFns.length - 1; i >= 0; i--) {
                        let fieldInitFns = initFns[i];
                        let cell = fieldInitFns.cell;
                        let fns = fieldInitFns.fns;

                        for (let j = 0; j < fns.length; j++) {
                            let initFn = fns[j];
                            initFn(cell, scope.gridFormHelper.getFieldByInternal(cell.model.internal));
                        }

                        initFns.splice(i, 1);
                    }
                }

                // endregion
                // region build html

                function generateHtml() {
                    let headerContainer = angular.element(element[0].querySelector("#header-row"));
                    buildHeader(headerContainer);
                    $compile(headerContainer)(scope);

                    let groupContainer = element.find(".groups");
                    let groupTemplate = scope.isPhone ? buildTemplate() : buildGroup();

                    let groupElements = angular.element(groupTemplate);
                    let cellContainer = angular.element(groupElements[0].querySelector(".form-row"));

                    buildCells(cellContainer);
                    groupContainer.append(groupElements);

                    $timeout(() => {
                        bindDragNDropEvents();
                    }, 0);

                    $compile(groupContainer)(scope);
                }

                function buildHeader(container) {
                    for (let i in columns) {
                        buildHeaderCell(container, columns[i].config)
                    }

                    let deleteHeaderCellConfig = {
                        classes: ["delete-cell"]
                    };

                    buildHeaderCell(container, deleteHeaderCellConfig);
                }

                function buildHeaderCell(container, config) {
                    let headerCell = angular.element("<div><div>");

                    if (config.icon != void 0) {
                        let imgTemplate = `<i class='${config.icon}'></i>`;
                        let img = angular.element(imgTemplate);

                        headerCell.attr("title", config.title);
                        headerCell.append(img);
                    } else {
                        headerCell.text(config.title);
                    }

                    if (config.width != void 0) {
                        headerCell.css({
                            width: `${config.width}px`,
                            flex: "none"
                        });
                    }

                    if (config.classes != void 0) {
                        for (let i = 0; i < config.classes.length; i++) {
                            headerCell.addClass(config.classes[i])
                        }
                    }

                    container.append(headerCell);
                    return headerCell;
                }

                function buildGroup() {
                    // unfortunately we need to build this part of the DOM tree here,
                    // instead of simply adding it to the html,
                    // as angular won't allow us to append further elements into a ng-repeat loop
                    let buttonsTemplate = "<div class='groupButtons'>" +
                        "<button class='add-row-button button-transparent' ng-click='api.addRow(group)' title='{{\"eob.workflow.circulation.tooltip.add.activity\" | translate}}'>" +
                        "<i class='icon-16-add' title='add'></i>" +
                        "</button>";

                    let groupTemplate =
                        `${"<div class='custom-grid-group' ng-repeat='group in model.groups' id='{{::group.id}}' expandable='{{group.expandable}}'>" +
                        "<div class='group-header'>" +
                        "<div class='drag-handle-group'></div><h2 ng-bind='group.title' title='{{group.title}}'></h2>"}${
                            buttonsTemplate
                            }</div></div>` +
                        "<div class='activityList'>" +
                        "<div class='form-row custom-grid-row' ng-repeat='(id, row) in group.rows' id='{{::row.id}}'></div></div>";

                    return groupTemplate;
                }

                function buildCells(container) {
                    if (!scope.isPhone) {
                        addDragHandle(container);
                    }
                    for (let i in columns) {
                        buildCell(container, columns[i].config)
                    }
                    if (!scope.isPhone) {
                        addDeleteCell(container);
                    }
                }

                function addDeleteCell(container) {
                    let cell = angular.element("<div class='form-row-input-container form-row delete-cell'>" +
                        "<button class='delete-row-button button-transparent' ng-click='row.disabled || api.removeRow(row)' title='{{\"eob.workflow.circulation.tooltip.delete.activity\" | translate}}'>" +
                        "<i class='icon-16-delete-dark'></i>" +
                        "</button></div>");

                    container.append(cell);
                }

                function addDragHandle(container) {
                    let cell = angular.element("<div class='drag-handle-cell'></div>");
                    container.append(cell);
                }

                function buildCell(container, config) {
                    let cellContainer = angular.element("<div class='form-row-input-container form-row form-element'></div>");
                    let fieldKey = `row.cells.${config.index}`;
                    let cell, title;

                    if (config.index == "activityType") {
                        cell = angular.element("<div class='form-row activity-type-cell'>" +
                            `<i ng-class='${fieldKey}.value'  title='{{${fieldKey}.title}}'></i>` +
                            "</div>");
                    } else {
                        cell = angular.element(`<eob-form-element [layoutfield]='${fieldKey}.model' [formhelper]="gridFormHelper" [ismockform]="true"></eob-form-element>`);
                    }

                    if (scope.isPhone) {
                        if (config.index == "activityType") {
                            return
                        } else if (config.index == "editable" || config.index == "deletable") {
                            cell.addClass("has-checkbox");
                            cellContainer.attr("style", "flex-direction: row-reverse; justify-content: flex-end; align-items: center");
                        } else if (config.onInit || config.onChange) {
                            cellContainer[0].title = config.title
                        }
                        title = angular.element(`<span title='${config.title}'>${config.title}</span>`)
                        cellContainer.append(title);
                    }

                    cellContainer.append(cell);

                    if (config.width != void 0) {
                        cellContainer.css({
                            width: `${config.width}px`,
                            flex: "none"
                        });
                    }

                    container.append(cellContainer);
                }

                // endregion
                // region drag 'n drop
                function bindDragNDropEvents() {
                    bindItemDnD();
                    bindGroupDnD();
                }

                function bindItemDnD() {
                    let activityContainers = element.find(".activityList");
                    let containers = [];

                    for (let i = 0; i < activityContainers.length; i++) {
                        containers.push(activityContainers[i]);
                    }

                    DragulaService.options(scope, "item-bag", {
                        revertOnSpill: true,
                        mirrorContainer: element[0],
                        ignoreInputTextSelection: true,
                        containers,
                        moves(el, source, handle) {
                            handle = angular.element(handle);

                            if (angular.element(handle).closest(".drag-handle-cell").length == 0) {
                                return false;
                            }

                            let groupElement = handle.closest(".custom-grid-group");
                            return gridField.model.expandable && groupElement.attr("expandable") === "true";
                        },
                        accepts(el, target) {
                            let targetGroupElement = target.closest(".custom-grid-group"),
                                targetGroupId = targetGroupElement.getAttribute("id"),
                                groupId = selectedRow.group.id;

                            return gridField.model.expandable && targetGroupElement.getAttribute("expandable") === "true" && targetGroupId != groupId;
                        }
                    });

                    itemDrake = DragulaService.find(scope, "item-bag").drake;

                    scope.$on("item-bag.drag", (el, source) => {
                        dragging = true;
                        let rowId = source.attr("id"),
                            groupId = source.closest(".custom-grid-group").attr("id");

                        let sourceGroup = groups.find(group => group.id == groupId);
                        if (sourceGroup) {
                            selectedRow = sourceGroup.rows[rowId];
                        }

                        $rootScope.$broadcast("close.inline.dialogs");
                    });

                    scope.$on("item-bag.drop", (el, target) => {
                        dragging = false;

                        let groupId = selectedContainer.closest(".custom-grid-group").attr("id");
                        let hasNewParent = groupId != selectedRow.group.id;

                        if (hasNewParent) {
                            let targetGroup = groups.find(group => group.id == groupId);

                            selectedContainer[0].removeChild(target[0]);

                            if (targetGroup != void 0) {
                                moveRow(selectedRow, targetGroup);
                                selectedRow = null;
                            }
                        }
                    });

                    scope.$on("item-bag.over", () => {
                        isOverContainerCalled = true;
                    });

                    scope.$on("item-bag.shadow", (el, container) => {
                        if (isOverContainerCalled) {
                            selectedContainer = container.closest(".activityList");
                            isOverContainerCalled = false;
                            selectedContainer.addClass("drag-over");
                        }
                    });

                    scope.$on("item-bag.out", () => {
                        if (selectedContainer != void 0) {
                            selectedContainer.removeClass("drag-over");
                        }
                    });
                }

                function bindGroupDnD() {
                    let groupContainers = element.find(".groups");
                    let containers = [];

                    for (let i = 0; i < groupContainers.length; i++) {
                        containers.push(groupContainers[i]);
                    }

                    DragulaService.options(scope, "group-bag", {
                        revertOnSpill: true,
                        mirrorContainer: element[0],
                        containers,
                        moves(el, source, handle) {
                            if (angular.element(handle).closest(".drag-handle-group").length == 0) {
                                return false;
                            }

                            return gridField.model.expandable;
                        }
                    });

                    scope.$on("group-bag.drag", (el, source) => {
                        dragging = true;
                        let groupId = source.closest(".custom-grid-group").attr("id");
                        selectedGroup = groups.find(group => group.id == groupId);

                        $rootScope.$broadcast("close.inline.dialogs");
                    });

                    scope.$on("group-bag.over", () => {
                        dragging = false;
                    });

                    scope.$on("group-bag.out", () => {
                        dragging = false;
                    });

                    scope.$on("group-bag.drop", () => {
                        dragging = false;
                        let prevChild = element.find(".gu-transit")[0].previousElementSibling,
                            index = 0;

                        if (prevChild != null) {
                            let prevGroupId = prevChild.attributes.id.value;

                            for (let i = 0; i < scope.model.groups.length; i++) {
                                let group = scope.model.groups[i];

                                if (group.id == prevGroupId) {
                                    index = i + 1;
                                    break;
                                }
                            }
                        }

                        moveGroup(selectedGroup, index);
                        selectedGroup = null;
                    });
                }

                // prevent scrolling while dragging on touch devices
                scrollContainer.bind("touchmove", (event) => {
                    if (dragging) {
                        event.preventDefault();
                    }
                });
                // endregion
                // region api functions

                function getGroup(id) {
                    for (let i = 0; i < groups.length; i++) {
                        let group = groups[i];

                        if (group.id == id) {
                            return group;
                        }
                    }

                    return null;
                }

                function addGroup(data, index) {
                    scope.showEmptyMessage = false;
                    let defaultGroup = config.createGroup(data);
                    prepareGroup(defaultGroup);

                    if (index == void 0) {
                        index = groups.length;
                    }

                    groups.splice(index, 0, defaultGroup);

                    $timeout(() => {
                        let convertedID = "";
                        for (let i = 0; i < defaultGroup.id.length; i++) {
                            let character = defaultGroup.id[i];

                            if (!isNaN(character)) {
                                convertedID += `\\3${character} `;
                            } else {
                                convertedID += character;
                            }
                        }

                        let actListEl = angular.element(element[0].querySelector(`#${convertedID}`)).find(".activityList");
                        itemDrake.containers.push(actListEl[0]);

                        if (scope.isPhone && data === void 0) {
                            for (let rowId in defaultGroup.rows) {
                                defaultGroup.expanded = true;
                                changeView(defaultGroup.rows[rowId], true);
                                break;
                            }
                        } else {
                            setTimeout(() => {
                                itemDrake.containers[itemDrake.containers.length - 1].scrollIntoView({ behavior: "smooth", inline: "nearest" });
                            }, 0);
                        }
                    }, 0);
                }

                function removeGroup(group) {
                    scope.showEmptyMessage = groups.length == 0;
                    let index = groups.indexOf(group);

                    if (index >= 0) {
                        groups.splice(groups.indexOf(group), 1);
                    }
                }

                function removeGroupById(id) {
                    let group = getGroup(id);

                    if (group != void 0) {
                        removeGroup(group);
                    }
                }

                function moveGroup(group, index) {
                    removeGroup(group);

                    groups.splice(index, 0, group);

                    $timeout(() => {
                        for (let rowId in group.rows) {
                            var row = group.rows[rowId];
                            prepareFields(row).then(() => {
                                disableRemoveRow(group, row);
                            });
                        }
                    }, 0);

                    if (config.onMoveGroup != void 0) {
                        config.onMoveGroup(group, index);
                    }
                }

                function addRowById(groupId, data) {
                    let group = getGroup(groupId);
                    addRow(group, data);
                }

                function addRow(group, data) {
                    let row = config.createRow(group, data);
                    prepareRow(group, row);

                    group.rows[row.id] = row;
                    if (scope.isPhone) {
                        group.expanded = true;
                    }
                }

                function removeRow(row, deleteGroupIfEmpty, getsMoved) {
                    delete row.group.rows[row.id];

                    if (config.onRemoveRow != void 0) {
                        config.onRemoveRow(row);
                    }

                    if (!getsMoved) {
                        Object.values(row.cells).forEach(cell => {
                            if (cell.field) {
                                delete fields[cell.field.internal]
                            }
                        });
                    }

                    if (deleteGroupIfEmpty != false && Object.keys(row.group.rows).length == 0) {
                        removeGroup(row.group);
                    }
                }

                function moveRow(row, newGroup) {
                    let oldGroup = row.group;
                    let hasNewParent = oldGroup.id != newGroup.id;
                    let deferred = $q.defer();

                    if (hasNewParent) {
                        $timeout(() => {
                            prepareFields(row).then(() => {
                                disableRemoveRow(newGroup, row);
                                deferred.resolve();
                            }, () => {
                                deferred.resolve();
                            });
                        }, 0);
                    } else {
                        deferred.resolve();
                    }

                    if (config.onMoveRow != void 0) {
                        config.onMoveRow(row, oldGroup, newGroup);
                    }

                    removeRow(row, hasNewParent, true);

                    newGroup.rows[row.id] = row;
                    row.group = newGroup;
                }

                function setGridExpandable(expandable, refresh, groupsComplete) {
                    if (refresh != true && gridField.model.expandable == expandable) {
                        return;
                    }

                    gridField.model.expandable = expandable;

                    hideAddGroup();
                    hideDeleteRow();

                    for (let i = 0; i < groups.length; i++) {
                        let group = groups[i];

                        if (groupsComplete == true) {
                            setGroupExpandable(group, group.expandable, true);
                        } else {
                            hideAddRow(group);
                        }
                    }
                }

                function setGroupExpandable(group, expandable, refresh) {
                    if (refresh != true && group.expandable == expandable) {
                        return;
                    }

                    group.expandable = expandable;

                    hideAddRow(group);

                    for (let i in group.rows) {
                        let row = group.rows[i];
                        disableRemoveRow(group, row);
                    }
                }

                function hideAddGroup() {
                    let el = element.find(".add-group-button");
                    hideElement(el, !gridField.model.expandable);
                }

                function hideAddRow(group) {
                    let groupEl = element.find(`#${group.id}`);
                    let el = groupEl.find(".add-row-button");
                    hideElement(el, !gridField.model.expandable || !group.expandable);
                }

                function disableRemoveRow(group, row) {
                    row.disabled = row.disableRemoveRow || !group.expandable;

                    let rowEl = element.find(`#${row.id}`);
                    let el = scope.isPhone ? rowEl.find(".delete-row-button") : rowEl.find(".delete-cell");
                    disableElement(el, row.disableRemoveRow || !group.expandable);
                }

                function hideDeleteRow() {
                    let el = element.find(".delete-cell");
                    hideElement(el, !gridField.model.expandable);
                }

                // endregion
                // region utils

                function hideElement(element, hide) {
                    hide ? element.hide() : element.show();
                }

                function disableElement(element, disable) {
                    element.attr("disabled", disable);
                    disable ? element.addClass("disabled") : element.removeClass("disabled");
                }

                // endregion
                // region phone

                function buildTemplate() {
                    scope.headerDescription = angular.element(document.body).find(".header-description")[0];
                    scope.defaultDescription = scope.headerDescription.innerHTML;
                    scope.validated = false;

                    let buttonsTemplate = "<div class='groupButtons'>" +
                        "<div class='add-row-button' ng-click='api.addRow(group)' title='{{\"eob.workflow.circulation.tooltip.add.activity\" | translate}}'>" +
                        "<i class='icon-16-add' title='add'></i>" +
                        "</div>";

                    // activity icon and name
                    let treeNode = "<div class='drag-handle-cell'></div><i class='{{::row.cells.activityType.value}}'></i><span  ng-bind='row.cells.activityName.value'></span>"

                    // activities displayed in a tree view
                    let treeTemplate = `${"<div ng-show='!showActivities' class='activities-overview' ng-click='changeView(row, true)'>"}${treeNode}
                        <div class='delete-row-button' ng-click='row.disabled || api.removeRow(row)'><i class='icon-16-delete-dark'></i></div></div>`;

                    // fields displayed underneath each other and "back-to-groups" button in activities view
                    let activityTemplate = "<div class='form-row custom-grid-row' ng-class='{visible: row.expanded, hidden: !row.expanded}' id='{{::row.id}}'></div>" +
                        "<button ng-show='showActivities && row.expanded' type='button' class='back-to-groups footer-button' ng-click='changeView(row, false)'>" +
                        "<i class='icon-32-footer-done'></i></button>";

                    // existing groups and their activities
                    let groupTemplate =
                        `${"<div class='custom-grid-group' ng-repeat='group in model.groups' id='{{::group.id}}' expandable='{{group.expandable}}'>" +
                        "<div class='group-header' ng-show='!showActivities'><div class='drag-handle-group'></div>" +
                        "<legend class='toggle-area-legend' ng-click='group.expanded = !group.expanded' ng-class='{closed: !group.expanded || !validated}' " +
                        "ng-bind='group.title' title='{{group.title}}'></legend>"}${buttonsTemplate}</div></div>
                        ${"<ul class='activityList toggle-area-hidden-content'  ng-class='{open: (!group.expanded && validated) || group.expanded, close: (!validated) && !group.expanded}'>" +
                        "<li ng-repeat='(id, row) in group.rows' id='{{::row.id}}'>"}${treeTemplate}${activityTemplate}</li></ul>`;

                    scope.$on("add.new.group", () => {
                        addGroup();
                    });

                    bindValidationBubbleSubsribtion = MessageService.subscribe("bind-validation-bubble", (data) => {
                        let rowId = data.input[0].closest("li") ? data.input[0].closest("li").id : "";
                        if (rowId) {
                            for (let group of scope.model.groups) {
                                for (let id in group.rows) {
                                    if (id == rowId && !scope.validated && !group.rows[id].expanded) {
                                        scope.validated = true;
                                        changeView(group.rows[id], true)
                                    }
                                }
                            }
                        }
                    });

                    return groupTemplate;
                }



                function changeView(row, showActivity) {
                    row.expanded = !row.expanded;
                    scope.showActivities = !scope.showActivities;
                    scope.headerDescription.innerHTML = showActivity ? row.group.title : scope.defaultDescription;
                    if (scope.validated && !showActivity) {
                        scope.validated = false;
                    }

                    let elementsToManipulate = [];
                    elementsToManipulate.push(angular.element(document.body).find("eob-form-footer"));
                    elementsToManipulate.push(angular.element(document.body).find(".state-header-plugins"));
                    elementsToManipulate.push(element.find(".buttonRow"));
                    elementsToManipulate.push(element.find("eob-kebab-menu"));
                    elementsToManipulate.push(element.find(".add-group-button"));

                    for (let el of elementsToManipulate) {
                        hideElement(el, showActivity)
                    }
                }

                scope.$on("$destroy", () => {
                    if (scope.isPhone) {
                        bindValidationBubbleSubsribtion.unsubscribe();
                    }
                });

                // endregion
            },
            template: require("!raw-loader!./eob.wf.circulation.grid.html")
        }
    }
})();
