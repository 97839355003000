export enum PdfjsToolbarAction {
    SHOW_KEBAB = "showKebab",
    SHOW_SEARCH_BAR = "showSearchBar",
    SHOW_REFRESH = "showRefresh",
    SHOW_SIDE_BAR = "showSideBar",
    SHOW_ZOOM_OUT = "showZoomOut",
    SHOW_ZOOM_IN = "showZoomIn",
    SHOW_ROTATE_RIGHT = "showRotateRight",
    SHOW_ROTATE_LEFT = "showRotateLeft",
    SHOW_ATTACHMENTS = "showAttachments",
    SHOW_GO_TO_PARENT = "showGoToParent",
    ATTACHMENTS_AVAILABLE = "attachmentsAvailable",
    ATTACHMENTS_OPEN = "attachmentsOpen"
}

