import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {Observable} from "rxjs";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";

@Injectable({providedIn: BackendDms2Module})
export class Dms2FavoritesService {

    constructor(private httpClient: HttpClient) {
    }

    getFavorites(): Observable<BackendObject> {
        throw new Error("not implemented yet");
    }
}