import {Injectable} from "@angular/core";
import {
    ContextMenuAction,
    ContextMenuActionsProvider,
    ContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {ContextMenuActionsService} from "MODULES_PATH/context-menu/services/context-menu-actions.service";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {defer, Observable} from "rxjs";

@Injectable({
    providedIn: ContextMenuModule
})
export class VariantsContextMenuService implements ContextMenuActionsProvider {

    constructor(private contextMenuActionsService: ContextMenuActionsService) {
    }

    getSingleSelectionContextMenuActions(contextMenuItem: ContextMenuItem, contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            const contextMenuActions: ContextMenuAction[] = await this.contextMenuActionsService.getVariantContextMenuActions(contextMenuItem);

            return contextMenuActions;
        });
    }

    getMultiselectionContextMenuActions(contextMenuItems: ContextMenuItem[], contextData: ContextData): Observable<ContextMenuAction[]> {
        return;
    }
}
