(function() {
    "use strict";

    angular.module("eob.core").factory("dvToolService", DvToolService);

    DvToolService.$inject = [];

    function DvToolService() {
        let service = {
            getDeepObjectByString,
            itemCounter,
            getQueryParameters,
            getNewUrl
        };
        return service;

        function getDeepObjectByString(origin, path) {
            let parts = path.split(".");

            let obj = origin;
            for (let i = 0; i < parts.length; i++) {
                obj = obj[parts[i]];
                if (obj == void 0) {
                    break;
                }
            }
            return obj;
        }

        //Zählt die Items eines Arrays od. Objektes in erster Instanz.
        //Liefert bei nicht vorhandenen Einträgen 0 zurück.
        function itemCounter(data) {
            let itemCount = 0;
            angular.forEach(data, () => {
                itemCount++;
            });
            return itemCount;
        }

        function getQueryParameters() {
            let queryParameters = {};
            let query = window.location.search.substring(1);
            let parameters = query.split("&");
            for (let i = 0; i < parameters.length; i++) {
                let parameterPair = parameters[i].split("=");

                let parameterName = parameterPair[0];
                let parameterValue = parameterPair[1];
                if (parameterName != void null && parameterValue != void null) {
                    queryParameters[parameterName] = parameterValue;
                }
            }
            return queryParameters;
        }

        function getNewUrl(queryParameters) {
            let params = [];
            let parameterNames = Object.keys(queryParameters);
            for (let i = 0; i < parameterNames.length; i++) {
                let parameterName = parameterNames[i];
                let parameterValue = queryParameters[parameterName];

                let parameter = `${parameterName}=${parameterValue}`;
                params.push(parameter);
            }

            let newUrl = window.location.href.split("?")[0];
            if (params.length > 0) {
                newUrl += `?${params.join("&")}`;
            }

            return newUrl;
        }
    }
})();