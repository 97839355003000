(function() {
    require("COMPONENTS_PATH/eob-modal-container/eob-modal-hitlist-config/eob.modal.hitlist.config.dir.js");

    require("SERVICES_PATH/eob.state.history.manager.srv.js");
    require("SERVICES_PATH/eob.desktop.srv.js");
    require("SERVICES_PATH/eob.search.srv.js");
    require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

    angular.module("eob.core").factory("hitlistConfigService", HitlistConfigService);

    HitlistConfigService.$inject = ["$state", "$timeout", "stateHistoryManager", "desktopService", "searchService", "asIniService", "objectTypeService", "clientService",
        "$rootScope", "$compile", "$filter", "$stateParams", "dmsActionService", "cacheManagerService", "stateService", "offlineCacheService", "notificationsService"];

    // eslint-disable-next-line max-params, require-jsdoc
    function HitlistConfigService($state, $timeout, StateHistoryManager, DesktopService, SearchService, AsIniService, ObjectTypeService, ClientService,
                                  $rootScope, $compile, $filter, $stateParams, DmsActionService, CacheManagerService, StateService, OfflineCacheService, NotificationsService) {
        return {
            saveSorting,
            saveColWidth,
            saveGrouping,
            saveTreeSize,
            resetConfiguration,
            openFolderTree,
            openFolderFlat
        };

        async function openFolderTree() {
            let state = StateHistoryManager.getCurrentStateData();
            if (ClientService.isOnline() || (await OfflineCacheService.isObjectOfflineCached(state.data.objectId))) {
                AsIniService.setShowRegisterTree(state.data.cabinetId, true);
                StateService.openFolderOrRegisterAsync({ osid: state.data.objectId, objectTypeId: state.data.objectTypeIds[0] });
            } else {
                NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
            }
        }

        async function openFolderFlat() {
            if (ClientService.isOnline() || (await OfflineCacheService.isObjectOfflineCached($stateParams.folderId))) {
                AsIniService.setShowRegisterTree($stateParams.cabinetId);

                let stateConfig = StateHistoryManager.getCurrentConfig();

                let selectedItems = [];
                if (stateConfig.selectedItems != void 0) {
                    for (let selectedItemId in stateConfig.selectedItems) {
                        let dmsDocument = CacheManagerService.dmsDocuments.getById(selectedItemId);

                        if (dmsDocument != void 0) {
                            selectedItems.push(dmsDocument);
                        }
                    }
                }

                let dmsItem = { osid: $stateParams.folderId, objectTypeId: $stateParams.objectTypeId };
                StateService.openFolderFlatAsync(dmsItem, $stateParams.cabinetId, selectedItems);
            } else {
                NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
            }
        }

        function saveColWidth(hitlistApi, stateId) {

            let state = StateHistoryManager.getStateData(stateId);
            let config = AsIniService.getHitlistConfiguration(state.data);
            setWidths(hitlistApi, config, state);

            if (state.data.flat == true && state.data.cabinetId != void 0) {
                config.id = state.data.cabinetId;
            }

            AsIniService.addHitlistConfiguration(config);
        }

        function saveTreeSize(stateId, treeSize) {

            let state = StateHistoryManager.getStateData(stateId);
            let config = AsIniService.getHitlistConfiguration(state.data);

            setLocationTreeWidth(config, treeSize);

            if (state.data.flat == true && state.data.cabinetId != void 0) {
                config.id = state.data.cabinetId;
            }

            AsIniService.addHitlistConfiguration(config);
        }

        function saveSorting(hitlistApi, stateId) {
            let state = StateHistoryManager.getStateData(stateId);
            let config = AsIniService.getHitlistConfiguration(state.data);

            setSorting(hitlistApi, config, state);

            if (state.data.flat == true && state.data.cabinetId != void 0) {
                config.id = state.data.cabinetId;
            }

            AsIniService.addHitlistConfiguration(config);
        }

        function saveGrouping(hitlistApi, stateId) {
            let state = StateHistoryManager.getStateData(stateId);
            let config = AsIniService.getHitlistConfiguration(state.data);

            setGrouping(hitlistApi, config, state);

            if (state.data.flat == true && state.data.cabinetId != void 0) {
                config.id = state.data.cabinetId;
            }

            AsIniService.addHitlistConfiguration(config);
        }

        /**
         * Add the grouping sections to the given hitlist config.
         *
         * @param {object} hitlistApi - A hitlist api.
         * @param {object} config - A hitlist configuration.
         * @param {object} state - A state config.
         */
        function setGrouping(hitlistApi, config, state) {
            let gridOptions = hitlistApi.getGridOptions(),
                groupColumns = gridOptions.columnApi.getRowGroupColumns(),
                columnDefs = gridOptions.columnDefs,
                expandedGroups = {};

            let anonymousStates = ["folder"]//, "workflow", "startable", "processes"];

            let getUnique = state.data.flat != true && anonymousStates.indexOf(state.data.type) < 0;

            let data = extractSetting(columnDefs, groupColumns, undefined, getUnique, false);

            if (!getUnique) {
                addInvisibleColumnSettings(data, config.groups, hitlistApi);
            }

            config.groups = data;

            gridOptions.api.forEachNode((node) => {
                if (node.group == true && node.expanded == true) {
                    expandedGroups[node.key] = true;
                }
            });

            config.expandedGroups = expandedGroups;
        }

        /**
         * Sets the sorting section at the given hitlist config.
         *
         * @param {object} hitlistApi - A hitlist api.
         * @param {object} config - A hitlist configuration.
         * @param {object} state - A state config.
         */
        function setSorting(hitlistApi, config, state) {
            let gridOptions = hitlistApi.getGridOptions(),
                sortColumns = gridOptions.columnApi.getColumnState(),
                columnDefs = gridOptions.columnDefs;

            let getUnique = state.data.flat != true;
            let data = extractSortSettings(columnDefs, sortColumns, getUnique);

            if (!getUnique) {
                addInvisibleColumnSettings(data, config.sort, hitlistApi);
            }

            config.sort = data;
        }

        /**
         * Add the width section to the given hitlist config.
         *
         * @param {object} hitlistApi - A hitlist api.
         * @param {object} config - A hitlist configuration.
         * @param {object} state - A state config.
         */
        function setWidths(hitlistApi, config, state) {
            let gridOptions = hitlistApi.getGridOptions(),
                columns = gridOptions.columnApi.getColumnState(),
                columnDefs = gridOptions.columnDefs;

            let getUnique = state.data.type != "folder" && state.data.flat != true;
            let data = extractSetting(columnDefs, columns, "width", getUnique, true);

            if (!getUnique) {
                addInvisibleColumnSettings(data, config.width, hitlistApi);
            }

            config.width = data;
        }

        /**
         * Add the tree width section to the given hitlist config.
         *
         * @param {object} config - A hitlist configuration.
         * @param {number} treeSize - The width of the tree panel in a folder state.
         */
        function setLocationTreeWidth(config, treeSize) {
            if (treeSize != void 0 && treeSize > 0) {
                config.locationTreeWidth = treeSize;
            }
        }

        /**
         * Add the settings to the hitlist config for the columns that are currently not displayed.
         * (i.e. for differing register content in a folder view)
         * @param {object} data - The hitlist config data of the preferred property.
         * @param {object} oldData - The former hitlist config data of the preferred property.
         * @param {object} hitlistApi - The hitlist api of the hitlist.
         */
        function addInvisibleColumnSettings(data, oldData, hitlistApi) {
            let columns = hitlistApi.getColumns(),
                length = `${ClientService.isTouchDevice() ? columns.length - 1 : columns.length}`;

            for (let colId in oldData) {
                let number = parseInt(colId.substring(1, colId.length));
                if (number >= length) {
                    data[colId] = oldData[colId];
                }
            }
        }

        /**
         * Build asini conform data from the given columns for the given property.
         *
         * @param {object[]} columnDefs - The columnDefs of a grid.
         * @param {object[]} columns - The columns.
         * @param {string=} property - The property to extract or null to use the index.
         * @param {boolean} getUnique - Create data with unique identifiers.
         * @param {boolean} ignoreIcons - Don't extract data for icon columns.
         * @returns {object} A column identifier to property map.
         */
        function extractSetting(columnDefs, columns, property, getUnique, ignoreIcons) {
            let data = {},
                index;

            columns.forEach((column, i) => {
                if (columnDefs[i] == void 0 || (ignoreIcons && columnDefs[i].isIconCell)) {
                    return;
                }

                let colId = getColumnIdentifier(columnDefs, column.colId, getUnique);

                if (colId != void 0) {
                    data[colId] = property == void 0 ? i : column[property];
                }
            });

            return data;
        }

        function extractSortSettings(columnDefs, sortCols, getUnique) {
            let data = {},
                index;

            sortCols.forEach(sortCol => {
                let colId = getColumnIdentifier(columnDefs, sortCol.colId, getUnique);
                if (getUnique && colId != void 0) {
                    data[colId] = sortCol.sort && (`${sortCol.sort}:${sortCol.sortIndex}`);
                } else {
                    const lastIndex = colId.indexOf("_") > 0 ? colId.lastIndexOf("_") : colId.length;
                    index = colId.substring(1, lastIndex);
                    index -= columnDefs.filter(column => column.isIconCell).length - 1;
                    data[index] = sortCol.sort;
                }
            })

            return data;
        }

        /**
         * Get the column identifier for the given column id.
         * It can be used for persisting the column settings in the asini.
         *
         * @param {object[]=} columnDefs - The column definitions of a grid. Only necessary when getUnique.
         * @param {string} colId - The column id.
         * @param {boolean} getUnique - Whether to create a unique or anonymous identifier.
         * @returns {string} An identifier of the column.
         */
        function getColumnIdentifier(columnDefs, colId, getUnique) {

            if (getUnique) {
                return getUniqueName(columnDefs, colId);
            }

            colId = colId.indexOf("_") > 0 ? colId.substring(0, colId.lastIndexOf("_")) : colId;
            return `${ClientService.isTouchDevice() ? "f" + parseInt(colId.substring(1, colId.length)) : colId}`;
        }

        /**
         * Get a unique identifier for the given column id.
         *
         * @param {object[]} columnDefs - The column definitions of a grid.
         * @param {string} colId - The column id.
         * @returns {string} A unique identifier.
         */
        function getUniqueName(columnDefs, colId) {
            let result = getName(columnDefs, colId, "internalName");
            if (result == void 0) {
                result = getName(columnDefs, colId, "headerName");
            }
            return `${result}@@@${colId}`;
        }

        function getName(columnDefs, colId, nameId) {
            let columnDef;
            for (let i = 0; i < columnDefs.length; i++) {
                columnDef = columnDefs[i];
                if (columnDef != void 0 && columnDef.field == colId) {
                    return columnDef[nameId];
                }
            }
            return undefined;
        }

        function resetConfiguration(item) {
            let state = StateHistoryManager.getCurrentStateData();
            let hitlistConfiguration = AsIniService.getHitlistConfiguration(state.data);
            delete hitlistConfiguration.sort;
            delete hitlistConfiguration.width;
            delete hitlistConfiguration.groups;
            delete hitlistConfiguration.expandedGroups;
            delete hitlistConfiguration.locationTreeWidth;

            item.hitlistConfig.api.resetGrid();

            $state.reload();
        }
    }
})();
