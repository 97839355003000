import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {TimerService} from "MODULES_PATH/timer/services/timer.service";
import {EmsDeduplicationOption} from "ENUMS_PATH/ems.enum";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {TimerDoneType} from "MODULES_PATH/timer/enums/timer-done-type";
import {EobTimer} from "MODULES_PATH/timer/interfaces/timer.interface";
import {Subscription} from "rxjs";
import {takeWhile} from "rxjs/operators";
import {FormFieldModelService} from "MODULES_PATH/form/services/form-field-model.service";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {FormDefinition} from "MODULES_PATH/form/interfaces/form-definition.interface";
import {TodoEnvironmentService, TodoFormData, TodoFormHelper} from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-modal-deduplication",
    templateUrl: "./eob-modal-deduplication.component.html",
    styleUrls: ["./eob-modal-deduplication.component.scss"]
})
export class EobModalDeduplicationComponent implements OnInit, OnDestroy {
    @Input() emsDeduplicationOption: EmsDeduplicationOption;

    currentTime: EobTimer;
    hasReferenceDocsRole: boolean;
    hasReferenceRole: boolean;
    rememberChoice: boolean;
    subscriptions: Subscription = new Subscription();
    readonly translateFn: TranslateFnType;
    checkboxFormdef: FormDefinition;

    private formHelper: TodoFormHelper;

    get emsDeduplicationEnum(): typeof EmsDeduplicationOption {
        return EmsDeduplicationOption;
    }

    // eslint-disable-next-line max-params
    constructor(
        private messageService: MessageService,
        private cdRef: ChangeDetectorRef,
        private timerService: TimerService,
        private formFieldModelService: FormFieldModelService,
        @Inject("formService") private formService: any,
        @Inject("formHelper") private formHelperService: any,
        @Inject("$filter") private $filter: ng.IFilterService,
        @Inject("environmentService") private environmentService: TodoEnvironmentService) {

        this.translateFn = this.$filter("translate");
        this.rememberChoice = false;
    }

    ngOnInit(): void {
        this.hasReferenceDocsRole = this.environmentService.userHasRole("R_CLNT_CREATE_REFERENCE_DOCS");
        this.hasReferenceRole = this.environmentService.userHasRole("R_CLNT_CREATE_REFERENCE");

        this.buildRememberCheckboxForm();

        this.subscriptions.add(this.timerService.countDown$.subscribe((time: EobTimer) => {
            this.currentTime = time;
            this.cdRef.detectChanges();
        }));

        this.subscriptions.add(this.timerService.done().pipe(takeWhile((doneBy): boolean => doneBy == TimerDoneType.TIMEOUT)).subscribe(() => {
            this.messageService.broadcast(ModalEvents.DESTROY);
        }));
    }

    selectOption(option: EmsDeduplicationOption = null): void {
        this.rememberChoice = this.formHelper.getFieldByInternal("rememberChoice")?.value === "1";
        this.messageService.broadcast(ModalEvents.DESTROY, {option, remember: this.rememberChoice});
    }

    buildRememberCheckboxForm(): void {
        const title: string = this.translateFn("eob.modal.deduplication.apply.choice.to.all");
        const formFields: FieldModel[] = [
            this.formFieldModelService.getMockedField("checkbox", "rememberChoice", title)
        ];

        const formData: TodoFormData = this.formService.createFormData(formFields, "min");
        this.formHelper = this.formHelperService.getFormHelper({formData});

        this.checkboxFormdef = {
            isMainForm: true,
            formFields,
            formHelper: this.formHelper,
            isMockForm: true,
            validationMode: "min"
        };
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
