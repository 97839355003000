angular.module("eob.core").factory("groupApiService", GroupApiService);

GroupApiService.$inject = [];

/**
 * A service that creates a form pagecontrol element
 */
export default function GroupApiService() {

    return {
        addGroupApi
    };

    function addGroupApi(api, field, formHelper) {

        /**
         * enables the group
         */
        api.enable = function() {
            field.isDisabled = false;

            for (let i in field.model.fields) {
                let childFieldName = field.model.fields[i].internal;
                let childField = formHelper.getFieldByInternal(childFieldName);

                // childField kann null sein, wenn es sich um einen Radiobutton handelt.
                if (childField != null && childField.api != void 0 && childField.api.enable != void 0) {
                    childField.api.enable();
                }
            }
        };

        /**
         * disables the group
         */
        api.disable = function() {
            field.isDisabled = true;

            for (let i in field.model.fields) {
                let childFieldName = field.model.fields[i].internal;
                let childField = formHelper.getFieldByInternal(childFieldName);

                // childField kann null sein, wenn es sich um einen Radiobutton handelt.
                if (childField != null && childField.api != void 0 && childField.api.disable != void 0) {
                    childField.api.disable();
                }
            }
        };

        /**
         * Sets the title of the group.
         *
         * @param {string} title of the group.
         */
        api.setTitle = function(title) {
            const element = api.getElement();
            const fieldType = field.model.type;

            let label = element.find(".group-label > span").first();
            label.prop("innerText", title);
        };
    }
}
