import {Injectable, Inject} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {
    Dms2Object,
    Dms2ObjectResult,
    Dms2Value,
    Dms2Permissions,
    Dms2ContentStream
} from "CORE_PATH/backend/modules/dms2/interfaces/dms2-object.interface";
import {BackendObject, ObjectTypeType} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {ObjectTypeRights, ObjectType} from "INTERFACES_PATH/object-type.interface";
import {BackendFileParameter} from "CORE_PATH/backend/interfaces/search-result/backend-file-parameter.interface";
import {BackendBaseParameterType} from "CORE_PATH/backend/interfaces/search-result/backend-base-parameter.interface";
import {BackendSystemFieldType} from "CORE_PATH/backend/interfaces/search-result/backend-system-field.interface";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";
import {HttpParams} from "@angular/common/http";
import {TodoEnvironmentService, TodoQuery, TodoQueryConfig} from "INTERFACES_PATH/any.types";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {User} from "INTERFACES_PATH/user.interface";
import {BackendSearchIdRequest} from "CORE_PATH/backend/interfaces/search-requests/backend-search-id-request";

@Injectable({providedIn: BackendDms2Module})
export class Dms2SearchService {

    constructor(private httpClient: HttpClient, private objectTypeService: ObjectTypeService,
                private organisationService: OrganisationService, @Inject("environmentService") private environmentService: TodoEnvironmentService) {
    }

    getObjectMetadata(osId: string, objectTypeId: string, parameters?: HttpParams): Observable<BackendObject> {
        const typeQueryString: string = (objectTypeId == null) ? "" : `?objectTypeId=${objectTypeId}`;

        return this.httpClient.get<Dms2ObjectResult>(`/api/dms/objects/${osId}${typeQueryString}`, {params: parameters}).pipe(
            map(dms2Result => this.convertToOsRestResult(dms2Result.objects)[0]));
    }

    search(query: TodoQuery, config?: TodoQueryConfig): Observable<BackendObject[]> {
        const params: HttpParams = new HttpParams({fromObject: config});

        return this.httpClient.post<Dms2ObjectResult>("/api/dms/objects/search/native", query, {params}).pipe(
            map(dms2Result => this.convertToOsRestResult(dms2Result.objects)));
    }

    getCheckedOutDocuments(): Observable<BackendObject[]> {
        throw new Error("not implemented yet");
    }

    getFolderContent(osId: string, maxSize: number): Observable<BackendObject[]> {
        throw new Error("not implemented yet");
    }

    searchVtx(query: TodoQuery): Observable<BackendObject[]> {
        throw new Error("not implemented yet");
    }

    searchByIds(query: BackendSearchIdRequest): Observable<BackendObject[]> {
        throw new Error("not implemented yet");
    }

    private convertToOsRestResult(dms2Objects: Dms2Object[]) : BackendObject[] {
        const retVal: BackendObject[] = [];

        for (const dms2Object of dms2Objects) {
            const osid: string = dms2Object.properties["system:objectId"].value;
            const objectTypeId: string = dms2Object.properties["system:objectTypeId"].value;
            const objectType: ObjectType = this.objectTypeService.getRealObjectType(dms2Object.properties["system:objectTypeId"].value);
            let baseType: ObjectTypeType;

            switch (dms2Object.properties["system:baseTypeId"].value) {
                case "document": baseType = "DOCUMENT"; break;
                case "register": baseType = "REGISTER"; break;
                case "folder": baseType = "FOLDER"; break;
            }

            // Todo 01: Bullshit using EMS config here! We need a ObjectTypeService! All components should use it when typ need information.
            // Some of the properties are initialized with null because odd typescript everytime need a
            // complete object for initialization. They are filled with the methods after initialization.
            const backendObject: BackendObject = {
                osid,
                objectTypeId,
                objectType: baseType,
                internalName: objectType.model.config.internal, // Todo: 01
                displayName: objectType.model.config.name, // Todo: 01
                ecmSimpleFields: null,
                ecmParentFields: null, // Todo: Parent fields are not yet supported by DMS2
                ecmTableFields: null,
                baseParameters: null,
                systemFields: null,
                fileProperties: this.convertFilePropertiesToOsRestResult(dms2Object),
                rights: dms2Object.permissions == undefined ? null : this.convertRightsToOsRestResult(dms2Object),
                childrenCount: 0,
                children: (dms2Object.children == undefined) ? null : this.convertToOsRestResult(dms2Object.children),
                objectInserts: null, // Will be loaded on demand
                objectFlagsValue: this.convertObjectFlagsValueToOsRestResult(dms2Object)
                // folderRegisterTree: null
            };

            this.convertPropertiesToOsRestResult(dms2Object, backendObject, objectType);
            this.countChildrenRecursivly(dms2Object);

            retVal.push(backendObject);
        }

        return retVal;
    }

    private convertPropertiesToOsRestResult(dms2Object: Dms2Object, osRestObject: BackendObject, objectType: ObjectType) : void {
        osRestObject.ecmSimpleFields = [];
        osRestObject.baseParameters = [];
        osRestObject.systemFields = [];

        const objectFlagsField: Dms2Value<string> = dms2Object.properties["system:OBJECT_FLAGS"];

        if (objectFlagsField != undefined) {
            this.fixReferenceObjectFlag(dms2Object, objectFlagsField);
        }

        for (const dms2PopertyName in dms2Object.properties) {
            if (dms2PopertyName.startsWith("system:")) {
                let baseProperty: BackendBaseParameterType;
                let systemProperty: BackendSystemFieldType;
                let finalValue: string = dms2Object.properties[dms2PopertyName].value;

                // Base Parameter
                switch (dms2PopertyName) {
                    case "system:createdBy":
                        baseProperty = "CREATOR";
                        break;
                    case "system:OBJECT_CRDATE":
                        systemProperty = "OBJECT_CRDATE";
                        break;
                    case "system:creationDate":
                        baseProperty = "CREATED";
                        break;
                    case "system:OBJECT_LINKS":
                        baseProperty = "LINKS";
                        break;
                    case "system:OBJECT_SYSTEMID":
                        baseProperty = "SYSTEM_ID";
                        break;
                    case "system:lastModificationDate":
                        baseProperty = "MODIFIED";
                        break;
                    case "system:lastModifiedBy":
                        baseProperty = "MODIFIER";
                        break;
                    case "system:OBJECT_FOREIGNID":
                        baseProperty = "FOREIGN_ID";
                        break;
                    case "system:OBJECT_PDFANNOTATIONCOUNT":
                        baseProperty = "PDF_ANNOTATION_COUNT";
                        break;
                    case "system:OBJECT_TXTNOTICECOUNT":
                        baseProperty = "TEXT_NOTICE_COUNT";
                        break;
                    case "system:OBJECT_AVDATE":
                        systemProperty = "OBJECT_AVDATE";
                        break;
                    case "system:OBJECT_DOCPAGECOUNT":
                        baseProperty = "DOCUMENTPAGECOUNT";
                        break;
                    case "system:versionLabel":
                        baseProperty = "VERSION";
                        break;
                    case "system:OBJECT_CHECKOUTTIME":
                        baseProperty = "LOCKED_TIME";
                        break;
                    case "system:versionSeriesLockedBy":
                        systemProperty = "OBJECT_LOCKUSER";
                        finalValue = this.convertVersionSeriesLockedBy(osRestObject, finalValue);
                        break;
                    case "system:OBJECT_RETENTION_PLANNED":
                        baseProperty = "RETENTION_PLANNED_DATE";
                        break;
                    case "system:OBJECT_RETENTION":
                        baseProperty = "RETENTION_DATE";
                        break;
                    case "system:OBJECT_FLAGS":
                        systemProperty = "OBJECT_FLAGS";
                        finalValue = this.convertObjectFlagsToSpeakingName(finalValue);
                        break;
                    case "system:OBJECT_USERGUID":
                        baseProperty = "OWNER";
                        finalValue = this.convertObjectUserGuid(finalValue);
                        break;
                    case "system:OBJECT_MAIN":
                        systemProperty = dms2PopertyName.replace("system:", "") as BackendSystemFieldType;
                        finalValue = this.convertMainTypeSpeakingName(finalValue);
                        break;
                    case "system:OBJECT_DELETED":
                    case "system:OBJECT_INDEXHISTFLAGS":
                    case "system:OBJECT_OSSD":
                    case "system:STAMM_ID":
                    case "system:STAMM_TIME":
                    case "system:STAMM_LINKS":
                    case "system:REG_ID":
                    case "system:ICON_ID":
                    case "system:SDSTA_ID":
                    case "system:REG_PARID":
                    case "system:FOLDERID":
                    case "system:FOLDERTYPE":
                    case "system:REGISTERID":
                    case "system:REGISTERTYPE":
                    case "system:PARENTREGID":
                    case "system:PARENTREGTYPE":
                    case "system:OBJECT_COUNT":
                    case "system:OBJECT_AVID":
                    case "system:OBJECT_CO":
                    case "system:OBJECT_MEDDOCID":
                    case "system:OBJECT_MEDDIAID":
                    case "system:OBJECT_MEDDOCNA":
                    case "system:OBJECT_MEDDIANA":
                    case "system:OBJECT_DOCHISTFLAGS":
                    case "system:OBJECT_MIMETYPEID":
                    case "system:OBJECT_FILESIZE":
                    case "system:OBJECT_SIGNSTATE":
                    case "system:SDOBJ_ID":
                    case "system:SDOBJTYPE":
                    case "system:SDREG_ID":
                    case "system:SDDEL":
                    case "system:SDTIME":
                    case "system:SDREG_TYPE":
                        systemProperty = dms2PopertyName.replace("system:", "") as BackendSystemFieldType;
                        break;
                    case "system:objectTypeId":
                    case "system:baseTypeId":
                    case "system:objectId":
                        break; // ignore
                    default:
                        systemProperty = dms2PopertyName.replace("system:", "") as BackendSystemFieldType;
                }

                if (baseProperty != null) {
                    osRestObject.baseParameters.push({
                        type: baseProperty,
                        value: finalValue
                    });
                } else if (systemProperty != null) {
                    osRestObject.systemFields.push({
                        type: systemProperty,
                        value: finalValue
                    });
                }
            } else {
                const field = objectType.model.responsiveFieldList.find(f => f.internal == dms2PopertyName);

                osRestObject.ecmSimpleFields.push({
                    dbName: field.dbname, // Todo: Don't use it, use ObjDef instead!
                    displayName: field.name, // Todo: Don't use it, use ObjDef instead!
                    internalName: dms2PopertyName,
                    isSearchField: (field.isSearchField == undefined) ? false : field.isSearchField, // Todo: Don't use it, use ObjDef instead!
                    searchField: (field.searchField == undefined)? false : field.searchField, // Todo: Don't use it, use ObjDef instead!
                    type: field.type, // Todo: Don't use it, use ObjDef instead!
                    value: dms2Object.properties[dms2PopertyName].value,
                    visible: true // Todo: Don't use it, use ObjDef instead!
                });
            }
        }
    }

    private convertFilePropertiesToOsRestResult(dms2Object: Dms2Object) : BackendFileParameter[] {
        if (dms2Object.contentStreams == undefined || dms2Object.contentStreams.length == 0) {
            return null;
        }

        const contentStream: Dms2ContentStream = dms2Object.contentStreams[0];
        const extension: string = contentStream.fileName.substring(contentStream.fileName.indexOf(".") + 1);
        const retVal: BackendFileParameter[] = [];

        retVal.push({type: "COUNT", value: dms2Object.contentStreams.length.toString()});
        retVal.push({type: "EXTENSION", value: extension});
        retVal.push({type: "MIMETYPE", value: contentStream.mimeType});
        retVal.push({type: "SIZE", value: contentStream.length.toString()});
        retVal.push({type: "MIMETYPEGROUP", value: ""}); // Todo: Missing in DMS2
        retVal.push({type: "ICONID", value: "0"}); // Todo: Missing in DMS2
        // BackendFileParameterEnum.DOCUMENTPAGECOUNT // Todo: Check if this exists in certain constellations. Otherwise delete.

        return retVal;
    }

    private convertRightsToOsRestResult(dms2Object: Dms2Object) : ObjectTypeRights {
        const p: Dms2Permissions = dms2Object.permissions;

        return {
            indexModify: (p.write?.includes("metadata")),
            objDelete: (p.delete?.includes("object")),
            objExport: (p.read?.includes("content")),
            objModify: (p.write?.includes("content"))
        };
    }

    private convertObjectFlagsValueToOsRestResult(dms2Object: Dms2Object) : number {
        const flags: Dms2Value<string> = dms2Object.properties["system:OBJECT_FLAGS"];
        return (flags == undefined) ? 0 : parseInt(flags.value);
    }

    private convertVersionSeriesLockedBy(osRestObject: BackendObject, value: string): string {
        const user: User = this.organisationService.getUserById(parseInt(value));
        let locked: string = "UNLOCKED";

        if (user) {
            osRestObject.baseParameters.push({
                type: "LOCKED_USER",
                value: user.name
            });
            osRestObject.baseParameters.push({
                type: "LOCKED_USER_FULL_NAME",
                value: user.fullname
            });

            const currentUserId: number = this.environmentService.getSessionInfo().userid;
            locked = currentUserId.toString() == value ? "SELF" : "OTHERS";
        }

        osRestObject.baseParameters.push({
            type: "LOCKED",
            value: locked
        });
        return locked;
    }

    private convertObjectUserGuid(value: string): string {
        const user: User = this.organisationService.getUserByGuid(value);
        return user ? user.name : "";
    }

    /**
     * Convert the numeric flags value of the DMS2 to the speaking values of OSRest.
     * See also grid-content-utils.service.ts getArchiveStateIcon method.
     *
     * @param dms2ObjectFlagNumber The numeric value DMS2 backend return
     * @return The speaking equivalent to the numeric value.
     */
    private convertObjectFlagsToSpeakingName(dms2ObjectFlagNumber: string) : string {
        switch (dms2ObjectFlagNumber) {
            case "0": return "ARCHIVED";
            case "1": return "ARCHIVABLE";
            case "2": return "NOT_ARCHIVABLE";
            case "8": return "NO_PAGES";
            case "64": return "REFERENCE";
            default: return dms2ObjectFlagNumber;
        }
    }

    /**
     * Convert the numeric main type value of the DMS2 to the speaking values of OSRest.
     * See also dms-document-model.ts getTypeCodeByName method.
     *
     * @param mainTypeNumber The numeric value DMS2 backend returns.
     * @return The speaking equivalent to the numeric value.
     */
    private convertMainTypeSpeakingName(mainTypeNumber: string) : string {
        switch (mainTypeNumber) {
            case "1": return "GRAYSCALE";
            case "2": return "BLACKWHITE";
            case "3": return "COLOR";
            case "4": return "WINDOWS";
            case "5": return "MULTIMEDIA";
            case "6": return "EMAIL";
            case "7": return "XML";
            // case "8": return "CONTAINER";
            default: return mainTypeNumber;
        }
    }

    /**
     * This method fixes the archive state value for reference objects (Grüner Pfeilverweis).
     * The algorithmen is copied from the enaio appConnector where it is also done. DMS2-
     * Service will not do this and the client is responsible if he need it.
     *
     * @param dms2Object The Dms2 result object
     * @param objectFlagsField The objectFlags system field
     */
    private fixReferenceObjectFlag(dms2Object: Dms2Object, objectFlagsField: Dms2Value<string>) : void {
        // 64 = Reference object (Grüner Pfeilverweis)
        if (objectFlagsField.value == "64") {
            const systemIdField: Dms2Value<string> = dms2Object.properties["system:OBJECT_SYSTEMID"];
            const systemId: string = (systemIdField == undefined) ? "" : systemIdField.value;

            if (systemId !== "" && systemId !== "0") {
                objectFlagsField.value = "0"; // ARCHIVED, see above
            } else {
                let countField: Dms2Value<string> = dms2Object.properties["system:OBJECT_COUNT"];

                if (countField != null) {
                    dms2Object.properties["system:OBJECT_COUNT"].value = "1";
                }

                countField = dms2Object.properties["system:OBJECT_DOCPAGECOUNT"];

                if (countField != null && parseInt(countField.value) < 1) {
                    dms2Object.properties["system:OBJECT_DOCPAGECOUNT"].value = "1";
                }
            }
        }
    }

    private countChildrenRecursivly(dms2Object: Dms2Object) : number {
        if (dms2Object.children == null) {
            return 0;
        }

        let childrenCount: number = dms2Object.children.length;

        for (const childObject of dms2Object.children) {
            if (childObject.children != null) {
                childrenCount += this.countChildrenRecursivly(childObject);
            }
        }

        return childrenCount;
    }
}