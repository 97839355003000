import {map} from "rxjs/operators";

require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/eob.modal.dialog.srv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");

angular.module("eob.core").factory("variantService", VariantService);

VariantService.$inject = ["$filter", "backendService", "notificationsService", "cacheManagerService", "modalDialogService",
    "stateHistoryManager", "httpService", "backendVariantsService"];

/* eslint-disable */
/**
 * A service that contains functions to handle variants.
 */
export default function VariantService($filter, BackendService, NotificationsService, CacheManagerService, ModalDialogService,
                                       StateHistoryManager, httpService, backendVariantsService) { /* eslint-enable */
    return {
        getActiveVariantId,
        changeActiveVariant,
        deleteVariant,
        getNextVersions,
        getNextVersionsAsync,
        getVariantTreeData,
        parseVariantsCall
    };

    function getVariantTreeData(osid) {
        return backendVariantsService.loadVariants(osid, null).pipe(
            map(variants => {
                const ecmObjects = parseVariantsCall(variants);
                CacheManagerService.dmsDocuments.add(ecmObjects);
                const currentDmsDocument = CacheManagerService.dmsDocuments.getById(osid);
                return CacheManagerService.variantTreeData.getById(currentDmsDocument.model.variantData[0].model.originalVariantId);
            })
        );
    }

    /**
     * Parse the variants call into an uniform variantInfo to ecmObject structure
     * and extract additional information for the contextmenu.
     *
     * @param {object} backendData - The variants call response data.
     * @returns {object[]} - An array of parsed ecmObjects.
     */
    function parseVariantsCall(backendData) {
        let activeVariantId;
        let variantTreeData = buildVariantTree(backendData);
        let versionIdMap = { 0: "" };

        for (const ecmObject of backendData) {
            versionIdMap[ecmObject.osid] = ecmObject.variantInfo.version;

            // Very strange which data is here additional stored and for which purpose. On refactoring this
            // should be investigated and cleaned up to save memory.
            ecmObject.variantInfo.originVersion = versionIdMap[ecmObject.variantInfo.originId];
            ecmObject.variantInfo.originalVariantId = backendData[0].osid;

            if (ecmObject.variantInfo.active) {
                activeVariantId = ecmObject.osid;
            }
        }

        CacheManagerService.variantTreeData.add({
            model: {
                originalVariantId: backendData[0].osid,
                variantTree: variantTreeData,
                activeVariantId,
            }
        });

        return backendData;
    }

    /**
     * Helper function to get the bulk back again with which here is worked.
     *
     * Todo: On refactoring we should think about this technique. It looks very serious and seems to be
     *       a waste of memory. Storing trees is no good idea because it's structure is hard to process.
     *       Also IO think we could easily live without this data and the consumer(s) can look in other
     *       given structures.
     */
    function buildVariantTree(backendData) {
        let original = null;
        let currentMain = null;
        let currentSub = null;

        for (const variant of backendData) {
            const temp = {
                osid: variant.osid,
                children: []
            };

            if (original == null) {
                original = temp;
            } else if (variant.variantInfo.version.indexOf(".0.0") > 0) {
                original.children.push(temp);
                currentMain = temp;
            } else if (variant.variantInfo.version.endsWith(".0")) {
                currentMain.children.push(temp);
                currentSub = temp;
            } else {
                currentSub.children.push(temp);
            }
        }

        return original;
    }

    async function getNextVersionsAsync(osid) {
        let currentDmsDocument = CacheManagerService.dmsDocuments.getById(osid);

        // there might be no dms document and we hbave to fetch it first
        if (currentDmsDocument == void 0) {
            await loadVariantsAsync(osid)
        }

        return getNextVersions(osid)
    }

    async function loadVariantsAsync(osId) {
        let res = await backendVariantsService.loadVariants(osId, null).toPromise(); // Todo: provide also objectTypeId as second argument
        let ecmObjects = parseVariantsCall(res);
        CacheManagerService.dmsDocuments.add(ecmObjects);
    }

    function getNextVersions(osid) {
        let currentDmsDocument = CacheManagerService.dmsDocuments.getById(osid);
        let currentVersion = currentDmsDocument.model.variantData[0].model.version;
        let currentMain, currentParallel, currentSub;

        [currentMain, currentParallel, currentSub] = currentVersion == "Original" ? [0, -1, -1] : currentVersion.split(".")

        let variantData = CacheManagerService.variantTreeData.getById(currentDmsDocument.model.variantData[0].model.originalVariantId);
        let osids = getIds([variantData.model.variantTree])
        let dmsDocumentList = CacheManagerService.dmsDocuments.get(osids)

        let versions = {
            main: currentMain,
            parallel: currentParallel,
            sub: currentSub
        }

        for (let dmsDocument of dmsDocumentList) {
            let version = dmsDocument.model.variantData[0].model.version;
            let versionMain, versionParallel, versionSub;

            [versionMain, versionParallel, versionSub] = version == "Original" ? [0, -1, -1] : version.split(".")

            if (versionMain > versions.main) {
                versions.main = Number(versionMain)
            }

            if (currentMain == versionMain && versionParallel > versions.parallel) {
                versions.parallel = Number(versionParallel)
            }

            if (currentMain == versionMain && currentParallel == versionParallel && versionSub > versions.sub) {
                versions.sub = Number(versionSub)
            }
        }

        versions.main = `${++versions.main}.0.0`
        versions.parallel = `${currentMain}.${++versions.parallel}.0`;
        versions.sub = `${currentMain}.${currentParallel}.${++versions.sub}`;

        if (currentParallel == 0) {
            delete versions.sub
        }

        if (currentVersion == "Original") {
            delete versions.parallel;
            delete versions.sub;
        }

        return versions;
    }

    function getIds(variantNode, ids) {
        let variantIds = ids || []

        for (let variant of variantNode) {
            variantIds.push(variant.osid)

            if (variant.children != void 0) {
                getIds(variant.children, variantIds)
            }
        }

        return variantIds
    }

    /**
     * Get the active variant id for a dms object osid.
     *
     * @param {string|number} osid - A dms object osid.
     * @returns {string} The active variant osid.
     */
    function getActiveVariantId(osid) {
        let docModel = CacheManagerService.dmsDocuments.getById(osid).model,
            originalVariantId = docModel.variantData[0].model.originalVariantId,
            variantTreeData = CacheManagerService.variantTreeData.getById(originalVariantId);

        return variantTreeData.model.activeVariantId;
    }

    /**
     * Changes the active variant of a dms document.
     *
     * @param {DmsDocument} newActiveVariant - The dms document that shall be set active.
     */
    async function changeActiveVariant(newActiveVariant) {
        if (!newActiveVariant.model.rights.indexModify) {
            // Do nothing if the user does not have the rights to change index data (which is required to edit variants)
            return;
        }

        let docModel = newActiveVariant.model;
        let variantDataModel = docModel.variantData[0].model;
        let variantTree = CacheManagerService.variantTreeData.getById(variantDataModel.originalVariantId);
        let activeVariantId = variantTree.model.activeVariantId;

        // update cache immediately for a faster visual response
        variantTree.model.activeVariantId = docModel.osid;
        CacheManagerService.variantTreeData.add(variantTree);

        let oldActiveVariant = CacheManagerService.dmsDocuments.getById(activeVariantId);
        oldActiveVariant.model.variantData[0].model.isActive = false;
        variantDataModel.isActive = true;

        // set variant active
        try {
            await backendVariantsService.activateVariant(oldActiveVariant.model.osid, newActiveVariant.model.osid).toPromise();
            updateCallerState(oldActiveVariant.model.osid, newActiveVariant.model.osid);
            CacheManagerService.dmsDocuments.executeListeners([oldActiveVariant.model.osid, newActiveVariant.model.osid]);
        } catch (error) {
            // reset cache changes on fail
            variantTree.model.activeVariantId = oldActiveVariant.model.osid;
            CacheManagerService.variantTreeData.add(variantTree);

            oldActiveVariant.model.variantData[0].model.isActive = true;
            variantDataModel.isActive = false;

            CacheManagerService.dmsDocuments.add([oldActiveVariant, newActiveVariant]);

            NotificationsService.backendError(error, "eob.action.change.active.variant.error.message");
        }
    }

    function updateCallerState(oldActiveVarianteId, newActiveVariantId) {
        let callerstateId = StateHistoryManager.getCurrentStateData().data.config.caller;
        let selectedItems = (StateHistoryManager.getStateData(callerstateId).data.config || {}).selectedItems;

        if (selectedItems != void 0 && Object.keys(selectedItems).length > 0 && selectedItems[oldActiveVarianteId] != void 0) {
            delete selectedItems[oldActiveVarianteId];
            selectedItems[newActiveVariantId] = 0;
        }
    }

    /**
     * Deletes a variant and all its children.
     *
     * @param {DmsDocument} dmsDocument - The dms document of the variant that shall be deleted.
     */
    async function deleteVariant(dmsDocument) {
        let variantDataModel = dmsDocument.model.variantData[0].model,
            fallbackErrorMsg, successMsg, confirmMessage;

        let variantTreeData = CacheManagerService.variantTreeData.getById(variantDataModel.originalVariantId),
            variantTree = variantTreeData.model.variantTree,
            { parentBranch, variantBranch } = getVariantBranchWithParentBranch(dmsDocument.model.osid, variantTree);

        if (variantBranch.children.length > 0) {
            confirmMessage = $filter("translate")("eob.action.delete.variants.confirm.message");
            successMsg = $filter("translate")("eob.action.delete.variants.success.message");
            fallbackErrorMsg = $filter("translate")("eob.action.delete.variants.error.message");
        } else {
            confirmMessage = $filter("translate")("eob.action.delete.variant.confirm.message");
            successMsg = $filter("translate")("eob.action.delete.variant.success.message");
            fallbackErrorMsg = $filter("translate")("eob.action.delete.variant.error.message");
        }

        await ModalDialogService.deleteVariantDialog(confirmMessage);
        try {
            await BackendService.delete(`/documentfiles/delete/${dmsDocument.model.id}`);

            // update the dms documents cache
            let deletedIds = [variantBranch.osid].concat(getChildIds(variantBranch));
            CacheManagerService.dmsDocuments.remove(deletedIds);

            // if the currently active variant was deleted, set the original variant active
            if (deletedIds.indexOf(variantTreeData.model.activeVariantId) >= 0) {
                let originalVariantId = variantTreeData.model.originalVariantId;

                variantTreeData.model.activeVariantId = originalVariantId;

                let originalVariant = CacheManagerService.dmsDocuments.getById(originalVariantId);
                originalVariant.model.variantData[0].model.isActive = true;
                CacheManagerService.dmsDocuments.add(originalVariant);
            }

            // update the variant tree cache
            parentBranch.children.splice(parentBranch.children.indexOf(variantBranch), 1);
            CacheManagerService.variantTreeData.add(variantTreeData);

            NotificationsService.success(successMsg);
        } catch (error) {
            NotificationsService.error(error, fallbackErrorMsg);
        }
    }

    /**
     * Return all the osids of all children of a variant tree branch.
     *
     * @param {object} variantBranch - The variant branch the child ids shall be extracted from.
     * @returns {string[]} - An array of the osids of the children.
     */
    function getChildIds(variantBranch) {
        let ids = [];

        for (let childBranch of variantBranch.children) {
            ids.push(childBranch.osid);
            ids = ids.concat(getChildIds(childBranch));
        }

        return ids;
    }

    /**
     * Extract a variant and its parent from a variant tree.
     *
     * @param {string} osid - The osid of the requested variant.
     * @param {object} variantBranch - The variant branch of the variant tree, that shall be traversed to find the variant.
     * @returns {{ parentBranch, variantBranch }} The found parent and variant branch.
     */
    function getVariantBranchWithParentBranch(osid, variantBranch) {
        for (let childBranch of variantBranch.children) {
            if (childBranch.osid == osid) {
                return { parentBranch: variantBranch, variantBranch: childBranch };
            }

            let branches = getVariantBranchWithParentBranch(osid, childBranch);
            if (branches != void 0) {
                return branches;
            }
        }
    }
}



