<div class="container">
    <div class="flex">
        <div class="modal-container-tabs">
            <ul class="nav nav-tabs">
                <li [ngClass]="{active : isQuickSearch}" (click)="showQuickSearch(true)">
                    <button class="button-transparent">
                        <h3>{{'modal.quickfinder.tabs.search.title' | ngTranslate}}</h3>
                    </button>
                </li>
                <li [ngClass]="{active : !isQuickSearch}" (click)="getSearchResultAsync()">
                    <button class="button-transparent">
                        <h3>{{'modal.quickfinder.tabs.result.title' | ngTranslate}}</h3>
                    </button>
                </li>
            </ul>
        </div>

        <div class="form-container" [hidden]="!isQuickSearch" [attr.lang]="showAltLang ? objectDefLang : null">
            <div class="form-wrapper">
                <eob-form [formdef]="formDef"></eob-form>
            </div>
        </div>
        <eob-hit-list class="modal-grid" *ngIf="isQuickResult" [hitlistconfig]="hitlistConfig" style="margin-top: 36px" [attr.lang]="showAltLang ? objectDefLang : null"></eob-hit-list>
        <div id="loadAnimation" class="osProgressbar invisible">
            <div class="osloader"></div>
        </div>

        <div class="button-container">
            <button (click)="close()" class="secondary-button">{{'modal.button.close' | ngTranslate}}</button>
            <button *ngIf="isQuickSearch" (click)="getSearchResultAsync()">{{'modal.button.search' | ngTranslate}}</button>
            <button *ngIf="!isQuickSearch" [ngClass]="{loading : isLoading}" (click)="applyEntryAsync()">{{'modal.button.submit' | ngTranslate}}</button>
        </div>
    </div>
</div>

