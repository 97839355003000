import {Component, Inject, OnInit, OnDestroy, Input} from "@angular/core";
import {ColorOption} from "MODULES_PATH/modal-dialog/interfaces/color-customization.interface";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ThemeService} from "MODULES_PATH/theme/theme.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";

@Component({
    selector: "eob-color-customization",
    templateUrl: "./color-customization.component.html",
    styleUrls: ["./color-customization.component.scss"]
})

export class ColorCustomizationComponent implements OnInit, OnDestroy {
    private readonly translate: TranslateFnType;

    @Input() persist = false;

    constructor(protected asIniService: AsIniService,
                protected themeService: ThemeService,
                @Inject("$filter") private $filter: ng.IFilterService
    ) {
        this.translate = $filter("translate");
    }

    colors: ColorOption[] = [];
    activeColor: string;
    initialActiveColor: string;

    ngOnInit(): void {
        this.colors= [{
            name: this.translate("color.customization.color.midnight"),
            hexValue: "#005873",//"#0092e1",
            isActive: false
        }, {
            name:  this.translate("color.customization.color.enaioblue"),
            hexValue: "#0092e1",
            isActive: false
        }, {
            name: this.translate("color.customization.color.rainforest"),
            hexValue: "#008e75",//"#00c3c1",
            isActive: false
        }, {
            name: this.translate("color.customization.color.terracotta"),
            hexValue: "#ef6927",
            isActive: false
        }, {
            name:  this.translate("color.customization.color.dragonfruit"),
            hexValue: "#e249bb",
            isActive: false
        }, {
            name: this.translate("color.customization.color.redwine"),
            hexValue: "#88002b",
            isActive: false
        }, {
            name: this.translate("color.customization.color.violet"),
            hexValue: "#671d9e", //"#a736c3",
            isActive: false
        }
        ];

        this.activeColor = this.themeService.getActiveAccentColor();
        this.initialActiveColor = this.activeColor;
        for (const color of this.colors) {
            if (color.hexValue == this.activeColor) {
                color.isActive = true;
                break;
            }
        }
    }

    /**
     * Changes the accent color fot the whole application.
     *
     * @param {object} param - A color object with a hexValue property.
     */
    changeColor = (param: ColorOption) => {
        this.activeColor = param.hexValue;

        for (const color of this.colors) {
            color.isActive = false;
            if (color.hexValue == this.activeColor) {
                color.isActive = true;
            }
        }

        this.themeService.setAccentColor(this.activeColor);
    };

    ngOnDestroy(): void {
        if(!this.persist) {
            this.themeService.setAccentColor(this.initialActiveColor);
        } else {
            this.asIniService.updateLayoutConfiguration({accentColor: this.activeColor});
        }
    }


}
