import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {Observable} from "rxjs";
import {BackendExternalTool} from "CORE_PATH/backend/interfaces/resource/backend-external-tool.interface";
import {BackendDashlet} from "CORE_PATH/backend/interfaces/resource/backend-dashlet.interface";
import {Dms2ResourceService} from "CORE_PATH/backend/modules/dms2/services/resource/dms2-resource.service";

@Injectable({providedIn: BackendModule})
export class BackendResourceService {
    /**
     * This backend service only uses DMS2 backend. There is no implementation for old OsRest.
     * In the future we will remove this from the DMS2 also and have a separate resource microservice.
     */
    constructor(private delegate: Dms2ResourceService) {
    }

    /**
     * Load a file from the service manager public configuration directory and
     * return the content of the file as it is.
     *
     * @param {string} url URL relative to /resources/apps/
     * @param {"json" | "arraybuffer" | "text"} responseType
     * @returns {Observable<any>}
     */
    getPublicResource(url: string, responseType: "json" | "arraybuffer" | "text" = "text"): Observable<unknown> {
        return this.delegate.getPublicResource(url, responseType);
    }

    /**
     * Loads the external tools json and return the defined external tools.
     * If the resource isn't available it return a empty array.
     *
     * @return {Observable<BackendExternalTool[]>}
     */
    getExternalTools(): Observable<BackendExternalTool[]> {
        return this.delegate.getExternalTools();
    }

    /**
     * Loads the dashlets.json and return the defined dashlets.
     * If the resource isn't available it return a empty array.
     *
     * @return {Observable<BackendDashlets[]>}
     */
    getDashlets(): Observable<BackendDashlet[]> {
        return this.delegate.getDashlets();
    }

    /**
     * Load a dashlet uri from the service manager public configuration directory and
     * return the content as text.
     *
     * @param {string} uri dashlet URI
     * @returns {Observable<string>}
     */
    getDashletUriContent(uri: string): Observable<string> {
        return this.delegate.getDashletUriContent(uri);
    }
}
