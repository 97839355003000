import {Injectable, OnDestroy} from "@angular/core";
import {HttpBackend, HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders} from "@angular/common/http";
import {AuthenticationData, AuthenticationProvider, AuthenticationState, AuthenticationStatusEvent, AuthenticationType} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {defer, from, Observable, of, throwError} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {AuthenticationModule} from "../authentication.module";
import {HttpHandler, HttpRequest} from "@angular/common/http";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {Profile, ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {Subject, Subscription} from "rxjs";
import {Inject} from "@angular/core";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {SessionInfo} from "INTERFACES_PATH/session-info.interface";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

enum AccountStatusAction {
    SUCCESS = 0,
    UNKNOWN_USER = 2,
    INCORRECT_PASSWORD_COOLDOWN = 3,
    INCORRECT_PASSWORD = 4,
    ACCOUNT_LOCKED = 5
}

interface AccountStatus {
    Action: AccountStatusAction;
    PwdExpires: number;
}

interface CombinedAccountStatus {
    accountStatus: AccountStatus;
    sessionInfo: SessionInfo;
    origin: string;
}

interface BasicAuthData {
    url: string;
    authToken?: string;
}

@Injectable({
    providedIn: AuthenticationModule
})
export class BasicAuthService implements AuthenticationProvider, OnDestroy {
    private httpClient: HttpClient;
    private basicAuthToken: string;
    private customStorage: CustomStorage;
    private subscriptions: Subscription = new Subscription();
    private backendOrigin: string;
    private authenticated = false;
    // expose as function to facilitate testing
    private readonly isLocalClient = () => !!(window.electron || window.cordova);
    private readonly defaultHeaders = new HttpHeaders().set("X-Requested-With", "XMLHttpRequest");
    statusEvent$: Subject<AuthenticationStatusEvent> = new Subject<AuthenticationStatusEvent>();

    constructor(handler: HttpBackend,
                customStorageService: CustomStorageService,
                private profileService: ProfileService,
                @Inject("fileCacheService") protected fileCacheService: FileCacheService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService) {
        this.httpClient = new HttpClient(handler);
        this.subscriptions.add(
            customStorageService.getStorage().subscribe(storage => {
                this.customStorage = storage;
            }));
    }

    isAuthenticated(): Observable<boolean> {
        // test, whether we are authenticated by looking into the last active profile
        // and start a test request with the basic auth token from the profile

        if (this.isLocalClient() && !this.profileService.hasProfiles()) {
            // no profiles, no authentication
            return of(false);
        }

        // first get the password for the last active profile key
        return from(this.customStorage.getSecureItem(`pw@@@${this.profileService.getLastActiveProfileKey()}`))
            .pipe(
                catchError(err => of("")),
                map(password => {
                    // then get the last active profile
                    const profile: Profile = this.profileService.getLastActiveProfile();
                    if (!profile) {
                        return null;
                    }
                    const basicAuthData: BasicAuthData = {url: profile.url ?? location.origin};

                    if(password) {
                        basicAuthData.authToken = `Basic ${btoa(unescape(encodeURIComponent(`${profile.username}:${password}`)))}`;
                    }
                    // and return auth data
                    return basicAuthData;
                }),
                switchMap((authData: BasicAuthData) => {
                    // if there was no last active profile, there will be no auth data
                    if (this.isLocalClient() && !authData) {
                        return of(false);
                    } else if(!this.isLocalClient()) {
                        authData = {url: location.origin};
                    }

                    let headers: HttpHeaders = this.defaultHeaders;
                    if(authData.authToken) {
                        headers = headers.set("Authorization", authData.authToken);

                    }

                    // do a test request with basic auth
                    return this._retrieveAccountStatusAndSession(authData.url, headers).pipe(
                        switchMap((session: CombinedAccountStatus) => {
                            if(session.accountStatus.PwdExpires == 0) {
                                this.authenticated = false;
                                this.basicAuthToken = null;
                                this.backendOrigin = null;
                                this.httpClient.get(`${authData.url}/_secure/logout/`, {responseType: "text"}).subscribe();
                                return of(false);
                            }
                            this.authenticated = true;
                            this.basicAuthToken = authData.authToken;
                            this.backendOrigin = authData.url;
                            const statusEvent: AuthenticationStatusEvent = {state: AuthenticationState.LOGGED_IN};
                            if(authData.authToken) {
                                statusEvent.requestHeaders = [{key: "Authorization", value: authData.authToken}];
                            }
                            this.statusEvent$.next(statusEvent);
                            return of(true);
                        }),
                        catchError((err: HttpErrorResponse) => {
                            this.authenticated = false;
                            this.basicAuthToken = null;
                            this.backendOrigin = null;
                            return of(false);
                        })
                    );
                }),
                catchError((err: HttpErrorResponse) => {
                    this.authenticated = false;
                    this.basicAuthToken = null;
                    this.backendOrigin = null;

                    return of(false);
                })
            );
    }

    authenticate(backendOrigin: string, authData?: AuthenticationData): Observable<AuthenticationStatusEvent> {
        let authToken: string;
        // Not introducing ClientService here to avoid DI hell
        if (!navigator.onLine) {
            return from(this.customStorage.getSecureItem(`pw@@@${this.profileService.buildProfileKey(backendOrigin, authData)}`)).pipe(
                catchError(err => of(null)),
                switchMap(persistedPassword => {
                    if (persistedPassword == authData.password) {
                        return of({state: AuthenticationState.LOGGED_IN});
                    } else {
                        return of({state: AuthenticationState.INVALID_LOGIN_DATA});
                    }
                })
            );
        }
        if (authData) {
            authToken = `Basic ${btoa(unescape(encodeURIComponent(`${authData.username}:${authData.password}`)))}`;
        } else if (this.basicAuthToken) {
            authToken = this.basicAuthToken;
            const creds: RegExpMatchArray = atob(this.basicAuthToken.split(" ")[1]).matchAll(/([^:]*):(.*)/g).next().value;
            authData = {
                username: creds[1],
                password: creds[2],
                authType: AuthenticationType.BASIC_AUTH
            };
        }

        let headers: HttpHeaders = new HttpHeaders();
        if (authToken) {
            headers = this.defaultHeaders.set("Authorization", authToken);
        }

        return this._retrieveAccountStatusAndSession(backendOrigin, headers).pipe(
            map((session: CombinedAccountStatus) => {
                if (session.accountStatus.PwdExpires == 0) {
                    this.httpClient.get(`${backendOrigin}/_secure/logout/`, {responseType: "text"}).subscribe();
                    return {state: AuthenticationState.PASSWORD_EXPIRED};
                }
                this.basicAuthToken = authToken;
                if(this.isLocalClient()) {
                    void this.customStorage.setSecureItem(`pw@@@${this.profileService.buildProfileKey(session.origin ?? backendOrigin, authData)}`, authData.password);
                }

                this.backendOrigin = backendOrigin;

                // set flag in session storage to bypass profile manager view
                sessionStorage.setItem("forceAutoLogin", "true");

                this.authenticated = true;
                this.statusEvent$.next({
                    state: AuthenticationState.LOGGED_IN,
                    requestHeaders: [{key: "Authorization", value: authToken}]
                });
                return {state: AuthenticationState.LOGGED_IN, userId: session.sessionInfo.userid, origin: session.origin};
            }),
            catchError((err: HttpErrorResponse) => {
                    console.info(err);
                    this.statusEvent$.next({state: AuthenticationState.INVALID_LOGIN_DATA});
                    this.authenticated = false;
                    if (err.status == 401) {
                        if (err.error.Action) {
                            switch (err.error.Action) {
                                case AccountStatusAction.UNKNOWN_USER:
                                case AccountStatusAction.INCORRECT_PASSWORD:
                                case AccountStatusAction.INCORRECT_PASSWORD_COOLDOWN:
                                default:
                                    return of({state: AuthenticationState.LOGIN_FAILED});
                            }
                        }
                        return of({state: AuthenticationState.LOGIN_FAILED});
                    } else {
                        return of({state: AuthenticationState.GENERAL_ERROR});
                    }
                }
            ));
    }

    invalidateSession(): Observable<AuthenticationStatusEvent> {
        return defer(async () => {
            sessionStorage.removeItem("forceAutoLogin");
            sessionStorage.removeItem("afterFirstLogin");
            sessionStorage.removeItem("randomSessionId");
            try {
                await this.httpClient.get(`${this.backendOrigin ?? ""}/_secure/logout/`, {headers: this.defaultHeaders.set("Authorization", this.basicAuthToken), responseType: "text"}).toPromise();
            } catch (_) {
                // ignored
            }
            this.basicAuthToken = "";
            this.authenticated = false;

            if (this.isLocalClient()) {
                await this.fileCacheService.removeTemporaryEntriesAsync();
                await this.fileCacheService.removeSessionDataAsync();

                const currentProfile: Profile = this.profileService.prepareCurrentProfile();

                if (currentProfile?.url && currentProfile?.authType) {
                    // autologin is not allowed in saved profiles after we logged out
                    if (currentProfile.autologin) {
                        currentProfile.autologin = false;
                        this.profileService.saveProfile(currentProfile);
                    }

                    // remove password from secure storage
                    try {
                        // if no secure storage element for the given id exists, the promise is rejected (at least on iOS)
                        await this.customStorage.removeSecureItem(`pw@@@${this.profileService.getProfileKeyFromProfile(currentProfile)}`);
                    } catch (_) {
                        // ignored
                    }
                }
            }
        }).pipe(
            switchMap(_ => {
                this.statusEvent$.next({state: AuthenticationState.LOGGED_OUT});
                return of({state: AuthenticationState.LOGGED_OUT});
            }));
    }

    getStatusEvent(): Observable<AuthenticationStatusEvent> {
        return this.statusEvent$.asObservable();
    }

    handleReauthenticationRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return from(this.customStorage.getSecureItem(`pw@@@${this.profileService.getLastActiveProfileKey()}`)).pipe(
            switchMap((password: string) => {
                const profile: Profile = this.profileService.getLastActiveProfile();
                if (!profile) {
                    return throwError("Last active profile missing");
                }
                const headerValue = `Basic ${btoa(unescape(encodeURIComponent(`${profile.username}:${password}`)))}`;
                return next.handle(req.clone({
                    headers: req.headers.set("Authorization", headerValue)
                })).pipe(tap((x: HttpEvent<any>) => {
                    if(x.type == HttpEventType.Response) {
                        this.statusEvent$.next({
                            state: AuthenticationState.SESSION_REFRESHED,
                            requestHeaders: [{key: "Authorization", value: headerValue}]
                        });
                        this.authenticated = true;
                    }
                }));
            }),
            catchError((refreshTokenError) => {
                this.statusEvent$.next({state: AuthenticationState.LOGGED_OUT});
                this.authenticated = false;
                console.error(refreshTokenError);
                return throwError(refreshTokenError);
            })
        );
    }

    private _retrieveAccountStatusAndSession(backendOrigin: string, headers: HttpHeaders): Observable<CombinedAccountStatus> {
        return this.httpClient.get<AccountStatus>(`${backendOrigin}/accountStatus`, {
            headers,
            responseType: "json",
            observe: "response"
        }).pipe(
            switchMap(accountStatus => {
                const origin = new URL(accountStatus.url).origin;
                if(accountStatus.body?.PwdExpires != 0) {
                    return this.httpClient.get<SessionInfo>(`${backendOrigin}/osrest/api/session`, {
                        headers,
                        responseType: "json"
                    }).pipe(map(sessionInfo => ({accountStatus: accountStatus.body ?? {Action: 0, PwdExpires: -1}, sessionInfo, origin} as CombinedAccountStatus)));
                } else {
                    return of({accountStatus: accountStatus.body, origin} as CombinedAccountStatus);
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if(error.status == 404) {
                    const origin = new URL(error.url).origin;
                    return this.httpClient.get<SessionInfo>(`${backendOrigin}/osrest/api/session`, {
                        headers,
                        responseType: "json"
                    }).pipe(map(sessionInfo => ({accountStatus: {Action: 0, PwdExpires: -1}, sessionInfo, origin} as CombinedAccountStatus)));
                } else {
                    return throwError(error);
                }
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
