/**
 * Hitlist broadcast names to be used with the message service
 */
export enum HitlistEvent {
    UPDATE_FILTER_QUERY = "UPDATE_FILTER_QUERY",
    UPDATE_ROWS = "UPDATE_ROWS",
    UPDATE_ROWS_DONE = "UPDATE_ROWS_DONE",
    ITEM_ADDED = "ITEM_ADDED",
    REFRESH_GROUPING_TAGS = "REFRESH_GROUPING_TAGS",
    RESTORE_SELECTION = "RESTORE_SELECTION",
    UPDATE_GRID_CONTAINER = "UPDATE_GRID_CONTAINER",
    SHOW_EMPTY_SPACE_CONTEXTMENU = "SHOW_EMPTY_SPACE_CONTEXTMENU",
    UPDATE_VIEWER = "HITLIST_UPDATE_VIEWER"
}
