(function() {
    "use strict";

    const marked = require("marked");

    angular.module("eob.framework").directive("markdown", DvMarkedDirective);

    DvMarkedDirective.$inject = ["$sanitize"];

    function DvMarkedDirective($sanitize) {
        return {
            restrict: "A",
            replace: true,
            scope: {
                markdown: "="
            },
            link(scope, elem) {
                scope.$watch("markdown", set);

                function set(text) {
                    try {
                        text = $sanitize(text);
                    } catch (error) {
                        // Catch sanizite failure and escale HTML completely
                        text = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
                    }
                    elem.html(parse(text).replace(/&#10;/g, "<br />"));
                }

                initMarkdown();

                function initMarkdown() {
                    marked.setOptions({
                        gfm: true,
                        breaks: true
                    });

                    // remove numbered lists from list regex
                    let lexer = new marked.Lexer();
                    lexer.rules.list = /^( *)((?:[*+-])) [\s\S]+?(?:\n+(?=\1?(?:[-*_] *){3,}(?:\n+|$))|\n+(?= *\[([^\]]+)\]: *<?([^\s>]+)>?(?: +["(]([^\n]+)[")])? *(?:\n+|$))|\n{1,}(?! )(?!\1(?:[*+-]) )\n*|\s*$)/;
                    lexer.rules.paragraph = /^((?:[^\n]+\n?(?! *(`{3,}|~{3,})[ \.]*(\S+)? *\n([\s\S]*?)\s*\2 *(?:\n+|$)|( *)((?:[*+-])) [\s\S]+?(?:\n+(?=\3?(?:[-*_] *){3,}(?:\n+|$))|\n+(?= *\[([^\]]+)\]: *<?([^\s>]+)>?(?: +["(]([^\n]+)[")])? *(?:\n+|$))|\n{2,}(?! )(?!\1(?:[*+-]) )\n*|\s*$)|( *[-*_]){3,} *(?:\n+|$)| *(#{1,6}) *([^\n]+?) *#* *(?:\n+|$)|([^\n]+)\n *(=|-){2,} *(?:\n+|$)|( *>[^\n]+(\n(?! *\[([^\]]+)\]: *<?([^\s>]+)>?(?: +["(]([^\n]+)[")])? *(?:\n+|$))[^\n]+)*\n*)+|<(?!(?:a|em|strong|small|s|cite|q|dfn|abbr|data|time|code|var|samp|kbd|sub|sup|i|b|u|mark|ruby|rt|rp|bdi|bdo|span|br|wbr|ins|del|img)\b)\w+(?!:\/|[^\w\s@]*@)\b|def))+)\n*/;
                }

                function parse(text) {
                    text = text || "";
                    return marked(text);
                }
            }
        };
    }
})();