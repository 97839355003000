import {AfterContentInit, Directive, ElementRef, EventEmitter, HostListener, Output} from "@angular/core";

@Directive({
    selector: "[dragDrop]"
})
export class DragDropDirective implements AfterContentInit {
    @Output() onFileDropped: EventEmitter<any> = new EventEmitter<any>();
    private dropzoneFilesDiv: Element;
    constructor(private el: ElementRef<Element>) {
    }

    ngAfterContentInit(): void {
        this.dropzoneFilesDiv = this.el.nativeElement.querySelector("div.dropzone-files");
    }

    // Dragover listener
    @HostListener("dragover", ["$event"])
    onDragOver(evt: MouseEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.el.nativeElement.classList.add("hover");
        this.dropzoneFilesDiv?.classList.add("dz-drag-hover");
    }

    // Dragleave listener
    @HostListener("dragleave", ["$event"])
    onDragLeave(evt: MouseEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.el.nativeElement.classList.remove("hover");
        this.dropzoneFilesDiv?.classList.remove("dz-drag-hover");

    }

    // Drop listener
    @HostListener("drop", ["$event"])
    ondrop(evt: any): void {
        // evt is of type DragEvent, but specifying the type breaks build for iOS. Should be investigated.
        evt.preventDefault();
        evt.stopPropagation();
        const files: FileList = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files);
        }

        this.el.nativeElement.classList.remove("hover");
        this.dropzoneFilesDiv?.classList.remove("dz-drag-hover");

        this.el.nativeElement.classList.add("drop");

        setTimeout(() => {
            this.el.nativeElement.classList.remove("drop");
        }, 500);
    }
}
