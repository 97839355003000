import {ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {delay, first} from "rxjs/operators";
import {StateService} from "@uirouter/core";
import {OfflineService} from "SERVICES_PATH/offline/eob.offline.srv";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {OfflineSyncStatus} from "SERVICES_PATH/offline/offline.sync.status.model";
import {AsIniHitlistConfiguration} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {HitlistConfig} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {OverallSyncStatus} from "ENUMS_PATH/offline/offline-global-sync-status.enum";
import {OfflineSynchronizationMessage} from "ENUMS_PATH/offline/offline-synchronization-message.enum";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {TodoEnvironmentService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";

const dayjs = require("dayjs");

interface LastSyncInfo {
    userId: string;
    lastSync: string;
}

@Component({
    selector: "eob-hitlist-footer",
    templateUrl: "./eob-hitlist-footer.component.html",
    styleUrls: ["./eob-hitlist-footer.component.scss"]
})
export class EobHitlistFooterComponent implements OnInit, OnDestroy {
    @Input() hitlistconfig: HitlistConfig;

    hitListSettings: AsIniHitlistConfiguration;

    readonly sub: Subscription = new Subscription();
    readonly translateFn: TranslateFnType;

    constructor(private messageService: MessageService,
                private clientService: ClientService,
                private asIniService: AsIniService,
                @Inject("$state") private $state: StateService,
                @Inject("offlineService") protected offlineService: OfflineService,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                @Inject("stateHistoryManager") protected stateHistoryManager: TodoStateHistoryManager,
                private el: ElementRef<HTMLElement>,
                private changeDetection: ChangeDetectorRef) {
        this.translateFn = this.$filter("translate");
    }

    ngOnInit(): void {
        this.hitListSettings = this.asIniService.getHitlistConfiguration(this.stateHistoryManager.getCurrentStateData().data);

        if (["hitlist.offlineObjects", "hitlist.failedSyncObjects"].some(state => this.$state.current.name == state)) {
            this.sub.add(this.messageService.subscribe(OfflineSynchronizationMessage.GLOBAL_SYNC_STATUS_CHANGED, (offlineSyncStatus: OfflineSyncStatus) => {
                this.getOfflineObjectsFooterInfo(offlineSyncStatus);
            }));
        }

        this.sub.add(this.messageService.subscribe("hitlist.ready", () => {
            if (this.hitlistconfig) {
                this.updateHitlistFooter();
            }

        }, first(), delay(500)));
    }

    private updateHitlistFooter(): void {
        this.hitlistconfig.totalRowsCount = this.hitlistconfig.api.getRows().length;

        if (["hitlist.offlineObjects", "hitlist.failedSyncObjects"].some(state => this.$state.current.name == state)) {
            this.getOfflineObjectsFooterInfo(this.offlineService.getSyncStatus());

            if (this.clientService.isPhone()) {
                const span: HTMLSpanElement = this.el.nativeElement.querySelector(".hitlist-footer-row-count");
                span.classList.add("offline-objects-phone");
            }
        }

        if (this.hitListSettings && this.hitListSettings.type == "folderFlat" && this.clientService.isOffline()) {
            this.hitlistconfig.footerIcon = "icon-24-warning";
            this.hitlistconfig.footerInformation = this.translateFn("eob.hitlist.footer.offline.maybe.incomplete");
        }

        this.changeDetection.detectChanges();
    }

    private getOfflineObjectsFooterInfo(offlineSyncStatus: OfflineSyncStatus): void {
        let info: string, icon: string;
        switch (offlineSyncStatus.status) {
            case OverallSyncStatus.RUNNING:
                info = this.translateFn("eob.sync.offline.running");
                info = info.replace("[%s1]", (offlineSyncStatus.percentage * 100).toFixed(1));
                icon = "icon-24-synchronize-footer";
                break;
            case OverallSyncStatus.ABORTING_QUOTA:
            case OverallSyncStatus.ABORTING_USER:
            case OverallSyncStatus.ABORTING_OFFLINE:
                info = this.translateFn("eob.sync.offline.aborting");
                icon = "icon-24-synchronize-footer";
                break;
            case OverallSyncStatus.ABORTED:
                info = offlineSyncStatus.abortStatus == OverallSyncStatus.ABORTING_QUOTA ? this.translateFn("eob.sync.offline.aborted.quota") : this.translateFn("eob.sync.offline.aborted");
                icon = "icon-24-warning";
                break;
            case OverallSyncStatus.FAILED:
                info = this.translateFn("eob.sync.offline.error");
                icon = "icon-24-warning";
                break;
            case OverallSyncStatus.CLEAR_CACHE:
                info = this.translateFn("eob.sync.offline.clear.cache");
                icon = "icon-24-synchronize-footer";
                break;
            case OverallSyncStatus.NONE:
                info = this.getOfflineSyncInfo();
                icon = undefined;
                break;
            default:
                info = this.translateFn("eob.sync.offline.done");
                icon = "icon-24-hitlist-footer-complete";
        }

        this.hitlistconfig.footerInformation = info;
        this.hitlistconfig.footerIcon = icon;

        this.changeDetection.detectChanges();
    }

    private getOfflineSyncInfo(): string {
        let result: string;
        const lastSyncInfo: LastSyncInfo = JSON.parse(localStorage.getItem("lastSync"));

        if (lastSyncInfo && this.environmentService.getSessionInfo().userid == lastSyncInfo.userId) {
            const format: string = this.environmentService.env.dateFormat.datetime;
            const date: string = dayjs(lastSyncInfo.lastSync).format(format);

            result = this.translateFn("eob.sync.last.time") + date;
        } else {
            result = this.clientService.isPhone() ? this.translateFn("eob.sync.last.time.no.phone") : this.translateFn("eob.sync.last.time.no");
        }
        return result;
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
