<div class="checkbox"
     title="{{ tooltip || title }}"
     eobDisplayBubble
     [bubble]="customBubble || bubble"
     [host]="checkbox"
     [control]="control"
     [ngClass]="{'disabled': control.disabled}"
     (keydown.enter)="onEnter($event)"
     (keydown.space)="onToggle($event)"
     (click)="onToggle($event)">
    <div #checkbox class='custom-checkbox' role="checkbox">
        <eob-icon *ngIf="checkboxState !== ''" [name]="checkboxState" size="16"></eob-icon>
    </div>
    <eob-icon *ngIf="icon" class="checkbox-img" [name]="icon" size="16"></eob-icon>
    <label *ngIf="!hideLabel" class='checkbox-label' [id]="fieldid"
           [attr.lang]="showAltLang ? objectDefLang : null">{{title}}</label>
</div>
<eob-standalone-validation-bubble #bubble></eob-standalone-validation-bubble>
