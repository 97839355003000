<div class="profile-manager-container" *ngIf="showProfileManager">
    <eob-profile-manager-banner></eob-profile-manager-banner>

    <!-- start view without profile dropdown and input fields, but with goto-login and demo button -->
    <eob-welcome-page
        [hidden]="profileManagerService.showLoginForm"
        (triggerAbortLogin)="abortLogin()"
        (triggerLoginToDemo)="loginToDemo()">
    </eob-welcome-page>

    <!-- profile view with profile dropdown, input fields and login button -->
    <eob-change-password
        *ngIf="profileManagerService.showPasswordExpiredForm"
        [usedDuringLogin]="true"
        [displayBackButton]="true"
        (back)="profileManagerService.togglePasswordExpiredForm()"
        (passwordChanged)="passwordChanged($event)">
    </eob-change-password>

    <eob-login
        [hidden]= "!profileManagerService.showLoginForm || profileManagerService.showPasswordExpiredForm"
        (triggerLogin)="login()"
        (triggerAbortLogin)="abortLogin()">
    </eob-login>
</div>
