/**
 * The used IndexedDB database
 */
export enum DatabaseType {
    /**
     * Global store persisted indepedendently of the current profile
     */
    GLOBAL = "global",

    /**
     * Profile specific store
     */
    PROFILE = "profile"
}