import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {from, Observable, of, Subject, Subscription} from "rxjs";
import {throttleTime} from "rxjs/operators";
import {HitlistConfig} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";

export interface KebabMenuAction {
    icon: string;
    alt: string;
    title: string;
    callback: any;
    context?: string;
    event?: Event;
    hitlistConfig?: HitlistConfig;
}

@Component({
    selector: "eob-kebab-menu",
    templateUrl: "./state-kebab-menu.component.html",
    styleUrls: ["./state-kebab-menu.component.scss"]
})
export class EobKebabMenuComponent implements OnInit, OnDestroy {
    @Input() menuItems: KebabMenuAction[];
    @Input() menuItemsCallbackAsync: any;

    dropdownOpen: boolean = false;
    menuToggler: Subject<void> = new Subject<void>();
    subscriber: Subscription = new Subscription();
    stateMenuItems$: Subject<KebabMenuAction[]> = new Subject<KebabMenuAction[]>();
    canExecuteCallbackAction: boolean = false;

    constructor(private messageService: MessageService, private el: ElementRef, private ngZone: NgZone) {
    }

    ngOnInit(): void {
        this.subscriber.add(this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, (event: Event) => {
            const isMenuItemWithSubmenu: boolean = event && this.el.nativeElement.contains(event.target) &&
                (event.target as HTMLElement).closest("li") &&
                (event.target as HTMLElement).closest("li").getAttribute("hassubmenu") == "true";

            if (this.dropdownOpen && !isMenuItemWithSubmenu) {
                this.toggleMenu();
            }
        }));
        // Since it's sometimes raining closing events, we need to throttle them a bit, as the menu would otherwise be closed before even becoming visible
        this.menuToggler.pipe(throttleTime(50)).subscribe(_ => this._toggleMenu());
    }

    ngOnDestroy(): void {
        this.subscriber.unsubscribe();
        this.menuToggler.complete();
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    async toggleMenu(): Promise<void> {
        this.ngZone.run(() => this.menuToggler.next());
    }

    executeAction(item: KebabMenuAction, event: Event): void {
        if (this.canExecuteCallbackAction) {
            item.event = event;
            event.preventDefault();
            event.stopImmediatePropagation();
            item.callback(item);

            if (item.context !== "emptySpaceInHitlist") {
                this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS, event);
            }
        }
    }

    hideContextMenuOnMouseEnter(): void {
        const submenuContainer: HTMLElement = document.querySelector("div.submenu-container");
        submenuContainer.style.display = "none";
    }

    private _updateMenuItems(): Observable<KebabMenuAction[]> {
        if (typeof this.menuItemsCallbackAsync == "function") {
            return from(this.menuItemsCallbackAsync() as Promise<KebabMenuAction[]>);
        } else {
            return of(this.menuItems);
        }
    }

    private _toggleMenu(): void {
        if (!this.dropdownOpen) {
            this._updateMenuItems().subscribe(x => {
                this.stateMenuItems$.next(x);
            });

            // #eob-kebab-menu-dropdown has transition(.35s) property,
            // therefore timeout to prevent opening the context-menu on mouseenter in folder state
            setTimeout(() => {
                this.canExecuteCallbackAction = true;
            }, 350);
        } else {
            this.canExecuteCallbackAction = false;
        }

        this.dropdownOpen = !this.dropdownOpen;
    }
}
