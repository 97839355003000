    const dayjs = require("dayjs");

    angular.module("eob.framework").directive("eobWorkflowInfo", EobWorkflowInfo);

    EobWorkflowInfo.$inject = ["$stateParams", "backendService", "formHelper", "$filter", "$state",
        "notificationsService", "environmentService", "kebabMenuService", "stateHistoryManager",
        "clientService", "viewerService", "messageService"];

    /**
     * State that shows the details of a workflow process.
     */
    function EobWorkflowInfo($stateParams, BackendService, FormHelper, $filter, $state,
                             NotificationsService, EnvironmentService, KebabMenuService, StateHistoryManager,
                             ClientService, ViewerService, MessageService) {
        return {
            restrict: "E",
            async link(scope) {

                let stateData = StateHistoryManager.getCurrentStateData().data;
                let backendData;
                let kebabMenuData;

                scope.areaToggleStates = {
                    details: true,
                    users: false,
                    responsible: false,
                    protocol: true
                };

                /**
                 * Update the given process data.
                 * @param {Object} e - The broadcast event.
                 * @param {Object} changedData - A map with the data, that has to be updated.
                 */
                MessageService.subscribe("workflow.info.update", (e, changedData) => {
                    if (changedData.personalizedBy != void 0) {
                        scope.personalizedBy = stateData.personalized = kebabMenuData.personalizedBy = changedData.personalizedBy;
                    }
                });

                /**
                 * Reload the backend data.
                 */
                MessageService.subscribe("workflow.info.refresh", async () => {
                    await initBackendDataAsync();
                    scope.$apply();
                });

                await initBackendDataAsync();
                init();

                /**
                 * Get and set the process data from the backend.
                 * @returns {Promise}
                 */
                async function initBackendDataAsync() {
                    try {
                        let response = await BackendService.get(`/workflows/running/processes/${$stateParams.processId}/${$stateParams.activityId}?outputUsersOnly=true`);
                        backendData = response.data;
                        backendData.inboxUsers = backendData.inboxUsers || [];

                        backendData.inboxUsers.sort(sortUserLists);
                        backendData.responsibleUsers.sort(sortUserLists);

                        if (stateData.personalized) {
                            scope.inboxUsers = backendData.inboxUsers.filter(x => x.name.toLowerCase() == stateData.personalized.toLowerCase());
                        } else {
                            scope.inboxUsers = backendData.inboxUsers;
                        }

                        scope.responsibleUsers = backendData.responsibleUsers;

                        if (backendData.objectId) {
                            ViewerService.updateViewer(backendData.objectId);
                        } else {
                            ViewerService.clearViewer();
                        }
                    } catch (error) {
                        if (error.status == 404) {
                            NotificationsService.error($filter("translate")("eob.running.process.load.failed"));
                        }
                        if(ClientService.isOnline()) {
                            $state.go("hitlist.inbox", {type: "processes"});
                        } else {
                            ClientService.executeStateErrorFallback();
                        }
                    }
                }

                function sortUserLists(userA, userB) {
                    return userA.name.localeCompare(userB.name);
                }

                /**
                 * Initialize the state.
                 */
                function init() {
                    scope.stateDescription = stateData.processName;
                    scope.activityName = stateData.activityName;
                    scope.subject = stateData.subject;
                    scope.startedAt = dayjs(new Date(parseFloat(stateData.creationTime))).format(EnvironmentService.env.dateFormat.datetime);
                    scope.reminderTime = (stateData.warningTime == "" || stateData.warningTime == void 0) ? "" : dayjs(new Date(parseFloat(stateData.warningTime))).format(EnvironmentService.env.dateFormat.datetime);
                    scope.isOvertime = stateData.overTime ? $filter("translate")("eob.objecttype.checkbox.trueval") : $filter("translate")("eob.objecttype.checkbox.falseval");
                    scope.personalizedBy = stateData.personalized;

                    kebabMenuData = {
                        activityId: $stateParams.activityId,
                        processId: $stateParams.processId,
                        personalizedBy: stateData.personalized
                    };

                    scope.menuItems = KebabMenuService.getWorkflowInfoMenuItems(kebabMenuData);
                    scope.formHelper = FormHelper.getFormHelper({isView: true});

                    // Protokoll Grid
                    let columnDefs = [{
                        headerName: $filter("translate")("eob.running.process.activity"),
                        field: "activityName",
                        cellRenderer: cellRendererActivity,
                    },{
                        headerName: $filter("translate")("eob.running.process.activity.state"),
                        field: "state",
                        cellRenderer: cellRendererState,
                    },{
                        headerName: $filter("translate")("eob.running.process.activity.started"),
                        field: "creationTime",
                        cellRenderer: cellRendererTimeStamp,
                    },{
                        headerName: $filter("translate")("eob.running.process.activity.access"),
                        field: "accessTime",
                        cellRenderer: cellRendererTimeStamp,
                    },{
                        headerName: $filter("translate")("eob.running.process.activity.ended"),
                        field: "endTime",
                        cellRenderer: cellRendererTimeStamp,
                    }];

                    scope.gridOptions = {
                        defaultColDef: {
                            sortable: true,
                            resizable: true,
                            filter: true,
                            suppressMenu: true
                        },
                        columnDefs,
                        rowData: backendData.protocolEntries,
                        groupUseEntireRow: true,
                        rowHeight: ClientService.isTouchDevice() ? 40 : 32,
                        headerHeight: ClientService.isTouchDevice() ? 40 : 32,
                        suppressDragLeaveHidesColumns: true,
                        suppressContextMenu: true,
                        suppressNoRowsOverlay: true,
                        rowBuffer: 20,
                        onGridSizeChanged: sizeToFit
                    };

                    scope.ready = true;
                    scope.$apply();
                }

                function sizeToFit() {
                    if (scope.gridOptions.api != void 0) {
                        scope.gridOptions.api.sizeColumnsToFit();
                    }
                }

                function cellRendererActivity(cell) {
                    // we want to wrap the cell value into a span thats why we need the cell renderer
                    if (cell.value == void 0) {
                        return "";
                    }
                    return cell.value;
                }

                function cellRendererTimeStamp(cell) {
                    if (cell.value == void 0) {
                        return "";
                    }

                    return dayjs(new Date(cell.value)).format(EnvironmentService.env.dateFormat.datetime);
                }

                function cellRendererState(cell) {
                    return $filter("translate")(`eob.running.process.activity.state.${cell.value}`);
                }

                scope.$on("$destroy", () => {
                    if (scope.formHelper != void 0) {
                        scope.formHelper.destroy();
                    }
                });
            }
        }
    }
