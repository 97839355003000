<!-- first-level context-menu -->
<div [ngStyle]="contextMenuStyle" #contextMenuContainer class="context-menu main-menu">
    <eob-context-menu-template
        [type]="type"
        [contextMenuActions]="contextMenuActions"
        [headerContent]="headerContent"
        (onClose)="close($event)" (onOpenSubmenu)="openSubmenu($event)">
    </eob-context-menu-template>
</div>

<div class="submenus-container" #submenus>
    <!-- first-level submenu -->
    <eob-submenu [headerContent]="headerContent" [hasSubmenu]="has2ndSubmenu"></eob-submenu>

    <!-- second-level submenu -->
    <eob-submenu *ngIf="has2ndSubmenu" [is2ndsubmenu]="has2ndSubmenu"></eob-submenu>
</div>

<!-- phone overlay -->
<div *ngIf="isPhone && isMenuVisible" class="overlayContextMenu" (click)="close($event)"></div>