(function () {
    

    require("COMPONENTS_PATH/eob-detailsviewer/components/dv-edit-comment/dv.edit.comment.dir.js");
    require("COMPONENTS_PATH/eob-detailsviewer/components/dv-comment/dv.comment.dir.js");

    angular.module("eob.framework").directive("dvNotes", DvNotes);

    DvNotes.$inject = ["$rootScope", "$log", "dvNotesService", "environmentService", "messageService"];

    function DvNotes($rootScope, $log, DvNotesService, EnvironmentService, MessageService) {
        return {
            restrict: "E",
            template: require("!raw-loader!./dv.notes.html"),
            link(scope, element) {
                scope.notes = [];
                scope.noteType = 1;
                scope.noteText = "";
                scope.noteTextMax = 4000;
                scope.ready = false;

                let newNoteDisabled = false;
                let editNotesDisabledByRole = false;
                let emptyStateElement = element.find(".no-notes");
                let addNoteButton = element.find(".add-note-button");
                let notesContainer = element.find(".notes-container");
                let emptyStateButtonElement = emptyStateElement.find(".add-first-note-button");

                scope.initNewNote = initNewNote;
                scope.endEditMode = endInitNewNote;
                scope.refreshNotesData = refreshData;

                emptyStateElement.hide();
                notesContainer.hide();

                if (EnvironmentService.userHasRole("R_CLNT_VIEWNOTES")) {
                    if (!EnvironmentService.userHasRole("R_CLNT_NOTES")) {
                        scope.editDisabled = editNotesDisabledByRole = true;
                        addNoteButton.hide();
                        emptyStateButtonElement.hide();
                    }

                    scope.$on("changeObject", changeObject);
                    MessageService.subscribe("clear.viewer", clear);
                    scope.$on("$destroy", DvNotesService.clearRegisteredNotes());
                    scope.$on("dv.notes.updated", refreshData);

                    DvNotesService.registerNewNoteContainer(toggleNewNote);
                    getNotes();
                }

                function getNotes(){
                    let osid = scope.objectId;
                    DvNotesService.load(osid).then((res) => {
                        if (osid === scope.objectId) {
                            scope.notes = res.notesData;
                        }
                        toggleEmptyPlaceholder();
                        scope.ready = true;
                    }, (reason) => {
                        // NotificationsService.error()
                    });
                    ;
                }

                function toggleEmptyPlaceholder(){
                    if (scope.notes.length || scope.newMode) {
                        notesContainer.show();
                        emptyStateElement.hide();
                    } else {
                        notesContainer.hide();
                        emptyStateElement.show();
                    }
                }

                function refreshData() {
                    DvNotesService.clearRegisteredNotes();
                    let oldCount = scope.notes.length;
                    let newCount = DvNotesService.data.notesData.length;
                    //set new noteData
                    scope.notes = DvNotesService.data.notesData;
                    endOpenInitMode();
                    if (oldCount != newCount) {
                        $rootScope.noteSize = newCount;
                        if (scope.ready && oldCount !== newCount) {
                            $rootScope.$broadcast("dv.notesChanged", {
                                id: scope.objectId,
                                notesCount: newCount
                            });
                        }
                    }
                }

                function initNewNote() {
                    if (!newNoteDisabled && !editNotesDisabledByRole) {
                        toggleInitMode(true);
                    }
                }

                function endInitNewNote() {
                    toggleInitMode(false);
                }

                function toggleInitMode(OnInitMode) {
                    DvNotesService.switchEditModeOnRegisteredNotes(0, !OnInitMode);
                    scope.newMode = OnInitMode;
                    toggleNewNote(!OnInitMode);
                }

                function toggleNewNote(enableInit) {
                    newNoteDisabled = !enableInit;
                    toggleEmptyPlaceholder();
                }

                function endOpenInitMode() {
                    scope.newMode = false;
                    toggleNewNote(true);
                }

                function changeObject() {
                    notesContainer.hide();
                    DvNotesService.clearRegisteredNotes();
                    endOpenInitMode();
                    getNotes();
                }

                function clear() {
                    endOpenInitMode();
                    DvNotesService.clearRegisteredNotes();
                    scope.notes = [];
                }
            }
        };
    }
})();
