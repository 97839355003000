import {Component, Inject, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {TodoExternalTrayService} from "INTERFACES_PATH/any.types";
import {ExternalTrayItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ContextMenuService} from "MODULES_PATH/context-menu/services/context-menu.service";
import {Subscription} from "rxjs";
import {EobPanelComponent} from "MODULES_PATH/navigation/components/eob-panel/eob-panel.component";
import {NavigationDirection} from "MODULES_PATH/navigation/enums/navigationDirection.enum";
import {ExternalTrayEvents} from "MODULES_PATH/external-tray/enums/external-tray-events.enum";


@Component({
    selector: "eob-nav-external-tray-elements",
    templateUrl: "./eob-nav-external-tray.component.html",
    styleUrls: ["./eob-nav-external-tray.component.scss"]
})
export class EobNavExternalTrayComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChildren(EobPanelComponent) panels!: QueryList<EobPanelComponent>;
    private readonly translate: TranslateFnType;
    ready: boolean = false;
    items: any[];
    panelHeading: string;
    private subs: Subscription = new Subscription();

    constructor(@Inject("$rootScope") private $rootScope: RootScope,
                @Inject("$filter") private $filter: ng.IFilterService,
                @Inject("externalTrayService") private externalTrayService: TodoExternalTrayService,
                private messageService: MessageService,
                private navigationService: NavigationService,
                private contextMenuService: ContextMenuService,
                private notificationsService: NotificationsService,
                private cdRef: ChangeDetectorRef
    ) {
        this.translate = this.$filter("translate");
    }

    ngOnInit(): void {
        this.panelHeading = this.translate("nav.pane.title.external.tray");
        this.contextMenuService.registerContextMenuActionsProvider("externaltray");
        this.setItemsAsync();
    }

    ngAfterViewInit(): void {
        this.$rootScope.$on("external.tray.elements.changed", async () => {
            await this.setItemsAsync();
        });
        this.subs.add(this.messageService.subscribe(ExternalTrayEvents.TRAY_ELEMENTS_CHANGED, () => {
            void this.setItemsAsync();
        }));

        if (this.navigationService.isFixed) {
            this.panels.first?.setTabReachable(true);
        }

        this.subs.add(this.navigationService.focusFirstPanel(this.panels, "externaltray"));
    }

    showMenu(item: ExternalTrayItem, event: MouseEvent): void {
        this.messageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
            items: [item],
            event,
            contextData: {
                context: "externaltray",
                title: item.displayTitle
            }
        });
    }

    passFocus(senderSide: NavigationDirection, senderID: number): void {
        this.navigationService.passFocus(this.panels, senderSide, senderID);
    }

    openItem(item: ExternalTrayItem): void {
        if (item.fileNames.length === 1) {
            this.externalTrayService.openTrayElementAsync(item);
        } else {
            this.notificationsService.info((this.translate("eob.action.external.tray.open.multiple.not.supported")));
        }
    }

    async setItemsAsync(): Promise<void> {
        this.items = await this.externalTrayService.getExternalTrayItemsAsync();
        this.ready = true;
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.contextMenuService.unregisterContextMenuActionsProvider("externaltray");
        this.subs.unsubscribe();
    }

}
