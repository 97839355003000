(function () {

    angular.module("eob.core").factory("variantModelService", VariantModelService);

    VariantModelService.$inject = ["$injector"];

    /* eslint-disable */
    /**
     * Create a variant object from backend data.
     */
    function VariantModelService($injector) { /* eslint-enable */
        return {
            createVariantData: (...args) => {
                return new VariantData(...args);
            },
            createVariantTreeData: (backendVariants) => {
                return new VariantTreeData(backendVariants);
            }
        };

        /**
         * Constructor for the specific variant data of one dms document.
         *
         * @param {object} backendData - The backend data for one or more variants.
         * @param {string=} variantId - The osid of the variant for which the data will be created. This is necessary if the backend data contains more than one variant data.
         */
        function VariantData(backendData, variantId) {
            let self = this;

            // backend data comes from the variants call
            if (backendData.variantInfo != void 0) {
                backendData = backendData.variantInfo;

                self.model = {
                    originalVariantId: backendData.originalVariantId,
                    isActive: backendData.active,
                    version: backendData.version,
                    originVersion: backendData.originVersion,
                    type: getType(backendData.version)
                };
            }
            // backend data comes from any kind of search call, that requested variant data additionally
            else if (backendData.variantTree != void 0) {
                backendData = backendData.variantTree;

                const CacheManagerService = $injector.get("cacheManagerService");
                CacheManagerService.variantTreeData.add(backendData);

                let idVersionMap = {},
                    variantData = extractVariantData(variantId, backendData, idVersionMap);

                // It appears, that it is possible, that a dms document has variants, but is not part of them. How?
                if (variantData != void 0) {
                    let info = variantData.variantInformation,
                        originalVariantId;

                    for (let id in idVersionMap) {
                        let version = idVersionMap[id];

                        if (version == "Original") {
                            originalVariantId = id;
                            break;
                        }
                    }

                    self.model = {
                        originalVariantId,
                        isActive: info.IS_ACTIVE.value == "1",
                        version: info.DOCUMENT_VERSION.value,
                        originVersion: info.originVersion,
                        type: getType(info.DOCUMENT_VERSION.value)
                    };
                }
            }
        }

        /**
         * Traverse the backend data and extract the necessary part for the requested id.
         *
         * @param {string} osid - The osid of the variant, data shall be extracted for.
         * @param {object} variantBranch - The variant tree branch that shall be searched (deep).
         * @param {object} idVersionMap - A conveniance id to version map to find out the original version id of the requested variant.
         * @returns {object|undefined} The backend data for the requested variant or null, if it wasn't found.
         */
        function extractVariantData(osid, variantBranch, idVersionMap) {
            let info = variantBranch.variantInformation,
                id = info["DOCUMENT_ID"].value,
                version = info["DOCUMENT_VERSION"].value,
                originId = info["DOCUMENT_PARENT_ID"].value;

            idVersionMap[id] = version;

            if (osid == id) {
                info.originVersion = idVersionMap[originId];
                return variantBranch;
            }

            for (let child of variantBranch.children) {
                let variantData = extractVariantData(osid, child, idVersionMap);

                if (variantData != void 0) {
                    return variantData;
                }
            }
        }

        /**
         * Constructur for the variant data that is globally valid for every variant of a dms document.
         *
         * @param {object} data - The backend data property variantTree of a dms document.
         */
        function VariantTreeData(data) {
            let self = this;

            self.model = {
                originalVariantId: data.variantInformation["DOCUMENT_ID"].value,
                activeVariantId: 0,
                variantTree: {children: []}
            };

            addVariantBranches(self, self.model.variantTree, data);

            self.model.variantTree = self.model.variantTree.children[0];
        }

        /**
         * Create uniform variant branches and add them to the variant tree.
         *
         * @param {VariantTreeData} variantTreeData - The VariantTreeData.
         * @param {object} parentBranch - The branch the parsed variant branch should be added to.
         * @param {object} variantData - The variant data that shall be parsed to a variant branch (deep).
         */
        function addVariantBranches(variantTreeData, parentBranch, variantData) {
            let info = variantData.variantInformation,
                id = info["DOCUMENT_ID"].value,
                isActive = info["IS_ACTIVE"].value == "1";

            if (isActive) {
                variantTreeData.model.activeVariantId = id;
            }

            let variantBranch = {
                osid: id,
                children: []
            };

            for (let child of variantData.children) {
                addVariantBranches(variantTreeData, variantBranch, child);
            }

            parentBranch.children.push(variantBranch);
        }

        /**
         * Determine the variant type.
         *
         * @param {string} version - The variant version.
         * @returns {string} The variant type (main|parallel|sub).
         */
        function getType(version) {
            if (version == "Original") {
                return "main";
            }

            let versionArray = version.split(".");

            if (versionArray[2] != "0") {
                return "sub";
            } else if (versionArray[1] != "0") {
                return "parallel";
            } else {
                return "main";
            }
        }
    }
})();