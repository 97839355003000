import {Directive} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {ElementRef, Injector} from "@angular/core";

@Directive({
    selector: "eob-quicksearch"
})
export class EobQuicksearchDirective extends UpgradeComponent {

    constructor(elementRef: ElementRef, injector: Injector) {
        super("eobQuicksearch", elementRef, injector);
    }

}
