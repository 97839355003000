<textarea #textarea
    *ngIf="field"
    [id] = "fieldid"
    [title]="field.value"
    [formControl]="control"
    [control]="control"
    [fieldMask]="control.eobOptions.masks"
    [field]="field"
    [attr.has-addon]="hasAddon"
    [attr.disabled]="(control.disabled || disabled) ? '' : null"
    [attr.readonly]="(disabled == true || control.disabled) ? '' : null"
    [ngClass]="{'mock-field': ismockform}"
    (click)="clickHandler($event)"
    (keydown)="keyDownHandler($event)"
    autocomplete="off"
    spellcheck = "false"
    class = "form-row-input"
    rows = "5"
    eobDisplayBubble
    [bubble]="customBubble || bubble"
    [host]="textarea">
</textarea>
<eob-addon *ngIf="control.eobOptions?.addonConfig" [control]="control"></eob-addon>
<eob-standalone-validation-bubble #bubble></eob-standalone-validation-bubble>
