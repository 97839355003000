import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EmsDropzoneComponent} from "MODULES_PATH/dropzone/components/ems-dropzone/ems-dropzone.component";
import {DragDropDirective} from "MODULES_PATH/dropzone/directives/drag-drop.directive";
import {Type} from "@angular/core";
import {SharedModule} from "SHARED_PATH/shared.module";

const components: Array<Type<unknown>> = [
    EmsDropzoneComponent
];

const directives: Array<Type<unknown>> = [
    DragDropDirective
];


@NgModule({
    imports: [
        CommonModule, SharedModule
    ],
    declarations: [...components, ...directives],
    exports: [...components, ...directives],
    entryComponents: [...components]
})
export class DropzoneModule {
}
