import {Directive, OnInit, Inject, Renderer2} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import { TimeRemaining } from "INTERFACES_PATH/autologout-timer.interface";
import {fromEvent} from "rxjs";
import {debounceTime} from "rxjs/operators";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

@Directive({
    selector: "[eob-autologout-timer]"
})
export class EobAutologoutTimerDirective implements OnInit {
    deadLine: number;
    translateFn: TranslateFnType;
    countdownUpdateInterval: number;
    events: string[] = ["load", "mousedown", "mousemove", "click", "scroll", "keypress"];
    idleContainer: HTMLElement;
    timeSpan: HTMLElement;
    isIdle: boolean = false;
    idleMessageTemplate: string;
    whatToDo: HTMLElement;
    gMaxInactiveInterval: number;
    gIdlePageInterval: number;
    gTimeToLogout: number;
    gTimeout: number;
    gIdleTimer: number;

    constructor(@Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("$eobConfig") $eobConfig: any,
                @Inject("backendService") protected backendService: any,
                private clientService: ClientService,
                private renderer: Renderer2) {
        this.translateFn = this.$filter("translate") ;
    }

    ngOnInit(): void {
        if (this.clientService.isLocalClient() || this.environmentService.env.sessionTimeout == -1) {
            console.debug("Disable autologout");
            return;
        }
        this.idleContainer = document.body.querySelector("#eob-idle-page");
        this.timeSpan = this.idleContainer.querySelector(".time-span");
        this.whatToDo = this.idleContainer.querySelector(".what-to-do");
        this.idleMessageTemplate = this.translateFn("eob.idle.message.pre");

        for (const ev of this.events) {
            fromEvent(window, ev).pipe(debounceTime(100)).subscribe(this.resetTimer.bind(this));
            // window.addEventListener(ev, this.resetTimer);
        }

        const maxInactiveInterval: number = isNaN(this.environmentService.env.sessionTimeout) ? 1800 * 1000 : this.environmentService.env.sessionTimeout * 1000;

        this.initializeTimer(maxInactiveInterval);

        this.whatToDo.innerText = this.translateFn("eob.idle.message.post");
        this.idleContainer.querySelector("h1").innerText = this.translateFn("eob.idle.title");

        this.resetTimer();
    }

    logout = (): void => {
        for (const ev of this.events) {
            window.removeEventListener(ev, this.resetTimer);
        }

        this.timeSpan.innerText = this.translateFn("eob.logged.out.message");

        setTimeout(() => {
            this.clientService.logoutAsync();
        }, 5000);
    };

    showIdleScreen = (): void => {
        this.isIdle = true;
        this.renderer.setStyle(this.idleContainer, "display", "flex");
        this.deadLine = +new Date() + this.gTimeToLogout;
        this.initCountDown();
    };

    getTimeRemaining = (): TimeRemaining => {
        const t: number = this.deadLine - +new Date();
        const seconds: number = t > 0 ? Math.floor((t / 1000) % 60) : 0;
        const minutes: number = t > 0 ? Math.floor((t / 1000 / 60) % 60) : 0;

        return {
            total: t,
            minutes,
            seconds
        };
    };

    updateClock = (): void => {
        const t: TimeRemaining = this.getTimeRemaining();
        let idleMessage: string = this.translateFn("eob.logged.out.message");

        if (t.total > 0) {
            idleMessage = this.idleMessageTemplate.replace("[%s1]", `${(`0${t.minutes}`).slice(-2)}:${(`0${t.seconds}`).slice(-2)}`);
        } else {
            this.renderer.setStyle(this.whatToDo, "display", "none");
        }

        this.timeSpan.innerText = idleMessage;
    };

    initCountDown = (): void => {
        this.updateClock();
        this.countdownUpdateInterval = window.setInterval(this.updateClock, 1000);
    };

    resetTimer = (): void => {
        clearTimeout(this.gTimeout);
        clearTimeout(this.gIdleTimer);
        this.gTimeout = window.setInterval(this.logout, this.gMaxInactiveInterval);
        this.gIdleTimer = window.setInterval(this.showIdleScreen, this.gIdlePageInterval);
        this.renderer.setStyle(this.whatToDo, "display", "flex");
        if (this.isIdle) {
            this.renderer.setStyle(this.idleContainer, "display", "none");
            clearInterval(this.countdownUpdateInterval);
            this.isIdle = false;
        }
    };

    initializeTimer = (timeout: number) => {
        this.gMaxInactiveInterval = timeout;
        this.gIdlePageInterval = this.gMaxInactiveInterval > 600000 ? this.gMaxInactiveInterval - 300000 : this.gMaxInactiveInterval / 2;
        this.gTimeToLogout = this.gMaxInactiveInterval > 600000 ? 300000 : this.gMaxInactiveInterval / 2;
    };
}
