(function () {
    "use strict";

    require("COMPONENTS_PATH/eob-download-content/eob.download.content.dir.js");

    angular.module("eob.client").controller("downloadController", DownloadController);

    function DownloadController() {
    }
})();
