import {Inject, Injectable} from "@angular/core";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {TodoCacheManagerService, TodoEnvironmentService, TodoFormHelper} from "INTERFACES_PATH/any.types";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ModalDialogInjectorService} from "MODULES_PATH/modal-dialog/services/modal-dialog-injector.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {FormField} from "MODULES_PATH/form/interfaces/form.interface";
import {
    BaseParam,
    BaseParams,
    ChildEntry,
    Data,
    EcmSimpleField,
    ParentEntry,
    QuickfinderConfig,
    QuickfinderObjectType,
    Row
} from "MODULES_PATH/form/interfaces/quickfinder.addon.interface";
import {ObjectType} from "INTERFACES_PATH/object-type.interface";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {Subject, ReplaySubject} from "rxjs";
import * as dayjs from "dayjs";
import {EobModalQuickfinderComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-quickfinder/eob-modal-quickfinder.component";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

@Injectable({
    providedIn: "root"
})
export class QuickfinderAddonService {

    private readonly translateFn: TranslateFnType;
    localField: FormField;
    localFormHelper: TodoFormHelper;

    constructor(@Inject("$filter") private $filter: ng.IFilterService,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                private notificationsService: NotificationsService,
                private messageService: MessageService,
                private modalDialogInjectorService: ModalDialogInjectorService) {
        this.translateFn = this.$filter("translate");
    }

    async showQuickfinderAsync(event: Event, field: FormField, formHelper: TodoFormHelper): Promise<void> {
        this.localField = field;
        this.localFormHelper = formHelper;

        this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);

        field.crosscheckValues = {};

        const quickfinderTypeId: number = await this.getQuickfinderTypeAsync(field, event);

        const typeDef: ObjectType = this.cacheManagerService.objectTypes.getById(quickfinderTypeId);

        if (typeDef != void 0) {
            field.applyData = this.applyData;

            const quickfinderdata = {
                typeDef,
                field,
                formHelper,
                applyData: this.applyData
            };

            this.modalDialogInjectorService.createDialog({
                title: "Quickfinder",
                description: typeDef.model.config.name
            }, {
                component: EobModalQuickfinderComponent,
                input: {quickfinderdata}
            });
        } else {
            this.notificationsService.error(this.translateFn("form.quickfinder.open.objecttype.error"));
        }

        this.buildCrosscheckValueMapping(field, formHelper);
    }

    applyData = (data: Data, objectTypeId: number): void => {
        const objectTypes: QuickfinderObjectType[] = this.localField.model.config.objectTypes;
        const values: string[] = [];
        let currentType: QuickfinderObjectType;

        for (const objectType of objectTypes) {
            if (objectType.id == objectTypeId) {
                currentType = objectType;
                break;
            }
        }

        for (let i = 0; i < currentType.fields.length; i++) {
            const tmpField: string = currentType.fields[i];
            let fieldName: string, value: string;

            if (!tmpField) {
                value = this.getValueByFieldmapping(data.ecmSimpleFields, i, this.localField, this.localFormHelper);
            } else if (tmpField.charAt(0) == "%" && tmpField.charAt(tmpField.length - 1) == "%") {
                fieldName = tmpField.replace(/%/g, "");
                value = this.getValueByKey(data.ecmSimpleFields, fieldName, "internalName");
            } else if (tmpField.lastIndexOf("@@") == tmpField.length - 2) {
                fieldName = tmpField.replace(/@@/g, "");
                value = this.getBaseParamValue(data, objectTypeId, fieldName);
            } else {
                value = this.getValueByKey(data.ecmSimpleFields, tmpField, "displayName");
            }

            values.push(value);
        }

        if (values[0] != "" || this.localField.model.config.replaceValues) {
            this.localField.api.setValue(values[0], true);
        }

        const fields: FormData = this.localFormHelper.getFields();

        for (const i in fields) {
            if (fields[i].model.crossCheck && fields[i].model.relatedControl == this.localField.model.tabIndex && fields[i].model.pageIndex == this.localField.model.pageIndex) {
                let val = values[fields[i].model.crossCheck];

                if (val != "" || this.localField.model.config.replaceValues) {
                    val = val === void 0 ? "" : val;
                    fields[i].api.setValue(val, true);
                }
            }
        }

        this.buildCrosscheckValueMapping(this.localField, this.localFormHelper);
    };

    buildCrosscheckValueMapping = (field: FormField, formHelper: TodoFormHelper): void => {
        const fields: FormData = formHelper.getFields();

        for (const key in fields) {
            if (fields[key].model.crossCheck && fields[key].model.relatedControl == field.model.tabIndex && fields[key].model.pageIndex == field.model.pageIndex) {
                field.crosscheckValues[key] = fields[key].value;
            }
        }
    };

    getValueByFieldmapping = (datas: EcmSimpleField[], missingIndex: number, field: FormField, formHelper: TodoFormHelper): string => {
        const fields: FormField[] = formHelper.getFields();
        let fieldName = "";

        for (const i in fields) {
            if (fields[i].model.crossCheck == missingIndex && fields[i].model.relatedControl == field.model.tabIndex && fields[i].model.pageIndex == field.model.pageIndex) {
                fieldName = fields[i].model.name;
                break;
            }
        }

        if (!fieldName) {
            return "";
        }

        for (const data of datas) {
            if (data.displayName == fieldName) {
                return data.value;
            }
        }

        return "";
    };

    getBaseParamValue = (data: Data, objectTypeId: number, fieldName: string): string => {
        const baseParameters: BaseParams = this.getBaseParamList(data, objectTypeId);

        if (baseParameters[fieldName]) {
            return baseParameters[fieldName];
        }

        return "";
    };

    getBaseParamList = (data: Data, objectTypeId: number): BaseParams => {
        const baseParameters = {
            OBJECTTYPE: objectTypeId,
            OBJECTID: data.osid,
            OBJECTNAME: data.displayName,
            CREATOR: "",
            CREATIONTIME: "",
            TIMESTAMP: "",
            MODIFYUSER: "",
            MODIFYTIME: "",
            ARCHIVIST: "",
            ARCHIVETIME: ""
        };

        for (const i in data.baseParameters) {
            const param: BaseParam = data.baseParameters[i],
                dateFormat = this.environmentService.env.dateFormat.datetime;

            if (param.value == "") {
                continue;
            }

            switch (param.type) {
                case "CREATOR":
                    baseParameters.CREATOR = param.value;
                    break;
                case "CREATION_DATE":
                    baseParameters.CREATIONTIME = param.value;
                    break;
                case "CREATED":
                    baseParameters.TIMESTAMP = dayjs(param.value, dateFormat).valueOf().toString();
                    break;
                case "MODIFIER":
                    baseParameters.MODIFYUSER = param.value;
                    break;
                case "MODIFIED":
                    baseParameters.MODIFYTIME = dayjs(param.value, dateFormat).valueOf().toString();
                    break;
                case "ARCHIVIST":
                    baseParameters.ARCHIVIST = param.value;
                    break;
                case "ARCHIVE_DATE":
                    baseParameters.ARCHIVETIME = param.value;
                    break;
            }
        }

        return baseParameters;
    };

    getValueByKey = (datas: EcmSimpleField[], fieldName: string, key: string): string => {
        for (const data of datas) {
            if (data[key] == fieldName) {
                return data.value || "";
            }
        }
    };

    getQuickfinderTypeAsync = async (field: FormField, event: Event): Promise<number> => {
        const subject$: Subject<number> = new ReplaySubject(1);
        if (field.model.config.objectTypes.length === 1) {
            subject$.next(Number(field.model.config.objectTypes[0].id));
            subject$.complete();
        } else {
            const config: QuickfinderConfig = {
                event,
                target: event.target,
                data: {
                    model: field.model,
                    applySelection: (row: Row) => {
                        subject$.next(row.objectTypeId);
                        subject$.complete();
                    }
                },
                config: {
                    iconCatalog: true,
                    objectTypeId: "",
                    internal: ""
                },
                list: this.getConfiguredObjectTypeTree(field),
                catalogType: "list"
            };

            event.stopImmediatePropagation();
            this.messageService.broadcast("showTree", config);

        }

        return subject$.toPromise();
    };

    getConfiguredObjectTypeTree = (field: FormField): ParentEntry[] => {
        const objectTypeTree: ParentEntry[] = [],
            parents = {};

        for (const type of field.model.config.objectTypes) {
            const typedef: ObjectType = this.cacheManagerService.objectTypes.getById(type.id);

            const parentTypedef: ObjectType = this.cacheManagerService.objectTypes.getById(typedef.model.config.cabinetId);
            let parentEntry: ParentEntry;

            if (parents[parentTypedef.model.config.objectTypeId] == void 0) {
                parentEntry = {
                    value: parentTypedef.model.config.title,
                    objectTypeId: parentTypedef.model.config.objectTypeId,
                    nodes: []
                };

                parents[parentTypedef.model.config.objectTypeId] = parentEntry;
                objectTypeTree.push(parentEntry);
            } else {
                parentEntry = parents[parentTypedef.model.config.objectTypeId];
            }

            const childEntry: ChildEntry = {
                value: typedef.model.config.title,
                objectTypeId: type.id,
                icon: null,
                iconClass: null
            };

            if (typedef.model.config.iconId != void 0) {
                childEntry.icon = typedef.model.config.iconId;
            } else if (typedef.model.config.mainType == "99" || typedef.model.config.mainType == "0") {
                childEntry.iconClass = `${typedef.model.config.icon}-dark`;
            } else {
                childEntry.iconClass = typedef.model.config.icon;
            }

            parentEntry.nodes.push(childEntry);
        }

        return objectTypeTree;
    };
}
