import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";
import {FieldExpander} from "INTERFACES_PATH/validation.interface";
import {RightGroupsAddonService} from "MODULES_PATH/form/services/form-builder/right-groups-addon.service";
import {DmsOrganisationObject} from "INTERFACES_PATH/dms-organisation-object.interface";
import {CorrectionFieldMask} from "../field-mask.model";

export class RightGroupMask extends CorrectionFieldMask {
    constructor(private field: FieldExpander, private rightGroupsAddonService: RightGroupsAddonService) {
        super();
    }

    parseValue(value: FormInputValue): FormInputValue {
        if (value === undefined || value === "") {
            return "";
        }

        const filteredItems: DmsOrganisationObject[] = this.rightGroupsAddonService.getUserAndGroups(this.field.model.config);

        const valueParts: string[] = value.toString().split(";").filter(v => v?.trim().length > 0);
        const parsedValues: string[] = [];
        for (const valuePart of valueParts) {
            let parsedValue: string = valuePart.toUpperCase();

            if (!/(.*)(\([UG]\))$/g.test(parsedValue)) {
                const matchingItem: DmsOrganisationObject = filteredItems.find(entry => entry.name.toUpperCase() === parsedValue);

                if (matchingItem) {
                    parsedValue = matchingItem.name.toUpperCase() + (matchingItem.type == "user" ? "(U)" : "(G)");
                }
            }

            if (!parsedValues.includes(parsedValue)) {
                parsedValues.push(parsedValue);
            }
        }

        return parsedValues.join(";");
    }
}