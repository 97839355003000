import {Injectable, Inject} from "@angular/core";
import {OrgAddonService} from "MODULES_PATH/form/services/form-builder/org-addon.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {OrgAddonField} from "INTERFACES_PATH/addon.field.interface";
import {OrgAddonConfig, OrgAddonEntry} from "INTERFACES_PATH/org-addon-entry.interface";
import {TreeNode} from "INTERFACES_PATH/tree.interface";
import {DmsGroupOrgObject, DmsOrganisationObject, DmsUserOrgObject} from "INTERFACES_PATH/dms-organisation-object.interface";
import {AdditionalItem} from "INTERFACES_PATH/validation.interface";
import {UserAddonConfig} from "MODULES_PATH/form/interfaces/form-addon-config.interface";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {TodoModalDialogService} from "INTERFACES_PATH/any.types";

@Injectable({providedIn: "root"})
export class UserAddonService {
    private readonly translateFn: TranslateFnType;

    constructor(private organisationService: OrganisationService, private orgAddonService: OrgAddonService,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("$filter") private $filter: ng.IFilterService, private messageService: MessageService) {
        this.translateFn = this.$filter("translate") ;
    }

    showUserAddon(event: Event, field: OrgAddonField, formHelper: any): void {
        event.stopImmediatePropagation();

        // we need to save this for later, in case the user enters an invalid name manually
        // we need to check if the entered name is valid, because the user is not allowed to
        // enter a user which is inactive
        field.initialValue = field.value;
        // only show all users if the user is inside the search state
        field.showAllUsers = formHelper.isSearch;

        if (field.model.config.multiSelect) {
            this.showUserAddonDialog(field);
        } else {
            this.showUserAddonInlineTree(field, formHelper, event);
        }
    }

    private showUserAddonDialog(field: OrgAddonField): void {
        const content: DmsOrganisationObject[] = [].concat(this.organisationService.getActiveUserList(), field.model.config.showGroups ? this.organisationService.getGroupList() : []);
        const fieldContent: string[] = field.value != "" ? field.value.split(";") : [],
            selectFn: (entry: DmsOrganisationObject) => boolean = entry => (fieldContent.includes(entry.name) || fieldContent.includes((entry as DmsUserOrgObject).fullname));

        const entries: OrgAddonEntry[] = this.orgAddonService.parseToOrgAddonEntries(content, selectFn);

        const config: OrgAddonConfig = {
            initialSort: field.model.config.sortName ? "displayName" : field.model.config.sortFullname ? "description" : undefined,
            sortBy: field.model.config.sortFullname ? "description" : "displayName"
        };

        const applyFn: (items: OrgAddonEntry[]) => void = items => this.applySelectionDialog(field, items);

        this.modalDialogService.showOrgMultiselectDialog(this.translateFn("modal.user.addon.title"), config, entries, applyFn);
    }

    private applySelectionDialog(field: OrgAddonField, items: OrgAddonEntry[]): void {
        const selection: string[] = items.map(item => (field.model.config.applyDescription && item.description != "") ? item.description : item.displayName);
        field.api.setValue(selection.join(";"), true);
    }

    private showUserAddonInlineTree(field: OrgAddonField, formHelper: any, event: Event): void {
        field.applySelection = item => this.applySelectionInlineTree(field, item);

        const list: TreeNode[] = this.buildInlineTreeUserList(field);

        field.model.tree = {
            config: {
                useMultiSelect: false,
                shortValue: true,
                readonly: formHelper.isView
            },
            nodes: list
        };

        this.messageService.broadcast("showTree", {
            event,
            target: event.target,
            data: field,
            element: field.api.getElement()
        });
    }

    private applySelectionInlineTree(field: OrgAddonField, item: TreeNode): void {
        const value: string = field.model.config.applyDescription && item.value != "" ? item.value : item.short;
        field.api.setValue(value, true);
    }

    buildInlineTreeUserList(field: OrgAddonField): TreeNode[] {
        let entries: DmsOrganisationObject[] = this.getUserAndGroups(field);
        entries = entries.sort(field.model.config.sortFullname ?
            (a: DmsUserOrgObject, b: DmsUserOrgObject) => a.fullname.localeCompare(b.fullname)
            : (a, b) => a.name.localeCompare(b.name));

        return entries.map(entry => ({
            value: entry.type == "group" ? (entry as DmsGroupOrgObject).description : (entry as DmsUserOrgObject).fullname,
            short: entry.name
        }));
    }

    getUserAndGroups(field: OrgAddonField): DmsOrganisationObject[] {
        const users: DmsUserOrgObject[] = field.showAllUsers ? this.organisationService.getUserList() as unknown as DmsUserOrgObject[] : this.organisationService.getActiveUserList() as unknown as DmsUserOrgObject[];
        const groups: DmsGroupOrgObject[] = field.model.config.showGroups ? this.organisationService.getGroupList() as unknown as DmsGroupOrgObject[] : [];
        const list: DmsOrganisationObject[] = [].concat(users, groups);

        for (const entry of field.model.config.additionalEntries) {
            list.push({
                fullname: "",
                icon: "",
                name: entry,
                type: "entry"
            } as DmsUserOrgObject);
        }

        return list;
    }

    getUserAndGroupsByConfig(config: UserAddonConfig): DmsOrganisationObject[] {
        const users: DmsUserOrgObject[] = config.showUsers ? this.organisationService.getUserList() as unknown as DmsUserOrgObject[] : [];
        const groups: DmsGroupOrgObject[] = config.showGroups ? this.organisationService.getGroupList() as unknown as DmsGroupOrgObject[] : [];
        const items: DmsOrganisationObject[] = [].concat(users, groups);

        for (const entry of config.additionalEntries) {
            items.push({
                fullname: "",
                icon: "",
                name: entry,
                type: "entry"
            } as AdditionalItem);
        }
        return items;
    }
}
