import {Component, Input, OnInit} from "@angular/core";
import {InputAddonConfig} from "MODULES_PATH/form/interfaces/input-addon-config.interface";
import {EobInputFormControl} from "MODULES_PATH/form/components/inputs/eob-input-form-control.model";

@Component({
    selector: "eob-addon",
    templateUrl: "./addon.component.html",
    styleUrls: ["./addon.component.scss"]
})
export class AddonComponent implements OnInit {
    @Input() control: EobInputFormControl;
    addonConfig: InputAddonConfig;
    isDisabled: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
        this.addonConfig = this.control.eobOptions.addonConfig;
        this.control.registerOnDisabledChange((isDisabled) => this.isDisabled = isDisabled);
    }

    displayAddon(event: MouseEvent): void {
        this.addonConfig.showFn(event);
    }
}
