import {
    Component,
    forwardRef,
    Inject,
    Input,
    OnInit
} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {Field} from "INTERFACES_PATH/field.interface";
import {TodoEnvironmentService, TodoFormData} from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-form-group",
    templateUrl: "./eob-form-group.component.html",
    styleUrls: ["./eob-form-group.component.scss"]
})
export class EobFormGroupComponent implements OnInit {
    @Input() formhelper: any;
    @Input() formdata: TodoFormData[];
    @Input() layoutField: any;
    @Input() formdef: any[];
    @Input() formid: string;
    @Input() field: Field;
    @Input() ismockform: boolean;

    showAltLang: boolean;
    objectDefLang: any;

    constructor(
        @Inject(forwardRef(() => ClientService)) public clientService: ClientService,
        @Inject(forwardRef(() => "environmentService")) public environmentService: TodoEnvironmentService
    ) {}

    ngOnInit(): void {
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang() && !this.ismockform;
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();
    }
}
