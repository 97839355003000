import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { KnTrapFocusDirective } from "./directives/kn-trap-focus.directive";

@NgModule({
    declarations: [KnTrapFocusDirective],
    imports: [
        CommonModule
    ],
    exports: [KnTrapFocusDirective],
    entryComponents: []
})
export class A11yModule {
}
