(function() {
    

    angular.module("eob.framework").directive("eobCircularTabForm", EobCircularTabForm);

    EobCircularTabForm.$inject = [];

    function EobCircularTabForm() {
        return {
            restrict: "E",
            transclude: true,
            link(scope, element) {
                let firstField = element.find(".first-field");
                let lastField = element.find(".last-field");
                let isCirculating = false;

                firstField.bind("focusin", () => {
                    if (!isCirculating) {
                        isCirculating = true;
                        lastField.focus();
                        $.focusPrev();
                        isCirculating = false;
                    }
                });

                lastField.bind("focusin", () => {
                    if (!isCirculating) {
                        isCirculating = true;
                        firstField.focus();
                        $.focusNext();
                        isCirculating = false;
                    }
                });

                scope.$on("$destroy", () => {
                    firstField.unbind();
                    lastField.unbind();
                });
            },
            template: require("!raw-loader!./eob.circular.tab.form.html")
        };
    }
})();
