import { FormEvent } from "../../../app/modules/form/enums/form-event.enum";

(function () {

    require("@shagstrom/split-pane/split-pane.js");

    // the search.dir is required in the form.srv depending on the server version
    angular.module("eob.client").controller("searchController", SearchController);

    SearchController.$inject = ["$scope", "messageService"];

    function SearchController($scope, messageService) {
        $scope.$on("$destroy", _ => {
            messageService.broadcast(FormEvent.SEARCH_DESTROYED);
        })
    }
})();
