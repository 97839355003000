import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";

(function() {
    require("SERVICES_PATH/eob.state.history.manager.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/form/eob.form.srv.js");
    require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");
    require("SERVICES_PATH/scripting/eob.client.script.srv.js");
    require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

    angular.module("eob.framework").directive("eobIndexdata", EobIndexdata);

    EobIndexdata.$inject = ["notificationsService", "stateHistoryManager", "environmentService", "objectTypeService",
        "dmsDocumentService", "backendService", "$stateParams", "formService", "viewerService", "formHelper",
        "$location", "$filter", "$state", "$q", "progressbarService", "clientService", "offlineCacheService",
        "clientScriptService", "cacheManagerService", "$rootScope", "messageService"];

    /* eslint-disable */
    /**
     * This directive is the wrapping directive for the indexdata modify
     * Its purpose is to get the Objecttype definition and further details
     * needed for this state (see /eob-core/state.history.manager.srv.js)
     */
    function EobIndexdata(NotificationsService, StateHistoryManager, EnvironmentService, ObjectTypeService,
                          DmsDocumentService, BackendService, $stateParams, FormService, ViewerService, FormHelper,
                          $location, $filter, $state, $q, ProgressbarService, ClientService, OfflineCacheService,
                          ClientScriptService, CacheManagerService, $rootScope, MessageService) { /*eslint-enable*/
        return {
            restrict: "E",
            async link(scope, element) {

                // Todo DODO-13393
                // if (FormService.getBeforeCancelExecuted().formHelper) {
                //     FormService.setBeforeCancelExecuted({isExecuted: false, formHelper: FormService.getBeforeCancelExecuted().formHelper});
                // }

                if (!DmsDocumentService.isValidOsid($stateParams.osid)) {
                    NotificationsService.error($filter("translate")("eob.object.not.found"), $filter("translate")("eob.notification.error.title"));
                    return;
                }

                scope.formHelper = null;
                scope.formLayout = null;
                scope.dataReady = false;
                scope.scriptsReady = false;
                scope.shouldBeHidden = true;

                let formReadySubscription;
                let stateId = $location.search().state;
                let stateConfig = StateHistoryManager.getStateData(stateId);
                let progressbar;

                if (stateConfig && stateConfig.data && stateConfig.data.redirectedByScript) {
                    setTimeout(() => {
                        // prevent being returned to hitlist if using browser forward navigation
                        StateHistoryManager.updateStateData({ redirectedByScript: false }, stateId);
                        history.back();
                    }, 0);
                }

                initFooterConfig();
                initProgressbar();

                let helperConfig = {
                    modelDef: null,
                    formData: null,
                    save: $stateParams.mode == "edit" ? FormService.updateIndexData : null,
                    cancel: FormService.cancel,
                    progressbar
                };

                try {
                    let response;
                    if (ClientService.isLocalClient() && ClientService.isOffline()) {
                        response = await OfflineCacheService.getById($stateParams.osid);
                    } else {
                        response = (await BackendService.get(`/documents/search/${$stateParams.osid}?refresh=true&t=${$.now()}${getObjectTypeIdRequestPart()}`)).data;
                    }
                    let dmsDocument = CacheManagerService.dmsDocuments.get(CacheManagerService.dmsDocuments.add(response))[0];

                    if ($stateParams.mode == "edit" && !dmsDocument.model.rights.indexModify) {
                        StateHistoryManager.goToIndexData(dmsDocument, "view", stateConfig.data.config.userAction, true);
                        return;
                    }

                    if ($stateParams.mode == "edit") {
                        scope.stateTitle = $filter("translate")("eob.indexdata.state.edit.title");
                        helperConfig.isEdit = true;
                        scope.shouldBeHidden = false;
                    } else {
                        scope.stateTitle = $filter("translate")("eob.indexdata.state.view.title");
                        helperConfig.isView = true;
                        scope.shouldBeHidden = false;
                    }

                    // set selected osid for dashlet container
                    stateConfig.data = stateConfig.data || {};
                    stateConfig.data.config = stateConfig.data.config || {};
                    stateConfig.data.config.selectedDmsOsids = [];
                    stateConfig.data.config.selectedDmsOsids.push(dmsDocument.model.osid.toString());

                    await new Promise((resolve) => {
                        setTimeout(() => {
                            ViewerService.updateViewer(dmsDocument.model.osid, undefined, [dmsDocument.model.osid]);
                            resolve();
                        }, 0);
                    })

                    let modelDef = CacheManagerService.objectTypes.getById(dmsDocument.model.objectTypeId);

                    if (!modelDef) {
                        NotificationsService.error($filter("translate")("eob.object.type.not.found"), $filter("translate")("eob.notification.error.title"));
                        handleError();
                    }

                    scope.modelDef = modelDef;

                    // check if there are cached scripts
                    scope.eventScripts = scope.modelDef.api.getTypeScripts();
                    if (scope.eventScripts == null) {
                        // if not, we need to get them and store them
                        try {
                            let scriptResponse = await ClientScriptService.getByObjectTypeIdAsync(dmsDocument.model.objectTypeId)
                            scope.eventScripts = scriptResponse.data;
                            scope.modelDef.api.setTypeScripts(scriptResponse.data);
                        } catch (error) {
                            NotificationsService.backendError(error, "eob.object.load.error");
                            handleError(error);
                        }
                    }

                    // get the current objecttype definition
                    scope.formFields = scope.modelDef.api.getFields(true);
                    const description = dmsDocument.api.getTitleAndDescription();
                    scope.stateDescription = `${scope.modelDef.model.config.title} - ${description.title} - ${description.description}`;

                    if (scope.isPhone) {
                        scope.descriptionCache = scope.stateDescription;
                    } else {
                        let tabTitle = `${$filter("translate")("eob.location.state.title")} - ${scope.stateDescription}`;
                        ClientService.refreshTabTitle(tabTitle);
                    };

                    helperConfig.modelDef = scope.modelDef.model;
                    if ($stateParams.mode == "edit") {
                        scope.stateTitle = $filter("translate")("eob.indexdata.state.edit.title");
                        helperConfig.isEdit = true;
                    } else {
                        scope.stateTitle = $filter("translate")("eob.indexdata.state.view.title");
                        helperConfig.isView = true;
                        helperConfig.isEdit = false;
                    }

                    let formData = FormService.createFormData(scope.formFields, "max");
                    FormService.addIndexData(formData, dmsDocument.model);

                    await FormService.restoreAutosavedIndexdata(formData)

                    helperConfig.formData = formData;
                    helperConfig.dmsData = dmsDocument;
                    await FormService.disableFieldsAsync(formData, dmsDocument.model);

                    scope.formHelper = FormHelper.getFormHelper(helperConfig);

                    scope.formDef = {
                        isMainForm: true,
                        validationMode: "max",
                        formFields: scope.formFields,
                        formHelper: scope.formHelper,
                        formLayout: scope.modelDef.api.getLayout(),
                    };

                    FormService.initFormAutoSave(scope.formHelper);
                    await scope.formHelper.bindScripts(scope.eventScripts);

                    formReadySubscription = MessageService.subscribeFirst(FormEvent.FORM_READY, async () => {
                            try {
                                scope.formHelper.focusFirstField();
                                const result = await FormService.executeFormScript("beforeOpen", scope.formHelper)
                                if (result == 2) {
                                    StateHistoryManager.updateStateData({ redirectedByScript: true }, stateId);
                                    scope.shouldBeHidden = true;
                                }

                                if (progressbar) {
                                    progressbar.hide();
                                    progressbar = null;
                                }

                                scope.scriptsReady = true;
                            } catch (error) {
                                setTimeout(() => {
                                    ClientService.executeStateErrorFallback();
                                }, 0);
                            }
                    });

                    scope.dataReady = true;
                    scope.$apply()

                } catch (error) {
                    scope.shouldBeHidden = false;
                    if (ClientService.isOnline()) {
                        NotificationsService.backendError(error, "eob.object.load.error");
                    }
                    handleError(error);
                }

                function initFooterConfig() {
                    const viewConfig = [{
                        icon: { name: "footer-back", title: $filter("translate")("form.footer.back") },
                        action: "back",
                        class: "footer-button"
                    }]

                    const editConfig = [{
                        icon: { name: "footer-back-dark", title: $filter("translate")("form.footer.back") },
                        action: "back",
                        class: "footer-button secondary-button"
                    }, {
                        icon: { name: "footer-save", title: $filter("translate")("form.footer.save") },
                        action: "submit",
                        class: "footer-button save-button"
                    }]

                    scope.footerConfigs = $stateParams.mode === "view" ? viewConfig : editConfig;
                }

                /**
                 * show the loading animation
                 */
                function initProgressbar() {
                    progressbar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);

                    setTimeout(() => {
                        if (progressbar != void 0) {
                            progressbar.show();
                        }
                    }, 100);
                }

                function handleError() {
                    let x = StateHistoryManager.getStateData(stateId);

                    if (x && !x.fromEntry) {
                        setTimeout(() => {
                            ClientService.executeStateErrorFallback();
                        }, 0);
                    } else if (ClientService.isOnline()) {
                        $state.go("dashboard");
                    }
                }

                function getObjectTypeIdRequestPart() {
                    if ($stateParams.objectTypeId == void 0 || $stateParams.objectTypeId == "") {
                        return "";
                    } else {
                        return `&objecttypeid=${$stateParams.objectTypeId}`;
                    }
                }

                scope.$on("$destroy", () => {

                    // Todo DODO-13393
                    // if (FormService.getBeforeCancelExecuted().formHelper && !FormService.getBeforeCancelExecuted().isExecuted) {
                    //     FormService.executeFormScript("beforeCancel", FormService.getBeforeCancelExecuted().formHelper, false);
                    // }

                    if (scope.formHelper != void 0) {
                        let newData = FormService.reduceFormdata(scope.formHelper.getFields());
                        let data = {
                            config: stateConfig.data.config,
                            type: "indexdata",
                            fields: newData
                        };
                        scope.formHelper.destroy();
                        StateHistoryManager.updateStateData(data, stateId);
                    }

                    if (formReadySubscription) {
                        formReadySubscription.unsubscribe();
                    }
                });

            }
        };
    }
})();
