/**
 * Simple enum that is made to improve readbility of keyboard navigation code,
 * but can be used in any situation that deals with
 * directional properties like top, bottom, left, right, etc.
 */
export enum NavigationDirection {
    top,
    bottom,
    left,
    right
}