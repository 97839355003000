import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import {forwardRef, Inject, Injectable, InjectionToken, Injector, Provider} from "@angular/core";
import {EMPTY, Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {ElectronWindow} from "INTERFACES_PATH/electron/electron-window.interface";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {
    AuthenticationState,
    AuthRequestHeader
} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {OAuthService} from "angular-oauth2-oidc";
import {ProfileService} from "CORE_PATH/authentication/util/profile.service";

declare let window: ElectronWindow | Window;

@Injectable({
    providedIn: "root"
})
export class SessionInvalidDialogDisplay {
    private sessionTimeoutDialogShown = false;
    private readonly translateFn: TranslateFnType;

    constructor(private clientService: ClientService,
                @Inject("modalDialogService") private modalDialogService: any,
                @Inject("$filter") private $filter: ng.IFilterService
    ) {
        this.translateFn = this.$filter("translate");
    }

    /**
     *
     * @returns {boolean} whether the dialog was visible before this function call
     */
    showSessionExpiredDialog(): boolean {
        if (!this.sessionTimeoutDialogShown && !this.clientService.isLoggingOut && sessionStorage.getItem("afterFirstLogin") == "true") {
            this.sessionTimeoutDialogShown = true;

            const title: string = this.translateFn("eob.modal.session.expired.title");
            const message: string = this.translateFn("eob.modal.session.expired.description");
            const submit: string = this.translateFn("eob.login.login");

            // eslint-disable-next-line promise/catch-or-return,promise/prefer-await-to-then
            this.modalDialogService.infoDialog(title, message, null, submit, null, true).then(_ => {
                this.clientService.logoutAsync();
                return;
            });

            return false;
        }

        return true;
    }
}

@Injectable({
    providedIn: "root"
})
export class HeaderInterceptor implements HttpInterceptor {
    constructor(@Inject(forwardRef(() => ProfileService)) private profileService: ProfileService,
                @Inject(forwardRef(() => ClientService)) private clientService: ClientService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers: HttpHeaders = req.headers
            .set("X-Requested-With", "XMLHttpRequest")
            .set("Cache-Control", "no-cache, no-store, must-revalidate, post-check=0, pre-check=0")
            .set("Pragma", "no-cache")
            .set("Expires", "0");
        const update: any = {headers};
        if (this.clientService.isLocalClient() && !/(^https?:\/\/|\/auth\/realms\/)/.test(req.url)) {
            update.url = this.profileService.getCurrentBaseUrl() + req.url;
        }

        return next.handle(req.clone(update));
    }
}

@Injectable({
    providedIn: "root"
})
export class AuthInterceptor implements HttpInterceptor {

    private authHeaders: AuthRequestHeader[];

    constructor(@Inject(forwardRef(() => ClientService)) private clientService: ClientService,
                private authenticationService: AuthenticationService, private oauthService: OAuthService,
                private sessionInvalidDialogDisplay: SessionInvalidDialogDisplay) {
        this.authenticationService.getStatusEvent().subscribe(statusEvent => {
            switch (statusEvent.state) {
                case AuthenticationState.LOGGED_IN:
                case AuthenticationState.SESSION_REFRESHED:
                    this.authHeaders = statusEvent.requestHeaders || this.authHeaders;
                    break;
                case AuthenticationState.LOGGED_OUT:
                    this.authHeaders = [];
            }
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.clientService.isLocalClient()) {
            // we're in a browser
            return next.handle(req)
                .pipe(
                    // only catch errors
                    catchError(requestError => {
                        let shouldThrow = true;
                        if (requestError instanceof HttpErrorResponse && requestError.status === 401 && !/checkPassword/gi.test(req.url)) {
                            shouldThrow = this.sessionInvalidDialogDisplay.showSessionExpiredDialog();
                        }

                        return shouldThrow ? throwError(requestError) : EMPTY;
                    }));
        }

        if (this.authHeaders) {
            let headers: HttpHeaders = req.headers;

            this.authHeaders.filter(x => x.key != "Cookie").forEach((authHeader: AuthRequestHeader) => {
                headers = headers.set(authHeader.key, authHeader.value);
            });

            req = req.clone({headers});
        }

        return next.handle(req).pipe(catchError(requestError => {
            if (requestError instanceof HttpErrorResponse && requestError.status === 401) {
                if (/checkPassword/gi.test(req.url)) {
                    return throwError(requestError);
                }

                return this.authenticationService.handleReauthenticationRequest(req, next)
                    .pipe(catchError(err => this.sessionInvalidDialogDisplay.showSessionExpiredDialog() ? throwError(err) : EMPTY));
            }
            return throwError(requestError);
        }));
    }
}

export const httpInterceptorProviders: Provider[] = [
    {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];
