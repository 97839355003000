import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {GridIconCellParamsInterface} from "MODULES_PATH/grid/interfaces/grid-icon-cell-params.interface";

@Component({
    selector: "eob-icon-cell",
    templateUrl: "./icon-cell.component.html",
    styleUrls: ["./icon-cell.component.scss"]
})
export class IconCellComponent implements ICellRendererAngularComp {
    icon: string;
    title: string;
    ariaLabel: string;

    agInit(params: ICellRendererParams): void {
        this.setData(params);
    }

    refresh(params: ICellRendererParams): boolean {
        this.setData(params);
        return true;
    }

    private setData(params: ICellRendererParams) {
        const value: GridIconCellParamsInterface = params.value;

        if (value) {
            this.icon = value.icon;
            this.ariaLabel = value.ariaLabel;
        }
        this.title = value?.title || "";
    }
}
