import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {takeWhile} from "rxjs/operators";

(function () {


    angular.module("eob.framework").directive("eobModalList", EobModalList);

    EobModalList.$inject = ["clientService", "notificationsService", "$filter", "messageService"];

    function EobModalList(ClientService, NotificationsService, $filter, MessageService) {
        return {
            restrict: "E",
            scope: {
                resolve: "=",
                reject: "=",
                config: "=",
                buttons: "=",
                title: "=",
                destroy$: "=destroy"
            },
            link (scope, ev) {
                if(scope.buttons != void 0 && scope.buttons.cancel != void 0){
                    scope.isCloseOnly = true;
                }

                scope.chooseItem = function (item) {
                    if (ClientService.isOffline()) {
                        NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                        return;
                    }
                    scope.resolve(item);
                    scope.close();
                };

                // close the dialog once it is canceled
                scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

                // remove the dialog content from the main dialog container once the dialog is destroyed
                scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => scope.reject());
            },
            template: require("!raw-loader!./eob.modal.list.html")
        };
    }
})();
