<eob-checkbox
        [field]="field"
        [formhelper]="formhelper"
        [formid]="formid"
        [ismockform]="ismockform"
        [control]="control"
        [icon]="field.model.icon"
        [title]="field.model.title"
        [tooltip]="field.model.tooltip"
        [hideLabel]="field.model.isInvisibleText"
        [useTriState]="formhelper.isSearch && !field.model.isBaseParam"
        [customBubble]="formhelper.validationBubble"
>
</eob-checkbox>
