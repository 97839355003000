import {BrowserModule} from "@angular/platform-browser";
import {CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, Injectable, ModuleWithProviders, NgModule} from "@angular/core";
import {UpgradeModule} from "@angular/upgrade/static";
import {FormsModule} from "@angular/forms";
import {LicenseManager} from "ag-grid-enterprise";
import {AppComponent} from "./app.component";
import {requireControllers} from "CLIENT_PATH/configuration/states.config.js";
import MODULE_NAME from "./app.module.ajs";
import {UIRouterUpgradeModule} from "@uirouter/angular-hybrid";
import {CoreModule} from "ROOT_PATH/app/core/core.module";
import {DmsModule} from "ROOT_PATH/app/modules/dms/dms.module";
import {SharedModule} from "SHARED_PATH/shared.module";
import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {DashletModule} from "MODULES_PATH/dashlet/dashlet.module";
import {ProfileManagerModule} from "MODULES_PATH/profile-manager/profile-manager.module";
import {HitlistModule} from "MODULES_PATH/hitlist/hitlist.module";
import {ModalDialogModule} from "MODULES_PATH/modal-dialog/modal-dialog.module";
import {FormModule} from "MODULES_PATH/form/form.module";
import {StateBasicsModule} from "MODULES_PATH/state-basics/state-basics.module";
import {AuthenticationModule} from "CORE_PATH/authentication/authentication.module";
import {DropzoneModule} from "MODULES_PATH/dropzone/dropzone.module";
import {SearchModule} from "MODULES_PATH/search/search.module";
import {NavigationModule} from "MODULES_PATH/navigation/navigation.module";
import {DashboardModule} from "MODULES_PATH/dashboard/dashboard.module";
import {TimerModule} from "MODULES_PATH/timer/timer.module";
import {IconModule} from "MODULES_PATH/icon/icon.module";
import {ProfileManagerComponent} from "MODULES_PATH/profile-manager/profile-manager.component";
import {EobEntryComponent} from "SHARED_PATH/components/eob-entry/eob-entry.component";
import {EobDetachedDashletContainerComponent} from "MODULES_PATH/dashlet/components/eob-detached-dashlet-container/eob-detached-dashlet-container.component";
import {EobDashletContainerComponent} from "MODULES_PATH/dashlet/components/eob-dashlet-container/eob-dashlet-container.component";
import {EobDropdownComponent} from "SHARED_PATH/components/eob-dropdown/eob-dropdown.component";
import {EobMainDirective} from "MODULES_PATH/home/main.directive";
import {EobDemoContactDirective} from "MODULES_PATH/home/demo.contact.directive";
import {EobGlobalEventsDirective} from "MODULES_PATH/home/eob-global-events.directive";
import {EobAutologoutTimerDirective} from "MODULES_PATH/home/eob-autologout-timer.directive";

import * as $ from "jquery"; // ???

import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {metaReducers, reducers} from "./+state";
import { ScriptingModule } from "MODULES_PATH/scripting/scripting.module";
import { A11yModule } from "MODULES_PATH/a11y/a11y.module";
import {ExternalTrayModule} from "MODULES_PATH/external-tray/external-tray.module";

LicenseManager.setLicenseKey("CompanyName=OPTIMAL SYSTEMS GmbH,LicensedApplication=enaio,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=50,AssetReference=AG-024434,ExpiryDate=2_March_2023_[v2]_MTY3NzcxNTIwMDAwMA==3e99be55e356d4328f3c562bd032242f");

const states: any[] = [
    {
        name: "search",
        url: "/search/:objectTypeId?state",
        template: require("!raw-loader!CLIENT_PATH/states/eob-search/eob.search.html"),
        controller: "searchController",
        controllerAs: "vm"
    },
    {
        name: "indexdata",
        url: "/indexdata/:mode/:osid/:objectTypeId?state",
        template: require("!raw-loader!CLIENT_PATH/states/eob-indexdata/eob.indexdata.html"),
        controller: "indexdataController",
        controllerAs: "vm"
    },
    {
        name: "create",
        url: "/create/:mode?state&objectTypeId&id&targetId&location&type&parentTypeId&openParentAfterCreate&groupKey",
        template: require("!raw-loader!CLIENT_PATH/states/eob-create/eob.create.html"),
        controller: "createController",
        controllerAs: "vm"
    },
    {
        name: "workflow",
        url: "/workflow/:id?state",
        template: require("!raw-loader!CLIENT_PATH/states/eob-workflow/eob.workflow.html"),
        controller: "workflowController",
        controllerAs: "vm"
    },
    {
        name: "workflowInfo",
        url: "/workflow/:processId/:activityId?state",
        template: require("!raw-loader!CLIENT_PATH/states/eob-workflow-info/eob.workflow.info.html"),
        controller: "workflowInfoController",
        controllerAs: "vm"
    },
    {
        name: "folder",
        url: "/folder/:folderId/:objectTypeId?state&path&select&cabinetId&parentId",
        template: require("!raw-loader!CLIENT_PATH/states/eob-folder/eob.folder.html"),
        controller: "folderController",
        controllerAs: "vm"
    },
    {
        name: "variants",
        url: "/variants/:id/:objectTypeId?state",
        template: require("!raw-loader!CLIENT_PATH/states/eob-variant/eob.variant.html"),
        controller: "variantController",
        controllerAs: "vm"
    },
    {
        name: "entry",
        url: "/entry?state",
        component: EobEntryComponent
    },
    {
        name: "usermanagement",
        url: "/usermanagement?state",
        template: require("!raw-loader!CLIENT_PATH/states/eob-usermanagement/eob.usermanagement.html"),
        controller: "usermanagementController",
        controllerAs: "vm"
    },
    {
        name: "userupsert",
        url: "/user?mode&state&user",
        template: require("!raw-loader!CLIENT_PATH/states/eob-usermanagement-upsert/eob.usermanagement.upsert.html"),
        controller: "usermanagementUpsertController",
        controllerAs: "vm"
    },
    {
        name: "download",
        url: "/download/:osid/?state&objectTypeId&rendition",
        template: require("!raw-loader!CLIENT_PATH/states/eob-download/eob.download.html"),
        controller: "downloadController",
        controllerAs: "vm"
    },
    {
        name: "dashboard",
        url: "/dashboard?state",
        template: require("!raw-loader!CLIENT_PATH/states/eob-dashboard/eob.dashboard.html"),
        controller: "dashboardController",
        controllerAs: "vm"
    },
    {
        name: "detachedViewer",
        url: "/detachedViewer?openerId",
        component: EobDetachedDashletContainerComponent
    },
    {
        name: "viewer",
        url: "/viewer",
        component: EobDashletContainerComponent
    },
    {
        name: "hitlist.**",
        url: "/hitlist",
        loadChildren: () => import("MODULES_PATH/hitlist/hitlist.module").then((m: any) => m.HitlistModule),
    },
    {
        name: "native.**",
        url: "/native",
        loadChildren: () => import("MODULES_PATH/profile-manager/profile-manager.module").then((m: any) => m.ProfileManagerModule),
    },
];

@Injectable()
export class AppInitService {
    load(): Promise<boolean> {
        return Promise.resolve(true);
    }
}

/**
 * The main app module.
 */
@NgModule({
    declarations: [
        AppComponent,
        EobDemoContactDirective,
        EobMainDirective,
        EobGlobalEventsDirective,
        EobAutologoutTimerDirective,
        EobEntryComponent
    ],
    imports: [
        A11yModule,
        BrowserModule,
        SharedModule,
        UpgradeModule,
        CoreModule,
        DmsModule,
        DropzoneModule,
        ContextMenuModule,
        FormModule,
        StateBasicsModule,
        SearchModule,
        HitlistModule,
        ModalDialogModule,
        UIRouterUpgradeModule.forRoot({states}),
        FormsModule,
        ProfileManagerModule,
        DashletModule,
        AuthenticationModule.forRoot(),
        NavigationModule,
        DashboardModule,
        TimerModule,
        IconModule,
        ScriptingModule,
        ExternalTrayModule,
        StoreModule.forRoot(reducers, {metaReducers}),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
    ],
    providers: [
        AppInitService,
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: (appInitService: AppInitService) => () =>
        //         appInitService.load().then(loaded => {
        //             // TODO: Add pre-bootstrap logic
        //         }),
        //     deps: [AppInitService],
        //     multi: true
        // },
    ],
    entryComponents: [
        AppComponent,
        EobDropdownComponent,
        ProfileManagerComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule implements DoBootstrap {
    constructor(private upgrade: UpgradeModule) {
    }

    /**
     * Bootstrap the client.
     */
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngDoBootstrap(): void {
        requireControllers();
        let sanitizedUrl: string = location.href;
        if (!location.hash) {
            sanitizedUrl = sanitizedUrl.replace(/\?.*/, "");
        } else {
            sanitizedUrl = sanitizedUrl.replace(new RegExp("/\\?.*?#"), "/#");
        }
        window.history.replaceState({}, document.title, sanitizedUrl);

        // Bootstrap an AngularJS application from this NgModule
        // (the DOM must already be available)
        this.upgrade.bootstrap(document.body, [MODULE_NAME], {strictDi: true});
    }
}
