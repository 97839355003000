import {ClientService} from "CORE_PATH/services/client/client.service";
import {Connection} from "ENUMS_PATH/connection.enum";

export class ConnectionWatcher {
    private kBsQuota: number = 0;
    private quotaI: number = 0;

    constructor(private clientService: ClientService) {
        this.reset(this.clientService.getConnectionType());
    }

    /** Reset the estimated bandwith quota. */
    start(): void {
        this.end();
        this.clientService.registerConnectivityChangeHandler(this.reset);
        this.reset(this.clientService.getConnectionType());
    }

    end(): void {
        this.clientService.unregisterConnectivityChangeHandler(this.reset);
    }

    /** Estimate the bandwidth from the downloaded filesize and download duration. */
    addBandwidth(time: number, filesize: number): void {
        const kB: number = filesize / 1024;
        const duration: number = time / 1000;

        if (duration > 1) {
            const bandwidth: number = Math.ceil(kB / duration);
            this.kBsQuota = Math.max(1, (this.kBsQuota * this.quotaI + bandwidth) / ++this.quotaI);
        }
    }

    /**
     * Get a number that sets the given filesize in relation to the estimated bandwidth.
     * The basic assumption is that several files can be downloaded at the same time,
     * if they are small enough to be downloaded in less than a minute.
     * Downloading larger files at once, will only result in delay.
     */
    calcPortionByFilesize(filesize: number): number {
        const kB: number = filesize / 1024,
            portion: number = Math.ceil(kB / this.kBsQuota / 10);

        return Math.max(1, portion);
    }

    calcPortionByConnectivity(): number {
        return this.calcPortionByFilesize(15728640); // translates to 1.5 for the wifi default
    }

    private reset = (connection: Connection): void => {
        const defaultkBs: number = this.getConnectionDefault(connection);
        if (connection === Connection.WIFI && this.kBsQuota > defaultkBs) {
            return;
        }

        this.kBsQuota = this.getConnectionDefault(this.clientService.getConnectionType());
        this.quotaI = 0;
    };

    private getConnectionDefault(connection: Connection): number {
        return connection === Connection.WIFI ? 1024 : 512;
    }
}
