(function() {
    "use strict";

    angular.module("eob.core").factory("documentPage", DocumentPage);

    DocumentPage.$inject = [];

    function DocumentPage() {

        return {
            getPage
        };

        function getPage(number, documentId, viewer, view) {
            return new Page(number, documentId, viewer, view);
        }

        function Page(number, documentId, viewer, view) {

            let self = this;

            self.viewer = viewer;
            self.view = view;
            self.documentId = documentId;
            self.number = number;
            self.thumbnail = 0; // did the server manage to render a thumbnail?
            self.fullView = 0; // do we already have a full size view of self page?

            self.thumbnailWidth = 0; // dimensions are 0x0 if the thumbnail hasn't been created yet
            self.thumbnailHeight = 0;
            self.fullWidth = 0;
            self.fullHeight = 0;

            self.foreign_id = false;

            self.text = false;

            self.thumbnail_timestamp = null;
            self.image_timestamp = null;

            self.isPreloadingThumbnail = false;
            self.hasPreloadedFull = false;
            self.isPreloadingTextoverlay = false;

            self.hasNoText = false;
            self.wasAskedForText = false; // remember if the app already asked for self pages text to prevent multiple requests

            /**
             * Todo: document
             **/
            self.setFullView = function(state) {
                self.fullView = state;
            };

            /**
             *  set maximum possible dimensions for the the full view ONLY if no values have been set before (!= 0)
             **/
            self.setFullViewDimensions = function(width, height) {
                if (!self.fullWidth) {
                    self.fullWidth = width;
                }
                if (!self.fullHeight) {
                    self.fullHeight = height;
                }
            };

            /**
             *  set the thumbnail dimensions so we know how much padding etc. (todo: maybe unnecessary)
             **/
            self.setThumbnailDimensions = function(width, height) {
                if (!self.thumbnailWidth) {
                    self.thumbnailWidth = width;
                }
                if (!self.thumbnailHeight) {
                    self.thumbnailHeight = height;
                }
            };

            self.setThumbnail = function(state) {
                self.thumbnail = state;
            };

            self.isForeign = function() {
                return self.foreign_id !== false;
            };

            self.isReady = function() {
                return self.fullView == true;
            };
        }
    }
})();