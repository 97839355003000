import {Injectable} from "@angular/core";
import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {
    ContextMenuAction,
    ContextMenuItem,
    WorkflowContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ObjectType, ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {ObjectTypeRights} from "ENUMS_PATH/objecttype-rights.enum";
import {Inject} from "@angular/core";
import {InboxActionService} from "CORE_PATH/services/actions/inbox-action.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {DmsActionService} from "MODULES_PATH/dms/dms-action.service";
import {DmsContentService} from "MODULES_PATH/dms/dms-content.service";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {MultiMenu} from "MODULES_PATH/context-menu/interfaces/multi-menu.interface";
import {TodoCacheManagerService, TodoEnvironmentService, TodoModalDialogService} from "INTERFACES_PATH/any.types";
import {DmsDocumentService} from "MODULES_PATH/dms/dms-document.service";

/**
 * A service that provides functions to build the contextmenu actions (multiselection).
 */
@Injectable({providedIn: ContextMenuModule})
export class MultiMenuService {

    private multiMenu: MultiMenu = {} as MultiMenu;

    constructor(@Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("inboxActionService") private inboxActionService: InboxActionService,
                private contextMenuUtilsService: ContextMenuUtilsService, private clientService: ClientService,
                private dmsActionService: DmsActionService, private dmsContentService: DmsContentService,
                private dmsDocumentService: DmsDocumentService) {
    }

    async getMultiselectActions(items: ContextMenuItem[], contextMenuActions: ContextMenuAction[], context: string, additionalContextData: ContextData): Promise<ContextMenuAction[]> {
        const typeDefs: ObjectType[] = [];
        const contextItems: DmsDocument[] = [];

        for (const item of items) {
            if (item.osid) {
                const contextItem: DmsDocument = this.cacheManagerService.dmsDocuments.getById(item.osid);

                if (contextItem != void 0) {
                    contextItems.push(contextItem);
                    typeDefs.push(this.cacheManagerService.objectTypes.getById(contextItem.model.objectTypeId));
                }
            }
        }

        this.multiMenu.actions = contextMenuActions;
        this.multiMenu.typeDefs = typeDefs;
        this.multiMenu.contextItems = contextItems;
        this.multiMenu.env = this.environmentService.env;
        this.multiMenu.context = context;
        this.multiMenu.parentId = additionalContextData == void 0 ? null : additionalContextData.parentId;

        if (typeDefs.length) {
            if (this.clientService.isOnline()) {
                await this.contextMenuUtilsService.addExternalTools(this.multiMenu.contextItems, this.multiMenu.actions);
                this.addEmailActions();
                this.addDelete();
                this.addWorkflows();
                this.addExportActions();
                this.addPrintContent();
                this.addRemoveFavorites();
                this.addAbo();
                this.addArchiveAction();
            } else {
                this.addExportActions();
                this.addPrintContent();
            }
        }

        return this.multiMenu.actions;
    }

    private addDelete = (): void => {
        if (!/(result|folder)/gi.test(this.multiMenu.context)) {
            return;
        }

        for (const contextItem of this.multiMenu.contextItems) {
            const typeConfig: ObjectTypeConfig = this.cacheManagerService.objectTypes.getById(contextItem.model.objectTypeId).model.config;

            // we need every object to be deletable
            if (!this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_DELETE], contextItem, typeConfig) || contextItem.model.baseParameters.locked == "OTHERS") {
                return;
            }

            // the document is archived but we do not own the systemrole to delete it though :(
            if (contextItem.model.baseParameters.archiveState == "ARCHIVED" && !this.environmentService.userHasRole("R_CLNT_ARC_DELDOCS")) {
                return;
            }
        }

        this.multiMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.delete.title",
            "delete-dark", this.dmsActionService.deleteDmsObjects, this.multiMenu.contextItems, this.multiMenu.parentId));
    };

    private addWorkflows = (): void => {
        if (!this.environmentService.isWorkflowUser()) {
            return;
        }

        const workflows: WorkflowContextMenuItem[] = this.environmentService.getStartableWorkflows();
        const workflowActions: ContextMenuAction[] = [];
        const objectTypeList: string[] = [];

        for (const item of this.multiMenu.contextItems) {
            objectTypeList.push(item.model.internal);
        }

        // check if the user is a workflowuser and if we have at least one workflow configured
        if (workflows.length) {
            for (const workflow of workflows) {
                const icon: string = (workflow.iconId > 0) ? `custom-icon-${workflow.iconId}` : "workflow-startable-blue";

                if (this.environmentService.isAllowedObjectTypes(objectTypeList, workflow.workflowId)) {
                    workflowActions.push(this.contextMenuUtilsService.addAction(workflow.title, icon, this.inboxActionService.startWorkflow, this.multiMenu.contextItems, workflow.id));
                }
            }

            this.multiMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.workflow.title", "workflow-startable-blue", workflowActions));
        }
    };

    private addAbo = (): void => {
        for (const contextItem of this.multiMenu.contextItems) {
            if (!this.contextMenuUtilsService.allowAddAbo(contextItem, this.multiMenu.context, this.multiMenu.env)) {
                return;
            }
        }

        this.multiMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.notification.abo.title",
            "abonnieren", this.modalDialogService.addAboDialog, this.multiMenu.contextItems));
    };

    private addArchiveAction = (): void => {
        if (!this.environmentService.userHasRole("R_CLNT_ARC_CHANGESTATE")) {
            return;
        }

        const archivableItems: DmsDocument[] = [];
        const deArchivableItems: DmsDocument[] = [];

        for (const contextItem of this.multiMenu.contextItems) {
            const typeConfig: ObjectTypeConfig = this.cacheManagerService.objectTypes.getById(contextItem.model.objectTypeId).model.config;

            if (!this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_MODIFY, ObjectTypeRights.INDEXDATA_MODIFY], contextItem, typeConfig) || contextItem.model.objectCount == "0") {
                continue;
            }

            if (contextItem.model.isinWfTray) {
                continue;
            }

            if (contextItem.model.baseParameters.archiveState === "ARCHIVABLE") {
                deArchivableItems.push(contextItem);
            } else if (contextItem.model.baseParameters.archiveState === "NOT_ARCHIVABLE") {
                archivableItems.push(contextItem);
            }
        }

        // return in case these actions do not work for all items
        if (archivableItems.length + deArchivableItems.length < this.multiMenu.contextItems.length) {
            return;
        }

        if (archivableItems.length > 0) {
            this.multiMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.archive.set.title", "set-archivable", this.dmsActionService.setArchivable, archivableItems));
        }
        if (deArchivableItems.length > 0) {
            this.multiMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.archive.unset.title",
                "nicht-archivierbar-setzen", this.dmsActionService.setNotArchivable, deArchivableItems));
        }
    };

    private addEmailActions = (): void => {
        for (const dmsDocument of this.multiMenu.contextItems) {
            if (dmsDocument.model.isTypeless) {
                return;
            }
        }

        const mailActions: ContextMenuAction[] = [];

        if (this.multiMenu.env.email.sendLink) {
            mailActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.email.link.title", "mail-house", this.modalDialogService.sendLinkDialog, this.multiMenu.contextItems));
        }

        if (this.multiMenu.env.email.smtpServer && this.environmentService.userHasRole("R_CLNT_SENDEXT")) {
            let canSend = false;

            for (const dmsDocument of this.multiMenu.contextItems) {
                const typeConfig: ObjectTypeConfig = this.cacheManagerService.objectTypes.getById(dmsDocument.model.objectTypeId).model.config;

                // it is enough if the user has the right to export 1 selected item
                if (this.dmsDocumentService.hasRights([ObjectTypeRights.OBJECT_EXPORT], dmsDocument, typeConfig) && dmsDocument.model.baseParameters.objectCount > 0) {
                    canSend = true;
                    break;
                }
            }

            if (canSend) {
                mailActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.email.content.title",
                    "mail-earth", this.modalDialogService.sendContentDialog, this.multiMenu.contextItems));
            }
        }

        if (mailActions.length) {
            this.multiMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.email.title", "send-to", mailActions));
        }
    };

    private addPrintContent = (): void => {
        if (this.multiMenu.contextItems.filter(x => !x.model.hasContent).length == 0 && this.clientService.isDesktop()) {
            this.multiMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.print.documents.title",
                "print", this.dmsContentService.printContentAsync.bind(this.dmsContentService), this.multiMenu.contextItems));
        }
    };

    private addExportActions = (): void => {
        if (this.multiMenu.contextItems.length == 0) {
            return;
        }

        const exportActions: ContextMenuAction[] = [];
        const lastObjectTypeId: string = this.multiMenu.contextItems[0].model.objectTypeId;

        let canExportContent = true;
        let canExportIndexdata = true;
        let exportViolation = false;

        for (const contextItem of this.multiMenu.contextItems) {
            const typeConfig: ObjectTypeConfig = this.cacheManagerService.objectTypes.getById(contextItem.model.objectTypeId).model.config;
            canExportContent = this.dmsDocumentService.isExportContentAllowed(contextItem, typeConfig);

            if (contextItem.model.objectTypeId != lastObjectTypeId) {
                canExportIndexdata = false;
            }

            if (this.multiMenu.env.export.allowedTypes.length && this.multiMenu.env.export.allowedTypes.indexOf(contextItem.model.objectTypeId) == -1) {
                exportViolation = true;
            }
        }

        if (canExportIndexdata && this.dmsDocumentService.isExportIndexdataAllowed()) {
            exportActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.export.hitlist.title",
                "export-indexdata", this.modalDialogService.exportIndexDataDialog, this.multiMenu.contextItems));
        }

        if (canExportContent) {
            exportActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.export.content.title",
                "export-content", this.dmsContentService.exportContentAsync.bind(this.dmsContentService), this.multiMenu.contextItems));
        }

        if (this.multiMenu.env.actions.useMergePdf && canExportContent && !exportViolation) {
            exportActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.export.pdf.title", "export-pdf", this.dmsContentService.exportPdfAsync.bind(this.dmsContentService), this.multiMenu.contextItems));
        }

        if (exportActions.length) {
            this.multiMenu.actions.push(this.contextMenuUtilsService.addSubmenu("eob.contextmenu.action.export.title", "export", exportActions));
        }
    };

    private addRemoveFavorites = (): void => {
        let isFavorite = true;

        for (const contextItem of this.multiMenu.contextItems) {
            if (contextItem.model.isinWfTray || (contextItem != void 0 && contextItem.model.isTypeless)) {
                return;
            }
        }

        for (const contextItem of this.multiMenu.contextItems) {
            if (contextItem.model.isFavorite != true) {
                isFavorite = false;
                break;
            }
        }

        if (isFavorite) {
            this.multiMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.fav.remove.title",
                "remove-from-favs", this.dmsActionService.removeFavorites, this.multiMenu.contextItems));
        } else {
            this.multiMenu.actions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.fav.add.title", "add-to-favs", this.dmsActionService.addFavorites, this.multiMenu.contextItems));
        }
    };
}
