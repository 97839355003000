(function () {
    

    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

    angular.module("eob.framework").directive("eobDownloadContent", EobDownloadContent);

    EobDownloadContent.$inject = ["$state", "$stateParams", "$filter", "$compile", "$templateRequest", "backendService", "objectTypeService", "gridContentService", "dmsActionService", "stateService", "dmsContentService", "cacheManagerService"];

    function EobDownloadContent($state, $stateParams, $filter, $compile, $templateRequest, BackendService, ObjectTypeService, GridContentService, DmsActionService, StateService, DmsContentService, CacheManagerService) {
        return {
            restrict: "E",
            link(scope, element) {
                let osId = $stateParams.osid,
                    objectTypeId = $stateParams.objectTypeId,
                    rendition = $stateParams.rendition;

                let objectTypeIdRequest = !objectTypeId ? "" : `?objecttypeid=${objectTypeId}`;
                let searchItem = null;
                let objectItem = null;
                let templateElement = null;

                scope.gotoLocation = function () {
                    if (searchItem) {
                        StateService.goToLocationAsync({
                            id: searchItem.osid,
                            objectTypeId: searchItem.objectTypeId
                        }, null);
                    }
                };

                scope.gotoDashboard = function () {
                    $state.go("dashboard");
                };

                scope.exportContent = function () {
                    let objectItemDmsDocument = CacheManagerService.dmsDocuments.get(objectItem.osid);
                    if (objectItem) {
                        if (rendition && rendition == "pdf") {
                            DmsContentService.exportPdfAsync(objectItemDmsDocument);
                        } else {
                            DmsContentService.exportContentAsync(objectItemDmsDocument);
                        }
                    }
                };

                let downloadTemplateHtml = require("!raw-loader!./eob.download.template.html");
                let template = angular.element(downloadTemplateHtml);
                templateElement = $compile(template)(scope);

                BackendService.get(`/documents/search/${osId}${objectTypeIdRequest}`).then((response) => {
                    searchItem = response.data;
                    let searchDmsDocument = CacheManagerService.dmsDocuments.get(CacheManagerService.dmsDocuments.add(response.data))[0];

                    let objectInfo = CacheManagerService.objectTypes.getById(searchDmsDocument.model.objectTypeId);
                    if (!objectInfo) {
                        scope.errorOccurred = true;
                        scope.locationAvailable = false;
                        scope.downloadErrorMessage = $filter("translate")("eob.download.content.missing.rights");
                        angular.element(document.body).append(templateElement);
                    } else {
                        let fileCount = searchDmsDocument.model.fileProperties.fileCount;
                        if (fileCount > 0) {
                            let data = GridContentService.getListEntries(searchDmsDocument);
                            objectItem = data.rows[0];
                            scope.errorOccurred = false;
                            scope.locationAvailable = true;
                            angular.element(document.body).append(templateElement);
                            scope.exportContent();
                        } else {
                            scope.errorOccurred = true;
                            scope.locationAvailable = true;
                            scope.downloadErrorMessage = $filter("translate")("eob.download.content.no.content");
                            angular.element(document.body).append(templateElement);
                        }
                    }
                    $state.go("dashboard");

                }, (error) => {
                    scope.errorOccurred = true;
                    scope.locationAvailable = false;
                    scope.downloadErrorMessage = $filter("translate")("eob.download.content.unspecified.error");
                    angular.element(document.body).append(templateElement);
                });

                scope.$on("$destroy", () => {
                    if (templateElement) {
                        templateElement.remove();
                    }
                });
            },
            template: require("!raw-loader!./eob.download.content.html")
        };
    }
})();
