(function () {


    require("COMPONENTS_PATH/eob-quicksearch/eob.quicksearch.dir.js");
    require("SERVICES_PATH/eob.desktop.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");

    angular.module("eob.framework").directive("eobNavQuicksearch", EobNavQuicksearch);

    EobNavQuicksearch.$inject = ["desktopService", "asIniService", "environmentService", "notificationsService", "clientService"];

    function compareQuicksearch(a, b) {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    }

    function EobNavQuicksearch(DesktopService, AsIniService, EnvironmentService, NotificationsService, ClientService) {
        return {
            restrict: "E",
            link (scope, element, attrs) {
                scope.privateSearches = [];
                scope.publicSearches = [];
                scope.ready = false;

                scope.showAltLang = !EnvironmentService.uiLangIsObjectDefLang();
                scope.objectDefLang = EnvironmentService.getObjectDefinitionLanguage();

                function init() {
                    DesktopService.initAsync().then(() => {
                        let allQuicksearches = DesktopService.getQuickSearches(AsIniService.getExpandedQuicksearchIds());

                        for (let i in allQuicksearches) {
                            if (!EnvironmentService.isFulltextAvailable() && isOnlyFulltextQuickSearch(allQuicksearches[i].types)) {
                                continue;
                            }

                            if (allQuicksearches[i].isPublic) {
                                scope.publicSearches.push(allQuicksearches[i]);
                            } else {
                                scope.privateSearches.push(allQuicksearches[i]);
                            }
                        }

                        scope.publicSearches.sort(compareQuicksearch);
                        scope.privateSearches.sort(compareQuicksearch);
                        scope.ready = true;
                    }).catch((error) => {
                        if (error.type == "WEB_OFFLINE_ERROR") {
                            let handler = () => {
                                ClientService.unregisterConnectivityChangeHandler(handler);
                                init();
                            };

                            ClientService.registerConnectivityChangeHandler(handler);
                        } else {
                            NotificationsService.customError(error);
                        }
                    });
                }

                init();

                // TS, 03.07.2017, DODO-2775:
                // Soll feststellen, ob es sich um eine gespeicherte Suchanfrage
                // mit nur einem variablen Parameter vom Typ Volltext handelt.
                function isOnlyFulltextQuickSearch(qsTypes) {
                    if (qsTypes == void 0) {
                        return false;
                    }

                    if (Object.keys(qsTypes).length == 1 && qsTypes[-1] != void 0 && qsTypes[-1][0].tabIndex == -1) {
                        return true;
                    }

                    return false;
                }

                scope.saveExpandedState = function (search) {
                    search.expanded = !search.expanded;
                    AsIniService.setQuicksearchExpandedState(search.id.toString(), search.expanded)
                };
            },
            template: require("!raw-loader!./eob.nav.quicksearch.html")
        };
    }
})();
