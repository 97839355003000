import {FieldAddon, FieldControlType, FieldDataType} from "../enums/field.enum";
import {Tree} from "../interfaces/tree.interface";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {Field, GridQueryResult} from "INTERFACES_PATH/field.interface";
import {AutoCompleteConfig} from "MODULES_PATH/autocomplete/interfaces/autocomplete.interface";
import {FieldExpander} from "MODULES_PATH/form/interfaces/form-validation.interface";
import {FormControl} from "@angular/forms";
import {FeatureSet} from "MODELS_PATH/eob.feature.set.model";
import {Cell} from "MODULES_PATH/grid/interfaces/grid-cell-component.interface";
import {QueryResult} from "INTERFACES_PATH/query.interface";

export class FieldModel {
    addon: FieldAddon;
    addonTooltip: string;
    alwaysEnableAddon?: boolean;
    columns?: any[]; // this is defined as any until we create all column definitions into the column.interface.ts
    dbname: string;
    guid: string;
    hasLeadingZeros: boolean;
    hasRegEx: boolean;
    regEx?: string;
    internal: string;
    isBaseParam: boolean;
    isInvisibleField: boolean;
    isInvisibleText: boolean;
    isPassword: boolean;
    isRequired: boolean;
    isTextArea: boolean;
    isUnicode: boolean;
    isUnique: boolean;
    labelIcon: any; // return null in response  -> TODO: check which is the appropriate property type on BE
    maxLength: number;
    name: string;
    readonly: any; // return undefined in response  -> TODO: check which is the appropriate property type on BE
    tabIndex: number;
    taborder: string;
    textAlign: string;
    title: string;
    tooltip: string;
    type: FormFieldType | FieldControlType | FieldDataType;
    width?: number;
    pages?: any;
    fields?: any;
    iconClass?: string;
    pageControl?: FieldModel;
    pageIndex?: string;
    parentPage?: string;
    tree?: Tree;
    masterRadioInternal?: string;
    init?: any;
    groupInternal?: string;
    relatedControl?: any;
    icon?: string;
    baseParamName?: string;
    crossCheck?: any;
    isEmsField?: boolean;
    isEmsDigestField?: boolean;
    emsFieldName?: string;
    isCorrupted?: boolean;
    groupName?: string;
    iconId?: string;
    isMasterRadio?: boolean;
    trueVal?: string;
    falseVal?: string;
    activePageIndex?: number;
    placeholder?: string;
    dateFormat?: string;
    subType?: FieldDataType | string;
    config?: any;
    fieldContainerWidth?: number;
    addonButtonWidth?: number;
    uniqueFields: FieldExpander[];
    grid: Field;
    autoCompleteConfig?: AutoCompleteConfig;
    isGridCell?: boolean;
    pseudoCatalogDefinition: any;
    autoCompleteOpen?: boolean;
    hasPseudoCatalog?: boolean;
    row?: number;
    cell?: Cell;
    pseudoCatalogDataCallback?: (this: this, ...args: any) => void;
    control?: FormControl;
    radioGroupControls?: FormControl[];
    masterRadioControl?: FormControl;
    isNull?: boolean;
    isDisabled?: boolean;
    isInvisible?: boolean;

    featureSet?: FeatureSet;

    readonly NULL_VALUE_SEARCH_REGEX: RegExp = /(?:<>|!=|[]{0})(?:#NULL#)/i;
    readonly NEGATED_NULL_VALUE_SEARCH_REGEX: RegExp = /^(?:<>|!=)(?:#NULL#)$/i;

    constructor(data, featureSet: FeatureSet = new FeatureSet()) {
        const {
            addon,
            addonTooltip,
            columns,
            dbname,
            guid,
            hasLeadingZeros,
            hasRegEx,
            regEx,
            internal,
            isBaseParam,
            isInvisibleField,
            isInvisibleText,
            isPassword,
            isRequired,
            isTextArea,
            isUnicode,
            isUnique,
            labelIcon,
            maxLength,
            name,
            readonly,
            tabIndex,
            taborder,
            textAlign,
            title,
            tooltip,
            type,
            width,
            pages,
            fields,
            iconClass,
            pageControl,
            pageIndex,
            parentPage,
            tree,
            masterRadioInternal,
            init,
            groupInternal,
            relatedControl,
            icon,
            baseParamName,
            crossCheck,
            isEmsField,
            isEmsDigestField,
            emsFieldName,
            isCorrupted,
            groupName,
            iconId,
            isMasterRadio,
            trueVal,
            falseVal,
            activePageIndex,
            placeholder,
            dateFormat,
            subType,
            config,
            fieldContainerWidth,
            addonButtonWidth,
            uniqueFields,
            autoCompleteConfig,
            isGridCell,
            autoCompleteOpen,
            hasPseudoCatalog,
            row,
            cell,
            pseudoCatalogDataCallback
        }: FieldModel = data;

        this.addon = addon || undefined;
        this.addonTooltip = addonTooltip || undefined;
        this.columns = columns || null;
        this.dbname = dbname || null;
        this.guid = guid || null;
        this.hasLeadingZeros = hasLeadingZeros || false;
        this.hasRegEx = hasRegEx || false;
        this.regEx = regEx || undefined;
        this.internal = internal || undefined;
        this.isBaseParam = isBaseParam || false;
        this.isInvisibleField = isInvisibleField || false;
        this.isInvisibleText = isInvisibleText || false;
        this.isPassword = isPassword || false;
        this.isRequired = isRequired || false;
        this.isTextArea = isTextArea || false;
        this.isUnicode = isUnicode || false;
        this.isUnique = isUnique || false;
        this.labelIcon = labelIcon || null;
        this.maxLength = maxLength !== undefined ? maxLength : 0;
        this.name = name || undefined;
        this.readonly = readonly || undefined;
        this.tabIndex = tabIndex !== undefined ? tabIndex : null;
        this.taborder = taborder || undefined;
        this.textAlign = textAlign || undefined;
        this.title = title || undefined;
        this.tooltip = tooltip || undefined;
        this.type = type || null;
        this.width = width !== undefined ? width : null;
        this.pages = pages || null;
        this.fields = fields || [];
        this.iconClass = iconClass || undefined;
        this.pageControl = pageControl || null;
        this.pageIndex = pageIndex || undefined;
        this.parentPage = parentPage || undefined;
        this.tree = tree || null;
        this.masterRadioInternal = masterRadioInternal || undefined;
        this.init = init || null;
        this.groupInternal = groupInternal || undefined;
        this.relatedControl = relatedControl !== undefined ? relatedControl : null;
        this.icon = icon || undefined;
        this.baseParamName = baseParamName || undefined;
        this.crossCheck = crossCheck || null;
        this.isEmsField = isEmsField || false;
        this.isEmsDigestField = isEmsDigestField || false;
        this.emsFieldName = emsFieldName || undefined;
        this.isCorrupted = isCorrupted || false;
        this.groupName = groupName || undefined;
        this.iconId = iconId || undefined;
        this.isMasterRadio = isMasterRadio || false;
        this.trueVal = trueVal || undefined;
        this.falseVal = falseVal || undefined;
        this.activePageIndex = activePageIndex !== undefined ? activePageIndex : null;
        this.placeholder = placeholder || undefined;
        this.dateFormat = dateFormat || undefined;
        this.subType = subType || null;
        this.config = config || null;
        this.fieldContainerWidth = fieldContainerWidth !== undefined ? fieldContainerWidth : null;
        this.addonButtonWidth = addonButtonWidth !== undefined ? addonButtonWidth : null;
        this.uniqueFields = uniqueFields || [];
        this.autoCompleteConfig = autoCompleteConfig || null;
        this.isGridCell = isGridCell || false;
        this.autoCompleteOpen = autoCompleteOpen || false;
        this.hasPseudoCatalog = hasPseudoCatalog || false;
        this.row = row !== undefined ? row : null;
        this.cell = cell || null;
        this.pseudoCatalogDataCallback = pseudoCatalogDataCallback || null;
        this.featureSet = featureSet;
    }

    getGridQueryValue(value: GridQueryResult): GridQueryResult {
        value.type = "GRID";
        value.internalName = this.internal;
        return value;
    }

    getQueryValue(value: string): QueryResult {
        let internalName: string = this.internal;

        if (this.isNull) {
            if (this.isBaseParam) {
                value = "";
                internalName = this.internal.substring("baseParam".length, this.internal.length);
            } else {
                value = this.NULL_VALUE_SEARCH_REGEX.test(value) ? value : "";
            }
        }

        if (this.isUnicode) {
            value = new TextEncoder().encode(value).toString();
        }

        const negateRegexp: RegExp = /^(\*?)(!=|<>)/;
        const result: QueryResult = {
            internalName,
            value: this.processValue(internalName, value),
            negate: undefined
        };

        if (this.featureSet.contains("dms.search.special.value") && this.NULL_VALUE_SEARCH_REGEX.test(result.value)) {
            if (this.NEGATED_NULL_VALUE_SEARCH_REGEX.test(value)) {
                delete result.value;
                result.negate = true;
            } else {
                result.value = "";
            }
        } else if (this.featureSet.contains("dms.search.operator.notEqual") && negateRegexp.test(result.value)) {
            result.value = result.value.replace(negateRegexp, "$1");
            result.negate = true;
        }

        return result;
    }

    processValue(fieldName: string, value: string): string {
        const result: string = value;

        if (this.isBaseParamTextField(fieldName)) {
            return result.toUpperCase();
        }

        return result;
    }

    isBaseParamTextField(fieldName: string): boolean {
        let result: boolean = false;

        if (fieldName == "Creator") {
            result = true;
        } else if (fieldName == "Modifier") {
            result = true;
        } else if (fieldName == "Owner") {
            result = true;
        }

        return result;
    }
}

export class MockField extends FieldModel {
    isLabel?: boolean;
    isInitiallyRequired?: boolean;

    constructor(data: any, private formCatalogParserService: any) {
        super(data);

        const {
            tooltip,
            addon,
            isLabel,
            isInitiallyRequired
        }: MockField = data;

        this.tooltip = tooltip || this.title;
        this.addon = this.getAddon(addon);
        this.isLabel = isLabel || false;
        this.isInitiallyRequired = isInitiallyRequired || false;
    }

    getAddon(addon: any = ""): FieldAddon {
        if (addon !== void 0) {
            this.addon = addon;
            if (addon === FieldAddon.LIST || addon === FieldAddon.HIERARCHY || addon === FieldAddon.TREE) {
                this.formCatalogParserService.addCatalog(this, {}, undefined, false);
            }
        }

        return addon;
    }
}
