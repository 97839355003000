import {
    Component, ElementRef, Input, ViewChild, Renderer2, Inject, AfterViewInit, ChangeDetectorRef
} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {HitlistEvent} from "MODULES_PATH/hitlist/enums/hitlist-event.enum";
import { Subscription } from "rxjs";
import {OnDestroy} from "@angular/core";
import { delay, first } from "rxjs/operators";


@Component({
    selector: "eob-state-filter",
    templateUrl: "./eob-state-filter.component.html",
    styleUrls: ["./eob-state-filter.component.scss"]
})

export class EobStateFilterComponent implements AfterViewInit, OnDestroy {
    @Input() hitlistconfig: any;
    @ViewChild("filterInput", {static: false}) input: ElementRef<HTMLInputElement>;
    @ViewChild("filterIcon", {static: false}) button: ElementRef<HTMLElement>;
    headerContent: HTMLElement = document.body.querySelector(".dialog-header-content");
    headerPlugins: HTMLElement = document.body.querySelector(".state-header-plugins");
    isFilterOpen = false;
    filter = "";
    isPhone = false;
    sub: Subscription = new Subscription();
    animationTimeout: NodeJS.Timeout;

    constructor(@Inject("stateHistoryManager") public stateHistoryManager: any,
                private messageService: MessageService,
                protected clientService: ClientService,
                private renderer: Renderer2,
                private cd: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.isPhone = this.clientService.isPhone();
        const filter: string = this.stateHistoryManager.getCurrentConfig()?.filter;

        if (this.hitlistconfig?.filter) {
            this.filter = this.hitlistconfig.filter;
            // Due to legacy code leading to race conditions, we might get initialized after the hitlist is "ready"
            // To make sure the filter is applied, it's broadcasted here
            this.messageService.broadcast(HitlistEvent.UPDATE_FILTER_QUERY, this.hitlistconfig.filter);
        } else if (filter) {
            this.filter = filter;
        }
        if (this.isPhone) {
            this.isFilterOpen = this.filter.length > 0;
            this.setFilterStyling();
        } else {
            this.isFilterOpen = true;
        }
        // Broadcast needs to be delayed, as otherwise the hitlist might not be ready yet
        // (even though the event is called "hitlist.ready", it sometimes isn't completely ready :^))
        this.sub.add(this.messageService.subscribe("hitlist.ready", () => {
            this.messageService.broadcast(HitlistEvent.UPDATE_FILTER_QUERY, this.filter);
        }, first(), delay(100)));

        this.cd.detectChanges();
    }

    onKeyUp(): void {
        this.stateHistoryManager.updateConfig({filter: this.filter});
        this.messageService.broadcast(HitlistEvent.UPDATE_FILTER_QUERY, this.filter);
    }

    toggleFilter(): void {
        this.isFilterOpen = !this.isFilterOpen;
        if (this.isPhone) {
            this.setFilterStyling();
        }
    }

    setFilterStyling(): void {
        if (this.isFilterOpen) {
            this.renderer.setStyle(this.headerContent, "display", "none");
            this.renderer.setStyle(this.headerPlugins, "width", "100%");
            this.renderer.setStyle(this.renderer.parentNode(this.headerPlugins), "width", "100%");
            this.renderer.removeClass(this.button.nativeElement, "icon-24-hitlist-filter-active");
            this.renderer.addClass(this.button.nativeElement, "icon-24-hitlist-filter");

            this.cd.detectChanges();

            setTimeout(() => {
                this.input.nativeElement.focus();
            }, 0);
        } else {
            this.renderer.setStyle(this.headerContent, "display", "flex");
            this.renderer.setStyle(this.headerPlugins, "width", "auto");
            this.renderer.setStyle(this.renderer.parentNode(this.headerPlugins), "width", "auto");
            if (this.filter) {
                this.renderer.removeClass(this.button.nativeElement, "icon-24-hitlist-filter");
                this.renderer.addClass(this.button.nativeElement, "icon-24-hitlist-filter-active");
            }
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
