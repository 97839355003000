import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";

@Injectable({providedIn: BackendDms2Module})
export class Dms2StoredQueriesService {

    constructor(private httpClient: HttpClient) {
    }

    getHistory(): Observable<BackendObject> {
        throw new Error("not implemented yet");
    }

    execute(queryId: string, pageSize: number): Observable<BackendObject> {
        throw new Error("not implemented yet");
    }
}