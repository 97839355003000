import {HttpParams} from "@angular/common/http";

require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/scripting/eob.client.script.srv.js");

angular.module("eob.core").factory("searchService", SearchService);

SearchService.$inject = ["environmentService", "objectTypeService", "dmsDocumentService", "valueUtilsService",
    "organisationService", "toolService", "asIniService", "errorModelService", "cacheManagerService", "offlineCacheService", "offlineLocationCacheService",
    "fileCacheService", "clientService", "clientScriptService", "$filter",
    "backendSearchService", "backendFavoritesService", "backendStoredQueriesService", "queryBuilderService"];

// eslint-disable-next-line max-params
export default function SearchService(EnvironmentService, ObjectTypeService, DmsDocumentService, ValueUtilsService,
                                      OrganisationService, ToolService, AsIniService, ErrorModelService, CacheManagerService,
                                      OfflineCacheService, OfflineLocationCacheService, FileCacheService, ClientService, ClientScriptService,
                                      $filter, BackendSearchService, BackendFavoritesService, BackendStoredQueriesService, QueryBuilderService) {

    const NULL_VALUE_SEARCH_REGEX = /(?:<>|!=|[]{0})(?:#NULL#)/i;

    let service = {
        searchById,
        searchByIdsAsync,
        searchDistinctValues,
        searchObjectTypeId,
        searchWorkflowFileDocument,
        searchDMSDescriptionAsync,

        searchCheckedOutObjectsAsync,

        executeSearch,
        executeFulltextSearch,
        executeHistorySearch,
        executeFavoritesSearch,
        executeOfflineObjectsSearch,
        executeFailedSyncObjectsSearch,
        executeEntrySearch,
        executeExpertSearch,
        executeScriptQuerySearch,

        executeQuery,
        executeQuerySearchByIdsAsync,
        executeQueryWithScriptsAsync,
        getValidFieldsschema
    };

    return service;

    //region search functions
    /**
     * Returns a dmsDocument.
     *
     * @param {int} id - The object id of the desired DMS document.
     * @param {int} objectTypeId - The object type id of the desired DMS document.
     * @param {boolean} original - Return the unparsed AppConnector response.
     * @returns {promise} A promise resolved with a dmsDocument or the original backend data.
     */
    function searchById(id, objectTypeId, original) {
        let localResolve, localReject, resultPromise = new Promise((resolve, reject) => {
            localResolve = resolve;
            localReject = reject;
        });

        if (!DmsDocumentService.isValidOsid(id)) {
            localReject(ErrorModelService.createParameterError("id", id));
            return resultPromise;
        } else if (objectTypeId != void 0 && !ObjectTypeService.isTypeless(objectTypeId) && !ObjectTypeService.isValidObjectTypeId(objectTypeId)) {
            localReject(ErrorModelService.createParameterError("objectTypeId", objectTypeId));
            return resultPromise;
        } else {
            (async () => {
                try {
                    const response = await BackendSearchService.getObjectMetadata(id, objectTypeId, new HttpParams({
                        fromObject: {
                            refresh: "true",
                            // OBJECT_SYSTEMID, OBJECT_VERID are empty, if requested via systemfields and currently need to be requested via baseparams parameter
                            includeBaseParams: "true"
                        }
                    })).toPromise();
                    let document = original ? response : DmsDocumentService.createDmsDocument(response);
                    localResolve(document);
                } catch (error) {
                    localReject(error);
                }
            })();
        }
        return resultPromise;
    }

    /**
     * Returns dmsDocuments for the given osids.
     *
     * @param {object[]} items - An array of an osid - objectTypeId pair.
     * @param {boolean} original - Return the unparsed AppConnector response.
     * @returns {Promise} A promise resolved with an array of dmsDocuments, a warning and an error.
     */
    async function searchByIdsAsync(items, original = false) {
        let objectTypeIdMap = {};
        let objectIds = [];
        let noIds = "";
        let warning;
        let error;

        if (items == void 0 || items.length == 0) {
            return { result: [], error, warning };
        }

        for (let item of items) {
            if (parseInt(item.osid) < 0 || isNaN(item.osid)) {
                noIds += `${item.osid}, `;
                continue;
            }
            if (CacheManagerService.objectTypes[item.objectTypeId] == void 0) {
                objectIds.push(item.osid.toString());
                if (objectTypeIdMap["unknown"] == void 0) {
                    objectTypeIdMap["unknown"] = {
                        "ids": objectIds
                    };
                } else {
                    objectTypeIdMap["unknown"].ids = objectIds;
                }
                continue;
            }

            if (objectTypeIdMap[item.objectTypeId] == void 0) {
                objectTypeIdMap[item.objectTypeId] = {
                    "ids": []
                };
            }

            objectTypeIdMap[item.objectTypeId].ids.push(item.osid.toString());
        }

        if (noIds !== "") {
            noIds = noIds.substring(0, noIds.lastIndexOf(","));
            warning = $filter("translate")("eob.search.srv.object.no.objectid") + noIds;
        }

        if (Object.keys(objectTypeIdMap).length == 0) {
            return { result: [], error, warning };
        }

        let response;

        try {
            response = await executeQuerySearchByIdsAsync(objectTypeIdMap)
        } catch(err) {
            error = err.error.errorMessage ? err.error.errorMessage : $filter("translate")("eob.object.retrieve.error");
            return { result: [], error, warning };
        }

        if (response.length == 0) {
            return {error, warning};
        }

        let ids = "";

        if (objectIds.length > 0) {
            objectIds.forEach(id => {
                if (!response.find(p => p.osid === id)) {
                    ids += `${id}, `;
                }
            });
            if (ids !== "") {
                ids = ids.substring(0, ids.lastIndexOf(","));
                warning = $filter("translate")("eob.search.srv.object.rights") + ids;
            }
        }

        let osids = CacheManagerService.dmsDocuments.add(response);

        const result = original ? response : CacheManagerService.dmsDocuments.get(osids);

        return { result, warning, error };
    }

    /**
     * Use the right search call to find the dms object belonging to a WfFile.
     *
     * @param {string} id - The object ID of the dms object.
     * @param {int} objectTypeId - The object type ID of the dms object.
     * @param {string} trayLocation - A location enum that shows whether the dms object is located in the workflow tray.
     * @param {boolean} useActiveVariant - Always return the active variant.
     * @returns {Promise} A search promise that is resolved with the original dms object structure from the AppConnector response.
     */
    function searchWorkflowFileDocument(id, objectTypeId, trayLocation, useActiveVariant) {
        // a different search call is necessary for an item without location or without type or for an inactive variant
        if (!useActiveVariant || trayLocation == "2" || ObjectTypeService.isTypeless(objectTypeId)) {
            objectTypeId = ObjectTypeService.isTypeless(objectTypeId) ? null : objectTypeId

            return searchById(id, objectTypeId, true);
        }
        // search call to request cabinet fields, etc.
        else {
            let data = {
                id,
                "objectTypeIds": [objectTypeId],
                "type": "search"
            };

            // context type: search
            return executeSearch(data);
        }
    }

    /**
     * Returns an Array of distinct values
     *
     * @param data An object with field information, osid, objectTypeID
     * @returns {deferred.promise|{then, catch, finally}}
     */
    async function searchDistinctValues(data) {
        let localResolve, localReject, resultPromise = new Promise((resolve, reject) => {
            localResolve = resolve;
            localReject = reject;
        });

        let error;

        if (!ObjectTypeService.isValidObjectTypeId(data.objectTypeId)) {
            error = ErrorModelService.createParameterError("data.objectTypeId", data.objectTypeId);
        } else if (data.responseFields == void 0 || data.responseFields.length != 1) {
            error = ErrorModelService.createCustomError("WEB_NO_SEARCH_FIELD_SPECIFIED");
        } else {
            error = validateQueryData(data);
        }

        if (error != void 0) {
            localReject(error);

            return resultPromise;
        }

        try {
            let query = QueryBuilderService.createQueryWithData(data);
            let config = {
                fieldsschema: "DEF",
                pagesize: query.query.result_config.maxhits
            };

            let res = await service.executeQuery(query, config);
            let list = [];

            for (let item of res) {
                let value = item.ecmSimpleFields[0].value;
                list.push(value);
            }

            localResolve(list);
        } catch (e) {
            localReject(e);
        }

        return resultPromise;
    }

    function searchObjectTypeId(id) {
        let localResolve, localReject, resultPromise = new Promise((resolve, reject) => {
            localResolve = resolve;
            localReject = reject;
        });
        let query = {
            query: {
                osid: id.toString(),
                result_config: {
                    pagesize: 1,
                    maxhits: 1,
                    fieldsschema_mode: "MIN"
                }
            }
        };

        service.executeQuery(query).then((response) => {
            let objectTypeId;

            if (response.length > 0) {
                objectTypeId = response[0].objectTypeId;
                localResolve(objectTypeId);
            } else {
                localReject(ErrorModelService.createCustomError("WEB_OBJECT_NOT_FOUND", id));
            }

            return;
        }).catch(error => {
            localReject(error);
        });

        return resultPromise;
    }

    /**
     * Assembles a description for a dms object: displayName - confField1 - confField2 - ...
     * @param {number} id - osid of the dms object
     * @param {number} typeId - object type id of the dms object
     * @returns {deferred.promise|{then, catch, finally}}
     */
    async function searchDMSDescriptionAsync(id, typeId) {
        let data;
        let configuredFields = null;

        if (typeId != void 0) {
            configuredFields = CacheManagerService.objectTypes.getById(typeId).api.getConfiguredFields();
        }

        if (ClientService.isOnline()) {
            const query = {}
            query[typeId.toString()] = {ids: [id.toString()]}
            data = await BackendSearchService.searchByIds(query).toPromise()
        } else {
            data = (await OfflineCacheService.getById(id)).model;
        }

        let description = [data.displayName];

        for (let i in data.ecmSimpleFields) {
            // If we are offline we have fieldSchema=ALL an too many fields loaded.
            // The description should only go over the configured fields.
            let field = data.ecmSimpleFields[i];

            if (configuredFields[field.internalName]) {
                description.push(field.value);
            }
        }

        return description.join(AsIniService.getTreeNameSeparator());
    }

    /**
     * searches globally for all documents that are checked out by the current user
     * @returns {Promise<Array>}
     */
    async function searchCheckedOutObjectsAsync() {
        if (EnvironmentService.featureSet.contains("dms.search.preset.lock")) {
            try {
                return await BackendSearchService.getCheckedOutDocuments().toPromise();
            } catch (error) {
                console.error(error);
                return [];
            }
        }

        return searchCheckedOutObjectsManuallyAsync();
    }

    async function searchCheckedOutObjectsManuallyAsync() {
        let username = EnvironmentService.getSessionInfo().username;
        let navigation = await ObjectTypeService.getNavigation()
        let promises = [];
        for (let cabinet of navigation) {

            let queryData = {
                additionalQueries: []
            };

            for (let { objectTypeId } of cabinet.objectTypes) {
                let objectType = CacheManagerService.objectTypes.getById(objectTypeId);
                let config = objectType.model.config;

                if (config.mainType != 4 && !config.multiType) {
                    continue;
                }

                if (config.withoutPages) {
                    continue;
                }

                let configuredFields = objectType.api.getConfiguredFields();
                let fieldsArray = Object.keys(configuredFields)
                if (queryData.query == void 0) {
                    queryData.query = QueryBuilderService.createQuery(true)
                    queryData.query.objectTypeId = objectTypeId.toString();
                    queryData.query.baseparams = {
                        Locked: { value: [username] }
                    }

                    addResponseFields(queryData.query, fieldsArray)

                } else {
                    let additionalQuery = QueryBuilderService.createQuery(true);
                    additionalQuery.objectTypeId = objectTypeId.toString();
                    additionalQuery.baseparams = {
                        Locked: { value: [username] }
                    }

                    addResponseFields(additionalQuery, fieldsArray)

                    queryData.additionalQueries.push(additionalQuery)
                }
            }

            if (queryData.query == void 0) {
                continue;
            }

            promises.push(service.executeQuery(queryData, { fieldsschema: "DEF", rights: true }));
        }

        try {
            let data = await Promise.all(promises);

            let documents = []

            for (let response of data) {
                if (response.length > 0) {
                    documents = documents.concat(response)
                }
            }
            return documents;
        } catch (ex) {
            console.error(ex)
            return [];
        }
    }

    // region fulltext query
    async function getFulltextQuery(data) {
        let query;

        if (data.cabinetId != void 0) {
            let neededTypes = CacheManagerService.objectTypes.getById(data.cabinetId).model.config.childTypes;
            let childObjectTypes = CacheManagerService.objectTypes.get(neededTypes);

            query = createFulltextQuery(childObjectTypes, data.searchKey);
            query.objectTypeIds.push(data.cabinetId);
        } else {
            query = createFulltextQuery(CacheManagerService.objectTypes.getAll(), data.searchKey);
        }

        return query;
    }

    function createFulltextQuery(objectTypes, searchKey) {
        let vtxObjectTypes = objectTypes.filter(objectType => objectType.model.config.useFullText),
            objectTypeIds = vtxObjectTypes.map(objectType => objectType.model.osid);

        return {
            verbose: "true",
            includeFacets: "false",
            includePreview: "false",
            fieldSchema: "AUTO",
            objectTypeIds,
            query: searchKey
        };
    }

    function addResponseFields(query, fields) {
        for (let i in fields) {
            query.result_config.fieldsschema.push({ internalName: fields[i] });
        }
    }

    function getValidFieldsschema(data, isDistinct) {
        let validFieldsschema = ["ALL", "DEF", "AUTO", "MIN"];
        let fieldsschema = data.fieldsschema;

        if (fieldsschema != void 0) {
            if (validFieldsschema.indexOf(fieldsschema) >= 0) {
                return fieldsschema;
            }

            console.warn("The fieldsschema must be either 'DEF', 'ALL', 'AUTO' or 'MIN'");
        }

        if (isDistinct) {
            fieldsschema = "DEF";
        } else if (data.responseFields != void 0 && data.responseFields.length > 0) {
            fieldsschema = "DEF";
        } else {
            fieldsschema = "ALL";
        }

        return fieldsschema;
    }

    //region execute search

    async function executeSearch(data) {
        if (data.flat) {
            if (ClientService.isOnline()) {
                return (await BackendSearchService.getFolderContent(data.objectId, EnvironmentService.env.hitlist.maxsize).toPromise());
            }

            return await OfflineLocationCacheService.getFlatFolderOrRegisterContent(data.objectId);
        } else {
            let query = QueryBuilderService.createFormDataQuery({
                formDataTypes: data.formDataTypes,
                objectTypeIds: data.objectTypeIds,
                activePage: data.activePage,
                fulltext: data.fulltext,
                id: data.id,
                context: "search"
            });

            return await service.executeQuery(query, {
                pagesize: EnvironmentService.env.hitlist.maxsize,
                fieldsschema: "DEF"
            });
        }
    }

    async function executeScriptQuerySearch(data) {
        let responseScript = await service.executeQuery(data.searchQuery, {
            pagesize: EnvironmentService.env.hitlist.maxsize,
            fieldsschema: "DEF",
            rights: true
        });

        return responseScript;
    }

    async function executeExpertSearch(data) {
        return await BackendStoredQueriesService.execute(data.queryId, EnvironmentService.env.hitlist.maxsize).toPromise();
    }

    async function executeEntrySearch(data) {
        let query = QueryBuilderService.createFormDataQuery({
            id: data.id,
            activePage: data.objectTypeId
        });

        return await service.executeQuery(query, {
            pagesize: EnvironmentService.env.hitlist.maxsize,
            fieldsschema: "DEF"
        });
    }

    async function executeFavoritesSearch() {
        if (ClientService.isOffline()) {
            return []
        }

        return await BackendFavoritesService.getFavorites().toPromise();
    }

    async function executeHistorySearch() {
        return await BackendStoredQueriesService.getHistory().toPromise();
    }

    async function executeFulltextSearch(data) {
        let queryVtx = await getFulltextQuery(data);
        let responseVtx = await BackendSearchService.searchVtx(Object.assign(queryVtx, { maxHits: EnvironmentService.env.hitlist.maxsize })).toPromise();
        let result = responseVtx.verboseResult;

        // create reference of the searchkey to each element to later use this in the viewer
        for (let i in result) {
            result[i].searchKey = data.searchKey;
        }

        return result;
    }

    async function executeFailedSyncObjectsSearch() {
        const failedSyncObjects = await OfflineCacheService.getFailedOfflineData();
        let searchResult = {};

        if (ClientService.isOffline()) {
            searchResult.result = await OfflineCacheService.getByIds(failedSyncObjects.map(obj => obj.osid));
        } else {
            let { result, warning, error } = await searchByIdsAsync(failedSyncObjects);
            searchResult.result = result;
            searchResult.warning = warning;
            searchResult.error = error;
        }

        for (const dmsDocument of searchResult.result) {
            dmsDocument.model.syncState = failedSyncObjects.find(obj => obj.osid == dmsDocument.model.osid).failedState;
        }

        return searchResult;
    }

    async function executeOfflineObjectsSearch() {
        if (ClientService.isOffline() && ClientService.isLocalClient()) {
            return OfflineCacheService.getMainOfflineObjects();
        }
        if (AsIniService.isSynchronizeFavoritesOffline()) {
            return await BackendFavoritesService.getFavorites().toPromise();
        }
        return [];
    }

    // endregion

    /**
     * Execute the beforeStartQuery script, if one exists.
     * Then execute the query depending on the script and return the query and script result.
     *
     * @access public
     * @param {object} data - The formData.
     * @param {object=} params - A map of additional query parameter- parameter name to value.
     * @throws {CustomError} If the script fails a custom error will be thrown.
     * @returns {Promise<{resultCode: number, searchResult: Array}>} Is resolved with the script result code and the search result.
     * The search result may be empty, if no search was executed.
     */
    async function executeQueryWithScriptsAsync(data, params) {
        if (data == void 0 || data.objectTypeIds == void 0) {
            console.warn("Cannot identify the objecttype id for the beforeStartQuery script.");
            throw ErrorModelService.createCustomError("WEB_PARAMETER_INVALID", "data.objectTypeIds");
        }
        data.context = "search";
        let objectTypeId = data.objectTypeIds[0],
            formDataQuery = QueryBuilderService.createFormDataQuery(data),
            searchResult;

        let { resultCode, query } = await ClientScriptService.executeBeforeStartQueryScriptAsync(objectTypeId, data.description, formDataQuery);

        if (resultCode >= 0) {
            params = Object.assign({ pagesize: EnvironmentService.env.hitlist.maxsize, fieldsschema: "DEF" }, params);

            searchResult = await BackendSearchService.search(query, params).toPromise();
        }

        return {
            resultCode,
            searchResult
        }
    }

    async function executeQuerySearchByIdsAsync(query) {
        return BackendSearchService.searchByIds(query).toPromise();
    }

    function executeQuery(query, config) {
        return (BackendSearchService.search(query, config)).toPromise();
    }

    //endregion

    //region validation

    function validateQueryData(data) {
        if (!ObjectTypeService.isValidObjectTypeId(data.objectTypeId)) {
            return ErrorModelService.createParameterError("data.objectTypeId", data.objectTypeId);
        }
    }

    //endregion
}
