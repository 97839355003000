import {Injectable} from "@angular/core";
import {Subject, Subscription, MonoTypeOperatorFunction, Observable} from "rxjs";
import {filter, map, first} from "rxjs/operators";

interface Message {
    type: string;
    payload: any;
}

type MessageCallback<T> = (payload: T) => void;

/**
 * A service for managing events.
 */
@Injectable({ providedIn: "root" })
export class MessageService {
    private handler: Subject<Message> = new Subject<Message>();

    /**
     * Send a message to all registered recipients.
     *
     * @param type - The message key.
     * @param payload - The message content.
     */
    broadcast<T>(type: string, payload?: T): void {
        this.handler.next({type, payload});
    }

    /**
     * Register a callback function for a specific message key.
     *
     * @param type - The message key.
     * @param callback - Function to be called, when a message is sent.
     * @param operators optional `MonoTypeOperatorFunction<T>`, which can further filter the messages (e.g. only subscribe for a single message using first() and automatically unsubscribing afterwards
     */
    subscribe<T>(type: string, callback: MessageCallback<T>, ...operators: Array<MonoTypeOperatorFunction<T>>): Subscription {
        let observable: Observable<T> = this.handler.pipe(filter(message => message.type === type), map(message => message.payload));
        for(const op of operators) {
            observable = observable.pipe(op);
        }
        return observable.subscribe(callback);
    }

    /**
     * Subscribes to first message, then unsubscribes automatically
     *
     * @param {string} type
     * @param {MessageCallback<T>} callback
     * @returns {Subscription}
     */
    subscribeFirst<T>(type: string, callback: MessageCallback<T>): Subscription {
        return this.subscribe(type, callback, first());
    }

    /**
     * Returns the observable of the message subject, which can be filtered individually as needed
     *
     * @returns {Observable<Message>}
     */
    getObservable(): Observable<Message> {
        return this.handler.asObservable();
    }
}