(function () {
    angular.module("eob.core").factory("touchHandlerService", TouchHandlerService);

    TouchHandlerService.$inject = ["$q", "$injector", "$filter", "$eobConfig", "clientService", "stateHistoryManager"];

    // eslint-disable-next-line max-params
    function TouchHandlerService($q, $injector, $filter, $eobConfig, ClientService, StateHistoryManager) {
        let doubleClickTarget = null;
        let storedTouchEvent;
        let touchHappened = false;
        let isIos = ClientService.isiOs();
        let isPhone = ClientService.isPhone();

        return {
            getTouchEvent,
            bindTouchEvents,
            initSwipeNavigation
        };

        function getTouchEvent() {
            return storedTouchEvent;
        }

        function storeTouchEvent(event) {
            storedTouchEvent = event;
        }

        function preventGhostClick(event) {
            angular.element(event.target).on("touchend click", () => {
                if (!touchHappened) {
                    touchHappened = true;
                    setTimeout(() => {
                        touchHappened = false
                    }, 300);
                }
            })
        }

        function bindTouchEvents(element) {

            if (element == void 0) {
                return;
            }

            // Timer for long touch detection
            let timerLongTouch;
            // Long touch flag for preventing "normal touch event" trigger when long touch ends
            let longTouch = false;
            let scrollDistance = 0;
            let scrollStart = 0;
            let isScrolling = false;
            let isDoubleClick = false;
            let doubleClickTimer = null;
            let lastTouchPosY;
            // let clickedRow = null;
            // let currentRowIndex = null;

            element.bind("touchstart", (event) => {

                // Timer for long touch detection
                scrollStart = event.originalEvent.touches.item(0).clientY;
                storeTouchEvent(event);

                clearTimeout(doubleClickTimer);
                // console.log("touchstart")
                // clickedRow = angular.element(event.target).closest(".tree-list-node");
                // currentRowIndex = clickedRow.attr("number");
                if (doubleClickTarget != void 0 && doubleClickTarget.is(event.target)) {

                    isDoubleClick = true;
                    element.dblclick()//callbacks.onDoubleClick(event)
                    // console.log("double click done")
                } else {
                    doubleClickTarget = element
                }

                doubleClickTimer = setTimeout(() => {
                    doubleClickTarget = null;
                     preventGhostClick(event);
                    // clickedRow = null;
                    // currentRowIndex = null;
                    isDoubleClick = false;
                }, 300);

                if (!isDoubleClick) {
                    timerLongTouch = setTimeout(() => {
                        // Flag for preventing "normal touch event" trigger when touch ends.
                        event.cancelable && event.preventDefault();
                        longTouch = true;

                        // console.log("execute contextmenu")
                        // simulated rightclick behavior
                        if (isPhone) {
                            element.contextmenu();
                        } else {
                            let el = event.originalEvent.target;
                            let x = event.originalEvent.touches[0].clientX,
                                y = event.originalEvent.touches[0].clientY

                            event.stopImmediatePropagation();
                            event.preventDefault();
                            event.stopPropagation();

                            //throwing the standard contextmenu event
                            if (!element.hasClass("form-row-input") && !(angular.element(event.target).closest(".ag-cell").length)) {
                                let newEvent = new MouseEvent("contextmenu", {
                                    bubbles: true,
                                    cancelable: true,
                                    clientX: x,
                                    clientY: y
                                })
                                el.dispatchEvent.call(el, newEvent)
                                // element.contextmenu() //callbacks.onRightClick(event);
                            }
                        }
                        preventGhostClick(event);
                    }, 500);
                }
            });

            element.bind("touchmove", (event) => {
                let clientY = event.originalEvent.touches.item(0).clientY;
                scrollDistance += clientY - scrollStart;

                // this is an ugly fix for ios. The group nodes in ag-grid cannot be scrolled otherwise.
                if (Math.abs(scrollDistance) > 10) {
                    let scrollTop;
                    let lastScrollIncrement;
                    let agGridViewport = element.find(".ag-body-viewport");
                    if (isIos && angular.element(event.target).hasClass("ag-row-group")) {
                        scrollTop = agGridViewport.scrollTop();
                        lastTouchPosY = lastTouchPosY == void 0 ? clientY : lastTouchPosY;
                        lastScrollIncrement = clientY - lastTouchPosY;
                        agGridViewport.scrollTop(scrollTop - lastScrollIncrement);
                        lastTouchPosY = clientY;
                    }
                    isScrolling = true;
                    // all we want to do is cancel the longpress event because the user scrolled somewhere
                    clearTimeout(timerLongTouch);
                    longTouch = false;
                    isDoubleClick = false;
                }
            });

            element.bind("touchend", (event) => {
                lastTouchPosY = null;
                let targetElement = angular.element(event.target);
                // Prevent default behavior on tree list node when we can (hitlist for example doesn't want this though except for the multi selection checkboxes)
                let isGridCell = targetElement.closest("div").hasClass("ag-cell");
                let isMultiselectCheckbox = targetElement.closest("div").hasClass("ag-wrapper") && targetElement.hasClass("ag-checkbox-input");
                let isTreeNode = angular.element(event.target).closest("li").hasClass("tree-list-node");

                if (isGridCell && (!isMultiselectCheckbox || isTreeNode) && StateHistoryManager.getCurrentStateContext() == "location") {
                    event.cancelable && event.preventDefault();
                    touchHappened = false;
                } else if (!ClientService.isPhone()){
                    touchHappened = true;
                }

                let isUserManagement = targetElement.hasClass("group-cell") || targetElement.find(".group-cell").length > 0;
                isUserManagement = isGridCell && (isUserManagement || targetElement.hasClass("icon-16-user-delete") || targetElement.find(".icon-16-user-delete").length > 0);

                if(isUserManagement){
                    event.preventDefault();
                    touchHappened = false;
                }

                if(isIos){
                    let isTouchKebab = (targetElement.hasClass("icon-16-kebab-dark") || targetElement.find(".icon-16-kebab-dark").length > 0);

                    if (isTouchKebab) {
                        touchHappened = true;
                        preventGhostClick(event);
                    }
                }

                // If timerLongTouch is still running, then this is not a long touch
                // so stop the timer
                clearTimeout(timerLongTouch);

                if (!longTouch && !isScrolling && !isDoubleClick) {
                    // workaround for "emptyspace" contextmenu on IOS
                    if (isIos && !ClientService.isPhone()) {
                        if (touchHappened) {
                            return;
                        } else {
                            touchHappened = true;
                            event.target.click();
                            setTimeout(() => {
                                touchHappened = false;
                            }, 0);
                        }
                    } else {
                        if(ClientService.isPhone()) {
                            event.stopPropagation();
                            event.stopImmediatePropagation();
                            event.preventDefault();

                            touchHappened = false;
                        }

                        if (!touchHappened) {
                            event.target.click();
                        }
                    }
                }


                longTouch = false;
                isScrolling = false;
                isDoubleClick = false;

                scrollDistance = 0;
                scrollStart = 0;
            })

        }

        function initSwipeNavigation() {
            if (!ClientService.isMobile()) {
                return;
            }

            let body = document.body;
            let main = angular.element(body).find("eob-main");
            let swipeNav = angular.element(document.body).find(".swipe-nav");
            let xSwipeStart;
            let swipeWidth;
            let mouse = {
                isDown: false,
                inLeft: false,
                inRight: false,
                downTimestamp: null
            };

            let width, thresholdStart, thresholdEnd, thresholdMilliseconds;

            function resize() {
                setTimeout(() => {
                    width = window.innerWidth;
                    thresholdStart = 0.05 * width;//within 5% of screen width
                    thresholdEnd = 0.50 * width;//beyond 50% of screen width
                    thresholdMilliseconds = 50;//min swiping time to triger
                }, 200)
            }

            window.addEventListener("orientationchange", resize, false);
            document.addEventListener("resize", resize, false);
            resize(); //initialize

            body.addEventListener("touchstart", (e) => {
                swipeWidth = null;
                mouse.isDown = true;
                mouse.downTimestamp = Date.now();

                let x = e.touches[0].pageX;
                xSwipeStart = x;
                if (x < thresholdStart) {
                    mouse.inLeft = true;

                } else if (x > width - thresholdStart) {
                    mouse.inRight = true;
                }
            });

            body.addEventListener("touchmove", (e) => {
                let x = e.touches[0].pageX;
                if (mouse.inLeft) {
                    swipeWidth = x - xSwipeStart;
                    main.css("transform", `translate(${swipeWidth}px , 0px)`);
                    swipeNav.css("width", `${swipeWidth}px`);
                    swipeNav.addClass("visible left");
                    if (x > thresholdEnd) {
                        swipeNav.addClass("highlight");
                    } else {
                        swipeNav.removeClass("highlight");
                    }

                } else if (mouse.inRight) {
                    swipeWidth = xSwipeStart - x;
                    main.css("transform", `translate(${-swipeWidth}px , 0px)`);
                    swipeNav.css("width", `${swipeWidth}px`);
                    swipeNav.addClass("visible right");
                    if (x < width - thresholdEnd) {
                        swipeNav.addClass("highlight");
                    } else {
                        swipeNav.removeClass("highlight");
                    }
                }
            });

            body.addEventListener("touchend", (e) => {
                if (!mouse.inLeft && !mouse.inRight) {
                    return;
                } else if (swipeWidth == void 0) {
                    mouse.inLeft = false;
                    mouse.inRight = false;
                    return;
                }

                let x = e.changedTouches[0].pageX;
                if (mouse.inLeft && x > thresholdEnd) {
                    main.addClass("swipe-out-right");
                    mouse.inLeft = false;
                    swipeEdgeFromLeft();
                } else if (mouse.inRight && x < width - thresholdEnd) {
                    mouse.inRight = false;
                    main.addClass("swipe-out-left");
                    swipeEdgeFromRight();
                } else {

                    if (mouse.inLeft) {
                        main.css({"transform": `translate(${0}px , 0px)`, "left": `${swipeWidth}px`});
                        main.addClass("nav-swipe-cancel");
                    } else {

                        main.css({"transform": `translate(${0}px , 0px)`, "right": `${swipeWidth}px`});
                        main.addClass("nav-swipe-cancel");
                    }
                    swipeNav.removeClass("visible left right");
                }
                swipeNav.removeClass("visible left right");
                setTimeout(() => {
                    main.css("transform", "none"); //needs to be none instead of 0, 0 otherwise it will create style issues with z indexes on ios
                    main.removeClass("swipe-out-right swipe-out-left nav-swipe-cancel");
                    main.css("left", "0");
                    main.css("right", "0");
                }, 500);

                mouse.isDown = false;
                mouse.inLeft = false;
                mouse.inRight = false;
                mouse.downTimestamp = null;
            });
        }

        // window.dispatchEvent(new Event("touchend")) - workaround for the issue in eob-state-touch-refresh component
        function swipeEdgeFromLeft() {
            history.back();
            window.dispatchEvent(new Event("refreshTouchend"));
        }

        function swipeEdgeFromRight() {
            history.forward();
            window.dispatchEvent(new Event("refreshTouchend"));
        }
    }
})();
