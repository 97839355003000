(function() {
    "use strict";
    angular.module("eob.core").factory("dvRevisitsService", DvRevisitsService);

    DvRevisitsService.$inject = ["$location", "$q", "backendService"];

    function DvRevisitsService($location, $q, BackendService) {

        let data;

        let service = {
            load,
            refresh,
            getData
        };
        return service;

        function getData() {
            return data.revisitsData;
        }

        function load(osid) {
            let deferred = $q.defer();
            let promise = deferred.promise;

            let sessionGuid = $location.search().sessionguid;
            let dataUrl = `/notifications/revisitObjects/${osid}`;

            if (sessionGuid != void 0) {
                dataUrl += `?sessionguid=${sessionGuid}`;
            }

            BackendService.get(dataUrl).then((res) => {
                let newData = {};
                newData.revisitsData = res.data;
                newData.docid = osid;
                data = newData;
                deferred.resolve(res.data);
            }, (data) => {
                if (data.status == -1) {
                    return;
                }
                deferred.reject("error");
            });

            return promise;
        }

        function refresh(osid) {
            data = null;
            return load(osid);
        }
    }
})();