import {Inject, Injectable} from "@angular/core";
import {DmsActionService} from "MODULES_PATH/dms/dms-action.service";
import {OsClientService} from "MODULES_PATH/dashlet/services/os-client.service";
import {DapiServiceProvider, DashletMessage, DashletMessageConfig} from "MODULES_PATH/dashlet/interfaces/dashlet-api.interface";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {TodoCacheManagerService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {HitlistParameters} from "MODULES_PATH/dashlet/interfaces/hitlist-parameters.interface";

@Injectable()
export class DashletApiService {
    private dapi: DapiServiceProvider = {};

    private dapiFn: DapiServiceProvider = {
        openLocation: this.dmsActionService.openLocation,
        openIndexData: this.dmsActionService.openIndexData,
        getSelectedObjects: this.stateHistoryManager.getSelectedItems,
        refreshHitListObjects: (ids: string[]) => this.fetchDmsDocuments(ids),
        openHitListByIds: (params: HitlistParameters) => {
            const callParams: HitlistParameters = Object.assign({
                objects: [],
                inNewTab: false,
                description: "",
                title: "",
                executeSingleHitAction: false
            }, params);
            return this.dmsActionService.openResultListByIds(callParams.inNewTab,
                callParams.objects.map(x => ({osid: x.objectId, objectTypeId: x.objectTypeId})),
                callParams.title, callParams.description, callParams.executeSingleHitAction);
        }
    };

    constructor(private dmsActionService: DmsActionService,
                private osClient: OsClientService,
                private clientService: ClientService,
                private messageService: MessageService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService) {
        this.initDapi();
    }

    async handleDashletMessage(dashletId: string, data: DashletMessage): Promise<void> {
        const fnKey: string = data[0];
        const params: unknown[] = Array.isArray(data[1]) ? data[1] : [data[1]];
        const config: DashletMessageConfig = data[2];

        const promise: Promise<unknown> = this.executeDapiFn(fnKey, params);
        if (config?.msgId) {
            let result: unknown;
            try {
                result = { result: await promise };
            } catch(error) {
                result = { error: error?.message };
            }
            this.messageService.broadcast(ViewerEvent.SEND_DASHLET_EVENT, {
                dashletId,
                eventKey: dashletId,
                data: ["response", result, {msgId: config.msgId}]
            });
        }
    }

    getDapi(): DapiServiceProvider {
        return this.dapi;
    }

    executeDapiFn(key: string, params: unknown[]): Promise<unknown> {
        const dapiFn: { service: DapiServiceProvider; fn: string } = this.getDapiFn(key);

        if (dapiFn == undefined) {
            throw new Error(`${key} - not found!`);
        }

        const {service, fn} = dapiFn;

        return service[fn](...params);
    }

    private getDapiFn(key: string): { service: DapiServiceProvider; fn: string } {
        const keyParts: string[] = key.split(".");

        let service: DapiServiceProvider;

        if (keyParts.length > 1) {
            for (let i: number = 0; i < keyParts.length - 1; i++) {
                service = this;
                service = service[keyParts[i]];

                if (service == undefined) {
                    return undefined;
                }
            }
        } else {
            service = this.dapiFn;
        }

        const fn: string = keyParts[keyParts.length - 1];

        if (service[fn] == undefined) {
            return undefined;
        }

        return {service, fn};
    }

    private initDapi(): void {
        const dapiServices: Array<[string, DapiServiceProvider]> = Object.entries({
            osClient: Object.getPrototypeOf(this.osClient) // to stay rich client dashlets compatible
        });

        for (const fnName of Object.getOwnPropertyNames(this.dapiFn)) {
            this.dapi[fnName] = fnName;
        }

        for (const [serviceName, service] of dapiServices) {
            this.dapi[serviceName] = {};

            for (const fnName of Object.getOwnPropertyNames(service)) {
                if (fnName == "constructor") {
                    continue;
                }

                this.dapi[serviceName][fnName] = `${serviceName}.${fnName}`;
            }
        }
    }

    async fetchDmsDocuments(ids: string[]): Promise<boolean> {
        if (Array.isArray(ids)) {
            if (this.clientService.isDesktop()) {
                window.electron.refreshHitListObjects(ids);
            } else {
                await this.cacheManagerService.dmsDocuments.getOrFetchByIds(ids, true);
            }

            return true;
        }

        return false;
    }
}
