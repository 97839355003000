import {Subject} from "rxjs";

(function() {
    "use strict";

    angular.module("eob.core").factory("dvWorkflowService", DvWorkflowService);

    DvWorkflowService.$inject = ["$location", "$q", "backendService", "$rootScope"];

    function DvWorkflowService($location, $q, BackendService, $rootScope) {

        let data;
        let workflow = new Subject();

        let service = {
            load,
            getData,
            workflow
        };
        return service;

        function getData() {
            return data.wfData;
        }

        function load(osid) {
            let deferred = $q.defer();
            let promise = deferred.promise;

            let sessionGuid = $location.search().sessionguid;
            let dataUrl = `/workflows/processes/${osid}`;

            if (sessionGuid != void 0) {
                dataUrl += `?sessionguid=${sessionGuid}`;
            }

            BackendService.get(dataUrl).then((res) => {
                let newData = {};
                newData.wfData = res.data;
                newData.docid = osid;
                data = newData;
                workflow.next({size: (res.data || []).length, osid: osid});
                $rootScope.$broadcast("dv.workflows.update", null);
                deferred.resolve(res.data);
            }, (data) => {
                if (data.status == -1) {
                    return;
                }
                deferred.reject("error");
            });

            return promise;
        }
        
    }
})();