export function requireControllers() {
    require("CLIENT_PATH/states/eob-dashboard/eob.dashboard.ctrl.js");
    require("CLIENT_PATH/states/eob-create/eob.create.ctrl.js");
    require("CLIENT_PATH/states/eob-download/eob.download.ctrl.js");
    require("CLIENT_PATH/states/eob-folder/eob.folder.ctrl.js");
    require("CLIENT_PATH/states/eob-indexdata/eob.indexdata.ctrl.js");
    require("CLIENT_PATH/states/eob-search/eob.search.ctrl.js");
    require("CLIENT_PATH/states/eob-usermanagement/eob.usermanagement.ctrl.js");
    require("CLIENT_PATH/states/eob-usermanagement-upsert/eob.usermanagement.upsert.ctrl.js");
    require("CLIENT_PATH/states/eob-variant/eob.variant.ctrl.js");
    require("CLIENT_PATH/states/eob-workflow/eob.workflow.ctrl.js");
    require("CLIENT_PATH/states/eob-workflow-info/eob.workflow.info.ctrl.js");

    angular.module("eob.client").config(["$urlRouterProvider", ($urlRouterProvider) => {
        $urlRouterProvider.otherwise("dashboard", "dashboard");

        $urlRouterProvider.when("", "dashboard");
        $urlRouterProvider.when("/", "dashboard");
    }]);
}
