import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {StateParams, StateService, UIRouterGlobals} from "@uirouter/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ObjectLinkKind, ObjectLinkResponse} from "CORE_PATH/backend/interfaces/object-link.interface";
import {from, Observable, of, ReplaySubject, Subject, Subscription} from "rxjs";
import {DmsDocumentService} from "MODULES_PATH/dms/dms-document.service";
import {catchError, map, mergeAll, reduce, switchMap} from "rxjs/operators";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {HitlistLoadingState} from "MODULES_PATH/hitlist/model/loading-state.model";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {BackendObjectService} from "CORE_PATH/backend/services/object/backend-object.service";
import {BackendSearchService} from "CORE_PATH/backend/services/search/backend-search.service";
import {GridData} from "MODULES_PATH/grid/interfaces/grid-data.interface";
import {IdPair} from "INTERFACES_PATH/id-pair.interface";
import {BackendSearchIdRequest} from "CORE_PATH/backend/interfaces/search-requests/backend-search-id-request";

@Component({
    selector: "eob-object-references",
    templateUrl: "./object-references.component.html",
    styleUrls: ["./object-references.component.scss"]
})
export class ObjectReferencesComponent implements OnInit, OnDestroy {
    stateTitle: string;
    stateDescription: string;
    dataReady: boolean = false;
    hitlistConfig: any;
    updatedHitlistConfig: Subject<GridData> = new Subject<GridData>();
    loadingState: HitlistLoadingState = new HitlistLoadingState();

    private readonly translateFn: TranslateFnType;
    private readonly stateParams: StateParams;
    private listenerGuid: string;
    private listEntries: GridData;
    private sub: Subscription = new Subscription();

    constructor(@Inject("$filter") $filter: ng.IFilterService, globals: UIRouterGlobals,
                @Inject("$rootScope") private $rootScope: ng.IRootScopeService,
                @Inject("stateHistoryManager") protected stateHistoryManager: any,
                @Inject("cacheManagerService") private cacheManagerService: any,
                @Inject("$injector") private $injector: ng.auto.IInjectorService,
                private $state: StateService,
                private gridContentService: GridContentService,
                private cdRef: ChangeDetectorRef,
                private dmsDocumentService: DmsDocumentService,
                private httpService: HttpService,
                private backendObjectService: BackendObjectService,
                private backendSearchService: BackendSearchService,
                private messageService: MessageService,
                private toolService: ToolService) {

        this.stateParams = globals.params;
        this.translateFn = $filter("translate");
        this.stateTitle = this.translateFn("eob.contextmenu.action.locations.links.references");
    }

    ngOnInit(): void {
        this.sub.add(this.messageService.subscribe(Broadcasts.OBJECT_LINKS_REMOVED, (update: ObjectLinkResponse) => {
            // Currently, only note link deletion is handled
            this.listEntries.rows = this.listEntries.rows.filter(entry => !(entry.referenceType == "note" && !update.links.find(x => x.kind == "note" && x.idPair.objectId == entry.osid)));
            this.updatedHitlistConfig.next(this.listEntries);
        }));

        // hitlist refresh after updating comment
        this.sub.add(this.messageService.subscribe(Broadcasts.OBJECT_LINK_UPDATED, (document: DmsDocument) => {
            this.listEntries.rows.forEach((item) => {
                if (item.id === document.model.osid && item.referenceType === "note") {
                    item.referenceComment = document.model.links.find(i => i.kind == "note" && i.idPair.objectId === item.id).comment;
                }
            });

            this.updatedHitlistConfig.next(this.listEntries);
        }));

        const references: ReplaySubject<ObjectLinkResponse> = new ReplaySubject<ObjectLinkResponse>(1);
        this.loadingState.isLoading = true;

        // Todo: httpService is waiting for Refactoring DmsDocument then the backend layer could directly produce DmsDocuments.
        //       This code is moved from the backendObjectService to here because a composition of different data should
        //       be done by a special component which knows what she needs. We could now also refactor this code for
        //       better readability and error handling must maybe not done with missingLinkTypes (see method getAllObjectReferences)
        this.sub.add(this.httpService.searchById(this.stateParams.osid).pipe(switchMap((x: DmsDocument) => this.getAllObjectReferences(this.stateParams.osid, x.model.objectTypeId, x.model.objectType).pipe(
            switchMap(linkResponse => {
                if (x.model.baseParameters.foreignId != "" && x.model.baseParameters.archiveState == "REFERENCE") {
                    return this.httpService.searchById(x.model.baseParameters.foreignId).pipe(
                        switchMap((originalDoc: DmsDocument) => {
                            linkResponse.links.push({
                                idPair: {
                                    objectTypeId: originalDoc.model.objectTypeId,
                                    objectId: originalDoc.model.osid
                                },
                                kind: "original",
                                dmsDocument: originalDoc
                            });

                            return this.getAllObjectReferences(originalDoc.model.osid, originalDoc.model.objectTypeId, originalDoc.model.objectType).pipe(
                                map(res => {
                                    res.links = res.links.filter(link => link.kind == "link")
                                        .filter(link => link.dmsDocument.model.osid != x.model.osid);
                                    res.links.push(...linkResponse.links);
                                    return res;
                                })
                            );
                        })
                    );
                }

                return of(linkResponse);
            })
        ))).subscribe(y => {
            references.next(y);
        }, _ => {
            this.loadingState.hasErrors = true;
            this.loadingState.isLoading = false;
        }));

        references.subscribe(x => {
            // filter non-accessible documents (e.g. deleted note links that are still being returned by OSRest)
            x.links = x.links.filter(y => !!y.dmsDocument);

            // Create DMS Document Model because backend does nothing know regarding injector
            x.links.forEach(y => {
                this.cacheManagerService.dmsDocuments.add(y.dmsDocument, true);
            });

            // Prevent "garbage collection"
            this.listenerGuid = this.cacheManagerService.dmsDocuments.attachListener(x.links.map(y => y.dmsDocument.model.osid), updatedIds => {
                updatedIds.forEach(y => {
                    x.links.find(z => z.idPair.objectId == y).dmsDocument = this.cacheManagerService.dmsDocuments.getById(y);
                });
                this.displayReferences(x, false);
                this.updatedHitlistConfig.next(this.listEntries);
                this.cdRef.detectChanges();
            });
            this.displayReferences(x);
            this.sub.add(from(this.cacheManagerService.dmsDocuments.getOrFetchById(this.stateParams.osid)).pipe(
                map(doc => this.dmsDocumentService.buildTitleByMode(doc as DmsDocument, "hitlist", false))
            ).subscribe(description => {
                this.stateDescription = description;
                this.loadingState.isLoading = false;
                this.loadingState.showHitlist = true;
                this.$rootScope.$apply();
            }));
        });
    }

    ngOnDestroy(): void {
        // ui-router does strange things, like entering states twice sometimes due to our legacy state management
        // we need to unsubscribe here, as the subscription won't be cancelled correctly otherwise when jumping to the foreign id
        this.sub.unsubscribe();
        if (this.listenerGuid) {
            this.cacheManagerService.dmsDocuments.detachListeners(this.listenerGuid);
        }
    }

    private getAllObjectReferences(objectId: string, objectTypeId: string, type: "DOCUMENT" | "REGISTER" | "FOLDER"): Observable<ObjectLinkResponse> {
        // Sadly it's not possible to park pipe arguments inside an array due to static typing, so this little hack has to be used to deduplicate code
        const mapperAndCatcher: (obs: Observable<any>, kind: ObjectLinkKind) => Observable<ObjectLinkResponse> = (obs, kind) => obs.pipe(map(x => ({
                missingLinkTypes: [],
                links: x.map(y => (y.comment != void 0) ? y : {kind, idPair: y})
            })),
            catchError(err => {
                console.warn(err);
                return of({missingLinkTypes: [kind], links: []} as ObjectLinkResponse);
            }));

        const observables: Array<Observable<ObjectLinkResponse>> = [
            mapperAndCatcher(this.reduceLocationChildren(this.backendObjectService.getDmsObjectLocations(objectId, objectTypeId)), "location"),
            mapperAndCatcher(this.backendObjectService.getNoteLinks(objectId), "note"),
            mapperAndCatcher(this.backendObjectService.getReferenceDocuments(objectId), "link")
        ];

        if (type == "FOLDER") {
            // No need to query locations for those
            observables.shift();
        }

        if (/FOLDER|REGISTER/.test(type)) {
            // Don't query reference documents for those
            observables.pop();
        }

        return from(observables).pipe(
            mergeAll(3),
            reduce((acc, value) => {
                acc.links.push(...value.links);
                acc.missingLinkTypes.push(...value.missingLinkTypes);
                return acc;
            }, {links:[], missingLinkTypes: []} as ObjectLinkResponse),
            switchMap(accumulatedObjectLinks => {
                if(accumulatedObjectLinks.links.length == 0) {
                    return of(accumulatedObjectLinks);
                }

                accumulatedObjectLinks.links.sort((l1, l2) => l1.kind > l2.kind ? 1 : -1);

                return this.backendSearchService.searchByIds(this.convertIdTuplesToSearchIdRequest(accumulatedObjectLinks.links.map(y => y.idPair))).pipe(
                    map(rawDmsDocuments => {
                        const dmsDocuments = rawDmsDocuments.map(x => new DmsDocument(x, this.$injector));
                        accumulatedObjectLinks.links.forEach(z => Object.assign(z,{dmsDocument: dmsDocuments.find(d => d.model.osid == z.idPair.objectId && d.model.objectTypeId == z.idPair.objectTypeId)}));
                        return accumulatedObjectLinks;
                    })
                );
            }),
        );
    }

    private reduceLocationChildren(locations: Observable<IdPair[][]>): Observable<IdPair[]> {
        return locations.pipe(map(x => {
            const reducedIdPairs: IdPair[] = [];

            for (const locationPath of x) {
                // We add always last object because it is the direct parent.
                reducedIdPairs.push(locationPath[locationPath.length - 1]);
            }

            return reducedIdPairs;
        }));
    }

    private convertIdTuplesToSearchIdRequest(pairs: IdPair[]): BackendSearchIdRequest {
        const searchIdRequest: BackendSearchIdRequest = {};
        const objectTypeSet: Set<string> = new Set<string>();
        pairs.map(x => x.objectTypeId).forEach(x => objectTypeSet.add(x));

        for (const objectTypeId of objectTypeSet.values()) {
            searchIdRequest[objectTypeId] = {
                ids: pairs.filter(x => x.objectTypeId == objectTypeId).map(x => x.objectId)
            };
        }

        return searchIdRequest;
    }

    private displayReferences(references: ObjectLinkResponse, initialPopulation: boolean = true): void {
        const listEntries: GridData = this.gridContentService.getListEntries(this.getDmsDocuments(references), "objectReferences");
        // We need to keep the initial hitlistConfig, as otherwise legacy directives referencing it will stop working as they should (e.g. state filter)
        if (initialPopulation) {
            this.hitlistConfig = listEntries;
            this.hitlistConfig.contextData = {parentId: this.stateParams.osid, context: "objectReferences"};
        }
        this.listEntries = listEntries;
        if (references.missingLinkTypes.length > 0) {
            this.hitlistConfig.footerInformation = this.translateFn("eob.hitlist.not.all.referenced.fetched");
            this.hitlistConfig.footerIcon = "icon-24-warning";
        }
    }

    private getDmsDocuments(references: ObjectLinkResponse): DmsDocument[] {
        const result: DmsDocument[] = [];
        let dmsDocument: DmsDocument, foundDmsDocument: DmsDocument;

        for (const link of references.links) {
            foundDmsDocument = result.find(x => x.model.osid == link.idPair.objectId);
            dmsDocument = link.dmsDocument;
            link.guid = this.toolService.createGUID();

            if (foundDmsDocument) {
                foundDmsDocument.model.links.push(link);
                continue;
            }

            dmsDocument.model.links = [];
            dmsDocument.model.links.push(link);
            result.push(dmsDocument);
        }

        return result;
    }
}
