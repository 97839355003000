import {OsrestGenericItemErrorResponse} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-generic-item-error-response.interface";

export class OsrestErrorResponseEntry {
    readonly affectedObjectId: string;
    readonly stackTrace: string[];
    readonly errorRoot: string;
    readonly errorMessage: string;
    readonly errorCode?: number;

    constructor(error: OsrestGenericItemErrorResponse) {
        this.affectedObjectId = typeof error.item == "object" ? error.item.id : error.item.toString();
        this.stackTrace = error.error.stackTrace;
        this.errorRoot = error.error.root;
        this.errorMessage = error.error.message ?? error.error.errorMessage;
        this.errorCode = error.error.errorCode;
    }
}
