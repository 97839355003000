import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EobModalDashletsComponent} from "MODULES_PATH/dashlet/components/eob-modal-dashlets/eob-modal-dashlets.component";
import {EobDashletContainerComponent} from "MODULES_PATH/dashlet/components/eob-dashlet-container/eob-dashlet-container.component";
import {EobDashletDefaultPageComponent} from "MODULES_PATH/dashlet/components/eob-dashlet-default-page/eob-dashlet-default-page.component";
import {EobDetachedDashletContainerComponent} from "MODULES_PATH/dashlet/components/eob-detached-dashlet-container/eob-detached-dashlet-container.component";
import {SharedModule} from "SHARED_PATH/shared.module";
import {WopiModule} from "MODULES_PATH/wopi/wopi.module";
import {Type} from "@angular/core";
import {DetailsViewerModule} from "MODULES_PATH/details-viewer/details-viewer.module";
import {DocumentViewerModule} from "MODULES_PATH/document-viewer/document-viewer.module";

import {EobDashletKebabMenuComponent} from "MODULES_PATH/dashlet/components/eob-dashlet-kebab-menu/eob-dashlet-kebab-menu.component";
import {EobCustomDashletComponent} from "MODULES_PATH/dashlet/components/eob-custom-dashlet/eob-custom-dashlet.component";
import {EobPdfjsComponent} from "./components/eob-pdfjs/eob-pdfjs.component";
import {EobPdfjsContainerComponent} from "./components/eob-pdfjs/components/eob-pdfjs-container/eob-pdfjs-container.component";
import {PdfjsKebabComponent} from "./components/eob-pdfjs/components/pdfjs-kebab/pdfjs-kebab.component";
import {EobAttachedDashletContainerComponent} from "./components/eob-attached-dashlet-container/eob-attached-dashlet-container.component";
import {DashletApiService} from "MODULES_PATH/dashlet/services/dashlet-api.service";
import {OsClientService} from "MODULES_PATH/dashlet/services/os-client.service";
import {CustomDashletService} from "MODULES_PATH/dashlet/dashlet-services/custom-dashlet.service";
import {StoreModule} from "@ngrx/store";
import * as fromPdfjs from "MODULES_PATH/dashlet/components/eob-pdfjs/+state/pdfjs-toolbar.reducer";
import {from} from "rxjs";

const components: Array<Type<unknown>> = [
    EobModalDashletsComponent,
    EobDashletContainerComponent,
    EobDashletDefaultPageComponent,
    EobDetachedDashletContainerComponent,
    EobPdfjsComponent,
    EobPdfjsContainerComponent,
    PdfjsKebabComponent,
    EobDashletKebabMenuComponent,
    EobCustomDashletComponent,
    EobAttachedDashletContainerComponent
];

@NgModule({
    declarations: [...components],
    providers: [DashletApiService, OsClientService, CustomDashletService],
    imports: [
        CommonModule,
        SharedModule,
        WopiModule,
        DetailsViewerModule,
        DocumentViewerModule,
        StoreModule.forFeature(fromPdfjs.pdfjsToolbarFeatureKey, fromPdfjs.pdfjsToolbarReducer)
    ],
    exports: [...components],
    entryComponents: [...components]
})
export class DashletModule {
}
