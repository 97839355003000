import {Directive} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {ElementRef, Injector} from "@angular/core";

@Directive({
    selector: "eob-nav-quicksearch"
})
export class EobNavQuicksearchDirective extends UpgradeComponent {

    constructor(elementRef: ElementRef, injector: Injector) {
        super("eobNavQuicksearch", elementRef, injector);
    }

}