import {HttpEventType} from "@angular/common/http";
import {Subject} from "rxjs";
import {timeout, takeWhile} from "rxjs/operators";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

require("SERVICES_PATH/eob.environment.srv.js");

angular.module("eob.framework").directive("eobModalUpload", EobModalUpload);

EobModalUpload.$inject = ["$filter", "$rootScope", "notificationsService", "$eobConfig", "$timeout", "$http", "$q",
    "environmentService", "clientService", "loggingService", "externalTrayService", "toolService", "httpService", "messageService", "mimeTypes"];

function EobModalUpload($filter, $rootScope, NotificationsService, $eobConfig, $timeout, $http, $q,
                        EnvironmentService, ClientService, LoggingService, ExternalTrayService, ToolService, httpService, MessageService, MimeTypes) {

    /*
     1. "@"   ( Text binding / one-way binding )
     2. "="   ( Direct model binding / two-way binding )
     3. "&"   ( Behaviour binding / Method binding  )

     'A' - only matches attribute name
     'E' - only matches element name
     'C' - only matches class name
     'M' - only matches comment
     */

    function niceBytes(x) {
        let units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            n = parseInt(x, 10) || 0,
            l = 0;

        while (n >= 1024) {
            n /= 1024;
            l++;
        }

        return (`${n.toFixed(n >= 10 || l < 1 ? 0 : 1)} ${units[l]}`);
    }

    return {
        restrict: "E",
        scope: {
            metadata: "=",
            destroy$: "=destroy"
        },
        link(scope, element, attrs) {
            let cancel = $q.defer();
            let userCanceled = false;

            scope.$on("close.inline.dialogs", () => {
                userCanceled = true;
            });

            scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => {
                if (!userCanceled) {
                    NotificationsService.error($filter("translate")("modal.upload.error"));
                }

                cancel.resolve();
                angular.element(document.body).find(".dz-hidden-input, .fileInput").remove();
            });

            scope.close = function() {
                setTimeout(() => {
                    userCanceled = true;
                    MessageService.broadcast(ModalEvents.DESTROY);
                }, 0);
            };

            function handleUpload(filename, content, filenameAccepted) {
                const awaiter = new Subject();
                if ($rootScope.dropzone && !filenameAccepted) {
                    $rootScope.dropzone.options.accept({ name: filename }, (response) => {
                        if (response != "Error") {
                            return handleUpload(filename, content, true);
                        } else {
                            NotificationsService.error($filter("translate")("dropzone.invalidfiletype"));
                            scope.close();
                        }
                    });
                    return;
                }

                scope.text = "";
                const extention = filename.substring(filename.lastIndexOf(".") + 1);
                const mimeType = MimeTypes[extention]
                const blob = new Blob([content], { type: mimeType ? mimeType : "application/octet-stream" });
                httpService.uploadFile(filename, blob).pipe(timeout(30000)).subscribe({
                    next: value => {
                        if (value.type == HttpEventType.UploadProgress) {
                            let message = $filter("translate")("modal.upload.progress");
                            message = message.replace("[%s1]", niceBytes(value.loaded)).replace("[%s2]", niceBytes(value.total));
                            scope.text = message;
                            scope.$apply();
                        } else if (value.type == HttpEventType.Response) {
                            if ($rootScope.dropzone) {
                                let handler = (result) => {
                                    $rootScope.dropzone.off("complete", handler);

                                    if (!result.accepted && result.status == "error") {
                                        onDropzoneErrorAddToExternalTrayAsync(result);
                                    } else {
                                        $rootScope.$broadcast("file.added.externally");
                                        scope.close();
                                    }
                                };

                                $rootScope.dropzone.on("complete", handler);

                                let fileOfBlob = ToolService.blob2file(blob, filename);
                                $rootScope.dropzone.addFile(fileOfBlob);
                            } else {
                                console.info("no dropzone");
                                onDropzoneErrorAddToExternalTrayAsync({});
                            }
                        }
                    }, error: err => {
                        onDropzoneErrorAddToExternalTrayAsync(err);
                        awaiter.next();
                        awaiter.complete();
                        scope.$destroy();
                    }, complete: () => {
                        awaiter.next();
                        awaiter.complete();
                        scope.$destroy();
                    }
                });
                return awaiter.toPromise();
            }

            async function onDropzoneErrorAddToExternalTrayAsync(error) {
                if (error.accepted == void 0) {
                    LoggingService.error(error);
                    NotificationsService.error($filter("translate")("eob.external.tray.insert.dropzone.failed"));
                }

                try {
                    await ExternalTrayService.storeDataInIndexedDBAsync(scope.metadata);
                    await ExternalTrayService.renameTrayElementAsync(scope.metadata);
                } catch (error) {
                    // TODO
                }

                $rootScope.$broadcast("file.added.externally");
                scope.close();
            }

            async function uploadAsync() {
                scope.text = $filter("translate")("modal.upload.file.being.read");

                try {
                    if (scope.metadata.filePaths instanceof Array && scope.metadata.filePaths.length > 0) {
                        for (let filepath of scope.metadata.filePaths) {
                            let filename = filepath.split(/[/\\]/).pop();
                            let content = await ClientService.readDataFromFileAsync(filepath, false);
                            await handleUpload(filename, content);
                        }
                    } else if (scope.metadata.filePaths instanceof Map && scope.metadata.filePaths.size > 0) {
                        for (let [filename, content] of scope.metadata.filePaths.entries()) {
                            await handleUpload(filename, content);
                        }
                    } else {
                        throw new Error("scope.metadata filePaths is invalid");
                    }
                } catch (error) {
                    onDropzoneErrorAddToExternalTrayAsync(error);
                }
            }

            uploadAsync();
        },
        template: require("!raw-loader!./eob.modal.upload.html")
    };
}
