<div *ngIf = "gridoptions.columnDefs && gridoptions.rowData">
    <ag-grid-angular
        style = "width: 100%; height: 100%"
        class = "ag-theme-base"
        [autoGroupColumnDef]="gridoptions.autoGroupColumnDef"
        [columnDefs] = "gridoptions.columnDefs"
        [defaultColDef] = "gridoptions.defaultColDef"
        [defaultGroupSortComparator]="defaultGroupSortComparator"
        [groupUseEntireRow] = "gridoptions.groupUseEntireRow"
        [headerHeight] = "gridoptions.headerHeight"
        [localeText]="gridoptions.localeText"
        [rowBuffer] = "gridoptions.rowBuffer"
        [rowData] = "gridoptions.rowData"
        [rowGroupPanelShow] = "gridoptions.rowGroupPanelShow"
        [rowHeight] = "gridoptions.rowHeight"
        [rowSelection] = "gridoptions.rowSelection"
        [suppressAsyncEvents] = "gridoptions.suppressAsyncEvents"
        [suppressContextMenu] = "gridoptions.suppressContextMenu"
        [suppressDragLeaveHidesColumns] = "gridoptions.suppressDragLeaveHidesColumns"
        [suppressMovableColumns] = "gridoptions.suppressMovableColumns"
        [suppressNoRowsOverlay] = "gridoptions.suppressNoRowsOverlay"
        [suppressPropertyNamesCheck] = "gridoptions.suppressPropertyNamesCheck"
        [suppressScrollOnNewData] = "gridoptions.suppressScrollOnNewData"
        [suppressColumnMoveAnimation] = "gridoptions.suppressColumnMoveAnimation"
        [suppressColumnVirtualisation]="gridoptions.suppressColumnVirtualisation"
        [frameworkComponents]="gridoptions.frameworkComponents"
        [context] = "gridoptions.context"
        (cellContextMenu) = "onCellContextMenu($event)"
        (columnResized) = "onColumnResized($event)"
        (columnRowGroupChanged) = "onColumnRowGroupChanged($event)"
        (gridReady) = "onGridReady($event)"
        (gridSizeChanged) = "onGridSizeChanged($event)"
        (rowSelected) = "onRowSelected($event)"
        (rowClicked) = "onRowClicked($event)"
        (rowDoubleClicked) = "onRowDoubleClicked($event)"
        (sortChanged) = "onSortChanged($event)"
        (gridColumnsChanged) = "onGridColumnsChanged($event)"
    >
        <!--        [rowSelection] = "gridOptions.rowSelection"-->
        <!--    >-->
    </ag-grid-angular>
</div>