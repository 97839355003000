import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {Observable} from "rxjs";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";
import {TodoQuery, TodoQueryConfig} from "INTERFACES_PATH/any.types";
import {BackendSearchIdRequest} from "CORE_PATH/backend/interfaces/search-requests/backend-search-id-request";

@Injectable({providedIn: BackendOsRestModule})
export class OsRestSearchService {

    constructor(private httpClient: HttpClient) {
    }

    getObjectMetadata(osId: string, objectTypeId: string, parameters?: HttpParams): Observable<BackendObject> {
        const typeQueryString: string = (objectTypeId == null) ? "" : `?objectTypeId=${objectTypeId}`;
        return this.httpClient.get<BackendObject>(`/osrest/api/documents/search/${osId}${typeQueryString}`, {params: parameters});
    }

    search(query: TodoQuery, config?: TodoQueryConfig): Observable<BackendObject[]> {
        const params: HttpParams = new HttpParams({fromObject: config});

        return this.httpClient.post<BackendObject[]>("/osrest/api/documents/search/", query, {params});
    }

    getCheckedOutDocuments(): Observable<BackendObject[]> {
        return this.httpClient.get<BackendObject[]>("/osrest/api/documentfiles/checkedout");
    }

    getFolderContent(osId: string, maxSize: number): Observable<BackendObject[]> {
        return this.httpClient.get<BackendObject[]>(`/osrest/api/documents/cabinets/content/${osId}?pagesize=${maxSize}`);
    }

    searchVtx(query: TodoQuery): Observable<BackendObject[]> {
        return this.httpClient.post<BackendObject[]>("/osrest/api/documents/vtx", query);
    }

    searchByIds(query: BackendSearchIdRequest): Observable<BackendObject[]> {
        return this.httpClient.post<BackendObject[]>("/osrest/api/documents/search/ids?baseparams=true&rights=true&fileinfo=true&fieldsschema=AUTO", query);
    }
}