import {Inject, Injectable} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {EMPTY, Observable, of, Subject, Subscription} from "rxjs";
import {debounceTime, tap} from "rxjs/operators";
import {
    AsIni,
    AsIniDashboardSearchConfiguration,
    AsIniFieldConfig,
    AsIniHitlistConfiguration,
    IniKeys,
    IniSetSettings,
    KeyValueSetting,
    Language,
    LayoutConfiguration,
    SortOrder,
    StaticIconColumnMapping,
    StaticIconColumns,
    Stringbool,
    AsIniTemplateConfiguration,
    AsIniFieldConfigs,
    AsIniWfInboxStaticColumns
} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {FeatureSet} from "MODELS_PATH/eob.feature.set.model";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {Connection} from "ENUMS_PATH/connection.enum";
import {Languages} from "ENUMS_PATH/languages.enum";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

const fieldConfigRegexp = /^(SETS#|FIELDS#0064|FIELDS#0066|FIELDS#0067|FIELDS#|EMAILFIELDS#|WINDOWTITELFIELDS#|EBWCHITLISTCONFIG)/;
const fieldConfigs: AsIniFieldConfigs = {
    hitlist: {prefix: IniKeys.PREFIX_HITLIST_FIELDS_SECTION, objTypeFields: {}},
    docBaseParams: {prefix: IniKeys.PREFIX_DOCUMENT_BASEPARAMS_SECTION, objTypeFields: {}},
    folderBaseParams: {prefix: IniKeys.PREFIX_FOLDER_BASEPARAMS_SECTION, objTypeFields: {}},
    registerBaseParams: {prefix: IniKeys.PREFIX_REGISTER_BASEPARAMS_SECTION, objTypeFields: {}},
    sendTitle: {prefix: IniKeys.PREFIX_SEND_TITLE_SECTION, objTypeFields: {}},
    windowTitle: {prefix: IniKeys.PREFIX_WINDOW_TITEL_SECTION, objTypeFields: {}},
};
@Injectable({
    providedIn: "root"
})
export class AsIniService {

    private suppressConnectivityChangeHandler = false;
    private initialLoad = false;
    private settings: AsIni = {};
    private hitlistConfigurations: AsIniHitlistConfiguration[] = [];
    private savingAllowed = false;
    private localSettingsOutdated = false;
    private saveTrigger$: Subject<boolean> = new Subject<boolean>();
    private saveExecutor$: Subject<Subscription> = new Subject<Subscription>();
    private lastSaveSubscription: Subscription = new Subscription();
    private featureSet: FeatureSet = new FeatureSet();
    private readonly translateFn: TranslateFnType;

    constructor(@Inject("$injector") private $injector: ng.auto.IInjectorService,
                @Inject("$filter") $filter: ng.IFilterService,
                private clientService: ClientService,
                private messageService: MessageService, private httpService: HttpService, @Inject("environmentService") private environmentService: TodoEnvironmentService) {
        this.messageService.subscribe(Broadcasts.CONNECTIVITY_CHANGED, (connectivity: Connection) => {
            // Required for some nasty legacy unit tests
            if (!this.suppressConnectivityChangeHandler) {
                this._handleConnectivityChange(connectivity);
            }
        });
        this.messageService.subscribe(Broadcasts.SUPPRESS_CONNECTIVITY_CHANGE_HANDLER, (suppress: boolean) => this.suppressConnectivityChangeHandler = suppress);
        this.messageService.subscribe(Broadcasts.FEATURESET_RECEIVED, (fs: FeatureSet) => this.featureSet = fs);
        this.saveTrigger$.pipe(debounceTime(500)).subscribe(force => {
            this.lastSaveSubscription.unsubscribe();
            this.saveExecutor$.next(this._saveSettings(force));
        });
        this.translateFn = $filter("translate");
    }

    setSavingAllowed(allowed: boolean): void {
        this.savingAllowed = allowed;
    }

    getSavingAllowed(): boolean {
        return this.savingAllowed;
    }

    getLayoutConfiguration(): LayoutConfiguration {
        return this.settings[IniKeys.LAYOUT_CONFIGURATION] ?? {};
    }

    updateLayoutConfiguration(newConfig: LayoutConfiguration): void {
        let dirty = false;
        if (!this.settings[IniKeys.LAYOUT_CONFIGURATION]) {
            this.settings[IniKeys.LAYOUT_CONFIGURATION] = {};
        }
        for (const k of Object.keys(newConfig)) {
            if (this.settings[IniKeys.LAYOUT_CONFIGURATION][k] != newConfig[k]) {
                dirty = true;
                this.settings[IniKeys.LAYOUT_CONFIGURATION][k] = newConfig[k];
            }
        }
        if (dirty) {
            this.saveSettings();
        }
    }

    private _handleConnectivityChange(connectivityState: Connection): void {
        if (connectivityState != Connection.NONE && connectivityState != Connection.UNKNOWN) {
            this.httpService.getSettingsTimestamp().subscribe(remoteTimestamp => {
                if(remoteTimestamp > this.settings[IniKeys.SETTINGS_TIMESTAMP]?.VALUE ?? 0) {
                    this.localSettingsOutdated = true;
                    this._showLogoutPrompt();
                } else {
                    console.info("saveSettings from connectivity handler");
                    this.saveSettings();
                }
            });
        }
    }

    private _blacklistedStateIsOpen(): boolean {
        return /\/indexdata\/edit|\/create\/|\/workflow\/[A-Fa-f0-9]{32}/.test(location.href);
    }

    private _showLogoutPrompt(): void {
        if(this._blacklistedStateIsOpen()) {
            window.addEventListener("hashchange", this._showLogoutPrompt.bind(this), {once: true});
            return;
        }
        const title: string = this.translateFn("modal.confirm.logout.for.new.settings.title");
        const cancelButton: string = this.translateFn("modal.button.later");
        const submitButton: string = this.translateFn("modal.refresh.title");
        const modalDialogService: any = this.$injector.get("modalDialogService");

        const msg: string = this.translateFn("modal.confirm.logout.for.new.settings.message");
        if(!modalDialogService.areModalDialogsOpen()) {

                modalDialogService.infoDialog(title, msg, cancelButton, submitButton, null, true).then(_ => location.reload()).catch(error => {
                    console.error(error);
                });

        }
    }

    private _getFieldConfig(key: string): AsIniFieldConfig {
        let fieldConfig: AsIniFieldConfig;

        const match: RegExpMatchArray | null = fieldConfigRegexp.exec(key);
        if(match) {
            for(const config of Object.keys(fieldConfigs)) {
                if(fieldConfigs[config].prefix == match[0]) {
                    fieldConfig = fieldConfigs[config];
                }
            }
        }
        return fieldConfig;
    }

    loadSettings(): Observable<AsIni> {
        return this.httpService.loadSettings().pipe(
            tap(x => {
                this.settings = x ?? {};
                this.hitlistConfigurations.splice(0, this.hitlistConfigurations.length);
                for(const fieldConfig of Object.keys(fieldConfigs)) {
                    fieldConfigs[fieldConfig].objTypeFields = {};
                }
                for (const cfg of Object.keys(this.settings.EBWCHITLISTCONFIG ?? {})) {
                    try {
                        this.hitlistConfigurations.push(JSON.parse(this.settings.EBWCHITLISTCONFIG[cfg].replace(/\\/g, "")));
                    } catch(error) {
                        console.warn(`Error while parsing hitlist configuration ${cfg}`);
                    }
                }
                for (const key in this.settings) {
                    const fieldConfig: AsIniFieldConfig = this._getFieldConfig(key);
                    if (fieldConfig != void 0) {
                        const objectTypeId: number = parseInt(key.replace(fieldConfig.prefix, ""), 16);
                        fieldConfig.objTypeFields[objectTypeId] = this.settings[key].FIELDS.split(",");
                    }
                }
                this.initialLoad = true;
                this.localSettingsOutdated = false;
            })
        );
    }

    saveSettings(force?: boolean): Observable<Subscription> {
        if(!this.clientService.isOnline()) {
            return of(EMPTY.subscribe());
        }
        this.saveTrigger$.next(force);
        return this.saveExecutor$.asObservable();
    }
    private _saveSettings(force?: boolean): Subscription {
        if(!this.savingAllowed && !force) {
            console.warn("Saving settings isn't allowed. Discarding call, since it wasn't forced.");
            return EMPTY.subscribe();
        }
        if(this.localSettingsOutdated) {
            this._showLogoutPrompt();
            return;
        }
        if(!this.initialLoad && !force) {
            console.warn("saveSettings called before initial loading took place. Discarding call, since it wasn't forced.");
            return EMPTY.subscribe();
        }
        this.settings[IniKeys.SETTINGS_TIMESTAMP] = {VALUE: Date.now()};
        this.lastSaveSubscription = this.httpService.saveSettings(this.settings).subscribe();
        return this.lastSaveSubscription;
    }

    getGuiLanguage(): Language {
        // rich client language codes switched from "deu" to "De", "eng" to "En" and "fra" to "Fr"
        // (also, rich client is able to cope with lowercase "de", "en" and "fr" in AS.INI)
        if(!this.settings?.CLIENT?.LANGUAGE) {
            return "de";
        } else {
            const match: RegExpMatchArray | null = /de|en|fr/i.exec(this.settings.CLIENT.LANGUAGE);
            return match ? match[0].toLowerCase() as Language : "de";
        }
    }

    setGuiLanguage(lang: Language): Observable<Subscription> {
        if (!this.settings.CLIENT) {
            this.settings.CLIENT = {};
        }
        this.settings.CLIENT.LANGUAGE = lang;
        return this.saveSettings();
    }

    setUnReadWorkflow(workflowId: string, isRead: boolean): void {
        this.settings.OPENEDWORKITEMS = this.settings.OPENEDWORKITEMS ?? {};
        const readWorkflows: Set<string> = new Set<string>(Object.entries(this.settings.OPENEDWORKITEMS).map(([k,v]) => v));
        if(readWorkflows.has(workflowId) && !isRead) {
            readWorkflows.delete(workflowId);
        } else if(!readWorkflows.has(workflowId) && isRead) {
            readWorkflows.add(workflowId);
        } else {
            console.warn("workflow read status hasn't changed");
            return;
        }
        let index = 0;
        this.settings.OPENEDWORKITEMS = [...readWorkflows].reduce((acc, currentValue) => {
            acc[`ITEM${index}`] = currentValue;
            index++;
            return acc;
        }, {});
        this.saveSettings();
    }

    setQuicksearchExpandedState(searchId: string, expanded: boolean): void {
        let expandedSearches: string[] = this.settings.EBWCQUICKSEARCHSTATE?.expanded?.split(",") ?? [];
        if(expanded && !expandedSearches.includes(searchId)) {
            expandedSearches.push(searchId);
        } else if(!expanded) {
            expandedSearches = expandedSearches.filter(x => x != searchId);
        } else {
            console.warn("Configuration hasn't been changed.");
            return;
        }
        this.settings.EBWCQUICKSEARCHSTATE = {expanded: expandedSearches.join(",")};
        this.saveSettings();
    }
    getExpandedQuicksearchIds(): string[] {
        return this.settings.EBWCQUICKSEARCHSTATE?.expanded?.split(",") ?? [];
    }
    getStaticColumns(): StaticIconColumns {
        const disableIconColumns = !this.featureSet.contains("user.setting.hitlist.iconColumn");
        const staticColumns: StaticIconColumns = {
            annotations: true,
            archiveState: true,
            dmsIcon: true,
            favorite: true,
            links: true,
            lockState: true,
            mimeType: true,
            notes: true,
            objectType: true,
            signature: true,
        };
        for(const x of Object.keys(StaticIconColumnMapping)) {
            if(this.settings.STATICLISTCOLS?.[x] == void 0) {
                continue;
            }
            staticColumns[StaticIconColumnMapping[x]] = this.settings.STATICLISTCOLS[x] == "1";
        }

        if (disableIconColumns) {
            staticColumns.notes = this.settings.STATICLISTCOLS == void 0 ? true : this.settings.STATICLISTCOLS.COL3 == "1";
            Object.assign(staticColumns, {
                mimeType: false, links: false, signature : false, annotations: false, favorite: false
            });
        }
        return staticColumns;
    }
    setStaticColumns(columnName: string, state: boolean): Observable<Subscription> {
        if(!this.settings.STATICLISTCOLS) {
            this.settings.STATICLISTCOLS = {};
        }
        const iniKey: string = Object.entries(StaticIconColumnMapping).find(([k,v]) => v == columnName)?.[0];
        if(!iniKey) {
            console.warn("Invalid column name given");
            return;
        } else if ((this.settings.STATICLISTCOLS[iniKey] == "1" && state) ||
            (this.settings.STATICLISTCOLS[iniKey] == "0" && !state)) {
            console.warn("Configuration hasn't been changed.");
            return;
        } else {
            this.settings.STATICLISTCOLS[iniKey] = state ? "1" : "0";
        }
        return this.saveSettings();
    }
    setWfInboxStaticColumns(columnName: keyof AsIniWfInboxStaticColumns, state: boolean): void {
        if(!this.settings["WORKFLOW@INBOXCOLUMNS"]) {
            this.settings["WORKFLOW@INBOXCOLUMNS"] = {};
        }
        const iniKey: string = Object.keys(this.getWfInboxStaticColumns()).includes(columnName) ? columnName : undefined;
        if(!iniKey) {
            console.warn("Invalid column name given");
            return;
        } else if ((this.settings["WORKFLOW@INBOXCOLUMNS"][iniKey] == "1" && state) ||
            (this.settings["WORKFLOW@INBOXCOLUMNS"][iniKey] == "0" && !state)) {
            console.warn("Configuration hasn't been changed.");
            return;
        } else {
            this.settings["WORKFLOW@INBOXCOLUMNS"][iniKey] = state ? "1" : "0";
        }
        this.saveSettings();
    }
    getTreeNameSeparator(): string {
        return this.settings.CLIENT?.TREEDIVCHAR ?? "-";
    }

    getDesktopFolderState(): KeyValueSetting<Stringbool> {
        return this.settings.EBWCDESKTOPFOLDERSTATE ?? {};
    }

    setDesktopFolderState(desktopItemId: string, expanded: boolean): void {
        if(!this.settings.EBWCDESKTOPFOLDERSTATE) {
            this.settings.EBWCDESKTOPFOLDERSTATE = {};
        }
        const existingEntry: [string, Stringbool] = Object.entries(this.settings.EBWCDESKTOPFOLDERSTATE).find(([k,v]) => k == desktopItemId);
        if(!existingEntry && expanded) {
            this.settings.EBWCDESKTOPFOLDERSTATE[desktopItemId] = expanded.toString() as Stringbool;
        } else if(existingEntry && !expanded) {
            delete this.settings.EBWCDESKTOPFOLDERSTATE[desktopItemId];
        } else {
            console.warn("Configuration hasn't been changed.");
            return;
        }
        this.saveSettings();
    }

    isShowRegisterTree(cabinetId: string): boolean {
        const key: string = parseInt(cabinetId).toString(16).padStart(8, "0");
        if (!this.settings[`${IniKeys.PREFIX_CABINET_SECTION}${key}`]) {
            return true;
        } else if (this.settings[`${IniKeys.PREFIX_CABINET_SECTION}${key}`][IniSetSettings.SHOW_REGISTER_TREE] == void 0) {
            return true;
        } else {
            return this.settings[`${IniKeys.PREFIX_CABINET_SECTION}${key}`][IniSetSettings.SHOW_REGISTER_TREE] == "1";
        }
    }

    setShowRegisterTree(cabinetId: string, isShowRegister: boolean): void {
        const key: string = parseInt(cabinetId).toString(16).padStart(8, "0");
        if(this.settings[`${IniKeys.PREFIX_CABINET_SECTION}${key}`]?.[IniSetSettings.SHOW_REGISTER_TREE] != void 0) {
            const currentSetting: boolean = this.settings[`${IniKeys.PREFIX_CABINET_SECTION}${key}`][IniSetSettings.SHOW_REGISTER_TREE] == "1";
            if(currentSetting == isShowRegister) {
                console.warn("Configuration hasn't been changed.");
                return;
            }
        } else if(!this.settings[`${IniKeys.PREFIX_CABINET_SECTION}${key}`]) {
                this.settings[`${IniKeys.PREFIX_CABINET_SECTION}${key}`] = {};
            }
        this.settings[`${IniKeys.PREFIX_CABINET_SECTION}${key}`][IniSetSettings.SHOW_REGISTER_TREE] = (isShowRegister ? "1" : "0");
        this.saveSettings();
    }
    getDashboardSearches(): AsIniDashboardSearchConfiguration {
        return this.settings.EBWCDASHBOARDSEARCHCONFIGURATION ?? {expanded: "", selected: ""};
    }

    setDashboardSearches(searches: Array<{id: string}>): void {
        if(!this.settings.EBWCDASHBOARDSEARCHCONFIGURATION) {
            this.settings.EBWCDASHBOARDSEARCHCONFIGURATION = {expanded: "", selected: ""};
        }
        const oldExpanded: string = this.settings.EBWCDASHBOARDSEARCHCONFIGURATION.selected;
        this.settings.EBWCDASHBOARDSEARCHCONFIGURATION.selected = searches.map(x => x.id).join(",");
        if(oldExpanded == this.settings.EBWCDASHBOARDSEARCHCONFIGURATION.selected) {
            console.warn("Configuration hasn't been changed.");
            return;
        }
        this.saveSettings();
    }

    getDashboardQuicksearchState(): string[] {
        return this.settings.EBWCDASHBOARDSEARCHCONFIGURATION?.expanded?.split(",") ?? [];
    }

    setDashboardQuicksearchState(searchId: number, expanded: boolean): void {
        if(!this.settings.EBWCDASHBOARDSEARCHCONFIGURATION) {
            this.settings.EBWCDASHBOARDSEARCHCONFIGURATION = {expanded: "", selected: ""};
        } else if(!this.settings.EBWCDASHBOARDSEARCHCONFIGURATION.expanded) {
            this.settings.EBWCDASHBOARDSEARCHCONFIGURATION.expanded = "";
        }
        const strSearchId: string = searchId.toString();
        const originalExpanded: string = this.settings.EBWCDASHBOARDSEARCHCONFIGURATION.expanded;
        const expandedSet: Set<string> = new Set<string>();
        if(originalExpanded.includes(",")) {
            originalExpanded.split(",").forEach(x => expandedSet.add(x));
        } else if(originalExpanded.length) {
            expandedSet.add(originalExpanded);
        }
        if(expanded && expandedSet.has(strSearchId)) {
            console.warn("Configuration hasn't been changed.");
            return;
        }

        if (expandedSet.has(strSearchId)) {
            expandedSet.delete(strSearchId);
        } else {
            expandedSet.add(strSearchId);
        }

        this.settings.EBWCDASHBOARDSEARCHCONFIGURATION.expanded = [...expandedSet].join(",");
        this.saveSettings();
    }
    getHitlistConfiguration(stateData: any): AsIniHitlistConfiguration {
        if(!stateData?.type) {
            console.error("State data lacks type property");
            return;
        }
        const hitlistConfigurationType: AsIniHitlistConfiguration = this._getHitlistConfigurationType(stateData);
        hitlistConfigurationType.isEmpty = true;
        const type: string = hitlistConfigurationType.type;
        const subType: string = hitlistConfigurationType.subType;
        const id: string = hitlistConfigurationType.id;
        for(const cfg of this.hitlistConfigurations) {
            let hit = false;
            switch (cfg.type) {
                case "offlineObjects":
                case "hitlist.offlineObjects":
                case "offlineFavorites":
                case "favorites":
                case "hitlist.favorites":
                    if (type === "favorites" || type === "hitlist.favorites" || type === "offlineObjects" || type === "hitlist.offlineObjects") {
                        hit = true;
                    }
                    break;
                case "workflow":
                    if (subType === cfg.subType) {
                        hit = true;
                    }
                    break;
                case "objecttype":
                case "folder":
                    if (type == cfg.type && id == cfg.id) {
                        hit = true;
                    }
                    break;
                case "folderFlat":
                    if (id == cfg.id && stateData.flat == true) {
                        hit = true;
                    }
                    break;
                default:
                    if (type === cfg.type) {
                        hit = true;
                    }
            }
            if(hit) {
                cfg.isEmpty = false;
                return cfg;
            }
        }

        return hitlistConfigurationType;
    }
    /**
     * known types are objecttype, query, fulltext, revisit, abo, workflow
     *
     * subType is only valid for the hit list types 'fulltext' and 'workflow'
     * fulltext: global, cabinet, objecttype
     * workflow: inbox, substitutes, startables, runnings
     *
     * id is
     * objecttype -> objectTypeId
     * query -> queryId
     * fulltext@objecttype -> objectTypeId
     */
    private _getHitlistConfigurationType(stateData: any): AsIniHitlistConfiguration {
        let hitlistType: string;
        if (stateData.type === "search" && stateData.objectTypeIds != void 0) {
            if (stateData.flat != true) {
                hitlistType = "objecttype";
            } else {
                hitlistType = "folderFlat";
            }
        } else {
            hitlistType = stateData.type;
        }
        const result: AsIniHitlistConfiguration = { type: hitlistType };

        switch (hitlistType) {
            case "objecttype":
                result.id = stateData.objectTypeIds?.[0];
                break;
            case "folder":
                result.id = stateData.cabinetId ?? stateData.config?.cabinetId;
                break;
            case "workflow":
                result.subType = "inbox";
                break;
            case "startable":
                result.type = "workflow";
                result.subType = "startable";
                break;
            case "folderFlat":
                result.id = stateData.objectTypeIds?.[0];
                break;
        }

        return result;
    }

    addHitlistConfiguration(newhitlistConfiguration: AsIniHitlistConfiguration): void {
        if(!newhitlistConfiguration.type) {
            console.error("Missing hitlist configuration type");
            return;
        }
        this.hitlistConfigurations = this.hitlistConfigurations.filter(x => x.type != newhitlistConfiguration.type
            && x.subType != newhitlistConfiguration.subType
            && x.id ? x.id != newhitlistConfiguration.id : true);
        this.hitlistConfigurations.push(newhitlistConfiguration);
        this.settings.EBWCHITLISTCONFIG = {};
        this.hitlistConfigurations.forEach(x => {
            let key: string = x.type;
            if (x.subType != void 0) {
                key = `${key}@${x.subType}`;
            }
            if (x.id != void 0) {
                key = `${key}@${x.id}`;
            }
            this.settings.EBWCHITLISTCONFIG[key] = JSON.stringify(x);
        });
        this.saveSettings();
    }

    getTemplateConfiguration(objectTypeId: string): AsIniTemplateConfiguration {
        const result: AsIniTemplateConfiguration = {};
        if(this.settings.LASTSORTCOL?.[objectTypeId]) {
            result.sortColumn = this.settings.LASTSORTCOL[objectTypeId];
        }
        if(this.settings.LASTSORTDIR?.[objectTypeId]) {
            result.sortDirection = this.settings.LASTSORTDIR[objectTypeId] == "1" ? "asc" : "desc";
        }
        if(this.settings.LASTWDOCSEL?.[objectTypeId]) {
            result.selectedEntry = this.settings.LASTWDOCSEL[objectTypeId];
        }
        return result;
    }

    updateTemplateConfiguration(objectTypeId: string, configuration: AsIniTemplateConfiguration): void {
        if(configuration.sortColumn) {
            if (!this.settings.LASTSORTCOL) {
                this.settings.LASTSORTCOL = {};
            }
            this.settings.LASTSORTCOL[objectTypeId] = configuration.sortColumn;
        }
        if(configuration.sortDirection) {
            if (!this.settings.LASTSORTDIR) {
                this.settings.LASTSORTDIR = {};
            }
            this.settings.LASTSORTDIR[objectTypeId] = configuration.sortDirection == "asc" ? "1" : "0";
        }
        if(configuration.selectedEntry) {
            if (!this.settings.LASTWDOCSEL) {
                this.settings.LASTWDOCSEL = {};
            }
            this.settings.LASTWDOCSEL[objectTypeId] = configuration.selectedEntry;
        }
        this.saveSettings();
    }

    useAutostarBehindDbCatalog(): boolean {
        // this is utter bullshit, but whatever
        // the autostar configuration gets stored in 8 bits like:
        // e.g : 00101011
        // explanation read from right to left (i only mention the bits that are set to 1 (true))
        // 1 (from right). says whether we use autostar or not (simple true false switch)
        // 2 (from right). says we use the autostar behind the search term
        // 4 (from right). says we use the autostar behind the db catalog
        // 6 (from right). says we use the autostar in front of the search term
        // simply shift the 8 bits 3 to the right and if the last char is a 1 we use the db catalog star
        // default is undefined and evaluates to true -.-".... *sigh*
        // i still dont know what the other bits mean, but i guess i dont wanna know ...
        // this is the bitwise operator if the 4th bit is set
        // if the 4th bit is set, the comperator returns a value greater than 0 , else it returns 0
        // also check null in case the settings are at their default
        return (parseInt(this.settings.OPTIONEN?.Autostern ?? "8") & 8) > 0;
    }

    getHitlistFieldsConfiguration(objectTypeId: string): string[] {
        return fieldConfigs.hitlist.objTypeFields[objectTypeId];
    }
    private _updateHitlistFieldsConfiguration(config: AsIniFieldConfig, iniKey: string, objectTypeId: string, value: string[]): void {
        config.objTypeFields[objectTypeId] = value;
        if(value.length) {
            this.settings[iniKey] = {FIELDS: value.join(",")};
        } else {
            delete this.settings[iniKey];
        }
    }
    updateHitlistFieldsConfiguration(configuration: {[k: string]: string[]}, cabId?: string): Observable<Subscription> {
        if(!Object.keys(configuration ?? {}).length) {
            console.warn("Configuration missing");
            return;
        }
        for(const [k, v] of Object.entries(configuration)) {
            if(/cabfields|document/.test(k) && cabId != void 0) {
                this._updateHitlistFieldsConfiguration(fieldConfigs.docBaseParams, `${IniKeys.PREFIX_DOCUMENT_BASEPARAMS_SECTION}${parseInt(cabId).toString(16).padStart(4, "0")}`, cabId, v);
            } else if(k.includes("folder") && cabId != void 0) {
                this._updateHitlistFieldsConfiguration(fieldConfigs.folderBaseParams, `${IniKeys.PREFIX_FOLDER_BASEPARAMS_SECTION}${parseInt(cabId).toString(16).padStart(4, "0")}`, cabId, v);
            } else if(k.includes("register") && cabId != void 0) {
                this._updateHitlistFieldsConfiguration(fieldConfigs.registerBaseParams, `${IniKeys.PREFIX_REGISTER_BASEPARAMS_SECTION}${parseInt(cabId).toString(16).padStart(4, "0")}`, cabId, v);
            } else if(!isNaN(parseInt(k))) {
                this._updateHitlistFieldsConfiguration(fieldConfigs.hitlist, `${IniKeys.PREFIX_HITLIST_FIELDS_SECTION}${parseInt(k).toString(16).padStart(8, "0")}`, k, v);
            } else {
                console.warn("Invalid configuration given");
                return;
            }
        }
        return this.saveSettings();
    }

    getConfiguredDocumentBaseParams(objectTypeId: string): string[] {
        return fieldConfigs.docBaseParams.objTypeFields[objectTypeId] ?? [];
    }

    getConfiguredFolderBaseParams(objectTypeId: string): string[] {
        return fieldConfigs.folderBaseParams.objTypeFields[objectTypeId] ?? [];
    }

    getConfiguredRegisterBaseParams(objectTypeId: string): string[] {
        return fieldConfigs.registerBaseParams.objTypeFields[objectTypeId] ?? [];
    }

    getConfiguredSendTitleFields(objectTypeId: string): string[] {
        return fieldConfigs.sendTitle.objTypeFields[objectTypeId] ?? [];
    }

    getConfiguredWindowTitleFields(objectTypeId: string): string[] {
        return fieldConfigs.windowTitle.objTypeFields[objectTypeId] ?? [];
    }
    isSynchronizeFavoritesOffline(): boolean {
        return this.settings.EBWCOFFLINE?.ALWAYSSYNCHFAVORITES == "1";
    }
    isUseMobileDataForSync(): boolean {
        return this.settings.EBWCOFFLINE?.USEMOBILEDATAFORSYNCH == "1";
    }
    setSynchronizeFavoritesOffline(enable: boolean): void {
        if(!this.settings.EBWCOFFLINE) {
            this.settings.EBWCOFFLINE = {};
        }
        this.settings.EBWCOFFLINE.ALWAYSSYNCHFAVORITES = enable ? "1" : "0";
        this.saveSettings();
    }
    setUseMobileDataForSync(enable: boolean): void {
        if(!this.settings.EBWCOFFLINE) {
            this.settings.EBWCOFFLINE = {};
        }
        this.settings.EBWCOFFLINE.USEMOBILEDATAFORSYNCH = enable ? "1" : "0";
        this.saveSettings();
    }
    isIncludeObjectsWithoutRegisterContext(): boolean {
        return this.settings.REQUESTMODE?.WITHNOREGISTER == "1";
    }
    setIncludeObjectsWithoutRegisterContext(enable: boolean): void {
        if(!this.settings.REQUESTMODE) {
            this.settings.REQUESTMODE = {};
        }
        this.settings.REQUESTMODE.WITHNOREGISTER = enable ? "1" : "0";
        this.saveSettings();
    }
    isSynchronizeOriginalContent(): boolean {
        // true until switch incorporated into UI
        // return this.settings.EBWCOFFLINE.SYNCHORIGINALCONTENT == "1"
        return true;
    }
    setSynchronizeOriginalContent(enable: boolean): void {
        if(!this.settings.EBWCOFFLINE) {
            this.settings.EBWCOFFLINE = {};
        }
        this.settings.EBWCOFFLINE.SYNCHORIGINALCONTENT = enable ? "1" : "0";
        this.saveSettings();
    }
    getWfInboxStaticColumns(): AsIniWfInboxStaticColumns {
        const def: AsIniWfInboxStaticColumns = {
            WFCLOSURE: true,
            WFCREATION: true,
            WFITEMNAME: true,
            WFNAME: true,
            WFPERSONALIZED: true,
            WFSUBJECT: true
        };
        for(const key of Object.keys(def)) {
            def[key] = this.settings["WORKFLOW@INBOXCOLUMNS"]?.[key] != "0";
        }
        return def;
    }
}
