import { ModalDialogService } from "SERVICES_PATH/eob.modal.dialog.srv.js";
import * as angular from "angular";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

angular.module("eob.framework").directive("eobModalProgressStatus", EobModalProgressStatus);

EobModalProgressStatus.$inject = ["$rootScope", "modalDialogService", "messageService"];

function EobModalProgressStatus($rootScope: any, modalDialogService: ModalDialogService, messageService: MessageService): any {
    return {
        restrict: "E",
        scope: {
            binding: "=",
            hideHeader: "=",
            noDismissing: "=",
            hideCloseHeaderButton: "="
        },

        link(scope: any): void {
            // Make the normal modal dialog smaller to 250px
            // let modalDialog = $("#modalDialogContainer");
            $rootScope.$broadcast("close.inline.dialogs");

            scope.$on("$destroy", () => {
                messageService.broadcast(ModalEvents.DESTROY);
            });

            /**
             * Notify the contained dialog directive, that the dialog will be closed.
             */
            scope.binding.closeProgressDialog = () => {
                modalDialogService.hideProgressDialog(true);
                $rootScope.$broadcast("close.inline.dialogs");
                scope.$destroy();
            };
        },
        template: require("!raw-loader!./eob.modal.progress.status.html")
    };
}
