import {Injectable} from "@angular/core";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {DashletService} from "MODULES_PATH/dashlet/interfaces/dashlet-service.interface";

@Injectable({
    providedIn: "root"
})
export class ContentDashletService implements DashletService {
    constructor(private viewerService: ViewerService) {
    }

    update(osid: number): void {
        this.viewerService.updateContentViewer(osid);
    }

    refresh(osid: number, keepCache: boolean): void {
        void this.viewerService.refreshContentViewer(osid, keepCache);
    }

    clear(): void {
        this.viewerService.clearContentViewer();
    }

    sendEvent(eventKey: string, data: unknown): void {
    }
}
