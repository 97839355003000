import {Component, forwardRef, Inject, Input, OnInit} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {FormControl} from "@angular/forms";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-form-static-text",
    templateUrl: "./eob-form-static-text.component.html",
    styleUrls: ["./eob-form-static-text.component.scss"]
})
export class EobFormStaticTextComponent implements OnInit {
    @Input() field: Field;
    @Input() layoutField: Field;
    @Input() ismockform: boolean;
    @Input() formid: string;
    @Input() control: FormControl;

    showAltLang: boolean;
    objectDefLang: any;

    constructor(@Inject(forwardRef(() => "environmentService")) public environmentService: TodoEnvironmentService) {
    }

    ngOnInit(): void {
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang() && !this.ismockform;
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();
    }
}
