import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2
} from "@angular/core";
import {Subject} from "rxjs";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {FormPhoneService} from "MODULES_PATH/form/services/form-phone.service";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {Layout} from "INTERFACES_PATH/layout.interface";
import {Field} from "INTERFACES_PATH/field.interface";
import {TodoFieldApiService, TodoFormHelper} from "INTERFACES_PATH/any.types";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {StandaloneValidationBubbleComponent} from "MODULES_PATH/form/components/standalone-validation-bubble/standalone-validation-bubble.component";

@Component({
    selector: "eob-responsive-form-component",
    templateUrl: "./eob-responsive-form.component.html",
    styleUrls: ["./eob-responsive-form.component.scss"]
})
export class EobResponsiveFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() layout: Layout;
    @Input() ismockform: boolean;
    @Input() formhelper: TodoFormHelper = {};
    @Input() formdata: any = {};
    @Input() formid: string;
    @Input() redrawsubject: Subject<any>;
    @Input() validationBubble?: StandaloneValidationBubbleComponent;

    @Output() onformready: any = new EventEmitter<any>();

    layoutFields: Field[] = [];

    // eslint-disable-next-line max-params
    constructor(
        @Inject(forwardRef(() => ClientService)) public clientService: ClientService,
        @Inject(forwardRef(() => FormPhoneService)) public formPhoneService: FormPhoneService,
        @Inject(forwardRef(() => "fieldApiService")) public fieldApiService: TodoFieldApiService,
        public layoutManagerService: LayoutManagerService,
        public renderer: Renderer2,
        public el: ElementRef) {
    }

    ngOnInit(): void {
        if (this.validationBubble) {
            this.formhelper.validationBubble = this.validationBubble;
        }

        this.buildResponsiveForm(this.layout, this.formdata, this.formhelper);

        if (!this.redrawsubject) {
            return;
        }

        this.redrawsubject.subscribe(baseParamFormDef => {
            // called by from.dir when base params have been added to or removed from the combined form
            // rebuilds the base param section
            this.ismockform = true;
            this.layoutFields = [];
            // clear formdata without creating a new reference
            for (const i in this.formdata) {
                delete this.formdata[i];
            }
            const baseParamFields: FieldModel[] = baseParamFormDef.formFields;
            // debugger
            for (const field of baseParamFields) {
                this.formdata[field.internal] = {
                    model: field,
                    value: "",
                    initialValue: "",
                    validationMode: "min"
                };
            }
            this.buildResponsiveForm(this.layout, this.formdata, this.formhelper);
        });
    }

    buildResponsiveForm(layout: any, fieldMap: any, formHelper: any, currentContainer?: any): void {
        // TODO find out why layout formFields don't use the same FieldModel instance as the ones created in FormService#createFormData()
        const addedFields: any[] = [];
        const formContainer: any = currentContainer == void 0 ? this.renderer.createElement("div") : currentContainer;

        // quick search
        if (this.ismockform && (layout == void 0 || layout.fieldList == void 0)) {
            for (const i in this.formdata) {
                const layoutField: any = this.formdata[i].model;

                // we need to push a separate field for label generation
                const labelField: any = {
                    ...layoutField,
                    isLabel: true,
                    realLeft: 0,
                    // absLeftInCols: 0,
                    realWidth: this.clientService.isPhone() ? 100 : 30
                };

                if (layoutField.isTextArea && layoutField.height == void 0) {
                    layoutField.height = 6;
                }

                if (!new RegExp(
                    `(${FormFieldType.GRID}|${FormFieldType.PAGECONTROL}|${FormFieldType.GROUP}|${FormFieldType.RADIO}|${FormFieldType.BUTTON}|${FormFieldType.CHECKBOX}|${FormFieldType.STATIC})`
                ).test(labelField.type)) {
                    layoutField.realLeft = 0;
                    layoutField.realWidth = this.clientService.isPhone() ? 100 : 70;
                    this.layoutFields.push(labelField);
                }
                this.layoutFields.push(layoutField);
            }
        } else if (this.clientService.isPhone()) {
            this.layoutFields = this.formPhoneService.buildFlexForm(this.layout);
        } else {
            if (layout == void 0) {
                console.warn("no layout information for form");
                return;
            }

            for (const i in layout.fieldList) {
                const layoutField: any = layout.fieldList[i];

                // build the fieldname based on its internal and the fact whether it is a label or a control
                const fieldName = `${layoutField.internal}-${layoutField.isLabel ? "text" : "input"}`;
                if (!addedFields.includes(fieldName)) {
                    addedFields.push(fieldName);
                    this.layoutFields.push(layoutField);
                }
            }
        }

        this.renderer.appendChild(this.el.nativeElement, formContainer);

        if (layout && layout.maxWidth != void 0 && !this.clientService.isPhone()) {
            const formHeight = layout.rows.length * 16 + 16;
            this.renderer.setStyle(this.el.nativeElement, "max-width", `${layout.maxWidth}`);
            this.renderer.setStyle(this.el.nativeElement, "margin", "auto");
            this.renderer.setStyle(this.el.nativeElement, "min-height", `${formHeight}px`);
        }

        if (this.clientService.isMobile()) {
            this.renderer.setStyle(this.el.nativeElement, "position", "relative");
        }

        if (layout && layout.rows && this.layoutManagerService.isTouchLayoutActive() && !this.clientService.isPhone()) {
            this.renderer.setStyle(this.el.nativeElement, "min-height", `${layout.rows.length * 20 + 16}px`);
        }

        this.linkUniqueFields(this.formdata);

        if (layout) {
            this.addApi2ZeroSizedFields(this.formhelper, this.layout.zeroSizedFields, this.formdata);
        }
    }

    /**
     * @param zeroSizedFields mask field which are positioned to zero to make them invisible to the user
     * @param formHelper
     */
    addApi2ZeroSizedFields(formhelper: any, zeroSizedFields: any[], formdata: any): void {
        zeroSizedFields.forEach(zeroSizedField => {
            const zeroSizedContainer: HTMLElement = this.renderer.createElement("span");
            const field: any = formdata[zeroSizedField.internal];
            field.api = this.fieldApiService.getFieldApi(formhelper, field, zeroSizedContainer);
        });
    }

    linkUniqueFields(formdata: any): void {
        const uniqueFields: any[] = [];

        // looking for uniquefields
        for (const i in formdata) {
            const fieldModel: any = formdata[i].model;

            if (fieldModel.isUnique) {
                uniqueFields.push(formdata[i]);
            }
        }

        if (uniqueFields.length) {
            for (const data of uniqueFields) {
                data.model.uniqueFields = uniqueFields;
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.layout != void 0 && this.layout.fieldList != void 0) {
            if (this.layout.parent == void 0) {
                this.onformready.emit(true);
            }
        }

        if (!this.clientService.isPhone()) {
            return;
        }

        for (const element of $(this.el.nativeElement).find("eob-form-element")) {
            this.renderer.setStyle(element, "top", "unset");
            this.renderer.setStyle(element, "height", "auto");
        }
        for (const element of $(this.el.nativeElement).find(".has-addon")) {
            const width = Number(element.style.width.replace("%", ""));
            if (width >= 100) {
                this.renderer.setStyle(element, "width", "100%");
                this.renderer.setStyle(element, "max-width", "100%");
            } else {
                this.renderer.setStyle(element, "width", "70%");
                this.renderer.setStyle(element, "max-width", "70%");
            }
            this.renderer.setStyle(element, "padding-right", "0px");
        }

    }

    ngOnDestroy(): void {
        //     // needed if child gets re-created (eg on some model changes)
        //     // note that subsequent subscriptions on the same subject will fail
        //     // so the parent has to re-create parentSubject on changes
        //     if (this.redrawsubject) {
        //         this.redrawsubject.unsubscribe();
        //     }
    }
}
