import {Component, forwardRef, Inject, Input, OnInit, ElementRef, OnDestroy, Renderer2} from "@angular/core";
import {StateParams, StateService} from "@uirouter/core";
import {Subscription} from "rxjs";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {FooterConfig} from "MODULES_PATH/form/interfaces/footer-config.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {
    TodoFormService,
    TodoFormHelper,
    TodoStateHistoryManager,
} from "INTERFACES_PATH/any.types";
import {TimerDoneType} from "MODULES_PATH/timer/enums/timer-done-type";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";

@Component({
    selector: "eob-form-footer",
    templateUrl: "./eob-form-footer.component.html",
    styleUrls: ["./eob-form-footer.component.scss"]
})
export class EobFormFooterComponent implements OnInit, OnDestroy {
    @Input() formHelper: TodoFormHelper;
    @Input() footerConfigs: FooterConfig[];
    @Input() footerSearchSettingsConfigs: FooterConfig[];

    saveDisabled = false;

    private readonly translateFn: TranslateFnType;

    private isIndexView = false;
    private isIndexEdit = false;
    private isSearch = false;
    private isCreate = false;
    private isWorkflow = false;
    private isVariant = false;

    private disableSaveButtonSubscription: Subscription;

    constructor(@Inject(forwardRef(() => "$filter")) private $filter: ng.IFilterService,
                @Inject(forwardRef(() => "$stateParams")) private $stateParams: StateParams,
                @Inject("formService") private formService: TodoFormService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                private clientService: ClientService,
                private notificationsService: NotificationsService,
                private $state: StateService,
                private messageService: MessageService,
                private el: ElementRef,
                private renderer: Renderer2
    ) {
        this.translateFn = this.$filter("translate");
    }

    ngOnInit(): void {
        this.initStateFlags();

        this.saveDisabled = this.formHelper != void 0 ? this.formHelper.saveDisabled : false;

        this.disableSaveButtonSubscription = this.messageService.subscribe(FormEvent.DISABLE_SAVE_BUTTON, () => {
            this.disableSaveButton();
        });
    }

    ngOnDestroy(): void {
        if (this.disableSaveButtonSubscription) {
            this.disableSaveButtonSubscription.unsubscribe();
        }
    }

    onClick(action: string) {
        switch (action) {
            case "submit":
                this.submit();
                break;
            case "saveUser":
                this.saveUser();
                break;
            case "saveWorkflow":
                this.saveWorkflow();
                break;
            case "toggleSearchSettings":
                this.toggleSearchSettings();
                break;
            case "back":
                this.back();
                break;
            default:
                break;
        }
    }

    toggleSearchSettings(): void {
        this.messageService.broadcast(FormEvent.TOGGLE_SEARCH_SETTINGS);
    }

    saveWorkflow(): void {
        this.formHelper.save();
    }

    saveUser(): void {
        this.messageService.broadcast(Broadcasts.USERMANAGEMENT_SAVE_USER);
    }

    submit(): Promise<any> {
        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            return;
        } else if (this.formHelper == void 0) {
            console.warn("The formHelper is not available, yet.");
            return;
        }

        return this.formHelper.submit();
    }

    back(): void {

        // Todo DODO-13393
        // FormService.setBeforeCancelExecuted({isExecuted: true});

        if (this.isCreate) {
            this.formHelper.createResult$.next(TimerDoneType.CREATE_VIEW_CANCEL);
            this.formHelper.createResult$.complete();
        }

        if (this.isIndexEdit || this.isWorkflow || this.isCreate) {
            return this.formService.cancel(this.formHelper);
        }

        if (this.isVariant) {
            this.stateHistoryManager.goToPreviousState();
        } else {
            history.back();
        }
    }

    private disableSaveButton(): void {
        const saveButton: HTMLButtonElement = this.el.nativeElement.querySelector(".save-button");

        if (saveButton) {
            this.renderer.setAttribute(saveButton, "disabled", "disabled");
            this.renderer.addClass(saveButton, "disabled");
        }
    }

    private initStateFlags(): void {
        this.isIndexView = ((this.$state.current.name == "indexdata" && this.$stateParams.mode == "view") || (this.formHelper && this.formHelper.isView));
        this.isIndexEdit = this.$state.current.name == "indexdata" && this.$stateParams.mode == "edit";
        this.isSearch = this.$state.current.name == "search";
        this.isCreate = this.$state.current.name == "create";
        this.isWorkflow = this.$state.current.name == "workflow";
        this.isVariant = this.$state.current.name == "variants";
    }
}
