export enum ViewerEvent {
    SHOW_VIEWER = "show.viewer",
    UPDATE_VIEWER = "update.viewer.area",
    REFRESH_VIEWER = "refresh.viewer.area",
    CLEAR_VIEWER = "clear.viewer.area",
    DESTROY_DETACHED_VIEWER = "destroy.detached.viewer",
    UPDATE_DMS_CACHE = "update.dms.cache",
    EXEC_DASHLET_API = "exec.dashlet.api",
    SEND_DASHLET_EVENT = "send.dashlet.event",
    ATTACHMENTS_AVAILABLE = "attachments.available"
}
