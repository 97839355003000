import {MessageService} from "CORE_PATH/services/message/message.service";
import {Observable} from "rxjs";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {BackendBaseService} from "CORE_PATH/backend/services/base/backend-base.service";
import {OsRestFavoritesService} from "CORE_PATH/backend/modules/osrest/services/favorites/osrest-favorites.service";
import {Dms2FavoritesService} from "CORE_PATH/backend/modules/dms2/services/favorites/dms2-favorites.service";

@Injectable({providedIn: BackendModule})
export class BackendFavoritesService extends BackendBaseService<Dms2FavoritesService | OsRestFavoritesService>{

    constructor(messageService: MessageService, dms2FavoritesService: Dms2FavoritesService, osRestFavoritesService: OsRestFavoritesService) {
        super(messageService, dms2FavoritesService, osRestFavoritesService);
    }

    /**
     * @return {Observable<BackendObject>} - An observable list of all the favorites from the current user.
     */
    getFavorites(): Observable<BackendObject> {
        return this.osRestService.getFavorites();
    }
}