export enum Languages {
    de = 7,
    en = 9,
    es = 10,
    fr = 12,
    hu = 14,
    it = 16,
    nl = 19,
    nb = 20,
    pl = 21,
    sv = 29,
    ar = 160,
    bg = 161,
    zh_CN = 162,
    zh_TW = 163,
    hr = 164,
    cs = 165,
    da = 166,
    et = 167,
    fi = 168,
    el = 169,
    he = 170,
    ja = 171,
    ko = 172,
    lv = 173,
    lt = 174,
    pt_BR = 175,
    pt = 176,
    ro = 177,
    ru = 178,
    sr = 179,
    sk_SK = 180,
    sl_SI = 181,
    th = 182,
    tr = 183,
    uk = 184
}