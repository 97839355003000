import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Type} from "@angular/core";
import {EobFormComponent} from "MODULES_PATH/form/eob-form.component";
import {EobResponsiveFormComponent} from "MODULES_PATH/form/components/eob-responsive-form/eob-responsive-form.component";
import {EobFormElementComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-element.component";
import {EobFormButtonComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-button/eob-form-button.component";
import {EobFormCheckboxComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-checkbox/eob-form-checkbox.component";
import {EobFormGroupComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-group/eob-form-group.component";
import {EobFormPageControlComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-page-control/eob-form-page-control.component";
import {EobFormRadioComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-radio/eob-form-radio.component";
import {EobFormStaticTextComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-static-text/eob-form-static-text.component";
import {EobFormTextFieldComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-text-field/eob-form-text-field.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "SHARED_PATH/shared.module";
import {EobValidationBubbleComponent} from "MODULES_PATH/form/components/eob-validation-bubble/eob-validation-bubble.component";
import {FieldMaskDirective} from "MODULES_PATH/form/directives/field-mask.directive";
import {EobFormFooterComponent} from "MODULES_PATH/form/components/form-footer/eob-form-footer.component";
import {AutocompleteModule} from "MODULES_PATH/autocomplete/autocomplete.module";
import {DefaultInputDirective} from "MODULES_PATH/form/directives/default-input.directive";
import {TextareaComponent} from "./components/inputs/textarea/textarea.component";
import {InputComponent} from "./components/inputs/input/input.component";
import {StandaloneValidationBubbleComponent} from "MODULES_PATH/form/components/standalone-validation-bubble/standalone-validation-bubble.component";
import {CheckboxComponent} from "./components/inputs/checkbox/checkbox.component";
import {DisplayBubbleDirective} from "./directives/display-bubble.directive";
import {RadioButtonComponent} from "MODULES_PATH/form/components/inputs/radio-button/radio-button.component";
import {LabelComponent} from "./components/inputs/label/label.component";
import {AddonComponent} from "./components/inputs/addon/addon.component";
import {CapslockTracerComponent} from "./components/capslock-tracer/capslock-tracer.component";
import {StandaloneFormElementComponent} from "./components/standalone-form-element/standalone-form-element.component";

const components: Array<Type<unknown>> = [
    EobFormComponent,
    EobResponsiveFormComponent,
    EobFormElementComponent,
    EobFormButtonComponent,
    EobFormCheckboxComponent,
    EobFormGroupComponent,
    EobFormPageControlComponent,
    EobFormRadioComponent,
    EobFormStaticTextComponent,
    EobFormTextFieldComponent,
    EobValidationBubbleComponent,
    EobFormFooterComponent,
    TextareaComponent,
    InputComponent,
    StandaloneValidationBubbleComponent,
    CheckboxComponent,
    RadioButtonComponent,
    LabelComponent,
    CapslockTracerComponent
];

const directives: Array<Type<unknown>> = [
    FieldMaskDirective,
    DefaultInputDirective,
    DisplayBubbleDirective
];

@NgModule({
    declarations: [...components, ...directives, AddonComponent, StandaloneFormElementComponent],
    imports: [CommonModule, FormsModule, SharedModule, AutocompleteModule, ReactiveFormsModule],
    exports: [...components, StandaloneFormElementComponent, DisplayBubbleDirective],
    entryComponents: [...components]
})
export class FormModule {
}
