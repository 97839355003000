import {Injectable, Inject, Injector} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {WfOrgAddonField} from "INTERFACES_PATH/addon.field.interface";
import {OrgAddonConfig, OrgAddonEntry} from "INTERFACES_PATH/org-addon-entry.interface";
import {WfOrganisationObject, WfUserOrgObject} from "INTERFACES_PATH/wf-organisation-object.interface";
import {Tree, TreeNode} from "INTERFACES_PATH/tree.interface";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {TodoFormHelper} from "INTERFACES_PATH/any.types";

@Injectable({providedIn: "root"})
export class WfOrgAddonService {
    private readonly translateFn: TranslateFnType;
    private dictionary: any;

    private wfOrganisationTree: Tree = {
        config: {
            readonly: false,
            useMultiSelect: true,
            shortValue: true,
            hideShortValues: true,
            sorted: true,
            facetIcon: true,
            separator: ";",
            intermediate: true,
            selectCaseInsensitive: true
        },
        nodes: []
    };

    constructor(private organisationService: OrganisationService,
                @Inject("treeAddonService") private treeAddonService: any,
                @Inject("$filter") private $filter: ng.IFilterService, private injector: Injector) {
        this.translateFn = this.$filter("translate");

        this.dictionary = {
            roleUser: this.translateFn("modal.org.addon.role.user"),
            unknownUser: this.translateFn("modal.org.addon.unknown.user")
        };
    }

    showOrganisationAddonByField(event: Event, field: WfOrgAddonField, formHelper?: TodoFormHelper, onCancel?: () => void): void {
        if (field.model?.config.display === "tree") {
            this.buildOrganisationList(field);
            return this.treeAddonService.showTree(event, field, formHelper);
        }

        if (event) {
            event.stopImmediatePropagation();
        }

        if (field.applySelection == undefined) {
            field.applySelection = items => this.applySelection(field, items);
        }

        this.buildOrganisationList(field);

        const content: OrgAddonEntry[] = this.getContent(field);
        content.sort((a, b) => a.displayName.localeCompare(b.displayName));

        const config: OrgAddonConfig = {
            sortBy: "displayName",
            showDescription: false
        };

        // noinspection JSDeprecatedSymbols - not possible to change to non-deprecated version
        this.injector.get("modalDialogService").showOrgMultiselectDialog(this.translateFn("modal.user.addon.title"), config, content, field.applySelection.bind(this), onCancel);
    }

    getContent(field: WfOrgAddonField): OrgAddonEntry[] {
        const entries: OrgAddonEntry[] = field.model?.config?.orgMember?.map(entry => ({
            displayName: this.buildDisplayName(field.model?.config, entry),
            type: entry.typ,
            icon: (entry.typ == "Person") ? "<i class='icon-16-user'></i>" : "<i class='icon-16-user-group'></i>",
            children: entry.children,
            selected: false,
            value: entry
        }));

        for (const selectedValue of field.orgValues) {
            const orgObj: any = entries.find(member => member.value.id == selectedValue.id);

            if (orgObj !== undefined) {
                orgObj.selected = true;
            } else {
                entries.push({
                    displayName: `${this.dictionary.unknownUser} [${selectedValue.id}]`, // show unknown user with their id
                    type: "user",
                    icon: "",
                    selected: true,
                    invalid: true,
                    value: selectedValue
                });
            }
        }

        return entries;
    }

    applySelection(field: WfOrgAddonField, items: OrgAddonEntry[]): void {
        field.api.setValue(items.map(item => item.value.name).join(";"), true);
        field.orgValues = items.map(item => Object.assign({}, item.value));

        if (!field.isValid) {
            field.api.setValid();
        }
    }

    /** Get the list of organisation objects specific for the given field. */
    getOrgList(config): WfOrganisationObject[] {
        const tmpMember: WfOrganisationObject[] = (config.showAll) ? this.organisationService.getWfOrgPerformer() :
            this.organisationService.getWfOrgPerformerByIds(config.member);

        const orgMember: WfOrganisationObject[] = [];
        for (const item of tmpMember) {
            if (item.login != "") {
                item.displayName = this.buildDisplayName(config, item);
                orgMember.push(Object.assign({}, item));
            }
        }
        return orgMember;
    }

    buildOrganisationList(field: WfOrgAddonField, autocorrectValues: boolean = false): WfOrgAddonField {
        if (field.model && field.model.config?.display === "tree" && field.model.tree == undefined) {
            field.model.tree = this.buildOrganisationTree(field.model?.config);
        }

        field.orgValues = [];
        if (field.model?.config.initializeValue != false && field.value != "" && field.value != void 0) {
            const tmpValues: string[] = field.value.split(";");
            const newValues: string[] = [];

            // these tmp values have to be the ids of the members, if this is not the case,
            // the validation and auto correction might solve this
            for (const tmpValue of tmpValues) {
                const orgObj: WfOrganisationObject = field.model?.config?.orgMember?.find(member => member.id == tmpValue || member.name == tmpValue);
                if (orgObj) {
                    field.orgValues.push(orgObj);
                    newValues.push(orgObj.name);
                } else {
                    field.orgValues.push({
                        id: tmpValue,
                        name: tmpValue,
                        typ: "invalid"
                    });
                }
            }

            if (newValues.length > 0 && autocorrectValues) {
                field.api.setValue(newValues.join(";"));
            }
        }

        return field;
    }

    buildDisplayName(config: any, member: WfOrganisationObject): string {
        const rule: string = config.namingRule;
        if (rule == void 0 || rule === "" || member.typ != "Person") {
            return member.name;
        }

        const user: WfUserOrgObject = member as WfUserOrgObject;

        let name: string = "";
        if (rule !== void 0) {
            name = rule.replace("%$$Name$$%", user.name);

            for (const key in user) {
                if (key === "name") {
                    continue;
                }

                const placeholder: string = `%${key}%`;
                const startIndex: number = name.toLowerCase().indexOf(placeholder);
                if (startIndex != -1) {
                    const arr: string[] = name.split("");
                    const val: string = user[key] != void 0 ? user[key] : "";
                    arr.splice(startIndex, placeholder.length, val);
                    name = arr.join("");
                }
            }
        }

        if (name.trim().length === 0) {
            if (user.vorname !== "" && user.nachname !== "") {
                name = user.nachname === "" ? user.vorname : `${user.nachname}, ${user.vorname}`;
            } else if (user.login !== "") {
                name = user.login;
            } else {
                name = user.name;
            }
        }

        return name;
    }

    private buildOrganisationTree(config: any): Tree {
        const wfOrganisation: any = this.organisationService.getWfOrganisationTree();
        const orgTree: Tree = { ...this.wfOrganisationTree, nodes: [] };

        this.traverseTree(wfOrganisation.Wurzel[0].children, orgTree.nodes, undefined, config);

        return orgTree;
    }

    private traverseTree(source: any, target: TreeNode[], parent: TreeNode, config: any): void {
        for (const node of source) {
            const newNode: TreeNode = this.createTreeNode(node, parent, config);
            if (config.member.includes(node.id) || node.children?.find(arg => config.member.includes(arg.id)) != void 0 || config.showAll) {
                target.push(newNode);
            }
            if (node.children) {
                newNode.nodes = [];
                this.traverseTree(node.children, newNode.nodes, node, config);
            }
        }
    }

    private createTreeNode(source: any, parent: any, config: any): TreeNode {
        let result: any;

        const typeIconMap: Map<string, string> = new Map([
            ["Person", "user"],
            ["Land", "earth"],
            ["Organisation", "organization"],
            ["Abteilung", "team"],
            ["Team", "team"],
            ["Rolle", "user-group"],
        ]);

        result = {
            value: this.buildDisplayName(config, source),
            short: `${source.name}`,
            iconClass: `icon-16-${typeIconMap.get(source.typ)}`,
            type: `${source.typ}`,
            selectable: /Rolle|Person/gi.test(`${source.typ}`)
        };

        if (parent) {
            result = {...result, ...{parent: {short: parent.name}}};
        }

        return result;
    }
}
