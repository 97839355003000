import {DmsMessageType} from "MODULES_PATH/dms/enums/dms-message-type.enum";
import * as dayjs from "dayjs";
import {BehaviorSubject, from, fromEvent, Subject, zip} from "rxjs";
import {catchError, concatMap, take, takeUntil} from "rxjs/operators"
import {BeforeOpenResultCode} from "SERVICES_PATH/scripting/eob.client.script.codes";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {HitlistEvent} from "MODULES_PATH/hitlist/enums/hitlist-event.enum";
import {EmsStatusCodes} from "ENUMS_PATH/ems.enum";
import {TimerDoneType} from "MODULES_PATH/timer/enums/timer-done-type";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
require("SERVICES_PATH/eob.state.location.srv.js");

require("SERVICES_PATH/eob.hitlist.config.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/eob.search.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");

angular.module("eob.framework").directive("eobFolder", EobFolder);

EobFolder.$inject = ["$state", "$filter", "$timeout", "$location", "$rootScope", "$stateParams",
    "toolService", "gridContentService", "gridContentUtilsService", "asIniService", "viewerService", "actionService", "locationService",
    "dmsActionService", "kebabMenuService", "objectTypeService", "dmsDocumentService", "virtualGridService", "offlineCacheService",
    "hitlistConfigService", "environmentService", "stateHistoryManager", "notificationsService", "cacheManagerService",
    "searchService", "stateService", "clientService", "progressbarService", "layoutManagerService", "backendService", "errorModelService",
    "clientScriptService", "messageService", "progressService", "fileCacheService", "emsService", "timerService", "modalDialogService", "iconService", "contextMenuService"];

// eslint-disable-next-line max-params
export default function EobFolder($state, $filter, $timeout, $location, $rootScope, $stateParams,
                                  ToolService, GridContentService, GridContentUtilsService, AsIniService, ViewerService, ActionService, LocationService,
                                  DmsActionService, KebabMenuService, ObjectTypeService, DmsDocumentService, VirtualGridService, OfflineCacheService,
                                  HitlistConfigService, EnvironmentService, StateHistoryManager, NotificationsService, CacheManagerService,
                                  SearchService, StateService, ClientService, ProgressbarService, LayoutManagerService, BackendService, ErrorModelService,
                                  ClientScriptService, MessageService, ProgressService, FileCacheService, EmsService, TimerService, ModalDialogService, IconService, ContextMenuService) {
    return {
        restrict: "E",
        async link(scope, element) {
            const unsubscriber = new Subject();
            const gStateId = $stateParams.state;
            const gTreeItemId = $stateParams.folderId;

            let emsSub;
            const emsResult$ = new Subject();

            let hitlistUpdateRowsDone$ = new Subject();
            let insertEmails$ = new Subject();
            const hitlistReadySub = MessageService.subscribe(HitlistEvent.UPDATE_ROWS_DONE, () => hitlistUpdateRowsDone$.next(true));

            let gContextIds = [];
            let state = StateHistoryManager.getCurrentStateData();
            StateHistoryManager.updateStateData({
                name: $state.current.name,
                params: angular.copy($state.params)
            })

            if (!state) {
                $state.go("dashboard");
            }

            let stateConfig = state.data.config;

            let isResizing = false;
            let root = null;
            let currentTreeItem = null;
            let virtualGridInstance = null;
            let parentChildMap = {};
            let exploredFolder = [];
            let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);
            let treeSizeInPercentage = 0;
            let changedIdsBuffer = [];
            let changeCallbackDebounce;

            let path = stateConfig != void 0 && stateConfig.path != void 0 ? stateConfig.path : [];
            let exploreId = state.data.exploreId || "";
            let exploreObjectTypeId = state.data.exploreObjectTypeId || "";

            let treeElement;

            const resizeObserver = new MutationObserver((mutationsList, observer) => {
                if(mutationsList.find(x => x.type == "attributes" && x.attributeName == "style")) {
                    if (!isResizing) {
                        isResizing = true;
                        window.addEventListener("mouseup", () => {
                            isResizing = false;

                            let listElement = element.find("eob-hit-list");
                            let totalWidth = treeElement.outerWidth() + listElement.outerWidth();

                            treeSizeInPercentage = Math.round(treeElement.outerWidth() / totalWidth * 100);
                            HitlistConfigService.saveTreeSize(gStateId, treeSizeInPercentage);
                        }, {once: true})
                    }
                }
            });

            // THIS IS A PFLASTER !!! for the condition when we start on a flat tree view in the offline mode, switch to online and
            //  activate the tree again ..
            // there will be a mismatch in the osid and objectTypeId and we reload the whole state before we do anything else..
            //special conditiion ..
            if (ClientService.isLocalClient() && exploreObjectTypeId === "" && exploreId === "" && path.length == 1) {
                // check if the requested root node is an offline object
                // in addition check if the cached objectTypeId is equal to the path's objectTypeId
                // if they are not equal we found an error and have to perform magic ..
                let dmsDocument = await OfflineCacheService.getById(path[0].objectId);
                if (ClientService.isOnline()) {
                    if (dmsDocument != void 0 && dmsDocument.model.objectTypeId != path[0].objectTypeId) {
                        reloadFolderWithCorrectedData(dmsDocument.model)
                        return;
                    }
                } else if (dmsDocument != void 0 && !ObjectTypeService.isFolderType(dmsDocument.model.objectTypeId)) {
                    ClientService.registerConnectivityChangeHandler(folderConnectivityHandler)
                }
            }

            function getMimeTypeIcons(objectIds) {
                let dmsDocuments = CacheManagerService.dmsDocuments.get(objectIds) || [];

                let iconIds = [];
                for (let dmsDocument of dmsDocuments) {
                    let iconId = dmsDocument.model.fileProperties.mimeTypeIconId;
                    if (iconId !== void 0 && iconIds.indexOf(iconId) === -1) {
                        iconIds.push(iconId);
                    }
                }
                return IconService.processIcons(iconIds);
            }

            async function folderConnectivityHandler() {
                reloadFolderWithCorrectedData((await OfflineCacheService.getById(path[0].objectId)).model);
            }

            function reloadFolderWithCorrectedData({ osid, objectTypeId }) {
                StateService.openFolderOrRegisterAsync({
                    osid,
                    objectTypeId
                }, undefined, undefined, undefined)
            }

            scope.isPhone = ClientService.isPhone();
            scope.isForcedPhoneLayout = ClientService.isForcedPhoneLayout();
            scope.ready = false;
            scope.initialized = false;
            scope.showHitlistMenu = true;

            scope.hitlistConfig = null;
            scope.isLocationTreeActive = false;

            scope.folderTreeWidthPercentage = 25;

            scope.menuItemsCallbackAsync = menuItemsCallbackAsync;

            scope.showHitlist = function() {
                let headerContent = angular.element(document.querySelector(".dialog-header-content"));

                if (scope.isLocationTreeActive) {
                    scope.stateDescription = scope.descriptionCache;
                    scope.isLocationTreeActive = false;
                    headerContent.css("flex", "1");

                    setTimeout(() => {
                        MessageService.broadcast(HitlistEvent.UPDATE_GRID_CONTAINER);
                    }, 0);
                } else {
                    headerContent.css({
                        "display": "flex",
                        "flex": "initial"
                    });
                    scope.stateDescription = "";
                    scope.isLocationTreeActive = true;
                }
            };

            fromEvent(window, "hashchange").pipe(takeUntil(unsubscriber)).subscribe(async () => {
                if (virtualGridInstance) {
                    if (currentTreeItem && currentTreeItem.osid == $location.search().currentId) {
                        return;
                    }

                    let treeRow = virtualGridInstance.api.getRowByKey("osid", $location.search().currentId);

                    if (treeRow == void 0) {
                        treeRow = root;
                    }

                    if (treeRow != void 0) {
                        virtualGridInstance.api.select(treeRow);
                        virtualGridInstance.api.toggleRow(treeRow, true);
                        virtualGridInstance.api.scrollToIndex(treeRow.index);
                        await showContentAsync(treeRow, true);
                    }
                }
            });

            initLocationStateAsync();

            function goBackOnError(error) {
                if (error != void 0 && error.status === 404) {
                    NotificationsService.customError(ErrorModelService.createCustomError("WEB_OBJECT_FOLDER_NOT_FOUND"));
                    $state.go("dashboard");
                } else {
                    if (ClientService.isOnline()) {
                        NotificationsService.customError(ErrorModelService.createCustomError("WEB_OBJECT_FOLDER_NOT_FOUND"));
                    }
                    if(state.data.config.fromEntryState) {
                        $state.go("dashboard");
                    } else {
                        ClientService.executeStateErrorFallback();
                    }
                }
            }

            async function initLocationStateAsync() {
                if (gTreeItemId == void 0 || gTreeItemId == "") {
                    NotificationsService.error($filter("translate")("eob.folder.not.found"));
                    return;
                }

                if ($stateParams.cabinetId == void 0) {
                    let typeConfig = CacheManagerService.objectTypes.getById($stateParams.objectTypeId).model.config;
                    if (typeConfig != void 0) {
                        $stateParams.cabinetId = typeConfig.cabinetId;
                    }
                }

                if ($stateParams.state == void 0) {
                    $stateParams.state = Date.now();
                }

                if (path.length > 0) {
                    exploreId = path[path.length - 1].objectId;
                    exploreObjectTypeId = path[path.length - 1].objectTypeId;
                } else {
                    exploreId = $stateParams.folderId;
                    exploreObjectTypeId = $stateParams.objectTypeId;
                }

                progressBar.show();

                setCabinetIdInState($stateParams.cabinetId);

                initialResizeFolderTreeWidth();

                ContextMenuService.registerContextMenuActionsProvider("folderTree");
                ContextMenuService.registerContextMenuActionsProvider("emptySpaceInHitlist");

                scope.initialized = true;

                getData();
            }

            function initResizeSensor() {
                // treeElement query has to be deferred so that the element is actually present after initialization is done
                treeElement = scope.isPhone ? element.find("#location-tree-phone") : element.find("#location-tree");
                resizeObserver.observe(treeElement[0].parentElement, {attributes: true})
            }

            function initialResizeFolderTreeWidth() {
                let hitlistConfiguration = AsIniService.getHitlistConfiguration(state.data);
                let isPossibleCalculationFailure = hitlistConfiguration.locationTreeWidth > 90;

                if (isPossibleCalculationFailure) {
                    console.warn("The tree was too big in size, this might be due to a misscalculation during init --> fallback to default");
                }

                if (hitlistConfiguration.locationTreeWidth != void 0 && !isPossibleCalculationFailure) {
                    scope.folderTreeWidthPercentage = `${hitlistConfiguration.locationTreeWidth}%`;
                } else {
                    scope.folderTreeWidthPercentage = "25%";
                }

                treeSizeInPercentage = scope.folderTreeWidthPercentage;
            }

            // to look up for hit list configuration later
            function setCabinetIdInState(cabinetId) {
                if (cabinetId != void 0) {
                    let newConfig = {
                        cabinetId: parseInt($stateParams.cabinetId)
                    };

                    StateHistoryManager.updateConfig(newConfig, gStateId);
                }
            }

            function updateParentChildMap(parentId, children) {
                if (parentChildMap[parentId] == void 0) {
                    parentChildMap[parentId] = [];
                }

                if (children == void 0) {
                    return;
                }

                if (!Array.isArray(children)) {
                    children = [children];
                }

                for (let child of children) {
                    let childOsid = (child.model != void 0) ? child.model.osid : child.osid;

                    if (parentChildMap[parentId].indexOf(childOsid) == -1) {
                        parentChildMap[parentId].push(childOsid);
                    }
                }
            }

            /**
             * Show the content of the Folder / Register with the given osid
             * @param {String | osid} parentId - The osid of the parent to explore
             */
            async function updateHitlistContent(parentId) {
                let childIds = parentChildMap[parentId];
                let dmsDocumentList = await CacheManagerService.dmsDocuments.getOrFetchByIds(childIds);
                if (dmsDocumentList.length === 0 && childIds.length === 1) {
                    // Fetch index data for items unknown to DmsDocumentCache
                    let url = `/documents/search/${childIds[0]}?refresh=true&lockinfo=true`;
                    try {
                        let response = await BackendService.get(url);
                        CacheManagerService.dmsDocuments.add(response.data);
                        dmsDocumentList = CacheManagerService.dmsDocuments.get(childIds);
                    } catch (error) {
                        console.warn(`Error while fetching data for document ${childIds[0]}`);
                    }
                }
                if (childIds.length != dmsDocumentList.length) {
                    console.warn("childIds length does not match result from dms cache")
                }

                dmsDocumentList.sort((nodeA, nodeB) => nodeA.model.isRegister === nodeB.model.isRegister ? 0 : nodeA.model.isRegister ? -1 : 1);
                await getMimeTypeIcons(childIds);

                let hitlistConfig = GridContentService.getListEntries(dmsDocumentList);
                if (!/(favorites|offlineobjects)/gi.test(state.data.type) && isMaxHitsExceeded(hitlistConfig.rows.length)) {
                    NotificationsService.warning($filter("translate")("eob.hitlist.maxhits.exceeded"));
                }

                scope.hitlistConfig.api.updateRows(hitlistConfig, true);

                if (hitlistConfig.rows.length) {
                    scope.hitlistConfig.api.deselectAll();
                    scope.hitlistConfig.api.selectFirstItem();
                } else {
                    ViewerService.clearViewer();
                }

                // scope.hitlistConfig.api.setHitlistConfiguration();
            }

            /**
             * Definition of what max hits exceeded shall mean.
             * @return {boolean}
             */
            function isMaxHitsExceeded(number) {
                return (number >= EnvironmentService.env.hitlist.maxsize &&
                    EnvironmentService.env.hitlist.maxsize != -1 &&
                    // If we are offline, we are loading from favorites cache and there are all objects included.
                    ClientService.isOnline());
            }

            /**
             * Explore the content of a register and / or show it's content
             * @param {Object} row - the row item we need to show the content from
             */
            async function showContentAsync(row, ignoreHashChange) {
                if (ClientService.isOffline() && !(await OfflineCacheService.isObjectOfflineCached(row.osid))) {
                    return;
                }
                const dmsDocument = CacheManagerService.dmsDocuments.getById(row.osid)
                const shouldOpen = await ClientScriptService.executeBeforeOpenObjectScript(dmsDocument)
                if (shouldOpen != BeforeOpenResultCode.OPEN) {
                    if (shouldOpen == BeforeOpenResultCode.PREVENT_WITH_FALLBACK) {
                        virtualGridInstance.api.deselectRow(row)
                        virtualGridInstance.api.select(currentTreeItem)
                    }
                    return;
                }

                currentTreeItem = row;
                if (exploredFolder.indexOf(row.osid) == -1) {
                    (async () => {
                        let backendData;
                        try {
                            backendData = await LocationService.exploreFolderAsync(row.osid, row.objectTypeId, false);
                        } catch (error) {
                            console.warn(error);
                            goBackOnError();
                            throw error;
                        }

                        updateDocumentPool(backendData);
                        updateParentChildMap(backendData.osid, backendData.children);
                        updateHitlistContent(backendData.osid);

                        exploredFolder.push(row.osid);
                    })();
                } else {
                    updateHitlistContent(row.osid);
                }

                if (!ignoreHashChange) {
                    setIdsInLocationHash(row.osid, row.objectTypeId);
                }

                //onRowClick on smartphone to open the nodes
                if (scope.isPhone) {
                    virtualGridInstance.api.toggleRow(row, true);
                    scope.descriptionCache = currentTreeItem.name;

                    if (!scope.isLocationTreeActive) {
                        scope.stateDescription = currentTreeItem.name;

                        $rootScope.$apply();
                    }
                } else {
                    scope.stateDescription = currentTreeItem.name;
                    let tabTitle = `${$filter("translate")("eob.location.state.title")} - ${scope.stateDescription}`;
                    ClientService.refreshTabTitle(tabTitle);
                }
            }

            /**
             * React to the right click event on a tree row by showing the contextmenu
             * @param {Object} row - The right clicked tree row
             * @param {Event} event - The right click event
             * @param {Object} rowElement - The angular element of the clicked row.
             */
            function onRowRightClick(row, event, rowElement) {
                let parentId = row.parent == void 0 ? null : row.parent.osid;

                // small workaround to keep the hover style, while the contextmenu is open
                rowElement.addClass("select-hover");

                MessageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                    items: [{ osid: row.osid, objectTypeId: row.objectTypeId, id: row.osid }],
                    event,
                    contextData: { parentId, context: "folderTree", title: row.name}
                });

                let removeDialogsListener = $rootScope.$on("close.inline.dialogs", () => {
                    rowElement.removeClass("select-hover");
                    removeDialogsListener();
                });

                let removeCtxListener = MessageService.subscribe(InlineDialogEvent.DISPLAY_CTX_ACTIONS, () => {
                    rowElement.removeClass("select-hover");
                    removeCtxListener.unsubscribe();
                });
            }

            /**
             * The double click callback
             * @param {Object} params - The params of the clicked row
             */
            function onRowDoubleClick(params) {
                virtualGridInstance.api.toggleRow(params.rowData, true);
            }

            /**
             * The callback for when the user expands a node
             * @param {Object} params - The params of the clicked row from the virtual tree instance
             * @param {Function} done - The Callback to tell the virtual tree that the data is ready
             */
            async function onNodeExpandAsync(params, done) {
                if (!params.rowData.dataReady) {
                    let dmsDocuments = await LocationService.loadRegisterDetailsAsync(params.rowData.childRegister);
                    // let isOnline = ClientService.isOnline();

                    updateDocumentPool(dmsDocuments);

                    for (let dmsDocument of dmsDocuments) {
                        let itemName = dmsDocument.api.buildNameFromIndexData(10, false, false);
                        let row = params.api.getRowByKey("osid", dmsDocument.model.osid);

                        if (row != void 0) {
                            row.name = itemName;
                            row.icon = dmsDocument.model.icon;
                        }
                    }

                    updateParentChildMap(params.rowData.osid, dmsDocuments);

                    params.rowData.dataReady = true;
                }

                done();
            }

            /**
             * React to the Doubleclick event from the hitlist
             * In case a register was doubleclicked open it and apply the changes to the tree
             * @param {Object} hitlistParams - The clicked hitlist row parameters
             */
            function onHitlistDoubleClick(hitlistParams) {
                const dmsDocument = CacheManagerService.dmsDocuments.getById(hitlistParams.data.osid)
                if (!dmsDocument) {
                    console.warn("Hitlist doubleclick action on non-existent object")
                    return;
                }
                // Don't execute beforeOpen here, otherwise it'd be executed twice due to execution in showContentAsync()
                ActionService.executeDoubleClickAction(dmsDocument, "folder", dmsDocument.model.isDocument, "doubleclick");

                if (scope.isPhone) {
                    scope.stateDescription = scope.descriptionCache;
                }
            }

            /**
             * React to the rightclick event from the hitlist
             * In case a register was doubleclicked open it and apply the changes to the tree
             * @param {Object} hitlistParams - The clicked hitlist row parameters
             */
            function onHitlistRightClick(hitlistParams) {
                let selectedTreeItem = virtualGridInstance.api.getSelectedRows();

                let selectedRows = [];

                hitlistParams.api.forEachNodeAfterFilterAndSort((node) => {
                    if (node.selected) {
                        selectedRows.push(node.data)
                    }
                })

                MessageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                    items: selectedRows,
                    event: hitlistParams.event,
                    contextData: { parentId: selectedTreeItem[0].osid, context: "folder", title: (selectedRows[0].f1 || {}).title }
                });
            }

            /**
             * Convert and Store the backend data as dmsDocuments
             * @param {Object} backendData - The raw objectdata from the appconnector
             * @returns {Array} - Return the converted DmsDocuments
             */
            function updateDocumentPool(backendData) {
                if (!Array.isArray(backendData)) {
                    backendData = [backendData];
                }

                // don't trigger update listeners, to avoid unneccessary refreshs
                let documentIds = CacheManagerService.dmsDocuments.add(backendData, true);
                let dmsDocuments = CacheManagerService.dmsDocuments.get(documentIds);
                let listenerGuid = CacheManagerService.dmsDocuments.attachListener(documentIds, changeCallback);

                gContextIds.push(listenerGuid);

                for (let item of backendData) {
                    if (item.children != void 0 && item.children.length > 0) {
                        updateDocumentPool(item.children);
                    }
                }

                return dmsDocuments;
            }

            /**
             * the change callback in case any document gets modified
             */
            function changeCallback(changedIds, executeImmediately) {
                if (!executeImmediately) {
                    if (Array.isArray(changedIds)) {
                        changedIds.forEach(id => {
                            if (!changedIdsBuffer.includes(id)) {
                                changedIdsBuffer.push(id)
                            }
                        })
                    }
                    clearTimeout(changeCallbackDebounce)
                    changeCallbackDebounce = setTimeout(changeCallback.bind(null, changedIdsBuffer, true), 250);
                    return;
                }
                changedIdsBuffer = []
                if (scope.hitlistConfig == void 0 || scope.hitlistConfig.api == void 0) {
                    return;
                }

                let selectedItems = [];
                let selectedRows = scope.hitlistConfig.api.getSelectedRows();

                for (const selectedRow of selectedRows) {
                    selectedItems.push(selectedRow.id);
                }

                let childIds = parentChildMap[currentTreeItem.osid];
                let dmsDocuments = CacheManagerService.dmsDocuments.get(childIds);

                if (dmsDocuments && dmsDocuments.length > 0) {
                    dmsDocuments.sort((nodeA, nodeB) => nodeA.model.isRegister === nodeB.model.isRegister ? 0 : nodeA.model.isRegister ? -1 : 1);
                }

                let hitlistConfig = GridContentService.getListEntries(dmsDocuments);

                if (CacheManagerService.dmsDocuments.get(root.osid).length === 0) {
                    history.back();
                }

                scope.hitlistConfig.api.updateRows(hitlistConfig, true);
                scope.hitlistConfig.api.selectItems(selectedItems, "id");
            }

            /**
             * Check if the current State is still inside the view
             * @returns {boolean} - Return Boolean?!
             */
            function isStateAlive() {
                return gStateId == $stateParams.state;
            }

            /**
             * Start fetching data
             */
            function getData() {
                $timeout(async () => {
                    if (!isStateAlive()) {
                        return;
                    }

                    try {
                        if (!isStateAlive()) {
                            return;
                        }

                        // If the locationTree isn't there scope have to apply
                        let locationTree = scope.isPhone ? element.find("#location-tree-phone") : element.find("#location-tree");

                        if (!locationTree[0]) {
                            scope.$apply();
                        }

                        let treeConfig = {
                            rowHeight: LayoutManagerService.isTouchLayoutActive() ? 40 : 24,
                            rows: [],
                            width: "100%",
                            childNodesKey: "childRegister",
                            columns: [{
                                field: "name",
                                isIconColumn: false,
                                showAsTree: true,
                                isLocationTree: true,
                                cellRenderer(params) {
                                    let icon = angular.element(`<i class="${(params.rowData.icon.indexOf("custom-icon-") !== -1) ? "" : "icon-16-"}${params.rowData.icon} eob-icon-16"></i>`);

                                    let container = angular.element("<div></div>");
                                    let text = angular.element(`<span class="node-text">${(params.rowData.name || "").replace(/</g, "&lt;").replace(/>/g, "&gt;")}</span>`);

                                    return container.append(icon).append(text);
                                }
                            }],
                            scrollContainer: scope.isPhone ? element.find("#location-tree-phone") : element.find("#location-tree"),
                            useMultiSelect: false,
                            useIntermediateNodes: true,
                            deselectWhenCollapse: false,
                            expandNodesByDefault: false,
                            autoSizeColumns: true,
                            readonly: false,
                            truncateCellContent: false,
                            sortComperator: sortRegisterTree,
                            onRowClick: async (row) => {
                                await showContentAsync(row)
                            },
                            onRowDoubleClick,
                            onRowRightClick,
                            onNodeExpandAsync
                        };

                        virtualGridInstance = VirtualGridService.getVirtualGridInstance(treeConfig);

                        // the explored register is not part of this location anymore, reload with the root node
                        let deepContains = container => container.osid == $stateParams.folderId || container.childRegister.find(child => deepContains(child));

                        let backendData = await LocationService.exploreFolderAsync(exploreId, exploreObjectTypeId, true);

                        if (!deepContains(backendData.folderRegisterTree[0])) {
                            reloadFolderWithCorrectedData({
                                osid: $stateParams.folderId,
                                objectTypeId: $stateParams.objectTypeId
                            });
                            return;
                        }

                        updateParentChildMap(backendData.osid, backendData.children);

                        let dmsDocuments = updateDocumentPool(backendData);
                        await getMimeTypeIcons(parentChildMap[backendData.osid]);

                        scope.stateDescription = dmsDocuments[0].api.getTitleAndDescription().title;
                        if (scope.isPhone) {
                            scope.descriptionCache = scope.stateDescription;
                        } else {
                            let tabTitle = `${$filter("translate")("eob.location.state.title")} - ${scope.stateDescription}`;
                            ClientService.refreshTabTitle(tabTitle);
                        }

                        let treeData = LocationService.buildTree(dmsDocuments, backendData.folderRegisterTree, state);

                        exploredFolder.push(exploreId);

                        virtualGridInstance.api.updateGridContent({ rows: treeData });

                        setIdsInLocationHash(exploreId, exploreObjectTypeId, true);

                        let registerList;
                        if (virtualGridInstance.api.getRowCount() > 100) {
                            registerList = virtualGridInstance.api.getVisibleRows();
                        } else {
                            registerList = virtualGridInstance.api.getRows();
                        }

                        let idSearchDmsDocuments = await LocationService.loadRegisterDetailsAsync(registerList);
                        let folderTreeIds = idSearchDmsDocuments.map(doc => doc.model.osid);
                        gContextIds.push(CacheManagerService.dmsDocuments.attachListener(folderTreeIds, changeCallback));

                        if (!isStateAlive()) {
                            return;
                        }

                        for (let dmsDocument of idSearchDmsDocuments) {
                            let itemName = dmsDocument.api.buildNameFromIndexData(10, false, false);
                            let row = virtualGridInstance.api.getRowByKey("osid", dmsDocument.model.osid);

                            if (row != void 0) {
                                row.name = itemName;
                                row.icon = dmsDocument.model.icon;
                            }
                        }

                        virtualGridInstance.api.refreshGrid(true);

                        let idMap = {};

                        for (let dmsDocument of idSearchDmsDocuments) {
                            idMap[dmsDocument.model.osid] = dmsDocument.model;
                        }

                        for (let registerItem of registerList) {
                            // if the parent does not exist we got the root node which we already know
                            if (registerItem.parent != void 0) {
                                updateParentChildMap(registerItem.parent.osid, idMap[registerItem.osid]);
                            }
                        }

                        let childIds = parentChildMap[exploreId];

                        await LocationService.addSelectedItemsAsync(childIds, exploreId);

                        let dmsDocumentList = CacheManagerService.dmsDocuments.get(childIds);
                        if (dmsDocumentList && dmsDocumentList.length > 0) {
                            dmsDocumentList.sort((nodeA, nodeB) => nodeA.model.isRegister === nodeB.model.isRegister ? 0 : nodeA.model.isRegister ? -1 : 1);
                        }

                        let hitlistConfig = GridContentService.getListEntries(dmsDocumentList);

                        if (!/(favorites|offlineobjects)/gi.test(state.data.type) && isMaxHitsExceeded(hitlistConfig.rows.length)) {
                            NotificationsService.warning($filter("translate")("eob.hitlist.maxhits.exceeded"));
                        }

                        scope.hitlistConfig = hitlistConfig;
                        scope.hitlistConfig.onDoubleClick = onHitlistDoubleClick;
                        scope.hitlistConfig.onRightClick = onHitlistRightClick;

                        $timeout(() => {
                            scope.ready = true;

                            MessageService.broadcast(Broadcasts.LOCATION_VIEW_READY, { emsResult$ });

                            root = virtualGridInstance.api.getRows()[0];

                            $timeout(async () => {
                                let selectedRows = virtualGridInstance.api.getSelectedRows();
                                if (selectedRows.length > 0) {
                                    virtualGridInstance.api.scrollToIndex(selectedRows[0].index);
                                } else {
                                    virtualGridInstance.api.selectIndex(0);
                                }

                                currentTreeItem = virtualGridInstance.api.getSelectedRows()[0];
                                scope.stateDescription = currentTreeItem.name;
                                let tabTitle = `${$filter("translate")("eob.location.state.title")} - ${scope.stateDescription}`;
                                ClientService.refreshTabTitle(tabTitle);
                            }, 0);

                            progressBar.hide();
                            $timeout(() => {
                                initResizeSensor();
                            }, 0);
                        }, 0);

                    } catch (error) {
                        console.warn(error);
                        goBackOnError(error);

                        if (error.status !== 404) {
                            throw error;
                        }
                    }
                }, 100);
            }

            function sortRegisterTree(rowA, rowB) {
                const docA = CacheManagerService.dmsDocuments.getById(rowA.osid);
                const docB = CacheManagerService.dmsDocuments.getById(rowB.osid);

                const confA = getFieldByPosition(CacheManagerService.objectTypes.getById(rowA.objectTypeId).api.getConfiguredFields());
                const confB = getFieldByPosition(CacheManagerService.objectTypes.getById(rowB.objectTypeId).api.getConfiguredFields());

                if (!confA || !confB) {
                    console.warn("confA and/or confB missing");
                    return 0;
                }

                // Also filter out obscure case where only one of the objects is missing
                if (!docA || !docB) {
                    if (docA || docB) {
                        console.warn("Only one of the objects to be compared doesn't exist inside cache", docA, docB);
                    }

                    return 0;
                }

                for (let i in confA) {
                    const confAi = confA[i];
                    const confBi = confB[i];

                    if (confBi === void 0) {
                        return 1; // > 0 because A it has further characters.
                    }

                    let valA = docA.model.fields[confAi.internal];
                    let valB = docB.model.fields[confBi.internal];

                    if (valA === void 0 && valB !== void 0) {
                        return -1; // < 0 because B it has further characters.
                    }

                    if (valA !== void 0 && valB === void 0) {
                        return 1; // > 0 because B it has further characters.
                    }

                    if (valA === void 0 && valB === void 0) {
                        continue; // They are identical and null. Let later field values decide.
                    }

                    if (confA[i].type === "date" || confA[i].type === "datetime") {
                        valA = dayjs(valA, EnvironmentService.env.dateFormat.datetime).valueOf().toString();
                    }

                    if (confB[i].type === "date" || confB[i].type === "datetime") {
                        valB = dayjs(valB, EnvironmentService.env.dateFormat.datetime).valueOf().toString();
                    }

                    if (valA.toLowerCase() === valB.toLowerCase()) {
                        continue; // They are identical and null. Let later field values decide.
                    }

                    return (valA.toLowerCase() > valB.toLowerCase()) ? 1 : -1;
                }

                return 0;
            }

            /**
             * returns the configured fields sorted by the position index
             * @param configuredFields
             * @returns {{}}
             */
            function getFieldByPosition(configuredFields) {
                let obj = {}

                for (let i in configuredFields) {
                    obj[configuredFields[i].position] = configuredFields[i]
                }

                return obj;
            }

            /**
             * save the current state information
             */
            function saveState() {
                let selection = virtualGridInstance.api.getSelectedRows();
                let selectedTreeItem = selection[0];

                // well there is nothing selected .. therefore the user navigated probably back before the location was
                // fully loaded .. simply return
                if (!selectedTreeItem) {
                    return;
                }

                // Todo: Use StateManager to Update the property. Otherwise it's hard to find errors while updating.
                let stateData = StateHistoryManager.getStateData(gStateId);
                stateData.data.exploreId = selectedTreeItem.osid;
                stateData.data.exploreObjectTypeId = selectedTreeItem.objectTypeId;
                stateData.data.config.path = [{
                    objectId: selectedTreeItem.osid,
                    objectTypeId: selectedTreeItem.objectTypeId
                }];

                let currentItem = selectedTreeItem;

                while (true) {
                    let parent = currentItem.parent;
                    if (parent == void 0) {
                        break;
                    }

                    currentItem = parent;

                    stateData.data.config.path.unshift({ objectId: parent.osid, objectTypeId: parent.objectTypeId });
                }
                // Todo: End use StateManager to Update

                if (scope.initialized && selectedTreeItem.objectTypeId >> 16 == 99) {
                    sessionStorage.setItem("eob.folder.state.selected.register", `${gTreeItemId};${selectedTreeItem.osid}`);
                }
            }

            function setIdsInLocationHash(objectId, objectTypeId, replaceURL) {
                // test, if current location hash includes something like "currentId=59209"
                let locationHash = window.location.hash || "";
                if (!/currentId=[0-9]+/gi.test(locationHash)) {
                    // add new currentId parameter
                    locationHash = `${locationHash}&currentId=${objectId}`;
                } else {
                    // replace existing currentId parameter
                    locationHash = locationHash.replace(/currentId=[0-9]+/gi, `currentId=${objectId}`);
                }

                // test, if current location hash includes something like "currentTypeId=17"
                if (!/currentTypeId=[0-9]+/gi.test(locationHash)) {
                    // add new currentTypeId parameter
                    locationHash = `${locationHash}&currentTypeId=${objectTypeId}`;
                } else {
                    // replace existing currentTypeId parameter
                    locationHash = locationHash.replace(/currentTypeId=[0-9]+/gi, `currentTypeId=${objectTypeId}`);
                }
                if (replaceURL) {
                    let url = `${location.pathname}${locationHash}`;
                    history.replaceState(null, null, url);
                } else {
                    window.location.hash = locationHash;
                }
            }

            /**
             * Create the kebab menu actions upon opening the kebab menu.
             * @returns {Promise<object>} Resolved with the kebab menu actions.
             */
            async function menuItemsCallbackAsync() {
                let menuItems = await KebabMenuService.getFolderStateMenuItemsAsync(currentTreeItem);
                for (let i in menuItems) {
                    menuItems[i].hitlistConfig = scope.hitlistConfig
                }

                return menuItems;
            }

            MessageService.subscribe(DmsMessageType.ROOT_REMOVED, () => {
                // Prevent state transition race condition, which would show a confusing "folder not found" toast
                setTimeout(() => {$state.go("dashboard");}, 500);
            }, takeUntil(unsubscriber));

            MessageService.subscribe(DmsMessageType.LOCATION_REMOVED, async (data) => {
                for (let osid of data.osids) {

                    if (osid == currentTreeItem.osid) {
                        await showContentAsync(virtualGridInstance.api.getRows()[0]);
                        virtualGridInstance.api.selectIndex(0);
                    }

                    virtualGridInstance.api.removeRowByKey("osid", osid);

                    let parentDeleted = false;
                    for (let child of Object.keys(parentChildMap)) {
                        if (parentChildMap[child].find(x => x == currentTreeItem.osid) && child == osid) {
                            await showContentAsync(virtualGridInstance.api.getRows()[0]);
                            virtualGridInstance.api.selectIndex(0);
                            parentDeleted = true;
                            break;
                        }
                    }
                    if (!parentDeleted) {
                        // Restore selection of virtual tree after deleting a node through context menu
                        let treeRow = virtualGridInstance.api.getRowByKey("osid", currentTreeItem.osid);
                        virtualGridInstance.api.select(treeRow);
                    }

                    let childIds = parentChildMap[data.parentId];

                    for (let j in childIds) {
                        if (childIds[j] == osid) {
                            childIds.splice(j, 1);
                        }
                    }
                }

                changeCallback();
            }, takeUntil(unsubscriber));

            MessageService.subscribe(DmsMessageType.LOCATION_CREATED, (data) => {
                let item = data.item;
                let parentId = data.parentId;

                if (data.error) {
                    // linking failed due to reasons
                    // if the location already exists, highlight it
                    if (parentChildMap[parentId].indexOf(item.id) != -1) {
                        NotificationsService.warning($filter("translate")("eob.folder.tree.link.item.already.exists"));
                    } else {
                        NotificationsService.customError(data.error, "WEB_CREATE_LOCATION_FAILED");
                        return;
                    }
                } else {
                    if (parentChildMap[parentId] == void 0) {
                        parentChildMap[parentId] = [];
                    }

                    parentChildMap[parentId].push(item.id);
                }

                let selectedTreeItem = virtualGridInstance.api.getSelectedRows();

                if (selectedTreeItem.length == 1 && selectedTreeItem[0].osid == parentId) {
                    updateHitlistContent(parentId);
                }
            }, takeUntil(unsubscriber));

            let timerDone$;

            if (TimerService.isRunning()) {
                emsSub = MessageService.subscribeFirst(ModalEvents.INSERT_EMAILS_AT_LOCATION, async (data) => {
                    ProgressService.finished.next(false);
                    const metadata = data.metadata;
                    const form = data.form;
                    const numberOfObjects = metadata.objects.length;
                    const resultObjects = [];
                    const timeIsUp$ = new Subject();
                    const loadingData$ = new BehaviorSubject(true);
                    let objectId, fileName, timerDoneReason;
                    let userDecision = {remember: false};

                    if (!timerDone$) {
                        timerDone$ = TimerService.done().subscribe(reason => {
                            timerDoneReason = reason;
                            timeIsUp$.next();
                            timeIsUp$.complete();
                        });
                    }

                    ProgressService.updateProgress(0, numberOfObjects);

                    async function handleDeduplication(object) {
                        const typeConfig = CacheManagerService.objectTypes.getById(metadata.objectTypeId).model.config;
                        const emsDeduplicationHandling = typeConfig.emsDeduplicationContext.handling;

                        if (ClientService.isDesktop()) {
                            window.electron.bringAppWindowToFront();
                        }

                        try {
                            if (emsDeduplicationHandling && userDecision.remember == false) {
                                userDecision = await ModalDialogService.showDeduplicationDialog(emsDeduplicationHandling);
                                if (userDecision.option) {
                                    return await EmsService.storeEmailAsync(metadata, fileName, object, form, userDecision.option);
                                }
                            } else {
                                return await EmsService.storeEmailAsync(metadata, fileName, object, form, userDecision.option);
                            }
                        } catch
                            (_) {
                            return null;
                        }
                    }

                    let subscription = from(metadata.objects).pipe(
                        concatMap(async (object, index) => {
                            loadingData$.next(true)
                            const current = index + 1;
                            fileName = object.properties.filePath.value.split(/[/\\]/).pop();

                            if (fileName == void 0 || fileName.length < 1) {
                                resultObjects.push(EmsService.buildEmsResponseObject(object, -1, EmsStatusCodes.FILENAME_PARSE_ERROR));
                                return current;
                            }

                            try {
                                let resultObject = await EmsService.storeEmailAsync(metadata, fileName, object, form);
                                resultObjects.push(resultObject);
                                loadingData$.next(false);
                                return current;
                            } catch (error) {
                                if (error.status != 300) {
                                    // status code other than 300, immediately fail
                                    resultObjects.push(EmsService.buildEmsResponseObject(object, objectId, EmsStatusCodes.STORING_FAILED));
                                    loadingData$.next(false);

                                    return current;
                                }

                                if (timerDoneReason === TimerDoneType.PROGRESS_DIALOG_CANCEL) {
                                    loadingData$.next(false);
                                    return current;
                                }
                                // status code 300, deduplication in progress
                                let resultObject = await handleDeduplication(object);
                                if (resultObject) {
                                    // deduplication was successful
                                    resultObjects.push(resultObject);
                                    loadingData$.next(false);

                                    return current;
                                }

                                // deduplication failed
                                if (timerDoneReason && timerDoneReason === TimerDoneType.TIMEOUT) {
                                    resultObjects.push(EmsService.buildEmsResponseObject(object, objectId, EmsStatusCodes.STORING_CANCELED_BY_TIMEOUT));
                                } else {
                                    timerDoneReason = TimerDoneType.SKIPPED_BY_USER;
                                    resultObjects.push(EmsService.buildEmsResponseObject(object, objectId, EmsStatusCodes.DEDUPLICATION_FAILED));
                                }

                                loadingData$.next(false);
                                return current;
                            }
                        }),
                        takeUntil(timeIsUp$),
                        catchError((error) => {
                            console.error(error);
                            resultObjects.push(EmsService.buildEmsResponseObject(null, objectId, EmsStatusCodes.GENERAL_EMS_ERROR));
                        })
                    ).subscribe(
                        current => ProgressService.updateProgress(current, numberOfObjects),
                        error => console.error(error),
                        () => {
                            loadingData$.subscribe((isloading) => {
                                if (!isloading) {
                                    insertEmailsDone(loadingData$, timerDone$, timerDoneReason, resultObjects, metadata);
                                    subscription.unsubscribe();
                                }
                            })
                        }
                    )
                });
            }

            async function insertEmailsDone(loadingData, timerDone, timerDoneReason, resultObjects, metadata) {
                loadingData.unsubscribe();
                timerDone.unsubscribe();

                await FileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, "", metadata.groupKey);
                ClientService.broadcastTrayItemsChanged();

                const allObjects = metadata.objects;

                TimerService.unsubscribe();

                const numberOfSuccess = resultObjects.filter(x => x.properties.statusCode.value >= 0).length;
                if (numberOfSuccess === allObjects.length) {
                    ProgressService.finishSuccess(numberOfSuccess);
                } else {
                    const hash = object => {
                        return object.properties.storeId.value + object.properties.entryId.value;
                    };

                    const missing = [];
                    allObjects.forEach(x => {
                        if (!resultObjects.find(y => hash(y) == hash(x))) {
                            delete x.properties.filePath;
                            if (TimerDoneType.TIMEOUT == timerDoneReason) {
                                x.properties.statusCode = { value: EmsStatusCodes.STORING_CANCELED_BY_TIMEOUT };
                            } else {
                                x.properties.statusCode = { value: EmsStatusCodes.STORING_CANCELED_BY_USER };
                            }
                            missing.push(x);
                        }
                    });

                    resultObjects = resultObjects.concat(missing);
                    const numberOfSkipped = resultObjects.filter(x => x.properties.statusCode.value === -6).length;
                    TimerService.showDoneInfoDialog(numberOfSuccess, allObjects.length, false, timerDoneReason, numberOfSkipped);

                    hitlistUpdateRowsDone$.next(true);
                }

                resultObjects.forEach(x => {
                    if (x.properties["system:objectId"] && x.properties["system:objectId"].value === undefined) {
                        delete x.properties["system:objectId"];
                    }
                });

                insertEmails$.next(resultObjects);

                emsResult$.pipe(take(1)).next({ objects: resultObjects });
                emsResult$.complete();
                ProgressService.finished.next(true);
            }

            scope.$on("eob.tree.open.item", async (ev, data) => {
                let treeRow = virtualGridInstance.api.getRowByKey("osid", data.osid);
                if (treeRow == void 0) {
                    let error = ErrorModelService.createCustomError("READ_ACCESS_DENIED");
                    NotificationsService.customError(error);
                    throw error;
                } else if (treeRow.parent) {
                    virtualGridInstance.api.toggleRow(treeRow.parent, true);
                }
                virtualGridInstance.api.toggleRow(treeRow, true);
                virtualGridInstance.api.select(treeRow);
                virtualGridInstance.api.scrollToIndex(treeRow.index);

                await showContentAsync(treeRow);

                if (scope.isPhone) {
                    scope.stateDescription = scope.descriptionCache;
                }
            });

            scope.$on("move.item", (ev, data) => {
                let dmsDocument = data.dmsDocument;
                let rowToMove = virtualGridInstance.api.getRowByKey("osid", dmsDocument.model.osid);
                let rowToAppend = virtualGridInstance.api.getRowByKey("osid", data.targetLocationId);

                let targetParentId = data.targetLocationId;
                let sourceParentId = data.sourceLocationId;
                let selectedTreeItem = virtualGridInstance.api.getSelectedRows();

                if (rowToMove == void 0 || rowToAppend == void 0) {
                    let itemIndex = parentChildMap[sourceParentId] == void 0 ? -1 : parentChildMap[sourceParentId].indexOf(dmsDocument.model.osid);
                    if (itemIndex != -1) {
                        parentChildMap[sourceParentId].splice(itemIndex, 1);
                    }

                    if (parentChildMap[rowToAppend.osid] == void 0) {
                        parentChildMap[rowToAppend.osid] = [];
                    }

                    parentChildMap[rowToAppend.osid].push(dmsDocument.model.osid);

                    if (selectedTreeItem[0].osid == targetParentId) {
                        updateHitlistContent(rowToAppend.osid);
                    } else {
                        updateHitlistContent(currentTreeItem.osid);
                    }

                    if (dmsDocument.model.isRegister) {
                        (async () => {
                            const response = await LocationService.exploreFolderAsync(dmsDocument.model.osid, dmsDocument.model.objectTypeId, true);

                            let nodeData = LocationService.findRootNode(response.data, response.data.folderRegisterTree);

                            if (nodeData == void 0) {
                                return;
                            }

                            let rootRegisterId = CacheManagerService.dmsDocuments.add(response.data)[0],
                                rootRegister = CacheManagerService.dmsDocuments.getById(rootRegisterId);

                            nodeData.name = rootRegister.api.buildNameFromIndexData(10, false, false);
                            nodeData.icon = ObjectTypeService.getIconClass(response.data.objectTypeId, response.data.iconId, true);

                            virtualGridInstance.api.insertRows(rowToAppend.index, [nodeData], true);
                        })();
                    }

                    return;
                }

                let currentParent = rowToMove.parent.osid;
                let childIndex = parentChildMap[currentParent].indexOf(rowToMove.osid);

                if (childIndex > -1) {
                    parentChildMap[currentParent].splice(childIndex, 1);
                }

                if (parentChildMap[rowToAppend.osid] == void 0) {
                    parentChildMap[rowToAppend.osid] = [];
                }

                parentChildMap[rowToAppend.osid].push(rowToMove.osid);

                virtualGridInstance.api.moveRow(rowToMove.index, rowToAppend.index, true);

                if (selectedTreeItem[0].osid == targetParentId) {
                    updateHitlistContent(rowToAppend.osid);
                } else {
                    updateHitlistContent(currentTreeItem.osid);
                }
            });

            scope.$on("hitlist.config.changed", (ev, objectTypeId) => {
                let rootRow = virtualGridInstance.api.getRowByKey("index", "0");
                if (rootRow.objectTypeId == objectTypeId || rootRow.cabinetId == objectTypeId) {
                    $state.reload();
                }
            });

            MessageService.subscribe(HitlistEvent.SHOW_EMPTY_SPACE_CONTEXTMENU, (event) => {
                if (ClientService.isOffline()) {
                    return;
                }

                let selection = virtualGridInstance.api.getSelectedRows();
                let treeItem = selection[0];

                if (!treeItem) {
                    return;
                }
                MessageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                    contextData: {
                        context: "emptySpaceInHitlist",
                        title: treeItem.name
                    },
                    items: [{
                        osid: treeItem.osid,
                        objectTypeId: treeItem.objectTypeId,
                        mainType: treeItem.objectType == "FOLDER" ? 0 : 99
                    }],
                    event
                });
            }, takeUntil(unsubscriber));

            scope.$on("$destroy", () => {
                console.log("destroy location view")
                unsubscriber.next(true);
                resizeObserver.disconnect()

                $rootScope.$broadcast("close.inline.dialogs");

                if (virtualGridInstance) {
                    saveState();
                    virtualGridInstance.destroy();
                }

                ClientService.unregisterConnectivityChangeHandler(folderConnectivityHandler);
                ContextMenuService.unregisterContextMenuActionsProvider("folderTree");
                ContextMenuService.unregisterContextMenuActionsProvider("emptySpaceInHitlist");
                CacheManagerService.dmsDocuments.detachListeners(gContextIds);

                if (emsSub) {
                    emsSub.unsubscribe();
                }
                hitlistReadySub.unsubscribe();
            });

            // Update hitlist with newly created email documents
            zip(hitlistUpdateRowsDone$, insertEmails$).subscribe(async x => {
                const objectIds = x[1]
                .filter(x => x.properties.hasOwnProperty("system:objectId"))
                .map(x => x.properties["system:objectId"].value);
                const documents = await CacheManagerService.dmsDocuments.getOrFetchByIds(objectIds, true);

                if (parentChildMap[currentTreeItem.osid] == void 0) {
                    parentChildMap[currentTreeItem.osid] = [];
                }

                documents.forEach(x => {
                    if (x && x.model && parentChildMap[currentTreeItem.osid].indexOf(x.model.osid) === -1) {
                        parentChildMap[currentTreeItem.osid].push(x.model.osid);
                        scope.hitlistConfig.api.addItem(x)
                    }
                });
                scope.hitlistConfig.api.selectItems(objectIds.map(id => {
                    if (id) {
                        return id.toString();
                    }
                }), "id");
            });
        }
    };
}
