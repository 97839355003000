(function() {
    "use strict";
    angular.module("eob.core").factory("dvDateService", DvDateService);

    DvDateService.$inject = ["environmentService"];

    function DvDateService(EnvironmentService) {
        let service = {
            getDate,
            convertTimestamp
        };
        return service;

        function getDate(inputDate) {
            let date;
            if (EnvironmentService.getLanguage() == "de") {
                date = inputDate.replace(/\//gi, ".");
            } else {
                date = inputDate.replace(/\./gi, "/");
            }
            return date;
        }

        function convertTimestamp(timestamp) {
            let date = new Date(timestamp);
            let day = get2DigitDatepart(date.getDate());
            let month = get2DigitDatepart(date.getMonth() + 1);
            let year = date.getFullYear();
            date = `${day}/${month}/${year}`;
            date = getDate(date);
            return date;
        }

        function get2DigitDatepart(str) {
            return str > 9 ? str : `0${str}`;
        }
    }
})();