import {ChangeDetectorRef, Component, Inject, Input, OnInit} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {TodoCacheManagerService, TodoSearchService} from "INTERFACES_PATH/any.types";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {ContextData, HitlistConfig, Location} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {RowNode} from "ag-grid-community/dist/lib/entities/rowNode";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";

@Component({
    selector: "eob-modal-hitlist",
    templateUrl: "./eob-modal-hitlist.component.html"
})
export class EobModalHitlistComponent implements OnInit {
    @Input() items: Location[];
    ready: boolean = false;
    hitlistConfig: HitlistConfig = {};
    isLoading: boolean = true;
    contextData: ContextData;


    constructor(@Inject("searchService") private searchService: TodoSearchService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                private notificationsService: NotificationsService,
                private gridContentService: GridContentService,
                private messageService: MessageService,
                private cdRef: ChangeDetectorRef) {
    }

    async ngOnInit(): Promise<void> {
        const {result, warning, error} = await this.searchService.searchByIdsAsync(this.items);

        if (warning) {
            this.notificationsService.warning(warning);
        }

        if (error) {
            this.notificationsService.error(error);
        }

        this.fillGrid(result);

        this.isLoading = false;
    }

    fillGrid(searchResult: DmsDocument[]): void {
        const documentIds: number[] = this.cacheManagerService.dmsDocuments.add(searchResult);
        const dmsDocuments: DmsDocument[] = this.cacheManagerService.dmsDocuments.get(documentIds);
        const hitlistConfig: HitlistConfig = this.gridContentService.getListEntries(dmsDocuments, "quickfinder");
        hitlistConfig.suppressStateUpdate = true;
        hitlistConfig.suppressGrouping = true;
        hitlistConfig.suppressMultiselect = true;
        hitlistConfig.suppressViewerUpdate = true;
        hitlistConfig.isModal = true;

        //hide smartphone-kebab-column for contextmenu
        hitlistConfig.hideTouchKebab = true;
        hitlistConfig.onDoubleClick = this.applyEntry;

        this.hitlistConfig = hitlistConfig;
        this.contextData = {context: "modalHitlist"};
        this.ready = true;
        this.cdRef.detectChanges();
    }

    applyEntry = (): void => {
        const row: RowNode = this.hitlistConfig.api.getSelectedRows()[0];
        this.close(row);
    };

    close(row?: RowNode): void {
        if (row) {
            this.messageService.broadcast(ModalEvents.DESTROY, row);
        } else {
            this.messageService.broadcast(ModalEvents.DESTROY);
        }
    }
}
