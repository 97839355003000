import {Component, ElementRef, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {Inject, Input, OnDestroy} from "@angular/core";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-state-header",
    templateUrl: "./eob-state-header.component.html",
    styleUrls: ["./eob-state-header.component.scss"]
})

export class EobStateHeaderComponent implements OnInit, OnChanges, OnDestroy {
    showAltLang: boolean;
    objectDefLang: any;

    constructor(@Inject("environmentService") private environmentService: TodoEnvironmentService,
                protected clientService: ClientService,
                private elementRef: ElementRef, private renderer: Renderer2) {
    }

    @Input() stateTitle: string;
    @Input() description: string;
    @Input() isMockContext?: boolean;
    @ViewChild("headerdescription", {static: true}) headerDescription: ElementRef;

    fullDescriptionVisible: boolean;

    mouseDownListener: () => void;
    mouseUpListener: () => void;
    mouseOutListener: () => void;

    ngOnInit(): void {
        this.fullDescriptionVisible = false;

        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang() && !this.isMockContext;
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();

        if (this.clientService.isTouchDevice()) {
            this.mouseDownListener = this.renderer.listen(this.headerDescription.nativeElement, "touchstart", this.onMouseDown);
            this.mouseUpListener = this.renderer.listen(this.headerDescription.nativeElement, "touchend", this.onMouseUp);
        } else {
            this.mouseDownListener = this.renderer.listen(this.headerDescription.nativeElement, "mousedown", this.onMouseDown);
            this.mouseOutListener = this.renderer.listen(this.headerDescription.nativeElement, "mouseout", this.onMouseUp);
            this.mouseUpListener = this.renderer.listen(this.headerDescription.nativeElement, "mouseup", this.onMouseUp);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.elementRef.nativeElement.classList.contains("modal-header")) {
            const tabTitle: string = this.getTitle() !== "" ? this.getTitle() : "enaio webclient";

            this.clientService.refreshTabTitle(tabTitle);
        }
    }

    onMouseDown = (): void => {
        this.fullDescriptionVisible = true;
    };

    onMouseUp = (): void => {
        this.fullDescriptionVisible = false;
    };

    ngOnDestroy(): void {
        this.mouseDownListener?.();
        this.mouseUpListener?.();

        if (!this.clientService.isTouchDevice()) {
            this.mouseOutListener?.();
        }
    }

    private getTitle(): string {
        if (this.description?.length) {
            return `${this.stateTitle} - ${this.description}`;
        } else {
            return this.stateTitle;
        }
    }
}
