import {ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewEncapsulation} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {AuthenticationState, AuthenticationType} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {Subscription} from "rxjs";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {Profile, ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {filter} from "rxjs/operators";
import {Todo$eobConfig, TodoEnvironmentService, TodoModalDialogService} from "INTERFACES_PATH/any.types";
import {ElectronWindow} from "INTERFACES_PATH/electron/electron-window.interface";
import {ThemeService} from "MODULES_PATH/theme/theme.service";
import {ProfileManagerService} from "MODULES_PATH/profile-manager/profile-manager.service";
import { CustomStorageService } from "CORE_PATH/services/custom-storage/custom-storage.service";

export interface LoginTypeDropdownItem {
    internal: AuthenticationType;
    name: string;
}

declare let window: ElectronWindow | Window;


@Component({
    selector: "eob-profile-manager",
    templateUrl: "./profile-manager.component.html",
    styleUrls: ["./profile-manager.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ProfileManagerComponent implements OnInit {
    @Input() initErrorMessage: string | Error;

    showProfileManager = false;
    authenticationSub: Subscription;
    translateFn: TranslateFnType;
    profileKeys: string[];
    profiles: Map<string, Profile>;

    constructor(private clientService: ClientService, @Inject("$filter") $filter: ng.IFilterService,
                private profileService: ProfileService,
                private notificationsService: NotificationsService,
                @Inject("$eobConfig") private $eobConfig: Todo$eobConfig,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("fileCacheService") private fileCacheService: FileCacheService,
                private messageService: MessageService,
                private renderer: Renderer2, private elementRef: ElementRef, private cdRef: ChangeDetectorRef,
                public authenticationService: AuthenticationService,
                public themeService: ThemeService,
                public profileManagerService: ProfileManagerService,
                public customStorageService: CustomStorageService) {
        this.translateFn = $filter("translate");
    }

    ngOnInit(): void {
        this.customStorageService.getStorage().subscribe(storage => {
            this.renderer.addClass(document.body, "pre-styles");
            this.themeService.setTheme("standard");

            this.showProfileManager = true;

            if (this.initErrorMessage) {
                console.warn(this.initErrorMessage);
                if (this.initErrorMessage instanceof Error) {
                    this.notificationsService.error(this.initErrorMessage.message);
                } else {
                    this.notificationsService.customError(this.initErrorMessage);
                }
            }
        });
    }

    loginToDemo(): void {
        this.profileManagerService.profile = {
            username: "mobi",
            password: "mobi",
            url: "https://demo.ecm.mobi",
            autologin: true,
            authType: AuthenticationType.BASIC_AUTH,
            isDemo: true
        };

        this.login();
    }

    passwordChanged(newPassword: string): void {
        this.profileManagerService.togglePasswordExpiredForm();
        this.profileManagerService.profile.password = newPassword;
        this.login();
    }

    login(): void {
        if (this.authenticationSub && !this.authenticationSub.closed) {
            this.authenticationSub?.unsubscribe();
            this.profileManagerService.checkingLoginData = false;
            this.cdRef.detectChanges();
        } else {
            let keepPassword = false;
            this.authenticationSub = this.profileManagerService.authenticate()
                .pipe(filter(x => x.state != AuthenticationState.SESSION_REFRESHED))
                // eslint-disable-next-line rxjs/no-async-subscribe,@typescript-eslint/no-misused-promises
                .subscribe(async statusEvent => {
                        switch (statusEvent.state) {
                            case AuthenticationState.LOGGED_IN:
                                /**
                                 * discard all notifications if the user has successfully logged in
                                 */
                                this.notificationsService.dismissAll();

                                sessionStorage.setItem("forceAutoLogin", "true");

                                if (this.clientService.isDesktop()) {
                                    window.electron.storeSessionStorage();
                                }

                                if (statusEvent.username) {
                                    this.profileManagerService.profile.username = statusEvent.username;
                                }
                                if (statusEvent.origin) {
                                    this.profileManagerService.profile.url = statusEvent.origin;
                                }
                                if (typeof statusEvent.userId == "number") {
                                    this.profileManagerService.profile.userId = statusEvent.userId;
                                }

                                // save current profile data
                                this.profileService.saveProfile(this.profileManagerService.profile);

                                // check for electron updates
                                let updateCheck = 0;
                                if (this.clientService.isDesktop() && this.clientService.isOnline()) {
                                    try {
                                        updateCheck = await window.electron.checkForUpdates(this.profileManagerService.profile);
                                    } catch (_) {
                                        // error ignored
                                    }
                                }

                                // if updateCheck is 0 (ie. no updates are available) or 1 (ie. only an update notification
                                // is to be shown), continue to init main view
                                if (updateCheck == 0 || updateCheck == 1) {
                                    if (this.clientService.isDesktop()) {
                                        window.electron.storeSessionStorage();
                                        window.electron.restoreWindowSizeAndPosition(this.profileService.prepareCurrentProfile());
                                    }
                                    this.messageService.broadcast(Broadcasts.INIT_MAIN_VIEW);
                                }

                                // TODO check, how to do this
                                // if updateCheck is 2, electron update with app restart is in progress, so stay in profile manager view
                                break;

                            case AuthenticationState.LOGIN_CREDENTIALS_INCOMPLETE:
                                this.profileManagerService.handleWarning("eob.login.credentials.incomplete", statusEvent.error);
                                this.profileManagerService.profile.password = "";
                                break;

                            case AuthenticationState.LOGIN_URL_INVALID:
                                this.profileManagerService.handleWarning("eob.login.illegal.webclient.path", statusEvent.error);
                                break;

                            case AuthenticationState.LOGIN_URL_NO_PROTOCOL:
                                this.profileManagerService.handleWarning("eob.login.url.no.protocol", statusEvent.error);
                                break;

                            case AuthenticationState.LOGIN_ABORTED:
                                break;

                            case AuthenticationState.PASSWORD_EXPIRED:
                                keepPassword = true;
                                this.profileManagerService.togglePasswordExpiredForm();
                                break;

                            default:
                                if (this.profileManagerService.profile.isDemo) {
                                    this.profileManagerService.handleError("eob.login.demo.failed", statusEvent.error);
                                } else if (this.profileManagerService.profile.authType === AuthenticationType.NTLM_SYSTEM) {
                                    this.profileManagerService.handleError("eob.login.windows.login.failed", statusEvent.error);
                                } else {
                                    this.profileManagerService.handleError("eob.login.login.failed", statusEvent.error);
                                }
                                this.profileManagerService.profile.password = "";
                                break;
                        }
                    }, _ => {
                        console.warn(_);
                    }, () => {
                        if (!keepPassword) {
                            this.profileManagerService.profile.password = "";
                        }
                        this.profileManagerService.checkingLoginData = false;
                        this.cdRef.detectChanges();
                    }
                );
        }
    }

    abortLogin(): void {
        if (this.authenticationSub && !this.authenticationSub.closed) {
            this.authenticationSub?.unsubscribe();
            this.profileManagerService.checkingLoginData = false;
            this.cdRef.detectChanges();
        }
    }
}
