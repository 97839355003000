require("SERVICES_PATH/form/eob.form.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");

const dayjs = require("dayjs");

angular.module("eob.core").factory("routingListService", RoutingListService);

RoutingListService.$inject = ["$q", "toolService", "formService", "backendService", "valueUtilsService"];

export default function RoutingListService($q, ToolService, FormService, BackendService, valueUtilsService) {

    return {
        parseRoutingList,
        parseRoutingListTemplate,
        addRoutingListApi,
        getRoutingListApi,
        getRoutingListGroupApi,
        getRoutingListItemApi,
        getTemplateApi
    };

    // region parse routing list
    function parseRoutingList(routingListData, isTemplate) {
        let routingList = {
            model: {
                id: routingListData.Id,
                activityId: routingListData.ActivityId,
                processId: routingListData.ProcessId,
                expandable: routingListData.Expandable == "1",
                isTemplate: isTemplate == true,
                remark: routingListData.Remark
            }
        };

        routingList.model.groups = parseRoutingListGroups(routingList, routingListData.Entries);
        return routingList;
    }

    function parseRoutingListGroups(routingList, groupsData) {
        let groups = [];

        groupsData = groupsData.Entry;
        if (groupsData == void 0) {
            return groups;
        }

        if (!Array.isArray(groupsData)) {
            groupsData = [groupsData];
        }

        groupsData.sort((a, b) => {
            let aProp = Number(a.Nr), bProp = Number(b.Nr);
            return aProp == bProp ? 0 : aProp > bProp ? 1 : -1;
        });

        for (let i in groupsData) {
            let groupData = groupsData[i];

            let isExpandable = groupData.Expandable == "1";
            let group = createRoutingListGroup(routingList, isExpandable);
            group.model.items = parseRoutingListItems(group, groupData.Items);

            groups.push(group);
        }

        return groups;
    }

    function createRoutingListGroup(routingList, expandable) {
        return {
            model: {
                id: ToolService.createGUID(),
                expandable: expandable != false,
                items: {}
            },
            routingList
        };
    }

    function parseRoutingListItems(group, itemsObjData) {
        let items = {};
        let itemsData = itemsObjData.Item;

        if (itemsData == void 0) {
            return items;
        }

        if (!Array.isArray(itemsData)) {
            itemsData = [itemsData];
        }

        for (let i in itemsData) {
            let itemData = itemsData[i];

            let item = {
                model: {
                    id: { value: itemData.Id },
                    deletable: { value: itemData.Deleteable },
                    editable: { value: itemData.Changeable },
                    participants: { value: (typeof itemData.ObjectIds == "string") ? itemData.ObjectIds.split(",").join(";") : "" },
                    remark: { value: itemData.Remark },
                    taskName: { value: itemData.ActivityName },
                    activityName: { value: itemData.ModelActivityName },
                    periodName: { value: itemData.TimerId },
                    timerType: { value: parseInt(itemData.TimerDurationType) },
                    dueOn: { value: itemData.TimerDuration },
                    isNew: { value: false }
                },
                group
            };

            items[item.model.id.value] = item;
        }

        return items;
    }

    function createRoutingListItem(group, activity, period) {
        let item = {
            model: {
                id: { value: ToolService.createGUID() },
                deletable: { value: "0" },
                editable: { value: "0" },
                participants: { value: [] },
                remark: { value: "" },
                taskName: { value: "" },
                activityName: { value: "" },
                periodName: { value: "" },
                timerType: { value: 0 },
                dueOn: { value: "" },
                isNew: { value: true }
            },
            group
        };

        if (activity != void 0) {
            setActivityData(item, activity);
        }

        if (period != void 0) {
            setPeriodData(item, period);
        }

        return item;
    }

    function setActivityData(item, activity) {
        let data = item.model;

        if (activity.name != void 0 && typeof(activity.name) == "string") {
            data.activityName.value = activity.name;
            data.taskName.value = activity.name;
        }

        if (activity.participants != void 0) {
            data.participants.value = getParticipantNames(activity.participants);
        }

        if (activity.editable != void 0) {
            data.editable.value = valueUtilsService.parseBoolean(activity.editable) ? 1 : 0;
        }

        if (activity.deletable != void 0) {
            data.deletable.value = valueUtilsService.parseBoolean(activity.deletable) ? 1 : 0;
        }
    }

    function setPeriodData(item, period) {
        let data = item.model;

        if (period.name != void 0) {
            data.periodName.value = period.name;
        }

        if (period.duration != void 0) {
            data.dueOn.value = valueUtilsService.formatDate(dayjs().add(period.duration * 1000).valueOf(), true);
            data.timerType.value = 2;
        }
    }

    function getParticipantNames(participants) {
        if (participants == void 0) {
            return "";
        }

        let participantNames = [];

        if (!Array.isArray(participants)) {
            participants = [participants];
        }

        for (let i = 0; i < participants.length; i++) {
            participantNames.push(participants[i].name);
        }
        return participantNames.join(";");
    }

    function parseRoutingListTemplate(routingListTemplateData, formHelper) {
        // Normalize Data for parsing
        let entryArray = [];

        for (let i in routingListTemplateData.routingList.entries) {
            let entryRaw = routingListTemplateData.routingList.entries[i];
            let itemArray = [];

            for (let j in entryRaw.items) {
                let itemRaw = entryRaw.items[j];
                let item = {};

                item.Deleteable = itemRaw.deleteable ? "1" : "0";
                item.ObjectIds = itemRaw.objectIds;
                item.ModelActivityName = itemRaw.modelActivityName;
                item.ActivityName = itemRaw.activityName;
                item.TimerDuration = itemRaw.timerDuration;
                item.TimerDurationType = itemRaw.timerDurationType;
                item.ActivityId = itemRaw.activityId;
                item.TimerId = itemRaw.timerName;
                item.Id = itemRaw.id;
                item.Remark = itemRaw.remark;
                item.Changeable = itemRaw.changeable ? "1" : "0";
                item.ObjectIds = itemRaw.objectIds;

                itemArray.push(item);
            }

            entryArray.push({
                Nr: entryRaw.nr,
                Expandable: entryRaw.expandable,
                Items: {
                    Item: itemArray
                }
            });
        }

        let routingListData = {
            Id: routingListTemplateData.routingList.id,
            ActivityId: routingListTemplateData.routingList.activityId,
            ProcessId: routingListTemplateData.routingList.processId,
            Expandable: routingListTemplateData.routingList.expandable,
            Entries: {
                Entry: entryArray
            }
        };

        let template = {
            model: {
                id: routingListTemplateData.templateId,
                name: routingListTemplateData.templateName,
                isPublic: routingListTemplateData.templatePublic,
                routingList: addRoutingListApi(parseRoutingList(routingListData, true), formHelper)
            }
        };
        template.api = getTemplateApi(template, formHelper);
        return template;
    }

    // endregion

    // region APIs
    // region routing list API
    function addRoutingListApi(routingList, formHelper) {
        routingList.api = getRoutingListApi(routingList, formHelper);

        let groups = routingList.model.groups;

        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            group.api = getRoutingListGroupApi(group, formHelper);

            let items = group.model.items;

            for (let itemId in items) {
                let item = items[itemId];
                item.api = getRoutingListItemApi(item, formHelper);
            }
        }

        return routingList;
    }

    function getRoutingListApi(routingList, formHelper) {
        return new RoutingListApi(routingList, formHelper);
    }

    function RoutingListApi(routingList, formHelper) {
        let self = this;

        /**
         * Sets the routing list expandable or not
         * @param isExpandable - The new value
         */
        self.setExpandable = function(isExpandable) {
            routingList.model.expandable = valueUtilsService.parseBoolean(isExpandable);

            let grid = formHelper.getRoutingListGrid();
            if (grid != void 0) {
                grid.api.setGridExpandable(isExpandable);
            }
        };

        /**
         * Checks if the routing list is valid
         */
        self.isValid = function() {
            let groupsAreValid = true;
            for (let i in routingList.model.groups) {
                let group = routingList.model.groups[i];
                if (!group.api.isValid()) {
                    groupsAreValid = false;
                }
            }

            return groupsAreValid;
        };

        self.getFields = function() {
            let fields = [];
            let grid = formHelper.getRoutingListGrid();

            if (grid == void 0) {
                return fields;
            }

            let groups = grid.model.groups;
            for (let i in groups) {
                let group = groups[i];

                for (let j in group.rows) {
                    let row = group.rows[j];

                    for (let k in row.cells) {
                        let field = row.cells[k];
                        if (field.model != void 0) {
                            fields.push(field);
                        }
                    }
                }
            }

            return fields;
        };

        /**
         * Gets a routing list group by id
         * @param id - The routing list group id
         * @returns {Object} or null
         */
        self.getGroupById = function(id) {
            let groups = routingList.model.groups;
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];
                if (group.model.id == id) {
                    return group;
                }
            }

            console.warn("Cannot find group with id -->", id);
            return null;
        };

        /**
         * Creates a routing list group and adds it to the routing list
         * @param expandable - group should be expandable or not
         * @param addToGrid - set explicitly to false, to avoid adding the group to the client grid
         * @returns {Object}
         */
        self.createGroup = function(expandable, addToGrid) {
            return self.createGroupAt(routingList.model.groups.length, expandable, addToGrid);
        };

        /**
         * Creates a routing list group and adds it to the routing list
         * @param expandable - group should be expandable or not
         * @param index - index for ordering
         * @param addToGrid - set explicitly to false, to avoid adding the group to the client grid
         * @returns {Object}
         */
        self.createGroupAt = function(index, expandable, addToGrid) {
            let group = createRoutingListGroup(routingList, expandable);
            group.api = getRoutingListGroupApi(group, formHelper);
            self.addGroupAt(group, index, false, addToGrid);
            return group;
        };

        /**
         * Adds a routing list group to the routing list
         * @param group - a routing list group
         * @param fromTemplate - is the group descended from a circulation slip template
         * @param addToGrid - set explicitly to false, to avoid adding the group to the client grid
         */
        self.addGroup = function(group, fromTemplate, addToGrid) {
            self.addGroupAt(group, routingList.model.groups.length, fromTemplate, addToGrid);
        };

        /**
         * Adds a routing list group to the routing list
         * @param group - a routing list group
         * @param index - index for ordering
         * @param fromTemplate - is the group descended from a circulation slip template
         * @param addToGrid - set explicitly to false, to avoid adding the group to the client grid
         */
        self.addGroupAt = function(group, index, fromTemplate, addToGrid) {
            if (group == void 0) {
                console.warn("Cannot add group. Group is not defined!");
                return;
            }

            if (index == void 0) {
                index = routingList.model.groups.length;
            }

            for (let j in group.model.items) {
                let item = group.model.items[j];

                if (group.routingList == void 0 || group.routingList.model.id != routingList.model.id) {
                    item.model.isNew.value = true;
                }

                if (fromTemplate) {
                    // Umrechnen der relativen Mahnfristen aus dem Template in Feste.
                    if (item.model.timerType.value == 1) {
                        item.model.timerType.value = 2;
                        let dueOnNumber = Number(item.model.dueOn.value);
                        dueOnNumber += dayjs();
                        item.model.dueOn.value = dueOnNumber.toString();
                    }
                }
            }

            routingList.model.groups.splice(index, 0, group);
            group.routingList = routingList;

            if (addToGrid != false) {
                let grid = formHelper.getRoutingListGrid();

                if (grid != void 0) {
                    grid.api.addGroup(group, index, fromTemplate, addToGrid);
                }
            }
        };

        /**
         * Adds the groups of a routing list to the routing list
         * @param list - a routing list
         * @param fromTemplate - is the list descended from a circulation slip template
         */
        self.addList = function(list, fromTemplate, addToGrid) {
            self.addListAt(list, routingList.model.groups.length, fromTemplate, addToGrid);
        };

        /**
         * Adds the groups of a routing list to the routing list
         * @param list - a routing list
         * @param index - index for ordering
         * @param fromTemplate - is the list descended from a circulation slip template
         * @param addToGrid - add the list to the GUI
         */
        self.addListAt = function(list, index, fromTemplate, addToGrid) {
            let routingListClone = list.api.copy();

            if (index == void 0) {
                index = routingList.model.groups.length;
            }

            if (addToGrid == void 0) {
                addToGrid = true;
            }

            for (let i = routingListClone.model.groups.length - 1; i >= 0; i--) {
                routingList.api.addGroupAt(routingListClone.model.groups[i], index, fromTemplate, addToGrid);
            }
        };

        /**
         * Gets a routing list group by id and removes it from the routing list
         * @param id - id of a routing list group
         * @param removeFromGrid - set explicitly to false, to avoid removing the group from the client grid
         */
        self.removeGroupById = function(id, removeFromGrid) {
            let group = self.getGroupById(id);
            self.removeGroup(group, removeFromGrid);
        };

        /**
         * Removes a routing list group from the routing list
         * @param group - a routing list group
         * @param removeFromGrid - set explicitly to false, to avoid removing the group from the client grid
         */
        self.removeGroup = function(group, removeFromGrid) {
            let groupIndex = routingList.model.groups.indexOf(group);
            if (group == void 0 || groupIndex < 0) {
                console.warn("Cannot remove group. Routing list does not contain group -->", group);
                return;
            }

            routingList.model.groups.splice(groupIndex, 1);
            group.routingList = null;

            if (removeFromGrid != false) {
                let grid = formHelper.getRoutingListGrid();

                if (grid != null) {
                    grid.api.removeGroupById(group.model.id);
                }
            }
        };

        /**
         * Copies the current routing list.
         */
        self.copy = function() {
            let clone = angular.copy(routingList);

            for (let i in clone.model.groups) {
                clone.model.groups[i] = clone.model.groups[i].api.copy();
            }

            clone.model.id = ToolService.createGUID();
            clone.api = getRoutingListApi(clone, formHelper);

            return clone;
        };

        /**
         * Save the routing list as template.
         * @returns {Object}
         */
        self.saveAsTemplate = function(name, isPublic, templateToOverwrite) {
            let transformedRoutingList = FormService.transformRoutingList(routingList, true);

            let deferred = $q.defer();
            let template = {
                templateId: (templateToOverwrite == null) ? "" : templateToOverwrite.model.id,
                templateName: name,
                templatePublic: isPublic,
                routingList: transformedRoutingList
            };

            BackendService.post("/workflows/adhoc/templates/save", template).then((res) => {
                template = parseRoutingListTemplate(res.data, formHelper);

                formHelper.getRoutingListTemplates().then((templates) => {
                    let found = false;

                    for (let i in templates) {
                        if (templates[i].model.name == name) {
                            templates[i] = template;
                            found = true;
                            break;
                        }
                    }

                    if (!found) {
                        templates.push(template);
                    }

                    deferred.resolve(template);
                }, (error) => {
                    deferred.reject(error);
                });
            }, (error) => {
                deferred.reject(error);
            });

            return deferred.promise;
        };
    }

    // endregion

    // region routing list group API
    function getRoutingListGroupApi(group, formHelper) {
        return new RoutingListGroupApi(group, formHelper);
    }

    function RoutingListGroupApi(group, formHelper) {
        let self = this;

        function getGridRow(rowId) {
            let grid = formHelper.getRoutingListGrid();

            if (grid != void 0) {
                let gridGroup = grid.api.getGroup(group.model.id);

                if (gridGroup != void 0) {
                    return gridGroup.rows[rowId];
                }
            }
        }

        /**
         * Sets the routing list group expandable or not
         * @param isExpandable - The new value
         */
        self.setExpandable = function(isExpandable) {
            group.model.expandable = valueUtilsService.parseBoolean(isExpandable);

            let grid = formHelper.getRoutingListGrid();
            if (grid != void 0) {
                let gridGroup = grid.api.getGroup(group.model.id);

                if (gridGroup != void 0) {
                    grid.api.setGroupExpandable(gridGroup, isExpandable);
                }
            }
        };

        /**
         * Checks if the routing list group is valid
         */
        self.isValid = function() {
            let errors = [];

            if (Object.keys(group.model.items).length == 0) {
                errors.push("The routing list group contains no items.");
            }

            for (var i = 0; i < errors.length; i++) {
                console.warn(`${errors[i]} -->`, group);
            }

            let itemsAreValid = true;

            for (var i in group.model.items) {
                let item = group.model.items[i];
                if (!item.api.isValid()) {
                    itemsAreValid = false;
                }
            }

            return errors.length == 0 && itemsAreValid;
        };

        /**
         * Removes the routing list group from its routingList
         * @param removeFromGrid - set explicitly to false, to avoid removing the group from the client grid
         */
        self.remove = function(removeFromGrid) {
            if (group.routingList != void 0) {
                group.routingList.api.removeGroup(group, removeFromGrid);
            }
        };

        /**
         * Removes the group from its current routing list and adds it to the given routing list.
         * @param list - the new routing list
         * @param index - optional position
         */
        self.moveTo = function(list, index, fromTemplate, addToGrid) {
            if (list == void 0) {
                console.warn("Cannot move entry. New parent routing list is undefined.");
                return;
            }

            self.remove(addToGrid);
            list.api.addGroupAt(group, index, fromTemplate, addToGrid);
        };

        /**
         * Creates a new routing list group from this group.
         * @returns {Object}
         */
        self.copy = function() {
            let clone = angular.copy(group);

            let newItems = {};
            for (let i in clone.model.items) {
                let itemClone = clone.model.items[i].api.copy();
                newItems[itemClone.model.id.value] = itemClone;
            }
            clone.model.items = newItems;

            clone.model.id = ToolService.createGUID();
            clone.api = getRoutingListGroupApi(clone, formHelper);

            return clone;
        };

        /**
         * Checks if the routing list group contains a routing list item with the given id
         * @param id - The routing list item id
         * @returns {boolean}
         */
        self.containsItemById = function(id) {
            return self.getItemById(id) != void 0;
        };

        /**
         * Gets the routing list item with the given id
         * @param id - The routing list item id
         * @returns {Object}
         */
        self.getItemById = function(id) {
            if (id == void 0 || id === "") {
                console.warn("Cannot find item by id. Id is not defined!");
            }

            return group.model.items[id];
        };

        /**
         * Creates a routing list item and adds it to the routing list group
         * @param activityData - activity object
         * @param periodData - period object
         * @param addToGrid - set explicitly to false, to avoid adding the item to the client grid
         * @returns {Object}
         */
        self.createItem = function(activityData, periodData, addToGrid) {
            let item = createRoutingListItem(group, activityData, periodData);
            item.api = getRoutingListItemApi(item, formHelper);
            self.addItem(item, addToGrid);
            return item;
        };

        /**
         * Adds a routing list item to the routing list group
         * @param item - a routing list item
         * @param addToGrid - set explicitly to false, to avoid adding the item to the client grid
         */
        self.addItem = function(item, addToGrid) {
            if (item == void 0) {
                console.warn("Cannot add item to entry. Item is undefined!");
                return;
            }

            group.model.items[item.model.id.value] = item;
            item.model.isNew.value = true;
            item.group = group;

            if (addToGrid != false) {
                let grid = formHelper.getRoutingListGrid();
                if (grid != void 0) {
                    grid.api.addRowById(group.model.id, item);
                }
            }
        };

        /**
         * Gets a routing list item by id and removes it from the group
         * @param id - id of a routing list item
         * @param removeFromGrid - set explicitly to false, to avoid removing the item from the client grid
         */
        self.removeItemById = function(id, removeFromGrid) {
            let item = self.getItemById(id);

            if (item == void 0) {
                console.warn("Group does not contain item -->", id);
                return;
            }

            self.removeItem(item, removeFromGrid);
        };

        /**
         * Removes a routing list item from the group
         * @param item - a routing list item
         * @param removeFromGrid - set explicitly to false, to avoid removing the item from the client grid
         */
        self.removeItem = function(item, removeFromGrid) {
            if (item == void 0 || self.containsItemById(item.model.id.value) == void 0) {
                console.warn("Group does not contain item -->", item);
                return;
            }

            delete group.model.items[item.model.id.value];
            item.group = null;

            if (Object.keys(group.model.items).length == 0) {
                group.api.remove();
            }

            if (removeFromGrid != false) {
                let grid = formHelper.getRoutingListGrid();

                if (grid != null) {
                    let row = getGridRow(item.model.id.value);
                    if (row != void 0) {
                        grid.api.removeRow(row);
                    }
                }
            }
        };
    }

    // endregion

    // region routing list item API
    function getRoutingListItemApi(item, formHelper) {
        return new RoutingListItemApi(item, formHelper);
    }

    function RoutingListItemApi(item) {
        let self = this;

        /**
         * Set item properties according to the given activity.
         * @param activity - the activity data
         */
        self.setActivityData = function(activity) {
            if (activity == void 0) {
                console.warn("Cannot set activity data. Activity data is undefined!");
                return;
            }

            setActivityData(item, activity);
        };

        /**
         * Set item properties according to the given period.
         * @param period - the period data
         */
        self.setPeriodData = function(period) {
            if (period == void 0) {
                console.warn("Cannot set period data. Period data is undefined!");
                return;
            }

            setPeriodData(item, period);
        };

        /**
         * Checks if the routing list item is valid
         */
        self.isValid = function() {
            let errors = [];
            let model = item.model;
            let adHocData = item.group.routingList.model.adHocData;

            // activity name is empty
            if (model.activityName.value == "" || model.activityName.value == void 0) {
                errors.push("The routing list item activity name is empty.");
            } // activity is unknown
            else if (adHocData.activities[model.activityName.value] == void 0) {
                errors.push(`The routing list item activity '${model.activityName.value}' is not available for this routing list.`);
            } // no participants for activity type != route
            else if (adHocData.activities[model.activityName.value].type != "ROUTE"
                && (model.participants.value == "" || model.participants.value == void 0)) {
                errors.push("The routing list item activity is not available for this routing list.");
            }

            if (model.periodName.value != "" && model.periodName.value != void 0) {
                // period unknown
                if (adHocData.periods[model.periodName.value] == void 0) {
                    errors.push(`The routing list item period '${model.periodName.value}' is not available for this routing list.`);
                }

                // period date is note set
                if (model.dueOn.value == "" || model.dueOn.value == void 0) {
                    errors.push("The routing list item period 'dueOn' is not set, though a period is set.");
                }
            }

            for (let i = 0; i < errors.length; i++) {
                console.warn(`${errors[i]} -->`, item);
            }

            return errors.length == 0;
        };

        /**
         * Removes the routing list item from its group
         * @param removeFromGrid - set explicitly to false, to avoid removing the item from the client grid
         */
        self.remove = function(removeFromGrid) {
            if (item.group != void 0) {
                item.group.api.removeItem(item, removeFromGrid);
            }
        };

        /**
         * Removes the item from its current group and adds it to the given group.
         * @param group - the new group
         */
        self.moveTo = function(group) {
            if (group == void 0) {
                console.warn("Cannot move item to group. Group is not defined!");
                return;
            }

            self.remove();
            group.api.addItem(item);
        };

        /**
         * Creates a new routing list item from this item.
         * @returns {Object}
         */
        self.copy = function() {
            let clone = angular.copy(item);
            clone.model.id.value = ToolService.createGUID();
            clone.model.isNew.value = true;

            for (let propName in clone.model) {
                let prop = clone.model[propName];
                clone.model[propName] = {
                    value: prop.value
                };
            }

            clone.api = getRoutingListItemApi(clone);

            return clone;
        };
    }

    // endregion

    // region template API
    function getTemplateApi(template, formHelper) {
        return new TemplateApi(template, formHelper);
    }

    function TemplateApi(template, formHelper) {
        let self = this;

        /**
         * Delete the routing list template
         * @returns {Object}
         */
        self.delete = function() {
            let deferred = $q.defer();

            BackendService.get(`/workflows/adhoc/templates/delete/${template.model.id}`).then(() => {
                formHelper.getRoutingListTemplates().then((templates) => {
                    for (let i in templates) {
                        if (templates[i] == template) {
                            templates.splice(i, 1);
                            break;
                        }
                    }

                    deferred.resolve();
                }, (error) => {
                    deferred.reject(error);
                });
            }, (error) => {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        /**
         * Publish the routing list template
         * @returns {Object}
         */
        self.publish = function() {
            return routingListTemplateMove(true);
        };

        /**
         * Personalize the routing list template
         * @returns {Object}
         */
        self.personalize = function() {
            return routingListTemplateMove(false);
        };

        /**
         * Save changes on the template on the server
         * @returns {Object}
         */
        self.update = function() {
            return template.model.routingList.api.saveAsTemplate(template.model.name, template.model.isPublic, template);
        };

        function routingListTemplateMove(publish) {
            let deferred = $q.defer();

            BackendService.get(`/workflows/adhoc/templates/move/${template.model.id}?publish=${publish}`).then(() => {
                template.model.isPublic = publish;
                deferred.resolve();
            }, (error) => {
                deferred.reject(error);
            });

            return deferred.promise;
        }
    }

    // endregion
    // endregion
}
