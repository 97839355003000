import {Injectable} from "@angular/core";
import {AuthenticationProvider, AuthenticationData, AuthenticationStatusEvent, AuthenticationType, AuthRequestHeader, AuthenticationState} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {Observable, Subject, of, throwError} from "rxjs";
import {HttpHandler, HttpHeaders} from "@angular/common/http";
import {HttpRequest} from "@angular/common/http";
import {switchMap} from "rxjs/operators";
import {ProfileService} from "CORE_PATH/authentication/util/profile.service";

@Injectable({
    providedIn: "root"
})
export class OidcElectronAuthDelegateService implements AuthenticationProvider {
    statusEvent$: Subject<AuthenticationStatusEvent> = new Subject<AuthenticationStatusEvent>();
    private _requestHeaders: AuthRequestHeader[];
    afterInitialLogin: boolean = false;

    constructor(private profileService: ProfileService) {
        window.electron?.subscribeToAuthenticationEvents?.().subscribe(event => {
            // We might have a stale refresh token in our storage, which triggers this event during an authentication attempt
            // Since we want to display an authentication window anyway, we don't need to abort our authentication attempt
            if (!this.afterInitialLogin && event.state == AuthenticationState.SESSION_REFRESH_FAILED) {
                return;
            }
            if(event.state == AuthenticationState.LOGGED_IN) {
                this.afterInitialLogin = true;
            }
            console.info(event);
            if (event.requestHeaders) {
                this._requestHeaders = event.requestHeaders;
            }
            this.statusEvent$.next(event);
        });
    }

    authenticate(backendOrigin: string, credentials?: AuthenticationData): Observable<AuthenticationStatusEvent> {
        return window.electron.callAuthenticateOnOidcDelegate({authType: AuthenticationType.OPENRESTY_KEYCLOAK, backendOrigin, credentials});
// return of({state: AuthenticationState.LOGGED_IN} as AuthenticationStatusEvent);
    }

    getStatusEvent(): Observable<AuthenticationStatusEvent> {
        return this.statusEvent$.asObservable();
    }

    handleReauthenticationRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return window.electron.callIsAuthenticatedOnOidcDelegate(this.profileService.getLastActiveProfile()?.url).pipe(switchMap(authenticated => {
            if(authenticated) {
                let headers: HttpHeaders = req.headers;
                this._requestHeaders?.forEach(header => {
                    headers = headers.set(header.key, header.value);
                });
                return next.handle(req.clone({headers}));
            } else {
                return throwError("Unable to reauthenticate");
            }
        }));
    }

    invalidateSession(): Observable<AuthenticationStatusEvent> {
        window.electron.invalidateDelegateSession();
        return of({state: AuthenticationState.LOGGED_OUT});
    }

    isAuthenticated(): Observable<boolean> {
        return window.electron.callIsAuthenticatedOnOidcDelegate(this.profileService.getLastActiveProfile()?.url);
    }

}
