<div class="dropzone-container dropzone-create">
    <div class="dropzone-outer-container" (click)="fileInput.click()" dragDrop (onFileDropped)="uploadFile($event)">
        <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" [multiple]="config.maxFileCount > 1" [accept]="getFileExtensions()">
        <div class="dropzone-content-box">
            <div class="dropzone dropzone-files">
                <div class="dz-default dz-message" *ngIf="files.length == 0">
                    <span class="dropzone-icon"></span>
                    <span>{{ "dropzone.defaultmessage" | ngTranslate }}</span>
                    <span class="allowed-types-message" *ngIf="config.allowedFileExtensions?.length > 0">{{ "dropzone.allowed.types.message" | ngTranslate }} {{config.allowedFileExtensions.join(",") | uppercase }}<br /><br />{{"dropzone.file.required.for.creation" | ngTranslate}}</span>
                </div>
                <div class = "dz-preview dz-file-preview" *ngFor="let file of files;let i=index" [ngClass]="{'dz-processing': file.processing, 'dz-success': file.uploadProgress == 1, 'dz-complete': !file.processing, 'dz-error': file.failed}">
                    <div class="dz-progress">
                        <span class="dz-upload" [style.width.%] = "file.uploadProgress * 100"></span>
                    </div>
                    <div class = "dz-success-mark">
                        <span>✔</span>
                    </div>
                    <div class = "dz-error-mark">
                        <span>✘</span>
                    </div>
                    <div class="dz-error-message">
                        <span *ngIf = "file.failed">{{ "modal.upload.error" | ngTranslate }}</span>
                    </div>
                    <div class = "dz-details">
                        <div class="dz-image">
                            <span>
                                <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTIwIDE1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIwIDE1MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNGRkZGRkY7fS5zdDF7ZmlsbDojRURFREVEO30uc3Qye2ZpbGw6I0RDRENEQzt9PC9zdHlsZT48cmVjdCBjbGFzcz0ic3QwIiB3aWR0aD0iMTIwIiBoZWlnaHQ9IjE0OS44Ii8+PHJlY3QgeD0iMjEiIHk9IjI1LjIiIGNsYXNzPSJzdDEiIHdpZHRoPSI3NyIgaGVpZ2h0PSIzLjciLz48cmVjdCB4PSIyMSIgeT0iMzIuMiIgY2xhc3M9InN0MSIgd2lkdGg9Ijc3IiBoZWlnaHQ9IjQ4LjciLz48cGF0aCBjbGFzcz0ic3QyIiBkPSJNODMuOCwwIi8+PHBhdGggY2xhc3M9InN0MSIgZD0iTTgxLDBIMHYxNDkuOGgxMjBWMzguMUw4MSwweiBNMTEzLDE0My41SDdWN2w2NiwwTDczLDQ2bDQwLDBWMTQzLjV6IE03OSw0MFY2LjdMMTEyLjIsNDBMNzksNDB6Ii8+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSI3OSw0MCA3OSw2LjcgMTEyLjIsNDAgIi8+PHJlY3QgeD0iMjEiIHk9Ijg0LjEiIGNsYXNzPSJzdDEiIHdpZHRoPSI3NyIgaGVpZ2h0PSIzLjciLz48L3N2Zz4=" />
                            </span>
                        </div>
                        <div class = "file-details">
                            <div class="dz-filename">
                                <span>{{ file.file.name }}</span>
                            </div>
                            <div class="dz-size">
                                <span>{{ fileCacheService.niceBytes(file.file.size) }}</span>
                            </div>
                        </div>
                    </div>
                    <button class="dz-remove button-transparent" (click)="$event.stopPropagation(); fileInput.value = ''; removeFile(i)">X</button>
                </div>
            </div>
        </div>
    </div>
</div>
