import { Pipe, PipeTransform } from "@angular/core";
import {forwardRef, Inject, InjectionToken, Injector} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: "ngTranslate"})
export class TranslationPipe implements PipeTransform {

    private readonly translateFn: TranslateFnType;

    constructor(@Inject("$filter") private $filter: ng.IFilterService
    ) {
        this.translateFn = this.$filter("translate") ;
    }

    transform(value: string): string {
        return this.translateFn(value);
    }
}