import {ERROR_CODES} from "SHARED_PATH/models/error-model.config";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {Injectable, Inject} from "@angular/core";
import {CustomError} from "CORE_PATH/models/custom-error/custom-error.model";

const DEFAULT_ERROR: any = ERROR_CODES.WEB_ERROR_UNDEFINED;

/**
 * Creates Webclient Custom Error Objects.
 */
@Injectable({providedIn: "root"})
export class ErrorModelService {
    private readonly translateFn: TranslateFnType;

    constructor(@Inject("$filter") $filter: ng.IFilterService) {
        this.translateFn = $filter("translate");
    }

    private generateMessage(msgKey: string, replacements?: string[]): string[] {
        const message: string[] = [this.translateFn(msgKey || DEFAULT_ERROR.messageKey)];
        if (replacements != void 0 && replacements.length > 0) {
            message[0] = this.replaceMessagePlaceholders(message[0], replacements);
        }
        return message;
    }

    /**
     * Checks whether the given parameter is null or undefined and builds a WEB_PARAMETER_MISSING webclient error
     * or otherwise a WEB_PARAMETER_INVALID webclient error.
     *
     * @param {string} name - Name of the parameter.
     * @param {*} value - Value of the parameter.
     * @returns {CustomError} - A custom webclient error.
     */
    createParameterError(name: string, value: any): CustomError {
        if (value == void 0) {
            return this.createCustomError("WEB_PARAMETER_MISSING", `'${name}'`);
        }

        return this.createCustomError("WEB_PARAMETER_INVALID", `'${name}' - ${value}`);
    }

    /**
     * Builds a custom webclient error object based on an error key
     * and replaces placeholder with given replacements.
     *
     * @param {String} errorKey - An error code key.
     * @param {...string} replacements - A spread array of placeholder replacements.
     * @returns {CustomError} A custom webclient error object.
     */
    createCustomError(errorKey: string, ...replacements: string[]): CustomError {
        const errorDetails: any = ERROR_CODES[errorKey];
        let messageKey: string;

        if (errorDetails != void 0) {
            messageKey = errorDetails.messageKey;
        } else {
            console.warn("Could not find error message for key ", errorKey);
        }

        return new CustomError(errorKey, 400, this.generateMessage(messageKey, replacements));
    }

    /**
     * Builds a custom webclient error object from scratch
     * and replaces placeholder with given replacements.
     *
     * @param {string} type - A textual ID for the error.
     * @param {int} status - A representativ of html status codes.
     * @param {string} msgKey - A translation key for the error message.
     * @param {string[]} replacements - An array of placehodler replacements.
     * @returns {CustomError} A custom webclient error object.
     */
    createCustomWebclientError(type: string, status: number, msgKey: string, replacements: string[]): CustomError {
        return new CustomError(type, status, this.generateMessage(msgKey, replacements));
    }

    /**
     * Checks if the message contains placeholder and replaces them with the given replacements.
     * In the bluebird language files the placeholder are marked with [%sx] there x is a number
     * and represents there x. replacement. Example: [%s2] should be replaced by the 2nd replacement.
     *
     * @param {String} message - A message.
     * @param {String[]} replacements - An array of placeholder replacements.
     * @returns {String} The edited message.
     */
    replaceMessagePlaceholders(message: string, replacements: string[]): string {
        const placeholders: string[] = this.extractMessageParameters(message);

        for (const placeholder of placeholders) {
            const index: number = +placeholder.replace("%s", ""); // The + is str -> number
            const replacement: string = replacements[index - 1] || "unknown";
            message = message.replace(`[${placeholder}]`, replacement);
        }

        return message;
    }

    /**
     * Checks if the message contains placeholder and extracts their contents.
     *
     * @param {String} message - A message.
     * @returns {String[]} The contained placeholder parameters.
     */
    extractMessageParameters(message: string): string[] {
        const replacements: string[] = [];

        if (message != void 0) {
            const regex: RegExp = /\[([^\]]+)\]/g; // anything between []
            let match: string[] = regex.exec(message);

            // eslint-disable-next-line no-cond-assign
            while (match) {
                replacements.push(match[1]);
                match = regex.exec(message);
            }
        }

        return replacements;
    }
}
