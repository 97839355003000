import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {EobWopiHostComponent} from "MODULES_PATH/wopi/components/eob-wopi-host/eob-wopi-host.component";
import {OfficeBackendService} from "MODULES_PATH/wopi/services/eob.office.backend.service";
import {WopiHostService} from "MODULES_PATH/wopi/services/eob.wopi.host.service";
import {first} from "rxjs/operators";
import {StateParams, StateService} from "@uirouter/core";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {Subscription} from "rxjs";

@Component({
    selector: "eob-wopi-container",
    templateUrl: "./eob-wopi-container.component.html",
    styleUrls: ["./eob-wopi-container.component.scss"]
})
export class EobWopiContainerComponent implements OnInit, OnDestroy {
    @ViewChild("wopiHost", {static: false}) wopiHost: EobWopiHostComponent;

    constructor(@Inject("$state") private $state: StateService,
                @Inject("$stateParams") private $stateParams: StateParams,
                private cdRef: ChangeDetectorRef,
                public officeBackendService: OfficeBackendService,
                public wopiHostService: WopiHostService,
                private viewerService: ViewerService) {
    }

    wopi$: Subscription;

    ngOnInit(): void {
        if (this.officeBackendService.urlSrc) {
            this.wopiHostService.refresh.next(true);
        }
        this.refresh();
    }

    refresh(): void {
        this.wopi$ = this.wopiHostService.refresh.subscribe((data) => {
            if (data) {
                this.cdRef.detectChanges();
                if (this.wopiHost) {
                    this.wopiHost.submit();
                }
                this.wopiHostService.refresh.next(false);
            }
        });
    }

    ngOnDestroy(): void {
        console.log("container destroyed");
        const accessToken: string = localStorage.getItem("accessToken");
        this.officeBackendService.getEditNotification(accessToken).pipe(first()).subscribe(async (editNotificationResponse) => {
            if (editNotificationResponse.status === true) {
                this.officeBackendService.updateFile(accessToken).pipe(first()).subscribe(
                    async (data) => {
                        console.log("update response: ", data);
                        await this.wopiHostService.refreshObjectInHitlist(data.osid);
                        if(data.status === "UPDATED") {
                            this.officeBackendService.updateContentAndDetailsViewer();
                        }
                        this.wopi$.unsubscribe();
                        await this.viewerService.updateO365Viewer(this.officeBackendService.osId, this.officeBackendService.dmsDocumet);
                    });
            } else {
                this.wopi$.unsubscribe();
                await this.viewerService.updateO365Viewer(this.officeBackendService.osId, this.officeBackendService.dmsDocumet);
            }
        });
    }
}
