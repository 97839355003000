import {TranslateFnType} from "../../../eob-client/custom.types";
import * as SimpleIni from "simple-ini/lib/simple-ini.js";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import * as iconv from "iconv-lite";

/**
 * Service used for opening binary files and parsing osfiles
 */
export class FileOpenService {
    static $inject: string[] = ["$q", "$filter", "$compile", "$rootScope", "$state", "$injector",
        "stateHistoryManager"];
    private translateFn: TranslateFnType;

    constructor(protected $q: ng.IQService, protected $filter: ng.IFilterService, protected $compile: ng.ICompileService,
                protected $rootScope: ng.IRootScopeService, protected $state: any, protected $injector: ng.auto.IInjectorService,
                protected stateHistoryManager: any) {

        this.translateFn = $filter("translate") ;
    }

    /**
     * Opens a passed map of files and processes them for the tray service
     *
     * @param {Map<string, string | ArrayBuffer>} fileMap
     * @returns {Promise<void>}
     */
    async openFilesAsync(fileMap: Map<string, string|ArrayBuffer>): Promise<void> {
        const externalTrayService: any = this.$injector.get("externalTrayService") ;
        console.info(`openFilesAsync() ${fileMap}`);

        try {
            // OS Link Files
            if (fileMap.size == 1 && fileMap.keys().next().value.match(/.*\.os$/g)) {
                this.openOsLinkFileContent(fileMap.values().next().value as ArrayBuffer);
            } else if (fileMap.size > 0) {
                if (!this.checkForCompatibility(fileMap)) {
                    return;
                }
                // External Tray files
                const metadata: any = externalTrayService.createMetadata({filePaths: fileMap});
                const context: any = this.stateHistoryManager.getCurrentStateContext();

                if (context.type == "location") {
                    metadata.trayItemType = "insertDocument";
                    await externalTrayService.addTrayItemAsync(metadata, (context.location || {}).model);// , arrayContent.buffer);
                } else if (context.type == "createWithDropzone") {
                    externalTrayService.insertTrayItemInDropzone(metadata);
                } else {
                    externalTrayService.addTrayItemAsync(metadata, null);// , arrayContent.buffer);
                }
            }
        } catch (error) {
            this.toastParsingFailed(`readAsText failed:\n${error.message}`, "");
            console.info(error.toString());
        }
    }

    /**
     * Parses osfile and, depending on its content, redirects to the desired state
     *
     * @param {string} content File content
     */
    openOsLinkFileContent(payload: ArrayBuffer): void {

        if(!payload) {
            return;
        }

        const notificationsService: any = this.$injector.get("notificationsService") ;
        const environmentService: TodoEnvironmentService = this.$injector.get("environmentService") ;
        let content;
        if(environmentService.getServiceInfo().serverUnicode == "1") {
            content = iconv.decode(payload as Buffer, "utf16");
        } else {
            content = iconv.decode(payload as Buffer, "utf8");
        }

        /**
         * sections: OSAS, SYSTEM, Mappe, FULLTEXT,
         * keys:
         * OSAS: "Dokumenttyp", @"Dokumentintern", @"Register", @"Registerintern", @"Schrank", @"Schrankintern"
         * SYSTEM: IDENT, NAME
         * Mappe: --NOP
         * FULLTEXT: TEXT
         */
        const options: any = {};

        if (!content) {
            // Handle empty files sent by workflow action and do nothing
            return;
        }
        if (content == "ENOENT") {
            // Handle file that could not be loaded
            this.toastParsingFailed("ENOENT", "", "eob.file.not.loaded.error");
            return;
        }

        if (content.includes("\r")) {
            options.lineSeparator = "\r\n";
        }
        options.caseSensitive = false;

        let simpleIni: any;

        try {
            simpleIni = new SimpleIni(((): string => content), options);
        } catch (err) {
            console.error(`Unable to instantiate SimpleIni: ${err.message}`);
        }

        const sections: string[] = ["DOKUMENTTYP", "DOKUMENTINTERN", "REGISTER", "REGISTERINTERN", "SCHRANK", "SCHRANKINTERN"];

        if (!simpleIni) {
            this.toastParsingFailed("SimpleIni is undefined", content);
        } else if (/\$VAR\d+\$/.test(content)) {
            notificationsService.info(this.translateFn("eob.osfile.query.with.params.not.supported"));
        } else if (simpleIni.hasSection("FULLTEXT")) {
            const txt: string = simpleIni.get("FULLTEXT.TEXT");

            if (txt) {
                const params = {fulltextsearch: txt};
                this.stateHistoryManager.goToState("entry", params, {
                    config: {},
                    params
                });
            } else {
                this.toastParsingFailed("FULLTEXT.TEXT does not exist", content);
            }
        } else if (simpleIni.hasSection("SYSTEM")) {
            const id: string = simpleIni.get("SYSTEM.IDENT");
            const name: string = simpleIni.get("SYSTEM.NAME");

            if (id) {
                console.info("SYSTEM");
                console.info({params: {search: id, name}});
                const params = {search: id, name, fromFile: true};
                this.stateHistoryManager.goToState("entry", params, {
                    config: {},
                    params
                });

            } else {
                this.toastParsingFailed("SYSTEM.IDENT does not exist", content);
            }
        } else if (simpleIni.hasSection("MAPPE")) {
            const portfolioId: string = simpleIni.get("MAPPE.#OSID#");
            // let title = simpleIni.get("MAPPE.THEMA");

            if (portfolioId) {
                notificationsService.info(this.translateFn("eob.osfile.portfolio.not.supported"));
            } else {
                this.toastParsingFailed("MAPPE.#OSID# does not exist", content);
            }
        } else if (simpleIni.hasSection("osas")) {
            let docId = "";
            for (const section of sections) {
                if (simpleIni.hasProperty(section, "osas")) {
                    const docIdSection: string = simpleIni.get(`osas.${section}`);
                    docId = simpleIni.get(docIdSection, "#OSID#");

                    if (docId) {
                        const params = {osid: docId, fromFile: true};
                        this.stateHistoryManager.goToState("entry", params, {
                            config: {},
                            params
                        });
                        break;
                    }
                }
            }
            if (!docId) {
                this.toastParsingFailed("No #OSID# inside any section", content);
            }
        } else {
            this.toastParsingFailed("unknown", "");
        }
    }

    private toastParsingFailed(reason: string, content: string, toasterMessage: string = "eob.osfile.parsing.failed"): void {
        const notificationsService: any = this.$injector.get("notificationsService") ;
        console.warn(`OS file parsing failed: ${reason}${content ? `\n\n${content}` : ""}`);
        notificationsService.error(this.translateFn(toasterMessage));
    }
    /**
     * we need to check whether file type is allowed. Also if user had selected multiple files, we need to check
     * whether these filetypes are allowed to be saved in one file in bluebird
     *
     * @param {Map<string, ArrayBuffer>} fileMap - A map containing file names and content
     * @returns {boolean} - whether the combination of files can be inserted anywhere in bluebird at all
     * @private
     */
    private checkForCompatibility(fileMap: Map<string, string|ArrayBuffer>): boolean {
        const notificationsService: any = this.$injector.get("notificationsService") ;
        const environmentService: TodoEnvironmentService = this.$injector.get("environmentService") ;
        const fileExtensions: string[] = [];
        let areExtensionsCompatible = false;

        for (const filename of [...fileMap.keys()]) {
            const fileExtension: string = filename.split(".").pop() || "";
            if (!fileExtensions.includes(fileExtension.toUpperCase())) {
                fileExtensions.push(fileExtension.toUpperCase());
            }
        }

        for (let maintype = 1; maintype < 8; maintype++) {
            let counter = 0;
            const allowedExtensions: string = environmentService.getAllowedFileExtByMainType(maintype).split(",");
            for (const ext of fileExtensions) {
                if (allowedExtensions.includes(ext)) {
                    counter++;
                }
            }
            if (counter == fileExtensions.length) {
                areExtensionsCompatible = true;
            }
        }

        if (fileExtensions.length > 1 && !areExtensionsCompatible) {
            notificationsService.error(this.translateFn("eob.mobile.open.bad.combination.of.files"));
            return false;
        }

        if (!areExtensionsCompatible) {
            notificationsService.error(this.translateFn("eob.mobile.open.bad.file").replace("%s1", fileExtensions[0]));
            return false;
        }

        return true;
    }
}
