import {Injectable} from "@angular/core";
import {Subject, Observable} from "rxjs";
import {Theme} from "./theme.interface";
import {ThemingUtilsService} from "MODULES_PATH/theme/theming-utils.service";
import {StandardThemeService} from "MODULES_PATH/theme/standard-theme.service";
import {DarkThemeService} from "MODULES_PATH/theme/dark-theme.service";
import {HighContrastThemeService} from "MODULES_PATH/theme/high-contrast-theme.service";

@Injectable({
    providedIn: "root"
})
export class ThemeService {

    constructor(
        private themingUtilsService: ThemingUtilsService,
        private standardThemeService: StandardThemeService,
        private darkThemeService: DarkThemeService,
        private highContrastThemeService: HighContrastThemeService
    ) {
    }

    private activeAccentColor: string = "#0092e1";

    private activeTheme: Theme = this.standardThemeService.getStandardTheme(this.activeAccentColor);
    private availableThemes: Theme[] = [
        this.darkThemeService.getDarkTheme(this.activeAccentColor),
        this.standardThemeService.getStandardTheme(this.activeAccentColor),
        this.highContrastThemeService.getHighContrastTheme()
    ];

    private readonly themeChanged$ = new Subject<string>();

    setTheme(name: string, accentColor?: string): void {
        if(accentColor) {
            this.activeAccentColor = accentColor;
        }
        document.body.classList.remove(`${this.activeTheme.name}`);

        this.activeTheme = this.getThemeByName(name) ?? this.standardThemeService.getStandardTheme(this.activeAccentColor);
        document.body.classList.add(`${this.activeTheme.name}`);

        const cssTheme: any = this.themingUtilsService.createCssVars(this.activeTheme);
        this.themingUtilsService.addStyleToDocument(cssTheme);

        this.themeChanged$.next(this.activeTheme.name);
        if(window.electron) {
            window.electron.setColorTheme(this.activeTheme.name);
        }
    }

    getThemeByName(name: string): Theme {
        switch (name) {
            case "dark":
                return this.darkThemeService.getDarkTheme(this.activeAccentColor);
            case "high-contrast":
                return this.highContrastThemeService.getHighContrastTheme();
            default:
                return this.standardThemeService.getStandardTheme(this.activeAccentColor);
        }
    }

    getAvailableThemes(): Theme[] {
        return this.availableThemes;
    }

    getActiveTheme(): Theme {
        return this.activeTheme;
    }

    getThemeChanges(): Observable<string> {
        return this.themeChanged$.asObservable();
    }

    setAccentColor(hexValue: string): void{
        // we set the whole theme again so all the accent color related variables can be set again and the observable will be triggered
        this.setTheme(this.activeTheme.name, hexValue);
    }

    getActiveAccentColor(): string {
        return this.activeAccentColor;
    }

}
