/**
 * Synchronization status for one offline object
 */
export enum ObjectSyncStatus {
    /* Signals that a favorite is new. As in it is an favorite but not cached yet */
    NEW= 0,
    /* Signals that a favorite is in cache but has been modified at the backend. */
    UPDATE = 1,
    /* Signals that a favorite is in cache but only the indexdata. */
    UPDATE_HALF = 1.5,
    /* Signals that a favorite is in cache and current with the backend data. */
    DONE = 2,
    /* Signals that a favorite is cached, but removed from the backend. */
    REMOVE = 3,
    FAILED = 4
}