import {Inject, Injectable} from "@angular/core";
import {LoginTypeDropdownItem} from "MODULES_PATH/profile-manager/profile-manager.component";
import {Profile} from "CORE_PATH/authentication/util/profile.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {AuthenticationState, AuthenticationStatusEvent, AuthenticationType} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {Todo$eobConfig, TodoMDMConfig} from "INTERFACES_PATH/any.types";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {Observable, of} from "rxjs";

@Injectable()
export class ProfileManagerService {
    private readonly translateFn: TranslateFnType;
    showBackButton = false;
    checkingLoginData = false;
    showLoginForm: boolean;
    isPlaceholderSelected = false;
    allowEnteringCredentials = true;
    showPasswordExpiredForm = false;
    isBrowserClient: boolean;
    profile: Profile = {} as Profile;

    constructor(
        @Inject("$eobConfig") private $eobConfig: Todo$eobConfig,
        @Inject("$filter") $filter: ng.IFilterService,
        private notificationsService: NotificationsService,
        private clientService: ClientService,
        public authenticationService: AuthenticationService) {
            this.translateFn = $filter("translate");
            this.isBrowserClient = !this.clientService.isLocalClient();
            if(this.isBrowserClient) {
                authenticationService.selectAuthenticationProvider(AuthenticationType.BASIC_AUTH);
                this.showLoginForm = true;
            }
    }

    authenticate(): Observable<AuthenticationStatusEvent> {
        if(!this.isBrowserClient) {
            if (!this.profile.url) {
                return of({state: AuthenticationState.LOGIN_URL_INVALID});
            }

            if (!/^https?:\/\//gi.test(this.profile.url)) {
                return of({state: AuthenticationState.LOGIN_URL_NO_PROTOCOL});
            }

            try {
                const url: URL = new URL(this.profile.url);
                let re = "^(\\";
                re += this.$eobConfig.getOswebBase();
                re += "\\\/?|\\/)$";
                if (!new RegExp(re, "gi").test(url.pathname)) {
                    return of({state: AuthenticationState.LOGIN_URL_INVALID});
                }
                this.profile.url = url.origin;
            } catch (error) {
                return of({state: AuthenticationState.LOGIN_URL_INVALID, error});
            }

            if (this.allowEnteringCredentials && (!this.profile.username || !this.profile.password)) {
                return of({state: AuthenticationState.LOGIN_CREDENTIALS_INCOMPLETE});
            }

            delete this.profile.windowPosition;
            delete this.profile.windowSize;
        }

        this.checkingLoginData = true;

        return this.authenticationService.authenticate(this.profile.url, {
            authType: this.profile.authType,
            username: this.profile.username,
            password: this.profile.password
        });
    }

    authTypeDropdownCallback(item: LoginTypeDropdownItem): void {
        switch (item.internal) {
            case AuthenticationType.BASIC_AUTH:
                this.profile.authType = AuthenticationType.BASIC_AUTH;
                this.allowEnteringCredentials = true;
                break;
            case AuthenticationType.NTLM_USERNAME:
                this.profile.authType = AuthenticationType.NTLM_USERNAME;
                this.allowEnteringCredentials = true;
                break;
            case AuthenticationType.NTLM_SYSTEM:
                this.profile.authType = AuthenticationType.NTLM_SYSTEM;
                this.allowEnteringCredentials = false;
                break;
            case AuthenticationType.OPENRESTY_KEYCLOAK:
                this.profile.authType = AuthenticationType.OPENRESTY_KEYCLOAK;
                this.allowEnteringCredentials = false;
                break;
        }

        this.authenticationService.selectAuthenticationProvider(item.internal);
    }

    togglePasswordExpiredForm(): void {
        this.showPasswordExpiredForm = !this.showPasswordExpiredForm;
    }

    handleError(messageKey: string, error?: unknown): void {
        this.notificationsService.error(this.translateFn(messageKey));
        if (error) {
            console.error(error);
        }
    }

    handleWarning(messageKey: string, error?: unknown): void {
        this.notificationsService.warning(this.translateFn(messageKey));
        if (error) {
            console.warn(error);
        }
    }

    showForm(): void {
        this.showBackButton = true;
        this.showLoginForm = true;

        const mdmConfig: TodoMDMConfig = this.clientService.getMDMConfig();

        if (mdmConfig) {
            for (const key in mdmConfig) {
                this.profile[key] = mdmConfig[key];
            }
        }
    }
}
