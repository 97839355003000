<div class="flex-container">
    <eob-state-header [stateTitle]="stateHeaderTitle" [description]="stateHeaderDescription" [isMockContext]="isMockContext">
        <eob-state-filter *ngIf="hitlistConfig" [hitlistconfig]="hitlistConfig"></eob-state-filter>
        <eob-state-refresher *ngIf="!isPhone && showRefreshButton"></eob-state-refresher>
        <eob-state-sync-button *ngIf="showStateSyncButton" [hitlistconfig]="hitlistConfig"></eob-state-sync-button>
        <eob-kebab-menu *ngIf="showHitlistMenu"
                        [menuItems]="menuItems"
                        [menuItemsCallbackAsync]="menuItemsCallback">
        </eob-kebab-menu>
    </eob-state-header>
    <div role="region" [attr.aria-label]="'eob.result.state.title' | ngTranslate" class="full-height-container">
        <eob-state-touch-refresh>
            <eob-hit-list *ngIf="hitlistConfig" [hitlistconfig]="hitlistConfig"></eob-hit-list>
        </eob-state-touch-refresh>
        <div id="loadAnimation" class="osProgressbar invisible" [hidden]="!loadingState.isLoading">
            <div class="osloader"></div>
        </div>
        <div *ngIf="loadingState.hasloadSyncAnimation" id="loadSyncAnimation" class="osProgressbar invisible">
            <div class="osloader"></div>
            <span>{{'eob.sync.offline.running.loading.title' | ngTranslate}}</span>
        </div>
    </div>
    <div [hidden]="!loadingState.hasErrors" class="empty-hitlist-placeholder">
        <div class="placeholder-content">
            <eob-icon size="32" name="no-elements-to-show" title="no elements to show"></eob-icon>
            <span>{{loadingState.errorMessage || 'eob.result.error' | ngTranslate}}</span>
        </div>
    </div>
    <eob-hitlist-footer [hitlistconfig]="hitlistConfig" *ngIf="!isForcedPhoneLayout"></eob-hitlist-footer>
</div>
