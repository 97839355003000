import { Injectable, Inject } from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {FieldDataType} from "ENUMS_PATH/field.enum";
import {ValidationMessage} from "MODULES_PATH/form/interfaces/validation-message.interface";
import {ValidationErrors} from "@angular/forms";


@Injectable({
    providedIn: "root"
})
export class ValidationErrorMapperService {
    translateFn: TranslateFnType;
    constructor(@Inject("$filter") $filter: ng.IFilterService) {
        this.translateFn = $filter("translate");
    }

    getValidationErrorMessage(controlErrors: ValidationErrors): ValidationMessage {
        if(controlErrors.customError) {
            return {
                title: controlErrors.customError.title ?? this.translateFn("eob.validation.general.title"),
                message: controlErrors.customError.message ?? this.translateFn("eob.validation.failed.error")
            };
        }
        if(controlErrors.failed) {
            switch(controlErrors.failed) {
                case FieldDataType.DECIMAL:
                    return {
                        title: this.translateFn("eob.validation.general.title"),
                        message: this.translateFn("eob.validation.decimal.message")
                    };
                case FieldDataType.NUMBER:
                    return {
                        title: this.translateFn("eob.validation.general.title"),
                        message: this.translateFn("eob.validation.number.message")
                    };
                case FieldDataType.TIME:
                    return {
                        title: this.translateFn("eob.validation.time.title"),
                        message: this.translateFn("eob.validation.time.message")
                    };
                case FieldDataType.LETTER:
                    return {
                        title: this.translateFn("eob.validation.general.title"),
                        message: this.translateFn("eob.validation.letter.message")
                    };
                case FieldDataType.ALPHANUM:
                    return {
                        title: this.translateFn("eob.validation.general.title"),
                        message: this.translateFn("eob.validation.number.message")
                    };
            }
        }
        if(controlErrors.required) {
            return {title: this.translateFn("eob.validation.required.title"),
            message: this.translateFn("eob.validation.required.message")};
        }
        if(controlErrors.maxlength) {
            return {
                title: this.translateFn("eob.validation.max.length.title"),
                message: this.translateFn("eob.validation.max.length.message").replace("{maxlength}", controlErrors.maxlength.requiredLength)
            };
        }
        console.warn(`FIXME: Validation message missing for error ${JSON.stringify(controlErrors)}`);
        return {
            title: this.translateFn("eob.validation.general.title"),
            message: this.translateFn("eob.validation.failed.error")
        };
    }
}
