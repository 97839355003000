<div id="chooseBoxes" [ngClass]="{'keep-sequence': options.keepSequence}">
    <div class="choose-box-container">
        <input placeholder="{{'state.header.filter.placeholder' | ngTranslate}}" class="choose-box-filter" (keyup)="onFilterChange('leftGrid', $event.target.value)">
        <div id="agGridLeft" *ngIf="leftGrid.options" [ag-grid]="leftGrid.options" class="inline-grid" [attr.lang] ="showAltLang ? objectDefLang : null"></div>
    </div>
    <div id="moveButtons">
        <button id="moverightall" type="button" value="addall" (click)="addAllFields()">
            <eob-icon title="{{'eob-multiselect-boxes.add.all' | ngTranslate}}" name="all-in-white" size="16"></eob-icon>
        </button>
        <br/>
        <button id="moveright" type="button" value="singleadd" (click)="addFields()">
            <eob-icon title="{{'eob-multiselect-boxes.add.single' | ngTranslate}}" name="back-white" size="16"></eob-icon>
        </button>
        <br/>
        <button id="moveleft" type="button" value="singleremove" (click)="removeFields()">
            <eob-icon title="{{'eob-multiselect-boxes.remove.single' | ngTranslate}}" name="back-white" size="16"></eob-icon>
        </button>
        <br/>
        <button id="moveleftall" type="button" value="removeall" (click)="removeAllFields()">
            <eob-icon title="{{'eob-multiselect-boxes.remove.all' | ngTranslate}}" name="all-back-white" size="16"></eob-icon>
        </button>
    </div>
    <div class="choose-box-container">
        <input placeholder="{{'state.header.filter.placeholder' | ngTranslate}}" class="choose-box-filter" (keyup)="onFilterChange('rightGrid', $event.target.value)">

        <div class="choose-box-container-content" [ngClass] = "{'full-width': !options.keepSequence}">
            <div id="agGridRight" *ngIf="rightGrid.options" [ag-grid]="rightGrid.options" class="inline-grid" [attr.lang] ="showAltLang ? objectDefLang : null"></div>

            <div class="export-arrow-box" style="float: unset" *ngIf="options.keepSequence && isPhone">
                <button (click)="moveUp()" class="export-arrow-box-arrow button-transparent">
                    <eob-icon name="arrow-up" size="16" role="img" title="{{'eob-multiselect-boxes.up' | ngTranslate}}"></eob-icon>
                </button>
                <button (click)="moveDown()" class="export-arrow-box-arrow button-transparent">
                    <eob-icon name="arrow-down" size="16" role="img" title="{{'eob-multiselect-boxes.down' | ngTranslate}}"></eob-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="export-arrow-box" style="float: unset" *ngIf="options.keepSequence && !isPhone">
    <button (click)="moveUp()" class="export-arrow-box-arrow button-transparent">
        <eob-icon name="arrow-up" role="img" size="16" title="{{'eob-multiselect-boxes.up' | ngTranslate}}"></eob-icon>
    </button>
    <button (click)="moveDown()" class="export-arrow-box-arrow button-transparent">
        <eob-icon name="arrow-down" role="img" size="16" title="{{'eob-multiselect-boxes.down' | ngTranslate}}"></eob-icon>
    </button>
</div>
