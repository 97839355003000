import {
    Component,
    ElementRef,
    Input,
    OnInit
} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {AfterViewInit, forwardRef, Inject, Renderer2} from "@angular/core";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-form-button",
    templateUrl: "./eob-form-button.component.html",
    styleUrls: ["./eob-form-button.component.scss"]
})

export class EobFormButtonComponent implements OnInit, AfterViewInit {

    @Input() field: any;
    @Input() layoutField: Field;
    @Input() ismockform: boolean;
    isIconButton: boolean;
    layoutFactor: number;
    showAltLang: boolean;
    objectDefLang: any;

    constructor(
        public el: ElementRef,
        public renderer: Renderer2,
        public layoutManagerService: LayoutManagerService,
        @Inject(forwardRef(() => "environmentService")) public environmentService: TodoEnvironmentService
    ) {
        this.layoutFactor = this.layoutManagerService.isTouchLayoutActive() ? 20 : 16;

    }

    ngOnInit(): void {
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang() && !this.ismockform;
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();
        this.isIconButton = this.field.model.iconClass != "" && this.field.model.iconClass != void 0;
        $(this.el.nativeElement).on("click", this.blur.bind(this));
    }

    /**
     * Blurs the button again after it was clicked with mouse button
     *
     * @param {object} event - A dom event object.
     */
    blur(event: any): void {
        // ok let me get this straight ...
        //
        // when the user used tab navigation and uses the space button to trigger the event
        // the event is emulated as a mouse click event with the signature of the left mouse button
        // to not blur when the user uses the space key we check if  the event is at clientX and clientY = 0
        if (event.clientX != 0 || event.clientY != 0) {
            $(this.el.nativeElement).trigger("blur");
        }
    }

    ngAfterViewInit(): void {
        const button: HTMLElement|null = this.el.nativeElement.querySelector("button");
        if(button) {
            this.renderer.setStyle(button, "width", this.layoutField.width);
            this.renderer.setStyle(button, "height", `${this.layoutField.height * this.layoutFactor - 4}px`);
        }
    }

}
