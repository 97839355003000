import { Injectable } from "@angular/core";
import * as angular from "angular";
import { BubbleBoxStyle } from "INTERFACES_PATH/validation.interface";

/**
 * A service for util functions that concern styling and browser functionality.
 */
@Injectable({ providedIn: "root" })
export class ViewService {

    /**
     * Copy to clipboard function for all browsers and devices.
     *
     * @param value - The text that shall be copied to the clipboard.
     * @returns Whether the action was sucessful.
     */
    copyToClipboard = (value: string): boolean => {
        const textArea: HTMLTextAreaElement = document.createElement("textarea");

        textArea.value = value;

        document.body.appendChild(textArea);

        // For security reasons iOS Safari only allows document.execCommand('copy') for text within a contentEditable container.
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            // workaround for safari browser to stop IOS keyboard opening
            textArea.blur();

            // save current contentEditable/readOnly status
            const oldContentEditable: string = textArea.contentEditable;
            const oldReadOnly: boolean = textArea.readOnly;

            // convert to non-editable with readonly to stop iOS keyboard opening
            textArea.contentEditable = "false";
            textArea.readOnly = true;

            // create a selectable range
            const range: Range = document.createRange();
            range.selectNodeContents(textArea);

            // select the range
            const selection: Selection | null = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
            textArea.setSelectionRange(0, 999999);

            // restore contentEditable/readOnly to original state
            textArea.contentEditable = oldContentEditable;
            textArea.readOnly = oldReadOnly;
        } else {
            textArea.focus();
            textArea.select();
        }

        const successful: boolean = document.execCommand("copy");

        document.body.removeChild(textArea);

        return successful;
    };

    /**
     * Recursively retrieves the z-index of the deepest {@link Element} starting from the given {@link Node}
     *
     * @param e
     * @returns number
     */
    getZIndex = (e: Element): number => {
        if (e == null) {
            return -1;
        }
        const z: string = (window.document.defaultView as WindowProxy).getComputedStyle(e).getPropertyValue("z-index");
        if (isNaN(parseInt(z, 10)) || z == "0" && e.parentNode) {
            return this.getZIndex(e.parentElement as Element);
        }
        return parseInt(z, 10);
    };

    /**
     * Tries to autofocus the given element
     *
     * @param element
     * @param selector
     */
    autoFocusElement = (element: Element, selector: string): void => {

        // try to focus the first input on the form
        // because there are no elegant ways to do this in this context
        // we use an interval that tries to focus the first input
        // after 5 runs the interval clears itself
        let runs: number = 0;
        const interval: any = setInterval(() => {
            runs++;

            if (runs > 5) {
                clearInterval(interval);
                console.warn("unable to select element");
            }

            const el: any = angular.element(element).find(selector)[0];

            if (typeof (el) != "undefined") {
                $(el).focus();
                clearInterval(interval);
            }
        }, 100);
    };

    getPosition(element: HTMLElement, isReCalc?: boolean): BubbleBoxStyle {
        const css: BubbleBoxStyle = {
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto"
        };

        const screenPos: DOMRect = element.getBoundingClientRect();
        css.left = screenPos.left;
        if (typeof screenPos.bottom === "number") {
            css.top = screenPos.bottom + window.scrollY + 4;
        }

        return css;
    }
}
