import {NgModule, NO_ERRORS_SCHEMA} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ContextMenuComponent} from "MODULES_PATH/context-menu/context-menu.component";
import {EobContextMenuTemplateComponent} from "MODULES_PATH/context-menu/components/eob-context-menu-template/eob-context-menu-template.component";
import {EobSubmenuComponent} from "MODULES_PATH/context-menu/components/eob-submenu/eob-submenu.component";
import {SharedModule} from "SHARED_PATH/shared.module";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";

const components: any = [
    ContextMenuComponent,
    EobContextMenuTemplateComponent,
    EobSubmenuComponent
];

@NgModule({
    declarations: [...components],
    imports: [CommonModule, SharedModule],
    exports: [...components],
    providers: [ContextMenuUtilsService],
    entryComponents: [ContextMenuComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class ContextMenuModule {
}
