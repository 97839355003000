import Dexie from "dexie";
import {DatabaseType} from "ENUMS_PATH/database/database-type.enum";
import {GlobalDatabase, OldGlobalDatabase} from "SHARED_PATH/models/global-database.model";
import {GlobalStore, ProfileStore} from "INTERFACES_PATH/database/database-store.interface";
import {ProfileDatabase} from "SHARED_PATH/models/profile-database.model";
import {OfflineDataStore} from "MODELS_PATH/eob.offline.data.model";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ContentType} from "ENUMS_PATH/content-type.enum";
import {Profile} from "CORE_PATH/authentication/util/profile.service";

export class UpdateUtilService {
    static $inject: string[] = ["$injector"];

    /** Keeps a reference to the old profile db after the initialization of the client, if it does still exist. */
    private oldProfileDb: ProfileDatabase;

    syncUpdateNecessary: boolean = false;

    constructor(private $injector: ng.auto.IInjectorService) {}

    /**
     * Move global data from an overall global database to an url-specific global database.
     *
     * @since 9.10.1
     */
    async updateGlobalDb(globDb: GlobalDatabase, profile: Profile): Promise<void> {
        const oldDb: OldGlobalDatabase = new OldGlobalDatabase(DatabaseType.GLOBAL);

        // using try-catch instead of Dexie.exists, since the function can't handle dbs with older versions
        try {
            if (!oldDb.isOpen()) {
                await oldDb.open();
            }
        } catch(error) {
            return; // database doesn't exist anymore
        }

        const profileCollection: Dexie.Collection<GlobalStore<any>, number> = oldDb.persistent.where("origin").equals(profile.url);
        const entries: Array<GlobalStore<any>> = await profileCollection.toArray();
        if (entries.length > 0) {
            entries.forEach(entry => delete entry.origin);
            await globDb.persistent.bulkPut(entries);
            await profileCollection.delete();
        }

        if (await oldDb.persistent.count() === 0) {
            await oldDb.delete();
        }
    }

    /**
     * Rename the profile database by copying its content to a new database.
     * The database name will change from the user name to the user id, since it is always unique.
     * Copy everything that is not part of the offline cache to the new database,
     * however keep the content data persisted so that it can be used in later synchronizations.
     *
     * @since 9.10.1
     */
    async updateProfileDb(globDb: GlobalDatabase, profileDb: ProfileDatabase, profile: Profile): Promise<void> {
        const oldDb: ProfileDatabase = new ProfileDatabase(btoa(`${profile.username}@${profile.url}`), this.$injector.get("clientService"));

        // using try-catch instead of Dexie.exists, since the function can't handle dbs with older versions
        try {
            if (!oldDb.isOpen()) {
                await oldDb.open();
            }
        } catch(error) {
            return; // database doesn't exist anymore
        }

        // check whether anything was synced before
        const oldSyncData: ProfileStore<any> = await oldDb.persistent.where("key").equals("favorites_synced_cache").first();
        this.syncUpdateNecessary = oldSyncData == undefined ? false : oldSyncData.content.length > 0;

        // delete offline cache data except content
        await oldDb.persistent.where("key").anyOf(["offlineTreeCache", "offlineObjectsCache", "favorites_synced_cache"]).delete();

        // copy all remaining entries except offline cache content
        const updateDataCol: Dexie.Collection<ProfileStore<any>, string> = oldDb.persistent.where("group").notEqual("content");
        const entries: Array<ProfileStore<any>> = await updateDataCol.toArray();
        if (entries.length > 0) {
            await profileDb.table("persistent").bulkPut(entries);
            await updateDataCol.delete();
        }

        // remove the db if it's empty
        if (await oldDb.persistent.limit(1).count() == 0) {
            await oldDb.delete();
        } else {
            this.oldProfileDb = oldDb;
        }
    }

    /**
     * Transfer content from the old profile db, if possible, to avoid downloading content for the update process from the backend again.
     *
     * @since 9.10.1
     */
    async updateSpecificContent(contentCache: Dexie.Table<any, string>, osid: string, type: ContentType, offlineData: OfflineDataStore, dmsDocument?: DmsDocument): Promise<boolean> {
        if (!this.oldProfileDb) {
            return false;
        }

        const cacheCollection: Dexie.Collection<ProfileStore<any>, string> = this.oldProfileDb.persistent.where("key").equals(`${type}-${osid}`),
            cachedContent: ProfileStore<any> = await cacheCollection.first();
        if (cachedContent === undefined) {
            return false;
        }

        if (cachedContent.timestamp.getTime() <= offlineData.lastmodified
            || cachedContent.content.files.length > 1) { // unzipped multi-images
            await cacheCollection.delete();
            return false;
        }

        const offlineCacheService: OfflineCacheService = this.$injector.get("offlineCacheService");
        await offlineCacheService.cacheContent(type, osid, cachedContent.content, dmsDocument ? dmsDocument.model.fileProperties : undefined);
        await cacheCollection.delete();

        if (dmsDocument && dmsDocument.model.fileProperties.mimeTypeGroup === "PDF") {
            this.oldProfileDb.persistent.where("key").equals(`${ContentType.RENDITION}-${osid}`).delete();
        }

        return true;
    }

    /**
     * Delete the old profile database.
     *
     * @since 9.10.1
     */
    deleteProfileDatabase(profile: Profile): void {
        const oldDb: ProfileDatabase = new ProfileDatabase(btoa(`${profile.username}@${profile.url}`), this.$injector.get("clientService"));

        // using try-catch instead of Dexie.exists, since the function can't handle dbs with older versions
        try {
            oldDb.delete();
        } catch(error) {
            return; // database doesn't exist anymore
        }
    }

    async changeSchema(globDb: any): Promise<any> {
        globDb.close();

        // open the global db with the old schema
        const oldDb: any = new OldGlobalDatabase(globDb.name);
        await oldDb.open();

        // get all the entries
        const entries: Array<GlobalStore<any>> = await oldDb.persistent.toArray();
        entries.forEach(entry => delete entry.origin);

        // delete the db
        await oldDb.delete();

        // create a new one with the new schema
        const newDb: any = new GlobalDatabase(oldDb.name, this.$injector.get("clientService"));
        await newDb.open();

        // get the old entries back in
        newDb.persistent.bulkAdd(entries);

        return newDb;
    }
}