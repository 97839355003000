import {MessageService} from "CORE_PATH/services/message/message.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ElementRef, Inject, Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {Subject} from "rxjs";
import {TreeConfig, TreeNode} from "INTERFACES_PATH/tree.interface";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {Field} from "INTERFACES_PATH/field.interface";
import * as dayjs from "dayjs";
import {StateParams} from "@uirouter/core";
import {AdditionalItem, CustomValidation, FieldExpander, OperatonData} from "INTERFACES_PATH/validation.interface";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {AbstractControl, ValidatorFn} from "@angular/forms";
import {FieldDataType} from "ENUMS_PATH/field.enum";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {
    TodoBackendService,
    TodoEnvironmentService,
    TodoFormHelper,
    TodoState,
    TodoTreeAddonService
} from "INTERFACES_PATH/any.types";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {RightGroupsAddonService} from "MODULES_PATH/form/services/form-builder/right-groups-addon.service";
import {UserAddonService} from "MODULES_PATH/form/services/form-builder/user-addon.service";

import * as customParseFormat from "dayjs/plugin/customParseFormat";
import {
    DmsGroupOrgObject,
    DmsOrganisationObject,
    DmsUserOrgObject
} from "INTERFACES_PATH/dms-organisation-object.interface";
import {QueryContainer} from "INTERFACES_PATH/query.interface";
import {WfOrganisationObject, WfUserOrgObject} from "INTERFACES_PATH/wf-organisation-object.interface";
import {Cell} from "MODULES_PATH/grid/interfaces/grid-cell-component.interface";

dayjs.extend(customParseFormat);

@Injectable({providedIn: "root"})
export class FormValidationService {

    private readonly translateFn: TranslateFnType;
    private readonly stateParams: StateParams;
    private renderer: Renderer2;

    // eslint-disable-next-line max-params
    constructor(private messageService: MessageService,
                @Inject("$filter") private $filter: ng.IFilterService,
                @Inject("$stateParams") private $stateParams: StateParams,
                @Inject("$state") private state: TodoState,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("backendService") private backendService: TodoBackendService,
                private organisationService: OrganisationService,
                private rightGroupsAddonService: RightGroupsAddonService,
                @Inject("treeAddonService") protected treeAddonService: TodoTreeAddonService,
                private userAddonService: UserAddonService,
                private valueUtilsService: ValueUtilsService,
                private rendererFactory: RendererFactory2) {
        this.translateFn = this.$filter("translate");
        this.stateParams = $stateParams;
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    /**
     * Field validator to be used for new form inputs incorporating Angular Forms
     *
     * @param {FieldDataType} type
     * @return {ValidatorFn}
     */
    static fieldValidator(type: FormFieldType): ValidatorFn {
        // dates and choice are skipped so far, as a proper implementation would require some serious refactoring (e.g. interdependency with environmentService)
        if ([FormFieldType.DATE, FormFieldType.DATETIME, FormFieldType.TEXT, FormFieldType.CAPITAL, FormFieldType.CHOICE].includes(type)) {
            return null;
        }
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            switch (type) {
                case FormFieldType.DECIMAL:
                    return new RegExp(ValueUtilsService.decimalRegEx).test(control.value) ? null : {failed: FormFieldType.DECIMAL};
                case FormFieldType.NUMBER:
                    return new RegExp(FormValidationService.numberRegEx).test(control.value) ? null : {failed: FormFieldType.NUMBER};
                case FormFieldType.ALPHANUMERIC:
                    return new RegExp(FormValidationService.alphaNumRegEx).test(control.value) ? null : {failed: FormFieldType.ALPHANUMERIC};
                case FormFieldType.LETTER:
                    return new RegExp(FormValidationService.letterRegEx).test(control.value) ? null : {failed: FormFieldType.LETTER};
                case FormFieldType.TIME:
                    return new RegExp(FormValidationService.timeRegEx).test(control.value) ? null : {failed: FormFieldType.TIME};
            }
            return null;
        };
    }

    private isUniqueValidationInProgress: boolean = false;
    private uniqueValidationPromise: Promise<boolean>;

    static timeRegEx: string = "^(?:[0-1][0-9]|2[0-3]|[0-9])\\:[0-5][0-9](?:\\:[0-5][0-9])?$";
    static letterRegEx: string = "^[a-zA-Z\\s-_\\%?*\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df\u00C0-\u2000\u2C00-\u2DE0\u2E80-\uD800\uD80C-\uDE7F]+$";
    static numberRegEx: string = "^-?[0-9]+$";
    static alphaNumRegEx: string = "^[0-9\\?\\*\\-\\;]+$";

    setInvalid(field: Field, message?: string, title?: string, isGridCell?: boolean): void {
        if (field.model?.control && !field.model.addon) {
            // The control should already have set the field to be invalid, but let's trigger it just in case
            field.model.control.markAsDirty();
            field.model.control.setErrors({customError: {title, message}});
            field.isValid = false;
            return;
        }
        const input: HTMLElement | Field = isGridCell ? field : this.getInput(field);
        const el: ElementRef = new ElementRef(input);
        if (!isGridCell) {
            field.isValid = false;
            this.renderer.addClass(el.nativeElement.closest(".form-element"), "invalid");
        } else {
            this.renderer.addClass(input, "invalid");
            this.renderer.addClass(el.nativeElement.closest(".form-row-input-container"), "invalid");
        }

        this.messageService.broadcast("bind-validation-bubble", {
            input,
            title,
            msg: message
        });
    }

    setValid(field: Field, isGridCell?: boolean): void {
        if (field.model?.control && !field.model.addon) {
            // The control should already have set the field to be invalid, but let's trigger it just in case
            field.model.control.markAsDirty();
            field.model.control.setErrors(null);
            field.isValid = true;
            return;
        }
        const input: HTMLElement | Field = isGridCell ? field : this.getInput(field);
        const el: ElementRef = new ElementRef(input);
        if (!isGridCell) {
            field.isValid = true;
            this.renderer.removeClass(el.nativeElement.closest(".form-element"), "invalid");
        } else {
            this.renderer.removeClass(input, "invalid");
        }

        this.messageService.broadcast("unbind-validation-bubble", input);
    }

    isValid(field: Field, isGridCell?: boolean): boolean {
        if (!isGridCell) {
            return (field.isValid == void 0 || field.isValid == true);
        }

        return field.isValid != false;
    }

    getInput(field: Field): HTMLElement {
        const element: HTMLElement = field.api.getElement();
        const el: ElementRef = new ElementRef(element[0]);
        let input: HTMLElement;

        if (field.isGridCell) {
            input = el.nativeElement.querySelector(".ag-cell");
        } else {
            const selectedElement: HTMLElement = el.nativeElement.querySelector("input, textarea, .checkbox");
            input = selectedElement == null ? el.nativeElement : selectedElement;
        }

        return input;
    }

    getSyncedOperations(field: Field): OperatonData[] {
        const operations: OperatonData[] = [];
        const model: FieldModel = field.model;
        const controlsWithoutValidation: string[] = ["label", "group", "button", "pageCtrl"];

        if (controlsWithoutValidation.includes(model.type)) {
            return operations;
        }

        if (model.isRequired) {
            operations.push({
                fn: (...args) => this.validateRequired(...args),
                msg: this.translateFn("eob.validation.required.message"),
                title: this.translateFn("eob.validation.required.title")
            });
        }

        if (model.hasRegEx) {
            operations.push({
                fn: (...args) => this.validateRegEx(...args),
                msg: this.translateFn("eob.validation.regex.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        }

        if (model.addon == "tree" && !field.model.hasPseudoCatalog) {
            operations.push({ // @ts-ignore - ...args can't be resolved in the ide
                fn: (...args) => this.validateTree(...args),
                msg: this.translateFn("eob.validation.tree.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        }

        if (model.addon == "hierarchy" && !field.model.hasPseudoCatalog) {
            operations.push({ // @ts-ignore - ...args can't be resolved in the ide
                fn: (...args) => this.validateTree(...args),
                msg: this.translateFn("eob.validation.hierarchy.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        }

        if (model.addon == "list" && !field.model.hasPseudoCatalog) {
            operations.push({ // @ts-ignore - ...args can't be resolved in the ide
                fn: (...args) => this.validateList(...args),
                msg: this.translateFn("eob.validation.list.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        }

        if (model.type == "number") {
            operations.push({
                fn: (...args) => this.validateNumber(...args),
                msg: this.translateFn("eob.validation.number.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        } else if (model.type == "decimal") {
            operations.push({
                fn: (...args) => this.validateDecimal(...args),
                msg: this.translateFn("eob.validation.decimal.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        } else if (model.type == "alphanum") {
            operations.push({
                fn: (...args) => this.validateAlphaNumerical(...args),
                msg: this.translateFn("eob.validation.number.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        } else if (model.type == "letter") {
            operations.push({
                fn: (...args) => this.validateLetter(...args),
                msg: this.translateFn("eob.validation.letter.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        } else if (model.type == "date") {
            operations.push({
                fn: (...args) => this.validateDate(...args),
                msg: this.translateFn("eob.validation.date.message"),
                title: this.translateFn("eob.validation.date.title")
            });
        } else if (model.type == "datetime") {
            operations.push({
                fn: (...args) => this.validateDatetime(...args),
                msg: this.translateFn("eob.validation.date.message"),
                title: this.translateFn("eob.validation.date.title")
            });
        } else if (model.type == "time") {
            operations.push({
                fn: (...args) => this.validateTime(...args),
                msg: this.translateFn("eob.validation.time.message"),
                title: this.translateFn("eob.validation.time.title")
            });
        } else if (model.type == "choice") {
            let validationMessage: string = "";
            switch (field.model.subType) {
                case "P":
                    validationMessage = this.translateFn("eob.validation.choice.patient.message");
                    break;
                case "Q":
                    validationMessage = this.translateFn("eob.validation.choice.question.message");
                    break;
                case "S":
                    const enableDivers: boolean = this.environmentService.featureSet.contains("objectdefinition.form.field.type.divers");
                    validationMessage = this.translateFn(enableDivers ? "eob.validation.choice.sex.divers.message" : "eob.validation.choice.sex.message");
                    break;
                case "T":
                    validationMessage = this.translateFn("eob.validation.choice.side.message");
                    break;
            }

            operations.push({
                fn: (...args) => this.validateChoice(...args),
                msg: validationMessage,
                title: this.translateFn("eob.validation.general.title")
            });
        }

        // we need to exclude choice fields from the field length validation because
        // the original value is just one char, but the appconnector/server accepts the full value as well
        // and converts it back to one char
        if (model.type != "choice" && model.type != "grid") {
            operations.push({
                fn: (...args) => this.validateFieldLength(...args),
                msg: this.translateFn("eob.validation.max.length.message").replace("{maxlength}", field.model.maxLength ? field.model.maxLength.toString() : "null"),
                title: this.translateFn("eob.validation.max.length.title")
            });
        }

        if (model.addon == "user") {
            let message: string = this.translateFn("eob.validation.useraddon.unknown.user.or.group.message");
            if (model.config.showUsers && !model.config.showGroups) {
                message = this.translateFn("eob.validation.useraddon.unknown.user.message");
            }
            if (!model.config.showUsers && model.config.showGroups) {
                message = this.translateFn("eob.validation.useraddon.unknown.group.message");
            }
            operations.push({
                fn: (...args) => this.validateUserAddon(...args),
                msg: message,
                title: this.translateFn("eob.validation.general.title")
            });
        } else if (model.addon?.includes("rightGroups")) { // for "rightGroups" and "rightGroupsOld"
            operations.push({
                fn: (...args) => this.validateRightGroups(...args),
                msg: this.translateFn("eob.validation.useraddon.unknown.user.or.group.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        } else if (model.addon == "organisation") {
            operations.push({
                fn: (...args) => this.validateOrgAddon(...args),
                msg: this.translateFn("eob.validation.useraddon.limit.exceeded.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        }

        if (field.customValidations != void 0 && field.customValidations.length > 0) {
            for (const i in field.customValidations) {
                const op: CustomValidation = field.customValidations[i];

                if (!op.isAsync) {
                    operations.push(op);
                }
            }
        }

        return operations;
    }

    getAsyncedOperations(field: Field): OperatonData[] {
        const operations: OperatonData[] = [];

        if (field.model.isUnique) {
            operations.push({
                fn: (...args) => this.validateUnique(...args),
                msg: this.translateFn("eob.validation.unique.message"),
                title: this.translateFn("eob.validation.general.title")
            });
        }

        if (field.model.hasPseudoCatalog) {

            let msg: string = "";
            switch (field.model.pseudoCatalogDefinition.type) {
                case "list":
                    msg = this.translateFn("eob.validation.list.message");
                    break;
                case "hierarchy":
                    msg = this.translateFn("eob.validation.hierarchy.message");
                    break;
                case "tree":
                    msg = this.translateFn("eob.validation.tree.message");
                    break;
            }

            operations.push({ // @ts-ignore - ...args can't be resolved in the ide
                fn: (...args) => this.validatePseudoCatalog(...args),
                msg,
                title: this.translateFn("eob.validation.general.title")
            });
        }

        if (field.model.type == "grid") {
            operations.push({fn: (...args) => this.validateGrid(...args)});
        }

        for (const j in field.customValidations) {
            const op: CustomValidation = field.customValidations[j];

            if (op.isAsync) {
                operations.push(op);
            }
        }

        return operations;
    }

    validatePseudoCatalog(field: Field, formHelper: TodoFormHelper): Promise<boolean> {
        const subject: Subject<boolean> = new Subject();
        const promise: Promise<boolean> = subject.toPromise();

        if (field.value === "") {
            subject.next(true);
            subject.complete();
        } else {
            // pseudoCatalogDataCallback parameters are different in normal text fields and grid cells
            const fieldOrCell: Cell | Field = (field.model.cell != void 0) ? { colIndex: field.model.cell.colIndex, rowIndex: field.model.cell.rowIndex } : field;
            const fieldOrRowValue: string[] | string = (field.model.cell != void 0) ? field.model.grid.api.getRowByIndex(field.model.cell.rowIndex) : field.value;

            field.model.pseudoCatalogDataCallback(fieldOrCell, fieldOrRowValue, (entries) => {
                const nodes: TreeNode[] = JSON.parse(JSON.stringify(entries));
                const config: TreeConfig = field.model.pseudoCatalogDefinition.config;

                this.treeAddonService.removeDeprecatedStarEntries(nodes, formHelper.isEdit, (config.intermediate || config.useIntermediateNodes));
                this.treeAddonService.setDefaultShortValues(nodes);

                let values: string[] = config.useMultiSelect ? field.value.split(config.multiselectionSeparator) : [field.value];

                if (field.model.cell != void 0) {
                    values = this.removeEmptyEntries(values);
                }

                switch (field.model.addon) {
                    case "list":
                        subject.next(this.isValidListEntry(values, nodes));
                        subject.complete();
                        break;
                    case "tree":
                    case "hierarchy":
                        let isValid: boolean = true;

                        config.shortValue = this.treeAddonService.hasShortValues(nodes);

                        this.treeAddonService.createParentNodes(nodes);

                        for (const value of values) {
                            if (!this.validateRecursive(value, field.model.addon, nodes, config)) {
                                isValid = false;
                            }
                        }

                        subject.next(isValid);
                        subject.complete();
                        break;
                }
            });
        }

        return promise;
    }

    validateOrgAddon(field?: FieldExpander): boolean {
        let values: string[] = field.value != void 0 ? field.value.toLowerCase().split(";") : [];
        values = values.filter(v => v?.trim().length > 0);

        const orgMember: WfOrganisationObject[] = Object.values(field.model.config.orgMember);
        return values.every(item => orgMember.find(cMember =>
            cMember.name.toLowerCase() == item
            || (cMember as WfUserOrgObject).nachname?.toLowerCase() == item
            || (cMember as WfUserOrgObject).vorname?.toLowerCase() == item));
    }

    validateRightGroups(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        const filteredItems: AdditionalItem[] = this.rightGroupsAddonService.getUserAndGroups(field.model.config) as unknown as AdditionalItem[];
        const filteredUserItems: AdditionalItem[] = filteredItems.filter(entry => entry.type === "user");
        const filteredGroupItems: AdditionalItem[] = filteredItems.filter(entry => entry.type === "group");

        const valueParts: string[] = field.value.split(";").filter(v => v?.trim().length > 0).map(v => v.toUpperCase());
        for (const valuePart of valueParts) {
            // valuePart doesn't look like "NAME(U)" or "NAME(G)" or is not part of the valid org objects
            if (!/(.*)(\([UG]\))$/g.test(valuePart) ||
                (!(RegExp.$2 === "(U)" && filteredUserItems.find(entry => entry.name.toUpperCase() === RegExp.$1)) &&
                    !(RegExp.$2 === "(G)" && filteredGroupItems.find(entry => entry.name.toUpperCase() === RegExp.$1)))) {
                return false;
            }
        }
        return true;
    }

    validateUserAddon(field?: Field): boolean {
        let values: string[] = (field.value != void 0) ? field.value.toLowerCase().split(";") : [];
        values = values.filter(v => v?.trim().length > 0);

        // multiple entries without the multiselect option result in a failure
        if (!field.model.config.multiSelect && values.length > 1) {
            return false;
        }

        // fetching all users, groups and additional entries
        const items: DmsOrganisationObject[] = this.userAddonService.getUserAndGroupsByConfig(field.model.config);
        items.forEach(item => {
            if (item.type === "entry") {
                (item as AdditionalItem).valid = true;
            }
        });

        const initialContent: string[] = (field.initialValue == void 0 || field.initialValue == "") ? [] : field.initialValue.toLowerCase().split(";");

        // the goal is to splice each entry from the new content
        // if at the end, there is one entry left, the validation will fail
        for (const item of items) {
            const name: string = item.name.toLowerCase();
            const fullname: string = item.type == "group" ? (item as DmsGroupOrgObject).description.toLowerCase() : (item as DmsUserOrgObject).fullname.toLowerCase();

            const nameIndex: number = values.indexOf(name);
            const fullnameIndex: number = values.indexOf(fullname);
            const index: number = nameIndex != -1 ? nameIndex : fullnameIndex != -1 ? fullnameIndex : -1;

            if (index != -1) {
                let isValid: boolean = false;

                if (item.type == "group" || ((item as AdditionalItem).locked != 1 && (item as AdditionalItem).valid)) {
                    isValid = true;
                } else if (field.showAllUsers) {
                    isValid = true;
                } else if (initialContent.includes(name) || initialContent.includes(fullname)) {
                    isValid = true;
                }

                if (isValid) {
                    values.splice(index, 1);
                } else {
                    return false;
                }
            }
        }
        return values.length == 0;
    }

    removeEmptyEntries(array: string[]): string[] {
        for (let i = 0; i < array.length; i++) {
            if (array[i] == void 0) {
                array.splice(i, 1);
                i--;
                continue;
            }

            const value: string = array[i].trim();

            if (value === "") {
                array.splice(i, 1);
                i--;
            }
        }

        return array;
    }

    validateGrid(field?: Field): Promise<boolean> {
        let resolveFn: (value?: boolean | PromiseLike<boolean>) => void;
        const promise: Promise<boolean> = new Promise((resolve) => {
            resolveFn = resolve;
        });
        const columns: any[] = field.model.columns;
        const rows: any[] = field.api.getRows();
        const promises: boolean[] = [];

        if (rows.length > 0) {
            for (const i in rows) {
                for (const j in columns) {
                    promises.push(field.api.validateCell(i, j));
                }
            }
        } else {
            // we need to check on empty grid whether it has any required columns
            columns.filter(col => {
                if (col.isRequired) {
                    promises.push(field.api.validateCell(0, columns.indexOf(col)));
                }
            });
        }

        Promise.all(promises).then(() => {
            field.isValid = true;
            resolveFn(true);
            return;
        }, () => {
            field.isValid = false;
            resolveFn(false);
        }).catch(() => {
        });

        return promise;
    }

    /**
     * Validate the uniqueness of a group of form fields.
     *
     * @param {FormField} formField - One of the unique fields, that shall be validated.
     * @param {TodoFormHelper=} formHelper - The parameter is only necessary as a convenience placeholder, since the 'asyncValidate' function calls every validation function with field and formHelper.
     * @param {boolean=false} keepValidState - Don't set any of the unique fields valid/invalid. This is necessary, if the fields validation is depending on more than this function.
     * @returns {promise} The validation promise. It is rejected if the fields are invalid and resolved otherwise.
     */
    validateUnique(formField?: Field, formHelper?: TodoFormHelper, keepValidState?: boolean): Promise<boolean> {
        let resolveFn: (value?: boolean | PromiseLike<boolean>) => void;

        if (this.state.current.name == "create" && this.stateParams.mode == "variants") {
            return Promise.resolve(true);
        }

        // set the var to true because there might be an occasion when
        // we are calling this function multiple times but we dont want to
        // start multiple get requests because one request catches all unique fields
        if (!this.isUniqueValidationInProgress) {
            this.isUniqueValidationInProgress = true;
            this.uniqueValidationPromise = new Promise((resolve) => {
                resolveFn = resolve;
            });
        } else {
            return this.uniqueValidationPromise;
        }

        const objectTypeId: string = this.stateParams.objectTypeId;
        const collection: Field[] = formField.model.uniqueFields;
        const query: QueryContainer = {
            query: {
                objectTypeId,
                fields: {},
                result_config: {
                    fieldsschema: [{
                        dbName: "id",
                        sort_order: "ASC",
                        sort_pos: 1
                    }],
                    pagesize: 5,
                    offset: 0,
                    maxhits: 5,
                    deny_empty: false,
                    normalize_values: false
                }
            }
        };

        for (const item of collection) {
            const value: string = item.value.toString().toLowerCase();
            query.query.fields[item.model.name] = {
                value,
                internalName: item.model.internal
            };
        }

        this.backendService.post("/documents/search?pagesize=5&fieldsschema=DEF", query).then((response) => {
            this.isUniqueValidationInProgress = false;

            let result: boolean = false;
            if (response.data.length == 0) {
                result = true;
            } else if (response.data.length === 1) {
                result = (this.stateParams.osid == response.data[0].osid);
            }
            resolveFn(result);

            if (!keepValidState) {
                if (result) {
                    for (const uniqueField of formField.model.uniqueFields) {
                        this.setValid(uniqueField);
                    }
                } else {
                    for (const uniqueField of formField.model.uniqueFields) {
                        this.setInvalid(uniqueField, this.translateFn("eob.validation.unique.message"), this.translateFn("eob.validation.general.title"));
                    }
                }
            }

            return;
        }).catch(() => {
            this.isUniqueValidationInProgress = false;
            resolveFn(false);
        });

        return this.uniqueValidationPromise;
    }

    validateChoice(field?: Field): boolean {
        if (field.value == void 0 || (field.value == "" && typeof (field.value) == "string")) {
            return true;
        }

        const cmpValue: string = field.value.toString().trim().toLowerCase();
        if (cmpValue == "") {
            return false;
        }

        // Note that the strings in the arrays are NOT localized.
        // That is: no matter what language has been chosen, valid values are German, only.
        // Same goes for Rich-Client.
        switch (field.model.subType.toString()) {
            case "P":
                return ["a", "f", "s", "ambulant", "fremdpatient", "station"].includes(cmpValue);
            case "Q":
                return ["j", "n", "ja", "nein"].includes(cmpValue);
            case "S":
                const validValues: string[] = ["m", "w", "m\u00E4nnlich", "weiblich"];

                if (this.environmentService.featureSet.contains("objectdefinition.form.field.type.divers")) {
                    validValues.push("d");
                    validValues.push("divers");
                }

                return validValues.includes(cmpValue);
            case "T":
                return ["l", "r", "links", "rechts"].includes(cmpValue);
        }

        return true;
    }

    validateDate(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        return this.isValidDate(field.value);
    }

    validateDatetime(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        const strValue: string = field.value.toString();
        const isIso: boolean = (strValue.indexOf("T") > 0);
        if (isIso) {
            const date: dayjs.Dayjs = dayjs(strValue, ["YYYY", "YYYY-MM-DD", "YYYY-MM-DDTHH:mm", "YYYY-MM-DDTHH:mm:ss"], true);
            return date.isValid() && date.valueOf() > -6626970000000;
        } else {
            const parts: string[] = strValue.split(" ");
            const datePart: string = parts[0];
            const timePart: string = parts[1];
            const dateIsValid: boolean = this.isValidDate(datePart);
            const timeIsValid: boolean = timePart?.length > 0 ? this.isValidTime(timePart) : true;
            return dateIsValid && timeIsValid;
        }
    }

    validateTime(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        return this.isValidTime(field.value);
    }

    isValidDate(dateString: string): boolean {
        dateString = dateString.toString();
        dateString = this.valueUtilsService.dateToLocaleDate(dateString);

        const date = dayjs(dateString, this.environmentService.env.dateFormat.date, true);
        // 01.01.1760 - server can't save dates older than 1753;
        return date.isValid() && date.valueOf() > -6626970000000;
    }

    isValidTime(time: string): boolean {
        return new RegExp(FormValidationService.timeRegEx).test(time);
    }

    validateLetter(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        // validates german umlauts, letters from a - z and  \?%_- and a space /validate all languages
        return new RegExp(FormValidationService.letterRegEx).test(field.value);
    }

    validateNumber(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        return new RegExp(FormValidationService.numberRegEx).test(field.value);
    }

    validateAlphaNumerical(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        return new RegExp(FormValidationService.alphaNumRegEx).test(field.value);
    }

    validateDecimal(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        return this.valueUtilsService.isDecimalValue(field.value);
    }

    validateFieldLength(field?: Field): boolean {
        if (field.model.maxLength == 0) {
            return true;
        }

        const value: string = field.value != void 0 ? field.value : "";
        let valLength: number = value.toString().length;

        // the dot does not count ...
        if (field.model.type == "decimal" || field.model.type == "number") {
            valLength = value.toString().replace(".", "").replace(/^-/, "").length;
        }

        return valLength <= field.model.maxLength;
    }

    isValidListEntry(values: string[], list: TreeNode[]): boolean {

        if (!Array.isArray(values)) {
            values = [values];
        }
        values.forEach((val, idx) => values[idx] = val.toLowerCase());

        const isShortValue: boolean = this.treeAddonService.hasShortValues(list);

        for (const item of list) {
            const val: string = isShortValue ? item.short.toLowerCase() : item.value.toLowerCase();
            if (values.includes(val)) {
                values.splice(values.indexOf(val), 1);
            }
        }

        return values.length == 0;
    }

    validateList(field: Field, formHelper: TodoFormHelper): boolean {
        if (field.value == void 0 || field.value === "" || field.model.tree.config.validate == false) {
            return true;
        }

        const tmpList: TreeNode[] = field.api != void 0 ? field.api.getListEntries() : field.model.tree.nodes;
        const nodes: TreeNode[] = JSON.parse(JSON.stringify(tmpList));
        this.treeAddonService.removeDeprecatedStarEntries(nodes, formHelper.isEdit);

        const useMultiSelect: boolean = field.model.tree.config.useMultiSelect;
        let values: string[];

        if (useMultiSelect) {
            if (field.value.includes("+")) {
                values = field.value.split("+");
            } else {
                values = field.value.split(";");
            }
        } else {
            values = [field.value];
        }

        return this.isValidListEntry(values, nodes);
    }

    validateTree(field: Field, formHelper: TodoFormHelper): boolean {
        if (field.value == void 0 || field.value === "" || field.model.tree.config.validate == false) {
            return true;
        }

        const tmpNodes: TreeNode[] = field.api != void 0 ? field.api.getListEntries() : field.model.tree.nodes;

        const nodes: TreeNode[] = JSON.parse(JSON.stringify(tmpNodes));
        const config: TreeConfig = field.model.tree.config;

        this.treeAddonService.removeDeprecatedStarEntries(nodes, formHelper.isEdit, (config.intermediate || config.useIntermediateNodes));

        this.treeAddonService.createParentNodes(nodes);

        return this.validateRecursive(field.value, field.model.addon, nodes, config);
    }

    validateRequired(field?: Field): boolean {
        return field.value != void 0 && field.value != "";
    }

    validateRegEx(field?: Field): boolean {
        if (field.value == void 0 || field.value === "") {
            return true;
        }

        let regEx: string = field.model.regEx;

        // Wir betrachten die gesamte Zeichenkette wie der RichClient. Sollte der Administrator
        // bereits RegEx Start- und Endezeichen definiert haben, so ergänzen wir sie nicht.
        if (regEx.charAt(0) != "^") {
            regEx = `^${regEx}`;
        }

        if (regEx.charAt(regEx.length - 1) != "$") {
            regEx += "$";
        }

        return new RegExp(regEx).test(field.value);
    }

    // helper function
    // used for searching matches in recursive structures from catalogs
    validateRecursive(valueToFind: string, treetype: string, nodes: TreeNode[], config: TreeConfig): boolean {
        let match: boolean;

        valueToFind = valueToFind.toLowerCase();

        let hierarchicValueToFind: string;
        if (treetype == "hierarchy") {
            // we need the last value from the valueToFind --> the value to find looks at this point like this
            // demo|test|value --> so we get the last index of this string and compare this to the current node value
            const tmpValues: string[] = valueToFind.split(config.separator);

            // this is the real value we try to find
            hierarchicValueToFind = tmpValues[tmpValues.length - 1];
        }

        for (const i in nodes) {
            const node: TreeNode = nodes[i];

            // get the right value
            const value: string = config.shortValue ? node.short.toLowerCase() : node.value.toLowerCase();

            // if the tree is a hierarchy, we need to build the hierarchy value first with each parent
            if (treetype == "hierarchy") {
                // the current value matches with the tmpValue like above mentioned
                // this does not mean that we found the real match, we just found a match with the last hierarchy value
                // but because a hierachy value shows a path to the last index we need to know exactly
                if (hierarchicValueToFind == value) {
                    match = valueToFind == this.getHierarchyValue(node, config);

                    // the value found is matching but it can still be false if
                    // we are not allowed to use intermediate nodes and this node has children
                    if (match && !config.intermediate && node.nodes) {
                        match = false;
                    }
                }
            } else if (config.useIntermediateNodes || node.nodes == void 0) {
                match = value == valueToFind;
            }

            // found it and return
            if (match) {
                return match;
            } else if (node.nodes) {
                match = this.validateRecursive(valueToFind, treetype, node.nodes, config);

                // we found a matching child and return from this for loop
                if (match) {
                    return match;
                }
            }
        }

        return match;
    }

    // helper function
    // used to build the path string for hierarchy values
    getHierarchyValue(node: TreeNode, config: TreeConfig): string {
        const values: string[] = [];

        while (node) {
            const value: string = config.shortValue ? node.short.toLowerCase() : node.value.toLowerCase();

            //  insert the current value at the first index, because we start here from the last leave
            //  in the tree
            values.splice(0, 0, value);

            node = node.parent;
        }

        return values.join(config.separator);
    }
}
