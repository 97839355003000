import {NgModule, Type} from "@angular/core";

import {IconModule} from "MODULES_PATH/icon/icon.module";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {TranslationPipe} from "SHARED_PATH/pipes/translate.pipe";
import {EobDropdownComponent} from "SHARED_PATH/components/eob-dropdown/eob-dropdown.component";
import {windowProviders} from "./tokens/window.providers";

const components: Array<Type<unknown>> = [
    EobDropdownComponent
];

const pipes: Array<Type<unknown>> = [
    TranslationPipe
];

@NgModule({
    declarations: [...components, ...pipes],
    providers: [...windowProviders],
    imports: [CommonModule, FormsModule, IconModule],
    exports: [...components, ...pipes, IconModule],
})
export class SharedModule {
}
