import {forkJoin, Observable, Subscriber} from "rxjs";
import {AttachmentObject} from "INTERFACES_PATH/attachement-object.interface";
import {ProcessedRenditionInfoData} from "INTERFACES_PATH/rendition-info-data.interfaces";
import {FileObject} from "INTERFACES_PATH/file-object.interface";
import {ToolService} from "CORE_PATH/services/utils/tool.service";

/**
 * Container for multiple {@link FileObject}
 */
export class ContentObject<T = ArrayBuffer> {
    [p: string]: any

    attachments: Array<AttachmentObject<T>> | null = null;
    info: ProcessedRenditionInfoData | null = null;
    files: Array<FileObject<T>> = [];

    /**
     * Converts the given object to a IndexedDB-friendy version, as ArrayBuffer persistance
     * on mobile devices works poorly and leads to crashing.
     * If the current device is considered to be a mobile device or Electron, the {@link FileObject} instances get replaced
     * by instances having a {@link Blob} content.
     *
     * @param {ContentObject} obj
     * @returns {ContentObject<ArrayBuffer | Blob>}
     */
    static serialize(obj: ContentObject): ContentObject<ArrayBuffer | Blob> {
        // browserMeta.mobile returned false on an Android tablet - rather defeats its purpose, eh?
        if (/iOS|iPad|iPhone|iPod|Android|Electron/gi.test(navigator.userAgent)) {
            const co: ContentObject<Blob> = new ContentObject<Blob>();
            co.info = obj.info;
            if (obj.attachments) {
                co.attachments = [] as Array<AttachmentObject<Blob>>;
            }
            // For the sake of simplicity, for other cases than ArrayBuffer inputs, we just return the original object (garbage in, garbage out)
            const bufferToBlob: (content: any) => Blob = content => content instanceof ArrayBuffer ? new Blob([new Uint8Array(content)]) : content;

            for (const file of obj.files) {
                const fo: FileObject<Blob> = {} as FileObject<Blob>;
                fo.name = ToolService.nameToFilename(file.name);
                fo.content = bufferToBlob(file.content);
                fo.extension = file.extension;
                co.files.push(fo);
            }
            for (const attachment of obj.attachments || []) {
                const ao: AttachmentObject<Blob> = {} as AttachmentObject<Blob>;
                if (attachment.content) {
                    ao.content = bufferToBlob(attachment.content);
                    ao.previewImage = bufferToBlob(attachment.previewImage);
                    ao.info = attachment.info;
                    co.attachments.push(ao);
                }
            }
            return co;
        } else {
            return obj;
        }
    }

    /**
     * Converts persisted {@link ContentObject} instances read from the database.
     * If the webclient is running on a mobile device, the {@link FileObject} instances have to be
     * converted to contain an {@link ArrayBuffer}, otherwise the original object is returned.
     *
     * @param {ContentObject} obj
     * @returns {Promise<ContentObject>}
     */
    static async parse(obj: ContentObject<any>): Promise<ContentObject<any>> {
        /**
         * Convenience function to replace content properties with ArrayBuffer, if applicable
         *
         * @param obj Any object
         * @param {string} property The property to replace
         * @returns {Observable<void>}
         */
        const blobToBuffer: (obj: any, property: string) => Observable<void> = (obj: any, property: string): Observable<void> => new Observable((subscriber: Subscriber<void>) => {
                // Catch non-applicable parameters
                if (!obj || !(obj[property] instanceof Blob)) {
                    subscriber.complete();
                    return;
                }
                const fileReader: FileReader = new FileReader();
                fileReader.onload = () => {
                    obj[property] = fileReader.result as ArrayBuffer;
                    subscriber.complete();
                };
                fileReader.readAsArrayBuffer(obj[property]);
            });
        if (!obj) {
            return obj;
        }
        const contentBlobs: Array<Observable<void>> = [];
        const co: ContentObject = new ContentObject();
        co.attachments = obj.attachments;
        co.info = obj.info;
        co.files = obj.files;

        for (const attachment of (co.attachments || [])) {
            if (attachment.previewImage instanceof Blob) {
                contentBlobs.push(blobToBuffer(attachment, "previewImage"));
            }
            if (attachment.content instanceof Blob) {
                contentBlobs.push(blobToBuffer(attachment, "content"));
            }
        }

        for (const file of co.files || []) {
            if (file.content instanceof Blob) {
                contentBlobs.push(blobToBuffer(file, "content"));
            }
        }

        await forkJoin(contentBlobs).toPromise();
        return co;
    }
}
