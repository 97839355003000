(function () {
    "use strict";

    require("SERVICES_PATH/mobile-desktop/eob.touch.handler.srv.js");

    angular.module("eob.framework").directive("eobTouchEventBinder", EobTouchEventBinder);

    EobTouchEventBinder.$inject = ["touchHandlerService"];

    function EobTouchEventBinder(TouchHandlerService) {
        return {
            restrict: "A",
            scope: false,
            link(scope, element, attrs) {
                TouchHandlerService.bindTouchEvents(element);
            }
        };
    }
})();