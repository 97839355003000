import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {Subject, Subscription} from "rxjs";
import {throttleTime} from "rxjs/operators";

export interface DashletKebabMenuItem {
    name: string;
    icon: string;
    title: string;
}

@Component({
    selector: "eob-dashlet-kebab-menu",
    templateUrl: "./eob-dashlet-kebab-menu.component.html",
    styleUrls: ["./eob-dashlet-kebab-menu.component.scss"]
})
export class EobDashletKebabMenuComponent implements OnInit, OnDestroy {
    @Input() menuItems: DashletKebabMenuItem[];
    @Input() activeContainerDashlet: string;

    activeCustomDashletName: string = null;
    dropdownOpen: boolean = false;
    menuToggler: Subject<void> = new Subject<void>();
    subscriber: Subscription = new Subscription();

    constructor(private messageService: MessageService, private el: ElementRef, private ngZone: NgZone) {
    }

    ngOnInit(): void {
        this.subscriber.add(this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, () => {
            if (!this.activeContainerDashlet?.includes("customDashlet-")) {
                this.activeCustomDashletName = null;
            }

            if (this.dropdownOpen) {
                void this.toggleMenu();
            }
        }));
        // Since it's sometimes raining closing events, we need to throttle them a bit, as the menu would otherwise be closed before even becoming visible
        this.menuToggler.pipe(throttleTime(50)).subscribe(_ => this._toggleMenu());
    }

    ngOnDestroy(): void {
        this.subscriber.unsubscribe();
        this.menuToggler.complete();
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    async toggleMenu(): Promise<void> {
        this.ngZone.run(() => this.menuToggler.next());
    }

    goTo(item: DashletKebabMenuItem): void {
        this.activeCustomDashletName = item.name;
        window.dispatchEvent(new CustomEvent("goto-custom-dashlet", {detail: item.name}));
    }

    private _toggleMenu(): void {
        this.dropdownOpen = !this.dropdownOpen;
    }
}
