import {Injectable} from "@angular/core";
import {
    AuthenticationData,
    AuthenticationProvider,
    AuthenticationState,
    AuthenticationStatusEvent
} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {EMPTY, Observable, of} from "rxjs";
import {AuthenticationModule} from "../authentication.module";
import {HttpHandler, HttpRequest} from "@angular/common/http";

@Injectable({
    providedIn: AuthenticationModule
})
export class NoneAuthService implements AuthenticationProvider {
    isAuthenticated(): Observable<boolean> {
        return of(true);
    }

    authenticate(backendOrigin: string, credentials?: AuthenticationData): Observable<AuthenticationStatusEvent> {
        return of({state: AuthenticationState.LOGGED_IN});
    }

    invalidateSession(): Observable<AuthenticationStatusEvent> {
        return of({state: AuthenticationState.LOGGED_OUT});
    }

    getStatusEvent(): Observable<AuthenticationStatusEvent> {
        return EMPTY;
    }

    handleReauthenticationRequest(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req);
    }
}
