angular.module("eob.core").factory("listApiService", ListApiService);

ListApiService.$inject = ["formCatalogParserService"];

/**
 * A service that creates a form pagecontrol element
 */
// eslint-disable-next-line max-params, require-jsdoc
export default function ListApiService(FormCatalogParserService) {

    return {
        addListApi
    };

    function addListApi(api, field) {
        /**
         * Sets custom list entries used by the tree / list
         *
         * @param {FormListEntry[]} list - An Array of entries
         */
        api.setCustomListEntries = function(list) {
            field.customEntries = FormCatalogParserService.parseListEntries(field, list);
        };

        api.getListEntries = function() {
            let list = [];

            // special case --> the db catalog does not have a tree
            // we are building it on the fly
            if (field.model.tree != void 0) {
                for (let i in field.model.tree.nodes) {
                    let entry = field.model.tree.nodes[i];

                    list.push(angular.copy(entry));
                }
            }

            for (let i in field.customEntries) {
                let entry = field.customEntries[i];

                if (typeof (entry) == "object" && entry.value != void 0) {
                    if (entry.short == void 0) {
                        entry.short = entry.value;
                    }

                    list.push(entry);
                } else if (typeof (entry) == "string" || typeof (entry) == "number") {
                    list.push({
                        value: entry,
                        short: entry
                    });
                } else {
                    console.warn("this is an invalid entry ", entry);
                }
            }

            return list;
        };
    }
}
