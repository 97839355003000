import {Inject, Injectable} from "@angular/core";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {SortService} from "CORE_PATH/services/utils/sort.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {InboxActionService} from "CORE_PATH/services/actions/inbox-action.service";
import {GridOptions, RowNode, ValueGetterParams, ValueSetterParams} from "ag-grid-community";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {StateParams} from "@uirouter/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {HitListApiService} from "MODULES_PATH/hitlist/services/hit-list-api.service";
import {ColumnDefinition} from "MODULES_PATH/grid/interfaces/column.interface";
import {ContextData, HitlistConfig} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {TodoCacheManagerService, TodoModalDialogService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {HitListStateConfig} from "MODULES_PATH/hitlist/interfaces/hit-list-state-config.interface";
import {IdPair} from "INTERFACES_PATH/id-pair.interface";

@Injectable({
    providedIn: "root"
})
export class HitListService {
    private readonly translateFn: TranslateFnType;

    private isPhone: boolean = this.clientService.isPhone();
    private useTouchLayout: boolean = this.layoutManagerService.isTouchLayoutActive();
    private cancelViewerRefresh: boolean = false;
    private touchHappened: boolean = false;
    private isLoading: boolean = false;
    private doubleClickTarget: RowNode;

    // eslint-disable-next-line max-params
    constructor(@Inject("$stateParams") protected $stateParams: StateParams,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("$rootScope") protected $rootScope: ng.IRootScopeService,
                @Inject("$location") private $location: ng.ILocationService,
                @Inject("cacheManagerService") protected cacheManagerService: TodoCacheManagerService,
                @Inject("stateHistoryManager") protected stateHistoryManager: TodoStateHistoryManager,
                @Inject("inboxActionService") protected inboxActionService: InboxActionService,
                @Inject("messageService") protected messageService: MessageService,
                protected valueUtilsService: ValueUtilsService,
                @Inject("modalDialogService") protected modalDialogService: TodoModalDialogService,
                // eslint-disable-next-line no-undef
                protected actionService: ActionService,
                protected viewerService: ViewerService,
                protected layoutManagerService: LayoutManagerService,
                private hitListApiService: HitListApiService,
                private objectTypeService: ObjectTypeService,
                protected clientService: ClientService,
                protected asIniService: AsIniService,
                protected sortService: SortService,
    ) {
        this.translateFn = this.$filter("translate");

        $rootScope.$on("annotationsChanged", () => {
            // we need to stop any viewer update that might happen after this because the hitlist content changes
            // otherwise an annotation that is being created will reset
            this.cancelViewerRefresh = true;
        });
    }

    getPlaceHolderString = (): string => {
        let placeholderString: string;

        switch (this.$stateParams.type) {
            case "revisit":
                placeholderString = "hitlist.emptyRevisitPlaceholder";
                break;
            case "abo":
                placeholderString = "hitlist.emptySubscriptionPlaceholder";
                break;
            case "subscriptionObjects":
                placeholderString = "hitlist.emptySubscriptionObjectsPlaceholder";
                break;
            case "workflow":
                placeholderString = "hitlist.emptyWorkflowPlaceholder";
                break;
            case "startable":
                placeholderString = "hitlist.emptyStartablePlaceholder";
                break;
            case "processes":
                placeholderString = "hitlist.emptyProcessesPlaceholder";
                break;
            default:
                placeholderString = "hitlist.emptyPlaceholder";
        }

        return placeholderString;
    };

    attachComparators = (columns: ColumnDefinition[]): void => {
        for (const col of columns) {
            // we need this because there is no gridoption event for contextmenu
            // on each row, but there is a contextmenu event on each cell, we can use
            // so we are adding the right click callback to each cell --> no watchers, no problems
            // col.onCellContextMenu = onRightClick;

            if (col) {
                col.valueGetter = this.valueGetter;
                col.valueSetter = this.valueSetter;
                col.headerValueGetter = this.headerValueGetter;
                if (!col.isIconCell) {
                    col.enableRowGroup = !this.isPhone;
                    col.minWidth = 60;
                    if (this.clientService.isPhone()) {
                        col.getQuickFilterText = (param) => {
                            if (param.data.f1?.filterValue) {
                                return param.data.f1?.filterValue;
                            }
                            return param.value;
                        };
                    }
                } else {
                    col.getQuickFilterText = (param) => null;
                }

                if (col.cellRenderer == undefined) {
                    if (col.type == "DECIMAL") {
                        col.cellRenderer = (params) => params.data[params.colDef.field] ? this.valueUtilsService.numberToLocaleNumber(params.data[params.colDef.field].value, ".2-2") : "";
                    } else if (col.type && !this.clientService.isPhone()) {
                        // We need to distinguish between static and index data rows - the latter should be sanitized
                        col.cellRenderer = (params) => {
                            const value: string = params.data[params.colDef.field] ? params.data[params.colDef.field].value : "";
                            const cellContent: string = `<span class=cell-value>${value.replace(/</g, "&lt;").replace(/>/g, "&gt;")}</span>`;
                            return cellContent;
                        };
                    } else {
                        col.cellRenderer = (params) => {
                            const value: string = params.data[params.colDef.field] ? params.data[params.colDef.field].value : "";
                            const cellContent: string = `<span class=cell-value>${value}</span>`;
                            return cellContent;
                        };
                    }
                }

                // adding the sorting function
                if (!col.sortable && !col.comparator) {
                    switch (col.type) {
                        case "DECIMAL":
                        case "number":
                            col.comparator = this.sortService.sortByNumber;
                            col.colType = col.type;

                            break;
                        case "DATETIME":
                            col.comparator = this.sortService.sortByDatetime;
                            col.colType = col.type;

                            break;
                        default :
                            if (col.isIconCell) {
                                col.comparator = (a, b) => (a.icon || "").localeCompare(b.icon || "");
                            } else {
                                col.comparator = this.sortService.sortFieldValueByText;
                            }
                            break;
                    }
                }

                delete col.type;
            }
        }
    };

    valueSetter = (params?: ValueSetterParams): boolean => {
        params.data[params.colDef.field].value = params.newValue;
        return true;
    };

    valueGetter = (params?: ValueGetterParams): string => {
        // if (!params.data || (params.api && params.api.rowModel.rowsToDisplay.length == 0)) {
        if (!params.data) {
            return "";
        }

        if (!params.data[params.colDef.field]) {
            return "";
        }

        if (params.colDef.rowGroup) {
            return this.sortService.extractValue(params.data[params.colDef.field].value);
        } else {
            return params.data[params.colDef.field].value;
        }
    };

    headerValueGetter = (params?): string => {
        const currentRowIndex: string = params.colDef.field;

        if (params.api == void 0) {
            return "";
        }

        // well not very satisfying, but this gets it done
        // we were triggered that the selection changed and now we need to redraw the column headers
        // to achieve this we are using the lastSelectedRow which we stored one step before
        const row: RowNode = params.api.getSelectedNodes()[0];

        if (row == void 0) {
            if (params.colDef.isIconCell) {
                return params.colDef.headerName;
            } else if (params.api.rowModel.rowsToDisplay && params.api.rowModel.rowsToDisplay.length > 0) {

                // at this point we grouped a grid where no selection is active
                if (params.api.rowModel.rowsToDisplay[0].data == null) {
                    return params.colDef.headerName;
                }

                if (!this.clientService.isPhone()) {
                    return (params.api.rowModel.rowsToDisplay[0].data[currentRowIndex] || {}).headerName || "";
                }

            } else {
                return "";
            }
        } else {
            const hasNoRows: boolean = params.api.rowModel.rowsToDisplay.length === 0;
            const hasNoRowData: boolean = (row.data == null && row.group) || !row.data[currentRowIndex];

            if (hasNoRows || hasNoRowData) {
                return params.colDef.headerName;
            }

            return row.data[currentRowIndex].headerName || "";
        }
    };

    onRowClicked = (params: any, hitListConfig: HitlistConfig): void => {
        this.doubleClickTarget = params.node;

        if (!params.node.selected && !params.node.group) {
            this.updateViewer(params.data, hitListConfig);

            if (typeof (hitListConfig.onSelectionChange) == "function") {
                hitListConfig.onSelectionChange(params.data);
            }
        }

        setTimeout(() => {
            if (!params.node.group) {
                this.messageService.broadcast("REFRESH_GROUPING_TAGS", params);
            }
        }, 0);

        // workaround to stop the row click from triggering for a cell with a cell click action
        const target = params.event.target;

        if (this.isPhone && target.tagName == "EOB-ICON" && target.children[0].ariaLabel == "kebab-dark") {
            return;
        }

        if (this.useTouchLayout && !(/quickfinder|workflow|processes|startable|usermanagement/gi.test(params.data.context)) &&
            this.cacheManagerService.dmsDocuments.getById(params.data.osid).model.isDocument) {
            this.messageService.broadcast(ModalEvents.OPEN_MODAL_DASHLETS, params.data.osid);
        } else if ((this.useTouchLayout || (this.isPhone && !(/processes|startable/gi.test(params.data.context)))) && !/usermanagement/gi.test(params.data.context)) {
            this.onRowDoubleClicked(params);
        }
    };

    onRowDoubleClicked = (params: any): void => {
        if (this.doubleClickTarget != params.node) {
            return;
        }

        if ((!params.node.group) && (!("realMainType" in params.data && params.data.realMainType === void 0))) {
            const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(params.data.osid);
            const context: string = params.data.context;

            let actionItem: DmsDocument;
            let executeBeforeOpen: boolean = false;

            if (this.isPhone && ["processes", "startable"].includes(context)) {
                return; // no action in these states on phone
            } else if (dmsDocument == void 0 || ["workflow", "processes"].includes(context)) {
                actionItem = params.data;
            } else if (["revisit", "abo"].includes(context)) {
                const contextProperty: string = {revisit: "revisits", abo: "subscriptions"}[context];
                actionItem = dmsDocument.model[contextProperty].find(item => item.model.guid == params.data.guid);
            } else {
                actionItem = dmsDocument;
                executeBeforeOpen = actionItem.model.isRegister || actionItem.model.isFolder;
            }

            if (this.useTouchLayout) {
                if (!this.isLoading) {
                    this.isLoading = true;
                    this.actionService.executeDoubleClickAction(actionItem, context, executeBeforeOpen, "doubleclick");

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                }
            } else {
                this.actionService.executeDoubleClickAction(actionItem, context, executeBeforeOpen, "doubleclick");
            }
        }
    };

    onRightClick = (params: any, hitListConfig: HitlistConfig): void => {
        // this is needed to prevent ghost click triggering the hitlist rightclick event a second time
        if (this.useTouchLayout) {
            if (this.touchHappened) {
                return;
            } else {
                this.touchHappened = true;

                setTimeout((): void => {
                    this.touchHappened = false;
                }, 500);
            }
        }

        params.event.preventDefault();
        params.event.stopPropagation();
        params.event.stopImmediatePropagation();

        if (!params.node.selected) {
            params.node.setSelected(true, true);// first true sets the value , secons true clear the other selection

            if (params.node != void 0 && params.node.data != void 0) {
                this.updateViewer(params.node.data, hitListConfig);
            }

            if (typeof (hitListConfig.onSelectionChange) == "function") {
                hitListConfig.onSelectionChange(params.node.data);
            }
        }

        setTimeout(() => {
            this.messageService.broadcast("REFRESH_GROUPING_TAGS", params);
        }, 0);

        if (typeof (hitListConfig.onRightClick) == "function") {
            hitListConfig.onRightClick(params);
        } else {
            const items: any[] = [];

            params.api.forEachNodeAfterFilterAndSort((node) => {
                if (node.selected) {
                    items.push(node.data);
                }
            });

            let contextData: ContextData;

            if (hitListConfig.contextData) {
                contextData = Object.assign(hitListConfig.contextData, {title: items[0].f1?.title});
            } else {
                contextData = {
                    context: items[0].context,
                    title: items[0].f1?.title
                };
            }

            if (!(contextData.context == "quickfinder" || contextData.context == "usermanagement")) {
                this.messageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                    items,
                    event: params.event,
                    contextData
                });
            }
        }
    };

    updateViewer = (item: any, hitListConfig: HitlistConfig): void => {
        if(item.context == "quickfinder") {
            return;
        }
        this.viewerService.setFileMimeTypeGroup(item.fileMimeTypeGroup, item.mainType, item.isTypeless);

        if (hitListConfig.isModal || this.cancelViewerRefresh) {
            this.cancelViewerRefresh = false;
            return;
        }

        if (hitListConfig.suppressViewerUpdate != true && item != void 0 && item.osid != void 0) {
            this.viewerService.updateViewer(item.osid);
        } else {
            this.viewerService.clearViewer();
        }
    };

    saveSelection = (gridOptions: GridOptions, stateId: string, filterValue: string): void => {
        if (gridOptions.api == void 0) {
            return;
        }

        const newConfig: HitListStateConfig = {
            selectedItems: {},
            selectedDmsOsids: []
        };

        const rows: any[] = gridOptions.api.getSelectedRows();
        const nodes: RowNode[] = gridOptions.api.getSelectedNodes();

        if (rows && rows.length > 0 && rows[0] != void 0) {
            let index: number = 0;

            rows.forEach(row => {
                if (row.guid != void 0 && (row.context.indexOf("wfFileArea") < 0)) {
                    newConfig.selectedItems[row.guid] = nodes[index].rowIndex;
                } else if (row.id != void 0) {
                    newConfig.selectedItems[row.id] = nodes[index].rowIndex;
                }

                if (row.osid) {
                    newConfig.selectedDmsOsids.push(row.osid);
                }

                index++;
            });
        }

        this.viewerService.updateViewer(undefined, undefined, newConfig.selectedDmsOsids);
        this.stateHistoryManager.updateConfig(newConfig, stateId);
    };

    callWorkflowAction = (params: any, context: string): void => {
        if (this.touchHappened) {
            return;
        } else {
            this.touchHappened = true;

            setTimeout(() => {
                this.touchHappened = false;
            }, 500);
        }

        params.event.preventDefault();
        params.event.stopPropagation();
        params.event.stopImmediatePropagation();

        if (context == "startable") {
            this.inboxActionService.startWorkflow(params.data);
        } else if (context == "processes") {
            this.modalDialogService.openAssignPerformersDialogFromHitlist(params.data);
        }
    };

    /**
     * Opens a modal dialog to choose a permitted object type from. Then
     * the creation state is launched to create an object of that type.
     */
    createNewObject = async () => {
        const location: IdPair = {
            objectId: this.$location.search().currentId,
            objectTypeId: this.$location.search().currentTypeId
        };
        const locationConfig: ObjectTypeConfig = this.cacheManagerService.objectTypes.getById(location.objectTypeId).model.config;
        const insertableTypes: ObjectTypeConfig[] = locationConfig.insertableTypes
            .filter(typeId => this.cacheManagerService.objectTypes.contains(typeId))
            .map(typeId => this.cacheManagerService.objectTypes.getById(typeId).model.config)
            .filter(typeConfig => typeConfig != 0);

        for (const type of insertableTypes) {
            type.icon = this.objectTypeService.getIconClass(type.objectTypeId, type.iconId, true).replace(/-white$/gi, "");
        }

        const dialogModel: string = "<eob-modal-external-tray-object-types></eob-modal-external-tray-object-types>";
        const title: string = this.translateFn("eob.contextmenu.action.new.title");
        const objectTypeConfig: ObjectTypeConfig = await this.modalDialogService.createModalContainer(dialogModel, title, undefined, {types: insertableTypes});

        if (objectTypeConfig) {
            await this.actionService.createObject(location, objectTypeConfig.objectTypeId);
        }
    };

    removeDeletedItems = (removedIds: string[], gridOptions: GridOptions): void => {
        if (!Array.isArray(removedIds)) {
            removedIds = [removedIds];
        }

        if (removedIds.length == 0) {
            return;
        }

        // get visible grid rows
        const rows: RowNode[] = [];
        if (gridOptions.api != void 0) {
            gridOptions.api.forEachNodeAfterFilterAndSort((node: RowNode): void => {
                if (Number(node.id) >= 0) {
                    rows.push(node.data);
                }
            });
        }

        // update selection to a node near to the last removed node
        const remainingRowIds: string[] = [];
        let deletedRowState: number = 0;
        let rowToSelect: RowNode = null;

        for (const row of rows) {
            if (!removedIds.includes(row.id)) {
                remainingRowIds.push(row.id);

                if (deletedRowState < 2) {
                    rowToSelect = row;

                    if (deletedRowState == 1) {
                        deletedRowState = 2;
                    }
                }
            } else {
                deletedRowState = 1;
            }
        }

        if (remainingRowIds.length > 0) {
            this.hitListApiService.selectItems(gridOptions, [rowToSelect.id], "id");
        } else {
            this.viewerService.clearViewer();
        }
    };
}
