import {DmsDocumentApi} from "./dms-document-api";
import {DmsDocumentModel} from "./dms-document-model";

/**
 * The dms document is an enriched object with all properties needed to be used everywhere inside the client
 * It consists of a model with all properties
 *      rights - the current rights the user has for this document (after clauses)
 *      baseparameters - all baseparameters as key-value-pair
 *      fields - the requested fields of the document stored as key-value-pairs (internal name - value)
 *      name - the displayname of the field
 *      internal - the internal name of the field
 *      osid - the documents id
 *      id - redundant information about the id to ensure backward compatibility to old code
 *      objectTypeId - the objecttype id ...
 */
export class DmsDocument {
    /**
     * Model representation of the DMS document
     */
    model: DmsDocumentModel;
    /**
     * API functions to manipulate the given underlying DMS document instance
     */
    api: DmsDocumentApi;

    constructor(data: any, $injector: ng.auto.IInjectorService) {
        this.model = new DmsDocumentModel(data, $injector);
        this.api = new DmsDocumentApi(this.model, $injector);
    }
}