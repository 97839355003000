<div class="welcome-page">
    <div class="form-row-container">
        <div id="loadAnimation" class="osProgressbar" [hidden]="!profileManagerService.checkingLoginData">
            <div class="osloader"></div>
        </div>
        <span class="welcome-page__heading">{{'eob.login.first.welcome' | ngTranslate}}</span>
        <span class="welcome-page__what-to-do">{{'eob.login.first.whattodo' | ngTranslate}}</span>
        <div class="welcome-page__buttons">
            <button class="primary" (click)="profileManagerService.showForm()">{{'eob.login.login'| ngTranslate}}</button>
            <button class="primary" (click)="loginToDemo()">{{'eob.login.demo'| ngTranslate}}</button>
        </div>
    </div>
    <div class="welcome-page__cancel-button-container" [hidden]="!profileManagerService.checkingLoginData || !profileManagerService.showLoginForm">
        <button class="primary" type="button" (click)="abortLogin()">{{'modal.button.cancel' | ngTranslate}}</button>
    </div>
</div>
