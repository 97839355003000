import {Inject, Injectable} from "@angular/core";
import {RightGroupsConfig, QuickfinderConfig, OrganisationConfig, UserAddonConfig, DbCatalogConfig, ObjectType} from "MODULES_PATH/form/interfaces/form-addon-config.interface";
import {FieldAddon} from "ENUMS_PATH/field.enum";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {WfOrgAddonService} from "MODULES_PATH/form/services/form-builder/wf-org-addon.service";

@Injectable({
    providedIn: "root"
})
export class FormAddonConfigParserService {
    constructor(protected organisationService: OrganisationService,
                private wfOrgAddonService: WfOrgAddonService,
                @Inject("$injector") private $injector: angular.auto.IInjectorService) {
    }

    /**
     * getConfig
     *
     * @param field
     * @param addonType
     * @param isWorkflow
     */
    getConfig(field: any, addonType: FieldAddon, isWorkflow: boolean): RightGroupsConfig | QuickfinderConfig | OrganisationConfig | UserAddonConfig | DbCatalogConfig {
        switch (addonType) {
            case FieldAddon.RIGHTGROUPS:
            case FieldAddon.RIGHTGROUPSOLD:
                return this.getRightGroupsConfig(field);
            case FieldAddon.QUICKFINDER:
                return this.getQuickfinderConfig(field);
            case FieldAddon.ORGANIZATION:
                return this.getOrganisationConfig(field, isWorkflow);
            case FieldAddon.USER:
                return this.getUserAddonConfig(field);
            case FieldAddon.DB:
                return this.getDbCatalogConfig(field);
        }
    }

    /**
     * getDbCatalogConfig
     *
     * @param field
     */
    private getDbCatalogConfig(field: any): DbCatalogConfig {
        const config: DbCatalogConfig = {
            ignoreOtherFieldsInEdit: false
        };
        if (field.list && field.list.IGNOREOTHERFIELDINEDIT != void 0) {
            const val: string = field.list.IGNOREOTHERFIELDINEDIT.toLowerCase().trim();

            if (val == "ja" || val == "true") {
                config.ignoreOtherFieldsInEdit = true;
            }
        }

        return config;
    }

    /**
     * getUserAddonConfig
     *
     * @param field
     */
    private getUserAddonConfig(field: any): UserAddonConfig {
        const config: UserAddonConfig = {
            applyDescription: false,
            sortName: false,
            sortFullname: false,
            showGroups: false,
            showUsers: true,
            multiSelect: false,
            additionalEntries: [],
            canLock: 1
        };

        const rawValuesStr: string = field.list.rawdata.trim();
        // seems like an eslint bug, there's nothing wrong here
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const lastSubIndex: number = rawValuesStr.indexOf(field.name) + field.name.length + 2;

        // remove the unnecessary part and split at every line break
        const rawValues: string[] = rawValuesStr.substr(lastSubIndex).split("\n");

        for (const val of rawValues) {
            if (val.includes("EXTRA00")) {
                const flags: any = val.split(/=(.+)/)[1].split(",");

                // trimming spaces from each entry
                for (const i in flags) {
                    flags[i] = flags[i].trim();
                }

                const groups: string = flags.find(param => param === "GRUPPEN" || param === "GROUPS");
                const user: string = flags.find(param => param === "BENUTZER" || param === "USER");
                const multiselect: string = flags.find(param => param === "MULTISELEKTION" || param === "MULTISELECTION");

                if (groups) {
                    config.showGroups = true;
                }

                if (user) {
                    config.showUsers = true;
                }

                if (multiselect) {
                    config.multiSelect = true;
                }

                if (flags.indexOf("FULLUSERNAME") != -1) {
                    config.applyDescription = true;
                }

                if (flags.indexOf("SORTCOL1") != -1) {
                    config.sortName = true;
                }

                if (flags.indexOf("SORTCOL2") != -1) {
                    config.sortFullname = true;
                }
            }

            if (val.includes("EXTRA01")) {
                config.additionalEntries = val.split(/=(.+)/)[1].split(",");
            }

            if (val.includes("CANLOCK")) {
                config.canLock = parseInt(val.split(/=(.+)/)[1]);
            }
        }

        return config;
    }

    /**
     * getOrganisationConfig
     *
     * @param field
     * @param isWorkflow
     */
    private getOrganisationConfig(field: any, isWorkflow: boolean): OrganisationConfig {
        const config: OrganisationConfig = {
            display: "list",
            namingRule: "",
            showAll: false,
            member: [],
            canLock: 1,
            orgMember: []
        };

        const classIds: any = {
            "12AA95D1D8244E6BB56C70A8D5CEE675": "Rolle",
            "9AB24246BB9040A29FCD6015CF4F4BD9": "Person"
        };

        const rawValuesStr: string = field.list.rawdata.trim();
        // seems like an eslint bug, there's nothing wrong here
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const lastSubIndex: number = rawValuesStr.indexOf(field.name) + field.name.length + 2;
        const rawValues: string[] = rawValuesStr.substr(lastSubIndex).split("\n");

        for (const val of rawValues) {
            if (val.includes("EXTRA00")) {
                config.display = val.split(/=(.+)/)[1] == "0" ? "tree" : "list";
            } else if (val.includes("EXTRA01")) {
                config.member = val.split(/=(.+)/)[1].split(";");
            } else if (val.includes("EXTRA06")) {
                config.namingRule = val.split(/=(.+)/)[1];
            } else if (val.includes("CANLOCK")) {
                config.canLock = parseInt(val.split(/=(.+)/)[1]);
            }
        }
        const orgMember: any = this.organisationService.getWfOrgPerformer();

        for (let j = 0; j < config.member.length; j++) {
            const item: any = config.member[j];

            if (classIds[item] != void 0) {
                const key: any = classIds[item];

                for (const k in orgMember) {
                    if (orgMember[k].typ == key && !config.member.includes(orgMember[k].id)) {
                        config.member.push(orgMember[k].id);
                    }
                }

                config.member.splice(j, 1);
                j--;
            }
        }

        config.orgMember = this.wfOrgAddonService.getOrgList(config);

        if (field.maxLength > 0) {
            console.warn("Caution! The displayed users will be saved as guids and not as the String that is written in the field. Max Length should be set to at least 500");
        }

        return config;
    }

    /**
     * getQuickfinderConfig
     *
     * @param field
     */
    private getQuickfinderConfig(field: any): QuickfinderConfig {
        const config: QuickfinderConfig = {
            objectTypes: [],
            replaceValues: false,
            validate: false,
            canLock: 0
        };

        const rawValuesStr: string = field.list.rawdata.trim();
        // seems like an eslint bug, there's nothing wrong here
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const lastSubIndex: number = rawValuesStr.indexOf(field.name) + field.name.length + 2;

        // remove the unnecessary part and split at every line break
        const rawValues = rawValuesStr.substr(lastSubIndex).split("\n");

        const rawConf: any = {};

        // split the rawvalues into an object for lesser scripting overhead
        for (const j in rawValues) {
            const val: any = rawValues[j].split(/=(.+)/);
            rawConf[val[0]] = val[1];
        }

        // each object starts with this prefix and ends with an incrementing number
        // see "OS_Editor" documentation
        const objectPrefix: string = "OBJEKT0";
        const fieldPrefix: string = "FELD";
        let i: number = 0;

        if (!rawConf[objectPrefix + i.toString()]) {
            // console.debug("quickfinder addon found without configured objecttypes");
        }

        // collecting the objecttypes
        do {
            let type = "";
            const tmp: any = rawConf[objectPrefix + i.toString()];

            if (tmp != void 0 && tmp.charAt(0) == "%" && tmp.charAt(tmp.length - 1) == "%") {
                const internal: string = tmp.substr(1, tmp.length - 2);
                const cacheManagerService: any = this.$injector.get("cacheManagerService");
                const quickfinderObjectType: any = cacheManagerService.objectTypes.getBy("config.internal", internal);
                type = quickfinderObjectType == void 0 ? "" : quickfinderObjectType.model.osid;
            } else if (!isNaN(tmp)) {
                type = tmp;
            }

            const objectType: ObjectType = {
                id: type,
                fields: []
            };

            // each field starts with the object prefix and ends with the field prefix
            // e.g. "OBJEKT00FELD00"
            const fieldKeyName: any = objectPrefix + i.toString() + fieldPrefix;
            // collecting the fields
            for (const key in rawConf) {
                if (key.indexOf(fieldKeyName) == 0) {
                    const fieldNumber: string = key.substr(key.length - 2, key.length);
                    objectType.fields[parseInt(fieldNumber)] = rawConf[key];
                }
            }

            i++;

            config.objectTypes.push(objectType);
        }
        while (rawConf[objectPrefix + i.toString()]);

        const extra01 = "EXTRA01";
        const canlock = "CANLOCK";
        config.replaceValues = rawConf[extra01] !== void 0 && rawConf[extra01] == 1;
        config.canLock = rawConf[canlock] !== void 0 ? rawConf[canlock] : 1;

        return config;
    }

    /**
     * getRightGroupsConfig
     *
     * @param field
     */
    private getRightGroupsConfig(field: any): RightGroupsConfig {

        const config: RightGroupsConfig = {
            sortByNameWithGroupsFirst: false,
            sortBothByName: false,
            sortByDescription: false,
            showUsers: true,
            showGroups: true,
            excludeGroups: [],
            excludeUsers: [],
            exclusiveUsers: [],
            exclusiveGroups: [],
            userFilter: [],
            groupsFilter: [],
            canLock: 1
        };

        let isUserExclude = false,
            isUserExclusive = false,
            isGroupExclude = false,
            isGroupExclusive = false;
        const rawValues: string = field.list.rawdata.trim();
        const flags: any = rawValues.split("\n");
        flags.shift(); // drop superfluous title

        const excludeKeyList: string[] = ["user_exclude", "user_exclusive", "groups_exclusive", "groups_exclude"];

        // trimming spaces from each entry
        for (const j in flags) {
            const flag: string = flags[j].trim().toLowerCase();
            const value: any = flag.split(/=(.+)/)[1];
            const key: any = flag.split(/=(.+)/)[0];

            if (key == "canlock") {
                config.canLock = value;
            } else if (value == "both") {
                config.showGroups = true;
                config.showUsers = true;
            } else if (value == "user") {
                config.showGroups = false;
                config.showUsers = true;
            } else if (value == "groups") {
                config.showGroups = true;
                config.showUsers = false;
            } else if (value == "sortcol1") {
                config.sortByNameWithGroupsFirst = true;
            } else if (value == "sortcol2") {
                config.sortBothByName = true;
            } else if (value == "sortcol3") {
                config.sortByDescription = true;
            } else if (isUserExclusiveExcluded(value)) {
                const configKey: string = value.split(":")[0];
                const tmpList: string = value.split(":")[1];

                if (tmpList == "" || tmpList == void 0) {
                    console.warn("malformed user or grouplist", field);
                    continue;
                }

                const list: any = tmpList.split(";");

                // it can be either exclude or exclusive but not both at the same time
                // these config properties will be skipped
                switch (configKey) {
                    case "user_exclude":
                        if (!isUserExclusive) {
                            config.excludeUsers = list;
                            isUserExclude = true;
                        }
                        break;
                    case "user_exclusive":
                        if (!isUserExclude) {
                            config.exclusiveUsers = list;
                            isUserExclusive = true;
                        }
                        break;
                    case "groups_exclusive":
                        if (!isGroupExclusive) {
                            config.exclusiveGroups = list;
                            isGroupExclude = true;
                        }
                        break;
                    case "groups_exclude":
                        if (!isGroupExclude) {
                            config.excludeGroups = list;
                            isGroupExclusive = true;
                        }
                        break;
                }
            } else if (isUserOrGroupsFilter(value)) {
                const filter: string = value.split(":")[0];
                const filterList: string = value.split(":")[1];

                if (filterList == "" || filterList == void 0) {
                    console.warn("malformed filter in addon configuration", field);
                    continue;
                }

                const entries: any = filterList.split(";");

                switch (filter) {
                    case "groups_filter":
                        config.groupsFilter = entries;
                        break;
                    case "user_filter":
                        config.userFilter = entries;
                        break;
                }
            }
        }

        function isUserOrGroupsFilter(value: any): boolean {
            return value.indexOf("groups_filter") == 0 || value.indexOf("user_filter") == 0;
        }

        function isUserExclusiveExcluded(value: any): boolean {
            for (const i in excludeKeyList) {
                if (value.indexOf(excludeKeyList[i]) == 0) {
                    return true;
                }
            }

            return false;
        }

        if (!config.sortBothByName && !config.sortByDescription && !config.sortByNameWithGroupsFirst) {
            config.sortByNameWithGroupsFirst = true;
        }
        return config;
    }
}