<div class="nav-header">
    <div class="nav-quick-access">
        <h2>{{navigationService.navTitle}}</h2>
        <button id="eob-nav-pin"
                class="button-transparent"
                (click)="triggerChangePinState(false)"
                *ngIf="!isTouchDevice"
                tabindex="{{navigationService.isNavVisible ? '0' : '-1'}}">
            <eob-icon name="nav-pin" size="24" alt="pin navigation bar icon" title="{{getNavPinTooltip()}}"></eob-icon>
        </button>
        <button id="close-button"
                (click)="close()"
                *ngIf="isPhone"
                class="button-transparent"
                tabindex="{{navigationService.isNavVisible ? '0' : '-1'}}">
            <eob-icon name="close-modal-white" size="24"></eob-icon>
        </button>
    </div>
</div>