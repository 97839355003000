import {NgModule} from "@angular/core";
import {EobExternalTrayElementsComponent} from "MODULES_PATH/external-tray/components/external-tray-elements/eob-external-tray-elements.component";
import {IconModule} from "MODULES_PATH/icon/icon.module";
import {SharedModule} from "SHARED_PATH/shared.module";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [CommonModule, IconModule, SharedModule],
    exports: [EobExternalTrayElementsComponent],
    declarations: [EobExternalTrayElementsComponent],
    entryComponents: [EobExternalTrayElementsComponent]
})
export class ExternalTrayModule {
}