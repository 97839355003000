import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    Renderer2,
    ViewChild
} from "@angular/core";
import {fromEvent, Subscription} from "rxjs";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ViewService} from "CORE_PATH/services/view/view.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {EobContextMenuTemplateComponent} from "MODULES_PATH/context-menu/components/eob-context-menu-template/eob-context-menu-template.component";
import {
    ContextMenuAction,
    ContextMenuItem,
    DisplayContextMenuActionsData
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {Connection} from "ENUMS_PATH/connection.enum";
import {ContextMenuService} from "MODULES_PATH/context-menu/services/context-menu.service";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {TodoTouchHandlerService} from "INTERFACES_PATH/any.types";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";

@Component({
    selector: "eob-context-menu",
    templateUrl: "./context-menu.component.html",
    styleUrls: ["./context-menu.component.scss"],
})

export class ContextMenuComponent extends EobContextMenuTemplateComponent implements OnDestroy, AfterViewInit {
    private subscription: Subscription = new Subscription();

    contextMenuActions: ContextMenuAction[] = [];
    contextMenuStyle: JQLiteCssProperties = {display: "none"};
    headerContent: string = "";
    isMenuVisible:boolean = false;
    type: string = "main";
    has2ndSubmenu: boolean = false;

    @ViewChild("contextMenuContainer", {static: false}) private contextMenuContainer: ElementRef<HTMLElement>;
    @ViewChild("submenus", {static: false}) private submenus: ElementRef<HTMLElement>;

    constructor(protected viewService: ViewService,
                protected contextMenuService: ContextMenuService,
                protected contextMenuUtilsService: ContextMenuUtilsService,
                protected clientService: ClientService,
                protected messageService: MessageService,
                protected renderer: Renderer2, protected el: ElementRef<HTMLElement>,
                protected changeDetection: ChangeDetectorRef,
                @Inject("touchHandlerService") protected touchHandlerService: TodoTouchHandlerService,
    ) {
        super(clientService, messageService, renderer);
    }

    @HostListener("window:keydown", ["$event"])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key == "Escape" || event.key == "Esc") {
            this.closeContextmenu(event);
        }
    }

    ngAfterViewInit(): void {
        this.clientService.registerConnectivityChangeHandler((connectivity) => {
            if (connectivity == Connection.NONE) {
                this.closeContextmenu();
            }
        });

        this.subscription.add(this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, (event?: Event) => {
            this.closeContextmenu(event);
        }));

        this.subscription.add(this.messageService.subscribe(InlineDialogEvent.DISPLAY_CUSTOM_ACTIONS, (data: DisplayContextMenuActionsData<ContextMenuAction>) => {
            this.closeContextmenu(data.event);

            if (data.contextData?.context == "emptySpaceInHitlist" && !this.isPhone) {
                this.openSubmenu({
                    event: data.event,
                    contextMenuAction: {params: data.items},
                    mainMenu: document.body.querySelector("#eob-kebab-menu-dropdown")
                });
            } else {
                this.contextMenuActions = data.items;
                this.openContextmenu(data.contextData, data.event);
            }
        }));

        this.subscription.add(this.messageService.subscribe(InlineDialogEvent.DISPLAY_CTX_ACTIONS, (data: DisplayContextMenuActionsData<ContextMenuItem>): void => {
            this.closeContextmenu(data.event);

            this.contextMenuService.getContextMenuActions(data.items, data.contextData)
                .subscribe(actions => {
                        this.contextMenuActions = actions;
                        this.openContextmenu(data.contextData, data.event);
                    }
                );
        }));

        if (!this.clientService.isTouchDevice()) {
            this.subscription.add(fromEvent(this.submenus.nativeElement, "mouseleave").subscribe(() => {
                this.hideSubmenuContainers();
            }));
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    close(event: Event): void {
        this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS, event);
    }

    openSubmenu(payload: any): void {
        this.messageService.broadcast(InlineDialogEvent.OPEN_SUBMENU, {
            contextMenuAction: payload.contextMenuAction,
            event: payload.event,
            mainMenu: payload.mainMenu ?? this.contextMenuContainer.nativeElement
        });
    }

    private closeContextmenu(event?: Event): void {
        const isCloseButton: boolean = this.isPhone && event && (event.target as HTMLElement).classList.contains("size-24") &&
            (event.target as HTMLElement).closest("eob-icon").classList.contains("close-modal");
        const isMenuItem: boolean = event && (event.target as HTMLElement).closest(".context-menu-item") &&
            (event.target as HTMLElement).closest(".context-menu-item").getAttribute("hassubmenu") == "true";

        if (!isCloseButton && isMenuItem) {
            return;
        } else {
            if (this.isPhone) {
                this.isMenuVisible = false;
                this.addClosedStyles(this.el.nativeElement);

                if (event) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    event.stopPropagation();
                }
            }

            this.hideSubmenuContainers();
            this.contextMenuActions = [];
            this.contextMenuStyle = {display: "none"};
            this.has2ndSubmenu = false;
        }
    }

    private getStyle(itemCount: number, event: any): JQLiteCssProperties {
        const approximateHeight: number = this.clientService.isTouchDevice() ? itemCount * 40 : itemCount * 32;
        const menuWidth = 250;
        const wx: number = document.body.offsetWidth;
        const wy: number = document.body.offsetHeight;

        let menuHeight: number = Math.max(approximateHeight) + 28; // 28px for padding, margin and border
        let css: JQLiteCssProperties;

        if (this.clientService.isPhone()) {
            menuHeight = Math.max(approximateHeight) + 42; // 42px for padding, margin, border and header

            css = {
                "top": `${wy - menuHeight}px`,
                "left": `${56}px`, // Nav bar
                "right": `${0}px`, // no space on the right side
                "height": `calc(100% - ${10}px)`,
                "width": "auto",
                "min-height": `${menuHeight}px`,
                "max-height": `${menuHeight}px`,
                "display": "block",
                "overflow-y": "auto",
                "overflow-x": "hidden",
            };

            if (menuHeight > wy) {
                css.top = `${10}px`;
                css["min-height"] = `${wy - 10}px`;
            }

            this.contextMenuUtilsService.gContextmenuStyles.set(this.type, css);
        } else {
            let x: number;
            let y: number;

            // workaround for Safari Mobile
            if (event.changedTouches) {
                x = event.changedTouches[0].clientX;
                y = event.changedTouches[0].clientY;
            } else if (event.clientX && event.clientY) {
                x = event.clientX;
                y = event.clientY;
            } else if ((event.target as HTMLElement).classList.contains(".ag-body-viewport")) {
                const touchEvent: any = this.touchHandlerService.getTouchEvent();

                x = touchEvent.originalEvent.changedTouches[0].clientX;
                y = touchEvent.originalEvent.changedTouches[0].clientY;
            } else {
                // try to get the middle of the current element
                const clientRect: DOMRect = (event.currentTarget as HTMLElement).getBoundingClientRect();

                x = clientRect.x + (clientRect.width / 2);
                y = clientRect.y + (clientRect.height / 2);
            }

            css = {
                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "auto",
                display: "block",
                overflow: "auto"
            };

            if ((menuWidth + x) >= wx) {
                css.right = `${wx - x}px`;
            } else {
                css.left = `${x}px`;
            }

            if (menuHeight + y >= wy) {
                css.bottom = "6px";
            } else {
                css.top = `${y}px`;
            }

            if (menuHeight > wy) {
                css.top = 0;
            }

            css["z-index"] = this.viewService.getZIndex(event.target as HTMLElement);
        }

        return css;
    }

    private openContextmenu(contextData: ContextData, event: any): void {
        let itemsLength: number = this.contextMenuActions.length;

        for (const contextMenuAction of this.contextMenuActions) {
            if (contextMenuAction.type == "divider") {
                itemsLength -= 1;
            } else if (contextMenuAction.type == "sub") {
                for (const submenuAction of contextMenuAction.params) {
                    if ((submenuAction as ContextMenuAction).type == "sub") {
                        this.has2ndSubmenu = true;
                        break;
                    }
                }
            }
        }

        if (itemsLength > 0) {
            if (this.isPhone) {
                this.headerContent = contextData.title;

                setTimeout(() => {
                    this.renderer.setStyle(this.el.nativeElement, "display", "block");
                    this.isMenuVisible = true;

                    this.changeDetection.detectChanges();
                }, 0);
            }

            this.contextMenuStyle = this.getStyle(itemsLength, event);
            this.changeDetection.detectChanges();
        }
    }
}
