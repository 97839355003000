import {Subject} from "rxjs";
import {MultiEntryMask} from "../../../../app/modules/form/models/field-masks/multi-entry-mask.model";

require("SERVICES_PATH/form/field-builder/eob.form.grid.builder.srv.js");

angular.module("eob.core").factory("gridApiService", GridApiService);

GridApiService.$inject = ["$q", "$filter", "valueUtilsService", "formValidationService", "layoutManagerService", "clientScriptService", "formAddonService", "autoCompleteService"];

// eslint-disable-next-line max-params, require-jsdoc
export default function GridApiService($q, $filter, ValueUtilsService, ValidationService, LayoutManagerService, ClientScriptService, formAddonService, autoCompleteService) {

    return {
        addGridApi
    };

    function addGridApi(api, field, formHelper) {
        /**
         * enables the grid
         */
        let onSelectionChanged$ = new Subject()
        // Listeners should be removed after user leaves the form
        const cleanupListener = () => {
            window.removeEventListener("hashchange", cleanupListener)
            onSelectionChanged$.complete()
        }
        window.addEventListener("hashchange", cleanupListener)

        /**
         * Sets the title and headers of the grid.
         *
         * @param {object} titles for grid and column headers.
         */
        api.setTitle = function(titles) {
            if (!Array.isArray(titles.headers)) {
                console.warn("title headers needs to be an array");
                return;
            }

            const element = api.getElement();

            const tableTitle = element.find(".form-table-label > span");
            tableTitle.prop("innerText", titles.title);

            const columnDefs = field.gridApi.getColumnDefs();

            columnDefs.filter(col => !(col.checkboxSelection || col.isDeleteRowCell)).forEach((colDef, index) => {
                if (index < titles.headers.length) {
                    colDef.headerName = titles.headers[index];
                }
            });

            field.gridApi.setColumnDefs(columnDefs);
        }

        /**
         * Sets the tooltip for the grid and each column header.
         *
         * @param {object} tooltip for grid and column headers.
         */
        api.setTooltip = function(tooltip) {
            if (!Array.isArray(tooltip.headers)) {
                console.warn("tooltip needs to be an array");
                return;
            }

            const element = api.getElement();

            let tableTitle = element.find(".form-table-label > span");
            tableTitle.prop("title", tooltip.tooltip);

            const columnDefs = field.gridApi.getColumnDefs();

            columnDefs.filter(col => !(col.checkboxSelection || col.isDeleteRowCell)).forEach((colDef, index) => {
                if (index < tooltip.headers.length) {
                    colDef.headerTooltip = tooltip.headers[index];
                }
            });

            field.gridApi.setColumnDefs(columnDefs);
        }

        /**
         * enables the grid
         */
        api.enable = function() {
            field.isDisabled = false;

            let rows = field.gridApi.rowModel.rowsToDisplay;
            toggleVisibilityOfTools(field, true);

            for (let i in rows) {
                for (let j in rows[i].data) {
                    api.enableCell(i, j);
                }
            }
        }

        /**
         * disables the grid
         */
        api.disable = function() {
            field.isDisabled = true;

            let rows = field.gridApi.rowModel.rowsToDisplay;

            toggleVisibilityOfTools(field, false);

            for (let i in rows) {
                for (let j in rows[i].data) {
                    api.disableCell(i, j);
                }
            }
        }

        function toggleVisibilityOfTools(field, visibility) {
            let colsToToggle = [];

            for (let i in field.gridApi.columnController.gridColumns) {
                if (field.gridApi.columnController.gridColumns[i].pinned != void 0) {
                    colsToToggle.push(field.gridApi.columnController.gridColumns[i].colId);
                }
            }

            field.gridApi.gridOptionsWrapper.gridOptions.columnApi.setColumnsVisible(colsToToggle, visibility);

            let gridFieldButtons = field.api.getElement().find(".grid-field-buttons");

            if (visibility) {
                gridFieldButtons.show();
            } else {
                gridFieldButtons.hide();
            }
        }

        /**
         * Is the grid enabled?
         *
         * @returns {boolean} true if it is enabled, else false
         */
        api.isEnabled = function() {
            return (field.isDisabled == void 0 || field.isDisabled == false);
        };

        /**
         * Get the value of a certain cell.
         * @param {number} rowIndex - The index of the row.
         * @param {number} colIndex - The index of the column.
         * @returns {String|undefined} The cell value or undefined, if the cell couldn't be found.
         */
        api.getCellValue = function(rowIndex, colIndex) {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap)) {
                return;
            }

            let row = api.getRowByIndex(rowIndex);
            if (row == void 0) {
                return;
            }

            let value = row[colIndex];
            if (value == void 0) {
                return "";
            }

            return value.toString();
        }

        api.getInitialCellValue = (rowIndex, colIndex) => {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.initialValue)) {
                return;
            }

            let row = api.getInitialRowByIndex(rowIndex);
            if (row == void 0) {
                return;
            }

            let value = row[colIndex];
            if (value == void 0) {
                return "";
            }

            return value.toString();
        }

        /**
         * sets a value for a certain cell
         * @param rowIndex - The row index
         * @param colIndex - The column index<
         * @param value - The new value
         * @param executeOnChangeScript - Boolean whether to execute the onChange event - Default is false
         */
        api.setCellValue = function(rowIndex, colIndex, value, executeOnChangeScript) {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap)) {
                return;
            }

            if (value == void 0) {
                console.warn("The value must be a String or Integer value");
                return;
            }

            let row = api.getRowByIndex(rowIndex);

            if (row == null) {
                return;
            }

            row[colIndex] = value;

            let cell = field.propMap[rowIndex][colIndex];
            cell.control.markAsDirty();
            cell.control.setValue(value);
            api.validateCell(rowIndex, colIndex);

            if (executeOnChangeScript === true) {
                let event = field.eventScripts != void 0 ? field.eventScripts["onCellChange"] : field.eventScripts;

                if (event != void 0) {
                    const _cell = {
                        rowIndex,
                        colIndex,
                        value
                    }

                    event(formHelper, formHelper.globals, ClientScriptService.getGlobalScriptingStorage(), field, _cell);
                }
            }
        }

        /**
         * Sets the cell valid
         * @param rowIndex - The row index
         * @param colIndex - The column index
         * @param suppressValidation - Suppresses the validation after the grid has been redrawn
         * Currently all cells will be validated once they are drawn
         */
        api.setCellValid = function(rowIndex, colIndex, suppressValidation) {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap)) {
                return;
            }

            suppressValidation = suppressValidation == void 0 ? true : suppressValidation;
            let cell = field.propMap[rowIndex][colIndex];

            // the cell is already valid
            if (cell.isValid) {
                return;
            }

            cell.isValid = true;
            delete cell.errTitle;
            delete cell.errMessage;
            cell.suppressValidation = suppressValidation;
            cell.control.setErrors(null);
        };

        /**
         * Sets the cell invalid
         * @param rowIndex - The row index
         * @param colIndex - The column index
         * @param title - The title of the error box
         * @param message - The description of the error box
         * @param suppressValidation - Suppresses the validation after the grid has been redrawn
         * Currently all cells will be validated once they are drawn
         */
        api.setCellInvalid = function(rowIndex, colIndex, title, message, suppressValidation) {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap) || formHelper.isSearch) {
                return;
            };

            suppressValidation = suppressValidation == void 0 ? true : suppressValidation;

            let cell = field.propMap[rowIndex][colIndex];
            cell.isValid = false;
            cell.suppressValidation = suppressValidation;

            cell.control.markAsDirty();
            cell.control.setErrors({
                customError: {
                    title: title || "",
                    message: message || ""
                }
            });
        };

        /**
         * Validates a single cell
         * @param rowIndex - The Index of the row
         * @param colIndex - The Index of the column
         * @param suppressFullRefresh - Boolean whether to suppress a full grid refresh. Deefault is false
         * @returns {Object}
         */
        api.validateCell = function(rowIndex, colIndex, suppressFullRefresh) {
            let isValid;

            let deferred = $q.defer();

            let value = api.getCellValue(rowIndex, colIndex);

            if(field.model.columns[colIndex] && field.model.columns[colIndex].type == "decimal") {
                value = ValueUtilsService.parseDecimal(value)
            }

            if (value == void 0) {
                deferred.resolve();
                return deferred.promise;
            }

            suppressFullRefresh = !!suppressFullRefresh;

            let col = field.model.columns[colIndex];
            let operations = ValidationService.getSyncedOperations({ model: col, value });

            for (const operation of operations) {
                const op = operation;

                isValid = op.fn({ model: col, value }, formHelper);
                if (!isValid) {
                    api.setCellInvalid(rowIndex, colIndex, op.title, op.msg, suppressFullRefresh);
                    deferred.reject();
                    return deferred.promise;
                }
            }

            let asyncOperations = ValidationService.getAsyncedOperations({ model: col, value });

            if (isValid && asyncOperations.length == 0) {
                api.setCellValid(rowIndex, colIndex, suppressFullRefresh);
                deferred.resolve();
                return deferred.promise;
            }

            let promises = [];

            let cellModel = Object.assign({}, col);
            cellModel.grid = field;
            cellModel.cell = {
                rowIndex,
                colIndex
            };

            for (const asyncOperation of asyncOperations) {
                const op = asyncOperation;

                let promise = op.fn({ model: cellModel, value }, formHelper).then((bool) => {
                    isValid = bool;
                    if (!isValid) {
                        api.setCellInvalid(rowIndex, colIndex, op.title, op.msg, true);
                    }
                });

                promises.push(promise);
            }

            $q.all(promises).then(() => {
                if (isValid) {
                    api.setCellValid(rowIndex, colIndex, suppressFullRefresh);
                    deferred.resolve();
                } else {
                    deferred.reject();
                }
            }).catch(() => deferred.reject());

            return deferred.promise;
        };

        /**
         * returns true / false whether the cell is valid or not
         * @param rowIndex - The row index
         * @param colIndex - The column index
         * @param getResultObject - Determines whether to return true/false or the whole validation result
         * @returns {*}
         */
        api.isCellValid = function(rowIndex, colIndex, getResultObject) {
            let returnObj = !!getResultObject;

            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap)) {
                return returnObj ? { isValid: false } : false;
            }

            let col = field.model.columns[colIndex];
            let value = api.getCellValue(rowIndex, colIndex);

            if (value === void 0) {
                return returnObj ? { isValid: true } : true;
            }

            let operations = ValidationService.getSyncedOperations({ model: col, value });

            let cell = { model: col, value };
            for (const operation of operations) {
                let op = operation;

                let isValid = op.fn(cell, formHelper);
                if (!isValid) {
                    let obj = {
                        isValid: false,
                        msg: op.msg,
                        title: op.title
                    };

                    return returnObj ? obj : false;
                }
            }
            return returnObj ? { isValid: true } : true;
        };

        /**
         * disables the given cell
         * @param rowIndex - The row index
         * @param colIndex - The column index
         */
        api.disableCell = function(rowIndex, colIndex) {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap)) {
                return;
            }

            const cell = field.propMap[rowIndex][colIndex];
            cell.isDisabled = true;
            if (cell.control) {
                cell.control.disable();
            }
        };

        /**
         * enables the given cell
         * @param rowIndex - The row index
         * @param colIndex - The column index
         */
        api.enableCell = function(rowIndex, colIndex) {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap)) {
                return;
            }

            if (field.isDisabled) {
                field.isDisabled = false;
            }

            const cell = field.propMap[rowIndex][colIndex];
            cell.isDisabled = false;
            cell.control.enable();
        };

        api.isCellEnabled = function(rowIndex, colIndex) {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap)) {
                return false;
            }

            if (field.isDisabled) {
                return false;
            }

            return (field.propMap[rowIndex][colIndex].isDisabled == void 0 || field.propMap[rowIndex][colIndex].isDisabled == false);
        };

        let focusTimeout = null;

        /**
         * focusswes the given cell
         * @param rowIndex - The row index
         * @param colIndex - The column index
         */
        api.focusCell = function(rowIndex, colIndex) {
            if (!ValueUtilsService.isValidCellIndex(rowIndex, colIndex, field.propMap)) {
                return;
            }

            if (!field.isReady) {
                clearTimeout(focusTimeout);

                focusTimeout = setTimeout(() => {
                    api.focusCell(rowIndex, colIndex);
                }, 100);

                return;
            }

            // the grid only accepts strings and not numbers ...
            // rowIndex = isUnsortedIndex ? getRealRowIndex(rowIndex) : rowIndex;
            colIndex = colIndex.toString();

            api.scrollToRow(rowIndex);

            let cell = field.propMap[rowIndex][colIndex];

            if (cell == void 0) {
                return;
            }

            let input = cell.input;
            if (input != void 0) {
                if (input.focus != void 0) {
                    setTimeout(() => {
                        input.focus();
                    }, 100)
                }

                input.selectionStart = input.value.length;
            }
        };

        /**
         * creates a value map for each cell in the grid
         * the map contains objects consisting of isValid or isDisabled properties
         * @param data
         */
        api.computeCellProperties = function(data) {
            let rows = data || field.api.getRows();
            let propMap = [];

            for (let i in rows) {
                let cells = [];
                let row = rows[i];
                for (let j in row) {
                    let cell = {
                        isDisabled: false,
                        isValid: true
                    };

                    if (field.model.columns[j].isDisabled) {
                        cell.isDisabled = true;
                    }

                    cells.push(cell);
                }
                propMap.push(cells);
            }
            field.propMap = propMap;
        };

        function getRealRowIndex(rowIndex) {
            let sortModel = field.gridApi.getSortModel();

            if (sortModel.length === 0) {
                return rowIndex.toString();
            } else {
                let realIndex = 0;

                field.gridApi.forEachNodeAfterFilterAndSort((node) => {
                    if (node.id == rowIndex) {
                        realIndex = node.childIndex.toString();
                    }
                });

                return realIndex;
            }
        }

        /**
         * Replaces all row values with the given values
         * @param index - The row index
         * @param values - An array of values the row has to be replaced with
         */
        api.editRow = function(index, values) {
            let rowsToDisplay = field.gridApi.rowModel.rowsToDisplay;
            let currentRows = [];
            let selectedRows = [];

            if (!ValueUtilsService.isValidRowIndex(index, field.propMap.length)) {
                return;
            }

            if (!Array.isArray(values)) {
                values = Array.prototype.slice.call(arguments, 1);
            }

            values = ValueUtilsService.normalizeRowValues(values, field.model.columns.length);

            // gather current data
            for (let i in rowsToDisplay) {
                if (i == index) {
                    currentRows.push(values);
                } else {
                    currentRows.push(rowsToDisplay[i].data);
                }

                if (rowsToDisplay[i].selected) {
                    selectedRows.push(i);
                }
            }

            field.gridApi.setRowData(currentRows);
            api.selectRows(selectedRows);
        };

        /**
         * Adds a new row.
         * @param {object[]|undefined} values - Either an array of values or null (adds an empty row).
         * @param {number} insertindex - The index, where the new row will be inserted before.
         */
        api.addRow = function(values, insertindex) {
            api.addRows([values], insertindex);
        };

        /**
         * Adds multiple rows to the grid.
         * @param {object[]=} rows - An array of arrays representing rows.
         * @param {number=} insertindex - The index, where the new rows will be inserted before.
         */
        api.addRows = function(rows, insertindex) {
            if (rows == void 0) {
                console.warn("Rows must be defined");
                return;
            }

            let rowsToDisplay = field.gridApi.rowModel.rowsToDisplay;

            let currentRows = [];
            let selectedRows = [];

            // gather current data
            for (let i in rowsToDisplay) {
                currentRows.push(rowsToDisplay[i].data);
                if (rowsToDisplay[i].selected) {
                    selectedRows.push(i);
                }
            }

            // Remove dummy row, if anything is added but an empty row
            let addOneEmptyRow = (rows.length == 0 || rows.length == 1 && (rows[0] == void 0 || rows[0].length == 0));
            if (currentRows.length == 1 && !addOneEmptyRow) {
                let onlyDummyRowPresent = true;

                for (let i in currentRows[0]) {
                    if (currentRows[0][i] != "") {
                        onlyDummyRowPresent = false;
                        break;
                    }
                }

                if (onlyDummyRowPresent) {
                    field.propMap.splice(field.propMap.length - 1, 1);
                    field.gridApi.updateRowData({ remove: currentRows });
                    currentRows = [];
                }
            }

            insertindex = (insertindex < currentRows.length) ? insertindex : currentRows.length;

            let rowsObj = [];

            let internalindex = insertindex;

            for (let values of rows) {
                //adding an empty row
                if (values === void 0) {
                    values = [];
                }

                values = ValueUtilsService.normalizeRowValues(values, field.model.columns.length);
                rowsObj.push(values);

                let newProps = [];
                for (let i in values) {
                    let isDisabled = field.isDisabled === true || field.model.columns[i].isDisabled === true;

                    newProps.push({ isDisabled, isValid: true });
                }

                field.propMap.splice(internalindex, 0, newProps); // index hochzählen bei mehreren rows
                internalindex++;
            }

            field.gridApi.updateRowData({ add: rowsObj, addIndex: insertindex });
            field.gridApi.ensureIndexVisible(insertindex);

            // setting focus into the first cell
            // removing the sorting --> see DODO-2422
            if (field.gridApi.getSortModel().length > 0) {
                field.gridApi.setSortModel([]);
            }

            if (!LayoutManagerService.isTouchLayoutActive()) {
                field.api.focusCell(insertindex, 0);
            }

            if (selectedRows.length > 0) {
                api.selectRows(selectedRows);
            }
        };

        /**
         * removes the given row
         * @param index - The row index
         * @param allowEmpty - don't add an empty row, if the table is empty
         */
        api.removeRow = function(index, allowEmpty) {
            if (!ValueUtilsService.isValidRowIndex(index, field.propMap.length)) {
                return;
            }

            let rowsToDisplay = field.gridApi.rowModel.rowsToDisplay;
            let currentRows = [];
            let selectedRows = [];

            // gather current data
            for (let i in rowsToDisplay) {
                if (i == index) {
                    currentRows.push(rowsToDisplay[i].data);
                    field.propMap.splice(i, 1);
                }

                if (rowsToDisplay[i].selected) {
                    selectedRows.push(i);
                }
            }

            field.gridApi.updateRowData({ remove: currentRows });

            if (field.propMap.length === 0 && allowEmpty != true) {
                // adding an empty row
                api.addRow();
            } else {
                api.selectRows(selectedRows);
            }
        };

        /**
         * removes the given rows
         * @param indexes - An array of row indexes
         */
        api.removeRows = function(indexes, allowEmpty) {
            if (indexes == void 0 || indexes === "") {
                console.warn("The index must not be empty!");
                return;
            }

            if (!Array.isArray(indexes)) {
                indexes = [].slice.apply(arguments);

                let lastArgumentIndex = indexes.length - 1;
                if (typeof (indexes[lastArgumentIndex]) == "boolean") {
                    allowEmpty = indexes[lastArgumentIndex];
                    indexes.splice(lastArgumentIndex, 1);
                }
            }

            // sorting the values --> we need to sort this inverted to start with the highest index
            // if we would not do this , the indexes might get scrambled because after deleting the first index
            // all following indexes will get decreased
            indexes.sort((a, b) => {
                return (a > b ? -1 : a < b ? 1 : 0);
            });

            for (let i in indexes) {
                api.removeRow(indexes[i], allowEmpty);
            }
            console.log("rows === index ", indexes);
        };

        /**
         * Scrolls to the given row indes
         * @param index - The row index
         */
        api.scrollToRow = function(index) {
            if (ValueUtilsService.isValidRowIndex(index, field.propMap.length)) {
                field.gridApi.ensureIndexVisible(Number(getRealRowIndex(index)));
            }
        };

        /**
         * Get all rows from the grid.
         * @returns {object[]} The rows.
         */
        api.getRows = function() {
            let rowsToDisplay = field.gridApi.rowModel.rowsToDisplay;

            let currentRows = [];

            // gather current data
            for (let row of rowsToDisplay) {
                currentRows.push(row.data);
            }

            if (currentRows.length == 1) {
                let firstRow = currentRows[0];
                let isEmpty = true;
                for (let i = 0; i < firstRow.length; i++) {
                    if (firstRow[i] != "") {
                        isEmpty = false;
                        break;
                    }
                }

                if (isEmpty) {
                    currentRows = currentRows.splice(1, 1);
                }
            }

            return currentRows;
        };

        /**
         * Get all initial rows.
         * @returns {object[]} The initial rows.
         */
        api.getInitialRows = () => { return field.initialValue; };

        /**
         * Get the row with the given index.
         * @param {number} rowIndex - The row index.
         * @returns {object|null} The row or null, if the row cannot be found.
         */
        api.getRowByIndex = function(rowIndex) {
            if (!ValueUtilsService.isValidRowIndex(rowIndex, field.propMap.length)) {
                return null;
            }

            // at some point the grid starts rendering itself without notifying us that it is already rendered
            // this leads to a point where we start asking the api to do stuff, but the gridapi does not exist
            if (field.gridApi == void 0) {
                return null;
            }

            let rowsToDisplay = field.gridApi.rowModel.rowsToDisplay;

            return rowsToDisplay[rowIndex].data;
        };

        /**
         * Get the initial row with the given index.
         * @param {number} rowIndex - The row index.
         * @returns {object|null} The initial row or null, if the row cannot be found.
         */
        api.getInitialRowByIndex = (rowIndex) => {
            if (!ValueUtilsService.isValidRowIndex(rowIndex, field.initialValue.length)) {
                return null;
            }

            return field.initialValue[rowIndex];
        };

        /**
         * searches for the given value and returns an array of rowindexes
         * @param search - The search value
         * @returns {Array}
         */
        api.getRowIndexesByValue = function(search) {

            let rows = api.getRowsByValue(search),
                matches = [];

            for (let i = 0; i < rows.length; i++) {
                matches.push(rows[i].index);
            }

            return matches;
        };

        /**
         * searches for the given value and returns an array of rows
         * @param search - The search value
         * @returns {Array}
         */
        api.getRowsByValue = function(search) {
            if (typeof (search) != "string" && typeof (search) != "number") {
                console.warn("The search parameter must be defined and a number or string");
                return [];
            }

            search = search.toString().toLowerCase();

            let rowsToDisplay = field.gridApi.rowModel.rowsToDisplay;
            let matches = [];

            for (let i in rowsToDisplay) {
                let row = rowsToDisplay[i].data;

                for (let j in row) {
                    let cell = row[j].toLowerCase();

                    if (cell == search) {
                        matches.push({
                            index: rowsToDisplay[i].id,
                            data: row
                        });
                        break;
                    }
                }
            }

            return matches;
        };

        /**
         * returns the current selection state
         * @returns {string}
         */
        api.getSelectState = function() {
            if (field.gridApi != void 0) {
                let selectState = field.gridApi.gridOptionsWrapper.gridOptions.rowSelection;
                return selectState != "multiple" && selectState != "single" ? "none" : selectState;
            }
        };

        /**
         * Adds the passed function to be called, whenever a selection change inside the grid happens.
         * Any number of listeners can be added and will be discarded, when the form state is left or `setSelectState("none")` is called
         * @param fn callback function
         */
        api.addSelectionChangeListener = fn => {
            if (typeof fn == "function") {
                onSelectionChanged$.subscribe(value => {
                    fn(value)
                })
            }
        }

        /**
         * make grid rows selectable
         * @param rowSelectOption - is a string and can be 'single' or 'multiple'
         */
        api.setSelectState = function(rowSelectOption) {
            if ((rowSelectOption == "multiple" || rowSelectOption == "single") && !formHelper.isSearch) {
                field.gridApi.gridOptionsWrapper.gridOptions.rowSelection = rowSelectOption;

                if (!field.model.checkBoxExists) {
                    onSelectionChanged$.complete()
                    onSelectionChanged$ = new Subject()
                    addCheckboxSelectionColumn();
                }

                // deselect all but one row, for single selection
                if (field.model.checkBoxExists && rowSelectOption == "single" && field.api.getSelectedRows().length > 1) {
                    let toDeselect = field.api.getSelectedRows();
                    toDeselect.shift();
                    field.api.deselectRows(toDeselect);
                }
            } else if (rowSelectOption == "none" && field.model.checkBoxExists) {
                field.gridApi.gridOptionsWrapper.gridOptions.rowSelection = undefined;

                removeCheckboxSelectionColumn();
                onSelectionChanged$.complete()

                field.api.deselectRows(field.api.getSelectedRows());
            }
        };

        /**
         * Marks a grid column as required
         * @param {number} colIndex - The index of the column.
         */
        api.setColumnRequired = function(colIndex) {
            if (!ValueUtilsService.isValidColIndex(colIndex, field.model.columns.length)) {
                return;
            }

            if (!field.model.columns[colIndex].isRequired) {
                field.model.columns[colIndex].isRequired = true;
                field.api.getElement().find(".ag-header-row").find(`.ag-header-cell[col-id="${colIndex}"]`).addClass("required");
            }
        }

        function addCheckboxSelectionColumn() {
            const gridOptions = field.gridApi.gridOptionsWrapper.gridOptions,
                columnDefs = field.gridApi.getColumnDefs();

            gridOptions.icons = {
                checkboxChecked: "<i class='ag-icon ag-icon-checkbox-checked'></i>",
                checkboxUnchecked: "<i class='ag-icon ag-icon-checkbox-unchecked'></i>"
            };

            // selection checkbox added to the grid as the first column
            columnDefs.unshift({
                checkboxSelection: true,
                headerTooltip: $filter("translate")("form.select.table.row"),
                headerComponentParams: {
                    template: "<div class=\"ag-cell-label-container\" role=\"presentation\">" +
                        "  <span ref=\"eMenu\" class=\"ag-header-icon ag-header-cell-menu-button\"></span>" +
                        "  <div ref=\"eLabel\" class=\"ag-header-cell-label\" role=\"presentation\">" +
                        "    <span ref=\"eSortOrder\" class=\"ag-header-icon ag-sort-order\" ></span>" +
                        "    <span ref=\"eSortAsc\" class=\"ag-header-icon ag-sort-ascending-icon\" ></span>" +
                        "    <span ref=\"eSortDesc\" class=\"ag-header-icon ag-sort-descending-icon\" ></span>" +
                        "    <span ref=\"eSortNone\" class=\"ag-header-icon ag-sort-none-icon\" ></span>" +
                        "    <i class=\"icon-16-checkbox-ok\"></i>" +
                        "    <span ref=\"eFilter\" class=\"ag-header-icon ag-filter-icon\"></span>" +
                        "  </div>" +
                        "</div>"
                },
                cellClass: "cell-checkbox",
                suppressMovable: true,
                resizable: false,
                sortable: false,
                suppressSizeToFit: true,
                width: LayoutManagerService.isTouchLayoutActive() ? 40 : 32,
                headerName: "checkbox-selection",
                template: ""
            });

            field.gridApi.setColumnDefs(columnDefs);

            // AgGrid currently doesn't change the order of columns depending on the columnDefs alone.
            gridOptions.columnApi.moveColumnByIndex(columnDefs.length - 1, 0);

            gridOptions.suppressRowClickSelection = true;
            gridOptions.onSelectionChanged = event => onSelectionChanged$.next(event.api.getSelectedNodes().map(n => n.childIndex));
            field.model.checkBoxExists = true;
        }

        function removeCheckboxSelectionColumn() {
            let columnDefs = field.gridApi.getColumnDefs();
            columnDefs.shift();
            field.gridApi.setColumnDefs(columnDefs);

            field.model.checkBoxExists = false;
            delete field.gridApi.gridOptionsWrapper.gridOptions.onSelectionChanged
            onSelectionChanged$.complete()
        }

        /**
         * sets grid rows selected
         * @param rowIndexes - is an array containing the indexes of the rows to select
         */
        api.selectRows = function(rowIndexes) {
            setRowSelectState(rowIndexes, true);
        };
        /**
         * sets grid rows deselected
         * @param rowIndexes - is an array containing the indexes of the rows to deselect
         */
        api.deselectRows = function(rowIndexes) {
            setRowSelectState(rowIndexes, false);
        };

        /**
         * Hides a grid column, while retaining its data and not altering
         * @param colId numeric id of the column (starting at 0)
         */
        api.hideColumn = colId => {
            _showHideColumn(colId, true);
        }

        /**
         * Shows a previously hidden column
         * @param colId numeric id of the column (starting at 0)
         */
        api.showColumn = colId => {
            _showHideColumn(colId, false);
        }

        function _showHideColumn(colId, hide) {
            if (isNaN(colId)) {
                console.error("Column id has to be numeric");
                return;
            }

            colId = field.model.checkBoxExists ? ++colId : colId;

            const gridOptions = field.gridApi.gridOptionsWrapper.gridOptions;
            const colState = gridOptions.columnApi.getColumnState();
            if (colId >= colState.length) {
                console.error("Column id is out of bounds");
                return;
            }
            if (colState[colId].hide == hide) {
                // Nothing changed, no need to set old settings again
                return;
            }
            colState[colId].hide = hide;
            gridOptions.columnApi.setColumnState(colState);
        }

        /**
         * sets grid rows selected or deselected depending on the given state
         * @param rowIndexes - is an array containing the indexes of the rows to select
         * @param state - in a boolean that influences the selection
         */
        function setRowSelectState(rowIndexes, state) {
            if (!Array.isArray(rowIndexes) || rowIndexes.length == 0) {
                console.warn("To select rows, the indexes must be given as an array.");
                return;
            }

            // set select state, if none is set yet
            if (!field.model.checkBoxExists && state) {
                api.setSelectState(rowIndexes.length == 1 ? "single" : "multiple");
            }

            let rowSelectOption = field.gridApi.gridOptionsWrapper.gridOptions.rowSelection;

            // check that max. one row wil be selected for single selection
            if (rowSelectOption == "single" && state) {
                let selectedIndexes = api.getSelectedRows();
                selectedIndexes = selectedIndexes.concat(rowIndexes.filter((index) => {
                    return selectedIndexes.indexOf(index) < 0;
                }));
            }

            let rows = field.gridApi.rowModel.rowsToDisplay;

            for (let rowIndex of rowIndexes) {
                let row = rows[rowIndex];

                if (row != void 0) {
                    row.setSelected(state);
                }
            }

            // field.api.getElement().find(".longer-scrollbar-mock-left").css({"display": "block"});
        }

        /**
         * gets selected grid rows
         * @returns array with indizes of selected rows
         */
        api.getSelectedRows = function() {
            let rows = field.gridApi.rowModel.rowsToDisplay;
            let selectedRows = [];
            for (let i in rows) {
                if (rows[i].selected) {
                    selectedRows.push(i);
                }
            }
            return selectedRows;
        };

        api.createPseudoCatalog = function(colIndex, catalogDefinition, dataCallback) {
            if (!ValueUtilsService.isValidColIndex(colIndex, field.model.columns.length)) {
                return;
            }

            if (!isValidPseudoCatalogDefinition(catalogDefinition, dataCallback)) {
                return;
            }

            let def = angular.copy(catalogDefinition);
            // this is the default configuration the user might overwrite
            def.config = mapPseudoCatalogDefinition(def);

            field.model.columns[colIndex].hasPseudoCatalog = true;
            field.model.columns[colIndex].pseudoCatalogDefinition = def;
            field.model.columns[colIndex].pseudoCatalogDataCallback = dataCallback;
            field.model.columns[colIndex].addon = catalogDefinition.type;

            field.propMap.forEach(element => {
                element[colIndex].colfield.model.hasPseudoCatalog = true;
                element[colIndex].colfield.model.pseudoCatalogDefinition = def;
                element[colIndex].colfield.model.pseudoCatalogDataCallback = dataCallback;
                element[colIndex].colfield.model.addon = catalogDefinition.type;
                element[colIndex].control.eobOptions.addonConfig = formAddonService.getInputAddonConfig(element[colIndex].colfield, formHelper);
                element[colIndex].control.addMask(new MultiEntryMask([def.config.multiselectionSeparator]));
                autoCompleteService.addAutoComplete(element[colIndex].colfield, formHelper);
            });
        };
    }

    function isValidPseudoCatalogDefinition(catalogDefinition, dataCallback) {
        if (catalogDefinition == void 0 || typeof (catalogDefinition) != "object") {
            console.warn("The catalog definition is not valid.");
            return false;
        }

        let validTypes = ["tree", "list", "hierarchy"];
        if (catalogDefinition.type == void 0 || validTypes.indexOf(catalogDefinition.type) < 0) {
            console.warn("The catalog type is not supported -->", catalogDefinition.type);
            return false;
        }

        if (dataCallback == void 0 || typeof (dataCallback) != "function") {
            console.warn("The callback function is not valid.");
            return false;
        }

        return true;
    }

    function mapPseudoCatalogDefinition(def) {
        return {
            shortValue: def.useShortValues != void 0 ? def.useShortValues : false,
            multiSelect: def.useMultiSelect != void 0 ? def.useMultiSelect : false,
            useCheckboxSelection: def.useCheckboxSelection != void 0 ? def.useCheckboxSelection : false,
            intermediate: def.useIntermediateNodes != void 0 ? def.useIntermediateNodes : false,
            separator: def.separator != void 0 ? def.separator : "|",
            multiselectionSeparator: def.multiselectionSeparator != void 0 ? def.multiselectionSeparator : ";",
            sorted: def.sortEntries ? def.sortEntries : false,
            readonly: def.readonly ? def.readonly : false,
            validate: true
        };
    }
}
