import {Component, Inject, OnInit, OnDestroy} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {TodoCacheManagerService, TodoSearchService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {StateConfig} from "INTERFACES_PATH/state.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import * as angular from "angular";
import {RawParams, StateService} from "@uirouter/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {HitlistLoadingState} from "MODULES_PATH/hitlist/model/loading-state.model";

@Component({
    selector: "eob-offline-objects",
    templateUrl: "./offline-objects.component.html",
    styleUrls: ["./offline-objects.component.scss"]
})
export class OfflineObjectsComponent implements OnInit, OnDestroy {
    private subs: Subscription = new Subscription();
    private readonly translateFn: TranslateFnType;

    updatedHitlistConfig: Subject<any> = new Subject<any>();
    loadingState: HitlistLoadingState = new HitlistLoadingState();
    hitlistItems: Subject<DmsDocument[]> = new Subject<DmsDocument[]>();

    constructor(
        @Inject("$filter") $filter: angular.IFilterService,
        @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
        @Inject("searchService") private searchService: TodoSearchService,
        @Inject("$state") private $state: StateService,
        @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
        private messageService: MessageService,
        private asIniService: AsIniService,
        private clientService: ClientService,
        private gridContentService: GridContentService,
    ) {
        this.translateFn = $filter("translate");
    }

    async ngOnInit(): Promise<void> {
        this.clientService.registerConnectivityChangeHandler(this.reloadHitlist);

        try {
            this.loadingState.isLoading = true;
            const hitlistItems: DmsDocument[] = await this.searchService.executeOfflineObjectsSearch();
            this.hitlistItems.next(hitlistItems);
        } catch {
            this.loadingState.hasErrors = true;
            this.loadingState.isLoading = false;
        } finally {
            this.loadingState.isLoading = false;
            this.loadingState.showHitlist = true;
        }

        const offlineSyncStatus: boolean = this.asIniService.isSynchronizeFavoritesOffline();

        this.subs.add(this.messageService.subscribeFirst(ModalEvents.UPDATE_OFFLINE_OBJECTS, (newOfflineStatus) => {
            if (this.clientService.isLocalClient() && newOfflineStatus !== undefined) {
                if (offlineSyncStatus != newOfflineStatus) {
                    this.toggleOfflineObjects();
                } else if (offlineSyncStatus) {
                    this.reloadHitlist();
                }
            }
        }));
    }

    /**
     * Reloads the offline objects.
     */
    private reloadHitlist = async () => {
        try {
            const searchResult = await this.searchService.executeOfflineObjectsSearch();
            const documentIds = this.cacheManagerService.dmsDocuments.add(searchResult, true);
            const dmsDocuments = this.cacheManagerService.dmsDocuments.get(documentIds);
            const listEntries = this.gridContentService.getListEntries(dmsDocuments);
            this.updatedHitlistConfig.next(listEntries);
        } catch(error) {
            this.clientService.executeStateErrorFallback();
        }
    };

    /**
     * Switch between "normal" favorites and the offline synchronization view.
     */
    private toggleOfflineObjects() {
        const nextStateType: string = (this.$state.current.name == "favorites") || (this.$state.current.name == "hitlist.favorites") ? "hitlist.offlineObjects" : "hitlist.favorites";
        const nextStateConfig: StateConfig = {
            userAction: undefined
        };
        const params: RawParams = {};

        const nextStateId: number = Date.now();
        nextStateConfig.executeSingleHitAction = false;

        const nextStateContent = {
            config: nextStateConfig,
            type: nextStateType,
            description: this.translateFn("eob.app.bar.favorites.title")
        };

        // this function generates a new state with given data
        this.stateHistoryManager.setStateData(nextStateContent, nextStateId);

        // jump into the new state
        this.$state.go(nextStateType, { state: nextStateId });

        //replace the previous state
        params.state = nextStateId;
        const url: string = this.$state.href(nextStateType, params);
        history.replaceState(null, null, url);
    }

    ngOnDestroy(): void {
        this.clientService.unregisterConnectivityChangeHandler(this.reloadHitlist);
    }

}
