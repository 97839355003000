import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";

require("SERVICES_PATH/eob.search.srv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/eob.state.location.srv.js");

angular.module("eob.core").factory("stateService", StateService);

StateService.$inject = ["$state", "$filter", "$rootScope", "$compile", "objectTypeService", "searchService",
    "stateHistoryManager", "asIniService", "errorModelService", "modalDialogService",
    "cacheManagerService", "clientService", "locationService", "notificationsService", "environmentService",
    "offlineLocationCacheService", "modalDialogInjectorService", "backendObjectService"];

/**
 * A service to navigate between states.
 */
// eslint-disable-next-line max-params, require-jsdoc
export default function StateService($state, $filter, $rootScope, $compile, ObjectTypeService, SearchService,
                      StateHistoryManager, AsIniService, ErrorModelService, ModalDialogService,
                      CacheManagerService, ClientService, LocationService, NotificationsService, EnvironmentService,
                      OfflineLocationCacheService, ModalDialogInjectorService, BackendObjectService) {

    let service = {
        goToLocationAsync,
        openFolderOrRegisterAsync,
        openFolderFlatAsync,
        showHitlistConfig
    };
    return service;

    /**
     * Take the given dmsDocument and open its location. Show a module dialog for user input, if the dmsDocument has multiple locations.
     *
     * @param {object} dmsDocument - Open the location of this dmsDocument.
     * @param {{ id, objectTypeId }=} parent - An id pair of a dms container. Determines that this location shall be opened, in case there are multiple.
     * @param {boolean=} inNewTab - whether the location should be opened in a new tab. If the webclient is being run inside a mobile app, the location is displayed within the current tab instead
     * @param {boolean=} forgetCurrentTab - Delete the state data for the currently open state. Usually the case after saving/creating document indexdata.
     * @param filter predefined value for the state filter header
     * @param fromEntryState whether this function is called from the entry state (to redirect to the dashboard, in case an error occurs)
     * @returns {Promise<void>} - Resolved once the state change is triggered or the user cancelled the action.
     */
    async function goToLocationAsync(dmsDocument, parent, inNewTab, forgetCurrentTab, filter, fromEntryState) {
        const path = await getLocationPathAsync(dmsDocument, parent);

        if (!path) {
            // No location has been selected in case of multiple locations
            return;
        }

        let cabinetObjectTypeId = path[0].objectTypeId;

        if (!AsIniService.isShowRegisterTree(cabinetObjectTypeId)) {
            return service.openFolderFlatAsync({ osid: path[0].objectId, objectTypeId: cabinetObjectTypeId }, cabinetObjectTypeId, [dmsDocument], forgetCurrentTab);
        }

        let exploreId = path[path.length - 1].objectId;
        let exploreObjectTypeId = path[path.length - 1].objectTypeId;
        let stateData = {
            type: "folder",
            cabinetId: cabinetObjectTypeId,
            exploreId,
            exploreObjectTypeId,
            config: {
                filter,
                selectedItems: {},
                path: "",
                fromEntryState
            }
        };

        stateData.config.selectedItems[dmsDocument.model.osid] = [];

        if (path.length > 1) {
            stateData.config.path = path;
        }

        let stateParams = {
            folderId: path[0].objectId,
            objectTypeId: cabinetObjectTypeId,
            cabinetId: cabinetObjectTypeId
        };

        StateHistoryManager.goToState("folder", stateParams, stateData, inNewTab, forgetCurrentTab);
    }

    /**
     * Take the given dmsDocument and determine its location. Show a module dialog for user input, if the dmsDocument has multiple locations.
     *
     * @param {object} dmsDocument - Open the location of this dmsDocument.
     * @param {{ id, objectTypeId }=} parent - An id pair of a dms container. Determines that this location shall be opened, in case there are multiple.
     * @returns {Promise<*>} Resolved with the evaluated path.
     */
    async function getLocationPathAsync(dmsDocument, parent) {
        const parents = await BackendObjectService.getDmsObjectLocations(dmsDocument.model.osid, dmsDocument.model.objectTypeId).toPromise();
        let path;

        // either there is a designated location given --> search for it and remember the folder register path
        // or there is no destination --> check if there are multiple locations... if so, let the user decide
        // otherwise go to the only path that exists
        if (parent != void 0 && parent.id != void 0) {
            path = await LocationService.getLocationPathAsync(dmsDocument.model, parent.id, parents);

            if (path == void 0) {
                throw ErrorModelService.createCustomError("WEB_NO_LOCATION_PRESENT_SPECIFIC", parent.id);
            }

            // additional parameter check, for script function feedback
            if (parent.objectTypeId != void 0) {
                let pathParent = path.find(p => p.objectId == parent.id);

                if (pathParent.objectTypeId != parent.objectTypeId) {
                    throw ErrorModelService.createCustomError("WEB_PARAMETER_INVALID", `'parentTypeId' - ${parent.objectTypeId}`);
                }
            }
        } else if (parents.length > 1) {
            // awaiting user input
            let idMap = [];

            for (let item of parents) {
                let p = item[item.length - 1];
                idMap.push({osid: p.objectId, objectTypeId: p.objectTypeId});
            }

            let result;

            try {
                result = await ModalDialogService.openMultipleLocation(idMap);
            } catch (err) {
                // the user canceled the dialog
                return;
            }

            if (result == undefined) {
                return;
            }

            for (let item of parents) {
                const p = item[item.length - 1];

                if (p.objectId == result.osid) {
                    path = item;
                    break;
                }
            }
        } else {
            path = parents[0];
        }

        return path;
    }

    /**
     * Open a folder state for a register or folder locally or in a new state.
     * In case of a register the folderTree may be restricted to only the register and not its full location.
     *
     * @param {{ osid, objectTypeId }} dmsItem - The folder or register properties which location shall be opened.
     * @param {boolean=} inNewTab - Whether to open the state in a new tab.
     * @param {boolean=} showFullLocation - Whether to show the complete location tree or just the register as a root node. This option relates to the osweb.property 'showFullLocation'.
     * @param {boolean=} forgetCurrentTab - Delete the state data for the currently open state. Usually the case after saving/creating document indexdata.
     * @returns {Promise<boolean>} A promise that is resolved once the state change has been triggered (true) or the opening has been prevented (false).
     */
    async function openFolderOrRegisterAsync({ osid, objectTypeId }, inNewTab, showFullLocation, forgetCurrentTab) {
        showFullLocation = showFullLocation == void 0 ? EnvironmentService.env.location.showFullLocation : showFullLocation;

        if (objectTypeId == void 0) {
            objectTypeId = await SearchService.searchObjectTypeId(osid);
        }

        let typeConfig = CacheManagerService.objectTypes.getById(objectTypeId).model.config;
        if (!typeConfig.rights.objExport) {
            NotificationsService.error($filter("translate")("eob.read.access.denied.error"));
            return false;
        }

        let cabinetId = typeConfig.cabinetId;

        if (!AsIniService.isShowRegisterTree(cabinetId)) {
            service.openFolderFlatAsync({ osid, objectTypeId }, cabinetId, undefined, forgetCurrentTab);
            return true;
        }

        // Todo: The way is very long to normalize osid, objectId, id to IdPair interface. Here it end for now.
        let path = [{ objectId: osid, objectTypeId }];
        let containerId = osid;
        let containerTypeId = objectTypeId;

        // Determine the folder to open, if the given dmsItem is just a child of the folder and we're not in register-view-mode or offline.
        if (showFullLocation && !ObjectTypeService.isFolderType(objectTypeId)) {
            let paths;
            if (ClientService.isOffline()) {
                paths = await OfflineLocationCacheService.getCachedLocationPath(osid) ? [paths] : undefined;
            } else {
                paths = await BackendObjectService.getDmsObjectLocations(osid, objectTypeId).toPromise();
            }

            if (paths !== undefined) {
                path = paths[0].concat(path);
                containerId = path[0].objectId;
                containerTypeId = cabinetId;
            }
        }

        let stateData = {
            type: "folder",
            config: {
                cabinetId,
                path
            }
        };

        let stateParams = {
            folderId: containerId,
            objectTypeId: containerTypeId,
            cabinetId
        };

        StateHistoryManager.goToState("folder", stateParams, stateData, inNewTab, forgetCurrentTab);
        return true;
    }

    /**
     * Open the result state for showing the flat content of a folder.
     *
     * @param {{ id, objectTypeId }} dmsObject - An id pair of a dms object, that is a child of the folder or the folder itself.
     * @param {number} folderObjectType - The object type id of the folder.
     * @param {DmsDocument[]=} selectItems - Select these items in the new state. They will be added, if they are missing.
     * @param {boolean=} forgetCurrentTab - Delete the state data for the currently open state. Usually the case after saving/creating document indexdata.
     * @returns {Promise<void>} Resolved once the state change is triggered.
     */
    async function openFolderFlatAsync(dmsObject, folderObjectType, selectItems, forgetCurrentTab) {
        let folderId = dmsObject.osid;

        if (ClientService.isOnline() && !ObjectTypeService.isFolderType(dmsObject.objectTypeId)) {
            const locations = await BackendObjectService.getDmsObjectLocations(dmsObject.osid, dmsObject.objectTypeId).toPromise();
            folderId = locations[0][0].objectId;
        }

        let description = await getFlatDescription(dmsObject);
        let stateData = {
            config: {},
            objectId: folderId,
            formDataTypes: {},
            objectTypeIds: [folderObjectType],
            activePage: folderObjectType,
            type: "search",
            flat: true,
            description,
            cabinetId: folderObjectType
        };

        let stateParams = {
            flat: true,
            folderId
        };

        if (selectItems != void 0) {
            let selectedItems = {};

            for (let selectItem of selectItems) {
                if (selectItem.model.isDocument) {
                    selectedItems[selectItem.model.osid] = [];
                }
            }

            stateData.config.selectedItems = selectedItems;
        }

        StateHistoryManager.goToState("hitlist.result", stateParams, stateData, false, forgetCurrentTab);
    }

    /**
     * Build the display string of the flat folder state from the given fields
     *
     * @param {{ id, objectTypeId }} folder - General info about the folder like id and objecttype
     * @return {Promise} - The Promise
     */
    async function getFlatDescription(folder) {
        let dmsDocument = CacheManagerService.dmsDocuments.getById(folder.osid);

        if (dmsDocument != void 0) {
            let nameParts = [dmsDocument.model.name];
            let configuredFields = CacheManagerService.objectTypes.getById(folder.objectTypeId).api.getConfiguredFields();

            for (let i in dmsDocument.model.fields) {
                // If we are offline we have fieldSchema=ALL an too many fields loaded.
                // The description should only go over the configured fields.
                if (configuredFields[i]) {
                    nameParts.push(dmsDocument.model.fields[i]);
                }
            }

            return nameParts.join(AsIniService.getTreeNameSeparator());
        }

        return SearchService.searchDMSDescriptionAsync(folder.osid, folder.objectTypeId);
    }

    /**
     * Show a modal dialog with the hitlist config.
     * @param {string=} cabinetId - will be used if present
     * @returns {Promise<void>} Resolved once the modal dialog is opened.
     */
    function showHitlistConfig(cabinetId) {
        if (ClientService.isOffline()) {
            NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
            return;
        }

        let objectTypeId = guessObjectTypeId(cabinetId);

        let modalScope = $rootScope.$new();

        modalScope.objectTypeId = objectTypeId;

        let objectTypeConfig = CacheManagerService.objectTypes.getById(objectTypeId).model.config;
        if (objectTypeConfig.mainType != 0) {
            objectTypeConfig = CacheManagerService.objectTypes.getById(objectTypeConfig.objectTypeId).model.config;
        }
        let objectTypeName = objectTypeConfig.name;

        ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
            input: {
                title: $filter("translate")("modal.hitlist.config.title"),
                description: $filter("translate")("modal.hitlist.config.description") + objectTypeName,
                isMockContext: false
            },
            childElement: angular.element("<eob-modal-hitlist-config objectTypeId='objectTypeId'></eob-modal-hitlist-config>"),
            scope: modalScope
        });
    }

    /**
     * @returns {number} object type id.
     */
    function guessObjectTypeId(cabinetId) {
        if (!isNaN(cabinetId) && ObjectTypeService.isValidObjectTypeId(cabinetId)) {
            return cabinetId;
        }

        let state = StateHistoryManager.getCurrentStateData();

        let result;
        if (state.data.objectTypeIds != void 0 && state.data.objectTypeIds.length > 0) {
            result = state.data.objectTypeIds[0];
        } else if (state.data.config != void 0) {
            result = state.data.config.cabinetId;
        }
        return result;
    }
}
