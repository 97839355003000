require("SERVICES_PATH/form/field-api/eob.common.api.srv.js");
require("SERVICES_PATH/form/field-api/eob.default.api.srv.js");
require("SERVICES_PATH/form/field-api/eob.grid.api.srv.js");
require("SERVICES_PATH/form/field-api/eob.group.api.srv.js");
require("SERVICES_PATH/form/field-api/eob.list.api.srv.js");
require("SERVICES_PATH/form/field-api/eob.pagecontrol.api.srv.js");

angular.module("eob.core").factory("fieldApiService", FieldApiService);

FieldApiService.$inject = [
    "$timeout",
    "$rootScope",
    "defaultApiService",
    "validationApiService",
    "commonApiService",
    "pageControlApiService",
    "listApiService",
    "gridApiService",
    "groupApiService",
    "clientScriptService"
];

/**
 * A service that contains all API functions for any type of form field.
 */
export default function FieldApiService($timeout,
                         $rootScope,
                         DefaultApiService,
                         ValidationApiService,
                         CommonApiService,
                         PageControlApiService,
                         ListApiService,
                         GridApiService,
                         GroupApiService,
                         ClientScriptService) {

    return {
        getFieldApi
    };

    // execute a post request
    function getFieldApi(helper, field, el) {
        if (field != void 0 && field.api != void 0) {
            field.api.changeElement(el);
            return field.api;
        } else {
            return new FieldApi(helper, field, el);
        }
    }

    function FieldApi(helper, fieldData, el) {
        let formHelper = helper,
            field = fieldData,
            element = el;

        let addon = field.model.addon;

        let self = this;

        self.getElement = function() {
            return Array.isArray(element)? element : angular.element(element);
        };

        self.changeElement = function(newElement) {
            element = newElement;
        };

        DefaultApiService.addDefaultApi(self, field, formHelper);
        ValidationApiService.addValidationApi(self, field, formHelper);
        CommonApiService.addCommonFieldsApi(self, field, formHelper);

        if (field.model.type == "pagecontrol") {
            PageControlApiService.addPageControlApi(self, field, formHelper);
        } else if (addon == "db" || addon == "tree" || addon == "list" || addon == "hierarchy"
            || (addon == "organisation" && field.model.config.display == "tree")) {
            ListApiService.addListApi(self, field);
        } else if (field.model.type == "grid") {
            GridApiService.addGridApi(self, field, formHelper);
        } else if (field.model.type == "group") {
            GroupApiService.addGroupApi(self, field, formHelper);
        }

        bindScriptEvents(self, field, formHelper);
    }

    function bindScriptEvents(self, field, formHelper) {
        if (field.eventScripts == void 0) {
            return;
        }

        let bind = {
            onClick(field, fn, formHelper) {
                if (typeof fn == "function") {
                    $timeout(() => {
                        let el = field.api.getElement();
                        let button = el.is("button") ? el : el.find("button");

                        button.bind("click", () => {
                            setTimeout(() => {
                                if (!field.isDisabled) {
                                    fn(formHelper, formHelper.globals, ClientScriptService.getGlobalScriptingStorage(), field);
                                    $rootScope.$apply();
                                }
                            }, 0);
                        });
                    }, 0);
                }
            },
            onFocus(field, fn, formHelper) {
                if (typeof fn == "function") {
                    $timeout(() => {
                        let el = field.api.getElement();
                        let input = el.is("input, textarea") ? el : el.find("input, textarea");

                        input.bind("focusin", () => {
                            setTimeout(() => {
                                fn(formHelper, formHelper.globals, ClientScriptService.getGlobalScriptingStorage(), field);
                                $rootScope.$apply();
                            }, 0);
                        });
                    }, 0);
                }
            }
        };

        for (let key in field.eventScripts) {
            let script = field.eventScripts[key];

            switch (key) {
                case "onClick":
                    bind.onClick(field, script, formHelper);
                    break;
                case "onFocus":
                    bind.onFocus(field, script, formHelper);
                    break;
            }
        }
    }
}
