import {BackendFacade} from "CORE_PATH/backend/interfaces/backend-facade.interface";
import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent} from "@angular/common/http";
import {OsrestObjectResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-object-result.interface";
import {Dms2Properties} from "CORE_PATH/backend/modules/dms2/interfaces/dms2-object.interface";
import {Observable} from "rxjs";
import {Cabinet} from "INTERFACES_PATH/object-type.interface";
import {OsrestSearchResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-search-result.interface";
import {OsrestDocumentfilesIdNameMap} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-documentfiles-id-name-map.interface";
import {OsrestDocumentfilesResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-documentfiles-result.interface";
import {ProcessedRenditionInfoData} from "INTERFACES_PATH/rendition-info-data.interfaces";
import {OsrestChildrenHierarchyResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-children-hierarchy-result.interface";
import {BulkRequestResult} from "CORE_PATH/backend/models/bulk-request-result.model";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {AsIni} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {HttpResponse} from "@angular/common/http";
import {OsrestDriver} from "CORE_PATH/backend/modules/osrest/osrest-driver";
import {RequestTransformService} from "CORE_PATH/backend/utils/request-transform.service";
import {OsrestSubscriptionObject} from "../osrest/interfaces/osrest-subscription-object.interface";
import {OsrestWorkflowResult} from "../osrest/interfaces/osrest-workflow-result.interface";
import {OsrestIcons} from "../osrest/interfaces/osrest-icons.interface";
import {OsrestWorkflowOrganisationResult} from "../osrest/interfaces/osrest-workflow-organisation-result.interface";
import {OsrestOrganisationGroupsResult} from "../osrest/interfaces/osrest-organisation-groups-result.interface";
import {User} from "INTERFACES_PATH/user.interface";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";
import {OsrestRevisits, OsrestSubscriptionNotifications} from "../osrest/interfaces/osrest-subscription-notifications.interface";
import {
    OsrestWorkflowProcess,
    OsrestWorkflowInbox,
    OsrestWorkItem
} from "../osrest/interfaces/osrest-workflow-inbox.interface";

@Injectable({providedIn: BackendDms2Module})
export class Dms2Driver implements BackendFacade {
    private osrestDelegate: OsrestDriver;

    constructor(private httpClient: HttpClient, private requestTransformService: RequestTransformService) {
    }

    search(request: any, params: string): Observable<OsrestSearchResult[]> {
        return this.osrestDelegate.search(request, params);

        /*let url: string;

        if (this.requestTransformService.canTransform(request)) {
            url = "/api/dms/objects/search";
            request = this.requestTransformService.transform(request);

            return this.httpClient.post<Dms2ObjectResult>(url, request).pipe(
                map(x => x.objects.map(y => new OsrestSearchResultObject(
                        y.properties["system:objectId"].value,
                        y.properties["system:objectTypeId"].value,
                        y.properties["system:baseTypeId"].value,
                        [],
                        [],
                        this._getEcmSimpleFields(y.properties))
                    )));
        } else {
            url = "/api/dms/objects/search/native";
            return this.httpClient.post<OsrestSearchResult[]>(url, request);
        }*/
    }

    _getEcmSimpleFields(properties: Dms2Properties): any {
        const result: any = [];
        for (const key in properties) {
            const property: any = properties[key];
            if (!key.startsWith("system")) {
                result.push({
                    value: property.value != void 0 ? property.value.toString() : "",
                    displayName: key,
                    internalName: key
                });
            }
        }
        return result;
    }

    uploadFile(filename: string, blob: Blob): Observable<HttpEvent<string>> {
        return this.osrestDelegate.uploadFile(filename, blob);
    }

    searchById(id: string | number, objectTypeId?: string | number, original?: boolean): Observable<OsrestSearchResult|DmsDocument> {
        return this.osrestDelegate.searchById(id, objectTypeId, original);
    }

    fetchObjectDefinition(): Observable<any> {
        return this.osrestDelegate.fetchObjectDefinition();
    }

    fetchCabinets(): Observable<Cabinet[]> {
        return this.osrestDelegate.fetchCabinets();
    }

    addFavorites(ids: string[]): Observable<BulkRequestResult[]> {
        return this.osrestDelegate.addFavorites(ids);
    }

    removeFavorites(ids: string[]): Observable<void> {
        return this.osrestDelegate.removeFavorites(ids);
    }

    checkoutDocument(objectId: string, objectTypeId?: string, undo?: boolean): Observable<void> {
        return this.osrestDelegate.checkoutDocument(objectId, objectTypeId, undo);
    }

    retrieveDocumentFiles(objects: OsrestDocumentfilesIdNameMap[], typeOrFileNumber: string, filename: string, addWatermark?: boolean): Observable<HttpResponse<ArrayBuffer>> {
        return this.osrestDelegate.retrieveDocumentFiles(objects, typeOrFileNumber, filename, addWatermark);
    }

    queryDocumentFiles(objectId: string): Observable<OsrestDocumentfilesResult> {
        return this.osrestDelegate.queryDocumentFiles(objectId);
    }

    assignTemplateToObject(objectId: string, objectTypeId: string, templateId: string, fillTemplate?: boolean): Observable<never> {
        return this.osrestDelegate.assignTemplateToObject(objectId, objectTypeId, templateId, fillTemplate);
    }

    retrievePdfRenditionStatus(objectId: string): Observable<number> {
        return this.osrestDelegate.retrievePdfRenditionStatus(objectId);
    }

    retrieveRenditionInformation(objectId: string): Observable<ProcessedRenditionInfoData> {
        return this.osrestDelegate.retrieveRenditionInformation(objectId);
    }

    retrieveRendition(objectId: string, type: "pdf" | "thumbnail", timeoutMs: number): Observable<ArrayBuffer> {
        return this.osrestDelegate.retrieveRendition(objectId, type, timeoutMs);
    }

    getChildrenHierarchy(objectId: string): Observable<OsrestChildrenHierarchyResult[]> {
        return this.osrestDelegate.getChildrenHierarchy(objectId);
    }

    getObjectHierarchy(objectId: string): Observable<OsrestObjectResult> {
        return this.osrestDelegate.getObjectHierarchy(objectId);
    }

    loadSettings(): Observable<AsIni> {
        return this.osrestDelegate.loadSettings();
    }

    saveSettings(settings: AsIni): Observable<void> {
        return this.osrestDelegate.saveSettings(settings);
    }

    getSettingsTimestamp(): Observable<number> {
        return this.osrestDelegate.getSettingsTimestamp();
    }

    setOsrestDelegate(osrestDelegate: OsrestDriver): void {
        this.osrestDelegate = osrestDelegate;
    }

    getDropzoneThumbnail(osid: string, index: string): Observable<Blob> {
        return this.osrestDelegate.getDropzoneThumbnail(osid, index);
    }

    querySubscription(aboGroup: string): Observable<OsrestSubscriptionObject> {
        return this.osrestDelegate.querySubscription(aboGroup);
    }

    getStartableWorkflowModels(clientType?: "web" | "mobile" | "desktop" | "web_de" | "web_en" | "web_fr"): Observable<OsrestWorkflowResult[]> {
        return this.osrestDelegate.getStartableWorkflowModels(clientType);
    }

    deleteOsRenditionCache(osid: number): Observable<void> {
        return this.osrestDelegate.deleteOsRenditionCache(osid);
    }

    changePassword(oldPassword: string, newPassword: string, backendOrigin?: string, authHeader?: { [k: string]: string }): Observable<void> {
        return this.osrestDelegate.changePassword(oldPassword, newPassword, backendOrigin, authHeader);
    }

    getIcons(iconIds: string[]): Observable<OsrestIcons> {
        return this.osrestDelegate.getIcons(iconIds);
    }

    getWorkflowOrganisation(): Observable<OsrestWorkflowOrganisationResult> {
        return this.osrestDelegate.getWorkflowOrganisation();
    }

    getOrganisationUsers(): Observable<User[]> {
        return this.osrestDelegate.getOrganisationUsers();
    }

    getOrganisationGroups(): Observable<OsrestOrganisationGroupsResult[]> {
        return this.osrestDelegate.getOrganisationGroups();
    }

    getRevisits(): Observable<OsrestRevisits> {
        return this.osrestDelegate.getRevisits();
    }

    getRunningProcesses(): Observable<OsrestWorkflowProcess[]> {
        return this.osrestDelegate.getRunningProcesses();
    }

    getRunningWorkflows(): Observable<OsrestWorkflowInbox[]> {
        return this.osrestDelegate.getRunningWorkflows();
    }

    getRunningWorkflow(id: string): Observable<{ WorkItem: OsrestWorkItem }> {
        return this.osrestDelegate.getRunningWorkflow(id);
    }

    getSubscriptionNotifications(): Observable<OsrestSubscriptionNotifications> {
        return this.osrestDelegate.getSubscriptionNotifications();
    }

    getSubscriptionObjects(): Observable<OsrestSubscriptionObject[]> {
        return this.osrestDelegate.getSubscriptionObjects();
    }


}
