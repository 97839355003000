import {
    AfterViewInit,
    OnInit,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    ViewChild,
    Renderer2,
    OnDestroy, ChangeDetectorRef
} from "@angular/core";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import * as dayjs from "dayjs";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {AutoCompleteConfig} from "MODULES_PATH/autocomplete/interfaces/autocomplete.interface";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {EobInputFormControl} from "MODULES_PATH/form/components/inputs/eob-input-form-control.model";
import {InputComponent} from "MODULES_PATH/form/components/inputs/input/input.component";
import {TextareaComponent} from "MODULES_PATH/form/components/inputs/textarea/textarea.component";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import {TodoEnvironmentService, TodoFormFieldBuilder, TodoFormHelper} from "INTERFACES_PATH/any.types";
import {Field} from "INTERFACES_PATH/field.interface";
import {FormControl} from "@angular/forms";
import {AutoCompleteService} from "MODULES_PATH/autocomplete/services/autocomplete.service";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {CapslockTracerService} from "MODULES_PATH/form/services/capslock-tracer.service";

dayjs.extend(customParseFormat);

@Component({
    selector: "eob-form-text-field",
    templateUrl: "./eob-form-text-field.component.html",
    styleUrls: ["./eob-form-text-field.component.scss"]
})

export class EobFormTextFieldComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() field: Field;
    @Input() formhelper: TodoFormHelper;
    @Input() hasAddon: boolean;
    @Input() ismockform: boolean;
    @Input() formid: string;
    @Input() disabled: boolean;
    @Input() control: FormControl;

    eobAutocomplete: AutoCompleteConfig = null;

    @ViewChild(InputComponent, {static: false}) input: InputComponent;
    @ViewChild(TextareaComponent, {static: false}) textarea: TextareaComponent;

    private inputField: any;
    private textareaField: any;

    readonly translateFn: TranslateFnType;

    fieldid: string = "";
    showPlaceholder: boolean = true;
    updateConfigSubscription: any;

    // eslint-disable-next-line max-params
    constructor(@Inject(forwardRef(() => ClientService)) public clientService: ClientService,
                @Inject(forwardRef(() => "formFieldBuilder")) public formFieldBuilder: TodoFormFieldBuilder,
                @Inject(forwardRef(() => "environmentService")) public environmentService: TodoEnvironmentService,
                @Inject(forwardRef(() => "toolService")) public toolService: ToolService,
                @Inject(forwardRef(() => "$filter")) public $filter: ng.IFilterService,
                public renderer: Renderer2,
                public messageService: MessageService,
                private capslockTracerService: CapslockTracerService,
                public el: ElementRef<HTMLElement>,
                private autoCompleteService: AutoCompleteService,
                private detectChanges: ChangeDetectorRef) {
        this.translateFn = $filter("translate");
    }

    ngOnInit(): void {
        this.control = this.field.model.control ?? new EobInputFormControl("");
        if (this.hasAddon) {
            this.autoCompleteService.addAutoComplete(this.field, this.formhelper);
            this.setAutoCompleteConfig();
        }

        this.updateConfigSubscription = this.messageService.subscribe(FormEvent.UPDATE_AUTOCOMPLETE_CONFIG, () => {
            this.setAutoCompleteConfig();
        });

        this.fieldid = `${this.field.model.internal}-${this.formid}`;
    }

    ngAfterViewInit(): void {
        this.inputField = {nativeElement: this.el.nativeElement.querySelector("input")};
        this.textareaField = {nativeElement: this.el.nativeElement.querySelector("textarea")};

        this.addDateTimeFieldLogic();
        const element: any = this.textarea ? this.textareaField : this.inputField;

        if (!this.ismockform) {
            let index: number;

            // DODO-14746
            if (this.field.model.pageControl && this.field.model.parentPage) {
                const pageControlIndex = `5${this.field.model.pageControl.tabIndex}${this.field.model.tabIndex}`;
                index = Number(pageControlIndex) + 1000;
            } else {
                index = Number(this.field.model.tabIndex) + 1000;
            }

            this.renderer.setAttribute(element.nativeElement, "tabindex", index.toString());
        }

        this.init();
    }

    ngOnDestroy(): void {
        this.updateConfigSubscription.unsubscribe();
    }

    /**
     * Binds the correct logic to the field dependend on its type
     */
    init(): void {
        if (this.clientService.isPhoneOrTablet() && this.field.model.type === FormFieldType.NUMBER) {
            this.renderer.setAttribute(this.inputField.nativeElement, "type", "number");
        }
        if (this.field.model.isPassword) {
            this.renderer.setAttribute(this.inputField.nativeElement, "type", "password");
        }
    }

    /**
     * Adds some propagation handling for the mouse click
     *
     * @param {object} event - The angular key down event
     */
    clickHandler = (event: MouseEvent): void => {
        // Prevent the dashboard directive from getting a misunderstood click event
        event.stopImmediatePropagation();
    };

    /**
     * Adds keydown handlers to show a hint when the user has capslock on on password fields and
     * handles the enter key events to stop interferences with the form submission
     *
     * @param {object} event - The angular key down event
     */
    keyDownHandler = (event: KeyboardEvent): void => {
        const inputTarget: any = event.target;

        if (this.field.model.isPassword) {
            if (event.altKey || event.ctrlKey) {
                return;
            }
        }

        if (event.key == "Enter") {
            if (!this.field.model.isPassword && !this.field.model.isTextArea) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }

            if (!this.field.model.isTextArea || (this.field.model.isTextArea && event.ctrlKey)) {

                if (inputTarget.value != void 0 && inputTarget.value != this.field.value) {
                    this.field.value = inputTarget.value;
                }

                if (!this.field.model.autoCompleteOpen && !this.formhelper.isWorkflow) {
                    this.formhelper.submit();
                }
            }
        }
    };

    /**
     * Adds or removes the date/time placeholders and formats the date/time if needed
     */
    addDateTimeFieldLogic(): void {
        if (this.field.model.type === FormFieldType.DATE || this.field.model.type === FormFieldType.DATETIME || this.field.model.type === FormFieldType.TIME) {
            this.field.model.control.registerOnDisabledChange((isDisabled) => {
                this.showPlaceholder = !isDisabled;
                this.detectChanges.detectChanges();
            });
        }
    }

    private setAutoCompleteConfig(): void {
        this.eobAutocomplete = this.field.model.autoCompleteConfig;
    }

    onInputFocus(focused: boolean): void {
        if (this.inputField.nativeElement.type != "password") {
            return;
        }
        if (focused) {
            this.capslockTracerService.showCapsLockWarning.next(true);
        } else {
            this.capslockTracerService.showCapsLockWarning.next(false);
            this.capslockTracerService.capsLockOn.next(false);
        }
    }
}
