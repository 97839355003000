import {
    AfterViewInit,
    Component,
    Inject,
    OnChanges,
    OnDestroy,
    SecurityContext,
    SimpleChanges,
    HostListener,
} from "@angular/core";
import {OfficeBackendService} from "MODULES_PATH/wopi/services/eob.office.backend.service";
import {DomSanitizer} from "@angular/platform-browser";
import {StateParams, StateService} from "@uirouter/core";
import {WopiHostService} from "MODULES_PATH/wopi/services/eob.wopi.host.service";
import {TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {first} from "rxjs/operators";

@Component({
    selector: "eob-wopi-host",
    templateUrl: "./eob-wopi-host.component.html",
    styleUrls: ["./eob-wopi-host.component.scss"]
})
export class EobWopiHostComponent implements AfterViewInit, OnChanges, OnDestroy {
    constructor(@Inject("$state") private $state: StateService,
                @Inject("stateHistoryManager") protected stateHistoryManager: TodoStateHistoryManager,
                @Inject("$stateParams") private $stateParams: StateParams,
                public officeBackendService: OfficeBackendService,
                public sanitizer: DomSanitizer,
                public wopiHostService: WopiHostService) {
    }

    ngAfterViewInit(): void {
        this.initIframe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log("CHANGES");
    }

    initIframe(): void {
        const frameholder = document.getElementById("frameholder_client");
        const officeFrame = document.createElement("iframe");
        officeFrame.name = "office_frame_client";
        officeFrame.id = "office_frame_client";
        // The title should be set for accessibility
        officeFrame.title = "Office Frame Client";
        // This attribute allows true fullscreen mode in slideshow view
        // when using PowerPoint's 'view' action.
        officeFrame.setAttribute("style", "width: 100%; height: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: 0; border: none; display: block;");
        officeFrame.setAttribute("allowfullscreen", "true");
        // The sandbox attribute is needed to allow automatic redirection to the O365 sign-in page in the business user flow
        officeFrame.setAttribute("sandbox", "allow-scripts allow-same-origin allow-top-navigation allow-forms allow-popups allow-popups-to-escape-sandbox");
        frameholder.appendChild(officeFrame);
    }

    @HostListener("window:message", ["$event"])
    handlePostMessage = (e: any): any => {
        let msg;
        // The actual message is contained in the data property of the event.
        try {
            msg = JSON.parse(e.data);
        } catch(err) {
            console.warn("Error while parsing message: ", err);
            return;
        }
        console.log(msg);
        if (msg.MessageId === "Host_PostmessageObjectConverted") {
            const stateId: number = +new Date();
            this.stateHistoryManager.setStateData({params: {location: msg.Values.osid}}, stateId);
            void this.$state.go("entry", {state: stateId});
        } else if (msg.MessageId === "Host_PostmessageObjectUpdated") {
            void this.refresh(msg.Values.osid, true);
        } else if (msg.MessageId === "Host_PostmessageObjectUpdating") {
            setTimeout(() => {
                void this.refresh(msg.Values.osid, true);
            }, 3500);
        } else if (msg.MessageId === "Host_PostmessageObjectLocked") {
            void this.refresh(msg.Values.osid, false);
        } else if (msg.MessageId === "Host_PostmessageOpenUrlInElectronTab") {
            window.electron.openNewTab(msg.Values.url);
            window.electron.showCloseButtonOnAllTabs();
        } else if (msg.MessageId === "Host_PostmessageElectronTabClosed") {
            this.officeBackendService.updateFile(msg.Values.accessToken).pipe(first()).subscribe(data => {
                if (data.status === "UPDATED") {
                    void this.refresh(data.objectId, true);
                } else {
                    void this.refresh(data.objectId, false);
                }
            });
        }
    };

    async refresh(osid: string, updateViewers: boolean): Promise<any> {
        await this.wopiHostService.refreshObjectInHitlist(osid);
        if (updateViewers) {
            this.officeBackendService.updateContentAndDetailsViewer();
            this.submit();
        }
    }

    submit(): void {
        (document.getElementById("office_frame_client") as HTMLIFrameElement).contentWindow.location.replace(this.sanitizer.sanitize(
            SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.officeBackendService.urlSrc)));
    }

    ngOnDestroy(): void {
        console.log("destroyed");
    }

}
