import {Component, OnInit} from "@angular/core";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {Inject, OnDestroy} from "@angular/core";
import {StateParams} from "@uirouter/core";
import {HitlistLoadingState} from "MODULES_PATH/hitlist/model/loading-state.model";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {Subscription} from "rxjs";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {SubscriptionNotification} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-subscription-notifications.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {TodoCacheManagerService, TodoEnvironmentService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {HitlistConfig} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {OsrestWorkflowInbox} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-workflow-inbox.interface";

enum InboxType {
    SUBSCRIPTION_NOTIFICATIONS = "abo",
    SUBSCRIPTION_OBJECTS = "subscriptionObjects",
    REVISITS = "revisit",
    WORKFLOWS = "workflow",
    STARTABLE_WORKFLOWS = "startable",
    RUNNING_PROCESSES = "processes"
}

@Component({
    selector: "eob-inbox",
    templateUrl: "./inbox.component.html",
    styleUrls: ["./inbox.component.css"]
})
export class InboxComponent implements OnInit, OnDestroy {

    hitlistConfig: HitlistConfig;
    translateFn: TranslateFnType;
    loadingState = new HitlistLoadingState();
    stateHeaderTitle: string;
    stateHeaderDescription: string;
    gInboxItemIds: string[] = [];
    changeListener: string = "";
    readonly backendSub: Subscription = new Subscription();

    readonly inboxType: string;
    readonly showRefreshButton: boolean;

    constructor(private gridContentService: GridContentService, private httpService: HttpService,
                private notificationsService: NotificationsService, private messageService: MessageService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("$stateParams") private $stateParams: StateParams,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("$filter") private $filter: ng.IFilterService,
    ) {
        this.translateFn = this.$filter("translate");
        this.stateHeaderTitle = this.translateFn("eob.inbox.title");
        this.inboxType = $stateParams.type;
        this.showRefreshButton = this.inboxType != "startable";
        this.backendSub.add(this.messageService.subscribe(Broadcasts.INBOX_INDEXDATA_CHANGED, (items: OsrestWorkflowInbox[]) => {
            const itemMap: {[k: string]: OsrestWorkflowInbox} = items.reduce((acc, val) => {
                acc[val.id] = val;
                return acc;
            }, {});
            this.hitlistConfig.rows = this.hitlistConfig?.rows?.map(x => itemMap[x.id] ? itemMap[x.id] : x);
            this.hitlistConfig.api?.updateRows(this.hitlistConfig);// = Object.assign({}, this.hitlistConfig);
        }));
    }

    ngOnInit(): void {
        this.loadingState.isLoading = true;
        switch (this.inboxType) {
            case InboxType.SUBSCRIPTION_NOTIFICATIONS:
                this.stateHeaderDescription = this.translateFn("eob.inbox.abo.title");
                this.backendSub.add(this.httpService.getSubscriptionNotifications().subscribe(x => {
                    const items = this.createInboxItems(x.notifications);
                    this.hitlistConfig = this.gridContentService.getListEntries(items, this.inboxType);
                    this.loadingState.isLoading = false;
                    this.loadingState.showHitlist = true;
                }, error => {
                    console.warn(error);
                    this.loadingState.isLoading = false;
                    this.loadingState.hasErrors = true;
                    this.loadingState.errorMessage = "eob.inbox.load.abo.failed";
                }));
                break;
            case InboxType.SUBSCRIPTION_OBJECTS:
                this.stateHeaderDescription = this.translateFn("eob.inbox.subscription.objects.title");
                this.backendSub.add(this.httpService.getSubscriptionObjects().subscribe(x => {
                    const items = this.createInboxItems(x);
                    this.hitlistConfig = this.gridContentService.getListEntries(items, this.inboxType);
                    this.loadingState.isLoading = false;
                    this.loadingState.showHitlist = true;
                }, error => {
                    console.warn(error);
                    this.loadingState.isLoading = false;
                    this.loadingState.hasErrors = true;
                    this.loadingState.errorMessage = "eob.inbox.load.abo.obj.failed";
                }));
                break;
            case InboxType.REVISITS:
                this.stateHeaderDescription = this.translateFn("eob.inbox.followups.title");
                this.backendSub.add(this.httpService.getRevisits().subscribe(x => {
                    const items = this.createInboxItems(x.notifications);
                    this.hitlistConfig = this.gridContentService.getListEntries(items, this.inboxType);
                    this.loadingState.isLoading = false;
                    this.loadingState.showHitlist = true;
                }, error => {
                    console.warn(error);
                    this.loadingState.isLoading = false;
                    this.loadingState.hasErrors = true;
                    this.loadingState.errorMessage = "eob.inbox.load.followups.failed";
                }));
                break;
            case InboxType.WORKFLOWS:
                this.stateHeaderDescription = this.translateFn("eob.inbox.workflow.title");
                this.backendSub.add(this.httpService.getRunningWorkflows().subscribe(x => {
                    this.hitlistConfig = this.gridContentService.getListEntries(x, this.inboxType);
                    this.loadingState.isLoading = false;
                    this.loadingState.showHitlist = true;
                }, error => {
                    console.warn(error);
                    this.loadingState.isLoading = false;
                    this.loadingState.hasErrors = true;
                    this.loadingState.errorMessage = "eob.inbox.load.workflow.failed";
                }));
                break;
            case InboxType.STARTABLE_WORKFLOWS:
                this.stateHeaderDescription = this.translateFn("eob.inbox.workflow.startable.title");
                this.hitlistConfig = this.gridContentService.getListEntries(this.environmentService.getStartableWorkflows(), this.inboxType);
                this.loadingState.isLoading = false;
                this.loadingState.showHitlist = true;
                break;
            case InboxType.RUNNING_PROCESSES:
                this.stateHeaderDescription = this.translateFn("eob.inbox.processes.title");
                this.backendSub.add(this.httpService.getRunningProcesses().subscribe(x => {
                    this.hitlistConfig = this.gridContentService.getListEntries(x, this.inboxType);
                    this.loadingState.isLoading = false;
                    this.loadingState.showHitlist = true;
                }, error => {
                    console.warn(error);
                    this.loadingState.isLoading = false;
                    this.loadingState.hasErrors = true;
                    this.loadingState.errorMessage = "eob.inbox.load.processes.failed";
                }));
                break;
        }
    }

    ngOnDestroy(): void {
        this.backendSub.unsubscribe();
        this.cacheManagerService.dmsDocuments.detachListeners(this.changeListener);
    }

    private createInboxItems(backendNotifications: any): DmsDocument[] {
        // Typing the input parameter correctly would be a big mess, as objectId is returned either as a string or number, depending on the endpoint
        // For now, this should be fine.
        const result: DmsDocument[] = [];
        const ecmObjectMap: {[k: string]: BackendObject & {notifications?: {[k: string]: Array<SubscriptionNotification<"REVISIT"|"SUBSCRIPTION">>}}} = {};
        const notificationToEcmObjectMap: {[k: string]: Array<SubscriptionNotification<"REVISIT"|"SUBSCRIPTION">>} = {};

        for (const notification of backendNotifications) {
            const notificationId = notification.id == void 0 ? notification.objectId : notification.id;

            if (notification.ecmObject == void 0) {
                continue;
            }

            if (ecmObjectMap[notificationId] == void 0) {
                ecmObjectMap[notificationId] = notification.ecmObject;
            }

            if (notificationToEcmObjectMap[notificationId] == void 0) {
                notificationToEcmObjectMap[notificationId] = [];
            }

            notificationToEcmObjectMap[notificationId].push(notification);

            delete notification.ecmObject;
        }

        for (const osid in ecmObjectMap) {
            ecmObjectMap[osid].notifications = {};
            ecmObjectMap[osid].notifications[this.inboxType] = notificationToEcmObjectMap[osid];

            this.gInboxItemIds.push(osid);
            this.cacheManagerService.dmsDocuments.add(ecmObjectMap[osid]);

            result.push(this.cacheManagerService.dmsDocuments.getById(osid));
        }

        this.changeListener = this.cacheManagerService.dmsDocuments.attachListener(this.gInboxItemIds, () => {
            const dmsDocuments = this.cacheManagerService.dmsDocuments.get(this.gInboxItemIds);
            if (this.hitlistConfig.api != void 0) {
                const hitlistConfig = this.gridContentService.getListEntries(dmsDocuments, this.inboxType);
                this.hitlistConfig.api.updateRows(hitlistConfig);
            }
        });

        return result;
    }

}
