import {Inject, Injectable} from "@angular/core";
import {TreeConfig, TreeNode, Tree} from "INTERFACES_PATH/tree.interface";
import {RawField} from "INTERFACES_PATH/field.interface";
import {FieldDataType, RawFiledType} from "ENUMS_PATH/field.enum";
import {RawTreeConfig} from "ENUMS_PATH/raw.tree.config.enum";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {FormField} from "MODULES_PATH/form/interfaces/form.interface";
import {FormListEntry, FormListNode} from "MODULES_PATH/form/interfaces/form-list.interfaces";

@Injectable({providedIn: "root"})
export class FormCatalogParserService {
    catalogCache: any = {};

    constructor(@Inject("treeAddonService") protected treeAddonService: any) {
    }

    addCatalog(model: FieldModel, field: RawField, parameter: any, isWorkflow: boolean): void {
        const entries: any = [];
        const config: TreeConfig = {
            shortValue: false,
            readonly: false,
            intermediate: false,
            useMultiSelect: false,
            useCheckboxSelection: false,
            deselectWhenCollapse: false,
            multiselectionSeparator: ";",
            separator: "|",
            sorted: false,
            sql: false,
            validate: !isWorkflow,
            icons: []
        };

        if (field.list == void 0) {
            model.tree = {
                config,
                nodes: []
            };
            return;
        }

        // guid for dms, internal for wf
        const catalogId: string = model.guid || model.internal;

        if (this.catalogCache[catalogId] != void 0) {
            model.tree = this.catalogCache[catalogId];
            return;
        }

        let rawValues: any = field.list.rawdata.trim();
        let lastIndex: number | string = -1;

        // we need to remove the first part of the value that looks like this
        // --> [m|Liste] ... this is the name of the model and the name of the field ...
        // we dont need this information at all --> substr()

        // determine the last index of the unnecessary information
        const lastSubIndex: number = rawValues.indexOf(field.name) + field.name.length + 2;

        // remove the unnecessary part and split at every line break
        rawValues = rawValues.substr(lastSubIndex).split("\n");

        for (const i in rawValues) {
            const val: string = rawValues[i];

            if (val.includes("ZEILE")) {
                const temp: string[] = val.split(/=(.+)/);
                entries.push(temp[1].trim());
                lastIndex = temp[0].replace(/ZEILE/, "");
            } else if (val.includes(RawTreeConfig.ICON) && lastIndex != -1) {
                config.icons[lastIndex] = val.split(/=(.+)/)[1];
            } else if (val.includes(RawTreeConfig.USEINTERMEDIATENODES) && val.split(/=(.+)/)[1].toLowerCase() == "ja") {
                config.intermediate = true;
            } else if (val.includes(RawTreeConfig.MULTISELEKTIONSEPARATOR)) {
                config.multiselectionSeparator = val.split(/=(.+)/)[1];
            } else if (val.includes(RawTreeConfig.SEPARATOR)) {
                config.separator = val.split(/=(.+)/)[1];
            } else if (val.includes(RawTreeConfig.MULTISELEKTION) && val.split(/=(.+)/)[1].toLowerCase() == "ja") {
                config.useMultiSelect = true;
            } else if (val.includes(RawTreeConfig.SORTIERUNG) && val.split(/=(.+)/)[1].toLowerCase() == "ja") {
                config.sorted = true;
            } else if (val.includes(RawTreeConfig.DESELECTWHENCOLLAPSE) && val.split(/=(.+)/)[1].toLowerCase() == "ja") {
                config.deselectWhenCollapse = true;
            } else if (val.includes(RawTreeConfig.SQL) && val.split(/=(.+)/)[1].toLowerCase() == "ja") {
                config.sql = true;
            } else if (val.includes(RawTreeConfig.VALIDATE) && val.split(/=(.+)/)[1].toLowerCase() == "1") {
                config.validate = true;
            }
        }

        // adding the subtype for further validation
        // grids are able to add the subtype for them self
        if (!model.subType) {
            this.addSubType(model, field, parameter, isWorkflow);
        }

        if (entries.length) {
            model.tree = this.parseTreeNodes(entries, config.icons, config, model);

            if (config.sorted) {
                const sortNodesBy: string = config.shortValue ? "short" : "value";

                this.treeAddonService.sortTreeNodes(model, model.tree.nodes, sortNodesBy);
            }

            this.catalogCache[catalogId] = model.tree;
        } else {
            model.tree = {
                config,
                nodes: []
            };
        }
    }

    private addSubType(model: FieldModel, field: RawField, parameter: any, isWorkflow: boolean): void {

        if (isWorkflow) {
            if (parameter) {
                model.subType = parameter.type;
            }
        } else {
            switch (field.type) {
                case RawFiledType.DATE:
                    model.subType = FieldDataType.DATE;
                    break;
                case RawFiledType.NUMBER:
                    model.subType = FieldDataType.NUMBER;
                    break;
                case RawFiledType.DECIMAL:
                    model.subType = FieldDataType.DECIMAL;
                    break;
                case RawFiledType.TEXT:
                    model.subType = FieldDataType.TEXT;
                    break;
            }

            if (field.flags) {
                switch (field.flags.datatype) {
                    case "num" :
                        if (field.flags.flags1 & 4194304) {
                            model.subType = FieldDataType.DATETIME;
                        } else if (field.flags.flags & 262144) {
                            model.subType = FieldDataType.TIME;
                        } else {
                            model.subType = FieldDataType.NUMBER;
                        }
                        break;
                    case "dec" :
                        model.subType = FieldDataType.DECIMAL;
                        break;
                    case "date":
                        model.subType = FieldDataType.DATE;
                        break;
                }
            }
        }

        if (!model.subType) {
            model.subType = FieldDataType.TEXT;
        }
    }

    parseListEntries(field: FormField, nodes: FormListEntry[]): FormListEntry[] {
        return nodes.map(node => this.parseListEntry(field, node));
    }

    parseListEntry(field: FormField, node: FormListEntry): FormListEntry {
        if (field.model.addon == "list") {
            if (typeof (node) == "number") {
                node = node.toString();
                return node;
            }
        }

        const treeNode: FormListNode = node as FormListNode;
        if (treeNode.nodes != void 0) {
            treeNode.nodes = this.parseListEntries(field, treeNode.nodes);
        }

        if (treeNode.short != void 0 && treeNode.short != treeNode.value && field.model.tree != void 0 && field.model.tree.config != void 0) {
            field.model.tree.config.shortValue = true;
        }

        return node;
    }

    parseTreeNodes(items: any, icons: string[], config: TreeConfig, model: FieldModel): Tree {
        const treeNodes: TreeNode[] = [];
        const flatTree: TreeNode[] = [];

        let level = 1;
        let parent: TreeNode = null;
        let lastNode: TreeNode = null;

        items.forEach((item, index) => {
            const treeItem: TreeNode = {
                value: "",
                short: "",
                iconClass: ""
            };

            let lastIndex = -1,
                itemLevel: number,
                content: any;

            if (model.addon != "list" && model.addon != "db") {
                lastIndex = item.lastIndexOf(",");
            }

            if (lastIndex == -1) {
                itemLevel = 1;
                content = item;
            } else {
                content = item.substr(0, lastIndex);
                itemLevel = item.substr(lastIndex + 1);
            }

            // in case we have a list, there is no level ;)
            if (!itemLevel) {
                itemLevel = 1;
            }

            if (content.indexOf("|") != -1) {
                treeItem.short = content.split("|")[0].trim();
                treeItem.value = content.split("|")[1].trim();
            } else {
                treeItem.value = treeItem.short = content.trim();
            }

            if (icons.length > index) {
                treeItem.iconClass = `custom-icon-${icons[index]}`;
            }

            if (!config.shortValue && treeItem.value != treeItem.short) {
                config.shortValue = true;
            }

            if (!config.iconCatalog && treeItem.iconClass != "") {
                config.iconCatalog = true;
            }

            if (itemLevel == 1) {
                level = 1;
                treeNodes.push(treeItem);
                flatTree.push(treeItem);
            } else if (itemLevel > level) {
                if (lastNode == null) {
                    return;
                }

                parent = lastNode;
                level = itemLevel;
                treeItem.parent = parent;
                parent.nodes = parent.nodes == void 0 ? [] : parent.nodes;
                parent.nodes.push(treeItem);
                flatTree.push(treeItem);
            } else if (level == itemLevel) {
                treeItem.parent = parent;
                parent.nodes = parent.nodes == void 0 ? [] : parent.nodes;
                parent.nodes.push(treeItem);
                flatTree.push(treeItem);
            } else if (itemLevel < level) {
                while (itemLevel < level) {
                    parent = parent.parent;
                    level--;
                }

                treeItem.parent = parent;
                parent.nodes.push(treeItem);
                flatTree.push(treeItem);
            }

            lastNode = treeItem;
        });

        for (const treeItem of flatTree) {
            const item: TreeNode = treeItem;

            if (item.parent) {
                const copy: TreeNode = $.extend({}, item.parent);
                delete copy.nodes;
                item.parent = copy;
            }
        }

        return {
            config,
            nodes: treeNodes
        };
    }
}