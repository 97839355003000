import {Injectable} from "@angular/core";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {WorkflowContextMenuItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {InboxActionContextMenuItem, StartableWorkflow} from "CORE_PATH/interfaces/inbox-action.service.interface";

@Injectable({providedIn: BackendOsRestModule})
export class OsrestWorkflowService {

    constructor(private httpClient: HttpClient) {
    }

    startWorkflow(wfClientType: string, startableWorkflow: StartableWorkflow): Observable<unknown> {
        return this.httpClient.post(`/osrest/api/workflows/start?clienttype=${wfClientType}`, startableWorkflow, {responseType: "text"});
    }

    personalizeWorkflows(wfClientType: string, result: InboxActionContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>(`/osrest/api/workflows/personalize?clienttype=${wfClientType}`, result);
    }

    depersonalizeWorkflows(wfClientType: string, result: WorkflowContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>(`/osrest/api/workflows/depersonalize?clienttype=${wfClientType}`, result);
    }
}
