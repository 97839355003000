<div class="submenu-container context-menu" #submenu [ngStyle]="submenuStyle">
        <eob-context-menu-template
            [contextMenuActions]="contextMenuAction.params"
            [submenuItem]="contextMenuAction"
            [headerContent]="headerContent"
            [type]="type"
            *ngIf="contextMenuAction"
            (onBack)="navigateBack($event)" (onOpen2ndSubmenu)="open2ndSubmenu($event)">
        </eob-context-menu-template>
</div>
