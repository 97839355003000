angular.module("eob.core").factory("vtxModelService", VtxModelService);

VtxModelService.$inject = ["toolService"];

export default function VtxModelService(ToolService) {

    return {
        createVtx
    };

    function createVtx(backendSearchEntry) {
        return new Vtx(backendSearchEntry);
    }

    function Vtx(backendSearchEntry) {
        let self = this;

        self.model = {
            vtxscore: backendSearchEntry.vtxscore,
            preview: backendSearchEntry.preview,
            searchKey: backendSearchEntry.searchKey,
            guid: ToolService.createGUID()
        };
    }
}
