import {Injectable} from "@angular/core";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {InboxContextMenuItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ConfirmationObject, InboxActionContextMenuItem, AboGroupPayload} from "CORE_PATH/interfaces/inbox-action.service.interface";

@Injectable({providedIn: BackendOsRestModule})
export class OsrestSubscriptionService {

    constructor(private httpClient: HttpClient) {
    }

    removeSubscriptions(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>("/osrest/api/notifications/subscriptions/remove", data);
    }

    markReadSubscriptions(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>("/osrest/api/notifications/subscriptions/markread/", data);
    }

    markUnreadSubscriptions(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>("/osrest/api/notifications/subscriptions/markread/?reset=true", data);
    }

    markAsEditedUneditedSubscriptions(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.httpClient.post<void>("/osrest/api/notifications/subscriptions/process/", data);
    }

    markSingleAsEditedUneditedSubscriptions(contextItem: InboxContextMenuItem, confirmationObject: ConfirmationObject): Observable<void> {
        const confirmWithPassword: boolean = contextItem.model.confirmationType == "CONFIRMATION_PASSWORD";
        let url: string = `/osrest/api/notifications/subscriptions/process/${contextItem.model.osid}/${contextItem.model.eventDate}`;

        if (confirmWithPassword) {
            url += `?confirmed=true&password=${btoa(confirmationObject.password)}`;
        } else {
            url += "?confirmed=true";
        }

        return this.httpClient.get<void>(url);
    }

    deleteSubscriptionObjects(payload: AboGroupPayload[]): Observable<void> {
        return this.httpClient.post<void>("/osrest/api/notifications/subscriptionObjects/delete", payload);
    }


}
