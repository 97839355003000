/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any,@typescript-eslint/typedef */
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {AuthenticationState} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {ProgressbarService} from "SERVICES_PATH/eob.progressbar.srv";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import * as angular from "angular";
import {Subscription} from "rxjs";
import {PdfjsToolbarState} from "MODULES_PATH/dashlet/interfaces/pdfjs.interface";
import {PdfjsToolbarAction} from "MODULES_PATH/dashlet/enums/pdfjs.enum";
import {selectAvailableStates} from "MODULES_PATH/dashlet/components/eob-pdfjs/+state/pdfjs-toolbar.selectors";
import {Store} from "@ngrx/store";
import {updatePdfjsToolbar} from "MODULES_PATH/dashlet/components/eob-pdfjs/+state/pdfjs-toolbar.actions";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {initialState} from "MODULES_PATH/dashlet/components/eob-pdfjs/+state/pdfjs-toolbar.reducer";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";
import {WindowToken} from "SHARED_PATH/tokens/window.providers";

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const PDFViewerApplication: any;

@Component({
    selector: "eob-pdfts",
    templateUrl: "./eob-pdfjs.component.html",
    styleUrls: ["./eob-pdfjs.component.scss"]
})
export class EobPdfjsComponent implements OnInit, AfterViewInit, OnDestroy {
    isSearchOpen = false;
    sub: Subscription = new Subscription();
    pdfjsToolbar: PdfjsToolbarState;
    private resizeTimer: NodeJS.Timeout;
    isPdfjsKebabOpen = false;

    @ViewChild("search", {static: false}) private search: ElementRef<HTMLButtonElement>;
    @ViewChild("refresh", {static: false}) private refresh: ElementRef<HTMLButtonElement>;
    @ViewChild("sidebar", {static: false}) private sidebar: ElementRef<HTMLButtonElement>;
    @ViewChild("zoomOut", {static: false}) private zoomOut: ElementRef<HTMLButtonElement>;
    @ViewChild("zoomIn", {static: false}) private zoomIn: ElementRef<HTMLButtonElement>;
    @ViewChild("rotateRight", {static: false}) private rotateRight: ElementRef<HTMLButtonElement>;
    @ViewChild("rotateLeft", {static: false}) private rotateLeft: ElementRef<HTMLButtonElement>;
    @ViewChild("attachments", {static: false}) private attachments: ElementRef<HTMLButtonElement>;
    @ViewChild("goToParent", {static: false}) private goToParent: ElementRef<HTMLButtonElement>;
    controls = {};

    @HostListener("window:resize") onResize(): void {
        this.setToolbarKebabState();
    }

    // eslint-disable-next-line max-params
    constructor(
        @Inject("$timeout") private $timeout: any, @Inject("$filter") private $filter: any, @Inject("$rootScope") private $rootScope: any,
        @Inject("$compile") private $compile: any, @Inject("pdfjsViewerService") private pdfjsViewerService: any,
        @Inject("viewService") private viewService: any, @Inject("notificationsService") private notificationsService: any,
        @Inject(WindowToken) private window: Window,
        private viewerService: ViewerService, private progressbarService: ProgressbarService,
        private layoutManagerService: LayoutManagerService,
        private messageService: MessageService,
        private authenticationService: AuthenticationService, private clientService: ClientService,
        private el: ElementRef<HTMLElement>,
        private pdfjsToolbarStore: Store<PdfjsToolbarState>,
        private cdRef: ChangeDetectorRef
    ) {
        this.initPdfjsToolbarStore();
    }

    ngOnInit(): void {
        // eslint-disable-next-line promise/catch-or-return
        PDFViewerApplication.initializedPromise.then(() => {
            PDFViewerApplication.overlayManager.enableDialogPosition = true;
            PDFViewerApplication.pdfOutlineViewer.disableOutline = true;

            this.proxyPdfEventsForAnnos();
            PDFViewerApplication.eventBus.on("pagesloaded", () => {
                // Since our enterprise version of PDF.JS seems to ignore the externalLinkTarget setting, we need to get a bit creative here
                document.querySelectorAll("div#viewerContainer a:not(a.internalLink)").forEach((x: HTMLLinkElement) => {
                    x.setAttribute("target", "_blank");
                });
            });
        });

        const element: JQuery<HTMLElement> = $(this.el.nativeElement);
        this.pdfjsToolbar = {...initialState};
        this.sub.add(this.messageService.subscribe(ViewerEvent.ATTACHMENTS_AVAILABLE, (payload: {newState: boolean; init: string}) => {
            const state = {
                [PdfjsToolbarAction.ATTACHMENTS_OPEN]: false,
                [PdfjsToolbarAction.SHOW_GO_TO_PARENT]: false,
                [PdfjsToolbarAction.ATTACHMENTS_AVAILABLE]: payload.newState
            };
            if(payload.init != "init" && payload.newState) {
                state[PdfjsToolbarAction.ATTACHMENTS_OPEN] = true;
                state[PdfjsToolbarAction.SHOW_GO_TO_PARENT] = true;
                state[PdfjsToolbarAction.SHOW_ATTACHMENTS] = false;

            } else if(payload.newState) {
                state[PdfjsToolbarAction.SHOW_ATTACHMENTS] = true;
            }
            this.updatePdfjsToolbarState(state);
        }));
        let authHeader = {};

        if (!this.clientService.isPhoneOrTablet()) {
            const viewContent: JQuery<HTMLElement> = angular.element(document).find("#pdfjsViewerContainer");
            viewContent.attr("os-anno", 1);
            this.$compile(viewContent)(this.$rootScope.$new());
        }

        this.sub.add(this.authenticationService.getStatusEvent().subscribe(ev => {
            if ((ev.state == AuthenticationState.SESSION_REFRESHED
                || ev.state == AuthenticationState.LOGGED_IN)
                && Array.isArray(ev.requestHeaders)) {
                authHeader = {};
                ev.requestHeaders.forEach(header => authHeader[header.key] = header.value);
            }
        }));

        this.viewerService.setViewerContainer(element);

        this.pdfjsViewerService.init(element);
        const viewer = this.pdfjsViewerService.getViewer();
        let toolBar = null,
            mainContainer = null;

        PDFViewerApplication.changeUrl = (url, isErrorPreview) => {
            if (this.window.location.href.includes("dashboard") && !this.window.location.href.includes("viewer.index") && !this.clientService.isMobile()) {
                return Promise.resolve();
            }

            if (this.isSearchOpen) {
                PDFViewerApplication.toggleSearch();
                PDFViewerApplication.findBar.toggle();
            }

            if (PDFViewerApplication.loadingBar) {
                PDFViewerApplication.loadingBar.show();
            }

            const promise = PDFViewerApplication.open(url, {httpHeaders: authHeader}).then(() => {
                if (PDFViewerApplication.pdfDocument == void 0 && viewer && !isErrorPreview) {
                    viewer.setPDFjsNoDocumentError();
                } else if (viewer) {
                    viewer.controlSidebarPreview();
                }
            }).catch(error => {
                viewer.setPDFjsNoDocumentError();
                console.warn(error);
            });

            this.$timeout(() => {
                // @ts-ignore
                angular.element(PDFViewerApplication.appConfig.mainContainer).scrollTop = 0;
            }, 100);

            return promise;
        };

        PDFViewerApplication.toggleSearch = (isFulltextSearch) => {
            if (PDFViewerApplication == void 0) {
                return;
            }
            this.$timeout(() => {
                this.isSearchOpen = !this.isSearchOpen;
                if (this.isSearchOpen) {
                    PDFViewerApplication.pdfSidebar.close();
                    angular.element(PDFViewerApplication.appConfig.mainContainer).css({
                        "margin-top": "74px",
                        "height": "calc(100% - 74px)"
                    });
                    angular.element(PDFViewerApplication.appConfig.mainContainer.firstElementChild).css("padding-top", "0px");
                    if (!isFulltextSearch) {
                        this.$timeout(() => {
                            PDFViewerApplication.appConfig.findBar.findField.focus();
                        }, 200);
                    }
                } else {
                    angular.element(PDFViewerApplication.appConfig.mainContainer).css({
                        "margin-top": "0px",
                        "height": "100%"
                    });
                    angular.element(PDFViewerApplication.appConfig.mainContainer.firstElementChild).css("padding-top", "20px");
                }
            }, 0);

        };

        PDFViewerApplication.showSearch = (searchKey) => {
            if (this.isExistingDocumentUrl()) {
                if (!this.isSearchOpen) {
                    PDFViewerApplication.toggleSearch(true);
                }
                angular.element(PDFViewerApplication.appConfig.findBar.findField).val(searchKey);
                PDFViewerApplication.findBar.toggle();
            } else if (this.isSearchOpen) {
                PDFViewerApplication.toggleSearch(true);
            }
        };

        PDFViewerApplication.hideTools = () => {
            if (mainContainer == void 0) {
                mainContainer = angular.element(PDFViewerApplication.appConfig.sidebar.mainContainer);
            }

            if (toolBar == void 0) {
                toolBar = element.find(".tools");
            }

            toolBar.hide();
            mainContainer.css({left: "0"});
            this.$timeout(() => {
                if (this.isSearchOpen) {
                    angular.element(PDFViewerApplication.appConfig.findBar.toggleButton).trigger("click");
                }
            }, 50);
        };

        PDFViewerApplication.showTools = () => {
            if (mainContainer == void 0) {
                mainContainer = angular.element(PDFViewerApplication.appConfig.sidebar.mainContainer);
            }

            if (toolBar == void 0) {
                toolBar = element.find(".tools");
            }

            mainContainer.css("left", "48px");
            toolBar.css("display", "flex");
        };
    }

    proxyPdfEventsForAnnos() {
        if (!this.clientService.isPhoneOrTablet()) {
            const viewContent: HTMLElement = document.getElementById("pdfjsViewerContainer");
            ["pagesloaded", "scalechange", "updateviewarea", "textlayerrendered"].forEach(eventName =>
                PDFViewerApplication.eventBus.on(eventName, (e) => viewContent.dispatchEvent(new CustomEvent(eventName, { detail: e }))));
        }
    }

    showPdfjsKebab(event: Event): void {
        event.stopImmediatePropagation();
        if (this.isPdfjsKebabOpen) {
            this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
            this.isPdfjsKebabOpen = false;
            return;
        } else {
            this.sub.add(this.messageService.subscribeFirst(InlineDialogEvent.CLOSE_INLINE_DIALOGS, () => {
                this.isPdfjsKebabOpen = false;
            }));
        }

        event.stopPropagation();
        this.isPdfjsKebabOpen = true;
    }

    private setToolbarKebabState() {
        clearTimeout(this.resizeTimer);

        this.resizeTimer = setTimeout(() => {
            const windowHeight: number = this.window.innerHeight;
            const menuItemMinHeight: number = 48;
            const visibleElementsCount: number = Math.floor((windowHeight - 78) / menuItemMinHeight) - 1; // -1 in case the annotation menu is shown
            this.updatePdfjsToolbarState({
                ...initialState,
                [PdfjsToolbarAction.ATTACHMENTS_OPEN]: this.pdfjsToolbar.attachmentsOpen,
                [PdfjsToolbarAction.SHOW_KEBAB]: this.pdfjsToolbar.showKebab,
                [PdfjsToolbarAction.ATTACHMENTS_AVAILABLE]: this.pdfjsToolbar.attachmentsAvailable,
                [PdfjsToolbarAction.SHOW_GO_TO_PARENT]: this.pdfjsToolbar.attachmentsOpen
            });
            const currentlyShown: PdfjsToolbarState = Object.assign({}, this.pdfjsToolbar);
            delete currentlyShown.attachmentsAvailable;
            delete currentlyShown.attachmentsOpen;
            let overflowCount: number = Object.values(currentlyShown).filter(x => x).length - visibleElementsCount;

            if(overflowCount <= 0 && currentlyShown.showKebab) {
                this.updatePdfjsToolbarState({[PdfjsToolbarAction.SHOW_KEBAB]: false});
            }
            while (overflowCount > 0 && Object.values(currentlyShown).filter(x => x).length > 1) {
                if (currentlyShown.showRotateLeft || currentlyShown.showRotateRight) {
                    currentlyShown.showRotateLeft = false;
                    currentlyShown.showRotateRight = false;

                    this.updatePdfjsToolbarState({
                        [PdfjsToolbarAction.SHOW_ROTATE_LEFT]: false,
                        [PdfjsToolbarAction.SHOW_ROTATE_RIGHT]: false,
                        [PdfjsToolbarAction.SHOW_KEBAB]: true,
                    });
                    overflowCount -= 2;

                } else if (currentlyShown.showRefresh) {
                    currentlyShown.showRefresh = false;

                    this.updatePdfjsToolbarState({[PdfjsToolbarAction.SHOW_REFRESH]: false});
                    overflowCount--;

                } else if (currentlyShown.showZoomIn || currentlyShown.showZoomOut) {
                    currentlyShown.showZoomIn = false;
                    currentlyShown.showZoomOut = false;

                    this.updatePdfjsToolbarState({
                        [PdfjsToolbarAction.SHOW_ZOOM_IN]: false,
                        [PdfjsToolbarAction.SHOW_ZOOM_OUT]: false
                    });
                    overflowCount -= 2;

                } else if (currentlyShown.showSearchBar) {
                    currentlyShown.showSearchBar = false;

                    this.updatePdfjsToolbarState({[PdfjsToolbarAction.SHOW_SEARCH_BAR]: false});
                    overflowCount--;
                } else if (currentlyShown.showSideBar) {
                    currentlyShown.showSideBar = false;

                    this.updatePdfjsToolbarState({[PdfjsToolbarAction.SHOW_SIDE_BAR]: false});
                    overflowCount--;
                } else if (currentlyShown.showAttachments || currentlyShown.showGoToParent){
                    currentlyShown.showAttachments = false;
                    currentlyShown.showGoToParent = false;

                    this.updatePdfjsToolbarState({
                        [PdfjsToolbarAction.SHOW_ATTACHMENTS]: false,
                        [PdfjsToolbarAction.SHOW_GO_TO_PARENT]: false
                    });
                    overflowCount -= 1;

                }
            }
            this.cdRef.detectChanges();
            // give 100ms browsers to complete initialization of the HTML elements.
        }, 100);
    }

    private initPdfjsToolbarStore(): void {
        this.updatePdfjsToolbarState({
            [PdfjsToolbarAction.SHOW_KEBAB]: false,
            [PdfjsToolbarAction.SHOW_SEARCH_BAR]: true,
            [PdfjsToolbarAction.SHOW_REFRESH]: true,
            [PdfjsToolbarAction.SHOW_SIDE_BAR]: true,
            [PdfjsToolbarAction.SHOW_ZOOM_OUT]: true,
            [PdfjsToolbarAction.SHOW_ZOOM_IN]: true,
            [PdfjsToolbarAction.SHOW_ROTATE_RIGHT]: true,
            [PdfjsToolbarAction.SHOW_ROTATE_LEFT]: true,
            [PdfjsToolbarAction.SHOW_ATTACHMENTS]: false,
            [PdfjsToolbarAction.SHOW_GO_TO_PARENT]: false,
            [PdfjsToolbarAction.ATTACHMENTS_AVAILABLE]: false,
            [PdfjsToolbarAction.ATTACHMENTS_OPEN]: false
        });


        this.sub.add(this.pdfjsToolbarStore.select(selectAvailableStates).subscribe(x => {
            this.pdfjsToolbar = x;
        }));
    }

    private updatePdfjsToolbarState(toolbarConfig: any): void {
        for (const key in toolbarConfig) {
            this.pdfjsToolbarStore.dispatch(updatePdfjsToolbar({
                pdfjsToolbarItemType: key as PdfjsToolbarAction,
                payload: toolbarConfig[key]
            }));
        }
    }

    toggleAttachments() {
        this.pdfjsViewerService.toggleAttachmentsPreview();
    }

    isExistingDocumentUrl() {
        return !(!PDFViewerApplication || !PDFViewerApplication.url
            || PDFViewerApplication.url.match(/eob-framework\/component/i));
    }

    ngAfterViewInit(): void {
        this.setToolbarKebabState();
        this.controls = {
            search: this.search.nativeElement.click.bind(this.search.nativeElement),
            refresh: this.refresh.nativeElement.click.bind(this.refresh.nativeElement),
            sidebar: this.sidebar.nativeElement.click.bind(this.sidebar.nativeElement),
            zoomOut: this.zoomOut.nativeElement.click.bind(this.zoomOut.nativeElement),
            zoomIn: this.zoomIn.nativeElement.click.bind(this.zoomIn.nativeElement),
            rotateRight: this.rotateRight.nativeElement.click.bind(this.rotateRight.nativeElement),
            rotateLeft: this.rotateLeft.nativeElement.click.bind(this.rotateLeft.nativeElement),
            attachments: this.attachments.nativeElement.click.bind(this.attachments.nativeElement),
            goToParent: this.goToParent.nativeElement.click.bind(this.goToParent.nativeElement),
        };
    }


    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
