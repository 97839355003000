export enum FieldControlType {
  UNKNOWN = "",
  RADIO = "radio",
  CHECKBOX = "checkbox",
  GRID = "grid",
  PAGE_CONTROL = "pagecontrol",
  STATIC = "static",
  GROUP = "group",
  BUTTON = "button"
}

export enum FieldDataType {
  DATE = "date",
  TIME = "time",
  TEXT = "text",
  LETTER = "letter",
  NUMBER = "number",
  CHOICE = "choice",
  CAPITAL = "capital",
  DECIMAL = "decimal",
  ALPHANUM = "alphanum",
  DATETIME = "datetime"
}

export enum FieldAddon {
  DB = "db",
  USER = "user",
  TREE = "tree",
  LIST = "list",
  DATE = "date",
  HIERARCHY = "hierarchy",
  ADDRESS = "addressAddon",
  DATETIME = "dateTimeAddon",
  RIGHTGROUPS = "rightGroups",
  QUICKFINDER = "quickfinder",
  ORGANIZATION = "organisation",
  RIGHTGROUPSOLD = "rightGroupsOld"
}

export enum RawFiledType {
    DATE = "D",
    NUMBER = "9",
    DECIMAL = "#",
    TEXT = "X",
}