import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";

export interface FieldMaskValue {
    rendererValue: FormInputValue;
    value: FormInputValue;
    invalid?: boolean;
}

export abstract class FieldMask {
    formatFocus(value: FormInputValue): FormInputValue {
        return value;
    }

    formatBlur(maskValue: FieldMaskValue): FieldMaskValue {
        return { value: maskValue.value, rendererValue: maskValue.rendererValue };
    }
}

export abstract class CorrectionFieldMask extends FieldMask {
    formatBlur({ value, rendererValue }: FieldMaskValue): FieldMaskValue {
        if (value === rendererValue) {
            const parsedValue: FormInputValue = value ? this.parseValue(value) : value;
            return {
                value: parsedValue,
                rendererValue: parsedValue
            };
        }

        return {
            value: value ? this.parseValue(value) : value,
            rendererValue: rendererValue ? this.parseValue(rendererValue) : rendererValue
        };
    }

    protected abstract parseValue(value: FormInputValue): FormInputValue;
}
