import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {Subject} from "rxjs";

require("COMPONENTS_PATH/eob-modal-container/eob-modal-promt/eob.modal.promt.dir.js");

require("SERVICES_PATH/form/eob.form.srv.js");
require("SERVICES_PATH/eob.search.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/eob.modal.dialog.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/dms/eob.variant.srv.js");

angular.module("eob.core").factory("desktopService", DesktopService);

DesktopService.$inject = ["$filter", "$compile", "$rootScope", "toolService", "sortService", "clientService",
    "asIniService", "actionService", "searchService", "backendService", "objectTypeService", "environmentService",
    "modalDialogService", "notificationsService", "cacheManagerService", "variantService", "desktopQueryService", "stateHistoryManager", "errorModelService", "dmsActionService", "modalDialogInjectorService", "queryBuilderService"];

/* eslint-disable */
export default function DesktopService($filter, $compile, $rootScope, ToolService, SortService, ClientService,
                                       AsIniService, ActionService, SearchService, BackendService, ObjectTypeService, EnvironmentService,
                                       ModalDialogService, NotificationsService, CacheManagerService, VariantService, DesktopQueryService, StateHistoryManager, ErrorModelService, DmsActionService, ModalDialogInjectorService, QueryBuilderService) { /* eslint-enable */

    let gDesktops = {},
        gAsIniConfig = {},
        gDmsItems = {},
        gDmsIds = [],
        gQueries = {},
        gFolder = {},
        gParamQueries = {},
        gSelectedDesktopItem = null,
        gIsDesktopMenuOpen = false,
        gListenerGuid,
        gInactiveVariants = [],
        gInitializationPromise = null;

    const service = {
        initAsync,
        addQuery,
        moveItem,
        isMovable,
        removeItem,
        renameItem,
        saveQuery,
        getQueryById,
        markSelected,
        executeAction,
        getQuickSearches,
        addItemToDesktop,
        updateFolderState,
        getDesktopFolderIcon,
        getAllDesktopQueries,
        getPublicDesktopQueries,
        getPrivateDesktopQueries,
        getPrivateDesktopFolder,
        getPublicDesktopFolder,
        isDesktopContextmenuOpen,
        removeAndDeleteDesktopItem
    }

    return service;

    /**
     * Request all desktops and build them.
     *
     * @returns {Promise} A promise that is resolved when the desktops are built.
     */
    function initAsync() {
        if (gInitializationPromise == null) {
            gInitializationPromise = new Promise((resolve, reject) => {
                setTimeout(async () => {
                    try {
                        if (ClientService.isOffline()) {
                            throw ErrorModelService.createCustomError("WEB_OFFLINE_ERROR");
                        }

                        gAsIniConfig = AsIniService.getDesktopFolderState();

                        const response = await BackendService.get("/session/userdesktops?refresh=true&verbose=true");
                        let privateDesktopData = response.data["private desktop"][0] || {};
                        let publicDesktopData = response.data["public desktop"][0] || {};
                        sortStoredQueryFields(publicDesktopData.children)
                        sortStoredQueryFields(privateDesktopData.children)

                        buildDesktop(publicDesktopData, true);
                        buildDesktop(privateDesktopData, false);

                        await setActiveVariantsAsync();
                        gListenerGuid = CacheManagerService.dmsDocuments.attachListener(gDmsIds, onDmsDocumentsChanged);
                        resolve();
                    } catch (error) {
                        reject(error);
                        gInitializationPromise = null;
                    }
                }, 0);
            });
        }

        return gInitializationPromise;
    }

    /**
     * Sorts parameters of queries according to their index or, if applicable, container GUID order inside the object type
     * Note: Sorting happens in-place
     * @param desktopEntries `children` array of a desktop object
     */
    function sortStoredQueryFields(desktopEntries) {
        if (!Array.isArray(desktopEntries)) {
            return;
        }
        desktopEntries.forEach(desktopEntry => {
            if (desktopEntry.type != "QUERY") {
                return;
            } else if (Array.isArray(desktopEntry.fields)) {
                desktopEntry.fields.forEach(queryObjectType => {
                    const objType = CacheManagerService.objectTypes.getById(queryObjectType.objectTypeId);
                    if (!objType) {
                        return;
                    }
                    let containerEncountered = !!queryObjectType.fields.find(x => x.containerGUID);
                    if (!containerEncountered) {
                        queryObjectType.fields.sort((f1, f2) => {
                            return f1.index > f2.index ? 1 : -1;
                        })
                    } else {
                        /**
                         * We need to reduce the array in two steps. Groups contain their fields as children,
                         * however they are relevant for the top level tab index (hence the order for displaying
                         * the fields).
                         * After flattening those fields, they are sorted, before being enriched with pagecontrol fields,
                         * since their ids should be put directly after the pagecontrol itself to simplify sorting afterwords.
                         * The pagecontrol fields have their own overlapping tab index, which would interfere with the sorting
                         * of top level fields.
                         */

                        const flattenedGroups = objType.model.combinedResponsiveFields.reduce((acc, val) => {
                            acc.push(val);
                            if (Array.isArray(val.fields)) {
                                // Yes, sometimes we can encounter stray strings ¯\_(ツ)_/¯
                                val.fields.filter(x => x.os_guid).forEach(z => {
                                    acc.push(z)
                                })
                            }
                            return acc;
                        }, []);
                        flattenedGroups.sort((a, b) => a.taborder > b.taborder ? 1 : -1);
                        const fieldGuids = flattenedGroups.reduce((acc, val) => {
                            let children = []
                            if (Array.isArray(val.page)) {
                                val.page.forEach(page => {
                                    children.push(page.page_id);
                                })
                            }
                            acc.push(val.os_guid, ...children);
                            return acc;
                        }, []);

                        queryObjectType.fields.sort((field1, field2) => {
                            const guid1 = field1.containerGUID || flattenedGroups.find(z => !z.page && z.taborder == field1.index).os_guid;
                            const guid2 = field2.containerGUID || flattenedGroups.find(z => !z.page && z.taborder == field2.index).os_guid;
                            if (field1.containerGUID == field2.containerGUID) {
                                // Sort fields inside a page control
                                return field1.index > field2.index ? 1 : -1;
                            }
                            const i1 = fieldGuids.findIndex(x => x == guid1)
                            const i2 = fieldGuids.findIndex(x => x == guid2)
                            return i1 == i2 ? 0 : i1 > i2 ? 1 : -1;
                        });
                    }
                });
            }
        });
    }

    function bindGlobalClickListeners() {
        angular.element(document.body).bind("mousedown", listener);
        gIsDesktopMenuOpen = true;
    }

    function listener() {
        if (gSelectedDesktopItem != void 0) {
            gSelectedDesktopItem.removeClass("selected");
            gSelectedDesktopItem = null;

            angular.element(document.body).unbind("mousedown");

            // clear the variable after 100 ms to ensure, the pinned navigation can do its stuff before we toggle the state
            setTimeout(() => {
                gIsDesktopMenuOpen = false;
            }, 100);
        }
    }

    function isDesktopContextmenuOpen() {
        return gIsDesktopMenuOpen;
    }

    function isEmptyFolder(item) {
        let isEmpty = true;

        for (let i in item.content) {
            if (item.content[i].length > 0) {
                isEmpty = false;
                break;
            }
        }

        return isEmpty;
    }

    function getPrivateDesktopFolder() {
        return gDesktops["private"];
    }

    function getPublicDesktopFolder() {
        return gDesktops["public"];
    }

    function getAllDesktopQueries() {
        return gQueries;
    }

    function getPublicDesktopQueries() {
        return _getFilteredDesktopQueries(true);
    }

    function getPrivateDesktopQueries() {
        return _getFilteredDesktopQueries(false);
    }

    function _getFilteredDesktopQueries(publicProperty) {
        let filteredQueries = {};
        for (let query of Object.keys(gQueries)) {
            if (!!gQueries[query].isPublic == publicProperty) {
                filteredQueries[query.id] = gQueries[query];
            }
        }
        return filteredQueries;
    }

    function getQuickSearches(quicksearchConfig) {
        return DesktopQueryService.buildDesktopQuicksearches(gParamQueries, quicksearchConfig);
    }

    function getQueryById(queryId) {
        let result;
        let queries = getAllDesktopQueries();

        for (let i in queries) {
            let query = queries[i];

            if (queryId == query.id) {
                result = query;
                break;
            }
        }

        if (result == void 0) {
            result = getParamQueryById(queryId);
        }

        return result;
    }

    function getParamQueryById(queryId) {
        let result;
        let paramQueries = getQuickSearches();

        for (let i = 0; i < paramQueries.length; i++) {
            let paramQuery = paramQueries[i];

            if (queryId == paramQuery.id) {
                result = paramQuery;
                break;
            }
        }

        return result;
    }

    function markSelected(event) {
        gSelectedDesktopItem = angular.element(event.target).closest("a");
        gSelectedDesktopItem.addClass("selected");

        bindGlobalClickListeners();
    }

    function executeAction(item) {
        if (item.type == "query") {

            if (item.isCorrupted) {
                NotificationsService.error($filter("translate")("nav.desktop.corrupted.query"));
                return;
            } else if (item.isInvalid) {
                NotificationsService.error($filter("translate")("nav.desktop.invalid.query"));
                return;
            }

            ActionService.executeSavedQuery(item);
        } else if (item.type == "document") {
            let info = {
                title: $filter("translate")("eob.desktop.abandoned.modal.remove.title"),
                msg: $filter("translate")("eob.desktop.abandoned.modal.remove.message"),
                cancelButton: $filter("translate")("modal.button.no"),
                submitButton: $filter("translate")("modal.button.yes")
            };

            if (CacheManagerService.dmsDocuments.getById(item.osid) == void 0) {
                if (item.isPublic) {
                    NotificationsService.warning($filter("translate")("eob.desktop.abandoned.modal.remove.message.readonly"));
                    return;
                }

                (async () => {
                    try {
                        await ModalDialogService.infoDialog(info.title, info.msg, info.cancelButton, info.submitButton);
                    } catch (error) {
                        // user does not want to delete it
                        return;
                    }
                    removeItem(item, true);
                })();
            } else if (!ObjectTypeService.isTypeless(item.objectTypeId)) {
                ActionService.executeDoubleClickAction(CacheManagerService.dmsDocuments.getById(item.osid), "desktop", false, "doubleclick");
            }
        }
    }

    /**
     * Parse the backend data to a webclient desktop.
     *
     * @param {object} backendData - The backend data for a desktop.
     * @param {boolean} isPublic - Is the desktop public.
     */
    function buildDesktop(backendData, isPublic) {
        let key = isPublic ? "public" : "private";

        let desktop = {
            id: backendData.id,
            parentId: backendData.id,
            name: $filter("translate")(`nav.desktop.${key}.title`),
            profile: backendData.profile,
            expanded: true,
            isRoot: true,
            isPublic,
            type: "folder",
            icon: "empty-register"
        };

        gDesktops[key] = desktop;

        desktop.content = buildChildren(backendData.children, desktop, isPublic);
        desktop.isEmpty = isEmptyFolder(desktop);

        gFolder[desktop.id] = desktop;
    }

    /**
     * Build all children of a folder, sort and return them.
     *
     * @param {object} items - The backend data children.
     * @param {DesktopFolder} parent - The desktop item folder, that contains the children.
     * @param {boolean} isPublic - Is the folder public.
     * @returns {object} The parsed content object of the folder.
     */
    function buildChildren(items, parent, isPublic) {
        let content = {
            folder: [],
            documents: [],
            queries: [],
            paramqueries: [],
            unsupported: []
        };

        for (let i in items) {
            let item = items[i];
            buildItem(item, parent, content, isPublic);
        }

        sortContent(content);

        return content;
    }

    function buildItem(backendDesktopItem, parent, content, isPublic) {
        let id = backendDesktopItem.objectId || backendDesktopItem.id;
        let desktopItem = {
            name: backendDesktopItem.name,
            id,
            parentId: backendDesktopItem.folderId,
            objectTypeId: backendDesktopItem.objectType,
            profile: backendDesktopItem.profile,
            isPublic,
            context: "desktop"
        };

        switch (backendDesktopItem.type) {
            case "FOLDER":
                desktopItem.type = "folder";
                desktopItem.expanded = !!gAsIniConfig[backendDesktopItem.id];
                desktopItem.content = buildChildren(backendDesktopItem.children, desktopItem, isPublic);
                desktopItem.isEmpty = isEmptyFolder(desktopItem);
                desktopItem.icon = getDesktopFolderIcon(desktopItem);
                gFolder[backendDesktopItem.id] = desktopItem;
                content.folder.push(desktopItem);
                break;
            case "OBJECT" :
                desktopItem.type = "document";
                desktopItem.osid = id;
                desktopItem.position = backendDesktopItem.position;
                desktopItem.sysMode = backendDesktopItem.sysMode;

                addEcmObject(desktopItem, backendDesktopItem);

                gDmsItems[desktopItem.name] = desktopItem;

                if (EnvironmentService.env.desktop.showObjects) {
                    content.documents.push(desktopItem);
                }

                break;
            case "QUERY" :
                desktopItem.type = "query";
                desktopItem.icon = "unparameterized-query";
                desktopItem.expert = backendDesktopItem.expert;
                desktopItem.id = backendDesktopItem.id;
                desktopItem.activePage = backendDesktopItem.activePage;

                if (backendDesktopItem.activePage >> 16 > 99) {
                    for (let i in backendDesktopItem.fields) {
                        let type = backendDesktopItem.fields[i];

                        if (type.objectTypeId >> 16 < 100) {
                            backendDesktopItem.activePage = type.objectTypeId;
                            desktopItem.activePage = type.objectTypeId;
                            break;
                        }
                    }
                }

                desktopItem.formDataTypes = DesktopQueryService.buildDesktopQueryFormDataTypes(backendDesktopItem, desktopItem);

                if (backendDesktopItem.var) {
                    desktopItem.fields = backendDesktopItem.fields;
                    gParamQueries[backendDesktopItem.id] = desktopItem;
                    content.paramqueries.push(desktopItem);
                    break;
                }

                gQueries[backendDesktopItem.id] = desktopItem;

                if (EnvironmentService.env.desktop.showQueries) {
                    content.queries.push(desktopItem);
                }

                break;
            default:
                content.unsupported.push(backendDesktopItem);
        }
    }

    /**
     * Finds out whether an object has been deleted while
     * it still might be found in the recycle bin.
     *
     * I more a workaround because the server API is not
     * consistent.
     *
     * @return {boolean}
     */
    function isAbandoned(ecmObject) {
        if (ecmObject == void 0) {
            return true;
        }

        if (ecmObject.api) {
            return false;
        }

        if (ecmObject.objectType === "REGISTER") {
            return ecmObject.systemFields.find(systemField => {
                if (systemField.type === "OBJECT_DELETED") {
                    return systemField.value > 0;
                }
            })
        }

        return false;
    }

    /**
     * Add the corresponding dmsDocument to the cache and set the item icon.
     *
     * @param {DesktopItem} item - The desktop item.
     * @param {object} backendData - The backend data for the desktop item.
     */
    function addEcmObject(item, backendData) {
        if (CacheManagerService.objectTypes.contains(item.objectTypeId)) {
            if (isAbandoned(backendData.ecmObject)) {
                item.icon = ObjectTypeService.getIconClass(item.objectTypeId);
                return;
            }

            let osid = item.osid,
                dmsDocument = CacheManagerService.dmsDocuments.getById(osid);

            if (dmsDocument == void 0) {
                CacheManagerService.dmsDocuments.add(backendData.ecmObject);

                dmsDocument = CacheManagerService.dmsDocuments.getById(osid);
            }

            // item.icon = dmsDocument.model.icon;
            item.icon = ObjectTypeService.getDefaultMainTypeIcon(dmsDocument.model.mainType);

            // always show the contextmenu actions for the active variant in the desktop
            if (dmsDocument.model.variantData != void 0 && !dmsDocument.model.variantData[0].model.isActive) {
                item.originalOsid = item.osid;
                item.osid = VariantService.getActiveVariantId(item.osid);
                item.id = item.osid;

                // request all missing ecm objects together later
                if (gInactiveVariants.indexOf(item) < 0) {
                    gInactiveVariants.push(item);
                }
            }

            if (gDmsIds.indexOf(item.osid) < 0) {
                gDmsIds.push(item.osid)
            }
        }
    }

    function sortContent(content) {
        content.folder.sort(nameSort);
        content.documents.sort(nameSort);
        content.queries.sort(nameSort);

        function nameSort(a, b) {
            return SortService.textComparator(a.name, b.name);
        }
    }

    /**
     * Callback for dmsDocument change listener.
     *
     * @param {string[]} updatedIds - The updated dms object osids.
     */
    function onDmsDocumentsChanged(updatedIds) {
        for (let id of updatedIds) {
            let dmsDocument = CacheManagerService.dmsDocuments.getById(id);

            // dmsDocument was deleted
            if (dmsDocument == void 0) {
                for (let i in gDmsItems) {
                    let dmsItem = gDmsItems[i];

                    if (dmsItem.osid == id) {
                        gDmsIds.splice(gDmsIds.indexOf(dmsItem.osid), 1);
                    }
                }
            }
            // active Variant changed
            else if (dmsDocument.model.variantData != void 0 && dmsDocument.model.variantData.length > 0 && dmsDocument.model.variantData[0].model && !dmsDocument.model.variantData[0].model.isActive) {
                for (let i in gDmsItems) {
                    let dmsItem = gDmsItems[i];

                    if (dmsItem.osid == dmsDocument.model.id) {
                        let activeId = VariantService.getActiveVariantId(dmsItem.id);
                        dmsItem.originalOsid = dmsItem.originalOsid || dmsItem.osid;
                        dmsItem.osid = activeId;
                        dmsItem.id = activeId;

                        gDmsIds.splice(gDmsIds.indexOf(dmsItem.originalOsid), 1);

                        if (gDmsIds.indexOf(dmsItem.osid) < 0) {
                            gDmsIds.push(dmsItem.osid);
                        }
                    }
                }
            }
        }

        CacheManagerService.dmsDocuments.updateListener(gListenerGuid, gDmsIds);
    }

    /**
     * Research missing active variants and add them to the dms cache.
     *
     * @returns {Promise} - A promise resolved with the given content.
     */
    async function setActiveVariantsAsync() {
        let missingData = gInactiveVariants.filter(item => !CacheManagerService.dmsDocuments.getById(item.osid)) || [];

        // add missing dmsDocuments to the cache
        let {result, warning, error} = await SearchService.searchByIdsAsync(missingData);

        if (warning) {
            this.notificationsService.warning(warning);
        }

        if (error) {
            this.notificationsService.error(error);
        }

        return result;
    }

    function addItemToDesktop(desktopData) {
        if (!desktopData) {
            return;
        }

        let dmsDocument;
        if (desktopData.dmsItem) {
            dmsDocument = CacheManagerService.dmsDocuments.getById(desktopData.dmsItem.model.osid);
        }
        let itemParent = desktopData.contextMenuItem;
        let namingSuggestion = "";
        let desktopItem = {};

        if (itemParent === void 0) {
            desktopItem.folderId = desktopData.type == "folder" ? desktopData.id : desktopData.parentId;
        } else {
            desktopItem.folderId = itemParent.type == "folder" ? itemParent.id : itemParent.parentId;
        }

        // building the naming suggestion in case the dms document has fields and so on ...
        if (dmsDocument) {
            namingSuggestion = dmsDocument.api.buildNameFromIndexData(3, false, true);
            desktopItem.objectId = dmsDocument.model.osid;
            desktopItem.objectType = dmsDocument.model.objectTypeId;
            desktopItem.type = "OBJECT";
        }

        let newItem = { name: namingSuggestion };
        if (desktopItem.type == void 0) {
            desktopItem.type = "FOLDER";
        }

        if (desktopItem.type == "FOLDER") {
            newItem.type = "folder";
        } else if (desktopItem.type == "OBJECT") {
            newItem.type = "document";
        }

        let msg = $filter("translate")("eob.action.modal.desktop.add.message"),
            title = $filter("translate")("eob.action.modal.desktop.add.title");

        showNamingPrompt(newItem, msg, title, async (name) => {
            desktopItem.name = name;

            let url = "/session/userdesktops/add";

            BackendService.post(url, desktopItem).then((response) => {
                let res = response.data;

                if (desktopItem.type == "FOLDER") {
                    desktopItem.id = res.id;
                } else if (desktopItem.type == "OBJECT") {
                    desktopItem.name = res.name;
                }

                let itemData = {
                    dmsDocument,
                    desktopItem
                };

                addItem(itemData, false);

                let parentContent = gFolder[res.folderId].content;
                sortContent(parentContent);
                $rootScope.$apply();
                return;
            }, (error) => {
                return NotificationsService.backendError(error, "eob.action.add.to.desktop.error.message");
            }).catch(err => console.error(err));
        });
    }

    function addItem(data, isPublic) {
        let item = data.desktopItem;

        if (data.dmsDocument) {
            item.ecmObject = data.dmsDocument;
        }

        if (item.folderId == null) {
            item.folderId = gDesktops.private.id;
        }

        let parent = gFolder[item.folderId];
        let content = parent.content;

        buildItem(item, parent, content, isPublic);

        if (item.type == "OBJECT") {
            CacheManagerService.dmsDocuments.updateListener(gListenerGuid, gDmsIds);
        }

        parent.isEmpty = isEmptyFolder(parent);

        expandParent(parent);

    }

    function addQuery(data, searchQuery) {
        let currentItem = {
            type: "query"
        };

        let msg = $filter("translate")("eob.action.modal.desktop.add.message"),
            title = $filter("translate")("eob.action.modal.desktop.add.title");

        showNamingPrompt(currentItem, msg, title, async (queryName) => {
            let url = `/documents/storedqueries/add?name=${window.encodeURIComponent(queryName.replace(/\\/g, "\\\\"))}`;

            transformTimestampExpressionToRange(searchQuery);

            try {
                const response = await BackendService.post(url, searchQuery);

                let res = response.data;
                let queryItem = {
                    name: queryName,
                    icon: 0,
                    folderId: null,
                    profile: "",
                    id: res.queryId,
                    var: null,
                    type: "QUERY"
                };

                addItem({ desktopItem: queryItem }, false);
                let parentContent = gFolder[gDesktops.private.id].content;
                let newQuery = getQueryById(res.queryId);

                newQuery.activePage = data.activePage;
                newQuery.formDataTypes = data.formDataTypes;
                newQuery.objectTypeIds = data.objectTypeIds;

                sortContent(parentContent);
                $rootScope.$broadcast("update.queries");

                NotificationsService.success($filter("translate")("eob.query.saver.save.success"));

                $rootScope.$apply();
            } catch (error) {
                return NotificationsService.backendError(error, "eob.query.saver.save.error");
            }
        });
    }

    function transformTimestampExpressionToRange(query) {
        let baseparams = query.query.baseparams;

        for (let baseparamName in baseparams) {
            let baseparam = baseparams[baseparamName];

            if (ToolService.isBaseParamDateTimeField(baseparam.internalName)
                && baseparam.value != void 0
                && baseparam.value.length > 0) {
                baseparam.value = ToolService.getRangeByExpression(baseparam.value);
            }
        }
    }

    function showNamingPrompt(currentItem, msg, title, finalizeCallback) {
        let suggestion = currentItem.name != void 0 ? currentItem.name.replace(/&lt;/g, "<").replace(/&gt;/g, ">") : ""
        let confirmObject = {
            message: msg,
            callback: namingCallback,
            finalize: finalizeCallback,
            suggestion,
            currentItem
        };

        let modalScope = $rootScope.$new();
        modalScope.buttons = {
            hideCancelButton: false,
            hideOkButton: false
        };
        modalScope.confirmObject = confirmObject;
        modalScope.destroy = new Subject();

        ModalDialogInjectorService.createDialogAJS(EobModalContainerComponent, {
            input: {
                title
            },
            childElement: angular.element("<eob-modal-promt buttons=\"buttons\" confirm-object=\"confirmObject\" destroy=\"destroy\"></eob-modal-promt>"),
            scope: modalScope
        });
    }

    function isNameAvailable(type, newName) {
        let items = {};

        switch (type) {
            case "document":
                items = gDmsItems;
                break;
            case "folder" :
                items = gFolder;
                break;
            case "query":
                items = gQueries;
                break;
        }

        for (let i in items) {
            if (items[i].name == newName) {
                return false;
            }
        }

        return true;
    }

    function namingCallback(object) {
        let currentItem = object.currentItem;
        let newName = object.message;

        if (isNameAvailable(currentItem.type, newName) || (currentItem.name == newName && currentItem.id)) {
            object.notifyCallback(true);
            object.finalize(newName);
        } else {
            object.notifyCallback(false, $filter("translate")("eob.desktop.promt.name.already.taken.error"));
        }
    }

    // we need to find out if the destination is a child of the currentitem
    function isMovable(item, destination) {
        let next = destination.parentId;

        if (item.parentId == destination.id) {
            return false;
        }

        do {
            if (gFolder[next].id == item.id) {
                return false;
            } else {
                next = gFolder[next].parentId;
            }
        } while (!gFolder[next].isRoot);

        return true;
    }

    function moveItem(item) {
        let clipboard = EnvironmentService.getClipboard();
        if (clipboard.clipboardAction && clipboard.clipboardAction === "copy") {
            let dmsMenuItem = $.extend({}, item);
            dmsMenuItem.dmsItem = clipboard.item;
            addItemToDesktop(dmsMenuItem);
            return;
        }
        let currentItem = findItem(clipboard.item);
        let newParent = gFolder[item.id];
        let oldParent = gFolder[currentItem.parentId];
        let newParentContent = getFittingItemContent(newParent, currentItem);

        removeFromFolder(clipboard.item);
        EnvironmentService.clearClipboard();

        newParentContent.push(currentItem);

        currentItem.parentId = item.id;
        sortContent(newParent.content);

        newParent.isEmpty = isEmptyFolder(newParent);
        expandParent(newParent);
        oldParent.isEmpty = isEmptyFolder(oldParent);
        expandParent(oldParent);
        save();
    }

    function findItem(item) {
        let parent = gFolder[item.parentId];
        let content = getFittingItemContent(parent, item);

        for (let i in content) {
            let entry = content[i];

            if (entry.id == item.id && entry.name == item.name) {
                return entry;
            }
        }

        return null;
    }

    function getFittingItemContent(parent, item) {
        switch (item.type) {
            case "query":
                return parent.content.queries;
            case "folder":
                return parent.content.folder;
            case "document":
                return parent.content.documents;
        }
    }

    function renameItem(item) {
        let currentItem = findItem(item);

        if (currentItem == null) {
            console.error("item could not be found");
            return;
        }

        let msg = $filter("translate")("eob.desktop.rename.item.message"),
            title = $filter("translate")("eob.desktop.rename.item.title");

        showNamingPrompt(currentItem, msg, title, (newName) => {
            currentItem.name = newName;
            let parent = gFolder[currentItem.parentId];
            sortContent(parent.content);
            $rootScope.$broadcast("update.queries");
            save();
        });
    }

    function removeFromFolder(item) {
        let parent = gFolder[item.parentId];
        let content = getFittingItemContent(parent, item);

        for (let i = 0; i < content.length; i++) {
            if (((content[i].id == item.id) || ((item.id == void 0) && (content[i].parentId == item.parentId))) && (content[i].name == item.name)) {
                content.splice(i, 1);
                break;
            }
        }

        parent.isEmpty = isEmptyFolder(parent);

        expandParent(parent);

    }

    function removeDesktopItem(item) {
        removeFromFolder(item);

        if (item.type == "folder") {
            delete gFolder[item.id];
        } else if (item.type == "query") {
            delete gQueries[item.id];
        } else if (item.type == "document") {
            delete gDmsItems[item.name];
            gDmsIds = gDmsIds.splice(gDmsIds.indexOf(item.id), 1);
            CacheManagerService.dmsDocuments.updateListener(gListenerGuid, gDmsIds);
        }
    }

    async function removeItem(item, skipModal) {
        if (skipModal) {
            removeDesktopItem(item);
            save();
        } else {
            let confirmMsgKey = "eob.action.modal.desktop.remove.confirm.message";
            let cancelButton = $filter("translate")("modal.button.cancel");
            let removeButtonTitle = $filter("translate")("eob.contextmenu.action.remove.from.desktop.title");

            try {
                await ModalDialogService.infoDialog($filter("translate")("eob.contextmenu.action.remove.from.desktop.title"), $filter("translate")(confirmMsgKey), cancelButton, removeButtonTitle);
            } catch (error) {
                // user does not want to remove the item
                return;
            }

            removeDesktopItem(item);
            save();
        }
    }

    async function removeAndDeleteDesktopItem(item) {
        let dmsDocument = await CacheManagerService.dmsDocuments.getOrFetchById(item.osid);
        let deleteSuccess = await DmsActionService.deleteDmsObjects([dmsDocument], undefined);
        if (deleteSuccess) {
            removeDesktopItem(item);
        }
        save();
    }

    function expandParent(parent) {
        if (!parent.isRoot) {
            parent.expanded = true;
            parent.icon = getDesktopFolderIcon(parent);
            updateFolderState(parent);
        }
    }

    function getDesktopFolderIcon(parent) {
        return parent.isEmpty ? "empty-register" : parent.expanded == true ? "nav-register-expanded" : "nav-register-collapsed";
    }

    function updateFolderState(item) {
        AsIniService.setDesktopFolderState(item.id, item.expanded);
    }

    async function save() {
        let payload = {
            "private desktop": [{
                children: prepareDesktopForAsIni(gDesktops.private).map(x => {
                    x.name = x.name.replace(/\\/g, "\\\\");
                    return x
                }),
                id: gDesktops.private.id,
                isPrivate: true,
                name: "private desktop",
                sysMode: 0,
                type: "FOLDER"
            }]
        };

        try {
            await BackendService.post("/session/userdesktops/save", payload);
            // success !? ... doi nothing at this point... for now ;)
            // TODO On folder rename desktop should be updated - Andrej 08.01.2018
        } catch (error) {
            NotificationsService.backendError(error, "eob.desktop.save.error");
        }
    }

    function prepareDesktopForAsIni(folder) {
        let children = [];

        for (let key in folder.content) {
            let typeContents = folder.content[key];

            for (let desktopItem of typeContents) {
                children.push(getBackendDesktopItem(desktopItem));

                if (key == "folder") {
                    let sub = prepareDesktopForAsIni(desktopItem);
                    children = children.concat(sub);
                }
            }
        }

        return children;
    }

    function getBackendDesktopItem(desktopItem) {
        let type = desktopItem.type,
            typeMap = { "folder": "FOLDER", "document": "OBJECT", "query": "QUERY", "paramqueries": "QUERY" };

        if (Object.keys(typeMap).indexOf(type) < 0) {
            return desktopItem;
        }

        let backendItem = {
            id: desktopItem.id,
            name: desktopItem.name,
            folderId: desktopItem.parentId,
            type: typeMap[type]
        };

        if (type == "folder") {
            backendItem.profile = desktopItem.profile;
        } else if (type == "document") {
            Object.assign(backendItem, {
                profile: desktopItem.profile,
                objectId: desktopItem.originalOsid || desktopItem.osid,
                objectType: desktopItem.objectTypeId,
                position: desktopItem.position,
                sysMode: desktopItem.sysMode
            });
        }

        return backendItem;
    }

    function saveQuery() {
        if (ClientService.isOffline()) {
            NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
        } else {
            let state = StateHistoryManager.getCurrentStateData();
            state.data.context = "save";
            let query = QueryBuilderService.createFormDataQuery(state.data);
            service.addQuery(state.data, query);
        }
    }
}
