<div role="banner" class="dialog-header">
    <div class="dialog-header-content">
        <h2 class="state-title">{{stateTitle}}</h2>
        <h3 class="header-description" #headerdescription [attr.lang]="showAltLang ? objectDefLang : null" [hidden]="!description">{{description}}</h3>
    </div>
    <div class="full-description-box" *ngIf="fullDescriptionVisible">
        <div class="little-triangle"></div>
        <span>{{description}}</span>
    </div>
    <div class="state-header-plugins">
        <ng-content></ng-content>
    </div>
</div>