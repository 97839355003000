/**
 * The types of fields that can appear on forms
 */
export enum FormFieldType {
    ALPHANUMERIC = "alphanum",
    BUTTON = "button",
    CAPITAL = "capital",
    CHECKBOX = "checkbox",
    CHOICE = "choice",
    DATE = "date",
    DATETIME = "datetime",
    DECIMAL = "decimal",
    GRID = "grid",
    GROUP = "group",
    LETTER = "letter",
    NUMBER = "number",
    PAGECONTROL = "pagecontrol",
    RADIO = "radio",
    STATIC = "static",
    TEXT = "text",
    TIME = "time"
}
