/**
 * Synchronization status for the complete sync process.
 */
export enum OverallSyncStatus {
    /* The synchronization is not running */
    NONE,
    /* The Synchronization is currently running */
    RUNNING,
    /* The Synchronization is aborted due to user interaction */
    ABORTING_USER,
    /* The synchronization has been aborted due to depleted quota */
    ABORTING_QUOTA,
    /* The synchronization has been aborted due to connectivity */
    ABORTING_OFFLINE,
    /* The Abort process finished. */
    ABORTED,
    /* The Synchronization is completed */
    FINISHED,
    /* The Synchronization failed */
    FAILED,
    /* The cache is being cleared. */
    CLEAR_CACHE
}