import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";

(function() {
    require("SERVICES_PATH/viewer/detailsviewer/dv.environment.srv.js");
    require("COMPONENTS_PATH/eob-detailsviewer/components/dv-table/dv.table.dir.js");

    angular.module("eob.framework").directive("expandableContainer", ExpandableContainer);

    ExpandableContainer.$inject = ["$log", "$stateParams", "$compile", "dvEnvironmentService", "clientService",
        "cacheManagerService", "formService", "formHelper", "environmentService",
        "clientScriptService", "messageService", "progressbarService"];

    function ExpandableContainer($log, $stateParams, $compile, DvEnvironmentService, ClientService,
                                 CacheManagerService, FormService, FormHelper, EnvironmentService, ClientScriptService,
                                 MessageService, ProgressbarService) {
        return {
            scope: {
                dvTitle: "@",
                dvType: "@",
                osdata: "=",
                objecttypeid: "@",
                objectid: "="
            },
            restrict: "AE",
            template: require("!raw-loader!./dv.expandable.container.html"),
            link(scope, element) {
                scope.visible = false;
                let tableContainer;
                let isTable = scope.dvType === "table";
                let formReadySubscription, changeIndexDataSubscription;

                scope.toggle = toggleVisibility;
                scope.isPhone = ClientService.isPhone();

                MessageService.subscribe("changeIndexData", () => {
                    load();
                })

                function load() {
                    setInitialVisibility();
                    if (scope.isPhone) {
                        showIndexDataPhone();
                    } else {
                        getDomElements();
                        if (isTable) {
                            tableContainer.addClass("table-field");
                        } else {
                            tableContainer.addClass("default-field");
                        }
                    }
                }

                formReadySubscription = MessageService.subscribe(FormEvent.FORM_READY, () => {
                    hideProgressbar();
                });

                function getDomElements() {
                    if (element[0].children[0].children[1].children[0].localName.indexOf("dv-table") !== -1) {
                        tableContainer = angular.element(element[0].children[0].children[1].children[0]);
                    }
                    if (!tableContainer) {
                        $log.error("Error! dv.expandable.container.dir: Nicht alle Container gefunden.");
                    }
                }

                function setInitialVisibility() {
                    if (scope.dvType == "simpleFields" && DvEnvironmentService.getSessionSetting(scope.dvType) == void 0) {
                        scope.visible = true;
                        DvEnvironmentService.storeContainerVisibility(scope.dvType, scope.visible);
                    }

                    scope.visible = DvEnvironmentService.retrieveContainerVisibility(getContainerKey());

                    if (isTable && scope.visible) {
                        scope.$broadcast("dv.table.visible", scope.visible);
                    }
                }

                function toggleVisibility() {
                    scope.visible = !scope.visible;
                    DvEnvironmentService.storeContainerVisibility(getContainerKey(), scope.visible);

                    if (isTable && scope.visible) {
                        scope.$broadcast("dv.table.visible", scope.visible);
                    }
                }

                function getContainerKey() {
                    return isTable ? (`${scope.objecttypeid}_${scope.dvTitle}`) : scope.dvType;
                }

                async function showIndexDataPhone() {
                    let dmsDocument = await CacheManagerService.dmsDocuments.getOrFetchById(scope.objectid);
                    if (dmsDocument != void 0) {
                        scope.modelDef = CacheManagerService.objectTypes.getById(dmsDocument.model.objectTypeId);

                        // check if there are cached scripts
                        scope.eventScripts = scope.modelDef.api.getTypeScripts();
                        if (scope.eventScripts == null) {
                            try {
                                let scriptResponse = await ClientScriptService.getByObjectTypeIdAsync(dmsDocument.model.objectTypeId)
                                scope.eventScripts = scriptResponse.data;
                                scope.modelDef.api.setTypeScripts(scriptResponse.data);
                            } catch (error) {
                                console.error(error);
                            }
                        }

                        scope.formFields = scope.modelDef.api.getFields(true);

                        let formData = FormService.createFormData(scope.formFields, "min");
                        FormService.addIndexData(formData, dmsDocument.model);
                        await FormService.disableFieldsAsync(formData, dmsDocument.model, true);

                        let helperConfig = {
                            formData,
                            modelDef: scope.modelDef.model,
                            isView: true,
                            dmsData: dmsDocument
                        };

                        scope.formHelper = FormHelper.getFormHelper(helperConfig);
                        scope.formDef = {
                            isMainForm: true,
                            validationMode: "max",
                            formFields: scope.formFields,
                            formHelper: scope.formHelper,
                            formLayout: scope.modelDef.api.getLayout()
                        };
                        await scope.formHelper.bindScripts(scope.eventScripts);

                        let content = angular.element("<div class='form-wrapper'></div>");
                        let form;

                        setTimeout(() => {
                            if (scope.dvType === "simpleFields" && scope.formDef) {
                                form = angular.element("<eob-form [formdef]='formDef' class='single-column'></eob-form>");
                            } else if (scope.dvType === "baseParamsFields") {
                                if (scope.osdata) {
                                    form = buildPropertiesForm();
                                }
                            }

                            clearIndexDataIfNecessary(element);

                            content.append(form);

                            let compiled = $compile(content);
                            element.find("#dv-indexdata-phone").append(content);
                            compiled(scope);
                        }, 0);
                    }
                }

                function clearIndexDataIfNecessary(element) {
                    let indexDataPhoneDom = element.find("#dv-indexdata-phone")[0];
                    if (indexDataPhoneDom.childElementCount > 0) {
                        indexDataPhoneDom.removeChild(indexDataPhoneDom.firstChild)
                    }
                }

                function buildPropertiesForm() {
                    let formContainer = angular.element("<div class='base-params-form-container'></div>");

                    for (let i = 0; i < scope.osdata.length; i++) {
                        let field = scope.osdata[i];
                        let container = angular.element("<div class='form-element disabled'></div>");
                        let formInput = angular.element("<input readonly class='form-row-input'/>");
                        let formLabel = angular.element(`<label class="form-row-label">${field.type}</label>`);

                        formInput[0].value = field.value;
                        container.append(formLabel).append(formInput);
                        formContainer.append(container);
                    }

                    return formContainer;
                }

                function hideProgressbar() {
                    let progressbar = ProgressbarService.getProgressbarInstance("loadAnimation", undefined, true);
                    setTimeout(() => {
                        if (progressbar != void 0) {
                            progressbar.hide();
                        }
                    }, 50);
                }

                scope.$on("$destroy", () => {
                    formReadySubscription.unsubscribe();
                    changeIndexDataSubscription ? changeIndexDataSubscription.unsubscribe() : undefined;
                });
            }
        };
    }
})();
