<nav aria-label="Main" id="eob-nav-container" knTrapFocus [knTrapClassLast]="'.panel-heading'">
    <div class="focus-trap"
         *ngIf="shouldTrapFocus()"
         [attr.tabindex]="navigationService.isNavVisible ? '0' : '-1'"
         [attr.disabled]="navigationService.isNavVisible ? true : null">
    </div>
    <eob-nav-bar id="eob-nav-bar" [navBarTitle]="navBarTitle" (changePinState)="changePinState($event)"></eob-nav-bar>
    <div class="nav-body-container" [hidden]="nav && !isNavigationAllowed()">
        <div class="nav-body">
            <eob-nav-content-header (changePinState)="changePinState($event)"></eob-nav-content-header>
            <eob-nav-content></eob-nav-content>
        </div>
    </div>
    <div class="focus-trap"
         *ngIf="shouldTrapFocus()"
         [attr.tabindex]="navigationService.isNavVisible ? '0' : '-1'"
         [attr.disabled]="navigationService.isNavVisible ? true : null">
    </div>
</nav>
