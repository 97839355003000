angular.module("eob.core").factory("defaultApiService", DefaultApiService);

DefaultApiService.$inject = ["$q", "$timeout", "clientService", "errorModelService", "layoutManagerService", "messageService", "treeAddonService"];

/**
 * A service that creates a form pagecontrol element
 */
export default function DefaultApiService($q, $timeout, ClientService, ErrorModelService, LayoutManagerService, MessageService, TreeAddonService) {

    return {
        addDefaultApi
    };

    function isParentVisible(field, formHelper) {
        if (field.model.parentPage == void 0) {
            return true;
        }

        let parentField = formHelper.getFieldByInternal(field.model.parentPage);

        if (field.model.pageIndex == void 0) {
            return parentField.api.isVisible();
        }

        // Pagecontrol
        return parentField.api.isPageVisible(field.model.pageIndex);
    }

    function addDefaultApi(api, field, formHelper) {
        /**
         * focuses the field
         */
        if (field.model.type != "group") {
            api.focus = function(focusFirstFieldInitCall) {
                if (field.model.pageControl != void 0 && field.model.pageIndex != void 0) {
                    let pageControl = formHelper.getFieldByInternal(field.model.pageControl.internal);
                    let pageIndex = pageControl.model.activePageIndex ? pageControl.model.activePageIndex : field.model.pageIndex;

                    pageControl.api.goToPage(focusFirstFieldInitCall ? pageIndex : field.model.pageIndex, true, true);
                }

                let element;
                // give angular some time to destroy the old validationbubble
                // this ensures it opens once the field is in sight
                $timeout(() => {
                    if (field.model.type == "grid") {
                        for (let i in field.propMap) {
                            let rowProps = field.propMap[i];
                            for (let j in rowProps) {
                                let cellProps = rowProps[j];
                                if (cellProps.isValid == false) {
                                    if (field.model.pageControl != void 0 && field.model.pageIndex != void 0) {
                                        let pageControl = formHelper.getFieldByInternal(field.model.pageControl.internal);
                                        pageControl.api.goToPage(field.model.pageIndex, true, true);
                                    }
                                    api.focusCell(i, j);
                                    return;
                                }
                            }
                        }
                        // this is the case when everything is valid
                        // select the first cell
                        api.focusCell(0, 0, false);
                    } else if (field.model.type == "pagecontrol" && !ClientService.isTouchDevice()) {
                        api.focusFirst();
                    } else if (field.model.type == "button") {
                        element = api.getElement();
                        element.find("button").focus();
                    } else {
                        element = api.getElement().closest(".form-element");
                        let focussable = element.find("input, textarea, .checkbox, .form-radio-group");
                        if (focussable.length) {
                            const invalidInputField = focussable.first()[0].classList.value.includes("ng-invalid");
                            const invalidAddonField = element[0].classList.value.includes("invalid");
                            const focusAllowed = (!ClientService.isTouchDevice() || !LayoutManagerService.isTouchLayoutActive()) || invalidInputField || invalidAddonField;

                            if (focusAllowed) {
                                let input = focussable.first()[0];
                                input.parentNode.classList.add("focus");

                                if (field.model.pageControl) {
                                    let pageControl = formHelper.getFieldByInternal(field.model.pageControl.internal);
                                    pageControl.api.goToPage(field.model.pageIndex, true, true);
                                }

                                input.focus();
                            }
                        }
                    }
                }, 100);
            };

            api.bindDefaultFocusEvents = function() {
                let element;
                let type = field.model.type;

                if (type == "radio") {
                    // is handled in the FormRadioBuilder
                    return;
                } else if (type == "pagecontrol") {
                    element = api.getElement();
                } else if (type != "static" && type != "group") {
                    element = api.getElement().closest(".form-element");
                }

                let classElement = element;

                element.bind("focusin", () => {
                    // if (type == "checkbox") {
                    //     classElement = element.closest(".form-element");
                    // }
                    // console.log(classElement)

                    element.bind("focusout", () => {
                        if (type == "checkbox") {
                            classElement = element.closest(".form-element");
                        }

                        if (!field.isDisabled) {
                            classElement.removeClass("focus");
                        }
                    });
                    if (field.isDisabled) {
                        classElement.removeClass("focus");
                    } else {
                        classElement.addClass("focus");
                    }
                });
            };

            api.destroy = function() {
            };
        }

        if (field.model.type != "grid" && field.model.type != "pagecontrol" && field.model.type != "group") {
            /**
             * enables the field
             */
            api.enable = function() {
                field.isDisabled = false;

                if (field.model.control) {
                    field.model.control.enable();
                }

                let parent = api.getElement().closest(".form-element");
                parent.removeClass("disabled");
            };

            /**
             * disables the field
             */
            api.disable = function() {
                field.isDisabled = true;

                if (field.model.control) {
                    field.model.control.disable();
                }

                let parent = api.getElement().closest(".form-element");
                parent.addClass("disabled");
            };

            /**
             * Is the field enabled?
             *
             * @returns {boolean} true if it is enabled, else false
             */
            api.isEnabled = function() {
                return (field.isDisabled == void 0 || field.isDisabled == false);
            };

            /**
             * Sets the field of the field.
             *
             * @param {string} title of the field
             */
            api.setTitle = function(title) {
                const element = api.getElement(),
                    fieldType = field.model.type;
                let label;

                switch (fieldType) {
                    case "button":
                        label = element.find("button > span");
                        label.prop("innerText", title);
                        break;
                    case "alphanum":
                    case "capital":
                    case "choice":
                    case "datetime":
                    case "date":
                    case "decimal":
                    case "letter":
                    case "number":
                    case "text":
                    case "time":
                        label = window.document.querySelector(`.main-component eob-form eob-form-element[internal='${element.attr("internal")}'] eob-form-static-text eob-label label`);
                        label.innerText = title;
                        break;
                    default:
                        label = element.find("label");
                        label.prop("innerText", title);
                }
            };
        }

        /**
         * Sets the tooltip of the field.
         *
         * @param {string} tooltip for the field
         */
        api.setTooltip = function(tooltip) {
            const element = api.getElement(),
                fieldType = field.model.type;
            let elementWithTooltip;

            switch (fieldType) {
                // don't have a tooltip
                case "pagecontrol":
                case "group":
                    break;
                case "button":
                    elementWithTooltip = element.find("button");
                    elementWithTooltip.prop("title", tooltip);
                    break;
                case "radio":
                    elementWithTooltip = element.find(".radio-container");
                    elementWithTooltip.prop("title", tooltip);
                    break;
                case "checkbox":
                    elementWithTooltip = element.find(".checkbox");
                    elementWithTooltip.prop("title", tooltip);
                    break;
                case "alphanum":
                case "capital":
                case "choice":
                case "datetime":
                case "date":
                case "decimal":
                case "letter":
                case "number":
                case "text":
                case "time":
                    elementWithTooltip = window.document.querySelector(`eob-form eob-form-element[internal='${element.attr("internal")}'] eob-form-static-text eob-label label`);
                    angular.element(elementWithTooltip).prop("title", tooltip);
                    break;
                default:
                    elementWithTooltip = element.find("label");
                    elementWithTooltip.prop("title", tooltip);
            }
        };

        if (field.model.type != "pagecontrol") {
            /**
             * hides the field
             */
            api.hide = function() {
                let element = api.getElement();

                let closest = element.closest(".form-element");
                if (closest != void 0 && closest.hide != void 0) {
                    closest.hide();
                }

                if (field.label != void 0) {
                    field.label.style.display = "none";
                }

                field.isInvisible = true;
            };

            /**
             * shows the field
             */
            api.show = function() {
                let element = api.getElement();

                let closest = element.closest(".form-element");
                if (closest != void 0 && closest.show != void 0) {
                    closest.show();
                }

                if (field.label != void 0) {
                    field.label.style.display = "block";
                }

                field.isInvisible = false;
            };

            /**
             * Is the field visible?
             *
             * @returns {boolean} true if it is visible, else false
             */
            api.isVisible = function() {
                if (!isParentVisible(field, formHelper)) {
                    return false;
                }

                return (field.isInvisible == void 0 || field.isInvisible == false);
            };
        }

        if (field.model.type == "button") {
            api.showList = (list, config) => {
                let button = api.getElement();

                if (list == void 0 || list.constructor != Array) {
                    console.error("Parameter [list] must be an array");
                    return Promise.reject(ErrorModelService.createParameterError("list", list));
                }

                if (config == void 0) {
                    config = {};
                }

                if (typeof config != "object") {
                    console.error("Parameter [config] must be an object");
                    return Promise.reject(ErrorModelService.createParameterError("config", config));
                }

                if (config.multiSelect == void 0) {
                    config.multiSelect = false;
                } else if (typeof config.multiSelect != "boolean") {
                    console.error("Parameter [config.multiSelect] must be a boolean");
                    return Promise.reject(ErrorModelService.createParameterError("config.multiSelect", config.multiSelect));
                }

                if (config.type == void 0) {
                    config.type = "tree";
                } else if (config.type != "list" && config.type != "tree" && config.type != "hierarchy") {
                    console.error("Parameter [config.type] must be one of the following strings: \"list\",\"tree\", \"hierarchy\"");
                    return Promise.reject(ErrorModelService.createParameterError("config.type", config.type));
                }

                if (config.type == "hierarchy" && config.multiSelect == true) {
                    console.error("Parameter [config.multiSelection] = true is not allowed for hierarchy catalogs");
                    return Promise.reject(ErrorModelService.createParameterError("config.multiSelection", config.multiSelection));
                }

                if (config.useCheckboxSelection == void 0) {
                    config.useCheckboxSelection = LayoutManagerService.isTouchLayoutActive();
                }

                if (!config.multiSelect && config.useCheckboxSelection && !LayoutManagerService.isTouchLayoutActive()) {
                    console.error("Parameter [config.useCheckboxSelection] = true is not allowed for catalogs with single selection for entries");
                    return Promise.reject(ErrorModelService.createParameterError("config.useCheckboxSelection", config.useCheckboxSelection));
                }

                if (config.multiSelect && formHelper.isSearch && config.type == "hierarchy") {
                    console.error("Multiselection works only with Parameter [config.type] = 'list' or 'tree catalogs'");
                    return Promise.reject(ErrorModelService.createParameterError("config.multiSelect", config.multiSelect));
                }

                config.iconCatalog = false;
                config.scriptDeferred = $q.defer();

                list = checkForSimpleList(list);
                checkForIcons(list);

                checkForShortValues(list);
                setDefaultShortValues(list);

                if (config.sortEntries && list.length) {
                    TreeAddonService.sortTreeNodes({ addon: config.type || "list" }, list, config.shortValue ? "short" : "value");
                }

                let eventData = {
                    target: button[0],
                    list,
                    data: {
                        model: {
                            type: config.type
                        }
                    },
                    config,
                    isSearch: formHelper.isSearch,
                    catalogType: config.type
                };

                MessageService.broadcast("showTree", eventData);

                function checkForSimpleList(list) {
                    let isSimpleList = true;
                    let newList = [];

                    for (let i in list) {
                        if (typeof list[i] == "object") {
                            isSimpleList = false;
                            break;
                        }
                        newList.push({ value: list[i] });
                    }

                    return isSimpleList ? newList : list;
                }

                function checkForIcons(nodes) {
                    for (let i in nodes) {
                        if (nodes[i].icon != void 0) {
                            config.iconCatalog = true;
                            break;
                        }

                        if (nodes[i].nodes != void 0) {
                            checkForIcons(nodes[i].nodes);
                        }
                    }
                }

                function checkForShortValues(nodes) {
                    for (let i in nodes) {
                        if (nodes[i].short != void 0) {
                            config.shortValue = true;
                            break;
                        }
                        if (nodes[i].nodes != void 0) {
                            checkForShortValues(nodes[i].nodes);
                        }
                    }
                }

                function setDefaultShortValues(nodes) {
                    for (let i in nodes) {
                        if (nodes[i].short == void 0) {
                            nodes[i].short = nodes[i].value;
                        }

                        if (nodes[i].nodes != void 0) {
                            setDefaultShortValues(nodes[i].nodes);
                        }
                    }
                }

                return config.scriptDeferred.promise;
            };
        }
    }
}
