import {ContextMenuModule} from "MODULES_PATH/context-menu/context-menu.module";
import {Inject, Injectable} from "@angular/core";
import {
    ContextMenuAction,
    ContextMenuActionsProvider
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {ContextMenuUtilsService} from "MODULES_PATH/context-menu/services/context-menu-utils.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ClipboardDesktopItem, ClipboardDmsDocument} from "INTERFACES_PATH/custom-storage.interface";
import {DesktopItem} from "INTERFACES_PATH/desktop.interface";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {TodoCacheManagerService, TodoDesktopService, TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {ContextMenuActionsService} from "MODULES_PATH/context-menu/services/context-menu-actions.service";
import {ContextData} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {defer, Observable} from "rxjs";

@Injectable({
    providedIn: ContextMenuModule
})
export class DesktopContextMenuService implements ContextMenuActionsProvider {
    private readonly translateFn: TranslateFnType;
    private contextMenuActions: ContextMenuAction[] = [];

    constructor(private contextMenuUtilsService: ContextMenuUtilsService,
                private contextMenuActionsService: ContextMenuActionsService,
                private clientService: ClientService,
                private notificationsService: NotificationsService,
                private actionService: ActionService,
                @Inject("$filter") private $filter: ng.IFilterService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("desktopService") private desktopService: TodoDesktopService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
    ) {
        this.translateFn = $filter("translate");
    }

    getSingleSelectionContextMenuActions(contextMenuItem: DesktopItem, contextData: ContextData): Observable<ContextMenuAction[]> {
        return defer(async () => {
            this.contextMenuActions = [];

            if (contextMenuItem.osid != void 0 && this.cacheManagerService.dmsDocuments.getById(contextMenuItem.id) == void 0) {
                this.notificationsService.warning(this.translateFn("eob.desktop.abandoned.modal.remove.message.readonly"));

                return [];
            }

            if (this.clientService.isOnline() && contextMenuItem.type == "query") {
                this.addExecuteQuery(contextMenuItem);
            }

            if ((!this.environmentService.env.desktop.edit) || ((contextMenuItem.profile != "") && (contextMenuItem.profile != void 0))) {
                return [];
            }

            if (this.clientService.isOnline() && !contextMenuItem.isPublic) {
                if (contextMenuItem.type == "folder") {
                    this.addAddFolderToDesktop(contextMenuItem);
                    this.addAddToDesktopFromClipboard(contextMenuItem);
                }

                if ((!contextMenuItem.isRoot) && (contextMenuItem.id != void 0)) {
                    this.addRenameDesktopItem(contextMenuItem);
                }

                if ((this.environmentService.env.actions.useClipboard) && (!contextMenuItem.isRoot) && (contextMenuItem.id != void 0)) {
                    this.addCutToClipboard(contextMenuItem);
                }

                if (!contextMenuItem.isRoot && (contextMenuItem.type != "folder" || (contextMenuItem.type == "folder" && contextMenuItem.isEmpty))) {
                    this.addRemoveDesktopItem(contextMenuItem);
                }
            }

            if (this.contextMenuActions.length > 0) {
                this.contextMenuActions.push(this.contextMenuUtilsService.addDivider());
            }

            await this.contextMenuActionsService.getSingleselectItems(contextMenuItem, this.contextMenuActions, "desktop", contextData);

            return this.contextMenuActions;
        });
    }

    getMultiselectionContextMenuActions(contextMenuItems: DesktopItem[], contextData: ContextData): Observable<ContextMenuAction[]> {
        return;
    }

    private addExecuteQuery(contextMenuItem: DesktopItem): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.execute.query", "sleekfind-dark",
            this.desktopService.executeAction, contextMenuItem));
    }

    private addAddFolderToDesktop(contextMenuItem: DesktopItem): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.add.folder.to.desktop.title",
            "folder-new", this.desktopService.addItemToDesktop, {contextMenuItem}));
    }

    private addAddToDesktopFromClipboard(contextMenuItem: DesktopItem): void {
        const clipboard: ClipboardDmsDocument | ClipboardDesktopItem = this.environmentService.getClipboard();

        // clipboard.item.type is not void 0 if the item is already in the desktop. Then no checks were made.
        // Queries are added to the desktop without clipboard. Therefore currently only documents, register and folder must be checked.
        if (this.contextMenuUtilsService.isValidClipboardContentForDesktop(contextMenuItem, clipboard)) {
            if (!(clipboard as ClipboardDesktopItem).item.isPublic) {
                if (contextMenuItem.type != "folder" || this.desktopService.isMovable(clipboard.item, contextMenuItem)) {
                    this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.insert.title",
                        "clipboard-get", this.desktopService.moveItem, contextMenuItem));
                }
            } else {
                this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.add.to.desktop.title",
                    "clipboard-get", this.desktopService.addItemToDesktop, {
                        //TODO check dmsDocument here
                        dmsDocument: contextMenuItem,
                        dmsItem: clipboard.item,
                        contextMenuItem,
                    }));
            }
        }

        return;
    }

    private addRenameDesktopItem(contextMenuItem: DesktopItem): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.desktop.rename.item.title",
            "datenblatt-bearbeiten", this.desktopService.renameItem, contextMenuItem));
    }

    private addCutToClipboard(contextMenuItem: DesktopItem): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.clipboard.cut.title", "cut",
            this.actionService.cutToClipboard, contextMenuItem));
    }

    private addRemoveDesktopItem(contextMenuItem: DesktopItem): void {
        this.contextMenuActions.push(this.contextMenuUtilsService.addAction("eob.contextmenu.action.remove.from.desktop.title",
            "delete-dark", this.desktopService.removeItem, contextMenuItem));
    }
}