<ul #navKebabElements class = "nav-kebab-elements nav-panel-elements" *ngIf = "nav$ | async as navigation">
    <ng-container *ngIf = "initialNavState$ | async as initialNavState">
        <li *ngIf = "!navigation.showObjectsearch && initialNavState.showObjectsearch"
            (click) = "switchNavContent( $event, 'objectsearch')"
            title = "{{'nav.pane.title.objectsearch' | ngTranslate}}">
            <button class = "button-transparent" tabindex="-1">
                <eob-icon name = "nav-objectsearch" size = "24"
                          title = "{{'nav.pane.title.objectsearch' | ngTranslate}}"></eob-icon>
                <span>{{'nav.pane.title.objectsearch' | ngTranslate}} </span>
            </button>
        </li>
        <li *ngIf = "!navigation.showDesktop && initialNavState.showDesktop"
            (click) = "switchNavContent($event, 'desktop')"
            title = "{{'nav.pane.title.desktop' | ngTranslate}}">
            <button class = "button-transparent" tabindex="-1">
                <eob-icon name = "nav-desktop" size = "24" title = "{{'nav.pane.title.desktop' | ngTranslate}}"></eob-icon>
                <span>{{'nav.pane.title.desktop' | ngTranslate}} </span>
            </button>
        </li>
        <li *ngIf = "!navigation.showQuickSearches && initialNavState.showQuickSearches"
            (click) = "switchNavContent( $event, 'quicksearch')" title = "{{'nav.pane.title.quicksearch' | ngTranslate}}">
            <button class = "button-transparent" tabindex="-1">
                <eob-icon name = "nav-quicksearch" size = "24"
                          title = "{{'nav.pane.title.quicksearch' | ngTranslate}}"></eob-icon>
                <span>{{'nav.pane.title.quicksearch' | ngTranslate}} </span>
            </button>
        </li>
        <li *ngIf = "!navigation.showInboxArea && initialNavState.showInboxArea"
            (click) = "switchNavContent($event, 'inbox')"
            title = "{{'eob.inbox.title' | ngTranslate}}">
            <button class = "button-transparent" tabindex="-1">
                <eob-icon name = "nav-inbox" size = "24" title = "{{'eob.inbox.title' | ngTranslate}}"></eob-icon>
                <span>{{'eob.inbox.title' | ngTranslate}} </span>
            </button>
        </li>

        <li *ngIf = "!navigation.showOfflineObjects && initialNavState.showOfflineObjects"
            (click) = "goToSecondaryState($event,'hitlist.offlineObjects')"
            title = "{{'eob.app.bar.offline.objects.title' | ngTranslate}}">
            <button class = "button-transparent" tabindex="-1">
                <eob-icon name = "nav-favs" size = "24"
                          title = "{{'eob.app.bar.offline.objects.title' | ngTranslate}}"></eob-icon>
                <span>{{'eob.app.bar.offline.objects.title' | ngTranslate}} </span>
            </button>
        </li>

        <li *ngIf = "!navigation.showFavorites && initialNavState.showFavorites"
            (click) = "goToSecondaryState($event,'hitlist.favorites')"
            title = "{{'eob.app.bar.favorites.title' | ngTranslate}}">
            <button class = "button-transparent" tabindex="-1">
                <eob-icon name = "nav-favs" size = "24"
                          title = "{{'eob.app.bar.favorites.title' | ngTranslate}}"></eob-icon>
                <span>{{'eob.app.bar.favorites.title' | ngTranslate}}</span>
            </button>
        </li>
        <li *ngIf = "!navigation.showHistory && initialNavState.showHistory"
            (click) = "goToSecondaryState($event,'hitlist.history')"
            title = "{{'eob.app.bar.history.title' | ngTranslate}}">
            <button class = "button-transparent" tabindex="-1">
                <eob-icon name = "nav-history" size = "24"
                          title = "{{'eob.app.bar.history.title' | ngTranslate}}"></eob-icon>
                <span>{{'eob.app.bar.history.title' | ngTranslate}}</span>
            </button>
        </li>
        <li *ngIf = "!navigation.showNavAvatar" (click) = "showUserMenu($event)"
            title = "{{'eob.app.bar.usermenu' | ngTranslate}}">
            <button class = "button-transparent" tabindex="-1">
                <img width = "24px" height = "24px" src = "{{avatarUrl}}">
                <span>{{'eob.app.bar.usermenu' | ngTranslate}} </span>
            </button>
        </li>
    </ng-container>
</ul>
