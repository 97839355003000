require("COMPONENTS_PATH/eob-circular-tab-form/eob.circular.tab.form.dir.js");

require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
require("SERVICES_PATH/form/eob.form.srv.js");
require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");

import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

angular.module("eob.framework").directive("eobModalAbo", EobModalAbo);

EobModalAbo.$inject = ["$filter", "$compile", "$rootScope", "$timeout", "environmentService", "organisationService",
    "backendService", "notificationsService", "valueUtilsService", "cacheManagerService", "clientService",
    "formFieldModelService", "formService", "formHelper", "orgAddonService", "messageService", "modalDialogService"];

// eslint-disable-next-line max-params, require-jsdoc
export default function EobModalAbo($filter, $compile, $rootScope, $timeout, EnvironmentService, OrganisationService,
                                    BackendService, NotificationsService, ValueUtilsService, CacheManagerService, ClientService,
                                    FormFieldModelService, FormService, FormHelper, OrgAddonService, MessageService, ModalDialogService) {
    return {
        scope: {
            aboObjects: "=items",
            isEdit: "=isedit"
        },
        async link(scope, element) {
            scope.isFormVisible = false;
            let aboObjects = !Array.isArray(scope.aboObjects) ? [scope.aboObjects] : scope.aboObjects;

            let actionEnum = {
                "deletion": "OBJECT_DELETED",
                "content": "DOCUMENT_MODIFIED",
                "indexdata": "INDEXDATA_MODIFIED",
                "location": "LOCATION_ADDED",
                "documentMoved": "DOCUMENT_MOVED",
                "registerMoved": "REGISTER_MOVED"
            };

            let confirmationEnum = {
                "noConfirmation": "NO_CONFIRMATION",
                "confirmation": "CONFIRMATION",
                "password": "CONFIRMATION_PASSWORD"
            };

            let notifyTypeEnum = {
                "internal": "INTERNAL",
                "mail": "EMAIL",
                "both": "INTERNAL_AND_EMAIL"
            };

            let g_OrgEntries; // keep all org addon entries, while some might be filtered from the multiselect boxes
            scope.orgEntries;
            scope.orgGridConfigs;

            scope.recipients = [];
            scope.ready = false;
            scope.aboObject = {};
            scope.getField = getField;
            const hasSystemRoleAboForOthers = EnvironmentService.userHasRole("R_CLNT_ABO_FOROTHERS");

            scope.validateEmail = validateEmail;
            scope.saveSubscription = saveSubscription;
            scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

            scope.onUserSelectionChange = (newValue) => {
                if (!scope.isFormVisible || getField("self") == undefined || scope.recipients[getField("self").api.getValue()] != "others") {
                    return;
                }

                if (newValue.length > 1 || (newValue.length == 1 && newValue[0].type == "group")) {
                    getField("email").api.disable();
                    getField("enableMail").api.disable();
                    return;
                }

                getField("email").api.enable();
                getField("enableMail").api.enable();
            };

            // build form data
            let formData = await buildFormData();
            scope.formHelper = FormHelper.getFormHelper({ formData, submit: scope.saveSubscription });
            scope.formDef = {
                isMainForm: true,
                validationMode: "max",
                formFields: scope.fields,
                formHelper: scope.formHelper,
                isMockForm: true
            };

            let content = angular.element("<div ng-show='isFormVisible'></div>");
            let form = angular.element("<eob-form [formdef]='formDef'></eob-form>");

            let buttons = angular.element("<div class=\"button-container\"> " +
                "<button type=\"button\" tabindex=\"0\" class=\"secondary-button\" ng-click=\"close()\">{{::'modal.button.cancel' | translate}}</button>" +
                "<button type=\"button\" tabindex=\"0\" class=\"btn btn-primary\" ng-click=\"saveSubscription()\">{{::'modal.button.save' | translate}}</button></div>");
            let containerElement = element.find(".abo-modal-container");

            content.append(form);
            content.append(buttons);
            let compiled = $compile(content);
            containerElement.append(content);
            compiled(scope);
            await initFields();

            async function buildFormData() {
                let formFields = {
                    indexdata: FormFieldModelService.getMockedField("checkbox", "indexdata", $filter("translate")("modal.abo.action.indexdata.changed")),
                    deletion: FormFieldModelService.getMockedField("checkbox", "deletion", $filter("translate")("modal.abo.action.object.deleted")),
                    enableMail: FormFieldModelService.getMockedField("checkbox", "enableMail"),
                    email: FormFieldModelService.getMockedField("text", "email", $filter("translate")("modal.abo.recipient.mail")),
                    information: FormFieldModelService.getMockedField("text", "information", $filter("translate")("modal.abo.legend.info.title"))
                };

                initCheckboxes();
                if (scope.isDocument) {
                    Object.assign(formFields, {
                        location: FormFieldModelService.getMockedField("checkbox", "location", $filter("translate")("modal.abo.action.location.added")),
                        documentMoved: FormFieldModelService.getMockedField("checkbox", "documentMoved", $filter("translate")("modal.abo.action.document.moved")),
                        content: FormFieldModelService.getMockedField("checkbox", "content", $filter("translate")("modal.abo.action.content.changed")),
                    });
                }

                if (scope.isRegister) {
                    Object.assign(formFields, {
                        registerMoved: FormFieldModelService.getMockedField("checkbox", "registerMoved", $filter("translate")("modal.abo.action.register.moved")),
                    });
                }

                if (hasSystemRoleAboForOthers) {
                    Object.assign(formFields, {
                        confirmation: FormFieldModelService.getMockedField("checkbox", "confirmation", $filter("translate")("modal.abo.notification.confirm")),
                        password: FormFieldModelService.getMockedField("checkbox", "password", $filter("translate")("modal.abo.notification.password")),
                        self: FormFieldModelService.getMockedField("radio", "self", $filter("translate")("modal.abo.recipient.self")),
                        others: FormFieldModelService.getMockedField("radio", "others", $filter("translate")("modal.abo.recipient.others")),
                        none: FormFieldModelService.getMockedField("radio", "none", $filter("translate")("modal.abo.recipient.none")),
                        forSelectionUser: FormFieldModelService.getMockedField("checkbox", "forSelectionUser", $filter("translate")("modal.revisit.userlist.user")),
                        forSelectionGroups: FormFieldModelService.getMockedField("checkbox", "forSelectionGroups", $filter("translate")("modal.revisit.userlist.groups"))
                    });
                    formFields.self.fields.push("self");
                    formFields.self.fields.push("others");
                    formFields.self.fields.push("none");
                    formFields.self.isMasterRadio = true;
                    formFields.self.masterRadioInternal = "self";

                    formFields.others.masterRadioInternal = "self";
                    formFields.none.masterRadioInternal = "self";

                    scope.recipients = formFields.self.fields;
                }

                scope.fields = Object.values(formFields);
                return FormService.createFormData(scope.fields, "max");
            }

            function modifyActionContainer() {
                let container = element.find("eob-form").children(":first");
                let actionContainer = angular.element("<div class=\"form-row-input-container action-container\"></div>");

                let actionLabel = angular.element(`<eob-label class="form-label-custom" [value]=\"'${$filter("translate")("modal.abo.legend.action.title")}'\"></eob-label>`);
                let actionElements = angular.element("<div class=\"form-element form-elements-container-wrap\"></div>");
                let firstRow = angular.element("<div class=\"form-checkbox-group\"></div>");
                let secondRow = angular.element("<div class=\"form-checkbox-group\"></div>");
                let thirdRow = angular.element("<div class=\"form-checkbox-group\"></div>");

                firstRow.append(getField("indexdata").api.getElement()[0]);

                if (scope.isDocument) {
                    firstRow.append(getField("content").api.getElement()[0]);
                    thirdRow.append(getField("documentMoved").api.getElement()[0]);
                    secondRow.append(getField("location").api.getElement()[0]);
                }

                if (scope.isRegister) {
                    thirdRow.append(getField("registerMoved").api.getElement()[0]);
                }

                secondRow.append(getField("deletion").api.getElement()[0]);

                actionContainer.append(actionLabel);
                let compiled = $compile(actionContainer);
                actionElements.append(firstRow);
                actionElements.append(secondRow);
                actionElements.append(thirdRow);
                actionContainer.append(actionElements);
                container.prepend(actionContainer);
                compiled(scope);
            }

            function modifyMailContainer() {
                let mailTextField = element.find(getField("email").api.getElement()[0]);

                if (ClientService.isPhone()) {
                    getField("enableMail").api.getElement().addClass("form-custom-checkbox-phone");
                    getField("email").api.getElement().addClass("form-email-container-phone");
                } else if (!ClientService.isPhone() && ClientService.isPhoneOrTablet()) {
                    scope.formHelper.getFieldByInternal("enableMail").api.getElement().addClass("form-custom-checkbox-tablet");
                    scope.formHelper.getFieldByInternal("email").api.getElement().addClass("form-email-container-tablet");
                } else {
                    getField("enableMail").api.getElement().addClass("form-custom-checkbox");
                    getField("email").api.getElement().addClass("form-email-container");
                }

                mailTextField.before(getField("enableMail").api.getElement()[0]);

                if (hasSystemRoleAboForOthers) {
                    let showContainer = angular.element("<div ng-class=\"{'open': recipients[getField('self').api.getValue()] == 'others' && getField('deletion').api.getValue() != 1, 'close': recipients[getField('self').api.getValue()] != 'others' || getField('deletion').api.getValue() == 1}\" class=\"form-element form-elements-container-wrap-inline for-selection-container\"></div>");
                    let compiledContainer = $compile(showContainer);
                    let showLabel = angular.element(`<div ng-class=\"{'open': recipients[getField('self').api.getValue()] == 'others' && getField('deletion').api.getValue() != 1, 'close': recipients[getField('self').api.getValue()] != 'others' || getField('deletion').api.getValue() == 1}\" class="form-element form-label-custom for-selection-label"><span>${$filter("translate")("modal.revisit.userlist.show")}</span></div>`);
                    let compiledLabel = $compile(showLabel);

                    showContainer.append(getField("forSelectionUser").api.getElement()[0])
                    showContainer.append(getField("forSelectionGroups").api.getElement()[0])
                    mailTextField.after(showLabel);
                    showLabel.after(showContainer);
                    compiledContainer(scope);
                    compiledLabel(scope);
                }
            }

            function modifyConfirmationContainer() {
                let multiselectDir = element.find(".toggle-hidden-area");
                let showContainer = angular.element("<div ng-class=\"{'open': recipients[getField('self').api.getValue()] == 'others' && getField('deletion').api.getValue() != 1, 'close': recipients[getField('self').api.getValue()] != 'others' || getField('deletion').api.getValue() == 1}\" class=\"form-element form-elements-container-wrap-inline confirmation-container\"></div>");
                let compiledContainer = $compile(showContainer);
                let showLabel = angular.element(`<div ng-class=\"{'open': recipients[getField('self').api.getValue()] == 'others' && getField('deletion').api.getValue() != 1, 'close': recipients[getField('self').api.getValue()] != 'others' || getField('deletion').api.getValue() == 1}\" class="form-element form-label-custom confirmation-label"><span>${$filter("translate")("modal.abo.confirmation.label")}</span></div>`);
                let compiledLabel = $compile(showLabel);

                showContainer.append(getField("confirmation").api.getElement()[0])
                showContainer.append(getField("password").api.getElement()[0])
                multiselectDir.after(showLabel);
                showLabel.after(showContainer);
                compiledContainer(scope);
                compiledLabel(scope);
            }

            function modifyMultiSelectBoxContainer() {
                let createForContainer = element.find(".for-selection-container");
                let multiselectBoxContainer = angular.element("<div class=\"toggle-hidden-area\" ng-class=\"{'open': recipients[getField('self').api.getValue()] == 'others' && getField('deletion').api.getValue() != 1, 'close': recipients[getField('self').api.getValue()] != 'others' || getField('deletion').api.getValue() == 1}\"></div>");
                let multiSelectDir = angular.element("<eob-multiselect-boxes " +
                    "[content]='orgEntries' [leftconfig]='orgGridConfigs.leftConfig' [rightconfig]='orgGridConfigs.rightConfig' " +
                    "(selectionchangeevent)='onUserSelectionChange($event)'></eob-multiselect-boxes>");

                multiselectBoxContainer.append(multiSelectDir)
                let compiled = $compile(multiselectBoxContainer);
                createForContainer.after(multiselectBoxContainer);
                compiled(scope);
            }

            function modifyCreateForRadioContainer() {
                let container = element.find(getField("email").api.getElement()[0]);
                let createForContainer = angular.element("<div class=\"form-row-input-container create-for-container\"></div>");

                let label = angular.element(`<eob-label class="form-label-custom" [value]=\"'${$filter("translate")("modal.revisit.for")}'\"></eob-label>`);
                let elementsContainer = angular.element("<div class=\"form-element form-elements-container-wrap-inline\"></div>");
                createForContainer.append(label);
                let compiledCreateFor = $compile(createForContainer);

                let selfRadioContainer = angular.element("<div class=\"form-radio-group\"></div>")
                selfRadioContainer.append(getField("self").api.getElement()[0]);
                elementsContainer.append(selfRadioContainer);

                let othersRadioContainer = angular.element("<div class=\"form-radio-group\"></div>")
                let othersRadioBtn = angular.element(getField("others").api.getElement()[0]);
                othersRadioBtn.bind("click", resetValuesForSelectionFields);
                othersRadioContainer.append(othersRadioBtn);
                elementsContainer.append(othersRadioContainer);

                let nobodyRadioContainer = angular.element("<div class=\"form-radio-group\"></div>")
                let nobodyRadioBtn = angular.element(getField("none").api.getElement()[0]);
                nobodyRadioBtn.bind("click", getField("enableMail").api.enable);
                nobodyRadioContainer.append(nobodyRadioBtn);
                elementsContainer.append(nobodyRadioContainer);

                createForContainer.append(elementsContainer);
                container.after(createForContainer);
                compiledCreateFor(scope);
            }

            function initFields() {
                return new Promise((resolve) => $timeout(() => {
                    modifyActionContainer();
                    modifyMailContainer();

                    if (hasSystemRoleAboForOthers) {
                        modifyCreateForRadioContainer();
                        modifyMultiSelectBoxContainer();
                        modifyConfirmationContainer();

                        getField("confirmation").api.validate = () => isEnabledPasswordConfirmation();
                        getField("forSelectionUser").api.validate = async () => refreshList();
                        getField("forSelectionGroups").api.validate = async () => refreshList();
                    }

                    getField("email").api.disable();
                    getField("email").api.validate = () => validateEmail();
                    getField("enableMail").api.validate = () => isEnabledMail();
                    getField("deletion").api.validate = async () => toggleDeleteAction();

                    initAbo();
                    initUserAddon();
                    scope.isFormVisible = true;
                    resolve();
                }, 0));
            }

            function resetValuesForSelectionFields() {
                getField("forSelectionUser").api.setValue(1);
                getField("forSelectionGroups").api.setValue(1);
                refreshList();
            }

            async function isEnabledMail() {
                ModalDialogService.isEnabledInputField("enableMail", "email", scope.formHelper);
            }

            async function isEnabledPasswordConfirmation() {
                if (getField("confirmation").api.getValue() == 0) {
                    getField("password").api.setValue(0);
                    getField("password").api.disable();
                } else {
                    getField("password").api.enable();
                }
            }

            /**
             * Fill the abo object data into the modal dialog.
             */
            function initAbo() {
                if (scope.isEdit && aboObjects.length > 0) {
                    let data = aboObjects[0].model;

                    if (data.notifyType == notifyTypeEnum.mail) {
                        getField("self").api.setValue(2);
                    } else if (data.usersToBeNotified.length > 1 || data.groupsToBeNotified.length != 0) {
                        getField("self").api.setValue(1);
                    } else {
                        getField("self").api.setValue(0);
                    }

                    getField("information").api.setValue(data.infoText);
                    if (data.confirm == confirmationEnum.password) {
                        getField("password").api.enable();
                        getField("password").api.setValue(1);
                    }

                    if (data.confirm == confirmationEnum.confirmation) {
                        getField("confirmation").api.setValue(1);
                        getField("password").api.enable();
                    }

                    if (data.confirm == confirmationEnum.noConfirmation) {
                        getField("confirmation").api.setValue(0);
                    }

                    if (data.mailAddresses.length > 0) {
                        getField("enableMail").api.setValue(1);
                        let emails = data.mailAddresses.join(",");
                        getField("email").api.setValue(emails);
                        getField("email").api.enable();
                    } else {
                        getField("email").api.disable();
                    }

                    for (let action in actionEnum) {
                        if (data.actions.indexOf(actionEnum[action]) !== -1) {
                            getField(action).api.setValue(1);
                        }
                    }

                    toggleDeleteAction();
                    getField("indexdata").api.focus();
                } else {
                    getField("email").api.setValue(EnvironmentService.getSessionInfo().email);
                    getField("email").api.disable();
                    getField("indexdata").api.setValue(1);

                    if (hasSystemRoleAboForOthers) {
                        getField("password").api.disable();
                        getField("forSelectionUser").api.setValue(1);
                        getField("forSelectionGroups").api.setValue(1);
                        getField("self").api.setValue(0);
                    }
                }
            }

            function initUserAddon() {
                const orgObjects = [].concat(OrganisationService.getActiveUserList(), OrganisationService.getGroupList());
                let selectFn;

                if (scope.isEdit && aboObjects.length > 0 && scope.recipients[getField("self").api.getValue()] == "others") {
                    let { usersToBeNotified, groupsToBeNotified } = aboObjects[0].model;

                    const selectedIds = [].concat(usersToBeNotified.map(user => user.id), groupsToBeNotified.map(group => group.id));
                    selectFn = entry => selectedIds.includes(entry.id);
                }

                scope.orgEntries = OrgAddonService.parseToOrgAddonEntries(orgObjects, selectFn);
                g_OrgEntries = scope.orgEntries;
                scope.orgGridConfigs = OrgAddonService.getDefaultGridConfigs();

                scope.ready = true;
            }

            /**
             * Initialize the checkbox behaviour.
             */
            function initCheckboxes() {
                if (scope.isEdit) {
                    let subscribedDmsDocument = CacheManagerService.dmsDocuments.getById(aboObjects[0].model.osid);

                    scope.isDocument = subscribedDmsDocument.model.isDocument;
                    scope.isRegister = subscribedDmsDocument.model.isRegister;
                } else {
                    for (let { model } of aboObjects) {
                        scope.isDocument = model.isDocument;
                        scope.isRegister = model.isRegister;
                    }
                }
            }

            /**
             * Set value, whom to notify.
             */
            function refreshList() {
                if (scope.recipients[getField("self").api.getValue()] == "others") {
                    const showUser = scope.formHelper.getFieldByInternal("forSelectionUser").api.getValue() == 1;
                    const showGroups = scope.formHelper.getFieldByInternal("forSelectionGroups").api.getValue() == 1;

                    scope.orgEntries = g_OrgEntries.filter(entry =>
                        (entry.selected || entry.type == "user" && showUser || entry.type == "group" && showGroups)
                    );
                } else {
                    getField("forSelectionUser").api.setValue(1);
                    getField("forSelectionGroups").api.setValue(1);
                }
            };

            /**
             * Toggle delete action.
             */
            function toggleDeleteAction() {
                if (getField("deletion").api.getValue() == 0) {
                    getField("indexdata").api.enable();

                    if (hasSystemRoleAboForOthers) {
                        getField("self").api.enable();
                    }

                    if (scope.isDocument) {
                        getField("location").api.enable();
                        getField("content").api.enable();
                    }

                    return;
                }

                getField("indexdata").api.disable();
                getField("indexdata").api.setValue(0);

                if (hasSystemRoleAboForOthers) {
                    getField("self").api.disable();
                    getField("self").api.setValue(0);
                }

                if (scope.isDocument) {
                    getField("content").api.disable();
                    getField("content").api.setValue(0);
                    getField("location").api.disable();
                    getField("location").api.setValue(0);
                }

                if (getField("deletion").api.getValue() == 1) {
                    getField("enableMail").api.enable();
                    getField("enableMail").api.setValue(1);
                    getField("email").api.enable();
                    getField("email").api.focus();
                }
            };

            function getField(internal) {
                return scope.formHelper.getFieldByInternal(internal);
            }

            /**
             * Validate the email addresses.
             */
            function validateEmail() {
                let obj = ValueUtilsService.extractMailAddresses(getField("email").value);
                const input = angular.element(getField("email").api.getElement()[0]);
                if (obj.isValid) {
                    input.removeClass("invalid");
                    MessageService.broadcast("unbind-validation-bubble", input);
                } else {
                    input.addClass("invalid");
                    MessageService.broadcast("bind-validation-bubble", {
                        input,
                        title: $filter("translate")("modal.email.validate.error.title"),
                        msg: $filter("translate")("modal.email.validate.error.message")
                    });
                }
            }

            function canSaveSubscription() {
                let canSave = false;

                if (scope.isDocument) {
                    if (getField("content").api.getValue() == 1 || getField("location").api.getValue() == 1
                        || getField("documentMoved").api.getValue() == 1) {
                        canSave = true;
                    }

                } else if (scope.isRegister) {
                    if (getField("registerMoved").api.getValue() == 1) {
                        canSave = true;
                    }
                }

                if (getField("indexdata").api.getValue() == 1 || getField("deletion").api.getValue() == 1) {
                    canSave = true;
                }

                return canSave;
            }
            /**
             * Evaluate the given data and save the changes to the abo objects.
             */
            function saveSubscription() {
                if (ClientService.isOffline()) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                    return;
                }

                if (!canSaveSubscription()) {
                    NotificationsService.error($filter("translate")("modal.abo.submission.missing.action.error"));
                    return;
                }

                let actions = getActionsObject();
                let confirmationMode = getConfirmationMode();
                let notifierObject = createUserAndGroupObject();
                const recipient = !hasSystemRoleAboForOthers ? "self" : scope.recipients[getField("self").api.getValue()];
                let mailAddresses = [];

                if (getField("enableMail").api.getValue() == 1 && notifierObject.recipients < 2) {
                    let obj = ValueUtilsService.extractMailAddresses(getField("email").api.getValue());

                    if (!obj.isValid || obj.mailAddresses.length == 0) {
                        NotificationsService.error($filter("translate")("modal.abo.submission.missing.mail.error"));
                        return;
                    } else {
                        mailAddresses = obj.mailAddresses;
                    }

                } else if (getField("deletion").api.getValue() == 1) {
                    NotificationsService.error($filter("translate")("modal.abo.submission.missing.mail.delete.action.error"));
                    return;
                }

                if (getField("deletion").api.getValue() == 0) {

                    if (recipient == "none" && (getField("enableMail").api.getValue() != 1 || !getField("email").api.getValue())) {
                        NotificationsService.error($filter("translate")("modal.abo.submission.missing.mail.norecipient.error"));
                        return;
                    }

                    if (recipient == "others" && notifierObject.usersToBeNotified.length == 0 && notifierObject.groupsToBeNotified.length == 0) {
                        NotificationsService.error($filter("translate")("modal.abo.submission.missing.user.error"));
                        return;
                    }
                }

                if (scope.isEdit) {
                    let url = "/notifications/subscriptionObjects";

                    let aboObject = aboObjects[0];
                    let aboData = {
                        objectId: parseInt(aboObject.model.osid),
                        infoText: getField("information").api.getValue(),
                        aboGroup: aboObject.model.aboGroup || "",
                        actions,
                        mailAddresses,
                        confirm: confirmationMode,
                        groupsToBeNotified: notifierObject.groupsToBeNotified,
                        usersToBeNotified: notifierObject.usersToBeNotified
                    };

                    submitData(url, aboData);
                } else {
                    let toSubscribedObjectList = [];
                    let url = "/notifications/subscriptionMultiObjects";

                    if (recipient == "others") {
                        url += "?system=true";
                    }

                    for (let aboObject of aboObjects) {
                        let aboData = {
                            objectId: parseInt(aboObject.model.osid),
                            infoText: getField("information").api.getValue(),
                            aboGroup: aboObject.model.aboGroup || "",
                            actions,
                            mailAddresses,
                            confirm: confirmationMode,
                            groupsToBeNotified: notifierObject.groupsToBeNotified,
                            usersToBeNotified: notifierObject.usersToBeNotified
                        };
                        toSubscribedObjectList.push(aboData);
                    }

                    submitData(url, toSubscribedObjectList);
                }

                /**
                 * Update the given abo objects.
                 * @param {string} url - The backend url for updating abo objects.
                 * @param {object} objectList - The list of abo objects.
                 * @returns {Promise<void>} Resolved once the abo objects are updated and changed.
                 */
                async function submitData(url, objectList) {
                    try {
                        let response = await BackendService.post(url, objectList);
                        let docModel = aboObjects[0].model;
                        let successMsg = scope.isEdit ? $filter("translate")("modal.abo.edit.success") : $filter("translate")("modal.abo.create.success");
                        NotificationsService.success(successMsg);

                        if (scope.isEdit) {
                            docModel.notifyType = response.data.notifyType;
                            docModel.actions = response.data.actions;
                            docModel.confirm = response.data.confirm;
                            docModel.infoText = response.data.infoText;
                            docModel.groupsToBeNotified = response.data.groupsToBeNotified;
                            docModel.usersToBeNotified = response.data.usersToBeNotified;
                            docModel.aboGroup = response.data.aboGroup;
                        }

                        CacheManagerService.dmsDocuments.executeListeners(docModel.osid);
                    } catch (error) {
                        let errorMsg = scope.isEdit ? $filter("translate")("modal.abo.edit.error") : $filter("translate")("modal.abo.add.error");
                        NotificationsService.backendMultiError(error, errorMsg);
                    } finally {
                        scope.close();
                    }
                }
            };

            /**
             * Get the selected actions of the abo object in backend format.
             * @returns {string[]} - A list of actions.
             */
            function getActionsObject() {
                let actions = [];

                for (let action in actionEnum) {
                    let field = getField(action);
                    if (field && field.api.getValue() == 1 && field.api.isEnabled()) {
                        actions.push(actionEnum[action]);
                    }
                }

                return actions;
            }

            /**
             * Get the organisation object data in backend format.
             * @returns {{recipients: number, groupsToBeNotified: Array, usersToBeNotified: Array}} - All organisation objects in backend format.
             */
            function createUserAndGroupObject() {
                let notifierObject = {
                    recipients: 0,
                    usersToBeNotified: [],
                    groupsToBeNotified: []
                };

                if (getField("deletion").api.getValue() == 1) {
                    return notifierObject;
                }

                if (!hasSystemRoleAboForOthers || scope.recipients[getField("self").api.getValue()] == "self") {
                    notifierObject.recipients++;
                    notifierObject.usersToBeNotified.push({
                        id: EnvironmentService.getSessionInfo().userid,
                        name: EnvironmentService.getSessionInfo().username,
                        fullname: EnvironmentService.getSessionInfo().fullname
                    });
                } else if (scope.recipients[getField("self").api.getValue()] == "others") {
                    const selectedContent = scope.orgEntries.filter(entry => entry.selected);
                    for (let { value: item } of selectedContent) {
                        if (item.type == "user") {
                            notifierObject.recipients++;
                            notifierObject.usersToBeNotified.push({
                                id: item.id,
                                name: item.name,
                                fullname: item.fullname
                            });
                        } else if (item.type == "group") {
                            notifierObject.recipients += 2;
                            notifierObject.groupsToBeNotified.push({
                                osguid: item.osguid,
                                name: item.name
                            });
                        }
                    }
                }

                return notifierObject;
            }

            /**
             * Get the confirmation mode in backend format.
             * @returns {string} The confirmation mode.
             */
            function getConfirmationMode() {
                if (!hasSystemRoleAboForOthers) {
                    return confirmationEnum.noConfirmation;
                }

                if (getField("confirmation").api.getValue() != 1 || getField("deletion").api.getValue() == 1) {
                    return confirmationEnum.noConfirmation;
                } else if (getField("confirmation").api.getValue() == 1 && getField("password").api.getValue() != 1) {
                    return confirmationEnum.confirmation;
                } else if (getField("confirmation").api.getValue() == 1 && getField("password").api.getValue() == 1) {
                    return confirmationEnum.password;
                }
            }
        },
        template: require("!raw-loader!./eob.modal.abo.html")
    };
}
