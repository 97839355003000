(function() {
    require("@shagstrom/split-pane/split-pane.js");

    require("COMPONENTS_PATH/eob-inline-tree/eob.inline.tree.dir.js");
    require("COMPONENTS_PATH/eob-user-menu/eob.user.menu.dir.js");
    require("COMPONENTS_PATH/eob-detailsviewer/eob.details.viewer.dir.js");
    require("COMPONENTS_PATH/eob-document-viewer/eob.document.viewer.dir.js");
    require("SERVICES_PATH/viewer/pdfjs.viewer.srv.js");

    require("SERVICES_PATH/eob.backend.srv.js");

    const EobModalDashletsComponent = require("../../../app/modules/dashlet/components/eob-modal-dashlets/eob-modal-dashlets.component").EobModalDashletsComponent;
    const NavigationEvents = require("MODULES_PATH/navigation/enums/navigation-events.enum").NavigationEvents;

    angular.module("eob.framework").directive("eobMain", EobMain);

    EobMain.$inject = ["$rootScope", "$compile", "viewerService", "asIniService", "backendService", "dmsDocumentService", "$q", "$timeout",
        "$filter", "layoutManagerService", "clientService", "ngZone", "modalDialogInjectorService", "messageService"];

    // eslint-disable-next-line max-params
    function EobMain($rootScope, $compile, ViewerService, AsIniService, BackendService, DmsDocumentService, $q, $timeout,
                     $filter, LayoutManagerService, ClientService, ngZone, ModalDialogInjectorService, MessageService) {
        return {
            restrict: "E",
            scope: false,
            link(scope, element) {
                let viewer = null,
                    main = null,
                    mainDivider = null;

                let layoutConfig = AsIniService.getLayoutConfiguration();
                window.onBluebird = true;

                if (ClientService.isDesktop()) {
                    supressResizeEvent();
                }

                if (!/#\/detachedViewer$/gi.test(location.href)) {
                    buildPanes();
                }

                MessageService.subscribe(NavigationEvents.UNPIN_NAVIGATION, (data) => {
                    let isFixed = data.unpin;
                    let suppressAutoSave = data.suppressAutoSave;
                    let nav = element.find(".nav-component");
                    let mainComponent = element.find(".main-component");
                    let splitPaneDivider = element.find(".split-pane-divider:first");
                    let navWidth = data.layoutConfig.navigationWidth == void 0 ? 20 : data.layoutConfig.navigationWidth;

                    if (nav == null) {
                        nav = element.find(".nav-component");
                    }

                    if (isFixed) {
                        nav.removeClass("pinned");
                        nav.addClass("fixed");
                        nav.css("width", "auto");
                        splitPaneDivider.removeClass("hidden");
                        mainComponent.removeClass("nav-pinned");
                        splitPaneDivider.css("right", `${100 - navWidth}%`);
                        mainComponent.css("width", `${100 - navWidth}%`);
                        nav.css("right", `${100 - navWidth}%`);
                    } else {
                        nav.addClass("pinned");
                        nav.removeClass("fixed");
                        nav.css("width", `${navWidth}%`);
                        nav.css("right", `${100 - navWidth}%`);
                        element.find(".split-pane-divider:first").addClass("hidden");
                        element.find(".main-component:first").addClass("nav-pinned");

                        let windowWidth = window.outerWidth;
                        mainComponent.css("width", `${windowWidth}px`);
                    }

                    if (!suppressAutoSave) {
                        savePinStateToAsIni(isFixed)
                    }
                });

                if (!ClientService.isPhoneOrTablet()) {
                    MessageService.subscribe("open.attached.viewer", (isShow) => {
                        if (main == null) {
                            main = element.find(".state-component");
                            viewer = element.find(".viewer-component");
                            mainDivider = element.find(".main-component .split-pane-divider:first");
                        }
                        if (isShow) {
                            // in case we open new tab and load it in background (electron)
                            if (viewer[0].style.width == "0%" || main[0].style.right == "0%") {
                                viewer.css("width", "30%");
                                main.css("right", "30%");
                                mainDivider.css("right", "30%")
                            }
                            main.removeClass("viewer-hidden");
                            viewer.removeClass("hidden");
                            mainDivider.removeClass("hidden");
                        } else {
                            main.addClass("viewer-hidden");
                            viewer.addClass("hidden");
                            mainDivider.addClass("hidden");
                        }
                    });
                } else {
                    // init the tablet-phone viewer
                    const dialogInput = { title: "", isPermanent: true, isFullscreen: true, isOpen: false };
                    ModalDialogInjectorService.createDialog(dialogInput, { component: EobModalDashletsComponent, input: {dialogInput} });
                }

                function savePinStateToAsIni(isFixed) {
                    let conf = {
                        isNavigationFixed: isFixed
                    };
                    AsIniService.updateLayoutConfiguration(conf)
                }

                function buildPanes() {
                    ngZone.runOutsideAngular(() => {
                        let mainDir = buildMain();
                        // create a new scope that is isolated and a child of this directives scope
                        let mainScope = $rootScope.$new(true, scope);

                        element.find("main").prepend($compile(mainDir)(mainScope));
                    });
                }

                function buildMain() {
                    // build the main parts, the outer container which holds the navigation and the inner container
                    // the inner container hold the
                    let outerContainer = angular.element("<split-pane></split-pane>");
                    let innerContainer = angular.element("<split-pane></split-pane>");

                    // adding special classes to later seperate them by scss
                    innerContainer.addClass("inner");
                    outerContainer.addClass("outer");

                    let width = parseInt(layoutConfig.navigationWidth);
                    if (ClientService.isMobile()) {
                        width = 40;
                    }
                    let navWidth = width != void 0 && !isNaN(width) ? `${width}%` : "18%";

                    // building each component
                    let navComponent = buildComponent(angular.element("<eob-nav style='z-index:100'></eob-nav>"), "nav-component", navWidth); //the zIndex is needed for autocomplete positioning and calculations
                    let mainComponent = buildComponent(angular.element("<div ui-view id='main-view'></div>"), "state-component", "70%");
                    let viewerComponent = buildComponent(angular.element("<eob-attached-dashlet-container id='eob-dashlet-container'></eob-attached-dashlet-container>"), "viewer-component");

                    // classes to adjust the dividers
                    mainComponent.addClass("main-split-pane");
                    viewerComponent.addClass("main-split-pane");

                    // initilize the navComponent as fixed
                    navComponent.addClass("fixed")

                    if (ClientService.isPhoneOrTablet()) {
                        // in this case we only need one component and simply overwrite the innerContainer
                        innerContainer = mainComponent;
                        innerContainer.addClass("full-width");
                    } else {
                        // wire the two inner components together
                        composeComponents(innerContainer, mainComponent, viewerComponent);
                    }

                    // build the inner component
                    let innerComponent = buildComponent(innerContainer, "main-component");

                    // wire the main components together
                    composeComponents(outerContainer, navComponent, innerComponent);

                    return outerContainer;
                }

                function composeComponents(container, leftComponent, rightComponent) {
                    container.append(leftComponent);
                    container.append(angular.element("<split-pane-divider width='8px'></split-pane-divider>"));
                    container.append(rightComponent)
                }

                function buildComponent(content, componentClass, width) {

                    let component = angular.element("<split-pane-component>");

                    if (width != void 0) {
                        component.attr("width", width);
                    }

                    if (componentClass != void 0) {
                        component.addClass(componentClass)
                    }

                    component.append(content);

                    return component;
                }

                function supressResizeEvent() {
                    element[0].addEventListener("resize", event => {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                    })
                }
            },
            template: require("!raw-loader!./eob.main.html")
        };
    }

    module.exports = EobMain;
})();
