import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";import {takeWhile} from "rxjs/operators";

(function () {


    require("COMPONENTS_PATH/eob-circular-tab-form/eob.circular.tab.form.dir.js");

    require("SERVICES_PATH/form/eob.form.srv.js");
    require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");

    angular.module("eob.framework").directive("eobModalCirculationSlipSave", EobModalCirculationSlipSave);

    EobModalCirculationSlipSave.$inject = ["$rootScope", "$timeout", "$filter", "$compile", "formFieldModelService", "formService", "formHelper", "clientService", "notificationsService", "messageService"];

    function EobModalCirculationSlipSave($rootScope, $timeout, $filter, $compile, FormFieldModelService, FormService, FormHelper, ClientService, NotificationsService, MessageService) {
        return {
            scope: {
                newTemplate: "=",
                destroy$: "=destroy"
            },
            link (scope, element) {
                scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

                scope.saveCirculationSlip = function () {
                    if (ClientService.isOffline()) {
                        NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                        return;
                    }
                    FormService.validateForm(scope.formHelper.getFields()).then(() => {
                        let nameField = scope.formHelper.getFieldByInternal("name");
                        let publicField = scope.formHelper.getFieldByInternal("public");

                        scope.newTemplate.name = nameField.api.getValue();
                        scope.newTemplate.public = (publicField.api.getValue() == "1");

                        $rootScope.$broadcast("circulation.slips.save");
                        scope.close();
                    }, () => {
                        // do nothing.
                    });
                };

                // create mocked fields to access the api later
                let nameField = FormFieldModelService.getMockedField("text", "name", $filter("translate")("eob.workflow.circulation.template.name"));
                let publicFormField = FormFieldModelService.getMockedField("checkbox", "public", $filter("translate")("eob.workflow.circulation.template.public"));

                nameField.isRequired = true;

                scope.circulationSlipFormFields = [nameField, publicFormField];

                let formData = FormService.createFormData(scope.circulationSlipFormFields, "max");

                let helperConfig = {
                    formData,
                    submit: scope.saveCirculationSlip
                };

                // create a form helper
                scope.formHelper = FormHelper.getFormHelper(helperConfig);
                scope.formHelper.getFieldByInternal("name").value = scope.newTemplate.name;

                scope.formDef = {
                    isMainForm: true,
                    validationMode: "max",
                    formFields: scope.circulationSlipFormFields,
                    formHelper: scope.formHelper,
                    isMockForm: true
                };

                let content = angular.element("<eob-circular-tab-form></eob-circular-tab-form>");
                let form = angular.element("<div class='form-container'><div class='form-wrapper'><eob-form class='single-column' [formdef]=\"formDef\"></eob-form></div></div>");
                let buttons = angular.element("<div class=\"button-container\"></div>");

                buttons.append("<button type='button' class='secondary-button' style='margin-right: 4px' ng-click='close()'>{{::'modal.button.cancel' | translate}}</button>");
                buttons.append("<button ng-click='saveCirculationSlip()'>{{::'modal.button.save' | translate}}</button>");

                content.append(form);
                content.append(buttons);

                // compile the form and let angular do its magic
                let compiled = $compile(content);

                // attach the compiled form to the directive HTML element
                element.append(content);

                // wire the compiled form to the current scope
                compiled(scope);

                $timeout(() => {
                    let nameField = scope.formHelper.getFieldByInternal("name");
                    let publicField = scope.formHelper.getFieldByInternal("public");

                    nameField.api.focus();

                    if (!scope.newTemplate.canManagePrivateCirculation || !scope.newTemplate.canManagePublicCirculation) {
                        publicField.api.disable();
                    }

                    if (!scope.newTemplate.canManagePrivateCirculation || scope.newTemplate.public) {
                        publicField.api.setValue("1"); // Checked?!?
                    }
                }, 0);

               scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => {
                    if (scope.formHelper != void 0) {
                        scope.formHelper.destroy();
                    }
               });
            }
        };
    }
})();