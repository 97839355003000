import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {Observable} from "rxjs";
import {OsrestWorkflowService} from "CORE_PATH/backend/modules/osrest/services/inbox/osrest-workflow.service";
import {InboxActionContextMenuItem, StartableWorkflow} from "CORE_PATH/interfaces/inbox-action.service.interface";
import {WorkflowContextMenuItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";

@Injectable({providedIn: BackendModule})
export class BackendWorkflowService {

    constructor(private delegate: OsrestWorkflowService) {
    }

    /**
     * start a Workflow
     *
     * @param {string} wfClientType
     * @param {StartableWorkflow} startableWorkflow
     */
    startWorkflow(wfClientType: string, startableWorkflow: StartableWorkflow): Observable<unknown> {
        return this.delegate.startWorkflow(wfClientType, startableWorkflow);
    }

    /**
     * personalize Workflows
     *
     * @param {string} wfClientType
     * @param {ContextItem[]} result
     */
    personalizeWorkflows(wfClientType: string, result: InboxActionContextMenuItem[]): Observable<void> {
        return this.delegate.personalizeWorkflows(wfClientType, result);
    }

    /**
     * depersonalize Workflows
     *
     * @param {string} wfClientType
     * @param {WorkflowContextMenuItem[]} result
     */
    depersonalizeWorkflows(wfClientType: string, result: WorkflowContextMenuItem[]): Observable<void> {
        return this.delegate.depersonalizeWorkflows(wfClientType, result);
    }
}
