import {Component, ElementRef, Input, ViewChild, OnInit, Renderer2, Inject,} from "@angular/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {AutoCompleteService} from "MODULES_PATH/autocomplete/services/autocomplete.service";
import {FormCatalogEntry} from "MODULES_PATH/form/interfaces/form.interface";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {AutoCompleteConfig} from "MODULES_PATH/autocomplete/interfaces/autocomplete.interface";

@Component({
    selector: "eob-state-fulltext-filter",
    templateUrl: "./eob-state-fulltext-filter.component.html",
    styleUrls: ["./eob-state-fulltext-filter.component.scss"]
})

export class EobStateFulltextFilterComponent implements OnInit {
    constructor(private messageService: MessageService, private autoCompleteService: AutoCompleteService,
                protected clientService: ClientService,
                @Inject("$stateParams") protected $stateParams: any,
                @Inject("backendService") protected backendService: any,
                private el: ElementRef, private renderer: Renderer2) {}

    @Input() fulltext: string;
    @Input() formhelper: any;
    @Input() objecttypeid: string;
    @Input() fulltextopen: boolean;
    eobAutocomplete: AutoCompleteConfig;

    @ViewChild("filterInput", {static: false}) input: ElementRef<HTMLInputElement>;
    @ViewChild("filterIcon", {static: false}) button: ElementRef<HTMLElement>;

    private headerContent: HTMLElement;
    private headerPlugins: HTMLElement;

    ngOnInit(): void {
        this.eobAutocomplete = {
            debounce: 0,
            minCharacters: 1,
            useMultiSelect: false,
            isField: false,
            hasAutoComplete: false,
            isGridCell: false,
            isDisabled: false,
            addon: undefined,
            autoCompleteOpen: false,
            isSelectingValue: false,
            getItems: this.autoCompleteCallback.bind(this)
        };

        this.headerContent = document.body.querySelector(".dialog-header-content");
        this.headerPlugins = document.body.querySelector(".state-header-plugins");

        if (this.clientService.isPhone()) {
            setTimeout(() => {
                this.setFilterStyling();
            }, 0);
        }
    }

    onKeyUp(): void {
        this.messageService.broadcast(FormEvent.UPDATE_FULLTEXT_QUERY, {
            fulltext: this.fulltext,
            objectTypeId: this.objecttypeid
        });
    }

    submit(): void {
        this.formhelper.submit();
    }

    toggleFilter(event: Event): void {
        event.preventDefault();
        this.fulltextopen = !this.fulltextopen;
        this.setFilterStyling();
    }

    setFilterStyling(): void {
        if (this.fulltextopen) {
            if (this.clientService.isPhone()) {
                this.renderer.setStyle(this.headerContent, "display", "none");
                this.renderer.setStyle(this.headerPlugins, "width", "100%");
                this.renderer.setStyle(this.renderer.parentNode(this.headerPlugins), "width", "100%");
            }

            setTimeout(() => this.input.nativeElement.focus(), 0);
        } else {
            this.button.nativeElement.className = this.input.nativeElement.value != "" ? "icon-24-fulltext-search-active" : "icon-24-search";

            if (this.clientService.isPhone()) {
                this.renderer.setStyle(this.headerContent, "display", "flex");
                this.renderer.setStyle(this.headerPlugins, "width", "auto");
                this.renderer.setStyle(this.renderer.parentNode(this.headerPlugins), "width", "auto");
            }
        }
    }

    autoCompleteCallback(searchKey: string): Promise<FormCatalogEntry[]> {
        return new Promise(((resolve, reject) => {
            let list: FormCatalogEntry[] = [];
            const url = "/documents/vtx/autocomplete/";
            const data: any = {
                query: searchKey,
                facets: {
                    objtype: [this.$stateParams.objectTypeId]
                }
            };

            this.backendService.post(url, data).then((response) => {
                if (typeof (response.data) == "object") {
                    for (const i in Object.keys(response.data)) {
                        const listEntry: FormCatalogEntry = {
                            value: "",
                            short: ""
                        };
                        listEntry.value = Object.keys(response.data)[i];
                        list.push(listEntry);
                    }
                }
                list = this.autoCompleteService.sortResult(list, false);
                resolve(list);
            }).catch((error) => {
                console.warn(error);
            });
        }));
    }
}
