import {NgModule} from "@angular/core";

@NgModule({
    declarations: [],
    imports: [],
    entryComponents: [],
    exports: []
})
export class ScriptingModule {
}
