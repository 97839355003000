import {Component, OnInit} from "@angular/core";
import {
    AfterViewInit,
    ChangeDetectorRef,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    Renderer2,
    ViewChild
} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {TodoClientScriptService, TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {FormControl, ValidationErrors} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {EMPTY, merge, Subscription} from "rxjs";
import {ValidationErrorMapperService} from "MODULES_PATH/form/services/validation-error-mapper.service";
import {DisplayBubbleDirective} from "MODULES_PATH/form/directives/display-bubble.directive";
import {StandaloneValidationBubbleComponent} from "MODULES_PATH/form/components/standalone-validation-bubble/standalone-validation-bubble.component";

@Component({
    selector: "eob-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"]
})
export class CheckboxComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() field: Field;
    @Input() formhelper: any;
    @Input() ismockform = true;
    @Input() formid = "";
    @Input() title = "";
    @Input() tooltip = "";
    @Input() icon = "";
    @Input() hideLabel = false;
    @Input() control: FormControl = new FormControl();
    @Input() useTriState: boolean;
    @Input() customBubble: StandaloneValidationBubbleComponent;

    checkboxState = "";
    fieldid = "";
    showAltLang: boolean;
    objectDefLang: any;
    readonly sub = new Subscription();

    @ViewChild("checkbox", {static: false}) checkbox: ElementRef<HTMLElement>;
    @ViewChild(DisplayBubbleDirective, {static: false}) displayBubble: DisplayBubbleDirective;

    constructor(@Inject(forwardRef(() => "environmentService")) public environmentService: TodoEnvironmentService,
                @Inject(forwardRef(() => "clientScriptService")) public clientScriptService: TodoClientScriptService,
                public validationErrorMapperService: ValidationErrorMapperService,
                public cdRef: ChangeDetectorRef,
                public renderer: Renderer2,
                public el: ElementRef) {
    }

    ngOnInit(): void {
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang() && !this.ismockform;
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();
        this.useTriState = !!this.useTriState;
        // Small debounce as not to do things twice if updateValueAndValidity() is called after setting the value
        this.sub.add(merge(this.control.valueChanges ?? EMPTY, this.control.statusChanges ?? EMPTY).pipe(debounceTime(50)).subscribe(() => {
            if (this.control.status == "INVALID") {
                this.renderer.addClass(this.checkbox.nativeElement, "invalid");
                const validationErrorMessage: ValidationErrors = this.validationErrorMapperService.getValidationErrorMessage(this.control.errors);
                this.displayBubble.setValidationMessage(validationErrorMessage.title, validationErrorMessage.message);
            } else {
                this.renderer.removeClass(this.checkbox.nativeElement, "invalid");
            }
            if(this.field) {
                if(!this.control.value) {
                    this.field.value = "";
                } else if(!/^[01]?$/.test(this.control.value)) {
                    this.field.value = "0";
                } else {
                    this.field.value = this.control.value;
                }

                this.field.api.syncParameterValue();
            }
            this.setValueClass();
            // Required if form control is invalidated externally, e.g. by form submission
            this.cdRef.detectChanges();
        }));
        this.fieldid = `${this.field?.model.internal ?? ""}-${this.formid}`;
    }

    ngAfterViewInit(): void {
        // TODO refactor external value setting
        //$(this.el.nativeElement).on("update.cb.class", this.setValueClass.bind(this));
        this.renderer.setAttribute(this.checkbox.nativeElement, "aria-labelledby", this.fieldid);
        if (this.field) {
            if (!this.useTriState && this.control.value === "") {
                this.field.value = "0";
            } else if (this.environmentService.featureSet.contains("dms.search.combined") && this.field.model.isBaseParam) {
                this.field.value = "1";
            }
            this.control.setValue(this.field.value, {emitEvent: false});
            this.renderer.setAttribute(this.checkbox.nativeElement, "id", `${this.field.model.internal}`);
            if (!this.ismockform) {
                const index: number = Number(this.field.model.tabIndex) + 1000;
                this.renderer.setAttribute(this.checkbox.nativeElement, "tabindex", `${index}`);
            }
        }
        this.setValueClass();
    }

    setValueClass(): void {
        // don't use the upper scope checkbox variable, it may be outdated by now
        ["ok", "null", "ambiguous"].forEach(x => this.renderer.removeClass(this.checkbox.nativeElement, x));
        if(this.control.value == null && this.field) {
            this.control.setValue(this.field.value, {emitEvent: false});
        }
        if (this.control.value == "1") {
            this.renderer.addClass(this.checkbox.nativeElement, "ok");
            this.renderer.setAttribute(this.checkbox.nativeElement, "aria-checked", "true");
            this.checkboxState = "checkbox-ok";
        } else if (this.control.value !== "" && this.control.value == "0") {
            this.renderer.addClass(this.checkbox.nativeElement, "null");
            this.renderer.setAttribute(this.checkbox.nativeElement, "aria-checked", "false");
            this.checkboxState = "";
        } else if (this.useTriState) {
            this.renderer.addClass(this.checkbox.nativeElement, "ambiguous");
            this.renderer.setAttribute(this.checkbox.nativeElement, "aria-checked", "mixed");
            this.checkboxState = "checkbox-ambiguous";
        } else {
            this.renderer.addClass(this.checkbox.nativeElement, "null");
            this.renderer.setAttribute(this.checkbox.nativeElement, "aria-checked", "false");
            this.checkboxState = "";
        }
        this.cdRef.detectChanges();
    }

    onEnter(event: KeyboardEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (!this.formhelper?.isWorkflow) {
            this.formhelper?.submit();
        }
    }

    onToggle(event: KeyboardEvent | MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (this.field?.isDisabled || this.control.disabled) {
            return;
        }
        if(this.field) {
            const tmpValue: string = this.field.value;

            if (this.useTriState) {
                if (this.field.value === "") {
                    this.field.value = "1";
                } else if (this.field.value == "1") {
                    this.field.value = "0";
                } else {
                    this.field.value = "";
                }
            } else if (!this.field.isDisabled) {
                if (this.field.value == "1") {
                    this.field.value = "0";
                } else {
                    this.field.value = "1";
                }
            }

            if (this.field.value !== tmpValue) {
                this.control.setValue(this.field.value);

                this.field.api.validate().finally(() => {
                    if (this.field.eventScripts != void 0 && typeof (this.field.eventScripts.onChange) == "function") {
                        this.field.eventScripts.onChange(this.formhelper, this.formhelper.globals, this.clientScriptService.getGlobalScriptingStorage(), this.field);
                    }
                }).catch(e => console.warn(e));
            }
        } else if (this.useTriState) {
            if (!this.control.value) {
                this.control.setValue("1");
            } else if (this.control.value == "1") {
                this.control.setValue("0");
            } else {
                this.control.setValue("");
            }
        } else if (!this.control.value || this.control.value == "0") {
            this.control.setValue("1");
        } else {
            this.control.setValue("0");
        }
        this.setValueClass();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

}
