import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {Observable} from "rxjs";
import {OsrestRevisitService} from "CORE_PATH/backend/modules/osrest/services/inbox/osrest-revisit.service";
import {InboxContextMenuItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {InboxActionContextMenuItem, ConfirmationObject} from "CORE_PATH/interfaces/inbox-action.service.interface";

@Injectable({providedIn: BackendModule})
export class BackendRevisitService {

    constructor(private delegate: OsrestRevisitService) {
    }

    /**
     * mark read revisits
     *
     * @param {ContextItem[]} data
     */
    markReadRevisits(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.delegate.markReadRevisits(data);
    }

    /**
     * mark unread revisits
     *
     * @param {ContextItem[]} data
     */
    markUnreadRevisits(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.delegate.markUnreadRevisits(data);
    }

    /**
     * mark as edited unedited revisits
     *
     * @param {ContextItem[]} data
     */
    markAsEditedUneditedRevisits(data: InboxActionContextMenuItem[]): Observable<void> {
        return this.delegate.markAsEditedUneditedRevisits(data);
    }

    /**
     * mark single as edited unedited subscriptions
     *
     * @param {InboxContextMenuItem} contextItem
     * @param {ConfirmationObject} confirmationObject
     */
    markSingleAsEditedUneditedRevisits(contextItem: InboxContextMenuItem, confirmationObject: ConfirmationObject): Observable<void> {
        return this.delegate.markSingleAsEditedUneditedRevisits(contextItem, confirmationObject);
    }

}
