import Dexie from "dexie";
import {DatabaseType} from "ENUMS_PATH/database/database-type.enum";
import {ClientService} from "CORE_PATH/services/client/client.service";

export class CustomDatabase extends Dexie {
    readonly TIMEOUT: number = 1000 * 30;
    readonly TIMEOUT_RETRY: number = 3;

    constructor(dbName: string = DatabaseType.GLOBAL, clientService: ClientService) {
        super(dbName);

        if (clientService.isiOs()) {
            this.addCustomTimeouts();
        }
    }

    // region iOS Workaraound
    /**
     * iOS Workaround
     * Intercept dexie db calls and retry them, if they run into a timeout.
     */
    private addCustomTimeouts(): void {
        // Special case for bulkAdd
        // If bulkAdd is aborted and retried, an error may occur, because some entries were already created and can't be overwritten.
        // Therefor we need to check first, which entries already exist and only add the remaining ones, in case of a retry.
        this.overwriteFn(this.Table.prototype, "bulkAdd", async (table, fnArgs, originalFn) => {
            let promise: any,
                entries: any[] = fnArgs[0];

            for (let i: number = 0; i < this.TIMEOUT_RETRY; i++) {
                try {
                    promise = originalFn.apply(table, [entries]);
                    await promise;
                } catch(error) {
                    const primKeySchema: any = table.schema.primKey;
                    // bulkAdd failed, because some elements were already successfully created
                    if (error.message.includes("ConstraintError") && !primKeySchema.dotted && primKeySchema.unique && !primKeySchema.compound) {
                        const primKey: string = table.schema.primKey.name;

                        const primKeys: any[] = entries.map(entry => entry[primKey]);
                        const existingKeys: any[] = await table.where(table.schema.primKey.name).anyOf(primKeys).primaryKeys();

                        entries = entries.filter(entry => !existingKeys.includes(entry[primKey]));

                        console.warn(`retry bulkAdd ${i+1}`);
                        continue;
                    } else {
                        break;
                    }
                }

                if (i > 0) {
                    console.warn(`bulkAdd retry ${i} was successfull`);
                }

                break;
            }

            return promise;
        });

        this.overwriteFn(this.Table.prototype, "_trans", (table, fnArgs, originalFn) => this.timeoutCall(() => originalFn.apply(table, fnArgs)));
    }

    private async overwriteFn(prototype: any, fnKey: string, overwriteFn: any): Promise<void> {
        const originalFn: any = prototype[fnKey];

        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        prototype[fnKey] = function(): Promise<any> {
            const args: any = arguments, self: any = this;
            return overwriteFn(self, args, originalFn);
        };
    }

    private async timeoutCall(originalFn: any): Promise<any> {
        let fnPromise: Dexie.Promise<any>;

        for (let i: number = 0; i < this.TIMEOUT_RETRY; i++) {
            try {
                fnPromise = originalFn().timeout(this.TIMEOUT * (i+1));
                await fnPromise;
            } catch(error) {
                if (error.name == "TimeoutError") {
                    console.warn(`retry db transaction ${i+1}`);
                    // console.trace();
                    continue;
                } else {
                    break;
                }
            }

            if (i > 0) {
                console.warn(`transaction retry ${i} was successfull`);
            }

            break;
        }

        return fnPromise;
    }
    // endregion
}