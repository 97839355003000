import {IconCellComponent} from "../../../../app/modules/grid/components/grid-cells/icon-cell/icon-cell.component";

(function() {

    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");
    require("SERVICES_PATH/form/eob.form.srv.js");
    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/eob.state.history.manager.srv.js");
    require("SERVICES_PATH/form/field-api/eob.form.field.api.srv.js");

    angular.module("eob.framework").directive("eobUserUpsert", EobUserUpsert);

    EobUserUpsert.$inject = ["$filter", "$compile", "$stateParams", "$timeout",
        "environmentService", "formFieldModelService", "formHelper", "formService", "notificationsService",
        "backendService", "valueUtilsService", "stateHistoryManager", "organisationService", "formValidationService",
        "clientService", "fieldApiService", "messageService"];

    /**
     * Directive for the user creation and editation.
     */
    // eslint-disable-next-line max-params, require-jsdoc
    function EobUserUpsert($filter, $compile, $stateParams, $timeout,
                           EnvironmentService, FormFieldModelService, FormHelper, FormService, NotificationsService,
                           BackendService, ValueUtilsService, StateHistoryManager, OrganisationService, ValidationService,
                           ClientService, FieldApiService, MessageService) {
        return {
            restrict: "E",
            link(scope, element) {
                scope.ready = false;
                scope.formHelper = null;
                scope.formData = null;
                scope.stateTitle = $filter("translate")("eob.usermanagement.state.title");

                let stateId = $stateParams.state;
                let stateConfig = StateHistoryManager.getCurrentConfig(stateId);
                let passwordHint;
                let currentUser = {
                    groups: []
                };

                scope.save = save;
                scope.cancel = () => setTimeout(() => { history.back() }, 0);

                (async () => {
                    try {
                        await buildUpsertForm();
                    } catch (error) {
                        console.warn(error);
                        ClientService.executeStateErrorFallback();
                        return;
                    }

                    if ($stateParams.mode == "create") {
                        currentUser.groups = ["STANDARD"];
                        scope.stateDescription = $filter("translate")("eob.usermanagement.state.description.create")
                    } else {
                        scope.stateDescription = $filter("translate")("eob.usermanagement.state.description.edit");
                        currentUser = OrganisationService.getUserById($stateParams.user);

                        if (stateConfig.formData == void 0) {
                            scope.formHelper.getFieldByInternal("loginName").value = currentUser.name;
                            scope.formHelper.getFieldByInternal("userName").value = currentUser.fullname;
                            scope.formHelper.getFieldByInternal("limited").value = currentUser.limited;
                            scope.formHelper.getFieldByInternal("locked").value = currentUser.locked;
                            scope.formHelper.getFieldByInternal("description").value = currentUser.description;
                            scope.formHelper.getFieldByInternal("email").value = currentUser.email;
                        }

                        ["loginName", "limited"].map(fieldName => {
                            return scope.formHelper.getFieldByInternal(fieldName);
                        }).forEach(field => {
                            FieldApiService.getFieldApi(scope.formHelper, field).disable();
                        })

                        delete currentUser.icon;
                        delete currentUser.type;
                        delete currentUser.userGroupMixInfo;
                        delete currentUser.description;
                        delete currentUser.valid;
                    }

                    if (stateConfig.formData != void 0) {
                        currentUser.groups = stateConfig.groups;

                        for (let fieldName in scope.formData) {
                            scope.formData[fieldName].value = stateConfig.formData[fieldName];
                        }
                    }

                    scope.saveUserSubscripton = MessageService.subscribe("USERMANAGEMENT_SAVE_USER", () => {
                        save();
                    });

                    initFooterConfig();
                    buildGroups();

                    scope.ready = true;
                })();

                function initFooterConfig() {
                    scope.footerConfigs = [{
                        icon: { name: "footer-back-dark", title: $filter("translate")("form.footer.back") },
                        action: "back",
                        class: "footer-button secondary-button"
                    }, {
                        icon: { name: "footer-save", title: $filter("translate")("form.footer.back") },
                        action: "saveUser",
                        class: "footer-button save-button"
                    }]
                }

                /**
                 * Create the form data with mocked fields.
                 *
                 * @returns {Promise} Resolved one the form is built and compiled.
                 */
                async function buildUpsertForm() {
                    let container = element.find("#upsert-container");

                    let loginNameField = FormFieldModelService.getMockedField("text", "loginName", $filter("translate")("eob.user.loginname"));
                    let userNameField = FormFieldModelService.getMockedField("text", "userName", $filter("translate")("eob.user.username"));
                    let descriptionField = FormFieldModelService.getMockedField("text", "description", $filter("translate")("eob.user.description"));
                    let emailField = FormFieldModelService.getMockedField("text", "email", $filter("translate")("eob.user.email"));
                    let passwordHintField = FormFieldModelService.getMockedField("static", "passwordHint", "");
                    let passwordField = FormFieldModelService.getMockedField("text", "password", $filter("translate")("eob.user.password"));
                    let passwordRepeatField = FormFieldModelService.getMockedField("text", "passwordRepeat", $filter("translate")("eob.user.password.repeat"));
                    let lockedField = FormFieldModelService.getMockedField("checkbox", "locked", $filter("translate")("eob.user.locked"));
                    let limitedField = FormFieldModelService.getMockedField("checkbox", "limited", $filter("translate")("eob.user.limited"));

                    loginNameField.isRequired = true;
                    passwordField.isPassword = true;
                    passwordRepeatField.isPassword = true;

                    if ($stateParams.mode == "create") {
                        passwordField.isRequired = true;
                        passwordRepeatField.isRequired = true;
                    }

                    passwordHintField.isInvisibleField = true;

                    let registryKeys = [{ "key": "Login\\PwdComplexityDescription" }, { "key": "Login\\PwdComplexity" }];
                    let registryValues = (await BackendService.post("/serviceinfo/registry/", registryKeys)).data;
                    passwordHint = registryValues[0].value;
                    let passwordRegex = registryValues[1].value;

                    if (passwordRegex != void 0 && passwordRegex.length > 0) {
                        passwordField.regEx = passwordRegex;

                        if (passwordHint != void 0 && passwordHint.length > 0) {
                            passwordHintField.title = $filter("translate")("modal.password.password.hint") + passwordHint;
                            passwordHintField.isInvisibleField = false;
                        }
                    }

                    scope.userFormFields = [loginNameField, userNameField, passwordHintField, passwordField, passwordRepeatField,
                        lockedField, limitedField, emailField, descriptionField];

                    scope.formData = FormService.createFormData(scope.userFormFields, "max");

                    if ($stateParams.mode != "create") {
                        currentUser = OrganisationService.getUserById($stateParams.user);

                        scope.formData.loginName.value = currentUser.name;
                        scope.formData.userName.value = currentUser.fullname;
                        scope.formData.limited.value = currentUser.limited;
                        scope.formData.locked.value = currentUser.locked;
                        scope.formData.description.value = currentUser.description;
                        scope.formData.email.value = currentUser.email;
                    }

                    let helperConfig = {
                        formData: scope.formData,
                        submit: scope.save
                    };

                    scope.formHelper = FormHelper.getFormHelper(helperConfig);

                    scope.formDef = {
                        isMainForm: true,
                        validationMode: "max",
                        formFields: scope.userFormFields,
                        formHelper: scope.formHelper,
                        isMockForm: true
                    };

                    let content = angular.element("<div class=\"form-wrapper\"></div>");
                    let form = angular.element("<eob-form [formdef]='formDef' class='single-column'></eob-form>");
                    let capslockTracer = angular.element("<div class='capslock-warning-container'><eob-capslock-tracer></eob-capslock-tracer></div>");

                    content.append(form);
                    content.append(capslockTracer);
                    let compiled = $compile(content);
                    container.append(content);
                    compiled(scope);

                    $timeout(() => {
                        $timeout(() => {
                            addValidation();

                            if ($stateParams.mode == "create") {
                                scope.formHelper.getFieldByInternal("loginName").api.focus();
                            }
                        }, 0);
                    }, 0);
                }

                /**
                 * Enhance the form with validation.
                 */
                function addValidation() {
                    let email = scope.formHelper.getFieldByInternal("email"),
                        passwordRepeat = scope.formHelper.getFieldByInternal("passwordRepeat"),
                        password = scope.formHelper.getFieldByInternal("password");
                    let errorTitle = $filter("translate")("eob.add.user.general.error.title");

                    email.api.addCustomValidation(field => ValueUtilsService.extractMailAddresses(field.value),
                        $filter("translate")("modal.email.validate.error.title"), $filter("translate")("modal.email.validate.error.message"));

                    passwordRepeat.api.addCustomValidation(() => { return password.value == passwordRepeat.value && password != ""; },
                        errorTitle, $filter("translate")("eob.add.user.password.missmatch.message"));

                    if (password.model.regEx) {
                        let errorMessage = ($filter("translate")("eob.password.insufficient.complexity.error")).replace("[%s]", passwordHint);
                        password.api.addCustomValidation(ValidationService.validateRegEx, errorTitle, errorMessage);
                    }

                    if ($stateParams.mode == "create") {
                        let loginName = scope.formHelper.getFieldByInternal("loginName");
                        loginName.api.addCustomValidation((field) => { return OrganisationService.getUserByName(field.value.toLowerCase()) == null; },
                            errorTitle, $filter("translate")("eob.add.user.name.already.in.use.message"));
                    }
                }

                /**
                 * Initialize the grid config for the user groups.
                 */
                function buildGroups() {
                    let groupColDefs = buildGroupColDefs();
                    let groupRows = buildGroupRows();

                    scope.groupListConfig = {
                        columns: groupColDefs,
                        rows: groupRows,
                        filter: "",
                        suppressMultiselect: true,
                        suppressGrouping: true,
                        onDoubleClick() { /*do nothing at the moment*/ },
                        frameworkComponents: {
                            iconCellRenderer: IconCellComponent
                        }
                    };
                }

                /**
                 * Create the column definitions for the grid config of the user groups.
                 *
                 * @returns {object[]} The grid column definitions.
                 */
                function buildGroupColDefs() {
                    let colDefs = [
                        {
                            field: "groupName",
                            headerName: $filter("translate")("eob.user.group.table.heading.group"),
                            isIconCell: false
                        }, {
                            field: "description",
                            headerName: $filter("translate")("eob.user.group.table.heading.description"),
                            isIconCell: false
                        }, {
                            field: "addGroup",
                            headerName: $filter("translate")("eob.user.group.table.heading.member"),
                            isIconCell: true,
                            resizable: false,
                            sortable: false,
                            suppressMovable: true,
                            suppressSizeToFit: true,
                            width: 80,
                            cellStyle: { "cursor": "pointer" },
                            onCellClicked: addToGroup,
                            cellRenderer: "iconCellRenderer",
                            cellClass: "group-cell"
                        }
                    ];

                    return colDefs;
                }

                /**
                 * Add the user to the given group.
                 *
                 * @param {object} params - Data with the group the user shall be added to.
                 */
                function addToGroup(params) {
                    let currentGroup = params.data.groupName.value;
                    let groupIndex = currentUser.groups.indexOf(currentGroup);

                    const newValue = {};
                    if (groupIndex == -1) {
                        currentUser.groups.push(currentGroup);
                        newValue.icon = "group-yes";
                        newValue.ariaLabel = $filter("translate")("eob.objecttype.checkbox.trueval");
                    } else {
                        currentUser.groups.splice(groupIndex, 1);
                        newValue.icon = "group-no";
                        newValue.ariaLabel = $filter("translate")("eob.objecttype.checkbox.falseval");
                    }

                    params.node.setDataValue("addGroup", newValue);
                }

                /**
                 * Build the rows for the grid config of the user groups.
                 *
                 * @returns {object[]} - The grid rows.
                 */
                function buildGroupRows() {
                    let groups = OrganisationService.getGroupList();
                    let rows = [];

                    for (let i in groups) {
                        let group = groups[i];

                        let row = {
                            "groupName": {
                                value: group.name,
                                headerName: $filter("translate")("eob.user.group.table.heading.group")
                            },
                            "description": {
                                value: group.description,
                                headerName: $filter("translate")("eob.user.group.table.heading.description")
                            },
                            "addGroup": {
                                headerName: $filter("translate")("eob.user.group.table.heading.member"),
                                value: {}
                            },
                            "id": group.id,
                            "context": "usermanagement"
                        };

                        let initialGroups = stateConfig.groups || currentUser.groups;
                        if (initialGroups.indexOf(group.name) != -1) {
                            row.addGroup.value.icon = "group-yes";
                            row.addGroup.value.ariaLabel = $filter("translate")("eob.objecttype.checkbox.trueval");
                        } else {
                            row.addGroup.value.icon = "group-no";
                            row.addGroup.value.ariaLabel = $filter("translate")("eob.objecttype.checkbox.falseval");
                        }

                        rows.push(row)
                    }

                    return rows;
                }

                /**
                 * Validates the form and saves the new user or changes to an exisiting user.
                 * In case of an error an error message is shown. Refreshes cached data.
                 *
                 * @returns {Promise} Resolved once the data is saved and the cached data is refreshed.
                 */
                async function save() {
                    await FormService.validateForm(scope.formHelper.getFields());

                    if (currentUser.groups.length === 0) {
                        NotificationsService.error($filter("translate")("eob.usermanagement.missing.user.groups"));
                        return;
                    }

                    let pwdValue = scope.formHelper.getFieldByInternal("password").value,
                        pwdRepeatValue = scope.formHelper.getFieldByInternal("passwordRepeat").value;

                    if (pwdValue != pwdRepeatValue) {
                        scope.formHelper.getFieldByInternal("passwordRepeat").api.focus();
                        scope.formHelper.getFieldByInternal("passwordRepeat").api.setInvalid($filter("translate")("eob.add.user.general.error.title"), $filter("translate")("eob.add.user.password.missmatch.message"));
                        return
                    }

                    currentUser.name = scope.formHelper.getFieldByInternal("loginName").value;
                    currentUser.fullname = scope.formHelper.getFieldByInternal("userName").value;
                    currentUser.limited = scope.formHelper.getFieldByInternal("limited").value;
                    currentUser.locked = scope.formHelper.getFieldByInternal("locked").value;
                    currentUser.description = scope.formHelper.getFieldByInternal("description").value;
                    currentUser.email = scope.formHelper.getFieldByInternal("email").value;

                    if ($stateParams.mode == "create" || scope.formHelper.getFieldByInternal("password").value != "") {
                        currentUser.password = scope.formHelper.getFieldByInternal("password").value;
                    }

                    await BackendService.post("/organization/user/updateCreate", currentUser).catch(error => NotificationsService.backendError(error, "eob.user.save.error"));

                    await OrganisationService.refreshUserList().toPromise().catch(error => NotificationsService.backendError(error));

                    setTimeout(() => { history.back() }, 0)
                }

                scope.$on("$destroy", () => {
                    StateHistoryManager.updateConfig({
                        formData: reduceFormData(),
                        groups: currentUser.groups
                    }, stateId);
                    if (scope.formHelper != void 0) {
                        scope.formHelper.destroy();
                    }

                    if (scope.saveUserSubscripton) {
                        scope.saveUserSubscripton.unsubscribe();
                    }
                });

                /**
                 * Reduce the form data to field name - value pairs.
                 *
                 * @returns {object} A map of field name - value pairs.
                 */
                function reduceFormData() {
                    let values = {};
                    for (let fieldName in scope.formData) {
                        let field = scope.formData[fieldName];
                        values[fieldName] = field.value;
                    }
                    return values;
                }
            }
        }
    }
})();
