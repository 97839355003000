import {Inject, Injectable} from "@angular/core";
import {DashletService} from "MODULES_PATH/dashlet/interfaces/dashlet-service.interface";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {DashletUserInfo} from "MODULES_PATH/dashlet/interfaces/user-info.interface";
import {DashletSessionInfo} from "MODULES_PATH/dashlet/interfaces/session-info.interface";
import {TodoCacheManagerService, TodoEnvironmentService, TodoScriptHelperModelService} from "INTERFACES_PATH/any.types";
import {ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {DashletMessage} from "MODULES_PATH/dashlet/interfaces/dashlet-api.interface";
import {CustomDashletInitEnvironmentProperties} from "MODULES_PATH/dashlet/interfaces/custom-dashlet-init-properties.interface";
import {StateParams, StateService} from "@uirouter/core";
import {CustomDashletEvent} from "MODULES_PATH/dashlet/enums/custom-dashlet-event.enum";
import {OsDashletInit} from "MODULES_PATH/dashlet/interfaces/os-dashlet-init.interface";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";
import {DashletApiService} from "MODULES_PATH/dashlet/services/dashlet-api.service";

@Injectable()
export class CustomDashletService implements DashletService {
    private readonly scriptHelper: TodoScriptHelperModelService;

    // eslint-disable-next-line max-params
    constructor(private messageService: MessageService,
                @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                @Inject("scriptHelperModelService") private scriptHelperModelService: TodoScriptHelperModelService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("$stateParams") private $stateParams: StateParams,
                private $state: StateService,
                private profileService: ProfileService, private clientService: ClientService, private dashletApiService: DashletApiService) {
        this.scriptHelper = this.scriptHelperModelService.getScriptHelper();

        // only trigger dashletFns in the main window
        if (!this.clientService.isDetached()) {
            this.messageService.subscribe(ViewerEvent.EXEC_DASHLET_API, ({dashletId, data}) => void this.dashletApiService.handleDashletMessage(dashletId, data));
        }
    }

    update(osid: number, dmsDocument: DmsDocument, selectedOsids: number[]): void {
        this.messageService.broadcast(CustomDashletEvent.UPDATE_CUSTOM_DASHLET, {osid});
    }

    refresh(osid: number, keepCache: boolean): void {
    }

    clear(): void {
    }

    sendEvent(eventKey: string, data?: DashletMessage): void {
        this.messageService.broadcast(eventKey, data);
    }

    getDashletInitProperties(): CustomDashletInitEnvironmentProperties {
        return {
            userInfo: this.getUserInfo(),
            sessionInfo: this.getSessionInfo()
        };
    }

    getUserInfo(): DashletUserInfo {
        return this.scriptHelper.getInfo().user;
    }

    getSessionInfo(): DashletSessionInfo {
        const languageObjectDefinition: string = this.environmentService.getObjectDefinitionLanguage();
        const sessionInfo: { language: string; sessionGuid: string } = this.scriptHelper.getInfo().session;
        const clientType: string = this.clientService.getClientType();
        const baseUrl: string = this.profileService.getCurrentBaseUrl() ? this.profileService.getCurrentBaseUrl() : (window.location as any).baseUrl;

        return {...sessionInfo, languageObjectDefinition, clientType, baseUrl};
    }

    getOsDashletInit(id: string): OsDashletInit {
        return {
            objecttype: this.cacheManagerService?.dmsDocuments?.getById(id)?.model?.objectTypeId,
            objectident: this.cacheManagerService?.dmsDocuments?.getById(id)?.model?.osid,
            userid: this.scriptHelper.getInfo()?.user?.userId?.toString(),
            userguid: this.scriptHelper.getInfo()?.user?.osGuid,
            sessionguid: this.scriptHelper.getInfo()?.session?.sessionGuid,
            pagecount: this.cacheManagerService?.dmsDocuments?.getById(id)?.model?.fileProperties?.fileCount?.toString() || "0",
            searchterm: this.cacheManagerService?.dmsDocuments?.getById(id)?.model?.vtx?.[0].model?.searchKey || "",
            regenerate: "0"
        };
    }

    getOsDashletUpdate(id: string, regenerate: string = "0"): OsDashletInit {
        return {...this.getOsDashletInit(id), regenerate};
    }

    getStateContext(): string {
        let context: string = this.$state.current.name;

        if (context === "hitlist.inbox") {
            context = `${context}.${this.$stateParams.type}`;
        }

        return context;
    }
}