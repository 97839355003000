import {Component, ElementRef, OnInit, OnDestroy, Inject, Input} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {StateService} from "@uirouter/core";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {OfflineSynchronizationMessage} from "ENUMS_PATH/offline/offline-synchronization-message.enum";
import {OverallSyncStatus} from "ENUMS_PATH/offline/offline-global-sync-status.enum";
import {Subscription} from "rxjs";
import {OfflineService} from "SERVICES_PATH/offline/eob.offline.srv";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {Connection} from "ENUMS_PATH/connection.enum";
import {TodoAsIniService} from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-state-sync-button",
    templateUrl: "./eob-state-sync-button.component.html",
    styleUrls: ["./eob-state-sync-button.component.scss"]
})

export class EobStateSyncButtonComponent implements OnInit, OnDestroy {

    constructor(protected clientService: ClientService,
                @Inject("$state") private $state: StateService,
                @Inject("asIniService") private asIniService: TodoAsIniService,
                @Inject("offlineService") public offlineService: OfflineService,
                private notificationsService: NotificationsService,
                @Inject("$filter") private $filter: any,
                @Inject("messageService") private messageService: MessageService,
                public el: ElementRef) {
    }

    @Input() hitlistconfig: any;

    isSynchronizing: boolean;
    offlineSyncSubscription: Subscription = new Subscription();

    ngOnInit(): void {
        this.isSynchronizing = this.offlineService.isSyncRunning();
        this.el.nativeElement.className = "state-header-button";
        this.clientService.registerConnectivityChangeHandler(this.onConnectivityChange);
        this.offlineSyncSubscription = this.messageService.subscribe(OfflineSynchronizationMessage.GLOBAL_SYNC_STATUS_CHANGED, this.updateResultCallback);
        this.onConnectivityChange();
    }

    // Hides the button when client is offline
    onConnectivityChange = (): void => {
        this.el.nativeElement.style.display = this.clientService.isOnline() ? "flex" : "none";
    };

    // sets the scope variable for displaying the stop or start sync button
    updateResultCallback = (data: any): void => {
        this.isSynchronizing = ![OverallSyncStatus.FINISHED, OverallSyncStatus.FAILED, OverallSyncStatus.ABORTED].includes(data.status);
    };

    // starts synchronization process and displays stop button
    restartSync = async (): Promise<any> => {
        if (await this.clientService.getIsSynchronizing()) {
            this.notificationsService.info(this.$filter("translate")("eob.info.sync.already.running"));
            return;
        }

        // automatic sync is not set --> do nothing
        if (!this.asIniService.isSynchronizeFavoritesOffline()) {
            return;
        }

        // the user is using mobile data and disabled the sync with
        if (!this.asIniService.isUseMobileDataForSync() && this.clientService.getConnectionType() !== Connection.WIFI) {
            this.notificationsService.info(this.$filter("translate")("eob.info.sync.via.mobile.data.disabled"));
            return;
        }

        if (this.$state.current.name == "hitlist.failedSyncObjects") {
            this.hitlistconfig.api.getGridOptions().api.setRowData([]);
        }

        this.isSynchronizing = true;

        await this.offlineService.synchronizeAsync();
    };

    abortSync = (): void => {
        this.offlineService.stopSynchronization();
    };

    ngOnDestroy(): void {
        this.clientService.unregisterConnectivityChangeHandler(this.onConnectivityChange);
        if (this.offlineSyncSubscription) {
            this.offlineSyncSubscription.unsubscribe();
        }
    }
}
