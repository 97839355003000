import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Type} from "@angular/core";
import {DocumentViewerComponent} from "MODULES_PATH/document-viewer/eob-document-viewer.component";
import {SharedModule} from "SHARED_PATH/shared.module";

const components: Array<Type<unknown>> = [
    DocumentViewerComponent
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule, SharedModule
    ],
    exports: [...components],
    entryComponents: [...components]
})
export class DocumentViewerModule {
}
