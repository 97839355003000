<div [attr.inert] = "!navigationService.isNavVisible ? '' : null" class="eob-nav-content" *ngIf="nav$ | async as navigation">
    <eob-nav-objectsearch [attr.alt]="'nav.pane.title.objectsearch' | ngTranslate" [hidden]="navigationService.activeTab !== 'objectsearch'">
    </eob-nav-objectsearch>
    <eob-nav-desktop [attr.alt]="'nav.pane.title.desktop' | ngTranslate"  [hidden]="navigationService.activeTab !== 'desktop'"></eob-nav-desktop>
    <eob-nav-quicksearch [attr.alt]="'nav.pane.title.quicksearch' | ngTranslate"
                         [hidden]="navigationService.activeTab !== 'quicksearch'"></eob-nav-quicksearch>
    <eob-nav-inbox [attr.alt]="'nav.pane.title.inbox' | ngTranslate" [hidden]="navigationService.activeTab !== 'inbox'"></eob-nav-inbox>
    <eob-nav-external-tray-elements [attr.alt]="'nav.pane.title.external.tray' | ngTranslate"
                           [hidden]="navigationService.activeTab !== 'externaltray'"></eob-nav-external-tray-elements>
    <eob-nav-kebab class="nav-content-kebab" [hidden]="navigationService.activeTab !== 'kebab'"></eob-nav-kebab>
</div>
