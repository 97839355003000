import {Inject, Injectable} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {FieldModel, MockField} from "SHARED_PATH/models/field.model";
import {FieldConfig, RawField} from "INTERFACES_PATH/field.interface";
import {ObjectTypeConfig} from "INTERFACES_PATH/object-type.interface";
import {FieldAddon, FieldControlType, FieldDataType} from "ENUMS_PATH/field.enum";
import {RawCatalogType} from "ENUMS_PATH/catalog.enum";
import {StateParams} from "@uirouter/core";
import {FormCatalogParserService} from "MODULES_PATH/form/services/form-catalog-parser.service";
import {FormAddonConfigParserService} from "./form-addon-config-parser.service";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

@Injectable({
    providedIn: "root"
})
export class FormFieldModelService {

    private readonly translateFn: TranslateFnType;
    private readonly stateParams: StateParams;

    // eslint-disable-next-line max-params
    constructor(@Inject("$stateParams") private $stateParams: StateParams,
                @Inject("$filter") private $filter: ng.IFilterService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                private formAddonConfigParserService: FormAddonConfigParserService,
                private valueUtilsService: ValueUtilsService,
                private iconService: IconService,
                private formCatalogParserService: FormCatalogParserService) {
        this.translateFn = $filter("translate");
        this.stateParams = $stateParams;
    }

    /**
     * Create a dummy field for dialogs etc.
     * A Mockfield resembels a FieldModel.
     *
     * @param type - The field type.
     * @param internal - A string identifier for the field.
     * @param title - The display name for the field.
     * @param addon - An optional addon type.
     * @param isTextArea - Checking if this is a textarea field.
     * @returns A field model.
     */
    getMockedField(type: string, internal?: string, title?: string, addon?: string, isTextArea?: boolean): FieldModel {
        const data: FieldConfig<MockField> = { model: new MockField({ type, internal, title, addon, isTextArea }, this.formCatalogParserService) };
        this.executeFurtherActions(data);
        return data.model;
    }

    getFieldModel(config: FieldConfig<FieldModel>): FieldModel {
        this.modifyFieldConfig(config);
        return new FieldModel(config.model, this.environmentService.featureSet);
    }

    private modifyFieldConfig(config: FieldConfig<FieldModel>): void {
        config.model = new FieldModel({});

        if (config.isWorkflow) {
            config.field = this.normalizeField(config.field);
        }

        // sets the fields properties and determines the type of each field
        this.setFieldProperties(config);
        // execute further actions for each type
        this.executeFurtherActions(config);
    }

    private executeFurtherActions(config: FieldConfig<FieldModel>): void {
        const model: FieldModel = config.model;
        const field: any = config.field;
        const parameter: any = config.parameter;
        const isWorkflow: boolean = config.isWorkflow;

        switch (model.type) {
            case FieldControlType.UNKNOWN:
                break;
            case FieldControlType.RADIO:
                if (field?.groupInternal) {
                    model.groupInternal = field.groupInternal;
                }

                if (field?.isMasterRadio) {
                    model.isMasterRadio = true;
                }

                if (field?.masterRadioInternal) {
                    model.masterRadioInternal = field.masterRadioInternal;
                }

                break;
            case FieldControlType.BUTTON:
                model.iconId = field.names == void 0 ? "" : this.environmentService.getLocalizedFieldInfo(field.names.name, field, "icon");
                model.iconClass = model.iconId != "" ? `custom-icon-${model.iconId}` : null;

                if (model.iconId != "") {
                    void this.iconService.processIcons([model.iconId]);
                }

                break;
            case FieldControlType.GROUP:
                if (field) {
                    model.fields = field.fields;
                }

                break;
            case FieldControlType.CHECKBOX:
                if (!field || isWorkflow) {
                    return;
                }

                // the checkbox is way too small, and the label will be cut off .... *wuahahaha*
                if (field.input_pos != void 0 && field.input_pos.right < 16) {
                    model.isInvisibleText = true;
                }

                this.getCheckBoxValues(model, field);
                break;
            case FieldDataType.CHOICE:
                if (!field) {
                    return;
                }

                model.subType = field.flags.dt;
                break;
            case FieldDataType.TIME:
                if (this.stateParams.mode !== "view") {
                    model.placeholder = "HH:MM:SS";
                    model.dateFormat = "H:i:s";
                }

                model.addon = this.identifyIfListAddonIsAvailable(field, FieldAddon.DATETIME);
                break;
            case FieldDataType.DATETIME:
                this.addDateFormatAndPlaceholder(model);
                model.addon = this.identifyIfListAddonIsAvailable(field, FieldAddon.DATETIME);
                break;
            case FieldDataType.DATE:
                this.addDateFormatAndPlaceholder(model);
                model.addon = this.identifyIfListAddonIsAvailable(field, FieldAddon.DATE);
                break;
            case FieldControlType.GRID:
                this.parseGrid(model, field, parameter, isWorkflow, config.objectTypeConfig);
                break;
            case FieldControlType.PAGE_CONTROL:
                if (!field) {
                    return;
                }

                model.activePageIndex = 0;
                model.pages = [];

                if (!Array.isArray(field.page)) {
                    field.page = [field.page];
                }

                for (const fieldPage of field.page) {

                    const pageTitle: string = isWorkflow ? fieldPage.name : this.environmentService.getLocalizedFieldInfo(fieldPage.names.name, field);
                    const pageId: string = fieldPage.page_id;
                    const iconClass: string = fieldPage.IconID == "0" ? null : (`custom-icon-${fieldPage.IconID}`);

                    if (fieldPage.IconID && fieldPage.IconID != "0") {
                        void this.iconService.processIcons([fieldPage.IconID]);
                    }

                    const page: any = {
                        title: pageTitle,
                        pageId,
                        iconClass,
                        iconId: fieldPage.IconID
                    };

                    if (fieldPage.internal != void 0) {
                        page.internal = fieldPage.internal;
                    }

                    const pageFields: any = fieldPage.fields.field;

                    if (typeof (pageFields) == "undefined") {
                        console.warn("PageControl without fields found ... what am I supposed to do !?");
                    } else {
                        page.fields = pageFields;
                    }

                    model.pages.push(page);
                }
                break;
        }

        switch (model.addon) {
            case FieldAddon.USER:
            case FieldAddon.ORGANIZATION:
            case FieldAddon.QUICKFINDER:
            case FieldAddon.RIGHTGROUPS:
            case FieldAddon.RIGHTGROUPSOLD:
            case FieldAddon.DATETIME:
            case FieldAddon.DB:
                if (!field) {
                    return;
                }

                model.config = this.formAddonConfigParserService.getConfig(field, model.addon, isWorkflow);
                break;
            case FieldAddon.HIERARCHY:
                if (field.list?.ADDON32 && field.list.ADDON32.toLowerCase() == "axaddxmltree.dll") {
                    model.iconClass = "addon-xml-tree";
                }

                if (!field) {
                    return;
                }

                this.formCatalogParserService.addCatalog(model, field, parameter, isWorkflow);
                break;
            case FieldAddon.TREE:
                if (field.list?.ADDON32 && field.list.ADDON32.toLowerCase() == "axaddxmltree.dll") {
                    model.iconClass = "addon-xml-tree";
                }

                if (!field) {
                    return;
                }

                this.formCatalogParserService.addCatalog(model, field, parameter, isWorkflow);
                break;
            case FieldAddon.LIST:
                if (!field) {
                    return;
                }

                if (field.list?.verweis) {
                    // console.error("Could not solve list references for '" + field.list.verweis + "'. More information also available in the AppConnector log. Fallback to text field.");
                    model.addon = undefined;
                } else {
                    this.formCatalogParserService.addCatalog(model, field, parameter, isWorkflow);
                }
                break;
        }
    }

    private identifyIfListAddonIsAvailable(field: any, defaultAddon: FieldAddon) : FieldAddon {
        const fieldAddon: FieldAddon = field ? this.getFieldAddon(field) : undefined;
        return (fieldAddon) ? fieldAddon : defaultAddon;
    }

    private parseGrid(model: FieldModel, field: any, parameter: any, isWorkflow: boolean, objectTypeConfig: ObjectTypeConfig): void {
        model.columns = [];
        model.width = field.field_pos.right;

        let useColumnFallback: boolean = true;
        let gridIsReadOnly: boolean = true;
        let colMembers: any;
        let rawCols: any;

        if (isWorkflow && parameter && parameter.data) {
            colMembers = parameter.data.columns;
            useColumnFallback = false;
        }

        rawCols = field.listctrl;

        if (rawCols == void 0) {
            console.warn("Empty Grid found, this might lead to an error, please fix it!");

            if (!isWorkflow) {
                console.warn("Current type: ", objectTypeConfig.name, " In Cabinet: ", objectTypeConfig.cabinetId);
            }

            return;
        }

        if (!Array.isArray(rawCols)) {
            rawCols = [rawCols];
        }

        for (const i in rawCols) {
            const rawCol: any = rawCols[i];
            let title: string;
            let idTitle: string;

            if (isWorkflow) {
                title = rawCol.name;
            } else {
                if (!Array.isArray(rawCol.names.name)) {
                    rawCol.names.name = [rawCol.names.name];
                }
                title = this.environmentService.getLocalizedFieldInfo(rawCol.names.name, rawCol);
                idTitle = rawCol.name;
            }

            if (!isWorkflow && (rawCol.internal == void 0 || rawCol.internal == "")) {
                console.error("Internal name of column missing!", " Current type: ", objectTypeConfig.name, " --> ", objectTypeConfig.objectTypeId, " In Cabinet: ", objectTypeConfig.cabinetId, " Field: ", field.name);
                model.isCorrupted = true;
            }

            const colGuid: string = rawCol.os_guid || `${(model.guid || model.internal)}${rawCol.name}`;
            const col: any = {
                maxLength: rawCol.length,
                readonly: true,
                internal: rawCol.internal,
                title,
                idTitle: idTitle || title,
                guid: colGuid,
                width: Number(rawCol.colwidth) * 2,
                type: ""
            };

            if (rawCol.readonly == "0") {
                col.readonly = false;
                gridIsReadOnly = false;
            }

            // there is no parameter setfor these columns, so we only use the
            // columns that are defined inside the mask definition
            if (useColumnFallback) {
                switch (rawCol.type) {
                    case "X":
                        col.type = "text";
                        break;
                    case "#":
                        col.type = "decimal";
                        break;
                    case "9":
                        col.type = "number";
                        break;
                    case "D":
                        col.type = "date";
                        this.addDateFormatAndPlaceholder(col);
                        col.iconClass = "addon-date";
                        break;
                }
            } else if (i < colMembers.length) {
                if (colMembers[i].STRING) {
                    col.type = "text";
                } else if (colMembers[i].DATESAFE) {
                    col.type = "date";
                    col.iconClass = "addon-date";
                    this.addDateFormatAndPlaceholder(col);
                } else if (colMembers[i].DATETIMESAFE) {
                    col.type = "datetime";
                    col.iconClass = "addon-date";
                    this.addDateFormatAndPlaceholder(col);
                } else if (colMembers[i].TIMESAFE) {
                    col.placeholder = "HH:MM:SS";
                    col.iconClass = "addon-time";
                    col.type = "time";
                } else if (colMembers[i].INTEGERSAFE) {
                    col.type = "number";
                } else if (colMembers[i].FLOATSAFE) {
                    col.type = "decimal";
                }
            }

            switch (rawCol.catalogtype) {
                case RawCatalogType.LIST: // list control
                    col.addon = FieldAddon.LIST;
                    this.formCatalogParserService.addCatalog(col, rawCol, parameter, isWorkflow);
                    break;
                case RawCatalogType.DB: // db control
                    col.addon = FieldAddon.DB;
                    break;
                case RawCatalogType.TREE: // tree control
                    col.addon = FieldAddon.TREE;
                    this.formCatalogParserService.addCatalog(col, rawCol, parameter, isWorkflow);
                    break;
                case RawCatalogType.HIERARCHY: // hierarchy control
                    col.addon = FieldAddon.HIERARCHY;
                    this.formCatalogParserService.addCatalog(col, rawCol, parameter, isWorkflow);
                    break;
                default:
                    if (col.type == "time" || col.type == "date" || col.type == "datetime") {
                        col.addon = "dateTimeAddon";
                    }
            }

            model.columns.push(col);
        }

        if (gridIsReadOnly) {
            model.readonly = "always";
        }
    }

    private setFieldProperties(config: FieldConfig<FieldModel>): void {
        const model: FieldModel = config.model;
        const field: any = config.field;
        const pageIndex: string = config.pageIndex;
        const parentPage: string = config.parentPage;
        const pageControl: FieldModel = config.pageControl;
        const parameter: any = config.parameter;
        const isWorkflow: boolean = config.isWorkflow;
        const objectTypeConfig: ObjectTypeConfig = config.objectTypeConfig;

        model.type = isWorkflow ? this.getWfFieldType(field, parameter) : this.getObjdefFieldType(field);
        model.addon = this.getFieldAddon(field);

        // a single & isn't displayed, as it is handled like a hotkey mapping in WinAPI. && shows an escaped &.
        model.name = field.name.replace(/&&?/g, match => match.length == 2 ? "&" : "");
        model.internal = field.internal;

        if (!isWorkflow && (field.internal === void 0 || field.internal === "")) {
            console.error("Internal name of field missing!", " Current type: ", objectTypeConfig.name, " --> ", objectTypeConfig.objectTypeId, " In Cabinet: ", objectTypeConfig.cabinetId, " Field: ", field.name);
            model.isCorrupted = true;
        }

        if (objectTypeConfig?.isEmsType) {
            const isEmsDigestField: boolean = objectTypeConfig.emsDeduplicationContext.internalName === model.internal;
            if (objectTypeConfig.emsMapping[model.internal] !== void 0 || isEmsDigestField) {
                model.isEmsField = true;
                model.isEmsDigestField = isEmsDigestField;
                model.emsFieldName = objectTypeConfig.emsMapping[model.internal] || null;
            }
        }

        model.dbname = field.fieldname;
        model.isInvisibleText = false;
        model.isInvisibleField = false;
        model.hasLeadingZeros = false;
        model.hasRegEx = false;
        model.isTextArea = false;
        model.isPassword = false;
        model.isRequired = false;
        model.isUnique = false;
        model.readonly = field.flags.readonly;
        model.maxLength = (field.flags.input_length == void 0 || field.flags.input_length == "0") ? 0 : field.flags.input_length;
        model.tabIndex = Number(field.taborder);
        model.isRequired = false;
        model.isBaseParam = false;
        model.isUnicode = false;
        model.labelIcon = null;
        model.guid = field.os_guid;
        model.textAlign = field.flags2 & 16777216 ? "right" : "left";

        if (field.isBaseParam != void 0) {
            model.isBaseParam = field.isBaseParam;
            model.icon = field.icon;
            model.baseParamName = field.baseParamName;
        }

        if (field.flags.crosscheck) {
            model.crossCheck = field.flags.crosscheck;

            model.relatedControl = field.flags.relatedControl ?? this.valueUtilsService.getCrosscheckRelatedControl(field.flags.flags1);
        }
        // pre-filled with constant
        // check whether it is not pre-filled by function
        // to ensure it is not a datefield plus timespan

        // prefilled by either constant or function
        if (field.flags.flags1 & 65536 || field.flags.flags1 & 131072 && field.init != "") {
            model.init = {};

            if (field.init[1].init_type == "const") {
                model.init.type = "constant";

                if (model.type == "checkbox") {

                    // special condition:
                    //
                    // case checkbox is set to no
                    // checkboxes are prefilled with
                    // "0:Yes:No"
                    // only using the first char in this case to ensure we only get a tiny-integer value

                    model.init.value = field.init[0].charAt(0);
                } else {
                    model.init.value = field.init[0];
                }

                // this is a field prefilled by a function with a constant
                // current date + timespan
                if (field.flags.flags1 & 131072) {
                    if (!isNaN(model.init.value)) {
                        model.init.value *= 86400000;
                        model.init.type = "function";
                        model.init.function = "currentDatePlusTimespan";
                    }
                }
            } else if (field.init[1].init_type == "func") {
                switch (field.init[1].func) {
                    case "0":
                    case "1": // currentDateGermany
                        model.init.type = "function";
                        model.init.function = "currentDate";
                        break;
                    case "2": // currentUserName
                        model.init.type = "function";
                        model.init.function = "currentUser";
                        break;
                    case "4": // currentDateTime
                        model.init.type = "function";
                        model.init.function = "currentDatetime";
                        break;
                    case "5": // currentTime
                        model.init.type = "function";
                        model.init.function = "currentTime";
                        break;
                    case "6": // currentUserID
                        model.init.type = "function";
                        model.init.function = "currentId";
                        break;
                    case "7": // currentUserFullName
                        model.init.type = "function";
                        model.init.function = "currentUserFullname";
                        break;
                    case "8": // currentUserData
                        model.init.type = "function";
                        model.init.function = "currentUserData";
                        break;
                    case "9": // isUnicode
                        model.isUnicode = true;
                }
            }
        }

        if (isWorkflow) {
            model.title = field.name.replace(/&&?/g, match => match.length == 2 ? "&" : "");
        } else {
            if (!Array.isArray(field.names.name)) {
                field.names.name = [field.names.name];
            }

            if (this.environmentService.getLocalizedFieldInfo(field.names.name, field)) {
                model.title = this.environmentService.getLocalizedFieldInfo(field.names.name, field).replace(/&&?/g, match => match.length == 2 ? "&" : "");
            }
        }

        if (field.flags.flags & 1) {
            model.isRequired = true;
        }

        if (pageIndex != void 0) {
            model.pageIndex = pageIndex;
        }

        if (parentPage != void 0) {
            model.parentPage = parentPage;
        }

        if (pageControl != void 0) {
            model.pageControl = pageControl;
        }

        if (field.flags.flags2 & 268435456) {
            model.readonly = "always";
        } else if (field.flags.flags2 & 67108864 && !(field.flags.flags2 & 134217728)) {
            model.readonly = "arch";
        } else if (field.flags.flags2 & 134217728 && !(field.flags.flags2 & 67108864)) {
            model.readonly = "init";
        } else if (field.flags.flags2 & 134217728 && field.flags.flags2 & 67108864) {
            model.readonly = "init & arch";
        } else if (field.flags.flags & 8) {
            model.readonly = "supervisor";
        }

        // incoming param only, therefore readonly
        if (parameter != void 0 && parameter.model.mode == "1") {
            model.readonly = "always";
        }

        if (field.flags.flags & 32 && model.type != FieldControlType.RADIO && model.type != FieldControlType.CHECKBOX) {
            model.isInvisibleText = true;
        }

        if (model.type == FieldControlType.GROUP && field.flags.flags2 & 16777216) {
            model.isInvisibleText = true;
        }

        if (model.type == FieldControlType.RADIO) {
            if (field.groupLabel) {
                model.title = field.groupLabel;
                model.groupName = field.groupLabel;
            }

            if (field.fields != void 0) {
                model.fields = field.fields;
            }

            for (const radio of model.fields) {
                // set an init value on the master radiobutton, so that it isn't ignored later
                if (radio.init != "") {
                    model.init = {
                        type: "constant"
                    };
                }

                if (radio.names == void 0) {
                    continue;
                }

                if (isWorkflow) {
                    radio.title = radio.name;
                } else {
                    radio.title = this.environmentService.getLocalizedFieldInfo(radio.names.name, radio);
                }
            }
        }

        if (field.flags.flags1 & 8388608) {
            model.isInvisibleField = true;
        }

        if (field.flags.flags & 64) {
            model.hasLeadingZeros = true;
        }

        if (field.flags.flags & 524288) {
            model.isTextArea = true;
        }

        if (field.flags.flags & 4) {
            model.isUnique = true;
        }

        if (field.regularexpression) {
            model.hasRegEx = true;
            model.regEx = field.regularexpression;
        }

        if (field.names != void 0) {
            const tooltip: string = this.environmentService.getLocalizedFieldInfo(field.names.name, field, "tooltip");

            if (tooltip != void 0) {
                const tooltips: string[] = tooltip.split("|");
                model.tooltip = tooltips[0] == "" ? model.title : tooltips[0];
                model.addonTooltip = tooltips[1];
            } else {
                model.tooltip = model.title;
            }
        } else if (isWorkflow) {
            model.tooltip = field.tooltip == void 0 ? "" : field.tooltip.replace(/\|/g, "");
            model.tooltip = model.tooltip !== "" ? model.tooltip : model.title;
        }
    }

    private addDateFormatAndPlaceholder(model: FieldModel): void {
        // the date formats in the EnvironmentService aren't available, yet
        model.dateFormat = model.type == "date" ? this.translateFn("eob.dateFormat.date") : this.translateFn("eob.dateFormat.datetime");
        model.placeholder = this.valueUtilsService.getDatePlaceholder(model.type);
    }

    private getCheckBoxValues(model: FieldModel, field: any): void {
        if (field.prnalias == void 0 || field.prnalias === "" || field.prnalias.length === 2) {
            /**
             * no values configured
             * set default (yes / no) but per locale
             *
             * second condition is meant for
             * pre-filling by constant.
             * we get "1:" or "0:" which means the checkbox is prefilled as true or false
             * without any custom alias
             */
            model.trueVal = this.translateFn("eob.objecttype.checkbox.trueval");
            model.falseVal = this.translateFn("eob.objecttype.checkbox.falseval");
        } else {
            /**
             * split the prnalias
             * we get a format that looks like this "0:Yes|No"
             *
             * pretty strange --> splice the first 2 chars and split at |
             * (yes the first two chars look always like this ("0:" or "1:"))
             * --> the number (0 or 1) specifies whether the checkbox is preselected or not
             */
            const tmpString: string = field.prnalias.substr(2, field.prnalias.length);

            model.trueVal = tmpString.split("|")[0];
            model.falseVal = tmpString.split("|")[1];
        }
    }

    private getWfFieldType(field: any, parameter: any): FieldControlType | FieldDataType {
        switch (field.flags.datatype) {
            case "1":
                return FieldControlType.RADIO;
            case "0":
                return FieldControlType.CHECKBOX;
            case "C":
                return FieldControlType.PAGE_CONTROL;
            case "W":
                return FieldControlType.GRID;
            case "G":
                return FieldControlType.GROUP;
            case "K":
                return FieldControlType.BUTTON;
        }

        if (field.flags.flags & 16 && (field.flags.datatype == "X" || field.flags.datatype == "")) {
            return FieldControlType.STATIC;
        }

        if (field.list != void 0 && field.list.rawdata != void 0) {
            const index: number = field.list.rawdata.indexOf("ADDON32=AxAddWfOrg.dll");
            if (index != -1) {
                return FieldDataType.TEXT;
            }
        }

        if (field.flags.datatype == "X") {
            if (parameter) {
                if (parameter.model.type == FieldControlType.GRID) {
                    return FieldDataType.TEXT;
                }

                return parameter.model.type;
            } else if (field.flags.flags && "2097152" && field.list && field.list.rawdata.indexOf("axaddate.dll") != -1) {
                return FieldDataType.DATE;
            } else {
                return FieldDataType.TEXT;
            }
        } else {
            console.warn("unknown field", field);
        }
    }

    private getFieldAddon(field: any): FieldAddon {
        if (field.flags.flags & 2) {
            return FieldAddon.DB;
        } else if (field.flags.flags & 128) {
            return FieldAddon.LIST;
        } else if (field.flags.flags & 256) {
            return FieldAddon.TREE;
        } else if (field.flags.flags & 512) {
            return FieldAddon.HIERARCHY;
        } else if (field.list?.ADDON32) {
            switch (field.list.ADDON32.toLowerCase()) {
                case "axaddate.dll":
                    return FieldAddon.DATE;
                case "axaddaddress.dll":
                    return FieldAddon.ADDRESS;
                case "axaddusr.dll":
                    return FieldAddon.USER;
                case "axaddwforg.dll":
                    return FieldAddon.ORGANIZATION;
                case "axaddusrgrp.dll":
                    return field.flags.dt == "R" ? FieldAddon.RIGHTGROUPS : FieldAddon.RIGHTGROUPSOLD;
                case "axaddreq.dll":
                    return FieldAddon.QUICKFINDER;
            }
        } else if (field.flags.flags & 2097152 && field.list) {
            return this.getWfFieldAddon(field);
        }
    }

    private getWfFieldAddon(field: any): FieldAddon {
        let addon: string = field.list.rawdata.split("\n")[1];
        addon = addon.toLowerCase();
        if (addon.includes("axaddwforg.dll")) {
            return FieldAddon.ORGANIZATION;
        } else if (addon.includes("axaddusr.dll")) {
            return FieldAddon.USER;
        } else if (addon.includes("axaddusrgrp.dll")) {
            return FieldAddon.RIGHTGROUPS;
        } else if (addon.includes("axaddreq.dll")) {
            return FieldAddon.QUICKFINDER;
        } else if (addon.includes("axaddate.dll")) {
            return FieldAddon.DATE;
        }
    }

    private getObjdefFieldType(field: any): FieldControlType | FieldDataType {
        switch (field.flags.control_type) {
            case FieldControlType.RADIO:
                return FieldControlType.RADIO;
            case FieldControlType.CHECKBOX:
                return FieldControlType.CHECKBOX;
            case FieldControlType.PAGE_CONTROL:
                return FieldControlType.PAGE_CONTROL;
            case FieldControlType.GRID:
                return FieldControlType.GRID;
        }

        // below this comment you will find fields without a specific control_type
        // but with datatypes and dt values (which is kinda the same)
        switch (field.flags.datatype) {
            case "num":
                if (field.flags.flags1 & 4194304) {
                    return FieldDataType.DATETIME;
                } else if (field.flags.flags & 262144) {
                    return FieldDataType.TIME;
                } else {
                    return FieldDataType.NUMBER;
                }
            case "dec":
                return FieldDataType.DECIMAL;
            case "date":
                return FieldDataType.DATE;
            case "group":
                return FieldControlType.GROUP;
            case "text":
                if (field.flags.control_type == FieldControlType.STATIC) {
                    if (field.flags.dt == "K") {
                        return FieldControlType.BUTTON;
                    } else {
                        return FieldControlType.STATIC;
                    }
                }
        }

        switch (field.flags.dt) {
            case "T":
            case "P":
            case "S":
            case "Q":
                return FieldDataType.CHOICE;
            case "9":
                if (field.flags.flags1 & 4194304) {
                    return FieldDataType.DATETIME;
                } else if (field.flags.flags & 262144) {
                    return FieldDataType.TIME;
                }
                break;
            case "A":
                return FieldDataType.LETTER;
            case "K":
                return FieldControlType.BUTTON;
            case "Z":
                return FieldDataType.ALPHANUM;
            case "G":
                return FieldDataType.CAPITAL;
            case "D":
                return FieldDataType.DATE;
        }

        if (field.list?.ADDON32) {
            if (field.list.ADDON32.toLowerCase() == "axaddate.dll") {
                return FieldDataType.DATE;
            }
        }

        if (field.flags.dt == "X" || field.flags.dt == "R") {
            return FieldDataType.TEXT;
        } else {
            console.warn("unknown field", field);
        }
    }

    private normalizeField(field: RawField): RawField {
        const normalized: any = {};

        if (field.groupLabel) {
            normalized.groupLabel = field.groupLabel;
        }

        normalized.name = field.Name;
        normalized.internal = field.Id;
        normalized.taborder = field.TabOrder;
        normalized.tooltip = field.ToolTip;

        if (field.RegularExpression && typeof (field.RegularExpression) == "string") {
            normalized.regularexpression = field.RegularExpression;
        }

        if (field.MaskFieldVal) {
            normalized.list = {
                rawdata: field.MaskFieldVal
            };
        }

        if (field.DataType == "1") {
            normalized.isMasterRadio = field.isMasterRadio;
            normalized.groupInternal = field.groupInternal;
            normalized.groupLabel = field.groupLabel;
            normalized.isMasterRadio = field.isMasterRadio;
            normalized.masterRadioInternal = field.masterRadioInternal;
        }

        normalized.field_pos = {
            bottom: field.FieldBottom,
            left: field.FieldLeft,
            right: field.FieldRight,
            top: field.FieldTop
        };

        normalized.input_pos = {
            bottom: field.InpBottom,
            left: field.InpLeft,
            right: field.InpRight,
            top: field.InpTop
        };

        normalized.flags = {
            flags: field.Flags,
            flags1: field.Flags1,
            flags2: field.Flags2,
            crosscheck: field.Crosscheck,
            relatedControl: field.RelatedControl,
            input_length: field.InpLen,
            datatype: field.DataType
        };

        if (field.Page) {
            if (!Array.isArray(field.Page)) {
                field.Page = [field.Page];
            }

            normalized.page = [];
            for (const p of field.Page) {
                const page: any = {
                    name: p.Name,
                    page_id: p.Id,
                    IconID: p.IconId,
                    fields: {
                        field: p.MaskFields.MaskField
                    }
                };

                normalized.page.push(page);
            }
        }

        if (field.fields) {
            normalized.fields = field.fields;

            for (const index in normalized.fields) {
                if (typeof (normalized.fields[index]) != "object") {
                    continue;
                }

                normalized.fields[index].name = normalized.fields[index].Name;
                normalized.fields[index].title = normalized.fields[index].Name;
            }
        }

        if (field.MaskListCtrls && field.MaskListCtrls.MaskListCtrl) {
            normalized.listctrl = [];
            const tmpArray: any = !Array.isArray(field.MaskListCtrls.MaskListCtrl) ? [field.MaskListCtrls.MaskListCtrl] : field.MaskListCtrls.MaskListCtrl;

            for (const col of tmpArray) {
                const normalizedCol: any = {
                    length: col.Length,
                    name: col.Name,
                    readonly: col.ReadOnly,
                    type: col.Type,
                    colwidth: col.ColWidth
                };

                if (col.MaskListCtrlVal) {
                    normalizedCol.catalogtype = "1";
                    normalizedCol.list = {
                        rawdata: col.MaskListCtrlVal
                    };
                }

                normalized.listctrl.push(normalizedCol);
            }
        }

        return normalized;
    }
}
