import {environment} from "../../environments/environment";

angular.module("eob.core").factory("stateHistoryManager", StateHistoryManager);

StateHistoryManager.$inject = ["$stateParams", "$state", "$window", "$rootScope", "$injector", "$filter"];

/**
 * A service to navigate between states.
 */
function StateHistoryManager($stateParams, $state, $window, $rootScope, $injector, $filter) {

    let stateHistory = {},
        userId = null;

    return {
        init,
        setStateData,
        getStateData,
        updateStateData,
        getCurrentStateData,
        getCurrentConfig,
        getCurrentStateContext,
        updateConfig,
        getSelectedItems,
        save,

        goToState,
        goToPreviousState,
        goToDashboardState,
        goToShowIndexData,
        goToEditIndexData,
        goToIndexData,
        goToCreateNewState,
        goToCopyState,
        goToCreateVariantState,
        goToCreateFromTypeless,
        goToCreateReferenceState,
        goToCreateTypedInWfTray,
        goToManageVariants,
        goToWorkflowInfo,
        goToObjectReferencesState,
        getCurrentStateId
    };

    function init(user) {
        userId = user;
        let history = JSON.parse(localStorage.getItem(userId));
        if (history != void 0) {
            stateHistory = history.stateHistory != void 0 ? history.stateHistory : {};

            cleanHistory(stateHistory);

            let currentState = $stateParams.state;

            if (Object.keys(stateHistory).length === 0 || stateHistory[currentState] == void 0) {

                let currentHash = location.hash;

                setTimeout(() => {
                    let newState = $.now();
                    window.history.pushState({}, "dashboard", `#/dashboard?state=${newState}`);
                    window.history.pushState({}, "new", currentHash);
                }, 0);
            }
        }
    }

    function getCurrentStateId() {
        return $stateParams.state;
    }

    function getCurrentStateContext() {
        let context = {
            type: ""
        };

        // We must take care that the dropzone is every time destroyed leaving a state or a modal dialog including it.
        // See DODO-12215
        if ($rootScope.dropzone && $stateParams.mode !== "reference") {
            context.type = "createWithDropzone";
        } else if (getCurrentStateData().data.type == "folder") {
            context.type = "location";

            let currentId = window.location.href.split("currentId=").pop().split("&")[0];
            let CacheManagerService = $injector.get("cacheManagerService");

            context.location = CacheManagerService.dmsDocuments.getById(currentId);
        } else if (getCurrentStateData().data.type != "") {
            context.type = getCurrentStateData().data.type;
        }

        return context;
    }

    function getCurrentConfig() {
        let stateId = $stateParams.state;

        if (stateId == void 0) {
            return {};
        }
        // either we return an existing object (during init we should set a config as a default)
        // anyway if there is no config object we return an empty object just to be sure
        return getStateData(stateId).data.config;
    }

    function getCurrentStateData() {
        return getStateData($stateParams.state);
    }

    function updateConfig(changes, stateId) {

        if (!stateId) {
            stateId = $stateParams.state;
        }

        let state = getStateData(stateId);

        for (let key in changes) {
            (state.data.config || {})[key] = changes[key];
        }
    }

    function updateStateData(changes, stateId) {
        if (!stateId) {
            stateId = $stateParams.state;
        }

        let state = getStateData(stateId);

        for (let key in changes) {
            state.data[key] = changes[key];
        }
    }

    function setStateData(data, stateId) {
        stateHistory[stateId] = {
            data
        };
    }

    function getStateData(stateId) {
        // we got a state ID but there is no state right now
        // we create it and return the empty state
        if (stateId && typeof (stateHistory[stateId]) == "undefined") {
            let storedData = JSON.parse(localStorage.getItem(stateId));
            if (storedData != void 0) {
                setStateData(storedData, stateId);
                localStorage.removeItem(stateId);
            } else {
                setStateData({
                    type: $state.current.name,
                    config: {}
                }, stateId);
            }
        }

        if (stateId && stateHistory[stateId]) {
            return stateHistory[stateId];
        }

        // dummy to not explode while booting with data from other app
        return {
            data: {}
        };
    }

    function save() {
        if (userId != void 0) {
            let history = {
                stateHistory
            };

            localStorage.setItem(userId, JSON.stringify(history));
        }
    }

    function cleanHistory(history) {
        let leastTimeState = +new Date() - 28800000; // 8 hours

        for (let i in history) {
            if (i < leastTimeState) {
                delete history[i];
            }
        }
    }

    function goToPreviousState() {
        const previousState = stateHistory[getCurrentStateData().data.config.caller];
        // Lazy-loaded states don't yield a URL using $state.href(), so we'll use this goofy workaround for now
        if (previousState && previousState.data && previousState.data.name && previousState.data.params &&
            $state.href(previousState.data.name, previousState.data.params)) {
            goToState(previousState.data.name, previousState.data.params, previousState.data);
        } else if (history.length > 1) {
            history.back();
        } else {
            goToDashboardState();
        }
    }

    function goToState(state, params, stateData, inNewTab, forgetCurrentState) {
        const stateId = Date.now()

        params = params || {};
        params.state = stateId;

        let url = $state.href(state, params);

        if (stateData != void 0) {
            if (inNewTab) {
                // to restore the last active dashlet in a new tab
                const ViewerService = $injector.get("viewerService");
                stateData.config.activeDashlet = ViewerService.getLastActiveDashlet();

                let stringifiedData = JSON.stringify(stateData);
                // localstorage setitem is not asynchronous but we still need to wait at this point
                // because we are about to open the state in a new tab, which needs to read the localstorage from disk
                // setting the localstorage item might not be flushed to the disk at first and therefore it would not be ready in time
                // avoid this by waiting for a few milliseconds
                localStorage.setItem(stateId, stringifiedData);
                (function wait() {
                    if (localStorage.getItem(stateId) === stringifiedData) {
                        openState(url, inNewTab, forgetCurrentState);
                    } else {
                        setTimeout(wait, 30);
                    }
                })();
                return;
            } else {
                setStateData(stateData, stateId);
            }
        }

        openState(url, inNewTab, forgetCurrentState);
    }

    async function openState(url, inNewTab, forgetCurrentState) {
        if(environment.test) {
            return;
        }
        const ClientService = $injector.get("clientService")
        if (inNewTab && (!ClientService.isMobile() || !ClientService.isLocalClient())) {
            if (window.electron) {
                window.electron.openNewTab(location.href.split("#")[0] + url)
            } else {
                const result = window.open(url, "_blank");
                if (result === null) {
                    try {
                        await $injector.get("modalDialogService").infoDialog(
                            $filter("translate")("modal.confirm.blocked.popup.title"),
                            $filter("translate")("modal.confirm.blocked.popup.message"),
                            $filter("translate")("modal.button.cancel"),
                            $filter("translate")("modal.button.yes"));
                        window.open(url, "_blank");
                    } catch(error) { /* user cancel */ }
                }
            }
        } else {
            setTimeout(() => {
                // don't add the current state to the browser history
                if (forgetCurrentState) {
                    window.location.replace(location.href.split("#")[0] + url);
                } else {
                    window.location.href = location.href.split("#")[0] + url;
                }
            }, 0);
        }
    }

    function goToDashboardState() {
        goToState("dashboard");
    }

    function goToShowIndexData(dmsDocument, userAction) {
        goToIndexData(dmsDocument, "view", userAction);
    }

    function goToEditIndexData(dmsDocument, userAction) {
        goToIndexData(dmsDocument, "edit", userAction);
    }

    function goToIndexData(dmsDocument, mode, userAction, forgetCurrentState = false) {
        goToState("indexdata", {
            mode,
            osid: dmsDocument.model.osid,
            objectTypeId: dmsDocument.model.objectTypeId,
        }, { type: "indexdata", config: { userAction, caller: getCurrentStateId() } }, false, forgetCurrentState);
    }

    /**
     * Open the create state.
     *
     * @param {{ osid, objectTypeId }=} parent - A pair of ids for the parent.
     * @param {number} objectTypeId - The objectTypeId of the ot be created dms object.
     * @param {object=} stateParams - Call the create state with these state parameters.
     * @param {boolean=} inNewTab - Open the create state in a new tab.
     * @param {string=} userAction - A custom user action, that led to the creation state.
     */
    function goToCreateNewState(parent, objectTypeId, stateParams = {}, inNewTab, userAction) {
        let targetId;
        let parentTypeId;

        if (parent != void 0) {
            targetId = parent.objectId;
            parentTypeId = parent.objectTypeId;
        }

        stateParams = Object.assign({
            mode: "new",
            targetId,
            objectTypeId,
            parentTypeId,
            openParentAfterCreate: false
        }, stateParams);

        goToCreateState(stateParams, {
            targetTypeId: objectTypeId
        }, inNewTab, userAction);
    }

    function goToCopyState(targetId, targetTypeId, dmsDocument) {
        let stateParams = {
            mode: "copy",
            targetId,
            parentTypeId: targetTypeId,
            objectTypeId: dmsDocument.model.objectTypeId,
            id: dmsDocument.model.osid
        };

        goToCreateState(stateParams, { targetTypeId });
    }

    function goToCreateVariantState(dmsDocument, type, groupKey, userAction) {
        let stateParams = {
            mode: "variants",
            id: dmsDocument.model.id,
            objectTypeId: dmsDocument.model.objectTypeId,
            type,
            groupKey
        };

        goToCreateState(stateParams, undefined, false, userAction);
    }

    function goToCreateFromTypeless(container, dmsDocument, objectTypeId) {
        let stateParams = {
            mode: "typeless",
            targetId: container.model.id,
            targetType: container.model.objectTypeId,
            objectTypeId,
            id: dmsDocument.model.id
        };

        goToCreateState(stateParams);
    }

    function goToCreateReferenceState(container, dmsDocument, objectTypeId) {
        let stateParams = {
            mode: "reference",
            targetId: container.model.id,
            targetType: container.model.objectTypeId,
            objectTypeId,
            id: dmsDocument.model.id
        };

        goToCreateState(stateParams);
    }

    /**
     * Open the create state.
     * @param {object=} stateParams - Open the create state with these state parameters.
     * @param {object=} nextStateContent - Add data to the nextStateContent.
     * @param {boolean=} inNewTab - Open the create state in a new tab.
     * @param {string=} userAction - A custom user action, that led to the creation state. Default is 'contextmenu'.
     */
    function goToCreateState(stateParams, nextStateContent = {}, inNewTab, userAction = "contextmenu") {
        nextStateContent.type = "create";
        nextStateContent.config = {
            userAction,
            caller: getCurrentStateId()
        };

        goToState("create", stateParams, nextStateContent, inNewTab);
    }

    function goToManageVariants(dmsDocument, groupKey) {
        let nextStateContent = {
            config: {
                caller: getCurrentStateId(),
                initActiveVariantId: dmsDocument.model.osid
            },
            type: "variants"
        }, stateParams = {
            id: dmsDocument.model.osid,
            objectTypeId: dmsDocument.model.objectTypeId,
            groupKey
        };

        goToState("variants", stateParams, nextStateContent);
    }

    /**
     * Go to the workflow info state.
     * @param {Object} item - A workflow process inbox item.
     */
    function goToWorkflowInfo(item) {
        goToState("workflowInfo", {
            processId: item.processId,
            activityId: item.activityId
        }, {
            processName: item.processName,
            activityName: item.activityName,
            subject: item.subject,
            creationTime: item.creationTime,
            overTime: item.overTime,
            personalized: item.personalized,
            warningTime: item.warningTime
        });
    }

    function goToCreateTypedInWfTray(data) {
        let nextStateContent = {
                config: {
                    caller: getCurrentStateId()
                },
                type: "create"
            },
            stateParams = {
                mode: "inwftray",
                objectTypeId: data.objectType.objectTypeId,
                location: data.location
            };

        goToState("create", stateParams, nextStateContent);
    }

    async function goToObjectReferencesState(dmsDocument) {
        $state.go("hitlist.object-references", {
            osid: dmsDocument.model.osid,
            objectTypeId: dmsDocument.model.objectTypeId,
            state: Date.now()
        });
    }

    /**
     * Get selected items of currently visible list of dmsDocuments
     *
     * @return {{objectId, objectTypeId}[]} - selected items with objectId and objectTypeId
     */
    function getSelectedItems() {
        const CacheManagerService = $injector.get("cacheManagerService");
        const selectedItems = [];
        const configItems = getCurrentConfig().selectedDmsOsids || [];
        for (const item of configItems) {
            const dmsDocument = CacheManagerService.dmsDocuments.getById(item);
            if (dmsDocument && dmsDocument.model) {
                selectedItems.push({
                    objectId: item.toString(),
                    objectTypeId: dmsDocument.model.objectTypeId || ""
                });
            }
        }

        return selectedItems;
    }
}
