import {HttpParams} from "@angular/common/http";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {takeWhile} from "rxjs/operators";

require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/form/eob.form.srv.js");
require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");

angular.module("eob.framework").directive("eobModalEmail", EobModalEmail);

EobModalEmail.$inject = ["$filter", "$timeout", "$compile", "$eobConfig", "notificationsService", "backendService", "formFieldModelService",
    "formService", "formHelper", "environmentService", "valueUtilsService", "loggingService", "dmsDocumentService",
    "clientService", "messageService", "backendObjectService"];

function EobModalEmail($filter, $timeout, $compile, $eobConfig, NotificationsService, BackendService, FormFieldModelService,
                       FormService, FormHelper, EnvironmentService, ValueUtilsService, LoggingService, DmsDocumentService,
                       ClientService, MessageService, BackendObjectService) {
    return {
        replace: true,
        scope: {
            dmsDocuments: "=items",
            sendcontent: "=",
            destroy$: "=destroy"
        },
        link(scope, element) {
            let isSending = false;

            scope.sendMail = async function() {
                if (isSending) {
                    return;
                } else if (ClientService.isOffline()) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                    return;
                }
                try {
                    isSending = true;
                    await FormService.validateForm(scope.formHelper.getFields());

                    scope.formData = {
                        from: scope.formHelper.getFieldByInternal("from").value,
                        to: scope.formHelper.getFieldByInternal("to").value,
                        cc: "",
                        bcc: "",
                        subject: scope.formHelper.getFieldByInternal("title").value,
                        message: scope.formHelper.getFieldByInternal("comment").value,
                        linkOrContent: "",
                        objectIds: ""
                    };

                    // if the user has an email use this as from adress
                    if (EnvironmentService.getSessionInfo().email.length > 0) {
                        scope.formData.from = EnvironmentService.getSessionInfo().email;
                    }

                    if (scope.sendcontent) {
                        scope.formData.linkOrContent = {
                            name: $filter("translate")("modal.email.form.data.content"),
                            id: "content"
                        };
                    } else {
                        scope.formData.linkOrContent = {
                            name: $filter("translate")("modal.email.form.data.link"),
                            id: "link"
                        };
                    }

                    let objectIds = [];
                    let parentIds = [];

                    for (let doc of scope.dmsDocuments) {
                        objectIds.push(doc.model.osid);

                        if (!doc.model.isinWfTray) {
                            const locations = await BackendObjectService.getDmsObjectLocations(doc.model.osid, doc.model.objectTypeId).toPromise();
                            parentIds.push(locations[0][0].objectId);
                        }
                    }

                    scope.formData.objectIds = objectIds.join(";");
                    scope.formData.parentIds = parentIds.join(";");

                    try {
                        if (EnvironmentService.isMicroserviceBackend()) {
                            await BackendService.post("/email.do", scope.formData, null, $eobConfig.getOswebBase());
                        } else {
                            let data = new HttpParams();

                            for(const x of Object.keys(scope.formData)) {
                                if(typeof scope.formData[x] == "object") {
                                    for(const y of Object.keys(scope.formData[x])) {
                                        data = data.set(`${x}[${y}]`, scope.formData[x][y])
                                    }
                                } else {
                                    data = data.set(x, scope.formData[x]);
                                }
                            }

                            await BackendService.post("/email.do", data, null, $eobConfig.getOswebBase());
                        }

                        NotificationsService.success($filter("translate")("modal.email.send.success"));
                        isSending = false;
                    } catch (_) {
                        // TODO: Altes Backend. Hat andere Fehlerstruktur. Wenn umgestiegen auf neues Backend/AppConnector V1/V2 dann backendError nehmen vom NotificationService
                        NotificationsService.error($filter("translate")("modal.email.send.error"));
                        isSending = false;
                    }

                    scope.close();
                } catch (error) {
                    LoggingService.error(`Error validating form: ${error}`);
                    isSending = false;
                }
            };

            scope.emailFormFields = [];

            // create mocked fields to access the api later
            let fromFormField = FormFieldModelService.getMockedField("text", "from", $filter("translate")("modal.email.form.from"));
            let toFormField = FormFieldModelService.getMockedField("text", "to", $filter("translate")("modal.email.form.to"));
            let titleFormField = FormFieldModelService.getMockedField("text", "title", $filter("translate")("modal.email.form.subject"));
            let commentFormField = FormFieldModelService.getMockedField("text", "comment", $filter("translate")("modal.email.form.text"), null, true);

            fromFormField.isRequired = true;
            toFormField.isRequired = true;
            commentFormField.isTextArea = true;

            // push it to the form fields for the eob-form
            scope.emailFormFields.push(fromFormField);
            scope.emailFormFields.push(toFormField);
            scope.emailFormFields.push(titleFormField);
            scope.emailFormFields.push(commentFormField);

            let formData = FormService.createFormData(scope.emailFormFields, "max");

            let helperConfig = {
                formData,
                submit: scope.sendMail
            };

            // create a form helper
            scope.formHelper = FormHelper.getFormHelper(helperConfig);
            let from = scope.formHelper.getFieldByInternal("from");
            from.value = EnvironmentService.getSessionInfo().email;

            scope.formDef = {
                isMainForm: true,
                validationMode: "max",
                formFields: scope.emailFormFields,
                formHelper: scope.formHelper,
                isMockForm: true
            };

            let content = angular.element("<eob-circular-tab-form></eob-circular-tab-form>");
            let form = angular.element("<div class='form-container'><div class='form-wrapper'><eob-form [formdef]='formDef'></eob-form></div></div>");
            let buttons = angular.element("<div class=\"button-container\"><input class=\"hidden\" type=\"text\" ng-model=\"formData.objectIds\"/></div>");

            buttons.append("<button type='button' class='secondary-button' style='margin-right: 4px' ng-click='close()'>{{::'modal.button.cancel' | translate}}</button>");
            buttons.append("<button ng-click='sendMail()'>{{::'modal.button.send' | translate}}</button>");

            content.append(form);
            content.append(buttons);
            // compile the form and let angular do its magic
            let compiled = $compile(content);

            // attach the compiled form to the directive HTML element
            element.append(content);

            // wire the compiled form to the current scope
            compiled(scope);

            // bind custom onChange validation after the current digest cycle
            // see the 0ms timeout --> this calls the callback after the current
            // digest has finished
            // first timeout used to compile the form
            $timeout(() => {
                // second timeout used to compile the fields
                let comment = scope.formHelper.getFieldByInternal("comment");
                comment.label.classList.add("form-textarea-label");

                $timeout(() => {
                    let fromField = scope.formHelper.getFieldByInternal("from");
                    let toField = scope.formHelper.getFieldByInternal("to");

                    let errorTitle = $filter("translate")("modal.email.validate.error.title"),
                        errorMessage = $filter("translate")("modal.email.validate.error.message");

                    fromField.api.addCustomValidation(validateMail, errorTitle, errorMessage);
                    toField.api.addCustomValidation(validateMail, errorTitle, errorMessage);

                    if (EnvironmentService.getSessionInfo().email.length > 0) {
                        fromField.api.disable();
                        toField.api.focus();
                    } else {
                        fromField.api.focus();
                    }
                }, 0);
            }, 0);

            // custom validations
            function validateMail(field) {
                let result = ValueUtilsService.extractMailAddresses(field.value);
                return result.isValid;
            }

            scope.close = _ => MessageService.broadcast(ModalEvents.DESTROY);

            scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => {
                if (scope.formHelper != void 0) {
                    scope.formHelper.destroy();
                }
           });
        }
    };
}
