import { Component, ElementRef, Injector } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Component({
  selector: "eob-details-viewer-wrapper",
  template: ""
})
export class EobDetailsViewerWrapperComponent extends UpgradeComponent {
  constructor(elementRef: ElementRef, injector: Injector) {
      super("eobDetailsViewer", elementRef, injector);
  }
}
