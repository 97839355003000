<div id = "header">
</div>
<div class = "modal-container-tabs" [hidden] = "(isPhone && isSubMenuOpenedPhone)">
    <ul class = "nav nav-tabs">
        <li [ngClass] = "{active : openTab === 'general' && !isPhone}" (click) = "switchSettingsTab('general')">
            <button class = "button-transparent">
                <h3>{{ 'eob.action.modal.user.config.general.title' | ngTranslate }}</h3>
                <eob-icon [hidden] = "!isPhone" name = "back" size = "16"></eob-icon>
            </button>
        </li>
        <li [hidden] = "!isWorkflowUser" [ngClass] = "{active : openTab === 'presence' && !isPhone}"
            (click) = "switchSettingsTab('presence')">
            <button class = "button-transparent">
                <h3>{{ "eob.action.modal.user.config.presence.title" | ngTranslate}}</h3>
                <eob-icon [hidden] = "!isPhone" name = "back" size = "16"></eob-icon>
            </button>
        </li>
        <!--ToDo: Important to handle Favorites on mobil (Deprecated)-->
        <!--<li [hidden]="!isCordova" [ngClass]="{active : openTab == 'mobile'}" (click)="switchSettingsTab('mobile')">
            <a>{{"eob.action.modal.user.config.mobile.title" | ngTranslate}}</a>
            <eob-icon [hidden]="!isPhone" name="back" size="16"></eob-icon>
        </li>-->
        <li [ngClass] = "{active : openTab === 'offline' && !isPhone}"
            [hidden] = "!isNativeClient || !showFavorites || !canSaveSettings" (click) = "switchSettingsTab('offline')">
            <button class = "button-transparent">
                <h3>{{"eob.action.modal.user.config.offline.title" | ngTranslate}}</h3>
                <eob-icon [hidden] = "!isPhone" name = "back" size = "16"></eob-icon>
            </button>
        </li>
        <li [ngClass] = "{active : openTab === 'hitlist' && !isPhone}" (click) = "switchSettingsTab('hitlist')">
            <button class = "button-transparent">
                <h3>{{"eob.action.modal.user.config.hitlist.title" | ngTranslate}}</h3>
                <eob-icon [hidden] = "!isPhone" name = "back" size = "16"></eob-icon>
            </button>
        </li>
        <li [ngClass] = "{active : openTab === 'inbox'}" [hidden] = "isPhone" (click) = "switchSettingsTab('inbox')">
            <button class = "button-transparent">
                <h3>{{"eob.action.modal.user.config.inbox.title" | ngTranslate}}</h3>
            </button>
        </li>
    </ul>
</div>

<div class = "user-configure-dialog" [hidden] = "!(!isPhone || isPhone && isSubMenuOpenedPhone)">
    <div [hidden] = "openTab !== 'general'">

        <!--language-->
        <div class = "language-container" *ngIf = "canChangeLanguage">
            <legend class = "toggle-area-legend"
                    (click) = "areaToggleStates.general.language = !areaToggleStates.general.language"
                    [ngClass] = "{closed: !areaToggleStates.general.language}">
                <eob-icon title = "{{'eob.usermenu.change.language' | ngTranslate}}" name = "toggle-arrow-gray"
                          size = "16"></eob-icon>
                <h5>{{"eob.usermenu.change.language" | ngTranslate}}</h5>
            </legend>
            <div class = "config-container toggle-area-hidden-content"
                 [ngClass] = "{open: areaToggleStates.general.language, close: !areaToggleStates.general.language}">
                <!--languages-->
                <div class = "language-dropdown-container">
                    <span class = "color-customization-title">{{"eob.usermenu.language.title.ui" | ngTranslate}}</span>
                    <eob-dropdown [hidden] = "!areaToggleStates.general.language"
                                  [dropdownconfig] = "dropDownConfig.language"></eob-dropdown>
                </div>
                <div class = "language-dropdown-container">
                    <span class = "color-customization-title">{{"eob.usermenu.language.title.obj.def" | ngTranslate}}</span>
                    <eob-dropdown [hidden] = "!areaToggleStates.general.objectDefinitionLanguage"
                                  *ngIf = "dropDownConfigObjDefLang?.language"
                                  [dropdownconfig] = "dropDownConfigObjDefLang.language"></eob-dropdown>
                </div>
                <span class="hint" *ngIf="changes.has('LANGUAGE') || changes.has('OBJDEF_LANGUAGE')">{{ "eob.action.modal.user.config.warn.language.change.reload" | ngTranslate }}</span>
            </div>

        </div>

        <!--color theme-->
        <legend class = "toggle-area-legend"
                (click) = "areaToggleStates.appearence.theme = !areaToggleStates.appearence.theme"
                [ngClass] = "{closed: !areaToggleStates.appearence.theme}">
            <eob-icon title = "{{'eob.usermenu.change.appearence' | ngTranslate}}" name = "toggle-arrow-gray"
                      size = "16"></eob-icon>
            <h5>{{"eob.usermenu.change.appearence" | ngTranslate}}</h5>
        </legend>
        <div class = "config-container toggle-area-hidden-content color-scheme"
             [ngClass] = "{open: areaToggleStates.appearence.theme, close: !areaToggleStates.appearence.theme}">
            <span>{{"modal.user.config.theme.title" | ngTranslate}}</span>
            <eob-dropdown *ngIf = "dropDownConfig" [hidden] = "!areaToggleStates.appearence.theme"
                          [dropdownconfig] = "dropDownConfig.theme"></eob-dropdown>
            <eob-color-customization *ngIf = "activeTheme !== 'high-contrast'" [persist]="persist"></eob-color-customization>
        </div>

        <!-- touch layout -->
        <div [hidden] = "!isTouchLayoutConfigurable">
            <legend class = "toggle-area-legend"
                    (click) = "areaToggleStates.appearence.layoutstyle = !areaToggleStates.appearence.layoutstyle"
                    [ngClass] = "{closed: !areaToggleStates.appearence.layoutstyle}">
                <eob-icon title = "{{'eob.usermenu.change.appearence.layout' | ngTranslate}}" name = "toggle-arrow-gray"
                          size = "16"></eob-icon>
                <h5>{{"eob.usermenu.change.appearence.layout" | ngTranslate}}</h5>
            </legend>
            <div class = "config-container toggle-area-hidden-content"
                 [ngClass] = "{open: areaToggleStates.appearence.layoutstyle, close: !areaToggleStates.appearence.layoutstyle}">
                <div class = "toggle-switch">
                    <label class = "switch">
                        <input type = "checkbox" [checked] = "isTouchLayoutActive"
                               (click) = "toggleLayoutStyle()">
                        <span class = "slider"></span>
                    </label>
                    <div class = "toggle-switch-label">
                        <span>{{"modal.user.config.appearance.activated" | ngTranslate}}</span>
                    </div>
                </div>
                <span class="hint" *ngIf="hasTouchFriendlyTheme()">{{ "eob.action.modal.user.config.warn.touch.layout.reload" | ngTranslate }}</span>
            </div>
        </div>

        <!--password-->
        <div class = "change-password-container">
            <div class = "change-password" *ngIf = "canChangePassword">
                <legend class = "toggle-area-legend"
                        (click) = "areaToggleStates.general.password = !areaToggleStates.general.password"
                        [ngClass] = "{closed: !areaToggleStates.general.password}">
                    <eob-icon title = "{{'eob.usermenu.change.password' | ngTranslate}}" name = "toggle-arrow-gray"
                              size = "16"></eob-icon>
                    <h5> {{"eob.usermenu.change.password" | ngTranslate}}</h5>
                </legend>
                <div #passwordcontainer
                     class = "config-container password-container toggle-area-hidden-content form-container"
                     [ngClass] = "{open: areaToggleStates.general.password, close: !areaToggleStates.general.password}">
                    <div>
                        <eob-form *ngIf = "formDef" [formdef] = "formDef"></eob-form>
                        <eob-capslock-tracer></eob-capslock-tracer>
                        <div class = "button-container" [ngClass] = "{phone:isPhone}">
                            <button class = "form-button" (click) = "saveNewPassword()"
                                    ng-disabled = "isSubmitting">{{"modal.button.change.password" | ngTranslate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [hidden] = "openTab !== 'presence'" *ngIf = "isWorkflowUser">

        <!--presence-->
        <legend class = "toggle-area-legend"
                (click) = "areaToggleStates.presence.presence = !areaToggleStates.presence.presence"
                [ngClass] = "{closed: !areaToggleStates.presence.presence}">
            <eob-icon title = "{{'eob.usermenu.change.presence' | ngTranslate}}" name = "toggle-arrow-gray"
                      size = "16"></eob-icon>
            <h5>{{"eob.usermenu.change.presence" | ngTranslate}}</h5>
        </legend>
        <div class = "config-container toggle-area-hidden-content"
             [ngClass] = "{open: areaToggleStates.presence.presence, close: !areaToggleStates.presence.presence}">
            <div class = "toggle-switch">
                <label class = "switch">
                    <input type = "checkbox" ng-disabled = "isChangingPresence" [checked] = "!isAbsent"
                           (click) = "togglePresence()">
                    <span class = "slider"></span>
                </label>
                <div class = "toggle-switch-label">
                    <span class = "on">{{"eob.usermenu.workflow.present" | ngTranslate}}</span>
                </div>
            </div>
        </div>

        <!--substitutes-->
        <legend class = "toggle-area-legend"
                (click) = "areaToggleStates.presence.substitutes = !areaToggleStates.presence.substitutes"
                [ngClass] = "{closed: !areaToggleStates.presence.substitutes}">
            <eob-icon title = "{{'eob.usermenu.change.substitutes' | ngTranslate}}" name = "toggle-arrow-gray"
                      size = "16"></eob-icon>
            <h5>{{"eob.usermenu.change.substitutes" | ngTranslate}}</h5>
        </legend>
        <div class = "config-container toggle-area-hidden-content"
             [ngClass] = "{open: areaToggleStates.presence.substitutes, close: !areaToggleStates.presence.substitutes}">
            <eob-substitutes-config #substitutesConfigComponent [standalone]="false"></eob-substitutes-config>
        </div>
    </div>

    <!--ToDo: Important to handle Favorites on mobil (Deprecated)-->
    <!--<div [hidden]="!(openTab == 'mobile')" *ngIf="isCordova">
        <div class="config-container">
            <legend  class="toggle-area-legend" (click)="areaToggleStates.mobile.autofav = !areaToggleStates.mobile.autofav"
                    [ngClass]="{closed: !areaToggleStates.mobile.autofav}">
                <eob-icon name="toggle-arrow-gray" size="16"></eob-icon>
                <span>{{"eob.usermenu.change.fav" | ngTranslate}}</span>
            </legend>
            <div class="config-container toggle-area-hidden-content"
                 [ngClass]="{open: areaToggleStates.mobile.autofav, close: !areaToggleStates.mobile.autofav}">
                <div class="modal-dropdown-wrapper first">
                    <eob-dropdown [dropdownconfig]="dropDownConfig.autoFav"></eob-dropdown>
                </div>
            </div>
        </div>

        <div class="config-container">
            <legend class="toggle-area-legend"  (click)="areaToggleStates.mobile.favCacheDepth = !areaToggleStates.mobile.favCacheDepth"
                    [ngClass]="{closed: !areaToggleStates.mobile.favCacheDepth}">
                <eob-icon name="toggle-arrow-gray" size="16"></eob-icon>
                <span>{{"eob.action.modal.user.config.mobile.favourite.depth" | ngTranslate}}</span>
            </legend>
            <div class="config-container toggle-area-hidden-content"
                 [ngClass]="{open: areaToggleStates.mobile.favCacheDepth, close: !areaToggleStates.mobile.favCacheDepth}">
                <div class="modal-dropdown-wrapper second">
                    <eob-dropdown [dropdownconfig]="dropDownConfig.favCacheDepth"></eob-dropdown>
                </div>
            </div>

            <div class="toggle-switch">
                <label class="switch">
                    <input type="checkbox" [checked]="mobileSettings.favCacheSyncOnMobile"   (click)="mobileSettings.favCacheSyncOnMobile = !mobileSettings.favCacheSyncOnMobile; setMobileSettings()">
                    <span class="slider"></span>
                </label>
                <div class="toggle-switch-label"  [ngClass]="{active: mobileSettings.favCacheSyncOnMobile}">
                    <span ng-bind="'eob.action.modal.user.config.mobile.sync.on.mobile.network' | ngTranslate"></span>
                </div>
            </div>

            <div class="toggle-switch">
                <label class="switch">
                    <input type="checkbox" [checked]="mobileSettings.downloadOriginalDoc"   (click)="mobileSettings.downloadOriginalDoc = !mobileSettings.downloadOriginalDoc; setMobileSettings()">
                    <span class="slider"></span>
                </label>
                <div class="toggle-switch-label"  [ngClass]="{active: mobileSettings.downloadOriginalDoc}">
                    <span ng-bind="'eob.action.modal.user.config.mobile.download.original.document' | ngTranslate"></span>
                </div>
            </div>
        </div>
    </div>-->

    <div [hidden] = "openTab !== 'offline'">
        <!-- always synchronize favorites -->
        <legend class = "toggle-area-legend"
                (click) = "areaToggleStates.offline.synchronizeFavoritesOffline = !areaToggleStates.offline.synchronizeFavoritesOffline"
                [ngClass] = "{closed: !areaToggleStates.offline.synchronizeFavoritesOffline}">
            <eob-icon title = "{{'eob.usermenu.offline.objects.availability' | ngTranslate}}" name = "toggle-arrow-gray"
                      size = "16"></eob-icon>
            <h5>{{"eob.usermenu.offline.objects.availability" | ngTranslate}}</h5>
        </legend>
        <div class = "config-container toggle-area-hidden-content"
             [ngClass] = "{open: areaToggleStates.offline.synchronizeFavoritesOffline, close: !areaToggleStates.offline.synchronizeFavoritesOffline}">
            <div class = "toggle-switch">
                <label class = "switch">
                    <input type = "checkbox" [checked] = "isSynchronizeFavoritesOffline"
                           (click) = "toggleSynchronizeFavoritesOffline()">
                    <span class = "slider"></span>
                </label>
                <div class = "toggle-switch-label">
                    <span>{{"eob.usermenu.offline.yes" | ngTranslate}}</span>
                </div>
            </div>
            <div class = "toggle-switch">
                <label class = "switch">
                    <input type = "checkbox" [checked] = "isUseMobileDataForSync" (click) = "toggleUseMobileDataForSync()">
                    <span class = "slider"></span>
                </label>
                <div class = "toggle-switch-label">
                    <span>{{"eob.usermenu.use.mobile.data" | ngTranslate}}</span>
                </div>
            </div>
        </div>
    </div>

    <div [hidden] = "openTab !== 'hitlist'">
        <legend class = "toggle-area-legend"
                (click) = "areaToggleStates.hitlist.staticCols = !areaToggleStates.hitlist.staticCols "
                [hidden] = "isPhone"
                [ngClass] = "{closed: !areaToggleStates.hitlist.staticCols}">
            <eob-icon title = "{{'eob.usermenu.hitlist.static.cols' | ngTranslate}}" name = "toggle-arrow-gray"
                      size = "16"></eob-icon>
            <h5>{{"eob.usermenu.hitlist.static.cols" | ngTranslate}}</h5>
        </legend>
        <div class = "config-container toggle-area-hidden-content configure-static-cols" [hidden] = "!enableIconColumns"
             *ngIf = "!isPhone"
             [ngClass] = "{open: areaToggleStates.hitlist.staticCols, close: !areaToggleStates.hitlist.staticCols}">
            <div [hidden] = "col.internalName==='staticColCheckbox'" class = "toggle-switch"
                 *ngFor = "let col of staticHitlistColumns">
                <label class = "switch"><input type = "checkbox" class = "{{col.internalName}} {{checkedClass}}"
                                               [checked] = "col.enabled"
                                               (click) = "toggleHitlistColumn(col.internalName)">
                    <span class = "slider"></span>
                </label>
                <div class = "toggle-switch-label">
                    <i [hidden] = "getClassByName(col.internalName) === ''"
                       class = "{{getClassByName(col.internalName)}}" title = "{{col.title ? col.title : ''}}"></i>
                    <span>
                        {{getHitListColumnTitle(col)}}
                    </span>
                </div>
            </div>
        </div>

        <legend class = "toggle-area-legend"
                (click) = "areaToggleStates.hitlist.requestBehavoir = !areaToggleStates.hitlist.requestBehavoir "
                [ngClass] = "{closed: !areaToggleStates.hitlist.requestBehavoir}">
            <eob-icon title = "{{'eob.usermenu.hitlist.request.behavior' | ngTranslate}}" name = "toggle-arrow-gray"
                      size = "16"></eob-icon>
            <h5>{{"eob.usermenu.hitlist.request.behavior" | ngTranslate}}</h5>
        </legend>
        <div class = "config-container toggle-area-hidden-content configure-objects-without-register"
             [ngClass] = "{open: areaToggleStates.hitlist.requestBehavoir, close: !areaToggleStates.hitlist.requestBehavoir}">
            <div class = "toggle-switch">
                <label class = "switch">
                    <input type = "checkbox" [checked] = "inclObjWithoutRegConf" (click) = "toggleHitlistRegConf()">
                    <span class = "slider"></span>
                </label>
                <div class = "toggle-switch-label">
                    <span>{{"eob.usermenu.hitlist.request.behavior.toggle.box.title" | ngTranslate}}</span>
                </div>
            </div>
        </div>
    </div>

    <div [hidden] = "openTab !== 'inbox'">
        <legend class = "toggle-area-legend"
                (click) = "areaToggleStates.inbox.staticCols = !areaToggleStates.inbox.staticCols "
                [hidden] = "isPhone && !enableIconColumns"
                [ngClass] = "{closed: !areaToggleStates.inbox.staticCols}">
            <eob-icon title = "{{'eob.usermenu.inbox.static.cols' | ngTranslate}}" name = "toggle-arrow-gray"
                      size = "16"></eob-icon>
            <h5>{{"eob.usermenu.inbox.static.cols" | ngTranslate}}</h5>
        </legend>
        <div class = "config-container toggle-area-hidden-content configure-static-inbox-cols"
             [hidden] = "isPhone && !enableIconColumns"
             [ngClass] = "{open: areaToggleStates.inbox.staticCols, close: !areaToggleStates.inbox.staticCols}">
            <div [hidden] = "col.internalName==='staticColCheckbox'" class = "toggle-switch"
                 *ngFor = "let col of staticInboxColumns">
                <label class = "switch"><input type = "checkbox" class = "{{col.internalName}} {{checkedClass}}"
                                               [checked] = "col.enabled"
                                               (click) = "toggleWorkflowInboxColumn(col.internalName)">
                    <span class = "slider"></span>
                </label>
                <div class = "toggle-switch-label">{{col.headerName}}<span>{{col.title === null ? "" : col.title}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class = "button-container">
        <button class = "secondary-button" [hidden] = "!isSubMenuOpenedPhone || !isPhone"
                (click) = "back()">{{'modal.button.back' | ngTranslate}}</button>
        <button class = "secondary-button" (click) = "cancel()">{{'modal.button.cancel' | ngTranslate}}</button>
        <button class = "primary-button" #applyButton (click) = "apply()">{{'modal.button.apply' | ngTranslate}}</button>

    </div>
</div>
<div class = "button-container" *ngIf = "isPhone && !isSubMenuOpenedPhone">
    <button (click) = "cancel()">{{'modal.button.close' | ngTranslate}}</button>
</div>
