import {Broadcasts} from "../../../../app/shared/enums/broadcasts.enum";

require("MODELS_PATH/eob.wffile.api.srv.js");
const dayjs = require("dayjs");

angular.module("eob.core").factory("wfScriptingService", WfScriptingService);

WfScriptingService.$inject = ["$q", "$log", "$rootScope", "backendService", "wfFileApiService", "dmsDocumentService", "errorModelService",
    "searchService", "cacheManagerService", "routingListService", "environmentService", "inboxActionService",
    "valueUtilsService", "stateHistoryManager", "messageService"];

export default function WfScriptingService($q, $log, $rootScope, BackendService, WfFileApiService, DmsDocumentService, ErrorModelService,
                                           SearchService, CacheManagerService, RoutingListService, EnvironmentService, InboxActionService,
                                           ValueUtilsService, StateHistoryManager, MessageService) {
    return {
        getWfActions,
        addGetWfOrgObjectFns,
        addWorkflowApi
    };

    function getWfActions() {
        return {
            openWorkItem
        }
    }

    function addWorkflowApi(self, helperConfig) {
        let forwardWorkflowCallback = helperConfig.forward,
            saveWorkflowCallback = helperConfig.save,
            cancelWorkflowCallback = helperConfig.cancel,
            switchWorkflowTabs = helperConfig.switchWorkflowTabs,
            hideWorkflowTabs = helperConfig.hideWorkflowTabs,
            parameters = helperConfig.parameters,
            routingList = helperConfig.routingList,
            isProcessResponsible = helperConfig.modelDef.isProcessResponsible(),
            files = {},
            forbiddenFiles = {},
            fileGrids = {},
            routingListGrid = {},
            protocolGrid;

        /**
         * sets the file grids when the onshow event is done for later api calls on the grids
         * @param grids
         */
        self.setFileGrids = function(grids) {
            if (Object.keys(fileGrids).length === 0) {
                fileGrids.info = grids.info;
                fileGrids.work = grids.work;
            }
        };

        /**
         * sets the protocol grids when the onshow event is done for later api calls on the grid
         * @param grid - the protocol grid
         */
        self.setProtocolGrid = function(grid) {
            protocolGrid = grid;
        };

        /**
         * @deprecated since SP5 8.50
         * Forwards the current workflow
         */
        self.forwardWorkflow = function() {
            $log.warn("The function 'formHelper.forwardWorkflow()' is deprecated and will be removed in enaio version 9.0");
            $log.warn("Use the function 'formHelper.forward()' instead");
            $log.warn("For further information see the api scripting documentation");

            forwardWorkflowCallback(self);
        };

        /**
         * @deprecated since SP5 8.50
         * Cancels the current workflow
         */
        self.cancelWorkflow = function() {
            $log.warn("The function 'formHelper.cancelWorkflow()' is deprecated and will be removed in enaio version 9.0");
            $log.warn("Use the function 'formHelper.cancel()' instead");
            $log.warn("For further information see the api scripting documentation");

            cancelWorkflowCallback(self);
        };

        /**
         * @deprecated since SP5 8.50
         * Saves the current workflow
         */
        self.saveWorkflow = function() {
            $log.warn("The function 'formHelper.saveWorkflow()' is deprecated and will be removed in enaio version 9.0");
            $log.warn("Use the function 'formHelper.save()' instead");
            $log.warn("For further information see the api scripting documentation");

            saveWorkflowCallback(self);
        };

        /**
         * Sets the files that are inside the files area
         * @param work files from the work space
         * @param info files from the info space
         */
        self.setFiles = function(work, info) {
            work = typeof work == "undefined" ? [] : work;
            info = typeof info == "undefined" ? [] : info;

            files = {};

            let dmsDocuments = work.concat(info);
            for (let dmsDocument of dmsDocuments) {
                // Strange construct not using the same instance here also.
                files[dmsDocument.model.osid] = {
                    wfFile: WfFileApiService.newWfFile(dmsDocument.model.wfItem[0].model.wfFile, self, fileGrids),
                    dmsDocument
                };
            }
        };

        /**
         * returns all fileobjects
         * @returns {Object}
         */
        self.getFiles = function() {
            return files;
        };

        /**
         * returns a single file
         * @param id - the osid of the file object
         * @returns {Object}
         */
        self.getFile = function(id) {
            let file = files[id];
            if (file == void 0) {
                return null;
            }

            return file;
        };

        /**
         * Adds a file to the current workflow
         * @param id - The osid of the document to insert
         * @param objectTypeId - (optional) object type ID of file
         * @param fileArea - Either 'work' or 'info'
         * @param trayLocation - Value "1" if the file has a real location, "2" if it is located in the workflow tray.
         * @returns {deferred.promise|{then, catch, finally}}
         */
        self.addFile = function(id, objectTypeId, fileArea, trayLocation) {
            let deferred = $q.defer();

            if (!DmsDocumentService.isValidOsid(id)) {
                deferred.reject(ErrorModelService.createParameterError("id", id));
            } else if (objectTypeId != void 0 && !DmsDocumentService.isValidOsid(objectTypeId)) {
                deferred.reject(ErrorModelService.createParameterError("objectTypeId", objectTypeId));
            } else {
                if (files[id] != void 0) {
                    console.warn("The file is already part of the workflow files.");
                    deferred.resolve(files[id]);
                    return deferred.promise;
                }

                trayLocation = trayLocation == void 0 ? 1 : trayLocation;
                fileArea = fileArea == void 0 ? "work" : fileArea;

                if (objectTypeId == void 0) {
                    SearchService.searchObjectTypeId(id).then((objectTypeId) => {
                        _addFile(id.toString(), objectTypeId.toString(), fileArea, trayLocation).then((res) => {
                            deferred.resolve(res);
                        }, (error) => {
                            deferred.reject(error);
                        });
                    }, (error) => {
                        deferred.reject(error);
                    });
                } else {
                    _addFile(id.toString(), objectTypeId.toString(), fileArea, trayLocation).then((res) => {
                        deferred.resolve(res);
                    }, (error) => {
                        deferred.reject(error);
                    });
                }
            }

            return deferred.promise;
        };

        async function _addFile(id, objectTypeId, fileArea, trayLocation) {
            let dmsItem = await SearchService.searchWorkflowFileDocument(id, objectTypeId, trayLocation, true);
            let wfItem = {
                deletable: true,
                display: false,
                location: trayLocation,
                id,
                movable: true,
                objectTypeId,
                useActiveVariant: true,
                workspace: fileArea == "work",
                isNew: true,
                modified: false
            };

            dmsItem.wfItem = wfItem;
            let docId = CacheManagerService.dmsDocuments.add(dmsItem);
            let dmsDocument = CacheManagerService.dmsDocuments.getById(docId);

            let file = {
                wfFile: WfFileApiService.newWfFile(wfItem, self, fileGrids),
                dmsDocument
            };
            files[id] = file;

            if (fileGrids[fileArea] != void 0) {
                fileGrids[fileArea].addItem(file.dmsDocument);
            }

            return file;
        }

        /**
         * Removes a file from the workflow files.
         *
         * @param {string} id - The osid of the document the user wants to remove.
         */
        self.removeFile = function(id) {
            let file = files[id];

            if (!DmsDocumentService.isValidOsid(id)) {
                console.warn("The id is not valid: ", id);
                return;
            }

            if (file == void 0) {
                console.warn("The workflow file does not exist: ", id);
                return;
            }

            let dmsDocument = files[id].dmsDocument;
            let location = file.wfFile.model.workspace ? fileGrids.work : fileGrids.info;

            // the file grid might not exist yet, this would mean we are still in preparation (onShow event)
            // the file grids will be created after the onShow event, till that time we can simply remove the file references
            // the file grids will do the work for us
            if (location != void 0) {
                location.deleteItem(dmsDocument);
            }

            dmsDocument.api.removeContextItem(dmsDocument.model.wfItem[0].model.guid, "wfItem");
            CacheManagerService.dmsDocuments.add(dmsDocument);

            delete files[id];
        };

        /**
         * returns a single wfFile
         * @param id - The osid of the file item
         * @returns {Object}
         */
        self.getWfFile = function(id) {
            let file = files[id];
            if (file == void 0) {
                return null;
            }

            return file.wfFile;
        };

        /**
         * returns all wfFiles
         * @returns {Object}
         */
        self.getWfFiles = function() {
            let wfFiles = {};

            for (var i in files) {
                wfFiles[i] = files[i].wfFile;
            }

            for (var i in forbiddenFiles) {
                wfFiles[i] = forbiddenFiles[i];
            }

            return wfFiles;
        };

        /**
         * hides the desired tabs by name. Either "mask", "files", "routingList" or "protocol" are allowed
         * @param tabs - name of the tab/s
         */
        self.hideWorkflowTabs = function(tabs) {
            hideWorkflowTabs(tabs);
        };

        /**
         * switches to the desired tab by name. Either "mask", "files", "routingList" or "protocol" are allowed
         * @param tab - name of the tab
         */
        self.setActiveWorkflowTab = function(tab) {
            switchWorkflowTabs(tab);
        };

        self.getProtocol = function() {
            let protocol = self.getParameterByName("wfProtocol");

            if (protocol == void 0) {
                console.warn("WFProtocol does not exist. Please ensure, that the parameter is bound to the application.");
                return null;
            }

            return protocol;
        };

        self.addProtocolEntry = function(data) {
            let protocol = self.getProtocol();

            if (protocol == void 0) {
                console.warn("There is no workflow protocol parameter bound to the workflow!");
                return;
            }

            if (typeof (data) != "object") {
                data = { "Protokoll": data };
            }

            let columns = protocol.value.columns,
                currentDate = Date.now(),
                info = self.getInfo();
            let gridItem = { selected: true },
                values = [];

            for (let i = 0; i < columns.length; i++) {
                var name = columns[i].name,
                    value;

                switch (name) {
                    // always use the same date format, independently from user language
                    case "Datum":
                        value = dayjs(currentDate).format("DD.MM.YYYY");
                        break;
                    case "Uhrzeit":
                        value = dayjs(currentDate).format("HH:mm:ss");
                        break;
                    case "Aktivität":
                        value = info.workflow.name;
                        break;
                    case "Benutzer":
                        value = info.user.name;
                        break;
                    default:
                        value = data[name] || "";
                }

                gridItem[name] = value;
                values.push(value);
            }

            protocol.api.addRow(values);

            if (protocolGrid != void 0) {
                protocolGrid.api.addItems([gridItem]);
            }
        };

        /**
         * returns all parameters
         * @returns {Object}
         */
        self.getParameters = function() {
            return parameters;
        };

        /**
         * returns a single parameter or null
         * @param name - name of the parameter
         */
        self.getParameterByName = function(name) {
            return self.getParameterByAnyKey("name", name);
        };

        /**
         * returns a single parameter by name or null
         * @param id - guid of the parameter
         */
        self.getParameterById = function(id) {
            let parameter = parameters[id];

            if (parameter == void 0) {
                console.warn("Parameter does not exist --> ", id);
                return null;
            }

            return parameter;
        };

        /**
         * returns a single parameter by any key value pair or null
         * @param key {string} The key to use. e.g "id"
         * @param value {string} The value that shall match to the given key
         * @returns {Object}
         */
        self.getParameterByAnyKey = function(key, value) {
            if (key == void 0) {
                return null;
            }

            if (key.toLowerCase() == "id") {
                return self.getParameterById(value);
            }

            for (let i in parameters) {
                if (parameters[i].model[key] == value) {
                    return parameters[i];
                }
            }

            console.warn("Parameter does not exist --> ", value);
            return null;
        };

        /**
         * returns the parameter to a fieldname
         * @param name - The name of the field
         */
        self.getParameterByFieldName = function(name) {
            return self.getParameterByAnyFieldKey("name", name);
        };

        /**
         * returns the parameter to an internal fieldname
         * @param internal - The internal name of the field
         */
        self.getParameterByFieldInternal = function(internal) {
            return self.getParameterByAnyFieldKey("internal", internal);
        };

        /**
         * returns a parameter to a given key value pair
         * @param key - The key to use. e.g "id"
         * @param value - The value that shall match to the given key
         * @returns {Object}
         */
        self.getParameterByAnyFieldKey = function(key, value) {
            let field = self.getFieldByAnyKey(key, value);

            if (field == void 0) {
                return null;
            }

            return self.getParameterByField(field);
        };

        /**
         * returns a parameter to a given field object
         * @param field - The field object
         * @returns {Object}
         */
        self.getParameterByField = function(field) {
            if (field == void 0) {
                console.warn("Field should not be empty.");
                return null;
            }

            let param = self.getParameterByAnyKey("fieldId", field.model.internal);

            if (param == void 0) {
                console.warn("Field has no parameter --> ", field);
                return null;
            }

            return param;
        };

        /**
         * returns a field by a parametername
         * @param name - The name of the parameter
         */
        self.getFieldByParameterName = function(name) {
            return self.getFieldByAnyParameterKey("name", name);
        };

        /**
         * returns a field to a given key value pair
         * @param key - The key to use. e.g "id"
         * @param value - The value that shall match to the given key
         * @returns {Object}
         */
        self.getFieldByAnyParameterKey = function(key, value) {
            let param = self.getParameterByAnyKey(key, value);

            if (param == void 0) {
                return null;
            }

            return self.getFieldByParameter(param);
        };

        /**
         * returns a field to a given parameterobject
         * @param param - The parameterobject
         * @returns {Object}
         */
        self.getFieldByParameter = function(param) {
            if (param == void 0) {
                console.warn("Parameter should not be empty.");
                return null;
            }

            let field = self.getFieldByInternal(param.model.fieldId);

            if (field == void 0) {
                console.warn("Parameter has no field --> ", param);
                return null;
            }

            return field;
        };

        /**
         * returns if the user is a process responsible of the workflow
         * @returns {boolean}
         */
        self.isUserProcessResponsible = function() {
            return isProcessResponsible;
        };

        // region routing list

        self.hasRoutingList = routingList != null;

        /**
         * returns the workflows routing list
         * @returns {Object} or null
         */
        self.getRoutingList = function() {
            return routingList;
        };

        if (self.hasRoutingList) {
            self.setRoutingListGrid = function(field) {
                routingListGrid = field;
            };

            self.getRoutingListGrid = function() {
                return routingListGrid;
            };
        }

        self.routingListTemplates = null;

        /**
         * Loads the routing list templates and returns them back.
         * @returns {Object}
         */
        self.getRoutingListTemplates = function() {
            let deferred = $q.defer();

            if (self.routingListTemplates == null) {
                BackendService.get("/workflows/adhoc/templates").then((res) => {
                    self.routingListTemplates = [];

                    for (let i in res.data) {
                        self.routingListTemplates.push(RoutingListService.parseRoutingListTemplate(res.data[i], self));
                    }

                    deferred.resolve(self.routingListTemplates);
                }, (error) => {
                    deferred.reject(error);
                });
            } else {
                deferred.resolve(self.routingListTemplates);
            }

            return deferred.promise;
        };
        // endregion
    }

    function addGetWfOrgObjectFns(self) {
        let orgObjData = [
            { "type": "Person", "fnName": "User" },
            { "type": "Rolle", "fnName": "Role" },
            { "type": "Abteilung", "fnName": "Department" },
            { "type": "Land", "fnName": "Country" },
            { "type": "Organisation", "fnName": "OrganisationObject" }
        ];

        angular.forEach(orgObjData, (fnData) => {
            let singleFnName = `getWf${fnData.fnName}`,
                multiName = `getWf${fnData.fnName}List`;

            self[singleFnName] = function(identifier, getChildren) {
                return self.getWfOrganisationMember(fnData.type, identifier, getChildren);
            };

            self[multiName] = function(getChildren) {
                return self.getWfOrganisationMembers(fnData.type, getChildren);
            };
        });

        // we only have one root element in active wf org, no need for getWfRootList yet
        self["getWfRoot"] = function(getChildren) {
            let orgObjects = self.getWfOrganisationMembers("Wurzel", getChildren);
            if (orgObjects && orgObjects.length > 0) {
                return orgObjects[0];
            } else {
                return null;
            }
        };
    }

    function openWorkItem(inNewTab, workItemId) {
        let deferred = $q.defer();

        if (!EnvironmentService.isWorkflowUser()) {
            deferred.reject(ErrorModelService.createCustomError("WEB_NO_WORKFLOW_USER"));
            return deferred.promise;
        }

        if (workItemId == void 0 || workItemId === "") {
            deferred.reject(ErrorModelService.createParameterError("workItemId", workItemId));
            return deferred.promise;
        }

        let item = {
            id: workItemId
        };

        try {
            InboxActionService.markWfItemRead([item]);

            let newTab = ValueUtilsService.parseBoolean(inNewTab);
            if (newTab) {
                MessageService.broadcast(Broadcasts.INBOX_INDEXDATA_CHANGED, [item]);
            }

            deferred.resolve(workItemId);
            StateHistoryManager.goToState("workflow", { id: workItemId }, null, newTab);
        } catch (error) {
            console.warn(`work item cannot be personalized: ${workItemId}`);
            deferred.reject(error);
        }

        return deferred.promise;
    };
}
