/**
 * Reasons that make a timer to be done.
 */
export enum TimerDoneType {
    TIMEOUT = "TIMEOUT",
    PROGRESS_DIALOG_CANCEL = "PROGRESS_DIALOG_CANCEL",
    SKIPPED_BY_USER = "SKIPPED_BY_USER",
    CREATE_VIEW_CANCEL = "CREATE_VIEW_CANCEL",
    EMS_NOT_AVAILABLE = "EMS_NOT_AVAILABLE"
}
