<ul>
    <!--list mark up necessary for accessibiity-->
    <li *ngFor = "let cab of cabinets, let indexOfCab = index">
        <eob-panel (click) = "closeOtherCabinetPanels(cab)" [eobPanelTitle] = "cab.name"
                   [isActive] = "cab.expanded"
                   [panelHeadingLang] = "showAltLang ? objectDefLang : null" [initExpanded] = "cab.expanded"
                   [isNavPanel] = "true"
                   [ngClass] = "{'use-hover-styles': !isIosMobile}"
                   (focusLeftEvent) = "passFocus($event, indexOfCab)"
                   (focusEnteredByMouseEvent)="resetTabindexes(indexOfCab)">
            <div panelContent>
                <ul class = "nav-panel-elements">
                    <li (click) = "openSearchView(type.objectTypeId,$event)"
                        *ngFor = "let type of cab.objectTypes; let i = index">
                       <button>
                           <eob-icon name = "{{type.icon}}" title = "{{type.name}}" size = "16"></eob-icon>
                           <span [attr.lang] = "showAltLang ? objectDefLang : null">{{type.name}}</span>
                       </button>
                        <button title = "{{'nav.objectsearch.add.folder.title' | ngTranslate}}"
                                (click) = "createFolder(type.objectTypeId,$event)"
                                class = "objectsearch-button-right create-cabinet-button button-transparent secondary-panel-button"
                                *ngIf = "(i == 0 && type.rights.indexModify)"
                                tabindex="-1">
                            <eob-icon name = "add-white" size = "16"
                                      title = "{{'nav.objectsearch.add.folder.title' | ngTranslate}}"></eob-icon>
                        </button>
                    </li>
                </ul>
            </div>
            <div panelPlugin>
                <button title = "{{'nav.objectsearch.configuration.title' | ngTranslate}}"
                        *ngIf = "hitlistConfigAllowed"
                        (click) = "showHitlistConfig(cab.cabinetId,$event)"
                        class = "objectsearch-button-right configure-hitlist-button button-transparent secondary-panel-button"
                        tabindex="-1">
                    <eob-icon name = "configure-white" size = "16"
                              title = "{{'nav.objectsearch.configuration.title' | ngTranslate}}"></eob-icon>
                </button>
            </div>
        </eob-panel>
    </li>
</ul>
