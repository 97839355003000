<!--suppress TypeScriptValidateTypes -->
<eob-textarea
    *ngIf="field.model.isTextArea"
    #eobTextarea
    [field]="field"
    [formhelper]="formhelper"
    [ismockform]="ismockform"
    [hasAddon]="hasAddon"
    [fieldid]="fieldid"
    [control]="control"
    [disabled]="disabled"
    [customBubble]="formhelper.validationBubble"
    (triggerKeydown)="keyDownHandler($event)"
    (triggerClick)="clickHandler($event)"
>
</eob-textarea>
<eob-input
    *ngIf="!field.model.isTextArea"
    #eobInput
    [field]="field"
    [formhelper]="formhelper"
    [ismockform]="ismockform"
    [hasAddon]="hasAddon"
    [showPlaceholder]="showPlaceholder"
    [fieldid]="fieldid"
    [control]="control"
    [disabled]="disabled"
    [autocomplete]="eobAutocomplete"
    [customBubble]="formhelper.validationBubble"
    (triggerKeydown)="keyDownHandler($event)"
    (triggerClick)="clickHandler($event)"
    (isFocused) = "onInputFocus($event)"
>
</eob-input>
