import {Theme} from "MODULES_PATH/theme/theme.interface";
import {Injectable} from "@angular/core";
// import {ThemingUtilsService} from "MODULES_PATH/theme/theming-utils.service";

@Injectable({
    providedIn: "root"
})

export class HighContrastThemeService {
    getHighContrastTheme(): Theme {
        return {
            name: "high-contrast",
            general: {
                baseColor: "#3a3a3a",
                urlBgNav: "",
                urlBgDashboard: "",
            },
            color: {
                background: {
                    panel: "#171717",
                    main: "#171717", // "darken($panel-color, 8%)",
                    splitPane: "#f1f1f1", // "lighten($panel-color, 10%)",
                    inlineDialog: "#171717", // "lighten($panel-color, 5%)",
                    pane: "#171717",
                    header: "#171717", // "darken($panel-color, 5%)",
                    grid: "#171717", // "darken($panel-color, 2.5%)",
                    overlay: "rgba(0,0,0,0.8)",
                    // navigation
                    nav: "#171717",
                    navOffline: "#171717", // "lighten($panel-color, 10%)",
                }, accent: {
                    primary: "#00E1FF",
                    gray: "#171717", // "lighten($panel-color, 10%)",
                    light: "#00E1FF", // "lighten($accent-color, 10%)",
                    extraLight: "#00E1FF", // "(lighten($accent-color, 10%), 0.15)",
                    desat1: "#00E1FF", // "desaturate($accent-color, 40%)",
                    invalid: "rgba(255,0,255,0.2)",
                    attention: "#ff0",
                    warning: "#ffe53d",
                    error: "#ff00ff",
                    ok: "#00be79",
                    info: "#00E1FF",
                    gradient1: "#171717",
                    gradient2: "#171717",
                    gradient3: "#171717",
                }, font: {
                    main: "#fafafa",
                    inverted: "#fafafa",
                    medium: "#fafafa",
                    buttonHover: "#000", // font color for items that have main font color but are being hovered, focused or selected
                    highlighted: "#000", // font color for items that have inverted font color but are being hovered, focused or selected
                    disabled: "#6dff36",
                }, element: {
                    button: "#171717",
                    navScrollbar: "#fafafa",
                    navScrollbarTrack: "#535353",
                    scrollbar: "#fafafa",
                    formButton: "#171717", // "rgba(desaturate($accent-color, 80%), 0.6)",
                    formInput: "#171717", // "lighten(#3a3a3a, 8%)",
                }, border: {
                    accessibility: "#fafafa", // border that is transparent in all themes other than high contrast theme
                    button: "#00E1FF", // border that is transparent in all themes other than high contrast theme
                    default: "#fafafa",
                    defaultNew: "#fafafa",
                    inverted: "#fafafa",
                    disabled: "#4e4e4e", // "lighten($panel-color, 8%)",
                }
            }
        };
    }
}
