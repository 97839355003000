import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ProfileManagerService} from "MODULES_PATH/profile-manager/profile-manager.service";
import {EobDropdownComponent} from "SHARED_PATH/components/eob-dropdown/eob-dropdown.component";
import {DropDownConfig} from "INTERFACES_PATH/dropdown.interface";
import {TodoDropdownItem, TodoModalDialogService} from "INTERFACES_PATH/any.types";
import {Profile, ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {AuthenticationType} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ProfileManagerIcon} from "MODULES_PATH/profile-manager/enums/profile-manager-icon.enum";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import { from } from "rxjs";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {CapslockTracerService} from "MODULES_PATH/form/services/capslock-tracer.service";

@Component({
    selector: "eob-login",
    templateUrl: "./eob-login.component.html",
    styleUrls: ["./eob-login.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class EobLoginComponent implements OnInit, AfterViewInit {
    @Output() triggerLogin = new EventEmitter();
    @Output() triggerAbortLogin = new EventEmitter();

    @ViewChild("autologinCheckbox", {static: false}) autologinCheckbox: ElementRef<HTMLDivElement>;
    @ViewChild("profileDropdown", { static: false }) profileDropdown: EobDropdownComponent;
    @ViewChild("deleteProfileButton", {static: false}) deleteProfileButton: ElementRef<HTMLButtonElement>;
    @ViewChild("typeDropdown", {static: false}) typeDropdown: EobDropdownComponent;
    @ViewChild("loginUsername", {static: false}) loginUsername: ElementRef<HTMLInputElement>;
    @ViewChild("submitButton", {static: false}) submitButton: ElementRef<HTMLButtonElement>;

    private readonly translateFn: TranslateFnType;
    profilesReady = false;
    profileDropDownConfig: DropDownConfig;
    authTypeDropdownConfig: DropDownConfig;
    hideTypeDropdown = false;
    profiles: Map<string, Profile>;
    profileKeys: string[];
    isBrowserClient: boolean;
    isPhone: boolean;
    customStorage: CustomStorage;

    constructor(
        @Inject("$filter") $filter: ng.IFilterService,
        @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
        private clientService: ClientService,
        private profileService: ProfileService,
        private cdRef: ChangeDetectorRef,
        public profileManagerService: ProfileManagerService,
        private renderer: Renderer2,
        public customStorageService: CustomStorageService,
        public notificationsService: NotificationsService,
        private capslockTracerService: CapslockTracerService
    ) {
        this.translateFn = $filter("translate");
        this.isBrowserClient = !this.clientService.isLocalClient();
        this.isPhone = this.clientService.isPhone();
    }

    ngOnInit(): void {
        this.customStorageService.getStorage().subscribe(storage => {
                this.customStorage = storage;
            }
        );
        if(this.isBrowserClient) {
            this.profileManagerService.profile = {url: "", username: "", password: "", authType: AuthenticationType.BASIC_AUTH};
            this.profilesReady = true;
            return;
        }
        this.profiles = this.profileService.getProfiles();
        const profileDropDownItems: TodoDropdownItem[] = [];
        const lastActiveProfileKey: string = this.profileService.getLastActiveProfileKey();

        this.profiles.forEach((profile, key) => {
            if (!profile.isDemo) {
                let icon = "";
                switch (profile.authType) {
                    case AuthenticationType.BASIC_AUTH:
                        icon = ProfileManagerIcon.ICON_BASIC_AUTH;
                        break;
                    case AuthenticationType.NTLM_SYSTEM:
                        icon = ProfileManagerIcon.ICON_NTLM_SYSTEM;
                        break;
                    case AuthenticationType.NTLM_USERNAME:
                        icon = ProfileManagerIcon.ICON_NTLM_USERNAME;
                        break;
                    case AuthenticationType.OPENRESTY_KEYCLOAK:
                        icon = ProfileManagerIcon.ICON_OPENRESTY_KEYCLOAK;
                        break;
                }

                // start the dropdown item display name with second part of profile url...
                let name = "(no profile url)";
                const urlParts: string[] = profile.url?.split("//");
                if (urlParts && urlParts.length > 1) {
                    name = urlParts[1];
                }

                // ... append username to display name, if given
                if (profile.username?.length > 0) {
                    name = `${name}/${profile.username}`;
                }

                const isActive: boolean = lastActiveProfileKey == key;
                profileDropDownItems.push({
                    internal: key,
                    name,
                    icon,
                    isActive
                });

                if(isActive) {
                    this.profileManagerService.profile = profile;
                }
            }
        });

        this.profileManagerService.showLoginForm = profileDropDownItems.length > 0;

        this.profileDropDownConfig = {
            items: profileDropDownItems,
            useFullHeight: true,
            callback: item => this.profileDropdownCallback(item),
            callbackAfterInit: true,
            emptyPlaceholder: {
                name: `< ${this.translateFn("eob.login.no.profile.available")} > `,
                placeholder: true
            },
            showEmptyPlaceholder: true
        };

        const authTypeItems: TodoDropdownItem[] = [{internal: AuthenticationType.BASIC_AUTH, name: "Basic authentication", icon: ProfileManagerIcon.ICON_BASIC_AUTH},
            {internal: AuthenticationType.NTLM_SYSTEM, name: "NTLM (Windows)", icon: ProfileManagerIcon.ICON_NTLM_SYSTEM},
            {internal: AuthenticationType.NTLM_USERNAME, name: "NTLM (Username)", icon: ProfileManagerIcon.ICON_NTLM_USERNAME},
            {
                internal: AuthenticationType.OPENRESTY_KEYCLOAK,
                name: "Openresty (Keycloak)",
                icon: ProfileManagerIcon.ICON_OPENRESTY_KEYCLOAK
            }];

        authTypeItems.find(x => x.internal == (this.profileManagerService.profile.authType ?? AuthenticationType.BASIC_AUTH)).isActive = true;

        this.authTypeDropdownConfig = {
            items: authTypeItems,
            useFullHeight: true,
            callback: item => this.profileManagerService.authTypeDropdownCallback(item),
            callbackAfterInit: true,
            showEmptyPlaceholder: true
        };

        if (this.clientService.isMobile() || !this.clientService.isLocalClient()) {
            // Only offer basic auth on mobile for now
            this.hideTypeDropdown = true;
            this.profileManagerService.authTypeDropdownCallback(this.authTypeDropdownConfig.items[0]);
        }

        this.profilesReady = true;
    }

    ngAfterViewInit(): void {
        if (this.clientService.isLocalClient()) {
            this.submitButton?.nativeElement.focus();
        } else {
            this.loginUsername?.nativeElement.focus();
        }

        // Changes inside ngAfterViewInit() have to be detected manually
        // In addition, some changes triggered by the dropdown menus lag slightly, which required this manual detection
        this.cdRef.detectChanges();
    }

    autoLoginCheckboxKeydown(event: KeyboardEvent): void {
        if (event.code === "Space") {
            this.toggleAutoLogin();
        }
    }

    loginButtonKeydown(event: KeyboardEvent): void {
        if (event.code === "Enter") {
            this.login();
        }
    }

    abortLogin(): void {
        this.triggerAbortLogin.emit();
    }

    login(): void {
        if(this.clientService.isOnline()) {
            this.triggerLogin.emit();
        } else {
            from(this.customStorage.getSecureItem(`pw@@@${this.profileService.getProfileKeyFromProfile(this.profileManagerService.profile)}`)).subscribe(pwd => {
                if(!pwd) {
                    this.notificationsService.info(this.translateFn("eob.login.offline"));
                } else {
                    this.triggerLogin.emit();
                }
            });
        }
    }

    back(): void {
        this.profileManagerService.showLoginForm = false;
        this.profileManagerService.showBackButton = false;
    }

    toggleAutoLogin(): void {
        // TODO: Unter iOS Safari wird dieser Handler doppelt aufgerufen. DODO-6330

        this.profileManagerService.profile.autologin = !this.profileManagerService.profile.autologin;
        ["ok", "null", "ambiguous"].forEach(cls => this.renderer.removeClass(this.autologinCheckbox.nativeElement, cls));

        if (this.profileManagerService.profile.autologin) {
            this.renderer.addClass(this.autologinCheckbox.nativeElement, "ok");
        } else {
            this.renderer.addClass(this.autologinCheckbox.nativeElement, "null");
        }
    }

    private profileDropdownCallback(param: TodoDropdownItem): void {
        // set active profile
        this.profiles = this.profileService.getProfiles();
        this.profileManagerService.profile = this.profiles.get(param.internal) || {} as Profile;
        this.profileKeys = Array.from(this.profiles.keys());
        this.profileManagerService.isPlaceholderSelected = !!param.placeholder;
        if (!this.profileManagerService.profile.authType) {
            this.profileManagerService.profile.authType = this.profileManagerService.profile.windowslogin ? AuthenticationType.NTLM_SYSTEM : AuthenticationType.BASIC_AUTH;
            delete this.profileManagerService.profile.windowslogin;
        }

        // set authType dropdown and trigger dropdown callback
        if(!this.clientService.isMobile()) {
            this.typeDropdown?.setItemActive(this.authTypeDropdownConfig.items.find(x => x.internal == this.profileManagerService.profile.authType));
        }
        // Required to notify the parent component when selecting a placeholder (no idea why though)
        this.cdRef.detectChanges();
    }

    async deleteProfile(): Promise<boolean> {
        this.deleteProfileButton.nativeElement.blur();
        if (this.profileManagerService.isPlaceholderSelected) {
            return;
        }

        try {
            if (!this.profileManagerService.profile.isDemo) {
                await this.modalDialogService.infoDialog(this.translateFn("eob.login.profile.delete.title"),
                    this.translateFn("eob.login.confirm.profile.deletion"),
                    this.translateFn("modal.button.cancel"),
                    this.translateFn("modal.button.delete"));
            }
        } catch (error) {
            // modal dialog not confirmed
            return false;
        }

        const profileKey: string = this.profileService.getProfileKeyFromProfile(this.profileManagerService.profile);
        await this.profileService.deleteProfile(this.profileManagerService.profile);

        // delete the profile entry in profile dropdown without triggering the dropdown callback
        const deletedEntry: TodoDropdownItem = this.profileDropDownConfig.items.find(item => item.internal == profileKey);
        const idx: number = this.profileDropDownConfig.items.indexOf(deletedEntry);
        this.profileDropDownConfig.items = this.profileDropDownConfig.items.filter(x => x.internal != profileKey);
        // Just change detection things
        this.profileDropDownConfig = Object.assign({}, this.profileDropDownConfig);

        if(this.profileDropDownConfig.items.length) {
            this.profileDropdown.setItemActive(this.profileDropDownConfig.items[(idx == -1 || idx >= this.profileDropDownConfig.items.length) ? 0 : idx]);
        }
    }

    onInputFocus(focused: boolean): void {
        if (focused) {
            this.capslockTracerService.showCapsLockWarning.next(true);
        } else {
            this.capslockTracerService.showCapsLockWarning.next(false);
            this.capslockTracerService.capsLockOn.next(false);
        }
    }
}
