import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { EobTimer } from "MODULES_PATH/timer/interfaces/timer.interface";
import { TimerService } from "MODULES_PATH/timer/services/timer.service";
import { takeWhile } from "rxjs/operators";
import { Subscription } from "rxjs";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {Inject, Input} from "@angular/core";
import {TimerDoneType} from "MODULES_PATH/timer/enums/timer-done-type";
import {Subject} from "rxjs";

@Component({
    selector: "eob-timer",
    templateUrl: "./eob-timer.component.html",
    styleUrls: ["./eob-timer.component.scss"]
})
export class EobTimerComponent implements OnInit, OnDestroy {
    hidden: boolean = false;
    hiddenInfo: boolean = false;
    currentTime: EobTimer;
    subscriptions: Subscription = new Subscription();
    private readonly translateFn: TranslateFnType;

    constructor(private cdRef: ChangeDetectorRef,
                private timerService: TimerService,
                @Inject("$filter") protected $filter: ng.IFilterService
    ) {
        this.translateFn = this.$filter("translate") ;
    }

    @Input() createResult$: Subject<string>;

    ngOnInit(): void {
        this.subscriptions.add(this.timerService.countDown$.subscribe((time: EobTimer) => {
            this.currentTime = time;
            this.cdRef.detectChanges();
        }));

        this.subscriptions.add(this.timerService.done().pipe(takeWhile((doneBy): boolean => doneBy == TimerDoneType.TIMEOUT)).subscribe(() => {
            this.hidden = true;
            this.cdRef.detectChanges();
            this.timerService.showDoneInfoDialog(0, 0, true);

            this.createResult$.next(TimerDoneType.TIMEOUT);
            this.createResult$.complete();
         }));

        this.subscriptions.add(this.timerService.done().pipe(takeWhile((doneBy): boolean => doneBy == TimerDoneType.CREATE_VIEW_CANCEL)).subscribe(() => {
            this.hidden = true;
            this.cdRef.detectChanges();

            this.createResult$.next(TimerDoneType.CREATE_VIEW_CANCEL);
            this.createResult$.complete();
         }));
    }

    toggle(): void {
        this.hiddenInfo = !this.hiddenInfo;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
