import {Injectable, Inject} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {WindowEventType} from "ENUMS_PATH/window-event-type.enum";
import { from, of } from "rxjs";
import {switchMap, filter, delay, concatMap} from "rxjs/operators";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import { ProfileService } from "CORE_PATH/authentication/util/profile.service";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {ReplaySubject, Subject} from "rxjs";
import {first} from "rxjs/operators";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

const {saveAs}: any = require("file-saver");

const _map: WeakMap<any, any> = new WeakMap();

const internal: (a: any) => any = (object: any): any => {
    if (!_map.has(object)) {
        _map.set(object, {});
        setTimeout(() => {
            _map.delete(object);
        }, 10000);
    }
    return _map.get(object);
};

/* eslint-disable @typescript-eslint/require-await */


/**
 * Client service implementation to be used inside a desktop app context
 */
@Injectable({
    providedIn: "root"
})
export class ClientElectronService extends ClientService {
    private gStorage$: Subject<CustomStorage> = new ReplaySubject<CustomStorage>(1);

    constructor(@Inject("$injector") $injector: ng.auto.IInjectorService,
                @Inject("$eobConfig") $eobConfig: any,
                @Inject("$rootScope") $rootScope: RootScope,
                errorModelService: ErrorModelService,
                messageService: MessageService,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("fileOpenService") protected fileOpenService: any,
                protected authenticationService: AuthenticationService,
                protected customStorageService: CustomStorageService) {
        super($filter, $injector, $eobConfig, $rootScope, errorModelService, messageService, authenticationService, customStorageService);
        customStorageService.getStorage()?.subscribe(storage => {
            this.gStorage$.next(storage);
        });
    }

    init(): void {
        super.init();
        this.addFileOpenHandler();
        this.showCloseButtonOnAllTabs();
        this.addCacheLockedEventHandler();
    }

    /**
     * Add an event listener to handle open.os.file event
     */
    addFileOpenHandler(): void {
        // event listener for opening os file passed by command line argument (used as Windows double click handler for .os files)
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.addEventListener("open.os.file", async (eventOpen: any) => {
            if (!(this.$rootScope as any).webclientReady) {
                const registerReadyEvent: any = this.$rootScope.$on("webclientReady", async (eventReady: ng.IAngularEvent): Promise<void> => {
                    const fileContent: ArrayBuffer = await this.readDataFromFileAsync(eventOpen.detail, false) as ArrayBuffer;
                    this.fileOpenService.openOsLinkFileContent(fileContent);
                    registerReadyEvent();
                });
            } else {
                const fileContent: ArrayBuffer = await this.readDataFromFileAsync(eventOpen.detail, false) as ArrayBuffer;
                this.fileOpenService.openOsLinkFileContent(fileContent);
            }
        });
    }

    /**
     * Show close button on all tabs after three seconds (have been hidden in postTabOpenCallback of new tabs)
     */
    showCloseButtonOnAllTabs(): void {
        if (!(this.$rootScope as any).webclientReady) {
            const registerReadyEvent: any = this.$rootScope.$on("webclientReady", (event: ng.IAngularEvent): void => {
                setTimeout(() => {
                    window.electron.showCloseButtonOnAllTabs();
                }, 3000);
                registerReadyEvent();
            });
        } else {
            setTimeout(() => {
                window.electron.showCloseButtonOnAllTabs();
            }, 3000);
        }
    }

    /** @inheritDoc */
    broadcastTrayItemsChanged(): void {
        window.electron.externalTrayElementsChanged();
    }

    /** @inheritDoc */
    setIsSynchronizing(isSynchronizing: boolean): void {
        if (this.isSynchronizing != isSynchronizing) {
            this.isSynchronizing = isSynchronizing;
            this.notifySynchronizationChange(isSynchronizing);
        }

        window.electron.setIsSynchronizing(isSynchronizing);
    }

    /** @inheritDoc */
    getIsSynchronizing(): Promise<boolean> {
        return window.electron.getIsSynchronizing();
    }

    private addCacheLockedEventHandler(): void {
        window.addEventListener(WindowEventType.CACHE_LOCK_CHANGED, (event: any) => {
            this.setIsCacheLocked(event.detail, false);
        });
    }

    /** @inheritDoc */
    setIsCacheLocked(isCacheLocked: boolean, broadcastToMain: boolean = true): void {
        if (this.isCacheLocked != isCacheLocked) {
            this.isCacheLocked = isCacheLocked;
            this.cacheLockedSubject.next(isCacheLocked);
        }

        if (broadcastToMain) {
            window.electron.setIsCacheLocked(isCacheLocked);
        }
    }

    /** @inheritDoc */
    getIsCacheLocked(): Promise<boolean> {
        return window.electron.getIsCacheLocked();
    }

    /** @inheritDoc */
    setColorTheme(theme: string): void {
        window.electron.setColorTheme(theme);
    }

    /** @inheritDoc */
    async getStorageAsync(): Promise<CustomStorage> {
        return this.gStorage$.pipe(first()).toPromise();
    }

    /** @inheritDoc */
    async platformLogoutAsync(): Promise<void> {
        window.electron.closeAllDetachedViewers();
        window.electron.onProfileLoggedOut();
        window.electron.closeAdditionalTabs();
        if (process.env.NODE_ENV != "development" && process.env.NODE_ENV != "test") {
            window.electron.disableDeveloperConsole();
            window.electron.closeDeveloperConsole();
        }
        window.electron.hideNewTabButton();
        window.electron.storeSessionStorage();
        window.electron.restoreWindowSizeAndPosition(); // restore to default window size and position

        this.clearCookies();
        window.electron.refreshMainTab(true);
    }

    /** @inheritDoc */
    clearCookies(): void {
        window.electron.clearCookies();
    }

    /** @inheritDoc */
    async platformOpenFileDataInDefaultAppAsync(dmsDocument: any, fileName: string,
                                                fileContent: ArrayBuffer, save?: boolean): Promise<string | Error | void> {
        let filePath = "";

        if (dmsDocument != void 0) {
            // dmsDocument is given, open special file in work directory for recognition in Office/Outlook addins
            const environmentService: TodoEnvironmentService = this.$injector.get("environmentService");

            // for checkouts in electron the output file will be located under a directory like
            // ${paddedUserIdInHex}/CACHE/{mainTypeInHex}/{subTypeInHex}/${lowByteOfObjectIdInHex}/${paddedObjectIdInHex}.${fileExt}
            // so Office/Outlook addins are able to recognize the name as an enaio document
            const paddedUserIdInHex: string = `00000000${parseInt(environmentService.getSessionInfo().userid, 10).toString(16).toUpperCase()}`.slice(-8);
            const paddedObjectIdInHex: string = `00000000${parseInt(dmsDocument.model.id, 10).toString(16).toUpperCase()}`.slice(-8);
            const lowByteOfObjectIdInHex: string = paddedObjectIdInHex.slice(-2);
            const paddedMainTypeInHex: string = `00${parseInt(dmsDocument.model.mainType, 10).toString(16).toUpperCase()}`.slice(-2);
            const paddedSubTypeInHex: string = `00${parseInt(dmsDocument.model.subType, 10).toString(16).toUpperCase()}`.slice(-2);
            const fileExt: string | undefined = fileName.split(".").pop();

            filePath = window.electron.pathModule.join(paddedUserIdInHex, "CACHE", paddedMainTypeInHex, paddedSubTypeInHex, lowByteOfObjectIdInHex);
            fileName = `${paddedObjectIdInHex}.${fileExt}`;

            window.electron.saveToWorkDirAndShellOpen(fileName, fileContent, filePath);
        } else {
            // no dmsDocument given, open file as renamed copy in temp dir
            fileName = `${+new Date()}-${fileName}`;
            window.electron.saveToTempDirAndShellOpen(fileName, fileContent);
        }

        return window.electron.pathModule.join(filePath, fileName);
    }

    /** @inheritDoc */
    async platformSaveAsAsync(filename: string, fileContent: ArrayBuffer): Promise<string> {
        const file: Blob = new Blob([fileContent]);
        saveAs(file, filename);
        return filename;
    }

    /** @inheritDoc */
    async readDataFromFileAsync(filePath: string, isText: boolean): Promise<string | ArrayBuffer> {
        return window.electron.getContentFromFilePath(filePath, isText);
    }

    /** @inheritDoc */
    async correctFilePathAsync(filePath: string): Promise<string> {
        return filePath;
    }

    /** @inheritDoc */
    refreshTabTitle(tabTitle: string): void {
        window.electron.refreshTabTitle({tabId: window.webviewId, title: tabTitle, width: 150});
    }

    /** @inheritDoc */
    async writeDataIntoTempFileAsync(filename: string, fileContent: ArrayBuffer): Promise<string | ArrayBuffer> {
        return window.electron.saveToOsTempDir(filename, fileContent, "");
    }

    /** @inheritDoc */
    async checkForCheckedOutFilesAsync(): Promise<boolean> {
        return window.electron.checkForCheckedOutFilesOnLogout();
    }

    /** @inheritdoc */
    execProgramAsync(pathToProgram: string, programArguments: string, returnResult: boolean, workingDirectory?: string): Promise<string> {
        // We save a Promise and return the inner promise directly to the caller who is also awaiting.
        return window.electron.execProgramAsync(pathToProgram, programArguments, returnResult);
    }

    /** @inheritdoc */
    getMDMConfig(): any {
        return undefined;
    }
}
