import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";

/**
 * @template T The delegate type to use. It's a piped type out of two. One for OsRest and one for Dms2.
 */
export abstract class BackendBaseService<T> {
    private static useOsRestDelegate: boolean | undefined = undefined;

    protected delegate: T;
    protected dms2Service: T;
    protected osRestService: T;

    /**
     * This generic base constructor make the decision if the backend classes should use the new DMS2 microservice
     * or the old OsRest backend. Some of the backend classes are constructred later then the message is
     * send by the environment service. Early services will wait on the message and store the decision in a
     * static class variable. If a later constructed service is created by angular he detect that the decision is
     * already made and assign directly the right delegate without waiting on the message.
     *
     * @param messageService - The message service if this service must wait on the decision.
     * @param dms2Service - The dms2 delegate
     * @param osRestService - The osrest delegate
     */
    protected constructor(messageService: MessageService, dms2Service: T, osRestService: T) {
        this.dms2Service = dms2Service;
        this.osRestService = osRestService;

        if (BackendBaseService.useOsRestDelegate == undefined) {
            messageService.subscribeFirst(Broadcasts.PRODUCT_VERSION_RECEIVED, (productVersion: string) => {
                BackendBaseService.useOsRestDelegate = productVersion.startsWith("9.");
                this.delegate = (BackendBaseService.useOsRestDelegate) ? osRestService : dms2Service;
            });
        } else {
            this.delegate = (BackendBaseService.useOsRestDelegate) ? osRestService : dms2Service;
        }
    }
}

