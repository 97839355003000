import {Theme} from "MODULES_PATH/theme/theme.interface";
import {Injectable} from "@angular/core";
import {ThemingUtilsService} from "MODULES_PATH/theme/theming-utils.service";

@Injectable({
    providedIn: "root"
})

export class DarkThemeService {

    constructor(private themingUtilsService: ThemingUtilsService) {}

    getDarkTheme(accentColor): Theme {
        return {
            name: "dark",
            general: {
                baseColor: "#3a3a3a",
                urlBgNav: "url('assets/background-images/dashboard-tiles-dark.png')",
                urlBgDashboard: "url('assets/background-images/dashboard-tiles-dark.png')",
            },
            color: {
                background: {
                    panel: "#2a2b2f",
                    main: "#262626", // "darken($panel-color, 8%)",
                    splitPane: "#535353", // "lighten($panel-color, 10%)",
                    inlineDialog: "#2a2b2f", // "lighten($panel-color, 5%)",
                    pane: "#3a3a3a",
                    header: "#272727", // "darken($panel-color, 5%)",
                    grid: "#353535", // "darken($panel-color, 2.5%)",
                    overlay: "rgba(0,0,0,0.8)",
                    // navigation
                    nav: "#272727",
                    navOffline: "#535353", // "lighten($panel-color, 10%)",
                }, accent: {
                    primary: accentColor,
                    gray: "#444546", // "lighten($panel-color, 10%)",
                    light: this.themingUtilsService.shade(accentColor, 0.28),
                    extraLight: this.themingUtilsService.shade(accentColor, -0.64),
                    desat1:  this.themingUtilsService.shade(accentColor, 0.18), 
                    invalid: "rgba(255,0,0,0.08)",
                    attention: "#ff0",
                    warning: "#ff9900",
                    error: "#bd362f",
                    info: "#0092e1",
                    ok: "#28c700",
                    gradient1: this.themingUtilsService.shade(accentColor, 0.1),
                    gradient2: accentColor,
                    gradient3: this.themingUtilsService.shade(accentColor, -0.1),
                }, font: {
                    main: "#ccc",
                    inverted: "#ccc",
                    medium: "rgba(164, 164, 164, 0.75)",
                    highlighted: "#ccc", // font color for items that have main font color but are being hovered, focused or selected
                    disabled: "rgba(164, 164, 164, 0.75)",
                    buttonHover: "#fff", // font color for items that have inverted font color but are being hovered, focused or selected
                }, element: {
                    button: accentColor,
                    navScrollbar: "#777",
                    navScrollbarTrack: "#535353",
                    scrollbar: "#777",
                    formButton: this.themingUtilsService.shade(accentColor, -0.24), // "rgba(desaturate($accent-color, 80%), 0.6)",
                    formInput: "#444546", // "lighten(#3a3a3a, 8%)",
                }, border: {
                    accessibility: "transparent", // border that is transparent in all themes other than high contrast theme
                    default: "rgba(255,255,255,0.16)",
                    button: "transparent", // border that is transparent in all themes other than high contrast theme
                    defaultNew: "#777",
                    inverted: "#777",
                    disabled: "#4e4e4e", // "lighten($panel-color, 8%)",
                }
            }
        };
    }
}
