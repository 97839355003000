<div *ngIf = "isPhone" class = "context-menu-header">
    <div class = "context-menu-header-content">
        <span *ngIf = "type == 'sub'" class = "context-menu-action-title">{{submenuItem.title}}</span>
        <span class = "context-menu-object-title">{{headerContent}}</span>
    </div>
    <div class = "context-menu-header-buttons">
        <button class = "button-transparent">
            <eob-icon *ngIf = "type == 'sub'" (click) = "goBack($event)"
                      class = "context-menu-back"
                      title = "{{'modal.button.back' | ngTranslate}}"
                      name = "back"
                      size = "16"></eob-icon>
        </button>
        <button class = "button-transparent">
            <eob-icon (click) = "closeMenu($event)"
                      class = "close-modal"
                      title = "{{'modal.close.title' | ngTranslate}}"
                      name = "close-modal"
                      size = "24"></eob-icon>
        </button>
    </div>
</div>

<ul>
    <li *ngFor = "let contextMenuAction of contextMenuActions" class = "context-menu-item"
        (mouseenter) = "onMouseEnter($event, contextMenuAction)" (click) = "onMouseEnter($event, contextMenuAction)"
        [attr.hasSubmenu] = "contextMenuAction.type == 'sub'">
        <a *ngIf = "contextMenuAction.type != 'divider'" (click) = "executeCallback($event, contextMenuAction)">
            <eob-icon class = "menuimage" name = "{{contextMenuAction.icon}}" size = "16"></eob-icon>
            <span class = "menutext">{{contextMenuAction.title}}</span>
        </a>
        <eob-icon name = "caret" size = "16" title = "" *ngIf = "contextMenuAction.type == 'sub'"></eob-icon>
        <a *ngIf = "contextMenuAction.type == 'divider'" class = "context-menu-divider"></a>
    </li>
</ul>