import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function customDecimalLength(maxLength: number, parser: (val: string|number) => string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const parsedLength = parser(control.value).length;
        // Stupid safeguard, as the validator is called twice, one of the calls containing the normalized value - go figure
        if (parsedLength > control.value.length && maxLength >= control.value.length) {
            return null;
        }
        return parsedLength > maxLength ?
            {maxlength: {requiredLength: maxLength, actualLength: parsedLength}} :
            null;
    };
}
