import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";

require("COMPONENTS_PATH/eob-right-click/eob.right.click.dir.js");
require("COMPONENTS_PATH/eob-touch-event-binder/eob.touch.event.binder.dir.js");
require("./eob-desktop-folder/eob.desktop.folder.dir.js");

require("SERVICES_PATH/eob.desktop.srv.js");

angular.module("eob.framework").directive("eobNavDesktop", EobNavDesktop);

EobNavDesktop.$inject = ["$rootScope", "$compile", "desktopService", "notificationsService", "clientService", "messageService", "contextMenuService", "environmentService"];

export default function EobNavDesktop($rootScope, $compile, DesktopService, NotificationsService, ClientService, MessageService, ContextMenuService, EnvironmentService) {
    return {
        restrict: "E",
        async link(scope, element) {
            scope.ready = false;
            scope.showMenu = function(item, event) {
                MessageService.broadcast(InlineDialogEvent.DISPLAY_CTX_ACTIONS, {
                    items: [item],
                    event: event.originalEvent,
                    contextData: {
                        context: "desktop",
                        title: item.name
                    }
                });

                DesktopService.markSelected(event)
            };

            await initAsync();
            scope.showAltLang = !EnvironmentService.uiLangIsObjectDefLang();
            scope.objectDefLang = EnvironmentService.getObjectDefinitionLanguage();
            scope.uiLang = EnvironmentService.getLanguage();

            async function initAsync() {

                try {
                    await DesktopService.initAsync()
                    scope.privateDesktopFolder = DesktopService.getPrivateDesktopFolder();
                    scope.publicDesktopFolder = DesktopService.getPublicDesktopFolder();
                    scope.ready = true;

                    ContextMenuService.registerContextMenuActionsProvider("desktop");
                } catch (error) {
                    if (error.type == "WEB_OFFLINE_ERROR") {
                        let handler = () => {
                            ClientService.unregisterConnectivityChangeHandler(handler);
                            initAsync();
                        };

                        ClientService.registerConnectivityChangeHandler(handler);
                    } else {
                        NotificationsService.customError(error);
                    }
                }
            }

            //panel height is needed as css attribute for opening and closing animation
            scope.getPanelHeight = function(desktop) {
                let height = 0;
                let desktopNav = $("eob-nav-desktop .nav-desktop-list");
                let el = (desktop == "private") ? desktopNav.first() : desktopNav.last();

                el.find("> li").each(function() {
                    height += $(this).outerHeight();
                });

                return { "height": `${height + 8}px` };
            };

            scope.$on("$destroy", () => {
                ContextMenuService.unregisterContextMenuActionsProvider("desktop");
            });
        },

        template: require("!raw-loader!./eob.nav.desktop.html")
    };
}
