<div id="main-fulltext" *ngIf="showFulltext">
    <div class="fulltext-dropdown-wrapper" [hidden]="isPhone">
        <eob-dropdown [dropdownconfig]="dropDownConfig" [attr.lang]="showAltLang ? objectDefLang : null"></eob-dropdown>
    </div>

    <div id="input-wrapper">
        <form (ngSubmit)="executeSearch()">
            <input name="fulltext"
                   id="fulltext-search-field"
                   [eobAutocomplete]="eobAutocomplete"
                   type="text" autocomplete="off"
                   placeholder="{{'eob.fulltext.search.global.placeholder' | ngTranslate}}"
                   [(ngModel)]="search.key">
        </form>
        <button [hidden]="!isPhone">
            <i (click)="executeSearch()"
               title="{{'eob.fulltext.search.global.execute.title' | ngTranslate}}"
               class="icon-24-nav-global-search fulltext-search-icon"></i>
        </button>
    </div>
    <button [hidden]="isPhone">
        <i (click)="executeSearch()"
           title="{{'eob.fulltext.search.global.execute.title' | ngTranslate}}"
           class="icon-24-nav-global-search fulltext-search-icon"
           role="img"></i>
    </button>
    <div class="fulltext-dropdown-wrapper" [hidden]="!isPhone">
        <eob-dropdown [dropdownconfig]="dropDownConfig" [attr.lang]="showAltLang ? objectDefLang : null"></eob-dropdown>
    </div>
</div>