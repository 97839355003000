import {Injectable} from "@angular/core";
import {Theme, ThemeGeneral, ThemeColors} from "./theme.interface";

@Injectable({
    providedIn: "root"
})

export class ThemingUtilsService{

    constructor() {}


    addStyleToDocument(cssTheme){
        Object.keys(cssTheme).forEach(property => {
            document.documentElement.style.setProperty(
                property,
                cssTheme[property]
            );
        });
    }

    // takes the chosen theme object and creates an object that contains all the according css Variables
    createCssVars(theme: Theme): any {
        const cssTheme: any = {};
        // convert some general properties like background picture
        this.addGeneralProperties(theme.general, cssTheme);
        this.addColorProperties(theme.color, cssTheme);

        return cssTheme;
    }

    private addGeneralProperties(properties: ThemeGeneral, cssTheme: any): void {
        for (const property in properties) {
            const cssVar: string = `--theme-${property}`;
            const propertyValue: string = properties[property];
            cssTheme[cssVar] = propertyValue;
            if (propertyValue.startsWith("#")) {
                // this propertyvalue is a hex color
                // we need an rgb version of this property
                const cssVarRgb: string = `--theme-${property}-rgb`;
                cssTheme[cssVarRgb] = this.hex2rgb(propertyValue);
            }
        }
    }

    private addColorProperties(properties: ThemeColors, cssTheme: any): void {
        // convert the colors sorted by type
        for (const type in properties) {
            for (const property in properties[type]) {
                const cssVar: string = `--color-${type}-${property}`;
                const propertyValue: string = properties[type][property];
                cssTheme[cssVar] = propertyValue;
                if (propertyValue.startsWith("#")) {
                    // this propertyvalue is a hex color
                    // we need an rgb version of this property
                    const cssVarRgb: string = `--color-${type}-${property}-rgb`;
                    cssTheme[cssVarRgb] = this.hex2rgb(propertyValue);
                }
            }
        }
    }

    private hex2rgb(hex: string): number[] {
        if (hex.charAt(0) === "#") {
            hex = hex.substr(1);
        }
        if ((hex.length < 2) || (hex.length > 6)) {
            return;
        }
        const values: string[] = hex.split("");
        let r: number;
        let g: number;
        let b: number;

        if (hex.length === 2) {
            r = parseInt(values[0].toString() + values[1].toString(), 16);
            g = r;
            b = r;
        } else if (hex.length === 3) {
            r = parseInt(values[0].toString() + values[0].toString(), 16);
            g = parseInt(values[1].toString() + values[1].toString(), 16);
            b = parseInt(values[2].toString() + values[2].toString(), 16);
        } else if (hex.length === 6) {
            r = parseInt(values[0].toString() + values[1].toString(), 16);
            g = parseInt(values[2].toString() + values[3].toString(), 16);
            b = parseInt(values[4].toString() + values[5].toString(), 16);
        } else {
            return;
        }
        return [r, g, b];
    }

    private hex2(c) {
        c = Math.round(c);
        c = c < 0 ? 0 : c;
        c = c > 255 ? 255 : c;

        let s = c.toString(16);
        s = s.length < 2 ? `0${s}` : s;

        return s;
    }

    private rgbToHexColor(r, g, b) {
        return `#${this.hex2(r)}${this.hex2(g)}${this.hex2(b)}`;
    }

    shade(color: string, light: number) {

        // TODO??? Assert that col is good and that -1 < light < 1

        let r = parseInt(color.substr(1, 2), 16);
        let g = parseInt(color.substr(3, 2), 16);
        let b = parseInt(color.substr(5, 2), 16);

        if (light < 0) {
            r = (1 + light) * r;
            g = (1 + light) * g;
            b = (1 + light) * b;
        } else {
            r = (1 - light) * r + light * 255;
            g = (1 - light) * g + light * 255;
            b = (1 - light) * b + light * 255;
        }

        return this.rgbToHexColor(r, g, b);
    }
}
