/**
 * Events that are called on the IpcRenderer.
 * Used for communication between webviews and other electron parts.
 */
export enum ElectronIpcEvent {
    IPC_MESSAGE = "ipc-message",
    EVENT_FOR_NAVIGATION = "event-for-navigation",
    STORE_SESSION_STORAGE = "store-session-storage",
    RETRIEVE_SESSION_STORAGE = "retrieve-session-storage",
    SET_DEBUG_MODE = "set-debug-mode",
    CLEAR_PAGE_BODY = "clear-page-body",
    CLEAR_PAGE_BODY_DONE = "clear-page-body-done",
    CLEAR_COOKIES = "clear-cookies",
    GET_TABS = "get-tabs",
    GET_TABS_RESULT = "get-tabs-result",
    UPDATE_AVAILABLE = "update-available",
    START_UPDATE = "start-update",
    ADD_EXTERNAL_TRAY_ITEM = "add-external-tray-item",
    INSERT_EMAILS_AT_LOCATION_RESULT = "insert-emails-at-location-result",
    IS_SYNCHRONIZING = "is-synchronizing",
    DOWNLOAD_AND_INSTALL_UPDATES = "download-and-install-updates",
    ADD_NEW_WINDOW_HANDLER = "add-new-window-handler",
    CALL_KEYTAR = "call-keytar",
    KEYTAR_RESULT = "keytar-result",
    RESTORE_WINDOW_SIZE_AND_POSITION = "restore-window-size-and-position",
    SET_CLIENT_INFOS = "set-client-infos",
    UNSET_CLIENT_INFOS = "unset-client-infos",
    GET_NTLM_AUTHENTICATION_COOKIE = "get-ntlm-authentication-cookie",
    GET_NTLM_AUTHENTICATION_COOKIE_CANCEL = "get-ntlm-authentication-cookie-cancel",
    START_UPDATE_FROM_RUNNING_CLIENT = "start-update-from-running-client",
    NOTIFY_UPDATE_AVAILABLE = "notify-update-available",
    CHECK_FOR_UPDATES = "check-for-updates",
    CHECK_FOR_UPDATES_RESULT = "check-for-updates-result",
    UPDATE_WITH_RESTART_WILL_START = "update-with-restart-will-start",
    READY_TO_DOWNLOAD_UPDATES = "ready-to-download-updates",
    EXEC = "exec",
    EXEC_RESULT = "exec-result",
    CLOSE_MAIN_WINDOW = "close-main-window",
    CHECKED_OUT_FILES_RESULT = "checked-out-files-result",
    CHECK_IN_DOCUMENT_RESULT = "check-in-document-result",
    UNDO_CHECKOUT_RESULT = "undo-checkout-result",
    OBJECT_TITLE_RESULT = "object-title-result",
    IS_CLIENT_OFFLINE_RESULT = "is-client-offline-result",
    EXTERNAL_TRAY_CREATE_METADATA_RESULT = "external-tray-create-metadata-result",
    EXTERNAL_TRAY_CHECK_CAN_INSERT_CONTENT_RESULT = "external-tray-check-can-insert-content-result",
    INSERT_EMAILS_CHECK_CAN_INSERT_RESULT = "insert-emails-check-can-insert-result",
    ENUMERATE_PRINTERS = "enumerate-printers",
    PRINT_PDF = "print-pdf",
    EXEC_PROGRAM_ASYNC = "exec-program-async",
    OPEN_LOCATION_RESULT = "open-location-result",
    OPEN_NEW_TAB = "open-new-tab",
    SET_TRANSLATION_STRINGS = "set-translation-strings",
    DEBUG_LOG = "debug-log",
    REFRESH_ALL_TABS = "refresh-all-tabs",
    WEBVIEW_GET_TAB_COUNT = "webview-get-tab-count",
    GET_INIT_PROFILES = "get-init-profiles",
    OPEN_RESULT_LIST_BY_IDS = "open-result-list-by-ids",
    EXECUTE_IN_SHELL_WORKER = "execute-in-shell-worker",
    OPEN_AUTH_WINDOW = "open-auth-window",
    CLOSE_AUTH_WINDOW = "close-auth-window",
    OPEN_AUTH_WINDOW_RESPONSE = "open-auth-window-response",
    CLOSE_LOCAL_HTTPD = "close-local-httpd",
    OPEN_LOCAL_HTTPD = "open-local-httpd",
    OPEN_LOCAL_HTTPD_RESPONSE = "open-local-httpd-response",
    LOCAL_HTTPD_AUTH_CODE_URI_RECEIVED = "local-httpd-auth-code-uri-received",
    PROPAGATE_AUTH_HEADERS = "propagate-auth-headers",
    AUTHENTICATION_WORKER_QUERY_IS_AUTHENTICATED = "authentication-worker-query-is-authenticated",
    AUTHENTICATION_WORKER_STATE_UPDATE = "authentication-worker-state-update",
    AUTHENTICATION_WORKER_AUTHENTICATE = "authentication-worker-authenticate",
    AUTHENTICATION_WORKER_ABORT_LOGIN = "authentication-worker-abort-login",
    AUTHENTICATION_WORKER_INVALIDATE_SESSION = "authentication-worker-invalidate-session",
    PURGE_AUTH_CLIENT_SESSION_DATA = "purge-auth-client-session-data",
    CLOSE_ALL_DETACHED_VIEWERS = "close-all-detached-viewers",
    BRING_APP_WINDOW_TO_FRONT = "bring-app-window-to-front",
    AFTER_TAB_RELOAD = "after-tab-reload",
    SET_OFFICE_DASHLET_ACCESS_TOKEN = "set-office-dashlet-access-token"
}

/**
 * Events that are called on the IpcRenderer and forwarded to the main tab.
 * Used for communication between webviews and other electron parts.
 */
export enum ElectronIpcMainTabEvent {
    WINDOW_MOVED = "window-moved",
    WINDOW_RESIZED = "window-resized",
    WINDOW_SHOULD_CLOSE = "window-should-close",
    CHECKED_OUT_FILES = "checked-out-files",
    CHECK_IN_DOCUMENT = "check-in-document",
    UNDO_CHECKOUT = "undo-checkout",
    OPEN_LOCATION = "open-location",
    OBJECT_TITLE = "object-title",
    EXTERNAL_TRAY_CREATE_METADATA = "external-tray-create-metadata",
    IS_CLIENT_OFFLINE = "is-client-offline",
}

/**
 * Events that are called on the IpcRenderer and forwarded to all tabs.
 * Used for communication between webviews and other electron parts.
 */
export enum ElectronIpcAllTabsEvent {
    EXTERNAL_TRAY_ELEMENTS_CHANGED = "external-tray-elements-changed",
    SET_RESULT_LIST_SELECTION = "set-result-list-selection-in-all-tabs",
    REFRESH_HITLIST = "refresh-hitlist-in-all-tabs",
    REFRESH_HITLIST_OBJECTS = "refresh-hitlist-objects-in-all-tabs",
    UPDATE_CACHE_MANAGER = "update-cache-manager",
    AUTHENTICATION_STATE_UPDATE = "authentication-state-update",
    AUTHENTICATION_WORKER_QUERY_IS_AUTHENTICATED_RESPONSE = "authentication-worker-query-is-authenticated-response",
    AUTHENTICATION_WORKER_AUTHENTICATE_RESPONSE = "authentication-worker-authenticate-response",
    POST_MESSAGE = "post-message"
}

/**
 * Events that are called on the IpcRenderer and forwarded to the active tab.
 * Used for communication between webviews and other electron parts.
 */
export enum ElectronIpcActiveTabEvent {
    SET_RESULT_LIST_SELECTION = "set-result-list-selection-in-active-tab",
    EXTERNAL_TRAY_CHECK_CAN_INSERT_CONTENT = "external-tray-check-can-insert-content",
    EXTERNAL_TRAY_ASK_FOR_VARIANT_VERSION = "external-tray-ask-for-variant-version",
    INSERT_EMAILS_CHECK_CAN_INSERT = "insert-emails-check-can-insert"
}
