import Dexie from "dexie";
import * as angular from "angular";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ContentObject} from "SHARED_PATH/models/eob.content.object";
import {OfflineDataStore} from "MODELS_PATH/eob.offline.data.model";
import {DmsContentStore} from "INTERFACES_PATH/dms-content-store.interface";
import {DmsDocumentStore} from "INTERFACES_PATH/dms-document-store.interface";
import {ContentType} from "ENUMS_PATH/content-type.enum";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ObjectSyncStatus} from "ENUMS_PATH/offline/offline-object-sync-status.enum";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {FileProperties} from "MODULES_PATH/dms/interfaces/file-properties.interface";
import { OfflineObjectSyncStatus } from "SHARED_PATH/models/offline-sync-status.model";
import {OfflineAvailability} from "INTERFACES_PATH/offline-cache.interface";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";

/** Query and transform data from the offline caches. */
export class OfflineCacheService {
    private dmsDocumentsCache: Dexie.Table<DmsDocumentStore, string>;
    private offlineDataCache: Dexie.Table<OfflineDataStore, string>;
    private dmsContentCache: Dexie.Table<DmsContentStore, string>;

    readonly MAX_PAGES: number = this.clientService.isiOs() ? 400 : 1000;

    static $inject: string[] = ["$injector", "fileCacheService", "environmentService", "objectTypeService", "errorModelService", "clientService"];

    // eslint-disable-next-line max-params
    constructor(private $injector: angular.auto.IInjectorService, private fileCacheService: FileCacheService,
                private environmentService: TodoEnvironmentService, private objectTypeService: ObjectTypeService,
                private errorModelService: ErrorModelService, private clientService: ClientService) {
    }

    init(): void {
        this.dmsDocumentsCache = this.fileCacheService.getDmsDocumentsTable();
        this.offlineDataCache = this.fileCacheService.getOfflineDataTable();
        this.dmsContentCache = this.fileCacheService.getDmsContentTable();
    }

    /** Check whether the indexdata of a dms object is cached. */
    async isObjectOfflineCached(osid: string): Promise<boolean> {
        return (await this.dmsDocumentsCache.where("osid").equals(osid).limit(1).count()) > 0;
    }

    /** Get the offline availability of all parts of a dms object (indexdata, original content, preview content). */
    async getOfflineAvailability(osid: string): Promise<OfflineAvailability> {
        if (!this.clientService.isLocalClient()) {
            return { indexdata: false, originalContent: false, previewContent: false };
        }

        const content: DmsContentStore = await this.dmsContentCache.where("osid").equals(osid).first();

        return {
            indexdata: await this.isObjectOfflineCached(osid),
            originalContent: content && content.original !== undefined,
            previewContent: content && (content.preview !== undefined || content.mimetype === "PDF")
        };
    }

    /** Get the offline cached DmsDocument for the given osid. */
    async getById(osid: string): Promise<DmsDocument> {
        return (await this.getByIds([osid]))[0];
    }

    /** Get the offline cached DmsDocuments for the given osids. */
    async getByIds(osids: string[]): Promise<DmsDocument[]> {
        const result: DmsDocument[] = [];
        if (osids === undefined || osids.length <= 0) {
            return result;
        }

        const offlineData: OfflineDataStore[] = await this.offlineDataCache.where("osid").anyOf(osids).toArray(),
            indexdataStore: DmsDocumentStore[] = await this.dmsDocumentsCache.where("osid").anyOf(osids).toArray();
        for (const data of offlineData) {
            const cachedDoc: DmsDocumentStore = indexdataStore.find(cache => cache.osid == data.osid);

            if (cachedDoc !== undefined) {
                result.push(this.createDmsDocument(data, cachedDoc));
            }
        }

        return result;
    }

    createDmsDocument(offlineData: OfflineDataStore, cachedDoc: DmsDocumentStore): DmsDocument {
        cachedDoc.indexdata.rights = offlineData.rights;
        return new DmsDocument(cachedDoc.indexdata, this.$injector);
    }

    /** Return all top level offline objects aka the cached favorites of the user. */
    async getMainOfflineObjects(): Promise<DmsDocument[]> {
        try {
            const offlineIds: string[] = await this.offlineDataCache.filter(entry => entry.favReferences.includes(entry.osid)).primaryKeys(),
                result: DmsDocument[] = await this.getByIds(offlineIds);

            // noinspection NestedConditionalExpressionJS
            return result.sort((objA: any, objB: any) => objA.osid === objB.osid ? 0 : (objA.osid < objB.osid ? -1 : 1));
        } catch (error) {
            console.error(`loading favorites from cache failed: ${error}`);
            return [];
        }
    }

    /** Get the content for the given osid. Returns undefined if the no content is cached or the content is empty. */
    getOriginalContentById = (osid: string) => this.getContentById(osid, ContentType.ORIGINAL);
    /** Get the content for the given osid. Returns undefined if the no content is cached or the content is empty. */
    getPreviewContentById = (osid: string) => this.getContentById(osid, ContentType.RENDITION);

    private async getContentById(osid: string, type: ContentType): Promise<ContentObject> {
        const cachedContent: DmsContentStore = await this.dmsContentCache.where("osid").equals(osid).first();
        if (cachedContent === undefined) {
            return;
        }

        const container: ContentObject = await ContentObject.parse(cachedContent[type]);
        if (container && container.files.length > 0) {
            return container;
        }

        if (type === ContentType.RENDITION && cachedContent.mimetype === "PDF") {
            return this.getOriginalContentById(osid);
        }
    }

    /** Get the amount of failed sync objects. */
    async getFailedSyncObjectsCount(): Promise<number> {
        return this.offlineDataCache.where("sync").equals(ObjectSyncStatus.FAILED).count();
    }

    /** Get the offline data for all failed sync objects. */
    async getFailedOfflineData(): Promise<OfflineDataStore[]> {
        return this.offlineDataCache.where("sync").anyOf(ObjectSyncStatus.FAILED).toArray();
    }

    /** Get the cache progress percentage for the given osids. */
    async getCacheProgresses(osids: string[]): Promise<Map<string, OfflineObjectSyncStatus>> {
        const result: Map<string, OfflineObjectSyncStatus> = new Map<string, OfflineObjectSyncStatus>();

        for (const osid of osids) {
            const syncStatus: OfflineObjectSyncStatus = await this.getCacheProgress(osid);
            result.set(osid, syncStatus);
        }

        return result;
    }

    /** Get the cache progress percentage for an offline object. */
    async getCacheProgress(osid: string): Promise<OfflineObjectSyncStatus> {
        const syncedItem: OfflineDataStore = await this.offlineDataCache.where("osid").equals(osid).first();
        let doneCount = 0, failedCount = 0, childCount: number;

        if (syncedItem === undefined
            || syncedItem.sync == ObjectSyncStatus.UPDATE) { // DODO-8343: Show updated folders or registers as not synchronized
            doneCount = 0; failedCount = 0;
        } else if (!syncedItem.children || syncedItem.children.length == 0) {
            doneCount = syncedItem.sync === ObjectSyncStatus.DONE ? 1 : 0;
            failedCount = syncedItem.isSyncFailed() ? 1 : 0;
            childCount = 0;
        } else { // check container children
            let children: OfflineDataStore[] = await this.offlineDataCache.where("favReferences").equals(osid).toArray();
            children = children.filter(child => child.osid !== osid);
            childCount = children.length;

            for (const child of children) {
                if (child.sync == ObjectSyncStatus.DONE) {
                    doneCount++;
                } else if (child.isSyncFailed()) {
                    failedCount++;
                }
            }
        }

        return new OfflineObjectSyncStatus(doneCount, failedCount, childCount);
    }

    /** Check whether cache data already exists for the given ids and update the reference data accordingly. */
    async updateCacheReferences(osids: string[]): Promise<void> {
        const offlineData: OfflineDataStore[] = await this.offlineDataCache.where("osid").anyOf(osids).toArray();
        offlineData.forEach(data => data.favReferences.includes(data.osid) || data.favReferences.push(data.osid));
        await this.cacheOfflineData(offlineData);
    }

    /** Allows to declare an object and its referenced objects as not synchronized after a local update. */
    async resetSynchronizationState(osid: string): Promise<void> {
        if (!osid || !this.clientService.isLocalClient() || (await this.clientService.getIsSynchronizing())) {
            return;
        }

        const cacheEntry: OfflineDataStore = await this.offlineDataCache.where("osid").equals(osid).first();
        if (cacheEntry !== undefined) {
            await this.offlineDataCache.where("osid").anyOf(cacheEntry.favReferences.concat(cacheEntry.osid)).modify({ sync: ObjectSyncStatus.UPDATE });

            // make sure other possibly open tabs showing offline objects get refreshed
            this.clientService.setIsSynchronizing(await this.clientService.getIsSynchronizing());
        }
    }

    /**
     * Check the offline data for the given osids and remove cache data accordingly.
     * - Don't remove offline data for documents, that are still referenced in another favorite register/folder.
     * - Additionally remove referenced children of register/folder, if they're not needed otherwise.
     */
    async removeOfflineObjects(idsToRemove: string[] = []): Promise<void> {
        const offlineData: OfflineDataStore[] = await this.offlineDataCache.toArray();

        // remove osid from favReferences
        const editedOfflineData: Set<OfflineDataStore> = new Set<OfflineDataStore>();
        for (const osid of idsToRemove) {
            for (const data of offlineData) {
                if (data.favReferences.includes(osid)) {
                    data.favReferences.splice(data.favReferences.indexOf(osid), 1);
                    editedOfflineData.add(data);
                }
            }
        }

        // remove all offline objects, that don't have any favReferences anymore
        const adjustedIdsToRemove: string[] = [];
        for (const data of editedOfflineData) {
            if (data.favReferences.length == 0) {
                adjustedIdsToRemove.push(data.osid);
                editedOfflineData.delete(data);
            }
        }

        this.cacheOfflineData(Array.from(editedOfflineData));
        return this.removeOfflineObjectsDirectly(adjustedIdsToRemove);
    }

    // region direct cache access
    async cacheOfflineData(offlineData: OfflineDataStore[], progressFn?: any): Promise<void> {
        if (offlineData.length == 0) {
            return;
        }

        for (let i = 0; i < offlineData.length;) {
            const part: OfflineDataStore[] = offlineData.slice(i, Math.min(i+=this.MAX_PAGES, offlineData.length));
            await this.offlineDataCache.bulkPut(part);
            progressFn?.();
        }
    }

    cacheDmsDocuments(entries: Map<string, any>): Promise<void> {
        entries.forEach( entry => {
            delete entry.rights; // the rights property is saved user specific, since it can differ globaly

            // boolean values are not indexable, therefor we convert hasContent to a number
            entry.hasContent = Number(entry.hasContent);
        });
        return this.cacheProperty(this.dmsDocumentsCache, "indexdata", entries);
    }

    async cacheContent(property: ContentType, osid: string, content: any, fileProperties?: FileProperties): Promise<void> {
        await this.fileCacheService.checkFreeSpace(Array.from(content));
        const value: any = {
            [property]: content
        };

        if (fileProperties) {
            value.mimetype = fileProperties.mimeTypeGroup;
        }

        return this.cacheData(this.dmsContentCache, new Map<string, any>([[osid, value]]));
    }

    /** Update a specifc property of existing entries in the offline caches or add new entries. */
    private async cacheProperty(cache: Dexie.Table<any, string>, property: string, entries: Map<string, any>): Promise<void> {
        const propEntries: Map<string, any> = new Map<string, any>();
        entries.forEach((value, key) => propEntries.set(key, {[property]: value}));
        return this.cacheData(cache, propEntries);
    }

    /** Update existing entries in the offline caches or add new entries. */
    async cacheData(cache: Dexie.Table<any, string>, entries: Map<string, any>): Promise<void> {
        const userId: number = this.environmentService.getSessionInfo().userid,
            ids: string[] = Array.from(entries.keys());

        const existingEntries: any[] = await cache.where("osid").anyOf(ids).toArray();
        for (let existingEntry of existingEntries) {
            existingEntry = Object.assign(existingEntry, entries.get(existingEntry.osid));

            if (existingEntry.userReferences.indexOf(userId) < 0) {
                existingEntry.userReferences.push(userId);
            }
        }

        for (let i = 0; i < existingEntries.length;) {
            const part: any[] = existingEntries.slice(i, Math.min(i+=this.MAX_PAGES, existingEntries.length));
            await cache.bulkPut(part);
        }

        const existingIds: string[] = existingEntries.map(d => d.osid);
        const missing: any[] = Array.from(entries).filter(entry => !existingIds.includes(entry[0])).map(entry => Object.assign({osid: entry[0], userReferences: [userId]}, entry[1]));

        for (let i = 0; i < missing.length;) {
            const part: any[] = missing.slice(i, Math.min(i+=this.MAX_PAGES, missing.length));
            await cache.bulkAdd(part);
        }
    }

    /** Add the user id to a specific cache entry, if it's not already part of it. */
    async addGlobalReference(cache: Dexie.Table<any, string>, osid: string): Promise<void> {
        const userId: number = this.environmentService.getSessionInfo().userid;
        const dataStore: Dexie.Collection<any, string> = cache.where("osid").equals(osid),
            data: any = await dataStore.first();

        // modify isn't used with a function directly, because it can lead to errors, when modifying objects with blobs
        if (!data.userReferences.includes(userId)) {
            await dataStore.modify({ userReferences: data.userReferences.concat(userId) });
        }
    }

    /** Remove the complete offline cache data for the given ids for the current profile. */
    async removeOfflineObjectsDirectly(idsToRemove: string[], progressFn?: any): Promise<void> {
        if (idsToRemove.length == 0) {
            return;
        }

        for (let i = 0; i < idsToRemove.length;) {
            const part: string[] = idsToRemove.slice(i, Math.min(i+=this.MAX_PAGES, idsToRemove.length));
            await this.offlineDataCache.where("osid").anyOf(part).delete();
            progressFn?.();
        }

        this.removeGlobalReferences(this.dmsDocumentsCache, idsToRemove);
        this.removeGlobalReferences(this.dmsContentCache, idsToRemove);
    }

    /** Remove the complete global offline cache data for the given or current profile. */
    async removeAllGlobalOfflineObjects(userId?: number): Promise<void> {
        userId = userId || this.environmentService.getSessionInfo().userid;
        if (userId == undefined) {
            throw new Error("Can't remove global offline objects without an user id!");
        }

        const cacheDocumentIds: string[] = await this.dmsDocumentsCache.where("userReferences").equals(userId).primaryKeys();
        await this.removeGlobalReferences(this.dmsDocumentsCache, cacheDocumentIds, userId);

        const cacheContentIds: string[] = await this.dmsContentCache.where("userReferences").equals(userId).primaryKeys();
        await this.removeGlobalReferences(this.dmsContentCache, cacheContentIds, userId);
    }

    /**
     * Remove the profile reference to the global indexdata and content of the given cached documents.
     * Delete the global data completly, if it's not needed by anyone anymore.
     */
    async removeGlobalReferences(cache: Dexie.Table<any, string>, idsToRemove: string[], userId?: number): Promise<void> {
        userId = userId || this.environmentService.getSessionInfo().userid;
        idsToRemove.sort();

        await this.pagedExecution(cache, entry => idsToRemove.includes(entry.osid), async (entries) => {
            const removeKeys: string[] = [], updatedEntries: any[] = [];

            for (const entry of entries) {
                if (entry.userReferences.length > 1) {
                    entry.userReferences.splice(entry.userReferences.indexOf(userId));
                    updatedEntries.push(entry);
                } else {
                    removeKeys.push(entry.osid);
                }
            }

            if (updatedEntries.length > 0) {
                await cache.bulkPut(updatedEntries);
            }

            if (removeKeys.length > 0) {
                await cache.bulkDelete(removeKeys);
            }
        }, entries => idsToRemove[idsToRemove.length - 1] < entries[entries.length - 1].osid);
    }

    async pagedExecution<T>(cache: Dexie.Table<T, string>, filterFn: any, executeFn: any, abortFn?: any): Promise<void> {
        let last = -1;

        while(true) {
            const result: T[] = await cache.where("osid").above(last).limit(this.MAX_PAGES).toArray();

            if (result.length == 0) {
                break;
            }

            last = (result[result.length - 1] as any).osid;
            await executeFn(result.filter(filterFn));

            if (abortFn(result)) {
                break;
            }
        }
    }
    // endregion
}
