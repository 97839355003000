import {Rights} from "ROOT_PATH/app/shared/interfaces/rights.interface";
import {ObjectSyncStatus} from "ENUMS_PATH/offline/offline-object-sync-status.enum";
import {ObjectSyncFailedStatus} from "ENUMS_PATH/offline/offline-object-sync-failed-status.enum";

/**
 * The model object of an offline data object.
 */
export class OfflineDataStore {
    static UPDATE_STATES: ObjectSyncStatus[] = [ObjectSyncStatus.NEW, ObjectSyncStatus.UPDATE];

    osid: string;
    objectTypeId: number; // Todo: Refactor to string. I don't know if this affects the indexedDB...
    sync: ObjectSyncStatus;
    failedState: ObjectSyncFailedStatus = ObjectSyncFailedStatus.NONE;
    lastmodified: number;
    favReferences: string[];

    rights: Rights;
    children: string[];

    /**
     * Create a new offline data model instance.
     *
     * @param {object} backendData - The osrest backend representation of an offline data object.
     * @param {ObjectSyncStatus} syncStatus - The sync status of the offline data object.
     */
    constructor(backendData: any, syncStatus: ObjectSyncStatus) {
        this.osid = backendData.id;
        this.objectTypeId = backendData.objectTypeId;
        this.sync = syncStatus;

        this.update(backendData);
    }

    update(backendData: any): boolean {
        let changed: boolean = false;

        for (const key of ["lastmodified", "favReferences", "children"]) {
            const oldValue: any = this[key], newValue: any = backendData[key];

            if (!changed) {
                if (oldValue != undefined && Array.isArray(newValue)) {
                    if (oldValue.length != newValue.length) {
                        changed = true;
                    } else {
                        oldValue.sort(); newValue.sort();
                        changed = !oldValue.every((value, index) => value === newValue[index]);
                    }
                } else if (oldValue != newValue) {
                    changed = true;
                }
            }

            this[key] = backendData[key];
        }

        return changed;
    }

    isSyncFailed(): boolean {
        return this.sync == ObjectSyncStatus.FAILED;
    }

    needsUpdate(): boolean {
        return OfflineDataStore.UPDATE_STATES.includes(this.sync);
    }

    setFailed(failedState: ObjectSyncFailedStatus): void {
        this.sync = ObjectSyncStatus.FAILED;
        this.failedState = failedState;
    }

    setDone(): void {
        this.sync = ObjectSyncStatus.DONE;
        this.failedState = ObjectSyncFailedStatus.NONE;
    }
}
