<!-- https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe -->

<iframe
    #iframeRef
    (load)="onLoad(iframeRef)"
    [title]="currentTitle"
    [src]="iframeUrl"
    sandbox="allow-scripts allow-same-origin allow-forms allow-presentation allow-modals allow-popups"
    referrerpolicy="unsafe-url"
    [name]="name"
    id="custom-dashlet-iframe"
></iframe>
