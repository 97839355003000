/**
 * Applications main configuration file
 */
angular.module("eob.client").constant("config", {
    "uri": {
        "serviceBase": "/osrest/api",
        "officeBase": "/office",
        "oswebBase": "/osweb"
    },
    "path": {
        "themeBase": "theme/",
        "pluginBase": "plugins/",
        "pluginStatesBase": "plugin-states/"
    },
    "configFiles": {
        "statesModel": "eo-client/configuration/states.model.json"
    }
});

// old config mapping to use for old backend
const configMappingOldBackend = {
    "com.os.odrt.search.enabled": "useObjectSearch",
    "com.os.osdrt.session.timeout": "sessionTimeout",
    "com.os.osdrt.abo.enabled": "actions.useAbo",
    "com.os.osdrt.autostart.workflow.open.inbox": "autoOpenWorkflow",
    "com.os.osdrt.baseparams.use": "form.useBaseParams",
    "com.os.osdrt.changepwd": "useChangePassword",
    "com.os.osdrt.usermanagement.enabled": "useUsermanagement",
    "com.os.osdrt.clipboard": "actions.useClipboard",
    "com.os.osdrt.desktop.edit": "desktop.edit",
    "com.os.osdrt.desktop.folder": "useDesktop",
    "com.os.osdrt.desktop.object": "desktop.showObjects",
    "com.os.osdrt.desktop.query": "desktop.showQueries",
    "com.os.osdrt.exportfiles.enabled": "actions.useFileExport",
    "com.os.osdrt.exporthits.delimiter": "export.delimiter",
    "com.os.osdrt.exporthits.enabled": "actions.useIndexdataExport",
    "com.os.osdrt.exporthits.exporthead": "export.exportHead",
    "com.os.osdrt.exporthits.quote": "export.quote",
    "com.os.osdrt.file.templatefilling": "import.useTemplateFilling",
    "com.os.osdrt.file.validate.map.C-Doc": "import.8", // Todo: Missing in new config
    "com.os.osdrt.file.validate.map.D-Doc": "import.2",
    "com.os.osdrt.file.validate.map.M-Doc": "import.5",
    "com.os.osdrt.file.validate.map.P-Doc": "import.3",
    "com.os.osdrt.file.validate.map.W-Doc": "import.4",
    "com.os.osdrt.file.validate.map.X-Doc": "import.1",
    "com.os.osdrt.file.validate.map.XML-Doc": "import.7",
    "com.os.osdrt.fulltext.use": "useFulltext",
    "com.os.osdrt.hitlist.document.doubleclick.action": "actions.doubleClickAction",
    "com.os.osdrt.hitlist.document.singlehit.action": "actions.singleHitAction",
    "com.os.osdrt.location.container.automatic": "location.showFullLocation",
    "com.os.osdrt.location.enable": "actions.useShowLocation",
    "com.os.osdrt.pdfmerge.enabled": "actions.useMergePdf",
    "com.os.osdrt.pdfmerge.type.ids": "export.allowedTypes",
    "com.os.osdrt.revisit.enabled": "actions.useRevisits",
    "com.os.osdrt.sendlink": "email.sendLink",
    "com.os.osdrt.smtpserver": "email.smtpServer",
    "com.os.osdrt.table.max.hitcount": "hitlist.maxsize",
    "com.os.osdrt.variant.administration.enabled": "actions.useVariants",
    "com.os.osdrt.workflow": "actions.useWorkflow",
    "com.os.osdrt.ui.default.shownavigation": "layout.showNavigation",
    "com.os.osdrt.disable.desktop.app.download": "disableDesktopAppDownload",
    "com.os.osdrt.ui.thousandseparator.en": "thousandSeparatorEn",
    "com.os.osdrt.ui.thousandseparator.fr": "thousandSeparatorFr",
    "com.os.osdrt.ui.thousandseparator.de": "thousandSeparatorDe",
    "com.os.osdrt.offline.rendition.additional.suffixes": "offline.rendition",
    "com.os.osdrt.office365": "useOffice365",

    // Undocumented from here on :)
    "com.os.osdrt.location.no.initial.tree.check": "location.noInitialTreeCheck", // DODO-4449, not used any longer
    "com.os.osdrt.enable.desktop.app.debugging": "enableDesktopAppDebugging" // DODO-7801
};

// new config mapping to use for microservice
const configMappingMicroservice = {
    "featureSwitches.search": "useObjectSearch",
    "connection.sessionTimeout": "sessionTimeout",
    "featureSwitches.subscription": "actions.useAbo",
    "featureSwitches.workflow.autoOpen": "autoOpenWorkflow",
    "featureSwitches.baseParams": "form.useBaseParams",
    "featureSwitches.changePassword": "useChangePassword",
    "featureSwitches.userManagement": "useUsermanagement",
    "featureSwitches.clipboard": "actions.useClipboard",
    "featureSwitches.desktop.edit": "desktop.edit",
    "featureSwitches.desktop.enabled": "useDesktop",
    "featureSwitches.desktop.references": "desktop.showObjects",
    "featureSwitches.desktop.queries": "desktop.showQueries",
    "featureSwitches.exportFiles": "actions.useFileExport",
    "featureSwitches.exportIndexData.delimiter": "export.delimiter",
    "featureSwitches.exportIndexData.enabled": "actions.useIndexdataExport",
    "featureSwitches.exportIndexData.includeHeaders": "export.exportHead",
    "featureSwitches.exportIndexData.quote": "export.quote",
    "featureSwitches.templateFilling": "import.useTemplateFilling",
    "validFileSuffixes.D_DOC": "import.2",
    "validFileSuffixes.E_DOC": "import.6",
    "validFileSuffixes.M_DOC": "import.5",
    "validFileSuffixes.P_DOC": "import.3",
    "validFileSuffixes.W_DOC": "import.4",
    "validFileSuffixes.X_DOC": "import.1",
    "validFileSuffixes.Xml_DOC": "import.7",
    "featureSwitches.fulltext": "useFulltext",
    "hitList.doubleClickAction": "actions.doubleClickAction",
    "hitList.singleHitAction": "actions.singleHitAction",
    "featureSwitches.location.showFull": "location.showFullLocation",
    "featureSwitches.location.enabled": "actions.useShowLocation",
    "featureSwitches.mergeAsPdf.enabled": "actions.useMergePdf",
    "featureSwitches.mergeAsPdf.objectTypeRestrictions": "export.allowedTypes",
    "featureSwitches.followUp": "actions.useRevisits",
    "featureSwitches.sendEmail.enabled": "email.sendLink",
    "featureSwitches.sendEmail.smtpServer": "email.smtpServer",
    "hitList.maxSize": "hitlist.maxsize",
    "featureSwitches.variantAdministration": "actions.useVariants",
    "featureSwitches.workflow.enabled": "actions.useWorkflow",
    "featureSwitches.initNavigationArea": "layout.showNavigation",
    "featureSwitches.nativeApp.download": "enableDesktopAppDownload",
    "thousandseparator.en": "thousandSeparatorEn",
    "thousandseparator.fr": "thousandSeparatorFr",
    "thousandseparator.de": "thousandSeparatorDe",
    "offline.rendition.additionalSuffixes": "offline.rendition",
    "office365": "useOffice365",

    // Undocumented from here on :)
    "featureSwitches.nativeApp.debugging": "enableDesktopAppDebugging",
};

// constant definition that defines a mapping from external config properties to an internal structure
angular.module("eob.client").constant("configMappingOldBackend", configMappingOldBackend);
angular.module("eob.client").constant("configMappingMicroservice", configMappingMicroservice);

// constant definition used for column definition inside the inboxes
let dmsDefault = [
    {
        columnType: "dmsIcon",
        type: "icon"
    }, {
        columnType: "mimeType",
        type: "icon"
    }, {
        columnType: "archiveState",
        type: "icon"
    }, {
        columnType: "lockState",
        type: "icon"
    }, {
        columnType: "links",
        type: "icon"
    }, {
        columnType: "signature",
        type: "icon"
    }, {
        columnType: "notes",
        type: "icon"
    }, {
        columnType: "annotations",
        type: "icon"
    }, {
        columnType: "favorite",
        type: "icon"
    }
];

let staticColumnConfig = {
    abo: dmsDefault.concat([{
        columnType: "confirmed",
        type: "icon"
    }, {
        columnType: "aboNotificationType",
        type: "icon"
    }, {
        columnType: "objectType",
        type: "text",
        colIndex: 4
    }, {
        columnType: "aboModifyDate",
        type: "datetime",
        colIndex: 5
    }, {
        columnType: "aboModifier",
        type: "datetime",
        colIndex: 6
    }, {
        columnType: "aboInfo",
        type: "text",
        colIndex: 7
    }]),
    subscriptionObjects: dmsDefault.concat([
        {
            columnType: "aboIndexdataModifier",
            type: "icon"
        },
        {
            columnType: "aboContentModifier",
            type: "icon"
        },
        {
            columnType: "aboLocationAddedModifier",
            type: "icon"
        },
        {
            columnType: "aboDeletionModifier",
            type: "icon"
        },
        {
            columnType: "aboServerNotification",
            type: "icon"
        },
        {
            columnType: "aboMailNotification",
            type: "icon"
        },
        {
            columnType: "aboObjectMovedNotification",
            type: "icon"
        },
        {
            columnType: "objectType",
            type: "text",
            colIndex: 4
        },
        {
            columnType: "aboInfo",
            type: "text"
        }
    ]),
    variants: [
        {
            columnType: "activeVariantIcon",
            type: "icon"
        }, {
            columnType: "variantVersion",
            type: "text"
        }, {
            columnType: "variantOf",
            type: "text"
        }, {
            columnType: "variantCreator",
            type: "text"
        }, {
            columnType: "variantCreated",
            type: "datetime"
        }
    ],
    revisit: dmsDefault.concat([
        {
            columnType: "confirmed",
            type: "icon"
        }, {
            columnType: "objectType",
            type: "text",
            colIndex: 4
        }, {
            columnType: "presentationDate",
            type: "datetime",
            colIndex: 5
        }, {
            columnType: "revisionCreator",
            type: "text",
            colIndex: 6
        }, {
            columnType: "comment",
            type: "text",
            colIndex: 7
        }
    ]),
    startable: [
        {
            columnType: "workflowIcon",
            type: "icon"
        }, {
            columnType: "workflowTitle",
            type: "text"
        }, {
            columnType: "workflowInfo",
            type: "text"
        }
    ],
    processes: [
        {
            columnType: "workflowIcon",
            type: "icon"
        }, {
            columnType: "workflowOvertime",
            type: "icon"
        }, {
            columnType: "workflowPersonalized",
            type: "icon"
        }, {
            columnType: "workflowClosuretime",
            type: "icon"
        }, {
            columnType: "workflowActivityName",
            type: "text"
        }, {
            columnType: "workflowSubject",
            type: "text"
        }, {
            columnType: "workflowProcessName",
            type: "text"
        }, {
            columnType: "workflowPersonalizedBy",
            type: "text"
        }, {
            columnType: "workflowCreationTime",
            type: "datetime"
        }, {
            columnType: "workflowWarningTime",
            type: "datetime"
        }, {
            columnType: "workflowClosureTime",
            type: "text"
        }
    ],
    workflow: [{
        columnType: "workflowIcon",
        type: "icon"
    }, {
        columnType: "workflowOvertime",
        type: "icon"
    }, {
        columnType: "workflowPersonalized",
        type: "icon"
    }, {
        columnType: "workflowSubstitute",
        type: "icon"
    }, {
        columnType: "workflowActivityName",
        type: "text"
    }, {
        columnType: "workflowCreationTime",
        type: "datetime"
    }, {
        columnType: "workflowProcessName",
        type: "text"
    }, {
        columnType: "workflowSubject",
        type: "text"
    }, {
        columnType: "workflowWarningTime",
        type: "datetime"
    }, {
        columnType: "workflowPersonalizedBy",
        type: "text"
    }],
    "hitlist.fulltextResult": [
        {
            columnType: "ranking",
            type: "text"
        }, {
            columnType: "preview",
            type: "text"
        }
    ],
    "hitlist.history": dmsDefault.concat([
        {
            columnType: "recentObjectAction",
            type: "icon"
        }, {
            columnType: "objectType",
            type: "text"
        }, {
            columnType: "historyLastModified",
            type: "text"
        }
    ]),
    wfFileArea: [
        {
            columnType: "dmsIcon",
            type: "icon"
        }, {
            columnType: "mimeType",
            type: "icon"
        }, {
            columnType: "location",
            type: "icon"
        }, {
            columnType: "archiveState",
            type: "icon"
        }, {
            columnType: "lockState",
            type: "icon"
        }, {
            columnType: "links",
            type: "icon"
        }, {
            columnType: "signature",
            type: "icon"
        }, {
            columnType: "notes",
            type: "icon"
        }, {
            columnType: "annotations",
            type: "icon"
        }, {
            columnType: "favorite",
            type: "icon"
        }, {
            columnType: "objectType",
            type: "text"
        }
    ],
    smartphoneDms: [
        {
            columnType: "dmsIcon",
            type: "icon"
        }, {
            columnType: "smartphoneTitleDescription",
            type: "phoneCellContent"
        }
    ],
    smartphoneNonDms: [
        {
            columnType: "workflowIcon",
            type: "icon"
        }, {
            columnType: "smartphoneWorkflowInfo",
            type: "phoneCellContent"
        }
    ],
    smartphoneOfflineObjects: [
        {
            columnType: "dmsIcon",
            type: "icon"
        }, {
            columnType: "smartphoneOfflineObjectsTitleDescription",
            type: "phoneCellContent"
        }
    ],
    "hitlist.offlineObjects": dmsDefault.concat([
        {
            columnType: "syncStatus",
            type: "icon"
        },
        {
            columnType: "objectType",
            type: "text"
        }
    ]),
    "hitlist.failedSyncObjects": dmsDefault.concat([
        {
            columnType: "failedSyncStatus",
            type: "icon"
        },
        {
            columnType: "objectType",
            type: "text"
        },{
            columnType: "failedSyncStatusInfo",
        },
    ]),
    objectReferences: dmsDefault.concat([
        {
            columnType: "referenceComment",
            type: "text"
        }
    ]),
    objectReferencesWithoutLinkNote: dmsDefault.concat([]),
    default: dmsDefault.concat([{
        columnType: "objectType",
        type: "text",
        colIndex: 4
    }]),
};

angular.module("eob.client").constant("staticColumnConfig", staticColumnConfig);

export default {staticColumnConfig};
