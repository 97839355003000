<div class="deduplication" *ngIf="emsDeduplicationOption">
    <div class="deduplication__info">
        {{'eob.modal.deduplication.options.info' | ngTranslate}}
    </div>
    <div class="deduplication__options">
        <button class="deduplication__options--item button-transparent" *ngIf="hasReferenceDocsRole && emsDeduplicationEnum.CREATE_REFERENCE_DOCUMENT === emsDeduplicationOption" (click)="selectOption(emsDeduplicationEnum.CREATE_REFERENCE_DOCUMENT)">{{'eob.modal.deduplication.create.reference' | ngTranslate}}</button>
        <button class="deduplication__options--item button-transparent" *ngIf="hasReferenceRole && emsDeduplicationEnum.ADD_LOCATION === emsDeduplicationOption" (click)="selectOption(emsDeduplicationEnum.ADD_LOCATION)">{{'eob.modal.deduplication.add.location' | ngTranslate}}</button>
        <button class="deduplication__options--item button-transparent"  (click)="selectOption(emsDeduplicationEnum.CREATE_COPY)">{{'eob.modal.deduplication.create.copy' | ngTranslate}}</button>
        <button class="deduplication__options--item button-transparent" (click)="selectOption()">{{'eob.modal.deduplication.skip.email' | ngTranslate}}</button>
    </div>
    <div>
        <eob-form *ngIf="checkboxFormdef" [formdef]="checkboxFormdef"></eob-form>
    </div>
</div>
<div class="deduplication__button-container">
    <div class="deduplication__remaining-time">{{'eob.modal.deduplication.remaining.time.message' | ngTranslate}}
        <div class="deduplication__remaining-time--countdown">
            <span *ngIf="currentTime && currentTime.days">{{ currentTime.days }}:</span>
            <span *ngIf="currentTime && currentTime.hours">{{ currentTime.hours }}:</span>
            <span *ngIf="currentTime && currentTime.minutes">{{ currentTime.minutes }}:</span>
            <span *ngIf="currentTime && currentTime.seconds">{{ currentTime.seconds }}</span>
        </div>
    </div>
</div>
