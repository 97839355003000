(function() {
    require("SERVICES_PATH/form/field-builder/eob.form.field.builder.srv.js");

    angular.module("eob.core").factory("formBuilder", FormBuilder);

    FormBuilder.$inject = ["formFieldBuilder"];

    function FormBuilder(FormFieldBuilder) {

        return {
            getDomElementByType,
        };

        // eslint-disable-next-line max-params
        function getDomElementByType(field, container, fieldMap = {}, layoutField = {}, formHelper = {}) {
            //this is needed because of angular and angularjs mix
            container = Array.isArray(container) ? container : angular.element(container);

            let model = field.model
            let domElement;
            // add the element to the dom
            if (model.type === "button") {
                console.error("old button creation attempted, please check");
            } else if (model.type === "radio") {
                console.error("old radio creation attempted, please check");
                return
            } else if (model.type === "checkbox") {
                console.error("old checkbox creation attempted, please check");
            } else if (model.type === "group") {
                console.error("old group creation attempted, please check");
                return
            } else if (model.type === "pagecontrol") {
                console.error("old page control creation attempted, please check");
                return
            } else if (layoutField.isLabel) {
                console.error("old label creation attempted, please check");
            } else if (model.type === "grid") {
                domElement = FormFieldBuilder.createGridField(field, container, formHelper);
            } else {
                console.error("old textfield creation attempted, please check");
            }

            return domElement;
        }
    }
})();
