(function () {
    "use strict";

    angular.module("eob.core").factory("subscriptionModelService", SubscriptionModelService);

    SubscriptionModelService.$inject = ["toolService"];

    function SubscriptionModelService(ToolService) {

        let service = {
            createSubscription,
            Subscription
        };
        return service;

        function createSubscription(backendNotification, documentModel) {
            return new service.Subscription(backendNotification, documentModel);
        }

        function Subscription(backendNotification, documentModel) {
            let self = this;

            self.model = {
                action: backendNotification.action,
                confirmed: backendNotification.confirmed,
                confirmationType: backendNotification.confirmationType,
                eventDate: backendNotification.eventDate,
                infoText: backendNotification.infoText,
                read: backendNotification.read,
                osid: backendNotification.id,
                modifier: documentModel.baseParameters.modifier,
                sender: {
                    fullname: backendNotification.sender.fullname,
                    name: backendNotification.sender.name,
                    id: backendNotification.sender.id
                },
                guid: ToolService.createGUID()
            }
        }
    }
})();
