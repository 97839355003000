(function () {
    "use strict";

    angular.module("eob.core").factory("subscriptionObjectModelService", SubscriptionObjectModelService);

    SubscriptionObjectModelService.$inject = ["toolService"];

    function SubscriptionObjectModelService(ToolService) {

        return {
            createSubscriptionObject
        };

        function createSubscriptionObject(backendNotification) {
            return new SubscriptionObject(backendNotification);
        }

        function SubscriptionObject(backendNotification) {
            let self = this;

            self.model = {
                aboGroup: backendNotification.aboGroup,
                actions: backendNotification.actions,
                confirm: backendNotification.confirm,
                infoText: backendNotification.infoText,
                notifyType: backendNotification.notifyType,
                mailAddresses: [], // muss noch in die backendNotification
                osid: backendNotification.objectId.toString(),
                groupsToBeNotified: backendNotification.groupsToBeNotified,
                usersToBeNotified: backendNotification.usersToBeNotified,
                guid: ToolService.createGUID()
            };
        }
    }
})();