import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {Observable} from "rxjs";
import {BackendHistoryObject} from "CORE_PATH/backend/interfaces/backend-history-object.interface";
import {Dms2ObjectService} from "CORE_PATH/backend/modules/dms2/services/object/dms2-object.service";
import {OsRestObjectService} from "CORE_PATH/backend/modules/osrest/services/object/osrest-object.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {BackendBaseService} from "CORE_PATH/backend/services/base/backend-base.service";
import {BackendInsertUpdateObject} from "CORE_PATH/backend/interfaces/insert-update/backend-insert-update-object.interface";
import {BackendObjectInserts} from "CORE_PATH/backend/interfaces/backend-object-insert.interface";
import {ObjectLink} from "CORE_PATH/backend/interfaces/object-link.interface";
import {IdPair} from "INTERFACES_PATH/id-pair.interface";

@Injectable({providedIn: BackendModule})
export class BackendObjectService extends BackendBaseService<Dms2ObjectService | OsRestObjectService> {

    constructor(messageService: MessageService, dms2ObjectService: Dms2ObjectService, private osRestObjectService: OsRestObjectService) {
        super(messageService, dms2ObjectService, osRestObjectService);
    }

    getObjectHistory(objectId: string): Observable<BackendHistoryObject[]> {
        return this.delegate.getObjectHistory(objectId);
    }

    // eslint-disable-next-line max-params
    moveDmsObject(objectId: string, targetId: string, sourceId: string, objectTypeId?: string, targetTypeId?: string, cabinetId?: string): Observable<void> {
        return this.delegate.moveDmsObject(objectId, targetId, sourceId, objectTypeId, targetTypeId, cabinetId);
    }

    deleteDmsObject(objectId: string, parentId: string, recursive: boolean): Observable<void> {
        return this.delegate.deleteDmsObject(objectId, parentId, recursive);
    }

    switchArchiveFlagDmsObject(objectId: string, switchToArchivable: boolean): Observable<void> {
        return this.delegate.switchArchiveFlagDmsObject(objectId, switchToArchivable);
    }

    setObjectTypeForTypelessDmsObject(payload: BackendInsertUpdateObject): Observable<void> {
        return this.delegate.setObjectTypeForTypelessDmsObject(payload);
    }

    getDmsObjectInserts(objectId: string, objectTypeId: string): Observable<BackendObjectInserts> {
        return this.delegate.getDmsObjectInserts(objectId, objectTypeId);
    }

    insertDmsObject(payload: BackendInsertUpdateObject, locationId: string, locationTypeId: string): Observable<string> {
        return this.delegate.insertUpdateDmsObject("insert", payload, locationId, locationTypeId);
    }

    insertWfTrayDmsObject(payload: BackendInsertUpdateObject): Observable<string> {
        return this.delegate.insertUpdateDmsObject("wfTray", payload, undefined, undefined);
    }

    insertGreenArrowDmsObject(payload: BackendInsertUpdateObject, locationId: string, locationTypeId: string): Observable<string> {
        return this.delegate.insertUpdateDmsObject("greenArrow", payload, locationId, locationTypeId);
    }

    updateDmsObject(payload: BackendInsertUpdateObject, locationId: string, locationTypeId: string): Observable<string> {
        return this.delegate.insertUpdateDmsObject("update", payload, locationId, locationTypeId);
    }

    /**
     * @returns {IdPair[][]} - All locations with their objectId and objectTypeId. If the object has no location undefined is returned.
     */
    getDmsObjectLocations(objectId: string, objectTypeId: string) : Observable<IdPair[][]> {
        return this.delegate.getDmsObjectLocations(objectId, objectTypeId);
    }

    addDmsObjectLocation(objectId: string, targetObjectId: string, objectTypeId: string, targetObjectTypeId: string): Observable<void> {
        return this.delegate.addDmsObjectLocation(objectId, targetObjectId, objectTypeId, targetObjectTypeId);
    }

    getReferenceDocuments(objectId: string): Observable<IdPair[]> {
        // Todo: Dms2 endpoint missing. Will be provided later.
        return this.osRestObjectService.getReferenceDocuments(objectId);
    }

    getNoteLinks(objectId: string): Observable<ObjectLink[]> {
        // Todo: Dms2 endpoint missing. Will be provided later.
        return this.osRestObjectService.getNoteLinks(objectId);
    }

    addNoteLink(sourceObjectId: string, targetObjectId: string, targetObjectTypeId: string, note: string): Observable<ObjectLink[]> {
        // Todo: Dms2 endpoint missing. Will be provided later.
        return this.osRestObjectService.addNoteLink(sourceObjectId, targetObjectId, targetObjectTypeId, note);
    }

    removeNoteLink(originalObjectId: string, referenceObjectId: string): Observable<ObjectLink[]> {
        // Todo: Dms2 endpoint missing. Will be provided later.
        return this.osRestObjectService.removeNoteLink(originalObjectId, referenceObjectId);
    }
}
