import {Component, OnInit} from "@angular/core";
import {TodoSearchService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {Inject} from "@angular/core";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {HitlistLoadingState} from "MODULES_PATH/hitlist/model/loading-state.model";
import {Subject} from "rxjs";

@Component({
    selector: "eob-fulltext",
    templateUrl: "./fulltext.component.html",
    styleUrls: ["./fulltext.component.css"]
})
export class FulltextComponent implements OnInit {
    loadingState: HitlistLoadingState = new HitlistLoadingState();
    hitlistItems: Subject<DmsDocument[]> = new Subject<DmsDocument[]>();

    constructor(
        @Inject("$location") private $location: ng.ILocationService,
        @Inject("searchService") private searchService: TodoSearchService,
        @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,) {
    }

    async ngOnInit(): Promise<void> {
        try {
            this.loadingState.isLoading = true;
            const data = this.stateHistoryManager.getStateData(this.$location.search().state).data;
            const hitlistItems: DmsDocument[] = await this.searchService.executeFulltextSearch(data);

            this.hitlistItems.next(hitlistItems);
        } catch {
            this.loadingState.hasErrors = true;
            this.loadingState.isLoading = false;
        } finally {
            this.loadingState.isLoading = false;
            this.loadingState.showHitlist = true;
        }
    }
}
