import {Action, createAction, props} from "@ngrx/store";

export interface NavAction extends Action{
    navType: string;
    payload: boolean;
}

// eslint-disable-next-line @typescript-eslint/typedef
export const updateNavigation = createAction(
    "[Navigation] Update Navigation",
    props<{navType: string; payload: boolean}>()
);
