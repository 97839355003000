import {NgModule, Optional, SkipSelf} from "@angular/core";

/**
 * A module for working with ecm objects.
 */
@NgModule({
    declarations: [],
    imports: [],
    providers: []
})
export class DmsModule {
    constructor(@Optional() @SkipSelf() serviceModule: DmsModule) {
        if(serviceModule) {
            throw new Error("Module is not to be used standalone");
        }
    }
}
