(function() {
    

    require("COMPONENTS_PATH/eob-detailsviewer/components/dv-markdown/dv.markdown.dir.js");

    angular.module("eob.framework").directive("dvComment", DvComment);

    DvComment.$inject = ["$log", "$filter", "$injector", "dvUsernameService", "dvNotesService", "dvDateService", "environmentService", "profileService"];

    function DvComment($log, $filter, $injector, DvUsernameService, DvNotesService, DvDateService, EnvironmentService, ProfileService) {
        return {
            restrict: "AE",
            replace: true,
            template: require("!raw-loader!./dv.comment.html"),
            link(scope, element, attr) {
                let initBar, deleteRequestBar, modifierBar, errorBar, editButton, deleteButton;
                let showContainer, buttonsDisabled, noteType;
                let note = scope.note;
                let notEditableString = $filter("translate")("error.note.eventnotpossible");
                let osid = attr["osid"];
                let editDisabled = attr["editDisabled"];
                scope.osid = osid;
                scope.editMode = false;
                scope.errmsg = $filter("translate")("error.note.delete");
                scope.creationDate = DvDateService.convertTimestamp(note.creationDate);
                let sending = false;

                init();

                function init() {

                    initHtmlElements();
                    if (note) {
                        setNoteSettings();
                    }
                    setNoteType();
                    setScopeFunctions();
                }

                function setNoteType() {
                    if (noteType) {
                        showContainer.remove(noteType);
                    }
                    noteType = note ? `type${note.color}` : 1;
                    showContainer.addClass(noteType);
                }

                function setNoteSettings() {
                    DvNotesService.registerNote({ "id": note.id, "cb": toggleButtonDisableState });
                    scope.username = DvUsernameService.getUsername(note.creator);
                    scope.type = parseInt(note.color);
                    scope.avatarUrl = `${ProfileService.getCurrentBaseUrl()}/osrest/api/organization/avatar/${EnvironmentService.getSessionInfo().username}`;
                    addLastEdit();
                    if (editDisabled) {
                        removeEditableState();
                    }
                }

                function initHtmlElements() {
                    let dvComment = element[0].children[0];
                    if (dvComment.className.indexOf("dv-comment") != -1) {
                        showContainer = angular.element(dvComment);
                    }
                    let contentContainer = dvComment.children[1];
                    if (contentContainer.className.indexOf("content") != -1) {
                        let contentChilds = contentContainer.children;
                        let childIndex = contentChilds.length;
                        while (childIndex--) {
                            let classname = contentChilds[childIndex].className;
                            if (classname.indexOf("init") != -1) {
                                initBar = angular.element(contentChilds[childIndex]);
                                editButton = angular.element(contentChilds[childIndex].children[0]);
                                deleteButton = angular.element(contentChilds[childIndex].children[1]);
                            } else if (classname.indexOf("delete") != -1) {
                                deleteRequestBar = angular.element(contentChilds[childIndex]);
                            } else if (classname.indexOf("modifier") != -1) {
                                modifierBar = angular.element(contentChilds[childIndex]);
                            } else if (classname.indexOf("error") != -1) {
                                errorBar = angular.element(contentChilds[childIndex]);
                            }
                        }
                    }
                    if (!showContainer || !initBar || !editButton || !deleteButton || !deleteRequestBar || !modifierBar || !errorBar) {
                        $log.error("Error! dv.comment.dir: Nicht alle Felder gefunden.");
                    }
                }

                function addLastEdit() {
                    if (note.date != note.creationDate) {
                        let text = $filter("translate")("app.btn.note.lastedit");
                        let date = DvDateService.convertTimestamp(note.date);
                        let user = DvUsernameService.getUsername(note.modifier);
                        scope.modified = `${text} ${date} - ${user}`;
                    } else {
                        modifierBar.css("display", "none");
                    }
                }

                function setScopeFunctions() {
                    scope.requestDelete = requestDelete;
                    scope.cancelDelete = cancelRequest;
                    scope.deleteNote = deleteNote;
                    scope.startEditMode = startEditMode;
                    scope.endEditMode = toggleEditMode;
                    scope.reload = reload;
                }

                function requestDelete() {
                    if (buttonsDisabled || editDisabled) {
                        return;
                    }
                    DvNotesService.switchEditModeOnRegisteredNotes(note.id, false);
                    toggleRequestbarVisibility(initBar, deleteRequestBar);
                }

                function cancelRequest() {
                    DvNotesService.switchEditModeOnRegisteredNotes(note.id, true);
                    toggleRequestbarVisibility(deleteRequestBar, initBar);
                }

                function deleteNote() {
                    if (sending || editDisabled) {
                        return;
                    }
                    sending = true;
                    DvNotesService.deleteNote(osid, note.id).then((res) => {
                        scope.refreshNotesData();
                        return;
                    }, (reason) => {
                        showError(reason);
                    }).catch((reason) => {
                        showError(reason);
                    });
                }

                function showError(reason) {
                    deleteRequestBar.addClass("invisible");
                    errorBar.removeClass("invisible");
                }

                function reload() {
                    initBar.removeClass("invisible");
                    errorBar.addClass("invisible");
                    scope.refreshNotesData();
                }

                function toggleButtonDisableState(isEnable) {
                    if (isEnable) {
                        buttonsDisabled = false;
                        editButton.removeClass("disabled");
                        deleteButton.removeClass("disabled");
                        editButton.addClass("enabled");
                        deleteButton.addClass("enabled");
                        editButton.attr("title", "");
                        deleteButton.attr("title", "");
                    } else {
                        buttonsDisabled = true;
                        editButton.addClass("disabled");
                        deleteButton.addClass("disabled");
                        editButton.removeClass("enabled");
                        deleteButton.removeClass("enabled");
                        editButton.attr("title", notEditableString);
                        deleteButton.attr("title", notEditableString);
                    }
                }

                function toggleRequestbarVisibility(currentVisibleBar, nextVisibleBar) {
                    nextVisibleBar.removeClass("invisible");
                    currentVisibleBar.addClass("invisible");
                }

                function removeEditableState() {
                    if (editDisabled) {
                        editButton.addClass("invisible");
                        editButton.removeClass("enabled");
                        deleteButton.addClass("invisible");
                        deleteButton.removeClass("enabled");
                    }
                }

                function startEditMode() {
                    if (buttonsDisabled || editDisabled) {
                        return;
                    }
                    DvNotesService.switchEditModeOnRegisteredNotes(note.id, false);
                    toggleEditMode();
                }

                function toggleEditMode() {
                    scope.editMode = !scope.editMode;
                    if (scope.editMode) {
                        showContainer.addClass("invisible");
                    } else {
                        showContainer.removeClass("invisible");
                        DvNotesService.switchEditModeOnRegisteredNotes(note.id, true);
                    }
                }
            }
        };
    }
})();

