<span id = "text-width-inspector"></span>

<div id = "browserError" style = "display: none;"></div>

<div id = "eob-splash">
    <div class = "eob-splash-content">
        <img class = "splash-image" src = "./assets/enaio-logo-large.svg" alt="enaio splash screen">
        <progress class = "eob-splash-progress" max = "100" value = "0" style = "--progress-value: 0%"></progress>
        <span id = "loadingMessage"></span>
    </div>
</div>
<div id = "eob-sad-page" *ngIf = "showSadPage">
    <div class = "sad-page-content">
        <img class = "splash-image" src = "./assets/broken-enaio-logo.svg">
        <span class = "static-error-text" role="status">{{"eob.static.sad.page.text" | ngTranslate}}</span>
        <span [innerHTML] = "initErrorMessage"></span>
        <button (click) = "logout()">{{"eob.login.login" | ngTranslate}}</button>
    </div>
</div>
<div id = "eob-idle-page">
    <div class = "eob-idle-page-container">
        <h1></h1>
        <!--<img src="theme/enaio-bluebird-still-there.svg">-->
        <div class = "time-span"></div>
        <div class = "what-to-do"></div>
    </div>
</div>
<div *ngIf="!viewerReady" class = "swipe-nav">
    <span>
        <eob-icon name = "footer-forward" size = "32"></eob-icon>
    </span>
</div>

<eob-profile-manager *ngIf = "shouldShowProfileManager" init-error-message = "initErrorMessage"></eob-profile-manager>
<eob-demo-contact *ngIf = "isDemo"></eob-demo-contact>
<eob-main *ngIf = "webclientReady" eob-global-events eob-autologout-timer ui-view = "main"></eob-main>
<eob-detached-dashlet-container *ngIf = "viewerReady" eob-global-events></eob-detached-dashlet-container>
