// noinspection NpmUsedModulesInstalled
import {MultiEntryMask} from "../../../../app/modules/form/models/field-masks/multi-entry-mask.model";
import {Broadcasts} from "../../../../app/shared/enums/broadcasts.enum";

require("SERVICES_PATH/form/field-builder/eob.form.field.builder.srv.js");

const he = require("he");

angular.module("eob.core").factory("commonApiService", CommonApiService);

CommonApiService.$inject = ["$timeout", "$rootScope", "valueUtilsService", "formFieldBuilder", "clientScriptService",
    "messageService", "autoCompleteService", "formAddonService", "newTreeAddonService"];

// eslint-disable-next-line max-params
export default function CommonApiService($timeout, $rootScope, ValueUtilsService, FormFieldBuilder, ClientScriptService,
                                         messageService, autoCompleteService, formAddonService, newTreeAddonService) {

    return {
        addCommonFieldsApi
    };

    function addCommonFieldsApi(api, field, formHelper) {

        let fieldType = field.model.type;

        if (fieldType === "button" || fieldType === "pagecontrol" || fieldType === "grid" || fieldType === "group") {
            return;
        }

        Object.defineProperty(field.model, "isInitiallyRequired", {
            enumerable: true,
            value: field.model.isRequired
        });

        if (field.model.type !== "checkbox" && field.model.type !== "radio") {
            api.createPseudoCatalog = function(catalogDefinition, dataCallback) {
                if (!isValidPseudoCatalogDefinition(catalogDefinition, dataCallback)) {
                    return;
                }

                let listEntries = [];
                let def = angular.copy(catalogDefinition);
                // this is the default configuration the user might overwrite
                def.config = mapPseudoCatalogDefinition(def);

                field.model.hasPseudoCatalog = true;
                field.model.pseudoCatalogDefinition = def;
                field.model.pseudoCatalogDataCallback = dataCallback;
                
                def.promise = new Promise(((resolve) => {
                    dataCallback(field, field.model.value, (response) => {
                        listEntries = response;
                        resolve(response);
                    });
                }));

                field.model.addon = catalogDefinition.type;
                field.model.control.eobOptions.addonConfig = formAddonService.getInputAddonConfig(field, formHelper);

                field.model.control.addMask(new MultiEntryMask([def.config.multiselectionSeparator]));

                let autoCompleteField = field;
                if (def.config.intermediate) {
                    //autocomplete does only know intermediates in addons that are labeled as hierarchy
                    autoCompleteField.model.addon = "hierarchy";
                }
                autoCompleteField.model.tree = {
                    config: def.config,
                    nodes: listEntries
                };

                autoCompleteService.addAutoComplete(autoCompleteField, formHelper);
                messageService.broadcast(Broadcasts.DETECT_CHANGES)
            };
        }

        /**
         * Get the value of the field.
         * @param {boolean} transform - Whether the value shall be transformed further (i.e. timestamps, organisation objects).
         * @returns {string} The transformed value.
         */
        api.getValue = transform => transformValue(field.value, transform, true);

        /**
         * Get the initial value of the field.
         * @param {boolean} transform - Whether the value shall be transformed further (i.e. timestamps, organisation objects).
         * @returns {string} The transformed initial value.
         */
        api.getInitialValue = transform => transformValue(field.initialValue, transform);

        /**
         * Get a transformed value of the field.
         * @param {*} value - The value, that shall be transformed.
         * @param {boolean} transform - Whether the datestring shall be transformed to a timestamp.
         * @param {boolean=} useOrgValues - Whether the value shall be transformed further (i.e. timestamps, organisation objects).
         * @returns {string} The transformed value.
         */
        function transformValue(value, transform, useOrgValues) {
            if (value == void 0 || value === "") {
                return "";
            }

            if (!transform) {
                return value.toString();
            }

            if (field.model.type === "date") {
                return ValueUtilsService.convertToTimestamp(value, false, !!formHelper.isWorkflow);
            }

            if (field.model.type === "datetime") {
                return ValueUtilsService.convertToTimestamp(value, true, !!formHelper.isWorkflow);
            }

            if (field.model.addon === "organisation") {
                let tmpArr = [],
                    orgValues = field.orgValues;

                if (!useOrgValues) {
                    let orgNames = value.split(";");
                    orgValues = field.model.config.orgMember.filter(member => orgNames.indexOf(member.name) >= 0);
                }

                for (let orgValue of orgValues) {
                    tmpArr.push(orgValue.id);
                }

                return tmpArr.join(";");
            }

            if (field.model.isUnicode) {
                return he.encode(value);
            }

            return value.toString();
        }

        /**
         * Get the requirement flag of a field
         * @param {boolean} initial Whether the initial requirement status should be returned
         * @return {boolean} Current or initial requirement flag
         */
        api.getRequired = (initial) => {
            return initial ? field.model.isInitiallyRequired : field.model.isRequired;
        };

        /**
         * Sets a field as (not) required. Note that fields set as required inside the object definition can't be made optional.
         * Radio buttons always have a set value, hence they are implicitely required.
         * Checkboxes also always have their current state as a value.
         * @param {boolean} isRequired whether the field should be required
         * @return {boolean} Requirement property of the field
         */
        api.setRequired = (isRequired) => {
            if (field.model.isInitiallyRequired || field.model.type === "radio" || field.model.type === "checkbox") {
                return true;
            }

            field.model.isRequired = isRequired;
            const el = api.getElement();
            const inputLabel = document.querySelector(`eob-form eob-form-element[internal='${el.attr("internal")}'] eob-form-static-text.form-label`);

            if (inputLabel) {
                if (isRequired) {
                    inputLabel.classList.add("required");
                } else {
                    inputLabel.classList.remove("required");
                    field.api.setValid();
                }
            }
            if(field.model.control) {
                field.model.control.markAsDirty();
                field.model.control.setRequired(isRequired);
            }

            return field.model.isRequired;
        };

        /**
         * Sets a new value. Overwritten for radiobuttons.
         * @param value - The new value
         * @param executeOnChangeScript - Boolean whether the script shall be executed or not, default is false
         */
        api.setValue = function(value, executeOnChangeScript) {
            // DODO-15875: force string representation for most field types
            if (field.model.type !== "checkbox" && field.model.type !== "radio") {
                value = value.toString();
            }

            if (field.model.control) {
                // The control should already have set the field to be invalid, but let's trigger it just in case
                field.model.control.setValue(value);
                field.model.control.markAsDirty();
                field.model.control.updateValueAndValidity();

                let el = api.getElement();
                let input = el.is("input, textarea") ? el : el.find("input, textarea");

                if (input && input.length > 0) {
                    input.trigger("change", [!!executeOnChangeScript]);
                } else if (executeOnChangeScript && field.eventScripts !== void 0 && typeof (field.eventScripts.onChange) == "function") {
                    field.eventScripts.onChange(formHelper, formHelper.globals, ClientScriptService.getGlobalScriptingStorage(), field);
                }

                // DODO-15452: If the field value is altered by scripting e.g. in onShow event, the field value
                // has to be set here to reflect the value in the index data mask.
                field.value = value
            } else {
                console.warn("unsupported", field);
            }
        };

        /** this is the default blur callback used to validate the field on focus out */
        api.bindDefaultOnChange = function() {
            let el = api.getElement();
            let input = el.is("input, textarea") ? el : el.find("input, textarea");

            // only bind onchange for textfields
            if (input && input.length > 0) {
                if (field.model.onChangeInitialized) {
                    return;
                }
                field.model.onChangeInitialized = true;

                input.bind("change", (event, executeOnChangeScript) => {
                    _onChange(field.model.control.value, executeOnChangeScript !== false);
                });
            }

            function _onChange(value, executeOnChangeScript) {

                if (value !== "" && field.model.addon && field.model.tree) {
                    field["selectedItems"] = newTreeAddonService.getTreeAddonSelectedItems(value, field.model.addon, field.model.tree.nodes, field.model.tree.config, formHelper.isSearch);
                }

                if (field.value === value) {
                    return;
                }

                field.value = value;

                syncParameterValue();

                if (api.validate && field.validationMode !== "min") {
                    // No manual change handling required for fields having a FormControl but without any custom validations
                    if(!field.model.control || field.customValidations || field.model.addon) {
                        api.validate()
                    }
                }

                if (executeOnChangeScript && field.eventScripts && typeof (field.eventScripts.onChange) === "function") {
                    field.eventScripts.onChange(formHelper, formHelper.globals, ClientScriptService.getGlobalScriptingStorage(), field);
                }

                setTimeout(() => {
                    $rootScope.$apply();
                }, 0);
            }
        };

        /**
         * Set the value of a linked parameter to the same value as the field.
         */
        api.syncParameterValue = syncParameterValue;
        function syncParameterValue() {
            if (formHelper.getParameters == void 0) {
                return;
            }

            let params = formHelper.getParameters();
            let paramId = Object.keys(params).find(id => params[id].model.fieldId == field.model.internal);

            if (paramId != void 0) {
                let param = params[paramId];

                if (param.model.type !== "grid") {
                    param.api.setValue(field.value, false);
                } else if (field.model.addon === "organisation") {
                    param.api.setRows(field.orgValues.map(value => [value.id, value.name]));
                }
            }
        }

        function mapPseudoCatalogDefinition(def) {
            return {
                hideValues: def.hideValues != void 0 ? def.hideValues : false,
                shortValue: def.useShortValues != void 0 ? def.useShortValues : false,
                useMultiSelect: def.useMultiSelect != void 0 ? def.useMultiSelect : false,
                useCheckboxSelection: def.useCheckboxSelection != void 0 ? def.useCheckboxSelection : false,
                intermediate: def.useIntermediateNodes != void 0 ? def.useIntermediateNodes : false,
                separator: def.separator != void 0 ? def.separator : "|",
                multiselectionSeparator: def.multiselectionSeparator != void 0 ? def.multiselectionSeparator : ";",
                sorted: def.sortEntries ? def.sortEntries : false,
                readonly: def.readonly ? def.readonly : false,
                validate: true
            };
        }
    }

    function isValidPseudoCatalogDefinition(catalogDefinition, dataCallback) {
        if (catalogDefinition == void 0 || typeof (catalogDefinition) != "object") {
            console.warn("The catalog definition is not valid.");
            return false;
        }

        let validTypes = ["tree", "list", "hierarchy"];
        if (catalogDefinition.type == void 0 || validTypes.indexOf(catalogDefinition.type) < 0) {
            console.warn("The catalog type is not supported -->", catalogDefinition.type);
            return false;
        }

        if (dataCallback == void 0 || typeof (dataCallback) != "function") {
            console.warn("The callback function is not valid.");
            return false;
        }

        return true;
    }
}
