import {Subscription} from "rxjs";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import { filter } from "rxjs/operators";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";

require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/form/eob.form.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/eob.modal.dialog.srv.js");
require("SERVICES_PATH/form/field-api/eob.form.field.api.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

angular.module("eob.framework").compileProvider.directive("eobSearch", EobSearch);

EobSearch.$inject = ["$timeout", "$filter", "$q", "$stateParams", "$location", "$rootScope",
    "objectTypeService", "stateHistoryManager", "formService", "environmentService", "formHelper", "backendService", "cacheManagerService",
    "modalDialogService", "viewerService", "progressbarService", "clientService", "clientScriptService", "messageService"];

/* eslint-disable */
/**
 * This directive is the wrapping directive for the object search
 * Its purpose is to get the Objecttype definition and further details
 * needed for this state (see /eob-core/state.history.manager.srv.js)
 */
export default function EobSearch($timeout, $filter, $q, $stateParams, $location, $rootScope,
                                  ObjectTypeService, StateHistoryManager, FormService, EnvironmentService, FormHelper, BackendService, CacheManagerService,
                                  ModalDialogService, ViewerService, ProgressbarService, ClientService, ClientScriptService, MessageService) { /* eslint-enable */
    return {
        restrict: "E",
        async link(scope, element) {

            $timeout(() => {
                MessageService.broadcast("show.viewer", false);
            }, 0);

            let stateId = $location.search().state;
            let useSearch = EnvironmentService.env.useObjectSearch &&
                EnvironmentService.userHasRole("R_CLNT_ARC_BAR") &&
                EnvironmentService.userHasRole("R_CLNT_EXECUTE_INQUIRY");

            let objectType = CacheManagerService.objectTypes.getById($stateParams.objectTypeId);
            let progressbar, beforeOpenExecuted = false;
            let helperConfig;
            let scriptMap = {}
            let lastState = StateHistoryManager.getStateData($location.search().state);
            let subscription = new Subscription();

            scope.isPhone = ClientService.isPhone();
            scope.showSettings = !scope.isPhone && lastState.data.config.showSettings;
            scope.currentObjectTypeId = $stateParams.objectTypeId;
            scope.stateDescription = objectType.model.config.title;
            scope.settingsTabDescription = "";

            scope.showAltLang = !EnvironmentService.uiLangIsObjectDefLang();
            scope.objectDefLang = EnvironmentService.getObjectDefinitionLanguage();

            scope.formHelper = null;
            scope.searchDefs = [];

            scope.scriptsReady = false;

            scope.fulltext = {
                useFullText: objectType.model.config.useFullText,
                query: ""
            };

            scope.areaToggleStates = {};
            scope.areaToggleStates[$stateParams.objectTypeId] = {
                objectTypeOpen: true,
                hasBaseparams: false,
                baseParamsOpen: false,
                fulltextOpen: false
            }

            scope.isPhone = ClientService.isPhone();

            scope.fulltext.useFulltext = objectType.model.config.useFullText && EnvironmentService.isFulltextAvailable();

            subscription.add(MessageService.subscribe(FormEvent.FORM_READY, async () => {
                if (beforeOpenExecuted) {
                    return;
                }

                scope.formHelper.focusFirstField();
                beforeOpenExecuted = true;

                try {
                    for (let searchDef of scope.searchDefs) {
                        await FormService.executeFormScript("beforeOpen", searchDef.formDef.formHelper)
                    }

                    progressbar = null;
                    scope.scriptsReady = true;
                    scope.$apply()
                } catch (err) {
                    // result code -2 tell the webclient not to take any kind of action after promise rejection
                    if (err != -2) {
                        // Firefox so slow, is funny to me
                        setTimeout(() => {
                            ClientService.executeStateErrorFallback()
                        }, 0);
                    }
                }
            }));

            subscription.add(MessageService.subscribe(FormEvent.UPDATE_FULLTEXT_QUERY, (config) => {
                scope.fulltext.query = config.fulltext;
                scope.searchDefs.find(searchDef => searchDef.objectTypeId == config.objectTypeId).fulltext = scope.fulltext.query;
            }));

            subscription.add(MessageService.subscribe(FormEvent.TOGGLE_SEARCH_SETTINGS, () => {
                scope.showSettings = !scope.showSettings;
                toggleSearchSettings();
            }));

            // In production mode, the directive gets initialized twice, hence breaking the first execution due to missing field API
            // This is a quick fix for a more serious issue (to be fixed in DODO-12446)
            MessageService.getObservable().pipe(filter(x => x.type == FormEvent.SEARCH_DESTROYED)).subscribe(_ => subscription.unsubscribe());

            ViewerService.clearViewer();
            showViewer(false);
            initFooterConfig();
            initFooterSearchSetting();
            initProgressbar();
            toggleSearchSettings();

            function initFooterSearchSetting() {
                scope.footerSearchSettingsConfigs = [
                    {
                        icon: {
                            name: "footer-done",
                            title: $filter("translate")("form.footer.submit.search")
                        },
                        action: "toggleSearchSettings",
                        class: "footer-button"
                    }
                ]
            }

            function initFooterConfig() {
                scope.footerConfigs = [{
                    icon: { name: "footer-search", title: $filter("translate")("form.footer.submit.search") },
                    action: "submit",
                    class: "footer-button"
                }]
            }

            function initProgressbar() {
                progressbar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);
                setTimeout(() => {
                    if (progressbar != void 0) {
                        progressbar.show();
                    }
                }, 100);
            }

            scope.closeSettings = function() {
                MessageService.broadcast(FormEvent.TOGGLE_SEARCH_SETTINGS);
            }

            scope.onSearchSettingsChanged = async function($event) {
                if ($event.type) {
                    await updateSearchMask($event);
                } else {
                    scope.settingsTabDescription = $event;
                }
            }

            if (lastState.data.combinedFormData) {
                scope.isFulltextOpen = lastState.data.fulltextValues[Number(scope.currentObjectTypeId)] !== "";
                await restoreSearches(lastState.data)
            } else {
                scope.searchDefs.push(await createSearchDef(objectType.model.config.objectTypeId))
            }

            //init children if data is ready
            $timeout(() => {
                scope.dataReady = true;
                // bindResizeSensor();
            }, 0)

            async function restoreSearches(data) {

                let combinedFormData = data.combinedFormData;
                let formDefOrder = data.formDefOrder;
                let fulltextValues = data.fulltextValues;
                for (let objectTypeId of formDefOrder) {
                    let searchDef = await createSearchDef(objectTypeId)

                    scope.searchDefs.push(searchDef)

                    let formData = searchDef.formDef.formHelper.getFields();
                    let baseParamFields = searchDef.baseparamFormDef.formFields;
                    let typeHasBaseParams = false;
                    searchDef.fulltext = fulltextValues[objectTypeId]
                    let objectTypeSettings;
                    try {
                        objectTypeSettings = data.searchSettings.objectTypes.find((type) => {
                            return type.objectTypeId == objectTypeId
                        });
                    } catch (e) {
                        console.warn(e);
                        objectTypeSettings = [];
                    }

                    if (combinedFormData[objectTypeId] != void 0 && Object.keys(combinedFormData[objectTypeId]).length !== 0) {
                        let type = combinedFormData[objectTypeId];
                        for (let internal in formData) {
                            let lastField = type[internal];

                            if (lastField) {
                                if ((formData[internal].model.addon == "date") && (typeof lastField.value === "string") && (lastField.value.indexOf("-") != -1)) {
                                    let split = lastField.value.split("-");
                                    formData[internal].value = split[0];
                                    formData[internal].to = split[1];
                                } else {
                                    formData[internal].value = lastField.value;

                                    if (lastField.gridData) {
                                        formData[internal].gridData = lastField.gridData;
                                    }
                                }
                            }
                        }
                    }

                    if (objectTypeSettings && objectTypeSettings.baseParams.length) {
                        for (let internal of objectTypeSettings.baseParams) {
                            let baseParam = searchDef.typeDef.api.getBaseParam(internal)
                            let restoredFormData = combinedFormData[objectTypeId]

                            baseParamFields.push(baseParam)

                            if (restoredFormData[internal]) {
                                formData[internal].value = restoredFormData[internal].value
                            }
                        }

                        typeHasBaseParams = true;
                    }

                    scope.areaToggleStates[objectTypeId] = {
                        objectTypeOpen: true,
                        baseParamsOpen: typeHasBaseParams,
                        hasBaseParams: typeHasBaseParams,
                        fulltextOpen: searchDef.fulltext !== ""
                    }
                }
            }

            async function createSearchDef(objectTypeId) {

                let typeDef = CacheManagerService.objectTypes.getById(objectTypeId)
                let formFields = typeDef.api.getFields(true);
                let allFields = formFields.concat(typeDef.api.getBaseParamFields());
                let formData = FormService.createFormData(allFields, "min");

                let searchDef = {
                    formDef: {},
                    baseparamFormDef: {},
                    objectTypeId,
                    useFulltext: false,
                    fulltext: "",
                    typeDef,
                    index: scope.searchDefs.length // increment the index to restore it in the right order
                }

                helperConfig = {
                    modelDef: typeDef.model,
                    formData,
                    isSearch: true,
                    search: () => {
                        return FormService.submitCombinedSearchForm(scope.searchDefs)
                    },
                    cancel() {
                        ClientService.executeStateErrorFallback();
                    },
                    progressbar
                };

                if (typeDef.model.config.useFullText) {
                    searchDef.fulltext = ""
                    searchDef.useFulltext = true
                }

                let formHelper = FormHelper.getFormHelper(helperConfig)

                if (scope.formHelper == void 0) {
                    scope.formHelper = formHelper;
                }

                searchDef.formDef = {
                    isMainForm: true,
                    validationMode: "min",
                    formFields,
                    formHelper,
                    formLayout: typeDef.api.getLayout(),
                };

                searchDef.baseparamFormDef = {
                    isMainForm: true,
                    validationMode: "min",
                    formFields: [],
                    formHelper,
                    isBaseParamDef: true,
                    isMockForm: true
                };

                searchDef.model = typeDef.model;
                searchDef.icon = ObjectTypeService.getIconClass(typeDef.model.config.objectTypeId, null, true)

                await bindScripts(typeDef, formHelper);

                return searchDef
            }

            scope.toggleSearchSettings = function() {
                scope.showSettings = !scope.showSettings;
                toggleSearchSettings();
            }

            async function bindScripts(typeDef, formHelper) {
                // check if there are cached scripts
                let objectTypeId = typeDef.model.config.objectTypeId;
                if (typeDef.api.getTypeScripts() == null) {
                    // if not, we need to get them and store them

                    try {
                        let scriptResponse = await ClientScriptService.getByObjectTypeIdAsync(objectTypeId)
                        scriptMap[objectTypeId] = scriptResponse.data;
                        typeDef.api.setTypeScripts(scriptResponse.data);
                    } catch (error) {
                        showScriptsErrorDialog();
                    }

                } else {
                    // if so, we use them
                    scriptMap[objectTypeId] = typeDef.api.getTypeScripts();
                }

                try {
                    await formHelper.bindScripts(scriptMap[objectTypeId]);
                } catch (ex) {
                    // do nothing ....
                    console.error("error")
                }
            }

            function showViewer(showViewer = true) {
                $timeout(() => {
                    MessageService.broadcast("show.viewer", showViewer);
                }, 0);
            }

            scope.$watch("fulltext.query", () => {
                if (scope.formHelper != void 0) {
                    scope.formHelper.config.fulltext = scope.fulltext.query;
                }
            });

            if (!useSearch) {
                history.back()
            }

            async function updateSearchMask(change) {

                switch (change.type) {
                    case "baseParams":

                        for (let search of scope.searchDefs) {
                            if (search.objectTypeId == change.objectTypeId) {
                                let typeDef = CacheManagerService.objectTypes.getById(change.objectTypeId)
                                let baseParamFormFields = typeDef.api.getBaseParamFields();

                                search.baseparamFormDef.formFields = [];

                                for (let baseParam of change.baseParams) {
                                    let baseParamField = baseParamFormFields.find(field => field.internal == baseParam);
                                    search.baseparamFormDef.formFields.push(baseParamField);
                                    scope.areaToggleStates[change.objectTypeId] = {
                                        objectTypeOpen: true,
                                        baseParamsOpen: true,
                                        hasBaseParams: true,
                                        fulltextOpen: search.fulltext !== ""
                                    }
                                }

                                if (search.baseparamFormDef.formFields.length === 0) {
                                    scope.areaToggleStates[change.objectTypeId].hasBaseParams = false;
                                }

                                let baseParam = search.baseparamFormDef.formHelper.getFieldByInternal(change.changedParam)
                                // scroll added param into view
                                if (change.isAdd) {
                                    $timeout(() => {
                                        if (baseParam.model.type == "checkbox") {
                                            baseParam.api.setValue(1);
                                        }
                                        baseParam.api.focus()
                                    }, 250)
                                } else {
                                    baseParam.api.setValue(null)
                                }

                                break;
                            }
                        }

                        $timeout(() => {
                            MessageService.broadcast(FormEvent.REDRAW_BASEPARAM_FORM);
                        }, 0)
                        break;
                    case "objectType":

                        for (let i = 0; i < scope.searchDefs.length; i++) {
                            if (scope.searchDefs[i].objectTypeId == change.objectTypeId) {
                                scope.searchDefs.splice(i, 1);
                                delete scope.areaToggleStates[change.objectTypeId];
                                return
                            }
                        }

                        let searchDef = await createSearchDef(change.objectTypeId);

                        scope.searchDefs.push(searchDef)
                        scope.$apply()

                        try {
                            searchDef.formDef.formHelper.focusFirstField();
                            await FormService.executeFormScript("beforeOpen", searchDef.formDef.formHelper)

                            scope.areaToggleStates[change.objectTypeId] = {
                                objectTypeOpen: true
                            }

                        } catch (err) {
                            console.warn("The ´beforeOpen event prevented the mask to be shown", err);
                            MessageService.broadcast(FormEvent.DESELECT_OBJECT_TYPE, change.objectTypeId);
                            scope.searchDefs.pop()
                        }
                        break;
                }
            }

            function showScriptsErrorDialog() {
                let title = $filter("translate")("modal.confirm.dms.get.script.error.title");
                let msg = $filter("translate")("modal.confirm.dms.get.script.error.message");
                let submit = $filter("translate")("modal.button.close");

                try {
                    ModalDialogService.infoDialog(title, msg, null, submit);
                    //no submit action atm
                } catch(error) {
                    ClientService.executeStateErrorFallback();
                }

            };

            function toggleSearchSettings() {
                if (scope.isPhone) {
                    $timeout(() => {
                        if (scope.showSettings) {
                            $(".search-left-pane").attr("style", "width: 0px");
                            $(".search-right-pane").attr("style", "left: 0");
                        } else {
                            let heading = element.find(".base-params-heading");
                            if (heading && heading[0]) {
                                heading[0].scrollIntoView();
                            }
                            $(".search-left-pane").attr("style", "right: 0");
                            $(".search-right-pane").attr("style", "width: 0px");
                        }
                    }, 0);
                } else {
                    $timeout(() => {
                        if (scope.showSettings) {
                            $(".search-divider").removeClass("hidden");
                            $(".search-left-pane").attr("style", "right: 50%");
                            $(".search-divider").attr("style", "right: 50%; width: 4px");
                            $(".search-right-pane").attr("style", "width: 50%");
                        } else {
                            $(".search-left-pane").attr("style", "right: 0");
                            $(".search-divider").addClass("hidden");
                            $(".search-right-pane").attr("style", "width: 0px");
                            $("eob-search-settings-pin").show();
                        }
                    }, 0);
                }
            }

            scope.$on("$destroy", () => {
                if (scope.formHelper == void 0) {
                    return;
                }

                MessageService.broadcast(ViewerEvent.SHOW_VIEWER, true);

                let combinedFormData = {};
                let fulltextValues = {},
                    formDefOrder = []
                for (let searchDef of scope.searchDefs) {
                    let formData = searchDef.formDef.formHelper.getFields(),
                        modelDef = searchDef.formDef.formHelper.getModel()
                    combinedFormData[modelDef.config.objectTypeId] = FormService.reduceFormdata(formData);
                    fulltextValues[modelDef.config.objectTypeId] = searchDef.fulltext
                    formDefOrder.push(modelDef.config.objectTypeId)
                }

                let data = {
                    config: Object.assign(StateHistoryManager.getStateData(stateId).data.config || {}, {
                        showSettings: scope.showSettings,
                        vtxQuery: scope.fulltext.query
                    }),
                    type: "search",
                    combinedFormData,
                    formDefOrder,
                    fulltextValues,
                };
                scope.formHelper.destroy();
                StateHistoryManager.setStateData(data, stateId);

                subscription.unsubscribe();
            });
        },
        template: require("!raw-loader!./eob.combined.search.html")
    }
}

