import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from "@angular/core";
import {Connection} from "ENUMS_PATH/connection.enum";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ThemeService} from "MODULES_PATH/theme/theme.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {Subscription} from "rxjs";
import {FormFieldModelService} from "MODULES_PATH/form/services/form-field-model.service";
import {FormValidationService} from "MODULES_PATH/form/services/form-validation.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {OfflineService} from "SERVICES_PATH/offline/eob.offline.srv";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {
    AreaToggleStates,
    Column,
    HelperConfig,
    LangOption,
    MobileSettings,
    RegistryKey,
    RegistryValue,
    ThemeOption
} from "MODULES_PATH/modal-dialog/interfaces/modal-user-config.interface";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {AsIniWfInboxStaticColumns, Language} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {Field} from "INTERFACES_PATH/field.interface";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {
    TodoEnvironmentService,
    TodoFieldApiService,
    TodoFormHelper,
    TodoFormService,
    TodoStaticColumnData
} from "INTERFACES_PATH/any.types";
import {FormDefinition} from "MODULES_PATH/form/interfaces/form-definition.interface";
import {Languages} from "ENUMS_PATH/languages.enum";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {StateService} from "@uirouter/core";
import {debounceTime, first} from "rxjs/operators";
import {EobSubstitutesConfigComponent} from "MODULES_PATH/modal-dialog/components/eob-substitutes-config/eob-substitutes-config.component";
import {ChangeDetectorRef} from "@angular/core";

enum Change {
    LANGUAGE = "LANGUAGE",
    OBJDEF_LANGUAGE = "OBJDEF_LANGUAGE",
    USER_ABSENT = "USER_ABSENT",
    HITLIST_COLUMN = "HITLIST_COLUMN",
    WORKFLOW_INBOX_COLUMN = "WORKFLOW_INBOX_COLUMN",
    TOUCH_FRIENDLY_THEME = "TOUCH_FRIENDLY_THEME",
    INCLUDE_WITHOUT_REGISTER_CONTEXT = "INCLUDE_WITHOUT_REGISTER_CONTEXT",
    SYNCHRONIZE_FAVORITES = "SYNCHRONIZE_FAVORITES",
    SYNCHRONIZE_USING_MOBILE_DATA = "SYNCHRONIZE_USING_MOBILE_DATA"
}

@Component({
    selector: "eob-modal-user-config",
    templateUrl: "./eob-modal-user-config.component.html",
    styleUrls: ["./eob-modal-user-config.component.scss"]
})
export class EobModalUserConfigComponent implements OnInit {
    private readonly translateFn: TranslateFnType;
    private readonly canChangeObjDefLanguage: boolean;
    persist = false;

    // eslint-disable-next-line max-params
    constructor(private clientService: ClientService,
                @Inject("fieldApiService") private fieldApiService: TodoFieldApiService,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("$state") protected $state: StateService,
                @Inject("formHelper") protected globalFormHelper: TodoFormHelper,
                @Inject("formService") protected formService: TodoFormService,
                @Inject("formValidationService") protected formValidationService: FormValidationService,
                @Inject("offlineService") protected offlineService: OfflineService,
                protected layoutManagerService: LayoutManagerService,
                protected notificationsService: NotificationsService,
                protected httpService: HttpService,
                protected formFieldModelService: FormFieldModelService,
                protected messageService: MessageService,
                protected asIniService: AsIniService,
                protected themeService: ThemeService,
                protected gridContentService: GridContentService,
                private renderer: Renderer2,
                private el: ElementRef,
                private cdRef: ChangeDetectorRef) {
        this.translateFn = this.$filter("translate");
        this.canChangeObjDefLanguage = environmentService.canChangeObjDefLanguage();
    }

    @ViewChild("passwordcontainer", {static: false}) passwordContainer: ElementRef<HTMLElement>;
    @ViewChild("slider", {static: false}) slider: ElementRef<HTMLElement>;
    @ViewChild("applyButton", {static: false}) applyButton: ElementRef<HTMLButtonElement>;
    @ViewChild("substitutesConfigComponent", {static: false}) substitutesConfigComponent: EobSubstitutesConfigComponent;

    user: string = this.environmentService.getSessionInfo().fullname || this.environmentService.getSessionInfo().username;
    canChangeLanguage: boolean = this.environmentService.userHasRole("R_CLNT_STORE_SETTINGS");
    canChangeObjectDefinitionLanguage: boolean = this.canChangeLanguage && parseFloat(this.environmentService.getServiceInfo().serverVersion) >= 10.0;
    isWorkflowUser: boolean = this.environmentService.isWorkflowUser();
    isAbsent: boolean = this.environmentService.getSessionInfo().isWorkflowAbsent;
    mobileSettings: MobileSettings = this.environmentService.getMobileSettings();
    canChangePassword: boolean = this.environmentService.env.useChangePassword;
    canSaveSettings: boolean = this.asIniService.getSavingAllowed();
    showFavorites: boolean = this.environmentService.userHasRole("R_CLNT_SHOW_MOBFAV");
    lang: string = this.asIniService.getGuiLanguage();
    activeTheme: string = this.themeService.getActiveTheme().name;
    initialActiveTheme: string = this.themeService.getActiveTheme().name;
    objDefLang: string;
    isOffline: boolean = this.clientService.isOffline();
    isPhone: boolean = this.clientService.isPhone();
    isNativeClient: boolean = this.clientService.isLocalClient();

    enableIconColumns: boolean = this.environmentService.featureSet.contains("user.setting.hitlist.iconColumn");

    // mobile tab is now inactive because offline features are not available
    isSubMenuOpenedPhone: boolean = false;
    isSynchronizeFavoritesOffline: boolean = this.asIniService.isSynchronizeFavoritesOffline();
    isUseMobileDataForSync: boolean = this.asIniService.isUseMobileDataForSync();
    isSynchronizeOriginalContent: boolean = this.asIniService.isSynchronizeOriginalContent();
    inclObjWithoutRegConf: boolean = this.asIniService.isIncludeObjectsWithoutRegisterContext();

    changeHandler: any[] = [];

    staticHitlistColumns: TodoStaticColumnData[];
    staticInboxColumns: TodoStaticColumnData[];
    touchHappened: boolean = false;

    offlineStatusChanged: boolean = false;
    isTouchLayoutActive: boolean = false;
    isTouchLayoutConfigurable: boolean = !this.clientService.isPhoneOrTablet() && this.clientService.isTouchDevice();
    // isPlaceholderSelectedLangOptions = false;
    isSubmitting: boolean = false;

    openTab: string = this.isPhone ? "" : "general";

    isChangingPresence: boolean = false;

    langOptions: LangOption[] = [];
    themeOptions: ThemeOption[] = [];
    objDefLangOptions: LangOption[] = [];
    changes: Map<Change, any> = new Map<Change, any>();
    passwordHint: string;

    passwordFormFields: FieldModel[];
    formDef: FormDefinition;
    formHelper: any;

    destroy$: Subscription;
    dropDownConfigObjDefLang: any;
    dropDownConfig: any;

    ngOnInit(): void {

        if (!this.isPhone) {
            this.staticHitlistColumns = this.gridContentService.getStaticColumns("hitlist.result", "getAll");
            this.staticHitlistColumns = this.gridContentService.addStaticColumns(this.staticHitlistColumns, "hitlist.result");
            this.staticInboxColumns = this.gridContentService.getStaticColumns("inbox", "getAll");
            this.staticInboxColumns = this.gridContentService.addStaticColumns(this.staticInboxColumns, "hitlist.result");
        }

        if (this.isTouchLayoutConfigurable) {
            this.isTouchLayoutActive = this.layoutManagerService.isTouchLayoutActive();
        }

        this.clientService.registerConnectivityChangeHandler((this.onConnectivityChange));
        this.onConnectivityChange();

        // options language change in dropdown
        for (const option of ["de", "en", "fr"]) {
            this.langOptions.push({
                name: this.translateFn(`eob.usermenu.language.${option}`),
                lang: option,
                isActive: this.lang === option
            });
        }

        const backendVersion: string = this.environmentService.getProductVersion();
        const isCorrectBackendVersion: boolean = Number(backendVersion.split(".")[0]) > 9 && backendVersion.split(" ")[0] != "10.0.0.0";
        for (const theme of this.themeService.getAvailableThemes()) {
            if (theme.name == "high-contrast" && (!isCorrectBackendVersion)) {
                continue;
            }
            this.themeOptions.push({
                name: this.translateFn(`modal.user.config.theme.${theme.name}`),
                theme: theme.name,
                isActive: this.activeTheme === theme.name
            });
        }

        this.objDefLang = this.environmentService.getObjectDefinitionLanguage();

        // options object definition language change in dropdown
        this.environmentService.getLanguages()?.forEach((data) => {
            if (data.active > 0) {
                this.objDefLangOptions.push({
                    name: this.translateFn(`eob.usermenu.object.definition.language.${Languages[data.lang_id]}`),
                    lang: Languages[data.lang_id],
                    isActive: this.objDefLang === Languages[data.lang_id]
                });
            }
        });

        this.dropDownConfigObjDefLang = {
            language: {
                items: this.objDefLangOptions,
                useFullHeight: true,
                callback: this.changeObjDefLanguage.bind(this),
                callbackAfterInit: false,
                emptyPlaceholder: {
                    name: this.translateFn("eob.action.modal.user.config.mobile.favourite.depth"),
                    placeholder: true
                },
                showEmptyPlaceholder: true
            }
        };

        void (async () => {
            const helperConfig: HelperConfig = {
                formData: await this.buildFormData(),
                submit: this.saveNewPassword
            };

            this.formHelper = this.globalFormHelper.getFormHelper(helperConfig);

            this.formDef = {
                isMainForm: true,
                validationMode: "max",
                formFields: this.passwordFormFields,
                formHelper: this.formHelper,
                isMockForm: true
            };

            this.addValidation();
        })();

        this.messageService.subscribeFirst(ModalEvents.DESTROY, () => {
            void (async () => {
                this.clientService.unregisterConnectivityChangeHandler(this.onConnectivityChange);
                try {
                    if (this.changeHandler.length > 0) {
                        this.asIniService.saveSettings();
                        await Promise.all(this.changeHandler.map(handler => handler()));
                    }
                } catch (error) {
                    console.warn(error);
                }

                if (this.formHelper != void 0) {
                    this.formHelper.destroy();
                }
            })();
        });

        this.dropDownConfig = {
            language: {
                items: this.langOptions,
                useFullHeight: true,
                callback: this.changeLanguage.bind(this),
                callbackAfterInit: false,
                emptyPlaceholder: {
                    name: "{{'eob.action.modal.user.config.mobile.favourite.depth' | ngTranslate}}",
                    placeholder: true
                },
                showEmptyPlaceholder: true
            },
            theme: {
                items: this.themeOptions,
                callback: this.changeTheme,
                callbackAfterInit: false,
            }
        };
    }

    back(): void {
        this.isSubMenuOpenedPhone = false;
    }

    getClassByName = (name: string): string => {
        switch (name) {
            case "staticColDmsIcon":
                return "icon-16-header-cell-objecttype";
            case "staticColSignature":
                return "icon-16-header-cell-signature";
            case "staticColFavorite":
                return "icon-16-header-cell-favorite";
            case "staticColLinks":
                return "icon-16-header-cell-links";
            case "staticColNotes":
                return "icon-16-header-cell-notes";
            case "staticColArchiveState":
                return "icon-16-header-cell-archived";
            case "staticColLockState":
                return "icon-16-header-cell-locking";
            case "staticColAnnotations":
                return "icon-16-header-cell-annotations";
            case "staticColMimeType":
                return "icon-16-header-cell-mimetype";
            default:
                return "";
        }
    };

    getHitListColumnTitle = (col: TodoStaticColumnData): string => {
        if (col.title) {
            return col.title;
        } else if (col.headerName) {
            return col.headerName;
        } else if (col.headerComponentParams) {
            return col.headerComponentParams.title;
        }
    };

    switchSettingsTab = (tab: string): void => {
        this.isSubMenuOpenedPhone = true;
        this.messageService.broadcast("IS_SUB_MENU_OPENED_PHONE");
        this.openTab = `${["general", "presence", "appearence", "mobile", "offline", "hitlist", "inbox"].includes(tab) ? tab : "general"}`;
    };

    setMobileSettings = () : void => this.environmentService.setMobileSettings(this.mobileSettings);

    areaToggleStates: AreaToggleStates = {
        general: {
            password: false,
            language: true,
            objectDefinitionLanguage: true
        },
        presence: {
            presence: true,
            substitutes: true
        },
        appearence: {
            theme: true
        },
        mobile: {
            autofav: true,
            favCacheDepth: true
        },
        offline: {
            synchronizeFavoritesOffline: true
        },
        hitlist: {
            staticCols: true,
            requestBehavoir: true
        },
        inbox: {
            staticCols: true,
            requestBehavoir: true
        }
    };

    /**
     * Callback for theme change.
     *
     * @param {object} param - An object with theme name property.
     * @returns {Promise} Resolved once the language is changed.
     */
    changeTheme = (param: ThemeOption) : void => {
        this.activeTheme = param.theme;
        for (const item of this.dropDownConfig.theme.items) {
            item.isActive = item.theme === this.activeTheme;
        }

        // this.messageService.broadcast("populate-dropdown-selection");

        this.themeService.setTheme(this.activeTheme);
    };

    private resetDropdownSelection(langOptions: LangOption[], lang: string, param: LangOption) : boolean {
        if (this.clientService.isOffline()) {
            for (const item of langOptions) {
                item.isActive = item.lang === lang;
            }

            this.messageService.broadcast("populate-dropdown-selection");
            this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));

            return false;
        }

        if (param.lang === lang) {
            return false;
        }
        return true;
    }

    /**
     * Callback for language change.
     *
     * @param {object} param - An object with lang property.
     * @returns {Promise} Resolved once the language is changed.
     */
    changeLanguage(param: LangOption): void {
        if (!this.resetDropdownSelection(this.dropDownConfig.language.items, this.lang, param)) {
            this.changes.delete(Change.LANGUAGE);
        } else {
            this.changes.set(Change.LANGUAGE, param.lang);
        }
    }

    /**
     * Callback for language change.
     *
     * @param {object} param - An object with lang property.
     */
    changeObjDefLanguage(param: LangOption): void {
        if (!this.resetDropdownSelection(this.dropDownConfigObjDefLang.language.items, this.objDefLang, param)) {
            this.changes.delete(Change.OBJDEF_LANGUAGE);
            return;
        } else {
            this.changes.set(Change.OBJDEF_LANGUAGE, param.lang);
        }
    }

    onConnectivityChange = (): void => {
        this.isOffline = this.clientService.isOffline();
        const elements: HTMLElement[] = Array.from(this.el.nativeElement.querySelectorAll(".switch"));

        elements.forEach((element) => {
            const slider: HTMLElement = element.querySelector(".slider");
            const ev: any = (event) => {
                if (this.clientService.isiOs()) {
                    this.preventGhostClick(event);
                }

                this.checkConnectivity(event);
            };

            if (this.isOffline) {
                this.renderer.addClass(slider, "disabled");
                element.addEventListener("click", ev);
            } else {
                this.renderer.removeClass(slider, "disabled");
                element.removeEventListener("click", ev);
            }
        });
    };

    /**
     * Build the form data with mocked fields.
     *
     * @returns {Promise<object>} Resolves with the build form data.
     */
    buildFormData = async (): Promise<any> => {
        // create mocked fields to access the api later
        const oldPasswordFormField: FieldModel = this.formFieldModelService.getMockedField("text", "oldPassword", this.translateFn("modal.password.old.password"));
        const newPasswordFormField: FieldModel = this.formFieldModelService.getMockedField("text", "newPassword", this.translateFn("modal.password.new.password"));
        const confirmNewPasswordFormField: FieldModel = this.formFieldModelService.getMockedField("text", "confirmNewPassword", this.translateFn("modal.password.repeat.password"));
        const passwordHintField: FieldModel = this.formFieldModelService.getMockedField("static", "passwordHint", "");

        passwordHintField.isInvisibleField = true;

        const registryKeys: RegistryKey[] = [{key: "Login\\PwdComplexityDescription"}, {key: "Login\\PwdComplexity"}];
        const registryValues: RegistryValue[] = (await this.httpService.legacyPost("/serviceinfo/registry/", registryKeys)).data;
        this.passwordHint = registryValues[0].value;
        const passwordRegex: string = registryValues[1].value;

        if (passwordRegex != void 0 && passwordRegex.length > 0) {
            newPasswordFormField.regEx = passwordRegex;

            if (this.passwordHint != void 0 && this.passwordHint.length > 0) {
                passwordHintField.title = this.translateFn("modal.password.password.hint") + this.passwordHint;
                passwordHintField.isInvisibleField = false;
            }
        }

        oldPasswordFormField.isRequired = true;
        oldPasswordFormField.isPassword = true;

        newPasswordFormField.isRequired = true;
        newPasswordFormField.isPassword = true;

        confirmNewPasswordFormField.isRequired = true;
        confirmNewPasswordFormField.isPassword = true;

        this.passwordFormFields = [oldPasswordFormField, passwordHintField, newPasswordFormField, confirmNewPasswordFormField];

        return this.formService.createFormData(this.passwordFormFields, "max");
    };

    /**
     * Enhance the form with validation.
     */
    addValidation = (): void => {
        const passwordRepeat: Field = this.formDef.formHelper.getFieldByInternal("confirmNewPassword");
        const password: Field = this.formDef.formHelper.getFieldByInternal("newPassword");
        let errorTitle: string;
        let errorMessage: string;

        if (password.model.regEx) {
            errorTitle = this.translateFn("eob.add.user.general.error.title");
            errorMessage = this.translateFn("eob.password.insufficient.complexity.error").replace("[%s]", this.passwordHint);

            if (password.api) {
                this.addCustomValidation((field) => this.formValidationService.validateRegEx(field), password, errorTitle, errorMessage);
            } else {
                password.api = this.fieldApiService.getFieldApi(this.formDef.formHelper, password);
                this.addCustomValidation((field) => this.formValidationService.validateRegEx(field), password, errorTitle, errorMessage);
            }
        }

        const validatePasswordRepetition: (field?: Field) => boolean = (): boolean => (password.value == passwordRepeat.value && password.value != "");

        errorTitle = this.translateFn("eob.add.user.general.error.title");
        errorMessage = this.translateFn("eob.add.user.password.missmatch.message");

        if (passwordRepeat.api) {
            this.addCustomValidation(validatePasswordRepetition, passwordRepeat, errorTitle, errorMessage);
        } else {
            passwordRepeat.api = this.fieldApiService.getFieldApi(this.formDef.formHelper, passwordRepeat);
            this.addCustomValidation(validatePasswordRepetition, passwordRepeat, errorTitle, errorMessage);
        }
    };

    private addCustomValidation(validRegEx: (field?: Field) => boolean, password: Field, errorTitle: string, errorMessage: string): void {
        password.api.addCustomValidation(validRegEx, errorTitle, errorMessage);
    }

    /**f
     * Clear the password fields.
     *
     * @param {object} pwdFields - A map of fields.
     */
    resetPasswordFields = (pwdFields: any): void => {
        [pwdFields.oldPassword, pwdFields.newPassword, pwdFields.confirmNewPassword].forEach(field => {
            field.api.setValue("");
            field.model.control.setErrors(null);
        });
    };

    /**
     * Validate and save the password. In case of an error, an error message is shown.
     *
     * @returns {Promise} Resolved once the password is checked and saved or an error occured.
     */
    saveNewPassword = async (): Promise<void> => {
        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            return;
        }

        try {
            await this.formService.validateForm(this.formHelper.getFields());
        } catch (error) {
            return;
        }

        const pwdFields: any = this.formHelper.getFields();

        if (pwdFields.oldPassword.value == pwdFields.newPassword.value) {
            this.resetPasswordFields(pwdFields);
            this.notificationsService.error(this.translateFn("modal.password.no.change.error.message"));
            return;
        }

        if (pwdFields.newPassword.value != pwdFields.confirmNewPassword.value) {
            this.resetPasswordFields(pwdFields);
            this.notificationsService.error(this.translateFn("modal.password.mismatch.error.message"));
            return;
        }

        try {
            this.isSubmitting = true;
            await this.httpService.changePassword(pwdFields.oldPassword.value, pwdFields.newPassword.value).toPromise();
            this.resetPasswordFields(pwdFields);
            this.notificationsService.success(this.translateFn("modal.password.change.success"));
        } catch (error) {
            this.resetPasswordFields(pwdFields);

            if (error.status == 403) {
                this.notificationsService.error(this.translateFn("modal.confirm.password.error"));
            } else {
                this.notificationsService.backendError(error, "modal.password.change.error");
            }
        } finally {
            this.isSubmitting = false;
        }
    };

    togglePresence(): void {
        if (!this.changes.has(Change.USER_ABSENT)) {
            this.changes.set(Change.USER_ABSENT, !this.isAbsent);
        } else {
            this.changes.delete(Change.USER_ABSENT);
        }
    }

    hasTouchFriendlyTheme(): boolean {
        return this.changes.has(Change.TOUCH_FRIENDLY_THEME);
    }

    /**
     * prevent action and notify user if offline
     */
    checkConnectivity = (event: MouseEvent): void => {
        if (this.clientService.isOffline()) {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
            if (!this.touchHappened) {
                this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
                this.touchHappened = false;
            }
        }
    };

    // Wichtig
    /**
     * preventing ghost click events on mobile devices
     */
    preventGhostClick = (event: MouseEvent): void => {
        (event.target as HTMLElement).addEventListener("touchend click", _ => {
            if (!this.touchHappened) {
                this.touchHappened = true;
                setTimeout(() => {
                    this.touchHappened = false;
                }, 0);
            }
        });
    };

    // Wichtig
    /**
     * Toggle whether favorites shall always be synchronized
     */
    toggleSynchronizeFavoritesOffline = (): void => {
        if(!this.changes.has(Change.SYNCHRONIZE_FAVORITES)) {
            this.changes.set(Change.SYNCHRONIZE_FAVORITES, !this.isSynchronizeFavoritesOffline);
        } else {
            this.changes.delete(Change.SYNCHRONIZE_FAVORITES);
        }
    };

    changeSynchronizeFavorites = (): void => {
        this.asIniService.setSynchronizeFavoritesOffline(this.isSynchronizeFavoritesOffline);

        try {
            if (!this.isSynchronizeFavoritesOffline) {
                this.offlineService.stopSynchronization();
                void this.offlineService.clearCache();
            }

            this.messageService.broadcast(ModalEvents.UPDATE_OFFLINE_OBJECTS, this.isSynchronizeFavoritesOffline);
        } catch (error) {
            console.warn(error);
        }
    };

    /**
     * Toggle whether mobile data should be used for synchronization
     */
    toggleUseMobileDataForSync = (): void => {
        if(!this.changes.has(Change.SYNCHRONIZE_USING_MOBILE_DATA)) {
            this.changes.set(Change.SYNCHRONIZE_USING_MOBILE_DATA, !this.isUseMobileDataForSync);
        } else {
            this.changes.delete(Change.SYNCHRONIZE_USING_MOBILE_DATA);
        }

    };

    /**
     * Toggle the websites touch layout.
     *
     * @param {boolean} state - Set the touch layout.
     */
    toggleLayoutStyle(): void {
        if(!this.changes.has(Change.TOUCH_FRIENDLY_THEME)) {
            this.changes.set(Change.TOUCH_FRIENDLY_THEME, !this.isTouchLayoutActive);
        } else {
            this.changes.delete(Change.TOUCH_FRIENDLY_THEME);
        }
    }

    private checkUncheckSlider(col: Column) : void {
        const slider: any = this.el.nativeElement.querySelector(`.${col.internalName}`);
        if(!slider) {
            return;
        }
        if (col.enabled) {
            this.renderer.addClass(slider, "checked");
            this.renderer.setAttribute(slider, "checked", "true");
        } else {
            this.renderer.removeClass(slider, "checked");
            this.renderer.setAttribute(slider, "checked", "false");
        }
    }

    toggleHitlistColumn(colName: string): void {
        const changes: Map<string, boolean> = this.changes.get(Change.HITLIST_COLUMN) ?? new Map<string, boolean>();

        const col: Column = this.staticHitlistColumns.find(column => column.internalName == colName);
        col.enabled = !col.enabled;

        colName = colName.replace("staticCol", "");
        colName = colName.charAt(0).toLowerCase() + colName.slice(1);

        if(changes.has(colName)) {
            changes.delete(colName);
        } else {
            changes.set(colName, col.enabled);
        }
        this.changes.set(Change.HITLIST_COLUMN, changes);
        this.checkUncheckSlider(col);
    }

    toggleWorkflowInboxColumn(colName: keyof AsIniWfInboxStaticColumns): void {
        const changes: Map<keyof AsIniWfInboxStaticColumns, boolean> = this.changes.get(Change.WORKFLOW_INBOX_COLUMN) ?? new Map<keyof AsIniWfInboxStaticColumns, boolean>();

        const col: Column = this.staticInboxColumns.find(column => column.internalName == colName);
        col.enabled = !col.enabled;

        if(changes.has(colName)) {
            changes.delete(colName);
        } else {
            changes.set(colName, col.enabled);
        }

        this.changes.set(Change.WORKFLOW_INBOX_COLUMN, changes);
        this.checkUncheckSlider(col);
    }

    toggleHitlistRegConf = (): void => {
        if(!this.changes.has(Change.INCLUDE_WITHOUT_REGISTER_CONTEXT)) {
            this.changes.set(Change.INCLUDE_WITHOUT_REGISTER_CONTEXT, !this.asIniService.isIncludeObjectsWithoutRegisterContext());
        } else {
            this.changes.delete(Change.INCLUDE_WITHOUT_REGISTER_CONTEXT);
        }
    };

    cancel() {
        this.themeService.setTheme(this.initialActiveTheme);
        this.messageService.broadcast(ModalEvents.DESTROY);
    }

    async apply(): Promise<void> {
        let reload = false;
        this.persist = true;
        this.cdRef.detectChanges();
        this.asIniService.updateLayoutConfiguration({colorTheme: this.activeTheme, accentColor: this.themeService.getActiveAccentColor()});

        // TODO reenable on error, correctly destroy modal dialog in any combination
        this.applyButton.nativeElement.disabled = true;
        try {
            if(this.changes.has(Change.OBJDEF_LANGUAGE)) {
                const lang = this.changes.get(Change.OBJDEF_LANGUAGE);
                try {
                    await this.httpService.legacyPost("/session/settings/custom/save/objDefLanguage", {isoObjLanguageCode: lang});
                    reload = true;
                } catch (error) {
                    console.log(error);
                    this.notificationsService.warning(error.error.errorMessage);
                }
            }

            if(this.changes.has(Change.TOUCH_FRIENDLY_THEME)) {
                this.layoutManagerService.setTouchLayoutState(this.changes.get(Change.TOUCH_FRIENDLY_THEME));
                reload = true;
            }

            if (this.changes.has(Change.LANGUAGE)) {
                this.asIniService.setGuiLanguage(this.changes.get(Change.LANGUAGE)).subscribe(sub => sub.add(() => {
                    sessionStorage.removeItem("randomSessionId");

                    if (this.clientService.isDesktop()) {
                        // reset to stored session/tab id, thus ensuring all refreshed tabs
                        // will have the same ids from the start
                        this.clientService.setRandomIds();
                        window.electron.storeSessionStorage();
                        setTimeout(() => {
                            window.electron.refreshAllTabs();
                        }, 0);
                    } else {
                        reload = true;
                    }
                }));
            }

            if (this.isWorkflowUser) {
                await this.substitutesConfigComponent.applySubstitutes();
            }

            if (this.changes.has(Change.USER_ABSENT)) {
                const absent = this.changes.get(Change.USER_ABSENT);
                await this.httpService.legacyGet(`/workflows/absence/${absent}`);
                this.isAbsent = absent;
                // also need to update the environment service session variable
                this.environmentService.getSessionInfo().isWorkflowAbsent = absent;
                // No point on showing these if page gets refreshed anyway
                if (!this.changes.has(Change.LANGUAGE)) {
                    if (absent) {
                        this.notificationsService.success(this.translateFn("eob.app.bar.usermenu.workflow.report.absent"));
                    } else {
                        this.notificationsService.success(this.translateFn("eob.app.bar.usermenu.workflow.report.present"));
                    }
                }
            }

            if(this.changes.has(Change.SYNCHRONIZE_USING_MOBILE_DATA)) {
                this.isUseMobileDataForSync = !this.isUseMobileDataForSync;
                this.asIniService.setUseMobileDataForSync(this.isUseMobileDataForSync);

                try {
                    if (!this.isUseMobileDataForSync && this.clientService.getConnectionType() != Connection.WIFI) {
                        this.offlineService.stopSynchronization();
                    }
                } catch (_) {
                    // ignored
                }
            }

            if(this.changes.has(Change.SYNCHRONIZE_FAVORITES)) {
                this.isSynchronizeFavoritesOffline = !this.isSynchronizeFavoritesOffline;
                this.asIniService.setSynchronizeFavoritesOffline(this.isSynchronizeFavoritesOffline);
                this.changeHandler.push(this.changeSynchronizeFavorites);
            }

            if(this.changes.has(Change.INCLUDE_WITHOUT_REGISTER_CONTEXT)) {
                this.inclObjWithoutRegConf = !this.asIniService.isIncludeObjectsWithoutRegisterContext();
                this.asIniService.setIncludeObjectsWithoutRegisterContext(this.inclObjWithoutRegConf);
            }

            if(this.changes.has(Change.WORKFLOW_INBOX_COLUMN)) {
                const changes: Map<string, boolean> = this.changes.get(Change.WORKFLOW_INBOX_COLUMN);
                if (changes.size) {
                    for (const [k, v] of changes.entries()) {
                        this.asIniService.setWfInboxStaticColumns(k as keyof AsIniWfInboxStaticColumns, v);
                    }
                    this.asIniService.saveSettings().pipe(first()).subscribe(x => x.add(() => {
                        sessionStorage.removeItem("randomSessionId");
                        if(this.clientService.isDesktop()) {
                            window.electron.refreshHitLists("hitlist.inbox");
                        }
                        if (this.$state.current.name == "hitlist.inbox") {
                            if (this.clientService.isDesktop()) {
                                // // reset to stored session/tab id, thus ensuring all refreshed tabs
                                // // will have the same ids from the start
                                this.clientService.setRandomIds();
                                window.electron.storeSessionStorage();
                            } else {
                                void this.$state.reload();
                            }

                        }
                    }));
                }

            }
            if (this.changes.has(Change.HITLIST_COLUMN)) {
                const changes: Map<string, boolean> = this.changes.get(Change.HITLIST_COLUMN);
                if (changes.size) {
                    for (const [k, v] of changes.entries()) {
                        this.asIniService.setStaticColumns(k, v);
                    }
                    this.asIniService.saveSettings().pipe(first()).subscribe(x => x.add(() => {
                        if(this.clientService.isDesktop()) {
                            window.electron.refreshHitLists();
                        }
                        if (/inbox|favorites|offlineobjects|result|folder/i.test(this.$state.current.name)) {
                            this.messageService.broadcast(ModalEvents.DESTROY);
                            void this.$state.reload();
                        }
                    }));
                }
            }
            if(!this.changes.size) {
                this.messageService.broadcast(ModalEvents.DESTROY);
            }
            // Just to make sure all changes have been applied
            this.asIniService.saveSettings().pipe(debounceTime(1000), first()).subscribe(() => {
                if(reload) {
                    if(this.clientService.isDesktop()) {
                        window.electron.refreshAllTabs();
                    } else {
                        location.reload(true);
                    }
                    // refresh all electron tabs
                } else {
                    this.messageService.broadcast(ModalEvents.DESTROY);
                    // refresh hitlist, if applicable
                }
            });
        } catch(e) {
            this.applyButton.nativeElement.disabled = false;
            this.notificationsService.error(this.translateFn("eob.asini.write.error"));
        }
    }

}
