/**
 * An enum of event key codes. Complete as necessary.
 */
export enum KeyCode {
    MOUSELEFT = 1,
    BACKSPACE = 8,
    TAB = 9,
    ENTER = 13,
    ESCAPE = 27,
    SPACE = 32,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    END = 35,
    LEFT = 37,
    UP = 38,
    RIGHT = 39,
    DOWN = 40,
    DELETE = 46,
    COMMA = 188,
    PERIOD = 190,
    F12 = "F12",
    SHIFT = 16,
    CTRL = 17,
    CAPS_LOCK = 20
}
