/* eslint-disable @typescript-eslint/no-explicit-any */
import {AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn, Validators} from "@angular/forms";
import {PipeTransform} from "@angular/core";
import {FieldMask} from "MODULES_PATH/form/models/field-mask.model";

interface EobOptions {
    label?: string;
    type?: string;
    placeholder?: string;
    addonConfig?: any;
    pipes?: PipeTransform[];
    masks?: FieldMask[];
    validators?: ValidatorFn | ValidatorFn[];
    asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[];
}
type EobInputControlOptions = AbstractControlOptions & EobOptions;

export class EobInputFormControl extends FormControl {
    eobOptions: EobOptions = {
        type: "text",
        validators: [],
        masks: []
    };

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    constructor(formState?: any,
                validatorOrOpts?: ValidatorFn | ValidatorFn[] | EobInputControlOptions | null,
                asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super(formState, validatorOrOpts, asyncValidator);

        if (typeof validatorOrOpts == "object") {
            this.init(validatorOrOpts as EobInputControlOptions);
        } else {
            this.init({ validators: validatorOrOpts, asyncValidators: asyncValidator } as EobInputControlOptions);
        }
    }

    init(options: EobInputControlOptions): void {
        Object.entries(options)
            .forEach(([key, value]) => {
                if (["validators", "asyncValidators"].includes(key) && !Array.isArray(value)) {
                    value = [value];
                }

                this.eobOptions[key] = value;
            });
    }

    getValidators(): ValidatorFn[] {
        return Array.isArray(this.eobOptions.validators) ? this.eobOptions.validators : [this.eobOptions.validators];
    }

    setValidators(newValidator: ValidatorFn | ValidatorFn[] | null): void {
        this.eobOptions.validators = newValidator;
        super.setValidators(newValidator);
    }

    addValidator(newValidator: ValidatorFn): void {
        const validators: ValidatorFn[] = Array.isArray(this.eobOptions.validators) ? this.eobOptions.validators : [this.eobOptions.validators];
        if(validators.find(x => x == newValidator)) {
            return;
        }
        validators.push(newValidator);
        super.setValidators(this.eobOptions.validators);
    }

    setRequired(set: boolean): void {
        const validators: ValidatorFn[] = Array.isArray(this.eobOptions.validators) ? this.eobOptions.validators : [this.eobOptions.validators];

        if (set) {
            if(validators.find(x => x == Validators.required)) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/unbound-method
            validators.push(Validators.required);
        } else if (!validators.find(x => x == Validators.required)) {
            return;
        } else {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            validators.splice((this.eobOptions.validators as ValidatorFn[]).indexOf(Validators.required), 1);
        }

        this.setValidators(this.eobOptions.validators);
        this.updateValueAndValidity();
    }

    addMask(mask: FieldMask): void {
        const masks: FieldMask[] = this.eobOptions.masks;

        if (!masks.find(m => m instanceof mask.constructor)) {
            this.eobOptions.masks = [...masks, mask]; // re-set the masks property to trigger the change event
        }
    }
}
