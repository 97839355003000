import { Component, OnInit } from "@angular/core";
import {Inject} from "@angular/core";
import {TodoSearchService} from "INTERFACES_PATH/any.types";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {HitlistLoadingState} from "MODULES_PATH/hitlist/model/loading-state.model";
import {Subject} from "rxjs";

@Component({
  selector: "eob-favorites",
  templateUrl: "./favorites.component.html",
  styleUrls: ["./favorites.component.scss"]
})
export class FavoritesComponent implements OnInit {
    loadingState: HitlistLoadingState = new HitlistLoadingState();
    hitlistItems: Subject<DmsDocument[]> = new Subject<DmsDocument[]>();

    constructor(@Inject("searchService") private searchService: TodoSearchService) {}

    async ngOnInit(): Promise<void> {
        try {
            this.loadingState.isLoading = true;
            const hitlistItems: DmsDocument[] = await this.searchService.executeFavoritesSearch();
            this.hitlistItems.next(hitlistItems);
        } catch {
            this.loadingState.hasErrors = true;
            this.loadingState.isLoading = false;
        } finally {
            this.loadingState.isLoading = false;
            this.loadingState.showHitlist = true;
        }
    }
}
