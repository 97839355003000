import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import {AutoCompleteConfig} from "MODULES_PATH/autocomplete/interfaces/autocomplete.interface";
import {Field} from "INTERFACES_PATH/field.interface";
import {EobInputFormControl} from "MODULES_PATH/form/components/inputs/eob-input-form-control.model";
import {TodoClientScriptService, TodoColDef, TodoEnvironmentService, TodoFormHelper} from "INTERFACES_PATH/any.types";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {FormControl} from "@angular/forms";
import {AutoCompleteService} from "MODULES_PATH/autocomplete/services/autocomplete.service";
import {InputComponent} from "MODULES_PATH/form/components/inputs/input/input.component";
import {FieldAddon} from "ENUMS_PATH/field.enum";
import {FormControlService} from "MODULES_PATH/form/services/form-control.service";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {TreeNode} from "INTERFACES_PATH/tree.interface";
import {
    Cell,
    ColumnModel,
    GridCellParams,
    ScriptCell
} from "MODULES_PATH/grid/interfaces/grid-cell-component.interface";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {RowNode} from "ag-grid-community";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Subscription} from "rxjs";
import {StandaloneValidationBubbleComponent} from "MODULES_PATH/form/components/standalone-validation-bubble/standalone-validation-bubble.component";
import {ClientService} from "CORE_PATH/services/client/client.service";

@Component({
    selector: "eob-grid-cell",
    templateUrl: "./grid-cell.component.html",
    styleUrls: ["./grid-cell.component.scss"]
})
export class GridCellComponent implements ICellRendererAngularComp, AfterViewInit, OnDestroy {
    eobAutocomplete: AutoCompleteConfig = null;
    colfield: Field;
    formhelper: TodoFormHelper;
    control: FormControl;
    bubble: StandaloneValidationBubbleComponent;

    private param: GridCellParams;
    private rowNode: RowNode;
    private field: Field;
    updateAutocompleteSubscription: Subscription;

    @Output() triggerKeydown: EventEmitter<Event> = new EventEmitter<Event>();
    @ViewChild(InputComponent, {static: false}) input: InputComponent;

    // eslint-disable-next-line max-params
    constructor(private autoCompleteService: AutoCompleteService,
                private formControlService: FormControlService,
                private valueUtilsService: ValueUtilsService,
                private messageService: MessageService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("clientScriptService") private clientScriptService: TodoClientScriptService,
                private cdRef: ChangeDetectorRef, private renderer: Renderer2, private clientService: ClientService) {
    }

    agInit(param: GridCellParams): void {
        this.param = param;
        this.field = param.field;
        this.formhelper = param.formHelper;
        this.rowNode = this.param.node;
        this.bubble = this.param.context.validationBubble;

        const col: TodoColDef = param.colDef;
        const rowIndex: number = this.rowNode.rowIndex;
        const colIndex: number = col.index;

        const cellProps: Cell = GridCellComponent.getCellProperties(param.field, rowIndex, colIndex);
        const colModel: ColumnModel = this.createColumnModel(param, colIndex);

        let control: EobInputFormControl = null;

        if (colModel.type == "decimal" && param.value) {
            param.data[col.index] = param.value;
            colModel.cell.rowNode.data[colIndex] = param.value;
        }

        const colField: Field = {
            model: colModel as FieldModel,
            value: param.value,
            api: {
                getElement: () => (this.input.input.nativeElement),
                setValue: (newValue, executeOnChange) => {
                    control.setValue(newValue);

                    if (executeOnChange) {
                        this.input.input.nativeElement.dispatchEvent(new Event("change"));
                    }

                    colField.value = newValue; // set model value
                    this.param.data[col.index] = newValue;

                    if (!this.formhelper.isSearch) {
                        this.field.api.validateCell(rowIndex, colIndex);
                    }
                },
                getListEntries() {
                    const list: TreeNode[] = [];
                    for (const node of colField.model.tree.nodes) {
                        list.push(JSON.parse(JSON.stringify(node)));
                    }
                    return list;
                },
                isEnabled() {
                    return !this.control.disabled;
                }
            }
        } as Field;

        this.colfield = colField;
        this.colfield.validationMode = this.field.validationMode;
        control = this.formControlService.getInputControlByField(this.colfield, this.colfield, param.formHelper);
        this.control = control;
        if (cellProps.isDisabled) {
            this.control.disable();
        }

        this.setAddonIcon(this.colfield.model.addon);

        const cell: Cell = this.field.propMap[rowIndex][colIndex];
        cell.control = this.control;
        cell.colfield = this.colfield;

        if (this.colfield.model.addon) {
            this.autoCompleteService.addAutoComplete(this.colfield, this.formhelper);
            this.setAutoCompleteConfig();
        }
        this.updateAutocompleteSubscription = this.messageService.subscribe(FormEvent.UPDATE_AUTOCOMPLETE_CONFIG, () => {
            this.setAutoCompleteConfig();
        });
    }

    private createColumnModel(param: GridCellParams, colIndex: number): ColumnModel {
        const colModel: ColumnModel = {...param.field.model.columns[colIndex]};
        colModel.grid = param.field;
        colModel.isGridCell = true;
        colModel.cell = {
            rowIndex: this.rowNode.rowIndex,
            colIndex,
            rowNode: this.rowNode
        };
        return colModel;
    }

    ngAfterViewInit(): void {
        this.input.input.nativeElement.addEventListener("change", (event: CustomEvent) => this.change(event));

        const col: TodoColDef = this.param.colDef;

        const rowIndex: number = this.rowNode.rowIndex,
            colIndex: number = col.index;

        const cellProps: Cell = GridCellComponent.getCellProperties(this.param.field, rowIndex, colIndex);
        cellProps.input = this.input.input.nativeElement;

        if (this.input.field.model.addon) {
            if (this.clientService.isPhone()) {
                this.renderer.setStyle(this.input.input.nativeElement, "padding-right", "40px");
            } else {
                this.renderer.setStyle(this.input.input.nativeElement, "padding", "4px 32px 4px 4px");
            }
        }

        // sign to the grid, that this cell is done initializing
        this.param.context.cellsInitializeCounter.next();
    }

    private static getCellProperties(field: Field, rowIndex: number, colIndex: number): Cell {
        return field.propMap[rowIndex][colIndex];
    }

    private setAddonIcon(addon: FieldAddon): void {
        if (this.control.disabled) {
           return;
        }

        if (addon === "list") {
            this.colfield.model.iconClass = "list-icon";
        } else if (addon === "tree") {
            this.colfield.model.iconClass = "tree-icon";
        } else if (addon === "hierarchy") {
            this.colfield.model.iconClass = "hierarchy-icon";
        }
    }

    private change(event: CustomEvent): void {
        const col: TodoColDef = this.param.colDef;
        let newValue: string = this.control.value;
        const oldValue: string = this.colfield.value || "";

        if (event?.detail?.format !== false) {
            if (this.colfield.model.type == "decimal" && !this.colfield.model.addon) {
                newValue = this.valueUtilsService.numberToLocaleNumber(newValue, ".2-8");
            }
        }

        this.colfield.api.setValue(newValue);

        const rowIndex: number = this.rowNode.rowIndex,
            colIndex: number = col.index;

        this.executeCellValueChange(rowIndex, colIndex, oldValue, newValue);

        const cell: Cell = GridCellComponent.getCellProperties(this.field, rowIndex, colIndex);

        if (cell.suppressValidation) {
            delete cell.suppressValidation; // one time shot
        } else {
            try {
                if (!this.formhelper.isSearch) {
                    this.field.api.validateCell(rowIndex, colIndex, false);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    focus(): void {
        if (this.control.disabled) {
            return;
        }

        const col: TodoColDef = this.param.colDef;

        const rowIndex: number = this.rowNode.rowIndex,
            colIndex: number = col.index,
            value: string = this.input.input.nativeElement.value;

        if (this.field.eventScripts != void 0 && typeof (this.field.eventScripts.onCellFocus) == "function") {
            const cell: ScriptCell = {
                rowIndex,
                colIndex,
                value
            };

            this.field.eventScripts.onCellFocus(this.formhelper, this.formhelper.globals, this.clientScriptService.getGlobalScriptingStorage(), this.field, cell);
        }
    }

    private executeCellValueChange(rowIndex: number, colIndex: number, oldValue: string, newValue: string) {
        if (newValue != oldValue) {
            if (this.field.eventScripts != void 0 && typeof (this.field.eventScripts.onCellChange) == "function") {
                const cell: ScriptCell = {
                    rowIndex,
                    colIndex,
                    value: newValue
                };

                this.field.eventScripts.onCellChange(this.formhelper, this.formhelper.globals, this.clientScriptService.getGlobalScriptingStorage(), this.field, cell);
            }
        }
    }

    private setAutoCompleteConfig(): void {
        if (this.colfield.model.autoCompleteConfig != undefined) {
            this.eobAutocomplete = this.colfield.model.autoCompleteConfig;
            this.cdRef.detectChanges();
        }
    }

    refresh(params: GridCellParams): boolean {
        return false;
    }

    keyDownHandler(event: Event): void {
        event.stopPropagation();
    }

    ngOnDestroy(): void {
        this.updateAutocompleteSubscription.unsubscribe();
    }
}
