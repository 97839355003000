export function DmsBaseparamsConfig(): { [key: string]: string[] } {
    const base: string[] = ["creator", "created", "modifier", "modifyDate", "owner"];

    const linksBase: string[] = ["links"];
    const linksDocument: string[] = [...linksBase, "notes", "annotations"];

    const contentDocument: string[] = ["locked", "archiveState", "archivist", "archiveDate", "retentionPlannedDate", "retentionDate", "pageCount"];

    const systemBase: string[] = ["systemId"];
    const systemDocument: string[] = [...systemBase, "foreignId", "version"];

    return {
        container: [...base, ...linksBase, ...systemBase],
        document: [...base, ...linksDocument, ...systemDocument, ...contentDocument],

        fallbackZero: ["systemId", "version", "foreignId"],
        fallbackUnknown: ["owner"],

        dateParams: ["archiveDate", "created", "modifyDate", "retentionDate", "retentionPlannedDate"],
        translateParams: ["archiveState", "locked"]
    };
}