import {Injectable} from "@angular/core";
import { FieldDataType } from "ENUMS_PATH/field.enum";
import { FormFieldType } from "MODULES_PATH/form/enums/form-field-type.enum";

/**
 * Collection of general tools used throughout the webclient
 */
@Injectable({ providedIn: "root" })
export class ToolService {
    static readonly futureEnd: number = 2147483647000;

    compareArrays(a: string[]|number[], b: string[]|number[]): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        a.sort();
        b.sort();

        for (let i: number = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    /**
     * Capitalize a string taking ß into account
     *
     * @param {string} str String to be capitalized
     * @returns {string} Capitalized string
     */
    capitalizeString = (str: string): string => {
        for (let i = 0, len: number = str.length; i < len; i++) {
            if (str.charAt(i) !== "ß") {
                str = str.substring(0, i) + str.charAt(i).toUpperCase() + str.substring(i + 1);
            }
        }
        return str;
    };

    /**
     * Calculates a date range based on the expression string
     *
     * @param expression Expression to parse
     * @param {"date" | "datetime"} type Type of field
     * @returns {string} Expression enriched by the current time or epoch start (start-end)
     * Example expressions:
     *
     *  1504821600000
     *  <1504821600000
     *  <=1504821600000
     *  >1504821600000
     *  >=1504821600000
     *  1504821600000-1504821600000
     */
    getRangeByExpression = (expression: string, type?: FieldDataType.DATE | FieldDataType.TIME | FieldDataType.DATETIME | FormFieldType.DATE | FormFieldType.DATETIME | FormFieldType.TIME): string => {
        if (expression == void 0 || expression.length < 1) {
            return "";
        }

        let result = "";

        const parts: string[] = expression.split(/(\d+)/).filter(Boolean);
        if (parts.length == 1) {
            const date: Date = new Date(parseInt(expression, 10));
            if (this.isDateOnly(date)) {
                result = `${expression}-${date.setDate(date.getDate() + 1) - 1000}`;
            } else {
                result = `${expression}-${expression}`;
            }
        } else if (parts.length == 2) {
            const operator: string = parts[0];
            const date: Date = new Date(parseInt(parts[1], 10));
            switch (operator) {
                case ">" :
                    if (type == "datetime") {
                        result = `${date.getTime() + 1000}-${ToolService.futureEnd}`;
                    } else {
                        date.setDate(date.getDate() + 1);
                        result = this.getRangeFromMidnight(date);
                    }
                    break;
                case ">=" :
                    if (type == "datetime") {
                        result = `${date.getTime()}-${ToolService.futureEnd}`;
                    } else {
                        result = this.getRangeFromMidnight(date);
                    }
                    break;
                case "<" :
                    if (type == "datetime") {
                        result = `0-${date.getTime() - 1000}`;
                    } else {
                        date.setDate(date.getDate() - 1);
                        result = this.getRangeToMidnight(date);
                    }
                    break;
                case "<=" :
                    if (type == "datetime") {
                        result = `0-${date.getTime()}`;
                    } else {
                        result = this.getRangeToMidnight(date);
                    }
                    break;
                default:
                    result = `${date.getTime()}-${date.setDate(date.getDate() + 1) - 1000}`;
            }
        } else if (parts.length == 3) {
            const dateBegin: Date = new Date(parseInt(parts[0], 10));
            const dateEnd: Date = new Date(parseInt(parts[2], 10));

            if (this.isDateOnly(dateEnd)) {
                dateEnd.setDate(dateEnd.getDate() + 1);
                dateEnd.setHours(0, 0, 0, 0);

                if (dateBegin > dateEnd) {
                    result = `${dateEnd.getTime()}-${dateBegin.getTime() - 1000}`;
                } else {
                    result = `${dateBegin.getTime()}-${dateEnd.getTime() - 1000}`;
                }

            } else {
                result = expression;
            }
        }
        return result;
    };

    createGUID = (): string => {

        const crypto: Crypto = window.crypto ||
            (window as any).msCrypto || {
                getRandomValues(array: Uint8Array): Uint8Array {
                    for (let i = 0, l: number = array.length; i < l; i++) {
                        array[i] = Math.floor(Math.random() * 256);
                    }
                    return array;
                }
            };
        return "10000000100040008000100000000000".replace(/[018]/g, (c: string): string => {
                const d: number = parseInt(c, 10);
                return (d ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> d / 4).toString(16).toUpperCase();
            }
        );
    };

    /**
     * converts a blob to a File by adding it to a formData and returning the set file
     *
     * @param {Blob} blob
     * @param {string} fileName
     * @returns {File}
     */
    blob2file = (blob: Blob, fileName: string): File => {
        const fd: FormData = new FormData();
        fd.set("a", blob, fileName);
        return fd.get("a") as File;
    };

    /**
     * Sanitizes a string to filename compatibility.
     *
     * @param {string} name - name which should be sanitise
     * @return {string} - the sanitise name
     */
    static nameToFilename(name: string): string {
        if (!name) {
            return "";
        }
        if (name.length > 120) {
            const ext: string[] = name.match(/\.([^.]*)$/gi);
            if (ext?.length) {
                name = `${name.substring(0, 120 - ext[0].length)}${ext[0]}`;
            }
        }
        return name.replace(/([\\/:*?"<>|#\r\n]|&lt;|&gt;)/g, "-");
    }

    /**
     * Tests whether given text starts with subtext
     * (IE11 does not support string.startsWith)
     *
     * @param text
     * @param subtext
     *
     * @return true, if given text starts with subtext, otherwise false
     */
    startsWith(text: string, subtext: string): boolean {
        if (text == void 0 || text.length == void 0 || subtext == void 0 || subtext.length == void 0) {
            return false;
        }

        return text.substring(0, subtext.length) === subtext;
    }

    /**
     * Tests whether given text ends with subtext
     * (IE11 does not support string.endsWith)
     *
     * @param text
     * @param subtext
     *
     * @return {boolean} true, if given text ends with subtext, otherwise false
     */
    endsWith(text: string, subtext: string): boolean {
        if (text == void 0 || text.length == void 0 || subtext == void 0 || subtext.length == void 0) {
            return false;
        }

        return text.substring(text.length - subtext.length, text.length) === subtext;
    }

    /**
     * Checks whether the given field name is a base param text field
     *
     * @param {string} fieldName
     * @returns {boolean}
     */
    isBaseParamTextField(fieldName: string): boolean {
        let result = false;

        if (fieldName == "Creator") {
            result = true;
        } else if (fieldName == "Modifier") {
            result = true;
        } else if (fieldName == "Owner") {
            result = true;
        }

        return result;
    }

    /**
     * Checks whether the given field name is a base param datetime field
     *
     * @param {string} fieldName
     * @returns {boolean}
     */
    isBaseParamDateTimeField(fieldName: string): boolean {
        let result = false;

        if (fieldName == "Created") {
            result = true;
        } else if (fieldName == "Modified") {
            result = true;
        } else if (fieldName == "ArchiveDate") {
            result = true;
        } else if (fieldName == "RetentionDate") {
            result = true;
        } else if (fieldName == "RetentionPlannedDate") {
            result = true;
        }

        return result;
    }

    /**
     * Creates a time range from midnight of the passed {@link Date} to the end of the 32bit unix time epoch
     *
     * @param {Date} date
     * @returns {string}
     */
    private getRangeFromMidnight(date: Date): string {
        date.setHours(0, 0, 0, 0);

        return `${date.getTime()}-${ToolService.futureEnd}`;
    }

    /**
     * Creates a time range to midnight of the passed {@link Date} from the unix time epoch start
     *
     * @param {Date} date
     * @returns {string}
     */
    private getRangeToMidnight(date: Date): string {
        date.setHours(23, 59, 59, 0);

        return `0-${date.getTime()}`;
    }

    /**
     * 08.09.2017 00:00:00 -> true
     * 08.09.2017 11:30:00 -> false
     */
    private isDateOnly(date: Date): boolean {
        return date.getHours() == 0
            && date.getMinutes() == 0
            && date.getSeconds() == 0;
    }

    /**
     * Performs a deep merge of objects and returns a new object. Does not modify
     * objects (immutable) and merges arrays via concatenation.
     */
    mergeDeep(...objects: any): any {
        const isObject: (obj: any)=>boolean = obj => obj && !Array.isArray(obj) && typeof obj === "object";
        const isFn: (obj: any)=>boolean = obj => obj && typeof obj === "function";

        return objects.reduce((prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal: any = prev[key];
                const oVal: any = obj[key];

                if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = this.mergeDeep(pVal, oVal);
                } else if (isFn(pVal) && isFn(oVal)) {
                    prev[key] = (...args) => {
                     pVal(...args); oVal(...args);
                    };
                } else {
                    prev[key] = oVal;
                }
            });

            return prev;
        }, {});
    }
}
