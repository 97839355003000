(function() {
    "use strict";

    //get module and attach directive
    angular.module("eob.framework").directive("eobRightClick", EobRightClick);
    //dependency injection here

    EobRightClick.$inject = [];

    function EobRightClick() {
        return {
            restrict: "A",
            scope: {
                callback: "=eobRightClick",
                item: "=rightClickItem"
            },
            link(scope, element) {

                element.bind("contextmenu", (event) => {
                    event.preventDefault();
                    event.stopImmediatePropagation();

                    scope.callback(scope.item, event);
                });

                scope.$on("$destroy", () => {
                    element.unbind();
                });
            }
        };
    }
})();
