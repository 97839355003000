import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";
import {FormFieldType} from "MODULES_PATH/form/enums/form-field-type.enum";
import {CorrectionFieldMask} from "MODULES_PATH/form/models/field-mask.model";
import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export class DateMask extends CorrectionFieldMask {
    private readonly typeFormat: string;

    constructor(private type: FormFieldType, private dateFormat: {[key: string]: string}) {
        super();
        this.typeFormat = this.type == FormFieldType.DATETIME ? this.dateFormat.datetime : this.dateFormat.date;
    }

    parseValue(value: FormInputValue): FormInputValue {
        if (value === undefined || value == "") {
            return "";
        }

        if (!isNaN(Number(value)) && this.type != FormFieldType.TIME) { //timestamp
            value = dayjs(parseInt(value.toString())).format(this.typeFormat);
        } else if (/[/.]+/.test(value.toString())) {
            if (this.type == FormFieldType.DATETIME) {
                value = dayjs(value.toString().replace(/\//g, "."), ["DD.MM.YYYY", "DD.MM.YYYY HH:mm:ss", "DD.MM.YYYY H:mm:ss", "DD.MM.YYYY HH:mm", "DD.MM.YYYY H:mm", "DD.MM.YYYY"], true).format(this.typeFormat);
            } else {
                value = dayjs(value.toString().replace(/\//g, "."), "DD.MM.YYYY", true).format(this.typeFormat);
            }
        } else {
            const m = dayjs(value);
            if (m.isValid()) {
                value = m.format(this.typeFormat);
            }
        }
        return value;
    }
}
