import * as angular from "angular";

(function() {
    require("SERVICES_PATH/viewer/documentviewer.viewer.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");

    const dvDragDrop = require("SERVICES_PATH/viewer/documentviewer.dragdrop");

    angular.module("eob.framework").directive("eobDocumentViewer", EobDocumentViewer);

    EobDocumentViewer.$inject = ["documentViewer", "viewerService", "environmentService", "clientService", "$compile"];

    function EobDocumentViewer(DocumentViewer, ViewerService, EnvironmentService, ClientService, $compile) {
        return {
            restrict: "E",
            link(scope, element) {

                scope.useAnnos = !ClientService.isPhoneOrTablet();

                if (!EnvironmentService.useDocumentViewer()) {
                    return;
                }
                dvDragDrop(jQuery);

                let viewer = DocumentViewer.getViewer();
                ViewerService.setViewerContainer(element);

                DocumentViewer.init(element, ViewerService);

                scope.recreate = function() {
                    let id = viewer.documentId;

                    if (id != void 0) {
                        ViewerService.refreshContent(id);
                    }
                };

                scope.switchZoom = function() {
                    viewer.view.switchZoom();
                };

                scope.switchSearch = function() {
                    viewer.view.switchSearch();
                };

                scope.nextSearchHit = function() {
                    viewer.view.nextSearchHit();
                };

                scope.prevSearchHit = function() {
                    viewer.view.prevSearchHit();
                };

                scope.zoom = function(factor) {
                    viewer.view.setCustomZoom(factor);
                    viewer.view.adjustDimensions();
                };

                scope.animateThumbnails = function() {
                    viewer.view.toggleThumbnails();
                };

                scope.toggleAttachments = function() {
                    viewer.view.toggleAttachments();
                };

                scope.goToParent = function() {
                    viewer.goToParent();
                };

                scope.prevPage = function() {
                    viewer.prevPage();
                };

                scope.nextPage = function() {
                    viewer.nextPage();
                };

                element.drag("start", (ev, dd) => {

                    viewer.view.removeTextSelection();

                    return $("<div class=\"selection\" />").css("opacity", .65).css("position", "absolute").css("z-index", "100").appendTo(document.body);
                }).drag((ev, dd) => {
                    $(dd.proxy).css({
                        top: Math.min(ev.pageY, dd.startY),
                        left: Math.min(ev.pageX, dd.startX),
                        height: Math.abs(ev.pageY - dd.startY),
                        width: Math.abs(ev.pageX - dd.startX)
                    });
                }).drag("end", (ev, dd) => {
                    $(dd.proxy).remove();
                });
            },
            template: require("!raw-loader!./eob.document.viewer.html")
        };
    }
})();
