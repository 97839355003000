<div class='page-control'>
    <div class='page-control-header'>
        <div class='page-control-wrapper'>
            <ul class='page-control-tabs' tabindex='0' [attr.lang] ="showAltLang ? objectDefLang : null">
                <li *ngFor="let page of field.model.pages; let i = index" class='page-control-tab' internal='page.model.internal' [attr.data-index]="i" (click)="tabClickHandler($event)">
                    <button class="button-transparent">
                        <i [class]="page.iconClass" class="eob-icon-16" *ngIf="page.iconClass"></i>
                        <span>{{page.title}}</span>
                    </button>
                </li>
            </ul>
        </div>
        <div class='tab-scroll-buttons'>
            <button class='tab-scroll-left button-transparent'>
                <div class='tab-scroll-image left' role="img" [attr.aria-label]="('eob-form-page-control.left' | ngTranslate)">
                    <eob-icon name="start-search" size="16"></eob-icon>
                </div>
            </button>
            <button class='tab-scroll-right button-transparent'>
                <div class='tab-scroll-image right' role="img" [attr.aria-label]="('eob-form-page-control.right' | ngTranslate)">
                    <eob-icon name="start-search" size="16"></eob-icon>
                </div>
            </button>
        </div>
    </div>

    <div class='page-control-content'>
        <div *ngFor="let page of field.model.pages; let i = index" [attr.data-index]="i" [class.active]= "i=='0'" class='page-control-page-content form'>
            <eob-responsive-form-component [layout]="layoutField.pages[i]"
                                       [formdata]="formdata"
                                       [formhelper]="formhelper"
                                       [formid]="formid">
            </eob-responsive-form-component>
        </div>
    </div>

</div>

