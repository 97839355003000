/**
 * api controller
 * everything related to the api of a virtual grid instance goes here
 */
export class VirtualGridApi {

    private debounceTimeout: any = null;
    private gPadding = 16;
    private useMultiSelect = false;
    private useCheckboxSelection = false;
    private isVariantManagement = false;
    private lastClickedRow;

    constructor(protected grid: any) {

    }

    /**
     * updates the config properties
     *
     * @param config
     */
    updateConfigProperties = (config: any): void => {
        this.useMultiSelect = config.useMultiSelect;
        this.useCheckboxSelection = config.useCheckboxSelection;
        this.isVariantManagement = !!config.isVariantManagement;
    };

    /**
     * clear the grid and reset all values
     */
    clear = (): void => {
        for (const row of this.grid.renderedRows) {
            row.listNode.empty();
        }

        this.grid.gridWrapper.css({"overflow-y": "hidden"});
        this.grid.gridWrapper.scrollTop(0);
        this.grid.listElement.height(0);

        this.grid.gridWrapper.css({"overflow-y": "auto"});
    };

    /**
     * refresh the grid and redraw the nodes
     * debounce the refresh in case multiple updates would be made in a short period of time like deleting rows
     *
     * @param {boolean} immediate - refreshes the grid immediate or after a timeout
     */
    refreshGrid = (immediate: boolean = false): void => {
        if (this.grid == void 0) {
            return;
        }

        if (this.grid.renderedRows.length > this.grid.renderedRowCount) {
            console.error("Rendered row count exceeded the configured maximum!!!");
        }

        clearTimeout(this.debounceTimeout);

        if (immediate) {
            this._refresh();
        } else {
            this.debounceTimeout = setTimeout(() => this._refresh.bind(this), 50);
        }

        this._refresh();
    };

    /**
     * refreshes the grid and redraws rows
     */
    private _refresh(): void {
        try {
            if (this.grid === void 0) {
                return;
            }
            this.grid.rowController.rebuildVisibleRowMap();
            this.grid.calculateGridHeight();

            this.grid.rowController.calculateRowPosition();
            this.grid.rowController.toggleRenderedRowVisibility();

            this.grid.rowController.renderRows(true);
            this.grid.columnController.refreshColumns();
        } catch (error) {
            console.warn(`VirtualGrid _refresh() failed: ${error}`);
        }
    }

    /**
     * rebuilds the rows and columns and refreshes the grid afterwards
     *
     * @param config
     */
    updateGridContent = (config: any): void => {
        this.grid.updateConfigProperties();

        this.grid.rowController.resetRenderedRows();

        // processing row data and convert into a more suitable structure
        this.grid.rowController.buildRows(config.rows.slice());

        // calculating columns etc
        this.grid.columnController.buildColumns();
        // creating dom nodes for each cell
        this.grid.rowController.buildCellContent();

        // refreshing the visual projection
        this.refreshGrid(true);
    };

    /**
     * Updates the rows in the grid and refreshes the grid
     *
     * @param {Array<any>} rows
     * @param {boolean=} resetRowConfig
     */
    updateGridRows = (rows: any[], resetRowConfig: boolean): void => {
        // resetting and setting general properties
        this.grid.updateConfigProperties();

        if (resetRowConfig) {
            this.grid.rowController.resetRenderedRows();
        }

        // processing row data and convert into a more suitable structure
        this.grid.rowController.buildRows(rows);

        // refreshing the visual projection
        this.grid.api.refreshGrid(true);
    };

    /**
     * returns alls rows
     *
     * @returns {Array<any>}
     */
    getRows = (): any[] => this.grid == void 0 ? [] : this.grid.rows;

    /**
     * return the row where key and value are matching
     *
     * @param {string} key   - the key to use
     * @param {string} value - the value to find
     * @returns {any}
     */
    getRowByKey = (key: string, value: string): any => {

        if (this.grid == void 0) {
            return;
        }

        for (const i in this.grid.rows) {
            if (this.grid.rows[i][key] == value) {
                return this.grid.rows[i];
            }
        }

        return null;
    };

    /**
     * return all visible rows
     *
     * @returns {Array<any>}
     */
    getVisibleRows = (): any[] => {
        const rows: any[] = [];

        for (const i in this.grid.rows) {
            if (this.grid.rows[i].isVisible) {
                rows.push(this.grid.rows[i]);
            }
        }

        return rows;
    };

    /**
     * return the selected rows
     *
     * @returns {Array<any>}
     */
    getSelectedRows = (): any[] => this.grid == void 0 ? [] : this.grid.rowController.selectedRows;

    /**
     * return the row count
     *
     * @returns {number}
     */
    getRowCount = (): number => this.grid == void 0 ? 0 : this.grid.rows.length;

    /**
     * select one or multiple rows according to ctrl and shift key
     *
     * @param row - the clicked row
     * @param {boolean} useCtrl - boolean if the ctrl key is used
     * @param {boolean} useShift - boolean if the shift key is used
     * @param {boolean} isCheckboxSelect - boolean - true if checkbox was clicked (only tablet)
     */
    select = (row: any, useCtrl: boolean = false, useShift: boolean = false, isCheckboxSelect: boolean = false): void => {

        if (row.isSelected) {
            if(this.useCheckboxSelection){
                this.deselectRow(row);
            } else if (!useCtrl && !useShift && !isCheckboxSelect) {
                this.deselectAll();
                this.selectRow(row);
                this.lastClickedRow = row;
            } else if (useCtrl || isCheckboxSelect && !useShift) {
                this.deselectRow(row);
            }

            return;
        }

        // in this case we deselect all other selected rows
        // no multi selection in the variant management state, so we deselect all and select the clicked row
        if (((!useCtrl && !useShift) && !this.useCheckboxSelection || !this.useMultiSelect ) || this.isVariantManagement) {
            this.deselectAll();
        }

        if (useShift && !this.isVariantManagement) {
            if (this.grid.rowController.selectedRows.length > 0 && this.lastClickedRow) {
                const lastSelectedIndex: number = this.lastClickedRow.index;
                const currentIndex: number = row.index;

                const min: number = Math.min(lastSelectedIndex, currentIndex);
                const max: number = Math.max(lastSelectedIndex, currentIndex);

                for (let i: number = min; i <= max; i++) {
                    if (this.grid.rows[i].isSelectable && !this.grid.rows[i].isSelected) {
                        this.selectRow(this.grid.rows[i]);
                    }
                }
            } else {
                this.selectRow(row);
            }
        } else {
            this.selectRow(row);
        }

        this.lastClickedRow = row;

        this.grid.rowController.renderRows();
    };

    /**
     * select the row with the given index
     *
     * @param {number|string} index - the index of the row to select
     */
    selectIndex = (index: string): void => {
        const row: any = this.getRowByKey("index", index);
        if (row != void 0) {
            this.select(row);
        }
    };

    /**
     * select the given values if possible
     * scroll to the first selected row
     * render the nodes
     *
     * @param values - the values to select
     * @param selectCaseInsensitive - boolean whether to select case insensitive or not
     */
    selectValues = (values: any, selectCaseInsensitive: boolean = false, type?: string, selected?: boolean): void => {

        if (values == void 0 || values == "") {
            return;
        }

        if (!Array.isArray(values)) {
            values = [values];
        }

        if (!this.useMultiSelect) {
            values = [values[0]];
        }

        const valueList: string[] = [];
        for (const i in values) {
            const value: string = selectCaseInsensitive ? values[i].toLowerCase() : values[i];
            valueList.push(value);
        }

        for (const row of this.grid.rows) {

            if (type == void 0 || type == row.type) {
                for (const col of this.grid.columnController.columns) {

                    const cellShortValue: string = row.short;
                    const cellValue: string = row[col.field];

                    if (cellValue == void 0) {
                        continue;
                    }

                    const cellShortValueUpperCase: string = cellShortValue != void 0 ? cellShortValue.toUpperCase() : cellShortValue;
                    const cellValueUpperCase: string = cellValue != void 0 && selectCaseInsensitive ? cellValue.toUpperCase() : cellValue;
                    if (valueList.find(x => x.toUpperCase() == cellShortValueUpperCase)
                        || valueList.find(x => x.toUpperCase() == cellValueUpperCase)) {
                        if (selected) {
                            this.deselectRow(row);
                        } else {
                            this.selectRow(row);
                        }

                        break;
                    }
                }
            }

        }

        if (type == void 0) {
            if (this.grid.rowController.selectedRows[0] != void 0) {
                this.scrollToIndex(this.grid.rowController.selectedRows[0].index);
            }

            this.grid.rowController.renderRows();
        }
    };

    /**
     * select the given values by the given paths (the path is parent/child related)
     * scroll to the first selected row
     * render the nodes
     *
     * @param valuePaths - the given paths to the values
     * @param selectCaseInsensitive - boolean whether to select case insensitive or not
     */
    selectValuesByPaths = (valuePaths: string[], selectCaseInsensitive: boolean = false): void => {

        for (const path of valuePaths) {
            const pathString: string = path[path.length - 1];
            const elementToFind: string = selectCaseInsensitive ? pathString.toLowerCase() : pathString;

            for (const row of this.grid.rows) {

                for (const col of this.grid.columnController.columns) {
                    let cellValue: any = row[col.field];

                    if (cellValue == void 0) {
                        continue;
                    }

                    cellValue = selectCaseInsensitive ? cellValue.toLowerCase() : cellValue;
                    if (cellValue == elementToFind) {
                        const currentPath: string[] = [];
                        currentPath.push(elementToFind);

                        let currentItem: any = row;
                        let hasParent: boolean = currentItem.parent != void 0;

                        while (hasParent) {
                            currentItem = currentItem.parent;

                            currentPath.unshift(currentItem[col.field]);

                            if (currentItem.parent == void 0) {
                                hasParent = false;
                            }
                        }

                        if (path.toString().toLowerCase() === currentPath.toString().toLowerCase()) {
                            this.selectRow(row);
                            break;
                        }
                    }
                }
            }
        }

        if (this.grid.rowController.selectedRows[0] != void 0) {
            this.scrollToIndex(this.grid.rowController.selectedRows[0].index);
        }

        this.grid.rowController.renderRows();
    };

    /**
     * deselect all rows
     */
    deselectAll = (): void => {

        for (const renderedRow of this.grid.renderedRows) {
            renderedRow.listNode.removeClass("selected");
        }

        for (const i in this.grid.rows) {
            this.grid.rows[i].isSelected = false;
        }

        this.grid.rowController.selectedRows = [];
    };

    /**
     * select a single row
     *
     * @param row
     */
    private selectRow(row: any): void {

        if (!row.isSelectable) {
            return;
        }

        row.isSelected = true;

        this.grid.rowController.selectedRows.push(row);

        for (const renderedRow of this.grid.renderedRows) {

            if (renderedRow.index == row.index) {
                this.toggleSelectionClasses(renderedRow);
            }
        }
    }

    /**
     * deselect a single row
     *
     * @param row
     */
    private deselectRow(row: any): void {

        row.isSelected = false;

        for (const i in this.grid.rowController.selectedRows) {
            if (this.grid.rowController.selectedRows[i].index == row.index) {
                this.grid.rowController.selectedRows.splice(i, 1);
                break;
            }
        }

        for (const renderedRow of this.grid.renderedRows) {
            if (renderedRow.index == row.index) {
                renderedRow.listNode.removeClass("selected");
                break;
            }
        }
    }

    /**
     * Alter the selection class of the row
     *
     * @param row
     */
    private toggleSelectionClasses(row: any): void {
        if (this.grid.rows[row.index].isSelected) {
            row.listNode.addClass("selected");
        } else {
            row.listNode.removeClass("selected");
        }

        if (!this.grid.rows[row.index].isSelectable) {
            row.listNode.addClass("not-selectable");
            row.listNode.removeClass("selectable");
        } else {
            row.listNode.addClass("selectable");
            row.listNode.removeClass("not-selectable");
        }
    }

    /**
     * scroll to a specific node by index
     * the node should be visible in the center of the grid if possible
     *
     * @param {Number} index - The Index we have to scroll to
     * @param {Boolean} scrollHorizontal - Whether to scroll horizontally or not
     */
    scrollToIndex = (index: number, scrollHorizontal: boolean = true): void => {

        let visibleItemsBefore = 0;

        for (const row of this.grid.rows) {

            if (row.index == index) {
                break;
            }

            if (row.isVisible) {
                visibleItemsBefore++;
            }
        }

        const scrollLeftPosition: number = this.grid.rows[index].level * this.gPadding;
        const scrollTopPosition: number = (visibleItemsBefore * this.grid.config.rowHeight) - (this.grid.config.scrollContainer.height() / 2);

        this.grid.gridWrapper.scrollTop(scrollTopPosition, false);

        if (scrollHorizontal && scrollLeftPosition > (this.grid.config.scrollContainer.width() / 2)) {
            this.grid.gridWrapper.scrollLeft(scrollLeftPosition / 2, false);
        }
    };

    /**
     * filter all gridelements based on the given string and set each row visible or not
     *
     * @param value - the value to search for (we only allow values that begin with the given value)
     * @param expandParents
     */
    setFilter = (value: string, expandParents: boolean): void => {

        const regex = new RegExp(value.replace(/[^A-Za-z0-9_]/g, "\\$&"), "i");
        const filteredArray: any[] = this.grid.rows.slice().filter((row: any) => {
            for (const col of this.grid.columnController.columns) {
                if (!col.isIconColumn) {
                    const cellValue: string = row[col.field];
                    // Show child elements if filter value matches any part of the given parent node
                    if (((row.parent || {}).value || "").toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return true;
                    }

                    if (regex.test(cellValue)) {
                        return true;
                    }
                }
            }
            return false;
        });

        const visibleRowIndices: number[] = [];

        for (const item of filteredArray) {
            visibleRowIndices.push(Number(item.index));

            let currentItem: any = item;
            let hasParent: boolean = currentItem.parent != void 0;
            let addedParentCount = 0;

            // adding parents to the visible rows and expanding them
            while (hasParent) {
                currentItem = currentItem.parent;
                if (expandParents) {
                    currentItem.expanded = true;
                }
                visibleRowIndices.splice(visibleRowIndices.length - 1 - addedParentCount, 0, currentItem.index);
                addedParentCount++;
                if (currentItem.parent == void 0) {
                    hasParent = false;
                }
            }
        }

        for (const i in this.grid.rows) {
            this.grid.rows[i].isVisible = visibleRowIndices.includes(this.grid.rows[i].index);
            this.grid.rows[i].isFiltered = !this.grid.rows[i].isVisible;
        }

        this.grid.config.scrollContainer.scrollTop(0);

        this.grid.rowController.calculateRowPosition(0, visibleRowIndices[0]);
        this.grid.rowController.rebuildVisibleRowMap();
        this.grid.calculateGridHeight();
        this.grid.rowController.toggleRenderedRowVisibility();
        this.grid.rowController.renderRows(true);
    };

    /**
     * Remove a row by the given key value pair
     *
     * @param key - the key to look for
     * @param value - the value to match
     */
    removeRowByKey = (key: string, value: string): void => {

        for (const row of this.grid.rows) {

            if (row[key] == value) {
                this.removeRow(row);
                this.deselectRow(row);
                break;
            }
        }
    };

    /**
     * remove multiple rows
     *
     * @param rows - an array of rows
     */
    removeRows = (rows: any[]): void => {
        rows.forEach(this.removeRow);
    };

    /**
     * remove a single row
     *
     * @param row - the row to delete
     */
    removeRow = (row: any): void => {
        // instead of just removing the nodes, we detach them and won't reattach them :P
        this.grid.rowController.detachRowByIndex(row.index);
        this.grid.rowController.setRowIndexes();

        if (row.parent != void 0) {
            row.parent.childCountTotal = this.grid.rowController.getCompleteChildCount(row.parent.index);
        }

        this.refreshGrid();
    };

    /**
     * convenience api function
     * implementation see RowController.moveRow
     *
     * @param {number} rowIndexToMove
     * @param {number} rowIndexToAppend
     * @param {boolean} appendAsChild
     */
    moveRow = (rowIndexToMove: number, rowIndexToAppend: number, appendAsChild: boolean): void => {
        this.grid.rowController.moveRow(rowIndexToMove, rowIndexToAppend, appendAsChild);
    };

    /**
     * convenience api function
     * implementation see RowController.insertRows
     *
     * @param {number} rowIndexToInsert
     * @param {Array<any>} rowsToInsert
     * @param {boolean} insertAsChilds
     */
    insertRows = (rowIndexToInsert: number, rowsToInsert: any[], insertAsChilds: boolean): void => {
        this.grid.rowController.insertRows(rowIndexToInsert, rowsToInsert, insertAsChilds);
    };

    /**
     * expand or collapse a row
     *
     * @param row - the row to open or close
     * @param expand - whether the row shall be expand or collapsed
     */
    toggleRow = (row: any, expand: boolean): void => {
        this.grid.rowController.toggleRow(row, expand);
    };

    /**
     * refreshes the column headers and executes the headervaluegetter
     */
    refreshHeader = (): void => {
        this.grid.columnController.refreshColumns();
    };
}
