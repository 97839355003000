import {Component} from "@angular/core";
import {AutoCompleteService} from "MODULES_PATH/autocomplete/services/autocomplete.service";

@Component({
    selector: "eob-autocomplete-wrapper",
    templateUrl: "./autocomplete-wrapper.component.html",
    styleUrls: ["./autocomplete-wrapper.component.scss"]
})
export class AutocompleteWrapperComponent {
    autocompleteValues: number[] = new Array(10);

    constructor(private autoCompleteService: AutoCompleteService) {
    }

    executeCallback(index: number): void {
        this.autoCompleteService.listClickCallback(index);
    }
}

