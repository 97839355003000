import {takeWhile} from "rxjs/operators";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {CustomDashletEvent} from "../../../../app/modules/dashlet/enums/custom-dashlet-event.enum";
require("COMPONENTS_PATH/eob-dropzone/eob.dropzone.dir.js");

require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/eob.backend.srv.js");
require("MODULES_PATH/dms/dms-content.service");

const { saveAs } = require("file-saver");

angular.module("eob.framework").directive("eobModalEditContent", EobModalEditContent);

EobModalEditContent.$inject = ["$filter", "$rootScope", "$eobConfig", "environmentService", "backendService",
    "notificationsService", "dmsActionService", "viewerService", "dmsContentService", "progressbarService", "clientService", "messageService"];

// eslint-disable-next-line max-params, require-jsdoc
function EobModalEditContent($filter, $rootScope, $eobConfig, EnvironmentService, BackendService,
                             NotificationsService, DmsActionService, ViewerService, DmsContentService, ProgressbarService, ClientService, MessageService) {
    return {
        scope: {
            dmsDocument: "=item",
            deferred: "=",
            attributes: "=",
            destroy$: "=destroy"
        },
        link(scope, element) {
            let editableObjectTypes = ["1", "2", "3", "4"];
            let downloading = false;
            let destroyed = false;
            let adding = false;
            let docModel = scope.dmsDocument.model;
            let currentDocumentDetails;

            scope.isEditDocumentContent = docModel.hasContent && editableObjectTypes.indexOf(docModel.subType) != -1;
            scope.loadExternal = !isInsertTypelessToWfTray();
            scope.showDropzone = true;
            scope.dropzoneConfig = {
                id: docModel.id,
                objectTypeId: docModel.objectTypeId,
                mainType: docModel.subType,
                isEditContent: true,
                isModal: true,
                destroy$: scope.destroy$
            };

            // if the modal dialog was opened from script
            if (scope.attributes !== void 0) {
                scope.dropzoneConfig.hideTemplates = !scope.attributes.showTemplates;
                scope.dropzoneConfig.showCustomContent = scope.attributes.showCustomContent;
                scope.dropzoneConfig.isFileInfoNeeded = scope.attributes.isFileInfoNeeded;
            }

            (async () => {
                const watcher = scope.$watch("dropzoneConfig.modified", value => {
                    if (value) {
                        const btn = element.find(".download-button");
                        if (btn) {
                            setButtonAttr(btn, true);
                        }
                        watcher()
                    }
                })
                // load the document details needed for the sake of naming this file
                // this gets us a filename like "document.1.png" -> see scope.downloadFile
                if (!isInsertTypelessToWfTray()) {
                    currentDocumentDetails = await BackendService.get(`/documentfiles/${docModel.id}`);
                }
            })();

            scope.downloadFileAsync = async function() {
                let httpConfig = {
                    responseType: "arraybuffer"
                };

                let downloadBtn = element.find(".download-button");
                let progressBarContainer = element.find(".dropzone-content-box")[0];
                let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", progressBarContainer, true);

                try {
                    progressBar.show();
                    if (downloadBtn != void 0) {
                        setButtonAttr(downloadBtn, true);
                    }
                    let response = await BackendService.get(`/documentfiles/${docModel.id}/1`, "", httpConfig);
                    let file = new Blob([response.data], { type: "application/octet-stream" });
                    let name = currentDocumentDetails.files ? currentDocumentDetails.files[0] : currentDocumentDetails.data.files[0];

                    // See dms-content.service.ts extractExtensionFromContentDisposition method
                    if (name.endsWith(".")) {
                        const contentDispositionParts = response.headers.get("Content-Disposition").split(/\./);
                        name += contentDispositionParts[contentDispositionParts.length - 1];
                    }

                    const fileName = scope.dmsDocument.api.buildNameFromIndexData(5, true, true);
                    const fileExtension = name.substring(name.indexOf("."), name.length);
                    saveAs(file, fileName + fileExtension);

                    downloading = true;
                    progressBar.hide();
                    scope.close();
                } catch (error) {
                    console.warn(error);
                    progressBar.hide();
                    NotificationsService.backendError(error, "modal.edit.content.initialize.error");
                }
            }

            /**
             * Determine whether the modal dialog is for the creation of a typeless document in the workflow tray.
             * @returns {boolean} If it is the typeless dialog.
             */
            function isInsertTypelessToWfTray() {
                return docModel.subType != void 0 && docModel.tray == "workflow" && docModel.objectTypeId == void 0;
            }

            /**
             * Creates a typeless document in the workflow tray with the chosen content.
             * @param {object} dropzoneContent - The content of the dropzone.
             */
            async function insertTypelessToWfTrayAsync(dropzoneContent) {
                let payload = {
                    mainType: dropzoneContent.item.mainType,
                    files: dropzoneContent.files
                };

                try {
                    const payloadTransfer = (EnvironmentService.isMicroserviceBackend()) ? payload : $.param(payload);
                    let response = await BackendService.post("/insertTypeless.do?tray=workflow", payloadTransfer, {}, $eobConfig.getOswebBase());
                    let item;

                    if (EnvironmentService.isMicroserviceBackend()) {
                        item = {
                            id: response.data.properties["system:objectId"]["value"],
                            typeId: response.data.properties["system:objectTypeId"]["value"],
                            fileArea: docModel.location
                        };
                    } else {
                        item = response.data;
                        item.fileArea = docModel.location;
                    }

                    $rootScope.$broadcast("workflow.add.typeless.to.file.area", item);
                    NotificationsService.success($filter("translate")("form.create.success"));
                } catch (error) {
                    NotificationsService.backendError(error, "modal.edit.content.save.error");
                }

                scope.close();
            }

            function setButtonAttr(button, disableButton) {
                if (disableButton) {
                    button.attr("disabled", "disabled");
                    button.addClass("disabled");
                } else {
                    button.removeClass("disabled");
                    button.removeAttr("disabled");
                }
            }

            scope.addFileAsync = async function() {
                if (ClientService.isOffline()) {
                    NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                    return;
                }
                if (adding) {
                    return;
                }

                let progressBarContainer = element.find(".dropzone-content-box")[0];
                let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", progressBarContainer, true);
                progressBar.show();

                let dropzoneContent = EnvironmentService.getDropzoneContent();

                if (dropzoneContent.files.length === 0 && dropzoneContent.mode != "template") {
                    NotificationsService.error($filter("translate")("modal.edit.content.missing.files"));
                    adding = false;
                    progressBar.hide();
                    return;
                }

                adding = true;

                if (isInsertTypelessToWfTray()) {
                    insertTypelessToWfTrayAsync(dropzoneContent);
                } else {
                    try {
                        await DmsContentService.addContentToDocumentAsync(dropzoneContent, docModel.id);

                        scope.dmsDocument.api.updateBaseParameters({ "objectCount": dropzoneContent.files.length });

                        await ViewerService.refreshContent(docModel.osid);

                        await DmsActionService.undoCheckOut(scope.dmsDocument);

                        scope.close(dropzoneContent.scriptObject);

                        await ViewerService.updateO365Viewer(docModel.osid, scope.dmsDocument);

                        MessageService.broadcast(CustomDashletEvent.UPDATE_CUSTOM_DASHLET_PROPERTIES, {"regenerate": "1"});
                    } catch (error) {
                        NotificationsService.backendError(error, "modal.edit.content.save.error");
                    }
                }
                progressBar.hide();
            };

            scope.close = function(data) {
                if (destroyed) {
                    return;
                }

                MessageService.broadcast(ModalEvents.DESTROY, data);

                // set this value in case the scope got destroyed
                // this may prevent an error when the backend blocks, the user opens another document
                // and then the callback kicks in --> the new modal dialog will get destroyed
                destroyed = true;
            };

            scope.$on("dropzone.content.changed", () => {
                let saveBtn = element.find(".save-button");
                if (saveBtn != void 0) {
                    if (EnvironmentService.getDropzoneContent().files.length == 0) {
                        setButtonAttr(saveBtn, true);
                    } else {
                        setButtonAttr(saveBtn, false);
                    }
                }

                let downloadBtn = element.find(".download-button");
                if (downloadBtn != void 0 && !scope.dropzoneConfig.modified) {
                    setButtonAttr(downloadBtn, false);
                }
            });

            scope.$on("dropzone.content.queued", () => {
                let saveBtn = element.find(".save-button");
                if (saveBtn != void 0) {
                    setButtonAttr(saveBtn, true);
                }

                let downloadBtn = element.find(".download-button");
                if (downloadBtn != void 0) {
                    setButtonAttr(downloadBtn, true);
                }
            });

            scope.$on("enable.save.button", (event, hasContent) => {
                let saveBtn = element.find(".save-button");
                setButtonAttr(saveBtn, !hasContent);

                let downloadBtn = element.find(".download-button");

                if (downloadBtn != void 0 && !scope.dropzoneConfig.modified && scope.attributes && !scope.attributes.showCustomContent) {
                    setButtonAttr(downloadBtn, false);
                }
            });

            scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => {
                // the user downloaded the document or swapped its content
                // --> no checkout at this point
                // either the file must be locked or will be unlocked by the "addFile" function
                if (ClientService.isOnline()) {
                    if (downloading || adding) {
                        MessageService.broadcast(ModalEvents.DESTROY);
                        angular.element(document.body).find(".dz-hidden-input, .fileInput").remove();
                        return;
                    }

                    if (!isInsertTypelessToWfTray()) {
                        DmsActionService.undoCheckOut(scope.dmsDocument);
                    }
                }

                MessageService.broadcast(ModalEvents.DESTROY);
                angular.element(document.body).find(".dz-hidden-input, .fileInput").remove();
            });
        },
        template: require("!raw-loader!./eob.modal.edit.content.html")
    };
}
