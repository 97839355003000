(function () {
    require("SERVICES_PATH/form/eob.form.srv.js");
    require("SERVICES_PATH/scripting/form/eob.form.helper.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/eob.modal.dialog.srv.js");

    angular.module("eob.framework").directive("eobQuicksearch", EobQuicksearch);

    EobQuicksearch.$inject = ["objectTypeService", "formService", "formHelper", "formFieldModelService", "$filter",
        "actionService", "environmentService", "modalDialogService", "cacheManagerService"];

    function EobQuicksearch(ObjectTypeService, FormService, FormHelper, FormFieldModelService, $filter,
                            ActionService, EnvironmentService, ModalDialogService, CacheManagerService) {
        return {
            restrict: "E",
            scope: {
                search: "="
            },
            link(scope) {

                let types = [...scope.search.types.keys()];
                let isSubmitting = false;

                scope.ready = false;
                scope.formDefs = [];
                scope.error;

                scope.submit = function (event) {
                    submitQuickSearch(scope.formDefs, event)
                };

                scope.showAltLang = !EnvironmentService.uiLangIsObjectDefLang();
                scope.objectDefLang = EnvironmentService.getObjectDefinitionLanguage();

                init();

                function init() {

                    if (scope.search.isCorrupted) {
                        scope.error = scope.error = $filter("translate")("quicksearch.error.missing.fields");
                        scope.ready = true;
                    } else if (scope.search.isInvalid) {
                        scope.error = scope.error = $filter("translate")("quicksearch.error.missing.types");
                        scope.ready = true;
                    } else {
                        let allTypeDefs = CacheManagerService.objectTypes.getAsMap(types);
                        let quickSearchTypeDefs = new Map();

                        for (let key in types) {
                            let typeId = types[key],
                                typeDef = allTypeDefs[typeId];

                            if (typeId >> 16 > 99) {
                                continue;
                            }

                            if (typeDef == void 0) {
                                scope.error = $filter("translate")("quicksearch.error.missing.type");
                                break;
                            } else {
                                quickSearchTypeDefs.set(typeId, typeDef)
                            }
                        }

                        if (!scope.error) {
                            buildQuickSearchForm(quickSearchTypeDefs);
                        }

                        let fieldCount = 0;
                        for (let i in scope.formDefs) {
                            fieldCount += scope.formDefs[i].formFields.length
                        }

                        // it could happen that the referenced field is no longer inside the fieldlist of the objecttype
                        if (fieldCount == 0) {
                            scope.error = $filter("translate")("quicksearch.error.missing.fields");
                        }

                        scope.ready = true
                    }
                }

                function buildQuickSearchForm(typeDefs) {
                    for (let [typeId, typeDef] of typeDefs.entries()) {
                        let formDef = {
                            isMainForm: true,
                            validationMode: "min",
                            formFields: [],
                            formHelper: {},
                            isMockForm: true
                        };

                        let searchFields = scope.search.types.get(typeId);

                        for (let searchField of searchFields) {
                            var field;

                            // fulltext field gets a negativ tab index because it is not inside the form
                            if (searchField.tabIndex == "-1") {
                                if (EnvironmentService.isFulltextAvailable()) {
                                    field = FormFieldModelService.getMockedField("text", "fulltext", $filter("translate")("quicksearch.fulltext.field.title"));
                                    field.tabIndex = -1;
                                    formDef.formFields.push(field);
                                } else {
                                    continue;
                                }
                            } else {
                                let def = typeDefs.get(searchField.objectTypeId);
                                let fieldList = def.api.getFlatFieldList(true);
                                field = getField(fieldList, searchField);

                                if (field == void 0) {
                                    console.warn("Could not find Parameter --> ", searchField);
                                    continue;
                                }

                                field.isInvisibleText = false;
                                removeIllegalAddons(field);

                                if (field.type == "group"
                                    && field.fields.length > 0 && field.fields[0].type == "radio") {
                                    for (let radiobutton of field.fields) {
                                        formDef.formFields.push(radiobutton);
                                    }
                                } else if (field.type == "radio") {
                                    for (let internal of field.fields) {
                                        formDef.formFields.push(getField(fieldList, {
                                            internal,
                                            pageId: searchField.pageId
                                        }, null, "internal"));
                                    }
                                } else {
                                    formDef.formFields.push(field);
                                }
                            }
                        }

                        if (formDef.formFields.length > 0) {
                            const orderFields = formDef.formFields.map(field => ({ order: FormService.getFieldDeepTabOrder(field), field }));
                            formDef.formFields = FormService.sortFieldsByOrder(orderFields).map(orderField => orderField.field);
                            formDef.name = typeDef.model.config.title
                            let formData = FormService.createFormData(formDef.formFields, "min");

                            let helperConfig = {
                                formData,
                                isSearch: true,
                                modelDef: typeDef.model,
                                search: ngSubmitHook
                            };

                            formDef.formHelper = FormHelper.getFormHelper(helperConfig);
                            scope.formDefs.push(formDef)
                        }
                    }
                }

                // this time we need to overwrite the default behavior because we need to pass more than one
                // form definition into the submit callback
                function ngSubmitHook() {
                    submitQuickSearch(scope.formDefs)
                }

                function submitQuickSearch(formDefs, event = undefined) {
                    if (isSubmitting) {
                        return;
                    }

                    if (event != void 0) {
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                    }

                    isSubmitting = true;

                    if (!isQuickSearchSupported(scope.search.objectPages)) {
                        let title = $filter("translate")("modal.confirm.quicksearch.not.supported.title");
                        let msg = $filter("translate")("modal.confirm.quicksearch.not.supported.message");
                        let submit = $filter("translate")("modal.button.close");

                        (async() => {
                            try {
                                await ModalDialogService.infoDialog(title, msg, undefined, submit);
                            } finally {
                                isSubmitting = false;
                            }
                        })();

                        return;
                    }

                    // we are now generating a new state which we will call in a few steps
                    // to initialize a new state we have to set a timestamp for it to get their data (the timestamp is the ID)
                    formDefs.forEach((formDef) => {
                        let helper = formDef.formHelper;
                        let formFields = helper.getFields();
                        let formObjectTypeId = helper.getModel().config.objectTypeId;

                        if (formObjectTypeId == -1) {
                            scope.search.formDataTypes["fulltext"][scope.search.activePage] = formFields["fulltext"].value;
                            return;
                        }

                        for (let i in formFields) {
                            let formField = formFields[i];

                            scope.search.formDataTypes[formObjectTypeId][formField.model.internal] = {
                                value: formField.value,
                                model: formField.model
                            };
                        }
                    });

                    fillMultiVars(scope.search, formDefs);

                    ActionService.executeSavedQuery(scope.search);

                    // simply set a timer and reset the submitting state
                    setTimeout(() => {
                        isSubmitting = false;
                    }, 1000)
                }

                function fillMultiVars(search, formDefs) {
                    let varMap = search.varMap;
                    let objectTypeFieldMap = {};

                    for (const i in formDefs) {
                        const fields = formDefs[i].formHelper.getFields();
                        let objectTypeId = formDefs[i].formHelper.getModel().config.objectTypeId;

                        objectTypeFieldMap[objectTypeId] = fields
                    }

                    for (const i in varMap) {
                        if (varMap[i].length > 1) {
                            let value = "";
                            const fields = varMap[i];

                            for (const j in fields) {
                                const f = (objectTypeFieldMap[fields[j].objectTypeId] || {})[fields[j].internal];

                                if (f == void 0) {
                                    continue;
                                }

                                if (f.value != value) {
                                    value = f.value
                                }
                            }

                            for (const j in fields) {
                                const f = search.formDataTypes[fields[j].objectTypeId][fields[j].internal];
                                f.value = value
                            }
                        }
                    }
                }

                function getField(fieldList, data, isInGroup, key = "tabIndex") {
                    // get pagectrl page, if necessary
                    if (data.pageId && !isInGroup) {
                        let page = getPageCtrlPage(fieldList, data);

                        if (page == void 0) {
                            console.warn("Couldn't find page: ", data.pageId);
                            return;
                        }

                        fieldList = page.fields;
                    }

                    for (let i in fieldList) {
                        let field = fieldList[i];

                        // ignore pageCtrl fields, if we're not looking for any
                        if (data.pageId == void 0 && field.parentPage != void 0 && !isInGroup) {
                            continue;
                        }

                        if (field.type == "pagecontrol") {
                            continue;
                        }

                        if (field.type == "group") {
                            field = getField(fieldList[i].fields, data, true, key);
                            if (field != void 0) {
                                return field;
                            }
                        } else if (data[key] == field[key]) {
                            return fieldList[i]
                        }
                    }
                }

                function getPageCtrlPage(fieldList, data) {
                    for (let i in fieldList) {
                        if (fieldList[i].type == "pagecontrol") {
                            for (let page in fieldList[i].pages) {
                                if (fieldList[i].pages[page].pageId == data.pageId) {
                                    return fieldList[i].pages[page];
                                }
                            }
                        }
                    }
                }

                function removeIllegalAddons(model) {
                    let addon = model.addon,
                        type = model.type;
                    if (addon == "quickfinder") {
                        model.addon = undefined;
                    }

                    if (type == "date" || type == "time" || type == "datetime" || type == "decimal" || type == "number") {
                        model.suppressRange = true
                    }
                }

                function isQuickSearchSupported(objectPages) {
                    for (let i in objectPages) {
                        let objectPage = objectPages[i];
                        for (let j in objectPage.fields) {
                            let field = objectPage.fields[j];
                            if (field.isVariable == true
                                && (field.variable.indexOf("COMPUTER-IP") > -1
                                    || field.variable.indexOf("COMPUTER-GUID") > -1
                                    || field.variable.indexOf("COMPUTER-NAME") > -1)) {
                                return false;
                            }
                        }
                    }
                    return true;
                }

                scope.$on("$destroy", () => {
                    for (let i in scope.formDefs) {
                        scope.formDefs[i].formHelper.destroy();
                    }
                })
            },
            template: require("!raw-loader!./eob.quicksearch.html")
        }
    }
})();
