/**
 * Synchronization failure status for one offline object
 */
export enum ObjectSyncFailedStatus {
    NONE,
    /* Original content could not be synchronized */
    FAILED_ORIGINAL_CONTENT,
    /* Preview content could not be synchronized */
    FAILED_PREVIEW_CONTENT,
    /* Email attachment content could not be synchronized */
    FAILED_EMAIL_ATTACHMENT,
    /* Content could not be synchronized due to a filesize limit */
    FAILED_CONTENT_LIMIT
}