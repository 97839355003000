(function() {
    

    require("COMPONENTS_PATH/eob-detailsviewer/components/dv-username/dv.username.dir.js");
    require("COMPONENTS_PATH/eob-detailsviewer/components/dv-days-from-now/dv.days.from.now.dir.js");

    angular.module("eob.framework").directive("dvRevisits", DvRevisits);

    DvRevisits.$inject = ["$filter", "dvRevisitsService", "dvDateService", "messageService"];

    function DvRevisits($filter, DvRevisitsService, DvDateService, MessageService) {
        return {
            restrict: "E",
            template: require ("!raw-loader!./dv.revisits.html"),
            link(scope) {
                scope.revisitsdata = undefined;
                scope.histerror = false;

                scope.toggleRecipientsShow = toggleRecipientsShow;

                scope.$on("changeObject", load);
                scope.$on("refreshObject", refresh);
                MessageService.subscribe("clear.viewer", clear);

                init();

                function init() {
                    scope.loading = true;
                    load();
                }

                function load() {
                    let requestedOsId = scope.objectId;
                    DvRevisitsService.load(requestedOsId).then((res) => {
                        if (requestedOsId == scope.objectId) {
                            scope.revisitsdata = prepareRevisits(res);
                        }
                        scope.histerror = false;
                        scope.loading = false;
                    }, (reason) => {
                        scope.histerror = true;
                        scope.loading = false;
                    });
                }

                function refresh() {
                    let requestedOsId = scope.objectId;
                    DvRevisitsService.refresh(requestedOsId).then((res) => {
                        if (requestedOsId == scope.objectId) {
                            scope.revisitsdata = prepareRevisits(res);
                        }
                        scope.histerror = false;
                        scope.loading = false;
                    }, (reason) => {
                        scope.histerror = true;
                        scope.loading = false;
                    });
                }

                function prepareRevisits(data) {
                    let index = data.length;
                    while (index--) {
                        setCreationDate(data[index]);
                        setDaysLeft(data[index]);
                    }
                    return data;
                }

                function toggleRecipientsShow(evt) {
                    let toggleButton = angular.element(evt.currentTarget);
                    let recipientContainer = angular.element(toggleButton.parent().children()[1]);
                    if (toggleButton.hasClass("showing")) {
                        toggleButton.removeClass("showing");
                        recipientContainer.removeClass("visible");
                    } else {
                        toggleButton.addClass("showing");
                        recipientContainer.addClass("visible");
                    }
                }

                function setCreationDate(dataObject) {
                    let creationStr;
                    let creationDate = dataObject.creationdate ? dataObject.creationdate : undefined;
                    let date = creationDate ? DvDateService.convertTimestamp(creationDate) : $filter("translate")("app.revisits.created");

                    creationStr = $filter("translate")("app.revisits.created");
                    creationStr += ` ${date}`;
                    creationStr += ` ${$filter("translate")("app.revisits.created.by")}`;
                    dataObject.creationStr = creationStr;
                }

                function setDaysLeft(dataObject) {
                    let revisitDate, currentDate, TimestampDistance, DaysDistance;

                    revisitDate = new Date(dataObject.duedate);
                    currentDate = new Date();
                    TimestampDistance = revisitDate - currentDate;
                    DaysDistance = parseInt(TimestampDistance / 86400000);
                    if (TimestampDistance < 0 || ( DaysDistance >= 0 && DaysDistance < 8)) {
                        dataObject.showDaysLeft = true;
                        if (TimestampDistance < 0) {
                            dataObject.daysLeft = -1;
                        } else if (DaysDistance === 0 && revisitDate.getDate() !== currentDate.getDate()) {
                            dataObject.daysLeft = 1;
                        } else if (DaysDistance === 1 && revisitDate.getDate() !== currentDate.getDate() + 1) {
                            dataObject.daysLeft = 2;
                        } else {
                            dataObject.daysLeft = DaysDistance;
                        }
                    }
                }

                function clear() {
                    scope.revisitsdata = undefined;
                    scope.histerror = false;
                }
            }
        };
    }
})();
