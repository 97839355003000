import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {EMPTY, merge, Subscription} from "rxjs";
import {ChangeDetectorRef, OnDestroy, PipeTransform, ViewChild} from "@angular/core";
import {EobInputFormControl} from "MODULES_PATH/form/components/inputs/eob-input-form-control.model";
import {distinctUntilChanged} from "rxjs/operators";
import { ValidationErrorMapperService } from "MODULES_PATH/form/services/validation-error-mapper.service";
import {debounceTime} from "rxjs/operators";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ValidationErrors} from "@angular/forms";
import {DisplayBubbleDirective} from "MODULES_PATH/form/directives/display-bubble.directive";
import {StandaloneValidationBubbleComponent} from "MODULES_PATH/form/components/standalone-validation-bubble/standalone-validation-bubble.component";

@Component({
    selector: "eob-textarea",
    templateUrl: "./textarea.component.html",
    styleUrls: ["./textarea.component.scss"]
})
export class TextareaComponent implements OnInit, OnDestroy {
    @Input() field: any;
    @Input() formhelper: any;
    @Input() ismockform: boolean;
    @Input() hasAddon: boolean;
    @Input() fieldid: any;
    @Input() control: EobInputFormControl;
    @Input() disabled: boolean;
    @Input() customBubble: StandaloneValidationBubbleComponent;

    @Output() triggerKeydown: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() triggerClick: EventEmitter<Event> = new EventEmitter<Event>();

    @ViewChild(DisplayBubbleDirective, {static: false}) displayBubble: DisplayBubbleDirective;

    private subs: Subscription = new Subscription();
    inputStatus: string = "";
    constructor(private validationErrorMapperService: ValidationErrorMapperService,
                private cdRef: ChangeDetectorRef,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.subs.add(merge(this.control?.valueChanges ?? EMPTY, this.control?.statusChanges ?? EMPTY).pipe(debounceTime(50), distinctUntilChanged()).subscribe(() => {
            this.inputStatus = this.control.status;
            if(this.inputStatus == "INVALID") {
                const validationErrorMessage: ValidationErrors = this.validationErrorMapperService.getValidationErrorMessage(this.control.errors);
                this.displayBubble.setValidationMessage(validationErrorMessage.title, validationErrorMessage.message);
            } else {
                this.displayBubble.hide();
            }
            // Required if form control is invalidated externally, e.g. by form submission
            this.cdRef.detectChanges();

        }));
        this.subs.add(this.messageService.subscribe(Broadcasts.DETECT_CHANGES, () => {
            this.cdRef.detectChanges();
        }));
        this.addPipes();
    }

    private addPipes(): void {
        const pipes: PipeTransform[] = this.control.eobOptions?.pipes;
        if (pipes) {
            this.subs.add(this.control.valueChanges.subscribe(value => {
                let newValue: string = value;
                pipes.forEach(pipe => newValue = pipe.transform(value));
                if (value !== newValue) {
                    this.control.setValue(newValue, { emitEvent: false, emitViewToModelChange: false });
                }
            }));
        }
    }

    clickHandler = (event: MouseEvent): void => {
        this.triggerClick.emit(event);
    };

    keyDownHandler(event: KeyboardEvent): void {
        this.triggerKeydown.emit(event);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
