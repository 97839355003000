import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";
import {FieldExpander} from "INTERFACES_PATH/validation.interface";
import {UserAddonConfig} from "MODULES_PATH/form/interfaces/form-addon-config.interface";
import {UserAddonService} from "MODULES_PATH/form/services/form-builder/user-addon.service";
import {
    DmsGroupOrgObject,
    DmsOrganisationObject,
    DmsUserOrgObject
} from "INTERFACES_PATH/dms-organisation-object.interface";
import {CorrectionFieldMask} from "MODULES_PATH/form/models/field-mask.model";

export class UserMask extends CorrectionFieldMask {
    constructor(private field: FieldExpander, private userAddonService: UserAddonService) {
        super();
    }

    parseValue(fieldValue: FormInputValue): FormInputValue {
        let values: string[] = (fieldValue != void 0) ? fieldValue.toString().split(";") : [];
        const config: UserAddonConfig = this.field.model.config;

        values = values.filter(v => v?.trim().length > 0);

        const items: DmsOrganisationObject[] = this.userAddonService.getUserAndGroupsByConfig(config);

        const parsedContent: string[] = values.map(value => {
            const item: DmsOrganisationObject = items.find(i => {
                const description: string = i.type == "group" ? (i as DmsGroupOrgObject).description : (i as DmsUserOrgObject).fullname;
                return value.toLowerCase() == i.name.toLowerCase() || value.toLowerCase() == description.toLowerCase();
            });

            if (!item) {
                return value;
            } else {
                const description: string = item.type == "user" ? (item as DmsUserOrgObject).fullname : "";
                return (config.applyDescription && description != "") ? description : item.name;
            }
        });
        return parsedContent.join(";");
    }
}