import {Component} from "@angular/core";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";
import {Observable} from "rxjs";
import {NavigationState} from "MODULES_PATH/navigation/interfaces/navigation.interface";
import {Store} from "@ngrx/store";
import {selectAvailableStates} from "MODULES_PATH/navigation/+state/navigation.selectors";

@Component({
    selector: "eob-nav-content",
    templateUrl: "./eob-nav-content.component.html",
    styleUrls: ["./eob-nav-content.component.scss"]
})
export class EobNavContentComponent {
    nav$: Observable<NavigationState>;

    constructor(private navStore: Store<NavigationState>,
                public navigationService: NavigationService,) {
        this.nav$ = this.navStore.select(selectAvailableStates);
    }
}
