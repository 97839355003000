import {DmsBaseparamsConfig} from "./dv-baseparams-config.model.ts";

(function() {
    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");
    require("SERVICES_PATH/utils/eob.cache.manager.srv.js");
    require("SERVICES_PATH/eob.search.srv.js");

    const baseparamsConfig = DmsBaseparamsConfig();

    angular.module("eob.core").factory("dvDocumentService", DvDocumentService);

    DvDocumentService.$inject = ["$location", "$q", "$filter", "backendService", "valueUtilsService", "environmentService", "formService",
        "cacheManagerService", "objectTypeService", "searchService", "clientService", "offlineCacheService", "organisationService"];

    /**
     * Get and prepare dms object data.
     */
    // eslint-disable-next-line max-params, require-jsdoc
    function DvDocumentService($location, $q, $filter, BackendService, ValueUtilsService, EnvironmentService, FormService,
                               CacheManagerService, ObjectTypeService, SearchService, ClientService, OfflineCacheService, OrganisationService) {
        let mailPattern = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi,
            mailBlockPattern = /([^\r\n\t\f\s;]+|".+"\s+["\\\\\"^\r\n\t\f\s;]+)/gi,
            urlPattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi,
            specialPattern = /(<|>)/g;
        let units = ["bytes", "KB", "MB", "GB", "TB", "PB"];

        let promises = {},
            currentDvDmsDocument,
            gListenerGuid = CacheManagerService.dmsDocuments.attachListener([], onDmsDocumentsChanged),
            changeListenerFns = [];

        let service = {
            attachListener,
            detachListener,
            loadAsync
        };
        return service;

        /**
         * Load the dvDmsObject for the given osid.
         * Don't start several search requests for the same osid.
         *
         * @param {number} osid - The osid of the requested dvDmsObject.
         * @param {boolean=} refresh - Don't return cached data.
         * @param {DmsDocument=} dmsDocument - Optionally use this dmsDocument instead of loading new data.
         * @returns {Promise} Resolved with a dvDmsObject.
         */
        async function loadAsync(osid, refresh, dmsDocument) {
            let promise = promises[osid];

            if (promise == void 0) {
                let lastData = currentDvDmsDocument;
                if (!refresh && lastData !== void 0 && lastData.osid === osid) {
                    return Promise.resolve(lastData);
                }

                promise = getDvDmsObjectAsync(osid, dmsDocument);

                promises[osid] = promise;

                (async () => {
                    try {
                        await promise;
                    } catch (error) { /* error will be handled by those calling loadAsync */ }

                    delete promises[osid];
                })();
            }

            return promise;
        }

        /**
         * Get and prepare the dvDmsObject for the given dmsDocument or osid.
         * Add it to the cache and listen to changes.
         *
         * @param {number} osid - The osid of the dms object.
         * @param {DmsDocument=} dmsDocument - Optionally use this dmsDocument instead of loading new data.
         */
        async function getDvDmsObjectAsync(osid, dmsDocument) {
            if (dmsDocument == void 0) {
                if (ClientService.isOffline()) {
                    dmsDocument = await OfflineCacheService.getById(osid);
                } else {
                    dmsDocument = await SearchService.searchById(osid, null);
                }
            }

            if (dmsDocument == void 0) {
                return;
            }

            // Don't execute the update listeners in this case.
            // Though we add additional data to the cache, we don't add relevant new data for the rest of the client. Therefor executing the listeners is not neccessary.
            // In the worst case we would trigger a refresh in the state that just triggered the dv to update, resulting in an infinite loop.
            CacheManagerService.dmsDocuments.add(dmsDocument, !ClientService.isDetached());
            CacheManagerService.dmsDocuments.updateListener(gListenerGuid, [dmsDocument.model.osid]);

            return transformDmsDocument(dmsDocument.model);
        }

        // region transform
        /**
         * Parse a dmsDocument model to a dvDmsObject.
         *
         * @param {DmsDocumentModel} docModel - An dmsDocument model.
         * @returns {object} The dvDmsObject repesetation of the dmsDocument.
         */
        function transformDmsDocument(docModel) {
            let fields = transformFields(docModel);
            let baseparams = transformBaseParams(docModel);

            currentDvDmsDocument = {
                osid: docModel.osid,
                objectTypeId: docModel.objectTypeId,
                objectType: docModel.objectType,
                isTypeless: docModel.isTypeless,
                name: docModel.name,
                simpleFields: fields.simpleFields,
                tableFields: fields.tableFields,
                baseParameters: baseparams.baseParameters,
                lockdata: baseparams.lockdata,
                fileProperties: docModel.fileProperties,
                rights: docModel.rights
            };

            for (let fn of changeListenerFns) {
                fn(currentDvDmsDocument);
            }

            return currentDvDmsDocument;
        }

        // region baseparams
        /**
         * Prepare the base parameters (including the lock data) for display.
         *
         * @param {object} docModel - A dmsDocument model.
         * @returns {{ baseParameters, lockdata }} Base parameters and lock data for display.
         */
        function transformBaseParams(docModel) {
            let baseParameters = [],
                docParams = docModel.baseParameters;

            const paramsConfig = docModel.isDocument ? baseparamsConfig.document : baseparamsConfig.container;
            for (const paramName of paramsConfig) {
                let value = docParams[paramName];

                if (value === undefined || value === "") {
                    if (baseparamsConfig.fallbackZero.includes(paramName)) {
                        value = 0;
                    } else if (baseparamsConfig.fallbackUnknown.includes(paramName)) {
                        value = $filter("translate")("app.baseparams.unknown");
                    } else {
                        continue;
                    }
                } else if (baseparamsConfig.translateParams.includes(paramName)) {
                    value = $filter("translate")(`app.baseparams.${paramName}.${value.toLowerCase()}`);
                } else if (baseparamsConfig.dateParams.includes(paramName)) {
                    value = isNaN(value) ? ValueUtilsService.dateToLocaleDate(value) : ValueUtilsService.formatDate(parseInt(value) * 1000, true);
                }

                baseParameters.push({ type: $filter("translate")(`app.baseparams.${paramName}`) , value });
            }

            baseParameters = baseParameters.concat(addSpecialFieldsToBaseParams(docModel));

            let lockdata = {
                isLocked: docModel.isLocked,
                isLockedSelf: docParams.locked == "SELF",
                lockedUser: docParams.lockedUser,
                lockedUserFullName: docParams.lockedUserFullName,
                lockedTime: docParams.lockedTime
            };

            return { baseParameters, lockdata };
        }

        /**
         * Add additional fields to the base parameters.
         *
         * @param {object} docModel - A dmsDocument model.
         * @returns {object[]} An array of additional base parameters.
         */
        function addSpecialFieldsToBaseParams(docModel) {
            let additionalBaseparams = [];

            if (docModel.fileProperties != void 0 && docModel.fileProperties.fileSize != void 0) {
                let size = docModel.fileProperties.fileSize;
                additionalBaseparams.push({ type: $filter("translate")("app.baseparams.size"), value: convertFilesSize(size, 2) });
            }
            if (docModel.osid) {
                additionalBaseparams.push({ type: $filter("translate")("app.baseparams.objectid"), value: docModel.osid });
            }
            if (docModel.objectTypeId) {
                additionalBaseparams.push({ type: $filter("translate")("app.baseparams.objecttypeid"), value: docModel.objectTypeId });
            }

            return additionalBaseparams;
        }

        //endregion

        // region fields
        /**
         * Prepare the dms object fields for display.
         *
         * @param {object} docModel - A dmsDocument model.
         * @returns {{simpleFields, tableFields}} The prepared fields split in simple and table fields.
         */
        function transformFields(docModel) {
            let objectType = CacheManagerService.objectTypes.getById(docModel.objectTypeId),
                fieldDefinitions = objectType.api.getFields(),
                docFields = docModel.fields;
            let fields = { simpleFields: [], tableFields: [] };

            for (let fieldDefinition of fieldDefinitions) {
                if (fieldDefinition.type == "pagecontrol") {
                    for (let page of fieldDefinition.pages) {
                        for (let pageFieldDef of page.fields) {
                            addField(fields, docFields[pageFieldDef.internal], pageFieldDef);
                        }
                    }
                } else {
                    addField(fields, docFields[fieldDefinition.internal], fieldDefinition);
                }
            }

            const uniqueSimpleFields = getUniqueListBy(fields.simpleFields, "displayName");
            const uniqueTableFields = getUniqueListBy(fields.tableFields, "displayName");

            return {
                simpleFields: FormService.sortFieldsByOrder(uniqueSimpleFields),
                tableFields: FormService.sortFieldsByOrder(uniqueTableFields)
            };
        }

        /**
         * Get a unique list of objects by key (i.e. remove duplicates from an array of objects)
         *
         * @param arr - An array of objects to be iterated over.
         * @param key - A key to filter the objects by.
         * @returns A unique list of objects that looks like the initial structure.
         */
        function getUniqueListBy(arr, key) {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        }

        /**
         * Prepare the field data and add it to the given fields, if it is not empty or invisible.
         *
         * @param {{simpleFields, tableFields}} fields - A collection of fields.
         * @param {*} value - The value of a field. May be a simple datatype or an object, if it is the value of a grid.
         * @param {object} definition - The field definition belonging to the given value.
         */
        function addField(fields, value, definition) {
            let isEmpty = value == void 0 || value == "" || (Array.isArray(value) && value.length == 0);
            if (definition.isInvisibleField || isEmpty) {
                return;
            }

            let order = FormService.getFieldDeepTabOrder(definition);
            if (definition.type == "grid") {
                if (value.rows == void 0 || value.rows.length == 0) {
                    return;
                }

                fields.tableFields.push({ order, displayName: definition.name, columns: value.columns, value: getTableFieldValue(value) });
            } else {
                fields.simpleFields.push({ order, displayName: definition.title, value: getSimpleValue(value, definition) });
            }
        }

        /**
         * Prepare the value of a table field.
         *
         * @param {{ rows, columns }} table - The value of a table field.
         * @returns {*[]} - The parsed rows.
         */
        function getTableFieldValue({ columns, rows }) {
            let value = [];

            for (let row of rows) {
                let cells = [];

                for (let i in columns) {
                    let column = columns[i],
                        cellValue = row[i];

                    cells.push(getSimpleValue(cellValue, column) || "");
                }

                value.push(cells);
            }

            return value;
        }

        /**
         * Prepare a simple datatype value.
         *
         * @param {*} value - A simple datatype.
         * @param {object} definition - The field definition belonging to the fiven value.
         * @returns {*} The parsed value.
         */
        function getSimpleValue(value, definition) {
            if (value === void 0 || value === "") {
                return;
            } else if (definition.type == "checkbox" && (!isNaN(value) && value > -1 && value < 3)) {
                return $filter("translate")(`app.checkbox.${value}`);
            } else if ((definition.type === "date" || definition.type === "datetime")) {
                return ValueUtilsService.dateToLocaleDate(value);
            } else if (definition.type === "decimal") {
                return ValueUtilsService.numberToLocaleNumber(value, ".2-2");
            } else if (definition.addon === "organisation") {
                return OrganisationService.getWfOrgPerformerByIds(value.split(";")).map(member => member.name).join(", ");
            }

            if (typeof value == "string" && !definition.isTextArea) {
                return transformLinkValue(value);
            }
            return value;
        }

        /**
         * Check string for a special regex and parse it accordingly.
         *
         * @param {string} value - Any kind of simple string value.
         * @returns {string} The parsed value.
         */
        function transformLinkValue(value) {
            let _target = "target='_blank'";
            // links
            if (value.match(urlPattern)) {
                return `<a class="link" ${_target} href="${value}">${value}</a>`;
            }
            if (isEmailAddress(value)) {
                return markupEmails(value, _target);
            }
            //escape <,>
            if (value.match(specialPattern)) {
                return escapeSpecialChars(value);
            }
            return value;
        }

        function isEmailAddress(value) {
            let mailBlocks = value.match(mailBlockPattern);
            return mailBlocks ? mailBlocks[mailBlocks.length - 1].match(mailPattern) : false;
        }

        /**
         * Transform each to a mailto dom element.
         *
         * @param {string} value - A simple string value.
         * @param {string} target - The target attribute to be used in the dom element.
         * @returns {string} The parsed string value.
         */
        function markupEmails(emails, target) {
            let result = [], markup, name, mailBlocks, address;

            emails.split(/[;]/).forEach(email => {
                mailBlocks = email.match(mailBlockPattern);

                address = mailBlocks.length > 1 ? mailBlocks[mailBlocks.length - 1] : mailBlocks[0];
                address = address.replace("<", "").replace(">", "");

                if (mailBlocks.length > 1) {
                    let name = mailBlocks.slice(0, mailBlocks.length -1).join(" ");
                    markup = `<span title='${address}'>${name} <a class='mail' href='mailto:${address}' ${target}>${address}</a></span>`;
                } else {
                    markup = `<span title='${address}'><a class='mail' href='mailto:${address}' ${target}>${address}</a></span>`;
                }

                result.push(markup);
            });

            return result.join("");
        }

        // endregion
        //endregion

        // update listener
        /**
         * Updatehandler for dmsDocuments.
         * We only watch out for changes on one dmsDocument.
         */
        function onDmsDocumentsChanged() {
            let dmsDocument = CacheManagerService.dmsDocuments.getById(currentDvDmsDocument.osid);

            if (dmsDocument != void 0) {
                transformDmsDocument(dmsDocument.model);
            }
        }

        /**
         * Add a function, that will be executed once the dvDmsObject changed.
         *
         * @param {function} fn - A function.
         */
        function attachListener(fn) {
            changeListenerFns.push(fn);
        }

        /**
         * Add a function, that will be executed once the dvDmsObject changed.
         *
         * @param {function} fn - A function.
         */
        function detachListener(fn) {
            changeListenerFns.splice(changeListenerFns.indexOf(fn), 1);
        }

        // endregion

        // region utils
        /**
         * Escape script characters.
         *
         * @param {string} value - A simple string value.
         * @returns {string} The escaped string value.
         */
        function escapeSpecialChars(value) {
            value = value.replace(/</g, "&#60;");
            value = value.replace(/>/g, "&#62;");
            return value;
        }

        /**
         * Parse the string representation of a byte size to a higher unit.
         *
         * @param {string} strBytes - A string value of a byte size.
         * @param {number} precision - The final precision.
         * @returns {string} - The parsed byte size.
         */
        function convertFilesSize(strBytes, precision) {
            let bytes = parseFloat(strBytes);
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
                return "?";
            }

            let unit = 0;
            while (bytes >= 1024) {
                bytes /= 1024;
                unit++;
            }
            return `${bytes.toFixed(+precision)} ${units[unit]}`;
        }

        //endregion
    }
})();
