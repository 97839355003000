import {Component, Inject, OnInit} from "@angular/core";
import {StateObject, StateParams, StateService} from "@uirouter/core";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {DmsActionService} from "MODULES_PATH/dms/dms-action.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {InboxActionService} from "CORE_PATH/services/actions/inbox-action.service";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {Location} from "MODULES_PATH/hitlist/interfaces/hit-list.interface";
import {OsrestSearchResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-search-result.interface";
import {StateContent} from "INTERFACES_PATH/state.interface";
import {DesktopQuery} from "INTERFACES_PATH/desktop.interface";
import {StateEnum} from "ENUMS_PATH/state.enum";
import {BeforeOpenResultCode} from "SERVICES_PATH/scripting/eob.client.script.codes";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {
    TodoCacheManagerService,
    TodoClientScriptService,
    TodoDesktopService,
    TodoEnvironmentService,
    TodoFulltextSearchConfig,
    TodoQuickSearchFormData,
    TodoStateHistoryManager,
    TodoStateService
} from "INTERFACES_PATH/any.types";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {WorkflowContextMenuItem} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";

interface RootScope extends ng.IRootScopeService {
    askedForFav: string[];
}

@Component({
    template: ""
})
export class EobEntryComponent implements OnInit {
    private readonly translateFn: TranslateFnType;
    private readonly stateId: string;
    private readonly stateData: StateObject;
    private readonly params: StateParams;
    private readonly paramKeys: StateEnum[];
    private readonly mainKey: StateEnum;

    // eslint-disable-next-line max-params
    constructor(@Inject("$rootScope") private $rootScope: RootScope,
                @Inject("$state") private $state: StateService,
                @Inject("$stateParams") private $stateParams: StateParams,
                @Inject("$location") private $location: ng.ILocationService,
                @Inject("$filter") private $filter: ng.IFilterService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("inboxActionService") protected inboxActionService: InboxActionService,
                @Inject("desktopService") protected desktopService: TodoDesktopService,
                @Inject("cacheManagerService") public cacheManagerService: TodoCacheManagerService,
                @Inject("stateService") protected stateService: TodoStateService,
                @Inject("clientScriptService") private clientScriptService: TodoClientScriptService,
                private notificationsService: NotificationsService,
                private actionService: ActionService,
                private httpService: HttpService,
                private dmsActionService: DmsActionService) {
        this.translateFn = this.$filter("translate");
        const {state} = $stateParams;

        if (!state) {
            const newState: number = +new Date();
            const params: StateParams = $location.search();

            stateHistoryManager.setStateData({params}, newState);

            location.hash += `&state=${newState}`;
            return;
        }

        this.stateId = state;
        this.stateData = stateHistoryManager.getStateData(this.stateId);
        this.params = (this.stateData.data as StateContent).params;
        this.paramKeys = Object.keys(this.params) as [StateEnum];
        this.mainKey = this.paramKeys[0];
    }

    ngOnInit(): void {
        this.decodeParams();
        void this.handleRedirection();
    }

    private decodeParams() {
        for (const param in this.params) {
            this.params[param] = decodeURI(this.params[param]);
        }
    }

    private async handleRedirection(): Promise<void> {
        switch (this.mainKey) {
            case StateEnum.INDEXDATA:
                await this.redirectToIndexDataState();
                break;
            case StateEnum.LOCATION:
                await this.redirectToLocationState();
                break;
            case StateEnum.APP_ENTRY_STOREDQUERY_ID:
            case StateEnum.SEARCH:
                await this.redirectBySearchParams();
                break;
            case StateEnum.APP_ENTRY_OSID:
            case StateEnum.APP_ENTRY_ASPATH:
            case StateEnum.OSID:
                await this.redirectToResultState();
                break;
            case StateEnum.APP_ENTRY_WF_WORKITEM_ID:
            case StateEnum.WORKFLOW:
                this.redirectToInboxState();
                break;
            case StateEnum.FULLTEXTSEARCH:
                this.redirectToFulltextResultState();
                break;
            case StateEnum.DOWNLOAD:
                await this.redirectToDownloadState();
                break;
            case StateEnum.HITLIST:
                this.redirectToHitlistState();
                break;
        }
    }

    private async redirectToIndexDataState(): Promise<void> {
        let {mode} = this.params;

        if (isNaN(parseInt(this.params[this.mainKey]))) {
            this.goToFallback();
            return;
        }

        if (!mode) {
            mode = "edit";
        }

        try {
            const data: { osid: string; item: OsrestSearchResult } = await this.getDocumentAndRights(this.params[this.mainKey], this.params.objecttypeid, this.params.activeVariant);
            const stateData: StateContent = this.stateData.data as StateContent;
            const userAction: string = stateData?.config && stateData?.config.userAction ? stateData.config.userAction : "entry";

            this.stateHistoryManager.goToIndexData({
                model: {
                    osid: data.osid,
                    objectTypeId: data.item.objectTypeId
                }
            }, mode, userAction, true);
        } catch (error) {
            console.warn(error);
        }
    }

    private async redirectToLocationState(): Promise<void> {
        const {parentid, parenttypeid, flat} = this.params;

        if (isNaN(parseInt(this.params[this.mainKey]))) {
            this.goToFallback();
            return;
        }

        // Beim Öffnen eines Standortes muss immer die aktive Variante geladen werden, weil
        // inaktive Varianten keinen Standort haben (getDocumentAndRights mit Parameter "true").
        try {
            const inactiveVariantSelected = false;
            const data: { osid: string; item: OsrestSearchResult } = await this.getDocumentAndRights(this.params[this.mainKey], this.params.objecttypeid, "true");
            const dmsDocumentId: string[] = this.cacheManagerService.dmsDocuments.add(data.item);
            let dmsDocument: DmsDocument;
            if (data.osid != data.item.osid) {
                this.notificationsService.info(this.translateFn("eob.action.open.location.inactive.variant"));
                dmsDocument = await this.cacheManagerService.dmsDocuments.getOrFetchById(data.osid);
            } else {
                dmsDocument = this.cacheManagerService.dmsDocuments.getById(data.osid);
            }

            if (flat === "true") {
                this.stateService.openFolderFlatAsync(dmsDocument.model, dmsDocument.model.cabinetId);
            } else {
                let parent: { id: string; objectTypeId: string };

                if (parentid != void 0) {
                    parent = {
                        id: parentid,
                        objectTypeId: parenttypeid
                    };
                }

                const nextStateContent: StateContent = {
                    config: {
                        userAction: "",
                    },
                    type: "entry",
                    id: data.osid,
                    objectTypeId: data.item.objectTypeId,
                };

                this.stateHistoryManager.setStateData(nextStateContent, this.stateId);
                this.stateService.goToLocationAsync(dmsDocument, parent, false, true, this.params.filter, true);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private async redirectBySearchParams(): Promise<void> {
        // TODO if query fails (HTTP 404), user is not returned to previous state. Instead, it loops.
        try {
            await this.desktopService.initAsync();
            const query: DesktopQuery = this.desktopService.getQueryById(this.params[this.mainKey]);
            if (!query) {
                this.notificationsService.warning(this.translateFn("eob.result.storedquery.not.found"));
                this.goToFallback();
                return;
            }
            const {fromFile} = this.params;

            query.fromFile = fromFile;
            if (query.type === "quicksearch") {
                this.fillQueryParameter(query, this.params);
            }
            this.actionService.executeSavedQuery(query, this.params.filter);

        } catch (error) {
            if (error.type != "WEB_OFFLINE_ERROR") {
                this.notificationsService.customError(error);
            }
        }
    }

    private async redirectToResultState(): Promise<void> {
        const nextStateId: number = $.now();

        if (!(this.$rootScope.askedForFav instanceof Array)) {
            this.$rootScope.askedForFav = [];
        }

        let id: string = this.params[this.mainKey];
        let fromFile: boolean = this.params.fromFile;

        if (this.$rootScope.askedForFav.includes(id)) {
            fromFile = false;
        } else if (this.params.fromFile) {
            this.$rootScope.askedForFav.push(id);
        }

        if (this.mainKey == "app.entry.aspath") {
            id = this.params[this.mainKey].split(";")[1];
        }

        try {
            const data: { osid: string; item: OsrestSearchResult } = await this.getDocumentAndRights(id, this.params.objecttypeid, this.params.activeVariant);
            const dmsDocumentId: string[] = this.cacheManagerService.dmsDocuments.add(data.item);
            const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(dmsDocumentId);
            const resultCode: string = await this.clientScriptService.executeBeforeOpenObjectScript(dmsDocument);

            if (resultCode != BeforeOpenResultCode.OPEN) {
                if (resultCode == BeforeOpenResultCode.PREVENT_WITH_FALLBACK) {
                    this.goToFallback();
                }

                return;
            }

            // the user can at least see the indexdata of the document
            // show the hitlist and the hitlist will do the rest
            const nextStateContent: StateContent = {
                config: {
                    userAction: "",
                    executeSingleHitAction: true
                },
                type: "entry",
                id: data.osid,
                objectTypeId: data.item.objectTypeId,
                fromFile
            };

            // this function generates a new state with given data
            this.stateHistoryManager.setStateData(nextStateContent, nextStateId);

            // jump into the new state
            void this.$state.go("hitlist.result", {state: nextStateId});
        } catch (error) {
            console.warn(error);
        }
    }

    private redirectToInboxState(): void {
        if (!this.environmentService.isWorkflowUser()) {
            this.goToFallback();
            return;
        }

        if (this.stateData.data.isWorkflowAlreadyInit) {
            void this.$state.go("hitlist.inbox", {type: "workflow"});
            return;
        }

        try {
            const workItem: WorkflowContextMenuItem = {
                id: this.params[this.mainKey]
            } as WorkflowContextMenuItem;

            this.inboxActionService.markWfItemRead([workItem]);

            // we are now generating a new state which we will call in a few steps
            // to initialize a new state we have to set a timestamp for it to get their data (the timestamp is the ID)
            const nextStateId: number = $.now();

            // jump into the new state
            this.stateHistoryManager.updateStateData({isWorkflowAlreadyInit: true}, this.stateId);
            void this.$state.go("workflow", {id: this.params[this.mainKey], state: nextStateId});
        } catch (e) {
            void this.$state.go("hitlist.inbox", {type: "workflow"});
        }
    }

    private redirectToFulltextResultState(): void {
        const nextStateId: number = $.now();
        const queryText: string = this.params[this.mainKey];

        const fulltextSearchConfig: TodoFulltextSearchConfig = {
            type: "fulltextResult",
            searchKey: queryText,
            cabinetId: null,
            description: `${this.translateFn("eob.result.state.fulltext.description")} - ${queryText}`,
            config: {}
        };

        this.stateHistoryManager.setStateData(fulltextSearchConfig, nextStateId);
        this.stateHistoryManager.updateConfig({filter: this.params.filter}, nextStateId);
        void this.$state.go("hitlist.fulltextResult", {state: nextStateId});
    }

    private async redirectToDownloadState(): Promise<void> {
        const rendition: string = this.params.rendition;

        try {
            const data: { osid: string; item: OsrestSearchResult } = await this.getDocumentAndRights(this.params[this.mainKey], this.params.objecttypeid, this.params.activeVariant);

            void this.$state.go("download", {
                osid: data.osid,
                objectTypeId: data.item.objectTypeId,
                rendition,
                state: $.now()
            });
        } catch (error) {
            console.warn(error);
        }
    }

    private redirectToHitlistState(): void {
        const title: string = this.params.title;
        const subtitle: string = this.params.subtitle;
        const ids: string[] = this.params.hitlist.split(",");
        const filter: string = this.params.filter;
        const items: Location[] = [];

        ids.forEach(entry => {
            if (entry.length > 0) {
                items.push({osid: entry, objectTypeId: "unknown"});
            }
        });

        void this.dmsActionService.openResultListByIds(false, items, title, subtitle, false, filter);
    }

    /**
     * fallback to dashboard state
     */
    private goToFallback(): void {
        void this.$state.go("dashboard");
    }

    /**
     * getting the document and the rights
     * this is the point where we can decide if the user is able to see at least the indexdata
     * further inspection is done in each callback
     */
    private async getDocumentAndRights(id: string, objectTypeId: string, activeVariant: string): Promise<{ osid: string; item: OsrestSearchResult }> {
        const objectTypeIdRequest: string = (objectTypeId == void 0) ? "" : `?objecttypeid=${objectTypeId}`;

        try {
            const response: HttpResponse<OsrestSearchResult> = await this.httpService.legacyGet(`/documents/search/${id}${objectTypeIdRequest}`);
            const item: OsrestSearchResult = response.body;

            // not allowed to see anything of this
            if (!this.cacheManagerService.objectTypes.contains(item.objectTypeId)) {
                throw new Error();
            }

            if (activeVariant == "true" && item.variantTree != null) {
                id = this.findActiveVariant(item.variantTree);
            }

            return {
                osid: id,
                item
            };

        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                this.notificationsService.backendError(error, "eob.entry.controller.unspecified.error.document");
            } else {
                this.notificationsService.error(this.translateFn("eob.entry.controller.missing.rights.document"));
            }

            this.goToFallback();
            throw error;
        }
    }

    private fillQueryParameter(query: DesktopQuery, params: StateParams): void {
        const {formDataTypes, activePage, varMap} = query;
        for (const paramName in params) {
            let paramValue: string = params[paramName];
            if (paramName.toUpperCase().includes("VAR") || paramName.toUpperCase().includes("STAT")) {
                for (const i in formDataTypes) {
                    const type: TodoQuickSearchFormData = formDataTypes[i];
                    if (i == "fulltext") {
                        type[activePage] = paramValue;
                    } else {
                        for (const j in type) {
                            if (type[j].value == this.getAlignedParameterName(paramName)) {
                                type[j].value = paramValue;
                                paramValue = undefined;
                            }
                        }
                    }
                }
            }
        }

        // if no params were given and we come from entry-action, field values still hold something like "VAR1" and has to be emptied
        if (varMap) {
            for (const varName in varMap) {
                for (const i in formDataTypes) {
                    const type: TodoQuickSearchFormData = formDataTypes[i];
                    if (i !== "fulltext") {
                        for (const j in type) {
                            if (type[j].value === `${varName}`) {
                                type[j].value = "";
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * e.g.
     *  var1 -> VAR1
     *  app.entry.storedquery.param.$VAR1$ -> VAR1
     */
    private getAlignedParameterName(parameterName: string): string {
        if (parameterName.includes("app.entry.storedquery.param.")) {
            parameterName = parameterName.substring(parameterName.indexOf("$") + 1, parameterName.length - 1);
        }
        return parameterName.toUpperCase();
    }

    private findActiveVariant(variant: OsrestSearchResult): string {
        if (variant.variantInformation.IS_ACTIVE.value == "1") {
            return variant.variantInformation.DOCUMENT_ID.value;
        }

        for (const v of variant.children) {
            const osid = this.findActiveVariant(v);

            if (osid != "-1") {
                return osid;
            }
        }

        return "-1";
    }

}
