import {Component, Inject, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";

@Component({
    selector: "eob-nav-user-avatar",
    templateUrl: "./eob-nav-user-avatar.component.html",
    styleUrls: ["./eob-nav-user-avatar.component.scss"]
})
export class EobNavUserAvatarComponent implements OnInit, AfterViewInit, OnDestroy {
    private subs: Subscription = new Subscription();
    avatarUrl: string;

    constructor(
        private clientService: ClientService,
        private cdRef: ChangeDetectorRef,
        private messageService: MessageService,
        private navigationService: NavigationService,
        private httpService: HttpService,
        @Inject("fileCacheService") private fileCacheService: FileCacheService,
        @Inject("$rootScope") private $rootScope: ng.IRootScopeService,
        @Inject("environmentService") private environmentService: TodoEnvironmentService
    ) {
    }

    async ngOnInit(): Promise<void> {
        if (this.clientService.isOffline()) {
            await this.setCachedAvatarAsync();
        } else {
            try {
                const avatar = await this.httpService.legacyGet(`/organization/avatar/${this.environmentService.getSessionInfo().username}`, "/osrest/api/", {responseType: "arraybuffer"});
                const avatarString = `data:${avatar.headers.get("content-type")};base64,${btoa(new Uint8Array(avatar.data).reduce((data, byte) => data + String.fromCharCode(byte), ""))}`;
                this.avatarUrl = avatarString;
                this.cdRef.detectChanges();
                this.fileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, avatarString, "avatar");
            } catch (e) {
                console.warn(e);
                await this.setCachedAvatarAsync();
            }
        }
    }

    ngAfterViewInit(): void {
    }

    /**
     * Get the avatar data from the cache and show it.
     *
     * @returns {Promise<void>} Resolved once the avatar is set.
     */
    private async setCachedAvatarAsync(): Promise<void> {
        try {
            const avatarString = await this.fileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, "avatar", {first: true});

            if(avatarString) {
                this.avatarUrl = avatarString;
                this.cdRef.detectChanges();
            }
        } catch (e) {
            console.warn(e);
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
