import {Component, HostBinding, Input, OnChanges, OnInit} from "@angular/core";

@Component({
    selector: "eob-icon",
    templateUrl: "./eob-icon.component.html",
    styleUrls: ["./eob-icon.component.scss"]
})

export class EobIconComponent implements OnInit, OnChanges {
    @Input() name: string;
    @Input() size: string;
    @Input() ariaLabel: string;
    @Input() title: string;

    iconUrl: string;
    iconSize: string;
    isCustomIcon: boolean;

    @HostBinding("title") get hostTitle(): string {
        return this.title || "";
    }

    ngOnInit(): void {
        if (!this.name) {
            console.warn("missing icon name");
        }

        this.isCustomIcon = this.name ? this.name.startsWith("custom-icon-") : false;
        this.iconUrl = `assets/icons-${this.size}-sprite-sheet.svg#${this.name}`;
        this.iconSize = `size-${this.size}`;
        this.ariaLabel = this.ariaLabel || this.title;
    }

    ngOnChanges(): void {
        if (!this.name) {
            console.warn("missing icon name");
        }

        this.isCustomIcon = this.name ? this.name.startsWith("custom-icon-") : false;
        this.iconUrl = `assets/icons-${this.size}-sprite-sheet.svg#${this.name}`;
        this.iconSize = `size-${this.size}`;
        this.ariaLabel = this.ariaLabel || this.title;
    }
}
