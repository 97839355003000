import {Component, Inject, OnInit, QueryList, ViewChildren} from "@angular/core";
import {TodoEnvironmentService, TodoStateHistoryManager} from "INTERFACES_PATH/any.types";
import {StateService} from "@uirouter/core";
import {NextStateContent} from "MODULES_PATH/navigation/interfaces/navigation.interface";
import {EobPanelComponent} from "MODULES_PATH/navigation/components/eob-panel/eob-panel.component";
import {NavigationDirection} from "MODULES_PATH/navigation/enums/navigationDirection.enum";
import {PanelKeyboardNavigation} from "MODULES_PATH/navigation/interfaces/panelKeyboardNavigation.interface";
import {Subscription} from "rxjs";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";
import {AfterViewInit, OnDestroy} from "@angular/core";

@Component({
    selector: "eob-nav-inbox",
    templateUrl: "./eob-nav-inbox.component.html"
})
export class EobNavInboxComponent implements OnInit, PanelKeyboardNavigation, AfterViewInit, OnDestroy {
    @ViewChildren(EobPanelComponent) panels!: QueryList<EobPanelComponent>;
    showRevisits: boolean;
    showAbo: boolean;
    showWorkflow: boolean;
    private subs: Subscription = new Subscription();

    constructor(private messageService: MessageService,
                private navigationService: NavigationService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                protected state: StateService
    ) {
    }

    ngOnInit(): void {
        this.showRevisits = this.environmentService.env.actions.useRevisits;
        this.showAbo = this.environmentService.env.actions.useAbo;
        this.showWorkflow = this.environmentService.isWorkflowUser();
    }

    ngAfterViewInit(): void {
        if (this.navigationService.isFixed) {
            this.panels.first?.setTabReachable(true);
        }

        this.subs.add(this.navigationService.focusFirstPanel(this.panels, "inbox"));
    }


    passFocus(senderSide: NavigationDirection, senderID: number): void {
        this.navigationService.passFocus(this.panels, senderSide, senderID);
    }

    openInbox(context: string): void {

        const nextStateId: number = Date.now();

        // next we create a package with all the relevant data
        // in this case the whole form, the objecttypeid and the type of task
        // the config key is used by the state itself
        // a state can update its state model with information like "sort,grouping, scroll position etc..."
        const nextStateContent: NextStateContent = {
            config: {userAction: undefined},
            type: context
        };

        // this function generates a new state with given data
        this.stateHistoryManager.setStateData(nextStateContent, nextStateId);
        // jump into the new state
        this.state.go("hitlist.inbox", {type: context, state: nextStateId});
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }


}
