import {Injectable, Injector} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {SystemFields} from "ENUMS_PATH/systemfields-enum";

@Injectable({providedIn: BackendModule})
export class RequestTransformService {
    constructor(private injector: Injector) {}

    canTransform(request: any): boolean {
        if (request.query
            && request.query.objectTypeId
            && request.query.fields
            && request.query.baseparams
            && !request.query.conditionGroups
            && request.additionalQueries.length == 0) {
            return true;
        }
        return false;
    }

    transform(request: any): any {
        const cacheManagerService: any = this.injector.get("cacheManagerService");

        const objectType: any = cacheManagerService.objectTypes.getById(request.query.objectTypeId).model.config.internal;
        const fieldsFilter: string = this.transformFields(request);
        const systemFieldsFilter: string = this.transformSystemFields(request);

        let cmisSql: string;
        if (fieldsFilter && systemFieldsFilter) {
            cmisSql = `SELECT * FROM ${objectType} WHERE ${fieldsFilter} AND ${systemFieldsFilter}`;
        } else if (fieldsFilter) {
            cmisSql = `SELECT * FROM ${objectType} WHERE ${fieldsFilter}`;
        } else if (systemFieldsFilter) {
            cmisSql = `SELECT * FROM ${objectType} WHERE ${systemFieldsFilter}`;
        } else {
            cmisSql = `SELECT * FROM ${objectType}`;
        }

        const maxhits: number = (request.query.result_config || {}).maxhits;

        return {
            query: {
                statement: cmisSql,
                skipCount: 0,
                maxItems: maxhits != -1 ? maxhits : 50,
                handleDeletedDocuments: "DELETED_DOCUMENTS_EXCLUDE",
                includePermissions: true
            }
        };
    }

    transformFields(request: any): string {
        if (Object.keys(request.query.fields).length == 0) {
            return undefined;
        }

        const fields: any = [];
        Object.entries(request.query.fields).map((x: any[]) => {
            fields.push(`${x[0]}='${x[1].value}'`);
        });

        return `${fields.join(" AND ")}`;
    }

    transformSystemFields(request: any): string {
        if (Object.keys(request.query.baseparams).length == 0) {
            return undefined;
        }

        const systemFields: any = [];
        let systemField: string;
        Object.entries(request.query.baseparams).map((x: any[]) => {
            systemField = SystemFields[x[0].toUpperCase()];
            systemFields.push(`${systemField}='${x[1].value}'`);
        });

        return `${systemFields.join(" AND ")}`;
    }
}
