import {BackendFacade} from "CORE_PATH/backend/interfaces/backend-facade.interface";
import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpEvent, HttpRequest} from "@angular/common/http";
import {catchError, concatMap, delay, mergeMap, retryWhen, switchMap, tap} from "rxjs/operators";
import {from, iif, Observable, of, throwError} from "rxjs";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {OsrestObjectResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-object-result.interface";
import {OsrestSearchResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-search-result.interface";
import {map} from "rxjs/operators";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {Cabinet, OsrestObjectDefinition} from "INTERFACES_PATH/object-type.interface";
import {OsrestGenericItemErrorResponse} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-generic-item-error-response.interface";
import {OsrestDocumentfilesIdNameMap} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-documentfiles-id-name-map.interface";
import {OsrestDocumentfilesResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-documentfiles-result.interface";
import {ProcessedRenditionInfoData} from "INTERFACES_PATH/rendition-info-data.interfaces";
import {OsrestChildrenHierarchyResult} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-children-hierarchy-result.interface";
import {BulkRequestResult} from "CORE_PATH/backend/models/bulk-request-result.model";
import {OsrestErrorResponseEntry} from "CORE_PATH/backend/modules/osrest/models/osrest-error-response-entry.model";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {HttpHeaders, HttpResponse} from "@angular/common/http";
import {AsIni} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {OsrestSubscriptionObject} from "./interfaces/osrest-subscription-object.interface";
import {OsrestWorkflowResult} from "./interfaces/osrest-workflow-result.interface";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {ProfileCacheKey} from "ENUMS_PATH/database/profile-cache-key.enum";
import {OsrestIcons} from "./interfaces/osrest-icons.interface";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {BasicAuthService} from "CORE_PATH/authentication/protocols/basic-auth.service";
import {
    AuthenticationState,
    AuthenticationType
} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {OsrestWorkflowOrganisationResult} from "./interfaces/osrest-workflow-organisation-result.interface";
import {OsrestOrganisationGroupsResult} from "./interfaces/osrest-organisation-groups-result.interface";
import {User} from "INTERFACES_PATH/user.interface";
import {OsrestRevisits, OsrestSubscriptionNotifications} from "./interfaces/osrest-subscription-notifications.interface";
import {OsrestWorkflowProcess, OsrestWorkflowInbox, OsrestWorkItem} from "./interfaces/osrest-workflow-inbox.interface";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

@Injectable({providedIn: BackendModule})
export class OsrestDriver implements BackendFacade {
    private readonly serviceBase: string;
    private readonly oswebBase: string;
    private username: string;
    private environment: any;

    constructor(private httpClient: HttpClient, @Inject("$eobConfig") $eobConfig: any,
                @Inject("$injector") private $injector: ng.auto.IInjectorService,
                @Inject("fileCacheService") private fileCacheService: FileCacheService,
                private clientService: ClientService,
                private messageService: MessageService,
                private authenticationService: AuthenticationService,
                private profileService: ProfileService) {

        this.serviceBase = $eobConfig.getServiceBase();
        this.oswebBase = $eobConfig.getOswebBase();

        messageService.subscribeFirst(Broadcasts.ENVIRONMENT_INITIALIZED, (env: TodoEnvironmentService) => this.environment = env);

        authenticationService.statusEvent$.subscribe(x => {
            const authHeader = x.requestHeaders?.find(y => y.key == "Authorization")?.value;
            if(authHeader?.includes("Basic")) {
                const creds: RegExpMatchArray = atob(authHeader.split(" ")[1]).matchAll(/([^:]*):(.*)/g).next().value;
                this.username = creds[1];
            }
        });
    }

    private _stringifyObjectToFormdata(data: any): FormData {
        const headers: any = {
            type: "application/json;charset=UTF-8"
        };
        const blob: Blob = new Blob([JSON.stringify(data)], headers);

        const formData: FormData = new FormData();
        formData.append("data", blob);
        return formData;
    }

    uploadFile(filename: string, blob: Blob): Observable<HttpEvent<string>> {
        const fd: FormData = new FormData();
        fd.append(filename, blob, filename);
        const req: HttpRequest<FormData> = new HttpRequest<FormData>("POST", `${this.oswebBase}/fileUpload.do`, fd, {reportProgress: true, responseType: "text"});
        return this.httpClient.request<string>(req);
    }

    search(request: any, config: string): Observable<OsrestSearchResult[]> {
        const parameters: string = Object.entries(config).map(x => `${x[0]} = ${x[1]}`).join("&");
        const url = `${this.serviceBase}/documents/search?${parameters}`;

        return this.httpClient.post<OsrestSearchResult[]>(url, request);
    }

    searchById(id: string | number, objectTypeId?: string | number, original?: boolean): Observable<OsrestSearchResult|DmsDocument> {
        let url = `${this.serviceBase}/documents/search/${id}?refresh=true&lockinfo=true`;

        if (objectTypeId != void 0) {
            url += `&objecttypeid=${objectTypeId}`;
        }
        return this.httpClient.get<OsrestSearchResult>(url).pipe(map(x => original ? x : new DmsDocument(x, this.$injector)));
    }

    fetchObjectDefinition(): Observable<OsrestObjectDefinition> {
        return throwError("Not implemented");
    }

    fetchCabinets(): Observable<Cabinet[]> {
        return throwError("Not implemented");
    }

    /** Convenience function to use inside catchError() for bulk query error handling */
    private _bulkErrorResponseCatcher(err: HttpErrorResponse, objects: BulkRequestResult[]): Observable<BulkRequestResult[]> {
        if(err.status == 409) {
            const errors: OsrestGenericItemErrorResponse[] = err.error;
            for(const x of errors.map(x => new OsrestErrorResponseEntry(x))) {
                const resultObject: BulkRequestResult = objects.find(y => y.objectId == x.affectedObjectId);
                resultObject.success = false;
                resultObject.errorMessage = x.errorMessage;
                resultObject.errorRoot = x.errorRoot;
            }
            return of(objects);
        } else {
            return throwError(err);
        }
    }

    addFavorites(ids: string[]): Observable<BulkRequestResult[]> {
        const addFavoritesResultArray: BulkRequestResult[] = ids.map((x: string) => ({success: true, objectId: x.toString()} as BulkRequestResult));
        return this.httpClient.post<BulkRequestResult[]>(`${this.serviceBase}/documents/favourites/add`, ids.map(x => ({id: x}))).pipe(
            switchMap(() => of(addFavoritesResultArray)),
            catchError(err => this._bulkErrorResponseCatcher(err, addFavoritesResultArray))
        );
    }

    removeFavorites(ids: string[]): Observable<void> {
        const objectsToAdd: any[] = ids.map(x => ({id: x}));
        return this.httpClient.post<any>(`${this.serviceBase}/documents/favourites/delete`, objectsToAdd);
    }

    checkoutDocument(objectId: string, objectTypeId?: string, undo?: boolean): Observable<void> {
        return this.httpClient.get<void>(`${this.serviceBase}/documentfiles/checkout/${undo ? "undo/" : ""}${objectId}${objectTypeId ? `?objectTypeId=${objectTypeId}` : ""}`);
    }

    retrieveDocumentFiles(objects: OsrestDocumentfilesIdNameMap[], typeOrFileNumber: string, filename: string, addWatermark?: boolean): Observable<HttpResponse<ArrayBuffer>> {
        if(objects.length == 0) {
            return throwError("Empty array given");
        }

        const urlParams: string[] = [];
        if (addWatermark) {
            urlParams.push("watermark=true");
        }
        if (typeOrFileNumber == "zip" && filename) {
            urlParams.push(`filename=${encodeURI(filename)}`);
        }

        let url = `${this.serviceBase}/documentfiles/${objects[0].id}/${typeOrFileNumber}${urlParams.length > 0 ? `?${urlParams.join("&")}` : ""}`;
        if (objects.length > 1 || typeOrFileNumber == "pdf") {
            url = `${this.serviceBase}/documentfiles/${typeOrFileNumber}${urlParams.length > 0 ? `?${urlParams.join("&")}` : ""}`;
        }

        if (objects.length == 1 && typeOrFileNumber != "pdf") {
            return this.httpClient.get(url, {observe: "response", responseType: "arraybuffer"});
        } else {
            let payload: any;
            switch (typeOrFileNumber) {
                case "zip":
                    payload = {
                        archivename: filename,
                        ids: [],
                        type: "zip"
                    };
                    for (const x of objects) {
                        payload.ids.push({id: x.id, name: x.name});
                    }

                    break;
                case "pdf":
                default:
                    payload = {
                        pdfname: filename,
                        ids: objects.map(x => x.id),
                        type: "pdf"
                    };
                    break;
            }
            return this.httpClient.post(url, payload, {observe: "response", responseType: "arraybuffer"});
        }
    }

    queryDocumentFiles(objectId: string): Observable<OsrestDocumentfilesResult> {
        return this.httpClient.get<OsrestDocumentfilesResult>(`${this.serviceBase}/documentfiles/${objectId}`);
    }

    assignTemplateToObject(objectId: string, objectTypeId: string, templateId: string, fillTemplate?: boolean): Observable<never> {
        return this.httpClient.get<never>(`${this.serviceBase}/documents/templates/acquisition?id=${objectId}&objectTypeId=${objectTypeId}&templateId=${templateId}&fillTemplate=${fillTemplate ? "true" : "false"}`);
    }

    retrievePdfRenditionStatus(objectId: string): Observable<number> {
        return this.httpClient.head<void>(`/osrenditioncache/app/api/document/${objectId}/rendition/pdf`, {observe: "response"}).pipe(
            map(x => x.status),
            catchError((err: HttpErrorResponse) => {
                throw err.status;
            })
        );
    }

    retrieveRenditionInformation(objectId: string, maxRetries: number = 50, delayMs: number = 2000): Observable<ProcessedRenditionInfoData> {
        return this.httpClient.get<ProcessedRenditionInfoData>(`/osrenditioncache/app/api/document/${objectId}/rendition/web/info`).pipe(
            mergeMap(x => {
                if(/failed|processing/.test(x.status)) {
                    return throwError(x);
                }
                return of(x);
            }),
            retryWhen(errors => errors.pipe(
                    mergeMap(x => ((typeof x.status == "number" && x.status != 200) || x.status == "failed") ? throwError(errors) : of(x)),
                    concatMap((e, i) =>
                        // Executes a conditional Observable depending on the result
                        // of the first argument
                        iif(
                            () => i > maxRetries - 1,
                            // If the condition is true we throw the error (the last error)
                            throwError(e),
                            // Otherwise we pipe this back into our stream and delay the retry
                            of(e).pipe(delay(delayMs), tap(_ => {
                                console.info(`retrying to retrieve rendition information for ${objectId}`);
                            }))
                        )
                    )
                ),
            )
        );
    }

    retrieveRendition(objectId: string, type: "pdf" | "thumbnail", timeoutMs: number): Observable<ArrayBuffer> {
        return this.httpClient.get(`/osrenditioncache/app/api/document/${objectId}/rendition/${type}?timeout=${timeoutMs}`, {responseType: "arraybuffer"});
    }

    getChildrenHierarchy(objectId: string): Observable<OsrestChildrenHierarchyResult[]> {
        return this.httpClient.get<OsrestChildrenHierarchyResult[]>(`${this.serviceBase}/documents/childrenHierarchy/${objectId}`);
    }

    getObjectHierarchy(objectId: string): Observable<OsrestObjectResult> {
        return this.httpClient.get<OsrestObjectResult>(`${this.serviceBase}/documents/objectHierarchy/${objectId}`);
    }

    loadSettings(): Observable<AsIni> {
        return this.httpClient.get<{ settings: any }>(`${this.serviceBase}/session/settings/load`).pipe(
            map(x => x.settings)
        );
    }

    saveSettings(settings: AsIni): Observable<void> {
        return this.httpClient.post<void>(`${this.serviceBase}/session/settings/save`, {settings});
    }

    getSettingsTimestamp(): Observable<number> {
        return this.httpClient.get<{ timestamp: string }>(`${this.serviceBase}/session/settings/timestamp`).pipe(
            map(x => +x.timestamp)
        );
    }

    getDropzoneThumbnail(osid: string, index: string): Observable<Blob> {
        return this.httpClient.get(`/osrenditioncache/app/api/document/${osid}/rendition/web/page/${index}/image/120`, {responseType: "blob"});
    }

    querySubscription(aboGroup: string): Observable<OsrestSubscriptionObject> {
        return this.httpClient.get<OsrestSubscriptionObject>(`${this.serviceBase}/notifications/aboGroup/${aboGroup}`);
    }

    getStartableWorkflowModels(clientType?: "web" | "mobile" | "desktop" | "web_de" | "web_en" | "web_fr"): Observable<OsrestWorkflowResult[]> {
        if (!this.clientService.isOnline()) {
            return from(this.fileCacheService.getContentAsync(DatabaseEntryType.PERSISTENT, ProfileCacheKey.STARTABLE_WORKFLOWS, {first: true}) ?? []);
        }
        return this.httpClient.get<OsrestWorkflowResult[]>(`${this.serviceBase}/workflows${clientType ? `?clienttype=${clientType}&` : "?"}objecttypes=true`)
            .pipe(tap(x => {
                void this.fileCacheService.storeContentAsync(DatabaseEntryType.PERSISTENT, x, ProfileCacheKey.STARTABLE_WORKFLOWS);
            }));
    }

    deleteOsRenditionCache(osid: number): Observable<void> {
        return this.httpClient.get<void>(`/osrenditioncache/app/api/document/${osid}?_method=delete&t=${Date.now()}`);
    }

    changePassword(oldPassword: string, newPassword: string, backendOrigin?: string, authHeader?: { [k: string]: string }): Observable<void> {
        let headers = new HttpHeaders();
        if (authHeader) {
            Object.keys(authHeader).forEach(k => {
                headers = headers.set(k, authHeader[k]);
            });
        }
        // No need to add the active backend origin here, since it's handled by the interceptor
        return this.httpClient.post<void>(`${backendOrigin ?? ""}${this.serviceBase}/session/changePassword`, {oldPassword: btoa(oldPassword), newPassword: btoa(newPassword)}, {headers}).pipe(
            map(() => {
                if(this.authenticationService.authenticationProvider instanceof BasicAuthService && this.username) {
                    this.authenticationService.authenticate(backendOrigin ?? this.profileService.getCurrentBaseUrl(), {
                        authType: AuthenticationType.BASIC_AUTH,
                        username: this.username,
                        password: newPassword
                    }).subscribe();
                }
            })
        );
    }


    getIcons(iconIds: string[]): Observable<OsrestIcons> {
        return this.httpClient.post<OsrestIcons>(`${this.serviceBase}/icon/ids`, {iconIds}).pipe(
            catchError((err: HttpErrorResponse) => {
                if(err.status == 409) {
                    return of(err.error);
                }
                return throwError(err);
            })
        );
    }

    getWorkflowOrganisation(): Observable<OsrestWorkflowOrganisationResult> {
        return this.httpClient.get<OsrestWorkflowOrganisationResult>(`${this.serviceBase}/workflows/organisation`);
    }

    getOrganisationUsers(): Observable<User[]> {
        return this.httpClient.get<User[]>(`${this.serviceBase}/organization/users`);
    }

    getOrganisationGroups(): Observable<OsrestOrganisationGroupsResult[]> {
        return this.httpClient.get<OsrestOrganisationGroupsResult[]>(`${this.serviceBase}/organization/groups?all=true&loadUsers=true`);
    }

    getRevisits(): Observable<OsrestRevisits> {
        return this.httpClient.get<OsrestRevisits>(`${this.serviceBase}/notifications/revisits?verbose=true&showown=true&fieldschema=AUTO`);
    }

    getRunningProcesses(): Observable<OsrestWorkflowProcess[]> {
        return this.httpClient.get<OsrestWorkflowProcess[]>(`${this.serviceBase}/workflows/running/processes?clienttype=${this.environment.wfClientType}`);
    }

    getRunningWorkflows(): Observable<OsrestWorkflowInbox[]> {
        return this.httpClient.get<OsrestWorkflowInbox[]>(`${this.serviceBase}/workflows/running?verbose=true&reload=true&clienttype=${this.environment.wfClientType}`);
    }

    getRunningWorkflow(id: string): Observable<{ WorkItem: OsrestWorkItem }> {
        return this.httpClient.get<{ WorkItem: OsrestWorkItem }>(`${this.serviceBase}/workflows/running/full/${id}?refresh=true&personalize=true&clienttype=${this.environment.wfClientType}`);
    }

    getSubscriptionNotifications(): Observable<OsrestSubscriptionNotifications> {
        return this.httpClient.get<OsrestSubscriptionNotifications>(`${this.serviceBase}/notifications/subscriptions?verbose=true&showown=true&fieldschema=AUTO`);
    }

    getSubscriptionObjects(): Observable<OsrestSubscriptionObject[]> {
        return this.httpClient.get<OsrestSubscriptionObject[]>(`${this.serviceBase}/notifications/subscriptionObjects?verbose=true&includeGroupSubscriptions=true&fieldschema=AUTO`);
    }


}
