const he = require("he");

angular.module("eob.core").factory("documentView", DocumentView);

DocumentView.$inject = ["$timeout", "$injector"];

function DocumentView($timeout, $injector) {
    let urlBase = "/osrenditioncache/app";
    let foreignThumbnailURLLayout, imageURLLayout;
    let globalTimeout = 30000;

    let IMAGE_SIZE_THUMBNAIL = "96";
    let IMAGE_SIZE_MIDDLE = "1200";

    let THUMBNAIL_PADDING = "8";

    let COMPLETE_THUMBNAIL_HEIGHT = Number(IMAGE_SIZE_THUMBNAIL) + Number(THUMBNAIL_PADDING);

    let MAX_THUMBNAILS = 30;

    // There is only one view
    let view = null;
    let activeThumb = null;
    let thumbnails = [];

    return {
        getView,
        initView,
        setViewer,
        setThumbActive
    };

    function setViewer(viewer) {
        if (view == void 0) {
            view = new View();
        }

        view.setViewer(viewer);
    }

    function initView() {
        try {
            let profileService = $injector.get("profileService");
            if (/^\//.test(urlBase)) {
                urlBase = `${profileService ? profileService.getCurrentBaseUrl() : ""}${urlBase}`
            }

        } catch (error) {console.info(error)}
        foreignThumbnailURLLayout = `${urlBase}/api/document/IDIDID/rendition/thumbnail?timeout=${globalTimeout}&timestamp=TIMESTAMPTIMESTAMP`;
        imageURLLayout = `${urlBase}/api/document/IDIDID/rendition/web/page/PAGEPAGEPAGE/image/SIZESIZESIZE?timestamp=TIMESTAMPTIMESTAMP&timeout=${globalTimeout}`;

        if (view == void 0) {
            view = new View();
        }
    }

    function getView() {
        initView();

        return view;
    }

    function setThumbActive(currentPage) {
        if (!thumbnails.length) {
            return;
        }
        for (let i in thumbnails) {
            //var thumb = thumbnails[i]["el"] == void 0 ? thumbnails
            thumbnails[i]["el"].removeClass("thumb-active");
            if (thumbnails[i].pageNumber == currentPage) {
                activeThumb = thumbnails[i]["el"];
                activeThumb.addClass("thumb-active");
            }
        }
    }

    function View() {

        let oldZoomFactor;

        let self = this;

        self.viewer = null;
        self.thumbnailsVisible = false;
        self.isCustomZoomState = false;

        self.customZoomFactor = 0;

        self.currentSearchHits = [];

        self.isThumbnailsInitialized = false;

        self.firstVisibleThumbnail = 0;
        self.lastVisibleThumbnail = 0;

        self.isShowAttachments = false;

        self.isSearchVisible = false;
        self.isAttachmentVisible = false;
        let wordContainerPool = [];

        let lastTop = 0;

        let currentSearchHitMarker = null;

        self.resetView = function() {
            self.documentId = 0;
            self.searchVisible = false;
            self.afterWindowResize = false; // self is set to true after the user has resized the window (we need to do some special stuff in that case)

            self.initialHeight = 0;
            self.initialWidth = 0;

            self.isCustomZoomState = false;

            self.initialZoomHeight = 0;
            self.initialZoomWidth = 0;

            self.customZoomFactor = 0;

            self.lastPage = -1;
            self.lastNumber = -1;

            self.firstVisibleThumbnail = 0;
            self.lastVisibleThumbnail = 0;

            self.currentSearchHits = [];

            self.adjustDimensions();
            self.removeTextSelection();
            self.removeSearchSelection();
        };

        self.setViewer = function(viewer) {
            self.viewer = viewer;
        };

        self.setDocumentId = function(id) {
            self.documentId = id;
        };

        function resetThumbnails() {
            for (let i = 0; i < thumbnails.length; i++) {

                let thumb = thumbnails[i];
                let top = COMPLETE_THUMBNAIL_HEIGHT * i;

                delete thumb.pageIndex;

                thumb.isUrlSet = false;
                thumb.index = i;
                thumb.img[0].src = null;
                thumb.img[0].src = "";
                // thumb.img[0].classList = 'icon-16-dummy';
                thumb.top = top;
                thumb.el.css({
                    top
                });
            }
            setThumbActive(1);
            self.viewer.layout.thumbnailContainer.scrollTop(0);
        }

        self.lastNumber = -1;

        self.thumbnailDefaultWidth = 96;
        self.thumbnailDefaultHeight = 96;
        self.initialHeight = 0;
        self.initialWidth = 0;

        self.getViewportWidth = function() {
            return self.viewer.layout.getViewportWidth();
        };

        self.getViewportHeight = function() {
            return self.viewer.layout.getViewportHeight();
        };

        self.searchVisible = false;
        self.afterWindowResize = false; // self is set to true after the user has resized the window (we need to do some special stuff in that case)

        self.lastPage = -1; // remember which was the last page we have shown
        self.lastPageRendered = false;

        // 3 zoom states available
        // - fit into viewport width
        // - fit into viewport height
        // - show original size (with probably a lot of scrolling)
        self.zoomState = 0;

        let reInitTextPosition = function() {
            self.highlightSearchResults(self.viewer.currentPage);

            (async () => {
                const text = await viewer.getPageText(self.viewer.currentPage);
                self.overlayPageText(text);
            })
        };

        self.hideAttachments = function() {
            self.attachmentsVisible = false;
            self.viewer.layout.showAttachmentsButton.removeClass("active");
        };

        self.toggleAttachments = function() {

            self.thumbnailsVisible = false;
            self.attachmentsVisible = !self.attachmentsVisible;

            self.viewer.layout.showThumbnailsButton.removeClass("active");
            self.viewer.layout.showAttachmentsButton.toggleClass("active");

            if (self.attachmentsVisible) {

                if (self.isSearchVisible) {
                    self.switchSearch();
                }

                self.showAttachments();
            } else {
                self.hideAttachments();
            }
        };

        self.toggleThumbnails = function() {
            self.thumbnailsVisible = !self.thumbnailsVisible;

            if (self.attachmentsVisible) {
                self.attachmentsVisible = false;
                self.initThumbnails();
            }

            self.hideAttachments();

            self.viewer.layout.showAttachmentsButton.removeClass("active");
            self.viewer.layout.showThumbnailsButton.toggleClass("active");

            if (self.thumbnailsVisible) {
                if (self.isSearchVisible) {
                    self.switchSearch();
                }

                self.showThumbnails();
            } else {
                self.hideThumbnails();
            }
        };

        self.hideSearch = function() {
            self.viewer.layout.searchContainer.removeClass("open");
            self.viewer.layout.searchContainer.addClass("close");
            self.viewer.layout.showSearchButton.removeClass("active");
            self.viewer.layout.searchInput.blur();
        };

        self.showSearch = function() {

            self.thumbnailsVisible = false;
            self.hideAttachments();
            self.attachmentsVisible = false;
            self.isSearchVisible = true;
            self.hideThumbnails();

            self.viewer.layout.showAttachmentsButton.removeClass("active");
            self.viewer.layout.showThumbnailsButton.removeClass("active");
            self.viewer.layout.showSearchButton.addClass("active");

            self.viewer.layout.searchContainer.addClass("open");
            self.viewer.layout.searchContainer.removeClass("close");

            $timeout(() => {
                self.viewer.layout.searchInput.focus();
            });

            reInitTextPosition();
        };

        self.switchSearch = function() {

            self.isSearchVisible = !self.isSearchVisible;

            if (self.isSearchVisible) {
                self.showSearch();
            } else {
                self.hideSearch();
            }

            $timeout(() => {
                self.viewer.layout.searchInput.focus();
            });

            reInitTextPosition();
        };

        // todo: should contain some logic to determine whether searching/zooming is possible etc...
        self.showNavigationBar = function() {
            self.viewer.layout.toolbar.show();
            self.viewer.layout.footerContainer.show();

            if (self.thumbnailsVisible) {
                self.viewer.layout.thumbnailContainer.show();
            }
        };

        /*
         *  Hide useless controls in error scenarios where the document could not be generated overall
         */
        self.hideControls = function() {
            self.viewer.layout.toolbar.hide();
            self.viewer.layout.thumbnailContainer.hide();
            self.viewer.layout.footerContainer.hide();
        };

        self.showErrorPage = function(errorCode) {

            if (!errorCode) {
                errorCode = "document-404";
            } else if (errorCode === "404") {
                errorCode = "document-404";
            } else if (errorCode === "403") {
                errorCode = "document-403";
            }

            self.viewer.layout.content.hide();
            self.viewer.layout.defaultPage.hide();

            if (self.viewer.isAttachment) {
                self.viewer.layout.contentErrorContainer.show();
                self.viewer.layout.contentErrorImage.attr("class", `${errorCode}`);
            } else {
                self.viewer.layout.errorContainer.show();
                self.viewer.layout.errorImage.attr("class", `icon-16-${errorCode}`);
                self.viewer.layout.viewerContainer.hide();
                self.hideControls();
            }
        };

        let debounce = null,
            searchKeypressDebounce = null,
            isScrollBound = false;

        self.bindThumbnailScrollEvent = function() {

            if (isScrollBound) {
                return;
            }

            isScrollBound = true;

            self.viewer.layout.thumbnailContainer.bind("scroll", () => {

                rearrangeThumbnails();
                clearTimeout(debounce);

                debounce = setTimeout(() => {
                    setThumbnailUrls();
                    setThumbActive(self.currentPage);
                }, 50);
            });
        };

        self.bindMouseScrollEvent = function() {
            let contentContainer = self.viewer.layout.contentContainer;

            contentContainer.bind("wheel", (e) => {
                if (e.ctrlKey) {
                    // cross-browser wheel delta
                    if (e.preventDefault) {
                        e.preventDefault();
                    } else {
                        e.returnValue = false;
                    }

                    let wheelDelta = e.originalEvent.wheelDelta;
                    let delta = Math.max(-0.1, Math.min(0.1, wheelDelta));

                    self.setCustomZoom(delta);
                    self.adjustDimensions();

                    self.viewer.layout.viewerContainer.focus();
                }
            });
        };

        let lastSearchValue = "";

        self.bindSearchKeypressEvent = function() {
            self.viewer.layout.searchInput.bind("keyup", (e) => {

                let value = angular.element(e.target).val();

                // nothing changed, return
                if (value == lastSearchValue && e.which != 13) {
                    return;
                }

                lastSearchValue = value;

                clearTimeout(searchKeypressDebounce);

                if (e.which == 13 && value.length) {
                    if (e.shiftKey) {
                        self.prevSearchHit();
                    } else {
                        self.nextSearchHit();
                    }
                } else if (value.length > 2) {
                    searchKeypressDebounce = setTimeout(() => {
                        self.viewer.search(value);
                    }, 250);
                } else {
                    self.removeSearchSelection();
                    self.viewer.layout.searchResultCount.hide();
                    self.viewer.layout.searchInput.removeClass("notFound");
                }
            });
        };

        self.bindPageNumberKeypress = function() {
            self.viewer.layout.currentPageNumber.bind("keypress", (e) => {
                if (e.key === "Enter") {
                    return true;
                }
                if (validateAndExtractPageNumber(e.key) < 0) {
                    return false;
                }
            });

            self.viewer.layout.currentPageNumber.bind("keyup", (e) => {
                let pageNumber = validateAndExtractPageNumber(self.viewer.layout.currentPageNumber.val());
                if (e.which == 13) { //ASCII: carriage return
                    if (pageNumber && pageNumber !== self.currentPage
                        && pageNumber > 0
                        && pageNumber <= Number(self.viewer.totalPageCount)) {
                        self.viewer.goToPage(pageNumber);
                    } else {
                        self.viewer.layout.currentPageNumber.val(self.currentPage);
                    }
                }
            });

            self.viewer.layout.currentPageNumber.bind("focus", (e) => {
                self.viewer.layout.currentPageNumber.select();
            });
        };

        function validateAndExtractPageNumber(value) {
            value = Number(value);
            if (value === void 0 || value === "" || value < 0 || value > Number(self.viewer.totalPageCount) || isNaN(value)) {
                return -1;
            }
            return value;
        }

        self.bindClickHandler = function(thumbnail) {
            thumbnail.el.bind("click", () => {
                self.viewer.goToPage(thumbnail.pageNumber);
                //setThumbActive(thumbnail.pageNumber);
            });
        };

        self.showAttachments = function() {
            self.attachmentsVisible = true;
            self.thumbnailsVisible = false;
            self.viewer.layout.thumbnailContainer.show();

            self.hideSearch();

            resetThumbnails();
            setThumbnailVisibility();
            setThumbnailUrls();
        };

        self.calcThumbnailWrapperHeight = function() {
            let thumbnailWrapper = self.viewer.layout.thumbnailWrapper;
            let thumbnailWrapperHeight = (self.viewer.pages.length - 1) * (Number(IMAGE_SIZE_THUMBNAIL) + Number(THUMBNAIL_PADDING));

            thumbnailWrapper.height(thumbnailWrapperHeight);
        };

        function setThumbnailVisibility() {
            for (let i = 0; i < thumbnails.length; i++) {
                let maxCount = self.attachmentsVisible ? self.viewer.attachments.length : self.viewer.pages.length - 1 - self.viewer.attachments.length;
                if (i >= maxCount) { // -2 because the first element inside the pages is a dummy
                    thumbnails[i].el.css({ display: "none" });
                } else {
                    thumbnails[i].el.css({ display: "flex" });
                }
            }
        }

        self.initThumbnails = function() {

            let thumbnailWrapper = self.viewer.layout.thumbnailWrapper;

            self.calcThumbnailWrapperHeight();

            if (self.isThumbnailsInitialized) {
                resetThumbnails();
                setThumbnailVisibility();
                setThumbnailUrls();
                return;
            }

            for (let i = 0; i < MAX_THUMBNAILS; i++) {
                let thumb = angular.element("<div class='thumb'></div>");
                let img = angular.element("<img>");

                thumb.append(img);
                let top = COMPLETE_THUMBNAIL_HEIGHT * i;
                let left = (200 - IMAGE_SIZE_THUMBNAIL) / 2;

                thumb.css({
                    top,
                    left,
                    "position": "absolute",
                    "height": "96px",
                    "width": "96px"
                });
                thumbnailWrapper.append(thumb);

                let thumbnail = { el: thumb, top, index: i, img, isUrlSet: false };

                self.bindClickHandler(thumbnail);

                thumbnails.push(thumbnail);
            }

            self.isThumbnailsInitialized = true;

            setThumbnailVisibility();

            setThumbnailUrls();

            setThumbActive(1);
        };

        function rearrangeThumbnails() {
            // using only the visible area to avoid giant dom nodes
            // this would impact the responsiveness of the whole browser

            // at this point once the user scrolles some thumbnails will be scrolled outside the
            // visible area, we use these containers and adjust their top position and shift them through our thumbnail array
            // this allows us to only use 30 images for giant documents
            let firstThumbTop = thumbnails[0].top;
            let lastThumbTop = thumbnails[thumbnails.length - 1].top;
            let thumbContainer = self.viewer.layout.thumbnailContainer;

            let currentTop = thumbContainer.scrollTop();
            let currentBottom = currentTop + thumbContainer.height();
            let needToShift, shiftable;

            // scrolling down
            if (lastTop < currentTop) {

                if (lastThumbTop + COMPLETE_THUMBNAIL_HEIGHT > self.viewer.layout.thumbnailWrapper.height()) {
                    return;
                }

                if (currentBottom + (10 * COMPLETE_THUMBNAIL_HEIGHT) > lastThumbTop) {

                    if (activeThumb.offset().top + activeThumb.height() <= thumbContainer.offset().top) {
                        activeThumb.removeClass("thumb-active");
                    }

                    needToShift = true;

                    while (needToShift) {
                        shiftable = thumbnails.shift();
                        shiftable.top = lastThumbTop + COMPLETE_THUMBNAIL_HEIGHT;
                        shiftable.el.css({ top: shiftable.top });
                        shiftable.index = shiftable.index + MAX_THUMBNAILS;
                        shiftable.isUrlSet = false;

                        thumbnails.push(shiftable);

                        //firstThumbTop = thumbnails[0].top; --> SonarQube Error
                        lastThumbTop = shiftable.top;

                        if (currentBottom + (10 * COMPLETE_THUMBNAIL_HEIGHT) <= lastThumbTop) {
                            needToShift = false;
                        }
                    }
                }
            } else if (currentTop - (10 * COMPLETE_THUMBNAIL_HEIGHT) < firstThumbTop) {
                // scrolling up
                needToShift = true;

                if (activeThumb.offset().top == thumbContainer.offset().top + thumbContainer.height()) {
                    activeThumb.removeClass("thumb-active");
                }

                while (needToShift) {
                    shiftable = thumbnails.pop();
                    shiftable.top = firstThumbTop - COMPLETE_THUMBNAIL_HEIGHT;
                    shiftable.el.css({ top: shiftable.top });
                    shiftable.index = shiftable.index - MAX_THUMBNAILS;
                    shiftable.isUrlSet = false;

                    thumbnails.unshift(shiftable);

                    firstThumbTop = shiftable.top;
                    //lastThumbTop = thumbnails[thumbnails.length - 1].top; --> SonarQube Error

                    if (currentTop - (10 * COMPLETE_THUMBNAIL_HEIGHT) >= firstThumbTop) {
                        needToShift = false;
                    }
                }
            }
            lastTop = currentTop;
        }

        function setThumbnailUrls() {

            // set the image urls for every thumbnail inside the visible area
            let currentTop = self.viewer.layout.thumbnailContainer.scrollTop();
            let currentBottom = currentTop + self.viewer.layout.thumbnailContainer.height();

            let skippedItems = 0;

            for (let i = 0; i < thumbnails.length; i++) {
                let thumb = thumbnails[i];
                if ((thumb.top + COMPLETE_THUMBNAIL_HEIGHT) > currentTop || thumb.top < currentBottom) {
                    if (!thumb.isUrlSet) {
                        let thumbnailURL = "";
                        let pageIndex = thumb.index + 1;

                        // the user might scroll way too fast, this leads to a shifting process inside the
                        // thumbnail list, but the now first element would be a negative pageIndex, which might
                        // not be the desired page to display ^^
                        if (pageIndex < 0) {
                            continue;
                        }

                        // reached the end of the line for normal pages
                        if (self.viewer.pages[pageIndex] == void 0 && !self.attachmentsVisible) {
                            return;
                        }

                        // reached the end of the attachments
                        if (self.viewer.attachments[i - skippedItems] == void 0 && self.attachmentsVisible) {
                            return;
                        }

                        if (self.attachmentsVisible && !self.viewer.pages[pageIndex].foreign_id) {
                            skippedItems++;
                            continue;
                        }

                        if (self.viewer.pages[pageIndex].foreign_id) {
                            // setting foreign url for email attachment
                            if (self.attachmentsVisible) {
                                thumb = thumbnails[i - skippedItems];
                                thumb.pageNumber = self.viewer.attachments[i - skippedItems].number;
                                thumbnailURL = foreignThumbnailURLLayout.replace(/IDIDID/g, self.viewer.attachments[i - skippedItems].foreign_id);
                            }

                        } else {
                            thumbnailURL = imageURLLayout.replace(/IDIDID/g, self.documentId);
                            thumbnailURL = thumbnailURL.replace(/PAGEPAGEPAGE/g, pageIndex);
                            thumbnailURL = thumbnailURL.replace(/SIZESIZESIZE/g, IMAGE_SIZE_THUMBNAIL);

                            thumb.pageNumber = self.viewer.pages[pageIndex].number;
                        }

                        thumbnailURL = thumbnailURL.replace(/TIMESTAMPTIMESTAMP/g, self.viewer.globalTimestamp);

                        thumb.img[0].src = null;
                        thumb.img[0].src = thumbnailURL;
                        thumb.isUrlSet = true;
                    }
                }
            }
        }

        self.showThumbnails = function() {
            self.thumbnailsVisible = true;
            self.viewer.layout.thumbnailContainer.show();

            if (self.attachmentsVisible) {
                self.attachmentsVisible = false;
                resetThumbnails();
                setThumbnailUrls();
                setThumbnailVisibility();
            }

            self.hideSearch();
        };

        self.hideThumbnails = function() {
            self.thumbnailsVisible = false;
            self.viewer.layout.thumbnailContainer.hide();
        };

        /**
         * refresh the input field for the page number
         **/
        self.updatePageNumber = function(number) {
            self.viewer.layout.currentPageNumber.val(number);
            self.viewer.layout.currentPageNumber.attr("size", (`${self.viewer.pages.length}`).length); // ""+number.length
        };

        self.sizeViewToFit = function() {
            self.viewer.globalViewerService.reattachResizeSensor();
            self.isCustomZoomState = false;

            self.initialZoomHeight = 0;
            self.initialZoomWidth = 0;

            self.customZoomFactor = 0;

            self.adjustDimensions();
        };

        self.setCustomZoom = function(factor) {

            if (!self.isCustomZoomState) {
                self.initialZoomWidth = self.initialWidth == 0 ? self.viewer.layout.content.find("img").width() : self.initialWidth;
                self.initialZoomHeight = self.initialHeight == 0 ? self.viewer.layout.content.find("img").height() : self.initialHeight;
            }

            self.isCustomZoomState = true;

            let newZoomFactor = self.customZoomFactor + factor;
            let newHeight = self.initialZoomHeight + self.initialZoomHeight * newZoomFactor;
            let newWidth = self.initialZoomWidth + self.initialZoomWidth * newZoomFactor;

            if (newHeight < 6 || newWidth < 6) {
                return;
            }

            self.customZoomFactor = newZoomFactor;
            self.viewer.zoomedHeight = newHeight;
            self.viewer.zoomedWidth = newWidth;

            self.viewer.layout.pageContainer.css({ "margin-left": "auto", "margin-right": "auto" });
            self.viewer.layout.content.find("img").css({ "flex": "0 0 auto" });
        };

        let adjustDebounce = null;

        self.adjustDimensions = function() {

            adjustDebounce = setTimeout(() => {
                self.overlayPageText(self.viewer.pageTextCache[self.viewer.currentPage]);
            }, 250);

            clearTimeout(adjustDebounce);

            if (self.viewer.layout.content == void 0) {
                return;
            }

            let image = self.viewer.layout.content.find("img");
            let pageContainer = self.viewer.layout.pageContainer;
            let textContainer = self.viewer.layout.textContainer;

            if (self.isCustomZoomState) {
                pageContainer.height(self.viewer.zoomedHeight);
                pageContainer.width(self.viewer.zoomedWidth);

                pageContainer.css({ "position": "relative" });

                textContainer.height("100%");
                textContainer.width("100%");

                image.height("100%");
                image.width("100%");
            } else {
                let dims = self.getScaledDimensions();

                self.initialHeight = dims.height;
                self.initialWidth = dims.width;

                pageContainer.height(self.initialHeight);
                pageContainer.width(self.initialWidth);

                pageContainer.css({ "position": "absolute" });

                textContainer.height(dims.height);
                textContainer.width(dims.width);

                image.height(dims.height);
                image.width(dims.width);
            }

            if (image.length) {
                reInitTextPosition();
            }
            let evt = $.Event("scalechange");
            evt.origin = "documentviewer";
            angular.element("document-viewer").trigger(evt);
        };

        self.getScaledDimensions = function() {
            let dims = { width: 0, height: 0 };

            let page = self.viewer.getPage(self.viewer.currentPage);

            if (page == void 0) {
                return dims;
            }

            let scaledHeight, scaledWidth;
            let maxAvailableWidth = self.getViewportWidth();
            let ratio = maxAvailableWidth / page.fullWidth;

            if (self.isCustomZoomState) {
                scaledHeight = self.viewer.zoomedHeight;
                scaledWidth = self.viewer.zoomedWidth;
            } else {
                scaledHeight = parseInt(page.fullHeight * ratio) - (self.viewer.layout.contentPadding * 2);
                scaledWidth = parseInt(maxAvailableWidth) - (self.viewer.layout.contentPadding * 2);
            }

            if (self.customZoomFactor != 0 && self.initialZoomWidth === self.viewer.zoomedWidth) {
                scaledHeight += scaledHeight * self.customZoomFactor;
                scaledWidth += scaledWidth * self.customZoomFactor;
            }

            dims.width = scaledWidth;
            dims.height = scaledHeight;

            return dims;
        };

        // showPage is called when navigating through the pages via thumbnails or the navigation arrows.
        self.showPage = function(number, zoomFactor, isResize) {

            let page = self.viewer.getPage(number);

            if (!page) {
                self.lastPageRendered = false;
                return false;
            }

            self.viewer.preloadPage(number, false);

            // v1.5 - Now preload the next image from the document if there is any, but not for externally referenced docs
            let nextPage = self.viewer.getPage(number + 1);
            if (nextPage && !nextPage.isForeign()) {
                // By preloading the full resolution image for the next page, we can speed up the page-browsing process significantly for a normal speed reader.
                // Users that read faster than the preloading works won't benefit but won't be harmed anyway either.
                self.viewer.preloadPage(number, true);
            }

            self.lastPage = number;

            self.updatePageNumber(number);

            // if the page rendering failed we show the error page (?)
            if (page.fullView == -2) {
                self.showErrorPage();
                self.lastPageRendered = true;
                return true;
            }

            // is the page file available for display?

            if (page.fullView == true) {
                self.lastPageRendered = true;
                self.currentPage = number;

                let dims = self.getScaledDimensions();

                let fullURL = imageURLLayout.replace(/IDIDID/g, self.documentId);
                fullURL = fullURL.replace(/PAGEPAGEPAGE/g, self.currentPage);
                fullURL = fullURL.replace(/SIZESIZESIZE/g, IMAGE_SIZE_MIDDLE);
                fullURL = fullURL.replace(/TIMESTAMPTIMESTAMP/g, self.viewer.globalTimestamp);

                let evt = $.Event("pagesloaded");
                evt.origin = "documentviewer";
                evt.maxPages = self.viewer.totalPageCount;

                setTimeout(() => {
                    angular.element("document-viewer .viewer").trigger(evt);
                }, 250);

                if (number != self.lastNumber || self.zoomState != oldZoomFactor || zoomFactor || isResize) {

                    self.removeTextSelection();
                    self.removeSearchSelection();

                    let pageContainer = self.viewer.layout.pageContainer;

                    pageContainer.attr("data-page-number", number);

                    let image = angular.element(`<img class='documentView'  src='${fullURL}' alt='' width='${dims.width}' height='${dims.height}' title=''>`);

                    pageContainer.find("img").remove();
                    pageContainer.append(image);

                    let textContainer = self.viewer.layout.textContainer;

                    textContainer.width(dims.width);
                    textContainer.height(dims.height);

                    pageContainer.width(dims.width);
                    pageContainer.height(dims.height);

                    $("body").scrollTop(0);

                    oldZoomFactor = self.zoomState;
                    self.lastNumber = number;
                    setTimeout(() => {
                        let evt = $.Event("updateviewarea");
                        $("body").trigger(evt);
                    }, 500);

                    self.viewer.layout.content.fadeIn("slow");
                    self.viewer.layout.viewerContainer.show();
                    self.viewer.layout.footerContainer.show();
                    self.viewer.layout.view.show();
                }

                return true;
            } else {
                self.lastPageRendered = false;
                self.viewer.layout.content.hide();
                return false;
            }
        };

        self.copySelectionToClipboard = function() {
            let line = null, newLine;
            let text = "";
            for (let i in wordContainerPool) {
                let element = wordContainerPool[i].element;
                if (element.hasClass("selected")) {
                    newLine = element.attr("line");

                    if (line == void 0) {
                        line = newLine;
                    }

                    if (text !== "") {
                        text += " ";
                    }

                    if (line != newLine && text !== "") {
                        text += "\n";
                        line = newLine;
                    }

                    text += he.decode(element.attr("value"));
                }
            }

            if (text !== "") {
                copyTextToClipboard(text);
                //text = ""; --> SonarQube Error
            }
        };

        function copyTextToClipboard(text) {
            let textArea = document.createElement("textarea");

            //
            // *** This styling is an extra step which is likely not required. ***
            //
            // Why is it here? To ensure:
            // 1. the element is able to have focus and selection.
            // 2. if element was to flash render it has minimal visual impact.
            // 3. less flakyness with selection and copying which **might** occur if
            //    the textarea element is not visible.
            //
            // The likelihood is the element won't even render, not even a flash,
            // so some of these are just precautions. However in IE the element
            // is visible whilst the popup box asking the user for permission for
            // the web page to copy to the clipboard.
            //

            // Place in top-left corner of screen regardless of scroll position.
            textArea.style.position = "fixed";
            textArea.style.top = 0;
            textArea.style.left = 0;

            // Ensure it has a small width and height. Setting to 1px / 1em
            // doesn't work as this gives a negative w/h on some browsers.
            textArea.style.width = "2em";
            textArea.style.height = "2em";

            // We don't need padding, reducing the size if it does flash render.
            textArea.style.padding = 0;

            // Clean up any borders.
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";

            // Avoid flash of white box if rendered for any reason.
            textArea.style.background = "transparent";

            textArea.value = text;

            document.body.appendChild(textArea);

            textArea.select();

            try {
                let successful = document.execCommand("copy");
                let msg = successful ? "successful" : "unsuccessful";
            } catch (err) {
                console.warn("Oops, unable to copy");
            }

            document.body.removeChild(textArea);
        }

        self.removeTextSelection = function() {
            for (let i in wordContainerPool) {
                wordContainerPool[i].element.removeClass("selected");
            }
        };

        self.removeSearchSelection = function() {
            for (let i in wordContainerPool) {
                wordContainerPool[i].element.removeClass("searched");
                wordContainerPool[i].element.removeClass("highlight");
            }
            self.currentSearchHits = [];
            currentSearchHitMarker = null;
        };

        self.markSearchHit = function(index) {
            if (self.currentSearchHits.length) {
                if (currentSearchHitMarker != void 0) {
                    currentSearchHitMarker.element.removeClass("highlight");
                    currentSearchHitMarker.highlighted = false;
                }

                currentSearchHitMarker = self.currentSearchHits[index];
                currentSearchHitMarker.element.addClass("highlight");
                currentSearchHitMarker.highlighted = true;
            }
        };

        self.highlightSearchResults = function(pageIndex) {

            // if we get called but do not have any search results, do nothing...
            if (!self.viewer.cachedSearchResults) {
                return;
            }

            if (self.viewer.layout.searchInput.val() === "") {
                return;
            }

            if (self.viewer.cachedSearchResults.documents == void 0) {
                return;
            }

            let pageSearchHits = self.viewer.cachedSearchResults.documents[1].pages;
            let currentHits = pageSearchHits[pageIndex];

            let hitCount = self.viewer.cachedSearchResults.count;

            self.viewer.layout.searchResultCount.show();
            self.viewer.layout.searchResultCount.text(hitCount);
            // there was a search, but it returned a hitcount of 0
            if (currentHits == void 0) {
                return;
            }

            let searchHits = [];

            self.currentSearchHits = [];

            for (let lineIndex in currentHits.lines) {
                let line = currentHits.lines[lineIndex];
                for (let wordindex in line.words) {
                    searchHits.push(line.words[wordindex].id);
                }
            }

            for (let i in wordContainerPool) {
                let word = wordContainerPool[i];
                if (word.active && searchHits.indexOf(word.id) != -1) {
                    word.element.addClass("searched");

                    // remember the current search hits for later iterating them when the user goes through all results
                    self.currentSearchHits.push(word);
                }
            }
        };

        /**
         * go to the next search hit
         * @param direction the direction the user wants to navigate to
         *
         * a ngative direction means backwards and a positive forward
         */
        function navigateToNextSearchHit(direction) {
            // we got no cached search results -> the user acts faster than the search response...
            if (!self.viewer.cachedSearchResults || !self.viewer.cachedSearchResults.documents[1].pages) {
                return;
            }

            let documentSearchHits = self.viewer.cachedSearchResults.documents[1].pages;
            let currentPageNumber = self.viewer.currentPage.toString();

            if (documentSearchHits[currentPageNumber] != void 0) {
                let index = direction < 0 ? (-self.currentSearchHits.length + 1) : 0;
                let loopEnd = direction < 0 ? 0 : self.currentSearchHits.length;
                for (; index < loopEnd; index++) {
                    let i = Math.abs(index);
                    let nextHitIndex = Math.abs(index + 1);
                    let selectedWord = self.currentSearchHits[i];
                    // find the current highlighted word and check iuf there is another one coming after it
                    if (selectedWord.highlighted && self.currentSearchHits[nextHitIndex] != void 0) {
                        self.markSearchHit(nextHitIndex);
                        return;
                    }
                }
            }

            // get the pageindexes from the object
            let pageNumbers = Object.keys(documentSearchHits);
            // get the current index of the element inside the array
            let currentIndex = pageNumbers.indexOf(currentPageNumber);

            let nextPageNumber = pageNumbers[currentIndex + direction];

            let selectLastHit = direction == -1;
            if (nextPageNumber == void 0) {
                // there is no hit after this one
                return;
            }

            self.viewer.goToPage(nextPageNumber, true, selectLastHit);
        }

        /**
         * go to the next page after the current page with search results on it
         **/
        self.nextSearchHit = function() {
            navigateToNextSearchHit(1);

        };

        self.prevSearchHit = function() {
            navigateToNextSearchHit(-1);
        };
        /**
         * Adds mouse selectable text placeholders on the viewport for the current page
         **/
        self.overlayPageText = function(text) {
            if (text == void 0) {
                return;
            }

            // only read the viewport sizes AFTER showing the page with the first search result
            let textContainer = self.viewer.layout.textContainer;
            //var contentContainer = self.viewer.layout.contentContainer;
            let imageViewport = self.viewer.layout.content.find("img");

            // clean up... remove all previous overlays to avoid duplicate overlays in rare cases...
            let containerWidth = imageViewport.width();
            let containerHeight = imageViewport.height();

            let startLeft = 0;
            let startTop = 0;

            // If the page image was not rendered yet, we cannot determine position of text elements, so we have to delay rendering.
            if (containerWidth == 0 || containerHeight == 0) {
                setTimeout(() => {
                    // To avoid rendering text from page X with a delay onto page X+1 or any other page if a user navigates very fast, we have to make sure we call the overlay with the pagetext for the currently focused page.
                    self.overlayPageText(self.viewer.getPageText(self.viewer.currentPage));
                }, 50);
                return;
            }

            let index = 0;

            for (let lineindex in text.lines) {
                let line = text.lines[lineindex];
                for (let wordindex in line.words) {
                    let word = line.words[wordindex];
                    let relativeXPosition = startLeft + Math.round((containerWidth / 100) * word.x0);
                    let relativeYPosition = startTop + Math.round((containerHeight / 100) * word.y0);
                    let relativeXSize = Math.round((containerWidth / 100) * (word.x1 - word.x0));
                    let relativeYSize = Math.round((containerHeight / 100) * (word.y1 - word.y0));

                    let wordValue = "";
                    if (word.value) {
                        wordValue = word.value;
                    }
                    let wordElement = {};
                    if (wordContainerPool[index] == void 0) {
                        wordElement = {
                            active: true,
                            element: angular.element("<div class='word drop'></div>"),
                            id: word.id
                        };

                        wordContainerPool.push(wordElement);
                        textContainer.append(wordElement.element);

                        bindDropEvent(wordElement.element);
                    } else {
                        wordElement = wordContainerPool[index];
                    }

                    wordElement.element.css({
                        "top": relativeYPosition,
                        "left": relativeXPosition,
                        "width": relativeXSize,
                        "height": relativeYSize,
                        "font-size": relativeYSize,
                        "display": "block"
                    });

                    wordElement.element.attr("value", wordValue);
                    wordElement.element.attr("line", lineindex);
                    wordElement.element.attr("page", self.currentPage);
                    wordElement.element.attr("word", wordindex);

                    wordElement.active = true;
                    index++;
                }
            }

            if (index < wordContainerPool.length) {
                for (index; index < wordContainerPool.length; index++) {
                    wordContainerPool[index].element.css({ display: "none" });
                    wordContainerPool[index].active = false;
                }
            }
        };

        function bindDropEvent(element) {
            element.drop("start", function(e) {
                if (e.target) {
                    let parentClassName = e.target.offsetParent.className;
                    if (parentClassName.indexOf("os-anno ui-droppable") >= 0
                        || (e.target.className && e.target.className.indexOf("annocontainer") >= 0)) {
                        return;
                    }
                }
                $(this).addClass("active");
                self.viewer.isSelectingText = true;
            }).drop(function(ev, dd) {
                if (self.viewer.isSelectingText) {
                    $(this).toggleClass("selected");
                }
            }).drop("end", function() {
                if (self.viewer.isSelectingText) {
                    $(this).removeClass("active");
                    self.viewer.layout.viewerContainer.focus();
                }
            });
        }

        $.drop({ multi: true });
    }
}
