import {Component, Inject, OnInit, ElementRef, ViewChild, ChangeDetectorRef, Input} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {FormFieldModelService} from "MODULES_PATH/form/services/form-field-model.service";
import {WfUser, Field, WfOrgPerformer} from "MODULES_PATH/modal-dialog/interfaces/substitutes-config.interface";
import {FormDefinition} from "MODULES_PATH/form/interfaces/form-definition.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {forwardRef} from "@angular/core";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-substitutes-config",
    templateUrl: "./eob-substitutes-config.component.html",
    styleUrls: ["./eob-substitutes-config.component.scss"]
})

export class EobSubstitutesConfigComponent implements OnInit {
    private readonly translateFn: TranslateFnType;
    newSubstitutes: WfUser[];
    @Input() standalone = true;
    constructor(protected clientService: ClientService,
                @Inject("formFieldModelService") private formFieldModelService: FormFieldModelService,
                @Inject("formFieldBuilder") private formFieldBuilder: any,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                protected notificationsService: NotificationsService,
                @Inject("$filter") protected $filter: ng.IFilterService,
                @Inject("fieldApiService") protected fieldApiService: any,
                @Inject("profileService") protected profileService: any,
                @Inject("organisationService") protected organisationService: any,
                @Inject("formService") protected formService: any,
                @Inject("formHelper") protected formHelper: any,
                @Inject(forwardRef(() => "sortService")) private sortService: any,
                private cd: ChangeDetectorRef,
                private httpService: HttpService) {
        this.translateFn = $filter("translate");
    }

    substitutes: WfUser[] = [];
    isPhone: boolean;
    availableUsers: WfUser[] = [];
    field: Field;
    formDef: FormDefinition = {
        isMainForm: true,
        validationMode: "max",
        formFields: [],
        formHelper: {},
        isMockForm: true
    };
    fieldModel: FieldModel;

    @ViewChild("formrow", {static: true}) formRow: ElementRef<HTMLElement>;
    @ViewChild("configuredsubstitutes", {static: true}) configuredSubstitutes: ElementRef<HTMLElement>;

    ngOnInit(): void {
        this.isPhone = this.clientService.isPhone();

        this.getSubstitutes();

        this.fieldModel = this.formFieldModelService.getMockedField("text", "substitutes", this.translateFn("modal.substitutes.add.display.name"), "list");

        this.field = {
            model: this.fieldModel
        };

        this.formDef.formFields = [this.fieldModel];

        const formData: any = this.formService.createFormData(this.formDef.formFields, "max");

        const helperConfig: any = {
            formData
        };

        this.formDef.formHelper = this.formHelper.getFormHelper(helperConfig);
        this.formDef.formHelper.submit = this.addSubstitutes.bind(this);
    }

    buildWfUserList(): WfUser[] {

        // get all workflow users
        const rawWfUsers: WfOrgPerformer[] = this.organisationService.getWfOrgPerformer();

        // format the result to fit the needs of the setCustomListEntries function
        const wfUser: WfUser[] = [];
        for (const rawWfUser of rawWfUsers) {
            const vorname: string = rawWfUser.vorname != void 0 ? rawWfUser.vorname : "";
            const nachname: string = rawWfUser.vorname != void 0 ? rawWfUser.nachname : "";
            let substituteExists = false;

            // check if any wfUsers are already substitutes
            substituteExists = this.substitutes.concat(this.newSubstitutes ?? []).find(substitute => substitute.id === rawWfUser.id) != undefined;
            // only Persons and no Groups are allowed and you can't define yourself as substitute
            if (!substituteExists && rawWfUser.typ === "Person" && rawWfUser.id != this.environmentService.getSessionInfo().wfGuid) {
                wfUser.push({
                    iconClass: "icon-16-user",
                    short: rawWfUser.name,
                    value: `${vorname} ${nachname}`,
                    id: rawWfUser.id
                });
            }
        }

        this.sortService.sortByTextPath(wfUser, "short");

        // fill the list with the names of all WF users
        this.field = this.formDef.formHelper.getFieldByInternal(this.field.model.internal);
        this.field.model.tree.config.iconCatalog = true;
        this.field.model.tree.config.useMultiSelect = true;
        this.field.api.setCustomListEntries(wfUser);
        this.availableUsers = wfUser;
        this.field.api.bindDefaultOnChange();

        return wfUser;
    }

    removeSubstitute = (itemId: string) => {
        for (const [index, substitute] of this.substitutes.entries()) {
            if (substitute.id == itemId) {
                this.substitutes.splice(index, 1);
                break;
            }
        }

        this.newSubstitutes = [];
        for (const substitute of this.substitutes) {
            this.newSubstitutes.push({
                id: substitute.id
            });
        }
        if(this.standalone) {
            void this.applySubstitutes(true);
        }
        this.buildWfUserList();
    };

    async getSubstitutes(): Promise<void> {
        try {
            const response: any = await this.httpService.legacyGet("/workflows/substitutes");
            this.substitutes = [];

            for (const rd of response.data) {
                if (rd.id) { // avoid invalid user data
                    this.substitutes.push({
                        id: rd.id,
                        name: rd.name || "",
                        userGuid: rd.userGuid
                    });
                }
            }
            this.sortService.sortByTextPath(this.substitutes, "name");
            this.buildWfUserList();
            this.cd.detectChanges();
        } catch (err) {
            console.warn(err);
            this.notificationsService.error(this.translateFn("eob.app.bar.usermenu.workflow.report.substitutes.error.get"));
        }
    }

    async addSubstitutes(): Promise<void> {
        const fieldVal: string = this.field.value;
        this.newSubstitutes = [];

        if (fieldVal != "") {
            for (const substitute of this.substitutes) {
                this.newSubstitutes.push({
                    id: substitute.id
                });
            }

            const userNames: string[] = fieldVal.split(";");

            for (const userName of userNames) {
                userName.trim();
                for (const availableUser of this.availableUsers) {
                    if (availableUser.short.toLocaleLowerCase() == userName.toLocaleLowerCase()) {
                        this.newSubstitutes.push({
                            id: availableUser.id
                        });
                        this.substitutes.push({
                            id: availableUser.id,
                            name: availableUser.short
                        });
                        break;
                    }
                }
            }

            try {
                await this.field.api.validate();
                this.field.api.setValue("");
                this.field.api.focus();
                if(this.standalone) {
                    void this.applySubstitutes(false);
                } else {
                    this.buildWfUserList();
                }
            } catch (_) {
                this.field.api.focus();
            }
        }
    }

    async applySubstitutes(isRemove?: boolean): Promise<void> {
        if(!this.newSubstitutes) {
            return;
        }
        if (this.clientService.isOffline()) {
            this.notificationsService.info(this.translateFn("eob.message.offline.function.disabled"));
            return;
        }

        try {
            if(!this.standalone) {
                this.buildWfUserList();
                this.cd.detectChanges();
            }
            await this.httpService.legacyPost("/workflows/substitutes", this.newSubstitutes);
            if (!isRemove && this.standalone) {
                this.getSubstitutes();
            }
            this.notificationsService.success(this.translateFn("eob.app.bar.usermenu.workflow.report.substitutes.new"));
        } catch (err) {
            console.warn(err);
            this.notificationsService.error(this.translateFn("eob.app.bar.usermenu.workflow.report.substitutes.error.update"));
        }
    }

    async persistNewSubstitutes(): Promise<void> {
        if (!this.newSubstitutes) {
            return;
        }
        try {
            await this.httpService.legacyPost("/workflows/substitutes", this.newSubstitutes);
            this.notificationsService.success(this.translateFn("eob.app.bar.usermenu.workflow.report.substitutes.new"));
        } catch (_) {
            this.notificationsService.error(this.translateFn("eob.app.bar.usermenu.workflow.report.substitutes.error.update"));
        }
    }
}
