import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {AgGridService} from "MODULES_PATH/grid/services/ag-grid.service";
import { TodoEnvironmentService } from "INTERFACES_PATH/any.types";

@Component({
    selector: "eob-multiselect-boxes",
    templateUrl: "./eob-multiselect-boxes.component.html",
    styleUrls: ["./eob-multiselect-boxes.component.scss"]
})
export class EobMultiselectBoxesComponent<T extends any> implements OnInit, OnChanges {
    @Input() content: T[];
    @Input("leftconfig") leftConfig: any;
    @Input("rightconfig") rightConfig: any;
    @Input() options: any = {};

    @Output("selectionchangeevent") selectionChangeEvent: EventEmitter<T[]> = new EventEmitter<T[]>();

    leftGrid: any = {options: undefined, filter: ""};
    rightGrid: any = {options: undefined, filter: ""};
    isPhone = false;
    showAltLang: boolean;
    objectDefLang: any;

    constructor(@Inject("environmentService") protected environmentService: TodoEnvironmentService,
                private agGridService: AgGridService,
                private clientService: ClientService) {
    }

    ngOnInit(): void {
        const {leftContent, rightContent}: { leftContent: T[]; rightContent: T[] } = this.initData();

        this.rightConfig = this.rightConfig || Object.assign({}, this.leftConfig);
        this.isPhone = this.clientService.isPhone();

        this.initGrid(this.leftGrid, this.rightGrid, leftContent, this.leftConfig);
        this.initGrid(this.rightGrid, this.leftGrid, rightContent, this.rightConfig);

        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang();
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();
    }

    /** Reset the grids in case the content is changed from the outside. */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.content !== undefined && !changes.content.firstChange) {
            const {leftContent, rightContent}: { leftContent: T[]; rightContent: T[] } = this.initData();

            this.leftGrid.options.api.setRowData(leftContent);
            this.rightGrid.options.api.setRowData(rightContent);
        }
    }

    private initData(): { leftContent: T[]; rightContent: T[] } {
        const leftContent: T[] = [], rightContent: T[] = [];
        this.content.forEach((entry: any) => entry.selected ? rightContent.push(entry) : leftContent.push(entry));

        this.setSequence(leftContent);
        this.setSequence(rightContent);

        return {leftContent, rightContent: this.content.filter((entry: any) => entry.selected)};
    }

    private initGrid(grid: any, opposingGrid: any, data: T[] = [], config: any = {}): any {
        const defaultOptions: any = {
            columnDefs: [{headerName: "Name", field: "name"}],
            rowData: data,
            defaultColDef: {resizable: false, suppressMenu: true},
            suppressMovableColumns: true,
            suppressScrollOnNewData: true,
            onRowClicked: () => opposingGrid.options.api.deselectAll(), // deselect any possible selections in the other grid
            onRowDoubleClicked: (row) => {
                this.changeSelection(grid, opposingGrid, [row.node.data]);

                if (grid == this.leftGrid) {
                    AgGridService.selectLastRow(opposingGrid.options);
                }
            }
        };

        // initially select the first row in the left grid
        if (grid === this.leftGrid) {
            config.onGridReady = () => AgGridService.selectFirstRow(this.leftGrid.options);
        } else if (this.options.keepSequence) {
            config.defaultColDef = {sortable: false, suppressMenu: true};
        }

        grid.options = this.agGridService.getDefaultOptions(defaultOptions, config);
    }

    addAllFields(): void {
        this.changeSelection(this.leftGrid, this.rightGrid, AgGridService.getAllRows(this.leftGrid.options));
    }

    addFields(): void {
        const selectedNodes: T[] = this.leftGrid.options.api.getSelectedNodes();
        this.changeSelection(this.leftGrid, this.rightGrid, selectedNodes.map((node: any) => node.data));
        AgGridService.selectNextRow(this.leftGrid.options, selectedNodes);
    }

    removeAllFields(): void {
        this.changeSelection(this.rightGrid, this.leftGrid, AgGridService.getAllRows(this.rightGrid.options));
    }

    removeFields(): void {
        const selectedNodes: T[] = this.rightGrid.options.api.getSelectedNodes();
        this.changeSelection(this.rightGrid, this.leftGrid, selectedNodes.map((node: any) => node.data));
        AgGridService.selectNextRow(this.rightGrid.options, selectedNodes);
    }

    onFilterChange(gridKey: string, value: string): void {
        this[gridKey].filter = value.toLowerCase();
        this[gridKey].options.api.setQuickFilter(value);
    }

    moveUp(): void {
        AgGridService.moveSelectedUp(this.rightGrid.options);

        const selectedNodes: any = this.rightGrid.options.api.getSelectedNodes();
        this.setSequence(selectedNodes.map(row => row.data), selectedNodes);
    }

    moveDown(): void {
        AgGridService.moveSelectedDown(this.rightGrid.options);

        const selectedNodes: any = this.rightGrid.options.api.getSelectedNodes();
        this.setSequence(selectedNodes.map(row => row.data), selectedNodes);
    }

    private changeSelection(grid: any, opposingGrid: any, data: T[]): void {
        if (data == undefined || data.length == 0) {
            return;
        }

        // filter out any duplicates (FF doubleClick event bug)
        data = data.filter(entry => !this.isIncluded(entry, opposingGrid));

        // move rows to the other grid
        grid.options.api.applyTransaction({remove: data});
        const movedNodes: any[] = opposingGrid.options.api.applyTransaction({add: data}).add;

        data.forEach((entry: any, index) => {
            entry.selected = !entry.selected;
        });
        this.setSequence(data, movedNodes);

        this.selectionChangeEvent.emit(this.content.filter((entry: any) => entry.selected));
    }

    /*
     * Checks if entry is in grid data by comparing the property guid
     */
    private isIncluded(entry: any, inGrid: any) {
        // Check if guid property exists
        if (!((inGrid.options.rowData.length > 0) && inGrid.options.rowData[0].guid && entry.guid)) {
            return false;
        }

        return inGrid.options.rowData.filter(gridEntry => gridEntry.isInvisibleField && gridEntry.guid == entry.guid).length > 0;
    }

    private setSequence(data: T[], nodes?: any[]): void {
        if (this.options.keepSequence) {
            data.forEach((entry: any, index) => {
                if (entry.selected) {
                    this.content.splice(this.content.indexOf(entry), 1);

                    const newIndex: number = nodes == undefined ? entry.position : nodes[index].rowIndex;
                    this.content.splice(newIndex, 0, entry);
                } else {
                    this.content.splice(this.content.indexOf(entry), 1);
                    this.content.push(entry);
                }
            });
        }
    }
}
