/* eslint-disable max-params */
import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { MessageService } from "CORE_PATH/services/message/message.service";
import { TimerService } from "MODULES_PATH/timer/services/timer.service";
import { InlineDialogEvent } from "ENUMS_PATH/inline-dialog-event.enum";
import { ModalEvents } from "MODULES_PATH/modal-dialog/enums/modal.enum";
import { TimerDoneType } from "MODULES_PATH/timer/enums/timer-done-type";

@Component({
    selector: "eob-modal-progress-counter",
    templateUrl: "./eob-modal-progress-counter.component.html",
    styleUrls: ["./eob-modal-progress-counter.component.scss"]
})
export class EobModalProgressCounterComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() binding: any;

    constructor(@Inject("$rootScope") private $rootScope: ng.IRootScopeService, @Inject("modalDialogService") private modalDialogService: any,
                private messageService: MessageService, private timerService: TimerService,
                private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        console.info("import emails dialog shown");
        this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
        this.binding.closeProgressDialog = (): void => this.closeProgressDialog();
    }

    ngAfterViewInit(): void {
        const containerElement: any = document.getElementById("modalDialogContainer");
        if (containerElement) {
            containerElement.style.minHeight = "400px";
            this.cdRef.detectChanges();
        }
    }

    closeProgressDialog(): void {
        this.timerService.done$.next(TimerDoneType.PROGRESS_DIALOG_CANCEL);
        this.modalDialogService.hideProgressDialog(true);
        this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
        this.messageService.broadcast(ModalEvents.DESTROY);
    }

    ngOnDestroy(): void {
        this.closeProgressDialog();
    }
}
