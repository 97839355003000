/* eslint-disable @angular-eslint/no-input-rename,@angular-eslint/no-output-rename */
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ExternalTrayEntry} from "MODULES_PATH/external-tray/interfaces/external-tray-entry.interface";

@Component({
    selector: "eob-external-tray-elements",
    templateUrl: "./eob-external-tray-elements.component.html",
    styleUrls: ["./eob-external-tray-elements.component.scss"]
})
export class EobExternalTrayElementsComponent implements OnInit, OnChanges {
    // lower case alias names for the ajs world
    @Input("trayentries") trayEntries: ExternalTrayEntry[];
    @Input("preselectedid") preselectedId: string;

    @Output("changeselection") changeSelection: EventEmitter<ExternalTrayEntry> = new EventEmitter();

    trackByKey: (index: number, entry: ExternalTrayEntry) => string = (i, e) => e.groupKey;
    activeEntry: ExternalTrayEntry;

    constructor() {
    }

    ngOnInit(): void {
        if (this.trayEntries) {
            // possible preselected element from e.g. autosave data
            const preselectedElement: ExternalTrayEntry = this.preselectedId ? this.trayEntries.find(entry => entry.groupKey == this.preselectedId) : undefined;
            this.chooseTrayElement(preselectedElement || this.trayEntries[0]);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // list of external tray elements was updated and we need to restore/update the selection
        if (changes.trayEntries?.firstChange === false) {
            const selectionIndex: number = changes.trayEntries.previousValue.findIndex(e => e === this.activeEntry);
            const lastSelectedEntry: ExternalTrayEntry = changes.trayEntries.previousValue[selectionIndex];

            let selectEntry: ExternalTrayEntry = this.trayEntries.find(entry => entry.groupKey === lastSelectedEntry.groupKey);
            if (!selectEntry) {
                const newIndex: number = Math.max(0, selectionIndex - 1);
                selectEntry = this.trayEntries[newIndex];
            }

            this.chooseTrayElement(selectEntry);
        }
    }

    chooseTrayElement(trayElement: ExternalTrayEntry): void {
        if (this.activeEntry !== trayElement) {
            this.activeEntry = trayElement;
            this.changeSelection.emit(this.activeEntry);
        }
    }
}