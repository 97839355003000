<div class="container">
    <div class=" choose-boxes-form-row">
        <eob-multiselect-boxes [content]="content"
                               [leftconfig]="leftConfig"
                               [rightconfig]="rightConfig">
        </eob-multiselect-boxes>
    </div>

    <div class="button-container">
        <button class="secondary-button" (click)="cancel()">{{'modal.button.back' | ngTranslate}}</button>
        <button (click)="apply()">{{'modal.button.apply' | ngTranslate}}</button>
    </div>
</div>