import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {BackendBaseService} from "CORE_PATH/backend/services/base/backend-base.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Observable} from "rxjs";
import {Dms2SessionService} from "CORE_PATH/backend/modules/dms2/services/session/dms2-session.service";
import {OsRestSessionService} from "CORE_PATH/backend/modules/osrest/services/session/osrest-session.service";

@Injectable({providedIn: BackendModule})
export class BackendSessionService extends BackendBaseService<Dms2SessionService | OsRestSessionService>{

    constructor(messageService: MessageService, dms2SessionService: Dms2SessionService, osRestSessionService: OsRestSessionService) {
        super(messageService, dms2SessionService, osRestSessionService);
    }

    checkPassword(pwd: string): Observable<void> {
        return this.osRestService.checkPassword(pwd);
    }
}