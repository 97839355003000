<div
    class='radio-container'
    [ngClass]="{'disabled': control.disabled}"
    [attr.master-internal]='masterInternal'
    [attr.tabindex]="isMasterRadio ? '0' : '-1'"
    [attr.value]='radioIndex'
    [title]='tooltip'
    (click)="onClick()"
    #radioContainer>
    <div #radio
         role="radio"
         class='radio-button'
         [ngClass]="{'checked': checked}"
         [attr.aria-labelledby]="fieldid"
         [attr.aria-checked]="checked">
    </div>
    <label *ngIf="!hideLabel" class='radio-label' [attr.lang] = "langAttribute" [id]="fieldid">{{title}}</label>
</div>
