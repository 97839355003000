<div [attr.aria-label]="'eob.result.state.title' | ngTranslate" class="outer-scroller">
    <div class="pull-box" [hidden]="!isTouch" #pullBox>
        <div class="refresh-icon-container" #refreshIconContainer>
            <eob-icon size="24" name="refresh" *ngIf="!isRefreshed"></eob-icon>
            <eob-icon size="24" name="refresh-blue-24" *ngIf="isRefreshed"></eob-icon>
        </div>
    </div>
    <div class="inner-scroller" #innerScroller>
        <ng-content></ng-content>
    </div>
</div>


