<div id="modalDialog" (click)="closeInnerDialogsOnClick()" [ngClass] = "[input?.hideHeader ? 'status-dialog' : '', input?.containerClass || '']">
    <div id="overlay"></div>
    <div id="modalDialogContainer" role="region" [attr.aria-label]="input?.title" [ngClass]="{'fullscreen': input?.isFullscreen, 'zoom-correction': isPhone}">
        <div class="dialogHeader" *ngIf="!isPermanent">
            <eob-state-header [stateTitle]="input?.title" [description]="input?.description" [isMockContext]="isMockContext" class="modal-header">
                <ng-content select="#header"></ng-content>
            </eob-state-header>
        </div>
        <div class="dialogContent" [ngClass]="{'headlessDialog': hideHeader}">
            <ng-content></ng-content>
        </div>
    </div>
</div>
