<div class="container">
    <div class="dashlet-enaio-logo">
        <img alt="Enaio Logo" src = "assets/enaio-by-os.svg">
    </div>

    <div class="os-website-links">
        <a role="img" aria-label="Website Icon" class="action website first" target="_blank" href="https://www.optimal-systems.de/{{language === 'de' ? '' : language}}"><span>Website</span></a>
        <a role="img" aria-label="Blog Icon" class="action blog" target="_blank" href="https://www.optimal-systems.de/{{language === 'de' ? '' : language + '/'}}blog/"><span>Blog</span></a>
        <a role="img" aria-label="Newsletter Icon" class="action newsletter" target="_blank" href="https://www.optimal-systems.de/{{language === 'de' ? '' : language + '/'}}newsletter"><span>Newsletter</span></a>
    </div>

    <div class="smc">
        <a role="img" aria-label="Twitter Icon" class="sm twitter" target="_blank" href="https://twitter.com/OptimalSystems"></a>
        <a role="img" aria-label="Facebook Icon" class="sm facebook" target="_blank" href="http://www.facebook.com/OptimalSystems"></a>
        <a role="img" aria-label="Instagram Icon" class="sm instagram" target="_blank" href="https://www.instagram.com/optimalsystems"></a>
        <a role="img" aria-label="YouTube Icon" class="sm youtube" target="_blank" href="http://www.youtube.com/user/OptimalSystems"></a>
        <a role="img" aria-label="Xing Icon" class="sm xing" target="_blank" href="https://www.xing.com/xbp/pages/optimal-systems-gmbh-2"></a>
        <a role="img" aria-label="Linkedin Icon" class="sm linkedin" target="_blank" href="https://www.linkedin.com/company/optimal-systems-gmbh"></a>
    </div>
</div>