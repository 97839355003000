/**
 * Message types for dms messages.
 */
export enum DmsMessageType {
    DMSOBJECT_DELETED = "dms-dmsobject-deleted",

    LOCATION_REMOVED = "dms-location-removed",
    LOCATION_CREATED = "dms-location-created",

    ADD_TO_FAVORITE = "add-to-favorite",

    ROOT_REMOVED = "dms-root-removed"
}