angular.module("eob.core").factory("formToolsService", FormToolsService);

FormToolsService.$inject = [];

export default function FormToolsService() {

    return {
        progressWrapper,
        getScriptName,
        defineFunction
    };

    /**
     * Hide any element with the class "state-content" and show the given progressbar instead
     * for as long as the given submit-function is not resolved or rejected.
     *
     * @param {Function} submitFn - A submit-function that returns a promise.
     * @param {boolean=false} shouldHideProgressBar - Whether the progress bar should be hidden after submitFn has been resolved
     * @returns {Function} - The wrapped submit-function.
     */
    function progressWrapper(submitFn, helperConfig, shouldHideProgressBar = false) {
        return (formHelper, closeWorkflowMask = true) => {
            let progressbar = helperConfig.progressbar;

            if (progressbar != void 0) {
                angular.element(progressbar._osProgressbarElement).css({ left: 0, right: 0, top: 0, bottom: 0, position: "absolute", "z-index": 1000 });
                progressbar.show();

                const functionPromise = submitFn(formHelper, closeWorkflowMask);

                if (shouldHideProgressBar || !closeWorkflowMask) {
                    functionPromise.then(() => {
                        progressbar.hide();
                    })
                }

                functionPromise.catch((error) => {
                    console.error(error);
                    progressbar.hide();
                });

                return functionPromise
            } else {
                return submitFn(formHelper, closeWorkflowMask);
            }
        };
    }

    /**
     * Create a script name from the given data.
     *
     * @param {boolean} isWorkflow - An objectTypeId will be added to the name for none-workflow scripts.
     * @param {{name:string, objectTypeId:number}} config - Additional information.
     * @param {object=} field - The field, the script is bound to.
     * @param {object} scriptObj - The script object.
     * @returns {string} The name.
     */
    function getScriptName(isWorkflow, config, field, scriptObj) {
        let name;
        name = `${config.name}-`;
        name += isWorkflow ? "" : `${config.objectTypeId}-`;
        name += field == void 0 ? scriptObj.eventType : `${field.model.name}-${scriptObj.eventType}`;

        if (scriptObj.eventType == "GLOBAL_WEBCLIENT") {
            name = scriptObj.eventType;
        } else if (scriptObj.eventType == "GLOBAL_WEBCLIENT_OBJECT_TYPE") {
            name = `${config.name}-${config.objectTypeId}-${scriptObj.eventType}`;
        }

        return name.replace(/\s+/g, "-");
    }

    /**
     * Defines a javascript function. Accepts an array of arguments
     *
     * @param {string[]} args - The names of the function arguments.
     * @param {object} script - A script object.
     * @param {string} name - The script name to use.
     * @returns {Function} The script function, ready to be executed.
     */
    function defineFunction(args, script, name) {
        // Define form script the function
        // Appending source url as comment. See https://developer.mozilla.org/en-US/docs/Tools/Debugger/How_to/Debug_eval_sources
        // Enables debugging using browser debugging tools (Chrome+Firefox)
        // The line feed is needed, if the script has a comment in the last line
        // In addition, the source URL is treated like a file name and has to be sanitized to be visible inside DevTools.
        return new Function(args, `${script}\n;//# sourceURL=${name.replace(/([\\/:*?"<>|#]|&lt;|&gt;)/g, "-")}.js`);
    }
}
