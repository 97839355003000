import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    PipeTransform,
    OnDestroy,
    ViewChild,
    ElementRef,
    Inject
} from "@angular/core";
import {EobInputFormControl} from "MODULES_PATH/form/components/inputs/eob-input-form-control.model";
import {Subscription, EMPTY, merge} from "rxjs";
import {AutoCompleteConfig} from "MODULES_PATH/autocomplete/interfaces/autocomplete.interface";
import {StandaloneValidationBubbleComponent} from "MODULES_PATH/form/components/standalone-validation-bubble/standalone-validation-bubble.component";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {ChangeDetectorRef} from "@angular/core";
import {ValidationErrorMapperService} from "MODULES_PATH/form/services/validation-error-mapper.service";
import {TodoFormHelper} from "INTERFACES_PATH/any.types";
import {ValidationErrors} from "@angular/forms";
import {FormField} from "MODULES_PATH/form/interfaces/form.interface";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {DisplayBubbleDirective} from "MODULES_PATH/form/directives/display-bubble.directive";

@Component({
    selector: "eob-input",
    templateUrl: "./input.component.html",
    styleUrls: ["./input.component.scss"]
})
export class InputComponent implements OnInit, OnDestroy {
    @Input() field: FormField;
    @Input() formhelper: TodoFormHelper;
    @Input() ismockform: boolean;
    @Input() hasAddon: boolean;
    @Input() showPlaceholder?: boolean = true;
    @Input() fieldid: string;
    @Input() control: EobInputFormControl;
    @Input() customBubble: StandaloneValidationBubbleComponent;
    @Input() autocomplete: AutoCompleteConfig;
    @Input() disabled: boolean;

    @Output() triggerKeydown: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() triggerClick: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() triggerInput: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() isFocused: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(DisplayBubbleDirective, {static: false}) displayBubble: DisplayBubbleDirective;
    @ViewChild("input", {static: false}) input: ElementRef<HTMLInputElement>;

    private inputStatus: string = "";
    private translateFn: TranslateFnType;
    private subs: Subscription = new Subscription();

    constructor(@Inject("$filter") $filter: ng.IFilterService,
                private validationErrorMapperService: ValidationErrorMapperService,
                private cdRef: ChangeDetectorRef,
                private messageService: MessageService) {
        this.translateFn = $filter("translate");
    }

    ngOnInit(): void {
        this.subs.add(merge(this.control?.valueChanges ?? EMPTY, this.control?.statusChanges ?? EMPTY).pipe(debounceTime(50), distinctUntilChanged()).subscribe(() => {
            this.inputStatus = this.control.status;
            if(this.inputStatus == "INVALID") {
                const validationErrorMessage: ValidationErrors = this.validationErrorMapperService.getValidationErrorMessage(this.control.errors);
                this.displayBubble.setValidationMessage(validationErrorMessage.title, validationErrorMessage.message);
            } else {
                this.displayBubble.hide();
            }

            // Required if form control is invalidated externally, e.g. by form submission
            this.cdRef.detectChanges();
        }));
        this.subs.add(this.messageService.subscribe(Broadcasts.DETECT_CHANGES, () => {
            this.cdRef.detectChanges();
        }));
        this.addPipes();
    }

    private addPipes(): void {
        const pipes: PipeTransform[] = this.control.eobOptions?.pipes;
        if (pipes) {
            this.subs.add(this.control.valueChanges.subscribe(value => {
                let newValue: string = value;
                pipes.forEach(pipe => newValue = pipe.transform(value));
                if (value !== newValue) {
                    this.control.setValue(newValue, { emitEvent: false, emitViewToModelChange: false });
                }
            }));
        }
    }

    clickHandler = (event: MouseEvent): void => {
        this.triggerClick.emit(event);
    };

    keyDownHandler(event: KeyboardEvent): void {
        // if autocomplete is open, this event can be ignored here
        const willChangeAutocompleteSelection: boolean = ["ArrowDown", "ArrowUp"].includes(event.code) && this.autocomplete?.autoCompleteOpen;
        const willSelectAutocompleteEntry: boolean = event.key == "Enter" && this.autocomplete?.isSelectingValue;
        if (willChangeAutocompleteSelection || willSelectAutocompleteEntry) {
            return;
        }

        this.triggerKeydown.emit(event);
    }

    inputHandler(): void {
        this.triggerInput.emit();
    }

    onFocus(): void {
        this.isFocused.emit(true);
    }

    onBlur(): void {
        this.isFocused.emit(false);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
