import {from, Subject, Subscription} from "rxjs";
import Dexie from "dexie";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {FileCacheService, PersistenceError} from "../mobile-desktop/eob.file.cache.srv";
import {OfflineDataStore} from "MODELS_PATH/eob.offline.data.model";
import {DmsDocumentModel} from "MODULES_PATH/dms/models/dms-document-model";
import {ContentObject} from "SHARED_PATH/models/eob.content.object";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {DmsContentStore} from "INTERFACES_PATH/dms-content-store.interface";
import {DmsDocumentStore} from "INTERFACES_PATH/dms-document-store.interface";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {ProfileCacheKey} from "ENUMS_PATH/database/profile-cache-key.enum";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";
import {OverallSyncStatus} from "ENUMS_PATH/offline/offline-global-sync-status.enum";
import {ObjectSyncStatus} from "ENUMS_PATH/offline/offline-object-sync-status.enum";
import {ContentType} from "ENUMS_PATH/content-type.enum";
import {TimeoutConfigHolder} from "INTERFACES_PATH/timeout-config-holder.interface";
import {UpdateUtilService} from "SERVICES_PATH/utils/update-utils.srv";
import {Profile, ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {OfflineSyncStatus} from "./offline.sync.status.model";
import {ConnectionWatcher} from "SHARED_PATH/models/connection-watcher";
import {ThrottleQueueSubject} from "SHARED_PATH/models/throttle-queue-subject.model";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Connection} from "ENUMS_PATH/connection.enum";
import {first, takeUntil} from "rxjs/operators";
import {ObjectSyncFailedStatus} from "ENUMS_PATH/offline/offline-object-sync-failed-status.enum";
import {AttachmentObject} from "INTERFACES_PATH/attachement-object.interface";
import {ProcessedRenditionInfoData} from "INTERFACES_PATH/rendition-info-data.interfaces";
import {TodoAsIniService, TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {QueryBuilderService} from "CORE_PATH/services/search/query-builder.service";
import {BackendSearchIdRequest} from "CORE_PATH/backend/interfaces/search-requests/backend-search-id-request";
import {OfflineLocationCacheService} from "SERVICES_PATH/offline/eob.offline.location.cache.srv";

/** Provides functionality for offline synchronization of favorites. And makes the cached Favorites accessible. */
export class OfflineService {
    private readonly translateFn: TranslateFnType;
    private readonly ID_QUERY_LIMIT: number = 500;
    private readonly QUEUE_AMOUNT: number = 4;

    private readonly syncStatus: OfflineSyncStatus;
    private abortSubject: Subject<any> = new Subject<any>();
    private connectionWatcher: ConnectionWatcher = new ConnectionWatcher(this.clientService);

    private doAutoSync: boolean = true;

    private dmsDocumentsCache: Dexie.Table<DmsDocumentStore, string>;
    private offlineDataCache: Dexie.Table<OfflineDataStore, string>;
    private dmsContentCache: Dexie.Table<DmsContentStore, string>;

    private offlineData: OfflineDataStore[]; // temporary global offlineData array for the synchronization

    static $inject: string[] = ["$rootScope", "$injector", "$filter", "backendService", "fileCacheService", "clientService", "errorModelService",
        "notificationsService", "asIniService", "environmentService", "offlineCacheService", "offlineLocationCacheService", "dmsContentService", "messageService", "searchService",
        "updateUtilService", "profileService", "queryBuilderService"];

    // eslint-disable-next-line max-params
    constructor(private $rootScope: RootScope, private $injector: ng.auto.IInjectorService, $filter: ng.IFilterService,
                private backendService: any, private fileCacheService: FileCacheService, private clientService: ClientService, private errorModelService: any,
                private notificationsService: any, private asIniService: any, private environmentService: TodoEnvironmentService, private offlineCacheService: OfflineCacheService,
                private offlineLocationCacheService: OfflineLocationCacheService, private dmsContentService: any, private messageService: MessageService, private searchService: any,
                private updateUtilService: UpdateUtilService, private profileService: ProfileService, private queryBuilderService: QueryBuilderService) {
        this.translateFn = $filter("translate");
        this.syncStatus = new OfflineSyncStatus(this.messageService, this.offlineCacheService, this.errorModelService, this.fileCacheService);

        this.clientService.registerConnectivityChangeHandler(connectivity => this.onConnectivityChange(connectivity));

        // make sure the sync is set to false in the global main thread, when the tab is refreshed
        window.addEventListener("unload", () => this.syncStatus.isSyncRunning() && this.clientService.setIsSynchronizing(false));
    }

    init(): void {
        this.offlineCacheService.init();
        this.offlineLocationCacheService.init();
        this.dmsDocumentsCache = this.fileCacheService.getDmsDocumentsTable();
        this.offlineDataCache = this.fileCacheService.getOfflineDataTable();
        this.dmsContentCache = this.fileCacheService.getDmsContentTable();
    }

    /**
     * Compare cached data with new favorites and update the sync states accordingly.
     * The comparison is done by existence, modification date and cached sync state.
     * Query and cache indexdata, permissions and content per dms object and scripts per object types.
     */
    async synchronizeAsync(isAutoSync: boolean = false): Promise<void> {
        // const start: number = new Date().getTime();
        if (isAutoSync && !this.doAutoSync) {
            return;
        }
        this.doAutoSync = false;

        if (!this.isSynchronizationEnabled()) {
            return;
        }

        try {
            this.clientService.setIsSynchronizing(true);
            // initialize a global timeout config to abort all running http requests
            this.abortSubject.complete();
            this.abortSubject = new Subject<void>();

            if (await this.clientService.getIsCacheLocked()) {
                this.syncStatus.setProgressState(0, OverallSyncStatus.CLEAR_CACHE);
                await new Promise((resolve, reject) => {
                    const subscription: Subscription = this.clientService.cacheLockedSubject.pipe(first()).subscribe(resolve);
                    this.abortSubject.subscribe(undefined, undefined, () => {
                        subscription.unsubscribe(); reject();
                    });
                });
            }

            this.syncStatus.start();
            this.connectionWatcher.start();

            const config: TimeoutConfigHolder = this.backendService.getCancelableHttpConfig();
            const backendOfflineObjectsTree: any[] = await this.abortable(this.getBackendOfflineObjects(config, this.syncStatus), config);
            if (backendOfflineObjectsTree.length > 0) {
                this.syncStatus.nextPhase();
                await this.updateOfflineData(backendOfflineObjectsTree);

                this.syncStatus.nextPhase();
                await this.updateIndexdata();

                this.syncStatus.nextPhase();
                await this.updatePermissions();

                this.syncStatus.nextPhase();
                await this.updateContent();

                this.syncStatus.nextPhase();
                await this.updateDmsScriptsAsync();

                this.syncStatus.nextPhase();
                await this.updateGlobalDmsScriptsAsync();
            } else {
                await this.clearCache();
            }

            this.setLastSynchronizationTime();
            this.syncStatus.finish(OverallSyncStatus.FINISHED);

            const oneOrMoreFailed: boolean = (await this.offlineCacheService.getFailedSyncObjectsCount()) > 0;
            if (oneOrMoreFailed) {
                this.notificationsService.warning(this.translateFn("eob.sync.offline.finished.warn"));
            } else if (backendOfflineObjectsTree.length > 0) {
                this.notificationsService.success(this.translateFn("eob.sync.offline.finished.success"));
            } else if (!isAutoSync) {
                this.notificationsService.info(this.translateFn("eob.sync.offline.finished.noop"));
            }
        } catch (error) {
            let msgKey: string, finishState: OverallSyncStatus;
            switch(this.syncStatus.status) {
                case OverallSyncStatus.ABORTING_USER: // abort was triggered - timeout error, checkForAbort error
                    msgKey = "eob.sync.offline.aborted";
                    finishState = OverallSyncStatus.ABORTED;
                    break;
                case OverallSyncStatus.ABORTING_OFFLINE: // connection lost
                    msgKey = "eob.sync.offline.connection.lost";
                    finishState = OverallSyncStatus.ABORTED;
                    break;
                case OverallSyncStatus.ABORTING_QUOTA: // quota exceeded
                    msgKey = "eob.sync.offline.aborted.quota";
                    finishState = OverallSyncStatus.ABORTED;
                    break;
                default: // any other kind of error
                    msgKey = "eob.sync.offline.error";
                    finishState = OverallSyncStatus.FAILED;
                    console.error(error);
            }

            this.notificationsService.info(this.translateFn(msgKey));
            this.syncStatus.finish(finishState);
        } finally {
            this.clientService.setIsSynchronizing(false);
            this.clearDownloads();
        }

        // console.debug(`the synchronization took: ${new Date().getTime() - start}`);
    }

    private isSynchronizationEnabled(): boolean {
        // TODO perhaps decide using something else
        if(sessionStorage.getItem("afterFirstLogin") != "true") {
            return false;
        }
        if (this.clientService.isOffline()) {
            return false;
        }
        // automatic sync is not set --> do nothing
        if (!this.asIniService.isSynchronizeFavoritesOffline()) {
            return false;
        }
        // no sync if user has disabled the use of mobile data and is using mobile network
        if (!this.asIniService.isUseMobileDataForSync() && this.clientService.getConnectionType() !== Connection.WIFI) {
            return false;
        }
        // No synchronization if the user can't see favourites anyway
        if (!this.environmentService.userHasRole("R_CLNT_SHOW_MOBFAV")) {
            return false;
        }
        if (!this.clientService.isLocalClient()) {
            console.info("offline synchronization is only enabled for local clients");
            throw this.errorModelService.createCustomError("WEB_NOT_IMPLEMENTED");
        }
        return this.syncStatus.status !== OverallSyncStatus.RUNNING;
    }

    // region update
    /**
     * Compare local and backend offline objects, update the offline data accordingly and cache it.
     *
     * @param backendOfflineObjectsTree - A complete hierarchical representation of the offline objects of the user from the backend.
     */
    private async updateOfflineData(backendOfflineObjectsTree: any[]): Promise<void> {
        const backendOfflineObjects: Map<string, any> = this.prepareOfflineObjects(backendOfflineObjectsTree, -1, new Map<string, any>());
        this.offlineData = await this.offlineDataCache.toArray();

        const changedOfflineData: OfflineDataStore[] = [],
            removableIds: string[] = [],
            changedProgressData: OfflineDataStore[] = [];

        console.debug(`number of offline objects is ${backendOfflineObjects.size}`);

        for (let i: number = this.offlineData.length-1; i >= 0; i--) {
            const localOfflineObject: OfflineDataStore = this.offlineData[i];
            const backendOfflineObject: any = backendOfflineObjects.get(localOfflineObject.osid);

            // the cache offline object was removed
            if (backendOfflineObject === undefined) {
                removableIds.push(localOfflineObject.osid);
                this.offlineData.splice(i, 1);
                continue;
            }

            let objectChanged: boolean = false;
            // the local offline data is not already on new or update, but the lastmodified timestamp implies that we need to get everything from scratch
            if (!localOfflineObject.needsUpdate() && backendOfflineObject.lastmodified > localOfflineObject.lastmodified) {
                if (localOfflineObject.sync != ObjectSyncStatus.UPDATE_HALF) {
                    changedProgressData.push(localOfflineObject);
                }

                localOfflineObject.sync = ObjectSyncStatus.UPDATE;
                localOfflineObject.failedState = ObjectSyncFailedStatus.NONE;
                objectChanged = true;
                // the last synchronization of the content failed, so try to sync (only) the content again
            } else if (localOfflineObject.isSyncFailed()) {
                localOfflineObject.sync = ObjectSyncStatus.UPDATE_HALF;
                changedProgressData.push(localOfflineObject);
                objectChanged = true;
            }

            // update the offline data in case it changed
            if (localOfflineObject.update(backendOfflineObject) || objectChanged) {
                changedOfflineData.push(localOfflineObject);
            }

            backendOfflineObjects.delete(localOfflineObject.osid);
        }

        // all remaining backend offline objects are new
        for (const backendOfflineObject of backendOfflineObjects.values()) {
            const newOfflineData: OfflineDataStore = new OfflineDataStore(backendOfflineObject, ObjectSyncStatus.NEW);
            changedOfflineData.push(newOfflineData);
            this.offlineData.push(newOfflineData);
        }

        const removeProgressStep: number = this.syncStatus.getProgressStepPart(removableIds.length / (removableIds.length + changedOfflineData.length), removableIds.length/this.offlineCacheService.MAX_PAGES);
        await this.offlineCacheService.removeOfflineObjectsDirectly(removableIds, () => this.syncStatus.addProgress(removeProgressStep));

        const cacheProgressStep: number = this.syncStatus.getProgressStepPart(changedOfflineData.length / (removableIds.length + changedOfflineData.length), changedOfflineData.length/this.offlineCacheService.MAX_PAGES);
        await this.offlineCacheService.cacheOfflineData(changedOfflineData, () => this.syncStatus.addProgress(cacheProgressStep));

        await this.syncStatus.updateCacheProgresses(changedProgressData);
    }

    /** Update the index data cache depending on the sync state of the offline data cache. */
    private async updateIndexdata(): Promise<void> {
        const outdatedOfflineData: OfflineDataStore[] = this.offlineData.filter(data => OfflineDataStore.UPDATE_STATES.includes(data.sync));

        if (outdatedOfflineData.length > 0) {
            const split: BackendSearchIdRequest[] = this.queryBuilderService.createSplitQueryIdMap(outdatedOfflineData, this.ID_QUERY_LIMIT);
            const progressStep: number = this.syncStatus.getProgressStep(split.length);

            for (const payload of split) {
                const timeoutConfig: TimeoutConfigHolder = this.backendService.getCancelableHttpConfig();
                const loadedOfflineObjects: any = await this.abortable(this.searchService.executeQuerySearchByIdsAsync(
                    payload, {
                        baseparams: true,
                        fieldsschema: "ALL",
                        fileinfo: "true",
                        status: "true",
                        rights: "false"
                    }, timeoutConfig), timeoutConfig);

                const loadedDocModels: Map<string, DmsDocumentModel> = new Map<string, DmsDocumentModel>();
                for (const loadedOfflineObject of loadedOfflineObjects) {
                    loadedDocModels.set(loadedOfflineObject.osid, new DmsDocumentModel(loadedOfflineObject, this.$injector));
                }

                await this.offlineCacheService.cacheDmsDocuments(loadedDocModels);
                this.syncStatus.addProgress(progressStep);
            }
        }
    }

    /**
     * Gather the permissions for all offline objects, whether old or new.
     * Update the cached permissions.
     */
    private async updatePermissions(): Promise<any> {
        if (Object.keys(this.offlineData).length == 0) {
            return;
        }

        const permissions: Map<string, any> = new Map();
        const splitQueries: BackendSearchIdRequest[] = this.queryBuilderService.createSplitQueryIdMap(this.offlineData, this.ID_QUERY_LIMIT);

        const backendProgressStep: number = this.syncStatus.getProgressStepPart(0.5, splitQueries.length);
        await new ThrottleQueueSubject<any>(async (payload: any) => {
            const result: any = (await this.backendService.post("/documents/getpermissions?rights=RWXDU", payload)).data;
            Object.entries(result).forEach(entry => permissions.set(entry[0], entry[1]));
            this.syncStatus.addProgress(backendProgressStep);
        }, () => this.connectionWatcher.calcPortionByConnectivity(), undefined, this.QUEUE_AMOUNT).awaitAll(splitQueries, this.abortSubject);

        const changedOfflineData: OfflineDataStore[] = this.offlineData.filter(offlineData => {
            const oldValue: any = offlineData.rights || {},
                newValue: any = permissions.get(offlineData.osid.toString());

            let changed: boolean = false;
            if ((Object.keys(oldValue).length != Object.keys(newValue).length)
                || !Object.keys(oldValue).every(key => oldValue[key] == newValue[key])) {
                offlineData.rights = newValue;
                changed = true;
            }

            if (offlineData.needsUpdate()) {
                offlineData.sync = ObjectSyncStatus.UPDATE_HALF;
                changed = true;
            }

            return changed;
        });

        const progressStep: number = this.syncStatus.getProgressStepPart(0.5, changedOfflineData.length / this.offlineCacheService.MAX_PAGES);
        await this.offlineCacheService.cacheOfflineData(changedOfflineData, () => this.syncStatus.addProgress(progressStep));
    }

    /**
     * Loads and stores all DMS scripts. Since scripts are assigned to object types the object types of the
     * currently stored offline objects are taken.
     */
    private async updateDmsScriptsAsync(): Promise<void> {
        const dmsScripts: any = {};

        const objectTypeIds: any[] = [...new Set(this.offlineData.map(item => item.objectTypeId))]; // distinct objectTypeIds
        const progressStep: number = this.syncStatus.getProgressStep(objectTypeIds.length);

        await new ThrottleQueueSubject<any>(async (objectTypeId) => {
            const timeoutConfig: TimeoutConfigHolder = this.backendService.getCancelableHttpConfig();
            const response: any = await this.abortable(this.backendService.get(`/documents/scripts?objecttypeid=${objectTypeId}&clienttype=web`, undefined, timeoutConfig), timeoutConfig);

            dmsScripts[objectTypeId] = {
                data: response.data
            };
            this.syncStatus.addProgress(progressStep);
        }, () => this.connectionWatcher.calcPortionByConnectivity(), undefined, this.QUEUE_AMOUNT).awaitAll(objectTypeIds, this.abortSubject);

        console.info(`number of synchronized DMS scripts is ${Object.keys(dmsScripts).length}`);

        await this.fileCacheService.storeContentAsync(
            DatabaseEntryType.GLOBAL,
            dmsScripts,
            ProfileCacheKey.DMS_OBJECT_TYPE_SCRIPT_CACHE,
            ProfileCacheKey.OFFLINE_SCRIPT_CACHE_GROUP);
    }

    /** Loads and stores the global DMS scripts. */
    private async updateGlobalDmsScriptsAsync(): Promise<void> {
        console.info("start updateGlobalDmsScripts");

        const timeoutConfig: TimeoutConfigHolder = this.backendService.getCancelableHttpConfig();
        const response: any = await this.abortable(this.backendService.get("/documents/scripts?clienttype=web&eventtype=GLOBAL_WEBCLIENT", undefined, timeoutConfig), timeoutConfig);
        const globalDmsScripts: any = {data: response.data};

        await this.fileCacheService.storeContentAsync(
            DatabaseEntryType.GLOBAL,
            globalDmsScripts,
            GlobalCacheKey.DMS_GLOBAL_SCRIPT_CACHE,
            ProfileCacheKey.OFFLINE_SCRIPT_CACHE_GROUP);

        console.info("synchronization of global DMS scripts done");
    }

    // region update content
    /** Check which cache content needs to be updated and update/remove them accordingly. */
    private async updateContent(): Promise<void> {
        const userId: number = this.environmentService.getSessionInfo().userid;

        const cachedContentIds: string[] = await this.dmsContentCache.toCollection().primaryKeys(),
            userContentIds: string[] = await this.dmsContentCache.where("userReferences").equals(userId).primaryKeys(),
            hasContentIds: string[] = await this.dmsDocumentsCache.where("indexdata.hasContent").equals(1).primaryKeys();

        const queuedOsids: string[] = [],
            bulkOfflineData: OfflineDataStore[] = [];
        for (const data of this.offlineData) {
            const hasContent: boolean = hasContentIds.includes(data.osid),
                needsContent: boolean = hasContent && data.rights.objExport;

            if (needsContent) {
                // Always request new content for all offline objects set to new or update.
                // Otherwise only request the content, if no content is cached, yet.
                if (data.needsUpdate() || data.sync == ObjectSyncStatus.UPDATE_HALF || !cachedContentIds.includes(data.osid)) {
                    if (data.sync != ObjectSyncStatus.UPDATE_HALF) {
                        bulkOfflineData.push(data);
                        data.sync = ObjectSyncStatus.UPDATE_HALF;
                    }

                    queuedOsids.push(data.osid);
                } else {
                    // The cache data is already complete and can be marked as done.
                    if (data.sync !== ObjectSyncStatus.DONE) {
                        bulkOfflineData.push(data);
                        data.setDone();
                    }

                    // If necessary add the user reference.
                    if (!userContentIds.includes(data.osid)) {
                        await this.offlineCacheService.addGlobalReference(this.dmsContentCache, data.osid);
                    }
                }
            } else {
                // existing content needs to be deleted
                if (userContentIds.includes(data.osid)) {
                    await this.offlineCacheService.removeGlobalReferences(this.dmsContentCache, [data.osid]);
                }

                if (data.sync !== ObjectSyncStatus.DONE) {
                    bulkOfflineData.push(data);
                    data.setDone();
                }
            }
        }

        // update sync states
        const cacheProgressStep: number = this.syncStatus.getProgressStep(bulkOfflineData.length / this.offlineCacheService.MAX_PAGES);
        await this.offlineCacheService.cacheOfflineData(bulkOfflineData, () => this.syncStatus.addProgress(cacheProgressStep));

        this.syncStatus.nextPhase();
        await this.syncStatus.updateCacheProgresses(bulkOfflineData);
        await this.syncStatus.setProgressByCache();

        // queue and download the content
        this.connectionWatcher.start();
        const progressStep: number = this.syncStatus.getProgressStep(queuedOsids.length);

        await new ThrottleQueueSubject<any>(
            value => this.queueUpdateContent(value, progressStep),
            value => value.model.fileProperties.fileSize ? this.connectionWatcher.calcPortionByFilesize(value.model.fileProperties.fileSize) : 1,
            initValue => this.offlineCacheService.getById(initValue),
            this.QUEUE_AMOUNT).awaitAll(queuedOsids, this.abortSubject);
    }

    /** Retrieves and stores content (original and preview) for the given osid. */
    private async queueUpdateContent(dmsDocument: DmsDocument, progressStep: number): Promise<void> {
        const osid: string = dmsDocument.model.osid;
        const offlineData: OfflineDataStore = this.offlineData.find(data => data.osid == osid);
        let failed: boolean = false;

        try {
            const filename: string = dmsDocument.api.buildNameFromIndexData(3, false, false);

            let originalContent: ContentObject;
            if ([ObjectSyncFailedStatus.NONE, ObjectSyncFailedStatus.FAILED_ORIGINAL_CONTENT, ObjectSyncFailedStatus.FAILED_CONTENT_LIMIT].includes(offlineData.failedState)
                // 9.10 downward compatibility step - if the file extension isn't cached yet, we have to get the content again, to extract it from the response and provide it for the failed preview
                || (offlineData.failedState == ObjectSyncFailedStatus.FAILED_PREVIEW_CONTENT && ((await this.dmsContentCache.where("osid").equals(osid).first())?.original?.files[0]?.extension)) == undefined) {
                originalContent = await this.updateOriginalContent(osid, filename, offlineData, dmsDocument);
            }

            let renditionContent: ContentObject;
            if (originalContent || [ObjectSyncFailedStatus.FAILED_PREVIEW_CONTENT].includes(offlineData.failedState)) {
                renditionContent = await this.updatePDFRendition(osid, filename, offlineData, dmsDocument, originalContent);
            }

            if (renditionContent || [ObjectSyncFailedStatus.FAILED_EMAIL_ATTACHMENT].includes(offlineData.failedState)) {
                await this.updateAttachments(osid, offlineData, renditionContent);
            }

            offlineData.setDone();
            await this.offlineDataCache.update(osid, { sync: offlineData.sync, failedState: offlineData.failedState });
        } catch(error) {
            if (error.name == PersistenceError.QuotaExceeded) {
                this.stopSynchronization(OverallSyncStatus.ABORTING_QUOTA);
            }

            failed = true;
            // the update functions will take care of setting the offline object to failed
        }

        await this.syncStatus.addCacheProgress(offlineData, failed);
        this.syncStatus.addProgress(progressStep);
    }

    private async updateOriginalContent(osid: string, filename: string, offlineData: OfflineDataStore, dmsDocument: DmsDocument): Promise<ContentObject> {
        if (!this.asIniService.isSynchronizeOriginalContent()) {
            return;
        }

        try {
            // 9.10 Update, content was successfully taken from the former content cache
            if (await this.updateUtilService.updateSpecificContent(this.dmsContentCache, osid, ContentType.ORIGINAL, offlineData, dmsDocument)) {
                return;
            }

            // On iOS maximum file size is currently 5MB.
            if (this.clientService.isiOs() && dmsDocument.model.fileProperties.fileSize > 5242880) {
                throw this.errorModelService.createCustomError("WEB_SYNCHRONIZATION_FILESIZE_LIMIT");
            }

            const start: number = new Date().getTime();
            const timeoutConfig: any = this.backendService.getCancelableHttpConfig();
            const contentObject: ContentObject = await this.abortable(this.dmsContentService.getOriginalContent(osid, filename).pipe(takeUntil(from(timeoutConfig.config.timeout))).toPromise(), timeoutConfig);

            if (contentObject.files.length > 0) {
                this.connectionWatcher.addBandwidth(new Date().getTime() - start, dmsDocument.model.fileProperties.fileSize);

                await this.offlineCacheService.cacheContent(ContentType.ORIGINAL, osid, ContentObject.serialize(contentObject), dmsDocument.model.fileProperties);
            }
            return contentObject;
        } catch (error) {
            if (error.status !== -1 || !this.abortSubject.isStopped) {
                console.warn(`Unable to retrieve documentfiles information for ${osid}.`, error);
                offlineData.setFailed(error.type == "WEB_SYNCHRONIZATION_FILESIZE_LIMIT" ? ObjectSyncFailedStatus.FAILED_CONTENT_LIMIT : ObjectSyncFailedStatus.FAILED_ORIGINAL_CONTENT);
                await this.offlineDataCache.update(osid,{ sync: offlineData.sync, failedState: offlineData.failedState });
            }
            throw error;
        }
    }

    private async updatePDFRendition(osid: string, filename: string, offlineData: OfflineDataStore, dmsDocument: DmsDocument, originalContent?: ContentObject): Promise<ContentObject> {
        let contentObject: ContentObject;
        const extension: string = (originalContent || (await this.dmsContentCache.where("osid").equals(osid).first())?.original)?.files[0]?.extension;

        // the rendition of a PDF document equals the original content
        const isPdfDocument: boolean = dmsDocument.model.fileProperties.mimeTypeGroup === "PDF";

        // if given, extension must be suitable for rendition
        const isNotSuitableForRendition: boolean = extension && !this.dmsContentService.checkAvailableRenditionExtension(extension);

        // image types with multiple pages are stored as zip files, so an extra check is needed
        // on top of the extension check
        const isImageContainerWithMultiplePages: boolean = dmsDocument.model.fileProperties.mimeTypeGroup === "IMAGE" && dmsDocument.model.fileProperties.fileCount > 1;

        if (isPdfDocument || (isNotSuitableForRendition && !isImageContainerWithMultiplePages)) {
            return;
        }

        try {
            // 9.10 Update, content was successfully taken from the former content cache
            if (await this.updateUtilService.updateSpecificContent(this.dmsContentCache, osid, ContentType.RENDITION, offlineData)) {
                return;
            }

            const timeoutConfig: TimeoutConfigHolder = this.backendService.getCancelableHttpConfig();
            contentObject = await this.abortable(this.dmsContentService.getPDFRendition(osid, filename, timeoutConfig), timeoutConfig);
        } catch (error) {
            if (error.status !== -1 || !this.abortSubject.isStopped) {
                console.warn(`Unable to retrieve rendition for ${osid}`, error);
                offlineData.setFailed(ObjectSyncFailedStatus.FAILED_PREVIEW_CONTENT);
                await this.offlineDataCache.update(osid, { sync: offlineData.sync, failedState: offlineData.failedState });
            }
            throw error;
        }

        if (contentObject && contentObject.files.length > 0) {
            await this.offlineCacheService.cacheContent(ContentType.RENDITION, osid, ContentObject.serialize(contentObject));
        }

        return contentObject;
    }

    private async updateAttachments(osid: string, offlineData: OfflineDataStore, renditionContent?: ContentObject): Promise<void> {
        let getAttachmentFailed: boolean = false,
            attachments: AttachmentObject[];

        try {
            const renditionInfo: ProcessedRenditionInfoData = renditionContent?.info || (await this.dmsContentCache.where("osid").equals(osid).first())?.preview?.info;

            const timeoutConfig: TimeoutConfigHolder = this.backendService.getCancelableHttpConfig();
            attachments = await this.abortable(this.dmsContentService.getAttachmentsAsync(renditionInfo, osid, timeoutConfig), timeoutConfig);

            await this.dmsContentCache.where("osid").equals(osid).modify({ ["preview.attachments"]: attachments });
        } catch (error) {
            console.error(error);
            getAttachmentFailed = true;
        }

        // The attachments couldn't be loaded completely. Mark the offline object as failed, but still cache all the data we have.
        const incompleteAttachments: boolean = getAttachmentFailed || (attachments || []).find(attachment => attachment.info === null) !== undefined;
        if (incompleteAttachments) {
            offlineData.setFailed(ObjectSyncFailedStatus.FAILED_EMAIL_ATTACHMENT);
            await this.offlineDataCache.update(osid, { sync: offlineData.sync, failedState: offlineData.failedState });
            throw new Error(`Unable to retrieve all attachments for ${osid}.`);
        }
    }
    // endregion

    /** Clear the offline data cache for the current profile completely. */
    async clearCache(): Promise<void> {
        this.clientService.setIsCacheLocked(true);

        try {
            const profile: Profile = this.profileService.prepareCurrentProfile();
            this.updateUtilService.deleteProfileDatabase(profile);

            await this.offlineDataCache.clear();
            await this.fileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, ProfileCacheKey.DMS_OBJECT_TYPE_SCRIPT_CACHE, ProfileCacheKey.OFFLINE_SCRIPT_CACHE_GROUP);
            await this.offlineCacheService.removeAllGlobalOfflineObjects();

            console.info("offline cache cleared");
        } finally {
            localStorage.removeItem("lastSync");
            this.syncStatus.reset();
            this.clientService.setIsCacheLocked(false);
        }
    }
    // endregion

    // region abort
    /** Abort/stop the current synchronization. */
    stopSynchronization(syncStatus: OverallSyncStatus = OverallSyncStatus.ABORTING_USER): void {
        if ([OverallSyncStatus.RUNNING, OverallSyncStatus.CLEAR_CACHE].includes(this.syncStatus.status)) {
            this.syncStatus.setProgressState(undefined, syncStatus);
            // abort running downloads as soon as possible
            this.clearDownloads();
        }
    }

    /** Complete the download queue subjects to ensure automatic unsubscription. */
    private clearDownloads(): void {
        this.abortSubject.complete();
        this.connectionWatcher.end();
    }

    /** Abort the synchronization when the connection is lost. */
    private onConnectivityChange(connectivity: Connection): void {
        if (this.syncStatus.status !== OverallSyncStatus.RUNNING) {
            return;
        }

        if (connectivity === Connection.NONE || (this.asIniService.isUseMobileDataForSync() && connectivity !== Connection.WIFI)) {
            this.stopSynchronization(OverallSyncStatus.ABORTING_OFFLINE);
        }
    }
    // endregion

    // region cache progress
    isSyncRunning = () => this.syncStatus.isSyncRunning();

    getSyncStatus(): OfflineSyncStatus {
        return this.syncStatus;
    }

    /** Persist the current time in relation to the user id. */
    private setLastSynchronizationTime(): void {
        const userId: number = this.environmentService.getSessionInfo().userid;
        localStorage.setItem("lastSync", JSON.stringify({ userId, lastSync: new Date() }));
    }
    // endregion

    // region backend
    /** Gets the offline objects along with sub trees for folder and register from the backend. */
    async getBackendOfflineObjects(timeoutConfigHolder?: TimeoutConfigHolder, syncStatus?: OfflineSyncStatus): Promise<any[]> {
        const result: any = await this.backendService.get("/documents/favourites", undefined, timeoutConfigHolder.config);
        const offlineObjects: any[] = result.data.documents;

        // console.debug(`number of users remote offline favorites is ${offlineObjects.length}`);

        const containers: any[] = offlineObjects.filter(oo => oo.type !== "DOCUMENT"),
            progressStep: number = syncStatus?.getProgressStep(containers.length);

        await new ThrottleQueueSubject<any>(async (offlineObject) => {
            const tree: any = await this.backendService.get(`/documents/childrenHierarchy/${offlineObject.id}`, undefined, timeoutConfigHolder.config);

            // console.debug(`get children hierarchy for ${offlineObject.type} with id ${offlineObject.id}`);
            // console.debug(tree);

            offlineObject.children = tree.data;
            syncStatus?.addProgress(progressStep);
        }, () => this.connectionWatcher.calcPortionByConnectivity(), undefined, this.QUEUE_AMOUNT).awaitAll(containers, timeoutConfigHolder.subject);

        return offlineObjects;
    }

    /**
     * Turn the offline objects tree into a flat map and add favReferences accordingly.
     *
     * @param backendObjects - The backend offline objects as a tree structure.
     * @param topLevelId - The id of the top level parent.
     * @param offlineObjectsFlat - A map to store all elements.
     * @returns A flat map of all offline objects of the given tree (osid -> offline object).
     */
    private prepareOfflineObjects(backendObjects: any, topLevelId: number, offlineObjectsFlat: Map<string, any>): Map<string, any> {
        if (backendObjects === undefined || backendObjects.length === 0) {
            return;
        }

        const isTopLevel: boolean = (topLevelId == -1);

        for (const backendObject of backendObjects) {
            // The flat list is a unique list. Check if the current object is already in it.
            let offlineObject: any = offlineObjectsFlat.get(backendObject.id);

            // Top level. There we set the topLevelId with -1.
            if (isTopLevel) {
                topLevelId = backendObject.id;
            }

            if (offlineObject === undefined) {
                offlineObjectsFlat.set(backendObject.id, backendObject);
                offlineObject = backendObject;
                offlineObject.favReferences = [];

                if (backendObject.children != void 0 && backendObject.children.length >= 0) {
                    this.prepareOfflineObjects(backendObject.children, topLevelId, offlineObjectsFlat);
                    offlineObject.children = backendObject.children.map(child => child.id);
                }
            }

            // The favReferences are needed for process calculation and update
            if (!offlineObject.favReferences.includes(topLevelId)) {
                offlineObject.favReferences.push(topLevelId);
            }
        }

        return offlineObjectsFlat;
    }

    async abortable<T>(promise: Promise<T>, config: TimeoutConfigHolder): Promise<T> {
        const sub: Subscription = this.abortSubject.subscribe(config.subject);
        let result: T;

        try {
            result = await promise;
        } finally {
            sub.unsubscribe();
        }

        return result;
    }
    //endregion
}
