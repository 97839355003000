import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2
} from "@angular/core";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {TodoDesktopService, TodoEnvironmentService, TodoExternalTrayService} from "INTERFACES_PATH/any.types";
import {LayoutConfiguration} from "CORE_PATH/services/as-ini/as-ini.interfaces";
import {NavigationState} from "MODULES_PATH/navigation/interfaces/navigation.interface";
import {NavigationAction} from "MODULES_PATH/navigation/enums/navigation.enum";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {fromEvent, interval, Observable, of, ReplaySubject, Subscription} from "rxjs";
import {delay, take, takeUntil} from "rxjs/operators";
import {ChangeDetectorRef} from "@angular/core";
import {Store} from "@ngrx/store";
import {selectAvailableStates} from "MODULES_PATH/navigation/+state/navigation.selectors";
import {updateNavigation} from "MODULES_PATH/navigation/+state/navigation.actions";
import {NavigationEvents} from "MODULES_PATH/navigation/enums/navigation-events.enum";
import {ExternalTrayEvents} from "MODULES_PATH/external-tray/enums/external-tray-events.enum";

@Component({
    selector: "eob-nav",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly translate: TranslateFnType;
    private readonly layoutConfig: LayoutConfiguration;
    private globalClickListener: Subscription;
    private stateChangeListener: Subscription;
    private isNavResizing: boolean = false;
    private autoCompleteWrapper: HTMLElement;
    private subs: Subscription = new Subscription();
    private resizeObserver: MutationObserver;
    private resizeTimer: NodeJS.Timeout;
    private mainMenu: HTMLElement;
    private initialNavConfig: NavigationState;

    private eobNavBar: HTMLElement;
    private eobNav: HTMLElement;
    private eobNavBody: HTMLElement;
    private oldConnectionState: string;

    navBarTitle: string;
    nav$: Observable<NavigationState>;
    nav: NavigationState;

    @HostListener("window:resize") onResize(): void {
        this.setNavKebabState();
    }

    constructor(
        @Inject("environmentService") private environmentService: TodoEnvironmentService,
        @Inject("desktopService") private desktopService: TodoDesktopService,
        @Inject("$filter") private $filter: ng.IFilterService,
        @Inject("externalTrayService") private externalTrayService: TodoExternalTrayService,
        private ngZone: NgZone,
        private asIniService: AsIniService,
        private clientService: ClientService,
        private el: ElementRef<HTMLElement>,
        private messageService: MessageService,
        private renderer: Renderer2,
        private navStore: Store<NavigationState>,
        private cdRef: ChangeDetectorRef,
        public navigationService: NavigationService
    ) {
        this.navigationService.navigationRef = this.el;
        this.navigationService.navEl = this.el.nativeElement.closest(".nav-component");
        this.translate = this.$filter("translate");
        this.layoutConfig = this.asIniService.getLayoutConfiguration();
        this.initNavStore();
    }

    ngOnInit(): void {
        this.resizeObserver = new MutationObserver(mutationsList => {
            if (mutationsList.find(x => x.type == "attributes" && x.attributeName == "style")) {
                if (!this.isNavResizing && this.navigationService.isFixed) {
                    this.isNavResizing = true;
                    /**
                     *  =========================== delay 500ms =======================================
                     *  if user resizes too fast rxjs fromEvent will be triggered too fast before html
                     *  finished with initialization and the ElementRef offsetWidth will be taken from
                     *  the previous size
                     */
                    fromEvent(window, "mouseup").pipe(delay(500), take(1)).subscribe(_ => {
                        const conf: LayoutConfiguration = {
                            navigationWidth: (Math.round(this.el.nativeElement.offsetWidth / document.body.clientWidth * 100) + 1).toString()
                        };

                        this.asIniService.updateLayoutConfiguration(conf);
                        this.isNavResizing = false;
                    });
                }
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.subs.add(this.messageService.subscribe(ExternalTrayEvents.TRAY_ELEMENTS_CHANGED, async () => {
            const updatedTrayElements = await this.externalTrayService.getExternalTrayItemsAsync();
            this.updateNavigationState({[NavigationAction.EXTERNALTRAY_ELEMENTS_AVAILABLE]: updatedTrayElements.length > 0});
        }));

        this.subs.add(this.navStore.select(selectAvailableStates).subscribe((state: NavigationState) => {
            if (!this.nav) {
                this.initDefaultNavPane(state);
                this.navigationService.initialNavState$ = of(state);
                this.initialNavConfig = state;
            }

            this.nav = state;
        }));
    }

    async ngAfterViewInit(): Promise<void> {
        this.mainMenu = this.renderer.selectRootElement("#eob-nav-bar", true);
        this.autoCompleteWrapper = this.renderer.selectRootElement("#autocomplete-wrapper", true);
        this.setNavKebabState();
        await this.navigationService.setExternalTrayStateAsync();

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        this.unbindGlobalListeners();
        this.unbindResizeSensor();
    }

    @HostListener("keydown", ["$event"])
    handleKeydownEvent(event: KeyboardEvent) {
        if (event.key == "Escape") {
            if (!this.navigationService.isFixed) {
                this.navigationService.closeNavigation(0);
            }
            this.messageService.broadcast(NavigationEvents.FOCUS_NAV_BAR);
        }
    }

    shouldTrapFocus(): boolean {
        return this.clientService.isPhone();
    }

    changePinState(suppressAutoSave: boolean): void {
        this.navigationService.isFixed = !this.navigationService.isFixed;

        this.messageService.broadcast(NavigationEvents.UNPIN_NAVIGATION, {
            unpin: this.navigationService.isFixed,
            suppressAutoSave,
            layoutConfig: this.layoutConfig
        });

        if (this.navigationService.isFixed) {
            this.unbindGlobalListeners();
            this.bindResizeSensor();
        } else {
            this.bindGlobalListeners();
            this.unbindResizeSensor();
            this.navigationService.closeNavigation(500);
        }
    }

    isNavigationAllowed(): boolean {
        return this.nav.showInboxArea || this.nav.showDesktop || this.nav.showQuickSearches
            || this.nav.showObjectsearch || this.nav.showNavKebab;
    }

    buildMenu(onlineOfflineHasChanged: boolean): void {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                const isOnline: boolean = this.clientService.isOnline();

                if (isOnline) {
                    this.addConnectionStatus("online");
                } else {
                    if (this.navigationService.activeTab !== "externaltray") {
                        if (this.navigationService.isFixed) {
                            this.changePinState(onlineOfflineHasChanged);
                        } else {
                            this.navigationService.closeNavigation(500);
                        }
                    }

                    this.addConnectionStatus("offline");
                }

                this.updateNavigationState({[NavigationAction.SHOW_HISTORY]: this.clientService.isOnline()});

                this.cdRef.detectChanges();
            }, 0);
        });
    }

    addConnectionStatus(connectionState: string): void {
        this.navigationService.connectionState = connectionState;
        const isLocalClient: boolean = this.clientService.isLocalClient();
        let navBarTitle: string;

        if (isLocalClient) {
            navBarTitle = this.clientService.isOnline() ? this.translate("nav.pane.title.dashboard") : this.translate("nav.pane.title.dashboard.offline");
        } else {
            navBarTitle = this.translate("nav.pane.title.dashboard");
        }

        if (!this.oldConnectionState) {
            this.oldConnectionState = connectionState;
        } else {
            this.eobNavBar.classList.remove(this.oldConnectionState);
            this.eobNav.classList.remove(this.oldConnectionState);
            this.eobNavBody.classList.remove(this.oldConnectionState);
            this.oldConnectionState = connectionState;
        }

        this.eobNavBar.classList.add(connectionState);
        this.eobNav.classList.add(connectionState);
        this.eobNavBody.classList.add(connectionState);

        // Title for the home logo
        this.navBarTitle = navBarTitle;
    }

    private setInitialNavPinState(): void {
        this.navigationService.isFixed = this.isInitAsFixed();

        if (this.clientService.isPhoneOrTablet()) {
            this.navigationService.isFixed = false;
            setTimeout(() => {
                this.renderer.setStyle(document.querySelector(".nav-component"), "width", "40%");
            }, 0);
        }
        if (this.navigationService.isFixed && !this.clientService.isPhoneOrTablet()) {
            this.bindResizeSensor();
        } else {
            this.messageService.broadcast(NavigationEvents.UNPIN_NAVIGATION, {
                unpin: this.navigationService.isFixed,
                suppressAutoSave: !this.clientService.isPhoneOrTablet(),
                layoutConfig: this.layoutConfig
            });
        }

        if (!this.navigationService.isFixed) {
            this.navigationService.closeNavigation(0);
        }

        this.bindGlobalListeners();
    }

    private isInitAsFixed(): boolean {
        if (!this.isNavigationAllowed()) {
            return false;
        }

        if (this.clientService.isMobile()) {
            return false;
        }

        if (this.layoutConfig.isNavigationFixed == void 0) {
            const showNavAsDefault: boolean = this.environmentService.env.layout.showNavigation;
            return !!(showNavAsDefault == void 0 || showNavAsDefault == true);
        }

        return !!(this.layoutConfig.isNavigationFixed == "true");
    }

    private setNavKebabState() {
        clearTimeout(this.resizeTimer);

        this.resizeTimer = setTimeout(() => {
            const windowHeight: number = window.innerHeight;
            const menuItemMinHeight: number = 40;
            const visibleElementsCount: number = Math.floor((windowHeight) / menuItemMinHeight) - 2;
            const currentlyShown: NavigationState = Object.assign({}, this.nav);

            let overflowCount: number = -1 * visibleElementsCount;

            for (const stateKey in this.initialNavConfig) {
                currentlyShown[stateKey] = this.initialNavConfig[stateKey];

                if (this.initialNavConfig[stateKey]) {
                    overflowCount++;
                }
            }

            this.updateNavigationState({
                ...this.initialNavConfig,
                [NavigationAction.EXTERNALTRAY_ELEMENTS_AVAILABLE]: this.nav.externalTrayElementsAvailable,
                [NavigationAction.SHOW_EXTERNALTRAY]: this.nav.showExternalTray
            });

            delete currentlyShown.externalTrayElementsAvailable;

            if (overflowCount <= 0 && this.navigationService.activeTab == "kebab") {
                this.navigationService.closeNavigation(0);
            }

            while (overflowCount > 0) {
                if (currentlyShown.showHistory || currentlyShown.showFavorites || currentlyShown.showOfflineObjects) {
                    currentlyShown.showHistory = false;
                    currentlyShown.showFavorites = false;
                    currentlyShown.showOfflineObjects = false;

                    this.updateNavigationState({
                        [NavigationAction.SHOW_OFFLINE_OBJECTS]: false,
                        [NavigationAction.SHOW_HISTORY]: false,
                        [NavigationAction.SHOW_FAVORITES]: false,
                        [NavigationAction.SHOW_NAV_KEBAB]: true,
                    });
                } else if (currentlyShown.showNavAvatar) {
                    currentlyShown.showNavAvatar = false;

                    this.updateNavigationState({[NavigationAction.SHOW_NAV_AVATAR]: false});
                } else if (currentlyShown.showInboxArea) {
                    currentlyShown.showInboxArea = false;

                    this.updateNavigationState({[NavigationAction.SHOW_INBOX_AREA]: false});
                } else if (currentlyShown.showQuickSearches) {
                    currentlyShown.showQuickSearches = false;

                    this.updateNavigationState({[NavigationAction.SHOW_QUICKSEARCHES]: false});
                } else if (currentlyShown.showDesktop) {
                    currentlyShown.showDesktop = false;

                    this.updateNavigationState({[NavigationAction.SHOW_DESKTOP]: false});
                } else if (currentlyShown.showExternalTray) {
                    currentlyShown.showExternalTray = false;

                    this.updateNavigationState({[NavigationAction.SHOW_EXTERNALTRAY]: false});
                } else if (currentlyShown.showObjectsearch) {
                    currentlyShown.showObjectsearch = false;

                    this.updateNavigationState({[NavigationAction.SHOW_OBJECTSEARCH]: false});
                }

                overflowCount--;
            }
            this.cdRef.detectChanges();
            // give 100ms browsers to complete initialization of the HTML elements.
        }, 100);
    }

    private initDefaultNavPane(nav: NavigationState) {
        let defaultNavPane: string = this.layoutConfig.activeNavPane ?? "objectsearch";

        if (nav.showObjectsearch && defaultNavPane == "objectsearch") {
            defaultNavPane = "objectsearch";
        } else if (nav.showInboxArea && defaultNavPane == "inbox") {
            defaultNavPane = "inbox";
        } else if (defaultNavPane == "desktop" && nav.showDesktop) {
            defaultNavPane = "desktop";
        } else if (nav.showQuickSearches) {
            defaultNavPane = "quicksearch";
        }

        this.navigationService.switchNavContent(defaultNavPane, false);

        const stopInterval$: ReplaySubject<boolean> = new ReplaySubject(1);

        interval(0).pipe(takeUntil(stopInterval$)).subscribe(_ => {
            const width: number = this.el.nativeElement.getBoundingClientRect().width;

            if (width > 0) {
                this.eobNavBar = this.renderer.selectRootElement(".nav-bar-container", true);
                this.eobNav = this.renderer.selectRootElement("#eob-nav-container", true);
                this.eobNavBody = this.renderer.selectRootElement("div.nav-body", true);

                this.buildMenu(this.clientService.isOffline());
                this.clientService.registerConnectivityChangeHandler(() => this.buildMenu(true));
                this.setInitialNavPinState();

                stopInterval$.next(true);
                stopInterval$.complete();
            }
        });
    }

    // binding a scope listener --> the binding returns a deregister function
    private bindGlobalListeners(): void {
        this.bindClickListener();
        this.bindStateChangeListener();
    }

    private bindResizeSensor(): void {
        this.resizeObserver.observe(document.querySelector("div.nav-component.split-pane-component"), {attributes: true});
    }

    private bindStateChangeListener(): void {
        this.stateChangeListener = fromEvent(window, "hashchange").subscribe(() => this.onHashChange());
    }

    private onHashChange(): void {
        if (!this.navigationService.isFixed) {
            this.navigationService.closeNavigation(this.navigationService.DEFAULT_ANIMATION_DURATION);
        }
    }

    private bindClickListener(): void {
        this.globalClickListener = this.messageService.subscribe(InlineDialogEvent.CLOSE_INLINE_DIALOGS, (e: Event | string) => {
            if (!e || e == "modalButton") {
                return;
            }

            if (this.desktopService.isDesktopContextmenuOpen()) {
                return;
            }

            if (this.autoCompleteWrapper.offsetParent !== null && Number(this.autoCompleteWrapper.style.zIndex) > Number(this.el.nativeElement.style.zIndex)) {
                if (this.autoCompleteWrapper.offsetLeft < this.el.nativeElement.offsetWidth) {
                    return;
                }
            }
            if (!this.navigationService.isFixed) {
                const target = e ? (e as Event).target as HTMLElement : undefined;
                this.navigationService.closeNavigation(this.navigationService.DEFAULT_ANIMATION_DURATION, target);
            }
        });
    }

    private unbindResizeSensor(): void {
        this.resizeObserver?.disconnect();
    }

    private unbindGlobalListeners(): void {
        if (this.globalClickListener != void 0) {
            this.globalClickListener.unsubscribe();
            this.globalClickListener = null;
        }

        if (this.stateChangeListener) {
            this.stateChangeListener.unsubscribe();
            this.stateChangeListener = null;
        }
    }

    private initNavStore(): void {
        const showWorkflow: boolean = this.environmentService.env.actions.useWorkflow && this.environmentService.userHasRole("R_WFCLNT_USE");
        const showInbox: boolean = this.environmentService.env.actions.useAbo && this.environmentService.env.actions.useRevisits;
        const showObjectsearch: boolean = this.environmentService.env.useObjectSearch &&
            this.environmentService.userHasRole("R_CLNT_ARC_BAR") &&
            this.environmentService.userHasRole("R_CLNT_EXECUTE_INQUIRY");
        const showFavorites: boolean = this.environmentService.userHasRole("R_CLNT_SHOW_MOBFAV") && (!this.clientService.isLocalClient() || !this.asIniService.isSynchronizeFavoritesOffline());
        const showOfflineObjects: boolean = this.environmentService.userHasRole("R_CLNT_SHOW_MOBFAV") && this.clientService.isLocalClient() && this.asIniService.isSynchronizeFavoritesOffline();

        this.updateNavigationState({
            [NavigationAction.SHOW_WORKFLOW]: showWorkflow,
            [NavigationAction.SHOW_INBOX]: showInbox,
            [NavigationAction.SHOW_OBJECTSEARCH]: showObjectsearch,
            [NavigationAction.SHOW_QUICKSEARCHES]: this.environmentService.env.desktop.showQueries,
            [NavigationAction.SHOW_INBOX_AREA]: showWorkflow || showInbox,
            [NavigationAction.SHOW_DESKTOP]: this.environmentService.env.useDesktop,
            [NavigationAction.SHOW_HISTORY]: true,
            [NavigationAction.SHOW_OFFLINE_OBJECTS]: showOfflineObjects,
            [NavigationAction.SHOW_FAVORITES]: showFavorites,
        });

        this.nav$ = this.navStore.select(selectAvailableStates);
    }

    private updateNavigationState(navConfig: any): void {
        for (const key in navConfig) {
            this.navStore.dispatch(updateNavigation({navType: key as NavigationAction, payload: navConfig[key]}));
        }
    }
}
