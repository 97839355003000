import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {takeWhile} from "rxjs/operators";

(function () {
    angular.module("eob.framework").directive("eobModalExternalTrayLocations", EobModalExternalTrayLocations);

    EobModalExternalTrayLocations.$inject = ["errorModelService", "messageService"];

    function EobModalExternalTrayLocations(ErrorModelService, MessageService) {
        return {
            restrict: "E",
            scope: {
                resolve: "=",
                reject: "=",
                locations: "=",
                message: "=",
                destroy$: "=destroy"
            },
            link (scope, ev) {
                scope.useNewTab = false;
                let chosenLocation = scope.locations[0];
                scope.locations[0].isActive = true;


                scope.toggleUseNewTab = function () {
                    scope.useNewTab = !scope.useNewTab;
                }

                scope.chooseLocation = function (location) {
                    chosenLocation = location;
                    for (let location of scope.locations){
                        location.isActive = false;
                    }
                    location.isActive = true;
                };

                scope.submit = function(){
                    let location = chosenLocation;
                    location.useNewTab = scope.useNewTab;

                    scope.resolve(location);
                    MessageService.broadcast(ModalEvents.DESTROY);
                }

                // close the dialog once it is canceled
                scope.close = function () {
                    scope.reject(ErrorModelService.createCustomError("WEB_USER_ABORTED"));
                    MessageService.broadcast(ModalEvents.DESTROY);
                };

                // remove the dialog content from the main dialog container once the dialog is destroyed
                scope.destroy$.pipe(takeWhile(destroyed => destroyed)).subscribe(_ => scope.reject(ErrorModelService.createCustomError("WEB_USER_ABORTED")));
            },
            template: require("!raw-loader!./eob.modal.external.tray.locations.html")
        };
    }
})();
