(function() {


    angular.module("eob.core").provider("$eobConfig", EobConfigProvider);

    function EobConfigProvider() {

        let pServiceBase,
            pOfficeBase,
            pOswebBase,
            pPluginBase,
            pPlugins = {};

        // providers set-interface
        let prv = {
            serviceBase,
            officeBase,
            oswebBase,
            pluginBase,
            pluginsConfig
        };

        /**
         * @ngdoc method
         * @name $eobConfigProvider#serviceBase
         * @param {string} serviceBaseUri - The base URI of the enaio backend REST-API. Default value is `/rest-ws/service`.
         */
        function serviceBase(serviceBaseUri) {
            pServiceBase = serviceBaseUri;
        }

        function officeBase(officeBaseUri) {
            pOfficeBase = officeBaseUri;
        }

        function oswebBase(oswebBaseUri) {
            pOswebBase = oswebBaseUri;
        }

        // set the path to the plugin directory
        function pluginBase(cfgPluginBase) {
            pPluginBase = cfgPluginBase;
        }

        // set the plugin configuration
        function pluginsConfig(cfgPlugin) {
            pPlugins = cfgPlugin;
        }

        prv.$get = EobConfig;
        return prv;

        function EobConfig() {

            // providers get-interface
            let srv = {
                getServiceBase,
                getOfficeBase,
                getOswebBase,
                getPluginBase,
                getPlugins
            };
            return srv;

            // get the backend services base uri
            function getServiceBase() {
                return pServiceBase;
            }

            // get the backend O365 provider services base uri
            function getOfficeBase() {
                return pOfficeBase;
            }

            // get the backend osweb base uri
            function getOswebBase() {
                return pOswebBase;
            }

            // get the base path of the plugins folder
            function getPluginBase() {
                return pPluginBase;
            }

            // return the configured plugins
            function getPlugins() {
                return pPlugins;
            }
        }
    }
})();
