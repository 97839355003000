import Dexie from "dexie";
import {GlobalStore} from "INTERFACES_PATH/database/database-store.interface";
import {DmsDocumentStore} from "INTERFACES_PATH/dms-document-store.interface";
import {DmsContentStore} from "INTERFACES_PATH/dms-content-store.interface";
import {DatabaseType} from "ENUMS_PATH/database/database-type.enum";
import {CustomDatabase} from "./custom-database.model";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";

/**
 * Class for the global database
 */
export class GlobalDatabase extends CustomDatabase {
    persistent!: Dexie.Table<GlobalStore<any>, string>;
    dmsDocuments!: Dexie.Table<DmsDocumentStore, string>;
    dmsContent!: Dexie.Table<DmsContentStore, string>;

    constructor(dbName: string = DatabaseType.GLOBAL, clientService: ClientService) {
        super(dbName, clientService);
        this.version(3).stores({
            persistent: "key, timestamp",
            dmsDocuments: "&osid,*userReferences,[indexdata.hasContent+indexdata.isReference]",
            dmsContent: "&osid,*userReferences"
        });
        this.version(4).stores({
            persistent: "key, timestamp",
            dmsDocuments: "&osid,*userReferences,indexdata.hasContent",
            dmsContent: "&osid,*userReferences"
        });
        this.version(5).stores({
            persistent: "key, timestamp",
            dmsDocuments: "&osid,*userReferences,indexdata.hasContent",
            dmsContent: "&osid,*userReferences"
        }).upgrade(tx => {
            // changed structure from 9.10.5 to 9.10.6
            tx.table("persistent").where("key").equals(GlobalCacheKey.EMS_TYPES).modify(entry => {
                entry.content = entry.content.mappedTypes;
            });
        });
    }
}

export class OldGlobalDatabase extends Dexie {
    persistent!: Dexie.Table<GlobalStore<any>, number>;

    constructor(dbName?: string) {
        super(dbName || DatabaseType.GLOBAL);
        this.version(1).stores({persistent: "[key+origin], key, timestamp"});
        this.version(2).stores({persistent: "[key+origin], key, origin, timestamp"});

    }
}