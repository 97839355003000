<div role="banner" class="dialog-header">
    <div class="header-overlay">
        <div class="dialog-header-content">
            <span [hidden]="activeDashletId != 'content'" class="state-title">{{'eob.content.header.title' | ngTranslate}}</span>
            <span [hidden]="activeDashletId != 'o365'" class="state-title">{{'eob.o365.header.title' | ngTranslate}}</span>
            <span [hidden]="activeDashletId != 'details'" class="state-title">{{'eob.detailsviewer.header.title' | ngTranslate}}</span>
            <span [hidden]="activeDashletId && !activeDashletId.includes('customDashlet')" class="state-title">{{activeCustomDashletTitle}}</span>
        </div>
        <div class="dashlet-button-container">
            <!--  internal-dashlets   -->
            <button (click) = "goTo('content')" title="{{'eob.pdfjs.header.title' | ngTranslate}}" [ngClass]="{active: activeDashletId == 'content'}" [hidden]="!hasContent" class="button-transparent">
                <eob-icon title="{{'eob.pdfjs.header.title' | ngTranslate}}" name="content" size="24"></eob-icon>
            </button>
            <button *ngIf="showO365ViewerEnabled && viewerService.getShowO365Viewer()" (click)="goTo('o365')" title="{{'eob.o365.header.title' | ngTranslate}}" [ngClass]="{active: activeDashletId == 'o365'}" class="button-transparent">
                <eob-icon title="{{'eob.o365.header.title' | ngTranslate}}" name="office-365" size="24"></eob-icon>
            </button>
            <button (click) = "goTo('details')" title="{{'eob.detailsviewer.header.title' | ngTranslate}}" [ngClass]="{active: activeDashletId == 'details'}" class="button-transparent">
                <eob-icon title="{{'eob.detailsviewer.header.title' | ngTranslate}}" name="indexdata" size="24"></eob-icon>
            </button>
            <ng-content></ng-content>

            <!--  custom-dashlet  -->
            <eob-dashlet-kebab-menu *ngIf="showCustomDashlets && !isMobile && dashletKebabMenuItems?.length > 0"
                                    [menuItems]="dashletKebabMenuItems"
                                    [activeContainerDashlet]="activeDashletId"></eob-dashlet-kebab-menu>
        </div>
    </div>
</div>
<div role="complementary" class="dashlet-content" [hidden]="isChanging == true">
    <eob-pdfts *ngIf="!useDocumentViewer" [hidden]="activeDashletId != 'content'"></eob-pdfts>
    <eob-details-viewer-wrapper [hidden]="activeDashletId != 'details'" language='standalone' mode='webclient'></eob-details-viewer-wrapper>
    <eob-wopi-container *ngIf='activeDashletId == "o365" && isOnline && !isMobile'></eob-wopi-container>
    <document-viewer *ngIf="useDocumentViewer" [hidden]="activeDashletId != 'content'"></document-viewer>
    <ng-container *ngFor="let dashletConfig of customDashlets">
        <eob-custom-dashlet *ngIf="showCustomDashlets && (dashlets[dashletConfig.id].initialized || activeDashletId == dashletConfig.id)"
                            class="custom-dashlet"
                                    [hidden]="activeDashletId != dashletConfig.id"
                                    [active]="activeDashletId == dashletConfig.id"
                                    [dashletConfig]="dashletConfig"
                                    [dashletData]="dashlets[dashletConfig.id]"
                                    [dashletInitProperties]="dashletInitProperties"
                                    [currentTitle]="activeCustomDashletTitle"></eob-custom-dashlet>
    </ng-container>
</div>