import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";
import {FieldMask, FieldMaskValue} from "../field-mask.model";

export class DecimalFieldMask extends FieldMask {
    constructor(private environmentService: TodoEnvironmentService, private valueUtilsService: ValueUtilsService) {
        super();
    }

    formatFocus(value: FormInputValue): FormInputValue {
        if (value && this.valueUtilsService.isDecimalValue(value)) {
            value = this.valueUtilsService.parseDecimal(value);

            // replace dot (default decimal separator after parsing the value to decimal) with German decimal separator (comma)
            return this.environmentService.getLanguage() == "de" ? value.toString().replace(/\./g, ",") : value;
        } else {
            return value;
        }
    }

    formatBlur(maskValue: FieldMaskValue): FieldMaskValue {
        if (maskValue.value && !this.valueUtilsService.isDecimalValue(maskValue.value)) {
            // if not a decimal value mark field as invalid
            return {
                value: maskValue.value,
                rendererValue: maskValue.rendererValue,
                invalid: true
            };
        }

        // decimal value will be saved in the field mask, rendererValue must be formatted and include thousand separators
        const value: string = this.valueUtilsService.parseDecimal(maskValue.value);

        return {
            value,
            rendererValue: this.valueUtilsService.numberToLocaleNumber(value, ".2-8")
        };
    }
}
