import {downgradeComponent, downgradeInjectable, setAngularJSGlobal} from "@angular/upgrade/static";
import * as angular from "angular";
import {IModule} from "angular";
import {FileOpenService} from "SERVICES_PATH/mobile-desktop/eob.file.open.srv";
import {OfflineService} from "SERVICES_PATH/offline/eob.offline.srv";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {VirtualGridService} from "SERVICES_PATH/grid/eob.virtual.grid.srv";
import {VirtualGridConverter} from "SERVICES_PATH/grid/eob.virtual.grid.converter.srv";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {ToolService} from "CORE_PATH/services/utils/tool.service";
import {ContextMenuService} from "MODULES_PATH/context-menu/services/context-menu.service";
import {ProgressbarService} from "SERVICES_PATH/eob.progressbar.srv";
import {UpdateService} from "SERVICES_PATH/eob.update.srv";
import {DmsDocumentService} from "./modules/dms/dms-document.service";
import {DmsActionService} from "ROOT_PATH/app/modules/dms/dms-action.service";
import {DmsContentService} from "MODULES_PATH/dms/dms-content.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ProgressStatusService} from "CORE_PATH/services/progress/progress-status.service";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {EmsDropzoneComponent} from "MODULES_PATH/dropzone/components/ems-dropzone/ems-dropzone.component";
import {EobFormComponent} from "MODULES_PATH/form/eob-form.component";
import {EobResponsiveFormComponent} from "ROOT_PATH/app/modules/form/components/eob-responsive-form/eob-responsive-form.component";
import {EobIconComponent} from "ROOT_PATH/app/modules/icon/components/eob-icon-component/eob-icon.component";
import {EobSearchSettingsComponent} from "ROOT_PATH/app/modules/search/components/eob-search-settings/eob-search-settings.component";
import {EobSearchSettingsPinComponent} from "ROOT_PATH/app/modules/search/components/eob-search-settings-pin/eob-search-settings-pin.component";
import {EobValidationBubbleComponent} from "ROOT_PATH/app/modules/form/components/eob-validation-bubble/eob-validation-bubble.component";
import {EobFulltextSearchComponent} from "ROOT_PATH/app/modules/dashboard/components/eob-fulltext-search/eob-fulltext-search.component";
import {EobModalDateTimePickerComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-date-time-picker/eob-modal-date-time-picker.component";
import {EobModalOrgMultiselectComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-org-multiselect/eob-modal-org-multiselect.component";
import {EobModalContainerComponent} from "MODULES_PATH/modal-dialog/eob-modal-container.component";
import {EobStateFulltextFilterComponent} from "ROOT_PATH/app/modules/state-basics/components/eob-state-fulltext-filter/eob-state-fulltext-filter.component";
import {EobMultiselectBoxesComponent} from "MODULES_PATH/modal-dialog/components/eob-multiselect-boxes/eob-multiselect-boxes.component";
import {EobStateRefresherComponent} from "MODULES_PATH/state-basics/components/eob-state-refresher/eob-state-refresher.component";
import {EobDropdownComponent} from "SHARED_PATH/components/eob-dropdown/eob-dropdown.component";
import {EobStateFilterComponent} from "MODULES_PATH/state-basics/components/eob-state-filter/eob-state-filter.component";
import {EobHitListComponent} from "MODULES_PATH/hitlist/eob-hit-list.component";
import {EobStateSyncButtonComponent} from "MODULES_PATH/state-basics/components/eob-state-sync-button/eob-state-sync-button.component";
import {ContextMenuComponent} from "MODULES_PATH/context-menu/context-menu.component";
import {EobContextMenuTemplateComponent} from "MODULES_PATH/context-menu/components/eob-context-menu-template/eob-context-menu-template.component";
import {EobSubmenuComponent} from "MODULES_PATH/context-menu/components/eob-submenu/eob-submenu.component";
import {EobBaseparamSwitchComponent} from "MODULES_PATH/search/components/eob-baseparam-switch/eob-baseparam-switch.component";
import {EobModalUserConfigComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-user-config/eob-modal-user-config.component";
import {FieldsetBuilderService} from "CORE_PATH/services/field/fieldset-builder.service";
import {ValidationApiService} from "MODULES_PATH/form/services/validation-api.service";
import {FormValidationService} from "MODULES_PATH/form/services/form-validation.service";
import {ObjectTypeService} from "./modules/dms/objecttype.service";
import {FormAddonConfigParserService} from "MODULES_PATH/form/services/form-addon-config-parser.service";
import {FormFieldModelService} from "MODULES_PATH/form/services/form-field-model.service";
import {IconService} from "MODULES_PATH/icon/services/icon.service";
import {ThemeService} from "MODULES_PATH/theme/theme.service";
import {FormCatalogParserService} from "MODULES_PATH/form/services/form-catalog-parser.service";
import {FormLayoutService} from "MODULES_PATH/form/services/form-layout.service";
import {AutoCompleteService} from "MODULES_PATH/autocomplete/services/autocomplete.service";
import {UpdateUtilService} from "SERVICES_PATH/utils/update-utils.srv";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {DateTimePickerService} from "MODULES_PATH/modal-dialog/services/date-time-picker.service";
import {UserAddonService} from "MODULES_PATH/form/services/form-builder/user-addon.service";
import {OrgAddonService} from "MODULES_PATH/form/services/form-builder/org-addon.service";
import {RightGroupsAddonService} from "MODULES_PATH/form/services/form-builder/right-groups-addon.service";
import {WfOrgAddonService} from "MODULES_PATH/form/services/form-builder/wf-org-addon.service";
import {ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {ProfileManagerComponent} from "MODULES_PATH/profile-manager/profile-manager.component";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {SortService} from "CORE_PATH/services/utils/sort.service";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {ViewService} from "CORE_PATH/services/view/view.service";
import {ModalDialogInjectorService} from "MODULES_PATH/modal-dialog/services/modal-dialog-injector.service";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {EobKebabMenuComponent} from "MODULES_PATH/state-basics/components/state-kebab-menu/state-kebab-menu.component";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {EobTimerComponent} from "MODULES_PATH/timer/components/eob-timer/eob-timer.component";
import {TimerService} from "MODULES_PATH/timer/services/timer.service";
import {ProgressService} from "CORE_PATH/services/progress/progress.service";
import {EmsService} from "CORE_PATH/services/ems/ems.service";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {NgZone} from "@angular/core";
import {EobModalProgressCounterComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-progress-counter/eob-modal-progress-counter.component";
import {EobModalDeduplicationComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-deduplication/eob-modal-deduplication.component";
import {EobDashletDefaultPageComponent} from "MODULES_PATH/dashlet/components/eob-dashlet-default-page/eob-dashlet-default-page.component";
import {EobHitlistFooterComponent} from "MODULES_PATH/hitlist/components/eob-hitlist-footer/eob-hitlist-footer.component";
import {DocumentViewerComponent} from "MODULES_PATH/document-viewer/eob-document-viewer.component";
import {ActionService} from "CORE_PATH/services/actions/action.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {EobDashletContainerComponent} from "MODULES_PATH/dashlet/components/eob-dashlet-container/eob-dashlet-container.component";
import {EobDetailsViewerWrapperComponent} from "MODULES_PATH/details-viewer/eob-details-viewer.component";
import {GridContentService} from "MODULES_PATH/grid/services/grid-content.service";
import {GridContentUtilsService} from "MODULES_PATH/grid/services/grid-content-utils.service";
import {GridWrapperComponent} from "MODULES_PATH/grid/grid-wrapper.component";
import {GridCellComponent} from "MODULES_PATH/grid/components/grid-cells/grid-cell/grid-cell.component";
import {EobModalDashletsComponent} from "MODULES_PATH/dashlet/components/eob-modal-dashlets/eob-modal-dashlets.component";
import {EobStateHeaderComponent} from "MODULES_PATH/state-basics/components/eob-state-header/eob-state-header.component";
import {OfficeBackendService} from "MODULES_PATH/wopi/services/eob.office.backend.service";
import {WopiHostService} from "MODULES_PATH/wopi/services/eob.wopi.host.service";
import {EobWopiHostComponent} from "ROOT_PATH/app/modules/wopi/components/eob-wopi-host/eob-wopi-host.component";
import {EobWopiContainerComponent} from "MODULES_PATH/wopi/eob-wopi-container.component";
import {EobModalQuickfinderComponent} from "MODULES_PATH/modal-dialog/components/eob-modal-quickfinder/eob-modal-quickfinder.component";
import {StateTouchRefreshComponent} from "MODULES_PATH/state-basics/components/state-touch-refresh/state-touch-refresh.component";
import {AutocompleteWrapperComponent} from "MODULES_PATH/autocomplete/components/autocomplete-wrapper/autocomplete-wrapper.component";

import {QuickfinderAddonService} from "MODULES_PATH/form/services/form-builder/quickfinder-addon.service";
import {LoggingService} from "CORE_PATH/services/logging/logging.service";
import {NavigationComponent} from "MODULES_PATH/navigation/navigation.component";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {EobFormFooterComponent} from "MODULES_PATH/form/components/form-footer/eob-form-footer.component";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {EobPanelComponent} from "MODULES_PATH/navigation/components/eob-panel/eob-panel.component";
import {BackendVariantsService} from "CORE_PATH/backend/services/variants/backend-variants.service";
import {BackendSearchService} from "CORE_PATH/backend/services/search/backend-search.service";
import {ResultComponent} from "MODULES_PATH/hitlist/components/result/result.component";
import {BackendResourceService} from "CORE_PATH/backend/services/resource/backend-resource.service";
import {BackendObjectService} from "CORE_PATH/backend/services/object/backend-object.service";
import {KebabMenuService} from "CORE_PATH/services/kebab-menu/kebab-menu.service";
import {EobAttachedDashletContainerComponent} from "MODULES_PATH/dashlet/components/eob-attached-dashlet-container/eob-attached-dashlet-container.component";
import {BackendFavoritesService} from "CORE_PATH/backend/services/search/backend-favorites.service";
import {BackendStoredQueriesService} from "CORE_PATH/backend/services/search/backend-stored-queries.service";
import {InputComponent} from "MODULES_PATH/form/components/inputs/input/input.component";
import {CheckboxComponent} from "MODULES_PATH/form/components/inputs/checkbox/checkbox.component";
import {RadioButtonComponent} from "MODULES_PATH/form/components/inputs/radio-button/radio-button.component";
import {InboxActionService} from "CORE_PATH/services/actions/inbox-action.service";
import {EobFormElementComponent} from "MODULES_PATH/form/components/eob-form-element/eob-form-element.component";
import {LabelComponent} from "MODULES_PATH/form/components/inputs/label/label.component";
import {IconCellComponent} from "MODULES_PATH/grid/components/grid-cells/icon-cell/icon-cell.component";
import { FormControlService } from "MODULES_PATH/form/services/form-control.service";
import {CapslockTracerComponent} from "MODULES_PATH/form/components/capslock-tracer/capslock-tracer.component";
import {FormAddonService} from "MODULES_PATH/form/services/form-addon.service";
import {DesktopQueryService} from "CORE_PATH/services/desktop/desktop-query.service";
import {QueryBuilderService} from "CORE_PATH/services/search/query-builder.service";
import {EobFormGridComponent} from "MODULES_PATH/grid/components/eob-form-grid/eob-form-grid.component";
import {ScriptingDmsService} from "MODULES_PATH/scripting/scripting-dms.service";
import { OfflineLocationCacheService } from "SERVICES_PATH/offline/eob.offline.location.cache.srv";
import {EobExternalTrayElementsComponent} from "MODULES_PATH/external-tray/components/external-tray-elements/eob-external-tray-elements.component";
import {NewTreeAddonService} from "MODULES_PATH/form/services/tree-addon.service";

const MODULE_NAME: string = "eob.client";
setAngularJSGlobal(angular);

require("angular-translate");
require("angular-translate-loader-partial");
require("angular-sanitize");
require("ng-cordova");
require("COMPONENTS_PATH/eob-dropzone/eob.dropzone.mod.js");
require("@shagstrom/angular-split-pane/angular-split-pane.js");

// Import the detailsviewer.
// Import non-angular modules that the detailsviewer expects to be globally existent, so that they may be shared with the client.
// Always use the path to the node_modules instead of the appmodule name in this case.

// require("imports-loader?agGrid=../../node_modules/ag-grid-enterprise/dist/ag-grid-enterprise.min.js" +
//    ",marked=../../node_modules/marked/marked.min.js");

// THIS WILL LEAD TO PARSING ERRORS ON iOS!!!

require("SERVICES_PATH/../eob.core.mod.js");
require("COMPONENTS_PATH/../eob.framework.mod.js");

const angularDragula: (ng) => string = require("angular-dragula");

const agGrid = require("ag-grid-enterprise/dist/ag-grid-enterprise.min.js");
agGrid.initialiseAgGridWithAngular1(angular);
agGrid.LicenseManager.setLicenseKey("CompanyName=OPTIMAL SYSTEMS GmbH,LicensedApplication=enaio,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=50,AssetReference=AG-024434,ExpiryDate=2_March_2023_[v2]_MTY3NzcxNTIwMDAwMA==3e99be55e356d4328f3c562bd032242f");

const dropzoneClass = "dropzone";
for (const event of ["dragenter", "dragover", "drop"]) {
    window.addEventListener(event, (e: DragEvent) => {
        if (!(e.target as Element).classList.contains(dropzoneClass)) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }
    }, false);
}

const appmodule: IModule = angular.module(MODULE_NAME, [
    "ui.router",
    "ui.router.upgrade",
    "pascalprecht.translate",
    "ngSanitize",
    "agGrid",
    "eo-pdf-anno",
    "eob.core",
    "eob.framework",
    "dropzone",
    "ngCordova",
    "shagstrom.angular-split-pane",
    angularDragula(angular)
]);

// ### DmsDocumentService dependencies

require("SERVICES_PATH/eob.backend.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("MODELS_PATH/eob.revisit.model.srv.js");
require("MODELS_PATH/eob.vtx.model.srv.js");
require("MODELS_PATH/eob.subscription.model.srv.js");
require("MODELS_PATH/eob.subscriptionObject.model.srv.js");
require("MODELS_PATH/eob.variant.model.srv.js");
require("MODELS_PATH/eob.wffile.model.srv.js");

// ### DmsDocumentService dependencies end

require("SERVICES_PATH/form/eob.form.builder.srv.js");

/*#######################################*/
/* insert upgraded services here */
/*#######################################*/

angular.module("eob.core").service("toolService", downgradeInjectable(ToolService));
angular.module("eob.core").service("formAddonService", downgradeInjectable(FormAddonService));
angular.module("eob.core").service("messageService", downgradeInjectable(MessageService));
angular.module("eob.core").service("fieldsetBuilderService", downgradeInjectable(FieldsetBuilderService));
angular.module("eob.core").service("formFieldModelService", downgradeInjectable(FormFieldModelService));
angular.module("eob.core").service("dmsDocumentService", downgradeInjectable(DmsDocumentService));
angular.module("eob.core").service("dmsActionService", downgradeInjectable(DmsActionService));
angular.module("eob.core").service("dmsContentService", downgradeInjectable(DmsContentService));
angular.module("eob.core").service("objectTypeService", downgradeInjectable(ObjectTypeService));
angular.module("eob.core").service("iconService", downgradeInjectable(IconService));
angular.module("eob.core").service("autoCompleteService", downgradeInjectable(AutoCompleteService));
angular.module("eob.core").service("themeService", downgradeInjectable(ThemeService));
angular.module("eob.core").service("errorModelService", downgradeInjectable(ErrorModelService));
angular.module("eob.core").service("formAddonConfigParserService", downgradeInjectable(FormAddonConfigParserService));
angular.module("eob.core").service("formCatalogParserService", downgradeInjectable(FormCatalogParserService));
angular.module("eob.core").service("progressStatusService", downgradeInjectable(ProgressStatusService));
angular.module("eob.core").service("formLayoutService", downgradeInjectable(FormLayoutService));
angular.module("eob.core").service("sortService", downgradeInjectable(SortService));
angular.module("eob.core").service("notificationsService", downgradeInjectable(NotificationsService));
angular.module("eob.core").service("valueUtilsService", downgradeInjectable(ValueUtilsService));
angular.module("eob.core").service("viewService", downgradeInjectable(ViewService));
angular.module("eob.core").service("httpService", downgradeInjectable(HttpService));
angular.module("eob.core").service("formValidationService", downgradeInjectable(FormValidationService));
angular.module("eob.core").service("validationApiService", downgradeInjectable(ValidationApiService));
angular.module("eob.core").service("dateTimePickerService", downgradeInjectable(DateTimePickerService));
angular.module("eob.core").service("orgAddonService", downgradeInjectable(OrgAddonService));
angular.module("eob.core").service("userAddonService", downgradeInjectable(UserAddonService));
angular.module("eob.core").service("rightGroupsAddonService", downgradeInjectable(RightGroupsAddonService));
angular.module("eob.core").service("wfOrgAddonService", downgradeInjectable(WfOrgAddonService));
angular.module("eob.core").service("gridContentService", downgradeInjectable(GridContentService));
angular.module("eob.core").service("gridContentUtilsService", downgradeInjectable(GridContentUtilsService));
angular.module("eob.core").service("modalDialogInjectorService", downgradeInjectable(ModalDialogInjectorService));
angular.module("eob.core").service("asIniService", downgradeInjectable(AsIniService));
angular.module("eob.core").service("clientService", downgradeInjectable(ClientService));
angular.module("eob.core").service("contextMenuService", downgradeInjectable(ContextMenuService));
angular.module("eob.core").service("authenticationService", downgradeInjectable(AuthenticationService));
angular.module("eob.core").service("profileService", downgradeInjectable(ProfileService));
angular.module("eob.core").service("customStorageService", downgradeInjectable(CustomStorageService));
angular.module("eob.core").service("ngZone", downgradeInjectable(NgZone));
angular.module("eob.core").service("timerService", downgradeInjectable(TimerService));
angular.module("eob.core").service("progressService", downgradeInjectable(ProgressService));
angular.module("eob.core").service("emsService", downgradeInjectable(EmsService));
angular.module("eob.core").service("actionService", downgradeInjectable(ActionService));
angular.module("eob.core").service("viewerService", downgradeInjectable(ViewerService));
angular.module("eob.core").service("quickfinderAddonService", downgradeInjectable(QuickfinderAddonService));
angular.module("eob.core").service("loggingService", downgradeInjectable(LoggingService));
angular.module("eob.core").service("navigationService", downgradeInjectable(NavigationService));
angular.module("eob.core").service("layoutManagerService", downgradeInjectable(LayoutManagerService));
angular.module("eob.core").service("organisationService", downgradeInjectable(OrganisationService));
angular.module("eob.core").service("backendVariantsService", downgradeInjectable(BackendVariantsService));
angular.module("eob.core").service("backendSearchService", downgradeInjectable(BackendSearchService));
angular.module("eob.core").service("backendFavoritesService", downgradeInjectable(BackendFavoritesService));
angular.module("eob.core").service("backendStoredQueriesService", downgradeInjectable(BackendStoredQueriesService));
angular.module("eob.core").service("backendResourceService", downgradeInjectable(BackendResourceService));
angular.module("eob.core").service("backendObjectService", downgradeInjectable(BackendObjectService));
angular.module("eob.core").service("inboxActionService", downgradeInjectable(InboxActionService));
angular.module("eob.core").factory("desktopQueryService", downgradeInjectable(DesktopQueryService));
angular.module("eob.core").factory("queryBuilderService", downgradeInjectable(QueryBuilderService));
angular.module("eob.core").factory("scriptingDmsService", downgradeInjectable(ScriptingDmsService));
angular.module("eob.core").factory("newTreeAddonService", downgradeInjectable(NewTreeAddonService));

/*#######################################*/
/* insert Typescript ready services here */
/*#######################################*/

angular.module("eob.core").service("offlineService", OfflineService);
angular.module("eob.core").service("offlineCacheService", OfflineCacheService);
angular.module("eob.core").service("offlineLocationCacheService", OfflineLocationCacheService);
angular.module("eob.core").service("fileOpenService", FileOpenService);
angular.module("eob.core").service("virtualGridService", VirtualGridService);
angular.module("eob.core").service("virtualGridConverter", VirtualGridConverter);
angular.module("eob.core").service("fileCacheService", FileCacheService);
angular.module("eob.core").service("updateService", UpdateService);
angular.module("eob.core").service("progressbarService", downgradeInjectable(ProgressbarService));
angular.module("eob.core").service("updateUtilService", UpdateUtilService);
angular.module("eob.core").service("actionService", downgradeInjectable(ActionService));
angular.module("eob.core").service("officeBackendService", downgradeInjectable(OfficeBackendService));
angular.module("eob.core").service("wopiHostService", downgradeInjectable(WopiHostService));
angular.module("eob.core").service("kebabMenuService", downgradeInjectable(KebabMenuService));
angular.module("eob.core").service("formControlService", downgradeInjectable(FormControlService));

require("CLIENT_PATH/configuration/mimetype.model.config.js");

require("SERVICES_PATH/eob.config.prv.js");
require("SERVICES_PATH/eob.state.history.manager.srv.js");
require("SERVICES_PATH/eob.state.srv.js");


// Import the annotations.
// Import non-angular modules that the annotations expect to be globally existent, so that they may be shared with the client.
// Always use the path to the node_modules instead of the appmodule name in this case.
require("official-lumx/dist/lumx.min.js");

require("os-pdfjs/build_jenkins/os.anno.min.js");
/*
require("imports-loader?saveAsMod=file-saver/FileSaver.js" +
    ",saveAs=>saveAsMod.saveAs" +
    ",toastr=toastr/build/toastr.min.js" +
    "!os-pdfjs/build_jenkins/os.anno.min.js");
 */

/*
The translated strings are held inside a map, since map lookups are much faster than $filter'ing values repeatedly.
As the language isn't changed without refreshing the page, there is no need to be able to clear the map
 */
const translatedMap: Map<string, string> = new Map();

// Improve AngularJS performance in production
if (process.env.NODE_ENV == "production" && !location.href.includes("debug")) {
    appmodule.config(["$compileProvider", ($compileProvider) => {
        $compileProvider.debugInfoEnabled(false);
    }]);
}

appmodule.config(["$provide", $provide => {
    $provide.decorator("$filter", ["$delegate", $delegate => arg => {
        if (arg != "translate") {
            return $delegate(arg);
        } else {
            return (...translateArgs) => {
                // expect (translationString: string, forceTranslate: boolean) to force retranslation if required
                let translation: string = translatedMap.get(translateArgs[0]);
                if (translateArgs[1] || translation === undefined) {
                    translation = $delegate("translate")(translateArgs[0]);
                    if (translation != translateArgs[0] && !/^(modal\.button|eob\.login)/gi.test(translateArgs[0])) {
                        translatedMap.set(translateArgs[0], translation);
                    }
                }
                return translation;
            };
        }
    }
    ]);
}]);

// This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
appmodule.config(["$urlServiceProvider", ($urlService) => $urlService.deferIntercept()]);

appmodule.factory("ngZone", downgradeInjectable(NgZone));

appmodule.directive("eobEmsDropzone", downgradeComponent({component: EmsDropzoneComponent}));
appmodule.directive("eobForm", downgradeComponent({component: EobFormComponent}));
appmodule.directive("responsiveFormComponent", downgradeComponent({component: EobResponsiveFormComponent}));
appmodule.directive("eobWopiHost", downgradeComponent({component: EobWopiHostComponent}));
appmodule.directive("eobWopiContainer", downgradeComponent({component: EobWopiContainerComponent}));
appmodule.directive("eobStateFulltextFilter", downgradeComponent({component: EobStateFulltextFilterComponent}));
appmodule.directive("eobGridWrapper", downgradeComponent({component: GridWrapperComponent}));
appmodule.directive("eobHitList", downgradeComponent({component: EobHitListComponent}));
appmodule.directive("eobSearchSettings", downgradeComponent({component: EobSearchSettingsComponent}));
appmodule.directive("eobSearchSettingsPin", downgradeComponent({component: EobSearchSettingsPinComponent}));
appmodule.directive("eobValidationBubble", downgradeComponent({component: EobValidationBubbleComponent}));
appmodule.directive("eobFulltextSearch", downgradeComponent({component: EobFulltextSearchComponent}));
appmodule.directive("eobModalDateTimePicker", downgradeComponent({component: EobModalDateTimePickerComponent}));
appmodule.directive("eobIcon", downgradeComponent({component: EobIconComponent}));
appmodule.directive("eobModalOrgMultiselect", downgradeComponent({component: EobModalOrgMultiselectComponent}));
appmodule.directive("eobMultiselectBoxes", downgradeComponent({component: EobMultiselectBoxesComponent}));
appmodule.directive("eobStateRefresher", downgradeComponent({component: EobStateRefresherComponent}));
appmodule.directive("eobDropdown", downgradeComponent({component: EobDropdownComponent}));
appmodule.directive("eobStateFilter", downgradeComponent({component: EobStateFilterComponent}));
appmodule.directive("eobModalContainer", downgradeComponent({component: EobModalContainerComponent}));
appmodule.directive("eobStateSyncButton", downgradeComponent({component: EobStateSyncButtonComponent}));
appmodule.directive("eobContextmenu", downgradeComponent({component: ContextMenuComponent}));
appmodule.directive("eobSubmenu", downgradeComponent({component: EobSubmenuComponent}));
appmodule.directive("eobContextmenuTemplate", downgradeComponent({component: EobContextMenuTemplateComponent}));
appmodule.directive("eobBaseparamSwitch", downgradeComponent({component: EobBaseparamSwitchComponent}));
appmodule.directive("eobKebabMenu", downgradeComponent({component: EobKebabMenuComponent}));
appmodule.directive("eobModalUserConfig", downgradeComponent({component: EobModalUserConfigComponent}));
appmodule.directive("eobProfileManager", downgradeComponent({component: ProfileManagerComponent}));
appmodule.directive("eobTimer", downgradeComponent({component: EobTimerComponent}));
appmodule.directive("eobModalProgressCounter", downgradeComponent({component: EobModalProgressCounterComponent}));
appmodule.directive("eobModalDeduplication", downgradeComponent({component: EobModalDeduplicationComponent}));
appmodule.directive("eobGridCell", downgradeComponent({component: GridCellComponent}));
appmodule.directive("eobDashletDefaultPage", downgradeComponent({component: EobDashletDefaultPageComponent}));
appmodule.directive("eobHitlistFooter", downgradeComponent({component: EobHitlistFooterComponent}));
appmodule.directive("eobFormFooter", downgradeComponent({component: EobFormFooterComponent}));
appmodule.directive("documentViewer", downgradeComponent({component: DocumentViewerComponent}));
appmodule.directive("eobDetailsViewerWrapper", downgradeComponent({component: EobDetailsViewerWrapperComponent}));
appmodule.directive("eobDashletContainer", downgradeComponent({component: EobDashletContainerComponent}));
appmodule.directive("eobModalDashlets", downgradeComponent({component: EobModalDashletsComponent}));
appmodule.directive("eobStateHeader", downgradeComponent({component: EobStateHeaderComponent}));
appmodule.directive("eobModalQuickfinder", downgradeComponent({component: EobModalQuickfinderComponent}));
appmodule.directive("eobStateTouchRefresh", downgradeComponent({component: StateTouchRefreshComponent}));
appmodule.directive("eobNav", downgradeComponent({component: NavigationComponent}));
appmodule.directive("eobPanel", downgradeComponent({component: EobPanelComponent}));
appmodule.directive("eobResult", downgradeComponent({component: ResultComponent}));
appmodule.directive("eobInbox", downgradeComponent({component: InputComponent}));
appmodule.directive("eobCheckbox", downgradeComponent({component: CheckboxComponent}));
appmodule.directive("eobAutocompleteWrapper", downgradeComponent({component: AutocompleteWrapperComponent}));
appmodule.directive("eobAttachedDashletContainer", downgradeComponent({component: EobAttachedDashletContainerComponent}));
appmodule.directive("eobRadioButton", downgradeComponent({component: RadioButtonComponent}));
appmodule.directive("eobFormElement", downgradeComponent({component: EobFormElementComponent}));
appmodule.directive("eobLabel", downgradeComponent({component: LabelComponent}));
appmodule.directive("iconCellComponent", downgradeComponent({component: IconCellComponent}));
appmodule.directive("eobCapslockTracer", downgradeComponent({component: CapslockTracerComponent}));
appmodule.directive("eobFormGrid", downgradeComponent({component: EobFormGridComponent}));
appmodule.directive("eobExternalTrayElements", downgradeComponent({component: EobExternalTrayElementsComponent}));

require("CLIENT_PATH/configuration/app.config.js");
require("CLIENT_PATH/configuration/main.config.js");
require("CLIENT_PATH/configuration/mimetype.model.config.js");

// Directives needed in the index.html or required by typescript services
require("COMPONENTS_PATH/eob-modal-container/eob-modal-add-fav-prompt/eob.modal.add.fav.prompt.dir.js");
require("COMPONENTS_PATH/eob-main/eob.main.dir.js");
require("COMPONENTS_PATH/eob-demo-contact/eob.demo.contact.dir.js");

require("COMPONENTS_PATH/eob-nav/eob-nav-desktop/eob.nav.desktop.dir.js");
require("COMPONENTS_PATH/eob-nav/eob-nav-quicksearch/eob.nav.quicksearch.dir.js");

// need this before as.ini is loaded
// @ts-ignore
// require.ensure([], () => {
//     require("style-loader!COMPONENTS_PATH/eob-profile-manager/_eob.profile.manager.scss");
// });

export default MODULE_NAME;
