import {Injectable} from "@angular/core";

/**
 * Helper file to update the loading progress from several locations without having to duplicate code
 */
// const loadingMessageSpan = document.querySelector("#loadingMessage");

let progressBar: any;
const progressStep: number = 20;
const maxValue: number = 100;
let autoState: number = 0;
let progressIsAutoCounting: boolean = false;
let progressDebounce: NodeJS.Timeout = null;

/**
 * A service for managing events.
 */
@Injectable({providedIn: "root"})
export class ProgressStatusService {

    initProgressBar = () => {
        progressBar = document.querySelector("progress.eob-splash-progress");
        if (!progressBar) {
            return;
        }
        progressBar.style.opacity = 1;// .css({"opacity": 1});
        this.setProgressBarValue(0);
    };

    hideProgressBar = () => {
        if (!progressBar) {
            return;
        }
        progressBar.style.display = "none";
    };

    /**
     * Set the Progressbar to its percentage
     *
     * @param {number} progressState - is the percentage the progress bar is set to
     */
    setProgressBarValue = (progressState: number) => {
        if (!progressBar) {
            return;
        }
        if (autoState < progressState && progressState <= maxValue) {
            progressIsAutoCounting = false;
            // set the value of progress tag

            progressBar.value = progressState;
            // set custom css property for the firefox to use as reference for animation
            progressBar.setAttribute("style", `opacity:1; --progress-value: ${progressState}%`);
        }

        // continue counting slowly if nothing happens for 1s
        clearTimeout(progressDebounce);
        progressDebounce = setTimeout(() => {
            progressIsAutoCounting = true;
            this.continueProgressbar(progressState);
        }, 1000);
    };

    continueProgressbar = (progressState: number) => {
        if (!progressBar) {
            return;
        }
        let count: number = progressState + 1;
        const interval: NodeJS.Timer = setInterval(() => {
            // if a normal count happend or the counting reached the next stepmark stop autocount
            if (!progressIsAutoCounting || (count >= maxValue) || count > progressState + progressStep) {
                clearInterval(interval);
                return;
            }
            // set the value of progress tag
            progressBar.value = count;
            // set custom css property for the firefox to use as reference for animation
            progressBar.setAttribute("style", `opacity:1; --progress-value: ${count}%`);
            // progressBar.css({"--progress-value": `${count}%`});
            autoState = count;
            count++;
        }, 300);
        const promise: Promise<NodeJS.Timer> = new Promise(resolve => setTimeout(resolve, 0));
    };

    setLoadingMessage = (message: string) => {
        // TODO to be used later
        // loadingMessageSpan.innerHTML = message;
    };

}
