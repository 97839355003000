import {NgModule} from "@angular/core";
import {Optional, SkipSelf} from "@angular/core";
import {ajsServiceProviders} from "ROOT_PATH/eob-core/ajs.upgraded.providers";
import {httpInterceptorProviders} from "CORE_PATH/backend/http/http.interceptors";
import {HttpClientModule} from "@angular/common/http";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";

/**
 * Module handling all backend requests
 */
@NgModule({
    imports: [HttpClientModule, BackendOsRestModule, BackendDms2Module],
    exports: [BackendOsRestModule, BackendDms2Module],
    providers: [...ajsServiceProviders, ...httpInterceptorProviders],
})
export class BackendModule {
    constructor(@Optional() @SkipSelf() serviceModule: BackendModule) {
        if(serviceModule) {
            throw new Error("Module is not to be used standalone");
        }
    }
}
