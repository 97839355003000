import {Provider, StaticProvider} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ClientBrowserService} from "CORE_PATH/services/client/client-browser.service";
import {ClientCordovaService} from "CORE_PATH/services/client/client-cordova.service";
import {ClientElectronService} from "CORE_PATH/services/client/client-electron.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";
import {FileOpenService} from "SERVICES_PATH/mobile-desktop/eob.file.open.srv";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";

/**
 * Initialization of the required client service depending on the platform
 */
export function initClientService($filter: ng.IFilterService, $injector: ng.auto.IInjectorService, $eobConfig: any, $rootScope: RootScope, errorModelService: ErrorModelService,
                                  messageService: MessageService, mimeTypes: any, fileOpenService: FileOpenService, notificationsService: NotificationsService,
                                  $cordovaFile: any, authenticationService: AuthenticationService, customStorageService: CustomStorageService): ClientService {
    if (window.electron) {
        return new ClientElectronService($injector, $eobConfig, $rootScope, errorModelService, messageService, $filter, fileOpenService, authenticationService, customStorageService);
    } else if (window.cordova) {
        return new ClientCordovaService($injector, $eobConfig, $rootScope, messageService, errorModelService, $filter, fileOpenService, mimeTypes, notificationsService, $cordovaFile,
            authenticationService, customStorageService);
    } else {
        return new ClientBrowserService($filter, $injector, $eobConfig, $rootScope, messageService, errorModelService, mimeTypes, authenticationService, customStorageService);
    }
}

/**
 * List of legacy service providers required by Angular services
 * Some providers provide already migrated services to compensate for the usage of @Inject("serviceName")
 */
export const ajsServiceProviders: StaticProvider[] = [
    {
        provide: ClientService,
        useFactory: initClientService,
        deps: ["$filter", "$injector", "$eobConfig", "$rootScope", ErrorModelService, MessageService,
            "mimeTypes", "fileOpenService", NotificationsService, "$cordovaFile",
            AuthenticationService, CustomStorageService]
    },
    {
        provide: "actionService",
        useFactory: i => i.get("actionService"),
        deps: ["$injector"]
    },
    {
        provide: "inboxActionService",
        useFactory: i => i.get("inboxActionService"),
        deps: ["$injector"]
    },
    {
        provide: "messageService",
        useFactory: i => i.get("messageService"),
        deps: ["$injector"]
    },
    {
        provide: "formAddonConfigParserService",
        useFactory: i => i.get("formAddonConfigParserService"),
        deps: ["$injector"]
    },
    {
        provide: "FormCatalogParserService",
        useFactory: i => i.get("FormCatalogParserService"),
        deps: ["$injector"]
    },
    {
        provide: "$eobConfig",
        useFactory: i => i.get("$eobConfig"),
        deps: ["$injector"]
    },
    {
        provide: "$filter",
        useFactory: i => i.get("$filter"),
        deps: ["$injector"]
    },
    {
        provide: "$compile",
        useFactory: i => i.get("$compile"),
        deps: ["$injector"]
    },
    {
        provide: "$timeout",
        useFactory: i => i.get("$timeout"),
        deps: ["$injector"]
    },
    {
        provide: "organisationService",
        useFactory: i => i.get("organisationService"),
        deps: ["$injector"]
    },
    {
        provide: "dbCatalogService",
        useFactory: i => i.get("dbCatalogService"),
        deps: ["$injector"]
    },
    {
        provide: "cacheManagerService",
        useFactory: i => i.get("cacheManagerService"),
        deps: ["$injector"]
    },
    {
        provide: "formFieldModelService",
        useFactory: i => i.get("formFieldModelService"),
        deps: ["$injector"]
    },
    {
        provide: "asIniService",
        useFactory: i => i.get("asIniService"),
        deps: ["$injector"]
    },
    {
        provide: "backendService",
        useFactory: i => i.get("backendService"),
        deps: ["$injector"]
    },
    {
        provide: "clientScriptService",
        useFactory: i => i.get("clientScriptService"),
        deps: ["$injector"]
    },
    {
        provide: "scriptHelperModelService",
        useFactory: i => i.get("scriptHelperModelService"),
        deps: ["$injector"]
    },
    {
        provide: "dmsContentService",
        useFactory: i => i.get("dmsContentService"),
        deps: ["$injector"]
    },
    {
        provide: "environmentService",
        useFactory: i => i.get("environmentService"),
        deps: ["$injector"]
    },
    {
        provide: "errorModelService",
        useFactory: i => i.get("errorModelService"),
        deps: ["$injector"]
    },
    {
        provide: "fieldApiService",
        useFactory: i => i.get("fieldApiService"),
        deps: ["$injector"]
    },
    {
        provide: "fileCacheService",
        useFactory: i => i.get("fileCacheService"),
        deps: ["$injector"]
    },
    {
        provide: "formBuilderService",
        useFactory: i => i.get("formBuilder"),
        deps: ["$injector"]
    },
    {
        provide: "formFieldBuilder",
        useFactory: i => i.get("formFieldBuilder"),
        deps: ["$injector"]
    },
    {
        provide: "modalDialogService",
        useFactory: i => i.get("modalDialogService"),
        deps: ["$injector"]
    },
    {
        provide: "notificationsService",
        useFactory: i => i.get("notificationsService"),
        deps: ["$injector"]
    },
    {
        provide: "objectTypeService",
        useFactory: i => i.get("objectTypeService"),
        deps: ["$injector"]
    },
    {
        provide: "offlineService",
        useFactory: i => i.get("offlineService"),
        deps: ["$injector"]
    },
    {
        provide: "offlineCacheService",
        useFactory: i => i.get("offlineCacheService"),
        deps: ["$injector"]
    },
    {
        provide: "offlineLocationCacheService",
        useFactory: i => i.get("offlineLocationCacheService"),
        deps: ["$injector"]
    },
    {
        provide: "pdfjsViewerServiceProvider",
        useFactory: i => i.get("pdfjsViewerServiceProvider"),
        deps: ["$injector"]
    },
    {
        provide: "profileService",
        useFactory: i => i.get("profileService"),
        deps: ["$injector"]
    },
    {
        provide: "searchService",
        useFactory: i => i.get("searchService"),
        deps: ["$injector"]
    },
    {
        provide: "sortService",
        useFactory: i => i.get("sortService"),
        deps: ["$injector"]
    },
    {
        provide: "stateHistoryManager",
        useFactory: i => i.get("stateHistoryManager"),
        deps: ["$injector"]
    },
    {
        provide: "stateService",
        useFactory: i => i.get("stateService"),
        deps: ["$injector"]
    },
    {
        provide: "toolService",
        useFactory: i => i.get("toolService"),
        deps: ["$injector"]
    },
    {
        provide: "variantService",
        useFactory: i => i.get("variantService"),
        deps: ["$injector"]
    },
    {
        provide: "viewService",
        useFactory: i => i.get("viewService"),
        deps: ["$injector"]
    },
    {
        provide: "iconService",
        useFactory: i => i.get("iconService"),
        deps: ["$injector"]
    },
    {
        provide: "fieldsetBuilderService",
        useFactory: i => i.get("fieldsetBuilderService"),
        deps: ["$injector"]
    },
    {
        provide: "treeAddonService",
        useFactory: i => i.get("treeAddonService"),
        deps: ["$injector"]
    },
    {
        provide: "$stateParams",
        useFactory: i => i.get("$stateParams"),
        deps: ["$injector"]
    },
    {
        provide: "$state",
        useFactory: i => i.get("$state"),
        deps: ["$injector"]
    },
    {
        provide: "$location",
        useFactory: i => i.get("$location"),
        deps: ["$injector"]
    },
    {
        provide: "formHelper",
        useFactory: i => i.get("formHelper"),
        deps: ["$injector"]
    },
    {
        provide: "formService",
        useFactory: i => i.get("formService"),
        deps: ["$injector"]
    },
    {
        provide: "progressbarService",
        useFactory: i => i.get("progressbarService"),
        deps: ["$injector"]
    },
    {
        provide: "autoCompleteService",
        useFactory: i => i.get("autoCompleteService"),
        deps: ["$injector"]
    },
    {
        provide: "workflowModelService",
        useFactory: i => i.get("workflowModelService"),
        deps: ["$injector"]
    },
    {
        provide: "rightGroupsAddonService",
        useFactory: i => i.get("rightGroupsAddonService"),
        deps: ["$injector"]
    },
    {
        provide: "autoCompleteService",
        useFactory: i => i.get("autoCompleteService"),
        deps: ["$injector"]
    },
    {
        provide: "gridContentService",
        useFactory: i => i.get("gridContentService"),
        deps: ["$injector"]
    },
    {
        provide: "gridContentUtilsService",
        useFactory: i => i.get("gridContentUtilsService"),
        deps: ["$injector"]
    },
    {
        provide: "staticColumnConfig",
        useFactory: i => i.get("staticColumnConfig"),
        deps: ["$injector"]
    },
    {
        provide: "formValidationService",
        useFactory: i => i.get("formValidationService"),
        deps: ["$injector"]
    },
    {
        provide: "validationApiService",
        useFactory: i => i.get("validationApiService"),
        deps: ["$injector"]
    },
    {
        provide: "touchHandlerService",
        useFactory: i => i.get("touchHandlerService"),
        deps: ["$injector"]
    },
    {
        provide: "mimeTypes",
        useFactory: i => i.get("mimeTypes"),
        deps: ["$injector"]
    },
    {
        provide: "fileOpenService",
        useFactory: i => i.get("fileOpenService"),
        deps: ["$injector"]
    },
    {
        provide: "$cordovaFile",
        useFactory: i => i.get("$cordovaFile"),
        deps: ["$injector"]
    },
    {
        provide: "$transitions",
        useFactory: i => i.get("$transitions"),
        deps: ["$injector"]
    },
    {
        provide: "$translate",
        useFactory: i => i.get("$translate"),
        deps: ["$injector"]
    },
    {
        provide: "updateUtilService",
        useFactory: i => i.get("updateUtilService"),
        deps: ["$injector"]
    },
    {
        provide: "updateService",
        useFactory: i => i.get("updateService"),
        deps: ["$injector"]
    },
    {
        provide: "hitlistConfigService",
        useFactory: i => i.get("hitlistConfigService"),
        deps: ["$injector"]
    },
    {
        provide: "documentViewer",
        useFactory: i => i.get("documentViewer"),
        deps: ["$injector"]
    },
    {
        provide: "desktopService",
        useFactory: i => i.get("desktopService"),
        deps: ["$injector"]
    },
    {
        provide: "externalTrayService",
        useFactory: i => i.get("externalTrayService"),
        deps: ["$injector"]
    },
    {
        provide: "pdfjsViewerService",
        useFactory: i => i.get("pdfjsViewerService"),
        deps: ["$injector"]
    },
    {
        provide: "officeBackendService",
        useFactory: i => i.get("officeBackendService"),
        deps: ["$injector"]
    },
    {
        provide: "wopiHostService",
        useFactory: i => i.get("wopiHostService"),
        deps: ["$injector"]
    },
    {
        provide: "dvDocumentService",
        useFactory: i => i.get("dvDocumentService"),
        deps: ["$injector"]
    },
    {
        provide: "layoutManagerService",
        useFactory: i => i.get("layoutManagerService"),
        deps: ["$injector"]
    },
    {
        provide: "locationService",
        useFactory: i => i.get("locationService"),
        deps: ["$injector"]
    },
    {
        provide: "backendSearchService",
        useFactory: i => i.get("backendSearchService"),
        deps: ["$injector"]
    },
    {
        provide: "backendObjectService",
        useFactory: i => i.get("backendObjectService"),
        deps: ["$injector"]
    },
    {
        provide: "backendFavoritesService",
        useFactory: i => i.get("backendFavoritesService"),
        deps: ["$injector"]
    },
    {
        provide: "backendStoredQueriesService",
        useFactory: i => i.get("backendStoredQueriesService"),
        deps: ["$injector"]
    },
    {
        provide: "formControlService",
        useFactory: i => i.get("formControlService"),
        deps: ["$injector"]
    },
    {
        provide: "formGridBuilder",
        useFactory: i => i.get("formGridBuilder"),
        deps: ["$injector"]
    }
];
