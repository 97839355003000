import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";

require("SERVICES_PATH/eob.search.srv.js");
require("SERVICES_PATH/eob.environment.srv.js");
require("SERVICES_PATH/utils/eob.cache.manager.srv.js");

const he = require("he");

angular.module("eob.framework").directive("eobModalExport", EobModalExport);

EobModalExport.$inject = ["$q", "$timeout", "searchService", "valueUtilsService", "sortService", "environmentService", "objectTypeService",
    "$filter", "progressbarService", "dmsContentService", "clientService", "offlineCacheService","fieldsetBuilderService", "cacheManagerService", "notificationsService", "messageService"];

// eslint-disable-next-line max-params
function EobModalExport($q, $timeout, SearchService, ValueUtilsService, SortService, EnvironmentService, ObjectTypeService,
                        $filter, ProgressbarService, DmsContentService, ClientService, OfflineCacheService,FieldsetBuilderService, CacheManagerService, NotificationsService, MessageService) {
    return {
        replace: true,
        scope: {
            dmsDocuments: "=items"
        },
        link(scope, element) {
            // the csv content gets bound to a variable inside a button
            let csvContent = "";
            let exportDocs = [];
            let delimiter = EnvironmentService.env.export.delimiter;
            let quote = EnvironmentService.env.export.quote;
            let exportHeader = EnvironmentService.env.export.exportHead;
            let skipFields = ["static", "pagecontrol", "grid", "group", "button"];

            let progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0]),
                progressStep = 100 / scope.dmsDocuments.length;

            let cabinetId,
                objectTypeId,
                isCabinet;

            let configuredFieldsMap = {},
                allFields = [];

            // the ng-model part used for the filename
            scope.exportFile = {
                name: "export"
            };
            if (scope.dmsDocuments.length === 1) {
                scope.exportFile.name = scope.dmsDocuments[0].api.buildNameFromIndexData(5, true, true);
            }

            scope.exportFile.name === "" && (scope.exportFile.name = "export");

            scope.availableItems = [];
            scope.gridConfig;
            scope.multiselectOptions = {
                keepSequence: true
            };

            scope.ready = false;
            scope.showProgressbar = scope.dmsDocuments.length > 2;

            scope.close = () => MessageService.broadcast(ModalEvents.DESTROY);
            scope.downloadContent = downloadContent;

            scope.showAltLang = !EnvironmentService.uiLangIsObjectDefLang();
            scope.objectDefLang = EnvironmentService.getObjectDefinitionLanguage();

            init();

            function init() {
                if (!Array.isArray(scope.dmsDocuments)) {
                    scope.dmsDocuments = [scope.dmsDocuments];
                }

                let docModel = scope.dmsDocuments[0].model;

                cabinetId = docModel.cabinetId;

                if (cabinetId === void 0 && docModel.parentCabinetKeyType === "INTERNAL_NAME") {
                    cabinetId = CacheManagerService.objectTypes.getBy("config.internal", docModel.parentCabinetKey).model.osid;
                }

                objectTypeId = docModel.objectTypeId;
                isCabinet = docModel.isFolder;

                getFields();
                setListConfig();
                setInitialFocus();

                setTimeout(()=>{
                    initProgressBar();
                }, 0);
            }

                function setListConfig() {
                    const getDefaultGridConfig = () => ({
                        columnDefs: [{
                            headerName: "",
                            field: "icon",
                            width: 20,
                            sortable: false,
                            cellRenderer: params => params.value
                        }, {
                            headerName: $filter("translate")("modal.export.available.fields"),
                            field: "title",
                            sortable: true
                        }]
                    });

                const rightConfig = getDefaultGridConfig();
                Object.assign(rightConfig.columnDefs[1], {
                    headerName: $filter("translate")("modal.export.fields.to.export")
                });

                scope.gridConfig = { leftConfig: getDefaultGridConfig(), rightConfig };
            }

            async function getFields() {
                let documentPromise = getObjectFields(objectTypeId);
                let cabinetPromise = getCabinetFields();

                const res = await Promise.all([documentPromise, cabinetPromise]);
                let cabinetCounter = 0;

                for (let i = 1; i >= 0; i--) {
                    let data = res[i];

                    for (const internal in data.configuredFields) {
                        const field = data.configuredFields[internal];

                        if (i === 1) {
                            cabinetCounter++;
                        } else {
                            field.position += cabinetCounter;
                        }

                        field.isCabinetField = i === 1;
                        configuredFieldsMap[getFieldKey(field)] = field;
                    }

                    for (const internal in data.allFields) {
                        const field = data.allFields[internal];
                        field.isCabinetField = i === 1;
                        allFields.push(field);
                    }
                }
                addFields();
            }

            function getCabinetFields() {
                // if the export item is a cabinet itself, it won't have additional configured cabinet fields
                if (ClientService.isOffline() || isCabinet) {
                    let deferred = $q.defer();
                    deferred.resolve({
                        configuredFields: {},
                        allFields: {}
                    });
                    return deferred.promise;
                }

                return getObjectFields(cabinetId, true);
            }

            function getObjectFields(typeId, areCabinetFields) {
                let deferred = $q.defer();
                let promise = deferred.promise;

                let response = {
                    configuredFields: {},
                    allFields: {}
                };

                let cabinetIconTitle = $filter("translate")("modal.export.icon.folder");
                let documentIconTitle = $filter("translate")("modal.export.icon.document");
                let cabinetFieldIcon = `<div title="${cabinetIconTitle}"><i class='icon-16-OT-Archive-dark'></i></div>`;
                let documentFieldIcon = `<div title="${documentIconTitle}"><i class='icon-16-document'></i></div>`;
                let typeDef = CacheManagerService.objectTypes.getById(typeId);

                let allTypeFields = typeDef.api.getFlatFieldList();

                allTypeFields.forEach((field) => {
                    if (isValidField(field) && !field.isInvisibleField && !field.isBaseParam) {
                        field = prepareField(allTypeFields, field);
                        field = angular.copy(field);

                        field.icon = areCabinetFields ? cabinetFieldIcon : documentFieldIcon;

                        response.allFields[field.internal] = field;
                    }
                });

                let tmpConfiguredFields = areCabinetFields ? typeDef.api.getConfiguredBaseParams(typeDef, ObjectTypeService.getObjectType(objectTypeId)) : typeDef.api.getConfiguredFields();
                for (let tmpName in tmpConfiguredFields) {
                    if (response.allFields[tmpName]) {
                        let tmp = response.allFields[tmpName];
                        tmp.position = tmpConfiguredFields[tmpName].position;
                        response.configuredFields[tmpName] = tmp;
                    }
                }

                deferred.resolve(response);
                return promise;
            }

            function addFields() {
                let selectedItems = [],
                    unselectedCabFields = [],
                    unselectedIndexdataFields = [];

                for (let i = 0; i < allFields.length; i++) {
                    let field = allFields[i];
                    let configuredField = configuredFieldsMap[getFieldKey(field)];

                    if (configuredField == void 0) {
                        if (field.isCabinetField) {
                            unselectedCabFields.push(field);
                        } else {
                            unselectedIndexdataFields.push(field);
                        }
                    } else {
                        configuredField.selected = true;
                        selectedItems.push(configuredField);
                    }
                }

                SortService.sortAlphanumericByKey(unselectedCabFields, "title");
                SortService.sortAlphanumericByKey(unselectedIndexdataFields, "title");

                scope.availableItems = [].concat(selectedItems, unselectedCabFields, unselectedIndexdataFields);

                scope.ready = true;
            }

            async function downloadContent() {
                if(ClientService.isOnline()) {
                    getContent();
                } else {
                    let isIndexDataCached = await OfflineCacheService.isObjectOfflineCached(scope.dmsDocuments[0].model.osid);
                    if(!isIndexDataCached) {
                        NotificationsService.info($filter("translate")("eob.message.offline.function.disabled"));
                        return
                    } else {
                        getContent();
                    }
                }
            }

            /**
             * Build and download a csv file with the requested data.
             * @returns {Promise<void>} Resolved once the
             */
            async function getContent() {
                scope.isLoading = true;
                progressBar.show();

                try {
                    await getExportData();
                    buildCSV();
                    await downloadCSV();
                } catch (error) {
                    NotificationsService.error($filter("translate")("eob.modal.indexdata.export.failed"));
                    throw error;
                } finally {
                    progressBar.hide();
                    scope.isLoading = false;
                }

                scope.close();
            }

            /**
             * Get the dms objects with the necessary field values that shall be exported .
             *
             * @returns {Promise} The search promise for all dms objects.
             */
            function getExportData() {
                let searchPromises = [];
                let queryObject = createSearchQuery();

                angular.forEach(scope.dmsDocuments, async (exportDmsDocument) => {
                    let searchPromise = (async() => {
                        let result;

                        // indexdata of inactive variants has to be researched differently
                        if (exportDmsDocument.model.variantData != void 0 && !exportDmsDocument.model.variantData[0].model.isActive) {
                            result = (await getInactiveExportItemPromise(exportDmsDocument, queryObject)).data;
                        } else if (ClientService.isOnline()) {
                            let itemQuery = angular.copy(queryObject);
                            itemQuery.query.osid = exportDmsDocument.model.osid;

                            result = await SearchService.executeQuery(itemQuery);
                        } else {
                            result = [(await OfflineCacheService.getById(exportDmsDocument.model.osid)).model];
                        }

                        exportDocs.push(result);
                        progressBar.addPercent(progressStep);
                    })();
                    searchPromises.push(searchPromise);
                });

                return $q.all(searchPromises);
            }

            /**
             * Create a template for a search query by id for one dms object with the requested fields and parent fields.
             *
             * @returns {Object} The search query template.
             */
            function createSearchQuery() {
                let fieldsschema = [];
                let parentFieldsschema = [];
                let selectedContent = scope.availableItems.filter(entry => entry.selected);

                //Create fieldschema
                for (let i = 0; i < selectedContent.length; i++) {
                    let item = selectedContent[i];
                    if (item.isCabinetField) {
                        parentFieldsschema.push({dbName: item.dbname});
                    } else {
                        fieldsschema.push({dbName: item.dbname});
                    }
                }

                let queryObject = {
                    query: {
                        osid: "",
                        objectTypeId,
                        fields: {},
                        baseparams: {},
                        result_config: {
                            fieldsschema
                        }
                    }
                };

                if (parentFieldsschema.length > 0 && !isCabinet) {
                    queryObject.query.result_config.parents = [
                        {
                            objectTypeId: cabinetId,
                            fieldsschema: parentFieldsschema
                        }
                    ]
                }

                return queryObject;
            }

            /**
             * Get a promise that is resolved with the necessary indexdata of a inactive dms variant and its parent fields.
             *
             * @param {Object} exportItem - The item which indexdata shall be exported.
             * @param {Object} queryObject - A query template to request parent fields.
             * @returns {Promise} The search promise.
             */
            async function getInactiveExportItemPromise(exportItem, queryObject) {
                let inactiveExportItemPromises = [];

                // get inactive variant index data
                inactiveExportItemPromises.push(SearchService.searchById(exportItem.model.osid, objectTypeId, true));

                // get parent fields separately
                if (queryObject.query.result_config.parents) {
                    let itemQuery = angular.copy(queryObject);
                    itemQuery.query.osid = exportItem.id;
                    itemQuery.query.result_config.fieldsschema = [];

                    inactiveExportItemPromises.push(SearchService.executeQuery(itemQuery));
                }

                // combine them
                const res = await Promise.all(inactiveExportItemPromises)
                let inactiveVariantData = res[0],
                    parentData = res[1];

                if (parentData != void 0 && parentData[0] != void 0) {
                    inactiveVariantData.ecmParentFields = parentData[0].ecmParentFields;
                }

                return {data: [inactiveVariantData]};
            }

            function buildCSV() {
                const selectedItems = scope.availableItems.filter(entry => entry.selected);
                csvContent = "";

                if (exportHeader) {
                    for (let index = 0; index < selectedItems.length; index++) {
                        csvContent += selectedItems[index].title + delimiter;
                    }
                    csvContent += "\n";
                }

                for (let i = 0; i < exportDocs.length; i++) {
                    let data = exportDocs[i],
                        parentDataMap = {},
                        indexDataMap = {};

                    if (data.length == 0) {
                        continue;
                    } else {
                        data = data[0];
                    }

                    if (data.ecmParentFields) {
                        for (let l = 0; l < data.ecmParentFields.length; l++) {
                            let parentDataField = data.ecmParentFields[l];
                            parentDataMap[parentDataField.internalName] = parentDataField.value;
                        }
                    } else {
                        parentDataMap = data.parentFields;
                    }

                    if (data.ecmSimpleFields) {
                        for (let j = 0; j < data.ecmSimpleFields.length; j++) {
                            let indexDataField = data.ecmSimpleFields[j];
                            indexDataMap[indexDataField.internalName] = indexDataField.value;
                        }
                    } else {
                        indexDataMap = data.fields;
                    }

                    for (let inner = 0; inner < selectedItems.length; inner++) {
                        let field = selectedItems[inner],
                            internal = field.internal;

                        let value = field.isCabinetField ? parentDataMap[internal] : indexDataMap[internal];

                        if (value == void 0) {
                            value = "";
                        } else if (field.hasLeadingZeros) {
                            let length = field.maxLength;
                            value = value.length >= length ? value : new Array(length - value.length + 1).join("0") + value;
                        } else if (field.type === "date" || field.type === "datetime") {
                            value = ValueUtilsService.dateToLocaleDate(value);
                        } else if (value && field.type === "decimal") {
                            value = ValueUtilsService.numberToLocaleNumber(value, ".2-2");
                        } else if (field.type === "radio" && field.fields) {
                            for (let counter = 0; counter < field.fields.length; counter++) {
                                if (field.fields[counter].name === value) {
                                    value = counter.toString();
                                    break;
                                }
                            }
                        } else if (field.isUnicode) {
                            value = he.decode(value)
                        }

                        csvContent += replaceIllegalChars(value) + delimiter;
                    }
                    csvContent += "\n";
                }

                csvContent = csvContent.trim();
            }

            async function downloadCSV() {
                // adding a universal BOM (Byte order Marker) to force Excel to read the csv as UTF-8 and not ANSI
                // DODO-7615 - See https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
                let universalBOM = "\uFEFF";

                let blob = new Blob([`${universalBOM}${csvContent}`], {type: "text/csv;charset=utf-8"});
                await DmsContentService.customSaveAsAsync(blob, scope.exportFile.name === "" ? "export.csv" : `${scope.exportFile.name}.csv`);
            }

            function prepareField(fieldCollection, field) {
                let internal = field.internal;

                // special condition --> we now found a value meant for a radiogroup INSIDE a groupbox
                // this is total bullshit that the field has the internal name of the group surrounding it but however ...
                // I mean, WHY would you give a field the internal name of its surrounding group box when the server needs the internal
                // of the field and has no clue what this groupbox is !?
                if (field.type === "RADIO") {
                    for (let i = 0; i < fieldCollection.length; i++) {
                        let tmp = fieldCollection[i];
                        if (tmp.type === "radio" && (tmp.groupInternal === internal || (tmp.groupInternal === void 0 && tmp.internal === internal))) {
                            // now we found the right field .. *facepalm*
                            field = fieldCollection[i];
                            break;
                        }
                    }
                }

                return field;
            }

            function getFieldKey(field) {
                return `${field.internal}-${field.isCabinetField}`;
            }

            function replaceIllegalChars(value) {
                let re = new RegExp(quote, "g");
                value = value.replace(re, quote + quote);
                value = quote + value + quote;
                return value;
            }

            function isValidField(field) {
                return (skipFields.indexOf(field.type) === -1) && (field.type != "radio" || field.isMasterRadio);
            }

            function initProgressBar() {
                let containerElement = element[0].getElementsByClassName("modal-export-container")[0];
                containerElement.style.minHeight = `${containerElement.offsetHeight}px`;
            }

            function setInitialFocus() {
                $timeout(() => {
                    element.find(".first-input").focus();
                }, 0);
            }
        },
        template: require("!raw-loader!./eob.modal.export.html")
    };
}
