import {Injectable, Injector} from "@angular/core";
import * as angular from "angular";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {
    AuthenticationData,
    AuthenticationType, AuthRequestHeader
} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {ElectronWindowInformation} from "INTERFACES_PATH/electron/electron-window-information.interface";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";

/**
 * Metadata persisted for checked out files
 */
export interface CheckedOutFile {
    docModel: any;
    fileName: string;
    filePath: string;
    hash: string;
    status?: string;
    originalHash?: string;
}

/**
 * Definition of a profile
 */
export interface Profile {
    url: string;
    userId?: number;
    username?: string;
    password?: string;
    key?: string; // TODO where is this property used?
    windowSize?: { [k: string]: number[] };
    windowPosition?: { [k: string]: number[] };
    checkedOutFiles?: CheckedOutFile[];
    autologin?: boolean;
    isDemo?: boolean;
    authType: AuthenticationType;
    authHeaders?: AuthRequestHeader[];
    /** @deprecated */
    windowslogin?: boolean;
}

/**
 * Definition of a profile initialization structure read from file "init-profiles.json"
 */
export interface InitProfile {
    activate: boolean; // whether to activate the new profile
    url: string;
    username: string;
    password: string;
    autologin: boolean;
    authType: AuthenticationType;
}

@Injectable({
    providedIn: "root"
})
export class ProfileService {
    private customStorage: CustomStorage;
    private currentProfile: Profile;
    private environmentService: TodoEnvironmentService;
    private fileCacheService: FileCacheService;
    private offlineCacheService: OfflineCacheService;

    constructor(customStorageService: CustomStorageService, private injector: Injector) {
        // get custom storage
        customStorageService.getStorage().subscribe(storage => {
            this.customStorage = storage;

            void this.adjustSecureItems();
        });

        // setTimeout is necessary, otherwise ProfileService is not available in ngOnInit of AppComponent
        setTimeout(() => {
            this.init();
        }, 100);
    }

    private init(): void {
        this.environmentService = this.injector.get("environmentService");
        this.fileCacheService = this.injector.get("fileCacheService");
        this.offlineCacheService = this.injector.get("offlineCacheService");
    }

    /**
     * Set (and return) the current profile either to last active profile or to empty object
     *
     * @returns {Profile} Last active profile or empty object
     */
    prepareCurrentProfile(): Profile {
        this.currentProfile = this.getProfiles().get(this.getLastActiveProfileKey()) || {} as Profile;
        return this.currentProfile;
    }

    /**
     * Get last active profile key from custom storage
     *
     * @return {string} - The last active profile key from custom storage
     */
    getLastActiveProfileKey(): string {
        return this.customStorage.getItem("lastActiveProfile");
    }

    /**
     * Get last active profile (or undefined)
     *
     * @return {Profile} - The last active profile (or undefined)
     */
    getLastActiveProfile(): Profile {
        return this.getProfiles().get(this.getLastActiveProfileKey());
    }

    /**
     * Get map with all profiles.
     *
     * @return {Map} - A map with all profiles. The map key is the unique profile key.
     */
    getProfiles(): Map<string, Profile> {
        try {
            const profiles: any = JSON.parse(this.customStorage.getItem("profiles"));
            if (Array.isArray(profiles)) {
                profiles.map(x => {
                    if (x[0].match(/@/g).length == 1) {
                        if (x[1].windowslogin) {
                            x[0] = `${x[0]}@${AuthenticationType.NTLM_SYSTEM}`;
                            x[1].authType = AuthenticationType.NTLM_SYSTEM;
                            delete x[1].windowslogin;
                        } else {
                            x[0] = `${x[0]}@${AuthenticationType.BASIC_AUTH}`;
                            x[1].authType = AuthenticationType.BASIC_AUTH;
                        }
                    }
                    return x;
                });
            }
            return new Map<string, Profile>(profiles);
        } catch (error) {
            return new Map<string, Profile>();
        }
    }

    /**
     * Check, if there are any profiles
     *
     * @return {boolean} - true, if there are any profiles, otherwise false
     */
    hasProfiles(): boolean {
        return this.getProfiles().size > 0;
    }

    /**
     * Builds the profile key based on origin and auth data
     *
     * @param {string} origin - The origin
     * @param {AuthenticationData} authData - The auth data
     * @return {string} - The profile key
     */
    buildProfileKey(origin: string, authData: AuthenticationData): string {
        return `${authData.username}@${origin}@${authData.authType}`;
    }

    /**
     * Builds the profile key based on the profile url, username and authType
     *
     * @param {Profile} profile - The profile
     * @return {string} - The profile key
     */
    getProfileKeyFromProfile(profile: Profile): string {
        return `${profile.username}@${profile.url}@${profile.authType}`;
    }

    /**
     * Saves a profile and activates it for further usage.
     *
     * @param {Profile} profile - The profile
     * @param {boolean} activateProfile - whether to activate the profile (default: true)
     */
    saveProfile(profile: Profile, activateProfile: boolean = true): void {
        const profiles: Map<string, Profile> = this.getProfiles();
        let profileKey: string = this.getProfileKeyFromProfile(profile);

        let oldProfile: Profile = profiles.get(profileKey);
        if (!oldProfile) {
            oldProfile = {} as Profile;
        }

        // create a new profile with an overlay of old profile and profile parameter
        let newProfile: Profile = Object.assign({}, oldProfile);
        if (profile) {
            newProfile = Object.assign(newProfile, profile);
        }

        // set authType for old profiles, which only have the windowslogin property
        if (newProfile.hasOwnProperty("windowslogin") || !newProfile.authType) {
            newProfile.authType = (newProfile as any).windowslogin ? AuthenticationType.NTLM_SYSTEM : AuthenticationType.BASIC_AUTH;
            profileKey = this.getProfileKeyFromProfile(newProfile);
        }

        // delete properties not allowed to be stored in config.json
        delete newProfile.password;
        delete (newProfile as any).windowslogin;
        delete (newProfile as any).basicAuth;
        delete (newProfile as any).authCookie;
        // delete newProfile.userId;
        profiles.set(profileKey, newProfile);

        if (activateProfile) {
            this.currentProfile = newProfile;
            this.customStorage.setItem("lastActiveProfile", profileKey);
        }

        this.customStorage.setItem("profiles", angular.toJson([...profiles]));
    }

    /**
     *  Deletes the given profile
     *
     * @param {Profile} profile - The profile to be deleted
     */
    async deleteProfile(profile: Profile): Promise<any> {
        if (!profile) {
            console.error("no profile");
            return;
        }

        const profileKey: string = this.getProfileKeyFromProfile(profile);
        const profiles: Map<string, Profile> = this.getProfiles();
        const profileToDelete: Profile = profiles.get(profileKey);
        if (!profileToDelete) {
            console.error("profile to be deleted not found");
            return;
        }

        profiles.delete(profileKey);

        try {
            // if no secure storage element for the given id exists, the promise is rejected (at least on iOS)
            await this.customStorage.removeSecureItem(`pw@@@${profileKey}`);
        } catch (_) {
            // error ignored
        }

        try {
            await this.customStorage.removeSecureItem(`authcookie@@@${profileKey}`);
        } catch (_) {
            // error ignored
        }

        if (this.getLastActiveProfileKey() === profileKey) {
            this.customStorage.removeItem("lastActiveProfile");
        }

        await this.fileCacheService.deleteProfileDatabaseAsync(profileToDelete);

        await this.fileCacheService.initGlobalDb(profileToDelete);
        if (Array.from(profiles.values()).filter((p: Profile) => p.url == profileToDelete.url).length == 0) {
            await this.fileCacheService.deleteGlobalDatabase(profileToDelete);
        } else if (profileToDelete.userId) {
            this.offlineCacheService.init();
            await this.offlineCacheService.removeAllGlobalOfflineObjects(profileToDelete.userId);
        }

        this.customStorage.setItem("profiles", angular.toJson([...profiles]));
    }

    /**
     * Check automatic profile initialization
     *
     * @return {Promise<void>} - empty promise
     */
    async checkAutomaticProfileInitialization(): Promise<void> {
        if (!window.electron) {
            return;
        }

        // get initialization profiles read from file "init-profiles.json"
        const initProfiles: InitProfile[] = await window.electron.getInitProfiles();

        // build new profiles and save them
        for (const initProfile of initProfiles) {
            const newProfile: any = {};
            Object.assign(newProfile, initProfile);
            delete newProfile.activate;
            this.saveProfile(newProfile as Profile, initProfile.activate);
        }
    }

    /**
     * returns the current base url in case we are running as an electron or mobile application to reference files from inside the
     * application
     *
     * @returns {string} current base url
     */
    getCurrentBaseUrl(): string {
        return ((window.electron || window.cordova) ? this.prepareCurrentProfile().url : "");
    }

    /**
     * Get window sizes and positions for current profile
     * The parameter consists of an object containing two optional properties **windowSize** and **windowPosition**.
     * Both properties consist of an object having ``string`` index identifiers and a number array as a value (currently
     * representing x and y values).
     * For details regarding the typing representation, see https://basarat.gitbooks.io/typescript/docs/types/index-signatures.html
     *
     * @returns {{windowSize: { [k: string]: number[] }, windowPosition: { [k: string]: number[] }}}
     */
    getWindowInformation(): { windowSize?: { [k: string]: number[] }; windowPosition?: { [k: string]: number[] } } {
        const profile: Profile = this.prepareCurrentProfile();

        if (!profile) {
            return {windowSize: {} as { [k: string]: number[] }, windowPosition: {} as { [k: string]: number[] }};
        }

        return {windowSize: profile.windowSize, windowPosition: profile.windowPosition};
    }

    /**
     * Set window sizes and positions for current profile
     * The parameter consists of an object containing two optional properties **windowSize** and **windowPosition**.
     * Both properties consist of an object having ``string`` index identifiers and a number array as a value (currently
     * representing x and y values).
     * For details regarding the typing representation, see https://basarat.gitbooks.io/typescript/docs/types/index-signatures.html
     *
     * @param {ElectronWindowInformation} windowInformation
     */
    setWindowInformation(windowInformation: ElectronWindowInformation): void {
        const profile: Profile = this.prepareCurrentProfile();
        if (!profile.autologin && !sessionStorage.getItem("forceAutoLogin")) {
            return;
        }

        if (!profile.windowSize) {
            profile.windowSize = {};
        }

        if (!profile.windowPosition) {
            profile.windowPosition = {};
        }

        Object.assign(profile.windowSize, windowInformation.windowSize);
        Object.assign(profile.windowPosition, windowInformation.windowPosition);

        this.saveProfile(profile);
    }

    /**
     * Copies the list of all checked out dms objects. A copy because external consumers should not
     * modify our internal list.
     *
     * @returns {CheckedOutFile[]} List of checked out files containing DMS and file metadata
     */
    getCheckedOutObjects(): CheckedOutFile[] {
        const profile: Profile = this.prepareCurrentProfile();
        if (!profile.checkedOutFiles) {
            return [];
        }

        return angular.copy(profile.checkedOutFiles) ;
    }

    /**
     * Add a checked out object to the list of checked out files
     *
     * @param dmsDocument
     * @param {string} filePath Complete filesystem path
     * @param {ArrayBuffer} fileContent File contents
     */
    async addCheckedOutObject(dmsDocument: any, filePath: string, fileContent: ArrayBuffer): Promise<void> {
        const hash: string = await this.fileCacheService.sha256Async(fileContent, false);
        const splitPath: string[] = filePath.split(/[\\/]/);

        const profile: Profile = this.prepareCurrentProfile();
        const checkoutEntry: CheckedOutFile = {
            docModel: {
                id: dmsDocument.model.id,
                osid: dmsDocument.model.osid,
                objectTypeId: dmsDocument.model.objectTypeId,
                mainType: dmsDocument.model.mainType,
                baseParameters: {
                    locked: "SELF"
                }
            },
            fileName: splitPath.pop(),
            filePath: `${splitPath.join("/")}/`,
            hash
        } as CheckedOutFile;

        this.removeCheckedOutObjectInternal(dmsDocument.model.id, false); // at most one instance per id should exist
        profile.checkedOutFiles = Array.isArray(profile.checkedOutFiles) ? profile.checkedOutFiles : [];
        profile.checkedOutFiles.push(checkoutEntry);

        this.saveProfile(profile);
    }

    /**
     * Removes checked out file from list
     * Note: Only the list entry is removed, file contents have to be removed individually
     *
     * @param {string} id Id of the document to be removed
     * @returns {CheckedOutFile[]} Found and removed entries
     */
    removeCheckedOutObject(id: string): CheckedOutFile[] {
        return this.removeCheckedOutObjectInternal(id, true);
    }

    /**
     * removes a file by id from the checked out dms documents
     *
     * @param {string} id
     * @param {boolean} save
     * @returns {CheckedOutFile[]}
     */
    private removeCheckedOutObjectInternal(id: string, save: boolean): CheckedOutFile[] {
        const profile: Profile = this.prepareCurrentProfile();
        const found: CheckedOutFile[] = [];

        if (!profile.checkedOutFiles) {
            profile.checkedOutFiles = [];
        }

        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const i in profile.checkedOutFiles) {
            if (profile.checkedOutFiles[i].docModel.id == id) {
                found.push(profile.checkedOutFiles[i]);
                profile.checkedOutFiles.splice(i as any, 1);
            }
        }

        if (found.length > 0 && save) {
            this.saveProfile(profile);
        }

        return found;
    }

    /**
     * Update mechanism from 9.10.5 to 9.10.6
     * The structure of the secure item keys change from prefix@@@username@url to prefix@@@username@url@authtype.
     */
    private async adjustSecureItems(): Promise<void> {
        // undefined can't be parsed
        const profiles: any = JSON.parse(this.customStorage.getItem("profiles") ?? "{}");
        if (!Array.isArray(profiles)) {
            return;
        }

        for (const profileEntry of profiles) {
            const profileKey: string = profileEntry[0], profile: any = profileEntry[1];

            // profile entry with old key pattern without authentication type
            if (profileKey.match(/@/g).length == 1) {
                const authPrefix: string = profile.windowslogin ? "authcookie" : "pw",
                    oldKey: string = `${authPrefix}@@@${profileKey}`;

                let pwd: any;
                try {
                    pwd = await this.customStorage.getSecureItem(oldKey);
                } catch(error) { /* key not found */ }

                if (!pwd) {
                    continue;
                }

                profile.authType = profile.windowslogin ? AuthenticationType.NTLM_SYSTEM : AuthenticationType.BASIC_AUTH;
                await this.customStorage.removeSecureItem(oldKey);
                await this.customStorage.setSecureItem(`${authPrefix}@@@${this.getProfileKeyFromProfile(profile)}`, pwd);
            }
        }
    }
}
