import {Injectable} from "@angular/core";
import {ClientService} from "../client/client.service";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {ValueUtilsService} from "../utils/value-utils.service";

@Injectable({
    providedIn: "root"
})
export class LayoutManagerService {
    customStorage: CustomStorage;

    constructor(protected clientService: ClientService,
                protected valueUtilsService: ValueUtilsService) {
    }

    async initAsync() {
        this.customStorage = await this.clientService.getStorageAsync();
    }

    isTouchLayoutActive() {
        return this.clientService.isPhoneOrTablet() || (this.clientService.isTouchDevice() && this.valueUtilsService.parseBoolean(this.customStorage.getItem("touchLayoutState")));
    }

    setTouchLayoutState(state: boolean) {
        this.customStorage.setItem("touchLayoutState", state);
    }
}
