import {
    Component,
    Input,
    Inject,
    forwardRef,
    ElementRef,
    Renderer2,
    AfterViewInit,
    OnInit
} from "@angular/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {TodoEnvironmentService} from "INTERFACES_PATH/any.types";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";

@Component({
    selector: "eob-form-page-control",
    templateUrl: "./eob-form-page-control.component.html",
    styleUrls: ["./eob-form-page-control.component.scss"]
})

export class EobFormPageControlComponent implements AfterViewInit, OnInit {
    @Input() formhelper: any;
    @Input() formdata: any[];
    @Input() layoutFields: any[];
    @Input() layoutField: any[];
    @Input() field: any;
    @Input() formid: string;
    @Input() ismockform: boolean;

    tabClickHandler: any;
    header: HTMLElement;
    tabWrapper: HTMLElement;
    tabContainer: JQuery;
    tabScrollButtons: JQuery;
    scrollStep: number = 64;
    isScrollButtonVisible: boolean = false;
    showAltLang: boolean;
    objectDefLang: any;

    constructor(@Inject(forwardRef(() => ClientService)) public clientService: ClientService,
                @Inject(forwardRef(() => "environmentService")) public environmentService: TodoEnvironmentService,
                public messageService: MessageService,
                public renderer: Renderer2,
                public el: ElementRef,) {
    }

    ngOnInit(): void {
        this.showAltLang = !this.environmentService.uiLangIsObjectDefLang() && !this.ismockform;
        this.objectDefLang = this.environmentService.getObjectDefinitionLanguage();

        this.messageService.subscribe(FormEvent.HANDLE_PAGECONTROL_BUTTONS, () => {
            this.handleButtonVisibility.call(this);
        });
    }

    ngAfterViewInit(): void {
        this.addPageControlLogic.call(this);
        this.handleButtonVisibility.call(this);
    }

    persistLastOpenPage = (): void => {
        const defaultLastPages: any = {
            state: (/(state=)([0-9]+)/.exec(window.location.href))[2],
            data: {}
        };
        let lastPages: any;

        try {
            lastPages = JSON.parse(localStorage.getItem("tabPages"));
        } catch (err) {
            lastPages = defaultLastPages;
        }

        if (!lastPages || !lastPages.data || !lastPages.state || lastPages.state !== (/(state=)([0-9]+)/.exec(window.location.href))[2]) {
            lastPages = defaultLastPages;
        }
        lastPages.data[this.field.model.name] = this.field.model.activePageIndex;
        localStorage.setItem("tabPages", JSON.stringify(lastPages));
    };

    setPageVisible = (pageElement: JQuery, pageIndex: number): void => {
        const container: JQuery = pageElement.find(".page-control-page-content");
        const tabs: JQuery = pageElement.find(".page-control-tab");

        for (let i: number = 0; i < container.length; i++) {
            if (i == pageIndex) {
                const activeTab: HTMLElement = tabs[i];
                $(container[i]).show();
                $(tabs[i]).addClass("active");

                // if neccessary scroll to tab
                const scrollButtons: JQuery = pageElement.find(".tab-scroll-buttons");
                if (scrollButtons.css("display") != "none") {
                    if (!this.clientService.isTouchDevice()) {
                        let cMargin: string | number = this.tabContainer.css("margin-left");
                        cMargin = parseInt(cMargin.substring(0, cMargin.length - 2));
                        if (activeTab.offsetLeft + activeTab.offsetWidth > scrollButtons[0].offsetLeft) {
                            const overflowRight: string | number = activeTab.offsetLeft + activeTab.offsetWidth - scrollButtons[0].offsetLeft;
                            this.tabContainer.css("margin-left", `${cMargin - overflowRight}px`);
                        } else if (activeTab.offsetLeft < 0) {
                            this.tabContainer.css("margin-left", `${cMargin - activeTab.offsetLeft}px`);
                        }
                    }
                }
            } else {
                $(container[i]).hide();
                $(tabs[i]).removeClass("active");
            }
        }
    };

    getScrollPosition = (): number => {
        if (this.clientService.isTouchDevice()) {
            return $(this.tabWrapper).scrollLeft();
        } else {
            const cMargin: string = this.tabContainer.css("margin-left");
            if (cMargin.length) {
                return parseInt(cMargin.substring(0, cMargin.length - 2));
            } else {
                return 0;
            }
        }
    };

    scrollLeft = (): void => {
        let scrollPosition: number = this.getScrollPosition();

        // only scroll if necessary
        if (scrollPosition < 0) {
            scrollPosition += this.scrollStep;

            // don't scroll further than is necessary
            if (scrollPosition > 0) {
                scrollPosition = 0;
            }

            this.renderer.setStyle(this.tabContainer[0], "margin-left", `${scrollPosition}px`);
        }
        if (this.clientService.isTouchDevice()) {
            // only scroll if necessary
            if (scrollPosition > 0) {
                this.tabScrollButtons.find(".tab-scroll-left").removeClass("disabled");
                $(this.tabWrapper).scrollLeft(scrollPosition - this.scrollStep);
            } else {
                this.tabScrollButtons.find(".tab-scroll-left").addClass("disabled");
            }
        }
    };

    scrollRight = (): void => {
        let scrollPosition: number = this.getScrollPosition();
        const buttonWidth: number = this.tabScrollButtons.width();

        let visibleTabWidth: number,
            actualHeaderSpace: number,
            tabPadding: number = 8,
            buttonsMargin: number;

        if (this.isScrollButtonVisible) {
            // 60px for margin for page-control-buttons
            buttonsMargin = 60;
        } else {
            buttonsMargin = 0;
        }

        if (this.clientService.isTouchDevice()) {
            visibleTabWidth = $(this.tabContainer).width() + buttonsMargin - scrollPosition;
            // actualHeaderSpace = header.width() - buttonsMargin;

            // only scroll if necessary
            if (visibleTabWidth > $(this.header).width()) {
                scrollPosition += this.scrollStep;
                this.tabScrollButtons.find(".tab-scroll-right").removeClass("disabled");
                $(this.tabWrapper).scrollLeft(scrollPosition);
            } else {
                this.tabScrollButtons.find(".tab-scroll-right").addClass("disabled");
            }
        } else {
            visibleTabWidth = $(this.tabContainer).width() + scrollPosition;
            actualHeaderSpace = this.header.offsetWidth - buttonWidth - buttonsMargin;
            tabPadding = 8;

            // only scroll if necessary
            if (visibleTabWidth > actualHeaderSpace + tabPadding) {
                scrollPosition -= this.scrollStep;

                // don't scroll further than is necessary
                if (visibleTabWidth < actualHeaderSpace + this.scrollStep) {
                    scrollPosition = actualHeaderSpace - $(this.tabContainer).width();
                }

                this.renderer.setStyle(this.tabContainer[0], "margin-left", `${scrollPosition}px`);
            }
        }
    };

    handleButtonVisibility = (): void => {
        const buttonWidthBuffer = 16;
        const scrollPosition: number = this.getScrollPosition();
        const visibleTabWidth: number = this.tabContainer.width() + scrollPosition;

        const rightScrollNeeded: boolean = visibleTabWidth >= this.header.offsetWidth + buttonWidthBuffer,
            leftScrollNeeded: boolean = scrollPosition < 0;
        if (!this.isScrollButtonVisible && (leftScrollNeeded || rightScrollNeeded)) {
            this.renderer.setStyle(this.tabScrollButtons[0], "display", "block");
            this.renderer.setStyle(this.tabWrapper, "margin", "0 30px");
            this.isScrollButtonVisible = true;
        } else if (this.isScrollButtonVisible && !leftScrollNeeded && !rightScrollNeeded) {
            this.renderer.setStyle(this.tabScrollButtons[0], "display", "none");
            this.renderer.setStyle(this.tabWrapper, "margin", 0);
            this.isScrollButtonVisible = false;
        }
    };

    refresh = (widthDif: number): void => {
        if (this.clientService.isTouchDevice()) {
            const activeTab: JQuery = this.el.nativeElement.querySelector(".page-control-tab.active");
            // 30px for margin in page-control-tabs
            if (activeTab[0]) {
                $(this.tabWrapper).scrollLeft(activeTab[0].offsetLeft - 30);
            }
        } else {
            let scrollPosition: number = this.getScrollPosition();

            const visibleTabWidth: number = this.tabContainer.width() + scrollPosition;

            if (widthDif > 0 && scrollPosition < 0) {
                const buttonWidth: number = this.tabScrollButtons.width();

                // first show what is overflowing right, then left
                const scrollBuffer: number = visibleTabWidth + buttonWidth - this.header.offsetWidth;
                if (scrollBuffer > 0) {
                    widthDif -= scrollBuffer;
                }

                if (widthDif > 0) {
                    // don't scroll further than is necessary
                    scrollPosition += (widthDif > Math.abs(scrollPosition)) ? -scrollPosition : widthDif;
                    this.renderer.setStyle(this.tabContainer[0], "margin-left", `${scrollPosition}px`);
                }
            }
        }
        this.handleButtonVisibility();
    };

    addPageControlLogic(): void {
        this.header = this.el.nativeElement.querySelector(".page-control-header");
        this.tabContainer = $(this.el.nativeElement.querySelector(".page-control-tabs"));
        this.tabWrapper = this.el.nativeElement.querySelector(".page-control-wrapper");
        this.tabScrollButtons = $(this.el.nativeElement.querySelector(".tab-scroll-buttons"));

        this.field.api.setPageActive = (index: number): void => {
            this.field.model.activePageIndex = index;
            this.setPageVisible(this.field.api.getElement(), index);
            setTimeout(() => {
                this.persistLastOpenPage();
            }, 0);
        };

        this.field.api.setPageActive("0");

        setTimeout(() => {
            let lastPages: any;
            try {
                lastPages = JSON.parse(localStorage.getItem("tabPages"));
            } catch (err) {
            }
            if (lastPages && lastPages.data && lastPages.state && lastPages.state === (/(state=)([0-9]+)/.exec(window.location.href))[2]) {
                for (const x in lastPages.data) {
                    if (this.field.model.name === x && Number.isFinite(lastPages.data[x])) {
                        this.field.api.goToPage(lastPages.data[x]);
                    }
                }
            } else {
                localStorage.removeItem("tabPages");
            }
        }, 0);

        this.formhelper.onFormResize((widthDif) => {
            this.refresh(widthDif);
        });

        addScrollButtonClickHandler($(this.el.nativeElement.querySelector(".tab-scroll-left .left")), this.scrollLeft);
        addScrollButtonClickHandler($(this.el.nativeElement.querySelector(".tab-scroll-right .right")), this.scrollRight);

        setTimeout(() => {
            this.refresh(0);
        }, 200);

        function addScrollButtonClickHandler(element: any, fn: () => void): void {
            let timeoutId: NodeJS.Timer, intervalId: NodeJS.Timer;
            element.bind("mousedown", (e) => {
                fn();

                // keep scrolling if the mouse is being pressed
                timeoutId = setTimeout(() => {
                    intervalId = setInterval(fn, 80);
                }, 250);

                // disable browser image draging
                e.preventDefault();

            }).bind("mouseup mouseleave dragend", () => {
                clearTimeout(timeoutId);
                clearInterval(intervalId);
            });
        }

        this.tabClickHandler = (e): void => {
            e = $(e.target);

            const tab: any = e.hasClass("page-control-tab") ? e : e.closest(".page-control-tab");
            const pageIndex = Number(tab.attr("data-index"));

            this.field.api.goToPage(pageIndex, true);
            this.setPageVisible(tab, pageIndex);
        };

        this.tabContainer.on("keydown", (event) => {
            const val: number = this.field.model.activePageIndex;
            const count: number = this.field.model.pages.length;
            let next: string | number;

            // return at this point if the target is not the pagecontrol
            // the control gets the focus once and thinks it is active till the end of time -.-"
            if (!$(event.target).is("ul")) {
                return;
            }

            if (event.which != 9) {
                event.preventDefault();
            }

            // arrow right
            if (event.which == 39) {
                if (count - 1 == val) {
                    return;
                }
                next = val == void 0 ? 0 : val + 1;
                this.field.api.goToPage(next, false);
                // arrow left
            } else if (event.which == 37) {
                if (val == 0) {
                    return;
                }
                next = val == void 0 ? 0 : val - 1;
                this.field.api.goToPage(next);
            }
        });
    }
}