<eob-radio-button
    [masterInternal]="field.model.masterRadioInternal"
    [toggleable]="!!formhelper.isSearch"
    [title]="field.model.title"
    [field]="field"
    [fieldid]="fieldid"
    [formid]="formid"
    [formhelper]="formhelper"
    [tooltip]="field.model.tooltip"
    [hideLabel]="field.model.isInvisibleText"
    [control]="field.model.control"
    [radioGroupControls]="field.model.radioGroupControls"
    [masterRadioControl]="field.model.masterRadioControl"
    [isMasterRadio]="field.model.isMasterRadio"
    [langAttribute]="showAltLang ? objectDefLang : null"
    (manualValueChange)="executeOnChange()"
>
</eob-radio-button>
