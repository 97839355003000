import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges
} from "@angular/core";
import {FormField} from "MODULES_PATH/form/interfaces/form.interface";
import {EobInputFormControl} from "MODULES_PATH/form/components/inputs/eob-input-form-control.model";
import {FormInputValue} from "MODULES_PATH/form/interfaces/form-value.types";
import {FieldMask, FieldMaskValue} from "MODULES_PATH/form/models/field-mask.model";
import {TranslateFnType} from "CLIENT_PATH/custom.types";

@Directive({
    selector: "[fieldMask]"
})
export class FieldMaskDirective implements AfterViewInit, OnInit, OnChanges {
    /* remains for DODO-13752 enable/disable */
    @Input() field: FormField;
    @Input() formControl: EobInputFormControl;
    @Input("fieldMask") masks: FieldMask[] = [];

    private initialised: boolean = false;
    private isFocused: boolean = false;
    private lastValue: any;
    translateFn: TranslateFnType;

    constructor(private el: ElementRef, private renderer: Renderer2, @Inject("$filter") $filter: ng.IFilterService) {
        this.translateFn = $filter("translate");
    }

    ngOnInit(): void {
        this.bindEvents();
    }

    ngAfterViewInit(): void {
        if (this.masks?.length) {
            setTimeout(() => {
                this.formatValue();
            }, 0);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.masks && !changes.masks.firstChange) {
            this.bindEvents();
        }
    }

    private bindEvents() {
        // only bind events if necessary
        if (!this.initialised && this.masks?.length) {
            this.initialised = true;

            const element: HTMLElement = this.el.nativeElement;
            // keep in mind - angular change host listener can't receive all change events
            element.onchange = (event: CustomEvent) => this.onChange(event);
        }
    }

    @HostListener("blur")
    private onBlur(): void {
        if (this.initialised) {
            this.formatValue();
        }
    }

    private onChange(event?: CustomEvent): void {
        // stop the onChange event that precedes the onBlur event
        // we will trigger a new one with the formatted value of the onBlur event
        if (this.isFocused) {
            event?.stopPropagation();
            event?.stopImmediatePropagation();
            return;
        }

        // format the value, except if the onChange event was triggered by this directive
        if (event?.detail?.format !== false) {
            this.formatValue();
        }
    }

    @HostListener("focus")
    private onFocus(): void {
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (this.field && !this.field.api?.isEnabled()) {
            // attention: do not use !this.field? here

            /* remains for DODO-13752 enable/disable */
            return;
        }

        this.isFocused = true;

        let rendererValue: FormInputValue = this.formControl.value;
        for (const mask of this.masks) {
            rendererValue = mask.formatFocus(rendererValue);
        }

        this.updateRenderedValue(rendererValue);
    }

    private formatValue(): void {
        this.isFocused = false;

        // in decimal fields field control value changes on focus and on blur (thousand separators) so we can't rely on this check
        // if (this.formControl.value == this.lastValue || this.formControl.value === "") {
        //     return;
        // }

        let maskValue: FieldMaskValue = {rendererValue: this.formControl.value, value: this.formControl.value};
        for (const mask of this.masks) {
            maskValue = mask.formatBlur(maskValue);
            if (maskValue.invalid) {
                break;
            }
        }
        if (!maskValue.invalid) {
            this.formControl.setValue(maskValue.value);
            this.formControl.updateValueAndValidity();
        } else {
            this.formControl.setErrors({
                customError: {
                    message: this.translateFn("eob.validation.failed.warn")
                }
            });
        }

        this.isFocused = false;
        // Dispatch an official event, that can be listened to. E.g. for the onChange script.
        this.el.nativeElement.dispatchEvent(new CustomEvent("change", {detail: {format: false}}));

        // set rendererValue after all "onChange" scripts to avoid displaying an incorrect value e.g. in decimal grid cells on "blur" event
        if (!maskValue.invalid) {
            this.updateRenderedValue(maskValue.rendererValue);
        }

        this.lastValue = this.formControl.value;
    }

    /** Update the displayed value without triggering a control or html element change event. */
    private updateRenderedValue(value: FormInputValue): void {
        if (this.el.nativeElement.value != value) {
            this.renderer.setProperty(this.el.nativeElement, "value", value || "");
        }
    }
}
