import Dexie from "dexie";
import {OfflineDataStore} from "MODELS_PATH/eob.offline.data.model";
import {ProfileStore} from "INTERFACES_PATH/database/database-store.interface";
import {DatabaseType} from "ENUMS_PATH/database/database-type.enum";
import {CustomDatabase} from "./custom-database.model";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {ObjectSyncStatus} from "ENUMS_PATH/offline/offline-object-sync-status.enum";
import {ObjectSyncFailedStatus} from "ENUMS_PATH/offline/offline-object-sync-failed-status.enum";

/**
 * Class for the profile specific database
 */
export class ProfileDatabase extends CustomDatabase {
    offlineData!: Dexie.Table<OfflineDataStore, string>;
    persistent!: Dexie.Table<ProfileStore<any>, string>;
    temporary!: Dexie.Table<ProfileStore<any>, string>;

    constructor(dbName: string, clientService: ClientService) {
        super(dbName || DatabaseType.PROFILE, clientService);
        this.version(1).stores({
            persistent: "[key+group], key, group, timestamp",
            temporary: "[key+group], key, group, timestamp"
        });
        this.version(2).stores({
            offlineData: "&osid, objectTypeId, sync, *favReferences, *children",
            persistent: "[key+group], key, group, timestamp",
            temporary: "[key+group], key, group, timestamp"
        });
        this.version(3).stores({
            offlineData: "&osid, objectTypeId, sync, *favReferences, *children",
            persistent: "[key+group], key, group, timestamp",
            temporary: "[key+group], key, group, timestamp"
        }).upgrade(tx => tx.table("offlineData").toCollection().modify(data => {
                // extract failed states into their own property
                if (data.sync > 3) {
                    data.failedState = data.sync - 3;
                    data.sync = ObjectSyncStatus.FAILED;
                } else {
                    data.failedState = ObjectSyncFailedStatus.NONE;
                }
            }));

        this.offlineData.mapToClass(OfflineDataStore);
    }
}