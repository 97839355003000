import {Injectable} from "@angular/core";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {Group, User} from "INTERFACES_PATH/user.interface";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {cloneDeep} from "lodash";
import {GroupUser} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-organisation-groups-result.interface";
import {OsrestWorkflowOrganisationResult, OrgNode} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-workflow-organisation-result.interface";
import { WfOrgPerformer } from "MODULES_PATH/modal-dialog/interfaces/substitutes-config.interface";
@Injectable({
    providedIn: "root"
})
export class OrganisationService {

    private readonly organisationUsers: User[] = [];
    private readonly organisationGroups: Array<Group<GroupUser>> = [];
    private readonly wfPerformers = new Map<string, WfOrgPerformer>();
    private wfOrganisation: OsrestWorkflowOrganisationResult = {};

    constructor(private httpService: HttpService) {}

    fetchOrganisationUsers(): Observable<User[]> {
        return this.httpService.getOrganisationUsers().pipe(
            map(users => {
                this.organisationUsers.splice(0, this.organisationUsers.length);

                for (const user of users) {
                  user.type = "user";
                  user.description = user.fullname;
                  user.userGroupMixInfo = user.fullname;
                  user.icon = "<i class=\"icon-16-user\"></i>";
                  this.organisationUsers.push(user);
                }

                return cloneDeep(this.organisationUsers);
            })
        );
    }

    fetchOrganisationGroups(): Observable<Array<Group<GroupUser>>> {
        return this.httpService.getOrganisationGroups().pipe(
            map(groups => {
                this.organisationGroups.splice(0, this.organisationGroups.length);

                for (const rawGroup of groups) {
                    const group: Group<GroupUser> = cloneDeep(rawGroup);
                    group.userGroupMixInfo = rawGroup.description;
                    group.icon = "<i class=\"icon-16-user-group\"></i>";
                    group.type = "group";

                    this.organisationGroups.push(group);
                }

                return cloneDeep(this.organisationGroups);
            })
        );
    }

    fetchWfOrganisation(): Observable<OsrestWorkflowOrganisationResult> {
        return this.httpService.getWorkflowOrganisation().pipe(
            map(org => {
                const nodeMap = new Map<string, OrgNode>();
                Object.values(org).forEach(orgNode => orgNode.forEach(orgNodeChild => nodeMap.set(orgNodeChild.id, orgNodeChild)));
                for(const [k,v] of Object.entries(org)) {
                    for(const node of v) {
                        node.typ = k;
                        if (node.typ == "Person" || node.typ == "Rolle") {
                            this.wfPerformers.set(node.id, node as unknown as WfOrgPerformer);
                        }
                        if(Array.isArray(node.children)) {
                            node.children = node.children.map(x => nodeMap.get(x.id) ?? x);
                        }
                    }
                }
                return this.wfOrganisation = org;
            }));
    }

    getWfOrganisation(): OrgNode[] {
        const flatOrg: OrgNode[] = [];
        for (const members of Object.values(this.wfOrganisation)) {
            for(const member of members) {
                const flatMember = cloneDeep(member);
                delete flatMember.children;
                flatOrg.push(flatMember);
            }
        }

        return flatOrg;

    }

    getWfOrgObjectsByType(type: string): OrgNode[] {
        return cloneDeep(this.wfOrganisation[type] ?? []);
    }

    getWfOrgPerformer(): WfOrgPerformer[] {
        return cloneDeep([...this.wfPerformers.values()]);
    }

    getWfOrgPerformerByIds(ids: string[]): WfOrgPerformer[] {
        return ids.reduce((acc, val) => {
            const performer = this.wfPerformers.get(val);
            if (performer) {
                acc.push(this.wfPerformers.get(val));
            }
            return acc;
        }, []);
    }

    getUserList(): User[] {
        return cloneDeep(this.organisationUsers);
    }

    getUserById(id: number): User {
        return cloneDeep(this.organisationUsers.find(x => x.id == id));
    }

    getUserByGuid(guid: string | string[]): User {
        return cloneDeep(this.organisationUsers.find(x => x.guid == guid));
    }

    getUserByName(name: string): User {
        return cloneDeep(this.organisationUsers.find(x => x.name.toLowerCase() == name.toLowerCase()));
    }


    getActiveUserList(): User[] {
        return cloneDeep(this.organisationUsers.filter(x => x.valid && x.locked != "1"));
    }
    getGroupList(): Array<Group<GroupUser>> {
        return cloneDeep(this.organisationGroups);
    }

    getWfOrganisationTree(): OsrestWorkflowOrganisationResult {
        return cloneDeep(this.wfOrganisation);
    }

    getDmsOrgMembers(): Array<User | Group<GroupUser>> {
        return cloneDeep([...this.organisationUsers, ...this.organisationGroups]);
    }

    refreshUserList(): Observable<User[]> {
        return this.fetchOrganisationUsers();
    }
}
