import {
    Component,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {DashletApiService} from "MODULES_PATH/dashlet/services/dashlet-api.service";
import {OsClientService} from "MODULES_PATH/dashlet/services/os-client.service";
import {OsDashletInit} from "MODULES_PATH/dashlet/interfaces/os-dashlet-init.interface";
import {filter, map} from "rxjs/operators";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Observable, Subscription} from "rxjs";
import {TodoCacheManagerService} from "INTERFACES_PATH/any.types";
import {DashletDmsInfo} from "MODULES_PATH/dashlet/interfaces/dms-info.interface";
import {CustomDashletInitEnvironmentProperties} from "MODULES_PATH/dashlet/interfaces/custom-dashlet-init-properties.interface";
import {CustomDashletData} from "MODULES_PATH/dashlet/interfaces/dashlet-data.interface";
import {CustomDashletConfig} from "MODULES_PATH/dashlet/interfaces/custom-dashlet-config.interface";
import {CustomDashletService} from "MODULES_PATH/dashlet/dashlet-services/custom-dashlet.service";
import {CustomDashletEvent, WindowDashletEvent} from "MODULES_PATH/dashlet/enums/custom-dashlet-event.enum";
import {DashletMessage} from "MODULES_PATH/dashlet/interfaces/dashlet-api.interface";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";

@Component({
    selector: "eob-custom-dashlet",
    templateUrl: "./eob-custom-dashlet.component.html",
    styleUrls: ["./eob-custom-dashlet.component.scss"]
})
export class EobCustomDashletComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild("iframeRef", {static: false}) iframeRef: HTMLIFrameElement;

    @Input() active: boolean;
    @Input() dashletConfig: CustomDashletConfig;
    @Input() dashletInitProperties: CustomDashletInitEnvironmentProperties;
    @Input() currentTitle: string;
    @Input() dashletData: CustomDashletData;

    currentOsid: number;
    selectedOsids: number[];
    currentContext: string;

    name: string;

    iframeUrl: SafeResourceUrl; // Required by Angular for security reasons

    private iFrameWindow: Window;
    private readonly updateObservable$: Observable<string[]>;
    private activeSubscription: Subscription;
    private updateSubscription: Subscription;
    private subscriptions: Subscription = new Subscription();
    private activeDashletOsidChanged: boolean = false; // needed for tracking whether the current osid has changed when the dashlet was not active

    @HostListener("window:message", ["$event"])
    onMessage(e: MessageEvent): void {
        if (this.active) {
            void this.handleDashletMessage(e.data);
        }
    }

    // eslint-disable-next-line max-params
    constructor(@Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
                public sanitizer: DomSanitizer, private osClientService: OsClientService,
                private messageService: MessageService, private customDashletService: CustomDashletService,
                private dashletApiService: DashletApiService) {
        this.updateObservable$ = this.messageService.getObservable().pipe(
            filter(message => message.type === CustomDashletEvent.UPDATE_CUSTOM_DASHLET),
            map(data => data.payload));
        this.updateSubscription = this.updateObservable$.subscribe(this.updateData.bind(this));
    }

    ngOnInit(): void {
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dashletConfig?.uri);
        this.name = this.currentTitle?.split(" ").join("-").toLowerCase();
        this.updateData();
        this.dashletData.initialized = true;

        // @ts-ignore
        this.subscriptions.add(this.messageService.subscribe(this.dashletConfig.id, (args: DashletMessage) => this.sendDashletMessage(...args)));
        this.subscriptions.add(this.messageService.subscribe(CustomDashletEvent.UPDATE_CUSTOM_DASHLET_PROPERTIES, this.updateProperties.bind(this)));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.active != undefined) {
            if (changes.active.currentValue) {
                this.activateDashlet();

                // update the dashlet when "active" property and osid have been changed after dashlet initialization
                if (!changes.active.firstChange && this.activeDashletOsidChanged) {
                    this.onUpdate();
                }
            } else {
                this.deactivateDashlet();
                this.activeDashletOsidChanged = false;
            }
        }
    }

    ngOnDestroy(): void {
        this.deactivateDashlet();
        this.dashletData.initialized = false;
        this.updateSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    // Called when the iframe is created
    onLoad(onLoadIframeRef: HTMLIFrameElement): Promise<void> {
        this.iframeRef = onLoadIframeRef;

        if (onLoadIframeRef == null) {
            return;
        }

        this.iFrameWindow = this.iframeRef.contentWindow;

        this.sendDashletMessage(WindowDashletEvent.ON_INIT, this.getInitEventData());
    }

    private activateDashlet(): void {
        this.activeSubscription = this.updateObservable$.subscribe(this.onUpdate.bind(this));
    }

    private deactivateDashlet(): void {
        this.activeSubscription?.unsubscribe();
    }

    private updateData(): void {
        this.currentOsid = this.dashletData.currentOsid;
        this.selectedOsids = this.dashletData.currentSelectedOsids;

        this.activeDashletOsidChanged = true;
    }

    private onUpdate(): void {
        this.sendDashletMessage(WindowDashletEvent.ON_UPDATE, this.getUpdateEventData());
    }

    private updateProperties(updatedProperty: Record<string, string>): void {
        if (this.active) {
            this.updateData();
            this.sendDashletMessage(WindowDashletEvent.ON_UPDATE, this.getUpdateEventData(updatedProperty));
        }
    }

    private handleDashletMessage(data: DashletMessage): void {
        this.messageService.broadcast(ViewerEvent.EXEC_DASHLET_API, { dashletId: this.dashletConfig.id, data });
    }

    private sendDashletMessage(type: string, message: DashletMessage, queryProperties?: { msgId: string }): void {
        this.iFrameWindow.postMessage({type, data: {...message}, ...queryProperties}, "*");
    }

    private getInitEventData(): DashletMessage {
        const initEventData: DashletMessage = this.getDashletData();
        const osDashletInit: OsDashletInit = initEventData.lastSelectedEntry ?
            this.customDashletService.getOsDashletInit(initEventData.lastSelectedEntry.osid) : undefined;
        const context: string = this.currentContext = this.customDashletService.getStateContext();
        

        return {...initEventData, osDashletInit, context};
    }

    private getUpdateEventData(updatedProperty: Record<string, string> = {}): DashletMessage {
        const updateEventData: DashletMessage = this.getDashletData();
        const osDashletInit: OsDashletInit = updateEventData.lastSelectedEntry ?
            this.customDashletService.getOsDashletUpdate(updateEventData.lastSelectedEntry.osid, updatedProperty.regenerate) : undefined;
        const context: string = this.currentContext = this.customDashletService.getStateContext();

        return {...updateEventData, osDashletInit, context};
    }

    private getDashletData(): DashletMessage {
        const selectedOsids: number[] = this.selectedOsids || [];

        if (!selectedOsids.includes(this.currentOsid)) {
            selectedOsids.push(this.currentOsid);
        }

        const selectedEntries: DashletDmsInfo[] = this.cacheManagerService.dmsDocuments.get(selectedOsids).map(dmsData => ({
            mainType: dmsData?.model?.mainType,
            objectTypeId: dmsData?.model?.objectTypeId,
            osid: dmsData?.model?.osid,
            hasVariants: !!dmsData?.model?.hasVariants,
            objectType: dmsData?.model?.objectType
        }));

        const dmsInfo: DashletDmsInfo = selectedEntries.find(dmsData => dmsData.osid == this.currentOsid.toString());

        return {
            ...this.dashletInitProperties,
            activeCustomDashlet: this.dashletConfig,
            dapi: this.dashletApiService.getDapi(),
            lastSelectedEntry: dmsInfo,
            selectedEntries
        };
    }
}
