import {Inject, Injectable} from "@angular/core";
import {
    TodoCacheManagerService,
    TodoEnvironmentService,
    TodoFormService, TodoPageInterface,
    TodoQuickSearchFormData
} from "INTERFACES_PATH/any.types";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";
import {ObjectType, ObjectTypeFieldList} from "INTERFACES_PATH/object-type.interface";
import {cloneDeep} from "lodash";
import * as dayjs from "dayjs";
import {CustomQueryField, DesktopField, DesktopQuery} from "INTERFACES_PATH/desktop.interface";
import {BackendDesktopEntry} from "CORE_PATH/backend/modules/osrest/interfaces/osrest-backend-desktop.interface";
import {FieldModel} from "SHARED_PATH/models/field.model";
import {FieldControlType, FieldDataType} from "ENUMS_PATH/field.enum";
import {User} from "INTERFACES_PATH/user.interface";
import {QueryField, QueryParam} from "INTERFACES_PATH/query.interface";

@Injectable({
    providedIn: "root"
})
export class DesktopQueryService {

    constructor(
        @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
        @Inject("environmentService") private environmentService: TodoEnvironmentService,
        @Inject("formService") private formService: TodoFormService,
        private organisationService: OrganisationService) {
    }

    /**
     * Build formData from desktop backend data.
     *
     * @param {Object} backendDesktopItem - Backend data from a desktop call.
     * @param {Object} query - A desktop query.
     * @returns {Object} The formData.
     */
    buildDesktopQueryFormDataTypes(backendDesktopItem: BackendDesktopEntry, query: DesktopQuery): { [objectTypeId: string]: { [internal: string]: TodoQuickSearchFormData } } {
        const types: DesktopField[] = backendDesktopItem.fields;
        const formDataTypes: { [objectTypeId: string]: { [internal: string]: TodoQuickSearchFormData } } = {};

        query.objectTypeIds = [];
        query.varMap = {};

        let objectTypeFieldLists: { [key: string]: ObjectTypeFieldList } = {};

        const activeTypeDef: ObjectType = this.cacheManagerService.objectTypes.getById(backendDesktopItem.activePage);

        let useDocumentParams: boolean = false;
        let useRegisterParams: boolean = false;
        let useFolderParams: boolean = false;

        let documentParams: QueryField[] = null;
        let registerParams: QueryField[] = null;
        let folderParams: QueryField[] = null;

        query.isInvalid = this.isInvalidQuery(types);
        query.isCorrupted = this.isCorruptedQuery(types);
        if (query.isInvalid || query.isCorrupted) {
            return formDataTypes;
        }

        if (types) {
            for (const type of types) {
                // 100 -> Dokumentenbasisparameter
                // 102 -> Ordnerbasisparameter
                // 103 -> Registerbasisparameter
                if (Number(type.objectTypeId) >> 16 == 100) {
                    useDocumentParams = true;
                    documentParams = type.fields;
                } else if (Number(type.objectTypeId) >> 16 == 102) {
                    useFolderParams = true;
                    folderParams = type.fields;
                    objectTypeFieldLists[activeTypeDef.model.config.cabinetId] = {
                        fieldList: [],
                        queryFields: []
                    };

                    query.objectTypeIds.push(activeTypeDef.model.config.cabinetId);
                } else if (Number(type.objectTypeId) >> 16 == 103) {
                    useRegisterParams = true;
                    registerParams = type.fields;

                    const parent: ObjectType = this.cacheManagerService.objectTypes.getById(activeTypeDef.model.config.cabinetId);
                    for (const childType of parent.model.config.childTypes) {
                        const typeDef: ObjectType = this.cacheManagerService.objectTypes.getById(childType);

                        if (typeDef.model.config.mainType == "99" && types.some(x => x.objectTypeId == typeDef.model.config.objectTypeId)) {
                            query.objectTypeIds.push(childType);
                            objectTypeFieldLists[childType] = {
                                fieldList: [],
                                queryFields: []
                            };
                        }
                    }
                } else if (Number(type.objectTypeId) != -1) {
                    const typeDef: ObjectType = this.cacheManagerService.objectTypes.getById(type.objectTypeId);

                    objectTypeFieldLists[type.objectTypeId] = {
                        fieldList: typeDef.api.getFlatFieldList(true),
                        queryFields: type.fields
                    };

                    query.objectTypeIds.push(type.objectTypeId);
                }

                // in case we have a stored query with variable parameters, there can only be one fulltext field
                // on the other hand, when we do not want to use variables, we are allowed to combine queries
                // with multiple fulltext fields as long we combine two object types
                // this does not seem to be consistent... but... well ... you know ... historical reasons and stuff ...

                // we need to check the fields at this point because the fulltext fields are inside each type (without params)
                // otherwise the fulltext field would have its own objectTypeId (with params)
                for (const field of type.fields) {
                    if (field.index == -1) {
                        if (objectTypeFieldLists?.fulltext == void 0) {
                            objectTypeFieldLists = {...objectTypeFieldLists, fulltext: {fieldList: null, queryFields: null}};
                        }

                        if (backendDesktopItem.var) {
                            objectTypeFieldLists.fulltext[backendDesktopItem.activePage] = "";

                            if (field.isVariable) {
                                if (query.varMap[field.variable] == void 0) {
                                    query.varMap[field.variable] = [];
                                }

                                query.varMap[field.variable].push({
                                    objectTypeId: backendDesktopItem.activePage,
                                    internal: "fulltext"
                                });
                            }
                        } else {
                            objectTypeFieldLists.fulltext[type.objectTypeId] = field.variable;
                        }
                    }
                }
            }
        }

        for (const key in objectTypeFieldLists) {
            const type: ObjectTypeFieldList = objectTypeFieldLists[key];
            const formData: { [key: string]: QueryParam } = {};

            const objectTypeId: string = key;
            const queryFields: QueryField[] = type.queryFields;

            if (Number(objectTypeId) >> 16 == 99 && useRegisterParams) {
                this.addBaseParams(formData, registerParams, false);
            } else if (Number(objectTypeId) >> 16 == 0 && useFolderParams) { // SonarQube Error !
                this.addBaseParams(formData, folderParams, false);
            } else if (Number(objectTypeId) >> 16 > 0 && Number(objectTypeId) >> 16 <= 8 && useDocumentParams) { // SonarQube Error !
                this.addBaseParams(formData, documentParams, true);
            }

            if (queryFields != void 0) {
                for (const queryField of queryFields) {
                    if (Number(queryField.index) == -1) {
                        continue;
                    }

                    let field: FieldModel;

                    if (queryField.containerGUID) {
                        field = this.getFieldFromPageControl(objectTypeFieldLists[objectTypeId].fieldList, queryField);
                    } else {
                        field = this.getFieldWithoutParent(objectTypeFieldLists[objectTypeId].fieldList, queryField);
                    }

                    if (field == void 0) {
                        continue;
                    }

                    formData[field.internal] = {
                        value: "",
                        model: field
                    };

                    if (queryField.gridData) {
                        // Table control. It can't be with placeholder. Only static values.
                        formData[field.internal].gridData = [queryField.gridData];
                    } else {
                        const value: string = queryField.variable;

                        if (value === "NULL") {
                            formData[field.internal].value = "";
                            formData[field.internal].model.isNull = true;
                        } else if (value === "BENUTZER") {
                            formData[field.internal].value = this.environmentService.getSessionInfo().username.toUpperCase();
                        } else if (value === "DATUM") {
                            formData[field.internal].value = dayjs().format(this.environmentService.env.dateFormat.date);
                        } else {
                            formData[field.internal].value = value;

                            if (queryField.isVariable) {
                                if (query.varMap[queryField.variable] == void 0) {
                                    query.varMap[queryField.variable] = [];
                                }

                                query.varMap[queryField.variable].push({
                                    objectTypeId, internal: field.internal
                                });
                            }
                        }
                    }
                }
            }

            // special fulltext stuff
            if (key == "fulltext") {
                formDataTypes[objectTypeId] = type;
            } else {
                formDataTypes[objectTypeId] = this.formService.reduceFormdata(formData);
            }
        }

        return formDataTypes;
    }

    buildDesktopQuicksearches(searches: { [key: string]: DesktopQuery }, config: string[]): DesktopQuery[] {
        const quicksearches: DesktopQuery[] = [];
        const quicksearchConfig: string[] = config != void 0 && Array.isArray(config) ? config : [];

        for (const i in searches) {
            const multivars: string[] = [];
            const newSearch: DesktopQuery = {
                type: "quicksearch",
                types: new Map(),
                name: searches[i].name,
                id: searches[i].id,
                profile: searches[i].profile,
                isCorrupted: searches[i].isCorrupted,
                isInvalid: searches[i].isInvalid,
                expanded: quicksearchConfig.includes(searches[i].id.toString()),
                isPublic: searches[i].isPublic,
                activePage: searches[i].activePage,
                formDataTypes: cloneDeep(searches[i].formDataTypes),
                objectTypeIds: searches[i].objectTypeIds,
                varMap: searches[i].varMap,
                context: searches[i].context,
                expert: searches[i].expert,
                icon: searches[i].icon,
                objectTypeId: searches[i].objectTypeId,
                parentId: searches[i].parentId,
                fields: searches[i].fields
            };

            const objectPages: DesktopField[] = searches[i].fields;

            for (const objectPage of objectPages) {
                const fields: QueryField[] = objectPage.fields;
                const objectTypeId: string = objectPage.objectTypeId;

                if (!newSearch.types.get(objectTypeId)) {
                    newSearch.types.set(objectTypeId, []);
                }

                for (const field of fields) {
                    if (!this.isVarField(field) || this.isHashVarQueryField(field) || this.isMultiVarField(multivars, field)) {
                        continue;
                    }

                    let variable: string = field.variable;
                    variable = variable.replace(/\$/g, "");
                    const newField: CustomQueryField = {
                        tabIndex: field.index,
                        var: variable,
                        objectTypeId
                    };

                    if (field.containerGUID != void 0) {
                        newField.pageId = field.containerGUID;
                    }

                    newSearch.types.get(objectTypeId).push(newField);
                }
            }

            quicksearches.push(newSearch);
        }

        return quicksearches;
    }

    /**
     * Check wether the user is allowed to see every objectType included in the query.
     *
     * @param {object[]} types - An array of backend data for a desktop query.
     * @returns {boolean} - Is the query corrupted.
     */
    private isCorruptedQuery(types: DesktopField[] = []): boolean {
        for (const type of types) {
            if (Number(type.objectTypeId) >> 16 > 99) {
                for (const param of type.fields) {
                    if (param.isVariable && (param.variable.indexOf("VAR") == 0 || param.variable.indexOf("STAT") == 0)) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    private isInvalidQuery(types: DesktopField[] = []): boolean {
        return types.some(type => {
            if (this.cacheManagerService.objectTypes.getById(type.objectTypeId) == void 0) {
                console.warn("objecttype not found --> ", type.objectTypeId);
                return true;
            }
        });
    }

    /**
     * Search the given field in all pageCtrls.
     *
     * @param {Object} fieldList - A list of fields.
     * @param {Object} fieldInfo - Information about the requested field.
     * @returns {Object|undefined} Returns the field or nothing.
     */
    private getFieldFromPageControl(fieldList: FieldModel[], fieldInfo: QueryField): FieldModel {
        const pageGuid: string = fieldInfo.containerGUID;

        for (const field of fieldList) {
            if (field.type != FieldControlType.PAGE_CONTROL) {
                continue;
            }

            for (const j in field.pages) {
                const page: TodoPageInterface = field.pages[j];

                if (page.pageId == pageGuid) {
                    return this.getFieldByIndex(page.fields, fieldInfo);
                }
            }
        }
    }

    /**
     * Iterate a fieldlist and return the field with the given index.
     *
     * @param fieldList - An array of fields.
     * @param fieldInfo - Information about the requested field.
     * @returns field The found field or undefined.
     */
    private getFieldByIndex(fieldList: FieldModel[], fieldInfo: QueryField): FieldModel {
        for (const field of fieldList) {
            if (field.type == FieldControlType.GROUP) {
                const groupField: FieldModel = this.getFieldByIndex(field.fields, fieldInfo);

                if (groupField != void 0) {
                    return groupField;
                }
            } else if (this.isQueryField(field, fieldInfo)) {
                return field;
            }
        }
    }

    /**
     * Search the given field directly on the mask.
     *
     * @param fieldList - A list of fields.
     * @param fieldInfo - Information about the requested field.
     * @returns field Returns the field or nothing.
     */
    private getFieldWithoutParent(fieldList: FieldModel[], fieldInfo: QueryField): FieldModel {
        for (const field of fieldList) {
            if (field.type == FieldControlType.PAGE_CONTROL || field.pageControl) {
                continue;
            }

            if (this.isQueryField(field, fieldInfo)) {
                return field;
            }
        }
    }

    /**
     * If guid property exists check for guid, otherwise fallback to index.
     */
    private isQueryField(field: FieldModel, fieldInfo: QueryField): boolean {
        return ((fieldInfo.guid && field.guid === fieldInfo.guid)
            || (!fieldInfo.guid && field.tabIndex == fieldInfo.index));
    }

    private addBaseParams(formData: { [key: string]: QueryParam }, data: QueryField[], isDocumentParam: boolean): void {
        data = this.resolveBaseparamHashVars(data);
        formData.Creator = this.findBaseParam("0", data, "baseparamCreator", "user");
        formData.Created = this.findBaseParamRange("1", "2", data, "baseparamCreated");
        formData.Modifier = this.findBaseParam("3", data, "baseparamModifier", "user");
        formData.Modified = this.findBaseParamRange("4", "5", data, "baseparamModified");
        formData.Owner = this.findBaseParam("7", data, "baseparamOwner", "user");

        formData.ArchiveDate = this.findBaseParamRange("9", "10", data, "baseparamArchiveDate");
        formData.RetentionPlannedDate = this.findBaseParamRange("11", "12", data, "baseparamRetentionPlannedDate");
        formData.RetentionDate = this.findBaseParamRange("13", "14", data, "baseparamRetentionDate");

        if (isDocumentParam) {
            formData.Medium = this.findBaseParam("8", data, "baseparamMedium", "list");
        }

        if (formData.Owner != null) {
            const user: User = this.organisationService.getUserByGuid(formData.Owner.value);
            formData.Owner.value = user != void 0 ? [user.name] : [];
        }

        for (const item of data) {
            if (String(item.index) == "6") {
                formData.LockedByMe = this.addCheckboxParam(item.variable, "16", "baseparamLockedByMe");
                formData.LockedByOther = this.addCheckboxParam(item.variable, "32", "baseparamLockedByOther");
                formData.ArchiveStateReference = this.addCheckboxParam(item.variable, "512", "baseparamArchiveStateReference");
                formData.ArchiveStateArchived = this.addCheckboxParam(item.variable, "1", "baseparamArchiveStateArchived");
                formData.ArchiveStateNotArchivable = this.addCheckboxParam(item.variable, "4", "baseparamArchiveStateNotArchivable");
                formData.ArchiveStateArchivable = this.addCheckboxParam(item.variable, "2", "baseparamArchiveStateArchivable");
                formData.ArchiveStateNoPages = this.addCheckboxParam(item.variable, "8", "baseparamArchiveStateNoPages");

                formData.HasVariants = this.addCheckboxParam(item.variable, "2048", "baseparamHasVariants");
                formData.MultiLocation = this.addCheckboxParam(item.variable, "1024", "baseparamMultiLocation");

                const inRegisterParam: QueryParam = this.addCheckboxParam(item.variable, "64", "baseparamInRegister");
                const notInRegisterParam: QueryParam = this.addCheckboxParam(item.variable, "128", "baseparamNotInRegister");

                if ((inRegisterParam != undefined) !== (notInRegisterParam != undefined)) {
                    formData.Register = inRegisterParam || notInRegisterParam;
                }

                formData.SignedCurrent = this.addCheckboxParam(item.variable, "4096", "baseparamSignedCurrentVersion");
                formData.SignedPrevious = this.addCheckboxParam(item.variable, "8192", "baseparamSignedPreviousVersion");
                break;
            }
        }

        for (const i in formData) {
            if (!formData[i]) {
                delete formData[i];
            }
        }
    }

    private resolveBaseparamHashVars(data: QueryField[]): QueryField[] {
        const newData: QueryField[] = [];

        for (const param of data) {
            const value: string = param.variable;
            if (param.isVariable && (value == "DATUM" || value == "BENUTZER")) {
                param.isVariable = false;
                switch (value) {
                    case "DATUM":
                        param.variable = new Date().setHours(0, 0, 0, 0).toString();
                        param.variable = param.variable.substr(0, param.variable.length - 3);
                        // const endParam = angular.copy(param);
                        const endParam: QueryField = cloneDeep(param);
                        endParam.index = Number(param.index) + 1;
                        endParam.variable = new Date().setHours(23, 59, 59, 0).toString();
                        endParam.variable = endParam.variable.substr(0, endParam.variable.length - 3);
                        newData.push(param);
                        newData.push(endParam);
                        break;
                    case "BENUTZER":
                        param.variable = String(param.index) == "7" ? this.environmentService.getSessionInfo().osGuid : this.environmentService.getSessionInfo().username;
                        newData.push(param);
                        break;
                }
            } else {
                newData.push(param);
            }
        }

        return newData;
    }

    private addCheckboxParam(checkboxValue: string, valueToMatch: string, internal: string): QueryParam {
        if ((Number(checkboxValue) & Number(valueToMatch)) == 0) {
            return null;
        } else {
            return {
                value: "1",
                model: new FieldModel({
                    type: FieldControlType.CHECKBOX,
                    internal,
                    isBaseParam: true
                })
            };
        }
    }

    private findBaseParam(index: string, params: QueryField[], internal: string, addon: string): QueryParam {
        const result: QueryParam = {
            value: "",
            model: new FieldModel({
                type: FieldDataType.TEXT,
                internal,
                isBaseParam: true,
                addon
            })
        };

        for (const param of params) {
            if (param.index == Number(index)) {
                result.value = `${param.isVariable ? "" : param.variable}`;

                if (param.isVariable && param.variable == "NULL") {
                    result.model.isNull = true;
                }

                return result;
            }
        }
    }

    private findBaseParamRange(fromIndex: string, toIndex: string, params: QueryField[], internal: string): QueryParam {
        let fromValue: string;
        let toValue: string;

        const result: QueryParam = {
            value: "",
            model: new FieldModel({
                type: FieldDataType.DATETIME,
                internal,
                isBaseParam: true
            })
        };

        for (const param of params) {
            if (param.index == Number(fromIndex)) {
                fromValue = param.variable;

                if (!fromValue) {
                    result.model.isNull = true;
                    return result; // in this case the result value must be ""
                }

                if (!isNaN(Number(fromValue))) {
                    fromValue = dayjs(new Date(Number(`${fromValue}000`))).format(this.environmentService.env.dateFormat.datetime);
                }
            }

            if (param.index == Number(toIndex)) {
                toValue = param.variable;

                if (!isNaN(Number(toValue))) {
                    toValue = dayjs(new Date(Number(`${toValue}000`))).format(this.environmentService.env.dateFormat.datetime);
                }
            }
        }

        if (fromValue == void 0 && toValue != void 0) {
            result.value = (`<${toValue}`);
            return result;
        } else if (fromValue != void 0 && toValue == void 0) {
            result.value = (`>${fromValue}`);
            return result;
        } else if (fromValue != void 0 && toValue != void 0) {
            result.value = `${fromValue} - ${toValue}`;
            return result;
        } else {
            return null;
        }
    }

    /**
     * Is the given field connected to a variable, that is used more than once.
     *
     * @param {String[]} multivars - A list of variables.
     * @param {Object} field - The to be checked field.
     * @returns {boolean} True if a variable is there a second time otherwise false.
     */
    private isMultiVarField(multivars: string[], field: QueryField): boolean {
        const isMultiVar: boolean = multivars.some((multivar: string) => multivar == field.variable);

        if (!isMultiVar) {
            multivars.push(field.variable);
        }

        return isMultiVar;
    }

    /**
     * Checks whether the variable of the given field is one of the prefilled hash variables.
     *
     * @param {Object} field - To evaluate whether it is a hash var field.
     * @param {string=} name - To check for a certain hash var query field.
     * @returns {boolean} Is the field's variable a hash variable.
     */
    private isHashVarQueryField(field: QueryField, name?: string): boolean {
        if (field.isVariable == false) {
            return false;
        }

        if (name != void 0) {
            return field.variable == name;
        } else {
            return ["NULL", "BENUTZER", "DATUM", "COMPUTER-IP", "COMPUTER-NAME", "COMPUTER-GUID"].some((value: string) => field.variable == value);
        }
    }

    private isVarField(field: QueryField): boolean {
        return (field.variable.includes("VAR") && field.variable.includes("STAT")) || field.isVariable == true;
    }
}
