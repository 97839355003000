import {Subscription} from "rxjs";
import {FormEvent} from "MODULES_PATH/form/enums/form-event.enum";

(function() {
    require("SERVICES_PATH/viewer/detailsviewer/dv.document.srv.js");
    require("COMPONENTS_PATH/eob-detailsviewer/components/dv-expandable-container/dv.expandable.container.dir.js");

    angular.module("eob.framework").directive("dvIndexdata", DvIndexdata);

    DvIndexdata.$inject = ["$compile", "$filter", "$timeout", "$log", "dvDocumentService", "clientService", "progressbarService", "messageService", "environmentService"];

    function DvIndexdata($compile, $filter, $timeout, $log, DvDocumentService, ClientService, ProgressbarService, MessageService, EnvironmentService) {
        return {
            restrict: "E",
            template: require("!raw-loader!./dv.indexdata.html"),
            link(scope, element) {
                scope.idxerror = false;
                scope.titleIndex = $filter("translate")("app.indexdata");
                scope.titleBase = $filter("translate")("app.baseparams");
                scope.loading = true;
                scope.objectTypeId;
                scope.isPhone = ClientService.isPhone();

                scope.altLang = !EnvironmentService.uiLangIsObjectDefLang() ? EnvironmentService.getObjectDefinitionLanguage() : null;

                let subscription = new Subscription();
                let simpleFieldsContainer, baseFieldsContainer;
                let progressBar;

                scope.$on("changeObject", load);
                MessageService.subscribe("clear.viewer", clear);

                load();

                /**
                 * Initialize the indexdata view.
                 *
                 * @returns {Promise} Resolved once the directive is initialized.
                 */
                async function load() {
                    getDomElements();
                    let dvDmsObject = await DvDocumentService.loadAsync(scope.objectId);
                    loadAsync(dvDmsObject);
                    DvDocumentService.attachListener(loadAsync);

                    delete scope.loading;

                    MessageService.broadcast("changeIndexData", {});
                }

                /**
                 * Get dvDmsObject and reset index data.
                 *
                 * @param {object} dvDmsObject - The dvDMsObject to load.
                 * @returns {Promise} Resolved once the view is reset.
                 */
                async function loadAsync(dvDmsObject) {
                    scope.idxerror = false;

                    if (dvDmsObject !== void 0 && scope.objectId == dvDmsObject.osid) {
                        try {
                            setIndexData(dvDmsObject);
                            setContainerVisibility(dvDmsObject.objectType);
                            // $timeout instead of scope.$apply() to prevent calling scope.$apply() while one is already in progress
                            $timeout(() => {}, 0);
                        } catch (error) {
                            scope.idxerror = true;
                        }
                    }
                }

                /**
                 * Set scope data.
                 *
                 * @param {object} dvDmsObject - A dvDmsObject.
                 */
                function setIndexData(dvDmsObject) {
                    scope.objectTypeId = dvDmsObject.objectTypeId;
                    scope.simpleData = dvDmsObject.simpleFields;
                    scope.ecmTableFields = dvDmsObject.tableFields;
                    scope.baseData = dvDmsObject.baseParameters;
                }

                function setContainerVisibility(type) {
                    if (scope.simpleData.length > 0 || scope.isPhone) {
                        simpleFieldsContainer[0].style.removeProperty("display");
                    } else {
                        simpleFieldsContainer[0].style.display = "none";
                    }

                    if (type !== "PORTFOLIO" && scope.baseData.length > 0) {
                        baseFieldsContainer[0].style.removeProperty("display");
                    } else {
                        baseFieldsContainer[0].style.display = "none";
                    }
                }

                function getDomElements() {
                    if (element[0].children[0].children[0].className.indexOf("simpleFields") !== -1) {
                        simpleFieldsContainer = angular.element(element[0].children[0].children[0]);
                    }
                    if (element[0].children[0].children[1].className.indexOf("baseFields") !== -1) {
                        baseFieldsContainer = angular.element(element[0].children[0].children[1]);
                    }
                    if (!simpleFieldsContainer || !baseFieldsContainer) {
                        $log.error("Error:indexdata: Nicht alle Dom Elemente geladen.");
                    }
                }

                function clear() {
                    scope.docdata = undefined;
                    scope.idxerror = false;
                }

                function initProgressbar() {
                    progressBar = ProgressbarService.getProgressbarInstance("loadAnimation", element[0], true);
                    progressBar.show();
                }

                scope.$on("$destroy", () => {
                    subscription.unsubscribe();
                    DvDocumentService.detachListener(loadAsync);
                });

                subscription.add(MessageService.subscribe(FormEvent.FORM_READY, async () => {
                    progressBar ? progressBar.hide() : undefined;
                    progressBar = null;
                }));

                subscription.add(MessageService.subscribe("show.detailsviewer.progressbar", async () => {
                    if (!progressBar) {
                        initProgressbar();
                    }
                }));
            }
        };
    }
})();
