import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BackendOsRestModule} from "CORE_PATH/backend/modules/osrest/backend-osrest.module";

@Injectable({providedIn: BackendOsRestModule})
export class OsRestSessionService {

    constructor(private httpClient: HttpClient) {
    }

    checkPassword(pwd: string): Observable<void> {
        return this.httpClient.get<void>(`/osrest/api/session/checkPassword?password=${btoa(pwd)}`);
    }
}