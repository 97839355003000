import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";

@Component({
    selector: "eob-label",
    templateUrl: "./label.component.html",
    styleUrls: ["./label.component.scss"]
})
export class LabelComponent implements OnInit, AfterViewInit {
    @Input() field: Field;
    @Input() formid: string;
    @Input() layoutField: any;
    @Input() ismockform: boolean;
    @Input() value: string = "";
    @Input() for: string = "";
    @Input() tooltip: string;
    @Input() isRequired: boolean = false;
    @Input() isUnique: boolean = false;
    @Input() lang: string;

    readonly layoutFactor: number;

    constructor(public el: ElementRef<HTMLElement>,
                private renderer: Renderer2,
                private layoutManagerService: LayoutManagerService) {
        this.layoutFactor = this.layoutManagerService.isTouchLayoutActive() ? 20 : 16;
    }

    @ViewChild("label", {static: false}) label: ElementRef<HTMLElement>;

    ngOnInit(): void {
        if (this.field) {
            if (this.field.model.type != "static") {
                this.field.label = this.el.nativeElement;
            }

            if (this.layoutField?.sibling == void 0 && this.field.model.textAlign != void 0) {
                this.renderer.setStyle(this.el.nativeElement, `${this.field.model.textAlign}`, "0px");
            } else {
                this.renderer.setStyle(this.el.nativeElement, "right", "0px");
            }

            if (this.layoutField?.sibling != void 0 && (this.layoutField?.row == this.layoutField?.sibling.row - 1 || this.layoutField?.row == this.layoutField?.sibling.row - 2)) {
                this.renderer.addClass(this.el.nativeElement, "is-label-above");
            }
        }
    }

    ngAfterViewInit(): void {
        // TODO remove this after we accept tags in text areas
        // if a label is multiline we need to attach different styles to make the text wrap correctly

        if (!this.ismockform && this.field) {
            this.renderer.setAttribute(this.label.nativeElement, "for", `${this.field.model.internal}-${this.formid}`);
        }

        if (this.layoutField && this.layoutField.sibling == void 0) {
            this.renderer.setStyle(this.el.nativeElement, "text-align", "left");
        }

        try {
            if (this.layoutField?.isLabel && this.layoutField.height > 2) {
                const lineHeight: number = 18;
                const padding: number = 8;
                const lineClamp: number = Math.floor((this.layoutField.height * this.layoutFactor - padding) / lineHeight);
                const labelSpan: HTMLElement = this.el.nativeElement.querySelector("span");
                this.renderer.setAttribute(labelSpan, "style", `text-align: right; white-space: normal; display: -webkit-box; -webkit-line-clamp: ${lineClamp}; -webkit-box-orient: vertical;`);
                this.renderer.addClass(labelSpan, "textarea-label");
            }
        } catch (e) {
            console.warn(e);
        }
    }
}
