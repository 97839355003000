import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EobIconComponent} from "MODULES_PATH/icon/components/eob-icon-component/eob-icon.component";
import {SharedModule} from "SHARED_PATH/shared.module";

@NgModule({
    declarations: [EobIconComponent],
    exports: [EobIconComponent],
    imports: [
        CommonModule
    ],
    entryComponents: [EobIconComponent]
})
export class IconModule { }
