
/**
 * Enum describing the possible connectivity states
 */
export enum Connection {
    NONE,
    MOBILE,
    WIFI,
    UNKNOWN
}
