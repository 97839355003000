import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit, ViewChild,
} from "@angular/core";
import {Field} from "INTERFACES_PATH/field.interface";
import {CheckboxComponent} from "MODULES_PATH/form/components/inputs/checkbox/checkbox.component";
import {FormControl} from "@angular/forms";

@Component({
    selector: "eob-form-checkbox",
    templateUrl: "./eob-form-checkbox.component.html",
    styleUrls: ["./eob-form-checkbox.component.scss"]
})
export class EobFormCheckboxComponent implements OnInit, AfterViewInit {
    @Input() field: Field;
    @Input() container: any;
    @Input() formhelper: any;
    @Input() ismockform: any;
    @Input() formid ="";
    @Input() control: FormControl = new FormControl();

    checkboxState: string = "";
    fieldid: string = "";

    @ViewChild(CheckboxComponent, {static: false}) checkbox: CheckboxComponent;

    constructor(public el: ElementRef) {}

    ngOnInit(): void {
        // this.addCheckboxLogic.call(this);
        this.fieldid =`${this.field.model.internal}-${this.formid}`;
    }

    ngAfterViewInit(): void {
        // TODO refactor
        $(this.el.nativeElement).on("update.cb.class", this.setValueClass.bind(this));
        // this.renderer.setAttribute(this.checkbox.nativeElement, "aria-labelledby", this.fieldid);
    }

    /**
     * Set the corresponding class for the field value.
     *
     * @param {object} event - a click or keydown event.
     * @param {object} checkboxElement - The dom element for the custom-checkbox element.
     */
    setValueClass(event: Event, checkboxElement: JQuery) : void {
        if (event != void 0) {
            event.stopPropagation();
        }
        this.checkbox.setValueClass();
    }

}
