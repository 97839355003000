/**
 * Mapping of backend call errors to resource key to i18n them to the user.
 */
export const ERROR_CODES: any = {

    // region osrest error codes - All error codes can be found in the osrest ErrorType.java

    READ_ACCESS_DENIED: {messageKey: "eob.read.access.denied.error"},
    USER_NOT_AUTHORIZED: {messageKey: "eob.action.process.missing.rights.error"},
    PASSWORD_INCORRECT: {messageKey: "eob.password.incorrect.error"},
    USER_IS_SUPERVISOR: {messageKey: "eob.user.deleted.is.superwiser.error"},
    USER_IS_BLOCKED: {messageKey: "eob.user.is.blocked.error"},
    PASSWORD_INSUFFICIENT_COMPLEXIIY: {messageKey: "eob.password.insufficient.complexity.error"},

    SERVER_VERSION_MISMATCH: {messageKey: "eob.server.version.mismatch.error"},
    LOCAL_CLIENT_SERVER_VERSION_MISMATCH: {messageKey: "eob.local.client.server.version.mismatch.error"},

    ASINI_READ_FAILED: {messageKey: "eob.asini.read.error"},
    ASINI_SAVE_FAILED: {messageKey: "eob.asini.write.error"},
    ASINI_LOADING_FAILED: {messageKey: "eob.asini.read.error"},

    OBJECT_NOT_FOUND: {messageKey: "eob.object.not.found.error"},
    OBJECT_TYPE_NOT_FOUND: {messageKey: "eob.objecttype.not.found.error"},
    OBJECT_IS_TYPELESS: {messageKey: "eob.object.typeless.error"},
    OBJECT_TYPE_ERROR: {messageKey: "eob.object.type.error"},
    OBJECT_NOT_FOUND_ID: {messageKey: "eob.object.not.found.error"},
    OBJECT_NOT_CABINET: {messageKey: "eob.object.no.cabinet.error"},

    OBJECT_ALREADY_EXISTS: {messageKey: "eob.folder.tree.link.item.already.exists"},
    OBJECT_INSERT_FAILED: {messageKey: "eob.object.insert.error"},
    OBJECT_XML_PARSING_FAILED: {messageKey: "eob.object.retrieve.error"},
    OBJECT_INSERT_CIRCLE: {messageKey: "eob.action.move.insert.circle.error"},
    OBJECT_INSERT_LOCATION_MAX: {messageKey: "eob.form.create.insert.count.max.error.message"},
    OBJECT_INSERT_LOCATION_TYPE: {messageKey: "eob.insert.target.object.type.error"},
    OBJECT_INSERT_MULTI_LOCATION: {messageKey: "eob.insert.multi.location.error"},
    OBJECT_INSERT_INTO_DOCUMENT: {messageKey: "eob.insert.into.document.error"},
    OBJECT_INSERT_TARGET_NOT_FOUND: {messageKey: "eob.insert.target.not.found.error"},
    OBJECT_SET_TYPE_FAILED: {messageKey: "eob.object.set.type.failed.error"},

    OBJECT_UPDATE_FAILED: {messageKey: "eob.object.update.error"},

    OBJECT_GET_DETAILS_FAILED: {messageKey: "eob.object.retrieve.error"},
    OBJECT_GET_HISTORY_FAILED: {messageKey: "eob.history.load.error"},
    OBJECT_FIELDS_LINKED_CATALOG_ERROR: {messageKey: "eob.linked.catalog.error"},

    FILE_NOT_FOUND: {messageKey: "eob.file.not.found.error"},
    FILE_NOT_EXISTS: {messageKey: "eob.action.delete.already.deleted.error.message"},
    FILE_IN_TRASH: {messageKey: "eob.action.delete.in.trash.error.message"},
    FILE_IN_WORKITEM_USE: {messageKey: "eob.action.delete.in.workflow.error.message"},
    FILE_NO_DELETE_ACCESS: {messageKey: "eob.action.delete.file.no.delete.access.error.message"},
    FILE_ENCRYPTED_ERROR: {messageKey: "eob.action.export.pdf.failed.password"},
    FILE_ENCRYPTED_ERROR_SPECIFIC: {messageKey: "eob.action.export.pdf.failed.password.specific"},
    FILE_EXPORT_TOO_MANY: {messageKey: "eob.action.export.failed.too.many.objects"},
    FILE_EXPORT_TOO_LESS: {messageKey: "eob.action.export.failed.no.objects"},

    TEMPLATE_LOADING_FAILED: {messageKey: "eob.template.loading.error"},
    TEMPLATE_NOT_FOUND: {messageKey: "eob.template.not.found.error"},

    TEMPLATE_ACQUISITION_FAILED: {messageKey: "eob.template.qcquisition.error"},
    TEMPLATE_COULD_NOT_BE_OPENED: {messageKey: "eob.template.open.error"},
    TEMPLATE_COULD_NOT_BE_SAVED: {messageKey: "eob.template.save.error"},
    TEMPLATE_COULD_NOT_BE_DELETED: {messageKey: "eob.template.delete.error"},
    TEMPLATE_COULD_NOT_BE_PUBLISHED: {messageKey: "eob.template.publishing.error"},

    VARIANT_MISSING: {messageKey: "eob.no.variant.error"},

    VTX_EXECUTION_FAILED: {messageKey: "eob.vtx.search.failed.error"},
    VTX_OBJECTS_FETCH_FAILED: {messageKey: "eob.vtx.search.failed.error"},
    VTX_AUTO_COMPLETION_FAILED: {messageKey: "eob.vtx.autocomplete.failed.error"},

    ABO_OBJ_NOT_FOUND: {messageKey: "eob.abo.object.not.found.error"},
    ABO_PROCESS_ERROR: {messageKey: "eob.abo.processed.error"},

    WORKFLOW_NOT_ACTIVATED: {messageKey: "eob.workflow.not.activated.error"},
    WORKFLOW_WORKITEM_GONE: {messageKey: "eob.workitem.gone.error"},
    WORKFLOW_WORKITEM_MISSING: {messageKey: "eob.workitem.missing.error"},
    WORKFLOW_WORKITEM_GET_FAILED: {messageKey: "eob.workitem.load.failed.error"},
    WORKFLOW_WORKITEM_PERSONALIZATION_FAILED: {messageKey: "eob.workitem.personalization.failed.error"},
    WORKFLOW_WORKITEMS_PERSONALIZATION_FAILED: {messageKey: "eob.workitem.personalization.failed.error"},
    WORKFLOW_WORKITEM_PERSONALIZATION_UNAUTHORIZED: {messageKey: "eob.workitem.personalization.unauth.error"},
    WORKFLOW_WORKITEM_SET_READ_FAILED: {messageKey: "eob.workitem.mark.read.error"},
    WORKFLOW_WORKITEMS_SET_READ_FAILED: {messageKey: "eob.workitem.mark.read.error"},
    WORKFLOW_WORKITEM_FORWARDING_FAILED: {messageKey: "eob.workitem.forwarding.error"},
    WORKFLOW_WORKITEM_PERSONALIZATION_UNAUTHORIZED_SPECIFIC: {messageKey: "eob.workitem.personalization.unauth.user.error", useCustomReplacement: true},
    WORKFLOW_WORKITEM_ASSIGN_PERFORMERS_FAILED: {messageKey: "eob.workitem.assign.error"},
    WORKFLOW_PROCESS_UNKNOWN: {messageKey: "eob.workflow.process.unknown.error"},
    WORKFLOW_SET_SUBSTITUTES_FAILED: {messageKey: "eob.workflow.substitutes.error"},

    // endregion
    // region WebClient internal

    WEB_ERROR_UNDEFINED: {messageKey: "eob.undefined.error", type: "error"},
    WEB_HTTP_REQUEST_CANCELED: {messageKey: "eob.undefined.error", type: "error"}, // no translation because it should not be displayed
    WEB_USER_ABORTED: {messageKey: "eob.running.process.activity.state.TERMINATED", type: "error"}, // The messageKey is irrelevant. We can use the constant in catch blocks

    WEB_LOGIN_TIMEOUT: {messageKey: "eob.login.timeout", type: "warning"},
    WEB_URL_FORMAT: {messageKey: "eob.login.url.format"},
    WEB_LOGIN_FAILED: {messageKey: "eob.login.login.failed", type: "warning"},
    WEB_WINDOWS_LOGIN_FAILED: {messageKey: "eob.login.windows.login.failed", type: "warning"},

    WEB_PARAMETER_MISSING: {messageKey: "eob.missing.input.parameter.error"},
    WEB_PARAMETER_INVALID: {messageKey: "eob.invalid.input.parameter.error"},

    WEB_OBJECT_NOT_FOUND: {messageKey: "eob.object.not.found.web.error"},
    WEB_OBJECT_FOLDER_NOT_FOUND: {messageKey: "eob.folder.not.found"},
    WEB_OBJECT_ALREADY_EXISTS: {messageKey: "eob.folder.tree.link.item.already.exists"},
    WEB_OBJECT_TYPE_NOT_FOUND: {messageKey: "eob.objecttype.not.found.web.error"},

    WEB_NO_OBJECT_OR_PARENT: {messageKey: "eob.no.object.id.parent.id.error"},
    WEB_NO_VARIANTS_OBJECTTYPE: {messageKey: "eob.no.variants.objecttype.error"},
    WEB_NO_VARIANTS_WORKFLOW_TRAY: {messageKey: "eob.no.variants.workflow.tray.error"},
    WEB_OBJECT_HAS_NO_CONTENT: {messageKey: "eob.object.no.content.error"},
    WEB_OBJECT_CONTENT_MISSING: {messageKey: "eob.file.not.found.error"},
    WEB_OBJECT_LOCKED_OTHER: {messageKey: "eob.object.locked.by.other.error"},
    WEB_OBJECT_NOT_LOCKED: {messageKey: "eob.object.not.locked.error"},
    WEB_NO_SEARCH_FIELD_SPECIFIED: {messageKey: "eob.no.field.specified.error"},
    WEB_NO_LOCATION_PRESENT: {messageKey: "eob.no.location.present.error"},
    WEB_NO_LOCATION_PRESENT_SPECIFIC: {messageKey: "eob.no.location.present.specific.error"},
    WEB_NO_COMPATIBLE_OBJECTTYPE: {messageKey: "eob.external.tray.source.no.matching.object.type", type: "info"},
    WEB_MAX_OBJECTTYPE: {messageKey: "eob.action.object.inserts.error", type: "info"},
    WEB_MOVE_PARENT_IN_CHILD: {messageKey: "eob.action.move.into.child.error"},
    WEB_VALIDATION_DETECTED_ERRORS: {messageKey: "eob.validation.failed.warn", type: "warning"},
    WEB_FORM_SCRIPT_RETURNED_ERROR: {messageKey: "eob.form.script.returned.error"},

    WEB_INSERT_FAILED: {messageKey: "eob.action.insert.error"},
    WEB_MOVE_FAILED: {messageKey: "eob.action.move.error"},
    WEB_CREATE_LOCATION_FAILED: {messageKey: "eob.folder.tree.link.item.failed"},

    WEB_USER_NOT_AUTHORIZED: {messageKey: "eob.action.process.missing.rights.error"},
    WEB_USER_NOT_ALLOWED_SAVE_SETTINGS: {messageKey: "eob.user.not.allowed.save.settings.error"},
    WEB_MISSING_ROLE_FOR_WEBCLIENT_USAGE: {messageKey: "eob.init.error.missing.role.for.webclient.usage"},

    WEB_NO_WORKFLOW_USER: {messageKey: "eob.no.workflow.user.error"},

    WEB_APPCONNECTOR_GENERAL_ERROR: {messageKey: "eob.init.error.appconnector.unavailable"},

    WEB_INVALID_MOBILE_PROFILE: {messageKey: "modal.mobile.profile.form.invalid.profile"},

    WEB_OFFLINE_ERROR: {messageKey: "eob.service.function.offline.not.available"},

    WEB_UNKNOWN_MIMETYPE: {messageKey: "eob.error.unknown.mimetype"},
    WEB_UNKNOWN_APP: {messageKey: "eob.error.unknown.app"},
    WEB_CHECKOUT_FAILED: {messageKey: "modal.edit.content.initialize.error"},
    WEB_EDIT_CONTENT_NOT_ALLOWED: {messageKey: "eob.action.edit.content.not.allowed"},
    WEB_EXPORT_FAILED: {messageKey: "eob.action.export.failed"},

    WEB_NOT_IMPLEMENTED: {messageKey: "eob.error.not.implemented"},

    WEB_SYNCHRONIZATION_ERROR: {messageKey: "eob.sync.offline.error"},
    WEB_SYNCHRONIZATION_ABORTED: {messageKey: "eob.sync.offline.aborted"},
    WEB_SYNCHRONIZATION_FILESIZE_LIMIT: {messageKey: "eob.sync.offline.filesize.limit"},

    WEB_EX_SOURCE_INVALID_METADATA_FILE: {messageKey: "eob.external.tray.source.invalid.metadata.file"},
    WEB_EX_SOURCE_MAINTYPE_MATCHING_ERROR: {messageKey: "eob.external.tray.source.maintype.matching.error"},
    WEB_EX_SOURCE_NO_MATCHING_OBJECT_TYPE: {messageKey: "eob.external.tray.source.no.matching.object.type"},

    WEB_LICENSE_SERVICE_NOT_AVAILABLE: {messageKey: "eob.init.error.license.service"},
    WEB_LICENSE_OUT_OF_LICENSES: {messageKey: "eob.init.error.out.of.licenses"}

    // endregion
};