(function () {
    

    require("COMPONENTS_PATH/eob-detailsviewer/views/dv-history/dv.history.dir.js");
    require("COMPONENTS_PATH/eob-detailsviewer/views/dv-indexdata/dv.indexdata.dir.js");
    require("COMPONENTS_PATH/eob-detailsviewer/views/dv-notes/dv.notes.dir.js");
    require("COMPONENTS_PATH/eob-detailsviewer/views/dv-revisits/dv.revisits.dir.js");
    require("COMPONENTS_PATH/eob-detailsviewer/views/dv-workflow/dv.workflow.dir.js");

    angular.module("eob.framework").directive("dvContent", DvContent);

    DvContent.$inject = ["$compile"];

    function DvContent($compile) {
        return {
            restrict: "E",
            template: require("!raw-loader!./dv.content.html"),
            link(scope, element) {
                let noDmsElement;
                let pageContentElement = element.find(".dv-content-container");
                let isMobileDms = (navigator.userAgent.indexOf("mobileDMS") != -1);

                scope.$on("dv.no.dms.object", toggleNoDmsObject);
                scope.initalLoad = true;


                if (!isMobileDms) {
                    noDmsElement = $compile(angular.element("<eob-dashlet-default-page class='no-dms-object invisible'></eob-dashlet-default-page>"))(scope);
                    element.find(".dv-content-container").append(noDmsElement);
                }

                /**
                 * Display the default page or hide it again.
                 *
                 * @param {Event} event - Placeholder for the js event.
                 * @param {{ isDmsObject: boolean }} data - Whether default page is shown or not.
                 */
                function toggleNoDmsObject(event, data) {
                    let isDMSObject = data.isDmsObject;
                    scope.isDmsObject = isDMSObject;
                    if (isDMSObject) {
                        if (noDmsElement != null) {
                            noDmsElement.addClass("invisible");
                        }
                        element.removeClass("no-object");
                        pageContentElement.removeClass("no-object");
                    } else {
                        if (noDmsElement != null) {
                            noDmsElement.removeClass("invisible");
                        }
                        element.addClass("no-object");
                        pageContentElement.addClass("no-object");
                    }
                    scope.initalLoad = false;
                }
            }
        };
    }
})();
