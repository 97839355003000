import {StateService} from "@uirouter/core";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {Inject, Injectable, Injector} from "@angular/core";
import {
    TodoCacheManagerService,
    TodoContextItem,
    TodoEnvironmentService,
    TodoModalDialogService,
    TodoStateHistoryManager
} from "INTERFACES_PATH/any.types";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {
    ContextMenuItem,
    ContextType,
    InboxContextMenuItem,
    WorkflowContextMenuItem
} from "MODULES_PATH/context-menu/interfaces/context-menu.interface";
import {DmsContentStore} from "INTERFACES_PATH/dms-content-store.interface";
import {BackendSubscriptionService} from "CORE_PATH/backend/services/inbox/backend-subscription.service";
import {BackendRevisitService} from "CORE_PATH/backend/services/inbox/backend-revisit.service";
import {BackendWorkflowService} from "CORE_PATH/backend/services/inbox/backend-workflow.service";
import {
    ConfirmationObject,
    InboxActionContextMenuItem,
    AboGroupPayload,
    StartableWorkflow
} from "CORE_PATH/interfaces/inbox-action.service.interface";
import {catchError, take, tap} from "rxjs/operators";
import {Observable, of, Subscription} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class InboxActionService {
    readonly translateFn: TranslateFnType;

    constructor(@Inject("stateHistoryManager") protected stateHistoryManager: TodoStateHistoryManager,
                @Inject("cacheManagerService") protected cacheManagerService: TodoCacheManagerService,
                @Inject("environmentService") protected environmentService: TodoEnvironmentService,
                @Inject("$state") protected $state: StateService,
                @Inject("$filter") protected $filter: ng.IFilterService,
                private injector: Injector,
                protected notificationsService: NotificationsService,
                protected clientService: ClientService,
                protected asIniService: AsIniService,
                protected viewerService: ViewerService,
                protected messageService: MessageService,
                protected backendSubscriptionService: BackendSubscriptionService,
                protected backendRevisitService: BackendRevisitService,
                protected backendWorkflowService: BackendWorkflowService) {
        this.translateFn = this.$filter("translate");
    }

    private handleError = (error: Error, contextItems: TodoContextItem[], compareFn: (a: DmsDocument | DmsContentStore, b: {item: InboxActionContextMenuItem}) => number, errorMsgKey: string, successCallback: (a: TodoContextItem[]) => void): void => {
        const succeededItems: TodoContextItem[] = this.notificationsService.backendMulti(error, contextItems, compareFn, errorMsgKey);
        successCallback(succeededItems);
    };

    /**
     * Removes the notifications for the given documents from the associated inboxes
     *
     * @param {TodoContextItem | TodoContextItem[]} contextItems - One or multiple subscriptions.
     * @returns {void} Resolved once the subscriptions are removed.
     */
    removeNotifications = (contextItems: TodoContextItem | TodoContextItem[]): void => {
        if (!Array.isArray(contextItems)) {
            contextItems = [contextItems];
        }
        const data: InboxActionContextMenuItem[] = [];
        const success: (items: DmsDocument[]) => void = (succeededContextItems) => {
            if (succeededContextItems.length == 0) {
                return;
            }

            const dmsDocuments: DmsDocument[] = [];

            for (const {model: contextModel} of succeededContextItems) {
                const dmsDocument: DmsDocument | null = this.cacheManagerService.dmsDocuments.getById(contextModel.osid),
                    subscriptions: DmsDocument[] = dmsDocument.model.subscriptions || [];

                // filter all subscriptions first and remove the context items afterwards
                subscriptions.filter(({model: subModel}: DmsDocument) => (subModel.osid == contextModel.osid && subModel.eventDate == contextModel.eventDate && subModel.action == contextModel.action))
                    .forEach((subscription: DmsDocument) => {
                        dmsDocument.api.removeContextItem(subscription.model.guid, "subscriptions");
                    });

                dmsDocuments.push(dmsDocument);
            }

            this.cacheManagerService.dmsDocuments.add(dmsDocuments);
        };

        for (const contextItem of contextItems) {

            let found: boolean = false;

            for (const i of data) {
                if ((i.id == contextItem.model.osid) && (i.eventDate == contextItem.model.eventDate) && (i.senderId == contextItem.model.sender.id)) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                data.push({
                    id: contextItem.model.osid,
                    eventDate: contextItem.model.eventDate,
                    senderId: contextItem.model.sender.id
                } as InboxActionContextMenuItem);
            }
        }

        const result: Observable<unknown> = this.backendSubscriptionService.removeSubscriptions(data);

        result.pipe(take(1), tap(() => {
            success(contextItems);
        }),catchError(({error}) => {
            const compareFn: (a: DmsDocument, b: { item: InboxActionContextMenuItem}) => number = (a, b) => +(a.model.id == b.item.id.toString() && a.model.eventDate == b.item.eventDate);
            this.handleError(error, contextItems, compareFn, "eob.action.notification.remove.error", success);
            return of({error: true});
        })).subscribe();
    };

    /**
     * Marks one or more inbox items as read.
     *
     * @param {InboxContextMenuItem[]} items - One or multiple revisits or subscriptions.
     * @param {ContextType} contextType - State context type (Subscription/Revisit).
     */
    markRead = (items: InboxContextMenuItem[], contextType: ContextType): void => {
        this.markReadUnread(items, false, contextType);
    };

    /**
     * Marks one or more inbox items as unread.
     *
     * @param {InboxContextMenuItem[]} items - One or multiple revisits or subscriptions.
     * @param {ContextType} contextType - State context type (Subscription/Revisit).
     */
    markUnread = (items: InboxContextMenuItem[], contextType: ContextType): void => {
        this.markReadUnread(items, true, contextType);
    };

    /**
     * Marks one or more inbox items as (un)read.
     *
     * @param {InboxContextMenuItem[]} items - One or multiple revisits or subscriptions.
     * @param {boolean} markUnread
     * @param {ContextType} contextType - State context type (Subscription/Revisit).
     */
    private markReadUnread = (items: InboxContextMenuItem[], markUnread: boolean, contextType: ContextType): void => {
        const success: (successItems: InboxContextMenuItem[]) => void = (successItems) => {
            if (successItems.length == 0) {
                return;
            }

            const refreshIds: string[] = [];

            for (const i of successItems) {
                i.model.read = !markUnread;
                refreshIds.push(i.model.osid);
            }

            this.cacheManagerService.dmsDocuments.executeListeners(refreshIds);
        };

        const data: InboxActionContextMenuItem[] = [];

        for (const i of items) {
            data.push({
                id: i.model.osid,
                eventDate: i.model.eventDate
            } as InboxActionContextMenuItem);
        }

        let result: Observable<unknown>;

        if (contextType == ContextType.REVISIT) {
            if (markUnread) {
                result = this.backendRevisitService.markUnreadRevisits(data);
            } else {
                result = this.backendRevisitService.markReadRevisits(data);
            }
        } else if (markUnread) {
            result = this.backendSubscriptionService.markUnreadSubscriptions(data);
        } else {
            result = this.backendSubscriptionService.markReadSubscriptions(data);
        }

        result.pipe(take(1), tap(() => {
            success(items);
        }),catchError(({error}) => {
            const compareFn: (a: DmsContentStore, b: {item: InboxActionContextMenuItem}) => number = (a: DmsContentStore, b: {item: InboxActionContextMenuItem}): number => +(a.original.id == b.item.id.toString() && a.original.eventDate == b.item.eventDate);
            this.handleError(error, items, compareFn, "eob.action.notification.remove.error", success);
            return of({error: true});
        })).subscribe();
    };

    /**
     * Toggles the edited status of the passed items.
     *
     * @param {InboxContextMenuItem[]} contextItems - One or multiple revisits or subscriptions.
     * @param {ContextType} contextType - State context type (Subscription/Revisit).
     * @returns {void}
     */
    markAsEditedUnedited = (contextItems: InboxContextMenuItem[], contextType: ContextType): void => {
        if (!Array.isArray(contextItems)) {
            contextItems = [contextItems];
        }

        let reopenNotifications: boolean = false;

        const success: (items: InboxContextMenuItem[]) => void = (succeededContextItems: InboxContextMenuItem[]) => {
            if (succeededContextItems.length == 0) {
                return;
            }

            const dmsDocuments: DmsDocument[] = [];

            for (const contextItem of succeededContextItems) {
                const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(contextItem.model.osid);
                dmsDocuments.push(dmsDocument);

                if (contextType == ContextType.REVISIT) {
                    dmsDocument.api.removeContextItem(contextItem.model.guid, "revisits");
                } else {
                    contextItem.model.confirmed = !reopenNotifications;
                }
            }

            this.cacheManagerService.dmsDocuments.add(dmsDocuments);
        };

        // we need to switch here because the multiple confirmation does not work
        // the user has to confirm that he knows what he does
        // so we either show a confirmation box with an optional password field
        if (contextItems.length === 1 && contextItems[0].model.confirmationType != "NO_CONFIRMATION") {
            this.markSingleAsEditedUnedited(contextItems[0], contextType);
        } else {
            // multiple confirmation of entries
            // we are here because the contextmenu service says, that there is no need to show the
            // confirmation box --> therefore, fire and forget ;)
            const data: InboxActionContextMenuItem[] = [];

            reopenNotifications = true;

            for (const i of contextItems) {

                if (!i.model.confirmed) {
                    reopenNotifications = false;
                }

                data.push({
                    id: i.model.osid,
                    eventDate: i.model.eventDate
                } as InboxActionContextMenuItem);
            }

            if (reopenNotifications) {
                for (const i of data) {
                    i.open = true;
                    // only set the reopen flag if every notification has already been set
                }
            }

            let result: Observable<unknown>;

            if (contextType == ContextType.REVISIT) {
                result = this.backendRevisitService.markAsEditedUneditedRevisits(data);
            } else {
                result = this.backendSubscriptionService.markAsEditedUneditedSubscriptions(data);
            }

            result.pipe(take(1), tap(() => {
                success(contextItems);
            }),catchError(({error}) => {
                const compareFn: (a: DmsContentStore, b: {item: InboxActionContextMenuItem}) => number = (a, b) => +(a.original.id == b.item.id.toString() && a.original.eventDate == b.item.eventDate);
                this.handleError(error, contextItems, compareFn, "eob.action.notification.markconfirmed.error", success);
                return of({error: true});
            })).subscribe();
        }
    };

    /**
     * Toggles the edited status of the given item. If a password confirmation is required, the user has to supply it through a modal dialog.
     *
     * @param {InboxContextMenuItem} contextItem - One revisit or subscription.
     * @param {ContextType} contextType - State context type (Subscription/Revisit).
     */
    markSingleAsEditedUnedited = (contextItem: InboxContextMenuItem, contextType: ContextType): void => {
        const confirmWithPassword: boolean = contextItem.model.confirmationType == "CONFIRMATION_PASSWORD";

        const confirmCallback: (confirmationObject?: ConfirmationObject) => void = (confirmationObject: ConfirmationObject) => {

            let result: Observable<void>;

            if (contextType == ContextType.REVISIT) {
                result = this.backendRevisitService.markSingleAsEditedUneditedRevisits(contextItem, confirmationObject);
            } else {
                result = this.backendSubscriptionService.markSingleAsEditedUneditedSubscriptions(contextItem, confirmationObject);
            }

            result.pipe(take(1), tap(() => {

                const osid: string = contextItem.model.osid;
                const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(osid);

                if (contextType == ContextType.REVISIT) {
                    dmsDocument.api.removeContextItem(contextItem.model.guid, contextType);
                } else {
                    // change the original content and rebuild
                    contextItem.model.confirmed = true;
                }

                this.cacheManagerService.dmsDocuments.add(dmsDocument);
            }),catchError(({error}) => {
                // forbidden - the user entered an invalid password
                // respond to the modal dialog what to do
                if ((error.data != void 0) && (error.data.errorCode == 32)) {
                    this.notificationsService.error(this.translateFn("modal.confirm.password.error"));
                } else if ((error.data != void 0) && (error.data.errorCode == 34)) {
                    this.notificationsService.error(this.translateFn("modal.confirm.blocked.error"), void 0, 15000);
                    setTimeout(() => {
                        this.clientService.logoutAsync();
                    }, 15000);
                } else {
                    this.notificationsService.error(this.translateFn("eob.action.notification.markconfirmed.error"));
                }
                return of({error: true});
            })).subscribe();
        };
        const modalDialogService: TodoModalDialogService = this.injector.get("modalDialogService");
        if (confirmWithPassword) {
            modalDialogService.showPasswordDialog(confirmWithPassword, confirmCallback);
        } else {
            modalDialogService.infoDialog(this.translateFn("eob.action.modal.confirm.title"),
                this.translateFn("eob.action.notification.confirmation.message"),
                this.translateFn("modal.button.no"),
                this.translateFn("modal.button.yes")).then(() => {
                confirmCallback();
            }).catch((_) => {
            });
        }
    };

    /**
     * Removes the subscription for the given item(s)
     *
     * @param {InboxContextMenuItem[]} contextItems {@link DmsDocument}, possibly without API functions
     * @returns {void}
     */
    clearAbo = (contextItems: InboxContextMenuItem[]): void => {
        const success: (items: InboxContextMenuItem[]) => void = (succeededContextItems: InboxContextMenuItem[]) => {
            if (succeededContextItems.length == 0) {
                return;
            }

            const dmsDocuments: DmsDocument[] = [];

            for (const succeededContextItem of succeededContextItems) {
                const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(succeededContextItem.model.osid);
                dmsDocuments.push(dmsDocument);

                // const successContext: string = this.$state.params.type == "revisit" ? "revisits" : "subscriptionObjects";
                //
                // if (successContext == "subscriptionObjects") {
                dmsDocument.api.removeContextItem(succeededContextItem.model.guid, ContextType.SUBSCRIPTIONOBJECTS);
                // } else {
                //     succeededContextItem.model.confirmed = true;
                // }
            }
            this.cacheManagerService.dmsDocuments.add(dmsDocuments);
        };

        const payload: AboGroupPayload[] = [];
        let couldNotDeleteCount: number = 0;
        const itemsDeleted: InboxContextMenuItem[] = [];
        const hasRole: boolean = this.environmentService.userHasRole("R_CLNT_ABO_ADMIN");

        for (const item of contextItems) {

            // if (item.original.userGuid == void 0 || hasRole) {
            if (hasRole) {
                itemsDeleted.push(item);

                payload.push({
                    aboGroup: item.model.aboGroup
                });
            } else {
                couldNotDeleteCount++;
            }
        }

        const result: Observable<unknown> = this.backendSubscriptionService.deleteSubscriptionObjects(payload);

        result.pipe(take(1), tap(() => {
            success(itemsDeleted);

            if (couldNotDeleteCount > 0) {
                let message: string = this.translateFn("eob.action.abo.clear.success.not.all");
                message = message.replace(/%d1/, String(contextItems.length - couldNotDeleteCount)).replace(/%d2/, contextItems.length.toString());

                this.notificationsService.success(message);
            } else if (itemsDeleted.length == 1) {
                this.notificationsService.success(this.translateFn("eob.action.abo.clear.success.single"));
            } else {
                this.notificationsService.success(this.translateFn("eob.action.abo.clear.success"));
            }
        }),catchError(({error}) => {
            const compareFn: (a: AboGroupPayload, b: {item: InboxActionContextMenuItem}) => number = (a, b) => +(a.aboGroup == b.item.aboGroup);

            const succeededItems: TodoContextItem[] = this.notificationsService.backendMulti(error, itemsDeleted, compareFn, "eob.action.abo.clear.error");
            success(succeededItems);
            return of({error: true});
        })).subscribe();
    };

    /**
     * Starts a new workflow with the given items
     *
     * @param {any | any[]} contextItems {@link DmsDocument}, possibly without API functions
     * @param {string} workflowId ID of the workflow to be started
     * @returns {void}
     */
    startWorkflow = (contextItems: InboxContextMenuItem | InboxContextMenuItem[], workflowId?: string): void => {
        if (!Array.isArray(contextItems)) {
            contextItems = [contextItems];
        }

        let startableWorkflow: StartableWorkflow;
        let osid: string;

        if (workflowId) {
            startableWorkflow = {
                id: workflowId,
                files: [] as string[]
            };

            for (const contextItem of contextItems) {
                osid = contextItem.model.id;
                startableWorkflow.files.push(contextItem.model.id);
            }
        } else if (contextItems[0].workflowId) {
            startableWorkflow = {
                id: contextItems[0].workflowId,
                files: undefined
            };
        }

        const result: Observable<unknown> = this.backendWorkflowService.startWorkflow(this.environmentService.wfClientType, startableWorkflow);

        result.pipe(take(1), tap(() => {
            this.viewerService.refreshDetails(Number(osid));
            this.notificationsService.success(this.translateFn("eob.action.workflow.start.success"));
        }),catchError(({error}) => {
            this.notificationsService.backendError(error, "eob.action.workflow.start.error");
            return of({error: true});
        })).subscribe();
    };

    /**
     * Opens a running workflow
     *
     * @param {WorkflowContextMenuItem} item
     * @returns {void}
     */
    openRunningWorkflow = (item: WorkflowContextMenuItem): void => {
        if (item.read === false) {
            this.markWfItemRead([item]);
        }

        this.stateHistoryManager.goToState("workflow", {id: item.id}, {
            type: "workflow",
            config: {caller: this.stateHistoryManager.getCurrentStateId()}
        });
    };

    /**
     * Personalizes a workflow. If reloadCurrentState is set to true, the state is refreshed to reflect the personalization change.
     *
     * @param {WorkflowContextMenuItem | WorkflowContextMenuItem[]} contextItems
     * @param {boolean=true} reloadCurrentState
     * @returns {Promise<any>} The personalized workflow items or an error, if the personalization failed.
     */
    personalizeWorkflows = (contextItems: WorkflowContextMenuItem | WorkflowContextMenuItem[], reloadCurrentState: boolean = true): void => {
        const success: (items: WorkflowContextMenuItem | WorkflowContextMenuItem[]) => void = (innerItems) => {
            if (Array.isArray(innerItems) && innerItems.length == 0) {
                return;
            }

            // we only need to change the indexdata in case we are inside the inbox
            // this might be called anywhere else (entry action or from inside the workflow)
            if (this.$state.current.name == "hitlist.inbox") {
                if (Array.isArray(innerItems)) {
                    for (const i of innerItems) {
                        i.personalized = this.environmentService.getSessionInfo().username.toUpperCase();
                    }
                } else {
                    innerItems.personalized = this.environmentService.getSessionInfo().username.toUpperCase();
                }
            }
        };

        if (!Array.isArray(contextItems)) {
            contextItems = [contextItems];
        }

        const items: InboxActionContextMenuItem[] = [];

        for (const {id} of contextItems) {
            items.push({id} as InboxActionContextMenuItem);
        }

        const result: Observable<void> = this.backendWorkflowService.personalizeWorkflows(this.environmentService.wfClientType, items);

        result.pipe(take(1), tap(() => {
            success(contextItems);

            if (this.$state.params.type == "workflow" && reloadCurrentState) {
                this.$state.reload();
            }
            return contextItems;
        }),catchError(({error}) => {
            const compareFn: (a: ContextMenuItem, b: {item: InboxActionContextMenuItem}) => number = (a, b) => +(a.id == b.item.id);
            const succeededItems: TodoContextItem[] = this.notificationsService.backendMulti(error, contextItems as any[], compareFn, "eob.action.personalize.error");
            success(succeededItems);
            throw error;
        })).subscribe();
    };

    /**
     * Depersonalize the workItems that belong to the given workflow inbox hitlist items.
     * Afterwords update the workflow inbox hitlist.
     *
     * @param {WorkflowContextMenuItem[]} items - Workflow inbox hitlist items.
     * @returns {void}
     */
    depersonalizeInboxWorkflows = (items: WorkflowContextMenuItem[]): Subscription => {

        const postRequest: WorkflowContextMenuItem[] = [];
        for (const i of items) {
            if (i.personalized) {
                postRequest.push({id: i.id} as WorkflowContextMenuItem);
            }
        }

        const successCb: (depersonalizedItems: WorkflowContextMenuItem[]) => void = (depersonalizedItems: WorkflowContextMenuItem[]) => {
            for (const depersonalizedItem of depersonalizedItems) {
                for (const item of items) {
                    if (item.id == depersonalizedItem.id) {
                        item.personalized = "";
                        break;
                    }
                }
            }
            this.$state.reload();
        };

        return this.depersonalizeWorkflows(postRequest, successCb).subscribe();
    };

    /**
     * Depersonalize workItems.
     *
     * @param {WorkflowContextMenuItem[]} contextItems - An array with objects that contain a workItem Id.
     * @param {function} successCb - A function that is called with the successfully depersonalized workItems.
     * @returns {Observable<void>}
     */
    depersonalizeWorkflows = (contextItems: WorkflowContextMenuItem[], successCb?: (items: WorkflowContextMenuItem[]) => void): Observable<void> => {
        if (!Array.isArray(contextItems)) {
            contextItems = [contextItems];
        }

        const result: Observable<void> = this.backendWorkflowService.depersonalizeWorkflows(this.environmentService.wfClientType, contextItems);

        return result.pipe(take(1), tap(() => {
            if (successCb) {
                successCb(contextItems);
            }
        }),catchError(({error}) => {
            const compareFn: (a: ContextMenuItem, b: {item: InboxActionContextMenuItem}) => number = (a, b) => +(a.id == b.item.id);

            const succeededItems: TodoContextItem[] = this.notificationsService.backendMulti(error, contextItems, compareFn, "eob.action.depersonalize.error");

            if (contextItems.length > 0 && successCb != void 0) {
                successCb(succeededItems);
            }
            throw error;
        }));
    };

    /**
     * Marks a workflow item as read
     *
     * @param {WorkflowContextMenuItem[]} items - One or more workflow items.
     */
    markWfItemRead = (items: WorkflowContextMenuItem[]): void => {
        this.markWfItemReadUnread(items, true);
    };

    /**
     * Marks a workflow item as unread
     *
     * @param {WorkflowContextMenuItem[]} items - One or more workflow items.
     */
    markWfItemUnread = (items: WorkflowContextMenuItem[]): void => {
        this.markWfItemReadUnread(items, false);
    };

    /**
     * Sets the read status of the given item(s) depending on the second parameter
     *
     * @param {WorkflowContextMenuItem[]} items - One or more workflow items.
     * @param {boolean} isRead whether the given item(s) should be marked as read.
     */
    markWfItemReadUnread = (items: WorkflowContextMenuItem[], isRead: boolean): void => {
        for (const i of items) {
            this.asIniService.setUnReadWorkflow(i.id, isRead);
            i.read = isRead;
        }
        this.messageService.broadcast(Broadcasts.INBOX_INDEXDATA_CHANGED, items);
    };
}
