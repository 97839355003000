export class CordovaFilesystemUtil {
    static getMetadata(path: string, filename: string): Promise<Metadata> {
        let resolve: (m: Metadata) => void;
        let reject: any;

        const promise: Promise<Metadata> = new Promise((intResolve: (m: Metadata) => void, intReject: (e: DOMError) => void): void => {
resolve = intResolve; reject = intReject; 
});
        (window as Window).resolveLocalFileSystemURL(`${path}${filename}`, (entry: Entry): void => {
            entry.getMetadata(resolve, reject);
        }, reject);

        return promise;
    }

    static read = async (path: string, file: string, isText: boolean): Promise<any> => {

        function getFileReader() {
            const preZoneFileReader = (window.FileReader as any).__zone_symbol__OriginalDelegate;
            if (preZoneFileReader) {
                return new preZoneFileReader();
            } else {
                return new FileReader();
            }
        }

        return new Promise((resolve, reject) => {
            if ((/^\//.test(file))) {
                reject("file-name cannot start with \/");
            }
    
            try {
                window.resolveLocalFileSystemURL(path, (fileSystem: any) => {
                    fileSystem.getFile(file, {create: false}, (fileEntry) => {
                        fileEntry.file((fileData) => {
                            const reader = getFileReader();
                            if (isText) {
                                reader.readAsText(fileData);
                            } else {
                                reader.readAsArrayBuffer(fileData);
                            }
                            reader.onloadend = (evt) => {
                                if (evt.target.result != undefined || evt.target.result != null) {
                                    resolve(evt.target.result);
                                } else if (evt.target.error != undefined || evt.target.error != null) {
                                    reject(evt.target.error);
                                } else {
                                    reject({code: null, message: "READER_ONLOADEND_ERR"});
                                }
                            };
                        });
                    }, (error) => {
                        reject(error);
                    });
                }, (err) => {
                    reject(err);
                });
            } catch (e) {
                reject(e);
            }
        });
    };

}
