import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EobAutocompleteDirective} from "MODULES_PATH/autocomplete/directives/eob-autocomplete.directive";
import {AutocompleteWrapperComponent} from "MODULES_PATH/autocomplete/components/autocomplete-wrapper/autocomplete-wrapper.component";

@NgModule({
    declarations: [EobAutocompleteDirective, AutocompleteWrapperComponent],
    imports: [
        CommonModule
    ],
    exports: [EobAutocompleteDirective, AutocompleteWrapperComponent],
    entryComponents: [AutocompleteWrapperComponent]
})
export class AutocompleteModule {
}
