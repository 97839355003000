export enum RawTreeConfig {
    ZEILE = "ZEILE",
    ICON = "ICON",
    USEINTERMEDIATENODES = "USEINTERMEDIATENODES",
    MULTISELEKTIONSEPARATOR = "MULTISELEKTIONSEPARATOR",
    SEPARATOR = "SEPARATOR",
    MULTISELEKTION ="MULTISELEKTION",
    SORTIERUNG = "SORTIERUNG",
    DESELECTWHENCOLLAPSE = "DESELECTWHENCOLLAPSE",
    SQL = "SQL",
    VALIDATE = "VALIDATE"
}