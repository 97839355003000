import {Component, ElementRef, OnDestroy, ViewChild} from "@angular/core";
import {Input, Renderer2} from "@angular/core";
import {BubbleBoxStyle} from "INTERFACES_PATH/validation.interface";

@Component({
    selector: "eob-standalone-validation-bubble",
    templateUrl: "./standalone-validation-bubble.component.html",
    styleUrls: ["./standalone-validation-bubble.component.scss"]
})
export class StandaloneValidationBubbleComponent implements OnDestroy {
    @ViewChild("bubble", {static: false}) bubbleElement: ElementRef;
    @ViewChild("bubbleTitle", {static: false}) bubbleTitle: ElementRef;
    @ViewChild("bubbleMessage", {static: false}) bubbleMessage: ElementRef;

    @Input() enablePositioning: boolean = false;

    constructor(private renderer: Renderer2) {
    }

    ngOnDestroy(): void {
        this.hideBubble();
    }

    setValidationMessage(title: string, message: string): void {
        this.bubbleTitle.nativeElement.textContent = title;
        this.bubbleMessage.nativeElement.textContent = message;
    }

    showBubble(): void {
        this.renderer.addClass(this.bubbleElement.nativeElement, "in");

    }

    hideBubble(): void {
        this.renderer.removeClass(this.bubbleElement.nativeElement, "in");
    }

    setPosition(css: BubbleBoxStyle): void {
        for (const s in css) {
            this.bubbleElement.nativeElement.style[s] = css[s];
            if (this.bubbleElement.nativeElement.style[s] !== css[s]) {
                this.bubbleElement.nativeElement.style[s] = `${css[s].toString()}px`;
            }
        }
    }
}
