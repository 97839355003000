import {Component, Inject, OnInit, ViewChild, AfterViewInit, OnDestroy} from "@angular/core";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {EobDashletContainerComponent} from "MODULES_PATH/dashlet/components/eob-dashlet-container/eob-dashlet-container.component";
import {TodoCacheManagerService, TodoEnvironmentService, TodoSearchService} from "INTERFACES_PATH/any.types";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {PopupViewerWindow} from "MODULES_PATH/dashlet/interfaces/popup-viewer-window.interface";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {CustomDashletConfig} from "MODULES_PATH/dashlet/interfaces/custom-dashlet-config.interface";
import {ThemeService} from "MODULES_PATH/theme/theme.service";
import {Subscription} from "rxjs";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";

// eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/typedef
declare let window: PopupViewerWindow;

@Component({
    selector: "eob-detached-dashlet-container",
    templateUrl: "./eob-detached-dashlet-container.component.html",
})
export class EobDetachedDashletContainerComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(EobDashletContainerComponent, {static: false}) eobDashletContainerComponent: EobDashletContainerComponent;

    private readonly translate: TranslateFnType;
    private readonly gListenerGuid: string = this.cacheManagerService.dmsDocuments.attachListener([], this.onDmsDocumentChanged.bind(this));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private resizeObserver: any; // Wasn't able to get the type definition to work, hence any is used here
    private subscriptions: Subscription = new Subscription();
    private scopeSubscriptions: Array<()=>void> = [];

    // eslint-disable-next-line max-params
    constructor(
        private clientService: ClientService,
        @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
        @Inject("searchService") private searchService: TodoSearchService,
        protected viewerService: ViewerService,
        @Inject("offlineCacheService") private offlineCacheService: OfflineCacheService,
        @Inject("environmentService") private environmentService: TodoEnvironmentService,
        @Inject("$filter") private $filter: ng.IFilterService,
        @Inject("$rootScope") private $rootScope: ng.IRootScopeService,
        private messageService: MessageService,
        private notificationsService: NotificationsService,
        private themeService: ThemeService) {
        this.translate = this.$filter("translate");
        window.detachedViewer = true;
        window.details = {activeViewerDashlet: undefined, activeOsid: undefined, activeSelectedOsids: undefined};
    }

    ngOnInit(): void {
        PopupViewerWindow.RootscopeViewerEvents.forEach(viewerEvent => {
            this.scopeSubscriptions.push(this.$rootScope.$on(viewerEvent, (evt, args) => {
                window.dispatchEvent(new CustomEvent(viewerEvent, {detail: args}));
            }));
        });

        PopupViewerWindow.ViewerEvents.forEach(viewerEvent => {
            this.subscriptions.add(this.messageService.subscribe(viewerEvent, (args) => {
                window.dispatchEvent(new CustomEvent(viewerEvent, {detail: args}));
            }));
        });

        PopupViewerWindow.ViewerFns.forEach(eventName => {
            window[eventName] = this[eventName].bind(this);
        });

        this.bindResize();

        this.$rootScope.$broadcast("viewerReady"); // for electron - sadly broadcast in messageSrv does not work across electron windows
    }

    ngAfterViewInit(): void {
        window.dispatchEvent(new Event("onPopUpReady"));
    }

    async init(dashletId: string, osid: number, searchKey?: string, selectedOsids?: number[]): Promise<void> {
        if (osid && selectedOsids?.length > 1) {
            const additionalOsids: number[] = selectedOsids.filter(selectedOsid => selectedOsid != osid);

            try {
                let dmsDocuments: DmsDocument[];
                if (this.clientService.isOffline()) {
                    dmsDocuments = await this.offlineCacheService.getByIds(additionalOsids.map(id => id.toString()));
                } else {
                    const {result, warning, error} = await this.searchService.searchByIdsAsync(additionalOsids.map(selectedOsid => ({ osid: selectedOsid })));

                    dmsDocuments = result;

                    if (warning) {
                        this.notificationsService.warning(warning);
                    }

                    if (error) {
                        this.notificationsService.error(error);
                    }
                }
                this.cacheManagerService.dmsDocuments.add(dmsDocuments);
            } catch (error) {}
        }

        await this.updateViewer(osid, searchKey, dashletId, selectedOsids);
        this.eobDashletContainerComponent.openDashlet(dashletId);
    }

    async updateViewer(osid?: number, searchKey?: string, dashletId?: string, selectedOsids?: number[]): Promise<void> {
        window.details.activeSelectedOsids = selectedOsids || [osid];

        let dmsDocument: DmsDocument;
        if (osid != undefined && window.details.activeOsid != osid) {
            try {
                if (this.clientService.isOffline()) {
                    dmsDocument = await this.offlineCacheService.getById(osid.toString());
                } else {
                    dmsDocument = await this.searchService.searchById(osid);
                }
            } catch (error) {
                this.clearViewer();
                throw error;
            }

            if (dmsDocument == void 0) {
                this.clearViewer();
                return;
            }

            this.cacheManagerService.dmsDocuments.add(dmsDocument);
            this.cacheManagerService.dmsDocuments.updateListener(this.gListenerGuid, window.details.activeSelectedOsids);
            this.cacheManagerService.dmsDocuments.executeGC();

            if (searchKey != "" && searchKey != void 0) {
                dmsDocument.model.vtx = [{model: {searchKey}}];
            }

            window.details.activeOsid = Number(osid);

            this.setWindowTitle(dmsDocument);
        }

        this.eobDashletContainerComponent.updateViewer(osid, dmsDocument, dashletId, selectedOsids);
    }

    refreshViewer(osid: number, dashletIds: string[], keepCache?: boolean): void {
        this.eobDashletContainerComponent.refreshViewer(osid, dashletIds, keepCache);
    }

    clearViewer(): void {
        window.details.activeOsid = undefined;
        window.details.activeSelectedOsids = undefined;
        this.cacheManagerService.dmsDocuments.updateListener(this.gListenerGuid, []);
        this.eobDashletContainerComponent.clearViewer();
    }

    sendDashletEvent(dashletId: string, eventKey: string, data: unknown): void {
        this.eobDashletContainerComponent.sendDashletEvent(dashletId, eventKey, data);
    }

    bindResize(): void {
        this.resizeObserver = new ResizeObserver(() => this.viewerService.getContentViewer()?.resizeCallback());
        this.resizeObserver.observe(document.querySelector(".popup-viewer"));
    }

    unbindResizeSensor(): void {
        this.resizeObserver.disconnect();
    }

    /** Sync detached window cache and main window cache without listeners. */
    onDmsDocumentChanged(osids: string[]): void {
        const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(osids[0]);
        // the api can't be transported over the electron renderer, but the cache can handle that
        window.dispatchEvent(new CustomEvent(ViewerEvent.UPDATE_DMS_CACHE, {detail: [{ model: dmsDocument.model }, true]}));
    }

    updateDmsDocumentCache = (osid: string, dmsDocument: DmsDocument): void => {
        if (!dmsDocument) {
            this.cacheManagerService.dmsDocuments.remove(osid);
        } else {
            const cachedDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(dmsDocument.model.osid);
            if (cachedDocument) {
                // prevent calling onDmsDocumentChanged of this service, which would send the cache update unnecessarily back to the main window
                const listenerOsids: number[] = window.details.activeSelectedOsids;
                this.cacheManagerService.dmsDocuments.updateListener(this.gListenerGuid, []);

                this.cacheManagerService.dmsDocuments.add(dmsDocument);

                this.cacheManagerService.dmsDocuments.updateListener(this.gListenerGuid, listenerOsids);
            }
        }
    };

    onThemeChanged = (name: string): void => {
        this.themeService.setTheme(name);
    };

    private setWindowTitle(dmsDocument: DmsDocument) {
        const fieldValues: string[] = Object.values(dmsDocument.model.fields).filter(value => value.length > 0);
        let titleString: string = fieldValues.join(" - ");

        if (titleString.length === 0) {
            titleString = this.translate("eob.pdfjs.header.title");
        }
        document.querySelector("title").innerHTML = titleString;
    }

    onActiveDashletChanged(dashletId: string): void {
        window.details.activeViewerDashlet = dashletId;
    }

    setDashlets(dashlets: CustomDashletConfig[]): void {
        this.environmentService.setDashlets(dashlets);
        this.eobDashletContainerComponent.initCustomDashlets();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.scopeSubscriptions.forEach(fn => fn());
    }
}
