<ng-container [ngSwitch] = "fieldType">
    <eob-form-page-control *ngSwitchCase = "'pagecontrol'" [field] = "field" [formdata] = "formdata"
                           [formhelper] = "formhelper" [layoutField] = "layoutField"
                           [formid] = "formid" [ismockform]="ismockform"></eob-form-page-control> <!--.form-pagecontrol-->
    <eob-form-group *ngSwitchCase = "'group'" class = "form-group" [field] = "field" [formdata] = "formdata"
                    [formhelper] = "formhelper" [layoutField] = "layoutField" [formid] = "formid" [ismockform] = "ismockform"></eob-form-group>
    <eob-form-static-text *ngSwitchCase = "'static'" [field] = "field" [layoutField] = "layoutField"
                          [ngClass] = "{'mock-field':ismockform}" [ismockform]="ismockform" [formid] = "formid"></eob-form-static-text>
    <eob-form-text-field *ngSwitchCase = "'text'" [field] = "field" [formhelper] = "formhelper" [hasAddon] = "hasAddon"
                         [ismockform] = "ismockform" [formid] = "formid" [control]="field.model.control" [disabled]="field?.model.readonly == 'init' && $stateParams.mode == 'edit'"></eob-form-text-field>
    <eob-form-checkbox *ngSwitchCase = "'checkbox'" [formhelper] = "formhelper" [field] = "field"
                       [ismockform] = "ismockform" [formid] = "formid" [control]="field.model.control"></eob-form-checkbox>
    <eob-form-radio *ngSwitchCase = "'radio'" [formhelper] = "formhelper" [field] = "field"
                    [formid] = "formid" [ismockform]="ismockform"></eob-form-radio>
    <eob-form-button *ngSwitchCase = "'button'" [field] = "field" [layoutField] = "layoutField" [ismockform] = "ismockform"></eob-form-button>

    <!-- TODO these are placeholders for future field type components-->
    <!--    <eob-form-grid *ngSwitchCase = "'grid'"  [formhelper]="formhelper" [field]="field"></eob-form-grid>-->

    <!--default case when there are no matches -->
    <ng-template *ngSwitchDefault></ng-template>
</ng-container>


