(function() {


    require("angular-translate");
    require("angular-translate-loader-partial");
    require("SERVICES_PATH/eob.config.prv.js");
    require("CLIENT_PATH/configuration/plugins.config.js");
    require("CLIENT_PATH/configuration/main.config.js");

    const module = angular.module("eob.client");

    /**
     * applications translation configuration
     */
    module.config(["$translateProvider", "$translatePartialLoaderProvider",
        ($translateProvider, $translatePartialLoaderProvider) => {
            $translateProvider.preferredLanguage("de");

            $translatePartialLoaderProvider.addPart("bb");
            $translateProvider.useLoader("$translatePartialLoader", {
                urlTemplate: "languages/{part}_{lang}.json"
            });

            //TODO @see http://angular-translate.github.io/docs/#/guide/19_security
            // Enable escaping of HTMLbuild-core
            $translateProvider.useSanitizeValueStrategy("escaped");
        }]);

    /**
     * configuration values for usage of eo-framework
     */
    module.config([
        "$eobConfigProvider",
        "$cfgPlugins",
        "config",
        ($eobConfigProvider, $cfgPlugins, config) => {
            $eobConfigProvider.serviceBase(config.uri.serviceBase);
            $eobConfigProvider.officeBase(config.uri.officeBase);
            $eobConfigProvider.oswebBase(config.uri.oswebBase);
            $eobConfigProvider.pluginBase(config.path.pluginBase);
            $eobConfigProvider.pluginsConfig($cfgPlugins);
        }]);
})();
