import Dexie from "dexie";
import {Observable, Subscriber} from "rxjs";
import {publishReplay, refCount} from "rxjs/operators";

import {OfflineDataStore} from "MODELS_PATH/eob.offline.data.model";
import {ContentObject} from "SHARED_PATH/models/eob.content.object";
import {DatabaseType} from "ENUMS_PATH/database/database-type.enum";
import {GlobalDatabase} from "SHARED_PATH/models/global-database.model";
import {ProfileDatabase} from "SHARED_PATH/models/profile-database.model";
import {ProfileServiceSurrogate} from "INTERFACES_PATH/profile-service-surrogate.interface";
import {FileCacheOptions} from "INTERFACES_PATH/database/file-cache-options.interface";
import {GlobalStore, ProfileStore} from "INTERFACES_PATH/database/database-store.interface";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";
import {DmsDocumentStore} from "INTERFACES_PATH/dms-document-store.interface";
import {DmsContentStore} from "INTERFACES_PATH/dms-content-store.interface";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {FileObject} from "INTERFACES_PATH/file-object.interface";
import {UpdateUtilService} from "SERVICES_PATH/utils/update-utils.srv";
import {AuthenticationType} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {Profile, ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {ClientService} from "CORE_PATH/services/client/client.service";

// region interfaces, enums
/**
 * Bytes of storage to reserve when checking for quota remains
 * (e.g. for other non-binary profile data)
 * Inside Electron, a lager security margin is used due to transaction caching, which can render
 * a database completely unusable (for example, if a large transaction is being commited with an
 * almost completely occupied system drive)
 *
 * @type {number}
 */
const RESERVED_SPACE: number = (/Electron/gi.test(navigator.userAgent) ? 500 : 20) * 1048576;

/**
 * An enum of errors, that may occur when using the dexie db.
 */
export enum PersistenceError {
    QuotaExceeded = "QuotaExceeded"
}
// endregion

/**
 * File cache service
 */
export class FileCacheService {
    static $inject: string[] = ["profileService", "updateUtilService", "clientService"];

    private globDb: GlobalDatabase = null;
    private profileDb: ProfileDatabase = null;
    private initialized: boolean = false;
    private accessible: boolean = true;
    private spaceObservable: Observable<number>;

    constructor(private profileService: ProfileService | ProfileServiceSurrogate,
                private updateUtilService: UpdateUtilService, private clientService: ClientService) {}

    initAsync = async (): Promise<void> => {
        if (this.initialized) {
            return;
        }

        try {
            await this.initGlobalDb();
            await this.initProfileDb();
        } catch(error) {
            this.accessible = false;
            console.warn("Unable to access databases.", error);
        }

        this.initialized = true;
    };

    async initGlobalDb(profile?: Profile): Promise<void> {
        profile = profile || this.profileService.prepareCurrentProfile();
        this.globDb = new GlobalDatabase(this.getGlobalDbName(profile), this.clientService);

        let globalUpdateNecessary: boolean = false;
        try {
            await this.globDb.open();
        } catch(error) {
            if (error.name == "UpgradeError") {
                globalUpdateNecessary = true;
            } else {
                throw error;
            }
        }

        // Unfortunatly dexie can't update changes to primary keys in a db schema.
        // That's why we need to update changes for the global db from version 2 to 3 manually.
        if (globalUpdateNecessary) { // updates the "global" global db for e.g. browsers
            this.globDb = await this.updateUtilService.changeSchema(this.globDb);
        } else if (this.globDb.name !== DatabaseType.GLOBAL) { // updates url based global dbs for e.g. local clients
            await this.updateUtilService.updateGlobalDb(this.globDb, profile);
        }
    }

    /** Should be initialized after the global db. */
    private async initProfileDb(): Promise<void> {
        const profile: Profile = this.profileService.prepareCurrentProfile();

        this.closeDb(this.profileDb);
        this.profileDb = new ProfileDatabase(this.getProfileDbName(profile), this.clientService);
        await this.openDatabase(this.profileDb);

        if (this.profileDb.name !== DatabaseType.PROFILE) {
            await this.updateUtilService.updateProfileDb(this.globDb, this.profileDb, profile);
        }
    }

    getDmsDocumentsTable(): Dexie.Table<any, string> {
        if (this.globDb == void 0) {
            return;
        }
        return this.globDb.dmsDocuments;
    }

    getDmsContentTable(): Dexie.Table<any, string> {
        if (this.globDb == void 0) {
            return;
        }
        return this.globDb.dmsContent;
    }

    getOfflineDataTable(): Dexie.Table<OfflineDataStore, string> {
        if (this.profileDb == void 0) {
            return;
        }
        return this.profileDb.offlineData;
    }

    private closeGlobalDb(): void {
        return this.closeDb(this.globDb);
    }

    private closeDb(db: Dexie): void {
        if (db && db.isOpen()) {
            db.close();
        }
    }

    /**
     * Calculates a SHA-256 Hash from an arraybuffer content.
     *
     * @param {arraybuffer} buffer - The input buffer with the content
     * @param {bool} shouldEncode - If the buffer contains text it can be encoded to utf-8 text buffer
     * @return {Promise<string>} - The SHA-256 String.
     */
    sha256Async = async (buffer: ArrayBuffer | string, shouldEncode: boolean): Promise<string> => {
        if (shouldEncode && typeof buffer == "string") {
            buffer = new TextEncoder().encode(buffer).buffer;
        }

        const hashBuffer: ArrayBuffer = await crypto.subtle.digest("SHA-256", buffer as ArrayBuffer);
        const hashArray: number[] = Array.from(new Uint8Array(hashBuffer));

        return hashArray.map((b: number) => (`00${b.toString(16)}`).slice(-2)).join("");
    };

    /**
     * Returns an object from the database according to the given criteria. Returns undefined if there is no match or the db is not accessible.
     *
     * @param {EntryTypes} type - type of storage
     * @param {string} key - name of an entry by which it is referenced
     * @param {FileCacheOptions} options - see {@link FileCacheOptions}
     * @param {object} [options.additionalCriteria] - add additional properties to the object passed to the where-function. Only applied if no startsWith options are used
     * @param {string} [options.first] - return only the first element instead of the full result collection
     * @param {string} [options.group] - name used for filtering related entries
     * @param {string} [options.keyStartsWith] - optionally filter for entries whose key should start with the given value
     * @param {string} [options.groupStartsWith] - optionally filter for entries whose group should start with the given value
     * @param {boolean} [options.onlyContent=true] - whether only the content or the stored object should be retrieved
     * @param {boolean} [options.timestampOnly] - only return the timestamp of the queried item, implicitely returning the timestamp of the first match or null if no match or timestamp
     */
    getContentAsync = async (type: DatabaseEntryType, key: string, options?: FileCacheOptions):
        Promise<ArrayBuffer | ArrayBuffer[] | Date | Date[] | GlobalStore<any> | Array<GlobalStore<any>> | ProfileStore<any> | Array<ProfileStore<any>> | any | void> => {
        if (!this.isAccessible()) {
            return;
        }

        await this.ensureDatabasesAreOpen();

        const effectiveOptions: FileCacheOptions = {onlyContent: true, group: "default"} as FileCacheOptions;
        Object.assign(effectiveOptions, options);

        let prelimitaryResult: ProfileStore<any> | Array<ProfileStore<any>> | void;

        switch (type) {
            case DatabaseEntryType.GLOBAL:
                const result: GlobalStore<any> | void = await this.globDb.persistent.where({
                    key
                }).first();
                if (result) {
                    if (effectiveOptions.onlyContent) {
                        return result.content;
                    } else if (effectiveOptions.timestampOnly) {
                        return result.timestamp;
                    }

                    return result;
                }
                break;
            case DatabaseEntryType.PERSISTENT:
            case DatabaseEntryType.TEMPORARY:
                let resultCollection: Dexie.Collection<ProfileStore<any>, string>;

                if (effectiveOptions.keyStartsWith) {
                    resultCollection = this.profileDb[type].where("key").startsWith(key).filter((entry: ProfileStore<any>) => {
                        if (effectiveOptions.groupStartsWith) {
                            return (entry.group.startsWith(effectiveOptions.group || "default"));
                        }

                        return effectiveOptions.group ? (entry.group == effectiveOptions.group) : true;
                    });
                } else if (effectiveOptions.groupStartsWith) {
                    resultCollection = this.profileDb[type].where("key").equals("metadata").and((entry: ProfileStore<any>) =>
                        // Filter by key first to avoid loading all available content and load all metadata instead
                         entry.group.startsWith(effectiveOptions.groupStartsWith)
                    );
                } else {
                    const criteria: { [k: string]: any } = {};

                    if (key) {
                        criteria.key = key;
                    }

                    if (effectiveOptions.group) {
                        criteria.group = effectiveOptions.group;
                    }

                    if (effectiveOptions.additionalCriteria) {
                        Object.assign(criteria, effectiveOptions.additionalCriteria);
                    }

                    resultCollection = this.profileDb[type].where(criteria);
                }

                try {
                    if (effectiveOptions.first) {
                        prelimitaryResult = await resultCollection.first();
                        if (prelimitaryResult) {
                            if (effectiveOptions.onlyContent) {
                                return prelimitaryResult.content;
                            } else if (effectiveOptions.timestampOnly) {
                                return prelimitaryResult.timestamp;
                            } else {
                                return prelimitaryResult;
                            }
                        }
                    } else {
                        prelimitaryResult = await resultCollection.toArray();
                        if (effectiveOptions.onlyContent) {
                            return prelimitaryResult.map((x: ProfileStore<any>) => x.content);
                        } else if (effectiveOptions.timestampOnly) {
                            return prelimitaryResult.map((x: ProfileStore<any>) => x.timestamp);
                        } else {
                            return prelimitaryResult;
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
                break;
            default:
                throw new Error("Unknown entry type");
        }
    };

    /**
     * Store data inside an IDB, where the type determines the database and store to use
     *
     * @param {EntryTypes} type - type of storage
     * @param {any} content - the content to be stored
     * @param {string} key - name of an entry by which it is referenced
     * @param {string} group - name used for filtering related entries
     * @param {[k: string]: any} additionalMetadata - used to pass additional data that should be persisted alongside the payload
     * @throws {Error} If entry can't be persisted (e.g. due to its blob size being larger than remaining quota)
     * @returns {Promise<void>} - Awaitable Promise
     */
    storeContentAsync = async (type: DatabaseEntryType, content: any, key: string, group?: string, additionalMetadata: { [k: string]: any } = {}): Promise<void> => {
        if (key == GlobalCacheKey.OBJ_DEF_CACHE && /\/\/(localhost|127\.\d+\.\d+\.\d+)/.test(location.origin)) {
            // Don't cache object definition for localhost. Otherwise, prepare for unforeseen consequences...
            return;
        }
        if (!this.isAccessible()) {
            return;
        }

        await this.ensureDatabasesAreOpen();
        if (!group) {
            group = "default";
        }

        await this.checkFreeSpace([content]);

        additionalMetadata = additionalMetadata || {};

        switch (type) {
            case DatabaseEntryType.GLOBAL:
                await this.globDb?.persistent.put(Object.assign(additionalMetadata, {
                    key,
                    timestamp: new Date(),
                    content
                }) as GlobalStore<any>);
                break;
            case DatabaseEntryType.PERSISTENT:
                await this.profileDb?.persistent.put(Object.assign(additionalMetadata, {
                    key,
                    group,
                    timestamp: new Date(),
                    content
                }) as ProfileStore<any>);
                break;
            case DatabaseEntryType.TEMPORARY:
                await this.profileDb?.temporary.put(Object.assign(additionalMetadata, {
                    key,
                    group,
                    timestamp: new Date(),
                    content
                }) as ProfileStore<any>);
                break;
            default:
                throw new Error("Unknown entry type");
        }
    };

    /**
     * Removes the content specified by the given parameters from the
     *
     * @param {EntryTypes} type type of entry to be removed
     * @param {string} key key used to identify the stored object
     * @param {string} group optional group name to filter entries by
     * @returns {Promise<void>} - Awaitable Promise
     */
    deleteContentAsync = async (type: DatabaseEntryType, key: string, group?: string): Promise<void> => {
        if (!this.isAccessible()) {
            return;
        }

        if (!group) {
            group = "default";
        }

        await this.ensureDatabasesAreOpen();

        let store: Dexie.Table<GlobalStore<any> | ProfileStore<any>, string>;

        switch (type) {
            case DatabaseEntryType.GLOBAL:
                await this.globDb.persistent.where({key}).delete();
                return;
            case DatabaseEntryType.PERSISTENT:
                store = this.profileDb.persistent;
                break;
            case DatabaseEntryType.TEMPORARY:
                store = this.profileDb.temporary;
                break;
            default:
                throw new Error("Unknown entry type");
        }

        if (key && group) {
            await store.where({key, group}).delete();
        } else if (key) {
            await store.where({key}).delete();
        } else if (group) {
            await store.where({group}).delete();
        }
    };

    /**
     * Clears the temporary store of the current profile database
     *
     * @returns {Promise<void>} - Awaitable Promise
     */
    removeTemporaryEntriesAsync = async (): Promise<void> => {
        if (!this.isAccessible()) {
            return;
        }

        await this.ensureDatabasesAreOpen();
        await this.profileDb.temporary.clear();
    };

    /**
     * Clears the session store of the current profile database
     *
     * @returns {Promise<void>} - Awaitable Promise
     */
    removeSessionDataAsync = async (): Promise<void> => {
        if (!this.isAccessible()) {
            return;
        }

        await this.ensureDatabasesAreOpen();
        await this.deleteContentAsync(DatabaseEntryType.GLOBAL, GlobalCacheKey.RANDOM_SESSION_ID, "");
    };

    /**
     * Deletes the database for the supplied profile
     *
     * @param {{url: string, username: string}} profile object having at least an url and username attribute
     * @returns {Promise<void>} - Awaitable Promise
     */
    deleteProfileDatabaseAsync = async (profile: Profile): Promise<void> => {
        if (!this.isAccessible()) {
            return;
        }

        await Dexie.delete(this.getProfileDbName(profile));
        this.updateUtilService.deleteProfileDatabase(profile);
    };

    /** Removes globally cached data for the given profile. */
    deleteGlobalDatabase = async (profile: Profile): Promise<void> => {
        const db: GlobalDatabase = new GlobalDatabase(this.getGlobalDbName(profile), this.clientService);
        await db.delete();
    };

    getIndexdataSpaceUsage = async (osids: string[]): Promise<string> => {
        const offlineData: OfflineDataStore[] = await this.getOfflineDataTable().toArray() || [];
        const dmsDocuments: DmsDocumentStore[] = await this.getDmsDocumentsTable().where("osid").anyOf(osids).toArray() || [];
        return this.niceBytes((offlineData.length != 0 ? JSON.stringify(offlineData).length : 0) + (dmsDocuments.length != 0 ? JSON.stringify(dmsDocuments).length : 0));
    };

    getOverallContentSpaceUsage = (osids: string[]): Observable<number> => {
        if(!this.spaceObservable) {
            this.spaceObservable = this.getContentSpaceUsage(osids).pipe(publishReplay(1), refCount());
            this.spaceObservable.subscribe({error: () => {
delete this.spaceObservable;
}, complete: () => {
delete this.spaceObservable;
}});
        }
        return this.spaceObservable;
    };

    getContentSpaceUsage = (osids: string[]): Observable<number> => {
        if (!this.spaceObservable) {
            this.spaceObservable = new Observable((subscriber: Subscriber<number>): void => {
                this.getDmsContentTable().where("osid").anyOf(osids).each((obj: DmsContentStore) => {
                    const files: Array<FileObject<ArrayBuffer|Blob>> = [].concat(((obj.original || {}).files || []), ((obj.preview || {}).files || []));
                    let length: number = 0;

                    files.forEach((x: FileObject<ArrayBuffer | Blob>) => {
                        if (x.content instanceof ArrayBuffer) {
                            length += x.content.byteLength;
                        } else if (x.content instanceof Blob) {
                            length += x.content.size;
                        }
                    });
                    subscriber.next(length);
                }).then(() => {
                    subscriber.complete();
                    delete this.spaceObservable;
                    return;
                }).catch((err: Error) => {
                    subscriber.error(err);
                    delete this.spaceObservable;
                });
            });
        }
        return this.spaceObservable;
    };

    /**
     * Format the given number to a pretty, readable byte size presentation.
     *
     * @param {number} x - A byte size as a number.
     */
    niceBytes(x: number): string {
        const units: string[] = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        let l: number = 0;

        while (x >= 1024) {
            x /= 1024;
            l++;
        }

        return (`${x.toFixed(2)} ${units[l]}`);
    }

    /**
     * Check whether the given content surpasses the remaining free storage space.
     * Throws a QuotaExceeded error if the remaining space doesn't suffice.
     */
    async checkFreeSpace(data: any[]): Promise<void> {
        const free: number = await this.getFreeSpace();
        if (free == Number.MAX_VALUE) {
            return;
        }

        let additionalSize: number = 0;
        for (const content of data) {
            // Check remaining quota before persisting if a StorageManager is available and a ContentObject is being persisted
            if (content instanceof ContentObject) {
                for (const attachment of content.attachments || []) {
                    for (const prop of ["previewImage", "content"]) {
                        if (attachment[prop] instanceof ArrayBuffer) {
                            additionalSize += attachment[prop].byteLength;
                        } else if (attachment[prop] instanceof Blob) {
                            additionalSize += attachment[prop].size;
                        }
                    }
                }
                for (const file of content.files) {
                    if (file.content instanceof ArrayBuffer) {
                        additionalSize += file.content.byteLength;
                    }
                    if (file.content instanceof Blob) {
                        additionalSize += file.content.size;
                    }
                }
            }
        }

        // Ignore case when the quota usage is already within the reserved space and no blob is being persisted
        if (additionalSize > free && additionalSize > 0) {
            const e: Error = new Error(`Size of to be persisted Blobs surpasses quota by ${Math.abs((additionalSize - free) / 1048576).toFixed(2)} MB`);
            e.name = PersistenceError.QuotaExceeded;
            throw e;
        }
    }

    /**
     * Returns the free space in bytes
     *
     * @returns {Promise<number>}
     */
    async getFreeSpace(): Promise<number> {
        if (navigator.storage && navigator.storage.estimate) {
            const estimate: StorageEstimate = await navigator.storage.estimate();
            return (estimate.quota || Number.MAX_VALUE) - (estimate.usage || 0) - RESERVED_SPACE;
        } else if (/(iPad|iPhone)/.test(navigator.userAgent) && window.cordova) {
            return new Observable((subscriber: Subscriber<number>) => {
              // @ts-ignore
              window.cordova.exec((result: any) => {
                    subscriber.next(result - RESERVED_SPACE);
                    subscriber.complete();
                }, () => {
                    subscriber.next(Number.MAX_VALUE);
                    subscriber.complete();
                }, "File", "getFreeDiskSpace", []);
            }).toPromise();
        } else {
            return Number.MAX_VALUE;
        }
    }

    /**
     * Encodes the profile's username and url into a Base64 string or a placeholder if the client is being run in a browser
     *
     * @param {Object} [profile] - The profile from the profile manager
     * @returns {string} Base64 profile identifier
     */
    private getProfileDbName = (profile?: Profile): string => {
        if (!profile) {
            profile = this.profileService.prepareCurrentProfile();
        }

        if (!profile.url || (!profile.username && profile.authType != AuthenticationType.NTLM_SYSTEM)) {
            // Client is being run outside of an application, assigning inspecific profile database name
            return DatabaseType.PROFILE;
        }

        // 9.10+ Update
        // profile.username can be removed later again
        return btoa(`${profile.userId || profile.username}@${profile.url}`);
    };

    private getGlobalDbName = (profile?: Profile): string => {
        if (profile == void 0) {
            profile = this.profileService.prepareCurrentProfile();
        }

        return profile.url == void 0 ? DatabaseType.GLOBAL : `global_${profile.url}`;
    };

    /**
     * Wait until all dbs are accessable.
     *
     * @throws {InvalidStateError} If one is using a firefox in private mode.
     * @returns {Promise<void>}
     */
    ensureDatabasesAreOpen = async (): Promise<void> => {
        if (!this.globDb || !this.profileDb) {
            await this.initAsync();
            //throw new Error("Global and/or profile database is missing.");
        }

        for (const db of [this.globDb, this.profileDb]) {
            await this.openDatabase(db);
        }
    };

    async openDatabase(db: Dexie): Promise<void> {
        if(!db || !db.isOpen) {
            // PANIC :---DDD
            console.warn("openDatabase(): db instance lacks isOpen() or db is missing");
            return;
        }
        try {
            if (!db.isOpen()) {
                await db.open();
            }
        } catch (error) {
            this.accessible = false;
            console.warn(`Unable to open database: ${error.message}`);
        }
    }

    /**
     * Check whether the inde xedDB can be used.
     *
     * @returns {boolean} Whether db mutations are available.
     */
    private isAccessible(): boolean {
        // Edge has a shit IndexedDB implementation without compound index, complex key path,
        // multiEntry index and storing blob. We can't use it currently.
        return !window.navigator.userAgent.includes("Edge") && this.accessible;
    }
}
