import {
    ChangeDetectorRef,
    Component,
    ElementRef, HostListener,
    Input,
    OnDestroy, EventEmitter,
    OnInit, Output, Renderer2,
    ViewChild
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {EMPTY, merge, Subscription} from "rxjs";

@Component({
    selector: "eob-radio-button",
    templateUrl: "./radio-button.component.html",
    styleUrls: ["./radio-button.component.scss"]
})
export class RadioButtonComponent implements OnInit, OnDestroy {
    @Input() toggleable = false;
    @Input() masterInternal;
    @Input() langAttribute = "";
    @Input() title: string;
    @Input() field: any;
    @Input() formhelper: any;
    @Input() formid: string;
    @Input() tooltip = "";
    @Input() control = new FormControl();
    @Input() radioGroupControls: FormControl[] = [];
    @Input() hideLabel = false;
    @Input() fieldid: string = "";
    @Input() isMasterRadio;
    @Input() masterRadioControl = new FormControl();

    @Output() manualValueChange: EventEmitter<any> = new EventEmitter();

    subs = new Subscription();
    checked = false;
    radioIndex = 0;

    @ViewChild("radio", {static: false}) radio: ElementRef<HTMLDivElement>;
    @ViewChild("radioContainer", {static: false}) radioContainer: ElementRef<HTMLDivElement>;

    constructor(private cdRef: ChangeDetectorRef,
                private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        if(this.control == this.masterRadioControl){
            throw new Error("The master radio control and control can't be the same control");
        }
        this.radioIndex = this.radioGroupControls.findIndex(x => x == this.control);

        this.subs.add(merge(this.control?.valueChanges ?? EMPTY, this.control?.statusChanges ?? EMPTY).subscribe(() => {
            if (!this.control.value) {
                return;
            }
            if(this.control.value.triggeredByMaster && this.control.value.hide === void 0) {
                this.checked = this.control.value.selected;
                // This call is crucial, don't question it
                this.cdRef.detectChanges();
                return;
            }
            if (this.control.value.hide !== void 0) {
                if (this.control.value.hide) {
                    this.renderer.setStyle(this.radioContainer.nativeElement, "display", "none");
                } else {
                    this.renderer.removeStyle(this.radioContainer.nativeElement, "display");
                }
            }

            // Required if form control is invalidated externally, e.g. by form submission
            this.cdRef.detectChanges();
        }));

        if (this.isMasterRadio) {
            // Initial value needs to be set here, as otherwise radio buttons that are part of a radiogroup (but not part of the same form group) aren't selected properly
            if (this.masterRadioControl.value !== "") {
                if (isNaN(Number.parseInt(this.masterRadioControl.value))) {
                    console.warn(`fallback value of radio button group '${this.title}' from ${this.masterRadioControl.value} to 0`);
                    this.masterRadioControl.setValue(0);
                }

                if (this.masterRadioControl.value >= 0 && this.masterRadioControl.value < this.radioGroupControls.length) {
                    this.radioGroupControls[this.masterRadioControl.value].setValue({
                        selected: true,
                        triggeredByMaster: true
                    });
                }
            }

            if(this.radioGroupControls.length == 1) {
                // Since the same instance would have its value set, this'd happen before the subscriber is attached
                // Also: who in their right mind would create a single radio button all by itself?
                this.control.setValue({selected: /0/.test(this.masterRadioControl.value), triggeredByMaster: true});
            }

            this.subs.add(merge(this.masterRadioControl?.valueChanges ?? EMPTY, this.masterRadioControl?.statusChanges ?? EMPTY).subscribe(() => {
                if(this.masterRadioControl.value?.hide !== void 0) {
                    this.radioGroupControls.forEach(x => {
                        x.setValue({hide: this.masterRadioControl.value.hide, triggeredByMaster: true});
                    });
                    return;
                }
                this.radioGroupControls.forEach(x => {
                    x.setValue({selected: false, triggeredByMaster: true});
                });

                if (!isNaN(Number.parseInt(this.masterRadioControl.value))
                    && this.masterRadioControl.value >= 0
                    && this.masterRadioControl.value < this.radioGroupControls.length) {
                    this.radioGroupControls[this.masterRadioControl.value].setValue({
                        selected: true,
                        triggeredByMaster: true
                    });
                }

                this.cdRef.detectChanges();
            }));
            if (this.field?.model.isMasterRadio && this.field.isDisabled) {
                this.radioGroupControls.forEach(x => x.disable());
                this.cdRef.detectChanges();
            }
        } else if (!isNaN(Number.parseInt(this.masterRadioControl.value)) && this.masterRadioControl.value == this.radioIndex) {
            this.control.setValue({selected: true, triggeredByMaster: true});
        }
    }

    onClick(): void {
        if (this.control.disabled || (this.control.value?.selected && !this.toggleable)) {
            return;
        }
        this.checked = !this.checked;
        this.masterRadioControl.setValue(this.checked ? this.radioIndex : "");
        this.manualValueChange.emit(this.masterRadioControl.value);
    }

    @HostListener("keydown", ["$event"])
    keydown(event: KeyboardEvent): void {
        if (!this.control.disabled && event.key == "Enter" && !this.formhelper?.isWorkflow) {
            this.formhelper?.submit();
        }
    }

    @HostListener("focusout") onfocusout(): void {
        if (this.control.disabled) {
            return;
        }

        this.renderer.removeClass(this.radio.nativeElement, "focus");
    }

    @HostListener("focusin") onfocusin(): void {
        if (this.control.disabled) {
            return;
        }

        this.renderer.addClass(this.radio.nativeElement, "focus");
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
