import {ChangeDetectorRef, Component, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {BehaviorSubject, defer, Observable, Subscription} from "rxjs";
import {TranslateFnType} from "CLIENT_PATH/custom.types";

import {ViewerService} from "CORE_PATH/services/viewer/viewer.service";
import {TodoEnvironmentService, TodoOfflineCacheService, TodoSearchService} from "INTERFACES_PATH/any.types";
import {EobDashletContainerComponent} from "MODULES_PATH/dashlet/components/eob-dashlet-container/eob-dashlet-container.component";
import {ModalDashletsContainerInput} from "MODULES_PATH/modal-dialog/interfaces/modal-container.interface";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {Connection} from "ENUMS_PATH/connection.enum";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {first} from "rxjs/operators";

@Component({
    selector: "eob-modal-dashlets",
    templateUrl: "./eob-modal-dashlets.component.html",
    styleUrls: ["./eob-modal-dashlets.component.scss"],
    providers: [{provide: "$scope", useExisting: "$rootScope"}]
})
export class EobModalDashletsComponent implements OnInit, OnDestroy {
    @ViewChild(EobDashletContainerComponent, {static: false}) eobDashletContainerComponent: EobDashletContainerComponent;
    @Input() dialogInput: ModalDashletsContainerInput;

    private dmsDocument: DmsDocument;
    private lastOsid: number;
    private refreshOnOpen: boolean = false;
    private subscriptions: Subscription = new Subscription();

    private readonly translateFn: TranslateFnType;

    isChanging: boolean = false;

    // eslint-disable-next-line max-params
    constructor(private viewerService: ViewerService, private messageService: MessageService,
                private clientService: ClientService, private notificationsService: NotificationsService,
                private ngZone: NgZone,
                @Inject("searchService") private searchService: TodoSearchService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("offlineCacheService") private offlineCacheService: TodoOfflineCacheService,
                @Inject("$filter") protected $filter: ng.IFilterService, private cdRef: ChangeDetectorRef) {
        this.translateFn = this.$filter("translate");
    }

    ngOnInit(): void {
        this.subscriptions.add(this.messageService.subscribe(ViewerEvent.REFRESH_VIEWER, ({osid}) => this.refreshOnOpen = this.lastOsid == osid));

        this.dialogInput.closeModalDashletsSubscription = new BehaviorSubject(false);
        this.subscriptions.add(this.dialogInput.closeModalDashletsSubscription.subscribe(() => this.close()));

        this.clientService.registerConnectivityChangeHandler(this.onConnectivityChanged);

        this.subscriptions.add(this.messageService.subscribe(ModalEvents.OPEN_MODAL_DASHLETS, this.openDashlet.bind(this)));
    }

    onConnectivityChanged = (currentConnectivity: Connection): void => {
        if (currentConnectivity !== Connection.NONE && this.lastOsid !== void 0 && this.dialogInput.isOpen) {
            this.updateDmsDocument(this.lastOsid).pipe(first()).subscribe(() => {
                this.refreshViewer(this.lastOsid);
            });
        }
    };

    private openDashlet(osid: number): void {
        this.isChanging = true;
        if (this.lastOsid != osid) {
            this.updateViewer(osid);
        } else if (this.refreshOnOpen) {
            this.refreshViewer(osid);
        }

        this.refreshOnOpen = false;

        this.ngZone.run(() => this.dialogInput.isOpen = true);
        setTimeout(() => {
            this.isChanging = false;
            this.cdRef.detectChanges();
        }, 100);
    }

    private updateViewer(osid: number) {
        this.updateDmsDocument(osid).pipe(first()).subscribe(() => {
            const nextDashlet: string = (this.dmsDocument.model.baseParameters.archiveState == "REFERENCE" || this.dmsDocument.model.hasContent) ? "content" : "details";
            this.eobDashletContainerComponent.updateViewer(osid, this.dmsDocument, nextDashlet);
            this.eobDashletContainerComponent.openDashlet(nextDashlet);

            this.eobDashletContainerComponent.hasContent = this.dmsDocument.model.hasContent;
        });
    }

    private refreshViewer(osid: number) {
        this.eobDashletContainerComponent.refreshViewer(osid);
    }

    private updateDmsDocument(id: number): Observable<void> {
        return defer(async () => {
                this.lastOsid = id;

                if (id !== void 0) {
                    try {
                        if (this.clientService.isOffline()) {
                            this.dmsDocument = await this.offlineCacheService.getById(id);
                        } else {
                            this.dmsDocument = await this.searchService.searchById(id, null);
                        }
                    } catch (error) {
                        this.notificationsService.error(this.translateFn("eob.result.document.load.error"));
                        throw error;
                    }
                }
            }
        );
    }

    close(): void {
        this.dialogInput.isOpen = false;
    }

    ngOnDestroy(): void {
        this.clientService.unregisterConnectivityChangeHandler(this.onConnectivityChanged);
        this.subscriptions.unsubscribe();
    }
}
