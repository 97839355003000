import {Component, ViewChild, ComponentFactoryResolver, Type} from "@angular/core";
import {IHeaderAngularComp} from "ag-grid-angular";
import {IHeaderParams} from "ag-grid-community";
import Event = JQuery.Event;
import {fromEvent, Subject} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {OnDestroy} from "@angular/core";
import {CellHostDirective} from "MODULES_PATH/grid/directives/cell-host.directive";
import {GridHeaderCellInterface} from "../../../interfaces/grid-header-cell.interface";

@Component({template: ""})
// the class is abstract, but angular doesn't allow the abstract decorator
export class GridHeaderCellComponent implements IHeaderAngularComp, OnDestroy {
    @ViewChild(CellHostDirective, {static: true}) cellHost: CellHostDirective;

    protected readonly cellContent: Type<GridHeaderCellInterface>;

    protected destroyed$ = new Subject<void>();
    sort: string;
    sortCount: number;

    private params;
    private column;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    }

    agInit(params: IHeaderParams): void {
        this.params = params;
        this.column = params.column;

        this.loadComponent();

        this.setSorting();

        fromEvent(this.params.api, "sortChanged").pipe(
            tap(() => this.setSorting()),
                takeUntil(this.destroyed$)
        ).subscribe();
    }

    refresh(params: IHeaderParams): boolean {
        return true;
    }

    onClick(event: Event): void {
        this.params.progressSort(event.shiftKey);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    private loadComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<GridHeaderCellInterface>(this.cellContent);
        const viewContainerRef = this.cellHost.viewContainerRef;
        const componentRef = viewContainerRef.createComponent<GridHeaderCellInterface>(componentFactory);
        componentRef.instance.params = this.params;
    }

    private setSorting() {
        this.sort = this.column.getSort();
        const sortIndex: number = this.column.getSortIndex();

        if (sortIndex != undefined) {
            const sortedColumns = this.params.api.sortController.getColumnsWithSortingOrdered();
            this.sortCount = sortedColumns.length > 1 ? sortIndex + 1 : 0;
        } else {
            this.sortCount = 0;
        }
    }
}
