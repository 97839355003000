(function() {
    require("SERVICES_PATH/viewer/detailsviewer/dv.notes.srv.js");

    angular.module("eob.framework").directive("dvEditComment", DvEditComment);

    DvEditComment.$inject = ["$filter", "dvNotesService", "environmentService", "profileService"];

    function DvEditComment($filter, DvNotesService, EnvironmentService, ProfileService) {
        return {
            restrict: "AE",
            template: require("!raw-loader!./dv.edit.comment.html"),
            link(scope, element) {
                let noteTextChanged = false;
                let noteTypeChanged = false;
                let currentNoteType = -1;
                let availableCharStr = `${$filter("translate")("app.note.availablechars")}: `;
                let maxTextLength = 4000;
                let loading = false;
                let dvCommentElement = element.find(".dv-comment");
                let saveButtonElement = element.find(".dv-note-save-button");
                let noteTypeElements = element.find(".nodeTypes").find(".note-color");
                let note = scope.note ? scope.note : undefined;
                let noteTypeId = note ? parseInt(note.color) : 1;
                let colorTypeId = note ? parseInt(note.color) : 1;
                let noteType = `type${colorTypeId}`;

                scope.buttonText = scope.note ? $filter("translate")("app.btn.note.edit"): $filter("translate")("app.btn.note.add");
                scope.avatarUrl = `${ProfileService.getCurrentBaseUrl()}/osrest/api/organization/avatar/${EnvironmentService.getSessionInfo().username}`;
                scope.noteTextMax = maxTextLength;
                scope.noteText = note ? note.text : "";

                scope.reload = reload;
                scope.save = save;
                scope.cancelEdit = cancelEdit;
                scope.switchType = switchSelectedType;
                scope.$watch("noteText", checkForNewNoteText);

                switchSelectedType(colorTypeId);

                function switchSelectedType(typeId) {
                    angular.element(noteTypeElements[typeId - 1]).addClass("selected");
                    if (currentNoteType != -1) {
                        angular.element(noteTypeElements[currentNoteType - 1]).removeClass("selected");
                    }
                    let newNoteType = `type${typeId}`;
                    dvCommentElement.removeClass(noteType);
                    dvCommentElement.addClass(newNoteType);
                    noteType = newNoteType;
                    currentNoteType = typeId;

                    if (note && ((noteTypeId != typeId) != noteTypeChanged)) {
                        noteTypeChanged = !noteTypeChanged;
                        checkAndEnableSaveButton();
                    }
                }

                function checkForNewNoteText() {
                    let usedLetters = scope.noteText ? scope.noteText.length : 0;
                    scope.availablechars = availableCharStr + (maxTextLength - usedLetters);
                    let oldState = noteTextChanged;
                    if (note) {
                        noteTextChanged = (scope.noteText && (scope.noteText != note.text));
                    } else {
                        noteTextChanged = usedLetters > 0;
                    }
                    //rais event only if new state is changed
                    if (oldState != noteTextChanged) {
                        checkAndEnableSaveButton();
                    }
                }

                function checkAndEnableSaveButton() {
                    if (noteTextChanged || noteTypeChanged) {
                        saveButtonElement.removeClass("disabled");
                    } else {
                        saveButtonElement.addClass("disabled");
                    }
                }

                function reload() {
                    delete scope.error;
                    loading = false;
                }

                function cancelEdit() {
                    //function at surrounding note Container.
                    scope.endEditMode();
                }

                function save() {
                    if (!noteTextChanged && !noteTypeChanged || loading) {
                        return;
                    }
                    loading = true;
                    if (note) {
                        editNote();
                    } else {
                        addNote();
                    }
                }

                function addNote() {
                    let note = {
                        "color": currentNoteType,
                        "text": scope.noteText
                    };

                    (async () => {
                        try {
                            await DvNotesService.addNote(note)
                            scope.refreshNotesData();
                        } catch (_) {
                            scope.error = true;
                            scope.errmsg = $filter("translate")("error.note.add");
                        }
                    })();
                }

                function editNote() {
                    let changedJson = {
                        "id": note.id
                    };
                    if (noteTextChanged) {
                        changedJson.text = scope.noteText;
                    }
                    if (noteTypeChanged) {
                        changedJson.color = currentNoteType;
                    }

                    (async () => {
                        try {
                            await DvNotesService.editNote(scope.osid, changedJson);
                            scope.refreshNotesData();
                        } catch (_) {
                            scope.error = true;
                            scope.errmsg = $filter("translate")("error.note.edit");
                        }
                    })();
                }
            }
        };
    }
})();
