import {Injectable} from "@angular/core";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {from, Observable, of, ReplaySubject, Subject} from "rxjs";
import {concatMap, delay, filter, first, mergeAll, switchMap} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class CustomStorageService {

    customStorage: CustomStorage;
    customStorage$: Subject<CustomStorage> = new ReplaySubject<CustomStorage>(1);

    constructor() {
        if (window.electron && process.env.NODE_ENV != "test") {
            this.setElectronCustomStorage();
        } else if (window.cordova && process.env.NODE_ENV != "test") {
            this.setCordovaCustomStorage();
        } else {
            this.setBrowserCustomStorage();
        }
    }

    private setElectronCustomStorage(): void {
        this.customStorage = {
            getItem: (key: string): any => window.electron.store?.get(key),
            setItem: (key: string, value: any): void => window.electron.store?.set(key, value),
            removeItem: (key: string): void => window.electron.store?.delete(key),

            getSecureItems: (): Promise<any[]> => window.electron.keytarProxy.findCredentials("webclient"),
            setSecureItems: async (items): Promise<void> => {
                const existingItems: Array<{ account: string; password: string }> = await window.electron.keytarProxy.findCredentials("webclient");
                const keysToDelete: string[] = [];
                existingItems.forEach(existing => {
                    if (!items.get(existing.account) && !/^\w+@@@/.test(existing.account)) {
                        keysToDelete.push(existing.account);
                    }
                });

                const deleteObs: Observable<void> = from([...keysToDelete]).pipe(
                    concatMap(item => of(item).pipe(delay(10))),
                    switchMap(val => from(window.electron.keytarProxy.deletePassword("webclient", val)) as Observable<void>)
                );

                const setObs: Observable<void> = from([...items]).pipe(
                    // Skip our stored passwords and auth cookies
                    filter(x => !/^\w+@@@/.test(x[0])),
                    // Throttle native calls a bit, otherwise there'll be a couple of listeners and node.js will get upset
                    concatMap(item => of(item).pipe(delay(10))),
                    // map(item => ({account: item[0], password: item[1]})),
                    switchMap(val => from(window.electron.keytarProxy.setPassword("webclient", val[0], val[1])) as Observable<void>));

                return from([deleteObs, setObs]).pipe(mergeAll(1), delay(1000)).toPromise();
            },

            getSecureItem: (key: string): Promise<any> => window.electron.keytarProxy.getPassword("webclient", key),
            setSecureItem: (key: string, value: any): Promise<void> => window.electron.keytarProxy.setPassword("webclient", key, value),
            removeSecureItem: (key: string): Promise<void> => window.electron.keytarProxy.deletePassword("webclient", key)
        };

        this.customStorage$.next(this.customStorage);
    }

    private setCordovaCustomStorage(): void {
        this.customStorage = {
            getItem: (key: string): any => window.localStorage.getItem(key),
            removeItem: (key: string): void => window.localStorage.removeItem(key),
            setItem: (key: string, value: any): void => window.localStorage.setItem(key, value)
        } as CustomStorage;

        const secureStorage: any = new window.cordova.plugins.SecureStorage((): void => {
            const secureEndpoints: any = {
                getSecureItem: (key: string): Promise<any> => new Promise((resolve: (a: any) => void, reject: (a: any) => void): void => {
                    secureStorage.get((value: any) => {
                        resolve(value);
                    }, (error: Error) => {

                        // TODO why this?
                        sessionStorage.removeItem("forceAutoLogin");

                        console.warn(error);
                        if (error.message.includes("Failed to obtain information about key")) {
                            console.warn("Key can't be decrypted, probably the user changed the lock settings. Clearing secure storage.");
                            secureStorage.clear(() => console.info("Secure storage cleared successfully"),
                                exception => console.warn("Error while clearing secure storage", exception));
                            resolve("");
                        } else if (error.message.includes("User not authenticated")) {
                            console.warn("User didn't unlock his device after initially setting a device lock, nothing to be nervous about.");
                            resolve("");
                        } else {
                            reject(error);
                        }
                    }, key);
                }),

                removeSecureItem: (key: string): Promise<void> => new Promise((resolve: () => void, reject: (a: any) => void): void => {
                    secureStorage.remove(() => {
                        resolve();
                    }, (error: Error) => {
                        reject(error);
                    }, key);
                }),

                setSecureItem: (key: string, value: any): Promise<void> => new Promise((resolve: () => void, reject: (a: any) => void): void => {
                    secureStorage.set(() => {
                        resolve();
                    }, (error: Error) => {
                        reject(error);
                    }, key, value);
                })
            };

            Object.assign(this.customStorage, secureEndpoints);
            this.customStorage$.next(this.customStorage);
        }, (error: Error): void => {
            console.warn("Secure storage not activated due to missing device lock.");
            const secureEndpoints: any = {
                getSecureItem: (key: string): Promise<any> => Promise.resolve(this.customStorage.getItem(key)),
                removeSecureItem: (key: string): Promise<void> => Promise.resolve(this.customStorage.removeItem(key)),
                setSecureItem: (key: string, value: any): Promise<void> => Promise.resolve(this.customStorage.setItem(key, value))
            };

            Object.assign(this.customStorage, secureEndpoints);
            this.customStorage$.next(this.customStorage);
        }, "webclient");
    }

    private setBrowserCustomStorage(): void {
        this.customStorage = {
            getItem: (key: string): any => window.localStorage.getItem(key),
            setItem: (key: string, value: any): any => {
                window.localStorage.setItem(key, value);
            },
            removeItem: (key: string): void => {
                window.localStorage.removeItem(key);
            },

            getSecureItem: (key: string): Promise<any> => Promise.reject("getSecureItem is only supported for native clients"),
            setSecureItem: (key: string, value: any): Promise<void> => Promise.reject("setSecureItem is only supported for native clients"),
            removeSecureItem: (key: string): Promise<void> => {
                window.localStorage.removeItem(`sec_${key}`);
                return Promise.resolve();
            }
        };

        this.customStorage$.next(this.customStorage);
    }

    getStorage(): Observable<CustomStorage> {
        return this.customStorage$.asObservable().pipe(first());
    }
}
