import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    HostBinding, HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import {fromEvent, Subject, Subscription} from "rxjs";

import {MessageService} from "CORE_PATH/services/message/message.service";
import {ModalDialogInjectorService} from "MODULES_PATH/modal-dialog/services/modal-dialog-injector.service";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {
    ModalContainerInput,
    ModalDashletsContainerInput
} from "MODULES_PATH/modal-dialog/interfaces/modal-container.interface";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {first} from "rxjs/operators";
import {NavigationService} from "MODULES_PATH/navigation/services/navigation.service";

@Component({
    selector: "eob-modal-container",
    templateUrl: "./eob-modal-container.component.html",
    styleUrls: ["./eob-modal-container.component.scss"],
    providers: [{provide: "$scope", useExisting: "$rootScope"}]
})
export class EobModalContainerComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() input: ModalContainerInput | ModalDashletsContainerInput;
    @Output() output: Subject<any> = new Subject();

    destroy: Subject<any>; // deprecated promise used by ajs dialogs
    destroy$: Subscription;
    backButtonSub: Subscription = new Subscription();
    childDestroy$: Subject<any>;
    isPhone: boolean;

    isPermanent = false;
    hideHeader = false;
    private destroyAll$: Subscription;
    overlayCount: number;
    overlayOpenend: Subscription;
    overlayClosed: Subscription;
    isMockContext: boolean;

    @HostBinding("class.hidden")
    get isClosed(): boolean {
        return (this.input != undefined && !this.input.isOpen && this.input.isPermanent);
    }

    // eslint-disable-next-line max-params
    constructor(private clientService: ClientService,
                @Inject("$rootScope") protected $rootScope: ng.IRootScopeService,
                private messageService: MessageService,
                private modalDialogInjectorService: ModalDialogInjectorService,
                private cdRef: ChangeDetectorRef,
                private navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.isPermanent = !!this.input?.isPermanent;
        this.hideHeader = !!this.input?.hideHeader;
        this.isPhone = this.clientService.isPhone();
        this.isMockContext = this.input?.isMockContext ?? true;

        if (this.clientService.isAndroid()) {
            this.backButtonSub.add(fromEvent(document, "backbutton").pipe(first()).subscribe(event => {
                event.preventDefault();
                event.stopPropagation();
                if (this.overlayCount > 0) {
                    return;
                }

                if (!this.input.noDismissing) {
                    this.close();
                }
            }));
        }

        this.messageService.broadcast(ModalEvents.OPEN_OVERLAY);
        this.initSubscriptionForDestroy();
        this.initSubscriptionForOverlay();
    }

    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    // @HostListener("document:backbutton", ["$event"])
    // handleBackButton(event: Event) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     if (this.overlayCount > 0) {
    //         return;
    //     }
    //
    //     if (!this.input.noDismissing) {
    //         this.closeOnOverlay();
    //     }
    // }

    @HostListener("window:keydown", ["$event"])
    handleKeyDown(event: KeyboardEvent) {
        if (this.overlayCount > 0) {
            return;
        }

        if (!this.input.noDismissing && (event.key == "Escape" || event.key == "Esc")) {
            this.close();
            event.stopPropagation();
        }
    }

    /**
     * Notify the contained inline dialogs like inline tree, that they need to close.
     */
    closeInnerDialogsOnClick(): void {
        // TODO: should be replaced with MessageService.broadcast after migrating all stuff left in ajs to the angular
        this.$rootScope.$broadcast("close.inline.dialogs");
    }

    /**
     * Notify the contained dialog directive, that the dialog will be closed.
     */
    close(): void {
        if (!this.isPermanent) {
            this.messageService.broadcast(ModalEvents.DESTROY);
        } else if (this.input.isOpen != false) {
            (this.input as ModalDashletsContainerInput).closeModalDashletsSubscription.next(true);
            this.input.isOpen = false;
        }
    }

    /**
     * Initilization of destroy$ subscription.
     */
    initSubscriptionForDestroy(): void {
        if (!this.isPermanent) {
            this.destroyAll$ = this.messageService.subscribe(ModalEvents.DESTROY_ALL, () => {
                this.onDestroy();
                this.modalDialogInjectorService.destroy();
            });

            this.destroy$ = this.messageService.subscribe(ModalEvents.DESTROY, (data: any): void => {
                this.onDestroy(data);
                this.modalDialogInjectorService.destroy();
            });
        }
    }

    /**
     * Initialization of overlay subscription
     * Used with keyboard navigation ESC feature to only close the top most modal
     */
    initSubscriptionForOverlay(): void {
        this.overlayCount = 0;
        this.overlayOpenend = this.messageService.subscribe(ModalEvents.OPEN_OVERLAY, () => {
            this.overlayCount += 1;
        });
        this.overlayClosed = this.messageService.subscribe(ModalEvents.CLOSE_OVERLAY, () => {
            this.overlayCount -= 1;
        });
    }

    private onDestroy(data?: any): void {
        if (data) {
            this.output.next(data);
        }

        if (!data && this.input && this.input.rejection) {
            this.output.error(undefined);
        }

        this.$rootScope.$broadcast("close.inline.dialogs", "modalButton");
        this.output.complete();
    }

    destroySubscriptions(): void {
        this.destroy$?.unsubscribe();
        this.overlayOpenend.unsubscribe();
        this.overlayClosed.unsubscribe();

        if (this.childDestroy$) {
            this.childDestroy$.next(true);
        }
    }

    ngOnDestroy(): void {
        if (this.clientService.isOffline()) {
            this.navigationService.closeNavigation(500);
        }
        this.messageService.broadcast(ModalEvents.CLOSE_OVERLAY);
        this.destroyAll$?.unsubscribe();
        this.destroy$?.unsubscribe();
        this.output?.complete();
        this.childDestroy$?.complete();
        this.backButtonSub.unsubscribe();
    }
}
