import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {GridPhoneCellContent} from "MODULES_PATH/grid/interfaces/grid-phone-cell-content.interface";
import {GridContentUtilsService} from "MODULES_PATH/grid/services/grid-content-utils.service";
import {ElementRef, ViewChild} from "@angular/core";

@Component({
    selector: "eob-phone-cell",
    templateUrl: "./phone-cell.component.html",
    styleUrls: ["./phone-cell.component.scss"]
})
export class PhoneCellComponent implements ICellRendererAngularComp {
    cellContent: GridPhoneCellContent = {} as GridPhoneCellContent;
    ellipsisStyle: JQLiteCssProperties = {
        "white-space": "pre-line",
        "display": "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": "2",
        "max-height": "35px",
        "word-break": "break-all",
        "overflow": "hidden"
    };
    isSyncRunning: boolean;

    @ViewChild("svgElementSpan", {static: false}) svgElementSpan: ElementRef<HTMLElement>;

    constructor(private gridContentUtilsService: GridContentUtilsService) {
    }

    agInit(params: ICellRendererParams): void {
        this.setData(params);
    }

    // to refresh cells whose display cell values are out of sync with the actual value after gridOptions.api.refreshCells() is called
    // currently only called when sync status changes
    refresh(params: ICellRendererParams): boolean {
        this.updateSyncStatus(params);
        return true;
    }

    private setData(params: ICellRendererParams) {
        this.cellContent = params.data.f1;
    }

    private updateSyncStatus(params: ICellRendererParams) {
        const content = this.gridContentUtilsService.getPhoneSyncStatus({
            percentage: params.value.percentage,
            failedCount: params.value.failedCount
        });

        this.cellContent.additionalContent = params.data.f1.additionalContent = content.additionalContent;
        this.cellContent.icon = params.data.f1.icon = content.icon;
        this.isSyncRunning = false;

        if (content.svgElement) {
            this.isSyncRunning = true;
            this.svgElementSpan.nativeElement.innerHTML = content.svgElement[0].outerHTML;
        }
    }
}
