export enum ModalEvents {
    /**
     * Thrown if a modal dialog is destroyed and closed.
     *
     * Refactoring: Different dialogs pass different parameters to this event.
     *              This should be normalized. All of our events must documentate
     *              which kind of parameter is passed.
     *
     *  @param any :(
     */
    DESTROY = "DESTROY_MODAL_DIALOG",

    /**
     * Inform other dialogs that there is an overlaying modal on top now
     */
    OPEN_OVERLAY = "OPEN_OVERLAY_MODAL_DIALOG",

    /**
     * Inform other dialogs that an overlaying modal closed
     */
    CLOSE_OVERLAY = "CLOSE_OVERLAY_MODAL_DIALOG",

    /**
     * Wiebke - Please add docu and parameter description with @param.
     */
    DESTROY_ALL = "DESTROY_ALL_MODAL_DIALOGS",

    /**
     * Torsten - Please add docu and parameter description with @param.
     */
    UPDATE_OFFLINE_OBJECTS = "UPDATE_OFFLINE_OBJECTS",

    /**
     * Richard - Please add docu and parameter description with @param.
     */
    INSERT_EMAILS_AT_LOCATION = "INSERT_EMAILS_AT_LOCATION",

    /**
     * Richard - Please add docu and parameter description with @param.
     */
    OPEN_MODAL_DASHLETS = "OPEN_MODAL_DASHLETS"
}