import {MessageService} from "CORE_PATH/services/message/message.service";
import {Dms2SearchService} from "CORE_PATH/backend/modules/dms2/services/search/dms2-search.service";
import {OsRestSearchService} from "CORE_PATH/backend/modules/osrest/services/search/osrest-search.service";
import {Observable} from "rxjs";
import {BackendObject} from "CORE_PATH/backend/interfaces/search-result/backend-object.interface";
import {Injectable} from "@angular/core";
import {BackendModule} from "CORE_PATH/backend/backend.module";
import {BackendBaseService} from "CORE_PATH/backend/services/base/backend-base.service";
import {HttpParams} from "@angular/common/http";
import {TodoQuery, TodoQueryConfig} from "INTERFACES_PATH/any.types";
import {BackendSearchIdRequest} from "CORE_PATH/backend/interfaces/search-requests/backend-search-id-request";

@Injectable({providedIn: BackendModule})
export class BackendSearchService extends BackendBaseService<Dms2SearchService | OsRestSearchService>{

    constructor(messageService: MessageService, dms2SearchService: Dms2SearchService, osRestSearchService: OsRestSearchService) {
        super(messageService, dms2SearchService, osRestSearchService);
    }

    getObjectMetadata(osId: string, objectTypeId: string, parameters?: HttpParams): Observable<BackendObject> {
        // todo: fix DODO-15343
        return this.osRestService.getObjectMetadata(osId, objectTypeId, parameters);
    }

    /**
     * Executes a search based on a query.
     *
     * @param {TodoQuery} query - The query like osrest query.
     * @param {TodoConfig} config - The config like {"parameter": "value", "parameter: "value"}.
     * @return {Observable<BackendObject[]>} - An observable list.
     *
     */
    search(query: TodoQuery, config?: TodoQueryConfig): Observable<BackendObject[]> {
        // enable delegate after DODO-14265 has been fixed
        // return this.delegate.search(query, config);

        return this.osRestService.search(query, config);
    }

    /**
     * Provides all checked out documents.
     *
     * @return {Observable<BackendObject[]>} - An observable list of documents.
     */
    getCheckedOutDocuments(): Observable<BackendObject[]> {
        return this.osRestService.getCheckedOutDocuments();
    }

    /**
     * Returns all the documents a folder contains recursively as a flat list.
     *
     * @param {string} osId - The ID of the folder.
     * @param {number} maxSize - Maximum number of hits.
     * @return {Observable<BackendObject[]>} - An observable flat list of elements.
     */
    getFolderContent(osId: string, maxSize: number): Observable<BackendObject[]> {
        return this.osRestService.getFolderContent(osId, maxSize);
    }

    /**
     * Executes a full text search.
     *
     * @param query
     * @return {Observable<BackendObject[]>} - An observable list of elements.
     */
    searchVtx(query: TodoQuery): Observable<BackendObject[]> {
        return this.osRestService.searchVtx(query);
    }

    /**
     * Searches by object IDs.
     *
     * @param query - A object which includes all object ids per object type id
     */
    searchByIds(query: BackendSearchIdRequest): Observable<BackendObject[]> {
        return this.osRestService.searchByIds(query);
    }
}