(function() {
    require("SERVICES_PATH/eob.search.srv.js");
    require("SERVICES_PATH/eob.backend.srv.js");
    require("SERVICES_PATH/eob.environment.srv.js");

    angular.module("eob.core").factory("dbCatalogService", DbCatalogService);

    DbCatalogService.$inject = ["$q", "searchService", "backendService", "asIniService", "sortService", "environmentService", "messageService",
        "valueUtilsService", "formCatalogParserService", "clientService", "queryBuilderService"];

    /**
     * Contains util functions for handling db catalogs.
     */
    // eslint-disable-next-line max-params, require-jsdoc
    function DbCatalogService($q, SearchService, BackendService, AsIniService, SortService, EnvironmentService, MessageService,
                              ValueUtilsService, FormCatalogParserService, ClientService, QueryBuilderService) {

        let maxHitsReached = false;

        return {
            getDbEntries,
            getMaxhitsReached,
            showDbCatalog
        };

        /**
         * Request catalog entries and display them in a tree popup.
         *
         * @param {Event} event - A js event.
         * @param {FormField} field - The catalog form field.
         * @param {FormHelper} formHelper - The form helper of the current form.
         * @returns {Promise} A promise that resolves once an event is thrown, that triggers the tree to be shown.
         */
        async function showDbCatalog(event, field, formHelper) {
            event.stopImmediatePropagation();

            let result = await getDbEntries(field, formHelper);

            if (field.model.hasLeadingZeros) {
                setLeadingZerosConfig(field);
            }

            MessageService.broadcast("showTree", {
                event,
                target: event.target,
                data: result.field,
                element: field.api.getElement(),
                list: result.list
            });
        }

        /**
         * Whether max hits are reached or not.
         *
         * @returns {boolean} Whether max hits are reached or not.
         */
        function getMaxhitsReached() {
            return maxHitsReached;
        }

        /**
         * Get all db catalog entries.
         *
         * @param {FormField} field - The catalog form field.
         * @param {FormHelper} formHelper - The form helper of the current form.
         * @param {string=} searchKey - An optional text to filter the entries.
         * @returns {Promise} A promise that is resolved with the catalog entries.
         */
        async function getDbEntries(field, formHelper, searchKey = "") {
            let query = createQuery(field, formHelper, searchKey);

            let ecmObjects;
            try {
                // this call is allowed to fail in cases where invalid field values are included
                // this will happen if the db catalog is set to IGNOREOTHERFIELDINEDIT=NEIN
                ecmObjects = (await BackendService.post("/documents/search?pagesize=10&fieldsschema=DEF", query)).data;
            } catch (warning) {
                console.warn(warning);
            }

            let list = [],
                internal = field.model.internal;

            maxHitsReached = false;
            for (let i in ecmObjects) {
                let ecmObject = ecmObjects[i];

                if (field.model.isGridCell) {
                    const tableField = ecmObject.ecmTableFields.find(tf => tf.internalName == field.model.grid.model.internal);
                    searchKey = searchKey != "" ? getAutostarSearchKey(field, searchKey) : searchKey;

                    for (const row of tableField.value) {
                        let value = row.ecmSimpleFields[0].value;

                        // we need to filter the search key manually, since the backend can't do it
                        if (!value || (searchKey != "" && !new RegExp(`^${searchKey.replaceAll("*", ".*")}$`).test(value))) {
                            continue;
                        }

                        if (field.model.hasLeadingZeros) {
                            value = `${ValueUtilsService.formatLeadingZeros(value, field.model.maxLength)}|${value}`;
                        }

                        list.push(value);
                    }
                } else {
                    const simpleField = ecmObject.ecmSimpleFields.find(sf => sf.internalName == internal);
                    let value = simpleField.value;

                    if (field.model.hasLeadingZeros) {
                        value = `${ValueUtilsService.formatLeadingZeros(value, field.model.maxLength)}|${value}`;
                    }

                    list.push(value);
                }
            }

            list = FormCatalogParserService.parseListEntries(field, list);

            field.model.tree = FormCatalogParserService.parseTreeNodes(list, [], { multiSelect: false }, field.model);

            list = field.api.getListEntries();

            SortService.sortListField(field, list);

            maxHitsReached = list.length == query.query.result_config.maxhits;

            return {
                field,
                list
            };
        }

        /**
         * Create a query for requesting the distinct values of the given form field.
         *
         * @param {FormField} field - The catalog form field.
         * @param {FormHelper} formHelper - The form helper of the current form.
         * @returns {Query} A query object for a backend search.
         */
        function createQuery(field, formHelper, searchKey) {
            let query,
                objectTypeId = formHelper.getModel().config.objectTypeId;

            if (field.model.isGridCell) {
                return createTableQuery(field, formHelper);
            }

            if (field.model.config.ignoreOtherFieldsInEdit && (formHelper.isCreate || formHelper.isEdit)) {
                query = {query: QueryBuilderService.createQuery(true)};
                query.query.objectTypeId = objectTypeId.toString();
            } else {
                let queryData = {
                    formDataTypes: {},
                    objectTypeIds: [objectTypeId]
                };
                queryData.formDataTypes[objectTypeId] = formHelper.getFields();

                query = QueryBuilderService.createFormDataQuery(queryData);
            }

            query.query.result_config = Object.assign(query.query.result_config, {
                deny_empty: false,
                maxhits: -1,
                distinct: true,
                fieldsschema: [{ internalName: field.model.internal }]
            });

            setSearchKey(field, query, searchKey);

            return query;
        }

        function createTableQuery(field, formHelper) {
            const objectTypeId = formHelper.getModel().config.objectTypeId;

            const query = {query: QueryBuilderService.createQuery(true)};
            query.query.objectTypeId = objectTypeId.toString();

            query.query.result_config = Object.assign(query.query.result_config, {
                deny_empty: false,
                distinct: true,
                maxhits: -1,
                fieldsschema: [{
                    type: "GRID",
                    internalName: field.model.grid.model.internal,
                    columns: [{ internalName: field.model.internal }]
                }]
            });

            return query;
        }

        /**
         * Add the given search term to the given query.
         *
         * @param {FormField} field - The catalog form field.
         * @param {Query} query - The backend search query.
         * @param {string=} searchKey - An optional text to filter the entries.
         */
        function setSearchKey(field, query, searchKey = "") {
            if (searchKey == "") {
                return;
            }

            if (query.query.fields[field.model.internal] == void 0) {
                query.query.fields[field.model.internal] = {
                    internalName: field.model.internal,
                    value: ""
                };
            }

            query.query.fields[field.model.internal].value = getAutostarSearchKey(searchKey);
        }

        function getAutostarSearchKey(field, searchKey) {
            // when we are using the db autostar then there are more than one possible configurations
            // i.e. behind the db catalog plus the normal star before each field, we need to set the
            // value on both ends of the string, else we simply set it behind
            if (field.model.type != "number" && AsIniService.useAutostarBehindDbCatalog()) {
                if ((EnvironmentService.getSessionInfo().autostar == "1") || (EnvironmentService.getSessionInfo().autostar == "3")) {
                    return `*${searchKey}*`;
                } else {
                    return `${searchKey}*`;
                }
            }
            return searchKey;
        }

        /**
         * Display entries with leading zeros, but enter the normal value into the field.
         * @param {FormField} field - The catalog form field.
         */
        function setLeadingZerosConfig(field) {
            field.model.tree.config.hideValues = true;

            field.applySelection = (item) => {
                field.api.setValue(item.value, true);

                if (!ClientService.isTouchDevice()) {
                    field.api.focus();
                }
            }
        }
    }
})();
