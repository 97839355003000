import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {downgradeComponent} from "@angular/upgrade/static";
import * as angular from "angular";
import {IModule} from "angular";
import MODULE_NAME from "ROOT_PATH/app/app.module.ajs";
import {Subscription} from "rxjs";
import {ClientService} from "CORE_PATH/services/client/client.service";
import {FormLayoutService} from "MODULES_PATH/form/services/form-layout.service";
import {Profile, ProfileService} from "CORE_PATH/authentication/util/profile.service";
import {AuthenticationService} from "CORE_PATH/authentication/authentication.service";
import {DatabaseEntryType} from "ENUMS_PATH/database/database-entry-type.enum";
import {AfterViewInit, ElementRef, Inject, Injector, Renderer2} from "@angular/core";
import {FileCacheService} from "SERVICES_PATH/mobile-desktop/eob.file.cache.srv";
import {WindowEventType} from "ENUMS_PATH/window-event-type.enum";
import {OfflineService} from "SERVICES_PATH/offline/eob.offline.srv";
import {MessageService} from "CORE_PATH/services/message/message.service";
import {Subject} from "rxjs";
import {Broadcasts} from "ENUMS_PATH/broadcasts.enum";
import {CustomStorageService} from "CORE_PATH/services/custom-storage/custom-storage.service";
import {CustomStorage} from "INTERFACES_PATH/custom-storage.interface";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {ProfileCacheKey} from "ENUMS_PATH/database/profile-cache-key.enum";
import {ProgressStatusService} from "CORE_PATH/services/progress/progress-status.service";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {StateService, TargetState, Transition, TransitionService} from "@uirouter/core";
import {NotificationsService} from "CORE_PATH/services/notification/notifications.service";
import {UpdateUtilService} from "SERVICES_PATH/utils/update-utils.srv";
import {ModalEvents} from "MODULES_PATH/modal-dialog/enums/modal.enum";
import {InlineDialogEvent} from "ENUMS_PATH/inline-dialog-event.enum";
import {GlobalCacheKey} from "ENUMS_PATH/database/global-cache-key.enum";
import {LoggingService} from "CORE_PATH/services/logging/logging.service";
import {AuthenticationType} from "CORE_PATH/authentication/interfaces/authentication-protocol.interface";
import {environment} from "ROOT_PATH/environments/environment";
import {
    TodoClientScriptService,
    TodoEnvironmentService, TodoFormService,
    TodoModalDialogService,
    TodoStateHistoryManager
} from "INTERFACES_PATH/any.types";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {AsIniService} from "CORE_PATH/services/as-ini/as-ini.service";
import {UpdateService} from "SERVICES_PATH/eob.update.srv";
import {OfflineCacheService} from "SERVICES_PATH/offline/eob.offline.cache.srv";
import {ErrorModelService} from "CORE_PATH/services/custom-error/custom-error-model.service";

/**
 * The main app component.
 */
@Component({
    selector: "eob-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    // TODO find out, what these $rootScope properties did for rootScope
    loading: boolean; // this was $rootScope.loading

    loadingMessage: string;
    shouldShowProfileManager: boolean;
    initErrorMessage: any;
    updateAvailable: boolean;
    webclientReady: boolean;
    viewerReady: boolean;
    isDemo: boolean;
    showSadPage: boolean;

    private readonly translate: TranslateFnType;
    private subscriptions: Subscription = new Subscription();
    private stateRefresher: any = null;
    private splashScreen: HTMLElement;
    private customStorage: CustomStorage;
    private resizeTimer: NodeJS.Timeout;

    // subscription to wait for profile manager component sending a signal to init main view
    private initMainViewSubscription: Subscription;
    currentlyShowingResizeInfo: boolean = false;

    // there shall be silence...
    /* eslint-disable max-params, no-unused-vars */
    constructor(private clientService: ClientService,
                private formLayoutService: FormLayoutService,
                private profileService: ProfileService,
                private authenticationService: AuthenticationService,
                private messageService: MessageService,
                private customStorageService: CustomStorageService,
                private progressStatusService: ProgressStatusService,
                private objectTypeService: ObjectTypeService,
                private notificationsService: NotificationsService,
                private loggingService: LoggingService,
                private layoutManagerService: LayoutManagerService,
                private asIniService: AsIniService,
                private errorModelService: ErrorModelService,
                private renderer: Renderer2,
                private el: ElementRef<HTMLElement>,
                private cdRef: ChangeDetectorRef,
                @Inject("$injector") private $injector: ng.auto.IInjectorService,
                @Inject("updateUtilService") private updateUtilService: UpdateUtilService,
                @Inject("fileCacheService") private fileCacheService: FileCacheService,
                @Inject("offlineService") private offlineService: OfflineService,
                @Inject("updateService") private updateService: UpdateService,
                @Inject("offlineCacheService") private offlineCacheService: OfflineCacheService,
                @Inject("stateHistoryManager") private stateHistoryManager: TodoStateHistoryManager,
                @Inject("clientScriptService") private clientScriptService: TodoClientScriptService,
                @Inject("environmentService") private environmentService: TodoEnvironmentService,
                @Inject("formService") protected formService: TodoFormService,
                @Inject("modalDialogService") private modalDialogService: TodoModalDialogService,
                @Inject("$filter") private $filter: ng.IFilterService,
                @Inject("$state") private $state: StateService,
                @Inject("$transitions") private $transitions: TransitionService,
                @Inject("$translate") private $translate: any,
                @Inject("$rootScope") private $rootScope: ng.IRootScopeService,
    ) {
        this.translate = this.$filter("translate");

        this.subscriptions.add(
            customStorageService.getStorage().subscribe((storage) => {
                this.customStorage = storage;
            }));
    }

    // ok, ok... you may speak again
    /* eslint-enable max-params, no-unused-vars */

    // Note: Changing the signature of ngOnInit to return a promise may delay the rendering
    // of the component, until the promise resolves. But it is a nice way to handle the await calls.
    async ngOnInit(): Promise<void> {
        this.clientService.init();
        this.formLayoutService.calculateCharsWidth();

        if (this.clientService.isLocalClient()) {
            this.addOfflineSyncEndedEventHandling();
        }

        if (this.clientService.isDesktop()) {
            // check for automatic profile initialization
            await this.profileService.checkAutomaticProfileInitialization();
        }

        // if (!this.clientService.isLocalClient()) {
        //     // if we're in a browser, there are no profiles and the browser takes care of authentication
        //
        //     // eslint-disable-next-line promise/catch-or-return,promise/prefer-await-to-then
        //     this.$translate.onReady().then(() => {
        //         this.initMainView();
        //     }).catch(err => this.handleError(err));
        // } else {
            // this is cordova or electron, so test for showing or skipping the profile manager view
            await this.showOrSkipProfileManager();
        // }
    }

    ngAfterViewInit(): void {
        this.splashScreen = this.el.nativeElement.querySelector("#eob-splash");
        this.layoutManagerService.initAsync().then(() => {
            if (this.clientService.isDetached()) {
                return;
            }

            if (this.layoutManagerService.isTouchLayoutActive()) {
                this.renderer.addClass(document.body, "is-touch");
            }
            if (this.clientService.isPhone()) {
                this.renderer.addClass(document.body, "is-phone");
            }
        }).catch(err => console.warn(err));
    }

    @HostListener("window:beforeunload")
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();

        if (this.stateRefresher) {
            window.removeEventListener(WindowEventType.SYNC_ENDED, this.stateRefresher);
        }

        this.stateHistoryManager.save();
    }

    // Switch to phone layout if user zooms the page ~>400%
    @HostListener("window:resize")
    onResize(): void {
        if (this.clientService.isDetached()) {
            return;
        }

        this.resizeTimer = setTimeout(() => {
            const isCurrentSizeNarrow = window.innerWidth < 600;
            const wasSmallBefore = this.clientService.isPhone();
            this.clientService.forcePhoneLayout(isCurrentSizeNarrow);

            if (!this.clientService.checkIsPhone() && (wasSmallBefore != isCurrentSizeNarrow)) {
                if (this.currentlyShowingResizeInfo) {
                    this.closeDialogs();
                    this.currentlyShowingResizeInfo = false;
                    return;
                }

                this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
                this.currentlyShowingResizeInfo = true;
                this.modalDialogService.infoDialog(
                    this.translate("resize.dialog.heading"),
                    this.translate("resize.dialog.message"),
                    this.translate("modal.button.cancel"),
                    this.translate("modal.refresh.title"), undefined, true, undefined).then(_ => {
                    this.currentlyShowingResizeInfo = false;
                    location.reload();
                    }).catch(error => {
                        this.currentlyShowingResizeInfo = false;
                        this.clientService.forcePhoneLayout(false);
                        console.error(error);
                    });
            }
        }, 100);
    }

    async logout(): Promise<void> {
        await this.clientService.logoutAsync();
    }

    private async showOrSkipProfileManager(): Promise<void> {
        //
        // this is mobile or electron, we have to check for profiles and autologin
        // and decide, whether to show the profile manager view or directly login
        // and continue to the bluebird main view
        //

        let forceShowProfileManager = false;

        let timeout = 0;
        let showProfileManagerOnCtrl: any = null;
        if (this.clientService.isDesktop() && this.profileService.getLastActiveProfileKey()) {
            // if we run in electron and have a last active profile, the user will get two seconds
            // to press the CTRL key to overwrite autologin

            showProfileManagerOnCtrl = (event): void => {
                if (event.key == "Control") {
                    forceShowProfileManager = true;
                    document.removeEventListener("keydown", showProfileManagerOnCtrl);
                }
            };

            // retrieve last stored session storage and reset random ids
            await window.electron.retrieveSessionStorageAsync();
            this.clientService.setRandomIds();

            document.addEventListener("keydown", showProfileManagerOnCtrl);
            timeout = sessionStorage.getItem("afterFirstLogin") ? 0 : 2000;
        }

        // surround async timeout block with a waiting subject for deposing asynch calls
        const innerWait: Subject<any> = new Subject();
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setTimeout(async () => {
            if (showProfileManagerOnCtrl) {
                document.removeEventListener("keydown", showProfileManagerOnCtrl);
            }

            this.loadingMessage = "";
            try {
                if (forceShowProfileManager) {
                    this.showProfileManager(null);
                } else if (await this.isAutoLogin()) {
                    // auto login is in progress
                    sessionStorage.setItem("forceAutoLogin", "true");

                    const currentProfile: Profile = this.profileService.prepareCurrentProfile();
                    if (this.clientService.isDesktop()) {
                        window.electron.storeSessionStorage();
                        window.electron.restoreWindowSizeAndPosition(currentProfile);
                    }

                    // eslint-disable-next-line promise/catch-or-return,promise/prefer-await-to-then
                    this.$translate.onReady().then(async () => {
                        // In case this is the initial tab loading, we want to perform the update check before bootstrapping the client
                        // TODO use specific decision criteria instead of relying on this not directly related timeout
                        if (timeout) {
                            // update check for auto login would freeze modal dialog in splash screen,
                            // so do it after webclient is initialzed
                            if (this.clientService.isDesktop()) {
                                try {
                                    await window.electron.checkForUpdates(currentProfile);
                                } catch (_) {
                                    // error ignored
                                }
                            }
                        }

                        // prior to starting the main view, user roles, session infos etc. has to
                        // set in environment service
                        await this.initMainView();
                    });

                } else {
                    this.showProfileManager(null);
                }
            } catch (error) {
                this.showProfileManager(error);
            } finally {
                innerWait.next();
                innerWait.complete();
            }

        }, timeout);

        // wait for inner subject
        await innerWait.toPromise();
    }

    private async isAutoLogin(): Promise<boolean> {
        if(!this.clientService.isLocalClient()) {
            this.authenticationService.selectAuthenticationProvider(AuthenticationType.BASIC_AUTH);
            return this.authenticationService.isAuthenticated().toPromise();
        }

        const lastActiveProfileKey: string = this.profileService.getLastActiveProfileKey();
        if (!lastActiveProfileKey) {
            // no last active profile key, thus no authentication currently set in session
            return false;
        }

        const lastActiveProfile: Profile = this.profileService.getLastActiveProfile();

        if (!this.clientService.isOnline() && lastActiveProfile) {
            // we're offline, just check session storage
            this.authenticationService.selectAuthenticationProvider(lastActiveProfile.authType);
            return sessionStorage.getItem("forceAutoLogin") == "true" || (await this.authenticationService.isAuthenticated().toPromise()) && lastActiveProfile.autologin;
        } else if (lastActiveProfile && (lastActiveProfile.autologin || sessionStorage.getItem("forceAutoLogin"))) {
            // we're online, check last active profile and session storage
            return this.checkLogin(lastActiveProfile);
        }

        return lastActiveProfile.autologin;
    }

    // eslint-disable-next-line no-unused-vars
    private async checkLogin(profile: Profile): Promise<boolean> {
        this.authenticationService.selectAuthenticationProvider(profile.authType);
        const authenticated: boolean = await this.authenticationService.isAuthenticated().toPromise();
        if (authenticated) {
            // TODO reconsider the following line
            // localStorage.removeItem("clipboard");
            sessionStorage.setItem("forceAutoLogin", "true");
            return true;
        } else {
            return false;
        }
    }

    private async initLanguage(): Promise<void> {
        let lang = "de";
        try {
            lang = this.environmentService.getLanguage();
            await this.$translate.use(lang);
        } catch (e1) {
            console.error("Cannot find preferred language resources, switch to fallback: German");
            try {
                lang = "de";
                await this.$translate.use(lang);
            } catch (e2) {
                this.showSadPage = true;
            }
        } finally {
            document.documentElement.setAttribute("lang", lang);
        }
    }

    private handleElectronLocalization(): void {
        try {
            window.electron.setNavigationTooltips({
                "#nav-ctrls-back": this.translate("modal.button.back", true),
                "#nav-ctrls-forward": this.translate("modal.button.forward", true),
                "#nav-ctrls-reload": this.translate("modal.refresh.title", true),
                "#nav-tabs-add": this.translate("modal.open.new.tab", true)
            });
            window.electron.setTranslationStrings({
                exceptionTitle: this.translate("eob.exception.title", true),
                exceptionMessage: this.translate("eob.exception.message", true)
            });
        } catch (error) {
            // ignored (sometimes there is an error in unit tests due to race conditions)
        }
    }

    // initMainView() has to be declared as a variable (not a function), otherwise running it via
    // initMainViewSubscription leads to a stub "this" object with empty local variables
    initMainView = async (): Promise<void> => {
        this.shouldShowProfileManager = false;
        this.progressStatusService.initProgressBar();
        this.toggleSplashScreen(true);

        try {
            // environment service init uses file cache service
            await this.fileCacheService.initAsync();

            // As the first call to the backend we must detect which enaio version is running
            // in the backend. Once we know it we can decide which backend we could use: DMS2 | OSRest
            await this.environmentService.fetchProductVersionAsync();

            // environment service will first do an osrest request to /session/systemroles,
            // so at this point, authentication has to be set
            await this.asIniService.loadSettings().toPromise();
            await this.environmentService.initAsync();
            await this.updateService.updateAsync();
            this.offlineService.init();
        } catch(err) {
            console.warn(err);
            this.authenticationService.invalidateSession().subscribe();
            this.handleError(err);
            return;
        }

        // eslint-disable-next-line no-undef
        // @ts-ignore
        this.loggingService.info(`Starting webclient ${environment.bluebirdVersion} ${process.env.NODE_ENV}`);

        try {
            await this.initLanguage();
            if (this.clientService.isDesktop()) {
                this.handleElectronLocalization();
            }

            // eslint-disable-next-line no-undef
            if (this.clientService.isDesktop() && !environment.test) {
                window.electron.storeSessionStorage();
                window.electron.showNewTabButton();

                // prevent update checks, if we already know there is an update available
                if (!this.updateAvailable) {
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    const checkUpdateInterval = setInterval(async () => {
                        const result: boolean = await window.electron.checkForUpdates(this.profileService.prepareCurrentProfile());
                        if (result) {
                            clearInterval(checkUpdateInterval);
                        }
                    }, 900 * 1000);
                }
            }

            // TODO ??? nobody seems to use window.location.baseUrl
            (window.location as any).baseUrl = this.clientService.isLocalClient() ? this.profileService.getCurrentBaseUrl() : window.location.origin;

            let objectTypesToIgnore: string[] = [];
            try {
                 objectTypesToIgnore = await this.clientScriptService.executeAfterLoginScriptAsync();
                if (objectTypesToIgnore.length > 0) {
                    this.loggingService.info(`Object types to ignore: ${objectTypesToIgnore.toString()}`);
                }
            } catch (e) {
                // Make sure the error message is also printed to the console when logging to idb
                // @ts-ignore
                _console.warn("Error executing after login script:\n", e);
                throw this.errorModelService.createCustomError("WEB_FORM_SCRIPT_RETURNED_ERROR");
            }

            this.loggingService.info(`Current backend version: ${this.environmentService.getProductVersion()}`);

            this.progressStatusService.setProgressBarValue(25);

            // init the objecttype definition
            // we just trigger each type here and cache a promise for each type
            // each component requesting another type will just get a promise from the service
            // types that are not cached yet might me not be part of the users objecttype definition
            await this.objectTypeService.initAsync(objectTypesToIgnore);

            this.progressStatusService.setProgressBarValue(50);

            this.environmentService.setDateFormats();

            // we need to check if the current user has some work to do before he can enter the bluebird
            // the first thing is reporting presence or not
            // we need to do this before we set the webclient ready because it could happen,
            // that the modal dialog opens automatically after init, and this would cause some problems
            // when we show a confirmation at init
            this.progressStatusService.setProgressBarValue(75);
            this.progressStatusService.setLoadingMessage(this.translate("eob.loading.checking.absence"));
            this.cdRef.detectChanges();

            await this.environmentService.checkAbsenceAsync();

            this.formService.init();

            this.progressStatusService.setProgressBarValue(90);

            // now we are ready to switch on a specific view

            // check if there is a state that was not finished
            // this might happen due to a browser crash or something else, where the webclient suddenly stops working or
            // the browser gets killed .. or a power outtage
            const autosavedDataRetrieverPromise: Promise<any> = this.fileCacheService.getContentAsync(
                DatabaseEntryType.PERSISTENT, ProfileCacheKey.AUTOSAVED_DATA, {first: true});
            let autosavedData: any;
            if (this.clientService.isiOs()) {
                // On iOS, opening the IndexedDB can sometimes hang. To mitigate having a hung app, the autosave data is
                // retrieved using a retry logic, which fails gracefully after 5 attempts by not supplying autosave data
                for (let i = 0; i < 5; i++) {
                    const timeoutPromise: Promise<any> = new Promise(resolve => setTimeout(() => {
                        resolve(false);
                    }, 500));
                    autosavedData = await Promise.race([timeoutPromise, autosavedDataRetrieverPromise]);
                    if (autosavedData !== false) {
                        break;
                    }

                    console.warn("Failed to retrieve autosave data");
                }
            } else {
                autosavedData = await autosavedDataRetrieverPromise;
            }

            if (autosavedData != void 0 && autosavedData.locationHash) {
                try {
                    await this.modalDialogService.infoDialog(
                        this.translate("eob.modal.restore.data.title"),
                        this.translate("eob.modal.restore.data.description"),
                        this.translate("eob.modal.restore.data.cancel"),
                        this.translate("eob.modal.restore.data.restore"));

                    // we have unfinished work ... lucky you, we saved it
                    let hash = "";

                    // now redirect to the state where the user was
                    if (autosavedData.locationHash.indexOf("restoreAutosave") == -1) {
                        hash = `${autosavedData.locationHash}&restoreAutosave=true`;
                    } else {
                        hash = autosavedData.locationHash;
                    }

                    if (this.$state.current.name == "dashboard") {
                        location.hash = hash;
                    } else {
                        const url = `${location.pathname}${hash}`;
                        history.replaceState(null, null, url);
                    }
                } catch (ex) {
                    this.fileCacheService.deleteContentAsync(DatabaseEntryType.PERSISTENT, ProfileCacheKey.AUTOSAVED_DATA);
                }
            }

            // either switch on the detached viewer or the webclient main view
            if (/#\/detachedViewer/gi.test(location.href)) {
                this.viewerReady = true;
            } else {
                this.webclientReady = true;
            }

            // This property and event is still used by legacy code, which has to be refactored,
            // before we're able to ditch $rootScope here.
            (this.$rootScope as any).webclientReady = true;
            this.$rootScope.$emit("webclientReady");

            sessionStorage.setItem("afterFirstLogin", "true");

            if (this.clientService.isDesktop()) {
                // send client infos to electron main process listeners after successfull login
                await window.electron.onProfileLoggedInAsync();
                window.electron.storeSessionStorage();
            }

            this.fileCacheService.storeContentAsync(DatabaseEntryType.GLOBAL, this.clientService.getRandomIds().randomSessionId, GlobalCacheKey.RANDOM_SESSION_ID);

            // list of always allowed states for offline transitions
            const allowedOfflineTransitions: string[] = ["hitlist.offlineObjects", "hitlist.favorites", "indexdata", "hitlist.failedSyncObjects", "detachedViewer"];

            if (!this.clientService.isOnline() && this.clientService.isLocalClient()) {
                this.notificationsService.info(this.translate("eob.init.offline.mode"));
                if (!allowedOfflineTransitions.includes(this.$state.current.name)) {
                    const osid: any = this.stateHistoryManager.getCurrentStateData().data.objectId;
                    if (osid == void 0 || !(await this.offlineCacheService.isObjectOfflineCached(osid))) {
                        this.clientService.executeStateErrorFallback();
                    }
                }
            }

            this.isDemo = (this.profileService.prepareCurrentProfile().isDemo) && (this.profileService.prepareCurrentProfile().username === "mobi");
            if (this.initMainViewSubscription) {
                // after the profile manager component was shown, a subscription was created
                // to wait for a signal, to init main view
                this.initMainViewSubscription.unsubscribe();
                this.initMainViewSubscription = null;

                this.renderer.removeClass(document.body, "pre-styles");
            }
            this.removeSplashScreen();

            setTimeout(() => {
                this.$transitions.onSuccess({}, () => {
                    this.closeDialogs();
                    window.dispatchEvent(new Event("hashchange"));
                });

                // check for transition restrictions in Electron and Cordova clients
                this.$transitions.onBefore({}, async (transition: Transition): Promise<boolean> => {
                    if (!this.clientService.isLocalClient() || this.clientService.isOnline()) {
                        return true;
                    }

                    const transitionFrom: string = transition.from().name;
                    const transitionTo: string = transition.to().name;

                    if (allowedOfflineTransitions.includes(transitionTo)) {
                        // this transition is always allowed in offline mode
                        return true;
                    }

                    const targetState: TargetState = transition.targetState();
                    const targetId: any = targetState.params().folderId;

                    // offline folder view (tree or flat) only if target id is in offline cache
                    if (targetId !== void 0 && await this.offlineCacheService.isObjectOfflineCached(targetId)) {
                        // folder tree view
                        if (transitionTo === "folder") {
                            return true;
                        }

                        // folder flat view
                        if (transitionTo === "hitlist.result" && targetState.options().location && targetState.params().flat) {
                            return true;
                        }
                    }

                    /**
                     * ATTENTION:
                     *
                     * $transitions.onBefore is called, AFTER all changes took place, right BEFORE the change to
                     * a new view or state. We do not know, what has already been changed, when we arrive here.
                     * Calls in between might have changed AS.INI values or made some preparations for the new view.
                     * Even the location URL might have already been changed, because we are navigating to a new state.
                     *
                     * Thus, we are not allowed to return false here and leaving an uncontrolled mess behind, while we
                     * visually stay in the view and the user is getting a simple notification toaster.
                     */

                    console.warn(`transition from '${transitionFrom}' to '${transitionTo}' not allowed in offline mode`);
                    this.notificationsService.info(this.translate("eob.message.offline.function.disabled"));

                    transition.abort();
                    return false;
                });

                // show an info dialog, if the offline cache was reset due to a database update
                if (this.updateUtilService.syncUpdateNecessary && this.clientService.isLocalClient()
                    && this.asIniService.isSynchronizeFavoritesOffline()) {
                    const title: string = this.translate("eob.modal.update.cache.title"),
                        message: string = this.translate("eob.modal.update.cache.information"),
                        submitButton: string = this.translate("modal.button.submit");
                    this.modalDialogService.infoDialog(title, message, undefined, submitButton, undefined, true);
                }
            }, 0);
        } catch (error) {
            console.error(error);
            if (this.clientService.isLocalClient()) {
                this.showProfileManager(error);
            } else {
                await this.initLanguage();
                this.handleError(error);
            }
        } finally {
            this.cdRef.detectChanges();
        }
    };

    private showProfileManager(error: any): void {
        this.$translate.onReady().then(() => {
            if (this.clientService.isDesktop()) {
                // init electron localization for first time arrival, where there is no env language initialized
                this.handleElectronLocalization();
            }

            this.shouldShowProfileManager = true;
            this.initErrorMessage = error;
            this.toggleSplashScreen(false);

            // subscribe to wait for profile manager component sending a signal to init main view
            this.initMainViewSubscription = this.messageService.subscribe(Broadcasts.INIT_MAIN_VIEW, () => {
this.initMainView();
});
        }).catch(err => this.handleError(err));
    }

    private closeDialogs(): void {
        this.messageService.broadcast(ModalEvents.DESTROY_ALL);
        this.messageService.broadcast(InlineDialogEvent.CLOSE_INLINE_DIALOGS);
    }

    /**
     * Takes care of adding and removing the event handler and handling of "sync-ended".
     */
    private addOfflineSyncEndedEventHandling(): void {
        this.stateRefresher = (event): Promise<void> => {
            const sourceWebviewId: string = event.detail;
            if (sourceWebviewId === window.webviewId) {
                return;
            }

            this.offlineService.init();

            if (["hitlist.offlineObjects", "hitlist.failedSyncObjects"].some(state => this.$state.current.name == state)) {
                this.$state.reload();
            }
        };

        window.addEventListener(WindowEventType.SYNC_ENDED, this.stateRefresher);
    }

    private handleError(errorObject: any): void {
        this.removeSplashScreen();

        const errorMsg: any = errorObject.message || errorObject.toString();

        if (this.clientService.isLocalClient()) {
            this.notificationsService.error(errorMsg);
            this.shouldShowProfileManager = true;
        } else {
            this.initErrorMessage = errorMsg;
            this.showSadPage = true;
        }
    }

    private removeSplashScreen(): void {
        this.progressStatusService.setProgressBarValue(100);
        setTimeout(() => {
            this.renderer.addClass(this.splashScreen, "leave");
            setTimeout(() => {
                // this.renderer.removeChild(document.body, this.splashScreen);
                this.renderer.setStyle(this.splashScreen, "display", "none");
                this.loading = false;
            }, 1000);
        }, 1000);
    }

    private toggleSplashScreen(show: boolean): void {
        if (show) {
            this.renderer.removeClass(this.splashScreen, "leave");
            this.renderer.setStyle(this.splashScreen, "display", "flex");
            this.loading = true;
        } else {
            this.renderer.addClass(this.splashScreen, "leave");
            setTimeout(() => {
                this.renderer.setStyle(this.splashScreen, "display", "none");
                this.loading = false;
            }, 1000);
        }
    }
}

const m: IModule = angular.module(MODULE_NAME);
m.directive("eobRoot", downgradeComponent({component: AppComponent}));
