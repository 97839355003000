<div class="smartphone-hitlist-layout" *ngIf="cellContent">
    <span class="smartphone-hitlist-title"
          [ngStyle]="ellipsisStyle"
          [ngClass]="{'bold-title': cellContent.isTitleBold}">{{cellContent.title}}
    </span>
    <span [hidden]="!cellContent.description"
          class="sartphone-hitlist-description"
          [ngStyle]="ellipsisStyle">{{cellContent.description}}
    </span>

    <div *ngIf="cellContent.additionalContent" [ngClass]="cellContent.additionalContent.class" class="additional-content">
        <eob-icon *ngIf="cellContent.icon"
                  name="{{cellContent.icon.name}}"
                  size="16">{{cellContent.icon.title}}
        </eob-icon>
        <span #svgElementSpan [hidden]="!isSyncRunning"></span>
        <span class="additional-content-span">{{cellContent.additionalContent.content}}</span>
    </div>
</div>
