import {Inject, Injectable} from "@angular/core";
import {QuickfinderAddonService} from "MODULES_PATH/form/services/form-builder/quickfinder-addon.service";
import {UserAddonService} from "MODULES_PATH/form/services/form-builder/user-addon.service";
import {WfOrgAddonService} from "MODULES_PATH/form/services/form-builder/wf-org-addon.service";
import {RightGroupsAddonService} from "MODULES_PATH/form/services/form-builder/right-groups-addon.service";
import {DateTimePickerService} from "MODULES_PATH/modal-dialog/services/date-time-picker.service";
import {TranslateFnType} from "CLIENT_PATH/custom.types";
import {InputAddonConfig} from "MODULES_PATH/form/interfaces/input-addon-config.interface";
import {Field} from "INTERFACES_PATH/field.interface";
import {TodoDbCatalogService, TodoFormHelper, TodoTreeAddonService} from "INTERFACES_PATH/any.types";
import {OrgAddonField, WfOrgAddonField} from "INTERFACES_PATH/addon.field.interface";

@Injectable({ providedIn: "root" })
export class FormAddonService {
    translateFn: TranslateFnType;

    // eslint-disable-next-line max-params
    constructor(@Inject("$filter") $filter: ng.IFilterService,
                private quickfinderAddonService: QuickfinderAddonService,
                @Inject("treeAddonService") private treeAddonService: TodoTreeAddonService,
                @Inject("dbCatalogService") private dbCatalogService: TodoDbCatalogService,
                private userAddonService: UserAddonService,
                private wfOrgAddonService: WfOrgAddonService,
                private rightGroupsAddonService: RightGroupsAddonService,
                private dateTimePickerService: DateTimePickerService) {
        this.translateFn = $filter("translate");
    }

    getInputAddonConfig(field: Field, formHelper: TodoFormHelper): InputAddonConfig {
        let addonConfig: InputAddonConfig;

        switch (field.model.addon) {
            case "organisation":
                addonConfig = {
                    title: this.translateFn("form.icon.title.addon-wf-organisation"),
                    showFn: event => this.wfOrgAddonService.showOrganisationAddonByField(event, field as WfOrgAddonField, formHelper, undefined),
                    iconClass: "addon-wf-organisation"
                };
                break;
            case "user":
                addonConfig = {
                    title: this.translateFn("form.icon.title.addon-user-groups"),
                    showFn: event => this.userAddonService.showUserAddon(event, field as OrgAddonField, formHelper),
                    iconClass: "addon-user-groups"
                };
                break;
            case "quickfinder":
                addonConfig = {
                    title: this.translateFn("form.icon.title.addon-quickfinder"),
                    showFn: event => this.quickfinderAddonService.showQuickfinderAsync(event, field as any, formHelper),
                    iconClass: "addon-quickfinder"
                };
                break;
            case "list":
                addonConfig = {
                    title: this.translateFn("form.icon.title.list-catalog"),
                    showFn: event => this.treeAddonService.showTree(event, field, formHelper),
                    iconClass: "list-icon"
                };
                break;
            case "tree":
                addonConfig = {
                    title: this.translateFn("form.icon.title.tree-catalog"),
                    showFn: event => this.treeAddonService.showTree(event, field, formHelper),
                    iconClass: "tree-icon"
                };
                break;
            case "hierarchy":
                addonConfig = {
                    title: this.translateFn("form.icon.title.hierarchy-catalog"),
                    showFn: event => this.treeAddonService.showTree(event, field, formHelper),
                    iconClass: "hierarchy-icon"
                };
                break;
            case "db":
                addonConfig = {
                    title: this.translateFn("form.icon.title.db-catalog"),
                    showFn: event => this.dbCatalogService.showDbCatalog(event, field, formHelper),
                    iconClass: "form-db-catalog-icon"
                };
                break;
            case "rightGroups":
            case "rightGroupsOld":
                addonConfig = {
                    title: this.translateFn("form.icon.title.addon-right-groups"),
                    showFn: event => this.rightGroupsAddonService.showRightGroupsAddon(event, field as OrgAddonField),
                    iconClass: "addon-right-groups"
                };
                break;
            // No idea why, but somehow this addon type is returned on the CI system, and only on the CI system
            case "date":
                addonConfig = {
                    title: this.translateFn("form.icon.title.addon-date"),
                    iconClass: "addon-date",
                    showFn: event => this.dateTimePickerService.showDateTimePicker(event, field, formHelper)
                };
                break;
            case "dateTimeAddon":
                let title: string,
                    iconClass: string;
                switch (field.model.type) {
                    case "date":
                        title = this.translateFn("form.icon.title.addon-date");
                        iconClass = "addon-date";
                        break;
                    case "datetime":
                        title = this.translateFn("form.icon.title.addon-date-time");
                        iconClass = "addon-date";
                        break;
                    case "time":
                        title = this.translateFn("form.icon.title.addon-time");
                        iconClass = "addon-time";
                        break;
                }
                addonConfig = {
                    title,
                    iconClass,
                    showFn: event => this.dateTimePickerService.showDateTimePicker(event, field, formHelper)
                };
                break;
            default:
                return;
        }

        addonConfig.alwaysEnabled = field.model.alwaysEnableAddon;
        addonConfig.iconClass = field.model.iconClass || addonConfig.iconClass;
        addonConfig.title = field.model.addonTooltip || addonConfig.title;
        return addonConfig;
    }
}
