import {Injectable} from "@angular/core";
import {BackendDms2Module} from "CORE_PATH/backend/modules/dms2/backend-dms2.module";
import {Observable} from "rxjs";
import {BackendHistoryObject} from "CORE_PATH/backend/interfaces/backend-history-object.interface";
import {Dms2ObjectHistoryResult} from "CORE_PATH/backend/modules/dms2/interfaces/dms2-object-history-result.interface";
import {map, catchError} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {
    Dms2Object,
    Dms2ObjectModificationResult,
    Dms2ObjectResult,
    Dms2Value
} from "CORE_PATH/backend/modules/dms2/interfaces/dms2-object.interface";
import {BackendInsertUpdateObject} from "CORE_PATH/backend/interfaces/insert-update/backend-insert-update-object.interface";
import {BackendInsertUpdateField} from "CORE_PATH/backend/interfaces/insert-update/backend-insert-update-field.interface";
import {BackendObjectInserts} from "CORE_PATH/backend/interfaces/backend-object-insert.interface";
import {ObjectTypeService} from "MODULES_PATH/dms/objecttype.service";
import {ObjectType} from "INTERFACES_PATH/object-type.interface";
import {IdPair} from "INTERFACES_PATH/id-pair.interface";
import * as cloneDeep from "lodash.clonedeep";
import {ValueUtilsService} from "CORE_PATH/services/utils/value-utils.service";
import {OrganisationService} from "CORE_PATH/services/organisation/organisation.service";

@Injectable({providedIn: BackendDms2Module})
export class Dms2ObjectService {

    constructor(private httpClient: HttpClient, private objectTypeService: ObjectTypeService,
                private valueUtilsService: ValueUtilsService, private organisationService: OrganisationService) {
    }

    getObjectHistory(objectId: string): Observable<BackendHistoryObject[]> {
        return this.httpClient.get<Dms2ObjectHistoryResult>(`/api/dms/objects/${objectId}/history?size=100000`).pipe(
            map(x => x.objects.map(y => {
                const loginName: string = y.properties["system:createdBy"].value;
                const fullName: string = this.organisationService.getUserByName(loginName)?.fullname || "";

                return new BackendHistoryObject(
                    loginName,
                    fullName,
                    y.properties.description.value,
                    y.properties.action.value,
                    y.properties.detail.value,
                    new Date(y.properties["system:creationDate"].value));
                }
            ))
        );
    }

    // eslint-disable-next-line max-params
    moveDmsObject(objectId: string, targetId: string, sourceId: string, objectTypeId?: string, targetTypeId?: string, _cabinetId?: string): Observable<void> {
        const dms2Object: Dms2Object = {
            properties: {
                "system:objectId": {
                    value: objectId
                },
                "system:parentId": {
                    value: targetId
                }
            }
        };

        if (objectTypeId != undefined) {
            dms2Object.properties["system:objectTypeId"] = {
                value: objectTypeId
            };
        }

        if (targetTypeId != undefined) {
            dms2Object.properties["system:parentObjectTypeId"] = {
                value: targetTypeId
            };
        }

        if (sourceId != undefined) {
            // FOLDERID or REGISTERID is not relevant. We could use everytime FOLDERID. Insider knowledge :)
            dms2Object.properties["system:FOLDERID"] = {
                value: sourceId
            };
        }

        // cabinetId isn't needed within DMS2. The service determine the archive out of the object defintion itself.
        const movePayload: Dms2ObjectResult = {
            objects: [dms2Object]
        };

        return this.httpClient.post<void>("/api/dms/objects", movePayload);
    }

    deleteDmsObject(objectId: string, parentId: string, recursive: boolean): Observable<void> {
        let uri: string = `/api/dms/objects/${objectId}`;

        if (recursive && parentId != undefined) {
            uri += `?cascadeDelete=true&location=${parentId}`;
        } else if (recursive) {
            uri += "?cascadeDelete=true";
        } else if (parentId != undefined) {
            uri += `?location=${parentId}`;
        }

        return this.httpClient.delete<void>(uri);
    }

    switchArchiveFlagDmsObject(objectId: string, switchToArchivable: boolean): Observable<void> {
        const dms2Object: Dms2Object = {
            properties: {
                "system:objectId": {
                    value: objectId
                }
            },
            options: {
                ARCHIVABLE: (switchToArchivable) ? 1 : 0
            }
        };
        const updatePayload: Dms2ObjectResult = {
            objects: [dms2Object]
        };

        return this.httpClient.post<void>("/api/dms/objects", updatePayload);
    }

    setObjectTypeForTypelessDmsObject(payload: BackendInsertUpdateObject): Observable<void> {
        const objectType: ObjectType = this.objectTypeService.getRealObjectType(payload.objectTypeId);
        const dms2Object: Dms2Object = {
            properties: {
                "system:objectId": {
                    value: payload.osid
                },
                "system:objectTypeId": {
                    value: payload.objectTypeId
                },
                "system:mainType": {
                    value: payload.mainTypeId
                }
            }
        };

        for (const fieldDisplayName in payload.fields) {
            const field: BackendInsertUpdateField = payload.fields[fieldDisplayName];
            dms2Object.properties[field.internalName] = this.convertFieldsForDms2(field, objectType);
        }

        const updatePayload: Dms2ObjectResult = {
            objects: [dms2Object]
        };

        return this.httpClient.patch<void>(`/api/dms/objects/${payload.osid}`, updatePayload);
    }

    getDmsObjectInserts(objectId: string, objectTypeId: string): Observable<BackendObjectInserts> {
        let uri: string = `/api/dms/objects/${objectId}?objecttypeid=${objectTypeId}`;
        uri += "&includeAllowedChildObjectTypeIds=true"; // They we want
        uri += "&includeContentStreams=false";
        uri += "&systemFields="; // empty, no system fields wanted

        return this.httpClient.get<Dms2ObjectResult>(uri)
            .pipe(map((x: Dms2ObjectResult) => x.objects[0].allowedChildObjectTypeIds)
        );
    }

    insertUpdateDmsObject(req: "insert" | "update" | "wfTray" | "greenArrow", payload: BackendInsertUpdateObject, locationId: string, _locationTypeId: string): Observable<string> {
        const objectType: ObjectType = this.objectTypeService.getRealObjectType(payload.objectTypeId);
        const dms2Object: Dms2Object = {
            properties: {
                "system:objectTypeId": {
                    value: payload.objectTypeId
                }
            }
        };

        if (payload.mainTypeId != undefined) {
            dms2Object.properties["system:mainType"] = {
                value: payload.mainTypeId
            };
        }

        if (locationId != undefined) {
            dms2Object.properties["system:parentId"] = {
                value: locationId
            };
        }

        if (req == "update") {
            dms2Object.properties["system:objectId"] = {
                value: payload.osid
            };
        } else if (req == "wfTray") {
            dms2Object.properties["system:parentId"] = {
                value: "system:workflowtray"
            };
        } else if (req == "greenArrow") {
            dms2Object.properties["system:OBJECT_FOREIGNID"] = {
                value: payload.osid
            };
            dms2Object.properties["system:OBJECT_SYSTEMID"] = {
                value: "0"
            };
        }

        for (const key in payload.fields) {
            dms2Object.properties[payload.fields[key].internalName] = this.convertFieldsForDms2(payload.fields[key], objectType);
        }

        const dms2Payload: Dms2ObjectResult = {
            objects: [dms2Object]
        };

        // minimalResponse=true because the upper cache layer load the object may be later again.
        // We only need the osId to return.
        return this.httpClient.post<Dms2ObjectModificationResult>("/api/dms/objects?minimalResponse=true", dms2Payload).pipe(
            map(response => response.objects[0].properties["system:objectId"].value),
            catchError((response: HttpErrorResponse) => {
                throw response.error.failed[0].innerError;
            })
        );
    }

    /**
     * Convert field values for DMS2. At the moment we need ISO-Date und ISO-DateiTime.
     *
     * @param field The field with it's data to convert
     * @param objectType The object type model to identify date/dateTime fields
     * @return {Dms2Value} A DMS2 field with converted data
     * @private
     */
    private convertFieldsForDms2(field: BackendInsertUpdateField, objectType: ObjectType) : Dms2Value<any> {
        let value: string = field.value;
        const fieldModel: any = objectType.api.getField(field.internalName);

        if (value != "" && fieldModel.dateFormat && fieldModel.type != "time") {
            const isDateTime: boolean = fieldModel.dateFormat.includes(" ");

            // Timestamp or a readable date(time) string?
            if (isNaN(+value)) {
                value = this.valueUtilsService.convertToIsoDateTime(value, isDateTime);
            } else {
                value = new Date(Number.parseInt(value)).toISOString();
            }

            if (!isDateTime) {
                value = value.substring(0, 10); // Cut the time part because it's only a date field
            }
        } else if (field.columns) {
            const dms2Rows: string[][] = [];

            for (const row of field.rows) {
                const dms2Row: string[] = [];

                // eslint-disable-next-line @typescript-eslint/no-for-in-array
                for (const columIndex in field.columns) {
                    value = row[columIndex];

                    if (value != "" && field.columns[columIndex].type == "date") {
                        if (isNaN(+value)) {
                            dms2Row.push(this.valueUtilsService.convertToIsoDateTime(value, false).substring(0, 10));
                        } else {
                            dms2Row.push(new Date(Number.parseInt(value)).toISOString().substring(0, 10));
                        }
                    } else {
                        dms2Row.push(value);
                    }
                }

                dms2Rows.push(dms2Row);
            }

            return {
                columnNames: field.columns.map(x => x.internalName),
                value: dms2Rows
            };
        }

        return {
            value
        };
    }

    getDmsObjectLocations(objectId: string, objectTypeId: string) : Observable<IdPair[][]> {
        return this.httpClient.get<Dms2ObjectResult>(`/api/dms/objects/${objectId}/native/locations${objectTypeId ? `?objectTypeId=${objectTypeId}` : ""}`)
            .pipe(map((x: Dms2ObjectResult) => {
                const locationList: IdPair[][] = [];

                function addFn(collection: IdPair[], backendItem: Dms2Object): void {
                    collection.push({
                        objectId: backendItem.properties["system:objectId"].value,
                        objectTypeId: backendItem.properties["system:objectTypeId"].value
                    });
                }

                function buildLocationList(startNode: Dms2Object, path: IdPair[]): void {
                    if (startNode.properties["system:objectId"].value != objectId) {
                        addFn(path, startNode);
                    } else {
                        if (path.length == 0) {
                            addFn(path, startNode);
                        }

                        locationList.push(path);
                        return;
                    }

                    const childrenDocuments: Dms2Object[] = startNode.children;

                    if (childrenDocuments != void 0) {
                        for (const childDocument of childrenDocuments) {
                            buildLocationList(childDocument, cloneDeep(path));
                        }
                    } else {
                        locationList.push(path);
                    }
                }

                for (const folder of x.objects) {
                    buildLocationList(folder, []);
                }

                return locationList;
            }));
    }

    addDmsObjectLocation(objectId: string, targetObjectId: string, objectTypeId: string, targetObjectTypeId: string): Observable<void> {
        const payload: Dms2ObjectResult = {
            objects: [{
                properties: {
                    "system:objectId": {
                        value: objectId
                    },
                    "system:parentId": {
                        value: targetObjectId
                    }
                },
                options: {
                    LINKDOCUMENT: 1
                }
            }]
        };

        if (objectTypeId != undefined) {
            payload.objects[0].properties["system:objectTypeId"] = {
                value: objectTypeId
            };
        }

        if (targetObjectTypeId != undefined) {
            payload.objects[0].properties["system:parentObjectTypeId"] = {
                value: targetObjectTypeId
            };
        }

        return this.httpClient.post<Dms2ObjectModificationResult>("/api/dms/objects", payload).pipe(map(x => {
            if (x.failed != null && x.failed.length == 1) {
                throw x.failed[0];
            }

            // We discard the result because the objectId is the same and the caller isn't interested in it.
            return undefined;
        }));
    }
}
