import {Component, ElementRef} from "@angular/core";
import {OnInit} from "@angular/core";
import * as angular from "angular";

@Component({
    selector: "eob-pdfjs-container",
    templateUrl: "../../../../../../../../node_modules/os-pdfjs/build_jenkins/web/viewer-template.html",
    styleUrls: ["./eob-pdfjs-container.scss"]
})
export class EobPdfjsContainerComponent implements OnInit {
    element: HTMLElement;

    constructor(el: ElementRef<HTMLElement>) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        this.removeUIComponents();
        this.hideUIComponents();
        this.addElements();
    }

    /** Remove pdf.js ui components, that we replace with our own components */
    removeUIComponents(): void {
        ["#findbar", "eob-pdfjs-container #passwordDialog"].forEach(selector => {
            const el: HTMLElement = this.element.querySelector(selector);
            if (el) {
                el.remove();
            }
        });
    }

    /** Hide pdf.js ui components, that we don't need. */
    hideUIComponents(): void {
        [".toolbar", "#toolbarSidebar"].forEach(selector => {
            const el: HTMLElement = this.element.querySelector(selector);
            if (el) {
                el.hidden = true;
            }
        });
    }

    /** Add elements to the pdfjs needed for os functionality. */
    addElements(): void {
        const sidebarContent: Element = this.element.querySelector("#sidebarContainer");
        sidebarContent.append(this.htmlToElement("" +
            "        <div id=\"attachment-container\" class=\"invisible\">\n" +
            "          <div id=\"attachment-wrapper\" class=\"attachment-wrapper\"></div>\n" +
            "        </div>"));
    }

    /**
     * @param {String} html representing a single element
     * @return {Element} the html element
     */
    htmlToElement(html: string): ChildNode {
        const template: HTMLTemplateElement = document.createElement("template");
        html = html.trim(); // Never return a text node of whitespace as the result
        template.innerHTML = html;
        return template.content.firstChild;
    }
}
