import {Directive, ElementRef, AfterViewInit, Input} from "@angular/core";
import {Observable} from "rxjs";

@Directive({
    selector: "[knTrapFocus]"
})

/**
 * Use this directive to trap focus inside a block e.g. modal dialog
 *
 * There are two modes:
 * (1) default: You define two divs with class .focus-trap,
 *         the directive will keep the focus inbetween those divs
 *         and pick focus-elements on its own
 * (2) dynamic focus-elements: Define .focus-trap divs (like in 1) and
 *          set [knTrapClassFirst] (or Last) to a class of the first (or last) element that your trap should focus to
 *          Each time focus is set the directice will search for the first focusable element with this class
 *
 * NOTE: To disable the trap just set attribute disabled and tabindex -1 on the focus-trap
 *       If no focus-traps are found nothing happens
 */
export class KnTrapFocusDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    @Input() knTrapClassFirst: string;
    @Input() knTrapClassLast: string;

    ngAfterViewInit() {
        this.trapFocus(this.el.nativeElement);
    }

    /**
     * Configure a div.focus-trap element so when it gets focused it moves the focus on to either the element in 'to' or
     * if 'className' and 'context' are specified it looks for the first element of class 'className' inside 'context'
     * each time the focus-trap receives focus
     *
     * @param from: a div.focus-trap element
     * @param to: a focusable element
     * @param className: (optional) a class to focus instead of element in 'to'
     * @param context: (only when className is set) node to search for 'className' element
     */
    configureFocusRedirection = (from: any, to: any, className: string, context: any) => {
        const getFocusableElement = () => {
            if (className && context) {
                return context.querySelector(`${className}[tabindex='0']`);
            }
            return to;
        };
        from.setAttribute("tabindex", "0");
        from.onfocus = () => {
            getFocusableElement().focus();
        };
    };

    /**
     * Find the two elements (with class .focus-trap) that trap the focus between them
     *
     * @param context: node to search for the fencing elements
     */
    getFencingElements(context): {first: any; last: any} {
        const getFirstAndLast = (prev: {first: any; last: any}, curr, i, arr) => {
            if (i == 0) {
                prev.first = curr;
                return prev;
            }
            if (i == arr.length - 1) {
                prev.last = curr;
                return prev;
            }
            return prev;
        };

        const userDefinedElements: {first: any; last: any} = Array.from(context.querySelectorAll(".focus-trap"))
            .reduce(getFirstAndLast, {first: undefined, last: undefined}) as {first: any; last: any};
        const defaultElements: {first: any; last: any} = Array.from(context.querySelectorAll(
            "a[href], button, textarea, input[type=\"text\"]," +
            "input[type=\"radio\"], input[type=\"checkbox\"], select"
        )).filter( (el: any) => !el.disabled)
            .reduce(getFirstAndLast, {first: undefined, last: undefined}) as {first: any; last: any};

        if (userDefinedElements?.first && userDefinedElements.last) {
            this.configureFocusRedirection(userDefinedElements.first, defaultElements.last, this.knTrapClassLast, context);
            this.configureFocusRedirection(userDefinedElements.last, defaultElements.first, this.knTrapClassFirst, context);
            return userDefinedElements;
        }

        return undefined;
    }

    trapFocus(element) {
        const fencingEl: {first: any; last: any} = this.getFencingElements(element);

        if (!fencingEl) {
            return;
        }

        const firstFocusableEl: any = fencingEl.first;
        const lastFocusableEl: any = fencingEl.last;

        element.addEventListener("keydown", (e) => {
            const isTabPressed = e.keyCode === 9; // isTabPressed
            if (!isTabPressed) {
                return;
            }

            if (lastFocusableEl.getAttribute("disabled") || firstFocusableEl.getAttribute("disabled")) {
                return;
            }

            if ( e.shiftKey ) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
        });
    }
}