import {Inject, Injectable, NgZone} from "@angular/core";
import {
    TodoCacheManagerService,
    TodoDocumentViewer,
    TodoEnvironmentService,
    TodoPdfjsViewerService
} from "INTERFACES_PATH/any.types";
import {DmsDocument} from "MODULES_PATH/dms/models/dms-document";
import {WopiHostService} from "MODULES_PATH/wopi/services/eob.wopi.host.service";
import {LayoutManagerService} from "CORE_PATH/services/layout-manager/layout-manager.service";
import {HttpService} from "CORE_PATH/backend/http/http.service";
import {ClientService} from "../client/client.service";
import {MessageService} from "../message/message.service";
import {ViewerEvent} from "MODULES_PATH/dashlet/enums/viewer-event.enum";
import {BehaviorSubject, Subscription} from "rxjs";
import {debounceTime, map, pairwise} from "rxjs/operators";
import {DashletMessage} from "MODULES_PATH/dashlet/interfaces/dashlet-api.interface";
import {CustomDashletConfig} from "MODULES_PATH/dashlet/interfaces/custom-dashlet-config.interface";

@Injectable({
    providedIn: "root"
})
export class ViewerService {
    private viewerContainer: JQuery;
    private searchKey: string;
    private clearDebounce: NodeJS.Timeout;
    private updateViewer$: BehaviorSubject<DashletMessage>;
    private showO365Viewer: boolean = false;
    private fileMimeTypeGroup = null;

    private lastActiveDashlet: string;

    // eslint-disable-next-line max-params
    constructor(
        @Inject("$compile") private $compile: ng.ICompileService,
        @Inject("documentViewer") private documentViewer: TodoDocumentViewer,
        @Inject("environmentService") private environmentService: TodoEnvironmentService,
        @Inject("pdfjsViewerService") private pdfjsViewerService: TodoPdfjsViewerService,
        @Inject("cacheManagerService") private cacheManagerService: TodoCacheManagerService,
        private layoutManagerService: LayoutManagerService,
        private httpService: HttpService,
        private clientService: ClientService,
        private messageService: MessageService,
        private ngZone: NgZone,
        private wopiHostService: WopiHostService) {
        this.searchKey = "";
    }

    getLastActiveDashlet(): string {
        return this.lastActiveDashlet;
    }

    setLastActiveDashlet(dashlet: string): void {
        this.lastActiveDashlet = dashlet;
    }

    setViewerContainer(element: JQuery): void {
        this.viewerContainer = element;
    }

    getContentViewer(): any {
        if (this.environmentService.useDocumentViewer()) {
            return this.documentViewer.getViewer();
        } else {
            return this.pdfjsViewerService.getViewer();
        }
    }

    // region search key
    getSearchKey(): string {
        return this.searchKey;
    }

    setSearchKey(key: string): void {
        if (this.environmentService.useDocumentViewer()) {
            this.documentViewer.getViewer().showSearch(key);
        } else {
            this.pdfjsViewerService.getViewer().showSearch(key);
        }

        this.searchKey = key;
    }

    /**
     * removes logical " and ' characters from the search string @ the beginning and the end
     * unless these characters are escaped
     */
    removeLogicalOperators(key: string): string {
        if (key.indexOf("\\\'") == 0 && key.lastIndexOf("\\\'") == key.length - 2) {
            key = key.replace(/^\\'/, "\'").replace(/\\\'$/, "\'");
        } else if (key.indexOf("\'") == 0 && key.lastIndexOf("\'") == key.length - 1) {
            key = key.replace(/^\'/, "").replace(/\'$/, "");
        } else if (key.indexOf("\\\"") == 0 && key.lastIndexOf("\\\"") == key.length - 2) {
            key = key.replace(/^\\"/, "\"").replace(/\\\"$/, "\"");
        } else if (key.indexOf("\"") == 0 && key.lastIndexOf("\"") == key.length - 1) {
            key = key.replace(/^\"/, "").replace(/\"$/, "");
        }

        return key;
    }

    // endregion

    // region viewer events
    /**
     * Update the shown viewer.
     *
     * @param osid - The osid of the to be shown dms object.
     * @param dmsDocument - Optional dmsDocument, that can be fed to the detailsviewer.
     * @param selectedOsids - Optional changed selection.
     * @returns Resolved once a viewer change was triggered.
     */
    async updateViewer(osid?: number, dmsDocument?: DmsDocument, selectedOsids?: number[]): Promise<void> {
        await this.environmentService.viewerPromise;

        if (!this.updateViewer$) {
            this.updateViewer$ = new BehaviorSubject<DashletMessage>({});

            // overwrite undefined with previous values
            const subscription: Subscription = this.updateViewer$.pipe(pairwise(),
                map(([prev, current]) => {
                    current.osid = current.osid || prev.osid;
                    current.selectedOsids = current.selectedOsids || prev.selectedOsids;
                    return current;
                }), debounceTime(300)).subscribe(value => {
                    subscription.unsubscribe();
                    this.updateViewer$ = undefined;
                    this.messageService.broadcast(ViewerEvent.UPDATE_VIEWER, value);
                });
        }

        this.updateViewer$.next({osid, dmsDocument, selectedOsids});
    }

    async refreshContent(osid: number, keepCache?: boolean): Promise<void> {
        await this.environmentService.viewerPromise;
        this.messageService.broadcast(ViewerEvent.REFRESH_VIEWER, {osid, keepCache, dashletIds: ["content"]});
    }

    async refreshDetails(osid: number): Promise<void> {
        await this.environmentService.viewerPromise;
        this.messageService.broadcast(ViewerEvent.REFRESH_VIEWER, {osid, dashletIds: ["details"]});
    }

    async clearViewer(): Promise<void> {
        await this.environmentService.viewerPromise;
        this.messageService.broadcast(ViewerEvent.CLEAR_VIEWER);
    }

    // endregion

    // region content viewer
    updateContentViewer(osid: number): void {
        // prevent interference from parallel clearViewer() call after navigation in folder tree
        clearTimeout(this.clearDebounce);

        if (this.environmentService.useDocumentViewer()) {
            this.notifyAnnotations(osid);
            this.documentViewer.getViewer().updateId(osid, undefined, true);
            this.hideDefaultPage();
        } else if (this.environmentService.env.usePdfViewer) {
            this.pdfjsViewerService.getViewer().updateId(osid);
            this.hideDefaultPage();
        }

        const dmsDocument: DmsDocument = this.cacheManagerService.dmsDocuments.getById(osid);
        const searchKeyLocal: string = dmsDocument != void 0 && (Array.isArray(dmsDocument.model.vtx)) ? dmsDocument.model.vtx[0].model.searchKey : "";

        if (searchKeyLocal != void 0 && searchKeyLocal != "") {
            this.setSearchKey(this.removeLogicalOperators(searchKeyLocal));
        } else {
            this.searchKey = "";
        }
    }

    async refreshContentViewer(osid: number, keepCache?: boolean): Promise<void> {
        await this.environmentService.viewerPromise;

        if (this.environmentService.useDocumentViewer()) {
            this.documentViewer.getViewer().showLoadingAnimation();
        }

        if (!keepCache) {
            try {
                await this.httpService.deleteOsRenditionCache(osid).toPromise();
            } catch (e) {
                console.warn(e);
            }
        }

        if (this.environmentService.useDocumentViewer()) {
            this.documentViewer.getViewer().refresh(osid);
        } else {
            this.hideDefaultPage();
            this.pdfjsViewerService.getViewer().refresh(osid);
        }
    }

    clearContentViewer(): void {
        if (this.environmentService.useDocumentViewer()) {
            this.documentViewer.getViewer().clearViewer();
        } else {
            this.showDefaultPage();
            this.pdfjsViewerService.getViewer().clearViewer();
        }

        this.notifyAnnotations();
    }

    // endregion

    // region o365
    getShowO365Viewer(): boolean {
        return !this.clientService.isOffline() && !this.clientService.isPhoneOrTablet() && this.showO365Viewer;
    }

    async updateO365Viewer(osId, dmsDocument): Promise<void> {
        if (!osId) {
            return;
        }

        const fileId = "1";
        const language = this.environmentService.getLanguage();
        await this.wopiHostService.initDMSObjectAsync(osId, fileId, dmsDocument, language);
    }

    setFileMimeTypeGroup(value: string, mainType: string | number, isTypeless?: boolean): void {
        this.fileMimeTypeGroup = value;
        const allowedExtensions: string[] = this.environmentService.getAllowedFileExtByMainType(mainType).split(",");

        if (isTypeless) {
            this.showO365Viewer = false;
        } else if (this.fileMimeTypeGroup !== "POWERPOINT" && this.fileMimeTypeGroup !== "WORD" && this.fileMimeTypeGroup !== "EXCEL") {
            this.showO365Viewer = allowedExtensions.includes("PPTX") || allowedExtensions.includes("DOCX") || allowedExtensions.includes("XLSX");
        } else {
            this.showO365Viewer = true;
        }
    }

    // endregion

    // region annotations
    refreshAnnotations(maxPages?: string): void {
        if (!this.clientService.isOnline()) {
            return;
        }

        this.dispatchCustomEvent("osAnnoRefresh", {maxPages});
    }

    notifyAnnotations(osid?: number): void {
        osid = (osid == void 0) ? -1 : osid;
        this.dispatchCustomEvent("osAnnoOsid", osid);
    }

    /**
     * @param eventName - defined name of the custom event
     * @param eventObject - defined as type any, because initCustomEvent method from
     * the CustomEvent expects a detailArg parameter which is already declared as any
     */
    dispatchCustomEvent(eventName: string, eventObject: any): void {
        const event: CustomEvent = document.createEvent("CustomEvent");
        event.initCustomEvent(eventName, true, false, eventObject);

        const eventContainer: HTMLElement = document.getElementById("pdfjsViewerContainer");
        if (eventContainer != void 0) {
            eventContainer.dispatchEvent(event);
        }
    }

    // endregion

    // region default page
    showDefaultPage(): void {
        if (this.viewerContainer != null) {
            this.viewerContainer.find("#default-page").show();
            this.viewerContainer.find("#pdfjsViewerContainer").hide();
        }
    }

    hideDefaultPage(): void {
        if (this.viewerContainer != null) {
            this.viewerContainer.find("#default-page").hide();
            this.viewerContainer.find("#pdfjsViewerContainer").show();
        }
    }

    // endregion
}
